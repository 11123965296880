define('modules/strategy/views/datePicker',["T1", "T1View", "text!../templates/datePicker.html", "moment"], function (T1, T1View, template, moment) {
  "use strict";

  const dateFormat = "MM/DD/YYYY";
  const rangeDescription = "Custom Date (Under 30 Days)";
  const thirty = 30;
  return T1View.extend({
    template: template,
    events: {
      "click .dates span": "openDatePickerHandler",
      "saved .date-picker": "saveDatesHandler",
      "change .date-picker": "calendarInputHandler",
      "calendar-select .date-picker": "calendarSelectHandler"
    },
    initialize: function (args) {
      this.setDateHelpers();
      this.initDates(args);
    },
    load: function () {
      this.render().then(() => {
        setTimeout(() => {
          var datePicker = this.datePicker = this.el.find(".date-picker")[0];
          datePicker.target = this.el.find(".dates")[0];
          datePicker.rangeDescription = rangeDescription;
          datePicker.isCustomEndSet = false;
        }, 0);
      });
    },
    unload: function () {
      this.datePicker = null;
      this.endDate = null;
      this.rangePresets = null;
      this.startDate = null;
    },
    initDates: function (args = {}) {
      this.startDate = args.startDate || this.tomorrow;
      this.endDate = args.endDate || this.oneMonth;
    },
    setDateHelpers: function () {
      const seven = 7;
      const forteen = 14;
      this.tomorrow = moment().add(1, "days");
      this.nextDay = this.tomorrow.clone().add(1, "days");
      this.oneWeek = this.tomorrow.clone().add(seven, "days");
      this.twoWeeks = this.tomorrow.clone().add(forteen, "days");
      this.oneMonth = this.tomorrow.clone().add(thirty, "days");
      this.rangePresets = [{
        name: "Custom Date (Under 30 Days)",
        value: rangeDescription,
        end: this.oneMonth
      }, {
        name: "Next Day",
        value: "Next Day",
        start: this.tomorrow,
        end: this.nextDay
      }, {
        name: "Next 7 Days",
        value: "Next 7 Days",
        start: this.tomorrow,
        end: this.oneWeek
      }, {
        name: "Next 14 Days",
        value: "Next 14 Days",
        start: this.tomorrow,
        end: this.twoWeeks
      }, {
        name: "Next 30 Days",
        value: "Next 30 Days",
        start: this.tomorrow,
        end: this.oneMonth
      }];
    },
    openDatePickerHandler: function () {
      const datePicker = this.datePicker;
      this.setDateHelpers();
      datePicker.end = this.endDate;
      datePicker.start = this.startDate;
      datePicker.allowedStart = this.tomorrow;
      datePicker.rangePresets = this.rangePresets;
      datePicker.allowedEnd = this.tomorrow.clone().add({
        years: 2,
        days: 30
      });
      datePicker.open();
    },
    startDateSelected: function (datePicker, selectedStartDate, allowedEndDate) {
      const currentEndDate = moment(datePicker.endDate, dateFormat);
      datePicker.startDate = selectedStartDate.format(dateFormat);
      if (currentEndDate.isAfter(allowedEndDate)) {
        datePicker.endDate = allowedEndDate.format(dateFormat);
      }
    },
    endDateSelected: function (datePicker, selectedEndDate, allowedStartDate) {
      const currentStartDate = moment(datePicker.startDate, dateFormat);
      datePicker.endDate = selectedEndDate.format(dateFormat);
      if (currentStartDate.isBefore(allowedStartDate)) {
        datePicker.startDate = allowedStartDate.format(dateFormat);
      }
    },
    calendarDateChanged: function (selectedDate, changeType) {
      const datePicker = this.datePicker;
      const allowedOpposingDate = selectedDate.clone();
      switch (changeType) {
        case "start":
          this.startDateSelected(datePicker, selectedDate, allowedOpposingDate.add(thirty, "days"));
          break;
        case "end":
          this.endDateSelected(datePicker, selectedDate, allowedOpposingDate.subtract(thirty, "days"));
          break;
      }
    },
    calendarSelectHandler: function (event) {
      const origEvt = event.originalEvent;
      const targetEl = origEvt.target;
      const pickerPanelEl = targetEl.closest("strand-datepicker-panel");
      this.calendarDateChanged(moment(origEvt.detail.date), pickerPanelEl.id);
    },
    calendarInputHandler: function (event) {
      const targetEl = event.target;
      const inputMaskEl = targetEl.closest("strand-input-mask");
      const pickerPanelEl = targetEl.closest("strand-datepicker-panel");
      this.calendarDateChanged(moment(inputMaskEl.value), pickerPanelEl.id);
    },
    saveDatesHandler: function (event) {
      const origEvent = event.originalEvent;
      const eventData = origEvent.detail;
      this.startDate = moment(eventData.start);
      this.endDate = moment(eventData.end);
      T1.EventHub.publish("forecaster:saveDate", {
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    serialize: function () {
      var params = {
        start: this.startDate.format(dateFormat),
        end: this.endDate.format(dateFormat)
      };
      return {
        params: params
      };
    }
  });
});
