define('models/videoCreativeStatus',["T1", "T1Model", "Backbone"], function (T1, T1Model, Backbone) {
  T1.Models.VideoCreativeStatus = T1Model.extend({
    apiRoot: T1.VIDEO_API_BASE,
    action: "status",
    url: function () {
      var creativeId = this.get("id");
      if (!creativeId) {
        throw new Error("Creative ID is required to display status");
      }
      return this.apiRoot + "creatives/" + creativeId + "/status/";
    },
    fetch: function (options) {
      var self = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (data) {
        self.loaded = true;
        self.set(data);
        if (options.onSuccess) {
          options.onSuccess(self, data);
        }
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      this.loaded = false;
      self.ajx = self.sync("read", "", {
        action: self.action,
        dataType: "json",
        success: onSuccess,
        processAjaxResponse: function (data) {
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    fixture: "-video_creative_status"
  });
  return T1.Models.VideoCreativeStatus;
});
