define('modules/reporting/campaigns/views/select_dimension',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1SelectFilterList", "text!../templates/select_dimension.html"], function ($, _, T1, T1View, T1Layout, T1SelectFilterList, template) {
  "use strict";

  const ANIM_TIME = 200;
  const ANIM_TIME_2 = 100;
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  return T1SelectFilterList.extend({
    documentEvents: "mousedown.select_dimension keyup.select_dimension",
    template: template,
    UIOpen: false,
    all_text: "All Strategies",
    events: {
      "click .remove-dimension": "removeDim2"
    },
    dataEvents: {
      reportModel: {
        "change:dimension": function () {
          if (this.isDim1Instance) {
            this.updateDimensionText();
          }
        },
        "change:dim2": function () {
          if (this.isDim2Instance) {
            this.updateDimensionText();
          }
        },
        "update:dim1DDL": function () {
          if (this.isDim1Instance) {
            this.toggleClearFilter();
          }
        },
        "update:dim2DDL": function () {
          if (this.isDim2Instance) {
            this.toggleClearFilter();
          }
        }
      }
    },
    eventhubEvents: {
      "select:campaign": "resetToAll",
      "select:focus": "resetToAll",
      "toggle:clearFilter": "toggleClearFilter"
    },
    toggleClearFilter: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var outCount = reportModel.getFilteredOutCount(isDim2Instance);
      var $clearFilter = this.$clearFilter;
      if ($clearFilter && $clearFilter.length > 0) {
        if (outCount > 0) {
          $clearFilter.fadeIn(ANIM_TIME);
        } else {
          $clearFilter.hide();
        }
      }
    },
    initialize: function (args) {
      var models = this.models;
      this.dim2InitState = true;
      this.isDim1Instance = args.index === 0;
      this.isDim2Instance = args.index === 1;
      this.report_type = args.report_type;
      this.dimensionObj = args.dimensionObj;
      this.dimensionOptions = args.dimensionOptions;
      this.updateDimensionInfo();
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
    },
    onCollectionLoad: function () {
      var reportMeta, reportModel;
      var self = this;
      var models = this.models;
      this.reportMeta = reportMeta = models.reportMeta;
      this.reportModel = reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, reportMeta.toJSON());
      this.reportModelObj = $.extend({}, reportModel.toJSON());
      if (this.isDim2Instance) {
        this.dim2InitState = !reportModel.getIsDim2Active();
        this.selectedDimension = reportModel.get("dim2");
      }
      this.updateDimensionInfo();
      this.initLayouts();
      this.render().then(function () {
        if (!self.layout.isActive) {
          self.activateSelectbox();
          self.initializeUI();
          self.layout.load();
        }
      });
    },
    initLayouts: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".filter-list-holder",
        template: '<div class="dimension-filter"></div>',
        layout: {
          ".dimension-filter": [{
            module: "reporting/campaigns",
            viewType: "dimension_filter_list",
            options: {
              clearFieldOnUpdate: true,
              dimensionObj: this.dimensionObj,
              index: this.isDim1Instance ? 0 : 1,
              models: this.models,
              report_type: this.report_type,
              selectFilterListView: this,
              showOnInit: true,
              onApply: function (checkedItems, allChecked) {
                self.allChecked = allChecked;
                self.closeByApply = true;
                self.checkedItems = checkedItems;
                self.applyFilteredItems(checkedItems);
              }
            }
          }]
        }
      });
    },
    open: function () {
      var self = this;
      var isDim2Instance = this.isDim2Instance;
      var $clearFilter = this.$clearFilter;
      this.$document.mousedown();
      if (this.searchApplied && this.$filterList) {
        this.$filterList.trigger("organizeSearchResult");
      }
      this.UIOpen = true;
      this.closeByApply = false;
      if ($clearFilter && $clearFilter.length > 0) {
        $clearFilter.addClass("white-bg");
      }
      this.$headLabel.addClass("white-bg");
      this.$wBody.fadeIn(ANIM_TIME);
      if (this.$wFilterList) {
        this.$wFilterList.trigger("updateScrollBar");
      }
      if (isDim2Instance) {
        this.updateDim2UI(this.UIOpen);
      }
      this.$document.one(this.documentEvents, function (event) {
        if (event.keyCode === TAB_KEY || event.keyCode === ESCAPE_KEY || !event.keyCode) {
          self.close();
        }
      });
    },
    close: function () {
      var $clearFilter = this.$clearFilter;
      var inTemporaryListMode = this.inTemporaryListMode();
      var $searchInput = this.el.find(".search input");
      var $wFilterList = this.$wFilterList;
      var searchTerm = $wFilterList ? $searchInput.val() : "";
      var searchTermApplied = $wFilterList ? $searchInput.data("searchTerm") || "" : "";
      var isDim2 = this.isDim2Instance;
      this.UIOpen = false;
      if ($clearFilter && $clearFilter.length > 0) {
        $clearFilter.removeClass("white-bg");
      }
      this.$headLabel.removeClass("white-bg");
      this.$wBody.hide();
      this.toggleClearFilter();
      if (this.closeByApply) {
        if ($searchInput.length > 0) {
          $searchInput.data("searchTerm", searchTerm);
        }
      } else {
        if (inTemporaryListMode) {
          this.abortCollectionLoad();
          this.reportModel.restoreOriginalDDLItems();
        } else {
          $searchInput.val(searchTermApplied);
        }
        this.resetFilter();
        if ($wFilterList) {
          $wFilterList.trigger("updateList");
        }
      }
      if (isDim2) {
        this.updateDim2UI(this.UIOpen);
      }
      this.$document.off(this.documentEvents);
    },
    updateDim2UI: function (showUI) {
      var $el = this.el;
      var $headLabel = this.$headLabel;
      var $dimensionLabel = $headLabel.find(".add-dim2");
      var $xIcon = $headLabel.find(".x-icon");
      var $filterList = this.$wFilterList;
      var $removeDimension = $el.find(".remove-dimension");
      var whiteBgClass = "white-bg";
      var selectionStateClass = "selection-state";
      var dividerAfterClass = "divider-after";
      var initState = this.dim2InitState;
      if (showUI) {
        if (initState) {
          $dimensionLabel.addClass(selectionStateClass);
          $xIcon.show();
          $headLabel.addClass(dividerAfterClass);
          this.updateDataBind({
            initLabelText: "Dimension Filter"
          }, $el);
          $filterList.find(".ui-submit").addClass("disabled");
          $filterList.find(".list, .search").hide();
        } else {
          $removeDimension.addClass(whiteBgClass);
        }
      } else {
        $headLabel.removeClass(dividerAfterClass);
        if (initState) {
          $dimensionLabel.removeClass(selectionStateClass);
          $xIcon.hide();
          this.updateDataBind({
            initLabelText: "+ Add Filter"
          }, $el);
        } else {
          $removeDimension.removeClass(whiteBgClass);
        }
      }
    },
    removeDim2: function () {
      var $el = this.el;
      var $DDLnative = this.$DDLnative;
      var reportModel = this.reportModel;
      var publish = T1.EventHub.publish;
      var total = reportModel.get("filteredOutCount") > 0 ? "Total (filtered)" : "Total";
      var isReloadNeeded = reportModel.get("filteredOutCountDim2") > 0;
      this.$document.mousedown();
      this.dim2InitState = true;
      reportModel.set({
        dim1FilteredDDLItems: null,
        dim1FilteredItems: null,
        dim1FilteredItemsCount: null,
        dim1FilteredItemsLookup: null,
        dim2: null,
        dim2DDLItems: null,
        dim2Info: null,
        dim2Items: null,
        dim2ItemsCount: null,
        dim2ItemsLookup: null,
        filteredInCountDim2: null,
        filteredInItemsDim2: null,
        filteredOutCountDim2: null,
        filteredOutItemsDim2: null,
        hiddenCountDim2: null,
        hiddenItemsDim2: null,
        tempDim2: null,
        tempDim2Info: null,
        totalLabel: total
      }, {
        silent: true
      });
      this.$headLabel.removeClass("white-bg");
      this.$wBody.hide();
      $el.find(".remove-dimension").addClass("no-display");
      this.renderPartial(".head-label.dim2");
      if ($DDLnative.find("option:first-child").hasClass("item")) {
        $DDLnative.prepend("<option></option>");
        $DDLnative.children().removeAttr("selected");
      }
      $DDLnative.val("").trigger("liszt:updated");
      publish("toggle:clearFilter");
      publish("remove:dim2", isReloadNeeded);
    },
    onSelectOpen: function () {
      this.toggleAvailableDimensions();
    },
    onSelectChange: function (event) {
      var $filterList = this.$wFilterList;
      var selection = $(event.target).val();
      var isDim1Instance = this.isDim1Instance;
      var isDim2Instance = this.isDim2Instance;
      if ($filterList) {
        $filterList.trigger("searchClear").trigger("emptyList");
        if (isDim2Instance && this.dim2InitState) {
          $filterList.find(".ui-submit").removeClass("disabled");
          $filterList.find(".list, .search").show();
        }
      }
      this.selectedDimension = selection;
      if (isDim1Instance) {
        this.reportModel.setDimension(selection, true);
      } else {
        this.reportModel.setDimension(null, null, null, selection, true);
      }
      this.reportModel.setHighlightedItem();
      this.updateDimensionInfo(true);
    },
    toggleAvailableDimensions: function () {
      var reportModel = this.reportModel;
      var dim1Info = reportModel.getDimensionInfo(false);
      var dim2Info = reportModel.getDimensionInfo(true);
      var dim1Label = dim1Info && dim1Info.text || "";
      var dim2Label = dim2Info && dim2Info.text || "";
      var $ddlItems = this.$DDL.find(".chzn-results li");
      var filterOutSelectedDimension = function (dimensionLabel) {
        $ddlItems.show();
        $ddlItems.filter(function () {
          return $(this).text() === dimensionLabel;
        }).hide();
      };
      if (this.isDim1Instance) {
        if (dim2Label) {
          filterOutSelectedDimension(dim2Label);
        }
      } else {
        if (dim1Label) {
          filterOutSelectedDimension(dim1Label);
        }
      }
    },
    filterClear: function (event) {
      var currentDimension = this.reportModel.getDimension(this.isDim2Instance);
      var $searchInput = this.el.find(".search input");
      this.closeByApply = true;
      this.$document.mousedown();
      if (event) {
        event.stopPropagation();
      }
      this.$clearFilter.fadeOut(ANIM_TIME_2);
      this.$DDLnative.val(currentDimension).trigger("liszt:updated");
      this.$wFilterList.trigger("filterClear", !this.UIOpen);
      if ($searchInput.length > 0) {
        $searchInput.data("searchTerm", "");
      }
    },
    resetFilter: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var currentDimension = reportModel.getDimension(this.isDim2Instance);
      if (isDim2Instance) {
        reportModel.setDimension(null, null, null, currentDimension, false);
      } else {
        reportModel.setDimension(currentDimension, false);
      }
      reportModel.clearTempDimension();
      this.$DDLnative.val(currentDimension).trigger("liszt:updated");
      if (this.$wFilterList) {
        this.$wFilterList.trigger("searchClear");
      }
    },
    resetToAll: function () {
      var reportModel = this.reportModel;
      var $clearFilter = this.$clearFilter;
      this.allChecked = true;
      reportModel.resetFilteredInItems();
      reportModel.setAppliedFilteredItems(null, false);
      reportModel.setAppliedFilteredItems(null, true);
      if ($clearFilter && $clearFilter.length > 0) {
        $clearFilter.hide();
      }
      if (this.$wFilterList) {
        this.$wFilterList.trigger("reset").trigger("updateList");
      }
    },
    updateDimensionInfo: function (withTempInfo) {
      var defaultLabelTxt = "Strategy";
      var isDim2Instance = this.isDim2Instance;
      var reportModel = this.models.reportModel;
      var dimensionInfo = withTempInfo ? reportModel.getTempDimensionInfo(isDim2Instance) : reportModel.getDimensionInfo(isDim2Instance);
      this.currentLabel = dimensionInfo && dimensionInfo.dimension || defaultLabelTxt;
      this.currentLabelText = dimensionInfo && dimensionInfo.text || defaultLabelTxt;
    },
    updateDimensionText: function () {
      this.updateDataBind({
        currentLabelText: this.reportModel.getDimensionInfo(this.isDim2Instance).text
      }, this.el);
    },
    applyFilteredItems: function (checkedItems) {
      var reportModel = this.reportModel;
      var publish = T1.EventHub.publish;
      var isDim1Instance = this.isDim1Instance;
      var isDim2Instance = this.isDim2Instance;
      var selectEvent = isDim1Instance ? "select:dimension" : "select:dim2";
      var currentDimension = reportModel.getDimension(isDim2Instance);
      var selectedDimension = this.selectedDimension || currentDimension;
      var filteredKey = reportModel.getFilteredKey(isDim2Instance);
      var dimChanged = selectedDimension !== currentDimension;
      if (this.UIOpen) {
        if (isDim2Instance) {
          if (this.dim2InitState) {
            this.dim2InitState = false;
            this.el.find(".remove-dimension").removeClass("no-display");
            this.renderPartial(".head-label.dim2");
          }
        }
      }
      reportModel.clearTempDimension();
      reportModel.setFilteredOutItems(checkedItems, dimChanged, true, isDim2Instance, filteredKey);
      publish(selectEvent, selectedDimension);
      publish("track:event", {
        action: isDim2Instance ? "Choose dimension 2" : "Choose dimension",
        label: selectedDimension
      });
    },
    inTemporaryListMode: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var tempDimension = reportModel.getTempDimension(isDim2Instance);
      var dimension = reportModel.getDimension(isDim2Instance);
      return dimension === null || tempDimension && dimension && tempDimension !== dimension;
    },
    abortCollectionLoad: function () {
      var models = this.models;
      var coll = this.isDim2Instance ? models.tempDim2Coll : models.tempDim1Coll;
      if (coll) {
        coll.abort();
        coll = null;
      }
    },
    unload: function () {
      this.unloaded = true;
    },
    serialize: function () {
      var options = this.dimensionOptions || this.reportMeta.get("dimensionOptions");
      var isDim1Instance = this.isDim1Instance;
      var initState = this.dim2InitState;
      var currentLabel = this.currentLabel;
      $.each(options, function (i, option) {
        option.selected = isDim1Instance ? option.value === currentLabel : initState ? false : option.value === currentLabel;
      });
      return {
        currentLabelText: this.currentLabelText,
        dim1: isDim1Instance,
        dim2: this.isDim2Instance,
        initState: initState,
        items: options,
        label: this.label
      };
    }
  });
});
