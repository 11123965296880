define('models/report',["T1", "T1Model"], function (T1, T1Model) {
  T1.Models.Report = T1Model.extend({
    canCache: true,
    cacheDuration: 18e5,
    apiRoot: T1.RPT_API_ROOT,
    url: "",
    fixture: "-report",
    action: "read",
    initialize: function (options) {
      T1Model.prototype.initialize.call(this, options);
      options = options || {};
      this.urlString = options.urlString || "report.csv";
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.url = options.url || this.url;
    },
    fetch: function (options) {
      var self = this;
      var model = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (resp) {
        model.clear();
        model.set(resp[0], options);
        options.onSuccess && options.onSuccess(model, resp);
      };
      self.abort();
      self.ajx = self.sync("read", self, {
        action: self.action,
        dataType: "html",
        onSuccess: onSuccess,
        processAjaxResponse: function (resp) {
          var data;
          if (resp.indexOf("<?xml") !== -1) {
            throw new Error("chart data response not csv");
          }
          data = d3.csv.parse.call(d3, resp);
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
    },
    abort: function () {
      if (this.ajx) {
        this.ajx.abort();
      }
    }
  });
  return T1.Models.Report;
});
