define('utils/campaignListStateMaintainer',["Underscore", "jQuery"], function (_, $) {
  var StateMaintainer, searchLabelBeingRestored;
  StateMaintainer = function CampaignListStateMaintainer(campaigns) {
    var campaignCollection = campaigns;
    var numberOfMoreAction = 0;
    var appliedFilterData, listView;
    if (!campaignCollection) {
      throw new Error(`Incoming 'campaigns' arg in the constructor must be a valid object`);
    }
    function saveFilterData(filterData) {
      appliedFilterData = filterData;
    }
    function clearAllStates() {
      appliedFilterData = null;
      numberOfMoreAction = 0;
    }
    function registerMoreAction() {
      numberOfMoreAction++;
    }
    function getSavedStates() {
      return {
        filterData: appliedFilterData,
        moreActionCount: numberOfMoreAction
      };
    }
    function replicateMoreActions() {
      var timesToInvoke = numberOfMoreAction;
      var loadedPageCnt = 0;
      var pageSize = campaignCollection.pageLimit;
      var maxAllowed = 100;
      var deferred = $.Deferred();
      const delay = 50;
      const delay2 = 100;
      function recursiveAddMore(count) {
        if (count <= 0) {
          deferred.resolve();
          return;
        }
        loadedPageCnt = timesToInvoke - count;
        if (loadedPageCnt * pageSize >= maxAllowed) {
          deferred.resolve();
          return;
        }
        listView.addMoreHandler().then(function () {
          count--;
          setTimeout(function () {
            recursiveAddMore(count);
          }, delay);
        });
      }
      if (timesToInvoke > 0 && listView && listView.addMoreHandler) {
        setTimeout(function () {
          recursiveAddMore(timesToInvoke);
        }, delay2);
      } else {
        deferred.resolve();
      }
      return deferred.promise();
    }
    function setCampaignListView(view) {
      listView = view;
    }
    function restoreCampaignPanePosition(campaignId) {
      if (campaignId) {
        listView.ensureCampaignPaneVisible(campaignId);
      }
    }
    function restorePreviousState(isFavoriteMode, campaignId, opts) {
      var needToRestoreFilter = appliedFilterData !== null && appliedFilterData !== undefined;
      var needToRestoreMoreActions = numberOfMoreAction > 0;
      var self = this;
      var originalFilterData = appliedFilterData;
      var originalMoreActionCount = numberOfMoreAction;
      var $doc = $(document);
      var successCallback = function () {
        if (needToRestoreMoreActions === true) {
          self.replicateMoreActions().then(function () {
            restoreCampaignPanePosition(campaignId);
          });
        } else {
          restoreCampaignPanePosition(campaignId);
        }
      };
      $doc.scrollTop(0);
      if (needToRestoreFilter === true) {
        searchLabelBeingRestored = originalFilterData.label;
        campaignCollection.applyFilter(appliedFilterData).then(function () {
          if (needToRestoreMoreActions === true) {
            self.replicateMoreActions().then(function () {
              restoreCampaignPanePosition(campaignId);
            });
          } else {
            restoreCampaignPanePosition(campaignId);
          }
          searchLabelBeingRestored = undefined;
        });
      } else {
        if (isFavoriteMode === true) {
          campaignCollection.fetchFavorites({
            success: successCallback
          });
        } else {
          if (opts) {
            const newOpts = $.extend({}, opts, {
              success: successCallback
            });
            campaignCollection.fetch(newOpts);
          } else {
            campaignCollection.fetch({
              success: successCallback
            });
          }
        }
      }
      appliedFilterData = originalFilterData;
      numberOfMoreAction = originalMoreActionCount;
    }
    return {
      saveFilterData: saveFilterData,
      registerMoreAction: registerMoreAction,
      restorePreviousState: restorePreviousState,
      clearAllStates: clearAllStates,
      replicateMoreActions: replicateMoreActions,
      setCampaignListView: setCampaignListView,
      getSavedStates: getSavedStates
    };
  };
  StateMaintainer.getFilterSearchLabelBeingRestored = function () {
    return searchLabelBeingRestored;
  };
  return StateMaintainer;
});
