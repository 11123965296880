define('modules/targeting/contextual/main',["jQuery", "T1Module", "models/strategyTargetGroupedSegments", "collections/organizations", "collections/strategyRetiredTargetSegments", "When"], function ($, T1Module, GroupedSegments, Organizations, StrategyRetiredTargetSegments, When) {
  "use strict";

  return new T1Module({
    name: "targeting/contextual",
    viewCfgs: {
      panel: {},
      browse: {},
      bins: {},
      readOnly: {
        model: null
      }
    },
    defaultView: "panel",
    getView: function (type, opts) {
      let resultPromise;
      var self = this;
      const deferred = $.Deferred();
      if (type === "bins") {
        Organizations.getOrganizations().getSelected().then(function (id) {
          const org = Organizations.getOrganizations().get(id);
          org.fetchOptions = $.extend({
            full: "*"
          }, org.fetchOptions);
          org.fetch({
            success: function () {
              opts.allowBYOPriceEdit = org.get("allow_byo_price") === "1";
              T1Module.prototype.getView.call(self, type, opts).then(function (view) {
                deferred.resolve(view);
              });
            }
          });
        });
        resultPromise = deferred.promise();
      } else if (type === "panel") {
        const strategyTargetDimensionsDeferred = When.defer();
        const groupedSegmentsDeferred = When.defer();
        const retiredSegmentsDeferred = When.defer();
        const strategy = opts.strategy;
        const strategyVersion = strategy.get("version");
        const groupedSegments = new GroupedSegments({
          id: strategy.id,
          version: strategyVersion
        });
        const retiredSegments = new StrategyRetiredTargetSegments({
          version: strategyVersion
        });
        const currencyCode = strategy.getCurrencyCode();
        const promises = [strategyTargetDimensionsDeferred.promise, groupedSegmentsDeferred.promise, retiredSegmentsDeferred.promise];
        opts.partialCollection = groupedSegments.get("partials");
        opts.sharedCollection = groupedSegments.get("user_saved");
        opts.groupedSegments = groupedSegments;
        opts.sharedCollection.loaded = false;
        opts.retiredCollection = retiredSegments;
        opts.retiredCollection.loaded = false;
        strategy.targetDimensions = undefined;
        opts.strategyTargetDimensions = opts.strategy.fetchTargetDimensions({
          success: function () {
            strategyTargetDimensionsDeferred.resolve();
          }
        });
        groupedSegments.fetch({
          success: function () {
            opts.sharedCollection.loaded = true;
            groupedSegmentsDeferred.resolve();
          },
          currency_code: currencyCode
        });
        retiredSegments.strategyId = strategy.id;
        retiredSegments.fetchOptions.currency_code = currencyCode;
        retiredSegments.fetch({
          success: function () {
            retiredSegmentsDeferred.resolve();
          },
          action: "retired_targeting_segments"
        });
        When.all(promises).then(function () {
          T1Module.prototype.getView.call(self, type, opts).then(function (view) {
            deferred.resolve(view);
          });
        });
        return deferred.promise();
      } else {
        resultPromise = T1Module.prototype.getView.call(this, type, opts);
      }
      return resultPromise;
    }
  });
});
