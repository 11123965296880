define('modules/siteList/views/readOnly',["jQuery", "T1", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils"], function ($, T1, T1View, template, Utils) {
  "use strict";

  return T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function (args) {
      var self = this;
      var domainRestrictionObj;
      self.siteListCollection = args.collection;
      self.strategy = args.model;
      this.editLinkPrefix = args.editLinkPrefix;
      this.canEdit = args.canEdit || false;
      if (self.strategy) {
        domainRestrictionObj = self.strategy.getDomainRestrictions();
      }
      self.canLoad = false;
      self.siteListCollection.fetch({
        success: function () {
          if (domainRestrictionObj) {
            domainRestrictionObj.fetch({
              success: function () {
                self.canLoad = true;
                self.load();
              }
            });
          } else {
            self.canLoad = true;
            self.load();
          }
        }
      });
    },
    load: function () {
      var self = this;
      var loader = this.loader();
      var $el = $(self.el);
      self.render().then(function () {
        if (!self.canLoad || self.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          $el.find(".loader-holder").hide();
          if (!self.hasData) {
            $el.find(".section-heading").css({
              opacity: .5
            });
            $el.find(".no-selection").css({
              display: "inline-block"
            });
          }
        }
      });
    },
    serialize: function () {
      var self = this;
      var strategy = self.strategy;
      var selectivenessValTextHash = Utils.selectivenessDataDisplayHash;
      var siteList = {};
      var siteLists = [];
      var spaceEntity = "&nbsp;";
      var collObj = self.siteListCollection;
      var resultJSON = {};
      var displayDateFormat = "MM/dd/yyyy";
      var getDateDisplay = function (rawVal) {
        var tmpDt = rawVal ? Date.parse(rawVal) : null;
        if (tmpDt) {
          return tmpDt.toString(displayDateFormat);
        } else {
          return rawVal;
        }
      };
      var buildSingleFieldEntry = function (nameVal) {
        return {
          name: nameVal,
          type: spaceEntity,
          sites: spaceEntity,
          lastUpdated: spaceEntity,
          siteId: spaceEntity
        };
      };
      if (this.canEdit) {
        resultJSON.editLink = this.editLinkPrefix + "/targeting/siteList";
      }
      if (!self.canLoad) {
        return resultJSON;
      }
      if (collObj && collObj.models && collObj.models.length > 0) {
        $.each(collObj.models, function (index, model) {
          var isActive = model.get("status") === "1";
          if (model.get("assigned") === "1" && isActive) {
            siteList = {
              name: model.get("name"),
              type: model.get("restriction") === "INCLUDE" ? "AL" : "BL",
              sites: model.get("sites_count"),
              domains: model.get("sites_count_domain") || "0",
              apps: model.get("sites_count_app") || "0",
              lastUpdated: getDateDisplay(model.get("updated_on")),
              siteId: model.id
            };
            siteLists.push(siteList);
          }
        });
      }
      resultJSON.firstSideTitle = "applied site lists";
      resultJSON.dataMain = [{
        sideTitle: spaceEntity,
        items: siteLists,
        isFirstSideTitle: true
      }];
      if (strategy) {
        const domainRestrictions = strategy.getDomainRestrictions();
        const restrictBidIsChecked = strategy.get("site_restriction_transparent_urls") !== "0";
        if (domainRestrictions && domainRestrictions.data.count > 0) {
          resultJSON.dataMain[0].items.push(buildSingleFieldEntry("Additional Sites (" + domainRestrictions.data.count + ")"));
        }
        if (restrictBidIsChecked) {
          resultJSON.dataMain.push({
            sideTitle: "transparency targeting",
            items: [{
              name: "Enabled"
            }],
            isFirstSideTitle: false
          });
        }
      }
      if (resultJSON.dataMain[0].items.length <= 0) {
        resultJSON.dataMain[0].items.push(buildSingleFieldEntry("No site lists applied"));
      }
      self.hasData = true;
      resultJSON.hasData = self.hasData;
      return resultJSON;
    }
  });
});
