define('modules/media/views/tabs',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "models/media", "collections/organizations", "text!modules/media/templates/tabs.html", "text!modules/media/templates/header.html", "T1Layout", "T1TabLayoutV2", "utils/AdminUtils"], function ($, _, T1, T1View, T1Permissions, MarketsModel, Organizations, template, headTemplate, T1Layout, T1TabLayout, Utils) {
  "use strict";

  return T1View.extend({
    model: new MarketsModel(),
    template: template,
    partials: {
      header: headTemplate
    },
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    initialize: function () {
      Utils.updateBreadCrumb();
      this.initLayouts();
    },
    initLayouts: function () {
      var model = this.model;
      var tabs = [];
      this.pmpeEnabled = T1Permissions.check("feature", "markets_pmpe");
      this.pmpdEnabled = T1Permissions.check("feature", "markets_pmpd");
      this.closedSupplyEnabled = T1Permissions.check("feature", "markets_closed_supply");
      this.marketsEnabled = T1Permissions.check("feature", "markets") && (this.pmpeEnabled || this.pmpdEnabled || this.closedSupplyEnabled);
      if (this.pmpeEnabled) {
        tabs.push({
          label: "Deals",
          module: "media/pmp-e",
          routeName: "pmp-e",
          viewType: "",
          options: {}
        });
        if (T1Permissions.check("feature", "deal_analytics")) {
          tabs.push({
            label: "Analytics",
            module: "media/dealAnalytics",
            routeName: "dealAnalytics",
            viewType: "",
            options: {}
          });
        }
      }
      if (this.pmpdEnabled) {
        tabs.push({
          label: "PMP-D",
          module: "media/pmp-d",
          routeName: "pmp-d",
          viewType: "",
          options: {}
        });
      }
      if (this.closedSupplyEnabled) {
        tabs.push({
          label: "Closed Supply",
          module: "media/closedSupply",
          routeName: "closedSupply",
          viewType: "",
          options: {}
        });
      }
      this.layout = new T1TabLayout({
        tabSetName: "MarketsList",
        level: 1,
        disabled: [],
        tabs: tabs,
        ignoreRouteDepth: true,
        selected: model.get("mainTabSectionId") || 0
      });
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.el = $(self.el).find(".admin-tabs");
        self.layout.load();
      });
    },
    saveSelectedTab: function (opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, tab => tab.label === selectedLabel), tabs);
      this.model.setMainTabSection(selectedIndex);
    },
    serialize: function () {
      return {
        title: "Media",
        isMarketsEnabled: this.marketsEnabled
      };
    }
  });
});
