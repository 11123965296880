define('collections/strategyRetiredAudienceSegments',["jQuery", "T1", "T1Collection", "T1Model", "models/strategyRetiredAudienceSegment"], function ($, T1, T1Collection, T1Model, RetiredSegment) {
  var collection = T1Collection.extend({
    model: RetiredSegment,
    urlString: "strategies/",
    includedBinOperation: "OR",
    fixture: "-retiredAudienceSegments",
    fetchOptions: {
      full: "*",
      currency_code: "USD"
    },
    url: function () {
      var originalString = this.urlString;
      var url;
      this.urlString = originalString + this.strategyId + "/" + "retired_audience_segments";
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    parse: function (data) {
      var parsedData;
      var result = [];
      parsedData = T1Collection.prototype.parse.call(this, data);
      $.each(parsedData, function (index, value) {
        var retiredSegment;
        if (value.retired_audience_segment) {
          retiredSegment = value.retired_audience_segment;
          retiredSegment.action = value.restriction === "INCLUDE" ? "include" : "exclude";
          if (value.user_cpm) {
            retiredSegment.user_cpm = value.user_cpm;
          }
          retiredSegment.doNotDelete = false;
          result.push(retiredSegment);
        }
      });
      return result;
    },
    buildPostObjectForRetiredSegments: function (listOfIds) {
      var obj = {};
      var dataSet = this.models;
      var dataSetLen = dataSet.length;
      var retiredSegmentsStr = "retired_segments.";
      var idStr = ".id";
      var i;
      var j;
      var id;
      var sum = 0;
      if (listOfIds.length > 0) {
        for (i = 0; i < listOfIds.length; i++) {
          id = listOfIds[i];
          for (j = 0; j < dataSetLen; j++) {
            if (id === dataSet[j].get("id")) {
              dataSet[j].set({
                doNotDelete: true
              });
            }
          }
        }
      }
      if (dataSetLen > 0) {
        for (i = 0; i < dataSetLen; i++) {
          if (dataSet[i].get("doNotDelete") === false) {
            obj[retiredSegmentsStr + (sum += 1) + idStr] = dataSet[i].get("id");
          }
        }
        return obj;
      }
    }
  });
  T1.Models.StrategyRetiredAudienceSegments = collection;
  return T1.Models.StrategyRetiredAudienceSegments;
});
