define('modules/media/closedSupply/views/closedSupply',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Permissions", "models/sessionUser", "models/userPreferences", "text!../templates/closedSupply.html", "T1Signature"], function ($, _, T1, T1View, T1Layout, Permissions, User, UserPreferences, tmpl) {
  "use strict";

  var closedSupply = T1View.extend({
    template: tmpl,
    load: function () {
      this.render();
    }
  });
  return closedSupply;
});
