define('modules/creatives/componentBased/lib/uploadHandler',["jQuery", "Underscore", "When", "./unzipper", "models/concept", "utils/validatorComponentCreatives", "xlsx"], function ($, _, When, Unzipper, Concept, ComponentCreativeValidator) {
  "use strict";

  function invalidTemplateFileValidType(fileType) {
    var validSpreadsheetFormats = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
    var templateFileIsValid = _.contains(validSpreadsheetFormats, fileType);
    if (templateFileIsValid) {
      return false;
    } else {
      return "Creatives data file must be XLS or XLSX";
    }
  }
  function shouldReadCreativesSheet(rows, key) {
    var validNumberOfRows = rows && rows.length > 0;
    var sheetIsCreateEdit = key === "creativesList";
    return validNumberOfRows && sheetIsCreateEdit;
  }
  return {
    handleUploads: function (opts) {
      var self = this;
      var templateFile = opts.templateFile;
      var mediaFile = opts.mediaFile;
      var deferred = When.defer();
      self.selectedAdvertiser = opts.selectedAdvertiser;
      self.isEdit = opts.isEdit || false;
      self.creativesToEdit = opts.creativesToEdit;
      self.componentsMap = opts.componentsMap;
      self.templateErrType = "";
      ComponentCreativeValidator.isEdit = self.isEdit;
      self.workbookResult = {};
      self.promiseResult = {
        media: {},
        warningMsg: undefined,
        getMedia: function (key) {
          var file, matchedFiles;
          var fileNames = _.keys(this.media);
          var regex = /[^\\]*(?=[.][a-zA-Z]+$)/;
          if (typeof key === "string") {
            key = key.toLowerCase();
            file = this.media[key];
            if (file) {
              return file;
            } else {
              matchedFiles = _.filter(fileNames, function (name) {
                var match = name.match(regex) ? name.match(regex)[0] : null;
                return match === key;
              });
              if (matchedFiles.length === 1) {
                return this.media[matchedFiles[0]];
              } else if (matchedFiles.length > 1) {
                self.templateErrType = "invalid_file_reference";
              }
            }
          }
        },
        setForMedia: function (key, values) {
          key = key.toLowerCase();
          if (this.getMedia(key)) {
            _.extend(this.media[key], values);
          } else {
            this.media[key] = values;
          }
        }
      };
      if (mediaFile) {
        self.initHandle(deferred, templateFile, mediaFile)();
      } else {
        self.initHandle(deferred, templateFile)();
      }
      return deferred.promise;
    },
    composeErrorMessage: function (messages) {
      var fullMsg = _.compact(messages).join(" and ");
      fullMsg = fullMsg.charAt(0).toUpperCase() + fullMsg.slice(1);
      return fullMsg;
    },
    setVideoData: function (videoData, videoName) {
      var def = When.defer();
      this.promiseResult.setForMedia(videoName, {
        videoBinaryData: videoData,
        videoName: videoName.replace(/.*\//g, "")
      });
      def.resolve(videoName);
      return def.promise;
    },
    setBase64Data: function (mediaData, fileName) {
      var self = this;
      var def = When.defer();
      var imageDataForAPI;
      function validImageMimeType(base64Data) {
        var encodedTypes = {
          "/9J/2": "image/jpeg",
          "/9J/4": "image/jpeg",
          IVBOR: "image/png",
          R0LGO: "image/gif",
          SUKQA: "image/tiff",
          PD94B: "image/svg"
        };
        var mimeType = base64Data.slice(0, 5).toUpperCase();
        return encodedTypes[mimeType];
      }
      function isSRTFile(fName) {
        var ext = fName.split(".").pop();
        return ext === "srt";
      }
      if (mediaData.match("base64,")) {
        imageDataForAPI = mediaData.split("base64,")[1];
      } else {
        imageDataForAPI = mediaData;
      }
      if (validImageMimeType(imageDataForAPI) || isSRTFile(fileName)) {
        self.promiseResult.setForMedia(fileName, {
          encodedfile: imageDataForAPI,
          fileName: fileName
        });
        def.resolve(fileName);
      } else {
        def.resolve(false);
      }
      return def.promise;
    },
    initHandle: function (d, file, mediaFile) {
      var self = this;
      return function () {
        var mediaZipMaxSize = 4294967296;
        var mediaUploadWarningMsg, dataUploadWarningMsg;
        if (invalidTemplateFileValidType(file.type)) {
          dataUploadWarningMsg = "Creatives data file must be XLS or XLSX";
        }
        var fileFormatsThatAreReadAsZips = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        function isZipFile(fileType) {
          if (fileType === undefined) {
            return true;
          }
          return fileType === "application/zip" || fileType === "application/x-zip-compressed";
        }
        function mediaFileCorrectSize(mfile) {
          if (mfile === undefined) {
            return true;
          }
          return mfile.size <= mediaZipMaxSize;
        }
        var mediaFileType = mediaFile === undefined ? undefined : mediaFile.type;
        var mediaFileIsXLSX = _.contains(fileFormatsThatAreReadAsZips, mediaFileType);
        if (!isZipFile(mediaFileType) || mediaFileIsXLSX) {
          mediaUploadWarningMsg = "You must upload media in a ZIP file";
        } else if (!mediaFileCorrectSize(mediaFile)) {
          mediaUploadWarningMsg = "Media upload is too large; the max file size is 4GB";
        }
        if (dataUploadWarningMsg || mediaUploadWarningMsg) {
          self.promiseResult.warningMessages = {
            templateUpload: dataUploadWarningMsg,
            mediaUpload: mediaUploadWarningMsg
          };
          d.resolve(self.promiseResult);
        } else {
          var promise = When();
          try {
            if (mediaFile) {
              promise = Unzipper.getData(mediaFile).then(function (entries) {
                var mediaPromises = [];
                for (var i = 0; i < entries.length; i++) {
                  var entry = entries[i].value;
                  if (entry) {
                    var entryMetadata = entry.entryMetadata;
                    var key = entryMetadata.filename;
                    var isVideo = entryMetadata.isVideo;
                    if (!key.match(/DS_Store/) && !key.match("__MACOSX")) {
                      if (key.split("/")[1] === undefined) {
                        key = key.split("/")[0];
                      } else {
                        key = key.slice(key.indexOf("/") + 1, key.length);
                      }
                      if (isVideo) {
                        mediaPromises.push(self.setVideoData(entry.data, key));
                      } else {
                        mediaPromises.push(self.setBase64Data(entry.data, key));
                      }
                    }
                  }
                }
                return When.settle(mediaPromises);
              });
            }
            promise.then(function () {
              var reader = new FileReader();
              reader.readAsBinaryString(file);
              reader.onload = self.handleTemplate(d);
            });
            return promise;
          } catch (e) {
            d.reject(new Error("Could not read media ZIP"));
            return d.promise;
          }
        }
      };
    },
    handleTemplate: function (d) {
      var self = this;
      var newConcepts = {};
      return function () {
        var rowsExist = false;
        var workbook = XLSX.read(this.result, {
          type: "binary"
        });
        function formatDateForPost(val) {
          var dateFormat = "yyyy-MM-ddTHH:mm";
          var rgx = new RegExp(/^([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]$/);
          if (val && val !== "" && Date.parse(val) && rgx.test(val)) {
            return Date.parse(val).toString(dateFormat);
          }
          return null;
        }
        function parseConceptIdFromUserInput(input) {
          var splitConcept = input.split("(");
          var conceptId;
          var conceptIdSlice = splitConcept[splitConcept.length - 1];
          if (conceptIdSlice) {
            conceptId = conceptIdSlice.split(")")[0];
          }
          return conceptId;
        }
        function readSheet(sheetName) {
          try {
            return XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          } catch (e) {
            d.reject(new Error("Unable to read XLSX file"));
            return d.promise;
          }
        }
        function getTemplateErrorMsg() {
          var errMsgMap = {
            concept_creation: "Error creating new concepts.",
            cannot_create_on_edit_mode: "Only bulk-edit templates can be uploaded in edit-mode.",
            cannot_edit_on_create_mode: "Only bulk-create templates can be uploaded in create-mode.",
            invalid_file_reference: "Invalid media file reference"
          };
          if (self.templateErrType) {
            return errMsgMap[self.templateErrType];
          }
        }
        function processCreativesAndValidations() {
          var msg, current, newCreatives;
          workbook.SheetNames.forEach(processSheet);
          if (!rowsExist) {
            msg = "Your creative template is empty - ";
            msg = msg + "please refer to the knowledge base for instructions on how to enter creative data.";
            self.promiseResult.warningMsg = msg;
          } else {
            self.promiseResult.warningMsg = getTemplateErrorMsg();
          }
          newCreatives = self.workbookResult.creativesList || {
            creatives: []
          };
          for (var s = 0; s < newCreatives.creatives.length; s++) {
            current = newCreatives.creatives[s];
            current.validationErrors = ComponentCreativeValidator.validateAttributes(current.attributes, self.componentsMap);
          }
          if (self.promiseResult.warningMsg) {
            newCreatives.warningMessages = {
              templateUpload: self.promiseResult.warningMsg
            };
          }
          d.resolve(newCreatives);
          return d.promise;
        }
        function processSheet(sheetName) {
          var creativeRows = readSheet(sheetName);
          var sheetMetadata = getSheetMetadata(sheetName);
          var sheetKey = sheetMetadata.sheetKey;
          var sheetObject;
          var type = sheetMetadata.type;
          var creativeRowOffset = 2;
          if (shouldReadCreativesSheet(creativeRows, sheetKey)) {
            creativeRows.splice(0, creativeRowOffset);
            self.workbookResult[sheetKey] = {
              creatives: []
            };
            for (var index = 0; index < creativeRows.length; index++) {
              rowsExist = true;
              sheetObject = processSheetRows(creativeRows, index, type);
              self.workbookResult[sheetKey].creatives.push(sheetObject);
            }
          }
        }
        function processSheetRows(creativeRows, creativeIndex, type) {
          var attributes = creativeRows[creativeIndex];
          var reviewGridRowOffset = 4;
          var assets = [];
          var newConcept, currAdvertiser, matchedCreative, startDateTime, endDateTime;
          function createAssetModel(id, value, encodedFile, fileType) {
            var assetModel = {
              creativeIds: [],
              component_id: id
            };
            if (encodedFile) {
              assetModel[fileType + "_filename"] = value;
              assetModel["encoded_" + fileType + "_file"] = encodedFile;
            } else {
              assetModel.asset_value = value;
            }
            return assetModel;
          }
          function findExistingCreative(creativeId) {
            var existingCreative = _.find(self.creativesToEdit, function (c) {
              return c.get("atomic_creative_id") === parseInt(creativeId, 10);
            });
            return existingCreative;
          }
          function findAndApplyExistingAsset(creative, assetType, key) {
            const fileNameKey = assetType + "_filename";
            const existingCreativeAssets = creative.get("assets");
            let foundExistingImage = false;
            if (existingCreativeAssets) {
              for (let j = 0; j < existingCreativeAssets.length; j++) {
                if (existingCreativeAssets[j].asset_type === assetType && existingCreativeAssets[j][fileNameKey] === attributes[key]) {
                  assets.push(existingCreativeAssets[j]);
                  foundExistingImage = true;
                }
              }
            }
            if (!foundExistingImage) {
              self.templateErrType = "invalid_file_reference";
            }
          }
          function createConceptModel(name, advertiser_id) {
            var conceptModel = new Concept({
              status: "1",
              name: name,
              advertiser_id: advertiser_id
            });
            return conceptModel;
          }
          function nameHasId(name) {
            return /\(\d+\)$/.test(name);
          }
          function prepareBase64MediaForPost(attrs, key, mediaType) {
            const filePath = attrs[key];
            const fileMap = self.promiseResult.getMedia(filePath);
            const fileName = fileMap ? fileMap.fileName : filePath;
            const encodedFileKey = "encoded_" + mediaType + "_file";
            const filenameKey = mediaType + "_filename";
            attrs[encodedFileKey] = fileMap ? fileMap.encodedfile : null;
            attrs[filenameKey] = fileName.replace(/.*\//g, "");
            if (attrs[encodedFileKey]) {
              assets.push(createAssetModel(key, attrs[filenameKey], attrs[encodedFileKey], mediaType));
              delete attrs[encodedFileKey];
              delete attrs[key];
            } else if (matchedCreative) {
              findAndApplyExistingAsset(matchedCreative, mediaType, key);
            } else {
              self.templateErrType = "invalid_file_reference";
            }
          }
          if (self.isEdit) {
            matchedCreative = findExistingCreative(attributes.id);
          }
          if (attributes.concept) {
            if (!nameHasId(attributes.concept)) {
              if (!newConcepts[attributes.concept]) {
                currAdvertiser = self.isEdit ? matchedCreative.get("advertiser_id") : self.selectedAdvertiser.id;
                newConcepts[attributes.concept] = createConceptModel(attributes.concept, currAdvertiser);
              }
              newConcept = newConcepts[attributes.concept];
              attributes.concept_id = "Not yet created";
              attributes.concept = attributes.concept + " (Not yet created)";
            } else {
              attributes.concept_id = parseConceptIdFromUserInput(attributes.concept);
            }
          } else if (!self.isEdit && !attributes.concept) {
            attributes.concept_id = "Not yet created";
            newConcept = createConceptModel(attributes.creative_name, self.selectedAdvertiser.id);
            attributes.concept = attributes.creative_name + " (Not yet created)";
          }
          if (attributes.start_date) {
            startDateTime = attributes.start_date;
            attributes.start_date = formatDateForPost(startDateTime);
          } else if (matchedCreative && matchedCreative.get("start_date")) {
            attributes.start_date = "";
          }
          if (attributes.end_date) {
            endDateTime = attributes.end_date;
            attributes.end_date = formatDateForPost(endDateTime);
          } else if (matchedCreative && matchedCreative.get("end_date")) {
            attributes.end_date = "";
          }
          for (var key in attributes) {
            if (attributes.hasOwnProperty(key)) {
              switch (self.componentsMap[key] || key) {
                case "id":
                case "creative_name":
                case "concept":
                case "concept_id":
                case "start_date":
                case "end_date":
                  break;
                case "IMAGE":
                case "SUBTITLE":
                  prepareBase64MediaForPost(attributes, key, self.componentsMap[key].toLowerCase());
                  break;
                case "VIDEO":
                  const videoPath = attributes[key];
                  const videoObj = self.promiseResult.getMedia(videoPath);
                  const videoAsset = createAssetModel(key, "");
                  if (videoObj) {
                    attributes.videoObject = videoObj ? videoObj : null;
                    attributes.videoAsset = videoAsset;
                  } else if (matchedCreative) {
                    findAndApplyExistingAsset(matchedCreative, "video", key);
                  } else {
                    self.templateErrType = "invalid_file_reference";
                  }
                  break;
                default:
                  assets.push(createAssetModel(key, attributes[key]));
                  delete attributes[key];
                  break;
              }
            }
          }
          attributes.assets = JSON.stringify(assets);
          if (!self.isEdit) {
            attributes.advertiser_id = self.selectedAdvertiser.id;
          }
          if (!self.isEdit && attributes.id) {
            self.templateErrType = "cannot_edit_on_create_mode";
          }
          if (self.isEdit && !attributes.id) {
            self.templateErrType = "cannot_create_on_edit_mode";
          }
          return {
            type: type,
            row: creativeIndex + reviewGridRowOffset,
            attributes: attributes,
            validationErrors: [],
            newConcept: newConcept
          };
        }
        function getSheetMetadata(sheetName) {
          var sheetKey, type;
          switch (sheetName) {
            case "Creatives":
              sheetKey = "creativesList";
              break;
            case "Concepts":
              sheetKey = "conceptList";
              break;
          }
          return {
            sheetKey: sheetKey,
            type: type
          };
        }
        processCreativesAndValidations();
      };
    }
  };
});
