define('modules/T1App/myApps/models/myApps',["jQuery", "Underscore", "T1", "T1Model", "modules/shared/configs/charts/lineChartOneAxisSeriesConfig", "modules/reporting/campaigns/models/additionalData", "moment", "models/sessionUser"], function ($, _, T1, T1Model, ChartConfig, additionalData, moment, User) {
  "use strict";

  return T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    url: "v1.0/apps",
    dataType: "json",
    defaultOffset: -7,
    sessionModel: new (T1Model.extend({
      url: "session/clone",
      fixture: "-t1AppSessionID"
    }))(),
    setData: function (rawData) {
      this.setChartData(rawData);
      this.setGridData(rawData);
      this.trigger("change:chartGridData");
    },
    setChartData: function (rawData) {
      let data = rawData.data;
      let colorIndex = 0;
      let dataProvider = [];
      let graphs = [];
      let config;
      const COLOR_WHITE = "#FFFFFF";
      const COLORS = additionalData.chartColors;
      function tooltipFunction(item) {
        return '<span style="font-family:AB">' + item.graph.title + "</span><br><br>" + "<div><span>Views: " + item.values.value + "</span></div>" + "<div><span>" + moment(item.category).format("ddd, MMM D, YYYY") + "</span></div>";
      }
      if (data && data.length > 0) {
        config = ChartConfig.copy();
        _.each(data[0].dataset, function (dateObj) {
          dataProvider.push({
            date: dateObj.label
          });
        });
        _.each(dataProvider, function (rowData) {
          _.each(data, function (item) {
            if (item.total_views !== 0) {
              _.each(item.dataset, function (viewData) {
                if (rowData.date === viewData.label) {
                  rowData[item.id] = viewData.views;
                }
              });
            }
          });
        });
        _.each(data, function (item, index) {
          if (index % 29 === 0) {
            colorIndex = 0;
          }
          if (item.total_views !== 0) {
            graphs.push({
              title: item.name,
              balloonFunction: tooltipFunction,
              bullet: "round",
              bulletSize: 6,
              bulletBorderColor: COLOR_WHITE,
              bulletBorderAlpha: 1,
              bulletBorderThickness: 1.5,
              hideBulletsCount: 80,
              lineColor: COLORS[colorIndex],
              lineThickness: 1.5,
              type: "smoothedLine",
              valueField: item.id
            });
          }
          colorIndex++;
        });
        config.dataProvider = dataProvider;
        config.graphs = graphs;
        config.valueAxes[0].labelFunction = value => value.toLocaleString();
        config.valueAxes[0].title = "Views";
        config.export = {};
      }
      this.set({
        chartData: config
      }, {
        silent: true
      });
    },
    setGridData: function (data) {
      var processedData = data.data;
      var appColor = additionalData.chartColors;
      var showEmptyGrid = false;
      var colorIndex = 0;
      var user = User.getUser();
      var isInternalUser = user.get("type") === "INTERNAL";
      var isGlobalAdmin = user.get("type") === "INTERNAL" && user.get("role") === "ADMIN" && user.get("scope") === "GLOBAL";
      if (processedData.length > 0) {
        for (var i = 0; i < processedData.length; i++) {
          processedData[i].total_views = processedData[i].total_views.toLocaleString();
          if (processedData[i].version_id === "") {
            processedData[i].version_id = "--";
          }
          if (processedData[i].dev_team) {
            var dev_team = "";
            var dev_ids = [];
            for (var j = 0, len = processedData[i].dev_team.length; j < len; j++) {
              dev_ids.push(processedData[i].dev_team[j].id);
              if (processedData[i].dev_team[j].name === "NULL") {
                dev_team += "#" + processedData[i].dev_team[j].id;
              } else if (processedData[i].dev_team[j].name) {
                dev_team += processedData[i].dev_team[j].name;
              } else {
                dev_team += "-- ";
              }
              if (j !== len - 1) {
                dev_team += ", ";
              }
            }
            processedData[i].dev_team = dev_team;
            processedData[i].dev_ids = dev_ids;
          } else {
            processedData[i].dev_team = "--";
            processedData[i].dev_ids = [];
          }
          if (i % 29 === 0) {
            colorIndex = 0;
          }
          processedData[i].appColor = appColor[colorIndex];
          colorIndex = colorIndex + 1;
          if (processedData[i].review_status === "pending" || processedData[i].review_status === "rejected") {
            processedData[i].review_boolean = true;
            if (processedData[i].review_status === "pending") {
              processedData[i].review_pending = true;
            }
            if (processedData[i].review_status === "rejected") {
              processedData[i].review_rejected = true;
            }
          }
          if (processedData[i].review_comments) {
            if ($.trim(processedData[i].review_comments.length) > 180) {
              processedData[i].rejected_more = true;
              processedData[i].review_comments = $.trim(processedData[i].review_comments).substring(0, 177) + "...";
            }
          }
          if (!isGlobalAdmin && $.inArray(parseInt(user.id), dev_ids) < 0) {
            processedData[i].cog = "display:none";
          }
          if (isInternalUser) {
            if (processedData[i].lab === 0) {
              processedData[i].appType = "App";
            } else {
              processedData[i].appType = "Lab";
            }
          }
        }
      } else {
        showEmptyGrid = true;
        processedData = [];
        if (!isInternalUser) {
          processedData.push({
            name: "--",
            version_id: "--",
            status: "--",
            deploy_status: "--",
            dev_team: "--",
            dev_ids: [],
            total_views: "--",
            appColor: "#ececec",
            cog: "display:none;"
          });
        } else {
          processedData.push({
            name: "--",
            version_id: "--",
            status: "--",
            deploy_status: "--",
            dev_team: "--",
            dev_ids: [],
            total_views: "--",
            appColor: "#ececec",
            cog: "display:none;"
          });
        }
      }
      processedData.showEmptyGrid = showEmptyGrid;
      processedData.isInternalUser = isInternalUser;
      this.set({
        gridData: processedData
      }, {
        silent: true
      });
    },
    getDefaultDate: function () {
      var _parseDate = T1.Date.parse;
      var YESTERDAY = "yesterday";
      var reportStart = _parseDate(YESTERDAY).add(-7).days();
      var reportEnd = _parseDate(YESTERDAY);
      return {
        startDate: reportStart,
        endDate: reportEnd
      };
    },
    loadClonedSessionID: function () {
      var self = this;
      self.sessionModel.save({
        api_key: T1.API_KEY
      }, {
        onSuccess: function (data) {
          self.sessionModel.set({
            sessionID: data.session.sessionid
          });
          self.trigger("change:T1AppsSessionID");
        }
      });
    },
    getDataPoints: function (data) {
      var dataPoints = [];
      for (var h = 0; h < data.length; h++) {
        dataPoints.push(data[h].views);
      }
      return dataPoints;
    },
    getDefaultStartDate: function () {
      return Date.parse("yesterday").add(this.defaultOffset).days();
    },
    getDefaultEndDate: function () {
      return Date.parse("yesterday");
    },
    setStartAndEndDate: function (startDate, endDate) {
      this.set({
        startDate: startDate,
        endDate: endDate
      }, {
        silent: true
      });
      this.trigger("change:start_end");
    },
    getStartAndEndDate: function () {
      var startDate = this.get("startDate");
      var endDate = this.get("endDate");
      var defaultDates = this.getDefaultDate();
      return {
        startDate: startDate ? startDate : defaultDates.startDate,
        endDate: endDate ? endDate : defaultDates.endDate
      };
    },
    setCollectionFetchParams: function (fetchParams, urlSuffix) {
      this.set({
        collectionFetchParams: fetchParams,
        collectionUrlSuffix: urlSuffix || ""
      }, {
        silent: true
      });
    },
    setMainTabSection: function (sectionId) {
      this.set({
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    },
    createEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: resp.meta === "success" ? "ok" : "error"
      };
    }
  });
});
