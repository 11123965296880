define('modules/reporting/campaigns/views/selectCampaign',['require','jQuery','Underscore','collections/advertisers','collections/agencies','collections/campaigns','collections/organizations','modules/reporting/campaigns/utils/reportingUtils','T1','T1View','text!../templates/selectCampaign.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const Advertisers = require("collections/advertisers");
  const Agencies = require("collections/agencies");
  const Campaigns = require("collections/campaigns");
  const Organizations = require("collections/organizations");
  const ReportUtils = require("modules/reporting/campaigns/utils/reportingUtils");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/selectCampaign.html");
  const dateFormat = "yyyy-MM-dd";
  const displayFormat = "MMM dd, yyyy";
  const limitedReports = {
    contextual_insights: 90,
    device_technology: 90,
    geo: 365,
    site_transparency: 365
  };
  function getDate(daysAgo) {
    return moment().add(-daysAgo, "days")._d;
  }
  function getDateText(isCTD, startDate, endDate, format) {
    return isCTD ? {
      startDateText: "Campaign",
      endDateText: "To Date"
    } : {
      startDateText: startDate.toString(format),
      endDateText: endDate.toString(format)
    };
  }
  function getInitialDates({
    reportModel: reportModel
  }) {
    const startDate = reportModel.get("startDate");
    return startDate ? {
      startDate: Date.parse(startDate),
      endDate: Date.parse(reportModel.get("endDate"))
    } : {
      startDate: ReportUtils.defaultStartDate,
      endDate: ReportUtils.defaultEndDate
    };
  }
  function requiredModels() {
    throw new Error("Reporting models required for Campaign Selector");
  }
  return T1View.extend({
    template: template,
    currentInterval: "last_30_days",
    dataEvents: {
      mainModel: {
        "change:campaignAccess": "updateEditCampaignLink",
        "change:section": "setRangePresetsUI"
      }
    },
    events: {
      "changed .advertiser-search-input": "searchAdvertisers",
      "changed .agency-search-input": "searchAgencies",
      "changed .campaign-search-input": "searchCampaigns",
      "changed .interval-ddl": "changeintervalDDL",
      "click .active-campaigns-checkbox": "toggleCampaigns",
      "click .all-search-results": "checkAllSearchedItems",
      "click .all": "checkAll",
      "click .apply-btn": "selectCampaigns",
      "click .campaign-checkbox": "checkItem",
      "click .dates span": "openDatePickerHandler",
      "mouseout .dates span": "removeHoverState",
      "mouseover .dates span": "addHoverState",
      "open .advertiser-selector": "clearSearch",
      "open .agency-selector": "clearSearch",
      "open .campaign-selector": "open",
      "saved .date-picker": "setDates",
      "selected .advertiser-selector": "loadCampaigns",
      "selected .agency-selector": "loadAdvertisers",
      "selected .date-picker": "presetHandler"
    },
    initialize({
      models = requiredModels()
    }) {
      const {
        mainModel: mainModel,
        campaign: campaign,
        reportModel: reportModel
      } = models;
      Object.assign(this, {
        advertiserColl: new Advertisers(),
        agencyColl: new Agencies(),
        campaign: campaign,
        campaignColl: new Campaigns(),
        isCTD: reportModel.get("isCTD"),
        mainModel: mainModel,
        models: models,
        section: mainModel.get("section")
      });
      this.setupCollections();
      this.initializeDates();
    },
    load() {
      this.section = this.mainModel.get("section");
      this.isCTD = this.models.reportModel.get("isCTD");
      this.render().then(() => {
        Object.assign(this, {
          activeCampaignsCheckbox: this.$(".active-campaigns-checkbox"),
          advertiserSpinner: this.$(".advertiser-spinner"),
          agencySpinner: this.$(".agency-spinner"),
          campaignDDL: this.$(".campaign-selector"),
          campaignSpinner: this.$(".campaign-spinner"),
          editCampaignLink: this.$(".edit-campaign-link"),
          intervalDDL: this.$(".interval-ddl")
        });
        this.loadAgencies();
        this.$(".agency-selector").attr("disabled", "disabled");
        this.$(".advertiser-selector").attr("disabled", "disabled");
        this.campaignDDL.attr("disabled", "disabled");
        this.intervalDDL.attr("disabled", "disabled");
        this.activeCampaignsCheckbox.attr("disabled", "disabled");
        if (this.section !== "reach_frequency") {
          this.initializeDates();
          this.updateDate();
        }
        this.toggleDatePickerUI();
        setTimeout(() => {
          const datePicker = this.datePicker = this.$(".date-picker")[0];
          datePicker.rangePresets = this.rangePresets;
          datePicker.target = this.$(".dates")[0];
          datePicker.closeLabel = "Cancel";
        }, 0);
      });
    },
    setupCollections() {
      Object.assign(this.agencyColl, {
        canCache: true,
        entityType: "agency",
        isPaginated: false
      });
      Object.assign(this.advertiserColl, {
        canCache: true,
        entityType: "advertiser",
        fetchOptions: {
          sort_by: "-status,name"
        },
        isPaginated: false
      });
      Object.assign(this.campaignColl, {
        canCache: true,
        entityType: "campaign",
        fetchOptions: {
          sort_by: "-status,name",
          full: "*"
        },
        isPaginated: false
      });
    },
    clearSearch(evt) {
      $(evt.currentTarget).find("strand-input")[0].clearInput();
    },
    addHoverState(event) {
      $(event.currentTarget).parent().addClass("onhover");
    },
    removeHoverState(event) {
      $(event.currentTarget).parent().removeClass("onhover");
    },
    openDatePickerHandler() {
      const datePicker = this.datePicker;
      const today = new Date();
      datePicker.end = this.endDate;
      datePicker.start = this.startDate;
      datePicker.allowedEnd = today;
      if (limitedReports[this.section]) {
        datePicker.allowedStart = getDate(limitedReports[this.section]);
      }
      if (this.isCTD) {
        datePicker.startEnabled = false;
        datePicker.endEnabled = false;
      }
      datePicker.open();
    },
    presetHandler(event) {
      const datePicker = this.datePicker;
      if (event.originalEvent.detail.name === "Campaign To Date") {
        datePicker.startEnabled = false;
        datePicker.endEnabled = false;
        this.isCTD = true;
      } else {
        datePicker.startEnabled = true;
        datePicker.endEnabled = true;
        this.isCTD = false;
      }
    },
    updateDate() {
      const {
        startDateText: startDateText,
        endDateText: endDateText
      } = getDateText(this.isCTD, this.startDate, this.endDate, displayFormat);
      this.$(".start-date").text(startDateText);
      this.$(".end-date").text(endDateText);
    },
    setDates(event) {
      const origEvent = event.originalEvent;
      const eventData = origEvent.detail;
      const isCTD = origEvent.target.rangeValue === "Campaign To Date";
      const newStartDate = moment(eventData.start).toDate();
      const newEndDate = moment(eventData.end).toDate();
      if (this.startDate.toString(dateFormat) !== newStartDate.toString(dateFormat) || this.endDate.toString(dateFormat) !== newEndDate.toString(dateFormat) || this.isCTD !== isCTD) {
        this.startDate = newStartDate;
        this.endDate = newEndDate;
        this.isCTD = isCTD;
        this.toggleApplyButton();
      }
      this.updateDate();
    },
    initializeDates() {
      const {
        startDate: startDate,
        endDate: endDate
      } = getInitialDates(this.models);
      this.initializeRangePresets();
      this.startDate = startDate;
      this.endDate = endDate;
    },
    initializeRangePresets() {
      const oneWeek = 7;
      const twoWeeks = 14;
      const oneMonth = 30;
      const yesterday = getDate(1);
      const weekAgo = getDate(oneWeek);
      const twoWeeksAgo = getDate(twoWeeks);
      const monthAgo = getDate(oneMonth);
      this.rangePresets = [{
        name: "Yesterday",
        value: "Yesterday",
        start: yesterday,
        end: yesterday
      }, {
        name: "Last 7 Days",
        value: "Last 7 Days",
        start: weekAgo,
        end: yesterday
      }, {
        name: "Last 14 Days",
        value: "Last 14 Days",
        start: twoWeeksAgo,
        end: yesterday
      }, {
        name: "Last 30 Days",
        value: "Last 30 Days",
        start: monthAgo,
        end: yesterday
      }];
      if (limitedReports[this.section] && this.section !== "contextual_insights") {
        this.rangePresets.push({
          name: "Campaign To Date",
          value: "Campaign To Date",
          start: getDate(limitedReports[this.section] + 1),
          end: yesterday
        });
      }
    },
    setRangePresetsUI() {
      this.section = this.models.mainModel.get("section");
      if (this.section !== "reach_frequency") {
        this.isCTD = this.models.reportModel.get("isCTD");
        this.initializeDates();
        if (!this.datePicker) {
          this.datePicker = this.$(".date-picker")[0];
        }
        this.datePicker.rangePresets = this.rangePresets;
        this.renderPartial(".dates");
      }
      this.toggleDatePickerUI();
    },
    toggleDatePickerUI() {
      if (this.section === "reach_frequency") {
        const intervalDDL = this.$(".interval-ddl");
        intervalDDL.removeClass("no-display");
        intervalDDL[0].value = this.models.reportModel.get("interval") || this.currentInterval;
        this.$(".selected-date").addClass("no-display");
      } else {
        this.$(".interval-ddl").addClass("no-display");
        this.$(".selected-date").removeClass("no-display");
      }
    },
    changeintervalDDL(event) {
      const selectedInterval = event.originalEvent.detail.value;
      if (this.currentInterval !== selectedInterval) {
        this.toggleApplyButton();
      }
    },
    loadAgencies() {
      const collection = this.agencyColl;
      function updateDisplay() {
        this.renderPartial(".agency-container");
        if (this.campaign.get("id")) {
          const selectedAdvertiser = this.campaign.get("advertiser");
          this.selectedAdvertiserID = selectedAdvertiser.id;
          this.selectedAgencyID = selectedAdvertiser.get("agency").id;
        }
        this.agencySpinner.addClass("no-display");
        setTimeout(() => {
          const $agencyDDL = this.$(".agency-selector");
          if (!this.agencies.length) {
            $agencyDDL[0].set("value", "no-data");
            $agencyDDL.attr("disabled", "disabled");
          }
          if (this.agencies.length === 1) {
            $agencyDDL[0].set("value", this.agencies[0].value);
            $agencyDDL.attr("disabled", "disabled");
          }
          if (this.selectedAgencyID) {
            $agencyDDL[0].set("value", this.selectedAgencyID);
          }
          $agencyDDL.removeAttr("disabled");
        }, 0);
      }
      this.agencySpinner.removeClass("no-display");
      if (collection.length) {
        updateDisplay.call(this);
      } else {
        Organizations.getOrganizations().getSelected().then(function (orgId) {
          collection.urlFilter.set({
            entity: "organization",
            id: orgId
          });
        });
        collection.fetch({
          success: response => {
            const models = response && response.models ? response.models : [];
            this.agencies = models.reduce((arr, {
              attributes: attributes
            }) => {
              if (attributes) {
                arr.push({
                  text: attributes.name,
                  value: attributes.id
                });
              }
              return arr;
            }, []);
            updateDisplay.call(this);
          }
        });
      }
    },
    searchAgencies() {
      let searchTerm = this.$(".agency-search-input").val();
      let searchItemCount = 0;
      const agencies = this.agencies;
      const $noResult = this.$(".agency-no-result");
      searchTerm = searchTerm.toLowerCase();
      if (agencies && agencies.length) {
        $noResult.addClass("no-display");
        this.$(".agency-item").addClass("no-display");
        _.each(agencies, item => {
          if (item.value.includes(searchTerm) || item.text.toLowerCase().includes(searchTerm)) {
            searchItemCount++;
            this.$(`.agency-item[data-id="${item.value}"]`).removeClass("no-display");
          }
        });
        if (searchItemCount === 0) {
          $noResult.removeClass("no-display");
          $noResult.text(`No results found for "${searchTerm}"`);
        }
      }
      this.updateHeight(searchItemCount, this.$(".agency-selector div#list"));
    },
    loadAdvertisers(event) {
      let isNewData = false;
      const collection = this.advertiserColl;
      const selectedAgencyID = event.originalEvent.detail.value;
      function updateDisplay(data) {
        const models = data && data.models ? data.models : [];
        this.advertisers = models.reduce((arr, {
          attributes: attributes
        }) => {
          if (attributes) {
            arr.push({
              text: attributes.name,
              value: attributes.id
            });
          }
          return arr;
        }, []);
        this.$(".advertiser-selector").removeAttr("disabled");
        this.campaignDDL.attr("disabled", "disabled");
        this.activeCampaignsCheckbox.attr("disabled", "disabled");
        this.editCampaignLink.attr("disabled", "disabled");
        this.renderPartial(".advertiser-container");
        this.advertiserSpinner.addClass("no-display");
        this.campaigns = [];
        this.updateCampaignDDL();
        setTimeout(() => {
          const $advertiserDDL = this.$(".advertiser-selector");
          this.updateCampaignLabel();
          this.toggleApplyButton();
          if (!this.advertisers.length) {
            $advertiserDDL[0].set("value", "no-data");
            $advertiserDDL.attr("disabled", "disabled");
          } else if (this.advertisers.length === 1) {
            $advertiserDDL[0].set("value", this.advertisers[0].value);
          }
          if (this.selectedAdvertiserID && selectedAgencyID === this.selectedAgencyID) {
            $advertiserDDL[0].set("value", this.selectedAdvertiserID);
          }
        }, 0);
      }
      this.advertiserSpinner.removeClass("no-display");
      if (this.selectedAgencyID !== selectedAgencyID) {
        this.selectedAgencyID = selectedAgencyID;
        isNewData = true;
      }
      if (collection.length && !isNewData) {
        updateDisplay.call(this, collection);
      } else {
        collection.urlFilter.set({
          entity: "agency",
          id: selectedAgencyID
        });
        collection.fetch({
          success: response => {
            updateDisplay.call(this, response);
          }
        });
      }
    },
    searchAdvertisers() {
      let searchTerm = this.$(".advertiser-search-input").val();
      let searchItemCount = 0;
      const advertisers = this.advertisers;
      const $noResult = this.$(".advertiser-no-result");
      searchTerm = searchTerm.toLowerCase();
      if (advertisers && advertisers.length) {
        $noResult.addClass("no-display");
        this.$(".advertiser-item").removeClass("no-display");
        _.each(advertisers, item => {
          if (item.value.includes(searchTerm) || item.text.toLowerCase().includes(searchTerm)) {
            searchItemCount++;
          } else {
            this.$(`.advertiser-item[data-id="${item.value}"]`).addClass("no-display");
          }
        });
        if (searchItemCount === 0) {
          $noResult.removeClass("no-display");
          $noResult.text(`No results found for "${searchTerm}"`);
        }
      }
      this.updateHeight(searchItemCount, this.$(".advertiser-selector div#list"));
    },
    loadCampaigns(event) {
      let isNewData = false;
      const collection = this.campaignColl;
      const showActiveCampaigns = this.activeCampaignsCheckbox.attr("checked");
      const selectedAdvertiserID = event.originalEvent.detail.value;
      function updateDisplay(response) {
        const models = response && response.models ? response.models : [];
        const mainModel = this.mainModel;
        let filteredCampaignItems = mainModel.get("filteredCampaignItems");
        const lookup = {};
        this.campaigns = [];
        this.allCampaigns = [];
        if (!filteredCampaignItems) {
          const reportId = mainModel.get("reportId");
          filteredCampaignItems = {};
          if (reportId) {
            const reportIdArr = reportId.split(",");
            _.each(reportIdArr, function (id) {
              filteredCampaignItems[id] = true;
            });
          }
        }
        _.each(models, model => {
          const campaignId = model.get("id");
          const campaignStatus = model.get("status");
          const item = {
            id: campaignId,
            name: model.get("name"),
            currencyCode: model.get("currency_code"),
            selected: filteredCampaignItems[campaignId] === true,
            statusClass: campaignStatus === "1" ? "active" : "inactive",
            state: campaignStatus
          };
          if (!filteredCampaignItems[campaignId]) {
            filteredCampaignItems[campaignId] = false;
          }
          if (showActiveCampaigns === "checked") {
            if (campaignStatus === "1") {
              this.campaigns.push(item);
            }
          } else {
            this.campaigns.push(item);
          }
          this.allCampaigns.push(item);
          lookup[campaignId] = item;
        });
        this.campaigns = this.sortItems(this.campaigns);
        mainModel.setCampaignItems(this.campaigns, lookup);
        if (filteredCampaignItems) {
          mainModel.setFilteredCampaignItems(filteredCampaignItems);
        }
        this.campaignDDL.removeAttr("disabled");
        this.intervalDDL.removeAttr("disabled");
        this.editCampaignLink.attr("disabled", "disabled");
        this.activeCampaignsCheckbox.removeAttr("disabled");
        this.renderPartial(".campaign-selector");
        this.campaignSpinner.addClass("no-display");
        if (this.campaign.get("id")) {
          setTimeout(() => {
            this.updateCampaignDDLUI();
            this.updateCampaignLabel(true);
          }, 0);
        }
        setTimeout(() => {
          this.campaignPanel = this.$("strand-scroll-panel");
          this.updateHeight(this.campaigns.length, this.campaignPanel, true);
          this.updateCampaignDDLState();
          this.toggleApplyButton();
        }, 0);
      }
      this.campaignSpinner.removeClass("no-display");
      if (this.advertisers.length === 1) {
        this.$(".advertiser-selector").attr("disabled", "disabled");
      }
      if (this.selectedAdvertiserID !== selectedAdvertiserID) {
        this.selectedAdvertiserID = selectedAdvertiserID;
        isNewData = true;
      }
      if (collection.length && !isNewData) {
        updateDisplay.call(this, collection);
      } else {
        collection.urlFilter.set({
          entity: "advertiser",
          id: selectedAdvertiserID
        });
        collection.fetch({
          success: response => {
            updateDisplay.call(this, response);
          }
        });
      }
    },
    updateCampaignDDLState() {
      if (!this.campaigns.length) {
        this.$(".campaign-label")[0].innerHTML = "No campaigns found";
        this.campaignDDL.attr("disabled", "disabled");
      } else if (this.campaigns.length === 1) {
        const campaign = this.$(".campaign-checkbox:not(.no-display)")[0];
        const campaignId = campaign.getAttribute("data-id");
        const displayEditCampaignLink = campaignId === this.mainModel.get("reportId");
        campaign.setAttribute("checked", "checked");
        this.updateCampaignLabel(displayEditCampaignLink);
        this.campaignDDL.attr("disabled", "disabled");
      }
    },
    selectCampaigns() {
      const campaigns = this.$(".campaign-checkbox:not(.no-display)");
      const selectedCampaigns = this.$(".campaign-checkbox[checked]:not(.no-display)");
      const checkedItems = {};
      const mainModel = this.mainModel;
      const curCampaignStr = mainModel.get("reportId");
      const publish = T1.EventHub.publish;
      _.each(campaigns, function (campaign) {
        checkedItems[campaign.getAttribute("data-id")] = false;
      });
      _.each(selectedCampaigns, function (selectedCampaign) {
        checkedItems[selectedCampaign.getAttribute("data-id")] = true;
      });
      _.each(this.allCampaigns, function (campaign) {
        campaign.selected = checkedItems[campaign.id];
      });
      const campaignStr = mainModel.setFilteredCampaignItems(checkedItems);
      const checkedCount = mainModel.get("filteredCampaignCount");
      const searchTerm = this.$(".campaign-search-input").val().toLowerCase();
      if (searchTerm !== "") {
        this.searchMode = true;
        this.searchTerm = searchTerm;
      } else {
        this.searchMode = false;
      }
      if (curCampaignStr !== campaignStr) {
        if (checkedCount > 0) {
          publish("select:campaign", {
            id: campaignStr
          });
          publish("track:event", {
            action: "Choose campaign",
            value: checkedCount
          });
          this.updateCampaignLabel(true);
        } else {
          throw new Error("No selection made in campaign filter list");
        }
      }
      this.close();
      if (this.section !== "reach_frequency") {
        this.models.reportModel.setStartAndEndDate(this.startDate, this.endDate);
        this.models.reportModel.set({
          isCTD: this.isCTD
        });
        const {
          startDateText: startDateText,
          endDateText: endDateText
        } = getDateText(this.isCTD, this.startDate, this.endDate, dateFormat);
        this.campaign.set({
          start: startDateText,
          end: endDateText,
          isCTD: this.isCTD
        });
        this.campaign.trigger("change:start_end");
        T1.EventHub.publish("reports:saveDate");
      } else {
        const selectedInterval = this.$(".interval-ddl")[0].value;
        if (this.currentInterval !== selectedInterval) {
          this.currentInterval = selectedInterval;
          publish("select:interval", this.currentInterval);
          publish("track:event", {
            action: "Choose interval",
            label: this.currentInterval
          });
        }
      }
      setTimeout(() => {
        this.$(".apply-btn").attr("disabled", "disabled");
      }, 0);
    },
    updateCampaignLabel(fromApply) {
      let campaignId, label;
      const selectedCampaigns = this.$(".campaign-checkbox[checked]");
      const checkedCount = selectedCampaigns.length;
      const campaignLookup = this.mainModel.get("campaignLookup");
      this.editCampaignLink.addClass("no-display");
      switch (checkedCount) {
        case 0:
          label = "Select Campaign";
          break;
        case 1:
          if (fromApply) {
            this.updateEditCampaignLink();
          }
          campaignId = this.$(".campaign-checkbox[checked]:not(.no-display)")[0].getAttribute("data-id");
          label = campaignLookup[campaignId].name;
          break;
        default:
          label = `Multiple Campaigns (${checkedCount})`;
          break;
      }
      this.$(".campaign-label")[0].innerHTML = label;
    },
    searchCampaigns() {
      let searchTerm = this.$(".campaign-search-input").val();
      let searchItemCount = 0;
      const campaigns = this.campaigns;
      const $noResult = this.$(".no-result");
      searchTerm = searchTerm.toLowerCase();
      if (campaigns && campaigns.length) {
        $noResult.addClass("no-display");
        this.$(".campaign-checkbox").removeClass("no-display");
        _.each(campaigns, item => {
          if (item.id.includes(searchTerm) || item.name.toLowerCase().includes(searchTerm)) {
            searchItemCount++;
          } else {
            this.$(`.campaign-checkbox[data-id="${item.id}"]`).addClass("no-display");
          }
        });
        if (searchItemCount === 0) {
          $noResult.removeClass("no-display");
          $noResult.text(`No results found for "${searchTerm}"`);
        }
        this.updateAllCheckbox();
        this.updateHeight(searchItemCount, this.campaignPanel, true);
      }
    },
    doCheck(isChecked, $el) {
      if (isChecked) {
        $el.attr("checked", true);
      } else {
        $el.removeAttr("checked");
      }
      this.toggleApplyButton();
    },
    checkAll(event) {
      this.doCheck(event.target.checked, this.$(".campaign-checkbox"));
      this.updateCampaignLabel();
    },
    checkAllSearchedItems(event) {
      this.doCheck(event.target.checked, this.$(".campaign-checkbox:not(.no-display)"));
      this.updateCampaignLabel();
    },
    checkItem(event) {
      const itemSelected = $(event.target).attr("checked");
      const campaignId = $(event.target).data("id");
      event.stopPropagation();
      _.each(this.campaigns, function (campaign) {
        if (campaignId === campaign.id) {
          campaign.selected = itemSelected;
        }
      });
      this.updateAllCheckbox();
      this.toggleApplyButton();
      this.updateCampaignLabel();
    },
    updateAllCheckbox() {
      const searchValue = this.$(".campaign-search-input").val();
      const $all = this.$(".all");
      const $allSearchResults = this.$(".all-search-results");
      $all.addClass("no-display");
      $allSearchResults.addClass("no-display");
      if (!searchValue.length) {
        const itemsCheckedLength = this.$(".campaign-checkbox[checked]").length;
        const itemsLength = this.$(".campaign-checkbox").length;
        $all.removeClass("no-display");
        if (itemsLength === itemsCheckedLength) {
          $all.attr("checked", true);
        } else {
          $all.removeAttr("checked");
        }
      } else {
        const searchedSelectedItems = this.$(".campaign-checkbox[checked]:not(.no-display)").length;
        const searchedItems = this.$(".campaign-checkbox:not(.no-display)").length;
        if (searchedItems > 0) {
          $allSearchResults.removeClass("no-display");
          if (searchedSelectedItems === searchedItems) {
            $allSearchResults.attr("checked", true);
          } else {
            $allSearchResults.removeAttr("checked");
          }
        }
      }
    },
    toggleApplyButton() {
      const selectedItems = this.$(".campaign-checkbox[checked]:not(.no-display)").length;
      const $apply = this.$(".apply-btn");
      if (selectedItems > 0) {
        $apply.removeAttr("disabled");
      } else {
        $apply.attr("disabled", true);
      }
    },
    updateCampaignDDL() {
      const filteredCampaignItems = this.mainModel.get("filteredCampaignItems") || [];
      _.each(this.campaigns, function (campaign) {
        campaign.selected = filteredCampaignItems[campaign.id] === true;
      });
      this.renderPartial(".campaigns-container");
    },
    sortItems(items) {
      items.sort(function (a, b) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      });
      return items;
    },
    updateHeight(itemCount, element, isCampaignDDL) {
      const rowHeight = 30;
      const singleHeight = 45;
      const maxHeight = 250;
      let itemsHeight = itemCount === 0 ? singleHeight : itemCount * rowHeight;
      const padding = isCampaignDDL ? rowHeight : 0;
      itemsHeight = itemCount === 0 ? itemsHeight : itemsHeight + padding;
      if (element) {
        if (itemsHeight < maxHeight) {
          element.height(itemsHeight);
        } else {
          element.height(maxHeight);
        }
      }
    },
    open() {
      if (this.searchMode) {
        this.searchCampaigns();
      } else {
        this.updateAllCheckbox();
        this.updateHeight(this.campaigns.length, this.campaignPanel, true);
      }
    },
    close() {
      this.campaignDDL[0].close();
      this.updateCampaignDDLUI();
    },
    updateCampaignDDLUI() {
      if (this.searchMode) {
        this.$(".campaign-search-input")[0].value = this.searchTerm;
        this.updateCampaignDDL();
      } else {
        this.searchTerm = "";
        this.$(".campaign-search-input")[0].value = "";
        this.updateCampaignDDL();
      }
      setTimeout(() => {
        this.toggleApplyButton();
      }, 0);
    },
    updateEditCampaignLink() {
      const mainModel = this.mainModel;
      const permission = mainModel.get("campaignAccess");
      const editCampaignURL = `#campaign/${permission}/${mainModel.get("reportId")}/details/general`;
      if (permission === "edit") {
        this.editCampaignLink.removeAttr("disabled");
        this.editCampaignLink.removeClass("no-display");
        this.editCampaignLink.attr("href", editCampaignURL);
      }
    },
    toggleCampaigns() {
      const {
        allCampaigns: allCampaigns,
        mainModel: mainModel
      } = this;
      const showActiveCampaigns = this.activeCampaignsCheckbox.attr("checked");
      let campaigns = [];
      const filteredCampaignItems = [];
      const campaignLookup = mainModel.get("campaignLookup");
      function setFilteredCampaigns(campaign) {
        campaigns.push(campaign);
        filteredCampaignItems[campaign.id] = campaign.selected;
      }
      this.campaignDDL.attr("disabled", "disabled");
      this.campaignSpinner.removeClass("no-display");
      _.each(allCampaigns, function (campaign) {
        if (showActiveCampaigns === "checked") {
          if (campaign.state === "1") {
            setFilteredCampaigns(campaign);
          }
        } else {
          setFilteredCampaigns(campaign);
        }
      });
      campaigns = this.sortItems(campaigns);
      this.campaigns = campaigns;
      mainModel.setCampaignItems(this.campaigns, campaignLookup);
      mainModel.setFilteredCampaignItems(filteredCampaignItems);
      this.updateCampaignDDLUI();
      setTimeout(() => {
        this.updateCampaignLabel();
        this.updateCampaignDDLState();
        this.toggleApplyButton();
      }, 0);
      this.updateAllCheckbox();
      this.campaignDDL.removeAttr("disabled");
      this.campaignSpinner.addClass("no-display");
    },
    serialize() {
      const {
        startDateText: startDateText,
        endDateText: endDateText
      } = getDateText(this.isCTD, this.startDate, this.endDate, displayFormat);
      const {
        agencies: agencies,
        advertisers: advertisers,
        campaigns: campaigns
      } = this;
      return {
        advertisers: advertisers,
        agencies: agencies,
        campaigns: campaigns,
        end: endDateText,
        noCampaigns: campaigns && !campaigns.length,
        start: startDateText
      };
    }
  });
});
