define('models/feedback',["jQuery", "InflectionJS", "T1Model", "models/sessionUser", "T1Permissions", "app.config"], function ($, InflectionJS, T1Model, User, T1Permissions, appCfg) {
  var user = User.getUser();
  var typeOptions = [{
    text: "Ask a question",
    value: "Ask a question"
  }, {
    text: "Report an Issue",
    value: "Report a bug"
  }];
  var sections = appCfg.app.layout[".side-bar"][0].options.sections;
  var componentOptions = [];
  $.each(sections, function (i, section) {
    if (!section.permission || T1Permissions.check("feature", section.permission)) {
      componentOptions.push({
        text: section.label.capitalize(),
        value: section.uid
      });
    }
  });
  return T1Model.extend({
    name: "feedback",
    url: "feedback/",
    fixture: "-feedback",
    defaults: {
      id: user.id,
      name: user.get("email"),
      subject: "",
      type: "",
      description: "",
      component: "",
      priority: 3
    },
    skipIdInUrl: true,
    showNotification: false,
    getTypeOptions: function () {
      return typeOptions;
    },
    getComponentOptions: function () {
      return componentOptions;
    },
    validate: function (attrs) {
      var errors = [];
      var requiredField = " is a required field.";
      var messages = {
        subject: "Subject" + requiredField,
        type: "Feedback Type" + requiredField,
        component: "Module" + requiredField,
        description: "Description" + requiredField
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && (!value.length || value === "")) {
          errors.push({
            field: key,
            message: messages[key]
          });
        }
      });
      if (errors.length) {
        return errors;
      }
    }
  });
});
