define('models/appIconModel',["jQuery", "Underscore", "Backbone", "T1", "T1Comm", "T1Model"], function ($, _, Backbone, T1, T1Comm, T1Model) {
  "use strict";

  T1.Models.AppIconModel = T1Model.extend({
    dataType: "json",
    type: "multipart/form-data",
    url: function () {
      var url = T1.API_APPS_ROOT + "v1.0/apps/";
      return url;
    },
    initialize: function (options) {
      T1Model.prototype.initialize.call(this, options);
    },
    save: function (attributes, options) {
      var postURL = this.url() + this.get("id") + "/" + options.imageType.replace(/_/g, "-");
      var saveOptions = {
        sourceURL: postURL,
        dataType: "json",
        onSuccess: options.success || $.noop,
        onError: options.error || $.noop,
        forcedProcessData: false,
        forcedContentType: false,
        processAjaxResponse: this.processAjaxResponse,
        data: attributes
      };
      return T1Comm.post(saveOptions);
    },
    processAjaxResponse: function (resp) {
      var jsonData;
      var dataType;
      var statusCode;
      if (resp.meta === "success") {
        statusCode = "ok";
      } else {
        statusCode = "error";
      }
      function isJson(str) {
        try {
          jsonData = JSON.parse(str);
        } catch (e) {
          dataType = "plaintxt";
        }
      }
      return {
        jsonData: isJson(resp) ? jsonData : resp,
        statusCode: statusCode
      };
    },
    createEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred;
    }
  });
  return T1.Models.AppIconModel;
});
