define('models/segmentExportDestination',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  "use strict";

  T1.Models.SegmentExportDestination = T1Model.extend({
    apiRoot: T1.ENV.SEGMENT_API_BASE,
    urlRoot: "exports/destinations",
    url: function () {
      return this.apiRoot + this.urlRoot;
    },
    fetch: function (options) {
      var self = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (response) {
        var destinations = [];
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].endpoints.map(function (dest) {
            if (!dest.enabled) {
              return;
            }
            destinations.push({
              value: dest.id,
              label: dest.name
            });
          });
        }
        options.success(destinations);
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      var syncOpts = {
        dataType: "json",
        success: onSuccess,
        processAjaxResponse: self.processAjaxResponse
      };
      self.ajx = self.sync("read", "", syncOpts);
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.SegmentExportDestination;
});
