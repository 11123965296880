define('collections/campaignReport',["Underscore", "jQuery", "T1", "T1Collection", "T1Model"], function (_, $, T1, T1Collection, T1Model) {
  "use strict";

  T1.Models.CampaignReport = T1Collection.extend({
    canCache: true,
    cacheDuration: 18e5,
    apiRoot: T1.RPT_API_ROOT,
    urlString: "",
    fixture: "-reports",
    model: T1Model,
    nestedData: [],
    initialize: function (options) {
      T1Collection.prototype.initialize.call(this, options);
      options = options || {};
      this.urlString = options.urlString || "report.csv";
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.url = options.url || this.url;
      this.ajxResponseHeaders = {};
    },
    select: function (opts) {
      var selected = this.find(function (model) {
        return model.get(opts.key) === opts.value;
      });
      this.trigger.apply(this, ["select", selected]);
    },
    sortByKey: function (sortKey, sortOrder, timeSeries, silent, isName, triggeredByGridSerialize) {
      var keyType = isName === 1 ? "string" : "number";
      var i, keysLen;
      var keyIndex = -1;
      if (timeSeries === "summary") {
        this.models = keyType === "number" ? _.sortBy(this.models, function (model) {
          return Number(model.get(sortKey));
        }) : _.sortBy(this.models, function (model) {
          return model.get(sortKey);
        });
        if (sortOrder === -1) {
          this.models.reverse();
        }
      } else {
        if (this.nestedData && this.nestedData.length > 0) {
          keysLen = this.nestedData[0].keys.length;
          for (i = 0; i < keysLen; i++) {
            if (sortKey === this.nestedData[0].keys[i].key) {
              keyIndex = i;
              break;
            }
          }
          if (keyIndex > -1) {
            this.nestedData = _.sortBy(this.nestedData, function (obj) {
              return obj.keys[keyIndex].sum;
            });
            if (sortOrder === -1) {
              this.nestedData = this.nestedData.reverse();
            }
          }
        }
      }
      if (!silent) {
        this.trigger("sort", triggeredByGridSerialize);
      }
    },
    fetch: function (options) {
      var self = this;
      var collection = this;
      var onSuccess;
      var headers = {};
      options = options || {};
      onSuccess = function (resp) {
        collection.loaded = true;
        collection[options.add ? "add" : "reset"](resp, options);
        if (options.onSuccess) {
          options.onSuccess(collection, resp);
        }
      };
      self.abort();
      collection.loaded = false;
      collection.unregister();
      collection.reset([], {
        silent: true
      });
      this.nestedData = null;
      self.ajxResponseHeaders = headers;
      self.ajx = self.sync("read", self, {
        action: self.action,
        dataType: "html",
        onSuccess: onSuccess,
        getResponseHeaders: headers,
        processAjaxResponse: function (resp) {
          var data;
          if (resp.indexOf("<?xml") !== -1) {
            throw new Error("chart data response not csv");
          }
          data = d3.csv.parse.call(d3, resp);
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
    },
    abort: function () {
      if (this.ajx) {
        this.ajx.abort();
      }
    }
  });
  return T1.Models.CampaignReport;
});
