define('modules/creatives/display/views/createT1AS',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1FileUpload", "T1Permissions", "collections/organizations", "text!../templates/createT1AS.html", "text!../templates/createT1ASBody.html", "text!../../video/templates/createEditWarning.html", "../models/mediaTypesT1AS", "T1Alert", "T1Form"], function ($, _, T1, T1View, T1Layout, T1FileUpload, T1Permissions, Organizations, template, templateBody, templateWarning, mediaTypesT1AS, T1Alert) {
  var T1ASCreateEdit, fList;
  function updateBreadCrumb() {
    var pathPieces = [{
      label: "Upload New T1AS Hosted Assets (Step 1 of 2)",
      location: "creatives/display-t1as"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  T1ASCreateEdit = T1View.extend({
    template: template,
    partials: {
      warning: templateWarning
    },
    events: {
      "click .assets": "handleRadioClick",
      "change .file-input": "handleFileSelect"
    },
    eventhubEvents: {
      "organization:explicitChange": function () {
        T1.Location.set("#creatives/displays");
      },
      "dropdown:advertisers": "handleAdvertiserSelect"
    },
    fileUploaderData: {
      formSelector: "asset-upload-form",
      placeholder: "Select zip, swf, gif, jpg, jpeg, tif, tiff or png",
      action: "append",
      accept: mediaTypesT1AS.restrictTypes,
      multiple: "multiple"
    },
    alertMsgData: {
      invalidFileMsg: {
        title: "Invalid File Error",
        bodyMsg: "",
        buttons: [{
          text: "Try Again",
          class: "save",
          click: function () {
            var $dialog = $(this);
            $dialog.dialog("close");
          }
        }],
        icon: "error-icon"
      }
    },
    isFormElementsChanged: false,
    tipConfig: {
      getExternalTip: true,
      html: true,
      delayIn: 0,
      delayOut: 1500
    },
    initialize: function () {
      var self = this;
      self.isHtml5 = T1Permissions.check("feature", "creatives.html5");
      self.actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel",
          location: "#"
        }, {
          label: "Parse & Review",
          class: "save btn-primary ",
          location: "#"
        }]
      };
      this.initLayout();
    },
    handleRadioClick: function (e) {
      var val = $(e.currentTarget).val();
      this.model.set({
        is_https: val
      });
      this.isFormElementsChanged = true;
    },
    handleFileSelect: function (e) {
      var files = e.target.files;
      const $inlineErrorBox = this.el.find(".inline-box-error");
      $inlineErrorBox.hide();
      this.fd = new FormData();
      this.fileNames = "";
      fList = [];
      if (!files) {
        return;
      }
      if (!this.files) {
        this.files = [];
      }
      for (var i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }
      this.removeInputError();
      for (var j = 0; j < this.files.length; j++) {
        var file = this.files[j];
        var fileName = file.name;
        fList.push(fileName);
        if (this.files.length > 1) {
          this.fd.append("0/" + fileName, file);
        }
        this.fileNames += String(fileName) + "<br/>";
      }
      $(".text-input").val("");
      $(".selected-files").html(this.fileNames);
      $(".selected-files").show();
    },
    handleAdvertiserSelect: function (data) {
      this.model.set({
        advertiser_id: data.id
      });
      this.isFormElementsChanged = true;
    },
    initLayout: function () {
      var self = this;
      updateBreadCrumb();
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".w-body",
          template: templateBody,
          layout: {
            ".advertiser-select": [{
              module: "shared",
              viewType: "advertiserSelect",
              options: {
                entity: "advertisers",
                dataBind: "advertiser_id",
                boundEntity: "organizations",
                boundEntityId: orgId,
                isSearchable: true,
                initialLoad: true,
                placeholder: "Select an Advertiser",
                preSelect: "Select an Advertiser",
                notFound: "No Advertisers Found!",
                refreshEvent: "refresh",
                preRender: function () {},
                defaultSortParams: {
                  fields: {
                    name: {
                      actual: "name",
                      display: "alphabetically"
                    }
                  },
                  default: {
                    sort_by: "name",
                    order_by: "ascending"
                  }
                }
              }
            }]
          }
        });
      }, this));
    },
    showUploadSizeError: function () {
      const $el = this.el;
      const $inlineErrorBox = $el.find(".inline-box-error");
      const $fileInput = $el.find(".asset-upload .file-name .text-input");
      $inlineErrorBox.show();
      this.files = [];
      $el.find(".selected-files").html("");
      $el.find(".selected-files").hide();
      $fileInput.addClass("error");
    },
    postData: function (formValues, successCallback, statusInvalid) {
      var self = this;
      var validFileType = this.validateFileType();
      var errors = [];
      if (!this.model.validateAdvertiserId()) {
        errors.push({
          field: "advertiser_id",
          message: "This is a required field."
        });
      }
      if (!this.model.get("is_https")) {
        this.model.set({
          is_https: "1"
        });
      }
      if (errors.length || !validFileType) {
        statusInvalid(errors, "There are errors on the page.", ".w-CreativesCreateEdit");
      } else {
        if (this.files.length === 1) {
          this.fileUploader.upload({
            sourceURL: T1.API_ROOT + "creative_assets/upload",
            onSuccess: function (data) {
              self.model.setResultData(data);
              successCallback();
              T1.Location.set("creatives/display-t1as-review");
            },
            onError: function (err) {
              statusInvalid([]);
              if (err && err.statusText === "Payload Too Large" || err.statusText === "Request Entity Too Large") {
                self.showUploadSizeError();
              }
            },
            onStatusInvalid: function (data) {
              var msg = data.status.text;
              statusInvalid([]);
              self.alertMsgData.invalidFileMsg.bodyMsg = msg;
              self.displayAlert(self.alertMsgData.invalidFileMsg);
            },
            errorDisplaySuppressingConfig: {
              errorSuppressed: true,
              errorCodes: [413]
            }
          });
        } else {
          return $.ajax({
            type: "POST",
            data: this.fd,
            processData: false,
            contentType: false,
            dataType: "json",
            url: T1.API_ROOT + "creative_assets/upload",
            success: function (data) {
              self.model.setResultData(data);
              successCallback();
              T1.Location.set("creatives/display-t1as-review");
            },
            error: function (err) {
              statusInvalid([]);
              if (err && err.statusText === "Payload Too Large" || err.statusText === "Request Entity Too Large") {
                self.showUploadSizeError();
              }
            }
          });
        }
      }
    },
    removeInputError: function () {
      var $input = $(".asset-upload .file-name .text-input", this.el);
      $input.parent().removeAttr("original-title");
      $input.removeClass("error");
    },
    validateFileType: function () {
      var $el = this.el;
      var self = this;
      var $txtFile = $el.find(".asset-upload-form .file-input");
      var $input = $el.find(".asset-upload .file-name .text-input");
      var isValid = false;
      var isError = false;
      self.removeInputError();
      if (!$txtFile.val()) {
        $input.addClass("error");
        self.applyTooltip($input, "This is a required field.");
      } else {
        for (var i = 0; i < fList.length; i++) {
          if (!isError) {
            if (!mediaTypesT1AS.checkMedia(fList[i])) {
              if (!$input.hasClass("error")) {
                $input.addClass("error");
              }
              self.applyTooltip($input, "Select zip, swf, gif, jpg, jpeg, tif, tiff or png.");
              isError = true;
            }
          }
          if (i === fList.length - 1 && !isError) {
            isValid = true;
          }
        }
      }
      return isValid;
    },
    clearFileValidation: function () {
      var $el = this.el;
      var $input = $el.find(".asset-upload .file-name .text-input");
      var $warning = $el.find(".create-edit-warning");
      if ($input.hasClass("error")) {
        $input.removeClass("error");
      }
      $warning.empty();
    },
    displayAlert: function (alertData) {
      var alertMsg = new T1Alert();
      alertMsg.initialize(alertData);
      alertMsg.show();
    },
    onFileChanged: function (e) {
      var $el = this.el;
      var fileData = e.data;
      var $form = $el.find(".asset-upload-form");
      var $fileInput = $form.find("input[type=file]");
      this.clearFileValidation();
      $fileInput.attr({
        name: String("0/" + fileData.fileInfo.name)
      });
    },
    generateInput: function (className, type, name, value) {
      var $input = $("<input/>").attr({
        class: className,
        type: type,
        name: name,
        value: value
      });
      return $input;
    },
    load: function () {
      var self = this;
      var el = self.el;
      self.resetModel();
      this.render().then(function () {
        if (self.layout) {
          self.layout.load();
        }
        if (!self.fileUploader) {
          self.fileUploader = T1FileUpload.create({
            $el: el.find(".asset-upload"),
            isMultiple: true,
            gatherFileInfo: true,
            onChange: $.proxy(self.onFileChanged, self)
          });
        }
        if (self.fileUploader) {
          self.fileUploader.render(self.fileUploaderData);
        }
        T1.Tooltip(el, self.tipConfig);
        if (!self.isEdit) {
          var $advDDL = $(".advertiser-select", el);
          $advDDL.find("._wrapper").addClass("ad-wrapper");
          el.not('div[class="ad-wrapper"]').click(function () {
            var $chosenDDL = $advDDL.find("ul");
            if ($chosenDDL.hasClass("search-results")) {
              self.layout.layout[".advertiser-select"][0].view.reloadResults();
              $chosenDDL.removeClass("search-results");
            }
          });
        }
        if (self.isHtml5) {
          el.find(".directions li:first-child").before('<li><span class="beta">BETA</span>' + " Please review the HTML5 creative specifications in the T1KB" + "  to ensure that your HTML5 creative meets the specifications supported by T1 Ad Server.</li>");
        }
      });
    },
    unload: function () {
      if (this.fileUploader) {
        this.fileUploader.destroy();
      }
    },
    canUnload: function (onTabChange) {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        self.openUnloadAlertDialog({
          name: "Add T1AS Assets"
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgId;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    onDontSave: function () {
      this.resetModel();
    },
    resetModel: function () {
      this.model.reset();
    },
    serialize: function () {
      var output = {};
      var warningMessage = this.uploadWarning && this.uploadWarning.warningMessage;
      output.uploadWarning = !warningMessage ? null : {
        show: true,
        warningMessage: warningMessage,
        cssClasses: "column-video video-upload-assets"
      };
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(T1ASCreateEdit);
});
