define('modules/reporting/campaigns/reachFrequency/views/reachFrequency',["T1", "T1View", "T1Layout", "T1Permissions", "models/userPreferences", "text!../templates/reachFrequency.html", "T1Signature", "T1Preferences"], function (T1, T1View, T1Layout, T1Permissions, UserPreferences, template) {
  "use strict";

  const SECTION_ID = "reach_frequency";
  return T1View.extend({
    focus: "",
    metric: "",
    interval: "last_30_days",
    selectedColumns: "",
    sortKeyInfo: "",
    template: template,
    section: "reachFrequency",
    eventhubEvents: {
      "select:interval": function (interval) {
        this.interval = interval;
        this.preferences.save();
        this.models.reportModel.setInterval(interval);
      },
      "select:metric": function (metric) {
        this.metric = metric;
        this.preferences.save();
        this.models.reportModel.setMetric(metric);
      },
      "preference:change": function (args) {
        this[args.key] = args.value;
        this.preferences.save();
      },
      "reporting:campaign:load": "refreshUI",
      "reporting:meta:load": "reload",
      "reporting:focus:meta:load": function () {
        this.initPreferences();
        this.refreshUI();
      }
    },
    refreshUI: function () {
      const reportModel = this.models.reportModel;
      reportModel.clearTempDimension();
      reportModel.setMoreFilters("");
      T1.EventHub.publish("unload:noDataMessages");
      this.load();
    },
    initialize: function (cfgs = {}) {
      const models = this.models = cfgs.models;
      this.report = cfgs.report;
      this.campaign = models.campaign;
      this.needEvents = true;
      models.reportModel.setSection(SECTION_ID);
      models.mainModel.setReportMainView(this);
    },
    initPreferences: function () {
      const signature = T1.Signature.create();
      const models = this.models;
      const {
        reportMeta: reportMeta,
        reportModel: reportModel
      } = models;
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", SECTION_ID]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["focus", "interval", "metric", "selectedColumns", "sortKeyInfo"]);
      reportModel.setPreferences(this.preferences);
      reportModel.setInterval(this.interval);
      reportMeta.parseDimensions("dimensionOptions");
      reportMeta.parseMetrics();
      reportModel.parseColumns(true);
      const defaultMetric = models.mainModel.get("multiCampaigns") ? "impressions" : "ctr";
      reportModel.setDimension("strategy_name", false, true);
      reportModel.setMetric(this.metric || defaultMetric, true);
    },
    initLayouts: function () {
      const models = this.models;
      const reportModel = models.reportModel;
      const layoutConfig = {
        el: () => this.$(".w-reach-frequency-panel"),
        template: `<div class="tabs-panel">\n            <div class="tabs-bar"></div>\n            <div class="tabs-control-dimensions"></div>\n            <div class="tabs-info"></div>\n            <div class="tabs-settings"></div>\n            <div class="reach-frequency-panel"></div>\n          </div>`,
        layout: {
          ".tabs-control-dimensions": [{
            module: "reporting/campaigns",
            options: {
              index: 0,
              isFromReachFrequency: true,
              label: "Filter by: ",
              models: models
            },
            viewType: "selectDimension"
          }],
          ".tabs-settings": [{
            module: "reporting/campaigns",
            options: {
              models: models,
              menu_config: {
                raw_data: {
                  label: "Export CSV",
                  items: {
                    export_frequency: {
                      contentType: "text/csv",
                      download_url: () => reportModel.getDownloadURL({
                        byDimension: true,
                        dimensions: "frequency",
                        sourceURL: `std/${SECTION_ID}/download`
                      }),
                      item_type: "action-item",
                      label: "By Frequency",
                      url: `std/${SECTION_ID}/download`
                    },
                    export_frequency_bin: {
                      contentType: "text/csv",
                      download_url: () => reportModel.getDownloadURL({
                        byDimension: true,
                        dimensions: "frequency_bin",
                        sourceURL: `std/${SECTION_ID}/download`
                      }),
                      item_type: "action-item",
                      label: "By Frequency Bin",
                      url: `std/${SECTION_ID}/download`
                    }
                  }
                }
              }
            },
            viewType: "settings_menu"
          }],
          ".tabs-info": [{
            module: "reporting/campaigns",
            viewType: "report_info"
          }],
          ".reach-frequency-panel": [{
            module: "reporting/campaigns/reachFrequency",
            options: {
              models: models,
              parentClass: this,
              report: this.report
            },
            viewType: "panel"
          }]
        }
      };
      this.layout = new T1Layout(layoutConfig);
    },
    load: function () {
      const models = this.models;
      if (models.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (this.needEvents) {
        this.bindViewEvents();
        this.needEvents = false;
      }
      models.reportModel.setSection(SECTION_ID, true);
      if (this.campaign.id && models.reportMeta.loaded) {
        if (this.layout) {
          this.layout.unload();
        }
        this.initPreferences();
        this.initLayouts();
        this.render().then(() => {
          this.layout.load();
          this.loaded = true;
        });
      }
    },
    unload: function () {
      this.loaded = false;
      this.needEvents = true;
    }
  });
});
