define('collections/strategyDealGroups',["jQuery", "moment", "T1", "T1UtilsAsync", "T1Collection", "T1Comm", "models/mediaDealGroup", "models/strategyDealGroup"], function ($, moment, T1, T1UtilsAsync, T1Collection, T1Comm, DealGroup, StrategyDealGroup) {
  "use strict";

  T1.Models.DealGroup = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    model: StrategyDealGroup,
    urlString: "strategies",
    url: function () {
      this.urlString = `${this.urlString}/${this.id}/deal_groups`;
      return T1Collection.prototype.url.call(this);
    },
    getSuccessFunction(options = {}) {
      const self = this;
      const parseFunc = function (data) {
        const parsedData = self.parse(data);
        options.success(parsedData, data);
      };
      return typeof options.success === "function" ? parseFunc : $.noop;
    },
    getSyncOptions(options) {
      return {
        dataType: "json",
        success: this.getSuccessFunction(options),
        processAjaxResponse: this.processAjaxResponse
      };
    },
    fetch: function (options) {
      if (this.ajax) {
        this.ajax.abort();
      }
      this.ajax = this.sync("read", "", this.getSyncOptions(options));
      if (this.ajax) {
        this.ajax.always(() => {
          this.ajax = null;
        });
      }
      return this.ajax;
    },
    fetchAllPages: function (options) {
      var self = this;
      const API_LIMIT = 100;
      options = options || {};
      this.isPaginated = true;
      this.page = 0;
      this.pageLimit = options.pageLimit || API_LIMIT;
      this.fetchOptions = $.extend(true, {}, this.fetchOptions, options.fetchOptions || {});
      this.fetch({
        silent: options.silent || false,
        success: function () {
          var pageLimit = self.pageLimit;
          var morePagesLen = self.count > 0 ? Math.ceil(self.count / pageLimit) - 1 : 0;
          var success = options.success;
          var models = self.models;
          var deferred = [];
          var promises = [];
          var morePages = [];
          var fetchMoreSuccess = function (collection, resp) {
            const page = parseInt(resp.meta.offset, 10) / pageLimit;
            var index = page - 1;
            morePages[index] = models.splice(pageLimit, models.length - pageLimit);
            deferred[index].resolve();
          };
          for (let i = 0; i < morePagesLen; i++) {
            deferred.push(T1UtilsAsync.makeDeferral());
            promises.push(deferred[i].promise);
            self.fetchMore({
              silent: true,
              success: fetchMoreSuccess
            });
          }
          self.isPaginated = false;
          if (success) {
            if (morePagesLen > 0) {
              T1UtilsAsync.linkPromises(promises).then(function () {
                self.models = models.concat(...morePages);
                success.call(self, self);
              });
            } else {
              success.call(self, self);
            }
          }
        }
      });
    },
    fetchMore: function (args) {
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          silent: args.silent || false,
          add: true,
          success: args.success || $.noop
        });
      }
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    parse: function (response) {
      const entity = response.entity.entity;
      const dealGroups = [];
      let dealGroup;
      if (!entity) {
        return dealGroups;
      }
      entity.forEach(item => {
        dealGroup = new StrategyDealGroup();
        dealGroups.push(dealGroup.parse(item));
      });
      this.add(dealGroups);
      this.count = dealGroups.count;
      this.loaded = true;
      return dealGroups;
    }
  });
  return T1.Models.DealGroup;
});
