define('modules/campaign/bulkedit/targeting/mydata/views/list',["jQuery", "T1", "T1View", "text!modules/campaign/bulkedit/targeting/mydata/templates/list.html", "T1Layout", "T1Selectbox", "T1Truncate"], function ($, T1, T1View, template, T1Layout) {
  "use strict";

  return T1View.extend({
    template: template,
    list_size_options: {
      options: [{
        value: "10",
        text: "10"
      }, {
        value: "25",
        text: "25"
      }]
    },
    selected: "",
    fetchRequired: true,
    allowMultipleSelection: false,
    eventhubEvents: {
      "advertiser:select": function ({
        id: id
      }) {
        this.advertiser_id = id;
      },
      "pixel:search": function (args) {
        this.searchPixels(args);
      },
      "pixel:applyFilter": function (args) {
        this.searchFilters = args;
        this.applyFilters();
      }
    },
    events: {
      "click .w-head .close": "hideList",
      "click .eligible label": "applyFilters"
    },
    previousSearchKey: "",
    initialize: function ({
      searchViewType = "search",
      orgId: orgId,
      campaign: campaign,
      getAdvertiserId: getAdvertiserId,
      getAdvertiserName: getAdvertiserName,
      getAdvertiser: getAdvertiser,
      sharedCollection: sharedCollection,
      allowMultipleSelection: allowMultipleSelection,
      selectionMode: selectionMode
    }) {
      var strategyID;
      const collection = this.collection;
      const pageSize = 40;
      collection.updatePageLimit(pageSize);
      this.isEligible = 1;
      this.searchFilters = null;
      this.searchViewType = searchViewType;
      this.campaign = campaign || {};
      if (!orgId) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
      this.orgId = orgId;
      if (getAdvertiserId) {
        const adID = getAdvertiserId();
        this.advertiser_id = adID !== "" ? adID : null;
        collection.search.set({
          params: [{
            term: "event",
            searchField: "pixel_type"
          }, {
            term: "1",
            searchField: "eligible"
          }],
          fetchRequired: false
        });
        if (adID !== undefined) {
          collection.urlFilter.set({
            entity: "advertiser",
            id: adID,
            fetchRequired: true,
            success: function (data) {
              collection.reset(data.models);
            }
          });
        }
      }
      if (getAdvertiserName) {
        this.advertiser_name = getAdvertiserName();
      }
      if (getAdvertiser) {
        this.advertiser = getAdvertiser();
      }
      if (sharedCollection) {
        this.sharedCollection = sharedCollection;
      }
      if (allowMultipleSelection) {
        this.allowMultipleSelection = allowMultipleSelection;
      }
      if (campaign) {
        this.campaign = campaign;
        if (this.campaign.get("strategy").length) {
          strategyID = this.campaign.get("strategy")[0].id;
        } else {
          strategyID = this.campaign.get("strategy").id;
        }
      }
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".search",
        template: '<div class="filters-box"></div>',
        wrapperEl: "<div></div>",
        layout: {
          ".filters-box": [{
            module: "pixel",
            viewType: searchViewType,
            options: {
              strategy_id: strategyID,
              orgId: this.orgId,
              advertiser: {
                id: this.advertiser_id,
                name: this.advertiser_name
              },
              getStrategyAgencyId: () => this.campaign.get("advertiser").get("agency_id")
            }
          }]
        }
      });
      this.gridLayout = new T1Layout({
        el: () => this.el,
        selectorPath: ".pixel-grid",
        template: '<div class="grid-holder"></div>',
        wrapperEl: "<div></div>",
        layout: {
          ".grid-holder": [{
            module: "pixel",
            viewType: "grid",
            options: {
              collection: this.collection,
              sharedCollection: sharedCollection,
              getAdvertiser: getAdvertiserName,
              selectionMode: selectionMode,
              allowMultipleSelection: this.allowMultipleSelection
            }
          }]
        }
      });
    },
    load: function () {
      this.render().then(() => {
        this.onRenderComplete();
      });
    },
    onRenderComplete: function () {
      var collection = this.collection;
      const selectBox = this.$(".list-size-select");
      var selectBoxConfig = {
        searchFieldDisabled: true,
        onChange: () => {
          const pageSize = selectBox.val();
          this.selected = pageSize;
          collection.updatePageLimit(pageSize);
          collection.fetch({
            success: function (response) {
              collection = response;
            }
          });
        }
      };
      this.layout.load();
      this.gridLayout.load();
      T1.Selectbox(selectBox, selectBoxConfig);
    },
    applyFilters: function () {
      const checkbox = this.$(".eligible input");
      this.isEligible = $(checkbox).is(":checked");
      this.search();
    },
    unload: function () {
      if (this.searchViewType === "filterSearch") {
        this.collection.search.clear();
        this.searchTerm = "";
        this.searchFilters = {
          type: "event"
        };
        this.search(false);
      }
    },
    search: function (isFetchRequired) {
      var searchConfig = [];
      const {
        isEligible: isEligible,
        searchTerm: searchTerm,
        collection: collection
      } = this;
      var filterConfiguration = [];
      collection.urlFilter.resetCriteria();
      if (this.searchFilters) {
        const searchFilterType = this.searchFilters.type;
        collection.urlFilter.clear();
        if (searchFilterType === "event") {
          filterConfiguration.push({
            entity: "advertiser",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "advertiser",
            id: this.searchFilters.advertisers_id || this.advertiser_id
          });
        } else if (searchFilterType === "data") {
          collection.urlFilter.clearAll();
          filterConfiguration.push({
            entity: "agency",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "agency",
            id: this.campaign.get("advertiser").get("agency_id")
          });
        } else {
          collection.urlFilter.clearAll();
          filterConfiguration.push({
            entity: "advertiser",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "advertiser",
            id: this.searchFilters.segment_advertisers_id || this.advertiser_id
          });
        }
        searchConfig.push({
          term: searchFilterType,
          searchField: "pixel_type"
        });
        if (searchFilterType === "data" && this.searchFilters.provider_id) {
          searchConfig.push({
            term: this.searchFilters.provider_id,
            searchField: "provider_id"
          });
        }
      } else {
        searchConfig.push({
          term: "event",
          searchField: "pixel_type"
        });
      }
      if (isEligible) {
        searchConfig.push({
          term: "1",
          searchField: "eligible"
        });
      }
      if (searchTerm && searchTerm !== "") {
        searchConfig.push({
          term: searchTerm,
          searchField: "name|id"
        });
      }
      T1.EventHub.publish("pixel:setSearchLoader");
      collection.search.set({
        params: searchConfig,
        fetchRequired: isFetchRequired || true,
        success: function () {
          collection.trigger("reset", collection);
        }
      });
    },
    hideList: function (event) {
      this.preventDefault(event);
      T1.EventHub.publish("pixel:hideDetails");
    },
    searchPixels: function ({
      term: term
    }) {
      if (term !== this.previousSearchKey) {
        this.previousSearchKey = term;
        this.searchTerm = term;
        this.search();
      }
    },
    serialize: function () {
      var pixelTypeLbl;
      if (this.searchFilters) {
        switch (this.searchFilters.type) {
          case "event":
            pixelTypeLbl = " Event Pixels";
            break;
          case "data":
            pixelTypeLbl = " Data Pixels";
            break;
          case "segment":
          case "dynamic":
            pixelTypeLbl = " Segments";
            break;
        }
      } else {
        pixelTypeLbl = " Merit Pixels";
      }
      return {
        list_size_options: this.list_size_options.options,
        pixel_type: pixelTypeLbl
      };
    }
  });
});
