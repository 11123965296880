define('modules/campaign/views/duplicateCampaign',["jQuery", "T1", "T1View", "T1Layout", "models/campaign", "models/duplicateCampaignBrainSettings", "T1Permissions", "utils/CampaignStrategyUtils", "text!modules/campaign/templates/duplicateCampaign.html"], function ($, T1, T1View, T1Layout, Campaign, DuplicateCampaignBrainSettings, T1Permissions, Utils, template) {
  "use strict";

  const modalTypes = Object.freeze({
    editing: "campaignDuplicateModal",
    saving: "campaignDuplicateSavingModal",
    error: "campaignDuplicateErrorModal"
  });
  const startTime = "12:00 AM";
  const endTime = "11:59 PM";
  const startBuffer = 7;
  const endBuffer = 30;
  const tooltipConfig = Object.freeze({
    className: "w-FormError",
    delayIn: 0,
    delayOut: 0,
    fade: true,
    getExternalTip: false,
    gravity: "s",
    html: true,
    offset: 10,
    trigger: "manual"
  });
  const duplicateCampaignBrainMsg = {
    activeCampaign: `You can't copy Strategies to a Campaign that is active`,
    alreadyStarted: `You can't copy Strategies to a Campaign that has already started`,
    alreadyBrainSet: `The Campaign you're copying to already has duplicated Brain data`,
    accessProblem: `You don't have permissions to duplicate from that Campaign`
  };
  const uiValidationMessages = {
    30021: "Merit pixel must be from same advertiser as campaign advertiser.",
    30301: "New campaign must have same goal type as old campaign.",
    30408: "Pixel type must be a merit/event pixel."
  };
  var DuplicateCampaignView = T1View.extend({
    template: template,
    duplicateCampaignBrainFlag: false,
    events: {
      "click #cancelAction": "cancelCampaignDuplication",
      "click #duplicateBtn": "duplicateCampaign",
      "click #closeBtn": "cancelCampaignDuplication",
      "changed .campaign-name": "clearValidation",
      "changed .duplicating-dropdown": "onDuplicatingChange",
      "click .duplicate-campaign-brain-chkbox-strategies": "toggleDuplicateCampaignBrain"
    },
    eventhubEvents: {
      "campaign:strategiesCopiedSuccessfully": function () {
        this.unload();
      },
      "campaign:toSelected": function (data) {
        const $duplicateCampaignCheckbox = this.el.find(".duplicate-campaign-brain-chkbox-strategies");
        $duplicateCampaignCheckbox.prop("checked", false);
        this.duplicateCampaignBrainFlag = false;
        const attributes = data.attributes;
        this.selectedCampaignVersion = attributes.version;
        if (attributes.status === "0" && !attributes.source_campaign_id && String(attributes.id) !== String(this.model.attributes.id) && !Utils.hasEntityStarted(data) && T1Permissions.check("campaign.edit", data) && (T1Permissions.check("campaign.readonly", this.model) || T1Permissions.check("campaign.edit", this.model)) && this.model.attributes.goal_type !== "reach" && this.model.attributes.goal_type !== "spend" && attributes.goal_type !== "reach" && attributes.goal_type !== "spend" && this.model.attributes.goal_type === attributes.goal_type) {
          $duplicateCampaignCheckbox.removeAttr("disabled");
        } else {
          $duplicateCampaignCheckbox.attr("disabled", true);
        }
      },
      "campaign:strategiesCopiedError": function (data) {
        if (data.special_errors) {
          this.handleErrorDuplicateStrategies(data);
        } else {
          this.handleInvalidDuplicateStrategies(data);
        }
      },
      "campaign:datesUpdated": function ({
        startDate: startDate,
        endDate: endDate
      }) {
        this.startDate = startDate;
        this.endDate = endDate;
      },
      "strategy:datesUpdated": function ({
        startDate: startDate,
        endDate: endDate
      }) {
        this.strategyStartDate = startDate;
        this.strategyEndDate = endDate;
      },
      "campaign:selected": function ({
        value: value
      }) {
        this.toCampaignId = value;
      }
    },
    initialize: function ({
      model: model,
      organizationInstance: organizationInstance
    }) {
      this.model = model;
      this.organizationInstance = organizationInstance;
      this.fromCampaignId = this.model.get("id");
      this.toCampaignId = this.fromCampaignId;
      this.duplicating = "strategies";
      this.errorMessage = "";
      this.selectedCampaignVersion = "";
      this.duplicationErrMsg = this.getCampaignActivationError(model);
      this.prepareDatePicker();
      this.initLayout();
    },
    initLayout: function () {
      this.campaignSettings = new T1Layout({
        el: () => this.el,
        selectorPath: ".campaign-settings-container",
        template: '<div class="campaign-settings"></div>',
        layout: {
          ".campaign-settings": [{
            module: "campaign",
            viewType: "duplicateCampaignSettings",
            options: {
              model: this.model,
              organizationInstance: this.organizationInstance,
              startDate: this.startDate,
              endDate: this.endDate,
              duplicateToExistingCampaign: this.duplicating === "strategies"
            }
          }]
        }
      });
      this.strategySelection = new T1Layout({
        el: () => this.el,
        selectorPath: ".strategy-selection-container",
        template: '<div class="strategy-selection"></div>',
        layout: {
          ".strategy-selection": [{
            module: "campaign",
            viewType: "duplicateStrategySelection",
            options: {
              model: this.model
            }
          }]
        }
      });
      this.strategySettings = new T1Layout({
        el: () => this.el,
        selectorPath: ".strategy-settings-container",
        template: '<div class="strategy-settings"></div>',
        layout: {
          ".strategy-settings": [{
            module: "strategy",
            viewType: "duplicateStrategySettings",
            options: {
              campaign: this.newCampaignModel,
              isStandalone: false,
              startDate: this.startDate,
              endDate: this.endDate,
              zone_name: this.model.get("zone_name")
            }
          }]
        }
      });
    },
    load: function () {
      this.render().then(() => {
        this.el.parents().find(".loader").hide();
        this.showModal(modalTypes.editing);
        this.footer = this.$("#duplicateCampaignFooter");
        this.footer[0].type = "error";
        this.campaignSettings.load();
        this.strategySelection.load();
        this.strategySettings.load();
      });
    },
    getCampaignActivationError: function (model) {
      const goalType = model.get("goal_type");
      if (goalType === "viewability_rate" || goalType === "vcpm") {
        return "Viewability vendor and measurement type required to duplicate campaign";
      }
      return "";
    },
    getCampaignBrainError: function (message, errMapping) {
      if (message.indexOf("campaign is active") > 0) {
        return errMapping.activeCampaign;
      } else if (message.indexOf("campaign has started") > 0) {
        return errMapping.alreadyStarted;
      } else if (message.indexOf("previously set") > 0) {
        return errMapping.alreadyBrainSet;
      } else if (message.indexOf("writable by user") > 0) {
        return errMapping.accessProblem;
      }
      return message;
    },
    handleSuccessDuplicateCampaign: function (formValues, data) {
      this.isNewCampaignCreated = true;
      $.extend(data, {
        from_campaign_id: this.fromCampaignId,
        strategySettings: formValues.strategySettings
      });
      this.newCampaignId = data.entity.id;
      this.newCampaignName = data.entity.name;
      T1.EventHub.publish("campaign:copiedSuccessfully", data);
    },
    handleInvalidDuplicateCampaign: function ({
      errors: errors
    }) {
      const {
        code: code,
        error: apiError
      } = errors.field_error || {};
      const errorTitle = `${this.duplicating === "campaign" ? "Campaign was" : "Campaign and Strategies were"} not duplicated`;
      const errorMessage = uiValidationMessages[code] || apiError;
      this.renderError(errorTitle, errorMessage);
    },
    handleInvalidDuplicateCampaignBrain: function (errArr) {
      const indexOfSourceCampaignId = Utils.indexOfError(errArr, "source_campaign_id");
      if (indexOfSourceCampaignId >= 0) {
        const errMsg = this.getCampaignBrainError(errArr[indexOfSourceCampaignId].message, duplicateCampaignBrainMsg);
        this.renderError("Duplicating Campaign Brain", errMsg);
      } else {
        this.hideModal(modalTypes.saving);
      }
    },
    handleInvalidDuplicateStrategies: function ({
      errors: errors,
      error: error
    }) {
      this.strategyErrors = null;
      const {
        code: code,
        error: apiError
      } = errors.field_error || {};
      const errorTitle = "Strategies were not duplicated";
      const errorMessage = uiValidationMessages[code] || apiError || error || "Validation error";
      this.renderError(errorTitle, errorMessage);
    },
    handleErrorDuplicateStrategies: function ({
      entities: entities,
      special_errors: special_errors
    }) {
      const successfulCopies = entities.entity ? entities.entity.length || 1 : 0;
      const specialError = special_errors.error;
      const failedCopies = specialError.length || 1;
      const attemptedCopies = successfulCopies + failedCopies;
      const errorTitle = attemptedCopies > 1 ? `${successfulCopies} of ${attemptedCopies} strategies were duplicated` : "Strategy was not duplicated";
      this.strategyErrors = Array.isArray(specialError) ? specialError : [specialError];
      this.renderError(errorTitle, "");
    },
    cancelCampaignDuplication: function (e) {
      var newNameInput = this.$(".campaign-name");
      var strategyNames = this.$(".new-strategy-text");
      var noOfCopies = this.$(".no-of-copies-input");
      e.preventDefault();
      if (newNameInput[0] && newNameInput[0].error) {
        $(newNameInput).tipsy("hide");
      }
      $.each(strategyNames, function (index, item) {
        if (item.error && $(item).attr("hasTooltip") === "true") {
          $(item).tipsy("hide");
        }
      });
      $.each(noOfCopies, function (index, item) {
        if (item.error && $(item).attr("hasTooltip") === "true") {
          $(item).tipsy("hide");
        }
      });
      this.unload();
    },
    clearValidation: function (e) {
      if (e.target.error === true && e.target.value !== "") {
        e.target.error = false;
        if (this.footer) {
          this.footer[0].hideMessage();
          $(e.target).tipsy("hide");
        }
      }
      if (e.target.value === "") {
        e.target.error = true;
        T1.Tooltip(this.el, Object.assign({
          tooltipEle: ".campaign-name"
        }, tooltipConfig));
        $(e.target).tipsy("show");
      }
    },
    onDuplicatingChange: function (e) {
      const val = e.target.value;
      this.duplicating = val;
      const isStrategy = val.includes("strategies");
      if (!isStrategy) {
        T1.EventHub.publish("strategyCopyGrid:resetStrategySelection");
      }
      this.$(".strategy-selection-container, .strategy-settings-container").toggle(isStrategy);
      if (val === "strategies") {
        this.$(".duplicate-campaign-strategies").show();
      } else {
        this.$(".duplicate-campaign-strategies").hide();
      }
      T1.EventHub.publish("campaign:duplicatingToggled", val);
    },
    toggleDuplicateCampaignBrain: function (e) {
      const checkState = $(e.target).attr("checked");
      if (checkState === "checked") {
        this.duplicateCampaignBrainFlag = true;
      } else {
        this.duplicateCampaignBrainFlag = false;
      }
    },
    showModal: function (modalToShow) {
      const modal = this.$(`.${modalToShow}`)[0];
      if (modal) {
        modal.hidden = false;
      }
    },
    hideModal: function (modalToShow) {
      const modal = this.$(`.${modalToShow}`)[0];
      if (modal) {
        modal.hidden = true;
      }
    },
    prepareDatePicker: function () {
      var endDate = new Date();
      var startDate = new Date();
      startDate.addDays(startBuffer);
      endDate.addDays(endBuffer);
      endDate = endDate.at(endTime);
      startDate = startDate.at(startTime);
      this.newCampaignModel = new Campaign();
      this.newCampaignModel.set({
        initial_start_date: startDate,
        start_date: startDate,
        end_date: endDate,
        zone_name: this.model.get("zone_name")
      });
      this.endDate = endDate;
      this.startDate = startDate;
    },
    duplicateCampaign: function () {
      let isNotValid;
      var self = this;
      var $el = this.el;
      const model = this.model;
      var formValues = {
        campaignSettings: T1.Form.dataAsObject(this.$(".campaign-settings-container").not(this.$('[data-bind="ignore"]'))),
        strategySettings: T1.Form.dataAsObject(this.$(".strategy-settings-container").not(this.$('[data-bind="ignore"]')))
      };
      this.$(".error-box").hide();
      const regExWhitespace = new RegExp("^\\s*$");
      T1.Tooltip(this.el, Object.assign({
        tooltipEle: ".campaign-name"
      }, tooltipConfig));
      if ((model.get("goal_type") === "viewability_rate" || model.get("goal_type") === "vcpm") && (!model.get("viewability_vendor_id") || !model.get("viewability_type"))) {
        this.$(".error-box").show();
        isNotValid = true;
      }
      function validateCampaignBrain() {
        if (self.duplicating === "strategies") {
          formValues.campaignSettings.remember_source = self.duplicateCampaignBrainFlag ? 1 : 0;
        } else {
          formValues.campaignSettings.remember_source = formValues.campaignSettings.duplicateCampaignBrain ? 1 : 0;
          delete formValues.campaignSettings.duplicateCampaignBrain;
        }
      }
      function validateCampaignData(duplicatingType) {
        if (!formValues.campaignSettings.name.length || regExWhitespace.test(formValues.campaignSettings.name)) {
          $el.find(".campaign-name")[0].error = true;
          $el.find(".campaign-name").tipsy("show");
          isNotValid = true;
        }
        if (formValues.campaignSettings.merit_pixel_id === "" && duplicatingType !== "strategies") {
          $($el.find(".pixel-select-box").find(".select")).find(".chzn-single").addClass("error");
          isNotValid = true;
        }
      }
      function validateStrategies() {
        const selectedStrategies = $el.find(".duplicate-strategies-grid .item strand-checkbox[checked]");
        const maxNoOfCopiesLimit = 100;
        const defaultCopies = 1;
        if (selectedStrategies && selectedStrategies.length) {
          formValues.strategySettings.selected_strategies = selectedStrategies;
          $.each(selectedStrategies, function (index, item) {
            const newStrategyTextInput = $(item).closest(".item").find(".new-strategy-text")[0];
            const noOfCopiesTextInput = $(item).closest(".item").find(".no-of-copies-input")[0];
            if (item.checked) {
              const newStrategyTextInputValue = newStrategyTextInput.value;
              const noOfCopiesTextInputValue = parseInt(noOfCopiesTextInput.value, 10);
              if (!newStrategyTextInputValue.length || regExWhitespace.test(newStrategyTextInputValue)) {
                newStrategyTextInput.error = true;
                isNotValid = true;
              }
              if (noOfCopiesTextInputValue > maxNoOfCopiesLimit || noOfCopiesTextInputValue < defaultCopies) {
                noOfCopiesTextInput.error = true;
                isNotValid = true;
              }
            }
          });
        }
      }
      validateCampaignData(this.duplicating);
      validateStrategies();
      validateCampaignBrain();
      if (isNotValid) {
        this.footer[0].message = "There are errors on the page.";
        this.footer[0].showMessage();
        return;
      }
      this.postData(formValues);
    },
    unload: function () {
      this.duplicateCampaignBrainFlag = false;
      if (this.campaignSettings) {
        this.campaignSettings.unload();
      }
      if (this.strategySelection) {
        this.strategySelection.unload();
      }
      if (this.strategySettings) {
        this.strategySettings.unload();
      }
      if (this.strategyErrorGrid) {
        this.strategyErrorGrid.unload();
      }
      this.isDataLoaded = undefined;
      this.strategyErrors = undefined;
      this.errorMessage = "";
      this.destroy();
    },
    renderError: function (errorTitle, errorMessage) {
      this.errorTitle = errorTitle;
      this.errorMessage = errorMessage;
      this.render().then(() => {
        this.showModal(modalTypes.error);
        if (this.strategyErrors) {
          this.strategyErrorGrid = new T1Layout({
            el: () => this.el,
            selectorPath: ".error-list-holder",
            template: '<div class="strategy-error-grid-holder"></div>',
            layout: {
              ".strategy-error-grid-holder": [{
                module: "campaign",
                viewType: "duplicateStrategiesErrorGrid",
                options: {
                  errors: this.strategyErrors
                }
              }]
            }
          });
          this.strategyErrorGrid.load();
        }
      });
    },
    campaignCopiedSuccessfully: function (formValues) {
      T1.EventHub.publish("campaign:copiedSuccessfully", {
        entity: {
          id: this.toCampaignId,
          name: this.model.get("name")
        },
        from_campaign_id: this.fromCampaignId,
        strategySettings: formValues.strategySettings
      });
    },
    postData: function (formValues) {
      let activeModal;
      var campaign = this.model;
      var zone = campaign.get("zone_name");
      var startDate = `${this.startDate.toString("s")} ${zone}`;
      var endDate = `${this.endDate.toString("s")} ${zone}`;
      var strategyStartDate = `${this.strategyStartDate.toString("s")} ${zone}`;
      var strategyEndDate = `${this.strategyEndDate.toString("s")} ${zone}`;
      $.extend(formValues.campaignSettings, {
        start_date: startDate,
        end_date: endDate,
        copy_all_assigned_site_lists: "1",
        copy_all_vendor_contracts: "1"
      });
      $.extend(formValues.strategySettings, {
        start_date: strategyStartDate,
        end_date: strategyEndDate
      });
      if (this.duplicating === "strategies") {
        if (formValues.strategySettings.selected_strategies && formValues.strategySettings.selected_strategies.length) {
          if (formValues.campaignSettings.remember_source) {
            this.duplicateCampaignBrainSettings = new DuplicateCampaignBrainSettings({
              id: this.toCampaignId
            });
            const reqObj = {
              source_campaign_id: this.fromCampaignId,
              version: this.selectedCampaignVersion
            };
            this.duplicateCampaignBrainSettings.createEdit(reqObj, {
              success: () => {
                this.campaignCopiedSuccessfully(formValues);
              },
              statusInvalid: errArr => this.handleInvalidDuplicateCampaignBrain(errArr),
              conflict: () => {
                setTimeout(() => {
                  this.hideModal(modalTypes.saving);
                }, 0);
              }
            });
            activeModal = modalTypes.saving;
          } else {
            this.campaignCopiedSuccessfully(formValues);
            activeModal = modalTypes.saving;
          }
        } else {
          this.footer[0].message = "Please select at least one strategy to duplicate.";
          this.footer[0].showMessage();
          return;
        }
      } else {
        campaign.duplicateCampaign(formValues.campaignSettings, {
          success: data => {
            this.handleSuccessDuplicateCampaign(formValues, data);
          },
          invalid: data => {
            this.handleInvalidDuplicateCampaign(data);
          }
        });
        activeModal = modalTypes.saving;
      }
      this.render().then(() => {
        this.showModal(activeModal);
      });
    },
    serialize: function () {
      const {
        model: model,
        duplicating: duplicating,
        errorTitle: errorTitle,
        errorMessage: errorMessage,
        duplicationErrMsg: duplicationErrMsg
      } = this;
      var modelJson = model.toJSON();
      var obj = {
        duplicating: duplicating,
        errorTitle: errorTitle,
        errorMessage: errorMessage,
        duplicationErrMsg: duplicationErrMsg,
        hasErrorGrid: errorMessage === ""
      };
      return $.extend(obj, modelJson);
    }
  });
  return DuplicateCampaignView;
});
