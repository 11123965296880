define('modules/strategy/views/list',["jQuery", "Underscore", "T1", "T1View", "text!modules/strategy/templates/list.html", "T1Layout", "text!modules/strategy/templates/listLayout.html", "T1Spinner", "T1Permissions", "jQueryUI", "T1Accordion", "T1Tooltip", "T1Loader", "jQPlugins/jquery.smartscroll"], function ($, _, T1, T1View, template, T1Layout, strategyListLayout, Spinner, T1Permissions) {
  "use strict";

  const getNoStrategyMessage = function () {
    function buildFilterStr(filters) {
      let filterStr = filters.join(", ");
      if (filterStr.length > 1) {
        filterStr = filterStr.replace(/,(?=[^,]*$)/, " and");
      }
      return `Search results showing ${filterStr} only. Please change your filters to show additional results.`;
    }
    return function ({
      isExpiredHidden: isExpiredHidden,
      statusFilter: statusFilter
    } = {}) {
      const filters = [];
      const DEFAULT_MSG = "No strategies available";
      if (statusFilter && statusFilter !== "all") {
        filters.push(statusFilter);
      }
      if (isExpiredHidden) {
        filters.push("current");
      }
      return filters.length ? buildFilterStr(filters) : DEFAULT_MSG;
    };
  }();
  var listSizes = {
    options: [{
      value: 10,
      text: "10"
    }, {
      value: 20,
      text: "20"
    }, {
      value: 30,
      text: "30"
    }, {
      value: 50,
      text: "50"
    }]
  };
  var extendedListSizes = {
    options: [{
      value: 10,
      text: "10"
    }, {
      value: 20,
      text: "20"
    }, {
      value: 30,
      text: "30"
    }, {
      value: 50,
      text: "50"
    }, {
      value: 100,
      text: "100"
    }]
  };
  var ListView = T1View.extend({
    listIsOpen: true,
    currentViewMode: "",
    template: template,
    initialize: function ({
      viewMode: viewMode,
      showAll: showAll,
      isIgnoreSmartScroll: isIgnoreSmartScroll,
      showListOptions: showListOptions,
      pageLimit: pageLimit,
      reportInterval: reportInterval,
      currentGoalMode: currentGoalMode
    }) {
      this.currentViewMode = this.viewMode = viewMode;
      this.showAll = Boolean(showAll);
      this.isIgnoreSmartScroll = Boolean(isIgnoreSmartScroll);
      this.showListOptions = Boolean(showListOptions);
      this.pageLimit = pageLimit;
      this.reportInterval = reportInterval;
      this.campaign = this.collection.campaign;
      this.campaignCollection = this.campaign.collection;
      this.collection.report_interval = this.reportInterval;
      this.currentGoalMode = currentGoalMode || "campaignGoal";
      this.layout = new T1Layout({
        el: this.el,
        selectorPath: ".strategy-list-body",
        template: strategyListLayout,
        wrapperEl: '<li class="list-item strategy"></li>',
        layout: {},
        serialize: this.serializerForLayout.bind(this)
      });
      this.alowExtendedPageLimit = T1Permissions.check("feature", "extended_page_limit");
      this.useAllocPacing = T1Permissions.check("feature", "use_alloc_pacing");
      this.isShowClicked = false;
    },
    events: {
      "click .w-foot .more-strategies-action": "addMore",
      "click .show-all-action": "clearSearch",
      "click .strategy-search .strategy-search-button": "search",
      "keyup input": "handleKeyUp",
      "click .js-strategy-expansion": "onExpanderClick"
    },
    dataEvents: {
      collection: {
        add: "append",
        reset: "reloadList"
      },
      campaign: {
        remove: "unbind"
      }
    },
    eventhubEvents: {
      "change:strategyGoalPerformances": "changeGoal",
      list_viewMode: "changeViewMode",
      "change:reportInterval": "changeReportInterval",
      "action:drawerExpandContract": "drawerExpandContractHandler"
    },
    serializerForLayout: function () {
      return {
        hasStrategies: !this.collection.loaded || Boolean(this.collection.length),
        viewMode: this.viewMode,
        noStrategyMessage: getNoStrategyMessage(this.campaignCollection)
      };
    },
    getList: function (layout) {
      var layoutObj = !_.isEmpty(layout) && !_.isEmpty(layout.layout) ? layout.layout : {};
      return layoutObj[".list"] || [];
    },
    drawerExpandContractHandler: function () {
      var listObj = this.getList(this.layout);
      var isExpandedState = null;
      var mixedResult = false;
      for (let i = 0; i < listObj.length; i++) {
        const targetView = listObj[i].view;
        if (targetView && targetView.isDrawerExpanded) {
          if (isExpandedState === null) {
            isExpandedState = targetView.isDrawerExpanded();
          } else {
            const tmpState = targetView.isDrawerExpanded();
            if (tmpState !== isExpandedState) {
              mixedResult = true;
              break;
            }
            isExpandedState = tmpState;
          }
        }
      }
      if (mixedResult !== true && isExpandedState !== null) {
        this.toggleExpandedState(isExpandedState);
      }
    },
    getCurrentExpandedState: function () {
      return this.el.find(".expander-part").hasClass("expanded");
    },
    toggleExpandedState: function (bool) {
      this.el.find(".expander-part").toggleClass("expanded", bool);
    },
    expanderSpinnerStartStop: function (toStart) {
      var spinnerOpts = {
        lines: 8,
        length: 2,
        width: 2,
        radius: 2,
        corners: 1,
        rotate: 0,
        trail: 14,
        speed: 1.4,
        top: -2,
        left: -1,
        className: "strategy-spinner"
      };
      var $spinnerTarget = this.el.find(".js-icon-spinner-expander");
      if (toStart === true) {
        Spinner.apply($spinnerTarget, spinnerOpts);
        $spinnerTarget.addClass("loading");
      } else {
        $spinnerTarget.spin(false);
        $spinnerTarget.removeClass("loading");
      }
    },
    onExpanderClick: function () {
      function handleExpansion(self) {
        var toExpand = !self.getCurrentExpandedState();
        self.expandAllChildren(toExpand).then(function () {
          self.expanderSpinnerStartStop(true);
          self.toggleExpandedState(toExpand);
        }).always(function () {
          self.expanderSpinnerStartStop(false);
        });
      }
      if (this.reloadingList !== true) {
        setTimeout(handleExpansion, 0, this);
      }
    },
    expandAllChildren: function (toExpand) {
      var listObj = this.getList(this.layout);
      var strategiesLength = listObj.length;
      var deferred = $.Deferred();
      if (!strategiesLength) {
        deferred.reject();
      } else {
        for (let i = 0; i < strategiesLength; i++) {
          const targetView = listObj[i].view;
          if (targetView && targetView.expandCloseDrawer) {
            targetView.expandCloseDrawer(toExpand);
          }
        }
        deferred.resolve();
      }
      return deferred.promise();
    },
    changeReportInterval: function (data) {
      this.collection.report_interval = data.reportInterval;
    },
    changeGoal: function (currentGoalMode) {
      this.el.find(".w-StrategyList").removeClass("campaignGoal strategyGoal").addClass(currentGoalMode);
      this.currentGoalMode = currentGoalMode;
    },
    changeViewMode: function (data) {
      this.currentViewMode = data.mode;
    },
    toggleList: function (e) {
      var smartscrollInst = e.data.data("smartscroll");
      this.listIsOpen = !this.listIsOpen;
      if (smartscrollInst) {
        smartscrollInst.unbind();
        if (!this.listIsOpen) {
          smartscrollInst.bind();
        }
      }
    },
    clearSearch: function (e) {
      var self = this;
      var collection = this.collection;
      var showAllBtn = this.el.find(".show-all");
      this.showAll = false;
      this.isShowClicked = true;
      this.campaign.collection.selectedStrategyId = null;
      collection.search.clear();
      if (this.campaign.collection.isStatusFilterOn()) {
        collection.search.set({
          params: [{
            term: this.campaign.collection.statusFilter === "active" ? "1" : "0",
            searchField: "status"
          }]
        });
      }
      showAllBtn.toggleClass("show-all-action", false);
      Spinner.apply(showAllBtn);
      this.layout.empty();
      if (this.campaign.collection && this.campaign.collection.pageLimit !== collection.pageLimit) {
        collection.pageLimit = this.campaign.collection.pageLimit;
      }
      collection.fetch({
        success: function () {
          showAllBtn.toggleClass("show-all-action", true);
          showAllBtn.spin(false);
          T1.EventHub.publish("update:filter", {
            entity: "campaign",
            data: self.campaignCollection.at(0).toJSON()
          });
          if (self.isShowClicked) {
            T1.EventHub.publish("strategyList_showAll", {
              mode: self.currentViewMode,
              interval: self.reportInterval,
              goal: self.currentGoalMode,
              time: Date.now()
            });
          }
        },
        data: this.campaign.collection.getExpiredFilterParam()
      });
      e.preventDefault();
    },
    addMore: function () {
      var moreBtn = this.el.find(".more-strategies");
      var fetchOptions = {
        success: function () {
          moreBtn.toggleClass("more-strategies-action", true);
          moreBtn.spin(false);
        }
      };
      moreBtn.toggleClass("more-strategies-action", false);
      Spinner.apply(moreBtn);
      this.collection.fetchMore(Object.assign(fetchOptions, this.campaign.collection ? {
        data: this.campaign.collection.getExpiredFilterParam()
      } : {}));
    },
    search: function (e, val) {
      var strategySearchC = this.el.find(".strategy-search-c");
      if (strategySearchC.is(":visible")) {
        const searchInput = this.el.find("input");
        const value = val || searchInput.val().trim();
        const term = value.length ? searchInput.val() : "";
        const obj = {
          params: [{
            term: term,
            searchField: "name|id"
          }]
        };
        e.preventDefault();
        this.collection.search.set(obj);
        this.collection.fetch();
      } else {
        strategySearchC.css("display", "block");
      }
    },
    handleKeyUp: function (e) {
      const enterKey = 13;
      var keyCode = e.which;
      var value = this.el.find("input").val().trim();
      if (keyCode === enterKey) {
        this.search(e, value);
      }
    },
    append: function (strategy) {
      var collection = this.collection;
      if (strategy === null || strategy === undefined || typeof strategy !== "object") {
        return false;
      }
      strategy.set({
        campaign: this.campaign
      });
      this.appendStrategy(strategy);
      if (!collection.canFetchMore()) {
        this.el.find(".more-strategies").remove();
      }
      this.updateDataBind({
        strategies_remaining: function (target) {
          var count = collection.count - collection.length;
          if (count) {
            target.html(count);
          } else {
            target.parent().remove();
          }
        }
      }, this.el);
    },
    setSearchData: function (data) {
      var collection = this.collection;
      if (!data || !(data.params || data.idList)) {
        collection.search.clear();
      } else {
        collection.search.set(data);
      }
      collection.fetch();
    },
    unload: function () {
      var $strategyList = this.el.find(".w-StrategyList");
      var smartscrollInst = $strategyList.data("smartscroll");
      T1.EventHub.publish("strategies:stopMonitoring");
      if (!this.isIgnoreSmartScroll && smartscrollInst) {
        smartscrollInst.unbind();
      }
      this.collection.reset([], {
        silent: true
      });
      $strategyList.off("campaign:toggleStrategies", this.toggleList);
      this.campaign.strategiesFetched = false;
      this.collection.search.clear();
      this.layout.empty();
      this.el.empty();
    },
    reloadList: function () {
      const hasItems = Boolean(this.collection.length);
      function onStrategyAppendedTimeout(self) {
        self.reloadingList = false;
      }
      function onStrategyAppended() {
        const delay = 200;
        setTimeout(onStrategyAppendedTimeout, delay, this);
      }
      function onLayoutLoaded() {
        var $strategyList;
        this.layout.loaded = true;
        if (hasItems) {
          this.appendStrategy().then(onStrategyAppended.bind(this));
          $strategyList = this.el.find(".w-StrategyList");
          this.applySmartScroll($strategyList);
          $strategyList.on("campaign:toggleStrategies", $strategyList, this.toggleList);
        }
      }
      function onRender(serializedData) {
        this.renderPartial(".w-foot", serializedData);
        this.prepareListSizeDDL();
        this.layout.empty();
        this.layout.load().then(onLayoutLoaded.bind(this));
      }
      T1.EventHub.publish("strategies:stopMonitoring");
      this.render().then(onRender.bind(this));
    },
    appendStrategy: function (model) {
      var deferred = $.Deferred();
      var config = {
        module: "strategy",
        viewType: "item"
      };
      var options = {
        viewMode: this.currentViewMode,
        viewModeFields: this.viewModeFields,
        reportInterval: this.reportInterval,
        currentGoalMode: this.currentGoalMode,
        defaultDrawerExpandedState: this.getCurrentExpandedState()
      };
      function resolveDeferred() {
        deferred.resolve();
      }
      function extendLayout(strategy) {
        return $.extend({
          model: strategy
        }, options);
      }
      if (!model) {
        if (this.isShowClicked) {
          T1.EventHub.publish("strategyList_showAll", {
            mode: this.currentViewMode,
            interval: this.reportInterval,
            goal: this.currentGoalMode,
            time: Date.now()
          });
        }
        this.layout.append(".list", config, this.collection, extendLayout).then(resolveDeferred);
      } else {
        options.model = model;
        config.options = options;
        this.layout.append(".list", config).then(resolveDeferred);
      }
      if (this.isShowClicked) {
        T1.EventHub.publish("strategyList_showAll", {
          mode: this.currentViewMode,
          interval: this.reportInterval,
          goal: this.currentGoalMode,
          time: Date.now()
        });
      }
      return deferred.promise();
    },
    prepareListSizeDDL: function () {
      if (this.showListOptions) {
        const listSize = this.$(".list-size-controls");
        const defaultListSize = 20;
        T1.Selectbox(listSize.find(".list-size-list"), {
          data: null,
          onChange: e => {
            var val = e.currentTarget.value;
            if (this.collection.pageLimit !== val) {
              this.pageLimit = val;
              this.reloadingList = true;
              T1.EventHub.publish("strategies:changePageLimit", {
                pageLimit: val
              });
            }
          }
        });
        listSize.show(defaultListSize);
      }
    },
    load: function (isDataFetched) {
      var collection = this.collection;
      var campaign = collection.campaign;
      T1.EventHub.publish("strategies:listQtyKnown", {
        count: collection.count,
        pageLimit: collection.pageLimit
      });
      function onFetchSuccess() {
        campaign.strategiesFetched = true;
        this.appendStrategies = true;
        this.load(true);
      }
      function onRender() {
        var $strategyList = this.el.find(".w-StrategyList");
        var searchOptions = {};
        if (!campaign.strategiesFetched) {
          collection.reset([], {
            silent: true
          });
          searchOptions.fetchRequired = false;
          searchOptions.paramsAndIDs = campaign.collection.selectedStrategyId && campaign.collection.isStatusFilterOn();
          if (campaign.collection.selectedStrategyId) {
            searchOptions.idList = [campaign.collection.selectedStrategyId];
          }
          if (campaign.collection.isStatusFilterOn()) {
            searchOptions.params = [{
              term: campaign.collection.statusFilter === "active" ? "1" : "0",
              searchField: "status"
            }];
          }
          collection.search.set(searchOptions);
          collection.fetch({
            silent: true,
            success: onFetchSuccess.bind(this),
            data: campaign.collection.getExpiredFilterParam()
          });
        }
        this.prepareListSizeDDL();
        this.layout.load();
        if (isDataFetched === true) {
          campaign.trigger("strategies:fetched");
        }
        if (this.appendStrategies) {
          this.appendStrategy();
          this.appendStrategies = false;
        }
        this.applySmartScroll($strategyList);
        $strategyList.on("campaign:toggleStrategies", $strategyList, this.toggleList);
      }
      this.layout.unload();
      this.render().then(onRender.bind(this));
    },
    applySmartScroll: function ($strategyList) {
      if (!this.isIgnoreSmartScroll) {
        $strategyList.smartscroll({
          scrollHeader: ".strategy-list-headers",
          scrollList: ".campaign-list",
          headerOffset: -44,
          topZindex: 0,
          bottomZindex: 0,
          autoBind: true
        });
      }
    },
    serialize: function () {
      var collection = this.collection;
      return {
        strategies: collection.toJSON(),
        hasStrategies: collection.length && this.showListOptions,
        isLoaded: collection.campaign.strategiesFetched,
        sizeFields: this.alowExtendedPageLimit === true ? extendedListSizes.options : listSizes.options,
        selectedPageLimit: parseInt(this.pageLimit, 10),
        displayMoreStrategies: collection.canFetchMore(),
        strategies_remaining: collection.count - collection.length,
        showAll: this.showAll,
        currentGoalMode: this.currentGoalMode,
        currentViewMode: this.currentViewMode,
        useAllocPacing: this.useAllocPacing
      };
    }
  });
  return T1.Form.ViewUtils.extend(ListView, ["serialize"]);
});
