define('models/vendorAdmin',["jQuery", "Underscore", "Backbone", "T1", "T1Model", "utils/AdminUtils"], function ($, _, Backbone, T1, T1Model, AdminUtils) {
  T1.Models.Vendor = T1Model.extend({
    url: "vendors/",
    fixture: "-vendors",
    defaults: {
      action: "include"
    },
    updateStatus: function (attributes, options) {
      T1Model.prototype.save.call(this, attributes, options);
    },
    delete: function (args) {
      var successCallback = args.successCallback || null;
      var errorCallback = args.errorCallback || null;
      var syncOpts = {
        success: function (data) {
          var onSuccess = successCallback || $.noop;
          onSuccess(data);
        },
        statusInvalid: errorCallback,
        error: errorCallback,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true
        },
        action: "delete"
      };
      if (!args || !successCallback || !errorCallback) {
        throw new Error("successCallback & errorCallback required for Delete post");
      }
      this.sync("POST", {
        version: this.get("version")
      }, syncOpts);
    },
    validateAttrs: function (attrs) {
      var errors = [];
      var optionalFields = ["adx_declaration_required", "mm_contract_available", "adx_approved", "is_eligible", "description", "mode", "code", "adx_ssl_approved", "adx_video_ssl_approved", "adx_video_approved", "rate_card_type", "rate_card_price", "wholesale_price", "adx_vendor_identifier"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        name: function () {
          return !attrs.name;
        },
        vendor_type: function () {
          return !attrs.vendor_type;
        },
        detect_domains: function () {
          return !attrs.detect_domain;
        }
      };
      var extraValFields = {
        name: function () {
          if (attrs.name.length > 256) {
            return "Name cannot be longer than 256 characters.";
          }
        }
      };
      var cleanedContent = [];
      var badCount = 0;
      if (attrs.mm_contract_available === "1") {
        reqFields.rate_card_type = function () {
          return !attrs.rate_card_type;
        };
        reqFields.rate_card_price = function () {
          return !attrs.rate_card_price;
        };
        reqFields.wholesale_price = function () {
          return !attrs.wholesale_price;
        };
        extraValFields.rate_card_type = function () {
          if (attrs.rate_card_type === "") {
            return "You must provide a Rate Card Type.";
          }
        };
        extraValFields.rate_card_price = function () {
          if (attrs.rate_card_price === "") {
            return "You must provide a Rate Card Price.";
          }
        };
        extraValFields.wholesale_price = function () {
          if (attrs.wholesale_price === "") {
            return "You must provide a Wholesale Price.";
          }
        };
        delete optionalFields[10];
        delete optionalFields[11];
        delete optionalFields[12];
      }
      if (attrs.adx_declaration_required === "1") {
        reqFields.adx_vendor_identifier = function () {
          return !attrs.adx_vendor_identifier;
        };
        extraValFields.adx_vendor_identifier = function () {
          if (attrs.adx_vendor_identifier === "") {
            return "You must provide a Adx Vendor ID.";
          }
        };
        delete optionalFields[13];
      }
      if ("string" === typeof attrs.name) {
        attrs.name = attrs.name.trim();
      }
      $.each(attrs, function (key, value) {
        if (value === undefined || value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          }
        }
        if (extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    },
    reset: function () {
      this.attributes = {};
    }
  });
  return T1.Models.Vendor;
});
