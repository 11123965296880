define('modules/creatives/componentBased/lib/creativeGridItem',["jQuery", "Underscore"], function ($, _) {
  "use strict";

  var ICON_MAP = {
    success: "success",
    failure: "fail",
    pending: "clock"
  };
  function changeIcon(gridItem, type) {
    gridItem.gridData.iconType = type;
    switch (type) {
      case "success":
        gridItem.gridData.iconColorClass = "component-bulk-success-icon";
        break;
      case "clock":
        gridItem.gridData.iconColorClass = "component-bulk-pending-icon";
        break;
      case "fail":
        gridItem.gridData.iconColorClass = "component-bulk-submit-failure-icon";
        break;
      default:
        gridItem.gridData.iconColorClass = "component-bulk-pending-icon";
    }
  }
  return {
    createCreativeGridItem: function (c) {
      var obj = {
        showLoader: "none",
        progress: "1",
        isUploadable: function () {
          var missingRequiredFields = this.validationErrors.missingRequired.length > 0;
          var hasGeneralValidationErrors = this.validationErrors.general.length > 0;
          if (missingRequiredFields || hasGeneralValidationErrors) {
            return false;
          }
          return true;
        },
        getRejectionReasonAndTooltip: function () {
          var result = {};
          var missingRequiredFields = this.validationErrors.missingRequired;
          var invalidGeneralFields = this.validationErrors.general;
          var hasMissingRequiredFields = missingRequiredFields.length > 0;
          var hasInvalidGeneralFields = invalidGeneralFields.length > 0;
          var reasons = [];
          if (hasMissingRequiredFields) {
            reasons.push("Missing required fields: " + missingRequiredFields.join(", "));
          }
          if (hasInvalidGeneralFields) {
            reasons.push(_.map(invalidGeneralFields, function (g) {
              return g.reason;
            }));
          }
          reasons = _.flatten(reasons);
          result.reason = reasons.join(". ");
          return result;
        },
        setCreateStatus: function (status) {
          changeIcon(this, ICON_MAP[status]);
        },
        setProgress: function (progress) {
          this.gridData.progress = progress + "";
        },
        getProgress: function () {
          return this.gridData.progress;
        },
        toggleConceptInvalidError: function (show) {
          if (show) {
            this.gridData.invalidConcept = "inline";
          } else {
            this.gridData.invalidConcept = "none";
          }
        },
        toggleLoader: function (show) {
          this.gridData.showLoader = show;
        },
        toggleRetryButton: function (show) {
          if (show) {
            this.gridData.showRetry = "inline";
          } else {
            this.gridData.showRetry = "none";
          }
        }
      };
      return _.extend(obj, c);
    }
  };
});
