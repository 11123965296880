define('collections/adServers',["T1", "T1Collection", "T1Sortable", "models/adServer"], function (T1, T1Collection, Sortable, AdServer) {
  var originalInitialize;
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: AdServer,
    urlString: "ad_servers"
  });
  originalInitialize = collection.prototype.initialize;
  collection.prototype.initialize = function (models, options) {
    options = options || {};
    options.isPaginated = false;
    return originalInitialize.call(this, models, options);
  };
  T1.Models.AdServers = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.AdServers;
});
