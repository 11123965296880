define('modules/creatives/display/models/mediaTypesBulk',["jQuery"], function ($) {
  function checkMedia(input) {
    var ext = input.split(".");
    var types = ["txt", "xls", "html", "htm", "xlsx"];
    ext = ext[ext.length - 1].toLowerCase();
    if (ext) {
      return $.inArray(ext, types) !== -1;
    }
    return false;
  }
  restrictTypes = [".xls", "application/vnd.ms-excel", "text/html", "text/plain", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  restrictTypesBulk3PAS = [".xls", "text/plain", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  return {
    checkMedia: checkMedia,
    restrictTypes: restrictTypes.join(","),
    restrictTypesBulk3PAS: restrictTypesBulk3PAS.join(",")
  };
});
