define('modules/audiences/segments/behavior/views/addBehaviorV2',["jQuery", "Underscore", "Backbone", "Hogan", "Mustache", "T1", "T1Collection", "T1Permissions", "collections/agencies", "collections/advertisers", "text!modules/audiences/segments/behavior/templates/addBehaviorV2.html", "text!modules/audiences/segments/behavior/templates/behaviorTabs.html", "text!modules/audiences/segments/behavior/templates/eventPixelsTab.html", "text!modules/audiences/segments/behavior/templates/dataPixelsTab.html", "text!modules/audiences/segments/behavior/templates/strategiesTab.html", "text!modules/audiences/segments/behavior/templates/campaignsTab.html", "text!modules/audiences/segments/behavior/templates/campaignsDropdown.html", "collections/pixelUniques", "collections/campaigns", "modules/audiences/segments/behavior/utils/binUtils"], function ($, _, Backbone, Hogan, Mustache, T1, T1Collection, T1Permissions, Agencies, Advertisers, template, Tabs, EventPixelsTab, DataPixelsTab, StrategiesTab, CampaignsTab, CampaignsDropdown, UniquesCollection, Campaigns, BinUtils) {
  "use strict";

  var AddBehaviorV2 = Backbone.View.extend({
    template: template,
    events: {
      "click #add-behavior-v2-cancel": "hide",
      "click #add-behavior-v2-apply": "applySelectedBehaviors",
      "click .behavior-grid-v2 strand-grid-item": "selectRowHandler",
      "click .click": "selectStrategyHandler",
      "click .impression": "selectStrategyHandler",
      "click .allImpressions": "selectCampaignHandler",
      "changed .advertiser-selector": "advertiserChanged",
      "changed .campaign-selector": "campaignChanged",
      "changed .entities-search": "searchEntities",
      "click .select-all": "selectAllHandler",
      "seg-collection:load segments-collection": "behaviorsLoaded",
      "changed .show-active-only": "toggleActiveOnly",
      "click .strand-tabs": "activeTabChanged"
    },
    initialize: function (options) {
      this.$el = $(this.el);
      this.segment = options.segment;
      this.segmentType = options.segmentType;
      this.addFunction = options.addFunction;
      this.isAdaptive = this.segmentType === "adaptive";
      this.dataPixelsEnabled = T1Permissions.check("feature", "segments_data_pixels") && this.isAdaptive;
      this.orgId = options.orgId;
      this.crossAgencyEnabled = options.crossAgencyEnabled && this.segmentType === "standard";
      this.advertiser = options.advertiser;
      this.binCollection = [];
      this.behaviorLimit = 250;
      this.load();
    },
    load: function () {
      this.advertiserId = this.advertiser.id;
      this.behaviorCount = this.segment.model.behaviors.length;
      this.crossAdv = this.advertiser.crossAdv === "1";
      this.showCrossAgency = this.crossAdv && this.crossAgencyEnabled;
      this.render();
    },
    render: function () {
      var leftPanel = this.isAdaptive ? Tabs : EventPixelsTab;
      var container;
      this.modal = this.modal || new Strand.Modal();
      container = this.modal.$.container;
      this.modal.dismiss = false;
      this.modal.noscroll = true;
      this.modal.className = "add-behavior-v2-modal";
      container.style.height = "85%";
      container.style.width = "85%";
      container.innerHTML = Mustache.to_html(this.template, {
        model: "{{model}}",
        scope: "{{scope}}",
        id: "{{model.id}}",
        state: "{{model.state}}",
        name: "{{model.name}}",
        data: "{{data}}",
        page: "{{page}}",
        search: "{{search}}",
        active: "{{active}}",
        isAdaptive: this.isAdaptive,
        dataPixelsEnabled: this.dataPixelsEnabled,
        sortOrder: "{{sortOrder}}",
        sortField: "{{sortField}}",
        pageLimit: "{{pageLimit}}",
        clickState: "{{model.clickState}}",
        impressionState: "{{model.impressionState}}",
        allImpressionsState: "{{model.allImpressionsState}}",
        crossAdv: this.crossAdv,
        crossAgency: this.showCrossAgency,
        enabled: "{{model.includable}}",
        disabled: "{{!model.includable}}",
        behaviorLimit: this.behaviorLimit,
        footerType: "{{footerType}}",
        segmentType: this.isAdaptive ? "adaptive" : "standard",
        strategiesLabel: "Strategies",
        modalBreadcrumb: this.getBreadCrumb()
      }, {
        leftPanel: leftPanel,
        eventPixelsTab: EventPixelsTab,
        dataPixelsTab: DataPixelsTab,
        strategiesTab: StrategiesTab,
        campaignsTab: CampaignsTab
      });
      this.el.append(this.modal);
      this.applyButton = this.el.find("#add-behavior-v2-apply")[0];
      requestAnimationFrame(() => {
        this.initFooter();
        this.initBins();
        this.initGrids();
        this.initCollections();
        let campaignsTab = $(".behavior-tabs").find("span.strand-tabs").filter(function () {
          return $(this).text() === "Campaigns";
        });
        if (this.showCrossAgency) {
          this.initAgencies().then(() => {
            this.initAdvertisers();
          });
        } else if (this.crossAdv) {
          this.initAdvertisers();
        }
      });
      this.open();
    },
    getBreadCrumb: function () {
      const crumbs = [];
      if (this.showCrossAgency) {
        crumbs.push(this.advertiser.agency);
      }
      crumbs.push(this.advertiser.name);
      crumbs.push(this.segmentDisplayName());
      return crumbs.filter(Boolean).join(" >> ");
    },
    initAgencies: function () {
      const def = $.Deferred();
      const agencyId = this.advertiser.agencyId;
      const agenciesDropdown = this.el.find("#pixels-agencies")[0];
      this.agenciesCollection = new Agencies();
      this.agenciesCollection.urlFilter.set({
        entity: "organization",
        id: this.orgId,
        fetchRequired: false,
        sort_by: ["name", "id"],
        order_by: "ascending",
        page_limit: 50
      });
      this.agenciesCollection.searchConfiguration.fields["allow_x_adv_pixels"] = {
        type: "enum"
      };
      this.agenciesCollection.search.set({
        params: [{
          term: "1",
          searchField: "allow_x_adv_pixels"
        }]
      });
      this.agenciesCollection.fetchAllPages({
        success: collection => {
          agenciesDropdown.set("data", collection.map(model => this.dropdownFormatter(model)));
          requestAnimationFrame(() => {
            agenciesDropdown.value = agencyId;
            def.resolve();
          });
        }
      });
      return def.promise();
    },
    initAdvertisers: function () {
      const agencyId = this.advertiser.agencyId;
      const pixelsDropdown = this.el.find("#pixels-advertisers")[0];
      const strategiesDropdown = this.el.find("#strategies-advertisers")[0];
      const campaignsDropdown = this.el.find("#campaigns-advertisers")[0];
      const dropdowns = new Array(pixelsDropdown);
      this.advertiserTabs = [{
        name: "Pixels",
        dropdown: pixelsDropdown
      }];
      if (this.isAdaptive) {
        this.advertiserTabs.push({
          name: "Campaigns",
          dropdown: campaignsDropdown
        }, {
          name: "Strategies",
          dropdown: strategiesDropdown
        });
      }
      this.advertisersCollection = new Advertisers({
        agency_id: agencyId
      });
      this.advertisersCollection.fetchOptions = {
        sort_by: "name",
        full: "*"
      };
      this.advertisersCollection.isPaginated = false;
      this.advertisersCollection.singleFetch = true;
      this.advertisersCollection.urlFilter.set({
        entity: "agency",
        id: agencyId,
        fetchRequired: false
      });
      this.advertisersCollection.fetchAllPages({
        success: response => {
          _.each(this.advertiserTabs, tab => {
            tab.dropdown.set("data", response.map(model => this.dropdownFormatter(model)));
            tab.collection = new T1Collection(response.toJSON());
            tab.dropdown.value = this.advertiserId;
          });
          if (this.showCrossAgency) {
            $(".agency-selector").on("selected", e => {
              this.agencyChanged(e);
            });
          }
        }
      });
    },
    initFooter: function () {
      this.footer = this.el.find("#selector-footer")[0];
      if (this.isMaxSelected()) {
        this.footer.showMessage();
      }
    },
    initGrids: function () {
      this.eventPixelsGrid = this.el.find("#event-pixels-grid")[0];
      attachIconToggleListener.call(this, this.eventPixelsGrid);
      if (this.isAdaptive) {
        this.strategiesGrid = this.el.find("#strategies-grid")[0];
        attachIconToggleListener.call(this, this.strategiesGrid);
        this.campaignsGrid = this.el.find("#campaigns-grid")[0];
        attachIconToggleListener.call(this, this.campaignsGrid);
      }
      if (this.dataPixelsEnabled) {
        this.dataPixelsGrid = this.el.find("#data-pixels-grid")[0];
        attachIconToggleListener.call(this, this.dataPixelsGrid);
      }
      function attachIconToggleListener(grid) {
        grid.addEventListener("DOMNodeInserted", e => {
          this.toggleIncludeIcons(!this.isMaxSelected(), $(e.target));
        }, false);
      }
    },
    initBins: function () {
      this.binlist = this.el.find("#bin")[0];
      this.binlist.addEventListener("item-include-or-exclude", BinUtils.removeFromBinsHandler.bind(this));
      this.binlist.grouped = [];
    },
    initCollections: function () {
      this.initEventPixelsCollection();
      if (this.dataPixelsEnabled) {
        this.initDataPixelsCollection();
      }
      if (this.isAdaptive) {
        this.initStrategiesCollection();
        this.initCampaignsCollection();
      }
    },
    attachCollectionEvents: function (collection, grid) {
      switch (grid.id) {
        case "strategies-grid":
          collection.setSelected = setStrategySelected.bind(this);
          break;
        case "campaigns-grid":
          collection.setSelected = setCampaignSelected.bind(this);
          break;
        default:
          collection.setSelected = setPixelSelected.bind(this);
          break;
      }
      collection.addEventListener("seg-collection:load", e => {
        grid.isLoading = false;
      });
      collection.addEventListener("seg-collection:call", () => {
        grid.isLoading = true;
      });
      collection.addEventListener("seg-collection:noResults", msg => {
        grid.noResultsMessage = msg.detail;
      });
      collection.addEventListener("seg-collection:errors", function () {
        grid.noResultsMessage = "Internal Error. Please contact T1 Support.";
      });
      function setPixelSelected(entity) {
        entity.state = BinUtils.inBins.call(this, entity.id) ? "selected" : "deselected";
      }
      function setStrategySelected(entity) {
        entity.clickState = BinUtils.inBins.call(this, entity.id, "click") ? "selected" : "deselected";
        entity.impressionState = BinUtils.inBins.call(this, entity.id, "impression") ? "selected" : "deselected";
      }
      function setCampaignSelected(entity) {
        entity.allImpressionsState = BinUtils.inBins.call(entity.id, "allImpressions") ? "selected" : "deselected";
      }
    },
    initDataPixelsCollection: function () {
      fetchDataPixelsWhitelist().then(response => {
        this.dataPixelsCollection = this.el.find("#data-pixels-collection")[0];
        this.dataPixelsCollection.agencyId = parseInt(this.advertiser.agencyId, 10);
        this.dataPixelsCollection.sourceUrl = "/api/v2.0/pixel_bundles";
        this.dataPixelsCollection.advertiserId = this.advertiserId;
        this.dataPixelsCollection.pageLimit = this.behaviorLimit;
        this.dataPixelsCollection.set("whitelist", response.data);
        this.attachCollectionEvents(this.dataPixelsCollection, this.dataPixelsGrid);
        this.dataPixelsCollection.sort = "agency_name,name";
        this.dataPixelsCollection.getUniques = this.getUniques.bind(this, "data_pixel_loads");
      });
      function fetchDataPixelsWhitelist() {
        return $.ajax({
          url: T1.ENV.SEGMENT_API_BASE + "pixel_bundles/whitelist"
        });
      }
    },
    initEventPixelsCollection: function () {
      this.eventPixelsCollection = this.el.find("#event-pixels-collection")[0];
      this.eventPixelsCollection.sourceUrl = "/api/v2.0/pixel_bundles";
      this.eventPixelsCollection.advertiserId = this.advertiserId;
      this.eventPixelsCollection.pageLimit = this.behaviorLimit;
      this.attachCollectionEvents(this.eventPixelsCollection, this.eventPixelsGrid);
      this.eventPixelsCollection.sort = "advertiser_name,name";
      this.eventPixelsCollection.getUniques = this.getUniques.bind(this, "event_pixel_loads");
    },
    initStrategiesCollection: function () {
      this.strategiesCollection = this.el.find("#strategies-collection")[0];
      this.strategiesCollection.sourceUrl = "/api/v2.0/strategies";
      this.strategiesCollection.advertiserId = this.advertiserId;
      this.strategiesCollection.pageLimit = this.behaviorLimit;
      this.attachCollectionEvents(this.strategiesCollection, this.strategiesGrid);
      this.strategiesCollection.sort = "campaign_name,name";
      if (!this.crossAdv) {
        this.loadCampaignsDropdown(this.advertiserId);
      }
    },
    initCampaignsCollection: function () {
      this.campaignsCollection = this.el.find("#campaigns-collection")[0];
      this.campaignsCollection.sourceUrl = "/api/v2.0/campaigns";
      this.campaignsCollection.advertiserId = this.advertiserId;
      this.campaignsCollection.pageLimit = 100;
      this.attachCollectionEvents(this.campaignsCollection, this.campaignsGrid);
      this.campaignsCollection.sort = "advertiser_name,name";
    },
    getUniques: function (urlString, pixelIds) {
      var def = $.Deferred();
      var uniquesCollection = new UniquesCollection();
      uniquesCollection.fetchOptions = {
        dimensions: "pixel_name,pixel_id,referrer",
        filter: "organization_id=" + this.segment.org + "&pixel_id=" + _.pluck(pixelIds, "id") + "&referrer_rank=01,9999",
        time_window: "yesterday",
        time_rollup: "by_day"
      };
      uniquesCollection.urlString = urlString;
      uniquesCollection.fetch({
        success: function (resp) {
          var data = _.select(resp, function (obj) {
            return obj.referrer === "Total";
          });
          def.resolve(data);
        }
      });
      return def.promise();
    },
    findAdvertiserTab: function (name) {
      if (this.crossAdv) {
        return this.advertiserTabs.find(tab => tab.name === name) || {};
      } else {
        return {};
      }
    },
    searchEntities: function (e) {
      const pixelsAdvCollection = this.findAdvertiserTab("Pixels").collection;
      const strategiesAdvCollection = this.findAdvertiserTab("Strategies").collection;
      const campaignsAdvCollection = this.findAdvertiserTab("Campaigns").collection;
      const collectionMap = {
        "pixels-agencies": this.agenciesCollection,
        "pixels-advertisers": pixelsAdvCollection,
        "strategies-advertisers": strategiesAdvCollection,
        "campaigns-advertisers": campaignsAdvCollection,
        campaigns: this.campaigns
      };
      let searchValue = e.srcElement.value;
      const dropdown = $(e.target).closest("strand-dropdown")[0];
      const collection = collectionMap[dropdown.id];
      let matches, matchesFormattedForDDL;
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        matches = collection.models.filter(model => model.get("name").toLowerCase().includes(searchValue) || model.id.includes(searchValue));
        matchesFormattedForDDL = matches.map(model => this.dropdownFormatter(model));
      } else {
        matchesFormattedForDDL = collection.models.map(model => this.dropdownFormatter(model));
      }
      dropdown.set("data", matchesFormattedForDDL);
      dropdown.open();
    },
    agencyChanged: function (e) {
      const agencyId = e.target.value;
      if (agencyId) {
        this.loadAdvertisersDropdown(agencyId);
      }
    },
    loadAdvertisersDropdown: function (agencyId) {
      const pixelsDropdown = this.el.find("#pixels-advertisers")[0];
      const pixelsTab = this.findAdvertiserTab("Pixels");
      const collection = new Advertisers({
        agency_id: agencyId
      });
      collection.fetchOptions = {
        sort_by: "name",
        full: "*"
      };
      collection.isPaginated = false;
      collection.singleFetch = true;
      collection.urlFilter.set({
        entity: "agency",
        id: agencyId,
        fetchRequired: false
      });
      collection.fetchAllPages({
        success: response => {
          var advertisersList = response.models.map(advertiser => ({
            name: this.nameIdTruncator(advertiser),
            value: advertiser.id
          }));
          pixelsDropdown.set("data", advertisersList);
        }
      });
    },
    advertiserChanged: function (e) {
      var advertiserId = e.target.value;
      if (advertiserId) {
        switch (e.target.id) {
          case "pixels-advertisers":
            this.eventPixelsCollection.advertiserId = advertiserId;
            break;
          case "strategies-advertisers":
            this.strategiesCollection.advertiserId = advertiserId;
            this.loadCampaignsDropdown(advertiserId);
            break;
          case "campaigns-advertisers":
            this.campaignsCollection.advertiserId = advertiserId;
            break;
        }
      }
    },
    campaignChanged: function (e) {
      if (e.currentTarget.value) {
        this.strategiesCollection.campaignId = e.currentTarget.value;
      }
    },
    loadCampaignsDropdown: function (advertiserId) {
      var self = this;
      var campaignsDropdown = this.el.find(".campaign-selector")[0];
      this.campaigns = new Campaigns();
      this.campaigns.urlFilter.set({
        entity: "advertiser",
        id: advertiserId,
        fetchRequired: false
      });
      this.campaigns.fetchOptions = {
        sort_by: "name"
      };
      this.campaigns.fetchAllPages({
        success: response => {
          var campaignsList = response.models.map(campaign => ({
            name: this.nameIdTruncator(campaign),
            value: campaign.id
          }));
          campaignsDropdown.set("data", campaignsList);
        }
      });
    },
    toggleActiveOnly: function (e) {
      var id = e.target.id;
      var activeOnly = e.target.value;
      var collection;
      switch (id) {
        case "event-pixels-active":
          collection = this.eventPixelsCollection;
          break;
        case "data-pixels-active":
          collection = this.dataPixelsCollection;
          break;
        case "strategies-active":
          collection = this.strategiesCollection;
          break;
        case "campaigns-active":
          collection = this.campaignsCollection;
          break;
      }
      if (collection) {
        collection.active = activeOnly;
      }
    },
    behaviorsLoaded: function (e) {
      const setMultiColumnSelectAllState = grid => {
        _.each(getIncludableFields(grid), field => {
          this.setColumnSelectAllState(grid, field);
        });
      };
      requestAnimationFrame(() => {
        switch (e.target.id) {
          case "event-pixels-collection":
            this.setSelectAllState(this.eventPixelsGrid);
            break;
          case "data-pixels-collection":
            this.setSelectAllState(this.dataPixelsGrid);
            break;
          case "strategies-collection":
            setMultiColumnSelectAllState(this.strategiesGrid);
            break;
          case "campaigns-collection":
            setMultiColumnSelectAllState(this.campaignsGrid);
            break;
        }
        this.toggleIncludeIcons(!this.isMaxSelected(), null);
      });
      function getIncludableFields(grid) {
        var columns = grid.getElementsByClassName("includable");
        return Array.from(columns).map(el => el.field);
      }
    },
    incrementSelectionCount: function (increment) {
      this.behaviorCount += increment;
      if (this.isMaxSelected()) {
        this.footer.showMessage();
        this.toggleIncludeIcons(false, null);
      } else {
        this.footer.hideMessage();
        this.toggleIncludeIcons(true, null);
      }
    },
    toggleIncludeIcons: function (enabled, ctx) {
      ctx = ctx || this.el;
      if (enabled) {
        ctx.find('strand-icon[type="include"').removeClass("disabled");
      } else {
        ctx.find('strand-icon[type="include"').addClass("disabled");
      }
    },
    setSelectAllState: function (grid) {
      var selectAll = grid.querySelector(".select-all");
      var selectedCount = grid.data.filter(function (model) {
        return model.state === "selected";
      }).length;
      this.setState(grid, selectAll, selectedCount);
    },
    setColumnSelectAllState: function (grid, column) {
      var selectAll = $(grid).find('strand-grid-column[field="' + column + '"]')[0].querySelector(".select-all");
      var selectedCount = grid.data.filter(function (model) {
        return model[column + "State"] === "selected";
      }).length;
      this.setState(grid, selectAll, selectedCount);
    },
    setState: function (grid, icon, selectedCount) {
      var totalCount = grid.data.length;
      if (grid === this.dataPixelsGrid) {
        totalCount = this.dataPixelsGrid.data.filter(pixel => pixel.includable).length;
      }
      if (selectedCount === 0) {
        icon.type = "include";
        this.setIconClass(icon, false);
      } else if (selectedCount === totalCount) {
        icon.type = "include";
        this.setIconClass(icon, true);
      } else {
        icon.type = "partial-include";
        this.setIconClass(icon, true);
      }
      if (this.isMaxSelected()) {
        $(icon).addClass("disabled");
      } else {
        $(icon).removeClass("disabled");
      }
    },
    setIconClass: function (icon, selected) {
      var addClass = selected ? "selected" : "deselected";
      var removeClass = selected ? "deselected" : "selected";
      var $icon = $(icon);
      $icon.addClass(addClass);
      $icon.removeClass(removeClass);
    },
    rowsToDeselect: function (grid, selector) {
      return grid.data.filter(function (row) {
        return row[selector] === "selected";
      });
    },
    rowsToSelect: function (grid, selector) {
      var maxToSelect = this.getMaxSelections();
      return grid.data.filter(function (row) {
        return row[selector] === "deselected";
      }).slice(0, maxToSelect);
    },
    shouldDeselectAll: function (icon) {
      return icon.classList.contains("selected") && icon.type === "include" || this.isMaxSelected();
    },
    selectAllHandler: function (e) {
      var icon = e.currentTarget;
      var grid = $(icon).closest("strand-grid")[0];
      var column, columnField;
      if (icon.classList.contains("rows")) {
        this.selectAllRows(icon, grid);
      } else if (icon.classList.contains("columns")) {
        this.selectAllColumn(icon, grid);
      }
    },
    selectAllRows: function (icon, grid) {
      var maxToSelect = this.getMaxSelections();
      var rowsToSelect, rowsToDeselect;
      if (this.shouldDeselectAll(icon)) {
        rowsToDeselect = this.rowsToDeselect(grid, "state");
        selectAll.call(this, rowsToDeselect, grid, false);
      } else {
        rowsToSelect = this.rowsToSelect(grid, "state");
        selectAll.call(this, rowsToSelect, grid, true);
      }
      this.setApplyButtonState();
      function selectAll(rows, selectedGrid, isSelect) {
        _.each(rows, model => {
          this.addOrRemoveRow(model, isSelect, selectedGrid);
        });
      }
    },
    selectAllColumn: function (icon, grid) {
      var column = $(icon).closest("strand-grid-column")[0];
      var field = column.field;
      var rowsToSelect, rowsToDeselect;
      var addOrRemove = grid === this.strategiesGrid ? this.addOrRemoveStrategy.bind(this) : this.addOrRemoveCampaign.bind(this);
      if (this.shouldDeselectAll(icon)) {
        rowsToDeselect = this.rowsToDeselect(grid, field + "State");
        selectAll.call(this, rowsToDeselect, grid, field, false, addOrRemove);
      } else {
        rowsToSelect = this.rowsToSelect(grid, field + "State");
        selectAll.call(this, rowsToSelect, grid, field, true, addOrRemove);
      }
      this.setApplyButtonState();
      function selectAll(rows, selectedGrid, column, isSelect, addFunction) {
        _.each(rows, model => {
          addFunction(model, isSelect, selectedGrid, column);
        });
      }
    },
    isMaxSelected: function () {
      return this.behaviorLimit === this.behaviorCount;
    },
    getMaxSelections: function () {
      return this.behaviorLimit - this.behaviorCount;
    },
    addOrRemoveRow: function (model, isSelected, grid) {
      var index = grid.data.indexOf(model);
      var ownerType = model.category === "dataPixels" ? "agency_name" : "advertiser_name";
      var containsLabel;
      if (grid === this.dataPixelsGrid && !model.includable) {
        return;
      }
      if (isSelected && !BinUtils.inBins.call(this, model.targetId)) {
        BinUtils.addBinLabel.call(this, model[ownerType]);
        model.included = true;
        this.binCollection.push(model);
        this.binlist.set("data", this.sort(this.binCollection));
        grid.set("data." + index + ".state", "selected");
        this.incrementSelectionCount(1);
      }
      if (!isSelected) {
        model.included = false;
        BinUtils.removeFromBinCollection.call(this, model);
        this.binlist.set("data", this.sort(this.binCollection));
        grid.set("data." + index + ".state", "deselected");
        this.incrementSelectionCount(-1);
      }
      this.setApplyButtonState();
      this.setSelectAllState(grid);
    },
    selectStrategyHandler: function (e) {
      var icon = e.currentTarget;
      var model = $(icon).closest("strand-grid-item")[0].model;
      var column = icon.className.indexOf("impression") > -1 ? "impression" : "click";
      var selecting = model[column + "State"] === "deselected";
      this.addOrRemoveStrategy(model, selecting, this.strategiesGrid, column);
      this.setApplyButtonState();
    },
    selectCampaignHandler: function (e) {
      var icon = e.currentTarget;
      var model = $(icon).closest("strand-grid-item")[0].model;
      var column = icon.className.indexOf("allImpressions") > -1 ? "allImpressions" : "";
      var selecting = model[column + "State"] === "deselected";
      this.addOrRemoveCampaign(model, selecting, this.campaignsGrid, column);
      this.setApplyButtonState();
    },
    addOrRemoveStrategy: function (model, isSelected, grid, column) {
      const columns = [{
        name: "click",
        selected: column === "click",
        label: "click"
      }, {
        name: "impression",
        selected: column === "impression",
        label: "impression"
      }];
      this.addOrRemoveColumn(model, isSelected, grid, columns);
    },
    addOrRemoveCampaign: function (model, isSelected, grid, column) {
      const columns = [{
        name: "allImpressions",
        selected: column === "allImpressions",
        label: "All Impressions"
      }];
      this.addOrRemoveColumn(model, isSelected, grid, columns);
    },
    addOrRemoveColumn: function (model, isSelected, grid, columns) {
      const duplicateModel = Object.assign({}, model);
      const index = grid.data.indexOf(model);
      const selectedColumn = columns.find(column => column.selected);
      const propertyName = `${selectedColumn.name}State`;
      if (isSelected) {
        duplicateModel.included = true;
        duplicateModel.name = duplicateModel.name + " - " + selectedColumn.label;
        if (grid == this.strategiesGrid) {
          duplicateModel.clickState = propertyName === "click" ? "selected" : "deselected";
          duplicateModel.impressionState = propertyName === "impression" ? "selected" : "deselected";
        }
        this.binCollection.push(duplicateModel);
        BinUtils.addBinLabel.call(this, model.advertiser_name);
        this.binlist.set("data", this.sort(this.binCollection));
        grid.set("data." + index + "." + propertyName, "selected");
        duplicateModel[propertyName] = "selected";
        this.incrementSelectionCount(1);
      }
      if (!isSelected) {
        duplicateModel.included = false;
        duplicateModel[propertyName] = "deselected";
        BinUtils.removeFromBinCollection.call(this, duplicateModel);
        this.binlist.set("data", this.sort(this.binCollection));
        grid.set("data." + index + "." + propertyName, "deselected");
        this.incrementSelectionCount(-1);
      }
      this.setColumnSelectAllState(grid, selectedColumn.name);
    },
    sort: function (collection) {
      return _.sortBy(collection, function (row) {
        return row.name;
      });
    },
    selectRowHandler: function (e) {
      var self = this;
      var model = e.currentTarget.model;
      var selecting = model.state === "deselected";
      var grid = findGrid(e);
      if (this.isMaxSelected() && selecting) {
        return;
      }
      if (grid === this.eventPixelsGrid || grid === this.dataPixelsGrid) {
        this.addOrRemoveRow(model, selecting, grid);
      }
      function findGrid(e) {
        switch (e.currentTarget.scope.id) {
          case "event-pixels-grid":
            return self.eventPixelsGrid;
          case "data-pixels-grid":
            return self.dataPixelsGrid;
          case "strategies-grid":
            return self.strategiesGrid;
          case "campaigns-grid":
            return self.campaignsGrid;
        }
      }
    },
    dropdownFormatter: function (model) {
      return {
        name: this.nameIdTruncator(model),
        value: model.id
      };
    },
    nameIdTruncator: function (model) {
      return model.get("name") + " (" + model.id + ")";
    },
    segmentDisplayName: function () {
      var fullName = this.segment.model.get("name");
      if (!fullName) {
        return null;
      } else {
        if (fullName.length > 90) {
          return fullName.slice(0, 87) + "...";
        } else {
          return fullName;
        }
      }
    },
    transformBehaviorsForSegment: function (models) {
      return models.map(function (model) {
        model.gridName = model.name;
        if (model.type === "strategy") {
          model.name = model.clickState === "selected" ? "Click" : "Impression";
          model.type = model.clickState === "selected" ? "click" : "impression";
        }
        if (model.type === "campaign") {
          model.name = "All Impressions";
          model.type = "allImpressions";
          model.gridName = `${model.parentName} - All Impressions`;
        }
        return model;
      });
    },
    setApplyButtonState: function () {
      if (this.binlist.data.length > 0) {
        this.applyButton.removeAttribute("disabled");
      } else {
        this.applyButton.setAttribute("disabled", "");
      }
    },
    applySelectedBehaviors: function () {
      var data = this.transformBehaviorsForSegment(this.binlist.data);
      this.addFunction.call(this.segment, data);
      this.hide();
    },
    activeTabChanged: function (e) {
      var tabName = e.target.innerText;
      var tabGrid = this.$('strand-tab[tab-label="' + tabName + '"]').find("strand-grid")[0];
      tabGrid.set("data", tabGrid.data.slice());
    },
    open: function () {
      this.modal.show();
    },
    hide: function () {
      this.binCollection = [];
      this.modal.remove();
    },
    unload: function () {
      $(".agency-selector").unbind();
      this.hide();
      this.el.empty();
      this.remove();
      this.unbind();
    }
  });
  return AddBehaviorV2;
});
