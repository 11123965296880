define('models/reportingMetadata',['require','Underscore','modules/reporting/campaigns/models/additionalData','modules/reporting/campaigns/models/metricCollections','modules/organization/utils/Utils','T1','T1Comm','T1Model','T1Permissions','modules/reporting/campaigns/utils/reportingUtils'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const AdditionalData = require("modules/reporting/campaigns/models/additionalData");
  const MetricCollections = require("modules/reporting/campaigns/models/metricCollections");
  const OrgUtils = require("modules/organization/utils/Utils");
  const T1 = require("T1");
  const T1Comm = require("T1Comm");
  const T1Model = require("T1Model");
  const T1Permissions = require("T1Permissions");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  T1.Models.reportMeta = T1Model.extend({
    urlString: "std/performance/meta",
    url() {
      return T1.RPT_API_ROOT + this.urlString;
    },
    fixture: "-reportMeta",
    action: "read",
    dataType: "json",
    defaultOffset: -29,
    id: "",
    mainMeta: null,
    initialize(options = {}) {
      const metricsToHide = MetricCollections.fees;
      T1Model.prototype.initialize.call(this, options);
      this.prevHasVcrImpression = null;
      this.urlString = options.urlString || this.urlString;
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.url = options.url || this.url;
      this.canCache = Boolean(options.canCache);
      this.mainMeta = options.mainMeta;
      this.mainModel = options.mainModel;
      this.hasViewabilityFlag = T1Permissions.check("feature", "reporting_viewability");
      this.usdMetrics = MetricCollections.currency.map(metric => `${metric}_usd`);
      if (Utils.hideCostsAndFees) {
        AdditionalData.fields = _.omit(AdditionalData.fields, metricsToHide);
        AdditionalData.metrics.all = _.omit(AdditionalData.metrics.all, metricsToHide);
        AdditionalData.metrics.video = _.omit(AdditionalData.metrics.video, metricsToHide);
        AdditionalData.metrics.display = _.omit(AdditionalData.metrics.display, metricsToHide);
        AdditionalData.metricsWinLoss.all = _.omit(AdditionalData.metricsWinLoss.all, metricsToHide);
        AdditionalData.metricsReachFrequency.all = _.omit(AdditionalData.metricsReachFrequency.all, metricsToHide);
        AdditionalData.columns.all = _.omit(AdditionalData.columns.all, metricsToHide);
        AdditionalData.columns.video = _.omit(AdditionalData.columns.video, metricsToHide);
        AdditionalData.columns.display = _.omit(AdditionalData.columns.display, metricsToHide);
        AdditionalData.columns.reach_frequency = _.omit(AdditionalData.columns.reach_frequency, metricsToHide);
      }
      this.additionalData = JSON.parse(JSON.stringify(AdditionalData));
      this.origMetricsVideo = JSON.parse(JSON.stringify(AdditionalData.metrics.video));
      this.origColumnsVideo = JSON.parse(JSON.stringify(AdditionalData.columns.video));
      this.impressionBasedMetrics = Object.keys(this.origMetricsVideo).filter(key => key.includes("_impression_based"));
      this.nonImpressionBasedMetrics = this.impressionBasedMetrics.map(key => key.replace("_impression_based", ""));
    },
    fetch(options = {}) {
      this.loaded = false;
      this.ajx = T1Comm.get({
        apiRoot: T1.RPT_API_ROOT,
        canCache: this.canCache,
        dataType: "json",
        sourceURL: this.url(),
        onSuccess: resp => {
          const isReportMeta = this.id === "reportMeta";
          const section = this._getSection();
          this.loaded = true;
          this.hasNewMetrics = true;
          this.set(resp, options);
          if (isReportMeta) {
            const fields = this.additionalData.fields;
            const structure = resp.structure;
            if (Utils.hideCostsAndFees) {
              structure.metrics = _.omit(structure.metrics, MetricCollections.fees);
            }
            this.updateVCRmetrics(section);
            if (section === "performance") {
              structure.metrics = _.omit(structure.metrics, MetricCollections.facebook);
              if (this.mainModel) {
                if (this._getActiveCurrency() === "usd") {
                  structure.metrics = _.omit(structure.metrics, MetricCollections.currency);
                } else {
                  structure.metrics = _.omit(structure.metrics, this.usdMetrics);
                }
              }
            }
            Object.assign(fields, structure.metrics);
            for (const [, value] of Object.entries(fields)) {
              switch (value.type) {
                case "money":
                  value.type = "currency";
                  break;
                case "count":
                  value.type = "integers";
                  break;
              }
            }
            this.set(JSON.parse(JSON.stringify(this.additionalData)));
          }
          if (options.onSuccess) {
            options.onSuccess(this, resp);
          }
        },
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: "ok"
          };
        }
      });
    },
    abort() {
      if (this.ajx) {
        this.ajx.abort();
      }
    },
    updateVCRmetrics(section) {
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      if (this.prevHasVcrImpression !== hasVcrImpression) {
        this.prevHasVcrImpression = hasVcrImpression;
        const {
          additionalData: additionalData,
          impressionBasedMetrics: impressionBasedMetrics,
          nonImpressionBasedMetrics: nonImpressionBasedMetrics,
          origColumnsVideo: origColumnsVideo,
          origMetricsVideo: origMetricsVideo
        } = this;
        if (section === "performance" || section === "geo" || section === "device_technology") {
          additionalData.metrics.video = hasVcrImpression ? _.omit(origMetricsVideo, nonImpressionBasedMetrics) : _.omit(origMetricsVideo, impressionBasedMetrics);
          additionalData.columns.video = hasVcrImpression ? _.omit(origColumnsVideo, nonImpressionBasedMetrics) : _.omit(origColumnsVideo, impressionBasedMetrics);
        } else {
          additionalData.metrics.video = _.omit(origMetricsVideo, impressionBasedMetrics);
          additionalData.columns.video = _.omit(origColumnsVideo, impressionBasedMetrics);
        }
      }
    },
    getFriendlyName(category, key) {
      const categoryObj = category === "metrics" ? this.getDisplayMetrics() : this.get("structure")[category];
      const field = categoryObj[key];
      return field ? field.name : "";
    },
    getCaseIndex(value, key) {
      return value.indexOf(key);
    },
    setReportModel(reportModel) {
      this.reportModel = reportModel;
    },
    _getSection() {
      return this.mainModel && this.mainModel.get("section") || this.reportModel.get("reportType") || "performance";
    },
    _getFocus(isMetrics, section) {
      const defaultFocus = "all";
      let focus = this.reportModel.get("focus") || defaultFocus;
      if (isMetrics && section === "contextual_insights") {
        focus = defaultFocus;
      }
      return focus;
    },
    _getActiveCurrency() {
      return this.mainModel && this.mainModel.get("currency");
    },
    getRequestMetrics() {
      return this.get("structure").metrics;
    },
    getDisplayMetrics() {
      if (this.hasNewMetrics) {
        const section = this._getSection();
        let metrics = this.getRequestMetrics();
        this.hasNewMetrics = false;
        if (section === "performance" && this._getActiveCurrency() === "usd") {
          metrics = Utils.removeStrFromObjKey(metrics, "_usd");
        }
        if (section === "performance" || section === "geo" || section === "device_technology") {
          const hasVcrImpression = OrgUtils.getOrgVcrSetting();
          metrics = hasVcrImpression ? _.omit(metrics, this.nonImpressionBasedMetrics) : _.omit(metrics, this.impressionBasedMetrics);
        }
        this.internalMetrics = metrics;
      }
      return this.internalMetrics;
    },
    parseMetrics() {
      const metricOptions = [];
      const displayMetrics = this.getDisplayMetrics();
      const lookupTable = this.additionalData;
      const section = this._getSection();
      const focus = this._getFocus(true, section);
      const isPerformance = section === "performance";
      function createMetricOptions(chartMetrics) {
        for (const [key, value] of Object.entries(chartMetrics)) {
          if (!isPerformance && value.onlyInPerformance) {
            continue;
          }
          if (!isPerformance) {
            if (!this.hasViewabilityFlag && value.featureFlag === "reporting_viewability") {
              continue;
            }
          }
          if (displayMetrics[key]) {
            const text = value.text;
            const abbr = value.abbr || text;
            metricOptions.push({
              abbr: abbr,
              text: text,
              type: displayMetrics[key].type,
              value: key
            });
          }
        }
      }
      switch (section) {
        case "reach_frequency":
          createMetricOptions.call(this, lookupTable.metricsReachFrequency[focus]);
          break;
        case "win_loss":
          createMetricOptions.call(this, lookupTable.metricsWinLoss[focus]);
          break;
        default:
          createMetricOptions.call(this, lookupTable.metrics[focus]);
          break;
      }
      this.set({
        metricOptions: metricOptions
      });
    },
    parseDimensions(optionsName, dimensionObj) {
      const optionsObj = {};
      const dimensionOptions = [];
      const apiDimensions = this.get("structure").dimensions;
      const lookupTable = this.get("dimensions");
      const section = this._getSection();
      const focus = this._getFocus(false, section);
      const localDimensions = dimensionObj || lookupTable[section][focus];
      _.each(localDimensions, function (dimObj, dimension) {
        if (apiDimensions[dimension]) {
          dimensionOptions.push({
            text: localDimensions[dimension].text,
            type: apiDimensions[dimension].type,
            value: dimension
          });
        }
      });
      optionsObj[optionsName] = dimensionOptions;
      this.set(optionsObj);
    }
  });
  return T1.Models.reportMeta;
});
