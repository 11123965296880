define('modules/reporting/campaigns/geoPerformance/views/chart',["jQuery", "Underscore", "T1", "T1Chart", "T1ChartBenchmarkOptions", "text!templates/chart_panel.html", "text!../templates/chart_ttip_daily.html", "text!../templates/chart_ttip.html", "text!../../templates/chart_title_box.html", "text!../templates/chart_metric_controls.html", "text!../../templates/select_metric.html", "jQPlugins/jquery.single_dbl_click"], function ($, _, T1, T1Chart, BenchmarkOptions, template, ttipTemplateDaily, ttip_template, titleBox, metricControls, selectMetric) {
  "use strict";

  var chartOptions = {
    template: template,
    padMatrix: {
      bottom: 116,
      left: 130,
      right: 130,
      top: 25
    },
    partials: {
      metric_controls: metricControls,
      select_metric: selectMetric,
      title_box: titleBox
    },
    xAxisLabelRotation: -45,
    xAxisLabelTextAnchor: "end",
    xAxisLabelOffsetY: -110,
    maxW: 250,
    xKey: "country_name",
    yKey: "",
    xAxisLabel: "Countries",
    yAxisLabel: "",
    xAxisSubLabel: "",
    aggregateBy: "country_name",
    dimmedColor: "#e5e5e5",
    dimension: "Country",
    dimensionKey: "country_name",
    highlightedX: -1,
    maxXLabelLength: 18,
    chartView: "summary",
    section: "geoPerformance",
    isCTD: false,
    events: {
      "click .metric-icon": "switchGraph",
      "click .metric-icon2": "switchGraph",
      "click circle.circle": "toggleHighlighted",
      "mouseout g.bar": "barOut",
      "mouseover g.bar": "barOver"
    },
    dataEvents: {
      collection: {
        sort: function () {
          if (this.subSection === "summary") {
            this.reload();
          }
        }
      },
      reportModel: {
        "change:campaignBenchmarkOn": "toggleBenchmark",
        "change:dimensionItems": "reload",
        "change:filteredOutItems": "updateChart",
        "change:hiddenCount": "updateChart",
        "change:highlightedItem": "chart"
      }
    },
    initialize: function (args) {
      var timeSeriesInfo;
      var models = args.models;
      this.campaign = models.campaign;
      this.cmodel = models.summary;
      this.mainModel = models.mainModel;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.subSection = this.mainModel.get("geoSubSection");
      this.summaryCollection = args.dataGroup.collection;
      this.dailyCollection = args.dataGroup.dailyCollection;
      this.timeSeries = args.type;
      this.timeSeriesInfo = timeSeriesInfo = models.timeSeries.daily;
      this.timeSeriesLabel = timeSeriesInfo.label;
      if (timeSeriesInfo.chartDateFormat) {
        this.dateformat = timeSeriesInfo.chartDateFormat;
      }
      this.xAxisLabelAdjustment = () => 0;
      this.toolTipDateFormatter = {
        Daily: function (str) {
          var dateObj = T1.Date.parse(str);
          return dateObj && dateObj.toString("ddd, MMM d, yyyy");
        }
      };
    },
    switchGraph: function (event) {
      var $el = this.el;
      var $clickedIcon = $(event.currentTarget);
      var $icons = $el.find(".metric-icon").add($el.find(".metric-icon2"));
      var publish = T1.EventHub.publish;
      var subSection = this.subSection;
      event.stopPropagation();
      if (!$clickedIcon.hasClass("selected")) {
        $icons.toggleClass("selected");
        if (subSection === "daily") {
          subSection = "summary";
          publish("select:chart", "summary");
        } else {
          subSection = "daily";
          publish("select:chart", "daily");
        }
        this.subSection = subSection;
        this.load();
      }
    },
    prepareRenderData: function () {
      var geoInfo, sortedCollection;
      var reportModel = this.reportModel;
      var geoNav = reportModel.get("geoNav");
      var reportMetaObj = this.reportMetaObj;
      var subSection = this.subSection;
      var timeSeriesInfo = this.timeSeriesInfo;
      var focus = reportModel.get("focus") || "all";
      var dim1Info = reportModel.get("dimensionInfo");
      var isCTD = this.isCTD = reportModel.get("isCTD");
      var metricKey = this.reportMetaObj.metrics[focus];
      this.subSection = isCTD ? "summary" : subSection;
      if (this.subSection === "daily") {
        sortedCollection = $.extend({}, this.dailyCollection);
        sortedCollection.sort();
        if (geoNav) {
          geoInfo = reportMetaObj.geoLevelConfig[geoNav.level];
          dim1Info = geoInfo.dimension;
          this.xKey = this.dimensionKey = geoInfo.dimension;
          this.dimension = this.dimensionName = geoInfo.aggregate_level;
        }
        this.aggregateBy = dim1Info;
        this.chartView = "series";
        this.collection = sortedCollection;
        this.maxW = 10;
        this.padMatrix = {
          bottom: 55,
          left: 130,
          right: 130,
          top: 25
        };
        this.xAxisLabel = timeSeriesInfo.xAxisLabel;
        this.xAxisLabelOffsetY = -42;
        this.xAxisLabelRotation = 0;
        this.xAxisLabelTextAnchor = "middle";
        this.xKey = timeSeriesInfo.xKey;
      } else {
        if (geoNav) {
          geoInfo = reportMetaObj.geoLevelConfig[geoNav.level];
          this.xKey = this.dimensionKey = geoInfo.dimension;
          this.dimension = this.dimensionName = geoInfo.aggregate_level;
          this.xAxisLabel = reportMetaObj.fields[this.dimensionKey].name;
        }
        this.aggregateBy = null;
        this.chartView = "summary";
        this.collection = $.extend({}, this.summaryCollection);
        this.maxW = 250;
        this.padMatrix = {
          bottom: 116,
          left: 130,
          right: 130,
          top: 25
        };
        this.xAxisLabelOffsetY = -110;
        this.xAxisLabelRotation = -45;
        this.xAxisLabelTextAnchor = "end";
      }
      this.yKey = reportModel.get("metric");
      this.yKey = metricKey[this.yKey] ? this.yKey : this.reportMetaObj.metricOptions[0].value;
      this.yAxisLabel = reportMetaObj.metrics[focus][this.yKey].text;
    },
    barOver: function (e) {
      this.overLabel = this.wChartBody.find(`#xlb${d3.select(e.currentTarget)[0][0].__data__.x}`);
      this.overLabelWasVisible = this.overLabel.css("visibility") === "visible";
      this.overLabel.css("visibility", "visible");
    },
    barOut: function () {
      if (!this.overLabelWasVisible) {
        this.overLabel.css("visibility", "hidden");
      }
    },
    toggleHighlighted: function (e) {
      var id = d3.select(e.currentTarget)[0][0].__data__[this.dimensionKey];
      var newID = id && id.replace(/[']/g, "");
      var highlighted = this.reportModel.get("highlightedItem");
      e.stopPropagation();
      if (this.subSection === "daily") {
        this.reportModel.setHighlightedItem({
          from: "line",
          id: highlighted && highlighted.id === newID ? null : newID,
          type: newID ? "strategy" : "campaign"
        });
      } else {
        this.reportModel.setHighlightedItem({
          from: "bar",
          id: highlighted && highlighted.id === newID ? null : newID,
          type: "strategy"
        });
      }
    },
    setupHighlight: function () {
      var i, id, obj;
      var isDaily = this.subSection === "daily";
      var reportModel = this.reportModel;
      var highlightedItem = reportModel.get("highlightedItem");
      var campaignType = highlightedItem && highlightedItem.type === "campaign";
      var chartColors = this.reportMeta.get("chartColors");
      var colorData = reportModel.get("colorData");
      var highlightedIndex = -1;
      var highlightedID = highlightedItem ? highlightedItem.id : null;
      var allColor = !highlightedItem || highlightedItem.id === null;
      var dataStack = this.dataStack;
      var len = dataStack[0].length;
      if (len > 0) {
        if (isDaily) {
          len = dataStack.length;
          for (i = 0; i < len; i++) {
            obj = dataStack[i][0];
            id = obj[this.dimensionKey];
            id = id && id.replace(/[']/g, "");
            if (allColor || !campaignType && id === highlightedID) {
              if (id === highlightedID) {
                highlightedIndex = i;
              }
              obj.color = chartColors[colorData[id]];
            } else {
              obj.color = this.dimmedColor;
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            obj = dataStack[0][i];
            id = obj[this.dimensionKey];
            id = id && id.replace(/[']/g, "");
            if (allColor || !campaignType && id === highlightedID) {
              obj.color = chartColors[colorData[id]];
              this.highlightedX = obj.x;
            } else {
              obj.color = this.dimmedColor;
            }
          }
        }
      }
      if (isDaily) {
        if (highlightedIndex > -1) {
          dataStack.push(dataStack.splice(highlightedIndex, 1)[0]);
        }
      } else {
        if (!highlightedItem || !highlightedItem.id) {
          this.highlightedX = -1;
        }
      }
      return this;
    },
    navInto: function (e) {
      var data = e.currentTarget.__data__;
      var countryName = data.country_name;
      var regionName = data.region_name;
      var reportModel;
      if (countryName) {
        $(document).find(".tipsy").remove();
        reportModel = this.reportModel;
        reportModel.setHighlightedItem();
        reportModel.geoNavigate("into", regionName || countryName, countryName);
      }
    },
    graphGoalVsActual: function () {
      var campaign = this.campaign;
      var goalValue = this.getGoalValue();
      var goalType = campaign.get("goal_type");
      var showGoal = !this.mainModel.get("multiCampaigns") && this.matchedMetricKeyToGoalType(this.yKey, goalType);
      var campaignValue = this.cmodel.get(this.yKey) || 0;
      var difference = campaignValue - goalValue;
      if (showGoal) {
        this.graphBenchMark({
          dash: "4,3",
          difference: difference,
          mark: goalValue,
          name: "goal"
        });
      }
      this.setBenchmarkVisibility();
      return this;
    },
    graphYValues: function () {
      if (this.subSection === "daily") {
        const MAX_CROWD_LEVEL = 2;
        return this.graphYAxisLabels(true).graphLines({
          circleRadius: 2.5,
          circleStrokeColor: "#fff",
          hideCircles: this.crowdLevel === MAX_CROWD_LEVEL
        }).graphGoalVsActual();
      }
      return this.graphYAxisLabels(true).graphBars(this).graphGoalVsActual();
    },
    getMaxBenchmarks: function () {
      return {
        campaignGoalValue: this.getGoalValue(),
        campaignMaxValue: 0
      };
    },
    activateToolTip: function () {
      var self = this;
      $(this.el).on("onChartComplete", function () {
        if (self.subSection === "daily") {
          self.attachTooltips({
            header: function () {
              return self.yAxisLabel;
            },
            paths: ["circle.circle"],
            renderInfo: function (data) {
              var formatter = self.toolTipDateFormatter[self.timeSeriesLabel];
              return formatter(data.xLabel);
            },
            renderKey: function (data) {
              return data[self.dimensionKey];
            },
            template: ttipTemplateDaily
          });
        } else {
          self.attachTooltips({
            paths: ["circle.line", "g.bar"],
            template: ttip_template,
            header: function () {
              return self.yAxisLabel;
            }
          });
        }
      });
    },
    activateSelectbox: function () {
      var self = this;
      var selectMetricUIs = $(self.el).find(".select-metric");
      var publish = T1.EventHub.publish;
      T1.Selectbox(selectMetricUIs, {
        dropDownRightPosition: "14px",
        highlightInSearchResult: true,
        no_results_text: "No results found for",
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search",
        onChange: function (e) {
          var select = $(e.target);
          self.yKey = select.val();
          self.yAxisLabel = select.find("option:selected").text();
          self.updateDataBind({
            chart_title: self.getTitle(),
            chart_subtitle: self.getSubtitle()
          }, self.el);
          publish("select:metric", self.yKey);
          publish("track:event", {
            action: "Choose metric",
            label: self.yKey
          });
          self.update();
        }
      });
    },
    activateSingleDoubleClick: function () {
      var el = this.el;
      var $proxy = $.proxy;
      el.single_dbl_click_off();
      el.single_dbl_click("g.bar", $proxy(this.toggleHighlighted, this), $proxy(this.navInto, this));
      this.geoPerformancePanel.off("dehighlight").on("dehighlight", function () {
        el.single_dbl_click_clear();
      });
    },
    load: function () {
      var el = this.el;
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      this.geoPerformancePanel = el.closest(".w-geo-performance-panel");
      this.wChartBody = el.find(".w-chart-body");
      if (this.campaign.id) {
        this.activateToolTip();
        this.activateSelectbox();
        if (this.subSection === "summary") {
          this.updateXAxisSubLabels();
          this.activateSingleDoubleClick();
          this.wChartBody = this.el.find(".w-chart-body");
        }
      }
    },
    unload: function () {
      this.el.single_dbl_click_off();
      if (this.geoPerformancePanel) {
        this.geoPerformancePanel.off("dehighlight");
      }
    },
    updateXAxisSubLabels: function () {
      var sortKeyInfo = this.reportModel.get("sortKeyInfo") || "";
      var sortKeyArray = sortKeyInfo.split(",");
      if (sortKeyArray.length > 0 && sortKeyArray[0] !== "") {
        this.xAxisSubLabel = `(Sorted by ${this.reportMetaObj.fields[sortKeyArray[0].toLowerCase()].name})`;
      }
    },
    getTitle: function () {
      return `${this.yAxisLabel} by ${this.dimension}`;
    },
    getSubtitle: function () {
      var chartCount, groupedData;
      var isDaily = this.subSection === "daily";
      var reportModel = this.reportModel;
      var collection = this.collection;
      var yAxisLabel = this.yAxisLabel;
      var dim = this.dimension;
      var colorData = reportModel.get("colorData");
      var hiddenCount = colorData ? reportModel.getHiddenItemsCount() : 0;
      var total = 0;
      if (colorData) {
        if (isDaily) {
          groupedData = collection.groupBy(model => model.attributes[this.dimensionKey]);
          total = _.size(groupedData);
        } else {
          total = collection.length;
        }
      }
      if (hiddenCount === 0) {
        chartCount = ` (all ${total})`;
      } else {
        chartCount = total > 0 ? ` (${total - hiddenCount} of ${total})` : ` (0 of ${total})`;
      }
      if (isDaily) {
        return `Lines show ${this.timeSeriesLabel.toLowerCase()} ${yAxisLabel} by ${dim}${chartCount}`;
      }
      return `Bars show summary of ${yAxisLabel} by ${dim}${chartCount}`;
    },
    updateChart: function () {
      this.updateDataBind({
        chart_subtitle: this.getSubtitle()
      }, this.el);
      this.processData().chart();
    },
    serialize: function () {
      var isDaily = this.subSection === "daily";
      var metricOptions = this.reportMetaObj.metricOptions;
      var yKey = this.yKey === "video_complete_rate_impression_based" ? "video_complete_rate" : this.yKey;
      $.each(metricOptions, function (i, option) {
        option.selected = option.value === yKey;
      });
      return $.extend(T1Chart.prototype.serialize(), {
        barChart: !isDaily,
        chart_subtitle: this.getSubtitle(),
        chart_title: this.getTitle(),
        chartLevel: this.reportModel.getGeoNav().level,
        isCTD: this.isCTD,
        lineChart: isDaily,
        metrics: metricOptions,
        showMetricIcon: true
      });
    }
  };
  return T1Chart.extend(BenchmarkOptions.extend(chartOptions));
});
