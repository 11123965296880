define('modules/creatives/display/views/details',["require", "jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Form", "T1Permissions", "T1FileUpload", "T1Spinner", "models/atomicCreative", "models/advertiser", "models/hybridAd", "models/concept", "../models/dimensions", "collections/organizations", "collections/vendors", "text!../templates/details.html", "text!../templates/additional_info.html", "text!templates/adx_error_display.html", "text!../../templates/infobox.html", "utils/CreativeUtils", "modules/creatives/display/views/previewT1AS", "T1Notifier"], function (require, $, _, When, T1, T1View, T1Layout, T1Form, T1Permissions, T1FileUpload, Spinner, AtomicCreative, Advertiser, HybridAd, Concept, Dimensions, Organizations, Vendors, template, additionalInfoPartial, adxErrorPartial, templateInfoBox, CreativeUtils, PreviewT1ASDialog) {
  "use strict";

  var DetailsView;
  var dropdownList = {
    file_type: {
      options: [{
        value: "gif",
        text: "GIF"
      }, {
        value: "html5",
        text: "HTML5"
      }, {
        value: "swf",
        text: "SWF"
      }, {
        value: "jpg",
        text: "JPG"
      }, {
        value: "unknown",
        text: "unknown"
      }]
    },
    has_sound: {
      options: [{
        value: "0",
        text: "No"
      }, {
        value: "1",
        text: "Yes"
      }]
    },
    ad_server_type: {
      options: [{
        value: "ATLAS",
        text: "Atlas"
      }, {
        value: "DART",
        text: "DFA"
      }, {
        value: "EYEWONDER",
        text: "EyeWonder"
      }, {
        value: "MEDIAFORGE",
        text: "mediaFORGE"
      }, {
        value: "MEDIAPLEX",
        text: "Mediaplex"
      }, {
        value: "POINTROLL",
        text: "Pointroll"
      }, {
        value: "MEDIAMIND",
        text: "Sizmek MDX"
      }, {
        value: "YIELD_MANAGER",
        text: "Yield Manager"
      }, {
        value: "OTHER",
        text: "Other"
      }, {
        value: "DOUBLECLICK",
        text: "DoubleClick"
      }, {
        value: "FLASHTALKING",
        text: "flashtalking"
      }, {
        value: "ADOCADO",
        text: "adocado"
      }, {
        value: "SIZMEK",
        text: "Sizmek"
      }]
    },
    expansion_trigger: {
      options: [{
        value: "AUTOMATIC",
        text: "Automatic"
      }, {
        value: "MOUSEOVER",
        text: "Mouse-Over to Expand"
      }, {
        value: "CLICK",
        text: "Click to Expand"
      }]
    },
    tpas_ad_format: {
      options: [{
        value: "DISPLAY",
        text: "Display"
      }, {
        value: "MOBILE",
        text: "Mobile"
      }, {
        value: "EXPANDABLE",
        text: "Expandable"
      }]
    },
    t1as_ad_format: {
      options: [{
        value: "DISPLAY",
        text: "Display"
      }, {
        value: "MOBILE",
        text: "Mobile"
      }]
    },
    tag_type: {
      options: [{
        value: "SCRIPT",
        text: "Javascript"
      }, {
        value: "IFRAME",
        text: "Iframe"
      }, {
        value: "IMG",
        text: "Image"
      }]
    }
  };
  var creativeTemplates = {
    t1as: "../templates/createEditT1ASBody.html",
    tpas: "../templates/createEdit3PASBody.html"
  };
  function getTemplatePath(isT1AS) {
    var type = isT1AS ? "t1as" : "tpas";
    return creativeTemplates[type];
  }
  function getTemplateBody(isT1AS) {
    var deferred = When.defer();
    var tmplPath = getTemplatePath(isT1AS);
    require(["text!" + tmplPath], function (tmpl) {
      deferred.resolve(tmpl);
    });
    return deferred.promise;
  }
  DetailsView = T1View.extend({
    template: template,
    partials: {
      additionalInfo: additionalInfoPartial,
      adxErrorDisplay: adxErrorPartial
    },
    actionsConfig: {
      events: {
        "click .cancel": "cancelOverride",
        "click .saveAndClose": "saveAndClose"
      }
    },
    events: {
      "click .expansion-direction input[type=checkbox]": "onExpansionDirectionCheck",
      'click input[name="dimension"]': "onDimensionClicked",
      "keyup .dimension .form-input": "onDimensionPress",
      'click input[name="html-edit"]': "onHtmlEditClicked",
      "change .html-code": "checkHtmlEdit",
      "click .save-btn-secondary": "onShowPreview",
      "click .savePreview": "createPreSavePreview",
      "click .asset-upload .icon": "onPreviewAssetClicked",
      "click .asset-review .icon-group": "onPreviewAssetClicked",
      "keyup .html-code": "activatePreview",
      "click .dimension .form-select .chzn-container .chzn-drop .chzn-results": "hidePreviewError",
      "keyup .width": "hidePreviewError",
      "keyup .height": "hidePreviewError"
    },
    eventhubEvents: {
      "vendor:list:updated": function () {
        this.isFormElementsChanged = true;
      },
      "dropdown:concepts": "onConceptChanged",
      "dropdown:concepts:selection": "onConceptChanged",
      "hybridAdReview:approve": "hybridApprove",
      "hybridAdReview:reject": "hybridReject"
    },
    isFormElementsChanged: false,
    componentCreationDate: "2016-11-01T00:00:00",
    initialize: function (args) {
      var self = this;
      var mode = args.mode;
      self.mode = mode;
      self.isEdit = args.id !== undefined;
      self.model = args.model;
      if (self.model.get("ad_format") === "FBX" && Date.parse(self.model.get("created_on")) >= Date.parse(this.componentCreationDate)) {
        T1.Location.set("#creatives/displays");
        return;
      }
      self.initializeData(args);
    },
    hybridApprove: function () {
      var displayState = "Approved for Use";
      this.hybridState(displayState);
    },
    hybridReject: function () {
      var displayState = "Rejected for Use";
      this.hybridState(displayState);
    },
    hybridState: function (displayState) {
      this.hybridAdState = displayState;
      $(".asset-review").find(".ad-status").html("(" + displayState + ")");
      $(".asset-review").find(".alert").hide();
    },
    setFormActions: function () {
      var self = this;
      if (!self.isEdit) {
        self.actionsConfig.formActions = [{
          label: "cancel",
          class: "cancel",
          location: false
        }, {
          label: "preview",
          class: "btn-secondary savePreview disabled",
          location: false
        }, {
          label: "save",
          class: "save btn-primary",
          location: false
        }, {
          label: "save & continue",
          class: "saveAndContinue btn-primary",
          location: false
        }];
      } else {
        self.actionsConfig.formActions = [{
          label: "cancel",
          class: "cancel",
          location: "#"
        }, {
          label: "preview",
          class: "save-btn-secondary",
          location: false
        }, {
          label: "save",
          class: "save btn-primary",
          location: false
        }, {
          label: "save & continue",
          class: "saveAndContinue btn-primary",
          location: false
        }];
      }
    },
    initializeData: function (args) {
      var self = this;
      var model = this.model;
      var id;
      args = args || {};
      id = parseInt(args.id || model.get("id"));
      this.orgID = args.orgID;
      if (!this.vendorCollection) {
        this.vendorCollection = model.get("manualVendors");
      }
      if (id) {
        this.isEdit = true;
        this.isT1AS = model.get("t1as") === "1";
        this.hybridAdState = model.get("mm_hybrid_ad_state");
        this.isHybrid = this.hybridAdState === "accepted" ? true : false;
        if (!this.hybridAdState || this.hybridAdState === "not_used" || this.hybridAdState === "needs_conversion") {
          this.isHybridActive = false;
        } else {
          this.isHybridActive = true;
          if (this.hybridAdState === "ready") {
            this.hybridAdReview = true;
          } else if (this.hybridAdState === "pending") {
            this.isHybridPending = true;
          }
        }
        if (this.isT1AS) {
          this.isEditedTag = model.get("edited_tag") !== model.get("default_t1as_tag");
        }
      }
      getTemplateBody(self.isT1AS).then(function (templateBody) {
        self.canLoad = true;
        self.selectedTemplate = templateBody;
        self.updateModels(args).then(function (argModel) {
          T1.EventHub.publish("creative:updateModel", {
            model: argModel
          });
          self.load();
        });
      });
    },
    updateModels: function (args) {
      var self = this;
      var deferred = When.defer();
      var model = self.model;
      var id, loader;
      var $currentEl = $(this.el);
      $currentEl.append('<div class="view-loader" style="padding-left:100px;" />');
      loader = T1.Loader.create({
        target: ".view-loader",
        text: "Loading ...",
        loaderClass: "loader",
        context: $currentEl
      });
      loader.start();
      args = args || {};
      id = parseInt(args.id || model.get("id"));
      if (id) {
        model.set({
          id: id
        });
        self.isEdit = true;
        model.fetch({
          params: {
            with: ["advertiser,agency", "creatives,vendor_pixels,vendor_pixel_domains,vendor_domain,vendor", "creatives,creative_assets", "atomic_creative_ssl_validators", "atomic_creative_detected_tag_urls,vendor_domain,vendor", "built_by_user"]
          },
          success: function (data) {
            model.set({
              isActive: data.get("status") === "1",
              isSecure: data.get("is_https") === "1"
            });
            self.isT1AS = data.get("t1as") === "1";
            self.autoVendorCollection = data.get("autoVendors");
            deferred.resolve(model);
          }
        });
      } else {
        model.set({
          action: "new",
          status: "1",
          manualVendors: new Vendors(),
          autoVendors: new Vendors()
        });
        deferred.resolve(model);
      }
      return deferred.promise;
    },
    initLayout: function (templateBody) {
      var self = this;
      var model = self.model;
      var orgID = self.orgID;
      var advertiserID = model.get("advertiser_id");
      var conceptID = model.get("concept_id") ? model.get("concept_id") : null;
      var createEditLayout;
      createEditLayout = {
        ".advertiser-select": [{
          module: "shared",
          viewType: "advertiserSelect",
          options: {
            entity: "advertisers",
            selectedId: advertiserID,
            dataBind: "advertiser_id",
            boundEntity: "organizations",
            boundEntityId: orgID,
            isSearchable: true,
            initialLoad: true,
            placeholder: "Select an Advertiser",
            preSelect: "Select an Advertiser",
            notFound: "No Advertisers Found!",
            refreshEvent: "refresh",
            preRender: function () {},
            defaultSortParams: {
              fields: {
                name: {
                  actual: "name",
                  display: "alphabetically"
                }
              },
              default: {
                sort_by: "name",
                order_by: "ascending"
              }
            }
          }
        }],
        ".concept-select": [{
          module: "shared",
          viewType: "select",
          options: {
            selectedId: conceptID,
            boundEntityId: advertiserID,
            dataBind: "concept_id",
            preSelect: "Default (same as 'Name')",
            notFound: "No Concepts Found",
            placeholder: "Default (same as 'Name')",
            isSearchable: true,
            clearOnClose: true,
            initialLoad: self.isEdit,
            isPaginated: false,
            extraLinkLabel: "+ Add New Concept",
            extraLinkClass: "extra-link-add clearfix",
            defaultSortParams: {
              fields: {
                name: {
                  actual: "name",
                  display: "alphabetically"
                }
              },
              default: {
                sort_by: "name",
                order_by: "ascending"
              }
            },
            extraLinkHandler: function (e) {
              e.preventDefault();
              e.stopImmediatePropagation();
              T1.EventHub.publish("popup:show");
            },
            disableCheck: function (count, hasId) {
              return !hasId;
            }
          }
        }],
        ".concept-popup": [{
          module: "concept",
          viewType: "popup",
          options: {
            advertiser_id: advertiserID
          }
        }],
        ".date-picker": [{
          module: "creatives",
          viewType: "date_picker",
          options: {
            startDate: model.get("start_date"),
            endDate: model.get("end_date"),
            startDataBind: "start_date",
            endDataBind: "end_date",
            showCheckboxes: true,
            showTitle: false,
            showScheduleCheck: true,
            startDateCheckLabel: "Strategy Start Date",
            endDateCheckLabel: "Strategy End Date",
            onChanged: function () {
              self.isFormElementsChanged = true;
            }
          }
        }],
        ".vendor-list": [{
          module: "vendor",
          viewType: "boxList",
          options: {
            showVendorTooltip: true,
            clearSearchInputAfterDelay: false,
            collection: self.vendorCollection,
            model: self.model
          }
        }],
        ".auto-vendor-list": [{
          module: "vendor",
          viewType: "autoList",
          options: {
            showVendorTooltip: true,
            collection: self.autoVendorCollection,
            model: self.model,
            isEdit: self.isEdit
          }
        }]
      };
      if (self.isEdit) {
        createEditLayout[".strategy-list-body"] = [{
          module: "shared",
          viewType: "strategy_list",
          options: {
            conceptID: conceptID
          }
        }];
      }
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".details",
        template: templateBody,
        partials: self.partials,
        serialize: $.proxy(self.serialize, self),
        layout: createEditLayout
      });
    },
    load: function () {
      var self = this;
      if (!this.canLoad) {
        return;
      }
      this.setFormActions();
      this.render().then(function () {
        self.initLayout(self.selectedTemplate);
        self.layout.load().then(function () {
          self.renderPlugins();
          if (!self.isEdit) {
            var $advDDL = $(".advertiser-select", self.el);
            $advDDL.find("._wrapper").addClass("ad-wrapper");
            $(self.el).not('div[class="ad-wrapper"]').click(function () {
              var $chosenDDL = $advDDL.find("ul");
              if ($chosenDDL.hasClass("search-results")) {
                self.layout.layout[".advertiser-select"][0].view.reloadResults();
                $chosenDDL.removeClass("search-results");
              }
            });
          }
        });
      });
    },
    reload: function () {
      this.load();
    },
    makeTooltips: function (context) {
      context = context || this.el;
      T1.Tooltip("body", {
        fade: false,
        tooltipEle: context.find(".t1-tooltip").not(".adx-warning, .adx-warning-list, .adx-error, .adx-error-list")
      });
      T1.Tooltip("body", {
        fade: false,
        tooltipEle: context.find(".adx-warning, .adx-warning-list"),
        className: "adx-warning-tipsy"
      });
      T1.Tooltip("body", {
        fade: false,
        tooltipEle: context.find(".adx-error, .adx-error-list"),
        className: "adx-error-tipsy"
      });
    },
    renderPlugins: function (context) {
      var self = this;
      var $additionalInfo;
      var defaultSelectConfig = {
        onChange: function (args) {
          self.removeTooltip(args);
        }
      };
      context = context || self.el;
      $additionalInfo = context.find(".additional-info");
      T1.Selectbox(context.find(".ad-format-select"), {
        onChange: function (args) {
          var value = args.target.value;
          var serializedData;
          self.model.set({
            ad_format: value
          });
          if (value === "EXPANDABLE") {
            $additionalInfo.slideDown();
            $additionalInfo.removeAttr("data-bind");
          } else {
            $additionalInfo.slideUp();
            $additionalInfo.attr("data-bind", "hidden");
          }
          serializedData = self.serialize();
          self.renderPartialTemplate({
            template: self.layout.template,
            targetEl: ".additional-info",
            templateTarget: ".additional-info",
            context: context,
            data: serializedData
          });
          T1.Selectbox(context.find(".expansion-trigger-select"), defaultSelectConfig);
          if (!self.isT1AS) {
            self.renderPartialTemplate({
              template: self.layout.template,
              targetEl: ".dimension-select",
              templateTarget: ".dimension-select",
              context: context,
              data: serializedData
            });
            context.find(".dimension-select").trigger("liszt:updated");
          }
          self.removeTooltip(args);
          self.makeTooltips(context);
        }
      });
      T1.Selectbox(context.find(".expansion-trigger-select"), defaultSelectConfig);
      T1.Selectbox(context.find(".ad-server-select"), defaultSelectConfig);
      T1.Selectbox(context.find(".file-type-select"), defaultSelectConfig);
      T1.Selectbox(context.find(".ad-tag-type-select"), defaultSelectConfig);
      T1.Selectbox(context.find(".dimension-select"), defaultSelectConfig);
      T1.Selectbox(context.find(".audio-select"), defaultSelectConfig);
      self.activateInputMasks();
      self.makeTooltips(context);
      if (self.isT1AS) {
        self.renderFileUploader();
      }
    },
    renderFileUploader: function () {
      var self = this;
      var model = self.model;
      var fileType = model.get("file_type");
      var imageTypes = "jpg|png|gif";
      var validTypes = {
        primary: "image/jpeg,image/png,image/gif,application/x-shockwave-flash,application/zip",
        backup: "image/jpeg,image/png,image/gif",
        expandable: "image/jpeg,image/png,image/gif"
      };
      if (!self.isT1MRAID || !self.mraidExpandFile || self.mraidExpandFile === "--") {
        self.el.find(".asset-upload[data-role='expandable']").remove();
      }
      if (imageTypes.indexOf(fileType) !== -1 || self.isT1MRAID) {
        self.el.find(".asset-upload[data-role='backup']").remove();
      }
      self.fileHandles = {};
      self.el.find(".asset-upload").each(function (i, el) {
        var $el = $(el);
        var role = $el.data("role");
        var file = model.get("creative").get("creative_asset").getAssetName(role);
        var uploaderTarget = $el.find(".file-uploader");
        var uploader;
        uploader = T1FileUpload.create({
          $el: uploaderTarget,
          gatherFileInfo: true,
          onChange: $.proxy(self.onAssetChanged, self, role)
        });
        uploader.render({
          buttonLabel: "Upload New Asset",
          placeholder: file || "--",
          accept: validTypes[role]
        });
        uploader.isValid = true;
        self.fileHandles[role] = uploader;
      });
    },
    onConceptChanged: function () {
      var $el = this.el;
      var $selectParent = $el.find(".concept-select");
      var $select = $selectParent.find(".select");
      $select.trigger("change");
      if (this.isEdit) {
        this.el.find(".concept-warning").slideDown();
      }
    },
    onExpansionDirectionCheck: function (e) {
      var $target = $(e.target);
      var isUnrestricted;
      isUnrestricted = $target.val() === "NONRESTRICTED" && $target.is(":checked");
      function toggleUnrestricted(isDisabled) {
        $target.closest("dd").find(".checkbox").not($target).attr("disabled", isDisabled).closest(".form-checkbox").toggleClass("disabled", isDisabled);
      }
      if (isUnrestricted) {
        toggleUnrestricted(true);
      } else {
        toggleUnrestricted(false);
      }
    },
    onDimensionClicked: function (e) {
      var $target = $(e.target);
      var $parent;
      var isCustom = e.target.value === "0";
      $parent = $target.closest("dl");
      $parent.find(".dimension-select").attr("disabled", isCustom).trigger("liszt:updated").closest("dd").toggleClass("disabled", isCustom);
      $parent.find("input[type=text]").attr("disabled", !isCustom).closest("dd").toggleClass("disabled", !isCustom);
    },
    onDimensionPress: function (e) {
      var $input = $(e.target);
      if ($input.val() === "0") {
        $input.val("");
      }
    },
    checkHtmlEdit: function () {
      var $htmlEditor = this.el.find(".textarea.html-code");
      $htmlEditor.val($.trim($htmlEditor.val()));
    },
    onHtmlEditClicked: function (e) {
      var enable = e.target.value === "1";
      var $htmlEditor = this.el.find(".textarea.html-code");
      var model = this.model;
      if (enable && !this.isEditedTag) {
        this.isEditedTag = true;
        this.resetHybridTag = false;
        $htmlEditor.removeAttr("disabled");
        $htmlEditor.val(model.get("edited_tag"));
      } else if (!enable && this.isEditedTag) {
        this.isEditedTag = false;
        model.set({
          edited_tag: $htmlEditor.val()
        });
        $htmlEditor.attr("disabled", "disabled");
        if (this.hybridAdState) {
          $htmlEditor.removeClass("error");
          $htmlEditor.val("Retrieving the original ad tag will occur after Save" + "and may take a few moments. Please refresh after 10 seconds.");
          this.resetHybridTag = true;
        } else {
          $htmlEditor.val(model.get("default_t1as_tag"));
        }
      }
    },
    onShowPreview: function () {
      CreativeUtils.showPreview([this.model.get("id")]);
    },
    onPreviewAssetClicked: function (e) {
      var $target = $(e.target);
      var role = $target.closest(".form-list").data("role");
      PreviewT1ASDialog.showDialog({
        model: this.model,
        role: role,
        hybridAdState: this.hybridAdState
      });
    },
    onAssetChanged: function (role) {
      var self = this;
      var uploader = self.fileHandles[role];
      this.isFormElementsChanged = true;
      function handleSuccess() {
        self.hideUploaderError(uploader);
        T1.Notify("message", "Asset successfully uploaded!");
      }
      function handleError(e) {
        self.showUploaderError(uploader, e, role);
        T1.Notify("error", "Asset upload failed");
      }
      self.uploadAsset(role).then(handleSuccess, handleError);
    },
    showUploaderError: function (uploader, e, role) {
      var $el = uploader.el.closest(".asset-upload");
      var fileInput = $el.find(".file-name .text-input");
      var uploadError = $el.find(".upload-error");
      var errorData = {
        type: "error",
        message: e.error
      };
      var roleText;
      if (e && e.status && e.status === 413) {
        roleText = role === "primary" ? "main" : role;
        errorData.message = T1.Utils.capitalize(roleText) + " asset upload failed: the new asset file size is too large. The max file size is 2.15MB";
      }
      fileInput.addClass("error");
      this.renderPartialTemplate({
        template: templateInfoBox,
        context: $el,
        targetEl: ".upload-error",
        useTemplateHtml: false,
        data: errorData
      });
      uploadError.show();
    },
    hideUploaderError: function (uploader) {
      var $el = uploader.el.closest(".asset-upload");
      var fileInput = $el.find(".file-name .text-input");
      var uploadError = $el.find(".upload-error");
      fileInput.removeClass("error");
      uploadError.slideUp();
    },
    validateUploaders: function () {
      var self = this;
      var errors = [];
      $.each(this.fileHandles, function (role, uploader) {
        if (!uploader.isValid) {
          self.showUploaderError(uploader, {
            error: "Invalid File - Please upload a file with the same original type and dimensions"
          });
          errors.push(role);
        }
      });
      if (errors.length > 0) {
        return errors;
      }
    },
    uploadAsset: function (role) {
      var deferred = When.defer();
      var uploader = this.fileHandles[role];
      var model = this.model;
      var creativeID = model.get("creative").get("creative_asset").getAssetID(role);
      var spinnerTarget = uploader.el.find(".file-name");
      var spinnerOpts = {
        length: 3,
        width: 2,
        radius: 2
      };
      Spinner.apply(spinnerTarget, spinnerOpts);
      spinnerTarget.find(".spinner").css({
        left: "96%"
      });
      uploader.isValid = false;
      uploader.upload({
        sourceURL: T1.API_ROOT + "creative_assets/upload_single",
        data: {
          file_role: role.toUpperCase(),
          creative_id: creativeID
        },
        onSuccess: function () {
          uploader.isValid = true;
          spinnerTarget.spin(false);
          deferred.resolve();
        },
        onStatusInvalid: function (e) {
          uploader.isValid = false;
          spinnerTarget.spin(false);
          deferred.reject(e);
        },
        onError: function (e) {
          uploader.isValid = false;
          spinnerTarget.spin(false);
          deferred.reject(e);
        },
        errorDisplaySuppressingConfig: {
          errorSuppressed: true,
          errorCodes: [413]
        }
      });
      return deferred.promise;
    },
    canUnload: function (args) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var tagType = this.isT1AS ? "TerminalOne Ad Tag" : "3rd Party Ad Tag";
      var message = this.isEdit ? "Edit " + tagType : "Add " + tagType;
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: message
        }, args);
      }
      return !isFormElementsChanged;
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgID;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    getPreSaveAdvertiser: function () {
      var self = this;
      var $el = $(self.el);
      var deferred = $.Deferred();
      var advertiser, agency, advertiserData;
      this.formValues = T1.Form.dataAsObject($el);
      advertiser = new Advertiser({
        id: this.formValues.advertiser_id
      });
      if (advertiser.get("id") !== "") {
        advertiser.fetch({
          params: {
            with: ["agency"]
          },
          success: function (data) {
            agency = data.get("agency").get("name");
            advertiserData = data.get("name");
            deferred.resolve(agency, advertiserData);
          }
        });
        return deferred.promise();
      } else {
        return null;
      }
    },
    activatePreview: function () {
      var self = this;
      var $el = self.el.find(".form-body");
      this.formValues = T1.Form.dataAsObject($el);
      if (this.formValues.tag) {
        $(".savePreview").removeClass("disabled");
      } else {
        $(".savePreview").addClass("disabled");
      }
      self.hidePreviewError();
    },
    showPreviewError: function () {
      var self = this;
      var $el = $(self.el);
      var $tagField = $el.find("textarea");
      var $dimensionSelect = $el.find(".dimension .form-select .chzn-container .chzn-single");
      var $dimensionRadio = $el.find(".dimension .form-input");
      var $widthField = $el.find("input.width");
      var $heightField = $el.find("input.height");
      var errorConfig;
      var message = "This is a required field";
      errorConfig = {
        trigger: "manual",
        getExternalTip: false,
        className: "w-FormError w-review-error-tooltip",
        offset: 10
      };
      this.formValues = T1.Form.dataAsObject($el);
      function showToolTip(field, isSelect) {
        field.addClass("error");
        errorConfig.tooltipEle = field;
        errorConfig.trigger = isSelect ? "hover" : "click";
        errorConfig.gravity = "s";
        field.attr("title", message);
        T1.Tooltip(field, errorConfig);
      }
      if (this.formValues.tag === "") {
        showToolTip($tagField, false);
      }
      if (this.formValues.dimension === "") {
        showToolTip($dimensionSelect, true);
      }
      if (!$($dimensionRadio).hasClass("disabled") && this.formValues.custom_width === "") {
        $dimensionSelect.removeClass("error");
        showToolTip($widthField, false);
      }
      if (!$($dimensionRadio).hasClass("disabled") && this.formValues.custom_height === "") {
        $dimensionSelect.removeClass("error");
        showToolTip($heightField, false);
      }
      self.footer = $el.find(".messages");
      self.footer.html('<div class="error-message"><b></b><div>There are errors on the page.</div></div>');
      self.footer.type = "error";
    },
    hidePreviewError: function () {
      var self = this;
      var $el = $(self.el);
      var $tagField = $el.find("textarea");
      var $dimensionSelect = $el.find(".dimension .form-select .chzn-container .chzn-single");
      var $widthField = $el.find("input.width");
      var $heightField = $el.find("input.height");
      this.formValues = T1.Form.dataAsObject($el);
      function removeErrorToolTip(field) {
        field.removeClass("error");
        field.attr("original-title", "");
        $(".w-review-error-tooltip").hide();
      }
      if (this.formValues.tag !== "") {
        removeErrorToolTip($tagField);
      }
      if (this.formValues.dimension !== "") {
        removeErrorToolTip($dimensionSelect);
      }
      if (this.formValues.custom_width !== "") {
        removeErrorToolTip($widthField);
      }
      if (this.formValues.custom_height !== "") {
        removeErrorToolTip($heightField);
      }
      if (this.formValues.tag !== "" && (this.formValues.dimension !== "" && this.formValues.dimensionType === "1" || this.formValues.custom_height !== "" && this.formValues.custom_width !== "")) {
        self.footer = $el.find(".messages");
        self.footer.html('<div class="unsaved-message"><b></b><div>There are unsaved changes on the page.</div></div>');
      }
    },
    createPreSavePreview: function () {
      var self = this;
      var $el = self.el.find(".form-body");
      var $dimensionRadio = $el.find(".dimension .form-input");
      var $previewButton = $(".savePreview");
      var getAgencyAndAdvertiserName = self.getPreSaveAdvertiser();
      var direction = _.chain($el.find(".expansion-direction input:checked").not('[value="NONRESTRICTED"]')).map(function ($item) {
        return $($item).val();
      }).value().join(",");
      var advertiser_name, agency_name, tag, dimension, height, width, secure, name, expandable, mraid;
      this.formValues = T1.Form.dataAsObject($el);
      tag = this.formValues.tag;
      dimension = this.formValues.dimension;
      height = $($dimensionRadio).hasClass("disabled") ? dimension.split("x")[1] : this.formValues.custom_height;
      width = $($dimensionRadio).hasClass("disabled") ? dimension.split("x")[0] : this.formValues.custom_width;
      secure = this.formValues.is_https === "1";
      name = this.formValues.name || null;
      expandable = direction.indexOf("L") >= 0;
      mraid = this.formValues.is_mraid === "1";
      if (tag === "" || width === undefined || width === "" || height === undefined || height === "") {
        self.showPreviewError();
      } else {
        if (getAgencyAndAdvertiserName === null) {
          advertiser_name = null;
          agency_name = null;
          CreativeUtils.generatePreSavePreview(tag, height, width, name, expandable, advertiser_name, agency_name, secure, mraid);
        } else {
          Spinner.apply($previewButton.addClass("save-action"));
          getAgencyAndAdvertiserName.then(function (agency, advertiser) {
            advertiser_name = advertiser;
            agency_name = agency;
            $previewButton.spin(false);
            $previewButton.removeClass("save-action");
            CreativeUtils.generatePreSavePreview(tag, height, width, name, expandable, advertiser_name, agency_name, secure, mraid);
          });
        }
        self.hidePreviewError();
      }
    },
    preparePostData: function (formValues) {
      var self = this;
      var model = self.model;
      var $el = self.el.find(".form-body");
      var advertiserId = $el.find(".advertiser-select").find("ul").attr("id");
      var direction;
      formValues = T1.Form.dataAsObject($el);
      if (formValues.ad_format === "EXPANDABLE") {
        if ($el.find('.expansion-direction input[value="NONRESTRICTED"]').is(":checked")) {
          direction = "NONRESTRICTED";
        } else {
          direction = _.chain($el.find(".expansion-direction input:checked").not('[value="NONRESTRICTED"]')).map(function ($item) {
            return $($item).val();
          }).value().join(",");
        }
        formValues.expansion_direction = direction;
      }
      formValues = this.vendorCollection.getFormValues(formValues);
      if (advertiserId) {
        formValues.advertiser_id = advertiserId;
      } else if (model && model.get("advertiser_id")) {
        formValues.advertiser_id = model.get("advertiser_id");
      } else {
        formValues.advertiser_id = "";
      }
      if (this.isT1AS && !this.resetHybridTag) {
        formValues.edited_tag = this.isEditedTag ? formValues.edited_tag : model.get("default_t1as_tag");
      } else if (this.resetHybridTag) {
        formValues.edited_tag = model.get("edited_tag") ? model.get("edited_tag") : "Retrieving the original ad tag will occur after a few moments. Please refresh.";
      }
      if (!this.isT1AS && !this.isEdit) {
        formValues.tpas_ad_tag_name = "not_applicable";
      }
      if (typeof formValues["search-term"] === "string") {
        delete formValues["search-term"];
      }
      return formValues;
    },
    postData: function (formValues, success, statusInvalid, conflict) {
      var self = this;
      var model = self.model;
      var deferred = $.Deferred();
      var errors, hybridModel, hybridOptions;
      if (self.isT1AS) {
        errors = self.validateUploaders();
        if (errors) {
          statusInvalid([], null, ".form-body");
          return;
        }
      }
      model.createEdit(formValues, {
        success: function (data) {
          if (!formValues.concept_id) {
            self.conceptModel = new Concept({
              action: "new"
            });
            self.conceptModel.save({
              name: formValues.name,
              status: "1",
              advertiser_id: formValues.advertiser_id || self.advertiserID
            }, {
              success: $.proxy(function () {
                deferred.resolve(self.conceptModel);
                model.save({
                  concept_id: self.conceptModel.id
                }, {
                  success: function () {
                    modelSaved();
                  }
                });
              })
            });
          } else {
            modelSaved();
          }
          function modelSaved() {
            hybridModel = new HybridAd();
            hybridOptions = {
              errorDisplaySuppressingConfig: {
                errorSuppressed: true
              }
            };
            T1.Notify("message", "Creative " + (self.isEdit ? "updated" : "created") + " successfully!");
            T1.Location.set("#creatives/display/" + data.id + "/details", {
              silent: true,
              replaceHistoryStack: true
            });
            if (self.resetHybridTag) {
              hybridModel.url = "atomic_creatives/" + data.id + "/reset_edited_tag";
              hybridModel.save({}, hybridOptions);
            }
            if (!self.getSaveAndContinueMode()) {
              self.initializeData();
            } else {
              T1.EventHub.publish("creative:updateModel");
            }
            success(model);
          }
        },
        statusInvalid: function (invalidErrors) {
          if (invalidErrors && invalidErrors.length) {
            _.map(invalidErrors, function (error) {
              error.field = error.field === "height" ? "custom_height" : error.field === "width" ? "custom_width" : error.field;
              return error;
            });
          }
          statusInvalid(invalidErrors, null, ".form-body");
        },
        conflict: conflict
      });
    },
    onVersionConflictClose: function () {
      this.initializeData();
    },
    cancelCreateEdit: {
      onAlertClose: function () {
        T1.Location.set("#creatives/displays");
      }
    },
    cancelOverride: function (e, fromUnloadAlertDlg) {
      var isFormElementsChanged = this.isFormElementsChanged;
      this.preventDefault(e);
      e.stopImmediatePropagation();
      if (fromUnloadAlertDlg === true || !isFormElementsChanged) {
        this.cancelCreateEdit.onAlertClose();
      } else {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Display Creative Edit" : "New Display Creative"
        }, this.cancelCreateEdit);
      }
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          setTimeout(function () {
            T1.Location.set("#creatives/displays");
          }, 1e3);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    getCheckedAndOrDisabled: function () {
      var model = this.model;
      var expansionDir = model ? model.get("expansion_direction") : "";
      var expansionDirections = expansionDir ? expansionDir.split(",") : "NONRESTRICTED";
      return function (dir) {
        var isUnrestricted = expansionDirections.indexOf("NONRESTRICTED") >= 0;
        var isChecked = !isUnrestricted && expansionDirections.indexOf(dir) >= 0;
        return (isUnrestricted ? ' disabled="disabled"' : "") + (isChecked ? ' checked="checked"' : "");
      };
    },
    getAgencyAndAdvertiserName: function () {
      var advertiser, agency, agencyDisplay;
      advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        agency = advertiser.get("agency");
        agencyDisplay = agency ? agency.get("name") + "&nbsp;&raquo;&nbsp;" : "";
        return agencyDisplay + advertiser.get("name");
      }
    },
    serialize: function () {
      var model = this.model;
      var output = model.toJSON();
      var vendors, length;
      var sslValidator = output.atomic_creative_ssl_validator;
      var detectedUrls = output.atomic_creative_detected_tag_url;
      output.isEdit = this.isEdit;
      output.isT1AS = this.isT1AS;
      output.isEditedTag = this.isEditedTag;
      if (this.hybridAdState === "accepted") {
        this.hybridAdState = "Approved for Use";
      } else if (this.hybridAdState === "rejected") {
        this.hybridAdState = "Rejected for Use";
      }
      output.hybridAdState = this.hybridAdState;
      output.isHybridActive = this.isHybridActive;
      output.isHybridPending = this.isHybridPending;
      output.hybridAdReview = this.hybridAdReview;
      output.isActive = output.status === "1";
      output.isSecure = output.is_https === "1";
      output.adServerOptions = dropdownList.ad_server_type.options;
      output.adFormatOptions = this.isT1AS ? dropdownList.t1as_ad_format.options : dropdownList.tpas_ad_format.options;
      output.fileTypeOptions = dropdownList.file_type.options;
      output.adTagTypeOptions = dropdownList.tag_type.options;
      output.expansionTriggerOptions = dropdownList.expansion_trigger.options;
      output.audioOptions = dropdownList.has_sound.options;
      output.isExpandable = output.ad_format === "EXPANDABLE";
      output.isFBX = output.ad_format === "FBX";
      output.showMRAID = !output.isFBX;
      if (!output.isFBX) {
        output.isMRAID = output.is_mraid === "1";
      }
      output.isT1MRAID = this.isT1MRAID = this.isT1AS && output.isMRAID;
      if (output.isExpandable) {
        output.isExpansionUnrestricted = output.expansion_direction ? output.expansion_direction === "NONRESTRICTED" : true;
        output.getCheckedAndOrDisabled = $.proxy(this.getCheckedAndOrDisabled, this);
      }
      output.dimensionOptions = Dimensions.getDimensions(output.ad_format || "DISPLAY");
      output.isCustomDimension = output.width && output.height ? Dimensions.isCustomDimension(output.ad_format, output.width, output.height) : false;
      if (!output.isCustomDimension) {
        output.dimension = output.width + "x" + output.height;
      } else {
        output.custom_width = output.width;
        output.custom_height = output.height;
      }
      if (output.isEdit) {
        output.advertiser_name = output.advertiser ? output.advertiser.name : "ADVERTISER";
        output.agencyAndAdvertiser = this.getAgencyAndAdvertiserName();
      }
      output.tpas_ad_tag_name = output.tpas_ad_tag_name === "not_applicable" ? "Not Applicable" : output.tpas_ad_tag_name;
      if (this.isT1MRAID) {
        this.mraidExpandFile = model.get("creative").get("creative_asset").getAssetName("expandable");
        if (this.mraidExpandFile) {
          output.asset_primary = model.get("creative").get("creative_asset").getAssetName("primary");
          output.asset_backup = this.mraidExpandFile;
        }
      } else if (this.isT1AS) {
        this.backupImgFile = model.get("creative").get("creative_asset").getAssetName("backup");
        output.asset_primary = model.get("creative").get("creative_asset").getAssetName("primary");
        output.asset_backup = this.backupImgFile;
      }
      output.hasADXError = output.hasADXError && !output.autoHasADXError && !output.manualHasADXError;
      if (sslValidator && sslValidator.ignored !== "YES") {
        output.ksizeWarning = sslValidator.ksize > 200;
        output.sslSecurity = true;
        output.non_secure_calls = nonSecureCallData();
        sslValidator.is_https = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
      } else {
        output.sslSecurity = false;
      }
      function nonSecureCallData() {
        var non_secure_calls = {};
        var vendorId;
        switch (sslValidator.is_https.toLowerCase()) {
          case "no":
            output.secureWarning = true;
            if (detectedUrls) {
              vendors = {};
              detectedUrls = detectedUrls.length ? detectedUrls : [detectedUrls];
              length = detectedUrls.length;
              for (var i = 0; i < length; i++) {
                if (detectedUrls[i].is_https === "YES") {
                  continue;
                }
                vendorId = detectedUrls[i].vendor_domain_id || "Unknown vendor";
                if (vendors.hasOwnProperty(vendorId)) {
                  vendors[vendorId].urls.push(detectedUrls[i].url);
                } else {
                  vendors[vendorId] = {
                    name: detectedUrls[i].vendor_domain ? detectedUrls[i].vendor_domain.vendor.name : "Unknown vendor",
                    urls: [detectedUrls[i].url]
                  };
                }
              }
              non_secure_calls.data = _.values(vendors);
            }
            break;
          case "inactive":
            non_secure_calls.message = "N/A - creative is inactive and paused from security scanning.";
            break;
          case "pending":
            non_secure_calls.message = "N/A - pending detection.";
            break;
          case "yes":
            output.verifiedSecure = true;
            non_secure_calls.message = "None found - creative is secure.";
            break;
          case "error":
          case "unknown":
            output.sslSecurity = false;
            non_secure_calls.message = "N/A - creative could not be scanned. Self declared security status is in use.";
            break;
        }
        return non_secure_calls;
      }
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(DetailsView);
});
