define('modules/campaign/bulkedit/creatives/views/list',["Underscore", "jQuery", "T1", "T1View", "T1Layout", "text!../templates/grid_item.html", "text!../templates/grid_footer.html", "text!../templates/grid_subItem.html", "T1GridView", "T1Spinner", "collections/creatives", "utils/CreativeUtils", "utils/VideoUtils", "T1Tooltip"], function (_, $, T1, T1View, T1Layout, gridItem, gridFooter, gridSubItem, createGrid, Spinner, Creatives, CreativeUtils, VideoUtils) {
  "use strict";

  var CreativesListView = T1View.extend({
    partials: {
      listItem: gridItem,
      gridFooter: gridFooter,
      gridSubItem: gridSubItem
    },
    headers: [{
      name: "name",
      label: "concept name"
    }, {
      name: "creative-id",
      label: "creative id"
    }, {
      name: "size",
      label: "size"
    }, {
      name: "secure",
      label: "secure"
    }, {
      name: "date-added",
      label: "date added"
    }, {
      name: "start-date",
      label: "start date"
    }, {
      name: "end-date",
      label: "end date"
    }],
    name: "strategyConceptList",
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    events: {
      "click .item": "toggle",
      "click .item .icon.include": "includeTarget",
      "click .jumpToConcept": "handleJumpToModel",
      "mouseenter .topitem": "showConceptLink",
      "mouseleave .topitem": "hideConceptLink",
      "click .jumpToCreative": "handleJumpToModel",
      "mouseenter .subitem": "showCreativeLink",
      "mouseleave .subitem": "hideCreativeLink"
    },
    creativesCollections: {},
    initialize: function ({
      sharedCollection: sharedCollection
    }) {
      this.searchTerm = "";
      this.sharedCollection = sharedCollection;
      this.getConcepts();
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".concept"
    },
    eventhubEvents: {
      "strategyBulkEditCreatives.showActive": function (flag) {
        const settings = {
          fetchRequired: true,
          success: data => {
            this.setAction(data);
          }
        };
        this.showActiveOnly = flag;
        this.setSearchLoader();
        if (this.showActiveOnly) {
          settings.params = [{
            term: flag ? "1" : "0",
            searchField: "status"
          }];
        }
        this.collection.search.set(settings);
      },
      "bins:droppedBulkEditConcept": function ({
        selectedList: selectedList
      }) {
        var collection = this.collection;
        for (let i = 0; i < selectedList.length; i++) {
          this.addTarget(collection.get(selectedList[i].id));
        }
      },
      "strategyBulkEditConcepts:bulkeditActionChanged": function () {
        this.toggleStageChangesBtn();
      }
    },
    dataEvents: {
      sharedCollection: {
        add: function (model) {
          var id = model.has("concept") ? model.get("concept").id : model.id;
          var action = model.get("action");
          const $parent = this.$(`[data-id="${id}"]`).parent();
          $parent.addClass("selected").find(`.icon.${action}`).addClass("active");
          $parent.find(".children").addClass("parent-selected");
          this.toggleStageChangesBtn();
        },
        remove: function (model) {
          var id = model.has("concept") ? model.get("concept").id : model.id;
          const $parent = this.$(`[data-id="${id}"]`).parent();
          $parent.removeClass("selected").find(".icon").removeClass("active");
          $parent.find(".children").removeClass("parent-selected");
          this.toggleStageChangesBtn();
        }
      },
      collection: {
        reset: "load",
        search: "search",
        "search.clear": "clearSearch",
        fetchMoreComplete: "onFetchMoreComplete"
      }
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 300
    },
    clearSearch: function () {
      var collection = this.collection;
      this.searchTerm = "";
      collection.search.clear();
      if (this.showActiveOnly) {
        collection.search.set({
          params: [{
            term: "1",
            searchField: "status"
          }]
        });
      }
      collection.fetch({
        success: data => {
          this.setAction(data);
        }
      });
    },
    search: function (term) {
      var collection = this.collection;
      if (this.showActiveOnly) {
        collection.search.set({
          params: [{
            term: term,
            searchField: "name|id"
          }, {
            term: "1",
            searchField: "status"
          }]
        });
      } else {
        collection.search.set({
          params: [{
            term: term,
            searchField: "name|id"
          }]
        });
      }
      this.searchTerm = term;
      this.setSearchLoader();
      collection.fetch({
        success: data => {
          this.setAction(data);
        }
      });
    },
    showActiveOnly: true,
    unload: function () {
      this.clearSearch();
      if (this.paginationLayout && this.paginationLayout.unload) {
        this.paginationLayout.unload();
      }
    },
    addChildren: function ({
      entityId: conceptId = "",
      collection: collection,
      container: container
    }) {
      const creativesColl = collection || this.getCreativesCollection(conceptId);
      const childrenContainer = container || this.$(`.children-${conceptId}`);
      const serializedData = this.serialize(creativesColl.toJSON());
      var boundAddChildren = this.addChildren.bind(this);
      $.each(serializedData.list, function (index, creativeData) {
        var creativeName = creativeData.name;
        var truncLength = 21;
        const adxTruncLength = 19;
        if (creativeData.hasADXError) {
          truncLength = adxTruncLength;
        }
        creativeData.showName = creativeData.name;
        if (creativeName.length > truncLength) {
          creativeData.showName = `${$.trim(creativeName).substring(0, truncLength)}...`;
        }
      });
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: childrenContainer,
        data: serializedData
      });
      if (creativesColl.count > creativesColl.pageLimit) {
        creativesColl.entityType = "Creative";
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: `.pagination-${conceptId}`,
          layout: {}
        });
        this.paginationLayout.layout[`.pagination-${conceptId}`] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: creativesColl,
            onSuccess: boundAddChildren,
            indent: "margin: 0 -55px 0 55px"
          }
        }];
        this.paginationLayout.load();
      }
      this.makeTooltips(childrenContainer);
      this.updateScrollBar();
    },
    getConcepts: function () {
      function success(data) {
        for (let i = 0; i < data.models.length; i++) {
          data.models[i].set({
            action: "include"
          });
        }
      }
      this.collection.search.set({
        params: [{
          term: "1",
          searchField: "status"
        }],
        fetchRequired: true,
        success: success
      });
    },
    getCreativesCollection: function (conceptId) {
      if (!this.creativesCollections[conceptId]) {
        const creativesColl = new Creatives([], {
          isPaginated: true,
          pageLimit: 100,
          fetchOptions: {
            with: ["creatives,vendor_pixels,vendor_pixel_domains,vendor_domain,vendor", "atomic_creative_ssl_validators"]
          }
        });
        creativesColl.urlFilter.set({
          entity: "concept",
          id: conceptId
        });
        creativesColl.parent = conceptId;
        this.creativesCollections[conceptId] = creativesColl;
      }
      return this.creativesCollections[conceptId];
    },
    handleJumpToModel: function () {},
    showConceptLink: function (e) {
      $(e.target).find(".concept-link-icon-hold").show();
      $(".topLink").show();
    },
    hideConceptLink: function () {
      $(".concept-link-icon-hold").hide();
    },
    showCreativeLink: function (e) {
      $(e.target).find(".creative-link-icon-hold").show();
      $(".subLink").show();
    },
    hideCreativeLink: function () {
      $(".creative-link-icon-hold").hide();
    },
    toggleStageChangesBtn: function () {
      const $parents = this.el.parents();
      var $stageChangesBtn = $parents.find(".stage");
      var $stageAndReviewBtn = $parents.find(".stageAndReview");
      const isEnabled = Boolean(this.sharedCollection.models.length);
      if (!isEnabled) {
        $stageChangesBtn.addClass("disabled");
        $stageAndReviewBtn.addClass("disabled");
      } else {
        $stageChangesBtn.removeClass("disabled");
        $stageAndReviewBtn.removeClass("disabled");
      }
    },
    toggle: function (evt) {
      var self = this;
      var item = $(evt.currentTarget);
      var arrow = item.find(".arrow");
      var iconArrow = arrow.find(".icon-arrow");
      var iconSpinner = arrow.find(".icon-spinner");
      var state = iconArrow.hasClass("expanded");
      var conceptId = item.data("id");
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var paginationContainer = item.closest(".item-wrapper").next(".pagination");
      if (!arrow.length || arrow.hasClass("hidden") || $(evt.target).hasClass("jumpToConcept") || $(evt.target).hasClass("jumpToCreative")) {
        return;
      }
      this.preventDefault(evt);
      if (state) {
        iconSpinner.spin(false);
        iconArrow.removeClass("expanded loading");
        childrenContainer.hide();
        paginationContainer.hide();
        this.updateScrollBar();
      } else {
        const creativesColl = this.getCreativesCollection(conceptId);
        iconArrow.addClass("loading expanded hide");
        Spinner.apply(iconSpinner, this.spinnerOpts);
        if (this.showActiveOnly) {
          creativesColl.search.set({
            params: [{
              term: "1",
              searchField: "status"
            }]
          });
        } else {
          creativesColl.search.clear();
        }
        creativesColl.fetch({
          success: function () {
            const models = creativesColl.models;
            const videos = models ? models.filter(model => model.get("media_type") === "video") : [];
            function present() {
              iconSpinner.spin(false);
              iconArrow.removeClass("loading hide");
              if (childrenContainer.is(":visible")) {
                self.addChildren({
                  entityId: conceptId
                });
              } else {
                childrenContainer.show();
                if (creativesColl.count > creativesColl.pageLimit) {
                  paginationContainer.show();
                }
              }
              self.updateScrollBar();
            }
            if (videos.length) {
              VideoUtils.processCreativesVideoMixins(videos, present);
            } else {
              present();
            }
          }
        });
      }
    },
    makeTooltips: function (context) {
      T1.Tooltip(context, {
        tooltipEle: context.find(".adx-error"),
        className: "adx-error-tipsy"
      });
      T1.Tooltip(context, {
        tooltipEle: context.find(".adx-warning"),
        className: "adx-warning-tipsy"
      });
    },
    includeTarget: function (evt) {
      var currentTarget = $(evt.currentTarget);
      var id = currentTarget.closest(".item").attr("data-id");
      this.preventDefault(evt);
      this.removeDragClasses();
      this.addTarget(this.collection.get(id));
    },
    addTarget: function (item) {
      const itemInSharedCollection = this.sharedCollection.get(item.id);
      if (itemInSharedCollection) {
        this.sharedCollection.remove(itemInSharedCollection);
      } else {
        this.sharedCollection.add(item);
      }
      const args = {
        item: item.toJSON(),
        wasAdded: itemInSharedCollection === undefined
      };
      T1.EventHub.publish("formElementsChanged:creatives");
      T1.EventHub.publish("strategyBulkEditConcepts:add-remove", args);
    },
    onFetchMoreComplete: function () {
      this.setAction();
    },
    setAction: function (col = this.collection) {
      var models = col.models;
      for (let i = 0; i < col.length; i++) {
        const model = models[i];
        if (!model.get("action")) {
          model.set({
            action: "include"
          });
        }
      }
    },
    load: function () {
      T1.EventHub.publish("strategyCreativesLoader:stop");
      this.render().then(() => {
        this.toggleStageChangesBtn();
        if (!this.loaderSet && !this.collection.loaded) {
          this.loaderSet = true;
          this.setSearchLoader();
        }
        T1.Tooltip(this.el, {
          el: ".text",
          offset: "4"
        });
      });
    },
    serialize: function (collectionData) {
      let list = collectionData || this.collection.toJSON();
      var dateFormat = "MM/dd/yy";
      var dateParse = T1.Date.parse;
      const sharedCollection = this.sharedCollection;
      var highlightText = T1.Utils.highlightText;
      const hasSearchTerm = this.searchTerm && this.searchTerm.length;
      const searchTerm = this.searchTerm;
      var resultData = [];
      var isArrayData = $.isArray(list);
      function processData(data) {
        data = !isArrayData ? data.toJSON() : data;
        data.created_on = dateParse(data.created_on).toString(dateFormat);
        data.start_date = data.start_date ? dateParse(data.start_date).toString(dateFormat) : "--";
        data.end_date = data.end_date ? dateParse(data.end_date).toString(dateFormat) : "--";
        data.auto_detected_vendors = CreativeUtils.parseVendorListForDisplay(data.autoVendors);
        data.manual_vendors = CreativeUtils.parseVendorListForDisplay(data.manualVendors);
        data.error_vendors = CreativeUtils.parseVendorListForDisplay(data.adxErrorVendors);
        const sslValidator = data.atomic_creative_ssl_validator;
        if (sslValidator && sslValidator.ignored !== "YES") {
          data.sslSecurity = true;
          data.secureWarning = sslValidator.is_https === "NO";
          data.verifiedSecure = sslValidator.is_https === "YES";
          if (sslValidator.is_https === "ERROR" || sslValidator.is_https === "UNKNOWN") {
            data.sslSecurity = false;
          }
          sslValidator.is_https = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
        } else {
          data.sslSecurity = false;
        }
        if (collectionData) {
          data.statusClass = data.status !== "1" ? "inactive" : "";
        } else {
          if (hasSearchTerm) {
            data.name = highlightText(data.name, searchTerm);
          }
        }
        if (sharedCollection.get(data.id)) {
          data.isIncluded = true;
        }
        return data;
      }
      this.errInfoList = [];
      list = !isArrayData ? list.models : list;
      for (let i = 0; i < list.length; i++) {
        resultData.push(processData(list[i]));
      }
      return {
        list: resultData
      };
    }
  });
  return createGrid(CreativesListView);
});
