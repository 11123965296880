define('collections/pixels',["T1", "T1Collection", "models/pixel", "collections/pixelUniques", "collections/organizations", "jQuery"], function (T1, T1Collection, Pixel, PixelUniques, Organizations, $) {
  "use strict";

  T1.Models.Pixels = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: Pixel,
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }, {
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: false
      }, {
        entity: "dataprovider",
        fetchOnChange: false
      }],
      enableEvents: true
    },
    setOrganization: function (orgId) {
      var id = orgId || "000000";
      this.urlFilter.set({
        entity: "organization",
        id: id
      });
    },
    setAdvertiser: function (advID) {
      this.urlFilter.set({
        entity: "advertiser",
        id: advID
      });
    },
    setDataProvider: function (proID) {
      this.urlFilter.set({
        entity: "dataprovider",
        id: proID
      });
    },
    fetch: function (opts) {
      if (this.isPaginated) {
        var self = this;
        var initCount = this.length;
        var models;
        var newColl;
        var silent = opts !== undefined ? opts.silent : false;
        this.isAdding = opts !== undefined ? opts.add : false;
        this.successCallback = opts !== undefined ? opts.success : this.successCallback;
        opts = opts || {};
        opts.silent = true;
        opts.success = function (data, resp) {
          if (self.isAdding) {
            models = self.models.slice(initCount);
          } else {
            models = self.models;
          }
          if (self.noUniquesData) {
            self.successCallback(data, resp);
          } else {
            if (models.length) {
              newColl = new T1Collection(models);
              self.fetchUniquesData(newColl, data, resp, silent);
            } else if (self.successCallback) {
              self.successCallback(data, resp);
            } else {
              self.reset(self.toJSON());
            }
          }
        };
        T1Collection.prototype.fetch.call(this, opts);
      } else {
        T1Collection.prototype.fetch.call(this, opts);
      }
    },
    pixelLoadsWindow: "yesterday",
    fetchUniquesData: function (collection, pixelData, pixelResp, silent) {
      var pixelIds = collection.pluck("id");
      var self = this;
      var uniquesCollection = new PixelUniques();
      var options = {};
      var json = collection.toJSON();
      if (json[0].pixel_type === "event") {
        uniquesCollection.urlString = "event_pixel_loads";
      } else {
        uniquesCollection.urlString = "data_pixel_loads";
      }
      options.success = function (uniquesResp) {
        var splitArr;
        var totalArr;
        var topRefArr;
        if (self.doesNotHaveData(uniquesResp, options)) {
          self.pixelLoadsWindow = "last_2_days";
          uniquesCollection.fetchLastTwoDays(options);
          return;
        }
        self.pixelLoadsWindow = uniquesCollection.fetchOptions.time_window;
        function splitReferrerTypes(data) {
          return data.referrer === "Total" ? true : false;
        }
        splitArr = _.partition(uniquesResp, splitReferrerTypes);
        totalArr = splitArr[0];
        topRefArr = splitArr[1];
        for (var i = 0; i < totalArr.length; i++) {
          var currentPixel = totalArr[i].pixel_id;
          var model = self.get(currentPixel);
          var topRef = _.find(topRefArr, function (p) {
            return p.pixel_id === currentPixel;
          });
          totalArr[i].referrer = topRef ? topRef.referrer : "No referrers";
          if (model) {
            model.set({
              unique: totalArr[i]
            });
          }
        }
        if (!silent && !self.isFetching) {
          self[options.add ? "add" : "reset"](self.toJSON(), options);
        }
        if (self.successCallback) {
          self.successCallback(pixelData, pixelResp);
        }
        if (self.isFetching) {
          self.isFetching = false;
        }
      };
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        uniquesCollection.fetchOptions = {
          dimensions: "pixel_name,pixel_id,referrer",
          filter: "organization_id=" + orgId + "&pixel_id=" + pixelIds + "&referrer_rank=01,9999",
          time_window: "yesterday",
          time_rollup: "by_day"
        };
        if (this.isAdding) {
          uniquesCollection.fetchOptions.time_window = this.pixelLoadsWindow;
        } else {
          this.pixelLoadsWindow = "yesterday";
        }
        if (uniquesCollection.url().length > this.uniquesLimit) {
          this.successCallback(pixelData, pixelResp);
        } else {
          uniquesCollection.fetch(options);
        }
      }, this));
    },
    doesNotHaveData: function (data) {
      return data.length === 0 && this.page === 0 && this.pixelLoadsWindow === "yesterday";
    },
    searchConfiguration: {
      fields: {
        pixel_type: {
          type: "enum"
        },
        name: {
          type: "string"
        },
        eligible: {
          type: "enum"
        },
        provider_id: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    urlString: "pixel_bundles"
  });
  return T1.Models.Pixels;
});
