define('modules/admin/myOrgs/agency/views/createEdit',['require','Underscore','jQuery','models/agency','text!modules/admin/myOrgs/agency/templates/createEditBody.html','text!modules/admin/shared/templates/dmp.html','text!modules/admin/templates/header.html','text!modules/admin/shared/templates/helix.html','models/organization','collections/organizations','text!modules/admin/myOrgs/agency/templates/pixel.html','text!modules/admin/shared/templates/safeSupply.html','text!modules/admin/myOrgs/agency/templates/providerIdTemplate.html','T1','T1Layout','T1Permissions','T1View','text!modules/admin/myOrgs/agency/templates/createEdit.html','utils/LaunchDarklyUtils','models/sessionUser','utils/AdminUtils','When','models/providerIds'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Agency = require("models/agency");
  const bodyTemplate = require("text!modules/admin/myOrgs/agency/templates/createEditBody.html");
  const dmpTemplate = require("text!modules/admin/shared/templates/dmp.html");
  const headTemplate = require("text!modules/admin/templates/header.html");
  const helixTemplate = require("text!modules/admin/shared/templates/helix.html");
  const Organization = require("models/organization");
  const Organizations = require("collections/organizations");
  const pixelTemplate = require("text!modules/admin/myOrgs/agency/templates/pixel.html");
  const safeSupplyTemplate = require("text!modules/admin/shared/templates/safeSupply.html");
  const providerIdTemplate = require("text!modules/admin/myOrgs/agency/templates/providerIdTemplate.html");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!modules/admin/myOrgs/agency/templates/createEdit.html");
  const LaunchDarklyUtils = require("utils/LaunchDarklyUtils");
  const User = require("models/sessionUser");
  const Utils = require("utils/AdminUtils");
  const When = require("When");
  const ProviderIds = require("models/providerIds");
  var AgenciesCreateEdit;
  var user = User.getUser().toJSON();
  var orgs = Organizations.getOrganizations();
  var explicitlyEnabledAgencyFound = Utils.explicitlyEnabledAgencyFound;
  var isAdmin = user.role === "ADMIN";
  const providerIdField = "providerIds";
  const CAN_ACCESS_ADAPTIVE = T1Permissions.check("feature", "adaptive-segments");
  AgenciesCreateEdit = T1View.extend({
    hasUnloaded: false,
    isEdit: false,
    template: template,
    partials: {
      header: headTemplate,
      body: bodyTemplate,
      pixel: pixelTemplate,
      dmp: dmpTemplate,
      helix: helixTemplate,
      safeSupply: safeSupplyTemplate,
      providerId: providerIdTemplate
    },
    actionsConfig: {
      events: {
        "click .ads-txt-checkbox": "onAdsTxtChanged",
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveAndNew",
        "click .save": "saveOnly"
      }
    },
    eventhubEvents: {
      "dropdown:org": "handleLocalOrgChange",
      "organization:select": "handleGlobalOrgChange"
    },
    initialize(args) {
      var formActions = [];
      var actionButtons = {
        cancel: {
          class: "cancel",
          label: "cancel",
          location: "#",
          uuid: "cancel"
        },
        save: {
          class: "save save-btn-secondary",
          label: "save",
          location: false,
          uuid: "save"
        },
        saveAndClose: {
          class: "saveAndClose save-btn-primary",
          label: "save & close",
          location: false,
          uuid: "save_close"
        },
        saveAndNew: {
          class: "saveAndNew save-btn-primary",
          label: "save & add another",
          location: false,
          uuid: "save_add"
        }
      };
      if (args.id) {
        this.setEditMode(args.id);
      }
      this.canLoad = false;
      formActions.push(actionButtons.cancel);
      if (this.isEdit) {
        formActions.push(actionButtons.save);
      }
      formActions.push(actionButtons.saveAndClose);
      if (!this.isEdit) {
        formActions.push(actionButtons.saveAndNew);
      }
      this.actionsConfig.formActions = formActions;
      this.providerIds = new ProviderIds({
        id: args.id
      });
      this.providerIdLimit = 100;
      this.providerIdCount = 0;
      orgs.getSelected().then(orgId => {
        this.org = orgId;
        this.updateModel(args);
      });
    },
    getAssociatedAccountIds() {
      const self = this;
      this.providerIds.fetch({
        success: function (data) {
          self.showActivatedAccountList(data);
        }
      });
    },
    onAdsTxtChanged(event) {
      this.model.set({
        ads_txt_verified: event.target.value ? "1" : "0"
      });
      if (!event.target.value) {
        this.model.set({
          direct_only: "0"
        });
      }
      this.renderPartialTemplate({
        template: safeSupplyTemplate,
        useTemplateHtml: false,
        action: "html",
        targetEl: ".safe-supp",
        skipDelegateEvents: true,
        data: {
          ads_txt_verified: this.model.get("ads_txt_verified") === "1",
          direct_only: this.model.get("direct_only") === "1"
        }
      });
    },
    updateModel(args) {
      var model, orgId;
      var deferred = When.defer();
      if (args.id) {
        const agency = new Agency({
          id: args.id
        });
        this.isEdit = true;
        agency.fetch({
          success: data => {
            this.model = data;
            model = this.model;
            orgId = model.get("organization_id");
            this.orgId = orgId;
            Utils.fetchData(Organization, orgId).then(org => {
              model.set({
                org: org.toJSON()
              });
              this.canLoad = true;
              Utils.updateBreadCrumb("Agency", model.toJSON());
              this.initLayout();
              this.load();
              deferred.resolve(model);
            });
          }
        });
      } else {
        this.model = new Agency({
          action: "new",
          status: 1,
          allow_x_adv_pixels: 1,
          name: ""
        });
        model = this.model;
        this.canLoad = true;
        const viewOptions = T1.Location.getViewOptions() || {};
        if (viewOptions.parent) {
          model.set({
            organization_id: viewOptions.parent
          });
        } else if (this.org) {
          model.set({
            organization_id: this.org
          });
        }
        orgId = model.get("organization_id");
        this.orgId = orgId;
        Utils.fetchData(Organization, orgId).then(org => {
          model.set({
            org: org.toJSON()
          });
          Utils.updateBreadCrumb("Agency", model.toJSON());
          this.initLayout();
          this.load();
          deferred.resolve(model);
        });
      }
      return deferred.promise;
    },
    initLayout() {
      var model = this.model;
      var organization_id = model.get("organization_id");
      var createEditLayout = {};
      if (!this.canLoad) {
        return;
      }
      if (!model.id) {
        createEditLayout = {
          ".organization-select": [{
            module: "shared",
            viewType: "select",
            options: {
              clearOnClose: true,
              dataBind: "organization_id",
              entity: "organizations",
              fetchSelected: true,
              initialLoad: true,
              isSearchable: true,
              notFound: "No Organization Found",
              placeholder: "Select an Organization",
              preselect: "Select an Organization",
              selectedId: organization_id,
              selectEvent: "org",
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              },
              preRender: function (data) {
                var options = data.options || [];
                var passedOptions = [];
                if (options.length > 0) {
                  $.each(options, function (i, option) {
                    if (explicitlyEnabledAgencyFound(user, option.id)) {
                      passedOptions.push(option);
                    }
                  });
                  data.options = passedOptions;
                }
                return data;
              }
            }
          }]
        };
      }
      Utils.updateBreadCrumb("Agency", model.toJSON());
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: "_wrapper",
        template: template,
        serialize: $.proxy(this.serialize, this),
        layout: createEditLayout
      });
      if (organization_id && !model.get("org")) {
        Utils.fetchData(Organization, organization_id).then(orgData => {
          var org = orgData.toJSON();
          model.set({
            org: org
          });
          this.renderPartials(org);
        });
      }
    },
    viewRender() {
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
        }
        if (this.model.get("disable_pixels")) {
          this.pixelCheckTooltip();
        }
        this.dmpCheckTooltip();
        if (this.isEdit && this.isProviderFieldEnabled) {
          this.getAssociatedAccountIds();
        }
      });
    },
    load() {
      if (this.canLoad) {
        const agencyId = this.model.get("id");
        if (agencyId) {
          LaunchDarklyUtils.getPromise().then(() => {
            LaunchDarklyUtils.updateAgency(agencyId).then(() => {
              this.isProviderFieldEnabled = T1Permissions.check("feature", "mediaocean_prisma_integration");
              this.viewRender();
            });
          });
        } else {
          this.viewRender();
        }
      }
    },
    deleteInvalidProviderId(errs) {
      const error = errs.find(err => err.field === providerIdField);
      if (error) {
        this.$('[data-bind="providerIds"]').val("");
      }
    },
    postData(formValues, success, statusInvalid, conflict) {
      this.model.createEdit(formValues, {
        success: newModel => {
          this.toggleProviderIdError(false);
          const data = newModel.toJSON();
          data.org = newModel.get("org");
          data.subtitle = data.org ? data.org.name : "";
          T1.Notify("message", `Agency ${this.isEdit ? "updated" : "created"} successfully!`);
          Utils.updateBreadCrumb("Agency", data);
          this.renderPartialTemplate({
            template: headTemplate,
            useTemplateHtml: false,
            action: "html",
            targetEl: ".create-edit-head",
            data: {
              title: data.name,
              subTitle: data.subtitle
            }
          });
          success(newModel);
          if (this.isEdit && this.isProviderFieldEnabled) {
            if (this.providerIdCount < this.providerIdLimit) {
              if (formValues.providerIds !== "") {
                this.postProviderIds({
                  formValues: formValues,
                  statusInvalid: statusInvalid
                });
              } else {
                if (this.saveClose) {
                  this.saveCloseAndRedirect();
                }
              }
            } else {
              const errObj = [{
                field: providerIdField,
                message: `A maximum of ${this.providerIdLimit} ids allowed per advertiser`
              }];
              this.statusInvalidError(errObj, statusInvalid);
            }
          } else {
            if (this.saveClose) {
              this.saveCloseAndRedirect();
            }
          }
        },
        statusInvalid: errors => {
          this.statusInvalidError(errors, statusInvalid);
        },
        conflict: (...args) => {
          conflict.apply(this, args);
        }
      });
    },
    showActivatedAccountList(data) {
      let accounts;
      if (data && data.accounts && data.accounts.length) {
        accounts = data.accounts;
        this.providerIdCount = accounts.length;
      }
      this.renderPartialTemplate({
        template: providerIdTemplate,
        useTemplateHtml: false,
        action: "html",
        targetEl: ".provide-id-sect",
        skipDelegateEvents: true,
        data: {
          accounts: accounts
        }
      });
    },
    postProviderIds({
      formValues: formValues,
      statusInvalid: statusInvalid
    }) {
      const self = this;
      this.providerIds.createEdit(formValues, providerIdField, {
        success: function (newModel, data) {
          self.showActivatedAccountList(data);
          if (self.saveClose) {
            self.saveCloseAndRedirect();
          }
        },
        statusInvalid: errors => {
          if (errors.length && errors[0].field !== undefined) {
            self.statusInvalidError(errors, statusInvalid);
          }
        },
        error: errors => {
          if (errors.length && errors[0].field !== undefined) {
            self.deleteInvalidProviderId(errors);
            self.statusInvalidError(errors, statusInvalid);
          }
        }
      });
    },
    handleStatusInvalidError(errors, statusInvalid) {
      const providerIdError = errors.find(err => err.field === providerIdField);
      if (providerIdError) {
        const $errMsgElem = this.$(".provider-id-inline-error-message")[0];
        if ($errMsgElem) {
          Polymer.dom($errMsgElem).textContent = providerIdError.message;
        }
        this.toggleProviderIdError(true);
        errors = errors.filter(err => err !== providerIdError);
      } else {
        this.toggleProviderIdError(false);
      }
      statusInvalid(errors, null, ".form-body");
    },
    toggleProviderIdError: function (toShow) {
      const $providerIdErrEle = this.$(".provider-id-inline-error-message");
      if (toShow) {
        $providerIdErrEle.removeClass("no-display");
      } else {
        if (!$providerIdErrEle.hasClass("no-display")) {
          $providerIdErrEle.addClass("no-display");
        }
      }
    },
    statusInvalidError(errors, statusInvalid) {
      if (errors && errors.length) {
        _.map(errors, function (error) {
          error.field = error.field === "height" ? "custom_height" : error.field === "width" ? "custom_width" : error.field;
          return error;
        });
      }
      this.handleStatusInvalidError(errors, statusInvalid);
    },
    setEditMode(id) {
      this.isEdit = true;
      T1.Location.set(`#admin/agency/${id}`, {
        silent: true
      });
    },
    saveCloseAndRedirect() {
      this.startSpinner(this.$("div.saveAndClose"));
      const delay = 1e3;
      setTimeout(function () {
        T1.Location.set("#admin/myOrgs");
      }, delay);
    },
    saveOnly(evt) {
      this.saveClose = false;
      return this.save(evt);
    },
    saveAndClose(evt) {
      this.saveClose = true;
      return this.save(evt);
    },
    saveAndNew(evt) {
      var model = this.model;
      var org = model.get("org");
      var callback = {
        success: () => {
          if (model.get("action") === "new") {
            const output = {
              action: "new",
              status: 1,
              organization_id: model.get("organization_id"),
              allow_x_adv_pixels: model.get("allow_x_adv_pixels"),
              disable_pixels: model.get("disable_pixels")
            };
            this.unload();
            this.model = new Agency(output);
            this.initLayout();
            this.load();
            this.renderPartials(org);
          }
          T1.Location.set("#admin/agency");
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    canUnload() {
      var isFormElementsChanged = this.isFormElementsChanged;
      var cancelHandler = {
        onAlertClose: function () {
          var T1Location = T1.Location;
          var abortedUrl = T1Location.getAbortedURL();
          if (abortedUrl === "" || abortedUrl === false) {
            setTimeout(function () {
              T1Location.set("#admin/myOrgs");
            }, 1);
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Agency Edit" : "New Agency"
        }, cancelHandler);
      }
      return !isFormElementsChanged;
    },
    cancelUnload() {
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          this.doNotProcessGlobalOrgChange = true;
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    unload() {
      this.hasUnloaded = true;
    },
    handleGlobalOrgChange(data) {
      if (!this.doNotProcessGlobalOrgChange) {
        T1.EventHub.publish("dropdown:organizations", data);
        T1.Location.set("admin/myOrgs");
      }
      this.doNotProcessGlobalOrgChange = false;
    },
    handleLocalOrgChange(data) {
      var model = this.model;
      if (data && data.id) {
        model.set({
          organization_id: data.id
        });
        Utils.fetchData(Organization, model.get("organization_id")).then(orgData => {
          var org = orgData.toJSON();
          model.set({
            org: org
          });
          Utils.updateBreadCrumb("Agency", model.toJSON());
          this.renderPartials(org);
        });
      }
    },
    renderPartials(org) {
      var parent = org.toJSON ? org.toJSON() : org;
      var pixelOutput = this.pixelCheckEnable(parent);
      var dmpOutput = this.dmpCheckEnable(parent);
      var helixOutput = this.helixCheckEnable(parent);
      if (pixelOutput) {
        this.renderPartialTemplate({
          action: "html",
          data: pixelOutput,
          targetEl: ".pixel",
          template: pixelTemplate,
          useTemplateHtml: false
        });
        if (pixelOutput.disable_pixels) {
          this.pixelCheckTooltip();
        }
      }
      if (dmpOutput) {
        this.renderPartialTemplate({
          action: "html",
          data: dmpOutput,
          targetEl: ".dmp",
          template: dmpTemplate,
          useTemplateHtml: false
        });
        this.dmpCheckTooltip();
      }
      if (helixOutput) {
        this.renderPartialTemplate({
          action: "html",
          data: helixOutput,
          targetEl: ".helix",
          template: helixTemplate,
          useTemplateHtml: false
        });
      }
    },
    pixelCheckEnable(org) {
      var data;
      if (org && typeof org.allow_x_agency_pixels !== "undefined") {
        if (org.allow_x_agency_pixels === "1") {
          data = {
            allow_x_adv_pixels: this.model.get("allow_x_adv_pixels") !== "0",
            disable_pixels: false
          };
        } else {
          data = {
            allow_x_adv_pixels: false,
            disable_pixels: true
          };
        }
        this.model.set({
          disable_pixels: data.disable_pixels
        });
      }
      return data;
    },
    dmpCheckEnable(parent) {
      var data = {};
      var model = this.model;
      var dmpEnabled = model.get("dmp_enabled");
      var parentDmpEnabled = parent && parent.dmp_enabled !== "disabled";
      data.dmp_enabled = typeof dmpEnabled !== "undefined" ? dmpEnabled !== "disabled" : parentDmpEnabled !== "disabled";
      data.deactivate_dmp = !isAdmin;
      if (!CAN_ACCESS_ADAPTIVE) {
        data.dmp_enabled = false;
        data.deactivate_dmp = true;
      }
      if (!parentDmpEnabled) {
        data.dmp_enabled = false;
        data.deactivate_dmp = true;
      }
      return data;
    },
    helixCheckEnable(parent) {
      var data = {};
      var isParentEnabled = parent && parent.eligible_for_data_sharing === "1";
      var isEligibleForDataSharing = this.model.get("eligible_for_data_sharing") === "1";
      data.eligible_for_data_sharing = isEligibleForDataSharing || isParentEnabled;
      data.enable_helix = isAdmin && isParentEnabled;
      return data;
    },
    pixelCheckTooltip() {
      T1.Tooltip(this.$(".allow_x_adv_pixels"), {
        delayIn: "100",
        delayOut: "100",
        offset: 5
      });
    },
    dmpCheckTooltip() {
      T1.Tooltip(this.$(".dmp_enabled"), {
        delayIn: "100",
        delayOut: "100",
        offset: 5
      });
    },
    serialize() {
      var output = this.model.toJSON();
      var hasEntityPermission = this.orgId && explicitlyEnabledAgencyFound(user, this.orgId);
      output.isEdit = this.isEdit;
      output.status = output.status !== "0";
      output.allow_x_adv_pixels = output.allow_x_adv_pixels !== "0";
      if (output.dmp_enabled === "1") {
        output.deactivate_dmp = false;
        output.dmp_enabled = true;
      } else {
        output.deactivate_dmp = true;
        output.dmp_enabled = false;
      }
      output = $.extend(output, this.helixCheckEnable(output.org));
      if (output.org) {
        output.subTitle = output.org.name;
        output = $.extend(output, this.pixelCheckEnable(output.org));
        output = $.extend(output, this.dmpCheckEnable(output.org));
      }
      if (output.id) {
        output.title = output.name;
      } else {
        output.title = "New Agency";
        output.subTitle = false;
      }
      output.canCreateEditAgency = hasEntityPermission && T1Permissions.check("agency_advertiser.add");
      output.isAdsTxtUserControlsEnabled = T1Permissions.check("feature", "ads_txt_user_controls");
      output.ads_txt_verified = output.ads_txt_verified || "ANY";
      output.isProviderFieldEnabled = this.isProviderFieldEnabled && this.isEdit;
      output.providerIdLimit = this.providerIdLimit;
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(AgenciesCreateEdit);
});
