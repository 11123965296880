define('modules/reporting/campaigns/geoPerformance/views/grid',["jQuery", "Underscore", "Mustache", "T1", "T1View", "T1GridViewV2", "T1PerformanceTimer", "text!../templates/grid_header.html", "text!../templates/grid_footer.html", "text!../templates/item.html", "text!../templates/subitem.html"], function ($, _, Mustache, T1, T1View, T1GridView, T1Timer, gridHeaderTemplate, gridFooterTemplate, itemTemplate, subItemTemplate) {
  "use strict";

  var $window = $(window);
  var viewportHeight = $window.height() || 0;
  var viewportLeft = $window.scrollLeft() || 0;
  var formatCurrency = T1.Utils.formatCurrency;
  var formatAs = T1.Formats.as;
  var GAMap = {
    render: "RPTG:GEO:GRID:RENDER"
  };
  var GridView = T1View.extend({
    selected: "",
    partials: {
      gridFooter: gridFooterTemplate,
      gridHeader: gridHeaderTemplate,
      listItem: itemTemplate
    },
    keys: [],
    headers: [],
    mainKey: "strategy_name",
    dimensionKey: "strategy_id",
    scrollVLimit_chartOn: 546,
    scrollVLimit_chartOff: 192,
    section: "geoPerformance",
    siteList: [],
    sortConfigs: {
      order: {
        custom: {
          Strategy_Name: "ascending"
        },
        default: "descending"
      },
      sortBy: function (sortKey, sortOrder) {
        var isName = this.keys[0] === sortKey ? "1" : "0";
        var order = sortOrder === "ascending" ? 1 : -1;
        this.reportModel.setSortKeyInfo(`${sortKey},${order},${isName}`);
      }
    },
    events: {
      "click .icon-nav-into": "navInto",
      "click .icon": "toggleOpen",
      "click .item": "toggleHighlighted",
      "click .legend-chart": "toggleSelected"
    },
    dataEvents: {
      collection: {
        sort: "reload"
      },
      reportModel: {
        "change:columnKeys": "reload",
        "change:dimensionItems": "reload",
        "change:filteredOutItems": "filterOutItems",
        "change:highlightedItem": "highlightItem"
      }
    },
    eventhubEvents: {
      "chart.toggle": "chartToggled",
      "chart.toggleEnd": "chartToggleEnded"
    },
    initialize: function (args) {
      var self = this;
      var models = args.models;
      this.campaign = models.campaign;
      this.mainMeta = models.mainMeta;
      this.mainModel = models.mainModel;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.collection = args.collection;
      this.dailyCollection = args.dataGroup.dailyCollection;
      this.type = args.type;
      this.scrollVLimit = this.scrollVLimit_chartOn;
      this.appTop = 82;
      this.gridLeft = 113;
      this.gridHeader = $("");
      this.gridHeaderMode = null;
      this.onScrollVHProxy = $.proxy(this.onScrollVH, this);
      this.viewportResizeListener = function () {
        viewportHeight = $window.height() || 0;
        self.updateScrollBar();
        self.shiftScrollbar($window.scrollTop());
      };
      this.bindViewEvents();
    },
    load: function () {
      var $contentHolder, $gridContentContainer, $gridHeaderContainer, $wDataGrid, offset, timerData;
      var self = this;
      var $el = this.el;
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (!this.reportModel.get("columnKeys")) {
        return;
      }
      timerData = {
        category: GAMap.render,
        label: this.campaign.id
      };
      $window.off("scroll", this.onScrollVHProxy).on("scroll", this.onScrollVHProxy);
      $window.off("resize", this.viewportResizeListener).on("resize", this.viewportResizeListener);
      T1Timer.start(timerData);
      this.sortData(true);
      this.prepareRenderData();
      this.prepareGridData();
      $(".rptg-icon-edit-tip").remove();
      this.render().then(function () {
        self.initNavIntoToolTip();
        self.recalculateSettings();
        T1Timer.stop(timerData);
      });
      $wDataGrid = $el.find(".w-DataGrid");
      $gridHeaderContainer = $wDataGrid.find(".header-container");
      $gridContentContainer = $wDataGrid.find(".content-c");
      this.$contentHolder = $contentHolder = $wDataGrid.find(".content-holder");
      $contentHolder.off("scroll").on("scroll", function () {
        if ($gridHeaderContainer) {
          $gridHeaderContainer.css("left", `${-$contentHolder.scrollLeft()}px`);
        }
      });
      offset = $wDataGrid.find(".content-holder").offset();
      if (offset !== null) {
        this.gridLeft = offset.left;
      }
      offset = $(".app-container").offset();
      if (offset !== null) {
        this.appTop = offset.top;
      }
      if ($gridHeaderContainer.width() > $gridContentContainer.width()) {
        $gridContentContainer.width(Number($gridHeaderContainer[0].scrollWidth) + Number($gridHeaderContainer.css("padding-left").replace("px", "")));
      }
      this.recalculateSettings();
    },
    unload: function () {
      this.itemCount = null;
      $window.off("scroll", this.onScrollVHProxy);
      $window.off("resize", this.viewportResizeListener);
      if (this.$contentHolder) {
        this.$contentHolder.off("scroll");
      }
    },
    recalculateSettings: function () {
      this.chartToggled(this.mainModel.get("chartShown") === "true");
      this.recalculateSettingsForHeader();
      this.recalculateSettingsForScrollbar();
    },
    recalculateSettingsForHeader: function () {
      var el = this.el;
      var gridHeader = el.find(".w-DataGrid .w-head");
      if (this.gridHeader[0] !== gridHeader[0]) {
        this.gridHeader = gridHeader;
        this.gridHeaderMode = null;
      }
      this.shiftHeader($window.scrollTop(), $window.scrollLeft());
    },
    recalculateSettingsForScrollbar: function () {
      var el = this.el;
      var gridList = el.find(".content-c");
      var gridListOffset = gridList.offset();
      var gridListTop = gridListOffset ? gridListOffset.top : 0;
      var gridListHeight = gridList.height() || 0;
      var gridListBottom = gridListTop + gridListHeight;
      var $contentHolder = this.$contentHolder;
      if ($contentHolder) {
        this.scrollbar = $contentHolder.find(".ps-scrollbar-x");
        this.scrollbarRangeStart = gridListTop;
        this.scrollbarRangeEnd = gridListBottom;
        if (!this.scrollbar[0]) {
          this.scrollbarRangeEnd = this.scrollbarRangeStart;
        }
        this.shiftScrollbar($window.scrollTop());
      }
    },
    onScrollVH: function () {
      var scrollTop = $window.scrollTop();
      var scrollLeft = $window.scrollLeft();
      this.shiftHeader(scrollTop, scrollLeft);
      this.shiftScrollbar(scrollTop);
    },
    shiftHeader: function (scrollTop, scrollLeft) {
      var gridHeader = this.gridHeader;
      var adjustHorizontally = false;
      var isFrozen = this.gridHeaderMode === "frozen";
      if (!gridHeader[0]) {
        return;
      }
      if (viewportLeft !== scrollLeft) {
        viewportLeft = scrollLeft;
        adjustHorizontally = true;
      }
      if (!isFrozen && scrollTop >= this.scrollVLimit) {
        this.gridHeaderMode = "frozen";
        gridHeader.css({
          left: `${this.gridLeft - viewportLeft}px`,
          position: "fixed",
          top: `${this.appTop}px`,
          zIndex: "2"
        });
        adjustHorizontally = false;
      } else if (this.gridHeaderMode !== "normal" && scrollTop < this.scrollVLimit) {
        this.gridHeaderMode = "normal";
        gridHeader.css({
          left: "0",
          position: "absolute",
          top: "auto",
          zIndex: "auto"
        });
      }
      if (adjustHorizontally && isFrozen) {
        gridHeader.css("left", `${this.gridLeft - viewportLeft}px`);
      }
    },
    shiftScrollbar: function (scrollTop) {
      var viewportBottom, viewportBottomInRange;
      var scrollbar = this.scrollbar;
      var $contentHolder = this.$contentHolder;
      if (!scrollbar[0]) {
        return;
      }
      viewportBottom = scrollTop + viewportHeight;
      viewportBottomInRange = this.scrollbarRangeStart < viewportBottom && this.scrollbarRangeEnd > viewportBottom;
      if (viewportBottomInRange) {
        scrollbar.css({
          "margin-left": `${this.gridLeft}px`,
          bottom: "2px",
          position: "fixed"
        });
        $contentHolder.perfectScrollbar("freezeX");
      } else {
        scrollbar.css({
          "margin-left": 0,
          position: "absolute"
        });
        $contentHolder.perfectScrollbar("thawX");
      }
      $contentHolder.perfectScrollbar("update");
    },
    chartToggled: function (chartShown) {
      this.scrollVLimit = chartShown ? this.scrollVLimit_chartOn : this.scrollVLimit_chartOff;
    },
    chartToggleEnded: function () {
      if (this.scrollVLimit === this.scrollVLimit_chartOff && $window.scrollTop() > this.scrollVLimit) {
        $window.scrollTop(this.scrollVLimit);
      }
      this.recalculateSettingsForHeader();
      this.recalculateSettingsForScrollbar();
    },
    toggleOpen: function (e) {
      var selectedItem = $(e.target).closest(".item");
      var selectedArrow = $(e.target).closest(".arrow");
      var expandedClass = "expanded";
      var loadingClass = "loading";
      var action;
      if (selectedItem.hasClass(expandedClass)) {
        action = "conceal";
        selectedItem.removeClass(expandedClass);
        selectedArrow.removeClass(loadingClass);
      } else {
        action = "reveal";
        selectedItem.addClass(expandedClass);
        selectedArrow.addClass(loadingClass);
        this.appendChildList(e);
      }
      this.recalculateSettingsForScrollbar();
      e.preventDefault();
      if (selectedItem.hasClass("selected")) {
        e.stopPropagation();
      }
      T1.EventHub.publish("track:event", {
        action: "Disclose time series",
        label: action
      });
    },
    appendChildList: function (event) {
      var ele = $(event.target);
      var id = ele.attr("data-id");
      var childRow = ele.closest(".item-row").find(".child-row");
      var html, list;
      list = this.nestedItemLookup[id];
      if (!list || childRow.data("loaded")) {
        return;
      }
      html = Mustache.to_html(subItemTemplate, {
        list: list
      });
      childRow.html(html);
      childRow.data("loaded", true);
      event.preventDefault();
    },
    sortData: function (silent) {
      var key, order, sortKeyArray;
      var sortKeyInfo = this.reportModel.get("sortKeyInfo") || "";
      const TYPE = 2;
      if (sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        key = sortKeyArray[0];
        order = parseInt(sortKeyArray[1], 10);
        this.collection.sortByKey(key, order, this.type, silent, parseInt(sortKeyArray[TYPE], 10));
      }
    },
    setAllNoneClass: function () {
      var reportModel = this.reportModel;
      var hiddenCount = reportModel.getHiddenItemsCount();
      var benchMark = reportModel.get("campaignBenchmarkOn");
      var $legend = $(".header-container .legend-chart.header", this.el);
      if (hiddenCount || !benchMark) {
        $legend.addClass("hide");
      } else {
        $legend.removeClass("hide");
      }
    },
    toggleAll: function (target) {
      var hideClass = "hide";
      var allHidden = target.hasClass(hideClass);
      var itemsToHide;
      var idList = {};
      var $legend = this.el.find(".legend-chart");
      if (allHidden) {
        this.reportModel.resetHiddenItems();
        $legend.removeClass(hideClass);
      } else {
        $legend.addClass(hideClass);
        itemsToHide = this.siteList;
        $.each(itemsToHide, function (i, item) {
          idList[item.id] = true;
        });
        this.reportModel.setHiddenItems(idList);
      }
    },
    toggleSelected: function (e) {
      var hideClass = "hide";
      var target = $(e.target);
      var item = target.closest(".item");
      e.stopPropagation();
      if (target.hasClass("header")) {
        this.toggleAll(target);
        return;
      }
      target.toggleClass(hideClass);
      this.reportModel.setHiddenItem({
        action: target.hasClass(hideClass) ? hideClass : "show",
        id: `${item.data("id")}`,
        type: "strategy"
      });
      T1.EventHub.publish("track:event", {
        action: "Add/remove row from chart",
        label: "legend-chart"
      });
      this.setAllNoneClass();
    },
    toggleHighlighted: function (e) {
      var item = $(e.currentTarget);
      var target = $(e.target);
      e.stopPropagation();
      if (target.hasClass("label")) {
        return;
      }
      this.reportModel.setHighlightedItem({
        from: "row",
        id: item.hasClass("selected") ? null : `${item.data("id")}`,
        type: "strategy"
      });
    },
    highlightItem: function (model) {
      var selectedClass = "selected";
      var contentC = $(this.el).find(".content-c");
      var highlightedItem = model.get("highlightedItem");
      contentC.children(".item").removeClass(selectedClass);
      if (highlightedItem && highlightedItem.id !== null) {
        contentC.find(`.item[data-id="${highlightedItem.id}"]`).addClass(selectedClass);
      }
    },
    filterOutItems: function (model) {
      var filteredInItem, filteredOutItem;
      var $contentC = this.el.find(".content-c");
      var filterOutClass = "filter-out";
      var filteredOutItems = model.get("filteredOutItems") || [];
      var filteredInItems = model.get("filteredInItems") || [];
      var count = this.reportModel.getFilteredInCount();
      for (let i = 0, outLength = filteredOutItems.length; i !== outLength; i++) {
        filteredOutItem = filteredOutItems[i];
        $contentC.find(`.item[data-id="${filteredOutItem.replace(/[']/g, "")}"]`).addClass(filterOutClass);
      }
      for (let inLength = filteredInItems.length, j = 0; j !== inLength; j++) {
        filteredInItem = filteredInItems[j];
        $contentC.find(`.item[data-id="${filteredInItem.replace(/[']/g, "")}"]`).removeClass(filterOutClass);
      }
      this.updateDataBind({
        count: count,
        total_count: count
      }, ".w-foot");
    },
    prepareRenderData: function () {
      var geoNavLevel = this.reportModel.getGeoNav().level;
      var geoLevelConfig = this.reportMeta.get("geoLevelConfig");
      this.mainKey = this.dimensionKey = geoLevelConfig[geoNavLevel].dimension;
    },
    prepareGridData: function () {
      var geoLevel, reportMetaObj, reportModelObj;
      var headers = [];
      var reportMeta = this.reportMeta;
      var reportModel = this.reportModel;
      var sortKeyInfo = reportModel.get("sortKeyInfo") || "";
      var sortKeyArray = sortKeyInfo.split(",");
      var sortKey = sortKeyArray[0];
      var sortOrder = parseInt(sortKeyArray[1], 10);
      var columns = {};
      var firstHeader = {};
      this.reportMetaObj = reportMetaObj = $.extend({}, reportMeta.toJSON());
      this.reportModelObj = reportModelObj = $.extend({}, reportModel.toJSON());
      reportModelObj = this.reportModelObj;
      geoLevel = reportMetaObj.geoLevelConfig[reportModel.getGeoNav().level];
      this.keys = [this.mainKey, ...reportModelObj.columnKeys];
      this.firstColName = reportMetaObj.fields[geoLevel.dimension].name;
      firstHeader[this.mainKey] = this.firstColName.toUpperCase();
      columns = $.extend(firstHeader, reportModelObj.formattedHeaderValues);
      $.each(this.keys, function (i, n) {
        var field = reportMetaObj.fields[n];
        var format = field.type;
        var label = columns[n];
        headers.push({
          ascending: sortOrder === 1,
          field: n,
          format: format,
          isSortable: true,
          label: label,
          name: format,
          showSortOrder: n === sortKey
        });
      });
      this.headers = headers;
    },
    prepareListForNoDataMessage: function (list) {
      const THIRD_ROW = 2;
      for (let i = 0, length = 4; i !== length; i++) {
        list.push({
          blank: "blank",
          id: "",
          msg: ""
        });
      }
      list[THIRD_ROW].msg = "No data available for this selection";
      list[THIRD_ROW].blank = "";
      return list;
    },
    initNavIntoToolTip: function () {
      T1.Tooltip($(this.el), {
        className: "rptg-icon-edit-tip",
        delayIn: 0,
        delayOut: 0,
        fade: false,
        getExternalTip: false,
        html: true,
        tooltipEle: ".icon-nav-into"
      });
    },
    navInto: function (e) {
      var reportModel = this.reportModel;
      var item = $(e.target).closest(".item");
      e.stopPropagation();
      reportModel.setHighlightedItem();
      reportModel.geoNavigate("into", item.data("id"), item.data("country"));
    },
    serialize: function () {
      var reportModel = this.reportModel;
      var collection = this.collection;
      var dailyCollection = this.dailyCollection;
      var summaryItemJson = collection && collection.loaded ? collection.toJSON() : [];
      var dailyData = dailyCollection && dailyCollection.loaded ? dailyCollection.toJSON() : [];
      var reportMetaObj = this.reportMetaObj;
      var list = [];
      var keys = this.keys;
      var self = this;
      var item = {
        keys: []
      };
      var highlightedItem = reportModel.get("highlightedItem");
      var highlightedStrategy = highlightedItem && highlightedItem.type === "strategy" ? highlightedItem : null;
      var hiddenItems = reportModel.get("hiddenItems") || {};
      var filteredOutItems = reportModel.get("filteredOutItems") || [];
      var colorData = reportModel.get("colorData");
      var itemsHidden = reportModel.getHiddenItemsCount();
      var count;
      var geoNav = reportModel.getGeoNav();
      var needsNavIcon = geoNav.level !== "region";
      var navIntoIconTip = geoNav.level === "all" ? "View Region" : "View Metro";
      var currencyCode = "USD";
      var isCTD = reportModel.get("isCTD");
      if (summaryItemJson && colorData) {
        $.each(summaryItemJson, function (i, n) {
          var id = n[self.dimensionKey];
          item = {
            keys: []
          };
          item.id = id = id && id.replace(/[']/g, "");
          item.isSelected = highlightedStrategy && highlightedStrategy.id === id;
          item.isFilteredOut = filteredOutItems.indexOf(id) > -1;
          item.country = n.country_name || "";
          $.each(keys, function (j, m) {
            let formattedValue;
            const format = reportMetaObj.fields[m].type;
            const value = n[m] ? n[m] : "--";
            if (value === "--") {
              formattedValue = value;
            } else if (format === "currency") {
              formattedValue = formatCurrency(value, null, currencyCode);
            } else {
              formattedValue = formatAs(format)(value);
            }
            item.keys.push({
              checked: !hiddenItems[id],
              colorIndex: colorData[id] + 1,
              format: format,
              isCTD: isCTD,
              isName: m === self.mainKey,
              navIntoIconTip: navIntoIconTip,
              needsNavIcon: needsNavIcon,
              value: formattedValue
            });
          });
          list.push(item);
        });
      }
      if (colorData && dailyData && dailyData.length && !isCTD) {
        const groupedData = _.groupBy(dailyData, this.dimensionKey);
        const sortedData = _.mapObject(groupedData, data => _.sortBy(data, "start_date"));
        const nestedItemLookup = _.mapObject(sortedData, function (dataSet) {
          var nestedItems = [];
          _.each(dataSet, function (dayObj) {
            var nestedItem = {
              keys: []
            };
            _.each(keys, function (metric) {
              let formattedValue;
              const format = reportMetaObj.fields[metric].type;
              const value = dayObj[metric] ? dayObj[metric] : "--";
              if (value === "--") {
                formattedValue = value;
              } else if (format === "currency") {
                formattedValue = formatCurrency(value, null, currencyCode);
              } else {
                formattedValue = formatAs(format)(value);
              }
              const isName = metric === self.mainKey;
              nestedItem.keys.push({
                format: isName ? "date" : format,
                isName: false,
                value: isName ? dayObj.start_date : formattedValue
              });
            });
            nestedItems.push(nestedItem);
          });
          return nestedItems;
        });
        this.nestedItemLookup = nestedItemLookup;
      }
      this.itemCount = reportModel.get("dimensionItemsCount");
      count = list.length;
      if (reportModel.get("dimensionItems") && this.itemCount === 0) {
        list = this.prepareListForNoDataMessage(list);
      }
      this.siteList = list;
      return {
        contentType: "summary-grid",
        hideNoResultMessage: true,
        isExpandable: true,
        itemsHidden: itemsHidden,
        list: list,
        pageControls: {
          areas: this.firstColName,
          count: count,
          total: count
        }
      };
    }
  });
  return T1GridView(GridView);
});
