define('modules/reporting/campaigns/siteTransparency/views/stLineChart',['require','modules/shared/configs/charts/lineChartOneAxisSeriesConfig','T1View'],function (require) {
  "use strict";

  const ChartConfig = require("modules/shared/configs/charts/lineChartOneAxisSeriesConfig");
  const T1View = require("T1View");
  return T1View.extend({
    togglePointText: function () {},
    initialize(args) {
      this.collection = args.collection;
      this.parentClass = args.parentClass;
      this.reportModel = args.reportModel;
    },
    setupChart() {
      const self = this;
      const {
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      reportModel.set({
        dailyData: collection
      });
      if (collection && collection.loaded && colorData) {
        const lineChartConfig = ChartConfig.copy();
        const chartData = reportModel.getLineChartData(parentClass.dimensionKey, parentClass.yKey, colorData, false);
        const labelFn = function (value) {
          return parentClass.formatValue(value, parentClass.yMetricObj.type);
        };
        if (chartData.graphs.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        if (this.siteTransparencyChart) {
          this.siteTransparencyChart.clear();
          this.siteTransparencyChart = null;
        }
        lineChartConfig.dataProvider = chartData.dataProvider;
        lineChartConfig.graphs = chartData.graphs;
        lineChartConfig.valueAxes[0].labelFunction = labelFn;
        lineChartConfig.valueAxes[0].title = parentClass.yAxisLabel;
        lineChartConfig.listeners = [{
          event: "init",
          method: function (evt) {
            evt.chart.chartDiv.addEventListener("click", self.toggleHighlighted.bind(self));
          }
        }, {
          event: "clickGraphItem",
          method: function (evt) {
            self.toggleHighlighted(evt.graph);
            evt.event.stopPropagation();
          }
        }];
        lineChartConfig.export = {};
        lineChartConfig.legend = null;
        parentClass.showChart();
        parentClass.hideNoDataChart();
        this.siteTransparencyChart = AmCharts.makeChart("site-transparency-chart", lineChartConfig);
        parentClass.highlightChart();
      }
    },
    updateChart() {
      const {
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      if (!this.siteTransparencyChart) {
        this.setupChart();
        return;
      }
      reportModel.set({
        dailyData: collection
      });
      if (collection && collection.loaded && colorData) {
        const chartData = reportModel.getLineChartData(parentClass.dimensionKey, parentClass.yKey, colorData, true);
        const labelFn = function (value) {
          return parentClass.formatValue(value, parentClass.yMetricObj.type);
        };
        if (chartData.graphs.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        parentClass.showChart();
        this.siteTransparencyChart.dataProvider = chartData.dataProvider;
        this.siteTransparencyChart.graphs = chartData.graphs;
        this.siteTransparencyChart.valueAxes[0].labelFunction = labelFn;
        this.siteTransparencyChart.valueAxes[0].title = parentClass.yAxisLabel;
        parentClass.highlightChart();
        parentClass.hideNoDataChart();
      }
    },
    toggleHighlighted(highlightedElement) {
      event.stopPropagation();
      const highlighted = this.reportModel.get("highlightedItem");
      let newHighlighedId = highlightedElement.id;
      if (highlighted && highlighted.id === newHighlighedId) {
        newHighlighedId = null;
      }
      this.reportModel.setHighlightedItem({
        from: "line",
        id: newHighlighedId,
        type: "strategy"
      });
    },
    unloadChart() {
      if (this.siteTransparencyChart) {
        this.siteTransparencyChart.clear();
        this.siteTransparencyChart = null;
      }
    }
  });
});
