define('modules/creatives/display/views/createEdit',["require", "jQuery", "Underscore", "When", "T1", "T1View", "T1Form", "T1TabLayoutV2", "T1Permissions", "T1Layout", "T1Menu", "utils/nextTabViewNavigation", "models/atomicCreative", "collections/organizations", "collections/vendors", "text!../templates/createEdit.html"], function (require, $, _, When, T1, T1View, T1Form, T1TabLayout, T1Permissions, T1Layout, t1Menu, NextTabViewNavigation, AtomicCreative, Organizations, Vendors, template) {
  "use strict";

  var DisplayCreativeCreateEdit;
  function updateBreadCrumb(creative) {
    var pathPieces;
    var id = parseInt(creative.id);
    if (id) {
      pathPieces = [{
        label: creative.name,
        location: "#creatives/display/" + id
      }];
    } else {
      pathPieces = [{
        label: "New 3rd Party Ad Tag",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: getBreadCrumbHeaderInfo(creative)
    });
  }
  function getBreadCrumbHeaderInfo(model) {
    var modelJSON = model.toJSON ? model.toJSON() : model;
    var valueToBeDisplayed = [{
      attr: "id",
      label: "Creative id",
      className: "id"
    }, {
      attr: "user",
      label: "created by",
      className: "id"
    }, {
      attr: "created_on",
      label: "created on",
      className: "id"
    }, {
      attr: "status",
      className: "status"
    }];
    var returnArry = [];
    var i;
    var noData = "&nbsp;--";
    var formatDate = function (date) {
      return date ? Date.parse(date).toString("MM/dd/yyyy") : noData;
    };
    var formatData = function (attr, value) {
      var attrOperations = {
        created_on: formatDate,
        user: function () {
          return value ? value.first_name + " " + value.last_name : noData;
        },
        status: function () {
          var statusText = value === "0" ? "Inactive" : "Active";
          if (!model.id) {
            statusText = "Active";
          }
          return statusText;
        },
        defaultFormat: function () {
          return value || noData;
        }
      };
      var formatter = attrOperations[attr] || attrOperations.defaultFormat;
      return formatter(value);
    };
    for (i = 0; i < valueToBeDisplayed.length; i++) {
      returnArry.push({
        label: valueToBeDisplayed[i].label || valueToBeDisplayed[i].attr,
        value: formatData(valueToBeDisplayed[i].attr, modelJSON[valueToBeDisplayed[i].attr]),
        className: valueToBeDisplayed[i].className
      });
    }
    return returnArry.reverse();
  }
  DisplayCreativeCreateEdit = T1View.extend({
    template: template,
    tabs: {
      details: 0,
      classification: 1
    },
    dataEvents: {
      model: {
        "change:id": function () {
          var tabLayout = this.layout;
          var tabs = this.tabs;
          tabLayout.execute("enable", tabs.classification);
        }
      }
    },
    eventhubEvents: {
      "organization:explicitChange": function () {
        T1.Location.set("#creatives/displays");
      },
      "creative:updateModel": function (args) {
        var self = this;
        var creative;
        if (args) {
          creative = args.model;
          self.isEdit = creative.get("id") ? true : false;
          updateBreadCrumb(creative.toJSON());
          self.renderPartial(".w-head");
        } else {
          this.updateModels().then(function (model) {
            self.isT1AS = model.get("t1as") === "1";
            updateBreadCrumb(model.toJSON());
            self.renderPartial(".w-head");
          });
        }
      }
    },
    menuConfig: {
      menuTriggerEleSelector: ".creative-settings",
      menuColumns: [{
        columnTitle: null,
        menuItems: [{
          label: "Change log",
          handler: "viewChangeLog",
          visible: true
        }]
      }]
    },
    prepareMenuItems: function () {
      var self = this;
      var menuConfig = $.extend(true, {}, self.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      t1Menu(menuConfig, self);
    },
    canUnload: function () {
      return this.layout ? this.layout.canUnload() : true;
    },
    initialize: function (args) {
      var self = this;
      var mode = args.mode;
      var id = parseInt(args.id);
      if (!T1Permissions.check("feature", "creatives")) {
        T1.Location.set("#unauthorized", {
          replace: true
        });
        return;
      }
      self.mode = mode;
      self.isEdit = id !== undefined && !isNaN(id);
      self.model = new AtomicCreative();
      NextTabViewNavigation.attachBehavior(self);
      self.initializeData(args);
    },
    initializeData: function (args) {
      var self = this;
      var modelOrgID;
      var modelJSON;
      var modelAdvertiser;
      var modelAgency;
      self.canLoad = false;
      Organizations.getOrganizations().getSelected().then(function (orgID) {
        self.orgID = orgID;
        self.updateModels(args).then(function (model) {
          modelJSON = model.toJSON();
          modelAdvertiser = modelJSON.advertiser;
          modelAgency = modelAdvertiser && modelAdvertiser.agency;
          modelOrgID = modelAgency && modelAgency.organization_id;
          if (modelOrgID && orgID !== modelOrgID) {
            Organizations.getOrganizations().setSelected(modelOrgID).then(function () {
              self.initializeData(args);
            });
            return;
          }
          updateBreadCrumb(model.toJSON());
          self.canLoad = true;
          self.initLayout();
          self.load();
        });
      });
    },
    updateModels: function (args) {
      var self = this;
      var deferred = When.defer();
      var model = self.model;
      var id;
      args = args || {};
      id = parseInt(args.id || model.get("id"));
      if (id) {
        model.set({
          id: id
        });
        self.isEdit = true;
        model.fetch({
          params: {
            with: ["advertiser,agency", "creatives,vendor_pixels,vendor_pixel_domains,vendor_domain,vendor", "creatives,creative_assets", "atomic_creative_ssl_validators", "atomic_creative_detected_tag_urls,vendor_domain,vendor", "built_by_user"]
          },
          success: function success(model) {
            if (model.get("media_type") === "video") {
              T1.Location.set("#creatives/video/" + model.get("id"));
            }
            model.set({
              isActive: model.get("status") === "1",
              isSecure: model.get("is_https") === "1"
            });
            self.isT1AS = model.get("t1as") === "1";
            deferred.resolve(model);
          }
        });
      } else {
        model.set({
          action: "new",
          isActive: true,
          manualVendors: new Vendors(),
          autoVendors: new Vendors()
        });
        deferred.resolve(model);
      }
      return deferred.promise;
    },
    initLayout: function () {
      var self = this;
      var model = self.model;
      var tabs = this.tabs;
      var tabData;
      var layoutOptions;
      var disabledList = [];
      layoutOptions = {
        orgID: self.orgID,
        id: model.id,
        mode: self.mode,
        model: model
      };
      tabData = [{
        label: "Details",
        module: "creatives/display",
        viewType: "details",
        routeName: "details",
        showLoader: true,
        options: layoutOptions
      }];
      tabData.push({
        label: "Classification",
        module: "creatives/display",
        viewType: "classification",
        routeName: "classification",
        showLoader: true,
        options: layoutOptions
      });
      if (!self.isEdit) {
        disabledList = [tabs.classification];
      }
      self.layout = new T1TabLayout({
        el: function () {
          return $(self.el).find(".w-body");
        },
        tabSetName: "DisplayCreativeEditCreate",
        level: 1,
        disabled: disabledList,
        selected: tabs.details,
        tabs: tabData
      });
    },
    load: function () {
      var self = this;
      if (!this.canLoad) {
        return;
      }
      this.render().then(function () {
        self.layout.load();
        $.proxy(self.prepareMenuItems, self)();
      });
    },
    viewChangeLog: function (e) {
      var creative = this.model;
      var layout;
      $(document).trigger("click");
      e.preventDefault();
      e.stopPropagation();
      layout = new T1Layout({
        template: '<div class=".changeDialog"></div>',
        layout: {
          ".changeDialog": [{
            module: "changelog",
            viewType: "dialog",
            options: {
              model: creative,
              entityType: "creative"
            }
          }]
        }
      });
      layout.load();
    },
    getAgencyAndAdvertiserName: function () {
      var advertiser;
      var agency;
      var agencyDisplay;
      advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        agency = advertiser.get("agency");
        agencyDisplay = agency ? agency.get("name") + "&nbsp;&raquo;&nbsp;" : "";
        return agencyDisplay + advertiser.get("name");
      }
    },
    serialize: function () {
      var model = this.model;
      var output = model.toJSON();
      output.isEdit = this.isEdit;
      output.isT1AS = this.isT1AS;
      output.isEditedTag = this.isEditedTag;
      if (output.isEdit) {
        output.advertiser_name = output.advertiser ? output.advertiser.name : "ADVERTISER";
        output.agencyAndAdvertiser = this.getAgencyAndAdvertiserName();
      }
      return output;
    }
  });
  return DisplayCreativeCreateEdit;
});
