define('modules/audiences/segments/views/segments',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "T1Menu", "T1Permissions", "text!modules/audiences/segments/templates/segments.html", "text!modules/audiences/segments/templates/duplicateSegment.html", "text!modules/audiences/segments/templates/addSegmentButton.html", "text!modules/audiences/segments/templates/addSegmentPulldownButton.html", "modules/audiences/segments/segmentUtils", "../models/segmentColumns", "models/userPreferences", "models/sessionUser", "models/advertiser", "models/organization", "models/segment", "collections/segments", "collections/organizations", "modules/campaign/models/multiSearch", "T1Spinner", "jQPlugins/chosen.jquery", "jQueryUI"], function ($, _, Backbone, Hogan, T1, T1Menu, T1Permissions, template, duplicateTemplate, addSegmentButtonTemplate, segmentOptionTemplate, Util, segmentColumns, Preferences, User, Advertiser, Organization, Segment, Segments, Organizations, MultiSearch, T1Spinner) {
  "use strict";

  var multiSearch = new MultiSearch();
  var _pageLimit = 25;
  var renderDelay = 50;
  var utils = T1.Utils;
  var SEGMENTS_URL = "audiences/segments/";
  var STANDARD_SEGMENTS_URL = `${SEGMENTS_URL}standard/`;
  var ADAPTIVE_SEGMENTS_URL = `${SEGMENTS_URL}adaptive/`;
  var READ_ONLY = User.getUser().get("edit_segments") === "0";
  var READ_ONLY_MESSAGE = "You don't have permission to edit this segment.";
  var CAN_SEE_EXPORTS = T1Permissions.check("feature", "segments_export_toggle");
  const CAN_ACCESS_ADAPTIVE = T1Permissions.check("feature", "adaptive-segments");
  const ADAPTIVE_SEGMENTS = "Adaptive Segments";
  const STANDARD_SEGMENTS = "Standard Segments";
  function updateBreadCrumb() {
    T1.EventHub.publish("updateNavPath", {});
  }
  var overlapNoticeShown = !T1Permissions.check("feature", "overlap_mm_inline_notice");
  var segmentsView = Backbone.View.extend({
    template: template,
    duplicateTemplate: duplicateTemplate,
    addSegmentButton: addSegmentButtonTemplate,
    segmentOptionTemplate: segmentOptionTemplate,
    pageNum: 0,
    filterViewMode: "campaigns",
    pageData: [],
    collection: multiSearch,
    segCollection: null,
    grid: "",
    orgId: -1,
    defaultEntity: "segments",
    segments: [],
    segmentType: "",
    stripId: function (str) {
      if (str) {
        var id = str.match(/[(][0-9]+[)]/);
        return $.trim(str.replace(id, ""));
      }
      return null;
    },
    initialize: function () {
      var self = this;
      var signature = T1.Signature.create();
      Util.jQueryExtend();
      this.boundReload = this.reload.bind(this);
      signature.set(new Map([["root", "ui"], ["module", "audiences/segments"], ["view", "segments"]]));
      this.preferences = T1.Preferences.create(signature, Preferences, this);
      this.preferences.mark(["segmentType"]);
      this.segmentType = this.preferences.get("segmentType");
      this.segmentType = this.segmentType === ADAPTIVE_SEGMENTS && CAN_ACCESS_ADAPTIVE ? ADAPTIVE_SEGMENTS : STANDARD_SEGMENTS;
      this.sizeColumnExists = true;
    },
    load: function () {
      updateBreadCrumb();
      T1.EventHub.subscribe("organization:select", this.boundReload);
      this.render().then(() => {
        setTimeout(() => {
          this.loadExecute();
        }, renderDelay);
      });
    },
    events: {
      "click #entities-results": "selectText",
      "click #show-more-segments": "loadSegments",
      "click #add-standard-segment": "addStandardSegment",
      "click #add-adaptive-segment": "addAdaptiveSegment",
      "selected #add-segment-option": "addSegmentOption",
      "click #segment-export": "jumpToSegmentExports",
      "click .clear-filter": "resetSearch",
      "change .list-size-list": "loadSegments",
      "click [id^=segment-edit]": "openSegment",
      "click [id^=segment-duplicate]": "initDuplicateSegment",
      "click [id^=strategy-target]": "editStrategy",
      "click [id^=segment-view]": "openSegment",
      "click #segment-targeting": "showTargeting",
      "changed #segment-type": "changeSegmentTypeAndLoad"
    },
    openSegment: function (e) {
      if (this.segmentType === ADAPTIVE_SEGMENTS) {
        T1.Location.set(ADAPTIVE_SEGMENTS_URL + e.currentTarget.id.replace(/\D+/, ""));
      } else {
        T1.Location.set(STANDARD_SEGMENTS_URL + e.currentTarget.id.replace(/\D+/, ""));
      }
    },
    editStrategy: function (e) {
      T1.Location.set(`strategy/edit/${e.currentTarget.id.replace(/\D+/, "")}/details/general`);
    },
    showTargeting: function (e) {
      var target = $(e.currentTarget).next()[0];
      $.makeArray($(target).children().children()).map(loadStratNames);
      function loadStratNames(child) {
        if (child.localName === "strand-list-item" && !child.classList.contains("namedStrat")) {
          $.ajax({
            url: `${T1.API_ROOT}strategies/${$(child).text()}`,
            success: function (result) {
              var jsonObj = utils.parseXMLtoJson(result);
              child.innerHTML = jsonObj.entity.name;
              child.classList.add("namedStrat");
            }
          });
        }
      }
    },
    changeSegmentTypeAndLoad: function (e) {
      this.segmentType = e.originalEvent.detail.value;
      this.hideErrorMessage();
      this.loadSegments();
      this.preferences.save();
    },
    selectText: function () {
      var el = $(this.el);
      var searchInput = el.find(".term .text");
      var value = searchInput.val();
      searchInput.select().autocomplete("search", this.stripId(value));
    },
    source: function (request, response) {
      var data, promise;
      promise = this.collection.find(request.term, $(this.el).find(".select-input").val());
      promise.done(function (result) {
        data = result.data;
        response($.map(data, function (item) {
          item.label = item.name;
          item.value = item.name;
          return item;
        }));
      }).fail(function () {
        response([]);
      });
    },
    render: function () {
      var deferred = $.Deferred();
      var filterList = [{
        text: "Segments",
        value: "segments"
      }, {
        text: "Agencies",
        value: "agencies"
      }, {
        text: "Advertisers",
        value: "advertisers"
      }];
      this.setDropDownEntity(filterList);
      this.compiledTemplate = Hogan.compile(this.template);
      this.compiledDuplicateTemplate = Hogan.compile(this.duplicateTemplate);
      var renderedTemplate = this.compiledTemplate.render({
        model: "{{model}}",
        scope: "{{scope}}",
        data: "{{data}}",
        page: "{{page}}",
        terms: "{{terms}}",
        sortField: "{{sortField}}",
        sortOrder: "{{sortOrder}}",
        isLoading: "{{isLoading}}",
        noResultsMessage: "{{noResultsMessage}}",
        id: "{{model.id}}",
        name: "{{model.name}}",
        icon: "{{model.editable}}",
        overlapNoticeShown: overlapNoticeShown,
        noicon: "{{model.icon}}",
        target: '{{findById("segment-settings")}}',
        targetingTarget: '{{findById("segment-targeting")}}',
        targeting: "{{model.pixel_targeted}}",
        nottargeting: "{{model.targetColor}}",
        creator: "{{model.creator}}",
        isAdaptive: "{{model.isAdaptive}}",
        isStandard: "{{!model.isAdaptive}}",
        filterList: filterList,
        columns: segmentColumns.tableColumns,
        readOnly: READ_ONLY,
        readOnlyMessage: READ_ONLY_MESSAGE,
        canSeeExports: CAN_SEE_EXPORTS,
        canAccessAdaptive: CAN_ACCESS_ADAPTIVE,
        search: "{{model.search}}",
        nosearch: "{{!model.search}}",
        highlightName: "{{model.highlightName}}",
        highlightId: "{{model.highlightId}}",
        highlightClass: "{{item.highlightClass}}",
        partialName: "{{item.partialName}}",
        item: "{{item}}",
        targetedStrategies: "{{model.pixel_targeted_strategies}}"
      });
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = renderedTemplate;
      } else {
        $(this.el).html(renderedTemplate);
      }
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    loadExecute: function () {
      var self = this;
      var el = $(self.el);
      var org = new Organization();
      var entityInput = null;
      var searchInput = null;
      var segmentTypeDropdown = null;
      this.segCollection = new Segments(null, {
        pageLimit: _pageLimit,
        pageOffset: 0
      });
      self.grid = this.el[0].querySelector("#list-of-segments");
      self.segmentCollection = this.el[0].querySelector("#collection");
      self.segmentCollection.addEventListener("seg-collection:call", function () {
        self.grid.isLoading = true;
      });
      self.segmentCollection.addEventListener("seg-collection:load", function () {
        self.showSegmentSize();
        self.grid.isLoading = false;
      });
      self.segmentCollection.addEventListener("seg-collection:noResults", function (msg) {
        self.grid.noResultsMessage = msg.detail;
      });
      self.segmentCollection.addEventListener("seg-collection:errors", function () {
        T1.Notify("error", "Internal Error. Please contact T1 Support.");
      });
      $(".refresh-timestamp").hide();
      $(".segment-message-container").hide();
      $(".empty-advertiser").hide();
      $("#entities-dropdown").chosen({
        disable_search_threshold: 10
      });
      $(".list-size-list").chosen({
        disable_search_threshold: 10
      });
      entityInput = el.find(".select-input");
      searchInput = el.find(".term .text");
      segmentTypeDropdown = el.find("#segment-type")[0];
      if (!overlapNoticeShown) {
        overlapNoticeShown = true;
      }
      entityInput.change(function () {
        self.entityType = entityInput.val();
        searchInput.val("");
      });
      $("#entities-results").autocomplete({
        minLength: 1,
        delay: 1,
        autoFocus: true,
        appendTo: ".entities-auto-complete",
        source: function (request, response) {
          if ($.trim(request.term) === "#") {
            searchInput.removeClass("ui-autocomplete-loading");
            return;
          }
          self.searchTerm = request.term;
          if (self.entityType === "segments") {
            $(self.el).find(".clear-filter").hide();
            self.pageNum = 0;
            self.loadSegments({
              term: request.term
            });
          } else {
            self.source.call(self, request, function (data) {
              searchInput.autocomplete({
                autoFocus: true
              });
              if (!data.length) {
                data = [{
                  summary: `No results found for "${utils.escapeHTML(request.term)}"`
                }];
                searchInput.autocomplete({
                  autoFocus: false
                });
              }
              searchInput.removeClass("ui-autocomplete-loading");
              response(data);
            });
          }
        },
        focus: function () {},
        select: function (event, ui) {
          var item = ui.item;
          var args = {
            entityId: item.id,
            ownerType: null
          };
          self.ownerType = $(self.el).find(".select-input").val();
          args.ownerType = self.ownerType;
          searchInput.val(item.label).select();
          self.pageNum = 0;
          self.loadSegments(args);
          return false;
        }
      }).keyup(function (e) {
        if (self.entityType === "segments" && !e.currentTarget.value) {
          self.resetSearch();
        }
      }).data("autocomplete")._renderItem = self.renderItem;
      searchInput.data("autocomplete")._renderMenu = self.renderMenu;
      $("#show-more-segments").attr("disabled", "true");
      $(".show-more-segments-wrapper").hide();
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.collection.orgId = orgId;
        this.segmentCollection.orgId = orgId;
        self = this;
        org = new Organization({
          id: orgId
        });
        org.fetch({
          onSuccess: function (orgModel) {
            switch (Util.getPermissions()) {
              case "full":
                setViewOptions({
                  segmentType: "adaptive",
                  showSelector: true,
                  buttonOpts: {
                    template: self.segmentOptionTemplate,
                    pulldown: true
                  },
                  addSegmentEnabled: true
                });
                break;
              case "view_only":
                setViewOptions({
                  segmentType: "adaptive",
                  showSelector: true,
                  buttonOpts: {
                    template: self.segmentOptionTemplate,
                    pulldown: true
                  },
                  addSegmentEnabled: false
                });
                break;
              case "none":
                self.setErrorMessage();
                break;
            }
          }
        });
      }, this));
      T1.resizeWidth();
      this.creatorColumn = document.querySelector("strand-grid").getColumnByField("creator_username");
      this.sizeColumn = document.querySelector("strand-grid").getColumnByField("size");
      function setViewOptions(options) {
        var segmentType = options.segmentType;
        var showSelector = options.showSelector;
        var buttonTemplate = options.buttonOpts.template;
        var addSegmentEnabled = options.addSegmentEnabled;
        const compiledTemplate = Hogan.compile(buttonTemplate);
        const renderedTemplate = compiledTemplate.render({
          canAccessAdaptive: CAN_ACCESS_ADAPTIVE
        });
        self.el.find(".segment-message-container").hide();
        segmentTypeDropdown.value = self.segmentType || `${T1.Utils.capitalize(segmentType)} Segments`;
        segmentTypeDropdown.hidden = !showSelector;
        segmentTypeDropdown.disabled = !showSelector;
        self.loadSegments();
        $("#add-segment-container", self.el).html(renderedTemplate);
        if (!addSegmentEnabled) {
          self.el.find(".add-segment").attr("disabled", "");
        }
        setAddSegmentButton(segmentType, options.buttonOpts);
      }
      function setAddSegmentButton(type, buttonOpts) {
        var $button;
        if (!buttonOpts.pulldown) {
          $button = self.el.find(".add-segment");
          $button.find("label").text(`Add ${type} Segment`);
          $button.attr("id", `add-${type}-segment`);
        }
      }
    },
    setErrorMessage: function () {
      var $inlineError = this.el.find(".segment-message");
      var boxType = "warning";
      $inlineError.addClass("user");
      $inlineError[0].$.content.innerHTML = "It looks like you don't have access to this page. " + "If you believe you are seeing this message in error, please contact our support team.";
      $inlineError[0].type = boxType;
      this.el.find(".segment-message-container").show();
      this.el.find(".campaign-controls").hide();
    },
    hideErrorMessage: function () {
      this.el.find(".segment-message-container").hide();
      this.el.find(".campaign-controls").show();
    },
    loadSegments: function (args) {
      var self = this;
      var collection = this.segCollection;
      var searchInput = this.el.find(".term .text");
      var searchValue = searchInput.val();
      var searchParams = [];
      collection.searchConfiguration.fields.segment_type = {
        type: "exact"
      };
      if (self.entityType === "segments" && !(args && "term" in args)) {
        if (searchValue && searchValue !== "#") {
          if (args && "target" in args && args.target === this.el.find("#segment-type")[0]) {
            args = {
              term: searchValue
            };
          }
        }
      }
      self.segmentCollection.sourceUrl = `${T1.SEGMENT_API_BASE}segments`;
      self.grid.isLoading = true;
      searchParams.push({
        term: self.segmentType === ADAPTIVE_SEGMENTS ? "adaptive" : "standard",
        type: "exact",
        searchField: "segment_type"
      });
      if (args && "ownerType" in args && "entityId" in args) {
        searchParams.push(args);
      } else if (args && "term" in args) {
        self.segmentCollection.search = args.term;
      } else {
        self.segmentCollection.search = "";
      }
      self.segmentCollection.searchOptions = searchParams;
      if (args) {
        searchInput.removeClass("ui-autocomplete-loading");
        self.searchIconSwap();
      }
    },
    showSegmentSize: function () {
      var segmentList = this.el[0].querySelector("#list-of-segments");
      if (this.segmentType === STANDARD_SEGMENTS && this.sizeColumnExists) {
        segmentList.removeColumn(this.sizeColumn);
        this.sizeColumnExists = false;
      } else if (this.segmentType === ADAPTIVE_SEGMENTS) {
        segmentList.insertBeforeColumn(this.sizeColumn, this.creatorColumn);
        this.sizeColumnExists = true;
      }
    },
    onCollectionError: function () {
      $(".empty-advertiser").show();
      $(".attribute-list .error-loading-advertiser").show();
      $("#add-attribute").attr("disabled", true);
      $("#save-attributes").attr("disabled", true);
      $("#cancel-attributes").addClass("disabled");
    },
    renderMenu: function (ul, items) {
      var self = this;
      var currentCategory = "";
      $.each(items, function (index, item) {
        if (item.category && item.category !== currentCategory) {
          ul.append(`<li class="ui-autocomplete-category"><b></b>${item.category}</li>`);
          currentCategory = item.category;
        }
        if (item.hasOwnProperty("summary")) {
          ul.append(`<li class='ui-autocomplete-summary'>${item.summary}</li>`);
        } else {
          item.label = item.value = `${item.label} (${item.id})`;
          self._renderItem(ul, item);
        }
      });
    },
    renderItem: function (ul, item) {
      return $("<li></li>").data("item.autocomplete", item).append(`<a>${item.label}</a>`).appendTo(ul);
    },
    addSegmentOption: function (e) {
      e.originalEvent.detail.value === "Adaptive Segment" ? this.addAdaptiveSegment() : this.addStandardSegment();
    },
    addStandardSegment: function () {
      T1.EventHub.unsubscribe("organization:select", this.orgChanged);
      T1.Location.set(`${STANDARD_SEGMENTS_URL}create`);
    },
    addAdaptiveSegment: function () {
      T1.EventHub.unsubscribe("organization:select", this.orgChanged);
      T1.Location.set(`${ADAPTIVE_SEGMENTS_URL}create`);
    },
    jumpToSegmentExports: function () {
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
      T1.Location.set(`${SEGMENTS_URL}export`, {});
    },
    searchIconSwap: function () {
      var $el = $(this.el);
      $el.find("#entities-results").addClass("hide-magnifying-glass");
      $el.find(".clear-filter").show();
    },
    resetSearch: function () {
      var $el = $(this.el);
      var $searchBox = $el.find("#entities-results");
      $el.find(".clear-filter").hide();
      $searchBox.removeClass("hide-magnifying-glass").val("");
      this.loadSegments();
    },
    findEntity: function (entities, value) {
      var entity = entities.filter(function (obj) {
        return obj.value === value;
      });
      return entity[0];
    },
    setDropDownEntity: function (entities) {
      if (this.ownerType) {
        this.findEntity(entities, this.ownerType).selected = "selected";
      } else {
        this.findEntity(entities, this.defaultEntity).selected = "selected";
        this.entityType = this.defaultEntity;
      }
    },
    initDuplicateSegment: function (e) {
      var self = this;
      var $el = this.el;
      var $modalEl = $el.find("#duplicate-modal");
      var segmentID = parseInt(e.currentTarget.id.replace(/\D+/, ""), 10);
      var $duplicateButton, $duplicateCancel, $duplicateName, $modal, newName, sourceSegment;
      var currentData = document.querySelector("#list-of-segments").data;
      sourceSegment = _.findWhere(currentData, {
        id: segmentID
      });
      Polymer.dom($modalEl[0]).innerHTML = this.compiledDuplicateTemplate.render({
        sourceAdv: sourceSegment.advertiser.name,
        sourceName: sourceSegment.name,
        sourceID: segmentID
      });
      $modal = $modalEl.find(".duplicate-segment");
      $duplicateButton = $modal.find("#duplicate-segment-save");
      $duplicateCancel = $modal.find("#duplicate-segment-cancel");
      $duplicateName = $modal.find("#duplicate-segment-name");
      $modal[0].show();
      $duplicateName.on("changed", function (e) {
        newName = e.target.value;
        if (newName.trim().length > 0) {
          $duplicateButton.removeAttr("disabled");
        } else {
          $duplicateButton.attr("disabled", "");
        }
      });
      $duplicateButton.on("click", function (e) {
        self.el.find("#duplicate-segment-cancel").hide();
        Util.buttonLoader(e);
        self.duplicateSegment(segmentID, newName);
      });
      $duplicateCancel.on("click", function (e) {
        e.preventDefault();
        $modal.remove();
      });
    },
    duplicateSpinner: function (e) {
      var button = $(e.target).find("label");
      $(e.target).attr("disabled", "");
      button.empty();
      T1Spinner.apply(button);
    },
    duplicateSegment: function (id, newName) {
      var self = this;
      var sourceSegment = new Segment({
        id: id.toString()
      });
      if (newName.length > 255) {
        newName = newName.substring(0, 255);
      }
      sourceSegment.fetch({
        dataType: "XML"
      }).pipe(function () {
        self.createDuplicateObj(sourceSegment, newName);
      });
    },
    createDuplicateObj: function (sourceSegment, newName) {
      var self = this;
      sourceSegment.set({
        name: newName
      });
      sourceSegment.set({
        id: "new"
      });
      self.saveDuplicate(sourceSegment);
    },
    saveDuplicate: function (sourceSegment) {
      var self = this;
      var $modalEl = this.el.find("#duplicate-modal");
      var $duplicateCancel, footer, modal;
      modal = $modalEl.find(".duplicate-segment");
      footer = $modalEl.find("#duplicate-segment-footer")[0];
      $duplicateCancel = modal.find("#duplicate-segment-cancel");
      sourceSegment.save({
        isDuplicate: true
      }).pipe(function () {
        self.showDuplicateSegment(sourceSegment);
      }, function () {
        footer.type = "error";
        footer.message = "Could not duplicate";
        footer.showMessage();
        $duplicateCancel.show();
        Util.buttonReset($duplicateCancel, "Duplicate");
      });
    },
    showDuplicateSegment: function (sourceSegment) {
      var viewOptions = {
        viewOptions: {
          duplicated: true
        }
      };
      T1.Location.set(SEGMENTS_URL + sourceSegment.id, viewOptions);
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      $(self.segmentCollection).off();
      $(this.el).empty();
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
    }
  });
  return segmentsView;
});
