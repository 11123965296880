define('modules/campaign/pixelSegment/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "models/customBrainSelection", "utils/CampaignStrategyUtils", "text!modules/campaign/pixelSegment/templates/panel.html", "text!templates/form_footer.html"], function ($, _, T1, T1View, T1Layout, CustomBrainSelection, CampaignStrategyUtils, template, form_footer) {
  "use strict";

  var maxPixels = COMPASS_ENV.TARGETING_MAX_PIXELS;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var PixelSegmentPanel = T1View.extend({
    template: template,
    viewMode: "browse",
    partials: {
      form_footer: form_footer
    },
    dataEvents: {},
    eventhubEvents: {
      "assigned:pixel": "assignPixel",
      "strategy-create-edit:savedPixelBundels": "updateTempCollection"
    },
    events: {
      "focus .text": "showTooltip",
      "blur .text": "hideTooltip",
      "keydown .text": "removeTooltip"
    },
    initialize: function ({
      model: model
    }) {
      this.campaign = model;
      this.campaign.getOrgId().then(orgId => {
        this.orgId = orgId;
      });
      const customBrainSelectionModel = new CustomBrainSelection({
        id: model.id
      });
      customBrainSelectionModel.fetchOptions = {
        full: "*"
      };
      this.partialCollection = customBrainSelectionModel.get("partials");
      this.sharedCollection = customBrainSelectionModel.get("user_saved");
      customBrainSelectionModel.fetch({
        success: () => {
          this.sharedCollection.id = this.campaign.id;
          this.advertiser = this.campaign.get("advertiser").toJSON();
          this.initLayout();
          if (!this.layout.loaded) {
            this.layout.load();
          }
          this.addDataEvent({
            sharedCollection: {
              add: function () {
                this.isFormElementsChanged = true;
                if (this.moreThanTwentyWarning) {
                  this.updateFooterMessage({
                    warning: true,
                    message: "There are unsaved changes on the page."
                  });
                  this.moreThanTwentyWarning = false;
                }
              },
              remove: function () {
                this.isFormElementsChanged = true;
                if (this.moreThanTwentyWarning) {
                  this.updateFooterMessage({
                    warning: true,
                    message: "There are unsaved changes on the page."
                  });
                  this.moreThanTwentyWarning = false;
                }
              },
              reset: function () {
                this.isFormElementsChanged = false;
                this.load();
              }
            }
          });
        }
      });
    },
    showTooManyPixelsMsg: function () {
      this.updateFooterMessage({
        warning: true,
        message: `You may not make more than ${maxPixels} selections.`
      });
    },
    assignPixel: function (data) {
      var sharedCollection = this.sharedCollection;
      var selectedList = data.selectedList;
      this.updateFooterMessage({
        warning: false,
        message: ""
      });
      if (selectedList) {
        sharedCollection.remove(selectedList);
        if (sharedCollection.length < maxPixels) {
          sharedCollection.add(selectedList);
          this.isFormElementsChanged = true;
          this.checkUnsavedState();
        } else {
          this.showTooManyPixelsMsg();
        }
      } else {
        let oldAction;
        const itemInSharedCollection = sharedCollection.get(data.id);
        const newAction = data.action || data.get("action");
        if (itemInSharedCollection) {
          oldAction = itemInSharedCollection.get("action");
        }
        sharedCollection.remove(data);
        if (oldAction && newAction === oldAction) {
          return;
        }
        if (sharedCollection.length < maxPixels) {
          sharedCollection.add(data);
          this.isFormElementsChanged = true;
          this.checkUnsavedState();
        } else {
          this.showTooManyPixelsMsg();
        }
      }
    },
    load: function () {
      var self = this;
      this.canScreenUnload = true;
      this.render().then(function () {
        const $entityInput = self.$(".select-input");
        T1.Selectbox($entityInput, {
          onChange: function () {
            const term = this.$(".term input").val().trim();
            const providerID = self.$(".providers").val();
            const minInputLength = 2;
            if (term.length >= minInputLength) {
              T1.EventHub.publish("searchAudience", {
                term: term,
                provider_id: providerID
              });
            }
          }
        });
        if (self.layout) {
          self.layout.load().then(function () {
            self.$(".search-c").hide();
          });
          self.loaded = true;
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn();
          self.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          self.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    updateTempCollection: function () {
      const origColl = this.strategy.pixel_bundles;
      const tempColl = {
        data: [],
        includeOp: this.strategy.pixel_bundles.includeOperator
      };
      origColl.each(function (pixelModel) {
        tempColl.data.push(pixelModel.clone());
      });
      this.sharedCollection.tempCollection = tempColl;
    },
    refreshDDL: function () {
      this.renderPartial(".select-input");
      this.$(".select-input").trigger("liszt:updated");
    },
    initLayout: function () {
      const {
        advertiser: advertiser,
        sharedCollection: sharedCollection,
        partialCollection: partialCollection
      } = this;
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: '<div class="column-A"></div><div class="bins-column"></div>',
        layout: {
          ".column-A": [{
            module: "campaign/pixelSegment",
            viewType: "list",
            options: {
              orgId: this.orgId,
              searchViewType: "filterSearch",
              sharedCollection: sharedCollection,
              partialCollection: partialCollection,
              allowMultipleSelection: true,
              campaign: this.campaign,
              getAdvertiser: () => advertiser,
              getAdvertiserId: () => advertiser.id,
              getAdvertiserName: () => advertiser.name
            }
          }],
          ".bins-column": [{
            module: "campaign/pixelSegment",
            viewType: "bins",
            options: {
              sharedCollection: sharedCollection,
              partialCollection: partialCollection
            }
          }]
        }
      });
    },
    unload: function () {
      if (this.canScreenUnload) {
        T1.EventHub.publish("pixel-in-brain-leave");
        this.canScreenUnload = false;
      }
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        let campaignName = "untitled strategy";
        const model = this.model;
        if (model.get("name")) {
          campaignName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: campaignName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: campaignName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    onDontSave: function () {
      const customBrainSelectionModel = new CustomBrainSelection({
        id: this.campaign.id
      });
      customBrainSelectionModel.fetchOptions = {
        full: "*"
      };
      this.partialCollection = customBrainSelectionModel.get("partials");
      this.sharedCollection = customBrainSelectionModel.get("user_saved");
      customBrainSelectionModel.fetch({
        success: () => {
          this.initLayout();
          this.sharedCollection.id = this.campaign.id;
        }
      });
    },
    onVersionConflictClose: function () {
      const customBrainSelectionModel = new CustomBrainSelection({
        id: this.campaign.id
      });
      customBrainSelectionModel.fetchOptions = {
        full: "*"
      };
      this.partialCollection = customBrainSelectionModel.get("partials");
      this.sharedCollection = customBrainSelectionModel.get("user_saved");
      customBrainSelectionModel.fetch({
        success: () => {
          this.initLayout();
          this.sharedCollection.id = this.campaign.id;
        }
      });
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    postData: function (formValues, successCallback, conflictCallback) {
      const $el = this.el;
      const maxItems = 20;
      this.sharedCollection.action = "custom_brain_selections";
      if (this.sharedCollection.models.length > maxItems) {
        this.updateFooterMessage({
          error: true,
          message: "Only can select up to 20 items."
        });
        this.moreThanTwentyWarning = true;
        this.toggleSpinner(false, $el, "save");
        this.toggleSpinner(false, $el, "saveAndContinue");
        if ($(this.$(".form-footer").find(".action")[1]).children().hasClass("save-disabled")) {
          $(this.$(".form-footer").find(".action")[1]).children().removeClass("save-disabled");
        }
        if ($el.parents().find(".ui-dialog").length === 1) {
          $el.parents().find(".ui-dialog .cancel").click();
        }
        return;
      }
      this.sharedCollection.saveCustomBrainSelections(formValues, successCallback, conflictCallback);
    }
  });
  PixelSegmentPanel = T1.Form.ViewUtils.extend(PixelSegmentPanel);
  return PixelSegmentPanel;
});
