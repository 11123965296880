define('collections/componentCreativeNetStats',["Underscore", "T1", "T1Collection"], function (_, T1, T1Collection) {
  "use strict";

  T1.Models.ComponentCreativeNetStats = T1Collection.extend({
    apiRoot: COMPASS_ENV.COMPONENT_API_BASE,
    canCache: false,
    urlString: "approvals",
    atomicCreativeIDs: "",
    url: function () {
      var appendString = "";
      var urlString;
      if (this.urlString) {
        urlString = typeof this.urlString === "function" ? this.urlString() : this.urlString;
      }
      if (this.atomicCreativeIDs && this.atomicCreativeIDs.length) {
        appendString += `?atomic_creative_ids=${this.atomicCreativeIDs}`;
      }
      return this.apiRoot + urlString + appendString;
    },
    fetch: function (options = {}) {
      const success = data => {
        this.loaded = true;
        this[options.add ? "add" : "reset"](data.data);
        if (options.success) {
          options.success(data);
        }
        return this;
      };
      function processAjaxResponse(resp) {
        const status = resp.meta && resp.meta.status;
        return {
          jsonData: resp.data,
          statusCode: status === "success" ? "ok" : status
        };
      }
      if (!options.add) {
        this.unregister();
        this.page = 0;
      }
      this.loaded = false;
      const syncOpts = {
        action: this.action,
        dataType: "json",
        success: success,
        processAjaxResponse: processAjaxResponse
      };
      if (options.url) {
        syncOpts.params = options.url;
      }
      return this.sync("read", "", syncOpts);
    }
  });
  return T1.Models.ComponentCreativeNetStats;
});
