define('modules/targeting/dayPart/views/list',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Spinner", "utils/CampaignStrategyUtils", "text!../templates/list.html"], function ($, _, T1, T1View, T1Layout, Spinner, CampaignStrategyUtils, template) {
  "use strict";

  var list = ".day-part-list-items";
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  const DayPartList = T1View.extend({
    template: template,
    events: {
      "click .add-action": "addDayPartModel",
      "click .add-action-disabled": "preventDefault",
      "click .run-all-day-parts .checkbox": "toggleRunAllDayParts",
      "mouseover .select-days .title": "showTooltip"
    },
    dataEvents: {
      collection: {
        change: function () {
          this.checkOrUncheck();
          this.isFormElementsChanged = true;
        },
        add: function (model) {
          this.addDayPartView(model);
          this.isFormElementsChanged = true;
        },
        remove: function (model) {
          this.removeDayPartView(model);
          this.isFormElementsChanged = true;
          if (!this.collection.length) {
            this.runAllDayParts();
          }
        }
      }
    },
    initialize: function ({
      strategy: strategy
    }) {
      this.strategy = strategy;
      this.switchSpinner("on");
      this.collection = this.strategy.fetchDayParts({
        success: () => {
          this.switchSpinner("off");
          this.layout = new T1Layout({
            el: () => this.el,
            template: `<ul class="${list.slice(1)}"></ul>`,
            selectorPath: ".day-part-list",
            wrapperEl: '<li class="day-part-list-item"></li>',
            layout: {}
          });
          this.load();
        }
      });
    },
    addDayPartView: function (dayPart) {
      this.appendToLayout([dayPart]);
    },
    addDayPartModel: function (flag) {
      var dayPart = {
        start_hour: "0",
        end_hour: "23",
        user_time: "1"
      };
      if (flag.preventDefault) {
        flag.preventDefault();
      }
      dayPart.days = flag === "allDays" ? "MTWRFSU" : "";
      this.collection.add(dayPart);
      if (flag.preventDefault) {
        this.preventDefault(flag);
      }
      if (this.shouldBeUnchanged) {
        this.isFormElementsChanged = false;
        this.shouldBeUnchanged = false;
      }
    },
    removeItem: function (item) {
      var dayPart = item.model;
      this.collection.remove(dayPart);
      item.remove();
    },
    removeDayPartView: function (dayPart) {
      var layout = this.layout.layout[list];
      var len = layout.length;
      for (let i = 0; i < len; i++) {
        const model = layout[i].view.model;
        if (model === dayPart) {
          layout[i].view.remove();
          return;
        }
        if (i + 1 === len) {
          throw new Error("removed dayPart not in layout");
        }
      }
    },
    switchSpinner: function (direction) {
      const $spinner = this.$(".spinner");
      var spinnerOpts = {
        lines: 12,
        length: 4,
        width: 3,
        radius: 2,
        corners: 1,
        rotate: 0,
        trail: 14,
        speed: 1.4
      };
      if (direction === "on") {
        this.isFetching = true;
        Spinner.apply($spinner, spinnerOpts);
      } else if (direction === "off") {
        this.isFetching = false;
        $spinner.spin(false);
      }
    },
    clearLayout: function () {
      this.layout.empty();
    },
    toggleAddAction: function () {
      var $button = this.el.find("button.btn-add-primary");
      $button.toggleClass("add-action");
      $button.toggleClass("add-action-disabled");
    },
    checkOrUncheck: function () {
      if (this.isAllDayParts && this.collection.length === 1 || this.collection.isAllDayParts()) {
        this.toggleAddAction();
        this.load();
      }
    },
    toggleRunAllDayParts: function (e) {
      e.stopPropagation();
      if (e.target.checked) {
        this.runAllDayParts();
      } else {
        this.toggleAddAction();
        if (this.layout) {
          const dayPartModel = this.collection.at(0);
          const dayPartView = this.layout.layout[list][0].view;
          dayPartModel.set({
            days: ""
          }, {
            silent: true
          });
          dayPartView.isAllDayUnchecked = true;
          dayPartView.load();
        }
      }
    },
    runAllDayParts: function () {
      var coll = this.collection;
      this.layout.empty();
      coll.reset();
      this.addDayPartModel("allDays");
      this.dontAppend = true;
      this.toggleAddAction();
      this.load();
    },
    appendToLayout: function (models) {
      const controller = this;
      this.layout.append(list, {
        module: "targeting/dayPart",
        viewType: "item"
      }, models, function (model) {
        return {
          model: model,
          controller: controller
        };
      });
    },
    isDayOkay: function (selectedHours) {
      const fullDay = 24;
      const hours = Array(fullDay).fill(true);
      for (let j = 0; j < selectedHours.length; j++) {
        const start = selectedHours[j][0];
        const end = selectedHours[j][1];
        for (let k = start; k <= end; k++) {
          if (hours[k]) {
            hours[k] = false;
          } else {
            return false;
          }
        }
      }
      return true;
    },
    unload: function () {
      T1.EventHub.publish("strategyTargetingLoader:start");
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    canUnload: function (onTabChange) {
      const strategyName = this.strategy.get("name") || "untitled strategy";
      var isChanged = this.isFormElementsChanged;
      if (isChanged) {
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isChanged;
    },
    onDontSave: function () {
      this.switchSpinner("on");
      this.collection = this.strategy.fetchDayParts({
        success: () => {
          this.switchSpinner("off");
          this.load();
        }
      });
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    postData: function (formValues, successCallback, statusInvalidCallback) {
      function applyTooltip(currentEl, message) {
        var errorConfig = {
          tooltipEle: currentEl,
          getExternalTip: false,
          className: "w-FormError w-DayPartError",
          offset: 10
        };
        currentEl.find(".title").addClass("error");
        currentEl.attr("title", message);
        T1.Tooltip(currentEl, errorConfig);
      }
      this.strategy.saveDayParts(this.collection.toJSON(), successCallback, errors => {
        const selectDays = this.$(".select-days");
        for (let i = 0; i < errors.length; i++) {
          const index = errors[i].field.split(".")[1];
          applyTooltip($(selectDays[parseInt(index, 10) - 1]), errors[i].message);
        }
        statusInvalidCallback(errors);
      });
    },
    load: function () {
      var coll = this.collection;
      const hasNoDayParts = !coll.length;
      var isAllDayParts = coll.isAllDayParts();
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.isAllDayParts = hasNoDayParts || isAllDayParts;
      if (!this.dontAppend && this.layout) {
        this.layout.empty();
      }
      this.render().then(() => {
        if (this.isFetching) {
          return;
        }
        T1.EventHub.publish("strategyTargetingLoader:stop");
        if (this.layout) {
          this.layout.load();
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "dayPart_targeting"
          });
        }
        if (hasNoDayParts) {
          this.shouldBeUnchanged = true;
          this.addDayPartModel("allDays");
        } else if (!this.dontAppend) {
          this.appendToLayout(coll);
        } else {
          this.dontAppend = false;
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(this.strategy.get("campaign"));
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    serialize: function () {
      return {
        isAllDayParts: this.isAllDayParts
      };
    }
  });
  return T1.Form.ViewUtils.extend(DayPartList);
});
