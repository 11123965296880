define('modules/reporting/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "tabs",
    name: "reporting",
    viewCfgs: {
      tabs: {}
    }
  });
});
