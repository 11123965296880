define('modules/reporting/campaigns/dataExport/createEdit/views/dateRange',['require','jQuery','Underscore','moment','./../../models/reportDefaults','T1','T1View','text!../templates/dateRange.html','T1DatePicker'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const moment = require("moment");
  const ReportDefaults = require("./../../models/reportDefaults");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/dateRange.html");
  const T1DatePicker = require("T1DatePicker");
  const T1Publish = T1.EventHub.publish;
  const allowedFFkeys = Object.freeze(["Backspace", "ArrowLeft", "ArrowRight", "Enter"]);
  const debounceDelay = 100;
  const oneMonth = 30;
  const oneWeek = 7;
  const today = moment().toDate();
  const yesterday = moment().subtract(1, "days").toDate();
  const default30DaysAgo = moment().subtract(oneMonth, "days").toDate();
  const default7DaysAgo = moment().subtract(oneWeek, "days").toDate();
  const rangeLimitMap = {};
  const multiUnitReports = [];
  const intervalBasedReports = [];
  const ctdReports = [];
  const ftdReports = [];
  for (const item of ReportDefaults) {
    rangeLimitMap[item.tag] = item.rangeLimit;
    if (item.isIntervalBased) {
      intervalBasedReports.push(item.tag);
    }
    if (item.hasHours) {
      multiUnitReports.push(item.tag);
    }
    if (item.hasCTD) {
      ctdReports.push(item.tag);
    }
    if (item.hasFTD) {
      ftdReports.push(item.tag);
    }
  }
  return T1View.extend({
    timeWindow: "",
    canLoad: false,
    template: template,
    events: {
      "changed .time-rollup-selector": "saveTimeRollupHandler",
      "changed .timewindow-selector": "saveTimeWindowHandler",
      "changed .timewindow-unit-selector": "saveTimeWindowUnitHandler",
      "changed .type-selector": "switchDateBasedTypeHandler",
      "click .dates": "openDatePickerHandler",
      "keypress .rolling-date-input": "enforceInputRestrictionHandler",
      "keyup .rolling-date-input": "saveInputHandler",
      "paste .rolling-date-input": "enforcePasteRestrictionHandler",
      "saved .date-picker": "saveDatesHandler"
    },
    eventhubEvents: {
      "createEdit.reportInfoChange": function () {
        if (this.canLoad && !this.DataExportModel.get("editReport")) {
          this.reportChanged = true;
          this.initReportSpecifics();
        }
        this.canLoad = true;
        this.load();
      }
    },
    initialize(DataExportModel) {
      Object.assign(this, {
        DataExportModel: DataExportModel,
        dateFormat: DataExportModel.getDateFormat(),
        displayFormat: DataExportModel.getDisplayFormat()
      });
      this.initDateLimits();
      DataExportModel.setDefaultDate(default30DaysAgo.toString(this.dateFormat), yesterday.toString(this.dateFormat));
    },
    initReportSpecifics() {
      const DataExportModel = this.DataExportModel;
      this.initDateLimits();
      this.timeWindowUnit = "Days";
      DataExportModel.setTimeWindow("", false);
      DataExportModel.setTimeWindowUnit("");
      DataExportModel.setDateBasedType(null);
    },
    initDateLimits() {
      this.dateLimit = rangeLimitMap[this.DataExportModel.getReportItem().tag];
    },
    switchDateBasedTypeHandler(event) {
      this.dateBasedType = event.originalEvent.detail.value;
      this.DataExportModel.setDateBasedType(this.dateBasedType);
      T1Publish("change:formElements", true);
      T1Publish("dateRange.toggle", this.dateBasedType);
      this.DataExportModel.resetCalendarRange();
      this.initUIelements();
    },
    load() {
      this.render().then(() => {
        this.el.css("visibility", "visible");
        this.el.addClass("fade-in");
        Object.assign(this, {
          $errorBox: this.$(".error-box"),
          $errorHolder: this.$(".error-holder"),
          $infoBox: this.$(".information-box"),
          $infoHolder: this.$(".info-holder")
        });
        if (this.canLoad) {
          window.requestAnimationFrame(() => {
            this.displayModel = this.$("strand-model")[0];
            this.initUIelements({
              firstLoad: true
            });
          });
        } else {
          T1Publish("load:metaData");
        }
      });
    },
    unload() {
      Object.assign(this, {
        $errorBox: null,
        canLoad: false,
        compiledTemplate: null,
        DataExportModel: null,
        dateBased: null,
        dateBasedType: null,
        dateFormat: null,
        dateLimit: null,
        displayFormat: null,
        displayModel: null,
        endDate: null,
        errorTimer: null,
        input: null,
        reportChanged: null,
        startDate: null,
        startDateLimit: null,
        template: null,
        timeWindow: null,
        timeWindowUnit: null
      });
    },
    initUIelements({
      firstLoad: firstLoad
    } = {}) {
      const {
        displayModel: displayModel,
        DataExportModel: DataExportModel
      } = this;
      const reportInfo = DataExportModel.get("reportInfo");
      const {
        label: label,
        tag: tag
      } = DataExportModel.getReportItem();
      this.dateBasedType = DataExportModel.getDateBasedType();
      this.getDateRangeType();
      const defaults = {
        dateBased: this.dateBased,
        Fixed: {},
        Ongoing: {},
        reportName: label,
        Rolling: {},
        showCTDoption: ctdReports.includes(tag),
        showFTDoption: ftdReports.includes(tag),
        showUnitSelector: multiUnitReports.includes(tag)
      };
      displayModel.init(defaults);
      if (this.dateBased) {
        this.updateDisplayModel();
        window.requestAnimationFrame(() => {
          const timeRollupDDL = this.$(".time-rollup-selector")[0];
          const typeDDL = this.$(".type-selector")[0];
          if (firstLoad) {
            typeDDL.set("initialValue", true);
          }
          typeDDL.set("value", this.dateBasedType);
          if (timeRollupDDL) {
            let timeRollupOptions = [];
            if (reportInfo) {
              timeRollupOptions = reportInfo.timeRollupNames.map(function ({
                timeRollup: timeRollup
              }) {
                return {
                  value: timeRollup,
                  name: timeRollup
                };
              });
            }
            if (firstLoad) {
              timeRollupDDL.set("initialValue", true);
            }
            timeRollupDDL.set("data", timeRollupOptions);
            timeRollupDDL.set("value", reportInfo ? DataExportModel.getTimeRollup() : "");
          }
        });
      } else {
        let timeWindowOptions = [];
        const timeWindowDDL = this.$(".timewindow-selector")[0];
        if (reportInfo) {
          timeWindowOptions = reportInfo.timeWindowNames.map(function ({
            timeWindow: timeWindow
          }) {
            return {
              value: timeWindow,
              name: timeWindow
            };
          });
        }
        timeWindowDDL.set("initialValue", true);
        timeWindowDDL.set("data", timeWindowOptions);
        timeWindowDDL.set("value", this.timeWindow);
      }
      this.reassignInputFld();
    },
    updateDisplayModel() {
      const {
        displayFormat: displayFormat,
        startDate: startDate,
        endDate: endDate,
        displayModel: displayModel,
        DataExportModel: DataExportModel
      } = this;
      displayModel.setProp("isFixed", false);
      displayModel.setProp("isOngoing", false);
      displayModel.setProp("isRolling", false);
      displayModel.setProp("isCTDorFTD", false);
      switch (this.dateBasedType) {
        case "fixed":
          displayModel.setProp("isFixed", true);
          displayModel.setProp("Fixed.dates", {
            start: startDate && startDate.toString(displayFormat),
            end: endDate && endDate.toString(displayFormat)
          });
          break;
        case "ongoing":
          displayModel.setProp("isOngoing", true);
          displayModel.setProp("Ongoing.dates", {
            start: startDate && startDate.toString(displayFormat),
            end: today.toString(displayFormat)
          });
          break;
        case "rolling":
          displayModel.setProp("isRolling", true);
          displayModel.setProp("Rolling.dateInput", this.timeWindow.split(" ")[1] || DataExportModel.getReportItem().defaultTimeWindow.split("_")[1]);
          displayModel.setProp("Rolling.timeWindowUnit", DataExportModel.getTimeWindowUnit());
          break;
        case "ctd":
        case "ftd":
          displayModel.setProp("isCTDorFTD", true);
          break;
      }
    },
    reassignInputFld() {
      window.requestAnimationFrame(() => {
        this.input = this.$(".rolling-date-input")[0];
      });
    },
    openDatePickerHandler() {
      let datePicker;
      switch (this.dateBasedType) {
        case "fixed":
          datePicker = this.$(".fixed-date-picker")[0];
          datePicker.target = this.$(".fixed-date-selector .dates")[0];
          break;
        case "ongoing":
          datePicker = this.$(".ongoing-date-picker")[0];
          datePicker.target = this.$(".ongoing-date-selector .dates")[0];
          break;
      }
      datePicker.start = this.startDate;
      datePicker.end = this.endDate;
      datePicker.allowedStart = this.startDateLimit;
      datePicker.allowedEnd = today;
      datePicker.closeLabel = "Cancel";
      datePicker.open();
    },
    saveDatesHandler(event) {
      const eventData = event.originalEvent.detail;
      this.startDate = moment(eventData.start).toDate();
      this.endDate = this.dateBasedType === "ongoing" ? today : moment(eventData.end).toDate();
      this.saveCalendarRange();
      this.updateDate();
    },
    saveCalendarRange() {
      this.DataExportModel.setCalendarRange(this.startDate.toString(this.dateFormat), this.dateBasedType === "ongoing" ? null : this.endDate.toString(this.dateFormat));
    },
    updateDate() {
      const {
        displayFormat: displayFormat,
        displayModel: displayModel
      } = this;
      const dateBasedType = T1.Utils.toInitialCaps(this.dateBasedType);
      displayModel.setProp(`${dateBasedType}.dates.start`, this.startDate.toString(displayFormat));
      displayModel.setProp(`${dateBasedType}.dates.end`, this.endDate.toString(displayFormat));
      T1Publish("change:formElements", true);
    },
    getInputValue() {
      return this.input.get("value");
    },
    updateInputValue(limitValue) {
      const value = limitValue || this.getInputValue();
      if (limitValue) {
        this.input.set("value", limitValue);
      }
      this.DataExportModel.setTimeWindow(`last ${value} ${this.timeWindowUnit}`);
      T1Publish("change:formElements", true);
    },
    enforcePasteRestrictionHandler(event) {
      const pasteDelay = 100;
      setTimeout(() => {
        if (this.verifyIntegerInput(event, this.getInputValue())) {
          if (!this.verifyMaxDateInput(event)) {
            this.updateInputValue();
          }
        } else {
          this.input.set("value", "");
        }
      }, pasteDelay);
    },
    enforceInputRestrictionHandler(event) {
      this.verifyIntegerInput(event, String.fromCharCode(event.charCode));
    },
    saveInputHandler: _.debounce(function (event) {
      if (this.verifyMaxDateInput(event)) {
        this.DataExportModel.setTimeWindow(`last ${this.getInputValue()} ${this.timeWindowUnit}`);
        T1Publish("change:formElements", true);
      }
    }, debounceDelay),
    verifyIntegerInput(event, value) {
      const allowOnlyIntegers = /^\d+$/;
      if (!allowOnlyIntegers.test(value) && !allowedFFkeys.includes(event.key)) {
        this.displayMessage(event, "Only numbers allowed", "error");
        return false;
      }
      return true;
    },
    verifyMaxDateInput(event) {
      const {
        dateLimit: dateLimit,
        DataExportModel: DataExportModel
      } = this;
      const inputValue = Number(this.getInputValue());
      if (inputValue > dateLimit) {
        this.displayMessage(event, `Limited to ${dateLimit} ${DataExportModel.getTimeWindowUnit()}`, "error");
        this.updateInputValue(dateLimit);
        return false;
      } else if (inputValue < 1) {
        const msgDelay = 1e3;
        this.displayMessage(event, `Needs to be greater than 0 ${DataExportModel.getTimeWindowUnit()}`, "error");
        setTimeout(() => {
          if (Number(this.getInputValue()) < 1) {
            this.updateInputValue(1);
          }
        }, msgDelay);
        return false;
      }
      return true;
    },
    displayMessage(event, msg, type) {
      let $messageBox, $messageHolder, messageTimer;
      event.preventDefault();
      switch (type) {
        case "error":
          $messageBox = this.$errorBox;
          $messageHolder = this.$errorHolder;
          messageTimer = this.errorTimer;
          break;
        case "info":
          $messageBox = this.$infoBox;
          $messageHolder = this.$infoHolder;
          messageTimer = this.infoTimer;
      }
      $(this.input).attr("title", msg);
      if (Polymer && Polymer.dom) {
        Polymer.dom($messageBox[0]).innerHTML = msg;
      } else {
        $messageBox.html(msg);
      }
      $messageHolder.removeClass("no-display");
      this.hideMessage(type, messageTimer, $messageHolder);
    },
    hideMessage(type, timer, holder) {
      const timeToRead = 2e3;
      const newTimer = setTimeout(function () {
        holder.addClass("no-display");
      }, timeToRead);
      if (timer) {
        clearTimeout(timer);
      }
      switch (type) {
        case "error":
          this.errorTimer = newTimer;
          break;
        case "info":
          this.infoTimer = newTimer;
          break;
      }
    },
    saveTimeWindowHandler({
      originalEvent: originalEvent
    }) {
      this.DataExportModel.setTimeWindow(originalEvent.detail.value);
      T1Publish("change:formElements", true);
    },
    saveTimeWindowUnitHandler(event) {
      const value = $(event.currentTarget).attr("value");
      const DataExportModel = this.DataExportModel;
      const selectedReport = DataExportModel.getReportItem().tag;
      if (multiUnitReports.includes(selectedReport)) {
        const hoursPerDay = 24;
        const isHours = value === "Hours";
        const rangeLimit = rangeLimitMap[selectedReport];
        this.dateLimit = isHours ? rangeLimit * hoursPerDay : rangeLimit;
      }
      this.timeWindowUnit = value;
      DataExportModel.setTimeWindow(`last ${this.getInputValue()} ${value}`);
      DataExportModel.setTimeWindowUnit(value);
      T1Publish("change:formElements", true);
      this.verifyMaxDateInput(event);
    },
    saveTimeRollupHandler({
      currentTarget: currentTarget
    }) {
      this.DataExportModel.setTimeRollup($(currentTarget).attr("value"));
      T1Publish("change:formElements", true);
    },
    getDateRangeType() {
      let endDate, startDate;
      const {
        dateBasedType: dateBasedType,
        DataExportModel: DataExportModel
      } = this;
      const calendarRange = DataExportModel.get("calendarRange");
      const {
        label: label,
        tag: tag
      } = DataExportModel.getReportItem();
      const timeWindowUnit = DataExportModel.getTimeWindowUnit();
      const defaultDaysAgo = multiUnitReports.includes(tag) ? default7DaysAgo : default30DaysAgo;
      function showRollingDateOption() {
        this.timeWindow = DataExportModel.getTimeWindow();
        this.timeWindowUnit = timeWindowUnit;
      }
      function determineStartEndDate() {
        if (!this.reportChanged && calendarRange && calendarRange[label]) {
          startDate = Date.parse(calendarRange[label].startDate);
          endDate = Date.parse(calendarRange[label].endDate);
        }
      }
      function setOngoingDates() {
        this.startDate = startDate ? startDate : yesterday;
        this.endDate = endDate ? endDate : today;
      }
      function setStartDateLimit() {
        const duration = moment.duration(this.dateLimit, multiUnitReports.includes(tag) ? timeWindowUnit.toLowerCase() : "days");
        this.startDateLimit = moment().subtract(duration).toDate();
      }
      if (intervalBasedReports.includes(tag)) {
        this.dateBased = false;
        showRollingDateOption.call(this);
      } else {
        this.dateBased = true;
        switch (dateBasedType) {
          case "fixed":
            determineStartEndDate.call(this);
            setStartDateLimit.call(this);
            this.startDate = startDate ? startDate : defaultDaysAgo;
            this.endDate = endDate ? endDate : yesterday;
            this.saveCalendarRange();
            break;
          case "ongoing":
            determineStartEndDate.call(this);
            setOngoingDates.call(this);
            setStartDateLimit.call(this);
            this.saveCalendarRange();
            break;
          case "rolling":
            showRollingDateOption.call(this);
            break;
          default:
            showRollingDateOption.call(this);
        }
      }
      this.reportChanged = false;
    },
    render() {
      const deferred = $.Deferred();
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = template;
      } else {
        this.el.html(template);
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
