define('modules/siteList/views/additionalSitesForm',["jQuery", "T1", "T1Comm", "T1Layout", "T1View", "collections/strategyDomainRestrictions", "text!modules/siteList/templates/additionalSitesForm.html", "utils/AdminUtils", "utils/SiteUtils", "T1Form", "T1Notifier"], function ($, T1, T1Comm, T1Layout, T1View, DomainRestrictions, template, AdminUtils, SiteUtils) {
  "use strict";

  var AdditionalSitesFormView = T1View.extend({
    template: template,
    typeOptions: [{
      text: "Allow list",
      value: "include"
    }, {
      text: "Block list",
      value: "exclude"
    }],
    events: {
      "click .ui-cancel": "closeDialog",
      "click .add": "add"
    },
    initialize: function (args) {
      this.domainRestrictions = args.domainRestrictions;
      this.onCloseCallback = args.onCloseCallback || $.noop;
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      var $guide;
      const {
        closeDialog: closeDialog,
        removeTooltip: removeTooltip
      } = this;
      this.render().then(function () {
        $el.dialog({
          dialogClass: "w-AdditionalSitesDialog",
          closeText: "",
          autoOpen: true,
          modal: true,
          close: function () {
            closeDialog.call(self);
          }
        });
        T1.Selectbox($el.find('[data-bind="type"]'), {
          onChange: function (e) {
            removeTooltip.call(self, e);
          }
        });
        self.updateDataBind({
          description: self.domainRestrictions.data.sitelist
        }, $el);
        self.delegateEvents();
        if (self.layout) {
          self.layout.load();
        }
        $guide = $("#extended-domain");
        if (!window.localStorage.getItem("guide-extended-domain") && $guide.length) {
          $guide[0].show();
        }
      });
    },
    closeDialog: function (e) {
      var el = $(this.el);
      if (e) {
        e.preventDefault();
        el.dialog("close");
      }
      this.onCloseCallback();
      el.dialog("destroy");
      this.unload();
    },
    add: function () {
      var self = this;
      var evt = new $.Event("empty");
      var $el = $(self.el);
      var formValues = T1.Form.dataAsObject($el.find("form").not($('[data-bind="ignore"]', $el)));
      var content = formValues.description || "";
      var badContent = [];
      var br = " ";
      const minLineLength = 85;
      const maxDomainLength = 128;
      if (self.domainRestrictions) {
        if (content) {
          content = content.replace(/app:\s+/gi, "app:").replace(/(\r|\n|,)/g, " ").split(/\s+/);
          $.each(content, function (i, member) {
            var parts = [];
            if (member !== "") {
              if (!SiteUtils.isApp(member) && !SiteUtils.isIPAddress(member) && !SiteUtils.isExtendedDomain(member, true) && !SiteUtils.isIDNDomain(member, true)) {
                if (member.length > maxDomainLength) {
                  while (member) {
                    parts.push(member.slice(0, minLineLength));
                    member = member.slice(minLineLength);
                  }
                  member = parts.join("-<br />");
                  br = "<br />";
                }
                badContent.push(`&quot;${member}&quot;`);
              }
            } else {
              content[i] = SiteUtils.normalizeExtendedDomain(member);
            }
          });
          if (badContent.length > 0) {
            self.showErrors(self.domainRestrictions, [{
              field: "description",
              message: `${badContent.join(`,${br}`) + br + AdminUtils.pluralize("is", badContent.length, AdminUtils.spcOpts.BOTH)}not ${badContent.length === 1 ? "a " : ""}valid additional${AdminUtils.pluralize("entry", badContent.length, AdminUtils.spcOpts.LEFT)}.`
            }], undefined, true);
            return;
          }
          formValues.description = content.join(",");
        }
        if (formValues.type === "" && formValues.description) {
          self.showErrors(self.domainRestrictions, [{
            field: "type",
            message: "List Type is a required field."
          }]);
          return;
        }
        self.domainRestrictions.setData(formValues.type, formValues.description);
        self.domainRestrictions.trigger("reset");
      }
      self.closeDialog(evt);
    },
    serialize: function () {
      var returnObj = {
        typeOptions: this.typeOptions
      };
      if (this.domainRestrictions.data.isIncluded !== undefined) {
        returnObj.type = this.domainRestrictions.data.isIncluded ? "include" : "exclude";
      }
      return returnObj;
    }
  });
  return T1.Form.ViewUtils.extend(AdditionalSitesFormView);
});
