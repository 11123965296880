define('modules/audiences/segments/behavior/views/behavior',["jQuery", "Underscore", "moment", "Backbone", "Hogan", "T1", "T1Comm", "text!modules/audiences/segments/behavior/templates/behavior.html", "text!modules/audiences/segments/behavior/templates/eventRow.html", "models/eventAttributes", "../utils/dateAttributeUtils", "jQPlugins/chosen.jquery", "jQPlugins/jquery.tagsinput", "jQueryUI"], function ($, _, moment, Backbone, Hogan, T1, T1Comm, template, eventRow, EventAttribute, DateUtils) {
  "use strict";

  var getDate = function (daysAgo) {
    return moment().subtract(daysAgo, "days")._d;
  };
  var BehaviorView = Backbone.View.extend({
    template: template,
    initialize: function (options) {
      T1Comm.detectSession();
      this.customLabel = "Select Custom Date Range";
      this.applyFunction = options.applyFunction;
      this.model = options.behaviorObj;
      this.segment = options.segment;
      this.segmentType = options.segmentType;
      this.name = options.name;
      this.attributeType = this.model.get("type") === "pixel" ? "Event" : this.toPrettyCase(this.model.get("type"));
      this.advertiserId = this.model.get("advertiserId");
      this.campaignId = this.model.get("campaignId");
      this.targetId = this.model.get("targetId");
      this.attributeLoading = 0;
      this.recency = this.model.get("recency") ? this.model.get("recency") : null;
      this.render();
      this.attachMasks(this.el);
    },
    events: {
      "click #back-to-relative-button": "backToRelativeRecency",
      "click #add-event-filter": "addEventFilterRow",
      "selected .select-time-range .input-time-unit": "customDateRangeSelected",
      "selected #frequency-options": "frequencySelected",
      "selected #recency-options": "recencySelected",
      "click #cancel-button": "closeDialog",
      "click #apply-button": "applyClick",
      "click #select-date-range-button": "openDualDatePicker",
      "click #select-time-button": "openSingleDatePicker",
      "saved #single-date-picker": "updateSingleDate",
      "saved #dual-date-picker": "updateDualDate"
    },
    render: function () {
      var optionSelections = this.getOptionSelections();
      var self = this;
      var $el;
      if (optionSelections.frequencyOption === "") {
        this.frequencySet = true;
      } else {
        this.frequencySet = false;
      }
      if (optionSelections.recencyOption === "") {
        this.recencySet = true;
      } else {
        this.recencySet = false;
      }
      if (optionSelections.recencyOption === "" && optionSelections.frequencyOption === "") {
        this.alreadyFilledIn = true;
      } else {
        this.alreadyFilledIn = false;
      }
      $el = $(this.el);
      $el.html(Hogan.compile(this.template).render({
        name: this.name,
        attributeType: this.getAttributeTypeLabel(),
        recencyOptions: ["Within", "Not Within", "Between", "Not Between", "Before", "After"],
        frequencyOptions: ["Between", "Not Between"],
        unitOptions: ["Minutes", "Hours", "Days", "Weeks", "Months"],
        frequencyOption: optionSelections.frequencyOption || "Between",
        recencyOption: optionSelections.recencyOption || "Within",
        timeRangeOption1: optionSelections.timeRangeOption1,
        timeRangeOption2: optionSelections.timeRangeOption2,
        timeOption: optionSelections.timeOption,
        isAdaptive: this.segmentType === "adaptive"
      }));
      this.hideTimeIntervals();
      $(".frequency-selector .frequency-inputs", $(this.el)).hide();
      $(".w-footer #error-container", $(this.el)).hide();
      $el.dialog({
        modal: true,
        width: 1100,
        autoOpen: false,
        draggable: false,
        dialogClass: "behavior-dialog"
      });
      $el.addClass("behavior-dialog");
      $el.dialog("open");
      $(".main-content").css({
        overflow: "hidden"
      });
      if (this.recencySet && this.frequencySet && this.model.get("filter_by") !== undefined) {
        this.fillInGUI();
      }
      $(".ui-dialog-titlebar", $el.parent()).hide();
    },
    getAttributeTypeLabel: function () {
      if (this.model.get("type") === "allImpressions") {
        return "Impression";
      } else {
        return this.model.get("type") === "pixel" ? "Event" : this.toPrettyCase(this.model.get("type"));
      }
    },
    finishedSelecting: function () {
      if (this.recencySet && this.frequencySet && !this.alreadyFilledIn) {
        this.alreadyFilledIn = true;
        this.fillInGUI();
      }
    },
    frequencySelected: function (e) {
      var selectedOption = e.originalEvent.detail.label || e.originalEvent.detail.value;
      if ($(".frequency-inputs", $(this.el)).not(":visible")) {
        $(".frequency-inputs", $(this.el)).show();
      }
      if (selectedOption === "Between") {
        $("#frequency-number-1", $(this.el)).val("1");
        $("#frequency-number-2", $(this.el)).val("*");
      } else if (selectedOption === "Not Between") {
        $("#frequency-number-1", $(this.el)).val("");
        $("#frequency-number-2", $(this.el)).val("");
      }
      this.frequencySet = true;
      this.finishedSelecting();
    },
    recencySelected: function (e) {
      var selectedOption = e.originalEvent.detail.label || e.originalEvent.detail.value;
      var newInterval;
      if (selectedOption === "Within" || selectedOption === "Not Within") {
        this.hideTimeIntervals();
        newInterval = $(".time-interval .select-time", $(this.el));
        if (this.alreadyFilledIn) {
          $("#select-time-number", newInterval).val("");
          this.resetDropdown("#select-time-unit", newInterval, "Select Unit");
          if (selectedOption === "Within") {
            $("#select-time-number", newInterval).val("30");
            $("#select-time-unit", newInterval).val("Days");
          }
        }
        newInterval.show();
      } else if (selectedOption === "Between" || selectedOption === "Not Between") {
        this.hideTimeIntervals();
        newInterval = $(".time-interval .select-time-range", $(this.el));
        if (this.alreadyFilledIn) {
          $("#select-time-range-number-1", newInterval).val("");
          $("#select-time-range-number-2", newInterval).val("");
          this.resetDropdown("#select-time-range-unit-1", newInterval, "Select Unit");
          this.resetDropdown("#select-time-range-unit-2", newInterval, "Select Unit");
        }
        newInterval.show();
      } else if (selectedOption === "Before" || selectedOption === "After") {
        this.hideTimeIntervals();
        $(".select-date #select-time-button", $(this.el)).text("Select Date");
        $(".select-date #select-time-hidden-datepicker", $(this.el)).datepicker("setDate");
        $(".select-date", $(this.el)).show();
      }
      this.recencySet = true;
      this.finishedSelecting();
    },
    customDateRangeSelected: function (e) {
      var selectedOption = e.originalEvent.detail.label || e.originalEvent.detail.value;
      if (selectedOption === "Custom Date Range") {
        this.hideTimeIntervals();
        $(".select-date-range", $(this.el)).show();
        $(".select-date-range #select-date-range-button", $(this.el)).text(this.customLabel);
      }
    },
    toPrettyCase: function (str) {
      var strArray = str.split(" ");
      for (var i = 0; i < strArray.length; i++) {
        strArray[i] = strArray[i].charAt(0).toUpperCase() + strArray[i].substr(1);
      }
      return strArray.join(" ");
    },
    toPrettyCaseFirst: function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    },
    resetDropdown: function (selector, container, placeholder) {
      $(selector, container).val("");
      $(selector, container)[0].placeholder = placeholder;
    },
    hideTimeIntervals: function () {
      $(".time-interval-option", $(this.el)).hide();
    },
    errorMessage: function (message) {
      var footer = $("#behavior-footer", this.el)[0];
      footer.type = "error";
      footer.message = message;
      footer.showMessage();
    },
    resetErrorBorders: function () {
      $("strand-input", $(this.el)).removeAttr("error");
      $("strand-dropdown", $(this.el)).removeAttr("error");
      $(".select-date-button", $(this.el)).css("border-color", "").css("color", "");
      $(".chzn-container .chzn-choices", $(this.el)).css("border-color", "");
      $(".chzn-container-active .chzn-choices", $(this.el)).css("border-color", "");
    },
    getIdFromAttribute: function (attributeName) {
      return this.attributeIdMapping.indexOf(attributeName);
    },
    getAttributeFromId: function (id) {
      return this.attributeIdMapping[id];
    },
    backToRelativeRecency: function () {
      this.hideTimeIntervals();
      var newInterval = $(".time-interval .select-time-range", $(this.el));
      $("#select-time-range-number-1", newInterval).val("");
      $("#select-time-range-number-2", newInterval).val("");
      this.resetDropdown("#select-time-range-unit-1", newInterval, "Select Unit");
      this.resetDropdown("#select-time-range-unit-2", newInterval, "Select Unit");
      newInterval.show();
    },
    parseDateforDatePicker: function (date) {
      return $.datepicker.formatDate("M d, yy", $.datepicker.parseDate("mm/dd/yy", date));
    },
    parseDateforSDL: function (date) {
      return $.datepicker.formatDate("yy-mm-dd", $.datepicker.parseDate("M d, yy", date));
    },
    openSingleDatePicker: function (e) {
      var $el = $(this.el);
      var datePicker;
      var target = e.target;
      var today = new Date();
      datePicker = this.el.querySelector("#single-date-picker");
      datePicker.target = target;
      datePicker.startLabel = "";
      datePicker.resetOnClose = false;
      datePicker.dual = false;
      datePicker.useDuration = false;
      datePicker.useTime = false;
      datePicker.allowedStart = getDate(45);
      if (target.textContent !== "Select Date") {
        datePicker.start = target.textContent;
      } else {
        datePicker.start = today;
      }
      datePicker.open();
    },
    openDualDatePicker: function (e) {
      var $el = $(this.el);
      var datePicker;
      var target = e.target;
      var datesArr;
      var today = new Date();
      datePicker = $el.find("#dual-date-picker")[0];
      datePicker.target = target;
      datePicker.allowedStart = getDate(30);
      if (e.target.textContent !== this.customLabel) {
        datesArr = e.target.textContent.split(" to ");
        datePicker.start = datesArr[0];
        datePicker.end = datesArr[1];
      }
      datePicker.useDuration = false;
      datePicker.useTime = false;
      datePicker.open();
    },
    updateSingleDate: function (e) {
      var datePicker = this.el.querySelector("#" + e.currentTarget.id);
      var newDate = this.parseDateforDatePicker(datePicker.startDate);
      $("#select-time-button").text(newDate);
      datePicker.close();
    },
    updateDualDate: function (e) {
      var datePicker = this.el.querySelector("#" + e.currentTarget.id);
      var newStartDate = this.parseDateforDatePicker(datePicker.startDate);
      var newEndDate = this.parseDateforDatePicker(datePicker.endDate);
      $("#select-date-range-button").text(newStartDate + " to " + newEndDate);
      datePicker.close();
    },
    addEventFilterRow: function (selections) {
      var attributeSource = function (name) {
        if (name === "Event") {
          return "site";
        } else if (name === "click") {
          return "click";
        } else {
          return "impression";
        }
      };
      var self = this;
      var source = attributeSource(this.attributeType);
      var eventAttributes = new EventAttribute({
        source: source,
        advertiserId: this.advertiserId,
        targetId: this.targetId
      });
      var rowNumber = 0;
      var attributes = [];
      var initialLoad = true;
      var isStandardSegment = this.segmentType === "standard";
      var targetOp, targetVal, attributeName, operator, prevValue, prevArray, altValue, altArray, alreadySet;
      $(".event-filter-row").each(function (index, el) {
        rowNumber = parseInt(el.id.split("-").pop(), 10) + 1;
      });
      var rowId = "row-" + rowNumber;
      var newEventRow = $(Hogan.compile(eventRow).render({
        id: rowId,
        includeExcludeOperatorOptions: ["Includes", "Excludes"],
        dateOperatorOptions: ["Within last", "Not within last", "Within next", "Not within next", "Before", "After", "Between", "Not between"],
        dateAttributeUnits: ["Hours", "Days", "Weeks", "Months"],
        stringOperatorOptions: ["Equal to", "Not equal to", "Starts with", "Does not start with", "Ends with", "Does not end with", "Contains", "Does not contain"],
        numericOperatorOptions: [{
          name: "Greater than",
          value: ">"
        }, {
          name: "Greater than or equal to",
          value: ">="
        }, {
          name: "Less than",
          value: "<"
        }, {
          name: "Less than or equal to",
          value: "<="
        }, {
          name: "Equal to",
          value: "="
        }, {
          name: "Not equal to",
          value: "!="
        }, {
          name: "Between",
          value: "between"
        }, {
          name: "Not between",
          value: "not between"
        }],
        attributeName: attributeName,
        attributeType: this.getAttributeTypeLabel(),
        operator: operator,
        allowedStart: moment().subtract("years", 1).toDate()
      }));
      var closeLoader = function () {
        $(newEventRow).css({
          visibility: "visible"
        });
        $(".attribute-options", newEventRow).resize();
        self.attributeLoading--;
        if (self.attributeLoading === 0) {
          $("#event-filter-loader").hide();
        }
      };
      var hideAllBut = function (dropDown, toShow) {
        if (dropDown === "operator") {
          $(".operator-options-include-exclude", newEventRow).hide();
          $(".operator-options-string", newEventRow).hide();
          $(".operator-options-numeric", newEventRow).hide();
          $(".operator-options-date", newEventRow).hide();
          if (toShow) {
            targetOp = ".operator-options-" + toShow;
            $(targetOp, newEventRow).show().resize();
          }
        }
        if (dropDown === "value") {
          $(".value-multiselect-container", newEventRow).hide();
          $(".value-textarea-container", newEventRow).hide();
          $(".value-datepicker-container", newEventRow).hide();
          $(".value-date-between-container", newEventRow).hide();
          $(".value-daterange-container", newEventRow).hide();
          $(".value-numeric-container", newEventRow).hide();
          $(".value-ziparea-container", newEventRow).hide();
          if (toShow) {
            targetVal = ".value-" + toShow + "-container";
            $(targetVal, newEventRow).show();
          }
        }
      };
      var globalAttributes = function (newEventRow, jsonData) {
        var testObj = {};
        var x;
        var i;
        var adaptiveBlockList = ["1", "9"];
        var adaptiveAllowList = ["5", "12", "7", "6", "14", "20", "13", "19", "8"];
        var standardBlockList = ["1", "9", "5", "6", "7", "8", "12"];
        var standardAllowList = ["14", "20", "13", "19"];
        var blockList = isStandardSegment ? standardBlockList : adaptiveBlockList;
        var allowList = isStandardSegment ? standardAllowList : adaptiveAllowList;
        for (x = 0; x < jsonData.length; x++) {
          testObj = {};
          try {
            for (i = 0; i < jsonData[x].field.length; i++) {
              testObj[jsonData[x].field[i].name] = jsonData[x].field[i].value;
            }
          } catch (e) {
            testObj = jsonData[x];
            for (i = 0; i < testObj.prop.length; i++) {
              testObj[testObj.prop[i].name] = testObj.prop[i].value;
            }
          }
          if (testObj.source === source && testObj.owner_type === "global_root" && blockList.indexOf(testObj.id) < 0) {
            if (testObj.data_type === "int_id" && allowList.indexOf(testObj.id) >= 0) {
              attributes[testObj.id] = testObj;
            } else if (testObj.data_type !== "int_id") {
              attributes[testObj.id] = testObj;
            }
          }
        }
      };
      var customAttributes = function (newEventRow, jsonData) {
        var testObj = {};
        var customAttributesByEntityType = {
          pixel_bundle: {},
          advertiser: {}
        };
        var x;
        var i;
        var isCustomAttribute = function (attr) {
          var retval = false;
          if (attr.source === source && attr.owner_type !== "global_root" && attr.data_type !== "global_root" && attr.data_type !== "int_id" && checkSegmentType(attr)) {
            retval = true;
          }
          return retval;
        };
        for (x = 0; x < jsonData.length; x++) {
          testObj = {};
          try {
            for (i = 0; i < jsonData[x].field.length; i++) {
              testObj[jsonData[x].field[i].name] = jsonData[x].field[i].value;
            }
          } catch (e) {
            testObj = jsonData[x];
            for (i = 0; i < testObj.prop.length; i++) {
              testObj[testObj.prop[i].name] = testObj.prop[i].value;
            }
          }
          if (isCustomAttribute(testObj)) {
            if (testObj.owner_type in customAttributesByEntityType) {
              customAttributesByEntityType[testObj.owner_type][testObj.id] = testObj;
            } else {
              attributes[testObj.id] = testObj;
            }
          }
        }
        if (_.size(customAttributesByEntityType.pixel_bundle) > 0) {
          _.extend(attributes, customAttributesByEntityType.pixel_bundle);
          if (attributeName in customAttributesByEntityType.advertiser) {
            attributes[attributeName] = customAttributesByEntityType.advertiser[attributeName];
          }
        } else if (_.size(customAttributesByEntityType.advertiser) > 0) {
          _.extend(attributes, customAttributesByEntityType.advertiser);
        }
        function checkSegmentType(attr) {
          if (isStandardSegment) {
            if (attr.data_type === "numeric" || attr.data_type === "text") {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      };
      var bindToDropdown = function (newEventRow, jsonData) {
        globalAttributes(newEventRow, jsonData);
        customAttributes(newEventRow, jsonData);
        var dropdownValues = attributes.filter(function (n) {
          return n != undefined;
        }).map(function (item) {
          item.value = item.id;
          return item;
        });
        document.querySelector("#" + rowId + " > .attribute-selector > strand-dropdown").data = dropdownValues;
        $(".attribute-options", newEventRow).autocomplete();
        $(".attribute-options", newEventRow).val(attributeName);
        attributeName !== "" ? $(".attribute-options", newEventRow).attr("data-placeholder", attributes[attributeName].name) : null;
        if (selections.values === undefined) {
          closeLoader();
        }
      };
      var updateDropDowns = function (e) {
        var selectedOption = e.originalEvent.detail.label || e.originalEvent.detail.value || attributeName;
        var defaultAttr = ["5", "3", "4", "12", "11", "7", "6", "14", "20", "13", "19", "17", "18", "8"];
        var attrId = selectedOption;
        var dataType = attributes[attrId].data_type;
        var keyValue = attributes[attrId].id;
        if ((dataType !== "int_id" || keyValue === "8") && selections.values !== undefined) {
          closeLoader();
        }
        if (selectedOption === undefined) {
          return;
        }
        if (dataType === "int_id" && defaultAttr.indexOf(keyValue) > -1) {
          if (!$(".operator-options-include-exclude", newEventRow).is(":visible")) {
            hideAllBut("operator", "include-exclude");
          }
          if (operator && initialLoad) {
            operator = operator === "=" ? "Includes" : operator === "!=" ? "Excludes" : operator;
            $(".operator-options-include-exclude", newEventRow).val(operator);
            $(".operator-options-include-exclude", newEventRow)[0].placeholder = operator;
          } else {
            self.resetDropdown(".operator-options-include-exclude", newEventRow, "Select Operator");
          }
          if (keyValue !== "8") {
            if (!$(".value-multiselect-container", newEventRow).is(":visible")) {
              hideAllBut("value", "multiselect");
            }
            $(".value-multiselect", newEventRow).empty();
            $(".chzn-container", newEventRow).hide();
            $(".attr-value-loader", newEventRow).show();
            eventAttributes.getAttributeOptions(keyValue, {
              fetchOnce: false,
              success: function (tempData) {
                var idType = keyValue === "14" || keyValue === "20" ? "bidder_exchange_identifier" : "id";
                var idValue;
                var status;
                var searchProp = function (data, term) {
                  return $.grep(data, function (e) {
                    return e.name == term;
                  })[0].value;
                };
                if (!tempData) {
                  $(".value-multiselect", newEventRow).append("<option value=''>No Results to Display</option>");
                  $(".value-multiselect", newEventRow).val(initialLoad ? prevArray : "");
                  initialLoad = false;
                  $(".value-multiselect", newEventRow).attr("data-placeholder", "Select " + selectedOption);
                  $(".value-multiselect", newEventRow).trigger("chosen:updated");
                }
                if (selections.values !== undefined) {
                  closeLoader();
                }
                if (!tempData.length && tempData.name) {
                  $(".value-multiselect", newEventRow).append("<option value='" + tempData[idType] + "'>" + tempData.name + "</option>");
                }
                for (var x = 0; x < tempData.length; x++) {
                  try {
                    idValue = searchProp(tempData[x].prop, "value");
                  } catch (e) {
                    idValue = tempData[x].id;
                  }
                  if (idType === "bidder_exchange_identifier") {
                    status = searchProp(tempData[x].prop, "status");
                    if (status === "1") {
                      try {
                        idValue = searchProp(tempData[x].prop, "bidder_exchange_identifier");
                        $(".value-multiselect", newEventRow).append("<option value='" + idValue + "'>" + tempData[x].name + "</option>");
                      } catch (e) {}
                    }
                  } else if (keyValue === "5") {
                    var arrow = "&raquo";
                    var dmaName = tempData[x].name.replace(/\/|\|/g, arrow);
                    idValue = searchProp(tempData[x].prop, "value");
                    $(".value-multiselect", newEventRow).append("<option value='" + idValue + "'>" + dmaName + "</option>");
                  } else if (keyValue === "19" || keyValue === "13") {
                    idValue = tempData[x].id;
                    $(".value-multiselect", newEventRow).append("<option value='" + idValue + "'>" + tempData[x].name + " (" + idValue + ")" + "</option>");
                  } else {
                    idValue = searchProp(tempData[x].prop, "value");
                    $(".value-multiselect", newEventRow).append("<option value='" + idValue + "'>" + tempData[x].name + "</option>");
                  }
                }
                $(".value-multiselect", newEventRow).val(initialLoad ? prevArray : "");
                initialLoad = false;
                $(".value-multiselect", newEventRow).attr("data-placeholder", "Select " + selectedOption);
                $(".chzn-container", newEventRow).show();
                $(".attr-value-loader", newEventRow).hide();
                $(".value-multiselect", newEventRow).trigger("liszt:updated");
              }
            });
          } else {
            if (!$(".value-ziparea-container", newEventRow).is(":visible")) {
              hideAllBut("value", "ziparea");
            }
            $(".value-ziparea", newEventRow).tagsInput({
              removeWithBackspace: false,
              validation: {
                type: "url",
                url: T1.ENV.API_BASE + "target_values/?dimension=USZC&q=name%3d%3a",
                saveButton: "#apply-button",
                function: function (value, options, skipTag, tagslist, id, validations, hiddenValues, valid, invalid, saveButton, self, finishRender, delimiter) {
                  var disableSave = function (truthy) {
                    saveButton = options.validation.saveButton;
                    $(saveButton).prop("disabled", truthy);
                  };
                  var usValue = /^[0-9]{5}$/.test(value) ? "us" + value : value;
                  usValue = usValue.replace(/(\s)/g, "");
                  if (/^[a-zA-Z0-9]{5,7}$/.test(usValue) && !$(self).tagExist(value)) {
                    validations.push(value);
                    disableSave(true);
                    finishRender(false, tagslist, id, value, "validating");
                    tagslist.pop();
                    $.ajax({
                      url: options.validation.url + usValue,
                      success: function (response) {
                        var tags = $(self).val().split(delimiter[id]);
                        var index = tags.indexOf(value);
                        tags.splice(index, 1);
                        response = $.xml2json(response);
                        validations.pop();
                        if (validations.length === 0 && invalid.length === 0) {
                          disableSave(false);
                        }
                        $("#" + id).removeTag(escape(value));
                        try {
                          hiddenValues.push(response.entities.entity.id);
                          valid.push(value);
                          finishRender(false, tags, id, value);
                        } catch (e) {
                          disableSave(true);
                          value = value + "*";
                          invalid.push(value);
                          finishRender(false, tags, id, value, "not_valid");
                        }
                      }
                    });
                  } else {
                    $("#" + id + "_tag").addClass("not_valid");
                    finishRender(true, tagslist, id, value);
                  }
                }
              },
              valid: initialLoad ? altArray : [],
              hiddenValues: initialLoad ? prevArray : []
            });
            $(".value-ziparea", newEventRow).importTags(initialLoad ? altValue : "", initialLoad);
            $(".value-zip", newEventRow).val(initialLoad ? prevValue : "");
            initialLoad = false;
            $(".value-ziparea", newEventRow).attr("data-placeholder", "Add Values");
          }
        }
        if (dataType === "numeric") {
          if (!$(".operator-options-numeric", newEventRow).is(":visible")) {
            hideAllBut("value");
            hideAllBut("operator", "numeric");
          }
          if (operator && initialLoad) {
            $(".operator-options-numeric", newEventRow).val(operator.toLowerCase());
            $(".operator-options-numeric", newEventRow)[0].placeholder = operator;
          } else {
            self.resetDropdown(".operator-options-numeric", newEventRow, "Select Operator");
            hideAllBut("value");
          }
          $(".operator-options-numeric", newEventRow).on("selected", function (e) {
            var firstValue = Array.isArray(prevArray) ? prevArray[0] : prevValue;
            var selectedOperator = e.originalEvent.detail.label || e.originalEvent.detail.value || operator.toLowerCase();
            if (!$(".value-numeric-container", newEventRow).is(":visible") && selectedOperator !== "") {
              hideAllBut("value", "numeric");
            }
            $(".value-numeric", newEventRow).val(initialLoad ? firstValue : "");
            if (selectedOperator === "between" || selectedOperator === "not between") {
              if (!$(".value-numeric-between", newEventRow).is(":visible")) {
                $(".value-numeric-between", newEventRow).show();
              }
              $(".value-numeric-between", newEventRow).val(initialLoad ? prevArray[1] || "" : "");
            } else {
              $(".value-numeric-between", newEventRow).hide();
            }
            initialLoad = false;
          });
        }
        if (dataType === "text" || dataType === "inet") {
          if (!$(".operator-options-string", newEventRow).is(":visible")) {
            hideAllBut("operator", "string");
          }
          if (operator && initialLoad) {
            operator = operator.charAt(0).toUpperCase() + operator.slice(1);
            $(".operator-options-string", newEventRow).val(operator);
            $(".operator-options-string", newEventRow)[0].placeholder = operator;
          } else {
            self.resetDropdown(".operator-options-string", newEventRow, "Select Operator");
          }
          if (!$(".value-textarea-container", newEventRow).is(":visible")) {
            hideAllBut("value", "textarea");
          }
          $(".value-textarea", newEventRow).importTags(initialLoad ? prevValue : "");
          $(".value-textarea", newEventRow).attr("data-placeholder", "Select Values");
          initialLoad = false;
        }
        if (dataType === "datetime") {
          if (!$(".operator-options-date", newEventRow).is(":visible")) {
            hideAllBut("operator", "date");
          }
          if (operator && initialLoad) {
            $(".operator-options-date", newEventRow).val(operator);
            $(".operator-options-date", newEventRow)[0].placeholder = operator;
          } else {
            self.resetDropdown(".operator-options-date", newEventRow, "Select Operator");
          }
          hideAllBut("value");
          $(".operator-options-date", newEventRow).on("selected", function (e) {
            var selectedOperator = e.originalEvent.detail.label || e.originalEvent.detail.value || operator.toLowerCase();
            switch (selectedOperator) {
              case "Before":
              case "After":
                DateUtils.selectDateRowType("datepicker", newEventRow);
                DateUtils.populateAbsolute(self, newEventRow, prevValue, initialLoad);
                break;
              case "Between":
              case "Not between":
                DateUtils.selectDateRowType("date-between", newEventRow);
                if (initialLoad && prevValue) {
                  if (prevValue.type === "relative") {
                    DateUtils.populateBetweenRelative(newEventRow, rowId, prevValue.dates);
                  } else if (prevValue.type === "custom") {
                    DateUtils.populateBetweenCustom(self, newEventRow, prevValue);
                  }
                } else {
                  DateUtils.resetBetweenContainer(newEventRow);
                }
                break;
              default:
                DateUtils.selectDateRowType("daterange", newEventRow);
                DateUtils.populateRelative(newEventRow, prevValue, initialLoad);
                break;
            }
            initialLoad = false;
          });
        }
        if (!newEventRow.data("alreadySet")) {
          newEventRow.data("alreadySet", true);
        }
      };
      $(newEventRow).css({
        visibility: "hidden"
      });
      self.attributeLoading++;
      $("#event-filter-loader").show();
      $(".behavior-dialog").dialog("option", "position", {
        my: "center",
        at: "center",
        of: window
      });
      if (selections.attributeName === undefined) {
        attributeName = "";
        operator = "";
        prevValue = "";
        prevArray = [];
        altValue = "";
        altArray = [];
        alreadySet = true;
      } else {
        attributeName = selections.attributeName;
        operator = selections.operator;
        prevValue = Array.isArray(selections.values) ? selections.values.join(", ") : selections.values;
        prevArray = selections.values;
        altValue = Array.isArray(selections.altValues) ? selections.altValues.join(", ") : selections.altValues;
        altArray = selections.altValues;
        alreadySet = false;
      }
      eventAttributes.modelData = [];
      eventAttributes.fetch({
        success: function (response) {
          self.segment.addAttributesToSegment(response);
          bindToDropdown(newEventRow, response);
        }
      });
      newEventRow.data("alreadySet", alreadySet);
      $("#event-filter-rows", $(this.el)).append(newEventRow);
      var numToDecPattern = /^\d+(?:\.\d{1,4})?$/;
      var valueNumeric = document.getElementsByClassName("value-numeric");
      var valueNumericBetween = document.getElementsByClassName("value-numeric-between");
      var i;
      for (i = 0; i < valueNumeric.length; i++) {
        valueNumeric[i].floater = numToDecPattern;
      }
      for (i = 0; i < valueNumericBetween.length; i++) {
        valueNumericBetween[i].floater = numToDecPattern;
      }
      hideAllBut("operator");
      $(".value-multiselect", newEventRow).chosen({
        width: "95%",
        placeholder_text_multiple: "Select Values",
        search_contains: true
      });
      $(".value-textarea", newEventRow).tagsInput({});
      hideAllBut("value");
      $(".value-numeric-between", newEventRow).hide();
      $(".attribute-options", newEventRow).on("selected", function (e) {
        updateDropDowns(e);
      });
      $(".remove-event-row", newEventRow).on("click", function () {
        newEventRow.remove();
      });
      $(".input-time-unit", newEventRow).on("selected", function (e) {
        if (e.currentTarget.value === "Custom Date Range") {
          newEventRow.find(".between-relative-container").hide();
          newEventRow.find(".between-datepicker-container").show();
        }
      });
      $("#" + rowId + "-back-to-relative-button", newEventRow).on("click", function () {
        newEventRow.find(".between-relative-container").show();
        newEventRow.find(".between-datepicker-container").hide();
        newEventRow.find(".input-time-unit").each(function (index, ddl) {
          ddl.reset();
        });
      });
      $("#" + rowId + "-single-dp", newEventRow).on("saved", function () {
        var dp = newEventRow[0].querySelector("#" + rowId + "-single-dp");
        var newStartDate = self.parseDateforDatePicker(dp.startDate);
        newEventRow.find("#" + rowId + "-single-dp-button").text(newStartDate);
      });
      $("#" + rowId + "-dual-dp", newEventRow).on("saved", function () {
        var dp = newEventRow[0].querySelector("#" + rowId + "-dual-dp");
        var newStartDate = self.parseDateforDatePicker(dp.startDate);
        var newEndDate = self.parseDateforDatePicker(dp.endDate);
        newEventRow.find("#" + rowId + "-dual-dp-button").text(newStartDate + " to " + newEndDate);
      });
      this.attachMasks(newEventRow);
      return newEventRow;
    },
    applyClick: function () {
      if (this.validate()) {
        this.changeModel();
        this.applyFunction.call(this.segment, this.model);
        this.closeDialog();
      }
    },
    validateStandardBehavior: function () {
      return this.validateAttributes();
    },
    validateAdaptiveBehavior: function () {
      return this.validateFrequency() && this.validateRecency() && this.validateAttributes();
    },
    validate: function () {
      var validationFunction = this.segmentType === "adaptive" ? this.validateAdaptiveBehavior : this.validateStandardBehavior;
      if (validationFunction.call(this)) {
        this.resetErrorBorders();
        $("#behavior-footer", this.el)[0].hideMessage();
        return true;
      }
      return false;
    },
    validateFrequency: function () {
      var lowerbound = $("#frequency-number-1", $(this.el));
      var upperbound = $("#frequency-number-2", $(this.el));
      if (lowerbound.val() === "" && upperbound.val() === "" && $("#frequency-options", $(this.el)).val() === "") {
        return true;
      } else if (lowerbound.val() === "*" && (isNaN(lowerbound.val()) || parseFloat(lowerbound.val()) % 1 !== 0 || parseFloat(lowerbound.val()) < 0)) {
        this.errorMessage("Input field should contain a whole number greater than or equal to 1");
        this.resetErrorBorders();
        $("#frequency-number-1", $(this.el)).attr("error", "");
        return false;
      } else if (upperbound.val() !== "*" && (isNaN(upperbound.val()) || parseFloat(upperbound.val()) % 1 !== 0 || parseFloat(upperbound.val()) < 0)) {
        this.errorMessage("Input fields should contain a whole number or *");
        this.resetErrorBorders();
        $("#frequency-number-2", $(this.el)).attr("error", "");
        return false;
      } else if (parseInt(lowerbound.val()) > parseInt(upperbound.val())) {
        this.errorMessage("Lower number should go on the left");
        this.resetErrorBorders();
        $("#frequency-number-1", $(this.el)).attr("error", "");
        $("#frequency-number-2", $(this.el)).attr("error", "");
        return false;
      }
      return true;
    },
    validateRecency: function () {
      if ($(".select-date-range", $(this.el)).is(":visible")) {
        if ($("#select-date-range-button", $(this.el)).text() === this.customLabel) {
          this.errorMessage("Please select dates");
          this.resetErrorBorders();
          $("#select-date-range-button", $(this.el)).css("border-color", "red").css("color", "red");
          return false;
        }
        return true;
      } else if ($(".select-date", $(this.el)).is(":visible")) {
        if ($("#select-time-button", $(this.el)).text() === "Select Date") {
          this.errorMessage("Please select a date");
          this.resetErrorBorders();
          $("#select-time-button", $(this.el)).css("border-color", "red").css("color", "red");
          return false;
        }
        return true;
      } else if ($(".select-time-range", $(this.el)).is(":visible")) {
        var lowerbound = $("#select-time-range-number-1", $(this.el));
        var upperbound = $("#select-time-range-number-2", $(this.el));
        var lowerboundUnit = $("#select-time-range-unit-1", $(this.el));
        var upperboundUnit = $("#select-time-range-unit-2", $(this.el));
        var lowerboundScale;
        if (lowerboundUnit.val() === "Minutes") {
          lowerboundScale = 60;
        } else if (lowerboundUnit.val() === "Hours") {
          lowerboundScale = 60 * 60;
        } else if (lowerboundUnit.val() === "Days") {
          lowerboundScale = 60 * 60 * 24;
        } else if (lowerboundUnit.val() === "Weeks") {
          lowerboundScale = 60 * 60 * 24 * 7;
        } else if (lowerboundUnit.val() === "Months") {
          lowerboundScale = 60 * 60 * 24 * 7 * 30;
        } else {
          this.errorMessage("Please select a unit of time");
          this.resetErrorBorders();
          $("#select-time-range-unit-1", $(this.el)).attr("error", "");
          return false;
        }
        var upperboundScale;
        if (upperboundUnit.val() === "Minutes") {
          upperboundScale = 60;
        } else if (upperboundUnit.val() === "Hours") {
          upperboundScale = 60 * 60;
        } else if (upperboundUnit.val() === "Days") {
          upperboundScale = 60 * 60 * 24;
        } else if (upperboundUnit.val() === "Weeks") {
          upperboundScale = 60 * 60 * 24 * 7;
        } else if (upperboundUnit.val() === "Months") {
          upperboundScale = 60 * 60 * 24 * 7 * 30;
        } else {
          this.errorMessage("Please select a unit of time");
          this.resetErrorBorders();
          $("#select-time-range-unit-2", $(this.el)).attr("error", "");
          return false;
        }
        if (isNaN(lowerbound.val()) || parseFloat(lowerbound.val()) % 1 !== 0 || parseFloat(lowerbound.val()) < 0) {
          this.errorMessage("Input fields should contain a whole number");
          this.resetErrorBorders();
          $("#select-time-range-number-1", $(this.el)).attr("error", "");
          return false;
        } else if (isNaN(upperbound.val()) || parseFloat(upperbound.val()) % 1 !== 0 || parseFloat(upperbound.val()) < 0) {
          this.errorMessage("Input fields should contain a whole number");
          this.resetErrorBorders();
          $("#select-time-range-number-2", $(this.el)).attr("error", "");
          return false;
        } else if (!this.validateRecencyToLastXDays(lowerboundUnit.val(), lowerbound.val())) {
          this.resetErrorBorders();
          $("##select-time-range-number-1", $(this.el)).attr("error", "");
          return false;
        } else if (!this.validateRecencyToLastXDays(upperboundUnit.val(), upperbound.val())) {
          this.resetErrorBorders();
          $("#select-time-range-number-2", $(this.el)).attr("error", "");
          return false;
        } else if (parseInt(lowerbound.val()) * lowerboundScale > parseInt(upperbound.val()) * upperboundScale) {
          this.errorMessage("Lower number should go on the left");
          this.resetErrorBorders();
          $("#select-time-range-number-1", $(this.el)).attr("error", "");
          $("#select-time-range-number-2", $(this.el)).attr("error", "");
          $("#select-time-range-unit-1", $(this.el)).attr("error", "");
          $("#select-time-range-unit-2", $(this.el)).attr("error", "");
          return false;
        } else if (parseInt(lowerbound.val()) * lowerboundScale === parseInt(upperbound.val()) * upperboundScale) {
          this.errorMessage("Lower and Upper bounds should not be equivalent");
          this.resetErrorBorders();
          $("#select-time-range-number-1", $(this.el)).attr("error", "");
          $("#select-time-range-number-2", $(this.el)).attr("error", "");
          $("#select-time-range-unit-1", $(this.el)).attr("error", "");
          $("#select-time-range-unit-2", $(this.el)).attr("error", "");
          return false;
        }
        return true;
      } else if ($(".select-time", $(this.el)).is(":visible")) {
        var number = $("#select-time-number", $(this.el));
        var unit = $("#select-time-unit", $(this.el));
        var recencyOption = $("#recency-options", $(this.el))[0];
        if (isNaN(number.val()) || parseFloat(number.val()) % 1 !== 0 || parseFloat(number.val()) < 0) {
          this.errorMessage("Input fields should contain a whole number");
          this.resetErrorBorders();
          $("#select-time-number", $(this.el)).attr("error", "");
          return false;
        } else if (parseInt(number.val()) === 0 && recencyOption.value === "Within") {
          this.errorMessage("Cannot select Within 0 units");
          this.resetErrorBorders();
          $("#select-time-number", $(this.el)).attr("error", "");
          return false;
        } else if (unit.val() === "") {
          this.errorMessage("Please select a unit of time");
          this.resetErrorBorders();
          $("#select-time-unit", $(this.el)).attr("error", "");
          return false;
        }
        if (!this.validateRecencyToLastXDays(unit.val(), number.val())) {
          $("#select-time-number", $(this.el)).attr("error", "");
          return false;
        }
        return true;
      }
      return true;
    },
    validateRecencyToLastXDays: function (unit, value) {
      var recencyUpperBound;
      if (unit === "Minutes") {
        recencyUpperBound = 64800;
      } else if (unit === "Hours") {
        recencyUpperBound = 1080;
      } else if (unit === "Days") {
        recencyUpperBound = 45;
      } else if (unit === "Weeks") {
        recencyUpperBound = 7;
      } else if (unit === "Months") {
        recencyUpperBound = 2;
      }
      if (parseInt(value) > recencyUpperBound) {
        this.errorMessage("Maximum recency window is " + recencyUpperBound + " " + unit + ".");
        this.resetErrorBorders();
        return false;
      }
      return true;
    },
    validateAttributes: function () {
      var self = this;
      var validated = true;
      $("#event-filter-rows .event-filter-row", $(this.el)).each(function () {
        if ($(".attribute-options", this).val() === "") {
          self.errorMessage("Please select an event attribute or delete the row");
          self.resetErrorBorders();
          $(".attribute-options", this).attr("error", "");
          validated = false;
          return;
        }
        if ($(".operator-options-string", this).is(":visible")) {
          if ($(".operator-options-string", this).val() === "") {
            self.errorMessage("Please select an operator");
            self.resetErrorBorders();
            $(".operator-options-string", this).attr("error", "");
            validated = false;
            return;
          }
        } else if ($(".operator-options-include-exclude", this).is(":visible")) {
          if ($(".operator-options-include-exclude", this).val() === "") {
            self.errorMessage("Please select an operator");
            self.resetErrorBorders();
            $(".operator-options-include-exclude", this).attr("error", "");
            validated = false;
            return;
          }
        } else if ($(".operator-options-date", this).is(":visible")) {
          if ($(".operator-options-date", this).val() === "") {
            self.errorMessage("Please select an operator");
            self.resetErrorBorders();
            $(".operator-options-date", this).attr("error", "");
            validated = false;
            return;
          }
        } else {
          if ($(".operator-options-numeric", this).val() === "") {
            self.errorMessage("Please select an operator");
            self.resetErrorBorders();
            $(".operator-options-numeric", this).attr("error", "");
            validated = false;
            return;
          }
        }
        if ($(".value-multiselect-container", this).is(":visible")) {
          if ($(".value-multiselect", this).val() === null) {
            self.errorMessage("Please select one or more values");
            self.resetErrorBorders();
            $(".chzn-container .chzn-choices", this).css("border-color", "red");
            $(".chzn-container-active .chzn-choices", this).css("border-color", "red");
            validated = false;
            return;
          }
        } else if ($(".value-textarea-container", this).is(":visible")) {
          if ($(".value-textarea", this).val() === "") {
            self.errorMessage("Please enter one or more values separated by commas");
            self.resetErrorBorders();
            $(".value-textarea", this).attr("error", "");
            validated = false;
            return;
          }
        } else if ($(".value-ziparea-container", this).is(":visible")) {
          if ($(".value-ziparea", this).val() === "") {
            self.errorMessage("Please enter one or more values separated by commas");
            self.resetErrorBorders();
            $(".value-ziparea", this).attr("error", "");
            validated = false;
            return;
          }
        } else if ($(".value-datepicker-container", this).is(":visible")) {
          validateCustomDatePicker("single", this);
        } else if ($(".between-datepicker-container", this).is(":visible")) {
          validateCustomDatePicker("dual", this);
        } else if ($(".between-relative-container", this).is(":visible")) {
          validateCustomRange(this);
        } else if ($(".value-numeric-container", this).is(":visible")) {
          var numericExpression = /^[0-9]*(?:\.[0-9]{1,4})?$/;
          var $numericEl = $(".value-numeric", this);
          var $numericBetweenEl = $(".value-numeric-between", this);
          var valueNumeric = $numericEl.val();
          var valueNumericBetween = $numericBetweenEl.last().val();
          var decimalMessage = "Please enter numeric value with up to 4 decimal places";
          var blankMessage = "Please enter a number";
          var sameValueMessage = "Please enter two different values";
          var validateNumericRow = function (message, elements) {
            self.errorMessage(message);
            self.resetErrorBorders();
            $.each(elements, function (i, el) {
              $(el).attr("error", "");
            });
            validated = false;
            return;
          };
          if (!numericExpression.test(valueNumeric)) {
            validateNumericRow(decimalMessage, $numericEl);
          } else if (valueNumeric.trim() === "") {
            validateNumericRow(blankMessage, $numericEl);
          } else if ($numericBetweenEl.is(":visible")) {
            if (!numericExpression.test(valueNumericBetween)) {
              validateNumericRow(decimalMessage, $numericBetweenEl);
            } else if (valueNumericBetween.trim() === "") {
              validateNumericRow(blankMessage, $numericBetweenEl);
            } else if (valueNumericBetween === valueNumeric) {
              validateNumericRow(sameValueMessage, [$numericBetweenEl, $numericEl]);
            }
          }
        } else if ($(".value-daterange-container", this).is(":visible")) {
          if ($(".value-daterange-number", this).val() === "") {
            self.errorMessage("Please enter a numeric value");
            self.resetErrorBorders();
            $(".value-daterange-number", this).attr("error", "");
            validated = false;
            return;
          } else if ($(".value-daterange-interval", this).val() === "") {
            self.errorMessage("Please pick an interval");
            self.resetErrorBorders();
            $(".value-daterange-interval", this).attr("error", "");
            validated = false;
            return;
          } else if (!validUnit(this)) {
            self.resetErrorBorders();
            self.errorMessage(DateUtils.maximumNumberMessage(this.querySelector(".value-daterange-interval").value));
            $(".value-daterange-number", this).attr("error", "");
            validated = false;
            return;
          }
        }
      });
      function validUnit(row) {
        var value = row.querySelector(".value-daterange-number").value;
        var units = row.querySelector(".value-daterange-interval").value;
        return DateUtils.validUnit(value, units);
      }
      function validateCustomDatePicker(type, row) {
        if (!$("#" + row.id + "-" + type + "-dp", row)[0].startDate) {
          self.errorMessage("Please pick a date");
          self.resetErrorBorders();
          $(".value-datepicker-button." + type, row).css("border-color", "red").css("color", "red");
          validated = false;
          return;
        }
      }
      function validateCustomRange(row) {
        var input1 = row.querySelector("#" + row.id + "-select-time-range-number-1");
        var input2 = row.querySelector("#" + row.id + "-select-time-range-number-2");
        var unit1 = row.querySelector("#" + row.id + "-select-time-range-unit-1");
        var unit2 = row.querySelector("#" + row.id + "-select-time-range-unit-2");
        var validatePresence = function (arr, message) {
          arr.forEach(function (input) {
            if (!input.value) {
              self.errorMessage(message);
              self.resetErrorBorders();
              input.error = true;
              validated = false;
              return;
            }
          });
        };
        var validateUnit = function (input, unitDDL) {
          var value = input.value;
          var units = unitDDL.value;
          if (!DateUtils.validUnit(value, units)) {
            self.resetErrorBorders();
            input.error = true;
            self.errorMessage(DateUtils.maximumNumberMessage(units));
            validated = false;
            return;
          }
        };
        validatePresence([input1, input2], "Please enter a value");
        validatePresence([unit1, unit2], "Please select a value");
        validateUnit(input1, unit1);
        validateUnit(input2, unit2);
      }
      return validated;
    },
    changeModel: function () {
      var frequencyOperator = $("#frequency-options", $(this.el)).val();
      var frequencyOp, lowerboundValue, upperboundValue, recencyOperator, recencyObj, currentOp, frequencyObj, datesArr;
      if (this.segmentType === "adaptive") {
        if (frequencyOperator !== "") {
          frequencyOp = frequencyOperator.toLowerCase();
          lowerboundValue = $("#frequency-number-1", $(this.el)).val();
          upperboundValue = $("#frequency-number-2", $(this.el)).val();
          if (upperboundValue === "*") {
            upperboundValue = "255";
          }
          frequencyObj = {
            op: frequencyOp,
            min: lowerboundValue,
            max: upperboundValue
          };
          this.model.set({
            frequency: frequencyObj
          });
        }
        recencyOperator = $("#recency-options", $(this.el)).val();
        if (recencyOperator !== "") {
          if (this.model.get("recency") !== undefined) {
            currentOp = this.model.get("recency").op;
            if (currentOp === "within" || currentOp === "not within") {
              delete this.model.get("recency").count;
              delete this.model.get("recency").unit;
            } else if (currentOp === "before" || currentOp === "after") {
              delete this.model.get("recency").date;
            } else if (currentOp === "between" || currentOp === "not between") {
              delete this.model.get("recency").start_count;
              delete this.model.get("recency").end_count;
              delete this.model.get("recency").start_unit;
              delete this.model.get("recency").end_unit;
              delete this.model.get("recency").start_date;
              delete this.model.get("recency").end_date;
            }
          }
          recencyObj = {};
          recencyObj.op = recencyOperator.toLowerCase();
          if (recencyOperator === "Within" || recencyOperator === "Not Within") {
            recencyObj.count = parseInt($("#select-time-number", $(this.el)).val());
            recencyObj.unit = $("#select-time-unit", $(this.el)).val().toLowerCase();
          } else if (recencyOperator === "Before" || recencyOperator === "After") {
            recencyObj.date = $.datepicker.formatDate("yy-mm-dd", $.datepicker.parseDate("M d, yy", $("#select-time-button", $(this.el)).text()));
          } else if (recencyOperator === "Between" || recencyOperator === "Not Between") {
            if ($(".select-time-range", $(this.el)).is(":visible")) {
              recencyObj.start_count = parseInt($("#select-time-range-number-1", $(this.el)).val());
              recencyObj.start_unit = $("#select-time-range-unit-1", $(this.el)).val().toLowerCase();
              recencyObj.end_count = parseInt($("#select-time-range-number-2", $(this.el)).val());
              recencyObj.end_unit = $("#select-time-range-unit-2", $(this.el)).val().toLowerCase();
            } else {
              datesArr = $("#select-date-range-button", $(this.el)).text().split(" to ");
              recencyObj.start_date = this.parseDateforSDL(datesArr[0]);
              recencyObj.end_date = this.parseDateforSDL(datesArr[1]);
            }
          }
          this.model.set({
            recency: recencyObj
          });
        }
      }
      if ($("#event-filter-rows .event-filter-row", $(this.el)).length > 0) {
        var attrs = [];
        var self = this;
        $("#event-filter-rows .event-filter-row", $(this.el)).each(function () {
          var attributeId = $(".attribute-options", this).val();
          var op;
          var values;
          var altValues;
          var isDatetime;
          var attrObject;
          if ($(".operator-options-string", this).is(":visible")) {
            op = $(".operator-options-string", this).val().toLowerCase();
          } else if ($(".operator-options-include-exclude", this).is(":visible")) {
            op = $(".operator-options-include-exclude", this).val().toLowerCase();
          } else if ($(".operator-options-date", this).is(":visible")) {
            op = $(".operator-options-date", this).val().toLowerCase();
            isDatetime = true;
          } else if ($(".operator-options-numeric", this).is(":visible")) {
            op = $(".operator-options-numeric", this).val().toLowerCase();
          }
          if ($(".value-multiselect-container", this).is(":visible")) {
            values = $(".value-multiselect", this).val();
          } else if ($(".value-textarea-container", this).is(":visible")) {
            values = $(".value-textarea", this).val();
            values = values.replace(/^\s+|\s+$/g, "");
            values = values.replace(/\s*,\s*/g, ",");
          } else if ($(".value-ziparea-container", this).is(":visible")) {
            values = $(".value-zip", this).val();
            altValues = $(".value-ziparea", this).val();
          } else if ($(".value-datepicker-container", this).is(":visible")) {
            values = $("#" + this.id + "-single-dp", this)[0].startDate;
          } else if ($(".value-numeric-container", this).is(":visible")) {
            if ($(".value-numeric-between", this).is(":visible")) {
              values = [$(".value-numeric", this).val(), $(".value-numeric-between", this).last().val()];
            } else {
              values = $(".value-numeric", this).val();
            }
          } else if ($(".value-daterange-container", this).is(":visible")) {
            values = {
              count: $(".value-daterange-number", this).val(),
              unit: $(".value-daterange-interval", this).val()
            };
          } else if ($(".between-relative-container", this).is(":visible")) {
            values = {
              type: "relative",
              dates: [{
                count: DateUtils.getValue(this, "select-time-range-number-1"),
                unit: DateUtils.getValue(this, "select-time-range-unit-1"),
                schedule: DateUtils.getValue(this, "select-schedule-number-1")
              }, {
                count: DateUtils.getValue(this, "select-time-range-number-2"),
                unit: DateUtils.getValue(this, "select-time-range-unit-2"),
                schedule: DateUtils.getValue(this, "select-schedule-number-2")
              }]
            };
          } else if ($(".between-datepicker-container", this).is(":visible")) {
            values = {
              type: "custom",
              start: $("#" + this.id + "-dual-dp", this)[0].startDate,
              end: $("#" + this.id + "-dual-dp", this)[0].endDate
            };
          }
          attrObject = {
            id: attributeId,
            op: op,
            values: values,
            altValues: altValues || ""
          };
          if (isDatetime) {
            attrObject.datetime = true;
          }
          attrs.push(attrObject);
        });
        this.model.set({
          filter_by: attrs
        });
      } else {
        this.model.unset("filter_by");
      }
    },
    fillInGUI: function () {
      var recencyOption;
      var startDate;
      var endDate;
      var selections;
      var filters;
      var newEventRow;
      if (this.segmentType === "adaptive") {
        if (this.model.get("frequency") !== undefined) {
          $("#frequency-number-1", $(this.el)).val(this.model.get("frequency").min);
          if (this.model.get("frequency").max === null || this.model.get("frequency".max === undefined)) {
            $("#frequency-number-2", $(this.el)).val("*");
          } else {
            $("#frequency-number-2", $(this.el)).val(this.model.get("frequency").max);
          }
        }
        if (this.recency !== undefined) {
          recencyOption = this.toPrettyCase(this.recency.op);
          if (recencyOption === "Within" || recencyOption === "Not Within") {
            $("#select-time-number", $(this.el)).val(this.recency.count);
          } else if (recencyOption === "Between" || recencyOption === "Not Between") {
            if ("start_date" in this.recency) {
              startDate = $.datepicker.formatDate("M d, yy", $.datepicker.parseDate("yy-mm-dd", this.recency.start_date));
              endDate = $.datepicker.formatDate("M d, yy", $.datepicker.parseDate("yy-mm-dd", this.recency.end_date));
              $(".time-interval .select-time-range", $(this.el)).hide();
              $(".time-interval .select-date-range", $(this.el)).show();
              $("#select-date-range-button", $(this.el)).text(startDate + " to " + endDate);
            } else {
              $("#select-time-range-number-1", $(this.el)).val(this.recency.start_count);
              $("#select-time-range-number-2", $(this.el)).val(this.recency.end_count);
            }
          } else if (recencyOption === "Before" || recencyOption === "After") {
            $("#select-time-button", $(this.el)).text($.datepicker.formatDate("M d, yy", $.datepicker.parseDate("yy-mm-dd", this.model.get("recency").date)));
            $("#select-time-hidden-datepicker", $(this.el)).datepicker("setDate", $.datepicker.formatDate("mm/dd/yy", $.datepicker.parseDate("yy-mm-dd", this.model.get("recency").date)));
          }
        }
      }
      if (this.model.get("filter_by") !== undefined) {
        filters = this.model.get("filter_by");
        for (var i = 0; i < filters.length; i++) {
          selections = {};
          selections.attributeName = filters[i].id;
          selections.operator = this.toPrettyCaseFirst(filters[i].op);
          selections.values = filters[i].values;
          try {
            selections.altValues = filters[i].altValues;
          } catch (e) {
            selections.altValues = [];
          }
          newEventRow = this.addEventFilterRow(selections);
        }
      }
    },
    getOptionSelections: function () {
      var optionSelections = {};
      var recencyOption;
      if (this.model.get("frequency") !== undefined) {
        optionSelections.frequencyOption = this.toPrettyCase(this.model.get("frequency").op);
      } else {
        optionSelections.frequencyOption = "";
      }
      optionSelections.timeRangeOption1 = "";
      optionSelections.timeRangeOption2 = "";
      optionSelections.timeOption = "";
      if (this.model.get("recency") !== undefined) {
        recencyOption = this.toPrettyCase(this.model.get("recency").op);
        optionSelections.recencyOption = recencyOption;
        if (recencyOption === "Within" || recencyOption === "Not Within") {
          optionSelections.timeOption = this.toPrettyCase(this.model.get("recency").unit);
        } else if (recencyOption === "Between" || recencyOption === "Not Between") {
          if (!("start_date" in this.model.get("recency"))) {
            optionSelections.timeRangeOption1 = this.toPrettyCase(this.model.get("recency").start_unit);
            optionSelections.timeRangeOption2 = this.toPrettyCase(this.model.get("recency").end_unit);
          }
        }
      } else {
        optionSelections.recencyOption = "";
      }
      return optionSelections;
    },
    closeDialog: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.remove();
      this.unbind();
      $(this.el).dialog("destroy");
      $(".main-content").css({
        overflow: "inherit"
      });
    },
    attachMasks: function (el) {
      var rgxFreq = new RegExp(/^[0-9]*$|^[0-9]*\*$|^\*$|^\*[0-9]$/);
      var rgxRec = new RegExp(/^[0-9]*$/);
      var bypassKey = function (e) {
        var result = true;
        var code = e.charCode || e.keyCode;
        switch (code) {
          case 224:
          case 40:
          case 39:
          case 38:
          case 37:
          case 36:
          case 35:
          case 17:
          case 9:
          case 8:
            break;
          default:
            result = false;
            break;
        }
        if (e.shiftKey) {
          result = false;
        }
        if (e.ctrlKey || e.metaKey) {
          result = true;
        }
        return result;
      };
      var inputs = $(".frequency-number", el);
      inputs.each(function (i, input) {
        input = $(input);
        T1.Form.Masks.attach(input);
        input.unbind("keypress");
        input.keypress(function (e) {
          var keyUnicode = e.charCode || e.keyCode;
          var chr;
          var newVal;
          var field = $(e.target);
          chr = String.fromCharCode(keyUnicode);
          newVal = field.val() + chr;
          if (!bypassKey(e)) {
            if (!rgxFreq.test(newVal)) {
              return false;
            }
          }
        });
      });
      inputs = $(".input-time-number, .value-daterange-number", el);
      inputs.each(function (i, input) {
        input = $(input);
        T1.Form.Masks.attach(input);
        input.unbind("keypress");
        input.keypress(function (e) {
          var keyUnicode = e.charCode || e.keyCode;
          var chr;
          var newVal;
          var field = $(e.target);
          chr = String.fromCharCode(keyUnicode);
          newVal = field.val() + chr;
          if (!bypassKey(e)) {
            if (!rgxRec.test(newVal)) {
              return false;
            }
          }
        });
      });
    }
  });
  return BehaviorView;
});
