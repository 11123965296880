define('modules/campaign/views/list',['require','jQuery','T1','T1Layout','T1Permissions','T1View','T1Spinner','models/userPreferences','modules/campaign/controller/actions','text!modules/campaign/templates/listLayout.html','text!modules/campaign/templates/activateCampaignModal.html','text!modules/campaign/templates/ribbonCommandStrip.html','text!modules/campaign/templates/list.html','jQueryUI','T1Accordion','T1Form','T1Loader','T1Preferences','T1Selectbox','T1Signature','T1Tooltip'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const Spinner = require("T1Spinner");
  const UserPreferences = require("models/userPreferences");
  const Actions = require("modules/campaign/controller/actions");
  const campaignsListLayout = require("text!modules/campaign/templates/listLayout.html");
  const activateCampaignModal = require("text!modules/campaign/templates/activateCampaignModal.html");
  const ribbonTemplate = require("text!modules/campaign/templates/ribbonCommandStrip.html");
  const template = require("text!modules/campaign/templates/list.html");
  const jQueryUI = require("jQueryUI");
  const T1Accordion = require("T1Accordion");
  const T1Form = require("T1Form");
  const T1Loader = require("T1Loader");
  const T1Preferences = require("T1Preferences");
  const T1Selectbox = require("T1Selectbox");
  const T1Signature = require("T1Signature");
  const T1Tooltip = require("T1Tooltip");
  var dropdownList = {
    report_interval: {
      options: [{
        value: "1",
        text: "Previous Day"
      }, {
        value: "7",
        text: "Previous 7 Days"
      }, {
        value: "14",
        text: "Previous 14 Days"
      }, {
        value: "30",
        text: "Previous 30 Days"
      }, {
        value: "FTD",
        text: "Flight to Date"
      }, {
        value: "CTD",
        text: "Campaign to Date",
        state: 'selected="selected"'
      }]
    },
    strategy_goal: {
      options: [{
        value: "strategyGoal",
        text: "Strategy Goal"
      }, {
        value: "campaignGoal",
        text: "Campaign Goal"
      }]
    },
    listViewTypes: {
      options: [{
        value: "spendMode",
        text: "Spend"
      }, {
        value: "performanceMode",
        text: "Performance"
      }]
    },
    list_sizes: {
      options: [{
        value: 10,
        text: "10"
      }, {
        value: 20,
        text: "20"
      }]
    },
    extended_list_sizes: {
      options: [{
        value: 10,
        text: "10"
      }, {
        value: 20,
        text: "20"
      }, {
        value: 50,
        text: "50"
      }, {
        value: 100,
        text: "100"
      }]
    }
  };
  var ensureInitialIntervalIsValid = function (targetInterval) {
    var optionList = dropdownList.report_interval.options;
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].value === targetInterval) {
        return targetInterval;
      }
    }
    return "CTD";
  };
  var getCampaignState = function () {
    var firstRun = true;
    return function getCampaignState(self) {
      var hasCampaigns;
      if (firstRun === true && (self.currentList !== "favorites" || self.collection.hasFavorites())) {
        firstRun = false;
        hasCampaigns = true;
      } else {
        hasCampaigns = self.collection.count > 0;
      }
      return hasCampaigns;
    };
  }();
  const CampaignListView = T1View.extend({
    partials: {
      activateCampaignModal: activateCampaignModal
    },
    template: template,
    itemViewMode: "performanceMode",
    entitySortValue: "updated_on",
    entitySortOrder: "descending",
    entityInterval: "CTD",
    selectedStrategyGoal: "",
    activationErrTitle: "Campaign Activation Error",
    initialize({
      currentList: currentList,
      stateMaintainer: stateMaintainer
    }) {
      var self = this;
      const {
        collection: collection,
        el: $el
      } = this;
      var signature = T1.Signature.create();
      const impPacingViewTypes = {
        options: [{
          value: "spendMode",
          text: "Spend"
        }, {
          value: "impressionMode",
          text: "Impressions"
        }, {
          value: "performanceMode",
          text: "Performance"
        }]
      };
      this.viewCache = {};
      this.statusFilter = collection.statusFilter;
      this.currentList = currentList;
      this.stateMaintainer = stateMaintainer;
      this.currentViewMode = "";
      dropdownList.listViewTypes = impPacingViewTypes;
      if (this.stateMaintainer) {
        this.stateMaintainer.setCampaignListView(this);
      }
      signature.set(new Map([["root", "ui"], ["module", "campaign"], ["view", "list"]]));
      T1.EventHub.publish("updateNavPath");
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["itemViewMode", "selectedStrategyGoal", "entitySortValue", "entitySortOrder", "entityInterval"]);
      this.itemViewMode = this.preferences.get("itemViewMode") || "spendMode";
      this.selectedStrategyGoal = this.preferences.get("selectedStrategyGoal") || "strategyGoal";
      this.entitySortValue = this.preferences.get("entitySortValue") || "updated_on";
      this.entitySortOrder = this.preferences.get("entitySortOrder") || "descending";
      this.entityInterval = this.preferences.get("entityInterval") || "CTD";
      this.entityInterval = ensureInitialIntervalIsValid(this.entityInterval);
      if (this.entitySortValue === "budget") {
        this.entitySortValue = "total_budget";
      }
      collection.report_interval = this.entityInterval;
      collection.strategy_goal_performance = this.selectedStrategyGoal;
      this.currentViewMode = this.itemViewMode + this.entityInterval;
      this.layout = new T1Layout({
        el: () => $el,
        selectorPath: ".campaign-list",
        template: campaignsListLayout,
        wrapperEl: '<li class="list-item campaign"></li>',
        layout: {},
        serialize: function () {
          return {
            hasCampaigns: getCampaignState(self),
            noCampaignMessage: self.getNoCampaignsMessage()
          };
        }
      });
      this.actions = new Actions(this, collection);
      $el.on("click", ".campaign-list .checkbox", {
        el: $el
      }, this.updateCheckAll);
      this.alowExtendedPageLimit = T1Permissions.check("feature", "extended_page_limit");
    },
    dataEvents: {
      collection: {
        reset: function () {
          this.load(true);
          this.addDataEvent({
            collection: {
              add: "append"
            }
          });
        },
        "change:reportInterval": "changeReportInterval",
        "change:itemViewMode": "changeViewMode"
      }
    },
    eventhubEvents: {
      "campaign:updateCheckAll": "updateCheckAll",
      "campaigns:filter, campaigns:clearFilter": function () {
        if (this.currentList === "favorites") {
          this.currentList = "all";
          this.updateListOrFavoriteModeRibbonCmd("all");
        }
      },
      "campaigns:beginRefresh": function () {
        this.showHideLoaderForCampaignListReload(true);
      },
      "campaign:activationError": function (errMsg) {
        this.activationErrText = errMsg;
        this.renderPartialTemplate({
          template: activateCampaignModal,
          useTemplateHtml: false,
          action: "html",
          targetEl: ".activate-campaign-modal",
          data: {
            activationErrTitle: this.activationErrTitle,
            activationErrText: this.activationErrText
          }
        });
        this.$(".activate-campaign-modal")[0].show();
      }
    },
    getNoCampaignsMessage() {
      let midMsg;
      const isFavoritesView = this.currentList === "favorites";
      const hasFavorites = this.collection.hasFavorites();
      if (isFavoritesView && !hasFavorites) {
        return "No campaigns are marked as favorites.";
      }
      const {
        statusFilter: statusFilter,
        isExpiredHidden: isExpiredHidden
      } = this.collection;
      const begMsg = "Search results showing";
      const endMsg = "only. Please change your filters to show additional results.";
      switch (statusFilter) {
        case "all":
          if (isExpiredHidden) {
            return `${begMsg} current ${endMsg}`;
          }
        case "active":
        case "inactive":
          midMsg = `${statusFilter + (isExpiredHidden ? " and current" : "")}`;
          return `${begMsg} ${midMsg} ${endMsg}`;
        default:
          return "No campaigns available.";
      }
    },
    changeReportInterval() {
      this.$(".campaign-list").removeClass("change-on-interval-trigger").addClass("change-on-interval-trigger");
      this.switchViewMode(this.itemViewMode);
    },
    changeViewMode(viewMode) {
      const viewList = this.layout && this.layout.layout ? this.layout.layout[".list"] : null;
      this.$(".campaign-list").removeClass("change-on-interval-trigger");
      this.switchViewMode(viewMode);
      this.strategyGoalState(viewMode);
      this.collection.itemViewMode = viewMode;
      if (viewList && $.isArray(viewList) && viewList.length) {
        viewList[0].view.closeInlineEditor();
      }
    },
    switchViewMode(viewMode = this.itemViewMode) {
      const reportInterval = this.collection.report_interval;
      const updatedViewMode = viewMode + reportInterval;
      T1.EventHub.publish("list_viewMode", {
        mode: viewMode,
        interval: reportInterval,
        goal: this.selectedStrategyGoal,
        time: Date.now()
      });
      if (updatedViewMode !== this.currentViewMode) {
        this.$(".campaign-list").removeClass(this.currentViewMode).addClass(updatedViewMode);
        this.currentViewMode = updatedViewMode;
      }
    },
    strategyGoalState(viewMode = this.itemViewMode) {
      var selectCompareAgainst = this.getSelectInstanceByRibbonCmdKey("cmd-compare-against");
      if (selectCompareAgainst) {
        const isDisabled = viewMode === "performanceMode";
        selectCompareAgainst.enableDisable(isDisabled);
      }
    },
    unload() {
      if (this.commandRibbonLayout) {
        this.commandRibbonLayout.unload();
      }
      if (this.accordion) {
        this.accordion.unbind();
      }
      this.layout.empty();
      this.el.empty();
    },
    load(isReset) {
      function onRender() {
        this.loadCommandRibbonLayout(this.collection.length);
      }
      function onLayoutLoad() {
        const {
          collection: collection,
          itemViewMode: viewMode
        } = this;
        var eventHub = T1.EventHub;
        if (!collection.length) {
          eventHub.publish("chart.add", {
            model: null,
            fromListView: true
          });
          if (isReset) {
            this.showHideLoaderForCampaignListReload(false);
          }
        } else {
          eventHub.publish("campaigns:listQtyKnown", {
            count: collection.count,
            pageLimit: collection.pageLimit
          });
          const firstCampaign = collection.at(0);
          collection.chartableCampaignId = firstCampaign.id;
          eventHub.publish("chart.add", {
            model: firstCampaign,
            fromListView: true
          });
          this.layout.append(".list", {
            module: "campaign",
            viewType: "item"
          }, collection, function (campaign) {
            return {
              model: campaign,
              sortConfiguration: collection.fetchOptions.sort_by,
              viewMode: viewMode
            };
          });
          this.accordion = T1.Accordion(".list.campaigns");
          T1.Selectbox(this.$(".list-size-list"), {
            data: null,
            onChange: function (e) {
              var val = e.currentTarget.value;
              if (collection.pageLimit !== val) {
                eventHub.publish("campaign:changePageLimit", {
                  pageLimit: val
                });
              }
            }
          });
          this.showHideLoaderForCampaignListReload(false);
        }
      }
      this.layout.empty();
      this.render().then(onRender.bind(this));
      this.layout.load().then(onLayoutLoad.bind(this));
    },
    loadCommandRibbonLayout(hasCampaigns) {
      var deferred = $.Deferred();
      var collection = this.collection;
      var sortItemList = [];
      var selectedStrategyGoal = collection.strategy_goal_performance;
      var selectedReportInterval = this.entityInterval || collection.report_interval;
      var hideExpired = collection.isExpiredHidden ? "checked" : "";
      const sortConfig = collection.getSortConfiguration();
      $.each(sortConfig.fields, function (key, sort) {
        sortItemList.push({
          text: sort.name,
          value: sort.value
        });
      });
      const currentSortField = this.entitySortValue || sortConfig.selectedValue;
      if (this.commandRibbonLayout) {
        this.commandRibbonLayout.empty();
      }
      this.commandRibbonLayout = new T1Layout({
        el: () => this.$(".ribbon-holder"),
        template: ribbonTemplate,
        layout: {
          ".cmd-sort-by": [{
            module: "shared",
            viewType: "selectForRibbonStrip",
            options: {
              itemList: sortItemList,
              onChange: () => {
                if (this.isCampaignListReloading !== true) {
                  this.sortCampaigns();
                }
              },
              selectedValue: currentSortField,
              itemLabel: "Sort by:",
              trailingIconCssClass: "sort-order-icon"
            }
          }],
          ".cmd-filter": [{
            module: "shared",
            viewType: "selectForRibbonStrip",
            options: {
              itemList: [{
                text: "All",
                value: "all"
              }, {
                text: "Active",
                value: "active"
              }, {
                text: "Inactive",
                value: "inactive"
              }],
              onChange: selectedState => {
                if (this.isCampaignListReloading !== true) {
                  this.updateStatusFilter(selectedState);
                }
              },
              selectedValue: this.statusFilter,
              itemLabel: "Status:",
              trailingIconCssClass: "filter-icon"
            }
          }],
          ".cmd-view-by": [{
            module: "shared",
            viewType: "selectForRibbonStrip",
            options: {
              itemList: dropdownList.listViewTypes.options,
              onChange: val => {
                this.actionHandlerRibbonSelect(val, "cmd-view-by", "changeListViewMode");
              },
              selectedValue: this.itemViewMode,
              itemLabel: "View by:"
            }
          }],
          ".cmd-compare-against": [{
            module: "shared",
            viewType: "selectForRibbonStrip",
            options: {
              itemList: dropdownList.strategy_goal.options,
              onChange: val => {
                this.actionHandlerRibbonSelect(val, "cmd-compare-against", "changeStrategyGoal");
              },
              selectedValue: selectedStrategyGoal,
              itemLabel: "Compare Actual Against:"
            }
          }],
          ".cmd-interval": [{
            module: "shared",
            viewType: "selectForRibbonStrip",
            options: {
              itemList: dropdownList.report_interval.options,
              onChange: val => {
                this.actionHandlerRibbonSelect(val, "cmd-interval", "changeReportInterval");
              },
              selectedValue: String(selectedReportInterval),
              itemLabel: "Interval:"
            }
          }]
        },
        serialize: () => ({
          hideExpired: hideExpired,
          isFavoritesView: this.currentList === "favorites"
        })
      });
      this.commandRibbonLayout.load().then(() => {
        const orderDirection = this.entitySortOrder || sortConfig.order_by;
        const $favoriteIcon = this.$("#ribbonFavoriteStar");
        const delay = 30;
        setTimeout(() => {
          this.$(".cmd-sort-by .sort-order-icon").addClass(orderDirection);
          if (hasCampaigns === true) {
            this.$(".command-ribbon.empty-list-mode").removeClass("empty-list-mode");
          }
          if (this.collection.hasFavorites()) {
            $favoriteIcon.addClass("orange-color-icon");
          }
          this.strategyGoalState();
        }, delay);
        deferred.resolve();
      });
      return deferred.promise();
    },
    events: {
      "click .w-foot .more-campaigns-action": "addMore",
      "click .w-head .cmd-sort-by .item-trailing-icon": "switchSortOrder",
      "click .w-head .cmd-activate .all-campaigns": "selectAllCampaigns",
      "click .bulk-actions-c .select-all": "toggleBulkActionDDL",
      "click .w-head .bulk-actions-c .option": "bulkAction",
      "click .command-ribbon .cmd-hide-expired": "toggleExpired",
      "click .command-ribbon .cmd-list-mode": "listOrFavoriteModesHandler",
      "click .command-ribbon .cmd-favorite-mode": "listOrFavoriteModesHandler",
      "click #closeBtnModal": "closeActivateCampaignModal"
    },
    applyRibbonUnitTabbingEffectByCmdKey(cmdKey) {
      const $allCmdUnits = this.$(".command-ribbon .ribbon-nav > li");
      $.each($allCmdUnits, function (index, ele) {
        $(ele).removeClass("unit-selected");
      });
      this.$(`.command-ribbon .${cmdKey}`).addClass("unit-selected");
    },
    updateListOrFavoriteModeRibbonCmd(listType) {
      const cmdKey = listType === "favorites" ? "cmd-favorite-mode" : "cmd-list-mode";
      this.applyRibbonUnitTabbingEffectByCmdKey(cmdKey);
    },
    listOrFavoriteModesHandler(event) {
      var target = $(event.currentTarget);
      var campaigns = this.collection;
      var listType = target.data("value");
      if (this.isCampaignListReloading === true || listType === this.currentList) {
        return;
      }
      this.showHideLoaderForCampaignListReload(true);
      this.currentList = listType;
      campaigns.clearFilters();
      campaigns.search.clear();
      campaigns.setSearchOptions();
      this.updateListOrFavoriteModeRibbonCmd(listType);
      if (listType === "all") {
        T1.EventHub.publish("campaign.changelistview", "all");
        campaigns.fetch({
          data: campaigns.getExpiredFilterParam()
        });
      } else if (listType === "favorites") {
        T1.EventHub.publish("campaign.changelistview", "favorites");
        T1.EventHub.publish("filter:clear");
        campaigns.count = 0;
        campaigns.fetchFavorites();
      }
    },
    showHideLoaderForCampaignListReload(toShow) {
      if (toShow === true) {
        this.$(".no-list-items").hide();
        this.$("[data-remove-onload]").show();
        this.$(".w-CampaignList .w-foot").hide();
        this.isCampaignListReloading = true;
      } else {
        this.$("[data-remove-onload]").slideUp();
        this.$(".w-CampaignList .w-foot").show();
        this.isCampaignListReloading = false;
      }
    },
    ensureCampaignPaneVisible(campaignId) {
      const $campaignPane = this.$(`.campaign-${campaignId}`);
      function getElementPosition(element) {
        var coords = {
          x: 0,
          y: 0
        };
        while (element) {
          coords.x += element.offsetLeft;
          coords.y += element.offsetTop;
          element = element.offsetParent;
        }
        return coords;
      }
      if ($campaignPane.length) {
        const fixedHeightAboveList = 177;
        const campaignPaneTop = getElementPosition($campaignPane[0]).y;
        const topVisiblePosition = campaignPaneTop - fixedHeightAboveList;
        $(document).scrollTop(topVisiblePosition);
      }
    },
    bulkAction(event) {
      event.stopPropagation();
      event.preventDefault();
      $(".bulk-actions-c", this.el).removeClass("active");
      this.handleGroupAction(event);
    },
    check(target) {
      target.removeClass("partial");
      target.addClass("checked");
    },
    uncheck(target) {
      target.removeClass("partial");
      target.removeClass("checked");
    },
    checkAll() {
      const checkBoxes = this.$('[data-checkbox-group="check-campaign"]');
      checkBoxes.removeClass("partial");
      checkBoxes.addClass("checked");
    },
    checkNone() {
      const checkBoxes = this.$('[data-checkbox-group="check-campaign"]');
      checkBoxes.removeClass("partial");
      checkBoxes.removeClass("checked");
    },
    closeActivateCampaignModal() {
      this.$(".activate-campaign-modal")[0].hide();
    },
    addMore() {
      this.addMoreHandler().then(() => {
        if (this.stateMaintainer) {
          this.stateMaintainer.registerMoreAction();
        }
      });
    },
    addMoreHandler() {
      var deferred = $.Deferred();
      var moreBtn = this.el.find(".more-campaigns");
      moreBtn.toggleClass("more-campaigns-action", false);
      Spinner.apply(moreBtn);
      this.collection.fetchMore({
        success: function () {
          moreBtn.toggleClass("more-campaigns-action", true);
          moreBtn.spin(false);
          deferred.resolve();
        },
        data: this.collection.getExpiredFilterParam()
      });
      return deferred.promise();
    },
    handleGroupAction(event) {
      var action = $(event.currentTarget).data("action");
      const checkBoxes = this.$(".campaign-list").find('[data-checkbox-group="check-campaign"]').filter(".checked");
      var campaigns = [];
      this.uncheck(this.$('[data-checkbox-group="check-campaign"]'));
      $.each(checkBoxes, function (index, checkBox) {
        const val = $(checkBox).data("value");
        if (val) {
          campaigns.push(val);
        }
      });
      this.actions.resolve(action, {
        campaigns: campaigns
      });
    },
    actionHandlerRibbonSelect(selectedVal, cmdKey, actionName) {
      this.actions.resolve(actionName, {
        value: selectedVal,
        callback: $.noop
      });
    },
    toggleBulkActionDDL() {
      var self = this;
      function hide(event) {
        const isParent = $(event.target).parents().index(self.$(".bulk-actions-c"));
        const isCheckIcon = self.$(".bulk-actions-c .checkbox").index(event.target) !== -1;
        if (isParent === -1 || isCheckIcon) {
          self.$(".bulk-actions-c").removeClass("active");
        }
        $(document).unbind("mouseup", hide);
      }
      this.$(".bulk-actions-c").toggleClass("active");
      $(document).bind("mouseup", hide);
    },
    switchSortOrder(event) {
      var $target = $(event.currentTarget);
      const $iconTarget = this.$(".cmd-sort-by .sort-order-icon");
      var isAscending = $iconTarget.hasClass("ascending");
      const order = isAscending ? "descending" : "ascending";
      if (this.isCampaignListReloading === true) {
        return;
      }
      $target.bind("click", $.noop);
      this.entitySortOrder = order;
      this.preferences.save();
      $iconTarget.removeClass(isAscending ? "ascending" : "descending");
      $iconTarget.addClass(order);
      this.sortCampaigns(order, true);
    },
    updateStatusFilter(newStatus) {
      if (!this.isCampaignListReloading) {
        this.statusFilter = newStatus;
        T1.EventHub.publish("campaigns:filterStateChange", {
          status: newStatus,
          search: this.stateMaintainer.getSavedStates().filterData
        });
        if (this.collection.hasFavorites()) {
          this.showHideLoaderForCampaignListReload(true);
        }
      }
    },
    toggleExpired(event) {
      if (!this.isCampaignListReloading) {
        const $target = $(event.currentTarget).find(".hide-expired-action");
        $target.toggleClass("checked");
        T1.EventHub.publish("campaigns:toggleExpired", {
          isChecked: $target.hasClass("checked"),
          search: this.stateMaintainer.getSavedStates().filterData
        });
        if (this.collection.hasFavorites()) {
          this.showHideLoaderForCampaignListReload(true);
        }
      }
    },
    getSelectInstanceByRibbonCmdKey(cmdKey) {
      var layoutObj = this.commandRibbonLayout ? this.commandRibbonLayout.layout : null;
      function getViewObj(layoutArr) {
        if ($.isArray(layoutArr) && layoutArr.length) {
          return layoutArr[0].view;
        }
        return null;
      }
      if (layoutObj) {
        for (const key in layoutObj) {
          if (T1.Utils.hasOwnProp(layoutObj, key)) {
            if (key.includes(`.${cmdKey}`)) {
              return getViewObj(layoutObj[key]);
            }
          }
        }
      }
      return null;
    },
    getSelectedValByRibbonCmdKey(cmdKey) {
      var selectObj = this.getSelectInstanceByRibbonCmdKey(cmdKey);
      return selectObj && selectObj.getSelectedValue ? selectObj.getSelectedValue() : null;
    },
    prevSortBy: null,
    sortCampaigns(order, updateOrderBy) {
      const {
        collection: collection,
        prevSortBy: prevSortBy
      } = this;
      var selectedValue = this.getSelectedValByRibbonCmdKey("cmd-sort-by");
      if (prevSortBy !== selectedValue) {
        collection.removeSort(prevSortBy || selectedValue);
      }
      this.prevSortBy = selectedValue;
      this.entitySortValue = selectedValue;
      this.preferences.save();
      this.showHideLoaderForCampaignListReload(true);
      collection.sortBy(selectedValue, order, updateOrderBy);
      collection.fetch({
        data: this.collection.getExpiredFilterParam()
      });
    },
    updateCheckAll(event) {
      let state;
      var el = event ? event.data.el : this.el;
      const checkBoxes = $(".campaign-list", el).find('[data-checkbox-group="check-campaign"]');
      var target = $(".w-head .cmd-activate .all-campaigns", el);
      if (event) {
        event.stopPropagation();
      }
      if (checkBoxes.length) {
        const countChecked = $(checkBoxes).filter(".checked").length;
        state = !countChecked ? "" : countChecked === checkBoxes.length ? "checked" : "partial";
      } else {
        state = "";
      }
      target.removeClass("partial");
      target.removeClass("checked");
      target.addClass(state);
    },
    selectAllCampaigns(event) {
      const currentTarget = $(event.currentTarget);
      const checkboxGroup = this.$('[data-checkbox-group="check-campaign"]');
      var isChecked = currentTarget.hasClass("checked");
      var state = isChecked ? this.uncheck : this.check;
      event.stopPropagation();
      $.each(checkboxGroup, function (index, target) {
        state($(target));
      });
    },
    append(campaign) {
      var collection = this.collection;
      if (campaign === null || campaign === undefined || typeof campaign !== "object") {
        return false;
      }
      this.layout.append(".list", {
        module: "campaign",
        viewType: "item",
        options: {
          model: campaign,
          viewMode: this.itemViewMode
        }
      });
      if (!collection.canFetchMore()) {
        this.$(".more-campaigns").remove();
      }
      this.updateDataBind({
        campaigns_remaining: function (target) {
          var count = collection.count - collection.length;
          if (count) {
            target.html(count);
          } else {
            target.parent().remove();
          }
        }
      }, this.$(".campaigns_remaining"));
      this.updateCheckAll();
    },
    serialize() {
      var reportInterval = this.entityInterval || this.collection.report_interval;
      this.selectedStrategyGoal = this.collection.strategy_goal_performance;
      this.currentViewMode = this.itemViewMode + reportInterval;
      return {
        hasCampaigns: this.collection.length,
        displayMore: this.collection.canFetchMore(),
        selectedReportInterval: reportInterval,
        selectedStrategyGoal: this.selectedStrategyGoal,
        selectedPageLimit: parseInt(this.collection.pageLimit, 10),
        selectedListView: this.itemViewMode,
        reportIntervalFields: dropdownList.report_interval.options,
        strategyGoalFields: dropdownList.strategy_goal.options,
        sizeFields: this.alowExtendedPageLimit === true ? dropdownList.extended_list_sizes.options : dropdownList.list_sizes.options,
        listViewTypes: dropdownList.listViewTypes.options,
        campaigns_remaining: this.collection.count - this.collection.length,
        currentViewMode: this.currentViewMode,
        activationErrTitle: this.activationErrTitle,
        activationErrText: this.activationErrText || "",
        getSelectOptions: function () {
          return function () {
            return `<option value="${this.value}">${this.text}</option>`;
          };
        }
      };
    }
  });
  return T1.Form.ViewUtils.extend(CampaignListView, ["serialize"]);
});
