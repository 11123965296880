define('modules/T1App/myApps/main',["jQuery", "T1", "T1Module", "models/t1AppDashboard"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  instance = new T1Module({
    name: "T1App/myApps",
    viewCfgs: {
      myApps: {},
      grid: {},
      chart: {},
      chart_date_picker: {},
      no_data_chart: {},
      grid_header_section: {}
    },
    defaultView: "myApps"
  });
  return instance;
});
