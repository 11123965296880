define('modules/reporting/segments/dataExport/manage/views/grid',["jQuery", "Underscore", "T1", "T1View", "text!../templates/grid.html"], function ($, _, T1, T1View, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {},
    eventhubEvents: {},
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    load: function load() {},
    unload: function unload() {
      DataExportModel = null;
    }
  });
});
