define('modules/reporting/campaigns/technology/views/donutChart',['require','jQuery','Underscore','modules/shared/configs/charts/donutChartConfig','modules/shared/configs/charts/donutChartConfigEmpty','T1','T1View','text!../templates/donutChart.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const ChartConfig = require("modules/shared/configs/charts/donutChartConfig");
  const ChartConfigEmpty = require("modules/shared/configs/charts/donutChartConfigEmpty");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/donutChart.html");
  const dimLevel = .5;
  const hundredPct = 100;
  const updateDelay = 1e3;
  const filterMap = Object.freeze({
    "In-Application": "In-App"
  });
  am4core.useTheme(am4themes_animated);
  am4core.addLicense("CH116590837");
  return T1View.extend({
    template: template,
    events: {
      "click .icon-delete-c": "removeFilter"
    },
    selectedItems: {},
    dataEvents: {},
    initialize({
      chartFilters: chartFilters,
      dimKey: dimKey,
      id: id,
      instanceID: instanceID,
      label: label,
      models: models
    }) {
      this.chartFilters = chartFilters;
      this.dimKey = dimKey;
      this.id = id;
      this.instanceID = instanceID;
      this.label = label;
      this.reportModel = models.reportModel;
      this.dataEvents.reportModel = {};
      this.dataEvents.reportModel[`change:${this.id}FilterData`] = "load";
    },
    load() {
      this.unload();
      this.data = this.reportModel.get(this.id === "device" ? "deviceFilterData" : "inventoryFilterData");
      this.render().then(() => {
        this.$chartLabelIcons = this.$(".chart-label-icons");
        this.initChart();
      });
    },
    cleanUpSelected() {
      for (const prop in this.selectedItems) {
        if (this.data[prop] === undefined) {
          delete this.selectedItems[prop];
        }
      }
      if (this.areItemsSelected()) {
        setTimeout(this.restoreSelectedSlices.bind(this), updateDelay);
      }
      this.applyFilters(true);
    },
    restoreSelectedSlices() {
      const series = this.chart.series.getIndex(0);
      series.slices.each(slice => {
        if (slice.dataItem.dataContext.selected) {
          slice.setState("active");
          slice.isActive = true;
        } else {
          slice.setState("unselected");
          slice.isActive = false;
        }
      });
    },
    initChart() {
      this.selectedItems = $.extend(true, {}, this.reportModel.get(`techFiltersSelected_${this.id}`) || {});
      this.chartData = this.reportModel.getDonutChartData(this.data, this.instanceID, this.selectedItems);
      const hasData = Boolean(this.chartData.length);
      const config = hasData ? ChartConfig.copy() : ChartConfigEmpty.copy();
      if (hasData) {
        this.labelContainer = this.createLabelContainer();
        this.percentLabel = this.createPercentLabel(this.labelContainer);
        this.legendContainer = this.createLegendContainer();
        this.updateSliceSettings(config, this.percentLabel);
        this.updateLegendSettings(config, this.percentLabel, this.legendContainer);
        config.data = this.chartData;
      }
      this.chart = am4core.createFromConfig(config, `donut${this.instanceID}`, am4charts.PieChart);
      this.cleanUpSelected();
      if (this.areItemsSelected()) {
        this.$chartLabelIcons.show();
      }
      this.el.trigger("handleNoDataDisplay", [this.id, hasData]);
    },
    createLabelContainer() {
      const container = am4core.create(`percent${this.instanceID}`, am4core.Container);
      container.width = am4core.percent(hundredPct);
      container.height = am4core.percent(hundredPct);
      container.background.fill = am4core.color("white");
      container.background.opacity = 1;
      return container;
    },
    createPercentLabel(labelContainer) {
      const pctLabel = labelContainer.createChild(am4core.Label);
      pctLabel.align = "center";
      pctLabel.valign = "middle";
      pctLabel.text = "{value}%";
      pctLabel.hide();
      return pctLabel;
    },
    createLegendContainer() {
      const container = am4core.create(`legend${this.instanceID}`, am4core.Container);
      container.width = am4core.percent(hundredPct);
      container.height = am4core.percent(hundredPct);
      return container;
    },
    updateSliceSettings(config, percentLabel) {
      const seriesConfig = config.series[0];
      const slicesConfig = seriesConfig.slices;
      const sliceEvents = slicesConfig.events;
      function handleRollActions({
        target: target,
        type: type
      }) {
        const activeSlice = target.dataItem;
        const isRollOutEvent = type === "out";
        this.findActiveLegendBullet(activeSlice, isRollOutEvent ? 0 : dimLevel);
        this.togglePercentLabel(percentLabel, activeSlice, type);
        if (isRollOutEvent && !activeSlice.slice.isActive && this.areItemsSelected()) {
          setTimeout(this.dimSlice.bind(activeSlice.slice), 0);
        }
      }
      seriesConfig.colors.list = this.reportModel.getDonutChartColors(this.instanceID);
      sliceEvents.over = handleRollActions.bind(this);
      sliceEvents.out = handleRollActions.bind(this);
      sliceEvents.hit = evt => {
        const clickedSlice = evt.target;
        const {
          component: series,
          category: name
        } = clickedSlice.dataItem;
        this.handleActiveSlice(clickedSlice, series, name);
      };
    },
    updateLegendSettings(config, percentLabel, legendContainer) {
      const legendEvents = config.legend.itemContainers.events;
      config.legend.parent = legendContainer;
      legendEvents.over = evt => {
        this.handleLegendRingState(evt, percentLabel, "over");
      };
      legendEvents.out = evt => {
        this.handleLegendRingState(evt, percentLabel, "out");
        this.handleUnselectedSlice(evt.target.dataItem);
      };
      legendEvents.hit = evt => {
        const clickedSlice = evt.target.dataItem.dataContext.slice;
        const {
          component: series,
          category: name
        } = clickedSlice.dataItem;
        clickedSlice.isActive = !clickedSlice.isActive;
        setTimeout(() => {
          this.handleActiveSlice(clickedSlice, series, name);
        }, 0);
      };
    },
    findActiveLegendBullet(activeSlice, value) {
      const name = activeSlice.properties.category;
      const activeLegendBullet = this.chart.legend.markers.values.find(marker => marker.dataItem.name === name);
      const outerCircle = activeLegendBullet.children.getIndex(1);
      outerCircle.opacity = value;
    },
    togglePercentLabel(label, activeItem, state) {
      if (state === "over") {
        label.setDataItem(activeItem);
        label.show();
      } else {
        label.hide();
      }
    },
    handleActiveSlice(clickedItem, series, name) {
      if (clickedItem.isActive) {
        this.selectedItems[name] = true;
      } else {
        delete this.selectedItems[name];
      }
      series.slices.each(slice => {
        if (!slice.isActive) {
          if (this.areItemsSelected()) {
            setTimeout(this.dimSlice.bind(slice), 0);
          } else {
            slice.setState("default");
          }
        }
      });
      this.filterChangeHandler();
    },
    handleLegendRingState(evt, percentLabel, state) {
      const currentItem = evt.target.children.getIndex(0);
      const outerCircle = currentItem.children.getIndex(1);
      outerCircle.opacity = state === "over" ? dimLevel : 0;
      this.togglePercentLabel(percentLabel, currentItem.dataItem.dataContext, state);
    },
    handleUnselectedSlice({
      name: name,
      dataContext: dataContext
    }) {
      const selectedKeys = Object.keys(this.selectedItems);
      const isThisSliceSelected = selectedKeys.includes(name);
      if (!isThisSliceSelected && selectedKeys.length) {
        setTimeout(function () {
          dataContext.slice.setState("unselected");
        }, 0);
      }
    },
    areItemsSelected() {
      return Boolean(Object.keys(this.selectedItems).length);
    },
    dimSlice() {
      this.setState("unselected");
    },
    removeFilter() {
      if (this.areItemsSelected()) {
        this.selectedItems = {};
        this.filterChangeHandler();
        this.chart.series.values[0].slices.each(slice => {
          slice.setState("default");
          slice.isActive = false;
        });
      }
    },
    filterChangeHandler() {
      if (this.areItemsSelected()) {
        this.$chartLabelIcons.show();
      } else {
        this.$chartLabelIcons.hide();
      }
      this.applyFilters();
    },
    getFilters() {
      const {
        chartData: chartData,
        selectedItems: selectedItems
      } = this;
      const options = _.pluck(chartData, "label");
      const allDataKeys = Object.keys(this.data);
      const unknownKeys = _.difference(allDataKeys, options);
      return chartData.reduce((arr, {
        label: label
      }) => {
        if (selectedItems[label]) {
          if (label === "All Others") {
            arr.push(label, ...unknownKeys);
          } else {
            arr.push(filterMap[label] || label);
          }
        }
        return arr;
      }, []);
    },
    applyFilters(silent) {
      const filters = this.areItemsSelected() ? this.getFilters() : [];
      const moreFilters = filters.length ? `&${this.dimKey}=${filters.toString()}` : "";
      this.reportModel.setTechFilters(this.id, moreFilters, $.extend(true, {}, this.selectedItems));
      if (!silent) {
        T1.EventHub.publish("set:moreFilters");
      }
    },
    unload() {
      if (this.percentLabel) {
        this.percentLabel.dispose();
        this.labelContainer.dispose();
        this.legendContainer.dispose();
        this.percentLabel = null;
        this.labelContainer = null;
        this.legendContainer = null;
      }
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    },
    serialize() {
      return {
        id: this.instanceID,
        label: this.data === undefined ? "" : this.label
      };
    }
  });
});
