define('modules/admin/siteLists/views/createEdit',['require','Underscore','jQuery','text!modules/admin/siteLists/templates/createEditBody.html','text!modules/admin/templates/header.html','text!modules/admin/siteLists/templates/listInfo.html','models/organization','collections/organizations','models/siteList','utils/SiteUtils','T1Spinner','T1','T1Alert','T1FileUpload','T1Layout','T1Menu','T1Permissions','T1View','text!modules/admin/siteLists/templates/createEdit.html','utils/TimezoneUtils','utils/AdminUtils','When'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const bodyTemplate = require("text!modules/admin/siteLists/templates/createEditBody.html");
  const headTemplate = require("text!modules/admin/templates/header.html");
  const listInfoTemplate = require("text!modules/admin/siteLists/templates/listInfo.html");
  const Organization = require("models/organization");
  const Organizations = require("collections/organizations");
  const SiteList = require("models/siteList");
  const SiteUtils = require("utils/SiteUtils");
  const Spinner = require("T1Spinner");
  const T1 = require("T1");
  const T1Alert = require("T1Alert");
  const T1FileUpload = require("T1FileUpload");
  const T1Layout = require("T1Layout");
  const T1Menu = require("T1Menu");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!modules/admin/siteLists/templates/createEdit.html");
  const TimezoneUtils = require("utils/TimezoneUtils");
  const Utils = require("utils/AdminUtils");
  const When = require("When");
  var timezone = TimezoneUtils.getClientTimezoneAbbr();
  var convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
  var dateFormat = "MM/dd/yyyy hh:mm TT ";
  var SiteListCreateEdit;
  var fileTypes = ["text/csv", "attachment/csv", "application/vnd.ms-excel", "application/x-download", "application/x-downloaded"];
  var fileExts = ["csv"];
  var orgs = Organizations.getOrganizations();
  const maxCSVLimit = 1e6;
  SiteListCreateEdit = T1View.extend({
    defaultFormat: "entries",
    defaultMode: "add",
    hasUnloaded: false,
    isEdit: false,
    template: template,
    partials: {
      header: headTemplate,
      body: bodyTemplate,
      listInfo: listInfoTemplate
    },
    events: {
      "changed .format-group": "toggleFormat",
      "changed .mode-group": "toggleMode",
      "click .delete": "confirmDelete",
      "click .file-upload-btn": "resetSitesFields",
      "keydown .file-uploader .text-input": "stopBubbling",
      "keyup .file-uploader .text-input": "stopBubbling",
      "mouseout .error": "hideTooltip",
      "mouseover .error": "showTooltip"
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveAndNew"
      }
    },
    menuConfig: {
      menuTriggerEleSelector: ".advanced-options",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Export Site List",
          handler: "exportCSVHandler",
          visible: true
        }, {
          label: "Delete Site List",
          handler: "confirmDelete",
          visible: true
        }]
      }],
      popoverXPosOffset: -10,
      popoverYPosOffset: 40
    },
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 8,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    eventhubEvents: {
      "organization:select": "handleOrgChange",
      clearUpload: function () {
        $(".file-input", this.el).val("");
        $(".file-selector .file-name input", this.el).val("");
        this.onFileUpload();
      },
      uploadFile: "onFileUpload"
    },
    initialize(args) {
      var formActions = [];
      var actionButtons = {
        cancel: {
          class: "cancel",
          label: "cancel",
          location: "#admin/siteLists",
          uuid: "cancel"
        },
        save: {
          class: "save save-btn-secondary",
          label: "save",
          location: false,
          uuid: "save"
        },
        saveAndClose: {
          class: "saveAndClose save-btn-primary",
          label: "save & close",
          location: false,
          uuid: "save_close"
        },
        saveAndNew: {
          class: "saveAndNew save-btn-primary",
          label: "save & add another",
          location: false,
          uuid: "save_add"
        }
      };
      if (args.id) {
        this.setEditMode(args.id);
      }
      this.canLoad = false;
      this.canSave = true;
      formActions.push(actionButtons.cancel);
      if (this.isEdit) {
        formActions.push(actionButtons.save);
      }
      formActions.push(actionButtons.saveAndClose);
      if (!this.isEdit) {
        formActions.push(actionButtons.saveAndNew);
      }
      this.actionsConfig.formActions = formActions;
      this.updateModel(args);
      this.fileUploaderData = {
        formSelector: "sitelistfile-upload",
        placeholder: `Select a CSV with your list of domains and/or apps. Max size: ${parseInt(this.model.sizeLimit / maxCSVLimit, 10)} MB.`,
        rubric: `${"Select a CSV file with your list of domains (example.com or example.com/page) and/or apps " + "(e.g. app:targeting_tag).<br />Max size: "}${parseInt(this.model.sizeLimit / maxCSVLimit, 10)} MB.`,
        accept: fileTypes.join(","),
        inputFields: [{
          name: "filename",
          dataBindInput: true
        }, {
          name: "csv",
          dataBindInput: true
        }]
      };
    },
    updateModel(args) {
      var model = this.model || new SiteList({});
      var deferred = When.defer();
      var siteList = {};
      this.model = model;
      if (args.id) {
        siteList = new SiteList({
          id: args.id
        });
        this.setEditMode(args.id);
        siteList.fetch({
          success: data => {
            this.model = data;
            model = this.model;
            model.isEdit = this.isEdit;
            Utils.fetchData(Organization, model.get("organization_id")).then(org => {
              model.set({
                org: org.toJSON()
              });
              this.canLoad = true;
              Utils.updateBreadCrumb("Site List", model.toJSON());
              this.initLayout();
              this.load();
              deferred.resolve(model);
            });
          }
        });
      } else {
        this.model = new SiteList({
          new: true,
          status: "1",
          name: ""
        });
        model = this.model;
        this.canLoad = true;
        this.initLayout();
        Utils.updateBreadCrumb("Site List", model.toJSON());
        deferred.resolve(model);
      }
      return deferred.promise;
    },
    initLayout() {
      var self = this;
      var model = this.model;
      var organization_id = model.get("organization_id");
      var createEditLayout = {};
      if (!this.canLoad) {
        return;
      }
      orgs.getSelected().then($.proxy(function (orgId) {
        self.org = orgId;
        if (!self.isEdit) {
          createEditLayout[".organization-select"] = [{
            module: "shared",
            viewType: "select",
            options: {
              clearOnClose: true,
              dataBind: "organization_id",
              entity: "organizations",
              fetchSelected: true,
              initialLoad: true,
              isSearchable: true,
              notFound: "No Organization Found",
              placeholder: "Select an Organization",
              preselect: "Select an Organization",
              selectedId: organization_id || orgId,
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              }
            }
          }];
        }
      }, this));
      if (this.isEdit) {
        createEditLayout[".w-SiteListUsage"] = [{
          module: "admin/siteLists",
          viewType: "usage",
          options: {
            id: model.id
          }
        }];
        createEditLayout[".bulk-export-modal"] = [{
          module: "admin/siteLists",
          viewType: "csvExportModal"
        }];
      }
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: "_wrapper",
        template: template,
        serialize: $.proxy(self.serialize, self),
        layout: createEditLayout
      });
    },
    load() {
      if (this.canLoad) {
        this.render().then(() => {
          this.layout.load();
          if (!this.fileUploader) {
            this.fileUploader = T1FileUpload.create({
              $el: this.$(".file-uploader"),
              gatherFileInfo: true,
              onChange: $.proxy(this.onFileUpload, this),
              onDataReady: $.proxy(this.onFileReceive, this),
              readAsText: true,
              showInput: false,
              useReader: true
            });
          }
          this.fileUploader.render(this.fileUploaderData);
          this.cacheElems();
          if (this.isEdit) {
            this.toggleFormat();
            this.toggleMode();
          }
          this.prepareMenuItems();
          const $guide = $("#extended-domain");
          if (!window.localStorage.getItem("guide-extended-domain") && $guide.length) {
            $guide[0].show();
          }
        });
      }
    },
    prepareMenuItems() {
      var menuConfig = $.extend(true, {}, this.menuConfig);
      if (this.isEdit) {
        T1Menu(menuConfig, this);
      }
    },
    cacheElems() {
      this.$csverror = this.fileUploader.el.find(".text-input");
      this.$csverror.attr({
        "data-bind": "csverror",
        "data-no-formchange": "true"
      }).removeAttr("disabled");
      this.$content = this.$('[data-bind="content"]');
      this.$entries = this.$('[data-bind="entries"]');
      this.$csv = this.$('[data-bind="csv"]');
      this.$filename = this.$('[data-bind="filename"]');
      this.$saveButtons = this.$(".save-btn-primary,.save-btn-secondary");
      this.$spinnerTarget = this.$(".file-upload-btn");
      this.$entriesBtn = this.$('[value="entries"]');
      this.$entriesPanel = this.$(".entries");
      this.$csvPanel = this.$(".csvmode");
      this.elemsCached = true;
    },
    toggleSaveButtons(enable) {
      this.$saveButtons.removeClass("disabled");
      if (!enable) {
        this.$saveButtons.addClass("disabled");
      }
    },
    onFileReceive(el, data) {
      var fileSiteCount = 0;
      if (this.fileUploading === true) {
        fileSiteCount = this.validateSiteData("csverror", data);
      }
      this.fileUploading = false;
      if (fileSiteCount > 0) {
        this.isFormElementsChanged = true;
      }
      this.toggleSaveButtons(true);
      this.$spinnerTarget.spin(false);
      this.$spinnerTarget.text("Browse");
    },
    validateSiteData(field, siteData) {
      var self = this;
      var model = this.model;
      var mode = this.mode;
      var dataOK = true;
      var fileSites = {};
      var siteDataMsg;
      var warnStr = [];
      var warnType = "error";
      var errors = [];
      var counts = {
        valid: 0,
        duplicate: 0,
        estimated: 0,
        invalid: 0,
        new: 0,
        original: parseInt(model.get("sites_count") || 0, 10)
      };
      if (typeof siteData === "string") {
        siteData = d3.csv.parseRows.call(d3, siteData.replace(/app:\s+/gi, "app:").replace(/\s+/g, ","));
        $.each(siteData, function (i, members) {
          $.each(members, function (j, member) {
            if (member !== "") {
              if (SiteUtils.isIPAddress(member) || SiteUtils.isExtendedDomain(member) || SiteUtils.isApp(member) || SiteUtils.isIDNDomain(member, true)) {
                if (SiteUtils.isApp(member)) {
                  member = SiteUtils.normalizeApp(member);
                } else {
                  member = SiteUtils.normalizeExtendedDomain(member);
                }
                if (fileSites[member] === member) {
                  counts.duplicate++;
                } else {
                  fileSites[member] = member;
                  counts.valid++;
                }
              } else {
                counts.invalid++;
              }
            }
          });
        });
        fileSites = _.toArray(fileSites);
        counts.new = fileSites.length;
        if (field !== "entries") {
          siteDataMsg = `${counts.new + (mode === "add" ? " new" : "") + Utils.pluralize("entry", counts.new, Utils.spcOpts.BOTH)}ready for upload!`;
        }
        if (mode === "add") {
          counts.estimated = counts.new + counts.original;
        } else {
          counts.estimated = counts.new;
        }
        fileSites = fileSites.join(" ");
        if (counts.new <= 0) {
          errors.push({
            field: field,
            message: `No valid entries ${field !== "entries" ? "in file" : "were entered"}.`
          });
          dataOK = false;
        }
        if (field === "entries" && counts.new > model.manualEntriesLimit) {
          errors.push({
            field: field,
            message: `${counts.new + Utils.pluralize("entry", counts.new, Utils.spcOpts.BOTH)}were detected.  That exceeds the maximum of ${model.manualEntriesLimit}.`
          });
          dataOK = false;
        }
        if (field !== "entries") {
          if (counts.invalid + counts.duplicate || warnStr.length > 0) {
            if (counts.invalid) {
              warnStr.push(`${counts.invalid} invalid${Utils.pluralize("entry", counts.invalid, Utils.spcOpts.LEFT)}`);
            }
            if (counts.duplicate) {
              warnStr.push(`${counts.duplicate} duplicate${Utils.pluralize("entry", counts.duplicate, Utils.spcOpts.LEFT)}`);
            }
            warnStr = `${warnStr.join(" and ") + Utils.pluralize("was", counts.invalid + counts.duplicate, Utils.spcOpts.BOTH)}removed.`;
            if (counts.new > 0) {
              warnType = "success";
              warnStr = `${siteDataMsg}<br />${warnStr}`;
            } else {
              warnStr += "<br />No valid entries in file.";
              dataOK = false;
            }
            T1.Notify(warnType, warnStr);
          } else if (field !== "entries" && dataOK) {
            T1.Notify("success", siteDataMsg);
          }
          self.$csverror.val(model.get("filename"));
        }
        if (dataOK) {
          self.counts = counts;
          self.$content.val(fileSites);
          if (field === "csverror") {
            self.$filename.val(model.get("filename"));
            self.updateListCount();
          }
        }
      } else {
        errors.push({
          field: field,
          message: `The Site List ${field === "entries" ? "data" : "file"} is incorrectly formatted.`
        });
      }
      if (errors.length) {
        self.displayErrors(errors);
        return false;
      }
      return counts.new;
    },
    onFileUpload(evt) {
      var model = this.model;
      var fileInfo = evt.data.fileInfo || {};
      var displaySize = (fileInfo.size / maxCSVLimit).toFixed(1).replace(/\.0$/, "");
      var fileParts = fileInfo.name.toLowerCase().trim().split(".");
      var fileExt = fileParts.length > 1 ? fileParts[fileParts.length - 1] : "missing";
      var sizeLimit = model.sizeLimit;
      var displayLimit = (sizeLimit / maxCSVLimit).toFixed(0);
      var field = "csverror";
      var errors = [];
      if (this.fileUploading === true) {
        return;
      }
      this.resetSitesFields();
      this.$csverror.val(fileInfo.name);
      if (fileInfo.size > sizeLimit) {
        if (displaySize === displayLimit) {
          displaySize = `over ${displaySize}`;
        }
        errors.push({
          field: field,
          message: `The Site List file is ${displaySize} MB. It must be no larger than ${displayLimit} MB.`
        });
      }
      if ($.inArray(fileInfo.type.toLowerCase().trim(), fileTypes) < 0) {
        errors.push({
          field: field,
          message: `The Site List file is of type '${fileInfo.type}'. It must be of type 'text/csv'.`
        });
      }
      if ($.inArray(fileExt, fileExts) < 0) {
        errors.push({
          field: field,
          message: `The Site List file extension is ${fileExt === "missing" ? fileExt : `'.${fileExt}'`}. It must be '.${fileExts.join("' or '")}'.`
        });
      }
      if (errors.length) {
        this.displayErrors(errors);
        return;
      }
      model.set({
        filename: fileInfo.name
      });
      this.fileUploading = true;
      this.toggleSaveButtons(false);
      this.$spinnerTarget.text("");
      Spinner.apply(this.$spinnerTarget, this.spinnerOpts);
    },
    updateListCount() {
      var model = this.model;
      var mode = this.mode;
      var countAll = model.get("sites_count");
      var countDomains = model.get("sites_count_domain");
      var countApps = model.get("sites_count_app");
      var counts = this.counts;
      if (!this.isEdit) {
        return;
      }
      if (counts) {
        if (mode === "add") {
          counts.estimated = counts.new + counts.original;
        } else {
          counts.estimated = counts.new;
        }
        countAll = `${counts.estimated} (estimated, pending save)`;
        countDomains = null;
        countApps = null;
      }
      if (countDomains || countApps) {
        countAll = null;
        countDomains = countDomains || "0";
        countApps = countApps || "0";
      }
      this.renderPartialTemplate({
        template: headTemplate,
        useTemplateHtml: false,
        action: "html",
        targetEl: ".create-edit-head",
        data: {
          title: model.get("name"),
          subTitle: model.get("org").name
        }
      });
      this.renderPartialTemplate({
        template: listInfoTemplate,
        useTemplateHtml: false,
        action: "html",
        targetEl: ".listInfo",
        data: {
          sites_count: countAll,
          sites_count_domain: countDomains,
          sites_count_app: countApps,
          updated_on: convertToLocalTime(model.get("updated_on")).toString(dateFormat) + timezone
        }
      });
    },
    postData(formValues, success, statusInvalid, conflict) {
      var self = this;
      var model = this.model;
      function initLoader() {
        self.updateFooterMessage({
          progress: true,
          message: "Upload in progress. Please do not navigate away."
        });
        self.progressLoader = T1.Loader.create({
          count: 100,
          context: self.$(".progress-holder"),
          loaderClass: "upload-bar",
          type: "progress"
        });
      }
      model.createEdit(formValues, {
        success: function (returnedModel) {
          var output = $.extend({}, returnedModel.attributes ? returnedModel.attributes : {});
          var siteCountMsg = [];
          output.actualCount = parseInt(output.sites_count || 0, 10);
          if (isNaN(output.actualCount)) {
            output.actualCount = 0;
          }
          if (self.counts) {
            $.each(self.counts, function (k, v) {
              var outputKey = `${k}Count`;
              output[outputKey] = parseInt(v || 0, 10);
              if (isNaN(output[outputKey])) {
                output[outputKey] = 0;
              }
            });
            if (output.estimatedCount > 0) {
              if (self.isEdit) {
                output.duplicateCount += output.estimatedCount - output.actualCount;
              } else if (output.actualCount <= 0) {
                output.actualCount = output.estimatedCount;
              }
            }
            if (self.mode !== "add") {
              output.newCount = output.actualCount;
            } else {
              output.newCount = output.actualCount - output.originalCount;
            }
            if (output.invalidCount > 0) {
              siteCountMsg.push(`${output.invalidCount} invalid${Utils.pluralize("entry", output.invalidCount, Utils.spcOpts.BOTH)}`);
            }
            if (output.duplicateCount > 0) {
              siteCountMsg.push(`${output.duplicateCount} duplicate${Utils.pluralize("entry", output.duplicateCount, Utils.spcOpts.BOTH)}`);
            }
            if (siteCountMsg.length > 0) {
              siteCountMsg = [`${siteCountMsg.join(" and ") + Utils.pluralize("was", output.invalidCount + output.duplicateCount, Utils.spcOpts.BOTH)}removed.`];
            }
            if (output.newCount + output.invalidCount + output.duplicateCount > 0) {
              siteCountMsg.unshift(`${output.newCount} new${Utils.pluralize("entry", output.newCount, Utils.spcOpts.BOTH)}${Utils.pluralize("was", output.newCount, Utils.spcOpts.BOTH)}${self.mode === "replace" ? "overwritten" : "added"}.`);
            }
            siteCountMsg = [siteCountMsg.join(" ")];
          }
          siteCountMsg.push(`Site List ${self.isEdit ? "updated" : "created"} successfully!`);
          T1.Notify("message", siteCountMsg.join("<br />"));
          delete self.counts;
          $.each(model.attributes, function (k) {
            if (k.indexOf("sites_count") === 0 && !output[k]) {
              output[k] = 0;
            }
          });
          model.set(output);
          self.isFormElementsChanged = false;
          self.updateListCount();
          success(returnedModel);
        },
        statusInvalid: function (errors) {
          if (errors && errors.length) {
            if (typeof errors === "string") {
              errors = T1.Utils.parseXMLtoJson($.parseXML(errors));
              if (errors.error) {
                errors = [{
                  message: errors.error,
                  field: self.format
                }];
              }
            }
            if (errors && errors.length) {
              _.map(errors, function (error) {
                error.field = error.field === "csv" ? "csverror" : error.field;
                return error;
              });
            }
          }
          statusInvalid(errors, null, ".form-body");
        },
        conflict: function (...args) {
          conflict.apply(self, args);
        },
        startChunking: function () {
          initLoader();
        },
        onChunk: function (chunk, chunks) {
          const percentage = 100;
          var percentComplete = Math.ceil(chunk / chunks * percentage);
          if (self.$(".progress-holder").length === 0) {
            initLoader();
          }
          self.progressLoader.progress(percentComplete);
        },
        action: self.isEdit ? "" : "upload"
      });
    },
    setEditMode(id) {
      this.isEdit = true;
      this.format = this.defaultFormat;
      this.mode = this.defaultMode;
      T1.Location.set(`#admin/siteList/${id}`, {
        silent: true
      });
    },
    saveOverride(evt, customCallbacks) {
      var entries = this.$entries.val();
      var callbacks = customCallbacks || {
        success: () => {
          this.resetSitesFields();
          this.$entriesBtn.trigger("click");
          Utils.updateBreadCrumb("Site List", this.model.toJSON());
        }
      };
      if (entries) {
        this.canSave = Boolean(this.validateSiteData("entries", entries));
      } else if (!this.$csverror.hasClass("error")) {
        this.clearErrors();
      }
      if (this.canSave) {
        return this.saveOriginal(evt, callbacks);
      } else if (customCallbacks && customCallbacks.invalid) {
        customCallbacks.invalid();
      }
    },
    saveAndClose(evt) {
      var entries = this.$entries.val();
      var callback = {
        success: () => {
          const delay = 1e3;
          this.startSpinner($(evt.currentTarget));
          setTimeout(function () {
            T1.Location.set("#admin/siteLists");
          }, delay);
        }
      };
      if (entries) {
        this.canSave = Boolean(this.validateSiteData("entries", entries));
      } else if (!this.$csverror.hasClass("error")) {
        this.clearErrors();
      }
      if (this.canSave) {
        return this.saveOriginal(evt, callback);
      }
    },
    saveAndNew(evt) {
      var callback = {
        success: () => {
          if (this.model.get("new")) {
            const currOrg = this.model.get("organization_id");
            this.unload();
            this.model = new SiteList({
              new: true,
              status: "1",
              organization_id: currOrg
            });
            this.initLayout();
            this.load();
          }
          T1.Location.set("#admin/siteList");
        }
      };
      if (this.canSave) {
        this.setSaveAndContinueMode(true);
        return this.saveOriginal(evt, callback);
      }
    },
    confirmDelete() {
      var self = this;
      var success = function () {
        self.isFormElementsChanged = false;
        T1.Notify("message", "Site List deleted successfully!");
        T1.Location.set("#admin/siteLists");
      };
      var confirmAction = function () {
        var $dialog = $(this);
        self.model.delete({
          successCallback: success,
          errorCallback: $.noop
        });
        $dialog.dialog("close");
      };
      var cancelAction = function () {
        var $dialog = $(this);
        $dialog.dialog("close");
      };
      var alertMsg = new T1Alert();
      var alertData = {
        title: "Deletion Warning",
        bodyMsg: "Deleting this Site List will permanently remove it from T1. " + "Also it will no longer apply to any Campaign and Strategy. Are you sure you want to do this?",
        buttons: [{
          text: "Yes",
          class: "save",
          click: confirmAction
        }, {
          text: "Cancel",
          class: "cancel",
          click: cancelAction
        }],
        icon: "warning-icon"
      };
      alertMsg.initialize(alertData);
      alertMsg.show();
    },
    canUnload() {
      var isFormElementsChanged = this.isFormElementsChanged;
      var cancelHandler = {
        onAlertClose: function () {
          var T1Location = T1.Location;
          var abortedUrl = T1Location.getAbortedURL();
          if (abortedUrl === "" || abortedUrl === false) {
            setTimeout(function () {
              T1Location.set("#admin/siteLists");
            }, 1);
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Site List Edit" : "New Site List"
        }, cancelHandler);
      }
      return !isFormElementsChanged;
    },
    cancelUnload() {
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          this.doNotProcessOrgChange = true;
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    unload() {
      delete this.fileInfo;
      if (this.fileUploader) {
        this.fileUploader.destroy();
        this.fileUploader = null;
      }
      this.hasUnloaded = true;
    },
    handleOrgChange(data) {
      if (!this.doNotProcessOrgChange) {
        T1.EventHub.publish("dropdown:organizations", data);
        T1.Location.set("admin/siteLists");
      }
      this.doNotProcessOrgChange = false;
    },
    stopBubbling(evt) {
      if (evt) {
        if (evt.stopPropagation) {
          evt.stopPropagation();
        }
        if (evt.preventDefault) {
          evt.preventDefault();
        }
      }
    },
    toggleMode(evt) {
      this.mode = evt && evt.originalEvent && evt.originalEvent.detail ? evt.originalEvent.detail.value || this.defaultMode : this.defaultMode;
      this.updateListCount();
      this.stopBubbling(evt);
    },
    toggleFormat(evt) {
      this.format = evt && evt.originalEvent && evt.originalEvent.detail ? evt.originalEvent.detail.value || this.defaultFormat : this.defaultFormat;
      this.stopBubbling(evt);
      if (this.elemsCached) {
        this.resetSitesFields();
        if (this.format === "entries") {
          this.$entriesPanel.removeClass("no-display");
          this.$csvPanel.addClass("no-display");
        } else {
          this.$csvPanel.removeClass("no-display");
          this.$entriesPanel.addClass("no-display");
        }
      }
    },
    resetSitesFields() {
      var currentIsFormElementsChanged = this.isFormElementsChanged;
      this.$content.val("");
      this.$csv.val("");
      this.$csverror.val("");
      this.$filename.val("");
      this.removeTooltip({
        currentTarget: this.$csverror
      });
      if (this.$entries.length > 0) {
        this.$entries.val("").css("height", "85px");
        this.$entries.removeClass("error");
        this.removeTooltip({
          currentTarget: this.$entries
        });
      }
      this.clearErrors();
      this.isFormElementsChanged = currentIsFormElementsChanged;
    },
    displayErrors(errors) {
      this.showErrors(this.model, errors, ".form-body");
      this.canSave = false;
    },
    clearErrors() {
      this.updateFooterMessage();
      this.canSave = true;
    },
    exportCSVHandler() {
      T1.EventHub.publish("csvExportModal:show", {
        id: this.model.id
      });
    },
    serialize() {
      var model = this.model;
      var output = model.toJSON();
      output.isEdit = this.isEdit;
      output.status = output.status === "1";
      output.manualEntriesLimit = model.manualEntriesLimit;
      output.title = output.id ? output.name : "New Site List";
      if (output.org) {
        output.subTitle = output.org.name;
      }
      output.canEditSiteLists = T1Permissions.check("siteLists.edit");
      if (output.updated_on) {
        output.updated_on = convertToLocalTime(output.updated_on).toString(dateFormat) + timezone;
      }
      output.csvRubric = this.fileUploaderData.rubric;
      return output;
    }
  });
  SiteListCreateEdit = T1.Form.ViewUtils.extend(SiteListCreateEdit);
  SiteListCreateEdit.prototype.saveOriginal = SiteListCreateEdit.prototype.save;
  SiteListCreateEdit.prototype.save = SiteListCreateEdit.prototype.saveOverride;
  return SiteListCreateEdit;
});
