define('modules/reporting/campaigns/performance/views/chartPanel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/chartLayout.html", "text!../templates/chartPanel.html"], function ($, _, T1, T1View, T1Layout, chartLayout, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: chartLayout,
    events: {
      "click .bar-icon": "switchGraph",
      "click .line-icon": "switchGraph",
      "mouseout strand-icon": "setChartIconState",
      "mouseover strand-icon": "changeChartIconColor"
    },
    initialize: function (args) {
      this.cfgs = args;
      const mainModel = this.cfgs.models.mainModel;
      this.chartShown = mainModel.get("chartShown") === "true";
      this.subSection = mainModel.get("performanceSubSection");
      this.initLayout();
    },
    initLayout: function () {
      const self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".performance-chart-panel");
        },
        template: template,
        layout: {
          ".performance-chart-title-box": [{
            module: "reporting/campaigns/performance",
            options: this.cfgs,
            viewType: "chartTitle"
          }],
          ".metric-select-c": [{
            module: "reporting/campaigns",
            options: this.cfgs,
            viewType: "selectMetric"
          }],
          ".performance-chart-box": [{
            module: "reporting/campaigns/performance",
            options: this.cfgs,
            viewType: "performanceChart"
          }]
        }
      });
    },
    load: function () {
      const $el = this.el;
      this.render().then(() => {
        this.layout.load();
        this.barIcon = $el.find(".bar-icon");
        this.lineIcon = $el.find(".line-icon");
        this.setChartIconState();
      });
    },
    setChartIconState: function () {
      const selectedClass = "selected";
      if (this.subSection === "daily") {
        this.barIcon.removeClass(selectedClass);
        this.lineIcon.addClass(selectedClass);
      } else {
        this.lineIcon.removeClass(selectedClass);
        this.barIcon.addClass(selectedClass);
      }
    },
    changeChartIconColor: function (event) {
      if (event) {
        $(event.currentTarget).addClass("selected");
      }
    },
    switchGraph: function (event) {
      let {
        subSection: subSection
      } = this;
      const {
        id: selectedSection
      } = event.currentTarget;
      event.stopPropagation();
      if (subSection !== selectedSection) {
        subSection = subSection === "daily" ? "summary" : "daily";
        T1Publish("select:chart", subSection);
        this.subSection = subSection;
        this.setChartIconState();
        T1Publish("switch:chart", subSection);
      }
    }
  });
});
