define('modules/creatives/display/views/createEditBulk',["jQuery", "Underscore", "T1", "T1Comm", "T1View", "T1Layout", "T1FileUpload", "T1Permissions", "collections/organizations", "text!../templates/createEditBulk.html", "text!../templates/createEditBulkBody.html", "text!../../video/templates/createEditWarning.html", "../models/mediaTypesBulk", "T1Alert", "T1Form"], function ($, _, T1, T1Comm, T1View, T1Layout, T1FileUpload, T1Permissions, Organizations, template, templateBody, templateWarning, mediaTypes, T1Alert) {
  var Bulk3PASCreateEdit;
  var creativesBulk3PAS = T1Permissions.check("feature", "creatives-bulk-3-pas");
  function updateBreadCrumb() {
    var pathPieces = [{
      label: "New Bulk 3PAS Ad Tag (Step 1 of 2)",
      location: "creatives/display-bulk"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  Bulk3PASCreateEdit = T1View.extend({
    template: template,
    partials: {
      warning: templateWarning
    },
    events: {
      "click .assets, .mobile, .verify_ads": "handleRadioClick",
      "click #dl-bulk-create-template": "downloadBulkCreateTemplate",
      "changed strand-dropdown": "handleAdserverSelect"
    },
    eventhubEvents: {
      "organization:explicitChange": function () {
        T1.Location.set("#creatives/displays");
      },
      "dropdown:advertisers": "handleAdvertiserSelect"
    },
    downloadBulkCreateTemplate: function () {
      var downloadUrl = `${COMPASS_ENV.BULK_UPLOAD_API_BASE}creatives/download?advertiser_id=${this.selectedAdvertiser.id}`;
      window.location.href = downloadUrl;
    },
    fileUploaderData: {
      inputFields: [],
      formSelector: "asset-upload-form",
      placeholder: creativesBulk3PAS ? "Select a file (.txt, .xls, .xlsx)" : "Select a file (i.e. .txt, .html, .xls, .xlsx)",
      accept: creativesBulk3PAS ? mediaTypes.restrictTypesBulk3PAS : mediaTypes.restrictTypes
    },
    alertMsgData: {
      invalidTagsMsg: {
        title: "Invalid Tags Message",
        bodyMsg: "No valid tags were found. Please double check your file contents and format.",
        buttons: [{
          text: "Try Again",
          class: "save",
          click: function () {
            var $dialog = $(this);
            var $input = $(".asset-upload .file-name .text-input");
            $input.val("");
            $dialog.dialog("close");
          }
        }, {
          text: "cancel",
          class: "cancel",
          click: function () {
            var $dialog = $(this);
            $dialog.dialog("close");
          }
        }],
        icon: "error-icon"
      },
      bulk3PASMsg: {
        title: "Upload Error",
        bodyMsg: "",
        buttons: [{
          text: "Try Again",
          class: "save",
          click: function () {
            T1.Location.set("#creatives/display-bulk");
            $(this).dialog("close");
          }
        }],
        icon: "error-icon"
      }
    },
    isFormElementsChanged: false,
    initialize: function () {
      var self = this;
      self.actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel",
          location: "#"
        }, {
          label: "Parse & Review",
          class: "save btn-primary ",
          location: "#"
        }]
      };
      this.initLayout();
    },
    handleRadioClick: function (e) {
      var self = this;
      var $target = $(e.currentTarget);
      var val = $target.val();
      var name = $(e.currentTarget).attr("name");
      switch (name) {
        case "assets":
          this.model.set({
            is_https: val
          });
          break;
        case "is_mraid":
          this.model.set({
            is_mraid: val
          });
          break;
        case "skip_ad_tag_validation":
          this.model.set({
            skip_ad_tag_validation: val
          });
          $(".inline-box-guidance").show();
          break;
      }
      this.isFormElementsChanged = true;
    },
    handleAdvertiserSelect: function (data) {
      this.model.set({
        advertiser_id: data.id
      });
      this.selectedAdvertiser = data.model;
      this.isFormElementsChanged = true;
      this.toggleTemplateDownloadButton(this.model.get("advertiser_id"), this.model.get("adServer"));
    },
    handleAdserverSelect: function (evt) {
      this.model.set({
        adServer: evt.target.value
      });
      this.selectedAdserver = evt.target.value;
      this.isFormElementsChanged = true;
      this.removeTooltip(evt);
      this.toggleTemplateDownloadButton(this.model.get("advertiser_id"), this.model.get("adServer"));
    },
    toggleTemplateDownloadButton(advertiserId, adServer) {
      if (creativesBulk3PAS && advertiserId && adServer === "3PAS") {
        $("#dl-bulk-create-template").removeAttr("disabled");
      } else {
        $("#dl-bulk-create-template").attr("disabled", true);
      }
    },
    initLayout: function () {
      var self = this;
      updateBreadCrumb();
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        self.orgId = orgId;
        T1Comm.get({
          sourceURL: `${COMPASS_ENV.BULK_UPLOAD_API_BASE}ad-servers`,
          dataType: "json",
          onSuccess: response => {
            self.loaded = true;
            self.adServers = response.data.adservers;
            self.load();
          },
          processAjaxResponse(response) {
            return {
              jsonData: response,
              statusCode: "ok"
            };
          }
        }).then(() => {
          self.layout = new T1Layout({
            el: function () {
              return self.el;
            },
            selectorPath: ".w-body",
            serialize: self.serialize,
            template: templateBody,
            layout: {
              ".advertiser-select": [{
                module: "shared",
                viewType: "advertiserSelect",
                options: {
                  entity: "advertisers",
                  dataBind: "advertiser_id",
                  boundEntity: "organizations",
                  boundEntityId: orgId,
                  isSearchable: true,
                  initialLoad: true,
                  placeholder: "Select an Advertiser",
                  preSelect: "Select an Advertiser",
                  notFound: "No Advertisers Found!",
                  refreshEvent: "refresh",
                  preRender: function () {},
                  defaultSortParams: {
                    fields: {
                      name: {
                        actual: "name",
                        display: "alphabetically"
                      }
                    },
                    default: {
                      sort_by: "name",
                      order_by: "ascending"
                    }
                  }
                }
              }]
            }
          });
        });
      }, self));
    },
    postData: function (formValues, successCallback, statusInvalid) {
      var self = this;
      var errors = [];
      var bypassedStatusCodes = [201, 206, 400, 401];
      if (!this.model.validateAdvertiserId()) {
        errors.push({
          field: "advertiser_id",
          message: "This is a required field."
        });
      }
      if (creativesBulk3PAS) {
        if (!this.model.get("is_https")) {
          this.model.set({
            is_https: "0"
          });
        }
        if (!this.model.get("skip_ad_tag_validation")) {
          this.model.set({
            skip_ad_tag_validation: "0"
          });
        }
        if (!this.selectedAdserver) {
          errors.push({
            field: "adserver",
            message: "This is a required field."
          });
        }
        if (!this.isFileSelected) {
          errors.push({
            field: "file-name",
            message: "This is a required field."
          });
        }
      }
      if (errors.length) {
        statusInvalid(errors, "There are errors on the page.", ".w-CreativesCreateEdit");
      } else if (creativesBulk3PAS) {
        this.fileUploader.upload({
          sourceURL: `${COMPASS_ENV.BULK_UPLOAD_API_BASE}creatives`,
          data: {
            advertiser_id: `${this.selectedAdvertiser.id}`,
            ad_server: `${this.selectedAdserver}`,
            file: $(".asset-upload .file-name .text-input").val()
          },
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: bypassedStatusCodes
          },
          processAjaxResponse: function (resp) {
            var responseText = resp || resp.responseText;
            var jsonData = JSON.parse(responseText);
            var statusCode = jsonData.meta && jsonData.meta.code;
            var status = bypassedStatusCodes.includes(statusCode) ? "error" : "ok";
            jsonData.status = status;
            return {
              jsonData: jsonData
            };
          },
          onError: function (resp) {
            var responseText = typeof resp === "String" ? resp : resp.responseText;
            var jsonData;
            var statusCode;
            try {
              jsonData = JSON.parse(responseText);
              statusCode = jsonData.meta && jsonData.meta.code;
              if (statusCode === 400 || statusCode === 401) {
                return;
              }
              var rejectedAdTags = jsonData.data.rejectedAdTags;
              var atomicCreativeIds = jsonData.data.atomicCreativeIds;
              var crCount = atomicCreativeIds && atomicCreativeIds.length;
              if (statusCode === 201 && !rejectedAdTags.length) {
                successCallback();
                T1.Notify("message", `${crCount} creative${crCount > 1 ? "s" : ""} uploaded successfully`);
                T1.Location.set("#creatives/displays");
              } else if (rejectedAdTags.length) {
                var data = {
                  batch: {
                    placements: {
                      valid: {},
                      invalid: {
                        placement: []
                      }
                    }
                  }
                };
                var invalidAdTags = data.batch.placements.invalid.placement;
                data.batch.total = data.batch.invalid = rejectedAdTags.length;
                for (const tag of rejectedAdTags) {
                  let errorClass = tag.errors.map(error => {
                    var columnName = error.match(/\"(\w+)\"/);
                    var cssValue = "";
                    columnName = columnName && columnName.length && columnName[1];
                    tag[columnName] = error;
                    if (columnName === "value") {
                      ["concept_is_new", "concept_name", "concept_id"].forEach(col => {
                        tag[col] = error;
                        cssValue += `${col}_error `.replace("value", col);
                      });
                    } else if (columnName) {
                      cssValue = `${columnName}_error`;
                    }
                    return cssValue;
                  }).join(" ");
                  if (!errorClass) {
                    self.alertMsgData.bulk3PASMsg.bodyMsg = tag.errors.toString().replace(",", ", ").split(".")[0];
                    self.displayAlert(self.alertMsgData.bulk3PASMsg);
                    break;
                  }
                  invalidAdTags.push({
                    row: tag.row,
                    ad_format: tag.ad_format,
                    ad_server_type: tag.ad_server_type,
                    click_url: tag.click_url,
                    concept_id: tag.concept_id,
                    concept_is_new: tag.concept_is_new,
                    concept_name: tag.concept_name,
                    end_date: tag.end_date,
                    errorClass: errorClass,
                    errors: tag.errors,
                    expansion_direction: tag.expansion_direction,
                    expansion_trigger: tag.expansion_trigger,
                    external_identifier: tag.external_identifier,
                    file_type: tag.file_type,
                    height: tag.height,
                    is_mraid: tag.is_mraid,
                    name: tag.name,
                    start_date: tag.start_date,
                    status: tag.status,
                    tag_type: tag.tag_type,
                    tag: tag.tag,
                    tpas_ad_tag_name: tag.tpas_ad_tag_name,
                    width: tag.width
                  });
                }
                self.model.setResultData(data);
                self.isFormElementsChanged = false;
                T1.Location.set("creatives/display-bulk-3pas-review");
              } else {
                self.alertMsgData.bulk3PASMsg.bodyMsg = meta.message.toString().replace(",", ", ");
                self.displayAlert(self.alertMsgData.bulk3PASMsg);
              }
            } catch (error) {}
            statusInvalid([]);
          },
          onStatusInvalid: function (req) {
            self.alertMsgData.bulk3PASMsg.bodyMsg = req.meta.message.toString().replace(",", ", ");
            self.displayAlert(self.alertMsgData.bulk3PASMsg);
            statusInvalid([]);
          }
        });
      } else {
        this.fileUploader.upload({
          sourceURL: `${T1.API_ROOT}creatives/upload`,
          data: {
            advertiser_id: `${this.selectedAdvertiser.id}`,
            file: $(".asset-upload .file-name .text-input").val(),
            skip_ad_tag_validation: this.model.get("skip_ad_tag_validation")
          },
          onSuccess: function (data) {
            successCallback();
            self.model.setResultData(data);
            T1.Location.set("creatives/display-bulk-review");
          },
          onError: function () {
            statusInvalid([]);
          },
          onStatusInvalid: function () {
            self.displayAlert(self.alertMsgData.invalidTagsMsg);
            statusInvalid([]);
          }
        });
      }
    },
    removeInputError: function () {
      var $input = $(".asset-upload .file-name .text-input", this.el);
      $input.parent().removeAttr("original-title");
      $input.removeClass("error");
    },
    clearFileValidation: function () {
      var $input = this.el.find(".file-name .text-input");
      if ($input.hasClass("error")) {
        $input.removeClass("error disabled");
        $input.removeAttr("disabled");
        this.removeTooltip({
          currentTarget: $input
        });
      }
    },
    onFileChanged: function (evt) {
      var self = this;
      self.isFileSelected = evt.target && evt.target.files && evt.target.files.length;
      self.removeInputError();
      self.clearFileValidation();
    },
    displayAlert: function (alertData) {
      var alertMsg = new T1Alert();
      alertMsg.initialize(alertData);
      alertMsg.show();
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      if (!self.loaded) return;
      this.resetModel();
      this.render().then(function () {
        var $fileNameInput, $fileSelector;
        var $advDDL = $(".advertiser-select", self.el);
        if (self.layout) {
          self.layout.load();
        }
        if (!self.fileUploader) {
          self.fileUploader = T1FileUpload.create({
            $el: $el.find(".asset-upload"),
            onChange: $.proxy(self.onFileChanged, self)
          });
          setTimeout(function () {
            $fileSelector = $el.find(".asset-upload .file-selector");
            $fileNameInput = $fileSelector.find(".file-name input");
            $fileNameInput.attr("data-bind", "file-name");
          }, 0);
        }
        if (self.fileUploader) {
          self.fileUploader.render(self.fileUploaderData);
        }
        if (!self.isEdit) {
          $advDDL.find("._wrapper").addClass("ad-wrapper");
          $(self.el).not('div[class="ad-wrapper"]').click(function () {
            var $chosenDDL = $advDDL.find("ul");
            if ($chosenDDL.hasClass("search-results")) {
              self.layout.layout[".advertiser-select"][0].view.reloadResults();
              $chosenDDL.removeClass("search-results");
            }
          });
        }
        if (creativesBulk3PAS) {
          $(".adserver-select")[0].data = self.adServers;
          $(".bulk-3pas-template").css("display", "block");
        }
      });
    },
    unload: function () {
      if (this.fileUploader) {
        this.fileUploader.destroy();
      }
    },
    canUnload: function (onTabChange) {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        self.openUnloadAlertDialog({
          name: "Add Bulk 3Pas Tag"
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgId;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    onDontSave: function () {
      this.resetModel();
    },
    resetModel: function () {
      this.model.reset();
    },
    serialize: function () {
      var output = {
        creativesBulk3PAS: creativesBulk3PAS
      };
      var warningMessage = this.uploadWarning && this.uploadWarning.warningMessage;
      output.uploadWarning = !warningMessage ? null : {
        show: true,
        warningMessage: warningMessage,
        cssClasses: "column-video video-upload-assets"
      };
      output.templateLink = `${T1.ENV.API_BASE}static/excel_templates/creatives_bulk_3pas.xlsx`;
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(Bulk3PASCreateEdit);
});
