define('modules/targeting/location/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "targeting/location",
    viewCfgs: {
      panel: {
        collection: null
      },
      browse: {
        collection: null
      },
      filter: {
        collection: null
      },
      bins: {
        collection: null
      },
      readOnly: {
        model: null
      }
    },
    getView: function (type, opts) {
      if (type === "panel" || type === "browse") {
        opts.strategyTargetDimensions = opts.strategy.fetchTargetDimensions();
      }
      if (type === "browse" && opts.strategyTargetDimensions) {
        opts.partialCollection = opts.strategyTargetDimensions.get("partial");
      }
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
