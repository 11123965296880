define('collections/budgetFlightCollection',["Underscore", "jQuery", "T1", "T1Collection", "models/budgetFlight"], function (_, $, T1, T1Collection, BudgetFlight) {
  T1.Models.BudgetFlightCollection = T1Collection.extend({
    model: BudgetFlight,
    urlString: "campaigns",
    action: "budget_flights",
    fixture: "-budgetFlights",
    cacheDuration: 100,
    url: function () {
      var originalString = this.urlString;
      var url, extraIndicator;
      if (this.id === undefined) {
        throw new Error("Campaign id needs to be set in id property of collection to construct URL");
      }
      if (this.requestMode === "fetch") {
        extraIndicator = "";
      } else {
        extraIndicator = "/bulk";
      }
      this.urlString = originalString + "/" + this.id + "/" + this.action + extraIndicator;
      this.isPaginated = false;
      delete this.pageLimit;
      delete this.page;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    validateData: function () {
      var errors = [];
      var itemId, budgetVal, impBudgetVal;
      var min = 1;
      var monetary = "Monetary ";
      function addToBudgetErrList(model, errMsg) {
        itemId = (model.id || model.cid) + "";
        errors.push({
          itemId: itemId,
          field: "budgetflight_total_budget_" + itemId,
          message: errMsg
        });
      }
      function addToImpErrList(model, errMsg) {
        itemId = (model.id || model.cid) + "";
        errors.push({
          itemId: itemId,
          field: "total_impression_budget_" + itemId,
          message: errMsg
        });
      }
      _.each(this.models, function (model) {
        budgetVal = parseFloat(model.get("total_budget"));
        if (isNaN(budgetVal) === true) {
          addToBudgetErrList(model, "This is a required field.");
        } else if (budgetVal < min) {
          addToBudgetErrList(model, monetary + "Budget must be greater than or equal to " + min + ".");
        }
        impBudgetVal = model.get("total_impression_budget");
        if (impBudgetVal && impBudgetVal < 1) {
          addToImpErrList(model, "Impression Budget must be greater than or equal to 1");
        }
      });
      return errors.length ? errors : undefined;
    },
    buildPostObj: function (timeZoneName, isNewCampaign) {
      var self = this;
      var resultObj = {};
      var models = self.models;
      var modelsDeleted = [];
      var modelsUpdated = [];
      var modelsNew = [];
      var orderedData = [];
      var i, indexVal, modelTmp, isDelete;
      var isFirstNewItem = true;
      self.zoneName = timeZoneName;
      if (models.length <= 0) {
        return resultObj;
      }
      function addToModelList(modelList, model, actionVal) {
        var jsonData = model.toJSON();
        if (jsonData.id === undefined) {
          jsonData.id = model.cid;
        }
        if (actionVal !== undefined) {
          jsonData.action = actionVal;
        }
        modelList.push(jsonData);
      }
      _.each(models, function (model) {
        if (model.get("action") === "delete") {
          addToModelList(modelsDeleted, model, "delete");
        } else if (model.get("action") === "new") {
          if (isFirstNewItem === true) {
            isFirstNewItem = false;
            if (isNewCampaign !== true) {
              addToModelList(modelsNew, model, "new");
            }
          } else {
            addToModelList(modelsNew, model, "new");
          }
        } else if (model.id && model.get("action") === "update") {
          addToModelList(modelsUpdated, model, "update");
        }
      });
      orderedData = modelsDeleted.concat(modelsUpdated).concat(modelsNew);
      for (i = 0; i < orderedData.length; i++) {
        modelTmp = orderedData[i];
        indexVal = i + 1;
        isDelete = modelTmp.action === "delete";
        if (modelTmp.action === "update" || isDelete === true) {
          resultObj["budget_flights." + indexVal + ".id"] = (isDelete === true ? "-" : "") + modelTmp.id;
          resultObj["budget_flights." + indexVal + ".version"] = modelTmp.version;
        }
        if (isDelete !== true) {
          resultObj["budget_flights." + indexVal + ".start_date"] = modelTmp.start_date + " " + self.zoneName;
          resultObj["budget_flights." + indexVal + ".end_date"] = modelTmp.end_date + " " + self.zoneName;
          resultObj["budget_flights." + indexVal + ".total_budget"] = modelTmp.total_budget;
          resultObj["budget_flights." + indexVal + ".total_impression_budget"] = modelTmp.total_impression_budget ? modelTmp.total_impression_budget : "";
        }
      }
      return resultObj;
    },
    updateVersionForDataBlock: function (dataBlock) {
      var self = this;
      var deferred = $.Deferred();
      var keys = _.keys(dataBlock);
      var dataLength = parseInt(keys[keys.length - 1].split(".")[1]);
      var i, modelId, idItem;
      self.requestMode = "fetch";
      self.fetchOptions = {
        full: "*"
      };
      self.fetch({
        success: function (data) {
          for (i = 1; i <= dataLength; i++) {
            _.each(data.models, function (model) {
              modelId = model.id + "";
              idItem = dataBlock["budget_flights." + i + ".id"];
              if (idItem !== undefined && modelId === idItem) {
                if (dataBlock["budget_flights." + i + ".version"] !== undefined) {
                  dataBlock["budget_flights." + i + ".version"] = model.get("version");
                }
              }
            });
          }
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    refreshCollection: function () {
      var self = this;
      var deferred = $.Deferred();
      self.requestMode = "fetch";
      self.fetchOptions = {
        full: "*"
      };
      self.fetch({
        success: function () {
          $.each(self.models, function (i, model) {
            model.set({
              action: ""
            }, {
              silent: true
            });
          });
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    saveBudgetFlights: function (campaignId, postData, successCallBack, conflictCallback, errorCallBack, invalidStatusCallback) {
      var self = this;
      var dataAttrObj = postData || {};
      self.id = campaignId;
      function triggerSuccessCallback(data) {
        if (successCallBack && $.isFunction(successCallBack)) {
          successCallBack(data);
        }
      }
      function successHandler(data) {
        self.refreshCollection().then(function () {
          triggerSuccessCallback(data);
        });
      }
      function invalidStatusHandler(data) {
        invalidStatusCallback(data);
      }
      if (_.isEmpty(dataAttrObj)) {
        self.refreshCollection().then(function () {
          triggerSuccessCallback();
        });
      } else {
        self.updateVersionForDataBlock(dataAttrObj).then(function () {
          self.requestMode = "save";
          delete self.fetchOptions;
          self.save({
            data: dataAttrObj
          }, {
            success: successHandler,
            conflict: conflictCallback,
            error: errorCallBack,
            onStatusInvalid: invalidStatusHandler
          });
        });
      }
    }
  });
  return T1.Models.BudgetFlightCollection;
});
