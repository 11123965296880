define('modules/reporting/campaigns/dataExport/manage/views/header',["jQuery", "Underscore", "Hogan", "T1", "T1View", "text!../templates/header.html"], function ($, _, Hogan, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "changed .status-ddl strand-dropdown": "changeStatus",
      "click .btn-add-report strand-button": "loadCreate"
    },
    eventhubEvents: {
      "manage.toggleBtnAddReport": "toggleBtnAddReport"
    },
    initialize: function (args) {
      this.DataExportModel = args.dataExportModel;
    },
    load: function () {
      const $el = this.el;
      this.render().then(() => {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        this.$btnAddReport = $el.find(".btn-add-report strand-button");
        this.setAddBtnState();
      });
    },
    unload: function () {
      this.$btnAddReport = null;
    },
    changeStatus: function (event) {
      const status = event.currentTarget.value;
      this.DataExportModel.setManageGridStatus(status);
      T1Publish("header.changeStatus", status);
    },
    loadCreate: function () {
      T1Publish("header.createReport");
    },
    setAddBtnState: function () {
      var savedReports = this.DataExportModel.get("savedReports");
      var downloadProgress = _.some(savedReports, function (report) {
        return report.progress === true;
      });
      if (!downloadProgress) {
        window.localStorage.removeItem("reportDownloadProgress");
      }
      this.$btnAddReport[0].disabled = downloadProgress;
    },
    toggleBtnAddReport: function (state) {
      if (window.localStorage.getItem("reportDownloadProgress")) {
        this.$btnAddReport[0].disabled = true;
      } else {
        this.$btnAddReport[0].disabled = !state;
      }
    },
    serialize: function () {
      return {
        status: this.DataExportModel.get("manageGridStatus")
      };
    }
  });
});
