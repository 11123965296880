define('modules/version/views/info',["jQuery", "T1", "T1View", "T1Versions", "T1PicardVersion", "text!modules/version/templates/info.html"], function ($, T1, T1View, T1Versions, T1PicardVersion, template) {
  return T1View.extend({
    displayStrings: ["TerminalOne v", "UI Build: ", "API ", "API Build: ", "Reports API ", "Reports API Build: "],
    current_version: "",
    currentVersionIdx: 0,
    template: template,
    pause: function () {
      this.load();
    },
    resyncVersions: function () {
      this.versions = [this.model.get("compass_version"), this.model.get("compass_build"), this.model.get("api_release"), this.model.get("api_build"), this.options.picardModel.get("picard_release"), this.options.picardModel.get("picard_build")];
    },
    initialize: function (args) {
      this.model.bind("reset", this.resyncVersions);
      if (args) {
        this.onCloseCallback = args.onCloseCallback || $.noop;
      }
      this.resyncVersions();
      this.updateCurrentVersionString();
    },
    repaintData: function () {
      var target = $(this.el).find(".version-info");
      var dataset;
      dataset = this.serialize();
      this.updateDataBind(dataset, target);
    },
    events: {
      "click .version-info": "switchVersion"
    },
    updateCurrentVersionString: function () {
      this.current_version = this.displayStrings[this.currentVersionIdx] + this.versions[this.currentVersionIdx];
    },
    switchVersion: function (e) {
      var idx;
      this.resyncVersions();
      idx = this.currentVersionIdx;
      idx = (idx + 1) % this.versions.length;
      this.currentVersionIdx = idx;
      this.updateCurrentVersionString();
      this.repaintData();
    },
    serialize: function () {
      return {
        current_version: this.current_version
      };
    }
  });
});
