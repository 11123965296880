define('collections/creativesConcept',["jQuery", "T1", "T1Collection", "T1Spinner", "moment", "models/concept", "models/creativesStrategy", "collections/creatives", "collections/creativesConceptsStrategies", "collections/organizations", "T1Sortable"], function ($, T1, T1Collection, Spinner, moment, Concept, Strategy, Creatives, StrategyConcepts, Organizations) {
  T1.Models.Concepts = T1Collection.extend({
    cors: COMPASS_ENV.VIDEO_API_BASE + "/creatives/proxy/?url=",
    model: Concept,
    ajx: {},
    countingFinished: {},
    mappingFinished: {},
    creativeCount: {},
    searchConfiguration: {
      isFetchSilent: false,
      fields: {
        name: {
          type: "string",
          useWildCard: true
        },
        id: {
          type: "enum"
        },
        advertiser_id: {
          type: "exact"
        },
        status: {
          type: "exact"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: false
      }, {
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: true
      }]
    },
    fetchOptions: {
      sort_by: "-updated_on",
      full: "*",
      with: "advertiser"
    },
    spinnerOpts: {
      lines: 14,
      length: 3,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 10,
      speed: 1
    },
    updateOrgFilter: function () {
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.urlFilter.set({
          entity: "organization",
          id: orgId
        });
      }, this));
    },
    setAdvertiser: function (advID) {
      this.urlFilter.set({
        entity: "advertiser",
        id: advID
      });
    },
    countCreatives: function (conceptIDs, loaderArray) {
      var self = this;
      this.countingFinished = {};
      for (var i = 0; i < conceptIDs.length; i++) {
        this.creativeCount[conceptIDs[i]] = new Creatives(null, {
          pageLimit: 1
        });
        this.creativeCount[conceptIDs[i]].canCache = false;
        this.creativeCount[conceptIDs[i]].urlFilter.set({
          entity: "concept",
          id: "(" + conceptIDs[i] + ")"
        });
        this.creativeCount[conceptIDs[i]].thisID = conceptIDs[i];
        this.creativeCount[conceptIDs[i]].fetch({
          params: {
            with: "concept"
          },
          success: function (data) {
            if (data.count === 0) {
              $("#act" + data.thisID).html("0");
              $("#head" + data.thisID).html("No Creatives Assigned").css("text-align", "center").addClass("creative-header");
              $("#more" + data.thisID).remove();
              self.countingFinished[data.thisID] = true;
              if (self.mappingFinished[data.thisID] === true) {
                self.stopLoader(loaderArray, data.thisID);
              }
            }
            data.each(function (model) {
              var conceptID = model.get("concept").id;
              $("#act" + conceptID).html(data.count);
              self.countingFinished[conceptID] = true;
              if (self.mappingFinished[conceptID] === true) {
                self.stopLoader(loaderArray, conceptID);
              }
            });
          }
        });
      }
    },
    stopLoader: function (loaderArray, ID) {
      var result = loaderArray.find(function (e) {
        return e.id === ID;
      });
      var $parent = $("#id" + ID);
      result.loader.spin(false);
      $parent.find(".icon-arrow").removeAttr("style").show();
      $parent.find(".icon-spinner").hide();
    },
    abortCountCalls: function () {
      $.each(this.creativeCount, function (index, value) {
        if (value.currentFetch) {
          value.currentFetch.abort();
        }
      });
    },
    mapStrategy: function (conceptIDs, loaderArray, isList, highlightValue) {
      var self = this;
      var conceptToStrategy = new StrategyConcepts(null, {
        pageLimit: 100
      });
      var theStratIDs = [];
      var matchedStrat = {};
      var activeStrat = {};
      var stratIDs = {};
      this.mappingFinished = {};
      conceptToStrategy.urlFilter.set({
        entity: "concept",
        id: "(" + conceptIDs + ")"
      });
      conceptToStrategy.fetch({
        success: function (data) {
          var that = this;
          this.CampaignName = {};
          this.CampaignID = {};
          this.CampaignStatus = {};
          data.each(function (model) {
            var ID = model.get("concept").id;
            var StratName = model.get("strategy").name;
            var StratID = model.get("strategy").id;
            var StratStatus = model.get("strategy").status;
            that.CampaignName[ID] = model.get("strategy").campaign.name;
            that.CampaignID[ID] = model.get("strategy").campaign.id;
            that.CampaignStatus[ID] = model.get("strategy").campaign.status;
            theStratIDs.push(model.get("strategy").id);
            if (matchedStrat[ID] && StratStatus === "1") {
              matchedStrat[ID].push(StratName + " (" + StratID + ")");
            } else if (StratStatus === "1") {
              matchedStrat[ID] = [StratName + " (" + StratID + ")"];
            }
            if (stratIDs[ID]) {
              stratIDs[ID].push(StratID);
              activeStrat[ID] = activeStrat[ID] + StratStatus;
            } else {
              stratIDs[ID] = [StratID];
              activeStrat[ID] = StratStatus;
            }
          });
          if (isList) {
            for (var i = 0; i < conceptIDs.length; i++) {
              var campaignNameValue = this.CampaignName[conceptIDs[i]];
              var campaignIDValue = this.CampaignID[conceptIDs[i]];
              if (campaignNameValue && highlightValue) {
                campaignNameValue = T1.Utils.highlightText(campaignNameValue, highlightValue);
              }
              if (campaignIDValue && highlightValue) {
                campaignIDValue = T1.Utils.highlightText(campaignIDValue, highlightValue);
              }
              if (matchedStrat[conceptIDs[i]] && activeStrat[conceptIDs[i]] >= 1) {
                var strategies = String(matchedStrat[conceptIDs[i]].join(", "));
                $("#st" + conceptIDs[i]).prop("title", strategies);
                if (strategies.length > 185) {
                  strategies = $.trim(strategies).substring(0, 185).split(" ").slice(0, -1).join(" ") + "...";
                }
                if (strategies && highlightValue) {
                  strategies = T1.Utils.highlightText(strategies, highlightValue);
                }
                $("#st" + conceptIDs[i]).html(strategies);
                if (this.CampaignStatus[conceptIDs[i]] === "1") {
                  $("#cmp" + conceptIDs[i]).html(campaignNameValue + " (" + campaignIDValue + ")");
                } else {
                  $("#cmp" + conceptIDs[i]).html("<em>No Active Campaigns</em>");
                }
              } else if (stratIDs[conceptIDs[i]] && activeStrat[conceptIDs[i]] < 1) {
                if (this.CampaignStatus[conceptIDs[i]] === "1") {
                  $("#cmp" + conceptIDs[i]).html(campaignNameValue + " (" + campaignIDValue + ")");
                } else {
                  $("#cmp" + conceptIDs[i]).html("<em>No Active Campaigns</em>");
                }
                $("#st" + conceptIDs[i]).html("<em>No Active Strategies</em>");
              } else {
                $("#st" + conceptIDs[i]).html("--");
                $("#cmp" + conceptIDs[i]).html("--");
              }
              self.mappingFinished[conceptIDs[i]] = true;
              if (self.countingFinished[conceptIDs[i]] === true) {
                self.stopLoader(loaderArray, conceptIDs[i]);
              }
            }
          } else {
            if (stratIDs[conceptIDs[0]]) {
              self.mapCampaigns(conceptIDs[0], stratIDs[conceptIDs[0]][0]);
            }
          }
        }
      });
    },
    mapCampaigns: function (conceptID, stratID) {
      var theStrategy = new Strategy({
        id: stratID
      });
      theStrategy.fetch({
        params: {
          full: "campaign",
          with: ["campaign"]
        },
        success: function (data) {
          if (data.get("campaign").status === "1") {
            $(".campaign_name").html(data.get("campaign").name);
          } else {
            $(".campaign_name").html(data.get("campaign").name + " <em>(Inactive)</em>");
          }
          $(".campaignID").html(data.get("campaign").id);
        }
      });
    },
    getDateDisplay: function (rawVal) {
      var tmpDt = rawVal ? Date.parse(rawVal) : null;
      if (tmpDt) {
        return tmpDt.toString("MM/dd/yy");
      } else {
        return rawVal;
      }
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    toggleAllSelected: function (isSelected) {
      this.each(function (model) {
        model.set({
          selected: isSelected
        });
      });
    },
    urlString: "concepts"
  });
  return T1.Models.Concepts;
});
