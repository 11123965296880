define('modules/targeting/siteList/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!modules/targeting/siteList/templates/bin_header.html", "text!modules/targeting/siteList/templates/bin_title.html", "text!modules/targeting/siteList/templates/bin_group_name.html", "text!modules/targeting/siteList/templates/bin_item.html"], function ($, _, T1, BinsView, bin_header, bin_title, bin_group_name, bin_item) {
  "use strict";

  return BinsView.extend({
    type: "SiteList",
    configsOverride: true,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: false,
        count: 0,
        use_or: true
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      bin_item: bin_item
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDroppable: true,
      checkExistenceSharedCollection: false,
      onDropEvent: "bins:droppedSiteList"
    },
    initialize: function ({
      strategy: strategy
    }) {
      this.strategy = strategy;
      this.domainRestrictions = strategy.domainRestrictions;
    },
    events: {
      "click .additional-sites": "showAdditionalSitesForm"
    },
    dataEvents: {
      domainRestrictions: {
        reset: "load"
      }
    },
    load: function () {
      var $el = $(this.el);
      this.render().then(function () {
        $el.trigger("binsContent:loaded");
        T1.EventHub.publish("strategyTargetingLoader:stop");
      });
    },
    unload: function () {
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    updateBins: function () {},
    removeItem: function (event) {
      var id = $(event.currentTarget).closest(".item").data("id");
      event.preventDefault();
      event.stopPropagation();
      this.unset(id);
    },
    unset: function (id) {
      var sharedCollection = this.sharedCollection;
      var item = sharedCollection.get(id);
      sharedCollection.remove(id);
      item.set({
        assigned: "0"
      });
      sharedCollection.add(item);
    },
    mapItems: function (items) {
      let mappedItem;
      var result = [];
      if (!items || typeof items !== "object") {
        throw new Error("mapItems requires an input object");
      }
      const modelArray = items;
      for (let i = 0; i < modelArray.length; i++) {
        const model = modelArray[i];
        const isActive = model.get("status") === "1";
        mappedItem = {};
        if (model.get("assigned") === "1" && isActive) {
          mappedItem.id = model.get("id");
          mappedItem.isAllowList = model.get("restriction") === "INCLUDE";
          mappedItem.isBlockList = model.get("restriction") === "EXCLUDE";
          mappedItem.assignedToCampaign = model.get("campaign_assigned") === "1";
          mappedItem.full_path = model.get("name");
          mappedItem.full_path_end = this.getPath(mappedItem.full_path);
          result.push(mappedItem);
        }
      }
      if (this.domainRestrictions && this.domainRestrictions.data.count > 0) {
        mappedItem = {};
        mappedItem.id = "";
        mappedItem.isAllowList = this.domainRestrictions.data.isIncluded;
        mappedItem.isBlockList = !mappedItem.isAllowList;
        mappedItem.assignedToCampaign = false;
        mappedItem.full_path = "additionalSites";
        mappedItem.full_path_end = "Additional Sites";
        mappedItem.additionalSites = true;
        result.push(mappedItem);
      }
      return result;
    },
    getPath: function (fullPath) {
      var path = fullPath;
      var goalLength = 30;
      return path.length > goalLength ? `...${path.substr(-goalLength)}` : path;
    },
    showAdditionalSitesForm: function (e) {
      var id = $(".column-A").find("._wrapper").attr("id");
      T1.EventHub.publish(`additionalSitesForm:show-${id}`, e);
    }
  });
});
