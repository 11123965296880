define('modules/reporting/campaigns/views/campaign_filter_list',["jQuery", "T1", "T1FilterList", "When", "collections/searchResults"], function ($, T1, T1FilterList, when, SearchResults) {
  "use strict";

  var CampaignFilterList = T1FilterList.extend({
    dataEvents: {
      mainModel: {
        "change:selectedAdvertiser": "updateFetchParams",
        "change:tempAdvertiser": "updateFetchParams"
      }
    },
    initialize: function (args) {
      var models;
      this.selectedAdvertiserID = args.selectedAdvertiserID;
      this.fetchParams = {
        fetchOptions: {
          sort_by: "-status,name",
          full: "*"
        },
        filterConfiguration: {
          filters: [{
            entity: "advertiser",
            fetchOnChange: true
          }],
          enableEvents: true
        },
        pageLimit: 100,
        urlString: "campaigns",
        urlFilter: {
          entity: "advertiser",
          id: this.selectedAdvertiserID
        }
      };
      this.showOnInit = args.showOnInit;
      this.models = models = args.models;
      this.mainModel = models.mainModel;
      this.collection = new SearchResults(null, this.fetchParams);
    },
    load: function () {
      var $el = this.el;
      if (this.loaded || !this.selectedAdvertiserID) {
        return;
      }
      if (!this.showOnInit) {
        $el.hide();
      }
      this.loaded = true;
      this.render().then(() => {
        $el.trigger("initFilterList");
        this.initializeUI();
        this.fetchCampaigns(this.collection).then(() => {
          this.updateList();
        });
      });
    },
    unload: function () {
      if (this.dataEvents) {
        this.dataEvents = null;
        this.mainModel.setCampaignItems(null);
        this.mainModel.setFilteredCampaignItems(null);
        this.fetchParams = null;
        this.showOnInit = null;
        this.models = null;
        this.mainModel = null;
        this.collection = null;
        this.allSelected = null;
      }
    },
    fetchCampaigns: function (collection) {
      var self = this;
      var dataLoadPromise = when.defer();
      var dataFormatPromise = when.defer();
      var deferreds = [dataLoadPromise.promise, dataFormatPromise.promise];
      collection.isPaginated = false;
      collection.fetch({
        success: function (response) {
          self.selectFilterListView.loadingCampaigns = false;
          dataLoadPromise.resolve();
          self.formatData(response, dataFormatPromise);
        },
        error: function (error) {
          dataLoadPromise.reject(new Error(`Could not fetch Campaigns. ${error}`));
        }
      });
      this.selectFilterListView.campaignsColl = collection;
      this.selectFilterListView.loadingCampaigns = true;
      return when.all(deferreds);
    },
    formatData: function (response, dataFormatPromise) {
      if (!this.mainModel) {
        return;
      }
      let campaign, campaignCurrencyCode, campaignId, campaignName, item, length, reportIdArr;
      const mainModel = this.mainModel;
      const reportId = mainModel.get("reportId");
      const campaigns = response.models;
      let filteredCampaignItems = mainModel.get("filteredCampaignItems");
      let filteredCampaignItemsNeedsSet = false;
      const list = [];
      const lookup = {};
      let i = 0;
      if (!filteredCampaignItems) {
        filteredCampaignItems = {};
        if (reportId) {
          filteredCampaignItemsNeedsSet = true;
          reportIdArr = reportId.split(",");
          length = reportIdArr.length;
          while (i < length) {
            filteredCampaignItems[reportIdArr[i++]] = true;
          }
        }
      }
      length = campaigns.length;
      i = 0;
      while (i < length) {
        campaign = campaigns[i++];
        campaignId = campaign.get("id");
        campaignName = campaign.get("name");
        campaignCurrencyCode = campaign.get("currency_code");
        item = {
          id: campaignId,
          name: campaignName,
          origText: campaignName,
          searchKey: campaignName,
          selected: filteredCampaignItems[campaignId] === true,
          currencyCode: campaignCurrencyCode
        };
        if (!filteredCampaignItems[campaignId]) {
          filteredCampaignItems[campaignId] = false;
        }
        list.push(item);
        lookup[campaignId] = item;
      }
      mainModel.setCampaignItems(list, lookup);
      if (filteredCampaignItemsNeedsSet) {
        mainModel.setFilteredCampaignItems(filteredCampaignItems);
      }
      dataFormatPromise.resolve();
    },
    updateFetchParams: function (args, param) {
      var self = this;
      if (this.fetchParams) {
        this.fetchParams.urlFilter.id = param.id;
        this.collection = new SearchResults(null, this.fetchParams);
        this.fetchCampaigns(this.collection).then(function () {
          var temp = self.selectFilterListView.inTemporaryListMode();
          var searchInput = self.searchInput;
          var searchTerm = searchInput ? searchInput.data("searchTerm") || "" : "";
          self.updateList();
          if (!temp && searchTerm !== "") {
            searchInput.val(searchTerm);
            self.search();
          }
        });
      }
    },
    updateList: function () {
      var mainModel = this.mainModel;
      var campaignItems = mainModel.get("campaignItems");
      var filteredCampaignItems = mainModel.get("filteredCampaignItems");
      var filteredCampaignItemsInitNeeded = false;
      var selectedCount = 0;
      var isSelected;
      var length = campaignItems && campaignItems.length || 0;
      var i = 0;
      var temp = this.selectFilterListView.inTemporaryListMode();
      if (campaignItems) {
        if (!filteredCampaignItems) {
          filteredCampaignItemsInitNeeded = true;
          filteredCampaignItems = {};
        }
        while (i < length) {
          if (temp) {
            isSelected = false;
          } else {
            if (filteredCampaignItemsInitNeeded) {
              filteredCampaignItems[campaignItems[i].id] = false;
              isSelected = false;
            } else {
              isSelected = filteredCampaignItems[campaignItems[i].id];
            }
          }
          campaignItems[i].selected = isSelected;
          if (isSelected) {
            selectedCount++;
          }
          i++;
        }
        this.allSelected = selectedCount === length;
        this.setItems(campaignItems);
        T1FilterList.prototype.updateList.call(this);
        this.updateSearch();
      }
    },
    updateSearch: function () {
      var temp = this.selectFilterListView.inTemporaryListMode();
      var searchInput = this.searchInput;
      var searchTerm = searchInput ? searchInput.data("searchTerm") || "" : "";
      if (!temp && searchTerm !== "") {
        searchInput.val(searchTerm);
        this.search();
      }
    },
    getEmptyMessage: function () {
      return "No campaigns found";
    },
    serialize: function () {
      return {
        all_text: "All Campaigns"
      };
    }
  });
  return CampaignFilterList;
});
