define('modules/campaign/views/helpers/budgetFlightsShared',["Underscore", "jQuery", "T1", "T1Permissions", "utils/CampaignStrategyUtils"], function (_, $, T1, T1Permissions, CampaignStrategyUtils) {
  "use strict";

  var formatCurrencyNoSymbol = T1.Utils.formatCurrencyNoSymbol;
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  var hasEntityStarted = CampaignStrategyUtils.hasEntityStarted;
  var hasEntityEnded = CampaignStrategyUtils.hasEntityEnded;
  var makeDateLabel = CampaignStrategyUtils.getDisplayTextForStartAndEndDates;
  var displayDateFormat = "MMMM d, yyyy";
  var displayTimeFormat = "h:mm tt";
  var startBuffer = 7;
  var endBuffer = 30;
  var dateNowOfUserTimeZone = new Date();
  const BudgetFlightsShared = function BudgetFlightsHelper(collection, currencyGetter) {
    var budgetFlightCollection = collection;
    var currencyCodeGetter = currencyGetter && $.isFunction(currencyGetter) ? currencyGetter : function () {
      return "";
    };
    function getCPEIODetails(model, cpeioDetails) {
      let cpeVal = "--";
      let ioNumber = "--";
      let prismaCampaignId = "--";
      const cpeIOObj = cpeioDetails.line_items.find(cpeioItem => cpeioItem.t1_budget_flight_id.toString() === model.get("id"));
      if (cpeIOObj) {
        cpeVal = cpeIOObj.client_product_estimate || "--";
        ioNumber = cpeIOObj.io_number || "--";
        prismaCampaignId = cpeIOObj.campaign_id || "--";
      }
      return {
        cpe: cpeVal,
        io: ioNumber,
        campaignId: prismaCampaignId
      };
    }
    function mapItem(model, cpeioDetails) {
      var startDateText = model.get("start_date");
      var endDateText = model.get("end_date");
      var startDateObj = Date.parse(startDateText);
      var endDateObj = Date.parse(endDateText);
      var currencyCode = currencyCodeGetter();
      var amountVal = model.get("total_budget");
      var impAmountVal = model.get("total_impression_budget");
      const isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(model.get("campaign"));
      const resultJson = model.toJSON();
      resultJson.itemid = model.id || model.cid;
      resultJson.startDateObj = startDateObj;
      resultJson.endDateObj = endDateObj;
      resultJson.startEndDates = makeDateLabel({
        startDate: startDateText,
        endDate: endDateText,
        dateFormat: displayDateFormat,
        timeFormat: displayTimeFormat
      });
      resultJson.amount = amountVal;
      resultJson.impAmount = impAmountVal;
      resultJson.amountFormatted = formatCurrencyNoSymbol(amountVal);
      resultJson.impAmountFormatted = impAmountVal ? impAmountVal.replace(/(\d)(?=(\d{3})+$)/g, "$1,") : "";
      resultJson.isAmountEditable = resultJson.budget_state === "notstarted" || resultJson.budget_state === "running" || resultJson.budget_state === "ended";
      resultJson.isBudgetEnded = resultJson.budget_state === "ended";
      resultJson.isDateEditable = true;
      resultJson.isCurrentFlight = resultJson.budget_state === "running";
      resultJson.hideDeleteCmd = resultJson.budget_state === "running" || resultJson.budget_state === "ended" || resultJson.isLast === true || isProgrammaticGuaranteed;
      resultJson.hideChartIcon = needToHideChartIcon(resultJson);
      resultJson.currencySymbol = getCurrencySymbol(currencyCode);
      if (cpeioDetails && cpeioDetails.line_items && cpeioDetails.line_items.length) {
        const {
          cpe: cpe,
          io: io,
          campaignId: campaignId
        } = getCPEIODetails(model, cpeioDetails);
        resultJson.cpe = cpe;
        resultJson.io = io;
        resultJson.campaignId = campaignId;
      } else {
        resultJson.cpe = "--";
        resultJson.io = "--";
        resultJson.campaignId = "--";
      }
      return resultJson;
    }
    function needToHideChartIcon({
      budget_state: budget_state,
      startDateObj: startDateObj
    }) {
      var needToHideIcon = false;
      var cannotAccessReporting = T1Permissions.check("feature", "reporting_hide");
      if (budget_state === "notstarted") {
        needToHideIcon = true;
      } else {
        const dateForComparison = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), startDateObj.getDate(), 1, 1, 1);
        const dayInMilliSecs = 864e5;
        const numOfDaysInBetween = (dateNowOfUserTimeZone.getTime() - dateForComparison.getTime()) / dayInMilliSecs;
        if (numOfDaysInBetween < 1) {
          needToHideIcon = true;
        }
      }
      if (cannotAccessReporting) {
        needToHideIcon = true;
      }
      return needToHideIcon;
    }
    function createModel(startDt) {
      var startDate = startDt || new Date();
      const fullDay = 23;
      const fullHrs = 59;
      var endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), fullDay, fullHrs, 0);
      var model = new budgetFlightCollection.model({
        action: "new"
      });
      if (!startDt) {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
        startDate.addDays(startBuffer);
      }
      endDate.addDays(endBuffer);
      model.set({
        start_date: startDate.toString("s"),
        end_date: endDate.toString("s"),
        total_budget: "",
        impression_cap_amount: ""
      }, {
        silent: true
      });
      return model;
    }
    function doSerialize(today, showCurrentAndUpcomingOnly, canSetCurrency, doNotAddStarterItem, cpeioDetails) {
      let itemJson, lastItem;
      var listItems = [];
      var coll = budgetFlightCollection;
      var hasExistingItem = false;
      var dtToday = today || new Date();
      var hasCampaignBudgetStarted = false;
      var editableItemCount = 0;
      var endedItemCount = 0;
      var currentAndFutureItemCount = 0;
      var isSpecialMsgSingleRow = false;
      var showCurrentAndUpcomingCheckbox = true;
      $.each(coll.models, function (index, model) {
        if (model.get("action") !== "delete") {
          model.set({
            budget_state: ""
          }, {
            silent: true
          });
          const hasStarted = hasEntityStarted(model, dtToday);
          const hasEnded = hasEntityEnded(model, dtToday);
          if (hasStarted === true) {
            hasCampaignBudgetStarted = true;
          }
          if (hasStarted === true && hasEnded !== true) {
            model.set({
              budget_state: "running"
            }, {
              silent: true
            });
            editableItemCount++;
            currentAndFutureItemCount++;
          } else if (hasEnded === true) {
            model.set({
              budget_state: "ended"
            }, {
              silent: true
            });
            endedItemCount++;
            editableItemCount++;
          } else {
            model.set({
              budget_state: "notstarted"
            }, {
              silent: true
            });
            editableItemCount++;
            currentAndFutureItemCount++;
          }
          itemJson = mapItem(model, cpeioDetails);
          listItems.push(itemJson);
          hasExistingItem = true;
        }
      });
      if (doNotAddStarterItem !== true && hasExistingItem === false) {
        const modelNew = createModel();
        modelNew.set({
          budget_state: "notstarted"
        }, {
          silent: true
        });
        editableItemCount++;
        currentAndFutureItemCount++;
        coll.add(modelNew);
        itemJson = mapItem(modelNew);
        itemJson.hideDeleteCmd = true;
        listItems.push(itemJson);
      }
      listItems.sort(function (a, b) {
        if (a.startDateObj > b.startDateObj) {
          return 1;
        }
        if (a.startDateObj < b.startDateObj) {
          return -1;
        }
        return 0;
      });
      if (showCurrentAndUpcomingOnly === true && listItems.length > 1) {
        listItems = _.filter(listItems, item => item.isBudgetEnded !== true);
      }
      if (listItems.length) {
        lastItem = listItems[listItems.length - 1];
        if (editableItemCount === 1 && lastItem.budget_state !== "notstarted") {
          lastItem.hideDeleteCmd = true;
        }
        if (listItems.length === 1) {
          lastItem.isSingleRow = true;
        }
        lastItem.isLast = true;
      }
      if (hasExistingItem === true && endedItemCount > 0 && editableItemCount <= 0) {
        if (lastItem) {
          lastItem.isLast = false;
          lastItem.isSingleRow = false;
        } else {
          isSpecialMsgSingleRow = true;
        }
        listItems.push({
          itemid: "specialMsgRow",
          isDateEditable: false,
          startEndDates: "There are no current or upcoming flights saved on campaign",
          isSpecialMessage: true,
          isLast: true,
          isSingleRow: isSpecialMsgSingleRow
        });
      }
      if (hasExistingItem === true && endedItemCount > 0 && currentAndFutureItemCount <= 0) {
        if (lastItem) {
          lastItem.isLast = true;
          lastItem.isSingleRow = true;
          showCurrentAndUpcomingCheckbox = false;
        }
      }
      if (showCurrentAndUpcomingOnly === true && listItems.length === 1 && hasExistingItem === true && endedItemCount > 0 && currentAndFutureItemCount <= 0) {
        if (lastItem) {
          lastItem.isLast = true;
          lastItem.isSingleRow = true;
          showCurrentAndUpcomingCheckbox = false;
        }
      }
      return {
        budgetFlightItems: listItems,
        hasCampaignBudgetStarted: hasCampaignBudgetStarted,
        allowSetCurrency: canSetCurrency === true && hasCampaignBudgetStarted !== true,
        showCurrentAndFutureOnly: showCurrentAndUpcomingOnly,
        checkboxVisibleForShowCurrent: endedItemCount > 0,
        showBudgetHeader: listItems.length === 1 && listItems[0].isLast === true && listItems[0].isSpecialMessage === true,
        showCurrentAndUpcomingCheckbox: showCurrentAndUpcomingCheckbox
      };
    }
    return {
      serializeData: doSerialize,
      createModel: createModel,
      setCurrentDateOfUserTimeZone: function (dateObj) {
        dateNowOfUserTimeZone = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 1, 1, 1);
      },
      needToHideChartIcon: needToHideChartIcon
    };
  };
  return BudgetFlightsShared;
});
