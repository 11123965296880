define('modules/reporting/campaigns/siteTransparency/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "siteTransparency",
    name: "reporting/campaigns/siteTransparency",
    viewCfgs: {
      addToSiteList: {
        model: null
      },
      chartPanel: {
        model: null
      },
      chartTitle: {
        model: null
      },
      columnSelector: {
        model: null
      },
      controls: {
        model: null
      },
      grid: {
        model: null
      },
      panel: {
        model: null
      },
      siteTransparency: {},
      siteTransparencyChart: {
        model: null
      },
      stLineChart: {
        model: null
      },
      stScatterChart: {
        model: null
      }
    }
  });
});
