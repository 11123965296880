define('modules/campaign/views/duplicateCampaignSettings',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Timezones", "text!modules/campaign/templates/duplicateCampaignSettings.html", "utils/CampaignStrategyUtils", "models/advertiser", "models/agency", "collections/campaigns", "models/organization", "T1UtilsAsync", "T1DatePicker"], function ($, _, T1, T1View, T1Layout, T1Timezones, template, CampaignStrategyUtils, Advertiser, Agency, Campaigns, Organization, T1UtilsAsync) {
  "use strict";

  const getStartEndDatesDisplay = CampaignStrategyUtils.getDisplayTextForStartAndEndDates;
  const startBufferMin = {
    minutes: 10
  };
  const displayDateFormat = "MMMM d, yyyy";
  const displayTimeFormat = "h:mm tt";
  var DuplicateCampaignSettingsView = T1View.extend({
    template: template,
    startTime: "12:00 AM",
    endTime: "11:59 PM",
    duplicateCampaignBrain: false,
    showAdvertiserLoader: false,
    showCrossAdPixelInfo: false,
    utilities: {
      onOrganizationFetchSuccess: function (data, resolver, self) {
        var models = {
          currentOrganization: data
        };
        if (self.originalAdvertiser.get("agency")) {
          models.originalCampaignAgency = self.originalAdvertiser.get("agency");
          resolver.resolve(models);
        } else {
          const agency = new Agency({
            id: self.originalAdvertiser.get("agency_id")
          });
          agency.fetch({
            success: function (agencyData) {
              models.originalCampaignAgency = agencyData;
              resolver.resolve(models);
            }
          });
        }
      }
    },
    events: {
      "changed #search-campaigns": "searchCampaigns",
      "selected #campaign-selection": "campaignSelected"
    },
    eventhubEvents: {
      "duplicateCampaignAdvertiser:select": function ({
        name: name,
        id: id
      }) {
        this.$(".info").children().text(name);
        this.checkCrossAdvertiserCampaignCopying(id);
      },
      "pixelBundle:select": "meritPixelChangedHandler",
      "campaign:duplicatingToggled": function (val) {
        this.resetExistingCampaignDropdown();
        this.duplicateToExistingCampaign = val === "strategies";
        this.$(".existing-campaign-form").toggle(this.duplicateToExistingCampaign);
        this.$(".new-campaign-form").toggle(!this.duplicateToExistingCampaign);
      }
    },
    initialize: function ({
      organizationInstance: organizationInstance,
      originalAdvertiser: originalAdvertiser,
      duplicateToExistingCampaign: duplicateToExistingCampaign,
      startDate: startDate,
      endDate: endDate
    }) {
      var deferred = T1UtilsAsync.makeDeferral();
      const organization = new Organization({
        id: organizationInstance.selected
      });
      this.originalAdvertiser = originalAdvertiser || this.model.get("advertiser");
      this.selectedAdvertiser = this.originalAdvertiser.get("id");
      this.duplicateToExistingCampaign = duplicateToExistingCampaign || false;
      this.startDate = startDate;
      this.endDate = endDate;
      this.selectedCampaign = {
        name: this.model.get("name"),
        value: this.model.get("id"),
        selected: true
      };
      deferred.promise.then(models => {
        $.extend(this, models);
        this.fetchData();
      });
      organization.fetch({
        success: data => {
          this.utilities.onOrganizationFetchSuccess(data, deferred, this);
        }
      });
      this.initLayout();
    },
    initLayout: function () {
      this.advertiserSelect = new T1Layout({
        el: () => this.el,
        selectorPath: ".advertiser-select",
        template: '<div class="advertiser-select-box"></div>',
        layout: {
          ".advertiser-select-box": [{
            module: "advertiser",
            viewType: "select",
            options: {
              model: this.model.get("advertiser") || null,
              eventName: "duplicateCampaignAdvertiser:select"
            }
          }]
        }
      });
      this.pixelSelect = new T1Layout({
        el: () => this.el,
        selectorPath: ".pixel-select",
        template: '<div class="pixel-select-box"></div>',
        layout: {
          ".pixel-select-box": [{
            module: "pixel",
            viewType: "select",
            options: {
              getAdvertiserId: () => this.selectedAdvertiser,
              id: this.model.get("merit_pixel_id"),
              doNotShowExtraLink: true
            }
          }]
        }
      });
    },
    load: function () {
      var goalType = this.model.get("goal_type") ? this.model.get("goal_type").toLowerCase() : "";
      this.disableDuplicateBrain = goalType === "spend" || goalType === "reach";
      this.showMeritPixel = goalType === "cpa" || goalType === "cpe" || goalType === "roi";
      if (this.isDataLoaded && !this.isLoadCalled) {
        this.isLoadCalled = true;
        T1Timezones.done(mthds => {
          this.timezones = mthds;
          this.render().then(() => {
            setTimeout(() => {
              this.loadComponents();
            }, 0);
          });
        });
      }
    },
    loadComponents: function () {
      this.advertiserSelect.load();
      this.pixelSelect.load();
      this.resetExistingCampaignDropdown();
      T1.DoubleDatePicker({
        getStartDate: () => this.startDate,
        getEndDate: () => this.endDate,
        chooseTime: true,
        chooseTimezone: false,
        timezoneEditable: false,
        lowerBounds: new Date(),
        positionBy: "bottomTop",
        topOffset: 25,
        closeOnScroll: false,
        onSave: ({
          data: data,
          error: error,
          success: success
        }) => {
          let saveValues = data.saveValues;
          var newStartDate = Date.parse(saveValues.start.date);
          this.timezones.validateStartDate(startBufferMin, newStartDate, this.model.get("zone_name"), {
            onSuccess: () => {
              saveValues = data.saveValues;
              newStartDate = Date.parse(saveValues.start.date);
              const newEndDate = Date.parse(saveValues.end.date);
              if (Date.compare(newEndDate, newStartDate) !== 1) {
                error({
                  message: this.startEndOrderError
                });
                return;
              }
              this.startDate = newStartDate;
              this.endDate = newEndDate;
              this.startTime = newStartDate.toString(displayTimeFormat);
              this.endTime = newEndDate.toString(displayTimeFormat);
              this.zoneName = saveValues.zoneName;
              this.renderPartial(".date-calendar");
              this.updateDates(newStartDate, newEndDate);
              success();
            },
            onError: () => {
              error({
                message: this.startDateError
              });
            },
            hasStarted: this.hasStarted
          });
        }
      }, this.el);
    },
    resetExistingCampaignDropdown: function () {
      this.populateDropdown([this.selectedCampaign], false);
      this.campaignSelectDropdown = this.campaignSelectDropdown || this.el.find("#campaign-selection")[0];
      this.campaignSelectDropdown.set("value", this.selectedCampaign.value);
    },
    setSelectedCampaign: function () {
      this.campaignSelectDropdown = this.campaignSelectDropdown || this.el.find("#campaign-selection")[0];
      this.campaignSelectDropdown.set("value", this.selectedCampaign.value);
    },
    performSearch: function (term) {
      const collection = new Campaigns();
      collection.fetchOptions = {
        sort_by: "name",
        order_by: "ascending"
      };
      collection.setOrganization(this.currentOrganization.get("id"));
      collection.pageLimit = 100;
      collection.search.set({
        params: [{
          term: term,
          searchField: "name|id"
        }]
      });
      collection.fetch({
        success: (coll, response) => {
          var results = [];
          if (response.entities.count === "1") {
            results[0] = response.entities.entity;
          } else {
            results = response.entities.entity;
          }
          if (results) {
            this.populateDropdown(results);
          } else {
            this.populateDropdown([{
              name: `No results match "${term}"`,
              value: null
            }]);
          }
        }
      });
    },
    searchCampaigns: function (e) {
      var value = e.srcElement.value || "";
      const minimumSearchLength = 3;
      if (value && value.length >= minimumSearchLength) {
        this.performSearch(value, e);
      } else {
        this.populateDropdown([{
          name: "Enter at least 3 characters",
          value: null
        }]);
      }
    },
    campaignSelected: function (e) {
      const el = e.target;
      this.selectedCampaign = el.items.find(item => item.value === el.value);
      T1.EventHub.publish("campaign:selected", this.selectedCampaign);
      this.setPlaceholderTxt(this.selectedCampaign.name);
    },
    populateDropdown: function (entities, state = true) {
      this.campaignSelectDropdown = this.campaignSelectDropdown || this.el.find("#campaign-selection")[0];
      this.campaignSelectDropdown.set("data", this.formatEntities(entities));
      this.campaignSelectDropdown.set("state", state ? "opened" : "closed");
      this.setPlaceholderTxt(this.selectedCampaign.name);
    },
    formatEntities: function (models) {
      const formattedArray = [];
      $.each(models, (i, model) => {
        formattedArray.push({
          name: model.name,
          value: model.id || model.value,
          selected: model.id === this.selectedCampaign.value
        });
      });
      return formattedArray;
    },
    setPlaceholderTxt: function (name) {
      const $lblDuplicateStrategy = this.$("#campaign-selection").find("label");
      if ($lblDuplicateStrategy.length) {
        $lblDuplicateStrategy.text(name);
      }
    },
    meritPixelChangedHandler: function () {
      this.el.find(".pixel-select-box .select .chzn-single").removeClass("error");
    },
    toggleAdvertiserLoader: function (action) {
      this.showAdvertiserLoader = action === "show";
      this.renderPartial(".advertiser-loader");
    },
    toggleCrossAdPixelInfo: function (action) {
      this.showCrossAdPixelInfo = action === "show";
      this.renderPartial(".info-box-wrapper");
    },
    checkCrossAdvertiserCampaignCopying: function (newAdvertiserID) {
      if (this.originalAdvertiser.get("id") === newAdvertiserID) {
        this.toggleCrossAdPixelInfo("hide");
        this.toggleAdvertiserLoader("hide");
        return;
      }
      this.toggleAdvertiserLoader("show");
      this.newAdvertiser = new Advertiser({
        id: newAdvertiserID
      });
      this.newAdvertiser.fetch({
        success: () => {
          if (this.originalCampaignAgency.get("id") === this.newAdvertiser.get("agency_id")) {
            if (this.originalCampaignAgency.get("allow_x_adv_pixels") === "1") {
              this.toggleCrossAdPixelInfo("hide");
            } else {
              this.toggleCrossAdPixelInfo("show");
            }
            this.toggleAdvertiserLoader("hide");
            return;
          }
          const newAgency = new Agency({
            id: this.newAdvertiser.get("agency_id")
          });
          newAgency.fetch({
            success: fetchedAgency => {
              this.toggleAdvertiserLoader("hide");
              if (this.currentOrganization.get("allow_x_agency_pixels") === "1" && this.originalCampaignAgency.get("allow_x_adv_pixels") === "1" && fetchedAgency.get("allow_x_adv_pixels") === "1") {
                this.toggleCrossAdPixelInfo("hide");
              } else {
                this.toggleCrossAdPixelInfo("show");
              }
            }
          });
        }
      });
    },
    unload: function () {
      if (this.advertiserSelect) {
        this.advertiserSelect.unload();
      }
      if (this.pixelSelect) {
        this.pixelSelect.unload();
      }
      this.isDataLoaded = undefined;
      this.duplicateCampaignBrain = false;
      this.showAdvertiserLoader = false;
      this.showCrossAdPixelInfo = false;
      this.destroy();
    },
    fetchData: function () {
      this.isDataLoaded = true;
      this.load();
    },
    updateDates: function (startDate, endDate) {
      T1.EventHub.publish("campaign:datesUpdated", {
        startDate: startDate,
        endDate: endDate
      });
    },
    serialize: function () {
      const {
        model: model,
        timezones: timezones,
        startDate: startDate,
        endDate: endDate,
        showMeritPixel: showMeritPixel,
        disableDuplicateBrain: disableDuplicateBrain,
        duplicateCampaignBrain: duplicateCampaignBrain,
        showAdvertiserLoader: showAdvertiserLoader,
        showCrossAdPixelInfo: showCrossAdPixelInfo,
        duplicateToExistingCampaign: duplicateToExistingCampaign
      } = this;
      var modelJson = model.toJSON();
      var timezone = timezones && timezones.getByZoneName(modelJson.zone_name);
      var obj = {
        dateTimeDisplay: getStartEndDatesDisplay({
          startDate: startDate,
          endDate: endDate,
          dateFormat: displayDateFormat,
          timeFormat: displayTimeFormat
        }),
        timeZone: timezone && timezone.toJSON() || {},
        showMeritPixel: showMeritPixel,
        disableDuplicateBrain: disableDuplicateBrain,
        duplicateCampaignBrain: duplicateCampaignBrain,
        showAdvertiserLoader: showAdvertiserLoader,
        showCrossAdPixelInfo: showCrossAdPixelInfo,
        duplicateToExistingCampaign: duplicateToExistingCampaign,
        newName: `${modelJson.name}_copy`
      };
      return $.extend(obj, modelJson);
    }
  });
  return DuplicateCampaignSettingsView;
});
