define('modules/strategy/views/createEdit',["jQuery", "T1", "T1View", "T1Layout", "T1TabLayoutV2", "text!modules/strategy/templates/createEdit.html", "modules/strategy/views/duplicateStrategy", "modules/strategy/views/helpers/createEditPermissionHelper", "T1Permissions", "collections/organizations", "models/strategyTargetSummary", "T1Menu", "utils/CampaignStrategyUtils", "utils/nextTabViewNavigation", "T1Spinner", "jQueryUI", "moment", "T1Accordion", "T1Truncate", "T1Selectbox", "T1DatePicker", "T1Form"], function ($, T1, T1View, T1Layout, T1TabLayout, template, DuplicateStrategyView, PermissionHelper, T1Permissions, Organizations, StrategyTargetSummary, t1Menu, Utils, NextTabViewNavigation) {
  "use strict";

  const enterKey = 13;
  var getAgencyAndAdvertizerDisplay = Utils.getAgencyAdvertiserDisplay;
  var getCtdReportJsonObjByModel = Utils.getCtdReportJsonObjByModel;
  var getUnitLabelForActualFieldOnHeaderRegion = Utils.getUnitLabelForActualFieldOnHeaderRegion;
  var goalTypeText = {
    spend: "CPM SPEND",
    reach: "CPM REACH",
    vcpm: "Viewable CPM",
    viewability_rate: "Viewability Rate",
    vcr: "Video Completion Rate",
    ctr: "CTR",
    cpc: "CPC",
    cpe: "CPE",
    cpa: "CPA",
    roi: "ROI"
  };
  var strategyInlineForecasterEnabled = T1Permissions.check("feature", "inline_forecaster_enabled");
  var strategyInlineForecasterPriceTimeoutId = null;
  const {
    isHybrid: isHybrid,
    origin: origin,
    organization: orgNewT1
  } = window.hybridWorkflowParams;
  function updateBreadCrumb(isReadOnly, strategyJSON, strategyModel) {
    const campaignId = strategyJSON.campaign.id;
    const locations = [{
      label: strategyJSON.campaign.name,
      location: `#campaign/${isReadOnly ? "readonly/" : "edit/"}${campaignId}/strategies`
    }];
    if (strategyJSON.id) {
      locations.push({
        label: strategyJSON.name,
        location: (isReadOnly ? "#strategy/readonly/" : "#strategy/edit/") + strategyJSON.id
      });
    } else {
      locations.push({
        label: "New Strategy",
        location: ""
      });
    }
    T1.EventHub.publish("updateNavPath", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(strategyModel),
      locations: locations
    });
  }
  function updateBreadCrumbNewT1(isReadOnly, strategyJSON, strategyModel) {
    const campaignId = strategyJSON.campaign.id;
    const locations = [{
      label: "Campaigns Home",
      location: `${origin}?organization=${orgNewT1}`,
      target: "_top"
    }, {
      label: strategyJSON.campaign.name,
      location: `${origin}/strategies?campaign=${campaignId}&organization=${orgNewT1}`,
      target: "_top"
    }];
    if (strategyJSON.id) {
      locations.push({
        label: strategyJSON.name,
        location: (isReadOnly ? "#strategy/readonly/" : "#strategy/edit/") + strategyJSON.id
      });
    } else {
      locations.push({
        label: "New Strategy",
        location: "",
        supressLink: true
      });
    }
    T1.EventHub.publish("updateNavPathNewT1", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(strategyModel),
      locations: locations
    });
  }
  function supplyAndTargetingAllowed(model) {
    return model.get("supply_type") !== "RMX_API";
  }
  const StrategyCreateEditView = T1View.extend({
    template: template,
    mode: "create",
    eventhubEvents: {
      "strategy-create-edit:cancelUnload": "cancelUnload",
      "strategy-create-edit:reloadSubViews": "reloadSubViews",
      "strategy-badges-edit:placementCheck": "badgesPlacementCheck",
      "forecaster:saveDate": "saveForecasterDates"
    },
    events: {
      "keyup .price-value-input": "onForecasterPriceKeyup",
      "keydown .price-value-input": "onForecasterPriceKeydown",
      "click .price-value-input": "onForecasterInputFocus"
    },
    menuConfig: {
      menuTriggerEleSelector: ".strategy-settings",
      menuColumns: [{
        columnTitle: null,
        menuItems: [{
          label: "Duplicate",
          handler: "duplicateStrategy",
          visible: true
        }, {
          label: "Change log",
          handler: "viewChangeLog",
          visible: true
        }, {
          label: "CheckList",
          handler: "showHealth",
          visible: true
        }, {
          label: "View Report",
          visible: false
        }]
      }]
    },
    initialize: function (args) {
      const {
        mode: mode,
        model: model
      } = args;
      var self = this;
      if (Utils.isProgrammaticGuaranteed(this.model.get("campaign"))) {
        Utils.redirectInaccessibleRoutes({
          inaccessibleRoutes: ["targeting"],
          defaultRoute: "details"
        });
      }
      this.targetSummaryData = args.targetSummaryData;
      this.hardCodedResults = {
        audience: "1",
        contextual: "1",
        creative: "1",
        day_part: "1",
        location: "1",
        pixel: "1",
        site_list: "1",
        supply: "1",
        technology: "1",
        video: "1"
      };
      this.ignoreResults = true;
      NextTabViewNavigation.attachBehavior(this);
      if (!model) {
        throw new Error("Strategy model must be passed in as part of the args parameter.");
      }
      this.permissionHelper = new PermissionHelper(args);
      Organizations.getOrganizations().getSelected().then(id => {
        this.org = id;
      });
      this.mode = mode;
      this.model = model;
      this.campaign = model.get("campaign");
      this.applyViewBlockingLogic();
      function getCampaignStrategyInfo(campaignModel) {
        const campaignStrategyInfo = model.toJSON();
        const campaign = campaignModel.toJSON();
        campaignStrategyInfo.campaign = campaign;
        self.campaign = campaign;
        return campaignStrategyInfo;
      }
      const {
        flag: canEditWithV2
      } = Utils.allowV2CampaignOrStrategyEdit({
        campaign: this.campaign,
        strategy: this.model,
        view: self,
        showDialog: true
      });
      if (canEditWithV2) {
        this.currencyCode = this.campaign.get("currency_code");
        this.asDecimalCurrency = T1.Utils.getDecimalCurrencyTemplateFunc(this.currencyCode);
        const isProgrammaticGuaranteed = Utils.isProgrammaticGuaranteed(this.campaign);
        this.strategyInlineForecasterEnabled = strategyInlineForecasterEnabled && !isProgrammaticGuaranteed;
        const launchInstructions = this.permissionHelper.getLayoutLaunchInstruction();
        this[launchInstructions.layoutMethodName]();
        if (launchInstructions.needToRedirect === true) {
          T1.Location.set(launchInstructions.redirectedUrl, {
            silent: true
          });
        }
        if (isHybrid) {
          updateBreadCrumbNewT1(this.permissionHelper.isReadOnly(), getCampaignStrategyInfo(this.campaign), model);
        } else {
          updateBreadCrumb(this.permissionHelper.isReadOnly(), getCampaignStrategyInfo(this.campaign), model);
        }
        if (this.permissionHelper.isReadOnly() === false) {
          this.addDataEvent({
            model: {
              "change:version": function () {
                var campModel = model.get("campaign");
                self.renderPartial(".basic-info");
                if (campModel) {
                  if (isHybrid) {
                    updateBreadCrumbNewT1(false, getCampaignStrategyInfo(campModel), model);
                  } else {
                    updateBreadCrumb(false, getCampaignStrategyInfo(campModel), model);
                  }
                }
              }
            }
          });
        }
      }
    },
    tabs: {
      details: 0,
      supply: 1,
      creatives: 2,
      targeting: 3,
      summary: 4
    },
    dataEvents: {
      model: {
        "change:id": function () {
          var serializedData = this.serialize();
          var tabLayout = this.layout;
          var tabs = this.tabs;
          var location = T1.Location.get(true);
          var model = this.model;
          tabLayout.execute("enable", tabs.creatives);
          tabLayout.execute("enable", tabs.summary);
          this.enableSiteListTargetingTabs();
          this.updateDataBind({
            canEditStrategy: serializedData.canEditStrategy
          }, this.el);
          location = location.replace(`create/${model.get("campaign").id}`, `edit/${model.id}`);
          T1.Location.set(location, {
            replace: true,
            silent: true
          });
        },
        "strategy:created": function () {
          if (this.model.get("supply_type") === "RMX_API") {
            this.layout.execute("select", this.tabs.creatives);
          }
        },
        "strategy:updated": function () {
          if (this.strategyInlineForecasterEnabled) {
            this.strategyInlineForecasterLayout.load();
          }
        }
      }
    },
    prepareMenuItems: function () {
      var menuIndexRef = {
        Duplicate: 0,
        Report: 3
      };
      const menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      const campaignId = this.model.get("campaign").id;
      var canAccessReporting = !T1Permissions.check("feature", "reporting_hide");
      if (canAccessReporting) {
        menuItems[menuIndexRef.Report].visible = true;
        menuItems[menuIndexRef.Report].url = `#reports/campaigns/performance/${campaignId}`;
      }
      if (this.permissionHelper.isReadOnly()) {
        menuConfig.menuColumns[0].menuItems[0].visible = false;
      }
      this.actionMenuObj = t1Menu(menuConfig, this);
    },
    enableSiteListTargetingTabs: function () {
      const {
        layout: tabLayout,
        tabs: tabs
      } = this;
      const isProgrammaticGuaranteed = Utils.isProgrammaticGuaranteed(this.model.get("campaign"));
      if (supplyAndTargetingAllowed(this.model) === true) {
        tabLayout.execute("enable", tabs.supply);
        if (!isProgrammaticGuaranteed) {
          tabLayout.execute("enable", tabs.targeting);
        }
      }
    },
    applyViewBlockingLogic: function () {
      var deepLinks = T1.Location.getRoute().deepLinks;
      const viewName = deepLinks && deepLinks.length ? deepLinks[0] : "";
      if (supplyAndTargetingAllowed(this.model) === false && viewName.length) {
        if (viewName === "supply" || viewName === "targeting") {
          T1.Location.set(`#strategy/edit/${this.model.id}/details`);
        }
      }
    },
    load: function () {
      var tabs = this.tabs;
      const nonSummaryBadgeLayout = 4;
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
          $.proxy(this.prepareMenuItems, this)();
          if (this.mode === "edit" || this.permissionHelper.isSummary() === true) {
            this.layout.execute("enable", tabs.creatives);
            this.layout.execute("enable", tabs.summary);
            this.enableSiteListTargetingTabs();
            if (this.layout.selected !== nonSummaryBadgeLayout) {
              this.loadLayoutForSummaryBadges();
            }
          }
          if (this.strategyInlineForecasterEnabled) {
            this.loadLayoutsForInlineForecaster();
          }
          this.loadLayoutForSearchBox();
        }
      });
    },
    initLayouts: function () {
      const {
        model: model,
        tabs: tabs
      } = this;
      this.layout = new T1TabLayout({
        el: () => this.$(".edit-create-tabs"),
        tabSetName: "StrategyEditCreate",
        level: 1,
        disabled: [tabs.supply, tabs.creatives, tabs.targeting, tabs.summary],
        selected: this.permissionHelper.isSummary() ? tabs.summary : tabs.details,
        tabs: [{
          label: "Details",
          module: "strategy",
          viewType: "details",
          routeName: "details",
          showLoader: true,
          options: {
            model: model || null
          }
        }, {
          label: "Supply",
          module: "strategy",
          viewType: "supply",
          routeName: "supply",
          showLoader: true,
          options: {
            model: model || null
          }
        }, {
          label: "Creatives",
          module: "strategy/creatives",
          viewType: "panel",
          routeName: "creatives",
          showLoader: true,
          options: {
            strategy: model || null
          }
        }, {
          label: "Targeting",
          module: "strategy",
          viewType: "targeting",
          routeName: "targeting",
          showLoader: true,
          options: {
            model: model || null
          }
        }, {
          label: "Summary",
          module: "strategy",
          viewType: "summary",
          routeName: "summary",
          showLoader: true,
          options: {
            model: model || null
          }
        }]
      });
    },
    unload: function () {
      if (this.detailsLayout) {
        this.detailsLayout.unload();
      }
      if (this.targetingLayout) {
        this.targetingLayout.unload();
      }
      if (this.searchBoxLayout) {
        this.searchBoxLayout.unload();
      }
      if (this.strategyInlineForecasterLayout) {
        this.strategyInlineForecasterLayout.unload();
      }
    },
    canUnload: function () {
      return this.layout ? this.layout.canUnload() : true;
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    initLayoutForReadOnly: function () {
      this.layout = new T1TabLayout({
        el: () => this.$(".edit-create-tabs"),
        tabSetName: "StrategyReadOnly",
        level: 1,
        tabs: [{
          label: "Details",
          module: "strategy",
          viewType: "summary",
          options: {
            model: this.model || null
          }
        }]
      });
    },
    loadLayoutForSearchBox: function () {
      this.searchBoxLayout = new T1Layout({
        el: () => this.$(".searchbox-holder"),
        template: '<div class="searchbox-content"></div>',
        layout: {
          ".searchbox-content": [{
            module: "strategy",
            viewType: "strategySearchBox",
            options: {
              model: this.model
            }
          }]
        }
      });
      this.searchBoxLayout.load();
    },
    loadLayoutsForInlineForecaster: function () {
      this.strategyInlineForecasterLayout = new T1Layout({
        el: () => this.$(".inline-forecaster"),
        template: '<div class="forecaster-container"></div><div class="forecaster-range"></div>' + '<div class="forecaster-warning"></div>',
        layout: {
          ".forecaster-container": [{
            module: "strategy",
            viewType: "strategyInlineForecaster",
            options: {
              strategyId: this.model.id,
              currencyCode: this.currencyCode,
              maxBid: this.model.get("max_bid"),
              inputPrice: this.strategyInlineForecasterPrice || null,
              mediaType: this.model.get("media_type"),
              startDate: this.strategyInlineForecasterStartDate,
              endDate: this.strategyInlineForecasterEndDate,
              frequencyAmount: this.model.get("frequency_amount"),
              frequencyInterval: this.model.get("frequency_interval"),
              frequencyType: this.model.get("frequency_type"),
              isRunOnAllExchanges: this.model.get("run_on_all_exchanges") === "1"
            }
          }],
          ".forecaster-range": [{
            module: "strategy",
            viewType: "datePicker",
            options: {
              startDate: this.strategyInlineForecasterStartDate,
              endDate: this.strategyInlineForecasterEndDate
            }
          }],
          ".forecaster-warning": [{
            module: "strategy",
            viewType: "forecasterWarning"
          }]
        }
      });
      this.strategyInlineForecasterLayout.load();
    },
    badgesPlacementCheck: function ({
      currentView: currentView
    }) {
      this.currentView = currentView;
      if (currentView === "summary") {
        if (this.summaryBadges) {
          this.summaryBadges.destroy();
        }
      } else {
        this.fetchTargetSummaryData();
      }
    },
    saveForecasterDates: function ({
      startDate: startDate,
      endDate: endDate
    }) {
      this.strategyInlineForecasterStartDate = startDate;
      this.strategyInlineForecasterEndDate = endDate;
      if (this.strategyInlineForecasterEnabled) {
        this.loadLayoutsForInlineForecaster();
      }
    },
    loadLayoutForSummaryBadges: function () {
      if (this.summaryBadges) {
        this.summaryBadges.remove();
      }
      if (this.currentView === "summary" || !this.model.id) {
        return;
      }
      this.summaryBadges = new T1Layout({
        el: () => this.$(".summary-badges-holder"),
        template: '<div class="summary-badges"></div>',
        layout: {
          ".summary-badges": [{
            module: "strategy",
            viewType: "summaryInfo",
            options: {
              model: this.model,
              targetSummaryData: this.targetSummaryData,
              currentView: this.currentView,
              strategyEdit: true
            }
          }]
        }
      });
      this.summaryBadges.load();
    },
    onForecasterPriceKeydown: function (event) {
      const backspaceKey = 8;
      const zeroKey = 48;
      const nineKey = 57;
      const periodKey = 190;
      const keyCode = event.keyCode;
      const isKeyCodeNumeric = keyCode >= zeroKey && keyCode <= nineKey;
      const isKeyCodeAllowedOther = keyCode === backspaceKey || keyCode === enterKey || keyCode === periodKey;
      if (!isKeyCodeNumeric && !isKeyCodeAllowedOther) {
        event.preventDefault();
      }
    },
    onForecasterPriceKeyup: function (event) {
      const newPrice = event.target.value;
      if (strategyInlineForecasterPriceTimeoutId) {
        clearTimeout(strategyInlineForecasterPriceTimeoutId);
      }
      this.strategyInlineForecasterPrice = newPrice;
      if (newPrice !== "") {
        if (event.keyCode === enterKey) {
          this.loadLayoutsForInlineForecaster();
        } else {
          const msDelayToForecasterLoad = 1e3;
          strategyInlineForecasterPriceTimeoutId = setTimeout(() => {
            this.loadLayoutsForInlineForecaster();
          }, msDelayToForecasterLoad);
        }
      }
    },
    onForecasterInputFocus: function () {
      this.strategyInlineForecasterPrice = "";
      T1.EventHub.publish("forecaster:inputClicked");
    },
    reloadSubViews: function () {
      this.fetchTargetSummaryData();
      if (this.strategyInlineForecasterEnabled) {
        this.loadLayoutsForInlineForecaster();
      }
    },
    fetchTargetSummaryData: function () {
      if (this.model.id && !this.ignoreResults) {
        const targetSummaryModel = new StrategyTargetSummary({
          id: this.model.id
        });
        targetSummaryModel.fetch({
          action: "targeting_summary",
          success: data => {
            this.targetSummaryData = data;
            this.loadLayoutForSummaryBadges();
          }
        });
      } else {
        this.targetSummaryData = {
          hardCodedResults: true
        };
        this.loadLayoutForSummaryBadges();
      }
    },
    duplicateStrategy: function (e) {
      var strategy = this.model;
      function showDialog() {
        DuplicateStrategyView.showDialog({
          model: strategy
        });
      }
      const canUnloadSelectedTab = this.layout.canUnloadSelectedTab(null, {
        onAlertClose: showDialog
      });
      $(document).trigger("click");
      e.preventDefault(e);
      if (canUnloadSelectedTab) {
        showDialog();
      }
    },
    showHealth: function (event) {
      $(document).trigger("click");
      event.preventDefault();
      event.stopPropagation();
      const layout = new T1Layout({
        el: () => this.$(".health-checklist"),
        template: '<div class="healthDialogue"></div>',
        layout: {
          ".healthDialogue": [{
            module: "strategy",
            viewType: "health",
            options: {
              model: this.model
            }
          }]
        }
      });
      layout.load();
    },
    viewChangeLog: function (e) {
      $(document).trigger("click");
      e.preventDefault();
      e.stopPropagation();
      const layout = new T1Layout({
        template: '<div class=".changeDialog"></div>',
        layout: {
          ".changeDialog": [{
            module: "changelog",
            viewType: "dialog",
            options: {
              model: this.model,
              entityType: "strategy"
            }
          }]
        }
      });
      layout.load();
    },
    serialize: function () {
      const {
        model: model,
        campaign: campaign
      } = this;
      var dateFormat = "MM/dd/yyyy";
      var modelJson = model.toJSON();
      const isAllowedToShow = this.permissionHelper.isViewAllowedToShow();
      const campaignLinkNewT1 = `${origin}/strategies?campaign=${campaign.id}&organization=${this.org}`;
      const campaignLinkCompass = `#campaign/${this.permissionHelper.isReadOnly() ? "readonly/" : "edit/"}${campaign.id}/strategies`;
      var obj = {
        status: this.status,
        agencyAndAdvertiser: getAgencyAndAdvertizerDisplay(model.get("campaign")),
        campaignName: campaign.name,
        campaignLink: isHybrid ? campaignLinkNewT1 : campaignLinkCompass,
        campaignLinkTarget: isHybrid ? "_top" : "_self",
        canCreateStrategy: isAllowedToShow,
        t1supportEmail: "t1_support@mediamath.com",
        startDateAsString: modelJson.start_date ? Date.parse(modelJson.start_date).toString(dateFormat) : undefined,
        endDateAsString: modelJson.end_date ? Date.parse(modelJson.end_date).toString(dateFormat) : undefined,
        isReadOnlyView: this.permissionHelper.isReadOnly(),
        strategyInlineForecasterEnabled: this.strategyInlineForecasterEnabled
      };
      var noData = "&nbsp;--";
      var strategyDefaults = {
        name: "New Strategy",
        advertiserName: "Advertiser Name",
        id: noData,
        startDateAsString: noData,
        endDateAsString: noData
      };
      var returnObj = $.extend(obj, modelJson);
      var ctdReportJsonForStrategy = getCtdReportJsonObjByModel(model);
      var ctdReportJsonForCampaign = getCtdReportJsonObjByModel(model.get("campaign"));
      this.canCreateStrategy = isAllowedToShow;
      const status = modelJson.status === "1";
      returnObj.status = status ? "active" : "inactive";
      returnObj.isStatusActive = status;
      returnObj.canEditStrategy = function (target) {
        var canEditStrategy = returnObj.id || model.id;
        var activeClassName = "strategy-actions";
        var activeClass = activeClassName;
        let inActiveClass = `${activeClassName}-disabled`;
        if (!canEditStrategy || canEditStrategy === noData) {
          activeClass = `${activeClassName}-disabled`;
          inActiveClass = activeClassName;
        }
        if (target) {
          target.addClass(activeClass).removeClass(inActiveClass);
        }
        return activeClass;
      };
      returnObj.strategy_status = function (target) {
        var strategyStatus = returnObj.status;
        if (target) {
          target.removeClass("active").removeClass("inactive");
          target.addClass(strategyStatus);
        }
        return strategyStatus;
      };
      returnObj.mm_goal_performance = ctdReportJsonForStrategy.mm_goal_performance;
      returnObj.campaign_mm_goal_performance = ctdReportJsonForCampaign.mm_goal_performance;
      returnObj.strategyDefaults = strategyDefaults;
      returnObj.goal_type_text = modelJson.goal_value || modelJson.roi_target ? goalTypeText[modelJson.goal_type] : "";
      returnObj.total_budget = campaign.total_budget;
      returnObj.campaign_goal_value = campaign.goal_type !== "roi" ? campaign.goal_value : " ";
      returnObj.campaign_goal_type_text = campaign.goal_value ? goalTypeText[campaign.goal_type] : noData;
      returnObj.campaign_performance_goal_type_text = getUnitLabelForActualFieldOnHeaderRegion(returnObj.campaign_mm_goal_performance, campaign.goal_type);
      returnObj.strategy_performance_goal_type_text = getUnitLabelForActualFieldOnHeaderRegion(returnObj.mm_goal_performance, modelJson.goal_type);
      returnObj.goal_value = modelJson.goal_type !== "roi" ? modelJson.goal_value : modelJson.roi_target;
      returnObj.isCampaignROI = campaign.goal_type === "roi";
      returnObj.isStrategyROI = modelJson.goal_type === "roi";
      returnObj.roi_target = `${modelJson.roi_target}:1`;
      returnObj.asDecimalCurrency = this.asDecimalCurrency;
      return returnObj;
    }
  });
  return StrategyCreateEditView;
});
