define('modules/T1App/myApps/views/chart',['require','jQuery','text!modules/T1App/myApps/templates/no_data_chart.html','T1','T1View','text!modules/T1App/myApps/templates/chart.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const noDataChart = require("text!modules/T1App/myApps/templates/no_data_chart.html");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!modules/T1App/myApps/templates/chart.html");
  return T1View.extend({
    eventhubEvents: {
      "myApps.graphDataChange": function () {
        this.updateChart(this.model.get("chartData"));
      },
      "myApps.toggleLoader": "toggleLoader"
    },
    template: template,
    partials: {
      noDataChart: noDataChart
    },
    initialize(args) {
      this.model = args.model;
    },
    load() {
      this.render().then(() => {
        this.$loaderHolder = this.$(".loader-holder");
        this.$chart = this.$("#apps-chart");
        this.$noDataChart = this.$(".no-data-chart");
        this.updateChart();
        T1.EventHub.publish("complete:layoutLoad");
      });
    },
    unload() {
      if (this.lineChart) {
        this.lineChart.clear();
        this.lineChart = null;
      }
    },
    updateChart(data) {
      const lineChart = this.lineChart;
      if (data) {
        if (!lineChart) {
          this.lineChart = AmCharts.makeChart("apps-chart", data);
        } else {
          lineChart.dataProvider = data.dataProvider;
          lineChart.graphs = data.graphs;
          lineChart.validateData();
        }
        this.toggleChart(true);
      } else {
        this.toggleLoader(false);
        this.toggleChart(false);
      }
    },
    toggleChart(show) {
      if (show) {
        this.$chart.removeClass("no-display");
        this.$noDataChart.addClass("no-display");
      } else {
        this.$chart.addClass("no-display");
        this.$noDataChart.removeClass("no-display");
      }
    },
    toggleLoader(state) {
      this.$loaderHolder.toggle(state);
    },
    render() {
      const deferred = $.Deferred();
      this.el.html(template);
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
