define('collections/performanceDataForCollections',['require','jQuery','Backbone','T1Collection','T1PerfDataFulfiller'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Backbone = require("Backbone");
  const T1Collection = require("T1Collection");
  const T1PerfDataFulfiller = require("T1PerfDataFulfiller");
  return function PerformanceDataForCollection(settings) {
    let hostCollection, intervalCodeMap, perfDataFulfiller;
    function getHostCollection() {
      return hostCollection;
    }
    function setHostCollection(options) {
      hostCollection = options.hostCollection;
      if (hostCollection instanceof Backbone.Collection) {
        return hostCollection;
      }
      throw new Error("A valid hostCollection must be passed in via constructor parameter.");
    }
    function getPerfDataFulfiller() {
      return perfDataFulfiller;
    }
    function setPerfDataFulfiller(DataFulfiller, collection, {
      urlString: urlString,
      metrics: metrics
    }) {
      const getConfig = collection.name === "campaigns" ? "getConfigForCampaign" : "getConfigForStrategy";
      perfDataFulfiller = new DataFulfiller({
        contextEntity: collection,
        dataOptions: DataFulfiller[getConfig](),
        urlString: urlString,
        metrics: metrics
      });
      return perfDataFulfiller;
    }
    function initialize(options) {
      var collection = setHostCollection(options);
      var dataFulfiller = setPerfDataFulfiller(T1PerfDataFulfiller, collection, options);
      intervalCodeMap = dataFulfiller.getIntervalCodeMap();
    }
    function getIntervalCodeByUiInterval(uiInterval) {
      var uiRptInterval = uiInterval ? uiInterval.toLowerCase() : "ctd";
      for (const key in intervalCodeMap) {
        if (intervalCodeMap[key].toLowerCase() === uiRptInterval) {
          return key;
        }
      }
      return "";
    }
    function getIntervalsForFirstBatch() {
      const firstBatchIntervals = ["0", "1", "CTD", "FTD"];
      const selectedInterval = getHostCollection().report_interval;
      if (!firstBatchIntervals.includes(selectedInterval)) {
        firstBatchIntervals.push(selectedInterval);
      }
      return firstBatchIntervals.map(interval => getIntervalCodeByUiInterval(interval));
    }
    function getIntervalsFor2ndBatch(firstBatchIntervals) {
      var toBeExcluded = firstBatchIntervals || getIntervalsForFirstBatch();
      function isPartOfExcludedList(interval) {
        for (let i = 0; i < toBeExcluded.length; i++) {
          if (toBeExcluded[i].toLowerCase() === interval.toLowerCase()) {
            return true;
          }
        }
        return false;
      }
      return Object.keys(intervalCodeMap).filter(interval => isPartOfExcludedList(interval) === false);
    }
    function testForModels({
      models: models
    }, DataFulfiller, withArr) {
      var isPerformanceDataNeeded = DataFulfiller.removeRestoreGoalMonitoringClause(withArr, true);
      const hasPopulatedModelArray = Boolean(models && models.length);
      return isPerformanceDataNeeded && hasPopulatedModelArray;
    }
    function callBackAndTriggerEvents(response, onSuccess, canReset) {
      var collection = getHostCollection();
      if (canReset === true) {
        collection.trigger("reset");
      }
      onSuccess(collection, response);
    }
    function getWithArray({
      fetchOptions: fetchOptions
    }) {
      return fetchOptions && fetchOptions.with ? fetchOptions.with : [];
    }
    function hostCollectionFetchHandler(opts = {}) {
      const options = $.extend(true, {}, opts);
      var initialSuccess = options.success || $.noop;
      const isAddMode = options.add === true;
      const isSilent = options.silent === true;
      const canReset = !isAddMode && !isSilent;
      var indexBeforeRequest = getHostCollection().models.length;
      var withArr = getWithArray(getHostCollection());
      function onSuccess(collection, response) {
        var hasModels = testForModels(collection, T1PerfDataFulfiller, withArr);
        if (hasModels) {
          const startIndex = !isAddMode ? 0 : indexBeforeRequest;
          const intervalsFirstBatch = getIntervalsForFirstBatch();
          getPerfDataFulfiller().fulfillDataForCollection(startIndex, intervalsFirstBatch, isSilent, true).then(function () {
            callBackAndTriggerEvents(response, initialSuccess, canReset);
            if (options.singleRequestOnly !== true) {
              getPerfDataFulfiller().fulfillDataForCollection(startIndex, getIntervalsFor2ndBatch(intervalsFirstBatch), isSilent, false);
            }
          });
          T1PerfDataFulfiller.removeRestoreGoalMonitoringClause(withArr, false);
        } else {
          if (!collection.length) {
            T1PerfDataFulfiller.removeRestoreGoalMonitoringClause(withArr, false);
          }
          callBackAndTriggerEvents(response, initialSuccess, canReset);
        }
      }
      options.success = onSuccess;
      options.silent = true;
      return T1Collection.prototype.fetch.call(hostCollection, options);
    }
    initialize(settings);
    return {
      callBackAndTriggerEvents: callBackAndTriggerEvents,
      getHostCollection: getHostCollection,
      getIntervalCodeByUiInterval: getIntervalCodeByUiInterval,
      getIntervalsFor2ndBatch: getIntervalsFor2ndBatch,
      getIntervalsForFirstBatch: getIntervalsForFirstBatch,
      getPerfDataFulfiller: getPerfDataFulfiller,
      hostCollectionFetchHandler: hostCollectionFetchHandler,
      setHostCollection: setHostCollection,
      setPerfDataFulfiller: setPerfDataFulfiller
    };
  };
});
