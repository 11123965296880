define('collections/creativeAssets',["jQuery", "T1", "T1Collection", "models/creativeAsset"], function ($, T1, T1Collection, CreativeAsset) {
  T1.Models.CreativeAssets = T1Collection.extend({
    model: CreativeAsset,
    urlString: "creative_assets",
    getAsset: function (role) {
      role = role.toUpperCase();
      var creativeAsset = this.find(function (asset) {
        return asset.get("file_role") === role;
      });
      return creativeAsset;
    },
    getAssetID: function (role) {
      var creativeAsset = this.getAsset(role) || this.getAsset("primary");
      var id = "";
      if (creativeAsset) {
        id = creativeAsset.get("creative_id");
      }
      return id;
    },
    getAssetName: function (role) {
      var creativeAsset = this.getAsset(role);
      var name = "--";
      if (creativeAsset) {
        name = creativeAsset.get("name");
      }
      return name;
    }
  });
  return T1.Models.CreativeAssets;
});
