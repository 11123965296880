define('modules/strategy/views/helpers/createEditPermissionHelper',["Underscore", "T1Permissions"], function (_, T1Permissions) {
  "use strict";

  var CEPermissionHelper = function (args) {
    if (!args || !args.model || !args.mode) {
      throw new Error('The incoming args and its "model" and "mode" properties should be all present.');
    }
    let resultIsReadOnly, resultLayoutMethodName, resultNeedToRedirect, resultRedirectedUrl;
    const {
      mode: mode,
      model: model
    } = args;
    const campaignModel = model.get("campaign");
    const isReadOnly = Boolean(mode && mode.toLowerCase() === "readonly");
    const isSummary = Boolean(mode && mode.toLowerCase() === "summary");
    const hasVideoEditPermission = T1Permissions.check("feature", "video", model);
    const isVideoContent = model.get("media_type") === "VIDEO";
    const hasStrategyEditingPermission = T1Permissions.check("strategy.edit", model);
    const hasStrategyReadOnlyPermission = T1Permissions.check("strategy.readonly", model);
    const hasStrategyCreatePermission = T1Permissions.check("strategy.create", campaignModel);
    let isReadOnlyForLackOfVideoPermission = false;
    let resultIsSummary = isSummary;
    function calculatePermissionSettings() {
      function setResultsForRedirectReadOnlyMode() {
        resultIsReadOnly = true;
        resultNeedToRedirect = true;
        resultLayoutMethodName = "initLayoutForReadOnly";
        resultRedirectedUrl = `#strategy/readonly/${model.id}`;
      }
      function setResultsForRedirectSummaryMode() {
        resultIsReadOnly = false;
        resultNeedToRedirect = true;
        resultLayoutMethodName = "initLayouts";
        resultRedirectedUrl = `#strategy/summary/${model.id}`;
      }
      function setResultsForNonRedirectReadOnlyMode() {
        resultIsReadOnly = true;
        resultNeedToRedirect = false;
        resultLayoutMethodName = "initLayoutForReadOnly";
        resultRedirectedUrl = "";
      }
      function setResultsForNonRedirectEditMode() {
        resultIsReadOnly = false;
        resultNeedToRedirect = false;
        resultLayoutMethodName = "initLayouts";
        resultRedirectedUrl = "";
      }
      if (!isReadOnly) {
        if (!hasStrategyEditingPermission) {
          setResultsForRedirectReadOnlyMode();
        } else if (isVideoContent && !hasVideoEditPermission) {
          isReadOnlyForLackOfVideoPermission = true;
          setResultsForRedirectReadOnlyMode();
        } else {
          setResultsForNonRedirectEditMode();
        }
      } else {
        if (hasStrategyEditingPermission && !isVideoContent) {
          resultIsSummary = true;
          setResultsForRedirectSummaryMode();
        } else if (hasStrategyEditingPermission && isVideoContent && hasVideoEditPermission) {
          resultIsSummary = true;
          setResultsForRedirectSummaryMode();
        } else {
          setResultsForNonRedirectReadOnlyMode();
        }
      }
    }
    function isViewAllowedToShowUp() {
      return resultIsReadOnly && !isReadOnlyForLackOfVideoPermission ? hasStrategyReadOnlyPermission : hasStrategyCreatePermission;
    }
    calculatePermissionSettings();
    return {
      getLayoutLaunchInstruction: function () {
        return {
          layoutMethodName: resultLayoutMethodName,
          needToRedirect: resultNeedToRedirect,
          redirectedUrl: resultRedirectedUrl
        };
      },
      isReadOnly: () => resultIsReadOnly,
      isSummary: () => resultIsSummary,
      isViewAllowedToShow: isViewAllowedToShowUp
    };
  };
  return CEPermissionHelper;
});
