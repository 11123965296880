define('modules/targeting/digital/views/browse',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Collection", "text!../templates/grid_item.html", "text!../templates/grid_subitem.html", "text!../templates/grid_footer.html", "modules/targeting/shared/itemSelectionBehavior", "T1GridView", "collections/targetValues", "collections/wurflTargetValues", "T1Spinner"], function ($, _, T1, T1View, T1Layout, T1Collection, gridItemTemplate, gridSubItemTemplate, gridFooter, ItemSelectionBehavior, createGrid, TargetValues, WurflTargetValues, Spinner) {
  "use strict";

  var boldLastPartOfFullPath = T1.Utils.boldLastPartOfFullPath.bind(T1.Utils);
  var batchTargetsPerDimension = {
    DVCE: {
      "Laptop/Desktop": true,
      Smartphone: true,
      Tablet: true,
      iPhone: true,
      iPad: true,
      iPod: true,
      "Android Smartphone": true,
      "Android Tablet": true,
      "Windows Smartphone": true,
      Other: true
    },
    CSPD: {
      "Wide Area Wireless": true
    }
  };
  function targetIsAllowedForBatch(target) {
    var isDimension = !Reflect.has(target, "type");
    var dimensionCode = target.dimension_code;
    var code = target.code;
    if (isDimension) {
      return batchTargetsPerDimension[dimensionCode];
    }
    if (batchTargetsPerDimension[dimensionCode]) {
      return batchTargetsPerDimension[dimensionCode][code];
    }
    return false;
  }
  function processDroppedItem({
    id: id,
    action: action
  }) {
    let targetValue = this.sharedCollection.get(id) || this.collection.get(id);
    let isRemovedFromBin;
    if (!targetValue) {
      Object.values(this.collectionList).forEach(collection => {
        if (collection.get(id)) {
          targetValue = collection.get(id);
        }
      });
    } else {
      isRemovedFromBin = targetValue.get("isRemovedFromBin") ? targetValue.get("isRemovedFromBin") : undefined;
    }
    if (targetValue && (targetValue.get("action") !== action || isRemovedFromBin === true || isRemovedFromBin === undefined)) {
      this.addTarget(targetValue, action, isRemovedFromBin);
    }
    T1.EventHub.publish("change:formStatus", {
      formStatus: true
    });
  }
  const DigitalBrowseView = T1View.extend({
    partials: {
      listItem: gridItemTemplate,
      gridSubItem: gridSubItemTemplate,
      gridFooter: gridFooter
    },
    headers: [{
      name: "type",
      label: "type"
    }],
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    prevSort: null,
    initialize: function ({
      strategy: strategy,
      partialCollection: partialCollection,
      sharedCollection: sharedCollection,
      filterConfigs: filterConfigs
    }) {
      this.targetDimensions = {};
      this.collectionList = {};
      this.consolidatedCollection = new T1Collection([]);
      this.viewType = "browse";
      this.strategy = strategy;
      this.strategyTargetDimensions = strategy.targetDimensions;
      this.strategyMediaType = strategy.get("media_type");
      this.partialCollection = partialCollection;
      this.sharedCollection = sharedCollection;
      this.viewType = "browse";
      this.isBatch = strategy.get("supply_type") === "BATCH";
      this.initData = this.createInitData(filterConfigs.filterOptions);
      ItemSelectionBehavior.attachBehavior(this);
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    events: {
      "click .item": "toggleNode",
      "click .item .icon.include": "includeTarget",
      "click .item .icon.exclude": "excludeTarget"
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_value_id",
      selectionAllowableKey: null,
      selectionAllowableValue: null,
      collectionKey: "consolidatedCollection"
    },
    eventhubEvents: {
      "bins:droppedTechnology": function ({
        selectedList: selectedList
      }) {
        selectedList.forEach(processDroppedItem, this);
      }
    },
    dataEvents: {
      collection: {
        search: "search",
        "search.clear": "search",
        reset: "reload"
      },
      sharedCollection: {
        "add:target_value": function (model) {
          this.sharedCollection.add(model);
          if (!model.get("parent")) {
            this.sharedCollectionAddHandler(model);
          }
        },
        "remove:target_value": function (model) {
          this.sharedCollection.remove(model);
          if (!model.get("parent")) {
            this.sharedCollectionRemoveHandler(model);
          }
        }
      }
    },
    createInitDataItem: function ({
      text: text,
      value: value
    }) {
      return {
        name: text,
        dimension_code: value,
        length: true,
        id: value
      };
    },
    createInitData: function (options) {
      return options.map(function (config) {
        this.targetDimensions[config.value] = config.text;
        return this.createInitDataItem(config);
      }, this);
    },
    changeTarget: function (evt, action) {
      var $item = $(evt.currentTarget).closest(".item");
      var $parentItem = $item.closest(".children").prev();
      var id = String($item.data("id"));
      const parentId = $parentItem.length ? String($parentItem.data("id")) : undefined;
      var item = parentId ? this.collectionList[parentId].get(id) : this.collection.get(id);
      this.preventDefault(evt);
      this.removeDragClasses();
      const name = item.get("name");
      let fullPath = this.getFullPath(item);
      fullPath = fullPath.substring(0, fullPath.lastIndexOf(name));
      this.renderPartial(".w-foot", {
        browse_path: fullPath,
        browse_path_end: name
      });
      this.addTarget(item, action, false);
      T1.EventHub.publish("change:formStatus", {
        formStatus: true
      });
    },
    includeTarget: function (evt) {
      this.changeTarget(evt, "include");
    },
    excludeTarget: function (evt) {
      this.changeTarget(evt, "exclude");
    },
    addTarget: function (item, action, isRemovedFromBin) {
      let lastAction;
      var targetValues = this.sharedCollection.get(item.get("dimension_code")).get("target_value");
      var itemInSharedCollection = targetValues.get(item.id);
      if (itemInSharedCollection) {
        lastAction = itemInSharedCollection.get("action");
        targetValues.remove(itemInSharedCollection);
      }
      if (lastAction !== action || isRemovedFromBin === true || isRemovedFromBin === undefined) {
        item.set({
          action: action,
          isRemovedFromBin: false
        });
        targetValues.add(item);
      }
    },
    unload: function () {
      delete this.searchTerm;
      this.viewType = "browse";
      this.collection.search.clear();
      if (this.paginationLayout) {
        this.paginationLayout.unload();
      }
    },
    collapseToggle: function (obj) {
      this.toggling = false;
      obj.$arrow.removeClass("expanded loading");
      obj.$iconSpinner.spin(false);
      obj.$childrenContainer.hide();
      obj.$paginationContainer.hide();
      this.updateScrollBar();
    },
    getTargetValue: function (collectionList, itemId, code) {
      if (code === "WURFL/OS") {
        collectionList[itemId] = new WurflTargetValues([], {
          name: "WURFL/OS"
        });
      } else {
        collectionList[itemId] = code !== "WURF" ? new TargetValues([], {
          dimension: code
        }) : new WurflTargetValues([], {
          dimension_code: code
        });
      }
      return collectionList[itemId];
    },
    expandedToggle: function ({
      item: item,
      $arrow: $arrow,
      $iconSpinner: $iconSpinner,
      $childrenContainer: $childrenContainer,
      $paginationContainer: $paginationContainer
    }) {
      var itemId = item.dataset.id;
      var code = item.dataset.dimension_code;
      var collection = this.getTargetValue(this.collectionList, itemId, code);
      if (this.toggling || !$arrow.length || $arrow.hasClass("hidden")) {
        return;
      }
      this.toggling = true;
      $arrow.addClass("loading expanded hide");
      if (!isNaN(itemId)) {
        collection.fetchOptions = $.extend(true, {}, collection.fetchOptions, {
          parent: itemId
        });
      }
      if (collection.length && $childrenContainer.children().length) {
        this.toggling = false;
        $arrow.removeClass("loading hide");
        $childrenContainer.show();
        if (collection.count > collection.pageLimit) {
          $paginationContainer.show();
        }
      } else {
        Spinner.apply($iconSpinner, this.spinnerOpts);
        if (!/nemo/.test(collection.apiRoot)) {
          collection.fetchOptions.dimension = code;
        }
        if (code === "WURF") {
          collection.fetchOptions.dimension_code = code;
          collection.fetchOptions.visible = 1;
        }
        if (code === "WURFL/OS") {
          this.name = "WURFL/OS";
          this.dimension_code = "WURFL/OS";
          collection.fetchOptions.name = this.name;
          collection.fetchOptions.depth = 2;
        } else {
          this.dimension_code = code;
          delete this.name;
          if (code === "DVCE") {
            collection.fetchOptions.sort_by = "id";
          } else if (this.prevSort) {
            collection.fetchOptions.sort_by = "name";
          }
        }
        collection.pageLimit = 100;
        collection.isPaginated = true;
        collection.fetch({
          silent: true,
          add: true,
          success: () => {
            this.toggling = false;
            $arrow.removeClass("loading hide");
            $iconSpinner.spin(false);
            if ($childrenContainer.css("display") !== "none") {
              const list = this.mapItems(collection);
              this.addChildren({
                entityId: itemId,
                data: {
                  list: this.isBatch ? list.filter(targetIsAllowedForBatch) : list
                },
                code: code
              });
            } else {
              $childrenContainer.show();
              $paginationContainer.show();
            }
            this.updateScrollBar();
          }
        });
      }
    },
    performToggle: function (item, $arrow) {
      var $parent = $(item).closest(".item-wrapper");
      var values = {
        $arrow: $arrow,
        $iconSpinner: $arrow.find(".icon-spinner"),
        $childrenContainer: $parent.children(".children"),
        $paginationContainer: $parent.children(".pagination")
      };
      if ($arrow.hasClass("expanded")) {
        this.collapseToggle(values);
      } else {
        values.item = item;
        this.expandedToggle(values);
      }
    },
    toggleNode: function (event) {
      var $arrow = $(".arrow", event.currentTarget);
      var canDoToggle = !(this.toggling || !$arrow.length || $arrow.hasClass("hidden"));
      if (canDoToggle) {
        this.preventDefault(event);
        this.performToggle(event.currentTarget, $arrow);
      }
    },
    addChildren: function ({
      entityId: entityId,
      code: code,
      data: data
    }) {
      const entityID = entityId !== "" ? entityId : code;
      const $item = this.$(`[data-id="${entityID}"]`);
      var indent = parseInt($item.find(".arrow").css("marginLeft").replace(/[^-\d.]/g, ""), 10);
      const collection = this.collectionList[entityID];
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: $item.closest(".item-wrapper").children(".children"),
        data: data || {
          list: this.mapItems(collection)
        }
      });
      this.updateScrollBar();
      this.applyDraggable();
      if (collection.count > collection.pageLimit) {
        const offset = 74;
        collection.parent = entityID;
        collection.entityType = "Option";
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: `.pagination-${entityID}`,
          layout: {}
        });
        this.paginationLayout.layout[`.pagination-${entityID}`] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: collection,
            onSuccess: this.addChildren.bind(this),
            indent: `margin: 5px -55px 0 ${indent + offset}px`
          }
        }];
        this.paginationLayout.load();
      }
    },
    search: function (data) {
      var collection = this.collection;
      this.dimension_code = "";
      if (data) {
        const term = data.term;
        const type = data.type;
        this.viewType = "search";
        this.searchTerm = term;
        if (data.type === "WURF" || data.type === "WURFL/OS") {
          collection = new WurflTargetValues([], {
            dimension_code: type
          });
          collection.isPaginated = true;
          collection.isSearch = true;
          collection.fetchOptions = {
            name: `%${term}%`,
            dimension: "WURF",
            visible: "1"
          };
          collection.fetch({
            success: wurflData => {
              const models = wurflData.models.filter(model => model.get("dimension_code") === data.type);
              this.collection.reset(models);
              this.collection.loaded = true;
              this.serialize();
            }
          });
        } else {
          collection = new TargetValues([], {
            dimension: type
          });
          collection.search.set({
            params: [{
              searchField: "name",
              term: `*${term}*`
            }],
            fetchRequired: false
          });
          collection.fetchOptions.dimension = type;
          this.setSearchLoader();
          collection.fetch({
            success: tvData => {
              this.collection.loaded = true;
              this.collection.reset(tvData.models);
              this.serialize();
            }
          });
        }
      } else {
        collection.search.clear();
        this.viewType = "browse";
        collection.reset([]);
      }
    },
    getFullPath: function (model) {
      var result = "";
      var collection = this.collection;
      while (model && model.get("parent_value_id")) {
        result = result === "" ? model.get("name") : `${model.get("name")} &raquo; ${result}`;
        model = collection.get(model.get("parent_value_id"));
      }
      if (model) {
        result = result === "" ? model.get("name") : `${model.get("name")} &raquo; ${result}`;
        result = `${this.targetDimensions[model.get("dimension_code")]} &raquo; ${result}`;
      }
      return result;
    },
    mapItems: function (collection) {
      let modelsToSerialize;
      var self = this;
      var models = collection.models;
      const {
        sharedCollection: sharedCollection,
        viewType: viewType,
        searchTerm: searchTerm,
        isBatch: isBatch
      } = this;
      var result = [];
      if (viewType === "browse") {
        if (this.dimension_code) {
          modelsToSerialize = models.filter(model => model.get("dimension_code") === this.dimension_code);
        } else {
          modelsToSerialize = models.filter(model => model.get("dimension_code") === this.name);
        }
      } else {
        modelsToSerialize = models;
      }
      this.consolidatedCollection.add(modelsToSerialize);
      $.each(modelsToSerialize, function (index, model) {
        let action;
        var targetValues = targetValues || sharedCollection.get(model.get("dimension_code")).get("target_value");
        var sharedItem = targetValues.get(model.id);
        if (sharedItem) {
          action = sharedItem.get("action");
        }
        model.set({
          action: action
        });
        const item = model.toJSON();
        self.applySelectionStates(item, collection);
        item.isIncluded = action === "include";
        item.isExcluded = action === "exclude";
        item.isActive = action !== undefined;
        item.isDisabled = false;
        if (viewType === "search") {
          const regex = new RegExp(searchTerm, "gi");
          const fullPathName = model.get("dimension_code") === "WURF" ? model.get("full_path_name") : self.getFullPath(model);
          if (regex.test(fullPathName)) {
            if (!isBatch || targetIsAllowedForBatch(item)) {
              item.name = boldLastPartOfFullPath({
                fullPath: fullPathName,
                separator: "&raquo;",
                searchTermToBeHighlighted: searchTerm
              });
              result.push(item);
            }
          }
        } else {
          item.length = self.isBatch && item.name === "Laptop/Desktop" ? false : getItemLength(item.child_count, item.child_count_visible);
          result.push(item);
        }
      });
      return result;
    },
    serialize: function () {
      return {
        list: getSerializeData(this),
        contentType: this.viewType
      };
    }
  });
  function getItemLength(count, visibleCount) {
    var num = visibleCount !== undefined && visibleCount !== 0 ? visibleCount : count;
    return num > 0;
  }
  function getSerializeData(self) {
    var data = self.viewType === "browse" ? self.initData : self.mapItems(self.collection);
    return self.isBatch ? data.filter(targetIsAllowedForBatch) : data;
  }
  return createGrid(DigitalBrowseView);
});
