define('modules/creatives/display/views/createEditBulkReview',["jQuery", "Underscore", "When", "T1", "T1Collection", "models/bulk3Pas", "models/advertiser", "models/atomicCreative", "collections/creativesDisplayBulk3Pas", "utils/CreativeUtils", "T1View", "T1Layout", "T1Permissions", "text!../templates/createEditBulkReview.html", "text!../templates/createEditBulkReviewGridValid.html", "text!../templates/createEditBulkReviewGridInvalid.html", "T1Form", "T1Notifier"], function ($, _, When, T1, T1Collection, Bulk3Pas, Advertiser, AtomicCreative, Bulk3PasCollection, CreativeUtils, T1View, T1Layout, T1Permissions, template, validGridTemplate, invalidGridTemplate) {
  "use strict";

  var Bulk3PASCreateEditReview;
  function updateBreadCrumb() {
    var pathPieces = [{
      label: "New Bulk 3PAS Ad Tag (Step 2 of 2)",
      location: "creatives/display-bulk-review"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  Bulk3PASCreateEditReview = T1View.extend({
    template: template,
    events: {
      "click .toggle": "updateFields",
      "click .bulk-valid-grid": "handleGridClicked",
      "click .saveBulkReview": "handleBulkReviewSave",
      "click .savePreview": "createPreSavePreview",
      "click .concept": "assignConcept",
      "changed .expansion strand-input": "landingInputChanged"
    },
    eventhubEvents: {
      "bulkedit:reviewSaved": "handleReviewSaved",
      "reviewgrid:valid:update": "updateGridItems"
    },
    isFormElementsChanged: false,
    customRedirect: {
      onAlertClose: function () {
        var nextLocation = T1.Location.getAbortedURL() ? T1.Location.getAbortedURL() : "creatives/display-bulk";
        T1.Location.set(nextLocation);
      }
    },
    initialize: function () {
      var self = this;
      if (_.isEmpty(this.model.attributes)) {
        T1.Location.set("creatives/display-bulk");
        return;
      } else {
        self.loadCompleted = false;
        self.isFormElementsChanged = true;
        self.isBulkReviewEnabled = T1Permissions.check("feature", "creatives.bulk_review");
        self.collection = new Bulk3PasCollection();
        self.collection.add(self.model.get("valid_placement"));
        self.actionsConfig = {
          formActions: [{
            label: "cancel",
            class: "cancel",
            location: "#"
          }, {
            label: "preview",
            class: "btn-secondary savePreview disabled",
            location: false
          }, {
            label: "Save & Finish",
            class: "btn-primary save",
            location: "#"
          }]
        };
        self.updateModels().then(function () {
          self.loadCompleted = true;
          self.initLayout();
          self.load();
        });
      }
    },
    assignConcept: function (e) {
      var self = this;
      var $target = $(e.target);
      var id = Number($target.closest(".concept").attr("id"));
      e.preventDefault();
      e.stopPropagation();
      self.model.resetCheckedItems();
      self.model.setCheckedItem(id);
      T1.EventHub.publish("conceptmodal:show");
      $(".save-disabled").removeClass("save-disabled").addClass("save");
    },
    updateGridItems: function (e) {
      var type = e.type;
      switch (type) {
        case "concept":
          this.updateConcept();
          break;
        default:
          return;
      }
    },
    updateConcept: function () {
      var checkedItems = this.model.get("checked_items");
      var validTags = this.model.get("valid_placement");
      var grid = $(".bulk-valid-grid", this.el)[0];
      _.each(checkedItems, function (id) {
        grid.set("data." + id + ".concept", validTags[id].concept_name);
      });
    },
    updateModels: function () {
      var self = this;
      var deferred = When.defer();
      var advertiser;
      advertiser = new Advertiser({
        id: self.model.get("advertiser_id")
      });
      advertiser.fetch({
        params: {
          with: ["agency"]
        },
        success: function (data) {
          self.model.set({
            advertiser: data
          });
          deferred.resolve(self.model);
        }
      });
      return deferred.promise;
    },
    initLayout: function () {
      var self = this;
      updateBreadCrumb();
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".bulk-edit-modal",
        template: '<div class="bulk-edit-modal"></div>',
        layout: {
          ".choose-action": [{
            module: "shared",
            viewType: "pullDownButton",
            options: {
              id: "choose-action",
              menuLabel: "Choose Action",
              items: [{
                itemLabel: "Activate",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doRowActivateOrDeactivate(item.id, true);
                  });
                }
              }, {
                itemLabel: "Deactivate",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doRowActivateOrDeactivate(item.id, false);
                  });
                }
              }, {
                itemLabel: "Edit",
                itemCallback: $.proxy(self.showBulkEdit, self)
              }, {
                itemLabel: "Include from Upload",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doSkipOrUnSkip(item.id, false);
                  });
                }
              }, {
                itemLabel: "Exclude from Upload",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doSkipOrUnSkip(item.id, true);
                  });
                }
              }, {
                itemLabel: "Preview",
                itemCallback: $.proxy(self.createPreSavePreview, self)
              }],
              disabled: true
            }
          }],
          ".concept-modal": [{
            module: "creatives/display",
            viewType: "conceptModal",
            options: {
              model: this.model
            }
          }]
        }
      });
    },
    showBulkEdit: function () {
      var self = this;
      var $clickField = self.el.find("strand-input");
      var selected = self.collection.getSelected();
      if (self.bulkEditLayout) {
        self.bulkEditLayout.destroy();
      }
      self.updateCheckedItemsArr();
      self.bulkEditLayout = new T1Layout({
        template: '<div class="bulk-edit"></div>',
        layout: {
          ".bulk-edit": [{
            module: "creatives/bulkedit",
            viewType: "dialog",
            options: {
              collection: selected,
              bulkAdvertiser: self.model.get("advertiser"),
              consistencyEnabled: true,
              isLocal: true
            }
          }]
        }
      });
      self.bulkEditLayout.load();
      self.hideError($clickField);
    },
    landingInputChanged: function (e) {
      var placement = this.model.get("valid_placement");
      _.each(placement, function (item) {
        if (item.id === Number(e.target.id)) {
          item.click_url = $(e.currentTarget).val();
        }
      });
    },
    updateFields: function () {
      var self = this;
      var placement = self.model.get("valid_placement");
      var $gridItem;
      function updateLandingPage(clickUrl) {
        var $landingPageInput;
        if ($gridItem) {
          $landingPageInput = $gridItem.find(".expansion strand-input");
          clickUrl = clickUrl ? clickUrl : $landingPageInput.val();
          $landingPageInput.val(clickUrl);
        }
      }
      function updateClassification(classificationData) {
        var $classification;
        if ($gridItem) {
          $classification = $gridItem.find(".expansion creatives-classification-grid-drawer");
          _.each($classification, function (component) {
            component.data = [];
            component.data = classificationData;
          });
        }
      }
      setTimeout(function () {
        _.each(placement, function (item) {
          $gridItem = self.getGridRowByID(item.id);
          updateClassification(item.current_classifications);
          updateLandingPage(item.click_url);
        });
      }, 0);
    },
    addRemoveValues: function (formValues, updateLandingPage, updateConceptName, updateClassification) {
      var self = this;
      var validPlacement = self.model.get("valid_placement");
      var placement, $gridItem, conceptName;
      _.each(self.getSelectedItems(), function (item) {
        placement = validPlacement[item.id];
        $gridItem = self.getGridRowByID(item.id);
        if (formValues.concept) {
          placement.concept_id = formValues.concept[item.id] ? formValues.concept[item.id].get("id") : placement.concept_id;
          conceptName = formValues.concept[item.id] ? formValues.concept[item.id].get("name") : placement.concept_name;
          updateConceptName($gridItem, conceptName);
        }
        if (formValues.status) {
          self.doRowActivateOrDeactivate(item.id, formValues.status === "1");
          placement.status = formValues.status;
        }
        if (formValues.click_url !== undefined) {
          placement.click_url = formValues.click_url;
          updateLandingPage($gridItem, placement.click_url);
        }
        if (formValues.add_classifications || formValues.remove_classifications) {
          placement.current_classifications = CreativeUtils.mapClassifications(placement, formValues);
        }
        updateClassification($gridItem, placement.current_classifications);
      });
    },
    handleReviewSaved: function (data) {
      var self = this;
      var formValues = data && data.formValues || {};
      self.formValues = formValues;
      function updateLandingPage($gridItem, clickUrl) {
        var $landingPageInput;
        if ($gridItem) {
          $landingPageInput = $gridItem.find(".expansion strand-input");
          $landingPageInput.val(clickUrl);
        }
      }
      function updateConceptName($gridItem, conceptName) {
        var $conceptName;
        if ($gridItem) {
          $conceptName = $gridItem.find(".concept");
          $conceptName.html(conceptName);
        }
      }
      function updateClassification($gridItem, classificationData) {
        var $classification;
        if ($gridItem) {
          $classification = $gridItem.find(".expansion creatives-classification-grid-drawer");
          _.each($classification, function (component) {
            component.data = [];
            component.data = classificationData;
          });
        }
      }
      self.addRemoveValues(formValues, updateLandingPage, updateConceptName, updateClassification);
      self.resetCollection();
    },
    resetCollection: function () {
      var self = this;
      if (self.collection) {
        self.collection.trigger("reset", self.collection);
      }
      self.collection = new Bulk3PasCollection();
      self.collection.add(self.model.get("valid_placement"));
      return self.collection;
    },
    handleBulkReviewSave: function (e) {
      $(".saveBulkReview").addClass("save-action");
      this.save(e);
      e.preventDefault();
    },
    getAdvertiserName: function () {
      var advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        return advertiser.get("name");
      } else {
        return null;
      }
    },
    getAgencyName: function () {
      var advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
      var agency;
      if (advertiser && advertiser.get) {
        agency = advertiser.get("agency");
        return agency.get("name");
      } else {
        return null;
      }
    },
    createPreSavePreview: function () {
      var self = this;
      var advertiser_name = self.getAdvertiserName();
      var agency_name = self.getAgencyName();
      var secure = self.model.get("is_https") === "1" ? true : false;
      var mraid = false;
      if (T1Permissions.check("feature", "creatives_mraid")) {
        mraid = self.model.get("is_mraid") === "1";
      }
      var bulkArray = [];
      _.each(self.getSelectedItems(), function (item) {
        var tag = item.converted_tag;
        var height = item.height;
        var width = item.width;
        var name = item.name;
        var tpasid = item.third_party_uuid;
        var bulkObj = {
          tag: tag,
          height: height,
          width: width,
          name: name
        };
        if (tpasid !== "NOT AVAILABLE") {
          bulkArray.push($.extend(bulkObj, {
            tpasid: tpasid
          }));
        } else {
          bulkArray.push(bulkObj);
        }
      });
      CreativeUtils.generateBulkPreSavePreview(bulkArray, advertiser_name, agency_name, secure, mraid);
    },
    load: function () {
      var self = this;
      var $el = self.el;
      var hasData = !_.isEmpty(this.model.attributes);
      var validTagsList = self.model.get("valid_placement");
      var invalidTagsList = self.model.get("invalid_placement");
      var validTags, invalidTags, $validGrid, $invalidGrid, $checkBoxes, $staticDataColl;
      if (!self.loadCompleted) {
        return;
      }
      function mapData(data, index) {
        var obj = {};
        $.extend(obj, data);
        obj.id = index;
        obj.index = data.batch_index;
        obj.dimensions = data.width + "x" + data.height;
        obj.type = data.tag_type;
        obj.upload = "INCLUDE", obj.reason = self.model.getErrors(data.errors);
        obj.selected = self.isBulkReviewEnabled ? false : true;
        obj.expanded = false;
        obj.classifications = data.classifications ? data.classifications : [];
        obj.staticData = self.staticData ? self.staticData : [];
        obj.concept = "Default (same as 'Name')";
        return obj;
      }
      function setGridData() {
        validTags = validTagsList && validTagsList.map(mapData);
        $validGrid[0].data = validTags;
      }
      this.render().then(function () {
        if (self.isBulkReviewEnabled) {
          if (self.layout) {
            self.layout.load();
          }
        }
        if (hasData) {
          invalidTags = invalidTagsList && invalidTagsList.map(mapData);
          $validGrid = $el.find(".bulk-valid-grid");
          $staticDataColl = $el.find("#staticData");
          $staticDataColl = $staticDataColl && $staticDataColl[0];
          $invalidGrid = $el.find(".bulk-invalid-grid");
          $checkBoxes = $validGrid.find("strand-checkbox.strand-grid");
          if ($validGrid) {
            if ($staticDataColl) {
              $staticDataColl.get().then(function (data) {
                self.staticData = data.marshalled;
                setGridData();
              }, function (error) {
                setGridData();
              });
            } else {
              validTags = validTagsList && validTagsList.map(mapData);
              $validGrid[0].data = validTags;
            }
            if (!self.isBulkReviewEnabled) {
              $checkBoxes.attr("checked", "checked");
              $(".savePreview").removeClass("disabled");
            }
          }
          if ($invalidGrid) {
            $invalidGrid[0].data = invalidTags;
          }
        }
      });
      T1.EventHub.publish("organization:select:disable");
    },
    render: function () {
      var self = this;
      var $el = self.el;
      var $validGrid, $invalidGrid;
      var deferred = $.Deferred();
      T1View.prototype.render.call(this).then(function () {
        $validGrid = $el.find(".bulk-valid");
        $invalidGrid = $el.find(".bulk-invalid");
        Polymer.dom($invalidGrid[0]).innerHTML = invalidGridTemplate;
        Polymer.dom($validGrid[0]).innerHTML = validGridTemplate;
        $validGrid.css("visibility", "visible");
        $validGrid.addClass("fade-in");
        self.buildPermissionedEvents();
        self.bindViewEvents();
      });
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    handleGridClicked: function (evt) {
      var self = this;
      var $el = self.el;
      var $grid = $el.find(".bulk-valid-grid");
      var strandGrid = $grid && $grid[0];
      var $item = $grid.find("strand-grid-item");
      var $checkBoxes = $grid.find("strand-checkbox.strand-grid-item");
      var $allCheckbox = $grid.find("#selectAll");
      var placement = self.model.get("valid_placement");
      setTimeout(function () {
        var count = strandGrid && strandGrid.data && strandGrid.data.length || 0;
        var selected = strandGrid ? strandGrid.selected : [];
        var selectedLen = selected.length;
        var isAllClicked = evt.target === $allCheckbox[0];
        var isAllChecked = isAllClicked && $allCheckbox[0].checked;
        if (selectedLen > 0 && count === selectedLen || selectedLen < count && selectedLen > 0 || isAllChecked) {
          T1.EventHub.publish("pullDownButton:choose-action:enable");
        } else {
          T1.EventHub.publish("pullDownButton:choose-action:disable");
        }
        if (selectedLen === 0) {
          $(".savePreview").addClass("disabled");
        } else {
          $(".savePreview").removeClass("disabled");
        }
        _.each($checkBoxes, function (item, index) {
          item.id = $item[index].id;
          placement[index].pos = index + 1;
        });
        self.updateCheckedItemsArr();
      }, 0);
    },
    updateCheckedItemsArr: function () {
      var self = this;
      var $validGrid = self.el.find(".bulk-valid-grid");
      var $checkBoxes = $validGrid.find("strand-checkbox.strand-grid-item");
      var validPlacement = self.model.get("valid_placement");
      self.model.resetCheckedItems();
      _.each($checkBoxes, function (item) {
        var id = Number(item.id);
        var checked = item.checked ? true : false;
        if (checked) {
          self.model.setCheckedItem(id);
          if (validPlacement[id]) {
            validPlacement[id].selected = true;
          }
        } else {
          if (validPlacement[id]) {
            validPlacement[id].selected = false;
          }
        }
      });
      self.resetCollection();
    },
    getSelectedItems: function () {
      var self = this;
      var $el = self.el;
      var $grid = $el.find(".bulk-valid-grid");
      return $grid && $grid[0].selected || [];
    },
    getGridRowByID: function (id) {
      var $grid = this.el.find(".bulk-valid-grid");
      return $grid.find("strand-grid-item[id=" + id + "]");
    },
    doRowActivateOrDeactivate: function (id, isActivate) {
      var self = this;
      var placements = self.model.get("valid_placement");
      var $gridItem = self.getGridRowByID(id);
      var $column = $gridItem.find("._mm_column:not(.checkbox)");
      if ($gridItem) {
        if (isActivate) {
          CreativeUtils.setActiveStatus(placements, Number(id));
        } else {
          CreativeUtils.setInactiveStatus(placements, Number(id));
        }
        $column.each(function () {
          $(this).css("opacity", isActivate ? 1 : .5);
        });
        $gridItem.find("strand-input").css("opacity", isActivate ? 1 : .5);
        $gridItem.find("creatives-classification-grid-drawer").css("opacity", isActivate ? 1 : .5);
      }
      setTimeout(function () {
        self.activateIcons(id);
      }, 0);
    },
    activateIcons: function (id) {
      var $gridItem = this.getGridRowByID(id);
      var $toggle = $gridItem.find("._mm_column.toggle");
      $toggle.each(function () {
        $(this).css("opacity", 1);
      });
    },
    doSkipOrUnSkip: function (id, isSkip) {
      var self = this;
      var $allItems;
      var placements = self.model.get("valid_placement");
      var $gridItem = self.getGridRowByID(id);
      if ($gridItem) {
        $allItems = $gridItem.find(".upload");
        if (isSkip) {
          $allItems.html("EXCLUDE");
          CreativeUtils.skipCreative(placements, Number(id));
        } else {
          $allItems.html("INCLUDE");
          CreativeUtils.unskipCreative(placements, Number(id));
        }
      }
    },
    finalValidationSweep: function () {
      var self = this;
      var placements = self.model.get("valid_placement");
      var errors = [];
      _.each(placements, function (item) {
        var $gridItems = self.getGridRowByID(item.id);
        if (item.valid === "1") {
          var $landingField = $gridItems.find(".expansion strand-input");
          var currentLandingValue = item.click_url;
          var newLandingValue = $landingField.val();
          var validateObject = {
            click_url: newLandingValue,
            name: item.name
          };
          var isInput = false;
          var err;
          if (newLandingValue && currentLandingValue !== newLandingValue) {
            item.click_url = newLandingValue;
          }
          err = self.model.validateAttrs(validateObject);
          _.each(err, function (msg) {
            var $field;
            if (msg && msg.field) {
              $field = $gridItems.find("." + msg.field + "");
              if (msg.field !== "click_url") {
                isInput = true;
              }
              self.displayError(msg.message, $field, isInput);
              errors.push(msg);
            } else {
              $field = $gridItems.find(".item-text");
              self.hideError($field);
            }
          });
        }
      });
      return errors;
    },
    displayError: function (msg, $field, isInput) {
      var errors = [];
      var errorConfig = {
        trigger: "manual",
        getExternalTip: false,
        className: "w-FormError w-review-error-tooltip",
        offset: 10
      };
      errorConfig.tooltipEle = $field;
      errorConfig.trigger = "hover";
      errorConfig.gravity = "s";
      $field.attr("title", msg.length > 100 ? msg.substring(0, 100) + "..." : msg);
      $field[0].error = true;
      if (isInput) {
        $field.addClass("error");
      }
      T1.Tooltip($field, errorConfig);
      errors.push(msg);
    },
    hideError: function ($field) {
      $field.each(function () {
        $(this).attr("title", "");
        $(this).attr("original-title", "");
        $(this).removeAttr("error");
      });
      $(".save", this.el).removeClass("save").addClass("saveBulkReview");
    },
    preparePostData: function (formValues) {
      var self = this;
      var $el = this.el;
      var batch = self.model.get("batch_index");
      var validPlacement = self.model.get("valid_placement");
      var $validGrid = $el.find(".bulk-valid-grid");
      if (self.isBulkReviewEnabled) {
        formValues.batch_index = [];
        _.each(batch, function (item, i) {
          if (validPlacement[i].valid === "1") {
            formValues.batch_index.push(item);
          }
        });
      } else {
        formValues.batch_index = $validGrid[0].selected.map(function (item) {
          return item.index;
        });
      }
      return formValues;
    },
    postData: function (formValues, successCallback, statusInvalid, conflictCallback) {
      var self = this;
      var urlId = self.model.get("batch_id");
      var advertiserId = self.model.get("advertiser_id");
      var version = self.model.get("version");
      var queue = [];
      var validationErrors = self.finalValidationSweep();
      var outputData, options, outputModel, count, entities, includedModels, mergedModels;
      if (validationErrors && validationErrors.length > 0) {
        statusInvalid(validationErrors);
        return;
      }
      outputModel = new Bulk3Pas();
      outputModel.setPostUrl(urlId);
      outputData = {
        is_https: self.model.get("is_https"),
        skip_ad_tag_validation: self.model.get("skip_ad_tag_validation"),
        batch_index: formValues.batch_index,
        advertiser_id: advertiserId
      };
      if (T1Permissions.check("feature", "creatives_mraid")) {
        outputData.is_mraid = self.model.get("is_mraid");
      }
      function creativesMapFunc(data) {
        if (!version) data.version = 0;
        return new AtomicCreative(data);
      }
      function getModelFormValues(model) {
        var saveObj = {
          status: model.get("status"),
          concept_id: model.get("concept_id"),
          click_url: model.get("click_url")
        };
        saveObj = JSON.parse(JSON.stringify(saveObj));
        $.extend(saveObj);
        return saveObj;
      }
      function handleSuccess() {
        successCallback();
        count = outputData.batch_index.length;
        T1.Notify("message", count + " Creative(s) successfully added");
        T1.Location.set("creatives/displays");
      }
      function handleError() {
        T1.Notify("message", "Error creating Creative(s)");
      }
      function saveModel() {
        options = {
          success: function (model, data) {
            if (self.isBulkReviewEnabled) {
              var deferred = When.defer();
              entities = new T1Collection().parse(data);
              includedModels = self.model.get("valid_placement");
              includedModels = includedModels.filter(item => item.valid === "1");
              mergedModels = CreativeUtils.mergeArrays(entities, includedModels);
              mergedModels = _.chain(mergedModels).map(creativesMapFunc).value();
              function finishSave() {
                _.each(mergedModels, function (mergedModel) {
                  queue.push(deferred.promise);
                  mergedModel.set({
                    selected: false
                  });
                  CreativeUtils.saveCreativeWithDefaultConcept(mergedModel, getModelFormValues(mergedModel), statusInvalid, conflictCallback, deferred, advertiserId);
                });
                When.all(queue).then(handleSuccess, handleError);
              }
              CreativeUtils.saveClassifications(mergedModels).then(finishSave, finishSave);
            } else {
              successCallback();
              count = outputData.batch_index.length;
              T1.Notify("message", count + " Creative(s) successfully added");
              T1.Location.set("creatives/displays");
            }
          },
          statusInvalid: function (errors) {
            statusInvalid(errors, "There are errors on the page.", ".createEditBulkReview");
          },
          conflict: function () {
            conflictCallback();
          }
        };
        outputModel.save(outputData, options);
      }
      saveModel();
    },
    unload: function () {
      T1.EventHub.publish("organization:select:enable");
      this.model.reset();
      if (this.layout) {
        this.layout.unload();
      }
      if (this.bulkEditLayout) {
        this.bulkEditLayout.unload();
      }
    },
    canUnload: function () {
      var self = this;
      if (this.isFormElementsChanged) {
        self.openUnloadAlertDialog({
          name: "Add Bulk 3Pas Tag"
        }, this.customRedirect);
      }
      return !this.isFormElementsChanged;
    },
    serialize: function () {
      var hasData = !_.isEmpty(this.model.attributes);
      var returnObj = {};
      if (hasData) {
        var resultData = this.model.get("result_data");
        var totalTags = resultData.batch.total;
        var validTagsList = resultData.batch.valid;
        var invalidTagsList = resultData.batch.invalid;
        returnObj.validCount = validTagsList;
        returnObj.invalidCount = invalidTagsList;
        returnObj.totalCount = totalTags;
        returnObj.agencyAndAdvertiser = this.getAgencyName() ? this.getAgencyName() + "&nbsp;&raquo;&nbsp;" + this.getAdvertiserName() : "";
      }
      return returnObj;
    }
  });
  return T1.Form.ViewUtils.extend(Bulk3PASCreateEditReview);
});
