define('models/t1AppVersion',["Underscore", "T1", "T1Model", "T1Comm"], function (_, T1, T1Model, T1Comm) {
  "use strict";

  T1.Models.T1AppsVersion = T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    dataType: "json",
    url: "",
    fetch: function (options) {
      var self = this;
      return T1Comm.get({
        dataType: "json",
        processAjaxResponse: self.processAjaxResponse,
        onStatusInvalid: function (e) {
          T1.log(e, "status");
        },
        onSuccess: options.success,
        onError: function (e) {
          T1.log(e);
        },
        sourceURL: T1.API_APPS_ROOT + "v1.0/app-versions?q=app_id==" + options.appID
      });
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.T1AppsVersion;
});
