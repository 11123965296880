define('modules/campaign/export/views/exportTags',["jQuery", "Underscore", "When", "T1", "T1View", "T1Collection", "text!modules/campaign/export/templates/exportTags.html", "models/campaign", "models/trackerTagsEmail", "models/trackerTagsDownload", "models/strategy", "utils/CampaignStrategyUtils", "jslibs/xdate/xdate"], function ($, _, When, T1, T1View, T1Collection, template, Campaign, TrackerTagsEmail, TrackerTagsDownload, Strategy, Utils, XDate) {
  "use strict";

  const defaultStatus = 2;
  const debounceDelay = 350;
  function updateBreadCrumb(campaign) {
    const pathPieces = [{
      label: campaign.attributes.name,
      location: `#campaign/edit/${campaign.id}`
    }, {
      label: "Export Tracker Tags",
      location: ""
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: Utils.getBreadCrumbHeaderInfo(campaign)
    });
  }
  function stopPropDefault(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  return T1View.extend({
    template: template,
    events: {
      "changed .search-exports": "searchHandler",
      "changed .search-dropdown": "updateSearchCriteria",
      "changed .status-select": "filterByStatus",
      "changed .to-field": "handleEmailChange",
      "changed .include-click-tracker": "includeClickTracker",
      "changed .strandExportGrid": "toggleCanExport",
      "click .submit-and-close": "submitAndClose",
      "click .submit-and-export-another": "submitAndExportAnother",
      "click .cancel": "cancel"
    },
    searchCriteria: "strategy",
    statusSelected: defaultStatus,
    clickTracker: false,
    noResultBoolean: true,
    initialize: function ({
      id: id
    }) {
      this.campaignId = id;
    },
    fetchCampaign: function (campaignId) {
      var campaign = new Campaign({
        id: campaignId
      });
      var deferred = $.Deferred();
      campaign.fetch({
        params: {
          with: "advertiser,agency"
        },
        success: data => {
          this.campaign = data;
          deferred.resolve();
          this.campaignLoaded = true;
          updateBreadCrumb(this.campaign);
        }
      });
      return deferred.promise();
    },
    fetchStrategiesConceptsCreatives: function () {
      var deferred = $.Deferred();
      var Strategies = T1Collection.extend({
        urlString: "strategies",
        model: Strategy,
        filterConfiguration: {
          filters: [{
            entity: "campaign",
            fetchOnChange: true
          }]
        }
      });
      var strategies = new Strategies();
      strategies.urlFilter.set({
        entity: "campaign",
        id: this.campaignId
      });
      strategies.fetchOptions = {
        with: "concepts,atomic_creatives",
        full: "strategy,atomic_creative",
        q: "supply_type==RMX_API"
      };
      strategies.fetchAllPages({
        success: data => {
          this.collection = data;
          this.isStrategiesLoaded = true;
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    load: function () {
      this.render().then(() => {
        this.grid = this.$(".strandExportGrid")[0];
        this.loadExecute(this.id);
      });
    },
    loadExecute: function (campaignId) {
      const grid = this.$(".strandExportGrid")[0];
      const loader = this.$(".exportLoader");
      const noResultsFound = this.$(".no-results-found");
      var sanitizeInput = function (input) {
        return T1.Utils.escapeHTML(input);
      };
      noResultsFound.hide();
      grid.style.display = "none";
      loader.show();
      When.all([this.fetchCampaign(campaignId), this.fetchStrategiesConceptsCreatives()]).then(() => {
        this.$(".header-export-data").html(`<div class="sub-header">${sanitizeInput(Utils.getAgencyAdvertiserDisplay(this.campaign))}&nbsp;&raquo;&nbsp;\n            <a href="#campaign/edit/${campaignId}">${sanitizeInput(this.campaign.get("name"))}</a>\n          </div>`);
        loader.hide();
        grid.style.display = "block";
        this.parseData();
      });
    },
    prepareParameters: function (gridData, clickTracker) {
      var includeClickTrackerKey = "include_click_tracker";
      var trackerTagsPrefix = "tracker_tags.";
      var strategyIdPostfix = ".strategy_id";
      var atomicCreativeIdPostfix = ".atomic_creative_id";
      var parameters = {};
      var index = 1;
      if (clickTracker) {
        parameters[includeClickTrackerKey] = 1;
      }
      _.each(gridData, function (info) {
        if (info.selected) {
          const strategyKey = trackerTagsPrefix + index + strategyIdPostfix;
          const atomicCreativeKey = trackerTagsPrefix + index + atomicCreativeIdPostfix;
          parameters[strategyKey] = info.strategy_id;
          parameters[atomicCreativeKey] = info.creative_id;
          ++index;
        }
      });
      return parameters;
    },
    exportCsvAndDownload: function (closeAfterSuccess) {
      var submitAndClose = this.$(".submit-and-close");
      var submitAndExportAnother = this.$(".submit-and-export-another");
      var postData = this.prepareParameters(this.gridArray, this.clickTracker);
      var trackerTagsDownload = new TrackerTagsDownload();
      trackerTagsDownload.sync("POST", postData, {
        dataType: "html",
        processAjaxResponse: function (resp) {
          var date = new XDate();
          var blob = new Blob([resp], {
            type: "attachment/csv"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var elem = document.body.appendChild(a);
          var handler = function () {
            const delay = 1e3;
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, delay);
            document.body.removeChild(elem);
            elem.removeEventListener("click", handler);
          };
          a.href = url;
          a.download = `mediamath_tracker_tags_${date.toString("yyyy-MM-dd")}.csv`;
          elem.addEventListener("click", handler);
          elem.click();
          submitAndClose.removeAttr("disabled");
          submitAndExportAnother.removeAttr("disabled");
          T1.Notify("message", "Form successfully submitted");
          if (closeAfterSuccess) {
            $(".tipsy").remove();
            T1.Location.set("monitor");
          }
          return {
            statusCode: "ok",
            jsonData: []
          };
        }
      });
    },
    toggleCanExport: function () {
      const submitAndClose = this.$(".submit-and-close");
      const submitAndExportAnother = this.$(".submit-and-export-another");
      var rows = this.gridArray;
      var nRows = rows.length;
      var canExport = false;
      for (let i = 0; i < nRows; ++i) {
        if (rows[i].selected) {
          canExport = true;
          break;
        }
      }
      if (canExport) {
        submitAndClose.removeAttr("disabled");
        submitAndExportAnother.removeAttr("disabled");
      } else {
        submitAndClose.attr("disabled", "");
        submitAndExportAnother.attr("disabled", "");
      }
    },
    includeClickTracker: function (e) {
      const clickTrackerStack = this.$(".click-tracker-stack");
      if (e.originalEvent.detail.state === "checked") {
        clickTrackerStack.removeAttr("hidden");
        this.clickTracker = true;
      } else {
        clickTrackerStack.attr("hidden", "");
        this.clickTracker = false;
      }
    },
    updateSearchCriteria: function (e) {
      const inputBox = this.$(".search-exports");
      inputBox[0].value = "";
      this.searchCriteria = e.originalEvent.detail.value;
    },
    searchHandler: _.debounce(function (e) {
      const noResults = this.$(".no-results-found");
      const searchyName = `${this.searchCriteria}_name`;
      const searchyId = `${this.searchCriteria}_id`;
      var searchArray = this.searchArray = [];
      var eventSearchDetail = e.originalEvent.detail.value;
      const minSearchLength = 3;
      noResults.hide();
      this.noResultBoolean = true;
      if (eventSearchDetail) {
        if (eventSearchDetail.length >= minSearchLength) {
          searchArray = _.filter(this.gridArray, function (item) {
            if (item[searchyName].toUpperCase().includes(eventSearchDetail.toUpperCase())) {
              return item;
            } else if (item[searchyId].includes(eventSearchDetail)) {
              return item;
            }
          });
          if (!searchArray.length) {
            noResults.show();
            this.noResultBoolean = false;
          }
        }
        if (eventSearchDetail.length > 0 && eventSearchDetail.length < minSearchLength) {
          this.$(".search-warning-export").removeAttr("hidden");
        } else {
          this.$(".search-warning-export").attr("hidden", "");
        }
        if (this.noResultBoolean && !searchArray.length) {
          this.searchArray = this.gridArray;
        } else {
          this.searchArray = searchArray;
        }
      } else if (!eventSearchDetail) {
        this.searchArray = this.gridArray;
      }
      this.updateGrid();
    }, debounceDelay),
    filterByStatus: function (e) {
      this.statusSelected = parseInt(e.originalEvent.detail.value, 10);
      this.updateGrid();
    },
    parseData: function () {
      var collection = this.collection.toJSON();
      var gridArray = this.gridArray = [];
      const noResults = this.$(".no-results-found");
      var newCollection = [];
      for (let d = 0; d < collection.length; d++) {
        if ("concept" in collection[d]) {
          newCollection.push(collection[d]);
        }
      }
      let collLength = newCollection.length;
      while (collLength--) {
        if (newCollection[collLength].concept instanceof Array) {
          let conceptLength = newCollection[collLength].concept.length;
          while (conceptLength--) {
            if (!("atomic_creative" in newCollection[collLength].concept[conceptLength])) {
              newCollection[collLength].concept.splice(conceptLength, 1);
            }
          }
        } else {
          if (!("atomic_creative" in newCollection[collLength].concept)) {
            newCollection.splice(collLength, 1);
          }
        }
      }
      for (let i = 0; i < newCollection.length; i++) {
        const strategyConceptCreative = newCollection[i];
        const {
          concept: concept,
          name: name,
          id: id,
          media_type: media_type,
          max_bid: max_bid,
          status: status
        } = strategyConceptCreative;
        if (concept instanceof Array) {
          for (let a = 0; a < concept.length; a++) {
            if (concept[a].atomic_creative instanceof Array) {
              for (let b = 0; b < concept[a].atomic_creative.length; b++) {
                gridArray.push({
                  strategy: `${name} (${id})`,
                  creative: `${concept[a].atomic_creative[b].name} (${concept[a].atomic_creative[b].id})`,
                  concept: `${concept[a].name} (${concept[a].id})`,
                  type: media_type,
                  advertiser_id: concept[a].atomic_creative[b].advertiser_id,
                  max_bid: max_bid,
                  strategy_name: name,
                  strategy_id: id,
                  creative_name: concept[a].atomic_creative[b].name,
                  creative_id: concept[a].atomic_creative[b].id,
                  concept_name: concept[a].name,
                  concept_id: concept[a].id,
                  selected: false,
                  status: status
                });
              }
            } else {
              gridArray.push({
                strategy: `${name} (${id})`,
                creative: `${concept[a].atomic_creative.name} (${concept[a].atomic_creative.id})`,
                concept: `${concept[a].name} (${concept[a].id})`,
                type: media_type,
                advertiser_id: concept[a].atomic_creative.advertiser_id,
                max_bid: max_bid,
                strategy_name: name,
                strategy_id: id,
                creative_name: concept[a].atomic_creative.name,
                creative_id: concept[a].atomic_creative.id,
                concept_name: concept[a].name,
                concept_id: concept[a].id,
                selected: false,
                status: status
              });
            }
          }
        } else {
          if (concept.atomic_creative instanceof Array) {
            for (let c = 0; c < concept.atomic_creative.length; c++) {
              gridArray.push({
                strategy: `${name} (${id})`,
                creative: `${concept.atomic_creative[c].name} (${concept.atomic_creative[c].id})`,
                concept: `${concept.name} (${concept.id})`,
                type: media_type,
                advertiser_id: concept.atomic_creative[c].advertiser_id,
                max_bid: max_bid,
                strategy_name: name,
                strategy_id: id,
                creative_name: concept.atomic_creative[c].name,
                creative_id: concept.atomic_creative[c].id,
                concept_name: concept.name,
                concept_id: concept.id,
                selected: false,
                status: status
              });
            }
          } else {
            gridArray.push({
              strategy: `${name} (${id})`,
              creative: `${concept.atomic_creative.name} (${concept.atomic_creative.id})`,
              concept: `${concept.name} (${concept.id})`,
              type: media_type,
              advertiser_id: concept.atomic_creative.advertiser_id,
              max_bid: max_bid,
              strategy_name: name,
              strategy_id: id,
              creative_name: concept.atomic_creative.name,
              creative_id: concept.atomic_creative.id,
              concept_name: concept.name,
              concept_id: concept.id,
              selected: false,
              status: status
            });
          }
        }
      }
      if (!gridArray.length) {
        noResults.show();
      }
      this.searchArray = gridArray;
      this.updateGrid();
    },
    updateGrid: function () {
      var filterArray = [];
      var statusSelected = this.statusSelected;
      if (statusSelected === defaultStatus) {
        filterArray = this.searchArray;
      } else {
        filterArray = _.filter(this.searchArray, item => parseInt(item.status, 10) === statusSelected);
      }
      const selected = filterArray.filter(value => value.selected);
      if (Boolean(selected.length) && selected.length !== filterArray.length) {
        this.grid.selectAllState = "partial";
      } else if (Boolean(selected.length) && selected.length === filterArray.length) {
        this.grid.selectAllState = "checked";
      } else {
        this.grid.selectAllState = "unchecked";
      }
      this.grid.set("data", filterArray);
    },
    cancel: function (e) {
      stopPropDefault(e);
      this.$(".tipsy").remove();
      T1.Location.set("monitor");
    },
    handleEmailChange: function (e) {
      if (e.target.error) {
        this.$(e.target).removeAttr("error");
        this.$(".tipsy").remove();
      }
    },
    exportCsvAndSendEmail: function (closeAfterSuccess) {
      let index = 1;
      var submitAndClose = this.$(".submit-and-close");
      var submitAndExportAnother = this.$(".submit-and-export-another");
      var $toField = this.$(".to-field");
      var toEmails = $toField.val().split(",");
      var email = new TrackerTagsEmail();
      var emailData = this.prepareParameters(this.gridArray, this.clickTracker);
      submitAndClose.attr("disabled", "");
      submitAndExportAnother.attr("disabled", "");
      _.each(toEmails, function (value) {
        const emailKey = `emails.${index}.address`;
        emailData[emailKey] = value.trim();
        ++index;
      });
      emailData.message = this.$(".email-message").val();
      email.save(emailData, {
        success: function () {
          submitAndClose.removeAttr("disabled");
          submitAndExportAnother.removeAttr("disabled");
          T1.Notify("message", "Form successfully submitted");
          if (closeAfterSuccess) {
            $(".tipsy").remove();
            T1.Location.set("monitor");
          }
        },
        statusInvalid: function (model) {
          submitAndClose.removeAttr("disabled");
          submitAndExportAnother.removeAttr("disabled");
          $toField.attr("error", true);
          T1.Tooltip($toField, {
            tooltipEle: $toField,
            trigger: "manual",
            getExternalTip: false,
            className: "w-toToolTipError",
            gravity: "s",
            offset: 16
          });
          $toField.attr("title", model[0].message).tipsy("show");
        }
      });
    },
    exportCsv: function (e, closeAfterSuccess) {
      stopPropDefault(e);
      const emailRadio = this.$(".email-radio");
      if (emailRadio.attr("checked") === "checked") {
        this.exportCsvAndSendEmail(closeAfterSuccess);
      } else {
        this.exportCsvAndDownload(closeAfterSuccess);
      }
    },
    submitAndClose: function (e) {
      this.exportCsv(e, true);
    },
    submitAndExportAnother: function (e) {
      this.exportCsv(e, false);
    },
    render: function () {
      var deferred = $.Deferred();
      Polymer.dom(this.el[0]).innerHTML = template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
