define('modules/campaign/views/filters',["jQuery", "T1", "T1View", "text!modules/campaign/templates/filters.html", "utils/campaignListStateMaintainer", "T1Selectbox", "jQueryUI"], function ($, T1, T1View, template, CampaignListStateMaintainer) {
  "use strict";

  var filterList = [{
    text: "Campaigns",
    value: "campaigns"
  }, {
    text: "Strategies",
    value: "strategies"
  }, {
    text: "Agencies",
    value: "agencies"
  }, {
    text: "Advertisers",
    value: "advertisers"
  }];
  function stripId(str) {
    var id = str.match(/[(][0-9]+[)]/);
    return $.trim(str.replace(id, ""));
  }
  const CampaignFiltersView = T1View.extend({
    template: template,
    filterViewMode: "campaigns",
    filterViewModeFlag: false,
    initialize: function (args) {
      this.singleEntity = args && args.singleEntity;
      this.extraOptions = args && args.extraOptions;
      this.filterViewMode = args.collection.filterViewMode;
    },
    eventhubEvents: {
      "update:filter": function (args = {}) {
        this.updateFilter(args);
      }
    },
    updateFilter: function ({
      data: data,
      entity: entity
    }) {
      this.$(".select-input").val(entity).trigger("liszt:updated");
      this.$(".term .text").val(`${data.name} (${data.id})`);
    },
    events: {
      "click input": "selectText",
      "click .clear-filter": "clearFilter",
      "change .select-input": "changeInput"
    },
    clearFilter: function (e) {
      this.$(".clear-filter").css("visibility", "hidden");
      this.$(".term .text").val("");
      T1.EventHub.publish("campaigns:clearFilter");
      this.preventDefault(e);
    },
    selectText: function (e) {
      var searchInput = $(e.currentTarget);
      var value = searchInput.val();
      searchInput.select().autocomplete("search", stripId(value));
      this.preventDefault(e);
    },
    changeInput: function (e) {
      T1.EventHub.publish("campaign.updateSearchFilter", e.currentTarget.value);
      this.filterViewMode = e.currentTarget.value;
      this.preventDefault(e);
    },
    source: function (request, response) {
      const promise = this.collection.find(request.term, this.$(".select-input").val(), this.extraOptions);
      promise.done(function ({
        data: data
      }) {
        response($.map(data, function (item) {
          item.label = item.name;
          item.value = item.name;
          return item;
        }));
      }).fail(function () {
        response([]);
      });
    },
    load: function () {
      var self = this;
      this.render().then(() => {
        this.restorePreviousLabel(CampaignListStateMaintainer.getFilterSearchLabelBeingRestored());
      });
      const entityInput = this.$(".select-input");
      const searchInput = this.$(".term .text");
      entityInput.val(this.filterViewMode);
      T1.Selectbox(entityInput, {
        onChange: function () {
          searchInput.select().autocomplete("search", stripId(searchInput.val()));
        }
      });
      searchInput.autocomplete({
        minLength: 1,
        delay: 1,
        autoFocus: true,
        appendTo: this.$(".auto-complete-results"),
        source: function (request, response) {
          if ($.trim(request.term) === "#") {
            searchInput.removeClass("ui-autocomplete-loading");
            return;
          }
          self.source(request, function (data) {
            self.data = data;
            searchInput.autocomplete({
              autoFocus: true
            });
            if (!data.length) {
              data = [{
                summary: `No results found for "${T1.Utils.escapeHTML(request.term)}"`
              }];
              searchInput.autocomplete({
                autoFocus: false
              });
            }
            searchInput.removeClass("ui-autocomplete-loading");
            response(data);
          });
        },
        focus: function () {},
        select: function (event, ui) {
          const {
            label: label,
            id: id,
            type: type,
            category_id: categoryId
          } = ui.item;
          searchInput.val(label).select();
          self.$(".clear-filter").css("visibility", "visible");
          T1.EventHub.publish("campaigns:filter", {
            id: id,
            type: type,
            categoryId: categoryId,
            label: label
          });
          return false;
        }
      }).keyup(function (event) {
        if (!event.currentTarget.value) {
          self.clearFilter(event);
          self.data = [];
          searchInput.autocomplete("close");
        }
      }).data("autocomplete")._renderItem = this.renderItem;
      searchInput.data("autocomplete")._renderMenu = this.renderMenu;
    },
    restorePreviousLabel: function (label) {
      const searchInput = this.$(".term .text");
      if (label) {
        searchInput.val(label);
        this.$(".clear-filter").css("visibility", "visible");
      }
    },
    renderMenu: function (ul, items) {
      var self = this;
      var currentCategory = "";
      $.each(items, function (index, item) {
        if (item.category && item.category !== currentCategory) {
          ul.append(`<li class='ui-autocomplete-category'><b></b>${item.category}</li>`);
          currentCategory = item.category;
        }
        if (T1.Utils.hasOwnProp(item, "summary")) {
          ul.append(`<li class='ui-autocomplete-summary'>${item.summary}</li>`);
        } else {
          item.label = item.value = `${item.label} (${item.id})`;
          self._renderItem(ul, item);
        }
      });
    },
    renderItem: function (ul, item) {
      return $("<li></li>").data("item.autocomplete", item).append(`<a>${item.label}</a>`).appendTo(ul);
    },
    serialize: function () {
      var list = filterList;
      var singleEntity = this.singleEntity;
      if (singleEntity) {
        list = list.filter(item => item.value === singleEntity);
        if (!list.length) {
          throw Error(`'singleEntity' ${singleEntity} not found in options for filter`);
        }
      }
      return {
        singleEntity: Boolean(singleEntity),
        filterList: list
      };
    }
  });
  return CampaignFiltersView;
});
