define('modules/targeting/location/views/readOnly',["jQuery", "Underscore", "T1", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils"], function ($, _, T1, T1View, template, Utils) {
  "use strict";

  return T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function ({
      model: model,
      hyperLocalTargeting: hyperLocalTargeting,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit,
      ipTargetingAttachments: ipTargetingAttachments
    }) {
      this.targetDimensions = model;
      this.hyperLocalTargeting = hyperLocalTargeting;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.ipTargetingAttachments = ipTargetingAttachments;
      delete this.ipTargetingAttachments.pageField;
      delete this.ipTargetingAttachments.pageLimit;
      delete this.ipTargetingAttachments.pageLimitField;
      delete this.ipTargetingAttachments.page;
    },
    load: function () {
      this.render().then(() => {
        const loader = this.loader();
        if (!this.prepareDataIsCalled || this.prepareDataIsCalled === false) {
          loader.start();
          const strategyId = this.model.id;
          this.hyperLocalTargeting.fetchAttachedGeofences().then(() => {
            this.ipTargetingAttachments.fetch({
              fetchOptions: {
                strategy_id: strategyId,
                dimension: "IPTA"
              },
              success: data => {
                let ipModel;
                for (let i = 0; i < data.length; i++) {
                  ipModel = data.models[i];
                  ipModel.set({
                    dimension_code: "IPTA"
                  }, {
                    silent: true
                  });
                  ipModel.set({
                    action: ipModel.get("restriction")
                  });
                  ipModel.set({
                    count: this.ipTargetingAttachments.count
                  });
                  break;
                }
                this.ipTargetingAttachments.reset(ipModel, {
                  silent: true
                });
                this.prepareData();
              }
            });
          });
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          T1.EventHub.publish("locationTargeting.ready");
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
      });
    },
    prepareData: function () {
      const dimCodes = ["REGN", "DMAX", "USZC"];
      var prepareData = Utils.prepareTargetDimensionData;
      const data = this.targetDimensions;
      this.prepareDataIsCalled = true;
      this.partialCollection = data.get("partial");
      this.locationCollection = prepareData(data, dimCode => dimCodes.includes(dimCode));
      this.geofenceCollection = this.hyperLocalTargeting.hyperLocalCollection;
      this.load();
    },
    prepareTitles: function (titles) {
      var hyperLocalCollection = this.hyperLocalTargeting.hyperLocalCollection;
      var hyperLocalOperators = hyperLocalCollection.operators;
      var returnObj = {
        include: $.extend(true, {}, titles),
        exclude: $.extend(true, {}, titles)
      };
      if (hyperLocalCollection.length) {
        $.each(["include", "exclude"], function (i, action) {
          if (hyperLocalOperators[action]) {
            const operator = hyperLocalOperators[action].toUpperCase();
            returnObj[action].hyperlocal = `Geofences (${operator})`;
          }
        });
      }
      return returnObj;
    },
    serialize: function () {
      var self = this;
      const {
        locationCollection: collObj,
        geofenceCollection: geofenceObj,
        ipTargetingAttachments: ipAttachmentsObj
      } = this;
      const codeNameRefs = this.prepareTitles({
        REGN: "Country / Region / City",
        DMAX: "DMA / Media Market",
        USZC: "Postal Codes",
        hyperlocal: "Geofences",
        IPTA: "IP List"
      });
      var resultJSON = {
        dataMain: []
      };
      var codeNameHashInc = {
        REGN: [],
        DMAX: [],
        USZC: [],
        hyperlocal: [],
        IPTA: []
      };
      var codeNameHashExc = {
        REGN: [],
        DMAX: [],
        USZC: [],
        hyperlocal: [],
        IPTA: []
      };
      var spaceEntity = "&nbsp;";
      var formatName = function (model, code) {
        var name = model.get("name");
        if (code === "REGN") {
          const pathObj = Utils.buildRegionPath(collObj, self.partialCollection, model);
          name = pathObj.partialPath + name;
        } else if (code === "USZC") {
          name = `Postal Codes Applied (${model.get("count")})`;
        } else if (code === "hyperlocal") {
          name = model.get("targeting_code") || model.get("name");
          if (model.parent) {
            name = `${model.parent.get("name")} &raquo ${name}`;
          }
        } else if (code === "IPTA") {
          name = `IP Addresses Applied (${model.get("count")})`;
        }
        return name ? name.replace(/\/|\|/g, "&raquo") : spaceEntity;
      };
      var createDataBlock = Utils.createGroupedDataArray;
      var hasIncludeData = false;
      var hasExcludeData = false;
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/location`;
      }
      if ((!collObj || !collObj.models || !collObj.models.length) && (!geofenceObj || !geofenceObj.length) && (!ipAttachmentsObj || !ipAttachmentsObj.length)) {
        this.hasData = false;
        return resultJSON;
      }
      $.each(collObj.models.concat(geofenceObj.models, ipAttachmentsObj.models), function (index, model) {
        var action = model.get("action").toLowerCase();
        var dimCode = model.get("dimension_code");
        var tmpArr = action === "include" ? codeNameHashInc[dimCode] : codeNameHashExc[dimCode];
        if (tmpArr) {
          tmpArr.push(formatName(model, dimCode));
        }
      });
      let tempData = createDataBlock(codeNameHashInc, codeNameRefs.include);
      if (tempData.length) {
        hasIncludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "included locations",
          data: tempData
        });
      }
      tempData = createDataBlock(codeNameHashExc, codeNameRefs.exclude);
      if (tempData.length) {
        hasExcludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "excluded locations",
          data: tempData
        });
      }
      this.hasData = resultJSON.hasData = hasIncludeData || hasExcludeData;
      if (resultJSON.hasData) {
        resultJSON.firstSideTitle = resultJSON.dataMain[0].sideTitle;
        resultJSON.dataMain[0].sideTitle = spaceEntity;
      }
      return resultJSON;
    }
  });
});
