define('modules/T1App/labs/views/launch-labs',["jQuery", "T1", "T1View", "T1Permissions", "text!modules/T1App/labs/templates/launch.html", "collections/organizations", "T1Spinner", "models/userPreferences", "models/sessionUser", "models/t1AppTerms", "collections/t1AppTerms", "models/t1AppAcceptedTerms", "collections/t1AppAcceptedTerms", "../../models/t1AppViews", "T1PerformanceTimer", "iframeResize", "utils/T1AppUtils", "T1Signature", "T1Preferences"], function ($, T1, T1View, T1Permissions, template, Organizations, Spinner, UserPreferences, User, T1AppTerms, AppTerms, T1AppAcceptedTerms, AppAcceptedTerms, t1AppViews, T1Timer, iframeResize, T1AppUtils) {
  "use strict";

  function generateAbsoluteURL(relativeURL) {
    var a = document.createElement("a");
    var url;
    a.href = relativeURL;
    url = a.href;
    return url;
  }
  return T1View.extend({
    template: template,
    t1AppConfig: {},
    labModal: [],
    initialize: function (arg) {
      var self = this;
      var model = this.model;
      var signature = T1.Signature.create();
      var appInfo, apps, orgId;
      this.appAcceptedTerms = new T1AppAcceptedTerms();
      this.acceptedTerms = new AppAcceptedTerms();
      this.appTerms = new T1AppTerms();
      this.collection = new AppTerms();
      $(document).scrollTop(0);
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "launch"]]));
      self.preferences = T1.Preferences.create.call(self, signature, UserPreferences);
      self.preferences.mark(["labModal"]);
      self.appModal = self.preferences.get("labModal") !== undefined ? self.preferences.get("labModal").split(",").map(function (item) {
        return parseInt(item);
      }) : [];
      Organizations.getOrganizations().getSelected().then(function (id) {
        orgId = id;
      });
      self.orgId = orgId;
      self.deepLinks = arg.deepLinks;
      if (!model.get("t1LabsCfgsArray")) {
        model.bind("change:T1LabsConfig", function () {
          model.unbind("change:T1LabsConfig");
          apps = model.get("t1LabsCfgsArray");
          appInfo = T1AppUtils.convertAppModalInfo(apps, arg.id);
          self.t1AppConfig = appInfo;
          if ($.inArray(self.t1AppConfig.app_id, self.appModal) !== -1) {
            this.exiting = true;
            this.currentModal = true;
          } else {
            this.exiting = false;
            this.currentModal = false;
          }
        });
        model.loadAppsConfig();
      } else {
        apps = this.model.get("t1LabsCfgsArray");
        appInfo = T1AppUtils.convertAppModalInfo(apps, arg.id);
        self.t1AppConfig = appInfo;
        if ($.inArray(self.t1AppConfig.app_id, self.appModal) !== -1) {
          this.exiting = true;
          this.currentModal = true;
        } else {
          this.exiting = false;
          this.currentModal = false;
        }
      }
    },
    load: function () {
      var self = this;
      var spinnerC = $(self.el).find(".spinner");
      var checkLab, termId, terms, validTerms;
      var $el = $(this.el);
      self.appTerms.fetch({
        success: function (data) {
          if (data.meta.status === "success") {
            self.appTerms.set(data.data);
          }
        }
      }).then(function () {
        self.appAcceptedTerms.fetch({
          success: function (data) {
            if (data.meta.status === "success") {
              self.appAcceptedTerms.set(data.data);
            }
          }
        }).then(function () {
          terms = T1AppUtils.getTermsInfo(self.appTerms.attributes, self.t1AppConfig.app_id);
          termId = terms ? terms.id : 0;
          validTerms = T1AppUtils.checkValidTerms(self.t1AppConfig.app_id, termId, self.appAcceptedTerms.attributes, self.t1AppConfig.fee, self.t1AppConfig.billing_type);
          checkLab = T1AppUtils.checkLabAgreement(self.appAcceptedTerms.attributes);
          if (validTerms || checkLab && self.t1AppConfig.billing_type === "none") {
            self.render().then(function () {
              T1Timer.start({
                category: "APP:LOAD",
                label: self.t1AppConfig.title
              });
              Spinner.apply(spinnerC, {
                lines: 10,
                length: 2,
                width: 2,
                radius: 2,
                top: 0,
                left: 3,
                color: "#ffffff"
              });
              $(".w-app-c", self.el).one("load", function () {
                $(".lab-iframe", $el).iFrameResize({
                  log: false,
                  scrolling: true,
                  heightCalculationMethod: "bodyScroll",
                  resizedCallback: function (messageData) {
                    $(".w-App", $el).height(parseInt(messageData.height) + 2);
                  }
                });
                T1Timer.stop({
                  category: "APP:LOAD",
                  label: self.t1AppConfig.title
                });
                $(".app-loader-c", self.el).fadeOut();
              });
            });
          } else {
            self.exiting = true;
            T1.Location.set("appstore");
          }
        });
      });
      $(".app-info strand-tooltip").remove();
      $(".overlay-view-bg").remove();
    },
    canUnload: function () {
      if (!this.exiting) {
        var $el = $(this.el);
        $el.find(".exit-app-modal .hideButton").show();
        $el.find(".exit-app-modal")[0].show();
        this.exiting = true;
      } else {
        return !false;
      }
    },
    unload: function () {
      T1View.prototype.unload.call(this);
    },
    getSessionID: function () {
      var self = this;
      self.model.bind("change:T1AppsSessionID", function () {
        self.model.unbind("change:T1AppsSessionID");
        self.load();
      });
      self.model.loadClonedSessionID();
    },
    events: {
      "click .exit-app": "exitApp",
      "click #confirmExit": "confirmExit",
      "click #cancelExit": "cancelExit",
      "click #newTab": "newTab",
      "click #close": "cancelExit"
    },
    newTab: function (e) {
      e.preventDefault();
      var T1Location = T1.Location;
      var abortedUrl = T1Location.getAbortedURL();
      var $el = $(this.el);
      if (abortedUrl === "" || abortedUrl === false) {
        setTimeout(function () {
          window.open("#appstore", "_blank");
          $el.find(".exit-app-modal")[0].hide();
        }, 1);
      } else {
        window.open(`#${abortedUrl}`, "_blank");
        $el.find(".exit-app-modal")[0].hide();
      }
    },
    exitApp: function (e) {
      e.preventDefault();
      var $el = $(this.el);
      var currentModal = this.currentModal;
      if (currentModal === true) {
        this.exiting = true;
        T1.Location.set("#appstore");
      } else {
        $("#modalCheckbox").attr("checked", false);
        $el.find(".exit-app-modal .hideButton").hide();
        $el.find(".exit-app-modal")[0].show();
        T1.Location.setAbortedURL("#appstore");
      }
    },
    confirmExit: function (e) {
      e.preventDefault();
      var T1Location = T1.Location;
      var abortedUrl = T1Location.getAbortedURL();
      var checkboxStatus = $("#modalCheckbox").attr("checked");
      var self = this;
      this.exiting = true;
      if (checkboxStatus === "checked") {
        this.appModal.push(self.t1AppConfig.app_id);
        this.appModal = this.appModal.join(",");
        this.preferences.save();
      }
      if (abortedUrl === "" || abortedUrl === false) {
        setTimeout(function () {
          T1Location.set("#appstore");
        }, 1);
      } else {
        T1Location.set(abortedUrl);
      }
    },
    cancelExit: function (e) {
      e.preventDefault();
      var $el = $(this.el);
      this.exiting = false;
      $("#modalCheckbox").attr("checked", false);
      $el.find(".exit-app-modal")[0].hide();
    },
    serialize: function () {
      var apiVersion,
        appPadding,
        appsApiBase,
        deepLinks,
        orgId,
        result,
        url,
        user = User.getUser();
      var createAppView;
      if (!$.isEmptyObject(this.t1AppConfig)) {
        url = this.t1AppConfig.url.split("?")[0];
        apiVersion = this.t1AppConfig.api_version;
        appsApiBase = COMPASS_ENV.APP_BASE + apiVersion;
        appsApiBase = generateAbsoluteURL(appsApiBase);
        appsApiBase = encodeURIComponent(`${appsApiBase}/`);
        deepLinks = this.deepLinks;
        if (deepLinks === undefined) {
          deepLinks = "";
        } else {
          deepLinks = `&${deepLinks}`;
        }
        orgId = this.orgId;
        appPadding = this.t1AppConfig.padding;
        createAppView = new t1AppViews();
        createAppView.createAppView(user.get("id"), this.t1AppConfig.app_id, orgId, "0.0.0");
        result = {
          app_title: this.t1AppConfig.title,
          app_id: this.t1AppConfig.id,
          app_url: `${url}?api_base=${appsApiBase}&session_id=${T1.SESSION_ID}&org_id=${orgId}${deepLinks}`,
          app_name: this.t1AppConfig.title,
          app_padding: `padding: ${appPadding}`
        };
      } else {
        result = {
          app_title: this.t1AppConfig.title,
          app_id: this.t1AppConfig.id,
          app_url: false,
          app_name: this.t1AppConfig.title,
          app_padding: ""
        };
      }
      return result;
    }
  });
});
