define('modules/creatives/views/flagViolationDialog',['require','jQuery','text!modules/creatives/templates/flagViolationDialog.html','Hogan','utils/RegexUtils','collections/supplySources','T1','T1Comm','T1UtilsAsync','modules/creatives/views/violationFilterList'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const fileTemplate = require("text!modules/creatives/templates/flagViolationDialog.html");
  const Hogan = require("Hogan");
  const RegexUtils = require("utils/RegexUtils");
  const SupplySources = require("collections/supplySources");
  const T1 = require("T1");
  const T1Comm = require("T1Comm");
  const T1UtilsAsync = require("T1UtilsAsync");
  const ViolationFilterList = require("modules/creatives/views/violationFilterList");
  return function FlagViolationDialog() {
    let $exchangeDDL, $incidentInput, $reasonDDL, dialog;
    const reasonsList = [{
      name: "Ad Fraud",
      requiresIncidentURL: false
    }, {
      name: "Auto-downloads",
      requiresIncidentURL: false
    }, {
      name: "Deceptive or Misleading",
      requiresIncidentURL: false
    }, {
      name: "Defamation",
      requiresIncidentURL: false
    }, {
      name: "Delayed Load",
      requiresIncidentURL: false
    }, {
      name: "Government Forms or Services",
      requiresIncidentURL: false
    }, {
      name: "Hate Speech",
      requiresIncidentURL: false
    }, {
      name: "Illegal",
      requiresIncidentURL: false
    }, {
      name: "Illegal Drugs",
      requiresIncidentURL: false
    }, {
      name: "Implied Knowledge",
      requiresIncidentURL: false
    }, {
      name: "Interferes with User Navigation",
      requiresIncidentURL: false
    }, {
      name: "Interferes with Another Party’s Content",
      requiresIncidentURL: false
    }, {
      name: "Invalid or Improper Classification",
      requiresIncidentURL: false
    }, {
      name: "Lost Video Impression Opportunities",
      requiresIncidentURL: false
    }, {
      name: "Morally Reprehensible",
      requiresIncidentURL: false
    }, {
      name: "Phishing",
      requiresIncidentURL: false
    }, {
      name: "Piracy",
      requiresIncidentURL: false
    }, {
      name: "Pornography",
      requiresIncidentURL: false
    }, {
      name: "Reselling",
      requiresIncidentURL: false
    }, {
      name: "Violence",
      requiresIncidentURL: false
    }, {
      name: "Weapons",
      requiresIncidentURL: false
    }, {
      name: "Others",
      requiresIncidentURL: true
    }, {
      name: "Mobile Redirects",
      requiresIncidentURL: true
    }, {
      name: "Auto Redirects",
      requiresIncidentURL: true
    }, {
      name: "IBV",
      requiresIncidentURL: true
    }, {
      name: "Auto Play",
      requiresIncidentURL: true
    }, {
      name: "Auto Audio",
      requiresIncidentURL: true
    }, {
      name: "Malware",
      requiresIncidentURL: true
    }, {
      name: "Malicious Activity",
      requiresIncidentURL: true
    }, {
      name: "Malicious Landing Page",
      requiresIncidentURL: true
    }, {
      name: "Malicious/Block listed Domains",
      requiresIncidentURL: true
    }];
    const show = (creativeJSON, creativeType) => {
      const template = Hogan.compile(fileTemplate);
      const supplySources = new SupplySources();
      const serializedData = {
        id: creativeJSON.id,
        reasons: reasonsList.map(item => ({
          name: item.name,
          value: item.name,
          requiresIncidentURL: item.requiresIncidentURL
        })).sort(sortName)
      };
      const closeHandler = () => {
        destroy();
      };
      const saveHandler = () => {
        const uiState = getUIState();
        const formValues = {
          atomic_creative_id: Number(creativeJSON.id),
          exchange_id: Number(uiState.selectedExchange),
          type: creativeType
        };
        formValues.exchange = serializedData.exchanges.filter(exchange => exchange.value === uiState.selectedExchange);
        formValues.exch_name = formValues.exchange && formValues.exchange.length && formValues.exchange[0].name;
        formValues.exchange = formValues.exchange && formValues.exchange.length && formValues.exchange[0].code;
        if (uiState.hasIncidentURL) {
          formValues.incident_url = uiState.incidentUrl;
        }
        formValues.rejection_reason = uiState.selectedReasons;
        postData(formValues).then(() => {
          T1.Notify("message", `Creative "${creativeJSON.name}" was flagged.`);
          destroy();
        }, errorResponse => {
          const errorJSON = JSON.parse(errorResponse.responseText);
          const errorList = errorJSON && errorJSON.errors || [];
          if (errorList && errorList.length) {
            errorList.forEach(error => {
              T1.Notify("error", error.message);
            });
          } else {
            T1.Notify("error", `Error flagging creative`);
          }
        });
      };
      const openHandler = evt => {
        dialog = $(evt.target);
        $exchangeDDL = dialog.find(".exchange-select strand-dropdown");
        $exchangeDDL[0].set("data", serializedData.exchanges);
        $exchangeDDL.on("changed", "strand-input", serializedData.exchanges, ddlSearchHandler);
        $exchangeDDL.on("selected", () => {
          updateSaveButtonState();
        });
        const violationFilterList = new ViolationFilterList({
          el: dialog.find(".reasons-select"),
          showOnInit: true,
          items: serializedData.reasons.map(item => ({
            id: item.name,
            name: item.name,
            searchKey: item.name,
            dataAttributes: `data-requires-incident-url=${item.requiresIncidentURL}`
          }))
        });
        violationFilterList.load();
        let topPosition = dialog.parent().css("top");
        const offset = 100;
        topPosition = parseFloat(topPosition.replace("px", ""));
        dialog.parent().css("top", `${topPosition - offset}px`);
        $reasonDDL = $(violationFilterList.el);
        $reasonDDL.on("change", ".list input[type=checkbox]", () => {
          updateSaveButtonState();
          toggleIncidentUrlInput(getUIState().requiresIncidentURL);
        });
        $incidentInput = dialog.find(".incidentUrl strand-input");
        $incidentInput.on("changed", () => {
          updateSaveButtonState();
        });
      };
      const ddlSearchHandler = evt => {
        const $ddl = evt.delegateTarget;
        const searchTerm = RegexUtils.escapeRegExp(evt.srcElement.value);
        const regexp = new RegExp(searchTerm, "gi");
        const ddlData = evt.data;
        $ddl.set("highlight", searchTerm);
        if (searchTerm) {
          const searchResults = ddlData.filter(item => item.name.match(regexp));
          $ddl.set("data", searchResults);
          $ddl.set("state", "opened");
        } else {
          $ddl.set("data", ddlData);
          $ddl.set("state", "opened");
        }
        $ddl.update();
        $ddl.requestInitialization();
      };
      const toggleIncidentUrlInput = showInput => {
        dialog.find(".incidentUrl").toggle(showInput);
      };
      const getUIState = () => {
        const exchange = $exchangeDDL && $exchangeDDL.val();
        const incidentUrl = $incidentInput && $incidentInput.val().trim();
        const hasIncidentURL = incidentUrl !== undefined && incidentUrl !== "";
        let rejectionReasons = $reasonDDL && $reasonDDL.find(".fl-item.selected");
        let requiresIncidentURL = $reasonDDL && $reasonDDL.find(".fl-item.selected[data-requires-incident-url=true]");
        rejectionReasons = $.map(rejectionReasons, checkbox => $(checkbox).data("id"));
        requiresIncidentURL = requiresIncidentURL && requiresIncidentURL.length > 0;
        return {
          hasIncidentURL: hasIncidentURL,
          incidentUrl: incidentUrl,
          requiresIncidentURL: requiresIncidentURL,
          selectedExchange: exchange,
          selectedReasons: rejectionReasons
        };
      };
      const updateSaveButtonState = () => {
        const uiState = getUIState();
        const hasExchanges = uiState.selectedExchange && uiState.selectedExchange !== "";
        const hasReasons = uiState.selectedReasons && uiState.selectedReasons.length > 0;
        const requiresIncidentURL = uiState.requiresIncidentURL;
        const hasIncidentURL = uiState.hasIncidentURL;
        let isSaveEnabled = hasExchanges && hasReasons;
        isSaveEnabled = requiresIncidentURL ? isSaveEnabled && hasIncidentURL : isSaveEnabled;
        dialog.parent().find(".save").toggleClass("disabled", !isSaveEnabled);
      };
      supplySources.fetchAllPages({
        fetchOptions: {
          full: "*"
        },
        success: function () {
          serializedData.exchanges = supplySources.models.filter(item => item.get("rtb_type") === "STANDARD").map(item => ({
            name: item.get("name"),
            value: item.get("bidder_exchange_identifier"),
            code: item.get("code")
          })).sort(sortName);
          $(template.render(serializedData)).dialog({
            autoOpen: true,
            buttons: [{
              class: "save disabled",
              click: saveHandler,
              text: "Flag Creative"
            }, {
              class: "cancel",
              click: closeHandler,
              text: "Cancel"
            }],
            close: closeHandler,
            closeText: "hide",
            dialogClass: "w-FlagCreativeViolationDialog",
            modal: true,
            open: openHandler
          });
        }
      });
    };
    const generateAbsoluteURL = relativeURL => {
      var a = document.createElement("a");
      a.href = relativeURL;
      const url = a.href;
      return url;
    };
    const getCreativeEnvUrl = (baseUrl, suffix) => {
      const API = generateAbsoluteURL(COMPASS_ENV.API_BASE);
      let url;
      if (API.indexOf("localhost") === -1) {
        const env = API.match(/t1(qa\w+)/);
        url = baseUrl + (env && env[1] || "prod") + suffix;
      } else {
        url = `${baseUrl}qa1${suffix}`;
      }
      return url;
    };
    const postData = formValues => {
      const forbiddenError = 403;
      const badRequestError = 400;
      const deferred = T1UtilsAsync.makeDeferral();
      const postObj = $.extend({}, formValues);
      const sourceURL = getCreativeEnvUrl(COMPASS_ENV.CRTVE_PUB_TOOLS_URL, "/publisher/violation");
      const opts = {
        sourceURL: sourceURL,
        dataType: "json",
        forcedContentType: "application/json",
        sendData: true,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true,
          errorCodes: [forbiddenError, badRequestError, 0]
        },
        onSuccess: response => {
          deferred.resolve(response);
        },
        onError: error => {
          deferred.reject(error);
        },
        processAjaxResponse: resp => {
          const jsonData = typeof resp === "string" ? JSON.parse(resp) : resp.data;
          return {
            jsonData: jsonData,
            statusCode: jsonData.meta && jsonData.meta.status || "ok"
          };
        }
      };
      opts.data = JSON.stringify(postObj);
      T1Comm.post(opts);
      return deferred.promise;
    };
    const hide = () => {
      destroy();
    };
    const destroy = () => {
      if (dialog) {
        dialog.remove();
        dialog.dialog("destroy");
      }
    };
    const sortName = (a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    };
    return {
      destroy: destroy,
      hide: hide,
      postData: postData,
      show: show
    };
  };
});
