define('modules/pixel/views/filterSearch',["jQuery", "T1", "T1View", "T1Permissions", "collections/pixelAdvertisers", "collections/agencies", "collections/dataproviders", "text!modules/pixel/templates/filterSearch.html"], function ($, T1, T1View, T1Permissions, Advertisers, Agencies, DataProviders, template) {
  const CAN_ACCESS_ADAPTIVE = T1Permissions.check("feature", "adaptive-segments");
  const adaptiveSegments = {
    text: "Adaptive Segments",
    value: "segment_advertisers"
  };
  const filterList = [{
    text: "Event Pixels",
    value: "advertisers"
  }, {
    text: "Data Pixels",
    value: "data"
  }];
  if (CAN_ACCESS_ADAPTIVE) filterList.push(adaptiveSegments);
  var PixelFilterSearch = T1View.extend({
    template: template,
    pixelTypeFilterList: filterList,
    pixelTypeLookupList: {
      advertisers: {
        type: "event",
        filter: "advertisers",
        placeholder: "Select One"
      },
      data: {
        type: "data",
        filter: "data",
        placeholder: "All data providers"
      },
      segment_advertisers: {
        type: "dynamic",
        filter: "advertisers",
        placeholder: "Select One"
      }
    },
    activeCollectionTypeInfo: null,
    activeCollection: null,
    pixelScopeList: [],
    events: {
      "keyup input": "searchPixels",
      "click .clear-filter": "clearFilter"
    },
    initialize: function (args) {
      var advertisers;
      var agencies = new Agencies();
      var dataProviders = new DataProviders();
      var strategy_id;
      var orgId = args.orgId;
      var filterConfig;
      if (!orgId) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
      this.advertiser = args.advertiser || null;
      strategy_id = args.strategy_id;
      advertisers = new Advertisers([], {
        strategy_id: strategy_id
      });
      this.activeCollectionTypeInfo = this.pixelTypeLookupList.advertisers;
      this.availableCollections = {
        advertisers: advertisers,
        agencies: agencies,
        data: dataProviders
      };
      if (args.getStrategyAgencyId) {
        this.getStrategyAgencyId = args.getStrategyAgencyId;
        dataProviders.urlFilter.set({
          entity: "agency",
          id: this.getStrategyAgencyId(),
          fetchRequired: false
        });
      }
      filterConfig = {
        entity: "organization",
        id: orgId,
        fetchRequired: false
      };
      agencies.urlFilter.set(filterConfig);
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        var $el = $(self.el);
        var primaryFilterBox = $el.find(".primaryFilter select");
        var secondaryFilterBox = $el.find(".secondaryFilter select");
        var primaryConfig;
        var secondaryConfig;
        var autoCompleteConfig;
        function applyFilter() {
          var primaryFilterValue = primaryFilterBox.val();
          var secondaryFilterValue = secondaryFilterBox.val();
          var secondaryFilterText = secondaryFilterBox.text();
          var isAdvertiserFilter = primaryFilterValue.indexOf("advertisers") > -1;
          var advertiser = secondaryFilterValue && !isAdvertiserFilter ? {
            id: secondaryFilterValue,
            name: secondaryFilterText
          } : self.advertiser;
          self.activeCollectionTypeInfo = self.pixelTypeLookupList[primaryFilterValue];
          self.renderPartial(".secondaryFilter select", {
            advertiser: advertiser,
            isEventPixel: isAdvertiserFilter
          });
          secondaryFilterBox.data("chosen").default_text = self.activeCollectionTypeInfo.placeholder;
          secondaryFilterBox.attr("data-placeholder", self.activeCollectionTypeInfo.placeholder);
          secondaryFilterBox.trigger("liszt:updated");
          secondaryFilterBox.trigger("clearDropDownPreviousSearch");
          self.applyFilter.call(self, self.activeCollectionTypeInfo.type, primaryFilterValue, isAdvertiserFilter ? advertiser.id : undefined);
        }
        primaryConfig = {
          searchFieldDisabled: true,
          onChange: applyFilter
        };
        T1.Selectbox(primaryFilterBox, primaryConfig);
        secondaryConfig = {
          searchFieldDisabled: false,
          onChange: function () {
            var primaryFilterValue = primaryFilterBox.val();
            var secondaryFilterValue = secondaryFilterBox.val();
            self.activeCollectionTypeInfo = self.pixelTypeLookupList[primaryFilterValue];
            self.applyFilter.call(self, self.activeCollectionTypeInfo.type, primaryFilterValue, secondaryFilterValue);
          }
        };
        autoCompleteConfig = {
          autoComplete: true,
          searchField: "name",
          onSearchRequest: function (searchOptions) {
            searchOptions = $.extend({
              fetchRequired: true
            }, searchOptions);
            self.activeCollection = self.availableCollections[self.activeCollectionTypeInfo.filter];
            self.activeCollection.search.set(searchOptions);
          }
        };
        T1.Selectbox(secondaryFilterBox, secondaryConfig, autoCompleteConfig);
      });
    },
    searchPixels: function (event) {
      var key = event.which;
      var $el = $(this.el);
      var $inputBox = $el.find(".term input");
      var $inputParent = $inputBox.parent();
      var term = $inputBox.val().trim();
      var $filterClearer = $el.find(".clear-filter");
      var hide_mag_glass = "hide-magnifying-glass";
      $inputParent.removeClass("error");
      if (term.length) {
        $inputBox.addClass(hide_mag_glass);
        $filterClearer.show();
      } else if (!this.isSearch) {
        $inputBox.removeClass(hide_mag_glass);
        $filterClearer.hide();
      }
      if (key === 13) {
        if (term.length === 0) {
          this.clearFilter(event);
        } else if (term.length < 3) {
          this.applyTooltip($inputBox, "Please enter at least 3 characters.");
          this.delegateEvents();
          this.showTooltip(event, $inputParent);
        } else {
          this.isSearch = true;
          T1.EventHub.publish("pixel:search", {
            term: term
          });
        }
      }
    },
    clearFilter: function (event) {
      var $el = $(this.el);
      var $inputBox = $el.find(".term input");
      event.preventDefault();
      $el.find(".clear-filter").hide();
      $inputBox.removeClass("hide-magnifying-glass").val("");
      this.removeTooltip({
        currentTarget: $inputBox
      });
      this.isSearch = false;
      T1.EventHub.publish("pixel:search", {
        term: ""
      });
    },
    applyFilter: function (entityType, filterType, id) {
      var filterConfiguration = {
        entity: filterType,
        type: entityType
      };
      if (id && entityType !== "data") {
        filterConfiguration[filterType + "_id"] = id;
      } else if (id) {
        filterConfiguration.provider_id = id;
      }
      T1.EventHub.publish("pixel:applyFilter", filterConfiguration);
    },
    serialize: function () {
      var self = this;
      var advertiser = this.advertiser;
      return {
        pixelTypeFilterList: self.pixelTypeFilterList,
        advertiser: advertiser,
        placeholder: self.activeCollectionTypeInfo.placeholder,
        isEventPixel: true
      };
    }
  });
  return T1.Form.ViewUtils.extend(PixelFilterSearch, ["applyTooltip", "showTooltip", "removeTooltip"]);
});
