define('modules/targeting/location/models/hyperLocalTargeting',["Underscore", "jQuery", "Backbone", "T1", "collections/organizations", "collections/hyperLocalDesigns", "collections/nemoTargets", "collections/nemoAttachments", "models/currencyConverter", "models/hyperLocalDesign", "models/nemoTarget", "models/nemoAttachment"], function (_, $, Backbone, T1, Organizations, Designs, Targets, Attachments, CurrencyConverter, Design, Target, Attachment) {
  "use strict";

  const CURRENCY_CODE_USD = "USD";
  return Backbone.Model.extend({
    parentId: 1,
    initialize: function ({
      strategy: strategy
    }) {
      this.strategy = strategy;
      this.strategyType = strategy.get("media_type");
      this.hyperLocalCollection = new Designs([], {
        strategyType: this.strategyType,
        strategy: this.strategy
      });
      this.nemoTargets = new Targets();
      this.hyperLocalAttachments = new Attachments();
      this.attachedGeofences = this.attachedGeofences || [];
      this.setGeoFenceCost();
    },
    saveHyperLocalAttachments: function (strategy) {
      var self = this;
      var deferred = $.Deferred();
      const geofencesInBins = this.getAddedGeofences({
        strategy: strategy
      });
      var promises = [];
      var attachmentsToDelete = findDeletedAttachments();
      function findDeletedAttachments() {
        var returnArr = [];
        _.each(self.attachedGeofences, function (geofence) {
          const fenceInBin = _.findWhere(geofencesInBins, {
            id: geofence.id
          });
          if (!fenceInBin) {
            const attachment = self.hyperLocalAttachments.findByResource({
              resource: geofence,
              type: "geofence"
            });
            returnArr.push(attachment);
          }
        });
        return returnArr;
      }
      function saveAttachment(target, geofence, callback) {
        var opts = {
          target: target,
          strategyID: self.strategy.id,
          action: geofence.get("action"),
          cpm: geofence.get("cpm"),
          callback: callback,
          geofence: geofence
        };
        self.createAttachment(opts);
      }
      Organizations.getOrganizations().getSelected().then(function (orgID) {
        self.selectedOrg = orgID;
        _.each(geofencesInBins, function (geofence) {
          let findOrCreateEntity;
          var def = $.Deferred();
          var type = geofence.get("type");
          var attachmentSaveCallback = function () {
            def.resolve();
          };
          promises.push(def);
          switch (type) {
            case "design":
              findOrCreateEntity = self.findOrCreateDesignTarget;
              break;
            case "set":
              findOrCreateEntity = self.findOrCreateSetTarget;
              break;
          }
          findOrCreateEntity.call(self, {
            geofence: geofence
          }).then(function (target) {
            var attachment = self.hyperLocalAttachments.findByTarget(target);
            if (attachment) {
              attachment.url = `${attachment.urlRoot}/${attachment.id}?currentTime=${T1.getRandomNumber()}`;
              self.editAttachment(attachment, geofence, attachmentSaveCallback);
            } else {
              saveAttachment(target, geofence, attachmentSaveCallback);
            }
          });
        });
        $.when(...promises).then(function () {
          self.deleteAttachments(attachmentsToDelete).then(function () {
            deferred.resolve();
          });
        });
      });
      return deferred.promise();
    },
    editAttachment: function (attachment, geofence, callback) {
      var savedAction = attachment.get("restriction").toLowerCase();
      var newAction = geofence.get("action");
      var savedOperator = attachment.get("operator");
      var newOperator = this.hyperLocalCollection.operators[newAction].toUpperCase();
      var changed = savedAction !== newAction || savedOperator !== newOperator;
      if (changed) {
        attachment.set({
          restriction: newAction.toUpperCase(),
          operator: newOperator.toUpperCase()
        });
        attachment.save().then(function () {
          callback.call();
        });
      } else {
        callback.call();
      }
    },
    deleteAttachments: function (attachments) {
      var deferred = $.Deferred();
      var promises = [];
      var self = this;
      if (attachments.length && attachments !== undefined) {
        _.each(attachments, function (attachment) {
          var def = $.Deferred();
          const geofence = _.findWhere(self.attachedGeofences, {
            id: attachment.geofence.id
          });
          promises.push(def);
          attachment.url = `${attachment.urlRoot}/${attachment.id}?currentTime=${T1.getRandomNumber()}`;
          attachment.destroy({
            success: function () {
              self.attachedGeofences = _.reject(self.attachedGeofences, g => g === geofence);
              def.resolve();
            }
          });
        });
        $.when(...promises).then(function () {
          deferred.resolve();
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise();
    },
    findDesignTarget: function ({
      geofence: geofence,
      depth: depth
    }) {
      var deferred = $.Deferred();
      const data = {
        factual_id: geofence.get("id")
      };
      if (depth) {
        data.depth = depth;
      }
      this.nemoTargets.singleFetch = true;
      this.nemoTargets.fetch({
        data: data,
        success: function (response) {
          if (response.length) {
            deferred.resolve(response.models[0]);
          } else {
            deferred.resolve();
          }
        },
        error: function () {
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    findSetTarget: function ({
      geofence: geofence,
      designTarget: designTarget
    }) {
      const childSets = designTarget.get("data");
      const setObj = _.findWhere(childSets, {
        factual_id: geofence.get("targeting_code")
      });
      if (setObj) {
        return new Target(setObj);
      }
    },
    findOrCreateSetTarget: function ({
      geofence: geofence
    }) {
      var def = $.Deferred();
      var parent = geofence.get("design");
      this.findOrCreateDesignTarget({
        geofence: parent,
        depth: 2
      }).then(designTarget => {
        const setTarget = this.findSetTarget({
          geofence: geofence,
          designTarget: designTarget
        });
        if (setTarget) {
          def.resolve(setTarget);
        } else {
          this.createSetTarget({
            geofence: geofence,
            designTarget: designTarget
          }).then(function (target) {
            def.resolve(target);
          });
        }
      });
      return def.promise();
    },
    findOrCreateDesignTarget: function ({
      geofence: geofence,
      depth: depth
    }) {
      var deferred = $.Deferred();
      this.findDesignTarget({
        geofence: geofence,
        depth: depth
      }).then(foundTarget => {
        if (foundTarget) {
          deferred.resolve(foundTarget);
        } else {
          this.createTarget(geofence).then(function (createdTarget) {
            deferred.resolve(createdTarget);
          });
        }
      });
      return deferred.promise();
    },
    createAttachment: function ({
      action: action,
      target: target,
      strategyID: strategyID,
      geofence: geofence,
      callback: callback
    }) {
      var attachment = new Attachment({
        target_id: target.get("id"),
        strategy_id: strategyID,
        restriction: action.toUpperCase(),
        operator: this.hyperLocalCollection.operators[action].toUpperCase()
      });
      attachment.save().then(({
        data: data
      }) => {
        this.attachedGeofences.push(geofence);
        this.hyperLocalAttachments.add(data);
        const savedAttachment = this.hyperLocalAttachments.get(data.id);
        savedAttachment.geofence = geofence;
        callback.call();
      });
    },
    createTarget: function (geofence, designTarget) {
      var deferred = $.Deferred();
      var dataObj = {
        name: geofence.get("name"),
        mediamath_organization_id: this.selectedOrg
      };
      if (geofence.get("type") === "set") {
        dataObj.factual_id = geofence.get("targeting_code");
        dataObj.parent_id = designTarget.id;
      } else {
        dataObj.factual_id = geofence.id;
        dataObj.parent_id = this.parentId;
      }
      const nemoTarget = new Target(dataObj);
      nemoTarget.save({}, {
        success: function (data) {
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    createSetTarget: function ({
      geofence: geofence,
      designTarget: designTarget
    }) {
      var def = $.Deferred();
      this.createTarget(geofence, designTarget).then(function (newSetTarget) {
        def.resolve(newSetTarget);
      });
      return def.promise();
    },
    attachmentToDesign: function (attachment) {
      let design;
      var def = $.Deferred();
      var self = this;
      const target = new Target({
        id: attachment.get("target_id")
      });
      target.fetch({
        success: function (response) {
          var onSuccess = function (type) {
            const returnObj = design.returnForTarget({
              target: target,
              type: type
            });
            returnObj.set({
              action: attachment.get("restriction")
            });
            def.resolve(returnObj);
          };
          var onError = function (collection, errResponse) {
            var resp = errResponse.resp;
            T1.EventHub.publish("comm:error", {
              code: resp.type,
              detail: `${errResponse.url}: ${resp.message}`
            });
          };
          if (response.get("parent_id") === 1) {
            design = new Design({
              id: response.get("factual_id"),
              strategy: self.strategy,
              advertiserId: self.strategy.get("campaign").get("advertiser_id")
            }, {
              collection: self.hyperLocalCollection
            });
            design.fetch({
              success: function () {
                onSuccess("design");
              },
              error: onError
            });
          } else {
            const parentTarget = new Target({
              id: response.get("parent_id")
            });
            parentTarget.fetch({
              success: function (resp) {
                design = new Design({
                  id: resp.get("factual_id"),
                  strategy: self.strategy,
                  advertiserId: self.strategy.get("campaign").get("advertiser_id")
                }, {
                  collection: self.hyperLocalCollection
                });
                design.fetch({
                  success: function () {
                    onSuccess("set");
                  },
                  error: onError
                });
              }
            });
          }
        }
      });
      return def.promise();
    },
    hasLoadedAttachments: function (view) {
      const attachments = _.filter(view.sharedCollection.models, model => model.get("dimension_code") === "hyperlocal");
      return Boolean(attachments.length);
    },
    getAttachedGeofences: function () {
      return _.pluck(this.hyperLocalAttachments.models, "geofence");
    },
    getAddedGeofences: function ({
      strategy: strategy,
      action: action
    }) {
      const dimensions = strategy.targetDimensions.get("target_dimensions");
      const hyperLocalDimension = _.findWhere(dimensions.models, {
        id: "hyperlocal"
      });
      var geofences = hyperLocalDimension.get("target_value").models;
      if (action) {
        return _.filter(geofences, geofence => geofence.get("action") === action);
      }
      return geofences;
    },
    getGeofencesForBadge: function () {
      return _.map(this.attachedGeofences, geofence => $.extend(true, {}, geofence.clone()));
    },
    setGeoFenceCost() {
      const {
        strategy: strategy
      } = this;
      if (!strategy.get("geofenceCost")) {
        const strategyType = this.strategyType.toUpperCase();
        const factualPrice = COMPASS_ENV.FACTUAL_RATE_CARD_PRICE[strategyType];
        const currencyCode = strategy.get("currency_code") || CURRENCY_CODE_USD;
        const setCost = price => {
          const geofenceCost = T1.Utils.formatCurrency(price, null, currencyCode).split(" ").join("");
          strategy.set({
            geofenceCost: geofenceCost
          }, {
            silent: true
          });
        };
        if (currencyCode === CURRENCY_CODE_USD) {
          setCost(factualPrice);
        } else {
          const currencyConverter = new CurrencyConverter();
          const currencySettings = {
            fromAmounts: [{
              amount: Number(factualPrice),
              index: 0
            }],
            fromCode: CURRENCY_CODE_USD,
            toCode: currencyCode
          };
          currencyConverter.getRate(currencySettings).then(resp => {
            if (resp && resp.length) {
              const [convertedPrice] = resp;
              setCost(convertedPrice.amount);
            } else {
              setCost();
            }
          });
        }
      }
    },
    getGeofenceCostDisplay: function (view) {
      var dimensions = view.strategy.targetDimensions.get("target_dimensions");
      const hyperLocalDimension = _.findWhere(dimensions.models, {
        id: "hyperlocal"
      });
      if (hyperLocalDimension && hyperLocalDimension.get("target_value").length || this.hasLoadedAttachments(view)) {
        return this.strategy.get("geofenceCost");
      }
      return "--";
    },
    findParentBySet: function (designId) {
      var parents = [];
      _.each(this.hyperLocalAttachments.models, function (attachment) {
        if (attachment.geofence.parent) {
          parents.push(attachment.geofence.parent);
        }
      });
      return _.find(parents, {
        id: designId
      });
    },
    fetchAttachedGeofences: function (onFetchDesign, onComplete) {
      var self = this;
      var deferred = $.Deferred();
      var firstCallback = onFetchDesign ? onFetchDesign : function (model, geofence) {
        model.geofence = geofence;
        self.hyperLocalCollection.add(model.geofence);
      };
      var completeAction = function (onCompleteAction) {
        self.attachedGeofences = self.getAttachedGeofences();
        if (onCompleteAction) {
          onCompleteAction();
        }
        deferred.resolve();
      };
      delete this.hyperLocalAttachments.pageField;
      delete this.hyperLocalAttachments.pageLimit;
      delete this.hyperLocalAttachments.pageLimitField;
      delete this.hyperLocalAttachments.page;
      this.hyperLocalAttachments.fetch({
        fetchOptions: {
          strategy_id: this.strategy.id,
          dimension: "HYPL"
        },
        success: function (response) {
          var i = 0;
          if (response.length) {
            const models = response.models;
            _.each(models, function (model) {
              var operator = model.get("operator").toLowerCase();
              var restriction = model.get("restriction").toLowerCase();
              if (!self.hyperLocalCollection.operators[restriction]) {
                self.hyperLocalCollection.operators[restriction] = operator;
              }
              self.attachmentToDesign(model).then(function (geofence) {
                firstCallback(model, geofence);
                i++;
                if (i === models.length) {
                  completeAction(onComplete);
                }
              });
            });
          } else {
            completeAction(onComplete);
          }
        }
      });
      return deferred.promise();
    }
  });
});
