define('modules/audiences/segments/behavior/utils/dateAttributeUtils',["jQuery", "Underscore", "moment", "T1"], function ($, _, moment, T1) {
  "use strict";

  var MAXIMUM_UNITS = {
    Hours: 8760,
    Days: 365,
    Weeks: 52,
    Months: 12
  };
  var capitalize = T1.Utils.capitalize;
  function getID(obj) {
    return obj.attr_id.toString();
  }
  function generateRightFilter(attribute) {
    switch (attribute.op) {
      case "within last":
      case "not within last":
      case "within next":
      case "not within next":
        return generateRelative(attribute);
      case "after":
      case "before":
        return generateAbsolute(attribute);
      case "between":
      case "not between":
        return generateBetween(attribute);
    }
  }
  function generateRelative(attribute) {
    var relativeOperators = {
      "within last": "in last",
      "not within last": "not within",
      "within next": "in next",
      "not within next": "not within next"
    };
    var values = attribute.values;
    return {
      op: relativeOperators[attribute.op],
      count: parseInt(values.count, 10),
      unit: values.unit.toLowerCase()
    };
  }
  function loadRelative(json) {
    var left = json.left;
    var right = json.right;
    var relativeOperators = {
      "in last": "within last",
      "not within": "not within last",
      "in next": "within next",
      "not within next": "not within next"
    };
    return {
      datetime: true,
      id: getID(left),
      op: relativeOperators[right.op],
      values: {
        count: right.count.toString(),
        unit: capitalize(right.unit)
      }
    };
  }
  function generateAbsolute(attribute) {
    return {
      op: attribute.op,
      date: formatDate(attribute.values)
    };
  }
  function loadAbsolute(json) {
    var left = json.left;
    var right = json.right;
    return {
      datetime: true,
      id: getID(left),
      op: right.op,
      values: formatDateForLoading(right.date)
    };
  }
  function generateBetween(attribute) {
    var values = attribute.values;
    var sortedDates;
    if (values.type === "relative") {
      sortedDates = sortDates(values.dates);
      sortedDates.op = attribute.op;
      return sortedDates;
    } else if (values.type === "custom") {
      return {
        op: attribute.op,
        start_date: formatDate(values.start),
        end_date: formatDate(values.end)
      };
    }
  }
  function loadBetween(json) {
    var left = json.left;
    var right = json.right;
    if (right.start_count) {
      return {
        datetime: true,
        id: getID(left),
        op: right.op,
        values: {
          type: "relative",
          dates: [{
            count: parseInt(right.start_count, 10),
            schedule: right.start_schedule,
            unit: capitalize(right.start_unit)
          }, {
            count: parseInt(right.end_count, 10),
            schedule: right.end_schedule,
            unit: capitalize(right.end_unit)
          }]
        }
      };
    } else {
      return {
        datetime: true,
        id: getID(left),
        op: right.op,
        values: {
          end: formatDateForLoading(right.end_date),
          start: formatDateForLoading(right.start_date),
          type: "custom"
        }
      };
    }
  }
  function sortDates(dates) {
    var schedule = {
      ago: -1,
      ahead: 1
    };
    var converted1 = convert(dates[0].count, dates[0].unit) * schedule[dates[0].schedule];
    var converted2 = convert(dates[1].count, dates[1].unit) * schedule[dates[1].schedule];
    var start_date = converted1 < converted2 ? dates[0] : dates[1];
    var end_date = converted1 < converted2 ? dates[1] : dates[0];
    return {
      start_count: parseInt(start_date.count, 10),
      start_unit: start_date.unit.toLowerCase(),
      start_schedule: start_date.schedule,
      end_count: parseInt(end_date.count, 10),
      end_unit: end_date.unit.toLowerCase(),
      end_schedule: end_date.schedule
    };
  }
  function formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  function formatDateForLoading(date) {
    return moment(date).format("MM/DD/YYYY");
  }
  function convert(value, unit) {
    switch (unit) {
      case "Minutes":
        return parseInt(value, 10);
      case "Hours":
        return value * 60;
      case "Days":
        return value * 60 * 24;
      case "Weeks":
        return value * 60 * 24 * 7;
      case "Months":
        return value * 60 * 24 * 30;
    }
  }
  function validUnit(value, unit) {
    return value <= MAXIMUM_UNITS[unit];
  }
  function maximumNumberMessage(units) {
    return "Please enter a number less than or equal to " + MAXIMUM_UNITS[units];
  }
  function getValue(row, id) {
    return $("#" + row.id + "-" + id, row)[0].value;
  }
  function toSteelRain(attribute) {
    return {
      op: "datetime",
      left: {
        op: "attribute",
        attr_id: parseInt(attribute.id, 10)
      },
      right: generateRightFilter(attribute)
    };
  }
  function convertJSONSDLforLoading(json) {
    switch (json.right.op) {
      case "in last":
      case "not within":
      case "in next":
      case "not within next":
        return loadRelative(json);
      case "after":
      case "before":
        return loadAbsolute(json);
      case "between":
      case "not between":
        return loadBetween(json);
    }
  }
  function selectDateRowType(type, row) {
    var containers = ["datepicker", "daterange", "date-between"];
    var rowToShow = $(".value-" + type + "-container", row);
    var classesToHide = _.reject(containers, function (container) {
      return container === type;
    }).map(function (klass) {
      return ".value-" + klass + "-container";
    }).join(", ");
    var rowsToHide = $(classesToHide, row);
    if (!rowToShow.is(":visible")) {
      rowToShow.show();
      rowsToHide.hide();
    }
  }
  function populateBetweenRelative(row, rowId, dates) {
    $("#" + rowId + "-select-schedule-number-1", row).val(dates[0].schedule);
    $("#" + rowId + "-select-schedule-number-2", row).val(dates[1].schedule);
    $("#" + rowId + "-select-time-range-unit-1", row).val(dates[0].unit);
    $("#" + rowId + "-select-time-range-unit-2", row).val(dates[1].unit);
    $("#" + rowId + "-select-time-range-number-1", row).val(dates[0].count);
    $("#" + rowId + "-select-time-range-number-2", row).val(dates[1].count);
  }
  function populateBetweenCustom(ctx, row, value) {
    var dualDP = row.find(".dual-dp")[0];
    dualDP.startDate = formatDateForLoading(value.start);
    dualDP.endDate = formatDateForLoading(value.end);
    $(".between-relative-container", row).hide();
    $(".between-datepicker-container", row).show();
    row.find(".dual").text(ctx.parseDateforDatePicker(value.start) + " to " + ctx.parseDateforDatePicker(value.end));
  }
  function resetBetweenContainer(row) {
    row.find(".dual").text("Select Timeframe");
    $(".between-datepicker-container", row).hide();
    $(".between-relative-container", row).show();
    $(".input-time-unit, .select-time-schedule", row).each(function (i, ddl) {
      ddl.reset();
    });
    $("strand-input", row).val("");
  }
  function populateRelative(row, values, initialLoad) {
    var value, unit;
    if (values && initialLoad) {
      value = parseInt(values.count);
      unit = values.unit;
    }
    $(".value-daterange-number", row).val(value || "");
    $(".value-daterange-interval", row).val(unit || "");
    $(".value-daterange-interval", row).attr("data-placeholder", "Select Interval");
  }
  function populateAbsolute(ctx, row, value, initialLoad) {
    var startDate, displayText;
    if (initialLoad && value) {
      displayText = ctx.parseDateforDatePicker(value);
      startDate = formatDateForLoading(value);
    }
    row.find(".single-dp")[0].startDate = startDate || "";
    row.find(".single").text(displayText || "Select Timeframe");
  }
  return {
    convert: convert,
    validUnit: validUnit,
    maximumNumberMessage: maximumNumberMessage,
    toSteelRain: toSteelRain,
    getValue: getValue,
    convertJSONSDLforLoading: convertJSONSDLforLoading,
    formatDateForLoading: formatDateForLoading,
    selectDateRowType: selectDateRowType,
    populateBetweenRelative: populateBetweenRelative,
    populateBetweenCustom: populateBetweenCustom,
    populateRelative: populateRelative,
    resetBetweenContainer: resetBetweenContainer,
    populateAbsolute: populateAbsolute
  };
});
