define('modules/creatives/concept/views/creative_grid',["jQuery", "Underscore", "T1", "moment", "T1View", "T1GridViewV2", "models/videoCreative", "text!../templates/creative_grid.html", "utils/CreativeUtils"], function ($, _, T1, moment, T1View, createGrid, VideoCreative, itemTemplate, CreativeUtils) {
  "use strict";

  var GridView = T1View.extend({
    selected: "",
    partials: {
      listItem: itemTemplate
    },
    cors: COMPASS_ENV.VIDEO_API_BASE + "/creatives/proxy/?url=",
    headers: [{
      name: "creative-name",
      label: "creative name"
    }, {
      name: "id",
      label: "creative id"
    }, {
      name: "status",
      label: "status"
    }, {
      name: "externalID",
      label: "concept (id)"
    }, {
      name: "type",
      label: "type"
    }, {
      name: "details",
      label: "dimension/duration"
    }, {
      name: "secure",
      label: "secure"
    }, {
      name: "drag",
      label: ""
    }],
    dataEvents: {},
    events: {
      "click .item .include": "includeExcludeCreativeList",
      "click .item .exclude": "includeExcludeCreativeList"
    },
    includeExcludeCreativeList: function (event) {
      var currentTarget = $(event.currentTarget);
      var id = currentTarget.closest(".item").data("id");
      this.preventDefault(event);
      this.removeDragClasses();
      this.assignedCreativeList({
        id: id
      });
    },
    eventhubEvents: {
      "assigned:creativeList": "assignedCreativeList",
      "bins:droppedCreativeList": function (data) {
        var self = this;
        var sharedCollection = self.sharedCollection;
        $.each(data.selectedList, function (index, item) {
          var model = sharedCollection.get(item.id);
          if (model && model.get("assigned") === "0" || model === undefined) {
            self.assignedCreativeList.call(self, item);
          }
        });
      },
      "creativeList:setSearchLoader": function () {
        this.setSearchLoader();
      },
      "grid:setHighlightValue": function (data) {
        this.highlightValue = data;
      }
    },
    assignedCreativeList: function (data) {
      var self = this;
      var id = data.id;
      var item = this.collection.get(id);
      var rowCount = 0;
      var assignToggle, itemInSharedCollection;
      itemInSharedCollection = self.sharedCollection.get(id);
      if (itemInSharedCollection) {
        self.sharedCollection.remove(item);
      }
      assignToggle = itemInSharedCollection && itemInSharedCollection.get("assigned") === "1" ? "0" : "1";
      item.set({
        assigned: assignToggle
      });
      self.sharedCollection.add(item);
      self.sharedCollection.each(function (model) {
        if (model.get("assigned") === "1") {
          rowCount++;
        }
      });
      $(".assign-count").html("(" + rowCount + ")");
    },
    initialize: function (args) {
      var self = this;
      var rowCount = 0;
      if (args.collection) {
        self.collection = args.collection;
        this.addDataEvent({
          collection: {
            reset: function () {
              this.load();
            }
          }
        });
      }
      if (args.sharedCollection) {
        this.sharedCollection = args.sharedCollection;
        this.addDataEvent({
          sharedCollection: {
            add: function (data) {
              var $item = self.el.find('[data-id="' + data.id + '"]');
              var $itemRow = $item.children(".item-row");
              if (data.get("assigned") === "1") {
                $itemRow.addClass("selected").find(".icon").addClass("active").end().parent().addClass("selected-item");
              }
              this.isFormElementsChanged = true;
            },
            remove: function (data) {
              var $item = self.el.find('[data-id="' + data.id + '"]');
              var $itemRow = $item.children(".item-row");
              $itemRow.removeClass("selected").find(".icon").removeClass("active").end().parent().removeClass("selected-item");
              this.isFormElementsChanged = true;
            }
          }
        });
        this.sharedCollection.each(function (model) {
          if (model.get("assigned") === "1") {
            rowCount++;
          }
        });
        $(".assign-count").html("(" + rowCount + ")");
      }
      if (args.searchTerm) {
        this.highlightValue = args.searchTerm;
      }
      this.preventDoubleBind = true;
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 200,
      loaderOpts: {
        target: ".w-DataGrid",
        text: "loading...",
        contentClass: "search-loader concept-creative-load",
        action: "append"
      }
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".name"
    },
    scrollContentRendered: function (newColl) {
      newColl.each(function (model) {
        var self = this;
        var ID = model.get("id");
        var detail = "--";
        var mediaType = model.get("media_type");
        var width, height, duration, vast, vc;
        if (mediaType === "video") {
          vc = new VideoCreative({
            id: ID
          });
          vc.sync("read", {
            id: ID
          }, {
            onSuccess: function (data) {
              if (data.duration) {
                duration = moment.utc(data.duration * 1e3).format("mm:ss");
              } else if (data.details) {
                if (data.details.customVAST) {
                  vast = data.details.customVAST;
                  try {
                    self.xmlDoc = $.parseXML(vast.trim()).documentElement;
                  } catch (err) {
                    $("#dtls" + ID).html("--");
                    return;
                  }
                  duration = $(self.xmlDoc).find("Duration").first().text().substr(-5);
                  duration = duration ? duration : "--";
                } else if (data.details.customVASTUrl) {
                  vast = data.details.customVASTUrl;
                  try {
                    self.xmlDoc = self.cors + encodeURIComponent(vast);
                  } catch (err) {
                    $("#dtls" + ID).html("--");
                    return;
                  }
                  $.get(self.xmlDoc, function (xmlData) {
                    self.xmlDoc = xmlData;
                    duration = $(self.xmlDoc).find("Duration").first().text().substr(-5);
                    duration = duration ? duration : "--";
                  });
                }
              }
              model.set({
                trait: duration
              });
              $("#dtls" + ID).html(model.get("trait"));
            },
            onError: function () {
              $("#dtls" + ID).html("--");
            },
            errorDisplaySuppressingConfig: {
              errorCodes: [404]
            },
            dataType: "json",
            processAjaxResponse: function (resp) {
              return {
                jsonData: resp,
                statusCode: resp.status.code || "ok"
              };
            }
          });
        } else {
          width = model.get("width");
          height = model.get("height");
          detail = width + "x" + height;
          model.set({
            trait: detail
          });
          $("#dtls" + ID).html(model.get("trait"));
        }
      });
    },
    serialize: function (inputCollection) {
      var self = this;
      var sharedCollection = this.sharedCollection;
      var creativeListData = [];
      var collection = inputCollection || this.collection;
      var collectionData, sslSecurity, sslValidator;
      if (collection) {
        collectionData = collection.toJSON();
        $.each(collectionData, function (index, creativeList) {
          var creativeData = creativeList;
          var sharedCreative;
          creativeData.showName = CreativeUtils.replaceSymbolsInName(creativeData.name);
          creativeData.showId = creativeData.id;
          if (creativeData.concept) {
            creativeData.showConceptName = CreativeUtils.replaceSymbolsInName(creativeData.concept.name);
            creativeData.showConceptId = creativeData.concept.id;
            creativeData.showConceptNameTitle = creativeData.showConceptName + "(" + creativeData.showConceptId + ")";
          }
          if (self.highlightValue) {
            self.highlightValue = decodeURIComponent(CreativeUtils.replaceSymbolsInName(self.highlightValue));
            creativeData.showName = T1.Utils.highlightText(creativeData.showName, self.highlightValue);
            creativeData.showId = T1.Utils.highlightText(creativeData.showId, self.highlightValue);
            creativeData.file_type = T1.Utils.highlightText(creativeData.file_type, self.highlightValue);
            if (creativeData.concept) {
              creativeData.showConceptName = T1.Utils.highlightText(creativeData.showConceptName, self.highlightValue);
              creativeData.showConceptId = T1.Utils.highlightText(creativeData.concept.id, self.highlightValue);
            }
          }
          if (sharedCollection) {
            sharedCreative = sharedCollection.get(creativeList.id);
            if (sharedCreative && sharedCreative.get("assigned") === "1") {
              creativeData.isIncluded = true;
              creativeData.isSelected = true;
              creativeData.assigned = "1";
            }
          }
          sslValidator = creativeData.atomic_creative_ssl_validator;
          if (sslValidator && sslValidator.ignored !== "YES") {
            creativeData.useSSL = true;
            sslSecurity = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
            switch (sslSecurity) {
              case "Yes":
              case "No":
                creativeData.isSecure = sslSecurity;
                creativeData.verifiedSecure = sslSecurity === "Yes";
                break;
              case "Pending":
                creativeData.isSecure = sslSecurity;
                creativeData.useSSL = false;
                break;
              case "Unknown":
              case "Inactive":
              case "Error":
                creativeData.isSecure = creativeData.is_https === "1" ? "Yes" : "No";
                creativeData.useSSL = false;
                break;
            }
          } else {
            creativeData.useSSL = false;
            creativeData.isSecure = creativeData.is_https === "1" ? "Yes" : "No";
          }
          creativeData.isActive = creativeData.status === "1";
          if (creativeData.status === "1") {
            creativeData.isStatus = "Active";
          } else {
            creativeData.isStatus = "Inactive";
          }
          creativeData.fileType = creativeData.file_type === "vast" ? "video" : creativeData.file_type;
          creativeListData.push(creativeData);
        });
      }
      return {
        list: creativeListData,
        contentType: "creative-list-grid"
      };
    }
  });
  return createGrid(GridView);
});
