define('modules/T1App/edit/access/users/views/userSearch',["jQuery", "Underscore", "T1", "T1View", "T1Comm", "text!modules/T1App/edit/access/users/templates/userSearch.html", "utils/AdminUtils", "T1Model", "T1Layout"], function ($, _, T1, T1View, T1Comm, template, AdminUtils) {
  "use strict";

  return T1View.extend({
    template: template,
    events: {
      "keyup .search-input": "onSearchInputKeyUp",
      "click #clearBtn": "clearFilter"
    },
    defaultOptions: {
      searchInputDelay: 500,
      searchInputMinChar: 3
    },
    initialize: function (args) {
      this.model = args.model;
      this.options = $.extend(args || {}, this.defaultOptions);
      this.lazySearch = _.debounce(this.doSearch, this.options.searchInputDelay);
    },
    onSearchInputKeyUp: function (evt) {
      this.term = evt.target.value;
      this.lazySearch(evt.target);
    },
    clearFilter: function () {
      T1.EventHub.publish("teamModel.gridDataChange");
    },
    doSearch: function (target) {
      var invalidShortSearch = "Please enter at least " + this.options.searchInputMinChar + " characters.";
      var publish = T1.EventHub.publish;
      var value = this.term;
      if (!this.loaded) {
        return;
      }
      function showErrorTooltip(errorMsg) {
        var $target = $(target);
        var errorConfig = {
          trigger: "manual",
          getExternalTip: false,
          className: "w-FormError",
          offset: 10
        };
        errorConfig.tooltipEle = target;
        errorConfig.trigger = "hover";
        errorConfig.gravity = "s";
        target.error = true;
        $target.attr("title", errorMsg);
        if ($target.data("tipsy")) {
          $target.tipsy("show");
        }
        return T1.Tooltip(target, errorConfig);
      }
      if (this.term.length > 0) {
        if (this.term.length < this.options.searchInputMinChar) {
          if (target.getAttribute("original-title") !== invalidShortSearch) {
            this.errorTooltip = showErrorTooltip(invalidShortSearch);
          }
          return;
        } else {
          this.searchField = this.options.getSearchField ? this.options.getSearchField(this.term) : !$.isNumeric(this.term) ? "name|id" : this.options.defaultSearchField || "multiField";
        }
      }
      publish("search:onSearchRequest", {
        field: this.searchField,
        value: AdminUtils.htmlEncode(value)
      });
      if (this.errorTooltip) {
        target.setAttribute("original-title", "");
        this.errorTooltip.unbind();
        $(".tipsy").remove();
      }
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      $el.html(template);
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
