define('modules/T1App/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "tabs",
    name: "T1App",
    viewCfgs: {
      tabs: {}
    }
  });
});
