define('modules/advertiser/main',["jQuery", "T1", "T1Module", "collections/advertisers", "collections/agencies", "collections/organizations"], function ($, T1, T1Module, Advertisers, Agencies, Organizations) {
  return new T1Module({
    name: "advertiser",
    viewCfgs: {
      select: {
        collection: null
      }
    },
    defaultView: "select",
    getView: function (type, opts) {
      var collection = new Advertisers(null, {
        pageLimit: 50
      });
      var orgPromise = Organizations.getOrganizations().getSelected();
      var self = this;
      orgPromise.then(function (orgId) {
        collection.urlFilter.set({
          entity: "organization",
          id: orgId,
          fetchRequired: false
        });
      });
      self.viewCfgs.select.collection = collection;
      return T1Module.prototype.getView.call(self, type, opts);
    }
  });
});
