define('modules/creatives/concept/views/list',["jQuery", "Underscore", "moment", "When", "async", "T1", "T1View", "T1Layout", "T1Spinner", "T1Permissions", "text!../templates/list.html", "text!../templates/listBody.html", "collections/creatives", "collections/creativesConcept", "collections/creativesConceptsCampaigns", "collections/strategies", "collections/creativesConceptsStrategies", "collections/organizations", "utils/SearchBuilder", "../models/creativeActive", "models/concept", "models/advertiser", "models/userPreferences", "T1Preferences", "jQPlugins/jquery.cookie"], function ($, _, moment, When, Async, T1, T1View, T1Layout, Spinner, T1Permissions, template, templateBody, Creatives, ConceptsCollection, Campaigns, Strategies, StrategyConcepts, Organizations, SearchBuilder, selectOptions, ConceptsModel, Advertiser, UserPreferences) {
  return T1View.extend({
    template: template,
    searchTerm: "",
    advID: "",
    active: "",
    statusValue: {},
    campCollectStrategies: {},
    campCollectStrategyConcepts: {},
    stratCollectStrategyConcepts: {},
    fetchCalls: {},
    spinnerOpts: {
      lines: 14,
      length: 3,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 10,
      speed: 1
    },
    events: {
      "click .add-action": "addConcept",
      "changed:checkbox .w-grid": "gridChange",
      "focus .w-Filters input.text": "showSearchTooltip"
    },
    dataEvents: {
      collection: {
        reset: "onCollectionEvent",
        "change:selected": "onItemSelected"
      }
    },
    eventhubEvents: {
      "assignModal:saved": "search",
      "organization:select": function () {
        this.searchBuilder.clearFlag("advertiser", "id");
        this.advID = null;
        this.preferences.save();
        this.filterAndClearCallback("", null, true);
        this.initLayout();
        this.load();
      },
      "track:currentFetch": function (data) {
        this.fetchCalls[data.page] = data.target;
      }
    },
    onCollectionEvent: function () {
      this.isDisabled = this.collection.loaded === false;
      this.toggleFilters(this.isDisabled);
      this.toggleBulkAction(this.isDisabled);
    },
    toggleFilters: function (isDisabled) {
      var self = this;
      var $el = $(self.el);
      $el.find(".status .select-input").attr("disabled", isDisabled).trigger("liszt:updated").toggleClass("disabled", isDisabled);
    },
    onItemSelected: function () {
      this.toggleBulkAction(this.isDisabled);
    },
    toggleBulkAction: function (isDisabled) {
      var selected = this.collection.getSelected();
      var subselected = $(".open").find(".checkbox").hasClass("checked");
      if (this.topcheck === false && this.subcheck === true) {
        $("a[data-id='0']").hide();
        $("a[data-id='1']").hide();
      } else {
        $("a[data-id='0']").show();
        $("a[data-id='1']").show();
      }
      if (!isDisabled && (selected.length > 0 || subselected)) {
        T1.EventHub.publish("pullDownButton:bulk-action:enable");
      } else {
        T1.EventHub.publish("pullDownButton:bulk-action:disable");
      }
    },
    initialize: function (args) {
      var self = this;
      var isReturning = $.cookie("isReturningConcept");
      var signature = T1.Signature.create();
      this.advertiserCollection = args.advertisers;
      this.allAdvertisersLoaded = false;
      this.topcheck = false;
      this.subcheck = false;
      signature.set(new Map([["root", "ui"], ["module", "creatives.concept"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["active", "searchTerm", "advID"]);
      if (isReturning === "true") {
        $.removeCookie("isReturningConcept");
        this.searchTerm = "";
        this.advID = "";
        this.active = "active";
        this.preferences.save();
      } else {
        this.searchTerm = this.preferences.get("searchTerm") || "";
        this.advID = this.preferences.get("advID") || null;
        this.active = this.preferences.get("active") || "Active";
      }
      this.searchBuilder = new SearchBuilder({
        active_state: {
          active: {
            term: "1",
            searchField: "status"
          },
          inactive: {
            term: "0",
            searchField: "status"
          }
        },
        advertiser: {
          id: {
            term: this.advID,
            searchField: "advertiser_id"
          }
        },
        search: {
          input: {
            term: this.searchTerm,
            searchField: "multiField"
          }
        }
      });
      if (this.advID === null) {
        this.searchBuilder.clearFlag("advertiser", "id");
      }
      $(window).one("beforeunload", function () {
        $.cookie("isReturningConcept", true);
        $.cookie("isReturning", true);
        return null;
      });
      if (this.active.toLowerCase() !== "active & inactive") {
        this.searchBuilder.setFlag("active_state", this.active.toLowerCase());
      }
      this.collection = new ConceptsCollection(null, {
        pageLimit: 30
      });
      this.collection.updateOrgFilter();
      Organizations.getOrganizations().getSelected().then(function (orgId) {
        self.orgId = orgId;
      });
      this.getAdvertiserData().then(function () {
        self.initLayout();
        self.load();
      });
    },
    getAdvertiserData: function () {
      var self = this;
      var deferred = When.defer();
      self.advertiserCollection.isPaginated = true;
      self.advertiserCollection.pageLimit = 50;
      self.advertiserCollection.fetch({
        success: function () {
          if (self.searchTerm || self.advID && self.advID !== null && self.advID !== "null") {
            self.filterAndClearCallback(self.searchTerm, {
              id: self.advID
            }, false);
          } else {
            self.filterAndClearCallback("", null, false);
          }
          self.allAdvertisersLoaded = true;
          if (self.advID && self.advID !== "null") {
            var model = self.advertiserCollection.get(self.advID);
            if (model) {
              model.set({
                selected: true
              });
              self.advertiserCollection.add(model, {
                merge: true
              });
              deferred.resolve(self.advertiserCollection);
            } else {
              model = new Advertiser({
                id: self.advID
              });
              model.fetch({
                success: function (adModel) {
                  adModel.set({
                    selected: true
                  });
                  self.advertiserCollection.add(adModel);
                  deferred.resolve(self.advertiserCollection);
                }
              });
            }
          } else {
            deferred.resolve(self.advertiserCollection);
          }
        }
      });
      return deferred.promise;
    },
    getAdvertiserList: function () {
      var options = this.advertiserCollection.map(function (adver) {
        return {
          text: adver.get("name"),
          value: adver.id,
          selected: adver.get("selected")
        };
      });
      options.unshift({
        text: "All Advertisers",
        value: "all"
      });
      return options;
    },
    filterAndClearCallback: function (term, flags, savePref) {
      var save = savePref === undefined ? true : savePref;
      this.advID = flags && flags.id !== "" ? flags.id : this.advID;
      if (this.advID && this.advID !== "all" && this.advID !== "null") {
        this.searchBuilder.setFlag("advertiser", "id", {
          term: this.advID
        });
      } else {
        if (this.advIDPref === "all") {
          this.clearTheFilter();
        }
        this.searchBuilder.clearFlag("advertiser", "id");
        this.advID = null;
      }
      this.searchTerm = term || "";
      if (save) {
        this.preferences.save();
      }
      this.search();
    },
    reload: function () {
      var self = this;
      this.initialize({
        advertisers: self.advertiserCollection
      });
    },
    initLayout: function () {
      var self = this;
      var searchTerm = self.searchTerm;
      var advertiserList;
      if (self.allAdvertisersLoaded) {
        advertiserList = $.proxy(self.getAdvertiserList, this);
        if (this.layout) {
          this.layout.destroy();
        }
        if (searchTerm) {
          searchTerm = decodeURIComponent(searchTerm);
        }
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".list-filters",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: {
            ".search": [{
              module: "shared",
              viewType: "filter",
              options: {
                defaultValue: searchTerm,
                filterClass: "advertiser-select",
                selectOptions: advertiserList,
                isDisabled: function () {
                  return false;
                },
                filterCallback: $.proxy(self.filterAndClearCallback, this),
                clearFilterCallback: $.proxy(self.filterAndClearCallback, this),
                autoCompleteConfig: {
                  autoComplete: true,
                  searchField: "name",
                  onSearchInputCleared: $.proxy(self.clearTheFilter, this),
                  onSearchRequest: function (searchOptions) {
                    self.searchOptions = searchOptions;
                    searchOptions = $.extend({
                      fetchRequired: true
                    }, searchOptions);
                    self.advertiserCollection.search.set(searchOptions);
                    self.el.find(".advertiser-select:first").val("All Advertiser");
                  }
                }
              }
            }],
            ".creative-assign-modal": [{
              module: "creatives/concept",
              viewType: "assignModal",
              options: {
                orgId: self.orgId
              }
            }],
            ".bulk-action": [{
              module: "shared",
              viewType: "pullDownButton",
              options: {
                id: "bulk-action",
                menuLabel: "Choose Action",
                items: [{
                  itemLabel: "Activate",
                  itemCallback: $.proxy(self.bulkAction, self, {
                    status: "1"
                  })
                }, {
                  itemLabel: "Deactivate",
                  itemCallback: $.proxy(self.chooseConfirmAction, self, {
                    status: "0"
                  })
                }, {
                  itemLabel: "Unassign Creatives",
                  itemCallback: $.proxy(self.unassaignCreatives, self)
                }],
                disabled: true
              }
            }],
            ".w-grid": [{
              module: "creatives/concept",
              viewType: "grid",
              options: {
                collection: self.collection,
                searchTerm: self.searchTerm
              }
            }]
          }
        });
      }
    },
    clearTheFilter: function () {
      var searchOptions = $.extend({
        fetchRequired: true
      }, this.searchOptions);
      this.advertiserCollection.search.clear();
      this.advertiserCollection.fetch(searchOptions);
    },
    showSearchTooltip: function (e) {
      var $target = $(e.currentTarget);
      var $parent = $target.parent();
      var className = "w-DisplaySearchTooltip";
      var crossSearchEnabled = T1Permissions.check("feature", "creatives_concepts_search");
      var msg = crossSearchEnabled ? "Search by Name, ID, Advertiser, Campaign Name, Campaign ID, Strategy Name or Strategy ID" : "Search by Name, ID or Advertiser";
      $(`.${className}`).remove();
      T1.Tooltip($target, {
        trigger: "manual",
        tooltipEle: $parent,
        getExternalTip: false,
        gravity: "s",
        className: className,
        offset: 10
      });
      $parent.attr("title", msg).tipsy("show");
    },
    unassaignCreatives: function () {
      var self = this;
      var selected = this.collection.getSelected();
      var selectedIDs = [];
      var allCreatives, allModelValue, gridHeadID, selectCreatives, selectModelValue;
      this.conceptLoader = {};
      this.includeIDs = [];
      this.masterIDs = [];
      this.subIDs = [];
      this.masterqueue = [];
      this.subqueue = [];
      Async.parallel({
        handleMasterChecked: function (callback) {
          if (selected && selected.length > 0) {
            selected.each(function (model) {
              var id = model.id;
              if (!$(`#grid${id}`).hasClass("open")) {
                self.masterIDs.push(id);
                self.conceptLoader[id] = self.createInlineLoader(`#id${id}`);
                self.conceptLoader[id].start();
              }
            });
            if (self.masterIDs.length > 0) {
              allCreatives = new Creatives(null, {
                pageLimit: 100
              });
              allCreatives.canCache = false;
              allCreatives.isPaginated = false;
              allCreatives.urlFilter.set({
                entity: "concept",
                id: `(${self.masterIDs})`
              });
              allCreatives.fetch({
                success: function (data) {
                  data.each(function (model) {
                    var deferred = When.defer();
                    allModelValue = {
                      concept_id: ""
                    };
                    model.save(allModelValue, {
                      success: function () {
                        deferred.resolve();
                      },
                      conflict: function () {
                        deferred.reject();
                      }
                    });
                    self.masterqueue.push(deferred.promise);
                  });
                  When.all(self.masterqueue).then(function () {
                    callback();
                  });
                }
              });
            } else {
              callback();
            }
          } else {
            callback();
          }
        },
        handleSubChecked: function (callback) {
          if ($(".open").find(".sub-check").hasClass("checked")) {
            $(".open").each(function (i, e) {
              if ($(e).find(".sub-check").hasClass("checked")) {
                var openID = $(e).data("id");
                self.subIDs.push(openID);
                self.conceptLoader[openID] = self.createInlineLoader(`#grid${openID}`);
                self.conceptLoader[openID].start();
              }
            });
            $(".open").find(".sub-check").each(function (i, e) {
              var $itemID = Number($(e).data("id"));
              if ($(e).hasClass("checked")) {
                self.includeIDs.push($itemID);
              }
            });
            selectCreatives = new Creatives(null, {
              pageLimit: 100
            });
            selectCreatives.canCache = false;
            selectCreatives.isPaginated = false;
            selectCreatives.urlFilter.set({
              entity: "concept",
              id: `(${self.subIDs})`
            });
            selectCreatives.fetch({
              success: function (data) {
                data.each(function (model) {
                  var subdeferred = When.defer();
                  if ($.inArray(Number(model.id), self.includeIDs) > -1) {
                    selectModelValue = {
                      concept_id: ""
                    };
                    model.save(selectModelValue, {
                      success: function () {
                        var conceptID = $(`#item${model.id}`).parent().data("id");
                        var creativeCount = Number($(`#act${conceptID}`).html());
                        creativeCount--;
                        $(`#act${conceptID}`).html(creativeCount);
                        $(`#item${model.id}`).remove();
                        subdeferred.resolve();
                      },
                      conflict: function () {
                        subdeferred.reject();
                      }
                    });
                    self.subqueue.push(subdeferred.promise);
                  }
                });
                When.all(self.subqueue).then(function () {
                  callback();
                });
              }
            });
          } else {
            callback();
          }
        }
      }, function () {
        selectedIDs = _.uniq(self.masterIDs.concat(self.subIDs));
        for (var i = 0; i < selectedIDs.length; i++) {
          self.conceptLoader[selectedIDs[i]].stop();
          if (!$(`#grid${selectedIDs[i]}`).closest(".item-wrapper").hasClass("open")) {
            $(`#act${selectedIDs[i]}`).html("0");
            $(`#head${selectedIDs[i]}`).html("No Creatives Assigned").css("text-align", "center").addClass("creative-header");
            $(`#grid${selectedIDs[i]}`).empty();
            $(`#more${selectedIDs[i]}`).hide();
          } else if ($(`#act${selectedIDs[i]}`).html() === "0") {
            $(`#head${selectedIDs[i]}`).html("No Creatives Assigned").css("text-align", "center").addClass("creative-header");
            $(`#grid${selectedIDs[i]}`).empty();
            $(`#more${selectedIDs[i]}`).hide();
          }
        }
        $(".open").each(function (index, e) {
          if (!$(e).find(".sub-check").hasClass("checked")) {
            gridHeadID = $(e).data("id");
            $(`#head${gridHeadID}`).find(".sub-check").removeClass("partial");
          }
        });
        T1.EventHub.publish("bulkaction:success");
        T1.Notify("message", `Creatives unassigned successfully from ${selectedIDs.length} concept(s)`);
      });
    },
    chooseConfirmAction: function (modelValues) {
      var selected = this.collection.getSelected();
      var activeFound = false;
      this.statusValue = modelValues;
      selected.each(function (model) {
        if ($(`#st${model.id}`).html() !== "--" && $(`#st${model.id}`).html() !== "<em>No Active Strategies</em>") {
          activeFound = true;
        }
      });
      if (activeFound) {
        T1.EventHub.publish("confirmAction:show");
        $("#modalContinueSingle").hide();
        $("#modalContinueBulk").show();
      } else {
        this.bulkAction(modelValues);
      }
    },
    confirmAction: function () {
      T1.EventHub.publish("confirmAction:hide");
      this.bulkAction(this.statusValue);
    },
    bulkAction: function (modelValues) {
      var self = this;
      var queue = [];
      var selected = this.collection.getSelected();
      var entityType = "concept";
      selected.each(function (model) {
        var loader = self.createInlineLoader(`#id${model.get("id")}`);
        var deferred = When.defer();
        loader.start();
        model.save(modelValues, {
          success: function () {
            loader.stop();
            deferred.resolve();
          },
          conflict: function () {
            loader.stop();
            deferred.reject();
          }
        });
        queue.push(deferred.promise);
      });
      When.all(queue).then(function () {
        T1.EventHub.publish("bulkaction:success");
        T1.Notify("message", `${selected.length} ${entityType}(s) updated successfully!`);
      });
    },
    createItemLoader: function (id) {
      var targetDiv = `#id${id}`;
      var loader;
      var item = $(targetDiv);
      var arrow = item.find(".icon");
      var iconSpinner = arrow.find(".icon-spinner");
      loader = iconSpinner;
      return loader;
    },
    createInlineLoader: function (id) {
      var targetDiv = id;
      var loader;
      loader = T1.Loader.create({
        contentClass: "inline-edit-loader",
        target: targetDiv,
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 4
        }
      });
      return loader;
    },
    load: function () {
      var $el = $(this.el);
      var self = this;
      var mContinue;
      this.render().then(function () {
        if (self.layout && !self.layout.loaded) {
          self.layout.load().then(function () {
            self.layout.loaded = true;
            self.toggleFilters(!self.collection.loaded);
            setTimeout(function () {
              var $advDDL = $(".advertiser-select", self.el);
              var $advSelectBox = $(`div#${$advDDL.attr("id")}_chzn`);
              var $title = $advSelectBox.find("span");
              $advDDL.find("._wrapper").addClass("ad-wrapper");
              $advSelectBox.attr("title", $title.html());
              $advSelectBox.click(function () {
                $advSelectBox.attr("title", $title.html());
              });
              $el.not('div[class="ad-wrapper"]').click(function () {
                self.clearTheFilter();
              });
            }, 1e3);
            $el.find(".chzn-container-single").css("width", "120px");
            T1.Selectbox($(".status .select-input", this.el), {
              onChange: function (e) {
                switch (parseInt(e.target.value, 10)) {
                  case 0:
                    self.searchBuilder.setFlag("active_state", "active");
                    self.active = "Active";
                    break;
                  case 1:
                    self.searchBuilder.setFlag("active_state", "inactive");
                    self.active = "Inactive";
                    break;
                  case 2:
                    self.searchBuilder.clearGroupFlags("active_state");
                    self.active = "Active & Inactive";
                    break;
                }
                self.search();
                self.preferences.save();
              }
            });
            T1.Selectbox($(".select-large .select-input", this.el), {
              onChange: $.noop
            });
            mContinue = document.getElementById("modalContinueBulk");
            mContinue.addEventListener("click", $.proxy(self.confirmAction, self));
            self.search();
          });
        }
      });
    },
    simplesearch: function () {
      var self = this;
      var conceptCount = 0;
      var conceptArray = [];
      var loaderArray = [];
      var loaderObj = {};
      var params;
      var searchTerm = this.searchTerm;
      var success = function (data) {
        data.each(function (model) {
          loaderObj[model.id] = self.createItemLoader(model.id);
          Spinner.apply(loaderObj[model.id], self.spinnerOpts);
          conceptArray.push(model.id);
          loaderArray.push({
            id: model.id,
            loader: loaderObj[model.id]
          });
          if (conceptCount === data.length - 1) {
            self.fetchData(conceptArray, loaderArray);
          }
          conceptCount++;
          if (model.get("status") === "1") {
            $(`#stat${model.id}`).html("Active");
          } else {
            $(`#stat${model.id}`).html("Inactive");
          }
          $(`#last${model.id}`).html(self.expandDate(model.get("updated_on")));
        });
        T1.EventHub.publish("creativegrid:clearLoaded");
      };
      this.abortCrossChannel();
      this.collection.abortCountCalls();
      params = this.searchBuilder.getAllFilters();
      if (searchTerm) {
        searchTerm = decodeURIComponent(searchTerm);
      }
      T1.EventHub.publish("creativegrid:setHighlightValue", searchTerm);
      this.collection.search.set({
        fetchRequired: true,
        params: params,
        success: success
      });
      T1.EventHub.publish("creativegrid:setSearchLoader");
    },
    abortCrossChannel: function () {
      var campColl = this.campCollectCampaigns;
      var stratCollStrat = this.stratCollectStrategies;
      var collectConc = this.collectConcepts;
      if (campColl && campColl.currentFetch) {
        campColl.currentFetch.abort();
      }
      if (stratCollStrat && stratCollStrat.currentFetch) {
        stratCollStrat.currentFetch.abort();
      }
      if (collectConc && collectConc.currentFetch) {
        collectConc.currentFetch.abort();
      }
      $.each(this.fetchCalls, function (index, value) {
        if (value) {
          value.abort();
        }
      });
      $.each(this.campCollectStrategies, function (index, value) {
        if (value.currentFetch) {
          value.currentFetch.abort();
        }
      });
      $.each(this.campCollectStrategyConcepts, function (index, value) {
        if (value.currentFetch) {
          value.currentFetch.abort();
        }
      });
      $.each(this.stratCollectStrategyConcepts, function (index, value) {
        if (value.currentFetch) {
          value.currentFetch.abort();
        }
      });
    },
    search: function () {
      var self = this;
      var conceptCount = 0;
      var conceptArray = [];
      var loaderArray = [];
      var loaderObj = {};
      var params;
      var searchTerm = this.searchTerm;
      var crossSearchEnabled = T1Permissions.check("feature", "creatives_concepts_search");
      this.abortCrossChannel();
      this.collection.abortCountCalls();
      this.campCollectCampaigns = new Campaigns(null, {
        pageLimit: 100
      });
      this.stratCollectStrategies = new Strategies(null, {
        pageLimit: 100
      });
      this.collectConcepts = new ConceptsCollection(null, {
        pageLimit: 100
      });
      if (this.searchTerm && this.searchTerm !== "") {
        this.searchBuilder.setFlag("search", "input", {
          term: self.searchTerm
        });
        if (!crossSearchEnabled) {
          this.simplesearch();
          return;
        }
      } else {
        this.searchBuilder.clearFlag("search", "input");
        this.simplesearch();
        return;
      }
      params = this.searchBuilder.getAllFilters();
      this.searchedIDs = [];
      this.searchedIdHold = {};
      this.campaignIDs = [];
      this.campaignStratIDs = [];
      this.strategyIDs = [];
      Async.parallel({
        searchCampaigns: function (callback) {
          self.campCollectCampaigns.isPaginated = false;
          self.campCollectStrategies.isPaginated = false;
          self.campCollectStrategyConcepts.isPaginated = false;
          self.campCollectCampaigns.urlFilter.set({
            entity: "organization",
            id: self.orgId
          });
          self.campCollectCampaigns.searchConfiguration = $.extend(true, self.campCollectCampaigns.searchConfiguration, {
            fields: {
              name: {
                type: "string",
                useWildCard: true
              },
              id: {
                type: "enum"
              },
              advertiser_id: {
                type: "exact"
              },
              status: {
                type: "exact"
              },
              multiField: {
                type: "multiField"
              }
            }
          });
          self.campCollectCampaigns.search.set({
            fetchRequired: true,
            params: params,
            success: function (adata) {
              if (adata.length > 0) {
                var filterConfiguration = [];
                var campaignIdChunks = [];
                var campaignChunkCount = 0;
                var campStratIdChunks = [];
                var campStratChunkCount = 0;
                filterConfiguration.push({
                  entity: "campaign",
                  fetchRequired: false
                });
                adata.each(function (model) {
                  self.campaignIDs.push(model.id);
                });
                campaignIdChunks = self.createChunks(self.campaignIDs, 100);
                for (var i = 0; i < campaignIdChunks.length; i++) {
                  self.campCollectStrategies[i] = new Strategies(null, {
                    pageLimit: 100
                  });
                  self.campCollectStrategies[i].urlFilter.setConfiguration(filterConfiguration);
                  self.campCollectStrategies[i].urlFilter.set({
                    entity: "campaign",
                    id: `(${campaignIdChunks[i]})`
                  });
                  self.campCollectStrategies[i].fetch({
                    params: {
                      full: "*",
                      with: ["campaign"]
                    },
                    success: function (bdata) {
                      if (bdata.length > 0) {
                        var stratConFiltConfig = [];
                        stratConFiltConfig.push({
                          entity: "strategy",
                          fetchRequired: false
                        });
                        self.campaignStratIDs = [];
                        bdata.each(function (model) {
                          self.campaignStratIDs.push(model.id);
                        });
                        campStratIdChunks = self.createChunks(self.campaignStratIDs, 500);
                        for (var j = 0; j < campStratIdChunks.length; j++) {
                          self.campCollectStrategyConcepts[j] = new StrategyConcepts(null, {
                            pageLimit: 100
                          });
                          self.campCollectStrategyConcepts[j].urlFilter.setConfiguration(stratConFiltConfig);
                          self.campCollectStrategyConcepts[j].urlFilter.set({
                            entity: "strategy",
                            id: `(${campStratIdChunks[j]})`
                          });
                          self.campCollectStrategyConcepts[j].fetch({
                            params: {
                              full: "concept"
                            },
                            success: function (cdata) {
                              if (cdata.length > 0) {
                                cdata.each(function (model) {
                                  self.searchedIDs.push(model.get("concept").id);
                                });
                                campStratChunkCount++;
                                if (campStratChunkCount >= campStratIdChunks.length) {
                                  campaignChunkCount++;
                                  if (campaignChunkCount >= campaignIdChunks.length) {
                                    callback(null, cdata);
                                  }
                                }
                              } else if (campaignChunkCount >= campaignIdChunks.length) {
                                callback(null, cdata);
                              } else {
                                campStratChunkCount++;
                                if (campStratChunkCount >= campStratIdChunks.length) {
                                  campaignChunkCount++;
                                  if (campaignChunkCount >= campaignIdChunks.length) {
                                    callback(null, cdata);
                                  }
                                }
                              }
                            }
                          });
                        }
                      } else {
                        callback(null, bdata);
                      }
                    }
                  });
                }
              } else {
                callback(null, adata);
              }
            }
          });
        },
        searchStrategies: function (callback) {
          self.stratCollectStrategies.isPaginated = false;
          self.stratCollectStrategies.searchConfiguration = $.extend(true, self.stratCollectStrategies.searchConfiguration, {
            fields: {
              name: {
                type: "string",
                useWildCard: true
              },
              id: {
                type: "enum"
              },
              status: {
                type: "exact"
              },
              multiField: {
                type: "multiField"
              }
            }
          });
          self.stratCollectStrategies.search.set({
            fetchRequired: true,
            params: params,
            success: function (adata) {
              if (adata.length > 0) {
                var filterConfiguration = [];
                var strategyIdChunks = [];
                var strategyChunkCount = 0;
                filterConfiguration.push({
                  entity: "strategy",
                  fetchRequired: false
                });
                adata.each(function (model) {
                  self.strategyIDs.push(model.id);
                });
                strategyIdChunks = self.createChunks(self.strategyIDs, 500);
                for (var i = 0; i < strategyIdChunks.length; i++) {
                  self.stratCollectStrategyConcepts[i] = new StrategyConcepts(null, {
                    pageLimit: 100
                  });
                  self.stratCollectStrategyConcepts[i].urlFilter.setConfiguration(filterConfiguration);
                  self.stratCollectStrategyConcepts[i].urlFilter.set({
                    entity: "strategy",
                    id: `(${strategyIdChunks[i]})`
                  });
                  self.stratCollectStrategyConcepts[i].fetch({
                    params: {
                      full: "*",
                      with: ["concept", "strategy"]
                    },
                    success: function (bdata) {
                      strategyChunkCount++;
                      if (bdata.length > 0) {
                        bdata.each(function (model) {
                          self.searchedIDs.push(model.get("concept").id);
                        });
                        if (strategyChunkCount >= strategyIdChunks.length) {
                          callback(null, bdata);
                        }
                      } else if (strategyChunkCount >= strategyIdChunks.length) {
                        callback(null, bdata);
                      }
                    }
                  });
                }
              } else {
                callback(null, adata);
              }
            }
          });
        },
        searchConcepts: function (callback) {
          self.collectConcepts.isPaginated = false;
          self.collectConcepts.updateOrgFilter();
          self.collectConcepts.search.set({
            fetchRequired: true,
            params: params,
            success: function (adata) {
              if (adata.length > 0) {
                adata.each(function (model) {
                  self.searchedIDs.push(model.id);
                });
                callback(null, adata);
              } else {
                callback(null, adata);
              }
            }
          });
        }
      }, function () {
        self.searchBuilder.clearFlag("search", "input");
        if (searchTerm) {
          searchTerm = decodeURIComponent(searchTerm);
        }
        T1.EventHub.publish("creativegrid:setHighlightValue", searchTerm);
        params = self.searchBuilder.getAllFilters();
        var maxCount = 850;
        if (self.searchedIDs.length > maxCount) {
          self.searchedIDs = self.searchedIDs.slice(0, maxCount);
        }
        if (self.searchedIDs.length <= 0) {
          self.collection.search.set({
            fetchRequired: true,
            idList: "000001"
          });
          T1.EventHub.publish("creativegrid:clearLoaded");
        } else {
          self.collection.search.set({
            fetchRequired: true,
            paramsAndIDs: true,
            params: params,
            idList: self.searchedIDs,
            success: function (data) {
              data.each(function (model) {
                loaderObj[model.id] = self.createItemLoader(model.id);
                Spinner.apply(loaderObj[model.id], self.spinnerOpts);
                conceptArray.push(model.id);
                loaderArray.push({
                  id: model.id,
                  loader: loaderObj[model.id]
                });
                if (conceptCount === data.length - 1) {
                  self.fetchData(conceptArray, loaderArray);
                }
                conceptCount++;
                if (model.get("status") === "1") {
                  $(`#stat${model.id}`).html("Active");
                } else {
                  $(`#stat${model.id}`).html("Inactive");
                }
                $(`#last${model.id}`).html(self.expandDate(model.get("updated_on")));
              });
              T1.EventHub.publish("creativegrid:clearLoaded");
            }
          });
        }
      });
      T1.EventHub.publish("creativegrid:setSearchLoader");
    },
    fetchData: function (conceptArray, loaderArray, callback) {
      var self = this;
      Async.parallel([function () {
        self.collection.mapStrategy(conceptArray, loaderArray, true, self.searchTerm);
      }, function () {
        self.collection.countCreatives(conceptArray, loaderArray);
      }], callback);
    },
    createChunks: function (arr, len) {
      var chunks = [];
      var i = 0;
      var n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    },
    expandDate: function (value) {
      var dateFormat = "MM/DD/YY";
      return value === "" ? "" : new moment.utc(value).format(dateFormat);
    },
    addConcept: function () {
      T1.Location.set("creatives/concept");
    },
    gridChange: function (e, data) {
      var $large = $(".select-large select");
      switch (data.state) {
        default:
        case "subnone":
          this.subcheck = false;
          this.toggleBulkAction();
          $large.attr("disabled", "disabled");
          break;
        case "none":
          this.topcheck = false;
          this.toggleBulkAction();
          $large.attr("disabled", "disabled");
          break;
        case "partial":
        case "checked":
          this.topcheck = true;
          this.toggleBulkAction(false);
          $large.removeAttr("disabled");
          break;
        case "subchecked":
          this.subcheck = true;
          this.toggleBulkAction(false);
          $large.removeAttr("disabled");
          break;
      }
      $large.trigger("liszt:updated");
    },
    unload: function () {
      if (this.layout) {
        this.layout.unload();
      }
      this.allAdvertisersLoaded = false;
      this.collection.toggleAllSelected(false);
      this.abortCrossChannel();
      this.collection.abortCountCalls();
      $(".main-check").removeClass("checked");
      $(".main-check").removeClass("partial");
      $(".sub-check").removeClass("checked");
      $(".sub-check").removeClass("partial");
    },
    serialize: function () {
      return {
        activeOptions: selectOptions.toOptionsList(selectOptions.active, this.active || "Active"),
        search: this.searchTerm
      };
    }
  });
});
