define('modules/T1App/edit/team/main',["jQuery", "T1", "T1Module", "models/t1App"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "T1App/edit/team",
      viewCfgs: {
        createEdit: {},
        search: {},
        bin: {},
        filterGrid: {}
      },
      defaultView: "createEdit"
    });
  }
  return instance;
});
