define('modules/audiences/pixels/main',["jQuery", "T1", "T1Module", "models/userPreferences"], function ($, T1, T1Module) {
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "audiences/pixels",
      viewCfgs: {
        createEdit: {},
        pixels: {
          collection: null,
          models: null
        },
        grid: {},
        bulkEmailModal: {},
        csvExportModal: {},
        eventURLPopover: {}
      },
      defaultView: "pixels"
    });
  }
  return instance;
});
