define('modules/admin/myOrgs/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "admin/myOrgs",
      viewCfgs: {
        myOrgs: {},
        grid: {}
      },
      defaultView: "myOrgs"
    });
  }
  return instance;
});
