define('modules/campaign/chart/views/datePicker',['require','moment','T1','T1View','text!../templates/datePicker.html'],function (require) {
  "use strict";

  const moment = require("moment");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/datePicker.html");
  const getDate = daysAgo => moment().subtract(daysAgo, "days")._d;
  const getHour = hoursAgo => moment().subtract(hoursAgo, "hours")._d;
  const now = moment()._d;
  const oneDay = 24;
  const twoDays = 48;
  const threeDays = 3;
  const sevenDays = 7;
  const twoWeeks = 14;
  const oneMonth = 30;
  const oneYear = 365;
  const twentyFourHrsAgo = getHour(oneDay);
  const fortyEightHrsAgo = getHour(twoDays);
  const threeDaysAgo = getDate(threeDays);
  const weekAgo = getDate(sevenDays);
  const twoWeeksAgo = getDate(twoWeeks);
  const monthAgo = getDate(oneMonth);
  const yearAgo = getDate(oneYear);
  const DAY_MULTIPLIER = 1e3 * 60 * 60 * 24;
  return T1View.extend({
    template: template,
    events: {
      "click .btn-date-picker": "openDatePickerHandler",
      "saved .chart-date-picker": "saveDatesHandler"
    },
    rangePresetsHourly: [{
      name: "Last 24 Hours",
      value: "Last 24 Hours",
      start: twentyFourHrsAgo,
      end: now
    }, {
      name: "Last 48 Hours",
      value: "Last 48 Hours",
      start: fortyEightHrsAgo,
      end: now
    }],
    rangePresetsDaily: [{
      name: "Last 3 Days",
      value: "Last 3 Days",
      start: threeDaysAgo,
      end: twentyFourHrsAgo
    }, {
      name: "Last 7 Days",
      value: "Last 7 Days",
      start: weekAgo,
      end: twentyFourHrsAgo
    }, {
      name: "Last 14 Days",
      value: "Last 14 Days",
      start: twoWeeksAgo,
      end: twentyFourHrsAgo
    }, {
      name: "Last 30 Days",
      value: "Last 30 Days",
      start: monthAgo,
      end: twentyFourHrsAgo
    }],
    initialize({
      getReportModel: getReportModel
    }) {
      const reportModel = getReportModel();
      const dateFormat = reportModel.getDateFormat();
      const {
        start: start,
        end: end
      } = reportModel.getStartEndDate();
      Object.assign(this, {
        dateFormat: dateFormat,
        displayFormat: reportModel.getDisplayFormat(),
        endDate: moment(end, dateFormat),
        reportModel: reportModel,
        startDate: moment(start, dateFormat)
      });
    },
    load() {
      this.render().then(() => {
        const datePicker = this.datePicker = this.$(".chart-date-picker")[0];
        datePicker.target = this.$(".dates")[0];
        datePicker.allowedStart = yearAgo;
        datePicker.allowedEnd = now;
        datePicker.closeLabel = "Cancel";
        this.$carat = this.$(".carat");
        T1.EventHub.publish("complete:layoutLoad");
      });
    },
    unload() {
      Object.assign(this, {
        $carat: null,
        dateFormat: null,
        datePicker: null,
        displayFormat: null,
        endDate: null,
        startDate: null
      });
    },
    openDatePickerHandler() {
      const isByHour = this.reportModel.getInterval() === "by_hour";
      const datePicker = this.datePicker;
      datePicker.rangePresets = isByHour ? this.rangePresetsHourly : this.rangePresetsDaily;
      datePicker.end = this.endDate;
      datePicker.start = this.startDate;
      datePicker.open();
    },
    saveDatesHandler(event) {
      const {
        start: start,
        end: end
      } = event.originalEvent.detail;
      const {
        dateFormat: dateFormat,
        reportModel: reportModel
      } = this;
      this.startDate = moment(start);
      this.endDate = moment(end);
      reportModel.setStartEndDate({
        start: this.startDate.format(dateFormat),
        end: this.endDate.format(dateFormat)
      }, false);
      this.updateDate();
      if (window.pendo) {
        window.pendo.track("Campaigns - MM Chart - Date Range Selection", {
          startDate: this.startDate.format(dateFormat),
          endDate: this.endDate.format(dateFormat),
          days: Math.floor((this.endDate.toDate() - this.startDate.toDate()) / DAY_MULTIPLIER) + 1,
          report: reportModel.get("reportType")
        });
      }
    },
    updateDate() {
      const displayFormat = this.displayFormat;
      this.$(".start-date").text(this.startDate.format(displayFormat));
      this.$(".end-date").text(this.endDate.format(displayFormat));
    },
    serialize() {
      const displayFormat = this.displayFormat;
      return {
        start: this.startDate.format(displayFormat),
        end: this.endDate.format(displayFormat)
      };
    }
  });
});
