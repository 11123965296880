define('modules/creatives/shared/creativeActive',[],function () {
  "use strict";

  return {
    active: ["All", "Active", "Inactive"],
    toOptionsList: function (list, selection) {
      return list.map((item, idx) => ({
        selected: item === selection,
        text: item,
        value: idx
      }));
    }
  };
});
