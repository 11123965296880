define('collections/dayParts',["T1", "T1Collection", "models/dayPart"], function (T1, T1Collection, DayPart) {
  T1.Models.DayParts = T1Collection.extend({
    model: DayPart,
    canCache: false,
    urlString: "day_parts",
    fixture: "-day_parts",
    url: function () {
      var originalString = this.urlString;
      var url;
      this.urlString = "strategies/" + this.id + "/" + originalString;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    filterConfiguration: {
      filters: [{
        entity: "strategy",
        fetchOnChange: true
      }]
    },
    isAllDayParts: function () {
      var len = this.length;
      var dayPart = len ? this.at(0) : null;
      function isOneDayPart() {
        return len === 1 || len === 0;
      }
      function isEveryDay() {
        return dayPart.get("days") === "MTWRFSU";
      }
      function isAllDay() {
        var startHour = dayPart.get("start_hour");
        var endHour = dayPart.get("end_hour");
        return startHour === "0" && endHour === "23";
      }
      if (dayPart === null) return false;
      return isOneDayPart() && isEveryDay() && isAllDay();
    }
  });
  return T1.Models.DayParts;
});
