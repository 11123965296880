define('modules/campaign/bulkedit/targeting/mydata/main',["jQuery", "T1Module", "collections/pixels"], function ($, T1Module, PixelBundles) {
  "use strict";

  return new T1Module({
    name: "campaign/bulkedit/targeting/mydata",
    viewCfgs: {
      panel: {
        strategy: null
      },
      bins: {
        sharedCollection: null
      },
      readOnly: {
        model: null
      },
      list: {
        model: null
      }
    },
    defaultView: "panel",
    getView: function (type, opts) {
      const collection = new PixelBundles();
      switch (type) {
        case "search":
        case "filterSearch":
          break;
        default:
          collection.fetchOptions = $.extend({
            with: ["advertiser", "agency", "provider"],
            full: "*",
            sort_by: "name"
          }, collection.fetchOptions);
          if (type === "select") {
            collection.search.set({
              params: [{
                term: "1",
                searchField: "eligible"
              }],
              fetchRequired: false
            });
          }
          this.viewCfgs[type].collection = collection;
          break;
      }
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
