define('collections/mediaDealGroups',["jQuery", "Underscore", "moment", "T1", "T1UtilsAsync", "T1Collection", "T1Comm", "T1Permissions", "models/mediaDealGroup"], function ($, _, moment, T1, T1UtilsAsync, T1Collection, T1Comm, T1Permissions, DealGroup) {
  "use strict";

  T1.Models.DealGroup = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    model: DealGroup,
    urlString: "deal_groups",
    apiRoot: T1Permissions.check("feature", "deals_v2_api") ? "/deals/v1.0/" : "/media/v1.0/",
    getSuccessFunction(options = {}) {
      const self = this;
      const parseFunc = function (data) {
        const parsedData = self.parse(data);
        options.success(parsedData, data);
      };
      return typeof options.success === "function" ? parseFunc : $.noop;
    },
    getSyncOptions(options) {
      return {
        dataType: "json",
        success: this.getSuccessFunction(options),
        processAjaxResponse: this.processAjaxResponse
      };
    },
    fetch: function (options) {
      return this.sync("read", "", this.getSyncOptions(options));
    },
    fetchAllPages: function (options) {
      var self = this;
      const API_LIMIT = 100;
      options = options || {};
      this.isPaginated = true;
      this.page = 0;
      this.pageLimit = options.pageLimit || API_LIMIT;
      this.fetchOptions = $.extend(true, {}, this.fetchOptions, options.fetchOptions || {});
      this.fetch({
        silent: options.silent || false,
        success: function () {
          var pageLimit = self.pageLimit;
          var morePagesLen = self.count > 0 ? Math.ceil(self.count / pageLimit) - 1 : 0;
          var success = options.success;
          var models = self.models;
          var deferred = [];
          var promises = [];
          var morePages = [];
          var fetchMoreSuccess = function (collection, resp) {
            const page = parseInt(resp.meta.offset, 10) / pageLimit;
            var index = page - 1;
            morePages[index] = models.splice(pageLimit, models.length - pageLimit);
            deferred[index].resolve();
          };
          var i;
          for (i = 0; i < morePagesLen; i++) {
            deferred.push(T1UtilsAsync.makeDeferral());
            promises.push(deferred[i].promise);
            self.fetchMore({
              silent: true,
              success: fetchMoreSuccess
            });
          }
          self.isPaginated = false;
          if (success) {
            if (morePagesLen > 0) {
              T1UtilsAsync.linkPromises(promises).then(function () {
                self.models = models.concat(...morePages);
                success.call(self, self);
              });
            } else {
              success.call(self, self);
            }
          }
        }
      });
    },
    fetchMore: function (args) {
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          silent: args.silent || false,
          add: true,
          success: args.success || $.noop
        });
      }
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    parse: function (response) {
      const data = response.data;
      const dealGroups = [];
      let dealGroup;
      if (!data) {
        return dealGroups;
      }
      data.forEach(item => {
        dealGroup = new DealGroup();
        dealGroups.push(dealGroup.parse(item));
      });
      this.add(dealGroups);
      this.count = response.meta.total_count;
      this.offset = response.meta.offset;
      this.loaded = true;
      return dealGroups;
    },
    filterGarbageData: function () {
      const result = this.models.filter(model => model.get("entity_type") === "deal_group");
      this.reset();
      this.add(result, {
        silent: true
      });
    },
    sortGrid: function (sortKey, sortOrder) {
      if (sortKey) {
        this.models = _.sortBy(this.models, function (model) {
          if (sortKey === "id") {
            return Number(model.get(sortKey));
          }
          return model.get(sortKey).trim().toLowerCase();
        });
      }
      if (sortOrder === -1) {
        this.models.reverse();
      }
      this.trigger("sort", this);
      this.trigger("reset", this);
    },
    filterFor: function (state) {
      const shouldFilter = state !== "both";
      if (!this.browseModels) {
        this.browseModels = $.extend(true, [], this.models);
      }
      if (shouldFilter) {
        const filtered = this.browseModels.filter(model => model.get("status") === state);
        this.reset(filtered);
      } else {
        this.reset(this.browseModels);
      }
    },
    getLength: function () {
      return this.browseModels ? this.browseModels.length : this.length;
    },
    getBrowseModels: function () {
      return this.browseModels || this.models;
    },
    searchDealGroups: function (term, filter, activeState, sortKey, sortOrder) {
      let filtered;
      const shouldShow = activeState === "both";
      if (!this.browseModels) {
        this.browseModels = this.models;
      }
      if (term) {
        term = term.toLowerCase();
        filtered = this.browseModels.filter(model => {
          const searched = model.get(filter).toString().toLowerCase();
          const hasTerm = searched.indexOf(term) !== -1;
          const state = model.get("status");
          const correctState = state === activeState || shouldShow;
          return hasTerm && (correctState || shouldShow);
        });
        this.reset(filtered);
      } else if (!shouldShow) {
        filtered = this.browseModels.filter(model => model.get("status") === activeState);
        this.reset(filtered);
      } else {
        this.reset(this.browseModels);
      }
      if (sortKey) {
        this.sortGrid(sortKey, sortOrder);
      }
    }
  });
  return T1.Models.DealGroup;
});
