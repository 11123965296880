define('modules/user/views/tools',["jQuery", "T1", "T1View", "T1Permissions", "text!modules/user/templates/tools.html"], function ($, T1, T1View, T1Permissions, template) {
  var bulkEditorURL = "https://bulkmanager.mediamath.com/";
  var idLookupURL = "https://idlookup.mediamath.com/";
  return T1View.extend({
    template: template,
    initialize: function () {
      this.hasSpreadsheetBulkEditFlag = T1Permissions.check("feature", "spreadsheet_bulk_edit");
    },
    serialize: function () {
      return {
        hasSpreadsheetBulkEditFlag: this.hasSpreadsheetBulkEditFlag,
        bulk_editor_url: bulkEditorURL,
        id_lookup_url: idLookupURL
      };
    }
  });
});
