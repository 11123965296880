define('collections/campaignReportV2',['require','T1','Backbone'],function (require) {
  "use strict";

  const T1 = require("T1");
  const Backbone = require("Backbone");
  const T1Publish = T1.EventHub.publish;
  const required = function () {
    throw Error('fetch() requires "fetchOptions" and "reportName"');
  };
  T1.Models.CampaignReportV2 = Backbone.Collection.extend({
    apiRoot: T1.RPT_API_ROOT,
    initialize() {
      this.CSVparser = new am4core.CSVParser();
      this.CSVparser.options.useColumnNames = true;
    },
    _getParams(opts) {
      const params = new URLSearchParams();
      Object.entries(opts).forEach(([key, value]) => params.append(key, value));
      return params.toString();
    },
    fetch({
      fetchOptions = required(),
      reportName = required()
    }) {
      this.abort();
      let headers;
      const urlParams = this._getParams(fetchOptions);
      const url = `${this.apiRoot}std/${reportName}?${urlParams}`;
      const controller = this.controller = new AbortController();
      if (localStorage.getItem("mm.bogo.acc")) {
        headers = new Headers({
          Accept: "text/csv",
          Authorization: `Bearer ${localStorage.getItem("mm.bogo.acc")}`,
          "Content-Type": "text/csv"
        });
      }
      const request = new Request(url, {
        headers: headers,
        method: "GET",
        signal: controller.signal
      });
      fetch(request).then(response => {
        if (response.ok) {
          return response.text();
        }
        T1Publish("comm:error", {
          code: response.status,
          detail: response.statusText
        });
      }).then(text => {
        this.reset();
        this._processCSV(text);
      }).catch(err => {
        if (err.name !== "AbortError") {
          const {
            code: code,
            message: message
          } = err;
          T1Publish("comm:error", {
            code: code,
            detail: message
          });
        }
      });
    },
    _processCSV(text) {
      const parsedData = this.CSVparser.parse(text);
      this.add(parsedData, {
        silent: true
      });
      this.trigger("change:data", this);
    },
    abort() {
      if (this.controller) {
        this.controller.abort();
      }
    }
  });
  return T1.Models.CampaignReportV2;
});
