define('models/targetValue',["T1", "Backbone", "T1Model"], function (T1, Backbone, T1Model) {
  T1.Models.TargetValue = T1Model.extend({
    relations: [{
      type: Backbone.HasMany,
      key: "target_value",
      relatedModel: "TargetValue",
      collectionType: "T1Collection",
      includeInJSON: false,
      reverseRelation: {
        key: "parent",
        includeInJSON: false
      }
    }],
    getBrowsePath: function () {
      var parent = this.get("parent");
      var parentPath;
      if (this.get("name") === "Channels") {
        return "";
      }
      parentPath = parent.getBrowsePath();
      parentPath = parentPath === "" ? this.get("name") : parentPath + " &raquo; " + this.get("name");
      return parentPath;
    }
  });
  return T1.Models.TargetValue;
});
