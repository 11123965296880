define('modules/reporting/campaigns/dataExport/createEdit/views/filter',['require','jQuery','T1','T1Layout','T1View','text!../templates/filter.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const template = require("text!../templates/filter.html");
  const T1Publish = T1.EventHub.publish;
  const ddlItemNum = 2;
  const minSearchLength = 3;
  const pixelReports = ["Audience Index Pixel", "Event Pixel Loads", "Data Pixel Loads"];
  return T1View.extend({
    template: template,
    events: {
      "changed .search-holder .search-input": "searchInputHandler",
      "changed .search-holder .search-selector": "searchSelectorHandler",
      "click .grid-holder": "removeErrorState",
      "click .select-all": "selectAllClickHandler"
    },
    eventhubEvents: {
      "createEdit.clearSearch": "clearSearchInput",
      "createEdit.filterError": "addErrorState",
      "createEdit.updateSearchDDL": "updateSearchDDL",
      "update.selectAllBtn": "updateSelectAllState"
    },
    initialize({
      gridModel: gridModel,
      binModel: binModel,
      model: model
    }) {
      this.GridModel = gridModel;
      this.BinModel = binModel;
      this.DataExportModel = model;
      this.searchDDLdata = [{
        value: "Agency",
        name: "Agency"
      }, {
        value: "Advertiser",
        name: "Advertiser"
      }, {
        value: "Campaign",
        name: "Campaign"
      }];
      this.initLayout();
    },
    initLayout() {
      const module = "reporting/campaigns/dataExport/createEdit";
      const viewConfigs = {
        dataExportModel: this.DataExportModel,
        binModel: this.BinModel,
        gridModel: this.GridModel
      };
      this.layout = new T1Layout({
        el: () => this.el,
        template: template,
        layout: {
          ".grid-component-holder": [{
            module: module,
            options: viewConfigs,
            viewType: "filterGrid"
          }],
          ".bin-component-holder": [{
            module: module,
            options: viewConfigs,
            viewType: "filterBin"
          }],
          ".additional-filters-holder": [{
            module: module,
            options: viewConfigs,
            viewType: "additionalFilters"
          }]
        }
      });
    },
    load() {
      const $el = this.el;
      const selectedReport = this.DataExportModel.getSelectedReport();
      this.render().then(() => {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        this.layout.load();
        this.$gridHolder = this.$(".grid-holder");
        this.$gridWarning = this.$(".filter-warning");
        this.$gridTitle = this.$gridHolder.find(".title");
        this.$selectAllIcon = this.$(".select-all");
        this.$searchWarning = this.$(".search-warning");
        this.$searchField = this.$(".search-input");
        this.$searchDDL = this.$(".search-selector");
        if (pixelReports.includes(selectedReport)) {
          this.updateSearchDDL({
            entity: "Pixel",
            label: selectedReport
          });
        }
        setTimeout(() => {
          this.$searchDDL[0].set("data", this.searchDDLdata);
        }, 0);
      });
    },
    unload() {
      this.$gridHolder = null;
      this.$gridTitle = null;
      this.$searchDDL = null;
      this.$searchField = null;
      this.$searchWarning = null;
      this.$selectAllIcon = null;
      this.BinModel = null;
      this.GridModel = null;
      this.searchDDLdata = null;
    },
    addErrorState() {
      this.$gridHolder.addClass("filter-error");
      this.$gridWarning.show();
    },
    removeErrorState() {
      this.$gridHolder.removeClass("filter-error");
      this.$gridWarning.hide();
    },
    updateSelectAllState() {
      const $selectAllIcon = this.$selectAllIcon;
      if (this.GridModel.getAllSelected()) {
        $selectAllIcon.addClass("deselected");
        $selectAllIcon.attr("type", "include");
        $selectAllIcon.addClass("deselected");
        $selectAllIcon.removeClass("selected selected-partial");
      } else {
        $selectAllIcon.addClass("selected");
        $selectAllIcon.attr("type", "partial-include");
        $selectAllIcon.addClass("selected-partial");
        $selectAllIcon.removeClass("selected deselected");
      }
    },
    selectAllClickHandler() {
      const $selectAllIcon = this.$selectAllIcon;
      const isSelected = $selectAllIcon.hasClass("selected");
      if (isSelected) {
        $selectAllIcon.attr("type", "include");
        $selectAllIcon.addClass("deselected").removeClass("selected");
      } else {
        $selectAllIcon.attr("type", "include");
        $selectAllIcon.addClass("selected");
        $selectAllIcon.removeClass("deselected selected-partial");
      }
      this.GridModel.updateIconState("", "select-all", !isSelected);
      T1Publish("change:formElements", true);
    },
    searchSelectorHandler(event) {
      const searchTerm = this.$searchField[0].value;
      this.GridModel.setSearchEntity(event.currentTarget.value);
      if (searchTerm && searchTerm.length > minSearchLength) {
        T1Publish("filter.searchData", searchTerm);
      }
    },
    searchInputHandler(event) {
      const {
        value: value
      } = event.currentTarget;
      const $searchWarning = this.$searchWarning;
      const length = value ? value.length : null;
      if (value && length) {
        if (length < minSearchLength) {
          $searchWarning.show();
          this.GridModel.setSearchTerm("");
        } else {
          $searchWarning.hide();
          this.GridModel.setSearchTerm(value);
          T1Publish("filter.searchData", value);
        }
      } else {
        $searchWarning.hide();
        this.GridModel.setSearchTerm("");
        T1Publish("filter.resetSearch");
      }
    },
    clearSearchInput() {
      const {
        value: value
      } = this.$searchField[0];
      if (value && value.length) {
        this.$searchField[0].value = "";
        T1Publish("filter.searchInput");
      }
    },
    updateSearchDDL({
      entity: entity,
      label: label
    }) {
      const searchDDLdata = $.extend([], this.searchDDLdata);
      const searchDDL = this.$searchDDL[0];
      if (label === "Data Pixel Loads") {
        searchDDLdata.splice(ddlItemNum, 1);
        searchDDLdata.splice(1, 1, {
          value: entity,
          name: entity
        });
        this.$gridTitle.text(`AGENCY / ${entity.toUpperCase()}`);
      } else {
        searchDDLdata.splice(1, 1, {
          value: "Advertiser",
          name: "Advertiser"
        });
        searchDDLdata.splice(ddlItemNum, 1, {
          value: entity,
          name: entity
        });
        this.$gridTitle.text(`AGENCY / ADVERTISER / ${entity.toUpperCase()}`);
      }
      searchDDL.set("data", searchDDLdata);
      searchDDL.reset();
      searchDDL.set("value", searchDDLdata[1].value);
      this.searchDDLdata = searchDDLdata;
    }
  });
});
