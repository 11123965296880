define('collections/hyperLocalDesigns',["Underscore", "jQuery", "T1", "Backbone", "models/hyperLocalDesign", "collections/organizations"], function (_, $, T1, Backbone, HyperLocalDesign, Organizations) {
  "use strict";

  var _currentFetch, _requiredFetches;
  return Backbone.Collection.extend({
    model: HyperLocalDesign,
    url: T1.API_ROOT + "hyperlocal/geopulse/designs",
    initialize: function (models, options) {
      var orgs = Organizations.getOrganizations();
      orgs.getSelected().then($.proxy(function (orgId) {
        this.organizationId = orgId;
      }, this));
      this.operators = {
        include: null,
        exclude: null
      };
      this.strategy = options.strategy;
      this.campaign = this.strategy && this.strategy.get("campaign");
      this.advertiserId = this.campaign && this.campaign.get("advertiser_id");
      this.strategyType = options.strategyType;
      this.singleFetch = Backbone.Collection.prototype.fetch;
      this.searchResults = [];
    },
    comparator: function (model) {
      var date = new Date(model.get("updated_at")).getTime();
      return -date;
    },
    parse: function (response) {
      var self = this;
      var statuses = ["building", "built", "requested"];
      return _.filter(response.response.data, function (design) {
        if (!self.get(design.id)) {
          return _.contains(statuses, design.status);
        }
      });
    },
    fetch: function (opts) {
      var self = this;
      var offset = 20;
      if (!_currentFetch) {
        _currentFetch = 1;
      }
      this.singleFetch.call(this, {
        data: {
          offset: (_currentFetch - 1) * offset,
          advertiser_id: self.advertiserId,
          organization_id: self.organizationId,
          currentTime: T1.getRandomNumber()
        },
        add: opts.add,
        success: function (coll, resp) {
          var response = resp.response;
          var totalRecords = response.total_row_count;
          if (_currentFetch === 1) {
            _requiredFetches = totalRecords > offset ? Math.ceil(totalRecords / offset) : 1;
          }
          if (_requiredFetches === _currentFetch) {
            _currentFetch = null;
            self.loaded = true;
            opts.success();
          } else {
            _currentFetch++;
            self.fetch(opts);
          }
        },
        error: function (coll, resp, options) {
          if (opts.error) {
            opts.error();
          }
          T1.EventHub.publish("comm:error", {
            code: resp.type,
            detail: options.url + ": " + resp.message
          });
          self.loaded = true;
        }
      });
    },
    findBySet: function (geofence) {
      return this.find(function (model) {
        return model.id === geofence.get("parent_value_id");
      });
    },
    search: function (opts) {
      var self = this;
      var term = opts.term;
      var parentCollection = opts.collection;
      var savedModels = _.filter(this.models, function (model) {
        return model.get("type") === "design";
      });
      this.singleFetch.call(this, {
        data: {
          q: term,
          advertiser_id: self.advertiserId,
          organization_id: self.organizationId
        },
        add: true,
        reset: false,
        success: function (resp) {
          var models = resp.models.length ? resp.models : savedModels;
          self.searchResults = [];
          if (models.length) {
            $.each(models, function (i, model) {
              if (model.get("name").toLowerCase().indexOf(term.toLowerCase()) > -1) {
                if (!model.strategy) {
                  model.strategy = self.strategy;
                }
                model.applyDesignAttributes();
                model.applySetAttributes();
                self.searchResults.push(model);
              }
              if (!parentCollection.get(model.id)) {
                parentCollection.add(model);
              }
              if (i === models.length - 1) {
                self.loaded = true;
                self.trigger("reset");
              }
            });
          } else {
            self.loaded = true;
            self.trigger("reset");
          }
        }
      });
    },
    resetSearch: function () {
      this.loaded = false;
      this.searchResults = [];
    }
  });
});
