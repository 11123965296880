define('modules/strategy/views/helpers/locationSummaryTarget',["Underscore", "jQuery", "utils/CampaignStrategyUtils"], function (_, $, Utils) {
  "use strict";

  return {
    prepareForSerialize: function (strategyModel, partialCollection, locationCollection, geofences, hyperLocalCollection) {
      var collObj = locationCollection;
      var resultJSON = {
        data: []
      };
      var codeNameHashRegn = [];
      var codeNameHashDmax = [];
      var codeNameHashUszc = [];
      var codeNameHashHyperLocal = [];
      var codeNameHashIPTA = [];
      var spaceEntity = "&nbsp;";
      var formatName = function (model) {
        var name = model.get("name");
        var code = model.get("dimension_code");
        var partialName = "";
        if (code === "REGN") {
          return Utils.buildRegionPath(collObj, partialCollection, model);
        } else if (code === "DMAX") {
          const splitArr = name.split("|");
          name = splitArr[splitArr.length - 1];
          if (splitArr.length > 1) {
            partialName = splitArr.slice(0, splitArr.length - 1).join("&raquo");
            return {
              partialPath: `${partialName} &raquo `,
              name: name
            };
          }
          return {
            name: name
          };
        } else if (code === "USZC") {
          name = `Postal Codes Applied (${model.get("count")})`;
        } else if (code === "IPTA") {
          name = `IP List Applied (${model.get("count")})`;
        } else if (code === "hyperlocal") {
          const parentNode = model.get("design");
          name = model.get("targeting_code") || model.get("name");
          if (parentNode) {
            return {
              partialPath: `${parentNode.get("name")} &raquo `,
              name: name
            };
          }
          return {
            name: name
          };
        }
        return {
          name: name ? name.replace(/\/|\|/g, "&raquo") : spaceEntity
        };
      };
      var sortIncEx = function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      };
      if (!collObj || !collObj.models) {
        return resultJSON;
      }
      $.each(collObj.models, function (index, model) {
        let tmpArr;
        var action = model.get("action");
        var dimCode = model.get("dimension_code");
        var tmpObj = formatName(model);
        if (dimCode === "REGN") {
          tmpArr = codeNameHashRegn;
        } else if (dimCode === "DMAX") {
          tmpArr = codeNameHashDmax;
        } else if (dimCode === "IPTA") {
          tmpArr = codeNameHashIPTA;
        } else {
          tmpArr = codeNameHashUszc;
        }
        if (tmpArr) {
          tmpArr.push({
            label: tmpObj.partialPath !== undefined ? tmpObj.partialPath : "",
            action: action,
            boldText: tmpObj.name
          });
        }
      });
      if (geofences.length) {
        $.each(geofences, function (index, model) {
          var hyperLocalPathObj = formatName(model);
          var action = model.get("action").toLowerCase();
          codeNameHashHyperLocal.push({
            label: hyperLocalPathObj.partialPath !== undefined ? hyperLocalPathObj.partialPath : "",
            action: action,
            boldText: `${hyperLocalPathObj.name} (${hyperLocalCollection.operators[action].toUpperCase()})`
          });
        });
      }
      if (!collObj.models.length && !geofences.length) {
        resultJSON.data.push({
          items: [{
            label: "Running on all locations"
          }]
        });
        return resultJSON;
      }
      let tempData = codeNameHashRegn.sort(sortIncEx);
      if (tempData.length) {
        resultJSON.data.push({
          header: "Country/Region/City",
          items: tempData
        });
      }
      tempData = codeNameHashDmax.sort(sortIncEx);
      if (tempData.length) {
        resultJSON.data.push({
          header: "DMA/Media Market",
          items: tempData
        });
      }
      tempData = codeNameHashHyperLocal.sort(sortIncEx);
      if (tempData.length) {
        resultJSON.data.push({
          header: "Geofences",
          items: tempData
        });
      }
      tempData = codeNameHashUszc.sort(sortIncEx);
      if (tempData.length) {
        resultJSON.data.push({
          header: "Postal Codes",
          items: tempData
        });
      }
      tempData = codeNameHashIPTA.sort(sortIncEx);
      if (tempData.length) {
        resultJSON.data.push({
          header: "IP List",
          items: tempData
        });
      }
      return resultJSON;
    }
  };
});
