define('modules/campaign/views/createEdit',["jQuery", "T1", "T1View", "text!modules/campaign/templates/createEdit.html", "T1Layout", "T1TabLayoutV2", "T1Permissions", "models/campaign", "models/clientProductEstimateIO", "collections/organizations", "T1Menu", "utils/CampaignStrategyUtils", "utils/nextTabViewNavigation", "utils/LaunchDarklyUtils", "jQueryUI"], function ($, T1, T1View, template, T1Layout, T1TabLayout, T1Permissions, Campaign, ClientProductEstimateIO, Organizations, t1Menu, Utils, NextTabViewNavigation, LaunchDarklyUtils) {
  "use strict";

  const getAgencyAndAdvertiserDisplay = Utils.getAgencyAdvertiserDisplay;
  const {
    isHybrid: isHybrid,
    origin: origin,
    organization: orgNewT1
  } = window.hybridWorkflowParams;
  function updateBreadCrumb(isReadOnly, campaign) {
    let locations;
    if (campaign.id) {
      locations = [{
        label: campaign.get("name"),
        location: (isReadOnly ? "#campaign/readonly/" : "#campaign/edit/") + campaign.id
      }];
    } else {
      locations = [{
        label: "New Campaign",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(campaign),
      locations: locations
    });
  }
  function updateBreadCrumbNewT1(isReadOnly, campaign) {
    const locations = [{
      label: "Campaigns Home",
      location: `${origin}/campaigns?organization=${orgNewT1}`,
      target: "_top"
    }];
    if (campaign.id) {
      locations.push({
        label: campaign.get("name"),
        location: (isReadOnly ? "#campaign/readonly/" : "#campaign/edit/") + campaign.id
      });
    } else {
      locations.push({
        label: "New Campaign",
        location: "",
        supressLink: true
      });
    }
    T1.EventHub.publish("updateNavPathNewT1", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(campaign),
      locations: locations
    });
  }
  return T1View.extend({
    template: template,
    initialize: function ({
      model: campaign,
      id: id,
      mode: mode
    }) {
      var self = this;
      var isIdNew = id === "new";
      const isReadOnly = Boolean(mode && mode.toLowerCase() === "readonly");
      NextTabViewNavigation.attachBehavior(this);
      this.organizationInstance = Organizations.getOrganizations();
      this.organizationInstance.getSelected().then(orgID => {
        this.org = orgID;
        fetchModelData();
      });
      this.isReadOnly = isReadOnly;
      function onSuccess() {
        self.canLoad = true;
        if (Utils.isProgrammaticGuaranteed(self.model)) {
          Utils.redirectInaccessibleRoutes({
            inaccessibleRoutes: ["siteList", "pixelSegment"],
            defaultRoute: "general"
          });
        }
        const {
          flag: canEditWithV2
        } = Utils.allowV2CampaignOrStrategyEdit({
          campaign: campaign,
          view: self,
          showDialog: true
        });
        if (canEditWithV2) {
          if (isReadOnly === true) {
            if (T1Permissions.check("campaign.edit", campaign) === true) {
              T1.Location.set(`#campaign/edit/${campaign.id}`);
              return;
            }
          } else if (isIdNew === false && T1Permissions.check("campaign.edit", campaign) === false) {
            const deepLinks = T1.Location.getRoute().deepLinks;
            T1.Location.set(`#campaign/readonly/${campaign.id}/${deepLinks && deepLinks.length ? deepLinks[0] : "details"}`);
            return;
          }
          if (isHybrid) {
            updateBreadCrumbNewT1(isReadOnly, campaign);
          } else {
            updateBreadCrumb(isReadOnly, campaign);
          }
          self.initLayouts();
          self.load();
          if (isReadOnly !== true) {
            self.addDataEvent({
              model: {
                "change:version": function () {
                  self.renderPartial(".create-edit-head");
                },
                "change:id": function () {
                  var location = T1.Location.get(true);
                  self.updateDataBind(self.serialize(), self.el);
                  self.layout.execute("enable", 1);
                  location = location.replace("create/new", `edit/${campaign.id}`);
                  this.model.set({
                    advertiser: {
                      name: this.advertiserName,
                      id: this.model.get("advertiser_id")
                    }
                  });
                  T1.Location.set(location, {
                    replace: true,
                    silent: true
                  });
                  self.prepareMenuItems();
                }
              }
            });
          }
        }
      }
      function fetchModelData() {
        if (id && id !== "new") {
          campaign = new Campaign({
            id: id
          });
          self.canLoad = false;
          self.id = id;
          campaign.fetch({
            params: {
              with: ["advertiser", "ad_server", "rpt_goal_monitoring", "vendor_contracts,vendor", "budget_flights"],
              full: "*"
            },
            success: onSuccess
          });
          self.model = campaign;
        } else {
          campaign = new Campaign();
          self.model = campaign;
          onSuccess();
        }
      }
    },
    initLayouts: function () {
      const model = this.model;
      this.layout = new T1TabLayout({
        tabSetName: "CampaignModification",
        level: 1,
        disabled: [1],
        tabs: [{
          label: "Details",
          routeName: "details",
          module: "campaign",
          viewType: this.isReadOnly === true ? "detailsReadOnly" : "details",
          options: {
            model: model || null,
            id: model ? model.id : null
          }
        }, {
          label: "Strategies",
          module: "campaign",
          routeName: "strategies",
          viewType: "strategiesPanel",
          options: {
            campaign: model
          }
        }]
      });
    },
    menuConfig: {
      menuTriggerEleSelector: ".campaign-settings",
      menuColumns: [{
        columnTitle: null,
        menuItems: [{
          label: "Bulk Edit",
          visible: false
        }, {
          label: "Duplicate",
          handler: "duplicateHandler",
          visible: false
        }, {
          label: "View Report",
          visible: false
        }, {
          label: "Brain Insights",
          handler: "viewBrainLog",
          visible: false
        }, {
          label: "Change log",
          handler: "viewChangeLog",
          visible: true
        }, {
          label: "Edit Margin",
          visible: false
        }]
      }]
    },
    prepareMenuItems: function () {
      var canEditMargin = T1Permissions.check("campaign.editMargin", this.model);
      var createEditPermitted = T1Permissions.check("campaign.edit", this.model);
      var canAccessReporting = !T1Permissions.check("feature", "reporting_hide");
      var canViewBrainLog = T1Permissions.check("feature", "use_log_brain");
      var menuIndexRef = {
        BulkEdit: 0,
        Duplicate: 1,
        Report: 2,
        Brain: 3,
        Changelog: 4,
        EditMargin: 5
      };
      const menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      const modelId = this.model.id;
      const goalType = this.model.get("goal_type");
      if (canAccessReporting) {
        menuItems[menuIndexRef.Report].visible = true;
        menuItems[menuIndexRef.Report].url = `#reports/campaigns/performance/${modelId}`;
      }
      if (canEditMargin) {
        menuItems[menuIndexRef.EditMargin].visible = true;
        menuItems[menuIndexRef.EditMargin].url = `#campaign/editMargin/${modelId}`;
      }
      if (createEditPermitted) {
        menuItems[menuIndexRef.Duplicate].visible = true;
      }
      if (createEditPermitted) {
        menuItems[menuIndexRef.BulkEdit].visible = true;
        menuItems[menuIndexRef.BulkEdit].url = `#campaign/bulkedit/${modelId}/create`;
      }
      if (canViewBrainLog && goalType !== "reach" && goalType !== "spend") {
        menuItems[menuIndexRef.Brain].visible = true;
        menuItems[menuIndexRef.Brain].url = `#appstore/launch/brain_insights/campaign_id=${modelId}`;
      }
      t1Menu(menuConfig, this);
    },
    events: {
      "click .add-strategy .add-action": "addStrategy"
    },
    eventhubEvents: {
      "advertiser:select": function ({
        name: name
      }) {
        this.advertiserName = name;
      },
      "campaign-create-edit:cancelUnload": "cancelUnload",
      "campaign:updateMenuItems": function () {
        this.prepareMenuItems();
      }
    },
    duplicateHandler: function (e) {
      const {
        model: campaign,
        el: $el
      } = this;
      var $loader = $el.parents().find(".loader");
      const half = 2;
      $(document).trigger("click");
      e.preventDefault();
      e.stopPropagation();
      if ($loader.length) {
        $loader = $loader.css("left", ($el.width() - $loader.width()) / half);
        $loader = $loader.css("top", "250px");
        $loader.show();
      } else {
        if (Polymer && Polymer.dom) {
          const node = document.createRange().createContextualFragment('<strand-loader class="loader"> <label>Loading...</label> </strand-loader>');
          Polymer.dom($el[0]).appendChild(node);
        }
        $loader = $el.find(".loader");
        $loader.css({
          left: ($el.width() - $loader.width()) / half,
          top: "250px"
        });
      }
      this.duplicationLayout = new T1Layout({
        el: () => $(".duplicate-campaign-modal"),
        template: '<div class="duplicate-holder"></div>',
        layout: {
          ".duplicate-holder": [{
            module: "campaign",
            viewType: "duplicateCampaign",
            options: {
              model: campaign,
              organizationInstance: this.organizationInstance,
              entityType: "campaign"
            }
          }]
        }
      });
      this.duplicationLayout.load();
    },
    addStrategy: function (e) {
      e.preventDefault();
      return T1.Location.set(`strategy/create/${this.model.id}`);
    },
    getCPEIODetails: function () {
      const promise = $.Deferred();
      this.clientProductEstimateIO.fetch({
        success: data => {
          promise.resolve(data);
        },
        statusInvalid: () => {
          promise.reject();
        },
        error: () => {
          promise.reject();
        }
      });
      return promise.promise();
    },
    loadLayout: function () {
      this.layout.el = this.$(".edit-create-tabs");
      this.layout.load();
      if (this.model.id) {
        this.layout.execute("enable", 1);
        $.proxy(this.prepareMenuItems, this)();
      }
    },
    viewRender: function () {
      this.render().then(() => {
        if (this.id && this.id !== "new" && this.isProviderFieldEnabled) {
          const self = this;
          this.clientProductEstimateIO = new ClientProductEstimateIO({
            id: this.id
          });
          this.getCPEIODetails().always(function (data) {
            if (data) {
              self.cpeio = data;
            }
            self.model.set({
              showCPEIO: true,
              cpeio: self.cpeio
            });
            self.loadLayout();
          });
        } else {
          this.loadLayout();
        }
      });
    },
    load: function () {
      if (!this.canLoad) {
        return;
      }
      let agencyId;
      if (this.id && this.id !== "new") {
        agencyId = this.model.attributes.advertiser.attributes.agency_id;
      }
      if (agencyId) {
        LaunchDarklyUtils.getPromise().then(() => {
          LaunchDarklyUtils.updateAgency(agencyId).then(() => {
            this.isProviderFieldEnabled = T1Permissions.check("feature", "mediaocean_prisma_integration");
            this.viewRender();
          });
        });
      } else {
        this.viewRender();
      }
    },
    viewChangeLog: function (e) {
      $(document).trigger("click");
      e.preventDefault();
      e.stopPropagation();
      const layout = new T1Layout({
        template: '<div class=".changeDialog"></div>',
        layout: {
          ".changeDialog": [{
            module: "changelog",
            viewType: "dialog",
            options: {
              model: this.model,
              entityType: "campaign"
            }
          }]
        }
      });
      layout.load();
    },
    viewBrainLog: function (e) {
      var campaignId = this.model ? this.model.id : null;
      $(document).trigger("click");
      e.preventDefault();
      const T1Location = T1.Location;
      const abortedUrl = T1Location.getAbortedURL();
      const windowOpener = window.open();
      windowOpener.opener = null;
      if (abortedUrl === "" || abortedUrl === false) {
        windowOpener.location = `#appstore/launch/brain_insights/campaign_id=${campaignId}`;
      } else {
        windowOpener.location = abortedUrl;
      }
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    unload: function () {
      if (isHybrid) {
        $("#add-new-strategy-btn").click(function (e) {
          e.preventDefault();
          return T1.Location.set(`strategy/create/${this.model.id}`);
        });
      }
    },
    canUnload: function () {
      return this.layout ? this.layout.canUnload() : true;
    },
    serialize: function () {
      var dateFormat = "MM/dd/yyyy";
      var model = this.model;
      const canCreateCampaign = this.id === undefined || this.id === "new" ? T1Permissions.check("campaign.create") : T1Permissions.check("campaign.edit", model);
      var modelJson = model.toJSON();
      var noData = "&nbsp;--";
      var newStratId = "new";
      var returnObj = {
        name: modelJson.name ? T1.Utils.escapeHTML(modelJson.name, true) : "New Campaign",
        id: modelJson.id || noData,
        canCreateCampaign: this.isReadOnly === true ? T1Permissions.check("campaign.readonly", model) : canCreateCampaign,
        startDateAsString: modelJson.start_date ? Date.parse(modelJson.start_date).toString(dateFormat) : noData,
        endDateAsString: modelJson.end_date ? Date.parse(modelJson.end_date).toString(dateFormat) : noData,
        agencyAndAdvertiser: getAgencyAndAdvertiserDisplay(model, this.advertiserName)
      };
      const addStrategy = this.id || this.model.id;
      const activeClassName = "add-action";
      const inActiveClassName = `${activeClassName}-disabled`;
      if (addStrategy === newStratId && this.model.id === undefined) {
        returnObj.canAddStrategy = false;
      } else {
        returnObj.canAddStrategy = true;
        this.$("#add-new-strategy-btn").removeClass(inActiveClassName);
        this.$("#add-new-strategy-btn").addClass(activeClassName);
      }
      if (modelJson.status !== undefined) {
        returnObj.campaign_status = function (target) {
          const status = modelJson.status === "1" ? "active" : "inactive";
          if (target) {
            target.removeClass("active").removeClass("inactive");
            target.addClass(status);
          }
          return status;
        };
      }
      returnObj.isReadOnlyView = this.isReadOnly;
      return returnObj;
    }
  });
});
