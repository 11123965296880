define('modules/auth/views/bab',["jQuery", "T1", "T1View", "T1Layout", "text!modules/auth/templates/bab.html"], function ($, T1, T1View, T1Layout, template) {
  "use strict";

  return T1View.extend({
    adBlockDetected: sessionStorage.getItem("browser.adBlockEnabled"),
    template: template,
    initialize: function () {
      this.layout = new T1Layout({
        el: null,
        selectorPath: ".mm-modal",
        template: template,
        layout: {}
      });
    },
    load: function () {
      var self = this;
      var show = function (popup) {
        if (popup.show) {
          $(popup).find("strand-button").on("click", self.unload.bind(self));
          popup.show();
          self.adBlockDetected = false;
          sessionStorage.removeItem("browser.adBlockEnabled");
        } else {
          window.setTimeout(function () {
            show(popup);
          }, 1e3);
        }
      };
      if (this.adBlockDetected) {
        this.render().then(function () {
          self.layout.load().then(function () {
            $(document).find(".w-adBlockDetected").each(function (i, elem) {
              self.popup = elem;
              show(self.popup);
            });
          });
        });
      }
    },
    unload: function () {
      if (this.popup) {
        this.popup.hide();
        $(this.el).remove();
      }
      this.destroy();
    }
  });
});
