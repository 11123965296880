define('modules/targeting/location/views/browse',["jQuery", "Underscore", "T1", "T1View", "text!../templates/grid_item.html", "text!../templates/grid_item_geofence.html", "text!../templates/grid_subitem.html", "text!../templates/grid_footer.html", "text!../templates/additional_headers.html", "text!../templates/empty_hyperlocal_message.html", "T1GridView", "text!templates/alert.html", "T1Spinner", "T1Layout", "collections/targetValues", "modules/targeting/shared/itemSelectionBehavior", "modules/strategy/views/helpers/defaultLocationPersistenceHelper", "moment"], function ($, _, T1, T1View, gridItemTemplate, geoFenceListItem, gridSubItemTemplate, gridFooter, AdditionalHeadersTemplate, EmptyHyperLocalMessage, createGrid, alertDialogTemplate, Spinner, T1Layout, TargetValues, ItemSelectionBehavior, DefaultLocPersistHelper, moment) {
  "use strict";

  function setCollectionFetchOptions(coll, id, item, options) {
    var fetchOptions = coll.fetchOptions || {};
    fetchOptions.dimension = id ? "REGN" : item.data("code") || options.dimension;
    coll.sortBy("name", "ascending");
    if (id) {
      fetchOptions.parent = id;
    } else {
      delete fetchOptions.parent;
    }
    coll.fetchOptions = fetchOptions;
  }
  const LocationBrowseView = T1View.extend({
    partials: {
      listItem: gridItemTemplate,
      gridSubItem: gridSubItemTemplate,
      gridFooter: gridFooter,
      alertDialog: alertDialogTemplate,
      geoFenceListItem: geoFenceListItem,
      altListItem: geoFenceListItem,
      additionalHeaderColumns: AdditionalHeadersTemplate,
      emptyHyperLocalMessage: EmptyHyperLocalMessage
    },
    headers: [{
      name: "type",
      label: "type"
    }],
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    initialize: function ({
      collection: collection,
      sharedCollection: sharedCollection,
      partialCollection: partialCollection,
      strategy: strategy,
      hyperLocalTargeting: hyperLocalTargeting
    }) {
      this.collection = collection;
      this.sharedCollection = sharedCollection;
      this.partialCollection = partialCollection;
      this.viewType = "browse";
      this.strategy = strategy;
      ItemSelectionBehavior.attachBehavior(this);
      this.hyperLocalTargeting = hyperLocalTargeting;
      this.hyperLocalCollection = this.hyperLocalTargeting.hyperLocalCollection;
      this.additionalHeaderColumns = true;
      this.altCollection = this.hyperLocalCollection;
    },
    events: {
      "click .item": "toggleNode",
      "click .item .icon.include": "includeOrExcludeTarget",
      "click .item .icon.exclude": "includeOrExcludeTarget",
      "click .hyperlocal-info": "launchHyperlocalApp"
    },
    eventhubEvents: {
      "bins:droppedLocation": function ({
        selectedList: selectedList
      }) {
        const {
          collection: collection,
          sharedCollection: sharedCollection,
          addTarget: addTarget
        } = this;
        var self = this;
        function addTargetValue(id, action) {
          const targetValue = sharedCollection.get(id) || collection.get(id);
          if (targetValue && targetValue.get("action") !== action) {
            addTarget.call(self, targetValue, action);
          }
        }
        for (let i = 0; i < selectedList.length; i++) {
          addTargetValue(selectedList[i].id, selectedList[i].action);
        }
      },
      defaultLocationSaved: function () {
        this.refreshDefaultLocations(this.collection);
        this.render();
      },
      "panel:attachmentsLoaded": "enableHyperLocal",
      "panel:selectSearchCategoy": "setSearchType"
    },
    dataEvents: {
      collection: {
        search: "search",
        "search.clear": "search",
        reset: "reload"
      },
      sharedCollection: {
        add: "sharedCollectionAddHandler",
        remove: "sharedCollectionRemoveHandler"
      },
      hyperLocalCollection: {
        search: "hyperLocalSearch",
        "search.clear": "hyperLocalSearch",
        reset: "reload"
      }
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_value_id",
      selectionAllowableKey: null,
      selectionAllowableValue: null,
      collectionKey: "collection"
    },
    refreshDefaultLocations: function (coll) {
      let collItem;
      var arrAll = [];
      const defaultLocationPersister = new DefaultLocPersistHelper();
      defaultLocationPersister.init();
      const defaultSettings = defaultLocationPersister.getSettings();
      const arrCategories = defaultSettings.include.split("|").concat(defaultSettings.exclude.split("|"));
      _.each(arrCategories, function (categoryText) {
        if (categoryText) {
          arrAll = arrAll.concat(categoryText.split(","));
        }
      });
      for (let j = 0; j < coll.length; j++) {
        collItem = coll.models ? coll.models[j] : coll[j];
        collItem.isDefault = undefined;
      }
      if (coll.models) {
        for (let i = 0; i < arrAll.length; i++) {
          const defaultItem = arrAll[i];
          collItem = coll.get(defaultItem);
          if (collItem) {
            collItem.isDefault = true;
          }
        }
      }
      if (!coll.models) {
        _.each(coll, function (item) {
          collItem = item;
          _.each(arrAll, function (defaultId) {
            if (defaultId === collItem.id) {
              collItem.isDefault = true;
            }
          });
        });
      }
    },
    enableHyperLocal: function () {
      this.el.find('.item[data-code="hyperlocal"]').removeClass("disabled");
    },
    includeOrExcludeTarget: function (evt) {
      var $target = $(evt.currentTarget);
      var disabled = $target.hasClass("disabled");
      const action = $target.hasClass("include") ? "include" : "exclude";
      var id = $target.closest(".item").attr("data-id");
      var item = this.collection.get(id);
      T1.EventHub.publish("updateFileAction", action);
      if (!disabled) {
        this.preventDefault(evt);
        this.removeDragClasses();
        this.setBrowsePath(id);
        this.addTarget(item, action);
      }
    },
    setBrowsePath: function (id) {
      var item = this.collection.get(id) || {};
      var isSpecial = item.id && item.id === "100989";
      const name = item.get && item.get("name") || "";
      const isDMA = !isSpecial && (name.includes("|") || name.includes("/"));
      var arrow = "&raquo";
      var dmaName = name.replace(/\/|\|/g, arrow);
      var regnName = isSpecial ? name : this.getParentPath(item.id);
      const pathName = isDMA ? dmaName : regnName;
      var hasArrow = pathName.lastIndexOf(arrow) >= 0;
      const len = hasArrow ? arrow.length : 0;
      var pathEndIndex = pathName.lastIndexOf(arrow) + len;
      const browsePath = hasArrow ? pathName.slice(0, pathEndIndex) : "";
      const browsePathEnd = hasArrow ? pathName.slice(pathEndIndex) : pathName;
      this.renderBrowsePath(browsePath, browsePathEnd);
    },
    clearBrowsePath: function () {
      this.renderBrowsePath("", "");
    },
    renderBrowsePath: function (browsePath, browsePathEnd) {
      this.renderPartialTemplate({
        template: this.partials.gridFooter,
        templateTarget: ".browse-path-selected",
        targetEl: this.$(".browse-path-selected"),
        data: {
          browse_path: browsePath,
          browse_path_end: browsePathEnd
        }
      });
    },
    getParentPath: function (id) {
      var result = "";
      var collection = this.collection;
      let node = collection.get(id);
      while (node && node.get("parent_value_id")) {
        result = result === "" ? node.get("name") : `${node.get("name")} &raquo ${result}`;
        node = collection.get(node.get("parent_value_id"));
      }
      return result === "" ? node.get("name") : `${node.get("name")} &raquo ${result}`;
    },
    addTarget: function (item, newAction) {
      let lastAction;
      var sharedCollection = this.sharedCollection;
      const itemInSharedCollection = sharedCollection.get(item.id);
      if (itemInSharedCollection) {
        lastAction = itemInSharedCollection.get("action");
        sharedCollection.remove(itemInSharedCollection);
      }
      if (lastAction !== newAction) {
        item.set({
          action: newAction
        });
        sharedCollection.add(item);
      }
    },
    toggleNode: function (event) {
      var self = this;
      var target = $(event.currentTarget);
      var item = target.closest(".item");
      var idVal = item.data && item.data("id") || "";
      var id = $.isNumeric(idVal) ? idVal : undefined;
      var isHyperLocal = item.attr("data-code") === "hyperlocal";
      var isHyperLocalSet = item.attr("class").indexOf("hyperlocal-design") > 0;
      var children = item.closest(".item-wrapper").children(".children");
      var paginationContainer = children.next();
      var isChildrenFetched = isHyperLocal ? this.hyperLocalCollection.loaded : children.find(".item-wrapper").length;
      var coll = isHyperLocal ? this.hyperLocalCollection : this.collection;
      var iconArrow = target.find(".icon-arrow");
      var iconSpinner = target.find(".icon-spinner");
      var isExpanded = iconArrow.hasClass("expanded");
      const canExpand = Boolean(iconArrow.length);
      function prepToRenderChildren() {
        iconArrow.addClass("expanded");
        iconArrow.addClass("hide");
        Spinner.apply(iconSpinner, self.spinnerOpts);
      }
      function pushUnknownAndOtherToBackOfCollection() {
        var otherAndUnknown = coll.filter(function (model) {
          const name = model.get("name");
          return name === "Other" || name === "Unknown";
        });
        coll.remove(otherAndUnknown, {
          silent: true
        });
        coll.add(otherAndUnknown, {
          silent: true
        });
      }
      function renderChildren() {
        var collectionToAdd = isHyperLocal ? coll : self.filterCollectionByDimension(coll.fetchOptions.dimension, true);
        pushUnknownAndOtherToBackOfCollection();
        if (isHyperLocal) {
          _.each(collectionToAdd.models, function (model) {
            if (!model.strategy) {
              model.strategy = self.strategy;
            }
            model.applyDesignAttributes();
            model.applySetAttributes();
          });
        }
        if (collectionToAdd.length) {
          iconSpinner.spin(false);
          iconArrow.removeClass("hide");
          self.addChildren({
            entityId: idVal,
            collection: collectionToAdd,
            container: children,
            rowType: isHyperLocal ? "hyperlocal" : item.attr("data-code")
          });
        } else if (collectionToAdd.loaded) {
          iconSpinner.spin(false);
          iconArrow.removeClass("hide");
          renderEmptyMessage();
        }
      }
      function renderHyperLocalSets() {
        var set = coll.get(idVal);
        var sets = set.get("sets");
        pushUnknownAndOtherToBackOfCollection();
        iconSpinner.spin(false);
        iconArrow.removeClass("hide");
        self.addChildren({
          entityId: idVal,
          collection: sets,
          container: children,
          rowType: "hyperlocal-design"
        });
      }
      function renderEmptyMessage() {
        self.renderPartialTemplate({
          template: self.partials.emptyHyperLocalMessage,
          useTemplateHtml: false,
          action: "html",
          targetEl: children
        });
      }
      function hyperLocalError() {
        iconSpinner.spin(false);
        iconArrow.removeClass("hide");
      }
      if (item.hasClass("disabled") && isHyperLocal) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      if (canExpand) {
        if (!isChildrenFetched) {
          prepToRenderChildren();
          setCollectionFetchOptions(coll, id, item);
          if (id) {
            this.parentId = id.toString();
          }
          if (isHyperLocalSet) {
            renderHyperLocalSets();
          } else {
            const fetchObj = {
              add: true,
              silent: true,
              success: renderChildren
            };
            if (isHyperLocal) {
              fetchObj.error = hyperLocalError;
            }
            coll.fetch(fetchObj);
          }
        }
        if (id) {
          this.setBrowsePath(id);
        } else {
          this.clearBrowsePath();
        }
        if (!isExpanded) {
          children.show();
          if (paginationContainer.children().length) {
            paginationContainer.show();
          }
          iconArrow.addClass("expanded");
        } else {
          children.hide();
          paginationContainer.hide();
          iconArrow.removeClass("expanded");
        }
        this.updateScrollBar();
      }
    },
    addChildren: function ({
      rowType: rowType,
      collection: collection,
      container: container,
      entityId: entityId
    }) {
      let data;
      var $el = this.el;
      var boundAddChildren = this.addChildren.bind(this);
      var boundPreFetchMore = this.preFetchMore.bind(this);
      var models = collection.models;
      switch (rowType) {
        case "hyperlocal":
          data = {
            list: this.hyperLocalDesignPreSerialize(collection),
            isHyperLocal: true
          };
          break;
        case "hyperlocal-design":
          data = {
            list: this.hyperLocalSetPreSerialize(collection),
            isHyperLocal: false
          };
          models = collection;
          break;
        default:
          data = {
            list: this.preSerialize(collection),
            isHyperLocal: false
          };
          break;
      }
      container.show();
      this.collection.add(models, {
        silent: true
      });
      if (rowType.indexOf("hyperlocal") === 0 && this.partialCollection && !this.cachedPartialCollection) {
        this.cachedPartialCollection = this.partialCollection;
        delete this.partialCollection;
      } else if (rowType.indexOf("hyperlocal") < 0 && !this.partialCollection && this.cachedPartialCollection) {
        this.partialCollection = this.cachedPartialCollection;
        delete this.cachedPartialCollection;
      }
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: container,
        data: data
      });
      if (collection.count > collection.pageLimit) {
        if ($.isNumeric(entityId)) {
          this.collection.parent = entityId;
        } else {
          delete this.collection.parent;
        }
        const paginationSelector = `[data-code='${container.prev().data("code")}'] ~ .pagination`;
        this.paginationLayout = new T1Layout({
          el: () => $el,
          template: "<div></div>",
          selectorPath: paginationSelector,
          layout: {}
        });
        this.paginationLayout.layout[paginationSelector] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: $el,
            collection: collection,
            onSuccess: boundAddChildren,
            preFetchMore: boundPreFetchMore,
            indent: "margin: 0 -55px 0 55px"
          }
        }];
        this.paginationLayout.load();
        const paginationContainer = $el.find(paginationSelector);
        paginationContainer.show();
      }
      this.updateScrollBar();
      this.applyDraggable();
    },
    pickSeparator: function (name, isSpecial) {
      return isSpecial ? name : name.replace(/\/|\|/g, "&raquo");
    },
    preFetchMore: function ({
      collection: collection,
      item: item
    }) {
      const id = item.data("id");
      const dimension = collection.fetchOptions.dimension;
      const page = collection.page;
      if (!collection.length) {
        return;
      }
      setCollectionFetchOptions(collection, id, item, {
        dimension: dimension
      });
      collection.page = page;
      return {
        collection: collection
      };
    },
    filterCollectionByDimension: function (dimension, returnNewColl) {
      var list;
      const self = this;
      const parent = this.collection.fetchOptions.parent;
      if (dimension === "REGN") {
        list = this.collection.filter(function (model) {
          var filteredModel;
          var filteredModelParentId = model && model.get("parent_value_id");
          var isRegionTargetValue = model && model.get("dimension_code") === "REGN";
          if (isRegionTargetValue) {
            if (parent) {
              if (filteredModelParentId && filteredModelParentId === self.parentId) {
                filteredModel = model;
              }
            } else {
              if (!filteredModelParentId) {
                filteredModel = model;
              }
            }
          }
          return filteredModel;
        });
      } else {
        list = this.collection.filter(model => model && model.get("dimension_code") === "DMAX");
      }
      if (returnNewColl) {
        list = new TargetValues(list);
        list.isPaginated = true;
        list.entityType = "Target Value";
        list.count = this.collection.count;
        list.page = this.collection.page;
        list.pageLimit = this.collection.pageLimit;
        list.fetchOptions = this.collection.fetchOptions;
      }
      return list;
    },
    hyperLocalSetPreSerialize: function (sets) {
      var self = this;
      var targetCodes = {};
      return _.map(sets, function (set) {
        var parentInSharedCollection = self.sharedCollection.get(set.parent_value_id);
        var includedOrExcluded;
        if (!targetCodes[set.targeting_code]) {
          targetCodes[set.targeting_code] = set;
          self.applySelectionStates(set);
          includedOrExcluded = set.isIncluded || set.isParentExcluded;
          if (parentInSharedCollection && !includedOrExcluded) {
            switch (parentInSharedCollection.get("action")) {
              case "include":
                set.isParentIncluded = true;
                break;
              case "exclude":
                set.isParentExcluded = true;
                break;
            }
          }
          switch (set.status) {
            case "built":
              set.isPending = false;
              break;
            case "building":
              set.isPending = true;
              break;
            case "requested":
              set.isPending = true;
              break;
          }
          set.id = `${set.parent_value_id}:${set.targeting_code}`;
          set.name = set.targeting_code;
          set.set_ids = set.set_id;
          set.set_names = set.set_name;
          delete set.set_id;
          delete set.set_name;
          return set;
        }
        targetCodes[set.targeting_code].set_ids += `,${set.set_id}`;
        targetCodes[set.targeting_code].set_names += `,${set.set_name}`;
      });
    },
    hyperLocalDesignPreSerialize: function (coll) {
      var self = this;
      var models = Array.isArray(coll) ? coll : coll.models;
      return _.map(models, function (model) {
        var json = model.toJSON();
        switch (json.status) {
          case "built":
            json.mappingStatus = "Live";
            json.isPending = false;
            break;
          case "building":
            json.mappingStatus = "Pending";
            json.isPending = true;
            break;
          case "requested":
            json.mappingStatus = "Pending";
            json.isPending = true;
            break;
        }
        json.dateModified = moment(json.updated_at).format("L");
        json.hasSets = Boolean(json.sets.length);
        self.applySelectionStates(json);
        return json;
      });
    },
    preSerialize: function (coll) {
      let searchTerm;
      var result = [];
      var self = this;
      const dimensionCode = this.collection.fetchOptions.dimension;
      var highlightText = T1.Utils.highlightText;
      this.refreshDefaultLocations(coll);
      $.each(coll.models || coll, function (index, model) {
        const json = model.toJSON ? model.toJSON() : model;
        const isSpecial = model.parent_value_id === "100989" || model.id === "100989";
        function isTermInName() {
          var term = searchTerm.toLowerCase();
          var name = json.name.toLowerCase();
          return name.includes(term);
        }
        json.isDefault = model.isDefault;
        if (self.viewType === "search") {
          json.length = false;
          searchTerm = self.searchTerm;
          let fullPathName = self.getParentPath(json.id);
          fullPathName = self.pickSeparator(fullPathName, isSpecial);
          json.name = highlightText(fullPathName, searchTerm);
          self.applySelectionStates(json, coll);
          if (isTermInName()) {
            result.push(json);
          }
        } else {
          json.length = Number(json.child_count) > 1;
          if (dimensionCode === json.dimension_code) {
            json.name = self.pickSeparator(json.name, isSpecial);
            self.applySelectionStates(json, coll);
            result.push(json);
          }
        }
      });
      return result;
    },
    hyperLocalSearch: function (data) {
      if (data) {
        const term = data.term;
        this.viewType = "search";
        this.searchTerm = term;
        this.setSearchLoader();
        this.hyperLocalCollection.loaded = false;
        this.hyperLocalCollection.search({
          term: term,
          collection: this.collection
        });
      } else {
        this.resetSearch();
      }
    },
    search: function (data) {
      var collection = this.collection;
      if (data) {
        const term = data.term;
        const type = data.type;
        this.viewType = "search";
        this.searchTerm = term;
        collection.fetchOptions.dimension = type;
        collection.search.set({
          params: [{
            searchField: "name",
            term: `*${term}*`
          }],
          fetchRequired: false
        });
        this.setSearchLoader();
        collection.fetch();
      } else {
        this.resetSearch();
      }
    },
    resetSearch: function () {
      this.collection.search.clear();
      this.collection.reset([], {
        silent: true
      });
      this.hyperLocalCollection.resetSearch();
      this.viewType = "browse";
      this.load();
    },
    setSearchType: function ({
      type: type
    }) {
      this.searchCategory = type;
      this.altSearchSelected = type === "hyperlocal";
    },
    launchHyperlocalApp: function (e) {
      e.preventDefault();
      e.stopPropagation();
      window.open("#appstore/launch/factual_geofence_manager", "_blank");
    },
    unload: function () {
      this.collection.trigger("search.clear");
      this.hyperLocalCollection.reset();
      this.hyperLocalCollection.loaded = false;
    },
    serialize: function () {
      let result;
      const viewType = this.viewType;
      if (viewType === "browse") {
        result = {
          list: [{
            name: "Country/Region/City",
            code: "REGN",
            length: true
          }, {
            name: "DMA/Media Market",
            code: "DMAX",
            length: true
          }],
          contentType: viewType
        };
        const isBatch = this.strategy.get("supply_type") === "BATCH";
        if (!isBatch) {
          result.list.push({
            name: "Geofence",
            code: "hyperlocal",
            length: true,
            hasToolTip: true,
            toolTipText: "To create a geofence, visit the Hyperlocal App located in the Apps Section of T1.",
            disabled: !this.hyperLocalTargeting.attachmentsLoaded
          });
        }
      } else {
        const data = [];
        this.collection.each(function (model) {
          data.push(model.toJSON());
        });
        result = {
          list: this.searchCategory !== "hyperlocal" ? this.preSerialize(data) : this.hyperLocalDesignPreSerialize(this.hyperLocalCollection.searchResults),
          contentType: viewType
        };
      }
      result.additionalHeaderColumns = this.additionalHeaderColumns;
      return result;
    }
  });
  return createGrid(LocationBrowseView);
});
