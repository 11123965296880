define('models/strategy',['require','jQuery','Backbone','T1','T1Model','T1Permissions','collections/dayParts','collections/strategyDomainRestrictions','models/performanceDataForModels','collections/pixels','utils/SiteUtils','collections/strategyAvailableConcepts','collections/strategySiteLists','models/strategyTargetDimensions','models/extendedMarginManagementReport','collections/extendedMarginManagementReportCollection','models/goalMonitoringReport','collections/goalMonitoringReportCollection','collections/strategyConcepts','collections/strategyTargets'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Backbone = require("Backbone");
  const T1 = require("T1");
  const T1Model = require("T1Model");
  const T1Permissions = require("T1Permissions");
  const DayParts = require("collections/dayParts");
  const DomainRestrictions = require("collections/strategyDomainRestrictions");
  const PerformanceData = require("models/performanceDataForModels");
  const Pixels = require("collections/pixels");
  const SiteUtils = require("utils/SiteUtils");
  const StrategyAvailableConcepts = require("collections/strategyAvailableConcepts");
  const StrategySiteLists = require("collections/strategySiteLists");
  const TargetDimensions = require("models/strategyTargetDimensions");
  const ExtendedMarginManagementReport = require("models/extendedMarginManagementReport");
  const ExtendedMarginManagementReportCollection = require("collections/extendedMarginManagementReportCollection");
  const GoalMonitoringReport = require("models/goalMonitoringReport");
  const GoalMonitoringReportCollection = require("collections/goalMonitoringReportCollection");
  const StrategyConcepts = require("collections/strategyConcepts");
  const StrategyTargets = require("collections/strategyTargets");
  function transformTargetDimensions(dimensions) {
    var result = [];
    var dimensionsHash = {};
    $.each(dimensions, function (index, dimension) {
      var targetDimension = dimensionsHash[dimension.target_dimension_id] || {};
      var targetValue = targetDimension.target_value || [];
      var target_value = dimension.target_value;
      var action = dimension.exclude === "0" ? "include" : "exclude";
      targetDimension.target_dimension_id = dimension.target_dimension_id;
      for (let i = 0; i < target_value.length; i++) {
        target_value[i].action = action;
      }
      targetDimension.target_value = targetValue.concat(target_value);
      targetDimension[`${action}_OP`] = dimension.target_op;
      dimensionsHash[dimension.target_dimension_id] = targetDimension;
    });
    $.each(dimensionsHash, function (id, object) {
      result.push(object);
    });
    return result;
  }
  const baseSaveMethod = T1Model.prototype.save;
  T1.Models.Strategy = T1Model.extend({
    url: "strategies/",
    name: "strategy",
    fixture: "-strategy",
    relations: [{
      type: Backbone.HasMany,
      key: "strategyConcepts",
      relatedModel: "StrategyConcept",
      collectionType: "StrategyConcepts",
      includeInJSON: false,
      reverseRelation: {
        key: "strategy",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: "rpt_goal_monitoring",
      relatedModel: "GoalMonitoringReport",
      collectionType: "GoalMonitoringReportCollection",
      includeInJSON: false,
      reverseRelation: {
        key: "strategy",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: "rpt_legacy_goal_monitoring",
      relatedModel: "GoalMonitoringReport",
      collectionType: "GoalMonitoringReportCollection",
      includeInJSON: false,
      reverseRelation: {
        key: "strategy",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: "strategy_target",
      relatedModel: "StrategyTargetValue",
      collectionType: "StrategyTargets",
      includeInJSON: false,
      reverseRelation: {
        key: "strategy",
        includeInJSON: false
      }
    }],
    postEntityParse: {
      rpt_legacy_goal_monitoring: T1.Utils.objectTOArray,
      rpt_goal_monitoring: T1.Utils.objectTOArray,
      rpt_margin_management_partial: T1.Utils.objectTOArray,
      strategy_target: function (entity) {
        var result = [];
        if (!$.isArray(entity)) {
          entity.target_value = T1.Utils.objectTOArray(entity.target_value || []);
          result.push(entity);
        } else {
          $.each(entity, function (index, target_dimenstion) {
            target_dimenstion.target_value = T1.Utils.objectTOArray(target_dimenstion.target_value || []);
            result.push(target_dimenstion);
          });
        }
        return transformTargetDimensions(result);
      },
      roi_target: function (data) {
        var target = parseFloat(data);
        if (target % 1 !== 0) {
          return data ? target : undefined;
        }
        return data ? target.toFixed(0) : undefined;
      }
    },
    canEdit() {
      var permissionDerferred = $.Deferred();
      const strategyEditPermission = this.name ? T1Permissions.check(`${this.name}.edit`, this) : false;
      const videoEditPermission = this.get("media_type") === "VIDEO" ? T1Permissions.check("feature", "video") : true;
      permissionDerferred.resolve(strategyEditPermission && videoEditPermission);
      return permissionDerferred;
    },
    validateAttrs(attrs) {
      var errors = [];
      var requiredField = "This is a required field.";
      var optionalFields = ["impression_pacing_amount", "description", "max_bid_wm", "min_bid", "budget", "ads_txt_verified"];
      const {
        goal_type: goal_type,
        goal_value: goal_value,
        impression_pacing_amount: impression_pacing_amount,
        max_bid: max_bid,
        min_bid: min_bid,
        pacing_amount: pacing_amount,
        pacing_optimization_amount: pacing_optimization_amount,
        pacing_optimization_type: pacing_optimization_type,
        supply_type: supply_type
      } = attrs;
      if (!attrs.hasImpressionCap) {
        optionalFields.push("impression_cap");
      }
      if (goal_type === "roi") {
        optionalFields.push("goal_value");
        optionalFields.push("max_bid");
      } else if (goal_type === "spend" && supply_type !== "PROGRAMMATIC_GUARANTEED") {
        optionalFields.push("goal_value");
        optionalFields.push("roi_target");
      } else {
        optionalFields.push("max_bid");
        optionalFields.push("roi_target");
      }
      if (goal_type === "vcpm" || goal_type === "viewability_rate") {
        if (this.campaign.viewability_type === undefined || this.campaign.viewability_type === "") {
          errors.push({
            field: "goal_type",
            message: `You must enable Viewability Post Bid Reporting at the campaign level in order to select a \n              strategy goal type of vCPM or Viewability Rate.`
          });
        }
      }
      if (!pacing_optimization_type || pacing_optimization_type === "") {
        optionalFields.push("pacing_optimization_amount");
        optionalFields.push("pacing_optimization_type");
      } else {
        optionalFields.push("pacing_amount");
      }
      if (pacing_optimization_type === "impression") {
        const campaign = this.campaign || this.get("campaign").toJSON();
        if (campaign && !campaign.total_impression_budget) {
          errors.push({
            field: "pacing_optimization_type",
            message: `Campaign must have a non-zero impression budget for strategies\n              to use Impression Target Allocation.`
          });
        }
      }
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          errors.push({
            field: key,
            message: requiredField
          });
        }
        if (key === "impression_pacing_amount") {
          if (impression_pacing_amount !== "" && impression_pacing_amount < 0) {
            errors.push({
              field: key,
              message: "Impression Pacing amount must be greater than or equal to 0.01."
            });
          }
        }
        if (key === "frequency_amount") {
          const maxAmount = 9999999;
          if (attrs.frequency_amount > maxAmount) {
            errors.push({
              field: key,
              message: "Frequency amount cannot be higher than 9999999."
            });
          }
        }
        if (key === "goal_value") {
          if (goal_type === "ctr" || goal_type === "vcr" || goal_type === "viewability_rate") {
            const maxValue = 100;
            if (goal_value < 0 || goal_value > maxValue) {
              let fieldName;
              switch (goal_type) {
                case "ctr":
                  fieldName = "CTR";
                  break;
                case "vcr":
                  fieldName = "Video Completion Rate";
                  break;
                case "viewability_rate":
                  fieldName = "viewability_rate";
                  break;
                default:
                  fieldName = goal_type.toUpperCase().replace(/_/g, " ");
                  break;
              }
              errors.push({
                field: key,
                message: `${fieldName} goal value must be a number between 0% and 100%.`
              });
            }
          } else if (supply_type === "BATCH" && goal_type !== "roi" && goal_type !== "spend") {
            if (goal_value <= 0) {
              errors.push({
                field: key,
                message: "Goal value must be positive"
              });
            }
          }
        }
        if (key === "min_bid" || key === "max_bid") {
          if (min_bid !== "" && max_bid !== "") {
            if (min_bid > max_bid) {
              errors.push({
                field: key,
                message: key === "min_bid" ? `Min bid of ${min_bid} must be lower than max bid of ${max_bid}.` : `Max bid of ${max_bid} must be higher than min bid of ${min_bid}.`
              });
            }
          }
        }
        if (key === "pacing_optimization_amount") {
          if (pacing_optimization_amount !== "" && pacing_optimization_amount <= 0) {
            errors.push({
              field: key,
              message: "Target Allocation must be greater than 0."
            });
          }
        }
        if (key === "pacing_amount") {
          if (pacing_amount !== "" && pacing_amount <= 0) {
            errors.push({
              field: key,
              message: "Pacing amount must be greater than or equal to 0.01."
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    createEdit(rawData, options) {
      var useOptimization = rawData.use_optimization;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      const isProgrammticGuaranteed = rawData.supply_type === "PROGRAMMATIC_GUARANTEED";
      function booleanToInt(attrAry) {
        for (let i = 0; i < attrAry.length; i++) {
          rawData[attrAry[i]] = rawData[attrAry[i]] ? 1 : 0;
        }
      }
      if ((useOptimization === "0" || useOptimization === "false") && !isProgrammticGuaranteed) {
        rawData.goal_value = rawData.goal_value || rawData.max_bid;
      }
      if (!this.id) {
        rawData.run_on_all_exchanges = rawData.supply_type === "RMX_API" || isProgrammticGuaranteed ? "0" : "1";
        if (rawData.supply_type === "BATCH") {
          delete rawData.run_on_all_exchanges;
        }
      }
      if (rawData.goal_type === "spend" && typeof rawData.goal_value === "undefined") {
        rawData.goal_value = "";
      }
      const validationErrors = this.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
        return;
      }
      booleanToInt(["use_campaign_start", "use_campaign_end"]);
      delete rawData.hasImpressionCap;
      delete rawData.ignore;
      if (rawData.use_campaign_start) {
        rawData.start_date = "";
      }
      if (rawData.use_campaign_end) {
        rawData.end_date = "";
      }
      if (rawData.goal_type === "spend" && rawData.supply_type === "BATCH" && rawData.goal_value === "") {
        rawData.goal_value = rawData.max_bid;
      }
      if (isProgrammticGuaranteed) {
        delete rawData.supply_type;
      }
      this.save(rawData, {
        success: function (model) {
          var onSuccess = options.success || $.noop;
          onSuccess(model);
        },
        statusInvalid: statusInvalid,
        conflict: conflict
      });
    },
    getPerformanceDataObj() {
      if (!this.performanceData) {
        this.performanceData = new PerformanceData({
          hostModel: this
        });
      }
      return this.performanceData;
    },
    fetch(opts) {
      var perfData = this.getPerformanceDataObj();
      perfData.hostModelFetchHandler(opts);
    },
    fetchSiteLists() {
      var siteList = this.siteList || new StrategySiteLists();
      this.siteList = siteList;
      siteList.id = this.id;
      siteList.fetchOptions = $.extend({
        full: "*",
        sort_by: "id",
        q: "assigned==1"
      }, siteList.fetchOptions);
      siteList.fetch({
        add: true,
        success: function () {
          siteList.trigger("reset");
        }
      });
      return siteList;
    },
    fetchTargetDimensions(opts) {
      let dimensions;
      var isDataReady = false;
      if (!this.id) {
        throw new Error('The method "fetchTargetDimensions" can only be invoked on a saved, existing strategy model.');
      }
      if (this.targetDimensions) {
        dimensions = this.targetDimensions;
        isDataReady = dimensions.get("target_dimensions").loaded;
      } else {
        dimensions = new TargetDimensions({
          id: this.id
        });
        this.targetDimensions = dimensions;
      }
      if (!isDataReady) {
        dimensions.fetch(opts);
      }
      return dimensions;
    },
    fetchPixelTargets(successCallback = $.noop, add = true) {
      var pixelTargets = this.pixel_bundles || new Pixels();
      var pixel_target_expr = this.get("pixel_target_expr") || "";
      var includes = [];
      var includeOperator = "OR";
      var excludeOperator = "OR";
      var excludes = [];
      const oneExpression = 1;
      const twoExpressions = 2;
      function applyAction(list, action) {
        $.each(list, function (index, id) {
          const model = pixelTargets.get(id);
          if (model) {
            model.set({
              action: action
            });
          }
        });
      }
      function success() {
        applyAction(includes, "include");
        applyAction(excludes, "exclude");
        successCallback(pixelTargets);
      }
      this.pixel_bundles = pixelTargets;
      pixelTargets.urlFilter.disableEventsListeners();
      const ids = pixel_target_expr.match(/[0-9]+/g);
      const expressions = pixel_target_expr ? pixel_target_expr.match(/\( \[\d+](( AND | OR )\[\d+])* \)/g) : [];
      switch (expressions && expressions.length) {
        case twoExpressions:
          includes = expressions[0].match(/\d+/g);
          excludes = expressions[1].match(/\d+/g);
          includeOperator = expressions[0].includes("AND") ? "AND" : "OR";
          excludeOperator = expressions[1].includes("AND") ? "AND" : "OR";
          break;
        case oneExpression:
          if (pixel_target_expr.includes("NOT")) {
            excludes = expressions[0].match(/\d+/g);
            excludeOperator = expressions[0].includes("AND") ? "AND" : "OR";
          } else {
            includes = expressions[0].match(/\d+/g);
            includeOperator = expressions[0].includes("AND") ? "AND" : "OR";
          }
          break;
      }
      pixelTargets.includeOperator = includeOperator;
      pixelTargets.excludeOperator = excludeOperator;
      pixelTargets.fetchOptions = $.extend({
        with: ["advertiser", "agency", "provider"],
        full: "*"
      }, pixelTargets.fetchOptions);
      if (ids && ids.length) {
        pixelTargets.search.set({
          idList: `${ids}`
        });
        pixelTargets.isPaginated = false;
        pixelTargets.fetch({
          success: success,
          add: add
        });
      } else {
        success();
      }
      return pixelTargets;
    },
    savePixelTargets(success, statusInvalid, conflict) {
      var pixelTargets = this.pixel_bundles || new Pixels();
      var includeOperator = pixelTargets.includeOperator || "OR";
      var excludeOperator = pixelTargets.excludeOperator || "OR";
      var includeExpression = "";
      var excludeExpression = "";
      var fullExpression = "";
      pixelTargets.each(function (pixel) {
        if (pixel.get("action") === "include") {
          includeExpression = `${includeExpression + (includeExpression.length ? ` ${includeOperator} ` : "")}[${pixel.id}]`;
        } else {
          excludeExpression = `${excludeExpression + (excludeExpression.length ? ` ${excludeOperator} ` : "")}[${pixel.id}]`;
        }
      });
      if (includeExpression.length && excludeExpression.length) {
        fullExpression = `( ${includeExpression} ) AND NOT ( ${excludeExpression} )`;
      } else if (includeExpression.length) {
        fullExpression = `( ${includeExpression} )`;
      } else if (excludeExpression.length) {
        fullExpression = `NOT ( ${excludeExpression} )`;
      }
      this.save({
        pixel_target_expr: fullExpression
      }, {
        success: function () {
          success();
          T1.EventHub.publish("strategy-create-edit:reloadSubViews");
          T1.EventHub.publish("strategy-create-edit:savedPixelBundels");
        },
        statusInvalid: statusInvalid,
        conflict: conflict
      });
    },
    fetchDomainRestrictions() {
      var domainRestrictions = this.getDomainRestrictions();
      domainRestrictions.fetch();
      return domainRestrictions;
    },
    getDomainRestrictions() {
      var domainRestrictions = this.domainRestrictions || new DomainRestrictions([], {
        isPaginated: false,
        fetchOptions: {
          full: "*"
        }
      });
      domainRestrictions.urlFilter.set({
        entity: "strategy",
        id: this.id
      });
      this.domainRestrictions = domainRestrictions;
      return domainRestrictions;
    },
    getAvailableConcepts(pageLimit) {
      var availableConcepts = this.availableConcepts || new StrategyAvailableConcepts([]);
      availableConcepts.id = this.id;
      availableConcepts.updatePageLimit(pageLimit);
      availableConcepts.fetch();
      this.availableConcepts = availableConcepts;
      return availableConcepts;
    },
    saveDomainRestrictions(data, successCallback, statusInvalid) {
      var postObject = {};
      var onSuccess = successCallback || $.noop;
      var counter = 1;
      if (data === undefined || $.isEmptyObject(data)) {
        throw new Error("Data is required to save the site list");
      }
      if (data.count > 0) {
        const action = data.isIncluded ? "INCLUDE" : "EXCLUDE";
        const parsedsites = data.sitelist.replace(/(\r|\n|,)/g, " ").split(/\s+/);
        $.each(parsedsites, function (index, site) {
          var type = false;
          if (SiteUtils.isApp(site)) {
            site = site.replace(/^app:/i, "");
            type = "APP";
          } else if (SiteUtils.isIPAddress(site) || SiteUtils.isExtendedDomain(site, true) || SiteUtils.isIDNDomain(site, true)) {
            site = SiteUtils.normalizeExtendedDomain(site);
            type = "DOMAIN";
          }
          if (type) {
            postObject[`domains.${counter}.restriction`] = action;
            postObject[`domains.${counter}.domain`] = site;
            postObject[`domains.${counter}.target_type`] = type;
            counter++;
          }
        });
      } else {
        postObject.forcePost = "1";
      }
      this.save(postObject, {
        action: "domain_restrictions",
        success: (...args) => {
          this.fetch();
          onSuccess.apply(this, args);
        },
        conflict: statusInvalid
      });
    },
    fetchDayParts(opts) {
      var dayParts = this.dayParts || new DayParts();
      this.dayParts = dayParts;
      dayParts.id = this.id;
      dayParts.fetch(opts);
      return dayParts;
    },
    saveDayParts(data, successCallback, statusInvalid) {
      var postObject = {};
      var onSuccess = successCallback || $.noop;
      var counter = 1;
      if (data === undefined) {
        throw new Error("Data is required to save the day parts");
      }
      if (data.length) {
        $.each(data, function (index, dayPart) {
          postObject[`day_parts.${counter}.start_hour`] = dayPart.start_hour;
          postObject[`day_parts.${counter}.end_hour`] = dayPart.end_hour;
          postObject[`day_parts.${counter}.days`] = dayPart.days;
          postObject[`day_parts.${counter}.user_time`] = dayPart.user_time;
          counter++;
        });
      }
      this.save(postObject, {
        action: "day_parts",
        success: (...args) => {
          this.fetch();
          onSuccess.apply(this, args);
          T1.EventHub.publish("strategy-create-edit:reloadSubViews");
        },
        statusInvalid: statusInvalid,
        conflict: statusInvalid
      });
    },
    duplicateStrategy(rawData, options) {
      var nameField = "name";
      var statusInvalid = options.invalid || $.noop;
      var validationErrors = this.validateAttrs(rawData);
      if (validationErrors === undefined) {
        if (rawData[nameField]) {
          const maxLength = 126;
          const maxTotalLength = 128;
          const strategyName = rawData[nameField] = rawData[nameField].trim();
          if (strategyName.length > maxTotalLength || strategyName === this.get(nameField) && strategyName.length >= maxLength) {
            validationErrors = [{
              field: nameField,
              message: "This field cannot be more than 128 characters."
            }];
          } else if (!strategyName.length) {
            validationErrors = [{
              field: nameField,
              message: "This field must have at least one character, not including spaces."
            }];
          }
        }
      }
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
        return;
      }
      if (rawData.use_campaign_start === "1") {
        rawData.start_date = "";
      }
      if (rawData.use_campaign_end === "1") {
        rawData.end_date = "";
      }
      delete rawData.ignore;
      this.sync("POST", rawData, {
        action: "copy",
        success: function (data) {
          var onSuccess = options.success || $.noop;
          onSuccess(data);
        },
        statusInvalid: statusInvalid
      });
    },
    getCurrencyCode() {
      var campaign = this.get("campaign");
      return campaign && campaign.get ? campaign.get("currency_code") : "";
    },
    requestAudienceTargetData(segments, callBack, onStatusInvalid, operation = "size") {
      const action = `audience_segments/${operation}`;
      return this.sync("POST", segments, {
        action: action,
        success: callBack,
        fixture: `-strategy-audience-segments-${operation}`,
        statusInvalid: onStatusInvalid,
        params: {
          currency_code: this.getCurrencyCode()
        }
      });
    },
    requestSafetyTargetData(targets, callBack, onStatusInvalid) {
      this.sync("POST", targets, {
        action: "target_values/contextual_fee",
        success: callBack,
        fixture: "-strategy-contextual-targets-contextual_fee",
        statusInvalid: onStatusInvalid,
        params: {
          currency_code: this.getCurrencyCode()
        }
      });
    },
    requestTargetSegmentsTargetData(segments, callBack, onStatusInvalid, operation = "size") {
      const action = `targeting_segments/${operation}`;
      return this.sync("POST", segments, {
        action: action,
        success: callBack,
        fixture: `-strategy-targeting-segments-${operation}`,
        statusInvalid: onStatusInvalid,
        params: {
          currency_code: this.getCurrencyCode()
        }
      });
    },
    save(attributes, opts = {}) {
      var self = this;
      var originalSuccess = opts.success || null;
      var dataObj = attributes || {};
      var errObj = null;
      var freqType = dataObj.frequency_type;
      function successHandler(args) {
        if ("impression_cap" in dataObj) {
          if (dataObj.impression_cap === undefined || dataObj.impression_cap === "") {
            delete self.attributes.impression_cap;
          }
        }
        if ("budget" in dataObj) {
          if (dataObj.budget === undefined || dataObj.budget === "") {
            delete self.attributes.budget;
            delete self.attributes.budget_amount;
          }
        }
        if (originalSuccess) {
          originalSuccess(args);
        }
      }
      function buildRequiredFieldErrData(fieldName) {
        return [{
          field: fieldName,
          message: "This is a required field."
        }];
      }
      if (freqType) {
        if (freqType === "standard") {
          dataObj.frequency_type = "no-limit";
          dataObj.frequency_amount = 0;
          dataObj.frequency_interval = "not-applicable";
          dataObj.frequency_optimization = "1";
        } else {
          dataObj.frequency_optimization = "0";
        }
      }
      if ("impressioncap_type" in dataObj) {
        if (dataObj.impressioncap_type === "no-cap") {
          dataObj.impression_cap = "";
        } else if (dataObj.impression_cap === "") {
          errObj = buildRequiredFieldErrData("impression_cap");
        }
      } else if ("impression_pacing_type" in dataObj) {
        if (dataObj.impression_pacing_type !== "no-limit" && dataObj.impression_pacing_amount === "") {
          errObj = buildRequiredFieldErrData("impression_pacing_amount");
        }
      } else if ("bid_aggressiveness" in dataObj) {
        if (dataObj.bid_aggressiveness === "") {
          errObj = buildRequiredFieldErrData("bid_aggressiveness");
        }
      }
      if (errObj) {
        opts.statusInvalid(errObj);
      } else {
        delete dataObj.impressioncap_type;
        opts.success = successHandler;
        baseSaveMethod.call(self, dataObj, opts);
      }
    }
  });
  return T1.Models.Strategy;
});
