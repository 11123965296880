define('utils/VideoUtils',["jQuery", "T1", "T1Model", "T1Comm", "When", "utils/providers/VendorProvider"], function ($, T1, T1Model, T1Comm, When, VendorProvider) {
  "use strict";

  var VideoUtils = function vUtils() {
    var sessionModel = new (T1Model.extend({
      url: "session/clone"
    }))();
    sessionModel.newSession = function () {
      sessionModel.save({
        api_key: COMPASS_ENV.API_KEY
      }, {
        onSuccess: function (data) {
          sessionModel.set({
            sessionID: data.session.sessionid
          });
        }
      });
    };
    sessionModel.newSession();
    function processAjaxResponse(resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
    function fetchVACSData(ids, onSuccess) {
      return T1Comm.get({
        dataType: "json",
        data: {
          creativeIds: ids.join(",")
        },
        onSuccess: onSuccess,
        processAjaxResponse: processAjaxResponse,
        sourceURL: `${T1.VIDEO_API_BASE}creatives`
      });
    }
    function fetchCustomVASTData(url, onSuccess, onError) {
      return $.ajax({
        type: "GET",
        dataType: "xml",
        url: url,
        success: onSuccess,
        error: onError
      });
    }
    function getVenderProviderList(vendorIDs) {
      var vendorProviders = null;
      var count = vendorIDs && vendorIDs.length;
      var id, index;
      if (count > 0) {
        vendorProviders = [];
        for (index = 0; index < count; index++) {
          id = vendorIDs[index];
          vendorProviders.push(new VendorProvider(id));
        }
      }
      return vendorProviders;
    }
    function processVendorsForModel(model) {
      var is_https = model.get("is_https");
      var details = model.get("details");
      var vendors = details && details.get("vendors");
      var autoVendors = details && details.get("autoVendors");
      var combined = !vendors ? autoVendors : !autoVendors ? vendors : vendors.concat(autoVendors);
      var dataID = model.get("id");
      return processVendorsExtended(is_https, combined, dataID);
    }
    function processVendorsExtended(is_https, vendorIDs, dataID) {
      var deferred = When.defer();
      var vendorProviders = getVenderProviderList(vendorIDs);
      var isSecure = is_https === "1";
      var hasADXError = true;
      var error_vendors = null;
      var adxErrorType = "adx-error";
      var showSecureInWarning = false;
      var hasRequestError = false;
      if (!vendorProviders) {
        deferred.resolve({
          isSecure: isSecure
        });
        return deferred.promise;
      }
      When.settle(vendorProviders).then(function (results) {
        var count = results.length;
        var errorDescription = null;
        var disapprovedNames, disapprovedSSLNames, index, item, name, vendor;
        hasADXError = false;
        for (index = 0; index < count; index++) {
          item = results[index];
          vendor = item.state === "fulfilled" ? item.value : null;
          if (!vendor) {
            hasRequestError = true;
            continue;
          }
          name = vendor.get("name");
          if (!isSecure && vendor.get("adx_video_approved") !== "1") {
            hasADXError = true;
            disapprovedNames = disapprovedNames ? `${name}, ${disapprovedNames}` : name;
          } else if (isSecure && vendor.get("adx_video_ssl_approved") !== "1") {
            showSecureInWarning = true;
            disapprovedSSLNames = disapprovedSSLNames ? `${name}, ${disapprovedSSLNames}` : name;
          } else if ((vendor.get("adx_video_approved") === "1" || vendor.get("adx_video_ssl_approved") !== "1") && vendor.get("detect_domains").trim() === "") {
            hasADXError = true;
            disapprovedNames = disapprovedNames ? `${name}, ${disapprovedNames}` : name;
          }
        }
        if (!hasADXError) {
          hasADXError = showSecureInWarning;
          adxErrorType = "adx-warning";
          error_vendors = disapprovedSSLNames;
        } else {
          error_vendors = disapprovedNames;
        }
        if (hasADXError) {
          errorDescription = {
            dataID: dataID,
            error_vendors: error_vendors,
            adxErrorType: adxErrorType,
            showSecureInWarning: showSecureInWarning,
            hasRequestError: hasRequestError
          };
        }
        deferred.resolve({
          hasADXError: errorDescription,
          isSecure: isSecure
        });
      });
      return deferred.promise;
    }
    function processCreativesVideoMixins(videos, postprocessCallback) {
      var ids = videos.map(vid => vid.get("id"));
      fetchVACSData(ids, function (data) {
        var count = videos.length;
        var promises = [];
        var autoVendors, combined, dataID, index, info, is_https, vendors, vid;
        for (index = 0; index < count; index++) {
          vid = videos[index];
          dataID = vid.get("id");
          is_https = vid.get("is_https");
          info = data.creatives[dataID];
          if (info) {
            vendors = info.details && info.details.vendors;
            autoVendors = info.autoVendors;
          } else {
            vendors = null;
            autoVendors = null;
          }
          combined = !vendors ? autoVendors : !autoVendors ? vendors : vendors.concat(autoVendors);
          promises.push(processVendorsExtended(is_https, combined, dataID));
        }
        When.all(promises).then(function (mixins) {
          blendModelsAndMixins(videos, mixins);
          postprocessCallback();
        });
      });
    }
    function blendModelsAndMixins(models, mixins) {
      var count = models.length;
      var err, index, mix, vid;
      for (index = 0; index < count; index++) {
        vid = models[index];
        mix = mixins[index];
        err = mix.hasADXError;
        if (err) {
          err.showNoPixelsWarning = true;
          err.hideVenderDomain = true;
        }
        vid.set(mix);
      }
    }
    return {
      fetchVACSData: fetchVACSData,
      fetchCustomVASTData: fetchCustomVASTData,
      processVendorsForModel: processVendorsForModel,
      processCreativesVideoMixins: processCreativesVideoMixins,
      blendModelsAndMixins: blendModelsAndMixins
    };
  };
  return VideoUtils();
});
