define('modules/strategy/views/search',["jQuery", "T1", "T1View", "text!modules/strategy/templates/search.html", "collections/strategies", "jQueryUI"], function ($, T1, T1View, template, Strategies) {
  "use strict";

  const SearchView = T1View.extend({
    template: template,
    initialize: function ({
      campaign_id: campaign_id
    }) {
      this.opened = false;
      this.collection = new Strategies();
      this.campaign_id = campaign_id;
      this.defaultList = true;
      this.listOpened = false;
      this.collection.urlFilter.set({
        entity: "campaign",
        id: campaign_id
      });
      this.el.bind("campaign:toggleStrategies", () => {
        this.toggle(undefined, true);
      });
    },
    load: function () {
      var self = this;
      this.render();
      const searchInput = this.$(".search-input");
      const panel = this.$(".panel");
      panel.addClass("inactive");
      this.el.hover(() => {
        if (!this.opened) {
          panel.toggleClass("inactive");
        }
      });
      searchInput.autocomplete({
        minLength: 1,
        appendTo: this.$(".auto-complete-results"),
        source: function (request, response) {
          if ($.trim(request.term) === "#") {
            searchInput.removeClass("ui-autocomplete-loading");
            return;
          }
          self.source(request, function (data) {
            if (!data.length) {
              data = [{
                summary: `No results found for "${T1.Utils.escapeHTML(request.term)}"`
              }];
            }
            searchInput.removeClass("ui-autocomplete-loading");
            response(data);
          });
        },
        select: function (event, ui) {
          var item = ui.item;
          searchInput.val(item.label);
          self.search({
            idList: [item.id]
          });
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }).data("autocomplete")._renderItem = this.renderItem;
      searchInput.data("autocomplete")._renderMenu = this.renderMenu;
    },
    renderMenu: function (ul, items) {
      $.each(items, (index, item) => {
        if (T1.Utils.hasOwnProp(item, "summary")) {
          ul.append(`<li class='ui-autocomplete-summary'>${item.summary}</li>`);
        } else {
          this._renderItem(ul, item);
        }
      });
    },
    renderItem: function (ul, item) {
      return $("<li></li>").data("item.autocomplete", item).append(`<a>${item.label}</a>`).appendTo(ul);
    },
    source: function (request, response) {
      var collection = this.collection;
      const isValidSearchTerm = collection.search.set({
        params: [{
          term: request.term,
          searchField: "name|id"
        }],
        fetchRequired: false
      });
      if (!isValidSearchTerm) {
        response([]);
        return;
      }
      collection.fetch({
        success: function () {
          response($.map(collection.toJSON(), function (item) {
            item.label = item.value = `${item.name} (${item.id})`;
            return item;
          }));
        }
      });
    },
    events: {
      "click .search-button": "toggle",
      "keyup input": "handleKeyUp",
      "click input": "selectValue",
      click: "preventDefault"
    },
    selectValue: function (e) {
      $(e.currentTarget).select();
    },
    toggle: function (e, campaignToggle) {
      const panel = this.$(".panel");
      const field = this.$(".field");
      const icon = this.$(".icon");
      const searchInput = this.$("input");
      const strategySearchInput = this.$(".search-input");
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (this.opened || campaignToggle) {
        searchInput.val("").autocomplete("close");
        strategySearchInput.css("display", "none");
        field.removeClass("field-open");
        icon.removeClass("icon-open");
        if (e === undefined) {
          panel.addClass("inactive");
        }
        this.opened = false;
        if (!campaignToggle) {
          this.resetList();
        } else {
          this.listOpened = !this.listOpened;
          if (this.listOpened && !this.defaultList) {
            this.resetList();
          }
        }
      } else {
        strategySearchInput.css("display", "block");
        field.addClass("field-open");
        icon.addClass("icon-open");
        panel.removeClass("inactive");
        searchInput.focus().select();
        this.opened = true;
      }
    },
    search: function ({
      idList: idList,
      term: term
    }) {
      var data = {};
      var needsUpdate = false;
      if (idList) {
        data.idList = idList;
        needsUpdate = true;
        this.defaultList = false;
      } else {
        if (term !== "") {
          data.params = [{
            term: term,
            searchField: "name|id"
          }];
        }
        needsUpdate = term !== "" || term === "" && !this.defaultList;
        this.defaultList = data.term === "";
      }
      if (needsUpdate) {
        T1.EventHub.publish(`strategies_${this.campaign_id}:search`, data);
      }
    },
    handleKeyUp: function (e) {
      var keyCode = e.which;
      const value = $.trim(this.$("input").val());
      const enterKey = 13;
      const escapeKey = 27;
      if (keyCode === enterKey) {
        this.$(".search-input").autocomplete("close");
        const startParenthesisIdx = value.lastIndexOf("(");
        const endParenthesisIdx = value.lastIndexOf(")");
        const contentInParentheses = parseInt(value.substring(startParenthesisIdx + 1, endParenthesisIdx), 10);
        if (startParenthesisIdx !== -1 && endParenthesisIdx !== -1 && value.length - 1 === endParenthesisIdx) {
          if (!isNaN(contentInParentheses)) {
            return;
          }
        }
        this.search({
          term: value
        });
      } else if (keyCode === escapeKey) {
        this.toggle();
      }
    },
    resetList: function () {
      this.search({
        term: ""
      });
      this.defaultList = true;
    },
    unload: function () {
      const el = this.el;
      el.unbind("campaign:toggleStrategies");
      el.unbind("mouseenter mouseleave");
    }
  });
  return SearchView;
});
