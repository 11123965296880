define('modules/creatives/componentBased/views/createEditBulk',["jQuery", "Underscore", "When", "T1", "T1Comm", "T1View", "T1Layout", "T1FileUpload", "collections/organizations", "../lib/creativeGridItem", "../lib/uploadHandler", "text!../templates/createEditBulk.html", "text!../templates/createEditBulkBody.html", "text!../templates/createEditBulkTableReview.html", "text!../templates/createEditBulkTableSubmit.html", "text!../templates/createEditWarning.html", "T1Form", "T1Notifier", "T1Preferences", "T1Signature"], function ($, _, When, T1, T1Comm, T1View, T1Layout, T1FileUpload, Organizations, CreativeGridItem, UploadHandler, template, templateBody, tableTemplate, submitTableTemplate, templateWarning) {
  "use strict";

  function updateBreadCrumb(isEdit) {
    var label = `Component Bulk ${isEdit ? "Edit" : "Create"}`;
    var pathPieces = [{
      label: label,
      location: "creatives/componentBased-bulk"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  var BulkComponentCreateEdit = T1View.extend({
    template: template,
    partials: {
      warning: templateWarning
    },
    componentsMap: {},
    selectedAdvertiser: undefined,
    headerStepNumber: "1",
    canUnload: function () {
      if (this.checkBeforeLeaving && !this.exiting) {
        var $el = $(this.el);
        $el.find(".exit-component-bulk-modal .hideButton").show();
        $el.find(".exit-component-bulk-modal")[0].show();
      } else {
        return true;
      }
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgId;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    initialize: function () {
      this.isEdit = false;
      this.componentsFetched = false;
      this.creativesToEdit = [];
      this.noCheckUnsavedState = true;
      this.exiting = false;
      var viewOptions = T1.Location.getViewOptions();
      this.creativesToEdit = viewOptions && viewOptions.selectedModels;
      var selectedIds = _.map(this.creativesToEdit, function (s) {
        return s.get("atomic_creative_id");
      });
      if (selectedIds.length) {
        this.isEdit = true;
        this.downloadBulkEditTemplate(selectedIds);
      }
      this.fetchComponents();
      this.actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel component-bulk-cancel",
          location: "#creatives/componentBased"
        }, {
          label: "Next",
          class: "saveAndContinue component-bulk-submit btn-primary ",
          location: false
        }]
      };
      this.initLayout();
    },
    fetchComponents: function () {
      var self = this;
      var url = `${COMPASS_ENV.COMPONENT_API_BASE}components`;
      T1Comm.get({
        dataType: "json",
        sourceURL: url,
        onSuccess: function (result) {
          var components = result.data;
          components.forEach(function (component) {
            self.componentsMap[component.component_id] = component.value_type_name;
          });
          self.componentsFetched = true;
        },
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: "ok"
          };
        }
      });
    },
    renderUploadWarnings: function () {
      var self = this;
      for (var i = 0; i < self.uploadWarnings.length; i++) {
        self.renderPartialTemplate({
          template: templateWarning,
          context: self.el,
          targetEl: `.create-edit-warning-${i + 1}`,
          useTemplateHtml: false,
          data: self.uploadWarnings[i]
        });
      }
      self.uploadWarnings = undefined;
    },
    renderNextStepInHeader: function (step) {
      this.headerStepNumber = step;
      this.renderPartial(".w-head");
    },
    removeTooltips: function () {
      var stepOneTooltip = document.querySelector("#step-one-tooltip");
      var stepTwoTooltipTemplateUpload = document.querySelector("#step-two-tooltip-template");
      var stepTwoTooltipImageUpload = document.querySelector("#step-two-tooltip-images");
      var stepThreeTooltip = document.querySelector("#step-three-tooltip");
      var tooltips = [stepOneTooltip, stepTwoTooltipTemplateUpload, stepTwoTooltipImageUpload, stepThreeTooltip];
      _.each(tooltips, function (t) {
        if (t && t.parentNode) {
          t.parentNode.removeChild(t);
        }
      });
    },
    applyUploadSpinner: function () {
      this.uploadSpinner = document.createElement("strand-spinner");
      this.uploadSpinner.setAttribute("radius", "9");
      this.uploadSpinner.setAttribute("id", "uploadSpinner");
      this.uploadSpinner.setAttribute("fill-color", "#333333");
    },
    removeBtnSpinner: function () {
      const $uploadBtn = this.el.find("#component-upload-bulk-create-template");
      const $uploadBtnLabel = $uploadBtn.find(".strand-button");
      $uploadBtn.removeAttr("disabled");
      $uploadBtnLabel[0].innerHTML = "Upload";
    },
    handleUploads: function () {
      const self = this;
      const $mediaForm = this.el.find(".media-asset-upload-form");
      const $templateForm = this.el.find(".template-asset-upload-form");
      const $uploadBtn = this.el.find("#component-upload-bulk-create-template");
      let $uploadBtnLabel, buttonWidth;
      self.checkBeforeLeaving = true;
      if (self.tableLayout) {
        self.tableLayout.destroy();
      }
      var file = $templateForm.children("input")[0].files[0];
      var mediaFile = $mediaForm.children("input")[0].files[0];
      if (mediaFile) {
        $uploadBtnLabel = $uploadBtn.find(".strand-button");
        buttonWidth = $uploadBtn.css("width");
        self.applyUploadSpinner();
        self.uploadSpinner.setAttribute("style", "margin-left: 13px;");
        $uploadBtn.css("width", buttonWidth);
        $uploadBtn.attr("disabled", true);
        $uploadBtnLabel[0].innerHTML = "";
        $uploadBtnLabel[0].appendChild(self.uploadSpinner);
      }
      function addUploadWarning(msg, opts) {
        var cssClasses = ["component-bulk-upload-warning"];
        if (opts && opts.cssClasses) {
          cssClasses = _.union(cssClasses, opts.cssClasses);
        }
        self.uploadWarnings = self.uploadWarnings || [];
        self.uploadWarnings.push({
          show: true,
          showError: true,
          warningMessage: msg,
          cssClasses: cssClasses.join(" ")
        });
      }
      return UploadHandler.handleUploads({
        templateFile: file,
        mediaFile: mediaFile,
        isEdit: self.isEdit,
        selectedAdvertiser: self.selectedAdvertiser,
        creativesToEdit: self.creativesToEdit,
        componentsMap: self.componentsMap
      }).then(function uploadSuccessCase(result) {
        var warningMessages = result.warningMessages;
        self.removeBtnSpinner($uploadBtn);
        if (!warningMessages) {
          var creativesData = result;
          self.el.find(".component-bulk-upload-warning").hide();
          self.renderNextStepInHeader("2");
          self.renderReviewTable(creativesData);
        } else {
          if (warningMessages.templateUpload) {
            addUploadWarning(warningMessages.templateUpload);
          }
          if (warningMessages.mediaUpload) {
            addUploadWarning(warningMessages.mediaUpload, {
              cssClasses: ["component-bulk-media-upload-warning"]
            });
          }
          self.renderUploadWarnings();
        }
      }, function uploadErrorCase(err) {
        var opts = {};
        self.removeBtnSpinner($uploadBtn);
        if (err.message.match("media")) {
          opts.cssClasses = ["component-bulk-media-upload-warning"];
        }
      });
    },
    renderReviewTable: function (creativesData) {
      var self = this;
      var $doneBtn = $(".component-bulk-done");
      if ($doneBtn) {
        $doneBtn.hide();
      }
      var uploadableCreatives = [];
      var nonUploadableCreatives = [];
      var allCreatives = creativesData.creatives;
      allCreatives = _.map(allCreatives, function (c) {
        return CreativeGridItem.createCreativeGridItem(c);
      });
      _.each(allCreatives, function (c) {
        if (c.isUploadable()) {
          uploadableCreatives.push(c);
        } else {
          nonUploadableCreatives.push(c);
        }
      });
      _.each(uploadableCreatives, function (c) {
        c.gridData = {
          name: c.attributes.creative_name,
          row: c.row,
          htmlIdentifier: `${c.type}-${c.row}`,
          id: c.attributes.id || "Not yet created",
          concept: c.attributes.concept || "--",
          iconType: "clock",
          iconColor: "#989898",
          showRetry: "none",
          invalidConcept: "none"
        };
      });
      _.each(nonUploadableCreatives, function (c) {
        var reasonData = c.getRejectionReasonAndTooltip();
        var reasonLine1 = "";
        var reasonLine2 = "";
        var placeholderName;
        if (reasonData.reason.length > 77) {
          var onLine2 = false;
          var reasonWords = reasonData.reason.split(" ");
          for (var i = 0; i < reasonWords.length; i++) {
            if (!onLine2 && (reasonLine1 + reasonWords[i]).length < 78) {
              reasonLine1 = `${reasonLine1 + reasonWords[i]} `;
            } else {
              onLine2 = true;
              reasonLine2 = `${reasonLine2 + reasonWords[i]} `;
            }
          }
        } else {
          reasonLine1 = reasonData.reason;
        }
        if (c.attributes.creative_name) {
          var isJustWhitespace = c.attributes.creative_name.replace(/\s/g, "") === "";
          placeholderName = isJustWhitespace ? "--" : c.attributes.creative_name;
        } else {
          placeholderName = "--";
        }
        c.gridData = {
          name: placeholderName,
          row: c.row,
          htmlIdentifier: `${c.type}-${c.row}`,
          id: c.attributes.id || "Not yet created",
          reasonLine1: reasonLine1,
          reasonLine2: reasonLine2,
          reasonTooltip: reasonData.tooltip
        };
      });
      self.uploadableCreatives = uploadableCreatives;
      self.nonUploadableCreatives = nonUploadableCreatives;
      var nonUploadableData = _.map(nonUploadableCreatives, function (c) {
        return c.gridData;
      });
      var uploadableData = _.map(uploadableCreatives, function (c) {
        return c.gridData;
      });
      if (self.uploadableCreatives.length > 0) {
        var $nextBtn = $(".component-bulk-submit");
        $nextBtn.show();
      }
      self.checkBeforeLeaving = true;
      self.tableLayout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".table-review",
        template: tableTemplate,
        layout: {
          ".table-review": [{
            module: "creatives/componentBased",
            viewType: "createEditBulkTableReview",
            options: {
              uploadableData: uploadableData,
              nonUploadableData: nonUploadableData
            }
          }]
        }
      });
      self.tableLayout.load();
    },
    events: {
      "click #component-upload-bulk-create-template": "handleUploads",
      "click .component-bulk-cancel": "pauseUploads",
      "click #component-download-bulk-create-template": "downloadBulkCreateTemplate",
      "click #cancelExit": "cancelExit",
      "click #confirmExit": "confirmExit",
      "click #accept-tooltips": "removeTooltips"
    },
    cancelExit: function (e) {
      e.preventDefault();
      var $el = $(this.el);
      this.exiting = false;
      this.cancelUnload();
      $el.find(".exit-component-bulk-modal")[0].hide();
      T1.EventHub.publish("component:restartPausedBulkUpload");
    },
    confirmExit: function (e) {
      var self = this;
      self.redirectUrl = "";
      T1.EventHub.publish("component:cancelBulkUpload");
      e.preventDefault();
      self.redirectUrl = T1.Location.getAbortedURL();
      this.exiting = true;
      function showSpinner() {
        var confirmButtonLabel = document.querySelector("#confirmExitLabel");
        var $confirmButton = $("#confirmExit");
        var buttonWidth = $confirmButton.css("width");
        $confirmButton.attr("disabled", true);
        confirmButtonLabel.innerHTML = "";
        var spinner = document.createElement("strand-spinner");
        spinner.setAttribute("radius", "9");
        spinner.setAttribute("id", "componentBulkConfirmExitSpinner");
        spinner.setAttribute("fillColor", "#333333");
        confirmButtonLabel.appendChild(spinner);
        $confirmButton.css("width", buttonWidth);
      }
      if (!self.bulkUploadStarted || self.bulkUploadCompleted) {
        self.navigateAway(self.redirectUrl);
        return;
      }
      if (self.bulkUploadCancelled) {
        self.redirectUrl = "#creatives/componentBased";
        if (self.creativeCurrentlyUploading) {
          showSpinner();
        } else {
          self.navigateAway(self.redirectUrl);
        }
        return;
      }
      showSpinner();
    },
    navigateAway: function (toUrl) {
      if (this.showGrowlerOnExit) {
        this.showUploadCountGrowler();
      } else {
        window.localStorage.removeItem("componentBulkSuccessfulUploads");
      }
      if (!toUrl) {
        setTimeout(function () {
          T1.Location.set("#creatives/componentBased");
        }, 1);
      } else {
        T1.Location.set(toUrl);
      }
    },
    downloadBulkCreateTemplate: function () {
      var downloadUrl = `${COMPASS_ENV.COMPONENT_API_BASE}downloads/bulk_create?advertiser_id=${this.selectedAdvertiser.id}`;
      window.location.href = downloadUrl;
    },
    downloadBulkEditTemplate: function (creativeIds) {
      var downloadUrl = `${COMPASS_ENV.COMPONENT_API_BASE}downloads/bulk_edit?creative_ids=${creativeIds.join(",")}`;
      window.location.href = downloadUrl;
    },
    pauseUploads: function () {
      T1.EventHub.publish("component:pauseBulkUpload");
      this.bulkUploadCancelled = true;
    },
    eventhubEvents: {
      "organization:explicitChange": function () {
        T1.Location.set("#creatives/componentBased");
      },
      "dropdown:advertisers": "handleAdvertiserSelect",
      "component:bulkCreateUploadStarted": "uploadStarted",
      "component:bulkCreateUploadCompleted": "uploadComplete",
      "component:bulkUploadCreativeUploaded": "noteThatCreativeFinished",
      "component:bulkUploadCreativeUploadStarting": "noteThatCreativeStarted"
    },
    noteThatCreativeStarted: function () {
      this.bulkUploadCancelled = false;
      this.checkBeforeLeaving = true;
      this.showCancelButton();
      this.creativeCurrentlyUploading = true;
    },
    noteThatCreativeFinished: function () {
      this.creativeCurrentlyUploading = false;
      this.navigateAwayIfWaiting();
    },
    navigateAwayIfWaiting: function () {
      if (this.redirectUrl) {
        this.navigateAway(this.redirectUrl);
      }
    },
    uploadStarted: function () {
      this.bulkUploadStarted = true;
      this.showGrowlerOnExit = true;
      var $doneBtn = $(".component-bulk-done");
      $doneBtn.addClass("disabled");
      $doneBtn.attr("disabled", "disabled");
      this.disableUploadButtons();
      var $uploadBtn = $("#component-upload-bulk-create-template");
      $uploadBtn.attr("disabled", "disabled");
    },
    uploadComplete: function () {
      this.bulkUploadCompleted = true;
      this.checkBeforeLeaving = false;
      this.enableUploadButtons();
      this.enableDoneButton();
      this.hideCancelButton();
    },
    showCancelButton: function () {
      var cancelButton = $(".component-bulk-cancel");
      cancelButton.parent().css("display", "block");
    },
    hideCancelButton: function () {
      var cancelButton = $(".component-bulk-cancel");
      cancelButton.parent().css("display", "none");
    },
    enableDoneButton: function () {
      var $doneBtn = $(".component-bulk-done");
      $doneBtn.removeClass("disabled");
      $doneBtn.removeAttr("disabled");
    },
    disableUploadButtons: function () {
      $(".file-upload-btn").addClass("disabled");
    },
    enableUploadButtons: function () {
      $(".file-upload-btn").removeClass("disabled");
    },
    handleAdvertiserSelect: function (data) {
      this.selectedAdvertiser = data.model;
      this.isFormElementsChanged = true;
      $("#component-download-bulk-create-template").removeAttr("disabled");
      this.enableUploadButtons();
    },
    fileUploaderData: {
      template: {
        inputFields: [],
        disabled: true,
        formSelector: "template-asset-upload-form",
        placeholder: "Select a file (must be the provided .xlsx file)",
        accept: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      media: {
        inputFields: [],
        disabled: true,
        formSelector: "media-asset-upload-form",
        placeholder: "Select a file (must be .zip and no larger 4GB)",
        accept: "application/zip"
      }
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      self.checkBeforeLeaving = false;
      self.bulkUploadStarted = false;
      self.bulkUploadCompleted = false;
      self.bulkUploadCancelled = false;
      self.showGrowlerOnExit = false;
      self.render().then(function () {
        $(".component-bulk-submit").css("display", "none");
        function initializeUploaders() {
          self.layout.load();
          if (!self.fileUploader) {
            var $templateEl = $el.find(".template-asset-upload");
            var $mediaEl = $el.find(".media-asset-upload");
            self.templateFileUploader = T1FileUpload.create({
              $el: $templateEl,
              onChange: $.proxy(self.onFileChanged, self),
              buttonText: "Choose File"
            });
            self.mediaFileUploader = T1FileUpload.create({
              $el: $mediaEl,
              onChange: $.proxy(self.onFileChanged, self),
              buttonText: "Choose File"
            });
          }
          if (self.templateFileUploader) {
            self.templateFileUploader.render(self.fileUploaderData.template);
            self.isFormElementsChanged = true;
          }
          if (self.mediaFileUploader) {
            self.mediaFileUploader.render(self.fileUploaderData.media);
            self.isFormElementsChanged = true;
          }
        }
        if (!self.isEdit) {
          initializeUploaders.call(self);
        } else {
          initializeUploaders.call(self);
          self.enableUploadButtons();
        }
      });
    },
    clearFileValidation: function () {
      var $input = $(".asset-upload .file-name .text-input");
      var $warning = $("[class^='create-edit-warning']");
      if ($input.hasClass("error")) {
        $input.removeClass("error");
      }
      $warning.empty();
    },
    onFileChanged: function () {
      this.clearFileValidation();
      var $templateForm = $(".template-asset-upload-form");
      var templateFile = $templateForm.children("input")[0].files[0];
      var $uploadBtn = $("#component-upload-bulk-create-template");
      if (templateFile && this.componentsFetched) {
        $uploadBtn.removeAttr("disabled");
      } else {
        $uploadBtn.attr("disabled", "true");
      }
    },
    postData: function () {
      var self = this;
      var $btn = $(".component-bulk-submit");
      this.stopSpinner($btn);
      T1.EventHub.publish("component:bulkCreateUploadStarted");
      self.renderNextStepInHeader("3");
      var $btnContainer = $btn.parent();
      $btn.hide();
      var $doneBtn = $("<div></div>", {
        class: "component-bulk-done btn-primary disabled",
        disabled: "disabled",
        text: "Done"
      }).on("click", function (e) {
        e.preventDefault();
        self.showUploadCountGrowler();
        T1.Location.set("creatives/componentBased");
      });
      $doneBtn.appendTo($btnContainer);
      self.tableLayout.destroy();
      var nonUploadableTable = $("#componentNonUploadableReview");
      nonUploadableTable.hide();
      self.checkBeforeLeaving = true;
      self.submitTableLayout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".table-review",
        template: submitTableTemplate,
        layout: {
          ".table-review": [{
            module: "creatives/componentBased",
            viewType: "createEditBulkTableSubmit",
            options: {
              uploadableCreatives: self.uploadableCreatives
            }
          }]
        }
      });
      self.submitTableLayout.load();
    },
    showUploadCountGrowler: function () {
      var successfulCreatives = window.localStorage.getItem("componentBulkSuccessfulUploads");
      var message;
      if (successfulCreatives > 0) {
        message = `${successfulCreatives} Creative(s) `;
        message = message + (this.isEdit ? "updated" : "created");
        message = `${message} successfully!`;
        T1.Notify("message", message);
      }
      window.localStorage.removeItem("componentBulkSuccessfulUploads");
    },
    initLayout: function () {
      var self = this;
      updateBreadCrumb(self.isEdit);
      var organization = Organizations.getOrganizations().getSelected();
      organization.then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".w-body",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: {
            ".advertiser-select": [{
              module: "shared",
              viewType: "advertiserSelect",
              options: {
                entity: "advertisers",
                dataBind: "advertiser_id",
                boundEntity: "organizations",
                boundEntityId: orgId,
                isSearchable: true,
                initialLoad: true,
                placeholder: "Select an Advertiser",
                preSelect: "Select an Advertiser",
                notFound: "No Advertisers Found!",
                refreshEvent: "refresh",
                preRender: function () {},
                disableCheck: function () {
                  if (self.isEdit) {
                    return true;
                  }
                },
                defaultSortParams: {
                  fields: {
                    name: {
                      actual: "name",
                      display: "alphabetically"
                    }
                  },
                  default: {
                    sort_by: "name",
                    order_by: "ascending"
                  }
                }
              }
            }]
          }
        });
      }, this));
    },
    serialize: function () {
      var output = {};
      output.isEdit = this.isEdit;
      output.headerStepNumber = this.headerStepNumber;
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(BulkComponentCreateEdit);
});
