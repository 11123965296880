define('models/vendorDomain',["T1", "T1Model", "Backbone", "models/vendor"], function (T1, T1Model, Backbone, Vendor) {
  T1.Models.VendorDomain = T1Model.extend({
    url: "vendor/",
    relations: [{
      type: Backbone.HasOne,
      key: "vendor",
      relatedModel: "Vendor",
      includeInJSON: true
    }]
  });
  return T1.Models.VendorDomain;
});
