// Sticky v1.0 by Daniel Raftery
// http://thrivingkings.com/sticky
//
// http://twitter.com/ThrivingKings

/*
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

			THIS FILE HAS BEEN MODIFIED FOR T1 PURPOSES, 
				CONSIDER THIS WHEN UPDATING STICKY

+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
define('jQPlugins/sticky.full',['jQuery', 'Underscore', 'Backbone'], function(jQuery, _, Backbone) {
	(function($) {
		// Using it without an object
		$.sticky = function (note, options, callback) {
			return $.fn.sticky(note, options, callback);
		};

		$.fn.sticky = function (note, options, callback) {
			// Default settings
			var position = 'sticky-top-right',// sticky-top-left, sticky-top-right, sticky-bottom-left, or sticky-bottom-right
				settings = {
					'speed' : 300, // animations: fast (200), slow (600), or integer
					'duplicates' : true, // true or false
					'autoclose' : 5000,  // integer or false
					'className' : ""  // integer or false
				},
				display = true,
				duplicate = 'no',
				uniqID = Math.floor(Math.random() * 99999),// Somewhat of a unique ID
				$stickyItem,
				$stickyContainer;

			// Passing in the object instead of specifying a note
			if (!note) {
				note = this.html();
			}

			if (options) {
				$.extend(settings, options);
			}

			// Handling duplicate notes and IDs
			$('.sticky-queue .sticky-notification-msg').each(function() {
				if ($(this).html() === note && $(this).is(':visible')) {
					duplicate = 'yes';

					if (!settings.duplicates) {
						display = false;
					}
				}

				if ($(this).attr('id') === uniqID) {
					uniqID = Math.floor(Math.random() * 9999999);
				}
			});

			// Make sure the sticky queue exists
			if (!$('body').find('.sticky-queue').html()) {
				$('body').append('<div class="sticky-queue ' + position + '"></div>');
			}

			// Can it be displayed?
			if (display) {
				// Building and inserting sticky note

				/*
				+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
								This section has been heavily changed to
									accommodate more flexible styling
				+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
				*/
				$('.sticky-queue').prepend('<div id="' + uniqID + '" class="sticky-item"></div>');
				$stickyItem = $('#' + uniqID);

				$stickyItem.append('<div class="sticky-container sticky-' + settings.className + '"></div>');
				$stickyContainer = $stickyItem.find(".sticky-container");

				switch (settings.className) {
					case "notify-message":
						$stickyContainer.append('<div class="sticky-check-icon"></div>');
						break;

					case "notify-warning":
						$stickyContainer.append('<div class="sticky-warning-icon"></div>');
						break;

					case "notify-error":
						$stickyContainer.append('<div class="sticky-error-icon"></div>');
						break;
				}

				$stickyContainer.append('<div class="sticky-notification-msg" rel="' + uniqID + '">' + note + '</div>');
				$stickyContainer.append('<div class="sticky-close-icon" rel="' + uniqID + '" title="Close"></div>');

				$stickyItem.css({
					"height": $stickyContainer.css("height"),
					"margin-top": -($stickyContainer.height()) + "px"
				});

				$stickyItem.animate({ "margin-top": "0px" }, settings.speed);

				// Listeners
				$stickyContainer.ready(function() {
					// If 'autoclose' is enabled, set a timer to close the sticky
					if (settings.autoclose) {
						$stickyItem.delay(settings.autoclose).fadeOut(settings.speed, function () {
							this.remove();
						});
					}
				});

				// Closing a sticky
				$('.sticky-queue .sticky-close-icon').click(function() {
					$('#' + $(this).attr('rel')).dequeue().fadeOut(settings.speed, function () {
						this.remove();
					});
				});
			}

			// Callback data
			var response = {
				'id' : uniqID,
				'duplicate' : duplicate,
				'displayed' : display,
				'position' : position
			};

			// Callback function?
			if (callback) {
				callback(response);
			} else {
				return (response);
			}
		};
	})(jQuery);
});
