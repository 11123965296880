define('modules/media/pmp-d/views/pmp-d',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Permissions", "models/sessionUser", "models/userPreferences", "text!../templates/pmp-d.html", "T1Signature"], function ($, _, T1, T1View, T1Layout, Permissions, User, UserPreferences, template) {
  "use strict";

  var pmpd = T1View.extend({
    template: template,
    load: function () {
      this.render();
    }
  });
  return pmpd;
});
