define('modules/campaign/chart/winLoss/views/strategyDailyChart',['require','Underscore','jQuery','modules/shared/configs/charts/lineChartTwoAxisSeriesConfig','../models/mStrategyDailyChart','text!../templates/controlsStrategyDaily.html','T1','T1Layout','T1View','models/userPreferences'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const ChartConfig = require("modules/shared/configs/charts/lineChartTwoAxisSeriesConfig");
  const ChartModel = require("../models/mStrategyDailyChart");
  const controlsTemplate = require("text!../templates/controlsStrategyDaily.html");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const UserPreferences = require("models/userPreferences");
  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    dataEvents: {
      ReportModel: {
        "change:ddlMetricOptions": "updateMetricsMenu"
      }
    },
    eventhubEvents: {
      "change:export": "exportChartHandler",
      "change:metric": function (metricObj) {
        this[`winLossStrategyDailyMetric${metricObj.id}`] = metricObj.value;
        this.preferences.save();
        this.ReportModel.setSelectedMetrics("strategy", "daily", metricObj);
        this.updateChart();
      },
      "change:interval": function ({
        value: value
      }) {
        this.winLossStrategyDailyInterval = value;
        this.preferences.save();
        this.ReportModel.setInterval(value);
      },
      "toggle:legend": function (value) {
        this.winLossStrategyDailyTxt = value;
        this.preferences.save();
        this.ChartModel.setLegend(value);
        $(this.lineChart.legendDiv).toggle();
        this.lineChart.invalidateSize();
      },
      "complete:layoutLoad": function () {
        this.layoutsLoaded();
      }
    },
    intervalOptions: [{
      value: "by_day",
      label: "by Day"
    }, {
      value: "by_hour",
      label: "by Hour"
    }],
    winLossStrategyDailyMetric0: "win_rate",
    winLossStrategyDailyMetric1: "average_bid_amount_cpm",
    winLossStrategyDailyInterval: "by_day",
    winLossStrategyDailyLegendTxt: "Hide",
    initialize({
      ReportModel: ReportModel,
      ParentClass: ParentClass
    }) {
      this.ReportModel = ReportModel;
      this.ParentClass = ParentClass;
      this.ChartModel = new ChartModel({}, this.ReportModel);
      this.buildPermissionedEvents();
      this.bindViewEvents();
      this.initPreferences();
      this.initLayout();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "monitor"], ["view", "campaign.chart"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["winLossStrategyDailyMetric0", "winLossStrategyDailyMetric1", "winLossStrategyDailyInterval", "winLossStrategyDailyLegendTxt"]);
      this.ReportModel.setInterval(this.winLossStrategyDailyInterval, true);
      this.ChartModel.setLegend(this.winLossStrategyDailyLegendTxt);
    },
    initLayout() {
      const selectedInterval = this.winLossStrategyDailyInterval;
      const options = {
        getReportModel: () => this.ReportModel
      };
      function getSelectedIntervalName() {
        return this.intervalOptions.find(({
          value: value
        }) => selectedInterval === value).label;
      }
      this.layout = new T1Layout({
        el: () => this.ParentClass.$headerEl,
        template: controlsTemplate,
        layout: {
          "#metricsMenu1": [{
            module: "campaign/chart",
            viewType: "metricsMenu",
            options: {
              id: 0,
              maxItems: 7,
              overflow: "visible",
              overflowWidth: 165
            }
          }],
          "#metricsMenu2": [{
            module: "campaign/chart",
            viewType: "metricsMenu",
            options: {
              id: 1,
              maxItems: 7,
              overflow: "visible",
              overflowWidth: 165
            }
          }],
          ".date-picker": [{
            module: "campaign/chart",
            options: options,
            viewType: "datePicker"
          }],
          ".interval-menu": [{
            module: "campaign/chart",
            viewType: "intervalMenu",
            options: {
              default: getSelectedIntervalName.call(this),
              id: 0,
              intervals: this.intervalOptions,
              selected: selectedInterval
            }
          }],
          ".export-menu": [{
            module: "campaign/chart",
            options: options,
            viewType: "exportMenu"
          }],
          ".info": [{
            module: "reporting/campaigns",
            options: options,
            viewType: "report_info"
          }],
          ".legend-toggle": [{
            module: "campaign/chart",
            options: {
              getChartModel: () => this.ChartModel
            },
            viewType: "legendToggle"
          }]
        }
      });
      this.layoutsLoaded = _.after(_.size(this.layout.layout), () => {
        this.ParentClass.layoutLoaded();
      });
      this.layout.load();
    },
    updateMetricsMenu(model, metricOpts) {
      let {
        winLossStrategyDailyMetric0: metric0,
        winLossStrategyDailyMetric1: metric1
      } = this;
      const ReportModel = this.ReportModel;
      const metric0exists = metricOpts.find(({
        value: value
      }) => value === metric0);
      const metric1exists = metricOpts.find(({
        value: value
      }) => value === metric1);
      if (!metric0exists) {
        this.winLossStrategyDailyMetric0 = metric0 = "win_rate";
        this.preferences.save();
      }
      if (!metric1exists) {
        this.winLossStrategyDailyMetric1 = metric1 = "average_bid_amount_cpm";
        this.preferences.save();
      }
      ReportModel.setSelectedMetrics("strategy", "daily", {
        id: 0,
        value: metric0
      });
      ReportModel.setSelectedMetrics("strategy", "daily", {
        id: 1,
        value: metric1
      });
      T1Publish("change:metrics", {
        default: ReportModel.getSelectedMetricLabel(metric0),
        id: 0,
        metrics: metricOpts,
        selected: metric0
      });
      T1Publish("change:metrics", {
        default: ReportModel.getSelectedMetricLabel(metric1),
        id: 1,
        metrics: metricOpts,
        selected: metric1
      });
    },
    updateMetricType() {
      const selectedMetrics = this.ReportModel.getSelectedMetrics();
      const [selectedMetric0, selectedMetric1] = selectedMetrics.strategy.daily;
      const metricOptions = this.ReportModel.reportMeta.get("metricOptions");
      const metricObj0 = metricOptions.find(item => item.value === selectedMetric0.value);
      const metricObj1 = metricOptions.find(item => item.value === selectedMetric1.value);
      metricObj0.label = selectedMetric0.label;
      metricObj1.label = selectedMetric1.label;
      this.metricObj0 = metricObj0;
      this.metricObj1 = metricObj1;
    },
    formatValue(value, index) {
      return this.ReportModel.formatValue(value, index === 0 ? this.metricObj0.type : this.metricObj1.type);
    },
    setupChart() {
      const ParentClass = this.ParentClass;
      const lineData = this.ChartModel.getChartData();
      const self = this;
      if (!lineData.dataProvider.length) {
        ParentClass.showNoDataGrid();
        return;
      }
      if (this.lineChart) {
        this.lineChart.clear();
        this.lineChart = null;
      }
      this.updateMetricType();
      const lineConfig = ChartConfig.copy();
      lineConfig.dataProvider = lineData.dataProvider;
      lineConfig.graphs = lineData.graphs;
      lineConfig.valueAxes[0].labelFunction = value => this.formatValue(value, 0);
      lineConfig.valueAxes[0].title = this.metricObj0.label;
      lineConfig.valueAxes[1].labelFunction = value => this.formatValue(value, 1);
      lineConfig.valueAxes[1].title = this.metricObj1.label;
      lineConfig.categoryAxis.minPeriod = this.ReportModel.getMinPeriod();
      lineConfig.legend.valueFunction = function (GraphDataItem) {
        return GraphDataItem.values ? self.formatValue(GraphDataItem.values.value, GraphDataItem.graph.index) : "";
      };
      ParentClass.hideNoDataGrid();
      this.lineChart = AmCharts.makeChart("chartCanvas", lineConfig);
      if (this.ChartModel.getLegend() === "Show") {
        $(this.lineChart.legendDiv).hide();
        this.lineChart.invalidateSize();
      }
    },
    updateChart() {
      const lineChart = this.lineChart;
      if (!lineChart) {
        this.setupChart();
        return;
      }
      const ParentClass = this.ParentClass;
      const lineData = this.ChartModel.getChartData();
      if (!lineData.dataProvider.length) {
        ParentClass.showNoDataGrid();
        return;
      }
      this.updateMetricType();
      ParentClass.hideNoDataGrid();
      lineChart.dataProvider = lineData.dataProvider;
      lineChart.graphs = lineData.graphs;
      lineChart.valueAxes[0].title = this.metricObj0.label;
      lineChart.valueAxes[1].title = this.metricObj1.label;
      lineChart.categoryAxis.minPeriod = this.ReportModel.getMinPeriod();
      lineChart.validateData();
    },
    exportChartHandler(type) {
      switch (type) {
        case "png":
          this.lineChart.export.capture({}, function () {
            this.toPNG({}, function (data) {
              this.download(data, this.defaults.formats.PNG.mimeType, "StrategyDailyChart.png");
            });
          });
          break;
        case "print":
          this.lineChart.export.capture({}, function () {
            this.toPRINT();
          });
          break;
        case "pdf":
          break;
      }
    },
    unloadChart() {
      if (this.lineChart) {
        this.lineChart.clear();
        this.lineChart = null;
      }
      if (this.layout) {
        this.layout.destroy();
        this.layout = null;
      }
      this.unbind();
      this.ChartModel = null;
      this.metricObj0 = null;
      this.metricObj1 = null;
      this.ParentClass = null;
      this.ReportModel = null;
    }
  });
});
