define('T1Menu',["jQuery", "Underscore", "T1", "T1View", "text!templates/menu.html", "T1PopoverPositionable"], function ($, _, T1, T1View, template, extendPositionable) {
  "use strict";

  function validateMenuCreateParameters(configs, view) {
    var result = {
      success: false,
      errMsg: ""
    };
    var generateErrResult = function (msg) {
      result.errMsg = msg;
      return result;
    };
    var menuItems;
    var hasVisibleMenu = false;
    var i;
    var j;
    var len;
    var len2;
    if (!configs) {
      return generateErrResult("menu config parameter cannot be null or undefined");
    }
    if (!configs.menuTriggerEleSelector) {
      return generateErrResult("configs.menuTriggerEleSelector must contain a valid class name for the UI that " + "triggers the menu to be displayed.");
    }
    if (!configs.menuColumns || !$.isArray(configs.menuColumns) || configs.menuColumns.length <= 0) {
      return generateErrResult("configs.menuColumns must be an array type and containing 1 or more items.");
    }
    len = configs.menuColumns.length;
    for (i = 0; i < len; i++) {
      menuItems = configs.menuColumns[i].menuItems;
      len2 = menuItems.length;
      for (j = 0; j < len2; j++) {
        if ($.isFunction(menuItems[j].handler) === false && !view) {
          return generateErrResult("If you set any handler as string, you are required to passed in the view object " + "as 2nd parameter when creating the menu.");
        }
        if (menuItems[j].visible === true) {
          hasVisibleMenu = true;
        }
      }
    }
    if (hasVisibleMenu === false) {
      return generateErrResult("you must declare menu items and ensure that at least one of them is visible.");
    }
    result.success = true;
    return result;
  }
  function makeItemKey(columnIndex, itemIndex) {
    return "col-" + columnIndex + "-idx-" + itemIndex;
  }
  var instance;
  var CommonMenu;
  CommonMenu = T1View.extend({
    template: template,
    load: function () {
      this.el = this.getPopoverContainer("w-MenuContainer");
      this.render();
    },
    attachEvents: function (viewHolder, configs, originEle) {
      var self = this;
      var view = viewHolder.view;
      var $el = $(self.el);
      var keyHandlerPairs = [];
      var i;
      var j;
      var items;
      var len = configs.menuColumns.length;
      var len2;
      for (i = 0; i < len; i++) {
        items = configs.menuColumns[i].menuItems;
        len2 = items.length;
        for (j = 0; j < len2; j++) {
          keyHandlerPairs.push({
            key: makeItemKey(i, j),
            handler: items[j].handler
          });
        }
      }
      $.each(keyHandlerPairs, function (index, pair) {
        var $item = $el.find(".menu-item." + pair.key);
        if (pair.handler) {
          if ($.isFunction(pair.handler) === true) {
            $item.on("click", {
              buttonEl: originEle
            }, function (event) {
              pair.handler(event);
              self.detachEvents();
            });
          } else {
            $item.on("click", {
              buttonEl: originEle
            }, function (event) {
              if (view) {
                view[pair.handler](event);
                self.detachEvents();
              }
            });
          }
        }
      });
    },
    detachEvents: function () {
      var $menuEls = $(this.el).find(".menu-item");
      if ($menuEls.length > 0) {
        $menuEls.off("click");
      }
    },
    activateDismissalLogic: function () {
      var self = this;
      var $el = $(self.el);
      var $doc = $(document);
      var $window = $(window);
      function removeMenuHandler(event) {
        var isWithinMenuBoundary = $(event.target).parents().index($el) > -1;
        var isMouseUpEvent = event.type === "mouseup";
        var isRightButton = event.which === 3;
        var isRightClickWithinMenuBoundary = isRightButton === true && isMouseUpEvent === true && isWithinMenuBoundary === true;
        if (isRightClickWithinMenuBoundary === true) {
          $doc.one("mouseup", removeMenuHandler);
        } else {
          $el.css("display", "none");
          $doc.off("mouseup", removeMenuHandler);
          $doc.off("scroll", removeMenuHandler);
          $doc.off("mousewheel", removeMenuHandler);
          $window.off("resize", removeMenuHandler);
          if (isMouseUpEvent !== true || isWithinMenuBoundary !== true) {
            self.detachEvents();
          }
          T1.EventHub.publish("onConfigDismiss");
        }
      }
      $doc.one("mouseup", removeMenuHandler);
      $doc.one("scroll", removeMenuHandler);
      $doc.one("mousewheel", removeMenuHandler);
      $window.one("resize", removeMenuHandler);
    },
    serialize: function () {
      var result = this.popoverConfigs;
      var items;
      var hasIcons;
      var hasBeta;
      var i;
      var j;
      var len = result.menuColumns.length;
      var len2;
      for (i = 0; i < len; i++) {
        result.menuColumns[i].firstCol = i === 0;
        hasIcons = result.menuColumns[i].hasIcons || false;
        hasBeta = result.menuColumns[i].hasBeta || false;
        items = result.menuColumns[i].menuItems;
        len2 = items.length;
        for (j = 0; j < len2; j++) {
          items[j].index = makeItemKey(i, j);
          if (hasIcons) {
            if (!items[j].hasOwnProperty("iconClass") || items[j].iconClass === "") {
              throw new Error("icon class can not be blank or undefined");
            }
          }
        }
      }
      return result;
    },
    createMenu: function (config, view) {
      var self = instance;
      var localConfig = $.extend(true, {}, config);
      var viewHolder = {
        view: view
      };
      var validationResult = validateMenuCreateParameters(localConfig, view);
      var $scopeToFindButton = view ? $(view.el) : $(self.getScopeContainer());
      var $menuButton;
      if (validationResult.success !== true) {
        throw new Error(validationResult.errMsg);
      }
      $menuButton = $scopeToFindButton.find(localConfig.menuTriggerEleSelector);
      if ($menuButton.length <= 0) {
        throw new Error("config.menuTriggerEleSelector is not a valid selector");
      }
      $menuButton.on("click", function (event) {
        if (localConfig.updateSettingsMenuConfig) {
          localConfig.updateSettingsMenuConfig();
          localConfig = $.extend(true, {}, config);
        }
        T1.EventHub.publish("onConfigClick", viewHolder);
        self.showPopover(event, viewHolder, localConfig, $menuButton);
      });
      return {
        t1MenuInstance: self,
        config: localConfig
      };
    }
  });
  CommonMenu = extendPositionable(CommonMenu);
  if (!instance) {
    instance = new CommonMenu();
  }
  return instance.createMenu;
});
