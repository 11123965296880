define('modules/targeting/digital/views/readOnly',["jQuery", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils", "modules/strategy/views/helpers/technologyWurflTargetHelper"], function ($, T1View, template, Util, TechnologyWurflTargetHelper) {
  "use strict";

  var readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": "prepareData"
    },
    initialize: function ({
      model: model,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit,
      strategy: strategy
    }) {
      this.targetDimensions = model;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.strategy = strategy;
      this.attachments = {};
      const isDimensionDataReady = this.targetDimensions.get(Util.names.targetDimensions).loaded;
      if (isDimensionDataReady && isDimensionDataReady === true) {
        this.prepareData();
      } else {
        this.bindViewEvents();
      }
    },
    load: function () {
      this.render().then(() => {
        var loader = this.loader();
        if (!this.prepareDataIsCalled || this.prepareDataIsCalled === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
      });
    },
    prepareData: function () {
      var data = this.targetDimensions;
      TechnologyWurflTargetHelper.fetchWURFLData(this.model.id).then(wurflData => {
        if (wurflData) {
          data.get("target_dimensions").get("WURF").get("target_value").add(wurflData);
        }
        this.prepareDataIsCalled = true;
        this.digitalCollection = Util.prepareTargetDimensionData(data, function (dimCode) {
          return /^(CSPD|ISPX|BSER|INVT|DVCE|WURF|WURFL\/OS)$/.test(dimCode);
        });
        this.partialCollection = data.get("partial");
        this.load();
      });
    },
    serialize: function () {
      var self = this;
      var collObj = this.digitalCollection;
      var partialCollObj = this.partialCollection;
      var codeNameRef = {
        CSPD: "Connection Speed",
        ISPX: "ISP",
        BSER: "Browser",
        INVT: "Inventory Type",
        DVCE: "Device Type",
        WURF: "Device Model",
        "WURFL/OS": "OS Version"
      };
      var resultJSON = {
        dataMain: []
      };
      var formatName = function (model) {
        let parent;
        var parentId = model.get("parent_value_id");
        var fullPathName = model.get("full_path_name");
        if (parentId) {
          partialCollObj.each(function (parentModel) {
            if (parentModel.get("id") === parentId) {
              parent = parentModel;
            }
          });
          if (!parent) {
            self.digitalCollection.each(function (parentModel) {
              if (parentModel.get("id") === parentId) {
                parent = parentModel;
              }
            });
          }
          if (parent) {
            return `${parent.get("name")} &raquo; ${model.get("name")}`;
          }
        }
        if (model.get("dimension_code") === "WURF" || model.get("dimension_code") === "WURFL/OS") {
          return fullPathName ? fullPathName : model.get("name");
        }
        return model.get("name");
      };
      var codeNameHashInc = {
        CSPD: [],
        ISPX: [],
        BSER: [],
        INVT: [],
        DVCE: [],
        WURF: [],
        "WURFL/OS": []
      };
      var codeNameHashExc = {
        CSPD: [],
        ISPX: [],
        BSER: [],
        INVT: [],
        DVCE: [],
        WURF: [],
        "WURFL/OS": []
      };
      var spaceEntity = "&nbsp;";
      var createDataBlock = Util.createGroupedDataArray;
      var hasIncludeData = false;
      var hasExcludeData = false;
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/technology`;
      }
      if (!collObj || !collObj.models || !collObj.models.length) {
        this.hasData = false;
        return resultJSON;
      }
      $.each(collObj.models, function (index, model) {
        var action = model.get("action");
        var dimCode = model.get("dimension_code");
        var tmpArr = action === "include" ? codeNameHashInc[dimCode] : codeNameHashExc[dimCode];
        if (tmpArr) {
          tmpArr.push(formatName(model));
        }
      });
      let tempData = createDataBlock(codeNameHashInc, codeNameRef);
      if (tempData.length) {
        hasIncludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "included technologies",
          data: tempData
        });
      }
      tempData = createDataBlock(codeNameHashExc, codeNameRef);
      if (tempData.length) {
        hasExcludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "excluded technologies",
          data: tempData
        });
      }
      resultJSON.hasData = hasIncludeData || hasExcludeData;
      if (resultJSON.hasData) {
        resultJSON.firstSideTitle = resultJSON.dataMain[0].sideTitle;
        resultJSON.dataMain[0].sideTitle = spaceEntity;
      }
      this.hasData = resultJSON.hasData;
      return resultJSON;
    }
  });
  return readOnlyView;
});
