define('modules/creatives/concept/views/creative_list',["jQuery", "T1", "T1View", "text!../templates/creative_list.html", "text!../templates/creative_content.html", "T1Layout", "moment", "models/videoCreative", "collections/organizations", "../models/creativeActive", "utils/SearchBuilder"], function ($, T1, T1View, template, subtemplate, T1Layout, moment, VideoCreative, Organizations, selectOptions, SearchBuilder) {
  return T1View.extend({
    template: template,
    selected: "",
    cors: COMPASS_ENV.VIDEO_API_BASE + "/creatives/proxy/?url=",
    searchFilters: null,
    fetchRequired: true,
    eventhubEvents: {
      "creativeList:search": function (args) {
        this.searchCreativeLists.call(this, args);
      },
      "advertisers:set": function (args) {
        $(".w-CreativeBin").show();
        $(".show-head").show();
        this.advertiserID = args;
        this.search();
      }
    },
    initialize: function (args) {
      this.conceptID = args.conceptID;
      this.orgID = args.orgId;
      this.advertiserID = args.advertiserID ? args.advertiserID : "";
      this.dataEvents = {};
      this.isEdit = args.isEdit;
      this.sortBy = "concept_id";
      this.orderBy = "-updated_on";
      if (args.sharedCollection) {
        this.sharedCollection = args.sharedCollection;
      }
      this.searchBuilder = new SearchBuilder({
        active_state: {
          active: {
            term: "1",
            searchField: "status"
          },
          inactive: {
            term: "0",
            searchField: "status"
          }
        },
        advertiser: {
          id: {
            term: "",
            searchField: "advertiser_id"
          }
        },
        assignment: {
          assigned: {
            term: "0",
            searchField: "concept_id"
          },
          unassigned: {
            term: "null",
            searchField: "concept_id"
          }
        },
        channel: {
          display: {
            term: "display",
            searchField: "media_type"
          },
          video: {
            term: "video",
            searchField: "media_type"
          }
        },
        search: {
          input: {
            term: this.searchTerm,
            searchField: "multiField"
          }
        },
        file_type: {
          file_type: {
            term: "",
            searchField: "file_type"
          }
        }
      });
      this.searchBuilder.setFlag("active_state", "active");
      this.initLayout();
      if (!this.orgID) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
    },
    initLayout: function () {
      var self = this;
      var filterAndClearCallback = function (term) {
        T1.EventHub.publish("creativeList:search", {
          term: term || ""
        });
      };
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".creative-list-list-container",
        tmplPath: "modules/creatives/concept/templates/creative_content.html",
        wrapperEl: "<div></div>",
        layout: {
          ".filters": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback
            }
          }],
          ".creative-list-grid": [{
            module: "creatives/concept",
            viewType: "creative_grid",
            options: {
              collection: self.collection,
              sharedCollection: self.sharedCollection,
              searchTerm: self.searchTerm
            }
          }]
        },
        serialize: function () {
          return {
            isEdit: self.isEdit
          };
        }
      });
      if (this.isEdit) {
        this.search();
      }
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.load().then(function () {
          T1.Selectbox($(".list-footer .select", $(self.el)), {
            onChange: function (args) {
              self.onSelectChanged(args);
            }
          });
          T1.Selectbox($(".status .select-input", this.el), {
            onChange: function (e) {
              switch (parseInt(e.target.value, 10)) {
                case 0:
                  self.searchBuilder.clearGroupFlags("active_state");
                  self.active = "All";
                  break;
                case 1:
                  self.searchBuilder.setFlag("active_state", "active");
                  self.active = "Active";
                  break;
                case 2:
                  self.searchBuilder.setFlag("active_state", "inactive");
                  self.active = "Inactive";
                  break;
              }
              self.search();
            }
          });
          T1.Selectbox($(".assignment .select-input", this.el), {
            onChange: function (e) {
              switch (parseInt(e.target.value, 10)) {
                case 0:
                  self.sortBy = "concept_id";
                  self.orderBy = "-updated_on";
                  self.searchBuilder.clearGroupFlags("assignment");
                  break;
                case 1:
                  self.sortBy = "-updated_on";
                  self.orderBy = "decending";
                  self.setSearchType("greater");
                  self.searchBuilder.setFlag("assignment", "assigned");
                  break;
                case 2:
                  self.sortBy = "-updated_on";
                  self.orderBy = "decending";
                  self.setSearchType("special");
                  self.searchBuilder.setFlag("assignment", "unassigned");
                  break;
              }
              self.search();
            }
          });
          T1.Selectbox($(".channel .select-input", this.el), {
            onChange: function (e) {
              switch (parseInt(e.target.value, 10)) {
                case 0:
                  self.searchBuilder.clearGroupFlags("channel");
                  break;
                case 1:
                  self.searchBuilder.setFlag("channel", "display");
                  break;
                case 2:
                  self.searchBuilder.setFlag("channel", "video");
                  break;
              }
              self.search();
            }
          });
        });
      });
    },
    setSearchType: function (setType) {
      this.collection.searchConfiguration = $.extend(true, this.collection.searchConfiguration, {
        fields: {
          status: {
            type: "enum"
          },
          concept_id: {
            type: setType
          },
          media_type: {
            type: "exact"
          },
          multiField: {
            type: "multiField"
          }
        }
      });
    },
    unload: function () {
      this.searchTerm = "";
    },
    assignCreativeList: function (event) {
      var currentTarget = $(event.currentTarget);
      var id = currentTarget.closest(".item").data("id");
      var collection = this.collection;
      var item = collection.get(id);
      T1.EventHub.publish("assigned:creativeList", item);
    },
    search: function () {
      var self = this;
      var searchTerm = this.searchTerm;
      var collection = this.collection;
      var advertiserID = this.advertiserID;
      var orgID = this.orgID;
      var fileTypeFilterKey = "file_type";
      var params;
      self.counter = 0;
      if (collection) {
        collection.reset();
      }
      if (searchTerm && searchTerm !== "") {
        if ($.inArray(searchTerm.toLowerCase(), ["gif", "swf", "jpg", "png", "tif", "unknown", "html5"]) !== -1) {
          this.searchBuilder.clearGroupFlags("multiField");
          this.searchBuilder.setFlag(fileTypeFilterKey, fileTypeFilterKey, {
            term: searchTerm.toLowerCase()
          });
        } else {
          this.searchBuilder.setFlag("search", "input", {
            term: this.searchTerm
          });
          this.searchBuilder.clearFlag(fileTypeFilterKey, fileTypeFilterKey);
        }
      } else {
        this.searchBuilder.clearFlag("search", "input");
        this.searchBuilder.clearFlag(fileTypeFilterKey, fileTypeFilterKey);
      }
      this.searchBuilder.setFlag("advertiser", "id", {
        term: advertiserID
      });
      collection.urlFilter.set({
        entity: "organization",
        id: orgID
      });
      collection.fetchOptions = {
        full: "*",
        with: ["concept", "atomic_creative_ssl_validators"],
        sort_by: self.sortBy,
        order_by: self.orderBy
      };
      collection.canCache = false;
      params = this.searchBuilder.getAllFilters();
      collection.search.set({
        params: params,
        fetchRequired: true,
        success: function (modeldata) {
          T1.EventHub.publish("grid:setHighlightValue", self.searchTerm);
          if (modeldata.count === 0 || self.collection.length === 0) {
            self.collection.trigger("reset", collection);
          }
          modeldata.each(function (model) {
            var that = this;
            var ID = model.get("id");
            var detail = "--";
            var mediaType = model.get("media_type");
            var width, height, duration, vast, vc;
            if (mediaType === "video") {
              vc = new VideoCreative({
                id: ID
              });
              vc.sync("read", {
                id: ID
              }, {
                onSuccess: function (data) {
                  if (data.duration) {
                    duration = moment.utc(data.duration * 1e3).format("mm:ss");
                  } else if (data.details) {
                    if (data.details.customVAST) {
                      vast = data.details.customVAST;
                      try {
                        that.xmlDoc = $.parseXML(vast.trim()).documentElement;
                      } catch (err) {
                        self.counter = self.counter + 1;
                        model.set({
                          trait: "--"
                        });
                        return;
                      }
                      duration = $(that.xmlDoc).find("Duration").first().text().substr(-5);
                      duration = duration ? duration : "--";
                    } else if (data.details.customVASTUrl) {
                      vast = data.details.customVASTUrl;
                      that.xmlDoc = self.cors + encodeURIComponent(vast);
                      $.get(that.xmlDoc, function (xmlData) {
                        that.xmlDoc = xmlData;
                        duration = $(that.xmlDoc).find("Duration").first().text().substr(-5);
                        duration = duration ? duration : "--";
                      }).fail(function () {
                        self.counter = self.counter + 1;
                        model.set({
                          trait: "--"
                        });
                        return;
                      });
                    }
                  }
                  self.counter = self.counter + 1;
                  model.set({
                    trait: duration
                  });
                  if (self.counter >= modeldata.length) {
                    collection.trigger("reset", collection);
                  }
                },
                onError: function () {
                  self.counter = self.counter + 1;
                  model.set({
                    trait: "--"
                  });
                  if (self.counter >= modeldata.length) {
                    collection.trigger("reset", collection);
                  }
                },
                errorDisplaySuppressingConfig: {
                  errorCodes: [404, 502, 503]
                },
                dataType: "json",
                processAjaxResponse: function (resp) {
                  return {
                    jsonData: resp,
                    statusCode: resp.status.code || "ok"
                  };
                }
              });
            } else {
              width = model.get("width");
              height = model.get("height");
              detail = width + "x" + height;
              model.set({
                trait: detail
              });
              self.counter++;
            }
            if (self.counter >= modeldata.length) {
              collection.trigger("reset", collection);
            }
          });
        }
      });
      T1.EventHub.publish("creativeList:setSearchLoader");
    },
    searchCreativeLists: function (data) {
      var term = data.term;
      this.searchTerm = term;
      this.search();
    },
    onSelectChanged: function (e) {
      var selectedIndex = e.target.selectedIndex;
      var $el = $(".list-footer .checkBoxHolder", $(this.el));
      if (selectedIndex === 0) {
        $el.show();
      } else {
        $el.hide();
      }
    }
  });
});
