define('modules/audiences/onboarding/create/views/inAppCreateEdit',["jQuery", "T1", "modules/audiences/onboarding/create/views/onboardingForm", "text!modules/audiences/onboarding/create/templates/inApp/delivery.html", "text!modules/audiences/onboarding/create/templates/inApp/standardAttributes.html", "text!modules/audiences/onboarding/create/templates/inApp/customAttributes.html", "text!modules/audiences/onboarding/create/templates/inApp/implementations.html", "modules/audiences/segments/segmentUtils"], function ($, T1, OnboardingCreateEdit, InAppDelivery, InAppStandardAttributes, InAppCustomAttributes, InAppImplementationsTemplate, Util) {
  "use strict";

  var inAppCreateEditView = OnboardingCreateEdit.extend({
    DeliveryTemplate: Hogan.compile(InAppDelivery),
    StandardAttributesTemplate: Hogan.compile(InAppStandardAttributes),
    CustomAttributeTemplate: Hogan.compile(InAppCustomAttributes),
    ImplementationsTemplate: Hogan.compile(InAppImplementationsTemplate),
    renderConfigs: {
      sdk_key: "{{model.sdk_key}}"
    },
    events: Object.assign({}, OnboardingCreateEdit.prototype.events, {
      'changed strand-repeater-row strand-input[name="sdk_key"]': "modifyKey"
    }),
    renderCallback: function () {
      if (this.mode === "edit") {
        this.loadModel();
      } else {
        this.loadAdvertisers();
      }
    },
    updateBreadCrumb: function () {
      var pathPieces;
      this.title = this.mode === "edit" ? this.model.name : "New In-App Events";
      if (this.mode === "edit") {
        pathPieces = [{
          label: "Edit " + this.model.name,
          location: "#audiences/onboarding/in-app/edit/" + this.id
        }];
      } else {
        pathPieces = [{
          label: "New In-App Events",
          location: "#audiences/onboarding/in-app/create"
        }];
      }
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
    },
    loadImplementations: function (adv) {
      if (this.mode === "edit" && this.model.method) {
        this.el[0].querySelector("#editImplementation").innerText = this.model.method;
      }
      var disableIneligibleMethods = () => {
        requestAnimationFrame(() => {
          if (this.ineligibleMethods.length) {
            var currentMethod = this.ineligibleMethods.shift();
            var methodRow = this.$(`#implementationMethod strand-list-item[value=${currentMethod.value}]`).closest(".recycler-panel");
            if (methodRow.length) {
              methodRow.addClass("disabled");
            } else {
              this.ineligibleMethods.push(currentMethod);
            }
            disableIneligibleMethods();
          }
        });
      };
      var bindToDropdowns = (methods, tag_managers) => {
        var implementationMethodDrop = this.el[0].querySelector("#implementationMethod");
        var preloadedImplementation = this.model.method;
        implementationMethodDrop.set("data", methods);
        this.el[0].querySelector("#tagManagement").set("data", tag_managers);
        implementationMethodDrop.isLoading = false;
        [...this.el[0].querySelectorAll("#implementationMethod .recycler-panel")].map(el => el.classList.remove("disabled"));
        this.ineligibleMethods = methods.filter(method => !method.eligible);
        disableIneligibleMethods();
        if (preloadedImplementation) {
          implementationMethodDrop.value = preloadedImplementation;
        }
        implementationMethodDrop.disabled = false;
      };
      this.el[0].querySelector("#implementationMethod").isLoading = true;
      return this.model.fetchImplementationMethods(bindToDropdowns, adv);
    },
    advertiserChanged: function () {
      var advertiserValue = this.el[0].querySelector("#advertisers").value || this.model.advertiser_id;
      if (typeof advertiserValue === "object") {
        return;
      }
      this.model.advertiser_id = advertiserValue;
      this.loadImplementations(advertiserValue);
      this.saveCheck();
    },
    validateCustomAttributes: function (data, added, modified, removed) {
      return data.map(function (model, index, data) {
        var isEmptyRow = !model.key && !model.name && !model.data_type && !model.note;
        if (isEmptyRow) {
          return {
            cId: model.cId
          };
        }
        return {
          cId: model.cId,
          sdk_key: !model.sdk_key ? "SDK Key Required" : !model.sdk_key.match(/^(\w|\s)*$/) ? "SDK Key must be alphanumeric" : null,
          key: !model.key ? "Attribute required" : _.pluck(data, "key").find((el, i) => el === model.key && i < index) ? "Attribute must be unique" : null
        };
      });
    },
    nameToSdkKey: function (attribute) {
      if (attribute.sdk_key || !attribute.key) {
        return attribute;
      }
      let sdk_key = attribute.key.replace("query.", "");
      attribute.sdk_key = sdk_key;
      delete attribute.value;
      return attribute;
    },
    createSaveObj: function () {
      this.model.events = this.eventsRepeater.data;
      this.model.standard_attributes = this.standardAttributesRepeater.data.map(attribute => this.nameToSdkKey(attribute));
      this.model.custom_attributes = this.customAttributesRepeater.data;
      this.model.delivery_format = "CSV";
      return this.model.dataToSaveObj();
    },
    successCallback: function (respObj, resp, e, postLoad) {
      var error = "";
      var viewOptions = {
        postSave: true
      };
      var returnedId;
      if (e) {
        Util.buttonReset(e.target, "Save");
        this.$(e.currentTarget).removeAttr("disabled");
      }
      this.el[0].querySelector("#onboarding-form").classList.remove("disabled");
      if (typeof resp === "string" && resp !== "success") {
        resp = $.parseJSON(resp);
        if ("errors" in resp) {
          if (Array.isArray(resp.errors) && resp.errors.length > 0) {
            error = error + resp.errors.join(";  ");
          }
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
        }
      } else {
        if (postLoad) {
          if (postLoad == "download") {
            this.model.postLoadDownload = false;
            this.viewOptions.makeDownloadCall = false;
            var deliveryFormat = this.model.delivery_format.toLowerCase();
            var blob = new Blob([respObj], {
              type: deliveryFormat === "csv" ? "attachment/csv" : "text/plain"
            });
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var elem = document.body.appendChild(a);
            var handler = function () {
              setTimeout(function () {
                window.URL.revokeObjectURL(url);
              }, 1e3);
              document.body.removeChild(elem);
              elem.removeEventListener("click", handler);
            };
            a.href = url;
            a.download = "pixel_data." + deliveryFormat;
            elem.addEventListener("click", handler);
            elem.click();
            return;
          }
        } else {
          this.model.id = respObj.data.id;
          viewOptions.makeViewCall = this.model.postLoadView;
          viewOptions.makeDownloadCall = this.model.postLoadDownload;
          if (this.mode === "edit") {
            this.load({
              viewOptions: viewOptions
            });
          } else {
            T1.Location.set("audiences/onboard/in-app/edit/" + this.model.id, {
              viewOptions: viewOptions
            });
          }
        }
      }
    },
    fetchDownload: function (data) {
      this.el[0].querySelector("#onboarding-form").classList.add("disabled");
      Util.buttonLoader({
        currentTarget: "#save-button"
      });
      this.model.delivery_format = "CSV";
      this.model.fetchDownload({
        success: (model, resp) => {
          Util.buttonReset($("#save-button"), "Save");
          this.successCallback.call(this, model, resp, null, "download");
        },
        error: (e, status, error) => {
          Util.buttonReset($("#save-button"), "Save");
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
        }
      });
    },
    handlePostLoadOptions: function (options, data) {
      if (options && options.postSave) {
        this.showFooterMsg();
      }
      if (options && options.makeDownloadCall) {
        this.fetchDownload(data);
      }
    }
  });
  return inAppCreateEditView;
});
