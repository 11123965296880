define('T1ColumnSortable',["Backbone", "jQuery", "T1", "T1View", "text!templates/column_sortable.html", "jQueryUI"], function (Backbone, $, T1, T1View, template) {
  "use strict";

  var extendColumnSortable;
  var ColumnSortableView = T1View.extend({
    template: template,
    initialize: function (args) {
      args = args || {};
      this.columnAHeader = args.columnAHeader || "Available";
      this.columnBHeader = args.columnBHeader || "Selected";
      this.doNotdisplayDialog = args.doNotdisplayDialog || false;
      this.listA = args.listA || [];
      this.listB = args.listB || [];
      this.onCloseCallback = args.onCloseCallback || $.noop;
      this.onUpdateCallback = args.onUpdateCallback;
      this.selectedClass = args.selectedClass || "selected";
      this.showCurrencyNotice = args.showCurrencyNotice;
      this.template = args.template || this.template;
      this.title = args.title || "Columns";
      this.warningText = args.warningText;
      this.warningType = args.warningType;
      this.showErrorState = args.showErrorState;
    },
    events: {
      "click .apply": "apply",
      "click .btn-clear": "removeItem",
      "click .list-add": "addItems",
      "click .list-remove": "removeItems",
      "click .ui-cancel": "closeDialog",
      "changed .search-input": "searchColumns"
    },
    eventhubEvents: {
      "reports.createEdit.dimensionError": "addErrorState"
    },
    load: function () {
      var self = this;
      var $el = this.el;
      var assignElements = function () {
        self.listRemoveButton = $el.find("button.list-remove");
        self.listAddButton = $el.find("button.list-add");
        self.listA_el = $el.find(".list-col-A .connectedSortable");
        self.listB_el = $el.find(".list-col-B .connectedSortable");
        self.searchTerm_el = $el.find(".search-input");
        self.currencyWarning = $el.find(".currency-warning");
        self.warningEl = $el.find(".selector-warning");
        self.initMultiSortable();
        self.listAddButton.blur();
      };
      this.unbindCustomEvents();
      this.bind("search:columns", this.searchItems);
      this.bind("reset:search:columns", this.resetSearchItems);
      this.render().then(function () {
        if (self.doNotdisplayDialog) {
          assignElements();
        } else {
          $el.dialog({
            dialogClass: "w-ColumnSortableDialog",
            closeText: "",
            autoOpen: true,
            modal: true,
            open: function () {
              assignElements();
              self.toggleCurrencyWarning();
            },
            close: function () {
              self.closeDialog.call(self);
            }
          });
        }
      });
    },
    unload: function () {
      this.unbindCustomEvents();
    },
    unbindCustomEvents: function () {
      Backbone.Events.unbind("search:columns", this.searchItems);
      Backbone.Events.unbind("reset:search:columns", this.resetSearchItems);
    },
    resetItem: function (item) {
      item.removeClass(this.selectedClass).addClass("highlight").removeData("i");
    },
    selectItem: function (item) {
      item.removeClass("highlight").addClass(this.selectedClass);
    },
    regroup: function (item, list) {
      var self = this;
      var myIndex, itemsBefore, itemsAfter;
      if (list.find("." + self.selectedClass).length > 0) {
        myIndex = item.data("i");
        itemsBefore = list.find("." + self.selectedClass).filter(function () {
          return $(this).data("i") < myIndex;
        }).css({
          position: "",
          width: ""
        });
        self.resetItem(itemsBefore);
        item.before(itemsBefore);
        itemsAfter = list.find("." + self.selectedClass).filter(function () {
          return $(this).data("i") > myIndex;
        }).css({
          position: "",
          width: ""
        });
        self.resetItem(itemsAfter);
        item.after(itemsAfter);
        self.resetItem(item);
      }
    },
    initMultiSortable: function () {
      var self = this;
      var $el = this.el;
      var targetButton, ignoreClickEvent;
      $el.find(".connectedSortable").sortable({
        connectWith: "#" + $el.attr("id") + " .connectedSortable",
        distance: 4,
        start: function (event, ui) {
          var parent = ui.item.parent();
          var selectedItems, myIndex;
          ui.item.addClass(self.selectedClass);
          parent.find(".ui-sortable-placeholder").removeClass(self.selectedClass).removeClass("highlight");
          self.itemW = ui.item.width();
          self.itemH = ui.item.outerHeight();
          selectedItems = parent.find("." + self.selectedClass);
          self.draggedItemNum = selectedItems.length;
          selectedItems.each(function (i) {
            $(this).removeClass("highlight").data("i", i);
          });
          myIndex = ui.item.data("i");
          self.draggedItemsBefore = selectedItems.filter(function () {
            return $(this).data("i") < myIndex;
          }).get().reverse();
          self.draggedItemsAfter = selectedItems.filter(function () {
            return $(this).data("i") > myIndex;
          });
        },
        sort: function (event, ui) {
          var top = parseInt(ui.item.css("top").replace("px", ""));
          var left = parseInt(ui.item.css("left").replace("px", ""));
          var height = 0;
          $(self.draggedItemsBefore).each(function () {
            height += self.itemH - 1;
            $(this).css({
              left: left,
              position: "absolute",
              top: top - height,
              width: self.itemW,
              zIndex: 1e3
            });
          });
          height = self.itemH - 1;
          self.draggedItemsAfter.each(function () {
            $(this).css({
              left: left,
              position: "absolute",
              top: top + height,
              width: self.itemW,
              zIndex: 1e3
            });
            height += self.itemH - 1;
          });
        },
        receive: function (event, ui) {
          self.regroup(ui.item, ui.sender);
        },
        stop: function (event, ui) {
          self.regroup(ui.item, ui.item.parent());
          self.updateButtonStates();
          self.updateLists();
          ignoreClickEvent = true;
          setTimeout(function () {
            ignoreClickEvent = false;
          }, 0);
          self.toggleSelectorWarning();
        }
      }).disableSelection().children().click(function (e) {
        if (!ignoreClickEvent) {
          var item = $(this);
          var parent = item.parent();
          var children = parent.children();
          var myIndex = children.index(item);
          var prevIndex = children.index(parent.find(".multiselectable"));
          if (e.shiftKey && prevIndex > -1 && prevIndex !== myIndex) {
            self.selectItem(prevIndex < myIndex ? item.prevUntil(".multiselectable") : item.nextUntil(".multiselectable"));
          }
          parent.find(".multiselectable").removeClass("multiselectable");
          item.removeClass("highlight").toggleClass(self.selectedClass);
          if (item.hasClass(self.selectedClass)) {
            item.addClass("multiselectable");
          }
          targetButton = $el.find(parent.data("btn"));
          self.handleButtonStates(targetButton, parent);
        }
      });
    },
    listHasSelectedItem: function (items) {
      var len = items.size();
      var i;
      var hasSelected = false;
      for (i = 0; i < len; i++) {
        if ($(items[i]).hasClass(this.selectedClass)) {
          hasSelected = true;
          break;
        }
      }
      return hasSelected;
    },
    handleButtonStates: function (targetButton, list) {
      if (this.listHasSelectedItem(list.children())) {
        targetButton.removeClass("btn-secondary").addClass("btn-highlighted");
      } else {
        targetButton.removeClass("btn-highlighted").addClass("btn-secondary");
      }
    },
    addItems: function (evt) {
      if (evt) {
        evt.stopPropagation();
      }
      this.listA_el.find("." + this.selectedClass).appendTo(this.listB_el).removeClass(this.selectedClass).addClass("highlight");
      this.handleButtonStates(this.listAddButton, this.listA_el);
      this.updateLists();
      if (this.showErrorState && this.errorState) {
        this.removeErrorState();
      }
      this.toggleSelectorWarning();
    },
    removeItems: function (evt) {
      if (evt) {
        evt.stopPropagation();
      }
      this.listB_el.find("." + this.selectedClass).appendTo(this.listA_el).removeClass(this.selectedClass).addClass("highlight");
      this.handleButtonStates(this.listRemoveButton, this.listB_el);
      this.updateLists();
      this.toggleSelectorWarning();
    },
    removeItem: function (evt) {
      if (evt) {
        evt.stopPropagation();
      }
      $(evt.currentTarget).parent().appendTo(this.listA_el).removeClass(this.selectedClass).addClass("highlight");
      this.handleButtonStates(this.listRemoveButton, this.listB_el);
      this.updateLists();
      this.toggleSelectorWarning();
    },
    searchItems: function (searchValue, reset) {
      var txt;
      var value = searchValue && searchValue.toLowerCase() || "";
      var filterItems = function ($parent, $items) {
        var $noResultsMsg = $parent.closest(".column").find(".no-results");
        $items.each(function () {
          txt = $(this).text().trim().toLowerCase();
          if (txt.indexOf(value) === -1) {
            $(this).hide();
          } else {
            $(this).show();
          }
        });
        if ($noResultsMsg) {
          if ($items.filter(":visible").length) {
            $noResultsMsg.hide();
          } else {
            $noResultsMsg.show();
          }
          if (reset) {
            $noResultsMsg.hide();
          }
        }
      };
      filterItems(this.listA_el, this.listA_el.children());
      filterItems(this.listB_el, this.listB_el.children());
    },
    resetSearchItems: function (searchValue) {
      this.searchItems(searchValue, true);
    },
    updateButtonStates: function () {
      this.handleButtonStates(this.listAddButton, this.listA_el);
      this.handleButtonStates(this.listRemoveButton, this.listB_el);
    },
    updateLists: function () {
      var returnObj, searchTerm;
      this.toggleCurrencyWarning();
      if (this.onUpdateCallback) {
        returnObj = this.organizeData();
        this.onUpdateCallback(returnObj.listB);
      }
      searchTerm = this.searchTerm_el.val() || "";
      this.searchItems(searchTerm, false);
    },
    addErrorState: function () {
      if (this.showErrorState) {
        this.errorState = true;
        this.listA_el.parent().addClass("error-state");
        this.listB_el.parent().addClass("error-state");
      }
    },
    removeErrorState: function () {
      this.errorState = false;
      this.listA_el.parent().removeClass("error-state");
      this.listB_el.parent().removeClass("error-state");
    },
    toggleCurrencyWarning: function () {
      if (this.showCurrencyNotice) {
        if (this.listB_el[0].innerText.indexOf("*") > -1) {
          this.currencyWarning.show();
        } else {
          this.currencyWarning.hide();
        }
      }
    },
    toggleSelectorWarning: function () {
      if (this.warningEl) {
        if (this.listB_el.children().length === 0) {
          this.warningEl.show();
        } else {
          this.warningEl.hide();
        }
      }
    },
    closeDialog: function (e, columns) {
      var el = $(this.el);
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.onCloseCallback.call(this, columns);
      el.dialog("destroy");
      this.unload();
    },
    organizeData: function () {
      var listA = [];
      var listB = [];
      this.listA_el.children().each(function () {
        listA.push($(this).data("item"));
      });
      this.listB_el.children().each(function () {
        listB.push($(this).data("item"));
      });
      return {
        listA: listA,
        listB: listB
      };
    },
    apply: function (e) {
      var self = this;
      var obj = self.organizeData();
      var listBCopy = obj.listB.concat();
      e.preventDefault();
      e.stopPropagation();
      self.closeDialog.call(self, e, obj);
      T1.EventHub.publish("track:event", {
        action: "Choose column",
        label: listBCopy.sort().toString(),
        value: listBCopy.length
      });
    },
    searchColumns: function (e) {
      var searchTerm = e.target.value;
      this.searchItems(searchTerm, false);
    },
    serialize: function () {
      return {
        columnAHeader: this.columnAHeader,
        columnBHeader: this.columnBHeader,
        listA: typeof this.listA === "function" ? this.listA() : this.listA,
        listB: typeof this.listB === "function" ? this.listB() : this.listB,
        showCurrencyNotice: this.showCurrencyNotice,
        title: this.title,
        warningText: this.warningText,
        warningType: this.warningType
      };
    }
  });
  extendColumnSortable = ColumnSortableView.extend;
  ColumnSortableView.extend = function () {
    var ColumnSortable = this;
    var originalInitialize, originalEvents, originalLoad, originalSerialize, ViewSubClass;
    ViewSubClass = extendColumnSortable.apply(this, arguments);
    originalInitialize = ViewSubClass.prototype.initialize || $.noop;
    originalEvents = ViewSubClass.prototype.events;
    originalLoad = ViewSubClass.prototype.load || $.noop;
    originalSerialize = ViewSubClass.prototype.serialize || $.noop;
    ViewSubClass.prototype.initialize = function (args) {
      ColumnSortable.prototype.initialize.call(this, args);
      originalInitialize.call(this, args);
    };
    ViewSubClass.prototype.events = $.extend(true, {}, ColumnSortable.prototype.events, originalEvents);
    ViewSubClass.prototype.load = function () {
      ColumnSortable.prototype.load.apply(this, arguments);
      originalLoad.apply(this, arguments);
    };
    ViewSubClass.prototype.serialize = function () {
      var tmplObj = ColumnSortable.prototype.serialize.apply(this, arguments);
      return originalSerialize.call(this, tmplObj) || tmplObj;
    };
    return ViewSubClass;
  };
  return ColumnSortableView;
});
