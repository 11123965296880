define('collections/vendorContracts',["jQuery", "T1", "T1Collection", "T1Sortable", "models/vendorContract"], function ($, T1, T1Collection, Sortable, VendorContract) {
  var sortConfiguration = {
    fields: {
      updated_on: {
        actual: "updated_on",
        display: "updated_on"
      }
    },
    default: {
      sort_by: "updated_on",
      order_by: "descending"
    }
  };
  var collection = T1Collection.extend({
    canCache: false,
    model: VendorContract,
    urlString: "vendors",
    fixture: "-vendors",
    filterConfiguration: {
      filters: [{
        entity: "campaign",
        fetchOnChange: false
      }],
      enableEvents: false
    },
    initialize: function () {
      this.fetchOptions.with = "vendor";
      this.fetchOptions.full = "*";
    },
    save: function (campaign_id, successCallBack, errorCallback, conflictCallback) {
      var self = this;
      var data;
      var modelsToSave = [];
      var modelsToDelete = [];
      var errorsList = [];
      var currentDropOperatonModelId = "unkown";
      function getData(model) {
        var price = model.get("price");
        if (!price || price === "") {
          price = "0.00";
          model.set({
            price: price
          });
        }
        return {
          version: model.get("version") || 0,
          campaign_id: campaign_id,
          price: price,
          rate_card_type: model.get("rate_card_type") || "CPM",
          vendor_id: model.get("vendor") ? model.get("vendor").id : undefined,
          use_mm_contract: model.get("use_mm_contract")
        };
      }
      function batchDeleteModels(modelsToBeDeleted) {
        var deferred = $.Deferred();
        var successCnt = 0;
        var totalCnt = modelsToBeDeleted.length;
        var successHandler = function () {
          successCnt++;
          if (successCnt >= totalCnt) {
            deferred.resolve();
          }
        };
        var errHandler = function (args) {
          if (!$.isArray(args)) {
            errorList.push(args);
          } else {
            errorList = args;
          }
          deferred.fail();
        };
        $.each(modelsToBeDeleted, function (index, model) {
          currentDropOperatonModelId = model.id || model.cid;
          model.drop(successHandler, errHandler);
        });
        return deferred.promise();
      }
      function batchSaveModels(modelsToSave) {
        var totalCnt = modelsToSave.length;
        var successCnt = 0;
        var saveSuccessHandler = function () {
          successCnt++;
          if (successCnt >= totalCnt) {
            successCallBack();
          }
        };
        $.each(modelsToSave, function (index, model) {
          var currentModelId = model.id;
          var saveErrorHandler = function (args) {
            if (!$.isArray(args)) {
              errorList.push(args);
            } else {
              errorList = args;
            }
            errorCallback([{
              id: currentModelId,
              errors: errorList
            }]);
          };
          currentModelId = model.id || model.cid;
          data = getData(model);
          model.save(data, {
            success: saveSuccessHandler,
            statusInvalid: saveErrorHandler,
            conflict: conflictCallback
          });
        });
      }
      this.each(function (model) {
        var errors;
        if (model.get("action") !== "delete" && model.get("action") !== "new") {
          data = getData(model);
          errors = model.validateAttrs(data);
          if (errors) {
            errorsList.push({
              id: model.id || model.cid,
              errors: errors
            });
          } else {
            modelsToSave.push(model);
          }
        } else if (model.get("action") === "delete") {
          modelsToDelete.push(model);
        }
      });
      if (!errorsList.length) {
        if (modelsToDelete.length > 0) {
          batchDeleteModels(modelsToDelete).done(function () {
            if (modelsToSave.length > 0) {
              batchSaveModels(modelsToSave);
            } else {
              successCallBack();
            }
            self.remove(modelsToDelete);
          }).fail(function () {
            errorCallback([{
              id: currentDropOperatonModelId,
              errors: errorList
            }]);
          });
        } else if (modelsToSave.length > 0) {
          batchSaveModels(modelsToSave);
        } else {
          successCallBack();
        }
      } else {
        errorCallback(errorsList);
      }
    }
  });
  T1.Models.VendorContracts = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.VendorContracts;
});
