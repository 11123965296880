define('collections/strategyTargetSegments',["jQuery", "Underscore", "T1", "T1Collection", "T1Model"], function ($, _, T1, T1Collection, T1Model) {
  var collection = T1Collection.extend({
    model: T1Model,
    urlString: "strategies",
    includedBinOperation: "OR",
    url: function () {
      var originalString = this.urlString;
      var url;
      this.urlString = originalString + "/" + this.id + "/" + this.action;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    parse: function (data) {
      var parsedData;
      var result = [];
      parsedData = T1Collection.prototype.parse.call(this, data);
      $.each(parsedData, function (index, value) {
        var targeting_segment;
        if (value.targeting_segment) {
          targeting_segment = value.targeting_segment;
          targeting_segment.action = value.restriction === "INCLUDE" ? "include" : "exclude";
          if (value.user_cpm) {
            targeting_segment.user_cpm = value.user_cpm;
          }
          result.push(targeting_segment);
        }
      });
      return result;
    },
    load: function () {
      var collection = this;
      this.action = "targeting_segments";
      this.fetchOptions.with = "targeting_segments";
      this.fetchOptions.full = "*";
      collection.fetch({
        add: true,
        success: function () {
          collection.trigger("segments:loaded");
        }
      });
    },
    buildPostObj: function () {
      var obj = {};
      var dataSet = this.models;
      var dataSetLen = dataSet.length;
      var segmentStr = "segments.";
      var idStr = ".id";
      var restrictionStr = ".restriction";
      var cpmStr = ".user_cpm";
      var operatorStr = ".operator";
      var userCPM;
      var i;
      var dataSetObj;
      var targetingOperators = this.targetingVendorOperators;
      var vendorName;
      if (dataSetLen > 0) {
        for (i = 0; i < dataSetLen; i++) {
          dataSetObj = dataSet[i].toJSON();
          if (dataSetObj.type !== "retired_target_segment") {
            obj[segmentStr + (i + 1) + idStr] = dataSetObj.id;
            obj[segmentStr + (i + 1) + restrictionStr] = dataSetObj.action.toUpperCase();
            if (dataSetObj.action.toUpperCase() === "INCLUDE") {
              vendorName = this.getSegmentType(dataSetObj);
              obj[segmentStr + (i + 1) + operatorStr] = targetingOperators[vendorName];
            }
            userCPM = dataSetObj.user_cpm;
            if (userCPM !== undefined) {
              obj[segmentStr + (i + 1) + cpmStr] = userCPM;
            }
          }
        }
      }
      obj.exclude_op = "OR";
      obj.include_op = this.includedBinOperation;
      return obj;
    },
    getSegmentType: function (object) {
      var self = this;
      var fullPath;
      var vendorName;
      fullPath = object.full_path;
      if (fullPath.split(" - ")) {
        vendorName = fullPath.split(" - ")[0];
        return vendorName;
      }
    },
    buildPostObjForPostingSafetyCpm: function (safetyCollection) {
      var obj = {};
      var safetyIndex = 0;
      var safetyObjects = safetyCollection.toJSON();
      var dataSetLen = safetyObjects.length;
      var targetValueStr = "target_values.";
      var idStr = ".id";
      var userCPM;
      var i;
      if (dataSetLen > 0) {
        for (i = 0; i < dataSetLen; i++) {
          obj[targetValueStr + (i + 1) + idStr] = safetyObjects[i].id;
        }
        return obj;
      }
    },
    buildPostObjForPostingSizeAndCpm: function () {
      var obj = {};
      var dataSet = this.models;
      var dataSetLen = dataSet.length;
      var segmentStr = "segments.";
      var idStr = ".id";
      var restrictionStr = ".restriction";
      var cpmStr = ".user_cpm";
      var userCPM;
      var i;
      var dataSetObj;
      if (dataSetLen > 0) {
        for (i = 0; i < dataSetLen; i++) {
          dataSetObj = dataSet[i].toJSON();
          obj[segmentStr + (i + 1) + idStr] = dataSetObj.id;
          obj[segmentStr + (i + 1) + restrictionStr] = dataSetObj.action.toUpperCase();
          userCPM = dataSetObj.user_cpm;
          if (userCPM !== undefined) {
            obj[segmentStr + (i + 1) + cpmStr] = userCPM;
          }
        }
      }
      obj.exclude_op = "OR";
      obj.include_op = this.includedBinOperation;
      return obj;
    }
  });
  T1.Models.StrategyTargetSegments = collection;
  return T1.Models.StrategyTargetSegments;
});
