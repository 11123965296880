define('modules/vertical/main',["jQuery", "T1", "T1Module", "collections/verticals"], function ($, T1, T1Module, Verticals) {
  return new T1Module({
    name: "vertical",
    viewCfgs: {
      select: {
        collection: new Verticals()
      }
    },
    defaultView: "select"
  });
});
