define('modules/audiences/pixels/views/grid',["jQuery", "T1", "T1View", "T1Layout", "text!modules/audiences/pixels/templates/grid_item.html", "T1GridView", "collections/pixels", "collections/organizations", "models/organization", "jslibs/ZeroClipboard/ZeroClipboard", "modules/changelog/views/dialog", "T1Menu", "T1Form", "models/pixelBundleUrl", "When", "jslibs/xdate/xdate"], function ($, T1, T1View, T1Layout, grid_item, createGrid, PixelBundles, Organizations, Organization, ZeroClipboard, ChangeLog, T1Menu, T1Form, PixelBundleUrl, When, xdate) {
  "use strict";

  var pixelView = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".pixel-grid",
        text: "Fetching Pixels",
        contentClass: "search-loader loader-overrides",
        action: "append"
      }
    },
    partials: {
      listItem: grid_item
    },
    sorterArrow: '<div class="order-by-pixel"><a class="order-icon descending"></a></div>',
    currentSorter: undefined,
    ajax_loader: undefined,
    pixel_type: "event",
    searchID: "all",
    pixel_status: 1,
    pixel_with: ["pixel_bundle_urls"],
    filtersEntity: [],
    filters: {},
    loading: true,
    originalHeaders: [{
      name: "checkbox-input",
      label: '<div class="checkbox all"></div>'
    }, {
      name: "name sortable",
      label: "Pixel Name"
    }, {
      name: "id sortable",
      label: "ID"
    }, {}, {
      name: "number-of-loads",
      label: "&#35; loads ydy"
    }, {
      name: "number-of-uniques",
      label: "&#35; uniques ydy"
    }, {
      name: "frequent-referrer",
      label: "top referrer ydy"
    }, {
      name: "modified sortable",
      label: "modified"
    }],
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Edit",
          visible: true
        }, {
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: true
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }, {
          label: "Email Pixel Code",
          handler: "emailExportHandler",
          visible: true
        }, {
          label: "Export Pixel Load History",
          handler: "exportCSVHandler",
          visible: true
        }, {
          label: "Change Log",
          handler: "viewChangeLog",
          visible: true
        }]
      }]
    },
    sortableHeaders: {
      name: {
        fieldName: "name",
        index: 1
      },
      id: {
        fieldName: "id",
        index: 2
      },
      advertiser: {
        fieldName: "advertiser.name",
        index: 3
      },
      "data-provider": {
        fieldName: "provider.name",
        index: 3
      },
      modified: {
        fieldName: "updated_on",
        index: 7
      }
    },
    events: {
      "click .item .icon-arrow, .item": "clickToggleNode",
      "click .header.sortable": "gridSorter",
      "click .checkbox": "handleCheckbox",
      "click .expansion-url": "showEventURlPopover",
      "mouseenter .expansion-url": "showUrlTooltip",
      "mouseleave .expansion-url": "hideUrlTooltip",
      "click strand-popover .remove": "removeEventURL",
      "click strand-popover .add": "addEventURL",
      "click strand-popover .close": "closeEventURLPopover",
      "click strand-popover .apply": "saveEventURLPopover",
      "close strand-popover": "closeEventURL",
      "click .pixel-edit": "editPixel",
      "click strand-popover strand-input": "removeErrorEventURLPopover",
      "contextmenu .pixel-edit": "applyEditHover",
      "mousemove .item-wrapper": "removeEditHover",
      "contextmenu .config": "stopEvent"
    },
    dataEvents: {
      collection: {
        reset: "load"
      }
    },
    loaderOpts: {
      contentClass: "inline-edit-loader",
      target: ".w-DataGrid .w-body.content",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15,
        top: 4
      }
    },
    stopEvent: function (e) {
      e.preventDefault();
      e.stopPropagation();
    },
    initialize: function (args) {
      var self = this;
      var filterConfiguration;
      var filter_with = this.pixel_with.slice();
      var orgPromise = Organizations.getOrganizations().getSelected();
      var orgCurrencyCode;
      var orgModel;
      this.pixel_type = args.pixel_type || this.pixel_type;
      this.pixel_status = args.pixel_status || this.pixel_status;
      this.searchTerm = args.searchTerm;
      this.collection = new PixelBundles();
      this.ajax_loader = T1.Loader.create({
        target: ".w-DataGrid .w-body.content",
        text: "Loading ...",
        contentClass: "search-loader loader-overrides grid-loader",
        spinnerConfig: {
          lines: 11,
          length: 1,
          radius: 3,
          left: 1,
          top: 0
        }
      });
      $(window).on("click", this.removeToolTips);
      if (this.pixel_type === "event") {
        filter_with.push("advertiser");
      } else {
        filterConfiguration = [{
          entity: "agency",
          fetchOnChange: true
        }, {
          entity: "organization",
          fetchOnChange: false
        }];
        filter_with.push("provider");
        this.collection.urlFilter.setConfiguration(filterConfiguration);
      }
      this.collection.fetchOptions = $.extend(true, this.collection.fetchOptions, {
        with: filter_with,
        sort_by: "-updated_on",
        full: "*"
      });
      this.filtersEntity = this.collection.filterConfiguration.filters.slice();
      this.collection.pageLimit = 50;
      this.collection.isPaginated = true;
      this.collection.canCache = false;
      this.addFilter("pixel_type", self.pixel_type);
      if (this.pixel_status !== "both") {
        this.addFilter("eligible", this.pixel_status);
      } else {
        this.removeFilter("eligible");
      }
      this.collection.search.set({
        params: this.allFilters(),
        fetchRequired: false
      });
      function fetchPixels(orgID) {
        self.collection.setOrganization(orgID);
        self.collection.fetch();
      }
      orgPromise.then(function (orgID) {
        self.orgID = orgID;
        orgModel = Organizations.getOrganizations().get(orgID);
        orgCurrencyCode = orgModel && orgModel.get("currency_code");
        if (!orgCurrencyCode) {
          orgModel = new Organization({
            id: orgID
          });
          orgModel.fetchOptions = $.extend({
            full: "*"
          }, orgModel.fetchOptions);
          orgModel.fetch({
            success: function (data) {
              self.orgCurrencyCode = orgModel.get("currency_code");
              fetchPixels(orgID);
            }
          });
        } else {
          self.orgCurrencyCode = orgCurrencyCode;
          fetchPixels(orgID);
        }
      });
    },
    loader: function (config) {
      if (config.id !== undefined) {
        config.target = "#" + config.id;
        if ($(this.el).find("dd .select").length > 0) {
          var id = "#expansion-loader-" + config.id;
          config.target = id;
          if ($(id).length === 0) {
            $("#expansion-row" + config.id).parent().find(".item").after('<div id="expansion-loader-' + config.id + '"></div>');
          }
        }
      } else if (config.action === "append") {
        var contentClass = config.contentClass.split(" ");
        contentClass.pop();
        contentClass.push("pixels");
        contentClass.push("loader-overrides");
        config.contentClass = contentClass.join(" ");
      }
      return T1.Loader.create(config);
    },
    eventhubEvents: {
      "PixelselectboxStatus:change": function (status) {
        this.setSearchLoader();
        this.collection.loaded = false;
        this.pixel_status = status;
        this.updatePixelStatus(true);
      },
      "PixelselectboxType:change": function (pixel_type) {
        this.setSearchLoader();
        this.searchTerm = "";
        this.collection.loaded = false;
        this.pixel_type = pixel_type;
        this.updatePixelType(true);
      },
      "organization:select": function (data) {
        if (!data.id || data.id === "") {
          throw new Error("Organization id must be provided");
        }
        this.filters = {};
        this.pixel_type = "event";
        this.searchTerm = "";
        this.searchID = "all";
        this.pixel_status = 1;
        this.collection.loaded = false;
        this.addFilter("eligible", this.pixel_status);
        this.addFilter("pixel_type", this.pixel_type);
        this.setSearchLoader();
        this.orgID = data.id;
        $(".select-type").val("event");
        $(".select-status").val(1);
        this.collection.fetch();
      },
      PixelSearchBox: function (data) {
        this.setSearchLoader();
        this.collection.loaded = false;
        if (data.flags) {
          this.searchTerm = data.term;
          this.searchID = data.flags.id;
          this.changePixelSearchSelect(false);
          this.updatePixelSearchTerm(true);
        } else {
          this.searchTerm = data.term;
          this.updatePixelSearchTerm(true);
        }
      },
      changePixelSearchSelect: function (data) {
        this.setSearchLoader();
        this.collection.loaded = false;
        this.searchID = data.id;
        this.changePixelSearchSelect(true);
      },
      "PixelselectboxAction:change": function (data) {
        var self = this;
        var action;
        if (data === "deactivate" || data === "active") {
          var $checkBoxes = $(this.el.find(".item-wrapper .checkbox.checked"));
          var status = data === "active" ? "1" : "0";
          var promises = [];
          $checkBoxes.each(function (index, value) {
            var not_done = true;
            promises.push(self.eligibleHandler(status, $(value).data("id"), false));
          });
          When.all(promises).then(function () {
            self.updatePixelStatus(true);
            action = data === "active" ? "activated" : "deactivated";
            T1.Notify("message", $checkBoxes.length + " Pixel(s) " + action);
          });
          return;
        }
        if (data === "export") {
          T1.EventHub.publish("buldEmailModal:show", {
            pixels_checked: $(this.el).find(".item-wrapper .checkbox.checked"),
            collection: this.collection
          });
          return;
        }
        if (data === "export-csv") {
          T1.EventHub.publish("csvExportModal:show", {
            pixels_checked: $(this.el).find(".item-wrapper .checkbox.checked"),
            collection: this.collection,
            pixelType: this.pixel_type,
            orgId: this.orgID
          });
          return;
        }
      },
      "inlineSelectBoxDefaults:change": "inlineSelectBoxChange"
    },
    updatePixelType: function (fetch) {
      var filter_with = this.pixel_with.slice();
      var filterConfiguration;
      fetch = fetch !== undefined ? fetch : true;
      this.collection.urlFilter.clearAll();
      this.collection.search.clear();
      this.collection.reset();
      if (this.pixel_type === "data") {
        filterConfiguration = [{
          entity: "agency",
          fetchOnChange: true
        }, {
          entity: "organization",
          fetchOnChange: false
        }];
        filter_with.push("provider");
        this.collection.fetchOptions = $.extend(true, this.collection.fetchOptions, {
          with: filter_with
        });
        this.collection.urlFilter.setConfiguration(filterConfiguration);
      } else {
        filter_with.push("advertiser");
        this.collection.fetchOptions = $.extend(true, this.collection.fetchOptions, {
          with: filter_with
        });
        this.collection.urlFilter.setConfiguration(this.filtersEntity);
      }
      this.removeFilter("provider_id");
      this.removeFilter("multiField");
      this.addFilter("pixel_type", this.pixel_type);
      this.collection.setOrganization(this.orgID);
      this.collection.search.set({
        params: this.allFilters(),
        fetchRequired: false
      });
      if (fetch) {
        this.collection.fetch();
      }
    },
    editPixel: function (event) {
      var $currentTarget = $(event.currentTarget);
      var id = $currentTarget.data("id");
      this.stopEvent(event);
      if (event.shiftKey || event.ctrlKey || event.metaKey) {
        window.open("#audiences/pixels/" + this.pixel_type + "/" + id, "_blank");
      } else {
        T1.Location.set("#audiences/pixels/" + this.pixel_type + "/" + id);
      }
    },
    applyEditHover: function (e) {
      var $currentTarget = $(e.currentTarget);
      $currentTarget.closest(".pixel-edit-holder").addClass("hover");
    },
    removeEditHover: function () {
      $(this.el).find(".pixel-edit-holder").removeClass("hover");
    },
    deactivateHandler: function (e) {
      var $currentTarget = $(e.currentTarget);
      var id = $currentTarget.closest("dl").data("id");
      this.eligibleHandler(0, id);
      T1.Notify("message", "Pixel eligibility was set to inactive");
    },
    exportCSVHandler: function (e) {
      var $currentTarget = $(e.currentTarget);
      T1.EventHub.publish("csvExportModal:show", {
        pixels_checked: $currentTarget.closest("dl"),
        collection: this.collection,
        pixelType: this.pixel_type,
        orgId: this.orgID
      });
    },
    emailExportHandler: function (e) {
      var $currentTarget = $(e.currentTarget);
      T1.EventHub.publish("buldEmailModal:show", {
        pixels_checked: $currentTarget.closest("dl"),
        collection: this.collection
      });
    },
    activateHandler: function (e) {
      var $currentTarget = $(e.currentTarget);
      var id = $currentTarget.closest("dl").data("id");
      this.eligibleHandler(1, id);
      T1.Notify("message", "Pixel eligibility was set to active");
    },
    eligibleHandler: function (status, itemID, updateGrid) {
      var self = this;
      var model = self.collection.get(itemID);
      var promise = When.defer();
      var data = {
        eligible: status
      };
      var hasRevenueAttribution;
      model = new self.collection.model($.extend({
        id: model.id
      }, model.toJSON()));
      if (model.url === "") {
        model.url = self.collection.urlString;
      }
      if (model.get("pixel_type") === "event") {
        hasRevenueAttribution = model.get("revenue") && (model.get("currency") || model.get("currency_fixed"));
        if (hasRevenueAttribution) {
          data.revenue = model.get("revenue");
          data.currency = data.revenue !== undefined ? model.get("currency") : "";
          data.currency_fixed = data.revenue !== undefined && data.currency === undefined ? model.get("currency_fixed") || self.orgCurrencyCode : "";
        } else {
          data.revenue = "";
          data.currency = "";
          data.currency_fixed = "";
        }
      }
      data.action = "status_change";
      updateGrid = updateGrid === undefined ? true : updateGrid;
      model.preventDelete = false;
      model.save(data, {
        success: function () {
          self.setSearchLoader();
          promise.resolve();
          if (updateGrid) {
            self.updatePixelStatus(true);
          }
        },
        statusInvalid: function () {
          promise.reject();
        }
      });
      return promise.promise;
    },
    updatePixelStatus: function (fetch) {
      fetch = fetch !== undefined ? fetch : true;
      this.collection.search.clear();
      if (this.pixel_status !== "both") {
        this.addFilter("eligible", this.pixel_status);
      } else {
        this.removeFilter("eligible");
      }
      this.collection.search.set({
        params: this.allFilters(),
        fetchRequired: false
      });
      if (fetch) {
        this.collection.fetch();
      }
    },
    updatePixelSearchTerm: function (fetch) {
      fetch = fetch !== undefined ? fetch : true;
      if (this.searchTerm === "") {
        this.collection.search.clear();
        this.removeFilter("multiField");
      } else {
        this.addFilter("multiField", this.searchTerm);
      }
      this.collection.search.set({
        params: this.allFilters(),
        fetchRequired: false
      });
      if (fetch) {
        this.collection.fetch({
          cache: false
        });
      }
    },
    changePixelSearchSelect: function (fetch) {
      fetch = fetch !== undefined ? fetch : true;
      if (this.searchID === "all") {
        this.removeFilter("provider_id");
        this.collection.search.set({
          params: this.allFilters(),
          fetchRequired: false
        });
        this.updatePixelType(false);
      } else {
        if (this.pixel_type === "event") {
          this.collection.setAdvertiser(this.searchID);
        } else {
          if (this.searchID) {
            this.addFilter("provider_id", this.searchID);
            this.collection.search.set({
              params: this.allFilters(),
              fetchRequired: false
            });
          }
        }
      }
      if (fetch) {
        this.collection.fetch();
      }
    },
    handleCheckbox: function (e) {
      var self = this;
      var $el = $(e.target);
      var $checkBoxes = $(self.el.find(".item-wrapper .checkbox"));
      var $allCheckBox = $(self.el.find(".header .checkbox.all"));
      var currentState = $allCheckBox.attr("class").split(" ");
      var isCheckAll = $el.hasClass("all");
      var allCheckedCount = function () {
        return $(self.el).find(".item-wrapper .checkbox.checked").length;
      };
      if (isCheckAll && currentState.indexOf("checked") !== -1 && $checkBoxes.length == allCheckedCount()) {
        $allCheckBox.removeClass("partial");
        $checkBoxes.removeClass("checked");
        $allCheckBox.removeClass("checked");
        $(".select-action").attr("disabled", "disabled");
      } else if (isCheckAll && currentState.indexOf("partial") !== -1) {
        $allCheckBox.removeClass("partial").addClass("checked");
        $checkBoxes.addClass("checked");
        $(".select-action").attr("disabled", null);
      } else if (isCheckAll) {
        $allCheckBox.removeClass("partial").addClass("checked");
        $checkBoxes.addClass("checked");
        $(".select-action").attr("disabled", null);
      }
      if (!isCheckAll) {
        if ($el.hasClass("checked")) {
          $el.removeClass("checked");
        } else {
          $el.addClass("checked");
        }
        if (allCheckedCount() === $checkBoxes.length) {
          $allCheckBox.removeClass("partial").addClass("checked");
        } else if (allCheckedCount() === 0) {
          $allCheckBox.removeClass("partial").removeClass("checked");
          $(".select-action").attr("disabled", "disabled");
          return;
        } else {
          $allCheckBox.addClass("partial");
        }
        $(".select-action").attr("disabled", null);
      }
    },
    load: function () {
      var self = this;
      this.headers = $.extend(true, [], this.originalHeaders);
      if (this.pixel_type === "data") {
        this.headers[3].label = "Data Provider";
        this.headers[3].name = "data-provider sortable";
      } else {
        this.headers[3].label = "Advertiser";
        this.headers[3].name = "advertiser sortable";
      }
      if (self.currentSorter) {
        var $arrow = $(self.sorterArrow);
        var index = self.currentSorter.index;
        $arrow.find("a").removeClass("descending").addClass(self.currentSorter.type);
        this.headers[index].label = this.headers[index].label + $arrow.get(0).outerHTML;
      }
      if (self.collection.loaded) {
        this.loading = false;
      } else {
        this.loading = true;
      }
      $(".select-action").attr("disabled", "disabled");
      this.render().then(function () {
        if (!self.collection.loaded) {
          self.setSearchLoader();
        }
        $(document.body).on("keydown", ".select", function (e) {
          if (e.keyCode === 39 || e.keyCode === 37) {
            e.preventDefault();
            e.stopPropagation();
          }
        });
        $(".content-holder").scroll(self.onScroll);
      });
    },
    clickToggleNode: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $arrow = $currentTarget.hasClass("icon-arrow") ? $currentTarget : $currentTarget.find(".icon-arrow");
      var expanded = $arrow.hasClass("expanded");
      var $itemWrapper = $arrow.closest(".item-wrapper");
      var $expansion = $itemWrapper.find(".expansion");
      var $target = $(e.target);
      var $buttons;
      var self = this;
      if ($target.hasClass("checkbox") || $target.hasClass("config") || $target.hasClass("settings-icon") || $target.hasClass("cancel")) {
        return;
      }
      this.preventDefault(e);
      $arrow.toggleClass("expanded");
      if (!$target.is("textarea")) {
        this.closeInlineEditor();
      }
      $expansion[expanded ? "hide" : "show"]("blind", {}, 500);
    },
    allFilters: function () {
      var filtersList = [];
      for (var index in this.filters) {
        if (this.filters.hasOwnProperty(index)) {
          filtersList.push({
            term: this.filters[index].toString(),
            searchField: index
          });
        }
      }
      return filtersList;
    },
    addFilter: function (key, value) {
      this.filters[key] = value;
    },
    removeFilter: function (key) {
      delete this.filters[key];
    },
    gridSorter: function (e) {
      var $el = $(e.currentTarget);
      var $elArrow = $($el).find(".order-by-pixel a");
      var classes = $el.attr("class").split(" ");
      var fieldName;
      var self = this;
      var index;
      self.setSearchLoader();
      for (index in this.sortableHeaders) {
        if (classes.indexOf(index) !== -1) {
          this.currentSorter = {
            class: "." + index,
            index: this.sortableHeaders[index].index
          };
          fieldName = this.sortableHeaders[index].fieldName;
          break;
        }
      }
      if ($elArrow.length === 0) {
        $(this.el).find(".headers .order-by-pixel").remove();
        $el.find(".header-label").parent().append(this.sorterArrow);
        this.currentSorter.type = "descending";
        this.collection.fetchOptions = $.extend(true, this.collection.fetchOptions, {
          sort_by: "-" + fieldName,
          full: "*"
        });
        this.collection.fetch();
        return;
      }
      if ($elArrow.hasClass("descending")) {
        $elArrow.removeClass("descending");
        $elArrow.addClass("ascending");
        this.currentSorter.type = "ascending";
      } else {
        $elArrow.addClass("descending");
        $elArrow.removeClass("ascending");
        this.currentSorter.type = "descending";
        fieldName = "-" + fieldName;
      }
      this.collection.fetchOptions = $.extend(true, this.collection.fetchOptions, {
        sort_by: fieldName,
        full: "*"
      });
      this.collection.fetch();
    },
    itemContentRendered: function ($item) {
      try {
        this.showOrApplySettingsMenu($item.find(".settings-icon"));
      } catch (e) {}
    },
    showOrApplySettingsMenu: function (eventOrTarget) {
      var self = this;
      var id;
      var model;
      var $menuTarget = eventOrTarget.hasOwnProperty("currentTarget") ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var isActive;
      var menuConfig = $.extend(true, {}, self.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Edit: 0,
        Deactivate: 1,
        Activate: 2
      };
      if ($menuTarget) {
        id = $menuTarget.data("id");
        model = self.collection.get(id);
        isActive = model.get("eligible") === "1";
        menuItems[menuIndexRef.Edit].url = "#audiences/pixels/" + self.pixel_type + "/" + id;
        menuItems[menuIndexRef.Activate].visible = !isActive;
        menuItems[menuIndexRef.Deactivate].visible = isActive;
        menuConfig.dataID = "data-id=" + id;
        menuConfig.menuTriggerEleSelector = "#" + id + " .config";
      }
      T1Menu(menuConfig, self);
    },
    serialize: function (inputCollection) {
      var collection = inputCollection || this.collection;
      var data = collection.toJSON();
      var dateFormat = "MM/dd/yy";
      var searchTerm = this.searchTerm;
      this.zeroclipLoaded = false;
      T1.EventHub.publish("searchTerm:set", searchTerm);
      if (searchTerm) {
        searchTerm = decodeURIComponent(searchTerm);
      }
      data.forEach(function (item, index) {
        var placement_key = "rpt_pixel_placement_qa_top";
        var placement = item[placement_key];
        var tag_types = {
          js: "Javascript MathTag Container",
          image: "Image MathTag",
          iframe: "IFrame MathTag Container",
          dfa: "Image MathTag for DART Floodlight",
          uat: "Image MathTag for Atlas UAT"
        };
        var tag_type = item.tag_type;
        var is_data = item.pixel_type !== "event" ? true : false;
        var highlightText = T1.Utils.highlightText;
        const escapeHTML = T1.Utils.escapeHTML;
        item.is_active = item.eligible === "1" ? true : false;
        if (item.unique) {
          item.load_count = item.unique.loads;
          item.load_count_date = item.unique.end_date;
          item.uniques_count = item.unique.uniques;
          item.top_referrer = item.unique.referrer;
        }
        item.name_highlight = escapeHTML(item.name, true);
        item.id_highlight = escapeHTML(item.id, true);
        if (!is_data) {
          item.advertiserOrDataProvider = escapeHTML(item.advertiser.name, true);
        } else {
          item.advertiserOrDataProvider = escapeHTML(item.pixel_provider.name, true);
        }
        if (searchTerm !== "") {
          searchTerm = escapeHTML(searchTerm, true);
          item.name_highlight = highlightText(item.name_highlight, searchTerm);
          item.id_highlight = highlightText(item.id_highlight, searchTerm);
          item.advertiserOrDataProvider = highlightText(item.advertiserOrDataProvider, searchTerm);
        }
        if (placement) {
          item[placement_key].load_date = Date.parse(placement.load_date).toString(dateFormat);
        }
        item.updated_on = Date.parse(item.updated_on).toString(dateFormat);
        item.tag_type = tag_types[tag_type];
        item.is_data = is_data;
        if (is_data) {
          var is_media_cost = item.cost_pct_cpm !== undefined;
          var execution_by = item.pixel_provider.execution_by.toLowerCase();
          if (is_media_cost) {
            item.cost_label = item.cost_pct_cpm + "% of Media Cost";
            item.cost_type = "percent";
          } else {
            item.cost_label = "$" + item.cost_cpm + " CPM";
            item.cost_type = "currency";
          }
          item.is_media_cost = is_media_cost;
          if (execution_by === "mediamath") {
            item.pixel_provider.execution_by = "MediaMath pays data provider (T1 client invoiced by MediaMath)";
            item.execution_by_mediamath = true;
          } else {
            item.pixel_provider.execution_by = "T1 Client pays data provider directly (T1 client is not invoiced by MediaMath)";
            item.execution_by_mediamath = false;
          }
        } else {
          var pixel_urls = item.pixel_bundle_url;
          if (pixel_urls !== undefined) {
            if (pixel_urls.hasOwnProperty("url")) {
              item.event_urls = pixel_urls.url;
              item.pixel_bundle_url.last_item = true;
            } else {
              var urls = [];
              for (index in pixel_urls) {
                urls.push(pixel_urls[index].url);
              }
              item.event_urls = urls.join(", ");
              if (pixel_urls.length !== 0) {
                item.pixel_bundle_url[pixel_urls.length - 1].last_item = true;
              } else {
                item.event_urls = "--";
              }
            }
          } else {
            item.event_urls = "--";
          }
        }
      });
      return {
        loading: this.loading,
        list: data,
        contentType: "pixel-grid"
      };
    },
    inlineSelectBoxChange: function (e) {
      var $target = $(e.target);
      var $parent = $target.closest(".form-list");
      var $inputs = $parent.find(".input-c input");
      var value = $target.val();
      var types = ["cost_cpm", "cost_pct_cpm"];
      if (value === "percent") {
        types.reverse();
      }
      $parent.find(".info-c  div").text(value === "percent" ? "%" : "$");
      $inputs.each(function (index, value) {
        var $value = $(value);
        var hidden = $value.attr("type") === "hidden" ? true : false;
        if (hidden) {
          $value.data("bind", types[1]);
        } else {
          $value.data("bind", types[0]);
          $value.val("");
        }
      });
    },
    noop: function () {},
    canUnload: function () {
      $(window).off("click", this.removeToolTips);
      return false;
    },
    unload: function () {
      this.collection.reset();
      $(".tipsy").remove();
    },
    removeToolTips: function () {
      $(document.body).find(".tipsy-s:not('.w-DisplaySearchTooltip')").css("visibility", "hidden");
    },
    showEventURlPopover: function (e) {
      var self = this;
      var $span = $(e.target);
      var pixel_id;
      var $popover = $(this.el).find("strand-popover");
      if ($span !== undefined && $span.length !== 0) {
        pixel_id = $span.attr("id").split("-")[2];
      } else {
        pixel_id = $target.attr("id").split("-")[2];
      }
      this.eventURLPopover = new T1Layout({
        el: function () {
          return $(self.el);
        },
        template: '<div class="popover-content"></div>',
        selectorPath: ".w-foot",
        layout: {
          ".popover-content": [{
            module: "audiences/pixels",
            viewType: "eventURLPopover",
            options: {
              pixel_model: this.collection.get(pixel_id)
            }
          }]
        }
      });
      if ($popover.length > 0 && $popover.attr("id").split("-")[1] == pixel_id && $popover[0].state === "opened") {
        $popover[0].toggle();
      } else {
        this.closeEventURL();
        this.eventURLPopover.load();
      }
    },
    showUrlTooltip: function (e) {
      var $target = $(e.target);
      var className = "w-PixelUrlTooltip";
      var $parent = $target.parent();
      var tooltipTextArray = $target.text().split(",");
      tooltipTextArray = tooltipTextArray.map(function (tooltipText) {
        return tooltipText.replace("<", "&lt;").replace(">", "&gt;");
      });
      var msg = $("<div>" + tooltipTextArray + "</div>");
      msg.css({
        width: "400px",
        "white-space": "normal",
        "word-wrap": "break-word",
        padding: "5px",
        "font-size": "12px"
      });
      if ($target.text().length < 100) {
        return;
      }
      $("." + className).remove();
      T1.Tooltip($parent, {
        html: true,
        trigger: "manual",
        tooltipEle: $parent,
        getExternalTip: false,
        gravity: "s",
        className: className,
        offset: 10
      });
      $parent.attr("title", msg[0].outerHTML).tipsy("show");
    },
    hideUrlTooltip: function (e) {
      var className = "w-PixelUrlTooltip";
      $("." + className).remove();
    },
    closeEventURL: function () {
      this.eventURLPopover.destroy();
    },
    removeEventURL: function (e) {
      e.preventDefault();
      var $parent = $(e.currentTarget).parent();
      var $popover = $parent.closest("strand-popover");
      var $span_last;
      var $span_parent;
      var $body;
      var children;
      var add_button;
      $parent.addClass("delete");
      $parent.slideUp(400, function () {
        add_button = new Strand.Action();
        add_button.classList.add("add");
        add_button.async(function () {
          Polymer.dom(add_button).innerHTML = "<label>+ Add URL</label>";
        });
        children = $popover.find(".event-url:not(.delete)");
        if (children.length === 0) {
          $body = $popover.find(".body");
          Polymer.dom($body[0]).appendChild(add_button);
        } else {
          $popover.find(".add").remove();
          $span_last = $popover.find(".event-url:not(.delete)").last().find("strand-action.remove");
          $span_last.removeClass("extra-space");
          $span_parent = $span_last.parent();
          Polymer.dom($span_parent[0]).insertBefore(add_button, $span_last[0]);
        }
      });
    },
    addEventURL: function (e) {
      e.preventDefault();
      var $popover = $(e.currentTarget).closest("strand-popover");
      var $children = $popover.find(".event-url:not(.delete)");
      var $elem;
      var $span_last;
      var $span_parent;
      var $body;
      var $left;
      var $right;
      var add_button;
      $elem = $("<div></div>", {
        class: "event-url",
        html: '<strand-input></strand-input><strand-action class="extra-space remove"><label>x</label></strand-action>'
      });
      $popover.find(".add").remove();
      $popover.find(".remove").addClass("extra-space");
      if ($children.length < 10) {
        $body = $popover.find(".body");
        $left = $body.find(".left");
        $right = $body.find(".right");
        if ($left.children().length < 5) {
          $left.append($elem);
        } else {
          $right.append($elem);
        }
        $span_last = $popover.find(".event-url:not(.delete)").last().find("strand-action.remove");
        if ($children.length !== 9) {
          $span_last.removeClass("extra-space");
          add_button = new Strand.Action();
          add_button.classList.add("add");
          add_button.async(function () {
            Polymer.dom(add_button).innerHTML = "<label>+ Add URL</label>";
          });
          $span_parent = $span_last.parent();
          Polymer.dom($span_parent[0]).insertBefore(add_button, $span_last[0]);
        }
      }
    },
    closeEventURLPopover: function (e) {
      e.preventDefault();
      var $popover = $(e.currentTarget).closest("strand-popover");
      $popover[0].toggle();
      this.closeEventURL();
    },
    saveEventURLPopover: function (e) {
      e.preventDefault();
      var self = this;
      var $popover = $(e.currentTarget).closest("strand-popover");
      var $inputs = $popover.find("strand-input");
      var input_array = $inputs.toArray();
      var pixelID = $popover.attr("id").split("-")[1];
      var model = this.collection.get(pixelID);
      var eventURLCollection = model.get("pixel_bundle_url");
      var urls = [];
      var urlModel;
      var text;
      var $message;
      var $target;
      var data = [];
      var elem_text = [];
      var valid_data = [];
      var uniqueNames = [];
      var promises = [];
      var has_promese = false;
      input_array.forEach(function (val, index) {
        var $elem = $(val);
        var id = $elem.attr("id");
        var val = $elem.val().trim();
        if (val === "" && id === undefined) {
          return;
        }
        data.push({
          elem: $elem,
          new_item: id === undefined
        });
        elem_text.push(val);
      });
      $(elem_text).each(function (index, text) {
        var pos = $.inArray(text, uniqueNames);
        var $elem = $(data[index].elem);
        var remove = $elem.hasClass("delete");
        if (pos === -1) {
          uniqueNames.push(text);
          valid_data.push($elem);
        } else {
          if (!data[index].new_item && !valid_data[pos].new_item && !remove) {
            var url = eventURLCollection.get(valid_data[pos].attr("id")).get("url");
            if (url !== text) {
              valid_data[pos] = $elem;
            }
          } else if (remove) {
            valid_data.push($elem);
          }
        }
      });
      $(valid_data).each(function (index, val) {
        var $input = $(val);
        var id = $input.attr("id");
        var value = $input.val().trim();
        var remove = $input.parent().hasClass("delete");
        var promise = When.defer();
        if (remove) {
          delete valid_data[index];
        }
        if ((value === "" || remove) && id !== undefined && !$popover.hasClass("empty")) {
          has_promese = true;
          $input.remove();
          urlModel = eventURLCollection.get(id);
          urlModel.drop(function () {
            promise.resolve();
          });
          eventURLCollection.remove(urlModel);
          model.set(eventURLCollection);
          self.collection.add(model);
          return;
        } else if (id !== undefined && value !== "") {
          has_promese = true;
          urlModel = eventURLCollection.get(id);
          var oldURl = urlModel.get("url");
          if (oldURl !== value) {
            urlModel.set({
              url: value
            });
            urlModel.save({
              url: value,
              pixel_bundle_id: urlModel.get("pixel_bundle_id")
            }, {
              success: function () {
                eventURLCollection.add(urlModel);
                model.set(eventURLCollection);
                self.collection.add(model);
                promise.resolve();
              }
            });
          } else {
            has_promese = true;
            promise.resolve();
          }
        } else if (!remove && value !== "") {
          has_promese = true;
          urlModel = new PixelBundleUrl();
          urlModel.save({
            url: value,
            pixel_bundle_id: pixelID
          }, {
            success: function (response) {
              eventURLCollection.add(response);
              model.set(eventURLCollection);
              self.collection.add(model);
              promise.resolve();
            }
          });
        } else {
          return;
        }
        promises.push(promise.promise);
      });
      When.all(promises).then(function () {
        var eventURLS = eventURLCollection.toJSON();
        var urls = [];
        var text;
        if (has_promese) {
          eventURLS.forEach(function (item, index) {
            urls.push(item.url);
          });
          text = urls.join();
          if (text === "") {
            text = "--";
          }
          $target = $($popover.attr("target"));
          $target.text(text);
          $popover[0].toggle();
        }
      });
      if (valid_data.length > 0) {
        valid_data.forEach(function (val, index) {
          urls.push(val.val());
        });
      }
      text = urls.join(", ");
      if (text === "" && $popover.hasClass("empty")) {
        $message = $popover.find("strand-footer");
        $message[0].message = "Please add at least one URL or click cancel.";
        $message[0].type = "error";
        $message[0].showMessage();
        $inputs.parent(':not(".delete")').find("strand-input")[0].error = true;
        return;
      }
    },
    viewChangeLog: function (e) {
      var self = this;
      var model;
      var id = $(e.currentTarget).closest("dl").data("id");
      e.preventDefault();
      e.stopPropagation();
      if (id) {
        model = self.collection.get(id);
        $(document).trigger("click");
        ChangeLog.showDialog({
          model: model,
          entityType: "pixel"
        });
      }
    },
    removeErrorEventURLPopover: function (e) {
      var $popover = $(e.currentTarget).closest("strand-popover");
      var $inputs = $popover.find("strand-input");
      var hasError = $inputs[0].error;
      if (hasError) {
        $inputs[0].error = false;
        $inputs.off("focus", this.removeErrorEventURLPopover);
        $popover.find("strand-footer")[0].hideMessage();
      }
    }
  });
  var dropdownOpts = {
    cost_type: {
      options: [{
        value: "percent",
        text: " % of Media Cost"
      }, {
        value: "currency",
        text: "CPM"
      }]
    }
  };
  function updateDataPriceText(model, attr, newVal) {
    var $edited = $("#cost" + model.id);
    var $parent = $edited.parent();
    if (attr === "cost_pct_cpm" && newVal !== undefined) {
      newVal = newVal + "% of Media Cost";
      $parent.find(".hidden-cost-ediable").data("bind", "cost_cpm");
      $parent.find(".cost-ediable").data("bind", "cost_pct_cpm");
      $parent.find(".group-edit-content.ddl").data("current-value", "percent");
      model.unset("cost_cpm");
    } else if (attr === "cost_cpm" && newVal !== undefined) {
      newVal = "$" + newVal + " CPM";
      $.data($edited, "bind", "cost_cpm");
      $parent.find(".cost-ediable").data("bind", "cost_cpm");
      $parent.find(".hidden-cost-ediable").data("bind", "cost_pct_cpm");
      $parent.find(".group-edit-content.ddl").data("current-value", "currency");
      model.unset("cost_pct_cpm");
    }
    $edited.text(newVal);
    $edited.attr("title", newVal);
  }
  (function addInlineEditRenderers(collEvents) {
    var changeAttrs = ["name", "cost_cpm", "cost_pct_cpm"];
    changeAttrs.forEach(function (attr) {
      collEvents["change:" + attr] = function (model, newVal) {
        var $edited = $("#" + attr + model.id);
        if ($edited.length === 0) {
          newVal = updateDataPriceText(model, attr, newVal);
          return;
        }
        $edited.text(newVal);
        $edited.attr("title", newVal);
      };
    });
  })(pixelView.prototype.dataEvents.collection);
  var inlineEditable = T1Form.InlineEdit(pixelView, dropdownOpts);
  inlineEditable.prototype.actionsConfig = {
    events: {
      "click .save": "noop"
    }
  };
  return createGrid(inlineEditable);
});
