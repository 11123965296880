define('modules/admin/siteLists/views/usageItem',['require','jQuery','text!modules/admin/siteLists/templates/usageItem.html','T1','T1GridView','T1View'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const grid_item = require("text!modules/admin/siteLists/templates/usageItem.html");
  const T1 = require("T1");
  const T1GridView = require("T1GridView");
  const T1View = require("T1View");
  var T1Tooltip = T1.Tooltip;
  var UsageGridView = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        action: "append",
        contentClass: "search-loader loader-overrides admin-infinite view-loader",
        target: ".w-DataGrid",
        text: "Fetching&nbsp;Assignments"
      }
    },
    editEntityTooltipConfigs: {
      className: "edit-entity-icon-tip",
      delayIn: 100,
      delayOut: 100,
      fade: false,
      getExternalTip: false,
      html: true,
      tooltipEle: ".edit-entity-icon"
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "name",
      label: "Name"
    }, {
      name: "type",
      label: "Type"
    }],
    events: {
      "click .parent": "editParent"
    },
    dataEvents: {
      collection: {
        reset: "load"
      }
    },
    eventhubEvents: {
      "usageGrid:reload": function () {
        this.setSearchLoader();
      }
    },
    initialize(args) {
      this.initLoaderShown = false;
      this.collection = args.collection;
    },
    load() {
      this.render().then(() => {
        if (!this.initLoaderShown && !this.collection.loaded) {
          this.initLoaderShown = true;
          this.setSearchLoader();
        }
      });
    },
    unload() {
      $(".tipsy").remove();
    },
    itemContentRendered($item) {
      T1Tooltip($item, this.editEntityTooltipConfigs);
    },
    editParent(evt) {
      var $currentTarget = $(evt.currentTarget);
      var id = $currentTarget.data("id");
      var type = $currentTarget.data("type");
      evt.preventDefault();
      evt.stopPropagation();
      if (type && id) {
        T1.Location.set(`#${type}/edit/${id}/details${type === "campaign" ? "/general" : ""}`);
      }
    },
    serialize() {
      var entity, member;
      var coll = this.collection;
      var members = coll.toJSON() || [];
      var len = members.length;
      var output = {
        list: []
      };
      var highlightText = T1.Utils.highlightText;
      var searchResult = coll.searchTerm;
      if (coll.loaded) {
        $("strand-input.search-box").removeAttr("disabled");
        for (let i = coll.page * coll.pageLimit; i < len; i++) {
          member = members[i];
          entity = member.collection;
          output.list.push({
            id: member.id,
            entity_id: member.entity_id,
            name: searchResult ? highlightText(member.name, searchResult) : member.name,
            type: entity
          });
        }
      }
      if (coll.searchTerm && coll.length < 1) {
        output.customNoResultMessage = false;
      } else {
        output.customNoResultMessage = "This Site List is not applied to any Campaign or Strategy";
      }
      return output;
    }
  });
  return T1GridView(UsageGridView);
});
