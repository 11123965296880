define('modules/pixel/views/list',["jQuery", "T1", "T1View", "text!modules/pixel/templates/list.html", "T1Spinner", "T1Layout", "T1Selectbox", "T1Truncate"], function ($, T1, T1View, template, Spinner, T1Layout) {
  return T1View.extend({
    template: template,
    list_size_options: {
      options: [{
        value: "10",
        text: "10"
      }, {
        value: "25",
        text: "25"
      }]
    },
    selected: "",
    fetchRequired: true,
    allowMultipleSelection: false,
    eventhubEvents: {
      "advertiser:select": function (args) {
        this.advertiser_id = args.id;
      },
      "pixel:search": function (args) {
        this.searchPixels.call(this, args);
      },
      "pixel:applyFilter": function (args) {
        this.searchFilters = args;
        this.applyFilters.call(this);
      }
    },
    events: {
      "click .w-head .close": "hideList",
      "click .eligible label": "applyFilters"
    },
    previousSearchKey: "",
    initialize: function (args) {
      var self = this;
      var collection = self.collection;
      var searchViewType = args.searchViewType || "search";
      var ad_id;
      var orgId = args.orgId;
      collection.updatePageLimit(40);
      this.isEligible = 1;
      this.searchFilters = null;
      this.searchViewType = searchViewType;
      this.strategy = args.strategy || {};
      if (!orgId) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
      self.orgId = orgId;
      if (args.getAdvertiserId) {
        ad_id = args.getAdvertiserId();
        self.advertiser_id = ad_id !== "" ? ad_id : null;
        collection.search.set({
          params: [{
            term: "event",
            searchField: "pixel_type"
          }, {
            term: "1",
            searchField: "eligible"
          }],
          fetchRequired: false
        });
        if (ad_id !== undefined) {
          collection.urlFilter.set({
            entity: "advertiser",
            id: ad_id,
            fetchRequired: true
          });
        }
      }
      if (args.getAdvertiserName) {
        self.advertiser_name = args.getAdvertiserName();
      }
      if (args.getAdvertiser) {
        self.advertiser = args.getAdvertiser();
      }
      if (args.sharedCollection) {
        self.sharedCollection = args.sharedCollection;
      }
      if (args.allowMultipleSelection) {
        self.allowMultipleSelection = args.allowMultipleSelection;
      }
      if (args.campaign) {
        self.campaign = args.campaign;
      }
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".search",
        template: '<div class="filters-box"></div>',
        wrapperEl: "<div></div>",
        layout: {
          ".filters-box": [{
            module: "pixel",
            viewType: searchViewType,
            options: {
              strategy_id: self.strategy.id,
              orgId: self.orgId,
              advertiser: {
                id: self.advertiser_id,
                name: self.advertiser_name
              },
              getStrategyAgencyId: function () {
                return self.campaign ? self.campaign.get("advertiser").get("agency_id") : self.strategy.get("campaign").get("advertiser").get("agency_id");
              }
            }
          }]
        }
      });
      self.gridLayout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".pixel-grid",
        template: '<div class="grid-holder"></div>',
        wrapperEl: "<div></div>",
        layout: {
          ".grid-holder": [{
            module: "pixel",
            viewType: "grid",
            options: {
              collection: self.collection,
              sharedCollection: args.sharedCollection,
              getAdvertiser: args.getAdvertiserName,
              selectionMode: args.selectionMode,
              allowMultipleSelection: self.allowMultipleSelection
            }
          }]
        }
      });
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.onRenderComplete.call(self);
      });
    },
    onRenderComplete: function () {
      var self = this;
      var collection = this.collection;
      var $el = $(self.el);
      var selectBox = $el.find(".list-size-select");
      var selectBoxConfig = {
        searchFieldDisabled: true,
        onChange: function () {
          var page_size = selectBox.val();
          self.selected = page_size;
          collection.updatePageLimit(page_size);
          collection.fetch();
        }
      };
      self.layout.load();
      self.gridLayout.load();
      T1.Selectbox(selectBox, selectBoxConfig);
    },
    applyFilters: function (data) {
      var $el = $(this.el);
      var checkbox = $el.find(".eligible input");
      this.isEligible = $(checkbox).is(":checked");
      this.search();
    },
    unload: function () {
      if (this.searchViewType === "filterSearch") {
        this.collection.search.clear();
        this.searchTerm = "";
        this.searchFilters = {
          type: "event"
        };
        this.search(false);
      }
    },
    search: function (isFetchRequired) {
      var searchConfig = [];
      var isEligible = this.isEligible;
      var searchTerm = this.searchTerm;
      var collection = this.collection;
      var filterConfiguration = [];
      var searchFilterType;
      var orgPromise;
      collection.urlFilter.resetCriteria();
      if (this.searchFilters) {
        searchFilterType = this.searchFilters.type;
        collection.urlFilter.clear();
        if (searchFilterType === "event") {
          filterConfiguration.push({
            entity: "advertiser",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "advertiser",
            id: this.searchFilters.advertisers_id || this.advertiser_id
          });
        } else if (searchFilterType === "data") {
          collection.urlFilter.clearAll();
          filterConfiguration.push({
            entity: "agency",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "agency",
            id: this.strategy.get("campaign").get("advertiser").get("agency_id")
          });
        } else {
          collection.urlFilter.clearAll();
          filterConfiguration.push({
            entity: "advertiser",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "advertiser",
            id: this.searchFilters.segment_advertisers_id || this.advertiser_id
          });
        }
        searchConfig.push({
          term: searchFilterType,
          searchField: "pixel_type"
        });
        if (searchFilterType === "data" && this.searchFilters.provider_id) {
          searchConfig.push({
            term: this.searchFilters.provider_id,
            searchField: "provider_id"
          });
        }
      } else {
        searchConfig.push({
          term: "event",
          searchField: "pixel_type"
        });
      }
      if (isEligible) {
        searchConfig.push({
          term: "1",
          searchField: "eligible"
        });
      }
      if (searchTerm && searchTerm !== "") {
        searchConfig.push({
          term: searchTerm,
          searchField: "name|id"
        });
      }
      T1.EventHub.publish("pixel:setSearchLoader");
      collection.search.set({
        params: searchConfig,
        fetchRequired: isFetchRequired || true,
        success: function () {
          collection.trigger("reset", collection);
        }
      });
    },
    hideList: function (event) {
      this.preventDefault(event);
      T1.EventHub.publish("pixel:hideDetails");
    },
    searchPixels: function (data) {
      var term = data.term;
      if (term !== this.previousSearchKey) {
        this.previousSearchKey = term;
        this.searchTerm = term;
        this.search();
      }
    },
    serialize: function () {
      var pixelTypeLbl;
      if (this.searchFilters) {
        switch (this.searchFilters.type) {
          case "event":
            pixelTypeLbl = " Event Pixels";
            break;
          case "data":
            pixelTypeLbl = " Data Pixels";
            break;
          case "segment":
          case "dynamic":
            pixelTypeLbl = " Segments";
            break;
        }
      } else {
        pixelTypeLbl = " Merit Pixels";
      }
      return {
        list_size_options: this.list_size_options.options,
        pixel_type: pixelTypeLbl
      };
    }
  });
});
