define('modules/strategy/views/duplicateStrategy',["jQuery", "T1", "T1View", "T1Layout", "text!modules/strategy/templates/duplicateStrategy.html", "jQPlugins/jquery.columizer.min", "T1DatePicker"], function ($, T1, T1View, T1Layout, template) {
  "use strict";

  var DuplicateStrategyView = T1View.extend({
    template: template,
    status: 0,
    events: {
      "click .w-foot .actions .ui-cancel": "closeDialog",
      "click .w-foot .actions .ui-submit": "duplicateStrategy",
      "click .duplicate-background": "closeDialog"
    },
    settings: {
      success: $.noop,
      closeDialogOnComplete: true,
      dispatchNotification: true,
      loadDuplicatedStrategy: true
    },
    initialize: function (args) {
      if (args) {
        $.extend(this.settings, args);
      }
      this.initLayout();
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".strategy-settings-container",
        template: '<div class="strategy-settings"></div>',
        layout: {
          ".strategy-settings": [{
            module: "strategy",
            viewType: "duplicateStrategySettings",
            options: {
              model: this.model
            }
          }]
        }
      });
    },
    load: function () {
      if (!this.el.hasClass("duplicationDialog")) {
        this.el.addClass("duplicationDialog");
      }
      this.render().then(() => {
        this.el.dialog({
          closeText: "",
          autoOpen: true,
          modal: false,
          width: 830,
          open: function () {
            $(this).closest(".ui-dialog").css("overflow", "visible");
          }
        }).position({
          my: "center",
          at: "center",
          of: window
        }).css("visibility", "visible");
        this.layout.load();
      });
    },
    closeDialog: function (e) {
      if (e) {
        e.preventDefault();
        this.el.dialog("close");
      }
      this.el.dialog("destroy");
      this.el.remove();
      this.unload();
    },
    duplicateStrategy: function () {
      const formValues = T1.Form.dataAsObject(this.$(".form-body").not(this.$('[data-bind="ignore"]')));
      formValues.use_campaign_start = formValues.use_campaign_start || 0;
      formValues.use_campaign_end = formValues.use_campaign_end || 0;
      this.postData(this.preparePostData(formValues));
    },
    unload: function () {
      this.destroy();
    },
    preparePostData: function (formValues) {
      var strategy = this.model;
      var startDate = `${strategy.startDate.toString("s")} ${strategy.zoneName}`;
      var endDate = `${strategy.endDate.toString("s")} ${strategy.zoneName}`;
      var settingsObj = {};
      $.each(formValues, function (key, value) {
        if (typeof value === "boolean" || typeof value === "undefined") {
          settingsObj[key] = value === true ? "1" : "0";
        }
      });
      $.extend(formValues, {
        start_date: startDate,
        end_date: endDate
      }, settingsObj);
      return formValues;
    },
    postData: function (formValues) {
      var strategy = this.model;
      const self = this;
      const loader = this.loader();
      this.toggleLoadingState(loader, true);
      strategy.duplicateStrategy(formValues, {
        success: data => {
          this.toggleLoadingState(loader, false);
          if (this.settings !== undefined) {
            this.renderPartial(".form-footer .messages", {
              success: true
            });
            if (this.settings.dispatchNotification) {
              T1.Notify("message", `${strategy.get("name")} was duplicated successfully!`);
            }
            if (this.settings.loadDuplicatedStrategy) {
              T1.Location.set(`#strategy/edit/${data.entity.id}`);
            }
            if (this.settings.closeDialogOnComplete) {
              this.closeDialog();
            }
            this.settings.success(data.entity);
          }
        },
        invalid: function (errors) {
          if (errors.length) {
            self.toggleLoadingState(loader, false, true);
            self.showErrors(strategy, errors, ".form-body");
          }
        }
      });
    },
    toggleLoadingState: function (loader, isLoading, isError) {
      if (isLoading) {
        loader.start();
        this.$(".w-head, .form-body").css({
          opacity: .05
        }).disableSelection();
        this.$(".form-body :input").attr("disabled", true);
        this.$(".ui-submit").addClass("disabled");
      } else {
        loader.stop();
        if (isError) {
          this.$(".w-head, .form-body").css({
            opacity: 1
          }).enableSelection();
          this.$(".form-body :input").attr("disabled", false);
          this.$(".ui-submit").removeClass("disabled");
        }
      }
    },
    serialize: function () {
      var model = this.model;
      var campaign = model.get("campaign");
      var modelJson = model.toJSON();
      var obj = {
        campaignName: campaign.get("name"),
        advertiserName: campaign.get("advertiser").get("name")
      };
      var returnObj = $.extend(obj, modelJson);
      return returnObj;
    }
  });
  DuplicateStrategyView = T1.Form.ViewUtils.extend(DuplicateStrategyView);
  return $.extend(DuplicateStrategyView, {
    showDialog: function (originalConfigs) {
      let configs = {
        el: originalConfigs.el || $("<div></div>")
      };
      configs = $.extend({}, originalConfigs, configs);
      const duplicateDialog = new DuplicateStrategyView(configs);
      duplicateDialog.load();
      return duplicateDialog;
    }
  });
});
