define('modules/creatives/bulkedit/views/changeGrid',["jQuery", "T1View", "text!../templates/change_grid_item.html", "T1GridView", "utils/FieldMap", "modules/creatives/shared/creativesMap", "modules/creatives/shared/videosMap"], function ($, T1View, gridItem, T1GridView, FieldMap, creativesMap, videosMap) {
  "use strict";

  var BulkEditGrid = T1View.extend({
    partials: {
      listItem: gridItem
    },
    headers: [{
      name: "field",
      label: "Field"
    }, {
      name: "value",
      label: "New Value"
    }],
    initialize: function (args) {
      this.formValues = args.formValues;
      this.entityType = args.entityType;
      this.fieldMap = this.entityType === "video" ? new FieldMap(videosMap) : new FieldMap(creativesMap);
    },
    load: function () {
      this.render();
    },
    serialize: function () {
      var formValues = this.formValues;
      var fieldMap = this.fieldMap;
      var items = [];
      var item;
      for (var field in formValues) {
        item = fieldMap.translate(field, formValues[field]);
        items.push(item);
      }
      return {
        list: items
      };
    }
  });
  return T1GridView(BulkEditGrid);
});
