define('modules/eventTracking/views/list',["jQuery", "Underscore", "T1", "T1View", "text!../templates/list.html", "text!../templates/item.html", "collections/vendors", "collections/trackingEvents", "../models/eventTypes", "T1Form", "T1Tooltip"], function ($, _, T1, T1View, template, itemTemplate, Vendors, TrackingEvents, eventTypes) {
  var listView = T1View.extend({
    template: template,
    partials: {
      item: itemTemplate
    },
    eventhubEvents: {
      "skippable:change": function (data) {
        this.isSkippable = data.isSkippable;
        this.load();
      }
    },
    initialize: function (args) {
      if (args.collection) {
        this.collection = args.collection;
      } else {
        this.collection = new TrackingEvents();
      }
      this.isSkippable = args.isSkippable !== undefined ? args.isSkippable : false;
    },
    events: {
      "click .add-tracking-event span": "addItem",
      "click .remove": "removeItem",
      "changed .url strand-input": "changeUrl"
    },
    formChangeHandler: function (trackingEvent) {
      if (trackingEvent.get("action") === "new") {
        delete trackingEvent.attributes.action;
        $(this.el).find(".tracking-event").last().find(".add-tracking-event").removeClass("hidden");
      }
      this.isFormElementsChanged = true;
    },
    addItem: function (event) {
      var collection = this.collection;
      var $currentTarget = $(event.currentTarget);
      var dataIndex = parseInt($currentTarget.closest(".tracking-event").attr("data-index"));
      var model;
      $currentTarget.closest(".add-tracking-event").addClass("hidden");
      model = new collection.model({
        action: "new"
      });
      collection.add(model);
      this.renderPartialTemplate({
        template: itemTemplate,
        data: this.mapItem(model),
        action: "append",
        targetEl: this.el.find(".tracking-event-list"),
        useTemplateHtml: false,
        skipDelegateEvents: true
      });
      $(this.el).find(".tracking-event[data-id='" + (model.id || model.cid) + "']").attr("data-index", dataIndex + 1);
      this.applySelectBoxes($(this.el).find(".tracking-event[data-id='" + (model.id || model.cid) + "']"));
      this.isFormElementsChanged = true;
    },
    removeItem: function (event) {
      var collection = this.collection;
      var target = event.currentTarget;
      var $trackingEvent = $(target).closest(".tracking-event");
      var $trackingEventList = $trackingEvent.closest(".tracking-event-list");
      var id = $trackingEvent.attr("data-id");
      var eventModel = collection.get(id);
      var $events;
      $trackingEvent.remove();
      if (eventModel) {
        eventModel.set({
          action: "delete"
        });
      } else {
        eventModel = collection.getByCid(id);
        if (eventModel.id) {
          eventModel.set({
            action: "delete"
          });
        } else {
          collection.remove(eventModel);
        }
      }
      $events = $trackingEventList.find(".tracking-event");
      if ($events.length) {
        $events.last().find(".add-tracking-event").removeClass("hidden");
      } else {
        collection.add({
          action: "new"
        });
        this.load();
      }
      this.isFormElementsChanged = true;
    },
    changeUrl: function (event) {
      var id = $(event.currentTarget).closest(".tracking-event").attr("data-id");
      var collection = this.collection;
      var model = collection.get(id) || collection.getByCid(id);
      var val = $(event.currentTarget).val();
      model.set({
        url: val.replace("^", "%5E")
      });
      if (!model.get("typ") && val === "") {
        delete model.attributes.url;
        model.set({
          action: "new"
        });
        return;
      }
      this.formChangeHandler(model);
    },
    applySelectBoxes: function ($scope) {
      var self = this;
      $scope.find("strand-dropdown").on("selected", function (e) {
        var id = $(e.currentTarget).closest(".tracking-event").attr("data-id");
        var collection = self.collection;
        var model = collection.get(id) || collection.getByCid(id);
        model.set({
          typ: $(e.currentTarget).val()
        });
        self.formChangeHandler(model);
      });
    },
    canUnload: function () {
      var self = this;
      var isFormElementsChanged = self.isFormElementsChanged;
      self.isFormElementsChanged = false;
      return !isFormElementsChanged;
    },
    load: function () {
      var self = this;
      var collection = this.collection;
      this.isFormElementsChanged = false;
      if (!collection.length) {
        collection.add(new collection.model({
          action: "new"
        }));
      }
      this.render().then(function listViewPostRender() {
        var $el = $(self.el);
        T1.Form.Masks.attach($("strand-input", $el));
        self.applySelectBoxes.call(self, $el);
        T1.Tooltip($el, {
          offset: 5,
          delayIn: "100",
          delayOut: "100"
        });
      });
    },
    mapItem: function (model) {
      var self = this;
      var skippableList = ["skip"];
      var type = model.get("typ");
      var url = model.get("url");
      var trackingEvent, sources;
      sources = _.chain(eventTypes).map(function (item) {
        if (self.isSkippable || skippableList.indexOf(type) > -1 && url !== undefined && !self.isVast) {
          return item;
        } else {
          if (skippableList.indexOf(item.value) === -1) {
            if (skippableList.indexOf(type) > -1) {
              model.unset("typ");
            }
            return item;
          }
        }
      }).compact().value();
      trackingEvent = {
        id: model.id || model.cid,
        sources: sources,
        placeholder: "Select One",
        url: url,
        typ: type
      };
      if (model.get("action") !== "delete") {
        return trackingEvent;
      }
    },
    serialize: function () {
      var collection = this.collection;
      var trackingEvents = [];
      var self = this;
      var mappedModel;
      var index = 0;
      var length = collection.length;
      $.each(collection.models, function (i, model) {
        if (model.get("action") !== "delete") {
          mappedModel = self.mapItem.call(self, model);
          mappedModel.index = index++;
          if (i === length - 1) {
            mappedModel.last = true;
          }
          trackingEvents.push(mappedModel);
        }
      });
      return {
        trackingEvents: trackingEvents
      };
    }
  });
  return listView;
});
