define('models/vendorContract',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  T1.Models.VendorContract = T1Model.extend({
    url: "vendor_contracts/",
    defaults: {
      use_mm_contract: "1"
    },
    validateAttrs: function (attrs) {
      var errors = [];
      var requiredField = "This is a required field.";
      var requiredFields = ["version", "campaign_id", "rate_card_type", "vendor_id", "use_mm_contract", "price"];
      $.each(requiredFields, function (key, value) {
        if (attrs[value] === undefined || attrs[value] === "") {
          errors.push({
            field: value,
            message: requiredField
          });
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var permissionDerferred = $.Deferred();
      permissionDerferred.resolve(true);
      return permissionDerferred.promise();
    },
    drop: function (successHandler, errorHandler) {
      if (this.id) {
        this.save({
          id: this.id,
          version: this.get("version")
        }, {
          action: "delete",
          success: successHandler || $.noop,
          conflict: errorHandler || $.noop
        });
      } else {
        successHandler();
      }
    },
    fixture: "-vendor"
  });
  return T1.Models.VendorContract;
});
