define('modules/campaign/bulkedit/targeting/mydata/views/readOnly',["jQuery", "Underscore", "T1", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils"], function ($, _, T1, T1View, template, Utils) {
  "use strict";

  var readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function ({
      model: model,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit
    }) {
      this.strategy = model;
      this.canLoad = false;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.pixelTitle = {
        DATA: "DATA PIXELS",
        EVENT: "EVENT PIXELS",
        DYNAMIC: "ADAPTIVE SEGMENTS"
      };
      this.strategy.fetchPixelTargets(data => {
        this.canLoad = true;
        this.collection = data;
        this.load();
      }, false);
    },
    load: function () {
      this.render().then(() => {
        const loader = this.loader();
        if (!this.canLoad || this.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
      });
    },
    serialize: function () {
      const collObj = this.collection;
      var resultJSON = {
        dataMain: []
      };
      var groupedDataHashInclude = {
        DATA: {},
        EVENT: {},
        SEGMENT: {},
        CREATIVE: {},
        INVALID: {},
        DYNAMIC: {}
      };
      var groupedDataHashExclude = {
        DATA: {},
        EVENT: {},
        SEGMENT: {},
        CREATIVE: {},
        INVALID: {},
        DYNAMIC: {}
      };
      var formatNumber = T1.Formats.rolledUpNumbersShort();
      var createDataBlock = Utils.createGroupedDataArray;
      var hasData = false;
      var getGroupNameFromModel = Utils.getGroupNameFromPixelModel;
      var getLoadCountFromModel = function (model, key) {
        const report = model.get("unique");
        if (report) {
          return formatNumber(parseInt(report[key], 10));
        }
        return "--";
      };
      var getAndOrOperation = function () {
        var defaultVal = "or";
        var result = defaultVal;
        if (collObj) {
          result = collObj.includeOperator;
          if (!result) {
            result = defaultVal;
          }
        }
        return `(${result})`;
      };
      var includedHeaderAdded = false;
      var excludedHeaderAdded = false;
      const includeTitle = {
        sideTitle: `included data<br/>${getAndOrOperation()}`,
        data: false
      };
      const excludeTitle = {
        sideTitle: "excluded data",
        data: false
      };
      function populateHash(model) {
        const key = this;
        const tmpObj = model.toJSON();
        const pixelType = model.get("pixel_type");
        const type = pixelType ? pixelType.toUpperCase() : "INVALID";
        const hashDataSet = model.get("action") === "include" ? groupedDataHashInclude[type] : groupedDataHashExclude[type];
        if (!hashDataSet[key]) {
          hashDataSet[key] = [];
        }
        if (pixelType === "dynamic") {
          hashDataSet[key].push({
            name: tmpObj.name,
            load_count: "N/A",
            pixel_id: tmpObj.id
          });
        } else {
          hashDataSet[key].push({
            name: tmpObj.name,
            load_count: getLoadCountFromModel(model, "loads"),
            unique_count: getLoadCountFromModel(model, "uniques"),
            pixel_id: tmpObj.id
          });
        }
      }
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/myData`;
      }
      if (!collObj || !collObj.models || !collObj.models.length) {
        this.hasData = false;
        return resultJSON;
      }
      const groupedCollObj = _.groupBy(collObj.models, model => getGroupNameFromModel(model));
      Object.entries(groupedCollObj).forEach(function ([key, modelArr]) {
        modelArr.forEach(populateHash, key);
      });
      function populateData(mode, hash) {
        Object.entries(hash).forEach(([key, itemArr]) => {
          const data = createDataBlock(itemArr);
          const sideTitle = this.pixelTitle[key] || key;
          if (data.length) {
            hasData = true;
            if (mode === "include") {
              if (!includedHeaderAdded) {
                includedHeaderAdded = true;
                resultJSON.dataMain.push(includeTitle);
              }
            } else {
              if (!excludedHeaderAdded) {
                excludedHeaderAdded = true;
                resultJSON.dataMain.push(excludeTitle);
              }
            }
            resultJSON.dataMain.push({
              sideTitle: sideTitle,
              data: data
            });
          }
        });
      }
      populateData.call(this, "include", groupedDataHashInclude);
      populateData.call(this, "exclude", groupedDataHashExclude);
      this.hasData = resultJSON.hasData = hasData;
      if (resultJSON.hasData) {
        resultJSON.firstSideTitle = resultJSON.dataMain[0].sideTitle;
        resultJSON.dataMain[0].sideTitle = "";
      }
      return resultJSON;
    }
  });
  return readOnlyView;
});
