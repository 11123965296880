define('modules/strategy/supply/pmpe/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "strategy/supply/pmpe",
    viewCfgs: {
      activeState: {},
      panel: {}
    },
    defaultView: "panel"
  });
});
