define('modules/creatives/componentBased/lib/unzipper',["jQuery", "Underscore", "When", "zipUtil"], function ($, _, When) {
  "use strict";

  zip.workerScriptsPath = "./js/libs/zipJs/";
  function decompressEntry(entry) {
    const entryDeferred = When.defer();
    const regex = /\.[0-9a-z]+$/;
    const regexResult = entry.filename.match(regex);
    const fileExt = regexResult ? regexResult[0] : null;
    const videoExtensions = [".3g2", ".3gp", ".3gpp", ".asf", ".avi", ".dat", ".divx", ".dv", ".f4v", ".flv", ".m2ts", ".m4v", ".mkv", ".mod", ".mov", ".mp4", ".mpe", ".mpeg", ".mpeg4", ".mpg", ".mts", ".nsv", ".ogm", ".ogv", ".qt", ".tod", ".ts", ".vob", ".wmv"];
    entry.isVideo = fileExt && videoExtensions.includes(fileExt);
    if (entry.compressionMethod === 0 || entry.filename.match(/DS_Store/) || entry.filename.match("__MACOSX")) {
      entryDeferred.resolve(false);
    } else if (entry.compressionMethod === 8) {
      if (entry.isVideo) {
        entry.getData(new zip.BlobWriter(), function (data) {
          entryDeferred.resolve({
            data: data,
            entryMetadata: entry
          });
        });
      } else {
        entry.getData(new zip.Data64URIWriter(), function (data) {
          entryDeferred.resolve({
            data: data,
            entryMetadata: entry
          });
        });
      }
    }
    return entryDeferred.promise;
  }
  var unzipper = {
    getData: function (imageFile) {
      var d = When.defer();
      zip.createReader(new zip.BlobReader(imageFile), function (reader) {
        function decompressEntries(entries) {
          var zipReaderPromises = [];
          if (entries.length > 0) {
            for (var i = 0; i < entries.length; i++) {
              zipReaderPromises.push(decompressEntry(entries[i]));
            }
          }
          return When.settle(zipReaderPromises).then(function (result) {
            return d.resolve(result);
          });
        }
        reader.getEntries(decompressEntries);
      });
      return d.promise;
    }
  };
  return unzipper;
});
