define('modules/T1App/edit/version/views/version',["jQuery", "Underscore", "T1", "T1View", "T1Comm", "text!modules/T1App/edit/version/templates/version.html", "text!templates/form_footer.html", "text!modules/T1App/edit/version/templates/versionList.html", "../../models/versionModel", "../models/versionModel", "collections/t1AppVersion", "collections/t1AppEdit", "When", "T1Signature", "T1Selectbox"], function ($, _, T1, T1View, T1Comm, template, form_footer, VersionListTemplate, Model, VersionModel, T1AppsVersion, T1AppEditCollection, When) {
  "use strict";

  var MetaVersionCreateEdit;
  var T1Location = T1.Location;
  MetaVersionCreateEdit = T1View.extend({
    isEdit: false,
    template: template,
    partials: {
      footer: form_footer
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveOverride",
        "keyup .release-title-input": "releaseTitleCounter",
        "focusout .release-title-input": "hideReleaseTitleCounter"
      },
      formActions: [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#appstore/myApps"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: false
      }, {
        label: "save & Continue",
        uuid: "save_close",
        class: "saveAndClose save-btn-primary",
        location: false
      }]
    },
    events: {
      "click .history-item": "showVersion",
      "click .radio": "changeVersion"
    },
    fetchOptions: {
      appID: null,
      precision: 2,
      urlString: ""
    },
    initialize: function () {
      var locationHashArray = T1Location.get(true).split("/");
      var appID = locationHashArray[2];
      this.fetchOptions.appID = appID;
      this.model = new Model();
      this.versionModel = new VersionModel();
      this.versionCollection = new T1AppEditCollection();
      this.canSave = true;
      this.canLoad = false;
      this.firstPost = true;
      this.tempName = "";
      this.initializeData({
        id: this.fetchOptions.appID
      });
    },
    initializeData: function (args) {
      var self = this;
      this.versionCollection.fetch({
        appID: args && args.id || self.fetchOptions.appID,
        success: function (data) {
          if (data.meta === "success") {
            self.model.setVersionData(data);
            self.fetchVersioning();
          } else {
            T1.Location.set("#appstore");
          }
        }
      });
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      if (this.canLoad) {
        this.render().then(function () {
          self.updateVersionList();
          self.releaseTitle = $(".release-title-input", $el).val();
          self.releaseNotes = $(".release-notes-input", $el).val();
          T1.resizeWidth($el);
        });
      }
    },
    reload: function () {
      this.initializeData();
    },
    fetchVersioning: function () {
      var self = this;
      var locationHashArray = T1Location.get(true).split("/");
      var appID = locationHashArray[2];
      if (!$.isNumeric(appID)) {
        T1.Location.set("#appstore");
      }
      this.collection = new T1AppsVersion();
      this.fetchOptions.appID = appID;
      this.collection.fetch({
        appID: this.fetchOptions.appID,
        success: function (data) {
          if (data.meta.status === "success") {
            self.versionModel.setVersionData(data);
            self.canLoad = true;
            self.load();
          }
        }
      });
    },
    updateVersionList: function () {
      var self = this;
      var $el = $(this.el);
      var versionListData = this.versionModel.get("versionData") || false;
      self.renderPartialTemplate({
        template: VersionListTemplate,
        useTemplateHtml: false,
        action: "html",
        targetEl: ".history-container",
        data: {
          versionListData: versionListData
        }
      });
      $(".history-main", $el).css("height", function (i, h) {
        $(this, $el).hide();
        return h;
      });
    },
    showVersion: function (e) {
      var $ct = $(e.currentTarget);
      var text = $ct.children(".history-main");
      var arrow = $ct.children(".arrow-icon");
      if (text.is(":visible")) {
        $(text).slideUp();
        $(arrow).removeClass("arrow-down");
      } else {
        $(text).slideDown();
        $(arrow).addClass("arrow-down");
      }
    },
    versionChange: function (currentVersion, versionType) {
      var splitVersion = currentVersion.split(".");
      var nextMajor = parseInt(splitVersion[0]) + parseInt(1);
      var nextMinor = parseInt(splitVersion[1]) + parseInt(1);
      var nextPatch = parseInt(splitVersion[2]) + parseInt(1);
      var nextVersion;
      if (versionType === "patch") {
        nextVersion = splitVersion[0] + "." + splitVersion[1] + "." + nextPatch;
      } else if (versionType === "minor") {
        nextVersion = splitVersion[0] + "." + nextMinor + ".0";
      } else if (versionType === "major") {
        nextVersion = nextMajor + ".0.0";
      } else {
        nextVersion = currentVersion;
      }
      return nextVersion;
    },
    changeVersion: function (e) {
      var $el = $(this.el);
      var $ct = $(e.currentTarget);
      var currentVersion = $("#versionSet").data("version");
      var nextVersion = $ct.attr("value");
      var versionChange = this.versionChange(currentVersion, nextVersion);
      $(".release-title-input, .release-notes-input", $el).val("");
      if (versionChange !== currentVersion) {
        $("#versionSet", $el).text(versionChange);
      } else {
        $("#versionSet", $el).text(currentVersion);
        $(".release-title-input", $el).val(this.releaseTitle);
        $(".release-notes-input", $el).val(this.releaseNotes);
      }
      this.firstChange = false;
    },
    releaseTitleCounter: function () {
      var $el = $(this.el);
      var totalChar = $(".release-title-input", $el).val().replace(/\n/g, "\r\n").length;
      $(".release-title-counter", $el).text(40 - totalChar);
    },
    hideReleaseTitleCounter: function () {
      var $el = $(this.el);
      $(".release-title-counter", $el).text("");
    },
    saveOverride: function (e, customCallbacks) {
      var callback = customCallbacks || {
        success: function () {
          T1.Notify("message", "App updated successfully!");
        }
      };
      if (this.canSave) {
        return this.saveOriginal(e, callback);
      }
    },
    saveAndClose: function (e) {
      var self = this;
      var app_id;
      var callback = {
        success: function () {
          app_id = self.model.get("app_id");
          T1.Notify("message", "App updated successfully!");
          setTimeout(function () {
            T1.Location.set("#appstore/edit/" + app_id + "/team");
          }, 1e3);
        }
      };
      if (this.canSave) {
        return this.saveOriginal(e, callback);
      }
    },
    postData: function (formValues, success, statusInvalid, conflict) {
      var $el = $(this.el);
      var model = this.model;
      var appID = T1.Location.getRoute().id;
      var releaseData = this.versionModel.get("releaseData");
      var self = this;
      var newVersionID;
      var queue = [self.validateAttrs(formValues)];
      var settled = When.settle(queue);
      var errors = [];
      var deployValues;
      newVersionID = parseInt(formValues.version);
      settled.then(function (descriptors) {
        _.each(descriptors, function (d) {
          if (d.state === "rejected") {
            errors = errors.concat(d.reason);
          }
        });
        if (errors && errors.length) {
          statusInvalid(errors);
          $(".appDetailsForm", $el).stopSpinner();
        } else {
          model.unset("data");
          model.unset("description");
          model.set({
            app_id: appID,
            version: model.get("version") || version,
            url: formValues.app_url,
            status: formValues.status,
            deploy_status: formValues.deploy_status,
            release_title: formValues.release_title,
            release_notes: formValues.release_notes,
            version_type: formValues.version_type,
            app_url: T1.API_APPS_ROOT + "v1.0/apps/" + appID,
            deploy_url: T1.API_APPS_ROOT + "v1.0/apps/" + appID + "/deploy",
            app_version_url: T1.API_APPS_ROOT + "v1.0/app-versions",
            app_version_edit_url: T1.API_APPS_ROOT + "v1.0/app-versions/" + parseInt(releaseData[0].appVersionID),
            url1: true,
            url2: false,
            url3: false,
            url4: false
          });
          model.set({
            lab_temp: model.get("lab") ? model.get("lab") : model.get("lab_temp")
          });
          model.unset("lab");
          self.tempName = model.get("name");
          model.unset("name");
          model.createEdit(model.toJSON(), {
            statusInvalid: statusInvalid,
            conflict: conflict,
            success: success
          }).pipe(function () {
            var def = $.Deferred();
            var saveOpts = {
              dataType: "json",
              stringify: true,
              contentType: "application/json",
              processData: false,
              success: function (data, response) {
                $("#version", $el).val(response.data[0].version);
                model.set({
                  newVersionID: parseInt($("#version").val())
                });
                def.resolve();
              },
              error: function (data, responses) {
                var errors = $.parseJSON(responses);
                var err = [];
                var errMsg;
                var addError = function (fld, errMsg) {
                  err.push({
                    field: fld,
                    message: errMsg
                  });
                };
                var notify = function (msg) {
                  T1.Notify("error", msg);
                };
                statusInvalid(errors, "There are errors on the page.", ".details");
                $.each(errors.errors, function (index, value) {
                  errMsg = value.message;
                  switch (value.field) {
                    case "api_version":
                      notify("API version does not exist or is deprecated.");
                      break;
                    case "json_name":
                      addError("name", "An existing app already uses this name.");
                      break;
                    case "url":
                      addError("app_url", "Please provide a valid secure URL e.g. https://www.mediamath.com.");
                      break;
                    case "version":
                      notify("Version Conflict.");
                      break;
                    default:
                      notify(errMsg);
                  }
                });
                statusInvalid(err);
              },
              processAjaxResponse: model.processAjaxResponse
            };
            model.save(model.toJSON(), saveOpts);
            return def.promise();
          }).then(function () {
            model.set({
              url1: false,
              url2: true,
              version: parseInt(newVersionID) + 1
            });
            model.createEdit(model.toJSON(), {
              statusInvalid: statusInvalid,
              conflict: conflict,
              success: success
            }).pipe(function () {
              var def = $.Deferred();
              var saveOpts = {
                dataType: "json",
                stringify: true,
                contentType: "application/json",
                processData: false,
                success: function (data, response) {
                  $("#version", $el).val(response.data[0].version);
                  self.model.set({
                    newVersionID: parseInt($("#version", $el).val())
                  });
                  def.resolve();
                },
                error: function (data, responses) {
                  var errors = $.parseJSON(responses);
                  var err = [];
                  var errMsg;
                  var addError = function (fld, errMsg) {
                    err.push({
                      field: fld,
                      message: errMsg
                    });
                  };
                  var notify = function (msg) {
                    T1.Notify("error", msg);
                  };
                  statusInvalid(errors, "There are errors on the page.", ".details");
                  $.each(errors.errors, function (index, value) {
                    errMsg = value.message;
                    switch (value.field) {
                      case "api_version":
                        notify("API version does not exist or is deprecated.");
                        break;
                      case "deploy_status":
                        addError("deploy_status", errMsg);
                        break;
                      case "status":
                        addError("status", errMsg);
                        break;
                      case "version":
                        notify("Version Conflict.");
                        break;
                      case "learn_more_image":
                        notify("Please upload a learn more image on the Meta tab.");
                        break;
                      case "icon":
                        notify("Please upload an icon on the Meta tab.");
                        break;
                      default:
                        notify(errMsg);
                    }
                  });
                  statusInvalid(err);
                },
                processAjaxResponse: model.processAjaxResponse
              };
              model.save(model.toJSON(), saveOpts);
              return def.promise();
            }).then(function () {
              if (formValues.version_type === undefined || formValues.version_type === "") {
                model.set({
                  url2: false,
                  url4: true,
                  version: parseInt(releaseData[0].appVersionVersion),
                  description: formValues.release_notes ? formValues.release_notes : "null",
                  title: formValues.release_title,
                  app_id: parseInt(appID)
                });
              } else {
                model.set({
                  url2: false,
                  url3: true,
                  description: formValues.release_notes ? formValues.release_notes : "null",
                  title: formValues.release_title,
                  app_id: parseInt(appID),
                  version_id: self.versionChange(releaseData[0].versionID, formValues.version_type)
                });
              }
              deployValues = JSON.stringify(deployValues);
              model.createEdit(formValues, {
                statusInvalid: statusInvalid,
                conflict: conflict,
                success: success
              }).pipe(function () {
                var def = $.Deferred();
                var saveOpts = {
                  dataType: "json",
                  stringify: true,
                  contentType: "application/json",
                  processData: false,
                  success: function (data, response) {
                    $("#versionNoChange", $el).prop("checked", "checked");
                    $("#versionSet", $el).data("version", response.data[0].version_id);
                    self.model.set({
                      version: parseInt($("#version", $el).val(), 10)
                    });
                    self.fetchVersioning();
                    success(data);
                    def.resolve();
                  },
                  error: function (data, responses) {
                    var errors = $.parseJSON(responses);
                    var err = [];
                    var errMsg;
                    var addError = function (fld, errMsg) {
                      err.push({
                        field: fld,
                        message: errMsg
                      });
                    };
                    var notify = function (msg) {
                      T1.Notify("error", msg);
                    };
                    statusInvalid(errors, "There are errors on the page.", ".details");
                    $.each(errors.errors, function (index, value) {
                      errMsg = value.message;
                      switch (value.field) {
                        case "api_version":
                          notify("API version does not exist or is deprecated.");
                          break;
                        case "json_name":
                          addError("name", "An existing app already uses this name.");
                          break;
                        case "url":
                          addError("app_url", "Please provide a valid secure URL e.g. https://www.mediamath.com.");
                          break;
                        case "version":
                          notify("Version Conflict.");
                          break;
                        default:
                          notify(errMsg);
                      }
                    });
                    statusInvalid(err);
                  },
                  processAjaxResponse: model.processAjaxResponse
                };
                model.save(model.toJSON(), saveOpts);
                return def.promise();
              });
            });
            self.firstPost = false;
          });
          model.set({
            name: self.tempName
          });
        }
      });
    },
    validateAttrs: function (attrs) {
      var deferred = When.defer();
      var errors = [];
      var requiredField = "This is a required field.";
      var validationResult;
      var optionalFields = ["release_notes", "version_type"];
      var reqFields = {
        release_title: function () {
          return !attrs.release_title;
        },
        app_url: function () {
          return !attrs.app_url;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (errors && errors.length) {
        deferred.reject(errors);
      } else {
        deferred.resolve(true);
      }
      return deferred.promise;
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var title = this.model.get("name");
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: title
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    serialize: function () {
      var self = this;
      var output = this.model.toJSON();
      var releaseData = this.versionModel.get("releaseData") || false;
      var versionData = this.versionModel.get("versionData") || false;
      var appData = this.model;
      output.data_version = releaseData.length > 0 ? releaseData[0].versionID : "0.0.0";
      output.release_title = releaseData.length > 0 ? releaseData[0].releaseTitle : "";
      output.release_notes = releaseData.length > 0 && releaseData[0].releaseNote !== "null" ? releaseData[0].releaseNote : "";
      output.version_data = versionData;
      output.isEdit = this.isEdit;
      output.disableStatus = "disabled";
      output.lab = appData.get("lab_temp") ? appData.get("lab_temp") : appData.get("lab");
      if (output.lab) {
        if (appData.get("description") && appData.get("learn_more_html") && appData.get("support_email") && appData.get("url") && appData.get("learn_more_link")) {
          output.disableStatus = "";
        }
      } else {
        if (appData.get("description") && appData.get("learn_more_html") && appData.get("support_email") && appData.get("url") && appData.get("learn_more_link") && appData.get("icon") && appData.get("learn_more_image")) {
          output.disableStatus = "";
        }
      }
      return output;
    }
  });
  MetaVersionCreateEdit = T1.Form.ViewUtils.extend(MetaVersionCreateEdit);
  MetaVersionCreateEdit.prototype.saveOriginal = MetaVersionCreateEdit.prototype.save;
  MetaVersionCreateEdit.prototype.save = MetaVersionCreateEdit.prototype.saveOverride;
  return MetaVersionCreateEdit;
});
