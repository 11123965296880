define('modules/targeting/siteList/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "utils/CampaignStrategyUtils", "text!modules/targeting/siteList/templates/panel.html", "text!templates/form_footer.html"], function ($, _, T1, T1View, T1Layout, CampaignStrategyUtils, template, form_footer) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var SiteListPanel = T1View.extend({
    template: template,
    viewMode: "browse",
    partials: {
      form_footer: form_footer
    },
    events: {
      "focus .text": "showTooltip",
      "blur .text": "hideTooltip",
      "keydown .text": "removeTooltip"
    },
    eventhubEvents: {
      "exclusive-checkbox:click": function () {
        this.isFormElementsChanged = true;
      }
    },
    dataEvents: {
      domainRestrictions: {
        reset: function () {
          if (this.readyToRegisterFormChangedEvent && this.hasInitDomainRestrictionsReset) {
            this.isFormElementsChanged = true;
          }
          this.hasInitDomainRestrictionsReset = true;
        }
      },
      sharedCollection: {
        add: function () {
          if (this.readyToRegisterFormChangedEvent) {
            this.isFormElementsChanged = true;
          }
        },
        remove: function () {
          if (this.readyToRegisterFormChangedEvent) {
            this.isFormElementsChanged = true;
          }
        },
        reset: function () {
          if (this.readyToRegisterFormChangedEvent) {
            this.isFormElementsChanged = false;
          }
          this.strategy.getOrgId().then(orgId => {
            this.orgId = orgId;
            if (this.initLayoutDone) {
              if (this.sharedCollection.loaded) {
                this.appendBin();
              }
              return;
            }
            this.initLayout();
            this.layout.load();
            this.initLayoutDone = true;
            if (this.sharedCollection.loaded) {
              this.appendBin();
              this.appendGrid();
              this.readyToRegisterFormChangedEvent = true;
            }
          });
        }
      }
    },
    initialize: function ({
      strategy: strategy
    }) {
      this.strategy = strategy;
      this.loadData();
      this.initLayout();
    },
    load: function () {
      this.hasInitDomainRestrictionsReset = false;
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        this.isFormElementsChanged = false;
        T1.EventHub.publish("strategy-badges-edit:placementCheck", {
          currentView: "siteList_targeting"
        });
        this.layout.load();
      });
      if (this.unloaded) {
        this.unloaded = false;
        this.loadData();
      }
      if (window.hybridWorkflowParams.isHybrid) {
        updateCancelBtn(this.strategy.get("campaign"));
        this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
        this.$cancelBtn = $(".cancelNewT1");
      }
    },
    unload: function () {
      this.unloaded = true;
      this.initLayoutDone = false;
      this.readyToRegisterFormChangedEvent = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    loadData: function () {
      this.sharedCollection = this.strategy.fetchSiteLists();
      this.domainRestrictions = this.strategy.fetchDomainRestrictions();
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: '<div class="column-A"></div><div class="bins-column"></div>'
      });
    },
    appendGrid: function () {
      this.layout.append(".column-A", {
        module: "siteList",
        viewType: "list",
        options: {
          sharedCollection: this.sharedCollection,
          strategy: this.strategy,
          orgId: this.orgId
        }
      });
    },
    appendBin: function () {
      this.layout.append(".bins-column", {
        module: "targeting/siteList",
        viewType: "bins",
        options: {
          sharedCollection: this.sharedCollection,
          strategy: this.strategy,
          domainRestrictions: this.domainRestrictions
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    onDontSave: function () {
      this.sharedCollection.reset();
      this.domainRestrictions.reset();
      this.sharedCollection = this.strategy.fetchSiteLists();
      this.domainRestrictions = this.strategy.fetchDomainRestrictions();
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    postData: function (formValues, successCallback, conflictCallback) {
      let restrictBidVal;
      var self = this;
      var domainRestrictions = this.strategy.domainRestrictions;
      var restrictBidFieldName = "site_restriction_transparent_urls";
      var needToSaveStrategy = false;
      var structToBeSaved = {};
      var saveSiteListOperation = function () {
        self.sharedCollection.saveSiteList(formValues, function () {
          if (domainRestrictions && domainRestrictions.data.count !== undefined) {
            self.strategy.saveDomainRestrictions(domainRestrictions.data, successCallback, conflictCallback);
          } else {
            successCallback();
          }
          T1.EventHub.publish("strategy-create-edit:reloadSubViews");
        }, conflictCallback);
      };
      var hasExclusivenessValChanged = function (restrictVal) {
        var oldRestrictBidVal = self.strategy.get(restrictBidFieldName);
        return !(oldRestrictBidVal === restrictVal);
      };
      const restrictBidCheckObj = this.$(".list-footer .checkbox")[0];
      if (restrictBidCheckObj) {
        restrictBidVal = restrictBidCheckObj.checked === true ? "1" : "0";
        if (hasExclusivenessValChanged(restrictBidVal) === true) {
          needToSaveStrategy = true;
        }
      }
      if (needToSaveStrategy === true) {
        structToBeSaved[restrictBidFieldName] = restrictBidVal;
        this.strategy.save(structToBeSaved, {
          success: function () {
            saveSiteListOperation();
          }
        });
      } else {
        saveSiteListOperation();
      }
    }
  });
  return T1.Form.ViewUtils.extend(SiteListPanel);
});
