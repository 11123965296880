define('modules/campaign/bulkedit/review/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "campaign/bulkedit/review",
    viewCfgs: {
      review: {},
      readOnly: {},
      readOnlyBrowse: {}
    }
  });
});
