define('modules/campaign/pixelSegment/views/grid',["jQuery", "Underscore", "T1", "T1Layout", "T1View", "T1GridView", "utils/CampaignStrategyUtils", "utils/currencyConversionHelper", "modules/targeting/shared/itemSelectionBehavior", "T1Spinner", "collections/audienceProviders", "text!modules/campaign/pixelSegment/templates/grid_item.html", "text!modules/campaign/pixelSegment/templates/grid_segment_footer.html", "text!modules/campaign/pixelSegment/templates/grid_item_segment.html", "text!modules/campaign/pixelSegment/templates/grid_item_dynamic_segment.html"], function ($, _, T1, T1Layout, T1View, createGrid, Utils, CurrencyUtils, ItemSelectionBehavior, Spinner, AudienceProviders, itemTemplate, gridFooter, itemSegmentTemplate, itemDynamicTemplate) {
  "use strict";

  function serializePixel(pixel) {
    const report = pixel.unique;
    pixel.load_count = report && report.loads !== "0" ? formatNumber(parseInt(report.loads, 10)) : "--";
    pixel.uniques_count = report && report.uniques !== "0" ? formatNumber(parseInt(report.uniques, 10)) : "--";
    pixel.refer_url = report && report.referrer !== "" ? report.referrer : "--";
    pixel.status = pixel.eligible === "1" ? "active" : "inactive";
    return pixel;
  }
  const pixelTypMap = Object.freeze({
    audience_segment: "Audience Target",
    custom: "Audience Target",
    data: "Data Pixel",
    dynamic: "Dynamic",
    event: "Event Pixel",
    normal: "Audience Target"
  });
  const formatNumber = T1.Formats.rolledUpNumbersShort();
  const GridView = T1View.extend({
    selected: "",
    partials: {
      listItem: itemTemplate
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    headers: [],
    headers_event_data: [{
      name: "name",
      label: "pixel name"
    }, {
      name: "loads",
      label: "# loads ydy"
    }, {
      name: "uniques",
      label: "# uniques ydy"
    }, {
      name: "referrer",
      label: "top referrer ydy"
    }, {
      name: "pixel id",
      label: "pixel id"
    }],
    headers_dynamic_segment: [{
      name: "name",
      label: "segment name"
    }, {
      name: "id",
      label: "segment id"
    }],
    headers_segment: [{
      name: "name",
      label: "segment"
    }, {
      name: "size",
      label: "size"
    }],
    dataEvents: {
      collection: {
        reset: function () {
          setTimeout($.proxy(this.load, this), 0);
        }
      },
      sharedCollection: {
        add: function (data) {
          this.sharedCollectionAddHandler(data);
        },
        remove: function (data) {
          this.sharedCollectionRemoveHandler(data);
        }
      }
    },
    eventhubEvents: {
      "bins:droppedPixel": function (args) {
        this.droppedPixelInBin(args);
      },
      "pixel:setSearchLoader": function () {
        this.setSearchLoader();
      },
      "pixel:applyFilter": function ({
        type: type
      }) {
        switch (type) {
          case "dynamic":
          case "audience_segments":
            this.viewMode = type;
            this.draggableConfigs.handlerTextEl = ".segment";
            this.headers = type === "audience_segments" ? this.headers_segment : this.headers_dynamic_segment;
            this.partials.listItem = type === "audience_segments" ? itemSegmentTemplate : itemDynamicTemplate;
            this.partials.gridFooter = gridFooter;
            this.isSegment = type === "audience_segments";
            break;
          default:
            this.viewMode = type;
            this.draggableConfigs.handlerTextEl = ".name";
            this.headers = this.headers_event_data;
            this.partials.listItem = itemTemplate;
            this.partials.gridFooter = undefined;
            this.isSegment = false;
        }
      },
      "pixel:onEmptyCollection": function () {
        this.response = true;
      },
      "pixel-in-brain-leave": "unload"
    },
    events: {
      "click .include": "assignPixel",
      "click .item": "toggle"
    },
    initialize: function ({
      collection: collection,
      selectionMode: selectionMode,
      sharedCollection: sharedCollection,
      partialCollection: partialCollection,
      audienceSegmentsCollection: audienceSegmentsCollection,
      campaign: campaign,
      getAdvertiserId: getAdvertiserId,
      getAdvertiserName: getAdvertiserName,
      getAdvertiser: getAdvertiser
    }) {
      this.selectionMode = selectionMode || "includeExclude";
      this.collection = this.pixelCollection = collection;
      this.collectionList = {};
      this.sharedCollection = sharedCollection;
      this.partialCollection = partialCollection;
      this.audienceSegmentsCollection = audienceSegmentsCollection;
      this.campaign = campaign;
      ItemSelectionBehavior.attachBehavior(this);
      this.isEligible = 1;
      this.searchFilters = null;
      this.headers = this.headers_event_data;
      this.response = false;
      if (getAdvertiserId) {
        const adID = getAdvertiserId();
        this.advertiser_id = adID !== "" ? adID : null;
        collection.search.set({
          params: [{
            term: "event",
            searchField: "pixel_type"
          }, {
            term: "1",
            searchField: "eligible"
          }],
          fetchRequired: false
        });
        if (adID) {
          collection.urlFilter.set({
            entity: "advertiser",
            id: adID,
            fetchRequired: true
          });
        }
      }
      if (getAdvertiserName) {
        this.advertiser_name = getAdvertiserName();
      }
      if (getAdvertiser) {
        this.advertiser = getAdvertiser();
      }
      if (this.selectionMode !== "includeExclude") {
        this.events["click .item"] = "selectPixel";
      }
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 100,
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Pixels",
        contentClass: "search-loader loader-overrides",
        action: "append"
      }
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_audience_segment_id",
      selectionAllowableKey: "buyable",
      selectionAllowableValue: "1",
      collectionKey: "collection"
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".name"
    },
    perfectScrollConfig: {
      allowWheelPropagation: false
    },
    selectPixel: function (event) {
      var itemEl = $(event.currentTarget);
      this.preventDefault(event);
      this.$(".item").removeClass("selected");
      itemEl.addClass("selected");
      T1.EventHub.publish("selected:pixel", {
        id: itemEl.data("id"),
        name: itemEl.find(".name .text").text()
      });
    },
    adjustGridSize: function (type) {
      const $pixelGrid = this.$(".content");
      var $content = $pixelGrid.find(".content-holder");
      var isAudience = type === "audience_segments";
      if (isAudience) {
        $pixelGrid.css("top", "52px");
        $content.css("bottom", "20px");
      } else {
        $pixelGrid.css("top", "36px");
        $content.css("bottom", "0px");
      }
    },
    assignPixel: function (event) {
      var currentTarget = $(event.currentTarget);
      var id = currentTarget.closest(".item").data("id");
      var collection = this.collection;
      var item = collection.get(id);
      var jsonData = item.toJSON();
      jsonData.action = currentTarget.data("action");
      this.preventDefault(event);
      if (currentTarget.hasClass("audience")) {
        this.renderPartial(".w-foot", this.getPath(item.get("full_path")));
      }
      this.removeDragClasses();
      this.updateSharedCollection(jsonData, item);
    },
    toggle: function (evt) {
      var item = $(evt.currentTarget);
      var arrow = item.find(".arrow");
      const itemId = String(item.data("id"));
      var iconSpinner = arrow.find(".icon-spinner");
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var paginationContainer = childrenContainer.next();
      var collectionList = this.collectionList;
      if (item.hasClass("audience")) {
        if (!arrow.length || arrow.hasClass("hide")) {
          return;
        }
        this.preventDefault(evt);
        this.renderPartial(".w-foot", this.getPath(item));
        if (arrow.hasClass("expanded")) {
          iconSpinner.spin(false);
          arrow.removeClass("expanded loading");
          childrenContainer.hide();
          paginationContainer.hide();
          this.updateScrollBar();
        } else {
          let collection = collectionList[itemId] || new AudienceProviders([]);
          arrow.addClass("loading expanded hide");
          collectionList[itemId] = collection;
          if (collection.length && childrenContainer.children().length) {
            arrow.removeClass("loading hide");
            childrenContainer.show();
            if (collection.count > collection.pageLimit) {
              paginationContainer.show();
            }
          } else {
            Spinner.apply(iconSpinner, this.spinnerOpts);
            collection.getAudienceSegments(collection.parent || itemId, this.currencyCode).done(() => {
              this.collection.add(collection.models);
              collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(collection, this.currencyCode);
              iconSpinner.spin(false);
              arrow.removeClass("loading hide");
              if (childrenContainer.is(":visible")) {
                this.addChildren({
                  entityId: itemId
                });
              } else {
                childrenContainer.show();
                paginationContainer.show();
              }
              this.updateScrollBar();
            });
          }
        }
      }
    },
    getPath: function (fullPath) {
      let path;
      if (typeof fullPath === "string") {
        path = fullPath.replace(/ - /g, " | ");
      } else {
        fullPath = $(fullPath);
        path = [];
        while (fullPath.hasClass("ui-draggable")) {
          path.push(fullPath.find(".segment").text().trim());
          fullPath = fullPath.closest(".children").closest(".item-wrapper").children(".ui-draggable");
        }
        path = path.reverse().join(" | ");
      }
      const lastSlashIndex = path.lastIndexOf(" | ");
      const noSlash = lastSlashIndex === -1;
      const pathLength = 3;
      const browsePath = noSlash ? "" : path.substring(0, lastSlashIndex + pathLength);
      const browsePathEnd = noSlash ? path : path.substr(lastSlashIndex + pathLength);
      return {
        browse_path: browsePath.replace(/ \| /g, " &raquo "),
        browse_path_end: browsePathEnd.replace(/ \| /g, " &raquo ")
      };
    },
    addChildren: function ({
      entityId = ""
    }) {
      const item = this.$(`[data-id='${entityId}']`);
      var arrow = item.find(".arrow");
      var text = item.find(".text");
      const margin = 20;
      const spacing = 4;
      const offset = 74;
      const percent = 100;
      const indent = parseInt(arrow.css("marginLeft").replace(/[^-\d.]/g, ""), 10);
      const textWidth = Math.round(percent * parseFloat(text.css("width")) / parseFloat(text.parent().css("width")));
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var collection = this.collectionList[entityId];
      const serializedData = this.serialize(collection);
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: childrenContainer,
        data: serializedData
      });
      const marginSetting = `${-(indent + margin)}px 0 ${indent + margin}px`;
      childrenContainer.find(".arrow").css("margin", `4px ${marginSetting}`);
      childrenContainer.find(".text").css("margin", `0 ${marginSetting}`).css("width", `${textWidth - spacing}%`);
      this.applyDraggable();
      if (collection.count > collection.pageLimit) {
        collection.entityType = "Segment";
        collection.parent = entityId;
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: `.pagination-${entityId}`,
          layout: {}
        });
        this.paginationLayout.layout[`.pagination-${entityId}`] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: collection,
            onSuccess: $.proxy(this.addChildren, this),
            indent: `margin: 5px -55px 0 ${indent + offset}px`
          }
        }];
        this.paginationLayout.load();
      }
    },
    droppedPixelInBin: function ({
      selectedList: selectedList
    }) {
      const {
        collection: collection,
        sharedCollection: sharedCollection
      } = this;
      const selectList = [];
      $.each(selectedList, function (index, item) {
        let model = sharedCollection.get(item.id);
        if (!model) {
          model = collection.get(item.id);
          if (model) {
            const jsonData = model.toJSON();
            jsonData.action = item.action;
            selectList.push(jsonData);
          }
        }
      });
      this.updateSharedCollection({
        selectedList: selectList
      });
    },
    updateSharedCollection: function (data) {
      let assignedItems;
      var self = this;
      if (data.selectedList) {
        $.each(data.selectedList, function (index, item) {
          assignedItems = _.find(self.sharedCollection.models, function (model) {
            return model.get("selection_id") === item.selection_id;
          });
          if (assignedItems) {
            self.sharedCollection.remove(item);
          } else {
            self.sharedCollection.add(item);
          }
        });
      } else {
        assignedItems = _.find(this.sharedCollection.models, function (model) {
          return model.get("selection_id") === data.selection_id;
        });
        if (assignedItems) {
          this.sharedCollection.remove(data);
        } else {
          this.sharedCollection.add(data);
        }
      }
    },
    unload: function () {
      this.headers = this.headers_event_data;
      this.partials.listItem = itemTemplate;
      this.partials.gridFooter = gridFooter;
      this.isSegment = false;
    },
    syncCollections: function (collection = this.collection) {
      var self = this;
      const $el = this.el;
      function findSelected(sharedModel) {
        const model = this;
        return sharedModel.get("selection_id") === model.id;
      }
      if (collection) {
        _.each(collection.models, function (model) {
          const ownerType = Utils.getGroupNameFromPixelModel(model);
          const pixelType = pixelTypMap[model.get("pixel_type") || model.get("type")];
          model.set({
            selection_type: pixelType
          });
          const isSelected = _.find(self.sharedCollection.models, findSelected.bind(model));
          if (isSelected) {
            $el.parents().find(`.w-panel-body .w-Pixel-Bins .item[data-id=${isSelected.id}]`).attr("data-id", model.get("id"));
            isSelected.set({
              id: model.id
            });
            isSelected.cid = model.cid;
            if (isSelected.get("selection_type") === "Audience Target") {
              model.set({
                action: "include"
              });
            }
            if (model.get("parent_audience_segment_id")) {
              isSelected.set({
                parent_audience_segment_id: model.get("parent_audience_segment_id")
              });
            }
          } else {
            model.set({
              selection_id: model.get("id")
            });
            if (model.get("type") === "audience_segment") {
              model.set({
                action: "exclude"
              });
            }
          }
          model.set({
            selection_id: model.get("id")
          });
          model.set({
            id: model.id
          });
          model.set({
            selection_type: pixelType
          });
          model.set({
            owner_name: ownerType
          });
        });
      }
    },
    load: function () {
      this.currencyCode = this.campaign.get("currency_code");
      this.render().then(() => {
        const single = this.collection.models[0];
        var primaryFilter = $(".primaryFilter .chzn-single");
        if (typeof single !== "undefined") {
          let filterVal = primaryFilter.text().split(" ")[0];
          const selectionVal = single.get("selection_type").split(" ")[0];
          if (filterVal === "Segments" && selectionVal === "Dynamic") {
            filterVal = selectionVal;
          }
          if (filterVal === selectionVal) {
            if (this.viewMode) {
              this.adjustGridSize(this.viewMode);
            }
          } else {
            this.collection.reset();
            this.setSearchLoader();
          }
        } else {
          const entityFilterVal = primaryFilter.text().split(" ")[0];
          if (entityFilterVal !== "Segments" && entityFilterVal !== "Data" && entityFilterVal !== "Audience" && (entityFilterVal !== "Event" || this.response === false)) {
            this.setSearchLoader();
          }
        }
      });
    },
    mapData: function (segments, displayNameGetter, childCountGetter) {
      var self = this;
      var segmentsArray = segments;
      const currencyCode = this.currencyCode;
      if (!$.isArray(segmentsArray) && $.isPlainObject(segments)) {
        segmentsArray = [segments];
      }
      return $.map(segmentsArray, function (segment) {
        const tmpModelJSON = {
          action: segment.action === "include" ? "include" : "exclude",
          id: segment.id || "",
          segment: displayNameGetter ? displayNameGetter(segment) : segment.name || "",
          size: segment.uniques ? formatNumber(parseInt(segment.uniques, 10)) : "--",
          cpm: segment.retail_cpm || "--",
          child_count: childCountGetter ? childCountGetter(segment) : parseInt(segment.child_count || "0", 10) > 0,
          buyable: (segment.buyable || "0") === "1",
          asCurrency: T1.Utils.getCurrencyTemplateFunc(currencyCode),
          isIncluded: false,
          isExcluded: false
        };
        self.applySelectionStates(tmpModelJSON);
        return tmpModelJSON;
      });
    },
    serialize: function (inputCollection) {
      var self = this;
      var sharedCollection = this.sharedCollection;
      var pixelsData = [];
      var collection = inputCollection || this.collection;
      var isAudienceProvider = false;
      var maxPathLength = 22;
      var isIncludeExludeSelectMode = this.selectionMode !== "singleSelection";
      const getSerializedDataForBrowseMode = function (segments) {
        function sortBySegment(a, b) {
          return a.segment.toLowerCase() > b.segment.toLowerCase() ? 1 : -1;
        }
        if (segments) {
          segments = segments.toJSON();
        }
        if (!segments) {
          segments = _.filter(collection.toJSON(), model => model.parent_audience_segment_id === undefined);
          isAudienceProvider = true;
        }
        let audienceSegments = self.mapData(segments).sort(sortBySegment);
        if (isAudienceProvider) {
          const customAudienceProviders = [];
          const normalAudienceProviders = [];
          for (let i = 0; i < audienceSegments.length; i++) {
            const segment = audienceSegments[i].segment;
            if (segment.match(/Helix/gi)) {
              customAudienceProviders.push(audienceSegments[i]);
            } else {
              normalAudienceProviders.push(audienceSegments[i]);
            }
          }
          audienceSegments = customAudienceProviders.concat(normalAudienceProviders);
        }
        return {
          list: audienceSegments,
          contentType: "pixel-grid"
        };
      };
      var getSerializedDataForSearchMode = function () {
        const result = {
          list: []
        };
        var utilsObj = T1.Utils;
        var boldLastPartOfFullPath = utilsObj.boldLastPartOfFullPath;
        var childCountGetter = function () {
          return false;
        };
        const audienceInformation = _.groupBy(collection.toJSON(), model => model.full_path.split(" - ")[0]);
        $.each(audienceInformation, function (vendor, targets) {
          var searchTerm = self.collection.searchTerm;
          var displayNameGetter = function (model) {
            var text = model.full_path;
            text = text.replace(`${vendor} - `, "");
            text = text.replace(/ - /g, " &raquo ");
            const name = boldLastPartOfFullPath.call(utilsObj, {
              fullPath: text,
              separator: "&raquo",
              searchTermToBeHighlighted: searchTerm ? searchTerm : null
            });
            return name;
          };
          let groupResult = self.mapData(targets, displayNameGetter, childCountGetter);
          groupResult = _.filter(groupResult, model => model.buyable);
          if (groupResult.length) {
            groupResult[0].group = vendor;
            result.list = result.list.concat(groupResult);
          }
        });
        return result;
      };
      this.collection = this.isSegment ? this.audienceSegmentsCollection : this.pixelCollection;
      this.syncCollections();
      const collectionData = this.collection.toJSON();
      if (this.isSegment) {
        if (this.collection.audienceViewType === "browse") {
          return getSerializedDataForBrowseMode(inputCollection);
        }
        return getSerializedDataForSearchMode();
      }
      $.each(collectionData, function (index, pixel) {
        const pixelData = serializePixel(pixel);
        const referURL = pixelData.refer_url;
        if (sharedCollection) {
          const sharedPixel = _.find(sharedCollection.models, model => model.get("selection_id") === pixel.id);
          const action = sharedPixel ? sharedPixel.get("action") : undefined;
          pixelData.isIncluded = action === "include";
          pixelData.isSelected = action !== undefined;
        }
        pixelData.includeExcludeSelectMode = isIncludeExludeSelectMode;
        if (referURL && referURL.length > maxPathLength) {
          pixelData.refer_url = `${referURL.substr(0, maxPathLength)}...`;
          pixelData.full_refer_url = referURL;
        }
        pixelsData.push(pixelData);
      });
      return {
        list: pixelsData,
        contentType: "pixel-grid",
        widgetClassName: this.isSegment === true ? "dynamic" : this.viewMode === "dynamic" ? "dynamic" : ""
      };
    }
  });
  return createGrid(GridView);
});
