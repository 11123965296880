define('modules/siteList/views/list',["jQuery", "T1", "T1View", "text!modules/siteList/templates/list.html", "T1Layout", "collections/organizations", "utils/CampaignStrategyUtils"], function ($, T1, T1View, template, T1Layout, Organizations, Utils) {
  return T1View.extend({
    template: template,
    selected: "",
    isActive: 1,
    searchFilters: null,
    fetchRequired: true,
    events: {
      "click .exclusivenessCheckBoxLabel": "handleCheckLabelClick",
      "click .btn-additional-sites": "showAdditionalSitesForm"
    },
    eventhubEvents: {
      "siteList:search": function (args) {
        this.searchSiteLists.call(this, args);
      }
    },
    initialize: function (args) {
      var orgId = args.orgId;
      var self = this;
      var $el = $(this.el);
      this.dataEvents = {};
      if (args.sharedCollection) {
        this.sharedCollection = args.sharedCollection;
      }
      if (args.strategy) {
        this.strategy = args.strategy;
      }
      this.initLayout();
      if (!orgId) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
      this.collection.urlFilter.set({
        entity: "organization",
        id: orgId
      });
      this.search();
      T1.EventHub.subscribe("additionalSitesForm:show-" + $el.attr("id"), function (e) {
        self.showAdditionalSitesForm(e);
      });
      if (args.strategy) {
        this.domainRestrictions = this.strategy.getDomainRestrictions();
        this.addDataEvent({
          domainRestrictions: {
            reset: "updateAdditionalSitesCount"
          }
        });
      }
    },
    handleCheckLabelClick() {
      T1.EventHub.publish("exclusive-checkbox:click");
    },
    initLayout: function () {
      var self = this;
      var filterAndClearCallback = function (term) {
        T1.EventHub.publish("siteList:search", {
          term: term || ""
        });
      };
      var showActiveCallback = function (e) {
        self.isActive = $(e.currentTarget).is(":checked");
        self.search();
      };
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".site-list-list-container",
        tmplPath: "modules/siteList/templates/listContent.html",
        wrapperEl: "<div></div>",
        layout: {
          ".filters": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback,
              showActiveCallback: showActiveCallback
            }
          }],
          ".site-list-grid": [{
            module: "siteList",
            viewType: "grid",
            options: {
              collection: self.collection,
              sharedCollection: self.sharedCollection
            }
          }]
        },
        serialize: function () {
          var selectivenessVal;
          var selectivenessOptions;
          var showRestrictBidCheck = false;
          var restrictBidIsChecked = false;
          var selectedItemFound = false;
          var i;
          var selectivenessValTextHash = Utils.selectivenessDataDisplayHash;
          if (self.strategy) {
            selectivenessVal = self.strategy.get("site_selectiveness");
            restrictBidIsChecked = self.strategy.get("site_restriction_transparent_urls") === "0" ? false : true;
            selectivenessOptions = [{
              value: "REDUCED",
              text: selectivenessValTextHash.REDUCED
            }, {
              value: "MATHSELECT_250",
              text: selectivenessValTextHash.MATHSELECT_250
            }, {
              value: "ALL",
              text: selectivenessValTextHash.ALL
            }];
            for (i = 0; i < selectivenessOptions.length; i++) {
              if (selectivenessOptions[i].value == selectivenessVal) {
                selectivenessOptions[i].isSelected = true;
                if (selectivenessVal === "REDUCED") showRestrictBidCheck = true;
                selectedItemFound = true;
                break;
              }
            }
            if (selectedItemFound === false) selectivenessOptions[0].isSelected = true;
          }
          return {
            isStrategyView: self.strategy !== undefined,
            selectivenessOptions: selectivenessOptions,
            isRestrictBidCheckBoxVisible: showRestrictBidCheck,
            isRestrictBidChecked: restrictBidIsChecked
          };
        }
      });
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.load().then(function () {
          self.updateAdditionalSitesCount();
          T1.Selectbox($(".list-footer .select", $(self.el)), {
            onChange: function (args) {
              self.onSelectChanged(args);
            }
          });
        });
      });
    },
    unload: function () {
      this.isActive = 1;
      this.searchTerm = "";
      this.search();
      T1.EventHub.unsubscribe("additionalSitesForm:show-" + $(this.el).attr("id"));
    },
    assignSiteList: function (event) {
      var currentTarget = $(event.currentTarget);
      var id = currentTarget.closest(".item").data("id");
      var collection = this.collection;
      var item = collection.get(id);
      T1.EventHub.publish("assigned:siteList", item);
    },
    search: function () {
      var searchConfig = [];
      var isActive = this.isActive;
      var searchTerm = this.searchTerm;
      var collection = this.collection;
      if (isActive) {
        searchConfig.push({
          term: isActive ? "1" : "0",
          searchField: "status"
        });
      }
      if (searchTerm && searchTerm !== "") {
        searchConfig.push({
          term: searchTerm,
          searchField: "name|id"
        });
      }
      T1.EventHub.publish("siteList:setSearchLoader");
      collection.search.set({
        params: searchConfig,
        fetchRequired: true,
        success: function () {
          collection.trigger("reset", collection);
        }
      });
    },
    searchSiteLists: function (data) {
      var term = data.term;
      this.searchTerm = term;
      this.search();
    },
    updateAdditionalSitesCount: function () {
      if (this.strategy) {
        this.updateDataBind({
          "additional-sites-count": "(" + this.domainRestrictions.data.count + ")"
        }, $(this.el));
      }
    },
    onFormClose: function () {
      $(this.el).find(".list-footer .action.active").removeClass("active");
      delete this.layout.layout[".additional-sites-dialog"];
    },
    onSelectChanged: function (e) {
      if (this.strategy) {
        var selectedIndex = e.target.selectedIndex;
        var $el = $(".list-footer .checkBoxHolder", $(this.el));
        if (selectedIndex === 0) {
          $el.show();
        } else {
          $el.hide();
        }
      }
    },
    showAdditionalSitesForm: function (e) {
      var self = this;
      if (e) {
        this.preventDefault(e);
      }
      if (this.domainRestrictions.data.count !== undefined) {
        if (e) {
          $(e.currentTarget).parent().addClass("active");
        }
        this.layout.append(".additional-sites-dialog", {
          module: "siteList",
          viewType: "additionalSitesForm",
          options: {
            domainRestrictions: this.domainRestrictions,
            onCloseCallback: function () {
              if (self.onFormClose) {
                self.onFormClose.call(self);
              }
            }
          }
        });
      }
    }
  });
});
