define('modules/reporting/segments/dataExport/createEdit/views/reportType',["jQuery", "T1", "T1View", "text!../templates/report-type.html"], function ($, T1, T1View, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {
      "changed .report-select": "selectReport"
    },
    eventhubEvents: {
      "reportType.changed": "selectReport"
    },
    initialize: function (model) {
      DataExportModel = model;
    },
    load: function () {
      var $el = this.el;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
      });
    },
    unload: function () {
      DataExportModel.setSelectedReport(null);
    },
    selectReport: function (event) {
      DataExportModel.setSelectedReport($(event.currentTarget).attr("value"));
    },
    serialize: function () {
      return {
        reports: DataExportModel.getReports()
      };
    }
  });
});
