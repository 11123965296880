define('utils/orgSynchronization',["jQuery", "utils/LaunchDarklyUtils"], function ($, LaunchDarklyUtils) {
  var cachedOrgs;
  function getOrganizations() {
    var deferred = $.Deferred();
    if (!cachedOrgs) {
      require(["collections/organizations"], function (Organizations) {
        cachedOrgs = Organizations.getOrganizations();
        deferred.resolve(cachedOrgs);
      });
    } else {
      deferred.resolve(cachedOrgs);
    }
    return deferred.promise();
  }
  function getOrgIdFromCampaign(model) {
    var advertiser = model && model.get ? model.get("advertiser") : null;
    var agency, result;
    if (advertiser) {
      agency = advertiser.get ? advertiser.get("agency") : null;
      if (agency) {
        result = agency.get ? agency.get("organization_id") : null;
      }
    }
    return result;
  }
  return {
    getOrgIdByCampaignModel: getOrgIdFromCampaign,
    attachOrgSynchronizationCheck: function (modelDefinition) {
      var baseFetchMethod = modelDefinition.prototype.fetch;
      var orgSyncInstance = this;
      if (baseFetchMethod) {
        modelDefinition.prototype.fetch = function (options) {
          var self = this;
          var opts = options || {};
          var originalSuccess = opts.success || null;
          var deferred = $.Deferred();
          var skipOrgSynchronization = opts.skipOrgSynchronization || false;
          var i, tmpVal, withArr;
          const VALUE_LENGTH = 10;
          if (opts.skipOrgSynchronization) {
            delete opts.skipOrgSynchronization;
          }
          function successHandler(args) {
            if (originalSuccess) {
              originalSuccess(args);
            }
            deferred.resolve();
            if (skipOrgSynchronization !== true) {
              orgSyncInstance.syncOrganization(self);
            }
          }
          if (opts && opts.params) {
            withArr = opts.params.with;
            if (withArr) {
              for (i = 0; i < withArr.length; i++) {
                tmpVal = withArr[i];
                if (tmpVal.lastIndexOf("advertiser") === tmpVal.length - VALUE_LENGTH) {
                  withArr[i] = `${tmpVal},agency`;
                  break;
                }
              }
            }
          }
          opts.success = successHandler;
          baseFetchMethod.call(self, opts);
          return deferred.promise();
        };
        modelDefinition.prototype.getOrgId = function () {
          var self = this;
          var deferred = $.Deferred();
          var campaignModel = self.get("campaign");
          var orgId;
          if (!campaignModel) {
            campaignModel = self;
          }
          orgId = getOrgIdFromCampaign(campaignModel);
          if (!orgId) {
            getOrganizations().then(function (resultOrgs) {
              resultOrgs.getSelected().then(function (id) {
                deferred.resolve(id);
              });
            });
          } else {
            deferred.resolve(orgId);
          }
          return deferred.promise();
        };
      }
    },
    syncOrganization: function (model) {
      var Orgs, campaignModel, orgIdCurrent, orgIdNew;
      if (!model || !model.get) {
        throw new Error("Incoming param must be a valid model object.");
      }
      campaignModel = model.get("campaign");
      if (!campaignModel) {
        campaignModel = model;
      }
      orgIdNew = getOrgIdFromCampaign(campaignModel);
      if (!orgIdNew) {
        return;
      }
      getOrganizations().then(function (resultOrgs) {
        Orgs = resultOrgs;
        Orgs.getSelected().then(function (id) {
          orgIdCurrent = id || "";
          orgIdNew = orgIdNew.toString();
          orgIdCurrent = orgIdCurrent.toString();
          if (orgIdCurrent.toLowerCase() !== orgIdNew.toLowerCase()) {
            LaunchDarklyUtils.getPromise().then(() => {
              LaunchDarklyUtils.updateOrganization(orgIdNew);
              Orgs.setSelected(orgIdNew, {
                implicitChange: true
              });
            });
          }
        });
      });
    }
  };
});
