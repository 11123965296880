define('modules/T1App/labs/views/list',["jQuery", "Underscore", "Mustache", "T1", "T1View", "T1Comm", "T1Permissions", "text!modules/T1App/labs/templates/list.html", "text!modules/T1App/labs/templates/modal.html", "text!modules/T1App/labs/templates/terms.html", "T1Spinner", "models/userPreferences", "models/sessionUser", "collections/organizations", "../../models/t1AppViews", "models/t1AppTerms", "collections/t1AppTerms", "models/t1AppAcceptedTerms", "collections/t1AppAcceptedTerms", "utils/T1AppUtils", "T1Model", "T1Layout", "T1Preferences", "T1Signature", "T1Tooltip", "jQueryUI"], function ($, _, Mustache, T1, T1View, T1Comm, T1Permissions, template, modalTemplate, termsTemplate, Spinner, UserPreferences, User, Organizations, t1AppViews, T1AppTerms, AppTerms, T1AppAcceptedTerms, AppAcceptedTerms, T1AppUtils) {
  "use strict";

  function generateAbsoluteURL(relativeURL) {
    var a = document.createElement("a");
    var url;
    a.href = relativeURL;
    url = a.href;
    return url;
  }
  return T1View.extend({
    template: template,
    partials: {
      modal: modalTemplate,
      tmodal: termsTemplate
    },
    appID: [],
    custom: [],
    events: {
      "changed .search-filter": "search",
      "click .learn-more-icon": "showModal",
      "click .close-app-modal": "closeModal",
      "click .close-terms-modal": "closeTermsModal",
      "click .terms-modal .launch": "launchAppModal",
      "click .launch-icon": "launchApp",
      "click .learn-more-modal-labs .launch": "launchAppLearnMoreModal",
      "click .app-icon": "launchApp",
      "click .app-title": "launchApp",
      "click .filter-by-name": "showAll",
      "click .filter-by-custom": "filterByCustom",
      "click .filter-by-lastUsed": "filterByLastUsed",
      "click .hide-marker": "hideMarker",
      "click #terms-link": "showTerms",
      "click #terms-link-alt": "showTerms",
      "click .terms-checkbox": "handleItemChecked"
    },
    initialize: function (args) {
      var self = this;
      self.displayCustom();
      self.lastUsed();
      self.lastUsedFilter();
      self.customSortInfo();
      args = args || {};
      self.selectedClass = args.selectedClass || "selected";
    },
    handleItemChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var self = this;
      var $el = $(self.el);
      var $accept_terms = $el.find("#terms-button");
      var notChecked = 0;
      $(".terms-checkbox", $el).each(function () {
        if ($(this).attr("checked") !== "checked") {
          notChecked++;
        }
      });
      if (notChecked === 0) {
        $accept_terms.removeAttr("disabled");
      } else {
        $accept_terms.attr("disabled", true);
      }
    },
    showTerms: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $ct = $(e.currentTarget).attr("id");
      var url = $(`#${$ct}`).data("id");
      var fullUrl = T1AppUtils.getEnv() + url;
      window.open(fullUrl, "_blank");
    },
    closeModal: function (modal) {
      modal.preventDefault();
      $(".learn-more-modal-labs")[0].hide();
    },
    closeTermsModal: function (tmodal) {
      tmodal.preventDefault();
      $(".terms-modal")[0].hide();
    },
    showModal: function (e) {
      var $ct = $(e.currentTarget);
      var ai = $ct.closest(".app-item");
      var appId = $(ai).data("app-id");
      var apps = this.model.get("t1LabsCfgsArray");
      var appInfo = T1AppUtils.convertAppModalInfo(apps, appId);
      var el = $(this.el);
      var render;
      appInfo.env = T1AppUtils.getEnv();
      if (appInfo.learn_more_html) {
        render = Mustache.to_html(modalTemplate, appInfo, this.partials);
        if (Polymer && Polymer.dom) {
          Polymer.dom(Polymer.dom(el[0]).querySelector(".learn-more-modal-labs")).innerHTML = render;
        } else {
          el.find(".learn-more-modal-labs").html(render);
        }
        $(".learn-more-modal-labs")[0].show();
      }
    },
    initTooltips: function (elem) {
      T1.Tooltip(elem, {
        html: true,
        getExternalTip: false,
        className: "launch-app-tool-tip-content",
        tooltipEle: ".launch-icon",
        delayIn: 0,
        delayOut: 0,
        fade: false
      });
      T1.Tooltip(elem, {
        html: true,
        getExternalTip: false,
        className: "learn-more-tool-tip-content",
        tooltipEle: ".learn-more",
        delayIn: 0,
        delayOut: 0,
        fade: false
      });
    },
    initPreferences: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["favorites"]);
    },
    customSortInfo: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "list"]]));
      this.prefCustomSortInfo = T1.Preferences.create.call(this, signature, UserPreferences);
      this.prefCustomSortInfo.mark(["customSortInfo"]);
    },
    displayCustom: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "list"]]));
      this.prefCustom = T1.Preferences.create.call(this, signature, UserPreferences);
      this.prefCustom.mark(["custom"]);
    },
    lastUsed: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "list"]]));
      this.prefLastUsed = T1.Preferences.create.call(this, signature, UserPreferences);
      this.prefLastUsed.mark(["lastUsed"]);
    },
    lastUsedFilter: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "list"]]));
      this.prefLastUsedFilter = T1.Preferences.create.call(this, signature, UserPreferences);
      this.prefLastUsedFilter.mark(["lastUsedFilter"]);
    },
    load: function () {
      var self = this;
      var spinnerC;
      var apps = this.model.get("t1LabsCfgsArray");
      var model = self.model;
      if (T1Comm.isUserLoggedIn()) {
        var lastUsedFilter = self.prefLastUsedFilter.get("lastUsedFilter") || "";
        var customSortInfo = self.prefCustomSortInfo.get("customSortInfo") || "";
        if (!model.get("t1LabsCfgsArray")) {
          model.bind("change:T1LabsConfig", function () {
            model.unbind("change:T1LabsConfig");
            model.sortApps("name");
            self.render().then(function () {
              if (!customSortInfo) {
                $(".custom-sort-marker", self.el).css("display", "inline-block");
              }
              if (lastUsedFilter === "custom") {
                self.filterByCustom();
              } else if (lastUsedFilter === "alpha" || lastUsedFilter === "") {
                self.showAll();
              } else if (lastUsedFilter === "lastUsed") {
                self.filterByLastUsed();
              }
            });
          });
          model.loadAppsConfig();
          T1AppUtils.fetchTerms();
          T1AppUtils.fetchAcceptedTerms();
        } else {
          self.render().then(function () {
            if (!customSortInfo) {
              $(".custom-sort-marker", self.el).css("display", "inline-block");
            }
            if (lastUsedFilter === "custom") {
              self.filterByCustom();
            } else if (lastUsedFilter === "alpha" || lastUsedFilter === "") {
              self.showAll();
            } else if (lastUsedFilter === "lastUsed") {
              self.filterByLastUsed();
            }
          });
          model.loadAppsConfig();
          T1AppUtils.fetchTerms();
          T1AppUtils.fetchAcceptedTerms();
        }
        T1.EventHub.publish("updateNavPath");
      }
      self.model.set({
        appsModalArray: apps
      });
      self.render();
      if (!apps) {
        spinnerC = $(this.el).find(".spinner-container");
        Spinner.apply(spinnerC, {
          lines: 10,
          length: 3,
          width: 3,
          radius: 3
        });
      }
    },
    debouncedSearch: function (term) {
      var output, outputExtra;
      var unifyError = false;
      var filterResults = true;
      var lastUsedFilterError = false;
      var list = this.currentDisplayList;
      var appList = this.model.get("t1LabsCfgsArray");
      var extraApps = [],
        lastUsedFilterView = this.prefLastUsedFilter.get("lastUsedFilter") || "";
      var filterHeadValue;
      var filterSubheaderValue;
      output = _.filter(list, function (app) {
        var txt = term ? term.toLowerCase() : "";
        return app.description.toLowerCase().indexOf(txt) > -1 || app.name.toLowerCase().indexOf(txt) > -1;
      });
      output = output.length ? output : null;
      if (lastUsedFilterView === "lastUsed") {
        $.each(appList, function (key1, value1) {
          var add = true;
          $.each(list, function (key2, value2) {
            if (value1.id === value2.id) {
              add = false;
            }
          });
          if (add) {
            extraApps.push(value1);
          }
        });
        if (list.length < 1) {
          extraApps = appList;
          filterResults = true;
        }
        outputExtra = _.filter(extraApps, function (app) {
          var txt = term ? term.toLowerCase() : "";
          return app.description.toLowerCase().indexOf(txt) > -1 || app.name.toLowerCase().indexOf(txt) > -1;
        });
        outputExtra = _.sortBy(outputExtra, function (app) {
          return app.name.toLowerCase();
        });
        outputExtra = outputExtra.length ? outputExtra : null;
        if (output === null && outputExtra === null) {
          unifyError = true;
        }
        if (output === null && list.length < 1) {
          lastUsedFilterError = true;
        }
        if (term) {
          filterHeadValue = `No results found for ${term}.`;
          filterSubheaderValue = "Please make sure your words are spelled correctly or try less or different keywords.";
        } else {
          filterHeadValue = "No Recently Used Labs";
          filterSubheaderValue = "Once you start using labs you will see your recently used labs here.";
        }
        this.updateDisplay({
          apps: output,
          filterResults: filterResults,
          filterHeader: filterHeadValue,
          filterSubheader: filterSubheaderValue,
          recentlyUsedView: true,
          extraAppsView: true,
          extraApps: outputExtra,
          unifyError: unifyError,
          lastUsedFilterError: lastUsedFilterError,
          lastUsedFilterHeader: "No Recently Used Labs",
          lastUsedFilterMessage: "Once you start using labs you will see your recently used labs here.",
          env: T1AppUtils.getEnv()
        });
      } else {
        if (outputExtra === null) {
          extraApps = false;
        }
        this.updateDisplay({
          apps: output,
          filterResults: true,
          filterHeader: `No results found for ${term}.`,
          filterSubheader: "Please make sure your words are spelled correctly or try less or different keywords.",
          extraApps: extraApps,
          env: T1AppUtils.getEnv()
        });
      }
    },
    search: function (event) {
      _.debounce(this.debouncedSearch(event.currentTarget.value), 200, true);
    },
    updateDisplay: function (list) {
      this.renderPartialTemplate({
        targetEl: $(".w-apps-c"),
        templateTarget: ".w-apps-c",
        context: ".app-column-b",
        action: "html",
        data: list
      });
      this.initMultiSortable();
    },
    showAll: function () {
      var apps = this.model.get("t1LabsCfgsArray");
      var displayList = [];
      var i;
      this.lastUsedFilter = "alpha";
      this.prefLastUsedFilter.save();
      $("ul.ribbon-nav li", this.el).removeClass("selected");
      for (i = 0; i < apps.length; i++) {
        if (apps[i].name.length >= 27) {
          apps[i].tooltip = true;
        }
        displayList.push(apps[i]);
      }
      displayList = _.sortBy(displayList, function (app) {
        return app.name.toLowerCase();
      });
      this.currentDisplayList = displayList;
      this.clearCategorySelection();
      this.clearSearchTerm();
      this.updateDisplay({
        apps: this.currentDisplayList,
        env: T1AppUtils.getEnv()
      });
      $(".filter-by-name", this.el).addClass("selected");
      $("#sortable", this.el).sortable({}).disableSelection();
    },
    filterByCustom: function () {
      var self = this;
      var appList = this.model.get("t1LabsCfgsArray");
      var displayList = self.prefCustom.get("custom") || "";
      var result = [];
      var extraApps = appList.slice();
      displayList = displayList.split(", ");
      for (var i = 0; i < displayList.length; i++) {
        for (var j = 0; j < appList.length; j++) {
          displayList[i] = parseInt(displayList[i]);
          if (displayList[i] === appList[j].app_id) {
            if (appList[j].name.length > 27) {
              appList[j].tooltip = true;
            }
            result.push(appList[j]);
            for (var k = 0; k < extraApps.length; k++) {
              if (appList[j].app_id === extraApps[k].app_id) {
                extraApps.splice(k, 1);
              }
            }
          }
        }
      }
      this.currentDisplayList = result.concat(extraApps);
      if (result.length < 1) {
        this.currentDisplayList = appList;
      }
      this.clearCategorySelection();
      this.clearSearchTerm();
      this.updateDisplay({
        apps: this.currentDisplayList,
        customSortView: true,
        env: T1AppUtils.getEnv()
      });
      $("#sortable", this.el).sortable({}).disableSelection();
      $("ul.ribbon-nav li", this.el).removeClass("selected");
      $(".filter-by-custom", this.el).addClass("selected");
      this.lastUsedFilter = "custom";
      this.prefLastUsedFilter.save();
    },
    filterByLastUsed: function () {
      var self = this;
      var appList = this.model.get("t1LabsCfgsArray");
      var displayList = self.prefLastUsed.get("lastUsed") || "";
      var result = [];
      var extraApps = [];
      displayList = displayList.split(", ");
      for (var i = 0; i < displayList.length; i++) {
        for (var j = 0; j < appList.length; j++) {
          displayList[i] = parseInt(displayList[i]);
          if (displayList[i] === appList[j].app_id) {
            if (appList[j].name.length >= 27) {
              appList[j].tooltip = true;
            }
            result.push(appList[j]);
          }
        }
      }
      extraApps = appList.filter(function (val) {
        return result.indexOf(val) === -1;
      });
      extraApps = _.sortBy(extraApps, function (app) {
        return app.name.toLowerCase();
      });
      this.currentDisplayList = result;
      this.displayCustom = false;
      this.clearCategorySelection();
      this.clearSearchTerm();
      if (result.length < 1) {
        this.updateDisplay({
          apps: this.currentDisplayList,
          recentlyUsedView: true,
          lastUsedFilter: true,
          lastUsedFilterHeader: "No Recently Used Labs",
          lastUsedFilterMessage: "Once you start using labs you will see your recently used labs here.",
          extraAppsView: true,
          extraApps: extraApps,
          env: T1AppUtils.getEnv()
        });
      } else {
        this.updateDisplay({
          apps: this.currentDisplayList,
          filterHeader: "No Un-used Labs",
          filterSubheader: "All Labs have been used and therefore appear above.",
          recentlyUsedView: true,
          extraAppsView: true,
          extraApps: extraApps,
          env: T1AppUtils.getEnv()
        });
      }
      this.lastUsedFilter = "lastUsed";
      this.prefLastUsedFilter.save();
      $("ul.ribbon-nav li", this.el).removeClass("selected");
      $(".filter-by-lastUsed", this.el).addClass("selected");
    },
    initMultiSortable: function () {
      var self = this;
      $("#sortable").sortable({
        update: function () {
          self.appID.length = 0;
          $.each(this.children, function () {
            self.appID.push(this.id);
          });
          self.custom = self.appID.join(", ");
          self.prefCustom.save();
        }
      }).disableSelection();
    },
    hideMarker: function () {
      $(".custom-sort-marker", this.el).fadeOut();
      this.customSortInfo = "1";
      this.prefCustomSortInfo.save();
    },
    clearSearchTerm: function () {
      $(".search-filter", this.el).val("");
    },
    clearCategorySelection: function () {
      $(".selection-item.category", this.el).removeClass("selected");
    },
    launchAppModal: function (event) {
      var app_id, id;
      var self = this;
      var $el = $(self.el);
      var notChecked = 0;
      $(".terms-checkbox", $el).each(function () {
        if ($(this).attr("checked") !== "checked") {
          notChecked++;
        }
      });
      id = $(event.currentTarget).attr("data-app-id");
      app_id = $(event.currentTarget).attr("data-id");
      $(".app-loader-holder", $el).show();
      if (notChecked === 0) {
        $(".terms-modal")[0].hide();
        T1AppUtils.appAcceptedTerms.acceptTerms(app_id).then(function () {
          T1AppUtils.appAcceptedTerms.fetch({
            success: function (data) {
              if (data.meta.status === "success") {
                T1AppUtils.appAcceptedTerms.set(data.data);
              }
            }
          }).then(function () {
            $(".app-loader-holder", $el).hide();
            self.launchApp(event, id);
          });
        });
      }
    },
    getSessionID: function () {
      var self = this;
      self.model.bind("change:T1AppsSessionID", function () {
        self.model.unbind("change:T1AppsSessionID");
        self.load();
      });
      self.model.loadClonedSessionID();
    },
    launchAppLearnMoreModal: function (event) {
      var id;
      id = $(event.currentTarget).attr("data-app-id");
      this.launchApp(event, id);
    },
    launchApp: function (event, di) {
      var self = this;
      var el = $(this.el);
      var model = self.model;
      var apps = this.model.get("t1LabsCfgsArray");
      var allTerms = T1AppUtils.appTerms.attributes;
      var allAcceptedTerms = T1AppUtils.appAcceptedTerms.attributes;
      var id,
        lastUsedList = self.prefLastUsed.get("lastUsed") || "";
      var url;
      var appsApiBase;
      var orgId;
      var appInfo;
      var createAppView,
        user = User.getUser();
      var terms;
      var termId;
      var validTerms;
      var checkLab;
      var render;
      id = di ? di : event.id || $(event.target).closest("li").attr("data-app-id");
      appInfo = T1AppUtils.convertAppModalInfo(apps, id);
      lastUsedList = `${appInfo.app_id}, ${lastUsedList}`;
      lastUsedList = lastUsedList.split(", ");
      terms = T1AppUtils.getTermsInfo(allTerms, appInfo.app_id);
      termId = terms ? terms.id : 0;
      validTerms = T1AppUtils.checkValidTerms(appInfo.app_id, termId, allAcceptedTerms, appInfo.fee, appInfo.billing_type);
      checkLab = T1AppUtils.checkLabAgreement(allAcceptedTerms);
      var unique = $.grep(lastUsedList, function (el, index) {
        return index === $.inArray(el, lastUsedList);
      });
      unique = unique.join(", ");
      self.lastUsed = unique;
      if (validTerms || checkLab && appInfo.billing_type === "none") {
        self.prefLastUsed.save();
        if (appInfo.external === 1) {
          appsApiBase = COMPASS_ENV.APP_BASE + appInfo.api_version;
          appsApiBase = generateAbsoluteURL(appsApiBase);
          appsApiBase = encodeURIComponent(`${appsApiBase}/`);
          Organizations.getOrganizations().getSelected().then(function (id) {
            orgId = id;
          });
          createAppView = new t1AppViews();
          createAppView.createAppView(user.get("id"), appInfo.app_id, orgId, "0.0.0");
          url = `${appInfo.url}?session_id=${T1.SESSION_ID}&api_base=${appsApiBase}&org_id=${orgId}`;
          window.open(url, "_blank");
        } else {
          T1.Location.set(`appstore/launch-labs/${id}`);
        }
      } else {
        var termsData = [];
        termsData.id = id;
        termsData.app_id = appInfo.app_id;
        termsData.env = T1AppUtils.getEnv();
        if (!checkLab) {
          termsData.title = "Labs   Terms & Conditions";
          termsData.terms = true;
          termsData.checkboxTerms = true;
          termsData.button = "Accept Terms";
        }
        if (appInfo.billing_type !== "none") {
          termsData.billing = true;
          termsData.checkboxBilling = true;
          termsData.title = `${appInfo.name}   Billing Agreement`;
          termsData.text = `You are attempting to access ${appInfo.name} ( “Application”), which incures a` + ` fee of $${appInfo.fee.toFixed(2)} CPM (“Fee”). By clicking below, you agree to the fee and authorize` + ` MediaMath Inc. to invoice you for the fee`;
          termsData.button = "Accept Pricing";
        }
        if (!checkLab && appInfo.billing_type !== "none") {
          termsData.bothTerms = true;
        }
        render = Mustache.to_html(termsTemplate, termsData, this.partials);
        if (Polymer && Polymer.dom) {
          Polymer.dom(Polymer.dom(el[0]).querySelector(".terms-modal")).innerHTML = render;
        } else {
          el.find(".terms-modal").html(render);
        }
        $(".terms-modal")[0].show();
      }
    }
  });
});
