define('modules/audiences/segments/bulkCreate/models/bulkSegments',["jQuery", "Underscore", "Backbone", "T1", "T1Comm", "modules/audiences/segments/segmentUtils", "models/segment", "models/behavior", "models/pixel", "models/advertiser", "JSZip", "xlsx"], function ($, _, Backbone, T1, T1Comm, Util, Segment, Behavior, Pixel, Advertiser) {
  "use strict";

  var FULL_SEGMENT_SHEET = "Segment Definition";
  var BEHAVIOR_SHEET = "Behavior Definitions";
  var TEMPLATE_ERROR = "Please download the latest version of the template.";
  var ATTRIBUTE_NAMES = {
    "DMA / Media Market": "5",
    "Connection Speed": "6",
    ISP: "7",
    "Zip / Postal Code": "8",
    Browser: "12"
  };
  var ATTRIBUTE_DIMS = {
    5: "DMAX",
    6: "CSPD",
    7: "ISPX",
    8: "USZC",
    12: "BSER",
    DIM_BASE: "target_values/?dimension="
  };
  return Backbone.Model.extend({
    rowOffset: 2,
    pixels: [],
    advertisers: [],
    saveSegment: function (segmentRow) {
      var self = this;
      var deferred = $.Deferred();
      var segmentBulkID = segmentRow["REFERENCE ID"];
      var name = segmentRow["SEGMENT NAME"];
      var rejectSaveObj = {
        name: name,
        bulkID: segmentBulkID
      };
      var returnObj = {};
      var segment = new Segment({
        name: name,
        id: "new",
        owner_id: segmentRow["ADVERTISER ID"],
        owner_type: "advertiser",
        segment_type: "adaptive"
      });
      var behaviorPromises = [];
      $.each(segmentRow.behaviors, function (i, behaviorRow) {
        behaviorPromises.push(self.createBehavior(i, segmentRow, behaviorRow));
      });
      $.when.all(behaviorPromises).then(function (behaviors) {
        $.each(behaviors, function (i, behavior) {
          segment.addBehavior(behavior);
        });
        self.setSegmentExpression(segment, segmentRow);
        segment.save().pipe(function () {
          returnObj.bulkID = segmentBulkID;
          returnObj.name = segment.get("name");
          returnObj.id = segment.get("id");
          deferred.resolve(returnObj);
        }, function () {
          rejectSaveObj.errorSource = segmentRow.errorSource ? segmentRow.errorSource : "Unknown Error";
          deferred.reject(rejectSaveObj);
        });
      }, function () {
        rejectSaveObj.errorSource = segmentRow.errorSource ? segmentRow.errorSource : "Unknown Error";
        deferred.reject(rejectSaveObj);
      });
      return deferred.promise();
    },
    createBehavior: function (i, segmentRow, behaviorRow) {
      var behaviorDeferred = new $.Deferred();
      var behaviorModel = new Behavior();
      var pixel = new Pixel({
        id: segmentRow.pixels[i]
      });
      var advertiser = new Advertiser();
      var rejectObj = {
        name: name,
        id: segmentRow["REFERENCE ID"]
      };
      var self = this;
      function addPixelToBehavior() {
        var pixelDeferred = $.Deferred();
        var cachedPixel = self.pixels.find(p => p.id === pixel.id);
        if (cachedPixel) {
          setPixelDataOnBehavior(cachedPixel);
          pixelDeferred.resolve(cachedPixel.get("advertiser_id"));
        } else {
          pixel.fetch({
            success: function (data) {
              self.pixels.push(data);
              setPixelDataOnBehavior(data);
              pixelDeferred.resolve(data.get("advertiser_id"));
            },
            onError: function () {
              pixelDeferred.reject(rejectObj);
            },
            errorDisplaySuppressingConfig: {
              errorCodes: [401, 404]
            }
          });
        }
        return pixelDeferred.promise();
      }
      function addAdvertiserToBehavior(id) {
        var advertiserDeferred = $.Deferred();
        var cachedAdvertiser = self.advertisers.find(a => a.id === id);
        if (cachedAdvertiser) {
          setAdvertiserInfoOnBehavior(cachedAdvertiser);
          advertiserDeferred.resolve();
        } else {
          advertiser.set({
            id: id
          });
          advertiser.fetch({
            params: {
              with: ["agency"]
            },
            success: function (data) {
              self.advertisers.push(data);
              setAdvertiserInfoOnBehavior(data);
              advertiserDeferred.resolve();
            },
            onError: function () {
              advertiserDeferred.reject(rejectObj);
            }
          });
        }
        return advertiserDeferred.promise();
      }
      function setPixelDataOnBehavior(data) {
        var attrs = data.attributes;
        var advertiserId = parseInt(attrs.advertiser_id);
        behaviorModel.set({
          parentId: advertiserId,
          advertiserId: advertiserId,
          campaignId: null,
          type: "pixel",
          category: "eventPixels",
          owner_id: parseInt(segmentRow["ADVERTISER ID"]),
          owner_type: "advertiser",
          id: "id_new_" + i,
          name: attrs.name,
          targetId: attrs.id
        });
      }
      function setAdvertiserInfoOnBehavior(data) {
        var attrs = data.attributes;
        behaviorModel.set({
          parentName: attrs.name,
          titleInfo: [attrs.agency.attributes.name, attrs.name, behaviorModel.get("name")]
        });
      }
      function addRecencyAndFrequencyToBehavior() {
        behaviorModel.set({
          recency: {
            op: behaviorRow["RECENCY OPERATOR"].toLowerCase(),
            count: parseInt(behaviorRow["RECENCY VALUE"]),
            unit: behaviorRow["RECENCY TIME SCALE"].toLowerCase()
          },
          frequency: {
            op: behaviorRow["FREQUENCY OPERATOR"].toLowerCase(),
            min: behaviorRow["FREQUENCY LOWER LIMIT"],
            max: behaviorRow["FREQUENCY UPPER LIMIT"]
          }
        });
        return Promise.resolve();
      }
      function addAttr(attr, attributes) {
        attributes.push({
          id: attr.attribute,
          op: attr.operator.toLowerCase(),
          values: attr.values,
          altValues: attr.altValue
        });
      }
      function addAttributesToBehavior() {
        var attributes = [];
        if (behaviorRow.customAttribute) {
          addAttr(behaviorRow.customAttribute, attributes);
        }
        if (behaviorRow.standardAttribute) {
          addAttr(behaviorRow.standardAttribute, attributes);
        }
        behaviorModel.set({
          filter_by: attributes
        });
        return Promise.resolve();
      }
      addRecencyAndFrequencyToBehavior().then(function () {
        return addAttributesToBehavior();
      }).then(function () {
        return addPixelToBehavior();
      }).then(function (advertiserId) {
        return addAdvertiserToBehavior(advertiserId);
      }).then(function () {
        behaviorDeferred.resolve(behaviorModel);
      }, function (error) {
        behaviorDeferred.reject(error);
      });
      return behaviorDeferred.promise();
    },
    getExpressionElements: function (segmentRow) {
      var pixels = Object.keys(segmentRow).filter(function (key) {
        return key.indexOf("PIXEL") > -1;
      });
      var operands = Object.keys(segmentRow).filter(function (key) {
        return key.indexOf("OPERATOR") > -1;
      });
      segmentRow.pixels = [];
      segmentRow.operands = [];
      $.each(pixels, function (i, key) {
        segmentRow.pixels.push(segmentRow[key]);
      });
      $.each(operands, function (i, key) {
        segmentRow.operands.push(segmentRow[key].toLowerCase());
      });
    },
    setSegmentExpression: function (segment, segmentRow) {
      var behaviors = segment.behaviors.models;
      var expression = "";
      $.each(behaviors, function (i, behavior) {
        expression += i < behaviors.length - 1 ? behavior.id + " " + segmentRow.operands[i] + " " : behavior.id;
      });
      segment.set({
        expression: expression
      });
    },
    convertStandardAttributes: function () {
      var self = this;
      var attrDef = new $.Deferred();
      var convertedAttributes = [];
      $.each(this.behaviorRows, function (i, row) {
        var attr = row.standardAttribute;
        if (attr) {
          convertedAttributes.push(self.convertAttribute(attr));
        }
      });
      $.when.all(convertedAttributes).then(function () {
        attrDef.resolve();
      });
      return attrDef.promise();
    },
    convertAttribute: function (attr) {
      var deferred = new $.Deferred();
      var attrName = attr.attribute || "";
      var value = attr.values || "";
      var attrID = ATTRIBUTE_NAMES[attrName];
      var dim = ATTRIBUTE_DIMS[attrID];
      var extension = dim === "USZC" ? "&full=*&q=name%3d%3aus" : "&full=*&q=name%3d%3a";
      if (dim === "DMAX") {
        value = encodeURIComponent(value.replace(/»/g, "/"));
      }
      function setURL() {
        return T1.ENV.API_BASE + ATTRIBUTE_DIMS.DIM_BASE + dim + extension + value;
      }
      function setAttributeIdOnRow(entity) {
        var newValue = _.where(entity.prop, {
          name: "value"
        })[0].value;
        attr.altValue = attr.values;
        attr.values = newValue;
        attr.attribute = attrID;
        deferred.resolve();
      }
      function setNullAttributeIdOnRow() {
        attr.values = null;
        attr.attribute = attrID;
        deferred.resolve();
      }
      function parseResponse(response) {
        return $.xml2json(response).entities.entity;
      }
      function DMAXFollowUpCall() {
        value = encodeURIComponent(attr.values.replace(/»/g, "|"));
        $.ajax({
          url: setURL(),
          success: function (response) {
            var entity = parseResponse(response);
            if (entity) {
              setAttributeIdOnRow(entity);
            } else {
              setNullAttributeIdOnRow();
            }
          },
          error: function () {
            setNullAttributeIdOnRow();
          }
        });
      }
      $.ajax({
        url: setURL(),
        success: function (response) {
          var entity = parseResponse(response);
          if (entity) {
            setAttributeIdOnRow(entity);
          } else {
            if (dim === "DMAX") {
              DMAXFollowUpCall();
            } else {
              setNullAttributeIdOnRow();
            }
          }
        },
        error: function () {
          setNullAttributeIdOnRow();
        }
      });
      return deferred.promise();
    },
    validate: function () {
      var globalErrors = [];
      var validationErrors = {};
      var versionError = this.versionCheck(this.data);
      var dataError, limitError, idErrors, behaviorIDErrors, segmentErrors;
      if (versionError) {
        validationErrors.versionError = versionError;
      } else {
        limitError = this.maxRowsCheck(this.segmentRows);
        dataError = this.hasDataCheck();
        idErrors = this.segmentsIDCheck(this.segmentRows);
        behaviorIDErrors = this.behaviorIDCheck(this.rogueBehaviors);
        $.each([limitError, dataError, idErrors, behaviorIDErrors], function (i, error) {
          if (error) {
            globalErrors.push(error);
          }
        });
        segmentErrors = this.segmentRowsValidation(this.segmentRows);
        if (segmentErrors) {
          validationErrors.segmentErrors = segmentErrors;
        }
      }
      if (globalErrors.length) {
        validationErrors.globalErrors = globalErrors;
      }
      return validationErrors;
    },
    maxRowsCheck: function (segmentRows) {
      var rowLimitError;
      if (segmentRows.length > this.maxRows) {
        rowLimitError = "Sorry, a maximum of " + this.maxRows + " segments is allowed.";
      }
      return rowLimitError;
    },
    hasDataCheck: function () {
      if (this.segmentRows.length === 0 || this.behaviorRows.length === 0) {
        return "Must contain segments and behaviors.";
      }
    },
    segmentsIDCheck: function (segmentRows) {
      var ids = [];
      var repeatedIDs = [];
      var uniqueIDs = [];
      for (var i = 0; i < segmentRows.length; i++) {
        ids.push(segmentRows[i]["REFERENCE ID"]);
      }
      for (var j = 0; j < ids.length; j++) {
        if ($.inArray(ids[j], uniqueIDs) < 0) {
          uniqueIDs.push(ids[j]);
        } else {
          repeatedIDs.push(ids[j]);
        }
      }
      if (repeatedIDs.length) {
        for (var k = 0; k < repeatedIDs.length; k++) {
          if (repeatedIDs[k] === undefined) {
            repeatedIDs[k] = "<blank>";
          }
        }
        return "IDs must be unique. The following IDs were repeated: " + repeatedIDs.join(", ");
      }
    },
    behaviorIDCheck: function (behaviors) {
      var ids = [];
      $.each(behaviors, function (i, behavior) {
        ids.push(behavior.__rowNum__);
      });
      if (ids.length) {
        return "The behaviors on the following excel rows have no corresponding segment: " + ids.join(", ");
      }
    },
    versionCheck: function (bulkSegmentsObj) {
      if (bulkSegmentsObj.Version[0].Version !== Util.bulkSegmentsCurrentVersion) {
        return TEMPLATE_ERROR;
      }
    },
    segmentRowsValidation: function (segmentRows) {
      var self = this;
      var segmentErrors = [];
      var rowErrorObj;
      $.each(segmentRows, function (i, segmentRow) {
        rowErrorObj = {};
        rowErrorObj.errors = self.segmentRowValidation(segmentRow);
        if (rowErrorObj.errors.length) {
          if (segmentRow["REFERENCE ID"]) {
            rowErrorObj.marker = "ID";
            rowErrorObj.id = segmentRow["REFERENCE ID"];
          } else if (segmentRow["SEGMENT NAME"]) {
            rowErrorObj.marker = "name";
            rowErrorObj.id = segmentRow["SEGMENT NAME"];
          } else {
            rowErrorObj.marker = "Excel Row";
            rowErrorObj.id = self.rowOffset + 2;
          }
          segmentErrors.push(rowErrorObj);
        }
      });
      if (segmentErrors.length) {
        return segmentErrors;
      }
    },
    segmentRowValidation: function (segment) {
      var self = this;
      var validationErrors = [];
      var requiredProperties = ["ADVERTISER ID", "REFERENCE ID", "SEGMENT NAME"];
      function propertyValidation() {
        var missingProperties = [];
        for (var i = 0; i < requiredProperties.length; i++) {
          if (!segment.hasOwnProperty(requiredProperties[i])) {
            missingProperties.push(requiredProperties[i]);
          }
        }
        if (missingProperties.length) {
          validationErrors.push("Missing properties: " + missingProperties.join(", "));
        }
      }
      function nameValidation() {
        var name;
        if (segment["SEGMENT NAME"]) {
          name = segment["SEGMENT NAME"];
          return name.trim().length > -1 && name.length < 65;
        } else {
          validationErrors.push("Invalid Name");
        }
      }
      function expressionValidation() {
        var attrs = [];
        var ops = [];
        attrs = Object.keys(segment).filter(function (key) {
          return key.indexOf("PIXEL") > -1;
        });
        ops = Object.keys(segment).filter(function (key) {
          return key.indexOf("OPERATOR") > -1;
        });
        if (attrs.length > self.maxBehaviors) {
          validationErrors.push("Too many behaviors. Maximum behaviors allowed is " + self.maxBehaviors);
        }
        if (attrs.length !== ops.length + 1) {
          validationErrors.push("Invalid Segment Expression");
        }
        if (!attrs.length) {
          validationErrors.push("Must contain at least one pixel");
        }
      }
      function behaviorsCollectionValidation() {
        var behaviors = segment.behaviors;
        var pixels = segment.pixels;
        var error;
        var index;
        $.each(behaviors, function (i, behavior) {
          index = i + 1;
          if (parseInt(behavior.POSITION) !== index || behavior["PIXEL ID"] !== segment.pixels[i]) {
            error = "Behavior Mismatches. Please make sure behaviors are in the right order and PIXEL ID's correspond to the right ID in the Segment Definition";
          } else if (behaviors.length !== pixels.length) {
            error = "Segment expression does not match the expected number of behaviors.";
          }
        });
        if (error) {
          return error;
        }
      }
      function behaviorsValidation() {
        var behaviorRowError, behaviorsError;
        if (segment.behaviors) {
          $.each(segment.behaviors, function (i, behavior) {
            behaviorRowError = self.behaviorRowValidation(behavior).join(", ");
            if (behaviorRowError) {
              validationErrors.push(behaviorRowError);
            }
          });
          behaviorsError = behaviorsCollectionValidation();
          if (behaviorsError) {
            validationErrors.push(behaviorsError);
          }
        } else {
          validationErrors.push("Has no associated behaviors.");
        }
      }
      self.getExpressionElements(segment);
      propertyValidation();
      nameValidation();
      expressionValidation();
      behaviorsValidation();
      return validationErrors;
    },
    behaviorRowValidation: function (behavior) {
      var validationErrors = [];
      var frequencyCheck = {
        operators: ["Between", "Not Between"],
        minimum: 1,
        maximum: null
      };
      var recencyCheck = {
        units: ["Minutes", "Days", "Hours", "Months", "Weeks"],
        operators: ["Within"]
      };
      var standardOperators = ["includes", "excludes"];
      var customOperators = ["greater than", "greater than or equal to", "less than", "less than or equal to", "equal to", "not equal to", "starts with", "does not start with", "ends with", "does not end with", "contains", "does not contain", "=", ">", ">=", "<", "<=", "!="];
      function setRecencyAndFrequencyDefaults() {
        if (!behavior["RECENCY OPERATOR"]) {
          behavior["RECENCY OPERATOR"] = "Within";
        }
        if (!behavior["RECENCY TIME SCALE"]) {
          behavior["RECENCY TIME SCALE"] = "Days";
        }
        if (!behavior["RECENCY VALUE"]) {
          behavior["RECENCY VALUE"] = "30";
        }
        if (!behavior["FREQUENCY OPERATOR"]) {
          behavior["FREQUENCY OPERATOR"] = "Between";
        }
        if (!behavior["FREQUENCY LOWER LIMIT"]) {
          behavior["FREQUENCY LOWER LIMIT"] = 1;
        }
        if (!behavior["FREQUENCY UPPER LIMIT"]) {
          behavior["FREQUENCY UPPER LIMIT"] = null;
        }
      }
      function recencyValidation() {
        var validUnit = _.contains(recencyCheck.units, behavior["RECENCY TIME SCALE"]);
        var validOperator = _.contains(recencyCheck.operators, behavior["RECENCY OPERATOR"]);
        if (!validUnit) {
          validationErrors.push("Invalid Recency Time Scale Unit");
        }
        if (!validOperator) {
          validationErrors.push("Invalid Recency Operator");
        }
      }
      function frequencyValidation() {
        var validOperator = _.contains(frequencyCheck.operators, behavior["FREQUENCY OPERATOR"]);
        var min = parseInt(behavior["FREQUENCY LOWER LIMIT"], 10);
        var max = parseInt(behavior["FREQUENCY UPPER LIMIT"], 10) || null;
        if (!validOperator) {
          validationErrors.push("Invalid Frequency Operator");
        }
        if (min < 1) {
          validationErrors.push("Invalid Frequency Lower Limit");
        }
        if (max !== null && max < 1) {
          validationErrors.Push("Invalid Frequency Upper Limit");
        }
        if (max) {
          if (min > max) {
            validationErrors.push("Invalid Frequency Range: Lower Limit must be less than or equal to Upper Limit");
          }
        }
      }
      function attributeValidation(attribute) {
        var errors = [];
        var operators = attribute.type === "custom" ? customOperators : standardOperators;
        var validProperties = attribute.attribute && attribute.values;
        var validOperator = attribute.operator && _.contains(operators, attribute.operator.toLowerCase());
        if (!validProperties) {
          errors.push("Missing or invalid attribute properties/values");
        }
        if (!validOperator) {
          errors.push("Missing or invalid attribute operator");
        }
        if (errors.length) {
          validationErrors.push("Behavior Definitions Row " + (behavior.__rowNum__ + 1) + " had the following errors: " + errors.join(", "));
        }
      }
      setRecencyAndFrequencyDefaults();
      recencyValidation();
      frequencyValidation();
      if (behavior.standardAttribute) {
        attributeValidation(behavior.standardAttribute);
      }
      if (behavior.customAttribute) {
        attributeValidation(behavior.customAttribute);
      }
      return validationErrors;
    },
    parse: function (file) {
      var self = this;
      var parsedPromise = new Promise(function (resolveParsed, rejectParsed) {
        self.loadBinaryFile(file, function (data) {
          var workbook = XLSX.read(data, {
            type: "binary"
          });
          self.data = self.to_json(workbook);
          if (self.data.Version && self.data.Version[0].Version === Util.bulkSegmentsCurrentVersion) {
            self.maxRows = parseInt(self.data.Version[0].maxRows);
            self.maxBehaviors = parseInt(self.data.Version[0].maxBehaviors);
            self.segmentRows = self.data[FULL_SEGMENT_SHEET].slice(self.rowOffset, self.data[FULL_SEGMENT_SHEET].length);
            self.behaviorRows = self.data[BEHAVIOR_SHEET].slice(self.rowOffset, self.data[BEHAVIOR_SHEET].length);
            self.rogueBehaviors = [];
            if (self.segmentRows.length && self.behaviorRows.length) {
              $.each(self.behaviorRows, function (i, behavior) {
                self.assignBehaviorRowToSegmentRow(behavior);
                if (i === self.behaviorRows.length - 1) {
                  resolveParsed(true);
                }
              });
            } else {
              resolveParsed(true);
            }
          } else {
            rejectParsed(TEMPLATE_ERROR);
          }
        });
      });
      return parsedPromise;
    },
    loadBinaryFile: function (file, success) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        success(data);
      };
      reader.readAsBinaryString(file.item(0));
    },
    to_json: function (workbook) {
      var result = {};
      workbook.SheetNames.forEach(function (sheetName) {
        var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });
      return result;
    },
    assignBehaviorRowToSegmentRow: function (row) {
      var segment = _.findWhere(this.segmentRows, {
        "REFERENCE ID": row["REFERENCE ID"]
      });
      assignAttributesToBehaviorRow();
      if (segment) {
        if (segment.behaviors) {
          segment.behaviors.push(row);
        } else {
          segment.behaviors = [];
          segment.behaviors.push(row);
        }
      } else {
        this.rogueBehaviors.push(row);
      }
      function assignAttributesToBehaviorRow() {
        var standardAttribute = {
          attribute: row["ATTRIBUTE"],
          operator: row["ATTRIBUTE OPERATOR"],
          values: row["ATTRIBUTE VALUE"],
          type: "standard"
        };
        var customAttribute = {
          attribute: row["CUSTOM ATTRIBUTE"],
          operator: row["CUSTOM ATTRIBUTE OPERATOR"],
          values: row["CUSTOM ATTRIBUTE VALUE"],
          type: "custom"
        };
        if (includesAttribute(customAttribute)) {
          row.customAttribute = customAttribute;
        }
        if (includesAttribute(standardAttribute)) {
          row.standardAttribute = standardAttribute;
        }
      }
      function includesAttribute(attribute) {
        var properties = ["attribute", "operator", "values"];
        var presentAttributes = _.find(properties, function (prop) {
          return attribute[prop] !== undefined;
        });
        return presentAttributes ? true : false;
      }
    }
  });
});
