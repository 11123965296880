define('modules/reporting/campaigns/reachFrequency/views/chart',['require','jQuery','modules/shared/configs/charts/lineBarChartTwoAxesConfig','text!modules/shared/templates/chartNoDataGrid.html','T1View','text!../templates/chart.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const ChartConfig = require("modules/shared/configs/charts/lineBarChartTwoAxesConfig");
  const noDataChartTmpl = require("text!modules/shared/templates/chartNoDataGrid.html");
  const T1View = require("T1View");
  const template = require("text!../templates/chart.html");
  return T1View.extend({
    template: template,
    partials: {
      chartNoDataChart: noDataChartTmpl
    },
    xKey: "frequency_bin",
    lineMetric: "",
    lineMetricLabel: "",
    barMetric: "uniques",
    barMetricLabel: "Uniques",
    eventhubEvents: {
      "update:metric": function () {
        this.prepareRenderData();
        this.updateChart();
      },
      "panel.filteredItemsChange": function () {
        const settleDelay = 100;
        setTimeout(() => {
          this.updateChart();
        }, settleDelay);
      }
    },
    initialize(args) {
      const models = args.models;
      this.collection = args.collection;
      this.totalCollection = args.dataGroup.totalCollection;
      this.parentClass = args.parentClass;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
    },
    load() {
      this.prepareRenderData();
      this.render().then(() => {
        this.$noDataChart = this.$(".no-data");
        this.$rfChartContainer = this.$("#rf-chart");
        this.setupChart();
      });
    },
    prepareRenderData() {
      const {
        reportModel: reportModel,
        reportMetaObj: reportMetaObj
      } = this;
      const focus = reportModel.get("focus") || "all";
      this.lineMetric = reportModel.get("metric") || reportMetaObj.metricOptions[0].value;
      this.lineMetricLabel = reportMetaObj.metricsReachFrequency[focus][this.lineMetric].text;
      this.lineMetricObj = reportMetaObj.fields[this.lineMetric];
      this.barMetricObj = reportMetaObj.fields[this.barMetric];
      reportModel.set({
        barMetricObj: this.barMetricObj,
        lineMetric: this.lineMetric,
        lineMetricObj: this.lineMetricObj
      });
    },
    setupChart() {
      const ReportModel = this.reportModel;
      const {
        barMetric: barMetric,
        barMetricLabel: barMetricLabel,
        collection: collection,
        lineMetric: lineMetric,
        lineMetricLabel: lineMetricLabel
      } = this;
      if (this.totalCollection.loaded && collection && collection.loaded) {
        const config = ChartConfig.copy();
        const chartData = ReportModel.getChartData(this.xKey, barMetric, lineMetric, barMetricLabel, lineMetricLabel);
        if (chartData.dataProvider.length) {
          this.showCanvas();
          config.dataProvider = chartData.dataProvider;
          config.graphs = chartData.graphs;
          config.valueAxes[0].labelFunction = value => this.formatValue(value, this.barMetricObj.type);
          config.valueAxes[1].labelFunction = value => this.formatValue(value, this.lineMetricObj.type);
          config.valueAxes[0].title = barMetricLabel;
          config.valueAxes[1].title = lineMetricLabel;
          this.rfChart = AmCharts.makeChart("rf-chart", config);
          this.hideNoDataChart();
        } else {
          this.showNoDataChart();
          this.hideCanvas();
        }
      }
    },
    updateChart() {
      const ReportModel = this.reportModel;
      const {
        barMetric: barMetric,
        barMetricLabel: barMetricLabel,
        collection: collection,
        lineMetric: lineMetric,
        lineMetricLabel: lineMetricLabel
      } = this;
      if (!this.rfChart) {
        this.setupChart();
        return;
      }
      const lineGraph = this.rfChart.graphs[1];
      lineGraph.title = lineMetricLabel;
      lineGraph.valueField = lineMetric;
      this.rfChart.valueAxes[1].title = lineMetricLabel;
      if (this.totalCollection.loaded && collection && collection.loaded) {
        const chartData = ReportModel.getChartData(this.xKey, barMetric, lineMetric, barMetricLabel, lineMetricLabel);
        if (chartData.dataProvider.length) {
          this.showCanvas();
          this.rfChart.dataProvider = chartData.dataProvider;
          this.rfChart.validateData();
          this.hideNoDataChart();
        } else {
          this.showNoDataChart();
          this.hideCanvas();
        }
      }
    },
    formatValue(value, type) {
      return this.reportModel.formatValue(value, type);
    },
    showNoDataChart() {
      this.$noDataChart.removeClass("no-display");
    },
    hideNoDataChart() {
      this.$noDataChart.addClass("no-display");
    },
    showCanvas() {
      this.$rfChartContainer.removeClass("no-display");
    },
    hideCanvas() {
      this.$rfChartContainer.addClass("no-display");
    },
    unload() {
      if (this.rfChart) {
        this.rfChart.clear();
        this.rfChart = null;
      }
      if (this.$rfChartContainer) {
        this.$rfChartContainer = null;
      }
      this.origHelper = null;
      this.config = null;
      this.lineMetric = null;
      this.lineMetricLabel = null;
      this.lineMetricObj = null;
      this.barMetricObj = null;
    }
  });
});
