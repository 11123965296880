define('T1PerfDataFulfiller',['require','jQuery','async','T1','T1Collection','T1Model','collections/organizations','modules/organization/utils/Utils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const async = require("async");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  const T1Model = require("T1Model");
  const Organizations = require("collections/organizations");
  const OrgUtils = require("modules/organization/utils/Utils");
  const intervalCodeMap = {
    current_day: "0",
    yesterday: "1",
    last_7_days: "7",
    last_14_days: "14",
    last_30_days: "30",
    campaign_to_date: "CTD",
    flight_to_date: "FTD"
  };
  const performanceDataFieldMap = {
    agg_start_date_gmt: "agg_start_date_gmt",
    billed_spend: "billed_spend",
    campaign_id: "campaign_id",
    clicks: "clicks_count",
    ctc: "mm_ctc",
    ctr: "mm_ctr",
    end_date: "end_date",
    impressions: "imp_count",
    media_cost_cpa: "media_ecpa",
    media_cost_cpc: "media_ecpc",
    media_cost_cpm: "media_ecpm",
    media_cost: "media_cost",
    mm_total_fee: "mm_total_fee",
    post_click_conversions: "pc_count",
    revenue: "revenue",
    start_date: "start_date",
    strategy_id: "strategy_id",
    total_conversions: "total_conversion_count",
    total_spend_cpa: "total_spend_ecpa",
    total_spend_cpc: "total_spend_ecpc",
    total_spend_cpcv: "total_spend_cpcv",
    total_spend_cpm: "total_spend_ecpm",
    total_spend_roi: "total_spend_roi",
    total_spend_vcpm: "total_spend_vcpm",
    total_spend: "total_spend",
    video_complete_rate: "video_complete_rate",
    viewability_rate: "viewability_rate"
  };
  const defaultDataOptions = {
    budgetFieldName: "total_budget",
    entityIdKey: "campaign_id",
    reqDimensions: "campaign_id",
    reqFilterKey: "campaign_id="
  };
  const goalTypeAndGoalPerformanceFieldMap = {
    cpa: "total_spend_ecpa",
    cpc: "total_spend_ecpc",
    cpcv: "total_spend_cpcv",
    cpe: "total_spend_ecpe",
    ctr: "mm_ctr",
    reach: "total_spend_ecpm",
    roi: "total_spend_roi",
    spend: "total_spend_ecpm",
    vcpm: "total_spend_vcpm",
    vcr: "video_complete_rate",
    viewability_rate: "viewability_rate"
  };
  function setSequenceOfIds(arrayOfIds, self) {
    self.sequencedIds = Array.isArray(arrayOfIds) ? arrayOfIds : [arrayOfIds];
  }
  function getSequencedIds(self) {
    return self.sequencedIds;
  }
  function resetSequenceOfIds(self) {
    self.sequencedIds = undefined;
  }
  function performFunc(startIndex, interval, callback, self) {
    self.getPerformanceDataByRptInterval(startIndex, interval).then(function (data, arrIds) {
      if (getSequencedIds(self) === undefined) {
        setSequenceOfIds(arrIds, self);
      }
      callback(null, {
        data: data,
        interval: interval
      });
    });
  }
  function createArrayOfFunctions(interval) {
    const {
      scope: self,
      startIndex: startIndex
    } = this;
    return function (callback) {
      performFunc(startIndex, interval, callback, self);
    };
  }
  T1.Models.performanceDataFulfiller = T1Collection.extend({
    canCache: true,
    cacheDuration: 18e5,
    apiRoot: T1.RPT_API_ROOT,
    urlString: "std/performance_aggregated_streaming",
    fixture: "-reports",
    hostTargetDataName: "rpt_goal_monitoring",
    model: T1Model,
    org: "",
    initialize(options = {}) {
      var self = this;
      T1Collection.prototype.initialize.call(this, options);
      this.contextEntity = options.contextEntity;
      if (!this.contextEntity) {
        throw new Error("The hostCollection must be passed in via constructor via option parameter.");
      }
      this.isCollection = this.contextEntity.models && Array.isArray(this.contextEntity.models);
      this.dataOptions = $.extend(true, {}, defaultDataOptions, options.dataOptions || {});
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.fetchOptions.time_rollup = "all";
      this.urlString = options.urlString || this.urlString;
      if (options.metrics) {
        this.metrics = options.metrics;
      }
      this.url = function () {
        var opts = $.extend({}, self.fetchOptions);
        return `${self.apiRoot + self.urlString}?${$.param(opts)}`;
      };
      Organizations.getOrganizations().getSelected().then(id => {
        this.org = id;
      });
      this.intervalCodeMap = this.removeFlightToDate(intervalCodeMap);
    },
    fulfillDataForCollection(startIndex, targetIntervals, isSilent, isFirstBatch) {
      var intervals = Array.isArray(targetIntervals) ? targetIntervals : [targetIntervals];
      var deferred = $.Deferred();
      if (!this.isCollection) {
        throw new Error("The contextEntity must be a collection type to invoke this method.");
      }
      this.fulfillDataForMultipleIntervals(startIndex, intervals, isSilent, isFirstBatch).then(function () {
        deferred.resolve();
      });
      return deferred.promise();
    },
    fulfillDataForModel() {
      var deferred = $.Deferred();
      if (this.isCollection) {
        throw new Error("The contextEntity must NOT be a collection type in order to invoke this method.");
      }
      this.fulfillDataForSingleInterval(0, "flight_to_date").then(function () {
        deferred.resolve();
      });
      return deferred.promise();
    },
    getIntervalCodeMap() {
      return intervalCodeMap;
    },
    removeFlightToDate(intervalCodeMapOBj) {
      return intervalCodeMapOBj;
    },
    fulfillDataForMultipleIntervals(startIndex, targetIntervals, isSilent, isFirstBatch) {
      var self = this;
      var deferred = $.Deferred();
      var collection = this.contextEntity;
      const arrFuncs = targetIntervals.map(createArrayOfFunctions, {
        scope: this,
        startIndex: startIndex
      });
      async.parallel(arrFuncs, function (err, results) {
        var orderedModels = [];
        var collectionAsHash = {};
        results.forEach(function (result) {
          self.mergeDataToContextEntity(result.data, result.interval);
        });
        if (collection.models && collection.models.length) {
          collection.models.forEach(function (model) {
            this[model.id] = model;
          }, collectionAsHash);
          const sequencedIds = getSequencedIds(self).splice(startIndex);
          orderedModels = sequencedIds.map(function (id) {
            return this[id];
          }, collectionAsHash);
          collectionAsHash = null;
        }
        orderedModels.forEach(function (model) {
          const idx = 5;
          if (startIndex > idx && isSilent !== true && isFirstBatch === true && self.isCollection === true) {
            collection.trigger("add", model);
          } else {
            model.set({
              perfDataReady: true
            }, {
              silent: true
            });
            model.trigger("change:performanceDataArrived");
          }
        });
        resetSequenceOfIds(self);
        deferred.resolve();
      });
      return deferred.promise();
    },
    fulfillDataForSingleInterval(startIndex, targetInterval) {
      var deferred = $.Deferred();
      var interval = targetInterval;
      this.getPerformanceDataByRptInterval(startIndex, interval).then(data => {
        this.mergeDataToContextEntity(data, interval);
        deferred.resolve();
      });
      return deferred.promise();
    },
    constructModelOptions(perfModel, interval, targetModel, idKey) {
      let doNotShowActualVal, entityStartDate, goalPerformanceVal;
      var resultModel = {};
      var dateFormat = "yyyy-MM-ddTHH:mm:ss";
      if (interval === "flight_to_date") {
        const ftdAggStartDateGMT = perfModel.agg_start_date_gmt;
        const isCampaign = targetModel.get("type") === "campaign";
        if (isCampaign) {
          entityStartDate = targetModel.get("start_date_actual");
        } else {
          entityStartDate = targetModel.get("campaign") ? targetModel.get("campaign").get("start_date_actual") : targetModel.get("start_date_actual");
        }
        if (ftdAggStartDateGMT) {
          if (entityStartDate !== undefined && entityStartDate.toString(dateFormat) !== ftdAggStartDateGMT.toString(dateFormat)) {
            perfModel.clicks = NaN;
            perfModel.impressions = NaN;
            perfModel.total_conversions = NaN;
            perfModel.total_spend = NaN;
            perfModel.total_spend_cpa = NaN;
            perfModel.total_spend_cpc = NaN;
            perfModel.total_spend_cpm = NaN;
            perfModel.total_spend_vcpm = NaN;
            perfModel.total_spend_roi = NaN;
            perfModel.total_spend_cpcv = NaN;
            perfModel.revenue = NaN;
            perfModel.ctc = NaN;
            perfModel.ctr = NaN;
            perfModel.viewability_rate = NaN;
            doNotShowActualVal = true;
          }
        }
      }
      Object.keys(performanceDataFieldMap).forEach(function (key) {
        resultModel[performanceDataFieldMap[key]] = perfModel[key];
      });
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      if (hasVcrImpression) {
        delete resultModel.video_complete_rate;
        resultModel.video_complete_rate_impression_based = perfModel.video_complete_rate_impression_based;
      }
      resultModel.total_spend_ecpe = perfModel.total_spend_cpa;
      resultModel.mm_interval = intervalCodeMap[interval];
      resultModel.id = `${targetModel.id}_${resultModel.mm_interval}`;
      resultModel[idKey] = targetModel.id;
      function setTotalBudgetAndSpendRemainingFields(totalBudgetVal, totalSpentVal) {
        if (isNaN(totalBudgetVal) === false && isNaN(totalSpentVal) === false) {
          resultModel.total_budget = totalBudgetVal;
          resultModel.spend_remaining = totalBudgetVal - totalSpentVal;
        }
      }
      const totalBudget = parseFloat(targetModel.get(this.dataOptions.budgetFieldName));
      const totalSpent = parseFloat(resultModel.total_spend);
      if (resultModel.mm_interval === "FTD" || resultModel.mm_interval === "CTD") {
        setTotalBudgetAndSpendRemainingFields(totalBudget, totalSpent);
      }
      if (doNotShowActualVal === true) {
        goalPerformanceVal = NaN;
      } else {
        const goalType = targetModel.get("goal_type");
        goalPerformanceVal = resultModel[goalTypeAndGoalPerformanceFieldMap[goalType]] || "";
      }
      resultModel.mm_goal_performance = goalPerformanceVal;
      return resultModel;
    },
    mergeDataToContextEntity(responseArray, interval) {
      var self = this;
      var targetPropName = this.hostTargetDataName;
      var idKey = this.dataOptions.entityIdKey;
      const collection = this.isCollection ? this.contextEntity : {
        get: () => this.contextEntity
      };
      function populateCollection(model, targetModel) {
        var modelOptions = self.constructModelOptions(model, interval, targetModel, idKey);
        var t1Model = new T1Model(modelOptions);
        var perfCollection = targetModel.get(targetPropName);
        if (!perfCollection) {
          const obj = {
            [targetPropName]: new T1Collection([t1Model])
          };
          targetModel.set(obj, {
            silent: true
          });
        } else {
          perfCollection.add(t1Model, {
            silent: true
          });
        }
      }
      function constructAffectedModelsArray(model) {
        var targetModel = collection.get(model[idKey]);
        if (targetModel) {
          populateCollection(model, targetModel);
          return targetModel;
        }
      }
      function fixSparseArray(item) {
        return item;
      }
      return responseArray.map(constructAffectedModelsArray).filter(fixSparseArray);
    },
    fillMissingEntities(arrData, arrIds) {
      const idKey = this.dataOptions.entityIdKey;
      const missingIds = arrIds.reduce((arr, id) => {
        const isInData = arrData.find(item => item[idKey] === id);
        if (!isInData) {
          arr.push(id);
        }
        return arr;
      }, []);
      missingIds.forEach(id => {
        arrData.push({
          [idKey]: id
        });
      });
    },
    getPerformanceDataByRptInterval(startIndex, interval) {
      let models;
      var self = this;
      var deferred = $.Deferred();
      const {
        module: module
      } = T1.Location.getRoute();
      function success(data) {
        self.fillMissingEntities(data, arrIds);
        deferred.resolve(data, arrIds);
      }
      function error(err) {
        deferred.resolve(err, arrIds);
      }
      if (this.isCollection) {
        models = this.contextEntity.models;
      } else {
        models = [this.contextEntity];
        startIndex = 0;
      }
      if (!interval) {
        throw new Error("reportInterval parameter must be provided.");
      }
      const arrIds = models.map(model => String(model.id));
      if (arrIds.length && module !== "#reports") {
        const type = models[0].get("type") === "campaign" ? "campaign" : "strategy";
        const filter = this.dataOptions.reqFilterKey + arrIds.join(",");
        const fetchOptions = {
          filter: type === "strategy" ? `campaign_id=${models[0].get("campaign_id")}&${filter}` : filter,
          time_window: interval,
          precision: 2,
          dimensions: interval === "flight_to_date" ? `${type}_id,agg_start_date_gmt` : this.dataOptions.reqDimensions
        };
        if (this.metrics) {
          const metrics = Array.isArray(this.metrics) ? this.metrics : [this.metrics];
          fetchOptions.metrics = metrics.join(",");
        }
        this.fetch({
          error: error,
          fetchOptions: fetchOptions,
          success: success
        });
      } else {
        deferred.resolve([], arrIds);
      }
      return deferred.promise();
    },
    fetch(options = {}) {
      function onSuccess(resp) {
        if (options.success) {
          options.success(resp);
        }
      }
      function onError(err) {
        if (options.error) {
          options.error(err);
        }
      }
      this.fetchOptions = $.extend(true, {}, this.fetchOptions, options.fetchOptions || {});
      this.sync("read", this, {
        action: this.action,
        dataType: "html",
        onSuccess: onSuccess,
        onError: onError,
        processAjaxResponse: function (resp) {
          if (resp.includes("<?xml")) {
            throw new Error("chart data response is not csv.");
          }
          const data = d3.csv.parse.call(d3, resp);
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
    }
  });
  T1.Models.performanceDataFulfiller.getConfigForCampaign = function () {
    return {
      reqDimensions: "campaign_id",
      reqFilterKey: "campaign_id=",
      entityIdKey: "campaign_id",
      budgetFieldName: "total_budget"
    };
  };
  T1.Models.performanceDataFulfiller.getConfigForStrategy = function () {
    return {
      reqDimensions: "strategy_id",
      reqFilterKey: "strategy_id=",
      entityIdKey: "strategy_id",
      budgetFieldName: "budget"
    };
  };
  T1.Models.performanceDataFulfiller.removeRestoreGoalMonitoringClause = function (arrWithClause, toRemove) {
    var goalMonitoringName = "rpt_goal_monitoring";
    var returnVal = false;
    if (toRemove === true) {
      for (let i = 0; i < arrWithClause.length; i++) {
        if (arrWithClause[i] === goalMonitoringName) {
          arrWithClause.splice(i, 1);
          returnVal = true;
          break;
        }
      }
    } else {
      if (!arrWithClause.includes(goalMonitoringName)) {
        arrWithClause.push(goalMonitoringName);
      }
    }
    return returnVal;
  };
  return T1.Models.performanceDataFulfiller;
});
