define('modules/reporting/campaigns/views/settings_menu',["jQuery", "T1", "T1Currencies", "T1Permissions", "T1View", "text!../templates/settings_menu.html"], function ($, T1, T1Currencies, T1Permissions, T1View, template) {
  "use strict";

  var $document = $(document);
  var documentEvents = "mousedown.settingsMenu keyup.settingsMenu";
  return T1View.extend({
    menu_config_table: {},
    template: template,
    events: {
      "click .action-item": "itemClick",
      "click .currency-item": "currencyChange",
      "mousedown .action": "menuClick",
      "mousedown .menu-panel": "stopBubbling"
    },
    initialize: function (args) {
      var models = args.models;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
      this.campaign = models.campaign;
      this.menu_config = args && args.menu_config || {};
      this.initialConfig = $.extend(true, {}, this.menu_config);
    },
    unload: function () {
      $document.off(documentEvents);
    },
    stopBubbling: function (e) {
      e.stopPropagation();
    },
    close: function () {
      this.menuPanel.addClass("hidden");
      this.selectedMenu.removeClass("selected");
      $document.off(documentEvents);
    },
    menuClick: function (e) {
      var self = this;
      var currentTarget = this.selectedMenu = $(e.currentTarget);
      var id = currentTarget.data("id");
      var selectedCurrency = this.mainModel.get("currency");
      var menuPanel = this.menuPanel = this.menuPanel || $(this.el).find(".menu-panel");
      const ANIM_TIME = 200;
      const TAB_KEY = 9;
      const ESCAPE_KEY = 27;
      e.stopPropagation();
      this.menu_config = $.extend(true, {}, this.initialConfig);
      this.selectedCurrency = selectedCurrency;
      this.settingsMenuLayout();
      this.setSelectionState();
      switch (id) {
        case "tab-settings":
          menuPanel.toggleClass("hidden");
          if (!menuPanel.hasClass("hidden")) {
            $document.mousedown();
            menuPanel.hide().fadeIn(ANIM_TIME);
            this.renderPartial(".menu-panel");
            currentTarget.addClass("selected");
            $document.one(documentEvents, function (evt) {
              if (evt.keyCode === TAB_KEY || evt.keyCode === ESCAPE_KEY || !evt.keyCode) {
                self.close();
              }
            });
          } else {
            $document.mousedown();
            $document.off(documentEvents);
          }
          break;
      }
    },
    itemClick: function () {
      this.close();
    },
    currencyChange: function (event) {
      var $currencyItems = $(this.el).find(".currency-item");
      var publish = T1.EventHub.publish;
      if (!$(event.currentTarget).hasClass("menu-column-item-selected")) {
        $currencyItems.toggleClass("menu-column-item-selected");
        this.selectedCurrency = this.selectedCurrency === "usd" ? "campaign" : "usd";
        publish("select:currency", this.selectedCurrency);
        publish("track:event", {
          action: "Choose currency",
          label: this.selectedCurrency
        });
      }
      this.close();
    },
    getSelectionState: function (itemType, itemID) {
      if (itemType === "currency-item") {
        if (this.selectedCurrency === itemID) {
          return "menu-column-item menu-column-item-selected";
        }
        return "menu-column-item";
      }
      return "menu-column-item";
    },
    setSelectionState: function () {
      if (this.selectedCurrency === "usd") {
        $(this.el).find('.currency-item[data-id="us_dollar"]').addClass("menu-column-item-selected");
      } else {
        $(this.el).find('.currency-item[data-id="campaign_currency"]').addClass("menu-column-item-selected");
      }
    },
    getCurrencyName: function (currencyCode, currencyList) {
      var currencyName;
      var length = currencyList.length;
      var i = 0;
      while (i < length) {
        if (currencyList[i].value === currencyCode) {
          currencyName = currencyList[i].text;
          break;
        }
        i++;
      }
      return currencyName;
    },
    settingsMenuLayout: function () {
      var currencyCode, currencyName;
      var menuConfig = this.menu_config;
      var mainModel = this.mainModel;
      var permission = mainModel.get("campaignAccess");
      var section = mainModel.get("section");
      if (this.reportModel.get("isCTD")) {
        switch (section) {
          case "site_transparency":
          case "geo":
            delete menuConfig.raw_data.items.export_current_daily;
            delete menuConfig.raw_data.items.export_full_daily;
            break;
          case "device_technology":
            delete menuConfig.raw_data.items.export_dimension2;
            delete menuConfig.raw_data.items.export_all_dimensions2;
            break;
        }
      }
      switch (permission) {
        case "denied":
          delete menuConfig.edit_campaign;
          delete menuConfig.view_summary;
          break;
        case "edit":
          delete menuConfig.view_summary;
          break;
        case "readonly":
          delete menuConfig.edit_campaign;
          break;
        default:
          throw new Error("permission action not defined");
      }
      if (!menuConfig.currency) {
        return;
      }
      currencyCode = mainModel.get("multiCampaigns") ? mainModel.get("multiCampaignCurrency").code : this.campaign.get("currency_code");
      if (currencyCode === "USD") {
        delete menuConfig.currency;
      } else {
        currencyName = this.getCurrencyName(currencyCode, T1Currencies.getCurrencyList());
        menuConfig.currency.items.campaign_currency.label = `${currencyName} (Campaign)`;
      }
    },
    serialize: function () {
      var self = this;
      var menuColumns = [];
      var cfg = this.menu_config;
      var obj;
      $.each(cfg, function (i, colObj) {
        obj = {
          items: [],
          title: colObj.label
        };
        $.each(colObj.items, function (j, item) {
          self.menu_config_table[j] = $.extend(self.menu_config_table[j] || {}, item, {
            key: j
          });
          obj.items.push({
            download_url: $.isFunction(item.download_url) ? item.download_url.call(self) : item.download_url,
            isExport: item.isExport,
            isLink: Boolean(self.menu_config_table[j].download_url),
            item_name: $.isFunction(item.label) ? item.label.call(self) : item.label,
            item_type: item.item_type,
            menu_id: j,
            selected_item: self.getSelectionState(item.item_type, item.id),
            subSection: item.subSection
          });
        });
        menuColumns.push(obj);
      });
      return {
        menuColumns: menuColumns
      };
    }
  });
});
