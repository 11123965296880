define('modules/audiences/onboarding/views/onboarding',["jQuery", "Underscore", "Backbone", "Hogan", "When", "T1", "T1Menu", "T1Permissions", "text!modules/audiences/onboarding/templates/onboarding.html", "text!modules/audiences/onboarding/templates/actionsButton.html", "text!modules/admin/shared/templates/customAttributes.html", "text!modules/admin/shared/templates/customAttributesLoading.html", "text!modules/admin/shared/templates/deletedAttributesWarning.html", "text!modules/audiences/onboarding/templates/webColumns.html", "text!modules/audiences/onboarding/templates/mediaColumns.html", "text!modules/audiences/onboarding/templates/otherDataColumns.html", "models/sessionUser", "models/pixel", "models/pixelInContainer", "utils/AttributeUtils", "collections/attributes", "collections/attributesWhiteList", "collections/organizations", "collections/pixelUniques", "modules/audiences/segments/segmentUtils", "jslibs/xdate/xdate", "Mustache"], function ($, _, Backbone, Hogan, When, T1, T1Menu, T1Permissions, template, actionsButton, attributesTemplate, attributesLoadingTemplate, deletedAttributesWarning, WebColumns, MediaColumns, DataColumns, User, Pixel, PixelInContainer, AttributeUtils, Attributes, AttributesWhiteList, Organizations, UniquesCollection, Utils, xdate, Mustache) {
  "use strict";

  const arrayify = ids => Array.isArray(ids) ? ids : [ids];
  var pixelTypes = [{
    name: "Web & Other Events",
    value: "web"
  }, {
    name: "External Data Segments",
    value: "data"
  }];
  const user = User.getUser().toJSON();
  const isGlobalAdmin = user.type === "INTERNAL" && user.role === "ADMIN" && user.scope === "GLOBAL";
  var onboardingView = Backbone.View.extend({
    template: template,
    events: {
      "changed .search-types": "handleSearchType",
      "changed .search-term": "handleSearch",
      "changed .filter-status": "filterByStatus",
      "changed .pixels-list strand-checkbox": "selectPixel",
      "click .onboard-actions": "handlePixelActions",
      "click .bulk-action": "handleBulkActions",
      "click .override-adv-custom-attributes": "overrideAdvCustomAttributes",
      "click #confirm": "closeWarning",
      "click #undo-remove": "undoRemoveAttribute",
      "removed #custom-attributes": "removeAttributeHandler",
      "change .standard-attributes": "changeStandardAttributeHandler",
      "selected .add-data": "redirectToAddData",
      "click .clearRow": "clearRepeaterRow",
      "changed #pixel-type-ddl": "handlePixelType"
    },
    initialize: function () {
      var compiledTemplate;
      var statusList = [{
        text: "All Status",
        value: "all"
      }, {
        text: "Active",
        value: "active"
      }, {
        text: "Inactive",
        value: "inactive"
      }];
      this.boundReload = this.reload.bind(this);
      T1.EventHub.subscribe("organization:select", this.boundReload);
      if (T1Permissions.check("feature", "umt_onboard_screen_inapp", "umt_onboard_screen_eme")) {
        pixelTypes = [{
          name: "Web & Other Events",
          value: "web"
        }, {
          name: "External Data Segments",
          value: "data"
        }, {
          name: "In-App Events",
          value: "inapp"
        }, {
          name: "External Media Events",
          value: "media"
        }];
      }
      compiledTemplate = Hogan.compile(this.template);
      this.renderedTemplate = compiledTemplate.render({
        model: "{{model}}",
        scope: "{{scope}}",
        index: "{{index}}",
        tag: "{{model.tag}}",
        data: "{{data}}",
        page: "{{page}}",
        search: "{{search}}",
        active: "{{active}}",
        sortOrder: "{{sortOrder}}",
        sortField: "{{sortField}}",
        pageLimit: "{{pageLimit}}",
        target: '{{findById("onboard-settings")}}',
        actions: "{{model.actions}}",
        action: "{{item.action}}",
        actionText: "{{item.actionText}}",
        id: "{{model.id}}",
        statusList: statusList,
        selectedStatus: "all",
        bulkActions: "{{bulkActions}}",
        pixelActions: "{{model.pixelActions}}",
        pixelIsInactive: "{{!model.eligible}}",
        pixelIsActive: "{{model.eligible}}",
        pixelStatus: "{{model.status}}",
        noSpin: "{{!spin}}",
        spin: "{{spin}}",
        buttonSelector: "{{buttonSelector}}",
        buttonLabel: "{{buttonLabel}}",
        showClear: "{{!scope._showRemove}}",
        webSelected: "{{webSelected}}",
        dataSelected: "{{dataSelected}}",
        inappSelected: "{{webSelected}}",
        mediaSelected: "{{mediaSelected}}",
        bulkValue: "{{item.value}}",
        bulkText: "{{item.text}}",
        showMedia: T1Permissions.check("feature", "umt_onboard_screen_eme"),
        showInApp: T1Permissions.check("feature", "umt_onboard_screen_inapp")
      }, {
        actionsButton: actionsButton,
        WebColumns: WebColumns,
        DataColumns: DataColumns,
        MediaColumns: MediaColumns
      });
      this.canEditAttributesPermission = user.edit_data_definition === "1";
      this.isAttributesViewOnlyPermission = user.view_data_definition === "1" && !this.canEditAttributesPermission;
      this.canViewSegments = self.canEditAttributesPermission || this.isAttributesViewOnlyPermission;
      this.dataAttributes = new Attributes();
      this.attributeScroll = true;
      this.attrsWhiteList = new AttributesWhiteList();
      this.whiteList = {};
      this.whiteListOptions = "";
    },
    load: function () {
      this.render().then(() => {
        this.gridContainerTemplate = this.el[0].querySelector("#grid-template");
        this.bulkActions = this.el[0].querySelector(".bulk-actions-selector");
        this.pixelTypeDDL = this.el.find("#pixel-type-ddl");
        this.pixelTypeDDL[0].data = pixelTypes;
        this.setupGrid();
      });
    },
    render: function () {
      var deferred = $.Deferred();
      if (T1.Utils.hasPolymer(this.el[0])) {
        Polymer.dom(this.el[0]).innerHTML = this.renderedTemplate;
      } else {
        this.el.html(this.renderedTemplate);
      }
      this.$el = $(this.el);
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    segmentCollCallHander(evt) {
      const scope = evt.currentTarget.scope;
      scope.grid.isLoading = true;
      scope.grid.noResultsMessage = null;
      if (scope.pixelTypeDDL) {
        scope.pixelTypeDDL.attr("disabled", "disabled");
      }
    },
    segmentCollLoadHandler(evt) {
      const scope = evt.currentTarget.scope;
      scope.grid.isLoading = false;
      scope.grid.setAllSelections(false);
      scope.grid.noResultsMessage = "No Results";
      scope.setBulkActionsStatus();
      scope.pixelTypeDDL.removeAttr("disabled");
    },
    segmentCollErrorHandler() {
      T1.Notify("error", "Internal Error. Please contact T1 Support.");
    },
    setupGrid: function () {
      this.gridContainerTemplate.webSelected = true;
      setTimeout(() => {
        this.grid = this.el[0].querySelector("#pixels-list");
        this.onboardingCollection = this.el[0].querySelector("#pixels-collection");
        this.onboardingCollection.scope = this;
        this.onboardingCollection.addEventListener("seg-collection:call", this.segmentCollCallHander);
        this.onboardingCollection.addEventListener("seg-collection:load", this.segmentCollLoadHandler);
        this.onboardingCollection.addEventListener("seg-collection:errors", this.segmentCollErrorHandler);
        Organizations.getOrganizations().getSelected().then(orgId => {
          this.orgId = orgId;
          this.onboardingCollection.orgId = orgId;
        });
        this.initOnboardingCollection();
        this.setSearchType();
        this.setBulkActionsList();
      }, 0);
    },
    initOnboardingCollection: function () {
      this.onboardingCollection.sourceUrl = T1.SEGMENT_API_BASE + "onboarding/pixel_bundles";
      this.onboardingCollection.advertiserId = this.advertiserId;
    },
    redirectToAddData: function (e) {
      const onboardType = e.originalEvent.detail.value;
      T1.Location.set(`audiences/onboard/${onboardType}/create/`);
    },
    setSearchType: function (e) {
      const pixelType = this.onboardingCollection.pixelType;
      const pixelDisplayType = pixelType === "inapp" || pixelType === "media" ? "web" : pixelType;
      const searchTypes = {
        web: [{
          name: "Pixel Name",
          value: "pixel_name"
        }, {
          name: "Pixel ID",
          value: "pixel_id"
        }, {
          name: "Group Name",
          value: "group_name"
        }, {
          name: "Group ID",
          value: "group_id"
        }, {
          name: "Advertiser",
          value: "advertiser"
        }],
        data: [{
          name: "Pixel Name",
          value: "pixel_name"
        }, {
          name: "Pixel ID",
          value: "pixel_id"
        }, {
          name: "Agency",
          value: "agency"
        }, {
          name: "Provider",
          value: "provider"
        }]
      };
      const searchTypeDDL = this.el.find(".search-types")[0];
      const selectedSet = searchTypes[pixelDisplayType];
      searchTypeDDL.data = selectedSet;
      searchTypeDDL.value = selectedSet[0].value;
    },
    handleSearchType: function (event) {
      this.entityType = event.target.value;
      if (this.el.find(".search-term")[0].value) {
        this.handleSearch();
      }
    },
    handleSearch: function (event) {
      const term = event ? event.target.value : this.el.find(".search-term").val();
      if (term) {
        this.onboardingCollection.searchOptions = {
          type: this.entityType,
          term: term
        };
      } else {
        this.onboardingCollection.searchOptions = {};
      }
    },
    handlePixelType: function (e) {
      const pixelType = e.target.value;
      const pixelDisplayType = pixelType === "inapp" ? "web" : pixelType;
      const selected = pixelTypes.find(type => type.value === pixelType);
      const unselected = pixelTypes.filter(type => type.value !== pixelType);
      unselected.forEach(type => {
        this.gridContainerTemplate[type.value + "Selected"] = false;
      });
      this.gridContainerTemplate[pixelDisplayType + "Selected"] = true;
      setTimeout(() => {
        this.grid = this.el[0].querySelector("#pixels-list");
        this.grid.set("data", []);
        this.grid.sortField = "created_on";
        this.grid.sortOrder = -1;
        this.onboardingCollection.pixelType = pixelType;
        this.setSearchType();
        this.setBulkActionsList();
      }, 0);
    },
    filterByStatus: function (event) {
      this.onboardingCollection.eligible = event.target.value;
    },
    selectPixel: function (event) {
      if (event.target.id === "selectAll") {
        return;
      }
      this.setBulkActionsStatus();
    },
    setBulkActionsStatus: function () {
      if (this.grid.selected.length > 0) {
        this.bulkActions.removeAttribute("disabled");
      } else {
        this.bulkActions.setAttribute("disabled", true);
      }
    },
    setBulkActionsList: function () {
      const template = this.el.find("#bulk-actions-template")[0];
      const activate = {
        text: "Activate",
        value: "activate"
      };
      const deactivate = {
        text: "Deactivate",
        value: "deactivate"
      };
      const email_code = {
        text: "Email Code",
        value: "email_code"
      };
      const export_load_history = {
        text: "Export Load History",
        value: "export_load_history"
      };
      const email_summary = {
        text: "Email Summary",
        value: "email_summary"
      };
      switch (this.onboardingCollection.pixelType) {
        case "web":
          template.bulkActions = [activate, deactivate, email_code, export_load_history];
          break;
        case "data":
          template.bulkActions = [activate, deactivate];
          break;
        case "inapp":
          template.bulkActions = [activate, deactivate, email_summary, export_load_history];
          break;
        case "media":
          template.bulkActions = [activate, deactivate, email_summary, export_load_history];
          break;
      }
    },
    handleBulkActions: function (e) {
      const action = e.target.value;
      const pixelIds = this.grid.selected.map(model => model.id);
      switch (action) {
        case "activate":
        case "deactivate":
          this.setPixelStatus(action, pixelIds);
          break;
        case "email_summary":
          this.showEmailSummaryModal(pixelIds);
          break;
        case "email_code":
          this.showEmailCodeModal(pixelIds);
          break;
        case "export_load_history":
          this.showExportLoadsModal(pixelIds);
          break;
      }
    },
    handlePixelActions: function (event) {
      var $actionObj = $(event.target);
      var action = event.target.value;
      var $gridRow = $actionObj.closest("strand-grid-item");
      var menu = $actionObj.closest("strand-menu")[0];
      var pixelModel = $gridRow[0].model;
      var pixelId = pixelModel.id;
      switch (action) {
        case "edit-group":
          this.editGroup(event);
          break;
        case "edit-pixel":
          this.editPixel(pixelModel);
          break;
        case "deactivate":
          this.setPixelStatus("deactivate", pixelId);
          break;
        case "activate":
          this.setPixelStatus("activate", pixelId);
          break;
        case "email-code":
          this.showEmailCodeModal(pixelId);
          break;
        case "email-summary":
          this.showEmailSummaryModal(pixelId);
          break;
        case "export-load-history":
          this.showExportLoadsModal(pixelId);
          break;
        case "attributes":
          this.editDataAttributeMapping(pixelModel);
          break;
        case "add-pixels-to-container":
          this.addPixelsToContainerModal(pixelModel);
          break;
      }
      menu.toggle();
    },
    onboardingActions: function (opts) {
      const {
        data: data,
        onSuccess: onSuccess,
        onError: onError,
        buttonTemplate: buttonTemplate,
        modal: modal,
        url: url
      } = opts;
      if (buttonTemplate) {
        buttonTemplate.spin = true;
      }
      this.grid.isLoading = true;
      return $.ajax({
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(data),
        url: url || T1.ENV.SEGMENT_API_BASE + "onboarding/pixel_bundles",
        success: onSuccess,
        error: (jqXHR, status, detail) => {
          if (onError) {
            onError(jqXHR, status, detail);
          } else {
            T1.EventHub.publish("comm:error", {
              detail: detail
            });
          }
        },
        complete: () => {
          if (modal) {
            modal.hide();
          }
          if (buttonTemplate) {
            buttonTemplate.spin = false;
          }
          this.grid.isLoading = false;
        },
        timeout: 3e4
      });
    },
    setPixelStatus: function (action, ids) {
      const key = action === "activate" ? "enable" : "disable";
      const pixelIds = arrayify(ids);
      const data = {
        [key]: pixelIds
      };
      const onSuccess = resp => {
        let {
          count: count
        } = resp.meta;
        let label = action === "activate" ? "activated" : "deactivated";
        let message;
        if (count > 1) {
          message = count + " pixels " + label;
        } else {
          let label = action === "activate" ? "active" : "inactive";
          message = "Pixel eligibility was set to " + label;
        }
        T1.Notify("message", message);
        this.updatePixelStatus(pixelIds, action);
      };
      this.onboardingActions({
        data: data,
        onSuccess: onSuccess
      });
    },
    updatePixelStatus: function (ids, action) {
      ids.forEach(id => {
        let {
          onboardingCollection: onboardingCollection
        } = this;
        let {
          data: data
        } = onboardingCollection;
        let status = action === "activate" ? "active" : "inactive";
        let pixel = data.find(pixel => pixel.id === id);
        let index = data.indexOf(pixel);
        onboardingCollection.set("data." + index + ".status", status);
        onboardingCollection.set("data." + index + ".eligible", action === "activate");
        onboardingCollection.set("data." + index + ".pixelActions", onboardingCollection.getPixelActions(pixel));
      });
    },
    editGroup: function (event) {
      var groupType = $(event.currentTarget).closest("strand-grid-item").find("#data_type")[0].innerText.toLowerCase().split(" ")[0];
      var groupId = $(event.currentTarget).closest("strand-grid-item").find("#group_id")[0].innerText;
      groupType = groupType === "external" ? "media" : groupType;
      T1.Location.set(`audiences/onboard/${groupType}/edit/${groupId}`);
    },
    editPixel: function (model) {
      const pixelType = model.pixel_type;
      const id = model.id;
      T1.Location.set("audiences/pixels/" + pixelType + "/" + id);
    },
    showEmailSummaryModal: function (ids) {
      this.showEmailCodeModal(ids, "Email Summary");
    },
    showEmailCodeModal: function (ids, altHeader) {
      const emailPixelModal = document.getElementById("emailPixelModal");
      const modalHeader = this.el[0].querySelector("#emailPixelModal strand-header");
      const buttonTemplate = document.getElementById("email-button-template");
      if (altHeader) {
        Polymer.dom(modalHeader).innerHTML = altHeader;
      } else {
        Polymer.dom(modalHeader).innerHTML = "Email Pixel Code";
      }
      buttonTemplate.buttonSelector = "send-button";
      buttonTemplate.buttonLabel = "Send";
      buttonTemplate.spin = false;
      emailPixelModal.show();
      requestAnimationFrame(() => {
        const $cancelButton = $(emailPixelModal).find("#cancel-button");
        const $sendButton = $(emailPixelModal).find("#send-button");
        const $emailList = $(emailPixelModal).find("#emails");
        const unbind = () => {
          $sendButton.unbind();
          $cancelButton.unbind();
        };
        const checkEmails = function () {
          let values = $emailList.val();
          if (values && Utils.validateEmailList(values)) {
            $sendButton.removeAttr("disabled");
          } else {
            $sendButton.attr("disabled", "disabled");
          }
        };
        $cancelButton.on("click", () => {
          emailPixelModal.hide();
          unbind();
        });
        $emailList.on("keyup", checkEmails);
        $sendButton.on("click", () => {
          let emails = emailPixelModal.querySelector("#emails").value;
          let message = emailPixelModal.querySelector("#message").value;
          if (altHeader) {
            this.sendSummaryByEmail(emails, message, ids, buttonTemplate, emailPixelModal);
          } else {
            this.sendPixelCodeByEmail(emails, message, ids, buttonTemplate, emailPixelModal);
          }
          unbind();
        });
        checkEmails();
      });
    },
    showExportLoadsModal: function (ids) {
      const exportPixelLoadHistoryModal = document.getElementById("exportPixelLoad");
      const buttonTemplate = document.getElementById("export-button-template");
      const days = $("#export-window", $(this.el));
      buttonTemplate.buttonSelector = "export-button";
      buttonTemplate.buttonLabel = "Export";
      buttonTemplate.spin = false;
      exportPixelLoadHistoryModal.show();
      days.on("changed", () => {
        let numberOfDays = parseInt(days.val(), 10);
        if (numberOfDays > 0 && numberOfDays <= 45) {
          days[0].error = false;
          $("#export-button", $(this.el)).attr("disabled", false);
        } else {
          days[0].error = true;
          $("#export-button", $(this.el)).attr("disabled", true);
        }
        return;
      });
      requestAnimationFrame(() => {
        const $cancelButton = $(exportPixelLoadHistoryModal).find("#cancel-export");
        const $sendButton = $(exportPixelLoadHistoryModal).find("#export-button");
        const unbind = () => {
          $sendButton.unbind();
          $cancelButton.unbind();
        };
        $cancelButton.on("click", () => {
          exportPixelLoadHistoryModal.hide();
          unbind();
        });
        $sendButton.on("click", () => {
          let days = $("#export-window").val();
          this.exportLoadHistory(ids, days, buttonTemplate, exportPixelLoadHistoryModal);
          unbind();
        });
      });
    },
    sendPixelCodeByEmail: function (emails, message, ids, buttonTemplate, modal) {
      const data = {
        email_to: emails,
        templates: arrayify(ids)
      };
      if (message) {
        data.email_message = message;
      }
      const onSuccess = () => {
        T1.Notify("message", "Pixel code sent successfully");
      };
      this.onboardingActions({
        modal: modal,
        data: data,
        onSuccess: onSuccess,
        buttonTemplate: buttonTemplate
      });
    },
    sendSummaryByEmail: function (emails, message, ids, buttonTemplate, modal) {
      const url = `${T1.ENV.SEGMENT_API_BASE}onboarding/inapp/templates`;
      const data = {
        email_to: emails,
        summaries: arrayify(ids)
      };
      if (message) {
        data.email_message = message;
      }
      const onSuccess = () => {
        T1.Notify("message", "Summary sent successfully");
      };
      this.onboardingActions({
        modal: modal,
        data: data,
        onSuccess: onSuccess,
        buttonTemplate: buttonTemplate,
        url: url
      });
    },
    exportLoadHistory: function (ids, days, buttonTemplate, modal) {
      const pixelIds = arrayify(ids);
      const uniquesCollection = new UniquesCollection();
      const now = new XDate();
      days = days !== "" ? days : 7;
      uniquesCollection.fetchOptions = {
        dimensions: "pixel_name,pixel_id,referrer,referrer_rank",
        filter: "organization_id=" + this.orgId + "&pixel_id=" + pixelIds,
        time_window: "last_" + days + "_days",
        time_rollup: "by_day",
        order: "-mm_date,pixel_id,referrer_rank",
        having: "referrer_rank=1,2,3,4,5,6,7,8,9,10,999"
      };
      uniquesCollection.urlString = "event_pixel_loads";
      buttonTemplate.spin = true;
      uniquesCollection.sync("read", uniquesCollection, {
        action: "download.csv",
        dataType: "html",
        onSuccess: () => {
          modal.hide();
          buttonTemplate.spin = false;
        },
        onError: (jqXHR, status, detail) => {
          buttonTemplate.spin = false;
          modal.hide();
          T1.EventHub.publish("comm:error", {
            detail: detail
          });
        },
        processAjaxResponse: function (resp) {
          var blob = new Blob([resp], {
            type: "attachment/csv"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var elem = document.body.appendChild(a);
          var handler = function () {
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, 1e3);
            document.body.removeChild(elem);
            elem.removeEventListener("click", handler);
          };
          a.href = url;
          a.download = now.toString("yyyy/MM/dd") + "-pixel_placement_qa_" + days + "_day(s).csv";
          elem.addEventListener("click", handler);
          elem.click();
          return {
            statusCode: "ok",
            jsonData: []
          };
        }
      });
    },
    addPixelsToContainerModal: function (model) {
      const addPixelsToContainerModal = document.getElementById("addPixelsToContainerModal");
      const buttonTemplate = document.getElementById("addPixel-button-template");
      const pixelId = model.id;
      const pixel = new Pixel({
        id: pixelId
      });
      const loader = $(this.el).find(".addPixel-loader")[0];
      const $repeater = $(this.el).find("#addPixel-row");
      loader.show();
      $repeater.hide();
      $repeater[0].validation = function (data, added, modified, removed) {
        return [...added, ...modified].map(function (model) {
          return {
            cId: model.cId,
            tag: model.tag ? null : "Empty Row"
          };
        });
      };
      const addPixelsToContainerToRepeater = (repeater, pixels) => {
        let data = pixels.map(pixel => ({
          tag: pixel.get("tag"),
          version: pixel.get("version"),
          id: pixel.id
        }));
        repeater.set("data", data);
      };
      buttonTemplate.buttonSelector = "save-button";
      buttonTemplate.buttonLabel = "Save";
      buttonTemplate.spin = false;
      addPixelsToContainerModal.show();
      pixel.fetch({
        params: {
          with: ["advertiser,agency", "pixel_bundle_urls", "pixels"]
        },
        success: model => {
          let pixelsInContainer = model.get("pixel").models;
          let pixelsInContainerRepeater = $repeater[0];
          loader.hide();
          $repeater.show();
          if (pixelsInContainer && pixelsInContainer.length) {
            addPixelsToContainerToRepeater(pixelsInContainerRepeater, pixelsInContainer);
          } else {
            pixelsInContainerRepeater.set("data", [{}]);
          }
        }
      });
      requestAnimationFrame(() => {
        const $cancelButton = $(addPixelsToContainerModal).find("#cancel-addPixel");
        const $saveButton = $(addPixelsToContainerModal).find("#save-button");
        const unbind = () => {
          $saveButton.unbind();
          $cancelButton.unbind();
        };
        $cancelButton.on("click", () => {
          addPixelsToContainerModal.hide();
          unbind();
        });
        $saveButton.on("click", () => {
          this.savePixelsToContainer(addPixelsToContainerModal, pixelId, unbind);
        });
      });
    },
    savePixelsToContainer: function (modal, id, onComplete) {
      const repeater = modal.querySelector("strand-repeater");
      const pixelsToRemove = repeater.removed;
      const pixelsToSave = repeater.added.concat(repeater.modified);
      const loadPromises = [];
      const buttonTemplate = document.getElementById("addPixel-button-template");
      function savePixelToContainer(pixelId, object) {
        const def = $.Deferred();
        let pixelInContainer = new PixelInContainer({
          tag: object.tag,
          bundle_id: pixelId,
          pixel_type: "event",
          version: object.version
        });
        if (object.id) {
          pixelInContainer.id = object.id;
        }
        pixelInContainer.save({}, {
          success: () => {
            def.resolve();
          },
          onError: () => {
            def.reject();
          }
        });
        loadPromises.push(def.promise());
      }
      function deletePixelInContainer(pixelId, object) {
        const def = $.Deferred();
        let pixelInContainer = new PixelInContainer();
        pixelInContainer.set({
          id: object.id,
          version: object.version
        });
        pixelInContainer.drop(() => {
          def.resolve();
        }, () => {
          def.reject();
        });
        loadPromises.push(def.promise());
      }
      if (repeater.validate()) {
        buttonTemplate.spin = true;
        if (pixelsToSave && pixelsToSave.length) {
          pixelsToSave.forEach(pixel => {
            savePixelToContainer(id, pixel);
          });
        }
        if (pixelsToRemove && pixelsToRemove.length) {
          pixelsToRemove.forEach(pixel => {
            deletePixelInContainer(id, pixel);
          });
        }
        $.when.apply($, loadPromises).then(() => {
          if (loadPromises.length) {
            onComplete();
            T1.Notify("message", "Pixel updated successfully!");
          }
          modal.hide();
        }).fail(() => {
          onComplete();
          T1.Notify("error", "Error: couldn't update pixel.");
          modal.hide();
        });
      }
    },
    clearRepeaterRow: function (e) {
      const targetIcon = e.srcElement;
      const repeater = this.$(targetIcon).closest("strand-repeater")[0];
      const deleted = [...repeater.removed, repeater.data[0]];
      repeater.set("data", [{
        cId: 1,
        tag: ""
      }]);
      repeater.set("removed", deleted);
    },
    editDataAttributeMapping: function (pixelModel) {
      const editDataAttributeMappingModal = this.el[0].querySelector("#editDataAttributeMapping");
      const buttonTemplate = document.getElementById("edit-mapping-button-template");
      this.pixelModel = pixelModel;
      this.model = new Pixel({
        id: pixelModel.id
      });
      this.model.fetch();
      this.showAttributesLoading();
      buttonTemplate.buttonSelector = "edit-mapping-button";
      buttonTemplate.buttonLabel = "Save";
      buttonTemplate.spin = false;
      editDataAttributeMappingModal.show();
      requestAnimationFrame(() => {
        const $cancelButton = $(editDataAttributeMappingModal).find("#cancel-mapping");
        const $sendButton = $(editDataAttributeMappingModal).find("#edit-mapping-button");
        const unbind = () => {
          $sendButton.unbind();
          $cancelButton.unbind();
        };
        unbind();
        $cancelButton.on("click", () => {
          editDataAttributeMappingModal.hide();
          unbind();
        });
        $sendButton.on("click", () => {
          this.saveAttrMapping(buttonTemplate, editDataAttributeMappingModal, unbind);
        });
      });
      this.getDataAttributes(pixelModel).then(() => {
        this.loadAttributeRows(pixelModel);
      });
    },
    saveAttrMapping: function (buttonTemplate, modal, unbind) {
      var canSubmit = this.attributeRepeater ? this.attributeRepeater.validate() : true;
      var footer = $(modal).find("strand-footer")[0];
      var pixelContext = Object.assign(this, this.pixelModel);
      pixelContext.model.attributes.type = "pixel_bundle";
      pixelContext.model.id = pixelContext.model.id.toString();
      buttonTemplate.spin = true;
      if (canSubmit) {
        footer.hideMessage();
        unbind();
        AttributeUtils.saveAttributes.call(pixelContext, this.attributeRepeater).then(function () {
          T1.Notify("message", "Attribute mappings updated successfully!");
          modal.hide();
        }, function (message, errorType) {
          var errorMessage = errorType === "failure" ? "attribute creation failed" : "some attributes did not save";
          T1.Notify(errorType === "failure" ? "error" : "warning", "Pixel updated successfully, but " + errorMessage);
          modal.hide();
        });
      } else {
        footer.message = "There are errors on the page.";
        footer.type = "error";
        footer.showMessage();
        buttonTemplate.spin = false;
      }
    },
    loadAttributeRows: function (pixelModel) {
      var pixelModel = pixelModel || this.pixelModel;
      if (this.whiteListOptions) {
        this.showAttributeRows(pixelModel);
      } else {
        this.showAttributesLoading();
        this.whiteListOptions = "";
        this.attrsWhiteList.fetch({
          success: data => {
            $.each(data.models, (index, model) => {
              var trimmedKey = model.attributes.key.replace(/^query\./, "");
              this.whiteList[trimmedKey] = {
                key: model.attributes.key,
                name: model.attributes.name,
                data_type: model.attributes.data_type
              };
              this.whiteListOptions += '<option value="' + trimmedKey + '">' + model.attributes.name + "</option>";
            });
            this.showAttributeRows(pixelModel);
          },
          statusInvalid: () => {
            this.showAttributesLoadingError("Failed to load Data Attributes.");
          }
        });
      }
    },
    getDataAttributes: function (pixelModel) {
      var deferred = When.defer();
      this.dataAttributes.pixelId = pixelModel.id;
      delete this.dataAttributes.advertiserId;
      this.dataAttributes.fetch({}).then(() => {
        var models = this.dataAttributes.models;
        if (models.length === 0) {
          delete this.dataAttributes.pixelId;
          this.dataAttributes.advertiserId = this.model.get("advertiser_id");
          this.dataAttributes.fetch({}).then(function () {
            deferred.resolve();
          });
          this.overrideDisabled = false;
          this.overrideSelected = false;
        } else {
          this.overrideDisabled = true;
          this.overrideSelected = true;
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    showAttributeRows: function (pixelModel) {
      var $attributeEl = this.el.find(".attributes-list");
      var tData, compiledTemplate, $standardAttrEl;
      if (this.isAttributesViewOnlyPermission) {
        this.isAttributesViewOnly = true;
        this.canEditAttributes = false;
        this.overrideDisabled = true;
      } else if (this.canEditAttributesPermission) {
        if (this.overrideSelected) {
          this.isAttributesViewOnly = false;
          this.canEditAttributes = true;
        } else {
          this.isAttributesViewOnly = true;
          this.canEditAttributes = false;
        }
      }
      tData = {
        model: "{{model}}",
        scope: "{{scope}}",
        index: "{{index}}",
        saved: "{{model.saved}}",
        unsaved: "{{!model.saved}}",
        cId: "{{model.cId}}",
        fixedKey: "{{model.fixedKey}}",
        customKey: "{{!model.fixedKey}}",
        key: "{{model.key}}",
        name: "{{model.name}}",
        data_type: "{{model.data_type}}",
        canEditAttributes: this.canEditAttributes,
        isAttributesViewOnly: this.isAttributesViewOnly,
        overrideSelected: this.overrideSelected,
        overrideDisabled: this.overrideDisabled,
        standardAttributeDisabled: this.isAttributesViewOnly
      };
      compiledTemplate = Mustache.to_html(attributesTemplate, tData);
      if (Polymer && Polymer.dom) {
        Polymer.dom($attributeEl[0]).innerHTML = compiledTemplate;
      } else {
        $attributeEl.html(compiledTemplate);
      }
      this.attributeRepeater = Polymer.dom($attributeEl[0]).querySelector("#custom-attributes");
      this.attributeRepeater.validation = AttributeUtils.validation.bind(isGlobalAdmin);
      this.pixelId = pixelModel.id;
      this.pixelAdvertiserId = this.model.get("advertiser_id");
      AttributeUtils.loadData(this, this.isAttributesViewOnly);
      $standardAttrEl = $attributeEl.find(".standard-attributes");
      $standardAttrEl.chosen({
        width: "95%",
        placeholder_text_multiple: "Select Standard Attributes",
        search_contains: true
      });
      $standardAttrEl.html(this.whiteListOptions);
      if (this.savedStandardAttributes) {
        $standardAttrEl.val(Object.keys(this.savedStandardAttributes));
      }
      $standardAttrEl.trigger("liszt:updated");
      this.el.find(".override-adv-custom-attributes").show();
      this.el.find(".attributes-list").removeClass("hidden");
    },
    overrideAdvCustomAttributes: function () {
      this.overrideSelected = this.el.find(".override-adv-custom-attributes").val();
      this.loadAttributeRows();
    },
    showAttributesLoading: function () {
      var $attributeEl = this.el.find(".attributes-list");
      var compiledTemplate = Mustache.to_html(attributesLoadingTemplate, {});
      if (Polymer && Polymer.dom) {
        Polymer.dom($attributeEl[0]).innerHTML = compiledTemplate;
      } else {
        $attributeEl.html(compiledTemplate);
      }
      $attributeEl.find(".data-attributes-loader").show();
      $attributeEl.find(".error-msg").hide();
      $attributeEl.removeClass("hidden");
    },
    showAttributesLoadingError: function (error_msg) {
      var $attributeEl = this.el.find(".attributes-list");
      $attributeEl.find(".data-attributes-loader").hide();
      $attributeEl.find(".error-msg").html(error_msg);
      $attributeEl.find(".error-msg").show();
    },
    closeWarning: function () {
      AttributeUtils.closeWarning.call(this);
    },
    undoRemoveAttribute: function () {
      AttributeUtils.undoDeleteAttribute.call(this);
      this.closeWarning();
    },
    showDeletedAttributeWarning: function (warningTemplate) {
      AttributeUtils.showDeletedAttributeWarning.call(this, warningTemplate);
    },
    removeAttributeHandler: function () {
      AttributeUtils.removeAttributeHandler(this);
    },
    changeStandardAttributeHandler: function (event, params) {
      var deselectedKey, deSelectedAttribute;
      this.isFormElementsChanged = true;
      if ("deselected" in params) {
        deselectedKey = params.deselected;
        if (deselectedKey in this.savedStandardAttributes) {
          deSelectedAttribute = this.savedStandardAttributes[deselectedKey];
          AttributeUtils.removeAttributeHandler(this, deSelectedAttribute);
        }
      }
    },
    setFooter: function (message, type) {
      var footer = document.querySelector("strand-footer");
      footer.message = message;
      footer.type = type;
      footer.showMessage();
      setTimeout(function () {
        self.clearFooter();
      }, 1e4);
    },
    clearFooter: function () {
      var footer = document.querySelector("strand-footer");
      footer.hideMessage();
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      this.onboardingCollection.removeEventListener("seg-collection:call", this.segmentCollCallHander);
      this.onboardingCollection.removeEventListener("seg-collection:load", this.segmentCollLoadHandler);
      this.onboardingCollection.removeEventListener("seg-collection:errors", this.segmentCollErrorHandler);
      $(this.el).empty();
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
    }
  });
  return onboardingView;
});
