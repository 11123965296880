define('modules/campaign/bulkedit/strategies/views/readOnly',["jQuery", "T1View", "T1Layout", "text!../templates/readOnly.html"], function ($, T1View, T1Layout, template) {
  "use strict";

  const StrategiesReadOnly = T1View.extend({
    template: template,
    events: {},
    initialize: function ({
      executionStatus: executionStatus,
      bulkeditor: bulkeditor
    }) {
      this.executionStatus = executionStatus;
      this.bulkeditor = bulkeditor;
      this.getErroredStrategies();
      this.initLayout();
    },
    getErroredStrategies: function () {
      const errors = this.bulkeditor.get("executionErrors");
      const strategyErrors = [];
      $.each(errors, function (index, {
        id: id,
        error_message: error_message,
        subcollection: subcollection
      }) {
        strategyErrors.push({
          id: id,
          error: error_message,
          type: subcollection
        });
      });
      this.strategyErrors = strategyErrors;
    },
    load: function () {
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
        }
      });
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body-strategies-errors",
        template: '<div class="w-grid"></div>',
        layout: {
          ".w-grid": [{
            module: "campaign/bulkedit/strategies",
            viewType: "readOnlyBrowse",
            options: {
              executionStatus: this.executionStatus,
              strategyErrors: this.strategyErrors,
              bulkeditor: this.bulkeditor
            }
          }]
        }
      });
    },
    serialize: function () {
      return {};
    }
  });
  return StrategiesReadOnly;
});
