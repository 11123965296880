define('modules/reporting/campaigns/dataExport/views/dataExport',['require','Underscore','jQuery','collections/advertisers','collections/campaigns','collections/pixels','../collections/savedReports','../models/saveReport','T1','T1Layout','T1UtilsAsync','T1View','text!../templates/dataExport.html','models/userPreferences'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Advertisers = require("collections/advertisers");
  const Campaigns = require("collections/campaigns");
  const Pixels = require("collections/pixels");
  const SavedReports = require("../collections/savedReports");
  const SaveReport = require("../models/saveReport");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1UtilsAsync = require("T1UtilsAsync");
  const T1View = require("T1View");
  const template = require("text!../templates/dataExport.html");
  const UserPreferences = require("models/userPreferences");
  const downloadTokens = {};
  const checkDownloadProgressInterval = 2e3;
  const defaultLocation = "#reports/campaigns/dataExport";
  const sectionID = "dataExport";
  const tokenKey = "download_token";
  const trackingSection = "Reporting - Data Export";
  const T1Location = T1.Location;
  const T1Notify = T1.Notify;
  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    manageGridStatus: "All",
    template: template,
    eventhubEvents: {
      "header.createReport"() {
        this.updateView({
          location: `${defaultLocation}/create`,
          target: "create"
        });
      },
      "header.changeStatus"(status) {
        this.manageGridStatus = status;
        this.preferences.save();
        this.loadSavedReports(T1UtilsAsync.makeDeferral());
      },
      "grid.exportReport": "exportCSV",
      "update:view": "updateView",
      "update:canUnload"({
        formElementsChanged: formElementsChanged,
        cancelUnloadFn: cancelUnloadFn
      }) {
        this.formElementsChanged = formElementsChanged;
        this.cancelUnloadFn = cancelUnloadFn;
      },
      "footer.saveExportReport": "verifyForm",
      "footer.cancelSaveReport": "verifyForm",
      "emailSettings.changed"(state) {
        this.emailFormChanged = state;
      },
      "manage.updateReportStatus"(id) {
        this.SaveReportModel.id = id;
        this.saveReport("saveStatus", null, null, id);
      },
      "hide:manageLoader": "hideLoader"
    },
    showLoader() {
      this.$manageLoader.fadeIn();
    },
    hideLoader() {
      this.$manageLoader.fadeOut();
    },
    initialize({
      models: models
    }) {
      const Models = models;
      this.ReportingMainModel = Models.mainModel;
      this.ReportingMainModel.setSection(sectionID);
      this.DataExportModel = Models.sectionModels.DataExport;
      this.DataExportModel.setReportsTransition(Models.mainMeta.get("reports"));
      this.SaveReportModel = new SaveReport();
      this.SavedReportsCollection = new SavedReports([], {
        canCache: false,
        sortBy: "name,report",
        filterBy: function () {
          switch (this.manageGridStatus) {
            case "All":
              return "";
            case "Active":
              return "active=true";
            case "Inactive":
              return "active=false";
          }
        }.bind(this)
      });
      this.initPreferences();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", "data_export"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["manageGridStatus"]);
      this.DataExportModel.setManageGridStatus(this.manageGridStatus);
    },
    initLayouts() {
      const moduleLoc = "reporting/campaigns/dataExport/";
      this.manageLayout = new T1Layout({
        el: () => this.$(".manage"),
        template: '<div class="manage-layout"></div>',
        layout: {
          ".manage-layout": [{
            module: `${moduleLoc}manage`,
            options: {
              dataExportModel: this.DataExportModel
            },
            viewType: "manage"
          }]
        }
      });
      this.createEditLayout = new T1Layout({
        el: () => this.$(".create-edit"),
        template: '<div class="create-layout"></div>',
        layout: {
          ".create-layout": [{
            module: `${moduleLoc}createEdit`,
            viewType: "createEdit",
            options: {
              dataExportModel: this.DataExportModel,
              saveReportModel: this.SaveReportModel
            }
          }]
        }
      });
      this.assignLayout();
    },
    assignLayout() {
      this.layout = this.reportAction === "manage" ? this.manageLayout : this.createEditLayout;
    },
    load() {
      const currentLocation = T1Location.get(true);
      const deferred = T1UtilsAsync.makeDeferral();
      this.ReportingMainModel.setSection(sectionID, true);
      this.ReportingMainModel.setTrackingSection(trackingSection);
      switch (true) {
        case currentLocation.includes("create"):
          this.reportAction = "create";
          break;
        case currentLocation.includes("edit"):
          this.reportAction = "edit";
          break;
        default:
          this.reportAction = "manage";
      }
      this.initLayouts();
      this.progressWatcher();
      this.render().then(() => {
        this.$manageLoader = this.$(".manage-loader");
        this.checkAPItimeOut();
        this.loadSavedReports(deferred).then(() => {
          this.layout.load();
        });
      });
    },
    unload() {
      this.SaveReportModel.abort();
      this.SavedReportsCollection.abort();
      if (this.LLECollections) {
        if (this.LLECollections.campaign && this.LLECollections.campaign.collection.currentFetch) {
          this.LLECollections.campaign.collection.currentFetch.abort();
        }
        if (this.LLECollections.advertiser && this.LLECollections.advertiser.collection.currentFetch) {
          this.LLECollections.advertiser.collection.currentFetch.abort();
        }
        this.LLECollections = null;
      }
      this.preferences = null;
      this.manageGridStatus = null;
      this.emailFormChanged = null;
      this.$manageLoader = null;
      this.cleanUpDownload();
      clearInterval(this.checkDownloadProgress);
      this.SaveReportModel = null;
      this.SavedReportsCollection = null;
    },
    canUnload(args) {
      if (this.formElementsChanged) {
        if (this.cancelUnloadFn instanceof Function) {
          this.cancelUnloadFn(args);
        }
        return false;
      }
      return true;
    },
    loadLowerLevelEntities(lowestLevelEntity, entity, ids, dataLoad, selectedReport) {
      let collection;
      const pageLimit = 100;
      const config = {
        filterConfiguration: {
          enableEvents: false,
          filters: [{
            entity: "agency",
            fetchOnChange: false
          }]
        }
      };
      switch (lowestLevelEntity) {
        case "campaign":
          collection = new Campaigns([], config);
          break;
        case "pixel":
          collection = new Pixels([], config);
          collection.noUniquesData = true;
          break;
        default:
          collection = new Advertisers([], config);
      }
      if (selectedReport === "Data Pixel Loads") {
        collection.urlFilter.setConfiguration([{
          entity: "agency",
          fetchOnChange: false
        }]);
      }
      collection.urlFilter.set({
        entity: entity,
        id: `(${ids})`
      });
      collection.canCache = true;
      collection.entityType = lowestLevelEntity.toUpperCase();
      collection.fetchOptions = {
        sort_by: "-status,name"
      };
      collection.isPaginated = false;
      collection.page = 0;
      collection.pageLimit = pageLimit;
      this.LLECollections[entity] = {
        collection: collection
      };
      this.LLECollections[entity].collection.fetch({
        success: function (response) {
          dataLoad.models = response.models;
          dataLoad.resolve();
        }
      });
    },
    loadSavedReports(deferred) {
      this.showLoader();
      this.SavedReportsCollection.reset(null, {
        silent: true
      });
      this.SavedReportsCollection.fetch({
        success: collection => {
          this.DataExportModel.setSavedReports(collection, downloadTokens);
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    checkAPItimeOut() {
      const now = new Date().getTime();
      const apiTimeoutLimit = this.DataExportModel.getApiTimeoutLimit();
      for (const [key, token] of Object.entries(downloadTokens)) {
        if (token.state === "progress" && now < token.timestamp + apiTimeoutLimit) {
          const remainingTime = apiTimeoutLimit - (now - token.timestamp);
          this.apiTimeOutWatcher(key, remainingTime);
        }
      }
    },
    updateView(args) {
      this.reportAction = args.target;
      this.updateLayout(args.location);
    },
    updateLayout(location) {
      const deferred = T1UtilsAsync.makeDeferral();
      function setLocation() {
        this.layout.load();
        T1Location.set(location, {
          silent: true
        });
        T1Location.push(location);
        T1Publish("router:updateCurrentLocation", T1Location.getRoute());
      }
      function layoutUpdate() {
        this.layout.destroy();
        this.assignLayout();
        this.emailFormChanged = null;
        setLocation.call(this);
      }
      if (this.reportAction === "manage") {
        const reportToExport = this.DataExportModel.get("reportToExport");
        this.loadSavedReports(deferred).then(() => {
          if (reportToExport) {
            this.DataExportModel.set({
              reportToExport: null
            });
            this.exportCSV(reportToExport);
          }
          layoutUpdate.call(this);
        });
      } else {
        layoutUpdate.call(this);
      }
    },
    updateLocation(model, goBack, sectionTarget, action) {
      const currentLocation = T1Location.get(true);
      let editLocation;
      if (goBack) {
        if (sectionTarget) {
          T1Location.set(sectionTarget, {
            silent: false
          });
        } else {
          this.updateView({
            location: defaultLocation,
            target: "manage"
          });
        }
      } else {
        if (currentLocation.includes("create")) {
          editLocation = `${currentLocation.replace("create", "edit")}/${model.id}`;
          T1Publish("dataExport.showFooterSaveAsNewBtn");
        } else if (action === "saveAsNew" && currentLocation.includes("edit")) {
          const locationUrlArray = currentLocation.split("/");
          locationUrlArray[locationUrlArray.length - 1] = `${model.id}`;
          editLocation = locationUrlArray.join("/");
        }
        if (editLocation) {
          T1Location.set(editLocation, {
            silent: true
          });
          T1Location.push(editLocation);
          T1Publish("router:updateCurrentLocation", T1Location.getRoute());
        }
      }
    },
    progressWatcher() {
      this.checkDownloadProgress = setInterval(() => {
        if (document.cookie.includes(tokenKey)) {
          this.getCookie();
          this.cleanUpDownload();
        }
      }, checkDownloadProgressInterval);
    },
    apiTimeOutWatcher(reportID, remainingTime) {
      const apiTimeoutLimit = this.DataExportModel.getApiTimeoutLimit();
      const time = remainingTime ? remainingTime : apiTimeoutLimit;
      this.apiTimeOutTimer = setTimeout(() => {
        if (reportID) {
          downloadTokens[reportID].state = "timeout";
          this.DataExportModel.setSavedReportStatus(reportID, "timeout");
        }
        this.cleanUpDownload();
        T1Notify("error", "Request timed out, please edit<br>and export the report with a<br>more limited selection.", false);
      }, time);
    },
    getCookie() {
      const cookies = document.cookie.split("; ");
      const tokenCookie = cookies.filter(cookie => cookie.includes(tokenKey));
      const tokenValue = tokenCookie && tokenCookie[0].replace(`${tokenKey}=`, "");
      for (const reportID in downloadTokens) {
        if (T1.Utils.hasOwnProp(downloadTokens, reportID)) {
          if (downloadTokens[reportID].timestamp === Number(tokenValue)) {
            downloadTokens[reportID].state = "download";
            this.DataExportModel.setSavedReportStatus(reportID, "download");
            break;
          }
        }
      }
    },
    cleanUpCookie() {
      document.cookie = `${tokenKey}=deleted; expires=${new Date(0).toUTCString()}; path=/`;
    },
    cleanUpDownload() {
      this.cleanUpCookie();
      clearTimeout(this.apiTimeOutTimer);
      T1Publish("dataExport.footerResetBtns");
    },
    updateTokens(id) {
      downloadTokens[id] = {
        timestamp: new Date().getTime(),
        state: "progress"
      };
    },
    verifyForm(args) {
      let formIsValid, isEmailAddressValid, isFrequencyValid, isMessageValid, isMonthlyValid, isPreferredAMPMValid, isPreferredTimeAMPMValid, isPreferredTimeValid, isRollingReport, isSubjectValid, isWeeklyValid;
      let updateFooterMsg = true;
      const event = args.event;
      const btnName = event.currentTarget.className;
      const DataExportModel = this.DataExportModel;
      const isFileNameValid = DataExportModel.getFileNameVerified();
      const isFilterValid = DataExportModel.getFilterVerified();
      const isDimensionValid = DataExportModel.getDimensionsVerified();
      event.preventDefault();
      if (this.emailFormChanged) {
        isEmailAddressValid = DataExportModel.getEmailAddressesVerified();
        isSubjectValid = Boolean(DataExportModel.get("emailSubject"));
        isFrequencyValid = DataExportModel.getEmailFrequencyGrain() !== "-1";
        if (isFrequencyValid) {
          switch (DataExportModel.getEmailFrequencyGrain()) {
            case "daily":
              isWeeklyValid = true;
              isMonthlyValid = true;
              break;
            case "weekly":
              isWeeklyValid = Boolean(DataExportModel.getEmailWeeklyValue());
              isMonthlyValid = true;
              break;
            case "monthly":
              isMonthlyValid = Boolean(DataExportModel.getEmailMonthlyValue().value);
              isWeeklyValid = true;
              break;
          }
        }
        isPreferredTimeValid = DataExportModel.getEmailTime() !== "-1";
        isPreferredAMPMValid = DataExportModel.getEmailAMPM() !== "-1";
        isPreferredTimeAMPMValid = DataExportModel.isPreferredTimeValid();
        isMessageValid = Boolean(DataExportModel.get("emailMessage"));
        isRollingReport = btnName === "export-btn" ? true : DataExportModel.getDateBasedType() !== "fixed";
        formIsValid = [isFileNameValid, isFilterValid, isDimensionValid, isEmailAddressValid, isRollingReport, isSubjectValid, isFrequencyValid, isWeeklyValid, isMonthlyValid, isPreferredTimeValid, isPreferredAMPMValid, isPreferredTimeAMPMValid, isMessageValid].every(bool => bool);
      } else {
        formIsValid = isFileNameValid && isFilterValid && isDimensionValid;
      }
      if (formIsValid) {
        T1Publish("createEdit.footerShowSpinner", btnName);
        switch (btnName) {
          case "save-btn":
            this.saveReport("save");
            break;
          case "save-new-btn":
            this.saveReport("saveAsNew");
            break;
          case "export-btn":
            this.exportReportOnly();
            break;
          case "save-export-btn":
            this.saveReport("saveExport");
            break;
          case "modal-save-btn":
            this.saveReport("saveCancel", args.sectionTarget, args.tabTarget);
            break;
        }
        T1Publish("change:formElements", false);
      } else {
        T1Publish("createEdit.footerHideModal");
        T1Publish("change:formElements", true);
        if (this.emailFormChanged) {
          if (!isFileNameValid) {
            T1Publish("createEdit.fileNameError");
          }
          if (!isFilterValid) {
            T1Publish("createEdit.filterError");
          }
          if (!isDimensionValid) {
            T1Publish("reports.createEdit.dimensionError");
          }
          if (!isEmailAddressValid) {
            T1Publish("createEdit.emailSettingsError", "address");
          }
          if (!isSubjectValid) {
            T1Publish("createEdit.emailSettingsError", "subject");
          }
          if (!isFrequencyValid) {
            T1Publish("createEdit.emailSettingsError", "frequency");
          }
          if (isFrequencyValid) {
            if (!isWeeklyValid) {
              T1Publish("createEdit.emailSettingsError", "weekly");
            }
            if (!isMonthlyValid) {
              T1Publish("createEdit.emailSettingsError", "monthly");
            }
          }
          if (!isPreferredTimeValid) {
            T1Publish("createEdit.emailSettingsError", "time");
          }
          if (!isPreferredAMPMValid) {
            T1Publish("createEdit.emailSettingsError", "ampm");
          }
          if (!isPreferredTimeAMPMValid) {
            T1Publish("createEdit.emailSettingsError", "preferredTime");
          }
          if (!isMessageValid) {
            T1Publish("createEdit.emailSettingsError", "message");
          }
          if (!isRollingReport) {
            T1Publish("createEdit.footerError", {
              msg: "Fixed Date reports cannot be scheduled. If you want to schedule this report, " + "please choose the Rolling Date option.",
              type: "error",
              timeout: true
            });
            updateFooterMsg = false;
          }
        } else {
          if (!isFileNameValid) {
            T1Publish("createEdit.fileNameError");
          }
          if (!isFilterValid) {
            T1Publish("createEdit.filterError");
          }
          if (!isDimensionValid) {
            T1Publish("reports.createEdit.dimensionError");
          }
        }
        if (updateFooterMsg) {
          T1Publish("createEdit.footerError", {
            msg: "There are errors on the page.",
            timeout: true,
            type: "error"
          });
        }
        T1Publish("createEdit.footerResetBtns");
      }
    },
    saveReport(action, sectionTarget, tabTarget, id) {
      const {
        updateLocation: updateLocation,
        DataExportModel: DataExportModel
      } = this;
      let savedReport;
      if (action === "saveAsNew") {
        DataExportModel.set({
          editReport: null,
          id: null
        });
        this.SaveReportModel.id = null;
      }
      this.SaveReportModel.save({
        data: DataExportModel.getSaveData(action, id, this.emailFormChanged),
        success: model => {
          switch (action) {
            case "save":
            case "saveAsNew":
              DataExportModel.set({
                editReport: model.toJSON()
              });
              updateLocation.call(this, model, undefined, undefined, action);
              break;
            case "saveStatus":
              savedReport = DataExportModel.get("savedReports").find(report => report.id === id);
              savedReport.version = model.get("version");
              this.SaveReportModel.id = null;
              break;
            case "saveExport":
              DataExportModel.set({
                editReport: null,
                reportToExport: model.id
              });
              T1Notify("message", "Your report is running. It will download when it is ready.");
              updateLocation.call(this, model, true);
              break;
            case "saveCancel":
              if (sectionTarget) {
                DataExportModel.set({
                  editReport: null
                });
                updateLocation.call(this, model, true, sectionTarget);
              } else if (tabTarget) {
                DataExportModel.set({
                  editReport: null
                });
                T1Publish("createEdit.footerHideModal");
                tabTarget();
              } else {
                DataExportModel.set({
                  editReport: model.toJSON()
                });
                updateLocation.call(this, model);
                T1Publish("createEdit.footerHideModal");
              }
              break;
          }
          T1Publish("saved:report", {
            msg: "Save Successful",
            timeout: true,
            type: "success"
          });
          T1Notify("message", "Report saved successfully.");
        }
      });
    },
    exportReportOnly() {
      T1Notify("message", "Your report is running. It will download when it is ready.");
      this.exportCSV();
    },
    exportCSV(reportID) {
      let downloadUrl, entitySelection, filterEndIndex, savedReportFilter, token;
      const DataExportModel = this.DataExportModel;
      let selectedReport = DataExportModel.getSelectedReport();
      const additionalFilters = this.DataExportModel.getAdditionalFilterTypes();
      if (reportID) {
        this.updateTokens(reportID);
        token = downloadTokens[reportID];
        DataExportModel.setSavedReportStatus(reportID, token.state);
        const existingReport = DataExportModel.get("savedReports").find(report => report.id === reportID);
        savedReportFilter = existingReport.definition.filter;
        selectedReport = existingReport.type;
      }
      const downloadToken = token && token.timestamp || new Date().getTime();
      downloadUrl = DataExportModel.getExportURL(reportID, downloadToken);
      const filterSelection = savedReportFilter ? savedReportFilter : DataExportModel.getFilterSelection();
      const entities = [];
      const ids = [];
      const isAudienceIndexPixelReport = selectedReport === "Audience Index Pixel";
      const isAttributionReport = selectedReport === "Performance" || selectedReport === "Performance Ecosystem Cost";
      switch (true) {
        case isAudienceIndexPixelReport:
          filterEndIndex = downloadUrl.search("%26pixel_type");
          entitySelection = _.initial(filterSelection.split("&"));
          break;
        case isAttributionReport:
          if (DataExportModel.getSelectedAttribution() !== "All Attribution Groups") {
            filterEndIndex = downloadUrl.search("%26attribution_group");
            entitySelection = _.initial(filterSelection.split("&"));
          } else {
            filterEndIndex = downloadUrl.search("&dimensions=");
            entitySelection = filterSelection.split("&");
          }
          break;
        default:
          filterEndIndex = downloadUrl.search("&dimensions=");
          entitySelection = filterSelection.split("&");
      }
      if (entitySelection.length > 1) {
        let lowestLevelEntity, lowestLevelEntityIds;
        const filterStartIndex = downloadUrl.search("&filter=");
        const filterParams = downloadUrl.substring(filterStartIndex, filterEndIndex);
        const dataLoads = [];
        const promises = [];
        const additionalFilterSelections = [];
        this.LLECollections = {};
        for (const item of entitySelection) {
          const entity = item.split("=")[0].replace("_id", "");
          if (additionalFilters.includes(entity)) {
            additionalFilterSelections.push(item);
          } else {
            entities.push(entity);
            ids.push(item.split("=")[1]);
          }
        }
        switch (true) {
          case entities.includes("pixel"):
            lowestLevelEntity = "pixel";
            break;
          case entities.includes("campaign"):
            lowestLevelEntity = "campaign";
            break;
          case entities.includes("advertiser"):
            lowestLevelEntity = "advertiser";
            break;
        }
        if (lowestLevelEntity) {
          for (const [index, value] of entities.entries()) {
            let dataLoad;
            const entityIDs = ids[index];
            if (value === lowestLevelEntity) {
              lowestLevelEntityIds = entityIDs;
            } else {
              dataLoad = T1UtilsAsync.makeDeferral();
              dataLoads.push(dataLoad);
              promises.push(dataLoad.promise);
              this.loadLowerLevelEntities(lowestLevelEntity, value, entityIDs, dataLoad, selectedReport);
            }
          }
          Promise.all(promises).then(() => {
            const lowestLevelEntityLoadedIdsArray = dataLoads.map(item => _.pluck(item.models, "id"));
            const flattenedArray = lowestLevelEntityLoadedIdsArray.flat();
            const lowestLevelEntityLoadedIds = flattenedArray.length ? `,${flattenedArray.toString()}` : "";
            const params = `${lowestLevelEntity}_id=${lowestLevelEntityIds}${lowestLevelEntityLoadedIds}`;
            const lleFilterParams = `&filter=${encodeURIComponent(params)}`;
            let newFilterParams = lleFilterParams;
            for (const item of additionalFilterSelections) {
              newFilterParams += encodeURIComponent(`&${item}`);
            }
            downloadUrl = downloadUrl.replace(filterParams, newFilterParams);
            this.startDownload(downloadUrl, reportID);
          });
        } else {
          this.startDownload(downloadUrl, reportID);
        }
      } else {
        this.startDownload(downloadUrl, reportID);
      }
    },
    startDownload(downloadUrl, reportID) {
      this.apiTimeOutWatcher(reportID);
      $("body").find("#downloadFrame").remove();
      $("body").append($(`<iframe id="downloadFrame" style="display:none" src="${downloadUrl}"></iframe>`));
    }
  });
});
