define('T1SelectFilterList',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "When"], function ($, _, T1, T1View, T1Layout, When) {
  "use strict";

  var T1SelectFilterList;
  var T1SelectFilterListExtend;
  T1SelectFilterList = T1View.extend({
    documentEvents: "mousedown.T1SelectFilterList keyup.T1SelectFilterList",
    $document: $(document),
    currentLabel: "Strategy",
    currentLabelText: "Strategy",
    UIOpen: false,
    all_text: "All Strategies",
    events: {
      "click .clear-filter": "filterClear",
      "click .head-label": "inputFocus",
      "initFilterList .w-SelectFilterList": "initFilterList",
      "mousedown .chzn-single": "onSelectOpen",
      "mousedown .head-label": "toggle",
      "mousedown .w-SelectFilterList": "stopBubbling"
    },
    initialize: function (args) {
      var models = args.models;
      this.models = models;
      this.label = args.label;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
      this.campaign = models.campaign;
      this.checkedItems = {};
      this.allChecked = true;
    },
    load: function () {
      this.reportModel = this.models.reportModel;
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
      if (this.collection) {
        this.fetchCollection(this.collection).then($.proxy(this.onCollectionLoad, this));
      } else {
        this.onCollectionLoad();
      }
    },
    unload: function () {
      this.$document.off(this.documentEvents);
      this.$arrow = null;
      this.$clearFilter = null;
      this.$DDL = null;
      this.$headLabel = null;
      this.$wBody = null;
      this.$wFilterList = null;
      this.collection = null;
    },
    initializeUI: function () {
      var $el = this.el;
      this.$wBody = $el.find(".w-body");
      this.$headLabel = $el.find(".head-label");
      this.$clearFilter = $el.find(".clear-filter");
    },
    initFilterList: function (event) {
      var $el = this.el;
      event.stopPropagation();
      this.$wFilterList = $el.find(".w-FilterList");
      this.$arrow = $el.find(".selector-toggle .icon-arrow");
    },
    fetchCollection: function (collection) {
      var self = this;
      var dataLoad = When.defer();
      var dataFormat = When.defer();
      var promises = [dataLoad.promise, dataFormat.promise];
      collection.isPaginated = false;
      collection.fetch({
        success: function (response) {
          dataLoad.resolve();
          self.formatData(response, dataFormat);
        }
      });
      return When.all(promises);
    },
    activateSelectbox: function () {
      var $el = this.el;
      var $DDLnative = $el.find(".select-group");
      T1.Selectbox($DDLnative, {
        onChange: $.proxy(this.onSelectChange, this)
      });
      this.$DDLnative = $DDLnative;
      this.$DDL = $el.find(".chzn-container");
    },
    onSelectChange: function () {
      if (this.$wFilterList) {
        this.$wFilterList.trigger("emptyList");
      }
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    inputFocus: function () {
      if (this.$wFilterList) {
        this.$wFilterList.trigger("inputFocus");
      }
    },
    toggle: function (event) {
      event.stopPropagation();
      if (this.UIOpen) {
        this.close(true);
      } else {
        this.open();
      }
    },
    onSelectOpen: function () {},
    open: function () {},
    close: function () {},
    formatData: function () {},
    onCollectionLoad: function () {},
    initLayouts: function () {},
    filterClear: function () {},
    resetFilter: function () {},
    inTemporaryListMode: function () {},
    abortCollectionLoad: function () {}
  });
  T1SelectFilterListExtend = T1SelectFilterList.extend;
  T1SelectFilterList.extend = function () {
    var originalPartials;
    var originalEvents;
    var originalDataEvents;
    var originalInit;
    var originalUnLoad;
    var ViewSubClass;
    ViewSubClass = T1SelectFilterListExtend.apply(this, arguments);
    originalInit = ViewSubClass.prototype.initialize || $.noop;
    originalPartials = ViewSubClass.prototype.partials || $.noop;
    originalEvents = ViewSubClass.prototype.events || {};
    originalDataEvents = ViewSubClass.prototype.dataEvents || {};
    originalUnLoad = ViewSubClass.prototype.unload || $.noop;
    ViewSubClass.prototype.partials = $.extend(true, {}, T1SelectFilterList.prototype.partials, originalPartials);
    ViewSubClass.prototype.events = $.extend(true, {}, T1SelectFilterList.prototype.events, originalEvents);
    ViewSubClass.prototype.dataEvents = $.extend(true, {}, T1SelectFilterList.prototype.dataEvents, originalDataEvents);
    ViewSubClass.prototype.initialize = function () {
      T1SelectFilterList.prototype.initialize.apply(this, arguments);
      originalInit.apply(this, arguments);
    };
    ViewSubClass.prototype.unload = function () {
      T1SelectFilterList.prototype.unload.apply(this, arguments);
      originalUnLoad.apply(this, arguments);
    };
    return ViewSubClass;
  };
  return T1SelectFilterList;
});
