define('utils/CampaignStrategyUtils',['require','jQuery','Underscore','Backbone','collections/contracts','moment','T1','T1Alert','T1Permissions','collections/targetValues','jslibs/submodules/datejs/build/date','jslibs/submodules/datejs/build/time','jQPlugins/globalize','jQueryUI','jQPlugins/jquery.tinypubsub','jslibs/xml2json/xml2json'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const Backbone = require("Backbone");
  const Contracts = require("collections/contracts");
  const moment = require("moment");
  const T1 = require("T1");
  const T1Alert = require("T1Alert");
  const T1Permissions = require("T1Permissions");
  const TargetValues = require("collections/targetValues");
  const datejsDate = require("jslibs/submodules/datejs/build/date");
  const datejsTime = require("jslibs/submodules/datejs/build/time");
  const Globalize = require("jQPlugins/globalize");
  const jQueryUI = require("jQueryUI");
  const TinyPubSub = require("jQPlugins/jquery.tinypubsub");
  const XML2json = require("jslibs/xml2json/xml2json");
  const dayInMilliSecs = 864e5;
  var intervalRef = {
    hour: "Hour",
    day: "Day",
    week: "7 Days",
    month: "30 Days"
  };
  var pacingTypeRef = {
    even: "Even",
    asap: "ASAP"
  };
  var selectivenessRef = {
    REDUCED: "All Sites (TerminalOne blocklist applied)",
    MATHSELECT_250: "MathSelect250 sites",
    ALL: "Unfiltered exchange inventory"
  };
  var maturityUgcOptionRef = {
    1591: "Gold - G & PG-13",
    1592: "Silver - G, PG-13, & some R",
    1593: "Bronze - G, PG-13, & R",
    1598: "No UGC",
    "1598,1597": "Some UGC"
  };
  const technologyFilterOptions = [{
    text: "Device Type",
    value: "DVCE",
    dimCode: "DVCE"
  }, {
    text: "Device Model",
    value: "WURF",
    dimCode: "WURF"
  }, {
    text: "Inventory Type",
    value: "INVT",
    dimCode: "INVT"
  }, {
    text: "Connection Speed",
    value: "CSPD",
    dimCode: "CSPD"
  }, {
    text: "ISP",
    value: "ISPX",
    dimCode: "ISPX"
  }, {
    text: "Browser",
    value: "BSER",
    dimCode: "BSER"
  }, {
    text: "OS Version",
    value: "WURFL/OS",
    dimCode: "WURFL/OS"
  }];
  const videoStrategyFilterOptions = [{
    text: "Player Size",
    value: "VPSZ"
  }, {
    text: "Linear Format",
    value: "VLIN"
  }, {
    text: "Content Initiation",
    value: "VCON"
  }, {
    text: "Audio",
    value: "VAUD"
  }, {
    text: "Skippability",
    value: "VSKP"
  }, {
    text: "Video Placement",
    value: "VPLC"
  }];
  const hasSkippabilityFeature = T1Permissions.check("feature", "skippability");
  const isWurfl = T1Permissions.check("feature", "use_wurfl_targeting");
  const isOSVersion = T1Permissions.check("feature", "use_os_version_targeting");
  const hasPlacementFeature = T1Permissions.check("feature", "use_vplc_targeting");
  const sharedNamesRef = {
    targetDimensions: "target_dimensions"
  };
  var formatCurrency = T1.Utils.formatCurrency;
  var trimNumberValue = function (numVal) {
    var value = $.trim(numVal);
    if (value === "" || isNaN(value) === true) {
      value = null;
    }
    return value;
  };
  var goalTypeText = {
    spend: "CPM SPEND",
    reach: "CPM REACH",
    vcpm: "Viewable CPM",
    viewability_rate: "Viewability Rate",
    ctr: "CTR",
    vcr: "Video Completion Rate",
    cpc: "CPC",
    cpe: "CPE",
    cpa: "CPA",
    roi: "ROI"
  };
  var divideByZeroShared = function (value, zeroIfNegative) {
    if (value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY || isNaN(value)) {
      value = 0;
    }
    if (zeroIfNegative) {
      if (value < 0) {
        value = 0;
      }
    }
    return value;
  };
  var stopLoader = function (loader) {
    loader.stop();
  };
  var closeModal = function (modalEle) {
    modalEle.hide();
  };
  var parseDate = isUTCTime() ? utcDate : localDate;
  var hasCurrentDatePastEntityDate = function (model, dateFieldName, currentDate) {
    if (!model) {
      throw new Error("Incoming model must be a valid backbone model");
    }
    const dateNow = currentDate || new Date();
    const targetDateStrVal = model.get ? model.get(dateFieldName) : model[dateFieldName];
    if (!targetDateStrVal) {
      return false;
    }
    const targetDateUTC = parseDate(targetDateStrVal);
    if (!targetDateUTC) {
      throw new Error(`Incoming model's start_date_actual has invalid date value.`);
    }
    const currentDateUTC = new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
    return currentDateUTC > targetDateUTC;
  };
  var useNewFreqCap = T1Permissions.check("feature", "use_new_freq_cap");
  function isUTCTime() {
    const referenceYear = 2015;
    const referenceMonth = 2;
    const referenceDate = 23;
    var parsedTime = Date._parse("2015-03-23T00:00:00");
    var utcTime = Date.UTC(referenceYear, referenceMonth, referenceDate, 0, 0, 0);
    return new Date(parsedTime).valueOf() === new Date(utcTime).valueOf();
  }
  function addOffsetToDate(unixTime, offset) {
    const oneMinute = 6e4;
    const minuteOffset = offset * oneMinute;
    return new Date(unixTime + minuteOffset);
  }
  function getCorrectOffset(unixTime, offset) {
    var date = addOffsetToDate(unixTime, offset);
    var newOffset = date.getTimezoneOffset();
    return offset !== newOffset ? newOffset : offset;
  }
  function utcDate(str) {
    var date = localDate(str);
    var unixTime = date.valueOf();
    var offset = getCorrectOffset(unixTime, date.getTimezoneOffset());
    return addOffsetToDate(unixTime, offset);
  }
  function localDate(str) {
    return new Date(Date._parse(str));
  }
  function getTipDate(dateObj) {
    return `${Date.CultureInfo.abbreviatedMonthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;
  }
  function compareDates(start, end, loadDate) {
    let results;
    const hundredPct = 100;
    if (T1.compareDates(start, end) !== 1) {
      results = {};
      if (T1.compareDates(start, loadDate) === 1) {
        results.tipDate = getTipDate(start);
        results.pacingRatio = 0;
        results.showImpCounts = false;
      } else if (T1.compareDates(loadDate, end) === 1) {
        results.pacingRatio = 100;
        results.showImpCounts = false;
      } else {
        const startTime = start.getTime();
        results.tipDate = getTipDate(loadDate);
        results.pacingRatio = divideByZeroShared(hundredPct * (loadDate.getTime() - startTime) / (end.getTime() - startTime));
        results.showImpCounts = true;
      }
    }
    return results;
  }
  function indexOfError(errorsArr, errorFieldName) {
    for (let i = 0, len = errorsArr.length; i < len; i++) {
      if (errorsArr[i].field === errorFieldName) {
        return i;
      }
    }
    return -1;
  }
  function removeError(errorsArr, errorFieldName) {
    var indexOfField = indexOfError(errorsArr, errorFieldName);
    if (indexOfField >= 0) {
      errorsArr.splice(indexOfField, 1);
      return true;
    }
    return false;
  }
  const Utils = {
    intervalDataDisplayHash: intervalRef,
    pacingTypeDataDisplayHash: pacingTypeRef,
    selectivenessDataDisplayHash: selectivenessRef,
    maturityUgcDataDisplayHash: maturityUgcOptionRef,
    perfDataWarningKbUrl: "https://kb.mediamath.com/wiki/x/LI1X",
    headerTooltipRetiredSegmentsWarningText: "This segment has been discontinued by the provider " + "or moved to another provider. The strategy will continue to run, " + "but the available audience in this segment will likely diminish over time.",
    names: sharedNamesRef,
    parseDate: parseDate,
    compareDates: compareDates,
    indexOfError: indexOfError,
    removeError: removeError,
    closeStickyTooltips({
      target: target
    } = {}) {
      if (target && target.className !== "wiki-link") {
        const $panel = $(this).find("strand-close-panel")[0];
        if ($panel && $panel.state === $panel.STATE_OPENED) {
          $(this).find("strand-tooltip")[0].close();
        }
      }
    },
    getDisplayTextForStartAndEndDates(configObj = {}) {
      var getDateObj = function (dt) {
        if (dt && dt instanceof Date) {
          return dt;
        }
        const tmpVal = dt ? Date.parse(dt) : null;
        return tmpVal && tmpVal instanceof Date ? tmpVal : null;
      };
      var startDt = getDateObj(configObj.startDate);
      var endDt = getDateObj(configObj.endDate);
      var part1 = startDt ? `${startDt.toString(configObj.dateFormat || "MM/dd/yyyy")} at ${startDt.toString(configObj.timeFormat || "h:mm tt")}` : "";
      var part2 = endDt ? `${endDt.toString(configObj.dateFormat || "MM/dd/yyyy")} at ${endDt.toString(configObj.timeFormat || "h:mm tt")}` : "";
      return `${part1} - ${part2}`;
    },
    updateFrequency(frequencyAmt, frequencyType, frequencyInterval, isNoCap, noCapVal) {
      if (!isNoCap) {
        frequencyType = pacingTypeRef[frequencyType];
        frequencyType = frequencyType ? `${frequencyType} ` : "";
        frequencyInterval = intervalRef[frequencyInterval];
        frequencyInterval = frequencyInterval ? ` per ${frequencyInterval}` : "";
        return frequencyType + frequencyAmt + frequencyInterval;
      }
      return noCapVal || "--";
    },
    getDisplayTextForFrequencyCap(frequencyAmt, frequencyType, frequencyInterval, noCapVal, useT1Optimized) {
      const isNoCap = !frequencyType || frequencyType !== "even" && frequencyType !== "asap" || !frequencyAmt || useT1Optimized !== "0";
      return Utils.updateFrequency(frequencyAmt, frequencyType, frequencyInterval, isNoCap, noCapVal);
    },
    getDisplayTextForCampFrequencyCap(frequencyAmt, frequencyType, frequencyInterval, noCapVal) {
      const isNoCap = !frequencyType || frequencyType !== "even" && frequencyType !== "asap" || !frequencyAmt;
      return Utils.updateFrequency(frequencyAmt, frequencyType, frequencyInterval, isNoCap, noCapVal);
    },
    getDisplayTextForCampSpendCap(spendCapAuto, spendValue, currencyCode) {
      if (!currencyCode) {
        throw new Error("incoming currencyCode cannot be undefined, null or empty.");
      }
      if (spendCapAuto === "1") {
        return "Auto";
      }
      return `${formatCurrency(spendValue, "", currencyCode)} per day`;
    },
    getDisplayTextForMonetaryCap(spendCapType, spendCapAuto, spendValue, currencyCode) {
      let type;
      if (!currencyCode) {
        throw new Error("incoming currencyCode cannot be undefined, null or empty.");
      }
      if (spendCapType === "no-limit") {
        return "No Cap";
      }
      switch (spendCapType) {
        case "asap":
          type = "ASAP";
          break;
        case "even":
          type = "EVEN";
          break;
      }
      const spendFormat = spendCapAuto === "0" ? ` ${formatCurrency(spendValue, "", currencyCode)} per Day` : "/Auto";
      return type + spendFormat;
    },
    prepareTargetDimensionData(data, dimensionCodeFilter, captureOpNameFilter) {
      var resultCollection = null;
      var dims = data.get(sharedNamesRef.targetDimensions);
      var addToLocationColl = function (dim) {
        var targetVals = dim.get("target_value");
        if (!resultCollection) {
          resultCollection = new TargetValues([], {
            dimension: "REGN"
          });
        }
        resultCollection.add(targetVals.models);
      };
      if (dims && dims.length) {
        dims.each(function (dim) {
          var dimCode = dim.get("code");
          if (dimensionCodeFilter && dimensionCodeFilter(dimCode) === true) {
            addToLocationColl(dim);
            if (captureOpNameFilter && captureOpNameFilter.code === dimCode) {
              resultCollection[captureOpNameFilter.opName] = dim.get(captureOpNameFilter.opName);
            }
          }
        });
      }
      return resultCollection;
    },
    getGroupNameFromPixelModel(model) {
      let name = "";
      const advertiserModel = model.get("advertiser");
      const pixelProviderModel = model.get("pixel_provider");
      if (advertiserModel) {
        name = advertiserModel instanceof Backbone.Model ? advertiserModel.get("name") : advertiserModel.name;
      } else if (pixelProviderModel) {
        name = pixelProviderModel instanceof Backbone.Model ? pixelProviderModel.get("name") : pixelProviderModel.name;
      }
      if (model.get("pixel_type") === "dynamic") {
        name += " ";
      }
      return name;
    },
    createGroupedDataArray(groupedHash, titleResolverHash) {
      var dataArray = [];
      for (const key in groupedHash) {
        if (T1.Utils.hasOwnProp(groupedHash, key)) {
          const tmpArr = groupedHash[key];
          if (tmpArr && tmpArr.length) {
            let tmpTitle = null;
            if (titleResolverHash) {
              tmpTitle = titleResolverHash[key];
            }
            if (!tmpTitle) {
              tmpTitle = key;
            }
            dataArray.push({
              title: tmpTitle,
              items: tmpArr
            });
          }
        }
      }
      return dataArray;
    },
    useCampaignFrequencyCap(campaignJson) {
      if (!campaignJson) {
        throw new Error("incoming json must be a valid campaign json model.");
      }
      let useCampCap = campaignJson.frequency_optimization === "0";
      if (!useNewFreqCap && useCampCap) {
        useCampCap = campaignJson.frequency_type !== "no-limit";
      }
      return useCampCap;
    },
    useCampaignDailySpendCap(campaignJson) {
      if (!campaignJson) {
        throw new Error("incoming json must be a valid campaign json model.");
      }
      const useSpendCap = campaignJson.spend_cap_enabled === "1";
      return useSpendCap;
    },
    isStrategyTargetDimensionDataReady(targetDimensions) {
      if (!targetDimensions || !targetDimensions.get) {
        throw new Error("incoming targetDeimensions parameter must be a valid model.");
      }
      const {
        loaded: loaded,
        models: models
      } = targetDimensions.get(sharedNamesRef.targetDimensions) || {};
      if (!models) {
        throw new Error(`failed in getting dimensions or its models via this key "${sharedNamesRef.targetDimensions}".\n          The model passed in is invalid.`);
      }
      return Boolean(loaded && models.length);
    },
    getCpmFromContextualTargetData(targetData, contract) {
      if (!targetData || !contract) {
        throw new Error("Both incoming targetData and contract must be present.");
      }
      let targetCode = (targetData.get ? targetData.get("code") : targetData.code).toLowerCase();
      if (targetCode === "category") {
        targetCode = "channel";
      }
      return contract.get(`peer39_${targetCode}_fee_cpm`);
    },
    getCPmFromContextualTargetDataWithStrategy(targetData, contract, strategy) {
      if (!targetData || !contract || !strategy) {
        throw new Error("Incoming targetData, contract and strategy must be present.");
      }
      let cpmValue;
      let targetCode = (targetData.get ? targetData.get("code") : targetData.code).toLowerCase();
      const currencyCode = strategy.get ? strategy.get("type") : strategy.type;
      if (targetCode === "category") {
        targetCode = "channel";
      }
      let currencies = contract.get(`peer39_${targetCode}_fee_cpm_amount`);
      if (currencies === undefined) {
        currencies = contract.get("peer39_channel_fee_cpm_amount");
      }
      if (currencies.length > 1) {
        $.each(currencies, function (index, currencyObj) {
          if (currencyObj.currency_code === currencyCode) {
            cpmValue = currencyObj.value;
          }
        });
      } else {
        cpmValue = contract.get(`peer39_${targetCode}_fee_cpm`);
      }
      return cpmValue;
    },
    getAgencyAdvertiserDisplay(campaignModel, advertiserName) {
      const advertiser = campaignModel && campaignModel.get ? campaignModel.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        const agency = advertiser.get("agency");
        const agencyDisplay = agency ? `${agency.get("name")}&nbsp;&raquo;&nbsp;` : "";
        return agencyDisplay + (advertiserName || advertiser.get("name"));
      }
      return `${advertiserName || ""}`;
    },
    getBreadCrumbHeaderInfo(model) {
      var isStrategyModel = model.name === "strategy";
      var entityName = isStrategyModel ? "Strategy ID" : "Campaign ID";
      var modelJSON = model.toJSON ? model.toJSON() : model;
      var valueToBeDisplayed = [{
        attr: "id",
        label: entityName,
        className: "id"
      }, {
        attr: "start_date",
        label: "start",
        className: "start"
      }, {
        attr: "end_date",
        label: "end",
        className: "end"
      }, {
        attr: "status",
        className: "status"
      }];
      var noData = "&nbsp;--";
      var formatDate = function (date) {
        return date ? Date.parse(date).toString("MM/dd/yyyy") : noData;
      };
      var formatData = function (attr, value) {
        var attrOperations = {
          start_date: formatDate,
          end_date: formatDate,
          status: function () {
            var statusText = value === "0" ? "Inactive" : "Active";
            if (!model.id && isStrategyModel) {
              statusText = "Inactive";
            }
            return statusText;
          },
          defaultFormat: () => value || noData
        };
        var formatter = attrOperations[attr] || attrOperations.defaultFormat;
        return formatter(value);
      };
      if (isStrategyModel) {
        const campaignJSON = model.get("campaign").toJSON();
        if (model.get("use_campaign_start") === "1" || model.get("start_date") === undefined) {
          modelJSON.start_date = campaignJSON !== undefined ? campaignJSON.start_date : "";
        }
        if (model.get("use_campaign_end") === "1" || model.get("end_date") === undefined) {
          modelJSON.end_date = campaignJSON !== undefined ? campaignJSON.end_date : "";
        }
      }
      const returnArry = valueToBeDisplayed.map(({
        attr: attr,
        className: className,
        label: label
      }) => ({
        label: label || attr,
        value: formatData(attr, modelJSON[attr]),
        className: className
      }));
      return returnArry.reverse();
    },
    calculateEquivalantTarget(campaignGoalValue, roiTarget) {
      if (!roiTarget || roiTarget === "0.00") {
        return "--";
      } else if (campaignGoalValue && roiTarget) {
        const twoDigits = 2;
        let result = (parseFloat(campaignGoalValue) / parseFloat(roiTarget)).toFixed(twoDigits).toString();
        if (result.includes("Infinity")) {
          result = "--";
        }
        return result;
      }
    },
    parseNumberToWhole(data) {
      var target = parseFloat(data);
      if (target % 1 !== 0) {
        return data ? target : undefined;
      }
      return data ? target.toFixed(0) : undefined;
    },
    formatsRolledUpNumbers(value) {
      var formatFunc = T1.Formats.rolledUpNumbers();
      var val = trimNumberValue(value);
      return val !== null ? formatFunc(parseFloat(val)) : "--";
    },
    formatsRolledUpNumbersNoAbbreviation(value) {
      var formatFunc = T1.Formats.integers();
      var val = trimNumberValue(value);
      return val !== null ? formatFunc(parseFloat(val)) : "--";
    },
    getPath(fullPath) {
      const endIdx = 3;
      var goalLength = 35;
      let path = fullPath.substr(fullPath.indexOf(" - ") + endIdx);
      path = path.replace(/ - /g, "|");
      path = path.length > goalLength ? `...${path.substr(-goalLength)}` : path;
      const displayTextResult = T1.Utils.boldLastPartOfFullPath({
        fullPath: path,
        separator: "|",
        searchTermToBeHighlighted: null
      });
      return {
        displayText: displayTextResult.replace(/[|]/g, " &raquo "),
        title: fullPath.replace(/ - /g, " &raquo ")
      };
    },
    divideByZero(value, zeroIfNegative) {
      return divideByZeroShared(value, zeroIfNegative);
    },
    getCtdReportJsonObjByModel(modelObj) {
      let resultJson = {};
      const {
        models: models
      } = modelObj && modelObj.get ? modelObj.get("rpt_goal_monitoring") : {};
      if (models) {
        $.each(models, function (index, report) {
          const tmpRptJson = report.get ? report.toJSON() : report;
          if (tmpRptJson.mm_interval === "CTD") {
            resultJson = tmpRptJson;
            return false;
          }
        });
      }
      return resultJson;
    },
    buildRegionPath(locationColl, partialColl, startModel) {
      function buildPath(locationCollection, partialCollection, model, path) {
        if (partialCollection && partialCollection.get) {
          const parentId = model.get("parent_value_id");
          let parentNode = partialCollection.get(parentId);
          if (!parentNode) {
            parentNode = locationCollection.get(parentId);
          }
          if (parentNode) {
            path = `${parentNode.get("name")} &raquo ${path}`;
            if (parentNode.get("parent_value_id")) {
              return buildPath(locationCollection, partialCollection, parentNode, path);
            }
            return {
              partialPath: path,
              name: startModel.get("name")
            };
          }
          return {
            partialPath: "",
            name: startModel.get("name")
          };
        }
        return {};
      }
      return buildPath(locationColl, partialColl, startModel, "");
    },
    getUnitLabelForActualFieldOnHeaderRegion(actualValue, goalType) {
      var resultVal = actualValue && actualValue.toString().indexOf("--") <= -1 ? goalTypeText[goalType] : "";
      return resultVal || "";
    },
    calculateDailySpendToPace(model, budgetInfo) {
      let dtEnd = model && model.get ? parseDate(model.get("end_date")) : null;
      const budgetInformation = budgetInfo || Utils.getBudgetInformation(model);
      let modelForStartedDetection = model;
      let modelForEndedDetection = model;
      if (model.name === "strategy") {
        const useCampaignStart = model.get ? model.get("use_campaign_start") : model.use_campaign_start;
        const useCampaignEnd = model.get ? model.get("use_campaign_end") : model.use_campaign_end;
        const campaign = model.get ? model.get("campaign") : model.campaign;
        if (useCampaignStart !== "0") {
          modelForStartedDetection = campaign;
        }
        if (useCampaignEnd !== "0") {
          modelForEndedDetection = campaign;
          dtEnd = parseDate(campaign.get("end_date"));
        }
      }
      const hasStarted = hasCurrentDatePastEntityDate(modelForStartedDetection, "start_date_actual");
      const hasEnded = hasCurrentDatePastEntityDate(modelForEndedDetection, "end_date_actual");
      if (hasStarted === false || hasEnded === true) {
        return "--";
      }
      const localTodayDate = new Date().toLocaleString("en-US", {
        timeZone: model.get("zone_name")
      });
      const timezoneDate = new Date(localTodayDate);
      let daysRemaining = Math.ceil((dtEnd.valueOf() - moment(timezoneDate).startOf("day")) / dayInMilliSecs);
      daysRemaining = daysRemaining > 0 ? daysRemaining : 0;
      return divideByZeroShared(budgetInformation.budget_remaining / daysRemaining, true);
    },
    hasEntityStarted(model, currentDate) {
      return hasCurrentDatePastEntityDate(model, "start_date_actual", currentDate);
    },
    hasEntityEnded(model, currentDate) {
      return hasCurrentDatePastEntityDate(model, "end_date_actual", currentDate);
    },
    getEntityDate: (arr, dateFieldName) => arr.find(function (item) {
      return item.name === dateFieldName && typeof item.local_time === "object";
    }),
    getBudgetInformation(entity) {
      let campaignStartDate, totalBudget;
      let currentDaySpend = 0;
      let ftdSpend = 0;
      const twoDigits = 2;
      const percentage = 100;
      const type = entity.get("type");
      const isCampaign = type === "campaign";
      const reports = entity.get("rpt_goal_monitoring").toJSON();
      const ctdReport = reports.find(item => item.mm_interval === "CTD");
      const ftdReport = reports.find(item => item.mm_interval === "FTD");
      const currentDayReport = reports.find(item => item.mm_interval === "0");
      const report = ftdReport || ctdReport;
      function getDefaultInfo() {
        return {
          total_budget: totalBudget,
          budget_spent: 0,
          budget_remaining: totalBudget,
          percnt_budget_spent: 0
        };
      }
      if (isCampaign) {
        totalBudget = Number(entity.get("total_budget"));
        campaignStartDate = entity.get("start_date_actual");
      } else {
        totalBudget = Number(entity.get("budget"));
        campaignStartDate = entity.get("campaign").get("start_date_actual");
      }
      if (!(report && report.spend_remaining !== undefined)) {
        return getDefaultInfo();
      }
      if (ftdReport) {
        if (Reflect.has(ftdReport, "total_spend")) {
          ftdSpend = ftdReport.total_spend;
        }
        if (Reflect.has(ftdReport, "agg_start_date_gmt")) {
          const dateFormat = "yyyy-MM-ddTHH:mm:ss";
          if (campaignStartDate.toString(dateFormat) !== ftdReport.agg_start_date_gmt.toString(dateFormat)) {
            return getDefaultInfo();
          }
        }
      }
      if (currentDayReport && Reflect.has(currentDayReport, "total_spend")) {
        currentDaySpend = currentDayReport.total_spend;
      }
      const budgetSpent = Number(ftdSpend) - Number(currentDaySpend);
      return {
        total_budget: totalBudget,
        budget_spent: budgetSpent.toFixed(twoDigits),
        budget_remaining: totalBudget - budgetSpent,
        percnt_budget_spent: budgetSpent / totalBudget * percentage
      };
    },
    getDatePickerLowerBoundForStrategy(hasStrategyStarted, hasCampaignStarted, strategyStartDate, campaignStartDate) {
      var resultDate;
      if (hasStrategyStarted === true) {
        resultDate = strategyStartDate;
      } else {
        if (hasCampaignStarted === true) {
          resultDate = new Date();
        } else {
          resultDate = campaignStartDate;
        }
      }
      return resultDate;
    },
    getContract(strategyObj) {
      var deferred = $.Deferred();
      var contractColl = new Contracts({
        currency_code: strategyObj.getCurrencyCode()
      });
      var getActiveContract = function (contractCollection) {
        var iterator = function (memo, obj) {
          var date1 = new Date(memo.get("effective_start_date"));
          var date2 = new Date(obj.get("effective_start_date"));
          return date2 > date1 ? obj : memo;
        };
        if (contractCollection && contractCollection.models && contractCollection.models.length) {
          return _.reduce(contractCollection.models, iterator);
        }
        return null;
      };
      strategyObj.getOrgId().then(function (orgId) {
        contractColl.urlFilter.set({
          entity: "organization",
          id: orgId
        });
        contractColl.fetch({
          success: function (data) {
            deferred.resolve(getActiveContract(data));
          }
        });
      });
      return deferred.promise();
    },
    toggleFavStar(favorites, icon) {
      if (favorites && favorites.length) {
        icon.addClass("orange-color-icon");
      } else {
        icon.removeClass("orange-color-icon");
      }
    },
    populatePerformanceAlert(dataset) {
      const multiplier = .75;
      const multiplier2 = 1.25;
      if (dataset.goal_type === "ctr" || dataset.goal_type === "vcr" || dataset.goal_type === "viewability_rate") {
        dataset.performanceAlert = parseFloat(dataset.mm_goal_performance) < parseFloat(dataset.goal_value) * multiplier ? "performance-alert" : "";
      } else {
        dataset.performanceAlert = parseFloat(dataset.mm_goal_performance) > parseFloat(dataset.goal_value) * multiplier2 ? "performance-alert" : "";
      }
      return dataset;
    },
    isProgrammaticGuaranteed(campaignModel) {
      return campaignModel.get("is_programmatic_guaranteed") ? Boolean(parseInt(campaignModel.get("is_programmatic_guaranteed"), 10)) : false;
    },
    redirectInaccessibleRoutes({
      inaccessibleRoutes: inaccessibleRoutes,
      defaultRoute: defaultRoute
    }) {
      for (let i = 0, len = inaccessibleRoutes.length; i < len; i++) {
        const routeIndex = location.hash.indexOf(inaccessibleRoutes[i]);
        if (routeIndex >= 0) {
          const rootHash = location.hash.substring(0, routeIndex);
          T1.Location.set(`${rootHash}${defaultRoute}`, {
            replace: true,
            silent: true
          });
          break;
        }
      }
    },
    deactivateEntity(entityConfigObj) {
      if (entityConfigObj.isProgrammaticGuaranteed && entityConfigObj.status === "0") {
        this.showModal(entityConfigObj.modalEle);
      } else {
        this.onConfirmDeactivateEntity(entityConfigObj);
      }
    },
    onConfirmDeactivateEntity(entityConfigObj) {
      entityConfigObj.loader.start();
      entityConfigObj.model.save({
        status: entityConfigObj.status
      }, {
        success: function () {
          stopLoader(entityConfigObj.loader);
          closeModal(entityConfigObj.modalEle);
        },
        conflict: function () {
          stopLoader(entityConfigObj.loader);
          closeModal(entityConfigObj.modalEle);
        }
      });
    },
    showModal(modalEle) {
      modalEle.show();
    },
    reducer(list) {
      const regEx = new RegExp(`^(${list.join("|")})$`);
      return function (acc, option) {
        if (regEx.test(option.value)) {
          acc.push(option);
        }
        return acc;
      };
    },
    getFilterConfigs(tabConfig) {
      let filterOptions, optionList;
      if (tabConfig.tabId === "video") {
        optionList = ["VPSZ", "VLIN", "VCON", "VAUD"];
        filterOptions = videoStrategyFilterOptions;
        if (hasSkippabilityFeature) {
          optionList.push("VSKP");
        }
        if (hasPlacementFeature) {
          optionList.push("VPLC");
        }
      } else if (tabConfig.tabId === "technology") {
        filterOptions = technologyFilterOptions;
        if (tabConfig.isBatch) {
          optionList = ["DVCE", "CSPD"];
        } else if (isWurfl || isOSVersion) {
          optionList = ["DVCE", "WURF", "INVT", "CSPD", "ISPX", "BSER", "WURFL/OS"];
        } else {
          optionList = ["DVCE", "INVT", "CSPD", "ISPX", "BSER"];
        }
      }
      const testOption = this.reducer(optionList);
      return {
        filterClass: tabConfig.filterClass,
        filterOptions: filterOptions.reduce(testOption, [])
      };
    },
    validateSearchFilterMinCharacter: function (searchFilterConfig) {
      const minCharLen = 3;
      const {
        searchTerm: searchTerm,
        inputBox: inputBox,
        event: event,
        inputParent: inputParent,
        context: self
      } = searchFilterConfig;
      if (searchTerm.length < minCharLen) {
        self.applyTooltip(inputBox, "Please enter at least 3 characters.");
        self.showTooltip(event, inputParent);
        return true;
      }
      return false;
    },
    updateCancelBtn(campaign) {
      const {
        origin: origin,
        organization: organization
      } = window.hybridWorkflowParams;
      const $cancelBtn = $('.cancel[href="#monitor"]');
      const href = campaign ? `${origin}/strategies?campaign=${campaign.id}&organization=${organization}` : `${origin}/campaigns?organization=${organization}`;
      $cancelBtn.attr({
        href: href,
        target: "_top"
      });
      $cancelBtn.removeClass("cancel");
      $cancelBtn.addClass("cancelNewT1");
    },
    newt1RedirectHandler(evt) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const {
        isBulkEdit: isBulkEdit,
        name: name,
        scope: scope
      } = evt.data;
      const redirectUrl = evt.currentTarget.href;
      const cancelHandler = {
        onAlertClose: function () {
          setTimeout(function () {
            window.parent.location.replace(redirectUrl);
          }, 1);
        }
      };
      if (isBulkEdit) {
        return scope.openUnloadAlertBulkeditDialog({}, cancelHandler);
      }
      return scope.openUnloadAlertDialog({
        name: name
      }, cancelHandler);
    },
    allowV2CampaignOrStrategyEdit({
      campaign: campaign,
      strategy = null,
      view: view,
      showDialog = true,
      cancelable = false
    }) {
      const CHECK_V3_COMPATIBILITY = T1Permissions.check("feature", "compass-v-3-compatibility-check");
      const hasV3Usage = Boolean(!strategy ? campaign.get("v3_usage") : strategy.get("v3_usage"));
      const allowViewEdit = CHECK_V3_COMPATIBILITY ? CHECK_V3_COMPATIBILITY && !hasV3Usage : true;
      const hostName = COMPASS_ENV.TIER === "PRODUCTION" || COMPASS_ENV.TIER === "PROD" ? "https://platform.mediamath.com" : "https://newt1qa.aws.csre.mediamath.com";
      const entityUrl = !strategy ? `${hostName}/campaigns/edit/${campaign.id}` : `${hostName}/campaigns/${campaign.id}/strategies/edit/${strategy.id}`;
      if (!allowViewEdit) {
        const entityType = !strategy ? "Campaign" : "Strategy";
        if (showDialog) {
          const alertMsg = new T1Alert();
          alertMsg.initialize({
            title: "Compatibility Warning",
            bodyMsg: `This ${entityType} can only be edited in the new MediaMath platform to prevent \n            compatibility issues.`,
            buttons: [{
              text: "Edit In MM Beta",
              class: "save",
              click: function () {
                window.parent.location.replace(entityUrl);
              }
            }, ...(cancelable ? [{
              text: "Cancel",
              class: "cancel",
              click: function () {
                const $dialog = $(this);
                $dialog.dialog("close");
              }
            }] : [])]
          });
          alertMsg.show();
        }
        if (view.loader) {
          view.el.find(".view-loader").hide();
        }
      }
      return {
        flag: allowViewEdit,
        entityUrl: entityUrl
      };
    }
  };
  return Utils;
});
