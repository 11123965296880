define('modules/reporting/campaigns/performance/views/grid',["jQuery", "Underscore", "T1", "T1View", "T1GridViewV2", "T1PerformanceTimer", "modules/reporting/campaigns/utils/gridColumnResize", "text!../templates/grid_header.html", "text!../templates/item.html", "text!../templates/subitem.html", "T1Spinner", "Mustache", "jQueryUI"], function ($, _, T1, T1View, T1GridView, T1Timer, gridColumnResize, gridHeaderTemplate, itemTemplate, subItemTemplate, Spinner, T) {
  "use strict";

  var $window = $(window);
  var viewportHeight = $window.height() || 0;
  var viewportLeft = $window.scrollLeft() || 0;
  var formatCurrency = T1.Utils.formatCurrency;
  var formatAs = T1.Formats.as;
  var GAMap = {
    render: "RPTG:DAILY:GRID:RENDER"
  };
  var GridView = T1View.extend({
    selected: "",
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 9,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    partials: {
      gridHeader: gridHeaderTemplate,
      gridSubItem: subItemTemplate,
      listItem: itemTemplate
    },
    columnKeys: [],
    headers: [],
    scrollVLimit_chartOn: 546,
    scrollVLimit_chartOff: 192,
    sortConfigs: {
      order: {
        default: "descending",
        custom: {
          Creative_Name: "ascending",
          Strategy_Name: "ascending"
        }
      },
      sortBy: function (sortKey, sortOrder) {
        var self = this;
        var isName;
        var nameKey = function () {
          if (self.multiCampaign && self.multiCampaignExtCols) {
            return sortKey === "campaign_name" ? self.columnKeys[0] : self.columnKeys[1];
          }
          return self.columnKeys[0];
        };
        isName = nameKey() === sortKey ? "1" : "0";
        this.reportModel.setSortKeyInfo(`${sortKey},${sortOrder === "ascending" ? 1 : -1},${isName}`);
      }
    },
    dateformat: "yyyy-MM-dd",
    aggregateBy: "strategy_id",
    mainKey: "strategy_name",
    sortBy: "strategy_name",
    pivotBy: "",
    childHash: {},
    section: "performance",
    events: {
      "click .icon-edit-strategy": "editStrategy",
      "click .icon-strategy-quickedit": "openQuickEditPopup",
      "click .icon": "toggleOpen",
      "click .item": "toggleHighlighted",
      "click .legend-chart": "toggleSelected",
      "click .legend": "toggleSelected",
      "mouseout .item": "hideEditStrategyIcon",
      "mouseover .item": "showEditStrategyIcon"
    },
    dataEvents: {
      collection: {
        sort: function (triggeredByGridSerialize) {
          if (!triggeredByGridSerialize) {
            this.reload();
          }
        }
      }
    },
    eventhubEvents: {
      "change:columnKeys": "reload",
      "change:dimensionItems:performance": "reload",
      "change:filteredOutItems": "filterOutItems",
      "change:highlightedItem": "highlightItem",
      "chart.toggle": "chartToggled",
      "chart.toggleEnd": "chartToggleEnded",
      "close.editStrategyPopup": function () {
        this.isEditStrategyPopupOpen = false;
        this.hideEditStrategyIcon();
      },
      "panel.filteredItemsChange": function (isNewData) {
        this.isNewTotalData = isNewData;
        this.updateTotalRow(isNewData);
        if (this.dataRendered) {
          this.initColumnResize();
        }
      }
    },
    initialize: function (args) {
      var self = this;
      const {
        dataGroup: dataGroup,
        models: models,
        type: type
      } = args;
      const focus = models.reportModel.get("focus");
      const useChannel = focus === "display" || focus === "video";
      Object.assign(this, {
        appTop: 82,
        campaign: models.campaign,
        campaignModel: useChannel ? models.summaryStrategyChannel : models.summary,
        campaignNestedCollection: dataGroup.campaignNestedCollection,
        collection: dataGroup.collection,
        dim1Collection: dataGroup.dim1Collection,
        dim1FilteredCollection: dataGroup.dim1FilteredCollection,
        dim1FilteredNestedCollection: dataGroup.dim1FilteredNestedCollection,
        dim1NestedCollection: dataGroup.collection,
        gridHeader: $(""),
        gridHeaderMode: null,
        gridLeft: 113,
        mainModel: models.mainModel,
        noDataRender: true,
        onScrollVHProxy: $.proxy(this.onScrollVH, this),
        pivotBy: models.timeSeries.daily.gridPivot,
        reportMeta: models.reportMeta,
        reportModel: models.reportModel,
        scrollVLimit: this.scrollVLimit_chartOn,
        seriesTotal: dataGroup.totalNestedCollection,
        total: useChannel ? models.totalStrategyChannel : models.total,
        type: type
      });
      this.reportModel.setGridScrollXPos(0);
      this.viewportResizeListener = function () {
        viewportHeight = $window.height() || 0;
        self.updateScrollBar();
        self.shiftScrollbar($window.scrollTop());
      };
      this.bindViewEvents();
      T1.EventHub.publish("daily:grid:inited");
    },
    load: function () {
      var $contentHolder, cc, focus, offset, span, timerData, wDataGrid;
      var self = this;
      var mainModel = this.mainModel;
      var reportModel = this.reportModel;
      var $el = this.el;
      var savedGridScrollXPos = reportModel.get("scrollLeft");
      var dimension = this.reportModel.get("dimension");
      let strategyQuickEditOffset = 0;
      const quickEditOffset = 30;
      const empiricalUndermeasurement = 52;
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (!reportModel.get("columnKeys")) {
        return;
      }
      timerData = {
        category: GAMap.render,
        label: this.campaign.id
      };
      focus = reportModel.get("focus");
      this.multiCampaign = mainModel.get("multiCampaigns");
      this.focusInfo = focus === "all" ? "" : ` (${focus})`;
      this.multiCampaignsText = mainModel.get("multiCampaignsText") + this.focusInfo;
      this.strategyAccess = mainModel.get("strategyAccess");
      this.creativeAccess = mainModel.get("creativeAccess");
      this.isStrategyDimension = dimension === "strategy_name" && this.strategyAccess === "edit";
      if (this.isStrategyDimension) {
        strategyQuickEditOffset = quickEditOffset;
      }
      $window.off("scroll", this.onScrollVHProxy).on("scroll", this.onScrollVHProxy);
      $window.off("resize", this.viewportResizeListener).on("resize", this.viewportResizeListener);
      this.dataRendered = false;
      T1Timer.start(timerData);
      this.prepareRenderData();
      this.prepareGridData();
      this.prepareTotalRowData(this.isNewTotalData);
      this.render().then(function () {
        T1Timer.stop(timerData);
        self.initStrategyToolTip();
        self.recalculateSettings();
        if (!self.noDataRender) {
          self.initColumnResize();
          self.dataRendered = true;
        }
      });
      wDataGrid = $el.find(".w-DataGrid");
      this.$contentHolder = $contentHolder = wDataGrid.find(".content-holder");
      this.gridHeaderContainer = wDataGrid.find(".header-container");
      $contentHolder.off("scroll").on("scroll", function () {
        var container = self.gridHeaderContainer;
        var gridScrollXPos = $contentHolder.scrollLeft();
        if (container) {
          container.css("left", `${-gridScrollXPos}px`);
          reportModel.setGridScrollXPos(gridScrollXPos);
        }
      });
      if (savedGridScrollXPos !== 0) {
        this.$contentHolder.scrollLeft(savedGridScrollXPos);
      }
      offset = $contentHolder.offset();
      if (offset !== null) {
        this.gridLeft = offset.left;
      }
      offset = $(".app-container").offset();
      if (offset !== null) {
        this.appTop = offset.top;
      }
      span = this.gridHeaderContainer.width() + empiricalUndermeasurement + strategyQuickEditOffset;
      cc = wDataGrid.find(".content-c");
      if (span > cc.width()) {
        cc.width(span);
      }
      this.recalculateSettings();
    },
    unload: function () {
      if (this.$1stColHeader && this.$1stColHeader.hasClass("ui-resizable")) {
        this.$1stColHeader.find(".ui-resizable-handle").off();
        this.$1stColHeader.resizable("destroy");
        this.$1stColHeader.off();
      }
      if (this.$2ndColHeader && this.$2ndColHeader.hasClass("ui-resizable")) {
        this.$2ndColHeader.find(".ui-resizable-handle").off();
        this.$2ndColHeader.resizable("destroy");
        this.$2ndColHeader.off();
      }
      if (this.$contentHolder) {
        this.$contentHolder.perfectScrollbar("destroy");
        this.$contentHolder.off("scroll");
        $window.off("scroll", this.onScrollVHProxy);
        $window.off("resize", this.viewportResizeListener);
        this.$contentHolder = null;
        this.childHash = {};
        this.collection.reset();
        this.columnKeys = [];
        this.dataRendered = null;
        this.dim1FilteredNestedCollection.nestedData = null;
        this.dim1NestedCollection.nestedData = null;
        this.focusInfo = null;
        this.gridHeaderContainer = null;
        this.headers = null;
        this.isNewTotalData = null;
        this.itemCount = null;
        this.list = null;
        this.multiCampaign = null;
        this.multiCampaignsText = null;
        this.nestedDataForTotal = null;
        this.strategyAccess = null;
        this.creativeAccess = null;
        delete this.meta;
        this.unbind();
        this.remove();
      }
    },
    openQuickEditPopup: function (evt) {
      evt.stopPropagation();
      this.strategyQuickViewID = $(evt.currentTarget).attr("data-id");
      T1.EventHub.publish("grid.showEditStrategyPopup", this.strategyQuickViewID);
      this.isEditStrategyPopupOpen = true;
    },
    showEditStrategyIcon: function (event) {
      var iconElement = $(event.currentTarget).find(".icon-strategy-quickedit");
      this.hideEditStrategyIcon();
      iconElement.addClass("show");
    },
    hideEditStrategyIcon: function () {
      if (!this.isEditStrategyPopupOpen) {
        this.el.find(".icon-strategy-quickedit").removeClass("show");
      }
    },
    initColumnResize: function () {
      var headers = gridColumnResize(this.reportModel, this.el, this.multiCampaign, this.multiCampaignExtCols, this.isStrategyDimension);
      this.$1stColHeader = headers[0];
      this.$2ndColHeader = headers[1];
    },
    recalculateSettings: function () {
      this.chartToggled(this.mainModel.get("chartShown") === "true");
      this.recalculateSettingsForHeader();
      this.recalculateSettingsForScrollbar();
    },
    recalculateSettingsForHeader: function () {
      var el = this.el;
      var gridHeader = el.find(".w-DataGrid .w-head");
      if (this.gridHeader[0] !== gridHeader[0]) {
        this.gridHeader = gridHeader;
        this.gridHeaderMode = null;
      }
      this.shiftHeader($window.scrollTop(), $window.scrollLeft());
    },
    recalculateSettingsForScrollbar: function () {
      var el = this.el;
      var gridList = el.find(".content-c");
      var gridListOffset = gridList.offset();
      var gridListTop = gridListOffset ? gridListOffset.top : 0;
      var gridListHeight = gridList.height() || 0;
      var gridListBottom = gridListTop + gridListHeight;
      var $contentHolder = this.$contentHolder;
      if ($contentHolder) {
        this.scrollbar = $contentHolder.find(".ps-scrollbar-x");
        this.scrollbarRangeStart = gridListTop;
        this.scrollbarRangeEnd = gridListBottom;
        if (!this.scrollbar[0]) {
          this.scrollbarRangeEnd = this.scrollbarRangeStart;
        }
        this.shiftScrollbar($window.scrollTop());
      }
    },
    onScrollVH: function () {
      var scrollTop = $window.scrollTop();
      var scrollLeft = $window.scrollLeft();
      this.shiftHeader(scrollTop, scrollLeft);
      this.shiftScrollbar(scrollTop);
    },
    shiftHeader: function (scrollTop, scrollLeft) {
      var gridHeader = this.gridHeader;
      var adjustHorizontally = false;
      var isFrozen = this.gridHeaderMode === "frozen";
      if (!gridHeader[0]) {
        return;
      }
      if (viewportLeft !== scrollLeft) {
        viewportLeft = scrollLeft;
        adjustHorizontally = true;
      }
      if (!isFrozen && scrollTop >= this.scrollVLimit) {
        this.gridHeaderMode = "frozen";
        gridHeader.css({
          left: `${this.gridLeft - viewportLeft}px`,
          position: "fixed",
          top: `${this.appTop}px`,
          zIndex: "2"
        });
        adjustHorizontally = false;
      } else if (this.gridHeaderMode !== "normal" && scrollTop < this.scrollVLimit) {
        this.gridHeaderMode = "normal";
        gridHeader.css({
          left: "0",
          position: "absolute",
          top: "auto",
          zIndex: "auto"
        });
      }
      if (adjustHorizontally && isFrozen) {
        gridHeader.css("left", `${this.gridLeft - viewportLeft}px`);
      }
    },
    shiftScrollbar: function (scrollTop) {
      var viewportBottom, viewportBottomInRange;
      var scrollbar = this.scrollbar;
      var $contentHolder = this.$contentHolder;
      if (!scrollbar[0]) {
        return;
      }
      viewportBottom = scrollTop + viewportHeight;
      viewportBottomInRange = this.scrollbarRangeStart < viewportBottom && this.scrollbarRangeEnd > viewportBottom;
      if (viewportBottomInRange) {
        scrollbar.css({
          "margin-left": `${this.gridLeft}px`,
          bottom: "2px",
          position: "fixed"
        });
        $contentHolder.perfectScrollbar("freezeX");
      } else {
        scrollbar.css({
          "margin-left": 0,
          position: "absolute"
        });
        $contentHolder.perfectScrollbar("thawX");
      }
      $contentHolder.perfectScrollbar("update");
    },
    chartToggled: function (chartShown) {
      this.scrollVLimit = chartShown ? this.scrollVLimit_chartOn : this.scrollVLimit_chartOff;
    },
    chartToggleEnded: function () {
      if (this.scrollVLimit === this.scrollVLimit_chartOff && $window.scrollTop() > this.scrollVLimit) {
        $window.scrollTop(this.scrollVLimit);
      }
      this.recalculateSettingsForHeader();
      this.recalculateSettingsForScrollbar();
    },
    toggleOpen: function (e) {
      var selectedItem = $(e.target).closest(".item");
      var selectedArrow = $(e.target).closest(".arrow");
      var expandedClass = "expanded";
      var loadingClass = "loading";
      var action;
      const {
        appendChildList: appendChildList
      } = this;
      if (selectedItem.hasClass(expandedClass)) {
        action = "conceal";
        selectedItem.removeClass(expandedClass);
        selectedArrow.removeClass(loadingClass);
      } else {
        action = "reveal";
        selectedItem.addClass(expandedClass);
        selectedArrow.addClass(loadingClass);
        appendChildList.call(this, e);
      }
      this.recalculateSettingsForScrollbar();
      e.preventDefault();
      if (selectedItem.hasClass("selected")) {
        e.stopPropagation();
      }
      T1.EventHub.publish("track:event", {
        action: "Disclose time series",
        label: action
      });
    },
    setAllNoneClass: function () {
      var reportModel = this.reportModel;
      var hiddenCount = reportModel.getHiddenItemsCount();
      var benchMark = reportModel.get("campaignBenchmarkOn");
      var $legend = $(".header-container .legend-chart.header", this.el);
      if (hiddenCount || !benchMark) {
        $legend.addClass("hide");
      } else {
        $legend.removeClass("hide");
      }
    },
    toggleAll: function (target) {
      var reportModel = this.reportModel;
      var hideClass = "hide";
      var allHidden = target.hasClass(hideClass);
      var itemsToHide;
      var idList = {};
      var $legend = this.el.find(".legend-chart");
      if (allHidden) {
        reportModel.resetHiddenItems();
        $legend.removeClass(hideClass);
      } else {
        $legend.addClass(hideClass);
        itemsToHide = reportModel.get("dimensionItems");
        $.each(itemsToHide, function (i, item) {
          idList[item.id] = true;
        });
        reportModel.setHiddenItems(idList);
      }
      reportModel.setCampaignBenchmarkState(allHidden);
    },
    toggleSelected: function (e) {
      var reportModel = this.reportModel;
      var target = $(e.target);
      var item = target.closest(".item");
      var publish = T1.EventHub.publish;
      var hideClass = "hide";
      e.stopPropagation();
      if (target.hasClass("header")) {
        this.toggleAll(target);
        return;
      }
      target.toggleClass(hideClass);
      if (item.hasClass("campaign-row")) {
        reportModel.setCampaignBenchmarkState(!reportModel.get("campaignBenchmarkOn"));
        publish("track:event", {
          action: "Add/remove campaign from chart",
          label: "legend-chart"
        });
      } else {
        reportModel.setHiddenItem({
          action: target.hasClass(hideClass) ? hideClass : "show",
          id: String(item.attr("data-id")),
          type: "strategy"
        });
        publish("track:event", {
          action: "Add/remove row from chart",
          label: "legend-chart"
        });
      }
      this.setAllNoneClass();
    },
    toggleHighlighted: function (e) {
      var item = $(e.currentTarget);
      var target = $(e.target);
      e.stopPropagation();
      if (target.hasClass("label")) {
        return;
      }
      if (!item.hasClass("total-row")) {
        this.reportModel.setHighlightedItem({
          from: "row",
          id: item.hasClass("selected") ? null : String(item.attr("data-id")),
          type: item.hasClass("campaign-row") ? "campaign" : "strategy"
        });
      }
    },
    highlightItem: function (model) {
      var contentC = $(this.el).find(".content-c");
      var highlightedItem = model.get("highlightedItem");
      var selectedClass = "selected";
      contentC.children(".item").removeClass(selectedClass);
      if (highlightedItem && highlightedItem.id !== null) {
        if (highlightedItem.type === "campaign") {
          contentC.find(".campaign-row").addClass(selectedClass);
        } else {
          contentC.find(`.item[data-id="${highlightedItem.id}"]`).addClass(selectedClass);
        }
      }
    },
    filterOutItems: function () {
      var $contentC = this.el.find(".content-c");
      var $filteredInItem, filteredInID, filteredOutID;
      var reportModel = this.reportModel;
      var filterOutClass = "filter-out";
      var filteredOutItems = reportModel.get("filteredOutItems") || [];
      var filteredInItems = reportModel.get("filteredInItems") || [];
      var count = 0;
      for (let i = 0; i !== filteredOutItems.length; i++) {
        filteredOutID = filteredOutItems[i];
        $contentC.find(`.item[data-id="${filteredOutID.replace(/[']/g, "")}"]`).addClass(filterOutClass);
      }
      for (let j = 0; j !== filteredInItems.length; j++) {
        filteredInID = filteredInItems[j];
        $filteredInItem = $contentC.find(`.item[data-id="${filteredInID.replace(/[']/g, "")}"]`);
        $filteredInItem.removeClass(filterOutClass);
        if ($filteredInItem && $filteredInItem.length > 0) {
          count++;
        }
      }
      this.updateDataBind({
        count: count,
        total_count: count
      }, ".w-foot");
    },
    extendWithKeys: function (nestedData, columnKeys, isCampaignRow, isTotalRow, parentData) {
      var self = this;
      var mainModel = this.mainModel;
      var reportMeta = this.reportMeta;
      var reportModel = this.reportModel;
      var reportMetaObj = $.extend({}, reportMeta.toJSON());
      var hiddenItems = reportModel.get("hiddenItems") || {};
      var filteredOutItems = reportModel.get("filteredOutItems") || [];
      var colorData = reportModel.get("colorData");
      var currencyCode = mainModel.getCurrencyCode();
      var dimension = reportModel.get("dimension");
      var accessStrategy = dimension === "strategy_name" && this.strategyAccess !== "denied";
      var accessCreative = dimension === "creative_name" && this.creativeAccess !== "denied";
      var isExtCol = false;
      var multiCampaignsText = this.multiCampaignsText;
      var totalLabel = reportModel.get("totalLabel");
      var firstColumnWidths = reportModel.get("firstColumnWidths");
      var secondColumnWidths = reportModel.get("secondColumnWidths");
      var is1stColumnWidthSaved = Boolean(firstColumnWidths);
      var isSecondColumnWidthSaved = Boolean(secondColumnWidths);
      _.each(nestedData, function (dataItem, dataIndex) {
        var nestedValues = dataItem.values;
        dataItem.keys = [];
        dataItem.id = isTotalRow ? "total" : dataItem.key && dataItem.key.replace(/[']/g, "");
        dataItem.isFilteredOut = filteredOutItems.indexOf(dataItem.key) > -1;
        dataItem.notTotalRow = !isTotalRow;
        dataItem.isStrategyDimension = self.isStrategyDimension;
        if (nestedValues) {
          _.each(nestedValues, function (nestedValue) {
            nestedValue.day = nestedValue.start_date;
          });
        }
        _.each(columnKeys, function (columnKey, columnIndex) {
          var format = reportMetaObj.fields[columnKey].type;
          var is1stCol = columnIndex === 0;
          var isCampaign = columnKey === "campaign_name";
          var isStrategy = columnKey === "strategy_name";
          var isName = columnKey === self.mainKey || columnKey === "strategy_name";
          var isWidthSaved = false;
          var columnChildrenWidth, columnNamesWidth, columnSubRowsWidth, dataValue, formattedValue, value;
          if (nestedValues) {
            if (parentData) {
              dataValue = isCampaignRow ? parentData[columnKey] : parentData[dataIndex][columnKey];
            }
          } else {
            dataValue = dataItem[columnKey];
          }
          if (format === "name" || format === "date") {
            value = dataValue;
            if (value === "" && format === "name") {
              value = "Unassigned";
            }
          } else {
            if (dataValue) {
              value = parseFloat(dataValue) || 0;
            } else {
              value = "--";
            }
          }
          if (value === "--") {
            formattedValue = value;
          } else if (format === "currency") {
            formattedValue = formatCurrency(value, null, currencyCode);
          } else {
            formattedValue = formatAs.call(self, format)(value);
          }
          if (self.multiCampaign) {
            if (self.multiCampaignExtCols) {
              if (isCampaignRow && isCampaign) {
                formattedValue = isTotalRow ? totalLabel : multiCampaignsText;
              } else if (isCampaignRow && isName) {
                formattedValue = "--";
                isExtCol = true;
              } else {
                if (isName) {
                  isExtCol = true;
                } else {
                  isExtCol = false;
                }
              }
              if (!parentData && isStrategy) {
                formattedValue = "--";
                format = "name blank-strategy";
              }
            } else {
              if (isCampaignRow && isName) {
                formattedValue = isTotalRow ? totalLabel : multiCampaignsText;
              }
            }
            if (is1stCol && is1stColumnWidthSaved) {
              columnChildrenWidth = firstColumnWidths.children;
              columnNamesWidth = firstColumnWidths.names;
              columnSubRowsWidth = firstColumnWidths.subrows;
              isWidthSaved = true;
            }
            if (isExtCol && isSecondColumnWidthSaved) {
              columnChildrenWidth = secondColumnWidths.children;
              columnNamesWidth = secondColumnWidths.names;
              columnSubRowsWidth = secondColumnWidths.subrows;
              isWidthSaved = true;
            }
            dataItem.keys.push({
              accessCreative: value === undefined ? false : accessCreative,
              accessStrategy: value === undefined ? false : accessStrategy,
              checked: !hiddenItems[dataItem.id],
              colorIndex: isCampaignRow ? 0 : colorData[dataItem.id] + 1,
              columnChildrenWidth: columnChildrenWidth,
              columnNamesWidth: columnNamesWidth,
              columnSubRowsWidth: columnSubRowsWidth,
              editCreativeIconTip: "Edit Creative",
              editCreativeURL: self.setCreativeURL(dataItem),
              editStrategyIconTip: self.strategyAccess === "edit" ? "Edit Strategy" : "View Summary",
              editStrategyURL: self.setStrategyURL(dataItem),
              format: format,
              index: columnIndex,
              isCampaign: isCampaign,
              isExtCol: isExtCol,
              isFirstCol: is1stCol,
              isName: isName,
              isValue: !isCampaign && !isExtCol,
              isWidthSaved: isWidthSaved,
              key: columnKey,
              multiCampaign: self.multiCampaign && self.multiCampaignExtCols,
              strategyQuickEdit: isCampaign && self.isStrategyDimension,
              sum: value === "--" ? 0 : value,
              value: formattedValue
            });
          } else {
            if (is1stCol && is1stColumnWidthSaved) {
              columnChildrenWidth = firstColumnWidths.children;
              columnNamesWidth = firstColumnWidths.names;
              columnSubRowsWidth = firstColumnWidths.subrows;
              isWidthSaved = true;
            }
            dataItem.keys.push({
              accessCreative: value === undefined ? false : accessCreative,
              accessStrategy: value === undefined ? false : accessStrategy,
              checked: !hiddenItems[dataItem.id],
              colorIndex: isCampaignRow ? 0 : colorData[dataItem.id] + 1,
              columnChildrenWidth: columnChildrenWidth,
              columnNamesWidth: columnNamesWidth,
              columnSubRowsWidth: columnSubRowsWidth,
              editCreativeIconTip: "Edit Creative",
              editCreativeURL: self.setCreativeURL(dataItem),
              editStrategyIconTip: self.strategyAccess === "edit" ? "Edit Strategy" : "View Summary",
              editStrategyURL: self.setStrategyURL(dataItem),
              format: format,
              index: columnIndex,
              isName: isName,
              isWidthSaved: isWidthSaved,
              key: columnKey,
              multiCampaign: self.multiCampaign && self.multiCampaignExtCols,
              strategyQuickEdit: isStrategy && self.isStrategyDimension,
              sum: value === "--" ? 0 : value,
              value: isTotalRow && isName ? totalLabel : formattedValue
            });
          }
          if (dataItem.values) {
            self.childHash[dataItem.id] = dataItem.values;
          }
        });
      });
    },
    appendChildList: function (event) {
      var ele = $(event.target);
      var id = ele.attr("data-id");
      var childRow = ele.closest(".item-row").find(".child-row");
      var columnKeys, html, list;
      list = this.childHash[id];
      if (!list || childRow.data("loaded")) {
        return;
      }
      columnKeys = $.merge([], this.columnKeys);
      columnKeys.splice(columnKeys.indexOf(this.sortBy), 1);
      columnKeys.unshift(this.pivotBy);
      if (this.multiCampaign && this.multiCampaignExtCols) {
        columnKeys[1] = "strategy_name";
      }
      this.extendWithKeys(list, columnKeys);
      html = T.to_html(subItemTemplate, {
        list: list
      });
      childRow.html(html);
      childRow.data("loaded", true);
      event.preventDefault();
      if (this.dataRendered) {
        this.initColumnResize();
      }
    },
    sortData: function (collection, type, silent, triggeredByGridSerialize) {
      var reportModel = this.reportModel;
      var sortKeyInfo = reportModel.get("sortKeyInfo") || "";
      var allColumns, currencyType, key, order, sortKeyArray;
      const TIME_SERIES = 2;
      if (sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        key = sortKeyArray[0];
        order = parseInt(sortKeyArray[1], 10);
        currencyType = this.mainModel.get("currency");
        allColumns = this.reportMeta.additionalData.columns[reportModel.get("focus")];
        if (currencyType === "usd") {
          if (allColumns[key] && allColumns[key].onlyViewableInCampaignCurrency) {
            key = "impressions";
            reportModel.setSortKeyInfo(key);
          }
        }
        collection.sortByKey(key, order, type, silent, parseInt(sortKeyArray[TIME_SERIES], 10), triggeredByGridSerialize);
      }
    },
    prepareRenderData: function () {
      var dimensionInfo = this.reportModel.getDimensionInfo(false);
      this.mainKey = dimensionInfo.dimension;
      this.aggregateBy = dimensionInfo.key;
      this.sortBy = this.mainKey;
    },
    prepareGridData: function () {
      var headers = [];
      var reportMeta = this.reportMeta;
      var mainModel = this.mainModel;
      var reportModel = this.reportModel;
      var reportModelObj = $.extend({}, reportModel.toJSON());
      var currencyType = mainModel.get("currency");
      var allColumns = reportMeta.additionalData.columns[reportModel.get("focus")];
      var sortKeyInfo = reportModel.get("sortKeyInfo") || "";
      var sortKeyArray = sortKeyInfo.split(",");
      var sortKey = sortKeyArray[0];
      var sortOrder = parseInt(sortKeyArray[1], 10);
      var columns = {};
      var firstHeader = {};
      var dimension = reportModel.get("dimension");
      var extCols, firstColumnWidths, isFirstColumnWidthSaved, isSecondColumnWidthSaved, reportMetaObj, secondColumnWidths;
      this.multiCampaignExtCols = mainModel.getMultiCampaignExtCols(this.aggregateBy) !== "";
      extCols = this.multiCampaign && this.multiCampaignExtCols;
      this.reportMetaObj = reportMetaObj = $.extend({}, reportMeta.toJSON());
      this.reportModelObj = $.extend({}, reportModel.toJSON());
      reportModelObj = this.reportModelObj;
      this.columnKeys = [this.mainKey].concat(reportModelObj.columnKeys);
      if (extCols) {
        firstHeader.campaign_name = "CAMPAIGN";
        firstHeader[this.mainKey] = reportModel.get("dimensionInfo").text.toUpperCase();
        this.columnKeys.unshift("campaign_name");
        secondColumnWidths = reportModel.get("secondColumnWidths");
      } else {
        firstHeader[this.mainKey] = "NAME";
      }
      columns = $.extend(firstHeader, reportModelObj.formattedHeaderValues);
      firstColumnWidths = reportModel.get("firstColumnWidths");
      isFirstColumnWidthSaved = Boolean(firstColumnWidths);
      isSecondColumnWidthSaved = Boolean(secondColumnWidths);
      if (currencyType === "usd") {
        this.columnKeys = _.filter(this.columnKeys, function (key) {
          if (allColumns[key]) {
            if (!allColumns[key].onlyViewableInCampaignCurrency) {
              return key;
            }
          } else {
            return key;
          }
        });
      }
      $.each(this.columnKeys, function (i, key) {
        var field = reportMetaObj.fields[key];
        var format = field.type;
        var label = columns[key];
        var isFirstCol = i === 0;
        var isExtCol = i === 1 && extCols;
        var isWidthSaved = false;
        var columnHeaderWidth;
        if (allColumns[key]) {
          label = allColumns[key].text ? allColumns[key].text.toUpperCase() : label;
        } else if (!label) {
          return;
        }
        if (isFirstCol && isFirstColumnWidthSaved) {
          columnHeaderWidth = firstColumnWidths.header;
          isWidthSaved = true;
        }
        if (isExtCol && isSecondColumnWidthSaved) {
          columnHeaderWidth = secondColumnWidths.header;
          isWidthSaved = true;
        }
        headers.push({
          accessStrategy: false,
          ascending: sortOrder === 1,
          colorIndex: 0,
          columnHeaderWidth: columnHeaderWidth,
          field: key,
          format: format,
          isSortable: true,
          isWidthSaved: isWidthSaved,
          label: label,
          name: format,
          showSortOrder: key === sortKey,
          strategyQuickEdit: key === "strategy_name" && dimension === "strategy_name" && this.strategyAccess === "edit"
        });
      });
      this.headers = headers;
    },
    prepareTotalRowData: function (isNewData) {
      var campaignFirstColumn, campaignFirstColumnKeys, totalCampaignJson, totalJson;
      var columnKeys = this.columnKeys;
      var nestedDataForTotal = [];
      if (isNewData && !_.isEmpty(this.total.attributes) && this.seriesTotal.loaded) {
        totalCampaignJson = this.total.toJSON();
        totalJson = this.seriesTotal.toJSON();
      } else {
        totalCampaignJson = {};
        totalJson = [{}];
      }
      nestedDataForTotal = d3.nest().key(function (d) {
        return d.campaign_id;
      }).entries(totalJson);
      this.extendWithKeys(nestedDataForTotal, columnKeys, true, true, totalCampaignJson);
      if (nestedDataForTotal.length > 0) {
        campaignFirstColumn = nestedDataForTotal[0];
        if (campaignFirstColumn.keys.length > 0) {
          campaignFirstColumnKeys = campaignFirstColumn.keys[0];
          campaignFirstColumnKeys.checked = false;
          campaignFirstColumn.isTotalRow = true;
          campaignFirstColumn.isSelected = false;
        }
      }
      this.nestedDataForTotal = nestedDataForTotal;
    },
    prepareListForNoDataMessage: function () {
      var self = this;
      var reportMeta = this.reportMeta;
      var reportModel = this.reportModel;
      var reportMetaObj = $.extend({}, reportMeta.toJSON());
      var highlightedItem = reportModel.get("highlightedItem");
      var highlightedCampaign = highlightedItem && highlightedItem.type === "campaign" ? highlightedItem : null;
      var isCampaignBenchMarkOn = reportModel.get("campaignBenchmarkOn");
      var columnKeys = this.columnKeys;
      var item = {
        keys: []
      };
      var list = [];
      var currencyCode = this.mainModel.getCurrencyCode();
      var isExtCol = false;
      var multiCampaignsText = this.multiCampaignsText;
      var dimension = reportModel.get("dimension");
      const THIRD_ROW = 2;
      $.each(columnKeys, function (j, m) {
        var format = reportMetaObj.fields[m].type;
        var value = "--";
        var id = "";
        var isFirstCol = j === 0;
        var isCampaign = m === "campaign_name";
        var isName = m === self.mainKey;
        var formattedValue;
        if (value === "--") {
          formattedValue = value;
        } else if (format === "currency") {
          formattedValue = formatCurrency(value, null, currencyCode);
        } else {
          formattedValue = formatAs(format)(value);
        }
        item.isCampaignRow = true;
        item.notTotalRow = true;
        item.id = id && id.replace(/[']/g, "");
        item.isSelected = highlightedCampaign && highlightedCampaign.id === id;
        item.isStrategyDimension = this.isStrategyDimension;
        if (self.multiCampaign) {
          if (self.multiCampaignExtCols) {
            if (isCampaign) {
              formattedValue = multiCampaignsText;
              format = "name no-data";
            } else if (isName) {
              isExtCol = true;
            } else {
              isExtCol = false;
            }
            item.keys.push({
              checked: isCampaignBenchMarkOn,
              colorIndex: 0,
              format: format,
              isCampaign: isCampaign,
              isExtCol: isExtCol,
              isFirstCol: isFirstCol,
              isName: isName,
              isValue: !isCampaign && !isExtCol,
              multiCampaign: self.multiCampaign && self.multiCampaignExtCols,
              strategyQuickEdit: isCampaign && dimension === "strategy_name" && self.strategyAccess === "edit",
              value: formattedValue
            });
          } else {
            if (isName) {
              formattedValue = multiCampaignsText;
              format = "name no-data";
            }
            item.keys.push({
              checked: isCampaignBenchMarkOn,
              colorIndex: 0,
              format: format,
              isCampaign: isCampaign,
              isExtCol: isExtCol,
              isFirstCol: isFirstCol,
              isName: isName,
              isValue: !isCampaign && !isExtCol,
              multiCampaign: self.multiCampaign && self.multiCampaignExtCols,
              strategyQuickEdit: isCampaign && dimension === "strategy_name" && self.strategyAccess === "edit",
              value: formattedValue
            });
          }
        } else {
          item.keys.push({
            checked: isCampaignBenchMarkOn,
            colorIndex: 0,
            format: format,
            isName: isName,
            strategyQuickEdit: m === "strategy_name" && dimension === "strategy_name" && self.strategyAccess === "edit",
            value: isName ? "Campaign" : formattedValue
          });
        }
        item.noArrow = true;
      });
      list.push(item);
      for (let i = 0, length = 4; i !== length; i++) {
        list.push({
          blank: "blank",
          id: "",
          isCampaignRow: false,
          msg: "",
          noArrow: true
        });
      }
      list[THIRD_ROW].msg = "No data available for this selection";
      list[THIRD_ROW].blank = "";
      return list;
    },
    initStrategyToolTip: function () {
      T1.Tooltip($(this.el), {
        className: "rptg-icon-edit-tip",
        delayIn: 0,
        delayOut: 0,
        fade: false,
        getExternalTip: false,
        html: true,
        tooltipEle: ".icon-edit-strategy"
      });
    },
    editStrategy: function (evt) {
      evt.stopPropagation();
      $(".rptg-icon-edit-tip").remove();
    },
    setStrategyURL: function (dataItem) {
      var strategyURL = `strategy/${this.strategyAccess}/${dataItem.id}`;
      if (this.strategyAccess === "edit") {
        strategyURL += "/details/general";
      }
      return strategyURL;
    },
    setCreativeURL: function (dataItem) {
      return `creatives/display/${dataItem.id}/details`;
    },
    updateTotalRow: function (isNewData) {
      var list = this.list;
      var totalRowEl = "div.item.total-row";
      var arrow, nestedDataForTotal, spinner, totalRow;
      this.prepareTotalRowData(isNewData);
      nestedDataForTotal = this.nestedDataForTotal;
      if (list && list.length > 1 && nestedDataForTotal.length > 0) {
        list[1] = nestedDataForTotal[0];
      }
      this.renderPartialTemplate({
        action: "replaceWith",
        data: nestedDataForTotal[0],
        targetEl: totalRowEl,
        template: this.partials.listItem,
        useTemplateHtml: false
      });
      totalRow = this.el.find(totalRowEl);
      arrow = totalRow.find(".icon.arrow");
      spinner = totalRow.first(".column.name");
      if (this.seriesTotal.loaded) {
        spinner.spin(false);
        totalRow.addClass("highlight");
        arrow.show();
      } else {
        Spinner.apply(spinner, this.spinnerOpts);
        arrow.hide();
      }
    },
    serialize: function () {
      var reportModel = this.reportModel;
      var campaignModel = this.campaignModel;
      var campaignNestedCollection = this.campaignNestedCollection;
      var dim1Collection = this.dim1Collection;
      var dim1NestedCollection = this.dim1NestedCollection;
      var dim1FilteredCollection = this.dim1FilteredCollection;
      var dim1FilteredNestedCollection = this.dim1FilteredNestedCollection;
      var campaignData = campaignModel && campaignModel.loaded ? campaignModel.toJSON() : undefined;
      var campaignNestedData = campaignNestedCollection && campaignNestedCollection.loaded ? campaignNestedCollection.toJSON() : undefined;
      var dim2 = reportModel.get("dim2");
      var campaignFirstColumn, campaignFirstColumnKeys, dim1Data, dim1NestedData, filteredOutList, highlightedStrategyID, nestedData;
      var colorData = reportModel.get("colorData");
      var aggregateBy = this.aggregateBy;
      var columnKeys = this.columnKeys;
      var nestedDataForCampaign = [];
      var highlightedItem = reportModel.get("highlightedItem");
      var highlightedCampaign = highlightedItem && highlightedItem.type === "campaign" ? highlightedItem : null;
      var highlightedStrategy = highlightedItem && highlightedItem.type === "strategy" ? highlightedItem : null;
      var count = 0;
      var isCampaignBenchMarkOn = reportModel.get("campaignBenchmarkOn");
      var itemsHidden = reportModel.getHiddenItemsCount() || !isCampaignBenchMarkOn;
      var list = [];
      var campaignLabel = `Campaign${this.focusInfo}`;
      var accessStrategy = this.isStrategyDimension;
      if (dim2) {
        dim1Data = dim1FilteredCollection && dim1FilteredCollection.loaded && dim1FilteredCollection.comparator ? dim1FilteredCollection.sort().toJSON() : [];
        dim1NestedData = dim1FilteredNestedCollection && dim1FilteredNestedCollection.loaded ? dim1FilteredNestedCollection.toJSON() : [];
      } else {
        dim1Data = dim1Collection && dim1Collection.loaded && dim1Collection.comparator ? dim1Collection.sort().toJSON() : [];
        dim1NestedData = dim1NestedCollection && dim1NestedCollection.loaded ? dim1NestedCollection.toJSON() : [];
      }
      if (colorData && campaignNestedData) {
        nestedDataForCampaign = d3.nest().key(d => d.campaign_id).entries(campaignNestedData);
        this.extendWithKeys(nestedDataForCampaign, columnKeys, true, false, campaignData);
        if (nestedDataForCampaign.length > 0) {
          campaignFirstColumn = nestedDataForCampaign[0];
          campaignFirstColumnKeys = campaignFirstColumn.keys[0];
          if (!this.multiCampaign) {
            campaignFirstColumnKeys.value = campaignLabel;
          }
          campaignFirstColumnKeys.checked = isCampaignBenchMarkOn;
          campaignFirstColumn.isCampaignRow = true;
          campaignFirstColumn.isSelected = highlightedCampaign && highlightedCampaign.id !== null;
        }
      }
      if (colorData && dim1NestedData) {
        nestedData = d3.nest().key(d => d[aggregateBy]).sortKeys(d3.ascending).entries(dim1NestedData);
        this.extendWithKeys(nestedData, columnKeys, false, false, dim1Data);
        if (highlightedStrategy) {
          highlightedStrategyID = highlightedStrategy.id;
          $.each(nestedData, function (i, obj) {
            obj.isSelected = obj.id === highlightedStrategyID;
          });
        }
        if (dim2) {
          dim1FilteredNestedCollection.nestedData = nestedData;
          this.sortData(this.dim1FilteredCollection, "summary", true);
          this.sortData(this.dim1FilteredNestedCollection, "daily", false, true);
        } else {
          dim1NestedCollection.nestedData = nestedData;
          this.sortData(this.dim1Collection, "summary", true);
          this.sortData(this.dim1NestedCollection, "daily", false, true);
        }
        T1.EventHub.publish("sort:grid:performance");
        this.itemCount = reportModel.get("dimensionItemsCount");
        filteredOutList = _.filter(nestedData, data => data.isFilteredOut);
        if (reportModel.get("dimensionItems")) {
          if (filteredOutList.length === nestedData.length || this.itemCount === 0) {
            list = this.prepareListForNoDataMessage();
            this.noDataRender = true;
            reportModel.setNoDataRender(true);
            count = 0;
          } else {
            list = nestedDataForCampaign.concat(this.nestedDataForTotal[0]);
            this.noDataRender = false;
            reportModel.setNoDataRender(false);
            if (nestedData) {
              if (dim2) {
                list = list.concat(dim1FilteredNestedCollection.nestedData);
              } else {
                list = list.concat(dim1NestedCollection.nestedData);
              }
            }
            count = _.chain(nestedData).filter(function (data) {
              if (data) {
                return !data.isFilteredOut;
              }
              return undefined;
            }).size().value();
          }
        }
      }
      this.list = list;
      return {
        contentType: "series-grid",
        hideNoResultMessage: true,
        isExpandable: true,
        isStrategyDimension: accessStrategy,
        itemsHidden: itemsHidden,
        list: list,
        pageControls: {
          count: count,
          total: count
        }
      };
    }
  });
  return T1GridView(GridView);
});
