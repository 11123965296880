define('modules/strategy/main',["jQuery", "T1", "T1Module", "models/strategy", "models/campaign", "models/strategyTargetSummary", "async", "modules/strategy/views/summaryInfo", "modules/strategy/views/strategyInlineForecaster"], function ($, T1, T1Module, Strategy, Campaign, StrategyTargetSummary, async) {
  "use strict";

  return new T1Module({
    name: "strategy",
    viewCfgs: {
      list: {
        collection: null
      },
      item: {
        model: null
      },
      health: {
        model: null
      },
      duplicateStrategy: {
        model: null
      },
      duplicateStrategySettings: {
        model: null
      },
      drawer: {
        model: null
      },
      summaryInfo: {
        model: null
      },
      search: {
        collection: null
      },
      createEdit: {
        model: null
      },
      targeting: {
        model: null
      },
      supply: {
        model: null
      },
      details: {
        model: null
      },
      summary: {
        model: null
      },
      strategySearchBox: {
        model: null
      },
      strategyInlineForecaster: {
        model: null
      },
      datePicker: {
        model: null
      },
      forecasterWarning: {
        model: null
      }
    },
    defaultView: "list",
    initialize: function () {
      var loadMonitor = this.monitor();
      loadMonitor.initialize();
    },
    monitor: function () {
      let isFocused, itemsLoaded, listQty, startTime, visibleRows;
      function initialize(tests) {
        var monitoringTests = tests || {
          hasPageVisibilityAPI: "hidden" in document,
          hasFocus: !document.hidden,
          hasPerformanceAPI: "performance" in window,
          isCorrectPage: /campaign/.test(T1.Location.get())
        };
        if (canMonitor(monitoringTests)) {
          doMonitoring();
        }
      }
      function stopMonitoring() {
        setIsFocused(false);
        manageSubscriptions(false);
      }
      function canMonitor(tests) {
        return Object.keys(tests).every(key => Boolean(tests[key]));
      }
      function doMonitoring() {
        setStartTime(window.performance.now());
        manageSubscriptions(true);
      }
      function itemLoaded() {
        setItemsLoaded(itemsLoaded + 1);
        if (isFocused && itemsLoaded === listQty) {
          logTiming(listQty, visibleRows);
          stopMonitoring();
        }
      }
      function listQtyKnown({
        count: count,
        pageLimit: pageLimit
      }) {
        if (isFocused === true) {
          stopMonitoring();
        } else {
          setIsFocused(true);
          setItemsLoaded(0);
          setListQty(count - pageLimit < 0 ? count : pageLimit);
          setVisibleRows(pageLimit);
        }
      }
      function logTiming(qty, rows) {
        const label = `strategy rows = ${rows}(${qty})`;
        var timeTaken = window.performance.now() - startTime;
        const trackingValue = 100;
        T1.Analytics.logTiming("Page Load", "/campaign", parseInt(timeTaken, 10), label, trackingValue);
      }
      function manageSubscriptions(attach) {
        const subMethod = attach ? "subscribe" : "unsubscribe";
        const listenerMethod = attach ? "addEventListener" : "removeEventListener";
        document[listenerMethod]("visibilitychange", visibilityChangeHandler);
        T1.EventHub[subMethod]("strategies:listQtyKnown", listQtyKnown);
        T1.EventHub[subMethod]("strategies:itemLoaded", itemLoaded);
        T1.EventHub[subMethod]("strategies:stopMonitoring", stopMonitoring);
      }
      function setIsFocused(bool) {
        isFocused = Boolean(bool);
        return isFocused;
      }
      function setItemsLoaded(num) {
        itemsLoaded = num;
        return itemsLoaded;
      }
      function setListQty(int) {
        listQty = int;
        return listQty;
      }
      function setStartTime(float) {
        startTime = float;
        return startTime;
      }
      function setVisibleRows(int) {
        visibleRows = int;
        return visibleRows;
      }
      function visibilityChangeHandler() {
        T1.EventHub.publish("strategies:stopMonitoring");
      }
      return {
        canMonitor: canMonitor,
        doMonitoring: doMonitoring,
        initialize: initialize,
        itemLoaded: itemLoaded,
        listQtyKnown: listQtyKnown,
        logTiming: logTiming,
        setIsFocused: setIsFocused,
        setItemsLoaded: setItemsLoaded,
        setListQty: setListQty,
        setStartTime: setStartTime,
        setVisibleRows: setVisibleRows,
        stopMonitoring: stopMonitoring,
        visibilityChangeHandler: visibilityChangeHandler
      };
    },
    getView: function (...viewArgs) {
      let resultPromise;
      const [type, opts] = viewArgs;
      var self = this;
      var deferredView = $.Deferred();
      var mode = opts.mode;
      var isReadOnly = mode && mode.toLowerCase() === "readonly";
      var isSummary = mode && mode.toLowerCase() === "summary";
      var model = new Strategy({});
      var id = opts.id;
      var goalMonitoringReport = "rpt_goal_monitoring";
      const targetSummaryModel = new StrategyTargetSummary({
        id: opts.id
      });
      var onSuccess = function () {
        opts.model = model;
        opts.targetSummaryData = self.targetSummaryData;
        T1Module.prototype.getView.call(self, type, opts).then(function (view) {
          deferredView.resolve(view);
        });
      };
      this.ignoreResizeWidth = true;
      this.ignoreResults = true;
      if (type === "createEdit") {
        if (mode === "edit" || isSummary === true || isReadOnly === true) {
          model.set({
            id: id,
            campaign: new Campaign()
          });
          async.series({
            getStrategyDetails: function (callback) {
              model.fetch({
                params: {
                  with: ["campaign,advertiser", goalMonitoringReport, "supplemental"],
                  full: "*"
                },
                success: function (...args) {
                  callback.apply(this, null, args);
                }
              });
            },
            getCampaignRptData: function (callback) {
              model.get("campaign").fetch({
                params: {
                  with: ["rpt_goal_monitoring"],
                  full: "*"
                },
                success: function (...args) {
                  callback.apply(this, null, args);
                }
              });
            },
            fetchTargetSummaryData: function (...targetSummaryArgs) {
              const callback = targetSummaryArgs[0];
              if (!self.ignoreResults) {
                targetSummaryModel.fetch({
                  action: "targeting_summary",
                  success: function (...args) {
                    self.targetSummaryData = args[0];
                    callback.apply(this, null, args);
                  }
                });
              } else {
                self.targetSummaryData = {
                  hardCodedResults: true
                };
                callback.apply(this, null, targetSummaryArgs);
              }
            }
          }, onSuccess);
        } else {
          const campaign = new Campaign({
            id: id
          });
          model.set({
            campaign: campaign
          });
          campaign.fetch({
            params: {
              with: ["advertiser", goalMonitoringReport],
              full: "*"
            },
            success: onSuccess
          });
        }
        resultPromise = deferredView.promise();
      } else {
        resultPromise = T1Module.prototype.getView.apply(this, viewArgs);
      }
      return resultPromise;
    }
  });
});
