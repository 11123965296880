define('modules/media/pmp-e/helpers/additions',[], function () {
  var gridDataObj = {
    getGridData: function (elem) {
      var grid1Array = [];
      const maxEntries = 33;
      for (let i = 0; i < maxEntries; i++) {
        grid1Array.push({
          first_name: "First",
          last_name: elem,
          email: "text@example.com",
          selected: false
        });
      }
      return grid1Array;
    }
  };
  return gridDataObj;
});
