define('modules/strategy/supply/batch/views/browse',["Underscore", "jQuery", "T1View", "T1GridView", "T1Permissions", "collections/batchSupplySources", "text!../templates/grid_item.html"], function (_, $, T1View, T1GridView, T1Permissions, BatchSupplySources, gridItem) {
  "use strict";

  var BatchSupplyBrowse = T1View.extend({
    partials: {
      listItem: gridItem
    },
    headers: [{
      name: "name",
      label: "Supply Sources"
    }],
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".name"
    },
    events: {
      "click .icon.include": "clickItem"
    },
    eventhubEvents: {
      "pmpe:dropInBins": "addDroppedItems"
    },
    dataEvents: {
      sharedCollection: {
        add: function ({
          id: id
        }) {
          var $item = this.$(`[data-id="${id}"]`);
          $item.find(".icon").addClass("active");
          $item.addClass("selected");
        },
        remove: function ({
          id: id
        }) {
          var $item = this.$(`[data-id="${id}"]`);
          $item.find(".icon").removeClass("active");
          $item.removeClass("selected");
        }
      }
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      strategy: strategy
    }) {
      this.sharedCollection = sharedCollection;
      this.strategy = strategy;
      this.fetchBatchSupplies();
    },
    fetchBatchSupplies: function () {
      var coll = this.collection = new BatchSupplySources(false, {
        fetchOptions: {
          full: "*"
        },
        isPaginated: false
      });
      if (T1Permissions.check("feature", "batch_supply_fb_video") && this.strategy.get("media_type") === "VIDEO") {
        coll.fetchOptions = $.extend(coll.fetchOptions, {
          q: "has_video==1"
        });
      }
      this.setSearchLoader();
      coll.fetch({
        success: () => {
          if (!T1Permissions.check("feature", "batch_supply_fb_video") || this.strategy.get("campaign").get("goal_type") !== "vcr") {
            const filteredColl = this.collection.models.filter(model => {
              const name = model.get("name");
              return name !== "Facebook In-Stream Video" && name !== "Facebook Suggested Video";
            });
            this.collection.reset(filteredColl);
          }
          this.load();
        }
      });
    },
    serialize: function () {
      var collObj = this.collection.toJSON();
      var sharedColl = this.sharedCollection;
      var resultData = [];
      function serializeData(data) {
        if (sharedColl.get(data.id)) {
          data.isIncluded = true;
        }
        return data;
      }
      for (let i = 0; i < collObj.length; i++) {
        resultData.push(serializeData(collObj[i]));
      }
      return {
        list: resultData
      };
    },
    addTarget: function (item) {
      const itemInSharedCollection = this.sharedCollection.get(item.id);
      if (itemInSharedCollection) {
        this.sharedCollection.remove(itemInSharedCollection);
      } else {
        if (!item.get("action")) {
          item.set({
            action: "include"
          });
        }
        this.sharedCollection.add(item);
      }
    },
    clickItem: function (evt) {
      var id = $(evt.currentTarget).closest(".item").data("id");
      this.preventDefault(evt);
      this.addTarget(this.collection.get(id));
    },
    addDroppedItems: function (items) {
      for (let i = 0; i < items.selectedList.length; i++) {
        this.addTarget(this.collection.get(items.selectedList[i].id));
      }
    }
  });
  return T1GridView(BatchSupplyBrowse);
});
