define('modules/pixel/views/grid',["jQuery", "T1", "T1View", "T1GridView", "Mustache", "text!modules/pixel/templates/grid_item.html", "text!modules/pixel/templates/grid_item_segment.html"], function ($, T1, T1View, createGrid, T, itemTemplate, itemSegmentTemplate) {
  function serializePixel(pixel) {
    var report;
    report = pixel.unique;
    pixel.load_count = report && report.loads !== "0" ? formatNumber(parseInt(report.loads, 10)) : "--";
    pixel.uniques_count = report && report.uniques !== "0" ? formatNumber(parseInt(report.uniques, 10)) : "--";
    pixel.refer_url = report && report.referrer !== "" ? report.referrer : "--";
    pixel.status = pixel.eligible === "1" ? "active" : "inactive";
    return pixel;
  }
  var formatNumber = T1.Formats.rolledUpNumbersShort();
  var GridView = T1View.extend({
    selected: "",
    partials: {
      listItem: itemTemplate
    },
    headers: [],
    headers_event_data: [{
      name: "name",
      label: "name"
    }, {
      name: "loads",
      label: "# loads ydy"
    }, {
      name: "uniques",
      label: "# uniques ydy"
    }, {
      name: "referrer",
      label: "top referrer ydy"
    }, {
      name: "pixel id",
      label: "id"
    }],
    headers_segment: [{
      name: "name",
      label: "name"
    }, {
      name: "segment id",
      label: "id"
    }],
    dataEvents: {
      collection: {
        reset: function () {
          setTimeout($.proxy(this.load, this), 0);
        }
      }
    },
    eventhubEvents: {
      "bins:droppedPixel": function (args) {
        this.droppedPixelInBin.call(this, args);
      },
      "pixel:setSearchLoader": function () {
        this.setSearchLoader();
      },
      "pixel:applyFilter": function (args) {
        switch (args.type) {
          case "dynamic":
            this.headers = this.headers_segment;
            this.partials.listItem = itemSegmentTemplate;
            this.isSegment = true;
            break;
          default:
            this.headers = this.headers_event_data;
            this.partials.listItem = itemTemplate;
            this.isSegment = false;
        }
      }
    },
    events: {
      "click .include": "assignPixel",
      "click .exclude": "assignPixel"
    },
    initialize: function (args) {
      var self = this;
      var collection = args.collection;
      this.selectionMode = args.selectionMode || "includeExclude";
      this.collection = collection;
      this.sharedCollection = args.sharedCollection;
      this.isEligible = 1;
      this.searchFilters = null;
      this.headers = this.headers_event_data;
      $(document.body).on("keydown", ".select", function (e) {
        if (e.keyCode === 39 || e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
        }
      });
      if (args.getAdvertiserId) {
        var ad_id = args.getAdvertiserId();
        self.advertiser_id = ad_id !== "" ? ad_id : null;
        collection.search.set({
          params: [{
            term: "event",
            searchField: "pixel_type"
          }, {
            term: "1",
            searchField: "eligible"
          }],
          fetchRequired: false
        });
        if (ad_id) {
          collection.urlFilter.set({
            entity: "advertiser",
            id: ad_id,
            fetchRequired: true
          });
        }
      }
      if (args.getAdvertiserName) {
        self.advertiser_name = args.getAdvertiserName();
      }
      if (args.getAdvertiser) {
        self.advertiser = args.getAdvertiser();
      }
      if (this.selectionMode !== "includeExclude") {
        this.events["click .item"] = "selectPixel";
      }
      if (args.sharedCollection) {
        self.sharedCollection = args.sharedCollection;
        self.addDataEvent({
          sharedCollection: {
            add: function (data) {
              var id = data.id;
              var $item = this.el.find('[data-id="' + id + '"]');
              var action = data.get("action");
              var $itemRow = $item.children(".item-row");
              $itemRow.addClass("selected").find(".icon." + action).addClass("active");
              $itemRow.parent().addClass("selected-item");
            },
            remove: function (data) {
              var id = data.id;
              var $item = this.el.find('[data-id="' + id + '"]');
              var $itemRow = $item.children(".item-row");
              $itemRow.removeClass("selected").find(".icon").removeClass("active");
              $itemRow.parent().removeClass("selected-item");
            }
          }
        });
      }
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 100
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".name"
    },
    perfectScrollConfig: {
      allowWheelPropagation: false
    },
    selectPixel: function (event) {
      var itemEl = $(event.currentTarget);
      this.preventDefault(event);
      $(this.el).find(".item").removeClass("selected");
      itemEl.addClass("selected");
      T1.EventHub.publish("selected:pixel", {
        id: itemEl.data("id"),
        name: itemEl.find(".name .text").text()
      });
    },
    assignPixel: function (event) {
      var currentTarget = $(event.currentTarget);
      var id = currentTarget.closest(".item").data("id");
      var collection = this.collection;
      var item = collection.get(id);
      var jsonData = item.toJSON();
      jsonData.action = currentTarget.data("action");
      this.preventDefault(event);
      this.removeDragClasses();
      T1.EventHub.publish("assigned:pixel", jsonData);
    },
    droppedPixelInBin: function (data) {
      var collection = this.collection;
      var sharedCollection = this.sharedCollection;
      var selectedList = [];
      var model;
      var jsonData;
      $.each(data.selectedList, function (index, item) {
        model = collection.get(item.id) || sharedCollection.get(item.id);
        if (model) {
          jsonData = model.toJSON();
          jsonData.action = item.action;
          selectedList.push(jsonData);
        }
      });
      T1.EventHub.publish("assigned:pixel", {
        selectedList: selectedList
      });
    },
    unload: function () {
      this.headers = this.headers_event_data;
      this.partials.listItem = itemTemplate;
      this.isSegment = false;
    },
    serialize: function (inputCollection) {
      var sharedCollection = this.sharedCollection;
      var pixelsData = [];
      var collection = inputCollection || this.collection;
      var collectionData = collection.toJSON();
      var maxPathLength = 22;
      var isIncludeExludeSelectMode = this.selectionMode !== "singleSelection";
      $.each(collectionData, function (index, pixel) {
        var pixelData = serializePixel(pixel);
        var refer_url = pixelData.refer_url;
        var action;
        var sharedPixel;
        if (refer_url && refer_url.length > maxPathLength) {
          pixelData.refer_url = refer_url.substr(0, maxPathLength) + "...";
          pixelData.full_refer_url = refer_url;
        }
        if (sharedCollection) {
          sharedPixel = sharedCollection.get(pixel.id);
          action = sharedPixel ? sharedPixel.get("action") : undefined;
          pixelData.isIncluded = action === "include";
          pixelData.isExcluded = action === "exclude";
          pixelData.isSelected = action !== undefined;
        }
        pixelData.includeExcludeSelectMode = isIncludeExludeSelectMode;
        pixelsData.push(pixelData);
      });
      return {
        list: pixelsData,
        contentType: "pixel-grid",
        widgetClassName: this.isSegment === true ? "segment" : ""
      };
    }
  });
  return createGrid(GridView);
});
