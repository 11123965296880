define('collections/concepts',["jQuery", "T1", "T1Collection", "models/concept", "T1Sortable"], function ($, T1, T1Collection, Concept) {
  T1.Models.Concepts = T1Collection.extend({
    model: Concept,
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }]
    },
    setAdvertiser: function (advID) {
      this.urlFilter.set({
        entity: "advertiser",
        id: advID
      });
    },
    urlString: "concepts"
  });
  return T1.Models.Concepts;
});
