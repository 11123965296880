define('modules/siteList/main',["jQuery", "T1", "T1Module", "collections/siteLists"], function ($, T1, T1Module, SiteLists) {
  return new T1Module({
    name: "siteList",
    viewCfgs: {
      list: {
        collection: null,
        sharedCollection: null
      },
      search: {
        model: null
      },
      grid: {
        collection: null
      },
      additionalSitesForm: {
        model: null
      },
      readOnly: {
        collection: null
      }
    },
    defaultView: "list",
    getView: function (type, opts) {
      switch (type) {
        case "search":
        default:
          var collection = new SiteLists([], {
            pageLimit: 40
          });
          collection.fetchOptions = $.extend({
            full: "*"
          }, collection.fetchOptions);
          collection.search.set({
            params: [{
              term: "1",
              searchField: "status"
            }],
            fetchRequired: false
          });
          this.viewCfgs[type].collection = collection;
          break;
      }
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
