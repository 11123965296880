define('utils/FieldMap',["T1"], function (T1) {
  function FieldMap(map) {
    this.fieldMap = map;
  }
  FieldMap.prototype.translate = function (key, value) {
    if (this.fieldMap.entity === "video" && key === "concept") {
      key = "concept_id";
      value = value.get("name");
    }
    const output = {};
    const map = this.fieldMap[key];
    if (map) {
      output.field = map.label;
      if (map.values) {
        if (typeof map.values === "function") {
          output.value = map.values(value);
        } else {
          output.value = map.values[value] || value;
        }
      } else {
        output.value = expandValue(value);
      }
      if (!output.value) {
        output.value = "--";
      } else if (map.suffix) {
        output.value += map.suffix;
      }
    }
    return output;
  };
  function expandValue(value) {
    var result = value;
    if (value instanceof T1.Models.T1Model) {
      result = value.get("name") || value.get("id");
    } else if (value instanceof T1.Models.T1Collection) {
      result = value.map(function (model) {
        return model.get("name") || model.get("id");
      }).join(", ");
    }
    return result;
  }
  return FieldMap;
});
