define('modules/audiences/segments/bulkCreate/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "audiences/segments/bulkCreate",
      viewCfgs: {
        bulkCreate: {}
      },
      defaultView: "bulkCreate"
    });
  }
  return instance;
});
