define('modules/campaign/views/detailsReadOnly',["jQuery", "T1", "T1View", "T1Layout", "text!modules/campaign/templates/detailsReadOnly.html", "models/pixel", "collections/campaignSiteLists", "collections/vendors", "models/organization", "utils/CampaignStrategyUtils", "T1Timezones", "T1Permissions", "modules/campaign/views/helpers/budgetFlightsShared", "models/userPreferences"], function ($, T1, T1View, T1Layout, template, Pixel, CampaignSiteLists, Vendors, OrganizationModel, CampaignStrategyUtils, T1Timezones, T1Permissions, BudgetFlightsSharedHelper, UserPreferences) {
  "use strict";

  const overrideLevels = Object.freeze({
    0: "STL_NONE",
    25: "STL_LIGHT",
    50: "STL_MODERATE",
    75: "STL_STRONG",
    100: "STL_MAXIMUM"
  });
  var getStartEndDatesDisplay = CampaignStrategyUtils.getDisplayTextForStartAndEndDates;
  var getFreqencyGap = CampaignStrategyUtils.getDisplayTextForCampFrequencyCap;
  var useCampaignFreqCap = CampaignStrategyUtils.useCampaignFrequencyCap;
  var getDisplayTextForMonetaryCap = CampaignStrategyUtils.getDisplayTextForMonetaryCap;
  function formatPercentage(goalValue) {
    var value = parseFloat(goalValue);
    const twoDigits = 2;
    return `${isNaN(value) === false ? value.toFixed(twoDigits) : "0"}%`;
  }
  const getRestrictedSameDeviceTargeting = function () {
    function getStatus(targetingValue, model) {
      let status;
      if (targetingValue === "0") {
        status = model.get("connected_id_type") === "DETERMINISTIC_ONLY" ? "Deterministic" : "Deterministic/Probabilistic";
      } else {
        status = "Disabled";
      }
      return status;
    }
    return function (self) {
      let messageBase, targetingValue;
      const sameDeviceTargeting = self.model.get("restrict_targeting_to_same_device_id");
      if (sameDeviceTargeting) {
        targetingValue = sameDeviceTargeting;
        messageBase = "Customize for campaign";
      } else {
        targetingValue = self.orgModel.get("restrict_targeting_to_same_device_id");
        messageBase = "Use Organization setting";
      }
      const status = getStatus(targetingValue, self.model);
      return `${messageBase} (${status})`;
    };
  }();
  const DetailsReadOnlyView = T1View.extend({
    template: template,
    displayDateFormat: "MM/dd/yyyy",
    displayTimeFormat: "h:mm tt",
    showCurrentFlights: false,
    events: {
      "click .section-budget-flights .show-currentandfuture-only": "budgetFlightsFilterHandler",
      "click .section-budget-flights .list-content .col3 span": "chartIconClickHandler"
    },
    suspiciousTrafficShortDescription: {
      STL_STANDARD: "T1 Standard",
      STL_NONE: "T1 Standard Disabled",
      STL_LIGHT: "T1 Standard + Light Increase",
      STL_MODERATE: "T1 Standard + Moderate increase",
      STL_STRONG: "T1 Standard + Strong increase",
      STL_MAXIMUM: "T1 Standard + Maximum increase"
    },
    budgetFlightsFilterHandler: function () {
      const checkObj = this.$(".section-budget-flights .show-currentandfuture-only");
      var showCurrentOnly = checkObj.is(":checked");
      if (this.showCurrentAndUpcomingBudgetFlightsOnly !== showCurrentOnly) {
        this.showCurrentAndUpcomingBudgetFlightsOnly = showCurrentOnly;
        this.showCurrentFlights = showCurrentOnly;
        this.preferences.save();
        this.renderPartial(".section-budget-flights .list-content");
        T1.Tooltip(this.$(".section-budget-flights .list-content .col3"), {
          offset: 5,
          delayIn: "100",
          delayOut: "100",
          className: "budgetflight-chart-icon-tooltip"
        });
      }
    },
    getBudgetFlightModelById: function (id) {
      var collection = this.model.get("budget_flight");
      return collection.get(String(id));
    },
    getSuspiciousTrafficLevel: function (overrideFlag, level) {
      var key = "STL_STANDARD";
      if (overrideFlag === "0" && level === "25") {
        key = "STL_STANDARD";
      } else if (overrideFlag === "1") {
        key = overrideLevels[level] || key;
      }
      return key;
    },
    chartIconClickHandler: function (e) {
      var itemId = $(e.currentTarget).closest(".data-row").data("itemid");
      const model = this.getBudgetFlightModelById(itemId);
      if (model) {
        const url = `#reports/campaigns/performance/${this.model.id}`;
        const urlLaunchOption = {
          viewOptions: {
            focus: "all",
            start_date: model.get("start_date"),
            end_date: model.get("end_date")
          }
        };
        T1.Location.set(url, urlLaunchOption);
      }
    },
    initialize: function ({
      model: model
    }) {
      var self = this;
      var signature = T1.Signature.create();
      this.model = model;
      this.pixelObj = null;
      this.model.getOrgId().then(orgId => {
        this.orgId = orgId;
      });
      this.budgetFlightHelper = new BudgetFlightsSharedHelper(this.model.get("budget_flight"), function () {
        return self.getCurrencyCode();
      });
      signature.set(new Map([["root", "ui"], ["module", "campaign"], ["view", "budgetFlights"]]));
      this.viewabilityType = this.model.get("viewability_type");
      if (this.viewabilityType) {
        this.vendorList = new Vendors();
      }
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["showCurrentFlights"]);
      this.showCurrentFlights = this.preferences.get("showCurrentFlights");
      this.showCurrentAndUpcomingBudgetFlightsOnly = String(this.showCurrentFlights) === "true";
      this.showCPEIODetails = this.model.get("showCPEIO");
    },
    doRender: function () {
      var toolTipDbaSetting = {
        className: "tooltip-orgs",
        getExternalTip: false,
        delayIn: 0,
        delayOut: 0,
        offset: 68,
        leftPosAdjust: 700,
        gravity: "s"
      };
      var toolTipTpas = {
        className: "tooltip-orgs",
        getExternalTip: false,
        delayIn: 0,
        delayOut: 0,
        offset: 35,
        leftPosAdjust: 435,
        gravity: "s"
      };
      var toolTipSameDevice = {
        className: "tooltip-orgs",
        getExternalTip: false,
        delayIn: 0,
        delayOut: 0,
        offset: 35,
        leftPosAdjust: 340,
        gravity: "s"
      };
      this.render().then(() => {
        T1.Tooltip(this.$(".section-budget-flights .list-content .col3"), {
          offset: 5,
          delayIn: "100",
          delayOut: "100",
          className: "budgetflight-chart-icon-tooltip"
        });
        T1.Tooltip(this.$(".dbaSetting"), toolTipDbaSetting);
        T1.Tooltip(this.$(".tpasTargeting"), toolTipTpas);
        T1.Tooltip(this.$(".sameDeviceTargeting"), toolTipSameDevice);
      });
    },
    load: function () {
      var self = this;
      this.model.getOrgId().then(function (orgId) {
        self.orgId = orgId;
        self.orgModel = new OrganizationModel({
          id: self.orgId
        });
        self.orgModel.fetch({
          success: function () {
            if (self.viewabilityType) {
              self.vendorList.pageLimit = 100;
              self.vendorList.search.set({
                params: [{
                  term: "VIEWABILITY",
                  searchField: "vendor_type"
                }],
                fetchRequired: true,
                pageLimit: 100,
                success: function () {
                  self.organizationFetchedSuccess();
                }
              });
            } else {
              self.organizationFetchedSuccess();
            }
          }
        });
      });
    },
    organizationFetchedSuccess: function () {
      let goalType;
      const model = this.model;
      const pixelId = model.get("merit_pixel_id");
      this.suspicious_traffic_level_default = true;
      if (model && model.get("goal_type")) {
        goalType = model.get("goal_type");
      }
      this.isCustomBrainCorrectType = goalType && (goalType === "roi" || goalType === "cpe" || goalType === "cpa" || goalType === "cpc" || goalType === "vcr" || goalType === "ctr" || goalType === "viewability_rate" || goalType === "vcpm");
      if (pixelId) {
        const pixel = new Pixel();
        pixel.id = pixelId;
        this.pixelObj = pixel;
        pixel.fetch({
          success: () => {
            this.doRender();
            this.loadSections();
          }
        });
      } else {
        this.doRender();
        this.loadSections();
      }
      T1Timezones.done(mthds => {
        const zoneName = this.model.get("zone_name");
        this.timezones = mthds;
        this.zoneName = zoneName || this.timezones.getDefaultZoneName();
        this.renderPartial(".main-data-block");
      });
    },
    loadSections: function () {
      var siteList = new CampaignSiteLists();
      siteList.id = this.model.id;
      siteList.fetchOptions = $.extend({
        full: "*"
      }, siteList.fetchOptions);
      this.layout = new T1Layout({
        el: () => this.$(".w-ReadonlySitelists"),
        template: '<div class="readonly-content"></div>',
        layout: {
          ".readonly-content": [{
            module: "siteList",
            viewType: "readOnly",
            options: {
              collection: siteList
            }
          }]
        }
      });
      this.customBrainLayout = new T1Layout({
        el: () => this.$(".w-ReadonlyCustomBrainSelections"),
        template: '<div class="readonly-content-CBS"></div>',
        layout: {
          ".readonly-content-CBS": [{
            module: "campaign/pixelSegment",
            viewType: "readOnly",
            options: {
              model: this.model
            }
          }]
        }
      });
      this.layout.load();
      if (this.isCustomBrainCorrectType) {
        this.customBrainLayout.load();
      }
    },
    canUnload: function () {
      return true;
    },
    getBudgetFlightSerializedData: function () {
      var showFlights = typeof this.showCurrentFlights === "string" ? this.showCurrentFlights === "true" : this.showCurrentFlights;
      const cpeioDetails = this.model.get("cpeio");
      return this.budgetFlightHelper.serializeData(new Date(), showFlights, false, true, cpeioDetails);
    },
    serialize: function () {
      let val;
      var self = this;
      var model = this.model;
      var currencyCode = model.get("currency_code");
      var resultJSON = {};
      var spaceEntity = "&nbsp;";
      var summaryLeft = [];
      var summaryRight = [];
      var attributionLeft = [];
      var attributionRight = [];
      var venderAgreements = [];
      var formatCurrency = T1.Utils.formatCurrency;
      var showAttribution = false;
      const timezone = this.timezones && this.timezones.getByZoneName ? this.timezones.getByZoneName(this.zoneName) : null;
      var goalTypeRef = {
        spend: "CPM SPEND",
        reach: "CPM REACH",
        cpc: "CPC",
        cpe: "CPE",
        cpa: "CPA",
        roi: "ROI",
        vcr: "Video Completion Rate",
        ctr: "CTR",
        viewability_rate: "Viewability Rate",
        vcpm: "Viewable CPM"
      };
      var getStatus = function () {
        val = model.get("status");
        return val && val === "1" ? "Active" : "Inactive";
      };
      var getMargin = function () {
        val = model.get("margin_pct");
        if (val && val.length) {
          return `${val}%`;
        }
        return "";
      };
      var getAdvertiserName = function () {
        val = model.get("advertiser");
        return val ? val.get("name") : spaceEntity;
      };
      var getGoalTypeAndValue = function () {
        var goalTypeValue = model.get("goal_type");
        var goalType = goalTypeRef[goalTypeValue];
        var goalValue = model.get("goal_value");
        goalType = !goalType ? "" : goalType;
        showAttribution = goalType === "CPE" || goalType === "CPA" || goalType === "ROI";
        goalValue = goalTypeValue === "vcr" || goalTypeValue === "ctr" || goalTypeValue === "viewability_rate" ? formatPercentage(goalValue) : formatCurrency(goalValue, "", currencyCode);
        if (goalType === "ROI") {
          return goalType;
        }
        return `${goalType} ${goalValue}`;
      };
      var getManagedByVal = function () {
        val = model.get("service_type");
        if (val && val.toUpperCase() === "MANAGED") {
          return "MediaMath";
        } else if (val && val.toUpperCase() === "SELF") {
          return "T1 Client";
        }
        return "Unknown";
      };
      var getStartEndDates = function (timeZoneOnly) {
        let timeZoneDesc = timezone ? timezone.get("description") || "" : "";
        timeZoneDesc = timeZoneDesc.length ? ` ${timeZoneDesc}` : "";
        if (timeZoneOnly === true) {
          return timeZoneDesc;
        }
        return getStartEndDatesDisplay({
          startDate: model.get("start_date"),
          endDate: model.get("end_date"),
          dateFormat: self.displayDateFormat,
          timeFormat: self.displayTimeFormat
        }) + timeZoneDesc;
      };
      var getFrequencyAmt = function () {
        var isFreqCapChecked = useCampaignFreqCap(model.toJSON());
        var noCapVal = model.get("frequency_optimization") === "1" ? "T1 Optimized" : "No Cap";
        if (isFreqCapChecked === true) {
          return getFreqencyGap(model.get("frequency_amount"), model.get("frequency_type"), model.get("frequency_interval"));
        }
        return noCapVal;
      };
      var getMeritPixelName = function () {
        var maxLen = 64;
        val = spaceEntity;
        if (self.pixelObj) {
          val = self.pixelObj.get("name");
          if (val) {
            if (val.length > maxLen) {
              val = val.substr(0, maxLen);
            }
          } else {
            val = spaceEntity;
          }
        }
        return val;
      };
      var getPostViewAttribution = function () {
        val = model.get("pv_pct");
        val = val ? parseInt(val, 10) : "";
        return isNaN(val) === false ? `${val.toFixed(0)}%` : spaceEntity;
      };
      var getDisplayValueFromMinuteValue = function (fieldName) {
        var fieldVal = model.get(fieldName);
        if (fieldVal && !isNaN(fieldVal)) {
          let resultVal, suffixVal;
          const hrsDivider = 60;
          const daysDivider = 24;
          const percent = 100;
          const minutes = parseInt(fieldVal, 10);
          const hours = minutes / hrsDivider;
          const days = hours / daysDivider;
          if (days >= 1 && hours % daysDivider === 0) {
            suffixVal = "Days";
            resultVal = parseFloat(days);
          } else if (hours >= 1) {
            suffixVal = "Hours";
            resultVal = parseFloat(hours);
          } else {
            suffixVal = "Minutes";
            resultVal = minutes;
          }
          if (resultVal <= 1) {
            suffixVal = suffixVal.replace(/s+$/, "");
          }
          return `${Math.round(resultVal * percent) / percent} ${suffixVal}`;
        }
        return null;
      };
      var getRepeatMeritEvents = function () {
        var perUserDesc = "Attribute one Merit Event per user";
        val = model.get("conversion_type");
        if (val === "one") {
          return perUserDesc;
        } else if (val === "variable") {
          val = getDisplayValueFromMinuteValue("conversion_variable_minutes");
          return !val ? spaceEntity : `${perUserDesc} per ${val}`;
        }
        return spaceEntity;
      };
      var getMonetaryPacingText = function () {
        var spendType = model.get("spend_cap_type");
        if (spendType === "t1-optimized") {
          return "T1 Optimized";
        }
        return `Custom: ${getDisplayTextForMonetaryCap(model.get("spend_cap_type"), model.get("spend_cap_automatic"), model.get("spend_cap_amount"), currencyCode)}`;
      };
      var getImpPacingText = function () {
        var customText = "Custom: ";
        var impType = model.get("impression_cap_type");
        var impAmount = model.get("impression_cap_amount");
        var impAuto = model.get("impression_cap_automatic");
        switch (impType) {
          case "t1-optimized":
            impType = "T1 Optimized";
            return impType;
          case "no-limit":
            impType = "No Cap";
            break;
          case "asap":
            impType = "ASAP";
            break;
          case "even":
            impType = "Even";
            break;
        }
        if (impType === "No Cap") {
          return customText + impType;
        }
        if (impAuto === "1") {
          return `${customText + impType}/Auto`;
        }
        return `${customText + impType} ${impAmount} per Day`;
      };
      var getValueForMinimizeAdCollision = function () {
        var isMinimizeAtAdvertiser = model.get("advertiser") && model.get("advertiser").get("minimize_multi_ads") === "1";
        if (isMinimizeAtAdvertiser) {
          return "This setting has been activated at the Advertiser level.";
        }
        if (model.get("minimize_multi_ads") === "0") {
          return "Off";
        }
        return "On";
      };
      var getDynamicBudgetAllocation = function () {
        var dbaType = model.get("dynamic_budget_allocation_type");
        switch (dbaType) {
          case "SBA_AUTO_ALLOCATION":
            dbaType = "Auto Allocation";
            break;
          case "SBA_RECOMMENDATIONS_ONLY":
            dbaType = "Recommendations Only";
            break;
          case "OFF":
          default:
            dbaType = "Off";
            break;
        }
        return dbaType;
      };
      var getSuspiciousTrafficFilter = function () {
        let suspicious_traffic_level = self.getSuspiciousTrafficLevel(model.get("override_suspicious_traffic_filter"), model.get("suspicious_traffic_filter_level"));
        const suspicious_traffic_level_org_default = self.getSuspiciousTrafficLevel(self.orgModel.get("override_suspicious_traffic_filter"), self.orgModel.get("suspicious_traffic_filter_level"));
        if (suspicious_traffic_level === "STL_STANDARD") {
          suspicious_traffic_level = suspicious_traffic_level_org_default;
        }
        return self.suspiciousTrafficShortDescription[suspicious_traffic_level];
      };
      summaryLeft.push({
        fieldName: "Status",
        fieldValue: getStatus()
      });
      summaryLeft.push({
        fieldName: "Advertiser",
        fieldValue: getAdvertiserName()
      });
      summaryLeft.push({
        fieldName: "Campaign Name",
        fieldValue: model.get("name") || spaceEntity
      });
      summaryLeft.push({
        fieldName: "Managed By",
        fieldValue: getManagedByVal()
      });
      summaryLeft.push({
        fieldName: "Time Zone",
        fieldValue: getStartEndDates(true)
      });
      summaryLeft.push({
        fieldName: "Monetary Budget",
        fieldValue: formatCurrency(model.get("total_budget"), "", currencyCode)
      });
      if (T1Permissions.check("feature", "dynamic_budget_allocation")) {
        summaryLeft.push({
          field: "dbaSetting",
          fieldName: "Dynamic Budget Allocation",
          fieldValue: getDynamicBudgetAllocation(),
          tooltipClass: "dbaSettingTooltip",
          tooltip: "Dynamic Budget Allocation (DBA) calculates optimal pacing amounts for strategies within a campaign " + "based on strategy performance and ability to spend. " + "<br/><br/>&nbsp;&nbsp;&nbsp;&bull;&nbsp;Auto Allocation: Strategy pacing amounts are automatically " + "updated. <br/>&nbsp;&nbsp;&nbsp;&bull;&nbsp;Recommendations Only: Strategy pacing amount recommendations" + " are provided for you to review and <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + "implement manually. <br/>&nbsp;&nbsp;&nbsp;&bull;&nbsp;Off: This feature is turned off." + "<br/><br/>Subject to fees. Please refer to T1 contracts for fee details." + "<br/><br/>IMPORTANT: DBA will set strategy pacing amounts based on the campaign " + "spend to pace amount unless a monetary manual campaign daily cap is set (this value can be " + 'set in the "Daily Pacing" section of campaign settings just below this setting).' + " If a monetary manual campaign daily cap is set, DBA will set strategy" + " pacing amounts based on that value.  Impression pacing targets are not adjusted by DBA." + '<br/><br/>More info on the <a class="wiki-link" ' + 'href="https://mediamathsupport.force.com/s/article/Dynamic-Budget-Allocation-DBA" rel="noopener" ' + 'target="_blank" style="color: #fff">Knowledge Base</a> as well as <a class="wiki-link" ' + 'href="https://mediamathsupport.force.com/s/article/DBA-Best-Practices" rel="noopener" ' + 'target="_blank" style="color: #fff">Best Practices</a> for using this feature.'
        });
      }
      if (this.model.get("is_programmatic_guaranteed") !== "1") {
        summaryLeft.push({
          fieldName: "Monetary Daily Cap",
          fieldValue: getMonetaryPacingText()
        });
      }
      summaryLeft.push({
        fieldName: "Impression Budget",
        fieldValue: model.get("total_impression_budget") ? model.get("total_impression_budget").replace(/(\d)(?=(\d{3})+$)/g, "$1,") : "--"
      });
      if (this.model.get("is_programmatic_guaranteed") !== "1") {
        summaryLeft.push({
          fieldName: "Impression Daily Cap",
          fieldValue: getImpPacingText()
        });
      }
      if (this.model.get("bid_min_devices") === "2") {
        summaryLeft.push({
          fieldName: "Bid on Cross Device",
          fieldValue: "Deterministic + Bid on users with 2 or more devices"
        });
      }
      val = model.get("io_name");
      if (val) {
        summaryLeft.push({
          fieldName: "IO Name",
          fieldValue: val
        });
      }
      summaryRight.push({
        fieldName: "Margin",
        fieldValue: getMargin()
      });
      summaryRight.push({
        fieldName: "Goal Type / Value",
        fieldValue: getGoalTypeAndValue()
      });
      summaryRight.push({
        fieldName: "Ad Server Of Record",
        fieldValue: model.get("ad_server") ? model.get("ad_server").name : spaceEntity
      });
      summaryRight.push({
        fieldName: "Campaign Frequency Cap",
        fieldValue: getFrequencyAmt()
      });
      summaryRight.push({
        fieldName: "Minimize Ad Collisions",
        fieldValue: getValueForMinimizeAdCollision()
      });
      summaryRight.push({
        fieldName: "Suspicious Traffic Filter Strength",
        fieldValue: getSuspiciousTrafficFilter()
      });
      if (this.model.get("is_programmatic_guaranteed") !== "1") {
        summaryRight.push({
          field: "sameDeviceTargeting",
          fieldName: `Bid across user's devices and browsers using ConnectedID`,
          fieldValue: getRestrictedSameDeviceTargeting(this),
          tooltipClass: "sameDeviceTargetingTooltip",
          tooltip: `When this setting is enabled, the campaign bids on any of the targeted user's devices using ` + "ConnectedID cross-device recognition. If this setting is disabled, the campaign will only bid on " + "impressions where the targeted action occurred on the same device."
        });
      }
      if (this.viewabilityType) {
        const viewabilityVendorId = model.get("viewability_vendor_id");
        if (this.viewabilityType === "BYOV") {
          summaryLeft.push({
            field: "viewabilityType",
            fieldName: "Viewability Type",
            fieldValue: "Bring Your Own Vendor"
          });
          summaryLeft.push({
            field: "viewabilityVendorId",
            fieldName: "Viewability Vendor",
            fieldValue: this.vendorList.get(viewabilityVendorId).get("name")
          });
        } else {
          const viewabilitySampleRate = model.get("viewability_sample_rate");
          summaryLeft.push({
            field: "viewabilityType",
            fieldName: "Viewability Type",
            fieldValue: "T1 Auto Tag"
          });
          summaryLeft.push({
            field: "viewabilityVendorId",
            fieldName: "Viewability Vendor",
            fieldValue: this.vendorList.get(viewabilityVendorId).get("name")
          });
          summaryRight.push({
            field: "viewabilitySampleRate",
            fieldName: "Viewability Sample Rate",
            fieldValue: viewabilitySampleRate === "0.05" ? "Sample" : "All Impressions"
          });
        }
      }
      val = model.get("io_reference_num");
      if (val) {
        summaryRight.push({
          fieldName: "IO Reference No.",
          fieldValue: val
        });
      }
      resultJSON.showAttribution = showAttribution;
      resultJSON.goal_type = model.get("goal_type");
      if (showAttribution === true) {
        attributionLeft.push({
          fieldName: "Merit Pixel",
          fieldValue: getMeritPixelName()
        });
        attributionLeft.push({
          fieldName: "Post-click Window",
          fieldValue: getDisplayValueFromMinuteValue("pc_window_minutes") || "--"
        });
        attributionLeft.push({
          fieldName: "Post-view Window",
          fieldValue: getDisplayValueFromMinuteValue("pv_window_minutes") || "--"
        });
        attributionRight.push({
          fieldName: "Post-View Attribution",
          fieldValue: getPostViewAttribution()
        });
        attributionRight.push({
          fieldName: "Repeat Merit Events",
          fieldValue: getRepeatMeritEvents()
        });
        attributionRight.push({
          fieldName: "Bid Optimization",
          fieldValue: model.get("has_custom_attribution") === "1" ? "Based on custom attribution" : "Based on T1 attribution"
        });
      }
      const vendorContracts = model.get("vendor_contract");
      if (vendorContracts && vendorContracts.models && vendorContracts.models.length) {
        $.each(vendorContracts.models, function (index, contract) {
          const vendor = contract.get("vendor");
          if (vendor) {
            const source = contract.get("use_mm_contract") === "1" ? "MediaMath" : "T1 Client";
            const cost = formatCurrency(contract.get("price"), "", "USD");
            venderAgreements.push({
              name: vendor.name || spaceEntity,
              source: source,
              cost: cost
            });
          }
        });
      }
      const budgetFlightSerializedBlock = this.getBudgetFlightSerializedData();
      resultJSON.budgetFlights = budgetFlightSerializedBlock.budgetFlightItems;
      resultJSON.showBudgetFlights = Boolean(resultJSON.budgetFlights.length);
      resultJSON.checkboxVisibleForShowCurrent = budgetFlightSerializedBlock.checkboxVisibleForShowCurrent;
      resultJSON.showCurrentAndUpcomingBudgetFlightsOnly = this.showCurrentAndUpcomingBudgetFlightsOnly;
      resultJSON.summaryLeft = summaryLeft;
      resultJSON.summaryRight = summaryRight;
      resultJSON.attributionLeft = attributionLeft;
      resultJSON.attributionRight = attributionRight;
      resultJSON.venderAgreements = venderAgreements;
      resultJSON.showCPEIODetails = this.showCPEIODetails;
      return resultJSON;
    }
  });
  return DetailsReadOnlyView;
});
