define('modules/admin/myOrgs/organization/main',['require','jQuery','collections/contracts','collections/legacyContracts','T1Module','When'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Contracts = require("collections/contracts");
  const LegacyContracts = require("collections/legacyContracts");
  const T1Module = require("T1Module");
  const When = require("When");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "admin/myOrgs/organization",
      viewCfgs: {
        contract: {},
        settings: {},
        createEdit: {},
        details_readOnly: {},
        details: {},
        exchangeSeats_readOnly: {},
        exchangeSeats: {},
        settings_readOnly: {}
      },
      defaultView: "createEdit",
      getView(...args) {
        const [type, opts] = args;
        var self = this;
        var promises = [];
        var deferredView = $.Deferred();
        var id = opts.id === "new" && opts.model && opts.model.get("id") || opts.id;
        var contracts, legacyContracts, resultPromise;
        var onSuccess = function () {
          opts.contracts = contracts;
          opts.legacyContracts = legacyContracts;
          T1Module.prototype.getView.call(self, type, opts).then(function (view) {
            deferredView.resolve(view);
          });
        };
        if (type === "contract") {
          resultPromise = deferredView.promise();
          const contractsFetch = When.defer();
          promises.push(contractsFetch.promise);
          contracts = new Contracts();
          contracts.canCache = false;
          contracts.pageLimit = 100;
          contracts.urlString = `contracts/limit/organization=${id}`;
          contracts.fetch({
            success: function () {
              contractsFetch.resolve();
            }
          });
          const legacyContractsFetch = When.defer();
          promises.push(legacyContractsFetch.promise);
          legacyContracts = new LegacyContracts();
          legacyContracts.canCache = false;
          legacyContracts.pageLimit = 100;
          legacyContracts.urlString = `legacy_contracts/limit/organization=${id}`;
          legacyContracts.fetch({
            success: function () {
              legacyContractsFetch.resolve();
            }
          });
          When.all(promises).then(function () {
            onSuccess();
          });
        } else {
          resultPromise = T1Module.prototype.getView.apply(this, args);
        }
        return resultPromise;
      }
    });
  }
  return instance;
});
