define('modules/admin/siteLists/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "admin/siteLists",
      viewCfgs: {
        createEdit: {},
        csvExportModal: {},
        grid: {},
        siteLists: {},
        usage: {},
        usageItem: {}
      },
      defaultView: "siteLists"
    });
  }
  return instance;
});
