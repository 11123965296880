define('modules/media/pmp-d/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "media/pmp-d",
      viewCfgs: {
        "pmp-d": {}
      },
      defaultView: "pmp-d"
    });
  }
  return instance;
});
