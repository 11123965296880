define('modules/targeting/audience/views/readOnly',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils", "utils/currencyConversionHelper", "T1Tooltip"], function ($, _, T1, T1View, T1Permissions, template, Utils, CurrencyUtils) {
  "use strict";

  var currencyFormatterGetter = T1.Utils.getCurrencyTemplateFunc;
  const hasGroupPermission = T1Permissions.check("feature", "campaigns_group_audience_segment");
  const readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function ({
      strategy: strategy,
      model: model,
      retiredSegmentsCollection: retiredSegmentsCollection,
      iASId: iASId,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit
    }) {
      this.strategy = strategy;
      this.currencyCode = strategy ? strategy.getCurrencyCode() : "";
      this.groupedSegments = model;
      this.retiredSegments = retiredSegmentsCollection;
      this.canLoad = false;
      this.iASId = iASId;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.groupedSegments.fetch({
        success: dataGrouped => {
          this.canLoad = true;
          this.partialCollection = dataGrouped.get("partials");
          this.collection = dataGrouped.get("user_saved");
          this.collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.collection, this.currencyCode);
          this.retiredSegments.fetchOptions.currency_code = this.currencyCode;
          this.retiredSegments.fetch({
            success: dataRetired => {
              this.canLoad = true;
              this.retiredCollection = dataRetired;
              this.collection = this.collection.add(this.retiredCollection.models, {
                silent: true
              });
              this.load();
            }
          });
        },
        currency_code: this.currencyCode
      });
    },
    load: function () {
      this.render().then(() => {
        const loader = this.loader();
        if (!this.canLoad || this.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
        T1.Tooltip(this.$(".warning-holder"), {});
      });
    },
    serialize: function () {
      let iASElement, tempData;
      var self = this;
      const {
        currencyCode: currencyCode,
        collection: collObj
      } = this;
      var resultJSON = {
        dataMain: []
      };
      var codeNameHashInc = {};
      var codeNameHashExc = {};
      var spaceEntity = "&nbsp;";
      var formatNumber = T1.Formats.rolledUpNumbersShort();
      var createDataBlock = Utils.createGroupedDataArray;
      var hasIncludeData = false;
      var hasExcludeData = false;
      const createGroupArray = function (groupedHash) {
        const dataArr = [];
        function groupSegments(segment) {
          const identifier = segment.identifier;
          const findGroup = dataArr.find(group => group.identifier === identifier);
          if (findGroup) {
            findGroup.items.push(segment);
          } else {
            const number = parseInt(identifier.substring(1), 10);
            dataArr.push({
              title: `Group ${number} (${segment.operator})`,
              identifier: identifier,
              items: [segment]
            });
          }
        }
        for (const [, value] of Object.entries(groupedHash)) {
          value.forEach(groupSegments);
        }
        dataArr.sort((a, b) => parseInt(a.identifier.substring(1), 10) - parseInt(b.identifier.substring(1), 10));
        return dataArr;
      };
      var getAndOrOperation = function () {
        var defaultVal = "or";
        var result = defaultVal;
        if (hasGroupPermission) {
          return "(AND)";
        }
        if (self.strategy) {
          result = self.strategy.get("audience_segment_include_op");
          if (!result) {
            result = defaultVal;
          }
        }
        return `(${result})`;
      };
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/audience`;
      }
      if (!collObj || !collObj.models || !collObj.models.length) {
        this.hasData = false;
        return resultJSON;
      }
      const sortedCollObj = _.groupBy(collObj.models, function (model) {
        return model.get("rel") !== "retired_audience_segment" ? model.get("full_path").split(" - ")[0] : model.get("full_retired_path").split(" - ")[0];
      });
      $.each(this.partialCollection.toJSON(), function (index, element) {
        if (element.id === self.iASId) {
          iASElement = element.name;
        }
      });
      delete sortedCollObj[iASElement];
      function populateHash(model) {
        const key = this;
        const hash = model.get("action") === "include" ? codeNameHashInc : codeNameHashExc;
        if (!hash[key]) {
          hash[key] = [];
        }
        const tmpObj = model.toJSON();
        const userCPM = tmpObj.user_cpm;
        const convertedCurrency = currencyCode;
        hash[key].push({
          size: tmpObj.uniques ? formatNumber(parseInt(tmpObj.uniques, 10)) : "--",
          asCurrency: currencyFormatterGetter(convertedCurrency),
          cpm: userCPM === undefined ? tmpObj.retail_cpm : userCPM || "--",
          full_path: tmpObj.full_path || tmpObj.full_retired_path ? tmpObj.rel !== "retired_audience_segment" ? tmpObj.full_path.replace(/ - /g, " &raquo ") : tmpObj.full_retired_path.replace(/ - /g, " &raquo ") : spaceEntity,
          showRetiredWarningIcon: tmpObj.rel === "retired_audience_segment",
          identifier: tmpObj.group_identifier,
          operator: tmpObj.operator
        });
      }
      Object.entries(sortedCollObj).forEach(function ([key, modelArr]) {
        modelArr.forEach(populateHash, key);
      });
      tempData = hasGroupPermission ? createGroupArray(codeNameHashInc) : createDataBlock(codeNameHashInc);
      if (tempData.length) {
        hasIncludeData = true;
        resultJSON.dataMain.push({
          sideTitle: `included audiences<br/>${getAndOrOperation()}`,
          data: tempData
        });
      }
      tempData = createDataBlock(codeNameHashExc);
      if (tempData.length) {
        hasExcludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "excluded audiences",
          data: tempData
        });
      }
      this.hasData = resultJSON.hasData = hasIncludeData || hasExcludeData;
      if (resultJSON.hasData) {
        resultJSON.firstSideTitle = resultJSON.dataMain[0].sideTitle;
        resultJSON.dataMain[0].sideTitle = spaceEntity;
        resultJSON.dataMain[0].isFirstSideTitle = true;
        resultJSON.dataMain[0].data[0].firstItem = true;
      }
      resultJSON.headerTooltipRetiredSegmentsWarningText = Utils.headerTooltipRetiredSegmentsWarningText;
      resultJSON.headerWarningUrl = Utils.perfDataWarningKbUrl;
      resultJSON.hasGroupPermission = hasGroupPermission;
      return resultJSON;
    }
  });
  return readOnlyView;
});
