define('modules/shared/views/pagination',["jQuery", "Underscore", "T1View", "text!modules/shared/templates/pagination.html", "utils/AdminUtils"], function ($, _, T1View, template, AdminUtils) {
  "use strict";

  function getEntityId(str) {
    var int = parseInt(str, 10);
    return isNaN(int) ? str : int;
  }
  return T1View.extend({
    template: template,
    events: {
      "click .page-action": "fetchMore",
      "changed .page-size": "changedSize",
      "click .noop": "preventDefault"
    },
    initialize: function ({
      collection: collection,
      parentId: parentId,
      onSuccess: onSuccess,
      preFetchMore: preFetchMore,
      indent: indent,
      extendedMode: extendedMode,
      pageDisplayMax: pageDisplayMax
    }) {
      const defaultDisplay = 10;
      this.collection = collection;
      this.parentId = parentId || this.collection.parent;
      this.onSuccess = onSuccess || $.noop;
      this.preFetchMore = preFetchMore || $.noop;
      this.indent = indent || false;
      this.extendedMode = extendedMode || false;
      this.pageDisplayMax = pageDisplayMax || defaultDisplay;
    },
    load: function () {
      this.el.closest(".pagination").show();
      this.render();
    },
    changedSize: function (evt) {
      var coll = this.collection;
      var currPageLimit = coll.pageLimit;
      var btn = evt.currentTarget;
      var newPageLimit = parseInt(btn.value, 10) || currPageLimit;
      if (newPageLimit === currPageLimit) {
        this.preventDefault(evt);
      } else {
        coll.pageLimit = newPageLimit;
        this.fetchMore(evt);
      }
    },
    fetchMore: function (evt) {
      var self = this;
      var coll = this.collection;
      var parent = coll.parent || this.parentId;
      var btn = $(evt.currentTarget);
      var page = btn.data("page") || 0;
      var item = parent === "" || parent === undefined ? btn.closest(".item-wrapper").children(".item") : $(btn.parents().find(`[data-uid="${parent}"]`)[0]);
      var spinner = btn.next(".more-spinner");
      var entityId = getEntityId(item.data("id"));
      var entityType = item.attr("data-type");
      var rowType = item.attr("data-code");
      var container = parent === "" || parent === undefined ? btn.closest(".item-wrapper").children(".children") : $(btn.parents().find(`.children-${parent}`));
      var prefetchValues;
      this.preventDefault(evt);
      if (this.extendedMode) {
        coll.page = page - 1;
        coll.reset();
      } else {
        btn.hide();
        spinner.removeClass("hidden");
        spinner[0].start();
        if (coll.length > 0 && coll.length < coll.count) {
          coll.page = Math.ceil(coll.length / coll.pageLimit) - 1;
        }
      }
      prefetchValues = this.preFetchMore({
        item: item,
        collection: coll,
        container: container
      });
      if (prefetchValues) {
        coll = prefetchValues.collection || coll;
      }
      coll.fetchMore({
        success: function () {
          self.onSuccess({
            collection: coll,
            container: container,
            entityId: entityId,
            entityType: entityType,
            rowType: rowType
          });
        }
      });
    },
    serialize: function () {
      var coll = this.collection;
      var models = coll && coll.toJSON() || [];
      var len = models && models.length || 0;
      var page = coll && coll.page || 0;
      var pageSize = coll && coll.pageLimit || 0;
      var count = coll && coll.count || 0;
      var pageCount = 0;
      var pages = [];
      var maxPage = 0;
      var displayQuota = this.pageDisplayMax;
      const multiplier = 2;
      var iterations = displayQuota * multiplier;
      var displayRange = 1;
      var output = {
        len: len,
        count: count,
        entityType: coll && AdminUtils.pluralize(coll.entityType || "", len),
        indent: this.indent,
        parent: this.parentId
      };
      var ellipsis = {
        before: {
          ellipsis: true,
          placement: "before"
        },
        after: {
          ellipsis: true,
          placement: "after"
        }
      };
      if (this.extendedMode && count > pageSize) {
        pageCount = parseInt(count / pageSize, 10) + 1;
        while (pageCount--) {
          pages.push({
            page: pageCount,
            displayPage: pageCount + 1,
            selected: pageCount === page,
            placement: pageCount < page ? "before" : pageCount > page ? "after" : ""
          });
        }
        maxPage = pages.length;
        pages = pages.reverse();
        if (maxPage > this.pageDisplayMax) {
          const subtractor = 3;
          pages[0].display = true;
          pages[page].display = true;
          pages[pages.length - 1].display = true;
          displayQuota = displayQuota - subtractor;
          while (iterations > 0) {
            if (pages[page - displayRange] && !pages[page - displayRange].display) {
              pages[page - displayRange].display = true;
              displayQuota--;
            }
            if (pages[page + displayRange] && !pages[page + displayRange].display) {
              pages[page + displayRange].display = true;
              displayQuota--;
            }
            displayRange++;
            iterations--;
            if (displayQuota <= 0) {
              iterations = 0;
            }
          }
          $.each(pages, function (i, thisPage) {
            if (thisPage.display !== true) {
              pages[i] = ellipsis[thisPage.placement];
            }
          });
          pages = _.uniq(pages);
        }
        const pageTotal = pageSize * page;
        output.thisPage = page;
        output.pages = pages;
        output.start = pageTotal + 1;
        output.end = Math.min(pageTotal + coll.pageLimit, count);
        output.hasPages = true;
        output.prev = page - 1 >= 0 ? page - 1 : false;
        output.hasPrev = output.prev !== false;
        output.next = page + 1 < maxPage ? page + 1 : false;
        output.pageSize = pageSize;
      } else {
        output.start = 1;
        output.end = len;
        output.remainder = output.count - output.len || 0;
      }
      return output;
    }
  });
});
