define('modules/campaign/chart/winLoss/shared/canvasTrapezoid',[], function () {
  "use strict";

  const DURATION = 80;
  const ALPHA_INDEX = 3;
  var Trapezoid = function ({
    context: ctx,
    p1: p1,
    p2: p2,
    p3: p3,
    p4: p4,
    color: color
  }) {
    const getTime = () => new Date().getTime();
    return {
      animateHeight: function () {
        const start = getTime();
        const end = start + DURATION;
        const distance = p3.y - p2.y;
        const promise = new Promise(function (resolve) {
          const step = function () {
            const timer = getTime();
            const progress = Math.min((DURATION - (end - timer)) / DURATION, 1);
            const animHeight = distance * progress;
            ctx.beginPath();
            ctx.moveTo(p1.x, p1.y);
            ctx.lineTo(p2.x, p2.y);
            ctx.lineTo(p3.x, p1.y + animHeight);
            ctx.lineTo(p4.x, p2.y + animHeight);
            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();
            if (progress < 1) {
              window.requestAnimationFrame(step);
            } else {
              resolve();
            }
          };
          step();
        });
        return promise;
      },
      draw: function (animateProp) {
        switch (animateProp) {
          case "animateHeight":
            return this.animateHeight();
          default:
            ctx.beginPath();
            ctx.moveTo(p1.x, p1.y);
            ctx.lineTo(p2.x, p2.y);
            ctx.lineTo(p3.x, p3.y);
            ctx.lineTo(p4.x, p4.y);
            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();
            return Promise.resolve();
        }
      },
      getBounds: function () {
        return {
          p1: p1,
          p2: p2,
          p3: p3,
          p4: p4
        };
      },
      contains: function (x, y) {
        if (p1.x < x && p2.x > x && p1.y < y && p3.y > y) {
          const pixel = ctx.getImageData(x, y, 1, 1);
          if (pixel.data[ALPHA_INDEX] > 0) {
            return true;
          }
        } else {
          return false;
        }
      }
    };
  };
  return Trapezoid;
});
