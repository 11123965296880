define('modules/reporting/campaigns/views/no_data_grid',["jQuery", "T1", "T1View", "text!../templates/no-data-grid-item.html"], function ($, T1, T1View, itemTemplate) {
  "use strict";

  const MAX_COLUMNS = 11;
  const MAX_ROWS = 4;
  const THIRD_ROW = 2;
  return T1View.extend({
    template: itemTemplate,
    initialize: function (args) {
      this.msg = args.msg;
    },
    serialize: function () {
      var items = [];
      var keys = [];
      var i;
      for (i = 0; i < MAX_COLUMNS; i++) {
        keys.push({});
      }
      items.push({
        firstRow: true,
        keys: keys
      });
      for (i = 0; i < MAX_ROWS; i++) {
        items.push({
          blank: "blank",
          msg: ""
        });
      }
      items[THIRD_ROW].msg = this.msg;
      items[THIRD_ROW].blank = "";
      return {
        items: items
      };
    }
  });
});
