define('modules/targeting/dayPart/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "targeting/dayPart",
    viewCfgs: {
      list: {},
      item: {},
      readOnly: {
        collection: null
      }
    },
    defaultView: "list"
  });
});
