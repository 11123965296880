define('models/countryToRegionMapper',["jQuery", "T1", "Backbone", "T1Model"], function ($, T1, Backbone, T1Model) {
  T1.Models.CountryToRegionMapper = T1Model.extend({
    countryToRegionMapping: {
      kazakhstan: "mm apac",
      kyrgyzstan: "mm apac",
      tajikistan: "mm apac",
      turkmenistan: "mm apac",
      uzbekistan: "mm apac",
      china: "mm apac",
      "hong kong": "mm apac",
      macao: "mm apac",
      "korea, democratic peoples republic of": "mm apac",
      japan: "mm apac",
      mongolia: "mm apac",
      "korea, republic of": "mm apac",
      afghanistan: "mm apac",
      bangladesh: "mm apac",
      bhutan: "mm apac",
      india: "mm apac",
      "iran, islamic republic of": "mm apac",
      maldives: "mm apac",
      nepal: "mm apac",
      pakistan: "mm apac",
      "sri lanka": "mm apac",
      "brunei darussalam": "mm apac",
      cambodia: "mm apac",
      indonesia: "mm apac",
      "lao peoples democratic republic": "mm apac",
      malaysia: "mm apac",
      myanmar: "mm apac",
      philippines: "mm apac",
      singapore: "mm apac",
      thailand: "mm apac",
      "timor-leste": "mm apac",
      "viet nam": "mm apac",
      australia: "mm apac",
      "new zealand": "mm apac",
      "norfolk island": "mm apac",
      fiji: "mm apac",
      "new caledonia": "mm apac",
      "papua new guinea": "mm apac",
      "solomon islands": "mm apac",
      vanuatu: "mm apac",
      guam: "mm apac",
      kiribati: "mm apac",
      "marshall islands": "mm apac",
      "micronesia (federated states of)": "mm apac",
      nauru: "mm apac",
      "northern mariana islands": "mm apac",
      palau: "mm apac",
      "american samoa": "mm apac",
      "cook islands": "mm apac",
      "french polynesia": "mm apac",
      niue: "mm apac",
      pitcairn: "mm apac",
      samoa: "mm apac",
      tokelau: "mm apac",
      tonga: "mm apac",
      tuvalu: "mm apac",
      "wallis and futuna islands": "mm apac",
      brazil: "mm brazil",
      burundi: "mm emea",
      comoros: "mm emea",
      djibouti: "mm emea",
      eritrea: "mm emea",
      ethiopia: "mm emea",
      kenya: "mm emea",
      madagascar: "mm emea",
      malawi: "mm emea",
      mauritius: "mm emea",
      mayotte: "mm emea",
      mozambique: "mm emea",
      "réunion": "mm emea",
      rwanda: "mm emea",
      seychelles: "mm emea",
      somalia: "mm emea",
      "south sudan": "mm emea",
      uganda: "mm emea",
      "tanzania, united republic of": "mm emea",
      zambia: "mm emea",
      zimbabwe: "mm emea",
      angola: "mm emea",
      cameroon: "mm emea",
      "central african republic": "mm emea",
      chad: "mm emea",
      congo: "mm emea",
      "democratic republic of the congo": "mm emea",
      "equatorial guinea": "mm emea",
      gabon: "mm emea",
      "sao tome and principe": "mm emea",
      algeria: "mm emea",
      egypt: "mm emea",
      libya: "mm emea",
      morocco: "mm emea",
      sudan: "mm emea",
      tunisia: "mm emea",
      "western sahara": "mm emea",
      botswana: "mm emea",
      lesotho: "mm emea",
      namibia: "mm emea",
      "south africa": "mm emea",
      swaziland: "mm emea",
      benin: "mm emea",
      "burkina faso": "mm emea",
      "cabo verde": "mm emea",
      "cote divoire": "mm emea",
      gambia: "mm emea",
      ghana: "mm emea",
      guinea: "mm emea",
      "guinea-bissau": "mm emea",
      liberia: "mm emea",
      mali: "mm emea",
      mauritania: "mm emea",
      niger: "mm emea",
      nigeria: "mm emea",
      "saint helena": "mm emea",
      senegal: "mm emea",
      "sierra leone": "mm emea",
      togo: "mm emea",
      armenia: "mm emea",
      azerbaijan: "mm emea",
      bahrain: "mm emea",
      cyprus: "mm emea",
      georgia: "mm emea",
      iraq: "mm emea",
      israel: "mm emea",
      jordan: "mm emea",
      kuwait: "mm emea",
      lebanon: "mm emea",
      oman: "mm emea",
      qatar: "mm emea",
      "saudi arabia": "mm emea",
      "state of palestine": "mm emea",
      "syrian arab republic": "mm emea",
      turkey: "mm emea",
      "united arab emirates": "mm emea",
      yemen: "mm emea",
      belarus: "mm emea",
      bulgaria: "mm emea",
      czechia: "mm emea",
      hungary: "mm emea",
      poland: "mm emea",
      "republic of moldova": "mm emea",
      romania: "mm emea",
      "russian federation": "mm emea",
      slovakia: "mm emea",
      ukraine: "mm emea",
      "åland islands": "mm emea",
      "channel islands": "mm emea",
      denmark: "mm emea",
      estonia: "mm emea",
      "faeroe islands": "mm emea",
      finland: "mm emea",
      guernsey: "mm emea",
      iceland: "mm emea",
      ireland: "mm emea",
      "isle of man": "mm emea",
      jersey: "mm emea",
      latvia: "mm emea",
      lithuania: "mm emea",
      norway: "mm emea",
      sark: "mm emea",
      "svalbard and jan mayen islands": "mm emea",
      sweden: "mm emea",
      "united kingdom": "mm emea",
      albania: "mm emea",
      andorra: "mm emea",
      "bosnia and herzegovina": "mm emea",
      croatia: "mm emea",
      gibraltar: "mm emea",
      greece: "mm emea",
      "holy see": "mm emea",
      italy: "mm emea",
      malta: "mm emea",
      montenegro: "mm emea",
      portugal: "mm emea",
      "san marino": "mm emea",
      serbia: "mm emea",
      slovenia: "mm emea",
      spain: "mm emea",
      "macedonia, the former yugoslav republic of": "mm emea",
      austria: "mm emea",
      belgium: "mm emea",
      france: "mm emea",
      germany: "mm emea",
      liechtenstein: "mm emea",
      luxembourg: "mm emea",
      monaco: "mm emea",
      netherlands: "mm emea",
      switzerland: "mm emea",
      anguilla: "mm us/latam",
      "antigua and barbuda": "mm us/latam",
      aruba: "mm us/latam",
      bahamas: "mm us/latam",
      barbados: "mm us/latam",
      "bonaire, sint eustatius and saba": "mm us/latam",
      "british virgin islands": "mm us/latam",
      "cayman islands": "mm us/latam",
      cuba: "mm us/latam",
      "curaçao": "mm us/latam",
      dominica: "mm us/latam",
      "dominican republic": "mm us/latam",
      grenada: "mm us/latam",
      guadeloupe: "mm us/latam",
      haiti: "mm us/latam",
      jamaica: "mm us/latam",
      martinique: "mm us/latam",
      montserrat: "mm us/latam",
      "puerto rico": "mm us/latam",
      "saint-barthélemy": "mm us/latam",
      "saint kitts and nevis": "mm us/latam",
      "saint lucia": "mm us/latam",
      "saint martin (french part)": "mm us/latam",
      "saint vincent and the grenadines": "mm us/latam",
      "sint maarten (dutch part)": "mm us/latam",
      "trinidad and tobago": "mm us/latam",
      "turks and caicos islands": "mm us/latam",
      "united states virgin islands": "mm us/latam",
      belize: "mm us/latam",
      "costa rica": "mm us/latam",
      "el salvador": "mm us/latam",
      guatemala: "mm us/latam",
      honduras: "mm us/latam",
      mexico: "mm us/latam",
      nicaragua: "mm us/latam",
      panama: "mm us/latam",
      argentina: "mm us/latam",
      "bolivia (plurinational state of)": "mm us/latam",
      chile: "mm us/latam",
      colombia: "mm us/latam",
      ecuador: "mm us/latam",
      "falkland islands (malvinas)": "mm us/latam",
      "french guiana": "mm us/latam",
      guyana: "mm us/latam",
      paraguay: "mm us/latam",
      peru: "mm us/latam",
      suriname: "mm us/latam",
      uruguay: "mm us/latam",
      "venezuela (bolivarian republic of)": "mm us/latam",
      bermuda: "mm us/latam",
      canada: "mm us/latam",
      greenland: "mm us/latam",
      "saint pierre and miquelon": "mm us/latam",
      "united states": "mm us/latam"
    },
    regionToIDMapping: {
      "mm apac": "99846327",
      "mm emea": "75449448",
      "mm us/latam": "41519752",
      "mm brazil": "86589265"
    },
    getExchangeSeatID: function (countryName) {
      var region;
      var exchangeSeatID;
      var defaultExchangeSeatId = "41519752";
      countryName = countryName.replace("'", "");
      countryName = countryName.toLowerCase();
      region = this.countryToRegionMapping[countryName];
      if (!region) {
        return defaultExchangeSeatId;
      }
      exchangeSeatID = this.regionToIDMapping[region];
      if (!exchangeSeatID) {
        return defaultExchangeSeatId;
      } else {
        return exchangeSeatID;
      }
    }
  });
  return T1.Models.CountryToRegionMapper;
});
