define('collections/segments',["jQuery", "T1", "T1Comm", "T1Collection", "models/segment", "collections/organizations"], function ($, T1, T1Comm, T1Collection, Segment, Organizations) {
  "use strict";

  T1.Models.Segments = T1Collection.extend({
    model: Segment,
    apiRoot: T1.SEGMENT_API_BASE,
    urlString: "segments",
    pageOffset: 0,
    searchConfiguration: {
      fields: {}
    },
    initialize: function (models, options) {
      var self = this;
      Organizations.getOrganizations().getSelected().then(function (orgId) {
        self.orgId = orgId;
      });
      T1Collection.prototype.initialize.call(this, models, options);
    },
    fetch: function (options) {
      var self = this;
      var sourceUrl = T1.SEGMENT_API_BASE + "segments";
      var data, searchObj;
      if (self.pageLimit === null) {
        self.pageLimit = 10;
      }
      data = {
        page_limit: self.pageLimit,
        page_offset: self.pageOffset,
        sort_by: self.sortBy,
        full: "*"
      };
      if (self.with) {
        data.with = self.with + ",owner";
      } else {
        data.with = "owner";
      }
      if (this.search) {
        searchObj = this.search.getCriteria();
        if (searchObj.hasOwnProperty("q")) {
          data = $.extend(data, searchObj);
          if (searchObj.q.indexOf("name=:") !== -1) {
            data.order_by = "ascending";
            data.sort_by = "name";
          }
        }
      }
      if (self.ownerType && self.entityId) {
        if (self.ownerType === "segmentEntityTypeId") {
          sourceUrl = sourceUrl + "/" + self.entityId;
        } else if (self.ownerType === "agencies") {
          sourceUrl = sourceUrl + "/limit/agency=" + self.entityId;
        } else if (self.ownerType === "behaviors.attribute") {
          sourceUrl = sourceUrl + "/limit/behaviors.attribute=" + self.entityId;
        } else {
          data.q = "owner_type==advertiser&owner_id==" + self.entityId + "&segment_type==" + self.segmentType;
        }
      } else {
        sourceUrl = sourceUrl + "/limit/organization=" + self.orgId;
      }
      return T1Comm.get({
        dataType: "xml",
        data: data,
        processAjaxResponse: self.processAjaxResponse,
        onSuccess: options.onSuccess,
        sourceURL: sourceUrl
      });
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.Segments;
});
