define('modules/shared/views/list',["jQuery", "Underscore", "T1", "T1View", "text!../templates/list.html", "text!../templates/listItem.html", "T1Form"], function ($, _, T1, T1View, template, itemTemplate) {
  var listView = T1View.extend({
    template: template,
    partials: {
      item: itemTemplate
    },
    initialize: function (args) {
      var i;
      var collection = this.collection;
      var model;
      this.title = args.title || "Title";
      this.addLabel = args.addLabel || "+Add Item";
      this.fieldName = args.fieldName || "name";
      this.placeHolder = args.placeHolder || "";
      this.maxLength = args.maxLength;
      this.initialFieldCount = args.initialFieldCount;
      if (this.initialFieldCount && collection && collection.length === 0) {
        for (i = 0; i < this.initialFieldCount; i++) {
          model = new collection.model({
            action: "new"
          });
          collection.add(model);
        }
      }
    },
    events: {
      "click .add-item span": "addItem",
      "click .remove": "removeItem",
      "changed strand-input": "changeName"
    },
    formChangeHandler: function (item) {
      T1.EventHub.publish("listChanged", true);
    },
    getVisibleItems: function () {
      return _.filter(this.collection.models, function (item) {
        return item && item.get("action") !== "delete";
      });
    },
    addItem: function (event) {
      var collection = this.collection;
      var model;
      var serializeData;
      var itemToAdd;
      $(event.currentTarget).closest(".add-item").addClass("hidden");
      if (this.maxLength && this.getVisibleItems().length >= this.maxLength) {
        return;
      }
      model = new collection.model({
        action: "new"
      });
      collection.add(model);
      serializeData = this.serialize();
      itemToAdd = serializeData.list[this.getVisibleItems().indexOf(model)];
      model.set({
        id: itemToAdd.id
      });
      this.renderPartialTemplate({
        template: itemTemplate,
        data: itemToAdd,
        action: "append",
        targetEl: this.el.find(".list"),
        useTemplateHtml: false,
        skipDelegateEvents: true
      });
      this.formChangeHandler(model);
    },
    removeItem: function (event) {
      var collection = this.collection;
      var target = event.currentTarget;
      var $item = $(target).closest(".list-item-row");
      var $list = $item.closest(".list");
      var id = $item.attr("data-id");
      var item = collection.get(id);
      var $items;
      $item.remove();
      if (item && $.isNumeric(item.id)) {
        item.set({
          action: "delete"
        });
      } else {
        item = collection.getByCid(id);
        if (item.id && $.isNumeric(item.id)) {
          item.set({
            action: "delete"
          });
        } else {
          collection.remove(item);
        }
      }
      $items = $list.find(".list-item-row");
      if ($items.length) {
        $items.last().find(".add-item").removeClass("hidden");
      } else {
        this.load();
      }
      this.formChangeHandler(item);
    },
    changeName: function (event) {
      var $current = $(event.currentTarget);
      var id = $current.attr("data-id");
      var collection = this.collection;
      var item = collection.get(id) || collection.getByCid(id);
      var field = {};
      if ($current.val().trim() === "") {
        return;
      }
      field[this.fieldName] = T1.Form.getUnmaskedVal($current);
      if (item.get("action") !== "new") {
        if (field[this.fieldName] === "") {
          item.set({
            action: "delete"
          });
        } else {
          item.unset("action");
        }
      }
      item.set(field);
      this.formChangeHandler(item);
    },
    mapItem: function (model) {
      var item = {
        id: model.id || model.cid,
        name: model ? model.get(this.fieldName) : undefined,
        fieldName: this.fieldName,
        addLabel: this.addLabel,
        placeHolder: this.placeHolder
      };
      if (model.get("action") !== "delete") {
        return item;
      }
    },
    canUnload: function (onTabChange) {
      var self = this;
      var isFormElementsChanged = self.isFormElementsChanged;
      self.isFormElementsChanged = false;
      return !isFormElementsChanged;
    },
    load: function () {
      var self = this;
      this.isFormElementsChanged = false;
      this.render().then(function listViewPostRender() {
        var $el = $(self.el);
        T1.Form.Masks.attach($("input", $el));
      });
    },
    serialize: function () {
      var collection = this.collection;
      var list = [];
      var self = this;
      var visibleItems = this.getVisibleItems();
      var length = visibleItems.length;
      var pushed = false;
      var mappedModel;
      $.each(visibleItems, function (index, model) {
        if (model.get("action") !== "delete") {
          mappedModel = self.mapItem.call(self, model);
          if (index === length - 1) {
            if (self.maxLength) {
              mappedModel.last = length < self.maxLength;
            } else {
              mappedModel.last = true;
            }
          }
          list.push(mappedModel);
          pushed = true;
        }
      });
      if (pushed === false) {
        var modelNew = new collection.model({
          action: "new"
        });
        collection.add(modelNew);
        mappedModel = self.mapItem(modelNew);
        modelNew.set({
          id: mappedModel.id
        });
        mappedModel.last = true;
        list.push(mappedModel);
      }
      return {
        title: this.title,
        list: list
      };
    }
  });
  return listView;
});
