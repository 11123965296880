define('collections/campaigns',['require','Underscore','jQuery','models/campaign','modules/organization/utils/Utils','collections/performanceDataForCollections','T1Sortable','T1','T1Collection','models/userPreferences','T1Preferences','T1Signature'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Campaign = require("models/campaign");
  const OrgUtils = require("modules/organization/utils/Utils");
  const PerformanceData = require("collections/performanceDataForCollections");
  const Sortable = require("T1Sortable");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  const UserPreferences = require("models/userPreferences");
  const T1Preferences = require("T1Preferences");
  const T1Signature = require("T1Signature");
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      },
      start_date: {
        actual: "start_date",
        display: "start date"
      },
      end_date: {
        actual: "end_date",
        display: "end date"
      },
      created_on: {
        actual: "created_on",
        display: "date created"
      },
      updated_on: {
        actual: "updated_on",
        display: "last updated"
      },
      total_budget: {
        actual: "total_budget",
        display: "budget"
      }
    },
    default: {
      sort_by: "updated_on",
      order_by: "descending"
    }
  };
  function clearCurrentFetch(self) {
    if (self.currentFetch) {
      self.currentFetch.abort();
      delete self.currentFetch;
    }
  }
  function clearSearch(self) {
    self.search.clear();
    self.each(function (campaign) {
      campaign.getRelated("strategies").search.clear();
    });
  }
  function applyFavorite(campaign) {
    campaign.favorite = this.isFavorite(campaign.id);
  }
  const collection = T1Collection.extend({
    model: Campaign,
    name: "campaigns",
    canCache: false,
    currentSearchConfig: [],
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }, {
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: false
      }]
    },
    searchConfiguration: {
      isFetchSilent: false,
      fields: {
        name: {
          type: "string"
        },
        status: {
          type: "exact"
        }
      }
    },
    chartableCampaignId: null,
    urlString: "campaigns",
    favorites: null,
    statusFilter: "active",
    isExpiredHidden: false,
    report_interval: "CTD",
    selectedStrategyId: null,
    postEntityParse: {
      rpt_goal_monitoring: T1.Utils.objectTOArray,
      rpt_legacy_goal_monitoring: T1.Utils.objectTOArray
    },
    getExpiredFilterParam() {
      return `filter_expired=${this.isExpiredHidden ? 1 : 0}`;
    },
    clearFilters() {
      this.urlFilter.clear("agency");
      this.urlFilter.clear("advertiser");
    },
    setOrganization(orgId, fetchRequired, fetchFavorites) {
      var signature = T1.Signature.create();
      var id = orgId || "000000";
      this.favorites = "";
      this.urlFilter.set({
        entity: "organization",
        id: id
      });
      signature.set(new Map([["root", "ui"], ["model", "organization"], ["id", orgId]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["favorites"]);
      if (fetchRequired) {
        if (fetchFavorites) {
          this.fetchFavorites();
        } else {
          this.fetch({
            data: this.getExpiredFilterParam()
          });
        }
      }
    },
    setSearchOptions(optionalData) {
      const searchOptions = {};
      if (this.isStatusFilterOn()) {
        searchOptions.params = [{
          searchField: "status",
          term: this.statusFilter === "active" ? "1" : "0"
        }];
      }
      if (optionalData !== null && typeof optionalData === "object") {
        Object.keys(optionalData).forEach(key => {
          searchOptions[key] = optionalData[key];
        });
      }
      if (!$.isEmptyObject(searchOptions)) {
        this.search.set(searchOptions);
      }
    },
    isStatusFilterOn() {
      return this.statusFilter.includes("active");
    },
    hasFavorites() {
      return Boolean(this.favorites);
    },
    fetchFavorites(opts) {
      if (!this.hasFavorites()) {
        T1.EventHub.publish("campaign:clearChart");
        this.count = 0;
        this.unregister();
        this.reset();
        if (opts) {
          opts.success();
        }
        this.loaded = true;
        return false;
      }
      this.clearFilters();
      this.setSearchOptions({
        idList: this.favorites,
        fetchRequired: false,
        paramsAndIDs: this.isStatusFilterOn()
      });
      this.fetch({
        data: this.getExpiredFilterParam(),
        success: () => {
          this.loaded = true;
          if (opts && opts.success) {
            opts.success();
          }
        }
      });
    },
    setFavorite(id, success) {
      var favorites = this.favorites || "";
      if (!id) {
        throw new Error("Id must be provided to set favorite");
      }
      const campaign = this.get(id);
      favorites = favorites === "" ? [] : favorites.split(",");
      id = id.toString();
      const doesExist = _.indexOf(favorites, id);
      if (doesExist > -1) {
        favorites = _.without(favorites, id);
      } else {
        favorites.push(id);
      }
      const strFavorites = favorites.join(",");
      this.favorites = strFavorites;
      campaign.favorite = this.isFavorite(campaign.id);
      this.preferences.save({
        success: success
      });
    },
    getPerformanceDataObj() {
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      const requestMetrics = ["clicks", "ctr", "impressions", "post_click_conversions", "revenue", "total_conversions", "total_revenue", "total_spend", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend_roi", "total_spend_vcpm", "viewability_rate"];
      if (hasVcrImpression) {
        requestMetrics.push("video_complete_rate_impression_based");
      } else {
        requestMetrics.push("video_complete_rate");
      }
      this.performanceData = new PerformanceData({
        hostCollection: this,
        metrics: requestMetrics
      });
      return this.performanceData;
    },
    fetch(opts) {
      this.getPerformanceDataObj().hostCollectionFetchHandler(opts);
    },
    isFavorite(id) {
      var favorites = this.favorites || "";
      if (!id) {
        throw new Error("Id must be provided to check favorite");
      }
      favorites = favorites === "" ? [] : favorites.split(",");
      return _.indexOf(favorites, id) > -1;
    },
    initialize() {
      T1Collection.prototype.initialize.call(this);
      this.initializeListPreferences(["isExpiredHidden", "statusFilter"]);
      this.initializeFilterState();
      this.initializeSubscriptions();
      this.bind("reset", () => {
        this.each(applyFavorite.bind(this));
      });
      this.bind("add", applyFavorite.bind(this));
    },
    initializeListPreferences(marks) {
      const listSignature = T1.Signature.create();
      listSignature.set(new Map([["root", "ui"], ["module", "campaign"], ["view", "list"]]));
      this.listPreferences = T1.Preferences.create.call(this, listSignature, UserPreferences);
      if (Array.isArray(marks) && marks.length) {
        this.listPreferences.mark(marks);
      }
    },
    initializeFilterState() {
      if (this.listPreferences.get("isExpiredHidden")) {
        this.isExpiredHidden = this.listPreferences.get("isExpiredHidden") === "true";
      } else {
        this.isExpiredHidden = true;
        this.listPreferences.save();
      }
      this.statusFilter = this.listPreferences.get("statusFilter") || this.statusFilter;
    },
    initializeSubscriptions() {
      T1.EventHub.subscribe({
        "campaigns:filter": this.applyFilter.bind(this),
        "campaigns:clearFilter": () => {
          this.each(campaign => {
            const strategies = campaign.get("strategies");
            if (strategies) {
              strategies.search.clear();
              strategies.urlFilter.clear("campaign");
              this.selectedStrategyId = null;
            }
          });
          this.search.clear();
          this.clearFilters();
          this.setSearchOptions();
          this.fetch({
            data: this.getExpiredFilterParam()
          });
        },
        "campaigns:toggleExpired": opts => {
          this.isExpiredHidden = opts.isChecked;
          this.onListFilterChange(opts);
        },
        "campaigns:filterStateChange": opts => {
          this.statusFilter = opts.status;
          this.onListFilterChange(opts);
        }
      });
    },
    onListFilterChange(opts) {
      this.listPreferences.save();
      if (this.listPreferences.get("favoriteViewMode") === "favorites") {
        this.fetchFavorites();
      } else {
        this.applyFilter(opts.search || {
          type: "listFilterChanged"
        });
      }
    },
    applyFilter(data) {
      const deferred = $.Deferred();
      const fetchOptions = {
        success: successData => deferred.resolve(successData),
        data: this.getExpiredFilterParam()
      };
      const types = {
        campaign: args => {
          const searchOptions = {
            idList: [args.id],
            fetchRequired: false,
            paramsAndIDs: this.isStatusFilterOn()
          };
          this.clearFilters();
          this.setSearchOptions(searchOptions);
          this.fetch(fetchOptions);
        },
        agency: args => {
          this.urlFilter.set({
            entity: "agency",
            id: args.id,
            fetchRequired: false
          });
          this.setSearchOptions();
          clearCurrentFetch(this);
          this.fetch(fetchOptions);
        },
        advertiser: args => {
          this.urlFilter.set({
            entity: "advertiser",
            id: args.id,
            fetchRequired: false
          });
          this.setSearchOptions();
          clearCurrentFetch(this);
          this.fetch(fetchOptions);
        },
        strategy: args => {
          const searchOptions = {
            idList: [args.categoryId],
            fetchRequired: false,
            paramsAndIDs: this.isStatusFilterOn()
          };
          this.clearFilters();
          this.setSearchOptions(searchOptions);
          this.fetch({
            success: successData => {
              this.each(campaign => {
                const strategies = campaign.getRelated("strategies");
                this.selectedStrategyId = args.id;
                campaign.prepareStrategiesConfig(strategies, false);
                strategies.search.set({
                  idList: [this.selectedStrategyId],
                  fetchRequired: false
                });
                strategies.fetch({
                  success: function () {
                    campaign.trigger("strategies:filter");
                  }
                });
              });
              deferred.resolve(successData);
            }
          });
        },
        listFilterChanged: () => {
          this.clearFilters();
          this.setSearchOptions();
          this.fetch(fetchOptions);
        }
      };
      if (types[data.type]) {
        clearSearch(this);
        types[data.type](data);
      } else {
        throw new Error(`Campaigns filter is not valid for entity ${data.type}`);
      }
      return deferred.promise();
    },
    applySearchFilter(field, term) {
      if (field !== undefined && term !== undefined) {
        let fieldIndex = -1;
        const searchType = {
          term: term,
          searchField: field
        };
        $.each(this.currentSearchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
          }
        });
        if (fieldIndex === -1) {
          if (term !== "") {
            this.currentSearchConfig.push(searchType);
          }
        } else {
          if (term === "") {
            this.currentSearchConfig.splice(fieldIndex, 1);
          } else {
            this.currentSearchConfig[fieldIndex] = searchType;
          }
        }
      }
    },
    filterBy(field, term, success) {
      var self = this;
      this.applySearchFilter(field, term);
      this.search.set({
        params: this.currentSearchConfig,
        fetchRequired: true,
        success: function (coll) {
          var onSuccess = success || $.noop;
          self.trigger("reset", this);
          onSuccess(coll);
        }
      });
    },
    fetchMore(args) {
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          silent: args.silent || false,
          add: true,
          success: args.success || $.noop,
          fromFetchAll: true,
          data: args.data || ""
        });
      }
    }
  });
  T1.Models.Campaigns = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.Campaigns;
});
