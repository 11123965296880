define('modules/strategy/supply/pmpe/views/activeState',["jQuery", "T1", "T1View", "text!../templates/active_state.html"], function ($, T1, T1View, tmpl) {
  "use strict";

  var ActiveState = T1View.extend({
    template: tmpl,
    events: {
      "change .activeState": "changeActiveState"
    },
    changeActiveState: function (e) {
      const isDealGroupGrid = $(e.currentTarget).closest(".deal-groups-select-status").length;
      this.state = e.currentTarget.value;
      if (isDealGroupGrid) {
        T1.EventHub.publish("pmpe:changeDealGroupActiveState", this.state);
      } else {
        T1.EventHub.publish("pmpe:changeActiveState", this.state);
      }
    },
    load: function () {
      var self = this;
      this.render().then(function renderSelectBox() {
        const $dropdown = self.$(".select-input");
        T1.Selectbox($dropdown);
        $dropdown.trigger("liszt:updated");
      });
    },
    serialize: function () {
      return {
        statusOptions: [{
          text: "Active",
          value: "1"
        }, {
          text: "Inactive",
          value: "0"
        }, {
          text: "All",
          value: "both"
        }],
        defaultStatus: this.state || "1"
      };
    }
  });
  return T1.Form.ViewUtils.extend(ActiveState, ["serialize"]);
});
