define('collections/wurflTargetValues',["collections/nemoTargetValues"], function (NemoTargetValues) {
  "use strict";

  var classProperties = {
    getFetchOptions: function () {
      return {
        depth: 1,
        sort_by: "name"
      };
    },
    getEntities: function (response) {
      var entities, payload;
      if (response && response.data) {
        if (Array.isArray(response.data)) {
          if (response.data[0] && response.data[0].data) {
            payload = response.data[0];
          } else {
            payload = response;
          }
        } else {
          payload = response.data;
        }
        entities = payload.data;
      }
      return entities || [];
    },
    parseName: function (str) {
      var paths = str.split("/");
      return paths[paths.length - 1];
    },
    extractNameAndDimensionCode: function (name, code) {
      const regex = /^WURFL\/Brand\/([^/]+)\/Device\/([^/]+)/;
      const parentRegex = /^WURFL\/Brand\/([^/]+)/;
      const osRegex = /^WURFL\/OS\/([^/]+)\/OS[ ]Version\/([^/]+)/;
      const osParentRegex = /^WURFL\/OS\/([^/]+)/;
      const browserRegex = /^WURFL\/Browser\/([^/]+)\/Browser[ ]Version\/([^/]+)/;
      const browserParentRegex = /^WURFL\/Browser\/([^/]+)/;
      const osRegexName = name.match(osRegex);
      const osParentRegexName = name.match(osParentRegex);
      const regexName = name.match(regex);
      const parentRegexName = name.match(parentRegex);
      const browserParentRegexName = name.match(browserParentRegex);
      const browserRegexName = name.match(browserRegex);
      const idxTwo = 2;
      if (regexName) {
        return {
          extractedName: `${regexName[1]} &raquo; ${regexName[idxTwo]}`,
          dimensionCode: "WURF"
        };
      } else if (parentRegexName) {
        return {
          extractedName: parentRegexName[1],
          dimensionCode: "WURF"
        };
      } else if (osRegexName) {
        return {
          extractedName: `${osRegexName[1]} &raquo; ${osRegexName[idxTwo]}`,
          dimensionCode: "WURFL/OS"
        };
      } else if (osParentRegexName) {
        return {
          extractedName: osParentRegexName[1],
          dimensionCode: "WURFL/OS"
        };
      } else if (browserParentRegexName || browserRegexName) {
        return {
          extractedName: name,
          dimensionCode: "WURFL"
        };
      }
      return {
        extractedName: name,
        dimensionCode: code
      };
    },
    constructExtraEntityData: function (code, name, parentId) {
      const {
        extractedName: extractedName,
        dimensionCode: dimensionCode
      } = WurflTargetValues.extractNameAndDimensionCode(name, code);
      return {
        parent_value_id: parentId,
        dimension_code: dimensionCode,
        name: WurflTargetValues.parseName(name),
        is_targetable: true,
        full_path_name: extractedName,
        full_dim_code_path_name: name
      };
    },
    augmentEntityData: function (entity) {
      this.dimension_code = this.dimension_code || "WURFL/OS";
      return Object.assign({}, entity, WurflTargetValues.constructExtraEntityData(this.dimension_code, entity.name, entity.parent_id));
    },
    replaceSuccessHandlerForFirstCall: function (options, self) {
      var existingSuccessHandler = options.success;
      options.success = function (collection, response) {
        var entities = WurflTargetValues.getEntities(response);
        var id;
        entities.some(function (entity) {
          var isBrand = entity.name === "WURFL/Brand";
          if (isBrand) {
            id = entity.id;
          } else {
            id = entity.parent_id;
          }
          return isBrand;
        });
        if (id) {
          Object.assign(options, {
            success: existingSuccessHandler,
            add: false
          });
          self.fetchOptions.parent = id;
          self.fetch(options);
        } else {
          existingSuccessHandler(collection, response);
        }
      };
    }
  };
  var instanceProperties = {
    urlString: function () {
      return `target/${this.fetchOptions.parent || ""}`;
    },
    fetch: function (options) {
      options = options || {};
      if (!this.isSearch) {
        if (!this.fetchOptions.parent) {
          WurflTargetValues.replaceSuccessHandlerForFirstCall(options, this);
        }
        Object.assign(this.fetchOptions, WurflTargetValues.getFetchOptions(), options.fetchOptions);
      }
      return NemoTargetValues.prototype.fetch.call(this, options);
    },
    parse: function (response) {
      var parsedData = WurflTargetValues.getEntities(response).map(WurflTargetValues.augmentEntityData, this);
      return NemoTargetValues.prototype.parse.call(this, {
        data: parsedData,
        length: parsedData.length
      });
    }
  };
  var WurflTargetValues = NemoTargetValues.extend(instanceProperties, classProperties);
  return WurflTargetValues;
});
