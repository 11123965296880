define('modules/reporting/campaigns/performance/views/performanceChart',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "./performanceBarChart", "./performanceLineChart", "text!../templates/chart.html", "text!modules/shared/templates/chartNoDataGrid.html"], function ($, _, T1, T1View, T1Permissions, PerformanceBarChart, PerformanceLineChart, template, noDataChartTmpl) {
  "use strict";

  return T1View.extend({
    template: template,
    partials: {
      chartNoDataChart: noDataChartTmpl
    },
    dimensionKey: "strategy_id",
    dimensionName: "strategy_name",
    yKey: "",
    yAxisLabel: "",
    eventhubEvents: {
      "update:metric": function () {
        this.prepareRenderData();
        this.updateChart();
      },
      "change:filteredOutItems": function () {
        this.updateChart();
      },
      "sort:grid:performance": function () {
        if (this.subSection === "summary") {
          this.reload();
        }
      },
      "switch:chart": function (chartType) {
        this.subSection = chartType;
        this.chart.unloadChart();
        this.setupChart();
      }
    },
    dataEvents: {
      reportModel: {
        "change:campaignBenchmarkOn": "updateChart",
        "change:dimensionItems": "reload",
        "change:highlightedItem": function () {
          if (this.subSection === "summary") {
            this.updateChart();
          } else {
            this.chart.highlightChart();
          }
        },
        "change:hiddenCount": "updateChart"
      }
    },
    initialize: function (args) {
      const {
        dataGroup: dataGroup,
        models: models
      } = args;
      const focus = models.reportModel.get("focus");
      Object.assign(this, {
        campaign: models.campaign,
        campaignNestedCollection: dataGroup.campaignNestedCollection,
        dim1Collection: dataGroup.dim1Collection,
        dim1FilteredCollection: dataGroup.dim1FilteredCollection,
        dim1FilteredNestedCollection: dataGroup.dim1FilteredNestedCollection,
        dim1NestedCollection: dataGroup.collection,
        mainModel: models.mainModel,
        reportMetaObj: $.extend({}, models.reportMeta.toJSON()),
        reportModel: models.reportModel,
        subSection: models.mainModel.get("performanceSubSection")
      });
      if (focus === "display" || focus === "video") {
        this.campaignModel = models.summaryStrategyChannel;
      } else {
        this.campaignModel = models.summary;
      }
    },
    load: function () {
      const self = this;
      const $el = self.el;
      this.prepareRenderData();
      this.render().then(function () {
        self.$noDataChart = $el.find(".no-data");
        self.chartContainer = $el.find("#performance-chart");
        self.setupChart();
      });
    },
    prepareRenderData: function () {
      const reportModel = this.reportModel;
      const reportMetaObj = this.reportMetaObj;
      const focus = reportModel.get("focus") || "all";
      const metricKey = this.reportMetaObj.metrics[focus];
      const dim1Info = reportModel.get("dimensionInfo");
      this.yKey = reportModel.get("metric");
      this.yKey = metricKey[this.yKey] ? this.yKey : this.reportMetaObj.metricOptions[0].value;
      this.yAxisLabel = reportMetaObj.metrics[focus][this.yKey].text;
      this.yMetricObj = reportMetaObj.fields[this.yKey];
      this.dimensionKey = dim1Info.key;
      this.dimensionName = dim1Info.dimension;
      reportModel.set({
        yAxisLabel: this.yAxisLabel,
        yMetricObj: this.yMetricObj,
        yKey: this.yKey
      });
    },
    updateSortKeyInfo: function () {
      const sortKeyInfo = this.reportModel.get("sortKeyInfo") || "";
      const sortKeyArray = sortKeyInfo.split(",");
      if (sortKeyArray.length > 0 && sortKeyArray[0] !== "") {
        this.sortKey = this.reportMetaObj.fields[sortKeyArray[0].toLowerCase()].name;
      }
    },
    setupChart: function () {
      let collection = $.extend({}, this.dim1Collection);
      let nestedCollection = $.extend({}, this.dim1NestedCollection);
      const reportModel = this.reportModel;
      const dim1Info = reportModel.get("dimensionInfo");
      const dim2Info = reportModel.get("dim2Info");
      if (dim2Info) {
        collection = $.extend({}, this.dim1FilteredCollection);
        nestedCollection = $.extend({}, this.dim1FilteredNestedCollection);
      }
      switch (this.subSection) {
        case "daily":
          this.chart = new PerformanceLineChart({
            campaignNestedCollection: this.campaignNestedCollection,
            campaign: this.campaign,
            collection: nestedCollection,
            mainModel: this.mainModel,
            parentClass: this,
            reportModel: reportModel,
            summaryCollection: collection
          });
          break;
        case "summary":
          this.xAxisLabel = dim1Info.xAxisLabel;
          this.updateSortKeyInfo();
          this.chart = new PerformanceBarChart({
            campaign: this.campaign,
            campaignModel: this.campaignModel,
            collection: collection,
            mainModel: this.mainModel,
            parentClass: this,
            reportModel: reportModel
          });
          break;
      }
      this.showNoDataChart();
      this.hideChart();
      this.updateChart();
    },
    updateChart: function () {
      const highlighted = this.reportModel.get("highlightedItem");
      const dimBars = Boolean(highlighted && highlighted.id);
      this.chart.updateChart(dimBars);
    },
    showNoDataChart: function () {
      this.$noDataChart.removeClass("no-display");
    },
    hideNoDataChart: function () {
      this.$noDataChart.addClass("no-display");
    },
    showChart: function () {
      this.chartContainer.removeClass("no-display");
    },
    hideChart: function () {
      this.chartContainer.addClass("no-display");
    },
    unload: function () {
      this.chart.unloadChart();
      if (this.chartContainer) {
        this.chartContainer = null;
      }
    }
  });
});
