define('T1Selectbox',["jQuery", "T1", "jQPlugins/chosen.jquery"], function ($, T1) {
  "use strict";

  T1.Selectbox = function (select, configs, autoCompleteConfigs) {
    var options = {
      data: {},
      searchFieldDisabled: true,
      onChange: $.noop,
      hideSelected: true,
      enableDirectionAuto: true,
      searchInputPlaceHolder: "",
      highlightInSearchResult: false,
      dropDownRightPosition: "auto",
      search_contains: true
    };
    var autoCompleteOptions = {
      autoComplete: false,
      onSearchRequest: $.noop
    };
    var parent = select.parent();
    var searchInput;
    var loaderClass = "search-loader-no-bg";
    var clickTarget;
    var extraLink;
    var getOpenedPortion = function (e) {
      var desiredLevelParent = $(e.srcElement || e.target);
      var openedPart;
      var found = false;
      var limitParentSearch = 8;
      var i;
      for (i = 0; i < limitParentSearch; i++) {
        if (desiredLevelParent && desiredLevelParent.hasClass("chzn-container-single")) {
          found = true;
          break;
        } else {
          desiredLevelParent = desiredLevelParent.parent();
        }
      }
      if (!desiredLevelParent || found === false) {
        return;
      }
      openedPart = desiredLevelParent.find(".chzn-drop");
      openedPart = openedPart.length > 0 ? openedPart[0] : null;
      return openedPart;
    };
    var ensureDropListVisible = function (e, openedEle) {
      var openedPart = openedEle;
      var borderColor = "#D5D5D5";
      var needDelay = false;
      if (openedPart && openedPart.style) {
        if (openedPart.style.left.indexOf("-9000") <= -1) {
          var formButtonStrip = $(document).find(".actions-container");
          var formButtonStripH = formButtonStrip ? formButtonStrip.height() : 0;
          var panelHeight = $(openedPart).height();
          var mouseY = e.clientY;
          var panelBottom = mouseY + panelHeight + 26;
          var $win = $(window);
          var $target = $(e.target);
          var windowHeight = $win.height();
          var needToFlip = panelBottom > windowHeight - formButtonStripH;
          var originTopBeforeReposition = $target.offset().top - $win.scrollTop();
          if (needToFlip === true && mouseY > panelHeight) {
            openedPart.style.top = -(panelHeight + 2) + "px";
            openedPart.style.borderTopStyle = "solid";
            openedPart.style.borderTopColor = borderColor;
            openedPart.style.borderTopWidth = "1px";
            openedPart.style.height = panelHeight + "px";
            if ($target.offset().top - $win.scrollTop() - originTopBeforeReposition > 10) {
              needDelay = true;
            }
          }
        }
      }
      if (openedPart) {
        openedPart.style.zIndex = 500;
        if (needDelay === true) {
          setTimeout(function () {
            openedPart.style.visibility = "visible";
          }, 180);
        } else {
          openedPart.style.visibility = "visible";
        }
      }
    };
    if (!select) {
      return;
    }
    select = $(select);
    configs = configs || {};
    options = $.extend(options, configs);
    if (options.searchFieldDisabled) {
      options.disable_search = true;
    }
    autoCompleteOptions = $.extend(autoCompleteOptions, autoCompleteConfigs);
    select.chosen(options).change(options.data, options.onChange);
    if (options.enableDirectionAuto === true) {
      clickTarget = parent.find(".chzn-container-single>.chzn-single");
      clickTarget.mousedown(function (e) {
        var openedPortion = getOpenedPortion(e);
        if (openedPortion) {
          openedPortion.style.visibility = "hidden";
          setTimeout(function () {
            ensureDropListVisible(e, openedPortion);
            $(openedPortion).find('.chzn-search input[type="text"]').focus();
          }, 0);
        }
      });
    }
    searchInput = parent.find(".chzn-search");
    if (options.searchFieldDisabled) {
      searchInput.css({
        position: "absolute",
        left: "-9999999px"
      });
      $(searchInput).on("keypress", function (e) {
        e.preventDefault();
      });
    } else {
      searchInput.children("input").attr("placeholder", options.searchInputPlaceHolder);
      parent.bind("click", function (e) {
        e.stopPropagation();
        $(e.currentTarget).find('.chzn-drop .chzn-search input[type="text"]').focus();
      });
    }
    if (autoCompleteOptions.autoComplete) {
      var $inputEle = parent.find(".chzn-container").find(".chzn-search > input");
      var $selectDropdown = parent.find(".select-input");
      var inputEventHandler = function (event, options) {
        var field = $(this);
        var val;
        var searchOptions = {};
        var needToFireSearchInputClearedEvent = false;
        var needToFireSearchInputFocusEvent = false;
        var $drop = parent.find(".chzn-drop");
        var isDropUp;
        val = $.trim(field.val());
        if (event.keyCode === 8 && val.length < 1 && autoCompleteOptions.onSearchInputCleared) {
          needToFireSearchInputClearedEvent = true;
        } else if (options && options.onFocus === true && autoCompleteOptions.onSearchInputFocus) {
          needToFireSearchInputFocusEvent = true;
        }
        if (val === $(this).data("prevVal")) {
          return false;
        } else if (val.length < 1 && needToFireSearchInputClearedEvent === false && needToFireSearchInputFocusEvent === false) {
          return false;
        }
        if (this.timer) {
          clearTimeout(this.timer);
        }
        field.data("prevVal", val);
        searchOptions = {
          params: [{
            term: val !== "" ? "*" + val + "*" : "",
            searchField: autoCompleteOptions.searchField || ""
          }],
          event: event
        };
        searchOptions.success = function (collection) {
          var data;
          var results_none_found = "No results found for ";
          var no_results_html;
          var selectedItem;
          var fieldVal = $.trim(field.val());
          var chznId = $(event.currentTarget).closest(".chzn-container").attr("id");
          var select = $("#" + chznId.substring(0, chznId.indexOf("_")));
          var existingItemMatched = false;
          var selectedIndicator;
          if (autoCompleteOptions.onSearchSuccess) {
            autoCompleteOptions.onSearchSuccess(collection);
          }
          data = collection.toJSON();
          isDropUp = parseInt($drop.css("top"), 10) < 0;
          select.find("option").each(function () {
            if ($(this).is(":selected") && !selectedItem) {
              selectedItem = {};
              selectedItem.value = $(this).val();
              selectedItem.text = $(this).text();
              field.data("prevSelection", selectedItem);
            }
            return $(this).remove();
          });
          if (!$.isEmptyObject(data)) {
            $.each(data, function (value, model) {
              selectedIndicator = "";
              if (selectedItem && model.id === selectedItem.value) {
                selectedIndicator = "selected";
                existingItemMatched = true;
              }
              $("<option " + selectedIndicator + "></option>").attr("value", model.id).html(model.name).appendTo(select);
            });
            if (!selectedItem) {
              selectedItem = field.data("prevSelection");
            }
            if (existingItemMatched === false && selectedItem) {
              select.prepend($("<option selected value='" + selectedItem.value + "'>" + selectedItem.text + "</option>"));
            }
            select.trigger("liszt:updated");
          } else {
            if (!selectedItem) {
              selectedItem = field.data("prevSelection");
            }
            if (selectedItem) {
              select.prepend($("<option selected value='" + selectedItem.value + "'>" + selectedItem.text + "</option>"));
              select.trigger("liszt:updated");
            }
            no_results_html = $('<li class="no-results">' + results_none_found + ' "<span></span>"</li>');
            no_results_html.find("span").first().text(val);
            parent.find("ul.chzn-results").first().html(no_results_html);
          }
          if (isDropUp) {
            setTimeout(function () {
              if (isDropUp) {
                $drop.css({
                  top: -$drop.height() - 1
                });
              }
            }, 50);
          }
          field.val(fieldVal);
          field.removeClass(loaderClass);
        };
        this.timer = setTimeout(function () {
          field.addClass(loaderClass);
          if (needToFireSearchInputClearedEvent === true) {
            return autoCompleteOptions.onSearchInputCleared(searchOptions);
          } else if (needToFireSearchInputFocusEvent === true) {
            return autoCompleteOptions.onSearchInputFocus(searchOptions);
          } else {
            return autoCompleteOptions.onSearchRequest(searchOptions);
          }
        }, 0);
        return this.timer;
      };
      var lastFocusInvoked;
      var dtNow;
      var $parent;
      $inputEle.bind("focus", function (event) {
        event.stopPropagation();
        lastFocusInvoked = $inputEle.data("lastFocusInvoked");
        dtNow = new Date().valueOf();
        $parent = $inputEle.closest(".chzn-drop");
        if ($parent.css("left").indexOf("-9000") <= -1) {
          if (!lastFocusInvoked || dtNow - lastFocusInvoked > 800) {
            $inputEle.data("lastFocusInvoked", dtNow);
            inputEventHandler.call($inputEle, event, {
              onFocus: true
            });
          }
        }
      });
      $inputEle.bind("keyup", function (event) {
        inputEventHandler.call($inputEle, event);
      });
      $selectDropdown.on("clearDropDownPreviousSearch", function () {
        $inputEle.removeData();
      });
    }
    if (configs.extraLink) {
      extraLink = $("<a/>").text(configs.extraLink.label).click(configs.extraLink.onClick).appendTo(parent.find(".chzn-drop"));
      if (!configs.extraLink.class) {
        extraLink.addClass("extra-link");
      } else {
        extraLink.addClass(configs.extraLink.class);
      }
    }
  };
});
