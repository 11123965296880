define('modules/targeting/pixel/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "T1Spinner", "collections/pixels", "models/vendor", "text!modules/targeting/pixel/templates/bin_header.html", "text!modules/targeting/pixel/templates/bin_title.html", "text!modules/targeting/pixel/templates/bin_group_name.html", "text!modules/targeting/pixel/templates/bin_item.html", "utils/CampaignStrategyUtils"], function ($, _, T1, BinsView, Spinner, Pixels, Vendor, bin_header, bin_title, bin_group_name, bin_item, Utils) {
  "use strict";

  const pixelTypes = Object.freeze({
    creative: "C",
    data: "D",
    dynamic: "S",
    event: "E",
    segment: "S"
  });
  const formatNumber = T1.Formats.rolledUpNumbersShort();
  const postCollection = new Pixels();
  postCollection.urlFilter.disableEventsListeners();
  const PixelBinsView = BinsView.extend({
    type: "Pixel",
    postCollection: postCollection,
    configs: {
      include: {
        showAndOr: true,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      }
    },
    events: {
      "click input:radio[name=and_or]": "andOrClicked"
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      bin_item: bin_item
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      allowMultipleSelection: true,
      onDropEvent: "bins:droppedPixel",
      checkExistenceSharedCollection: false,
      handlerTextEl: ".name"
    },
    andOrClicked: function () {
      const inputChecked = this.$("input:radio[name=and_or]:checked");
      this.sharedCollection.includeOperator = inputChecked.val() || "OR";
      this.updateLoadCount();
    },
    initialize: function ({
      strategy: strategy
    }) {
      this.strategy = strategy;
    },
    mapItems: function (items) {
      if (!items || typeof items !== "object") {
        throw new Error(`mapItems fails if input doesn't exist`);
      }
      const getGroupNameFromModel = Utils.getGroupNameFromPixelModel;
      const groupedItems = _.groupBy(items, value => getGroupNameFromModel(value));
      const result = [];
      const createItem = function (model, i) {
        const name = model.get("name");
        const {
          shortPath: shortPath,
          shortPathEnd: shortPathEnd,
          title: title
        } = this.getPath(name);
        result.push({
          id: model.id,
          bin_show_group_name: i === 0,
          full_name: name,
          full_path: shortPath,
          full_path_end: shortPathEnd,
          title: title,
          pixel_type: pixelTypes[model.get("pixel_type")] || "INVALID",
          bin_group_name: getGroupNameFromModel(model),
          action: model.action || model.get("action")
        });
      };
      Object.entries(groupedItems).forEach(([, modelArr]) => {
        modelArr.forEach(createItem, this);
      });
      return result;
    },
    getPath: function (fullPath) {
      var path = fullPath;
      var goalLength = 30;
      if (!fullPath) {
        throw new Error("Pixel has no name attribute");
      }
      path = path.replace(/ - /g, "|");
      path = path.length > goalLength ? `...${path.substr(-goalLength)}` : path;
      const lastSlashIndex = path.lastIndexOf("|");
      const noSlash = lastSlashIndex === -1;
      path = path.replace(/[|]/g, " - ");
      return {
        shortPath: noSlash ? "" : path.substring(0, lastSlashIndex + 1),
        shortPathEnd: noSlash ? path : path.substr(lastSlashIndex + 1),
        title: fullPath.replace(/ - /g, "|")
      };
    },
    getPixelTotalLoads: function (ids, operation, passThru, callback) {
      var ajxOperation = `${passThru}Ajx`;
      if (!ids || !operation || !passThru || !callback) {
        throw new Error("getPixelTotalLoads requires all function arguments");
      }
      if (!(operation === "AND" || operation === "OR")) {
        throw new Error(`getPixelTotalLoads requires operation be either 'AND' or 'OR'`);
      }
      if (typeof callback !== "function") {
        throw new Error("getPixelTotalLoads requires a callback function");
      }
      const fetchOptions = {
        full: "*"
      };
      const opts = {
        success: data => {
          var dataList = data.toJSON();
          var loads = 0;
          var totalCount = 0;
          var nonDynamicCount = 0;
          this.$(`.header-${passThru}-bin .icon-spinner`).spin(false);
          $.each(dataList, function (index, value) {
            if (value.pixel_type !== "dynamic") {
              const num = value.unique ? parseFloat(value.unique.loads) : 0;
              if (operation === "OR") {
                loads += num;
              } else {
                loads = nonDynamicCount === 0 ? num : loads;
                loads = loads > num ? num : loads;
              }
              nonDynamicCount++;
            }
            totalCount++;
          });
          const dynamicCount = totalCount - nonDynamicCount;
          if (totalCount === dynamicCount) {
            loads = "N/A";
          }
          callback({
            totalLoads: loads
          }, passThru, operation, ids.length - dynamicCount);
        }
      };
      const postCollectionBin = new Pixels();
      postCollectionBin.fetchOptions = $.extend(fetchOptions, postCollection.fetchOptions);
      postCollectionBin.isPaginated = false;
      postCollectionBin.urlFilter.clear();
      postCollectionBin.search.clear();
      postCollectionBin.search.set({
        idList: ids.join(",")
      });
      if (this[ajxOperation]) {
        this[ajxOperation].abort();
      }
      postCollectionBin.fetch(opts);
    },
    getProviderTax(model, hasBeenRemoved) {
      const pixelProvider = model.get("pixel_provider");
      const pixelType = model.get("pixel_type");
      if (!hasBeenRemoved && pixelType === "data" && pixelProvider) {
        const vendorModel = new Vendor();
        vendorModel.id = pixelProvider.get("vendor_id");
        vendorModel.fetch({
          success: respModel => {
            if (respModel.get("provider_tax")) {
              T1.EventHub.publish("bins.providerTaxAdded");
            }
          }
        });
      }
    },
    updateLoadCount: function () {
      var self = this;
      var bins = {};
      var actions = ["include", "exclude"];
      var binItemsLength = 0;
      let operation = this.sharedCollection.includeOperator;
      function applySpinner(spinnerTarget) {
        if (!spinnerTarget.children().length) {
          Spinner.apply(spinnerTarget, self.spinnerOpts);
        }
      }
      function groupItems(model) {
        const action = this;
        return model.action === action || model.get("action") === action;
      }
      actions.forEach(action => {
        const group = this.sharedCollection.groupBy(groupItems, action);
        bins[action] = group.true || null;
      });
      function getTotalLoads(totalLoads, loadsAction, idCount, loadsOperation) {
        if (totalLoads === "N/A") {
          return totalLoads;
        }
        const formattedTotalLoads = formatNumber(totalLoads);
        const canHaveUpto = loadsOperation === "AND" && loadsAction === "include" && idCount > 1;
        return canHaveUpto ? `Up to ${formattedTotalLoads}` : formattedTotalLoads;
      }
      function setTotalLoads(data, loadsAction, loadsOperation, idCount) {
        data.totalLoads = getTotalLoads(data.totalLoads, loadsAction, idCount, loadsOperation);
        self.updateDataBind(data, `.header-${loadsAction}-bin`);
      }
      for (const action in bins) {
        if (T1.Utils.hasOwnProp(bins, action)) {
          const binItems = bins[action];
          const iconSpinner = this.$(`.header-${action}-bin .icon-spinner`);
          if (binItems) {
            const ids = binItems.map(item => parseInt(item.id, 10));
            applySpinner(iconSpinner);
            this.updateDataBind({
              totalLoads: ""
            }, `.header-${action}-bin`);
            binItemsLength = binItems.length;
            operation = action === "exclude" ? "OR" : operation;
            this.getPixelTotalLoads(ids, operation, action, setTotalLoads);
          } else {
            const totalLoads = getTotalLoads(0, action, binItemsLength);
            this.updateDataBind({
              totalLoads: totalLoads
            }, `.header-${action}-bin`);
            iconSpinner.spin(false);
          }
        }
      }
    },
    updateBins: function (model, hasBeenRemoved) {
      this.getProviderTax(model, hasBeenRemoved);
      this.updateLoadCount();
    },
    load: function () {
      this.render().then(() => {
        this.updateLoadCount();
        T1.EventHub.publish("strategyTargetingLoader:stop");
      });
    },
    unload: function () {
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    serialize: function (dataset) {
      const includeBinHeader = "Total included loads yesterday";
      const excludeBinHeader = "Total excluded loads yesterday";
      if (dataset && dataset.bins) {
        dataset.bins[0].use_or = this.sharedCollection.includeOperator === "OR";
        $.each(dataset.bins, function (index, bin) {
          bin.bin_label = bin.bin_action === "include" ? includeBinHeader : excludeBinHeader;
        });
        return dataset;
      }
    }
  });
  return PixelBinsView;
});
