define('T1Countries',["countries.config"], function (countriesConfig) {
  var instance;
  var T1CountrySupport;
  T1CountrySupport = function () {
    function getCountryList(obj) {
      var countries = countriesConfig.countries.split(":");
      var len = countries.length;
      var i;
      var arr;
      var selectedId = obj.selectedId;
      var list = [];
      for (i = 0; i < len; i++) {
        arr = countries[i].split("|");
        list.push({
          text: arr[0],
          value: arr[1],
          selected: arr[1] === selectedId
        });
      }
      return list;
    }
    function getCountryName(country_code) {
      var countries = countriesConfig.countries;
      var endIndex = countries.indexOf("|" + country_code);
      var startIndex = countries.substr(0, endIndex).lastIndexOf(":") + 1;
      return countries.substring(startIndex, endIndex);
    }
    return {
      getCountryList: getCountryList,
      getCountryName: getCountryName
    };
  };
  if (!instance) {
    instance = new T1CountrySupport();
  }
  return instance;
});
