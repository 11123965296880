define('modules/strategy/views/drawer',['require','jQuery','T1','T1Form','T1View','utils/CampaignStrategyUtils','text!modules/strategy/templates/drawer.html','jQueryUI'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Form = require("T1Form");
  const T1View = require("T1View");
  const CampaignStrategyUtils = require("utils/CampaignStrategyUtils");
  const template = require("text!modules/strategy/templates/drawer.html");
  const jQueryUI = require("jQueryUI");
  const hundredPct = 100;
  var Date = T1.Date;
  var NoCapLabel = "No Cap";
  const {
    divideByZero: divideByZero,
    calculateDailySpendToPace: calculateDailySpendToPace,
    populatePerformanceAlert: populatePerformanceAlert,
    formatsRolledUpNumbersNoAbbreviation: formatsRolledUpNumbersNoAbbreviation
  } = CampaignStrategyUtils;
  var dropdownList = {
    impressioncap_type: {
      options: [{
        value: "asap",
        text: "Cap"
      }, {
        value: "no-cap",
        text: NoCapLabel
      }]
    }
  };
  var impressionCap = function ($groupEditEl, isNoCap) {
    var $impEl = $('[data-bind="impressioncap_type"]', $groupEditEl);
    $groupEditEl.toggleClass("no-cap", isNoCap);
    const $impressionCapEls = $("[data-bind]", $groupEditEl).not('[data-bind="impressioncap_type"]');
    $impEl.val(dropdownList.impressioncap_type.options[isNoCap ? 1 : 0].value).trigger("liszt:updated");
    if (isNoCap) {
      $groupEditEl.css("margin-left", "132px");
    } else {
      $groupEditEl.css("margin-left", "0px");
    }
    $.each($impressionCapEls, function (key, el) {
      $(el).closest("dd").toggle(!isNoCap);
    });
    $(".info", $groupEditEl).toggle(!isNoCap);
  };
  var DrawerItemView = T1View.extend({
    template: template,
    dataEvents: {
      model: {
        change: function () {
          this.isCurrentDrawerOpen = this.$(".drawer").is(":visible");
          this.load();
        }
      },
      campaign: {
        "change:start_date": "onCampaignDateChanges",
        "change:end_date": "onCampaignDateChanges"
      }
    },
    eventhubEvents: {
      onCloseInlineEdit: function (event) {
        if (event && event.view && event.view.cid === this.cid) {
          T1.EventHub.publish(`action:drawer:load-${this.model.id}`);
          this.isCurrentDrawerOpen = true;
          this.render().then(() => {
            this.bindImpressionCap();
            this.prepareDataPoints();
            this.delegateEvents();
          });
        }
      }
    },
    initialize({
      model: model,
      defaultDrawerExpandedState: defaultDrawerExpandedState
    }) {
      this.model = model;
      this.campaign = this.model.get("campaign");
      this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(this.campaign);
      this.isCurrentDrawerOpen = defaultDrawerExpandedState;
    },
    load() {
      this.render().then(() => {
        T1.EventHub.publish(`action:drawer:load-${this.model.id}`);
        this.bindImpressionCap();
        this.prepareDataPoints();
        this.delegateEvents();
      });
    },
    onCampaignDateChanges() {
      var model = this.model;
      if (model.get("use_campaign_start") !== "0" || model.get("use_campaign_end") !== "0") {
        this.renderPartial(".remaining");
        this.renderPartial(".spend-to-pace");
        T1.Tooltip(this.$(".pointer"), {
          offset: "4"
        });
      }
    },
    prepareDataPoints() {
      T1.Tooltip(this.$(".pointer"), {
        offset: "4"
      });
      T1.Tooltip(this.$(".label"));
    },
    loaderOpts: {
      loaderContentClass: "inline-edit-loader",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15
      }
    },
    serializeAdditionalInformation(dataset) {
      let budgetInformation, pacingRatio;
      var model = this.model;
      var campaign = model.get("campaign");
      const info = model.get("rpt_goal_monitoring");
      var startOffsetX = 5;
      var outPacingRange = 10;
      var coloredBarWidth = 95;
      const dayInMilliSecs = 864e5;
      const multiplier = 1.25;
      const reports = {};
      $.each(info.toJSON(), function (index, report) {
        reports[report.mm_interval] = report;
      });
      const startDate = model.get("use_campaign_start") !== "0" ? campaign.get("start_date") : model.get("start_date");
      let endDate = model.get("use_campaign_end") !== "0" ? campaign.get("end_date") : model.get("end_date");
      const start = Date.parse(startDate);
      const end = Date.parse(endDate);
      const loadDate = reports.CTD && reports.CTD.load_date ? Date.parse(reports.CTD.load_date) : new Date();
      function getTipDate(dateObj) {
        return `${Date.CultureInfo.abbreviatedMonthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;
      }
      if (T1.compareDates(start, end) !== 1) {
        if (T1.compareDates(start, loadDate) === 1) {
          dataset.tip_date = getTipDate(start);
          pacingRatio = 0;
        } else if (T1.compareDates(loadDate, end) === 1) {
          pacingRatio = hundredPct;
        } else {
          dataset.tip_date = getTipDate(loadDate);
          pacingRatio = divideByZero(hundredPct * (loadDate.getTime() - start.getTime()) / (end.getTime() - start.getTime()));
        }
      }
      endDate = model.get("use_campaign_end") !== "0" ? campaign.get("end_date") : model.get("end_date");
      dataset.days_remaining = Math.ceil((end.valueOf() - T1.Date.parse("today")) / dayInMilliSecs);
      dataset.days_remaining = dataset.days_remaining > 0 ? dataset.days_remaining : 0;
      dataset.durationRatio = startOffsetX + Math.round((coloredBarWidth - startOffsetX) * (pacingRatio / hundredPct));
      dataset.displayPointer = pacingRatio === hundredPct || !dataset.durationRatio ? "none" : "block";
      if (!info.length) {
        dataset.coloredBarWidth = 0;
        return dataset;
      }
      dataset.budgetInformation = budgetInformation = CampaignStrategyUtils.getBudgetInformation(model);
      dataset.spent = budgetInformation.budget_spent || "";
      dataset.budgetMeterLabel = budgetInformation.budget_remaining;
      dataset.campaignEffectiveGoalValue = campaign.get("goal_value");
      dataset.strategyEffectiveGoalValue = model.get("goal_value");
      dataset.strategyEffectiveGoalPerformance = dataset.mm_goal_performance;
      dataset.campaignPerformanceAlert = parseFloat(dataset.campaignEffectiveGoalPerformance) > parseFloat(dataset.campaignEffectiveGoalValue) * multiplier ? "alert-item" : "";
      dataset.strategyPerformanceAlert = parseFloat(dataset.strategyEffectiveGoalPerformance) > parseFloat(dataset.strategyEffectiveGoalValue) * multiplier ? "alert-item" : "";
      dataset.spend_remaining = budgetInformation.total_budget - dataset.spent;
      dataset.budgetMeterLabel = dataset.spend_remaining;
      dataset.budgetMeterLabel = T1.Utils.formatCurrency(dataset.budgetMeterLabel.toString(), "", this.getCurrencyCode());
      if (dataset.hasBudget) {
        dataset.daily_spend_to_pace = calculateDailySpendToPace(model);
      }
      dataset = populatePerformanceAlert(dataset);
      dataset.coloredBarWidth = budgetInformation.percnt_budget_spent ? startOffsetX + Math.round(budgetInformation.percnt_budget_spent * ((coloredBarWidth - startOffsetX) / hundredPct)) : 0;
      dataset.coloredBarWidth = dataset.coloredBarWidth > coloredBarWidth ? coloredBarWidth : dataset.coloredBarWidth;
      dataset.coloredBarClass = Math.abs(budgetInformation.percnt_budget_spent - pacingRatio) >= outPacingRange ? "out-pacing" : "pacing";
      return dataset;
    },
    serialize() {
      var model = this.model;
      var dataset = model.toJSON();
      const isBatch = model.get("supply_type") === "BATCH";
      function getImpressionsVal() {
        if (model.get("use_optimization") === "1" && model.get("goal_type") === "reach") {
          let intVal = parseFloat(model.get("bid_aggressiveness"));
          if (intVal) {
            intVal = intVal.toFixed(0);
          }
          model.set({
            bid_aggressiveness: intVal
          }, {
            silent: true
          });
          return isNaN(intVal) ? "" : `BID FOR ${intVal}%`;
        }
        return false;
      }
      dataset.hasBudget = !isBatch && !this.isProgrammaticGuaranteed;
      this.serializeAdditionalInformation(dataset);
      dataset.impression_cap = dataset.impression_cap && $.isNumeric(dataset.impression_cap) ? `${formatsRolledUpNumbersNoAbbreviation(dataset.impression_cap)} /d` : NoCapLabel;
      dataset.impressions = getImpressionsVal();
      dataset.impressioncap_type = dataset.impression_cap ? "asap" : "no-cap";
      dataset.impressionNoCapClass = dataset.impression_cap ? "" : "impression-no-cap";
      dataset.toShowDrawer = this.isCurrentDrawerOpen === true;
      if (dataset.hasBudget) {
        dataset.enableTrackingSupplyTypeFeatureFlag = model.get("supply_type") === "RMX_API";
        if (dataset.enableTrackingSupplyTypeFeatureFlag) {
          dataset.budget = "--";
          dataset.daily_spend_to_pace = "--";
        }
      }
      return dataset;
    },
    editElementBindings: {
      bid_aggressiveness: {
        type: "keyup",
        action: function (evt) {
          var target = $(evt.currentTarget);
          var originalVal = target.val();
          const valResult = parseInt(originalVal, 10);
          if (isNaN(valResult) !== true) {
            if (valResult > hundredPct) {
              target.val(hundredPct);
            } else if (valResult < 1) {
              target.val(1);
            } else if (originalVal.toString().includes(".")) {
              target.val(valResult);
            }
          }
        }
      },
      impressioncap_type: {
        type: "change",
        action: function (evt) {
          var target = $(evt.currentTarget);
          var $groupEditEl = target.closest(".w-GroupInlineEditForm");
          const isNoCap = target.val() === "no-cap";
          impressionCap($groupEditEl, isNoCap);
        }
      }
    },
    bindImpressionCap() {
      const $el = this.el;
      var $impressionCapEl = $(".name .section-c", this.el);
      var model = this.model;
      $(".editable-content", $impressionCapEl).on("click", function () {
        function onImpressionCapEdit() {
          var $groupEditEl = $(".w-GroupInlineEditForm", $impressionCapEl);
          var impCapVal = model.get("impression_cap");
          const isNoCap = impCapVal === undefined || !impCapVal.length;
          impressionCap($groupEditEl, isNoCap);
          $el.unbind("inlineFormEdit.open", onImpressionCapEdit);
        }
        $el.bind("inlineFormEdit.open", onImpressionCapEdit);
      });
    }
  });
  return T1Form.InlineEdit(DrawerItemView, dropdownList);
});
