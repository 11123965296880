define('modules/reporting/campaigns/contextual/views/controls',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!../templates/controls.html", "T1Layout"], function ($, _, T1, T1View, T1Permissions, template, T1Layout) {
  "use strict";

  var ControlsView = T1View.extend({
    selectedColumns: null,
    template: template,
    events: {
      "changed .search": "searchData",
      "click strand-button": "showColumnSelector"
    },
    dataEvents: {
      reportModel: {
        "change:columnKeys": "updateMetadataSortKeys",
        "change:dimensionItems": "reload",
        "change:sortKeyInfo": "resortCollection"
      }
    },
    initialize: function (args) {
      var models = args.models;
      this.collection = args.collection;
      this.reportMeta = models.reportMeta;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
      this.campaign = models.campaign;
    },
    load: function () {
      var reportModel = this.reportModel;
      var preferences = reportModel.get("preferences");
      var focus = reportModel.get("focus");
      var getFocusKey = reportModel.makeFocusKeyFunc(focus);
      var selectedColumns = preferences.get(getFocusKey("selectedColumns"));
      var sortKeyInfo = preferences.get(getFocusKey("sortKeyInfo"));
      var campaignGoalType = this.mainModel.get("multiCampaigns") ? "multi" : this.campaign.get("goal_type");
      var self = this;
      reportModel.setColumnKeys(selectedColumns === undefined || selectedColumns === "" ? reportModel.get("defaultColumnKeys") : selectedColumns.split(","));
      reportModel.setSortKeyInfo(sortKeyInfo === undefined || sortKeyInfo === "" ? this.reportMeta.get("defaultSortForGoalType")[campaignGoalType] || "" : sortKeyInfo);
      this.getFocusKey = getFocusKey;
      this.updateMetadataSortKeys();
      this.render().then(function () {
        self.$searchWarning = self.el.find(".search-warning");
      });
    },
    updateMetadataSortKeys: function () {
      var reportModel = this.reportModel;
      var sortKeyInfo = reportModel.get("sortKeyInfo");
      var sortKeyArray = sortKeyInfo ? sortKeyInfo.split(",") : [];
      var columnKeys = reportModel.get("columnKeys");
      var sortKeyIndexInColumnKeys = sortKeyArray.length > 0 ? $.inArray(sortKeyArray[0], columnKeys) : -1;
      if (sortKeyIndexInColumnKeys === -1 && sortKeyArray[0] !== "path" && sortKeyArray[0] !== "target_name") {
        this.reportModel.setSortKeyInfo(columnKeys.length === 0 ? "Strategy_Name,1,1" : `${columnKeys[0]},-1`);
      }
    },
    resortCollection: function (reportModel) {
      var sortKeyArray;
      var sortKeyInfo = reportModel.get("sortKeyInfo");
      const TYPE = 2;
      if (sortKeyInfo && sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        this.collection.sortByKey(sortKeyArray[0], parseInt(sortKeyArray[1], 10), "summary", false, parseInt(sortKeyArray[TYPE], 10));
      }
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("sortKeyInfo"),
        value: sortKeyInfo
      });
    },
    prepareMultiSortableData: function () {
      var self = this;
      var reportMeta = this.reportMeta;
      var allColumnsObj = $.extend({}, reportMeta.additionalData.columns.all);
      var selectedKeys = this.selectedColumns ? this.selectedColumns.split(",") : this.reportModel.get("defaultColumns");
      var selectedCols = [];
      var availableCols, availableKeys;
      _.each(allColumnsObj, function (obj, prop) {
        if (obj.onlyViewableInCampaignCurrency) {
          delete allColumnsObj[prop];
        }
        if (obj.onlyInPerformance) {
          delete allColumnsObj[prop];
        }
        if (obj.featureFlag && !T1Permissions.check("feature", obj.featureFlag)) {
          delete allColumnsObj[prop];
        }
        if (obj.section && !obj.section.includes(self.reportModel.get("key"))) {
          delete allColumnsObj[prop];
        }
      });
      _.each(selectedKeys, function (obj) {
        var key = self.selectedColumns ? obj : obj.value;
        if (allColumnsObj[key]) {
          delete allColumnsObj[key];
        } else {
          return;
        }
        selectedCols.push({
          text: reportMeta.getFriendlyName("metrics", key),
          value: key
        });
      });
      availableKeys = _.keys(allColumnsObj);
      availableCols = _.map(availableKeys, function (key) {
        return {
          text: reportMeta.getFriendlyName("metrics", key),
          value: key
        };
      });
      this.availableColumnsArray = availableCols;
      this.selectedColumnsArray = selectedCols;
    },
    initLayout: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".column-selector-dialog");
        },
        template: '<div class="column-selector"></div>',
        layout: {
          ".column-selector": [{
            module: "reporting/campaigns/reachFrequency",
            viewType: "columnSelector",
            options: {
              listA: self.availableColumnsArray,
              listB: self.selectedColumnsArray,
              onCloseCallback: function (obj) {
                if (obj) {
                  self.saveColumns(obj);
                }
                self.onFormClose();
              }
            }
          }]
        }
      });
    },
    saveColumns: function (obj) {
      var listB = obj.listB;
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("selectedColumns"),
        value: `${listB.toString()}`
      });
      this.reportModel.setColumnKeys(listB);
    },
    onFormClose: function () {
      delete this.layout.layout[".column-selector-dialog"];
    },
    showColumnSelector: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.selectedColumns = this.reportModel.get("preferences").get(this.getFocusKey("selectedColumns"));
      this.prepareMultiSortableData();
      this.initLayout();
      this.layout.load();
    },
    searchData: function (event) {
      var value = event.currentTarget.value;
      var length = value ? value.length : 0;
      var T1Publish = T1.EventHub.publish;
      var $searchWarning = this.$searchWarning;
      var reportModel = this.reportModel;
      const SEARCH_THRESHOLD = 3;
      if (value && length) {
        if (length < SEARCH_THRESHOLD) {
          $searchWarning.removeClass("no-display");
          reportModel.setSearchTerm("");
        } else {
          $searchWarning.addClass("no-display");
          reportModel.setSearchTerm(value);
          T1Publish("grid.searchData", value);
        }
      } else {
        $searchWarning.addClass("no-display");
        reportModel.setSearchTerm("");
        T1Publish("grid.resetSearch", "");
      }
    }
  });
  return ControlsView;
});
