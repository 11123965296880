define('modules/reporting/campaigns/technology/views/chartFilters',['require','jQuery','text!../templates/chart_metric_controls.html','text!../../templates/select_metric.html','T1','T1Layout','T1View','text!../templates/chart_filters.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const metricControls = require("text!../templates/chart_metric_controls.html");
  const selectMetric = require("text!../../templates/select_metric.html");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const template = require("text!../templates/chart_filters.html");
  return T1View.extend({
    template: template,
    partials: {
      metric_controls: metricControls,
      select_metric: selectMetric
    },
    filterMetric: "impressions",
    dimKey_device_type: "device_type",
    dimKey_inventory_type: "inventory_type",
    chartRenderState: {
      device: undefined,
      inventory: undefined
    },
    loaderOpts: {
      contentClass: "loader",
      context: ".w-chart-filters",
      target: ".view-loader",
      text: "requesting data"
    },
    message: {
      show(context) {
        if (!context.loaderStarted) {
          context.chartLoader.start();
        }
        context.loaderStarted = true;
      },
      hide(context) {
        context.chartLoader.stop();
        context.loaderStarted = false;
      }
    },
    dataEvents: {
      reportModel: {
        "change:deviceData": function () {
          this.updateData("device");
        },
        "change:inventoryData": function () {
          this.updateData("inventory");
        }
      }
    },
    events: {
      "handleNoDataDisplay .w-chart-filters-body": "noDataCheck"
    },
    eventhubEvents: {
      "load:donutData": "displayLoadingMessage"
    },
    initialize({
      models: models,
      chartFiltersMetrics: chartFiltersMetrics
    }) {
      this.models = models;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
      this.filterMetric = this.reportModelObj.metric2;
      this.metrics = chartFiltersMetrics;
      this.chartLoader = this.loader();
      this.initLayouts();
    },
    load(noLoadingMessage) {
      if (!this.loaded) {
        this.render().then(() => {
          this.activateSelectbox();
          this.updateData("device");
          this.updateData("inventory");
          this.layout.load();
          this.loaded = true;
        });
      }
      if (!noLoadingMessage) {
        this.displayLoadingMessage();
      }
    },
    initLayouts() {
      this.layout = new T1Layout({
        el: () => this.$(".w-chart-filters-body"),
        template: `\n          <div class="filter-c filter0"></div>\n          <div class="filter-c filter1"></div>\n        `,
        layout: {
          ".filter0": [{
            module: "reporting/campaigns/technology",
            viewType: "donutChart",
            options: {
              chartFilters: this,
              dimKey: this.dimKey_device_type,
              id: "device",
              instanceID: 0,
              label: "Device Type",
              models: this.models
            }
          }],
          ".filter1": [{
            module: "reporting/campaigns/technology",
            viewType: "donutChart",
            options: {
              chartFilters: this,
              dimKey: this.dimKey_inventory_type,
              id: "inventory",
              instanceID: 1,
              label: "Inventory Type",
              models: this.models
            }
          }]
        }
      });
    },
    displayLoadingMessage() {
      const reportModel = this.reportModel;
      this.$(".msg").addClass("no-display");
      reportModel.setDeviceFilterData(undefined);
      reportModel.setInventoryFilterData(undefined);
      this.message.show(this);
    },
    updateData(dataType) {
      let deviceData, deviceFilteredData, inventoryData, inventoryFilteredData;
      const reportModel = this.reportModel;
      const getFilteredData = reportModel.getTechFilteredData;
      this.$(".msg").addClass("no-display");
      switch (dataType) {
        case "device":
          deviceData = reportModel.get("deviceData");
          if (deviceData !== undefined) {
            this.message.hide(this);
            deviceFilteredData = getFilteredData(deviceData, this.dimKey_device_type, this.filterMetric);
            reportModel.setDeviceFilterData(deviceFilteredData);
          }
          break;
        case "inventory":
          inventoryData = reportModel.get("inventoryData");
          if (inventoryData !== undefined) {
            this.message.hide(this);
            inventoryFilteredData = getFilteredData(inventoryData, this.dimKey_inventory_type, this.filterMetric);
            reportModel.setInventoryFilterData(inventoryFilteredData);
          }
          break;
      }
    },
    activateSelectbox() {
      const selectMetricUIs = this.$(".select-metric");
      T1.Selectbox(selectMetricUIs, {
        dropDownRightPosition: "14px",
        highlightInSearchResult: true,
        no_results_text: "No results found for",
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search",
        onChange: evt => {
          const select = $(evt.target);
          this.filterMetric = select.val();
          this.reportModel.setDeviceFilterData(undefined, true);
          this.reportModel.setInventoryFilterData(undefined, true);
          this.updateData("device");
          this.updateData("inventory");
          T1.EventHub.publish("select:metric2", this.filterMetric);
          this.load(true);
        }
      });
    },
    makeMetricOptions() {
      const metricOptions = $.extend(true, [], this.reportMetaObj.metricOptions);
      return this.metrics.map(metric => metricOptions.find(option => metric === option.value));
    },
    noDataCheck(e, chartID, dataState) {
      const $msg = this.$(".msg");
      const chartRenderState = this.chartRenderState;
      chartRenderState[chartID] = dataState;
      if (chartRenderState.device === false && chartRenderState.inventory === false) {
        $msg.removeClass("no-display");
      } else {
        $msg.addClass("no-display");
      }
    },
    serialize() {
      const metricOptions = this.makeMetricOptions();
      const metrics = metricOptions.map(option => {
        option.selected = option.value === this.filterMetric;
        return option;
      });
      return {
        "no-data-msg": "No data available for this selection",
        inChartFilters: true,
        metrics: metrics
      };
    }
  });
});
