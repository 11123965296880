define('modules/creatives/concept/views/assignModal',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "text!../templates/assignModalHold.html", "text!../templates/assignModal.html", "models/videoCreative", "models/videoCreativeDetail", "models/concept", "collections/creatives", "T1Form"], function ($, _, When, T1, T1View, T1Layout, tmpl, tmplsub, VideoCreative, VideoCreativeDetail, Concept, Creatives) {
  var assignModal;
  assignModal = T1View.extend({
    template: tmpl,
    loaderOpts: {
      target: ".view-loader",
      text: "Loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "assignModal:show": "show"
    },
    initialize: function (args) {
      this.orgId = args.orgId;
    },
    initLayout: function () {
      var self = this;
      var orgName = $(".org-name").text();
      var $creativeModal = $(".assign-creative-modal");
      self.layout = new T1Layout({
        el: self.el,
        selectorPath: ".body-content",
        template: tmplsub,
        layout: {
          ".browse": [{
            module: "creatives/concept",
            viewType: "creative_list",
            options: {
              sharedCollection: self.sharedCollection,
              orgId: self.orgId,
              advertiserID: self.advertiserID,
              conceptID: self.conceptID,
              isEdit: true
            }
          }],
          ".bins-column": [{
            module: "creatives/concept",
            viewType: "bins",
            options: {
              sharedCollection: self.sharedCollection,
              advertiserID: self.advertiserID
            }
          }]
        }
      });
      self.layout.load();
      $(".w-CreativeBin").show();
      $creativeModal.find(".show-head").show();
      $creativeModal.find(".agency-name").text(orgName);
      $creativeModal.find(".advertiser-name").text(self.advertiser);
      $creativeModal.find(".sub-head").text(self.name);
    },
    load: function () {
      this.renderView();
    },
    renderView: function () {
      var self = this;
      this.render().then(function () {
        self.modal = self.el;
        self.modal.dialog({
          autoOpen: false,
          modal: true,
          dialogClass: "w-customAlert assign-creative-modal",
          buttons: [{
            text: "Save & Close",
            class: "save",
            click: $.proxy(self.saveAndClose, self)
          }, {
            text: "Cancel",
            class: "cancel",
            click: $.proxy(self.hide, self)
          }]
        });
      });
    },
    unload: function () {
      if (this.modal) {
        this.modal.remove();
        this.modal.dialog("destroy");
        this.modal = null;
      }
    },
    show: function (data) {
      var self = this;
      $(".save-action").removeClass("save-action").addClass("save");
      $(".save").find(".spinner").remove();
      this.theloader = self.loader();
      this.name = data.model.get("name");
      this.advertiser = data.model.get("advertiser").name;
      this.conceptID = data.model.id;
      this.advertiserID = data.advertiserID;
      this.sharedCollection = new Creatives(null, {
        pageLimit: 100
      });
      this.sharedCollection.canCache = false;
      this.sharedCollection.isPaginated = false;
      this.sharedCollection.urlFilter.set({
        entity: "concept",
        id: "(" + this.conceptID + ")"
      });
      this.sharedCollection.fetch({
        params: {
          full: "*",
          with: ["advertiser", "concept"]
        },
        success: function (cdata) {
          cdata.each(function (model) {
            model.set({
              assigned: "1"
            });
            model.set({
              original: "true"
            });
          });
          self.theloader.stop();
          self.el.parent().find(".ui-dialog-buttonpane .save").attr("disabled", false);
          self.el.parent().find(".ui-dialog-buttonpane .save").removeClass("disabled");
          self.initLayout();
        }
      });
      this.theloader.start();
      self.el.parent().find(".ui-dialog-buttonpane .save").attr("disabled", "disabled");
      self.el.parent().find(".ui-dialog-buttonpane .save").addClass("disabled");
      self.modal.dialog("open");
      document.activeElement.blur();
    },
    hide: function () {
      var $creativeModal = $(".assign-creative-modal");
      var $bodyContent = $creativeModal.find(".body-content");
      var isFormElementsChanged, layoutView;
      if (this.layout) {
        layoutView = this.layout.layout[".bins-column"][0].view;
        isFormElementsChanged = layoutView.isFormElementsChanged;
      }
      if (isFormElementsChanged) {
        this.showDialog(isFormElementsChanged);
      } else {
        this.theloader.stop();
        this.modal.dialog("close");
        this.layout.unload();
        $(".w-FormUnloadAlert").dialog("destroy").remove();
        $creativeModal.find(".show-head").hide();
        $bodyContent.empty();
        $bodyContent.append('<div class="view-loader"></div>');
      }
    },
    showDialog: function (isFormElementsChanged) {
      var self = this;
      var cancelConceptEdit = {
        onAlertClose: function () {
          T1.Location.set("#creatives/concepts");
          self.layout.layout[".bins-column"][0].view.isFormElementsChanged = false;
          self.hide();
        }
      };
      if (!isFormElementsChanged) {
        self.hide();
      } else {
        self.openUnloadAlertDialog({
          name: "Creative to Concept Assignment"
        }, cancelConceptEdit);
      }
    },
    postData: function () {
      this.saveAndClose();
    },
    saveAndClose: function () {
      var self = this;
      var queue = [];
      var modelValue;
      var deferred = When.defer();
      self.isFormElementsChanged = false;
      self.el.parent().find(".ui-dialog-buttonpane .save").attr("disabled", "disabled");
      self.el.parent().find(".ui-dialog-buttonpane .save").addClass("disabled");
      this.sharedCollection.each(function (model) {
        function saveVideo(conceptId) {
          var outputModel = new VideoCreativeDetail({
            id: model.id
          });
          var vc = new VideoCreative({
            id: model.id
          });
          var saveOpts = {
            dataType: "json",
            stringify: true,
            contentType: "application/json",
            processData: false,
            success: function () {
              deferred.resolve();
            },
            conflict: function () {
              deferred.reject();
            },
            processAjaxResponse: outputModel.processAjaxResponse
          };
          vc.sync("read", {
            id: model.id
          }, {
            onSuccess: function (data) {
              var details = data.details;
              if (details.customVAST) {
                outputModel.set({
                  customVAST: details.customVAST
                });
              } else if (details.customVASTUrl) {
                outputModel.set({
                  customVASTUrl: details.customVASTUrl
                });
              } else {
                outputModel.set({
                  clickthroughUrl: details.clickthroughUrl
                });
              }
              outputModel.set({
                name: details.name,
                advertiser: details.advertiser,
                concept: conceptId,
                landingUrl: details.landingUrl,
                startTime: details.startTime,
                endTime: details.endTime,
                active: details.active
              });
              outputModel.save(outputModel.toJSON(), saveOpts);
            },
            errorDisplaySuppressingConfig: {
              errorCodes: [404, 502, 503]
            },
            dataType: "json",
            processAjaxResponse: function (resp) {
              return {
                jsonData: resp,
                statusCode: resp.status.code || "ok"
              };
            }
          });
        }
        if (model.get("assigned") === "1") {
          modelValue = {
            concept_id: self.conceptID
          };
        } else {
          modelValue = {
            concept_id: ""
          };
        }
        if (model.get("media_type") === "video" && model.get("assigned")) {
          if (model.get("assigned") === "0") {
            self.conceptModel = new Concept({
              action: "new"
            });
            self.conceptModel.save({
              name: model.get("name"),
              status: "1",
              advertiser_id: self.advertiserID
            }, {
              success: $.proxy(function () {
                deferred.resolve(self.conceptModel);
                saveVideo(self.conceptModel.id);
              })
            });
            queue.push(deferred.promise);
          } else {
            saveVideo(self.conceptID);
          }
        } else {
          model.save(modelValue, {
            success: function () {
              deferred.resolve();
            },
            conflict: function () {
              deferred.reject();
            }
          });
          queue.push(deferred.promise);
        }
      });
      When.all(queue).then(function () {
        T1.Notify("message", "Creative(s) assigned/unassigned successfully!");
        self.el.parent().find(".ui-dialog-buttonpane .save").attr("disabled", false);
        self.el.parent().find(".ui-dialog-buttonpane .save").removeClass("disabled");
        self.layout.layout[".bins-column"][0].view.isFormElementsChanged = false;
        self.hide();
        T1.EventHub.publish("assignModal:saved");
      });
      return deferred.promise;
    }
  });
  return T1.Form.ViewUtils.extend(assignModal);
});
