define('modules/reporting/segments/dataExport/manage/views/manage',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/manage.html"], function ($, _, T1, T1View, T1Layout, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {},
    eventhubEvents: {},
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    initLayouts: function initLayouts() {
      var self = this;
      this.layout = new T1Layout({
        el: function el() {
          return self.el;
        },
        template: template,
        layout: {
          ".header": [{
            module: "reporting/segments/dataExport/manage",
            viewType: "header",
            options: DataExportModel
          }],
          ".grid": [{
            module: "reporting/segments/dataExport/manage",
            viewType: "grid",
            options: DataExportModel
          }]
        }
      });
    },
    load: function load() {
      var self = this;
      this.initLayouts();
      this.render().then(function render() {
        self.layout.load();
      });
    },
    unload: function unload() {
      DataExportModel = null;
    }
  });
});
