define('modules/strategy/views/helpers/creativeSummaryTarget',["Underscore", "jQuery"], function (_, $) {
  "use strict";

  return {
    prepareForSerialize: function (strategy, collection) {
      var collObj = collection;
      var resultJSON = {
        data: []
      };
      var dataArray = [];
      if (!collObj || !collObj.models || !collObj.models.length) {
        return resultJSON;
      }
      $.each(collObj.models, function (index, model) {
        var action = model.get("action");
        var tmpObj = model.toJSON();
        dataArray.push({
          action: action,
          label: tmpObj.name
        });
      });
      const tempData = dataArray;
      if (tempData.length) {
        resultJSON.data.push({
          items: tempData,
          header: "Concepts"
        });
      }
      return resultJSON;
    }
  };
});
