define('modules/nav/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  return new T1Module({
    name: "nav",
    viewCfgs: {
      list: {
        name: "sections",
        sections: []
      },
      breadcrumb: {}
    },
    defaultView: "list"
  });
});
