define('modules/reporting/campaigns/dataExport/createEdit/views/createEdit',['require','jQuery','Underscore','collections/advertisers','collections/agencies','collections/campaigns','../models/filterBinModel','../models/filterGridModel','collections/organizations','collections/pixels','T1','T1Layout','T1UtilsAsync','T1View','text!../templates/createEdit.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const Advertisers = require("collections/advertisers");
  const Agencies = require("collections/agencies");
  const Campaigns = require("collections/campaigns");
  const ModelFilterBin = require("../models/filterBinModel");
  const ModelFilterGrid = require("../models/filterGridModel");
  const Organizations = require("collections/organizations");
  const Pixels = require("collections/pixels");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1UtilsAsync = require("T1UtilsAsync");
  const T1View = require("T1View");
  const template = require("text!../templates/createEdit.html");
  const apiLimit = 100;
  const T1Publish = T1.EventHub.publish;
  const T1Location = T1.Location;
  return T1View.extend({
    searchValueCleared: false,
    EntityCollection: {},
    SearchCollection: {},
    dataEvents: {
      DataExportModel: {
        "change:attribution": function () {
          T1Publish("createEdit.attributionChange");
        },
        "change:reportInfo": function () {
          T1Publish("createEdit.reportInfoChange");
        },
        "change:selectedReport": function () {
          function reset() {
            this.FilterBinModel.setBinData([]);
            T1Publish("createEdit.clearSearch");
            if (!this.searchValueCleared) {
              this.resetSearch();
            }
            this.searchValueCleared = false;
          }
          const selectedReport = this.DataExportModel.get("selectedReport");
          const prevSelectedReport = this.DataExportModel.get("prevSelectedReport");
          const pixelReports = ["Audience Index Pixel", "Event Pixel Loads", "Data Pixel Loads"];
          if (pixelReports.includes(selectedReport)) {
            T1Publish("createEdit.updateSearchDDL", {
              entity: "Pixel",
              label: selectedReport
            });
            reset.call(this);
          } else if (pixelReports.includes(prevSelectedReport)) {
            T1Publish("createEdit.updateSearchDDL", {
              entity: "Campaign",
              label: selectedReport
            });
            reset.call(this);
          }
          if (selectedReport === "Performance" || selectedReport === "Performance Ecosystem Cost") {
            this.toggleAttributionGroupSelector(true);
          } else {
            this.toggleAttributionGroupSelector(false);
          }
          this.loadMetaData();
        },
        "change:fileName": function () {
          T1Publish("createEdit.updateFileName");
        }
      },
      FilterGridModel: {
        "change:gridData": function () {
          T1Publish("createEdit.updateGridItems", {
            search: false
          });
        },
        "change:searchData": function () {
          T1Publish("createEdit.updateGridItems", {
            search: true
          });
        },
        "update:binData": function () {
          this.FilterBinModel.updateData();
        },
        "update:selectAllBtn": function () {
          T1Publish("update.selectAllBtn");
        },
        "select:all": function () {
          this.FilterBinModel.selectAll();
        },
        "deselect:all": function () {
          this.FilterBinModel.deselectAll();
        }
      },
      FilterBinModel: {
        "change:binData": function () {
          T1Publish("createEdit.updateBinGrid");
        },
        "deselect:items": function (ids) {
          this.FilterGridModel.deselectItems(ids);
        },
        "deselect:all": function () {
          this.FilterGridModel.deselectAll();
        }
      }
    },
    eventhubEvents: {
      "filter.searchData": "loadSearch",
      "filter.resetSearch": "resetSearch",
      "filter.searchInput": function () {
        this.searchValueCleared = true;
      },
      "filterGrid.loadData": "loadEntities",
      "load:metaData": "metaDataLoad",
      "load:savedData": "savedEntityLoad",
      "attributionGroup.toggleInfo": function (selection) {
        this.toggleAttributionGroupInfo(selection);
      }
    },
    initialize(cfgs) {
      const orgID = Organizations.getOrganizations().selected;
      const self = this;
      const requiredEntitiyViews = 2;
      const requiredMetaViews = 3;
      this.cfgs = cfgs;
      this.orgID = orgID;
      this.savedEntityLoad = _.after(requiredEntitiyViews, function () {
        self.loadSavedEntities(self.existingReport);
      });
      this.metaDataLoad = _.after(requiredMetaViews, function () {
        self.loadMetaData();
      });
      this.SaveReportModel = cfgs.saveReportModel;
      this.DataExportModel = cfgs.dataExportModel;
      this.FilterGridModel = new ModelFilterGrid([], {
        sharedModel: this.DataExportModel
      });
      this.FilterBinModel = new ModelFilterBin([], {
        sharedModel: this.DataExportModel
      });
      this.AgencyCollection = new Agencies();
      this.AdvertiserCollection = new Advertisers([], {
        filterConfiguration: {
          enableEvents: false,
          filters: [{
            entity: "agency",
            fetchOnChange: false
          }]
        }
      });
      this.CampaignsCollection = new Campaigns([], {
        filterConfiguration: {
          filters: [{
            entity: "advertiser",
            fetchOnChange: false
          }]
        }
      });
      this.PixelsCollection = new Pixels([], {
        filterConfiguration: {
          filters: [{
            entity: "advertiser",
            fetchOnChange: false
          }]
        }
      });
    },
    initLayouts() {
      const module = "reporting/campaigns/dataExport/createEdit";
      const DataExportModel = this.DataExportModel;
      const selectedReport = DataExportModel.getSelectedReport();
      this.layout = new T1Layout({
        el: () => this.el,
        template: template,
        layout: {
          ".file-name": [{
            module: module,
            options: DataExportModel,
            viewType: "fileName"
          }],
          ".file-type": [{
            module: module,
            options: DataExportModel,
            viewType: "fileType"
          }],
          ".report-type": [{
            module: module,
            options: DataExportModel,
            viewType: "reportType"
          }],
          ".date-range": [{
            module: module,
            options: DataExportModel,
            viewType: "dateRange"
          }],
          ".filter": [{
            module: module,
            viewType: "filter",
            options: {
              binModel: this.FilterBinModel,
              gridModel: this.FilterGridModel,
              model: DataExportModel
            }
          }],
          ".dimensions-metrics": [{
            module: module,
            options: DataExportModel,
            viewType: "dimensionsMetrics"
          }],
          ".email-settings": [{
            module: module,
            options: DataExportModel,
            viewType: "emailSettings"
          }],
          ".footer": [{
            module: module,
            options: DataExportModel,
            viewType: "footer"
          }]
        }
      });
      if (selectedReport === "Performance" || selectedReport === "Performance Ecosystem Cost") {
        this.layout.layout[".attribution-group"] = [{
          module: module,
          options: DataExportModel,
          viewType: "attributionGroup"
        }];
      }
    },
    load() {
      let existingReport;
      const currentLocation = T1Location.get(true);
      if (currentLocation.includes("edit")) {
        const defaultLocation = "#reports/campaigns/dataExport";
        const [,,,, editID] = currentLocation.split("/");
        const savedReports = this.DataExportModel.get("savedReports");
        existingReport = savedReports.find(report => report.id === editID);
        if (!existingReport) {
          T1Publish("update:view", {
            location: defaultLocation,
            target: "manage"
          });
          return;
        }
      }
      if (existingReport) {
        this.DataExportModel.setEditReport(existingReport);
        this.existingReport = existingReport;
        this.SaveReportModel.id = existingReport.id;
      } else {
        this.SaveReportModel.id = null;
      }
      this.initLayouts();
      this.render().then(() => {
        this.el.css("visibility", "visible");
        this.el.addClass("fade-in");
        T1Publish("hide:manageLoader");
        this.layout.load().then(() => {
          this.$attrGrp = this.$(".attribution-group");
          this.$attrGrpInfo = this.$(".attribution-group-info");
          const selectedReport = this.DataExportModel.getSelectedReport();
          this.toggleAttributionGroupSelector(false);
          if (selectedReport === "Performance" || selectedReport === "Performance Ecosystem Cost") {
            this.toggleAttributionGroupSelector(true);
          }
        });
      });
    },
    unload() {
      this.abortAjaxCalls();
      this.AdvertiserCollection = null;
      this.AgencyCollection = null;
      this.CampaignsCollection = null;
      this.cfgs = null;
      this.EntityCollection = null;
      this.orgID = null;
      this.PixelsCollection = null;
      this.SavedEntityCollections = null;
      this.DataExportModel.set({
        binChangeItems: [],
        binItems: null,
        calendarRange: null,
        customDate: null,
        dateBasedType: null,
        editReport: null,
        emailAMPM: null,
        emailFrequencyGrain: null,
        emailFromAddress: null,
        emailMessage: null,
        emailMonthlyValue: null,
        emailStartEndDate: null,
        emailSubject: null,
        emailTime: null,
        emailTimezone: null,
        emailToAddresses: null,
        emailWeeklyValue: null,
        fileName: null,
        fileType: null,
        gridItems: null,
        prevSelectedReport: null,
        reportInfo: {},
        selectedReport: null,
        timeRollup: null,
        timeWindow: null,
        timeWindowUnit: null
      }, {
        silent: true
      });
      this.$attrGrp = null;
      this.$attrGrpInfo = null;
      $(window).off("scroll.dataExport");
    },
    abortAjaxCalls() {
      this.DataExportModel.abort();
      if (this.EntityCollection && this.EntityCollection.currentFetch) {
        this.EntityCollection.currentFetch.abort();
      }
      if (this.SearchCollection && this.SearchCollection.currentFetch) {
        this.SearchCollection.currentFetch.abort();
      }
      if (this.SavedEntityCollections) {
        if (this.SavedEntityCollections.agency && this.SavedEntityCollections.agency.collection.currentFetch) {
          this.SavedEntityCollections.agency.collection.currentFetch.abort();
        }
        if (this.SavedEntityCollections.advertiser && this.SavedEntityCollections.advertiser.collection.currentFetch) {
          this.SavedEntityCollections.advertiser.collection.currentFetch.abort();
        }
        if (this.SavedEntityCollections.campaign && this.SavedEntityCollections.campaign.collection.currentFetch) {
          this.SavedEntityCollections.campaign.collection.currentFetch.abort();
        }
      }
    },
    loadMetaData() {
      this.DataExportModel.fetchMetadata();
    },
    loadEntities() {
      let collection;
      const FilterGridModel = this.FilterGridModel;
      const filterEntity = FilterGridModel.getFilterEntity();
      const clickItem = FilterGridModel.get("clickItem");
      const selectedReport = this.DataExportModel.getSelectedReport();
      switch (filterEntity) {
        case "agency":
          collection = this.AgencyCollection;
          collection.urlFilter.set({
            entity: "organization",
            id: this.orgID
          });
          break;
        case "advertiser":
          collection = this.AdvertiserCollection;
          collection.urlFilter.set({
            entity: "agency",
            id: clickItem.id
          });
          break;
        case "campaign":
          collection = this.CampaignsCollection;
          collection.urlFilter.set({
            entity: "advertiser",
            id: clickItem.id
          });
          break;
        case "pixel":
          collection = this.PixelsCollection;
          collection.noUniquesData = true;
          if (selectedReport === "Data Pixel Loads") {
            collection.urlFilter.setConfiguration([{
              entity: "agency",
              fetchOnChange: false
            }]);
            collection.urlFilter.set({
              entity: "agency",
              id: clickItem.id
            });
          } else {
            collection.urlFilter.setConfiguration([{
              entity: "advertiser",
              fetchOnChange: false
            }]);
            collection.urlFilter.set({
              entity: "advertiser",
              id: clickItem.id
            });
          }
          break;
      }
      collection.canCache = false;
      collection.entityType = filterEntity.toUpperCase();
      collection.fetchOptions = {
        sort_by: "-status,name"
      };
      collection.isPaginated = false;
      collection.page = 0;
      collection.pageLimit = apiLimit;
      this.EntityCollection = collection;
      if (filterEntity === "pixel") {
        if (selectedReport === "Data Pixel Loads") {
          this.EntityCollection.search.set({
            fetchRequired: false,
            params: [{
              term: "data",
              searchField: "pixel_type"
            }]
          });
        } else {
          this.EntityCollection.search.set({
            fetchRequired: false,
            params: [{
              term: "event",
              searchField: "pixel_type"
            }]
          });
        }
      }
      this.EntityCollection.fetch({
        success: function (response) {
          FilterGridModel.addGridData(response.models);
        }
      });
    },
    loadSavedEntities(report) {
      let filterEntities = [];
      const self = this;
      const {
        DataExportModel: DataExportModel
      } = this;
      const dataLoads = [];
      const promises = [];
      const additionalFilters = DataExportModel.getAdditionalFilterTypes();
      const selectedReport = DataExportModel.getSelectedReport();
      const selectedAttribution = DataExportModel.getSelectedAttribution();
      const isAttributionReport = selectedReport === "Performance" || selectedReport === "Performance Ecosystem Cost";
      this.SavedEntityCollections = {};
      if (report.definition.filter) {
        switch (true) {
          case selectedReport === "Audience Index Pixel":
            filterEntities = _.initial(report.definition.filter.split("&"));
            break;
          case isAttributionReport:
            if (selectedAttribution !== "All Attribution Groups") {
              filterEntities = _.initial(report.definition.filter.split("&"));
            } else {
              filterEntities = report.definition.filter.split("&");
            }
            break;
          default:
            filterEntities = report.definition.filter.split("&");
        }
      }
      for (const entityStr of filterEntities) {
        let collection, parentEntity;
        const entity = entityStr.split("=")[0].replace("_id", "");
        const ids = entityStr.split("=")[1];
        const dataLoad = T1UtilsAsync.makeDeferral();
        dataLoad.entity = entity;
        dataLoads.push(dataLoad);
        promises.push(dataLoad.promise);
        if (entity === "organization") {
          dataLoad.models = Organizations.getOrganizations().get(self.orgID);
          dataLoad.resolve();
        } else if (additionalFilters.includes(entity)) {
          dataLoad.resolve();
        } else {
          switch (entity) {
            case "agency":
              parentEntity = "";
              collection = new Agencies();
              break;
            case "advertiser":
              parentEntity = "agency";
              collection = new Advertisers();
              break;
            case "campaign":
              parentEntity = "advertiser,agency";
              collection = new Campaigns();
              break;
            case "pixel":
              if (selectedReport === "Data Pixel Loads") {
                parentEntity = "agency";
              } else {
                parentEntity = "advertiser,agency";
              }
              collection = new Pixels();
              collection.noUniquesData = true;
              break;
          }
          collection.canCache = true;
          collection.entityType = entity.toUpperCase();
          collection.fetchOptions = {
            sort_by: "-status,name",
            with: parentEntity
          };
          collection.isPaginated = false;
          collection.page = 0;
          collection.pageLimit = apiLimit;
          T1Publish("createEdit.showLoader");
          self.SavedEntityCollections[entity] = {
            collection: collection
          };
          self.SavedEntityCollections[entity].collection.search.set({
            idList: ids
          });
          self.SavedEntityCollections[entity].collection.fetch({
            success: function ({
              models: models
            }) {
              dataLoad.models = models;
              dataLoad.resolve();
            }
          });
        }
      }
      Promise.all(promises).then(function () {
        report.entityData = {};
        for (const item of dataLoads) {
          report.entityData[item.entity] = item.models;
        }
        self.DataExportModel.setEditReportPart2(report);
        self.FilterBinModel.updateData();
        self.loadEntities();
      });
    },
    loadSearch(searchTerm) {
      let collection;
      const FilterGridModel = this.FilterGridModel;
      const searchEntity = FilterGridModel.getSearchEntity();
      const selectedReport = this.DataExportModel.getSelectedReport();
      switch (searchEntity) {
        case "agency":
          collection = this.AgencyCollection;
          break;
        case "advertiser":
          collection = this.AdvertiserCollection;
          collection.fetchOptions = {
            with: "agency"
          };
          collection.urlFilter.setConfiguration([{
            entity: "agency.organization",
            fetchOnChange: false
          }]);
          collection.urlFilter.set({
            entity: "agency.organization",
            id: this.orgID
          });
          break;
        case "campaign":
          collection = this.CampaignsCollection;
          collection.fetchOptions = {
            with: ["advertiser,agency"]
          };
          collection.urlFilter.setConfiguration([{
            entity: "advertiser.agency.organization",
            fetchOnChange: false
          }]);
          collection.urlFilter.set({
            entity: "advertiser.agency.organization",
            id: this.orgID
          });
          break;
        case "pixel":
          collection = this.PixelsCollection;
          collection.noUniquesData = true;
          if (selectedReport === "Data Pixel Loads") {
            collection.fetchOptions = {
              with: "agency",
              q: "pixel_type==data"
            };
            collection.urlFilter.setConfiguration([{
              entity: "agency.organization",
              fetchOnChange: false
            }]);
            collection.urlFilter.set({
              entity: "agency.organization",
              id: this.orgID
            });
          } else {
            collection.fetchOptions = {
              with: ["advertiser,agency"],
              q: "pixel_type==event"
            };
            collection.urlFilter.setConfiguration([{
              entity: "advertiser.agency.organization",
              fetchOnChange: false
            }]);
            collection.urlFilter.set({
              entity: "advertiser.agency.organization",
              id: this.orgID
            });
          }
          break;
      }
      collection.canCache = false;
      collection.entityType = searchEntity.toUpperCase();
      collection.isPaginated = false;
      collection.page = 0;
      collection.pageLimit = apiLimit;
      $.extend(collection.fetchOptions, {
        sort_by: "-status,name"
      });
      T1Publish("createEdit.showLoader");
      this.SearchCollection = collection;
      if (searchEntity === "pixel") {
        if (selectedReport === "Data Pixel Loads") {
          this.SearchCollection.search.set({
            fetchRequired: false,
            params: [{
              term: "data",
              searchField: "pixel_type"
            }, {
              term: searchTerm,
              searchField: "name|id"
            }]
          });
        } else {
          this.SearchCollection.search.set({
            fetchRequired: false,
            params: [{
              term: "event",
              searchField: "pixel_type"
            }, {
              term: searchTerm,
              searchField: "name|id"
            }]
          });
        }
        this.SearchCollection.fetch({
          success: function (response) {
            FilterGridModel.addSearchData(response.models);
          }
        });
      } else {
        this.SearchCollection.filterBy("name|id", searchTerm, function (response) {
          FilterGridModel.addSearchData(response.models);
        });
      }
    },
    resetSearch() {
      if (this.SearchCollection.currentFetch) {
        this.SearchCollection.currentFetch.abort();
      }
      this.resetCollections();
      this.FilterGridModel.set({
        clickItem: null,
        filterEntity: "agency",
        gridData: [],
        searchData: []
      });
      this.DataExportModel.set({
        gridItems: []
      });
      T1Publish("createEdit.showLoader");
      this.loadEntities();
    },
    resetCollections() {
      this.AgencyCollection.reset();
      this.AgencyCollection.search.clear();
      this.AdvertiserCollection.reset();
      this.AdvertiserCollection.search.clear();
      this.AdvertiserCollection.urlFilter.setConfiguration([{
        entity: "agency",
        fetchOnChange: false
      }]);
      this.CampaignsCollection.reset();
      this.CampaignsCollection.search.clear();
      this.CampaignsCollection.urlFilter.setConfiguration([{
        entity: "advertiser",
        fetchOnChange: false
      }]);
      this.PixelsCollection.reset();
      this.PixelsCollection.search.clear();
      this.PixelsCollection.urlFilter.setConfiguration([{
        entity: "advertiser",
        fetchOnChange: false
      }]);
    },
    toggleAttributionGroupSelector(show) {
      if (show) {
        this.$attrGrp.removeClass("no-display");
        this.toggleAttributionGroupInfo(this.DataExportModel.getSelectedAttribution());
      } else {
        this.$attrGrp.addClass("no-display");
        this.toggleAttributionGroupInfo("");
      }
    },
    toggleAttributionGroupInfo(attribution) {
      if (attribution === "All Attribution Groups") {
        this.$attrGrpInfo.removeClass("no-display");
      } else {
        this.$attrGrpInfo.addClass("no-display");
      }
    }
  });
});
