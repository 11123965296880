define('modules/creatives/bulkedit/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  return new T1Module({
    name: "creatives/bulkedit",
    viewCfgs: {
      dialog: {},
      edit: {},
      review: {},
      assignment: {},
      entityGrid: {},
      changeGrid: {}
    },
    defaultView: "dialog"
  });
});
