define('collections/contracts',["jQuery", "T1", "T1Collection", "models/contract", "utils/ContractUtils"], function ($, T1, T1Collection, Contract, ContractUtils) {
  "use strict";

  T1.Models.Contracts = T1Collection.extend({
    canCache: true,
    cacheDuration: 18e5,
    fetchOptions: {
      full: "*",
      currency_code: "USD"
    },
    fixture: "-contracts",
    model: Contract,
    filterConfiguration: {
      filters: [{
        entity: "organization",
        fetchOnChange: false
      }],
      enableEvents: true
    },
    initialize: function (options) {
      T1Collection.prototype.initialize.apply(this, arguments);
      if (options) {
        this.fetchOptions.currency_code = options.currency_code;
      }
    },
    getHealth: function () {
      var healthDeferred = $.Deferred();
      this.getActiveContract().done(function (collection) {
        var result = {};
        if (collection.length) {
          result.foundActive = true;
          result.expiryDate = collection.at(0).get("effective_end_date");
        }
        healthDeferred.resolve(result);
      });
      return healthDeferred;
    },
    getActiveContract: function (startDate, endDate) {
      var self = this;
      var dateFormat = "yyyy-MM-dd";
      var today = ContractUtils.formatDate(new Date(), dateFormat);
      var start = startDate ? ContractUtils.formatDate(startDate, dateFormat) : today;
      var end = endDate ? ContractUtils.formatDate(endDate, dateFormat) : today;
      var searchParam = "effective_start_date<=" + end + "&" + "effective_end_date>=" + start;
      var deferredFetch = $.Deferred();
      self.fetchOptions.q = searchParam;
      self.fetch({
        success: function () {
          deferredFetch.resolve(self);
        }
      });
      return deferredFetch;
    },
    urlString: function () {
      return "contracts";
    }
  });
  return T1.Models.Contracts;
});
