define('modules/campaign/bulkedit/review/views/review',["jQuery", "T1", "T1View", "T1Layout", "text!../templates/review.html", "text!templates/alert.html", "collections/strategies", "utils/CampaignStrategyUtils"], function ($, T1, T1View, T1Layout, template, alertDialogTemplate, Strategies, CampaignStrategyUtils) {
  "use strict";

  const {
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var readOnlyView = T1View.extend({
    template: template,
    partials: {
      alertDialog: alertDialogTemplate
    },
    formConfig: {
      bulkedit: true,
      bulkeditReview: true
    },
    eventhubEvents: {
      "bulkEdit:FinishedExecution": "executionFinishedSuccess",
      "bulkEdit:ErroredExecution": "executionFinishedError"
    },
    events: {},
    initialize: function ({
      bulkeditor: bulkeditor
    }) {
      this.bulkeditor = bulkeditor;
      this.bulkeditorExecuting = "ready";
      this.strategyCollection = new Strategies();
      if (bulkeditor.get("state") === "closed" || bulkeditor.get("editor") === "closed") {
        this.bulkeditorExecuting = "success";
        if (bulkeditor.get("executionErrors") && bulkeditor.get("executionErrors").length) {
          this.bulkeditorExecuting = "error";
        }
      }
      this.bulkeditorReady = false;
      this.checkBulkeditorReady();
    },
    load: function () {
      const bulkeditor = this.bulkeditor;
      this.render().then(() => {
        if (this.bulkeditorReady) {
          this.bulkeditorClosed = bulkeditor.get("state") === "closed" || bulkeditor.get("editor") === "closed";
          if (this.bulkeditorClosed) {
            this.disableActions();
            if (bulkeditor.get("executionErrors") && bulkeditor.get("executionErrors").length) {
              T1.EventHub.publish("update:footerMessage", {
                warning: true,
                message: "Finished but some strategies were not updated."
              });
            } else {
              T1.EventHub.publish("update:footerMessage", {
                message: "Bulk edits executed successfully!",
                success: true
              });
            }
          } else {
            if (bulkeditor.get("state") === "pending" && bulkeditor.get("editor_status") !== "closed") {
              this.disableActions();
              T1.EventHub.publish("bulkEdit:Executing");
              T1.Notify("warning", "Bulk edits are being executed!");
              bulkeditor.checkExecutionStatus();
            }
          }
          this.loadSections();
          T1.EventHub.publish("bulkEdit:hideLoadingIcon");
          if (this.executionError) {
            this.disableActions();
            T1.EventHub.publish("update:footerMessage", {
              warning: true,
              message: "Finished but some strategies were not updated."
            });
          }
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn();
        }
      });
    },
    reload: function () {
      this.bulkeditorReady = false;
      this.checkBulkeditorReady();
    },
    checkBulkeditorReady: function () {
      if (this.bulkeditor.editorReady) {
        this.bulkeditorReady = true;
      } else {
        this.bulkeditor.getStatus({
          success: () => {
            this.bulkeditorReady = true;
            this.load();
          }
        });
      }
    },
    loadSections: function () {
      const readOnlyViews = ["w-ReadonlyReviewActions"];
      const layoutObj = {
        ".w-ReadonlyReviewActions": [{
          module: "campaign/bulkedit/review",
          viewType: "readOnly",
          options: {
            executionStatus: this.bulkeditorExecuting,
            bulkeditor: this.bulkeditor
          }
        }]
      };
      if (this.bulkeditorExecuting === "error") {
        readOnlyViews.push("w-ReadonlyStrategies");
        layoutObj[".w-ReadonlyStrategies"] = [{
          module: "campaign/bulkedit/strategies",
          viewType: "readOnly",
          options: {
            executionStatus: this.bulkeditorExecuting,
            bulkeditor: this.bulkeditor
          }
        }];
      }
      this.layout = new T1Layout({
        el: () => this.$(".sections-container"),
        template: '{{#readOnlyViews}}<div class="{{.}}"></div><div class="spacer" ></div>{{/readOnlyViews}}',
        layout: layoutObj,
        serialize: function () {
          return {
            readOnlyViews: readOnlyViews
          };
        }
      });
      this.layout.load();
    },
    execute: function (successCallback, invalidCallback, confict, cancel) {
      var self = this;
      var alertDialog = $("<div></div>");
      this.renderPartialTemplate({
        template: this.partials.alertDialog,
        data: {
          header: "Confirm Bulk Edits",
          message: `Changes applied to strategies cannot be undone. Review changes carefully before\n            proceeding. <span style="font-weight:bold;font-style:italic;">Updating strategies may take a few minutes.\n            Please remain on this page or changes will be lost.</span>`
        },
        useTemplateHtml: false,
        targetEl: alertDialog
      });
      this.executionDialog = alertDialog.dialog({
        autoOpen: true,
        modal: true,
        dialogClass: "w-WarningAlert",
        buttons: [{
          text: "Save Edits",
          class: "save",
          click: function (e) {
            const $btn = $(e.currentTarget);
            self.startSpinner($btn);
            T1.EventHub.publish("bulkEdit:Executing");
            self.bulkeditor.executeBulkedit({
              success: function () {
                T1.Notify("warning", "Bulk edits are being executed!");
                self.bulkeditor.checkExecutionStatus(successCallback, invalidCallback);
              }
            });
          }
        }, {
          text: "Cancel",
          class: "cancel",
          click: function () {
            $(this).dialog("close");
            cancel();
          }
        }],
        close: function () {
          $(this).remove();
        }
      });
    },
    executionFinishedSuccess: function () {
      if (this.executionDialog) {
        this.executionDialog.remove();
      }
      this.bulkeditorExecuting = "success";
      T1.Notify("message", "Bulk edits have been executed successfully!");
      T1.EventHub.publish("update:footerMessage", {
        message: "Bulk edits executed successfully!",
        success: true
      });
      this.disableStrategiesLink();
    },
    unload: function () {
      T1.EventHub.publish("bulkEdit:showLoadingIcon");
    },
    executionFinishedError: function () {
      if (this.executionDialog) {
        this.executionDialog.remove();
      }
      this.bulkeditorExecuting = "error";
      T1.Notify("warning", "Some strategies did not update");
      this.executionError = true;
      this.load();
    },
    disableStrategiesLink: function () {
      this.$(".strategies-link").removeClass("strategies-link");
    },
    serialize: function () {
      return {
        strategies: this.bulkeditor.get("stagedStrategies"),
        bulkeditorClosed: this.bulkeditorClosed
      };
    }
  });
  return T1.Form.ViewUtils.extend(readOnlyView);
});
