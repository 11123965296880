define('modules/creatives/video/models/creativeActive',["jQuery"], function ($) {
  return {
    active: ["All", "Active", "Inactive"],
    toOptionsList: function (list, selection) {
      var i;
      var output = [];
      for (i = 0; i < list.length; i++) {
        output.push({
          text: list[i],
          value: i,
          selected: list[i] === selection
        });
      }
      return output;
    }
  };
});
