define('modules/T1App/edit/team/views/createEdit',["jQuery", "Underscore", "T1", "T1Layout", "T1View", "T1Comm", "text!modules/T1App/edit/team/templates/createEdit.html", "text!templates/form_footer.html", "../../models/teamModel", "collections/t1AppDevTeam", "collections/appsT1Users", "collections/t1AppEdit", "T1Model", "T1Signature"], function ($, _, T1, T1Layout, T1View, T1Comm, template, form_footer, StateModel, T1AppDevTeam, AppsT1Users, T1AppEdit) {
  "use strict";

  var TeamCreateEdit;
  var T1Location = T1.Location;
  TeamCreateEdit = T1View.extend({
    template: template,
    partials: {
      footer: form_footer
    },
    fetchOptions: {},
    eventhubEvents: {
      "change:formElements": "updateFormElementsChanged"
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveOverride"
      },
      formActions: [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#appstore/myApps"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: false
      }, {
        label: "Save & continue",
        uuid: "save_close",
        class: "saveAndClose save-btn-primary",
        location: false
      }]
    },
    initialize: function () {
      var locationHashArray = T1Location.get(true).split("/");
      var appID = T1.Utils.escapeHTML(locationHashArray[2]);
      this.showAccessTab = false;
      this.appID = appID;
      this.t1AppDevTeamColl = new T1AppDevTeam();
      this.T1AppEditColl = new T1AppEdit();
      this.appsT1UsersColl = new AppsT1Users();
      this.stateModel = new StateModel();
      this.initLayout();
    },
    load: function () {
      var self = this;
      this.T1AppEditColl.fetch({
        appID: self.appID,
        success: function (data) {
          if (data.meta === "success") {
            self.appStatus = data.data[0].deploy_status;
            if (data.data[0].deploy_status === "restricted") {
              self.showAccessTab = true;
            }
            if (data.data[0].organization_id) {
              self.orgID = data.data[0].organization_id;
            } else {
              T1Location.set("#appstore/");
            }
            self.stateModel.orgID = self.orgID;
            self.appsT1UsersColl.fetch({
              params: {
                orgID: self.orgID,
                pageLimit: 100
              },
              success: function (userData) {
                self.stateModel.orgUser = userData.models;
                self.stateModel.parseGridData();
                self.t1AppDevTeamColl.fetch({
                  appID: self.appID,
                  success: function (devData) {
                    var devTeam = self.stateModel.addBinUser(devData.data);
                    self.setFormActions();
                    self.stateModel.devTeam = devTeam;
                    self.stateModel.parseDevTeam();
                    self.render().then(function () {
                      self.layout.load();
                    });
                  }
                });
              }
            });
          }
        }
      });
    },
    setFormActions: function () {
      var self = this;
      self.actionsConfig.formActions = [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#appstore/myApps"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: false
      }, {
        label: self.showAccessTab ? "Save & Continue" : "Save & Close",
        uuid: "save_close",
        class: "saveAndClose save-btn-primary",
        location: false
      }];
    },
    initLayout: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".team-container");
        },
        template: template,
        layout: {
          ".bin-holder": [{
            module: "T1App/edit/team",
            viewType: "bin",
            options: {
              model: self.stateModel
            }
          }],
          ".search-holder": [{
            module: "T1App/edit/team",
            viewType: "search",
            options: {
              model: self.stateModel
            }
          }],
          ".grid-holder": [{
            module: "T1App/edit/team",
            viewType: "filterGrid",
            options: {
              model: self.stateModel
            }
          }]
        }
      });
    },
    saveAndClose: function (e) {
      var self = this;
      var url = self.showAccessTab ? "#appstore/edit/" + self.appID + "/access/users" : "#appstore";
      var callback = {
        success: function () {
          T1.Notify("message", "App updated successfully!");
          setTimeout(function () {
            T1.Location.set(url);
          }, 1e3);
        }
      };
      return this.saveOriginal(e, callback);
    },
    saveOverride: function (e, customCallbacks) {
      var callback = customCallbacks || {
        success: function () {
          T1.Notify("message", "App updated successfully!");
        }
      };
      return this.saveOriginal(e, callback);
    },
    updateFormElementsChanged: function (msg) {
      var warning = false;
      var error = true;
      if (!msg) {
        msg = "There are unsaved changes on the page.";
        warning = true;
        error = false;
      }
      this.updateFooterMessage({
        warning: warning,
        error: error,
        message: msg
      });
      this.isFormElementsChanged = true;
    },
    postData: function (formValues, success, statusInvalid, conflict) {
      var model = this.stateModel;
      var self = this;
      model.set({
        app_id: this.appID
      });
      model.save(model.toJSON(), {
        dataType: "json",
        statusInvalid: function () {},
        success: function () {
          self.stateModel.unset("addToDev");
          self.stateModel.unset("removeFromDev");
          success(model);
        },
        conflict: conflict,
        error: function (data) {
          var errors = $.parseJSON(data.responseText);
          var email = "";
          var msg = "";
          $.each(errors.data, function (index, value) {
            email = $("#usersGrid").find("div#" + value.user_id + '[field="email"]').html();
            msg = email + " " + value.message + " ";
          });
          statusInvalid(msg);
        },
        contentType: "application/json",
        processData: false,
        stringify: true
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        var title = $("div.strand-header").html();
        this.openUnloadAlertDialog({
          name: title
        }, onTabChange);
      }
      return !isFormElementsChanged;
    }
  });
  TeamCreateEdit = T1.Form.ViewUtils.extend(TeamCreateEdit);
  TeamCreateEdit.prototype.saveOriginal = TeamCreateEdit.prototype.save;
  TeamCreateEdit.prototype.save = TeamCreateEdit.prototype.saveOverride;
  return TeamCreateEdit;
});
