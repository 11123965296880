define('collections/strategyAudienceSegments',["jQuery", "Underscore", "T1", "T1Collection", "T1Model", "T1Permissions"], function ($, _, T1, T1Collection, T1Model, T1Permissions) {
  const hasGroupPermission = T1Permissions.check("feature", "campaigns_group_audience_segment");
  var collection = T1Collection.extend({
    model: T1Model,
    urlString: "strategies",
    includedBinOperation: "OR",
    url: function () {
      var originalString = this.urlString;
      var url;
      this.urlString = originalString + "/" + this.id + "/" + this.action;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    parse: function (data) {
      var parsedData;
      var result = [];
      parsedData = T1Collection.prototype.parse.call(this, data);
      $.each(parsedData, function (index, value) {
        var audience_segment;
        if (value.audience_segment) {
          audience_segment = value.audience_segment;
          audience_segment.action = value.restriction === "INCLUDE" ? "include" : "exclude";
          if (value.user_cpm) {
            audience_segment.user_cpm = value.user_cpm;
          }
          result.push(audience_segment);
        }
      });
      return result;
    },
    load: function () {
      var collection = this;
      this.action = "audience_segments";
      this.fetchOptions.with = "audience_segment";
      this.fetchOptions.full = "*";
      collection.fetch({
        add: true,
        success: function () {
          collection.trigger("segments:loaded");
        }
      });
    },
    buildPostObj: function () {
      let obj = {};
      const dataSet = this.models;
      const segmentStr = "segments.";
      let userCPM;
      if (hasGroupPermission) {
        if (this.includeGroups.length && this.includeGroups[0].binList.length) {
          this.includeGroups.forEach(group => {
            obj[`${group.identifier}.operator`] = group.binGroupOperator;
            group.binList.forEach(segment => {
              const idx = dataSet.findIndex(item => item.get("id") === segment.id);
              dataSet[idx].set({
                group_identifier: group.identifier
              });
            });
          });
        }
      }
      if (dataSet.length) {
        dataSet.forEach((setItem, i) => {
          if (setItem.get("type") !== "retired_audience_segment" && setItem.get("rel") !== "retired_audience_segment") {
            const action = setItem.get("action");
            const group_identifier = setItem.get("group_identifier");
            obj[`${segmentStr}${i + 1}.id`] = setItem.get("id");
            obj[`${segmentStr}${i + 1}.restriction`] = action.toUpperCase();
            if (hasGroupPermission) {
              obj[`${segmentStr}${i + 1}.group_identifier`] = action === "include" ? group_identifier : "g0";
            }
            userCPM = setItem.get("user_cpm");
            if (userCPM !== undefined) {
              obj[`${segmentStr}${i + 1}.user_cpm`] = userCPM;
            }
          }
        });
        const excluded = dataSet.some(item => item.get("action") === "exclude");
        if (excluded) {
          obj["g0.operator"] = "OR";
        }
      }
      obj.exclude_op = "OR";
      obj.include_op = hasGroupPermission ? "AND" : this.includedBinOperation;
      return obj;
    },
    buildPostObjForPostingSizeAndCpm: function () {
      let obj = {};
      const dataSet = this.models;
      const segmentStr = "segments.";
      let userCPM;
      if (hasGroupPermission) {
        if (this.includeGroups.length && this.includeGroups[0].binList.length) {
          this.includeGroups.forEach(group => {
            obj[`${group.identifier}.operator`] = group.binGroupOperator;
            group.binList.forEach(segment => {
              const idx = dataSet.findIndex(item => item.get("id") === segment.id);
              dataSet[idx].set({
                group_identifier: group.identifier
              });
            });
          });
        }
      }
      if (dataSet.length) {
        dataSet.forEach((setItem, i) => {
          if (setItem.get("type") !== "retired_audience_segment" && setItem.get("rel") !== "retired_audience_segment") {
            const action = setItem.get("action");
            const group_identifier = setItem.get("group_identifier");
            obj[`${segmentStr}${i + 1}.id`] = setItem.get("id");
            obj[`${segmentStr}${i + 1}.restriction`] = action.toUpperCase();
            if (hasGroupPermission) {
              obj[`${segmentStr}${i + 1}.group_identifier`] = action === "include" ? group_identifier : "g0";
            }
            userCPM = setItem.get("user_cpm");
            if (userCPM !== undefined) {
              obj[`${segmentStr}${i + 1}.user_cpm`] = userCPM;
            }
          }
        });
        const excluded = dataSet.some(item => item.get("action") === "exclude");
        if (excluded) {
          obj["g0.operator"] = "OR";
        }
      }
      obj.exclude_op = "OR";
      obj.include_op = hasGroupPermission ? "AND" : this.includedBinOperation;
      return obj;
    },
    buildPostObjForIAS: function (includeOp, excludeOp, postModels) {
      var obj = {};
      var dataSet = postModels;
      var dataSetLen = dataSet.length;
      var segmentStr = "segments.";
      var idStr = ".id";
      var restrictionStr = ".restriction";
      var groupIdentifier = ".group_identifier";
      var operator = ".operator";
      var cpmStr = ".user_cpm";
      var userCPM;
      var segmentCount;
      var i;
      var originalAudienceModel;
      if (dataSetLen > 0) {
        for (i = 0; i < dataSetLen; i++) {
          if (dataSet[i].type !== "retired_audience_segment") {
            segmentCount = segmentStr + (i + 1);
            obj[segmentCount + idStr] = dataSet[i].id;
            obj[segmentCount + restrictionStr] = dataSet[i].action.toUpperCase();
            if (dataSet[i].action === "include") {
              obj[segmentCount + groupIdentifier] = "IAS_INCLUDE";
              obj[segmentCount + operator] = "AND";
            } else {
              obj[segmentCount + groupIdentifier] = "IAS_EXCLUDE";
              obj[segmentCount + operator] = "OR";
            }
            originalAudienceModel = _.filter(this.models, function (model) {
              return model.get("id") === dataSet[i].id;
            });
            if (originalAudienceModel.length > 0) {
              originalAudienceModel = originalAudienceModel[0];
              userCPM = originalAudienceModel.get("user_cpm");
              if (userCPM !== undefined) {
                obj[segmentCount + cpmStr] = userCPM;
              }
            } else {
              if (dataSet[i].user_cpm) {
                userCPM = dataSet[i].user_cpm;
                obj[segmentCount + cpmStr] = userCPM;
              }
            }
          }
        }
      }
      obj.exclude_op = excludeOp;
      obj.include_op = includeOp;
      return obj;
    }
  });
  T1.Models.StrategyAudienceSegments = collection;
  return T1.Models.StrategyAudienceSegments;
});
