define('modules/strategy/views/summaryInfo',['require','jQuery','When','Underscore','T1','T1View','T1Permissions','text!../templates/summaryInfo.html','utils/CampaignStrategyUtils','utils/LaunchDarklyUtils','collections/strategySupplySources','models/strategyTargetDimensions','models/strategyTargetSummary','utils/currencyConversionHelper','models/strategyAudienceGroupedSegments','models/strategyTargetGroupedSegments','collections/strategyRetiredAudienceSegments','collections/strategyRetiredTargetSegments','collections/strategySiteLists','collections/strategyConcepts','collections/dayParts','collections/targetValues','collections/nemoAttachments','modules/targeting/location/models/hyperLocalTargeting','modules/strategy/views/helpers/supplySummaryTarget','modules/strategy/views/helpers/locationSummaryTarget','modules/strategy/views/helpers/creativeSummaryTarget','modules/strategy/views/helpers/audienceSummaryTarget','modules/strategy/views/helpers/contextualSummaryTarget','modules/strategy/views/helpers/pixelSummaryTarget','modules/strategy/views/helpers/siteListSummaryTarget','modules/strategy/views/helpers/dayPartSummaryTarget','modules/strategy/views/helpers/technologySummaryTarget','modules/strategy/views/helpers/videoSummaryTarget','modules/strategy/views/helpers/technologyWurflTargetHelper','models/advertiser'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const When = require("When");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Permissions = require("T1Permissions");
  const template = require("text!../templates/summaryInfo.html");
  const Utils = require("utils/CampaignStrategyUtils");
  const LaunchDarklyUtils = require("utils/LaunchDarklyUtils");
  const StrategySupplySources = require("collections/strategySupplySources");
  const StrategyTargetDimensions = require("models/strategyTargetDimensions");
  const StrategyTargetSummary = require("models/strategyTargetSummary");
  const CurrencyUtils = require("utils/currencyConversionHelper");
  const GroupedSegments = require("models/strategyAudienceGroupedSegments");
  const StrategyTargetSegments = require("models/strategyTargetGroupedSegments");
  const RetiredSegments = require("collections/strategyRetiredAudienceSegments");
  const StrategyRetiredTargetSegments = require("collections/strategyRetiredTargetSegments");
  const StrategySiteLists = require("collections/strategySiteLists");
  const StrategyConcepts = require("collections/strategyConcepts");
  const DayParts = require("collections/dayParts");
  const TargetValues = require("collections/targetValues");
  const NemoIPAttachments = require("collections/nemoAttachments");
  const HyperLocalTargeting = require("modules/targeting/location/models/hyperLocalTargeting");
  const SupplySummaryTarget = require("modules/strategy/views/helpers/supplySummaryTarget");
  const LocationSummaryTarget = require("modules/strategy/views/helpers/locationSummaryTarget");
  const CreativeSummaryTarget = require("modules/strategy/views/helpers/creativeSummaryTarget");
  const AudienceSummaryTarget = require("modules/strategy/views/helpers/audienceSummaryTarget");
  const ContextualSummaryTarget = require("modules/strategy/views/helpers/contextualSummaryTarget");
  const PixelSummaryTarget = require("modules/strategy/views/helpers/pixelSummaryTarget");
  const SiteListSummaryTarget = require("modules/strategy/views/helpers/siteListSummaryTarget");
  const DayPartSummaryTarget = require("modules/strategy/views/helpers/dayPartSummaryTarget");
  const TechnologySummaryTarget = require("modules/strategy/views/helpers/technologySummaryTarget");
  const VideoSummaryTarget = require("modules/strategy/views/helpers/videoSummaryTarget");
  const TechnologyWurflTargetHelper = require("modules/strategy/views/helpers/technologyWurflTargetHelper");
  const Advertiser = require("models/advertiser");
  const targetingActions = Object.freeze({
    audience: "loadAudienceTargetingInfo",
    contextual: "loadContextualTargetingInfo",
    creative: "loadCreativesTargetingInfo",
    dayPart: "loadDayPartTargetingInfo",
    location: "loadLocationTargetingInfo",
    pixel: "loadPixelTargetingInfo",
    siteList: "loadSiteListTargetingInfo",
    supply: "loadSupplyTargetingInfo",
    technology: "loadTechnologyTargetingInfo",
    video: "loadVideoTargetingInfo"
  });
  const targetingTypes = Object.freeze({
    audience: "audience",
    contextual: "contextual",
    creatives: "creative",
    "day-part": "dayPart",
    dots: "supply",
    "geo-tag": "location",
    "my-data": "pixel",
    "site-list": "siteList",
    video: "video",
    wireless: "technology"
  });
  const viewTypes = Object.freeze({
    audience_targeting: "audience",
    contextual_targeting: "contextual",
    creatives_targeting: "creatives",
    dayPart_targeting: "day-part",
    location_targeting: "geo-tag",
    pixel_targeting: "my-data",
    siteList_targeting: "site-list",
    supply_targeting: "dots",
    technology_targeting: "wireless",
    video_targeting: "video"
  });
  const T1hasOwnProp = T1.Utils.hasOwnProp;
  var readOnlyView = T1View.extend({
    template: template,
    strategyBadges: null,
    isProgrammaticGuaranteed: false,
    sharedNamesRef: {
      targetDimensions: "target_dimensions"
    },
    eventhubEvents: {
      "strategy-badges-close": "closeBadgesPanel"
    },
    htmlHelper: function ($target, content) {
      if (Polymer && Polymer.dom) {
        Polymer.dom($target[0]).innerHTML = content;
      } else {
        $target.html(content);
      }
    },
    initialize: function ({
      model: model,
      strategyEdit: strategyEdit,
      currentView: currentView,
      targetSummaryData: targetSummaryData
    }) {
      this.model = model;
      this.icons = [{
        id: "0",
        name: "Supply",
        type: "dots",
        size: "15",
        disabled: "true"
      }, {
        id: "1",
        name: "Creatives",
        type: "creatives",
        size: "15",
        disabled: "true"
      }, {
        id: "2",
        name: "Audience Targeting",
        type: "audience",
        size: "15",
        disabled: "true"
      }, {
        id: "3",
        name: "Contextual Targeting",
        type: "contextual",
        size: "15",
        disabled: "true"
      }, {
        id: "4",
        name: "My Data Targeting",
        type: "my-data",
        size: "15",
        disabled: "true"
      }, {
        id: "5",
        name: "Site List Targeting",
        type: "site-list",
        size: "15",
        disabled: "true"
      }, {
        id: "6",
        name: "Location Targeting",
        type: "geo-tag",
        size: "15",
        disabled: "true"
      }, {
        id: "7",
        name: "Day Part Targeting",
        type: "day-part",
        size: "15",
        disabled: "false"
      }, {
        id: "8",
        name: "Technology Targeting",
        type: "wireless",
        size: "15",
        disabled: "true"
      }];
      this.hardCodedResults = {
        audience: "1",
        contextual: "1",
        creative: "1",
        day_part: "1",
        location: "1",
        pixel: "1",
        site_list: "1",
        supply: "1",
        technology: "1",
        video: "1"
      };
      this.targetIconString = "";
      this.loaded = false;
      this.bindSummaryBadges = false;
      this.hasStrategyTargetingEdit = T1Permissions.check("strategy.edit", model);
      this.hasStrategyTargetingReadOnly = T1Permissions.check("strategy.readonly", model);
      this.showSummaryBadges = this.hasStrategyTargetingEdit ? true : Boolean(this.hasStrategyTargetingReadOnly);
      this.iASId = COMPASS_ENV.IAS_ID;
      this.strategyEdit = strategyEdit;
      this.currentViewBadges = this.mapViewToType(currentView);
      if (targetSummaryData) {
        this.prepareBadgesData(targetSummaryData);
      }
    },
    mapViewToType: function (currentView) {
      return viewTypes[currentView] || "main";
    },
    fetchData: function (ignoreResults) {
      var targetSummaryModel = new StrategyTargetSummary({
        id: this.model.get("id")
      });
      var deferred = When.defer();
      if (ignoreResults !== undefined && ignoreResults) {
        this.prepareBadgesData({
          hardCodedResults: true
        });
        deferred.resolve();
      } else {
        targetSummaryModel.fetch({
          action: "targeting_summary",
          success: data => {
            this.prepareBadgesData(data);
            deferred.resolve();
          }
        });
      }
      return deferred.promise;
    },
    load: function () {
      this.isProgrammaticGuaranteed = Utils.isProgrammaticGuaranteed(this.model.get("campaign"));
      this.render().then(() => {
        this.strategyBadges = this.$("campaigns-strategy-badges");
        this.strategyBadges[0].set("icons", this.icons);
        this.strategyBadges[0].set("currentViewBadges", this.currentViewBadges);
        this.strategyBadges.bind("summaryButtonClick", e => {
          this.onStrategyButtonClick(e, this);
        });
      });
    },
    prepareBadgesData: function (data) {
      let targetSummaryResults;
      var readOnly = false;
      const ID = this.model.id;
      function setIconProps(disabledVal, editlink, icon) {
        this.icons[icon].disabled = disabledVal;
        this.icons[icon].editlink = readOnly ? `#strategy/readonly/${ID}` : `#strategy/edit/${ID}/${editlink}`;
      }
      if (data.hardCodedResults) {
        this.hardCodedResults.video = this.model.get("media_type") === "DISPLAY" ? "-1" : "1";
        targetSummaryResults = this.cleanData(this.hardCodedResults);
      } else {
        targetSummaryResults = this.cleanData(data.toJSON());
      }
      if (!this.hasStrategyTargetingEdit && this.hasStrategyTargetingReadOnly || this.model.get("supply_type") === "RMX_API") {
        readOnly = true;
      }
      for (const icon in this.icons) {
        if (T1hasOwnProp(this.icons, icon)) {
          switch (this.icons[icon].type) {
            case "dots":
              setIconProps.call(this, targetSummaryResults.supply, "supply", icon);
              break;
            case "creatives":
              setIconProps.call(this, targetSummaryResults.creative, "creatives", icon);
              break;
            case "audience":
              setIconProps.call(this, targetSummaryResults.audience, "targeting/audience", icon);
              break;
            case "contextual":
              setIconProps.call(this, targetSummaryResults.contextual, "targeting/contextual", icon);
              break;
            case "my-data":
              setIconProps.call(this, targetSummaryResults.pixel, "targeting/myData", icon);
              break;
            case "site-list":
              setIconProps.call(this, targetSummaryResults.site_list, "targeting/siteList", icon);
              break;
            case "geo-tag":
              setIconProps.call(this, targetSummaryResults.location, "targeting/location", icon);
              break;
            case "day-part":
              setIconProps.call(this, false, "targeting/dayPart", icon);
              break;
            case "wireless":
              setIconProps.call(this, targetSummaryResults.technology, "targeting/technology", icon);
              break;
          }
        }
      }
      this.icons = this.icons.filter(el => el.id !== "9");
      if (targetSummaryResults.video === "0" || targetSummaryResults.video === "1") {
        this.icons.push({
          id: "9",
          name: "Video Targeting",
          type: "video",
          size: "15",
          disabled: false,
          editlink: readOnly ? `#strategy/readonly/${this.model.id}` : `#strategy/edit/${this.model.id}/targeting/video`
        });
      }
      this.targetIconString = JSON.stringify(this.icons).replace(/"/g, "'");
    },
    prepareDimensionsData: function (data, dimensionCodeFilter, captureOpNameFilter) {
      let dims;
      var resultCollection = null;
      var self = this;
      var addToLocationColl = function (dim) {
        var targetVals = dim.get("target_value");
        if (!resultCollection) {
          resultCollection = new TargetValues([], {
            dimension: "REGN"
          });
        }
        if (targetVals.tempBadgesCollection && targetVals.tempBadgesCollection.strategyId === self.model.id && self.currentViewBadges !== "main") {
          resultCollection.add(targetVals.tempBadgesCollection.data);
        } else {
          resultCollection.add(targetVals.models);
        }
      };
      if (data.get) {
        dims = data.get(this.sharedNamesRef.targetDimensions);
      } else {
        dims = data;
      }
      if (dims && dims.length) {
        dims.each(function (dim) {
          var dimCode = dim.get("code");
          if (dimensionCodeFilter && dimensionCodeFilter(dimCode) === true) {
            addToLocationColl(dim);
            if (captureOpNameFilter && captureOpNameFilter.code === dimCode) {
              resultCollection[captureOpNameFilter.opName] = dim.get(captureOpNameFilter.opName);
            }
          }
        });
      }
      return resultCollection;
    },
    cleanData: function (data) {
      for (const prop in data) {
        if (T1hasOwnProp(data, prop)) {
          if (prop !== "video") {
            data[prop] = false;
          }
        }
      }
      return data;
    },
    determineTargetingType: function (targetType) {
      this.targetType = targetingTypes[targetType];
    },
    loadTargetingInfo: function (buttonType) {
      var infoEl = this.strategyBadges;
      var stratTargetDims = new StrategyTargetDimensions({
        id: this.model.id
      });
      this.htmlHelper(infoEl, "");
      this.determineTargetingType(buttonType);
      this.infoEl = infoEl;
      this.strategyBadges[0].showLoading = true;
      this.prepareTargetDimensions(stratTargetDims);
    },
    prepareTargetDimensions: function (stratTargetDims) {
      var self = this;
      var hyperLocalTargeting = this.model.hyperLocalTargeting;
      var nemoIPAttachments = this.model.nemoIPAttachments;
      var promises = [];
      const contentListsType = ["technology", "video"];
      if (this.strategyEdit && this.model.targetDimensions && hyperLocalTargeting && nemoIPAttachments) {
        this.hyperLocalCollection = hyperLocalTargeting.hyperLocalCollection;
        this.targetDimensions = this.model.targetDimensions;
        this.attachedGeofences = this.attachedGeofences || hyperLocalTargeting.getGeofencesForBadge();
        this.dispatchTargetingClick();
      } else if (this.strategyEdit && this.model.targetDimensions && contentListsType.includes(this.targetType)) {
        this.targetDimensions = this.model.targetDimensions;
        this.dispatchTargetingClick();
      } else {
        const ipTargetingFetch = When.defer();
        const hyperlocalFetch = When.defer();
        promises.push(ipTargetingFetch.promise);
        promises.push(hyperlocalFetch.promise);
        hyperLocalTargeting = new HyperLocalTargeting({
          strategy: this.model
        });
        this.model.hyperLocalTargeting = hyperLocalTargeting;
        this.hyperLocalCollection = hyperLocalTargeting.hyperLocalCollection;
        this.targetDimensions = stratTargetDims;
        this.nemoIPAttachments = new NemoIPAttachments();
        delete this.nemoIPAttachments.pageField;
        delete this.nemoIPAttachments.pageLimit;
        delete this.nemoIPAttachments.pageLimitField;
        delete this.nemoIPAttachments.page;
        this.nemoIPAttachments.fetch({
          fetchOptions: {
            strategy_id: this.model.id,
            dimension: "IPTA"
          },
          success: function (data) {
            _.each(data.models, function (model) {
              model.set({
                dimension_code: "IPTA"
              }, {
                silent: true
              });
              model.set({
                count: self.nemoIPAttachments.count
              }, {
                silent: true
              });
            });
            ipTargetingFetch.resolve();
          }
        });
        this.targetDimensions.fetch({
          silent: true
        }).then(function () {
          self.model.hyperLocalTargeting.fetchAttachedGeofences(null, function () {
            self.attachedGeofences = hyperLocalTargeting.getGeofencesForBadge();
            hyperlocalFetch.resolve();
          });
        });
        When.all(promises).then(function () {
          self.dispatchTargetingClick();
        });
      }
    },
    dispatchTargetingClick: function () {
      const targetingAction = targetingActions[this.targetType];
      if (typeof this[targetingAction] === "function") {
        this[targetingAction]();
      }
    },
    sanitizeCollection: function (coll) {
      const escapeHTML = T1.Utils.escapeHTML;
      coll.models = coll.models.map(model => {
        model.set({
          name: escapeHTML(model.get("name"))
        });
        if (model.get("type") === "deal") {
          model.set({
            deal_identifier: escapeHTML(model.get("deal_identifier"))
          });
        }
        return model;
      });
    },
    loadSupplyTargetingInfo: function () {
      var currencyCode = this.getCurrencyCode();
      this.collection = new StrategySupplySources([], {
        isPaginated: false
      });
      this.collection.id = this.model.id;
      this.collection.strategy = this.model.tempSupplyInfo ? $.extend(true, {}, this.model, {
        attributes: {
          run_on_all_pmp: this.model.tempSupplyInfo.run_on_all_pmp,
          run_on_all_exchanges: this.model.tempSupplyInfo.run_on_all_exchanges
        }
      }) : this.model;
      const model = this.model;
      const campaign = model.get("campaign");
      const advertiserId = campaign.get("advertiser_id");
      if (campaign.get("advertiser") === null) {
        const advertiser = new Advertiser({
          id: advertiserId
        });
        advertiser.fetch({
          success: data => {
            campaign.get("advertiser").set(data);
          }
        });
      }
      this.sourceType = "open";
      this.collection.fetchOptions = {
        currency_code: currencyCode
      };
      const data = this.targetDimensions.data || this.targetDimensions;
      this.collection.singleFetch = true;
      this.collection.isPaginated = false;
      const agencyId = campaign.get("advertiser").get("agency_id");
      LaunchDarklyUtils.getPromise().then(() => {
        LaunchDarklyUtils.updateAgency(agencyId);
        this.collection.fetch({
          silent: true,
          success: () => {
            let collection = this.collection;
            const permissionedFetch = When.defer();
            this.canLoad = true;
            SupplySummaryTarget.processFetchedResult(model);
            if (collection.models.some(tempModel => tempModel.get("type") === "deal")) {
              collection = CurrencyUtils.updatePMPePriceWithCurrency(collection, currencyCode);
              this.collection.fetchPermissionedDeals(advertiserId, currencyCode, permissionedFetch, false);
            } else {
              permissionedFetch.resolve();
            }
            const foldPositions = this.prepareDimensionsData(data, dimCode => dimCode === "FOLD");
            permissionedFetch.promise.then(() => {
              this.sanitizeCollection(collection);
              this.strategyBadges[0].showLoading = false;
              if (this.targetType === "supply") {
                if (this.isProgrammaticGuaranteed && this.collection.length || !this.isProgrammaticGuaranteed) {
                  const htmlText = this.getHtmlStringFromData(SupplySummaryTarget.prepareForSerialize(this.model, this.collection, this.sourceType, foldPositions));
                  this.htmlHelper(this.strategyBadges, htmlText);
                }
              }
            });
          }
        });
      });
    },
    loadCreativesTargetingInfo: function () {
      var coll = new StrategyConcepts([], {
        isPaginated: false
      });
      coll.id = this.model.id;
      this.strategy = this.model;
      this.collection = coll;
      coll.fetch({
        silent: true,
        success: () => {
          const htmlText = this.getHtmlStringFromData(CreativeSummaryTarget.prepareForSerialize(this.model, this.collection));
          this.htmlHelper(this.strategyBadges, htmlText);
          this.strategyBadges[0].showLoading = false;
        }
      });
    },
    loadLocationTargetingInfo: function () {
      var data = this.targetDimensions;
      var ipData = this.model.nemoIPAttachments || this.nemoIPAttachments;
      function getDimCode(dimCode) {
        return dimCode === "REGN" || dimCode === "DMAX" || dimCode === "USZC";
      }
      this.partialCollection = data.get("partial");
      this.collection = this.prepareDimensionsData(data, getDimCode);
      if (ipData.length) {
        this.collection.add({
          action: ipData.models[0].get("restriction").toLowerCase(),
          count: ipData.models[0].get("count"),
          id: "ITPA",
          name: "ip list",
          dimension_code: "IPTA"
        }, {
          silent: true
        });
      }
      if (this.targetType === "location") {
        const htmlText = this.getHtmlStringFromData(LocationSummaryTarget.prepareForSerialize(this.model, this.partialCollection, this.collection, this.attachedGeofences, this.hyperLocalCollection, this.nemoIPAttachments));
        this.htmlHelper(this.strategyBadges, htmlText);
        this.strategyBadges[0].showLoading = false;
      }
    },
    loadAudienceTargetingInfo: function () {
      const groupedAudienceSegments = new GroupedSegments({
        id: this.model.id,
        version: this.model.get("version")
      });
      var retiredAudienceSegments = new RetiredSegments({
        version: this.model.get("version")
      });
      this.strategy = this.model;
      this.currencyCode = this.strategy ? this.strategy.getCurrencyCode() : "";
      this.groupedSegments = groupedAudienceSegments;
      retiredAudienceSegments.strategyId = this.model.id;
      this.retiredSegments = retiredAudienceSegments;
      this.groupedSegments.fetch({
        silent: true,
        success: data => {
          this.partialCollection = data.get("partials");
          this.collection = data.get("user_saved");
          this.collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.collection, this.currencyCode);
          this.retiredSegments.fetchOptions.currency_code = this.strategy.getCurrencyCode();
          this.retiredSegments.fetch({
            silent: true,
            success: retiredSegData => {
              this.retiredCollection = retiredSegData;
              this.collection = this.collection.add(this.retiredCollection.models, {
                silent: true
              });
              if (this.targetType === "audience") {
                const htmlText = this.getHtmlStringFromData(AudienceSummaryTarget.prepareForSerialize(this.currencyCode, this.collection, this.strategy, this.partialCollection, this.iASId));
                this.htmlHelper(this.strategyBadges, htmlText);
                this.strategyBadges[0].showLoading = false;
              }
            }
          });
        },
        currency_code: this.strategy.getCurrencyCode()
      });
    },
    loadContextualTargetingInfo: function () {
      const targetSegments = new StrategyTargetSegments({
        id: this.model.id,
        version: this.model.get("version")
      });
      var retiredTargetSegments = new StrategyRetiredTargetSegments({
        version: this.model.get("version")
      });
      var data = this.targetDimensions;
      this.strategy = this.model;
      this.currencyCode = this.strategy ? this.strategy.getCurrencyCode() : "";
      this.targetSegments = targetSegments;
      retiredTargetSegments.strategyId = this.model.id;
      this.retiredSegments = retiredTargetSegments;
      this.prepareDataIsCalled = true;
      this.partialCollection = data.get("partial");
      this.targetSegments.fetch({
        silent: true,
        success: targetResp => {
          this.targetSegmentsPartialCollection = targetResp.get("partials");
          this.targetSegmentsCollection = targetResp.get("user_saved");
          this.targetSegmentsCollection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.targetSegmentsCollection, this.currencyCode);
          this.retiredSegments.fetchOptions.currency_code = this.strategy.getCurrencyCode();
          this.retiredSegments.fetch({
            silent: true,
            success: retiredResp => {
              function getDimCode(dimCode) {
                return dimCode === "CHNL" || dimCode === "SFTY";
              }
              this.retiredCollection = retiredResp;
              this.collection = this.prepareDimensionsData(data, getDimCode, {
                opName: "INCLUDE_OP",
                code: "CHNL"
              });
              this.targetSegmentsCollection = this.targetSegmentsCollection.add(this.retiredCollection.models, {
                silent: true
              });
              if (this.targetType === "contextual") {
                const htmlText = this.getHtmlStringFromData(ContextualSummaryTarget.prepareForSerialize(this.currencyCode, this.targetSegmentsCollection, this.strategy, this.targetSegmentsPartialCollection, this.collection));
                this.htmlHelper(this.strategyBadges, htmlText);
                this.strategyBadges[0].showLoading = false;
              }
            }
          });
        },
        currency_code: this.strategy.getCurrencyCode()
      });
    },
    loadPixelTargetingInfo: function () {
      let htmlText;
      var self = this;
      this.strategy = this.model;
      this.pixelTitle = {
        DATA: "DATA PIXELS",
        EVENT: "EVENT PIXELS",
        DYNAMIC: "ADAPTIVE SEGMENTS"
      };
      if (this.strategyEdit && this.strategy.pixel_bundles) {
        if (this.targetType === "pixel") {
          htmlText = this.getHtmlStringFromData(PixelSummaryTarget.prepareForSerialize(this.model, this.strategy.pixel_bundles, this.pixelTitle));
          this.strategyBadges.html(htmlText);
          this.strategyBadges[0].showLoading = false;
        }
      } else {
        this.strategy.fetchPixelTargets(function (data) {
          self.collection = data;
          if (self.targetType === "pixel") {
            htmlText = self.getHtmlStringFromData(PixelSummaryTarget.prepareForSerialize(self.model, self.collection, self.pixelTitle));
            self.htmlHelper(self.strategyBadges, htmlText);
            self.strategyBadges[0].showLoading = false;
          }
        }, false);
      }
    },
    loadSiteListTargetingInfo: function () {
      let domainRestrictionObj;
      var strategyId = this.model.id;
      var siteList = new StrategySiteLists();
      siteList.id = strategyId;
      siteList.fetchOptions = $.extend({
        full: "*",
        sort_by: "id",
        q: "assigned==1"
      }, siteList.fetchOptions);
      this.collection = siteList;
      this.strategy = this.model;
      if (this.strategy) {
        domainRestrictionObj = this.strategy.getDomainRestrictions();
      }
      this.canLoad = false;
      this.collection.fetch({
        silent: true,
        success: () => {
          var htmlText;
          if (domainRestrictionObj) {
            domainRestrictionObj.fetch({
              silent: true,
              success: () => {
                this.canLoad = true;
                if (this.targetType === "siteList") {
                  htmlText = this.getHtmlStringFromData(SiteListSummaryTarget.prepareForSerialize(this.model, this.collection));
                  this.htmlHelper(this.strategyBadges, htmlText);
                  this.strategyBadges[0].showLoading = false;
                }
              }
            });
          } else {
            this.canLoad = true;
            if (this.targetType === "siteList") {
              htmlText = this.getHtmlStringFromData(SiteListSummaryTarget.prepareForSerialize(this.model, this.collection));
              this.htmlHelper(this.strategyBadges, htmlText);
              this.strategyBadges[0].showLoading = false;
            }
          }
        }
      });
    },
    loadDayPartTargetingInfo: function () {
      var dayParts = new DayParts();
      dayParts.id = this.model.id;
      this.collection = dayParts;
      this.collection.fetch({
        silent: true,
        success: () => {
          this.canLoad = true;
          if (this.targetType === "dayPart") {
            const htmlText = this.getHtmlStringFromData(DayPartSummaryTarget.prepareForSerialize(this.model, this.collection));
            this.htmlHelper(this.strategyBadges, htmlText);
            this.strategyBadges[0].showLoading = false;
          }
        }
      });
    },
    loadTechnologyTargetingInfo: function () {
      const dimCodes = ["CSPD", "ISPX", "BSER", "INVT", "DVCE", "WURF", "WURFL/OS"];
      var data = this.targetDimensions;
      function getDimCode(dimCode) {
        return dimCodes.includes(dimCode);
      }
      this.partialCollection = data.get("partial");
      TechnologyWurflTargetHelper.fetchWURFLData(this.model.id).then(wurflData => {
        if (wurflData) {
          const osVersionData = wurflData.filter(item => item.dimension_code === "WURFL/OS");
          const wurfData = wurflData.filter(item => item.dimension_code === "WURF");
          if (osVersionData) {
            data.get("target_dimensions").get("WURFL/OS").get("target_value").add(osVersionData);
          }
          if (wurfData) {
            data.get("target_dimensions").get("WURF").get("target_value").add(wurfData);
          }
        }
        this.collection = this.prepareDimensionsData(data, getDimCode);
        if (this.targetType === "technology") {
          const htmlText = this.getHtmlStringFromData(TechnologySummaryTarget.prepareForSerialize(this.model, this.collection, this.partialCollection));
          this.htmlHelper(this.strategyBadges, htmlText);
          this.strategyBadges[0].showLoading = false;
        }
        T1.EventHub.publish("tech-targeting:setFormElementStatus", {
          badgesData: this.collection.models
        });
      });
    },
    loadVideoTargetingInfo: function () {
      const dimCodes = ["VPSZ", "VLIN", "VCON", "VAUD", "VSKP", "VPLC"];
      function getDimCode(dimCode) {
        return dimCodes.includes(dimCode);
      }
      this.collection = this.prepareDimensionsData(this.targetDimensions, getDimCode);
      if (this.targetType === "video") {
        const htmlText = this.getHtmlStringFromData(VideoSummaryTarget.prepareForSerialize(this.model, this.collection));
        this.htmlHelper(this.strategyBadges, htmlText);
        this.strategyBadges[0].showLoading = false;
      }
    },
    onStrategyButtonClick: function (e, self) {
      if (e.originalEvent) {
        const buttonType = e.originalEvent.detail.type;
        const $jumpToLink = this.$("strand-icon[type=jump-to]").parent();
        if (this.isProgrammaticGuaranteed && buttonType !== "dots" && buttonType !== "creatives") {
          $jumpToLink.hide();
        } else {
          $jumpToLink.show();
        }
        self.loadTargetingInfo(buttonType);
      }
    },
    getHtmlStringFromData: function (dataMain) {
      var htmlText = "";
      var dataSections = dataMain.data;
      for (const key in dataSections) {
        if (T1hasOwnProp(dataSections, key)) {
          htmlText += `<div class="sb-section">`;
          if (dataSections[key].header) {
            htmlText += `<div class="sb-section-header">\n              <span class="sb-section-content-header">${dataSections[key].header}</span>`;
            if (dataSections[key].includeOP) {
              htmlText += `<span class="sb-section-content-header includeOP">${dataSections[key].includeOP}</span>`;
            }
            htmlText += "</div>";
          }
          if (dataSections[key].header === "Private Marketplace Exchange" && !dataSections[key].items.length) {
            htmlText += `\n              <div class="sb-section">\n                <div class="sb-section-content-item">\n                  <span class="sb-section-content-item-text">No Selection</span>\n                </div>\n              </div>`;
          }
          const dataItems = dataSections[key].items;
          for (const itemKey in dataItems) {
            if (T1hasOwnProp(dataItems, itemKey)) {
              htmlText += `<div class="sb-section-content-item">`;
              if (dataItems[itemKey].action === "include") {
                htmlText += `<strand-icon class="sb-icon sb-icon-include" type="include-outline" height="12" width="12">\n                  </strand-icon>\n                  <div class="sb-section-content-wrapper">`;
              } else if (dataItems[itemKey].action === "exclude") {
                htmlText += `<strand-icon class="sb-icon sb-icon-exclude" type="exclude" height="12" width="12">\n                  </strand-icon>\n                  <div class="sb-section-content-wrapper">`;
              }
              if (dataItems[itemKey].showRetiredWarningIcon) {
                htmlText += `<span class="warning-holder"><a href="${dataItems[itemKey].headerWarningUrl}" \n                target="_blank" rel="noopener" class="warning-icon t1-tooltip" \n                data-tip-content="retired-segments-header-warning"></a>\n                </span>`;
              }
              htmlText += `<span class="sb-section-content-item-text">${dataItems[itemKey].label}</span>`;
              if (dataItems[itemKey].boldText) {
                htmlText += `<span class='boldTextSummaryBadge'>${dataItems[itemKey].boldText}</span>`;
              }
              if (dataItems[itemKey].action) {
                htmlText += "</div>";
              }
              htmlText += "</div>";
            }
          }
          htmlText += "</div>";
        }
      }
      if (dataMain.numberOfItemsLeft) {
        htmlText += `\n          <div class="sb-section">\n            <div class="sb-section-content-item">\n              <span>There are ${dataMain.numberOfItemsLeft} more targets. Please visit the targeting screen.</span>\n            </div>\n          </div>`;
      }
      return htmlText;
    },
    closeBadgesPanel: function () {
      if (this.strategyBadges) {
        this.strategyBadges[0].close();
      }
    },
    serialize: function () {
      return {
        dataMain: {},
        showSummaryBadges: this.showSummaryBadges
      };
    }
  });
  return readOnlyView;
});
