define('models/advertiser',["jQuery", "T1", "T1Model", "T1Permissions", "Backbone", "models/agency", "models/concept", "models/advertiserRemoteAccount", "utils/SiteUtils"], function ($, T1, T1Model, T1Permissions, Backbone, Agency, Concept, RemoteAccount, Utils) {
  "use strict";

  T1.Models.Advertiser = T1Model.extend({
    url: "advertisers/",
    defaults: {
      minimize_multi_ads: "off"
    },
    relations: [{
      type: Backbone.HasOne,
      key: "agency",
      relatedModel: "Agency",
      includeInJSON: true
    }, {
      type: Backbone.HasMany,
      key: "concept",
      relatedModel: "Concept",
      includeInJSON: false
    }],
    validateAttrs: function (attrs) {
      const domainAttr = attrs.domain.trim();
      var errors = [];
      var optionalFields = ["status", "minimize_multi_ads", "facebook_id", "ads_txt_verified", "external_id"];
      var requiredField = "This is a required field.";
      var validationResult;
      var actualDomain = domainAttr.replace(/^https?:\/\//, "").split("/")[0];
      var reqFields = {
        agency_id: function () {
          return !attrs.agency_id;
        },
        name: function () {
          return !attrs.name;
        },
        domain: function () {
          return !domainAttr;
        },
        vertical_id: function () {
          return !attrs.vertical_id;
        },
        ad_server_id: function () {
          return !attrs.ad_server_id;
        }
      };
      var extraValFields = {
        name: function () {
          if (attrs.name.length > 64) {
            return "Name cannot be longer than 64 characters.";
          }
        },
        domain: function () {
          if (domainAttr.length > 255) {
            return "Domain cannot be longer than 255 characters.";
          }
          if (!domainAttr.match(/^https?:\/\//)) {
            return 'Domain must begin with "http://" or "https://".';
          }
          if (!Utils.isFQDN(actualDomain)) {
            return `"${actualDomain}" is not a valid domain name.`;
          }
        },
        facebook_id: function () {
          if (!attrs.facebook_id.match(/^\s*$/) && !attrs.facebook_id.match(/^\+?(0|[1-9]\d*)$/)) {
            return "Facebook Page ID must be a number";
          }
        }
      };
      $.extend(reqFields, {
        frequency_type: function () {
          return !attrs.frequency_type;
        }
      });
      if (attrs.frequency_type !== "no-limit") {
        $.extend(reqFields, {
          frequency_amount: function () {
            return !attrs.frequency_amount;
          },
          frequency_interval: function () {
            return !attrs.frequency_interval;
          }
        });
        $.extend(extraValFields, {
          frequency_amount: function () {
            if (!$.isNumeric(attrs.frequency_amount) || Math.floor(attrs.frequency_amount) !== Number(attrs.frequency_amount)) {
              return "Frequency amount must be an integer.";
            }
            if (attrs.frequency_amount > 9999999) {
              return "Frequency amount cannot be higher than 9999999.";
            }
          }
        });
      } else {
        optionalFields.push("frequency_amount");
        optionalFields.push("frequency_interval");
      }
      $.each(this.defaults, function (key, value) {
        if (!attrs[key]) {
          attrs[key] = value;
        }
      });
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        } else if (extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData);
      delete rawData.organization_id;
      delete rawData.facebook_id;
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    },
    fetch: function (opts) {
      return T1Model.prototype.fetch.call(this, opts);
    },
    reset: function () {
      this.attributes = {};
    },
    fixture: "-advertiser"
  });
  return T1.Models.Advertiser;
});
