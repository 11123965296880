define('collections/nemoAttachments',["T1NemoCollection", "models/nemoAttachment"], function (NemoCollection, Attachment) {
  "use strict";

  var collection = NemoCollection.extend({
    model: Attachment,
    urlString: "attachment",
    init: function () {
      this.initOriginal({
        isPaginated: false
      });
    },
    findByTarget: function (target) {
      return this.find(function (model) {
        return model.get("target_id") === target.id;
      });
    },
    findByResource: function (opts) {
      return this.find(function (model) {
        return model[opts.type].id === opts.resource.id;
      });
    }
  });
  collection.prototype.initOriginal = collection.prototype.initialize;
  collection.prototype.initialize = collection.prototype.init;
  return collection;
});
