define('modules/T1App/myApps/views/no_chart_data',["jQuery", "T1", "T1View", "text!../../templates/no_data_chart.html"], function ($, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    initialize: function (args) {
      this.msg = args.msg;
    },
    serialize: function () {
      var vcells = [];
      var hcells = [];
      var bars = [];
      var i;
      for (i = 0; i < 10; i++) {
        vcells.push({});
      }
      for (i = 0; i < 3; i++) {
        hcells.push({});
        bars.push({
          "bar-id": i
        });
      }
      return {
        vcells: vcells,
        hcells: hcells,
        bars: bars,
        msg: this.msg
      };
    }
  });
});
