define('modules/audiences/pixels/views/csvExportModal',["jQuery", "Underscore", "T1", "T1View", "text!../templates/csvExportModal.html", "jslibs/xdate/xdate", "collections/campaignReport", "collections/pixelUniques", "collections/organizations"], function ($, _, T1, T1View, tmpl, xdate, CampaignReport, PixelUniques, Organizations) {
  var csvExportModal = T1View.extend({
    template: tmpl,
    eventhubEvents: {
      "csvExportModal:show": "show"
    },
    events: {
      "changed .export-days": "onInputChange"
    },
    tipConfig: {
      getExternalTip: true,
      delayIn: 0,
      delayOut: 0
    },
    onInputChange: function () {
      var inputField = $(this.el).find(".export-days");
      if (inputField[0].error) {
        inputField.tipsy("hide");
      }
      inputField[0].error = false;
    },
    isInt: function (value) {
      if (value > 0 && value < 46) {
        return !isNaN(value) && function (x) {
          return (x | 0) === x;
        }(parseFloat(value));
      }
      return false;
    },
    load: function () {
      var self = this;
      T1.Tooltip(this.el, this.tipConfig);
      this.render().then(function () {
        self.modal = self.el;
        self.modal.dialog({
          autoOpen: false,
          modal: true,
          dialogClass: "w-customAlert pixels-export-download-modal",
          buttons: [{
            text: "Export",
            class: "save",
            click: $.proxy(self.export, self)
          }, {
            text: "Cancel",
            class: "cancel",
            click: $.proxy(self.hide, self)
          }]
        });
      });
    },
    unload: function () {
      if (this.modal) {
        this.modal.remove();
        this.modal.dialog("destroy");
        this.modal = null;
      }
    },
    show: function (data) {
      var pixelsEl = data.pixels_checked;
      var self = this;
      this.pixels = [];
      this.pixel_type = data.pixelType;
      this.orgId = data.orgId;
      this.el.find("input[type=text]").val("");
      this.modal.dialog("open");
      $.each(pixelsEl, function (index, value) {
        self.pixels.push(data.collection.get($(value).data("id")).toJSON());
      });
      this.reload();
    },
    hide: function () {
      this.modal.dialog("close");
      this.hideError();
    },
    formatURL: function (url) {
      return url.replace("http", "https");
    },
    showError: function (errors) {
      this.options.hasError = true;
      this.options.errors = errors;
      this.el.find("input[type=text]").addClass("error");
      this.renderPartial(".error-wrapper");
    },
    hideError: function () {
      this.options.hasError = false;
      this.options.errors = "";
      this.el.find("input[type=text]").removeClass("error");
      this.renderPartial(".error-wrapper");
    },
    handleKey: function (e) {
      if (e.keyCode === 13) {
        this.export();
      }
    },
    validateDays: function () {
      var value = $(".export-days").val();
    },
    export: function () {
      var self = this;
      var pixelIds = [];
      var date = new XDate();
      var now = new XDate();
      var value = $(".export-days").val();
      var day = value !== "" ? value : 7;
      var $el = $(this.el);
      var uniquesCollection = new PixelUniques();
      T1.Tooltip($el, {
        className: "w-FormError",
        delayIn: 0,
        delayOut: 0,
        fade: true,
        getExternalTip: false,
        gravity: "s",
        html: true,
        offset: 10,
        tooltipEle: ".export-days",
        trigger: "manual"
      });
      if (!self.isInt(value)) {
        $el.find(".export-days")[0].error = true;
        $el.find(".export-days").tipsy("show");
        return;
      }
      self.startSpinner.call(self, $el);
      $.each(this.pixels, function (index, value) {
        var pixel_id = value.id;
        pixel_id = parseInt(pixel_id);
        pixelIds.push(pixel_id);
      });
      if (this.pixel_type === "event") {
        uniquesCollection.urlString = "event_pixel_loads";
      } else {
        uniquesCollection.urlString = "data_pixel_loads";
      }
      uniquesCollection.fetchOptions = {
        dimensions: "pixel_name,pixel_id,referrer,referrer_rank",
        filter: "organization_id=" + this.orgId + "&pixel_id=" + pixelIds,
        time_window: "last_" + parseInt(value, 10) + "_days",
        time_rollup: "by_day",
        order: "-mm_date,pixel_id,referrer_rank",
        having: "referrer_rank=1,2,3,4,5,6,7,8,9,10,999"
      };
      self.cancelled = false;
      self.fetching = true;
      uniquesCollection.sync("read", uniquesCollection, {
        action: "download.csv",
        dataType: "html",
        onSuccess: function () {
          self.stopSpinner($el, "save");
        },
        processAjaxResponse: function (resp) {
          var blob = new Blob([resp], {
            type: "attachment/csv"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var elem = document.body.appendChild(a);
          var handler = function () {
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, 1e3);
            document.body.removeChild(elem);
            elem.removeEventListener("click", handler);
          };
          self.fetching = false;
          if (!self.cancelled) {
            self.hide();
            a.href = url;
            a.download = now.toString("yyyy/MM/dd") + "-pixel_placement_qa_" + day + "_day(s).csv";
            elem.addEventListener("click", handler);
            elem.click();
          }
          return {
            statusCode: "ok",
            jsonData: []
          };
        }
      });
    },
    serialize: function () {
      return {
        pixels: this.pixels
      };
    }
  });
  return T1.Form.ViewUtils.extend(csvExportModal);
});
