define('modules/shared/views/pullDownButton',["jQuery", "Underscore", "T1", "T1View", "text!../templates/pull_down_button.html"], function ($, _, T1, T1View, tmpl) {
  return T1View.extend({
    template: tmpl,
    events: {
      "click .pull-down-btn": "addBtnClick",
      "click .pull-down-list ul li a": "itemClick"
    },
    menuState: "closed",
    initialize: function (args) {
      _.each(args.items, function (item, i) {
        item.itemLabel = item.itemLabel || "No Label";
        item.locationStr = item.locationStr || "";
        item.locationOpts = item.locationOpts || {};
        item.itemCallback = item.itemCallback || $.noop;
        item.id = item.id || i;
      });
      this.options = {
        id: args.id || null,
        menuLabel: args.menuLabel || "No Label",
        icon: args.icon || null,
        items: args.items,
        themeColor: args.themeColor || "gray",
        disabled: args.disabled || false,
        renderNotify: args.renderNotify || false
      };
      this.eventhubEvents = {
        "pullDownButton:all:disable": "disable",
        "pullDownButton:all:enable": "enable"
      };
      if (this.options.id) {
        this.eventhubEvents["pullDownButton:" + this.options.id + ":disable"] = "disable";
        this.eventhubEvents["pullDownButton:" + this.options.id + ":enable"] = "enable";
      }
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        if (self.options.disabled) {
          self.disable();
        }
        if (self.options.renderNotify) {
          T1.EventHub.publish("pullDownButton:renderComplete");
        }
      });
      $(document).on("click", $.proxy(this.bodyClick, this));
    },
    unload: function () {
      $(document).off("click");
    },
    enable: function (itemID) {
      const pullDownListItem = this.el.find(".pull-down-list li a[data-id=" + itemID + "]");
      const pullDownBtn = this.el.find(".pull-down-btn");
      let targetItems;
      if (itemID !== undefined) {
        targetItems = this.options.items.filter(item => item.id === itemID);
        if (targetItems && targetItems.length) {
          targetItems[0].itemDisabled = false;
        }
        pullDownListItem.removeClass("disabled");
      } else {
        this.options.disabled = false;
        pullDownBtn.removeClass("disabled");
      }
    },
    disable: function (itemID) {
      const pullDownListItem = this.el.find(".pull-down-list li a[data-id=" + itemID + "]");
      const pullDownBtn = this.el.find(".pull-down-btn");
      let targetItems;
      if (itemID !== undefined) {
        targetItems = this.options.items.filter(item => item.id === itemID);
        if (targetItems && targetItems.length) {
          targetItems[0].itemDisabled = true;
        }
        pullDownListItem.addClass("disabled");
      } else {
        this.options.disabled = true;
        pullDownBtn.addClass("disabled");
      }
      if (this.menuState === "opened") {
        this.toggleMenu();
      }
    },
    addBtnClick: function (e) {
      e.preventDefault();
      if (!this.options.disabled) {
        this.toggleMenu();
      }
    },
    bodyClick: function (e) {
      if ($(e.target).closest(this.el).length == 0) {
        if (this.menuState == "opened") {
          this.toggleMenu();
        }
      }
    },
    toggleMenu: function () {
      var pullDownMenu = this.el.find(".pull-down");
      var pullDownBtn = this.el.find(".pull-down-btn");
      switch (this.menuState) {
        case "closed":
          pullDownMenu.removeClass("closed").addClass("opened");
          pullDownBtn.addClass("active");
          this.menuState = "opened";
          break;
        default:
          pullDownMenu.removeClass("opened").addClass("closed");
          pullDownBtn.removeClass("active");
          this.menuState = "closed";
          break;
      }
    },
    itemClick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $domItem = $(e.currentTarget);
      var id = $domItem.data().id;
      var dataItem = _.find(this.options.items, function (item) {
        return item.id === id;
      });
      dataItem.itemCallback(e);
      if (dataItem.locationStr !== "") {
        T1.Location.set(dataItem.locationStr, dataItem.locationOpts);
      }
      this.toggleMenu();
    },
    serialize: function () {
      return {
        icon: this.options.icon,
        menuLabel: this.options.menuLabel,
        menuItems: this.options.items,
        themeColor: this.options.themeColor
      };
    }
  });
});
