define('modules/creatives/bulkedit/views/edit',["jQuery", "Underscore", "moment", "T1", "T1Permissions", "T1View", "text!../templates/form_template.html", "text!../templates/edit_body.html", "text!../templates/form_footer.html", "T1Layout", "T1Alert", "collections/vendors", "models/concept", "utils/CreativeUtils"], function ($, _, moment, T1, T1Permissions, T1View, template, formBody, formFooter, T1Layout, T1Alert, Vendors, Concept, CreativeUtils) {
  "use strict";

  var BulkEditView = T1View.extend({
    template: template,
    partials: {
      footer: formFooter
    },
    events: {
      "click .form-body .checkbox-input .checkbox": "handleItemChecked",
      "keydown input": "handleKey",
      "click .mraid-select": "handleMraidSelected",
      "changed .mraid-type-select": "handleMraidTypeSelect"
    },
    actionsConfig: {
      events: {
        "click .cancel": "handleCancel",
        "click .reviewOrAssignBtn": "handleReviewOrAssign"
      }
    },
    eventhubEvents: {
      "dropdown:concepts": "handleConceptSelected",
      "dropdown:concepts:selection": "handleConceptSelected"
    },
    initialize: function (args) {
      var self = this;
      var frmActions = [{
        label: "cancel",
        class: "cancel ui-cancel",
        location: "#"
      }, {
        label: "review",
        class: "reviewOrAssignBtn btn-primary",
        location: false
      }];
      self.existingVendors = args.existingVendors;
      self.collection = args.collection;
      self.bulkAdvertiser = args.bulkAdvertiser;
      self.advertiser = args.advertiser;
      self.entityType = args.entityType;
      self.consistencyEnabled = args.consistencyEnabled;
      self.addedVendors = new Vendors();
      self.removedVendors = new Vendors();
      self.isHtmlOrSwf = false;
      self.isMraid = false;
      self.currentClassifications = args.existingClassifications;
      self.existingClassifications = _.chain(self.currentClassifications).map(function (classificationInfo) {
        return classificationInfo.map(function (model) {
          return model;
        });
      }).flatten().uniq(function (item) {
        return item.id + item.entity;
      }).value();
      self.removedClassifications = [];
      self.assignAfterPost = false;
      self.actionsConfig = {
        formActions: frmActions
      };
      self.initLayout();
    },
    initLayout: function () {
      var self = this;
      var advertiserID = this.advertiser.get("id") ? this.advertiser.get("id") : this.bulkAdvertiser.get("id");
      var collection = self.collection.toJSON();
      self.collection.each(function (model) {
        self.model = model;
        if (self.entityType === "video") {
          self.details = model.get("details");
        }
      });
      self.dateOptions = {
        startDataBind: "start_date",
        endDataBind: "end_date",
        showCheckboxes: true,
        showTitle: false,
        showRemoveScheduleCheck: true,
        startDateCheckLabel: "Strategy Start Date",
        endDateCheckLabel: "Strategy End Date"
      };
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-body",
        template: formBody,
        layout: {
          ".concept-select": [{
            module: "shared",
            viewType: "select",
            options: {
              boundEntityId: advertiserID,
              dataBind: "concept",
              notFound: "No Concepts Found",
              placeholder: "Default (same as 'Name')",
              isSearchable: true,
              initialLoad: true,
              extraLinkLabel: "+ Add New Concept",
              extraLinkClass: "extra-link-add clearfix",
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              },
              extraLinkHandler: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                T1.EventHub.publish("popup:show");
              },
              disableCheck: function (count, hasId) {
                return self.consistencyEnabled ? null : !hasId;
              }
            }
          }],
          ".concept-popup": [{
            module: "concept",
            viewType: "popup",
            options: {
              advertiser_id: advertiserID
            }
          }],
          ".add-vendor": [{
            module: "vendor",
            viewType: "boxList",
            options: {
              showLabel: false,
              clearSearchInputAfterDelay: false,
              collection: self.addedVendors
            }
          }],
          ".remove-vendor": [{
            module: "vendor",
            viewType: "boxList",
            options: {
              showLabel: false,
              showAddItem: false,
              clearSearchInputAfterDelay: false,
              collection: self.existingVendors,
              removedCollection: self.removedVendors
            }
          }],
          ".date-picker": [{
            module: "creatives",
            viewType: "date_picker",
            options: self.dateOptions
          }]
        },
        serialize: function () {
          var mediaNotVideo = collection[0].media_type !== "video";
          var is3PAS = collection[0].t1as === "0" && mediaNotVideo;
          return {
            consistencyEnabled: self.consistencyEnabled,
            securityDisabled: self.ignoreSslCheck(),
            showMRAID: is3PAS,
            isT1AS: collection[0].t1as === "1" && mediaNotVideo,
            is3PAS: is3PAS
          };
        }
      });
    },
    ignoreSslCheck: function () {
      var collection = this.collection.toJSON();
      var securityDisabled = false;
      for (var i = 0; i < collection.length; i++) {
        if (collection[i].atomic_creative_ssl_validator && collection[i].atomic_creative_ssl_validator.ignored === "NO") {
          switch (collection[i].atomic_creative_ssl_validator.is_https) {
            case "Yes":
            case "No":
            case "Pending":
              securityDisabled = true;
              break;
            case "Error":
            case "Inactive":
            case "Unknown":
              securityDisabled = securityDisabled ? true : false;
              break;
          }
        }
      }
      return securityDisabled;
    },
    load: function () {
      var self = this;
      var removeClassificationsTagList;
      self.render().then(function () {
        if (self.layout) {
          self.layout.load();
          self.makeTooltips();
          if (self.entityType === "video") {
            self.el.find(".no-video").hide();
            self.el.find(".actValue1").val("true");
            self.el.find(".actValue0").val("false");
          }
          removeClassificationsTagList = self.el.find(".remove-classification")[0];
          removeClassificationsTagList.tags = self.existingClassifications;
          removeClassificationsTagList.addEventListener("tag-remove", function (event) {
            self.removedClassifications = event.detail.removedTags;
          });
        }
      });
    },
    canUnload: function () {
      return true;
    },
    makeTooltips: function () {
      T1.Tooltip(this.el, {
        gravity: "ne",
        tooltipEle: ".entity-tip"
      });
    },
    handleItemChecked: function (e) {
      e.preventDefault();
      var self = this;
      var $el = $(self.el);
      var $checkbox = $(e.target);
      var $inputHolder = $checkbox.parent().siblings(".form-drawer");
      var isChecked;
      if ($inputHolder.find(".security").attr("data-bind") === "is_https" && this.ignoreSslCheck()) {
        return;
      }
      $checkbox.toggleClass("checked");
      isChecked = $checkbox.hasClass("checked");
      if (isChecked) {
        $inputHolder.show();
        $inputHolder.attr("data-bind", "visible");
      } else {
        $inputHolder.hide();
        $inputHolder.attr("data-bind", "hidden");
      }
      if ($checkbox.hasClass("addClassification")) {
        $el.find(".reviewOrAssignBtn span").text(isChecked ? "continue" : "review");
        self.assignAfterPost = isChecked;
      }
    },
    checkValidSelection: function () {
      var self = this;
      var primary = self.collection.models;
      var fileType;
      var error;
      _.each(primary, function (item) {
        fileType = item.get("file_type");
        if (fileType.indexOf("html5") > -1 || fileType.indexOf("swf") > -1) {
          self.isHtmlOrSwf = true;
        }
      });
      if (self.isHtmlOrSwf) {
        error = {
          title: "MRAID cannot be set when bulk edit includes HTML5 or SWF creatives",
          bodyMsg: "Please ensure that all selected creatives are of one type or the other."
        };
      }
      return error;
    },
    displayAlert: function (alertData) {
      var alertMsg = new T1Alert();
      var defaultData = {
        buttons: [{
          text: "Close",
          class: "save",
          click: function () {
            var $dialog = $(this);
            $dialog.dialog("close");
          }
        }],
        icon: "error-icon"
      };
      $.extend(alertData, defaultData);
      alertMsg.initialize(alertData);
      alertMsg.show();
    },
    handleMraidSelected: function (e) {
      var self = this;
      var $target = $(e.target);
      var selectValue = $target.hasClass("checked");
      var $formDrawer = $target.parent().siblings(".form-drawer");
      var selectionError;
      self.isMraid = selectValue;
      if (selectValue) {
        selectionError = self.checkValidSelection();
      }
      if (selectionError) {
        self.displayAlert(selectionError);
        $target.toggleClass("checked");
        $formDrawer.hide();
        $formDrawer.attr("data-bind", "hidden");
        return;
      }
    },
    handleMraidTypeSelect: function (e) {
      var selectValue = e.currentTarget.value;
      var $expandableSelect = $(".mraid-expandable-select");
      var modelCollection = this.model.collection.models;
      if (selectValue === "Expandable") {
        $expandableSelect.show();
        this.buildExpandSelectBox($expandableSelect, modelCollection);
      } else {
        $expandableSelect.hide();
      }
    },
    buildExpandSelectBox: function (target, modelCollection) {
      var fileType;
      var isHtmlOrSwf = false;
      var selectList = "<strand-dropdown " + 'placeholder="Select Expandable File" ' + 'name="expandable" ' + 'data-bind="expandable" ' + 'type="expandable" ' + 'class="expandable-select">';
      _.each(modelCollection, function (item) {
        fileType = item.get("file_type");
        isHtmlOrSwf = fileType.indexOf("html5") > -1 || fileType.indexOf("swf") > -1;
        if (!isHtmlOrSwf) {
          selectList += "<strand-list-item " + 'value="' + item.get("file_name") + '" ' + 'data-size-key="' + item.get("size_key") + '" ' + 'data-file-type="' + item.get("file_type") + '">' + item.get("file_name") + "</strand-list-item>";
        }
      });
      selectList += "</strand-dropdown>";
      target.html(selectList);
    },
    handleCancel: function (e) {
      e.preventDefault();
      T1.EventHub.publish("bulkedit:cancel");
    },
    handleReviewOrAssign: function (e) {
      e.preventDefault();
      this.save(e);
    },
    handleKey: function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    },
    handleConceptSelected: function (data) {
      var concept = data.model;
      this.concept = concept;
      if (!this.consistencyEnabled) {
        this.el.find(".concept-warning").slideDown();
      }
    },
    preparePostData: function (formValues) {
      if (this.isMraid && formValues.mraid_type === undefined) {
        formValues.mraid_type = "";
      }
      if (formValues.mraid_type === "Expandable" && formValues.expandable === undefined) {
        formValues.expandable = "";
      }
      if (formValues.concept && this.concept) {
        formValues.concept = this.concept;
      }
      if (formValues.concept === "") {
        formValues.concept = new Concept({
          action: "new",
          name: "Default (same as 'Name')"
        });
      }
      if (formValues.add_vendors && this.addedVendors.length) {
        formValues.add_vendors = this.addedVendors;
      } else {
        delete formValues.add_vendors;
      }
      if (formValues.remove_vendors && this.removedVendors.length) {
        formValues.remove_vendors = this.removedVendors;
      } else {
        delete formValues.remove_vendors;
      }
      if (formValues.add_classifications || formValues.remove_classifications) {
        if (this.currentClassifications) {
          formValues.current_classifications = this.currentClassifications;
        } else {
          delete formValues.current_classifications;
        }
      }
      if (formValues.remove_classifications && this.removedClassifications.length) {
        formValues.remove_classifications = this.removedClassifications;
      } else {
        delete formValues.remove_classifications;
      }
      if (formValues.file_type === "") {
        delete formValues.file_type;
      }
      return formValues;
    },
    specialValidation: function (formValues, errors) {
      var requiredField = "This is a required field.";
      var validationResult, reqFields;
      errors = errors || [];
      reqFields = {
        click_url: function () {
          return !formValues.click_url;
        },
        click_through_url: function () {
          return !formValues.click_through_url;
        },
        mraid_type: function () {
          return !formValues.mraid_type;
        },
        expandable: function () {
          return !formValues.expandable;
        }
      };
      $.each(formValues, function (key, value) {
        if (value !== undefined && value === "") {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    postData: function (formValues, success, statusInvalid) {
      var reviewBtn = this.el.find(".reviewOrAssignBtn");
      var validationErrors;
      if (this.collection.length) {
        validationErrors = this.specialValidation(formValues, CreativeUtils.validateAttrs(formValues));
        if (validationErrors) {
          statusInvalid(validationErrors);
        } else if ($.isEmptyObject(formValues)) {
          statusInvalid([], "Please select a field.");
        } else {
          this.updateFooterMessage();
          if (formValues.start_date) {
            formValues.start_date = moment.utc(formValues.start_date || this.model.get("start_date") || this.details.get("startTime")).unix();
          }
          if (formValues.end_date) {
            formValues.end_date = moment.utc(formValues.end_date || this.model.get("end_date") || this.details.get("endTime")).unix();
          }
          T1.EventHub.publish(this.assignAfterPost ? "bulkedit:assignment" : "bulkedit:review", {
            formValues: formValues
          });
        }
      }
    },
    serialize: function () {
      var data = {};
      var advertiser = this.advertiser.get("name") ? this.advertiser : this.bulkAdvertiser;
      var agency = advertiser ? advertiser.get("agency") : null;
      if (this.collection.length) {
        data.title = "Select/Edit Fields (Step 1)";
        data.advertiser = advertiser ? advertiser.get("name") : "";
        data.agency = agency ? agency.get("name") : "";
        data.count = this.collection.length;
        data.entityType = this.entityType;
        data.entities = this.collection.pluck("name");
      }
      return data;
    }
  });
  return T1.Form.ViewUtils.extend(BulkEditView);
});
