define('modules/reporting/campaigns/performance/views/performanceBarChart',['require','modules/shared/configs/charts/barChartOneAxisConfig','T1','T1View'],function (require) {
  "use strict";

  const ChartConfig = require("modules/shared/configs/charts/barChartOneAxisConfig");
  const T1 = require("T1");
  const T1View = require("T1View");
  const maxLen = 20;
  return T1View.extend({
    initialize(args) {
      this.campaign = args.campaign;
      this.campaignModel = args.campaignModel;
      this.collection = args.collection;
      this.parentClass = args.parentClass;
      this.reportModel = args.reportModel;
      this.mainModel = args.mainModel;
    },
    setupChart() {
      const {
        campaign: campaign,
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      const currencyCode = this.mainModel.getCurrencyCode(campaign, reportModel.get("focus"));
      const self = this;
      if (collection && collection.loaded && colorData) {
        const barChartConfig = ChartConfig.copy();
        const highlighted = this.reportModel.get("highlightedItem");
        const dimBars = Boolean(highlighted && highlighted.id);
        const labelFn = function (value) {
          return reportModel.formatValue(value, parentClass.yMetricObj.type, currencyCode);
        };
        reportModel.set({
          chartData: collection.toJSON()
        });
        const chartData = reportModel.getBarChartData(parentClass.dimensionKey, parentClass.dimensionName, false, dimBars);
        if (chartData.dataProvider.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        parentClass.showChart();
        barChartConfig.dataProvider = chartData.dataProvider;
        barChartConfig.graphs = chartData.graphs;
        barChartConfig.valueAxes[0].labelFunction = labelFn;
        barChartConfig.valueAxes[0].title = parentClass.yAxisLabel;
        barChartConfig.valueAxes[0].includeGuidesInMinMax = true;
        barChartConfig.categoryField = parentClass.dimensionName;
        barChartConfig.categoryAxis.labelRotation = 45;
        barChartConfig.categoryAxis.labelFunction = value => T1.Utils.trunc(value, maxLen);
        barChartConfig.categoryAxis.title = `${parentClass.xAxisLabel} (Sorted by ${parentClass.sortKey})`;
        barChartConfig.categoryAxis.titleBold = true;
        barChartConfig.listeners = [{
          event: "init",
          method: function (evt) {
            evt.chart.chartDiv.addEventListener("click", self.toggleHighlighted.bind(self));
          }
        }, {
          event: "clickGraphItem",
          method: function (evt) {
            self.toggleHighlighted(evt.item);
            evt.event.stopPropagation();
          }
        }];
        this.showGuideLines(barChartConfig.valueAxes[0], barChartConfig);
        this.performanceChart = AmCharts.makeChart("performance-chart", barChartConfig);
        parentClass.hideNoDataChart();
      }
    },
    showGuideLines(valueAxes, barChartConfig) {
      const {
        campaign: campaign,
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const campaignLabel = mainModel.get("multiCampaigns") ? "Multiple \nCampaigns" : "Campaigns";
      const yKey = this.parentClass.yKey;
      const focus = reportModel.get("focus");
      const campaignValue = this.campaignModel.get(yKey) || 0;
      const currencyCode = mainModel.getCurrencyCode(campaign, focus);
      const goalType = campaign.get("goal_type");
      const goalValue = reportModel.getGoalValue(campaign, yKey);
      const showGoal = !mainModel.get("multiCampaigns") && reportModel.checkMetricKeyGoalType(yKey, goalType);
      const difference = campaignValue - goalValue;
      const formattedDifference = reportModel.formatValue(Math.abs(difference), reportModel.get("yMetricObj").type, currencyCode);
      const differenceLabel = difference <= 0 ? `(-${formattedDifference})` : `(+${formattedDifference})`;
      const showCampaignLine = reportModel.get("campaignBenchmarkOn");
      const guides = [];
      barChartConfig.marginRight = 60;
      if (showCampaignLine && showGoal) {
        guides.push({
          above: true,
          color: difference <= 0 ? "green" : "red",
          inside: false,
          label: `${campaignLabel} ${differenceLabel}`,
          lineAlpha: 0,
          lineThickness: 1.5,
          position: "right",
          value: campaignValue
        });
        barChartConfig.marginRight = 120;
      }
      if (showCampaignLine) {
        guides.push({
          above: true,
          boldLabel: true,
          inside: false,
          label: campaignLabel,
          lineAlpha: 1,
          lineColor: "#000000",
          lineThickness: 1.5,
          position: "right",
          value: campaignValue
        });
      }
      if (showGoal) {
        guides.push({
          above: true,
          boldLabel: true,
          color: "#989898",
          dashLength: 4,
          inside: true,
          label: "Goal",
          lineAlpha: 1,
          lineColor: "#989898",
          lineThickness: 1.8,
          position: "right",
          value: goalValue
        });
      }
      valueAxes.guides = guides;
    },
    toggleHighlighted(highlightedElement) {
      event.stopPropagation();
      const highlighted = this.reportModel.get("highlightedItem");
      let newHighlighedId = highlightedElement.dataContext && highlightedElement.dataContext[this.parentClass.dimensionKey] || null;
      if (highlighted && highlighted.id === newHighlighedId) {
        newHighlighedId = null;
      }
      this.reportModel.setHighlightedItem({
        from: "bar",
        id: newHighlighedId,
        type: "strategy"
      });
    },
    updateChart(dimBars) {
      const {
        campaign: campaign,
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      const currencyCode = this.mainModel.getCurrencyCode(campaign, reportModel.get("focus"));
      if (!this.performanceChart) {
        this.setupChart();
      }
      if (collection && collection.loaded && colorData) {
        const labelFn = function (value) {
          return reportModel.formatValue(value, parentClass.yMetricObj.type, currencyCode);
        };
        reportModel.set({
          chartData: collection.toJSON()
        });
        const chartData = reportModel.getBarChartData(parentClass.dimensionKey, parentClass.dimensionName, true, dimBars);
        if (chartData.dataProvider.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        parentClass.showChart();
        this.performanceChart.dataProvider = chartData.dataProvider;
        this.performanceChart.graphs = chartData.graphs;
        this.performanceChart.valueAxes[0].labelFunction = labelFn;
        this.performanceChart.valueAxes[0].title = parentClass.yAxisLabel;
        this.showGuideLines(this.performanceChart.valueAxes[0], this.performanceChart);
        if (reportModel.get("showEmptyChart")) {
          this.performanceChart.categoryAxis.color = "#fff";
        } else {
          this.performanceChart.categoryAxis.color = "#000";
        }
        this.performanceChart.validateData();
        parentClass.hideNoDataChart();
      }
    },
    unloadChart() {
      if (this.performanceChart) {
        this.performanceChart.clear();
        this.performanceChart = null;
      }
    }
  });
});
