define('T1Collection',["jQuery", "Underscore", "T1", "T1Model", "Backbone", "When", "T1Comm", "T1Filterable", "T1Searchable"], function ($, _, T1, T1Model, Backbone, When, T1Comm, Filterable, Searchable) {
  "use strict";

  T1.Models.T1Collection = Backbone.Collection.extend({
    canCache: false,
    canCacheBackToTrue: false,
    defaultCacheExcludes: ["currentTime"],
    cacheDuration: 3e5,
    apiRoot: T1.API_ROOT,
    count: 0,
    initialize: function (models, options) {
      var settings = {
        page: 0,
        pageLimit: 100,
        isPaginated: true,
        singleFetch: false
      };
      var collection = this;
      var filterConfiguration = this.filterConfiguration;
      var searchConfiguration = this.searchConfiguration;
      options = options || {};
      $.extend(settings, options);
      this.isPaginated = settings.isPaginated;
      this.singleFetch = settings.singleFetch;
      this.pageLimit = settings.pageLimit;
      this.page = settings.page;
      this.pageLimitField = settings.pageLimitField || "page_limit";
      this.pageField = settings.pageField || "page_offset";
      this.searchField = settings.searchField || "q";
      this.fetchOptions = $.extend(this.fetchOptions, settings.fetchOptions);
      if (this.cacheExcludes && this.canCache) {
        $.merge(this.defaultCacheExcludes, this.cacheExcludes);
      }
      this.cacheExcludes = this.defaultCacheExcludes;
      function entityfetch(args) {
        if (collection.currentFetch) {
          collection.currentFetch.abort();
        }
        delete collection.currentFetch;
        collection.fetch(args);
      }
      function searchFetch(args) {
        if (collection.currentFetch) {
          collection.currentFetch.abort();
        }
        delete collection.currentFetch;
        collection.fetch(args);
      }
      function resetPage() {
        collection.page = 0;
      }
      if (filterConfiguration) {
        this.urlFilter = new Filterable(filterConfiguration.filters, entityfetch, resetPage, filterConfiguration.enableEvents);
      }
      if (searchConfiguration) {
        this.search = new Searchable(searchConfiguration, searchFetch, resetPage);
      }
    },
    canFetchMore: function () {
      var result = false;
      if (this.isPaginated && this.count / this.pageLimit > this.page + 1) {
        result = true;
      }
      return result;
    },
    url: function (params) {
      var appendString = "";
      var pageLimit = parseInt(this.pageLimit, 10);
      var searchOption = "";
      var fullOptions = $.extend(true, {}, this.fetchOptions, {
        currentTime: T1.getRandomNumber()
      }, params || {});
      var searchObj, urlString;
      if (this.urlString) {
        urlString = typeof this.urlString === "function" ? this.urlString() : this.urlString;
      }
      if (this.urlFilter) {
        appendString += this.urlFilter.getString();
      }
      if (this.isPaginated) {
        fullOptions[this.pageLimitField] = pageLimit;
        fullOptions[this.pageField] = this.page * pageLimit;
      } else {
        delete fullOptions[this.pageLimitField];
        delete fullOptions[this.pageField];
      }
      if (this.search) {
        searchObj = this.search.getCriteria();
        if (Reflect.has(searchObj, this.searchField)) {
          fullOptions = $.extend(fullOptions, searchObj);
        } else {
          delete fullOptions[this.searchField];
        }
      }
      if (!$.isEmptyObject(fullOptions)) {
        appendString += `?${$.param(fullOptions, true)}`;
      }
      return this.apiRoot + urlString + appendString + searchOption;
    },
    loaded: false,
    processOptions: function (opts) {
      var options = opts;
      options.canCache = this.canCache;
      options.cacheDuration = this.cacheDuration;
      options.cacheExcludes = this.cacheExcludes;
      options.canCacheBackToTrue = this.canCacheBackToTrue;
      options.apiRoot = this.apiRoot;
      return options;
    },
    fetch: function (opts) {
      if (this.singleFetch || this.isPaginated) {
        if (!opts || !opts.add) {
          this.unregister();
          this.page = 0;
        }
        this.currentFetch = Backbone.Collection.prototype.fetch.call(this, opts);
        T1.EventHub.publish("track:currentFetch", {
          page: this.page,
          target: this.currentFetch
        });
        return this.currentFetch;
      }
      this.fetchAllPages(opts);
    },
    unregister: function () {
      for (let i = this.models.length - 1; i >= 0; i--) {
        Backbone.Relational.store.unregister(this.models[i]);
      }
    },
    save: function (attributes, options) {
      var self = this;
      var collectionErrors;
      var statusInvalid = options && options.statusInvalid ? options.statusInvalid : $.noop;
      function i(a, b, c) {
        return function (d) {
          return a ? a(b, d, c) : b.trigger("error", b, d, c);
        };
      }
      function sync() {
        var collection = self;
        var success = options.success;
        var method = "POST";
        options.success = function (successAttributes, e, f) {
          collection.loaded = true;
          return success && success(collection, successAttributes, f);
        };
        options.statusInvalid = function (data) {
          var errors = [];
          var fieldError = data.errors.field_error;
          if ($.isArray(fieldError)) {
            $.each(fieldError, function (key, value) {
              errors.push({
                field: value.name,
                message: value.error
              });
            });
          } else {
            errors.push({
              field: fieldError.name,
              message: fieldError.error
            });
          }
          statusInvalid(errors);
        };
        options.error = i(options.error, collection, options);
        return collection.sync.call(self, method, attributes, options);
      }
      options = options || {};
      collectionErrors = this.validate ? this.validate(attributes) : {};
      if ($.isArray(collectionErrors) && collectionErrors.length) {
        statusInvalid(collectionErrors);
      } else {
        $.each(attributes, function (attr, val) {
          if (val === "" || val === undefined) {
            delete attributes[attr];
          }
        });
        sync();
      }
    },
    sync: function (action, collection, options) {
      var conflict, errorCallback, method, onError, params, postJsonObj, postObj, request, success, url;
      var self = this;
      options = self.processOptions(options || {});
      url = self.url(options.params);
      if (this.useAPIVersionOnce) {
        this.setAPIVersion();
      }
      request = action === "read" ? T1Comm.get : T1Comm.post;
      method = action === "read" ? "GET" : "POST";
      postJsonObj = action === "POSTJSON" ? collection.data : {};
      postObj = method === "POST" ? $.param(collection.data) : {};
      success = options.success || $.noop;
      conflict = options.conflict || $.noop;
      errorCallback = options.error || $.noop;
      onError = function (data) {
        var responseText, statusCode;
        if (method === "POST") {
          responseText = data;
          statusCode = $(responseText).find("status").attr("code");
          if (statusCode === "conflict") {
            T1.VersionConflictDialog.open();
            conflict();
          } else {
            errorCallback({
              code: statusCode,
              errMsg: $(responseText).find("status").text()
            });
          }
        }
      };
      if (options.httpAction === "delete") {
        method = "DELETE";
        request = T1Comm.deleteCall;
      }
      self.loaded = false;
      params = $.extend({
        sourceURL: url,
        data: action === "POSTJSON" ? postJsonObj : postObj,
        onSuccess: success,
        onError: onError,
        fixture: self.fixture || "-collectionFixture",
        postJson: action === "POSTJSON"
      }, options);
      return request(params);
    },
    postEntityParse: {},
    parse: function (data) {
      var self = this;
      var entities = data.entities;
      var entityData = [];
      var doParseEntity = T1.Utils.parseEntity;
      function parseEntity(rawData) {
        var postEntityParse = self.postEntityParse;
        var parsedEntity = doParseEntity(rawData);
        $.each(postEntityParse, function (key, modifier) {
          if (parsedEntity[key]) {
            parsedEntity[key] = modifier(parsedEntity[key], parsedEntity);
          }
        });
        return parsedEntity;
      }
      if (!entities) {
        return;
      }
      if (entities.entity) {
        if ($.isArray(entities.entity)) {
          $.each(entities.entity, function (key, entity) {
            entityData[entityData.length] = parseEntity(entity);
          });
        } else {
          entityData[entityData.length] = parseEntity(entities.entity);
        }
      }
      this.count = parseInt(entities.count || 0, 10);
      this.loaded = true;
      return entityData;
    },
    fetchNextPage: function () {
      if (this.isPaginated && this.count / this.pageLimit >= this.page + 1) {
        this.page = this.page + 1;
        this.fetch();
      }
    },
    fetchPreviousPage: function () {
      if (this.isPaginated && this.page > 0) {
        this.page = this.page - 1;
        this.fetch();
      }
    },
    fetchMore: function (args) {
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          silent: args.silent || false,
          add: true,
          success: args.success || $.noop,
          fromFetchAll: true
        });
      }
    },
    updatePageLimit: function (num) {
      if (typeof num === "string") {
        num = parseInt(num, 10);
      }
      if (typeof num !== "number") {
        throw new Error("updatePageLimit method needs to be a number");
      }
      this.pageLimit = num;
      this.page = 0;
    },
    fetchAllPages: function (options) {
      var self = this;
      var wrapper = options && options.wrapper || "entities";
      options = options || {};
      this.isPaginated = true;
      this.pageLimit = options.pageLimit || this.pageLimit;
      this.fetchOptions = $.extend(true, {}, this.fetchOptions, options.fetchOptions || {});
      this.fetch({
        silent: options.silent || false,
        success: function () {
          var pageLimit = self.pageLimit;
          var morePagesLen = self.count > 0 ? Math.ceil(self.count / pageLimit) - 1 : 0;
          var success = options.success;
          var models = self.models;
          var deferred = [];
          var promises = [];
          var morePages = [];
          var fetchMoreSuccess = function (collection, resp) {
            const index = parseInt(resp[wrapper].start, 10) / pageLimit;
            var zeroBasedIndex = index - 1;
            morePages[zeroBasedIndex] = models.splice(pageLimit, models.length - pageLimit);
            deferred[zeroBasedIndex].resolve();
          };
          var i;
          for (i = 0; i < morePagesLen; i++) {
            deferred.push(When.defer());
            promises.push(deferred[i].promise);
            self.fetchMore({
              silent: true,
              success: fetchMoreSuccess
            });
          }
          if (success) {
            if (morePagesLen > 0) {
              When.all(promises).then(function () {
                self.models = models.concat(...morePages);
                success.call(self, self);
              });
            } else {
              success.call(self, self);
            }
          }
        }
      });
    },
    refreshOnNextFetch: function () {
      this.canCache = false;
      this.canCacheBackToTrue = true;
    }
  });
  const T1CollectionExtend = T1.Models.T1Collection.extend;
  T1.Models.T1Collection.extend = function (...args) {
    return T1CollectionExtend.apply(this, args);
  };
  return T1.Models.T1Collection;
});
