define('T1',["Underscore", "window", "jQuery", "Backbone", "Hogan", "T1Currencies", "jslibs/xml2json/xml2json", "jQueryUI", "jQPlugins/jquery.tinypubsub", "jslibs/submodules/datejs/build/date", "jslibs/submodules/datejs/build/time", "jslibs/xdate/xdate"], function (_, window, $, Backbone, Hogan, t1Currencies) {
  "use strict";

  var T1 = {};
  var lastScrollLeft;
  var defaultNumericValue = "--";
  var isDefaultFormattedValue = function (valueResult, callerDefault) {
    if (callerDefault !== undefined && callerDefault !== null && callerDefault === valueResult) {
      return true;
    }
    if (valueResult === defaultNumericValue) {
      return true;
    }
    return false;
  };
  T1.API_KEY = "pgb5qztfp7v6v82w4f7mxcm4";
  T1.API_ROOT = COMPASS_ENV.API_BASE;
  T1.API_APPS_ROOT = COMPASS_ENV.API_APPS_ROOT;
  T1.RPT_API_ROOT = COMPASS_ENV.RPT_API_BASE;
  T1.RPT_SAVED_REPORTS_API_ROOT = COMPASS_ENV.RPT_SAVED_REPORTS_API_BASE;
  T1.VIDEO_API_BASE = COMPASS_ENV.VIDEO_API_BASE;
  T1.PINT_API_BASE = COMPASS_ENV.PINT_API_BASE;
  T1.APPS_API_BASE = COMPASS_ENV.APPS_API_BASE;
  T1.SEGMENT_API_BASE = COMPASS_ENV.SEGMENT_API_BASE;
  T1.NEMO_API_BASE = `${COMPASS_ENV.API_BASE}nemo/`;
  T1.ANALYTICS_API_BASE = COMPASS_ENV.ANALYTICS_API_BASE;
  T1.ENV = COMPASS_ENV;
  T1.NO_ACCESS = false;
  T1.Date = Date;
  function hoganRender(text, hoganData, currencyCode, isDecimal) {
    return T1.Utils.markupCurrency(Hogan.compile(text).render(hoganData), null, currencyCode, isDecimal);
  }
  function mustacheRender(text, render, currencyCode, isDecimal) {
    return T1.Utils.markupCurrency(render(text), null, currencyCode, isDecimal);
  }
  T1.Utils = {
    isFunc(item) {
      return typeof item === "function";
    },
    isString(item) {
      return typeof item === "string";
    },
    isObj(item) {
      return typeof item === "object";
    },
    isEmptyObj(item) {
      if (item && T1.Utils.isObj(item)) {
        return !Object.keys(item).length;
      }
      throw Error(`"isEmptyObj" function expects an object, received ${typeof item} instead.`);
    },
    objectTOArray: function (entity) {
      var result = [];
      if (!$.isArray(entity)) {
        result.push(entity);
      } else {
        result = entity;
      }
      return result;
    },
    hasOwnProp: function (obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key);
    },
    escapeMap: {
      "&": "&",
      "<": "<",
      ">": ">",
      '"': '"',
      "'": "'"
    },
    altEscapeMap: {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;"
    },
    createUnescapeMap: function () {
      var escapeMap = this.escapeMap;
      var key;
      for (key in escapeMap) {
        if (T1.Utils.hasOwnProp(escapeMap, key)) {
          this.unescapeMap[escapeMap[key]] = key;
        }
      }
    },
    unescapeMap: {},
    escapeHTML: function (string, isEscape) {
      return String(string).replace(/&(?!\w+;)|[<>"']/g, function (s) {
        return (isEscape ? T1.Utils.altEscapeMap[s] : T1.Utils.escapeMap[s]) || s;
      });
    },
    unescapeHTML: function (string) {
      return String(string).replace(/&[^\s]*;/gim, function (s) {
        return T1.Utils.unescapeMap[s] || s;
      });
    },
    equalObjects: function (obj1, obj2, ignore) {
      var result = true;
      var a;
      if (!obj1 || !obj2) {
        return false;
      }
      ignore = ignore || [];
      for (a in obj1) {
        if (!T1.Utils.hasOwnProp(obj2, a) || $.inArray(a, ignore) === -1 && obj1[a] !== obj2[a]) {
          result = false;
        }
      }
      return result;
    },
    valueToBool: function (value) {
      return !/^(?:f(?:alse)?|no?|0+)$/i.test(value) && Boolean(value);
    },
    parseXMLtoJson: function (xml) {
      var parsedJson;
      if (xml === undefined || typeof xml === "string" || !$.isXMLDoc(xml)) {
        return false;
      }
      parsedJson = $.xml2json(xml);
      return parsedJson;
    },
    parseEntity: function (object) {
      var result = {};
      var propertyAry = [];
      var entityTypesForCollection = ["budget_flight", "pixel_bundle_url", "pixel", "legacy_contract", "contracts", "contract"];
      var tempObject, type;
      object = object || {};
      $.each(object, function (key, item) {
        if (typeof item === "string") {
          result[key] = item;
        } else if (key === "prop") {
          propertyAry = $.isArray(item) ? $.merge(propertyAry, item) : propertyAry.push(item);
        } else if (key === "entity") {
          if ($.isArray(item)) {
            tempObject = {};
            $.each(item, function (subKey, entity) {
              var toPush;
              if (entityTypesForCollection.indexOf(entity.type) >= 0) {
                if (!tempObject[entity.type]) {
                  tempObject[entity.type] = [];
                }
              }
              if (tempObject[entity.type]) {
                if (!$.isArray(tempObject[entity.type])) {
                  toPush = tempObject[entity.type];
                  tempObject[entity.type] = [];
                  tempObject[entity.type].push(toPush);
                }
                tempObject[entity.type].push(T1.Utils.parseEntity(entity));
              } else {
                tempObject[entity.type] = T1.Utils.parseEntity(entity);
              }
            });
            result = $.extend(result, tempObject);
          } else {
            type = item.type;
            tempObject = T1.Utils.parseEntity(item);
            result[type] = tempObject;
          }
        }
      });
      $.each(propertyAry, function (name, value) {
        if (value.local_time) {
          result[`${value.name}_actual`] = value.value;
          result[value.name] = value.local_time.value;
        } else if (value.amount) {
          result[`${value.name}_amount`] = value.amount;
          result[value.name] = value.value;
        } else if (value.name === "errors" && value.value === undefined) {
          if (value.error) {
            result[value.name] = Array.isArray(value.error) ? value.error : [value.error];
          }
        } else {
          result[value.name] = value.value;
        }
      });
      return result;
    },
    validate: function (args) {
      var inputValues = args.inputValues;
      var mandatoryFields = args.mandatoryFields;
      var messages = args.messages;
      var errorMessage = [];
      var mandatoryFieldsCount = mandatoryFields.length;
      var fieldKey, i, mandatoryField;
      for (i = 0; i < mandatoryFieldsCount; i++) {
        fieldKey = mandatoryFields[i];
        mandatoryField = inputValues[fieldKey];
        if (mandatoryField !== undefined && mandatoryField.length === 0) {
          errorMessage.push(messages[fieldKey]);
        }
      }
      return errorMessage.length > 0 ? errorMessage : null;
    },
    getGUID: function encodeGUID(guid) {
      return guid ? (guid ^ Math.random() * 16 >> guid / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, encodeGUID);
    },
    getClientId: function () {
      if (localStorage && !localStorage.t1ClientId) {
        localStorage.t1ClientId = T1.Utils.getGUID();
      }
      return localStorage.t1ClientId;
    },
    toCamelCase: function (str) {
      if (!str || typeof str !== "string" || str.length < 1) {
        return false;
      }
      return str.trim().substr(0, 1).toLowerCase() + str.substr(1);
    },
    toInitialCaps: function (str) {
      if (!str || typeof str !== "string" || str.length < 1) {
        return false;
      }
      str = $.trim(str);
      return str.substr(0, 1).toUpperCase() + str.substr(1);
    },
    capitalize: function (str) {
      return str.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (firstChar) {
        return firstChar.toUpperCase();
      });
    },
    reverseString: function (str) {
      var reversedStr = "";
      for (let i = str.length - 1; i >= 0; i--) {
        reversedStr += str[i];
      }
      return reversedStr;
    },
    constructModelUrl: function (model, action, params) {
      var parameters = $.extend({
        currentTime: T1.getRandomNumber()
      }, params || {});
      var path = [];
      var apiRoot = model.apiRoot;
      var query, url;
      if (!model || !model.url) {
        return null;
      }
      if (typeof model.url === "function") {
        return model.url();
      }
      url = model.url.replace(/^\/|\/$/g, "");
      path.push(url);
      if (model.id && !model.skipIdInUrl) {
        path.push(model.id);
      }
      if (action && typeof action === "string") {
        path.push(action);
      }
      query = `?${$.param(parameters, true)}`;
      if (apiRoot === undefined) {
        apiRoot = T1.API_ROOT;
      }
      return apiRoot + path.join("/") + query;
    },
    getMinutes: function (convert, type) {
      var minutesInADay = 1440;
      var minutesInAnHour = 60;
      var multiplyBy;
      if (type === "minutes") {
        return convert;
      }
      if (type !== "days" && type !== "hours") {
        return false;
      }
      multiplyBy = type === "days" ? minutesInADay : minutesInAnHour;
      return Math.round(convert * multiplyBy);
    },
    getDiagonalPoints: function (event) {
      var $this = $(this);
      var offset = $this.offset();
      var height = $this.height();
      var width = $this.width();
      var x = event.pageX - offset.left;
      var y = event.pageY - offset.top;
      const diagonal1 = height * x;
      const diagonal2 = width * y;
      const area = width * height;
      return diagonal1 + diagonal2 - area;
    },
    getCurrencyList: function (options) {
      return t1Currencies.getCurrencyList(options);
    },
    formatCurrencyNoSymbol: function (num, defaultOutput, currencyCode, decimalCurrency) {
      if (defaultOutput === undefined || defaultOutput === null) {
        defaultOutput = defaultNumericValue;
      }
      if (num === undefined || num === "") {
        return defaultOutput;
      }
      return t1Currencies.formatNumber(currencyCode, num, defaultOutput, decimalCurrency);
    },
    getCurrencyObj: function (currencyCode) {
      return t1Currencies.getCurrencyObj(currencyCode);
    },
    formatCurrency: function (num, defaultOutput, currencyCode, type) {
      var T1U = T1.Utils;
      var result = T1U.formatCurrencyNoSymbol(num, defaultOutput, currencyCode);
      var currenciesRequireDirectionFlowSpan = t1Currencies.getCurrenciesRequireDirectionFlowSpan();
      var extraSpace = " ";
      var currencySymbol = T1U.getCurrencySymbol(currencyCode);
      if (currenciesRequireDirectionFlowSpan.indexOf(currencyCode) > -1) {
        switch (type) {
          case "svgText":
            currencySymbol = currencySymbol.split("").reverse().join("");
            break;
          case "tooltip":
            currencySymbol = `&#x202a;${currencySymbol}&#x202c;`;
            break;
          default:
            currencySymbol = `<span dir="LTR">${currencySymbol}</span>`;
        }
      }
      return isDefaultFormattedValue(result, defaultOutput) ? result : currencySymbol + extraSpace + result;
    },
    unformatCurrency: function (formattedText, currencyCode) {
      return t1Currencies.unformatNumber(currencyCode, formattedText);
    },
    getCurrencyDecimalSeparator: function (currencyCode) {
      return t1Currencies.getDecimalSeparator(currencyCode);
    },
    getCurrencySymbol: function (currencyCode) {
      return t1Currencies.getCurrencySymbol(currencyCode);
    },
    markupCurrency: function (num, defaultOutput, currencyCode, decimalCurrency) {
      var currencySym = T1.Utils.getCurrencySymbol(currencyCode);
      var formattedNum = T1.Utils.formatCurrencyNoSymbol(num, defaultOutput, currencyCode, decimalCurrency);
      var str = isDefaultFormattedValue(formattedNum, defaultOutput) ? formattedNum : `<span class="symbol" dir="LTR">${currencySym}</span>${formattedNum}`;
      return `<span class="currency">${str}</span>`;
    },
    markupDecimalCurrency: function (num, defaultOutput, currencyCode) {
      return T1.Utils.markupCurrency(num, defaultOutput, currencyCode, true);
    },
    templateCurrency: function () {
      var markupCurrency = T1.Utils.markupCurrency;
      return function (text, render) {
        var num = render(text);
        return markupCurrency(num);
      };
    },
    getCurrencyAsHTML: function (currencyCode, isDecimal) {
      return function () {
        return function (text, render) {
          return render === undefined ? hoganRender(text, this, currencyCode, isDecimal) : mustacheRender(text, render, currencyCode, isDecimal);
        };
      };
    },
    getCurrencyTemplateFunc: function (currencyCode) {
      return T1.Utils.getCurrencyAsHTML(currencyCode, false);
    },
    getDecimalCurrencyTemplateFunc: function (currencyCode) {
      return T1.Utils.getCurrencyAsHTML(currencyCode, true);
    },
    validatePhone: function (str) {
      var pattern = /^[0-9]{3}[0-9]{3}[0-9]{4}$/;
      const part1 = 3;
      const part2 = 6;
      const part3 = 9;
      str = str.replace(/-|\(|\)|\s/g, "");
      if (pattern.test(str)) {
        return `(${str.substring(0, part1)}) ${str.substring(part1, part2)}-${str.substring(part2, part3)}`;
      }
      return "";
    },
    validateZip: function (str) {
      var returnZip;
      var pattern = /^[0-9]{5}[\s|-]{0,1}[0-9]?\d{0,4}$/;
      const zipLength = 5;
      const zipPlus4 = 9;
      if (pattern.test(str)) {
        str = str.replace(/\s|-/g, "");
        returnZip = str.substring(0, zipLength);
        if (str.length === zipPlus4) {
          return `${returnZip}-${str.substring(zipLength, zipPlus4)}`;
        }
        return returnZip;
      }
      return "";
    },
    validateEmail: function (str) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);
    },
    getABtest: function (User) {
      const numOfUserTypes = 2;
      return User.getUser().id % numOfUserTypes === 1;
    },
    highlightText: function (text, term) {
      var regex = new RegExp(term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "gi");
      return text.replace(regex, '<span class="highlighted-term">$&</span>');
    },
    boldLastPartOfFullPath: function (inputConfig) {
      var fullPath, lastIndexOfSeparator, pathEnd, pathPart, separator, separatorLen, separatorNotFound;
      if (!inputConfig || !inputConfig.fullPath) {
        return "";
      }
      if (!inputConfig.separator) {
        return inputConfig.fullPath;
      }
      fullPath = inputConfig.fullPath;
      separator = inputConfig.separator;
      separatorLen = separator.length;
      lastIndexOfSeparator = fullPath.lastIndexOf(separator);
      separatorNotFound = lastIndexOfSeparator < 0;
      pathPart = separatorNotFound ? "" : fullPath.substring(0, lastIndexOfSeparator + separatorLen);
      pathEnd = separatorNotFound ? fullPath : fullPath.substr(lastIndexOfSeparator + separatorLen);
      if (inputConfig.searchTermToBeHighlighted && inputConfig.searchTermToBeHighlighted.length > 0) {
        pathEnd = this.highlightText(pathEnd, inputConfig.searchTermToBeHighlighted);
        pathPart = this.highlightText(pathPart, inputConfig.searchTermToBeHighlighted);
      }
      return `${pathPart}<span class="strong">${pathEnd}</span>`;
    },
    getHostDomain: function () {
      var getHostname = function (url) {
        var m = url.match(/^https:\/\/[^/]+/);
        return m ? m[0] : null;
      };
      var urlFavicon = $("link").attr("href");
      var host = getHostname(urlFavicon);
      const pathPart = 3;
      const pathPart2 = 4;
      if (host !== null) {
        const locations = urlFavicon.split("/");
        host += `/${locations[pathPart]}/${locations[pathPart2]}`;
        host += "/";
      }
      return host;
    },
    trunc: function (text, maxLength) {
      if (!text || text.length <= maxLength) {
        return text;
      }
      return `${text.slice(0, maxLength).trim()}...`;
    },
    appendStylesheet: function () {
      return function (url, callback, scope, id) {
        var path = "";
        var host;
        scope = scope || this;
        if (!url || typeof url !== "string") {
          return false;
        }
        url = url.trim();
        if (url === "") {
          return false;
        }
        host = this.getHostDomain();
        if (host !== null) {
          path = `${host}/`;
        }
        path += `css/${url}.css`;
        $.get(path, function (response) {
          var $appendedStyles, cssWithFixedPaths;
          if (!$(`style[id="${id}"]`).length) {
            $("head").append(`<style id="${id}"></style>`);
          }
          if (host !== null) {
            cssWithFixedPaths = response.replace(/..\/images/g, `${host}/images`);
          } else {
            cssWithFixedPaths = response.replace(/..\/images/g, "./images");
          }
          $appendedStyles = $(`style[id="${id}"]`);
          $appendedStyles.text(cssWithFixedPaths);
          StyleFix.styleElement($appendedStyles[0]);
          if (callback) {
            callback.call(scope);
          }
        });
      };
    }(window),
    hasPolymer: function (polymer) {
      var canUsePolymer = polymer && polymer.dom;
      return function (element) {
        return canUsePolymer && arguments.length === 0 ? true : element instanceof HTMLElement;
      };
    }(window.Polymer)
  };
  T1.Location = function () {
    var isReloading = false;
    var abortedURL = "";
    var abortionRootUrl = {};
    var historyStack = [];
    var defaultUrl = "index";
    var viewOptions;
    function splitLocation(loc) {
      loc = loc || window.location.hash;
      return loc.indexOf("/") > -1 ? loc.split("/") : [loc];
    }
    function get(full) {
      var loc = window.location.hash;
      var arry = splitLocation(loc);
      return full ? loc : arry[0];
    }
    function getRoute(route) {
      var routeAry = splitLocation(route);
      var routeObj;
      const part2 = 2;
      const part3 = 3;
      const part4 = 4;
      if (routeAry[0].indexOf("audiences") > -1 || routeAry[0].indexOf("reports") > -1) {
        routeObj = {
          module: routeAry[0],
          mode: routeAry[1],
          section: routeAry[part2],
          id: routeAry[part3],
          deepLinks: _.rest(routeAry, part4)
        };
      } else {
        routeObj = {
          module: routeAry[0],
          mode: routeAry[1],
          id: routeAry[part2],
          deepLinks: _.rest(routeAry, part3)
        };
      }
      return routeObj;
    }
    function pop() {
      return historyStack.pop();
    }
    function push(url) {
      var lastItem;
      var needToPush = true;
      if (historyStack.length > 0) {
        lastItem = historyStack[historyStack.length - 1];
        if (lastItem === url || lastItem === `#${url}`) {
          needToPush = false;
        }
      }
      if (needToPush === true) {
        historyStack.push(url);
      }
    }
    function reload() {
      return window.location.reload(true);
    }
    function set(loc, opts) {
      var current = get();
      var hashArry = loc.split("#");
      var URLStr = hashArry[1] || hashArry[0];
      var firstChar = URLStr.substring(0, 1);
      var hasAPage = firstChar !== "/" && URLStr !== "";
      var newURL = hasAPage ? `#${URLStr}` : current + URLStr;
      var path = window.location.href.split("#")[0];
      opts = opts || {};
      isReloading = false;
      if (opts.external) {
        newURL = path + URLStr;
      }
      if (opts.viewOptions) {
        viewOptions = opts.viewOptions;
      }
      if (opts.replaceHistoryStack) {
        pop();
        push(newURL);
      }
      if (opts.silent && Backbone.history !== undefined) {
        return Backbone.history.navigate(newURL);
      } else if (Backbone.history === undefined) {
        return;
      }
      if (opts.replace) {
        return window.location.replace(newURL);
      }
      if (opts.reload) {
        isReloading = true;
        if (URLStr !== current) {
          window.location.replace(newURL);
        }
        return window.location.reload(true);
      }
      T1.Location.push(newURL);
      window.location.assign(newURL);
    }
    function goBack(count, fromCancelAction) {
      var previousUrl;
      const secondToLast = 2;
      if (!fromCancelAction) {
        count = count || secondToLast;
        previousUrl = historyStack[historyStack.length - count];
      } else {
        const curUrl = historyStack[historyStack.length - 1];
        const currUrl = curUrl && curUrl.replace(/^#/, "");
        const prevUrl = historyStack[historyStack.length - secondToLast];
        const getModule = function (url) {
          var module = url.substr(0, url.indexOf("/")).replace(/^#/, "");
          return module === "campaign" || module === "strategy" ? "c&s" : module;
        };
        previousUrl = prevUrl;
        if (currUrl && prevUrl && getModule(currUrl) === getModule(prevUrl)) {
          const indexPlain = historyStack.indexOf(currUrl);
          const indexHashed = historyStack.indexOf(`#${currUrl}`);
          const indexFound = indexHashed > -1 ? Math.min(indexPlain, indexHashed) : indexPlain;
          if (indexFound > 0) {
            previousUrl = historyStack[indexFound - 1];
            historyStack = historyStack.slice(0, indexFound + 1);
          }
        }
      }
      set(previousUrl || defaultUrl);
    }
    function gotToAbortionRootURL() {
      var urlStr = "";
      if (!$.isEmptyObject(abortionRootUrl)) {
        urlStr = abortionRootUrl.module;
        $.each(abortionRootUrl, function (key, value) {
          if (key !== "module" && value) {
            urlStr += `/${$.isArray(value) ? value.join("/") : value}`;
          }
        });
      }
      set(urlStr, {
        replace: true,
        silent: true
      });
    }
    function setAbortedURL(urlStr) {
      abortedURL = urlStr;
    }
    function setAbortionRootURL(urlObj) {
      abortionRootUrl = urlObj;
      gotToAbortionRootURL();
    }
    function getAbortionRootURL() {
      return abortionRootUrl;
    }
    function getAbortedURL() {
      return abortedURL !== "" ? abortedURL : false;
    }
    function resetAbort() {
      abortedURL = "";
      abortionRootUrl = {};
    }
    function goToAbortedURL() {
      if (abortedURL !== "") {
        set(abortedURL);
        resetAbort();
      }
    }
    function getViewOptions() {
      var options;
      if (viewOptions) {
        options = $.extend(true, {}, viewOptions);
        viewOptions = undefined;
        return options;
      }
    }
    return {
      get: get,
      set: set,
      getAbortionRootURL: getAbortionRootURL,
      setAbortionRootURL: setAbortionRootURL,
      getAbortedURL: getAbortedURL,
      setAbortedURL: setAbortedURL,
      goToAbortedURL: goToAbortedURL,
      resetAbort: resetAbort,
      isReloading: function () {
        return isReloading;
      },
      pop: pop,
      push: push,
      goBack: goBack,
      reload: reload,
      getRoute: getRoute,
      getViewOptions: getViewOptions
    };
  }();
  T1.Logger = function () {
    var console = !window.console ? {} : window.console;
    var logger;
    function windowsLogger(arg) {
      console.log(arg);
    }
    console.log = console.log || function () {};
    console.warn = console.warn || function () {};
    console.error = console.error || function () {};
    console.info = console.info || function () {};
    logger = typeof console.log !== "function" ? console.log : windowsLogger;
    return {
      log: function (...args) {
        var foot, i, numOfStatments, statments, title;
        if (args.length > 1) {
          title = `*******${args.shift()}*******`;
          foot = "*************************";
        }
        statments = args;
        numOfStatments = statments.length;
        if (title) {
          logger(title);
        }
        for (i = 0; i < numOfStatments; i++) {
          logger(statments[i]);
        }
        if (foot) {
          logger(foot);
        }
        return true;
      }
    };
  }();
  T1.EventHub = function () {
    function isNotValidEvent(str) {
      return !str || typeof str !== "string" || str.length < 1;
    }
    function publish(event, args) {
      $.publish(event, args);
      return true;
    }
    function subscribe(event, fn) {
      function subSingleEvent(e, callback) {
        var i, multiEvents;
        if (!callback || typeof callback !== "function" || isNotValidEvent(e)) {
          return false;
        }
        multiEvents = e.split(",");
        for (i = 0; i < multiEvents.length; i++) {
          $.subscribe(multiEvents[i].trim(), callback);
        }
        return true;
      }
      function subMultipleEvent(obj) {
        var isEventSubscribed = true;
        $.each(obj, function (e, callback) {
          if (_.isFunction(callback)) {
            isEventSubscribed = subSingleEvent(e, callback);
          }
        });
        return isEventSubscribed;
      }
      function isMultipleEvents() {
        return typeof event === "object" && !$.isEmptyObject(event);
      }
      return isMultipleEvents() ? subMultipleEvent(event, fn) : subSingleEvent(event, fn);
    }
    function unsubscribe(event, fn) {
      if (isNotValidEvent(event)) {
        return false;
      }
      $.unsubscribe(event, fn);
      return true;
    }
    return {
      publish: publish,
      subscribe: subscribe,
      unsubscribe: unsubscribe
    };
  }();
  T1.Queue = function (opts) {
    var isactive = true;
    var isrunning = false;
    var onEnd = opts.onEnd || $.noop;
    var queue = [];
    var collection = opts.collection;
    var count = collection ? collection.length : 0;
    var index = 0;
    var item;
    collection.at = collection.at || function (i) {
      return collection[i];
    };
    function isActive() {
      return isactive;
    }
    function isRunning() {
      return isrunning;
    }
    function pause() {
      isactive = false;
      isrunning = false;
      $.each(queue, function (i, n) {
        if (n && n.pause) {
          n.pause();
        }
      });
    }
    function size() {
      return queue.length;
    }
    function flush() {
      isactive = false;
      isrunning = false;
      $.each(queue, function (i, n) {
        if (n && n.abort) {
          n.abort();
        }
      });
      queue.length = 0;
      collection = null;
    }
    function add(obj) {
      if (!isactive) {
        return;
      }
      queue.push(obj);
    }
    function loadQueue() {
      if (index >= count || !isactive || !collection) {
        isrunning = false;
        onEnd();
        return !isrunning;
      }
      isrunning = true;
      item = collection.at(index);
      if (!item) {
        isrunning = false;
        onEnd();
        return !isrunning;
      }
      add(opts.action(item, index));
      index++;
      setTimeout(loadQueue, 0);
      return true;
    }
    function resume() {
      isactive = true;
      $.each(queue, function (i, n) {
        if (n && n.resume) {
          n.resume();
        }
      });
      loadQueue();
    }
    return {
      load: loadQueue,
      size: size,
      flush: flush,
      pause: pause,
      resume: resume,
      isActive: isActive,
      isRunning: isRunning
    };
  };
  T1.RequestQueue = function () {
    return {
      create: function (opts) {
        if (!opts || !opts.collection || typeof opts.collection !== "string" && opts.collection.length === undefined || !opts.action || typeof opts.action !== "function") {
          return null;
        }
        return new T1.Queue(opts);
      }
    };
  }();
  T1.VersionConflictDialog = function () {
    function open() {
      require(["text!templates/versionConflict.html", "T1Tooltip"], function (versionConflictTemplate) {
        var $versionDialog = $(versionConflictTemplate).dialog({
          modal: true,
          closeText: "",
          dialogClass: "w-VersionConflictDialog",
          autoOpen: true,
          buttons: [{
            text: "Confirm",
            class: "confirm",
            click: function () {
              $(this).dialog("close");
              $(this).remove();
              T1.EventHub.publish("versionConflictDialog:close");
            }
          }]
        });
        var tooltipEle = $versionDialog.find(".t1-tooltip");
        T1.Tooltip($versionDialog, {
          className: "versionConflict-tipsy"
        });
        tooltipEle.on("click", function () {
          window.open("https://kb.mediamath.com/wiki/display/t1kb/Conflicting+Edits");
        });
      });
    }
    return {
      open: open
    };
  }();
  T1.Formats = {
    string: function () {
      return function (n) {
        return n;
      };
    },
    dollars: function () {
      var formatter = d3.format(",.4f");
      return function (n) {
        return T1.Utils.formatCurrency(formatter(n));
      };
    },
    date: function (ctx) {
      var stringFormat = ctx && ctx.dateformat || "";
      return function (str) {
        var dateObj = ctx.useUTC ? T1.parseDateAsUTC(str) : T1.Date.parse(str);
        return dateObj && dateObj.toString(stringFormat);
      };
    },
    rolledUpNumbers: function () {
      return d3.format("8,.5s");
    },
    rolledUpNumbersShort: function () {
      return d3.format("3.3s");
    },
    integers: function () {
      return d3.format("8,r");
    },
    percent: function () {
      const DECIMALS = 2;
      return function (n) {
        var num = parseFloat(n);
        return `${num.toFixed(DECIMALS)}%`;
      };
    },
    float: function () {
      return d3.format("8,.2f");
    },
    ratio: function () {
      return d3.format("8,.2f");
    },
    as: function (type) {
      var format = type === "currency" ? "dollars" : type;
      var formatter = T1.Formats[format] || T1.Formats.string;
      var self = this;
      return function (text) {
        return formatter(self)(text);
      };
    }
  };
  T1.compareDates = function (date1, date2) {
    if (!date1 || !date2) {
      throw new Error("T1.compareDates needs two dates as input parameters");
    }
    function getDate(dateObj) {
      return dateObj && dateObj.getMonth ? dateObj : Date.parse(dateObj);
    }
    date1 = getDate(date1);
    date2 = getDate(date2);
    if (!date1 || !date2) {
      throw new Error("T1.compareDates needs valid date as input parameters");
    }
    return date1.compareTo(date2);
  };
  T1.getNumDays = function (date1, date2) {
    if (!date1 || !date2) {
      throw new Error("T1.getNumDays needs two dates as input parameters");
    }
    function getDate(dateObj) {
      return dateObj && dateObj.getMonth ? dateObj : Date.parse(dateObj);
    }
    date1 = getDate(date1);
    date2 = getDate(date2);
    if (!date1 || !date2) {
      throw new Error("T1.getNumDays needs valid date as input parameters");
    }
    return new TimeSpan(T1.compareDates(date1, date2) === 1 ? date1 - date2 : date2 - date1).days;
  };
  T1.today = function () {
    var now = new Date();
    var offset = now.getTimezoneOffset();
    now.addMinutes(offset);
    return now;
  };
  T1.parseDateAsUTC = function (dateStr) {
    return new XDate(dateStr, true);
  };
  T1.getRandomNumber = function () {
    return Date.today().setTimeToNow().getTime();
  };
  T1.repositionLeft = function (el, scrollAmt) {
    var scope = el || "body";
    var fixedEls = $(".fixwidth-resize, .fixposition", scope);
    var scrollToAmt = scrollAmt ? parseInt(scrollAmt, 10) : 0;
    var docOffset = $(document).width() - $(window).width();
    $.each(fixedEls, function (i, n) {
      var fixedEl = $(n);
      var fixedElParent = fixedEl.parent();
      var parentLeft = fixedElParent.offset().left + parseInt(fixedElParent.css("paddingLeft"), 10) + parseInt(fixedElParent.css("borderLeftWidth"), 10);
      var leftOffset = scrollToAmt < 0 ? 0 : scrollToAmt > docOffset ? docOffset : scrollToAmt;
      var calcLeft = parentLeft - leftOffset;
      fixedEl.css("left", calcLeft);
      if (fixedEl.hasClass("padwidth")) {
        fixedEl.css("paddingRight", 1 - calcLeft);
      }
    });
  };
  T1.resizeWidth = function (el) {
    var fixedEls;
    var scope = el || "body";
    fixedEls = $(".fixwidth-resize", scope);
    $.each(fixedEls, function (i, n) {
      var fixedEl = $(n);
      fixedEl.width(fixedEl.parent().innerWidth());
    });
    T1.repositionLeft(scope, $(document).scrollLeft());
  };
  T1.Analytics = function () {
    const sampleRate = 100;
    const _GA_logPageview = function (pageURL) {
      if (pageURL) {
        window._gaq.push(["_setSiteSpeedSampleRate", sampleRate]);
        window._gaq.push(["_trackPageview", pageURL]);
      }
    };
    var _GA_logEvent = function (category, action, opt_label, opt_value) {
      window._gaq.push(["_trackEvent", category, action, opt_label || "", Number(opt_value) || 0]);
    };
    var _GA_logTiming = function (category, variable, time, opt_label, opt_value) {
      window._gaq.push(["_trackTiming", category, variable, time, opt_label || "", Number(opt_value) || 0]);
    };
    return {
      logPageview: function (pageURL) {
        _GA_logPageview(pageURL);
      },
      logEvent: function (category, action, opt_label, opt_value) {
        _GA_logEvent(category, action, opt_label, opt_value);
      },
      logTiming: function (category, variable, time, opt_label, opt_value) {
        if (COMPASS_ENV.DISABLE_SEND_PERF_GOOGLE) {
          T1.log(`window._gaq.push(['_trackTiming',${category},${variable},${time},${opt_label},${opt_value}]);`);
        } else {
          _GA_logTiming(category, variable, time, opt_label, opt_value);
        }
      }
    };
  }();
  T1.log = T1.Logger.log;
  T1.Utils.createUnescapeMap();
  T1.appVariables = {};
  $(window).on("scroll", function () {
    var dScrollLeft = $(document).scrollLeft();
    if (lastScrollLeft === dScrollLeft) {
      return;
    }
    $(document).trigger("scrollLeft", dScrollLeft);
    lastScrollLeft = dScrollLeft;
  });
  $(window).on("resize", function () {
    T1.resizeWidth();
  });
  $(document).on("scrollLeft", function (e, scrollAmt) {
    T1.repositionLeft("body", scrollAmt);
  });
  return T1;
});
