define('modules/reporting/campaigns/views/campaign_reports',['require','jQuery','models/campaign','../models/reportingModels','T1','T1Comm','T1Layout','T1Permissions','T1TabLayoutV2','T1View','text!../templates/campaign_reports.html','models/userPreferences','modules/reporting/campaigns/utils/reportingUtils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Campaign = require("models/campaign");
  const RPTGModels = require("../models/reportingModels");
  const T1 = require("T1");
  const T1Comm = require("T1Comm");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1TabLayout = require("T1TabLayoutV2");
  const T1View = require("T1View");
  const template = require("text!../templates/campaign_reports.html");
  const UserPreferences = require("models/userPreferences");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  let reportState = null;
  const $document = $(document);
  const T1Location = T1.Location;
  const dateformat = "yyyy-MM-dd";
  const eventHub = T1.EventHub;
  const defaultLocationDataExport = "#reports/campaigns/dataExport";
  const sectionNameHash = Object.freeze({
    "campaign performance": "performance",
    contextual_insights: "contextual",
    contextual: "contextual",
    data_export: "dataExport",
    "data export": "dataExport",
    device_technology: "technology",
    geo: "geoPerformance",
    "geo performance": "geoPerformance",
    performance: "performance",
    reach_frequency: "reachFrequency",
    "reach & frequency": "reachFrequency",
    site_transparency: "siteTransparency",
    "site transparency": "siteTransparency",
    technology: "technology"
  });
  const sectionTabHash = {
    performance: ["performance", 0],
    siteTransparency: ["site_transparency", 1],
    geoPerformance: ["geo", 2],
    technology: ["device_technology", 3],
    reachFrequency: ["reach_frequency", 4],
    dataExport: ["data_export", 5]
  };
  return T1View.extend({
    template: template,
    id: "",
    canLoad: false,
    defaultSection: "performance",
    defaultSubSection: {
      dataExport: "",
      geoPerformance: "",
      performance: "daily",
      siteTransparency: "",
      technology: ""
    },
    focus: "all",
    section: "performance",
    selectedCurrency: "campaign",
    chartShown: "",
    subSection: "daily",
    trackingSection: "Reporting - Performance",
    initialize() {
      const self = this;
      const models = RPTGModels;
      const {
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportModel: reportModel
      } = models;
      let locationHashArray = T1Location.get(true).split("/");
      const [, mode, section,, subSection] = locationHashArray;
      let [,,, id] = locationHashArray;
      function getSettings() {
        return UserPreferences.get(`ui.reporting.${self.section}.subSection`) || self.defaultSubSection[self.section];
      }
      function updateRoute(silent) {
        T1Location.set(locationHashArray.join("/"), {
          silent: silent
        });
      }
      Object.assign(this, {
        dataExportIndex: 4,
        hasViewabilityFlag: T1Permissions.check("feature", "reporting_viewability"),
        hasEcoCostFlag: T1Permissions.check("feature", "reporting_ecosystem_cost"),
        mainMeta: mainMeta,
        mainModel: mainModel,
        models: models,
        reportModel: reportModel,
        showContextualTab: T1Permissions.check("feature", "reporting_contextual_tab")
      });
      if (this.showContextualTab) {
        sectionTabHash.contextual = ["contextual_insights", 5];
        sectionTabHash.dataExport = ["data_export", 6];
      }
      if (mode === "campaigns") {
        this.section = Reflect.has(sectionTabHash, section) ? section : this.defaultSection;
        if (!section) {
          locationHashArray.push(this.section);
        }
        if (id === "edit" || id === "create") {
          id = null;
        }
        this.id = !Reflect.has(sectionTabHash, id) ? id : null;
        this.subSection = subSection || getSettings();
        if (subSection && section !== "dataExport") {
          locationHashArray.length = 4;
        }
        if (mainModel && reportModel && id !== mainModel.get("reportId")) {
          reportModel.resetUIStates();
          if (reportModel.resetTechFilters) {
            reportModel.resetTechFilters();
          }
        }
        updateRoute(true);
      } else {
        if (mode) {
          this.section = Reflect.has(sectionTabHash, mode) ? mode : this.defaultSection;
          this.subSection = id || getSettings();
          this.id = section || null;
        } else {
          const mainTabSection = mainModel.get("mainTabSection");
          this.section = Reflect.has(sectionTabHash, mainTabSection) ? mainTabSection : this.defaultSection;
          this.subSection = getSettings();
          this.id = null;
        }
        locationHashArray = ["reports/campaigns", this.section];
        if (this.id) {
          locationHashArray.push(this.id);
        }
        updateRoute(false);
        return;
      }
      eventHub.publish("updateNavPath");
      this.multiCampaigns = this.id && this.id.includes("-");
      this.report = {
        end: Date.parse("today").toString(dateformat),
        id: this.multiCampaigns ? this.id.replace(/-/g, ",") : this.id,
        type: this.section
      };
      this.model = new Campaign({
        id: this.id
      });
      models.campaign = this.model;
      models.summary.id = this.id;
      models.reportState = reportState;
      if (this.id && reportState && reportState.id !== this.id) {
        this.resetStateObjects();
      }
      mainModel.setReportId(this.report.id);
      mainModel.setMainTabSection(this.section, sectionTabHash[this.section][1]);
      mainModel.setSection(sectionTabHash[this.section][0], true);
      mainModel.setSubSection(this.section, this.subSection);
      this.report.start = Utils.defaultStartDate.toString(dateformat);
      this.report.end = Utils.defaultEndDate.toString(dateformat);
      this.campaignFetchParams = {
        currency_code: "USD",
        full: "*",
        with: ["advertiser", "ad_server", "rpt_goal_monitoring", "campaign_watermarks"]
      };
      if (T1Comm.isUserLoggedIn()) {
        mainMeta.abort();
        mainMeta.fetch({
          onSuccess: function (model) {
            let focus, uriMeta;
            const reports = model.get("reports");
            const reportSection = self.section;
            switch (reportSection) {
              case "dataExport":
                if (!models.reportMeta) {
                  uriMeta = reports.performance.URI_Meta;
                  models.setupReportMeta(uriMeta);
                }
                models.setupDataExportModel();
                break;
              default:
                uriMeta = reports[sectionTabHash[reportSection][0]].URI_Meta;
                focus = self.getFocusFromUserPref(reportSection);
                models.setupReportMeta(uriMeta, reportSection).reportModel.setFocus(focus, true);
                self.focus = focus;
            }
            self.canLoad = true;
            self.initLayouts();
            self.load();
          }
        });
      }
      this.loaded = true;
    },
    events: {
      "click .icon-edit-campaign": "editCampaign",
      "hideGuideMarker .w-Reporting": "hideGuideMarker",
      "showGuideMarker .w-Reporting": "showGuideMarker"
    },
    dataEvents: {
      mainModel: {
        "change:section": "loadSectionModel",
        "change:trackingSection": function (model) {
          this.trackingSection = model.get("trackingSection");
        }
      }
    },
    eventhubEvents: {
      "organization:select": function () {
        this.resetStateObjects();
        T1.Location.set("#reports");
      },
      "chart.toggle": function (chartShown) {
        this.chartShown = chartShown ? "true" : "false";
        this.preferences.save();
        this.models.mainModel.setChartShown(this.chartShown);
      },
      "reports.initTabLayout": function () {
        if (this.loaded) {
          this.tabLayout.load();
        }
      },
      "select:campaign": function (campaignParams) {
        this.models.reportModel.resetColumnResize();
        this.changeCampaign(campaignParams);
      },
      "select:currency": function (currencySelected) {
        if (currencySelected && currencySelected !== this.selectedCurrency) {
          this.selectedCurrency = currencySelected;
          this.preferences.save();
          this.loadSectionModel();
        } else {
          this.updateCurrency();
        }
      },
      "reports:saveDate": "updateReportState",
      "select:focus": function (focus) {
        const models = this.models;
        const section = this.mainModel.get("section");
        const reportMeta = models.sectionMetas[section];
        const reportModel = models.sectionModels[section];
        models.reportMeta = reportMeta;
        models.reportModel = reportModel;
        reportModel.resetColumnResize();
        reportModel.resetUIStates();
        this.focus = focus;
        this.loadSectionModel(focus);
      },
      "tab:select": function ({
        tabLevel: tabLevel,
        tabLabel: tabLabel
      }) {
        const levelIndex = 6;
        const level = tabLevel.substr(levelIndex);
        const label = tabLabel.toLowerCase();
        const mainModel = this.mainModel;
        const campaignIDs = mainModel.get("reportIdList") || "";
        const lastPath = mainModel.get("dataExportLastPath");
        const section = sectionNameHash[label];
        let location = campaignIDs ? `reports/campaigns/${section}/${campaignIDs}` : `reports/campaigns/${section}`;
        let currentLocation = T1Location.get(true);
        if (currentLocation.includes("dataExport")) {
          if ((currentLocation.includes("dataExport/create") || currentLocation.includes("dataExport/edit")) && this.formElementsChanged) {
            return;
          }
          if (currentLocation.includes("dataExport/create") && !this.formElementsChanged) {
            currentLocation = defaultLocationDataExport;
          }
          mainModel.setDataExportLastPath(currentLocation);
        }
        if (!label) {
          this.layout.destroy();
          return;
        }
        if (level === "1") {
          this.section = sectionNameHash[label];
          mainModel.setMainTabSection(this.section, sectionTabHash[this.section][1]);
        }
        this.layout.unload();
        this.setLayoutType();
        if (section === "dataExport") {
          location = lastPath ? lastPath : defaultLocationDataExport;
        } else {
          this.layout.loadView(".select-campaign");
          this.layout.loadView(".report-chart");
          this.layout.loadView(".report-grid");
        }
        T1Location.set(location, {
          silent: true
        });
        T1Location.push(location);
      },
      "select:chart": function (chartType) {
        this.subSection = chartType;
        eventHub.publish("track:event", {
          action: "Choose time view",
          label: chartType
        });
      },
      "track:event": function ({
        action: action,
        label: label,
        value: value
      }) {
        T1.Analytics.logEvent(this.trackingSection, action, label, value);
      },
      "unload:noDataMessages": function () {
        this.$(".no-data-panel .no-data").empty();
      },
      "update:canUnload": function ({
        formElementsChanged: formElementsChanged,
        cancelUnloadFn: cancelUnloadFn
      }) {
        this.formElementsChanged = formElementsChanged;
        this.cancelUnload = cancelUnloadFn;
      }
    },
    initLayouts() {
      const {
        models: models,
        report: report
      } = this;
      const contextualPosition = 5;
      const tabSectionId = this.mainModel.get("mainTabSectionId");
      const tabs = [{
        label: "Campaign Performance",
        module: "reporting/campaigns/performance",
        routeName: "campaigns/performance",
        viewType: "performance",
        options: {
          campaignReports: this,
          models: models,
          report: report
        }
      }, {
        label: "Site Transparency",
        module: "reporting/campaigns/siteTransparency",
        options: {
          report: report,
          models: models
        },
        routeName: "campaigns/siteTransparency",
        viewType: "siteTransparency"
      }, {
        label: "Geo Performance",
        module: "reporting/campaigns/geoPerformance",
        options: {
          report: report,
          models: models
        },
        routeName: "campaigns/geoPerformance",
        viewType: "geoPerformance"
      }, {
        label: "Technology",
        module: "reporting/campaigns/technology",
        options: {
          report: report,
          models: models
        },
        routeName: "campaigns/technology",
        viewType: "technology"
      }, {
        label: "Reach & Frequency",
        module: "reporting/campaigns/reachFrequency",
        options: {
          report: report,
          models: models
        },
        routeName: "campaigns/reachFrequency",
        viewType: "reachFrequency"
      }, {
        label: "Data Export",
        module: "reporting/campaigns/dataExport",
        options: {
          models: models
        },
        routeName: "campaigns/dataExport",
        viewType: "dataExport"
      }];
      if (this.showContextualTab) {
        tabs.splice(contextualPosition, 0, {
          label: "Contextual",
          module: "reporting/campaigns/contextual",
          options: {
            report: report,
            models: models
          },
          routeName: "campaigns/contextual",
          viewType: "contextual"
        });
      }
      this.tabLayout = new T1TabLayout({
        destroyViewOnSwitch: true,
        el: () => this.$(".reporting-tabs"),
        ignoreRouteDepth: false,
        level: 1,
        selected: tabSectionId || 0,
        tabs: tabs,
        tabSetName: "CampaignReporting"
      });
      this.gridChartLayout = {
        ".select-campaign": [{
          module: "reporting/campaigns",
          viewType: "selectCampaign",
          options: {
            models: models
          }
        }],
        ".report-chart": [{
          module: "reporting/campaigns",
          viewType: "no_data_chart",
          options: {
            msg: "Select a campaign to visualize",
            noData: true
          }
        }],
        ".report-grid": [{
          module: "reporting/campaigns",
          viewType: "no_data_grid",
          options: {
            msg: "Select a campaign to view data",
            noData: true
          }
        }]
      };
      this.dataExportLayout = {};
      this.setLayoutType();
    },
    setLayoutType() {
      let layout;
      const $headerInfo = this.$(".header-info");
      const $noDataPanel = this.$(".no-data-panel");
      if (this.section === "dataExport") {
        layout = this.dataExportLayout;
        $headerInfo.hide();
        $noDataPanel.hide();
      } else {
        layout = this.gridChartLayout;
        $headerInfo.show();
        $noDataPanel.show();
      }
      this.layout = new T1Layout({
        el: () => this.el,
        template: template,
        layout: layout,
        serialize: () => this.serialize()
      });
    },
    loadSectionModel(newFocus) {
      let reportMeta;
      const models = this.models;
      const {
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: oldReportMeta
      } = models;
      const reports = mainMeta.get("reports");
      const section = mainModel.get("section") || "performance";
      const previousFocus = this.getFocusFromUserPref(section);
      const focus = newFocus || previousFocus;
      const focusIsChanging = Boolean(newFocus && newFocus !== previousFocus);
      let uriMeta = reports[section] && reports[section].URI_Meta;
      if (oldReportMeta && !oldReportMeta.loaded) {
        oldReportMeta.abort();
      }
      this.updateCurrency();
      switch (section) {
        case "performance":
          if (this.hasEcoCostFlag) {
            uriMeta = uriMeta.replace("/meta", "_ecosystem_cost/meta");
          }
          break;
        case "site_transparency":
          if (this.hasViewabilityFlag) {
            uriMeta = uriMeta.replace("/meta", "_viewability/meta");
          }
          break;
        case "device_technology":
          uriMeta = uriMeta.replace("/meta", "/meta?v2=");
          break;
      }
      if (section === "dataExport") {
        models.setupDataExportModel();
      } else {
        reportMeta = models.setupReportMeta(uriMeta, section);
        reportMeta.reportModel.setFocus(focus);
        reportMeta.fetch({
          onSuccess: function () {
            if (focusIsChanging) {
              eventHub.publish("reporting:focus:meta:load");
            } else {
              eventHub.publish("reporting:meta:load");
            }
          }
        });
      }
    },
    editCampaign() {
      $(".rptg-icon-edit-tip").remove();
    },
    loadLayout() {
      const layout = this.layout;
      this.render().then(() => {
        this.initPreferences();
        this.delegateEvents();
        if (this.section === "dataExport") {
          this.$(".header-info").hide();
          this.$(".no-data-panel").hide();
          this.tabLayout.load();
        } else {
          layout.loadView(".select-campaign");
          this.tabLayout.load();
          if (!this.report.id) {
            layout.loadView(".report-chart");
            layout.loadView(".report-grid");
          }
        }
      });
    },
    load() {
      if (!this.canLoad) {
        return;
      }
      const reportID = this.report.id;
      const {
        model: model,
        mainModel: mainModel
      } = this;
      this.resetCampaignUIData();
      if (reportID) {
        if (this.multiCampaigns) {
          model.id = reportID.substring(0, reportID.indexOf(","));
        }
        model.set({
          rpt_goal_monitoring: null
        }, {
          silent: true
        });
        model.fetch({
          success: () => {
            if (this.loaded) {
              mainModel.setCampaignAccess(model);
              this.restoreStates();
              this.loadLayout();
            }
          },
          params: this.campaignFetchParams
        });
      } else {
        this.loadLayout();
      }
      this.loaded = true;
    },
    canUnload(args) {
      if (this.formElementsChanged) {
        this.cancelUnload(args);
      }
      return !this.formElementsChanged;
    },
    showGuideMarker() {
      const settleDelay = 400;
      if (this.report.id || this.guideMarkerHidden) {
        return;
      }
      this.$campaignMarker = this.$(".select-campaign-marker");
      this.campaignMarkerTimeOutId = setTimeout(() => {
        const animTime = 200;
        this.$campaignMarker.fadeIn(animTime);
      }, settleDelay);
    },
    hideGuideMarker() {
      clearTimeout(this.campaignMarkerTimeOutId);
      this.guideMarkerHidden = true;
      if (this.$campaignMarker) {
        this.$campaignMarker.hide();
      }
    },
    resetStateObjects() {
      reportState = null;
    },
    restoreStates() {
      const models = this.models;
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = models;
      if (!reportState) {
        reportState = {};
      } else {
        if (models) {
          const {
            dimensionItems: dimensionItems,
            filteredInCount: filteredInCount,
            filteredInItems: filteredInItems,
            filteredOutItems: filteredOutItems,
            hiddenCount: hiddenCount,
            hiddenItems: hiddenItems
          } = reportState;
          models.reportState = reportState;
          reportModel.set({
            dimensionItems: dimensionItems,
            filteredInCount: filteredInCount,
            filteredInItems: filteredInItems,
            filteredOutItems: filteredOutItems,
            hiddenCount: hiddenCount,
            hiddenItems: hiddenItems
          });
          reportModel.setReportState(reportState);
          mainModel.setReportState(reportState);
        }
      }
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", "reports"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["selectedCurrency", "chartShown"]);
      this.models.mainModel.setChartShown(this.chartShown === "" ? "true" : this.chartShown);
    },
    updateCurrency() {
      if (!this.report.id) {
        return;
      }
      let currencyCode;
      const {
        campaign: campaign,
        mainMeta: mainMeta,
        mainModel: mainModel
      } = this.models;
      const multiCampaigns = mainMeta.get("multiCampaigns");
      const multiCampaignCurrency = mainMeta.get("multiCampaignCurrency");
      const multiCampaignCurrencyCode = multiCampaignCurrency && multiCampaignCurrency.code || "USD";
      const multiCampaignCurrencyType = multiCampaignCurrency && multiCampaignCurrency.type || "usd";
      let selectedCurrency = this.selectedCurrency;
      if (multiCampaigns) {
        if (selectedCurrency === "campaign" && multiCampaignCurrencyType === "campaign") {
          currencyCode = multiCampaignCurrencyCode;
        } else {
          currencyCode = "USD";
          selectedCurrency = "usd";
        }
      } else {
        currencyCode = campaign.get("currency_code");
      }
      if (currencyCode === "USD") {
        selectedCurrency = this.selectedCurrency = "campaign";
        this.preferences.save();
      }
      mainModel.setCurrency(selectedCurrency, currencyCode);
    },
    getFocusFromUserPref(section) {
      if (section === "performance" || section === "site_transparency" || section === "geoPerformance" || sectionNameHash[section] === "geoPerformance" || section === "contextual" || sectionNameHash[section] === "contextual") {
        const signature = T1.Signature.create();
        signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", section]]));
        const preferences = T1.Preferences.create.call(this, signature, UserPreferences);
        preferences.mark(["focus"]);
        this.focus = this.focus || "all";
      } else {
        this.focus = "all";
      }
      return this.focus;
    },
    changeCampaign(args) {
      const campaignID = args ? args.id : "";
      const {
        model: model,
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      function getCurrency() {
        return this.multiCampaigns ? mainModel.get("multiCampaignCurrency").type : "campaign";
      }
      function renderUpdates() {
        this.renderPartial(".no-data-panel");
        this.tabLayout.execute({
          disabled: []
        });
        eventHub.publish("reporting:campaign:load");
        eventHub.publish("reports:select:campaign", args);
        eventHub.publish("select:currency", getCurrency.call(this));
      }
      this.multiCampaigns = campaignID.includes(",");
      if (this.report.id !== campaignID) {
        $document.mousedown();
        if (reportModel) {
          reportModel.resetUIStates();
          if (reportModel.resetTechFilters) {
            reportModel.resetTechFilters();
          }
        }
        this.report.id = campaignID;
        mainModel.setReportId(campaignID);
        model.id = this.multiCampaigns ? campaignID.substring(0, campaignID.indexOf(",")) : campaignID;
        eventHub.publish("toggle:loader", true);
        model.set({
          rpt_goal_monitoring: null
        }, {
          silent: true
        });
        model.fetch({
          success: () => {
            if (this.loaded) {
              mainModel.setCampaignAccess(model);
              renderUpdates.call(this);
            }
            eventHub.publish("toggle:loader", false);
          },
          params: this.campaignFetchParams
        });
        const section = sectionNameHash[mainModel.get("section")];
        let location = `reports/campaigns/${section}/${mainModel.get("reportIdList")}`;
        if (section === "performance") {
          const [,,,, subSection] = T1Location.get(true).split("/");
          location += subSection ? `/${subSection}` : "";
        }
        T1Location.set(location, {
          silent: true
        });
        T1Location.push(location);
        eventHub.publish("router:updateCurrentLocation", T1Location.getRoute());
      }
    },
    unload() {
      let resetDateObj;
      const {
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: reportMeta
      } = this.models;
      if (this.viewOptionsDate) {
        this.viewOptionsDate = false;
        resetDateObj = {
          startDate: null,
          endDate: null
        };
      } else {
        resetDateObj = {};
      }
      this.updateReportState();
      mainModel.resetReportMainView();
      if (this.tabLayout && this.tabLayout.loaded) {
        this.tabLayout.unload();
      }
      if (reportMeta) {
        reportMeta.abort();
        reportMeta.set(resetDateObj);
      }
      mainMeta.abort();
      mainModel.setSection(undefined, true);
      mainModel.set(resetDateObj);
      $.extend(reportState, resetDateObj);
      this.loaded = false;
    },
    updateReportState() {
      if (!(this.report && this.report.id)) {
        return;
      }
      if (!reportState) {
        reportState = {};
      }
      if (this.models) {
        const reportModel = this.models.reportModel;
        reportState.id = this.report.id;
        reportState.section = this.section;
        reportState.subSection = this.section === "performance" ? this.subSection : "";
        if (reportModel) {
          reportState.dimensionItems = reportModel.get("dimensionItems");
          reportState.filteredInCount = reportModel.get("filteredInCount");
          reportState.filteredInItems = reportModel.get("filteredInItems");
          reportState.filteredOutItems = reportModel.get("filteredOutItems");
          reportState.hiddenItems = reportModel.get("hiddenItems");
          reportState.hiddenCount = reportModel.get("hiddenCount");
        }
        if (reportModel) {
          reportModel.setReportState(reportState);
        }
        reportModel.setReportState(reportState);
      }
    },
    getAgencyName() {
      let agencyName;
      try {
        agencyName = this.model.get("advertiser").get("agency").get("name");
      } catch (error) {
        agencyName = "AGENCY";
      }
      return agencyName;
    },
    resetCampaignUIData() {
      this.models.mainModel.set({
        campaignItems: null,
        currency: null,
        currencyCode: null,
        multiCampaignCurrency: null,
        selectedAdvertiser: null,
        tempAdvertiser: null
      }, {
        silent: true
      });
    },
    serialize() {
      const {
        advertiser: advertiser,
        name: name,
        id: id
      } = this.model.toJSON();
      return {
        advertiserName: this.advertiserName || (advertiser ? advertiser.name : "ADVERTISER"),
        agencyName: this.getAgencyName(),
        name: name || "Campaign Name",
        newCampaignSelector: this.newCampaignSelector,
        noCampaign: id === undefined
      };
    }
  });
});
