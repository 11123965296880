define('modules/concept/views/select',["jQuery", "T1", "T1View", "collections/concepts", "text!../templates/select.html", "T1Selectbox"], function ($, T1, T1View, Concepts, template) {
  return T1View.extend({
    template: template,
    selected: "",
    fetchRequired: true,
    dataEvents: {
      collection: {
        reset: "load"
      }
    },
    eventhubEvents: {
      "advertiser:select": function (args) {
        this.advertiserId = args.id;
      }
    },
    initialize: function (args) {
      var self = this;
      var advertiserId = args.advertiserId;
      var collection = new Concepts();
      var model = args.model;
      collection.isPaginated = false;
      if (args.selectedId) {
        self.selected = args.selectedId;
        this.advertiserId = advertiserId;
        collection.urlFilter.set({
          entity: "advertiser",
          id: args.advertiserId,
          fetchRequired: true
        });
      }
      this.collection = collection;
    },
    load: function () {
      var selectBox;
      var self = this;
      var el = $(self.el);
      this.selectBoxConfig = {
        default_text_default: self.advertiserId ? "Concept" : "Select an Advertiser First!",
        onChange: function () {
          var id = selectBox.val();
          self.selected = id;
        }
      };
      self.render().then(function () {
        selectBox = $(self.el).find(".select-input");
        T1.Selectbox(selectBox, this.selectBoxConfig);
      });
    },
    unload: function () {
      if (this.collection) {
        if (this.collection.urlFilter) {
          this.collection.urlFilter.destroy();
        }
        this.collection.unregister();
        this.collection.reset([]);
      }
    },
    refreshList: function () {
      this.renderPartial(".select-input");
      $(this.el).find(".select-input").trigger("liszt:updated");
    },
    serialize: function () {
      var self = this;
      var concepts = self.collection;
      var result = [];
      var obj = {};
      var placeholder;
      concepts.each(function (concept) {
        obj = concept.toJSON();
        if (parseInt(concept.id, 10) == self.selected) {
          obj.selected = "selected";
        }
        result.push(obj);
      });
      if (concepts.models.length === 0) {
        placeholder = "No concepts found";
      } else {
        placeholder = this.advertiserId ? "Select One" : "Select an Advertiser First!";
      }
      return {
        disabled: concepts.models.length === 0,
        placeholder: placeholder,
        options: result
      };
    }
  });
});
