define('modules/reporting/campaigns/performance/views/performanceLineChart',['require','modules/shared/configs/charts/lineChartOneAxisSeriesConfig','T1','T1View'],function (require) {
  "use strict";

  const ChartConfig = require("modules/shared/configs/charts/lineChartOneAxisSeriesConfig");
  const T1 = require("T1");
  const T1View = require("T1View");
  const colorLineInactive = "#E5E5E5";
  const maxLen = 20;
  return T1View.extend({
    initialize(args) {
      this.campaign = args.campaign;
      this.campaignNestedCollection = args.campaignNestedCollection;
      this.collection = args.collection;
      this.parentClass = args.parentClass;
      this.reportModel = args.reportModel;
      this.mainModel = args.mainModel;
      this.summaryCollection = args.summaryCollection;
    },
    setupChart() {
      const {
        campaign: campaign,
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      const currencyCode = this.mainModel.getCurrencyCode(campaign, reportModel.get("focus"));
      const self = this;
      if (collection && collection.loaded && colorData) {
        const lineChartConfig = ChartConfig.copy();
        const campaignData = this.campaignNestedCollection ? this.campaignNestedCollection.toJSON() : [];
        const labelFn = function (value) {
          return reportModel.formatValue(value, parentClass.yMetricObj.type, currencyCode);
        };
        reportModel.set({
          chartData: collection.toJSON(),
          campaignData: campaignData,
          summaryData: this.summaryCollection.toJSON()
        });
        const chartData = reportModel.getLineChartData(parentClass.dimensionKey, parentClass.dimensionName, parentClass.yKey, colorData, false);
        if (chartData.graphs.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        parentClass.showChart();
        lineChartConfig.dataProvider = chartData.dataProvider;
        lineChartConfig.graphs = chartData.graphs;
        lineChartConfig.valueAxes[0].labelFunction = labelFn;
        lineChartConfig.valueAxes[0].title = parentClass.yAxisLabel;
        lineChartConfig.listeners = [{
          event: "init",
          method: function (evt) {
            evt.chart.chartDiv.addEventListener("click", self.toggleHighlighted.bind(self));
          }
        }, {
          event: "clickGraphItem",
          method: function (evt) {
            self.toggleHighlighted(evt.item);
            evt.event.stopPropagation();
          }
        }];
        lineChartConfig.categoryAxis.labelFunction = value => T1.Utils.trunc(value, maxLen);
        lineChartConfig.export = {};
        lineChartConfig.legend = null;
        lineChartConfig.marginRight = 40;
        this.showGuideLines(lineChartConfig.valueAxes[0]);
        parentClass.hideNoDataChart();
        this.performanceChart = AmCharts.makeChart("performance-chart", lineChartConfig);
        this.highlightChart();
      }
    },
    showGuideLines(valueAxes) {
      const {
        campaign: campaign,
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const yKey = this.parentClass.yKey;
      const goalType = campaign.get("goal_type");
      const goalValue = reportModel.getGoalValue(campaign, yKey);
      const showGoal = !mainModel.get("multiCampaigns") && reportModel.checkMetricKeyGoalType(yKey, goalType);
      const guides = [];
      if (showGoal) {
        guides.push({
          above: true,
          boldLabel: true,
          color: "#989898",
          dashLength: 4,
          inside: true,
          label: "Goal",
          lineAlpha: 1,
          lineColor: "#989898",
          lineThickness: 1.8,
          position: "right",
          value: goalValue
        });
      }
      valueAxes.guides = guides;
    },
    toggleHighlighted(highlightedElement) {
      event.stopPropagation();
      const highlighted = this.reportModel.get("highlightedItem");
      let newHighlighedId = highlightedElement.graph ? highlightedElement.graph.id : null;
      if (highlighted && highlighted.id === newHighlighedId) {
        newHighlighedId = null;
      }
      this.reportModel.setHighlightedItem({
        from: "line",
        id: newHighlighedId,
        type: newHighlighedId === "campaign-line" ? "campaign" : "strategy"
      });
    },
    updateChart() {
      const {
        campaign: campaign,
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      const currencyCode = this.mainModel.getCurrencyCode(campaign, reportModel.get("focus"));
      if (!this.performanceChart) {
        this.setupChart();
      }
      if (collection && collection.loaded && colorData) {
        const labelFn = function (value) {
          return reportModel.formatValue(value, parentClass.yMetricObj.type, currencyCode);
        };
        reportModel.set({
          chartData: collection.toJSON()
        });
        reportModel.set({
          summaryData: this.summaryCollection.toJSON()
        });
        const chartData = reportModel.getLineChartData(parentClass.dimensionKey, parentClass.dimensionName, parentClass.yKey, colorData, true);
        if (chartData.graphs.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        parentClass.showChart();
        this.performanceChart.dataProvider = chartData.dataProvider;
        this.performanceChart.graphs = chartData.graphs;
        this.performanceChart.valueAxes[0].labelFunction = labelFn;
        this.performanceChart.valueAxes[0].title = parentClass.yAxisLabel;
        this.showGuideLines(this.performanceChart.valueAxes[0]);
        parentClass.hideNoDataChart();
        this.highlightChart();
      }
    },
    highlightChart() {
      if (!this.performanceChart) {
        return;
      }
      const lineColors = this.reportModel.get("lineColors");
      const performanceChart = this.performanceChart;
      const graphs = performanceChart.graphs;
      const clickedElement = this.reportModel.get("highlightedItem");
      function resetColors() {
        for (const [index, graph] of graphs.entries()) {
          graph.lineColor = lineColors[index];
        }
      }
      if (clickedElement && clickedElement.type === "campaign" && clickedElement.id !== null) {
        clickedElement.id = "campaign-line";
      }
      if (clickedElement && clickedElement.id) {
        let clickedElementIndex;
        resetColors();
        for (const [index, graph] of graphs.entries()) {
          if (graph.id !== clickedElement.id) {
            if (graph.id !== "campaign-line") {
              graph.lineColor = colorLineInactive;
            }
          } else {
            clickedElementIndex = index;
          }
        }
        graphs.splice(graphs.length - 1, 0, graphs.splice(clickedElementIndex, 1)[0]);
        lineColors.splice(graphs.length - 1, 0, lineColors.splice(clickedElementIndex, 1)[0]);
      } else {
        resetColors();
      }
      performanceChart.validateData();
    },
    unloadChart() {
      if (this.performanceChart) {
        this.performanceChart.clear();
        this.performanceChart = null;
      }
    }
  });
});
