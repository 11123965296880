define('models/mediaPerformance',['require','T1','T1Model'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1Model = require("T1Model");
  T1.Models.MediaPerformance = T1Model.extend({
    canCache: false,
    cacheDuration: 6e4,
    urlString: "performance",
    apiRoot: "/reporting/v1/std/"
  });
  return T1.Models.MediaPerformance;
});
