define('modules/reporting/campaigns/performance/models/model',["jQuery", "Underscore", "T1", "models/reportingAbstractModel", "moment"], function ($, _, T1, RPTGAbstractModel, moment) {
  "use strict";

  const COLOR_LINE_INACTIVE = "#E5E5E5";
  const COLOR_WHITE = "#FFFFFF";
  const COLOR_BLACK = "#000000";
  const formatCurrency = T1.Utils.formatCurrency;
  const formatAs = T1.Formats.as;
  const MAX_LENGTH = 60;
  const PerformanceModel = RPTGAbstractModel.extend({
    initialize: function (options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    formatValue: function (value, type, currencyCode) {
      if (type === "currency") {
        value = formatCurrency(value, null, currencyCode);
      } else {
        value = formatAs(type)(value);
      }
      return value;
    },
    checkMetricKeyGoalType: function (yKey, goalType) {
      const reportMeta = this.reportMeta;
      const map = reportMeta && reportMeta.get("defaultSortForGoalType");
      let value = map && map[goalType];
      value = value && value.split(",")[0];
      return yKey === value;
    },
    getGoalValue: function (campaign, yKey) {
      const mainModel = this.mainModel;
      const multiCampaign = mainModel.get("multiCampaigns");
      const multiCampaignCurrency = mainModel.get("multiCampaignCurrency");
      const currencyCode = mainModel.getCurrencyCode();
      const goalType = campaign.get("goal_type");
      const showGoal = !multiCampaign && this.checkMetricKeyGoalType(yKey, goalType);
      const goalValueAmounts = campaign.get("goal_value_amount");
      let goalValue, goalValueCurrencies, goalValueIndex;
      if (!showGoal) {
        return 0;
      }
      if (_.isArray(goalValueAmounts)) {
        goalValueCurrencies = _.map(goalValueAmounts, obj => obj.currency_code);
        if (multiCampaign) {
          goalValueIndex = _.indexOf(goalValueCurrencies, multiCampaignCurrency.code);
        } else {
          goalValueIndex = _.indexOf(goalValueCurrencies, currencyCode);
        }
        goalValue = goalValueAmounts[goalValueIndex].value;
      } else {
        goalValue = goalValueAmounts.value;
      }
      return goalValue;
    },
    _processFilteredData: function (dimensionKey) {
      let data = this.get("chartData");
      const filteredOutItems = this.get("filteredOutItems") || [];
      if (!_.isEmpty(filteredOutItems)) {
        _.each(filteredOutItems, function (filteredItem) {
          data = _.reject(data, dataItem => dataItem[dimensionKey] === filteredItem);
        });
      }
      this.set({
        filteredData: data
      });
    },
    _processHiddenData: function (dimensionKey) {
      let data = this.get("filteredData");
      const hiddenItems = this.get("hiddenItems") || {};
      if (!_.isEmpty(hiddenItems)) {
        _.each(hiddenItems, function (value, hiddenItem) {
          data = _.filter(data, dataItem => dataItem[dimensionKey] !== hiddenItem);
        });
      }
      this.set({
        filteredHiddenData: data
      });
    },
    _createEmptyBarData: function (dimensionName) {
      const dataProvider = [{
        dummyData: 0
      }];
      const graphs = [{
        fillAlphas: 0,
        id: "",
        lineAlpha: 0,
        title: "",
        type: "column",
        valueAxis: "barAxis",
        valueField: "dummyData"
      }];
      dataProvider[0][dimensionName] = "test name";
      this.set({
        dataProvider: dataProvider,
        graphs: graphs
      });
    },
    _createBarDataProvider: function (dimensionKey, dimensionName, dimBars) {
      const dataProvider = [];
      const chartColors = this.reportMeta.get("chartColors");
      const chartItemColors = [];
      const highlightedElement = this.get("highlightedItem");
      const parsedData = this.get("filteredHiddenData");
      const yKey = this.get("yKey");
      const colorData = this.get("colorData");
      _.each(parsedData, function (item) {
        const chartDataItem = {};
        const dimensionId = item[dimensionKey];
        const color = chartColors[colorData[dimensionId]];
        chartDataItem[dimensionKey] = item[dimensionKey];
        chartDataItem[dimensionName] = item[dimensionName];
        chartDataItem[yKey] = item[yKey];
        if (dimBars) {
          chartDataItem.color = COLOR_LINE_INACTIVE;
          if (highlightedElement && highlightedElement.id && highlightedElement.id === dimensionId) {
            chartDataItem.color = color;
          }
        } else {
          chartDataItem.color = color;
        }
        chartItemColors.push(color);
        dataProvider.push(chartDataItem);
      });
      this.set({
        chartItemColors: chartItemColors,
        dataProvider: dataProvider
      });
    },
    _createBarGraphs: function (dimensionKey, animateChart) {
      function tooltipFunction(item) {
        const yAxisLabel = this.get("yAxisLabel");
        const yMetricObj = this.get("yMetricObj");
        const yAxisKey = this.get("yKey");
        const currencyCode = this.mainModel.getCurrencyCode();
        let title = item.category || "";
        if (title.length > MAX_LENGTH) {
          title = title.match(/([\S\s]{1,60})/g);
        }
        return `<p style="font-family:AB;">${title.toString().replace(/,/g, "<br>")}</p><br>\n          ${yAxisLabel}: ${this.formatValue(item.dataContext[yAxisKey], yMetricObj.type, currencyCode)}<br>\n          &nbsp;`;
      }
      const graphs = [{
        animationPlayed: animateChart,
        balloonFunction: tooltipFunction.bind(this),
        fillAlphas: 1,
        fillColorsField: "color",
        id: "dimensionBars",
        lineColor: "color",
        title: this.get("yAxisLabel"),
        type: "column",
        valueAxis: "barAxis",
        valueField: this.get("yKey")
      }];
      this.set({
        graphs: graphs
      });
    },
    getBarChartData: function (dimensionKey, dimensionName, animateChart, dimBars) {
      this._processFilteredData(dimensionKey);
      this._processHiddenData(dimensionKey);
      this.set({
        showEmptyChart: false
      });
      if (this.get("chartData").length > 0 && this.get("filteredData").length === this.getHiddenItemsCount()) {
        this._createEmptyBarData(dimensionName);
        this.set({
          showEmptyChart: true
        });
      } else {
        this._createBarDataProvider(dimensionKey, dimensionName, dimBars);
        this._createBarGraphs(dimensionKey, animateChart);
      }
      return {
        dataProvider: this.get("dataProvider") || [],
        graphs: this.get("graphs") || []
      };
    },
    _parseData: function (yKey, timeSlots, dimensionKey, dimensionName) {
      const showCampaignLine = this.get("campaignBenchmarkOn");
      let data = this.get("filteredHiddenData");
      if (showCampaignLine && data) {
        data = data.concat(this.get("campaignData"));
      }
      const parsedData = _.chain(data).groupBy(model => model[dimensionKey]).map(function (arr, key) {
        const values = [];
        let name = "";
        key = key !== "undefined" ? key : "campaign-line";
        _.each(timeSlots, function (startDate) {
          var dataSet = _.find(arr, item => startDate === item.start_date);
          if (dataSet) {
            name = dataSet[dimensionName];
          }
          values.push(dataSet ? dataSet[yKey] : "");
        });
        return {
          id: key,
          name: name || "Campaign",
          values: values
        };
      }).value();
      this.set({
        parsedData: parsedData
      });
    },
    _createLineDataProvider: function (dimension, timeSlots) {
      const dataProvider = timeSlots.map(start_date => ({
        date: start_date
      }));
      const parsedData = this.get("parsedData");
      dataProvider.forEach(function (item, index) {
        parsedData.forEach(function (data) {
          item[data.id] = data.values[index];
        });
      });
      this.set({
        dataProvider: dataProvider
      });
    },
    _createLineGraphs: function (dimension, colorData, animateChart) {
      const parsedData = this.get("parsedData");
      const chartColors = this.reportMeta.get("chartColors");
      const lineColors = [];
      function labelFunction(item, label) {
        if (item.index === item.graph.chart.dataProvider.length - 1 && item.graph.id === "campaign-line") {
          return label;
        }
        return "";
      }
      function tooltipFunction(item) {
        let title = item.graph.title || "";
        const yAxisLabel = this.get("yAxisLabel");
        const yMetricObj = this.get("yMetricObj");
        const currencyCode = this.mainModel.getCurrencyCode();
        if (title.length > MAX_LENGTH) {
          title = title.match(/([\S\s]{1,60})/g);
        }
        return `<p style="font-family:AB;">${title.toString().replace(/,/g, "<br>")}</p><br>\n          ${yAxisLabel}: ${this.formatValue(item.values.value, yMetricObj.type, currencyCode)}<br>\n          ${moment(item.category).format("ddd, MMM D, YYYY")}<br>&nbsp;`;
      }
      const graphs = _.map(parsedData, data => {
        const value = data.id;
        let color = chartColors[colorData[value]];
        const isCampaignLine = value === "campaign-line";
        if (isCampaignLine) {
          color = COLOR_BLACK;
        }
        lineColors.push(color);
        return {
          animationPlayed: animateChart,
          balloonFunction: tooltipFunction.bind(this),
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 6,
          hideBulletsCount: 100,
          id: value,
          labelFunction: labelFunction,
          labelPosition: "right",
          labelText: "[[title]]",
          lineColor: color,
          lineThickness: 1.5,
          title: data.name,
          type: "smoothedLine",
          valueField: value
        };
      });
      this.set({
        graphs: graphs,
        lineColors: lineColors
      });
    },
    _createEmptyLineData: function (timeSlots) {
      const dataProvider = timeSlots.map(start_date => ({
        date: start_date
      }));
      dataProvider.forEach(function (item) {
        item.dummyData = "";
      });
      this.set({
        dataProvider: dataProvider,
        graphs: [{
          balloonFunction: () => "",
          bulletAlpha: 0,
          id: "dummyData",
          lineAlpha: 0,
          lineColor: COLOR_WHITE,
          title: "dummyData",
          valueField: "dummyData"
        }]
      });
    },
    getLineChartData: function (dimensionKey, dimensionName, yKey, colorData, animateChart) {
      const dailyData = this.get("chartData");
      const timeSeriesSlots = this.get("timeSeriesSlots") || {
        slots: []
      };
      const tsSlots = _.pluck(timeSeriesSlots.slots, "start_date");
      this._processFilteredData(dimensionKey);
      this._processHiddenData(dimensionKey);
      this._parseData(yKey, tsSlots, dimensionKey, dimensionName);
      this.set({
        showEmptyChart: false
      });
      if (dailyData.length > 0 && this.get("summaryData").length === this.getHiddenItemsCount()) {
        this._createEmptyLineData(tsSlots);
      } else {
        this._createLineDataProvider(dimensionKey, tsSlots);
        this._createLineGraphs(dimensionKey, colorData, animateChart);
      }
      return {
        dataProvider: this.get("dataProvider") || [],
        graphs: this.get("graphs") || []
      };
    }
  });
  return PerformanceModel;
});
