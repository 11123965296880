define('modules/creatives/display/views/list',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Permissions", "collections/creativesDisplay", "models/userPreferences", "utils/SearchBuilder", "../../shared/creativeActive", "../control/searchConfigs", "text!../templates/list.html", "text!../templates/listBody.html", "utils/CreativeUtils", "models/advertiser", "models/staticData", "T1Selectbox", "T1Tooltip", "T1Preferences", "T1Signature", "T1Notifier", "jQPlugins/jquery.cookie"], function ($, _, When, T1, T1View, T1Layout, T1Permissions, DisplayCreatives, UserPreferences, SearchBuilder, statusOpts, getConfigs, tmpl, templateBody, CreativeUtils, Advertiser, StaticData) {
  return T1View.extend({
    template: tmpl,
    events: {
      "change .select-input.status": "changeActiveStatus",
      "focus .w-Filters input.text": "showSearchTooltip"
    },
    termPref: "",
    advIDPref: "",
    active: "Active",
    dataEvents: {
      collection: {
        reset: "onCollectionEvent",
        fetchMore: "onCollectionEvent",
        fetchMoreComplete: "onCollectionEvent",
        startSearch: "onCollectionEvent",
        "change:selected": "onItemSelected"
      }
    },
    eventhubEvents: {
      "organization:select": function () {
        this.collection.searchBuilder.clearFlag("advertiser", "id");
        this.advID = this.advIDPref = null;
        this.preferences.save();
        this.filterAndClearCallback("", null, true);
        this.initLayout();
        this.load();
      }
    },
    onCollectionEvent: function () {
      this.isDisabled = this.collection.loaded === false;
      this.toggleFilters(this.isDisabled);
      this.toggleBulkAction(this.isDisabled);
    },
    toggleFilters: function (isDisabled) {
      var self = this;
      var $el = $(self.el);
      $el.find(".status-select select").attr("disabled", isDisabled).trigger("liszt:updated").closest("div").toggleClass("disabled", isDisabled);
    },
    onItemSelected: function () {
      if (this.resubmitEnabled) {
        CreativeUtils.toggleResubmitMenuOption(this.collection, this.bulkActionMenuItems);
      }
      if (this.violationFlaggingEnabled) {
        CreativeUtils.toggleViolationMenuOption(this.collection, this.bulkActionMenuItems);
      }
      this.toggleBulkAction(this.isDisabled);
    },
    toggleBulkAction: function (isDisabled) {
      var selected = this.collection.getSelected();
      if (!isDisabled && selected.length > 0) {
        T1.EventHub.publish("pullDownButton:bulk-action:enable");
      } else {
        T1.EventHub.publish("pullDownButton:bulk-action:disable");
      }
    },
    initialize: function (args) {
      var self = this;
      var signature = T1.Signature.create();
      var isReturning = $.cookie("isReturning");
      this.advertiserCollection = args.advertisers;
      this.allAdvertisersLoaded = false;
      signature.set(new Map([["root", "ui"], ["module", "creatives.display"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["active", "termPref", "advIDPref"]);
      this.active = this.preferences.get("active") || "Active";
      this.isHtml5 = T1Permissions.check("feature", "creatives.html5");
      this.resubmitEnabled = T1Permissions.check("feature", "creative_resubmit");
      this.violationFlaggingEnabled = T1Permissions.check("feature", "creatives_violation");
      if (isReturning === "true") {
        $.removeCookie("isReturning");
        self.termPref = "";
        self.advIDPref = "";
        self.preferences.save();
      } else {
        this.term = this.preferences.get("termPref") || "";
        this.advID = this.preferences.get("advIDPref") || null;
      }
      this.staticVerticals = new StaticData({
        action: "iab_verticals"
      });
      this.staticVerticals.fetch({
        onSuccess: function (data) {
          self.staticVerticals.set({
            data: data
          });
        }
      });
      self.createCollection();
      self.getAdvertiserData().then(function () {
        self.initLayout();
        self.load();
      });
      $(window).one("beforeunload", function () {
        $.cookie("isReturning", true);
        $.cookie("isReturningVideo", true);
        return null;
      });
    },
    getAdvertiserData: function () {
      var self = this;
      var deferred = When.defer();
      self.advertiserCollection.isPaginated = true;
      self.advertiserCollection.pageLimit = 50;
      self.advertiserCollection.fetch({
        success: function () {
          if (self.term || self.advID && self.advID !== null && self.advID !== "null") {
            self.filterAndClearCallback(self.term, {
              id: self.advID
            }, false);
          } else {
            self.filterAndClearCallback("", null, false);
          }
          self.allAdvertisersLoaded = true;
          if (self.advIDPref && self.advIDPref !== "null") {
            var model = self.advertiserCollection.get(self.advID);
            if (model) {
              model.set({
                selected: true
              });
              self.advertiserCollection.add(model, {
                merge: true
              });
              deferred.resolve(self.advertiserCollection);
            } else {
              model = new Advertiser({
                id: self.advID
              });
              model.fetch({
                success: function (adModel) {
                  adModel.set({
                    selected: true
                  });
                  self.advertiserCollection.add(adModel);
                  deferred.resolve(self.advertiserCollection);
                }
              });
            }
          } else {
            deferred.resolve(self.advertiserCollection);
          }
        }
      });
      return deferred.promise;
    },
    getAdvertiserList: function () {
      var options = this.advertiserCollection.map(function (adver) {
        return {
          text: adver.get("name"),
          value: adver.id,
          selected: adver.get("selected")
        };
      });
      options.unshift({
        text: "All Advertisers",
        value: "all"
      });
      return options;
    },
    createCollection: function () {
      var searchBuilder = new SearchBuilder(getConfigs(), {
        searchField: "media_type",
        term: "video"
      });
      var activeKey = "active_state";
      if (this.advID === null || this.advIDPref === null) {
        searchBuilder.clearFlag("advertiser", "id");
      }
      if (this.active === "All") {
        searchBuilder.clearGroupFlags(activeKey);
      } else {
        searchBuilder.setFlag(activeKey, this.active.toLowerCase());
      }
      this.collection = new DisplayCreatives(null, {
        pageLimit: 50,
        searchBuilder: searchBuilder
      });
    },
    filterAndClearCallback: function (term, flags, savePref) {
      var coll = this.collection;
      var advertiser_key = "advertiser";
      var filterKey = "search";
      var fileTypeFilterKey = "file_type";
      var save = savePref === undefined ? true : savePref;
      this.advIDPref = flags && flags.id !== "" ? flags.id : this.advIDPref;
      T1.EventHub.publish("creativegrid:setHighlightValue", term);
      if (this.advIDPref && this.advIDPref !== "all" && this.advIDPref !== "null") {
        coll.searchBuilder.setFlag(advertiser_key, "id", {
          term: this.advIDPref
        });
      } else {
        if (this.advIDPref === "all") {
          this.clearTheFilter();
        }
        coll.searchBuilder.clearGroupFlags(advertiser_key);
        this.advIDPref = null;
      }
      if ($.inArray(term.toLowerCase(), ["gif", "swf", "jpg", "png", "tif", "unknown", "html5"]) !== -1) {
        filterKey = fileTypeFilterKey;
        coll.searchBuilder.clearGroupFlags("multiField");
        coll.searchBuilder.setFlag(fileTypeFilterKey, fileTypeFilterKey, {
          term: term.toLowerCase()
        });
      } else {
        coll.searchBuilder.clearFlag(fileTypeFilterKey, fileTypeFilterKey);
      }
      this.termPref = term || "";
      if (save) {
        this.preferences.save();
      }
      coll.filterBy(filterKey, this.termPref);
    },
    reload: function () {
      var self = this;
      this.initialize({
        advertisers: self.advertiserCollection
      });
    },
    initLayout: function () {
      var self = this;
      var searchTerm = self.term;
      var advertiserList;
      if (self.allAdvertisersLoaded) {
        advertiserList = $.proxy(self.getAdvertiserList, this);
        if (this.layout) {
          this.layout.destroy();
        }
        if (searchTerm) {
          searchTerm = decodeURIComponent(searchTerm);
        }
        this.bulkActionMenuItems = [{
          itemLabel: "Activate",
          itemCallback: $.proxy(self.bulkAction, self, {
            status: "1"
          })
        }, {
          itemLabel: "Deactivate",
          itemCallback: $.proxy(self.bulkAction, self, {
            status: "0"
          })
        }, {
          itemLabel: "Edit",
          itemCallback: $.proxy(self.showBulkEdit, self)
        }, {
          itemLabel: "Preview",
          itemCallback: $.proxy(self.showBulkPreview, self)
        }, ...(this.violationFlaggingEnabled ? [{
          itemLabel: "Flag for Violation",
          itemCallback: $.proxy(self.showFlagViolationDialog, self)
        }] : []), {
          itemLabel: "Export Approval Status",
          itemCallback: $.proxy(self.exportApprovalStatus, self)
        }, ...(this.resubmitEnabled ? [{
          itemLabel: "Resubmit for Approval",
          itemCallback: $.proxy(self.resubmitForApproval, self),
          itemDisabled: true
        }] : [])];
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".list-filters",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: {
            ".search": [{
              module: "shared",
              viewType: "filter",
              options: {
                defaultValue: searchTerm,
                filterClass: "advertiser-select",
                selectOptions: advertiserList,
                isDisabled: function () {
                  return false;
                },
                filterCallback: $.proxy(self.filterAndClearCallback, this),
                clearFilterCallback: $.proxy(self.filterAndClearCallback, this),
                autoCompleteConfig: {
                  autoComplete: true,
                  searchField: "name",
                  onSearchInputCleared: $.proxy(self.clearTheFilter, this),
                  onSearchRequest: function (searchOptions) {
                    self.searchOptions = searchOptions;
                    searchOptions = $.extend({
                      fetchRequired: true
                    }, searchOptions);
                    self.advertiserCollection.search.set(searchOptions);
                    self.el.find(".advertiser-select:first").val("All Advertiser");
                  }
                }
              }
            }],
            ".bulk-action": [{
              module: "shared",
              viewType: "pullDownButton",
              options: {
                id: "bulk-action",
                menuLabel: "Choose Action",
                items: this.bulkActionMenuItems,
                disabled: true
              }
            }],
            ".add-creative-type": [{
              module: "shared",
              viewType: "pullDownButton",
              options: {
                menuLabel: "Add Creative",
                icon: "plus",
                items: [{
                  itemLabel: "Single 3PAS",
                  locationStr: "creatives/display"
                }, {
                  itemLabel: "Bulk 3PAS",
                  locationStr: "creatives/display-bulk"
                }, {
                  itemLabel: "Upload Assets",
                  locationStr: "creatives/display-t1as"
                }],
                themeColor: "blue",
                disabled: false
              }
            }],
            ".w-grid": [{
              module: "creatives/display",
              viewType: "grid",
              options: {
                collection: self.collection,
                searchTerm: self.term,
                staticVerticals: self.staticVerticals
              }
            }]
          }
        });
      }
    },
    clearTheFilter: function () {
      var searchOptions = $.extend({
        fetchRequired: true
      }, this.searchOptions);
      this.advertiserCollection.search.clear();
      this.advertiserCollection.fetch(searchOptions);
    },
    changeActiveStatus: function (e) {
      this.collection.filterBy("active_state", e.currentTarget.value);
    },
    showSearchTooltip: function (e) {
      var $target = $(e.currentTarget);
      var $parent = $target.parent();
      var className = "w-DisplaySearchTooltip";
      var msg = "Search by Name, ID, Advertiser, Concept, 3PAS Tag ID, Type or Dimension";
      $(`.${className}`).remove();
      T1.Tooltip($target, {
        trigger: "manual",
        tooltipEle: $parent,
        getExternalTip: false,
        gravity: "s",
        className: className,
        offset: 10
      });
      $parent.attr("title", msg).tipsy("show");
    },
    showBulkEdit: function () {
      var selected = this.collection.getSelected();
      if (this.bulkEditLayout) {
        this.bulkEditLayout.destroy();
      }
      this.bulkEditLayout = new T1Layout({
        template: '<div class="bulk-edit"></div>',
        layout: {
          ".bulk-edit": [{
            module: "creatives/bulkedit",
            viewType: "dialog",
            options: {
              collection: selected
            }
          }]
        }
      });
      this.bulkEditLayout.load();
    },
    showFlagViolationDialog: function () {
      var selected = this.collection.getSelected();
      var models = selected.models;
      if (models && models.length === 1) {
        CreativeUtils.showFlagViolationDialog(models[0].toJSON(), "display");
      }
    },
    exportApprovalStatus: function () {
      var selected = this.collection.getSelected();
      var models = selected.models;
      var creativeIds = [];
      var downloadUrl = `${T1.API_ROOT}/atomic_creatives/creative_approvals_report?atomic_creative_ids=`;
      var dialog = this.el[0].querySelector("#creativeApprovalDialog");
      dialog.querySelector("label").textContent = "Cancel";
      if (models.length > 50) {
        setTimeout(function () {
          dialog.show();
        }, 0);
        return;
      }
      _.each(models, function (creative) {
        creativeIds.push(creative.id);
      });
      downloadUrl = `${downloadUrl}(${creativeIds.join()})` + `&report_type=csv`;
      window.open(downloadUrl);
    },
    resubmitForApproval: function () {
      CreativeUtils.resubmitSelectedForApproval(this.collection);
    },
    showBulkPreview: function () {
      var selected = this.collection.getSelected();
      CreativeUtils.showPreview(_.map(selected.models, function (item) {
        return item.get("id");
      }), "display");
    },
    bulkAction: function (modelValues) {
      var self = this;
      var queue = [];
      var selected = this.collection.getSelected();
      var entityType = "creative";
      selected.each(function (model) {
        var loader = CreativeUtils.createInlineLoader(model.get("id"));
        var deferred = When.defer();
        loader.start();
        model.save(modelValues, {
          success: function () {
            loader.stop();
            deferred.resolve();
          },
          conflict: function () {
            loader.stop();
            deferred.reject();
          }
        });
        queue.push(deferred.promise);
      });
      When.all(queue).then(function () {
        T1.EventHub.publish("bulkaction:success");
        T1.Notify("message", `${selected.length} ${entityType}(s) updated successfully!`);
      });
    },
    load: function () {
      var self = this;
      var el = self.el;
      this.render().then(function () {
        self.layout && !self.layout.isActive && self.layout.load().then(function () {
          self.renderSelectbox();
          self.toggleFilters(!self.collection.loaded);
          setTimeout(function () {
            var $advDDL = $(".advertiser-select", el);
            var $advSelectBox = $(`div#${$advDDL.attr("id")}_chzn`);
            var $title = $advSelectBox.find("span");
            $advDDL.find("._wrapper").addClass("ad-wrapper");
            $advSelectBox.attr("title", $title.html());
            $advSelectBox.click(function () {
              $advSelectBox.attr("title", $title.html());
            });
            el.not('div[class="ad-wrapper"]').click(function () {
              self.clearTheFilter();
            });
          }, 1e3);
        });
      });
    },
    unload: function () {
      if (this.bulkEditLayout) {
        this.bulkEditLayout.unload();
      }
      this.allAdvertisersLoaded = false;
      this.collection.toggleAllSelected(false);
      T1View.prototype.unload.call(this);
    },
    renderSelectbox: function () {
      var self = this;
      var context = this.el;
      T1.Selectbox(context.find(".select-input.status"), {
        onChange: function (args) {
          self.active = $(args.target).find(":selected").text();
          self.preferences.save();
        }
      });
    },
    serialize: function () {
      return {
        isCollectionLoaded: this.collection && this.collection.loaded,
        violationFlaggingEnabled: this.violationFlaggingEnabled,
        activeOptions: statusOpts.toOptionsList(statusOpts.active, this.active || "Active")
      };
    }
  });
});
