define('modules/creatives/video/views/grid',["jQuery", "Underscore", "T1", "T1View", "T1GridViewV2", "T1Form", "T1Permissions", "text!../templates/grid_header.html", "text!../templates/grid_item.html", "../control/headers", "T1Menu", "utils/VideoUtils", "utils/CreativeUtils", "collections/concepts", "models/concept", "models/classifications", "models/videoCreative", "models/videoCreativeDetail", "collections/vendors", "../control/ParallelDeferred", "collections/companions", "collections/trackingEvents", "collections/creativesVideo", "collections/creativeNetStatuses", "When", "T1Layout", "moment", "T1Loader", "T1Tooltip"], function ($, _, T1, T1View, gridDecorator, T1Form, T1Permissions, headerTemplate, itemTemplate, headers, T1Menu, VideoUtils, CreativeUtils, Concepts, ConceptsModel, Classifications, VideoCreative, VideoCreativeDetail, Vendors, ParallelDeferred, Companions, TrackingEvents, VideoCreativesCollection, CreativeNetStatuses, When, T1Layout, moment) {
  "use strict";

  const updateProgress = function () {
    let _self;
    function getProgressValue(model) {
      const progress = model && model.get("progress");
      const encodingStatus = progress && progress.get("encodingStatus");
      return encodingStatus ? encodingStatus.percent : 0;
    }
    function getLoaderObject($element) {
      return T1.Loader.create({
        count: 100,
        context: $element.find(".conversion-progress"),
        loaderClass: "upload-bar",
        type: "progress",
        replace: true
      });
    }
    function iterator(element) {
      const $element = $(element);
      const id = $element.data("id");
      const model = _self.collection.get(id);
      _self.progressMap[id] = getLoaderObject($element);
      _self.fetchVendorsById(model);
      _self.progressMap[id].progress(getProgressValue(model));
    }
    return function (self) {
      _self = self;
      return iterator;
    };
  }();
  var VideoGridView = T1View.extend({
    scrollConfigs: {
      triggerPoint: 400,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Creatives",
        contentClass: "search-loader loader-overrides",
        action: "append"
      }
    },
    partials: {
      gridHeader: headerTemplate,
      listItem: itemTemplate
    },
    loaderOpts: {
      contentClass: "inline-edit-loader",
      target: ".w-DataGrid .w-body.content",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15,
        top: 0
      }
    },
    callOnce: 0,
    maxCount: {},
    itemViewed: {},
    vendListData: {},
    readyToServeList: [],
    enterDisabled: true,
    inlineSaving: false,
    headersTooltips: true,
    headers: headers,
    menuConfig: {
      menuTriggerEleSelector: ".config",
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: false
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }, {
          label: "Edit",
          iconClass: null,
          visible: true
        }, {
          label: "Preview",
          iconClass: null,
          handler: "preview",
          visible: true
        }, {
          label: "Flag For Violation",
          handler: "showFlagViolationDialog",
          visible: false
        }]
      }]
    },
    events: {
      "click .item .icon-arrow, .item": "clickToggleNode",
      "click .checkbox": "handleCheckbox",
      "click .config": "showMenu",
      "click .menu-item .col-0-idx-1 ": "preview",
      "click .dates": "openDatePickerHandler",
      "keydown .w-InlineEditForm .form-input": "enterKeyCheck",
      "mousedown .editable": "checkInlineType",
      "focus .w-InlineEditForm .form-input": "checkInlineType",
      "blur .w-InlineEditForm .form-input": "clearInline",
      "click .edit": "openEditPage",
      "click strand-popover .close": "closeNetStatusPopover",
      "contextmenu .edit-icon": "showEditIcon",
      "mousemove .edit-icon": "hideEditIcon",
      "click .ssl-url": "followTip",
      saved: "savedDatepickerHandler"
    },
    dataEvents: {
      collection: {
        reset: "load",
        startSearch: "useSearchLoader",
        "change:concept_id": function (model, conceptId) {
          var self = this;
          var concept = new ConceptsModel({
            id: conceptId
          });
          var selectedId = model.get("id");
          concept.fetch({
            success: function () {
              var passedConceptName = concept.get("name");
              self.getConcept(model, conceptId).then(function (passedConcept) {
                self.passConcept = passedConcept;
                self.sendAll(selectedId);
              });
              self.renderChange("concept", model, passedConceptName);
            }
          });
          return concept;
        },
        "change:hasADXError": function (model, error) {
          var id = model.get("id");
          var $tooltip = $("#adx-error-" + id);
          if (error) {
            var ADXError = model.get("hasADXError");
            var noVendorWarning = ADXError.showNoVendorWarning ? "no vendor" : ADXError.error_vendors;
            var secureInWarning = ADXError.showSecureInWarning ? "secure " : "";
            var tooltipContent = "Creatives with " + noVendorWarning + " will not run on AdX " + secureInWarning + "inventory";
            if ($tooltip) {
              $tooltip.next(".tooltipContent").html(tooltipContent);
              $tooltip.show();
            }
          } else {
            if ($tooltip) {
              $tooltip.hide();
            }
          }
        },
        "change:net_status": "updateNetStatus",
        fetchMoreComplete: function () {
          this.getMixinsAndBlend();
        }
      }
    },
    eventhubEvents: {
      "creativegrid:setSearchLoader": function () {
        this.setSearchLoader();
        this.itemViewed = {};
      },
      "creativegrid:setHighlightValue": function (data) {
        this.highlightValue = data;
      },
      "organization:select": function () {
        this.setSearchLoader();
      },
      "bulkedit:saved": "handleBulkEditSaved",
      "inlineSelectBoxDefaults:change": "handleInlineSelectChanged"
    },
    closeNetStatusPopover: function (e) {
      var $popover = $(e.currentTarget).closest("strand-popover");
      e.preventDefault();
      $popover[0].toggle();
    },
    initialize: function (args) {
      const videoHeaders = {
        name: {},
        id: {},
        concept: {
          fieldName: "concept.name"
        },
        advertiser: {
          fieldName: "advertiser.name"
        },
        "third-pas-id": {
          fieldName: "external_identifier"
        },
        secure: {
          fieldName: "is_https",
          marginLeft: 18
        }
      };
      this.staticVerticals = args.staticVerticals;
      this.searchTerm = args.searchTerm;
      this.violationFlaggingEnabled = T1Permissions.check("feature", "creatives_violation");
      this.canRender = true;
      this.setActive = "";
      this.progressMap = {};
      if (args.collection) {
        this.collection = args.collection;
        this.addDataEvent({
          collection: {
            "relational:change:progress": $.proxy(this.handleProgress, this)
          }
        });
      }
      this.sortableHeadersView = new T1Layout({
        el: () => this.el,
        template: '<div class="sortable-content"></div>',
        selectorPath: ".w-foot",
        layout: {
          ".sortable-content": [{
            module: "shared",
            viewType: "gridSorter",
            options: {
              parentEl: this.el,
              collection: this.collection,
              sortableHeaders: videoHeaders,
              onSort: () => {
                this.disableUISorter = true;
                this.useSearchLoader();
              }
            }
          }]
        }
      });
    },
    followTip: function () {
      window.open("https://mediamathsupport.force.com/s/article/Creative-Approvals", "_blank");
    },
    getMixinsAndBlend: function () {
      var self = this;
      var promises = self.collection.models.map(VideoUtils.processVendorsForModel);
      When.all(promises).then(function (mixins) {
        VideoUtils.blendModelsAndMixins(self.collection.models, mixins);
      });
    },
    getConcept: function (model, conceptId) {
      var deferred = $.Deferred();
      var passedConcept;
      var concept = new ConceptsModel({
        id: conceptId
      });
      concept.fetch({
        success: function () {
          passedConcept = concept.get("id");
          deferred.resolve(passedConcept);
        }
      });
      return deferred.promise();
    },
    fetchVendorsById: function (model) {
      var self = this;
      var $el = $(this.el);
      var details = model.get("details");
      var autoVend = {};
      var autoVendorIds = details ? details.get("autoVendors") : [];
      var manualVend = {};
      var manVendorIds = details ? details.get("vendors") : [];
      var autoSuccess = function (data) {
        var thenames = [];
        data.each(function (item) {
          thenames.push(item.get("name"));
        });
        $el.find("#autoVend" + model.id).html(thenames.toString());
        self.vendListData[model.id] = model;
        self.vendListData[model.id].data = data;
      };
      var manualSuccess = function (data) {
        var thenames = [];
        data.each(function (item) {
          thenames.push(item.get("name"));
        });
        $el.find("#manualVend" + model.id).html(thenames.toString());
      };
      autoVend[model.id] = new Vendors();
      autoVend[model.id].canCache = false;
      autoVend[model.id].pageLimit = 100;
      autoVend[model.id].isPaginated = false;
      if (autoVendorIds.length > 0) {
        autoVend[model.id].search.set({
          idList: autoVendorIds,
          fetchRequired: true,
          success: autoSuccess
        });
      }
      manualVend[model.id] = new Vendors();
      manualVend[model.id].canCache = false;
      manualVend[model.id].pageLimit = 100;
      manualVend[model.id].isPaginated = false;
      if (manVendorIds.length > 0) {
        manualVend[model.id].search.set({
          idList: manVendorIds,
          fetchRequired: true,
          success: manualSuccess
        });
      }
    },
    inVendorArray: function (value, array) {
      var theName;
      array.forEach(function (item) {
        if (value === Number(item.id)) {
          theName = item.get("name");
        }
      });
      return theName;
    },
    groupUrlsBy: function (orig, vendList) {
      var newArr = [];
      var vendorGroup = {};
      var cur, vendorName;
      for (var i = 0; i < orig.length; i++) {
        cur = orig[i];
        vendorName = "";
        if (!(cur.vendors[0] in vendorGroup)) {
          vendorName = cur.vendors[0] ? this.inVendorArray(cur.vendors[0], vendList) : "Unknown vendor";
          vendorGroup[cur.vendors[0]] = {
            group: vendorName,
            groupID: cur.vendors[0],
            urls: []
          };
          newArr.push(vendorGroup[cur.vendors[0]]);
        }
        vendorGroup[cur.vendors[0]].urls.push(cur.url);
      }
      return newArr;
    },
    showUnsecureURLs: function (model, vendList) {
      var $targetDiv = $("#nonSecure" + model.id);
      var $targetUL = "";
      var details = model.get("details");
      var list = details ? details.get("unsecureUrls") : {};
      var groupList, returnName, vendorName;
      if (!this.maxCount[model.id] && (list.length > 0 || !$.isEmptyObject(list))) {
        $targetDiv.html("");
        this.maxCount[model.id] = true;
        if ($.isArray(list)) {
          groupList = this.groupUrlsBy(list, vendList);
          _.each(groupList, function (item) {
            $targetDiv.append('<div class="secure-list-head">' + item.group + "</div>");
            $targetDiv.append('<ul id="id_' + item.groupID + '"></ul>');
            $targetUL = $targetDiv.find("#id_" + item.groupID);
            for (var i = 0; i < item.urls.length; i++) {
              $targetUL.append('<li class="secure-list">' + item.urls[i] + "</li>");
            }
          });
        } else {
          for (var i in list) {
            if (list.hasOwnProperty(i)) {
              returnName = this.inVendorArray(list[i].id, vendList);
              vendorName = returnName ? returnName : "Unknown vendor";
              if (list.hasOwnProperty(i)) {
                $targetDiv.append('<div class="secure-list-head">' + vendorName + "</div>");
                $targetDiv.append('<ul id="id_' + list[i].id + '"></ul>');
                $targetUL = $targetDiv.find("#id_" + list[i].id);
                for (var j = 0; j < list[i].urls.length; j++) {
                  $targetUL.append('<li class="secure-list">' + list[i].urls[j] + "</li>");
                }
              }
            }
          }
        }
      }
    },
    useSearchLoader: function () {
      this.setSearchLoader();
    },
    renderChange: function (attr, model, newVal) {
      const $el = this.el;
      const $textField = $el.find("#" + attr + model.id);
      const $spinner = $el.find("#" + attr + "-spinner-" + model.id);
      var $edited = $("#" + attr + model.id);
      var deferred = $.Deferred();
      var editedText = newVal === "STUB" ? "" : newVal;
      const previousVal = model.previous(attr);
      $edited.text(editedText || "--");
      $edited.attr("title", editedText || "--");
      function toggleTextAndSpinner() {
        $textField.toggle();
        $spinner.toggle();
      }
      if (attr === "name") {
        toggleTextAndSpinner();
        this.passName = newVal;
        this.sendAll(model.get("id"), toggleTextAndSpinner);
      } else if (attr === "external_identifier") {
        this.passExternalID = newVal;
        if (previousVal === "" && newVal === "STUB") {
          model.set({
            external_identifier: ""
          }, {
            silent: true
          });
        } else {
          toggleTextAndSpinner();
          this.sendAll(model.get("id"), toggleTextAndSpinner);
        }
      }
      deferred.resolve(newVal);
      return deferred.promise();
    },
    clickToggleNode: function (e) {
      var $currentTarget = $(e.currentTarget);
      var itemID = $currentTarget.data("id");
      var $target = $(e.target);
      var $arrow = $currentTarget.hasClass("icon-arrow") ? $currentTarget : $currentTarget.find(".icon-arrow");
      var expanded = $arrow.hasClass("expanded");
      var busy = $arrow.hasClass("busy");
      var $itemWrapper = $arrow.closest(".item-wrapper");
      var $child = $itemWrapper.children(".children");
      if (!$target.is("textarea")) {
        this.closeInlineEditor();
      }
      if (busy || $target.is("#iconGlyph") || $target.hasClass("strand-button") || $target.hasClass("settings-icon") || $target.hasClass("creative-settings")) {
        return;
      }
      if (itemID && !this.itemViewed[itemID]) {
        this.itemViewed[itemID] = true;
        this.showDrawerDataOnClick($itemWrapper, itemID);
      }
      this.preventDefault(e);
      $arrow.addClass("busy");
      $arrow.toggleClass("expanded");
      this.toggleNode(expanded, $child, $arrow);
      this.makeTooltips();
    },
    toggleNode: function (shouldHideChild, $child, $arrow) {
      $child[shouldHideChild ? "hide" : "show"]("blind", {}, 500).promise().done(function () {
        $arrow.removeClass("busy");
      });
    },
    showDrawerDataOnClick: function (itemWrapper, itemID) {
      var self = this;
      var $subChild = itemWrapper.find(".sub-child" + itemID);
      var $loader = $subChild.find("#appliedLoader");
      var $classAttr = $subChild.find(".classAttr");
      var $classCat = $subChild.find(".classCat");
      var $classLang = $subChild.find(".classLang");
      var classifications = new Classifications({
        id: itemID
      });
      var spinner = document.createElement("strand-spinner");
      var highest = null;
      var initialHeight = 0;
      var categories, currHeight, catParent, category;
      spinner.setAttribute("radius", "5");
      spinner.setAttribute("fillColor", "#333333");
      spinner.style.marginBottom = "3px";
      $loader.append(spinner);
      $loader.show();
      if (this.vendListData[itemID] && this.vendListData[itemID].data) {
        self.showUnsecureURLs(this.vendListData[itemID], this.vendListData[itemID].data);
      }
      classifications.fetch({
        onSuccess: function (data) {
          $loader.hide();
          categories = _.sortBy(data.verticals, "parent");
          if (categories.length) {
            $.each(categories, function (i, item) {
              category = item.description;
              if (item.parent !== 0) {
                catParent = self.getVerticalsParent(String(item.parent), self.staticVerticals);
                catParent = catParent ? '<span class="catParent">' + catParent + " >></span> " : "";
                category = catParent + category;
              }
              $classCat.append("<dd>" + category + "</dd>");
            });
          } else {
            $classCat.append("<dd>--</dd>");
          }
          if (data.attributes.length) {
            $.each(data.attributes, function (i, item) {
              $classAttr.append("<dd>" + item.name + "</dd>");
            });
          } else {
            $classAttr.append("<dd>--</dd>");
          }
          if (data.language) {
            $classLang.append("<dd>" + data.language.name + "</dd>");
          } else {
            $classLang.append("<dd>--</dd>");
          }
          $subChild.find(".classification-block dl").each(function () {
            currHeight = $(this).height();
            if (currHeight > initialHeight) {
              initialHeight = currHeight;
              highest = $(this).height();
            }
          });
          $subChild.find(".classification-block dl").height(highest);
        },
        onError: function () {
          var emptyClassifications = "<dd>--</dd>";
          $classCat.append(emptyClassifications);
          $classAttr.append(emptyClassifications);
          $classLang.append(emptyClassifications);
          $loader.hide();
        }
      });
    },
    getVerticalsParent: function (ID, staticData) {
      var mainData = staticData ? staticData.get("data") : "";
      var returnName;
      $.each(mainData, function (i, item) {
        if (ID === item.id) {
          returnName = item.description;
        }
      });
      return returnName;
    },
    load: function () {
      this.itemViewed = {};
      if (!this.collection.loaded) {
        this.duringRender();
        this.setSearchLoader();
      } else {
        this.maxCount = {};
        this.getMixinsAndBlend();
        this.duringRender();
      }
    },
    handleInlineSelectChanged: function (e) {
      var target = $(e.currentTarget);
      var bind = target.data("bind");
      if (bind === "concept_id") {
        T1.Notify("warning", "This creative may be currently assigned to a strategy. By changing the concept, the " + "creative may stop bidding &amp; adversely affect the strategy's performance.", false, false);
      }
    },
    duringRender: function () {
      var self = this;
      if (self.canRender) {
        self.canRender = false;
        self.render().then(function () {
          self.sortableHeadersView.load();
          self.postRender();
          self.canRender = true;
        }, function () {
          self.canRender = true;
        });
      }
    },
    postRender: function () {
      if (this.collection.models.length) {
        Array.from(this.$(".item-wrapper")).forEach(updateProgress(this));
        this.makeTooltips();
        setTimeout(() => this.checkNetStatus(), 0);
      }
      this.el.append(CreativeUtils.createDatePicker());
    },
    checkNetStatus: function () {
      var self = this;
      var el = this.el;
      var creativeNetStatuses = new CreativeNetStatuses(null, {
        pageLimit: 100
      });
      var ids = this.collection.map(function (model) {
        return model.id;
      }).splice(-100);
      if (ids.length) {
        creativeNetStatuses.fetchOptions.atomic_creative_ids = "(" + ids + ")";
        creativeNetStatuses.fetch({
          success: function (data) {
            data.each(function (model) {
              var ID = model.get("atomic_creative_id");
              var netStatus = model.get("net_status").toLowerCase();
              var collectionModel = self.collection.get(ID);
              var $netStatusIcon, $spinner, $popOver, $adxOpen, $adxDeals, $appNexus, $microsoft, $yahoo, $brightroll, $mopub, hasADXerror, hasADXvendor;
              if (model.get("atomic_creative_id") === collectionModel.get("id")) {
                $netStatusIcon = el.find("#displayNetStatus" + ID);
                $spinner = el.find("#spinner-" + ID);
                $popOver = el.find("#netStatusPopover" + ID);
                $adxOpen = $popOver.find("#adxOpen");
                $adxDeals = $popOver.find("#adxDeals");
                $appNexus = $popOver.find("#appNexus");
                $microsoft = $popOver.find("#microsoft");
                $yahoo = $popOver.find("#yahoo");
                $brightroll = $popOver.find("#brightroll");
                $mopub = $popOver.find("#mopub");
                hasADXerror = collectionModel.get("hasADXError");
                hasADXvendor = hasADXerror ? collectionModel.get("hasADXError").error_vendors : false;
                $spinner.hide();
                $netStatusIcon.show();
                if (hasADXerror) {
                  netStatus = hasADXvendor ? "vendor unsupported" : "missing vendor";
                  self.setNetStatusIcon($adxOpen, netStatus, true);
                  self.setNetStatusIcon($adxDeals, netStatus, true);
                } else {
                  self.setNetStatusIcon($adxOpen, model.get("adx_open_auction").toLowerCase(), true);
                  self.setNetStatusIcon($adxDeals, model.get("adx_deals").toLowerCase(), true);
                }
                collectionModel.set({
                  net_status: netStatus
                }, {
                  silent: true
                });
                self.setNetStatusIcon($netStatusIcon, netStatus, false);
                self.setNetStatusIcon($appNexus, model.get("app_nexus").toLowerCase(), true);
                self.setNetStatusIcon($microsoft, model.get("microsoft_ad_exchange").toLowerCase(), true);
                self.setNetStatusIcon($yahoo, model.get("right_media_exchange").toLowerCase(), true);
                self.setNetStatusIcon($brightroll, model.get("brightroll_for_display").toLowerCase(), true);
                self.setNetStatusIcon($mopub, model.get("mopub_direct").toLowerCase(), true);
              }
            });
          }
        });
      }
    },
    setNetStatusIcon: function (iconTarget, netStatus, isPopover) {
      if (isPopover) {
        iconTarget.find(".status-text").text(netStatus);
        iconTarget = iconTarget.find("#" + iconTarget.attr("id") + "Icon");
      }
      if (netStatus === "approved") {
        iconTarget.attr("type", "success");
        iconTarget.removeClass("net-status-pending").addClass("net-status-approved");
      } else if (netStatus === "pending") {} else if (netStatus === "pending > 48 hours") {
        iconTarget.attr("type", "pending");
        iconTarget.attr("width", "20").attr("height", "20");
        if (isPopover) {
          iconTarget.removeClass("net-status-pending").addClass("net-status-pending48");
        }
      } else if (netStatus === "rejected") {
        iconTarget.removeClass("net-status-pending").addClass("net-status-rejected");
      } else if (netStatus === "n/a") {
        iconTarget.parent().html("&nbsp;--&nbsp;");
      } else if (netStatus === "missing vendor" || netStatus === "vendor unsupported") {
        iconTarget.removeClass("net-status-pending").addClass("net-status-rejected");
      }
    },
    showEditIcon: function (e) {
      var $target = $(e.currentTarget);
      $target.closest(".item-wrapper").addClass("hover");
    },
    hideEditIcon: function () {
      var self = this;
      var $allEdit = $(self.el).find(".item-wrapper");
      $allEdit.removeClass("hover");
    },
    openEditPage: function (e) {
      var $target = $(e.currentTarget);
      var id = Number($target.attr("id"));
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        window.open("#creatives/video/" + id, "_blank");
      } else {
        T1.Location.set("#creatives/video/" + id);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    showMenu: function (e) {
      var self = this;
      var target = $(e.currentTarget);
      var item = target.closest(".item");
      var id = item.data("id");
      var model = this.collection.get(id);
      var details = model && model.get("details");
      var isActive = details && details.get("active");
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Deactivate: 0,
        Activate: 1,
        Edit: 2,
        Preview: 3,
        FlagViolation: 4
      };
      menuItems[menuIndexRef.Activate].visible = !isActive;
      menuItems[menuIndexRef.Deactivate].visible = isActive;
      menuItems[menuIndexRef.Edit].url = "#creatives/video/" + id;
      menuItems[menuIndexRef.FlagViolation].visible = self.violationFlaggingEnabled;
      menuConfig.menuTriggerEleSelector = ".item[data-id=" + id + "] .config";
      menuConfig.updateSettingsMenuConfig = updateSettingsMenuConfig;
      if (!details || !details.get("readyToServe")) {
        menuItems[menuIndexRef.Preview].visible = false;
      }
      if (!details) {
        menuItems[menuIndexRef.Activate].visible = false;
        menuItems[menuIndexRef.Deactivate].visible = false;
      }
      function updateSettingsMenuConfig() {
        if (self.collection.get(model.id).get("details")) {
          if (self.collection.get(model.id).get("details").get("readyToServe")) {
            menuItems[menuIndexRef.Preview].visible = true;
          }
          menuItems[menuIndexRef.Activate].visible = !self.collection.get(model.id).get("details").get("active");
          menuItems[menuIndexRef.Deactivate].visible = self.collection.get(model.id).get("details").get("active");
        }
      }
      T1Menu(menuConfig, this);
      target.find(".settings-icon").trigger("click");
    },
    makeTooltips: function () {
      T1.Tooltip("body", {
        tooltipEle: $(this.el).find(".tip").not(".adx-warning, .adx-error"),
        getExternalTip: true
      });
      T1.Tooltip("body", {
        getExternalTip: true,
        tooltipEle: $(this.el).find(".t1-tooltip").not(".tip, .adx-warning, .adx-error"),
        className: "w-InlineEditError"
      });
      if (this.collection.models.length) {
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".adx-warning"),
          className: "adx-warning-tipsy"
        });
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".adx-error"),
          className: "adx-error-tipsy"
        });
      }
    },
    scrollContentRendered: function () {
      this.postRender();
    },
    activateHandler: function (e) {
      e.preventDefault();
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      this.setActive = true;
      this.inlineSaving = true;
      this.sendAll(id);
    },
    deactivateHandler: function (e) {
      e.preventDefault();
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      this.setActive = false;
      this.inlineSaving = true;
      this.sendAll(id);
    },
    updateStatus: function (id, status) {
      var $item = $("#id" + id).find(".item");
      if (!status) {
        $item.removeClass("active");
        $item.addClass("inactive");
      } else {
        $item.removeClass("inactive");
        $item.addClass("active");
      }
      this.collection.get(id).get("details").set({
        active: status
      });
    },
    updateNetStatus: function (model) {
      const el = this.el;
      const id = model.get("id");
      const netStatus = model.get("net_status");
      const $netStatusIcon = el.find("#displayNetStatus" + id);
      $netStatusIcon.removeClass("net-status-approved").removeClass("net-status-rejected").removeClass("net-status-pending48").addClass("net-status-pending");
      $netStatusIcon.attr("type", "warning");
      this.setNetStatusIcon($netStatusIcon, netStatus, false);
    },
    edit: function (e) {
      e.preventDefault();
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      T1.Location.set("creatives/video/" + id);
    },
    assignments: function (e) {
      e.preventDefault();
    },
    preview: function (e) {
      e.preventDefault();
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      if (id) {
        CreativeUtils.showPreview([id], "video");
      }
    },
    showFlagViolationDialog: function (e) {
      e.preventDefault();
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      var model;
      if (id) {
        model = this.collection.get(id);
        CreativeUtils.showFlagViolationDialog(model.toJSON(), "video");
      }
    },
    openDatePickerHandler: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.closeInlineEditor();
      setTimeout(CreativeUtils.openDatePickerHandler(e, this), 0);
    },
    savedDatepickerHandler: function (e) {
      const {
        start: start,
        end: end,
        id: id
      } = e.target;
      const value = id.split("-")[2];
      const model = this.collection.get(value);
      const modelID = model.get("id");
      const isDisplay = false;
      this.startTime = moment.utc(start).unix();
      this.endTime = moment.utc(end).unix();
      this.inlineSaving = true;
      this.sendAll(modelID);
      CreativeUtils.setDateSavingState(model, true);
      CreativeUtils.setDateInGrid(this, modelID, "end", end, isDisplay);
      CreativeUtils.setDateInGrid(this, modelID, "start", start, isDisplay);
    },
    handleProgress: function (model) {
      var id = model.get("id");
      var status;
      if (this.progressMap[id]) {
        status = model.get("progress");
        if (status.encodingStatus) {
          this.progressMap[id].progress(status.encodingStatus.percent);
        }
      }
    },
    handleCheckbox: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.closeInlineEditor();
      var self = this;
      var $target = $(e.target);
      var count = 0;
      var outClass = "";
      var checked, current, id, model, selected;
      if ($target.hasClass("all")) {
        current = $target.attr("class").split(" ");
        if (current.indexOf("checked") !== -1) {
          $(".checkbox").removeClass("checked");
          this.collection.toggleAllSelected(false);
        } else if (current.indexOf("partial") !== -1) {
          outClass = "checked";
          $target.removeClass("partial").addClass("checked");
          $(".checkbox").addClass("checked");
        } else {
          outClass = "checked";
          $target.addClass("checked");
          $(".checkbox").addClass("checked");
        }
        $(".checkbox.checked:not(.all)").each(function () {
          id = $(this).data("id");
          model = self.collection.get(id);
          selected = model.get("selected") || false;
          model.set({
            selected: !selected
          });
        });
      } else {
        $target.toggleClass("checked");
        count = $(".checkbox:not(.all)").size();
        checked = $(".checkbox.checked:not(.all)").size();
        if (count === checked) {
          outClass = "checked";
        } else if (checked < count && checked > 0) {
          outClass = "partial";
        }
        $(".checkbox.all").removeClass("checked partial").addClass(outClass);
        id = $target.data("id");
        model = this.collection.get(id);
        selected = model.get("selected") || false;
        model.set({
          selected: !selected
        });
      }
      $(".w-grid").trigger("changed:checkbox", {
        state: outClass === "" ? "none" : outClass
      });
    },
    handleBulkEditSaved: function (opts) {
      if (opts.requireRefresh) {
        this.setSearchLoader();
        this.collection.fetch({
          reset: true
        });
      }
    },
    sendAll: function (getId, toggleTextAndSpinner) {
      var self = this;
      var $el = self.el;
      var collectModel = this.collection.get(getId);
      var acExtId = collectModel.get("external_identifier");
      var outputModel = new VideoCreativeDetail();
      var details = collectModel.get("details");
      var name = this.passName || details.get("name");
      var advertiser = details.get("advertiser");
      var concept = this.passConcept || details.get("concept");
      var acExtId = collectModel.get("external_identifier");
      var externalID = acExtId && acExtId !== "STUB" ? acExtId : null;
      var landingUrl = this.passLandURL || details.get("landingUrl");
      var active = this.setActive !== "" ? this.setActive : details.get("active");
      var startTime = this.startTime || details.get("startTime");
      var endTime = this.endTime || details.get("endTime");
      var skippableDuration = details.get("skippableDuration");
      var eventPixels = details.get("eventPixels");
      var vendors = details.get("vendors");
      var id = collectModel.get("id");
      var loader = CreativeUtils.createInlineLoader(getId);
      var statusInvalid, conflictCallback;
      if (this.inlineSaving) {
        loader.start();
      }
      if (details.get("customVAST")) {
        outputModel.set({
          customVAST: details.get("customVAST")
        });
      } else if (details.get("customVASTUrl")) {
        outputModel.set({
          customVASTUrl: details.get("customVASTUrl")
        });
      } else {
        outputModel.set({
          clickthroughUrl: this.passClickURL || details.get("clickthroughUrl")
        });
      }
      outputModel.set({
        name: name,
        advertiser: advertiser,
        concept: concept,
        externalIdentifier: externalID,
        landingUrl: landingUrl,
        active: active,
        startTime: startTime,
        endTime: endTime,
        skippableDuration: skippableDuration,
        creativeId: id,
        id: id,
        eventPixels: eventPixels,
        vendors: vendors
      });
      new ParallelDeferred(outputModel.createEdit(outputModel.toJSON(), {
        statusInvalid: statusInvalid,
        conflict: conflictCallback
      }), function () {
        var def = $.Deferred();
        def.resolve();
        return def;
      }()).then($.noop, function () {
        statusInvalid([]);
      }).pipe(function () {
        var def = $.Deferred();
        var saveOpts = {
          dataType: "json",
          stringify: true,
          contentType: "application/json",
          processData: false,
          success: function () {
            collectModel.set({
              version: Number(collectModel.get("version")) + 2 + ""
            });
            if (toggleTextAndSpinner) {
              toggleTextAndSpinner();
            }
            loader.stop();
            self.closeInlineEditor();
            CreativeUtils.setDateSavingState(collectModel, false);
            CreativeUtils.isDateSaving = false;
            if (self.passLandURL) {
              $el.find("#landurl" + id).text(self.passLandURL);
              collectModel.set({
                videoLandUrl: self.passLandURL
              });
            }
            if (self.passClickURL) {
              $el.find("#clickurl" + id).text(self.passClickURL);
              collectModel.set({
                videoClickUrl: self.passClickURL
              });
            }
            if (self.setActive !== "") {
              T1.Notify("message", "Creative updated successfully!");
              self.updateStatus(id, self.setActive);
              self.setActive = "";
            }
            def.resolve();
          },
          statusInvalid: function () {
            loader.stop();
            self.inlineSaving = false;
            CreativeUtils.setDateSavingState(collectModel, false);
          },
          conflict: conflictCallback,
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400, 413]
          },
          error: function (data, response) {
            var jsonData = JSON.parse(response.responseText);
            var errors;
            loader.stop();
            CreativeUtils.setDateSavingState(collectModel, false);
            if (jsonData.errors && jsonData.errors.field_error) {
              errors = !$.isArray(jsonData.errors.field_error) ? [jsonData.errors.field_error] : jsonData.errors.field_error;
              errors = _.map(errors, function (error) {
                return {
                  field: error.name.replace(/customVast/g, "vast"),
                  message: error.error
                };
              });
              if (errors.length > 0 && (errors[0].field === "landingUrl" || errors[0].field === "clickthroughUrl")) {
                self.inlineError(getId, loader, "Please provide a valid URL.");
              }
            }
            self.inlineSaving = false;
          },
          processAjaxResponse: outputModel.processAjaxResponse
        };
        if (id) {
          outputModel.set({
            id: id
          });
        }
        outputModel.save(outputModel.toJSON(), saveOpts);
        return def.promise();
      });
    },
    unload: function () {
      $(".tipsy").remove();
      clearInterval(this.checkReadyInterval);
      this.collection.reset([], {
        silent: true
      });
      for (var key in this.itemViewed) {
        if (this.itemViewed.hasOwnProperty(key)) {
          this.itemViewed[key] = null;
        }
      }
    },
    canUnload: function () {
      return false;
    },
    inlineError: function (ID, loader, message) {
      var target = $("#child" + ID).find(".form-input");
      if (loader) {
        loader.stop();
      }
      this.applyTooltip(target, message);
      target.removeClass("error");
      target.parent().addClass("error");
      target.trigger("focus");
    },
    enterKeyCheck: function (e) {
      var keyCode = e.which;
      if (keyCode === 13) {
        e.data = {
          enterDisabled: true
        };
      }
    },
    clearInline: function () {
      $(".tipsy").remove();
    },
    checkInlineType: function (e) {
      var self = this;
      var currentTarget = $(e.currentTarget);
      var inputType = currentTarget.data("bind");
      var targetID = currentTarget.closest(".item-wrapper").data("id");
      var saveButton = currentTarget.closest(".w-InlineEditForm").find(".save-action");
      this.passConcept = "";
      if (inputType === "external_identifier") {
        saveButton.click(function (event) {
          if (!currentTarget.val()) {
            self.closeInlineEditor();
            currentTarget.val("STUB");
          }
        });
      }
      if (inputType === "videoLandUrl" || inputType === "videoClickUrl") {
        saveButton.click(function (event) {
          self.inlineSaving = true;
          event.stopPropagation();
          event.preventDefault();
          if (inputType === "videoClickUrl") {
            self.passClickURL = currentTarget.val();
            self.passLandURL = null;
          }
          if (inputType === "videoLandUrl") {
            self.passLandURL = currentTarget.val();
            self.passClickURL = null;
          }
          if (currentTarget.val() === "") {
            self.inlineError(targetID, "", "This is a required field.");
          } else {
            self.sendAll(targetID);
          }
        });
      } else if (inputType) {
        this.passLandURL = null;
        this.passClickURL = null;
      } else {
        this.clearInline();
        this.closeInlineEditor();
      }
    },
    postData: function () {
      this.noop();
    },
    noop: function () {},
    handleIsReadyToServe: function (modelID) {
      for (var i = this.readyToServeList.length - 1; i >= 0; i--) {
        if (this.readyToServeList[i] === modelID) {
          this.readyToServeList.splice(i, 1);
        }
      }
      this.collection.get(modelID).get("details").set({
        readyToServe: true
      });
      $("#progressText" + modelID).html("Transcoding finished");
      $("#transBar" + modelID).find(".colored-bar").width("100%");
    },
    checkCollection: function () {
      var self = this;
      this.collectReadyCheck.forEach(function (model) {
        var modelDetails = model.get("details");
        var progressPercent = modelDetails.get("percent");
        if (!modelDetails.get("customVAST") && !modelDetails.get("customVASTUrl") && !modelDetails.get("isUploaded")) {
          for (var i = self.readyToServeList.length - 1; i >= 0; i--) {
            if (self.readyToServeList[i] === model.id) {
              self.readyToServeList.splice(i, 1);
            }
          }
        }
        if (modelDetails.get("readyToServe")) {
          self.handleIsReadyToServe(model.id);
        }
        $("#progressText" + model.id).html("Transcoding in progress " + progressPercent + "%");
        $("#transBar" + model.id).find(".colored-bar").width(progressPercent + "%");
      });
    },
    checkReadyToServe: function () {
      var self = this;
      var limitCount = 0;
      this.checkReadyInterval = setInterval(function () {
        self.collectReadyCheck = new VideoCreativesCollection();
        self.collectReadyCheck.search.set({
          fetchRequired: true,
          idList: self.readyToServeList,
          success: function () {
            self.checkCollection();
            limitCount++;
            if (limitCount >= 55 || self.readyToServeList.length <= 0) {
              clearInterval(self.checkReadyInterval);
            }
          }
        });
      }, 7e3);
    },
    strPadLeft: function (string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
    serialize: function (models) {
      var self = this;
      var data = models || this.collection;
      var searchTerm = this.highlightValue;
      var outputCount = 0;
      var out = {};
      var output, minutes, seconds, time, finalTime;
      if (searchTerm) {
        searchTerm = decodeURIComponent(CreativeUtils.replaceSymbolsInName(searchTerm));
      }
      output = data.map(function (model) {
        out = model.toJSON();
        outputCount++;
        if (out.details) {
          out.isSecure = out.details.unsecureUrls.length === 0 || $.isEmptyObject(out.details.unsecureUrls);
        } else {
          out.isSecure = "--";
        }
        if (out.external_identifier === "STUB") {
          model.set({
            external_identifier: ""
          }, {
            silent: true
          });
          out.external_identifier = "--";
        }
        if (outputCount < data.length && out.t1as === "1" && out.details && out.details.readyToServe === false && !out.details.hasError) {
          self.readyToServeList.push(out.id.toString());
        } else if (outputCount === data.length && self.readyToServeList.length > 0) {
          if (self.callOnce === 0) {
            self.checkReadyToServe(self.readyToServeList);
            self.callOnce++;
          }
        }
        out.skipOffset = "--";
        if (out.details && out.details.vpaidVersion) {
          out.VPAID = "VPAID " + out.details.vpaidVersion + ".0";
        } else {
          out.VPAID = "--";
        }
        if (out.details && out.vast_version) {
          out.vastVersion = "VAST " + out.vast_version + ".0";
        } else {
          out.vastVersion = "--";
        }
        if (out.details && out.details.skippableDuration) {
          time = out.details.skippableDuration;
          minutes = Math.floor(time / 60);
          seconds = time - minutes * 60;
          finalTime = self.strPadLeft(minutes, "0", 2) + ":" + self.strPadLeft(seconds, "0", 2);
          out.skipOffset = finalTime;
        }
        out.is_3pas = out.t1as === "0";
        out.isVast = out.details && (out.details.customVAST || out.details.customVASTUrl);
        out.clickurl = out.details ? out.details.clickthroughUrl : "";
        out.landurl = out.details ? out.details.landingUrl : "";
        out.landingPageTooltipTxt = "Declare the page URL a user will arrive on after clicking the ad. " + "This should be the destination URL (e.g. http://www.advertiser.com), not a click tracking URL " + "(e.g. http://ad.3pserver.com/clicktracker).";
        model.set({
          videoLandUrl: out.landurl
        });
        model.set({
          videoClickUrl: out.clickurl
        });
        model.set({
          vastVersion: out.vast_version
        });
        out.showName = CreativeUtils.replaceSymbolsInName(out.name);
        out.showId = out.id;
        out.showAdName = out.advertiser ? CreativeUtils.replaceSymbolsInName(out.advertiser.name) : "";
        out.showConceptName = out.concept ? CreativeUtils.replaceSymbolsInName(out.concept.name) : "";
        if (searchTerm) {
          out.showName = T1.Utils.highlightText(out.showName, searchTerm);
          out.showId = T1.Utils.highlightText(out.id, searchTerm);
          out.showAdName = T1.Utils.highlightText(out.showAdName, searchTerm);
          out.showConceptName = out.concept ? T1.Utils.highlightText(out.showConceptName, searchTerm) : "--";
        }
        out.startDate = out.start_date ? Date.parse(out.start_date).toString("MM/dd/yyyy") : "--";
        out.endDate = out.end_date ? Date.parse(out.end_date).toString("MM/dd/yyyy") : "--";
        return out;
      });
      return {
        list: output,
        contentType: "video-creative-list-grid"
      };
    }
  });
  (function addInlineEditRenderers(collEvents) {
    var changeAttrs = ["name", "external_identifier"];
    changeAttrs.forEach(function (attr) {
      collEvents["change:" + attr] = function (model, newVal) {
        this.renderChange(attr, model, newVal);
      };
    });
  })(VideoGridView.prototype.dataEvents.collection);
  var dropdownOpts = {
    concept_id: function (model) {
      var deferred = When.defer();
      var advertiser = model.get("advertiser");
      var config = {
        contentClass: "inline-edit-loader",
        target: "#id" + model.get("id"),
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 0
        }
      };
      var concepts, loader;
      concepts = new Concepts();
      concepts.search.clear();
      concepts.setAdvertiser(advertiser.get("id"));
      concepts.pageLimit = 100;
      concepts.fetchOptions.sort_by = "name,id";
      loader = T1.Loader.create(config);
      loader.stop = function () {
        var el = $(".inline-edit-loader");
        el.fadeOut(200, function () {
          el.remove();
        });
      };
      loader.start();
      concepts.fetchOptions = {
        sort_by: "name"
      };
      concepts.fetch({
        success: function () {
          var inlineConcept = model.get("concept");
          var options = concepts && concepts.map ? concepts.map(function (concept) {
            return {
              value: concept.get("id"),
              text: concept.get("name")
            };
          }) : [];
          if (inlineConcept !== null) {
            var conceptId = inlineConcept.get("id");
            var collModel = concepts.get(conceptId);
            if (collModel === undefined) {
              options.push({
                text: inlineConcept.get("name"),
                value: conceptId
              });
            }
          }
          loader.stop();
          return deferred.resolve({
            searchFieldDisabled: false,
            options: options,
            autoComplete: {
              autoComplete: true,
              searchField: "name",
              onSearchInputCleared: function (searchOptions) {
                concepts.search.clear();
                concepts.fetch({
                  success: function () {
                    searchOptions.success(concepts);
                  }
                });
              },
              onSearchRequest: function (searchOptions) {
                concepts.search.set(searchOptions);
                concepts.fetch({
                  success: function () {
                    searchOptions.success(concepts);
                  }
                });
              }
            }
          });
        }
      });
      return deferred.promise;
    }
  };
  var inlineEditable = T1Form.InlineEdit(VideoGridView, dropdownOpts);
  inlineEditable.prototype.actionsConfig = {
    events: {
      "click .save": "noop",
      "click .cancel": "clearInline"
    }
  };
  return gridDecorator(inlineEditable);
});
