define('models/t1AppAcceptedTerms',["Underscore", "T1", "T1Model", "T1Comm", "models/sessionUser"], function (_, T1, T1Model, T1Comm, User) {
  "use strict";

  T1.Models.T1AppAcceptedTerms = T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    dataType: "json",
    url: "v1.0/accepted-terms/",
    fetch: function (options) {
      var self = this;
      var user = User.getUser();
      var def = $.Deferred();
      T1Comm.get({
        dataType: "json",
        processAjaxResponse: self.processAjaxResponse,
        onSuccess: function (data) {
          options.success(data);
          def.resolve();
        },
        sourceURL: T1.API_APPS_ROOT + "v1.0/accepted-terms?q=user_id==" + user.get("id")
      });
      return def.promise();
    },
    createEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred;
    },
    acceptTerms: function (appId) {
      var self = this;
      var def = $.Deferred();
      self.clear({
        slient: true
      });
      self.set({
        app_id: parseInt(appId, 10)
      });
      self.createEdit(self.toJSON(), {}).pipe(function () {
        var saveOpts = {
          dataType: "json",
          stringify: true,
          contentType: "application/json",
          processData: false,
          processAjaxResponse: self.processAjaxResponse,
          onSuccess: function () {
            def.resolve();
          },
          onError: function (e) {
            def.reject();
          }
        };
        self.save(self.toJSON(), saveOpts);
      });
      return def.promise();
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.T1AppAcceptedTerms;
});
