define('models/segmentReport',["jQuery", "T1", "Backbone", "T1Model"], function ($, T1, Backbone, T1Model) {
  "use strict";

  T1.Models.Segment = T1Model.extend({
    urlRoot: "overlap",
    reportId: null,
    apiRoot: function () {
      if (!T1.ANALYTICS_API_BASE) {
        return "/analytics/";
      }
      return T1.ANALYTICS_API_BASE;
    },
    segments_A: [],
    segments_B: [],
    parse: function (response) {
      var report_type = this.urlRoot;
      var report_id;
      if (typeof response !== "object") {
        if (typeof response === "string") {
          response = JSON.parse(response);
        }
      }
      if (response && (response.status.code == "success" || response.status.code == "ok")) {
        if (report_type === "/analytics/performance") {
          report_id = response.report_id;
        } else {
          report_id = response.data.report_id;
        }
      }
      this.set({
        report_id: report_id
      });
    },
    fetch: function (options) {},
    save: function (data, opts) {
      var def = $.Deferred();
      var self = this;
      var handleInvalid = function (errors, message, formEl) {
        throw new Error("Segment Report save failed.");
      };
      var options = {
        url: self.apiRoot() + (opts.report || self.urlRoot),
        type: "POST",
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: opts.success,
        error: def.reject,
        statusInvalid: handleInvalid,
        processAjaxResponse: self.processAjaxResponse
      };
      this.set({
        report_type: opts.report
      });
      self.urlRoot = self.apiRoot() + opts.report;
      T1Model.prototype.save.call(this, data, options);
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.Segment;
});
