define('collections/creativesDisplayBulk3Pas',["jQuery", "T1", "T1Collection", "models/bulk3Pas"], function ($, T1, T1Collection, Bulk3Pas) {
  "use strict";

  T1.Models.Bulk3Pas = T1Collection.extend({
    model: Bulk3Pas,
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }]
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    urlString: "bulk3pas"
  });
  return T1.Models.Bulk3Pas;
});
