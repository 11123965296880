define('modules/creatives/views/tabs',['require','Underscore','jQuery','models/creative','T1','T1Permissions','T1TabLayoutV2','T1View','text!modules/creatives/templates/tabs.html','jQueryUI'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const CreativeModel = require("models/creative");
  const T1 = require("T1");
  const T1Permissions = require("T1Permissions");
  const T1TabLayout = require("T1TabLayoutV2");
  const T1View = require("T1View");
  const template = require("text!modules/creatives/templates/tabs.html");
  const jQueryUI = require("jQueryUI");
  var showComponentsTab = T1Permissions.check("feature", "creatives_components_tab");
  function updateBreadCrumb() {
    T1.EventHub.publish("updateNavPath", {});
  }
  return T1View.extend({
    template: template,
    model: new CreativeModel(),
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    initialize() {
      this.canLoad = true;
      updateBreadCrumb();
      this.initLayouts();
    },
    canUnload() {
      return true;
    },
    initLayouts() {
      var model = this.model;
      var tabs = [];
      if (T1Permissions.check("feature", "creatives")) {
        tabs.push({
          label: "Display",
          module: "creatives/display",
          routeName: "displays",
          viewType: "list",
          options: {}
        });
      }
      if (T1Permissions.check("feature", "video")) {
        tabs.push({
          label: "Video / Audio",
          module: "creatives/video",
          routeName: "videos",
          viewType: "list",
          options: {}
        });
      }
      tabs.push({
        label: "Concept",
        module: "creatives/concept",
        routeName: "concepts",
        viewType: "list",
        options: {}
      });
      if (showComponentsTab) {
        tabs.push({
          label: "Creatives",
          module: "creatives/componentBased",
          routeName: "componentBased",
          viewType: "list",
          tabRibbon: true,
          options: {}
        });
      }
      this.layout = new T1TabLayout({
        tabSetName: "CreativeList",
        level: 1,
        disabled: [],
        tabs: tabs,
        ignoreRouteDepth: true,
        selected: model.get("mainTabSectionId") || 0
      });
    },
    load() {
      if (!this.canLoad) {
        return;
      }
      this.render().then(() => {
        this.layout.el = this.$(".edit-create-tabs");
        this.layout.load();
        if (showComponentsTab) {
          this.$(".ui-tab:last-child").attr("id", "creatives-tab-label");
        }
      });
    },
    saveSelectedTab(opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, tab => tab.label === selectedLabel), tabs);
      this.model.setMainTabSection(selectedIndex);
    },
    serialize() {
      return {
        canAddCreative: "add-action",
        canCreateCreative: true,
        isReadOnly: false
      };
    }
  });
});
