define('modules/strategy/views/summary',["jQuery", "underscore", "T1", "T1View", "T1Layout", "text!modules/strategy/templates/summary.html", "utils/CampaignStrategyUtils", "T1Timezones", "modules/targeting/location/models/hyperLocalTargeting", "models/strategyTargetDimensions", "collections/strategySiteLists", "collections/dayParts", "models/strategyAudienceGroupedSegments", "models/strategyTargetGroupedSegments", "collections/strategyRetiredAudienceSegments", "collections/strategyRetiredTargetSegments", "collections/nemoAttachments", "T1Permissions", "T1Preferences"], function ($, _, T1, T1View, T1Layout, template, Utils, T1Timezones, HyperLocalTargeting, StrategyTargetDimensions, StrategySiteLists, DayParts, GroupedSegments, StrategyTargetSegments, RetiredSegments, StrategyRetiredTargetSegments, NemoIPAttachments, T1Permissions) {
  "use strict";

  var strategyTypeNameRef = {
    AUD: "Audience Targeting (AUD)",
    REM: "Remarketing (REM)",
    GBO: "Goal-Based Optimization (GBO)"
  };
  var supplyTypeRef = {
    MKT: "Curated Markets",
    RTB: "RTB & Private Market Place (PMP)",
    RMX_API: "External Media Tracking",
    BATCH: "Facebook"
  };
  var goalTypeRef = {
    spend: "CPM SPEND",
    reach: "CPM REACH",
    cpc: "CPC",
    cpe: "CPE",
    cpa: "CPA",
    roi: "ROI",
    vcr: "Video Completion Rate",
    ctr: "CTR",
    viewability_rate: "Viewability Rate",
    vcpm: "Viewable CPM",
    cpcv: "CPCV"
  };
  var pacingTypeRef = Utils.pacingTypeDataDisplayHash;
  var intervalRef = Utils.intervalDataDisplayHash;
  var formatsRolledUpNumbersNoAbbreviation = Utils.formatsRolledUpNumbersNoAbbreviation;
  var formatCurrency = T1.Utils.formatCurrency;
  var nonBreakingSpace = "&nbsp;";
  var useAllocationPacing = T1Permissions.check("feature", "use_alloc_pacing");
  return T1View.extend({
    template: template,
    displayDateFormat: "MM/dd/yyyy",
    displayTimeFormat: "h:mm tt",
    initialize: function ({
      model: model
    }) {
      var isVideoStrategy = model.get("media_type") === "VIDEO";
      this.isFacebookStrategy = model.get("supply_type") === "BATCH";
      this.model = model;
      this.editLinkPrefix = `#strategy/edit/${model.id}`;
      this.iASId = COMPASS_ENV.IAS_ID;
      this.hasVideoPermission = T1Permissions.check("feature", "video", model);
      this.canEdit = T1Permissions.check("strategy.edit", model) && (isVideoStrategy ? this.hasVideoPermission : true);
    },
    canUnload: function () {
      return true;
    },
    onTimezoneResponse: function (serializedData, timezoneMethods) {
      var campaignObj = this.model.get("campaign");
      var zoneName = campaignObj ? campaignObj.get("zone_name") : null;
      var datesObject = _.find(serializedData.summaryLeft, item => item.fieldName === "Start / End Dates");
      if (datesObject) {
        this.timezones = timezoneMethods;
        this.zoneName = zoneName || this.timezones.getDefaultZoneName();
        datesObject.fieldName = this.getStartEndDates();
        this.renderPartial(".summary-block-left");
      }
    },
    load: function () {
      T1.EventHub.publish("strategy-badges-edit:placementCheck", {
        currentView: "summary"
      });
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.stratTargetDims = new StrategyTargetDimensions({
        id: this.model.id
      });
      this.render().then(serializedData => {
        T1Timezones.done(timezoneMethods => {
          this.onTimezoneResponse(serializedData, timezoneMethods);
        });
        this.stratTargetDims.fetch().then(() => {
          this.loadSections();
        });
      });
    },
    loadSections: function () {
      const strategyId = this.model.id;
      var siteList = new StrategySiteLists();
      var stratTargetDims = this.stratTargetDims;
      var dayParts = new DayParts();
      var version = this.model.get("version");
      var readOnlyViews = ["w-ReadonlySupply", "w-ReadonlyCreatives", "w-ReadonlyAudience", "w-ReadonlyContextual", "w-ReadonlyPixel", "w-ReadonlySitelists", "w-ReadonlyLocation", "w-ReadonlyDaypart", "w-ReadonlyTechnology", "w-ReadonlyVideoTargeting"];
      const groupedAudienceSegments = new GroupedSegments({
        id: strategyId,
        version: version
      });
      const targetSegments = new StrategyTargetSegments({
        id: strategyId,
        version: version
      });
      const retiredAudienceSegments = new RetiredSegments({
        version: version
      });
      const retiredTargetSegments = new StrategyRetiredTargetSegments({
        version: version
      });
      const hyperLocalTargeting = new HyperLocalTargeting({
        strategy: this.model
      });
      var ipTargetingAttachments = new NemoIPAttachments();
      var editLinkPrefix = this.editLinkPrefix;
      var canEdit = this.canEdit;
      const canEditSupplyAndTargeting = canEdit && this.model.get("supply_type") !== "RMX_API";
      const isVideoStrategy = this.model.get("media_type") === "VIDEO";
      var isProgrammaticGuaranteed = Utils.isProgrammaticGuaranteed(this.model.get("campaign"));
      retiredAudienceSegments.strategyId = strategyId;
      siteList.id = strategyId;
      siteList.fetchOptions = $.extend({
        full: "*",
        sort_by: "id",
        q: "assigned==1"
      }, siteList.fetchOptions);
      dayParts.id = strategyId;
      const targetDimensionsOpts = {
        model: stratTargetDims,
        editLinkPrefix: editLinkPrefix,
        canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true),
        audienceSegments: groupedAudienceSegments,
        retiredSegmentsCollection: retiredAudienceSegments,
        strategy: this.model,
        iASId: this.iASId,
        hyperLocalTargeting: hyperLocalTargeting,
        ipTargetingAttachments: ipTargetingAttachments
      };
      const layoutObj = {
        ".w-ReadonlySupply": [{
          module: "strategy/supply/open",
          viewType: "readOnly",
          options: {
            model: this.model,
            targetDimensions: stratTargetDims,
            editLinkPrefix: editLinkPrefix,
            canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true)
          }
        }],
        ".w-ReadonlyCreatives": [{
          module: "strategy/creatives",
          viewType: "readOnly",
          options: {
            strategy: this.model,
            editLinkPrefix: editLinkPrefix,
            canEdit: canEdit && (isVideoStrategy ? this.hasVideoPermission : true)
          }
        }]
      };
      if (!isProgrammaticGuaranteed) {
        const nonPGLayoutObj = {
          ".w-ReadonlyAudience": [{
            module: "targeting/audience",
            viewType: "readOnly",
            options: {
              model: groupedAudienceSegments,
              retiredSegmentsCollection: retiredAudienceSegments,
              strategy: this.model,
              editLinkPrefix: editLinkPrefix,
              canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true),
              iASId: this.iASId
            }
          }],
          ".w-ReadonlyContextual": [{
            module: "targeting/contextual",
            viewType: "readOnly",
            options: {
              model: targetSegments,
              retiredSegmentsCollection: retiredTargetSegments,
              strategy: this.model,
              editLinkPrefix: editLinkPrefix,
              canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true),
              iASId: this.iASId,
              targetDimentions: stratTargetDims
            }
          }],
          ".w-ReadonlyPixel": [{
            module: "targeting/pixel",
            viewType: "readOnly",
            options: {
              model: this.model,
              editLinkPrefix: editLinkPrefix,
              canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true)
            }
          }],
          ".w-ReadonlySitelists": [{
            module: "siteList",
            viewType: "readOnly",
            options: {
              collection: siteList,
              model: this.model,
              editLinkPrefix: editLinkPrefix,
              canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true)
            }
          }],
          ".w-ReadonlyLocation": [{
            module: "targeting/location",
            viewType: "readOnly",
            options: targetDimensionsOpts
          }],
          ".w-ReadonlyTechnology": [{
            module: "targeting/digital",
            viewType: "readOnly",
            options: targetDimensionsOpts
          }],
          ".w-ReadonlyDaypart": [{
            module: "targeting/dayPart",
            viewType: "readOnly",
            options: {
              collection: dayParts,
              editLinkPrefix: editLinkPrefix,
              canEdit: canEditSupplyAndTargeting && (isVideoStrategy ? this.hasVideoPermission : true)
            }
          }]
        };
        if (isVideoStrategy) {
          nonPGLayoutObj[".w-ReadonlyVideoTargeting"] = [{
            module: "targeting/video",
            viewType: "readOnly",
            options: {
              model: stratTargetDims,
              editLinkPrefix: editLinkPrefix,
              canEdit: canEditSupplyAndTargeting && this.hasVideoPermission
            }
          }];
        }
        if (this.isFacebookStrategy) {
          delete nonPGLayoutObj[".w-ReadonlyContextual"];
          delete nonPGLayoutObj[".w-ReadonlySitelists"];
          delete nonPGLayoutObj[".w-ReadonlyDaypart"];
          delete nonPGLayoutObj[".w-ReadonlyVideoTargeting"];
        }
        Object.assign(layoutObj, nonPGLayoutObj);
      }
      this.layout = new T1Layout({
        el: () => this.$(".sections-container"),
        template: '{{#readOnlyViews}}<div class="{{.}}"></div><div class="spacer" ></div>{{/readOnlyViews}}',
        layout: layoutObj,
        serialize: function () {
          return {
            readOnlyViews: readOnlyViews
          };
        }
      });
      this.layout.load();
    },
    getTimezoneDescription: function () {
      const timezones = this.timezones;
      var timezone = timezones && timezones.getByZoneName ? timezones.getByZoneName(this.zoneName) : null;
      var description = timezone ? timezone.get("description") || "" : "";
      return description.length ? ` ${description}` : "";
    },
    getDate: function (campaignModel, mode, campaignGet) {
      const model = this.model;
      var date = model.get(`use_campaign_${mode}`) === "1" ? campaignModel.get(campaignGet) : model.get(`${mode}_date`);
      return Utils.parseDate(date);
    },
    getStartEndDates: function () {
      var campaign = this.model.get("campaign");
      return Utils.getDisplayTextForStartAndEndDates({
        startDate: this.getDate(campaign, "start", "initial_start_date"),
        endDate: this.getDate(campaign, "end", "end_date"),
        dateFormat: "MM/dd/yyyy",
        timeFormat: "h:mm tt"
      }) + this.getTimezoneDescription();
    },
    getStatus: function (model) {
      return model.get("status") === "1" ? "Active" : "Inactive";
    },
    getPropertyFromHash: function (hash, model, key) {
      return hash[model.get(key)] || nonBreakingSpace;
    },
    getStrategyType: function () {
      return this.getPropertyFromHash(strategyTypeNameRef, this.model, "type");
    },
    getSupplyType: function () {
      const isPG = Utils.isProgrammaticGuaranteed(this.model.get("campaign"));
      return isPG ? "Programmatic Guaranteed" : this.getPropertyFromHash(supplyTypeRef, this.model, "supply_type");
    },
    getOptimizeValue: function (isOptimizationOn) {
      if (this.doSupplyCheck()) {
        return "--";
      }
      return isOptimizationOn ? "On" : "Off";
    },
    getGoalValueForDesc: function () {
      return this.model.get("bid_price_is_media_only") === "1" ? "for Media Only" : "for Total Spend";
    },
    getGoalValueLine2: function (isOptimizationOn, goalType, maxBidPrice, minBidPrice) {
      var returnValue = null;
      if (isOptimizationOn === true && goalType === "CPM REACH") {
        let value = parseFloat(this.model.get("bid_aggressiveness"));
        value = isNaN(value) === false ? value.toFixed(0) : "";
        const part2 = value && isNaN(value) === false ? ` for ${value}% of Impressions` : "";
        returnValue = `Bid between ${minBidPrice} and ${maxBidPrice} ${this.getGoalValueForDesc()}${part2}`;
      }
      return returnValue;
    },
    getGoalValueText: function (goalValue, goalTypeRefKey) {
      var goalText, goalType;
      let goalTypeShortForm = "";
      if (goalTypeRefKey === "roi") {
        goalTypeShortForm = `${this.model.get("roi_target")}:1 ${goalTypeRefKey.toUpperCase()} `;
      } else if (goalTypeRefKey === "vcr" || goalTypeRefKey === "ctr" || goalTypeRefKey === "viewability_rate") {
        goalText = goalTypeRefKey === "viewability_rate" ? goalTypeRef[goalTypeRefKey] : goalTypeRefKey.toUpperCase();
        goalTypeShortForm = `${goalValue}% ${goalText}`;
      } else if (goalTypeRefKey === "vcpm") {
        goalTypeShortForm = `${goalValue} vCPM ${this.getGoalValueForDesc()}`;
      } else {
        goalType = goalTypeRef[goalTypeRefKey];
        goalTypeShortForm = goalType ? `${goalType.split(" ")[0]} ` : "";
        goalTypeShortForm = `${goalValue} ${goalTypeShortForm}${this.getGoalValueForDesc()}`;
      }
      return goalTypeShortForm;
    },
    getROITargetValueLine2: function (isOptimizationOn, currencyCode, goalType) {
      const model = this.model;
      var returnValue = null;
      if (isOptimizationOn === true && goalType === "ROI") {
        const value = Utils.calculateEquivalantTarget(model.get("campaign").get("goal_value"), model.get("roi_target"));
        returnValue = `( equivalent target of ${formatCurrency(value, null, currencyCode)} CPA ${this.getGoalValueForDesc()} )`;
      }
      return returnValue;
    },
    doSupplyCheck: function () {
      return this.model.get("supply_type") === "RMX_API";
    },
    getBudget: function (currencyCode) {
      return this.doSupplyCheck() || !this.model.get("budget") ? "--" : formatCurrency(this.model.get("budget"), "", currencyCode);
    },
    getBudgetPacing: function (currencyCode) {
      let monetaryPacingText, pacingAmount, pacingInterval, pacingType, returnValue;
      const model = this.model;
      if (useAllocationPacing) {
        const pacingOptimizationType = model.get("pacing_optimization_type");
        if (pacingOptimizationType) {
          if (pacingOptimizationType === "monetary") {
            monetaryPacingText = "Target Allocation: ";
            pacingAmount = model.get("pacing_optimization_amount");
            returnValue = `${monetaryPacingText + pacingAmount}%`;
          } else {
            returnValue = "No Cap";
          }
        } else {
          pacingType = pacingTypeRef[model.get("pacing_type")];
          monetaryPacingText = "Custom Amount: ";
          pacingType = pacingType ? `${pacingType} ` : "";
          pacingAmount = formatCurrency(model.get("pacing_amount"), "", currencyCode);
          pacingInterval = intervalRef[model.get("pacing_interval")];
          pacingInterval = pacingInterval ? ` per ${pacingInterval}` : "";
          returnValue = monetaryPacingText + pacingType + pacingAmount + pacingInterval;
        }
      } else {
        if (this.doSupplyCheck()) {
          returnValue = "--";
        } else {
          pacingType = pacingTypeRef[model.get("pacing_type")];
          pacingType = pacingType ? `${pacingType} ` : "";
          pacingInterval = intervalRef[model.get("pacing_interval")];
          pacingInterval = pacingInterval ? ` per ${pacingInterval}` : "";
          returnValue = pacingType + formatCurrency(model.get("pacing_amount"), "", currencyCode) + pacingInterval;
        }
      }
      return returnValue;
    },
    getFrequencyCap: function () {
      if (this.doSupplyCheck()) {
        return "--";
      }
      const frequencyOptimization = this.model.get("frequency_optimization");
      return Utils.getDisplayTextForFrequencyCap(this.model.get("frequency_amount"), this.model.get("frequency_type"), this.model.get("frequency_interval"), frequencyOptimization === "1" ? "T1 Optimized" : "No Cap", frequencyOptimization);
    },
    getImpressionCap: function () {
      var returnValue = null;
      var impressionCap = this.model.get("impression_cap");
      if (impressionCap && isNaN(parseInt(impressionCap, 10)) === false) {
        returnValue = `${formatsRolledUpNumbersNoAbbreviation(impressionCap)} per Day`;
      }
      return returnValue;
    },
    getStrategyValue: function (goalValue, goalType) {
      return this.doSupplyCheck() ? "--" : this.formatGoalPriceValue(goalValue, true, goalType);
    },
    getImpressionPacing: function () {
      let impressionAmount, impressionPacingText, pacingInterval, pacingType, returnValue;
      const model = this.model;
      if (useAllocationPacing) {
        const pacingOptimizationType = model.get("pacing_optimization_type");
        if (pacingOptimizationType) {
          if (pacingOptimizationType === "impression") {
            impressionPacingText = "Target Allocation: ";
            impressionAmount = model.get("pacing_optimization_amount");
            returnValue = `${impressionPacingText + impressionAmount}%`;
          } else {
            returnValue = "No Cap";
          }
        } else {
          if (model.get("impression_pacing_type") === "no-limit") {
            returnValue = "No Cap";
          } else {
            impressionPacingText = "Custom Amount: ";
            pacingType = pacingTypeRef[model.get("impression_pacing_type")];
            pacingType = pacingType ? `${pacingType} ` : "";
            impressionAmount = formatsRolledUpNumbersNoAbbreviation(model.get("impression_pacing_amount"));
            pacingInterval = intervalRef[model.get("impression_pacing_interval")];
            pacingInterval = pacingInterval ? ` per ${pacingInterval}` : "";
            returnValue = impressionPacingText + pacingType + impressionAmount + pacingInterval;
          }
        }
      } else {
        if (model.get("impression_pacing_type") === "no-limit") {
          returnValue = "No Cap";
        } else {
          pacingType = pacingTypeRef[model.get("impression_pacing_type")];
          pacingType = pacingType ? `${pacingType} ` : "";
          pacingInterval = intervalRef[model.get("impression_pacing_interval")];
          pacingInterval = pacingInterval ? ` per ${pacingInterval}` : "";
          returnValue = pacingType + formatsRolledUpNumbersNoAbbreviation(model.get("impression_pacing_amount")) + pacingInterval;
        }
      }
      return returnValue;
    },
    getGoalValue: function (goalTypeValue, currencyCode) {
      var goalValue = this.model.get("goal_value");
      if (goalTypeValue === "vcr" || goalTypeValue === "ctr" || goalTypeValue === "viewability_rate") {
        const twoDigits = 2;
        goalValue = parseFloat(goalValue);
        goalValue = isNaN(goalValue) === false ? goalValue.toFixed(twoDigits) : "0";
      } else {
        goalValue = formatCurrency(goalValue, "", currencyCode);
      }
      return goalValue;
    },
    formatGoalPriceValue: function (formattedPriceVal, doNotShowGoalType, goalType) {
      var goalTypeShortForm = "";
      if (doNotShowGoalType === false) {
        goalTypeShortForm = goalType ? `${goalType.split(" ")[0]} ` : "";
      }
      return `${formattedPriceVal} ${goalTypeShortForm}${this.getGoalValueForDesc()}`;
    },
    adjustDetailsForFB: function (columnAry) {
      var isSpend = this.model.get("goal_type") === "spend";
      return columnAry.filter(function (item) {
        if (item.fieldName === "Enable Optimization" || item.fieldName === "Frequency Cap" || item.fieldName === "Monetary Budget" || item.fieldName === "Impression Pacing" || item.fieldName === "Max Bid CPM" || item.fieldName === "Max Watermark Bid CPM" || item.fieldName === "&nbsp;") {
          return false;
        } else if (isSpend && item.fieldName === "Strategy Goal Value") {
          return false;
        } else if (!isSpend && item.fieldName === "Bid Price") {
          return false;
        }
        return true;
      });
    },
    populateSummaryLeft: function (isOptimizationOn) {
      var summaryLeft = [];
      var description = this.model.get("description");
      summaryLeft.push({
        fieldName: "Status",
        fieldValue: this.getStatus(this.model)
      });
      summaryLeft.push({
        fieldName: "Strategy Name",
        fieldValue: this.model.get("name") || nonBreakingSpace
      });
      if (this.model.get("media_type") === "VIDEO") {
        summaryLeft.push({
          fieldName: "Channel",
          fieldValue: "Video/Audio"
        });
      }
      summaryLeft.push({
        fieldName: "Strategy Type",
        fieldValue: this.getStrategyType()
      });
      summaryLeft.push({
        fieldName: "Supply Type",
        fieldValue: this.getSupplyType()
      });
      summaryLeft.push({
        fieldName: "Start / End Dates",
        fieldValue: this.getStartEndDates()
      });
      if (description) {
        summaryLeft.push({
          fieldName: "Description",
          fieldValue: description
        });
      }
      summaryLeft.push({
        fieldName: "Enable Optimization",
        fieldValue: this.getOptimizeValue(isOptimizationOn)
      });
      summaryLeft = this.isFacebookStrategy ? this.adjustDetailsForFB(summaryLeft) : summaryLeft;
      if (this.model.get("campaign").get("bid_min_devices") === "2" || this.model.get("bid_min_devices") === "2") {
        summaryLeft.push({
          fieldName: "Bid on Cross Device",
          fieldValue: "Deterministic + Bid on users with 2 or more devices"
        });
      }
      return summaryLeft;
    },
    populateSummaryRight: function (isOptimizationOn) {
      let result;
      var summaryRight = [];
      var currencyCode = this.model.getCurrencyCode();
      var goalTypeValue = this.model.get("goal_type");
      var goalType = goalTypeRef[goalTypeValue];
      var goalValue = this.getGoalValue(goalTypeValue, currencyCode);
      var maxBidPrice = formatCurrency(this.model.get("max_bid"), "", currencyCode);
      var minBidPrice = formatCurrency(this.model.get("min_bid"), "", currencyCode);
      var maxBidWm = formatCurrency(this.model.get("max_bid_wm"), "", currencyCode);
      var displayVal = "";
      const isProgrammaticGuaranteed = Utils.isProgrammaticGuaranteed(this.model.get("campaign"));
      summaryRight.push({
        fieldName: "Goal Type",
        fieldValue: goalType || nonBreakingSpace
      });
      if (isOptimizationOn === true) {
        result = this.getGoalValueText(goalValue, goalTypeValue);
        summaryRight.push({
          fieldName: "Goal Value",
          fieldValue: result
        });
        result = this.getGoalValueLine2(isOptimizationOn, goalType, maxBidPrice, minBidPrice);
        if (result) {
          summaryRight.push({
            fieldName: nonBreakingSpace,
            fieldValue: result
          });
        }
        result = this.getROITargetValueLine2(isOptimizationOn, currencyCode, goalType);
        if (result) {
          summaryRight.push({
            fieldName: nonBreakingSpace,
            fieldValue: result
          });
        }
      } else {
        summaryRight.push({
          fieldName: "Bid Price",
          fieldValue: isProgrammaticGuaranteed ? "N/A" : this.formatGoalPriceValue(maxBidPrice, false, goalType)
        });
      }
      summaryRight.push({
        fieldName: "Frequency Cap",
        fieldValue: this.getFrequencyCap()
      });
      summaryRight.push({
        fieldName: "Monetary Budget",
        fieldValue: this.getBudget(currencyCode)
      });
      summaryRight.push({
        fieldName: "Monetary Pacing",
        fieldValue: isProgrammaticGuaranteed ? "N/A" : this.getBudgetPacing(currencyCode)
      });
      summaryRight.push({
        fieldName: "Impression Pacing",
        fieldValue: this.getImpressionPacing()
      });
      if (isOptimizationOn === false) {
        summaryRight.push({
          fieldName: "Strategy Goal Value",
          fieldValue: this.getStrategyValue(goalValue, goalType)
        });
      }
      if (goalType === "CPA" || goalType === "CPC" || goalType === "Viewability Rate" || goalType === "Viewable CPM" || goalType === "Video Completion Rate" || goalType === "CTR") {
        summaryRight.push({
          fieldName: "Max Bid CPM",
          fieldValue: this.formatGoalPriceValue(maxBidPrice, true, goalType)
        });
        if (!this.isFacebookStrategy) {
          summaryRight.push({
            fieldName: "Min Bid CPM",
            fieldValue: this.formatGoalPriceValue(minBidPrice, true, goalType)
          });
        }
      }
      if (goalType !== "CPM REACH" && goalType !== "CPM SPEND") {
        const maxLength = 2;
        displayVal = "";
        if (maxBidWm.length > maxLength) {
          displayVal = `${maxBidWm} ${this.getGoalValueForDesc()}`;
        } else {
          displayVal = "--";
        }
        summaryRight.push({
          fieldName: "Max Watermark Bid CPM",
          fieldValue: displayVal
        });
      }
      if (T1Permissions.check("feature", "ads_txt_user_controls_campaign_strategy")) {
        const adsTxtVerified = this.model.get("ads_txt_verified");
        const effectiveAdsTxtVerified = this.model.get("effective_ads_txt_verified");
        const adsTxtData = {
          AUTHORIZED_ONLY: "Authorized Supply Only",
          AUTHORIZED_DIRECT_ONLY: "Authorized Direct Supply Only",
          ANY: "Authorized + Unknown",
          NONE: adsTxtVerified ? "--" : "None"
        };
        const adsTxtVerifiedValue = adsTxtVerified ? adsTxtData[adsTxtVerified] : `Inherited from Campaign (${adsTxtData[effectiveAdsTxtVerified]})`;
        summaryRight.push({
          fieldName: "Safe Supply Controls",
          fieldValue: adsTxtVerifiedValue
        });
      }
      summaryRight = this.isFacebookStrategy ? this.adjustDetailsForFB(summaryRight) : summaryRight;
      return summaryRight;
    },
    serialize: function () {
      var isOptimizationOn = this.model.get("use_optimization") === "1";
      var resultJSON = {
        summaryLeft: this.populateSummaryLeft(isOptimizationOn),
        summaryRight: this.populateSummaryRight(isOptimizationOn)
      };
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/details`;
      }
      return resultJSON;
    }
  });
});
