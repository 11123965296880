define('modules/audiences/onboarding/create/models/otherEventBulkModel',["jQuery", "T1", "models/onboarding", "modules/audiences/onboarding/create/utils/bulkCreateUtils"], function ($, T1, OnboardingModel, BulkUtils) {
  "use strict";

  T1.Models.BulkCreateOtherEventPixels = OnboardingModel.extend({
    delivery_format: "CSV",
    formatOptions: [{
      format: "CSV",
      checked: true
    }],
    save: function (opts) {
      BulkUtils.save.call(this, opts);
    },
    dataToSaveObj: function () {
      const saveObj = {
        events: this.pixels.map(pixel => {
          const obj = {
            advertiser_id: parseInt(this.advertiser_id, 10),
            pixel_name: pixel.name,
            tag_type: this.method
          };
          if (this.revenuePairEnabled) {
            if (this.currencyType === "Fixed") {
              obj.currency_fixed = this.currency;
            }
            if (this.currencyType === "Dynamic") {
              obj.currency = this.currency;
            }
            obj.revenue = this.revenue;
          }
          return obj;
        })
      };
      BulkUtils.assignDelivery.call(this, saveObj);
      return saveObj;
    }
  });
  return T1.Models.BulkCreateOtherEventPixels;
});
