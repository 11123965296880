define('collections/dataproviders',["T1", "T1Collection", "models/dataprovider", "T1Sortable"], function (T1, T1Collection, DataProvider, Sortable) {
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name"
      }
    },
    default: {
      sort_by: "name"
    }
  };
  T1.Models.DataProviders = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: DataProvider,
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    filterConfiguration: {
      filters: [{
        entity: "agency",
        fetchOnChange: false
      }],
      enableEvents: false
    },
    urlString: "pixel_providers"
  });
  T1.Models.DataProviders = Sortable.extendCollection(T1.Models.DataProviders, sortConfiguration);
  return T1.Models.DataProviders;
});
