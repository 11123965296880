define('modules/creatives/concept/main',["jQuery", "T1", "T1Module", "collections/creativesAssign", "collections/advertisers", "collections/organizations", "models/userPreferences"], function ($, T1, T1Module, Creatives, Advertisers, Organizations) {
  var instance;
  var creatives = new Creatives(null, {
    pageLimit: 50
  });
  var advertisers = new Advertisers(null, {
    isPaginated: false
  });
  if (instance === undefined) {
    instance = new T1Module({
      name: "creatives/concept",
      viewCfgs: {
        createEdit: {},
        creative_list: {
          collection: creatives
        },
        creative_grid: {},
        list: {
          advertisers: advertisers
        },
        grid: {},
        bins: {
          sharedCollection: null
        },
        assignModal: {}
      },
      defaultView: "list",
      initialize: function () {
        var orgPromise = Organizations.getOrganizations().getSelected();
        orgPromise.then(function (orgId) {
          advertisers.urlFilter.set({
            entity: "organization",
            id: orgId
          });
        });
      }
    });
  }
  return instance;
});
