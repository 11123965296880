define('modules/creatives/bulkedit/views/dialog',["jQuery", "Underscore", "T1", "T1Permissions", "T1View", "text!../templates/dialog.html", "T1Layout", "T1Alert", "models/advertiser", "collections/vendors"], function ($, _, T1, T1Permissions, T1View, template, T1Layout, T1Alert, Advertiser, Vendors) {
  "use strict";

  var BulkEditDialog = T1View.extend({
    eventhubEvents: {
      "bulkedit:cancel": "handleCancel",
      "bulkedit:edit": "handleEdit",
      "bulkedit:assignment": "handleAssignment",
      "bulkedit:review": "handleReview",
      "bulkedit:saved": "handleSaved",
      "bulkedit:reviewSaved": "handleReviewSaved"
    },
    initialize: function (args) {
      var self = this;
      var vendorIDs, selectionError, manualVendors;
      var success = function () {
        if (self.collection.length) {
          self.showDialog();
        }
      };
      self.collection = args.collection;
      self.bulkAdvertiser = args.bulkAdvertiser;
      self.consistencyEnabled = args.consistencyEnabled;
      self.bulkClassification = args.bulkClassification;
      self.entityType = args.entityType || "creative";
      self.canLoad = false;
      self.existingVendors = new Vendors();
      self.collectedVIDs = [];
      self.collection.each(function (model) {
        if (self.entityType === "video") {
          vendorIDs = model.get("details").get("vendors");
          if (vendorIDs && vendorIDs.length !== 0) {
            self.collectedVIDs.push(vendorIDs);
          }
        } else {
          manualVendors = model.get("manualVendors");
          if (manualVendors && manualVendors.models) {
            self.existingVendors.add(manualVendors.models);
          }
        }
      });
      selectionError = self.checkValidSelection(self.collection, self.entityType);
      if (selectionError) {
        self.displayAlert(selectionError);
        return;
      }
      function showView() {
        if (self.entityType === "video" && self.collectedVIDs.length) {
          self.existingVendors.search.set({
            idList: self.collectedVIDs,
            fetchRequired: true,
            success: success
          });
        } else {
          if (self.collection.length) {
            self.showDialog();
          }
        }
      }
      self.existingClassifications = [];
      self.fetchClassifications(args && args.isLocal).then(function (classificationData) {
        if (self.consistencyEnabled) {
          self.existingClassifications = classificationData && classificationData.length ? classificationData : [];
        } else {
          self.existingClassifications = classificationData.error ? [] : classificationData;
        }
        showView();
      });
    },
    initLayout: function () {
      var self = this;
      var layoutObj = {
        ".edit": [{
          module: "creatives/bulkedit",
          viewType: "edit",
          options: {
            collection: self.collection,
            advertiser: self.advertiser,
            entityType: self.entityType,
            existingVendors: self.existingVendors,
            existingClassifications: self.existingClassifications,
            bulkAdvertiser: self.bulkAdvertiser,
            consistencyEnabled: self.consistencyEnabled
          }
        }],
        ".review": [{
          module: "creatives/bulkedit",
          viewType: "review",
          options: {
            collection: self.collection,
            advertiser: self.advertiser,
            entityType: self.entityType,
            bulkAdvertiser: self.bulkAdvertiser,
            consistencyEnabled: self.consistencyEnabled
          }
        }]
      };
      layoutObj[".assignment"] = [{
        module: "creatives/bulkedit",
        viewType: "assignment",
        options: {
          collection: self.collection,
          advertiser: self.advertiser,
          entityType: self.entityType,
          bulkAdvertiser: self.bulkAdvertiser
        }
      }];
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        template: template,
        layout: layoutObj
      });
    },
    fetchAdvertiser: function (model) {
      var deferred = $.Deferred();
      var advertiser;
      advertiser = new Advertiser({
        id: model.get("advertiser_id")
      });
      advertiser.fetch({
        params: {
          with: ["agency"]
        },
        success: function (data) {
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchClassifications: function (isLocal) {
      var self = this;
      var deferred = $.Deferred();
      var classificationCollection, classificationData, innerData, cridList, isBatch;
      function fetchComplete(def, classificationData) {
        if (isBatch || isLocal) {
          classificationData = classificationData;
        } else {
          classificationData = [classificationCollection.data];
        }
        return def.resolve(classificationData);
      }
      function fetchFailed(def, ajax) {
        var output;
        var error = ajax && ajax.error;
        if (error === 404) {
          output = def.resolve([]);
        } else {
          output = def.reject();
        }
        return output;
      }
      if (isLocal) {
        classificationData = _.chain(this.collection.models).map(function (model) {
          innerData = model.get("current_classifications");
          if (innerData) {
            innerData = innerData.map(function (item) {
              item.atomic_creative_id = model.id;
              return item;
            });
          }
          return innerData;
        }).flatten().compact().value();
        fetchComplete(deferred, [classificationData]);
      } else {
        cridList = this.collection.map(function (model) {
          return {
            id: model.id
          };
        });
        isBatch = cridList && cridList.length > 1;
        classificationCollection = new T1ComponentLib.CreativesClassificationCollection();
        classificationCollection.auto = false;
        classificationCollection.getClassifications(cridList).then(fetchComplete.bind(self, deferred), fetchFailed.bind(self, deferred));
      }
      return deferred.promise();
    },
    checkValidSelection: function (collection, entityType) {
      var key, val, matching, error, errorMessages;
      var AtomicEerrorMessages = {
        advertiser_id: {
          title: "Bulk edits can only be applied to creatives within a single advertiser.",
          bodyMsg: "Please ensure that all selected creatives are from the same advertiser."
        },
        t1as: {
          title: "Bulk edits cannot be applied to T1 Ad Server creative and 3PAS creative at the same time.",
          bodyMsg: "Please ensure that all selected creatives are of one type or the other."
        },
        isMraid: {
          title: "Bulk edits to T1 MRAID creatives cannot include other types of creatives",
          bodyMsg: "Please ensure that all selected creatives are of one type or the other."
        }
      };
      var VideoEerrorMessages = {
        advertiser_id: {
          title: "Bulk edits can only be applied to videos within a single advertiser.",
          bodyMsg: "Please ensure that all selected videos are from the same advertiser."
        }
      };
      function getKey(model) {
        return model.get(key) === val;
      }
      errorMessages = entityType === "video" ? VideoEerrorMessages : AtomicEerrorMessages;
      if (collection.length > 0) {
        for (key in errorMessages) {
          if (errorMessages.hasOwnProperty(key)) {
            val = collection.at(0).get(key);
            matching = _.every(collection.models, getKey);
            if (!matching) {
              error = errorMessages[key];
              break;
            }
          }
        }
      }
      return error;
    },
    displayAlert: function (alertData) {
      var alertMsg = new T1Alert();
      var defaultData = {
        buttons: [{
          text: "Close",
          class: "save",
          click: function () {
            var $dialog = $(this);
            $dialog.dialog("close");
          }
        }],
        icon: "error-icon"
      };
      $.extend(alertData, defaultData);
      alertMsg.initialize(alertData);
      alertMsg.show();
    },
    load: function () {
      var self = this;
      if (!self.canLoad) {
        return;
      }
      self.render().then(function () {
        self.layout.load().then(function () {
          $(".w-BulkEditDialog").show();
        });
      });
    },
    handleCancel: function () {
      this.closeDialog();
    },
    handleEdit: function () {
      this.showEdit();
    },
    handleAssignment: function () {
      this.showAssignment();
    },
    handleReview: function () {
      this.showReview();
    },
    handleSaved: function () {
      this.closeDialog();
    },
    handleReviewSaved: function () {
      this.closeDialog();
    },
    showDialog: function () {
      var self = this;
      var $el = $(this.el);
      $el.dialog({
        dialogClass: "w-BulkEditDialog",
        closeText: "",
        autoOpen: true,
        modal: true,
        resizable: false,
        draggable: false,
        position: ["center", 15],
        close: function () {
          $el.dialog("destroy").remove();
          self.unload();
        }
      });
      $(".w-BulkEditDialog").hide();
      self.fetchAdvertiser(self.collection.at(0)).then(function (advertiser) {
        self.advertiser = advertiser;
        self.canLoad = true;
        self.initLayout();
        self.load();
      });
    },
    closeDialog: function () {
      $(this.el).dialog("close");
    },
    showReview: function () {
      this.el.find(".edit").hide();
      this.el.find(".review").show();
      this.el.find(".assignment").hide();
    },
    showAssignment: function () {
      this.el.find(".edit").hide();
      this.el.find(".review").hide();
      this.el.find(".assignment").show();
    },
    showEdit: function () {
      this.el.find(".review").hide();
      this.el.find(".edit").show();
      this.el.find(".assignment").hide();
    }
  });
  return BulkEditDialog;
});
