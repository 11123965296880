define('modules/audiences/onboarding/create/views/otherEventCreateEdit',["Underscore", "jQuery", "T1", "T1Permissions", "Hogan", "modules/audiences/onboarding/create/views/onboardingForm", "modules/audiences/segments/segmentUtils", "models/sessionUser", "collections/agencies", "text!modules/audiences/onboarding/create/templates/otherEvent/advertisers.html", "text!modules/audiences/onboarding/create/templates/otherEvent/pixelsList.html", "modules/audiences/onboarding/create/models/otherEventBulkModel", "modules/audiences/onboarding/create/utils/bulkCreateUtils"], function (_, $, T1, T1Permissions, Hogan, OnboardingCreateEdit, Util, User, Agencies, AdvertisersTemplate, PixelsListTemplate, OtherEventPixels, BulkUtils) {
  "use strict";

  const formatForDDL = entity => ({
    name: entity.get("name"),
    value: entity.id
  });
  const togglePriceSection = opts => {
    const {
      enable: enable,
      disable: disable,
      row: row
    } = opts;
    row.find("." + enable + "-section").find(".pricing-input").removeClass("disabled");
    row.find("." + disable + "-section").find(".pricing-input").addClass("disabled");
    row.find("." + disable + "-section").find("strand-input")[0].clearInput();
    row.find(".pricing-type." + disable).removeAttr("checked");
  };
  var otherEventCreateEditView = OnboardingCreateEdit.extend({
    events: Object.assign({}, OnboardingCreateEdit.prototype.events, {
      "keyup #event-pixels-repeater": "pixelsListChanged"
    }),
    renderConfigs: {
      viewInBrowserEnabled: false
    },
    initialize: function (options) {
      this.model = new OtherEventPixels();
      this.mode = "create";
      this.AdvertisersTemplate = Hogan.compile(AdvertisersTemplate);
      this.EventsTemplate = Hogan.compile(PixelsListTemplate);
      this.CustomAttributeTemplate = null;
      this.StandardAttributesTemplate = null;
    },
    imagePixelsEnabled: function () {
      const user = User.getUser().toJSON();
      const isGlobalAdmin = user.type === "INTERNAL" && user.role === "ADMIN" && user.scope === "GLOBAL";
      const isApprovedAdmin = (user.role === "ADMIN" || user.role === "MANAGER") && T1Permissions.check("feature", "pixels_enable_nonjs");
      return isGlobalAdmin || isApprovedAdmin;
    },
    renderCallback: function () {
      this.pixelsRepeater = this.el.find("#event-pixels-repeater")[0];
      this.pixelsRepeater.validation = this.eventPixelsValidation;
      this.loadAdvertisers();
      this.loadImplementations();
      this.populateCurrencyOptions();
      this.revenueAttributionChanged();
    },
    populateCurrencyOptions: function () {
      const el = this.el[0];
      const ddlOptions = [{
        name: "V1",
        value: "V1"
      }, {
        name: "V2",
        value: "V2"
      }, {
        name: "S1",
        value: "S1"
      }, {
        name: "S2",
        value: "S2"
      }];
      const currencyFixed = el.querySelector("#currencyFixed");
      const currencyDynamic = el.querySelector("#currencyDynamic");
      const revenue = el.querySelector("#revenue");
      this.currencyValues = T1.Utils.getCurrencyList({
        selectedId: "USD"
      }).map(currency => Object.assign(currency, {
        name: currency.text
      }));
      revenue.data = ddlOptions;
      currencyDynamic.data = ddlOptions;
      currencyFixed.data = this.currencyValues;
      currencyFixed.value = "USD";
      revenue.value = "V1";
    },
    loadImplementations: function () {
      const implementations = [{
        name: "JavaScript",
        value: "js"
      }];
      if (this.imagePixelsEnabled()) {
        implementations.push({
          name: "Image",
          value: "image"
        });
      }
      this.el[0].querySelector("#implementationMethod").set("data", implementations);
    },
    implementationChanged: function (e) {
      var implementation = this.el[0].querySelector("#implementationMethod").value;
      this.model.method = implementation;
      this.$(".events-attributes-section").show();
      this.saveCheck();
    },
    eventPixelsValidation: function (data, added, modified) {
      return data.map(model => ({
        cId: model.cId,
        name: model.name ? null : "Name required"
      })).filter(StrandLib.DataUtils.isDef);
    },
    pixelsListChanged: function () {
      BulkUtils.pixelsListChanged.call(this);
    },
    repeaterRowAdded: function () {
      this.disableSave();
    },
    saveCheck: function () {
      const advertiserSet = () => this.model.advertiser_id;
      const implementationSet = () => this.model.method;
      const pixelsRepeaterValid = () => this.pixelsRepeater.validate() && this.model.pixels.length;
      const validateDelivery = () => {
        const delivery = this.model.delivery_method && this.model.delivery_method.length > 0;
        if (delivery) {
          return !this.model.delivery_method.includes("email") ? true : this.model.email_to;
        } else {
          return true;
        }
      };
      if (advertiserSet() && implementationSet() && pixelsRepeaterValid() && validateDelivery() && !this.revenueAttributionError) {
        this.enableSave();
      } else {
        this.disableSave();
      }
    },
    updateBreadCrumb: function () {
      var pathPieces = [{
        label: "New Other Events",
        location: "#audiences/onboarding/event/create"
      }];
      this.title = "New Other Events";
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
    }
  });
  otherEventCreateEditView.prototype.successCallback = BulkUtils.successCallback;
  otherEventCreateEditView.prototype.saveModel = BulkUtils.saveModel;
  return otherEventCreateEditView;
});
