define('T1FileUpload',["jQuery", "Underscore", "T1", "T1Comm", "Mustache", "text!templates/fileUpload.html"], function ($, _, T1, T1Comm, T, fileTemplate) {
  return function () {
    function initialize(opts) {
      var self = this;
      this.options = {};
      this.isIPList = false;
      $.extend(this.options, opts);
      this.$el = this.options.$el || $(".file-upload");
      this.$form = {};
      function addListeners($el, options) {
        var onChange = options.onChange;
        var onError = options.onError;
        var onClick = options.onClick;
        var onDropDownChange = options.onDropDownChange;
        var bulkeditorLocation = options.bulkeditorLocation;
        if ($el) {
          $el.off("change").off("click");
          $el.on("change", ".file-input-nemo", function (e) {
            var $target = $(e.currentTarget);
            var $field = $el.find(".file-name input, .file-name span");
            var fileName;
            fileName = $target.val().substring($target.val().lastIndexOf("\\") + 1);
            $field.val(fileName);
            $field.text(fileName);
            self.ipfileInfo = $target[0].files[0];
            if (onChange) {
              onChange(e);
            }
          });
          $el.on("change", ".file-input", function (e) {
            var fileName;
            var inputElem = this;
            var $target = $(e.currentTarget);
            var $field = $el.find(".file-name input, .file-name span");
            if (bulkeditorLocation) {
              fileName = "postalcodes.csv";
            } else {
              fileName = $target.val().substring($target.val().lastIndexOf("\\") + 1);
            }
            this.fileReader = window.FileReader && new FileReader();
            $field.val(fileName);
            $field.html(fileName);
            if (options.useReader) {
              inputElem.dataReady = false;
              this.fileReader.onload = function (e) {
                inputElem.dataReady = true;
                if (options.onDataReady) {
                  options.onDataReady(inputElem, e.target.result);
                }
              };
              if (options.readAsText) {
                this.fileReader.readAsText($target[0].files[0]);
              } else {
                this.fileReader.readAsDataURL($target[0].files[0]);
              }
            }
            if (options.gatherFileInfo) {
              self.fileInfo = $.extend({}, $target[0].files[0]);
              e.data = e.data || {};
              e.data.fileInfo = self.fileInfo;
            } else {
              if (self.isIPList) {
                self.ipfileInfo = $target[0].files[0];
              } else {
                self.fileInfo = $target[0].files[0];
              }
            }
            if (onChange) {
              onChange(e);
            }
          });
          $el.on("click", ".file-upload-btn", function (e) {
            var $file = $el.find("input[type=file]");
            $file.trigger("click");
            if (onClick) {
              onClick(e);
            }
          });
          $el.on("change", ".file-selector .select", function (e) {
            var $field;
            if (onDropDownChange) {
              $field = $el.find(".file-name input, .file-name span");
              $field.val("");
              $field.html("");
              self.isIPList = e.target.value === "IPTA";
              if (self.isIPList) {
                $el.find(".file-input-nemo").attr("disabled", false);
                $el.find(".file-input").attr("disabled", true);
              } else {
                $el.find(".file-input-nemo").attr("disabled", true);
                $el.find(".file-input").attr("disabled", false);
              }
              onDropDownChange(e);
            }
          });
        }
      }
      addListeners.apply(this, [this.options.$el || $(".file-upload"), this.options]);
      function render(args) {
        var classes = "browse-button";
        if (args.disabled) {
          classes = classes + " disabled";
        }
        var data = {
          buttonLabel: self.options.buttonText || "Browse",
          buttonClass: classes,
          action: "html",
          showInput: true,
          nemoInput: self.options.nemoInput || false,
          showDropDown: self.options.showDropDown || false,
          uploadTypeOptions: args.uploadType ? args.uploadType.options : {},
          getSelectOptions: function () {
            return function (attr) {
              var selected = data[attr] && this.value === data[attr] ? "selected='selected'" : "";
              return "<option " + selected + " value='" + this.value + "'>" + this.text + "</option>";
            };
          }
        };
        $.extend(data, args);
        self.$el[data.action](T.to_html(fileTemplate, data));
        self.$form = self.$el.find("form");
        setTimeout(function () {
          T1.Selectbox($(".file-selector .select", self.$el));
        }, 0);
      }
      function upload(args) {
        var options = {
          isForm: true,
          formEl: self.$form,
          sourceURL: self.options.sourceURL,
          onSuccess: self.options.onSuccess || $.noop,
          onError: self.options.onError || $.noop,
          uploadProgress: self.options.uploadProgress || $.noop,
          ipTargetingPostObj: self.options.ipTargetingPostObj || {}
        };
        var argsOnSuccess = args.onSuccess;
        var argsOnError = args.onError;
        var dontSaveIP = args.dontSaveIP;
        args.onSuccess = function () {
          argsOnSuccess && argsOnSuccess.apply(self, arguments) || self.options.onSuccess && self.options.onSuccess.apply(self, arguments);
          $(document).trigger("mouseup");
        };
        args.onError = function () {
          argsOnError && argsOnError.apply(self, arguments) || self.options.onError && self.options.onError.apply(self, arguments);
          $(document).trigger("mouseup");
        };
        if (self.isIPList && !dontSaveIP) {
          var fd = new FormData();
          fd.append("target_inet", self.ipfileInfo, "target_inet");
          options.sourceURL = "/api/v2.0/nemo/target";
          options.data = fd;
          options.isForm = false;
          options.forcedContentType = false;
          options.forcedProcessData = false;
          options.forcedDataType = "";
          options.processAjaxResponse = function (resp) {
            return {
              jsonData: resp.data,
              statusCode: resp.meta.status && resp.meta.status === "success" ? "ok" : "error" || "ok"
            };
          };
          if (!self.ipfileInfo || self.ipfileInfo && self.ipfileInfo.size === 0) {
            return;
          }
        }
        self.$el.find(".file-input-nemo").attr("disabled", true);
        self.$el.find(".file-input").attr("disabled", false);
        $.extend(options, args);
        T1Comm.post(options);
      }
      function getReaderContent() {
        return this.fileReader.result;
      }
      function destroy() {
        self.$el.off("click", ".file-upload-btn");
        self.$el.off("change", ".file-input");
        self.$el.off("change", ".file-selector .select");
        self.$el.off("change", ".file-input-nemo");
      }
      return {
        el: self.$el,
        render: render,
        upload: upload,
        getReaderContent: getReaderContent,
        destroy: destroy
      };
    }
    function create(options) {
      return new initialize(options);
    }
    return {
      create: create
    };
  }();
});
