define('modules/strategy/views/forecasterWarning',["T1View", "text!modules/strategy/templates/forecasterWarning.html"], function (T1View, template) {
  "use strict";

  const ForecasterWarning = T1View.extend({
    template: template,
    eventhubEvents: {
      "forecaster:warning:noncritical": "showNonCriticalWarning",
      "forecaster:warning:critical": "showCriticalWarning"
    },
    initialize: function () {
      this.message = null;
      this.type = null;
    },
    showNonCriticalWarning: function (message) {
      this.message = message;
      this.type = "warning";
      this.render();
    },
    showCriticalWarning: function (message) {
      this.message = message;
      this.type = "error";
      this.render();
    },
    serialize: function () {
      return {
        warningMessage: this.message,
        isWarning: this.type === "warning",
        isError: this.type === "error",
        iconClass: `forecaster-${this.type}-icon`
      };
    }
  });
  return ForecasterWarning;
});
