define('modules/admin/creatives/views/components-list',['require','jQuery','T1','T1Collection','T1View','text!../templates/components-list.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  const T1View = require("T1View");
  const template = require("text!../templates/components-list.html");
  return T1View.extend({
    template: template,
    events: {
      "changed .data_type strand-dropdown": "formChangeHandler",
      "changed .name": "formChangeHandler",
      "changed .size strand-input": "formChangeHandler",
      "changed .status strand-dropdown": "formChangeHandler"
    },
    dataEvents: {
      collection: {
        reset: function () {
          this.load();
        }
      }
    },
    initialize(args) {
      this.collection = args.collection || new T1Collection();
      this.canLoad = true;
    },
    load() {
      if (!this.canLoad) {
        return;
      }
      this.render().then(() => {
        const $componentList = this.$("#creative-component-list");
        setTimeout(() => {
          const componentData = this.collection.map(item => {
            const itemJSON = item.toJSON();
            itemJSON.cid = item.cid;
            itemJSON.status = itemJSON.is_enabled ? "on" : "off";
            return itemJSON;
          });
          const componentListView = $componentList[0];
          componentListView.data = componentData;
        }, 0);
      });
    },
    _getComponentModelFromTarget(target) {
      var $listItem = $(target).closest("creative-admin-component-list-item");
      return $listItem[0].model;
    },
    formChangeHandler(evt) {
      let componentModel = this._getComponentModelFromTarget(evt.currentTarget);
      componentModel = componentModel.toJSON ? componentModel.toJSON() : componentModel;
      if (componentModel) {
        this.isFormElementsChanged = true;
        T1.EventHub.publish("component:list:updated", componentModel);
      }
    }
  });
});
