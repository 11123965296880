define('modules/campaign/bulkedit/strategies/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "campaign/bulkedit/strategies",
    viewCfgs: {
      panel: {
        strategy: null
      },
      browse: {
        strategy: null
      },
      readOnly: {
        strategy: null
      },
      readOnlyBrowse: {
        strategy: null
      }
    },
    defaultView: "panel"
  });
});
