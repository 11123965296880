define('modules/audiences/segments/segmentUtils',["Underscore", "jQuery", "T1Spinner", "T1Model", "T1", "T1Permissions", "models/sessionUser"], function (_, $, T1Spinner, T1Model, T1, T1Permissions, User) {
  "use strict";

  return {
    jQueryExtend: function () {
      if ($.when.all === undefined) {
        $.when.all = function (deferreds) {
          var deferred = new $.Deferred();
          $.when.apply($, deferreds).then(function () {
            deferred.resolve(Array.prototype.slice.call(arguments));
          }, function () {
            deferred.reject(Array.prototype.slice.call(arguments));
          });
          return deferred;
        };
      }
    },
    validateEmailList: function (string) {
      const emails = string.split(",");
      return emails.every(email => T1.Utils.validateEmail(email.trim()));
    },
    expressionToJSONSdl: function (infix) {
      function Stack() {
        this.dataStore = [];
        this.top = 0;
      }
      Stack.prototype.push = function (element) {
        this.dataStore[this.top++] = element;
      };
      Stack.prototype.pop = function () {
        return this.dataStore[--this.top];
      };
      Stack.prototype.peek = function () {
        return this.dataStore[this.top - 1];
      };
      Stack.prototype.length = function () {
        return this.top;
      };
      function isOperator(token) {
        if (typeof token === "undefined") {
          return false;
        }
        var ops = ["and", "andnot", "or", "ornot"];
        for (var i = 0; i < ops.length; i++) {
          if (ops[i] === token) {
            return true;
          }
        }
        return false;
      }
      function processPostfix(postfixArray) {
        var stack = new Stack();
        postfixArray.forEach(function (element) {
          if (!isOperator(element)) {
            stack.push(element);
          } else {
            var mainNode = {};
            var notNode = null;
            if (element.indexOf("andnot") !== -1) {
              notNode = {
                op: "and"
              };
            } else if (element.indexOf("ornot") !== -1) {
              notNode = {
                op: "or"
              };
            }
            mainNode.op = element;
            mainNode.values = [];
            var operand = stack.pop();
            mainNode.values.push(stack.pop());
            if (notNode !== null) {
              mainNode.values.push({
                op: "not",
                expression: operand
              });
              notNode.values = mainNode.values;
              stack.push(notNode);
            } else {
              mainNode.values.push(operand);
              stack.push(mainNode);
            }
          }
        });
        return stack.pop();
      }
      infix = infix.replace(/\band\snot\b/gi, "andnot");
      infix = infix.replace(/\bor\snot\b/gi, "ornot");
      infix = infix.split(" ");
      var s = new Stack();
      var precedence = {
        and: 4,
        andnot: 3,
        or: 2,
        ornot: 1
      };
      var postfix = [];
      var token, operator1, operator2;
      for (var i = 0; i < infix.length; i++) {
        token = infix[i];
        if (token === "(") {
          s.push(token);
        } else if (token === ")") {
          while (s.peek() !== "(") {
            postfix.push(s.pop());
          }
          s.pop();
        } else if (!isOperator(token)) {
          postfix.push(token);
        } else if (isOperator(token)) {
          operator1 = token;
          operator2 = s.peek();
          while (isOperator(operator2) !== -1 && precedence[operator1] <= precedence[operator2]) {
            postfix.push(operator2);
            s.pop();
            operator2 = s.peek();
          }
          s.push(operator1);
        }
      }
      while (s.length() > 0) {
        postfix.push(s.pop());
      }
      return processPostfix(postfix);
    },
    operatorToFromSDL: function (operator) {
      operator = operator.toLowerCase();
      var stringOps = {
        "equal to": "equals",
        "not equal to": "not equals",
        "starts with": "startswith",
        "does not start with": "not startswith",
        "ends with": "endswith",
        "does not end with": "not endswith",
        "does not contain": "not contains",
        equals: "equal to",
        "not equals": "not equal to",
        startswith: "starts with",
        "not startswith": "does not start with",
        endswith: "ends with",
        "not endswith": "does not end with",
        contains: "contains",
        "not contains": "does not contain"
      };
      try {
        return stringOps[operator];
      } catch (e) {
        return false;
      }
    },
    bulkSegmentsCurrentVersion: "1.0",
    buttonLoader: function (e) {
      var $target = $(e.currentTarget);
      var button = $target.find("label");
      $target.attr("disabled", "");
      button.empty();
      T1Spinner.apply(button);
    },
    buttonReset: function (button, newText) {
      var el = button instanceof $ ? button : $(button);
      el.find("label").text(newText);
    },
    fetchHandler: function (targetModel, opts, dataTarget) {
      var self = targetModel;
      var options, onSuccess;
      options = $.extend(true, {}, opts || {});
      onSuccess = function (data) {
        var currentData = $.xml2json(data.currentFetch.responseXML).entities;
        var count = currentData.count;
        currentData = Array.isArray(currentData.entity) ? currentData.entity : currentData.entity ? [currentData.entity] : [];
        currentData.map(function (obj) {
          dataTarget.push(obj);
        });
        function finalCallback() {
          if (opts && opts.success) {
            opts.success(dataTarget);
          }
        }
        if (dataTarget.length >= count || opts.fetchOnce) {
          finalCallback();
        } else {
          opts.offset = opts.offset ? opts.offset + 100 : self.offset + 100;
          self.fetch(opts);
        }
      };
      options.success = onSuccess;
      T1Model.prototype.fetch.call(targetModel, options);
    },
    showErrorModal: function (error, response) {
      var parsedResponse = response;
      if (typeof parsedResponse !== "string") {
        parsedResponse = new XMLSerializer().serializeToString(response.documentElement);
      }
      T1.EventHub.publish("comm:error", {
        code: ": 500(Internal Server Error)",
        detail: error + "\n" + parsedResponse
      });
    },
    isNonZeroDataPixel: function (currentRow) {
      var props = currentRow.json.prop;
      if (props) {
        var propMap = {};
        for (var i = 0; i < props.length; i++) {
          propMap[props[i].name] = props[i].value;
        }
        if (propMap.pixel_type === "data" && propMap.cost_cpm && parseInt(propMap.cost_cpm, 10) > 0) {
          return true;
        }
      }
      return false;
    },
    getPermissions: function () {
      var user = User.getUser();
      var pixels = T1Permissions.check("feature", "pixels");
      var dataMapping = user.get("view_data_definition") === "1";
      var editSegments = user.get("edit_segments") === "1";
      var basicPermissions = pixels && dataMapping;
      var scheme = [{
        permissionType: "full",
        editSegments: true
      }, {
        permissionType: "view_only",
        editSegments: false
      }];
      if (basicPermissions) {
        return _.where(scheme, {
          editSegments: editSegments
        })[0].permissionType;
      } else {
        return "none";
      }
    },
    unauthorizedMessage: {
      adaptive: {
        org: `Get more from your data with\n                 <a href="https://mediamathsupport.force.com/s/article/Using-Adaptive-Segments">Adaptive Segments</a>.\n                 Discover and build audiences segments with precision, scale, and control\n                 to connect with your best customers and prospects and increase the effectiveness of your marketing.\n                  Ask your account team for access and put your data to work today.`,
        user: "It looks like you don't have access to this page. " + "If you believe you are seeing this message in error, please contact our support team."
      },
      standard: {
        user: "It looks like you don't have access to this page. " + "If you believe you are seeing this message in error, please contact our support team."
      }
    }
  };
});
