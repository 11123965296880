define('collections/strategyConcepts',["jQuery", "T1", "T1Collection", "models/strategyConcept", "T1Sortable"], function ($, T1, T1Collection, StrategyConcept) {
  const getTotalOfConcepts = function () {
    let _self, _strategyId;
    function getValue(concept) {
      let value;
      if (concept.percent) {
        value = concept.percent;
      } else if (concept.strategy_concept) {
        value = _self.getStrategyConcept(_strategyId, concept.strategy_concept).percent;
      } else {
        value = "0";
      }
      return parseFloat(value);
    }
    function setPrivateVars(self, strategyId) {
      _self = self;
      _strategyId = strategyId;
    }
    function reducer(total, concept, index, array) {
      const value = getValue(concept);
      if (index === array.length - 1) {
        setPrivateVars(null, null);
      }
      return total + value;
    }
    return function (self, strategyId) {
      setPrivateVars(self, strategyId);
      return reducer;
    };
  }();
  function isWeightingEnabled(weightingType) {
    weightingType = weightingType.toLowerCase();
    return weightingType !== "no weighting" && weightingType !== "none";
  }
  T1.Models.StrategyConcepts = T1Collection.extend({
    model: StrategyConcept,
    filterConfiguration: {
      filters: [{
        entity: "strategy",
        fetchOnChange: true
      }]
    },
    fetchOptions: {
      with: ["strategy_concepts"]
    },
    url: function (params) {
      var originalString = this.urlString;
      var url;
      this.urlString = `strategies/${this.id}/${originalString}`;
      url = T1Collection.prototype.url.call(this, params);
      this.urlString = originalString;
      return url;
    },
    validateStrategyConcepts: function (weightingType, strategyId) {
      if (isWeightingEnabled(weightingType)) {
        const total = this.toJSON().reduce(getTotalOfConcepts(this, strategyId), 0);
        const DECIMALS = 2;
        const API_LIMIT = 100;
        return {
          error: total !== API_LIMIT,
          total: total.toFixed(DECIMALS)
        };
      }
      return {
        error: false,
        total: ""
      };
    },
    getStrategyConcept: function (strategyId, strategy_concepts) {
      var concept;
      if (!strategy_concepts.length) {
        concept = strategy_concepts.strategy_id === strategyId ? strategy_concepts : undefined;
        return concept;
      }
      for (let i = 0; i < strategy_concepts.length; i++) {
        if (strategy_concepts[i].strategy_id === strategyId) {
          concept = strategy_concepts[i];
        }
      }
      return concept;
    },
    saveConcepts: function (successCallback, statusInvalid) {
      var postObject = {};
      var data = this.toJSON();
      var onSuccess = successCallback || $.noop;
      var onStatusInvalid = statusInvalid || $.noop;
      var i, percent, weighting;
      if (data === undefined) {
        throw new Error("Data is required to save the day parts");
      }
      if (data.length > 0) {
        for (i = 0; i < data.length; i++) {
          weighting = data[i].weighting ? data[i].weighting : "NONE";
          percent = data[i].percent;
          postObject[`concepts.${i + 1}.id`] = data[i].id;
          if (isWeightingEnabled(weighting)) {
            postObject[`concepts.${i + 1}.weighting`] = weighting;
            postObject[`concepts.${i + 1}.percent`] = percent;
          } else {
            postObject[`concepts.${i + 1}.weighting`] = "NONE";
          }
        }
      }
      this.save({
        data: postObject
      }, {
        success: function () {
          onSuccess();
          T1.EventHub.publish("strategy-create-edit:reloadSubViews");
        },
        error: function (coll, errors) {
          onStatusInvalid(errors);
        },
        conflict: function (errors) {
          onStatusInvalid(errors);
        }
      });
    },
    urlString: "concepts"
  });
  return T1.Models.StrategyConcepts;
});
