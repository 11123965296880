define('models/exchangeSeat',["jQuery", "T1", "T1Model", "T1Collection"], function ($, T1, T1Model, T1Collection) {
  T1.Models.ExchangeSeat = T1Model.extend({
    url: function (params) {
      var originalString = this.urlString;
      var url;
      this.urlString = "organizations/" + this.id + "/" + originalString;
      url = T1Collection.prototype.url.call(this, params);
      this.urlString = originalString;
      return url;
    },
    defaults: {
      action: "include"
    },
    fixture: "-exchangeSeat",
    validateAttrs: function (attrs) {
      var errors = [];
      var optionalFields = ["bill_media_to_client"];
      var pct;
      var num;
      var requiredField = "This is a required field";
      var validationResult;
      var reqFields = {
        seat_identifier: function () {
          return !attrs.seat_identifier;
        }
      };
      var extraValFields = {};
      if (attrs.rmx_exchange_cost_unit) {
        $.extend(extraValFields, {
          rmx_exchange_cost_unit: function () {
            return !attrs.rmx_exchange_cost_unit;
          }
        });
        if (attrs.rmx_exchange_cost_unit === "CPM") {
          if (attrs.status === "1") {
            $.extend(extraValFields, {
              rmx_exchange_cost_cpm: function () {
                num = Number(attrs.rmx_exchange_cost_cpm);
                if (attrs.rmx_exchange_cost_cpm === "") {
                  return "This is a required field";
                } else if (num < 0 || num > 9999999.99) {
                  return "CPM must be between 0 and 9999999.99";
                } else if (isNaN(num)) {
                  return "CPM must be a number";
                }
              }
            });
          } else {
            optionalFields.push("rmx_exchange_cost_cpm");
          }
        } else {
          if (attrs.status === "1") {
            $.extend(extraValFields, {
              rmx_exchange_cost_pct: function () {
                num = Number(attrs.rmx_exchange_cost_pct);
                if (attrs.rmx_exchange_cost_pct === "") {
                  return "This is a required field";
                } else if (num < 0 || num > 100) {
                  return "Percentage must be between 0 and 100";
                } else if (isNaN(num)) {
                  return "Percentage must be a number";
                }
              }
            });
          } else {
            optionalFields.push("rmx_exchange_cost_pct");
          }
        }
      }
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key + "_" + attrs.supply_source_id,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key + "_" + attrs.supply_source_id,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key + "_" + attrs.supply_source_id,
              message: requiredField
            });
          }
        } else if (extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key + "_" + attrs.supply_source_id,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    }
  });
  return T1.Models.ExchangeSeat;
});
