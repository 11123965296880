define('modules/campaign/models/currencySearch',["Underscore", "T1"], function (_, T1) {
  "use strict";

  var getCurrencyList = T1.Utils.getCurrencyList;
  const CurrencySearch = function () {
    function searchHandler(searchOptions, toReload) {
      var sourceList = getCurrencyList();
      var arrResult = [];
      var toFind = searchOptions.params[0].term;
      toFind = toFind.replace(/\*/g, "").toUpperCase();
      _.each(sourceList, function (item) {
        if (toFind === "_" || toReload === true) {
          arrResult.push({
            name: item.text,
            id: item.value
          });
        } else {
          if (item.text.toUpperCase().includes(toFind)) {
            arrResult.push({
              name: item.text,
              id: item.value
            });
          }
        }
      });
      searchOptions.success({
        toJSON: function () {
          return arrResult;
        }
      });
    }
    function doSearch(searchOptions, toReload) {
      setTimeout(function () {
        searchHandler(searchOptions, toReload);
      }, 0);
    }
    return {
      search: doSearch
    };
  };
  return CurrencySearch;
});
