define('collections/advertiserRemoteAccounts',["jQuery", "Underscore", "T1", "T1Collection", "models/advertiserRemoteAccount"], function ($, _, T1, T1Collection, AdvertiserRemoteAccount) {
  T1.Models.AdvertiserRemoteAccounts = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    model: AdvertiserRemoteAccount,
    urlString: "advertisers",
    fixture: "-advertisers",
    fetch: function (opts) {
      T1Collection.prototype.fetch.call(this, opts);
    },
    url: function () {
      var originalString = this.urlString;
      var url;
      if (this.id === undefined) {
        throw new Error("Advertiser id needs to be set in id property of collection to construct URL");
      }
      this.urlString = originalString + "/" + this.id + "/remote_accounts";
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    saveAccounts: function (facebookId, onSuccess, statusInvalid) {
      var postObj = {};
      var data = {};
      if (facebookId) {
        data["remote_accounts.1.remote_account_type_id"] = "1";
        data["remote_accounts.1.remote_account_id"] = facebookId;
      }
      postObj.data = data;
      this.save(postObj, {
        success: onSuccess,
        statusInvalid: statusInvalid,
        conflict: statusInvalid
      });
    }
  });
  return T1.Models.AdvertiserRemoteAccounts;
});
