define('models/eventAttributes',["jQuery", "T1", "T1Model", "models/attributeOptions", "modules/audiences/segments/segmentUtils"], function ($, T1, T1Model, AttrOptions, Utils) {
  "use strict";

  T1.Models.EventAttribute = T1Model.extend({
    urlRoot: "attributes/",
    offset: 0,
    modelData: [],
    attributeOptions: {},
    url: function () {
      var self = this;
      var source = this.get("source");
      var focus = source === "site" ? "focus/pixel_bundle=" + this.get("targetId") : "focus/advertiser=" + this.get("advertiserId");
      if (source === "top") {
        return T1.ENV.SEGMENT_API_BASE + this.urlRoot + "?full=*&page_offset=" + self.offset;
      }
      return T1.ENV.SEGMENT_API_BASE + this.urlRoot + focus + "?full=*&q=source==" + source + "&page_offset=" + self.offset;
    },
    fetch: function (opts) {
      if (opts.offset) {
        this.offset = opts.offset;
      }
      Utils.fetchHandler(this, opts, this.modelData);
    },
    getAttributeOptions: function (keyValue, opts) {
      var advertiserId = this.get("advertiserId");
      var attr = new AttrOptions({
        keyValue: keyValue,
        advertiserId: advertiserId,
        targetId: this.get("targetId"),
        campaignId: this.get("campaignId")
      });
      attr.dataTarget = [];
      attr.fetch(opts);
    }
  });
  return T1.Models.EventAttribute;
});
