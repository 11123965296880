define('modules/shared/configs/charts/donutChartConfig',[], function () {
  "use strict";

  const config = {
    hideCredits: true,
    data: [],
    innerRadius: 47,
    legend: {
      height: "100%",
      itemContainers: {
        events: {
          hit: function () {},
          out: function () {},
          over: function () {}
        },
        paddingBottom: 2,
        paddingTop: 2,
        togglable: false
      },
      markers: {
        children: [{
          disabled: true,
          name: "default"
        }, {
          name: "outerCircle",
          opacity: 0,
          radius: 8,
          states: {
            default: {
              properties: {
                opacity: 0
              }
            }
          },
          type: "Circle",
          x: 4,
          y: 4
        }, {
          name: "innerCircle",
          radius: 5,
          stroke: "#fff",
          strokeOpacity: 1,
          strokeWidth: 2,
          type: "Circle",
          x: 4,
          y: 4
        }],
        height: 10,
        width: 10
      },
      parent: {},
      position: "right",
      width: "100%"
    },
    radius: 66,
    series: [{
      colors: {
        list: []
      },
      dataFields: {
        category: "label",
        value: "value"
      },
      hiddenState: {
        properties: {
          endAngle: -90
        }
      },
      labels: {
        disabled: true
      },
      slices: {
        events: {
          hit: function () {},
          out: function () {},
          over: function () {}
        },
        states: {
          active: {
            properties: {
              radius: 72,
              scale: 1,
              shiftRadius: 0
            }
          },
          hover: {
            properties: {
              radius: 72,
              scale: 1,
              shiftRadius: 0
            }
          },
          unselected: {
            properties: {
              fill: "#e1e1e1",
              radius: 66,
              scale: 1,
              shiftRadius: 0
            }
          }
        },
        stroke: "#fff",
        strokeWidth: 1
      },
      ticks: {
        disabled: true
      },
      tooltip: {
        disabled: true
      },
      type: "PieSeries"
    }]
  };
  return {
    copy: () => JSON.parse(JSON.stringify(config))
  };
});
