define('modules/campaign/bulkedit/targeting/mydata/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "T1Spinner", "collections/pixels", "models/vendor", "text!../templates/bin_header.html", "text!../templates/bin_title.html", "text!../templates/bin_group_name.html", "text!../templates/bin_item.html", "text!../templates/bin_overall_header.html", "text!../templates/bin_targeting_operator.html", "utils/CampaignStrategyUtils"], function ($, _, T1, BinsView, Spinner, Pixels, Vendor, bin_header, bin_title, bin_group_name, bin_item, bin_overall_header, targeting_operator, Utils) {
  "use strict";

  const pixelTypeMap = Object.freeze({
    creative: "C",
    data: "D",
    dynamic: "S",
    event: "E",
    segment: "S"
  });
  const formatNumber = T1.Formats.rolledUpNumbersShort();
  var postCollection = new Pixels();
  postCollection.urlFilter.disableEventsListeners();
  const PixelBinsView = BinsView.extend({
    type: "Pixel",
    postCollection: postCollection,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true,
        showTargetingOperator: true,
        showTooltip: true,
        tooltipText: "Total pixel loads yesterday for pixels in the bins. " + 'This amount will get added to ("Add"), subtracted from ("Remove") ' + 'or will replace ("Overwrite") the total pixel loads yesterday ' + "for each strategy selected for bulk editing (based on existing pixel assignments) "
      }
    },
    events: {
      "click #andOrRadioGroup": "andOrClicked",
      "click #targetingCheckbox": "onKeepExistingClick",
      "changed #edit-options": "onEditOptionsChange"
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      bin_item: bin_item,
      overall_header: bin_overall_header,
      targeting_operator: targeting_operator
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      allowMultipleSelection: true,
      onDropEvent: "bins:droppedPixel",
      checkExistenceSharedCollection: false,
      handlerTextEl: ".name"
    },
    andOrClicked: function () {
      const andOrRadioGroup = this.$("#andOrRadioGroup");
      const operation = andOrRadioGroup.val() || "OR";
      this.sharedCollection.includeOperator = operation;
      this.updateLoadCount();
    },
    onKeepExistingClick: function (evt) {
      const andOrRadioGroup = this.$("#andOrRadioGroup");
      const andRbt = andOrRadioGroup.find('[value="AND"]')[0];
      const orRbt = andOrRadioGroup.find('[value="OR"]')[0];
      if (evt.currentTarget.value) {
        andOrRadioGroup.attr("disabled", true);
        orRbt.checked = false;
        andRbt.checked = false;
        this.sharedCollection.includeOperator = "";
      } else {
        andOrRadioGroup.attr("disabled", false);
        orRbt.checked = true;
        this.sharedCollection.includeOperator = "OR";
      }
      this.updateLoadCount();
    },
    initialize: function ({
      sharedCollection: sharedCollection
    }) {
      this.sharedCollection = sharedCollection;
    },
    mapItems: function (items) {
      var result = [];
      var getGroupNameFromModel = Utils.getGroupNameFromPixelModel;
      if (!items || typeof items !== "object") {
        throw new Error(`mapItems fails if input doesn't exist`);
      }
      items = _.groupBy(items, groupedItem => getGroupNameFromModel(groupedItem));
      for (const item in items) {
        if (T1.Utils.hasOwnProp(items, item)) {
          const modelArray = items[item];
          modelArray.forEach((model, i) => {
            const path = this.getPath(model.get("name"));
            result.push({
              action: model.action || model.get("action"),
              bin_group_name: getGroupNameFromModel(model),
              bin_show_group_name: i === 0,
              full_path_end: path.shortPathEnd,
              full_path: path.shortPath,
              id: model.id,
              pixel_type: pixelTypeMap[model.get("pixel_type")] || "INVALID",
              title: path.title
            });
          });
        }
      }
      return result;
    },
    getPath: function (fullPath) {
      var path = fullPath;
      var goalLength = 30;
      if (!fullPath) {
        throw new Error("Pixel has no name attribute");
      }
      path = path.replace(/ - /g, "|");
      path = path.length > goalLength ? `...${path.substr(-goalLength)}` : path;
      const lastSlashIndex = path.lastIndexOf("|");
      path = path.replace(/[|]/g, " - ");
      const noSlash = lastSlashIndex === -1;
      return {
        shortPath: noSlash ? "" : path.substring(0, lastSlashIndex + 1),
        shortPathEnd: noSlash ? path : path.substr(lastSlashIndex + 1),
        title: fullPath.replace(/ - /g, "|")
      };
    },
    getPixelTotalLoads: function (ids, operation, passThru, callback) {
      const ajxOperation = `${passThru}Ajx`;
      var idCount = ids.length;
      if (!ids || !operation || !passThru || !callback) {
        throw new Error("getPixelTotalLoads requires all function arguments");
      }
      if (!(operation === "AND" || operation === "OR")) {
        throw new Error("getPixelTotalLoads requires operation be either AND or OR");
      }
      if (typeof callback !== "function") {
        throw new Error("getPixelTotalLoads requires a callback function");
      }
      const fetchOptions = {
        full: "*"
      };
      const opts = {
        success: data => {
          let num;
          var dataList = data.toJSON();
          var loads = 0;
          var totalCount = 0;
          var nonDynamicCount = 0;
          this.$(`.header-${passThru}-bin .icon-spinner`).spin(false);
          $.each(dataList, function (index, value) {
            if (value.pixel_type !== "dynamic") {
              if (value.unique) {
                num = parseFloat(value.unique.loads);
              } else {
                num = 0;
              }
              if (operation === "OR") {
                loads += num;
              } else {
                loads = nonDynamicCount === 0 ? num : loads;
                loads = loads > num ? num : loads;
              }
              nonDynamicCount++;
            }
            totalCount++;
          });
          const dynamicCount = totalCount - nonDynamicCount;
          if (totalCount === dynamicCount) {
            loads = "N/A";
          }
          callback({
            totalLoads: loads
          }, passThru, operation, idCount - dynamicCount);
        }
      };
      const postCollectionBin = new Pixels();
      postCollectionBin.fetchOptions = $.extend(fetchOptions, postCollection.fetchOptions);
      postCollectionBin.isPaginated = false;
      postCollectionBin.urlFilter.clear();
      postCollectionBin.search.clear();
      postCollectionBin.search.set({
        idList: ids.join(",")
      });
      if (this[ajxOperation]) {
        this[ajxOperation].abort();
      }
      postCollectionBin.fetch(opts);
    },
    getProviderTax(model, hasBeenRemoved) {
      const pixelProvider = model.get("pixel_provider");
      const pixelType = model.get("pixel_type");
      if (!hasBeenRemoved && pixelType === "data" && pixelProvider) {
        const vendorModel = new Vendor();
        vendorModel.id = pixelProvider.get ? pixelProvider.get("vendor_id") : pixelProvider.vendor_id;
        vendorModel.fetch({
          success: respModel => {
            if (respModel.get("provider_tax")) {
              T1.EventHub.publish("bins.providerTaxAdded");
            }
          }
        });
      }
    },
    updateLoadCount: function () {
      var self = this;
      var bins = {};
      var actions = ["include", "exclude"];
      var binItemsLength = 0;
      let operation = this.sharedCollection.includeOperator;
      function applySpinner(spinnerTarget) {
        if (!spinnerTarget.children().length) {
          Spinner.apply(spinnerTarget, self.spinnerOpts);
        }
      }
      function groupShColl(model) {
        const i = this;
        return model.action === actions[i] || model.get("action") === actions[i];
      }
      for (let i = 0; i < actions.length; i++) {
        const group = this.sharedCollection.groupBy(groupShColl.bind(i));
        bins[actions[i]] = group.true || null;
      }
      function getTotalLoads(totalLoads, action, idCount, totalOperation) {
        if (totalLoads === "N/A") {
          return totalLoads;
        }
        const formattedTotalLoads = formatNumber(totalLoads);
        const canHaveUpto = totalOperation === "AND" && action === "include" && idCount > 1;
        return canHaveUpto ? `Up to ${formattedTotalLoads}` : formattedTotalLoads;
      }
      for (const action in bins) {
        if (T1.Utils.hasOwnProp(bins, action)) {
          const ids = [];
          const binItems = bins[action];
          const iconSpinner = this.$(`.header-${action}-bin .icon-spinner`);
          if (binItems && this.sharedCollection.includeOperator) {
            for (let j = 0; j < binItems.length; j++) {
              ids.push(parseInt(binItems[j].id, 10));
            }
            applySpinner(iconSpinner);
            this.updateDataBind({
              totalLoads: ""
            }, `.header-${action}-bin`);
            binItemsLength = binItems.length;
            operation = action === "exclude" ? "OR" : operation;
            this.getPixelTotalLoads(ids, operation, action, function (data, totalAction, totalOperation, idCount) {
              data.totalLoads = getTotalLoads(data.totalLoads, totalAction, idCount, totalOperation);
              self.updateDataBind(data, `.header-${totalAction}-bin`);
            });
          } else if (!this.sharedCollection.includeOperator) {
            this.updateDataBind({
              totalLoads: "N/A"
            }, `.header-${action}-bin`);
            iconSpinner.spin(false);
          } else {
            const totalLoads = getTotalLoads(0, action, binItemsLength);
            this.updateDataBind({
              totalLoads: totalLoads
            }, `.header-${action}-bin`);
            iconSpinner.spin(false);
          }
        }
      }
    },
    updateBins: function (model, hasBeenRemoved) {
      this.getProviderTax(model, hasBeenRemoved);
      this.updateLoadCount();
    },
    selectBulkEditOptions: function () {
      var bulkEditAction = this.sharedCollection.bulkeditAction;
      var $bulkEditGroup = this.el.find("#edit-options");
      this.isFirstLoad = true;
      $bulkEditGroup.find(`[value=${bulkEditAction}]`)[0].checked = true;
    },
    selectTargetingOperatorOptions: function () {
      var includeOperatorAction = this.sharedCollection.includeOperator;
      var andOrRadioGroup = this.el.find("#andOrRadioGroup");
      var targetingCheckbox = this.el.find("#targetingCheckbox");
      if (!includeOperatorAction || includeOperatorAction === "") {
        targetingCheckbox.attr("checked", true);
        andOrRadioGroup.attr("disabled", true);
      } else {
        targetingCheckbox.attr("checked", false);
        andOrRadioGroup.attr("disabled", false);
        andOrRadioGroup.find(`[value=${includeOperatorAction}]`)[0].checked = true;
      }
    },
    toggleKeepExistingCheckbox: function (val) {
      this.$("#targetingCheckbox").attr("disabled", val);
    },
    onEditOptionsChange: function (event) {
      var value = event.currentTarget.value;
      var includeOperatorAction = this.sharedCollection.includeOperator;
      const keepExistingCheckbox = this.$("#targetingCheckbox");
      const andOrRadioGroup = this.$("#andOrRadioGroup");
      const andRbt = andOrRadioGroup.find('[value="AND"]')[0];
      const orRbt = andOrRadioGroup.find('[value="OR"]')[0];
      switch (value) {
        case "add":
          this.sharedCollection.bulkeditAction = "add";
          if (!this.isFirstLoad && !keepExistingCheckbox[0].value) {
            keepExistingCheckbox[0].checked = true;
            andOrRadioGroup.attr("disabled", true);
            orRbt.checked = false;
            andRbt.checked = false;
            this.sharedCollection.includeOperator = "";
          }
          this.toggleKeepExistingCheckbox(false);
          this.isFirstLoad = false;
          this.updateLoadCount();
          break;
        case "remove":
          this.sharedCollection.bulkeditAction = "remove";
          if (!this.isFirstLoad && !keepExistingCheckbox[0].value) {
            keepExistingCheckbox[0].checked = true;
            andOrRadioGroup.attr("disabled", true);
            orRbt.checked = false;
            andRbt.checked = false;
            this.sharedCollection.includeOperator = "";
          }
          this.toggleKeepExistingCheckbox(true);
          this.isFirstLoad = false;
          this.updateLoadCount();
          break;
        case "overwrite":
          this.sharedCollection.bulkeditAction = "overwrite";
          if (this.isFirstLoad && this.sharedCollection.includeOperator) {
            this.isFirstLoad = false;
            this.selectTargetingOperatorOptions();
          } else if (!includeOperatorAction || includeOperatorAction === "") {
            this.sharedCollection.includeOperator = "OR";
            this.selectTargetingOperatorOptions();
          }
          this.toggleKeepExistingCheckbox(true);
          this.updateLoadCount();
          break;
      }
      T1.EventHub.publish("strategyBulkEditMyData:bulkeditActionChanged");
    },
    load: function () {
      if (!this.sharedCollection.bulkeditAction || !this.sharedCollection.length) {
        this.sharedCollection.bulkeditAction = "add";
      }
      this.render().then(() => {
        this.selectBulkEditOptions();
        this.selectTargetingOperatorOptions();
        $.proxy(this.updateLoadCount, this)();
        T1.EventHub.publish("strategyTargetingLoader:stop");
      });
    },
    unload: function () {
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    serialize: function (dataset) {
      const includeBinHeader = "Total included loads yesterday";
      const excludeBinHeader = "Total excluded loads yesterday";
      if (dataset && dataset.bins) {
        dataset.bins[0].use_or = this.sharedCollection.includeOperator === "OR";
        $.each(dataset.bins, function (index, bin) {
          bin.bin_label = bin.bin_action === "include" ? includeBinHeader : excludeBinHeader;
        });
        return dataset;
      }
    }
  });
  return PixelBinsView;
});
