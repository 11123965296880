define('collections/supernodes',["jQuery", "Underscore", "T1", "T1Collection", "T1Model", "Backbone", "models/supernode"], function ($, _, T1, T1Collection, T1Model, Backbone, Supernode) {
  "use strict";

  T1.Models.Supernodes = T1Collection.extend({
    model: Supernode,
    contentType: "application/json",
    dataType: "json",
    url: function () {
      return T1.SEGMENT_API_BASE + "audience_segments/vendors/focus/advertiser=" + this.advertiserId;
    },
    fetch: function (opts) {
      opts = opts || {};
      opts.dataType = opts.dataType || this.dataType;
      opts.processAjaxResponse = opts.processAjaxResponse || this.processAjaxResponse;
      T1Collection.prototype.fetch.call(this, opts);
    },
    parse: function (resp) {
      return resp.data;
    },
    processAjaxResponse: function (resp) {
      return {
        statusCode: "ok",
        jsonData: resp
      };
    }
  });
  return T1.Models.Supernodes;
});
