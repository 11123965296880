define('modules/media/pmp-e/views/binGrid',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Permissions", "T1Currencies", "T1Spinner", "models/sessionUser", "models/userPreferences", "text!../templates/binGrid.html", "collections/agencies", "collections/timezones", "collections/advertisers", "collections/organizations", "collections/supplySources", "collections/mediaDeals", "models/advertiser", "models/agency", "models/mediaDeal", "T1Timezones", "moment-timezone", "models/organization", "T1UtilsAsync"], function ($, _, When, T1, T1View, T1Layout, T1Permissions, T1Currencies, T1Spinner, User, UserPreferences, template, Agencies, Timezones, Advertisers, Organizations, SupplySources, Deals, Advertiser, Agency, Deal, T1Timezones, moment, OrganizationModel, T1UtilsAsync) {
  "use strict";

  const MIN_SEARCH_INPUT = 3;
  const DEAL_OWNER_TYPE = {
    organization: "organization",
    agency: "agency",
    advertiser: "advertiser"
  };
  const binGrid = T1View.extend({
    template: template,
    searchTerm: "",
    items: {},
    selectedItems: {},
    dealOwner: {},
    ownerChanged: false,
    events: {
      "changed .search-tree": "loadTreeSearch",
      "item-selected .tree-permissions": "handleTargetingPermissionsChanged",
      "item-include-or-exclude creatives-bin-list-item": "handleRemoveFromBin",
      "select-all .tree-permissions": "handleSelectAllEntities",
      "item-selected .deals-grid": "handleSelectItem"
    },
    eventhubEvents: {
      "ownerType:selected": "handleOwnerTypeSelected",
      "owner:selected": "handleOwnerSelected",
      "deal:showTargetingPermissionsGrid": "handleShowTargetingPermissionsGrid",
      "load:dealData": "handleDealData",
      "orgChanged:hideBinGrid": "hideGrid"
    },
    initialize: function () {
      this.selectItem = this.selectItem.bind(this);
      this.isNewDeal = Backbone.history.getFragment().includes("create");
      this.isEditingDeal = !this.isNewDeal;
    },
    load: function () {
      this.render().then(() => {
        this.selectedItems = {};
        this.treePermissions = this.el.find(".tree-permissions")[0];
        this.binList = this.el.find("#selected-items-bin")[0];
        this.binList.grouped = [];
        this.isDealGroupPage = false;
        this.firstCall = 0;
      });
    },
    hideGrid() {
      const dealsSearchTree = $(".deals-search-tree");
      const saveDealButtons = $(".post-deals-close, .post-deals-another");
      dealsSearchTree.attr("hidden", "");
      saveDealButtons.attr("disabled", "");
      if (this.isEditingDeal && this.ownerChanged) {
        this.selectedItems = {};
      }
      if (this.treePermissions) {
        this.treePermissions.data = [];
      }
      if (this.binList) {
        this.binList.data = [];
      }
    },
    showGrid(shouldSelectAll) {
      const dealOwnerName = this.dealOwner.name;
      const dealsSearchTree = $(".deals-search-tree");
      const entityGrid = this.treePermissions;
      const dealTargetingLoader = $(".deal-targeting-loader");
      const saveDealButtons = $(".post-deals-close, .post-deals-another");
      entityGrid.loading = true;
      entityGrid.header = dealOwnerName;
      dealsSearchTree.removeAttr("hidden");
      const promise = this.selectDataFetch();
      promise.then(values => {
        dealTargetingLoader.attr("hidden", "");
        entityGrid.loading = false;
        this.populateGrid(values, "", false, shouldSelectAll);
        this.firstCall += 1;
        if (this.isNewDeal && this.firstCall > 0) {
          this.dealPermissionsLoaded = true;
        }
        saveDealButtons.removeAttr("disabled");
        const permissionedEntities = {
          permissionedEntities: Object.values(this.selectedItems)
        };
        T1.EventHub.publish("binGridChanged:updatePermissions", permissionedEntities);
      });
    },
    handleDealData({
      user: user,
      hasFullOrgAccess: hasFullOrgAccess,
      hasAgencies: hasAgencies,
      isNewDeal: isNewDeal,
      isEditingDeal: isEditingDeal,
      deal: deal
    }) {
      this.user = user;
      this.hasFullOrgAccess = hasFullOrgAccess;
      this.hasAgencies = hasAgencies;
      this.isNewDeal = isNewDeal;
      this.isEditingDeal = isEditingDeal;
      this.deal = deal;
      this.dealOwner = this.deal.owner;
      if (this.dealOwner) {
        this.dealOwnerType = this.dealOwner.type;
      }
      if (this.isEditingDeal) {
        this.setPermissionedDeals();
      }
    },
    handleOwnerTypeSelected(data) {
      this.dealOwnerType = data;
      this.hideGrid();
    },
    handleOwnerSelected({
      dealOwner: dealOwner
    }) {
      let shouldSelectAll;
      if (this.isEditingDeal && !this.ownerChanged) {
        shouldSelectAll = false;
        this.ownerChanged = true;
      } else {
        shouldSelectAll = true;
      }
      this.dealOwner = dealOwner;
      this.showGrid(shouldSelectAll);
    },
    handleRemoveFromBin(event) {
      const model = event.originalEvent.detail.model;
      const entityGrid = this.treePermissions;
      const entityGridData = entityGrid.data;
      const searchInput = this.$(".search-tree")[0].value;
      const isSearching = Boolean(searchInput) && searchInput.length >= MIN_SEARCH_INPUT;
      entityGridData.forEach(row => {
        if (this.dealOwner.type === DEAL_OWNER_TYPE.organization) {
          if (model.type === DEAL_OWNER_TYPE.organization) {
            this.setEntityUnselected(row);
            row.children.forEach(advertiser => {
              this.setEntityUnselected(advertiser);
            });
            delete this.selectedItems[this.dealOwner.id];
            entityGrid.set("_selectAllState", "unchecked");
          } else if (model.type === DEAL_OWNER_TYPE.agency) {
            if (row.id === model.id) {
              this.setEntityUnselected(row);
              row.children.forEach(advertiser => {
                this.setEntityUnselected(advertiser);
              });
            }
          } else if (model.agencyName === row.name) {
            row.children.forEach(advRow => {
              if (advRow.id === model.id) {
                this.setEntityUnselected(advRow);
              }
            });
          }
        } else if (this.dealOwner.type === DEAL_OWNER_TYPE.agency) {
          if (model.type === DEAL_OWNER_TYPE.agency) {
            delete this.selectedItems[this.dealOwner.id];
            entityGrid.set("_selectAllState", "unchecked");
            this.setEntityUnselected(row);
          } else if (row.id === model.id) {
            this.setEntityUnselected(row);
          }
        } else {
          this.setEntityUnselected(row);
        }
      });
      delete this.selectedItems[model.id];
      if (isSearching) {
        this.loadTreeSearch();
      } else {
        entityGrid.set("data", entityGridData.slice());
        entityGrid.set("selected", entityGrid.selected.filter(entity => this.selectedItems[entity.id]));
      }
      const permissionedEntities = {
        permissionedEntities: Object.values(this.selectedItems)
      };
      T1.EventHub.publish("binGridChanged:updatePermissions", permissionedEntities);
    },
    handleSelectItem: function (event, optionalModel) {
      const model = event.originalEvent.detail.model ? event.originalEvent.detail.model : optionalModel;
      const funcType = model.selectedState === "checked" ? "HandleSelect" : "HandleUnselect";
      const isAdvertiser = model.type === DEAL_OWNER_TYPE.advertiser;
      const isAgency = model.type === DEAL_OWNER_TYPE.agency;
      const ownerType = this.dealOwner.type;
      switch (ownerType.toLowerCase()) {
        case DEAL_OWNER_TYPE.organization:
          this[`org${funcType}`](model, isAgency, isAdvertiser);
          break;
        case DEAL_OWNER_TYPE.agency:
          this[`agency${funcType}`](model);
          break;
        case DEAL_OWNER_TYPE.advertiser:
          this[`advertiser${funcType}`](model);
          break;
      }
      const selectedArr = Object.values(this.selectedItems);
      this.binList.set("data", selectedArr);
      T1.EventHub.publish("binGridChanged:updatePermissions", {
        permissionedEntities: selectedArr
      });
    },
    orgHandleSelect: function (model, isAgency, isAdvertiser) {
      let isEveryOtherAgencySelected;
      if (isAdvertiser) {
        const agency = this.agencyList.filter(ag => ag.name === model.agencyName)[0];
        let agencyAdvertisers = agency.children;
        if (agencyAdvertisers instanceof Array) {
          agencyAdvertisers = agencyAdvertisers.filter(adv => adv.id !== model.id);
        } else {
          agencyAdvertisers = [];
        }
        const isEveryOtherAdvertiserSelected = this.areElementsInObject(agencyAdvertisers, this.selectedItems);
        if (isEveryOtherAdvertiserSelected) {
          this.selectedItems = this.filterElementsFromObject(agencyAdvertisers, this.selectedItems);
          const everyOtherAgency = this.agencyList.filter(item => item.name !== agency.name);
          isEveryOtherAgencySelected = this.areElementsInObject(everyOtherAgency, this.selectedItems);
          if (!isEveryOtherAgencySelected) {
            this.selectItem(agency);
          }
        } else {
          this.selectItem(model);
        }
      } else if (isAgency) {
        const everyOtherAgency = this.agencyList.filter(agency => agency.name !== model.name);
        const orgPermissioned = this.selectedItems[this.dealOwner.id];
        isEveryOtherAgencySelected = orgPermissioned || this.areElementsInObject(everyOtherAgency, this.selectedItems);
        if (!isEveryOtherAgencySelected) {
          this.selectItem(model);
        }
        this.selectedItems = this.filterElementsFromObject(model.children, this.selectedItems);
      }
      this.addElementsToBin(isEveryOtherAgencySelected, this.selectedItems);
    },
    agencyHandleSelect: function (model) {
      const agencyAdvertisers = this.advertiserList.filter(adv => adv.name !== model.name);
      const isEveryOtherAdvertiserSelected = agencyAdvertisers.length ? this.areElementsInObject(agencyAdvertisers, this.selectedItems) : true;
      this.selectedItems[model.id] = model;
      this.addElementsToBin(isEveryOtherAdvertiserSelected, this.selectedItems);
    },
    advertiserHandleSelect: function (model) {
      this.selectedItems[model.id] = model;
      this.addElementsToBin(false, this.selectedItems);
    },
    orgHandleUnselect: function (model, isAgency, isAdvertiser) {
      if (isAgency) {
        if (this.selectedItems[this.dealOwner.id]) {
          const otherAgencies = this.agencyList.filter(entity => entity.id !== model.id);
          this.selectedItems = {};
          otherAgencies.forEach(this.selectItem);
        } else {
          this.filterEntityAndParent(model);
        }
      } else if (isAdvertiser) {
        const agency = this.agencyList.filter(entity => entity.name === model.agencyName)[0];
        if (this.selectedItems[this.dealOwner.id]) {
          const otherAgencies = this.agencyList.filter(entity => entity.name !== model.agencyName);
          const otherAdvertisers = agency.children.filter(entity => entity.id !== model.id);
          this.selectedItems = {};
          otherAgencies.forEach(this.selectItem);
          otherAdvertisers.forEach(this.selectItem);
        } else if (this.selectedItems[agency.id]) {
          const otherAdvertisers = agency.children.filter(entity => entity.id !== model.id);
          this.filterEntityAndParent(model, true);
          otherAdvertisers.forEach(this.selectItem);
        } else {
          this.filterEntityAndParent(model, true);
        }
      }
    },
    agencyHandleUnselect: function (model) {
      if (this.selectedItems[this.dealOwner.id]) {
        const otherAdvertisers = this.advertiserList.filter(entity => entity.id !== model.id);
        this.selectedItems = {};
        otherAdvertisers.forEach(this.selectItem);
      } else {
        delete this.selectedItems[model.id];
      }
    },
    advertiserHandleUnselect: function (model) {
      delete this.selectedItems[model.id];
    },
    addElementsToBin: function (isAddingOwner, selectedEntities) {
      if (isAddingOwner) {
        this.selectedItems = {};
        this.selectItem(this.dealOwner);
      } else {
        Object.values(selectedEntities).forEach(this.selectItem);
      }
    },
    filterEntityAndParent: function (model, useAgencyName) {
      if (useAgencyName) {
        const entity = this.treePermissions.data.filter(age => age.name === model.agencyName)[0];
        delete this.selectedItems[entity.id];
      } else {
        delete this.selectedItems[this.dealOwner.id];
      }
      delete this.selectedItems[model.id];
    },
    filterElementsFromArray: function (elements, array) {
      return array.filter(el => !elements.some(el2 => el.name === el2.name));
    },
    areElementsInArray: function (elements, array) {
      return elements.every(el => array.some(el2 => el.name === el2.name));
    },
    areElementsInObject: function (elements, object) {
      return elements.every(el => object[el.id]);
    },
    filterElementsFromObject: function (elements, object) {
      elements.forEach(el => delete object[el.id]);
      return object;
    },
    resetTargetPermissions: function () {
      this.binCollection = [];
      this.binList.set("data", []);
      this.treePermissions.selected = [];
      this.handleHideTree();
    },
    setEntityUnselected: function (entity) {
      delete this.selectedItems[entity.id];
      entity.selected = false;
      entity.selectedState = "unchecked";
    },
    handleShowTargetingPermissionsGrid: function (data) {
      this.dealOwner = Object.assign({}, data.dealOwner);
      this.showGrid(data.shouldSelectAll);
    },
    handleTargetingPermissionsChanged: function (event) {
      T1.EventHub.publish("dealShowWarningStatus");
      this.handleSelectItem(event);
      if (this.dealDataLoaded) {
        this.dealPermissionsLoaded = true;
      }
    },
    handleSelectAllEntities: function (event) {
      const isSelected = event.originalEvent.detail.selected;
      const isSearching = event.target.searchTerm !== "";
      const gridData = event.target.data;
      const hasGridData = Boolean(gridData.length);
      if (isSelected && !isSearching) {
        this.addElementsToBin(true);
      } else if (isSelected && isSearching && hasGridData) {
        gridData.forEach(entity => {
          this.handleSelectItem(event, entity);
        });
      } else if (!isSelected && isSearching && hasGridData) {
        const isOwnerSelected = this.selectedItems[this.dealOwner.id];
        if (isOwnerSelected) {
          this.selectedItems = {};
          const isOrgOwner = this.dealOwner.type === DEAL_OWNER_TYPE.organization;
          let otherEntities = isOrgOwner ? this.agencyList : this.advertiserList;
          gridData.forEach(entity => {
            otherEntities = otherEntities.filter(otherEntity => otherEntity.id !== entity.id);
          });
          otherEntities.forEach(this.selectItem);
        } else {
          gridData.forEach(entity => {
            delete this.selectedItems[entity.id];
          });
        }
      } else if (!isSearching) {
        this.selectedItems = {};
      } else {
        const prevState = this.treePermissions.get("_selectAllState") === "checked" ? "unchecked" : "checked";
        this.treePermissions.set("_selectAllState", prevState);
      }
      const selectedArr = Object.values(this.selectedItems);
      this.binList.set("data", selectedArr);
      T1.EventHub.publish("binGridChanged:updatePermissions", {
        permissionedEntities: selectedArr
      });
    },
    selectDataFetch: function () {
      switch (this.dealOwner.type.toLowerCase()) {
        case DEAL_OWNER_TYPE.organization:
          return this.fetchAgencies();
        case DEAL_OWNER_TYPE.agency:
          return this.fetchAdvertisers("", false, this.dealOwner.id, false);
        case DEAL_OWNER_TYPE.advertiser:
          return this.fetchSingleAdvertiser(this.dealOwner.id);
      }
    },
    fetchAdvertisers: function (searchTerm, more, agencyId, isAdSearch) {
      var deferred = T1UtilsAsync.makeDeferral();
      var orgs = Organizations.getOrganizations();
      var fetchOptions = {
        full: "*",
        with: "agency"
      };
      if (!this.advertisers || isAdSearch) {
        this.advertisers = new Advertisers(false, {
          fetchOptions: fetchOptions,
          pageLimit: 100,
          isPaginated: true
        });
      }
      if (isAdSearch) {
        this.advertisers.urlString = `advertisers/limit/agency.organization=${orgs.selected}`;
      } else {
        this.advertisers.urlString = `advertisers/limit/agency=${agencyId}`;
      }
      if (searchTerm && searchTerm !== "") {
        this.advertisers.search.set({
          params: [{
            term: `*${searchTerm}*`,
            searchField: "name"
          }]
        });
      } else if (searchTerm === "") {
        this.advertisers.search.clear();
      }
      const fetchFunc = more ? this.advertisers.fetchMore : this.advertisers.fetch;
      fetchFunc.call(this.advertisers, {
        success: data => {
          this.advertiserList = data.models;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    fetchAgencies: function (searchTerm) {
      var deferred = T1UtilsAsync.makeDeferral();
      var orgs = Organizations.getOrganizations();
      this.agencies = new Agencies(false, {
        pageLimit: 100,
        isPaginated: true,
        fetchOptions: {
          with: ["advertisers"]
        }
      });
      this.agencies.urlString = `agencies/limit/organization=${orgs.selected}`;
      if (searchTerm && searchTerm !== "") {
        this.agencies.search.set({
          params: [{
            term: `*${searchTerm}*`,
            searchField: "name"
          }]
        });
      } else if (searchTerm === "") {
        this.agencies.search.clear();
      }
      this.agencies.fetchAllPages({
        success: data => {
          this.agencyList = data.models;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    fetchSingleAdvertiser: function (id) {
      var deferred = T1UtilsAsync.makeDeferral();
      var ownerID = id || this.deal.owner.id;
      var advertiser = new Advertiser({
        id: ownerID
      });
      advertiser.fetch({
        params: {
          with: ["agency", "organization"]
        },
        success: data => {
          this.singleAdObject = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    selectItem: function (item) {
      const selectedItem = Object.assign({}, item);
      const itemType = selectedItem.type;
      const binLabelIncluded = this.binList.grouped.some(group => group.label === itemType);
      if (!binLabelIncluded) {
        this.binList.grouped.push({
          label: itemType,
          entity: itemType
        });
      }
      selectedItem.included = true;
      selectedItem.selected = true;
      selectedItem.selectedState = "checked";
      selectedItem.entity = itemType;
      this.selectedItems[selectedItem.id] = selectedItem;
    },
    hideSelectAll: function () {
      this.treePermissions.disableSelectAll = true;
      this.el.find("#selectAll").hide();
    },
    checkSelectedState: function (entity) {
      entity.selected = Boolean(this.selectedItems[entity.id]) || Boolean(this.selectedItems[this.dealOwner.id]);
      entity.selectedState = entity.selected ? "checked" : "unchecked";
    },
    populateGrid: function (collection, searchTerm, isSearch, setAllSelected) {
      let formattedData, isAdvertiserOwnedAndPermissioned, isAgencyOwnedAndPermissioned, isOrganizationSelected;
      const entityGrid = this.treePermissions;
      const pagingInfo = {};
      if (collection && !isSearch) {
        ({
          formattedData: formattedData,
          isAgencyOwnedAndPermissioned: isAgencyOwnedAndPermissioned,
          isAdvertiserOwnedAndPermissioned: isAdvertiserOwnedAndPermissioned
        } = this.formatDealData(collection, pagingInfo));
      } else if (isSearch && searchTerm !== "") {
        formattedData = this.formatSearchData(collection, searchTerm);
      } else if (isSearch && searchTerm === "") {
        formattedData = collection;
      }
      if (!this.firstCall < 1 || this.isEditingDeal) {
        formattedData.forEach(entity => {
          this.checkSelectedState(entity);
          if (entity.children && entity.children.length) {
            entity.children.forEach(child => {
              if (entity.selected) {
                child.selected = true;
                child.selectedState = "checked";
              } else {
                this.checkSelectedState(child);
              }
            });
          }
        });
      }
      entityGrid.set("data", formattedData);
      if (this.dealOwner.type.toLowerCase() === DEAL_OWNER_TYPE.advertiser && formattedData[0].selected) {
        setAllSelected = true;
      }
      if (setAllSelected) {
        entityGrid.set("_selectAllState", "checked");
        entityGrid.data.forEach(function (item, i) {
          entityGrid._setSelection(item, "checked", `data.${i}`);
        }, entityGrid);
      }
      entityGrid.set("pagingInfo", pagingInfo);
      entityGrid.searchTerm = searchTerm || "";
      if (!isSearch) {
        entityGrid.selected.forEach(this.selectItem);
      }
      if (this.isEditingDeal && this.dealOwnerType === DEAL_OWNER_TYPE.organization) {
        if (this.firstCall < 1) {
          isOrganizationSelected = this.selectedOrgs.length;
        } else {
          isOrganizationSelected = Boolean(this.selectedItems[this.dealOwner.id]);
        }
      }
      if (setAllSelected || isOrganizationSelected || isAgencyOwnedAndPermissioned || isAdvertiserOwnedAndPermissioned) {
        entityGrid.set("_selectAllState", "checked");
        this.selectedItems = {};
        this.selectItem(this.dealOwner);
      }
      this.binList.set("data", Object.values(this.selectedItems));
    },
    setPermissionedDeals: function () {
      function mapId(entity, data) {
        return data.map(id => ({
          id: id,
          type: entity,
          entity: entity,
          included: true,
          selected: true,
          selectedState: "checked"
        }));
      }
      if (this.isEditingDeal && !this.selected) {
        const permissions = this.deal.permissions;
        this.selectedOrgs = mapId(DEAL_OWNER_TYPE.organization, permissions.organization_ids);
        this.selectedAgencies = mapId(DEAL_OWNER_TYPE.agency, permissions.agency_ids);
        this.selectedAdvertisers = mapId(DEAL_OWNER_TYPE.advertiser, permissions.advertiser_ids);
        this.selected = [...this.selectedOrgs, ...this.selectedAgencies, ...this.selectedAdvertisers];
      }
      this.selected.forEach(entity => {
        this.selectedItems[entity.id] = entity;
      });
    },
    formatDealData: function (collection, pagingInfo) {
      const disableSelect = false;
      const entityGrid = this.treePermissions;
      let selectedState = this.selectedOrgs && this.selectedOrgs.length > 0 ? "checked" : "unchecked";
      let dataToParse, isAdvertiserOwnedAndPermissioned, isAgencyOwnedAndPermissioned;
      let formattedData = [];
      if (this.isEditingDeal && selectedState === "unchecked") {
        isAdvertiserOwnedAndPermissioned = this.dealOwner.type.toLowerCase() === DEAL_OWNER_TYPE.advertiser && this.selected[0] && this.selected[0].type === DEAL_OWNER_TYPE.advertiser;
        isAgencyOwnedAndPermissioned = this.dealOwner.type.toLowerCase() === DEAL_OWNER_TYPE.agency && this.selected[0] && this.selected[0].type === DEAL_OWNER_TYPE.agency;
        if (isAdvertiserOwnedAndPermissioned || isAgencyOwnedAndPermissioned) {
          selectedState = "checked";
        }
      }
      dataToParse = collection.toJSON ? collection.toJSON() : collection;
      if (!(dataToParse instanceof Array)) {
        dataToParse = [dataToParse];
      }
      formattedData = dataToParse.map(item => {
        var id = item.id;
        var name = item.name;
        var type = item.type;
        const newSelectedState = entityGrid._selectAllState === "checked" ? "checked" : selectedState;
        var mappedItem = {
          children: type !== "advertiser" ? [] : null,
          id: id,
          name: name,
          type: type,
          loading: false,
          selectedState: newSelectedState,
          disableSelect: disableSelect
        };
        if (item.advertiser) {
          mappedItem.children = T1.Utils.objectTOArray(item.advertiser);
          mappedItem.children.forEach(advertiser => {
            advertiser.loading = false;
            advertiser.type = "advertiser";
            advertiser.selectedState = newSelectedState;
            advertiser.agencyName = mappedItem.name;
            advertiser.disableSelect = disableSelect;
          });
        } else {
          mappedItem.agencyName = this.dealOwner.name;
        }
        return mappedItem;
      });
      const isOrgOwner = this.dealOwner.type === DEAL_OWNER_TYPE.organization;
      if (isOrgOwner) {
        this.agencyList = formattedData;
      } else {
        this.advertiserList = formattedData;
      }
      pagingInfo.count = collection.count;
      pagingInfo.length = collection.length;
      pagingInfo.page = collection.page;
      pagingInfo.pageLimit = collection.pageLimit;
      return {
        formattedData: formattedData,
        isAgencyOwnedAndPermissioned: isAgencyOwnedAndPermissioned,
        isAdvertiserOwnedAndPermissioned: isAdvertiserOwnedAndPermissioned
      };
    },
    formatSearchData: function (collection, searchTerm) {
      let childrenList;
      return collection.map(function (node) {
        if (node.children) {
          childrenList = node.children.filter(function (childNode) {
            return childNode.name.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
        return Object.assign({}, node, {
          name: node.name,
          id: node.id,
          type: node.type,
          selectedState: node.selectedState,
          expanded: childrenList ? childrenList.length > 0 : false
        });
      });
    },
    loadTreeSearch: function () {
      var treeSearchInput = this.el.find(".search-tree");
      var searchValue = treeSearchInput[0].value || "";
      var treeWarning = this.el.find(".search-warning-ad-agency-org");
      var fetchList = [];
      var concatValue;
      searchValue = searchValue.trim().length === 0 ? "" : searchValue;
      if (searchValue.length < MIN_SEARCH_INPUT && searchValue.length > 0) {
        treeWarning.removeAttr("hidden");
        treeSearchInput.attr("error", "");
      } else if (this.dealOwner.type === DEAL_OWNER_TYPE.organization) {
        treeWarning.attr("hidden", "");
        treeSearchInput.removeAttr("error");
        this.agencyList.forEach(agency => {
          if (agency.selectedState === "checked" && this.getAdvertiserCheckState(agency, false)) {
            agency.selectedState = "partial";
          } else if (agency.selectedState === "partial" && this.getAdvertiserCheckState(agency, true)) {
            agency.selectedState = "unchecked";
          }
        });
        fetchList = this.agencyList.reduce(function (acc, value) {
          if (value.name.toLowerCase().includes(searchValue.toLowerCase())) {
            concatValue = value;
          } else {
            concatValue = value.children.filter(function (obj) {
              return obj.name.toLowerCase().includes(searchValue.toLowerCase());
            }).length > 0 ? value : null;
          }
          return acc.concat(concatValue);
        }, []).filter(Boolean);
        if (searchValue === "") {
          this.populateGrid(this.agencyList, searchValue, true);
        } else {
          this.populateGrid(fetchList, searchValue, true);
        }
      } else {
        treeWarning.attr("hidden", "");
        treeSearchInput.removeAttr("error");
        fetchList = this.advertiserList.filter(function (value) {
          return value.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        const {
          selectedItems: selectedItems
        } = this;
        fetchList.forEach(adv => {
          adv.selectedState = selectedItems[this.dealOwner.id] || selectedItems[adv.id] ? "checked" : "unchecked";
        });
        if (searchValue === "") {
          this.populateGrid(this.advertiserList, searchValue, true);
        } else {
          this.populateGrid(fetchList, searchValue, true);
        }
      }
    },
    getAdvertiserCheckState: (agency, isAll) => {
      const fn = isAll ? "every" : "some";
      return agency.children[fn](advertiser => advertiser.selectedState === "unchecked");
    },
    render: function () {
      const deferred = T1UtilsAsync.makeDeferral();
      Polymer.dom(this.el[0]).innerHTML = template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise;
    }
  });
  return binGrid;
});
