define('modules/campaign/bulkedit/creatives/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!modules/campaign/bulkedit/creatives/templates/bin_title.html", "text!../templates/bin_item.html", "text!../templates/bin_header.html", "text!../templates/bin_footer.html", "text!../templates/bin_overall_header.html"], function ($, _, T1, BinsView, bin_title, bin_item, bin_header, bin_footer, bin_overall_header) {
  "use strict";

  const bulkEditActions = Object.freeze(["add", "remove", "overwrite"]);
  const twoDigits = 2;
  return BinsView.extend({
    type: "concepts",
    configsOverride: true,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: false,
        count: 0,
        use_or: true,
        hasFooter: true
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_item: bin_item,
      bin_footer: bin_footer,
      overall_header: bin_overall_header
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDraggable: false,
      isDroppable: true,
      onDropEvent: "bins:droppedBulkEditConcept"
    },
    creativesCollections: {},
    initialize: function ({
      sharedCollection: sharedCollection,
      strategy: strategy
    }) {
      this.sharedCollection = sharedCollection;
      this.strategy = strategy;
    },
    events: {
      "keyup .weighting-input": "weightingChanged",
      "changed .weighting-type": "typeChanged",
      "changed #edit-options": "onEditOptionsChange"
    },
    dataEvents: {
      sharedCollection: {
        reset: "render"
      }
    },
    eventhubEvents: {
      "strategyBulkEditConcepts:add-remove": function (args) {
        if (args) {
          const model = this.sharedCollection.get(args.item.id);
          const dropdownVal = this.$(".weighting-type")[0].value;
          if (args.wasAdded && model) {
            if (dropdownVal === "No Weighting") {
              if (model.has("weighting")) {
                model.unset("weighting");
                if (model.has("percent")) {
                  model.unset("percent");
                }
              }
            } else {
              model.set({
                weighting: dropdownVal.toUpperCase()
              });
              if (this.sharedCollection.length === 1) {
                this.toggleWeighting(false);
              }
            }
          }
        }
        this.checkWeighting();
      },
      "strategyBulkEditConcepts:save": "buildPostObj"
    },
    weightingChanged: function (e) {
      this.buildPostObj();
      this.checkWeighting();
      e.preventDefault();
    },
    buildPostObj: function () {
      const $inputs = this.$(".weighting-input");
      const sharedCollection = this.sharedCollection;
      const json = sharedCollection.toJSON();
      const bulkEditOptions = sharedCollection.bulkeditAction;
      for (let i = 0; i < json.length; i++) {
        const model = sharedCollection.get(json[i].id);
        if (!$inputs.length || bulkEditOptions === "add" || bulkEditOptions === "remove") {
          model.unset("percent");
        } else {
          const val = $inputs[i].value === "" ? 0 : parseFloat($inputs[i].value);
          model.set({
            percent: val.toFixed(twoDigits)
          });
        }
      }
    },
    toggleWeighting: function (hide) {
      const $weightingEls = this.$(".weighting-percentage");
      if (hide) {
        $weightingEls.addClass("no-weighting");
      } else {
        $weightingEls.removeClass("no-weighting");
      }
    },
    disableWeighting: function (disable) {
      const $weightingEls = this.$(".weighting-type");
      if (disable) {
        $weightingEls[0].disabled = true;
        $weightingEls[0].value = "No Weighting";
      } else {
        $weightingEls[0].disabled = false;
      }
    },
    onEditOptionsChange: function (event) {
      var value = event.currentTarget.value;
      if (bulkEditActions.includes(value)) {
        this.sharedCollection.bulkeditAction = value;
      }
      T1.EventHub.publish("strategyBulkEditConcepts:bulkeditActionChanged");
      if (value === "add" || value === "remove") {
        this.disableWeighting(true);
        this.toggleWeighting(true);
        this.updateBinFooter();
        this.sharedCollection.models.forEach(function (model) {
          if (model.has("weighting")) {
            model.unset("weighting");
          }
        });
      } else {
        this.checkWeighting();
      }
    },
    typeChanged: function (e) {
      let model;
      var type = $(e.currentTarget)[0].value;
      var json = this.sharedCollection.toJSON();
      const $inputs = this.$(".weighting-input");
      if (type === "No Weighting" || type === "None") {
        this.toggleWeighting(true);
      } else {
        this.toggleWeighting(false);
      }
      for (let i = 0; i < json.length; i++) {
        model = this.sharedCollection.get(json[i].id);
        model.set({
          weighting: type.toUpperCase()
        });
        if ($inputs.length) {
          $inputs[i].value = json[i].percent;
        }
      }
      this.checkWeighting();
      this.updateBins(model);
    },
    checkWeighting: function () {
      var dropdown = document.querySelector(".weighting-type");
      var type = dropdown ? dropdown.value : null;
      var strategyId = "1078276";
      var validationResult = this.validateStrategyConcepts(type, strategyId);
      var json = this.sharedCollection.toJSON();
      if (json.length && this.sharedCollection.bulkeditAction === "overwrite") {
        this.updateBinFooter(validationResult.total);
        this.disableWeighting(false);
      } else {
        this.updateBinFooter();
        this.toggleWeighting(true);
        this.disableWeighting(true);
      }
    },
    selectBulkEditOptions: function () {
      var bulkEditAction = this.sharedCollection.bulkeditAction;
      const $bulkEditGroup = this.$("#edit-options");
      $bulkEditGroup.find(`[value=${bulkEditAction}]`)[0].checked = true;
    },
    load: function () {
      var json = this.sharedCollection.toJSON();
      this.render().then(() => {
        this.$(".drop-text").html("Select concepts to bulk apply");
        this.el.trigger("binsContent:loaded");
        if (!this.sharedCollection.bulkeditAction || !this.sharedCollection.length) {
          this.sharedCollection.bulkeditAction = "add";
        }
        this.selectBulkEditOptions();
        const $dropdown = this.$(".weighting-type")[0];
        if (json.length && $dropdown) {
          let weighting = json[0].weighting ? json[0].weighting : " ";
          weighting = weighting.indexOf(" ") >= 0 ? "No Weighting" : weighting.charAt(0) + weighting.substring(1).toLowerCase();
          $dropdown.value = weighting || "No Weighting";
        } else {
          $dropdown.value = "No Weighting";
        }
        if ($dropdown.value === "No Weighting") {
          this.toggleWeighting(true);
        }
        T1.Form.Masks.attach($("input"));
        this.checkWeighting();
        setTimeout(function () {
          T1.EventHub.publish("resetIsFormElementsChanged:strategy-creatives");
        }, 0);
      });
    },
    updateBinDDText: function () {
      if (!this.sharedCollection.length) {
        this.$(".drop-text").text("Select concepts to bulk apply");
      }
    },
    updateBinFooter: function (percent) {
      if (percent !== undefined) {
        $(".total-weight").text(`${percent}%`);
        $(".footer-title").text("Total Weight");
      } else {
        $(".total-weight").text("");
        $(".footer-title").text("");
      }
    },
    updateBins: function () {
      this.updateBinDDText();
    },
    validateStrategyConcepts: function (type) {
      var total = 0;
      const fullBudget = 100;
      const errorMsg = `This strategy will not run until the concept weighting adds up to 100% of\n        budget or impressions.`;
      var json = this.sharedCollection.toJSON();
      if (type === "No Weighting" || type === "None") {
        return {
          error: false
        };
      }
      for (let i = 0; i < json.length; i++) {
        const concept = json[i];
        const savedPercent = concept && concept.percent;
        const percent = json[i].percent !== undefined ? json[i].percent : savedPercent;
        const val = parseFloat(percent) || 0;
        total += val;
      }
      total = parseFloat(total.toFixed(twoDigits));
      let result;
      if (total !== fullBudget) {
        result = {
          error: true,
          message: errorMsg,
          total: total
        };
      } else {
        result = {
          error: false,
          total: total
        };
      }
      return result;
    },
    removeItem: function (event) {
      const id = `${$(event.currentTarget).closest(".item").data("id")}`;
      const itemInSharedCollection = this.sharedCollection.get(id);
      if (itemInSharedCollection) {
        this.sharedCollection.remove(itemInSharedCollection);
      }
      T1.EventHub.publish("strategyBulkEditConcepts:add-remove");
      this.preventDefault(event);
    },
    mapItems: function () {
      var coll = this.sharedCollection;
      var strategyId = "1078276";
      var json = coll.toJSON();
      function getStrategyConceptData(item) {
        var concept;
        if (item.strategy_concept) {
          concept = coll.getStrategyConcept(strategyId, item.strategy_concept);
        }
        const savedPercent = concept && concept.percent;
        const weighting = item.weighting ? item.weighting : concept && concept.weighting;
        const percent = item.percent ? item.percent : savedPercent;
        item.noWeighting = weighting ? weighting === "NONE" || weighting === "NO WEIGHTING" : true;
        item.percent = percent ? percent : "0.00";
        item.isInactive = item.status === "0";
      }
      for (let i = 0; i < json.length; i++) {
        getStrategyConceptData(json[i]);
      }
      return json;
    }
  });
});
