define('modules/creatives/componentBased/main',["T1Module"], function (T1Module) {
  "use strict";

  var componentBasedTab = new T1Module({
    name: "creatives/componentBased",
    viewCfgs: {
      list: {},
      columnSelector: {
        model: null
      },
      createEditBulk: {},
      createEditBulkTableReview: {},
      createEditBulkTableSubmit: {}
    },
    defaultView: "list",
    initialize: function () {}
  });
  return componentBasedTab;
});
