define('collections/strategyAvailableConcepts',["jQuery", "T1", "T1Collection", "models/strategyConcept", "T1Sortable"], function ($, T1, T1Collection, StrategyConcept) {
  T1.Models.StrategyAvailableConcepts = T1Collection.extend({
    model: StrategyConcept,
    canCache: true,
    cacheDuration: 18e4,
    filterConfiguration: {
      filters: [{
        entity: "strategy",
        fetchOnChange: true
      }]
    },
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    fetchOptions: {
      full: "*"
    },
    url: function (params) {
      var originalString = this.urlString;
      var url;
      this.urlString = "strategies/" + this.id + "/" + originalString;
      url = T1Collection.prototype.url.call(this, params);
      this.urlString = originalString;
      return url;
    },
    urlString: "available_concepts",
    fixture: "-strategyAvailableConcepts"
  });
  return T1.Models.StrategyAvailableConcepts;
});
