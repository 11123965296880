define('modules/strategy/views/helpers/supplySummaryTarget',['require','jQuery','T1','T1Permissions'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Permissions = require("T1Permissions");
  const isSourceFeatureEnabled = T1Permissions.check("feature", "source");
  const T1hasOwnProp = T1.Utils.hasOwnProp;
  return {
    processFetchedResult: function (model) {
      let runOnAllExchanges, runOnAllPmp;
      if (model.tempSupplyInfo) {
        runOnAllPmp = model.tempSupplyInfo.run_on_all_pmp === "1";
        runOnAllExchanges = model.tempSupplyInfo.run_on_all_exchanges === "1";
      } else {
        runOnAllPmp = model.get("run_on_all_pmp") === "1";
        runOnAllExchanges = model.get("run_on_all_exchanges") === "1";
      }
      if (runOnAllPmp === true) {
        this.isAllPMP = true;
      } else if (runOnAllExchanges === true) {
        this.isAllExchanges = true;
      }
    },
    prepareForSerialize: function (model, collection, sourceType, foldPositions) {
      var codeNameHash = {
        STANDARD: [],
        MARKETPLACE: [],
        BATCH: []
      };
      const advSourceProp = model.get("campaign").get("advertiser").get("accountable_and_addressable");
      const isSourceEnabledOnAdv = advSourceProp === "1";
      var createDataBlock = function (groupedHash, titleResolverHash) {
        var dataArray = [];
        for (const key in groupedHash) {
          if (T1hasOwnProp(groupedHash, key)) {
            const itemHolderArray = [];
            const tmpArr = isSourceFeatureEnabled ? groupedHash[key] : groupedHash[key].filter(item => item !== "Rubicon SOURCE");
            if (tmpArr && tmpArr.length) {
              let tmpTitle = null;
              if (titleResolverHash) {
                tmpTitle = titleResolverHash[key];
              }
              if (!tmpTitle) {
                tmpTitle = key;
              }
              for (const listItem in tmpArr) {
                if (T1hasOwnProp(tmpArr, listItem)) {
                  itemHolderArray.push({
                    action: "include",
                    label: tmpArr[listItem]
                  });
                }
              }
              dataArray.push({
                header: tmpTitle,
                items: itemHolderArray
              });
            }
          }
        }
        return dataArray;
      };
      var createBlockForSiteAndDeal = function (items, obj, supplyType) {
        var dataArray = [];
        for (const key in items) {
          if (T1hasOwnProp(items, key)) {
            dataArray.push({
              action: obj.length ? "include" : "none",
              label: items[key].name
            });
          }
        }
        return {
          header: supplyType,
          items: dataArray
        };
      };
      var createBlockForSiteAndDealGroup = function (items, obj) {
        var dataArray = [];
        items.forEach(dealGroup => {
          dataArray.push({
            action: obj.length ? "include" : "none",
            label: dealGroup.name
          });
        });
        return {
          header: "Deal Groups",
          items: dataArray
        };
      };
      const getFoldPositionData = function (collObjDims) {
        if (collObjDims) {
          const targetList = [{
            id: 45054,
            txt: "Above"
          }, {
            id: 45055,
            txt: "Below"
          }, {
            id: 45056,
            txt: "Uncategorized"
          }];
          const list = [];
          $.each(targetList, function (index, target) {
            if (collObjDims.get(target.id)) {
              list.push({
                action: "include",
                label: target.txt
              });
            }
          });
          if (list.length) {
            return {
              header: "Fold Position",
              items: list
            };
          }
        }
        return null;
      };
      var openSupplyTypeRef = {
        MARKETPLACE: "Marketplaces",
        STANDARD: "Open Exchanges & SSPs",
        BATCH: "Facebook"
      };
      const dataMain = {
        data: []
      };
      let deals = [];
      const deal_groups = [];
      const site_placements = [];
      let supply_sources = [];
      if (model.get("supply_type") === "RMX_API") {
        dataMain.data.push({
          items: [{
            label: "External Media Tracking",
            action: "none"
          }]
        });
        return dataMain;
      }
      const collObj = collection;
      const collObjDims = foldPositions;
      $.each(collObj.models, function (index, collModel) {
        const type = collModel.get("type") || collModel.get("entity_type");
        if (type === "deal") {
          deals.push({
            name: collModel.get("name"),
            deal_id: collModel.get("deal_identifier"),
            price: collModel.get("price"),
            price_type: collModel.get("price_type")
          });
        } else if (type === "deal_group") {
          deal_groups.push({
            name: collModel.get("name"),
            deal_group_id: collModel.get("id")
          });
        } else if (type === "site_placement") {
          site_placements.push({
            name: collModel.get("display_text"),
            publisher: collModel.get("name"),
            site: collModel.get("publisher_site_id")
          });
        } else {
          const tmpArr = codeNameHash[collModel.get("rtb_type")];
          if (tmpArr) {
            if (isSourceFeatureEnabled && isSourceEnabledOnAdv || collModel.get("accountable_and_addressable") !== "1") {
              tmpArr.push(collModel.get("name"));
            }
          }
        }
      });
      if (model.tempSupplyInfo) {
        if (model.tempSupplyInfo.run_on_all_pmp === "1") {
          deals = [{
            name: "Run on all deals"
          }];
        }
      } else {
        if (model.get("run_on_all_exchanges") === "1") {
          supply_sources = [{
            name: "Run on all Open supply sources"
          }];
        }
        if (model.get("run_on_all_pmp") === "1") {
          deals = [{
            name: "Run on all deals"
          }];
        }
      }
      const exchangeSupplyType = model.get("exchange_type_for_run_on_all");
      const isRunOnAllExchanges = model.get("run_on_all_exchanges") === "1";
      if (isRunOnAllExchanges) {
        if (isSourceFeatureEnabled && isSourceEnabledOnAdv && exchangeSupplyType === "SOURCE") {
          supply_sources = [{
            name: "Run on all SOURCE exchanges"
          }];
        } else {
          supply_sources = [{
            name: "Run on all Open supply sources"
          }];
        }
      }
      if (!$.isEmptyObject(foldPositions)) {
        foldPositions = getFoldPositionData(collObjDims);
      } else {
        foldPositions = false;
      }
      if (supply_sources.length) {
        dataMain.data.push(createBlockForSiteAndDeal(supply_sources, collObj, "Open Supply"));
      } else {
        dataMain.data = [...dataMain.data, ...createDataBlock(codeNameHash, openSupplyTypeRef)];
      }
      if (deals.length) {
        dataMain.data.push(createBlockForSiteAndDeal(deals, collObj, "Deals"));
      }
      if (deal_groups.length) {
        dataMain.data.push(createBlockForSiteAndDealGroup(deal_groups, collObj, "Deal Groups"));
      }
      if (site_placements.length) {
        dataMain.data.push(createBlockForSiteAndDeal(site_placements, collObj, "Private Marketplace Direct"));
      }
      if (foldPositions) {
        dataMain.data.push(foldPositions);
      }
      return dataMain;
    }
  };
});
