define('modules/admin/views/date_picker',['require','jQuery','moment','T1','T1View','text!../templates/date_picker.html','T1DatePicker','T1Timezones'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const moment = require("moment");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/date_picker.html");
  const T1DatePicker = require("T1DatePicker");
  const T1Timezones = require("T1Timezones");
  var Date = T1.Date;
  return T1View.extend({
    template: template,
    defaults: {
      dateFormat: "YYYY-MM-DDTHH:mm:ss",
      displayFormat: "MMMM D, YYYY",
      endDate: null,
      isEndDisabled: false,
      isStartDisabled: false,
      onChanged: $.noop,
      positionBy: "bottomTop",
      startDate: null,
      startEndOrderError: "Please make sure your selected END DATE is after START DATE.",
      utc: true
    },
    getDefaultStartDate(inputDate) {
      const oneHour = 60;
      var date = inputDate || moment();
      var zoneOffset = date.zone();
      var defaultStartDate = moment.utc(date.format()).add(oneHour - zoneOffset, "minutes").startOf("hour");
      return defaultStartDate;
    },
    initialize(args) {
      $.extend(this, this.defaults, args);
      this.defaultStartDate = this.defaultStartDate || this.getDefaultStartDate();
      this.defaultEndDate = this.defaultEndDate || this.defaultStartDate.clone().add(1, "month");
      this.startDate = this.startDate || this.defaultStartDate;
      this.endDate = this.endDate || this.defaultEndDate;
      this.startDate = this.utc ? moment.utc(this.startDate) : moment(this.startDate);
      this.endDate = this.utc ? moment.utc(this.endDate) : moment(this.endDate);
    },
    load() {
      this.render().then(() => {
        this.attachDatePicker();
      });
    },
    attachDatePicker() {
      this.datePicker = T1.DoubleDatePicker({
        chooseTime: false,
        chooseTimezone: false,
        endDisabled: () => this.isEndDisabled,
        endMinDate: () => Date.parse("today"),
        endTimeEditable: () => !this.isEndDisabled,
        getEndDate: () => this.endDate.format(),
        getStartDate: () => this.startDate.format(),
        lowerBounds: Date.parse("today"),
        positionBy: this.positionBy,
        startDisabled: () => this.isStartDisabled,
        startTimeEditable: () => !this.isStartDisabled,
        topOffset: 25,
        zoneName: () => this.zoneName || "",
        onOpen: () => {
          const topIndex = 9999;
          $(".cluetip-datepicker").css("z-index", topIndex);
        },
        onSave: o => {
          var saveValues = o.data.saveValues;
          var newStartDate = moment.utc(saveValues.start.date);
          var newEndDate = moment.utc(saveValues.end.date);
          if (newStartDate.isAfter(newEndDate)) {
            return o.error({
              message: this.startEndOrderError
            });
          }
          this.startDate = newStartDate;
          this.endDate = newEndDate;
          this.update(true);
          o.success();
          return true;
        }
      }, this.el);
    },
    getDates(format) {
      return {
        start: this.startDate.format(format),
        end: this.endDate.format(format)
      };
    },
    update(render) {
      var dates = this.getDates(this.dateFormat);
      this.onChanged(dates);
      if (render) {
        this.load();
      }
    },
    serialize() {
      var dates = this.getDates(this.dateFormat);
      var displayDates = this.getDates(this.displayFormat);
      return {
        dates: dates,
        displayDates: displayDates,
        startDataBind: this.startDataBind,
        endDataBind: this.endDataBind,
        isEnabled: this.isEnabled,
        timezone: this.utc ? "Campaign" : "Local"
      };
    }
  });
});
