define('modules/audiences/segments/export/views/export',["jQuery", "Underscore", "Backbone", "Hogan", "Mustache", "T1", "T1Menu", "T1Permissions", "models/segmentExport", "collections/organizations", "modules/audiences/segments/segmentUtils", "text!modules/audiences/segments/export/templates/export.html", "text!modules/audiences/segments/behavior/templates/columnSelector.html"], function ($, _, Backbone, Hogan, Mustache, T1, T1Menu, T1Permissions, Export, Organizations, Util, template, ColumnSelector) {
  "use strict";

  var SEGMENTS_URL = "audiences/segments/";
  var statusList = [{
    statusValue: "active",
    text: "Active"
  }, {
    statusValue: "pending",
    text: "Pending"
  }, {
    statusValue: "expired",
    text: "Expired"
  }, {
    statusValue: "not eligible",
    text: "Not Eligible"
  }];
  var statusIcon = {
    active: {
      icon: "success",
      color: "#49b82d",
      tooltip: "Actively exported segment"
    },
    pending: {
      icon: "clock",
      color: "#FFD700",
      tooltip: "New segment or refreshed segment awaiting processing"
    },
    expired: {
      icon: "pending",
      color: "#a6a5a5",
      tooltip: "Segment has reached expiration or has been canceled"
    },
    "not eligible": {
      icon: "exclude",
      color: "#a6a5a5",
      tooltip: "Segment must contain at least 1000 users for export"
    }
  };
  var actionsByStatus = {
    active: [{
      actionText: "Extend Export",
      action: "extend"
    }, {
      actionText: "Cancel Export",
      action: "cancel"
    }],
    pending: [{
      actionText: "Extend Export",
      action: "extend"
    }, {
      actionText: "Cancel Export",
      action: "cancel"
    }],
    expired: [{
      actionText: "Refresh Export",
      action: "refresh"
    }],
    "not eligible": [{
      actionText: "Extend Export",
      action: "extend"
    }, {
      actionText: "Cancel Export",
      action: "cancel"
    }]
  };
  var exportView = Backbone.View.extend({
    template: template,
    events: {
      "changed .column-selector-checkbox": "columnChange",
      "changed #select-all-columns": "selectAllColumns",
      "click .add-export": "routeToExportCreate",
      "changed .segments-search": "handleSearch",
      "changed .filter-status": "filterByStatus",
      "click .export-actions strand-list-item": "handleExportAction",
      "click .extend-button": "extendExport",
      "click .cancel-extend": "cancelExtendExport"
    },
    initialize: function () {
      var compiledTemplate;
      var pathPieces = [{
        label: "Manage Segments",
        location: "#audiences/segments"
      }, {
        label: "Manage Exports",
        location: "#audiences/segments/export/"
      }];
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
      this.boundReload = this.reload.bind(this);
      T1.EventHub.subscribe("organization:select", this.boundReload);
      compiledTemplate = Hogan.compile(this.template);
      this.renderedTemplate = compiledTemplate.render({
        model: "{{model}}",
        scope: "{{scope}}",
        statusIcon: "{{model.status_icon}}",
        statusColor: "{{model.status_color}}",
        statusTooltip: "{{model.status_tooltip}}",
        exportId: "{{model.export_id}}",
        target: '{{findById("export-settings")}}',
        tooltipTarget: '{{findById("status-icon")}}',
        data: "{{data}}",
        page: "{{page}}",
        search: "{{model.search}}",
        noSearch: "{{!model.search}}",
        highlightName: "{{model.highlightName}}",
        highlightId: "{{model.highlightId}}",
        highlightClass: "{{item.highlightClass}}",
        partialName: "{{item.partialName}}",
        segmentName: "{{model.segment_name}}",
        searchSegmentOwnerName: "{{model.searchSegmentOwnerName}}",
        noSearchSegmentOwnerName: "{{!model.searchSegmentOwnerName}}",
        highlightSegmentOwnerName: "{{model.highlightSegmentOwnerName}}",
        segmentOwnerName: "{{model.segment_owner_name}}",
        statusValue: "{{item.statusValue}}",
        text: "{{text}}",
        status: "{{model.status}}",
        statusList: statusList,
        actions: "{{model.actions}}",
        action: "{{item.action}}",
        actionText: "{{item.actionText}}"
      });
    },
    load: function () {
      var self = this;
      var statusDropdown;
      this.render().then(function () {
        if (T1Permissions.check("feature", "segments_export_toggle")) {
          Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
            self.setupGrid();
            self.initColumns();
            statusDropdown = self.el[0].querySelector(".filter-status");
            statusDropdown.value = "any";
            self.orgId = orgId;
            self.exportsCollection.orgId = orgId;
            self.exportsCollection.sourceUrl = T1.SEGMENT_API_BASE + "exports/segments";
            self.exportsCollection.search = "";
            self.exportsCollection.searchOptions = [];
          }));
        } else {
          self.$el.find(".segment-message").show();
        }
      });
    },
    render: function () {
      var deferred = $.Deferred();
      if (T1.Utils.hasPolymer(this.el[0])) {
        Polymer.dom(this.el[0]).innerHTML = this.renderedTemplate;
      } else {
        this.el.html(this.renderedTemplate);
      }
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    setupGrid: function () {
      var self = this;
      self.grid = this.el[0].querySelector("#exports-list");
      self.exportsCollection = this.el[0].querySelector("#exports-collection");
      self.exportsCollection.addEventListener("seg-collection:call", function () {
        self.grid.isLoading = true;
      });
      self.exportsCollection.addEventListener("seg-collection:load", function () {
        self.grid.isLoading = false;
        self.enableMenus();
      });
      self.exportsCollection.addEventListener("seg-collection:noResultsForExportName", function () {
        self.exportsCollection.search = self.searchTerm;
        self.exportsCollection.searchOptions = {
          type: "segment_owner_name",
          name: self.searchTerm
        };
      });
      self.exportsCollection.addEventListener("seg-collection:noResultsForExportID", function () {
        self.exportsCollection.search = "";
        self.exportsCollection.searchOptions = {
          type: "segment_id",
          segment_id: self.searchTerm
        };
      });
      self.exportsCollection.addEventListener("seg-collection:noResults", function (msg) {
        self.grid.noResultsMessage = msg.detail;
      });
      self.exportsCollection.addEventListener("seg-collection:errors", function () {
        self.setFooter("Internal Error. Please contact T1 Support.", "error");
      });
    },
    initColumns: function () {
      var temp = ColumnSelector;
      this.savedExportsGrid = this.el.find("#exports-list")[0];
      this.savedExportsGridColumns = this.savedExportsGrid.getAllColumns();
      this.savedExportsGridSelectableColumns = this.savedExportsGridColumns.filter(function (column) {
        return column.classList.contains("selectable");
      });
      var $dropDown = $(".grid-column-selector");
      var data = this.savedExportsGridSelectableColumns.map(function (column) {
        return {
          name: column.label,
          id: column.field
        };
      });
      var compiledTemplate = Mustache.to_html(temp, {
        columns: data
      });
      $dropDown.append(compiledTemplate);
    },
    handleSearch: function (e) {
      var populateSearch;
      var self = this;
      this.searchTerm = e.currentTarget.value;
      this.disableMenus();
      this.grid.isLoading = true;
      clearTimeout(this.typingTimer);
      populateSearch = function () {
        var searchOptions = {};
        var status = document.querySelector(".filter-status").value;
        if (self.searchTerm) {
          if (isNaN(self.searchTerm)) {
            searchOptions.type = "name";
            searchOptions.name = self.searchTerm;
          } else {
            searchOptions.type = "id";
            searchOptions.id = self.searchTerm;
          }
        }
        if (status && status !== "any") {
          searchOptions.statusType = status;
        }
        self.exportsCollection.search = self.searchTerm;
        self.exportsCollection.searchOptions = searchOptions;
      };
      self.typingTimer = setTimeout(populateSearch, 1e3);
    },
    filterByStatus: function (e) {
      var status = e.target.value;
      var searchOptions = {};
      this.disableMenus();
      if (this.exportsCollection.searchOptions.type) {
        searchOptions = JSON.parse(JSON.stringify(this.exportsCollection.searchOptions));
        this.exportsCollection.search = this.searchTerm;
        if (status === "any") {
          if ("statusType" in searchOptions) {
            delete searchOptions.statusType;
          }
        } else {
          searchOptions.statusType = status;
        }
      } else {
        this.exportsCollection.search = "";
        searchOptions = status === "any" ? {} : {
          statusType: status
        };
      }
      this.exportsCollection.searchOptions = searchOptions;
    },
    handleExportAction: function (event) {
      var $actionObj = $(event.target);
      var $gridRow = $actionObj.closest("strand-grid-item");
      var $menuArray = $actionObj.closest("strand-menu");
      var $menu = $menuArray[0];
      var action = $actionObj[0].textContent;
      var rowModel = $gridRow[0].model;
      var self = this;
      var extendModal, extendButton, exportModel, promise;
      if (action === "Extend Export") {
        extendModal = document.getElementById("extendActionModal");
        extendButton = document.getElementById("extend-button");
        extendButton.id = "extend-button-" + rowModel.id;
        extendModal.show();
      } else if (action === "Refresh Export") {
        this.disableOtherActions();
        this.grid.isLoading = true;
        exportModel = new Export({
          id: rowModel.id
        });
        promise = exportModel.refresh(rowModel);
        promise.done(function (response) {
          if ("errors" in response) {
            console.error("Extend Failed. ERROR: " + response.errors.join(";  "));
            self.setFooter("Refreshing export ID: " + rowModel.id + " failed. Please contact T1 Support.", "error");
          } else {
            response = response[0];
            rowModel.status_icon = statusIcon[response.status].icon;
            rowModel.status_color = statusIcon[response.status].color;
            rowModel.status_tooltip = statusIcon[response.status].tooltip;
            rowModel.actions = actionsByStatus[response.status];
            rowModel.expire_on = response.expire_on;
            rowModel.expiry = self.getExpiryDays(response.expire_on);
            rowModel.status = response.status;
            self.grid.set("data", self.grid.data.slice());
          }
          self.grid.isLoading = false;
          self.enableOtherActions();
        }).fail(function () {
          self.grid.isLoading = false;
          self.enableOtherActions();
        });
      } else if (action === "Cancel Export") {
        this.disableOtherActions();
        this.grid.isLoading = true;
        exportModel = new Export({
          id: rowModel.id
        });
        promise = exportModel.cancel();
        promise.done(function (response) {
          if ("errors" in response) {
            console.error("Refresh Failed. ERROR: " + response.errors.join(";  "));
            self.setFooter("Refreshing export ID: " + rowModel.id + " failed. Please contact T1 Support.", "error");
          } else {
            var status = "expired";
            rowModel.status_icon = statusIcon[status].icon;
            rowModel.status_color = statusIcon[status].color;
            rowModel.status_tooltip = statusIcon[status].tooltip;
            rowModel.actions = actionsByStatus[status];
            rowModel.expiry = "--";
            rowModel.status = status;
            self.grid.set("data", self.grid.data.slice());
          }
          self.grid.isLoading = false;
          self.enableOtherActions();
        }).fail(function () {
          self.grid.isLoading = false;
          self.enableOtherActions();
        });
      }
      $menu.close();
    },
    extendExport: function (event) {
      var self = this;
      var extendButton = event.target.parentElement;
      var re = /extend-button-(\d*)/;
      var idRe = extendButton.id.match(re);
      var id = idRe[1];
      var extendModal = document.getElementById("extendActionModal");
      var extendDaysObj = document.getElementById("extend-duration");
      var extendDays = extendDaysObj.value;
      var buttonText = $(extendButton).find("label").text();
      var exportModel, promise, rowModel;
      if (!extendDays) {
        extendDaysObj.error = true;
        return;
      }
      exportModel = new Export({
        id: id
      });
      rowModel = _.where(this.grid.data, {
        id: parseInt(id)
      });
      rowModel = rowModel[0];
      Util.buttonLoader(event);
      promise = exportModel.extend(rowModel.expire_on, extendDays);
      promise.done(function (response) {
        if ("errors" in response) {
          console.error("Extend Failed. ERROR: " + response.errors.join(";  "));
          self.setFooter("Extending export ID: " + rowModel.id + " failed with error message '" + response.errors.join("; ") + "'. Please contact T1 Support.", "error");
        } else {
          rowModel.expiry = self.getExpiryDays(response.expire_on);
          self.grid.set("data", self.grid.data.slice());
        }
        extendDaysObj.reset();
        Util.buttonReset(event.target, buttonText);
        extendButton.removeAttribute("disabled");
        extendButton.id = "extend-button";
        extendModal.hide();
      }).fail(function () {
        console.error("Extend Failed.");
        self.setFooter("Extending export ID: " + rowModel.id + " failed. Please contact T1 Support.", "error");
        extendDaysObj.reset();
        Util.buttonReset(event.target, buttonText);
        extendButton.removeAttribute("disabled");
        extendButton.id = "extend-button";
        extendModal.hide();
      });
    },
    cancelExtendExport: function (event) {
      var extendDaysObj = this.el[0].querySelector("#extend-duration");
      var extendButton = this.el[0].querySelector(".extend-button");
      var extendModal = this.el[0].querySelector("#extendActionModal");
      extendDaysObj.reset();
      extendButton.removeAttribute("disabled");
      extendButton.id = "extend-button";
      extendModal.hide();
      event.preventDefault();
      event.stopPropagation();
    },
    disableMenus: function () {
      $(".export-settings").addClass("disabled");
    },
    enableMenus: function () {
      $(".export-settings").removeClass("disabled");
    },
    disableOtherActions: function () {
      this.el[0].querySelector(".segments-search").disabled = true;
      this.el[0].querySelector(".filter-status").disabled = true;
      this.el[0].querySelector(".add-export").disabled = true;
      $(".export-settings").addClass("disabled");
    },
    enableOtherActions: function () {
      this.el[0].querySelector(".segments-search").removeAttribute("disabled");
      this.el[0].querySelector(".filter-status").removeAttribute("disabled");
      this.el[0].querySelector(".add-export").removeAttribute("disabled");
      $(".export-settings").removeClass("disabled");
    },
    routeToExportCreate: function () {
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
      T1.Location.set(SEGMENTS_URL + "export/create", {});
    },
    selectAllColumns: function (e) {
      var selectAll = e.target.value;
      var otherColumns = $(".column-selector-checkbox");
      if (e.target.state === "partial") {
        return;
      }
      if (selectAll) {
        otherColumns.attr("checked", "checked");
      } else {
        otherColumns.removeAttr("checked");
      }
    },
    setSelectAllState: function () {},
    columnChange: function (e) {
      var field = e.target.id;
      var self = this;
      var addColumn = e.target.checked;
      var removeColumn = !e.target.checked;
      var columnToInsertBefore;
      var gridColumn = _.findWhere(this.savedExportsGridColumns, {
        field: field
      });
      if (removeColumn) {
        this.savedExportsGrid.removeColumn(gridColumn);
      }
      if (addColumn) {
        columnToInsertBefore = findColumnToBeInsertedBefore(field);
        this.savedExportsGrid.insertBeforeColumn(gridColumn, columnToInsertBefore);
      }
      this.setSelectAllState();
      function findColumnToBeInsertedBefore(insertedColumn) {
        var originalIndex = self.savedExportsGridColumns.indexOf(_.findWhere(self.savedExportsGridColumns, {
          field: insertedColumn
        }));
        for (var i = originalIndex; i < self.savedExportsGridColumns.length; i++) {
          if (isReferenceInGrid(self.savedExportsGridColumns[i])) {
            return self.savedExportsGridColumns[i];
          }
        }
      }
      function isReferenceInGrid(column) {
        if (!column) {
          return;
        }
        return Boolean(self.savedExportsGrid.getColumnByField(column.field));
      }
    },
    getExpiryDays: function (expireOn) {
      var expiry = moment(expireOn);
      var today = moment();
      return expiry.diff(today, "days");
    },
    setFooter: function (message, type) {
      var self = this;
      var footer = document.querySelector("strand-footer");
      footer.message = message;
      footer.type = type;
      footer.showMessage();
      setTimeout(function () {
        self.clearFooter();
      }, 1e4);
    },
    clearFooter: function () {
      var footer = document.querySelector("strand-footer");
      footer.hideMessage();
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      this.remove();
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
    }
  });
  return exportView;
});
