define('modules/creatives/views/violationFilterList',['require','text!modules/creatives/templates/violationFilterSearch.html','T1FilterList'],function (require) {
  "use strict";

  const searchTemplate = require("text!modules/creatives/templates/violationFilterSearch.html");
  const T1FilterList = require("T1FilterList");
  var ViolationFilterList = T1FilterList.extend({
    partials: {
      search: searchTemplate,
      item_all: null,
      item_all_search_results: null,
      footer: null
    },
    events: {
      "click .w-FilterList .clear-btn": "searchClear"
    },
    initialize(args) {
      this.items = args && args.items;
    },
    serialize() {
      return {
        items: this.items
      };
    }
  });
  return ViolationFilterList;
});
