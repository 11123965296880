define('modules/pixel/views/select',["jQuery", "T1", "T1View", "text!modules/pixel/templates/select.html", "T1Selectbox"], function ($, T1, T1View, template) {
  return T1View.extend({
    template: template,
    selected: "",
    fetchRequired: true,
    eventhubEvents: {
      "selected:pixel": "selectPixel",
      "pixel:onEmptyCollection": function () {
        $(this.el).find(".chzn-container").find(".chzn-search > input").removeClass("search-loader");
      },
      "advertiser:select": function (args) {
        this.advertiser_id = args.id;
      },
      "duplicateCampaignAdvertiser:select": function (args) {
        this.advertiser_id = args.id;
        $(this.el).find(".select-input").attr("disabled", "disabled").trigger("liszt:updated");
        this.collection.urlFilter.set({
          entity: "advertiser",
          id: args.id,
          fetchRequired: true
        });
      }
    },
    initialize: function (args) {
      var self = this;
      var id = args.id;
      var collection = this.collection;
      var model;
      var ad_id;
      if (id) {
        self.selected = id;
        collection.add({
          id: id
        });
        model = collection.get(id);
        model.fetch({
          success: function () {
            self.selectPixel.call(self, {
              id: model.id,
              name: model.get("name")
            });
          }
        });
      }
      if (args.getAdvertiserId) {
        ad_id = args.getAdvertiserId();
        self.advertiser_id = ad_id !== "" ? ad_id : null;
        collection.search.set({
          params: [{
            term: "event",
            searchField: "pixel_type"
          }, {
            term: "1",
            searchField: "eligible"
          }],
          fetchRequired: false
        });
        if (!id) {
          collection.urlFilter.set({
            entity: "advertiser",
            id: ad_id,
            fetchRequired: true
          });
        }
      }
      this.doNotShowExtraLink = args.doNotShowExtraLink;
      collection.bind("reset", self.load, self);
    },
    load: function () {
      var selectBox;
      var self = this;
      var collection = self.collection;
      var el = $(self.el);
      var selectBoxConfig = {
        searchFieldDisabled: false,
        default_text_default: self.advertiser_id ? "Merit Pixel" : "Select an Advertiser First!",
        onChange: function () {
          var id = selectBox.val();
          self.selected = id;
          T1.EventHub.publish("pixelBundle:select", {
            id: id,
            name: el.find(".select-input option:selected").text(),
            fetchRequired: self.fetchRequired
          });
        },
        extraLink: {
          label: "Detailed List",
          onClick: function (event) {
            $(self.el).find(".select-input").data("chosen").close_field();
            T1.EventHub.publish("pixel:showDetails");
          }
        }
      };
      var autoCompleteConfig = {
        autoComplete: true,
        searchField: "name",
        onSearchRequest: function (searchOptions) {
          if (!self.advertiser_id) {
            return;
          }
          collection.urlFilter.set({
            entity: "advertiser",
            id: self.advertiser_id
          });
          searchOptions = $.extend({
            fetchRequired: true
          }, searchOptions);
          searchOptions.params.push({
            term: "1",
            searchField: "eligible"
          });
          collection.search.set(searchOptions);
        }
      };
      self.render().then(function () {
        selectBox = $(self.el).find(".select-input");
        if (self.doNotShowExtraLink) {
          selectBoxConfig.extraLink = undefined;
        }
        T1.Selectbox(selectBox, selectBoxConfig, autoCompleteConfig);
      });
    },
    refreshList: function () {
      this.renderPartial(".select-input");
      $(this.el).find(".select-input").trigger("liszt:updated");
    },
    selectPixel: function (data) {
      var collection = this.collection;
      var model = collection.get(data.id);
      var self = this;
      this.selected = "" + data.id;
      if (!model) {
        collection.add({
          id: data.id
        });
        model = this.collection.get(data.id);
        model.fetch({
          success: function () {
            self.refreshList();
          }
        });
      } else {
        this.refreshList();
      }
    },
    serialize: function () {
      var self = this;
      var pixelBundles = self.collection;
      var result = [];
      var obj = {};
      pixelBundles.each(function (pixelBundle) {
        obj = pixelBundle.toJSON();
        if (pixelBundle.id === self.selected) {
          obj.selected = "selected";
        }
        result.push(obj);
      });
      return {
        disabled: self.advertiser_id ? false : true,
        placeholder: self.advertiser_id ? "Select One" : "Select an Advertiser First!",
        options: result
      };
    }
  });
});
