define('modules/error/views/errorMessage',["jQuery", "T1", "T1View", "text!modules/error/templates/errorMessage.html", "Mustache", "jQueryUI"], function ($, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    initialize: function () {
      var self = this;
      var model = this.model;
      if (sessionStorage.getItem("browser.adBlockEnabled")) {
        return;
      }
      T1.EventHub.subscribe("comm:error", function (data) {
        model.add(data);
        self.load.call(self, data);
      });
    },
    load: function (data) {
      if (!data) {
        return;
      }
      var self = this;
      self.render();
      if (!self.isOpen) {
        $(this.el).dialog({
          dialogClass: "w-ErrorMessage",
          closeText: "",
          autoOpen: true,
          modal: true,
          stack: true,
          close: function () {
            self.closeDialog.call(self);
          }
        });
        this.isOpen = true;
      }
    },
    events: {
      "click .close-button": "closeDialog"
    },
    serialize: function () {
      var errors = this.model.toJSON();
      return {
        errorCodes: errors.code.join(", "),
        description: errors.description,
        detail: errors.detail.join("\n")
      };
    },
    closeDialog: function (e) {
      var el = $(this.el);
      if (e) {
        e.preventDefault();
        el.dialog("close");
      }
      el.dialog("destroy");
      this.isOpen = false;
      this.model.reset();
    },
    unload: function () {
      T1.EventHub.unsubscribe("comm:error");
    }
  });
});
