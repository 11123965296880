define('modules/T1App/edit/access/users/views/userBin',["jQuery", "Underscore", "T1", "T1View", "T1Comm", "text!modules/T1App/edit/access/users/templates/userBin.html", "T1Model", "T1Layout"], function ($, _, T1, T1View, T1Comm, template) {
  "use strict";

  var MetaTeamBinCreateEdit;
  var T1Publish = T1.EventHub.publish;
  MetaTeamBinCreateEdit = T1View.extend({
    template: template,
    events: {
      "click .remove-dev ": "binClickHandler"
    },
    initialize: function (args) {
      this.model = args.model;
    },
    load: function () {
      this.render();
    },
    binClickHandler: function (event) {
      T1Publish("change:userBinElements", event);
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      if ($el[0] && Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return MetaTeamBinCreateEdit;
});
