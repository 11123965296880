define('modules/reporting/campaigns/utils/gridColumnResize',[],function () {
  "use strict";

  var gridColumnResize = function (reportModel, $el, multiCampaign, multiCampaignExtCols, strategyQuickEdit) {
    var selectorHeader = ".column.header.name";
    var selectorRows = ".row .column.detail.name";
    var $1stColSubRows, $2ndColHeader, $2ndColRowContent, $2ndColRows, $2ndColSubRows, $header, $helper, $rows, initWidth2ndColRows, resizeWidth;
    var $gridContentContainer = $el.find(".w-DataGrid .content-c");
    var $headerContainer = $el.find(".header-container");
    var $1stColHeader = $el.find(selectorHeader);
    var $1stColRows = $el.find(selectorRows);
    var $1stColRowContent = $1stColRows.find("span").not(".item-icon");
    const editOffset = 30;
    const margin2 = 19;
    const quickEditOffset = strategyQuickEdit ? editOffset : 0;
    const legendOffset = margin2 + quickEditOffset;
    const iconOffset = 24;
    const arrowOffset = 14;
    const subRowsOffset = 14;
    const subRowsOffset2 = 2;
    const firstColLeftPadding = 20;
    const secondColLeftPadding = -11;
    const gridContentRightPadding = 30;
    const colMaxWidth = 1600;
    var initWidthGridContentContainer = $gridContentContainer.width();
    var initWidth1stColRows = $1stColRows.width() + firstColLeftPadding + arrowOffset;
    var deltaWidth1stCol = 0;
    var deltaWidth2ndCol = 0;
    var preventColumnSorting = function ($column) {
      $column.find(".ui-resizable-handle").click(function (event) {
        event.stopPropagation();
      });
    };
    var get1stColWidths = function () {
      return {
        children: $1stColRows.width(),
        header: $1stColHeader.width(),
        names: $1stColRowContent.css("max-width"),
        subrows: $1stColSubRows && $1stColSubRows.length > 0 ? $1stColSubRows.width() : subRowsOffset + $1stColHeader.width()
      };
    };
    var get2ndColWidths = function () {
      return {
        children: $2ndColRows.width(),
        header: $2ndColHeader.width(),
        names: $2ndColRowContent.css("max-width"),
        subrows: $2ndColSubRows && $2ndColSubRows.length > 0 ? $2ndColSubRows.width() : $2ndColHeader.width() - subRowsOffset2
      };
    };
    var setColumnWidths = function () {
      if ($2ndColHeader) {
        reportModel.setColumnWidths(get1stColWidths(), get2ndColWidths());
      } else {
        reportModel.setColumnWidths(get1stColWidths());
      }
    };
    var gridContainerResize = function () {
      $gridContentContainer.css("width", `${Number(initWidthGridContentContainer + deltaWidth1stCol + deltaWidth2ndCol + gridContentRightPadding)}px`);
      $el.find(".content-holder").perfectScrollbar("update");
    };
    var resize1stCol = function () {
      const offsetHelper = firstColLeftPadding + legendOffset;
      const offsetDelta = firstColLeftPadding + arrowOffset - initWidth1stColRows;
      if ($1stColHeader.hasClass("ui-resizable")) {
        $1stColHeader.resizable("destroy");
      }
      $1stColHeader.resizable({
        handles: "e",
        helper: "resizable-helper",
        maxWidth: colMaxWidth,
        minWidth: 194,
        start: function () {
          var scrollLeft = $headerContainer.css("left").replace("px", "");
          var scrollCorrection = scrollLeft !== "auto" ? Number(scrollLeft) : 0;
          var noLeftGuide = scrollCorrection < 0 ? " border-left: 0;" : "";
          $1stColSubRows = $el.find(".child-row .column.detail.date");
          $el.append(`<div class="resizable-helper" style="left: ${scrollCorrection}px;${noLeftGuide}"></div>`);
          $helper = $el.find(".resizable-helper");
        },
        resize: function (event, ui) {
          resizeWidth = ui.size.width;
          deltaWidth1stCol = Number(resizeWidth + offsetDelta);
          $helper.css("width", `${Number(resizeWidth + offsetHelper)}px`);
        },
        stop: function () {
          $helper.remove();
          $1stColSubRows = $el.find(".child-row .column.detail.date");
          $1stColHeader.css("width", `${Number(resizeWidth)}px`);
          $1stColRows.css("width", `${Number(legendOffset + resizeWidth)}px`);
          $1stColRowContent.css("max-width", `${Number(resizeWidth - legendOffset - iconOffset - arrowOffset)}px`);
          $1stColSubRows.css("width", `${Number(subRowsOffset + resizeWidth)}px`);
          gridContainerResize();
          setColumnWidths();
        }
      }).on("resize", function (event) {
        event.stopPropagation();
      });
      preventColumnSorting($1stColHeader);
    };
    var resize2ndCol = function () {
      var offsetDelta = secondColLeftPadding + arrowOffset - initWidth2ndColRows;
      if ($2ndColHeader.hasClass("ui-resizable")) {
        $2ndColHeader.resizable("destroy");
      }
      $2ndColHeader.resizable({
        handles: "e",
        helper: "resizable-helper",
        maxWidth: colMaxWidth,
        minWidth: 204,
        start: function () {
          var width1stCol = $1stColHeader.width();
          var offset1stCol = firstColLeftPadding + legendOffset + width1stCol;
          var scrollLeft = $headerContainer.css("left").replace("px", "");
          var scrollCorrection = scrollLeft !== "auto" ? Number(scrollLeft) : 0;
          var noLeftGuide = scrollCorrection < -offset1stCol ? " border-left: 0;" : "";
          $el.append(`<div class="resizable-helper" style="left: ${Number(offset1stCol + scrollCorrection)}px;${noLeftGuide}"></div>`);
          $helper = $el.find(".resizable-helper");
        },
        resize: function (event, ui) {
          resizeWidth = ui.size.width;
          deltaWidth2ndCol = Number(resizeWidth + offsetDelta);
          $helper.css("width", `${Number(resizeWidth)}px`);
        },
        stop: function () {
          const half = 2;
          const halfOffset = legendOffset / half;
          $helper.remove();
          $2ndColSubRows = $el.find(".child-row .column.detail.name");
          $2ndColHeader.css("width", `${Number(resizeWidth)}px`);
          $2ndColRows.css("width", `${Number(halfOffset + resizeWidth)}px`);
          $2ndColRowContent.css("max-width", `${Number(resizeWidth - legendOffset - iconOffset)}px`);
          $2ndColSubRows.css("width", `${Number(resizeWidth - subRowsOffset2)}px`);
          gridContainerResize();
          setColumnWidths();
        }
      }).on("resize", function (event) {
        event.stopPropagation();
      });
      preventColumnSorting($2ndColHeader);
    };
    if (multiCampaign) {
      if (multiCampaignExtCols) {
        $header = $el.find(selectorHeader);
        $rows = $el.find(selectorRows);
        $1stColHeader = $header.eq(0);
        $1stColRows = $rows.filter(":even");
        $1stColRowContent = $1stColRows.find("span").not(".item-icon");
        resize1stCol();
        $2ndColHeader = $header.eq(1);
        $2ndColRows = $rows.filter(":odd");
        $2ndColRowContent = $2ndColRows.find("span");
        initWidth2ndColRows = $2ndColRows.width() + secondColLeftPadding;
        resize2ndCol();
      } else {
        resize1stCol();
      }
    } else {
      resize1stCol();
    }
    return [$1stColHeader, $2ndColHeader];
  };
  return gridColumnResize;
});
