define('modules/targeting/dayPart/views/readOnly',["jQuery", "T1", "T1View", "text!../templates/readOnly.html"], function ($, T1, T1View, template) {
  "use strict";

  var readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function ({
      collection: collection,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit
    }) {
      this.collection = collection;
      this.canLoad = false;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.collection.fetch({
        success: () => {
          this.canLoad = true;
          this.load();
        }
      });
    },
    load: function () {
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        const loader = this.loader();
        if (!this.canLoad || this.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
        }
      });
    },
    serialize: function () {
      var dayPartLists = [];
      const collObj = this.collection;
      var resultJSON = {};
      var blankEntity = "&nbsp;";
      var daysValRef = {
        MTWRFSU: "Everyday",
        MTWRF: "Weekdays",
        SU: "Weekends"
      };
      var dayCodeDisplayValRef = {
        M: "Mon",
        T: "Tue",
        W: "Wed",
        R: "Thu",
        F: "Fri",
        S: "Sat",
        U: "Sun"
      };
      var getDaysDisplay = function (model) {
        var daysVal = model.get("days");
        var result = daysValRef[daysVal];
        if (!result) {
          const tmpArr = daysVal.split("");
          result = "";
          $.each(tmpArr, function (index, strVal) {
            result += `${dayCodeDisplayValRef[strVal]}, `;
          });
          result = result.replace(/, $/, "");
        }
        return result;
      };
      var getStartEndHours = function (model) {
        var startH = model.get("start_hour");
        var endH = model.get("end_hour");
        var result = {
          startH: blankEntity,
          endH: blankEntity
        };
        const fullDay = 23;
        function getDisplayValsFromHour(hr, isEndHour) {
          var minPart = ":00";
          const halfDay = 12;
          if (isEndHour === true) {
            minPart = ":59";
          }
          if (hr === 0) {
            return `12${minPart} AM`;
          } else if (hr === halfDay) {
            return `12${minPart} PM`;
          }
          if (hr > halfDay) {
            hr = hr - halfDay;
            return `${hr + minPart} PM`;
          }
          return `${hr + minPart} AM`;
        }
        startH = parseInt(startH, 10);
        endH = parseInt(endH, 10);
        if (isNaN(startH) || isNaN(endH)) {
          return result;
        }
        if (startH === 0 && endH === fullDay) {
          result.startH = "All";
          result.endH = "All";
        } else {
          result.startH = getDisplayValsFromHour(startH, false);
          result.endH = getDisplayValsFromHour(endH, true);
        }
        return result;
      };
      if (collObj && collObj.models && collObj.models.length) {
        $.each(collObj.models, function (index, model) {
          const startEndResult = getStartEndHours(model);
          dayPartLists.push({
            days: getDaysDisplay(model),
            startHour: startEndResult.startH,
            endHour: startEndResult.endH,
            relativeTo: model.get("user_time") === "1" ? "End User" : "Eastern Timezone"
          });
        });
      }
      resultJSON.canLoad = this.canLoad;
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/dayPart`;
      }
      if (dayPartLists.length === 1 && dayPartLists[0].days === "Everyday" && dayPartLists[0].startHour === "All" && dayPartLists[0].endHour === "All") {
        resultJSON.hasData = false;
      } else {
        resultJSON.hasData = Boolean(dayPartLists.length);
      }
      resultJSON.dayPartLists = dayPartLists;
      return resultJSON;
    }
  });
  return readOnlyView;
});
