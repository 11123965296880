define('modules/strategy/views/helpers/defaultLocationPersistenceHelper',["Underscore", "T1", "models/userPreferences", "T1Preferences", "T1Signature"], function (_, T1, UserPreferences) {
  var DefaultLocationPersistHelper = function () {
    const indexRef = {
      REGN: 0,
      DMAX: 1
    };
    function initialize() {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "strategy"], ["view", "targetingLocation"]]));
      this.defaultInclude = "";
      this.defaultExclude = "";
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["defaultInclude", "defaultExclude"]);
    }
    function saveDefaultLocations(includeText, excludeText, success) {
      if (this.preferences === undefined) {
        throw new Error("You must call init method of this class first.");
      }
      this.defaultInclude = includeText;
      this.defaultExclude = excludeText;
      this.preferences.save({
        success: success
      });
    }
    function getDefaultLocations() {
      if (this.preferences === undefined) {
        throw new Error("You must call init method of this class first.");
      }
      return {
        include: this.defaultInclude,
        exclude: this.defaultExclude
      };
    }
    function extractValueIDs(postObj = {}) {
      let dmaxEx, dmaxIn, regnEx, regnIn;
      const limit = 5;
      for (let i = 1; i < limit; i++) {
        const code = postObj[`dimensions.${i}.code`] || "";
        const restriction = postObj[`dimensions.${i}.restriction`] || "";
        const values = postObj[`dimensions.${i}.value_ids`] || "";
        if (code === "DMAX" && restriction === "INCLUDE") {
          dmaxIn = values;
        }
        if (code === "DMAX" && restriction === "EXCLUDE") {
          dmaxEx = values;
        }
        if (code === "REGN" && restriction === "INCLUDE") {
          regnIn = values;
        }
        if (code === "REGN" && restriction === "EXCLUDE") {
          regnEx = values;
        }
      }
      return {
        DMAX_Include: dmaxIn || "",
        DMAX_Exclude: dmaxEx || "",
        REGN_Include: regnIn || "",
        REGN_Exclude: regnEx || ""
      };
    }
    function convertFromPostObjToPrefText(postObj) {
      let excludeResult, includeResult;
      var valueIDs = extractValueIDs(postObj);
      if (valueIDs.REGN_Include.length || valueIDs.DMAX_Include.length) {
        includeResult = `${valueIDs.REGN_Include}|${valueIDs.DMAX_Include}`;
      }
      if (valueIDs.REGN_Exclude.length || valueIDs.DMAX_Exclude.length) {
        excludeResult = `${valueIDs.REGN_Exclude}|${valueIDs.DMAX_Exclude}`;
      }
      return {
        include: includeResult || "",
        exclude: excludeResult || ""
      };
    }
    function convertFromPrefTextToPostObj(preferenceTextInclude, preferenceTextExclude) {
      var includeVal = preferenceTextInclude || "";
      var excludeVal = preferenceTextExclude || "";
      var includeArr = includeVal.split("|");
      var excludeArr = excludeVal.split("|");
      var resultObj = {};
      var index = 1;
      const maxItems = 2;
      if (includeArr.length < maxItems) {
        includeArr[1] = "";
      }
      if (excludeArr.length < maxItems) {
        excludeArr[1] = "";
      }
      if (includeArr[indexRef.DMAX].length) {
        resultObj[`dimensions.${index}.code`] = "DMAX";
        resultObj[`dimensions.${index}.restriction`] = "INCLUDE";
        resultObj[`dimensions.${index}.operation`] = "OR";
        resultObj[`dimensions.${index}.value_ids`] = includeArr[indexRef.DMAX];
        index++;
      }
      if (excludeArr[indexRef.DMAX].length) {
        resultObj[`dimensions.${index}.code`] = "DMAX";
        resultObj[`dimensions.${index}.restriction`] = "EXCLUDE";
        resultObj[`dimensions.${index}.operation`] = "OR";
        resultObj[`dimensions.${index}.value_ids`] = excludeArr[indexRef.DMAX];
        index++;
      }
      if (includeArr[indexRef.REGN].length) {
        resultObj[`dimensions.${index}.code`] = "REGN";
        resultObj[`dimensions.${index}.restriction`] = "INCLUDE";
        resultObj[`dimensions.${index}.operation`] = "OR";
        resultObj[`dimensions.${index}.value_ids`] = includeArr[indexRef.REGN];
        index++;
      }
      if (excludeArr[indexRef.REGN].length) {
        resultObj[`dimensions.${index}.code`] = "REGN";
        resultObj[`dimensions.${index}.restriction`] = "EXCLUDE";
        resultObj[`dimensions.${index}.operation`] = "OR";
        resultObj[`dimensions.${index}.value_ids`] = excludeArr[indexRef.REGN];
      }
      return resultObj;
    }
    function validatePostObj(postObj) {
      var valueIDs = extractValueIDs(postObj);
      var maxCount = 100;
      const result = {
        success: true,
        category: ""
      };
      const arrTesterInclude = valueIDs.REGN_Include.split(",").concat(valueIDs.DMAX_Include.split(","));
      const arrTesterExclude = valueIDs.REGN_Exclude.split(",").concat(valueIDs.DMAX_Exclude.split(","));
      if (arrTesterInclude.length > maxCount && arrTesterExclude.length > maxCount) {
        result.success = false;
        result.category = "Both Bins";
      } else if (arrTesterInclude.length > maxCount) {
        result.success = false;
        result.category = "Included Bin";
      } else if (arrTesterExclude.length > maxCount) {
        result.success = false;
        result.category = "Excluded Bin";
      }
      return result;
    }
    return {
      init: initialize,
      saveSettings: saveDefaultLocations,
      getSettings: getDefaultLocations,
      convertToPrefText: convertFromPostObjToPrefText,
      convertFromPrefText: convertFromPrefTextToPostObj,
      validatePostObj: validatePostObj,
      indexReference: indexRef
    };
  };
  return DefaultLocationPersistHelper;
});
