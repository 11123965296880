define('modules/reporting/campaigns/contextual/views/contextual',["moment", "T1", "T1View", "T1Layout", "T1Permissions", "models/userPreferences", "modules/reporting/campaigns/models/additionalData", "text!../templates/contextual.html"], function (moment, T1, T1View, T1Layout, T1Permissions, UserPreferences, additionalData, template) {
  "use strict";

  const defaultDimension = "target_name";
  const defaultDimension2 = "strategy_name";
  const sectionID = "contextual_insights";
  return T1View.extend({
    defaultSubSection: "summary",
    dimension2: "",
    displayDimension2: "",
    displayMetric: "",
    displaySelectedColumns: "",
    displaySortKeyInfo: "",
    focus: "",
    metric: "",
    selectedColumns: "",
    sortKeyInfo: "",
    videoDimension2: "",
    videoMetric: "",
    videoSelectedColumns: "",
    videoSortKeyInfo: "",
    template: template,
    section: "contextual",
    subSection: "summary",
    events: {
      click: "dehighlightItem"
    },
    eventhubEvents: {
      "select:focus": function (focus) {
        this.focus = focus;
        this.getFocusKey = this.models.reportModel.makeFocusKeyFunc(focus);
        this.preferences.save();
      },
      "select:dim2": function (dimension) {
        this[this.getFocusKey("dimension2")] = dimension;
        this.preferences.save();
        this.models.reportModel.setDimension(null, null, null, dimension === "" ? null : dimension);
      },
      "remove:dim2": function () {
        this[this.getFocusKey("dimension2")] = "";
        this.preferences.save();
      },
      "select:chart": function (chartType) {
        const mainModel = this.models.mainModel;
        this.subSection = chartType;
        this.preferences.save();
        mainModel.setSubSection(mainModel.get("section"), chartType);
      },
      "select:metric": function (metric) {
        this[this.getFocusKey("metric")] = metric;
        this.preferences.save();
        this.models.reportModel.setMetric(metric);
      },
      "preference:change": function (args) {
        this[args.key] = args.value;
        this.preferences.save();
      },
      "reporting:campaign:load": "refreshUI",
      "reporting:meta:load": "reload",
      "reporting:focus:meta:load": function () {
        this.initPreferences();
        this.refreshUI();
      }
    },
    refreshUI: function () {
      const reportModel = this.models.reportModel;
      reportModel.clearTempDimension();
      reportModel.setSearchTerm("");
      T1.EventHub.publish("unload:noDataMessages");
      this.load();
    },
    initPreferences: function () {
      let focus, getFocusKey;
      const signature = T1.Signature.create();
      const models = this.models;
      const {
        reportMeta: reportMeta,
        reportModel: reportModel
      } = models;
      const contextualDimObj = reportMeta.get("dimensions").contextual_insights;
      const contextualSubSection = models.mainMeta.get("contextualSubSection");
      const mainModel = models.mainModel;
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", sectionID]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["dimension2", "displayDimension2", "displayMetric", "displaySelectedColumns", "displaySortKeyInfo", "focus", "metric", "selectedColumns", "sortKeyInfo", "subSection", "videoDimension2", "videoMetric", "videoSelectedColumns", "videoSortKeyInfo"]);
      reportModel.setPreferences(this.preferences);
      if (contextualSubSection) {
        this.subSection = contextualSubSection;
      } else {
        this.subSection = UserPreferences.get("ui.reporting.contextual_insights.subSection") || this.defaultSubSection;
      }
      mainModel.setSubSection(mainModel.get("section"), this.subSection);
      this.focus = focus = this.focus || "all";
      this.getFocusKey = getFocusKey = reportModel.makeFocusKeyFunc(focus);
      reportModel.setFocus(focus);
      reportMeta.parseDimensions("dimensionOptions", contextualDimObj.viewBy);
      reportMeta.parseDimensions("dimension2Options", contextualDimObj.filterBy);
      reportMeta.parseMetrics();
      reportModel.parseColumns();
      const dimensionKey2 = getFocusKey("dimension2");
      const dimension2 = this[dimensionKey2];
      let verifiedDimension2 = "";
      if (dimension2) {
        const verifiedDimensions = Object.keys(additionalData.dimensions.contextual_insights[focus]);
        if (verifiedDimensions.includes(dimension2) && dimension2 !== defaultDimension) {
          verifiedDimension2 = dimension2;
        } else {
          verifiedDimension2 = defaultDimension2;
        }
      }
      this[dimensionKey2] = verifiedDimension2;
      this.preferences.save();
      const defaultMetric = reportModel.getDefaultMetric(this.campaign);
      reportModel.setDimension(defaultDimension, false, true, verifiedDimension2, false, true, true);
      reportModel.setMetric(this[getFocusKey("metric")] || defaultMetric, true);
    },
    initialize: function (cfgs = {}) {
      const models = this.models = cfgs.models;
      this.report = cfgs.report;
      this.campaign = models.campaign;
      this.needEvents = true;
      models.reportModel.setSection(sectionID);
      models.mainModel.setReportMainView(this);
    },
    initLayout: function () {
      const models = this.models;
      const {
        reportModel: reportModel,
        reportMeta: reportMeta
      } = models;
      const contextualDimObj = reportMeta.get("dimensions").contextual_insights;
      const layoutConfig = {
        el: () => this.$(".w-contextual-panel"),
        template: `<div class="tabs-panel">\n            <div class="tabs-bar"></div>\n            <div class="tabs-control-focus"></div>\n            <div class="tabs-control-view-by"></div>\n            <div class="tabs-control-dimensions"></div>\n            <div class="tabs-info"></div>\n            <div class="tabs-settings"></div>\n            <div class="contextual-panel"></div>\n          </div>`,
        layout: {
          ".tabs-control-focus": [{
            module: "reporting/campaigns",
            options: {
              models: models
            },
            viewType: "select_focus"
          }],
          ".tabs-control-dimensions": [{
            module: "reporting/campaigns",
            options: {
              dimensionObj: contextualDimObj.filterBy,
              dimensionOptions: reportMeta.get("dimension2Options"),
              index: 1,
              label: "+ Add Filter",
              models: models
            },
            viewType: "selectDimension"
          }],
          ".tabs-settings": [{
            module: "reporting/campaigns",
            options: {
              models: models,
              menu_config: {
                raw_data: {
                  label: "Export CSV",
                  items: {
                    export_target_name: {
                      contentType: "text/csv",
                      download_url: function () {
                        return reportModel.getDownloadURL({
                          byDimension: true,
                          dimensions: "target_name,path,parent",
                          isSummary: true,
                          sourceURL: `std/${sectionID}/download`
                        });
                      },
                      item_type: "action-item",
                      label: "By Target Name",
                      subSection: " (Summary)",
                      url: `std/${sectionID}/download`
                    },
                    export_target_name_daily: {
                      contentType: "text/csv",
                      download_url: function () {
                        return reportModel.getDownloadURL({
                          byDimension: true,
                          dimensions: "target_name,path,parent",
                          sourceURL: `std/${sectionID}/download`
                        });
                      },
                      item_type: "action-item",
                      label: "By Target Name",
                      subSection: " (Daily)",
                      url: `std/${sectionID}/download`
                    }
                  }
                }
              }
            },
            viewType: "settings_menu"
          }],
          ".tabs-info": [{
            module: "reporting/campaigns",
            viewType: "report_info"
          }],
          ".contextual-panel": [{
            module: "reporting/campaigns/contextual",
            options: {
              models: models,
              parentClass: this,
              report: this.report
            },
            viewType: "panel"
          }]
        }
      };
      this.layout = new T1Layout(layoutConfig);
    },
    load: function () {
      const models = this.models;
      if (models.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (this.needEvents) {
        this.bindViewEvents();
        this.needEvents = false;
      }
      models.reportModel.setSection(sectionID, true);
      if (this.campaign.id && models.reportMeta.loaded) {
        if (this.layout) {
          this.layout.unload();
        }
        this.initPreferences();
        this.initLayout();
        this.render().then(() => {
          this.layout.load();
          this.loaded = true;
        });
      }
    },
    unload: function () {
      this.loaded = false;
      this.needEvents = true;
    },
    dehighlightItem: function () {
      const reportModel = this.models.reportModel;
      const highlighted = reportModel.get("highlightedItem");
      if (highlighted && highlighted.id) {
        reportModel.setHighlightedItem();
      }
    }
  });
});
