define('modules/strategy/views/helpers/technologySummaryTarget',[], function () {
  "use strict";

  var titles = {
    CSPD: "Connection Speed",
    ISPX: "ISP",
    BSER: "Browser",
    INVT: "Inventory Type",
    DVCE: "Device Type",
    WURF: "Device Model",
    "WURFL/OS": "OS Version"
  };
  function processGroupHash(key) {
    return {
      header: this.titles[key] || key,
      items: this.populatedHash[key].sort(this.sortByIncludeExclude)
    };
  }
  function getParentName(model, digitalModels, partialModels) {
    var parentId = model.get("parent_value_id");
    var findParent, parent;
    if (parentId) {
      findParent = function (parentModel) {
        if (parentModel.get("id") === parentId) {
          parent = parentModel;
          return true;
        }
      };
      partialModels.some(findParent);
      if (!parent) {
        digitalModels.some(findParent);
      }
    }
    if ((model.get("dimension_code") === "WURF" || model.get("dimension_code") === "WURFL/OS") && model.get("parent_name")) {
      return `${model.get("parent_name")} &raquo; `;
    }
    return parent ? `${parent.get("name")} &raquo; ` : "";
  }
  function createDataBlock(digitalModels, partialModels) {
    var populatedHash = digitalModels.reduce(createPopulatedHash, {});
    var context = {
      populatedHash: populatedHash,
      titles: titles,
      sortByIncludeExclude: function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      }
    };
    function createPopulatedHash(obj, model) {
      var code = model.get("dimension_code");
      if (titles[code] !== undefined) {
        obj[code] = obj[code] || [];
        obj[code].push({
          label: getParentName(model, digitalModels, partialModels),
          boldText: model.get("name"),
          action: model.get("action")
        });
      }
      return obj;
    }
    return Object.keys(populatedHash).map(processGroupHash, context);
  }
  return {
    prepareForSerialize: function (strategyModel, digitalCollection, partialCollection) {
      this.hasData = digitalCollection || digitalCollection.models || digitalCollection.models.length;
      return {
        data: this.hasData ? createDataBlock(digitalCollection.models, partialCollection.models) : [],
        editLink: this.canEdit ? `${this.editLinkPrefix}/targeting/technology` : ""
      };
    }
  };
});
