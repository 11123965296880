define('modules/campaign/bulkedit/main',["jQuery", "T1Module", "models/bulkeditor"], function ($, T1Module, Bulkeditor) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "campaign/bulkedit",
      viewCfgs: {
        createEdit: {
          model: null
        },
        targeting: {
          model: null
        }
      },
      defaultView: "createEdit",
      getView: function (type, opts) {
        let bulkeditor, resultPromise;
        var action = opts.deepLinks;
        const actionLength = action && action.length;
        if (actionLength && action[0] === "create") {
          const deferred = $.Deferred();
          bulkeditor = new Bulkeditor({
            newEditor: true,
            parentType: "campaign",
            parentId: opts.id
          });
          opts.bulkeditor = bulkeditor.createNewEditor({
            success: () => {
              T1Module.prototype.getView.call(this, type, opts).then(function (view) {
                deferred.resolve(view);
              });
            }
          });
          resultPromise = deferred.promise();
        } else {
          if (opts.id) {
            const deferred = $.Deferred();
            const twoPartId = opts.id.split("-");
            bulkeditor = new Bulkeditor({
              newEditor: false,
              editorId: twoPartId[1],
              parentType: "campaign",
              parentId: twoPartId[0]
            });
            opts.bulkeditor = bulkeditor.getStatus({
              success: () => {
                T1Module.prototype.getView.call(this, type, opts).then(function (view) {
                  deferred.resolve(view);
                });
              }
            });
            resultPromise = deferred.promise();
          } else {
            resultPromise = T1Module.prototype.getView.call(this, type, opts);
          }
        }
        return resultPromise;
      }
    });
  }
  return instance;
});
