define('utils/ExpiringCache',["When"], function () {
  var ExpiringCache = function (shelflife) {
    this.__shelflife = shelflife;
    this.__storage = {};
  };
  var ExpiringCacheRecord = function () {
    this.__birthTime = null;
    this.__value = null;
  };
  ExpiringCacheRecord.prototype.setValue = function (value, currentTime) {
    this.__value = value;
    this.__birthTime = currentTime;
  };
  ExpiringCacheRecord.prototype.getValue = function () {
    return this.__value;
  };
  ExpiringCacheRecord.prototype.freshValue = function (currentTime, validator, context) {
    if (validator.call(context, currentTime, this.__birthTime)) {
      return this.getValue();
    }
    return null;
  };
  ExpiringCache.prototype.validator = function (currentTime, birthTime) {
    return currentTime < birthTime + this.__shelflife;
  };
  ExpiringCache.prototype.set = function (key, value, currentTime) {
    var record = this.__storage[key];
    const REQ_ARGS = 3;
    if (arguments.length < REQ_ARGS) {
      currentTime = Date.now();
    }
    if (!record) {
      record = this.__storage[key] = new ExpiringCacheRecord();
    }
    return record.setValue(value, currentTime);
  };
  ExpiringCache.prototype.get = function (key, currentTime) {
    var record = this.__storage[key];
    const REQ_ARGS = 2;
    if (arguments.length < REQ_ARGS) {
      currentTime = Date.now();
    }
    if (!record) {
      return null;
    }
    return record.freshValue(currentTime, this.validator, this);
  };
  ExpiringCache.prototype.unsafe = function (key) {
    var record = this.__storage[key];
    if (!record) {
      return null;
    }
    return record.getValue();
  };
  return ExpiringCache;
});
