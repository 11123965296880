define('modules/campaign/chart/views/exportMenu',["jQuery", "Underscore", "T1", "T1View", "text!../templates/exportMenu.html"], function ($, _, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  return T1View.extend({
    template: template,
    documentEvents: "mousedown.CanvasChart keyup.CanvasChart",
    $document: $(document),
    events: {
      "click .btn-export": "toggleHandler",
      "click .list-export .list-item": "downloadHandler",
      "mousedown .list-export": "stopBubbling"
    },
    initialize: function ({
      getReportModel: getReportModel
    }) {
      this.reportModel = getReportModel();
    },
    load: function () {
      this.render().then(() => {
        this.$list = this.$(".list-export");
        this.$carat = this.$(".carat-export");
        const $downloadLinks = this.$list.find(".download-links");
        this.$linkCurrentView = $downloadLinks.find("a:eq(0)");
        this.$linkAllMetrics = $downloadLinks.find("a:eq(1)");
        T1Publish("complete:layoutLoad");
      });
    },
    unload: function () {
      this.$carat = null;
      this.$downloadLinks = null;
      this.$linkAllMetrics = null;
      this.$linkCurrentView = null;
      this.$list = null;
      this.reportModel = null;
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    toggleHandler: function () {
      const $list = this.$list;
      if ($list.hasClass("no-display")) {
        $list.toggleClass("no-display");
        this.$carat.toggleClass("menu-open");
        this.updateDownloadURLs();
        this.$document.one(this.documentEvents, evt => {
          if (evt.keyCode === TAB_KEY || evt.keyCode === ESCAPE_KEY || !evt.keyCode) {
            if (!$(evt.target).closest(".export-menu").length) {
              this.closeMenu();
            }
          }
        });
      } else {
        this.closeMenu();
      }
    },
    closeMenu: function () {
      this.$list.toggleClass("no-display");
      this.$carat.toggleClass("menu-open");
      this.$document.off(this.documentEvents);
    },
    updateDownloadURLs: function () {
      let having, urlCurrentView;
      const reportModel = this.reportModel;
      const entityType = reportModel.getEntityType();
      const chartType = reportModel.getChartType();
      const isCampaign = entityType === "campaign";
      const selectedMetrics = reportModel.getSelectedMetrics();
      const selectedMetric0 = selectedMetrics[entityType][chartType][0];
      const selectedMetric1 = selectedMetrics[entityType][chartType][1];
      const metricOptions = reportModel.reportMeta.get("metricOptions");
      const highestLowest = isCampaign ? reportModel.getHighestLowest() : null;
      const order = isCampaign ? highestLowest.order : null;
      const limit = isCampaign ? highestLowest.limit : null;
      const limitMetric = isCampaign ? highestLowest.metric : null;
      const excludeZeros = isCampaign ? T1.Utils.valueToBool(highestLowest.excludeZeros) : null;
      let urlData = reportModel.chartData.url();
      if (isCampaign) {
        const limitMetricInfo = _.find(metricOptions, ({
          value: value
        }) => value === limitMetric);
        having = limitMetricInfo.type === "integers" ? ">0" : ">=0.01";
      }
      urlData = urlData.replace("?", "/download?");
      urlData = urlData.replace("%2Ccampaign_timezone_code", "");
      if (isCampaign) {
        const orderOpts = order === "lo" ? "&order=" : "&order=-";
        urlData = urlData.replace("order=date", `page_limit=${limit}${orderOpts}${limitMetric}`);
      }
      if (excludeZeros) {
        urlData = `${urlData}&having=${limitMetric}${having}`;
      }
      if (isCampaign) {
        urlCurrentView = `${urlData}&metrics=${selectedMetric0.value}`;
        if (selectedMetric1) {
          urlCurrentView += `,${selectedMetric1.value}`;
        }
      } else {
        urlData = urlData.replace("dimensions=", "dimensions=strategy_id,strategy_name,");
        urlCurrentView = `${urlData}&metrics=${selectedMetric0.value},${selectedMetric1.value}`;
      }
      const urlAllMetrics = urlData;
      this.$linkCurrentView.attr("href", urlCurrentView);
      this.$linkAllMetrics.attr("href", urlAllMetrics);
    },
    downloadHandler: function (event) {
      switch (event.currentTarget.id) {
        case "current":
          this.$linkCurrentView[0].click();
          break;
        case "all":
          this.$linkAllMetrics[0].click();
          break;
        case "png":
          T1Publish("change:export", "png");
          break;
        case "print":
          T1Publish("change:export", "print");
          break;
        case "pdf":
          T1Publish("change:export", "pdf");
          break;
      }
      this.closeMenu();
    }
  });
});
