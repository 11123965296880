define('utils/AttributeUtils',["Underscore", "jQuery", "T1", "collections/attributes", "NaturalSort", "Mustache", "collections/segments", "text!modules/admin/shared/templates/deletedAttributesWarning.html"], function (_, $, T1, Attributes, NaturalSort, Mustache, Segments, DeletedAttributesTemplate) {
  "use strict";

  var _requiredFields = ["key", "name", "data_type"];
  var myModified = function (modifiedRows) {
    return _.reject(modifiedRows, function (row) {
      return _.any(_requiredFields, function (field) {
        return !row[field];
      });
    });
  };
  var hasReservedKey = function (model) {
    var pattern = /(^[sv][0-9]*)/g;
    return pattern.test(model.key);
  };
  var sortValues = function (values) {
    return values.sort(function (x, y) {
      var xSortValue = getSortValue(x);
      var ySortValue = getSortValue(y);
      if (xSortValue === ySortValue) {
        return NaturalSort(x.key, y.key);
      }
      return xSortValue - ySortValue;
    });
    function getSortValue(key) {
      const SORT_VAL = 2;
      return hasReservedKey(key) ? SORT_VAL : 1;
    }
  };
  var findTemplate = function (segments) {
    var targetedSegments = _.filter(segments, function (segment) {
      var targetedProp = _.findWhere(segment.prop, {
        name: "pixel_targeted_strategies"
      });
      segment.type = _.findWhere(segment.prop, {
        name: "segment_type"
      }).value;
      return Boolean(targetedProp.item);
    });
    var includesUntargetedSegment = Boolean(segments.length);
    var includesTargetedSegment = Boolean(targetedSegments.length);
    var data = {};
    if (includesTargetedSegment) {
      data.includesTargetedSegment = true;
      data.segments = targetedSegments;
    } else if (includesUntargetedSegment) {
      data.includesUntargetedSegments = true;
      data.segments = segments;
    } else {
      data.notInSegments = true;
    }
    return Mustache.to_html(DeletedAttributesTemplate, data);
  };
  return {
    validation: function (data, added, modified) {
      var allKeys = _.pluck(data, "key");
      var isGlobalAdmin = this;
      return modified.concat(added).map(function (model) {
        var uniqueKey = _.countBy(allKeys, function (key) {
          return key === model.key;
        }).true === 1;
        var allBlank = (!model.key || hasReservedKey(model)) && !model.data_type && !model.name;
        var keyValidation = function (key) {
          if (isGlobalAdmin) {
            return Boolean(key);
          }
          return /^[sv]([1-9]|1[0-9]|20)$/g.test(key);
        };
        if (!allBlank) {
          return {
            cId: model.cId,
            name: model.name || allBlank ? null : "Attribute name required",
            data_type: model.data_type || allBlank ? null : "Data type required",
            key: uniqueKey ? keyValidation(model.key) || allBlank ? null : "Data key required" : "Data keys must be unique"
          };
        }
        return undefined;
      }).filter(StrandLib.DataUtils.isDef);
    },
    hasDeletedAttributes: function () {
      var repeaterRemoved = _.any(this.attributeRepeater.removed, model => Boolean(model.id));
      var newStandardAttributes = this.el.find(".standard-attributes").val();
      var existingStandardAttributes = Object.keys(this.savedStandardAttributes);
      var deletedStandardAttributes, standardAttrRemoved;
      newStandardAttributes = _.sortBy(newStandardAttributes, function (name) {
        return name;
      });
      existingStandardAttributes = _.sortBy(existingStandardAttributes, function (name) {
        return name;
      });
      deletedStandardAttributes = _.difference(existingStandardAttributes, newStandardAttributes);
      standardAttrRemoved = deletedStandardAttributes.length > 0;
      return repeaterRemoved || standardAttrRemoved;
    },
    prepopulateDataAttributes: function (viewOnly) {
      const TOTAL_ATTRIBUTE_KEYS = 21;
      const NUMBER_EACH_KEY_SET = 10;
      var models = this.dataAttributes.models;
      var values = [];
      var attrValues, dataKey;
      var fixedKeys = new Array(NUMBER_EACH_KEY_SET).map(function (a, num) {
        return `s${num + 1}`;
      }, Number).concat(new Array(NUMBER_EACH_KEY_SET).map(function (a, num) {
        return `v${num + 1}`;
      }, Number));
      var self = this;
      var overrideAdv = this.overrideSelected && !this.overrideDisabled;
      self.savedStandardAttributes = {};
      _.each(models, function (model) {
        attrValues = cleanAttrData(model);
        if (attrValues.key in self.whiteList) {
          if (overrideAdv) {
            delete attrValues.id;
          }
          self.savedStandardAttributes[attrValues.key] = attrValues;
          return true;
        }
        if (overrideAdv) {
          values.push({
            key: attrValues.key,
            name: attrValues.name,
            data_type: attrValues.data_type,
            source: "site",
            owner_type: self.model.get("type"),
            owner_id: self.model.id,
            saved: false,
            fixedKey: $.inArray(attrValues.key, fixedKeys) >= 0,
            override_advertiser_attr: "1",
            advertiser_id: attrValues.owner_id,
            advertiser_attr_id: attrValues.id
          });
        } else {
          values.push(attrValues);
        }
      });
      if (!viewOnly) {
        for (let i = 1; i < TOTAL_ATTRIBUTE_KEYS; i++) {
          if (i <= NUMBER_EACH_KEY_SET) {
            dataKey = `s${parseInt(i, 10)}`;
          } else {
            dataKey = `v${parseInt(i - NUMBER_EACH_KEY_SET, 10)}`;
          }
          if (!findAttributeByKey(dataKey)) {
            values.push({
              key: dataKey,
              name: "",
              data_type: null,
              source: "site",
              owner_type: this.model.get("type"),
              owner_id: this.model.id,
              saved: false,
              fixedKey: true
            });
          }
        }
      }
      function findAttributeByKey(key) {
        return models.find(function (model) {
          return model.get("key") === key;
        });
      }
      function cleanAttrData(model) {
        var json;
        const TRUNCATION = String("query.").length;
        if (model.get("key").match(/^query\./)) {
          model.set({
            key: model.get("key").substring(TRUNCATION)
          });
        }
        json = model.toJSON();
        json.saved = true;
        switch (json.data_type) {
          case "text":
            json.data_type = "String";
            break;
          case "numeric":
            json.data_type = "Numeric";
            break;
          case "datetime":
            json.data_type = "Date";
            break;
        }
        return json;
      }
      return sortValues(values);
    },
    loadData: function (view, viewOnly) {
      view.attributeRepeater.set("data", this.prepopulateDataAttributes.call(view, viewOnly));
      view.el.find(".attributes-loader").hide();
      view.el.find(".attribute-repeater-container").show();
    },
    saveAttributes: function (repeater) {
      var def = $.Deferred();
      var self = this;
      var crudActions = [];
      var attributes, attributesToDelete, cleanedAddedRows, errorType, modifiedAndAdded, overrideAddedRows, rowsToDelete, rowsToSave;
      var newStandardAttributes = this.el.find(".standard-attributes").val();
      var overrideAdv = this.overrideSelected && !this.overrideDisabled;
      if (overrideAdv) {
        this.savedStandardAttributes = {};
      }
      if (!newStandardAttributes) {
        newStandardAttributes = [];
      }
      if (repeater) {
        cleanedAddedRows = cleanAddedRows(repeater.added);
        modifiedAndAdded = repeater.modified.concat(cleanedAddedRows);
        overrideAddedRows = _.filter(repeater.data, function (attr) {
          if (attr.override_advertiser_attr) {
            if (!listContainsAttribute(attr, modifiedAndAdded)) {
              return true;
            }
          }
        });
        modifiedAndAdded = modifiedAndAdded.concat(overrideAddedRows);
        rowsToSave = myModified(modifiedAndAdded);
        $.each(rowsToSave, function (index, row) {
          row.key = `query.${row.key}`;
        });
        rowsToDelete = _.filter(repeater.removed, function (attr) {
          return attr.id;
        });
        $.each(this.savedStandardAttributes, function (key, value) {
          if (_.indexOf(newStandardAttributes, key) >= 0) {
            newStandardAttributes = _.without(newStandardAttributes, key);
          } else {
            rowsToDelete.push(value);
          }
        });
        $.each(newStandardAttributes, function (index, key) {
          var standardAttr = self.whiteList[key];
          standardAttr.owner_type = self.model.get("type");
          standardAttr.owner_id = self.model.id;
          standardAttr.source = "site";
          switch (standardAttr.data_type) {
            case "text":
              standardAttr.data_type = "String";
              break;
            case "numeric":
              standardAttr.data_type = "Numeric";
              break;
          }
          rowsToSave.push(standardAttr);
        });
        attributes = new Attributes(rowsToSave);
        attributesToDelete = new Attributes(rowsToDelete);
        if (attributes.length) {
          crudActions.push(attributes.saveAll({
            owner: this.model
          }));
        }
        if (attributesToDelete.length) {
          crudActions.push(attributesToDelete.deleteAll());
        }
        $.when(...crudActions).then(function () {
          def.resolve();
        }, function (message) {
          errorType = anySaved(crudActions) ? "partial" : "failure";
          def.reject(message, errorType);
        });
      } else {
        def.resolve();
      }
      function cleanAddedRows(rows) {
        return _.map(rows, function (row) {
          row.source = "site";
          row.owner_type = self.model.get("type");
          row.owner_id = self.model.id;
          return row;
        });
      }
      function anySaved(promises) {
        return _.any(promises, function (promise) {
          return promise.state() === "resolved";
        });
      }
      function listContainsAttribute(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
          if (list[i].advertiser_attr_id === obj.advertiser_attr_id && list[i].advertiser_id === obj.advertiser_id) {
            return true;
          }
        }
        return false;
      }
      return def.promise();
    },
    getRemovedAttribute: function (repeater) {
      return repeater.removed[repeater.removed.length - 1];
    },
    undoDeleteAttribute: function () {
      var attribute, removed, repeater;
      if (this.deleteAttributeType === "custom") {
        repeater = this.attributeRepeater;
        attribute = repeater.removed.pop();
        removed = repeater.removed.slice();
        repeater.set("data", sortValues(repeater.data.concat([attribute])));
        repeater.set("removed", removed);
      } else if (this.deleteAttributeType === "standard") {
        attribute = this.deleteAttribute;
        this.el.find(`.standard-attributes option[value="${attribute.key}"]`).attr("selected", "selected");
        this.el.find(".standard-attributes").trigger("liszt:updated");
      }
    },
    renderDeleteWarning: function (attribute) {
      var def = $.Deferred();
      var segments = new Segments();
      var includedSegments = [];
      var template;
      var limit = 10;
      var success = function (response) {
        var parsedResponse = T1.Utils.parseXMLtoJson(response);
        var entities, parsedSegments;
        if (parsedResponse) {
          entities = parsedResponse.entities.entity;
          parsedSegments = [];
          if (entities instanceof Array) {
            parsedSegments = entities;
          } else if (entities instanceof Object) {
            parsedSegments[0] = entities;
          }
          includedSegments = includedSegments.concat(parsedSegments);
          if (includedSegments.length !== parseInt(parsedResponse.entities.count, 10)) {
            segments.pageOffset = segments.pageOffset + limit;
            segments.fetch({
              onSuccess: success
            });
          } else {
            template = findTemplate(includedSegments);
            def.resolve(template);
          }
        } else {
          def.reject(response);
        }
      };
      segments.pageLimit = limit;
      segments.pageOffset = 0;
      segments.ownerType = "behaviors.attribute";
      segments.entityId = attribute.id;
      segments.sortBy = "-created_on";
      segments.with = "pixel_target";
      segments.fetch({
        onSuccess: success
      });
      return def.promise();
    },
    closeWarning: function () {
      var loader = this.deleteLoader ? this.deleteLoader : this.el.find(".delete-loader");
      if (this.modal) {
        this.modal.remove();
        this.modal = null;
      }
      this.deleteAttribute = null;
      this.deleteAttributeType = null;
      this.el.find(".save, .saveAndClose").removeClass("disabled");
      this.el.find(".attribute-repeater-container, .standard-attributes-container").css("pointer-events", "auto");
      loader.hide();
    },
    showDeletedAttributeWarning: function (warningTemplate) {
      var maxHeight = 450;
      const HEADER_HEIGHT = 60;
      this.modal = new Strand.Modal();
      const container = this.modal.$.container;
      this.modal.dismiss = false;
      this.modal.className = "attributes-warning";
      container.innerHTML = warningTemplate;
      this.el.find(".w-CreateEditForm").append(this.modal);
      this.modal.show();
      if (container.clientHeight > maxHeight) {
        container.style.height = `${maxHeight}px`;
        $(this.modal).find("strand-scroll-panel").height(maxHeight - HEADER_HEIGHT);
      }
    },
    disableForm: function () {
      var loader = this.deleteLoader ? this.deleteLoader : this.el.find(".delete-loader");
      this.el.find(".save, .saveAndClose").addClass("disabled");
      this.el.find(".attribute-repeater-container, .standard-attributes-container").css("pointer-events", "none");
      loader.show();
    },
    removeAttributeHandler: function (view, attribute) {
      var repeater = view.attributeRepeater;
      var self = this;
      if (!attribute) {
        attribute = this.getRemovedAttribute(repeater);
        view.deleteAttributeType = "custom";
      } else {
        view.deleteAttributeType = "standard";
      }
      view.deleteAttribute = attribute;
      if (attribute.id) {
        this.disableForm.call(view);
        this.renderDeleteWarning(attribute).then(function (warning) {
          view.showDeletedAttributeWarning(warning);
        }, function (response) {
          view.attributeScroll = false;
          self.undoDeleteAttribute.call(view);
          self.closeWarning.call(view);
          T1.EventHub.publish("comm:error", {
            detail: response,
            url: "/dmp/v2.0/segments/limit/behaviors.attribute"
          });
        });
      }
      if (this.hasDeletedAttributes.call(view)) {
        view.isFormElementsChanged = true;
      }
    }
  };
});
