define('modules/reporting/segments/dataExport/views/dataExport',["jQuery", "T1", "T1View", "T1Layout", "text!../templates/data-export.html"], function ($, T1, T1View, T1Layout, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {},
    dataEvents: {},
    eventhubEvents: {
      "manage.createEditReport": "loadCreateEdit"
    },
    initialize: function (cfgs) {
      var models = cfgs.models;
      DataExportModel = this.DataExportModel = models.DataExport;
    },
    initLayouts: function initLayouts() {
      var self = this;
      this.manageLayout = new T1Layout({
        el: function el() {
          return self.el;
        },
        template: template,
        layout: {
          ".manage": [{
            module: "reporting/segments/dataExport/manage",
            viewType: "manage"
          }]
        }
      });
      this.createEditLayout = new T1Layout({
        el: function el() {
          return self.el;
        },
        template: template,
        layout: {
          ".create-edit": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "createEdit",
            options: {
              dataExportModel: DataExportModel
            }
          }]
        }
      });
      this.assignLayout();
    },
    assignLayout: function assignLayout() {
      this.layout = this.reportAction === "manage" ? this.manageLayout : this.createEditLayout;
    },
    load: function () {
      var self = this;
      if (this.layout) {
        this.layout.unload();
      }
      this.reportAction = "edit";
      this.initLayouts();
      this.render().then(function render() {
        self.layout.load();
      });
      T1.repositionLeft();
    },
    loadCreateEdit: function loadCreateEdit() {
      this.reportAction = "edit";
      this.layout.unload();
      this.assignLayout();
      this.layout.load();
    },
    unload: function () {},
    serialize: function () {
      return {
        test: "Hello World!"
      };
    }
  });
});
