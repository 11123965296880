define('modules/campaign/siteList/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "campaign/siteList",
    viewCfgs: {
      panel: {
        campaign: null
      },
      bins: {
        sharedCollection: null
      }
    },
    defaultView: "panel"
  });
});
