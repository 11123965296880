define('collections/pixelAdvertisers',["T1", "T1Collection", "T1Sortable", "models/advertiser"], function (T1, T1Collection, Sortable, Advertiser) {
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: Advertiser,
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    initialize: function (models, args) {
      var strategyId = args.strategy_id;
      var campaignId;
      T1Collection.prototype.initialize.apply(this, [models, args]);
      if (args && args.campaign_id) {
        campaignId = args.campaign_id;
        this.urlString = "campaigns/" + campaignId + "/pixel_targeting_advertisers";
      } else {
        this.urlString = "strategies/" + strategyId + "/pixel_targeting_advertisers";
      }
      this.fetchOptions.full = "*";
    },
    urlString: "pixel_targeting_advertisers"
  });
  T1.Models.PixelAdvertisers = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.PixelAdvertisers;
});
