define('models/strategyTargetingAttachment',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  var StrategyTargetingAttachment = T1Model.extend({
    url: function () {
      var id = this.get("id");
      return id ? T1.API_ROOT + "strategies/" + id + "/targeting_attachment?target_ids=vendor" : "";
    },
    fetch: function (opts) {
      opts = opts || {};
      opts.url = this.url();
      return T1Model.prototype.fetch.call(this, opts);
    }
  });
  T1.Models.StrategyTargetingAttachment = StrategyTargetingAttachment;
  return StrategyTargetingAttachment;
});
