define('modules/targeting/contextual/views/readOnly',["jQuery", "Underscore", "T1", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils", "utils/currencyConversionHelper", "T1Tooltip"], function ($, _, T1, T1View, template, Utils, CurrencyUtils) {
  "use strict";

  function buildOneLineItem(sideTitle, displayText) {
    return {
      sideTitle: sideTitle,
      data: [{
        items: [{
          full_path: displayText,
          matOrUGCSegment: true
        }],
        matOrUGC: true
      }]
    };
  }
  function getMaturityData(targetDimColl) {
    const ids = ["1591", "1592", "1593"];
    let result = null;
    $.each(ids, function (index, id) {
      const targetValueModel = targetDimColl.get(parseInt(id, 10));
      if (targetValueModel && targetValueModel.get("dimension_code") === "SFTY") {
        result = buildOneLineItem("maturity filter", dataTextHash[id]);
        return false;
      }
    });
    return result;
  }
  function getUgcData(targetDimColl) {
    const ids = ["1597", "1598"];
    const targetValueModel = targetDimColl.get(parseInt(ids[1], 10));
    const dimensionCode = targetValueModel ? targetValueModel.get("dimension_code") : null;
    const sideTitle = "user generated<br/>content (ugc)";
    if (targetValueModel && targetDimColl.get(parseInt(ids[0], 10)) && dimensionCode === "SFTY") {
      return buildOneLineItem(sideTitle, dataTextHash[`${ids[1]},${ids[0]}`]);
    } else if (targetValueModel && dimensionCode === "SFTY") {
      return buildOneLineItem(sideTitle, dataTextHash[ids[1]]);
    }
    return null;
  }
  function populateHash(key, tmpObj, hash, currency) {
    const {
      uniques: uniques,
      user_cpm: user_cpm,
      full_path: full_path,
      full_retired_path: full_retired_path,
      type: type
    } = tmpObj;
    if (!hash[key]) {
      hash[key] = [];
    }
    hash[key].push({
      size: uniques ? formatNumber(parseInt(uniques, 10)) : "--",
      asCurrency: currency,
      cpm: user_cpm === undefined ? tmpObj.retail_cpm : user_cpm || "--",
      full_path: full_path || full_retired_path ? type !== "retired_targeting_segment" ? full_path.replace(/ - /g, " &raquo ") : full_retired_path.replace(/ - /g, " &raquo ") : "&nbsp;",
      showRetiredWarningIcon: type === "retired_targeting_segment",
      matOrUGCSegment: false
    });
  }
  function populateData(resultJSON, operators, hasData, mode, hash) {
    Object.entries(hash).forEach(function ([key, itemArr]) {
      const vendorEntities = {
        [key]: itemArr
      };
      const data = createDataBlock(vendorEntities);
      if (data.length) {
        const sideTitle = mode === "include" ? `included channels<br/>( ${operators[key]} )` : `excluded channels<br/>( OR )`;
        hasData[mode] = true;
        resultJSON.dataMain.push({
          sideTitle: sideTitle,
          data: data
        });
      }
    });
  }
  const currencyFormatterGetter = T1.Utils.getCurrencyTemplateFunc;
  const dataTextHash = Utils.maturityUgcDataDisplayHash;
  const createDataBlock = Utils.createGroupedDataArray;
  const formatNumber = T1.Formats.rolledUpNumbersShort();
  const readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": "prepareData",
      "locationTargeting.ready": "loadSegments"
    },
    initialize: function ({
      strategy: strategy,
      model: model,
      retiredSegmentsCollection: retiredSegmentsCollection,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit,
      targetDimentions: targetDimentions
    }) {
      this.strategy = strategy;
      this.currencyCode = this.strategy ? this.strategy.getCurrencyCode() : "";
      this.groupedSegments = model;
      this.retiredSegments = retiredSegmentsCollection;
      this.canLoad = false;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.targetDimensions = targetDimentions;
      this.isDimensionDataReady = this.targetDimensions.get(Utils.names.targetDimensions).loaded;
    },
    loadSegments() {
      this.groupedSegments.fetch({
        success: dataGrouped => {
          this.partialCollection = dataGrouped.get("partials");
          this.collection = dataGrouped.get("user_saved");
          this.collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.collection, this.currencyCode);
          this.retiredSegments.fetchOptions.currency_code = this.strategy.getCurrencyCode();
          this.retiredSegments.strategyId = this.strategy.id;
          this.retiredSegments.fetch({
            success: dataRetired => {
              this.canLoad = true;
              this.retiredCollection = dataRetired;
              this.collection = this.collection.add(this.retiredCollection.models, {
                silent: true
              });
              this.prepareData();
            }
          });
        },
        currency_code: this.strategy.getCurrencyCode()
      });
    },
    prepareData: function () {
      var prepareDataUsingLib = Utils.prepareTargetDimensionData;
      const data = this.targetDimensions;
      var targetingVendorOperators = {};
      this.prepareDataIsCalled = true;
      this.targetDimensionsCollection = prepareDataUsingLib(data, dimCode => dimCode === "SFTY");
      if (this.collection) {
        this.collection.each(function (model) {
          const modelObj = model.toJSON();
          const fullPath = modelObj.full_path;
          if (fullPath.split(" - ")) {
            const vendorName = fullPath.split(" - ")[0];
            if (modelObj.action === "include") {
              targetingVendorOperators[vendorName] = modelObj.operator;
            }
          }
        });
        this.collection.targetingVendorOperators = targetingVendorOperators;
      }
      this.load();
    },
    load: function () {
      this.render().then(() => {
        const loader = this.loader();
        if (!this.canLoad || this.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
        T1.Tooltip(this.$(".warning-holder"), {});
      });
    },
    serialize: function () {
      const {
        currencyCode: currencyCode,
        collection: collObj,
        targetDimensionsCollection: targetDimColl
      } = this;
      var resultJSON = {
        dataMain: []
      };
      var codeNameHashInc = {};
      var codeNameHashExc = {};
      const hasData = {
        include: false,
        exclude: false
      };
      var hasUgcOrMaturityData = false;
      function callback(model) {
        const key = this;
        const tmpObj = model.toJSON();
        const hash = tmpObj.action === "include" ? codeNameHashInc : codeNameHashExc;
        const currency = currencyFormatterGetter(currencyCode);
        populateHash(key, tmpObj, hash, currency);
      }
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/contextual`;
      }
      if ((!collObj || !collObj.models || !collObj.models.length) && (!targetDimColl || !targetDimColl.length)) {
        this.hasData = false;
        return resultJSON;
      }
      if (!collObj) {
        return {};
      }
      const sortedCollObj = _.groupBy(collObj.models, function (model) {
        return model.get("type") !== "retired_targeting_segment" ? model.get("full_path").split(" - ")[0] : model.get("full_retired_path").split(" - ")[0];
      });
      Object.entries(sortedCollObj).forEach(function ([key, modelArr]) {
        modelArr.forEach(callback, key);
      });
      populateData(resultJSON, collObj.targetingVendorOperators, hasData, "include", codeNameHashInc);
      populateData(resultJSON, collObj.targetingVendorOperators, hasData, "exclude", codeNameHashExc);
      if (this.targetDimensionsCollection) {
        let tempData = getMaturityData(targetDimColl);
        if (tempData) {
          hasUgcOrMaturityData = true;
          resultJSON.dataMain.push(tempData);
        }
        tempData = getUgcData(targetDimColl);
        if (tempData) {
          hasUgcOrMaturityData = true;
          resultJSON.dataMain.push(tempData);
        }
      }
      if (!hasData.include && hasUgcOrMaturityData) {
        resultJSON.onlyUgcOrMaturityData = true;
      }
      this.hasData = resultJSON.hasData = hasData.include || hasData.exclude || hasUgcOrMaturityData;
      if (resultJSON.hasData && !resultJSON.onlyUgcOrMaturityData) {
        resultJSON.firstSideTitle = resultJSON.dataMain[0].sideTitle;
        resultJSON.dataMain[0].sideTitle = "&nbsp;";
        resultJSON.dataMain[0].isFirstSideTitle = true;
        resultJSON.dataMain[0].data[0].firstItem = true;
      }
      resultJSON.headerTooltipRetiredSegmentsWarningText = Utils.headerTooltipRetiredSegmentsWarningText;
      resultJSON.headerWarningUrl = Utils.perfDataWarningKbUrl;
      return resultJSON;
    }
  });
  return readOnlyView;
});
