define('modules/reporting/segments/dataExport/manage/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "manage",
    name: "reporting/segments/dataExport/manage",
    viewCfgs: {
      grid: {},
      header: {},
      manage: {}
    }
  });
});
