define('T1Tooltip',["jQuery", "T1", "jQPlugins/jquery.tipsy"], function ($, T1) {
  T1.Tooltip = function (context, configs) {
    $.fn.tipsy.autoSWE = function () {
      var $el = $($(this).data("tipsy").tip());
      return $(this).offset().left < $(document).scrollLeft() ? "sw" : $(this).offset().left + ($(this).width() + $el.outerWidth()) / 2 > $(document).scrollLeft() + $(window).width() ? "se" : "s";
    };
    var autoGravityMap = {
      swe: $.fn.tipsy.autoSWE
    };
    var options = {
      html: true,
      delayIn: 250,
      delayOut: 250,
      opacity: 1,
      getExternalTip: true,
      fade: true,
      gravity: "s",
      offset: 0
    };
    var tooltipEle = configs && configs.tooltipEle ? configs.tooltipEle : ".t1-tooltip";
    var toolTipElements;
    function unbindToolTips() {
      var events = "mouseover mouseout focus blur";
      toolTipElements.unbind(events).die(events);
      $.data(toolTipElements, "tipsy", null);
    }
    function removeTooltips() {
      toolTipElements.remove();
    }
    context = context || "body";
    configs = configs || {};
    configs.gravity = autoGravityMap[configs.gravity] || configs.gravity;
    options = $.extend(options, configs);
    toolTipElements = $(tooltipEle, context);
    unbindToolTips();
    toolTipElements.tipsy(options);
    return {
      unbind: unbindToolTips,
      remove: removeTooltips
    };
  };
});
