define('T1Cache',["T1"], function (T1) {
  var instance;
  function Cache() {
    var hashMap = {};
    function remove(cacheKey) {
      if (cacheKey) {
        hashMap[cacheKey] = null;
      }
    }
    function setValue(cacheKey, value, duration) {
      var hashObj;
      var ts;
      if (!cacheKey) {
        return;
      }
      hashObj = hashMap[cacheKey];
      if (!hashObj) {
        ts = new Date().getTime();
        hashObj = {
          time: ts,
          value: value,
          duration: duration || 3e5
        };
      } else {
        hashObj.value = value;
      }
      hashMap[cacheKey] = hashObj;
    }
    function setDuration(cacheKey, duration) {
      var hashObj;
      if (!cacheKey) {
        return;
      }
      hashObj = hashMap[cacheKey];
      if (hashObj) {
        hashObj.duration = duration;
        hashMap[cacheKey] = hashObj;
      }
    }
    function retrieve(cacheKey) {
      if (!cacheKey) {
        return null;
      }
      return hashMap[cacheKey].value;
    }
    function isCached(cacheKey) {
      var hash;
      var ts;
      var time;
      var duration;
      hash = cacheKey;
      if (!cacheKey) {
        return false;
      }
      if (!hashMap[cacheKey]) {
        return false;
      }
      ts = Math.round(new Date().getTime());
      time = hashMap[cacheKey].time;
      duration = hashMap[cacheKey].duration ? hashMap[cacheKey].duration : 3e5;
      if (ts - time > duration) {
        remove(cacheKey);
        return false;
      }
      return true;
    }
    function buildKeyFromQuery(queryString, API_ROOT, excludeArray) {
      var subString;
      if (queryString) {
        subString = queryString.split(API_ROOT)[1];
        if (subString) {
          if (excludeArray) {
            return removeParams(subString, excludeArray);
          } else {
            return subString;
          }
        }
      }
      return null;
    }
    function removeParams(subString, excludeArray) {
      var result;
      var rgx;
      result = subString;
      for (var i = 0; i < excludeArray.length; i++) {
        rgx = new RegExp("[?&]" + excludeArray[i] + "(?:=([^&]*))?", "i");
        result = result.replace(rgx, "");
      }
      return result;
    }
    return {
      setValue: setValue,
      setDuration: setDuration,
      retrieve: retrieve,
      isCached: isCached,
      removeParams: removeParams,
      remove: remove,
      buildKeyFromQuery: buildKeyFromQuery,
      hashMap: hashMap
    };
  }
  if (!instance) {
    instance = new Cache();
  }
  return instance;
});
