define('modules/creatives/video/views/list',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Permissions", "text!../templates/list.html", "text!../templates/listBody.html", "collections/creativesVideo", "utils/SearchBuilder", "utils/CreativeUtils", "../models/creativeActive", "models/userPreferences", "models/videoCreativeDetail", "models/advertiser", "models/staticData", "../control/ParallelDeferred", "T1Preferences", "jQPlugins/jquery.cookie"], function ($, _, When, T1, T1View, T1Layout, T1Permissions, template, templateBody, VideoCreativesCollection, SearchBuilder, CreativeUtils, selectOptions, UserPreferences, VideoCreativeDetail, Advertiser, StaticData, ParallelDeferred) {
  return T1View.extend({
    template: template,
    searchTerm: "",
    advID: "",
    active: "",
    events: {
      "click .add-action": "addCreative",
      "changed:checkbox .w-grid": "gridChange",
      "focus .w-Filters input.text": "showSearchTooltip"
    },
    dataEvents: {
      collection: {
        reset: "onCollectionEvent",
        "change:selected": "onItemSelected"
      }
    },
    eventhubEvents: {
      "organization:select": function () {
        this.searchBuilder.clearFlag("advertiser", "id");
        this.advID = null;
        this.preferences.save();
        this.filterAndClearCallback("", null, true);
        this.initLayout();
        this.load();
      }
    },
    onCollectionEvent: function () {
      this.isDisabled = this.collection.loaded === false;
      this.toggleFilters(this.isDisabled);
      this.toggleBulkAction(this.isDisabled);
    },
    toggleFilters: function (isDisabled) {
      var self = this;
      var $el = $(self.el);
      $el.find(".status .select-input").attr("disabled", isDisabled).trigger("liszt:updated").toggleClass("disabled", isDisabled);
    },
    onItemSelected: function () {
      if (this.resubmitEnabled) {
        CreativeUtils.toggleResubmitMenuOption(this.collection, this.bulkActionMenuItems);
      }
      if (this.violationFlaggingEnabled) {
        CreativeUtils.toggleViolationMenuOption(this.collection, this.bulkActionMenuItems);
      }
      this.toggleBulkAction(this.isDisabled);
    },
    toggleBulkAction: function (isDisabled) {
      var selected = this.collection.getSelected();
      if (!isDisabled && selected.length > 0) {
        T1.EventHub.publish("pullDownButton:bulk-action:enable");
      } else {
        T1.EventHub.publish("pullDownButton:bulk-action:disable");
      }
    },
    initialize: function (args) {
      var self = this;
      var isReturning = $.cookie("isReturningVideo");
      var signature = T1.Signature.create();
      this.advertiserCollection = args.advertisers;
      this.allAdvertisersLoaded = false;
      signature.set(new Map([["root", "ui"], ["module", "creatives.video"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["active", "searchTerm", "advID"]);
      this.resubmitEnabled = T1Permissions.check("feature", "creative_resubmit");
      this.violationFlaggingEnabled = T1Permissions.check("feature", "creatives_violation");
      if (isReturning === "true") {
        $.removeCookie("isReturningVideo");
        this.searchTerm = "";
        this.advID = "";
        this.active = "Active";
        this.preferences.save();
      } else {
        this.searchTerm = this.preferences.get("searchTerm") || "";
        this.advID = this.preferences.get("advID") || null;
        this.active = this.preferences.get("active") || "Active";
      }
      if (this.searchTerm) {
        T1.EventHub.publish("creativegrid:setHighlightValue", this.searchTerm);
      }
      this.lazySearch = _.debounce(this.search, 700);
      this.searchBuilder = new SearchBuilder({
        active_state: {
          active: {
            term: "true",
            searchField: "status"
          },
          inactive: {
            term: "false",
            searchField: "status"
          }
        },
        advertiser: {
          id: {
            term: this.advID,
            searchField: "advertiser_id"
          }
        },
        search: {
          input: {
            term: this.searchTerm,
            searchField: "multiField"
          }
        }
      }, {
        term: "video",
        searchField: "media_type"
      });
      if (this.advID === null) {
        this.searchBuilder.clearFlag("advertiser", "id");
      }
      $(window).one("beforeunload", function () {
        $.cookie("isReturningVideo", true);
        $.cookie("isReturning", true);
        return null;
      });
      if (this.active.toLowerCase() !== "all") {
        this.searchBuilder.setFlag("active_state", this.active.toLowerCase());
      }
      this.staticVerticals = new StaticData({
        action: "iab_verticals"
      });
      this.staticVerticals.fetch({
        onSuccess: function (data) {
          self.staticVerticals.set({
            data: data
          });
        }
      });
      this.collection = new VideoCreativesCollection(null, {
        pageLimit: 100
      });
      this.collection.updateOrgFilter();
      this.getAdvertiserData().then(function () {
        self.initLayout();
        self.load();
      });
    },
    getAdvertiserData: function () {
      var self = this;
      var deferred = When.defer();
      self.advertiserCollection.isPaginated = true;
      self.advertiserCollection.pageLimit = 50;
      self.advertiserCollection.fetch({
        success: function () {
          if (self.searchTerm || self.advID && self.advID !== null && self.advID !== "null") {
            self.filterAndClearCallback(self.searchTerm, {
              id: self.advID
            }, false);
          } else {
            self.filterAndClearCallback("", null, false);
          }
          self.allAdvertisersLoaded = true;
          if (self.advID && self.advID !== "null") {
            var model = self.advertiserCollection.get(self.advID);
            if (model) {
              model.set({
                selected: true
              });
              self.advertiserCollection.add(model, {
                merge: true
              });
              deferred.resolve(self.advertiserCollection);
            } else {
              model = new Advertiser({
                id: self.advID
              });
              model.fetch({
                success: function (adModel) {
                  adModel.set({
                    selected: true
                  });
                  self.advertiserCollection.add(adModel);
                  deferred.resolve(self.advertiserCollection);
                }
              });
            }
          } else {
            deferred.resolve(self.advertiserCollection);
          }
        }
      });
      return deferred.promise;
    },
    getAdvertiserList: function () {
      var options = this.advertiserCollection.map(function (adver) {
        return {
          text: adver.get("name"),
          value: adver.id,
          selected: adver.get("selected")
        };
      });
      options.unshift({
        text: "All Advertisers",
        value: "all"
      });
      return options;
    },
    filterAndClearCallback: function (term, flags, savePref) {
      var save = savePref === undefined ? true : savePref;
      this.advID = flags && flags.id !== "" ? flags.id : this.advID;
      if (this.advID && this.advID !== "all" && this.advID !== "null") {
        this.searchBuilder.setFlag("advertiser", "id", {
          term: this.advID
        });
      } else {
        if (this.advIDPref === "all") {
          this.clearTheFilter();
        }
        this.searchBuilder.clearFlag("advertiser", "id");
        this.advID = null;
      }
      this.searchTerm = term || "";
      if (save) {
        this.preferences.save();
      }
      this.search();
    },
    reload: function () {
      var self = this;
      this.initialize({
        advertisers: self.advertiserCollection
      });
    },
    initLayout: function () {
      var self = this;
      var searchTerm = self.searchTerm;
      var advertiserList;
      if (self.allAdvertisersLoaded) {
        advertiserList = $.proxy(self.getAdvertiserList, this);
        if (this.layout) {
          this.layout.destroy();
        }
        if (searchTerm) {
          searchTerm = decodeURIComponent(searchTerm);
        }
        this.bulkActionMenuItems = [{
          itemLabel: "Activate",
          itemCallback: $.proxy(self.bulkAction, self, {
            active: true
          })
        }, {
          itemLabel: "Deactivate",
          itemCallback: $.proxy(self.bulkAction, self, {
            active: false
          })
        }, {
          itemLabel: "Edit",
          itemCallback: $.proxy(self.showBulkEdit, self)
        }, ...(this.violationFlaggingEnabled ? [{
          itemLabel: "Flag for Violation",
          itemCallback: $.proxy(self.showFlagViolationDialog, self)
        }] : []), {
          itemLabel: "Export Approval Status",
          itemCallback: $.proxy(self.exportApprovalStatus, self)
        }, ...(this.resubmitEnabled ? [{
          itemLabel: "Resubmit for Approval",
          itemCallback: $.proxy(self.resubmitForApproval, self),
          itemDisabled: true
        }] : [])];
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".list-filters",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: {
            ".search": [{
              module: "shared",
              viewType: "filter",
              options: {
                defaultValue: searchTerm,
                filterClass: "advertiser-select",
                selectOptions: advertiserList,
                isDisabled: function () {
                  return false;
                },
                filterCallback: $.proxy(self.filterAndClearCallback, this),
                clearFilterCallback: $.proxy(self.filterAndClearCallback, this),
                autoCompleteConfig: {
                  autoComplete: true,
                  searchField: "name",
                  onSearchInputCleared: $.proxy(self.clearTheFilter, this),
                  onSearchRequest: function (searchOptions) {
                    self.searchOptions = searchOptions;
                    searchOptions = $.extend({
                      fetchRequired: true
                    }, searchOptions);
                    self.advertiserCollection.search.set(searchOptions);
                    self.el.find(".advertiser-select:first").val("All Advertiser");
                  }
                }
              }
            }],
            ".bulk-action": [{
              module: "shared",
              viewType: "pullDownButton",
              options: {
                id: "bulk-action",
                menuLabel: "Choose Action",
                items: this.bulkActionMenuItems,
                disabled: true
              }
            }],
            ".w-grid": [{
              module: "creatives/video",
              viewType: "grid",
              options: {
                collection: self.collection,
                searchTerm: self.searchTerm,
                staticVerticals: self.staticVerticals
              }
            }]
          }
        });
      }
    },
    showFlagViolationDialog: function () {
      var selected = this.collection.getSelected();
      var models = selected.models;
      if (models && models.length === 1) {
        CreativeUtils.showFlagViolationDialog(models[0].toJSON(), "video");
      }
    },
    exportApprovalStatus: function () {
      var selected = this.collection.getSelected();
      var models = selected.models;
      var creativeIds = [];
      var downloadUrl = `${T1.API_ROOT}/atomic_creatives/creative_approvals_report?atomic_creative_ids=`;
      var dialog = this.el[0].querySelector("#creativeApprovalDialog");
      dialog.querySelector("label").textContent = "Cancel";
      if (models.length > 50) {
        setTimeout(function () {
          dialog.show();
        }, 0);
        return;
      }
      _.each(models, function (creative) {
        creativeIds.push(creative.id);
      });
      downloadUrl = `${downloadUrl}(${creativeIds.join()})` + `&report_type=csv`;
      window.open(downloadUrl);
    },
    resubmitForApproval: function () {
      CreativeUtils.resubmitSelectedForApproval(null, this.collection);
    },
    clearTheFilter: function () {
      var searchOptions = $.extend({
        fetchRequired: true
      }, this.searchOptions);
      this.advertiserCollection.search.clear();
      this.advertiserCollection.fetch(searchOptions);
    },
    showSearchTooltip: function (e) {
      var $target = $(e.currentTarget);
      var $parent = $target.parent();
      var className = "w-DisplaySearchTooltip";
      var msg = "Search by Name, ID, Advertiser or Concept";
      $(`.${className}`).remove();
      T1.Tooltip($target, {
        trigger: "manual",
        tooltipEle: $parent,
        getExternalTip: false,
        gravity: "s",
        className: className,
        offset: 10
      });
      $parent.attr("title", msg).tipsy("show");
    },
    showBulkEdit: function () {
      var selected = this.collection.getSelected();
      if (this.bulkEditLayout) {
        this.bulkEditLayout.destroy();
      }
      this.bulkEditLayout = new T1Layout({
        template: '<div class="bulk-edit thevideo"></div>',
        layout: {
          ".bulk-edit": [{
            module: "creatives/bulkedit",
            viewType: "dialog",
            options: {
              collection: selected,
              entityType: "video"
            }
          }]
        }
      });
      this.bulkEditLayout.load();
    },
    updateStatus: function (model) {
      var id = model.get("id");
      var active = model.get("active");
      var $item = $(`#id${id}`).find(".item");
      if (active === false) {
        $item.addClass("inactive");
        $item.removeClass("active");
      } else {
        $item.addClass("active");
        $item.removeClass("inactive");
      }
    },
    bulkAction: function (modelValues, success, statusInvalid, conflictCallback) {
      var self = this;
      var selected = this.collection.getSelected();
      self.successCount = 0;
      self.collectionCount = selected.length;
      selected.each(function (model) {
        var outputModel = new VideoCreativeDetail();
        var details = model.get("details");
        var name = details.get("name");
        var advertiser = details.get("advertiser");
        var concept = details.get("concept");
        var landingUrl = details.get("landingUrl");
        var active = modelValues.active;
        var startTime = details.get("startTime");
        var endTime = details.get("endTime");
        var skippableDuration = details.get("skippableDuration");
        var eventPixels = details.get("eventPixels");
        var vendors = details.get("vendors");
        var id = model.get("id");
        var loader = CreativeUtils.createInlineLoader(model.get("id"));
        loader.start();
        if (details.get("customVAST")) {
          outputModel.set({
            customVAST: details.get("customVAST")
          });
        } else if (details.get("customVASTUrl")) {
          outputModel.set({
            customVASTUrl: details.get("customVASTUrl")
          });
        } else {
          outputModel.set({
            clickthroughUrl: details.get("clickthroughUrl")
          });
        }
        outputModel.set({
          name: name,
          advertiser: advertiser,
          concept: concept,
          landingUrl: landingUrl,
          active: active,
          startTime: startTime,
          endTime: endTime,
          skippableDuration: skippableDuration,
          creativeId: id,
          id: id,
          eventPixels: eventPixels,
          vendors: vendors
        });
        new ParallelDeferred(outputModel.createEdit(outputModel.toJSON(), {
          statusInvalid: statusInvalid,
          conflict: conflictCallback
        }), function () {
          var def = $.Deferred();
          def.resolve();
          return def;
        }()).then($.noop, function () {
          statusInvalid([]);
        }).pipe(function () {
          var def = $.Deferred();
          var saveOpts = {
            dataType: "json",
            stringify: true,
            contentType: "application/json",
            processData: false,
            success: function (newModel) {
              def.resolve();
              self.successCount++;
              loader.stop();
              self.updateStatus(newModel);
              if (self.successCount === self.collectionCount) {
                T1.EventHub.publish("bulkaction:success");
                T1.Notify("message", `${selected.length} creative(s) updated successfully!`);
              }
            },
            statusInvalid: function (errors) {
              statusInvalid(errors, "There are errors on the page.", ".w-CreativesCreateEdit");
              loader.stop();
            },
            conflict: conflictCallback,
            errorDisplaySuppressingConfig: {
              errorSuppressed: true,
              errorCodes: [400, 413]
            },
            error: function (data, response) {
              var jsonData = JSON.parse(response.responseText);
              var errors;
              if (jsonData.errors && jsonData.errors.field_error) {
                errors = !$.isArray(jsonData.errors.field_error) ? [jsonData.errors.field_error] : jsonData.errors.field_error;
                errors = _.map(errors, function (error) {
                  return {
                    field: error.name.replace(/customVast/g, "vast"),
                    message: error.error
                  };
                });
                self.showErrors(outputModel, errors, ".w-CreativesCreateEdit");
                statusInvalid([]);
              }
            },
            processAjaxResponse: outputModel.processAjaxResponse
          };
          if (id) {
            outputModel.set({
              id: id
            });
          }
          outputModel.save(outputModel.toJSON(), saveOpts);
          return def.promise();
        });
      });
    },
    load: function () {
      var $el = $(this.el);
      var self = this;
      this.render().then(function () {
        self.layout && !self.layout.isActive && self.layout.load().then(function () {
          self.toggleFilters(!self.collection.loaded);
          setTimeout(function () {
            var $advDDL = $(".advertiser-select", self.el);
            var $advSelectBox = $(`div#${$advDDL.attr("id")}_chzn`);
            var $title = $advSelectBox.find("span");
            $advDDL.find("._wrapper").addClass("ad-wrapper");
            $advSelectBox.attr("title", $title.html());
            $advSelectBox.click(function () {
              $advSelectBox.attr("title", $title.html());
            });
            $el.not('div[class="ad-wrapper"]').click(function () {
              self.clearTheFilter();
            });
          }, 1e3);
          T1.Selectbox($(".status .select-input", this.el), {
            onChange: function (e) {
              switch (parseInt(e.target.value, 10)) {
                case 0:
                  self.searchBuilder.clearGroupFlags("active_state");
                  self.active = "All";
                  break;
                case 1:
                  self.searchBuilder.setFlag("active_state", "active");
                  self.active = "Active";
                  break;
                case 2:
                  self.searchBuilder.setFlag("active_state", "inactive");
                  self.active = "Inactive";
                  break;
              }
              self.search();
              self.preferences.save();
            }
          });
          T1.Selectbox($(".select-large .select-input", this.el), {
            onChange: $.noop
          });
          self.search();
        });
      });
    },
    search: function () {
      var params;
      var searchTerm = this.searchTerm;
      if (searchTerm && searchTerm !== "") {
        this.searchBuilder.setFlag("search", "input", {
          term: searchTerm
        });
      } else {
        this.searchBuilder.clearFlag("search", "input");
      }
      params = this.searchBuilder.getAllFilters();
      if (searchTerm) {
        searchTerm = decodeURIComponent(searchTerm);
      }
      T1.EventHub.publish("creativegrid:setHighlightValue", searchTerm);
      this.collection.search.set({
        fetchRequired: true,
        params: params
      });
      T1.EventHub.publish("creativegrid:setSearchLoader");
    },
    addCreative: function () {
      T1.Location.set("creatives/video");
    },
    gridChange: function (e, data) {
      var $large = $(".select-large select");
      switch (data.state) {
        default:
        case "none":
          $large.attr("disabled", "disabled");
          break;
        case "partial":
        case "checked":
          $large.removeAttr("disabled");
          break;
      }
      $large.trigger("liszt:updated");
    },
    unload: function () {
      if (this.bulkEditLayout) {
        this.bulkEditLayout.unload();
      }
      if (this.layout) {
        this.layout.unload();
      }
      $(".search input", this.el).off("keyup");
      this.collection.toggleAllSelected(false);
    },
    serialize: function () {
      return {
        activeOptions: selectOptions.toOptionsList(selectOptions.active, this.active || "Active"),
        violationFlaggingEnabled: self.violationFlaggingEnabled,
        search: this.searchTerm
      };
    }
  });
});
