define('utils/LaunchDarklyUtils',["T1", "LaunchDarkly", "T1UtilsAsync"], function (T1, LaunchDarkly, utilsAsync) {
  "use strict";

  let currentUser, deferral, ldClient;
  function noop() {}
  function updateClient(user, updateDeferred) {
    ldClient.identify(user, null, () => {
      if (updateDeferred) {
        updateDeferred.resolve();
      }
      deferral.resolve();
    });
  }
  function updateOrganization(orgId) {
    const updateDeferred = utilsAsync.makeDeferral();
    Object.assign(currentUser.custom, {
      organization: orgId
    });
    updateClient(currentUser, updateDeferred);
    return updateDeferred.promise;
  }
  function updateAgency(agencyId) {
    const updateDeferred = utilsAsync.makeDeferral();
    Object.assign(currentUser.custom, {
      agency: agencyId
    });
    updateClient(currentUser, updateDeferred);
    return updateDeferred.promise;
  }
  function updateAdvertiser(advertiserId) {
    const updateDeferred = utilsAsync.makeDeferral();
    Object.assign(currentUser.custom, {
      advertiser: advertiserId
    });
    updateClient(currentUser, updateDeferred);
    return updateDeferred.promise;
  }
  function initializeClient(user) {
    let ldEnvironmentKey = "5a997c098d378b2025588fef";
    if (COMPASS_ENV.TIER === "PRODUCTION") {
      ldEnvironmentKey = "5a997c098d378b2025588ff0";
    }
    ldClient = LaunchDarkly.initialize(ldEnvironmentKey, user);
    return ldClient;
  }
  function getUserData(results) {
    const user = getUserInfo(results.addUserInfo);
    const org = getOrgPermissions(results.addOrgPermissions);
    if (user.id && org.id) {
      return {
        key: user.id,
        custom: {
          organization: org.id,
          permissionType: user.type
        }
      };
    }
    return getAnonymousUserObj();
  }
  function getUserInfo(userModel) {
    const obj = {};
    if (userModel !== undefined) {
      obj.id = userModel.get("id");
      obj.role = userModel.get("role");
      obj.type = userModel.get("type");
      obj.scope = userModel.get("scope");
      obj.firstName = userModel.get("first_name");
      obj.lastName = userModel.get("last_name");
      obj.email = userModel.get("email");
    }
    return obj;
  }
  function getOrgPermissions(org) {
    const obj = {};
    if (org !== undefined) {
      const model = org.selectedOrgModel || org.get(org.selected);
      if (!model) {
        if (org.selected) {
          obj.id = org.selected;
        }
      } else {
        obj.id = model.get("id");
        obj.country = model.get("country");
      }
    }
    return obj;
  }
  function getFlag(key) {
    return ldClient && ldClient.variation(key);
  }
  function getAnonymousUserObj() {
    const guid = T1.Utils.getClientId();
    return {
      key: guid,
      anonymous: true
    };
  }
  function getAnonymousClient() {
    onResetUser();
    return utilsAsync.makePromise(resolve => {
      initializeClient(getAnonymousUserObj()).on("ready", resolve);
    });
  }
  function getPromise() {
    return deferral.promise;
  }
  function onDataLoaded(results) {
    const user = getUserData(results);
    currentUser = user;
    if (ldClient) {
      updateClient(user);
    } else {
      initializeClient(user).on("ready", () => deferral.resolve());
    }
  }
  function onResetUser() {
    deferral.reject();
    constructDeferral();
  }
  function constructDeferral() {
    deferral = utilsAsync.makeDeferral();
    deferral.promise.catch(noop);
  }
  function initializeSubscriptions() {
    T1.EventHub.subscribe({
      "UserDataUtils:dataLoaded": onDataLoaded,
      "UserDataUtils:reset": onResetUser
    });
  }
  function initialize() {
    constructDeferral();
    initializeSubscriptions();
  }
  initialize();
  return {
    getFlag: getFlag,
    getPromise: getPromise,
    updateOrganization: updateOrganization,
    updateAgency: updateAgency,
    updateAdvertiser: updateAdvertiser,
    getAnonymousClient: getAnonymousClient
  };
});
