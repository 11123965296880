define('modules/strategy/views/helpers/technologyWurflTargetHelper',["T1UtilsAsync", "collections/nemoTargets", "collections/nemoAttachments"], function (T1UtilsAsync, WurflTargetValues, NemoWURFLttachments) {
  "use strict";

  function addPathNamesToModels(regex, parentRegex, osRegex, osParentRegex, attachments) {
    return function (model) {
      const nameIdx = 2;
      const name = model.get("name");
      const osRegexName = name.match(osRegex);
      const osParentRegexName = name.match(osParentRegex);
      const regexName = name.match(regex);
      const parentRegexName = name.match(parentRegex);
      let hasOsVersionData = false;
      if (regexName) {
        model.set({
          full_path_name: `${regexName[1]} &raquo; ${regexName[nameIdx]}`,
          name: regexName[nameIdx],
          parent_name: regexName[1]
        });
      } else if (parentRegexName) {
        model.set({
          name: parentRegexName[1]
        });
      }
      if (osRegexName) {
        hasOsVersionData = true;
        model.set({
          full_path_name: `${osRegexName[1]} &raquo; ${osRegexName[nameIdx]}`,
          name: osRegexName[nameIdx],
          parent_name: osRegexName[1]
        });
      } else if (osParentRegexName) {
        hasOsVersionData = true;
        model.set({
          name: osParentRegexName[1]
        });
      }
      model.set({
        action: attachments[model.get("id")].toLowerCase(),
        parent_value_id: model.get("parent_id"),
        dimension_code: hasOsVersionData ? "WURFL/OS" : "WURF"
      });
    };
  }
  function onWurfTargetValuesFetch(deferral, attachments) {
    return function (data) {
      const regex = /^WURFL\/Brand\/([^/]+)\/Device\/([^/]+)/;
      const parentRegex = /^WURFL\/Brand\/([^/]+)/;
      const osRegex = /^WURFL\/OS\/([^/]+)\/OS[ ]Version\/([^/]+)/;
      const osParentRegex = /^WURFL\/OS\/([^/]+)/;
      data.models.forEach(addPathNamesToModels(regex, parentRegex, osRegex, osParentRegex, attachments));
      deferral.resolve(data.toJSON());
    };
  }
  function onNemoWURFLAttachmentsFetch(deferral) {
    return function (attachmentData) {
      const attachments = {};
      const targetsList = attachmentData.models.map(function (model) {
        const id = model.get("target_id");
        attachments[id] = model.get("restriction");
        return id;
      });
      if (targetsList.length) {
        const wurflTargetValues = new WurflTargetValues();
        wurflTargetValues.fetch({
          fetchOptions: {
            id: [].concat(targetsList)
          },
          success: onWurfTargetValuesFetch(deferral, attachments)
        });
      } else {
        deferral.resolve();
      }
    };
  }
  return {
    fetchWURFLData: function (strategyID) {
      const deferral = T1UtilsAsync.makeDeferral();
      const nemoWURFLattachments = new NemoWURFLttachments();
      nemoWURFLattachments.fetch({
        fetchOptions: {
          strategy_id: strategyID,
          dimension: "WURF"
        },
        success: onNemoWURFLAttachmentsFetch(deferral)
      });
      return deferral.promise;
    }
  };
});
