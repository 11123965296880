define('T1Model',["jQuery", "Underscore", "Backbone", "T1", "T1Comm", "utils/orgSynchronization", "T1Notifier", "backbone-relational"], function ($, _, Backbone, T1, T1Comm, OrgSynchronization) {
  var T1ModelExtend;
  T1.Models.T1Model = Backbone.RelationalModel.extend({
    canCache: false,
    defaultCacheExcludes: ["currentTime"],
    cacheDuration: 3e5,
    apiRoot: T1.API_ROOT,
    fixture: "T1Model",
    postEntityParse: {},
    initialize: function (options) {
      if (this.cacheExcludes && this.canCache) {
        $.merge(this.defaultCacheExcludes, this.cacheExcludes);
      }
      this.cacheExcludes = this.defaultCacheExcludes;
      if (options.includeIdInPost) {
        this.includeIdInPost = options.includeIdInPost;
      }
    },
    parse: function (data) {
      var postEntityParse = this.postEntityParse || {};
      var parsedEntity = T1.Utils.parseEntity(data.entity);
      if ($.isEmptyObject(postEntityParse) && data.active) {
        parsedEntity = data.active;
      }
      $.each(postEntityParse, function (key, modifier) {
        if (parsedEntity[key]) {
          parsedEntity[key] = modifier(parsedEntity[key]);
        }
      });
      return parsedEntity;
    },
    canEdit: function () {
      var self = this;
      var permissionDerferred = $.Deferred();
      require(["T1Permissions"], function (T1Permissions) {
        permissionDerferred.resolve(self.name ? T1Permissions.check(self.name + ".edit", self) : false);
      });
      return permissionDerferred.promise();
    },
    getRelated: function (entityName) {
      var entity = this.get(entityName);
      if (entity && !entity[this.name]) {
        entity[this.name] = this;
      }
      return entity;
    },
    fetchRelated: function (entityName) {
      var self = this;
      var entity = this.getRelated(entityName);
      var isFetching = this.isFetchingRelated;
      if (!entity || isFetching) {
        return false;
      }
      this.isFetchingRelated = true;
      entity.fetch({
        success: function () {
          self.isFetchingRelated = false;
          self.trigger("reset:" + entityName, entity);
        }
      });
    },
    save: function (attributes, options) {
      var self = this;
      var statusInvalid = options && options.statusInvalid ? options.statusInvalid : $.noop;
      var canEdit, modelErrors;
      function onError(errorHandler, model, opts) {
        return function (response) {
          if (typeof errorHandler === "function") {
            errorHandler(model, response, opts);
          } else {
            model.trigger("error", model, response, opts);
          }
        };
      }
      function sync() {
        var model = self;
        var success = options.success;
        var method;
        options.success = function (attrs, e, f) {
          if (!model.set(model.parse(attrs, f), options)) {
            return !1;
          }
          return success && success(model, attrs, f);
        };
        options.statusInvalid = function (data) {
          var errors = [];
          var fieldError = data.errors ? data.errors.field_error : data;
          if ($.isArray(fieldError)) {
            $.each(fieldError, function (key, value) {
              errors.push({
                field: value.name,
                message: value.error
              });
            });
          } else {
            errors.push({
              field: fieldError.name,
              message: fieldError.error
            });
          }
          statusInvalid(errors);
        };
        options.error = onError(options.error, model, options);
        method = self.isNew() ? "create" : "update";
        if (self.isNew()) {
          attributes = $.isEmptyObject(attributes) ? {} : $.extend({
            version: 0
          }, attributes);
        } else {
          attributes = $.isEmptyObject(attributes) ? self.attributes : $.extend({
            version: self.get("version")
          }, attributes);
        }
        if (self.includeIdInPost) {
          attributes.id = self.id;
        }
        if (self.wrapPostObj) {
          if (self.createNewBulkeditor && attributes.length) {
            attributes = [attributes[0]];
          } else if (attributes.length) {
            attributes = [attributes[0], attributes[1]];
          } else if (!_.isEmpty(attributes) && !self.stagingActions) {
            attributes = [attributes[0]];
          }
          if (self.stagingActions) {
            delete attributes.version;
            attributes = $.map(attributes, function (value) {
              return [value];
            });
          }
        }
        if (self.deleteVersion) {
          delete attributes.version;
        }
        return self.sync.call(self, method, attributes, options);
      }
      options = options || {};
      if (!this.isNew()) {
        canEdit = this.canEdit();
        canEdit.done(function (editable) {
          if (!editable) {
            return;
          }
          return sync();
        });
      } else {
        modelErrors = this.validate ? this.validate(attributes) : {};
        if ($.isArray(modelErrors) && modelErrors.length) {
          statusInvalid(modelErrors);
        } else {
          const hasNoValue = val => val === "" || val === undefined;
          if (options.keepEmptyAndUndefinedAttribute) {
            const hasExemptAttr = attr => options.keepEmptyAndUndefinedAttribute.includes(attr);
            $.each(attributes, function (attr, val) {
              if (hasNoValue(val) && !hasExemptAttr(attr)) {
                delete attributes[attr];
              }
            });
          } else {
            $.each(attributes, function (attr, val) {
              if (hasNoValue(val)) {
                delete attributes[attr];
              }
            });
          }
          return sync();
        }
      }
    },
    fetch: function (opts) {
      this.currentFetch = Backbone.RelationalModel.prototype.fetch.call(this, opts);
      return this.currentFetch;
    },
    sync: function (action, attributes, options) {
      var self = this;
      var method = action === "read" ? "GET" : "POST";
      var request = method === "GET" ? T1Comm.get : options.method === "PUT" ? T1Comm.put : T1Comm.post;
      var params = options.params;
      var modelUrl = T1.Utils.constructModelUrl(this, options.action, params);
      var postObj;
      var success = options.success || $.noop;
      var error = options.error || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      var errSuppressingConfig = options.errorDisplaySuppressingConfig;
      var showNotification = typeof self.showNotification === "boolean" ? self.showNotification : false;
      if (options.httpAction === "delete") {
        method = "DELETE";
        request = T1Comm.deleteCall;
      }
      var onError = function (data) {
        var responseText, freshData, statusCode, jsonData;
        if (errSuppressingConfig && errSuppressingConfig.errorSuppressed === true) {
          error(data);
          return;
        }
        if (method === "POST" || method === "DELETE") {
          responseText = data;
          statusCode = $(responseText).find("status").attr("code");
          if (statusCode === "conflict") {
            jsonData = T1.Utils.parseXMLtoJson($.parseXML(responseText), self.modelTemplate);
            T1.VersionConflictDialog.open();
            freshData = self.parse(jsonData);
            self.set(freshData);
            conflict();
          } else if (statusCode === "error") {
            error(data);
          }
        }
      };
      if (options.dataType === "json") {
        postObj = attributes || {};
      } else {
        postObj = method === "POST" || method === "DELETE" ? $.param(attributes, true) : {};
      }
      params = $.extend({
        sourceURL: modelUrl,
        data: postObj,
        onSuccess: success,
        onError: onError,
        onStatusInvalid: statusInvalid,
        showNotification: showNotification,
        fixture: this.fixture || "",
        async: options.async || true,
        canCache: this.canCache,
        cacheDuration: this.cacheDuration,
        cacheExcludes: this.cacheExcludes,
        apiRoot: this.apiRoot
      }, options);
      return request(params);
    }
  });
  T1ModelExtend = T1.Models.T1Model.extend;
  T1.Models.T1Model.extend = function () {
    var subClass = T1ModelExtend.apply(this, arguments);
    var modelName = subClass && subClass.prototype && subClass.prototype.name ? subClass.prototype.name : "";
    if (modelName === "campaign" || modelName === "strategy") {
      OrgSynchronization.attachOrgSynchronizationCheck(subClass);
    }
    return subClass;
  };
  return T1.Models.T1Model;
});
