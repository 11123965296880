define('modules/creatives/bulkedit/views/entityGrid',["jQuery", "T1", "T1View", "text!../templates/entity_grid_item.html", "T1GridView"], function ($, T1, T1View, gridItem, T1GridView) {
  "use strict";

  var BulkEditGrid = T1View.extend({
    partials: {
      listItem: gridItem
    },
    initialize: function (args) {
      this.collection = args.collection;
      this.consistencyEnabled = args.consistencyEnabled;
      this.headers = this.getHeaders();
    },
    getHeaders: function () {
      var headers = [{
        label: "Name",
        name: "name"
      }, {
        label: this.consistencyEnabled ? "Row" : "ID",
        name: "id"
      }];
      return headers;
    },
    load: function () {
      this.render();
    },
    serialize: function () {
      var self = this;
      var collection = self.collection;
      var items = [];
      collection.each(function (model) {
        model.set({
          consistencyEnabled: self.consistencyEnabled
        });
        items.push(model.toJSON());
      });
      return {
        list: items
      };
    }
  });
  return T1GridView(BulkEditGrid);
});
