define('collections/pixelUniques',["T1", "T1Collection"], function (T1, T1Collection) {
  T1.Models.PixelsUniques = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    apiRoot: T1.RPT_API_ROOT + "std/",
    url: function (params) {
      var appendString = "";
      var urlString = this.urlString;
      var pageLimit = parseInt(this.pageLimit, 10);
      var fullOptions;
      var searchOption = "";
      var searchObj;
      fullOptions = $.extend(true, {}, this.fetchOptions, params || {});
      if (typeof urlString === "function") {
        urlString = urlString();
      }
      if (this.urlFilter) {
        appendString += this.urlFilter.getString();
      }
      if (!$.isEmptyObject(fullOptions)) {
        appendString += "?" + $.param(fullOptions, true);
      }
      return this.apiRoot + urlString + appendString + searchOption;
    },
    fetch: function (options) {
      var self = this;
      var id = self.id;
      var onSuccess;
      var onError;
      options = options || {};
      onSuccess = options.success || $.noop;
      onError = options.error || $.noop;
      self.ajx = self.sync("read", self, {
        action: self.action,
        dataType: "html",
        onSuccess: onSuccess,
        onError: onError,
        processAjaxResponse: function (resp, req) {
          var data;
          if (resp.indexOf("<?xml") !== -1) {
            throw new Error("report data response not csv");
          }
          data = d3.csv.parse.call(d3, resp);
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
    },
    fetchLastTwoDays: function (options) {
      this.fetchOptions.time_window = "last_2_days";
      this.fetch(options);
    }
  });
  return T1.Models.PixelsUniques;
});
