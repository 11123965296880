define('collections/componentCreatives',["Underscore", "T1", "T1Collection", "models/componentCreative"], function (_, T1, T1Collection, ComponentCreative) {
  "use strict";

  T1.Models.ComponentCreatives = T1Collection.extend({
    apiRoot: COMPASS_ENV.COMPONENT_API_BASE,
    urlString: "creatives",
    model: ComponentCreative,
    isPaginated: true,
    fetchOptions: {},
    fetchCount: 0,
    page: 0,
    moreFetchable: false,
    fetch: function (options) {
      var self = this;
      var onSuccess;
      if (!options || !options.add) {
        this.unregister();
        this.page = 0;
      }
      options = options || {};
      onSuccess = function (data) {
        self.loaded = true;
        self.count = data.meta && data.meta.total_count;
        self[options.add ? "add" : "reset"](data.data);
        if (options.success) {
          options.success(data);
        }
        return self;
      };
      self.loaded = false;
      var syncOpts = {
        action: self.action,
        dataType: "json",
        success: onSuccess,
        processAjaxResponse: function (data) {
          let jsonData = typeof data === "string" ? JSON.parse(data) : data;
          return {
            statusCode: "ok",
            jsonData: jsonData
          };
        }
      };
      if (options.url) {
        syncOpts.params = options.url;
      }
      self.ajx = self.sync("read", "", syncOpts);
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    fetchMore: function (args) {
      var self = this;
      var onSuccess = args.success || function (d) {
        return d;
      };
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          silent: args.silent || false,
          add: true,
          success: function (data) {
            self.add(data.data);
            self.loaded = true;
            return onSuccess(data);
          }
        });
      }
    },
    filterBy: function (key, value, onsuccess) {
      var self = this;
      var opts = {};
      if (onsuccess) {
        opts = {
          success: onsuccess
        };
      }
      self.page = 0;
      if (key === "search" && value.length) {
        this.fetchOptions.search = value;
      }
      if (key === "status") {
        if (value >= 0 && value < 2) {
          this.fetchOptions.status = value;
        } else {
          delete this.fetchOptions.status;
        }
      }
      this.loaded = false;
      this.trigger("startSearch");
      return self.fetch(opts);
    },
    initialize: function () {
      T1Collection.prototype.initialize.apply(this, arguments);
    },
    toggleAllSelected: function (isSelected) {
      this.each(function (model) {
        model.set({
          selected: isSelected
        });
      });
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    abort: function () {
      if (this.ajx) {
        this.ajx.abort();
      }
    }
  });
  return T1.Models.ComponentCreatives;
});
