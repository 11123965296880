define('modules/reporting/campaigns/contextual/views/chartPanel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/chartLayout.html", "text!../templates/chartPanel.html", "T1Animate"], function ($, _, T1, T1View, T1Layout, chartLayout, template, T1Animate) {
  "use strict";

  var T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: chartLayout,
    events: {
      "click .bar-icon": "switchGraph",
      "click .line-icon": "switchGraph",
      "mouseout strand-icon": "setChartIconState",
      "mouseover strand-icon": "changeChartIconColor",
      "toggled .w-chart-drawer": "toggleChart"
    },
    initialize: function (args) {
      var mainModel;
      this.cfgs = args.config;
      mainModel = this.cfgs.models.mainModel;
      this.chartShown = mainModel.get("chartShown") === "true";
      this.subSection = mainModel.get("contextualSubSection");
      this.parentClass = args.parentClass;
      this.initLayout();
    },
    initLayout: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".contextual-chart-panel");
        },
        template: template,
        layout: {
          ".contextual-chart-title-box": [{
            module: "reporting/campaigns/contextual",
            options: this.cfgs,
            viewType: "chartTitle"
          }],
          ".metric-select-c": [{
            module: "reporting/campaigns",
            options: this.cfgs,
            viewType: "selectMetric"
          }],
          ".contextual-chart-box": [{
            module: "reporting/campaigns/contextual",
            options: this.cfgs,
            viewType: "contextualChart"
          }]
        }
      });
    },
    load: function () {
      var self = this;
      var $el = this.el;
      this.render().then(function () {
        self.layout.load();
        self.barIcon = $el.find(".bar-icon");
        self.lineIcon = $el.find(".line-icon");
        self.setChartIconState();
      });
    },
    setChartIconState: function () {
      var selectedClass = "selected";
      if (this.subSection === "daily") {
        this.barIcon.removeClass(selectedClass);
        this.lineIcon.addClass(selectedClass);
      } else {
        this.lineIcon.removeClass(selectedClass);
        this.barIcon.addClass(selectedClass);
      }
    },
    changeChartIconColor: function (event) {
      if (event) {
        $(event.currentTarget).addClass("selected");
      }
    },
    toggleChart: function () {
      var chartShown = this.chartShown;
      T1Animate({
        target: this.parentClass.el.find(".report-controls"),
        options: {
          marginTop: chartShown ? "8px" : "350px"
        },
        duration: 400,
        easing: "ease",
        complete: function () {
          T1Publish("chart.toggleEnd");
          T1Publish("track:event", {
            action: "Toggle chart",
            label: chartShown ? "show" : "hide"
          });
        }
      });
      this.chartShown = !chartShown;
      T1Publish("chart.toggle", this.chartShown);
    },
    switchGraph: function (event) {
      let {
        subSection: subSection
      } = this;
      const {
        id: selectedSection
      } = event.currentTarget;
      event.stopPropagation();
      if (subSection !== selectedSection) {
        subSection = subSection === "daily" ? "summary" : "daily";
        T1Publish("select:chart", subSection);
        this.subSection = subSection;
        this.setChartIconState();
        T1Publish("switch:chart", subSection);
      }
    },
    serialize: function () {
      return {
        chartShown: this.chartShown
      };
    }
  });
});
