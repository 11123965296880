define('collections/targetValues',["Underscore", "jQuery", "T1", "T1Collection", "models/targetValue", "T1Sortable"], function (_, $, T1, T1Collection, TargetValue, T1Sortable) {
  var originalInitialize;
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "Name"
      },
      id: {
        actual: "id",
        display: "id"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: TargetValue,
    urlString: "target_values",
    fixture: "-targetValues",
    searchConfiguration: {
      isFetchSilent: false,
      fields: {
        name: {
          type: "string"
        },
        id: {
          type: "enum"
        }
      }
    },
    buildPostObjForContextualTargets: function (models, safetyModels) {
      var obj = {};
      var dataSet = models.concat(safetyModels);
      var dataSetLen = dataSet.length;
      var i;
      if (dataSetLen > 0) {
        for (i = 0; i < dataSetLen; i++) {
          obj[`target_values.${i + 1}.id`] = dataSet[i].id;
        }
      }
      return obj;
    }
  });
  originalInitialize = collection.prototype.initialize;
  collection.prototype.initialize = function (models, options) {
    var fetchOptions, result;
    options = options || {};
    fetchOptions = options.fetchOptions || {};
    options.isPaginated = false;
    fetchOptions.full = "*";
    options.fetchOptions = fetchOptions;
    result = originalInitialize.call(this, models, options);
    if (options.dimension) {
      this.fetchOptions.dimension = options.dimension;
    }
    return result;
  };
  T1.Models.TargetValues = T1Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.TargetValues;
});
