define('modules/T1App/edit/access/users/views/usersGrid',["jQuery", "Underscore", "T1", "T1View", "T1Comm", "text!modules/T1App/edit/access/users/templates/usersGrid.html", "collections/appsAccessUsers", "../../models/accessUsersModel", "models/sessionUser", "collections/appsAccessUsersBin", "T1Model"], function ($, _, T1, T1View, T1Comm, template, AppsAccessUsers, StateModel, User, AppsAccessUsersBin) {
  "use strict";

  var Users;
  var T1Publish = T1.EventHub.publish;
  var T1Location = T1.Location;
  var _currentDevteam = [];
  Users = T1View.extend({
    template: template,
    events: {
      "click .team-grid-include-icon": "gridClickHandler",
      "click #load-more-users": "onLoadMoreClick"
    },
    eventhubEvents: {
      "change:userBinElements": "binClickHandler",
      "teamModel.gridDataChange": function () {
        this.clearFilter();
      },
      "search:onSearchRequest": function (args) {
        if (args.value.length > 0) {
          this.model.searchTerm = args.value;
          if (this.searchTerm === this.model.searchTerm) {
            return;
          }
          this.searchTerm = this.model.searchTerm;
          this.filterCollection(args.field, args.value);
        } else {
          this.clearFilter();
        }
      }
    },
    initialize: function (args) {
      var self = this;
      var locationHashArray = T1Location.get(true).split("/");
      var user = User.getUser();
      this.appID = parseInt(T1.Utils.escapeHTML(locationHashArray[2]), 10);
      this.stateModel = args.model;
      this.model = new AppsAccessUsers();
      this.userID = parseInt(user.get("id"), 10);
      this.model.fetch({
        appID: this.appID,
        offset: 30,
        success: function (data) {
          self.loadGrid();
        }
      });
    },
    loadGrid: function () {
      var self = this;
      var $el = $(this.el);
      var usersGrid;
      var stateModel = this.stateModel;
      _currentDevteam = [];
      self.gridData = self.createGridData(stateModel.accessUsers);
      self.userBinModel = new AppsAccessUsersBin();
      $el.find("#accessUsersGrid")[0].isLoading = true;
      self.userBinModel.fetch({
        appID: self.appID,
        success: function (data) {
          self.stateModel.accessUsers = data.data[0].users;
          self.stateModel.selectedUsers(data.data[0].users);
          self.stateModel.initialUsers = self.devTeamOnLoad(data.data[0].users);
          _currentDevteam = stateModel.getInitialDevTeam();
          self.render().then(function () {
            _.each(data.data[0].users, function (value) {
              var userName = value.user_name ? value.user_name : value.id;
              $(".dev-team-container").append('<div id="' + value.id + '" class="dev-team">' + userName + '<strand-icon class="icon remove-dev pull-right" type="delete" width="10" height="10"></mm-icon></div>');
            });
            usersGrid = $el.find("#accessUsersGrid")[0];
            usersGrid.data = self.gridData;
            self.offset = 30;
          });
        }
      });
    },
    filterCollection: function (field, term) {
      var self = this;
      var usersGrid = $(this.el).find("#accessUsersGrid")[0];
      usersGrid.isLoading = true;
      this.model.filterBy(field, term, this.appID, function (data) {
        usersGrid.data = self.createGridData(data.data[0].users, term);
        usersGrid.isLoading = false;
      });
    },
    clearFilter: function () {
      var usersGrid = $(this.el).find("#accessUsersGrid")[0];
      usersGrid.isLoading = true;
      usersGrid.data = this.createGridData(this.gridData);
      this.searchTerm = "";
      usersGrid.isLoading = false;
    },
    createGridData: function (data, searchTerm) {
      var gridData = [];
      var currentDev = this.markFilterDev(data, _currentDevteam);
      if (currentDev.length > 0) {
        _.each(currentDev, function (item) {
          gridData.push({
            id: item.id.toString(),
            user_name: item.user_name,
            first_name: item.first_name,
            last_name: item.last_name,
            role: item.role,
            searchTerm: searchTerm || null,
            classlist: item.classlist
          });
        });
      } else {
        gridData.push({
          id: "",
          email: "",
          name: "",
          first_name: "No result found",
          last_name: "",
          role: "",
          classlist: "hidden",
          searchTerm: searchTerm || null
        });
      }
      return gridData;
    },
    markFilterDev: function (gridData, currentDevTeam) {
      if (currentDevTeam.length > 0) {
        for (var i = 0; i < gridData.length; i++) {
          for (var j = 0; j < currentDevTeam.length; j++) {
            if (parseInt(gridData[i].id, 10) === parseInt(currentDevTeam[j], 10)) {
              gridData[i].classlist = "selected";
            }
          }
        }
      } else {
        for (var k = 0; k < gridData.length; k++) {
          if (gridData[k].access === true) {
            gridData[k].classlist = "selected";
          }
        }
      }
      return gridData;
    },
    onLoadMoreClick: function (event) {
      var self = this;
      var $el = $(this.el);
      var usersGrid = $el.find("#accessUsersGrid")[0];
      var newGridData = [];
      usersGrid.isLoading = true;
      this.offset = this.offset + 30;
      this.model.fetch({
        appID: this.appID,
        offset: this.offset,
        success: function (data) {
          self.stateModel.accessUsers = data.data[0].users;
          self.stateModel.selectedUsers(data.data[0].users);
          self.gridData = self.createGridData(self.stateModel.accessUsers);
          self.gridData.map(function (value, index) {
            newGridData.push({
              classlist: value.classlist,
              id: value.id,
              user_name: value.user_name,
              first_name: value.first_name,
              last_name: value.last_name,
              role: value.role,
              searchTerm: null
            });
          });
          usersGrid.push.apply(usersGrid, ["data"].concat(newGridData));
          usersGrid.isLoading = false;
        }
      });
      return true;
    },
    markCurrentDev: function (id, action) {
      var gridData = this.gridData;
      action = action === "add" ? "selected" : "";
      for (var i = 0; i < gridData.length; i++) {
        if (parseInt(gridData[i].id, 10) === parseInt(id, 10)) {
          gridData[i].classlist = action;
        }
      }
      return gridData;
    },
    binClickHandler: function (event) {
      var $devID = $(event.currentTarget).closest(".dev-team");
      var id = parseInt($devID.attr("id"), 10);
      $(".dev-team-container").find("#" + id).remove();
      $("strand-icon#" + id).removeClass("selected");
      _currentDevteam = _.without(_currentDevteam, id);
      this.stateModel.currentDevteam = _currentDevteam;
      this.markCurrentDev(id, "remove");
      T1Publish("change:formElements", "There are unsaved changes on the page.");
    },
    gridClickHandler: function (event) {
      var $icon = $(event.currentTarget);
      var id = parseInt($icon.attr("id"), 10);
      var email = $(event.delegateTarget).find("strand-highlight#" + id).text();
      var action = "";
      if ($icon.hasClass("selected")) {
        $icon.removeClass("selected");
        $(".dev-team-container").find("#" + id).remove();
        _currentDevteam = _.without(_currentDevteam, id);
        action = "remove";
      } else {
        $icon.addClass("selected");
        $(".dev-team-container").append('<div id="' + id + '" class="dev-team">' + email + '<strand-icon class="icon remove-dev pull-right" type="delete" width="10" height="10"></strand-icon></div>');
        _currentDevteam.push(id);
        action = "add";
      }
      this.stateModel.currentDevteam = _currentDevteam;
      this.markCurrentDev(id, action);
      T1Publish("change:formElements", "There are unsaved changes on the page.");
    },
    devTeamOnLoad: function (data) {
      var users = [];
      for (var i = 0; i < data.length; i++) {
        users.push(data[i].id);
      }
      return users;
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      if ($el[0] && Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return Users;
});
