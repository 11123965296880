define('modules/reporting/campaigns/geoPerformance/views/panel',['require','jQuery','Underscore','collections/campaignReport','text!../templates/layout.html','modules/reporting/utils/reportingComm','T1','T1Layout','T1PerformanceTimer','T1View','text!../templates/panel.html','modules/reporting/campaigns/utils/reportingUtils','When'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const CampaignReport = require("collections/campaignReport");
  const layout = require("text!../templates/layout.html");
  const ReportingComm = require("modules/reporting/utils/reportingComm");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Timer = require("T1PerformanceTimer");
  const T1View = require("T1View");
  const template = require("text!../templates/panel.html");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  const When = require("When");
  const GAMap = {
    filterDimensionsApiLoad: "RPTG:GEO:API:FILTER_DIMENSION",
    geoApiLoad: "RPTG:GEO:API:GEO_DATA",
    geoDailyApiLoad: "RPTG:GEO:API:GEO_DAILY_DATA",
    totalLoad: "RPTG:GEO:API:LOAD:TOTAL"
  };
  const trackingSection = "Reporting - Geo";
  const dateFormat = "yyyy-MM-dd";
  let comm, timerValue;
  return T1View.extend({
    dimensionData: "strategy_id",
    template: template,
    section: "geoPerformance",
    dataEvents: {
      reportModel: {
        "change:tempDimension": function () {
          this.setGetDataOnce();
          this.getDataOnce();
        },
        "change:dimension": function () {
          this.reportModel.resetHiddenItems();
          this.load();
        },
        "change:geoNav": function (reportModel, geoNav) {
          this.updateGeoNav(geoNav);
          this.load();
        }
      },
      campaign: {
        "change:start_end": function () {
          const campaign = this.campaign;
          this.report.start = campaign.get("start");
          this.report.end = campaign.get("end");
          this.report.isCTD = campaign.get("isCTD");
          this.reportModel.set({
            isCTD: this.report.isCTD
          });
          this.reportModel.resetUIStates();
          this.updateData();
        }
      }
    },
    eventhubEvents: {
      "reports:select:campaign": function ({
        id: id
      }) {
        const campaignID = id;
        if (this.currentReportId !== campaignID) {
          this.updateGeoNavOnCampaignChange(campaignID);
          this.report.id = campaignID;
          this.load();
        }
      },
      "select:dimension": function () {
        const reportModel = this.reportModel;
        const filteredInItems = reportModel.get("filteredInItems");
        const dimensionInfoKey = reportModel.get("dimensionInfo").key;
        if (!filteredInItems.length) {
          this.moreFilters = "";
        } else {
          this.moreFilters = `&${dimensionInfoKey}=${reportModel.getEscapedNames(filteredInItems).toString()}`;
        }
        reportModel.setMoreFilters(this.moreFilters);
        this.load();
      },
      "select:interval": "load",
      "toggle:loader": function (show) {
        this.toggleLoader(show);
      }
    },
    initialize(args) {
      let cfgs, reportModel;
      let strategyChannelFilter = "";
      const dailyFetchOptions = {
        dimensions: "country_name",
        filter: "campaign_id=",
        precision: 2,
        time_rollup: "by_day",
        v2: ""
      };
      comm = new ReportingComm({
        id: "geo"
      });
      this.cfgs = cfgs = args;
      cfgs.dataGroup = comm.dataGroup;
      this.report = cfgs.report;
      const models = cfgs.models;
      this.mainModel = models.mainModel;
      this.reportModel = reportModel = models.reportModel;
      this.reportMeta = models.reportMeta;
      this.currentReportId = this.report.id;
      this.campaign = models.campaign;
      this.dimensionData = reportModel.getDimensionInfo(false).key;
      cfgs.type = "summary";
      const focus = reportModel.get("focus");
      if (focus === "all") {
        this.filterDimension = comm.buildCollection("filterDimension", models.geoFilterDimension);
        cfgs.collection = this.collection = comm.buildCollection("collection", models.geoCollection);
      } else {
        strategyChannelFilter = `strategy_channel="${focus.toUpperCase()}"&campaign_id=`;
        models.geoFilterStrategyChannelDimension = $.extend(true, {}, models.geoFilterDimension);
        models.geoFilterStrategyChannelDimension.fetchOptions.filter = strategyChannelFilter;
        models.geoStrategyChannelCollection = $.extend(true, {}, models.geoCollection);
        models.geoStrategyChannelCollection.fetchOptions.filter = strategyChannelFilter;
        this.filterDimension = comm.buildCollection("filterDimension", models.geoFilterStrategyChannelDimension);
        cfgs.collection = this.collection = comm.buildCollection("collection", models.geoStrategyChannelCollection);
      }
      this.filterDimension.fetchOptions.metrics = "clicks";
      cfgs.dailyCollection = this.dailyCollection = comm.buildCollection("dailyCollection", new CampaignReport({
        urlString: "std/geo",
        fetchOptions: dailyFetchOptions,
        url: function () {
          const opts = $.extend({}, this.fetchOptions);
          const moreFilters = reportModel.get("moreFilters") || "";
          const geoFilters = reportModel.get("geoFilters") || "";
          opts.filter += `${this.id}${moreFilters}${geoFilters}`;
          return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
        }
      }));
      this.updateGeoNavOnCampaignChange(this.currentReportId);
      this.updateGeoNav(reportModel.getGeoNav());
      this.updateFetchOptionsDate(dailyFetchOptions);
      this.initLayouts();
      this.setGetDataOnce();
    },
    initLayouts() {
      this.layout = new T1Layout({
        el: () => this.$(".w-report-panel"),
        template: layout,
        layout: {
          ".report-chart": [{
            module: "reporting/campaigns/geoPerformance",
            options: this.cfgs,
            viewType: "chart"
          }],
          ".report-controls": [{
            module: "reporting/campaigns/geoPerformance",
            options: this.cfgs,
            viewType: "controls"
          }],
          ".report-grid": [{
            module: "reporting/campaigns/geoPerformance",
            options: this.cfgs,
            viewType: "grid"
          }]
        }
      });
      this.layout.serialize = () => ({
        chartHidden: !this.chartShown
      });
    },
    updateFetchOptionsDate(fetchOptions) {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      if (reportModel.get("isCTD")) {
        delete fetchOptions.start_date;
        delete fetchOptions.end_date;
        fetchOptions.time_window = "campaign_to_date";
      } else {
        delete fetchOptions.time_window;
        if (reportModel.get("startDate")) {
          fetchOptions.start_date = mainModel.getDateString(reportModel.get("startDate"));
          fetchOptions.end_date = mainModel.getDateString(reportModel.get("endDate"));
        } else {
          fetchOptions.start_date = Utils.defaultStartDate.toString(dateFormat);
          fetchOptions.end_date = Utils.defaultEndDate.toString(dateFormat);
        }
      }
    },
    getDateFetchOptions() {
      let end_date, start_date;
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      if (reportModel.get("isCTD")) {
        this.dateOption = "ctd";
        return {
          time_window: "campaign_to_date"
        };
      }
      if (reportModel.get("startDate")) {
        start_date = mainModel.getDateString(reportModel.get("startDate"));
        end_date = mainModel.getDateString(reportModel.get("endDate"));
      } else {
        start_date = Utils.defaultStartDate.toString(dateFormat);
        end_date = Utils.defaultEndDate.toString(dateFormat);
      }
      this.dateOption = `${start_date}:${end_date}`;
      return {
        start_date: start_date,
        end_date: end_date
      };
    },
    timer(category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    },
    getData() {
      let dim, dimKey;
      const self = this;
      const {
        cfgs: cfgs,
        report: report,
        timer: timer,
        filterDimension: filterDimension,
        collection: collection,
        dailyCollection: dailyCollection,
        reportModel: reportModel
      } = this;
      const reportID = report.id;
      const models = cfgs.models;
      const dimensionInfo = reportModel.get("dimensionInfo");
      const tempDimension = reportModel.get("tempDimension");
      const tempDimensionInfo = reportModel.get("tempDimensionInfo");
      const geoLevelConfig = this.reportMeta.get("geoLevelConfig");
      const aggregateBy = geoLevelConfig[reportModel.getGeoNav().level].dimension;
      const focus = reportModel.get("focus") || "all";
      const section = "geo";
      let dateFetchOptions = this.dateFetchOptions;
      let dimensionName = "";
      let silent = false;
      const filterDimensionDeferred = When.defer();
      const summaryDeferred = When.defer();
      const dailyDeferred = When.defer();
      const promises = [filterDimensionDeferred.promise, summaryDeferred.promise, dailyDeferred.promise];
      let strategyChannelFilter = "";
      const campaignFetchOptionsExtend = {};
      if (!reportID) {
        return;
      }
      if (!(tempDimensionInfo && tempDimensionInfo.key)) {
        this.toggleLoader(true);
      }
      this.prevDateOption = this.dateOption;
      dateFetchOptions = this.getDateFetchOptions();
      this.updateFetchOptionsDate(campaignFetchOptionsExtend);
      this.campaignFetchOptionsExtend = campaignFetchOptionsExtend;
      if (tempDimensionInfo) {
        dimensionName = tempDimensionInfo.dimension;
      } else if (dimensionInfo) {
        dimensionName = dimensionInfo.dimension;
      }
      dimensionName = dimensionName.toUpperCase().replace(/[ ]/g, "_");
      reportModel.resetDateRangeChanged();
      const dateRangeChanged = this.prevDateOption !== undefined && this.prevDateOption !== this.dateOption;
      if (dateRangeChanged) {
        reportModel.resetOnDateRangeChange();
      }
      this.currentReportId = reportID;
      comm.reportID = reportID;
      comm.urlString = `std/${section}`;
      comm.resetDataItem("filterDimension", dateFetchOptions);
      comm.resetDataItem("collection", dateFetchOptions);
      comm.resetDataItem("dailyCollection", dateFetchOptions);
      this.dailyCollection.comparator = model => model.get("start_date");
      if (tempDimension) {
        this.dimensionData = tempDimensionInfo.key;
        silent = true;
      }
      if (!tempDimension) {
        reportModel.setCollectionFetchParams(collection.fetchOptions);
        dim = dimensionInfo.dimension;
        dimKey = dimensionInfo.key;
      } else {
        dim = tempDimensionInfo.dimension;
        dimKey = tempDimensionInfo.key;
      }
      filterDimension.fetchOptions.dimensions = dim === dimKey ? dim : `${dim},${dimKey}`;
      collection.fetchOptions.dimensions = this.geoDimensions;
      if (focus !== "all") {
        strategyChannelFilter = `strategy_channel="${focus.toUpperCase()}"&campaign_id=`;
        filterDimension.fetchOptions.filter = strategyChannelFilter;
        collection.fetchOptions.filter = strategyChannelFilter;
        dailyCollection.fetchOptions.filter = strategyChannelFilter;
      }
      this.abortAjaxCalls();
      timerValue = this.report.id;
      timer(GAMap.totalLoad, timerValue, "start");
      function loadFilterDimensionHandler() {
        timer(GAMap.filterDimensionsApiLoad + dimensionName, timerValue, "start");
        filterDimension.loaded = false;
        if (tempDimension) {
          models.tempDim1Coll = filterDimension;
        }
        filterDimension.fetch({
          onSuccess: function () {
            timer(GAMap.filterDimensionsApiLoad + dimensionName, timerValue, "stop");
            filterDimension.loaded = true;
            filterDimensionDeferred.resolve();
          }
        });
      }
      function loadGeoHandler() {
        timer(GAMap.geoApiLoad + dimensionName, timerValue, "start");
        collection.loaded = false;
        collection.fetch({
          silent: silent,
          onSuccess: function (collectionData) {
            timer(GAMap.geoApiLoad + dimensionName, timerValue, "stop");
            collection.loaded = true;
            self.collectionData = collectionData;
            reportModel.setReportInfo(`${section}-${focus}`, collection.ajxResponseHeaders);
            summaryDeferred.resolve();
          }
        });
      }
      function loadDailyGeoHandler() {
        timer(GAMap.geoDailyApiLoad + dimensionName, timerValue, "start");
        dailyCollection.loaded = false;
        dailyCollection.fetch({
          silent: silent,
          onSuccess: function () {
            timer(GAMap.geoDailyApiLoad + dimensionName, timerValue, "stop");
            dailyCollection.loaded = true;
            dailyDeferred.resolve();
          }
        });
      }
      loadFilterDimensionHandler();
      loadGeoHandler();
      if (reportModel.get("isCTD")) {
        dailyDeferred.resolve();
      } else {
        loadDailyGeoHandler();
      }
      When.all(promises).then(function () {
        let aggregatedEntities, countByEntities, jsonData;
        const duplicatesArr = [];
        const updateEntityName = function (attr) {
          if (duplicatesArr.includes(attr[aggregateBy])) {
            attr[aggregateBy] = `${attr.region_name}, ${attr.country_name}`;
          }
        };
        if (self.mainModel.leftTheSection(self.section)) {
          return;
        }
        timer(GAMap.totalLoad, timerValue, "stop");
        if (tempDimension) {
          self.dimensionData = null;
          reportModel.setDimensionItems({
            applying: false,
            dim1Data: filterDimension,
            dim1FilteredData: null,
            dim2Data: null
          });
        } else {
          if (aggregateBy === "region_name") {
            jsonData = collection.toJSON();
            aggregatedEntities = _.map(jsonData, item => item[aggregateBy]);
            countByEntities = _.countBy(aggregatedEntities);
            _.each(countByEntities, function (value, key) {
              if (value > 1) {
                duplicatesArr.push(key);
              }
            });
            collection.each(function (model) {
              updateEntityName(model.attributes);
            });
            dailyCollection.each(function (model) {
              updateEntityName(model.attributes);
            });
          }
          reportModel.setColorData(self.collectionData);
          reportModel.setDimensionItems({
            applying: true,
            dim1Data: filterDimension,
            dim1FilteredData: null,
            dim2Data: null
          });
          if (comm.dataGroup.dailyCollection.models.length && !reportModel.get("isCTD")) {
            reportModel.setTimeSeriesSlots(self.campaignFetchOptionsExtend, "geo_performance");
          }
          T1.EventHub.publish("responseHeadersReady", reportModel.get("reportInfo").reportTypes[`${section}-${focus}`]);
        }
        self.toggleLoader(false);
      });
    },
    setGetDataOnce() {
      this.getDataOnce = _.once(this.getData);
    },
    updateData() {
      this.setGetDataOnce();
      this.getDataOnce();
    },
    needsDataLoad() {
      return this.unloaded || this.currentReportId !== this.report.id || this.prevFocus !== this.focus || this.prevDimensionData !== this.dimensionData || this.prevMoreFilters !== this.moreFilters || this.prevGeoDimensions !== this.geoDimensions || this.prevGeoFilters !== this.geoFilters || this.prevDateOption !== this.dateOption;
    },
    resetData() {
      if (comm) {
        comm.clearDataGroup();
        comm = null;
      }
    },
    load() {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const dimensionInfo = reportModel.getDimensionInfo(false);
      if (mainModel.leftTheSection(this.section)) {
        return;
      }
      this.focus = reportModel.get("focus");
      this.dimensionData = dimensionInfo.key;
      this.chartShown = mainModel.get("chartShown") === "true";
      this.dateFetchOptions = this.getDateFetchOptions();
      mainModel.setTrackingSection(trackingSection);
      if (this.needsDataLoad()) {
        reportModel.resetColorData();
        this.setGetDataOnce();
      } else {
        reportModel.setCollectionFetchParams(this.collection.fetchOptions);
      }
      this.unloaded = false;
      this.layout.unload();
      this.initLayouts();
      this.render().then(() => {
        this.layout.load();
        this.$loader = this.$(".loader-container");
        this.getDataOnce();
        this.prevFocus = this.focus;
        this.prevDimensionData = this.dimensionData;
        this.prevMoreFilters = this.moreFilters;
        this.prevGeoDimensions = this.geoDimensions;
        this.prevGeoFilters = this.geoFilters;
        this.prevDateOption = this.dateOption;
      });
    },
    toggleLoader(show) {
      const animTime = 200;
      if (show) {
        this.$loader.fadeIn(animTime);
      } else {
        this.$loader.fadeOut(animTime);
      }
    },
    abortAjaxCalls() {
      if (comm) {
        comm.abortAjaxCalls(["filterDimension", "collection"]);
      }
    },
    unload() {
      this.abortAjaxCalls();
      this.layout.unload();
      this.resetData();
      this.unloaded = true;
    },
    updateGeoNav(geoNav) {
      let geoDimensions, geoFilters;
      switch (geoNav.level) {
        case "country":
          geoDimensions = "country_name,region_name";
          geoFilters = geoNav.country === "" ? "" : `&country_name=${geoNav.country}`;
          break;
        case "region":
          geoDimensions = "metro_name";
          geoFilters = geoNav.country === "" || geoNav.country !== "" && geoNav.region === "" ? "" : `&country_name=${geoNav.country}&region_name=${geoNav.region}`;
          break;
        default:
          geoDimensions = "country_name";
          geoFilters = "";
      }
      this.geoDimensions = geoDimensions;
      this.geoFilters = geoFilters;
      this.collection.fetchOptions.dimensions = geoDimensions;
      this.dailyCollection.fetchOptions.dimensions = geoDimensions;
      this.reportModel.setGeoFilters(geoFilters);
    },
    updateGeoNavOnCampaignChange(campaignID) {
      const reportModel = this.reportModel;
      if (reportModel.get("geoCampaignId") !== campaignID) {
        reportModel.setGeoNav(reportModel.getGeoNav().level, "", "");
        reportModel.setGeoCampaignId(campaignID);
      }
    }
  });
});
