define('modules/vendor/views/list',["jQuery", "T1", "T1View", "text!../templates/list.html", "text!../templates/item.html", "collections/vendors", "models/sessionUser", "T1Currencies", "T1Form", "T1Tooltip"], function ($, T1, T1View, template, itemTemplate, Vendors, User, t1Currencies) {
  var vendorSourceCode = {
    MediaMath: "1",
    T1Client: "0",
    None: "3"
  };
  var listView = T1View.extend({
    template: template,
    partials: {
      item: itemTemplate
    },
    initialize: function (args) {
      this.vendors = new Vendors();
      this.user = User.getUser();
      this.currencyCode = args.currencyCode;
    },
    updateCurrencySymbol: function (currencyCode) {
      var self = this;
      self.currencyCode = currencyCode;
    },
    events: {
      "click .add-vendor span": "addItem",
      "click .remove": "removeItem",
      "change .cost input": "changePrice"
    },
    formChangeHandler: function (agreement) {
      if (agreement.get("action") === "new") {
        delete agreement.attributes.action;
      }
      this.isFormElementsChanged = true;
    },
    addItem: function (event) {
      var collection = this.collection;
      var model;
      $(event.currentTarget).closest(".add-vendor").addClass("hidden");
      model = new collection.model({
        action: "new"
      });
      collection.add(model);
      this.renderPartialTemplate({
        template: itemTemplate,
        data: this.mapItem(model),
        action: "append",
        targetEl: this.el.find(".vendor-agreements"),
        useTemplateHtml: false,
        skipDelegateEvents: true
      });
      this.applySelectBoxes($(this.el).find(".agreement[data-id='" + (model.id || model.cid) + "']"));
      this.isFormElementsChanged = true;
      this.updateDisabledStates();
    },
    removeItem: function (event) {
      var collection = this.collection;
      var target = event.currentTarget;
      var $agreement = $(target).closest(".agreement");
      var $agreementsList = $agreement.closest(".vendor-agreements");
      var id = $agreement.attr("data-id");
      var $agreements;
      var agreement = collection.get(id);
      var $agreements;
      $agreement.remove();
      if (agreement) {
        agreement.set({
          action: "delete"
        });
      } else {
        agreement = collection.getByCid(id);
        if (agreement.id) {
          agreement.set({
            action: "delete"
          });
        } else {
          collection.remove(agreement);
        }
      }
      $agreements = $agreementsList.find(".agreement");
      if ($agreements.length) {
        $agreements.last().find(".add-vendor").removeClass("hidden");
      } else {
        collection.add({
          action: "new"
        });
        this.load();
      }
      this.isFormElementsChanged = true;
    },
    changePrice: function (event) {
      var id = $(event.currentTarget).closest(".agreement").attr("data-id");
      var collection = this.collection;
      var agreement = collection.get(id) || collection.getByCid(id);
      agreement.set({
        price: T1.Form.getUnmaskedVal($(event.currentTarget))
      });
      this.formChangeHandler(agreement);
    },
    updateDisabledStates: function () {
      var self = this;
      var $agreements = $(self.el).find(".w-vendorsList .agreement");
      var totalCnt = $agreements.length;
      var userType = self.user.get("type");
      var collection = self.collection;
      $.each($agreements, function (index, ele) {
        var $tmpEl = $(ele);
        var $sourceSelect = $tmpEl.find(".source select");
        var $vendorSelect = $tmpEl.find(".vendor select");
        var $priceInput = $tmpEl.find(".cost input");
        var $addVendorCmd = $tmpEl.find(".add-vendor");
        var $removeCmd = $tmpEl.find(".remove");
        var isLastItem = index === totalCnt - 1;
        var id = $tmpEl.attr("data-id");
        var sourceSelectVal = $sourceSelect.val();
        var agreement = collection.get(id) || collection.getByCid(id);
        var isOriginalMM = agreement && agreement.get("original_mm") === "1" ? true : false;
        var enableDisableEntireRow = function (toEnable) {
          if (toEnable === true) {
            $sourceSelect.removeAttr("disabled").trigger("liszt:updated");
            $vendorSelect.removeAttr("disabled").trigger("liszt:updated");
            $priceInput.removeAttr("disabled");
            $removeCmd.removeClass("hidden");
          } else {
            $sourceSelect.attr("disabled", "disabled").trigger("liszt:updated");
            $vendorSelect.attr("disabled", "disabled").trigger("liszt:updated");
            $priceInput.attr("disabled", "disable");
            if (!$removeCmd.hasClass("hidden")) {
              $removeCmd.addClass("hidden");
            }
          }
        };
        if (userType === "AGENCY" && isOriginalMM === true) {
          enableDisableEntireRow(false);
        } else {
          enableDisableEntireRow(true);
          if (sourceSelectVal === vendorSourceCode.None) {
            $vendorSelect.attr("disabled", "disabled").trigger("liszt:updated");
            $priceInput.attr("disabled", "disable").val("");
          } else {
            $vendorSelect.removeAttr("disabled").trigger("liszt:updated");
            if (userType === "AGENCY" && sourceSelectVal === vendorSourceCode.MediaMath) {
              $priceInput.attr("disabled", "disable");
            } else {
              $priceInput.removeAttr("disabled");
            }
          }
        }
        if (isLastItem === true && sourceSelectVal !== vendorSourceCode.None) {
          $addVendorCmd.removeClass("hidden");
        } else {
          if (!$addVendorCmd.hasClass("hidden")) {
            $addVendorCmd.addClass("hidden");
          }
        }
      });
    },
    applySelectBoxes: function ($scope) {
      var self = this;
      var autoCompleteConfig = {
        autoComplete: true,
        searchField: "name",
        onSearchRequest: function (searchOptions) {
          var $eventTarget = $(searchOptions.event.currentTarget);
          var source = $eventTarget.closest(".agreement").find(".source select").val();
          var params = searchOptions.params;
          delete searchOptions.event;
          params.push({
            term: "1",
            searchField: "is_eligible"
          });
          if (source === "1") {
            params.push({
              term: source,
              searchField: "mm_contract_available"
            });
          }
          searchOptions = $.extend({
            fetchRequired: true
          }, searchOptions);
          self.vendors.search.set(searchOptions);
        }
      };
      function updateAgreementDeleteState(toDelete, agreement, id) {
        var collection = self.collection;
        if (toDelete === true) {
          if (agreement) {
            agreement.set({
              action: "delete"
            });
          }
        } else {
          if (agreement && agreement.get("action") === "delete") {
            delete agreement.attributes.action;
          }
        }
      }
      T1.Selectbox($(".source select", $scope), {
        onChange: function (event) {
          var collection = self.collection;
          var target = event.currentTarget;
          var $agreement = $(target).closest(".agreement");
          var id = $agreement.attr("data-id");
          var agreement = collection.get(id) || collection.getByCid(id);
          var value = target.value;
          var userType = self.user.get("type");
          $agreement.find(".vendor select").html("<option/>").trigger("liszt:updated");
          $agreement.find(".vendor input").data("prevVal", "");
          $agreement.find(".cost input").val("");
          agreement.set({
            use_mm_contract: value == vendorSourceCode.None ? vendorSourceCode.T1Client : value
          });
          delete agreement.attributes.vendor;
          self.updateDisabledStates();
          if (value === vendorSourceCode.None) {
            updateAgreementDeleteState(true, agreement, id);
            self.isFormElementsChanged = true;
          } else {
            updateAgreementDeleteState(false, agreement, id);
            self.formChangeHandler.call(self, agreement);
          }
        }
      });
      T1.Selectbox($(".vendor select", $scope), {
        enableDirectionAuto: false,
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search Vendor",
        onChange: function (event) {
          var collection = self.collection;
          var $agreement = $(event.currentTarget).closest(".agreement");
          var agreementId = $agreement.attr("data-id");
          var agreement = collection.get(agreementId) || collection.getByCid(agreementId);
          var vendorId = $agreement.find(".vendor select").val();
          var vendor = self.vendors.get(vendorId);
          var price;
          price = vendor.get("rate_card_price") || "0.00";
          $agreement.find(".cost input").val(price).focus().blur();
          agreement.set({
            vendor: vendor.toJSON(),
            price: price,
            rate_card_type: vendor.get("rate_card_type") || "CPM"
          });
          self.formChangeHandler.call(self, agreement);
        }
      }, autoCompleteConfig);
    },
    canUnload: function (onTabChange) {
      var self = this;
      var isFormElementsChanged = self.isFormElementsChanged;
      self.isFormElementsChanged = false;
      return !isFormElementsChanged;
    },
    load: function () {
      var self = this;
      var collection = this.collection;
      $.each(collection.models, function (index, model) {
        if (model.get("action") !== "new" && model.get("use_mm_contract") === "1") {
          model.set({
            original_mm: "1"
          });
        }
      });
      this.isFormElementsChanged = false;
      this.render().then(function listViewPostRender() {
        var $el = $(self.el);
        T1.Form.Masks.attach($("input", $el));
        self.applySelectBoxes.call(self, $el);
        self.updateDisabledStates();
        T1.Tooltip($el, {
          offset: 5,
          delayIn: "100",
          delayOut: "100"
        });
      });
    },
    mapItem: function (model) {
      var sources = [{
        text: "Select One",
        value: vendorSourceCode.None
      }, {
        text: "T1 Client",
        value: vendorSourceCode.T1Client
      }, {
        text: "Mediamath",
        value: vendorSourceCode.MediaMath
      }];
      var userType = this.user.get("type");
      var vendor = model.get("vendor");
      var currencySym = t1Currencies.getCurrencySymbol(this.currencyCode);
      var vendorContract = {
        id: model.id || model.cid,
        sources: sources,
        getSelectOptions: function () {
          return function (attr) {
            var hasVendor = model.get("vendor");
            var selected = "";
            if (this.value === vendorSourceCode.None && !hasVendor) {
              selected = "selected='selected'";
            } else if (hasVendor) {
              selected = this.value === (model.get("use_mm_contract") || vendorSourceCode.MediaMath) ? "selected='selected'" : "";
            }
            return "<option " + selected + " value='" + this.value + "'>" + this.text + "</option>";
          };
        },
        vendor_id: vendor ? vendor.id : undefined,
        vendor_name: vendor ? vendor.name : undefined,
        cpm: model.get("price"),
        cpmReadOnly: userType === "AGENCY" && (model.get("use_mm_contract") || vendorSourceCode.MediaMath) === vendorSourceCode.MediaMath ? true : false,
        currency_symbol: currencySym
      };
      if (model.get("action") !== "delete") {
        return vendorContract;
      }
    },
    serialize: function () {
      var collection = this.collection;
      var vendorContracts = [];
      var self = this;
      var mappedModel;
      var length = collection.length;
      var pushed = false;
      var currencySym = t1Currencies.getCurrencySymbol("USD");
      $.each(collection.models, function (index, model) {
        if (model.get("action") !== "delete") {
          mappedModel = self.mapItem.call(self, model);
          if (index === length - 1 && model.get("vendor")) {
            mappedModel.last = true;
          }
          mappedModel.currency_symbol = currencySym;
          vendorContracts.push(mappedModel);
          pushed = true;
        }
      });
      if (pushed === false) {
        var modelNew = new collection.model({
          action: "new"
        });
        collection.add(modelNew);
        mappedModel = self.mapItem(modelNew);
        mappedModel.last = true;
        mappedModel.currency_symbol = currencySym;
        vendorContracts.push(mappedModel);
      }
      return {
        vendorContracts: vendorContracts
      };
    }
  });
  return listView;
});
