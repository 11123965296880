define('models/sessionUserPermissions',['require','T1','T1Model','T1UtilsAsync'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1Model = require("T1Model");
  const T1UtilsAsync = require("T1UtilsAsync");
  let instance;
  const Permissions = T1Model.extend({
    name: "userpermissions",
    fixture: "-permissions",
    url() {
      return `${this.apiRoot}users/${this.id}/permissions?now_time=${T1.getRandomNumber()}`;
    },
    loadPermissions(args) {
      const self = this;
      const deferred = T1UtilsAsync.makeDeferral();
      if (!args || !args.id) {
        return false;
      }
      this.id = args.id;
      this.fetch({
        async: false,
        success: function (model, resp) {
          if (resp && resp.status && resp.status.code === "ok") {
            if (args.parseEntities) {
              self.parseEntities(resp);
            }
            deferred.resolve(resp);
          } else {
            deferred.resolve({
              permissions: undefined
            });
          }
        },
        error: function (err) {
          deferred.reject(err);
        }
      });
      return deferred.promise;
    },
    savePermissions(permissions, isGlobalAdmin) {
      let postData;
      const deferred = T1UtilsAsync.makeDeferral();
      if (isGlobalAdmin) {
        postData = {
          organization_id: "",
          agency_id: "",
          advertiser_id: ""
        };
      } else {
        postData = permissions;
      }
      if (postData && !T1.Utils.isEmptyObj(postData)) {
        this.save(postData, {
          success: function () {
            deferred.resolve();
          },
          error: function (err) {
            deferred.reject(err);
          }
        });
      }
      return deferred.promise;
    },
    parseEntities(resp) {
      let entitiesObj, partialEntities;
      const entities = resp ? resp.permissions.entities : null;
      resp.entitiesObj = entitiesObj = {
        org: {},
        ag: {},
        adv: {}
      };
      resp.partialEntities = partialEntities = {
        org: {},
        ag: {}
      };
      if (entities) {
        const {
          organization: org,
          agency: ag,
          advertiser: adv
        } = entities;
        const orgs = org === "" ? [] : Array.isArray(org.access) ? org.access : [org.access];
        const ags = ag === "" ? [] : Array.isArray(ag.access) ? ag.access : [ag.access];
        const advs = adv === "" ? [] : Array.isArray(adv.access) ? adv.access : [adv.access];
        for (const orgItem of orgs) {
          const orgId = orgItem.id;
          let permChildren = 0;
          for (const agItem of ags) {
            if (agItem.organization_id === orgId) {
              permChildren++;
            }
          }
          entitiesObj.org[orgId] = orgItem;
          partialEntities.org[orgId] = parseInt(orgItem.child_count, 10) > permChildren;
        }
        for (const agItem of ags) {
          const agId = agItem.id;
          let permChildren = 0;
          for (const advItem of advs) {
            if (advItem.agency_id === agId) {
              permChildren++;
            }
          }
          entitiesObj.ag[agId] = agItem;
          partialEntities.ag[agId] = parseInt(agItem.child_count, 10) > permChildren;
        }
        for (const advItem of advs) {
          entitiesObj.adv[advItem.id] = advItem;
        }
      }
    }
  });
  function getPermissions(args) {
    if (instance === undefined) {
      instance = new Permissions(args);
    }
    return instance;
  }
  return {
    getPermissions: getPermissions,
    createPermissions: function (args) {
      return new Permissions(args);
    }
  };
});
