define('modules/campaign/bulkedit/targeting/location/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Model", "text!templates/alert.html", "text!../templates/panel.html", "text!../templates/panel_layout.html", "T1Layout", "collections/targetValues", "T1FileUpload", "T1Spinner", "utils/CampaignStrategyUtils", "text!templates/info_box.html", "T1Tooltip", "T1Preferences", "T1Signature"], function ($, _, T1, T1View, T1Model, alertDialogTemplate, template, panelLayout, T1Layout, TargetValues, T1FileUpload, Spinner, CampaignStrategyUtils, info_box) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  const LocationTargetingView = T1View.extend({
    template: template,
    viewMode: "browse",
    formConfig: {
      bulkedit: true,
      bulkeditActionViews: true
    },
    events: {
      "click .postal-codes .icon": "setPostalCodeRestriction",
      "click #closeBtn": "onCloseInvalidPostalClick"
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    eventhubEvents: {
      clearzipcodes: function () {
        this.$(".postal-codes .icon").removeClass("active");
        this.$(".postal-codes .include").addClass("active");
        this.$(".file-input").val("");
        this.$(".file-selector .file-name input").val("");
        this.onZipCodeChange();
        this.showHidePostalExcludeIncludeIcons(false);
        delete this.zipCodeInfo;
        this.toggleStageChangesBtn();
      },
      uploadzipcodes: "onZipCodeChange",
      "strategyBulkEditLocations:bulkeditActionChanged": function () {
        this.toggleStageChangesBtn();
      }
    },
    initialize: function ({
      bulkeditor: bulkeditor
    }) {
      this.bulkeditor = bulkeditor;
      this.collection = new TargetValues([], {
        pageLimit: 100,
        dimension: "REGN"
      });
      this.collection.isPaginated = true;
      this.sharedCollection = new TargetValues([], {
        dimension: "REGN",
        isPaginated: false
      });
      this.partialCollection = new TargetValues([], {
        dimension: "REGN",
        isPaginated: false
      });
      this.zipcodeUrl = `${T1.API_ROOT}target_values/validate_postal_csv`;
      this.zipcodeStageUrl = `${T1.API_ROOT}/campaigns/${this.bulkeditor.get("parentId")}/bulk_edits/${this.bulkeditor.get("editorId")}/actions_csv`;
      this.preventDoubleBind = true;
      this.bindViewEvents();
      T1.EventHub.publish("targetingTab:select", {
        type: "location"
      });
      this.checkBulkeditorReady();
    },
    showHidePostalExcludeIncludeIcons: function (toShow) {
      var settingVal = "hidden";
      if (toShow === true) {
        settingVal = "visible";
      }
      this.$(".postal-codes .icons .icon").css("visibility", settingVal);
    },
    onCloseInvalidPostalClick: function () {
      this.$("#errorModal")[0].hide();
    },
    prepareSharedCollection: function () {
      var bulkeditorIds = [];
      var locations = new TargetValues();
      const stagedLocations = this.stagedLocations;
      for (let i = 0; i < stagedLocations.ids.length; i++) {
        const bulkeditorModel = stagedLocations.ids[i];
        bulkeditorIds.push(bulkeditorModel.id);
      }
      locations.fetchOptions = {
        full: "*",
        include_ancestors: 1
      };
      this.sharedCollection.bulkeditAction = stagedLocations.action;
      locations.search.set({
        idList: bulkeditorIds,
        fetchRequired: true,
        success: coll => {
          this.locations = coll;
          coll.bulkeditAction = this.stagedLocations.action;
          for (let j = 0; j < stagedLocations.ids.length; j++) {
            const bulkeditorModel = stagedLocations.ids[j];
            const modelObj = coll.get(bulkeditorModel.id);
            const clonedModel = modelObj.clone();
            clonedModel.set({
              action: bulkeditorModel.exclude ? "exclude" : "include"
            });
            this.sharedCollection.add(clonedModel, {
              silent: true
            });
            coll.remove(modelObj);
          }
          if (coll.length) {
            coll.each(model => {
              this.partialCollection.add(model.clone(), {
                silent: true
              });
            });
          }
          this.initializeDataEventsAndLayout();
          this.load();
        }
      });
    },
    checkBulkeditorReady: function () {
      if (this.bulkeditor.editorReady) {
        this.retrieveStagedLocationIds();
        this.retrieveAttachedZipcodes();
        if (this.stagedLocations.ids.length) {
          this.prepareSharedCollection();
        } else {
          this.initializeDataEventsAndLayout();
        }
      } else {
        this.bulkeditor.getStatus({
          success: () => {
            this.retrieveStagedLocationIds();
            this.retrieveAttachedZipcodes();
            if (this.stagedLocations.ids.length) {
              this.prepareSharedCollection();
            } else {
              this.initializeDataEventsAndLayout();
              this.load();
            }
          }
        });
      }
    },
    retrieveStagedLocationIds: function () {
      var stagedActions = this.bulkeditor.get("stagedActions");
      const stagedLocations = {
        ids: []
      };
      $.each(stagedActions, function (index, actionObj) {
        if (actionObj.tag === "location") {
          if (actionObj.add) {
            stagedLocations.action = "add";
            stagedLocations.ids.push(actionObj.add);
          } else if (actionObj.remove) {
            stagedLocations.action = "remove";
            stagedLocations.ids.push(actionObj.remove);
          } else if (actionObj.overwrite) {
            stagedLocations.action = "overwrite";
            stagedLocations.ids.push(actionObj.overwrite);
          }
        }
      });
      this.stagedLocations = stagedLocations;
    },
    retrieveAttachedZipcodes: function () {
      var stagedActions = this.bulkeditor.get("stagedActions");
      const stagedZipcodes = {
        ids: []
      };
      var zipcodeModel = new T1Model();
      $.each(stagedActions, function (index, actionObj) {
        if (actionObj.tag === "zipcodes") {
          if (actionObj.add) {
            stagedZipcodes.action = "add";
            stagedZipcodes.ids.push(actionObj.add);
          } else if (actionObj.remove) {
            stagedZipcodes.action = "remove";
            stagedZipcodes.ids.push(actionObj.remove);
          } else if (actionObj.overwrite) {
            stagedZipcodes.action = "overwrite";
            stagedZipcodes.ids.push(actionObj.overwrite);
          }
        }
      });
      if (!stagedZipcodes.ids.length) {
        return;
      }
      this.stagedZipcodes = stagedZipcodes;
      zipcodeModel.set({
        count: stagedZipcodes.ids.length,
        dimension_code: "USZC",
        name: "postal code",
        action: stagedZipcodes.ids[0].exclude ? "exclude" : "include"
      });
      this.sharedCollection.add(zipcodeModel, {
        silent: true
      });
      this.zipCodeInfo = {
        filename: zipcodeModel.get("name"),
        action: zipcodeModel.get("action"),
        count: zipcodeModel.get("count"),
        unchanged: true
      };
    },
    initializeDataEventsAndLayout: function () {
      this.addDataEvent({
        sharedCollection: {
          reset: function () {
            this.isFormElementsChanged = false;
          },
          add: function () {
            this.isFormElementsChanged = true;
            this.toggleStageChangesBtn();
          },
          remove: function () {
            this.isFormElementsChanged = true;
            this.toggleStageChangesBtn();
          }
        }
      });
      if (!this.sharedCollection.bulkeditAction && this.stagedZipcodes) {
        this.sharedCollection.bulkeditAction = this.stagedZipcodes.action;
      }
      this.initLayout();
      this.isFormElementsChanged = false;
      this.removeEventHubEvents(["strategyTargetDimension:reset"]);
    },
    onZipCodeChange: function (data) {
      this.isFormElementsChanged = true;
      this.changedZipCodes = true;
      if (data) {
        this.zipCodeCount = data.count;
      } else {
        delete this.zipCodeCount;
      }
    },
    canUnload: function (onTabChange) {
      if (this.isFormElementsChanged) {
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              isBulkEdit: true,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertBulkeditDialog({}, onTabChange);
      }
      return !this.isFormElementsChanged;
    },
    setPostalCodeRestriction: function (event) {
      var $target = $(event.currentTarget);
      const action = $target.hasClass("include") ? "INCLUDE" : "EXCLUDE";
      if (this.zipcodeUploading === true) {
        return;
      }
      $target.siblings().removeClass("active");
      $target.addClass("active");
      $target.closest(".postal-codes").find("form .restriction").attr("value", action);
      if (this.zipCodeInfo) {
        this.zipCodeInfo.action = action;
        this.zipCodeInfo.unchanged = false;
        T1.EventHub.publish("uploadzipcodes", this.zipCodeInfo);
      }
    },
    fileUploaderData: {
      inputFields: [{
        name: "restriction",
        value: "INCLUDE"
      }, {
        name: "exclude",
        value: "0"
      }, {
        name: "action_name",
        value: "add"
      }, {
        name: "ignore_errors",
        value: "1"
      }, {
        name: "collection",
        value: "strategies"
      }, {
        name: "tag",
        value: "zipcodes"
      }, {
        name: "active",
        value: "true"
      }],
      formSelector: "zipcode-upload",
      placeholder: "Select a CSV file with your complete list of postal codes to target"
    },
    load: function () {
      this.render().then(() => {
        if (this.layout) {
          this.layout.load().then(() => {
            T1.EventHub.publish("bulkEdit:hideLoadingIcon");
            T1.Tooltip(this.$(".filter-strip .save-default-group"), {
              offset: 5,
              delayIn: "100",
              delayOut: "100"
            });
            this.toggleStageChangesBtn();
          });
          if (!this.fileUploader) {
            this.fileUploader = T1FileUpload.create({
              $el: this.$(".file-uploader-c"),
              onChange: $.proxy(this.changeFile, this),
              sourceURL: this.zipcodeUrl,
              bulkeditorLocation: true
            });
          }
        }
        if (this.fileUploader) {
          this.fileUploader.render(this.fileUploaderData);
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn();
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
        this.showHidePostalExcludeIncludeIcons(false);
      });
    },
    reload: function () {
      T1.EventHub.publish("targetingTab:select", {
        type: "location"
      });
      this.checkBulkeditorReady();
    },
    unload: function () {
      delete this.zipCodeInfo;
      if (this.fileUploader) {
        this.fileUploader.destroy();
        this.fileUploader = null;
      }
      T1.EventHub.publish("bulkEdit:showLoadingIcon");
      this.bulkeditor.editorReady = false;
      this.sharedCollection.reset([], {
        silent: true
      });
      this.partialCollection.reset([], {
        silent: true
      });
      this.collection.reset([], {
        silent: true
      });
      this.loaded = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    toggleStageChangesBtn: function () {
      const $parents = this.el.parents();
      var $stageChangesBtn = $parents.find(".stage");
      var $stageAndReviewBtn = $parents.find(".stageAndReview");
      const isEnabled = (this.sharedCollection.models.length || this.zipCodeInfo) && this.sharedCollection.bulkeditAction;
      if (!isEnabled) {
        $stageChangesBtn.addClass("disabled");
        $stageAndReviewBtn.addClass("disabled");
      } else {
        $stageChangesBtn.removeClass("disabled");
        $stageAndReviewBtn.removeClass("disabled");
      }
    },
    syncUploadRestrictionFieldWithIconVals: function () {
      const $postalCodes = this.$(".postal-codes");
      const resultAction = $postalCodes.find(".icons .icon.exclude.active").length ? "EXCLUDE" : "INCLUDE";
      $postalCodes.find("form .restriction").attr("value", resultAction);
    },
    changeFile: function (event) {
      var self = this;
      var fileName = "postalcodes.csv";
      const spinnerTarget = this.$(".postal-codes .icon-spinner");
      if (this.zipcodeUploading === true) {
        this.isFormElementsChanged = true;
        return;
      }
      this.syncUploadRestrictionFieldWithIconVals();
      const action = event.target.form.querySelector(".restriction").value;
      $(".validate_only", this.el).val("1");
      this.showHidePostalExcludeIncludeIcons(true);
      function successHandler(response) {
        self.zipCodeInfo = {
          filename: fileName,
          action: action,
          count: response.entities.count,
          unchanged: false
        };
        T1.EventHub.publish("uploadzipcodes", self.zipCodeInfo);
        self.zipcodeUploading = false;
        self.$(".postal-codes .icon-spinner").spin(false);
        self.toggleStageChangesBtn();
      }
      function generatePostalGridData(errors) {
        const data = [];
        if (errors.length) {
          errors.forEach(function (key) {
            const errorZipcode = key.error.substring(key.error.indexOf("'"));
            const errorZip = errorZipcode.replace(new RegExp("['.]", "g"), "");
            const lineNo = key.error.substring(0, key.error.indexOf(","));
            data.push({
              id: lineNo,
              row: errorZip
            });
          });
        } else {
          const errorZipcode = errors.error.substring(errors.error.indexOf("'"));
          const errorZip = errorZipcode.replace(new RegExp("['.]", "g"), "");
          const lineNo = errors.error.substring(0, errors.error.indexOf(","));
          data.push({
            id: lineNo,
            row: errorZip
          });
        }
        return data;
      }
      function errorHandler(args) {
        self.zipcodeUploading = false;
        self.zipCodeInfo = undefined;
        self.zipcodeUploading = false;
        self.$(".postal-codes .icon-spinner").spin(false);
        self.showHidePostalExcludeIncludeIcons(false);
        self.$(".postal-codes .file-name input").val("");
        if (args) {
          const jsonData = T1.Utils.parseXMLtoJson($.parseXML(args));
          const $errorModal = self.$("#errorModal");
          const grid = $errorModal.find("#ipTargetingErrorGrid")[0];
          grid.data = generatePostalGridData(jsonData.errors.field_error);
          $errorModal[0].show();
          grid.$.viewport.scrollToIndex(0);
          const totalRejected = jsonData.errors.field_error.length || 1;
          self.$("#totalLen").html(`Total postal codes rejected:  ${totalRejected}`);
        }
      }
      this.zipcodeUploading = true;
      Spinner.apply(spinnerTarget, this.spinnerOpts);
      this.fileUploader.upload({
        onSuccess: successHandler,
        onError: errorHandler
      });
    },
    initLayout: function () {
      const {
        collection: collection,
        sharedCollection: sharedCollection,
        partialCollection: partialCollection
      } = this;
      var selectOptions = [{
        text: "Country/Region/City",
        value: "REGN"
      }, {
        text: "DMA",
        value: "DMAX"
      }];
      var filterClass = "locationTargets";
      var filterCallback = function (term) {
        const type = this.$(`.${filterClass}`).val();
        collection.trigger("search", {
          term: term,
          type: type
        });
      };
      var clearFilterCallback = function () {
        collection.trigger("search.clear");
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panelLayout,
        partials: {
          info_box: info_box,
          alertDialog: alertDialogTemplate
        },
        layout: {
          ".filters-c": [{
            module: "shared",
            viewType: "filter",
            options: {
              collection: collection,
              filterClass: filterClass,
              selectOptions: selectOptions,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback
            }
          }],
          ".browser-c": [{
            module: "campaign/bulkedit/targeting/location",
            viewType: "browse",
            options: {
              collection: collection,
              sharedCollection: sharedCollection,
              partialCollection: partialCollection
            }
          }],
          ".bins-column": [{
            module: "campaign/bulkedit/targeting/location",
            viewType: "bins",
            options: {
              sharedCollection: sharedCollection,
              filterConfigs: {
                filterOptions: selectOptions,
                filterClass: filterClass
              },
              collection: collection,
              partialCollection: partialCollection
            }
          }]
        },
        serialize: function () {
          var returnObj = {};
          returnObj.msgForSaveDefaultLocation = {
            infoBoxMessageLines: ["Your default Location targeting settings have been updated and will be applied " + "to all future strategies unless modified.", "&nbsp;NOTE: Does not include Postal Codes."],
            infoMessageCanClose: true,
            infoBoxClassName: "save-defaultlocation-info"
          };
          return returnObj;
        }
      });
    },
    prepareStagingData: function () {
      var locationData = {};
      if (!this.sharedCollection.length) {
        return false;
      } else if (this.sharedCollection.length === 1 && this.sharedCollection.models[0].get("dimension_code") === "USZC") {
        return false;
      }
      locationData.ids = [];
      this.sharedCollection.each(function (model) {
        if (model.get("name") !== "postal code") {
          locationData.ids.push({
            id: model.get("id"),
            segmentAction: model.get("action"),
            targetOp: "OR"
          });
        }
      });
      locationData.bulkeditAction = this.sharedCollection.bulkeditAction;
      locationData.collection = "target_values";
      locationData.tag = "location";
      return locationData;
    },
    stage: function (successCallback, invalidCallback) {
      var stagingData = this.prepareStagingData();
      var $form = $(".zipcode-upload", this.el);
      if (!stagingData && (!this.zipCodeInfo || this.zipCodeInfo.count === 0)) {
        invalidCallback(undefined, "Please include a location or upload zipcodes.");
        return;
      } else if (!stagingData && this.zipCodeInfo && this.zipCodeInfo.unchanged) {
        this.bulkeditor.stageActions({
          deleteCallOnly: true,
          tag: "location"
        }, {
          success: () => {
            this.onStageActionSuccess(successCallback);
          }
        });
        return;
      }
      if (this.zipCodeInfo && !this.zipCodeInfo.unchanged) {
        $(".exclude", $form).val($(".restriction", $form).val() === "EXCLUDE" ? 1 : 0);
        $(".action_name", $form).val("overwrite");
        this.bulkeditor.stageActions({
          deleteCallOnly: true,
          tag: "zipcodes"
        }, {
          success: () => {
            this.fileUploader.upload({
              sourceURL: this.zipcodeStageUrl,
              onSuccess: () => {
                if (stagingData) {
                  stagingData.deleteZipcodes = false;
                  this.bulkeditor.stageActions(stagingData, {
                    success: (newModel, attributes) => {
                      if (!attributes.entity.execution_errors.length) {
                        this.onStageActionSuccess(successCallback);
                      }
                    }
                  });
                } else {
                  this.bulkeditor.stageActions({
                    deleteCallOnly: true,
                    tag: "location"
                  }, {
                    success: () => {
                      this.onStageActionSuccess(successCallback);
                    }
                  });
                }
              }
            });
          }
        });
      } else {
        if (!this.zipCodeInfo) {
          stagingData.deleteZipcodes = true;
        } else {
          stagingData.deleteZipcodes = false;
        }
        this.bulkeditor.stageActions(stagingData, {
          success: (newModel, attributes) => {
            if (!attributes.entity.execution_errors.length) {
              this.onStageActionSuccess(successCallback);
            }
          }
        });
      }
    },
    onStageActionSuccess: function (callback) {
      T1.EventHub.publish("stageActionsSuccess");
      T1.Notify("message", "Locations have been staged successfully!");
      callback();
    }
  });
  return T1.Form.ViewUtils.extend(LocationTargetingView);
});
