define('modules/admin/user/views/permissionsGrid',['require','Underscore','jQuery','utils/AdminUtils','collections/advertisers','collections/agencies','text!modules/admin/user/templates/permissionGridItem.html','collections/organizations','models/sessionUser','T1Spinner','T1','T1GridView','T1View','models/sessionUserPermissions'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const Advertisers = require("collections/advertisers");
  const Agencies = require("collections/agencies");
  const gridItem = require("text!modules/admin/user/templates/permissionGridItem.html");
  const Organizations = require("collections/organizations");
  const SessionUser = require("models/sessionUser");
  const Spinner = require("T1Spinner");
  const T1 = require("T1");
  const T1GridView = require("T1GridView");
  const T1View = require("T1View");
  const UserPermissions = require("models/sessionUserPermissions");
  const entitySpacing = 5;
  const agencyIndent = 45;
  const margin = 20;
  const renderDelay = 100;
  const sessionUser = SessionUser.getUser();
  const permissionsGridView = T1View.extend({
    partials: {
      listItem: gridItem
    },
    canLoad: false,
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 9,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    events: {
      "click .headers .checkbox": "handleAllChecked",
      "click .item": "toggle",
      "click .w-body.content .checkbox.partial": "partialClick"
    },
    eventhubEvents: {
      "permissions:isShowAllowedEntities": function (isShowAllowedEntities, switchedFromGlobalAdmin) {
        this.isShowAllowedEntities = isShowAllowedEntities;
        if (switchedFromGlobalAdmin) {
          this.switchedFromGlobalAdmin = true;
        } else {
          this.showLoader();
          this.getData();
        }
      },
      "permissionsGrid:applyFilter": function (args) {
        this.filterValue = args.entity;
        if (this.searchTerm && this.searchTerm !== "") {
          this.showLoader();
          this.getData();
        }
      },
      "permissionsGrid:reload": function (args) {
        this.searchTerm = args.searchTerm;
        this.field = args.field;
        this.term = args.term;
        this.showLoader();
        this.getData();
      },
      "user:savedSuccessful": function (editSaveObj) {
        const entityMap = {
          org: "organization_id",
          ag: "agency_id",
          adv: "advertiser_id"
        };
        const userPermissions = {
          organization_id: [],
          agency_id: [],
          advertiser_id: []
        };
        const savePermissionsDoneHandler = () => {
          T1.EventHub.publish("user:savedPermissions");
          if (saveBtnUsed) {
            this.reload();
          }
        };
        const {
          createEditView: createEditView,
          permissionObj: permissionObj
        } = this;
        const isGlobalAdmin = editSaveObj.isGlobalAdmin;
        const permissions = UserPermissions.createPermissions();
        const getSaveAndContinueMode = createEditView.getSaveAndContinueMode;
        const saveBtnUsed = getSaveAndContinueMode && !getSaveAndContinueMode.call(createEditView);
        permissions.id = editSaveObj.id;
        if (sessionUser.id === this.editedUserId || this.editedUserId && this.isPermissionDataLoaded === false) {
          T1.EventHub.publish("user:savedPermissions");
          return;
        }
        for (const item of Object.values(permissionObj)) {
          if (item.state === "checked" && !item.disabled) {
            const parent = permissionObj[item.parentType + item.parentID];
            if (item.entityType === "org" || parent && (parent.state === "partial" || parent.state === "checked" && parent.disabled)) {
              userPermissions[entityMap[item.entityType]].push(item.entityID);
            }
          }
        }
        permissions.savePermissions(userPermissions, isGlobalAdmin).then(savePermissionsDoneHandler);
      }
    },
    isInitDataLoaded: false,
    permissionObj: null,
    initialize(args) {
      this.headers = [{
        name: "checkbox-container",
        label: '<strand-checkbox class="checkbox all" data-bind="has_assigned_entities"></strand-checkbox>'
      }, {
        name: "entity",
        label: "Organization/Agency/Advertiser"
      }];
      Object.assign(this, {
        collectionAdv: new Advertisers(),
        collectionAg: new Agencies(),
        createEditView: args.createEditView,
        editedUserId: args.id,
        entityAdding: "org",
        initLoaderShown: false,
        isPermissionDataLoaded: false,
        isShowAllowedEntities: args.isShowAllowedEntities,
        selfEditNotPermitted: false,
        sessionUserEntitiesObj: sessionUser.get("entitiesObj"),
        sessionUserIsGlobalAdmin: sessionUser.get("role") === "ADMIN" && sessionUser.get("scope") === "GLOBAL",
        sessionUserPartialEntities: sessionUser.get("partialEntities"),
        userIsReporter: args.model.get("role") === "REPORTER",
        userRole: args.model.get("role"),
        userScope: args.model.get("scope"),
        userType: args.model.get("type")
      });
      this.setCheckboxEditableState();
    },
    showLoader() {
      if (!this.gridLoader) {
        this.gridLoader = T1.Loader.create({
          target: ".w-DataGrid .w-body.content",
          text: "Loading ...",
          contentClass: "search-loader loader-overrides grid-loader",
          spinnerConfig: {
            lines: 11,
            length: 1,
            radius: 3,
            left: 1,
            top: 0
          }
        });
      }
      this.$(".no-results").remove();
      this.gridLoader.start();
    },
    setCheckboxEditableState() {
      const {
        editedUserId: editedUserId,
        userRole: userRole,
        userScope: userScope,
        userType: userType
      } = this;
      const isAgencyAdmin = userType === "AGENCY" && userRole === "ADMIN";
      const isAgencyMng = userType === "AGENCY" && userRole === "MANAGER";
      const isAgencyReporter = userType === "AGENCY" && userRole === "REPORTER";
      const isInternalRestrictedAdmin = userType === "INTERNAL" && userRole === "ADMIN" && userScope === "SELECT";
      const isInternalRestrictedMng = userType === "INTERNAL" && userRole === "MANAGER" && userScope === "SELECT";
      const isInternalRestrictedReporter = userType === "INTERNAL" && userRole === "REPORTER" && userScope === "SELECT";
      const isSessionUser = sessionUser.id === editedUserId;
      if (isSessionUser && (isAgencyAdmin || isAgencyMng || isAgencyReporter || isInternalRestrictedAdmin || isInternalRestrictedMng || isInternalRestrictedReporter)) {
        this.selfEditNotPermitted = true;
      }
    },
    preLoadPermissionModel() {
      const orgIds = [];
      const {
        advertiserPermissionsAry: advertiserPermissionsAry,
        agencyPermissionsAry: agencyPermissionsAry,
        organizationPermissionsAry: organizationPermissionsAry,
        sessionUserIsGlobalAdmin: sessionUserIsGlobalAdmin
      } = this;
      function getOrigPermissionOrgs(item) {
        if (orgIds.includes(item.entityID)) {
          return item.entityType === "org";
        }
      }
      if (this.permissionObj === null) {
        this.permissionObj = {};
      }
      const permissionObj = this.permissionObj;
      if (organizationPermissionsAry) {
        for (const item of organizationPermissionsAry) {
          let permObj = this.getPermissionItem(`org${item.id}`);
          const state = item.value === "0" ? "partial" : "checked";
          if (!permObj.entityID) {
            permObj = {
              entityID: item.id,
              entityType: "org",
              state: state
            };
          }
          permObj.disabled = sessionUserIsGlobalAdmin ? false : state === "partial";
          permissionObj[`org${item.id}`] = permObj;
          orgIds.push(item.id);
        }
      }
      if (advertiserPermissionsAry) {
        for (const item of advertiserPermissionsAry) {
          let permObj = this.getPermissionItem(`adv${item.id}`);
          if (!permObj.entityID) {
            permObj = {
              entityID: item.id,
              entityType: "adv",
              parentID: item.agency_id,
              parentType: "ag",
              state: "checked"
            };
          }
          permObj.disabled = false;
          permissionObj[`adv${item.id}`] = permObj;
        }
      }
      if (agencyPermissionsAry) {
        for (const item of agencyPermissionsAry) {
          let permObj = this.getPermissionItem(`ag${item.id}`);
          const state = item.value === "0" ? "partial" : "checked";
          if (!permObj.entityID) {
            permObj = {
              entityID: item.id,
              entityType: "ag",
              parentID: item.organization_id,
              parentType: "org",
              state: state
            };
          }
          permObj.disabled = sessionUserIsGlobalAdmin ? false : state === "partial";
          permissionObj[`ag${item.id}`] = permObj;
        }
      }
      this.origPermissionOrgs = Object.values(permissionObj).filter(getOrigPermissionOrgs);
    },
    getData() {
      const self = this;
      const searchTerm = this.searchTerm;
      function displayPermissionResults() {
        let collArr = [...self.organizationPermissionsAry];
        if (searchTerm) {
          collArr = collArr.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        collArr.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        self.collection = Organizations.createOrganizations(collArr);
        self.collection.searchTerm = searchTerm;
        self.collection.loaded = true;
        self.canLoad = true;
        self.load();
      }
      this.canLoad = false;
      if (this.editedUserId && this.isInitDataLoaded === false || this.isShowAllowedEntities === true && this.isInitDataLoaded === true) {
        this.isInitDataLoaded = true;
        this.needsToUpdatePermissionsObj = true;
        if (this.isPermissionDataLoaded === true) {
          displayPermissionResults();
        } else {
          const loadPermissionsDoneHandler = permissionsModel => {
            this.permissions = permissionsModel.permissions;
            const userEntities = this.permissions.entities;
            this.organizationPermissionsAry = [];
            this.agencyPermissionsAry = [];
            this.advertiserPermissionsAry = [];
            if (userEntities) {
              this.organizationPermissionsAry = userEntities.organization !== "" ? userEntities.organization.access : [];
              this.organizationPermissionsAry = Array.isArray(this.organizationPermissionsAry) ? this.organizationPermissionsAry : [this.organizationPermissionsAry];
              this.agencyPermissionsAry = userEntities.agency !== "" ? userEntities.agency.access : [];
              this.agencyPermissionsAry = Array.isArray(this.agencyPermissionsAry) ? this.agencyPermissionsAry : [this.agencyPermissionsAry];
              this.advertiserPermissionsAry = userEntities.advertiser !== "" ? userEntities.advertiser.access : [];
              this.advertiserPermissionsAry = Array.isArray(this.advertiserPermissionsAry) ? this.advertiserPermissionsAry : [this.advertiserPermissionsAry];
            }
            this.isPermissionDataLoaded = true;
            if (this.loadOrgsAfterPermissions) {
              this.loadOrgsAfterPermissions = false;
              this.loadOrgs();
            } else {
              displayPermissionResults();
            }
          };
          this.userPermissionsObj = UserPermissions.createPermissions();
          this.userPermissionsObj.loadPermissions({
            id: this.editedUserId
          }).then(loadPermissionsDoneHandler);
        }
      } else {
        if (!this.switchedFromGlobalAdmin || this.switchedFromGlobalAdmin && this.isPermissionDataLoaded) {
          this.switchedFromGlobalAdmin = false;
          this.loadOrgs();
        } else {
          this.loadOrgsAfterPermissions = true;
        }
      }
    },
    loadOrgs() {
      const self = this;
      const permissionObj = this.permissionObj || {};
      const sessionUserEntitiesObj = this.sessionUserEntitiesObj;
      function onSuccess() {
        self.canLoad = true;
        if (permissionObj) {
          for (const model of coll.models) {
            const id = model.id;
            const orgID = `org${id}`;
            if (!permissionObj[orgID]) {
              permissionObj[orgID] = {
                entityType: "org",
                entityID: id,
                state: "unchecked"
              };
            }
            permissionObj[orgID].disabled = sessionUserEntitiesObj.org[id] && sessionUserEntitiesObj.org[id].value === "0";
          }
          for (const model of Object.values(sessionUserEntitiesObj.ag)) {
            const id = model.id;
            const agID = `ag${id}`;
            if (!permissionObj[agID]) {
              permissionObj[agID] = {
                entityType: "ag",
                entityID: id,
                parentType: "org",
                parentID: model.organization_id,
                state: "unchecked"
              };
            }
            permissionObj[agID].disabled = sessionUserEntitiesObj.ag[id] && sessionUserEntitiesObj.ag[id].value === "0";
          }
          for (const model of Object.values(sessionUserEntitiesObj.adv)) {
            const id = model.id;
            const advID = `adv${id}`;
            if (!permissionObj[advID]) {
              permissionObj[advID] = {
                entityType: "adv",
                entityID: id,
                parentType: "ag",
                parentID: model.agency_id,
                state: "unchecked"
              };
            }
            permissionObj[advID].disabled = false;
          }
        }
        self.permissionObj = permissionObj;
        self.load();
      }
      const coll = this.collection = Organizations.createOrganizations();
      coll.canCache = true;
      coll.isPaginated = false;
      coll.searchTerm = this.searchTerm;
      coll.fetchOptions = {
        full: "*",
        sort_by: "name"
      };
      coll.filterBy(this.field, this.term, onSuccess);
    },
    handleAllChecked(evt) {
      const $target = $(evt.target);
      let isChecked = $target.attr("checked") === "checked";
      const permissionObj = this.permissionObj;
      const $checkboxes = this.$(".content .checkbox").not(".partial");
      const $checkboxesPartial = this.$(".content .checkbox.partial");
      if ($target.attr("state") === "checked" && isChecked) {
        isChecked = false;
        $target.removeAttr("checked").attr("state", "unchecked");
      }
      const chk = isChecked ? "checked" : "unchecked";
      $checkboxes.attr({
        checked: isChecked,
        state: chk
      });
      if (chk === "checked") {
        $checkboxesPartial.attr({
          state: "partial"
        });
        _.each($checkboxesPartial, function (ele) {
          $(ele)[0].state = "partial";
        });
      } else {
        $checkboxesPartial.attr({
          state: "unchecked"
        });
      }
      for (const item of Object.values(permissionObj)) {
        if (item.disabled) {
          item.state = chk === "checked" ? "partial" : "unchecked";
        } else {
          item.state = chk;
        }
      }
      this.updateList(chk);
    },
    updateList(chk) {
      const checked = chk === "checked";
      for (const item of this.list) {
        item.state = chk;
        item.checked = checked;
      }
    },
    getParentState(entityObjs, getCheckedCount) {
      let total;
      let checkedCount = 0;
      let partialCount = 0;
      let uncheckedCount = 0;
      let disabledPartial = 0;
      let parentState = "partial";
      if (this.isShowAllowedEntities && entityObjs[0].entityType === "org") {
        total = _.size(this.origPermissionOrgs);
        entityObjs = this.origPermissionOrgs;
      } else {
        total = _.size(entityObjs);
      }
      for (const item of Object.values(entityObjs)) {
        switch (item.state) {
          case "checked":
            checkedCount++;
            break;
          case "partial":
            partialCount++;
            if (item.disabled) {
              disabledPartial++;
            }
            break;
          default:
            uncheckedCount++;
        }
      }
      if (disabledPartial > 0) {
        checkedCount += disabledPartial;
        partialCount -= disabledPartial;
      }
      if (getCheckedCount) {
        return {
          checkedCount: checkedCount,
          partialCount: partialCount,
          uncheckedCount: uncheckedCount
        };
      }
      if (total === checkedCount) {
        parentState = "checked";
      } else if (total === uncheckedCount) {
        parentState = "unchecked";
      }
      return parentState;
    },
    updateHeaderCheckbox(updateNow) {
      let state;
      const $headerCheckbox = this.$(".headers .checkbox");
      const permissionObj = this.permissionObj;
      const permissionOrgs = _.filter(permissionObj, obj => obj.entityType === "org");
      const permissionOrgsLen = _.size(permissionOrgs);
      const coll = this.collection;
      const noEdit = this.selfEditNotPermitted || coll && coll.searchTerm ? " disabled=true" : "";
      if (this.isShowAllowedEntities) {
        state = permissionOrgs.length ? this.getParentState(permissionOrgs) : "unchecked";
      } else {
        if (coll && coll.loaded) {
          const orgsCheckedCount = this.getParentState(permissionOrgs, true);
          const checkedCount = orgsCheckedCount.checkedCount;
          if (permissionOrgsLen === 0 || permissionOrgsLen === orgsCheckedCount.uncheckedCount) {
            state = "unchecked";
          } else if (checkedCount > 0 && coll.length === checkedCount) {
            state = "checked";
          } else {
            state = "partial";
          }
        }
      }
      if (updateNow) {
        $headerCheckbox.attr("state", state);
        $headerCheckbox[0].state = state;
      } else {
        this.headers[0].label = `\n          <strand-checkbox class="checkbox all" data-bind="has_assigned_entities" state="${state}"${noEdit}>\n          </strand-checkbox>\n        `;
      }
    },
    uncheckHeaderCheckbox() {
      const $headerCheckbox = this.$(".headers .checkbox");
      $headerCheckbox.attr("state", "unchecked");
      $headerCheckbox[0].state = "unchecked";
    },
    toggle(evt, fromAllChecked) {
      const self = this;
      const $el = this.el;
      const {
        getParentType: getParentType,
        sessionUserEntitiesObj: sessionUserEntitiesObj
      } = this;
      const $item = $(evt.currentTarget);
      const $target = $(evt.target);
      const $arrow = $item.find(".arrow");
      const $iconSpinner = $arrow.find(".icon-spinner");
      const entityID = $item.attr("data-id");
      const entityType = $item.attr("data-type");
      const parentID = $item.attr("data-parent-id");
      let indent = $arrow.length ? parseInt($arrow.css("marginLeft").replace(/[^-\d.]/g, ""), 10) : 0;
      const $childrenContainer = $item.closest(".item-wrapper").children(".children");
      function checkHandler(permObj) {
        const checkedOrState = function ($elem, obj) {
          const state = obj.state;
          if (state === "partial") {
            $elem.attr("state", "partial");
            $elem[0].state = state;
          } else {
            _.each($elem, function (ele) {
              const $ele = $(ele);
              if ($ele.hasClass("partial")) {
                const newState = state === "checked" ? obj.disabled ? "partial" : "checked" : "unchecked";
                $ele.attr({
                  state: newState,
                  checked: false
                });
                $ele[0].state = newState;
              } else {
                $ele.attr({
                  state: state,
                  checked: state === "checked"
                });
              }
            });
          }
          if (obj.disabled && obj.state === "checked") {
            obj.state = "partial";
          }
        };
        if (permObj.parentID) {
          let orgPerm;
          const parentPerm = self.getPermissionItem(permObj.parentType + permObj.parentID);
          self.permissionObj[permObj.entityType + permObj.entityID] = permObj;
          if (permObj.entityType === "adv") {
            self.updateDataModel(permObj);
            orgPerm = self.permissionObj[`org${parentPerm.parentID}`];
            checkedOrState($el.find(`#ag-${permObj.parentID} .checkbox`), parentPerm);
            checkedOrState($el.find(`#org-${parentPerm.parentID} .checkbox`), orgPerm);
          } else if (entityType === "ag") {
            self.updateDataModel(permObj);
            orgPerm = self.permissionObj[`org${permObj.parentID}`];
            checkedOrState($el.find(`#org-${permObj.parentID} .checkbox`), orgPerm);
            checkedOrState($childrenContainer.find(".checkbox"), permObj);
          }
        } else if (permObj.entityType === "org") {
          self.updateDataModel(permObj);
          checkedOrState($childrenContainer.find(".checkbox"), permObj);
        }
        if (!fromAllChecked) {
          self.updateHeaderCheckbox(true);
        }
      }
      function getChildData() {
        let targetCollection;
        function onSuccess() {
          $iconSpinner.spin(false);
          $arrow.removeClass("loading hide");
          if ($childrenContainer.is(":visible")) {
            addChildren(targetCollection, $childrenContainer);
          } else {
            if ($arrow.hasClass("expanded")) {
              $childrenContainer.show();
            }
          }
          self.updateScrollBar();
        }
        if ($arrow.hasClass("expanded")) {
          $iconSpinner.spin(false);
          $arrow.removeClass("expanded loading");
          $childrenContainer.hide();
          self.updateScrollBar();
        } else {
          $arrow.addClass("loading expanded hide");
          Spinner.apply($iconSpinner, self.spinnerOpts);
          switch (entityType) {
            case "org":
              self.collectionAg.urlFilter.set({
                entity: "organization",
                id: entityID
              });
              targetCollection = self.collectionAg;
              break;
            case "ag":
              self.collectionAdv.urlFilter.set({
                entity: "agency",
                id: entityID
              });
              targetCollection = self.collectionAdv;
              break;
            default:
              return;
          }
          targetCollection.canCache = true;
          targetCollection.isPaginated = false;
          targetCollection.fetchOptions = {
            full: "*",
            sort_by: "name"
          };
          targetCollection.fetch({
            success: onSuccess.bind(self)
          });
        }
      }
      function addChildren(parentCollection, $container) {
        let entityAdding;
        const permissionObj = self.permissionObj;
        const parentState = permissionObj[entityType + entityID].state;
        self.entityAdding = entityAdding = entityType === "org" ? "ag" : "adv";
        const addingAg = self.entityAdding === "ag";
        const advSpacing = entityAdding === "adv" ? entitySpacing : 0;
        for (const model of parentCollection.models) {
          const id = model.id;
          const objID = entityAdding + id;
          if (!permissionObj[objID]) {
            permissionObj[objID] = {
              entityType: entityAdding,
              entityID: id,
              parentType: entityType,
              parentID: entityID,
              disabled: addingAg ? sessionUserEntitiesObj.ag[id] && sessionUserEntitiesObj.ag[id].value === "0" : false,
              state: parentState === "partial" ? "unchecked" : parentState
            };
          }
        }
        const serializedData = self.serialize(parentCollection);
        self.renderPartialTemplate({
          action: "html",
          data: serializedData,
          targetEl: $container,
          template: self.partials.gridSubItem,
          useTemplateHtml: false
        });
        if (entityType === "ag") {
          indent = agencyIndent;
        }
        const marginSetting = `${-(indent + margin) + advSpacing}px 0 ${indent + margin}px`;
        $container.find(".checkbox-container").css("margin", `-1px ${marginSetting}`);
        $container.find(".entity").css("margin", `1px ${marginSetting}`);
      }
      if ($target.hasClass("checkbox")) {
        const isChecked = $target.hasClass("partial") ? $target.attr("state") !== "unchecked" : $target.attr("checked") === "checked";
        evt.preventDefault();
        evt.stopPropagation();
        let permObj = this.getPermissionItem(entityType + entityID);
        if (!permObj.entityID) {
          permObj = {
            entityType: entityType,
            entityID: entityID,
            parentType: getParentType(entityType),
            parentID: parentID
          };
        }
        permObj.state = isChecked ? "checked" : "unchecked";
        if (permObj.state === "unchecked") {
          $target.attr("checked", false);
        }
        checkHandler(permObj);
        return;
      }
      if (entityType === "adv") {
        return;
      }
      if (!this.permissionObj[entityType + entityID]) {
        this.permissionObj[entityType + entityID] = {
          entityType: entityType,
          entityID: entityID,
          parentType: getParentType(entityType),
          parentID: parentID
        };
      }
      getChildData();
    },
    partialClick(evt) {
      const $target = $(evt.target);
      $target.attr("state", $target.attr("state") === "unchecked" ? "partial" : "unchecked");
    },
    setParentState(parentObj, nextState) {
      if (nextState === "checked") {
        nextState = "partial";
      }
      parentObj.state = nextState;
    },
    updateDataModel(args) {
      let filteredEntities;
      const {
        entityID: entityID,
        entityType: entityType,
        parentID: parentID,
        parentType: parentType,
        state: state
      } = args;
      const permissionObj = this.permissionObj;
      const permissionOrgs = {};
      const permissionAgs = {};
      const permissionAdvs = {};
      if (state === "partial") {
        return;
      }
      for (const item of Object.values(permissionObj)) {
        switch (item.entityType) {
          case "org":
            permissionOrgs[item.entityID] = item;
            break;
          case "ag":
            permissionAgs[item.entityID] = item;
            break;
          case "adv":
            permissionAdvs[item.entityID] = item;
            break;
        }
      }
      if (entityID) {
        permissionObj[entityType + entityID] = args;
        if (parentID) {
          if (entityType === "ag") {
            filteredEntities = _.filter(permissionAgs, ag => ag.parentID === parentID);
            this.setParentState(permissionObj[parentType + parentID], this.getParentState(filteredEntities));
            for (const adv of Object.values(permissionAdvs)) {
              if (adv.parentID === entityID) {
                adv.state = state;
              }
            }
          } else if (entityType === "adv") {
            filteredEntities = _.filter(permissionAdvs, adv => adv.parentID === parentID);
            this.setParentState(permissionObj[parentType + parentID], this.getParentState(filteredEntities));
            const orgID = permissionObj[parentType + parentID].parentID;
            filteredEntities = _.filter(permissionAgs, ag => ag.parentID === orgID);
            this.setParentState(permissionObj[`org${orgID}`], this.getParentState(filteredEntities));
          }
        } else {
          for (const ag of Object.values(permissionAgs)) {
            if (ag.parentID === entityID) {
              ag.state = state === "checked" ? ag.disabled ? "partial" : "checked" : "unchecked";
              const agID = ag.entityID;
              for (const adv of Object.values(permissionAdvs)) {
                if (adv.parentID === agID) {
                  adv.state = state;
                }
              }
            }
          }
        }
      }
    },
    unload() {
      let coll = this.collection;
      if (coll) {
        const searchFieldVal = coll.searchTerm;
        coll.searchTerm = null;
        if (searchFieldVal && searchFieldVal !== "") {
          coll.applySearchFilter(this.field || "multiField", "");
        }
        coll = null;
      }
      this.userPermissionsObj = null;
      this.permissionObj = null;
      T1.EventHub.unsubscribe("user:savedSuccessful");
    },
    canUnload() {
      return false;
    },
    load() {
      if (this.canLoad) {
        const renderDoneHandler = () => {
          this.el.trigger("toggleShowAllowed", [!this.createEditView.loadedAsGlobalAdmin, this.reloading]);
          this.reloading = false;
        };
        if (this.needsToUpdatePermissionsObj) {
          this.needsToUpdatePermissionsObj = false;
          this.preLoadPermissionModel();
        }
        this.updateHeaderCheckbox();
        this.render().then(renderDoneHandler);
      } else {
        const renderDoneHandler = () => {
          this.uncheckHeaderCheckbox();
          this.showLoader();
        };
        if (this.reloading && this.collection) {
          this.collection.reset();
          this.collection = null;
        }
        this.userPermissionsObj = null;
        this.render().then(renderDoneHandler);
        setTimeout(() => {
          this.getData();
        }, renderDelay);
      }
    },
    reload() {
      this.permissionObj = null;
      this.isInitDataLoaded = false;
      this.isPermissionDataLoaded = false;
      this.isShowAllowedEntities = true;
      this.canLoad = false;
      this.reloading = true;
      this.el.trigger("toggleShowAllowed", [false]);
      this.load();
    },
    getPermissionItem(id) {
      return this.permissionObj && this.permissionObj[id] || {
        state: "unchecked"
      };
    },
    getParentType(type) {
      return type === "ag" ? "org" : type === "adv" ? "ag" : "";
    },
    serialize(inputCollection) {
      const coll = inputCollection || this.collection;
      const models = coll && coll.toJSON() || [];
      const list = [];
      const typeHash = {
        ORGANIZATION: "org",
        AGENCY: "ag",
        ADVERTISER: "adv"
      };
      const searchedTerm = this.collection ? this.collection.searchTerm : null;
      const highlightText = T1.Utils.highlightText;
      const getPermissionItem = this.getPermissionItem.bind(this);
      const collEntityType = this.collection ? this.collection.entityType : null;
      const isSelfEdit = this.selfEditNotPermitted;
      const escapeHTML = T1.Utils.escapeHTML;
      function processData(data, entType) {
        const id = data.id;
        const item = getPermissionItem(entType + id);
        const partial = item.disabled;
        const state = partial && item.state === "checked" ? "partial" : item.state;
        const isSearchResult = collEntityType ? searchedTerm && entType === typeHash[collEntityType] : Boolean(searchedTerm);
        const gridName = escapeHTML(data.name, true);
        return {
          checked: state === "checked",
          child_count: entType !== "adv",
          entity: isSearchResult ? highlightText(gridName, AdminUtils.htmlDecode(searchedTerm)) : gridName,
          entityType: entType,
          id: id,
          is_active: true,
          isSelfEdit: isSelfEdit,
          parentID: data.type ? data.type === "agency" ? data.organization_id : data.agency_id : "",
          partial: partial,
          state: state
        };
      }
      if (models.length) {
        const entityType = models[0].type || "organization";
        const entType = typeHash[entityType.toUpperCase()];
        for (const model of models) {
          list.push(processData(model, entType));
        }
      }
      this.entityAdding = "org";
      this.list = list;
      return {
        list: list,
        hideNoResultMessage: !this.canLoad && list.length === 0
      };
    }
  });
  return T1GridView(permissionsGridView);
});
