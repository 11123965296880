define('modules/campaign/views/budgetFlights',["Underscore", "jQuery", "T1", "moment", "T1View", "utils/CampaignStrategyUtils", "text!modules/campaign/templates/budgetFlights.html", "modules/campaign/views/helpers/budgetFlightsShared", "collections/organizations", "models/userPreferences", "utils/CampaignStrategyUtils", "T1Tooltip", "T1DatePicker"], function (_, $, T1, moment, T1View, CampaignStrategyUtils, template, BudgetFlightsSharedHelper, Organizations, UserPreferences, Util) {
  "use strict";

  var getCurrencyList = T1.Utils.getCurrencyList;
  var formatsRolledUpNumbersNoAbbreviation = Util.formatsRolledUpNumbersNoAbbreviation;
  const BudgetFlightView = T1View.extend({
    template: template,
    startBuffer: 7,
    endBuffer: 30,
    startBufferMin: {
      minutes: 10
    },
    isDataReady: false,
    showCurrentFlights: false,
    isProgrammaticGuaranteed: false,
    zoneName: "",
    customMsg: "",
    initialize: function ({
      campaign: campaign,
      canSetCurrency: canSetCurrency,
      hasStarted: hasStarted,
      zoneName: zoneName,
      timeZones: timeZones
    }) {
      var signature = T1.Signature.create();
      this.model = campaign;
      this.currencyCode = this.model.get("currency_code");
      this.canSetCurrency = canSetCurrency;
      this.hasStarted = hasStarted;
      this.zoneName = zoneName;
      this.tempZoneName = this.zoneName;
      this.timeZones = timeZones;
      this.timeZoneList = this.timeZones.getTimeZones({
        selected: this.zoneName
      });
      signature.set(new Map([["root", "ui"], ["module", "campaign"], ["view", "budgetFlights"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["zoneName", "showCurrentFlights"]);
      this.initializeCollectionHelper();
    },
    checkBudgetFlightActions: function () {
      var collection = this.collection.toJSON();
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].action === undefined) {
          this.markModelAsUpdate(collection[i].id);
        }
      }
    },
    initializeCollectionHelper: function () {
      var self = this;
      const isCurrencyReady = this.getCurrencyCode();
      this.collection = this.model.get("budget_flight");
      this.collection.id = this.model.id;
      if (this.collection.id === undefined && isCurrencyReady === undefined) {
        let currencyCode;
        const orgModel = Organizations.getOrganizations().selectedOrgModel;
        if (orgModel && orgModel.get("currency_code")) {
          currencyCode = orgModel.get("currency_code");
          this.model.set({
            currency_code: currencyCode
          });
          this.currencyCode = currencyCode;
          this.sharedHelper = new BudgetFlightsSharedHelper(this.collection, function () {
            return self.currencyCode;
          });
          this.isDataReady = true;
          this.load();
        } else {
          Organizations.getOrganizations().fetchSelectedOrg(function (orgsModel) {
            currencyCode = orgsModel.get("currency_code");
            self.model.set({
              currency_code: currencyCode
            });
            self.currencyCode = currencyCode;
            self.sharedHelper = new BudgetFlightsSharedHelper(self.collection, function () {
              return self.currencyCode;
            });
            self.isDataReady = true;
            self.load();
          });
        }
      } else {
        this.sharedHelper = new BudgetFlightsSharedHelper(this.collection, function () {
          return self.getCurrencyCode();
        });
        this.isDataReady = true;
      }
    },
    events: {
      "click .budget-flights .col-add-cmd span": "addItem",
      "click .showCurrentFlights .show-currentandfuture-only": "showCurrentAndUpcomingOnlyHandler",
      "click .budget-flights .remove": "removeItem",
      "change .budget-flights .col-amount input": "onBudgetAmountChange",
      "click .budget-flights .col-chart-icon": "chartIconClickHandler",
      "change .budget-flights .col-imp-amount input": "onImpAmountChange"
    },
    dataEvents: {
      model: {
        "change:is_programmatic_guaranteed": function (model) {
          this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(model);
          this.collection.reset();
          this.doRenderView();
        }
      }
    },
    onBudgetAmountChange: function (e) {
      var itemId = $(e.currentTarget).closest(".budget-flight-row").data("itemid");
      this.updateCollectionWithBudgetAmounts();
      this.markModelAsUpdate(itemId);
      T1.EventHub.publish("budgetFlight:budgetChange");
    },
    onImpAmountChange: function (e) {
      var itemId = $(e.currentTarget).closest(".budget-flight-row").data("itemid");
      var budgetHold = $(e.currentTarget).closest(".budget-flights");
      $(e.currentTarget).removeAttr("original-title");
      budgetHold.removeAttr("original-title");
      this.updateCollectionWithImpAmounts();
      this.markModelAsUpdate(itemId);
      T1.EventHub.publish("budgetFlight:budgetChange");
    },
    markModelAsUpdate: function (itemId) {
      const model = this.getModelById(String(itemId));
      if (model && model.id) {
        model.set({
          action: "update"
        }, {
          silent: true
        });
      }
    },
    setFlightReportEndDate: function (model) {
      var T1Date = T1.Date;
      var endDate = model.get("end_date");
      var yesterday = T1Date.parse("yesterday");
      if (T1Date.compare(yesterday, T1Date.parse(endDate)) < 0) {
        endDate = yesterday;
      }
      return endDate;
    },
    chartIconClickHandler: function (e) {
      var itemId = $(e.currentTarget).closest(".budget-flight-row").data("itemid");
      const model = this.getModelById(itemId);
      if (model) {
        const flightReportEndDate = this.setFlightReportEndDate(model);
        const url = `#reports/campaigns/performance/${this.model.id}`;
        const urlLaunchOption = {
          viewOptions: {
            focus: "all",
            start_date: model.get("start_date"),
            end_date: flightReportEndDate
          }
        };
        T1.Location.set(url, urlLaunchOption);
      }
    },
    validateBudgetFlightData: function (isNewCampaign) {
      let validationErr;
      var self = this;
      const $flightsHolder = this.$(".budget-flights");
      function displayError(errors) {
        _.each(errors, function (err) {
          const $targetRow = $flightsHolder.find(`[data-itemid=${err.itemId}]`);
          self.showErrors(undefined, [err], $targetRow.first(), true);
        });
        self.activateInputMasks(self.$(".budget-flights"));
      }
      this.checkBudgetFlightActions();
      validationErr = this.overLappingValidation();
      if (validationErr && validationErr.length) {
        displayError(validationErr);
        return false;
      }
      validationErr = this.collection.validateData();
      if (validationErr && validationErr.length) {
        displayError(validationErr);
        return false;
      }
      this.dataToSubmit = this.collection.buildPostObj(this.zoneName, isNewCampaign);
      return true;
    },
    emptyImpBudgetCheck: function (isNewCampaign) {
      var self = this;
      var isEmpty = false;
      var budgetFlights = this.model.get("budget_flight").models;
      const $flightRows = this.$(".budget-flights .budget-flight-row");
      var valuesToCheck = false;
      $.each($flightRows, function (index, row) {
        const impInputObj = $(row).find(".col-imp-amount input");
        if (impInputObj.val()) {
          valuesToCheck = true;
          return false;
        }
      });
      if (valuesToCheck) {
        $.each(budgetFlights, function (index, element) {
          if (isNewCampaign && index === 0) {
            if (!element.get("total_impression_budget")) {
              isEmpty = true;
            } else {
              self.model.set({
                total_impression_budget: element.get("total_impression_budget")
              }, {
                silent: true
              });
            }
          } else if (!isNewCampaign && element.get("is_relevant") === "1" && element.get("action") !== "delete") {
            if (!element.get("total_impression_budget")) {
              isEmpty = true;
            } else {
              self.model.set({
                total_impression_budget: element.get("total_impression_budget")
              }, {
                silent: true
              });
            }
          }
        });
      } else {
        isEmpty = true;
      }
      return isEmpty;
    },
    saveBudgetFlights: function (campaignId) {
      var deferred = $.Deferred();
      this.collection.saveBudgetFlights(campaignId, this.dataToSubmit, function () {
        deferred.resolve();
      }, function () {
        deferred.reject({
          isConflict: true
        });
      }, function (coll, errObj) {
        deferred.reject({
          isConflict: false,
          errObj: errObj
        });
      }, function (coll) {
        deferred.reject({
          data: coll
        });
      });
      return deferred.promise();
    },
    showCurrentAndUpcomingOnlyHandler: function () {
      const checkObj = this.$(".showCurrentFlights .show-currentandfuture-only");
      var showCurrentOnly = checkObj.is(":checked");
      if (this.showCurrentFlights !== showCurrentOnly) {
        this.showCurrentFlights = showCurrentOnly;
        this.preferences.save();
        this.doRenderView();
      }
    },
    removeItem: function (event) {
      var itemId = $(event.currentTarget).closest(".budget-flight-row").data("itemid");
      if (itemId) {
        itemId = String(itemId);
        const targetModel = this.getModelById(itemId);
        if (itemId.indexOf("c") === 0) {
          this.collection.remove(targetModel, {
            silent: true
          });
        } else {
          targetModel.set({
            action: "delete"
          }, {
            silent: true
          });
        }
        this.doRenderView().then(() => {
          this.isFormElementsChanged = true;
        });
      }
    },
    hasBudgetFlightsChanged: function () {
      return this.isFormElementsChanged;
    },
    getInitialValues: function () {
      var model = this.collection.at(0);
      return {
        start_date: model.get("start_date"),
        end_date: model.get("end_date"),
        total_budget: model.get("total_budget"),
        total_impression_budget: model.get("total_impression_budget")
      };
    },
    addItem: function (event) {
      let dateToStart;
      const {
        collection: collection,
        budgetItemList: itemList
      } = this;
      var currentRow = $(event.currentTarget).closest(".budget-flight-row");
      var itemId = currentRow.data("itemid");
      if (itemId === "specialMsgRow") {
        currentRow.remove();
        if (itemList && itemList.length) {
          itemList.pop();
        }
      }
      if (itemList && itemList.length) {
        const lastItem = itemList[itemList.length - 1];
        if (lastItem.budget_state !== "ended") {
          dateToStart = this.replicateDatePart(lastItem.endDateObj);
          dateToStart.addDays(1);
        }
      }
      collection.add(this.createModel(dateToStart), {
        silent: true
      });
      this.doRenderView().then(() => {
        this.isFormElementsChanged = true;
      });
    },
    load: function () {
      var self = this;
      var dateNow = new Date();
      this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(this.model);
      if (this.isDataReady) {
        const dtNowUTC = new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
        this.timeZones.convertTimeByZone({
          time: dtNowUTC.toString("yyyy-MM-ddTHH:mm:ss"),
          from_zone: "Etc/GMT",
          to_zone: this.zoneName
        }, function (resultObj) {
          const dtNowUserZone = Date.parse(resultObj.times.current_time.local);
          self.sharedHelper.setCurrentDateOfUserTimeZone(dtNowUserZone);
          self.doRenderView().then(function () {
            self.isFormElementsChanged = false;
          });
        });
      }
    },
    unload: function () {
      this.removeErrorTooltip();
      this.currencyCode = null;
    },
    onDontSave: function () {
      this.resetTimezone(this.timeZoneList, this.tempZoneName);
      this.zoneName = this.tempZoneName;
    },
    resetTimezone: function (timeZoneList, zoneName) {
      _.each(timeZoneList, function (item) {
        delete item.selected;
        if (zoneName === item.zone_name) {
          item.selected = true;
        }
      });
    },
    updateCollectionWithBudgetAmounts: function () {
      var self = this;
      const $flightRows = this.$(".budget-flights .budget-flight-row");
      var max = COMPASS_ENV.CURRENCY_MAX;
      $.each($flightRows, function (index, element) {
        const itemId = $(element).data("itemid");
        const inputObj = $(element).find(".col-amount input");
        if (inputObj.length) {
          let amountVal = T1.Form.Masks.revert("currency", inputObj.first());
          if (isNaN(amountVal) !== true) {
            if (amountVal > max) {
              amountVal = max;
            }
          }
          if (amountVal === undefined || amountVal === null) {
            amountVal = "";
          } else {
            amountVal = amountVal.toString();
          }
          const targetModel = self.getModelById(itemId);
          if (targetModel) {
            targetModel.set({
              total_budget: amountVal
            }, {
              silent: true
            });
          }
        }
      });
    },
    updateCollectionWithImpAmounts: function () {
      var self = this;
      const $flightRows = this.$(".budget-flights .budget-flight-row");
      $.each($flightRows, function (index, element) {
        const itemId = $(element).data("itemid");
        const inputImpObj = $(element).find(".col-imp-amount input");
        if (inputImpObj.length) {
          let amountVal = T1.Form.Masks.revert("currency", inputImpObj.first());
          let amountValFormat;
          if (amountVal === 0) {
            amountValFormat = 0;
          } else {
            amountValFormat = amountVal ? $.trim(formatsRolledUpNumbersNoAbbreviation(amountVal)) : "";
          }
          inputImpObj.val(amountValFormat);
          if (amountVal === undefined || amountVal === null) {
            amountVal = "";
          } else {
            amountVal = amountVal.toString();
          }
          const targetModel = self.getModelById(itemId);
          if (targetModel) {
            targetModel.set({
              total_impression_budget: amountVal
            }, {
              silent: true
            });
          }
        }
      });
    },
    getModelById: function (itemId) {
      var collection = this.collection;
      let targetModel = collection.get(itemId);
      if (!targetModel) {
        targetModel = collection.getByCid(itemId);
      }
      return targetModel;
    },
    doRenderView: function () {
      var self = this;
      var deferred = $.Deferred();
      this.updateCollectionWithBudgetAmounts();
      this.render().then(() => {
        const $elTitle = this.$(".w-head .title");
        const $elChartIcon = this.$(".w-body .col-chart-icon");
        const delay = 50;
        this.activateInputMasks(this.$(".budget-flights"));
        T1.Selectbox(this.$(".col-currency .select"), {
          enableDirectionAuto: true,
          searchFieldDisabled: false,
          searchInputPlaceHolder: "Search currencies",
          onChange: function (e) {
            self.currencySelectChangeHandler(e);
          }
        });
        T1.Selectbox(this.$(".w-dropdown-section .col-dates .select"), {
          searchFieldDisabled: false,
          onChange: function (e) {
            var selectedZoneId = parseInt($(e.currentTarget).val(), 10);
            var timeZone = self.timeZones.getByZoneId(selectedZoneId);
            self.tempZoneName = self.zoneName;
            self.zoneName = timeZone ? timeZone.get("zone_name") : "";
            T1.EventHub.publish("timeZoneName:change", self.zoneName);
            _.each(self.collection.models, function (model) {
              const action = model.get("action");
              if (model.id && action !== "delete" && action !== "new") {
                model.set({
                  action: "update"
                }, {
                  silent: true
                });
              }
            });
            _.each(self.timeZoneList, function (item) {
              delete item.selected;
              if (self.zoneName === item.zone_name) {
                item.selected = true;
              }
            });
          }
        });
        T1.Tooltip($elTitle, {
          offset: 5,
          delayIn: "100",
          delayOut: "100"
        });
        T1.Tooltip($elChartIcon, {
          offset: 5,
          delayIn: "100",
          delayOut: "100",
          className: "budgetflight-chart-icon-tooltip"
        });
        setTimeout(() => {
          this.prepareDatePicker();
        }, delay);
        deferred.resolve();
      });
      return deferred.promise();
    },
    removeErrorTooltip: function () {
      this.removeTooltipOnElement(this.$(".w-body .error"));
    },
    hasBudgetFlightStarted: function (itemId) {
      if (itemId) {
        const model = this.getModelById(itemId);
        if (model) {
          return CampaignStrategyUtils.hasEntityStarted(model);
        }
      }
      return false;
    },
    prepareDatePicker: function () {
      var self = this;
      var dtToday = new Date();
      const defaultStartDate = this.replicateDatePart(dtToday);
      const defaultEndDate = this.replicateDatePart(dtToday);
      defaultEndDate.addDays(this.endBuffer);
      this.datePicker = T1.DoubleDatePicker({
        supportMultipleFields: true,
        keepUserDatesOnValidationError: true,
        chooseTime: true,
        chooseTimezone: false,
        defaultStart: defaultStartDate.toString("s"),
        defaultEnd: defaultEndDate.toString("s"),
        useDefaultStart: true,
        useDefaultEnd: true,
        closeOnScroll: false,
        useStartDefaultCheckInput: false,
        useEndDefaultCheckInput: false,
        zoneName: () => this.zoneName,
        positionBy: "bottomTop",
        topOffset: 25,
        lowerBounds: function () {
          var itemId = this.contextEl ? this.contextEl.data("itemid") : undefined;
          if (self.hasBudgetFlightStarted(itemId) === true) {
            const model = self.getModelById(itemId);
            return Date.parse(model.get("start_date"));
          }
          return dtToday;
        },
        canClose: () => true,
        onOpen: function () {
          const topIndex = 9999;
          $(".cluetip-datepicker").css("z-index", topIndex);
          self.removeErrorTooltip();
        },
        startDisabled: function () {
          var itemId = this.contextEl ? this.contextEl.data("itemid") : undefined;
          return self.hasBudgetFlightStarted(itemId);
        },
        startTimeEditable: function () {
          var itemId = this.contextEl ? this.contextEl.data("itemid") : undefined;
          return !self.hasBudgetFlightStarted(itemId);
        },
        onSave: function ({
          data: data,
          contextEl: contextEl,
          error: error,
          success: success
        }) {
          const saveValues = data.saveValues;
          var newStartDate = Date.parse(saveValues.start.date);
          var newEndDate = Date.parse(saveValues.end.date);
          const itemId = contextEl.data("itemid");
          self.validateStartDate(newStartDate, itemId).then(function (result) {
            if (result.hasError === true) {
              error({
                message: result.errMessage
              });
            } else {
              if (T1.compareDates(newStartDate, newEndDate) >= 0) {
                error({
                  message: "End date must be later than the start date."
                });
              } else if (self.isNewDatesOverlapping(itemId, newStartDate, newEndDate) === true) {
                error({
                  message: self.customMsg || "Flight dates cannot overlap."
                });
              } else {
                success();
                const targetModel = self.getModelById(itemId);
                if (!self.hasBudgetFlightStarted(itemId)) {
                  targetModel.set({
                    start_date: newStartDate.toString("s")
                  }, {
                    silent: true
                  });
                }
                targetModel.set({
                  end_date: newEndDate.toString("s")
                }, {
                  silent: true
                });
                self.markModelAsUpdate(itemId);
                T1.EventHub.publish("budgetFlight:budgetChange");
                self.doRenderView().then(function () {
                  self.isFormElementsChanged = true;
                });
              }
            }
          });
        }
      }, this.el);
    },
    overLappingValidation: function () {
      const itemList = this.budgetItemList || this.serialize().budgetFlightItems;
      for (let i = 0; i < itemList.length; i++) {
        if (i > 0 && itemList[i].itemid && itemList[i].itemid !== "specialMsgRow") {
          const previousEndDate = moment(itemList[i - 1].endDateObj);
          const currentStartDate = moment(itemList[i].startDateObj);
          if (currentStartDate.isBefore(previousEndDate)) {
            return [{
              itemId: String(itemList[i].itemid),
              field: `budgetflight_dates_${itemList[i].itemid}`,
              message: "This budget flight overlaps the previous one."
            }];
          }
        }
      }
      return undefined;
    },
    isNewDatesOverlapping: function (itemId, newStartDate, newEndDate) {
      let dtStartExisting, idToMatch;
      const itemList = this.budgetItemList || this.serialize().budgetFlightItems;
      var length = itemList.length;
      var dtStartNew = moment(newStartDate);
      var dtEndNew = moment(newEndDate);
      itemId = String(itemId);
      for (let i = length - 1; i >= 0; i--) {
        idToMatch = String(itemList[i].itemid);
        if (idToMatch !== "specialMsgRow") {
          if (idToMatch !== itemId) {
            dtStartExisting = moment(itemList[i].startDateObj);
            if (dtStartNew.isAfter(dtStartExisting)) {
              const dtEndExisting = moment(itemList[i].endDateObj).add(1, "h").startOf("hour");
              const dateToMatch = moment(itemList[i].endDateObj).startOf("hour");
              if (dtStartNew.isBefore(dtEndExisting) && dtStartNew.isBefore(dateToMatch)) {
                this.customMsg = "Flight dates cannot overlap.";
                return true;
              } else if (dtStartNew.isBefore(dtEndExisting)) {
                this.customMsg = "Budget flight cannot start within the same hour in which the old budget flight ends";
                return true;
              }
              break;
            }
          }
        }
      }
      for (let i = 0; i < length; i++) {
        idToMatch = String(itemList[i].itemid);
        if (idToMatch !== "specialMsgRow") {
          if (idToMatch !== itemId) {
            dtStartExisting = moment(itemList[i].startDateObj).add(1, "h").startOf("hour");
            if (dtStartExisting.isAfter(dtStartNew)) {
              if (dtStartExisting.isBefore(dtEndNew)) {
                return true;
              }
              break;
            }
          }
        }
      }
      return false;
    },
    validateStartDate: function (newStartDate, itemId) {
      var deferred = $.Deferred();
      var result = {
        hasError: false,
        errMessage: ""
      };
      this.timeZones.validateStartDate(this.startBufferMin, newStartDate, this.zoneName, {
        onSuccess: function () {
          deferred.resolve(result);
        },
        onError: () => {
          result.hasError = true;
          result.errMessage = this.startDateError;
          deferred.resolve(result);
        },
        hasStarted: this.hasBudgetFlightStarted(itemId)
      });
      return deferred.promise();
    },
    currencySelectChangeHandler: function (e) {
      var currCode = $(e.currentTarget).val();
      this.currencyCode = currCode;
      T1.EventHub.publish("currencyCode:change", this.currencyCode);
      this.doRenderView().then(() => {
        this.isFormElementsChanged = true;
      });
    },
    createModel: function (startDt) {
      return this.sharedHelper.createModel(startDt);
    },
    replicateDatePart: function (dateObj) {
      return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    },
    serialize: function (today) {
      const showFlights = typeof this.showCurrentFlights === "string" ? this.showCurrentFlights === "true" : this.showCurrentFlights;
      const cpeioDetails = this.model.get("cpeio");
      const result = this.sharedHelper.serializeData(today, showFlights, this.canSetCurrency, undefined, cpeioDetails);
      this.budgetItemList = result.budgetFlightItems;
      result.currencyList = getCurrencyList({
        selectedId: this.getCurrencyCode()
      });
      result.timeZoneList = this.timeZoneList;
      result.isProgrammaticGuaranteed = this.isProgrammaticGuaranteed;
      result.showCPEIODetails = this.model.get("id") && this.model.get("showCPEIO");
      return result;
    }
  });
  return T1.Form.ViewUtils.extend(BudgetFlightView);
});
