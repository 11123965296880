define('models/t1asAsset',["jQuery", "Underscore", "T1", "T1Model", "T1Collection", "Backbone", "utils/CreativeUtils", "collections/vendors"], function ($, _, T1, T1Model, T1Collection, Backbone, CreativeUtils, Vendors) {
  T1.Models.T1asAsset = T1Model.extend({
    apiRoot: T1.API_ROOT,
    initialize: function () {},
    setResultData: function (resultData) {
      var self = this;
      var resultsDataParsed = T1Collection.prototype.parse.call(this, resultData);
      var primaries = [];
      var alternates = {};
      var unusables = [];
      _.each(resultsDataParsed, function (item) {
        var key = "";
        var fileRole = item.file_role;
        var fileType = item.file_type;
        var imageExt = ["gif", "jpg", "jpeg", "tif", "tiff", "png"];
        if (fileRole === "UNUSABLE") {
          unusables.push(item);
        } else {
          var width = item.width;
          var height = item.height;
          key = width + "x" + height;
          item.add_classifications = [];
          item.click_through_url = undefined;
          item.click_url = undefined;
          item.tpas_ad_tag = undefined;
          item.current_classifications = [];
          item.concept_name = "Default (same as 'Name')";
          item.concept_id = undefined;
          item.manualVendors = new Vendors();
          item.manual_vendors = CreativeUtils.parseVendorListForDisplay(item.manualVendors);
          item.remove_classifications = [];
          item.selected = true;
          item.size_key = key;
          item.t1as = "1";
          item.valid = "1";
          if (fileRole === "PRIMARY") {
            item.backup_only = false;
            item.backup = "";
            primaries.push(item);
            if (imageExt.indexOf(fileType) > -1) {
              item.ishtml5 = true;
              alternates[key] = alternates[key] || [];
              alternates[key].push(item);
            }
          } else if (fileRole === "BACKUP") {
            item.ishtml5 = true;
            alternates[key] = alternates[key] || [];
            alternates[key].push(item);
          } else if (fileRole === "ALTERNATE") {
            alternates[key] = alternates[key] || [];
            alternates[key].push(item);
          }
        }
      });
      _.each(primaries, function (primary) {
        var primaryName = primary.name;
        var fileType = primary.file_type;
        var isSWF = fileType.indexOf("swf") > -1;
        var isHTML = fileType.indexOf("html5") > -1;
        var alternateSize = alternates[primary.size_key];
        var options = [];
        if (isHTML) {
          _.each(alternates, function (item) {
            for (var key in item) {
              var itemKey = item[key];
              if (itemKey.ishtml5) {
                if (itemKey.file_role === "BACKUP" || itemKey.file_role === "PRIMARY") {
                  options.push(itemKey);
                }
              }
            }
          });
        } else {
          _.each(alternateSize, function (item) {
            if (item.file_role === "ALTERNATE") {
              options.push(item);
            }
          });
        }
        if (options && options.length > 0) {
          if (isSWF || isHTML) {
            primary.options = options;
            _.each(options, function (item) {
              var itemName = self.removeExtFromName(item.name);
              if (itemName === primaryName) {
                primary.backup = item.name;
                if (isHTML) {
                  primary.size_key = item.size_key;
                }
              } else if (!primary.backup) {
                primary.backup = options[0].name;
                if (isHTML) {
                  primary.size_key = options[0].size_key;
                }
              }
            });
          }
        }
      });
      primaries = _.sortBy(primaries, "size_key");
      this.set({
        primaries: primaries
      });
      this.set({
        alternates: alternates
      });
      this.set({
        unusables: unusables
      });
      this.setBatchIndex(primaries);
      this.resetCheckedItems();
    },
    setBatchIndex: function (validData) {
      var batchIndex = this.getBatchIndices(validData);
      this.updateBatchIndex(batchIndex);
    },
    updateBatchIndex: function (batchIndex) {
      this.set({
        batch_index: batchIndex
      });
    },
    getBatchIndices: function (data) {
      var output = [];
      var batchIndex;
      _.each(data, function (item, i) {
        batchIndex = i;
        data[i].index = i;
        output.push(batchIndex);
      });
      return output;
    },
    getOptionsBySize: function (sizeKey) {
      var alternates = this.get("alternates");
      var options = alternates[sizeKey];
      return options;
    },
    getSelectedOptionSize: function (name, sizeKey) {
      var self = this;
      var fileName = this.removeExtFromName(name);
      var alternates = this.get("alternates");
      var alternatesArr = alternates[sizeKey];
      var output = "--";
      _.each(alternatesArr, function (item) {
        var itemName = self.removeExtFromName(item.name);
        if (itemName === fileName) {
          output = self.formatFileSize(item.file_size);
        }
      });
      return output;
    },
    formatFileSize: function (fileSize) {
      return Math.round(parseInt(fileSize) / 1e3) + "kb";
    },
    removeExtFromName: function (name) {
      var fileExtIndex = name.lastIndexOf(".");
      return name.substring(0, fileExtIndex);
    },
    setPrimaryBackup: function (primaryIndex, backupName) {
      var primaries = this.get("primaries");
      var primaryDataObj = primaries[primaryIndex];
      primaryDataObj.backup = backupName;
      if (primaryDataObj.file_type.indexOf("html5") > -1) {
        _.each(primaryDataObj.options, function (item) {
          if (item.name === backupName) {
            primaryDataObj.size_key = item.size_key;
          }
        });
      }
    },
    setPrimaryBackupOnly: function (primaryIndex, value) {
      var primaries = this.get("primaries");
      var primaryDataObj = primaries[primaryIndex];
      primaryDataObj.backup_only = value;
    },
    setClickThru: function (value) {
      var primaries = this.get("primaries");
      var checkedItems = this.get("checked_items");
      _.each(checkedItems, function (id) {
        primaries[id].click_through_url = value;
      });
    },
    setConcept: function (conceptObj) {
      var primaries = this.get("primaries");
      var checkedItems = this.get("checked_items");
      _.each(checkedItems, function (id) {
        primaries[id].concept_id = conceptObj.id;
        primaries[id].concept_name = conceptObj.name;
      });
    },
    setCheckedItem: function (id) {
      var checkedItems = this.get("checked_items");
      if (checkedItems.indexOf(id) === -1) {
        checkedItems.push(id);
      }
    },
    resetCheckedItems: function () {
      this.set({
        checked_items: []
      });
    },
    getBackupKbSize: function (sizeKey, selectedName) {
      var alternates = this.get("alternates");
      var altsBySize = alternates[sizeKey];
      var itemData = this.getItemByProp(altsBySize, "name", selectedName)[0];
      var kbSize = this.formatFileSize(itemData.file_size);
      return kbSize;
    },
    getItemByProp: function (array, prop, matchProp) {
      return $.grep(array, function (item) {
        return item[prop] === matchProp;
      });
    },
    reset: function () {
      this.url = "";
      this.attributes = {};
    },
    validateAttrs: function (attrs) {
      var self = this;
      var errors = [];
      var optionalFields = ["click_url"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        click_url: function () {
          if (attrs.click_url) {
            return self.validateUrl(attrs.click_url, 1e4);
          }
          return false;
        },
        click_through_url: function () {
          if (attrs.click_through_url) {
            return self.validateUrl(attrs.click_through_url);
          }
          return false;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (!validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (attrs.hasOwnProperty("click_url")) {
        validationResult = reqFields.click_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_url",
            message: validationResult
          });
        }
      }
      if (attrs.hasOwnProperty("click_through_url")) {
        validationResult = reqFields.click_through_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_through_url",
            message: validationResult
          });
        }
      }
      if (errors.length) {
        return errors;
      }
    },
    validateUrl: function (value, maxChar) {
      var urlValidation = /^(http|https):\/\/[^ "]+$/;
      maxChar = maxChar || 2048;
      if (!urlValidation.test(value)) {
        return "Please provide a valid URL.";
      }
      if (value.length > maxChar) {
        return "URL must not exceed " + maxChar + " characters.";
      }
      return false;
    },
    validateAdvertiserId: function () {
      return !!this.attributes.advertiser_id;
    }
  });
  return T1.Models.T1asAsset;
});
