define('collections/audienceProviders',["jQuery", "T1", "T1Collection", "models/audienceProvider", "Underscore", "T1Sortable"], function ($, T1, T1Collection, AudienceProvider, _, Sortable) {
  "use strict";

  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      },
      audience_vendor_id: {
        actual: "audience_vendor_id"
      },
      pin_top_search: {
        actual: "audience_vendor.pin_top_search,audience_vendor_id"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending",
      firstSortBy: "pin_top_search",
      firstSortByOrder: "descending"
    }
  };
  var switchCollection = function (mode, collectionObj) {
    var defaultPageLimit = 50;
    delete collectionObj.fetchOptions.parent;
    if (mode === "search") {
      collectionObj.urlString = "path_audience_segments";
      collectionObj.page = 0;
      collectionObj.pageLimit = defaultPageLimit;
    } else if (mode === "browse") {
      collectionObj.urlString = "audience_segments";
      collectionObj.isPaginated = true;
      collectionObj.pageLimit = 100;
      if (collectionObj.search) {
        collectionObj.search.clear();
      }
    } else if (mode === "searchFromSupernodes") {
      collectionObj.urlString = "path_audience_segments/limit/audience_vendor.vendor=";
      collectionObj.isPaginated = true;
      collectionObj.page = 0;
      collectionObj.pageLimit = defaultPageLimit;
    } else {
      collectionObj.urlString = "audience_segments";
      collectionObj.isPaginated = false;
      if (collectionObj.search) {
        collectionObj.search.clear();
      }
    }
  };
  var AudienceProviders = T1Collection.extend({
    canCache: false,
    cacheDuration: 18e5,
    model: AudienceProvider,
    urlString: "audience_segments",
    fixture: "-audience_segments",
    fetchOptions: {
      full: "*"
    },
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        },
        audience_vendor_id: {
          type: "enum"
        }
      }
    },
    getProviders: function (options) {
      var opts = options || {};
      var collection = this;
      var deferred = $.Deferred();
      switchCollection("default", this);
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      collection.fetch(opts);
      return deferred.promise();
    },
    getAudienceSegments: function (parent, currency) {
      var opts = {
        silent: true,
        params: {
          currency_code: currency
        }
      };
      var collection = this;
      var deferred = $.Deferred();
      switchCollection("browse", this);
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      if (!isNaN(parent)) {
        collection.fetchOptions = $.extend(true, {}, collection.fetchOptions, {
          parent: parent
        });
      }
      collection.fetch(opts);
      return deferred.promise();
    },
    getAudienceFromSupernodes: function (query, vendor_id, currency) {
      var opts = {};
      var tmpQ;
      var collection = this;
      var params;
      var deferred = $.Deferred();
      switchCollection("searchFromSupernodes", this);
      this.urlString = this.urlString + vendor_id;
      tmpQ = "*" + query + "*";
      params = [{
        term: tmpQ,
        searchField: "name"
      }];
      collection.search.set({
        params: params
      });
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      collection.fetchOptions = $.extend(true, collection.fetchOptions, {
        currency_code: currency
      });
      collection.fetch(opts);
      return deferred.promise();
    },
    getAudience: function (query, vendor_id, currency) {
      var opts = {};
      var tmpQ;
      var collection = this;
      var params;
      var deferred = $.Deferred();
      switchCollection("search", this);
      tmpQ = "*" + query + "*";
      params = [{
        term: tmpQ,
        searchField: "name"
      }];
      if (parseInt(vendor_id, 10) !== 0) {
        params.push({
          term: vendor_id,
          searchField: "audience_vendor_id"
        });
      }
      collection.search.set({
        params: params
      });
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      collection.fetchOptions = $.extend(true, collection.fetchOptions, {
        currency_code: currency
      });
      collection.fetch(opts);
      return deferred.promise();
    }
  });
  T1.Models.AudienceProviders = Sortable.extendCollection(AudienceProviders, sortConfiguration);
  return T1.Models.AudienceProviders;
});
