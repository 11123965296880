define('T1ChartBenchmarkOptions',['require','Underscore','jQuery','T1','jQueryUI','T1Tooltip'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const T1 = require("T1");
  const jQueryUI = require("jQueryUI");
  const T1Tooltip = require("T1Tooltip");
  T1.ChartBenchmarkOptions = {
    options: {
      benchmarkLabelLeftPadding: 20,
      benchmarkLabelRightPadding: 40,
      benchmarkLabelLineRightPadding: 155,
      benchmarkTagOpenH: 30,
      campaignBenchmarkToggleClass: "campaign-toggle",
      leftTagOpen: "0",
      rightTagOpen: "0",
      highlightedId: 0,
      multiCampaignsLabel: "multiple campaigns",
      toggleBenchmark() {
        this.update();
        this.setBenchmarkVisibility();
      },
      setBenchmarkVisibility() {
        var chart = this.$(this.chartSelector);
        var campaignBenchmark = chart.find(`.${this.campaignBenchmarkToggleClass}`);
        campaignBenchmark.toggle(this.reportModel.get("campaignBenchmarkOn"));
      },
      benchmarkKeyForGoalType(goalType, reportMeta) {
        var rptMeta = reportMeta || this.reportMeta || null;
        var map = rptMeta && rptMeta.get("defaultSortForGoalType");
        var value = map && map[goalType];
        return value && value.split(",")[0];
      },
      matchedMetricKeyToGoalType(yKey, goalType, reportMeta) {
        return yKey === this.benchmarkKeyForGoalType(goalType, reportMeta);
      },
      getGoalValue() {
        var goalValue;
        var campaign = this.campaign;
        var mainModel = this.mainModel;
        var multiCampaign = mainModel.get("multiCampaigns");
        var multiCampaignCurrency = mainModel.get("multiCampaignCurrency");
        var currencyCode = mainModel.getCurrencyCode();
        var goalType = campaign.get("goal_type");
        var showGoal = !multiCampaign && this.matchedMetricKeyToGoalType(this.yKey, goalType);
        var goalValueAmounts = campaign.get("goal_value_amount");
        if (!showGoal) {
          return 0;
        }
        if (_.isArray(goalValueAmounts)) {
          let goalValueIndex;
          const goalValueCurrencies = _.map(goalValueAmounts, obj => obj.currency_code);
          if (multiCampaign) {
            goalValueIndex = _.indexOf(goalValueCurrencies, multiCampaignCurrency.code);
          } else {
            goalValueIndex = _.indexOf(goalValueCurrencies, currencyCode);
          }
          goalValue = goalValueAmounts[goalValueIndex].value;
        } else {
          goalValue = goalValueAmounts.value;
        }
        return goalValue;
      },
      graphBenchMarkSeries(cfgs = {}) {
        var benchMarkLabelXPos, benchmark, benchmarkExtendY, extendW, mark, tagValueFormat, yPos;
        const leftID = 2;
        const rightID = 1;
        const defaultWidth = 2;
        const extension = 15;
        const extend1 = 6;
        const extend2 = 32;
        const extend3 = 9;
        const half = 2;
        var self = this;
        const halfWidth = this.targetW / half;
        var name = cfgs.name || "benchmark";
        var color = cfgs.color || "#000";
        var strokeWidth = cfgs.strokeWidth || defaultWidth;
        var dash = cfgs.dash || "0";
        var data = cfgs.data || [[]];
        var processedData = cfgs.processedData;
        var mmChart = this.mmChart;
        var benchmarkExtendX = mmChart ? 0 : extension;
        var benchmarkExtendSide = "right";
        var benchmarkTagName = "";
        var tagValue = cfgs.benchmarkTagValue || 0;
        var mainModel = this.mainModel;
        var goalValue = cfgs.goalValue || 0;
        var noGoal = cfgs.noGoal || false;
        var campaignToggleClass = name.indexOf("campaign") > -1 ? ` ${this.campaignBenchmarkToggleClass}` : "";
        var circleColor = color;
        var circleStrokeColor = cfgs.circleStrokeColor || circleColor;
        var circleStroke = cfgs.circles === false ? 0 : 1;
        var lineColor = cfgs.lineColor || "#000";
        var radius = cfgs.circleRadius || this.circleRadius;
        var line = cfgs.line || this.line;
        var yRadius = cfgs.yRadius || this.yRadius;
        var y = cfgs.y || "y";
        var startX = this.padMatrix.left + halfWidth;
        var benchMarkLabel = name;
        if (!this.vis || !this.chartCanvas) {
          throw Error("T1Chart: Chart svg canvas is not setup");
        }
        if (!processedData) {
          processedData = this.dataProcessor(data);
        }
        const firstMark = processedData.length && processedData[0].length ? processedData[0][0] : {
          x: 0,
          y: 0
        };
        const lastMark = processedData.length && processedData[0].length ? processedData[0][processedData[0].length - 1] : {
          x: 0,
          y: 0
        };
        if (mmChart) {
          if (name === "campaign") {
            mark = firstMark;
            benchmarkExtendSide = "left";
            benchmarkExtendY = Math.round(this.y(mark.y));
          } else {
            mark = lastMark;
            benchmarkExtendY = Math.round(this.y_2(mark.y_2));
          }
        } else {
          benchMarkLabel = mainModel.get("multiCampaigns") ? this.multiCampaignsLabel : benchMarkLabel;
          mark = lastMark;
          benchmarkExtendY = Math.round(this.y(mark.y));
        }
        const dim = benchmarkExtendSide === "left" && this.highlightedId === leftID || benchmarkExtendSide === "right" && this.highlightedId === rightID ? " dim" : "";
        const lines = this.vis.selectAll("path.benchmark").data(processedData);
        lines.exit().remove();
        const markX = this.x(mark.x);
        if (benchmarkExtendSide === "left") {
          const offset = 10;
          extendW = -startX + this.benchmarkLabelLeftPadding + offset;
          this.vis.append("line").attr("class", `${name}-benchmark-line-extend${campaignToggleClass}`).attr("stroke-dasharray", dash).attr("x1", markX + extendW).attr("x2", this.xItemsCount === 1 ? this.width / half : markX).attr("y1", benchmarkExtendY).attr("y2", benchmarkExtendY).attr("stroke-width", strokeWidth).style("stroke", lineColor);
        }
        lines.enter().append("path").attr("class", `${name}-benchmark-line${campaignToggleClass}`).attr("stroke-dasharray", dash).style("stroke", color).attr("d", line).attr("fill", "none").attr("stroke-width", strokeWidth);
        if (benchmarkExtendSide === "right") {
          if (this.chartChangeLogIsOpen && this.reportType === "performance") {
            extendW = startX - self.benchmarkLabelRightPaddingOriginal + benchmarkExtendX;
          } else {
            extendW = startX - self.benchmarkLabelRightPadding + benchmarkExtendX;
          }
          this.vis.append("line").attr("class", `${name}-benchmark-line-extend${campaignToggleClass}`).attr("stroke-dasharray", dash).attr("x1", this.xItemsCount === 1 ? this.width / half : markX).attr("x2", this.xItemsCount === 1 ? this.width + extendW : markX + extendW).attr("y1", benchmarkExtendY).attr("y2", benchmarkExtendY).attr("stroke-width", strokeWidth).style("stroke", lineColor);
        }
        const circles = this.vis.selectAll(`g.${name}-circles`).data(processedData);
        circles.exit().remove();
        circles.enter().append("g").attr("class", `${name}-circles${campaignToggleClass}`).selectAll("circle.line").data(d => d).enter().append("circle").attr("class", "circle").attr("stroke", circleStrokeColor).attr("stroke-width", circleStroke).attr("fill", circleColor).attr("cx", function (d) {
          const halfRadius = .5;
          return self.units === 1 ? (self.width - radius) * halfRadius : self.x(d.x);
        }).attr("cy", d => yRadius(d[y])).attr("r", radius).attr("style", () => cfgs.hideCircles ? "opacity: 0" : "");
        if (mmChart) {
          if (benchmarkExtendSide === "left") {
            benchmarkTagName = this.yAxisLabel;
            tagValueFormat = this.reportMetaObj.fields[this.yKey].type;
          } else {
            benchmarkTagName = this.yAxisLabel2;
            tagValueFormat = this.reportMetaObj.fields[this.yKey2].type;
          }
          const currencyFormatter = benchmarkExtendSide === "left" ? this.currencyFormatter : this.currencyFormatter2;
          const tagValueFormatter = tagValueFormat === "currency" ? currencyFormatter : this.dataFormatters(tagValueFormat);
          const formattedTagValue = tagValueFormatter(tagValue);
          benchmark = $(`<div class="${self.reportType} benchmark-tag-c ${name}-benchmark-label ${benchmarkExtendSide}">\n              <div class="tag benchmark-tag-${benchmarkExtendSide}${dim}">\n                <div class="title">${benchmarkTagName}</div>\n                <div class="value">${formattedTagValue}</div>\n              </div>\n              <div class="benchmark-ptr-${benchmarkExtendSide}${dim}"></div>\n            </div>`);
          yPos = benchmarkExtendY + extend1;
        } else {
          benchmark = $(`<div class="benchmark-label ${name}-benchmark-label${campaignToggleClass}">\n              <div class="title narrow-text">${benchMarkLabel}</div>\n            </div>`);
          if (noGoal || mark.y - goalValue < 0 && name === "campaign") {
            yPos = benchmarkExtendY + extend2;
          } else {
            yPos = benchmarkExtendY + extend3;
          }
        }
        if (mmChart) {
          benchMarkLabelXPos = benchmarkExtendSide === "left" ? {
            left: this.benchmarkLabelLeftPadding
          } : {
            right: this.benchmarkLabelRightPadding
          };
        } else {
          benchMarkLabelXPos = {
            left: this.width + this.padMatrix.left + this.tickSize + this.targetW - this.benchmarkLabelRightPadding
          };
        }
        this.chartCanvas.find(`.${name}-benchmark-label`).remove().end().append(benchmark);
        const benchmarkTag = this.chartCanvas.find(`.${name}-benchmark-label`).css($.extend({
          position: "absolute",
          top: yPos
        }, benchMarkLabelXPos));
        if (name === "campaign" && this.leftTagOpen === "1" || name === "campaign2" && this.rightTagOpen === "1") {
          benchmarkTag.children(".tag").css("height", `${this.benchmarkTagOpenH}px`);
        }
        return this;
      },
      graphBenchMark(cfgs) {
        const defaultWidth = 2;
        const half = .5;
        const extend1 = 32;
        const extend2 = 9;
        var mark = cfgs.mark || 0;
        var name = cfgs.name || "benchmark";
        var format = cfgs.format || this.reportMetaObj.fields[this.yKey].type;
        var mainModel = this.mainModel;
        var reportModel = this.reportModel;
        var campaign = this.campaign;
        var formatCurrency = T1.Utils.formatCurrency;
        var currencyCode = mainModel.getCurrencyCode(campaign, reportModel.get("focus"));
        var formattedTooltip = format === "currency" ? formatCurrency(mark, null, currencyCode, "tooltip") : T1.Formats.as(format)(mark);
        var difference = cfgs.difference || 0;
        var formattedDifference = format === "currency" ? formatCurrency(Math.abs(difference), null, currencyCode) : T1.Formats.as(format)(difference);
        var color = cfgs.color || "#000";
        var strokeWidth = cfgs.strokeWidth || defaultWidth;
        var dash = cfgs.dash || "0";
        var benchmark, yPos;
        var series = cfgs.series || false;
        var noGoal = cfgs.noGoal || false;
        var campaignToggleClass = name === "campaign" ? ` ${this.campaignBenchmarkToggleClass}` : "";
        var campaignStatus = difference <= 0 ? "low" : "high";
        var narrowTextClass = "";
        var benchMarkLabel = name;
        const halfTargetW = this.targetW * half;
        if (mainModel.get("multiCampaigns")) {
          narrowTextClass = " narrow-text";
          benchMarkLabel = this.multiCampaignsLabel;
        }
        if (!this.vis || !this.chartCanvas) {
          throw Error("T1Chart: Chart svg canvas is not setup");
        }
        this.vis.append("line").attr("class", `${name}-benchmark-line${campaignToggleClass}`).attr("stroke-dasharray", dash).attr("x1", series ? 0 : -(halfTargetW + this.tickSize)).attr("x2", this.chartCanvas.width() - this.benchmarkLabelLineRightPadding - halfTargetW).attr("y1", Math.round(this.y(mark))).attr("y2", Math.round(this.y(mark))).attr("stroke-width", strokeWidth).style("stroke", color);
        benchmark = $(`<div class="benchmark-label ${name}-benchmark-label${campaignToggleClass}" title="${formattedTooltip}">${name === "campaign" && difference !== 0 ? `<div class="value ${campaignStatus}">${campaignStatus === "high" ? "(+" : "(-"}${formattedDifference})</div>` : ""}<div class="title${narrowTextClass}">${benchMarkLabel}</div></div>`);
        if (noGoal || difference >= 0 && name === "goal" || difference < 0 && name === "campaign") {
          yPos = this.y(mark) + extend1;
        } else {
          yPos = this.y(mark) + extend2;
        }
        this.chartCanvas.find(`.${name}-benchmark-label`).remove().end().append(benchmark).find(`.${name}-benchmark-label`).css({
          position: "absolute",
          top: yPos,
          left: this.width + this.padMatrix.left + this.tickSize + this.targetW - this.benchmarkLabelRightPadding
        });
        return this;
      }
    },
    extend(chartOptions) {
      return $.extend({}, this.options, chartOptions);
    }
  };
  return T1.ChartBenchmarkOptions;
});
