define('collections/searchResults',["T1", "T1Collection", "T1Model", "T1Sortable", "collections/organizations"], function (T1, T1Collection, T1Model, Sortable, Organizations) {
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    initialize: function (models, options) {
      var self = this;
      var defaultSearchOpts = {
        isFetchSilent: false,
        fields: {
          name: {
            type: "string"
          }
        }
      };
      if (!options.urlString || options.urlString === "") {
        throw new Error("urlString must be provided");
      }
      this.urlString = options.urlString;
      this.model = T1Model.extend({});
      if (options.filterConfiguration) {
        this.filterConfiguration = options.filterConfiguration || {};
      }
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.searchConfiguration = options.searchConfiguration || defaultSearchOpts;
      T1Collection.prototype.initialize.apply(this, arguments);
      Organizations.getOrganizations().getSelected().then(function (id) {
        if (options.urlFilter) {
          self.urlFilter.set(options.urlFilter);
        } else {
          self.urlFilter.set({
            entity: "organization",
            id: id
          });
        }
      });
    }
  });
  T1.Models.SearchResults = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.SearchResults;
});
