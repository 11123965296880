define('models/companion',["jQuery", "Underscore", "T1", "T1Model"], function ($, _, T1, T1Model) {
  T1.Models.Companion = T1Model.extend({
    apiRoot: T1.VIDEO_API_BASE,
    url: function () {
      var creativeId = this.get("creativeId");
      var id = this.get("id") || "";
      var action = this.get("action");
      var actionOutput = action ? "/" + action : "";
      if (!creativeId) {
        throw new Error("creativeId is required on this model to save");
      }
      return this.apiRoot + "creatives/" + creativeId + "/companions/" + id + actionOutput;
    },
    fixture: "-companion",
    canEdit: function () {
      var def = $.Deferred();
      def.resolve(true);
      return def.promise();
    },
    save: function (opts, callback) {
      opts = opts || {};
      var modelObj = this.toJSON();
      var def = $.Deferred();
      var self = this;
      var saveSuccessHandler = function () {
        callback.handleSuccess(def);
      };
      var showErrors = opts.showErrors;
      var statusInvalid = opts.onStatusInvalid;
      var id = self.id || self.cid;
      var handleInvalid = function (errors) {
        var names, err;
        if (!statusInvalid || !showErrors) {
          throw new Error("Please make sure T1Form.showErrors and the save statusInvalid methods are in scope");
        }
        statusInvalid(errors, "There are errors on the page.", ".w-CreativesCreateEdit");
        $.each(errors, function (i, error) {
          if (error.field) {
            names = error.field.split(".");
            err = [{
              field: "companion-" + names[1],
              message: error.message
            }];
            showErrors(self.outputModel, err, ".companion[data-id=" + id + "]");
          }
        });
      };
      var options = {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: saveSuccessHandler,
        error: def.reject,
        statusInvalid: handleInvalid,
        processAjaxResponse: self.processAjaxResponse
      };
      var output = {};
      output.fileExtension = modelObj.fileExtension;
      delete modelObj.fileExtension;
      output.resourceData = modelObj.resourceData;
      delete modelObj.resourceData;
      output.fileName = modelObj.fileName;
      delete modelObj.fileName;
      output.details = modelObj;
      if (modelObj.typ === "static") {
        delete modelObj.iFrameUrlOrHTML;
        delete output.iFrameUrlOrHTML;
      }
      T1Model.prototype.save.apply(this, [output, options]);
      return def.promise();
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: resp.status.code || "ok"
      };
    },
    validateAttrs: function (attrs) {
      attrs = attrs || this.toJSON();
      var self = this;
      var msg = "";
      var errors = [];
      var requiredField = "This is a required field.";
      var urlValidation = /^(http|https):\/\/[^ "]+$/;
      var minInt = 31;
      var maxInt = 9999;
      var maxFileSize = 2097152;
      var validFiles = ["png", "gif", "jpg", "jpeg", "swf"];
      var reqFields = {
        adServer: function () {
          if (!attrs.adServer) {
            return requiredField;
          }
          return false;
        },
        typ: function () {
          if (!attrs.typ) {
            return requiredField;
          }
        },
        width: function () {
          if (!attrs.width) {
            return requiredField;
          }
          if (attrs.width <= minInt) {
            return "Width must be greater than " + minInt + " pixels";
          }
          if (attrs.width > maxInt) {
            return "Width exceeds " + maxInt + " pixels";
          }
          return false;
        },
        height: function () {
          if (!attrs.height) {
            return requiredField;
          }
          if (attrs.height <= minInt) {
            return "Height must be greater than " + minInt + " pixels";
          }
          if (attrs.height > maxInt) {
            return "Height exceeds " + maxInt + " pixels";
          }
          return false;
        },
        resourceData: function () {
          if (attrs.typ === "static" && attrs.action !== "delete") {
            if (!attrs.resourceData && !self.id || !attrs.resourceData && !attrs.resourceUrl) {
              return requiredField;
            } else if ($.inArray(attrs.fileExtension.toLowerCase(), validFiles) === -1) {
              return "Invalid file type";
            } else if (attrs.fileSize > maxFileSize) {
              return "File size cannot be greater than 2 Megabytes";
            }
          }
          return false;
        },
        iFrameUrlOrHTML: function () {
          if (attrs.typ !== "static") {
            if (attrs.typ === "iframe") {
              if (!urlValidation.test(attrs.iFrameUrlOrHTML)) {
                return "Bad URL " + _.escape(attrs.iFrameUrlOrHTML);
              }
            }
            if (!attrs.iFrameUrlOrHTML) {
              return requiredField;
            }
          }
          return false;
        },
        clickthroughUrl: function () {
          if (attrs.typ === "static") {
            if (!attrs.clickthroughUrl) {
              return requiredField;
            }
            if (!urlValidation.test(attrs.clickthroughUrl)) {
              return "Bad URL " + attrs.clickthroughUrl;
            }
          }
          return false;
        },
        landingUrl: function () {
          if (!attrs.landingUrl) {
            return requiredField;
          }
          if (!urlValidation.test(attrs.landingUrl)) {
            return "Bad URL " + attrs.landingUrl;
          }
          return false;
        }
      };
      var fieldPrefix = "companion-";
      for (var key in reqFields) {
        if (reqFields.hasOwnProperty(key)) {
          msg = reqFields[key]();
          if (msg) {
            errors.push({
              field: fieldPrefix + key,
              message: msg,
              id: self.id || self.cid
            });
          }
        }
      }
      if (errors.length) {
        return errors;
      }
    }
  });
  return T1.Models.Companion;
});
