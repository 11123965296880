define('modules/media/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "media",
      viewCfgs: {
        tabs: {}
      },
      defaultView: "tabs"
    });
  }
  return instance;
});
