define('models/clientProductEstimateIO',['require','T1','T1Model','T1Comm'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1Model = require("T1Model");
  const T1Comm = require("T1Comm");
  T1.Models.ClientProductEstimateIO = T1Model.extend({
    name: "clientProductEstimateIO",
    urlString: T1.ENV.MEDIAOCEAN_API_BASE,
    url: function () {
      return `${this.urlString}campaign/${this.id}`;
    },
    fetch: function (options) {
      const url = this.url();
      const notAvailable = 404;
      T1Comm.get({
        forcedContentType: "application/json",
        dataType: "json",
        onSuccess: options.success,
        onStatusInvalid: options.statusInvalid,
        onError: options.error,
        processAjaxResponse: this.processAjaxResponse,
        sourceURL: url,
        errorDisplaySuppressingConfig: {
          errorCodes: [notAvailable]
        }
      });
    },
    processAjaxResponse: function (resp) {
      const responseData = typeof resp === "string" ? JSON.parse(resp) : resp;
      if (responseData.Status) {
        return {
          statusCode: responseData.Status,
          jsonData: responseData.Description
        };
      }
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.ClientProductEstimateIO;
});
