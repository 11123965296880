define('modules/reporting/segments/dataExport/createEdit/performance/views/list',["jQuery", "T1", "T1View", "text!../templates/list.html"], function ($, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    fetchRequired: true,
    segmentData: [],
    events: {
      "click .include-icon": "includeClickHandler",
      "click .performance-show-more-segments": "loadSegments"
    },
    eventhubEvents: {
      clearPerformanceReport: "clearPerformanceReport",
      unloadSegmentsReports: "unload"
    },
    initialize: function (args) {
      this.sharedCollection = args.sharedCollection;
      this.collection = this.sharedCollection;
      this.collection.pageOffset = 0;
      this.collection.pageLimit = 100;
      this.model = args.model;
      this.model.performanceSegments = [];
      this.segmentData = [];
      this.pageNum = 1;
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.grid = self.el[0].querySelector("#strandGrid");
        self.loadSegments();
      });
    },
    loadSegments: function () {
      var self = this;
      this.collection = this.sharedCollection;
      this.collection.sortBy = "-created_on";
      this.grid.isLoading = true;
      if (this.pageNum > 1) {
        this.grid.splice("data", this.grid.data.length - 1, 1);
      }
      this.collection.fetch({
        onSuccess: function (response) {
          var parsedResponse = T1.Utils.parseXMLtoJson(response);
          var numOfPages = Math.ceil(parsedResponse.entities.count / self.collection.pageLimit);
          var segments = [];
          if (parsedResponse.entities.entity instanceof Array) {
            segments = parsedResponse.entities.entity;
          } else if (parsedResponse.entities.entity instanceof Object) {
            segments[0] = parsedResponse.entities.entity;
          }
          $.each(segments, function (index, seg) {
            seg.advertiserName = seg.entity.name;
            seg.lastSegment = false;
            seg.state = seg.state ? seg.state : "deselected";
          });
          self.segmentData = self.segmentData.concat(segments);
          if (numOfPages > self.pageNum) {
            self.segmentData.push({
              lastSegment: true
            });
          }
          self.grid.set("data", self.segmentData);
          self.pageNum++;
          self.collection.pageOffset = self.collection.pageOffset + self.collection.pageLimit;
          self.attachRemoveSegmentClickHandler();
          self.grid.isLoading = false;
        }
      });
    },
    attachRemoveSegmentClickHandler: function () {
      var self = this;
      var bl = document.querySelector(".bins-column #bl");
      bl.addEventListener("item-include-or-exclude", function (e) {
        var model = e.detail.model;
        model.state = "deselected";
        self.model.performanceSegments = self.model.performanceSegments.filter(function (obj) {
          return obj.id !== model.id;
        });
        bl.set("data", self.model.performanceSegments.slice());
        self.model.updatePerformanceSegments(self.model.performanceSegments);
        self.el.find("#include-icon-" + model.id + " > strand-icon").attr("id", "deselected");
      });
    },
    clearPerformanceReport: function () {
      var model, i;
      var bl = document.querySelector(".bins-column #bl");
      for (i = 0; i < this.model.performanceSegments.length; i++) {
        model = this.model.performanceSegments[i];
        model.state = "deselected";
        this.el.find("#include-icon-" + model.id + " > strand-icon").attr("id", "deselected");
      }
      this.model.performanceSegments = [];
      if (bl) {
        bl.set("data", null);
      }
      this.model.updatePerformanceSegments([]);
    },
    includeClickHandler: function (event) {
      var $includeIcon = $(event.currentTarget);
      var bl = document.querySelector(".bins-column #bl");
      var $gridRow = $includeIcon.closest("strand-grid-item");
      var rowModel = $gridRow[0].model;
      if (rowModel.state === "deselected" || rowModel.state === undefined) {
        if (this.model.performanceSegments.length < 10) {
          rowModel.entity = "segments";
          rowModel.included = true;
          rowModel.state = "selected";
          $includeIcon.attr("id", "selected");
          this.model.performanceSegments.push(rowModel);
        }
      } else if (rowModel.state === "selected") {
        rowModel.state = "deselected";
        $includeIcon.attr("id", "deselected");
        this.model.performanceSegments = this.model.performanceSegments.filter(function (obj) {
          return obj.id !== rowModel.id;
        });
      }
      bl.set("data", this.model.performanceSegments.slice());
      this.model.updatePerformanceSegments(this.model.performanceSegments);
    },
    unload: function () {
      T1.EventHub.publish("clearPerformanceReport");
      this.pageNum = 0;
      this.segmentData = [];
      this.collection = null;
      this.sharedCollection = null;
    },
    serialize: function () {
      return {
        model: "{{model}}",
        scope: "{{scope}}",
        name: "{{model.name}}",
        advertiserName: "{{model.advertiserName}}",
        id: "{{model.id}}",
        state: "{{model.state}}",
        isLastSegment: "{{model.lastSegment}}",
        isNotLastSegment: "{{!model.lastSegment}}"
      };
    }
  });
});
