define('modules/creatives/concept/views/createEdit',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "models/user", "models/advertiser", "models/concept", "models/videoCreative", "models/videoCreativeDetail", "collections/organizations", "collections/creatives", "collections/creativesConcept", "text!../templates/createEdit.html", "text!../templates/createEditBody.html", "text!../templates/createEditWarning.html", "T1Form", "jQPlugins/jquery.trunk8"], function ($, _, When, T1, T1View, T1Layout, User, Advertiser, Concept, VideoCreative, VideoCreativeDetail, Organizations, Creatives, Concepts, template, templateBody, templateWarning) {
  var CreativesCreateEdit;
  function fetchBreadCrumbData(model) {
    var theConcept = new Concept({
      id: model.id
    });
    var deferred = $.Deferred();
    var date_created, concept_name, status;
    theConcept.fetch({
      params: {
        full: "*",
        with: "[concept, advertiser]"
      },
      success: function (arg) {
        date_created = arg.get("created_on");
        concept_name = arg.get("name");
        status = arg.get("status");
        updateBreadCrumb(model, arg.id, concept_name, date_created, status);
      }
    });
    return deferred.promise();
  }
  function getBreadCrumbHeaderInfo(model, dateCreated, status) {
    var modelJSON = model || model.toJSON();
    var valueToBeDisplayed = [{
      attr: "id",
      label: "Concept id",
      className: "id"
    }, {
      attr: "created_on",
      label: "created on",
      className: "id"
    }, {
      attr: "status",
      className: "status"
    }];
    var returnArry = [];
    var i;
    var noData = "&nbsp;--";
    var formatDate = function () {
      return dateCreated ? Date.parse(dateCreated).toString("MM/dd/yyyy") : noData;
    };
    var formatData = function (attr, value) {
      var attrOperations = {
        created_on: formatDate,
        status: function () {
          var statusText = status === "0" ? "Inactive" : "Active";
          if (!model.id) {
            statusText = "Active";
          }
          return statusText;
        },
        defaultFormat: function () {
          return value || noData;
        }
      };
      var formatter = attrOperations[attr] || attrOperations.defaultFormat;
      return formatter(value);
    };
    for (i = 0; i < valueToBeDisplayed.length; i++) {
      returnArry.push({
        label: valueToBeDisplayed[i].label || valueToBeDisplayed[i].attr,
        value: formatData(valueToBeDisplayed[i].attr, modelJSON[valueToBeDisplayed[i].attr]),
        className: valueToBeDisplayed[i].className
      });
    }
    return returnArry.reverse();
  }
  function updateBreadCrumb(model, cId, cName, dateCreated, status) {
    var pathPieces;
    if (cId) {
      pathPieces = [{
        label: cName,
        location: "#creatives/concepts/" + cId
      }];
    } else {
      pathPieces = [{
        label: "New Concept",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: getBreadCrumbHeaderInfo(model, dateCreated, status)
    });
  }
  CreativesCreateEdit = T1View.extend({
    template: template,
    confirmInactive: false,
    partials: {
      warning: templateWarning
    },
    events: {
      "click .save-action": "noop"
    },
    actionsConfig: {
      events: {
        "click .cancel": "cancelOverride",
        "click .saveAndContinue": "saveAndClose",
        "change .advertiser-select": "setAdvertiser"
      }
    },
    dataEvents: {
      sharedCollection: {
        add: function () {
          this.isFormElementsChanged = true;
        },
        remove: function () {
          this.isFormElementsChanged = true;
        },
        reset: function () {
          if (this.layout) {
            this.layout.load();
          }
          this.isFormElementsChanged = false;
        }
      }
    },
    eventhubEvents: {
      "organization:select": function (data) {
        T1.Location.set("#creatives/concepts");
        T1.EventHub.publish("dropdown:organizations", data);
      }
    },
    removeBinItems: function () {
      var self = this;
      var sharedCollection = this.sharedCollection;
      sharedCollection.each(function (binItem) {
        self.sharedCollection.remove(binItem);
      });
      if (this.sharedCollection.length > 0) {
        this.removeBinItems();
      }
    },
    setAdvertiser: function (event) {
      var targetValue = $(event.target).val();
      if (!this.isEdit && this.sharedCollection.length > 0) {
        this.removeBinItems();
      }
      T1.EventHub.publish("advertisers:set", targetValue);
    },
    initialize: function (args) {
      var self = this;
      var mode = args.mode;
      this.saveType = "save";
      self.collection = new Concepts();
      self.isEdit = false;
      self.mode = mode;
      self.args = args;
      self.actionsConfig.formActions = [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary save-preview",
        location: "#"
      }, {
        label: "save & close",
        uuid: "save_close",
        class: "saveAndContinue save-btn-primary",
        location: false
      }];
      this.sharedCollection = new Creatives(null, {
        pageLimit: 100
      });
      this.sharedCollection.canCache = false;
      this.sharedCollection.isPaginated = false;
      if (args.id) {
        this.sharedCollection.urlFilter.set({
          entity: "concept",
          id: "(" + args.id + ")"
        });
        this.sharedCollection.fetch({
          params: {
            full: "*",
            with: ["advertiser", "concept"]
          },
          success: function (data) {
            if (args.id) {
              data.each(function (model) {
                model.set({
                  assigned: "1"
                });
                model.set({
                  original: "true"
                });
                if (model.get("action") === "new") {
                  model.set({
                    action: "include"
                  });
                }
              });
            }
            self.updateModels(self.args);
          }
        });
      } else {
        self.updateModels(self.args);
      }
    },
    updateModels: function (args) {
      var self = this;
      if (args.id) {
        this.model = new Concept({
          id: args.id
        });
        this.canLoad = false;
        this.isEdit = true;
        self.confirmInactive = false;
        this.model.fetch({
          params: {
            full: "*",
            with: ["advertiser"]
          },
          success: function (data) {
            if (data.get("status") === "0") {
              self.confirmInactive = true;
            }
            self.canLoad = true;
            self.advertiserName = self.model.get("advertiser").name;
            self.advertiserID = self.model.get("advertiser").id;
            self.initLayout();
          }
        });
        fetchBreadCrumbData(this.model);
      } else {
        this.model = new Concept({
          action: "new"
        });
        this.canLoad = true;
        this.initLayout();
        updateBreadCrumb({});
      }
    },
    initLayout: function () {
      var self = this;
      var advertiser = this.isEdit === true ? this.model.get("advertiser").id : "";
      var conceptID = this.isEdit === true ? this.model.id : "";
      var createEditLayout;
      if (!this.canLoad) {
        return;
      }
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        createEditLayout = {
          ".advertiser-select": [{
            module: "shared",
            viewType: "advertiserSelect",
            options: {
              entity: "advertisers",
              selectedId: advertiser,
              dataBind: "advertiser_id",
              boundEntity: "organizations",
              boundEntityId: orgId,
              isSearchable: true,
              initialLoad: true,
              placeholder: "Select an Advertiser",
              preSelect: "Select an Advertiser",
              notFound: "No Advertisers Found",
              refreshEvent: "refresh",
              preRender: function () {},
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              },
              disableCheck: $.proxy(function () {
                return this.isEdit;
              }, this)
            }
          }],
          ".browse": [{
            module: "creatives/concept",
            viewType: "creative_list",
            options: {
              sharedCollection: self.sharedCollection,
              orgId: orgId,
              advertiserID: advertiser,
              conceptID: conceptID,
              isEdit: self.isEdit
            }
          }],
          ".bins-column": [{
            module: "creatives/concept",
            viewType: "bins",
            options: {
              sharedCollection: self.sharedCollection
            }
          }],
          ".strategy-list-body": [{
            module: "shared",
            viewType: "strategy_list",
            options: {
              conceptID: conceptID
            }
          }]
        };
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          partials: {
            warning: templateWarning
          },
          selectorPath: ".w-body",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: createEditLayout
        });
        self.load();
      }, this));
    },
    load: function () {
      var self = this;
      if (this.canLoad) {
        this.render().then(function () {
          self.layout.load();
          self.makeTooltips();
          if (self.isEdit) {
            self.collection.mapStrategy([self.args.id || self.conceptID], {}, false);
          }
          self.reloadAd = _.once(self.reloadDDL);
          self.reloadAd();
        });
      }
    },
    reloadDDL: function () {
      var self = this;
      var $advDDL = $(".advertiser-select", self.el);
      if (!self.isEdit) {
        $advDDL.find("._wrapper").addClass("ad-wrapper");
        $(self.el).not('div[class="ad-wrapper"]').click(function () {
          var $chosenDDL = $advDDL.find("ul");
          if ($chosenDDL.hasClass("search-results")) {
            self.layout.layout[".advertiser-select"][0].view.reloadResults();
            $chosenDDL.removeClass("search-results");
          }
        });
      }
    },
    makeTooltips: function () {
      var self = this;
      var context = self.el;
      var mClose = document.getElementById("modalClose");
      var mContinue = document.getElementById("modalContinue");
      mClose.addEventListener("click", self.hideConfirmAction);
      mContinue.addEventListener("click", $.proxy(self.confirmAction, self));
      T1.Tooltip("body", {
        fade: false,
        tooltipEle: context.find(".t1-tooltip")
      });
    },
    confirmAction: function () {
      var tm = document.getElementById("confirmModal");
      this.confirmInactive = true;
      $("." + this.saveType).trigger("click");
      tm.hide();
    },
    showConfirmAction: function () {
      var tm = document.getElementById("confirmModal");
      tm.show();
    },
    hideConfirmAction: function (e) {
      var tm = document.getElementById("confirmModal");
      if (e) {
        e.preventDefault();
      }
      tm.hide();
    },
    unload: function () {},
    preparePostData: function (formValues) {
      var $el = $(this.el);
      formValues = T1.Form.dataAsObject($(".w-CreativesCreateEdit", $el));
      formValues.advertiser_id = $el.find(".advertiser-select").find("ul").attr("id");
      return formValues;
    },
    postData: function (formValues, successCallback, statusInvalid) {
      var self = this;
      var deferred = When.defer();
      var queue = [];
      var activeStrat = false;
      var noAdvID = !formValues.advertiser_id && !this.advertiserID;
      var modelValue, error;
      if (formValues.name.length > 256 || noAdvID || !formValues.name) {
        if (formValues.name.length > 256) {
          error = [{
            field: "name",
            message: "Name must not exceed 256 characters."
          }];
          self.showErrors(this.model, error, ".w-CreativesCreateEdit");
        }
        if (noAdvID) {
          error = [{
            field: "advertiser_id",
            message: "This is a required field."
          }];
          self.showErrors(this.model, error, ".w-CreativesCreateEdit");
        }
        if (!formValues.name) {
          error = [{
            field: "name",
            message: "This is a required field."
          }];
          self.showErrors(this.model, error, ".w-CreativesCreateEdit");
        }
        statusInvalid([]);
        deferred.reject();
        return;
      }
      activeStrat = $(".strategy-list-grid").find(".item").hasClass("active");
      if (formValues.status === "0" && !this.confirmInactive && activeStrat) {
        this.showConfirmAction();
        statusInvalid([]);
        deferred.reject();
        return;
      }
      this.model.save({
        name: formValues.name,
        status: formValues.status,
        advertiser_id: formValues.advertiser_id || self.advertiserID
      }, {
        success: $.proxy(function () {
          deferred.resolve(this.model);
          self.conceptID = this.model.id;
          this.sharedCollection.each(function (model) {
            var deferredColl = When.defer();
            var outputModel = new VideoCreativeDetail({
              id: model.id
            });
            function saveVideo(conceptId) {
              var vc = new VideoCreative({
                id: model.id
              });
              var saveOpts = {
                dataType: "json",
                stringify: true,
                contentType: "application/json",
                processData: false,
                success: function () {
                  deferredColl.resolve();
                },
                conflict: function () {
                  deferredColl.reject();
                },
                processAjaxResponse: outputModel.processAjaxResponse
              };
              vc.sync("read", {
                id: model.id
              }, {
                onSuccess: function (data) {
                  var details = data.details;
                  if (details.customVAST) {
                    outputModel.set({
                      customVAST: details.customVAST
                    });
                  } else if (details.customVASTUrl) {
                    outputModel.set({
                      customVASTUrl: details.customVASTUrl
                    });
                  } else {
                    outputModel.set({
                      clickthroughUrl: details.clickthroughUrl
                    });
                  }
                  outputModel.set({
                    name: details.name,
                    advertiser: details.advertiser,
                    concept: conceptId,
                    landingUrl: details.landingUrl,
                    startTime: details.startTime,
                    endTime: details.endTime,
                    active: details.active,
                    externalIdentifier: details.externalIdentifier,
                    eventPixels: details.eventPixels
                  });
                  outputModel.save(outputModel.toJSON(), saveOpts);
                },
                errorDisplaySuppressingConfig: {
                  errorCodes: [404, 502, 503]
                },
                dataType: "json",
                processAjaxResponse: function (resp) {
                  return {
                    jsonData: resp,
                    statusCode: resp.status.code || "ok"
                  };
                }
              });
            }
            function saveModel(conceptId) {
              modelValue = {
                concept_id: conceptId
              };
              model.save(modelValue, {
                success: function () {
                  deferredColl.resolve();
                },
                conflict: function () {
                  deferredColl.reject();
                }
              });
            }
            if (model.get("assigned")) {
              self.isNewEdit = false;
              if (model.get("assigned") === "1") {
                if (model.get("media_type") === "video") {
                  saveVideo(self.conceptID);
                } else {
                  saveModel(self.conceptID);
                }
              } else if (model.get("assigned") === "0" && !model.get("saved")) {
                self.isNewEdit = true;
                var conceptModel = new Concept({
                  action: "new"
                });
                conceptModel.save({
                  name: model.get("name"),
                  status: "1",
                  advertiser_id: formValues.advertiser_id || self.advertiserID
                }, {
                  success: $.proxy(function () {
                    model.set({
                      saved: true
                    });
                    deferred.resolve(conceptModel);
                    var conceptID = Number(conceptModel.id);
                    if (model.get("media_type") === "video") {
                      saveVideo(conceptID);
                    } else {
                      saveModel(conceptID);
                    }
                  })
                });
              }
              queue.push(deferred.promise);
            }
          });
          When.all(queue).then(function () {
            successCallback();
            if (self.isEdit && !self.isNewEdit) {
              T1.Notify("message", "Concept updated successfully!");
            } else if (self.isNewEdit) {
              T1.Notify("message", "Concept updated and created successfully!");
            } else {
              T1.Notify("message", "Concept created successfully!");
            }
          });
        }, this),
        statusInvalid: $.proxy(function (errors) {
          var batchedErrors = _.pluck(errors, "message");
          batchedErrors = batchedErrors.join("<br/>\n");
          deferred.reject(batchedErrors);
        }, this)
      });
      return deferred.promise;
    },
    setEditMode: function () {
      this.isEdit = true;
      this.updateModels({
        id: this.model.id,
        partial: false
      });
      T1.Location.set("#creatives/concept/" + this.model.id, {
        silent: true
      });
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          setTimeout(function () {
            T1.Location.set("#creatives/concepts");
          }, 1e3);
        }
      };
      this.saveType = "saveAndContinue";
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    cancelCreativeViewEdit: {
      onAlertClose: function () {
        T1.Location.set("#creatives/concepts");
      }
    },
    cancelOverride: function (e, fromUnloadAlertDlg) {
      var isFormElementsChanged = this.isFormElementsChanged;
      this.preventDefault(e);
      e.stopImmediatePropagation();
      if (fromUnloadAlertDlg === true || !isFormElementsChanged) {
        this.cancelCreativeViewEdit.onAlertClose();
      } else {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Concept Edit" : "New Concept"
        }, this.cancelCreativeViewEdit);
      }
    },
    canUnload: function () {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Concept Edit" : "New Concept"
        });
      }
      return !isFormElementsChanged;
    },
    noop: function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    },
    serialize: function () {
      var output = this.model.toJSON();
      output.isEdit = this.isEdit;
      if (this.isEdit) {
        output.advertiser_name = this.advertiserName;
      }
      if (output.action === "new" || output.status === "1") {
        output.active = true;
      }
      output.pageTitle = "Details";
      if (output.name) {
        output.title = output.name + " (" + output.id + ")";
      } else {
        output.title = "Create New Concept";
      }
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(CreativesCreateEdit);
});
