define('modules/audiences/onboarding/create/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "audiences/onboarding/create",
      viewCfgs: {
        onboarding: {}
      },
      getView: function (type, opts) {
        this.defaultView = type;
        return T1Module.prototype.getView.call(this, type, opts);
      }
    });
  }
  return instance;
});
