define('models/userPreferences',["jQuery", "T1", "T1Model", "T1UtilsAsync", "utils/UserDataUtils", "models/sessionUser", "T1Preferences", "T1Signature"], function ($, T1, T1Model, utilsAsync, UserDataUtils, User) {
  "use strict";

  var UserPreferences;
  if (T1.UserPreferences) {
    return T1.UserPreferences;
  }
  UserPreferences = T1Model.extend({
    name: "userpreferences",
    url: "users/",
    fixture: "-userpreferences",
    params: {
      q: "ui.*"
    },
    orgParams: {
      q: "ui.organizations.selected"
    },
    initializationPromise: null,
    initialize: function () {
      var userModel = User.getUser();
      var userPreferences = this;
      this.signature = T1.Signature.create();
      this.signature.set(new Map([["root", "ui"], ["access", "auth"]]));
      this.preferences = T1.Preferences.create.call(this, this.signature, this);
      this.initializationPromise = userModel.initialSessionPromise.then(function () {
        return utilsAsync.makePromise(function (resolve, reject) {
          userPreferences.fetch({
            success: resolve,
            error: reject,
            reloadPrefs: void 0
          });
        });
      }).catch($.noop);
    },
    reload: function (reloadPrefs) {
      this.initializationPromise = utilsAsync.makePromise((resolve, reject) => {
        this.fetch({
          success: resolve,
          error: reject,
          reloadPrefs: reloadPrefs
        });
      });
      return this.initializationPromise;
    },
    sync: function (method, model, options) {
      var self = this;
      var userModel = User.getUser();
      var defaultData = model.toJSON ? model.toJSON() : model;
      var actions = {
        org: function (data) {
          var org, settings;
          if (data.settings && data.settings.prop) {
            settings = data.settings.prop.length ? data.settings : {
              prop: [data.settings.prop]
            };
            org = T1.Utils.parseEntity(settings);
          }
          if (org && org["ui.organizations.selected"]) {
            self.orgId = org["ui.organizations.selected"];
          } else {
            self.orgId = true;
          }
          return self.sync("read", model, options);
        },
        read: function (data) {
          var pref, settings;
          if (data.settings && data.settings.prop) {
            settings = data.settings.prop.length ? data.settings : {
              prop: [data.settings.prop]
            };
            pref = T1.Utils.parseEntity(settings);
            UserDataUtils.addData("onPreferencesReceived", settings);
          }
          delete self.orgId;
          self.clear();
          if (pref) {
            self.set(pref);
          } else {
            self.trigger("change");
          }
          return options.success && options.success(data);
        },
        default: options.success || $.noop
      };
      var params = {
        org: self.orgParams,
        read: self.params
      };
      var userModelMethod = {
        org: "read",
        read: "read",
        default: "default"
      };
      var data = {
        org: "",
        read: "",
        default: options.reloadPrefs ? "" : $.param(defaultData)
      };
      if (method === "read") {
        if (typeof self.orgId === "string") {
          params.read.organization_id = self.orgId;
        } else if (params.read.organization_id) {
          delete params.read.organization_id;
        }
      }
      this.id = userModel.id;
      if (!userModel.id) {
        return;
      }
      if (method === "read" && !self.orgId) {
        self.sync("org", model, options);
      } else {
        userModel.sync.call(this, userModelMethod[method], userModel.toJSON(), {
          action: "settings",
          data: data[method] || data.default,
          params: params[method],
          async: false,
          fixture: model.fixture,
          responseTemplate: model.modelTemplate,
          onSuccess: actions[method] || actions.default,
          onError: options.onError || $.noop,
          onStatusInvalid: options.onStatusInvalid || $.noop
        });
      }
    }
  });
  T1.UserPreferences = new UserPreferences();
  return T1.UserPreferences;
});
