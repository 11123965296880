define('T1Truncate',["jQuery", "T1", "jQPlugins/jquery.dotdotdot"], function ($, T1) {
  T1.Truncate = function (context, configs) {
    var targetEls;
    var isMouseOver = false;
    var options = {
      ellipsis: "...",
      wrapper: "span",
      wrap: "letter",
      watch: "window"
    };
    var timeoutId;
    if (!configs || !configs.el) {
      return false;
    }
    context = context || "body";
    targetEls = $(configs.el, context);
    if (!targetEls.size()) {
      return false;
    }
    $.extend(options, configs);
    targetEls.trigger("destroy");
    targetEls.dotdotdot(options);
    targetEls.bind("update.dot", function () {
      var target = $(this);
      var isTruncated = target.triggerHandler("isTruncated");
      target.data("showtext", isTruncated);
    });
    if (!options.tooltip) {
      return true;
    }
    targetEls.on({
      mouseenter: function () {
        var target = targetEls;
        var displayText;
        isMouseOver = true;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
          if (isMouseOver) {
            target.trigger("update");
            displayText = target.triggerHandler("originalContent");
            if (target.data("showtext")) {
              target.attr("title", displayText[0].data);
            } else {
              target.prop("title", "");
            }
          }
        }, 300);
      },
      mouseleave: function () {
        clearTimeout(timeoutId);
        isMouseOver = false;
      }
    });
    return true;
  };
});
