define('modules/vendor/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  return new T1Module({
    name: "vendor",
    viewCfgs: {
      list: {},
      boxList: {},
      popup: {},
      autoList: {}
    },
    defaultView: "list"
  });
});
