define('utils/nextTabViewNavigation',["Underscore", "jQuery", "T1"], function (_, $, T1) {
  var NextTabViewNavigation = function ({
    hostView: hostView
  }) {
    function doNavigateToNextView() {
      var resultSet = getNextTabResult();
      if (resultSet && resultSet.found === true) {
        if (resultSet.tabSet.layoutObj.tabEl) {
          resultSet.tabSet.layoutObj.tabEl.tabs("select", resultSet.tabIndex);
        }
      }
    }
    function doNavigateToLastView() {
      var resultSet = getNextTabResult({
        lastView: true
      });
      if (resultSet && resultSet.found === true) {
        if (resultSet.tabSet.layoutObj.tabEl) {
          resultSet.tabSet.layoutObj.tabEl.tabs("select", resultSet.tabSet.tabs.length - 1);
        }
      }
    }
    function getNextTabResult(opts) {
      var currentLocation = T1.Location.getRoute();
      var view = hostView;
      var arrDeepLinks = currentLocation.deepLinks;
      var childTabName, childTabSet, lengthOfDeepLinks, parentTabName, parentTabSet;
      const PARENT_LEVEL = 2;
      if (!arrDeepLinks || arrDeepLinks.length <= 0) {
        return null;
      }
      lengthOfDeepLinks = arrDeepLinks.length;
      if (lengthOfDeepLinks === 1) {
        childTabName = arrDeepLinks[0];
        parentTabName = null;
      } else {
        childTabName = arrDeepLinks[lengthOfDeepLinks - 1];
        parentTabName = arrDeepLinks[lengthOfDeepLinks - PARENT_LEVEL];
      }
      if (parentTabName) {
        parentTabSet = findMatchingTabListByTabName(view, parentTabName);
        if (opts && opts.lastView) {
          return calculateNextTabSet(parentTabSet, undefined);
        }
        if (parentTabSet.found === true) {
          childTabSet = findMatchingTabListByTabName(parentTabSet.tabs[parentTabSet.targetIndex].view, childTabName);
        } else {
          childTabSet = findMatchingTabListByTabName(view, childTabName);
        }
      } else {
        childTabSet = findMatchingTabListByTabName(view, childTabName);
      }
      return calculateNextTabSet(childTabSet, parentTabSet);
    }
    function calculateNextTabSet(childTabSet, parentTabSet) {
      var resultSet;
      function getNextTabSetResult(tabSet) {
        var result = {
          found: false,
          tabSet: null,
          tabIndex: -1
        };
        var arrDisabledTabs, index;
        const {
          layoutObj: layoutObj
        } = tabSet;
        arrDisabledTabs = layoutObj && layoutObj.tabEl ? layoutObj.tabEl.tabs("option", "disabled") : [];
        for (index = tabSet.targetIndex + 1; index < tabSet.tabs.length; index++) {
          if ($.inArray(index, arrDisabledTabs) <= -1) {
            result.found = true;
            result.tabSet = tabSet;
            result.tabIndex = index;
            return result;
          }
        }
        return result;
      }
      if (childTabSet) {
        resultSet = getNextTabSetResult(childTabSet);
        if (resultSet.found === false && parentTabSet) {
          resultSet = getNextTabSetResult(parentTabSet);
        }
      }
      return resultSet;
    }
    function findMatchingTabListByTabName(startView, tabName) {
      function recursiveFind(currView, tabName) {
        var result = {
          found: false,
          tabs: [],
          targetIndex: 0,
          layoutObj: null
        };
        var i, list;
        if (!currView) {
          return result;
        }
        list = currView.layout && currView.layout.panels ? _.toArray(currView.layout.panels) : null;
        if (!list || list.length <= 0) {
          return result;
        }
        for (i = 0; i < list.length; i++) {
          if (list[i].routeName === tabName) {
            result.found = true;
            result.tabs = list;
            result.targetIndex = i;
            result.layoutObj = currView.layout;
            return result;
          }
        }
        for (i = 0; i < list.length; i++) {
          recursiveFind(list[i].view, tabName);
        }
      }
      return recursiveFind(startView, tabName);
    }
    return {
      navigateToNextView: doNavigateToNextView,
      navigateToLastView: doNavigateToLastView,
      getNextTabViewResult: getNextTabResult
    };
  };
  NextTabViewNavigation.attachBehavior = function (view) {
    var nextViewNav;
    if (view) {
      nextViewNav = new NextTabViewNavigation({
        hostView: view
      });
      if (!view.eventhubEvents) {
        view.eventhubEvents = {};
      }
      view.eventhubEvents["form:save-and-continue"] = function () {
        nextViewNav.navigateToNextView();
      };
      view.eventhubEvents["form:save-and-last-tab"] = function () {
        nextViewNav.navigateToLastView();
      };
    }
  };
  return NextTabViewNavigation;
});
