define('modules/campaign/bulkedit/targeting/mydata/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "collections/pixels", "T1Collection", "utils/CampaignStrategyUtils", "text!modules/campaign/bulkedit/targeting/mydata/templates/panel.html", "text!modules/targeting/pixel/templates/panel_layout.html", "text!modules/targeting/audience/templates/info_box.html", "text!templates/form_footer.html"], function ($, _, T1, T1View, T1Layout, Pixels, T1Collection, CampaignStrategyUtils, template, panel_layout, info_box, form_footer) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var maxPixels = COMPASS_ENV.TARGETING_MAX_PIXELS;
  const providerTaxTimeToRead = 6e3;
  const providerTaxMsgState = {
    isDisplayed: false
  };
  const providerTaxWarningMessage = `Sales taxes may be charged for Pixels from this provider.\n  This will be included in the MM Data Cost in reporting and invoices.`;
  var PixelPanel = T1View.extend({
    template: template,
    viewMode: "browse",
    formConfig: {
      bulkedit: true,
      bulkeditActionViews: true
    },
    partials: {
      form_footer: form_footer
    },
    dataEvents: {},
    providerTaxTimeout: null,
    eventhubEvents: {
      "assigned:pixel": "assignPixel",
      "strategy-create-edit:savedPixelBundels": "updateTempCollection",
      "strategyBulkEditMyData:bulkeditActionChanged": "toggleSubmitButton",
      "bins.providerTaxAdded": function () {
        if (this.providerTaxTimeout) {
          clearTimeout(this.providerTaxTimeout);
        }
        this.toggleUsageWarningInfo(true, {
          node: "providerTax"
        });
        this.providerTaxTimeout = setTimeout(() => {
          this.toggleUsageWarningInfo(false, {
            node: "providerTax"
          });
        }, providerTaxTimeToRead);
      }
    },
    events: {
      "click .usage-warning-info .close": "toggleUsageWarningInfo",
      "focus .text": "showTooltip",
      "blur .text": "hideTooltip",
      "keydown .text": "removeTooltip"
    },
    initialize: function ({
      bulkeditor: bulkeditor,
      campaign: campaign
    }) {
      this.bulkeditor = bulkeditor;
      this.campaign = campaign;
      this.sharedCollection = new T1Collection();
      this.campaign.getOrgId().then(orgId => {
        this.orgId = orgId;
      });
      this.advertiser = this.campaign.get("advertiser").toJSON();
      this.addDataEvent({
        sharedCollection: {
          add: function () {
            this.isFormElementsChanged = true;
            this.toggleSubmitButton();
          },
          remove: function () {
            this.isFormElementsChanged = true;
            this.toggleSubmitButton();
          },
          reset: function () {
            this.isFormElementsChanged = false;
            if (!this.layout.loaded) {
              this.layout.load();
            }
            this.load();
          }
        }
      });
      this.checkBulkeditorReady();
      T1.EventHub.publish("targetingTab:select", {
        type: "mydata"
      });
    },
    initializeViews: function () {
      this.initLayout();
    },
    toggleUsageWarningInfo: function (e, opts) {
      const defaultTopMargin = 10;
      const defaultSpacing = 25;
      let newTopMargin = defaultTopMargin;
      const canShow = e === true;
      const action = canShow ? "show" : "hide";
      const provider = opts && opts.node || e.currentTarget.parentNode.className.replace(/\s*(?:usage-warning-info|info-box)\s*/g, "");
      const $listBody = this.$(".list-c");
      const $infoEls = this.$(".usage-warning-info");
      const $providerTaxMsg = $infoEls.filter(".providerTax");
      if (typeof e === "object") {
        this.preventDefault(e);
      }
      switch (provider) {
        case "providerTax":
          providerTaxMsgState.isDisplayed = canShow;
          $providerTaxMsg[action]();
          break;
      }
      const providerTaxMsgHeight = providerTaxMsgState.isDisplayed ? $providerTaxMsg.height() : 0;
      const msgsDisplaying = [providerTaxMsgState].filter(state => state.isDisplayed);
      const spaceBelowInfo = msgsDisplaying.length ? defaultSpacing * msgsDisplaying.length : 0;
      newTopMargin += providerTaxMsgHeight + spaceBelowInfo;
      $listBody.css("top", `${newTopMargin}px`);
    },
    checkBulkeditorReady: function () {
      if (this.bulkeditor.editorReady) {
        this.retrieveStagedPixeIds();
        if (this.stagedPixels.ids.length) {
          this.prepareSharedCollection();
        } else {
          this.initializeViews();
        }
      } else {
        this.bulkeditor.getStatus({
          success: () => {
            this.retrieveStagedPixeIds();
            if (this.stagedPixels.ids.length) {
              this.prepareSharedCollection();
            } else {
              this.initializeViews();
              this.load();
            }
          }
        });
      }
    },
    toggleSubmitButton: function () {
      const $parents = this.el.parents();
      var $stageChangesBtn = $parents.find(".stage");
      var $stageAndReviewBtn = $parents.find(".stageAndReview");
      const isEnabled = this.sharedCollection.models.length && this.sharedCollection.bulkeditAction;
      if (!isEnabled) {
        $stageChangesBtn.addClass("disabled");
        $stageAndReviewBtn.addClass("disabled");
      } else {
        $stageChangesBtn.removeClass("disabled");
        $stageAndReviewBtn.removeClass("disabled");
      }
    },
    retrieveStagedPixeIds: function () {
      var stagedActions = this.bulkeditor.get("stagedActions");
      const stagedPixels = {
        ids: []
      };
      $.each(stagedActions, function (index, {
        tag: tag,
        add: add,
        remove: remove,
        overwrite: overwrite,
        update: update
      }) {
        if (tag === "mydata") {
          if (add) {
            stagedPixels.action = "add";
            stagedPixels.ids.push(add);
          } else if (remove) {
            stagedPixels.action = "remove";
            stagedPixels.ids.push(remove);
          } else if (overwrite) {
            stagedPixels.action = "overwrite";
            stagedPixels.ids.push(overwrite);
          } else if (update && update.pixel_target_include_operator) {
            stagedPixels.includeOperator = update.pixel_target_include_operator;
          }
        }
      });
      this.stagedPixels = stagedPixels;
    },
    prepareSharedCollection: function () {
      var bulkeditorIds = [];
      var pixels = new Pixels();
      const stagedPixels = this.stagedPixels;
      pixels.noUniquesData = true;
      for (let i = 0; i < stagedPixels.ids.length; i++) {
        const bulkeditorModel = stagedPixels.ids[i];
        bulkeditorIds.push(bulkeditorModel.id);
      }
      pixels.fetchOptions = $.extend({
        with: ["advertiser", "agency", "provider"],
        full: "*",
        sort_by: "name"
      }, pixels.fetchOptions);
      pixels.pageLimit = 100;
      pixels.isPaginated = false;
      pixels.search.set({
        idList: bulkeditorIds,
        fetchRequired: true,
        success: coll => {
          this.pixels = coll;
          coll.bulkeditAction = stagedPixels.action;
          coll.includeOperator = stagedPixels.includeOperator;
          this.sharedCollection = pixels;
          for (let j = 0; j < stagedPixels.ids.length; j++) {
            const bulkeditorModel = stagedPixels.ids[j];
            if (this.sharedCollection.get(bulkeditorModel.id)) {
              const sharedCollectionModel = this.sharedCollection.get(bulkeditorModel.id);
              sharedCollectionModel.set({
                action: bulkeditorModel.exclude_flag === 0 ? "include" : "exclude"
              });
            }
          }
          this.initializeViews();
          this.load();
        }
      });
    },
    showTooManyPixelsMsg: function () {
      this.updateFooterMessage({
        warning: true,
        message: `You may not make more than ${maxPixels} selections.`
      });
    },
    assignPixel: function (data) {
      var sharedCollection = this.sharedCollection;
      var selectedList = data.selectedList;
      this.updateFooterMessage({
        warning: false,
        message: ""
      });
      if (selectedList) {
        sharedCollection.remove(selectedList);
        if (sharedCollection.length < maxPixels) {
          sharedCollection.add(selectedList);
          this.isFormElementsChanged = true;
          this.checkUnsavedState();
        } else {
          this.showTooManyPixelsMsg();
        }
      } else {
        const itemInSharedCollection = sharedCollection.get(data.id);
        const newAction = data.action || data.get("action");
        let oldAction;
        if (itemInSharedCollection) {
          oldAction = itemInSharedCollection.get("action");
        }
        sharedCollection.remove(data);
        if (oldAction && newAction === oldAction) {
          return;
        }
        if (sharedCollection.length < maxPixels) {
          sharedCollection.add(data);
          this.isFormElementsChanged = true;
          this.checkUnsavedState();
        } else {
          this.showTooManyPixelsMsg();
        }
      }
    },
    load: function () {
      this.render().then(() => {
        const $entityInput = this.$(".select-input");
        T1.EventHub.publish("strategy-badges-edit:placementCheck", {
          currentView: "pixel_targeting"
        });
        T1.Selectbox($entityInput, {
          onChange: () => {
            const term = this.$(".term input").val().trim();
            const providerID = this.$(".providers").val();
            const minSearchLength = 2;
            if (term.length >= minSearchLength) {
              T1.EventHub.publish("searchAudience", {
                term: term,
                provider_id: providerID
              });
            }
          }
        });
        if (this.layout) {
          this.layout.load().then(() => {
            this.$(".search-c").hide();
            T1.EventHub.publish("bulkEdit:hideLoadingIcon");
          });
          this.loaded = true;
          this.toggleSubmitButton();
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn();
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    updateTempCollection: function () {
      const tempColl = {
        data: [],
        includeOp: this.strategy.pixel_bundles.includeOperator
      };
      const origColl = this.strategy.pixel_bundles;
      origColl.each(function (pixelModel) {
        tempColl.data.push(pixelModel.clone());
      });
      this.sharedCollection.tempCollection = tempColl;
    },
    refreshDDL: function () {
      this.renderPartial(".select-input");
      this.$(".select-input").trigger("liszt:updated");
    },
    initLayout: function () {
      const advertiser = this.advertiser;
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panel_layout,
        partials: {
          info_box: info_box
        },
        layout: {
          ".column-A": [{
            module: "campaign/bulkedit/targeting/mydata",
            viewType: "list",
            options: {
              orgId: this.orgId,
              searchViewType: "filterSearch",
              sharedCollection: this.sharedCollection,
              allowMultipleSelection: true,
              strategy: this.strategy,
              campaign: this.campaign,
              getAdvertiser: () => advertiser,
              getAdvertiserId: () => advertiser.id,
              getAdvertiserName: () => advertiser.name
            }
          }],
          ".bins-column": [{
            module: "campaign/bulkedit/targeting/mydata",
            viewType: "bins",
            options: {
              sharedCollection: this.sharedCollection
            }
          }]
        },
        serialize: function () {
          return {
            msgForProviderTax: {
              infoBoxMessageLines: providerTaxWarningMessage,
              infoMessageCanClose: true,
              infoBoxClassName: "usage-warning-info providerTax no-display"
            }
          };
        }
      });
    },
    reload: function () {
      T1.EventHub.publish("targetingTab:select", {
        type: "mydata"
      });
      this.checkBulkeditorReady();
    },
    unload: function () {
      T1.EventHub.publish("bulkEdit:showLoadingIcon");
      this.bulkeditor.editorReady = false;
      this.sharedCollection.reset([], {
        silent: true
      });
      this.collection.reset([], {
        silent: true
      });
      this.loaded = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              isBulkEdit: true,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertBulkeditDialog({}, onTabChange);
      }
      return !isFormElementsChanged;
    },
    onDontSave: function () {
      this.sharedCollection.reset([]);
    },
    onVersionConflictClose: function () {
      this.sharedCollection.reset([]);
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    saveOverride: function (e, callbacks) {
      if (this.sharedCollection.length <= maxPixels) {
        return this.saveOriginal(e, callbacks);
      }
      this.showTooManyPixelsMsg();
      if (callbacks && callbacks.invalid) {
        callbacks.invalid();
      }
    },
    prepareStagingData: function () {
      const pixelData = {
        ids: []
      };
      if (!this.sharedCollection.length) {
        return false;
      }
      this.sharedCollection.each(model => {
        pixelData.ids.push({
          id: model.get("id"),
          pixelAction: model.get("action"),
          includeOperator: this.sharedCollection.includeOperator
        });
      });
      pixelData.bulkeditAction = this.sharedCollection.bulkeditAction;
      pixelData.collection = "pixel_bundles";
      pixelData.tag = "mydata";
      if (this.sharedCollection.includeOperator) {
        pixelData.ids.push({
          update: {
            pixel_target_include_operator: this.sharedCollection.includeOperator
          }
        });
        pixelData.ids.push({
          update: {
            pixel_target_exclude_operator: "OR"
          }
        });
      }
      return pixelData;
    },
    stage: function (successCallback, invalidCallback) {
      var stagingData = this.prepareStagingData();
      if (!stagingData) {
        invalidCallback();
        return;
      }
      this.bulkeditor.stageActions(stagingData, {
        success: function (newModel, attributes) {
          if (!attributes.entity.execution_errors.length) {
            T1.EventHub.publish("stageActionsSuccess");
            T1.Notify("message", "Pixels have been staged successfully!");
            successCallback();
          }
        }
      });
    }
  });
  PixelPanel = T1.Form.ViewUtils.extend(PixelPanel);
  PixelPanel.prototype.saveOriginal = PixelPanel.prototype.save;
  PixelPanel.prototype.save = PixelPanel.prototype.saveOverride;
  return PixelPanel;
});
