define('modules/reporting/campaigns/dataExport/createEdit/views/attributionGroup',["jQuery", "T1", "T1View", "text!../templates/attributionGroup.html"], function ($, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "changed .attribution-group-selector": "selectAttribution"
    },
    initialize: function (model) {
      this.DataExportModel = model;
    },
    load: function () {
      var $el = this.el;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
      });
    },
    selectAttribution: function (evt) {
      const selection = evt.originalEvent.detail.value;
      this.DataExportModel.setSelectedAttribution(selection);
      T1Publish("attributionGroup.toggleInfo", selection);
      T1Publish("change:formElements", true);
    },
    serialize: function () {
      return {
        selectedAttribution: this.DataExportModel.getSelectedAttribution()
      };
    }
  });
});
