define('modules/campaign/views/copyStrategiesGrid',["jQuery", "Underscore", "T1", "T1View", "T1GridView", "text!modules/campaign/templates/copyStrategiesGrid.html", "T1Collection", "collections/strategies"], function ($, _, T1, T1View, T1GridView, grid_item, T1Collection, Strategies) {
  "use strict";

  var CopyStrategiesGrid = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Users",
        contentClass: "search-loader loader-overrides admin-infinite",
        action: "append"
      }
    },
    truncateConfig: {
      ".truncate": {
        lines: 1
      }
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "checkbox-input-field",
      label: '<strand-checkbox class="checkbox all"></strand-checkbox>'
    }, {
      name: "current-strategy-name",
      label: "Current Strategy Name"
    }, {
      name: "id",
      label: "ID"
    }, {
      name: "no-of-copies",
      label: "# of copies"
    }, {
      name: "new_strategy_name",
      label: "New Strategy Name"
    }],
    events: {
      "click .headers .checkbox": "handleAllChecked",
      "click .item .checkbox": "handleItemChecked",
      "click .new-strategy-text": "clearValidation",
      "click .no-of-copies-input": "applyValidationForNoOfCopies",
      "keypress .no-of-copies-input": "keyPressClicked"
    },
    dataEvents: {
      collection: {
        "change:selected": function (model) {
          this.updateItemCheckbox(model);
          this.updateAllCheckbox(model);
        },
        reset: function () {
          this.load();
        }
      }
    },
    eventhubEvents: {
      "strategyCopyGrid:reload": function (args) {
        this.initalLoad = false;
        this.loadStrategies(args);
      },
      "campaign:copiedSuccessfully": "duplicateSelectedStrategies",
      "strategyCopyGrid:resetStrategySelection": "resetStrategySelection",
      "strategyCopyGrid:search": function (searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        if (!this.browseModels) {
          this.browseModels = this.collection.models;
        }
        if (searchTerm === "") {
          this.collection.reset(this.browseModels);
        } else {
          const searchResults = _.filter(this.collection.models, function (model) {
            var name = model.get("name").toLowerCase();
            const hasTerm = name.includes(searchTerm);
            return hasTerm;
          });
          this.collection.reset(searchResults);
        }
      }
    },
    initialize: function ({
      model: model
    }) {
      this.model = model;
      this.initalLoad = true;
      this.loadStrategies(true);
    },
    keyPressClicked: function (evt) {
      const keyCode = evt.which ? evt.which : evt.keyCode;
      const keyForModeChange = 31;
      const keyCodeForZero = 48;
      const keyCodeForNine = 57;
      return !(keyCode > keyForModeChange && (keyCode < keyCodeForZero || keyCode > keyCodeForNine));
    },
    applyValidationForNoOfCopies: function (e) {
      const noOfCopies = parseInt(e.currentTarget.value, 10);
      const maxNoOfCopiesLimit = 100;
      const defaultCopies = 1;
      if (e.currentTarget.error === true) {
        if (noOfCopies > maxNoOfCopiesLimit) {
          $(e.currentTarget).attr("title", "Only 100 copies of a strategy can be made at a time.");
        } else if (noOfCopies < defaultCopies) {
          $(e.currentTarget).attr("title", "The number of copies must be greater than 0.");
        }
        $(e.currentTarget).attr("hasTooltip", "true");
        T1.Tooltip(this.el, {
          className: "w-FormError",
          delayIn: 0,
          delayOut: 0,
          fade: true,
          getExternalTip: false,
          gravity: "s",
          html: true,
          offset: 10,
          tooltipEle: ".no-of-copies-input",
          trigger: "manual"
        });
        $(e.currentTarget).tipsy("show");
        this.el.parents().find("#duplicateCampaignFooter")[0].hideMessage();
      }
    },
    clearValidation: function (e) {
      if (e.currentTarget.error === true) {
        $(e.currentTarget).attr("title", "This is a required field.");
        $(e.currentTarget).attr("hasTooltip", "true");
        T1.Tooltip(this.el, {
          className: "w-FormError",
          delayIn: 0,
          delayOut: 0,
          fade: true,
          getExternalTip: false,
          gravity: "s",
          html: true,
          offset: 10,
          tooltipEle: ".new-strategy-text",
          trigger: "manual"
        });
        $(e.currentTarget).tipsy("show");
        this.el.parents().find("#duplicateCampaignFooter")[0].hideMessage();
      }
    },
    duplicateSelectedStrategies: function (newCampaign) {
      var self = this;
      var strategies = this.collection;
      var newCampaignId = newCampaign.entity.id;
      var newCampaignName = newCampaign.entity.name;
      const saveObj = new T1Collection({
        fetchOptions: {}
      });
      function buildStrategiesPostObj(selectedStrategies) {
        const strategiesPostObj = {};
        $.each(selectedStrategies, function (index, model) {
          const modelId = model.get("id");
          const gridItem = self.$(`[data-id='${modelId}']`).closest(".item");
          let inputValue = gridItem.find(".new-strategy-text").attr("value");
          const noOfCopies = gridItem.find(".no-of-copies-input").attr("value");
          const settings = newCampaign.strategySettings;
          index++;
          if (inputValue === "") {
            inputValue = model.get("name");
          }
          strategiesPostObj[`strategies.${index}.id`] = modelId;
          strategiesPostObj[`strategies.${index}.name`] = inputValue;
          strategiesPostObj[`strategies.${index}.copies`] = noOfCopies;
          strategiesPostObj[`strategies.${index}.audience_targeting`] = settings.audience_targeting ? 1 : 0;
          strategiesPostObj[`strategies.${index}.contextual_targeting`] = settings.contextual_targeting ? 1 : 0;
          strategiesPostObj[`strategies.${index}.creative_assignment`] = settings.creative_assignment ? 1 : 0;
          strategiesPostObj[`strategies.${index}.day_parts`] = settings.day_parts ? 1 : 0;
          strategiesPostObj[`strategies.${index}.location`] = settings.location ? 1 : 0;
          strategiesPostObj[`strategies.${index}.pixel_targeting`] = settings.pixel_targeting ? 1 : 0;
          strategiesPostObj[`strategies.${index}.site_lists`] = settings.site_lists ? 1 : 0;
          strategiesPostObj[`strategies.${index}.supply_management`] = settings.supply_management ? 1 : 0;
          strategiesPostObj[`strategies.${index}.technology`] = settings.technology ? 1 : 0;
          strategiesPostObj[`strategies.${index}.video_targeting`] = settings.video_targeting ? 1 : 0;
        });
        return strategiesPostObj;
      }
      const selectedStrategies = strategies.filter(model => model.get("selected"));
      if (selectedStrategies && selectedStrategies.length) {
        const postObj = {
          data: {
            from_campaign_id: newCampaign.from_campaign_id,
            to_campaign_id: newCampaignId
          }
        };
        $.extend(postObj.data, buildStrategiesPostObj(selectedStrategies));
        saveObj.isPaginated = false;
        saveObj.url = function () {
          return `${T1.API_ROOT}strategies/bulk_copy`;
        };
        saveObj.save(postObj, {
          action: "bulk_copy",
          success: function (coll, data) {
            if (data.special_errors) {
              T1.EventHub.publish("campaign:strategiesCopiedError", data);
            } else {
              const strategiesCount = data.entities.entity.length || "1";
              T1.Notify("message", `Campaign ${newCampaignName} with ${strategiesCount} strategies was duplicated successfully!`);
              T1.EventHub.publish("campaign:strategiesCopiedSuccessfully");
              T1.Location.set(`#campaign/edit/${newCampaignId}/general`);
            }
          },
          statusInvalid: function (coll, data) {
            T1.EventHub.publish("campaign:strategiesCopiedError", data);
          },
          onError: function (resp) {
            var jsonData = T1.Utils.parseXMLtoJson($.parseXML(resp));
            T1.EventHub.publish("campaign:strategiesCopiedError", jsonData);
          }
        });
      } else {
        T1.Notify("message", `Campaign ${newCampaignName} with 0 strategies was duplicated successfully!`);
        T1.EventHub.publish("campaign:strategiesCopiedSuccessfully");
        T1.Location.set(`#campaign/edit/${newCampaignId}/general`);
      }
    },
    loadStrategies: function (args) {
      var self = this;
      var strategies = new Strategies();
      this.setSearchLoader();
      this.loadingActiveStrategiesOnly = args;
      this.browseModels = undefined;
      strategies.urlFilter.set({
        entity: "campaign",
        id: this.model.get("id")
      });
      if (args) {
        strategies.search.set({
          params: [{
            term: "1",
            searchField: "status"
          }],
          fetchRequired: false
        });
      }
      strategies.fetchOptions = {
        full: "*"
      };
      strategies.isPaginated = false;
      strategies.singleFetch = true;
      strategies.fetchAllPages({
        success: function () {
          const containsVideo = strategies.models.some(item => item.get("media_type") === "VIDEO");
          const publishObj = {
            containsVideo: containsVideo,
            initalLoad: self.initalLoad
          };
          T1.EventHub.publish("strategy:containsVideo", publishObj);
          if (strategies.models.length) {
            strategies.models.sort(function (a, b) {
              if (a.get("name") < b.get("name")) {
                return -1;
              }
              if (a.get("name") > b.get("name")) {
                return 1;
              }
              return 0;
            });
          }
          self.collection = strategies;
          self.toggleAllSelected(false);
          self.isLoaded = true;
          if (!self.initalLoad) {
            self.unbindDataEvents();
            self.addDataEvent(self.dataEvents);
          }
          self.load();
        }
      });
    },
    load: function () {
      if (this.isLoaded) {
        this.render();
      }
    },
    updateActive: function (model) {
      var id = model.get("id");
      var active = model.get("active");
      const targetDiv = `div[id="id${id}"]`;
      const $item = this.$(`${targetDiv} .item`);
      var $config = $item.find(".config");
      if (active === "0") {
        $item.addClass("inactive");
      } else {
        $item.removeClass("inactive");
      }
      this.showOrApplySettingsMenu($config);
    },
    deactivateHandler: function (e) {
      this.preventDefault(e);
      this.activationHandler(false, e);
    },
    activateHandler: function (e) {
      this.preventDefault(e);
      this.activationHandler(true, e);
    },
    activationHandler: function (toActivate, e) {
      let loader;
      var $currentTarget = $(e.currentTarget);
      var id = $currentTarget.closest("dl").data("id");
      const active = toActivate === true ? "1" : "0";
      function stopLoader() {
        loader.stop();
      }
      if (id) {
        const model = this.collection.get(id);
        const targetDiv = `div[id="id${id}"]`;
        loader = T1.Loader.create({
          contentClass: "inline-edit-loader",
          target: targetDiv,
          spinnerConfig: {
            lines: 10,
            length: 4,
            radius: 3,
            left: 15,
            top: 4
          }
        });
        loader.start();
        model.save({
          active: active
        }, {
          success: stopLoader,
          conflict: stopLoader
        });
        if (this.users.isActive === "true") {
          this.activationUpdated = true;
        }
      }
    },
    handleItemChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      const $target = $(e.target);
      const id = $target.data("id");
      const model = this.collection.get(id);
      const selected = model.get("selected");
      model.set({
        selected: !selected
      });
    },
    toggleAllSelected: function (isSelected) {
      this.collection.each(function (model) {
        model.set({
          selected: isSelected
        });
      });
    },
    resetStrategySelection() {
      this.toggleAllSelected(false);
    },
    getSelected: function () {
      return this.collection.filter(model => model.get("selected"));
    },
    handleAllChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      const selected = this.getSelected();
      if (this.collection.length === selected.length) {
        this.toggleAllSelected(false);
      } else {
        this.toggleAllSelected(true);
      }
    },
    unload: function () {
      this.unbindEventHubEvents();
    },
    updateItemCheckbox: function (model) {
      const $checkbox = this.el.find(`#id${model.get("id")} .checkbox`);
      if (model.get("selected")) {
        $checkbox.attr("checked", true);
      } else {
        $checkbox.attr("checked", false);
      }
    },
    updateAllCheckbox: function () {
      const $allCheckBox = this.el.find(".header .checkbox");
      const selected = this.getSelected();
      if (this.collection.length === selected.length) {
        $allCheckBox.attr("state", "checked");
      } else if (selected.length) {
        $allCheckBox.attr("state", "partial");
      } else {
        $allCheckBox.attr("state", "unchecked");
      }
    },
    serialize: function (inputCollection = this.collection) {
      const models = inputCollection.toJSON() || [];
      var list = [];
      function processData({
        id: id,
        name: name,
        status: status,
        selected: selected
      }) {
        return {
          id: id,
          entityID: id,
          name: name,
          noOfCopies: 1,
          newName: `${name}_copy`,
          is_active: status === "1",
          is_selected: selected
        };
      }
      for (let i = 0; i < models.length; i++) {
        list.push(processData(models[i]));
      }
      const output = {
        list: list
      };
      if (!list.length) {
        if (this.loadingActiveStrategiesOnly) {
          output.customNoResultMessage = "No active strategies available";
        } else {
          output.customNoResultMessage = "No strategies available";
        }
      } else {
        output.customNoResultMessage = false;
      }
      return output;
    }
  });
  return T1GridView(CopyStrategiesGrid);
});
