define('modules/admin/myOrgs/agency/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "admin/myOrgs/agency",
      viewCfgs: {
        createEdit: {}
      },
      defaultView: "createEdit"
    });
  }
  return instance;
});
