define('modules/strategy/supply/pmpd/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "strategy/supply/pmpd",
    viewCfgs: {
      bins: {},
      browse: {},
      panel: {}
    },
    defaultView: "panel"
  });
});
