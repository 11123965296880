define('modules/reporting/campaigns/dataExport/createEdit/main',["jQuery", "T1Module"], function ($, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "createEdit",
    name: "reporting/campaigns/dataExport/createEdit",
    viewCfgs: {
      additionalFilters: {},
      attributionGroup: {},
      columnSelector: {},
      dateRange: {},
      dimensionsMetrics: {},
      emailSettings: {},
      fileName: {},
      fileType: {},
      filter: {},
      filterBin: {},
      filterGrid: {},
      footer: {},
      reportType: {}
    }
  });
});
