define('modules/changelog/views/grid',["jQuery", "T1", "T1View", "../utils/mapper", "text!../templates/grid_item.html", "T1GridView"], function ($, T1, T1View, ChangeLogMapper, gridItem, createGrid) {
  "use strict";

  var changeLog = T1View.extend({
    partials: {
      listItem: gridItem
    },
    headers: [{
      name: "field-name",
      label: "Field Name"
    }, {
      name: "old-value",
      label: "Old Value"
    }, {
      name: "new-value",
      label: "New Value"
    }],
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 8,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    loaderOpts: {
      action: "append",
      contentClass: "search-loader",
      target: ".content-c",
      text: "loading"
    },
    perfectScrollConfig: {
      allowWheelPropagation: false
    },
    truncateConfig: {
      ".name, .change-by-c": {},
      ".truncate": {
        fill: " &hellip; ",
        lines: 2
      }
    },
    dataEvents: {
      collection: {
        reset: "reload"
      }
    },
    eventhubEvents: {
      "search:changelog": "search",
      "clear-search:changelog": "search"
    },
    initialize: function (options) {
      this.entityType = options.entityType;
      this.collection.fetchAllPages({
        pageLimit: 100,
        silent: true,
        success: $.proxy(this.reload, this),
        wrapper: "log_entries"
      });
    },
    search: function (term) {
      if (term) {
        this.searchTerm = term;
      } else {
        delete this.searchTerm;
      }
      this.load();
    },
    load: function () {
      this.render();
      if (!this.collection.loaded) {
        this.setSearchLoader();
      }
    },
    unload: function () {
      delete this.searchTerm;
    },
    serialize: function () {
      return ChangeLogMapper(this.entityType, this.collection, this.searchTerm);
    }
  });
  return createGrid(changeLog);
});
