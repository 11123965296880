define('collections/vendors',["Underscore", "T1", "T1Collection", "T1Sortable", "T1Model"], function (_, T1, T1Collection, Sortable, T1Model) {
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: T1Model,
    urlString: "vendors",
    fixture: "-vendors",
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        },
        is_eligible: {
          type: "enum"
        },
        mm_contract_available: {
          type: "enum"
        },
        vendor_type: {
          type: "enum"
        }
      }
    },
    initialize: function () {
      T1Collection.prototype.initialize.call(this);
      this.fetchOptions.full = "*";
    },
    getFormValues: function (formValues, entityType) {
      var result = formValues || {};
      var counter = 0;
      var vendorID;
      var vendorArray = [];
      var isVideo;
      if (entityType === "video") {
        isVideo = true;
      }
      if (this.length === 0) {
        if (!isVideo) {
          result["vendors.1.id"] = "";
        } else {
          result.vendors = "";
        }
      } else {
        this.each(function (model) {
          vendorID = model.get("id");
          if (vendorID) {
            if (isVideo) {
              vendorArray[counter] = vendorID;
              counter++;
            } else {
              counter++;
              result["vendors." + counter + ".id"] = vendorID;
            }
          }
        });
        if (isVideo) {
          result = Array(vendorArray);
        }
      }
      return result;
    }
  });
  T1.Models.Vendors = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.Vendors;
});
