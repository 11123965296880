define('modules/reporting/campaigns/dataExport/createEdit/models/filterGridModel',["jQuery", "Backbone", "Underscore", "T1"], function ($, Backbone, _, T1) {
  "use strict";

  const ADVERTISER_LEVEL = 1;
  const AGENCY_LEVEL = 0;
  const CAMPAIGN_LEVEL = 2;
  const CENTER_INDENTATION = 260;
  const NO_DATA_LEVEL = 13;
  const PIXEL_INDENTATION = 20;
  return Backbone.Model.extend({
    initialize: function (attributes, options) {
      this.SharedModel = options.sharedModel;
    },
    entityPluralLookup: function (level) {
      let entities;
      const selectedReport = this.SharedModel.getSelectedReport();
      switch (level) {
        case AGENCY_LEVEL:
          entities = "agencies";
          break;
        case ADVERTISER_LEVEL:
          entities = selectedReport === "Data Pixel Loads" ? "pixels" : "advertisers";
          break;
        case CAMPAIGN_LEVEL:
          entities = selectedReport === "Audience Index Pixel" || selectedReport === "Event Pixel Loads" ? "pixels" : "campaigns";
          break;
      }
      return entities;
    },
    entityLoadLookup: function (level) {
      let entity;
      const selectedReport = this.SharedModel.getSelectedReport();
      switch (level) {
        case "0":
          entity = selectedReport === "Data Pixel Loads" ? "pixel" : "advertiser";
          break;
        case "1":
          entity = selectedReport === "Audience Index Pixel" || selectedReport === "Event Pixel Loads" ? "pixel" : "campaign";
          break;
      }
      return entity;
    },
    entityLevelLookup: function (entity) {
      let level;
      switch (entity) {
        case "agency":
          level = AGENCY_LEVEL;
          break;
        case "advertiser":
          level = ADVERTISER_LEVEL;
          break;
        case "campaign":
          level = CAMPAIGN_LEVEL;
          break;
        case "pixel":
          level = this.SharedModel.getSelectedReport() === "Data Pixel Loads" ? ADVERTISER_LEVEL : CAMPAIGN_LEVEL;
          break;
      }
      return level;
    },
    setClickItem: function (id, level, name) {
      this.set({
        clickItem: {
          id: id,
          level: Number(level),
          name: name
        }
      });
    },
    setFilterEntity: function (level) {
      this.set({
        filterEntity: this.entityLoadLookup(level)
      });
    },
    getFilterEntity: function () {
      return this.get("filterEntity") || "agency";
    },
    setSearchEntity: function (selected) {
      this.set({
        searchEntity: selected.toLowerCase()
      });
    },
    getSearchEntity: function () {
      return this.get("searchEntity") || "advertiser";
    },
    getAllSelected: function () {
      const gridData = this.searchMode ? this.getSearchData() : this.getGridData();
      const topItems = _.filter(gridData, dataItem => dataItem.level === 0);
      const deselectedItems = _.filter(topItems, topItem => topItem.iconState === "deselected");
      return topItems.length === deselectedItems.length;
    },
    deselectAll: function () {
      const gridData = this.getGridData();
      _.each(gridData, item => {
        this.updateIconState(item.id, "selected", null, true);
      });
      this.trigger("update:selectAllBtn");
    },
    deselectItems: function (ids) {
      const idArr = ids.split(",");
      _.each(idArr, id => {
        this.updateIconState(id, "selected", null, true);
      });
      this.trigger("update:selectAllBtn");
    },
    addGridData: function (models) {
      let groupName, hasChildren, insertionIndex, model, modelID, modelName, newDataItem, parentSelected, prevSelected, topParentID;
      let gridData = this.getGridData();
      const SharedModel = this.SharedModel;
      const newData = [];
      const clickItem = this.get("clickItem");
      const entity = models[0] && models[0].attributes.type || "";
      const type = entity.replace("pixel_bundle", "pixel");
      const insertionLevel = clickItem && clickItem.level + 1 || 0;
      const clickID = clickItem && clickItem.id;
      const clickName = clickItem && clickItem.name;
      const binData = SharedModel.getBinItems();
      const isTopLevel = insertionLevel === 0;
      const selectedReport = SharedModel.getSelectedReport();
      function findPrevSelected() {
        let prevState;
        _.each(binData, function (item) {
          if (item.id) {
            switch (true) {
              case item.type === "organization":
                prevState = {
                  iconState: "selected-outline",
                  iconType: "include-outline"
                };
                break;
              case item.id === modelID:
                prevState = {
                  iconState: "selected",
                  iconType: "include"
                };
                break;
              case item.parentID === modelID:
              case item.topParentID === modelID:
                prevState = {
                  iconState: "selected-partial",
                  iconType: "partial-include"
                };
                break;
            }
          }
        });
        return prevState;
      }
      if (models.length) {
        while (models.length) {
          model = models.shift();
          modelID = model.id;
          modelName = model.attributes.name;
          prevSelected = findPrevSelected();
          hasChildren = selectedReport === "Data Pixel Loads" && insertionLevel === 1 || selectedReport !== "Data Pixel Loads" && insertionLevel === CAMPAIGN_LEVEL ? "none" : "block";
          newDataItem = {
            groupName: isTopLevel ? modelName : "",
            hasChildren: hasChildren,
            iconState: prevSelected ? prevSelected.iconState : "deselected",
            iconType: prevSelected ? prevSelected.iconType : "include",
            id: modelID,
            isExpanded: "",
            level: insertionLevel,
            levelIndent: insertionLevel * PIXEL_INDENTATION,
            name: modelName,
            parentID: isTopLevel ? modelID : "",
            parentName: isTopLevel ? modelName : "",
            path: "",
            showIncludeIcon: "block",
            topParentID: isTopLevel ? modelID : "",
            type: type
          };
          newData.push(newDataItem);
        }
      } else {
        newDataItem = {
          groupName: "",
          hasChildren: "none",
          iconState: "",
          iconType: "",
          id: "",
          isExpanded: "",
          level: NO_DATA_LEVEL,
          levelIndent: CENTER_INDENTATION,
          name: `No ${this.entityPluralLookup(insertionLevel)} exist`,
          parentID: "",
          parentName: "",
          path: "",
          showIncludeIcon: "none",
          topParentID: "",
          type: type
        };
        newData.push(newDataItem);
      }
      if (gridData.length) {
        _.each(gridData, function (item, index) {
          if (item.id === clickID) {
            item.isExpanded = "level-expanded";
            item.children = newData;
            insertionIndex = index + 1;
            parentSelected = item.iconState === "selected" || item.iconState === "selected-outline";
            topParentID = item.topParentID;
            groupName = item.groupName;
          }
        });
        _.each(newData, function (item, index) {
          if (parentSelected && item.id) {
            item.iconState = "selected-outline";
            item.iconType = "include-outline";
          }
          item.groupName = groupName;
          item.parentID = clickID;
          item.parentName = clickName;
          item.topParentID = topParentID;
          if (topParentID === clickID) {
            item.title = `${item.groupName} » ${item.name}`;
            item.path = "";
          } else {
            item.title = `${item.groupName} » ${item.parentName} » ${item.name}`;
            item.path = `${item.parentName} « `;
          }
          if (!item.pathReversed) {
            item.path = T1.Utils.reverseString(item.path);
            item.pathReversed = true;
          }
          gridData.splice(insertionIndex + index, 0, item);
        });
      } else {
        gridData = newData;
      }
      this.set({
        gridData: gridData
      }, {
        silent: true
      });
      this.trigger("change:gridData", this);
      this.trigger("update:selectAllBtn");
    },
    removeGridData: function () {
      let extractionIndex;
      let childrenToExtract = 0;
      const clickID = this.get("clickItem").id;
      const gridData = this.getGridData();
      function traverseChildren(children) {
        if (children) {
          while (children.length) {
            childrenToExtract++;
            traverseChildren(children.shift().children);
          }
        }
      }
      $.each(gridData, function (index, item) {
        if (item.id === clickID) {
          extractionIndex = index + 1;
          traverseChildren(item.children);
          item.children = [];
          item.isExpanded = "";
        }
      });
      gridData.splice(extractionIndex, childrenToExtract);
      this.set({
        gridData: gridData
      }, {
        silent: true
      });
      this.trigger("change:gridData", this);
    },
    getGridData: function () {
      return $.extend([], this.get("gridData"));
    },
    setSearchTerm: function (term) {
      this.searchMode = term !== "";
      this.set({
        searchTerm: term
      });
    },
    addSearchData: function (models) {
      let advertiser, agency, groupName, model, modelID, modelName, name, newDataItem, parentID, parentName, path, prevSelected, searchPath, title, topParentID;
      const searchTerm = this.get("searchTerm");
      const searchRegex = new RegExp(`(${searchTerm})`, "i");
      const newData = [];
      const entity = models[0] && models[0].attributes.type || "";
      const type = entity.replace("pixel_bundle", "pixel");
      const entityLevel = this.entityLevelLookup(type);
      const binData = this.SharedModel.getBinItems();
      function findPrevSelected(currentModel) {
        let prevState;
        const implicitSelection = {
          iconState: "selected-outline",
          iconType: "include-outline"
        };
        _.each(binData, function (item) {
          if (item.id) {
            switch (true) {
              case item.type === "organization":
                prevState = implicitSelection;
                break;
              case item.id === modelID:
                prevState = {
                  iconState: "selected",
                  iconType: "include"
                };
                break;
              case item.parentID === modelID:
              case item.topParentID === modelID:
                prevState = {
                  iconState: "selected-partial",
                  iconType: "partial-include"
                };
                break;
              case Boolean(currentModel.advertiser):
                if (item.id === currentModel.advertiser.id) {
                  prevState = implicitSelection;
                }
                if (currentModel.advertiser.agency) {
                  if (item.id === currentModel.advertiser.agency.id) {
                    prevState = implicitSelection;
                  }
                }
                break;
              case Boolean(currentModel.agency):
                if (item.id === currentModel.agency.id) {
                  prevState = implicitSelection;
                }
                break;
            }
          }
        });
        return prevState;
      }
      function highlightTerm(mName) {
        return _.map(mName.split(searchRegex), function (fragment) {
          return {
            highlightClass: fragment.toLowerCase() === searchTerm.toLowerCase() ? "highlighted-term" : "",
            name: fragment
          };
        });
      }
      if (models.length) {
        while (models.length) {
          model = models.shift().toJSON();
          modelID = model.id;
          modelName = model.name;
          prevSelected = findPrevSelected(model);
          switch (entityLevel) {
            case AGENCY_LEVEL:
              groupName = modelName;
              parentName = modelName;
              parentID = modelID;
              topParentID = modelID;
              path = "";
              title = "";
              searchPath = "";
              name = modelName;
              break;
            case ADVERTISER_LEVEL:
              agency = model.agency;
              parentID = agency.id;
              parentName = agency.name;
              topParentID = parentID;
              groupName = parentName;
              path = "";
              searchPath = `${groupName} » `;
              title = searchPath + modelName;
              name = modelName;
              break;
            case CAMPAIGN_LEVEL:
              advertiser = model.advertiser;
              agency = advertiser.agency;
              parentID = advertiser.id;
              parentName = advertiser.name;
              topParentID = agency.id;
              groupName = agency.name;
              path = T1.Utils.reverseString(`${parentName} « `);
              searchPath = `${groupName} » ${parentName} » `;
              title = searchPath + modelName;
              name = modelName;
              break;
          }
          newDataItem = {
            groupName: groupName,
            hasChildren: "none",
            iconState: prevSelected ? prevSelected.iconState : "deselected",
            iconType: prevSelected ? prevSelected.iconType : "include",
            id: modelID,
            isExpanded: "",
            level: 0,
            name: name,
            parentID: parentID,
            parentName: parentName,
            path: path,
            pathReversed: true,
            search: true,
            searchHighlight: highlightTerm(modelName),
            searchPath: searchPath,
            showIncludeIcon: "block",
            title: title,
            topParentID: topParentID,
            type: type
          };
          newData.push(newDataItem);
        }
      } else {
        newDataItem = {
          groupName: "",
          hasChildren: "none",
          iconState: "",
          iconType: "",
          id: "",
          isExpanded: "",
          level: NO_DATA_LEVEL,
          name: "No results found",
          parentID: "",
          parentName: "",
          path: "",
          showIncludeIcon: "none",
          topParentID: "",
          type: type
        };
        newData.push(newDataItem);
      }
      this.set({
        searchData: newData
      }, {
        silent: true
      });
      this.trigger("change:searchData", this);
      this.trigger("update:selectAllBtn");
    },
    getSearchData: function () {
      return $.extend([], this.get("searchData"));
    },
    updateIconState: function (id, state, selectAll, noBinUpdate) {
      let parentID;
      let childCounter = 0;
      const self = this;
      const searchMode = this.searchMode;
      const gridData = searchMode ? this.getSearchData() : this.getGridData();
      const binChangeItems = {
        add: [],
        remove: []
      };
      const parentTracker = [];
      function traverseChildren(children) {
        for (let childItem, i = 0; i < children.length; i++) {
          childItem = children[i];
          childCounter++;
          if (childItem.children && childItem.children.length) {
            traverseChildren(childItem.children);
          }
        }
      }
      function updateChildren(parent, startIndex, endIndex) {
        for (let childItem, i = startIndex; i < endIndex; i++) {
          childItem = gridData[i];
          if (childItem.id) {
            if (childItem.iconState === "selected") {
              binChangeItems.remove.push(childItem);
            }
            switch (parent.iconState) {
              case "selected":
                childItem.iconState = "selected-outline";
                childItem.iconType = "include-outline";
                break;
              case "deselected":
                childItem.iconState = "deselected";
                childItem.iconType = "include";
                break;
            }
          }
        }
      }
      function updateSiblings(clickItem) {
        for (let i = 0, item, sibling; i < gridData.length; i++) {
          item = gridData[i];
          if (item.parentID === clickItem.parentID && item.level === clickItem.level || clickItem.level === 0 && item.type === clickItem.type) {
            sibling = item;
            if (sibling.id !== clickItem.id && sibling.iconState === "selected-outline") {
              if (searchMode) {
                if (item.parentID === clickItem.parentID) {
                  sibling.iconState = "deselected";
                  sibling.iconType = "include";
                  binChangeItems.remove.push(sibling);
                }
              } else {
                sibling.iconState = "selected";
                sibling.iconType = "include";
                binChangeItems.add.push(sibling);
                if (sibling.isExpanded && sibling.children) {
                  childCounter = 0;
                  traverseChildren(sibling.children);
                  updateChildren(sibling, i + 1, i + 1 + childCounter);
                }
              }
            }
          }
        }
      }
      function traverseParents(startIndex) {
        for (let i = startIndex, item; i >= 0; i--) {
          item = gridData[i];
          if (item.id === parentID) {
            parentTracker.push(item.id);
            parentID = item.parentID;
            traverseParents(i - 1);
          }
        }
      }
      function updateParents(startIndex) {
        var childItem, childSelectCounter, item, parentItem;
        for (let i = startIndex; i >= 0; i--) {
          item = gridData[i];
          if (parentTracker.indexOf(item.id) > -1) {
            parentItem = item;
            childSelectCounter = 0;
            for (let j = 0; j < parentItem.children.length; j++) {
              childItem = parentItem.children[j];
              if (childItem.iconState === "selected" || childItem.iconState === "selected-partial" || childItem.iconState === "selected-outline") {
                childSelectCounter++;
              }
            }
            if (parentItem.iconState === "selected") {
              binChangeItems.remove.push(parentItem);
            }
            if (childSelectCounter > 0 && childSelectCounter <= parentItem.children.length) {
              parentItem.iconState = "selected-partial";
              parentItem.iconType = "partial-include";
            } else if (childSelectCounter === 0) {
              parentItem.iconState = "deselected";
              parentItem.iconType = "include";
            }
            if (parentItem.id !== parentItem.parentID || parentItem.level === 0) {
              updateSiblings(parentItem);
            }
          }
        }
      }
      function updateBinItems() {
        self.SharedModel.setBinChangeItems(binChangeItems);
        self.trigger("update:binData");
      }
      if (state === "select-all") {
        if (selectAll) {
          $.each(gridData, function (index, item) {
            if (item.id) {
              if (searchMode) {
                item.iconState = "selected";
                item.iconType = "include";
                binChangeItems.add.push(item);
              } else {
                item.iconState = "selected-outline";
                item.iconType = "include-outline";
              }
            }
          });
          if (!searchMode) {
            this.trigger("select:all");
          } else {
            updateBinItems();
          }
        } else {
          $.each(gridData, function (index, item) {
            if (item.id) {
              if (searchMode) {
                binChangeItems.remove.push(item);
              }
              item.iconState = "deselected";
              item.iconType = "include";
            }
          });
          if (!searchMode) {
            this.trigger("deselect:all");
          } else {
            updateBinItems();
          }
        }
      } else {
        $.each(gridData, function (index, item) {
          if (item.id === id) {
            switch (state) {
              case "selected":
                item.iconState = "deselected";
                item.iconType = "include";
                binChangeItems.remove.push(item);
                break;
              case "selected-outline":
                if (searchMode) {
                  item.iconState = "selected";
                  item.iconType = "include";
                  binChangeItems.add.push(item);
                  updateSiblings(item);
                } else {
                  item.iconState = "deselected";
                  item.iconType = "include";
                }
                break;
              case "selected-partial":
              case "deselected":
                item.iconState = "selected";
                item.iconType = "include";
                binChangeItems.add.push(item);
                break;
            }
            if (!searchMode) {
              if (item.children) {
                traverseChildren(item.children);
                updateChildren(item, index + 1, index + 1 + childCounter);
              }
              if (state === "selected-outline") {
                updateSiblings(item);
              }
              if (item.id !== item.topParentID) {
                parentID = item.parentID;
                traverseParents(index);
                updateParents(index);
              }
            }
            self.trigger("update:selectAllBtn");
          }
        });
        if (!searchMode) {
          this.trigger("change:gridData", this);
        } else {
          this.trigger("change:searchData", this);
        }
        if (!noBinUpdate) {
          updateBinItems();
        }
      }
    }
  });
});
