define('modules/reporting/campaigns/geoPerformance/views/breadCrumb',["jQuery", "T1", "T1View", "text!../templates/breadCrumb.html"], function ($, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    events: {
      "click .icon-nav-up": "navUp",
      "click .level": "navigate"
    },
    dataEvents: {
      reportModel: {
        "change:geoNav": "reload"
      }
    },
    initialize: function (args) {
      this.reportModel = args.models.reportModel;
    },
    load: function () {
      var self = this;
      var geoNav = this.reportModel.getGeoNav();
      var level = geoNav.level;
      this.render().then(function () {
        if (level === "region" && geoNav.country !== "" && geoNav.region === "") {
          self.el.find(".w-GeoBreadCrumb .arrow:eq(1)").hide();
        }
      });
    },
    navUp: function () {
      this.reportModel.geoNavigate("up");
    },
    navigate: function (e) {
      var level = $(e.target).data("id");
      var reportModel = this.reportModel;
      switch (level) {
        case "all":
          reportModel.setGeoNav("all");
          break;
        case "country":
          reportModel.setGeoNav("country", reportModel.getGeoNav().country);
          break;
      }
    },
    serialize: function () {
      var geoNav = this.reportModel.getGeoNav();
      var level = geoNav.level;
      var links;
      var $wGeoBreadCrumb = this.el.find(".w-GeoBreadCrumb");
      if (level === "country") {
        links = [{
          label: "All",
          last: false,
          level: "all"
        }, {
          label: geoNav.country,
          last: true,
          level: "country"
        }];
      } else if (level === "region") {
        links = [{
          label: "All",
          last: false,
          level: "all"
        }, {
          label: geoNav.country,
          last: false,
          level: "country"
        }, {
          label: geoNav.region,
          last: true,
          level: "region"
        }];
      }
      return {
        hide: geoNav.country === "",
        fadeIn: geoNav.country !== "" && $wGeoBreadCrumb.hasClass("no-display"),
        links: links
      };
    }
  });
});
