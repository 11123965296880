define('modules/admin/myOrgs/advertiser/views/createEdit',['require','Underscore','jQuery','models/advertiser','collections/advertiserRemoteAccounts','models/agency','collections/attributes','text!modules/admin/shared/templates/customAttributesLoading.html','text!modules/admin/shared/templates/customAttributes.html','collections/attributesWhiteList','utils/AttributeUtils','text!modules/admin/myOrgs/advertiser/templates/createEditBody.html','text!modules/admin/shared/templates/dmp.html','text!modules/admin/templates/header.html','text!modules/admin/shared/templates/helixAdvertiser.html','Mustache','models/organization','collections/organizations','text!modules/admin/shared/templates/safeSupply.html','T1','T1Layout','T1Permissions','T1View','text!modules/admin/myOrgs/advertiser/templates/createEdit.html','models/sessionUser','utils/AdminUtils','When','collections/agencies','jQPlugins/chosen.jquery'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Advertiser = require("models/advertiser");
  const AdvertiserRemoteAccounts = require("collections/advertiserRemoteAccounts");
  const Agency = require("models/agency");
  const Attributes = require("collections/attributes");
  const attributesLoadingTemplate = require("text!modules/admin/shared/templates/customAttributesLoading.html");
  const attributesTemplate = require("text!modules/admin/shared/templates/customAttributes.html");
  const AttributesWhiteList = require("collections/attributesWhiteList");
  const AttributeUtils = require("utils/AttributeUtils");
  const bodyTemplate = require("text!modules/admin/myOrgs/advertiser/templates/createEditBody.html");
  const dmpTemplate = require("text!modules/admin/shared/templates/dmp.html");
  const headTemplate = require("text!modules/admin/templates/header.html");
  const helixAdvertiserTemplate = require("text!modules/admin/shared/templates/helixAdvertiser.html");
  const Mustache = require("Mustache");
  const Organization = require("models/organization");
  const Organizations = require("collections/organizations");
  const safeSupplyTemplate = require("text!modules/admin/shared/templates/safeSupply.html");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!modules/admin/myOrgs/advertiser/templates/createEdit.html");
  const User = require("models/sessionUser");
  const Utils = require("utils/AdminUtils");
  const When = require("When");
  const Agencies = require("collections/agencies");
  const chosen = require("jQPlugins/chosen.jquery");
  var user = User.getUser().toJSON();
  var orgs = Organizations.getOrganizations();
  var isAdmin = user.role === "ADMIN";
  var isGlobalAdmin = isAdmin && user.type === "INTERNAL" && user.scope === "GLOBAL";
  var isHelixManager = user.role === "MANAGER";
  var enableFacebookRemoteId = T1Permissions.check("feature", "batch_supply_fb");
  var canEditAttributes = user.edit_data_definition === "1";
  var isAttributesViewOnly = user.view_data_definition === "1" && !canEditAttributes;
  var canViewSegments = isAttributesViewOnly || canEditAttributes;
  let AdvertiserCreateEdit = T1View.extend({
    hasUnloaded: false,
    isEdit: false,
    useNewFreqCap: T1Permissions.check("feature", "use_new_freq_cap"),
    template: template,
    partials: {
      attributes: attributesTemplate,
      body: bodyTemplate,
      dmp: dmpTemplate,
      header: headTemplate,
      helixAdvertiser: helixAdvertiserTemplate,
      safeSupply: safeSupplyTemplate
    },
    events: {
      "click .freqCap-disabled": "stopBubbling",
      "mouseup .freqCap-disabled": "stopBubbling",
      "mousedown .freqCap-disabled": "stopBubbling",
      "mouseover .freqCap-disabled": "stopBubbling",
      "mouseout .freqCap-disabled": "stopBubbling",
      "click .toggleFreqCap": "toggleFreqCap",
      "click .toggleAddFreq": "toggleAddFreq",
      "changed .frequency_type": "checkFreqCap",
      "click #confirm": "closeWarning",
      "click #undo-remove": "undoRemoveAttribute",
      "removed strand-repeater": "removeAttributeHandler",
      "added strand-repeater": "addAttributeHandler",
      "change .organization-select": "toggleDataSharing",
      "change .agency-select": "toggleDataSharing",
      "change .standard-attributes": "changeStandardAttributeHandler",
      "click .ads-txt-checkbox": "onAdsTxtChanged"
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveAndNew"
      }
    },
    eventhubEvents: {
      "dropdown:ag": "handleAncestorChange",
      "dropdown:organizations": "handleAncestorChange",
      "organization:select": "handleOrgChange"
    },
    initialize: function (args) {
      var formActions = [];
      var actionButtons = {
        cancel: {
          class: "cancel",
          label: "cancel",
          location: "#",
          uuid: "cancel"
        },
        save: {
          class: "save save-btn-secondary",
          label: "save",
          location: false,
          uuid: "save"
        },
        saveAndClose: {
          class: "saveAndClose save-btn-primary",
          label: "save & close",
          location: false,
          uuid: "save_close"
        },
        saveAndNew: {
          class: "saveAndNew save-btn-primary",
          label: "save & add another",
          location: false,
          uuid: "save_add"
        }
      };
      if (!T1Permissions.check("agency_advertiser.add")) {
        T1.Location.set("#admin", {
          replace: true
        });
      }
      orgs.getSelected().then($.proxy(function (orgId) {
        this.org = orgId;
      }, this));
      if (args.id) {
        this.setEditMode(args.id);
      }
      this.canLoad = false;
      formActions.push(actionButtons.cancel);
      if (this.isEdit) {
        formActions.push(actionButtons.save);
      }
      formActions.push(actionButtons.saveAndClose);
      if (!this.isEdit) {
        formActions.push(actionButtons.saveAndNew);
      }
      this.actionsConfig.formActions = formActions;
      this.dataAttributes = new Attributes();
      this.attributeScroll = true;
      this.attrsWhiteList = new AttributesWhiteList();
      this.whiteList = {};
      this.whiteListOptions = "";
      this.updateModel(args);
    },
    onAdsTxtChanged(event) {
      this.model.set({
        ads_txt_verified: event.target.value ? "1" : "0"
      });
      if (!event.target.value) {
        this.model.set({
          direct_only: "0"
        });
      }
      this.renderPartialTemplate({
        template: safeSupplyTemplate,
        useTemplateHtml: false,
        action: "html",
        targetEl: ".safe-supp",
        skipDelegateEvents: true,
        data: {
          ads_txt_verified: this.model.get("ads_txt_verified") === "1",
          direct_only: this.model.get("direct_only") === "1"
        }
      });
    },
    updateModel(args) {
      var deferred = When.defer();
      const advertiserRemoteAccounts = this.advertiserRemoteAccounts = new AdvertiserRemoteAccounts();
      const advertiserFetch = When.defer();
      const advertiserRemoteFetch = When.defer();
      const promises = [advertiserFetch.promise, advertiserRemoteFetch.promise];
      if (args.id) {
        const advertiser = new Advertiser({
          id: args.id
        });
        this.isEdit = true;
        advertiserRemoteAccounts.id = args.id;
        advertiserRemoteAccounts.fetch({
          success: function () {
            advertiserRemoteFetch.resolve();
          }
        });
        advertiser.fetch({
          success: data => {
            this.model = data;
            this.fetchAncestors().then((agency, org) => {
              this.model.set({
                agency: agency,
                org: org.toJSON()
              });
              this.canLoad = true;
              const newData = this.model.toJSON();
              newData.agency = agency.toJSON();
              Utils.updateBreadCrumb("Advertiser", newData);
              this.dataAttributes.advertiserId = this.model.id;
              this.dataAttributes.fetch({}).then(() => {
                deferred.resolve(this.model);
                advertiserFetch.resolve();
              });
            });
          }
        });
      } else {
        this.model = new Advertiser({
          action: "new",
          status: 1,
          name: ""
        });
        const viewOptions = T1.Location.getViewOptions() || {};
        if (viewOptions.parent) {
          this.fetchAncestors(viewOptions.parent).then((agency, org) => {
            this.model.set({
              agency: agency,
              org: org.toJSON()
            });
            this.canLoad = true;
            Utils.updateBreadCrumb("Advertiser", this.model.toJSON());
            this.initLayout();
            this.load();
            deferred.resolve(this.model);
          });
        } else {
          this.canLoad = true;
          Utils.updateBreadCrumb("Advertiser", this.model.toJSON());
          this.initLayout();
          deferred.resolve(this.model);
        }
      }
      When.all(promises).then(() => {
        this.initLayout();
        this.load();
      });
      return deferred.promise;
    },
    fetchAncestors(agency_id) {
      var deferred = $.Deferred();
      var model = this.model;
      var agencyId = agency_id || model.get("agency_id");
      if (agencyId) {
        Utils.fetchData(Agency, agencyId).then(function (parentModel) {
          Utils.fetchData(Organization, parentModel.get("organization_id")).then(function (grandParentModel) {
            parentModel.set({
              parent: grandParentModel.toJSON()
            });
            model.set({
              parent: parentModel
            });
            deferred.resolve(parentModel, grandParentModel);
          });
        });
      }
      return deferred.promise();
    },
    initLayout() {
      var self = this;
      var model = self.model;
      var carryOver = self.carryOver;
      var agency_id, organization_id;
      var layout = {
        ".vertical-select": [{
          module: "vertical",
          viewType: "select",
          options: {
            id: model.get("vertical_id")
          }
        }],
        ".adServer-select": [{
          module: "adServer",
          viewType: "select",
          options: {
            id: model.get("ad_server_id")
          }
        }]
      };
      if (!this.canLoad) {
        return;
      }
      const agency = model.get("agency") ? model.get("agency").toJSON() : {};
      if (carryOver) {
        agency_id = carryOver.agency_id;
        organization_id = carryOver.organization_id;
      } else {
        agency_id = agency ? agency.id : model.get("agency_id");
        organization_id = agency ? agency.organization_id : null;
      }
      if (!organization_id) {
        orgs.getSelected().then($.proxy(function (orgId) {
          organization_id = orgId;
        }, this));
      }
      if (!model.id && !agency.id) {
        $.extend(layout, {
          ".organization-select": [{
            module: "shared",
            viewType: "select",
            options: {
              clearOnClose: true,
              dataBind: "organization_id",
              entity: "organizations",
              fetchSelected: true,
              initialLoad: true,
              isSearchable: true,
              notFound: "No Organization Found",
              placeholder: "Select an Organization",
              preselect: "Select an Organization",
              selectedId: organization_id,
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              }
            }
          }],
          ".agency-select": [{
            module: "shared",
            viewType: "select",
            options: {
              boundEntity: "organizations",
              boundEntityId: organization_id,
              clearOnClose: true,
              dataBind: "agency_id",
              entity: "agencies",
              fetchSelected: true,
              initialLoad: true,
              isSearchable: true,
              notFound: "No Agencies Found",
              placeholder: "Select an Agency",
              preSelect: "Select an Organization First",
              selectedId: agency_id,
              selectEvent: "ag",
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              }
            }
          }]
        });
      }
      const data = model.toJSON();
      data.agency = agency;
      Utils.updateBreadCrumb("Advertiser", data);
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: "_wrapper",
        template: template,
        serialize: $.proxy(this.serialize, this),
        layout: layout
      });
      this.fetchAncestors(agency_id).then((agencyModel, orgModel) => {
        this.model.set({
          agency: agencyModel,
          org: orgModel.toJSON()
        });
        this.renderDmp(agencyModel);
      });
    },
    load() {
      if (this.canLoad) {
        this.render().then(() => {
          if (this.layout) {
            this.layout.load();
          }
          T1.Tooltip(this.$(".minimize-multi-ads, .cross-device-graph"), {
            delayIn: "100",
            delayOut: "100",
            offset: 5
          });
        });
        this.dmpCheckTooltip();
      }
    },
    postData(formValues, success, statusInvalid, conflict) {
      var self = this;
      var model = this.model;
      var canSubmit = formValues.agency_id ? this.attributeRepeater.validate() : true;
      var facebookId = formValues.facebook_id;
      if (formValues.ads_txt_verified === "INHERITED") {
        formValues.ads_txt_verified = "";
      }
      this.carryOver = {
        organization_id: formValues.organization_id,
        agency_id: formValues.agency_id
      };
      function saveAdvertiserRemoteAccounts(id, saveFacebookId) {
        if (saveFacebookId) {
          self.advertiserRemoteAccounts.id = id;
          self.advertiserRemoteAccounts.saveAccounts(saveFacebookId, $.noop, $.noop);
        }
      }
      if (canSubmit) {
        model.createEdit(formValues, {
          success: newModel => {
            var data = newModel.toJSON();
            var subtitle = [];
            saveAdvertiserRemoteAccounts(data.id, facebookId);
            data.agency = newModel.get("agency") ? newModel.get("agency").toJSON() : null;
            data.org = newModel.get("org");
            if (data.org) {
              subtitle.push(data.org.name);
            }
            if (data.agency) {
              subtitle.push(data.agency.name);
            }
            data.subtitle = subtitle.join(" &raquo; ");
            Utils.updateBreadCrumb("Advertiser", data);
            this.renderPartialTemplate({
              template: headTemplate,
              useTemplateHtml: false,
              action: "html",
              targetEl: ".create-edit-head",
              data: {
                title: data.name,
                subTitle: data.subtitle
              }
            });
            AttributeUtils.saveAttributes.call(this, this.attributeRepeater).then(function () {
              self.dataAttributes.fetch({}).then(function () {
                T1.Notify("message", `Advertiser ${self.isEdit ? "updated" : "created"} successfully!`);
                success(newModel);
                self.refreshAttributes();
              });
            }, function (message, errorType) {
              var errorMessage = errorType === "failure" ? "attribute creation failed" : "some attributes did not save";
              statusInvalid([], message, ".form-body");
              T1.Notify(errorType === "failure" ? "error" : "warning", `Advertiser ${self.isEdit ? "updated" : "created"} successfully, but ${errorMessage}`);
            });
          },
          statusInvalid: function (errors) {
            if (errors && errors.length) {
              _.map(errors, function (error) {
                error.field = error.field === "height" ? "custom_height" : error.field === "width" ? "custom_width" : error.field;
                return error;
              });
            }
            statusInvalid(errors, null, ".form-body");
          },
          conflict: (...args) => {
            conflict.apply(this, args);
          }
        });
      } else {
        statusInvalid([], null, ".form-body");
      }
    },
    saveFromAttributeWarning(evt) {
      var callback = this.saveCallback || null;
      this.modal.hide();
      if (this.$(".form-footer").data("saveAndContinueMode")) {
        evt.currentTarget = this.$(".saveAndClose")[0];
        this.saveOriginal(evt, callback);
      } else {
        evt.currentTarget = this.$(".save-btn-secondary")[0];
        this.saveOriginal(evt);
      }
    },
    saveOverride(evt, callback) {
      if (!canViewSegments || !this.attributeRepeater) {
        if (callback) {
          this.saveOriginal(evt, callback);
        } else {
          this.saveOriginal(evt);
        }
      } else {
        this.saveOriginal(evt, callback);
      }
    },
    closeWarning() {
      AttributeUtils.closeWarning.call(this);
    },
    undoRemoveAttribute() {
      this.attributeScroll = false;
      AttributeUtils.undoDeleteAttribute.call(this);
      this.closeWarning();
    },
    showDeletedAttributeWarning(warningTemplate) {
      AttributeUtils.showDeletedAttributeWarning.call(this, warningTemplate);
    },
    addAttributeHandler() {
      if (this.attributeScroll) {
        window.scrollTo(0, document.body.scrollHeight);
      }
      this.attributeScroll = true;
    },
    changeStandardAttributeHandler(event, params) {
      this.isFormElementsChanged = true;
      if ("deselected" in params) {
        const deselectedKey = params.deselected;
        if (deselectedKey in this.savedStandardAttributes) {
          const deSelectedAttribute = this.savedStandardAttributes[deselectedKey];
          AttributeUtils.removeAttributeHandler(this, deSelectedAttribute);
        }
      }
    },
    removeAttributeHandler() {
      AttributeUtils.removeAttributeHandler(this);
    },
    setEditMode(id) {
      this.isEdit = true;
      T1.Location.set(`#admin/advertiser/${id}`, {
        silent: true
      });
    },
    saveAndClose(evt) {
      var callback = {
        success: function () {
          const delay = 1e3;
          setTimeout(function () {
            T1.Location.set("#admin/myOrgs");
          }, delay);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    saveAndNew(evt) {
      var model = this.model;
      var parent = model.get("parent");
      var callback = {
        success: () => {
          if (this.model.get("action") === "new") {
            this.unload();
            this.model = new Advertiser({
              action: "new",
              domain: "",
              frequency_amount: "",
              frequency_interval: "",
              frequency_type: "",
              minimize_multi_ads: "",
              name: "",
              status: 1,
              toggleAddFreq: false
            });
            this.initLayout();
            this.load();
            this.renderDmp(parent);
            this.dataAttributes = new Attributes();
          }
          T1.Location.set("#admin/advertiser");
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    canUnload() {
      var isFormElementsChanged = this.isFormElementsChanged;
      var cancelHandler = {
        onAlertClose: function () {
          var T1Location = T1.Location;
          var abortedUrl = T1Location.getAbortedURL();
          if (abortedUrl === "" || abortedUrl === false) {
            setTimeout(function () {
              T1Location.set("#admin/myOrgs");
            }, 1);
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Advertiser Edit" : "New Advertiser"
        }, cancelHandler);
      }
      return !isFormElementsChanged;
    },
    cancelUnload() {
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          this.doNotProcessOrgChange = true;
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    unload() {
      this.hasUnloaded = true;
    },
    handleOrgChange(data) {
      if (!this.doNotProcessOrgChange) {
        T1.EventHub.publish("dropdown:organizations", data);
        T1.Location.set("admin/myOrgs");
      }
      this.doNotProcessOrgChange = false;
    },
    handleAncestorChange(data) {
      var model = this.model;
      var ancestorModel = data && data.model || {};
      var ancestorType = ancestorModel.get ? ancestorModel.get("type") : "";
      if (data && data.id) {
        const ancestorSettings = {};
        ancestorSettings[`${ancestorType}_id`] = data.id;
        model.set(ancestorSettings);
        if (ancestorType === "organization") {
          Utils.fetchData(Organization, model.get("organization_id")).then(parentData => {
            model.set({
              parent: parentData
            });
            this.renderDmp(parentData);
          });
        } else {
          this.fetchAncestors(data.id).then((agency, org) => {
            this.model.set({
              agency: agency,
              org: org.toJSON()
            });
            this.renderDmp(agency);
            this.toggleDataSharing(agency, org);
          });
        }
      }
    },
    renderDmp(data) {
      var entity = data.toJSON();
      var dmpOutput = this.dmpCheckEnable(entity);
      if (dmpOutput) {
        this.renderPartialTemplate({
          action: "html",
          data: dmpOutput,
          targetEl: ".dmp",
          template: dmpTemplate,
          useTemplateHtml: false
        });
        this.renderAttributes();
      }
      this.dmpCheckTooltip();
    },
    showAttributesLoading() {
      var $attributeEl = this.$(".attributes-list");
      var compiledTemplate = Mustache.to_html(attributesLoadingTemplate, {});
      if (Polymer && Polymer.dom) {
        Polymer.dom($attributeEl[0]).innerHTML = compiledTemplate;
      } else {
        $attributeEl.html(compiledTemplate);
      }
      $attributeEl.find(".data-attributes-loader").show();
      $attributeEl.find(".error-msg").hide();
      $attributeEl.removeClass("hidden");
    },
    showAttributesLoadingError(error_msg) {
      var $attributeEl = this.$(".attributes-list");
      $attributeEl.find(".data-attributes-loader").hide();
      $attributeEl.find(".error-msg").html(error_msg);
      $attributeEl.find(".error-msg").show();
    },
    renderAttributes() {
      var $attributeEl = this.$(".attributes-list");
      var polymerEl = Polymer.dom($attributeEl[0]);
      var tData = {
        canEditAttributes: canEditAttributes,
        cId: "{{model.cId}}",
        customKey: "{{!model.fixedKey}}",
        data_type: "{{model.data_type}}",
        fixedKey: "{{model.fixedKey}}",
        index: "{{index}}",
        isAttributesViewOnly: isAttributesViewOnly,
        key: "{{model.key}}",
        model: "{{model}}",
        name: "{{model.name}}",
        saved: "{{model.saved}}",
        scope: "{{scope}}",
        standardAttributeDisabled: isAttributesViewOnly,
        unsaved: "{{!model.saved}}"
      };
      this.showAttributesLoading();
      this.attrsWhiteList.fetch({
        success: data => {
          this.whiteListOptions = "";
          $.each(data.models, (index, model) => {
            var trimmedKey = model.attributes.key.replace(/^query\./, "");
            this.whiteList[trimmedKey] = {
              key: model.attributes.key,
              name: model.attributes.name,
              data_type: model.attributes.data_type
            };
            this.whiteListOptions += `<option value="${trimmedKey}">${model.attributes.name}</option>`;
          });
          const compiledTemplate = Mustache.to_html(attributesTemplate, tData);
          if (Polymer && Polymer.dom) {
            Polymer.dom($attributeEl[0]).innerHTML = compiledTemplate;
          } else {
            $attributeEl.html(compiledTemplate);
          }
          this.attributeRepeater = polymerEl.querySelector("#custom-attributes");
          this.attributeRepeater.validation = AttributeUtils.validation.bind(isGlobalAdmin);
          this.deleteLoader = polymerEl.querySelector(".delete-loader");
          const $standardAttrEl = $attributeEl.find(".standard-attributes");
          $standardAttrEl.chosen({
            placeholder_text_multiple: "Select Standard Attributes",
            search_contains: true,
            width: "95%"
          });
          this.attributeRepeater.set("data", AttributeUtils.prepopulateDataAttributes.call(this, isAttributesViewOnly));
          $standardAttrEl.html(this.whiteListOptions);
          if (this.savedStandardAttributes) {
            $standardAttrEl.val(Object.keys(this.savedStandardAttributes));
          }
          $standardAttrEl.trigger("liszt:updated");
          if (!this.dataAttributes.models.length && this.model.id) {
            this.dataAttributes.advertiserId = this.dataAttributes.advertiserId || this.model.id;
            this.dataAttributes.fetch({}).then(() => {
              AttributeUtils.loadData(this, isAttributesViewOnly);
            });
          } else {
            AttributeUtils.loadData(this, isAttributesViewOnly);
          }
        }
      });
    },
    refreshAttributes() {
      this.attributeRepeater = null;
      this.$(".attributes-list").removeClass("hidden");
      this.renderAttributes();
      this.dataAttributes.advertiserId = this.dataAttributes.advertiserId || this.model.id;
    },
    dmpCheckTooltip() {
      T1.Tooltip(this.$(".dmp_enabled"), {
        delayIn: "100",
        delayOut: "100",
        offset: 5
      });
    },
    dmpCheckEnable(parent) {
      var data = {};
      var model = this.model;
      var grandParent = parent.parent;
      var dmpEnabled = model.get("dmp_enabled");
      var parentDmpEnabled = parent && parent.dmp_enabled !== "disabled";
      var grandParentDmpEnabled = grandParent && grandParent.dmp_enabled ? grandParent.dmp_enabled !== "disabled" : parentDmpEnabled;
      data.dmp_enabled = typeof dmpEnabled !== "undefined" ? dmpEnabled !== "disabled" : parentDmpEnabled !== "disabled";
      data.deactivate_dmp = !isAdmin;
      if (!parentDmpEnabled || !grandParentDmpEnabled) {
        data.dmp_enabled = false;
        data.deactivate_dmp = true;
      }
      return data;
    },
    renderHelix(data) {
      var entity = data.toJSON();
      var helixOutput = this.helixCheckEnable(entity);
      if (helixOutput) {
        this.renderPartialTemplate({
          action: "html",
          data: helixOutput,
          targetEl: ".helix",
          template: helixAdvertiserTemplate,
          useTemplateHtml: false
        });
      }
    },
    helixCheckEnable(parent) {
      var data = {};
      var model = this.model;
      var dataSharing = $(".helix-activation")[0];
      var dataSharingRadio = document.querySelector('.helix-activation .radio[value = "0"]');
      if (isAdmin || isHelixManager) {
        data.enable_helix = true;
      }
      if (dataSharing && model.get("data_sharing_enabled") === "1") {
        dataSharing.removeAttribute("disabled");
        if (isHelixManager) {
          dataSharingRadio.setAttribute("disabled", "true");
        }
        return;
      } else if (dataSharing && model.get("data_sharing_enabled") === "0") {
        dataSharing.setAttribute("disabled", "true");
        if (parent.eligible_for_data_sharing === "1") {
          dataSharing.removeAttribute("disabled");
          if (isHelixManager) {
            dataSharingRadio.setAttribute("disabled", "true");
          }
        }
      }
      data.data_sharing_enabled = model.get("data_sharing_enabled");
      if (data.data_sharing_enabled === "1") {
        data.data_sharing_enabled = true;
      } else {
        data.data_sharing_enabled = false;
      }
      return data;
    },
    toggleDataSharing(agency, org) {
      var dataSharing = $(".helix-activation")[0];
      var dataSharingRadio = document.querySelector(".helix-activation .radio[value = '0']");
      if (agency.id && agency.get("eligible_for_data_sharing") === "1") {
        dataSharing.value = "1";
        if (isHelixManager) {
          dataSharingRadio.setAttribute("disabled", "true");
        }
      } else if (agency.id && agency.get("eligible_for_data_sharing") === "0" && org.id && org.get("eligible_for_data_sharing") === "1") {
        dataSharing.setAttribute("disabled", "true");
      } else if (org.id && org.get("eligible_for_data_sharing") === "0") {
        dataSharing.value = "0";
        dataSharing.setAttribute("disabled", "true");
      } else {
        dataSharing.value = "0";
        dataSharing.removeAttribute("disabled");
      }
    },
    stopBubbling(evt) {
      evt.stopPropagation();
      evt.preventDefault();
    },
    toggleFreqCap(evt) {
      var $checkbox = $(evt.currentTarget).val();
      var $fieldSet;
      var $freqType = this.$(".frequency_type");
      var freqTypeVal = $freqType.val();
      var $freqAmount = this.$(".frequency_amount");
      var freqAmountVal = $freqAmount.val();
      var $freqInterval = this.$(".frequency_interval");
      var freqIntervalVal = $freqInterval.val();
      this.stopBubbling(evt);
      if ($checkbox) {
        $fieldSet = this.$(".freqCap-disabled");
        $fieldSet.addClass("freqCap").removeClass("freqCap-disabled");
        $fieldSet.find("strand-dropdown").removeAttr("disabled");
        this.isFormElementsChanged = true;
      } else {
        $fieldSet = this.$(".freqCap");
        $fieldSet.addClass("freqCap-disabled").removeClass("freqCap");
        $fieldSet.find("strand-dropdown").attr("disabled", true);
        $fieldSet.children().removeClass("error").removeAttr("error title original-title");
        if ($freqType.length > 0 && freqTypeVal !== "" && freqTypeVal !== "no-limit") {
          $freqType.val("");
          $freqType[0].placeholder = "Type";
          this.isFormElementsChanged = true;
        }
        if ($freqAmount.length > 0 && freqAmountVal > 0) {
          $freqAmount[0].clearInput();
          this.isFormElementsChanged = true;
        }
        if ($freqInterval.length > 0 && freqIntervalVal !== "" && freqIntervalVal !== "not-applicable") {
          $freqInterval.val("");
          $freqInterval[0].placeholder = "Interval";
          this.isFormElementsChanged = true;
        }
        if (this.isFormElementsChanged) {
          this.model.set({
            frequency_amount: null,
            frequency_interval: "not-applicable",
            frequency_type: "no-limit"
          });
        }
      }
    },
    toggleAddFreq(evt) {
      var $label = $(evt.currentTarget).find("label");
      var txt = $label.text();
      var newTxt = "";
      this.stopBubbling(evt);
      if (txt.match(/\+/)) {
        newTxt = txt.replace(/\+/, "-");
      } else if (txt.match(/-/)) {
        newTxt = txt.replace(/-/, "+");
      }
      if (newTxt) {
        $label.text(newTxt);
      }
      this.$(".addFreq").toggle();
    },
    checkFreqCap(evt) {
      var freqType = evt.currentTarget.value;
      var $fieldSet = this.$(".fc-hidable");
      this.stopBubbling(evt);
      if (freqType === "no-limit") {
        $fieldSet.hide();
      } else {
        $fieldSet.show();
      }
      this.isFormElementsChanged = true;
    },
    serialize() {
      var output = this.model.toJSON();
      var agency = this.model.get("agency");
      var dmpData = {
        dmp_enabled: "disabled"
      };
      var facebookId = this.advertiserRemoteAccounts.models[0] ? this.advertiserRemoteAccounts.models[0].get("remote_account_id") : "";
      output.isEdit = this.isEdit;
      output.status = output.status !== "0";
      output.minimize_multi_ads = output.minimize_multi_ads === "1";
      output.facebook_id = facebookId;
      output.isFacebookVisiable = enableFacebookRemoteId;
      if (this.useNewFreqCap) {
        if (!this.isEdit) {
          output.frequency_type = "no-limit";
        }
        if (output.frequency_type === "no-limit") {
          output.freqInitHide = true;
          delete output.frequency_amount;
          delete output.frequency_interval;
        }
      } else {
        if (output.frequency_type === "no-limit") {
          delete output.frequency_type;
        }
      }
      if (output.frequency_amount <= 0) {
        delete output.frequency_amount;
      }
      if (output.frequency_interval === "not-applicable") {
        delete output.frequency_interval;
      }
      if (output.id) {
        output.title = output.name;
      } else {
        output.title = "New Advertiser";
      }
      if (agency) {
        output.agency = agency.toJSON();
        dmpData = output.agency;
      }
      if (output.org) {
        output.subTitle = output.org.name + (output.agency ? ` &raquo; ${output.agency.name}` : "");
        if (agency) {
          dmpData.parent = output.org;
        } else {
          dmpData = output.org;
          dmpData.parent = {
            dmp_enabled: "disabled"
          };
        }
      }
      $.extend(output, this.dmpCheckEnable(dmpData), this.helixCheckEnable(output.agency));
      if (this.useNewFreqCap || output.frequency_amount) {
        output.toggleFreqCap = true;
      }
      output.useNewFreqCap = this.useNewFreqCap;
      output.useAaSupp = T1Permissions.check("feature", "source");
      output.isAaSuppEnabled = this.model.get("accountable_and_addressable") === "1";
      output.isPoliticalAdvertiser = this.model.get("political") === "1";
      output.isDeterministicFirst = this.model.get("connected_id_type") === "DETERMINISTIC_FIRST";
      output.isOrgAaSuppEnabled = this.model.get("org") && this.model.get("org").accountable_and_addressable === "1";
      output.isAdsTxtUserControlsEnabled = T1Permissions.check("feature", "ads_txt_user_controls");
      output.ads_txt_verified = output.ads_txt_verified || "INHERITED";
      output.isAdvertiserView = true;
      return output;
    }
  });
  AdvertiserCreateEdit = T1.Form.ViewUtils.extend(AdvertiserCreateEdit);
  AdvertiserCreateEdit.prototype.saveOriginal = AdvertiserCreateEdit.prototype.save;
  AdvertiserCreateEdit.prototype.save = AdvertiserCreateEdit.prototype.saveOverride;
  return AdvertiserCreateEdit;
});
