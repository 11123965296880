define('modules/audiences/onboarding/create/views/otherDataCreateEdit',["Underscore", "jQuery", "T1", "Hogan", "modules/audiences/onboarding/create/views/onboardingForm", "modules/audiences/segments/segmentUtils", "text!modules/audiences/onboarding/create/templates/otherData/agencies.html", "text!modules/audiences/onboarding/create/templates/otherData/dataSources.html", "text!modules/audiences/onboarding/create/templates/otherData/pixelsList.html", "collections/agencies", "collections/dataproviders", "modules/audiences/onboarding/create/models/otherDataBulkModel", "modules/audiences/onboarding/create/utils/bulkCreateUtils"], function (_, $, T1, Hogan, OnboardingCreateEdit, Util, AgencyDropdownTemplate, DataSourcesTemplate, PixelsListTemplate, Agencies, DataProviders, OtherDataPixels, BulkUtils) {
  "use strict";

  const formatForDDL = entity => ({
    name: entity.get("name"),
    value: entity.id
  });
  const togglePriceSection = opts => {
    const {
      enable: enable,
      disable: disable,
      row: row
    } = opts;
    row.find("." + enable + "-section").find(".pricing-input").removeClass("disabled");
    row.find("." + disable + "-section").find(".pricing-input").addClass("disabled");
    row.find("." + enable + "-section").find("strand-input").removeAttr("disabled");
    row.find("." + disable + "-section").find("strand-input").attr("disabled", "disabled")[0].clearInput();
    row.find(".pricing-type." + disable).removeAttr("checked");
  };
  var otherDataCreateEditView = OnboardingCreateEdit.extend({
    events: Object.assign({}, OnboardingCreateEdit.prototype.events, {
      "changed #agencies strand-input": "searchAgencies",
      "selected #agencies": "onAgencySelect",
      "changed #data-sources strand-input": "searchDataProviders",
      "selected #data-sources": "onDataProviderSelect",
      "selected .pricing-type": "onPricingTypeSelected",
      "click .add-row": "onRepeaterRowAdded",
      "keyup #data-pixels-repeater": "pixelsListChanged"
    }),
    renderConfigs: {
      viewInBrowserEnabled: false,
      cost_pct_cpm: "{{model.cost_pct_cpm}}",
      cost_cpm: "{{model.cost_cpm}}",
      name: "{{model.name}}",
      cID: "{{model.cId.toString()}}"
    },
    initialize: function (options) {
      this.model = new OtherDataPixels();
      this.mode = "create";
      this.CustomAttributeTemplate = null;
      this.StandardAttributesTemplate = null;
      this.RevenueTemplate = null;
      this.AdvertisersTemplate = Hogan.compile(AgencyDropdownTemplate);
      this.ImplementationsTemplate = Hogan.compile(DataSourcesTemplate);
      this.EventsTemplate = Hogan.compile(PixelsListTemplate);
    },
    renderCallback: function () {
      this.agencySelector = this.el.find("#agencies")[0];
      this.dataProviderSelector = this.el.find("#data-sources")[0];
      this.pixelsRepeater = this.el.find("#data-pixels-repeater")[0];
      this.pixelsRepeater.validation = this.dataPixelsValidation;
      this.agencySelector.isLoading = true;
      this.dataProviderSelector.isLoading = true;
      this.loadAgencies();
      this.loadDataProviders().then(() => {
        this.agencySelector.disabled = false;
        this.dataProviderSelector.isLoading = false;
        this.agencySelector.isLoading = false;
      });
    },
    loadAgencies: function (searchTerm) {
      let def = $.Deferred();
      let agencies = new Agencies();
      agencies.urlFilter.set({
        entity: "organization",
        id: this.orgId
      });
      if (searchTerm) {
        let params = [{
          term: searchTerm,
          searchField: "multiField"
        }];
        agencies.search.set({
          params: params
        });
      }
      agencies.fetch({
        success: collection => {
          let formattedData = collection.models.map(agency => formatForDDL(agency));
          this.agencySelector.data = formattedData;
          def.resolve();
        }
      });
      return def.promise();
    },
    onAgencySelect: function (e) {
      const agencyId = e.target.value;
      const models = this.allDataProviders.models;
      this.selectedAgencyDataProviders = models.filter(model => {
        let providerAgencyId = model.get("agency_id");
        return providerAgencyId === agencyId || providerAgencyId === undefined;
      });
      const formattedData = this.selectedAgencyDataProviders.map(provider => formatForDDL(provider));
      this.el.find("#data-sources")[0].data = formattedData;
      this.dataProviderSelector.disabled = false;
      this.model.agency_id = agencyId;
      this.model.data_provider_id = null;
      this.saveCheck();
    },
    loadDataProviders: function (agencyId) {
      var deferred = $.Deferred();
      var providers, filtered, providerAgencyId;
      providers = new DataProviders(null, {
        isPaginated: false
      });
      providers.fetchOptions = $.extend({
        full: "*"
      }, providers.fetchOptions);
      providers.fetch({
        success: collection => {
          this.allDataProviders = collection;
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    searchAgencies: _.debounce(function (e) {
      const searchTerm = e.target.value;
      this.loadAgencies(searchTerm).then(() => {
        this.agencySelector.open();
      });
    }, 1e3),
    searchDataProviders: function (e) {
      const searchTerm = e.target.value || "";
      const filteredList = this.selectedAgencyDataProviders.filter(provider => provider.get("name").toLowerCase().includes(searchTerm.toLowerCase()) || provider.id === searchTerm).map(provider => formatForDDL(provider));
      this.dataProviderSelector.data = filteredList;
      this.dataProviderSelector.open();
    },
    onDataProviderSelect: function (e) {
      const dataProvider = this.allDataProviders.find(provider => provider.id === e.target.value);
      const mediamathPays = dataProvider.get("execution_by").toLowerCase() === "mediamath";
      const billingMessage = mediamathPays ? "MediaMath pays data provider (T1 client invoiced by MediaMath)" : "T1 Client pays data provider directly (T1 client is not invoiced by MediaMath)";
      this.el.find("#billing").text(billingMessage);
      this.el.find("#data-provider-message").show();
      if (mediamathPays) {
        $(this.pixelsRepeater).removeClass("pct-enabled");
        this.el.find(".pct-section").hide();
        this.el.find(".pricing-type.cpm").hide();
        this.el.find(".pricing-type.cpm").attr("checked", true);
      } else {
        $(this.pixelsRepeater).addClass("pct-enabled");
        this.el.find(".pct-section").show();
        this.el.find(".pricing-type.cpm").show();
      }
      this.model.data_provider_id = dataProvider.id;
      this.editHasChanged = true;
      this.el.find(".data-pixels-section").show();
      this.el.find(".events-attributes-section").show();
      this.saveCheck();
    },
    onPricingTypeSelected: function (e) {
      const $repeaterRow = $(e.target).parents("strand-repeater-row");
      const className = e.target.className;
      if (className.includes("pct")) {
        togglePriceSection({
          enable: "pct",
          disable: "cpm",
          row: $repeaterRow
        });
      } else if (className.includes("cpm")) {
        togglePriceSection({
          enable: "cpm",
          disable: "pct",
          row: $repeaterRow
        });
      }
      this.saveCheck();
    },
    onRepeaterRowAdded: function () {
      const className = this.pixelsRepeater.className;
      if (className.includes("pct-enabled")) {
        this.el.find(".pct-section").show();
        this.el.find(".pricing-type.cpm").show();
      } else {
        this.el.find(".pct-section").hide();
        this.el.find(".pricing-type.cpm").hide();
      }
      this.disableSave();
    },
    pixelsListChanged: function () {
      BulkUtils.pixelsListChanged.call(this);
    },
    dataPixelsValidation: function (data, added, modified) {
      return data.map(model => {
        const hasPricing = model.cost_pct_cpm || model.cost_cpm;
        return {
          cId: model.cId,
          name: model.name ? null : "Name required",
          cost_cpm: hasPricing ? null : "Pricing required",
          cost_pct_cpm: hasPricing ? null : "Pricing required"
        };
      }).filter(StrandLib.DataUtils.isDef);
    },
    repeatersChanged: function (e) {
      this.saveCheck();
    },
    saveCheck: function () {
      const agencySet = () => this.model.agency_id;
      const dataProviderSet = () => this.model.data_provider_id;
      const pixelsRepeaterValid = () => this.pixelsRepeater.validate() && this.model.pixels.length;
      const validateDelivery = () => {
        const delivery = this.model.delivery_method && this.model.delivery_method.length > 0;
        if (delivery) {
          return !this.model.delivery_method.includes("email") ? true : this.model.email_to;
        } else {
          return true;
        }
      };
      if (agencySet() && dataProviderSet() && pixelsRepeaterValid() && validateDelivery()) {
        this.enableSave();
      } else {
        this.disableSave();
      }
    },
    updateBreadCrumb: function () {
      var pathPieces = [{
        label: "New External Data Segments",
        location: "#audiences/onboarding/data/create"
      }];
      this.title = "New External Data Segments";
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
    }
  });
  otherDataCreateEditView.prototype.successCallback = BulkUtils.successCallback;
  otherDataCreateEditView.prototype.saveModel = BulkUtils.saveModel;
  return otherDataCreateEditView;
});
