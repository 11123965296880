define('modules/reporting/campaigns/dataExport/models/reportDefaults',[], function () {
  "use strict";

  const dataRetentionDays = moment().diff("2013-01-01", "days");
  return Object.freeze([{
    label: "All Dimensions and Metrics",
    fetchName: "all_dimensions_and_metrics",
    tag: "allDimensionsAndMetricsV1",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_7_days",
    defaultDimensions: ["campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "All Dimensions and Metrics Ecosystem Cost",
    fetchName: "all_dimensions_and_metrics_ecosystem_cost",
    tag: "allDimensionsAndMetricsEcosystemV1",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_7_days",
    defaultDimensions: ["campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "App Transparency",
    fetchName: "app_transparency",
    tag: "appTransparencyV1",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "app_name", "campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Audience Index",
    fetchName: "audience_index",
    tag: "audienceIndexV1",
    rangeLimit: 14,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: true,
    defaultTimeWindow: "last_14_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "audience_name", "audience_path", "campaign_id", "campaign_name"],
    defaultMetrics: ["audience_index", "matched_impressions"]
  }, {
    label: "Audience Index Pixel",
    fetchName: "audience_index_pixel",
    tag: "audienceIndexPixelV1",
    rangeLimit: 14,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: true,
    defaultTimeWindow: "last_14_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "audience_name", "audience_path", "pixel_id", "pixel_name"],
    defaultMetrics: ["audience_index", "matched_users"]
  }, {
    label: "Audience Verification",
    fetchName: "audience_verification",
    tag: "audienceVerificationV1",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: true,
    hasFTD: false,
    isIntervalBased: true,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["age", "campaign_id", "campaign_name", "country_code", "gender", "platform_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["impressions"]
  }, {
    label: "Brain Feature Value",
    fetchName: "brain_feature_value",
    tag: "brainFeatureValueV1",
    rangeLimit: 30,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "agency_id", "bid_impact", "campaign_id", "created_on", "feature", "feature_report_type", "feature_value", "index", "is_numeric", "mean", "model_goal", "organization_id", "position"],
    defaultMetrics: []
  }, {
    label: "Brain Feature Summary",
    fetchName: "brain_feature_summary",
    tag: "brainFeatureSummaryV1",
    rangeLimit: 30,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "agency_id", "campaign_id", "feature", "index", "model_goal", "organization_id", "position"],
    defaultMetrics: []
  }, {
    label: "By Hour",
    fetchName: "by_hour",
    tag: "byHourV1",
    rangeLimit: 7,
    hasHours: true,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_7_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Contextual Discovery",
    fetchName: "contextual_discovery",
    tag: "contextualDiscoveryV1",
    rangeLimit: 90,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "parent", "path", "strategy_id", "strategy_name", "target_id"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Contextual Insights",
    fetchName: "contextual_insights",
    tag: "contextualInsightsV1",
    rangeLimit: 90,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "parent", "path", "strategy_id", "strategy_name", "target_id"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Data Pixel Loads",
    fetchName: "data_pixel_loads",
    tag: "dataPixelLoadsV1",
    rangeLimit: 90,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["agency_name", "agency_id", "referrer", "referrer_rank"],
    defaultMetrics: ["loads", "uniques"]
  }, {
    label: "Day Part",
    fetchName: "day_part",
    tag: "dayPartV2",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: true,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "day_part_name", "strategy_id", "strategy_name", "weekday_id", "weekday_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Event Pixel Loads",
    fetchName: "event_pixel_loads",
    tag: "eventPixelLoadsV1",
    rangeLimit: 90,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["agency_name", "agency_id", "advertiser_name", "advertiser_id", "referrer", "referrer_rank"],
    defaultMetrics: ["loads", "uniques"]
  }, {
    label: "Geo Performance",
    fetchName: "geo",
    tag: "geoPerformanceV2",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: true,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "country_name", "metro_name", "region_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Hyperlocal",
    fetchName: "hyperlocal",
    tag: "hyperlocalV1",
    rangeLimit: 90,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "node_level", "path", "strategy_id", "strategy_name", "target_id"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Performance",
    fetchName: "performance",
    tag: "performanceV1",
    rangeLimit: dataRetentionDays,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Performance Ecosystem Cost",
    fetchName: "performance_ecosystem_cost",
    tag: "performanceEcoCostV1",
    rangeLimit: dataRetentionDays,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Postal Code",
    fetchName: "postal_code",
    tag: "postalCodeV1",
    rangeLimit: 30,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "postal_code", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Reach and Frequency",
    fetchName: "reach_frequency",
    tag: "reachFrequencyV1",
    rangeLimit: 30,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: true,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "frequency", "strategy_id", "strategy_name"],
    defaultMetrics: ["uniques", "impressions", "clicks", "total_conversions", "ctc", "ctr", "rr_per_1k_imps"]
  }, {
    label: "Site Transparency",
    fetchName: "site_transparency",
    tag: "siteTransparencyV2",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: true,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "site_domain", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Site Transparency Viewability",
    fetchName: "site_transparency_viewability",
    tag: "siteTransparencyViewabilityV2",
    rangeLimit: 365,
    hasHours: false,
    hasCTD: true,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "site_domain", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Technology",
    fetchName: "device_technology",
    tag: "deviceTechnologyV2",
    rangeLimit: 90,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "browser", "campaign_id", "campaign_name", "connection_speed", "device_type", "inventory_type", "operating_system", "strategy_id", "strategy_name"],
    defaultMetrics: ["clicks", "ctc", "ctr", "impressions", "rr_per_1k_imps", "total_conversions", "total_spend_cpa", "total_spend_cpc", "total_spend_cpm", "total_spend"]
  }, {
    label: "Watermark",
    fetchName: "watermark",
    tag: "watermarkV1",
    rangeLimit: 30,
    hasHours: false,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_30_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["non_watermark_impressions", "non_watermark_spend", "watermark_impressions", "watermark_spend_pct", "watermark_spend"]
  }, {
    label: "Win/Loss",
    fetchName: "win_loss",
    tag: "winLossV2",
    rangeLimit: 365,
    hasHours: true,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_7_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "strategy_id", "strategy_name"],
    defaultMetrics: ["average_bid_amount_cpm", "average_win_amount_cpm", "bid_rate", "bids", "matched_bid_opportunities", "win_rate", "wins"]
  }, {
    label: "Win/Loss Creative",
    fetchName: "win_loss_creative",
    tag: "winLossCreativeV1",
    rangeLimit: 365,
    hasHours: true,
    hasCTD: false,
    hasFTD: false,
    isIntervalBased: false,
    defaultTimeWindow: "last_7_days",
    defaultDimensions: ["advertiser_id", "advertiser_name", "campaign_id", "campaign_name", "creative_id", "creative_name", "creative_size", "strategy_id", "strategy_name"],
    defaultMetrics: ["bids", "wins", "win_rate", "average_bid_amount_cpm", "average_win_amount_cpm"]
  }]);
});
