define('modules/strategy/views/details',["Underscore", "jQuery", "T1", "T1View", "text!modules/strategy/templates/details.html", "models/strategyTargetDimensions", "modules/organization/utils/Utils", "collections/targetValues", "collections/batchSupplySources", "collections/strategySupplySources", "T1Permissions", "T1Timezones", "T1Form", "utils/CampaignStrategyUtils", "text!modules/strategy/templates/frequency.html", "text!modules/strategy/templates/pacing.html", "text!modules/shared/templates/safeSupply.html", "text!templates/info_box.html", "T1Animate", "modules/strategy/views/helpers/defaultLocationPersistenceHelper", "T1Spinner", "jQueryUI", "T1Accordion", "T1Truncate", "T1Selectbox", "T1TabButton", "T1DatePicker", "T1Tooltip"], function (_, $, T1, T1View, template, StrategyTargetDimensions, OrgUtils, TargetValues, BatchSupplySources, SupplySources, T1Permissions, T1Timezones, T1Form, CampaignStrategyUtils, Frequency, Pacing, SafeSupply, InfoBox, t1Animate, DefaultLocationHelper) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  const fbStrategyGoalTypes = Object.freeze({
    cpa: "cpa",
    cpc: "cpc",
    ctr: "cpc",
    reach: "reach",
    roi: "cpa",
    spend: "spend",
    vcpm: "cpc",
    vcr: "cpcv",
    viewability_rate: "cpc"
  });
  var useAllocPacing = T1Permissions.check("feature", "use_alloc_pacing");
  var pacingOptimization = true;
  var frequencyIntervalText = {
    hour: "Hour",
    day: "Day",
    week: "7 Days",
    month: "30 Days"
  };
  var goalTypeText = {
    spend: "CPM SPEND",
    reach: "CPM REACH",
    cpc: "CPC",
    cpe: "CPE",
    cpa: "CPA",
    roi: "ROI",
    vcr: "Video Completion Rate",
    ctr: "CTR",
    viewability_rate: "Viewability Rate",
    vcpm: "Viewable CPM",
    cpcv: "CPCV"
  };
  var goalTypes = [{
    value: "spend",
    text: goalTypeText.spend
  }, {
    value: "reach",
    text: goalTypeText.reach
  }, {
    value: "cpc",
    text: goalTypeText.cpc
  }, {
    value: "cpe",
    text: goalTypeText.cpe
  }, {
    value: "cpa",
    text: goalTypeText.cpa
  }, {
    value: "roi",
    text: goalTypeText.roi
  }, {
    value: "vcr",
    text: goalTypeText.vcr
  }, {
    value: "ctr",
    text: goalTypeText.ctr
  }, {
    value: "viewability_rate",
    text: goalTypeText.viewability_rate
  }, {
    value: "vcpm",
    text: goalTypeText.vcpm
  }, {
    value: "cpcv",
    text: goalTypeText.cpcv
  }];
  var bidFor = [{
    value: "0",
    text: "Total Spend"
  }, {
    value: "1",
    text: "Media Only"
  }];
  var goalTypeOptions = {
    roi: [1, 9, 8, 6, 7, 2, 4, 5],
    cpa: [1, 9, 8, 6, 7, 2, 4],
    cpc: [1, 9, 8, 6, 7, 2],
    vcpm: [1, 9, 8, 6, 7, 2],
    viewability_rate: [1, 9, 8, 6, 7, 2],
    ctr: [1, 9, 8, 6, 7, 2],
    vcr: [1, 9, 8, 6, 7, 2],
    reach: [1],
    spend: [1],
    cpe: [1, 2, 3]
  };
  var batchGoalTypeOptions = {
    spend: [0, 1],
    reach: [0, 1],
    cpc: [0, 1, 2],
    ctr: [0, 1, 2],
    vcpm: [0, 1, 2],
    viewability_rate: [0, 1, 2],
    cpe: [0, 1, 2, 3],
    cpa: [0, 1, 2, 4],
    roi: [0, 1, 2, 4],
    vcr: [10]
  };
  var goalTypeOptionsNoOptimization = [0];
  var dropdownList = {
    type: {
      options: [{
        value: "AUD",
        text: "Audience Targeting (AUD)"
      }, {
        value: "REM",
        text: "Remarketing (REM)"
      }, {
        value: "GBO",
        text: "Goal-Based Optimization (GBO)"
      }]
    },
    supply_type: {
      options: [{
        value: "MKT",
        text: "Curated Markets",
        cls: "non-supply-type-tracking"
      }, {
        value: "RTB",
        text: "RTB & Private Market Place (PMP)",
        cls: "non-supply-type-tracking"
      }, {
        value: "RMX_API",
        text: "External Media Tracking",
        cls: "supply-type-tracking"
      }, {
        value: "BATCH",
        text: "Facebook",
        cls: "supply-type-facebook",
        selected: true
      }, {
        value: "PROGRAMMATIC_GUARANTEED",
        text: "Programmatic Guaranteed",
        cls: "supply-type-pg"
      }]
    },
    goal_type: {
      options: goalTypes
    },
    bid_price_is_media_only: {
      options: bidFor
    },
    pacing_type: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }]
    },
    pacing_interval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }]
    },
    impression_pacing_interval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }]
    },
    impression_pacing_type: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    frequency_type: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    frequency_interval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }, {
        value: "week",
        text: frequencyIntervalText.week
      }, {
        value: "month",
        text: frequencyIntervalText.month
      }]
    }
  };
  let prevGoalType;
  var Date = T1.Date;
  var pacingASAPCaution = "<p>ASAP pacing is designed to bolster spend for strategies targeting small audiences. " + "When targeting larger audiences, ASAP pacing may result in spending more than your pacing amount.</p>" + '<p class="confirm-message">By clicking the "Confirm" button, you are acknowledging the possibility ' + "of overspend.</p>";
  var impPacingASAPCaution = "<p>ASAP pacing is designed to bolster delivery for strategies targeting small " + "audiences. When targeting larger audiences, ASAP pacing may result in delivering more than your pacing amount." + '</p><p class="confirm-message">By clicking the "Confirm" button, you are acknowledging the ' + "possibility of over delivery.</p>";
  var getStartEndDatesDisplay = CampaignStrategyUtils.getDisplayTextForStartAndEndDates;
  var useCampaignFreqCap = CampaignStrategyUtils.useCampaignFrequencyCap;
  var getDatePickerLowerBoundForStrategy = CampaignStrategyUtils.getDatePickerLowerBoundForStrategy;
  var formatsRolledUpNumbersNoAbbreviation = CampaignStrategyUtils.formatsRolledUpNumbersNoAbbreviation;
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  var formatCurrency = T1.Utils.formatCurrencyNoSymbol;
  var customPacing = false;
  var formatCurrencyNoSymbol = T1.Utils.formatCurrencyNoSymbol;
  var goalTypePCT = ["ctr", "vcr", "viewability_rate"];
  var currMask = true;
  const defaultAnimSpeed = 300;
  const accordionAnimSpeed = 400;
  const twoDigits = 2;
  const fourDigits = 4;
  function getStrandSelectOptions(returnData) {
    return function () {
      return `<strand-list-item value="${this.value}" class="${this.cls}"` + `${returnData.supply_type === this.value ? " selected" : ""}` + `${this.disabled ? " disabled" : ""}>` + `${this.text}` + `</strand-list-item>`;
    };
  }
  function getSupplyTypeOptions(isCuratedMarketsTeam, enableFacebookSupplyType, isFacebookVideoPermitted, isProgrammaticGuaranteed, mediaType, isVcrGoalType) {
    return dropdownList.supply_type.options.filter(option => {
      if (option.value === "MKT" && !isCuratedMarketsTeam) {
        return false;
      }
      if (option.value === "BATCH" && !enableFacebookSupplyType) {
        return false;
      }
      if (option.value === "PROGRAMMATIC_GUARANTEED" && !isProgrammaticGuaranteed) {
        return false;
      }
      return true;
    }).map(option => {
      if (option.value === "BATCH" && !isFacebookVideoPermitted) {
        option.disabled = mediaType === "VIDEO";
      } else if (option.value === "BATCH" && isFacebookVideoPermitted && isVcrGoalType) {
        option.disabled = mediaType === "DISPLAY";
      } else {
        option.disabled = false;
      }
      return option;
    });
  }
  const StrategyDetailsView = T1View.extend({
    template: template,
    bidAggression: 50,
    status: 0,
    displayDateFormat: "MMMM d, yyyy",
    displayTimeFormat: "h:mm tt",
    startTime: "12:00 AM",
    endTime: "11:59 PM",
    startBuffer: 1,
    endBuffer: 30,
    startBufferMin: {
      minutes: 10
    },
    use_optimization: 0,
    campaignHasStarted: false,
    campaignHasEnded: false,
    useCampaignStart: false,
    useCampaignEnd: false,
    strategyMediaType: "DISPLAY",
    disableMediaType: false,
    canViewVideoStrategy: false,
    displayMaxBidWm: false,
    isProgrammaticGuaranteed: false,
    endDatePicker: null,
    prepareDatePicker: function () {
      const {
        model: strategy,
        campaign: campaign
      } = this;
      var endDate = strategy.endDate || new Date();
      var startDate = strategy.startDate || new Date();
      var campaignEndDate = Date.parse(campaign.end_date);
      var campaignStartDate = Date.parse(campaign.start_date);
      var newStartDate = new Date();
      strategy.zoneName = campaign.zone_name;
      this.campaignHasStarted = CampaignStrategyUtils.hasEntityStarted(campaign);
      this.campaignHasEnded = CampaignStrategyUtils.hasEntityEnded(campaign);
      if (strategy.id) {
        this.disableCampaignStart = this.campaignHasStarted;
        this.disableCampaignEnd = this.campaignHasEnded;
        this.useCampaignStart = strategy.get("use_campaign_start") === "1";
        this.useCampaignEnd = strategy.get("use_campaign_end") === "1";
        strategy.startDate = this.useCampaignStart ? Date.parse(campaign.initial_start_date) : Date.parse(strategy.get("start_date"));
        strategy.endDate = this.useCampaignEnd ? Date.parse(campaign.end_date) : Date.parse(strategy.get("end_date"));
        this.startTime = startDate.toString(this.displayTimeFormat);
        this.endTime = endDate.toString(this.displayTimeFormat);
        this.hasStarted = this.useCampaignStart ? CampaignStrategyUtils.hasEntityStarted(campaign) : CampaignStrategyUtils.hasEntityStarted(strategy);
        this.hasEnded = CampaignStrategyUtils.hasEntityEnded(strategy);
      } else {
        if (this.campaignHasStarted) {
          this.disableCampaignStart = true;
          this.useCampaignStart = false;
          newStartDate.addDays(this.startBuffer);
          if (!this.campaignHasEnded && Date.compare(newStartDate, campaignEndDate) === 1) {
            newStartDate.setDate(startDate.getDate());
          }
          startDate = newStartDate;
        } else {
          this.disableCampaignStart = false;
          this.useCampaignStart = true;
          startDate = campaignStartDate;
          this.startTime = startDate.toString(this.displayTimeFormat);
        }
        if (this.campaignHasEnded) {
          this.disableCampaignEnd = true;
          this.useCampaignEnd = false;
          endDate.addDays(this.endBuffer);
        } else {
          this.disableCampaignEnd = false;
          this.useCampaignEnd = true;
          endDate = campaignEndDate;
          this.endTime = endDate.toString(this.displayTimeFormat);
        }
        strategy.endDate = endDate;
        strategy.startDate = startDate;
        this.hasStarted = false;
        this.hasEnded = false;
      }
    },
    partials: {
      frequency: Frequency,
      infoBox: InfoBox,
      pacing: Pacing,
      safeSupply: SafeSupply
    },
    initialize: function () {
      this.currMask = currMask;
      this.useAllocPacing = useAllocPacing;
      this.customPacing = customPacing;
      this.pacingOptimization = pacingOptimization;
      this.userChanged = false;
      this.enableFacebookSupplyType = T1Permissions.check("feature", "batch_supply_fb");
      this.campaign = this.model.get("campaign").toJSON();
      this.canViewVideoStrategy = T1Permissions.check("feature", "video");
      this.accordionState = {
        "advanced-settings-c": "close"
      };
      this.userSelectedIsMediaOnly = null;
      this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(this.model.get("campaign"));
      this.defaultLocationHelper = new DefaultLocationHelper();
      this.defaultLocationHelper.init();
      $(document).on("mousedown", CampaignStrategyUtils.closeStickyTooltips);
    },
    getBidAgressivenessForDisplay: function () {
      var numVal = this.model ? parseFloat(this.model.get("bid_aggressiveness")) : null;
      return isNaN(numVal) ? "" : numVal.toFixed(0);
    },
    load: function () {
      var self = this;
      var strategy = this.model;
      var campaignGoalType = this.campaign.goal_type;
      const $el = this.el;
      var bidAggressiveness = strategy.get("bid_aggressiveness");
      var useOptimization = strategy.get("use_optimization");
      var goalValue = strategy.get("goal_value");
      var maxBid = strategy.get("max_bid");
      var minBid = strategy.get("min_bid");
      var roiTarget = strategy.get("roi_target");
      var maxBidWm = strategy.get("max_bid_wm") || "";
      var supplyType = strategy.get("supply_type") || "RTB";
      if (!strategy.get("type")) {
        strategy.set({
          type: "AUD"
        });
      }
      if (strategy.get("pacing_optimization_type")) {
        this.pacingOptimization = true;
        this.customPacing = false;
      }
      if (!strategy.startDate) {
        this.prepareDatePicker();
      }
      if (this.canViewVideoStrategy) {
        if (strategy.id) {
          this.disableMediaType = true;
          this.applyChannelSettingsByMediaType(strategy.toJSON(), "");
        } else if (campaignGoalType === "vcr") {
          strategy.set({
            media_type: "VIDEO"
          });
          this.applyChannelSettingsByMediaType(strategy.toJSON(), "");
        }
      }
      if (bidAggressiveness) {
        this.bidAggression = this.getBidAgressivenessForDisplay();
      }
      if (!strategy.id && campaignGoalType !== "spend") {
        useOptimization = 1;
      }
      if (this.isProgrammaticGuaranteed) {
        useOptimization = 0;
      }
      if (useOptimization) {
        this.use_optimization = parseInt(useOptimization, 10);
      }
      if (goalValue) {
        this.goal_value = goalValue;
      }
      if (maxBid !== undefined) {
        this.max_bid = maxBid;
      }
      if (minBid !== undefined) {
        this.min_bid = minBid;
      }
      if (roiTarget !== undefined) {
        this.roi_target = roiTarget;
      }
      this.max_bid_wm = maxBidWm;
      this.supply_type = supplyType;
      this.setGoalTypeDDL();
      this.setMaxBidWm();
      if (strategy && strategy.id) {
        T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      }
      this.render().then(function () {
        var $dateDisplay = $el.find(".date-display");
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(self.campaign);
          self.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          self.$cancelBtn = $(".cancelNewT1");
        }
        T1.EventHub.publish("strategy-badges-edit:placementCheck", {
          currentView: "details_targeting"
        });
        OrgUtils.fetchOrgSettings(self.campaign.advertiser.agency.organization_id);
        T1Timezones.done(function (mthds) {
          var campaign = self.campaign;
          self.timezones = mthds;
          self.renderPartial(".date-display");
          T1.DoubleDatePicker({
            getStartDate: () => strategy.startDate,
            getEndDate: () => strategy.endDate,
            chooseTime: true,
            chooseTimezone: true,
            lowerBounds: getDatePickerLowerBoundForStrategy(self.hasStarted, self.campaignHasStarted, Date.parse(strategy.startDate), self.useCampaignStart ? Date.parse(campaign.initial_start_date) : Date.parse(campaign.start_date)),
            startTimeEditable: () => !self.hasStarted,
            startDisabled: () => self.hasStarted,
            defaultStart: campaign.start_date,
            defaultEnd: campaign.end_date,
            useDefaultStart: self.useCampaignStart,
            useDefaultEnd: self.useCampaignEnd,
            useStartDefaultCheckInput: $(".campaign-start-date", $el),
            useEndDefaultCheckInput: $(".campaign-end-date", $el),
            zoneName: () => strategy.zoneName,
            positionBy: "bottomTop",
            topOffset: 25,
            closeOnScroll: false,
            canClose: function (e) {
              var parent = $(e.target).parent();
              var closestInput = parent.find("input");
              return !(parent.is("label") && (closestInput.hasClass("campaign-start-date") || closestInput.hasClass("campaign-end-date")));
            },
            onOpen: function () {
              $(".cluetip-datepicker").addClass("datepicker-width-auto");
              if (self.endDatePicker) {
                this.endMinDate = self.endDatePicker;
              }
            },
            onSave: function ({
              data: data,
              error: error,
              success: success
            }) {
              const saveValues = data.saveValues;
              var newStartDate = Date.parse(saveValues.start.date);
              self.timezones.validateStartDate(self.startBufferMin, newStartDate, saveValues.zoneName, {
                onSuccess: function () {
                  const saveValuesSuccess = data.saveValues;
                  var newStartDateSuccess = Date.parse(saveValuesSuccess.start.date);
                  var newEndDate = Date.parse(saveValuesSuccess.end.date);
                  if (Date.compare(newEndDate, newStartDateSuccess) !== 1) {
                    error({
                      message: self.startEndOrderError
                    });
                    return;
                  }
                  strategy.startDate = newStartDateSuccess;
                  strategy.endDate = newEndDate;
                  self.endDatePicker = newStartDateSuccess;
                  self.startTime = newStartDateSuccess.toString(self.displayTimeFormat);
                  self.endTime = newEndDate.toString(self.displayTimeFormat);
                  self.renderPartial(".date-display");
                  self.removeTooltip({
                    currentTarget: $dateDisplay
                  });
                  self.useCampaignStart = $(".campaign-start-date", $el).is(":checked");
                  self.useCampaignEnd = $(".campaign-end-date", $el).is(":checked");
                  success();
                },
                onError: function () {
                  error({
                    message: self.startDateError
                  });
                },
                hasStarted: self.hasStarted
              });
            }
          }, self.el);
        });
        self.toggleFrequencyCapOptions();
        if (strategy.id && strategy.get("supply_type") === "BATCH") {
          self.setEditViewForFacebook();
        }
        if (strategy.id && strategy.get("supply_type") === "RMX_API") {
          self.setEditViewForMarketPlace();
        }
        self.$(".goal-type .select, .bid-for .select").prop("disabled", self.isProgrammaticGuaranteed).trigger("liszt:updated");
      });
      if (!T1Permissions.check("feature", "batch_supply_fb_video")) {
        batchGoalTypeOptions.vcr = [0, 1, 2];
        const $supplyTypeOptionFb = this.$(".supply-type-facebook");
        if (strategy.get("media_type") === "VIDEO") {
          $supplyTypeOptionFb.attr("disabled", "disabled");
        } else {
          $supplyTypeOptionFb.removeAttr("disabled");
        }
        this.onMediaTypeClick = function (e) {
          const $currentTarget = $(e.currentTarget);
          const $supplyTypeDropdown = this.$(".supply-type-dropdown");
          const isFacebook = $supplyTypeDropdown.val() === "BATCH";
          const isVideo = $currentTarget.val() === "VIDEO";
          this.model.set({
            media_type: isVideo ? "VIDEO" : "DISPLAY"
          });
          this.applyChannelSettingsByMediaType(undefined, $currentTarget.val());
          if (isFacebook && isVideo) {
            this.onRtbClick("RTB");
            $supplyTypeDropdown.val("RTB");
          }
          this.setGoalTypeDDL(e);
          this.renderPartial(".goal-type");
          this.renderPartial(".supply-type-dropdown");
          this.prepareGoalTypeSelectBox();
          this.toggleGoalTypeSettings();
        };
      }
      const $frequency = this.$(".frequency");
      const $impressionAmount = this.$(".impression-amount");
      const $impressionInterval = this.$(".impression-interval");
      const $noCapToggle = $frequency.find(".no-cap-toggle");
      const $frequencyHeader = this.$(".frequency-cap");
      const $impressionHeader = this.$(".impression-cap");
      T1.Selectbox(this.$(".strategy-type .select"));
      this.prepareGoalTypeSelectBox();
      T1.Selectbox(this.$(".budget-pacing .select"));
      T1.Selectbox($(".frequency-interval .select", $frequency));
      T1.Selectbox(this.$(".monetary-interval .select"));
      T1.Tooltip($frequencyHeader, {
        offset: 5,
        delayIn: "100",
        delayOut: "100"
      });
      T1.Tooltip($impressionHeader, {
        offset: 5,
        delayIn: "100",
        delayOut: "100"
      });
      if (this.canViewVideoStrategy) {
        T1.TabButton(".media-type", {
          disabled: this.disableMediaType,
          buttons: {
            display: {
              label: "Display",
              dataValue: "DISPLAY",
              checked: this.strategyMediaType === "DISPLAY",
              disabled: this.disableMediaType,
              dataBind: "media_type",
              icon: "display-icon"
            },
            video: {
              label: "Video / Audio",
              dataValue: "VIDEO",
              checked: this.strategyMediaType === "VIDEO",
              disabled: this.disableMediaType,
              dataBind: "media_type",
              icon: "video-icon"
            }
          }
        });
      }
      const $supplyType = this.$(".supply-type");
      T1.Selectbox($(".select", $supplyType), {
        onChange: function (e) {
          var $targetValue = $(e.currentTarget).val();
          var $supplyInfo = $supplyType.find(".info-box");
          const $optimizeOptions = self.$("input:radio[class=.use-optimization][name=optimize]");
          var animateInfoBox = function animateInfoBox() {
            t1Animate({
              target: $supplyInfo,
              options: {
                opacity: $targetValue === "RMX_API" || $targetValue === "BATCH" ? "1" : "0"
              },
              duration: 400,
              easing: "ease"
            });
          };
          var toggleElementsBasedOnSupplyType = function toggleElementsBasedOnSupplyType() {
            const $selectors = self.$(".optimize-stack, .optimize, .budget, .pacing-frequency, .advanced-settings, .additional-info");
            if ($targetValue === "RMX_API") {
              $optimizeOptions.filter("[value=0]").prop("checked", true).trigger("click");
              $selectors.hide();
            } else {
              $selectors.show();
            }
          };
          animateInfoBox();
          toggleElementsBasedOnSupplyType();
        }
      });
      this.prepareBidForSelectBox();
      T1.Selectbox($(".frequency-type .select", $frequency), {
        onChange: function (args) {
          var value = args.target.value;
          if (value === "no-limit") {
            $noCapToggle.hide();
            $noCapToggle.attr("data-bind", "hidden");
          } else {
            $noCapToggle.show();
            $noCapToggle.removeAttr("data-bind");
            $noCapToggle.children().removeClass("disabled").removeAttr("disabled").trigger("liszt:updated");
          }
        }
      });
      T1.Selectbox($(".monetary-type .select", $el));
      T1.Selectbox($(".impression-type .select", $el), {
        onChange: function (e) {
          if (e.currentTarget.value === "no-limit") {
            $impressionAmount.css("visibility", "hidden");
            $impressionInterval.css("visibility", "hidden");
          } else {
            $impressionAmount.css("visibility", "visible");
            $impressionInterval.css("visibility", "visible");
          }
        }
      });
      this.addEventHubEvent({
        "startDatePicker:select": function () {
          self.onDatePickerSelect($el, "start");
        },
        "endDatePicker:select": function () {
          self.onDatePickerSelect($el, "end");
        },
        onCloseInlineEdit: function () {
          self.onDatePickerClose($el);
        }
      });
      this.toggleGoalTypeSettings({
        silent: true
      });
      this.activateInputMasks();
      this.setRoiStyling(this.goal_type === "roi");
    },
    events: {
      "click .active-status": "onStatusClick",
      "click .advanced-settings-toggle": "toggleAccordion",
      "click .use-optimization": "onUseOptimizationClick",
      "click .campaign-start-date": "onCampaignStartDateClick",
      "click .campaign-end-date": "onCampaignEndDateClick",
      "change .column-A .frequency-type": "onPacingFrequencyTypeChange",
      'click [data-bind="hasImpressionCap"]': "onImpressionCheck",
      "click .media-type input[type=radio]": "onMediaTypeClick",
      "change .goal-type": "onGoalTypeChange",
      "blur .roi-type": "onRoiChange",
      "blur .reach-settings .bid-aggressiveness .text": "onReachTypeMaxBidInputBlur",
      "changed .frequency-cap": "toggleFrequencyCapOptions",
      "change .frequency-type .select": "toggleFrequencyCapOptions",
      "click .supply-type-tracking": "onExternalMediaClick",
      "click .supply-type-facebook": "onFacebookClick",
      "click .non-supply-type-tracking": "onRtbClick",
      "change .impression-amount input": "formatImpressionAmount",
      "change .column-A-custom .monetary-type": "onPacingFrequencyTypeChange",
      "change .column-A-custom .impression-type": "onImpPacingTypeChange",
      "click .pacing-optimize": "toggleStrategyPacing",
      "click .bid-min-devices": "onBidMinDeviceCheckboxClick"
    },
    onFacebookClick: function (e) {
      var $el = this.el;
      var targetValue = $(e.currentTarget).val();
      var $budget = $el.find(".form-list.budget");
      var $monetaryPacing = $el.find(".monetary-interval .select");
      var $goalType = $el.find(".goal-type .select");
      var campaignGoalType = this.model.get("campaign").get("goal_type");
      this.supply_type = targetValue;
      this.use_optimization = $goalType.val() === "spend" ? 0 : 1;
      if ($budget.css("display") === "none") {
        this.onRtbClick(e);
      }
      if (targetValue === "BATCH") {
        if ($goalType.val() === "reach") {
          $el.find(".reach-settings").hide();
        }
        $el.find(".optimize-stack, .optimize, .budget, .new-frequency, .impression-pacing, " + ".reach-settings, .max-bid, .min-bid").hide();
        $el.find(".info-box-holder .info-box").slideUp();
        $monetaryPacing.val("day").attr("disabled", true).trigger("liszt:updated");
        if (campaignGoalType === "roi") {
          $goalType.val("cpa").trigger("liszt:updated");
          this.goal_type = "cpa";
          campaignGoalType = "cpa";
        } else if (campaignGoalType === "vcr" || campaignGoalType === "ctr" || campaignGoalType === "viewability_rate" || campaignGoalType === "vcpm") {
          $goalType.val(campaignGoalType).trigger("liszt:updated");
          this.goal_type = "cpc";
        } else {
          $goalType.val(campaignGoalType).trigger("liszt:updated");
          this.goal_type = campaignGoalType;
        }
        if (T1Permissions.check("feature", "batch_supply_fb_video") && campaignGoalType === "vcr") {
          $goalType.val("cpcv").trigger("liszt:updated");
          this.goal_type = "cpcv";
        }
        const options = batchGoalTypeOptions[campaignGoalType];
        dropdownList.goal_type.options = [];
        for (let i = 0; i < options.length; i++) {
          dropdownList.goal_type.options.unshift(goalTypes[options[i]]);
        }
        this.renderPartial(".goal");
        this.prepareGoalTypeSelectBox();
        this.prepareBidForSelectBox();
      }
      this.toggleGoalTypeSettings();
    },
    onBidMinDeviceCheckboxClick: function (e) {
      this.model.set({
        bid_min_devices: e.target.checked ? "2" : "1"
      });
    },
    onExternalMediaClick: function (e) {
      var $targetValue = $(e.currentTarget).val();
      const $optimizeOptions = this.$(".use-optimization").filter('[name="optimize"]');
      const $selectors = this.$(".optimize-stack, .optimize, .budget, .pacing-frequency, " + ".advanced-settings, .additional-info, .new-frequency, .reach-settings, .max-bid, .min-bid");
      this.supply_type = $targetValue;
      function toggleElementsBasedOnSupplyType() {
        if ($targetValue === "RMX_API") {
          $optimizeOptions.filter("[value=0]").prop("checked", true).trigger("click");
          $selectors.hide();
        }
      }
      toggleElementsBasedOnSupplyType();
    },
    onRtbClick: function (e) {
      var $targetValue = e.currentTarget ? $(e.currentTarget).val() : e;
      const $monetaryPacing = this.$(".monetary-interval .select");
      const $selectors = this.$(".optimize-stack, .optimize, .budget, .pacing-frequency, " + ".advanced-settings, .additional-info, .new-frequency, .budget-pacing, .max-bid, .min-bid");
      this.supply_type = $targetValue;
      function toggleElementsBasedOnSupplyType() {
        if ($targetValue !== "RMX_API") {
          $selectors.show();
          self.setMaxBidWm();
          $monetaryPacing.attr("disabled", false).trigger("liszt:updated");
        }
      }
      toggleElementsBasedOnSupplyType.call(this);
    },
    applyChannelSettingsByMediaType: function (modelJSON, mType) {
      this.strategyMediaType = modelJSON ? modelJSON.media_type : mType;
    },
    onMediaTypeClick: function (e) {
      const $supplyTypeDropdown = this.$(".supply-type-dropdown");
      const selectedMediaType = $(e.currentTarget).val();
      const isVideo = selectedMediaType === "VIDEO";
      const isVcrCampaign = this.campaign.goal_type === "vcr";
      const isFacebook = $supplyTypeDropdown.val() === "BATCH";
      if (!isVideo && isVcrCampaign) {
        if (isFacebook) {
          this.onRtbClick("RTB");
          $supplyTypeDropdown.val("RTB");
        }
      }
      if (isFacebook) {
        this.use_optimization = 1;
        this.$('[data-bind="goal_value"]').removeAttr("data-bind").removeData("bind");
        this.$(".goal .goal-value .input input").attr("data-bind", "goal_value").data("bind", "goal_value");
      }
      this.model.set({
        media_type: isVideo ? "VIDEO" : "DISPLAY"
      });
      this.applyChannelSettingsByMediaType(undefined, selectedMediaType);
      this.setGoalTypeDDL(e);
      this.renderPartial(".goal");
      this.renderPartial(".supply-type-dropdown");
      this.prepareGoalTypeSelectBox();
      this.prepareBidForSelectBox();
      this.toggleGoalTypeSettings();
      this.$(".goal-type .select, .bid-for .select").prop("disabled", this.isProgrammaticGuaranteed).trigger("liszt:updated");
    },
    onImpressionCheck: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $impCap = $currentTarget.closest("dl").find(".text");
      $impCap.trigger("keydown");
      if ($currentTarget.is(":checked")) {
        $impCap.removeAttr("disabled");
      } else {
        $impCap.val("").attr("disabled", true);
      }
    },
    onStatusClick: function (e) {
      if (this.isProgrammaticGuaranteed) {
        const status = parseInt($(e.currentTarget).attr("value"), 10);
        if (status === 1) {
          this.el.find(".pg-status-message").slideUp(defaultAnimSpeed);
        } else {
          this.el.find(".pg-status-message").slideDown(defaultAnimSpeed);
        }
      }
    },
    onDatePickerSelect: function ($details, startOrEnd) {
      $(`.campaign-${startOrEnd}-date`, $details).attr("checked", false);
    },
    onDatePickerClose: function ($details) {
      $(".campaign-start-date", $details).attr("checked", this.useCampaignStart);
      $(".campaign-end-date", $details).attr("checked", this.useCampaignEnd);
    },
    setGoalTypeDDL: function (e) {
      let options;
      var model = this.model;
      var strategySupplyType = model.get("supply_type") || this.supply_type;
      var strategyGoalType = model.get("goal_type");
      var campaignGoalType = this.campaign.goal_type;
      var goalType = this.use_optimization === 1 ? campaignGoalType : "spend";
      var isOriginalOptimizationSelected = this.use_optimization === parseInt(this.model.get("use_optimization"), 10);
      var channel = model.get("media_type") || "DISPLAY";
      if (strategySupplyType !== "BATCH") {
        if (this.use_optimization === 1) {
          options = goalTypeOptions[goalType];
          if (goalType === "spend") {
            strategyGoalType = "reach";
            goalType = "reach";
          }
        } else {
          options = goalTypeOptionsNoOptimization;
        }
      } else if (strategySupplyType === "BATCH") {
        options = batchGoalTypeOptions[campaignGoalType];
        goalType = fbStrategyGoalTypes[campaignGoalType] || campaignGoalType;
      } else {
        if (this.use_optimization === 1 && goalType === "spend") {
          options = goalTypeOptions.reach;
          strategyGoalType = "reach";
          goalType = "reach";
        } else {
          options = goalTypeOptions[goalType];
        }
      }
      if (e === undefined && strategyGoalType !== undefined) {
        goalType = strategyGoalType;
      }
      if (isOriginalOptimizationSelected) {
        this.goal_type = strategyGoalType;
      } else {
        if (goalType === "vcr" && channel !== "VIDEO") {
          goalType = "cpc";
        }
        this.goal_type = goalType;
      }
      dropdownList.goal_type.options = [];
      for (let i = 0; i < options.length; i++) {
        if (channel === "VIDEO" || goalTypes[options[i]].value !== "vcr") {
          dropdownList.goal_type.options.unshift(goalTypes[options[i]]);
        }
      }
      this.setMaxBidWm();
      this.toggleGoalTypeSettings(!e ? {
        silent: true
      } : {});
    },
    setMaxBidWm: function () {
      var strategyGoalType = this.goal_type;
      var $maxBidWmHeader = this.el.find(".max-bid-wm");
      if (this.use_optimization === 1) {
        if (strategyGoalType !== "reach" && strategyGoalType !== "spend") {
          this.max_bid_wm = this.model.get("max_bid_wm") || "";
          this.displayMaxBidWm = true;
          this.renderPartial(".max-bid-wm");
          T1.Tooltip($maxBidWmHeader, {
            offset: 5,
            delayIn: "100",
            delayOut: "100"
          });
        } else {
          this.displayMaxBidWm = false;
        }
      } else {
        this.displayMaxBidWm = false;
      }
    },
    setRoiStyling: function (isRoi) {
      var $goalContainer = this.el.find(".goal");
      var methodName = isRoi ? "addClass" : "removeClass";
      $goalContainer[methodName]("goal-roi");
    },
    onGoalTypeChange: function (e) {
      var targetValue = e.target.value;
      const maxBidElem = this.$(".max-bid-wm-text .text");
      var isOriginalGoalType = targetValue === this.model.get("goal_type");
      var maxBidValue = maxBidElem.val();
      prevGoalType = this.model.get("goal_type");
      this.goal_type = targetValue;
      switch (isOriginalGoalType) {
        case true:
          if (targetValue === "roi") {
            this.roi_target = this.model.get("roi_target");
            this.goal_value = "";
          } else {
            this.goal_value = this.model.get("goal_value");
            this.roi_target = "";
          }
          break;
        case false:
          if (targetValue === "roi") {
            this.roi_target = "";
          } else {
            this.goal_value = "";
          }
          break;
      }
      this.setRoiStyling(targetValue === "roi");
      this.toggleGoalTypeSettings();
      this.toggleFrequencyCapOptions();
      this.renderPartial(".goal");
      this.renderPartial(".advanced-settings-c .form-stack");
      this.hideImpressionsForFacebook(targetValue);
      this.prepareGoalTypeSelectBox();
      this.prepareBidForSelectBox();
      if (targetValue !== "reach" && targetValue !== "spend") {
        this.displayMaxBidWm = true;
        if (maxBidValue) {
          maxBidElem.val(maxBidValue);
          if (!maxBidElem.hasClass("error")) {
            this.$(".max-bid-wm-text .input").removeAttr("original-title");
          }
        } else {
          this.setMaxBidWm();
        }
      } else {
        this.displayMaxBidWm = false;
      }
      if (targetValue !== "viewability_rate" || targetValue !== "vcpm") {
        this.toggleGoalTypeError(false);
      }
    },
    hideImpressionsForFacebook: function (targetValue) {
      var isFacebook = this.$(".supply-type-dropdown").val() === "BATCH";
      var isSupplyTypeBatch = this.model.get("supply_type") === "BATCH";
      const $reachSettings = this.$(".reach-settings");
      const $advancedSettings = this.$(".advanced-settings");
      if ((isFacebook || isSupplyTypeBatch) && targetValue === "reach") {
        $reachSettings.hide();
      }
      if ((isFacebook || isSupplyTypeBatch) && targetValue !== "spend") {
        $advancedSettings.hide();
      }
    },
    setEditViewForFacebook: function () {
      const $advancedSetting = this.$(".advanced-settings");
      const $additionalInfo = this.$(".additional-info");
      var $maxBidAdvanced = $additionalInfo.find(".max-bid-adv");
      var $minBidAdvanced = $additionalInfo.find(".min-bid-adv");
      const $monetaryPacing = this.$(".monetary-interval .select");
      const $goalType = this.$(".goal-type .select").val() || this.model.get("goal_type");
      const $goalValueInput = this.$(".goal .goal-value .input input");
      if ($goalType === "spend") {
        this.use_optimization = 0;
        this.$('[data-bind="max_bid"]').removeAttr("data-bind").removeData("bind");
        $goalValueInput.attr("data-bind", "max_bid").data("bind", "max_bid");
        $advancedSetting.show();
        $additionalInfo.show();
        $maxBidAdvanced.hide();
        $minBidAdvanced.hide();
      } else {
        this.use_optimization = 1;
        this.$('[data-bind="goal_value"]').removeAttr("data-bind").removeData("bind");
        $goalValueInput.attr("data-bind", "goal_value").data("bind", "goal_value");
      }
      if ($goalType === "reach") {
        this.$(".reach-settings").hide();
      }
      this.$(".optimize-stack, .optimize, .budget, .new-frequency, " + ".impression-pacing, .reach-settings, .max-bid, .min-bid").hide();
      $monetaryPacing.attr("disabled", true).trigger("liszt:updated");
      this.renderPartial(".goal");
      this.renderPartial(".advanced-settings-c .form-stack");
      this.prepareGoalTypeSelectBox();
      this.prepareBidForSelectBox();
    },
    setEditViewForMarketPlace: function () {
      const $goalValueInput = this.$(".goal .spend-bid-price .input input");
      this.$(".max-bid, .min-bid").hide();
      this.$('[data-bind="max_bid"]').removeAttr("data-bind").removeData("bind");
      $goalValueInput.attr("data-bind", "max_bid").data("bind", "max_bid");
    },
    toggleFrequencyCapOptions: function () {
      var modelData = this.model.toJSON();
      var campaignData = this.campaign;
      const freq = this.$(".frequency");
      var freqControlsSelects = freq.find(".frequency-type .select, .frequency-interval .select");
      var freqControlType = freq.find(".frequency-type .select");
      var freqControlAmount = freq.find(".frequency-amount");
      var freqControlAmountInput = freqControlAmount.find(".text");
      var freqControlAmountLabel = freqControlAmount.find(".frequency-amount-label");
      const freqOptimization = freq.find(".frequency-cap").length ? freq.find(".frequency-cap") : false;
      var freqOptimizationToggle = false;
      var noLimitWarning = freq.find(".no-campaign-cap-warning");
      var campGoalType = campaignData.goal_type;
      var disableFrequencyControls = function () {
        if (freqOptimization) {
          freqOptimization.find("[checked]").removeAttr("checked");
          freqOptimization.find("[value=0]").attr("checked", "checked");
          freqOptimization.attr("disabled", "disabled");
        }
      };
      var enableCustomControls = function (self) {
        self.useCampaignFreqCap = true;
        noLimitWarning.hide();
        freqControlsSelects.removeAttr("disabled").trigger("liszt:updated");
        freqControlAmountInput.removeAttr("disabled");
        freqControlAmountLabel.removeClass("disabled");
        if (freqControlType.val() === "no-limit") {
          freqControlAmountInput.attr("disabled", "disabled");
          freqControlAmountLabel.addClass("disabled");
        }
      };
      var disableCustomControls = function (self) {
        self.useCampaignFreqCap = false;
        freqControlAmountInput.attr("disabled", "disabled");
        freqControlAmountLabel.addClass("disabled");
        freqControlAmountInput.attr("value", "");
        freqControlsSelects.val(0).attr("disabled", "disabled").trigger("liszt:updated");
        freqControlAmountInput.removeClass("error");
        freq.find(".frequency-interval .chzn-single").removeClass("error");
        if (campaignData.frequency_optimization === "0" && campaignData.frequency_type === "no-limit") {
          noLimitWarning.show();
        }
      };
      if (this.isProgrammaticGuaranteed) {
        disableFrequencyControls();
        freqControlType.attr("disabled", "disabled").trigger("liszt:updated");
      } else if (this.use_optimization) {
        let freqCustomControls;
        if (freqOptimization) {
          freqOptimizationToggle = campGoalType !== "reach" && campGoalType !== "spend";
          if (freqOptimizationToggle) {
            freqOptimization.removeAttr("disabled");
          } else {
            disableFrequencyControls();
          }
        }
        if (freqOptimization.val && freqOptimization.val()) {
          freqCustomControls = freqOptimization.val() === "0";
        } else {
          if (modelData.frequency_optimization === undefined) {
            freqCustomControls = modelData.id ? campaignData.frequency_optimization === "0" : true;
          } else {
            freqCustomControls = modelData.frequency_optimization === "0";
          }
        }
        if (freqCustomControls) {
          enableCustomControls(this);
        } else {
          disableCustomControls(this);
        }
      } else {
        disableFrequencyControls();
        enableCustomControls(this);
      }
    },
    onUseOptimizationClick: function (e) {
      this.use_optimization = parseInt($(e.currentTarget).attr("value"), 10);
      this.setGoalTypeDDL(e);
      const isOriginalOptimizationSelected = this.use_optimization === parseInt(this.model.get("use_optimization"), 10);
      this.goal_value = isOriginalOptimizationSelected ? this.model.get("goal_value") : "";
      this.max_bid = isOriginalOptimizationSelected ? this.model.get("max_bid") : "";
      this.min_bid = isOriginalOptimizationSelected ? this.model.get("min_bid") : "";
      this.roi_target = isOriginalOptimizationSelected ? this.model.get("roi_target") : "";
      this.max_bid_wm = isOriginalOptimizationSelected ? this.model.get("max_bid_wm") : "";
      this.renderPartial(".strategy-budget-stack");
      this.renderPartial(".advanced-settings-c .form-stack");
      this.prepareGoalTypeSelectBox();
      this.prepareBidForSelectBox();
      this.toggleGoalTypeSettings();
      this.updateBidForTextDisplay();
      this.toggleFrequencyCapOptions();
      this.setRoiStyling(this.goal_type === "roi");
      this.setMaxBidWm();
      this.toggleStrategyPacing(e);
    },
    prepareBidForSelectBox: function () {
      var self = this;
      T1.Selectbox(this.$(".bid-for .select"), {
        onChange: function () {
          self.updateBidForTextDisplay();
        }
      });
    },
    updateBidForTextDisplay: function () {
      const $bidForSelect = this.$(".bid-for .select");
      var value = $bidForSelect.val();
      const bid_for = value === 0 ? "Total Spend" : "Media Only";
      this.userSelectedIsMediaOnly = String(value);
      this.updateDataBind({
        bid_for: bid_for,
        bid_for_reach: bid_for
      }, this.el);
    },
    prepareGoalTypeSelectBox: function () {
      T1.Selectbox(this.$(".goal-type .select"));
    },
    onReachTypeMaxBidInputBlur: function (e) {
      var $target = $(e.target);
      const maxUsers = 100;
      this.preventDefault(e);
      if ($target.length) {
        const userVal = parseFloat($target.val());
        if (isNaN(userVal)) {
          $target.val("0");
        } else {
          if (userVal > maxUsers) {
            $target.val("100");
          } else if (userVal < 0) {
            $target.val("0");
          }
        }
      }
    },
    toggleGoalTypeSettings: function (opts) {
      var self = this;
      var goalType = this.goal_type || "";
      const $reachSetting = this.$(".reach-settings");
      const $budgetStack = this.$(".strategy-budget-stack");
      const $advancedSetting = this.$(".advanced-settings");
      const $additionalInfo = this.$(".additional-info");
      var $maxBidReachMode = $reachSetting.find(".max-bid .text");
      var $minBidReachMode = $reachSetting.find(".min-bid .text");
      var $aggressivenessReachMode = $reachSetting.find(".bid-aggressiveness .text");
      var $aggressivenessNonReachMode = $additionalInfo.find(".bid-aggressiveness-text");
      var $maxBidWm = $additionalInfo.find(".max-bid-wm-text .text");
      var $maxBidAdvanced = $additionalInfo.find(".max-bid-adv");
      var $minBidAdvanced = $additionalInfo.find(".min-bid-adv");
      var $maxBidNoAdvanced = $budgetStack.find(".max-bid");
      var $minBidNoAdvanced = $budgetStack.find(".min-bid");
      var $maxBidAdvancedField = $maxBidAdvanced.find(".text");
      var $minBidAdvancedField = $minBidAdvanced.find(".text");
      var $maxBidNoAdvancedField = $maxBidNoAdvanced.find(".bid-price .text");
      var $minBidNoAdvancedField = $minBidNoAdvanced.find(".bid-price .text");
      const $curContainer = this.$(".goal-value .cur");
      const $pctContainer = this.$(".goal-value .pct");
      const $spendBidPriceField = this.$(".spend-bid-price .input input");
      const $goalValueField = this.$(".goal .goal-value .input input");
      const $openContainer = this.$(".info-box-holder .info-box");
      const $myMsgContainer = this.$(`.${goalType}-info-box-holder .info-box `);
      const $roiOnly = this.$(".roi-only");
      const $notRoi = this.$(".not-roi");
      const $supplyTypeDropdown = this.$(".supply-type-dropdown");
      var isBatch = $supplyTypeDropdown.val() === "BATCH" || this.model.get("supply_type") === "BATCH";
      var isExternalMedia = $supplyTypeDropdown.val() === "RMX_API" || this.model.get("supply_type") === "RMX_API";
      var t1Masks = T1.Form.Masks;
      const $goalValueInput = this.$(".goal-value");
      function resetDataBindAttr($targetEl, targetFieldName) {
        if ($targetEl.length) {
          self.$(`[data-bind="${targetFieldName}"]`).removeAttr("data-bind").removeData("bind");
          $targetEl.attr("data-bind", targetFieldName).data("bind", targetFieldName);
        }
      }
      $openContainer.stop(true, true).slideUp(defaultAnimSpeed);
      if (goalType === "reach") {
        resetDataBindAttr($maxBidReachMode, "max_bid");
        resetDataBindAttr($minBidReachMode, "min_bid");
        resetDataBindAttr($aggressivenessReachMode, "bid_aggressiveness");
        resetDataBindAttr($maxBidAdvancedField, "max_bid_discard");
        resetDataBindAttr($minBidAdvancedField, "min_bid_discard");
        resetDataBindAttr($aggressivenessNonReachMode, "bid_aggressiveness_discard");
        resetDataBindAttr($maxBidWm, "max_bid_wm");
        $advancedSetting.hide();
        $additionalInfo.hide();
        $maxBidAdvanced.show();
        $maxBidNoAdvanced.hide();
        $minBidAdvanced.show();
        $minBidNoAdvanced.hide();
        this.currMask = true;
        if (!isBatch) {
          $reachSetting.slideDown();
        }
      } else {
        resetDataBindAttr($maxBidReachMode, "max_bid_discard");
        resetDataBindAttr($minBidReachMode, "min_bid_discard");
        resetDataBindAttr($aggressivenessReachMode, "bid_aggressiveness_discard");
        resetDataBindAttr($maxBidAdvancedField, "max_bid");
        resetDataBindAttr($minBidAdvancedField, "min_bid");
        resetDataBindAttr($aggressivenessNonReachMode, "bid_aggressiveness");
        resetDataBindAttr($maxBidWm, "max_bid_wm");
        $myMsgContainer.stop(true, true).slideDown(defaultAnimSpeed);
        $reachSetting.slideUp();
        if (goalType === "roi") {
          $notRoi.hide();
          $roiOnly.show();
        } else {
          $roiOnly.hide();
          $notRoi.show();
        }
        if (goalType !== "spend") {
          resetDataBindAttr($maxBidNoAdvancedField, "max_bid");
          resetDataBindAttr($minBidNoAdvancedField, "min_bid");
          $maxBidAdvanced.hide();
          $maxBidNoAdvanced.show();
          $minBidAdvanced.hide();
          $minBidNoAdvanced.show();
        }
        if (isBatch) {
          $maxBidNoAdvanced.hide();
          $minBidNoAdvanced.hide();
        } else {
          $advancedSetting.show();
          $additionalInfo.show();
        }
        if (goalType === "spend" || this.use_optimization === 0) {
          resetDataBindAttr($spendBidPriceField, "max_bid");
          $maxBidNoAdvanced.hide();
          $minBidNoAdvanced.hide();
          if (isBatch) {
            this.use_optimization = 0;
            $advancedSetting.show();
            $additionalInfo.show();
            $maxBidAdvanced.hide();
            $minBidAdvanced.hide();
          }
        } else {
          if (isBatch) {
            this.use_optimization = 1;
            $advancedSetting.hide();
            $additionalInfo.hide();
          }
        }
        if (goalTypePCT.includes(this.goal_type)) {
          if (goalTypePCT.includes(prevGoalType)) {
            return;
          }
          $goalValueField.data("precision", twoDigits);
          this.currMask = false;
          $curContainer.addClass("hidden");
          $pctContainer.removeClass("hidden");
        } else {
          this.currMask = true;
          if (goalTypePCT.includes(prevGoalType)) {
            return;
          }
          $goalValueField.data("precision", fourDigits);
          $curContainer.removeClass("hidden");
          $pctContainer.addClass("hidden");
        }
        t1Masks.attach($("input", $goalValueInput), this.getCurrencyCode());
      }
      if (isExternalMedia) {
        resetDataBindAttr($goalValueField, "max_bid");
      }
      if (this.isProgrammaticGuaranteed) {
        resetDataBindAttr($spendBidPriceField, "goal_value");
      }
      if (!opts || !opts.silent) {
        this.isFormElementsChanged = true;
      }
    },
    onPacingFrequencyTypeChange: function (e) {
      const $el = this.el;
      if (e.target.value === "asap") {
        $(`<div>${pacingASAPCaution}</div>`).dialog({
          autoOpen: true,
          modal: true,
          title: "<b></b>ASAP pacing confirmation",
          dialogClass: "w-PacingASAPAlert",
          buttons: [{
            text: "Confirm",
            class: "confirm",
            click: function () {
              $(this).dialog("close");
            }
          }, {
            text: "Cancel",
            class: "cancel",
            click: function () {
              $el.find(".column-A .form-select.frequency-type .select").val("even").trigger("liszt:updated");
              $el.find(".column-A-custom .form-select.monetary-type .select").val("even").trigger("liszt:updated");
              $(this).dialog("close");
            }
          }],
          close: function () {
            $(this).remove();
          }
        });
      }
    },
    onImpPacingTypeChange: function (e) {
      const $el = this.el;
      const $impressionAmount = this.$(".impression-amount");
      const $impressionInterval = this.$(".impression-interval");
      if (e.target.value === "no-limit") {
        $impressionAmount.css("visibility", "hidden");
        $impressionInterval.css("visibility", "hidden");
      } else {
        $impressionAmount.css("visibility", "visible");
        $impressionInterval.css("visibility", "visible");
      }
      if (e.target.value === "asap") {
        $(`<div>${impPacingASAPCaution}</div>`).dialog({
          autoOpen: true,
          modal: true,
          title: "<b></b>ASAP pacing confirmation",
          dialogClass: "w-PacingASAPAlert",
          buttons: [{
            text: "Confirm",
            class: "confirm",
            click: function () {
              $(this).dialog("close");
            }
          }, {
            text: "Cancel",
            class: "cancel",
            click: function () {
              $el.find(".column-A-custom .form-select.impression-type .select").val("even").trigger("liszt:updated");
              $(this).dialog("close");
            }
          }],
          close: function () {
            $(this).remove();
          }
        });
      }
    },
    toggleStrategyPacing: function (e) {
      if (this.use_optimization === 1) {
        const pacingVal = parseInt($(e.currentTarget).attr("value"), 10);
        this.customPacing = pacingVal === 0;
      } else {
        this.pacingOptimization = true;
        this.customPacing = true;
      }
      this.userChanged = true;
      this.renderPartial(".pacing-frequency");
      T1.Selectbox(this.$(".budget-pacing .select"));
    },
    onCampaignStartDateClick: function (e) {
      const {
        model: model,
        campaign: campaign
      } = this;
      this.useCampaignStart = $(e.currentTarget).is(":checked");
      model.startDate = Date.parse(campaign.start_date);
      this.startTime = model.startDate.toString(this.displayTimeFormat);
      this.renderPartial(".date-display");
    },
    onCampaignEndDateClick: function (e) {
      const {
        model: model,
        campaign: campaign
      } = this;
      this.useCampaignEnd = $(e.currentTarget).is(":checked");
      model.endDate = Date.parse(campaign.end_date);
      this.endTime = model.endDate.toString(this.displayTimeFormat);
      this.renderPartial(".date-display");
    },
    toggleAccordion: function (event) {
      var currentTarget = $(event.currentTarget);
      var target = currentTarget.find("a");
      var $maxBidWmHeader = this.el.find(".max-bid-wm");
      T1.Tooltip($maxBidWmHeader, {
        offset: 5,
        delayIn: "100",
        delayOut: "100"
      });
      event.preventDefault();
      const parent = target.parent();
      const accordionDataData = parent.data("accordion");
      const accordionTarget = accordionDataData ? this.el.find(`.${accordionDataData}`) : null;
      if (accordionDataData && accordionTarget) {
        const isVisible = accordionTarget.is(":visible");
        this.accordionState[accordionDataData] = isVisible ? "close" : "open";
        if (isVisible) {
          parent.removeClass("open");
          parent.addClass("close");
          accordionTarget.slideUp();
          currentTarget.animate({
            paddingTop: "0"
          }, accordionAnimSpeed);
        } else {
          parent.removeClass("close");
          parent.addClass("open");
          accordionTarget.slideDown();
          currentTarget.animate({
            paddingTop: "32px"
          }, accordionAnimSpeed);
        }
      }
    },
    formatImpressionAmount: function (e) {
      var $target = $(e.currentTarget);
      var impAmount = $target.val().replace(/,/g, "");
      $target.val($.trim(formatsRolledUpNumbersNoAbbreviation(impAmount)));
    },
    unload: function () {
      this.accordionState = {
        "advanced-settings-c": "close"
      };
      this.prepareDatePicker();
      this.userSelectedIsMediaOnly = null;
      this.$("strand-tooltip").each(function (i, tooltip) {
        if (tooltip && tooltip.close) {
          tooltip.close();
        }
      });
      $(document).unbind("mousedown", CampaignStrategyUtils.closeStickyTooltips);
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.model;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    preparePostData: function (formValues) {
      var model = this.model;
      var useOptimization = formValues.use_optimization;
      var supplyType = formValues.supply_type || model.get("supply_type");
      var goalType = formValues.goal_type || model.get("goal_type");
      if (this.campaign.bid_min_devices === "2") {
        formValues.bid_min_devices = this.campaign.bid_min_devices;
      } else {
        formValues.bid_min_devices = this.model.get("bid_min_devices") || "1";
      }
      if (!formValues.use_campaign_start) {
        formValues.start_date = `${model.startDate.toString("s")} ${model.zoneName}`;
      }
      if (!formValues.use_campaign_end) {
        formValues.end_date = `${model.endDate.toString("s")} ${model.zoneName}`;
      }
      if (formValues.frequency_optimization === "1") {
        formValues.frequency_type = "standard";
        formValues.frequency_amount = 0;
        formValues.frequency_interval = "not-applicable";
      }
      if (this.hasStarted) {
        delete formValues.start_date;
      }
      if (goalType !== "reach" && useOptimization === false) {
        formValues.bid_aggressiveness = "50";
      }
      if (supplyType === "BATCH") {
        delete formValues.frequency_type;
        delete formValues.frequency_interval;
        delete formValues.frequency_amount;
        delete formValues.frequency_optimization;
        delete formValues.use_optimization;
        delete formValues.impression_pacing_amount;
        delete formValues.impression_pacing_type;
        delete formValues.bid_aggressiveness;
        formValues.budget = 10;
        if (!formValues.supply_type) {
          formValues.supply_type = supplyType;
        }
      }
      if (supplyType === "RMX_API") {
        formValues.budget = 10;
        formValues.pacing_amount = 1;
        delete formValues.pacing_interval;
        delete formValues.pacing_type;
        delete formValues.frequency_type;
        delete formValues.frequency_interval;
        delete formValues.frequency_amount;
        delete formValues.hasImpressionCap;
        delete formValues.impression_pacing_interval;
        delete formValues.impression_pacing_type;
        delete formValues.impression_pacing_amount;
      }
      if (supplyType === "PROGRAMMATIC_GUARANTEED") {
        const infinity = 9999999.99;
        formValues.max_bid = infinity;
        formValues.pacing_amount = formValues.budget || infinity;
        formValues.pacing_interval = "day";
      }
      delete formValues.impression_cap;
      if (formValues.impression_pacing_amount) {
        formValues.impression_pacing_amount = formValues.impression_pacing_amount.replace(/,/g, "");
      }
      if (formValues.pacing_optimization_type) {
        formValues.pacing_amount = "";
        model.set({
          pacing_amount: ""
        });
        formValues.impression_pacing_amount = "";
        model.set({
          impression_pacing_amount: ""
        });
      } else {
        formValues.pacing_optimization_type = "";
        formValues.pacing_optimization_amount = "";
        model.set({
          pacing_optimization_amount: ""
        });
      }
      if (formValues.impression_pacing_type === "no-limit") {
        delete formValues.impression_pacing_interval;
        delete formValues.impression_pacing_amount;
      }
      if (goalType === "reach") {
        delete formValues.max_bid_wm;
      }
      if (goalType === "spend") {
        formValues.min_bid = "";
      }
      if (formValues.max_bid_wm === "") {
        model.set({
          max_bid_wm: " "
        });
      }
      delete formValues.max_bid_discard;
      delete formValues.min_bid_discard;
      delete formValues.bid_aggressiveness_discard;
      delete formValues.pacingOptimization;
      return formValues;
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    toggleGoalTypeError: function (toShow) {
      var stateFunctionName = toShow ? "show" : "hide";
      this.el.find(".viewability-inline-error-message")[stateFunctionName]();
    },
    setGoalTypeErrorMessage: function (message) {
      var element = this.el.find(".viewability-inline-error-message")[0];
      if (element) {
        Polymer.dom(element).textContent = message;
      }
    },
    toggleBidDevicesError: function (toShow) {
      var stateFunctionName = toShow ? "show" : "hide";
      this.$(".bid-device-inline-error-message")[stateFunctionName]();
    },
    setBidDevicesErrorMessage: function (message) {
      var $element = this.$(".bid-device-inline-error-message")[0];
      if ($element) {
        Polymer.dom($element).textContent = message;
      }
    },
    postData: function (formValues, successCallback, statusInvalid, conflictCallback) {
      var self = this;
      var strategy = this.model;
      var isNew = strategy.id === undefined;
      var goalValue;
      function setDefaultTargetValues(success) {
        var stratTargetDims = new StrategyTargetDimensions({
          id: strategy.id
        });
        var targetDims = stratTargetDims.get("target_dimensions");
        var regions = new TargetValues([], {
          dimension: "REGN"
        });
        stratTargetDims.strategy = strategy;
        const persistedLocations = self.defaultLocationHelper.getSettings();
        const postObj = self.defaultLocationHelper.convertFromPrefText(persistedLocations.include, persistedLocations.exclude);
        const defaultUsSettings = OrgUtils.getOrgSettings().default_us_settings;
        if (_.isEmpty(postObj) !== true) {
          stratTargetDims.saveTargetValues(success, undefined, undefined, postObj);
        } else if (defaultUsSettings) {
          regions.search.set({
            fetchRequired: true,
            isFetchSilent: true,
            params: [{
              searchField: "id",
              term: "251"
            }],
            success: function () {
              var usa = regions.get("251");
              usa.set({
                action: "include"
              });
              regions.reset([usa], {
                silent: true
              });
              targetDims.add({
                INCLUDE_OP: "OR",
                EXCLUDE_OP: "AND",
                target_value: regions.toJSON(),
                code: "REGN",
                id: "REGN"
              });
              stratTargetDims.saveTargetValues(success);
              T1.EventHub.publish("strategy-create-edit:reloadSubViews");
            }
          });
        } else {
          stratTargetDims.saveTargetValues(success);
        }
        self.setMaxBidWm();
      }
      formValues.campaign_id = this.campaign.id;
      strategy.campaign = this.campaign;
      if (formValues.ads_txt_verified === "INHERITED") {
        formValues.ads_txt_verified = "";
      }
      function refreshModelData() {
        if (self.useAllocPacing && self.customPacing && self.model.get("pacing_optimization_type")) {
          self.model.unset("pacing_optimization_type");
        }
        if (strategy.id) {
          self.disableSupplyType = true;
          self.$(".supply-type-dropdown").prop("disabled", true);
          if (self.canViewVideoStrategy) {
            self.disableMediaType = true;
            self.$(".media-type").addClass("disabled").find(".ui-button").addClass("ui-button-disabled ui-state-disabled").off();
            self.$("input[name=media-type]:radio").attr("disabled", true);
          }
        }
        if (formValues.goal_value) {
          goalValue = (typeof formValues.goal_value === "string" ? parseFloat(formValues.goal_value.replace(/,/g, "")) : formValues.goal_value).toFixed(twoDigits);
        }
        self.updateDataBind({
          goal_value: goalValue,
          max_bid: strategy.get("max_bid"),
          min_bid: strategy.get("min_bid"),
          roi_target: strategy.get("roi_target"),
          max_bid_wm: strategy.get("max_bid_wm") || "",
          bid_aggressiveness: self.getBidAgressivenessForDisplay()
        }, self.el);
        self.userSelectedIsMediaOnly = null;
      }
      function onStatusInvalid(errorsArr) {
        var indexOfGoalTypeError = CampaignStrategyUtils.indexOfError(errorsArr, "goal_type");
        if (indexOfGoalTypeError >= 0) {
          self.setGoalTypeErrorMessage(errorsArr[indexOfGoalTypeError].message);
          self.toggleGoalTypeError(true);
          CampaignStrategyUtils.removeError(errorsArr, "goal_type");
        } else {
          self.toggleGoalTypeError(false);
        }
        const indexOfBidDevicesError = CampaignStrategyUtils.indexOfError(errorsArr, "bid_min_devices");
        if (indexOfBidDevicesError >= 0) {
          self.setBidDevicesErrorMessage(errorsArr[indexOfBidDevicesError].message);
          self.toggleBidDevicesError(true);
          CampaignStrategyUtils.removeError(errorsArr, "bid_min_devices");
        } else {
          self.toggleBidDevicesError(false);
        }
        statusInvalid(errorsArr);
      }
      strategy.createEdit(formValues, {
        success: function () {
          T1.EventHub.publish("strategy-create-edit:reloadSubViews");
          self.toggleGoalTypeError(false);
          self.toggleBidDevicesError(false);
          function createEditSuccess() {
            successCallback();
            refreshModelData();
            if (isNew) {
              strategy.trigger("strategy:created");
            }
          }
          if (isNew) {
            if (strategy.get("supply_type") === "BATCH") {
              self.assignSupplySourcesForFacebook(strategy);
            }
            if (!self.isProgrammaticGuaranteed) {
              setDefaultTargetValues(createEditSuccess);
            } else {
              createEditSuccess();
            }
          } else {
            createEditSuccess();
          }
        },
        statusInvalid: onStatusInvalid,
        conflict: conflictCallback
      });
    },
    onVersionConflictClose: function () {
      this.model.unset("impression_cap", {
        silent: true
      });
      this.model.fetch({
        success: data => {
          this.load();
          this.updateDataBind({
            name: data.get("name")
          }, this.el);
        }
      });
    },
    assignSupplySourcesForFacebook: function (strategyModel) {
      var batchSupplies = new BatchSupplySources(false, {
        fetchOptions: {
          full: "*"
        },
        isPaginated: false
      });
      var supplySources = new SupplySources(false, {
        isPaginated: false,
        fetchOptions: {
          currency_code: strategyModel.get("currency_code")
        }
      });
      if (T1Permissions.check("feature", "batch_supply_fb_video") && this.strategyMediaType === "VIDEO") {
        batchSupplies.fetchOptions = $.extend(batchSupplies.fetchOptions, {
          q: "has_video==1"
        });
      }
      supplySources.id = strategyModel.id;
      supplySources.contentType = "supply_source";
      batchSupplies.fetch({
        success: data => {
          let models = data.models;
          if (!T1Permissions.check("feature", "batch_supply_fb_video") || this.campaign.goal_type !== "vcr") {
            models = models.filter(model => {
              const name = model.get("name");
              return name !== "Facebook In-Stream Video" && name !== "Facebook Suggested Video";
            });
          }
          supplySources.add(models, {
            silent: true
          });
          supplySources.saveStrategySupplySources(false);
        }
      });
    },
    onRoiChange: function (e) {
      this.updateDataBind({
        equivalent_target_cpa: formatCurrency(CampaignStrategyUtils.calculateEquivalantTarget(this.campaign.goal_value, e.target.value), null, this.getCurrencyCode())
      }, this.el);
    },
    disableRoiTargetingInputs: function () {
      const $optomizeRB = this.$(".optimize .use-optimization");
      if (this.hasStarted) {
        $optomizeRB.attr("disabled", "disabled");
      } else {
        $optomizeRB.removeAttr("disabled", "disabled");
      }
    },
    serialize: function (targetModel) {
      var dateFormat = "MM/dd/yy";
      var model = targetModel === undefined ? this.model : targetModel;
      var campaign = this.campaign;
      var modelData = model.toJSON();
      var campaignData = campaign;
      var advertiserData = campaignData.advertiser || {};
      var timezone = this.timezones && this.timezones.getByZoneName(model.zoneName);
      const strategyName = this.$(".text.strategy-name").val();
      var isCuratedMarketsTeam = T1Permissions.check("feature", "supply_type_mkt");
      var isFacebookVideoPermitted = T1Permissions.check("feature", "batch_supply_fb_video");
      var campaignReachOrSpend = campaignData.goal_type === "reach" || campaignData.goal_type === "spend";
      var baseData = {
        campaignName: campaign.name,
        advancedSettingsState: this.accordionState["advanced-settings-c"],
        strategyTypeOptions: dropdownList.type.options,
        goalTypeOptions: dropdownList.goal_type.options,
        bidForOptions: dropdownList.bid_price_is_media_only.options,
        pacingTypeOptions: dropdownList.pacing_type.options,
        impressionPacintOptions: dropdownList.impression_pacing_type.options,
        pacingIntervalOptions: dropdownList.pacing_interval.options,
        impressionIntervalOptions: dropdownList.impression_pacing_interval.options,
        frequencyTypeOptions: dropdownList.frequency_type.options,
        frequencyIntervalOptions: dropdownList.frequency_interval.options,
        dateTimeDisplay: getStartEndDatesDisplay({
          startDate: model.startDate,
          endDate: model.endDate,
          dateFormat: this.displayDateFormat,
          timeFormat: this.displayTimeFormat
        }),
        timeZone: timezone && timezone.toJSON() || {},
        startDateAsString: modelData.start_date ? Date.parse(modelData.start_date).toString(dateFormat) : undefined,
        endDateAsString: modelData.end_date ? Date.parse(modelData.end_date).toString(dateFormat) : undefined,
        disableCampaignStart: this.disableCampaignStart,
        disableCampaignEnd: this.disableCampaignEnd,
        useCampaignStart: this.useCampaignStart,
        useCampaignEnd: this.useCampaignEnd,
        canViewVideoStrategy: this.canViewVideoStrategy,
        hasStarted: this.hasStarted,
        bidMinDevices_val: modelData.bid_min_devices === "2",
        infoBoxMessageLines: ["Please contact your MediaMath representative to determine if your account is " + "enrolled for this strategy type."],
        campaignNoCap: campaign.frequency_type === "no-limit" && campaign.frequency_optimization !== "1" && !this.isProgrammaticGuaranteed,
        disableSupplyType: modelData.id || this.isProgrammaticGuaranteed
      };
      var returnData = $.extend(baseData, modelData);
      var currencyCode = this.getCurrencyCode();
      returnData.useAllocPacing = this.useAllocPacing;
      returnData.pacing_optimization_type = modelData.pacing_optimization_type || "monetary";
      returnData.pacing_optimization_amount = modelData.pacing_optimization_amount || "";
      if (this.useAllocPacing && modelData.pacing_optimization_type) {
        $(".budget-pacing .select").val(modelData.pacing_optimization_type).trigger("liszt:updated");
        if (modelData.pacing_type) {
          modelData.pacing_type = "even";
          modelData.pacing_interval = "day";
          modelData.impression_pacing_type = "no-limit";
        }
      }
      returnData.isDeterministicSelected = campaign.connected_id_type === "DETERMINISTIC_ONLY" && campaign.bid_min_devices !== "2";
      returnData.isVideo = returnData.media_type === "VIDEO";
      returnData.isCuratedMarketsTeam = isCuratedMarketsTeam;
      returnData.use_optimization = this.use_optimization === 1;
      returnData.goal_value = this.goal_value;
      returnData.min_bid = this.min_bid;
      returnData.max_bid_wm = returnData.max_bid_wm || "";
      returnData.isFacebookVisible = this.enableFacebookSupplyType;
      returnData.bid_for = returnData.bid_price_is_media_only === "1" ? "Media Only" : "Total Spend";
      const status = modelData.status ? modelData.status === "1" : this.isProgrammaticGuaranteed;
      returnData.status = status ? "active" : "inactive";
      returnData.isStatusActive = status;
      if (this.isProgrammaticGuaranteed) {
        returnData.isNoLimit = true;
        returnData.frequency_type = "no-limit";
        returnData.frequency_amount = "";
        returnData.frequency_interval = "";
      } else if (modelData.frequency_optimization === "0") {
        returnData.isNoLimit = modelData.frequency_type === "no-limit";
        returnData.frequency_type = modelData.frequency_type;
        returnData.frequency_amount = modelData.frequency_amount;
        returnData.frequency_interval = modelData.frequency_interval;
      } else if (!modelData.frequency_optimization) {
        returnData.frequency_type = undefined;
      } else {
        returnData.isNoLimit = true;
        returnData.frequency_type = "no-limit";
        returnData.frequency_amount = "";
        returnData.frequency_interval = "";
      }
      returnData.hasImpressionCap = Boolean(modelData.impression_cap);
      returnData.impCapInterval = modelData.impression_pacing_interval === "day" ? "Day" : modelData.impression_pacing_interval === "hour" ? "Hour" : "Day";
      returnData.impression_pacing_type = modelData.impression_pacing_type ? modelData.impression_pacing_type : "no-limit";
      returnData.impression_pacing_interval = modelData.impression_pacing_interval ? modelData.impression_pacing_interval : "day";
      returnData.impressionNoCap = returnData.impression_pacing_type === "no-limit";
      returnData.impression_pacing_amount = modelData.impression_pacing_amount ? $.trim(formatsRolledUpNumbersNoAbbreviation(modelData.impression_pacing_amount)) : "";
      returnData.pacing_amount = modelData.pacing_amount ? $.trim(formatsRolledUpNumbersNoAbbreviation(modelData.pacing_amount)) : "";
      if (this.isProgrammaticGuaranteed) {
        returnData.supply_type = "PROGRAMMATIC_GUARANTEED";
        returnData.goal_type = "spend";
        returnData.bid_price_is_media_only = "1";
        returnData.pacingTypeOptions = [{
          value: "even",
          text: "N/A"
        }];
        returnData.pacing_type = "even";
        returnData.pacing_interval = "no-limit";
        returnData.customPacing = "0";
      } else {
        returnData.supply_type = this.supply_type || "RTB";
        returnData.goal_type = this.goal_type || campaignData.goal_type;
        returnData.max_bid = this.max_bid;
        if (this.userSelectedIsMediaOnly !== null) {
          returnData.bid_price_is_media_only = this.userSelectedIsMediaOnly;
        } else {
          returnData.bid_price_is_media_only = returnData.bid_price_is_media_only || "1";
        }
        returnData.pacing_type = modelData.pacing_type ? modelData.pacing_type : "even";
        returnData.pacing_interval = modelData.pacing_interval ? modelData.pacing_interval : "day";
        returnData.customPacing = this.userChanged ? this.customPacing : modelData.pacing_optimization_type ? false : Boolean(modelData.id);
      }
      this.customPacing = returnData.customPacing;
      if (this.useAllocPacing && returnData.pacing_type) {
        $(".strategy-cap-toggle .monetary-type .select").val(returnData.pacing_type).trigger("liszt:updated");
        $(".strategy-cap-toggle .monetary-interval .select").val(returnData.pacing_interval).trigger("liszt:updated");
        $(".strategy-cap-toggle .impression-type .select").val(returnData.impression_pacing_type).trigger("liszt:updated");
        $(".strategy-cap-toggle .impression-interval .select").val(returnData.impression_pacing_interval).trigger("liszt:updated");
      }
      returnData.campaign_freq = useCampaignFreqCap(campaign);
      returnData.campaignFrequencyType = campaignData.frequency_type.toLowerCase && campaignData.frequency_type.toLowerCase() === "asap" ? campaignData.frequency_type.toUpperCase() : campaignData.frequency_type;
      returnData.campaignFrequencyAmount = campaignData.frequency_amount;
      returnData.campaignFrequencyInterval = campaignData.frequency_interval;
      returnData.advertiserFreqCap = advertiserData.frequency_type !== "no-limit";
      if (returnData.advertiserFreqCap) {
        returnData.advertiserFrequencyType = advertiserData.frequency_type.toLowerCase && advertiserData.frequency_type.toLowerCase() === "asap" ? advertiserData.frequency_type.toUpperCase() : advertiserData.frequency_type;
        returnData.advertiserFrequencyAmount = advertiserData.frequency_amount;
        returnData.advertiserFrequencyInterval = advertiserData.frequency_interval;
      }
      returnData.bid_aggression = this.bidAggression;
      returnData.name = strategyName === undefined ? model.get("name") : strategyName;
      returnData.goal_type_text = goalTypeText[returnData.goal_type];
      returnData.goalValuePCT = returnData.goal_type === "vcr" || returnData.goal_type === "ctr" || returnData.goal_type === "viewability_rate";
      returnData.campaign_goal_value = formatCurrencyNoSymbol(campaign.goal_value, null, currencyCode);
      returnData.equivalent_target_cpa = formatCurrency(CampaignStrategyUtils.calculateEquivalantTarget(campaign.goal_value, this.roi_target), null, currencyCode);
      returnData.roi_type = returnData.goal_type === "roi";
      returnData.ctr_type = returnData.goal_type === "ctr";
      returnData.vcr_type = returnData.goal_type === "vcr";
      returnData.vrate_type = returnData.goal_type === "viewability_rate";
      returnData.vcpm_type = returnData.goal_type === "vcpm";
      returnData.roi_target = this.roi_target;
      returnData.isBatch = returnData.supply_type === "BATCH";
      returnData.isReachType = returnData.goal_type === "reach";
      returnData.isSpendType = returnData.goal_type === "spend";
      returnData.showReachSettings = returnData.isReachType && !returnData.isBatch;
      returnData.isReachOrSpend = returnData.goal_type === "reach" || returnData.goal_type === "spend";
      returnData.displayMaxBidWm = this.displayMaxBidWm && !returnData.isBatch;
      returnData.currMask = this.currMask;
      returnData.notBidPrice = returnData.isBatch ? !returnData.isSpendType : returnData.use_optimization;
      returnData.campaignFreqCap = returnData.campaign_freq;
      returnData.cpm_not_advanced = !campaignReachOrSpend;
      returnData.msgForCTRGoalTypeSection = {
        infoBoxMessageLines: ["Use the Max Bid field to control the cost of impressions. Based on your Supply, Creatives, and Targeting " + "settings, a higher target CTR goal may result in a tradeoff with scale"],
        infoBoxClassName: returnData.ctr_type ? "show-on-load" : ""
      };
      returnData.msgForVCRGoalTypeSection = {
        infoBoxMessageLines: ["Video Completion Rate (VCR) Strategies optimize to the percentage of impressions played to completion. " + "Use the Max Bid field to control the cost of impressions. Based on your Supply, Creatives, and " + "Targeting settings, a higher target VCR goal may result in a tradeoff with scale."],
        infoBoxClassName: returnData.vcr_type ? "show-on-load" : ""
      };
      returnData.msgForViewabilityGoalTypeSection = {
        infoBoxMessageLines: ["Viewability Rate Strategies optimize to the percentage of impressions deemed viewable by the post-bid " + "reporting partner you selected at the Campaign level. Use the Max Bid field to control the cost of " + "impressions. Based on your Supply, Creatives, and Targeting settings, a higher Viewability Rate goal " + "may result in a tradeoff with scale."],
        infoBoxClassName: returnData.vrate_type ? "show-on-load" : ""
      };
      returnData.msgForVCPMGoalTypeSection = {
        infoBoxMessageLines: ["Viewable CPM (vCPM) Strategies seek to minimize the effective cost of viewable impressions. " + "Viewability will be determined by the post-bid reporting partner you selected at the Campaign level."],
        infoBoxClassName: returnData.vcpm_type ? "show-on-load" : ""
      };
      if (returnData.isReachType === true) {
        if (returnData.bid_aggression === undefined || isNaN(returnData.bid_aggression)) {
          returnData.bid_aggression = "100";
        }
      }
      returnData.currency_symbol = getCurrencySymbol(currencyCode);
      if (returnData.frequency_optimization) {
        returnData.frequency_optimization_bool = returnData.frequency_optimization === "1";
      } else {
        returnData.frequency_optimization_bool = !campaignReachOrSpend;
      }
      if (!returnData.campaignFreqCap) {
        returnData.campaignFreqCap = true;
        returnData.campaignFrequencyType = "T1 Optimized";
        returnData.campaignFrequencyAmount = "";
        returnData.campaignFrequencyInterval = "";
      } else if (returnData.campaignFrequencyType === "no-limit") {
        returnData.campaignFreqCap = false;
      }
      returnData.eitherCap = returnData.campaignFreqCap || returnData.advertiserFreqCap;
      returnData.bothCaps = returnData.campaignFreqCap && returnData.advertiserFreqCap;
      returnData.currency_symbol = getCurrencySymbol(currencyCode);
      returnData.enableTrackingSupplyTypeFeatureFlag = returnData.supply_type === "RMX_API";
      returnData.isFacebookVideoPermitted = isFacebookVideoPermitted;
      returnData.isProgrammaticGuaranteed = this.isProgrammaticGuaranteed;
      returnData.supplyTypeOptions = getSupplyTypeOptions(isCuratedMarketsTeam, this.enableFacebookSupplyType, isFacebookVideoPermitted, this.isProgrammaticGuaranteed, returnData.media_type, campaign.goal_type === "vcr");
      returnData.getStrandSelectOptions = () => getStrandSelectOptions(returnData);
      returnData.isAdsTxtUserControlsEnabled = T1Permissions.check("feature", "ads_txt_user_controls_campaign_strategy");
      returnData.ads_txt_verified = modelData.ads_txt_verified || "INHERITED";
      const campaign_ads_txt_verified = campaign.ads_txt_verified && campaign.ads_txt_verified !== "INHERITED" ? campaign.ads_txt_verified : null;
      const inherited_ads_txt_verified = campaign_ads_txt_verified || campaign.inherited_ads_txt_verified;
      const adsTxtDropdownData = {
        AUTHORIZED_ONLY: "Authorized Supply Only",
        AUTHORIZED_DIRECT_ONLY: "Authorized Direct Supply Only",
        ANY: "Authorized + Unknown",
        NONE: "None"
      };
      returnData.inherited_ads_txt_verified = inherited_ads_txt_verified ? adsTxtDropdownData[inherited_ads_txt_verified] : inherited_ads_txt_verified;
      returnData.inherited_ads_txt_verified_origin_entity_type = "Campaign";
      returnData.isStrategyView = true;
      return returnData;
    }
  });
  return T1Form.ViewUtils.extend(StrategyDetailsView);
});
