define('collections/siteLists',["jQuery", "Underscore", "T1", "T1Collection", "models/siteList"], function ($, _, T1, T1Collection, SiteList) {
  "use strict";

  T1.Models.SiteLists = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: SiteList,
    fixture: "-siteLists",
    filterConfiguration: {
      filters: [{
        entity: "organization",
        fetchOnChange: true
      }],
      enableEvents: false
    },
    currentSearchConfig: [],
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        },
        status: {
          type: "enum"
        },
        restriction: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    searchAttempts: 0,
    urlString: "site_lists",
    fetchOptions: {
      target_type: "*"
    },
    initialize: function (models, options) {
      options = options || {};
      options.fetchOptions = $.extend(options.fetchOptions, this.fetchOptions);
      if (options.id && options.action) {
        this.urlString += "/" + options.id + "/" + options.action;
        if (options.action === "assignments") {
          this.postEntityParse = {
            id: function (value, data) {
              if (data.collection && data.entity_id) {
                return data.collection + "-" + data.entity_id;
              } else {
                return value;
              }
            }
          };
        }
      }
      T1Collection.prototype.initialize.apply(this, arguments);
    },
    applySearchFilter: function (field, term) {
      var searchType;
      var fieldIndex = -1;
      if (field !== undefined && term !== undefined) {
        term = term.replace(/&/g, "%26");
        searchType = {
          term: term,
          searchField: field
        };
        $.each(this.currentSearchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
          }
        });
        if (fieldIndex === -1) {
          if (term !== "") {
            this.currentSearchConfig.push(searchType);
          }
        } else {
          if (term === "") {
            this.currentSearchConfig.splice(fieldIndex, 1);
          } else {
            this.currentSearchConfig[fieldIndex] = searchType;
          }
        }
      }
    },
    filterBy: function (field, term, success) {
      var self = this;
      var search = self.search;
      var searchAttempt = self.searchAttempts + 1;
      var onSuccess = success || $.noop;
      self.searchAttempts++;
      self.applySearchFilter(field, term);
      search.set({
        params: this.currentSearchConfig,
        fetchRequired: true,
        success: function (coll) {
          if (searchAttempt >= self.searchAttempts) {
            self.trigger("reset");
            onSuccess(coll);
          }
        }
      });
    }
  });
  return T1.Models.SiteLists;
});
