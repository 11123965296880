define('collections/verticals',["T1", "T1Collection", "T1Sortable", "models/vertical"], function (T1, T1Collection, Sortable, Vertical) {
  var originalInitialize;
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: Vertical,
    urlString: "verticals"
  });
  originalInitialize = collection.prototype.initialize;
  collection.prototype.initialize = function (models, options) {
    options = options || {};
    options.isPaginated = false;
    return originalInitialize.call(this, models, options);
  };
  T1.Models.Verticals = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.Verticals;
});
