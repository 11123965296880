define('collections/strategyDomainRestrictions',["Underscore", "jQuery", "T1", "T1Collection", "T1Model"], function (_, $, T1, T1Collection, T1Model) {
  "use strict";

  T1.Models.StrategyDomainRestrictions = T1Collection.extend({
    canCache: false,
    model: T1Model,
    filterConfiguration: {
      filters: [{
        entity: "strategy",
        fetchOnChange: false
      }],
      enableEvents: false
    },
    urlString: "strategy_domain_restrictions",
    fixture: "-strategyDomainRestrictions",
    initialize: function () {
      this.isPaginated = false;
      T1Collection.prototype.initialize.apply(this, arguments);
      this.data = {};
    },
    canFetchMore: function () {
      var result = false;
      if (Math.ceil(this.count / this.pageLimit) > this.page + 1) {
        result = true;
      }
      return result;
    },
    fetch: function (options) {
      var self = this;
      var opts = options || {};
      if (opts.success === undefined) {
        opts.pageLimit = 0;
        this.isPaginated = false;
        opts.success = function () {
          var models = self.models;
          var len = models.length;
          var sitelistArray = [];
          var type;
          var value;
          var i;
          for (i = 0; i < len; i++) {
            type = (models[i].get("target_type") || "").toLowerCase();
            value = models[i].get("domain");
            sitelistArray.push((type === "" || type === "domain" ? "" : type + ":") + value);
          }
          if (len > 0) {
            self.setData(models[0].get("restriction") === "INCLUDE" ? "include" : "exclude", sitelistArray.toString());
          } else {
            self.setData(null, "");
          }
        };
      }
      T1Collection.prototype.fetch.call(this, opts);
    },
    parse: function () {
      var jsonObj = T1Collection.prototype.parse.apply(this, arguments);
      if (jsonObj.length > 0) {
        this.data.isIncluded = jsonObj[0].restriction === "INCLUDE";
      }
      this.data.count = this.count;
      return jsonObj;
    },
    setData: function (listType, listString, isParse) {
      var arr;
      var newArr = [];
      var i;
      var len;
      var val;
      var count;
      var listArray = listString.split(",");
      if (listString) {
        if (!isParse || listArray.length === this.count) {
          arr = listString.replace(/(\r|\n|,)/g, " ").split(/\s+/);
          len = arr.length;
          for (i = 0; i < len; i++) {
            val = $.trim(arr[i]);
            if (val !== "") {
              newArr.push(val);
            }
          }
          newArr = _.uniq(newArr);
          listString = newArr.toString();
          if (isParse) {
            count = this.count;
            if (this.data.sitelist) {
              listString = this.data.sitelist + "," + listString;
            }
          } else {
            count = listString.length === 0 ? 0 : newArr.length;
          }
          this.data = {
            count: count,
            isIncluded: listType && listString.length > 0 ? listType === "include" : undefined,
            sitelist: listString
          };
        } else {
          this.fetch();
        }
      } else {
        this.data = {
          count: 0,
          isIncluded: undefined,
          sitelist: ""
        };
      }
    }
  });
  return T1.Models.StrategyDomainRestrictions;
});
