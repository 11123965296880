define('T1Preferences',["T1"], function (T1) {
  "use strict";

  T1.Preferences = function () {
    function Preference(sig, model, scope) {
      let prefs = [];
      const previousPrefs = {};
      const signature = sig;
      function count() {
        return prefs.length;
      }
      function set() {
        prefs.forEach(pref => {
          scope[pref] = model.get(signature.sign(pref)) || scope[pref];
          previousPrefs[pref] = scope[pref];
        });
      }
      function get(attr) {
        return model.get(signature.sign(attr));
      }
      function mark(attrs) {
        if (!(Array.isArray(attrs) && attrs.length)) {
          throw new Error("T1.Preferences: mark method only accepts an non empty array");
        }
        attrs.forEach(attr => {
          if (scope[attr] === undefined) {
            throw new Error("T1.Preferences: mark method only mark attributes of object in scope");
          }
          if (!prefs.includes(attr)) {
            prefs.push(attr);
          }
        });
        set();
        return true;
      }
      function unmark(unmarkAttrs) {
        if (!(Array.isArray(unmarkAttrs) && unmarkAttrs.length)) {
          throw new Error("T1.Preferences: mark method only accepts an non empty array");
        }
        prefs = prefs.filter(pref => !unmarkAttrs.includes(pref));
      }
      function save(options) {
        const prefsToPost = {};
        let doSave = false;
        prefs.forEach(pref => {
          if (previousPrefs[pref] !== scope[pref]) {
            prefsToPost[signature.sign(pref)] = scope[pref];
            previousPrefs[pref] = scope[pref];
            doSave = true;
          }
        });
        if (doSave) {
          try {
            model.set(prefsToPost);
          } catch (e) {}
          model.save(prefsToPost, options);
        }
      }
      function add(options) {
        signature.set(options);
      }
      return {
        add: add,
        count: count,
        get: get,
        mark: mark,
        save: save,
        set: set,
        unmark: unmark
      };
    }
    return {
      create(sig, prefModel, scope = this) {
        if (!sig) {
          throw new Error("T1.Preferences: cannot be called without an argument");
        }
        if (typeof sig === "string" || typeof sig === "function") {
          throw new Error('T1.Preferences: most be called with a "signature" object');
        }
        if (!sig.sign || typeof sig.sign !== "function") {
          throw new Error('T1.Preferences: most be called with a "signature" object');
        }
        if (!prefModel) {
          throw new Error("T1.Preferences: most be called with a model as the second argument");
        }
        return new Preference(sig, prefModel, scope);
      }
    };
  }();
});
