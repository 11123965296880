define('modules/campaign/bulkedit/targeting/location/views/browse',["jQuery", "Underscore", "T1", "T1View", "text!../templates/grid_item.html", "text!../templates/grid_subitem.html", "text!../templates/grid_footer.html", "T1GridView", "text!templates/alert.html", "T1Spinner", "T1Layout", "collections/targetValues", "modules/targeting/shared/itemSelectionBehavior", "modules/strategy/views/helpers/defaultLocationPersistenceHelper"], function ($, _, T1, T1View, gridItemTemplate, gridSubItemTemplate, gridFooter, createGrid, alertDialogTemplate, Spinner, T1Layout, TargetValues, ItemSelectionBehavior, DefaultLocPersistHelper) {
  "use strict";

  function setCollectionFetchOptions(coll, id, item, options) {
    var fetchOptions = coll.fetchOptions || {};
    fetchOptions.dimension = id ? "REGN" : item.data("code") || options.dimension;
    coll.sortBy("name", "ascending");
    if (id) {
      fetchOptions.parent = id;
    } else {
      delete fetchOptions.parent;
    }
    coll.fetchOptions = fetchOptions;
  }
  const LocationBrowseView = T1View.extend({
    partials: {
      listItem: gridItemTemplate,
      gridSubItem: gridSubItemTemplate,
      gridFooter: gridFooter,
      alertDialog: alertDialogTemplate
    },
    headers: [{
      name: "type",
      label: "type"
    }],
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    initialize: function ({
      collection: collection,
      sharedCollection: sharedCollection,
      partialCollection: partialCollection
    }) {
      this.collection = collection;
      this.sharedCollection = sharedCollection;
      this.partialCollection = partialCollection;
      this.viewType = "browse";
      ItemSelectionBehavior.attachBehavior(this);
    },
    events: {
      "click .item": "toggleNode",
      "click .item .icon.include": "includeOrExcludeTarget",
      "click .item .icon.exclude": "includeOrExcludeTarget"
    },
    eventhubEvents: {
      "bins:droppedLocation": function ({
        selectedList: selectedList
      }) {
        const {
          collection: coll,
          sharedCollection: sharedColl,
          addTarget: addTarget
        } = this;
        var self = this;
        function addTargetValue(id, action) {
          var targetValue = sharedColl.get(id) || coll.get(id);
          if (targetValue && targetValue.get("action") !== action) {
            addTarget.call(self, targetValue, action);
          }
        }
        for (let i = 0; i < selectedList.length; i++) {
          addTargetValue(selectedList[i].id, selectedList[i].action);
        }
      },
      defaultLocationSaved: function () {
        this.refreshDefaultLocations(this.collection);
        this.render();
      }
    },
    dataEvents: {
      collection: {
        search: "search",
        "search.clear": "search",
        reset: "reload"
      },
      sharedCollection: {
        add: "sharedCollectionAddHandler",
        remove: "sharedCollectionRemoveHandler"
      }
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_value_id",
      selectionAllowableKey: null,
      selectionAllowableValue: null,
      collectionKey: "collection"
    },
    refreshDefaultLocations: function (coll) {
      let collItem;
      var arrAll = [];
      const defaultLocationPersister = new DefaultLocPersistHelper();
      defaultLocationPersister.init();
      const defaultSettings = defaultLocationPersister.getSettings();
      const arrCategories = defaultSettings.include.split("|").concat(defaultSettings.exclude.split("|"));
      _.each(arrCategories, function (categoryText) {
        if (categoryText) {
          arrAll = arrAll.concat(categoryText.split(","));
        }
      });
      for (let j = 0; j < coll.length; j++) {
        if (coll.models) {
          collItem = coll.models[j];
        } else {
          collItem = coll[j];
        }
        collItem.isDefault = undefined;
      }
      if (coll.models) {
        for (let i = 0; i < arrAll.length; i++) {
          const defaultItem = arrAll[i];
          collItem = coll.get(defaultItem);
          if (collItem) {
            collItem.isDefault = true;
          }
        }
      }
      if (!coll.models) {
        _.each(coll, function (item) {
          collItem = item;
          _.each(arrAll, function (defaultId) {
            if (defaultId === collItem.id) {
              collItem.isDefault = true;
            }
          });
        });
      }
    },
    includeOrExcludeTarget: function (evt) {
      var $target = $(evt.currentTarget);
      const action = $target.hasClass("include") ? "include" : "exclude";
      var id = $target.closest(".item").attr("data-id");
      var item = this.collection.get(id);
      var isSpecial = id === "100989";
      var name = item.get("name");
      var arrow = "&raquo";
      var len = arrow.length;
      const isDMA = !isSpecial && (name.includes("|") || name.includes("/"));
      var dmaName = name.replace(/\/|\|/g, arrow);
      var regnName = isSpecial ? name : this.getParentPath(item.id);
      const pathEndIndex = isDMA ? dmaName.lastIndexOf(arrow) + len : isSpecial ? 0 : regnName.lastIndexOf(arrow) + len;
      const browsePath = isDMA ? dmaName.slice(0, pathEndIndex) : regnName.slice(0, pathEndIndex);
      const browsePathEnd = isDMA ? dmaName.slice(pathEndIndex) : regnName.slice(pathEndIndex);
      this.preventDefault(evt);
      this.removeDragClasses();
      this.renderPartialTemplate({
        template: this.partials.gridFooter,
        templateTarget: ".browse-path-selected",
        targetEl: this.$(".browse-path-selected"),
        data: {
          browse_path: browsePath,
          browse_path_end: browsePathEnd
        }
      });
      this.addTarget(item, action);
    },
    getParentPath: function (id) {
      var result = "";
      var collection = this.collection;
      let node = collection.get(id);
      while (node && node.get("parent_value_id")) {
        result = result === "" ? node.get("name") : `${node.get("name")} &raquo ${result}`;
        node = collection.get(node.get("parent_value_id"));
      }
      result = result === "" ? node.get("name") : `${node.get("name")} &raquo ${result}`;
      return result;
    },
    addTarget: function (item, newAction) {
      let lastAction;
      var sharedCollection = this.sharedCollection;
      const itemInSharedCollection = sharedCollection.get(item.id);
      if (itemInSharedCollection) {
        lastAction = itemInSharedCollection.get("action");
        sharedCollection.remove(itemInSharedCollection);
      }
      if (lastAction !== newAction) {
        item.set({
          action: newAction
        });
        sharedCollection.add(item);
      }
    },
    toggleNode: function (event) {
      let id, idVal;
      var self = this;
      var target = $(event.currentTarget);
      var item = target.closest(".item");
      var children = item.closest(".item-wrapper").children(".children");
      var paginationContainer = children.next();
      var isChildrenFetched = children.find(".item-wrapper").length;
      var coll = this.collection;
      var iconArrow = target.find(".icon-arrow");
      var iconSpinner = target.find(".icon-spinner");
      var isExpanded = iconArrow.hasClass("expanded");
      const canExpand = Boolean(iconArrow.length);
      function prepToRenderChildren() {
        iconArrow.addClass("expanded");
        idVal = item.data("id");
        id = $.isNumeric(idVal) ? idVal : undefined;
        iconArrow.addClass("hide");
        Spinner.apply(iconSpinner, self.spinnerOpts);
      }
      function pushUnknownAndOtherToBackOfCollection() {
        var otherAndUnknown = coll.filter(function (model) {
          const name = model.get("name");
          return name === "Other" || name === "Unknown";
        });
        coll.remove(otherAndUnknown, {
          silent: true
        });
        coll.add(otherAndUnknown, {
          silent: true
        });
      }
      function renderChildren() {
        pushUnknownAndOtherToBackOfCollection();
        iconSpinner.spin(false);
        iconArrow.removeClass("hide");
        self.addChildren({
          entityId: idVal,
          collection: self.filterCollectionByDimension(coll.fetchOptions.dimension, true),
          container: children
        });
      }
      event.preventDefault();
      event.stopPropagation();
      if (canExpand) {
        if (!isChildrenFetched) {
          prepToRenderChildren();
          setCollectionFetchOptions(coll, id, item);
          if (id) {
            this.parentId = id.toString();
          }
          coll.fetch({
            add: true,
            silent: true,
            success: renderChildren
          });
        }
        if (!isExpanded) {
          children.show();
          iconArrow.addClass("expanded");
        } else {
          children.hide();
          paginationContainer.hide();
          iconArrow.removeClass("expanded");
        }
        this.updateScrollBar();
      }
    },
    addChildren: function ({
      container: container,
      collection: collection,
      entityId: entityId
    }) {
      var boundAddChildren = this.addChildren.bind(this);
      var boundPreFetchMore = this.preFetchMore.bind(this);
      container.show();
      this.collection.add(collection.models, {
        silent: true
      });
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: container,
        data: {
          list: this.preSerialize(collection)
        }
      });
      if (collection.count > collection.pageLimit) {
        if ($.isNumeric(entityId)) {
          this.collection.parent = entityId;
        } else {
          delete this.collection.parent;
        }
        const paginationSelector = `[data-code='${container.prev().data("code")}'] ~ .pagination`;
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: paginationSelector,
          layout: {}
        });
        this.paginationLayout.layout[paginationSelector] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: collection,
            onSuccess: boundAddChildren,
            preFetchMore: boundPreFetchMore,
            indent: "margin: 0 -55px 0 55px"
          }
        }];
        this.paginationLayout.load();
        const paginationContainer = this.$(paginationSelector);
        paginationContainer.show();
      }
      this.updateScrollBar();
      this.applyDraggable();
    },
    pickSeparator: function (name, isSpecial) {
      return isSpecial ? name : name.replace(/\/|\|/g, "&raquo");
    },
    preFetchMore: function ({
      collection: collection,
      item: item
    }) {
      const id = item.data("id");
      const dimension = collection.fetchOptions.dimension;
      const page = collection.page;
      if (!collection.length) {
        return;
      }
      setCollectionFetchOptions(collection, id, item, {
        dimension: dimension
      });
      collection.page = page;
      return {
        collection: collection
      };
    },
    filterCollectionByDimension: function (dimension, returnNewColl) {
      var list;
      const collection = this.collection;
      const parent = collection.fetchOptions.parent;
      if (dimension === "REGN") {
        list = collection.filter(model => {
          var filteredModel;
          var filteredModelParentId = model && model.get("parent_value_id");
          var isRegionTargetValue = model && model.get("dimension_code") === "REGN";
          if (isRegionTargetValue) {
            if (parent) {
              if (filteredModelParentId && filteredModelParentId === this.parentId) {
                filteredModel = model;
              }
            } else {
              if (!filteredModelParentId) {
                filteredModel = model;
              }
            }
          }
          return filteredModel;
        });
      } else {
        list = collection.filter(model => model && model.get("dimension_code") === "DMAX");
      }
      if (returnNewColl) {
        list = new TargetValues(list);
        list.isPaginated = true;
        list.entityType = "Target Value";
        list.count = collection.count;
        list.page = collection.page;
        list.pageLimit = collection.pageLimit;
        list.fetchOptions = collection.fetchOptions;
      }
      return list;
    },
    preSerialize: function (coll) {
      var result = [];
      var self = this;
      const {
        dimension: dimensionCode,
        parent: hasParent
      } = this.collection.fetchOptions;
      var highlightText = T1.Utils.highlightText;
      this.refreshDefaultLocations(coll);
      $.each(coll.models || coll, function (index, model) {
        let searchTerm;
        const json = model.toJSON ? model.toJSON() : model;
        var parentId = json.parent_value_id;
        const isSpecial = model.parent_value_id === "100989" || model.id === "100989";
        function isTermInName() {
          var term = searchTerm.toLowerCase();
          var name = json.name.toLowerCase();
          return name.includes(term);
        }
        json.isDefault = model.isDefault;
        if (self.viewType === "search") {
          json.length = false;
          searchTerm = self.searchTerm;
          let fullPathName = self.getParentPath(json.id);
          fullPathName = self.pickSeparator(fullPathName, isSpecial);
          json.name = highlightText(fullPathName, searchTerm);
          self.applySelectionStates(json);
          if (isTermInName()) {
            result.push(json);
          }
        } else {
          json.length = Number(json.child_count) > 1;
          if (dimensionCode === json.dimension_code && (hasParent ? parentId === self.parentId : true)) {
            json.name = self.pickSeparator(json.name, isSpecial);
            self.applySelectionStates(json);
            result.push(json);
          }
        }
      });
      return result;
    },
    search: function (data) {
      var collection = this.collection;
      if (data) {
        const term = data.term;
        const type = data.type;
        this.viewType = "search";
        this.searchTerm = term;
        collection.fetchOptions.dimension = type;
        collection.search.set({
          params: [{
            searchField: "name",
            term: `*${term}*`
          }],
          fetchRequired: false
        });
        this.setSearchLoader();
        collection.fetch();
      } else {
        collection.search.clear();
        collection.reset([], {
          silent: true
        });
        this.viewType = "browse";
        this.load();
      }
    },
    unload: function () {
      this.collection.trigger("search.clear");
    },
    serialize: function () {
      var result;
      const data = [];
      const {
        viewType: viewType,
        collection: collection
      } = this;
      if (viewType === "browse") {
        result = {
          list: [{
            name: "Country/Region/City",
            code: "REGN",
            length: true
          }, {
            name: "DMA/Media Market",
            code: "DMAX",
            length: true
          }],
          contentType: this.viewType
        };
      } else {
        collection.each(function (model) {
          data.push(model.toJSON());
        });
        result = {
          list: this.preSerialize(data),
          contentType: this.viewType
        };
      }
      return result;
    }
  });
  return createGrid(LocationBrowseView);
});
