define('modules/targeting/contextual/views/panel',["jQuery", "Underscore", "T1", "T1View", "When", "text!modules/targeting/contextual/templates/panel.html", "text!modules/targeting/contextual/templates/panel_layout.html", "text!templates/form_footer.html", "text!templates/filter-box.html", "text!modules/targeting/audience/templates/info_box.html", "T1Layout", "collections/targetSegmentsProviders", "collections/strategyTargetSegments", "collections/targetValues", "utils/CampaignStrategyUtils", "collections/strategyRetiredTargetSegments", "utils/currencyConversionHelper"], function ($, _, T1, T1View, When, template, panel_template, form_footer, filterBox, info_box, T1Layout, TargetSegmentProviders, StrategyTargetSegments, TargetValues, CampaignStrategyUtils, RetiredSegments, CurrencyUtils) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var targetDimensionKey = CampaignStrategyUtils.names.targetDimensions;
  const isTargetDimensionReady = CampaignStrategyUtils.isStrategyTargetDimensionDataReady.bind(CampaignStrategyUtils);
  const updateCPM = CurrencyUtils.updateCpmTargetingValuesWithCurrency.bind(CurrencyUtils);
  const idList = [{
    id: 1591,
    action: "include",
    type: "maturity",
    dimension: "SFTY"
  }, {
    id: 1592,
    action: "include",
    type: "maturity",
    dimension: "SFTY"
  }, {
    id: 1593,
    action: "include",
    type: "maturity",
    dimension: "SFTY"
  }, {
    id: 1597,
    action: "include",
    type: "ugc",
    dimension: "SFTY"
  }, {
    id: 1598,
    action: "include",
    type: "ugc",
    dimension: "SFTY"
  }];
  const providerTaxTimeToRead = 8e3;
  const providerTaxMsgState = {
    isDisplayed: false
  };
  const providerTaxWarningMessage = `Sales taxes may be charged for segments from this provider.\n  Where applicable this will be reflected in the total cost shown below and included in\n  the Contextual Cost in reporting and invoices.`;
  var ContextualPanel = T1View.extend({
    template: template,
    partials: {
      form_footer: form_footer
    },
    targetVendorsFilterList: [{
      text: "Choose Provider",
      value: 0
    }],
    providerTaxTimeout: null,
    eventhubEvents: {
      targetSegmentsCollectionIsLoaded: function () {
        this.setupSearchFilter();
      },
      reloadSearchDropDown: function () {
        this.searchFilterLoaded = false;
        this.setupSearchFilter();
      },
      targetingVendorsFetched: "setDefaultAndTargetingVendorsOperators",
      "bins.providerTaxAdded": function () {
        this.toggleUsageWarningInfo(true, {
          node: "providerTax"
        });
        if (this.providerTaxTimeout) {
          clearTimeout(this.providerTaxTimeout);
        }
        this.providerTaxTimeout = setTimeout(() => {
          this.toggleUsageWarningInfo(false, {
            node: "providerTax"
          });
        }, providerTaxTimeToRead);
      }
    },
    events: {
      "click .usage-warning-info .close": "toggleUsageWarningInfo",
      "click .hideIcon": "onCloseRetiredSegmentsPopup"
    },
    toggleUsageWarningInfo: function (e, opts) {
      const defaultTopMargin = 45;
      const defaultSpacing = 25;
      let newTopMargin = defaultTopMargin;
      const canShow = e === true;
      const action = canShow ? "show" : "hide";
      const provider = opts && opts.node || e.currentTarget.parentNode.className.replace(/\s*(?:usage-warning-info|info-box)\s*/g, "");
      const $listBody = this.$(".list-body");
      const $infoEls = this.$(".usage-warning-info");
      const $providerTaxMsg = $infoEls.filter(".providerTax");
      if (typeof e === "object") {
        this.preventDefault(e);
      }
      switch (provider) {
        case "providerTax":
          providerTaxMsgState.isDisplayed = canShow;
          $providerTaxMsg[action]();
          break;
      }
      const providerTaxMsgHeight = providerTaxMsgState.isDisplayed ? $providerTaxMsg.height() : 0;
      const msgsDisplaying = [providerTaxMsgState].filter(state => state.isDisplayed);
      const spaceBelowInfo = msgsDisplaying.length ? defaultSpacing * msgsDisplaying.length : 0;
      newTopMargin += providerTaxMsgHeight + spaceBelowInfo;
      $listBody.css("top", `${newTopMargin}px`);
    },
    onCloseRetiredSegmentsPopup: function () {
      const $warningPopUp = this.$(".tabWarning");
      const $grid = this.$(".grid-holder");
      $warningPopUp.css("display", "none");
      $grid.css("top", "0px");
    },
    dataEvents: {
      sharedCollection: {
        add: function () {
          this.isFormElementsChanged = true;
        },
        remove: function () {
          this.isFormElementsChanged = true;
        },
        "change:user_cpm": function () {
          this.isFormElementsChanged = true;
        },
        reset: function () {
          if (!this.layout) {
            this.initLayout();
          }
          this.load();
          this.isFormElementsChanged = false;
        }
      }
    },
    initialize: function ({
      strategy: strategy,
      sharedCollection: sharedCollection,
      groupedSegments: groupedSegments,
      partialCollection: partialCollection,
      retiredCollection: retiredCollection,
      strategyTargetDimensions: strategyTargetDimensions
    }) {
      this.strategy = strategy;
      this.sharedCollection = sharedCollection;
      this.sharedCollection.currencyCode = strategy.getCurrencyCode();
      this.groupedSegments = groupedSegments;
      this.partialCollection = partialCollection;
      this.retiredCollection = retiredCollection;
      this.strategyTargetDimensions = strategyTargetDimensions;
      T1.appVariables.warning_message_providerTax = T1.appVariables.warning_message_providerTax || {};
      if (this.sharedCollection.loaded === true) {
        this.initLayout();
      }
    },
    reload: function (dataOnly, success) {
      const strategy = this.strategy;
      var currencyCode = strategy.getCurrencyCode();
      var strategyTargetDimensionsDeferred = When.defer();
      var groupedSegmentsDeferred = When.defer();
      var retiredSegmentsDeferred = When.defer();
      const promises = [strategyTargetDimensionsDeferred.promise, groupedSegmentsDeferred.promise, retiredSegmentsDeferred.promise];
      if (!dataOnly) {
        this.strategyTargetDimensions.fetch({
          success: function () {
            strategyTargetDimensionsDeferred.resolve();
          }
        });
        this.groupedSegments.fetch({
          success: () => {
            this.sharedCollection.loaded = true;
            groupedSegmentsDeferred.resolve();
          },
          currency_code: currencyCode
        });
        this.retiredCollection.fetch({
          success: function () {
            retiredSegmentsDeferred.resolve();
          },
          action: "retired_targeting_segments"
        });
        When.all(promises).then(() => {
          this.prepareSharedCollection();
          if (!dataOnly) {
            if (!this.layout && isTargetDimensionReady(this.strategyTargetDimensions) === true) {
              this.initializeLayout();
            }
            T1View.prototype.reload.call(this);
          }
        });
      } else {
        this.strategyTargetDimensions.fetch({
          success: () => {
            this.prepareSharedCollection();
            if (!dataOnly) {
              if (!this.layout && isTargetDimensionReady(this.strategyTargetDimensions) === true) {
                this.initializeLayout();
              }
              T1View.prototype.reload.call(this);
            } else {
              if (success) {
                success();
              }
            }
          }
        });
      }
    },
    unload: function () {
      this.retiredCollection.reset([], {
        silent: true
      });
      this.retiredCollection.loaded = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
      _.each(this.sharedCollection.models, model => {
        if (model.get("type") === "retired_target_segments") {
          this.sharedCollection.remove(model.get("id"), {
            silent: true
          });
        }
      });
      this.retiredCollection.fetch({
        success: () => {
          this.retiredCollection.loaded = true;
        }
      });
      this.searchFilterLoaded = false;
    },
    setupSearchFilter: function () {
      const providersList = this.targetSegmentProviders.toJSON();
      if (!this.searchFilterLoaded) {
        this.searchFilterLoaded = true;
        const filterList = $.map(providersList, function (provider) {
          return {
            text: provider.name || "",
            value: provider.targeting_vendor_id || ""
          };
        });
        if (this.targetVendorsFilterList.length > 1) {
          this.targetVendorsFilterList = [this.targetVendorsFilterList[0]];
        }
        this.targetVendorsFilterList = this.targetVendorsFilterList.concat(filterList);
        this.sortProviderList();
        this.refreshFilterDropdown();
        this.delegateEvents();
        this.isFormElementsChanged = false;
      }
    },
    sortProviderList: function () {
      var allProviderHolder;
      const filterList = _.sortBy(this.targetVendorsFilterList, function (item) {
        if (item.value) {
          return item.text.toLowerCase();
        }
        allProviderHolder = item;
      });
      filterList.unshift(allProviderHolder);
      filterList.pop();
      this.targetVendorsFilterList = filterList;
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    initLayout: function () {
      var self = this;
      const {
        strategy: strategy,
        sharedCollection: sharedCollection
      } = this;
      const targetSegmentProviders = new TargetSegmentProviders([], {
        isPaginated: false
      });
      const safetyCollection = new TargetValues([], {
        dimension: "SFTY"
      });
      var filterClass = "targetSegmentProviders";
      var filterCallback = function (term) {
        const providerId = this.$(`.${filterClass}`).val();
        const minCharLen = 3;
        if (self.$(".targetSegmentProviders").val() === "0") {
          this.applyTooltip(this.inputBox, "Please choose a provider.");
          this.showTooltip(this.event, this.inputParent);
          return;
        }
        this.inputBox.removeClass("error");
        this.inputParent.removeAttr("original-title");
        if (term.length < minCharLen) {
          this.applyTooltip(this.inputBox, "Please enter at least 3 characters.");
          this.showTooltip(this.event, this.inputParent);
          return;
        }
        T1.EventHub.publish("searchTargetSegments", {
          term: term,
          provider_id: providerId
        });
      };
      var clearFilterCallback = function () {
        T1.EventHub.publish("browseTargetSegments");
      };
      targetSegmentProviders.fetchOptions.full = "*";
      this.targetSegmentProviders = targetSegmentProviders;
      safetyCollection.add(idList);
      this.safetyCollection = safetyCollection;
      this.prepareSharedCollection();
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panel_template,
        partials: {
          info_box: info_box
        },
        layout: {
          ".contextual-filter": [{
            module: "shared",
            viewType: "filter",
            options: {
              check_length: false,
              selectOptions: this.targetVendorsFilterList,
              filterClass: filterClass,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback
            }
          }],
          ".browser-c": [{
            module: "targeting/contextual",
            viewType: "browse",
            options: {
              sharedCollection: sharedCollection,
              safetyCollection: safetyCollection,
              safetySharedCollection: this.safetySharedCollection,
              collection: targetSegmentProviders,
              partialCollection: this.partialCollection,
              validateSafetySettings: function () {
                self.validateSafetySettings();
              }
            }
          }],
          ".bins-column": [{
            module: "targeting/contextual",
            viewType: "bins",
            options: {
              strategy: strategy,
              sharedCollection: sharedCollection,
              retiredCollection: this.retiredCollection,
              safetySharedCollection: this.safetySharedCollection
            }
          }]
        },
        serialize: function () {
          return {
            msgForProviderTax: {
              infoBoxMessageLines: providerTaxWarningMessage,
              infoMessageCanClose: true,
              infoBoxClassName: "usage-warning-info providerTax"
            },
            showRetiredSegments: Boolean(self.retiredCollection.models.length)
          };
        }
      });
    },
    refreshFilterDropdown: function () {
      var renderPartialConfig = {
        template: filterBox,
        templateTarget: ".select-input",
        targetEl: ".select-input",
        context: this.el,
        useTemplateHtml: false,
        data: {
          filterList: this.targetVendorsFilterList,
          filterClass: "providers"
        }
      };
      this.renderPartialTemplate(renderPartialConfig);
      this.$(".select-input").trigger("liszt:updated");
    },
    showRetiredSegmentsInWarningPopup: function () {
      const $gridHolder = this.$(".grid-holder");
      const $msgHolder = this.$(".tabWarning");
      const $retiredSegmentsHolder = this.$(".retired-segments-holder");
      const retiredSegments = this.retiredCollection.models;
      var utils = T1.Utils;
      var boldLastPartOfFullPath = utils.boldLastPartOfFullPath;
      var str = "";
      $msgHolder.css("display", "block");
      $gridHolder.css("top", "155px");
      for (let i = 0; i < retiredSegments.length; i++) {
        const item = retiredSegments[i];
        const itemToDisplay = CampaignStrategyUtils.getPath(item.get("full_retired_path"));
        itemToDisplay.title = boldLastPartOfFullPath.call(utils, {
          fullPath: itemToDisplay.title,
          separator: " &raquo ",
          searchTermToBeHighlighted: null
        });
        str += `<div>${itemToDisplay.title}</div>`;
      }
      $retiredSegmentsHolder.append(str);
    },
    load: function () {
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        if (this.layout) {
          const currencyCode = this.sharedCollection.currencyCode;
          this.layout.load();
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "contextual_targeting"
          });
          if (this.retiredCollection.models.length) {
            this.showRetiredSegmentsInWarningPopup();
            this.retiredCollection = updateCPM(this.retiredCollection, currencyCode);
          }
          if (this.sharedCollection.loaded) {
            if (!this.searchFilterLoaded) {
              setTimeout(() => {
                this.setupSearchFilter();
              }, 0);
            }
            this.sharedCollection = updateCPM(this.sharedCollection, currencyCode);
          }
          this.isFormElementsChanged = false;
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(this.strategy.get("campaign"));
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    prepareRetiredCollectionForSave: function () {
      const retiredTargetingSegments = this.sharedCollection.models.reduce(function (arr, model) {
        if (model.get("type") === "retired_targeting_segment") {
          arr.push(model.get("id"));
        }
        return arr;
      }, []);
      return this.retiredCollection.buildPostObjectForRetiredSegments(retiredTargetingSegments);
    },
    onDontSave: function () {
      const currencyCode = this.sharedCollection.currencyCode;
      this.sharedCollection.reset([], {
        silent: true
      });
      this.sharedCollection.loaded = false;
      this.retiredCollection.reset([], {
        silent: true
      });
      this.retiredCollection.loaded = false;
      this.groupedSegments.fetch({
        success: () => {
          this.sharedCollection.loaded = true;
          this.sharedCollection = updateCPM(this.sharedCollection, currencyCode);
        },
        currency_code: currencyCode
      });
      this.retiredCollection.fetch({
        success: () => {
          this.retiredCollection.loaded = true;
          this.retiredCollection = updateCPM(this.retiredCollection, currencyCode);
        }
      });
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    prepareSharedCollection: function () {
      let safetySharedCollection;
      var strategyTargetDimensions = this.strategyTargetDimensions;
      let targetDimensions = strategyTargetDimensions.get(targetDimensionKey);
      if (!(targetDimensions && targetDimensions.length)) {
        strategyTargetDimensions.set({
          target_dimensions: [],
          partial: []
        });
        targetDimensions = strategyTargetDimensions.get(targetDimensionKey);
      }
      targetDimensions.each(function (dimension) {
        if (dimension.get("code") === "SFTY") {
          safetySharedCollection = dimension.get("target_value");
          safetySharedCollection.INCLUDE_OP = dimension.get("INCLUDE_OP");
          safetySharedCollection.EXCLUDE_OP = dimension.get("EXCLUDE_OP");
        }
      });
      this.targetDimensions = targetDimensions;
      this.safetySharedCollection = safetySharedCollection;
      this.updateTempCollection();
    },
    setDefaultAndTargetingVendorsOperators: function () {
      const defaultOperators = {};
      const iasName = "Integral Ad Science";
      this.targetSegmentProviders.each(function (model) {
        const name = model.get("name");
        defaultOperators[name] = name === iasName ? "AND" : "OR";
      });
      this.sharedCollection.defaultOperators = defaultOperators;
    },
    updateTempCollection: function () {
      const origSafeColl = this.safetySharedCollection;
      const tempSafeColl = {
        data: []
      };
      origSafeColl.each(function (model) {
        tempSafeColl.data.push(model.clone());
      });
      this.safetySharedCollection.tempBadgesCollection = tempSafeColl;
      this.safetySharedCollection.tempBadgesCollection.strategyId = this.strategy.id;
    },
    getSafetySettings: function () {
      const $formList = this.$(".w-foot .form-list");
      var errorMessage = "This is a required field";
      var errors = [];
      var values = {};
      $formList.each(function (index, el) {
        var list = $(el);
        var isChecked = list.find(".checkbox").is(":checked");
        var selectBox = list.find(".select");
        var dataBind = selectBox.data("bind");
        var settingsVal = selectBox.val();
        if (isChecked && settingsVal === "") {
          errors.push({
            field: dataBind,
            message: errorMessage
          });
        } else {
          values[dataBind] = settingsVal;
        }
      });
      if (errors.length) {
        this.showErrors(undefined, errors, this.$(".w-foot"));
      }
      return {
        errors: errors,
        values: values
      };
    },
    validateSafetySettings: function () {
      var safetyCollection = this.safetyCollection;
      var inValidSelection = false;
      this.safetySharedCollection.each(function (safetyDimension) {
        if (!safetyCollection.get(safetyDimension.id)) {
          inValidSelection = true;
        }
      });
      if (inValidSelection) {
        this.getSafetySettings();
        this.updateFooterMessage({
          error: true,
          message: "Your UGC selection is no longer supported. Please make a new selection."
        });
      }
    },
    postData: function (formValues, successCallback, statusInvalidCallback) {
      const self = this;
      var postData = this.sharedCollection.buildPostObj();
      var strategyTargetSegments = new StrategyTargetSegments();
      var strategyRetiredSegments = new RetiredSegments();
      const retiredSegmentsPostData = this.prepareRetiredCollectionForSave();
      var safetySettings = this.getSafetySettings();
      var strategyId = this.strategy.id;
      function saveRetiredSegments() {
        strategyRetiredSegments.strategyId = strategyId;
        strategyRetiredSegments.save({
          data: retiredSegmentsPostData
        }, {
          success: retiredSucces,
          conflict: statusInvalidCallback,
          onStatusInvalid: statusInvalidCallback
        });
      }
      function success() {
        if (!$.isEmptyObject(retiredSegmentsPostData)) {
          saveRetiredSegments();
        } else {
          self.strategy.fetch({
            silent: true
          });
          self.strategyTargetDimensions.saveTargetValues(function () {
            self.reload(true, function () {
              self.isFormElementsChanged = false;
              self.strategy.fetch({
                silent: true
              });
              self.prepareSharedCollection();
              T1.EventHub.publish("strategy-create-edit:reloadSubViews");
              successCallback.call(self);
            });
          }, statusInvalidCallback, undefined, undefined, self);
        }
      }
      function retiredSucces() {
        self.strategy.fetch({
          silent: true
        });
        self.strategyTargetDimensions.saveTargetValues(function (...args) {
          successCallback(...args);
        }, statusInvalidCallback, undefined, undefined, self);
      }
      if (safetySettings.errors.length) {
        statusInvalidCallback([]);
        return;
      }
      strategyTargetSegments.id = strategyId;
      if ($.isEmptyObject(postData) || !strategyTargetSegments.id) {
        statusInvalidCallback(strategyTargetSegments, []);
        if (!$.isEmptyObject(retiredSegmentsPostData)) {
          saveRetiredSegments();
        }
      }
      strategyTargetSegments.action = "targeting_segments";
      strategyTargetSegments.save({
        data: postData
      }, {
        success: success,
        conflict: statusInvalidCallback,
        onStatusInvalid: statusInvalidCallback
      });
    }
  });
  return T1.Form.ViewUtils.extend(ContextualPanel);
});
