define('modules/campaign/bulkedit/review/views/readOnlyBrowse',["jQuery", "Underscore", "T1", "T1View", "text!../templates/readOnlyGridItem.html", "text!../templates/readOnlyGridHeader.html", "T1GridView", "collections/concepts", "collections/targetValues", "collections/pixels", "When"], function ($, _, T1, T1View, gridItemTemplate, gridHeader, T1GridView, Concepts, TargetValues, Pixels, When) {
  "use strict";

  var BulkEditReviewActionsGrid = T1View.extend({
    partials: {
      listItem: gridItemTemplate,
      gridHeader: gridHeader
    },
    events: {},
    dataEvents: {},
    eventhubEvents: {},
    initialize: function ({
      executionStatus: executionStatus,
      bulkeditor: bulkeditor
    }) {
      this.executionStatus = executionStatus;
      this.bulkeditor = bulkeditor;
      this.gridLoader = this.el.parents().find(".loader-holder");
      this.retrieveDataCollections();
    },
    getStagedActions: function (actionTag) {
      var stagedActions = this.bulkeditor.get("stagedActions");
      const resultActions = {
        ids: []
      };
      $.each(stagedActions, function (index, {
        tag: tag,
        add: add,
        remove: remove,
        overwrite: overwrite
      }) {
        if (tag === actionTag) {
          if (add) {
            resultActions.action = "add";
            resultActions.ids.push(add.id);
          } else if (remove) {
            resultActions.action = "remove";
            resultActions.ids.push(remove.id);
          } else if (overwrite) {
            resultActions.action = "overwrite";
            resultActions.ids.push(overwrite.id);
          }
        }
      });
      return resultActions;
    },
    retrieveDataCollections: function () {
      let concepts, conceptsFetch, locations, locationsFetch, pixels, pixelsFetch;
      var conceptsStaged = this.getStagedActions("concepts");
      var locationsStaged = this.getStagedActions("location");
      var pixelsStaged = this.getStagedActions("mydata");
      var zipcodesStaged = this.getStagedActions("zipcodes");
      var promises = [];
      const conceptsExist = Boolean(conceptsStaged.ids.length);
      const locationsExist = Boolean(locationsStaged.ids.length);
      const pixelsExist = Boolean(pixelsStaged.ids.length);
      const zipcodesExist = Boolean(zipcodesStaged.ids.length);
      if (conceptsExist) {
        conceptsFetch = When.defer();
        promises.push(conceptsFetch.promise);
        concepts = new Concepts();
        this.conceptsAction = conceptsStaged.action;
      }
      if (locationsExist) {
        locationsFetch = When.defer();
        promises.push(locationsFetch.promise);
        locations = new TargetValues();
        this.locationAction = locationsStaged.action;
      }
      if (zipcodesExist) {
        this.zipcodesFetch = When.defer();
        promises.push(this.zipcodesFetch.promise);
        this.zipcodes = new TargetValues();
        this.zipcodesAction = zipcodesStaged.action;
      }
      if (pixelsExist) {
        pixelsFetch = When.defer();
        promises.push(pixelsFetch.promise);
        pixels = new Pixels();
        pixels.canCache = false;
        this.pixelsAction = pixelsStaged.action;
      }
      if (conceptsExist) {
        concepts.fetchOptions = {
          full: "*"
        };
        concepts.pageLimit = 100;
        concepts.isPaginated = false;
        concepts.search.set({
          idList: conceptsStaged.ids,
          fetchRequired: true,
          success: coll => {
            this.concepts = this.assignActionProperties(coll, "concepts");
            conceptsFetch.resolve();
          }
        });
      }
      if (locationsExist) {
        locations.fetchOptions = {
          full: "*",
          include_ancestors: 1
        };
        locations.pageLimit = 100;
        locations.isPaginated = false;
        locations.search.set({
          idList: locationsStaged.ids,
          fetchRequired: true,
          success: coll => {
            this.locations = this.processTargetValueProperties(coll, "location");
            locationsFetch.resolve();
          }
        });
      }
      if (pixelsExist) {
        pixels.fetchOptions = {
          full: "*"
        };
        pixels.pageLimit = 100;
        pixels.noUniquesData = true;
        pixels.search.set({
          idList: pixelsStaged.ids,
          fetchRequired: true,
          success: coll => {
            this.pixels = this.assignActionProperties(coll, "mydata");
            pixelsFetch.resolve();
          }
        });
      }
      if (zipcodesExist) {
        this.zipcodes.fetchOptions = {
          full: "*",
          dimension: "USZC"
        };
        this.zipcodes.pageLimit = 100;
        this.zipcodes.isPaginated = false;
        const zipCodesNestedArray = this.prepareZipCodeListArray(zipcodesStaged.ids);
        this.zipCodeTotalCount = zipcodesStaged.ids.length;
        this.zipCodeIterator = zipCodesNestedArray[Symbol.iterator]();
        this.fetchZipCodes(this.zipCodeIterator.next().value);
      }
      When.all(promises).then(() => {
        this.gridLoader.hide();
        this.load();
      });
    },
    fetchZipCodes: function (zipCodeValue) {
      this.zipcodes.search.set({
        idList: zipCodeValue,
        fetchRequired: true,
        success: coll => {
          const nextZipCodeArray = this.zipCodeIterator.next();
          if (!nextZipCodeArray.done) {
            this.fetchZipCodes(nextZipCodeArray.value);
          } else {
            this.zipcodesFetch.resolve();
          }
          this.zipcodes = this.assignActionProperties(coll, "zipcodes");
        }
      });
    },
    prepareZipCodeListArray: function (zipcodeStageIds) {
      const maxUrlLength = 1024;
      const zipCodeNestedArray = [];
      let batchOfZipcodes = [];
      for (const id of zipcodeStageIds) {
        const contentLength = batchOfZipcodes.toString().length;
        if (contentLength + String(id).length < maxUrlLength) {
          batchOfZipcodes.push(id);
        } else {
          zipCodeNestedArray.push([...batchOfZipcodes]);
          batchOfZipcodes = [id];
        }
      }
      zipCodeNestedArray.push([...batchOfZipcodes]);
      return zipCodeNestedArray;
    },
    load: function () {
      this.render().then(() => {
        if (this.executionStatus === "error") {
          this.$(".content-holder").addClass("bulk-changes-grid-error");
        }
      });
    },
    unload: function () {
      if (this.concepts) {
        this.concepts.reset([], {
          silent: true
        });
      }
      if (this.pixels) {
        this.pixels.reset([], {
          silent: true
        });
      }
    },
    assignActionProperties: function (coll, tag) {
      let operatorAction;
      var stagedActions = this.bulkeditor.get("stagedActions");
      var action = this.getStagingAction(tag);
      var pixelsUpdateBlockFound = false;
      if (stagedActions.length) {
        $.each(stagedActions, function (index, actionObj) {
          if (actionObj.tag === tag && actionObj[action]) {
            const model = coll.get(actionObj[action].id);
            if (model) {
              if (actionObj[action].weighting) {
                model.set({
                  weighting: actionObj[action].weighting.toLowerCase(),
                  percent: actionObj[action].percent
                });
              }
              if (actionObj[action].exclude_flag !== undefined) {
                model.set({
                  assignment: actionObj[action].exclude_flag ? "Exclude" : "Include"
                });
              }
              if (actionObj[action].exclude !== undefined) {
                model.set({
                  assignment: actionObj[action].exclude ? "Exclude" : "Include"
                });
              }
            }
          }
          if (actionObj.tag === tag && actionObj.update) {
            if (actionObj.update.pixel_target_include_operator) {
              pixelsUpdateBlockFound = true;
              operatorAction = actionObj.update.pixel_target_include_operator;
            }
          }
        });
        if (pixelsUpdateBlockFound) {
          coll.each(function (modelObject) {
            if (modelObject.get("assignment") === "Exclude") {
              modelObject.set({
                includeOperatorExist: true,
                includeOperatorDoesntExist: false,
                includeOperator: "or"
              });
            } else {
              modelObject.set({
                includeOperatorExist: true,
                includeOperatorDoesntExist: false,
                includeOperator: operatorAction.toLowerCase()
              });
            }
          });
        }
        if (!pixelsUpdateBlockFound && tag === "mydata") {
          coll.each(function (modelObject) {
            modelObject.set({
              includeOperatorExist: false,
              includeOperatorDoesntExist: true
            });
          });
        }
      }
      return coll;
    },
    processTargetValueProperties: function (coll, tag) {
      var self = this;
      var stagedActions = this.bulkeditor.get("stagedActions");
      var action = this.getStagingAction(tag);
      var resultCollection = [];
      function addParent(thisModel, path) {
        if (thisModel.get("parent_value_id")) {
          const parent = coll.get(thisModel.get("parent_value_id"));
          path = `${parent.get("name")} &raquo ${path}`;
          path = addParent(parent, path);
        }
        return path;
      }
      if (stagedActions.length) {
        $.each(stagedActions, function (index, actionObj) {
          if (actionObj.tag === tag) {
            const model = coll.get(actionObj[action].id);
            let fullPath = model.get("name");
            fullPath = addParent(model, fullPath);
            model.set({
              name: fullPath,
              assignment: actionObj[action].exclude ? "exclude" : "include",
              type: "Location",
              bulkAction: self.locationAction === "add" ? "Add" : self.locationAction !== "remove" ? "overwrite" : "remove"
            });
            resultCollection.push(model.toJSON());
          }
        });
      }
      return resultCollection;
    },
    getStagingAction: function (tag) {
      var stagedActions = this.bulkeditor.get("stagedActions");
      for (let i = 0; i < stagedActions.length; i++) {
        if (stagedActions[i].tag === tag) {
          return stagedActions[i].add !== undefined ? "add" : stagedActions[i].overwrite !== undefined ? "overwrite" : "remove";
        }
      }
    },
    serialize: function () {
      var list = [];
      var pixelsHash = {
        event: [],
        data: [],
        dynamic: []
      };
      function pixelArrIterator(pixelModel) {
        const pixelType = pixelModel.pixel_type;
        pixelModel.type = pixelType === "dynamic" ? "Segment" : `${pixelType.capitalize()} Pixels`;
        pixelModel.bulkAction = this.pixelsAction === "add" ? "Add" : this.pixelsAction !== "remove" ? "overwrite" : "remove";
        list.push(pixelModel);
      }
      if (this.concepts && this.concepts.length) {
        this.concepts.each(model => {
          const tempModel = model.toJSON();
          tempModel.assignment = "Include";
          tempModel.type = "Creatives";
          tempModel.bulkAction = this.conceptsAction === "add" ? "Add" : this.conceptsAction !== "remove" ? "overwrite" : "remove";
          list.push(tempModel);
        });
      }
      if (this.locations && this.locations.length) {
        this.locations.forEach(jsonObj => {
          jsonObj.name = jsonObj.name.replace(/\||\//g, "&raquo");
          list.push(jsonObj);
        });
      }
      if (this.pixels && this.pixels.length) {
        this.pixels.each(model => {
          const tempModel = model.toJSON();
          const pixelType = tempModel.pixel_type;
          pixelsHash[pixelType].push(tempModel);
        });
        for (const pixelKey in pixelsHash) {
          if (T1.Utils.hasOwnProp(pixelsHash, pixelKey) && pixelsHash[pixelKey]) {
            const pixelArr = pixelsHash[pixelKey];
            pixelArr.forEach(pixelArrIterator.bind(this));
          }
        }
      }
      if (this.zipcodes && this.zipcodes.length) {
        list.push({
          type: "Locations (Postal Code)",
          bulkAction: this.zipcodesAction === "add" ? "Add" : this.zipcodesAction !== "remove" ? "overwrite" : "remove",
          assignment: "Include",
          name: `Postal Codes Applied (${this.zipCodeTotalCount})`
        });
      }
      return {
        list: list
      };
    }
  });
  return T1GridView(BulkEditReviewActionsGrid);
});
