define('modules/strategy/supply/batch/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "strategy/supply/batch",
    viewCfgs: {
      bins: {},
      browse: {},
      panel: {},
      readOnly: {
        model: null
      }
    },
    defaultView: "panel"
  });
});
