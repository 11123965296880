define('modules/companion/models/companionTypes',["jQuery"], function ($) {
  return {
    adServer: [{
      text: "Atlas",
      value: "Atlas"
    }, {
      text: "DART for Advertisers (DFA)",
      value: "DART for Advertisers (DFA)"
    }, {
      text: "EyeWonder",
      value: "EyeWonder"
    }, {
      text: "MediaFORGE",
      value: "MediaFORGE"
    }, {
      text: "MediaMath",
      value: "MediaMath"
    }, {
      text: "Mediaplex",
      value: "Mediaplex"
    }, {
      text: "Other",
      value: "Other"
    }, {
      text: "PointRoll",
      value: "PointRoll"
    }, {
      text: "Sizmek  MDX",
      value: "MediaMind (Eyeblaster)"
    }, {
      text: "Yield Manager",
      value: "Yield Manager"
    }],
    typ: [{
      text: "SWF",
      value: "static/swf"
    }, {
      text: "GIF",
      value: "static/gif"
    }, {
      text: "JPG",
      value: "static/jpg"
    }, {
      text: "PNG",
      value: "static/png"
    }, {
      text: "IFrame",
      value: "iframe"
    }, {
      text: "HTML",
      value: "html"
    }],
    typ_3pas: [{
      text: "IFrame",
      value: "iframe"
    }, {
      text: "HTML",
      value: "html"
    }],
    check3pas: function (adServer) {
      return adServer.toLowerCase() !== "mediamath";
    },
    mimeMap: {
      "application/swf": "swf",
      "image/gif": "gif",
      "image/jpeg": "jpg",
      "image/png": "png"
    },
    toOptionsList: function (list, selection) {
      var i;
      var output = [];
      for (i = 0; i < list.length; i++) {
        if (list[i].text) {
          var o = $.extend({}, list[i]);
          o.selected = list[i].value === selection;
          output.push(o);
        } else {
          output.push({
            text: list[i],
            value: list[i],
            selected: list[i] === selection
          });
        }
      }
      return output;
    }
  };
});
