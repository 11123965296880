define('modules/media/pmp-e/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  let instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "media/pmp-e",
      viewCfgs: {
        "pmp-e": {},
        dealEdit: {},
        binGrid: {},
        dealGroupEdit: {},
        binStrandGrid: {}
      },
      defaultView: "pmp-e"
    });
  }
  return instance;
});
