define('collections/attributes',["jQuery", "Underscore", "T1", "Backbone", "T1Comm", "T1Collection", "models/attribute", "T1Sortable"], function ($, _, T1, Backbone, T1Comm, T1Collection, Attribute) {
  "use strict";

  var Attributes = Backbone.Collection.extend({
    model: Attribute,
    url: T1.SEGMENT_API_BASE + "attributes",
    pageOffset: 0,
    fetch: function (options) {
      var data = {
        full: "*"
      };
      if (this.pageLimit) {
        data.page_limit = this.pageLimit;
      }
      if (this.pageOffset) {
        data.page_offset = this.pageOffset;
      }
      if (this.advertiserId) {
        data.q = "owner_type==advertiser&owner_id==" + this.advertiserId;
      } else if (this.pixelId) {
        data.q = "owner_type==pixel_bundle&owner_id==" + this.pixelId;
      }
      options.data = data;
      return Backbone.Collection.prototype.fetch.call(this, options);
    },
    parse: function (resp) {
      return resp.data;
    },
    saveAll: function (opts) {
      var owner = opts.owner;
      var def = $.Deferred();
      var targetLength = this.models.length - 1;
      if (this.models && this.models.length) {
        _.each(this.models, function (model, index) {
          model.set({
            data_type: setDataType(model),
            owner_id: owner.id,
            owner_type: owner.get("type"),
            key: model.get("key")
          });
          model.save({}, {
            success: function () {
              if (index === targetLength) {
                def.resolve();
              }
            },
            error: function () {
              def.reject("Attribute creation failed.");
            }
          });
        });
      } else {
        def.resolve();
      }
      function setDataType(attr) {
        var dataType;
        switch (attr.get("data_type")) {
          case "String":
            dataType = "text";
            break;
          case "Numeric":
            dataType = "numeric";
            break;
          case "Date":
            dataType = "datetime";
            break;
        }
        return dataType;
      }
      return def.promise();
    },
    deleteAll: function () {
      var def = $.Deferred();
      if (Array.isArray(this.models) && this.models.length > 0) {
        this.models.forEach(destroyModels, def);
      } else {
        def.resolve();
      }
      function destroyModels(model, i, arr) {
        var deferred = this;
        model.destroy({
          url: model.url() + "?override=true",
          success: function () {
            if (arr.length === 0) {
              deferred.resolve();
            }
          },
          error: function () {
            deferred.reject("Attribute deletion failed");
          }
        });
      }
      return def.promise();
    }
  });
  return Attributes;
});
