define('utils/currencyConversionHelper',["Underscore", "jQuery"], function (_, $) {
  return {
    getCpmResultForTargeting: function (resultAry, currency) {
      var result = "";
      if (_.isArray(resultAry)) {
        $.each(resultAry, function (index, value) {
          if (value.currency_code === currency) {
            result = value.value;
          }
        });
      } else {
        result = resultAry.value;
      }
      return result;
    },
    updateCpmTargetingValuesWithCurrency: function (collection, currencyCode) {
      var self = this;
      var models = collection.models;
      _.each(models, function (model) {
        var wholesaleCpmAmount = model.get("wholesale_cpm_amount");
        var retailCpmAmount = model.get("retail_cpm_amount");
        if (wholesaleCpmAmount) {
          model.set({
            wholesale_cpm: self.getCpmResultForTargeting(wholesaleCpmAmount, currencyCode)
          });
        }
        if (retailCpmAmount) {
          model.set({
            retail_cpm: self.getCpmResultForTargeting(retailCpmAmount, currencyCode)
          });
        }
      });
      return collection;
    },
    updatePeer39ChannelFeeCpmWithCurrency: function (model, currencyCode) {
      var peer39ChannelFeeCpmAmount = model && model.get("peer39_channel_fee_cpm_amount") || undefined;
      var peer39CustomFeeCpmAmount = model && model.get("peer39_custom_fee_cpm_amount") || undefined;
      var peer39QualityFeeCpmAmount = model && model.get("peer39_quality_fee_cpm_amount") || undefined;
      var peer39SafetyFeeCpmAmount = model && model.get("peer39_safety_fee_cpm_amount") || undefined;
      if (peer39ChannelFeeCpmAmount) {
        model.set({
          peer39_channel_fee_cpm: this.getCpmResultForTargeting(peer39ChannelFeeCpmAmount, currencyCode)
        });
      }
      if (peer39CustomFeeCpmAmount) {
        model.set({
          peer39_custom_fee_cpm: this.getCpmResultForTargeting(peer39CustomFeeCpmAmount, currencyCode)
        });
      }
      if (peer39QualityFeeCpmAmount) {
        model.set({
          peer39_quality_fee_cpm: this.getCpmResultForTargeting(peer39QualityFeeCpmAmount, currencyCode)
        });
      }
      if (peer39SafetyFeeCpmAmount) {
        model.set({
          peer39_safety_fee_cpm: this.getCpmResultForTargeting(peer39SafetyFeeCpmAmount, currencyCode)
        });
      }
      return model;
    },
    updatePMPePriceWithCurrency: function (collection, currencyCode) {
      var self = this;
      var models = collection.models;
      _.each(models, function (model) {
        var priceAmount = model.get("price_amount");
        if (priceAmount) {
          model.set({
            price: self.getCpmResultForTargeting(priceAmount, currencyCode)
          });
          model.set({
            currency_code: currencyCode
          });
        }
      });
      return collection;
    }
  };
});
