define('modules/T1App/views/tabs',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!../templates/tabs.html", "text!../templates/header.html", "T1TabLayoutV2", "../myApps/models/myApps", "models/t1AppPermission", "collections/t1AppPermission", "models/userPreferences", "T1Signature", "T1Preferences"], function ($, _, T1, T1View, T1Permissions, template, headTemplate, T1TabLayout, Model, AppPermissionModel, AppPermissionCollection, UserPreferences) {
  "use strict";

  return T1View.extend({
    model: new Model(),
    template: template,
    partials: {
      header: headTemplate
    },
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    appTab: 0,
    initialize: function () {
      var self = this;
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "apps"], ["view", "tabs"]]));
      self.preferences = T1.Preferences.create.call(self, signature, UserPreferences);
      self.preferences.mark(["appTab"]);
      self.appTab = parseInt(self.preferences.get("appTab"));
    },
    load: function () {
      var self = this;
      var totalTabs;
      self.appPermissionCollection = new AppPermissionCollection();
      var tabs = [{
        label: "Apps",
        module: "T1App/apps",
        routeName: "apps",
        viewType: "apps",
        options: {}
      }, {
        label: "Labs",
        module: "T1App/labs",
        routeName: "labs",
        viewType: "labs",
        options: {}
      }];
      this.render().then(function () {
        self.appPermissionCollection.fetch({
          success: function (data) {
            if (data.models.length > 0) {
              tabs.push({
                label: "My Apps",
                module: "T1App/myApps",
                routeName: "myApps",
                viewType: "myApps",
                options: {}
              });
            }
            totalTabs = tabs.length - 1;
            if (self.appTab > totalTabs || self.appTab < 0) {
              self.appTab = 0;
            }
            self.layout = new T1TabLayout({
              tabSetName: "AppList",
              level: 1,
              disabled: [],
              tabs: tabs,
              ignoreRouteDepth: true,
              selected: self.appTab || 0
            });
            self.layout.el = self.el.find(".apps-admin-tabs");
            self.layout.load();
          },
          error: function () {
            totalTabs = tabs.length - 1;
            if (self.appTab > totalTabs || self.appTab < 0) {
              self.appTab = 0;
            }
            self.layout = new T1TabLayout({
              tabSetName: "AppList",
              level: 1,
              disabled: [],
              tabs: tabs,
              ignoreRouteDepth: true,
              selected: self.appTab || 0
            });
            self.layout.el = self.el.find(".apps-admin-tabs");
            self.layout.load();
          }
        });
      });
    },
    saveSelectedTab: function (opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, function (tab) {
        return tab.label === selectedLabel;
      }), tabs);
      if (parseInt(selectedIndex) < 0) {
        this.appTab = parseInt(0);
      } else {
        this.appTab = parseInt(selectedIndex);
      }
      this.preferences.save();
    },
    serialize: function () {
      return {
        title: "Appstore",
        subTitle: false
      };
    }
  });
});
