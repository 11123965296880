define('modules/reporting/campaigns/dataExport/createEdit/views/filterGrid',["jQuery", "T1", "T1View", "text!../templates/filterGrid.html"], function ($, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const CHILD_LEVEL = 2;
  return T1View.extend({
    template: template,
    recyclerScrollTop: 0,
    eventhubEvents: {
      "createEdit.showLoader": "showLoader",
      "createEdit.updateGridItems": "updateGridData",
      "filter.resetSearch": "resetSearch"
    },
    initialize: function (args) {
      this.DataExportModel = args.dataExportModel;
      this.GridModel = args.gridModel;
    },
    load: function () {
      const $el = this.el;
      const parentView = this;
      const {
        caratClickHandler: caratClickHandler,
        includeClickHandler: includeClickHandler,
        rowClickHandler: rowClickHandler
      } = this;
      this.render().then(() => {
        this.$filterGrid = $el.find(".filter-grid");
        this.$filterGrid[0].parentView = parentView;
        this.$filterGrid[0].mixinFindable = {
          mixinsForValuePath: function () {
            return null;
          },
          mixinsForValue: function () {
            return {
              caratClickHandler: caratClickHandler,
              includeClickHandler: includeClickHandler,
              rowClickHandler: rowClickHandler
            };
          }
        };
        this.$filterLoader = $el.find(".load-spinner");
        if (this.DataExportModel.get("editReport")) {
          T1Publish("load:savedData");
        } else {
          T1Publish("filterGrid.loadData");
        }
      });
    },
    unload: function () {
      this.$filterGrid = null;
      this.$filterLoader = null;
      this.GridModel = null;
    },
    showLoader: function () {
      this.$filterLoader.fadeIn();
    },
    updateGridData: function (args) {
      if (!this.$filterGrid) {
        return;
      }
      const self = this;
      const grid = this.$filterGrid[0];
      const recycler = grid.querySelector("#viewport");
      this.searchMode = Boolean(args.search);
      this.dataIsLoading = false;
      T1Publish("filterGrid.hideLoader");
      this.$filterLoader.fadeOut();
      const data = this.searchMode ? this.GridModel.getSearchData() : this.GridModel.getGridData();
      grid.set("index", 0);
      grid.set("data", data);
      recycler.addEventListener("presentation-settled", function fn() {
        recycler.removeEventListener("presentation-settled", fn);
        recycler.$.pane.scrollTop = self.recyclerScrollTop;
      });
    },
    resetSearch: function () {
      this.recyclerScrollTop = 0;
    },
    caratClickHandler: function (e) {
      const parentView = this.scope.parentView;
      const event = Polymer && Polymer.dom ? Polymer.dom(e) : e;
      const clickedRow = parentView._getRowFromPath(event);
      parentView.recyclerScrollTop = clickedRow.scope.$.viewport.$.pane.scrollTop;
      if (!parentView.dataIsLoading) {
        const clickedItem = event.localTarget ? event.localTarget : event.target;
        parentView.expandCollapseGrid(clickedRow, clickedItem);
      }
    },
    rowClickHandler: function (e) {
      const parentView = this.scope.parentView;
      const event = Polymer.dom(e);
      const tagName = event.localTarget.tagName.toLowerCase();
      const iconClicked = tagName === "strand-icon";
      if (iconClicked || parentView.searchMode) {
        return;
      }
      const clickedRow = parentView._getRowFromPath(event);
      const clickedItem = clickedRow.querySelector(".carat-icon");
      parentView.recyclerScrollTop = clickedRow.scope.$.viewport.$.pane.scrollTop;
      if (!parentView.dataIsLoading) {
        parentView.expandCollapseGrid(clickedRow, clickedItem);
      }
    },
    expandCollapseGrid: function (row, icon) {
      const clickedRow = row;
      const level = clickedRow.getAttribute("level-id");
      const selectedReport = this.DataExportModel.get("selectedReport");
      if (level < CHILD_LEVEL && selectedReport !== "Data Pixel Loads" || level < 1 && selectedReport === "Data Pixel Loads") {
        const dataId = clickedRow.getAttribute("data-id");
        const dataName = clickedRow.getAttribute("data-name");
        const clickedIcon = icon;
        this.GridModel.setClickItem(dataId, level, dataName);
        if (clickedIcon.classList.contains("level-expanded")) {
          this.GridModel.removeGridData();
        } else {
          this.dataIsLoading = true;
          const carat = clickedRow.querySelector(".carat-icon");
          const loader = clickedRow.querySelector(".loader-icon");
          carat.style.display = "none";
          loader.style.display = "block";
          this.GridModel.setFilterEntity(level);
          T1Publish("filterGrid.loadData");
        }
      }
    },
    includeClickHandler: function (e) {
      const parentView = this.scope.parentView;
      const event = Polymer && Polymer.dom ? Polymer.dom(e) : e;
      const includeIcon = event.localTarget ? event.localTarget : event.target;
      const gridRow = parentView._getRowFromPath(event);
      const id = gridRow.getAttribute("data-id");
      let state;
      switch (true) {
        case includeIcon.classList.contains("deselected"):
          state = "deselected";
          break;
        case includeIcon.classList.contains("selected-partial"):
          state = "selected-partial";
          break;
        case includeIcon.classList.contains("selected-outline"):
          state = "selected-outline";
          break;
        case includeIcon.classList.contains("selected"):
          state = "selected";
          break;
      }
      parentView.recyclerScrollTop = gridRow.scope.$.viewport.$.pane.scrollTop;
      parentView.updateInclude(id, state);
    },
    updateInclude: function (id, state) {
      this.GridModel.updateIconState(id, state);
      T1Publish("change:formElements", true);
      T1Publish("update:selectAllBtn");
    },
    render: function () {
      const $el = this.el;
      const deferred = $.Deferred();
      if (Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    _getRowFromPath: function (event) {
      let row;
      event.path.some(function (elem) {
        row = elem;
        return elem.tagName && elem.tagName.toLowerCase() === "strand-grid-item";
      });
      return row;
    }
  });
});
