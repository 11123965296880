define('modules/campaign/chart/winLoss/views/campaignSummaryChart',['require','Underscore','jQuery','modules/shared/configs/charts/scatterChartConfig','../models/mCampaignSummaryChart','text!../templates/controlsCampaignSummary.html','./funnelChart','T1','T1Layout','T1View','models/userPreferences'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const ChartConfig = require("modules/shared/configs/charts/scatterChartConfig");
  const ChartModel = require("../models/mCampaignSummaryChart");
  const controlsTemplate = require("text!../templates/controlsCampaignSummary.html");
  const FunnelChart = require("./funnelChart");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const UserPreferences = require("models/userPreferences");
  const colorLineInactive = "#E5E5E5";
  const T1Publish = T1.EventHub.publish;
  const bounceDelay = 200;
  return T1View.extend({
    dataEvents: {
      ReportModel: {
        "change:ddlMetricOptions": "updateMetricsMenu",
        "change:timeSlots": "resetClickedDot",
        "change:entity": function () {
          this.resetClickedDot(true);
        }
      }
    },
    eventhubEvents: {
      "change:export": "exportChartHandler",
      "change:viewBy": function ({
        value: value
      }) {
        this.winLossCampaignSummaryViewBy = value;
        this.preferences.save();
        this.ReportModel.setViewByDimension(value);
        this.resetClickedDot(true);
        T1Publish("fetch:initData");
      },
      "change:metric": function (metricObj) {
        this[`winLossCampaignSummaryMetric${metricObj.id}`] = metricObj.value;
        this.preferences.save();
        this.ReportModel.setSelectedMetrics("campaign", "summary", metricObj);
        this.resetClickedDot();
        this.updateChart();
      },
      "change:highestLowest": function ({
        range: range,
        metric: metric,
        excludeZeros: excludeZeros
      }) {
        this.winLossCampaignSummaryIncludeOnlyRange = range;
        this.winLossCampaignSummaryIncludeOnlyMetric = metric;
        this.winLossCampaignSummaryIncludeOnlyExcludeZeros = excludeZeros;
        this.preferences.save();
        this.ReportModel.setHighestLowest({
          range: range,
          metric: metric,
          excludeZeros: excludeZeros
        });
        this.resetClickedDot();
        this.updateChart();
      },
      "complete:layoutLoad": function () {
        this.layoutsLoaded();
      }
    },
    highestLowestOptions: [{
      value: "all",
      label: "All"
    }, {
      value: "hi10",
      label: "Highest 10"
    }, {
      value: "hi20",
      label: "Highest 20"
    }, {
      value: "hi50",
      label: "Highest 50"
    }, {
      value: "hi100",
      label: "Highest 100"
    }, {
      value: "lo10",
      label: "Lowest 10"
    }, {
      value: "lo20",
      label: "Lowest 20"
    }, {
      value: "lo50",
      label: "Lowest 50"
    }, {
      value: "lo100",
      label: "Lowest 100"
    }],
    viewByOptions: [{
      value: "strategy",
      label: "Strategy"
    }, {
      value: "deal",
      label: "Deal"
    }, {
      value: "creative",
      label: "Creative"
    }, {
      value: "exchange",
      label: "Exchange"
    }],
    winLossCampaignSummaryViewBy: "strategy",
    winLossCampaignSummaryMetric0: "win_rate",
    winLossCampaignSummaryMetric1: "average_bid_amount_cpm",
    winLossCampaignSummaryIncludeOnlyRange: "hi50",
    winLossCampaignSummaryIncludeOnlyMetric: "wins",
    winLossCampaignSummaryIncludeOnlyExcludeZeros: "false",
    initialize({
      ReportModel: ReportModel,
      ParentClass: ParentClass
    }) {
      let $el;
      this.ReportModel = ReportModel;
      this.ParentClass = ParentClass;
      this.ChartModel = new ChartModel({}, this.ReportModel);
      this.$el = $el = this.ParentClass.el;
      this.$canvas2 = $el.find("#chartCanvas2");
      this.$funnelHolder = $el.find(".funnel-holder");
      this.$funnelHeader = $el.find(".entity-header");
      this.$exportLink = $el.find(".chart-export-link");
      this.$tooltipHolder = $el.find(".tooltip-holder");
      this.$ttipTrigger = $el.find("#ttipTrigger");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      this.initPreferences();
      this.initFunnel();
      this.initTooltip();
      this.initLayout();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "monitor"], ["view", "campaign.chart"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["winLossCampaignSummaryViewBy", "winLossCampaignSummaryMetric0", "winLossCampaignSummaryMetric1", "winLossCampaignSummaryIncludeOnlyRange", "winLossCampaignSummaryIncludeOnlyMetric", "winLossCampaignSummaryIncludeOnlyExcludeZeros"]);
      this.ReportModel.setViewByDimension(this.winLossCampaignSummaryViewBy, true);
    },
    initTable() {},
    initFunnel() {
      const canvas = this.$canvas2[0];
      const funnelHolder = this.$funnelHolder[0];
      canvas.width = funnelHolder.clientWidth;
      canvas.height = funnelHolder.clientHeight;
      this.funnelHolderWidth = funnelHolder.clientWidth;
    },
    initTooltip() {
      this.ttip = new Strand.Tooltip();
      this.ttip.target = "#ttipTrigger";
      Polymer.dom(this.$tooltipHolder[0]).appendChild(this.ttip);
    },
    initLayout() {
      const selectedDimension = this.winLossCampaignSummaryViewBy;
      const options = {
        getReportModel: () => this.ReportModel
      };
      function getSelectedDimensionName() {
        return this.viewByOptions.find(item => selectedDimension === item.value).label;
      }
      this.layout = new T1Layout({
        el: () => this.ParentClass.$headerEl,
        template: controlsTemplate,
        layout: {
          ".dimensions-menu": [{
            module: "campaign/chart",
            viewType: "dimensionsMenu",
            options: {
              default: getSelectedDimensionName.call(this),
              dimensions: this.viewByOptions,
              id: 0,
              maxItems: 4,
              overflow: "visible",
              selected: selectedDimension
            }
          }],
          ".highest-lowest-menu": [{
            module: "campaign/chart",
            viewType: "highestLowestMenu",
            options: {
              ranges: this.highestLowestOptions
            }
          }],
          "#metricsMenu1": [{
            module: "campaign/chart",
            viewType: "metricsMenu",
            options: {
              id: 0,
              maxItems: 7,
              overflow: "visible",
              overflowWidth: 165
            }
          }],
          "#metricsMenu2": [{
            module: "campaign/chart",
            viewType: "metricsMenu",
            options: {
              id: 1,
              maxItems: 7,
              overflow: "visible",
              overflowWidth: 165
            }
          }],
          ".date-picker": [{
            module: "campaign/chart",
            options: options,
            viewType: "datePicker"
          }],
          ".export-menu": [{
            module: "campaign/chart",
            options: options,
            viewType: "exportMenu"
          }],
          ".info": [{
            module: "reporting/campaigns",
            options: options,
            viewType: "report_info"
          }]
        }
      });
      this.layoutsLoaded = _.after(_.size(this.layout.layout), () => {
        this.ParentClass.layoutLoaded();
      });
      this.layout.load();
    },
    updateMetricsMenu(model, metricOpts) {
      let metric0 = this.winLossCampaignSummaryMetric0;
      let metric1 = this.winLossCampaignSummaryMetric1;
      let highestLowest = this.winLossCampaignSummaryIncludeOnlyMetric;
      const ReportModel = this.ReportModel;
      const metric0exists = metricOpts.find(({
        value: value
      }) => value === metric0);
      const metric1exists = metricOpts.find(({
        value: value
      }) => value === metric1);
      const highestLowestExists = metricOpts.find(({
        value: value
      }) => value === highestLowest);
      if (!metric0exists) {
        this.winLossCampaignSummaryMetric0 = metric0 = "win_rate";
        this.preferences.save();
      }
      if (!metric1exists) {
        this.winLossCampaignSummaryMetric1 = metric1 = "average_bid_amount_cpm";
        this.preferences.save();
      }
      if (!highestLowestExists) {
        this.winLossCampaignSummaryIncludeOnlyMetric = highestLowest = "wins";
        this.preferences.save();
      }
      ReportModel.setSelectedMetrics("campaign", "summary", {
        id: 0,
        value: metric0
      });
      ReportModel.setSelectedMetrics("campaign", "summary", {
        id: 1,
        value: metric1
      });
      ReportModel.setHighestLowest({
        excludeZeros: this.winLossCampaignSummaryIncludeOnlyExcludeZeros,
        metric: highestLowest,
        range: this.winLossCampaignSummaryIncludeOnlyRange
      });
      T1Publish("change:metrics", {
        default: ReportModel.getSelectedMetricLabel(metric0),
        id: 0,
        metrics: metricOpts,
        selected: metric0
      });
      T1Publish("change:metrics", {
        default: ReportModel.getSelectedMetricLabel(metric1),
        id: 1,
        metrics: metricOpts,
        selected: metric1
      });
      T1Publish("change:metricsHighestLowest", {
        excludeZeros: this.winLossCampaignSummaryIncludeOnlyExcludeZeros,
        filter1: this.winLossCampaignSummaryIncludeOnlyRange,
        filter2: highestLowest,
        id: 0,
        metrics: metricOpts,
        title: ReportModel.getHighestLowest().title
      });
    },
    dimDots(clickedElement) {
      let clickID;
      const dotColors = this.ChartModel.getChartColors();
      const graphs = this.scatterChart.graphs;
      function resetColors() {
        for (const [index, graph] of graphs.entries()) {
          graph.lineColor = dotColors[index];
        }
      }
      if (clickedElement && clickedElement.id) {
        resetColors();
        for (const [index, graph] of graphs.entries()) {
          if (index !== clickedElement.index) {
            graph.lineColor = colorLineInactive;
          }
        }
        clickID = clickedElement.id;
        graphs.splice(graphs.length - 1, 0, graphs.splice(clickedElement.index, 1)[0]);
        dotColors.splice(graphs.length - 1, 0, dotColors.splice(clickedElement.index, 1)[0]);
      } else {
        resetColors();
        clickID = null;
      }
      this.scatterChart.validateData();
      this.prevClickID = this.clickID;
      this.clickID = clickID;
      this.ChartModel.setClicked(clickID);
      if (this.clickID !== this.prevClickID) {
        this.updateFunnel();
      }
    },
    resetClickedDot(forceChange) {
      this.prevClickID = forceChange ? undefined : this.clickID;
      this.clickID = null;
      this.ChartModel.setClicked(null);
    },
    updateMetricType() {
      const selectedMetrics = this.ReportModel.getSelectedMetrics();
      const [selectedMetric0, selectedMetric1] = selectedMetrics.campaign.summary;
      const metricOptions = this.ReportModel.reportMeta.get("metricOptions");
      const metricObj0 = metricOptions.find(({
        value: value
      }) => value === selectedMetric0.value);
      const metricObj1 = metricOptions.find(({
        value: value
      }) => value === selectedMetric1.value);
      metricObj0.label = selectedMetric0.label;
      metricObj1.label = selectedMetric1.label;
      this.metricObj0 = metricObj0;
      this.metricObj1 = metricObj1;
    },
    formatValue(value, index) {
      return this.ReportModel.formatValue(value, index === 0 ? this.metricObj0.type : this.metricObj1.type);
    },
    updateFunnelHeader() {
      const headerTxt = this.ChartModel.getFunnelHeader();
      this.$funnelHeader.text(headerTxt);
      this.$funnelHeader.attr("title", headerTxt);
    },
    updateFunnel() {
      this.FunnelChart = FunnelChart({
        canvas: this.$canvas2[0],
        formatter: this.ReportModel.dataFormatter,
        funnelData: this.ChartModel.getFunnelData(),
        isCreative: this.ReportModel.getViewByDimension() === "creative",
        ttip: this.ttip,
        ttipTrigger: this.$ttipTrigger
      });
      this.FunnelChart.draw();
      this.updateFunnelHeader();
    },
    setupChart() {
      const ParentClass = this.ParentClass;
      const scatterData = this.ChartModel.getChartData();
      if (!scatterData.dataProvider.length) {
        ParentClass.showNoDataGrid();
        return;
      }
      if (this.scatterChart) {
        this.scatterChart.clear();
        this.scatterChart = null;
      }
      this.updateMetricType();
      const scatterConfig = ChartConfig.copy();
      scatterConfig.dataProvider = scatterData.dataProvider;
      scatterConfig.graphs = scatterData.graphs;
      scatterConfig.valueAxes[0].labelFunction = value => this.formatValue(value, 0);
      scatterConfig.valueAxes[1].labelFunction = value => this.formatValue(value, 1);
      scatterConfig.valueAxes[0].title = this.metricObj0.label;
      scatterConfig.valueAxes[1].title = this.metricObj1.label;
      scatterConfig.listeners[0].method = evt => {
        evt.chart.chartDiv.addEventListener("click", this.dimDots.bind(this));
      };
      scatterConfig.listeners[1].method = evt => {
        this.dimDots(evt.graph);
        evt.event.stopPropagation();
      };
      ParentClass.hideNoDataGrid();
      this.scatterChart = AmCharts.makeChart("chartCanvas", scatterConfig);
      this.updateFunnel();
      const resizeFunnelChart = _.debounce(() => {
        const width = this.$funnelHolder[0].clientWidth;
        if (this.funnelHolderWidth !== width) {
          this.funnelHolderWidth = width;
          this.FunnelChart.draw(width);
        }
      }, bounceDelay);
      $(window).off("resize.charting").on("resize.charting", resizeFunnelChart);
    },
    updateChart() {
      const scatterChart = this.scatterChart;
      if (!scatterChart) {
        this.setupChart();
        return;
      }
      const ParentClass = this.ParentClass;
      const scatterData = this.ChartModel.getChartData();
      if (!scatterData.dataProvider.length) {
        ParentClass.showNoDataGrid();
        return;
      }
      this.updateMetricType();
      ParentClass.hideNoDataGrid();
      scatterChart.dataProvider = scatterData.dataProvider;
      scatterChart.graphs = scatterData.graphs;
      scatterChart.valueAxes[0].title = this.metricObj0.label;
      scatterChart.valueAxes[1].title = this.metricObj1.label;
      scatterChart.validateData();
      if (this.clickID !== this.prevClickID) {
        this.updateFunnel();
      }
    },
    exportChartHandler(type) {
      const link = this.$exportLink[0];
      switch (type) {
        case "png":
          link.download = "CampaignSummaryFunnelChart.png";
          link.href = this.$canvas2[0].toDataURL("image/png");
          link.click();
          this.scatterChart.export.capture({}, function () {
            this.toPNG({}, function (data) {
              this.download(data, this.defaults.formats.PNG.mimeType, "CampaignSummaryChart.png");
            });
          });
          break;
        case "print":
          this.scatterChart.export.capture({}, function () {
            this.toPRINT();
          });
          break;
        case "pdf":
          break;
      }
    },
    unloadChart() {
      this.resetClickedDot();
      $(window).off("resize.charting");
      if (this.FunnelChart) {
        this.FunnelChart.resetFunnel();
        this.FunnelChart.unload();
      }
      if (this.scatterChart) {
        this.scatterChart.chartDiv.removeEventListener("click", this.dimDots);
        this.scatterChart.clear();
        this.scatterChart = null;
      }
      if (this.layout) {
        this.layout.destroy();
        this.layout = null;
      }
      this.$tooltipHolder.empty();
      this.unbind();
      this.$canvas2 = null;
      this.$el = null;
      this.$exportLink = null;
      this.$funnelHeader = null;
      this.$funnelHolder = null;
      this.$tooltipHolder = null;
      this.$ttipTrigger = null;
      this.ChartModel = null;
      this.metricObj0 = null;
      this.metricObj1 = null;
      this.ParentClass = null;
      this.ReportModel = null;
      this.ttip = null;
    }
  });
});
