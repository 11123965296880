define('utils/AdminUtils',["jQuery", "Underscore", "T1", "utils/TimezoneUtils"], function ($, _, T1, TimezoneUtils) {
  "use strict";

  return {
    adminEntities: {
      Organization: {
        dataName: "org",
        urlPart: "organization"
      },
      Agency: {
        dataName: "agency",
        urlPart: "agency"
      },
      Advertiser: {
        dataName: "advertiser",
        urlPart: "advertiser"
      },
      "Site List": {
        dataName: "sitelist",
        urlPart: "siteList"
      },
      Vendor: {
        dataName: "vendor",
        urlPart: "vendor"
      }
    },
    spcOpts: {
      NONE: 0,
      LEFT: 1,
      RIGHT: 2,
      BOTH: 3
    },
    checkEntityPermission: function (user, entity, instance, permissionCheckArr, checkMethod) {
      var noInstance = instance === undefined;
      var permissions = user.permissions;
      var access = permissions.entities && permissions.entities[entity].access;
      if (access) {
        permissionCheckArr = permissionCheckArr || ["w"];
        if (_.isArray(access)) {
          checkMethod = noInstance ? checkMethod || "find" : "find";
          instance = parseInt(instance, 10);
          return Boolean(_[checkMethod](access, function (item) {
            return (noInstance || parseInt(item.id, 10) === instance) && _.some(permissionCheckArr, function (perm) {
              return item.value === perm;
            });
          }));
        }
        return _.some(permissionCheckArr, function (perm) {
          return access.value === perm;
        });
      }
      return user.scope === "GLOBAL" && user.role === "ADMIN";
    },
    checkUserPermissions: function (actionUser, targetUser) {
      var userType = actionUser.get("type");
      var userRole = actionUser.get("role");
      var userScope = actionUser.get("scope");
      var sessionUserID = actionUser.get("id");
      var targetedUserID = targetUser && targetUser.get("id");
      var targetedUserType = targetUser && targetUser.get("type");
      var targetedUserRole = targetUser && targetUser.get("role");
      var isTargetedUserActive = targetUser && targetUser.get("active") === "1";
      var isTargetedUserSessionUser = targetedUserID && targetedUserID.toString() === sessionUserID;
      var isTargetedUserMMUser = targetedUserType === "INTERNAL";
      var isTargetedUserAgencyUser = targetedUserType === "AGENCY";
      var isTargetUserAgencyAndLowerThenAdmin = targetedUserType === "AGENCY" && targetedUserRole !== "ADMIN";
      var isTargetedUserMMAndLowerThanSessionUser = isTargetedUserMMUser && (targetedUserRole === "MANAGER" || targetedUserRole === "REPORTER");
      var isSessionUserAgencyAdmin = userType === "AGENCY" && userRole === "ADMIN";
      var isSessionUserMMGlobalAdmin = userType === "INTERNAL" && userScope === "GLOBAL" && userRole === "ADMIN";
      var isSessionUserMMRestrictedAdmin = userType === "INTERNAL" && userScope === "SELECT" && userRole === "ADMIN";
      var isSessionUserAgencyManager = userType === "AGENCY" && userRole === "MANAGER";
      return {
        canActivate: !isTargetedUserActive && !isTargetedUserSessionUser && (isSessionUserAgencyAdmin || isSessionUserMMGlobalAdmin || isSessionUserAgencyManager && isTargetUserAgencyAndLowerThenAdmin || isSessionUserMMRestrictedAdmin && (isTargetedUserMMAndLowerThanSessionUser || isTargetedUserAgencyUser)),
        canDeactivate: isTargetedUserActive && !isTargetedUserSessionUser && (isSessionUserAgencyAdmin || isSessionUserMMGlobalAdmin || isSessionUserAgencyManager && isTargetUserAgencyAndLowerThenAdmin || isSessionUserMMRestrictedAdmin && (isTargetedUserMMAndLowerThanSessionUser || isTargetedUserAgencyUser)),
        canEdit: isTargetedUserSessionUser || isSessionUserAgencyAdmin || isSessionUserMMGlobalAdmin || isSessionUserAgencyManager && isTargetUserAgencyAndLowerThenAdmin || isSessionUserMMRestrictedAdmin && (isTargetedUserMMAndLowerThanSessionUser || isTargetedUserAgencyUser)
      };
    },
    explicitlyEnabledAgencyFound: function (user, orgId) {
      var permissions = user.permissions;
      var entities = permissions.entities;
      var agencyAccess = entities && entities.agency.access;
      var agencyExists = false;
      var match = false;
      var result;
      if (user.scope === "GLOBAL") {
        return true;
      }
      if (agencyAccess) {
        orgId = String(orgId);
        if (!_.isArray(agencyAccess)) {
          agencyAccess = [agencyAccess];
        }
        result = _.some(agencyAccess, function (agency) {
          match = agency.organization_id && agency.organization_id === orgId;
          agencyExists = agencyExists || match;
          return match && agency.value === "w";
        });
        return agencyExists ? result : true;
      }
      return true;
    },
    fetchData: function (Model, id) {
      var deferred = $.Deferred();
      var instance;
      instance = new Model({
        id: id
      });
      instance.fetch({
        success: function (data) {
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    getBreadCrumbHeaderInfo: function (model, isUser) {
      var i;
      var modelJSON = model.toJSON ? model.toJSON() : model;
      var returnArry = [];
      var noData = "&nbsp;--";
      var timezone = TimezoneUtils.getClientTimezoneAbbr();
      var convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
      var dateFormat = "MM/dd/yyyy hh:mm TT ";
      var formatData = function (attr, value) {
        var attrOperations = {
          status: function () {
            return value === "0" ? "Inactive" : "Active";
          },
          is_eligible: function () {
            return value === "0" ? "Inactive" : "Active";
          },
          active: function () {
            return value === "0" ? "Inactive" : "Active";
          },
          defaultFormat: function () {
            return value || noData;
          },
          last_login_on: function () {
            return modelJSON.last_login_on ? convertToLocalTime(value).toString(dateFormat) + timezone : noData;
          }
        };
        var formatter = attrOperations[attr] || attrOperations.defaultFormat;
        return formatter(value);
      };
      var lastLoginObj = {
        attr: "last_login_on",
        label: "last login",
        className: "login"
      };
      var userStatusObj = {
        attr: "active",
        label: "status",
        className: "status"
      };
      var valueToBeDisplayed = [{
        attr: "id",
        label: `${model.entityName} ID`,
        className: "id"
      }, {
        attr: "status",
        className: "status"
      }];
      if (model.type === "vendor") {
        valueToBeDisplayed.pop();
        valueToBeDisplayed.push({
          label: "status",
          attr: "is_eligible",
          className: "status"
        });
      }
      i = valueToBeDisplayed.length;
      if (isUser) {
        valueToBeDisplayed[1] = userStatusObj;
        valueToBeDisplayed.unshift(lastLoginObj);
        i = valueToBeDisplayed.length;
      }
      while (i--) {
        returnArry.push({
          label: valueToBeDisplayed[i].label || valueToBeDisplayed[i].attr,
          value: formatData(valueToBeDisplayed[i].attr, modelJSON[valueToBeDisplayed[i].attr]),
          className: valueToBeDisplayed[i].className
        });
      }
      return returnArry;
    },
    pluralize: function (str, num, spc) {
      var nonStandard = {
        is: "are",
        entry: "entries",
        was: "were"
      };
      var lastLetter = str.slice(-1);
      var lettercase, plural;
      if (str === str.toLowerCase()) {
        lettercase = "lower";
      } else if (str === str.toUpperCase()) {
        lettercase = "upper";
      } else {
        if (lastLetter === lastLetter.toLowerCase()) {
          lettercase = "lower";
        } else if (lastLetter === lastLetter.toUpperCase()) {
          lettercase = "upper";
        }
      }
      if (parseInt(num, 10) !== 1) {
        if (nonStandard[str.toLowerCase()]) {
          str = nonStandard[str.toLowerCase()];
          if (lettercase === "upper") {
            str = str.toUpperCase();
          }
        } else {
          if (lastLetter.toLowerCase() === "y") {
            str = str.slice(0, -1);
            plural = "ies";
          } else {
            plural = "s";
          }
          if (lettercase === "upper") {
            plural = plural.toUpperCase();
          }
          str = str + plural;
        }
      }
      return (spc & this.spcOpts.LEFT ? " " : "") + str + (spc & this.spcOpts.RIGHT ? " " : "");
    },
    updateBreadCrumb: function (entityName, opts) {
      var data = opts || {};
      var pathPieces = [];
      var headerInfo;
      if (data.id) {
        $.each(this.adminEntities, function (name, entity) {
          var entityData = data[entity.dataName];
          if (!entityData && name === entityName) {
            entityData = {
              id: data.id,
              name: data.name
            };
          }
          if (entityData) {
            pathPieces.push({
              label: entityData.name,
              location: `#admin/${entity.urlPart}/${entityData.id}`
            });
          }
        });
      } else if (entityName) {
        pathPieces = [{
          label: (data.noNew ? "" : "New ") + entityName,
          location: ""
        }];
      }
      if (opts) {
        opts.entityName = entityName;
        headerInfo = this.getBreadCrumbHeaderInfo(opts);
      }
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces,
        headerInfo: headerInfo
      });
    },
    htmlEncode: function (value) {
      return $("<div />").text(value.replace(/\\/, "\\\\").replace(/&/g, encodeURIComponent("&"))).html();
    },
    htmlDecode: function (value) {
      return $("<div />").html(value.replace(/\\\\/, "\\").replace(/%26/g, "&")).text();
    }
  };
});
