define('modules/creatives/video/control/ParallelDeferred',["jQuery"], function ($) {
  var sliceDeferred = [].slice;
  var ParallelDeferred = function (firstParam) {
    var args = sliceDeferred.call(arguments, 0);
    var proms = sliceDeferred.call(arguments, 0);
    var i = 0;
    var length = args.length;
    var pValues = new Array(length);
    var count = length;
    var deferred = length <= 1 && firstParam && jQuery.isFunction(firstParam.promise) ? firstParam : jQuery.Deferred();
    var promise = deferred.promise();
    var result;
    function rejectFunc(i) {
      return function (value) {
        args[i] = arguments.length > 1 ? sliceDeferred.call(arguments, 0) : value;
        if (! --count) {
          checkStatus();
        }
      };
    }
    function resolveFunc(i) {
      return function (value) {
        args[i] = arguments.length > 1 ? sliceDeferred.call(arguments, 0) : value;
        if (! --count) {
          checkStatus();
        }
      };
    }
    function progressFunc(i) {
      return function (value) {
        pValues[i] = arguments.length > 1 ? sliceDeferred.call(arguments, 0) : value;
        deferred.notifyWith(promise, pValues);
      };
    }
    function checkStatus() {
      if (!count) {
        var res = true;
        proms.map(function (def) {
          res &= def.isResolved();
        });
        if (res) {
          deferred.resolveWith(deferred, args);
        } else {
          deferred.rejectWith(deferred, args);
        }
      }
    }
    if (length > 1) {
      for (i = 0; i < length; i++) {
        if (args[i] && args[i].promise && jQuery.isFunction(args[i].promise)) {
          args[i].promise().then(resolveFunc(i), rejectFunc(i), progressFunc(i));
        } else {
          --count;
        }
      }
      if (!count) {
        checkStatus();
      }
    } else if (deferred !== firstParam) {
      deferred.resolveWith(deferred, length ? [firstParam] : []);
    }
    return promise;
  };
  return ParallelDeferred;
});
