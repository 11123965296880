define('modules/admin/myOrgs/organization/views/settings',['require','modules/organization/utils/Utils','T1','T1View','text!../templates/settings.html','T1Form'],function (require) {
  "use strict";

  const OrgUtils = require("modules/organization/utils/Utils");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/settings.html");
  const T1Form = require("T1Form");
  const T1Location = T1.Location;
  const navigationDelay = 1e3;
  const Settings = T1View.extend({
    template: template,
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndCloseHandler"
      },
      formActions: [{
        class: "cancel",
        label: "cancel",
        location: "#admin"
      }, {
        class: "save btn-secondary",
        label: "save",
        location: false
      }, {
        class: "saveAndClose btn-primary",
        label: "save & close",
        location: false,
        uuid: "save_close"
      }]
    },
    initialize() {},
    load() {
      this.render().then(() => {
        this.getData();
      });
    },
    getData() {
      OrgUtils.fetchOrgSettings(this.model.id).then(() => {
        this.render();
      });
    },
    postData(formValues, success, statusInvalid) {
      function onSuccess(model) {
        T1.Notify("message", "Settings updated successfully!");
        OrgUtils.fetchOrgSettings(model.id);
        success(model);
      }
      function onError(errors) {
        statusInvalid(errors, null, ".form-body");
      }
      OrgUtils.saveOrgSettings(formValues, onSuccess, onError);
    },
    saveAndCloseHandler(evt) {
      this.setSaveAndContinueMode(true);
      this.save(evt, {
        success: this.navigateToLocation.bind(this)
      });
    },
    canUnload(onTabChange) {
      const self = this;
      const isFormElementsChanged = this.isFormElementsChanged;
      const onTabChangeHandler = {
        onAlertClose: () => {
          const abortedUrl = T1Location.getAbortedURL();
          if (typeof onTabChange !== "object" && !abortedUrl) {
            self.navigateToLocation("details");
          } else if (!abortedUrl) {
            onTabChange.onAlertClose();
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.model.get("name")
        }, onTabChangeHandler);
      }
      return !isFormElementsChanged;
    },
    navigateToLocation(isDetails) {
      const path = isDetails ? `#admin/organization/${this.model.id}/details` : "#admin/myOrgs";
      setTimeout(() => {
        T1Location.set(path);
      }, navigationDelay);
    },
    serialize() {
      return OrgUtils.getOrgSettings(this.model.id);
    }
  });
  return T1.Form.ViewUtils.extend(Settings);
});
