define('modules/targeting/video/views/readOnly',["jQuery", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils"], function ($, T1View, template, Utils) {
  "use strict";

  var readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": "prepareData"
    },
    initialize: function ({
      model: model,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit
    }) {
      this.targetDimensions = model;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      const isDimensionDataReady = this.targetDimensions.get(Utils.names.targetDimensions).loaded;
      if (isDimensionDataReady && isDimensionDataReady === true) {
        this.prepareData();
      } else {
        this.bindViewEvents();
      }
    },
    load: function () {
      this.render().then(() => {
        const loader = this.loader();
        if (!this.prepareDataIsCalled || this.prepareDataIsCalled === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
      });
    },
    prepareData: function () {
      const dimCodes = ["VPSZ", "VLIN", "VCON", "VAUD", "VSKP", "VPLC"];
      var prepareData = Utils.prepareTargetDimensionData;
      var data = this.targetDimensions;
      this.prepareDataIsCalled = true;
      this.digitalCollection = prepareData(data, function (dimCode) {
        return dimCodes.includes(dimCode);
      });
      if (this.digitalCollection) {
        this.digitalCollection.models.forEach(function (model) {
          if (model.get("dimension_code") === "VSKP") {
            model.set({
              name: model.get("name").split(" ")[0]
            });
          }
        });
      }
      this.partialCollection = data.get("partial");
      this.load();
    },
    serialize: function () {
      const deepCopy = obj => JSON.parse(JSON.stringify(obj));
      const codeHashInit = {
        VPSZ: [],
        VLIN: [],
        VCON: [],
        VAUD: [],
        VSKP: [],
        VPLC: []
      };
      const collObj = this.digitalCollection;
      var partialCollObj = this.partialCollection;
      var codeNameRef = {
        VPSZ: "Player Size",
        VLIN: "Linear Format",
        VCON: "Content Initiation",
        VAUD: "Audio",
        VSKP: "Skippability",
        VPLC: "Video Placement"
      };
      var resultJSON = {
        dataMain: []
      };
      var formatName = function (model) {
        let parent;
        var parentId = model.get("parent_value_id");
        if (parentId) {
          partialCollObj.each(function (parentModel) {
            if (parentModel.get("id") === parentId) {
              parent = parentModel;
            }
          });
          if (!parent) {
            collObj.each(function (parentModel) {
              if (parentModel.get("id") === parentId) {
                parent = parentModel;
              }
            });
          }
          if (parent) {
            return `${parent.get("name")} &raquo; ${model.get("name")}`;
          }
        }
        return model.get("name");
      };
      const codeNameHashInc = deepCopy(codeHashInit);
      const codeNameHashExc = deepCopy(codeHashInit);
      var spaceEntity = "&nbsp;";
      var createDataBlock = Utils.createGroupedDataArray;
      var hasIncludeData = false;
      var hasExcludeData = false;
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/video`;
      }
      if (!collObj || !collObj.models || !collObj.models.length) {
        this.hasData = false;
        return resultJSON;
      }
      $.each(collObj.models, function (index, model) {
        const action = model.get("action");
        const dimCode = model.get("dimension_code");
        const tmpArr = action === "include" ? codeNameHashInc[dimCode] : codeNameHashExc[dimCode];
        if (tmpArr) {
          tmpArr.push(formatName(model));
        }
      });
      let tempData = createDataBlock(codeNameHashInc, codeNameRef);
      if (tempData.length) {
        hasIncludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "included technologies",
          data: tempData
        });
      }
      tempData = createDataBlock(codeNameHashExc, codeNameRef);
      if (tempData.length) {
        hasExcludeData = true;
        resultJSON.dataMain.push({
          sideTitle: "excluded technologies",
          data: tempData
        });
      }
      this.hasData = resultJSON.hasData = hasIncludeData || hasExcludeData;
      if (resultJSON.hasData) {
        resultJSON.firstSideTitle = resultJSON.dataMain[0].sideTitle;
        resultJSON.dataMain[0].sideTitle = spaceEntity;
      }
      return resultJSON;
    }
  });
  return readOnlyView;
});
