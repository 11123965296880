define('modules/audiences/segments/behavior/views/createEdit',["jQuery", "Backbone", "Mustache", "T1", "text!modules/audiences/segments/behavior/templates/behavior.html"], function ($, Backbone, T, T1, template) {
  "use strict";

  var createEditView = Backbone.View.extend({
    template: template,
    initialize: function () {
      this.render();
    },
    render: function () {
      var $el = $(this.el);
      $el.html(T.to_html(this.template, {}));
    }
  });
  return createEditView;
});
