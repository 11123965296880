define('modules/admin/user/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "admin/user",
      viewCfgs: {
        createEdit: {},
        users: {},
        grid: {},
        permissionsGrid: {}
      },
      defaultView: "users"
    });
  }
  return instance;
});
