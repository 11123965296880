define('models/providerIds',['require','jQuery','T1','T1Model','T1Comm'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Model = require("T1Model");
  const T1Comm = require("T1Comm");
  T1.Models.ProviderIds = T1Model.extend({
    name: "providerIds",
    dataType: "json",
    contentType: "application/json",
    urlString: T1.ENV.MEDIAOCEAN_API_BASE,
    url: function () {
      return `${this.urlString}agency/${this.id}`;
    },
    canEdit: function () {
      const deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    fetch: function (options) {
      const url = this.url();
      T1Comm.get({
        dataType: this.dataType,
        onSuccess: options.success,
        processAjaxResponse: this.processAjaxResponse,
        sourceURL: url
      });
    },
    processAjaxResponse: function (resp) {
      const responseData = typeof resp === "string" ? JSON.parse(resp) : resp;
      if (responseData.Status) {
        return {
          statusCode: responseData.Status,
          jsonData: responseData.Description
        };
      }
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    createEdit(formValues, providerIdField, options) {
      const statusInvalid = options.statusInvalid || $.noop();
      const conflictCallback = options.conflict || $.noop();
      const errorCallback = options.error || $.noop();
      const forbiddenErr = 403;
      this.deleteVersion = true;
      this.save({
        accounts: [{
          account_id: formValues.providerIds
        }]
      }, {
        success: options.success || $.noop(),
        forcedContentType: this.contentType,
        dataType: this.dataType,
        statusInvalid: statusInvalid,
        stringify: true,
        conflict: conflictCallback,
        processAjaxResponse: this.processAjaxResponse,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true,
          errorCodes: [forbiddenErr]
        },
        error: function (model, response) {
          const errObj = [];
          if (response.responseText) {
            const resp = typeof response.responseText === "string" ? JSON.parse(response.responseText) : response.responseText;
            if (resp.Status === forbiddenErr) {
              if (resp.Description === "Forbidden") {
                errObj[0] = {
                  field: providerIdField,
                  message: "Provider ID was not valid on Prisma. Enter Provider ID again."
                };
              } else {
                errObj[0] = {
                  field: providerIdField,
                  message: "The ID entered was previously registered. No action needed."
                };
              }
            }
            errorCallback(errObj);
          }
        }
      });
    }
  });
  return T1.Models.ProviderIds;
});
