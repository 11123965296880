define('models/segmentExport',["jQuery", "T1", "T1Model", "T1Comm"], function ($, T1, T1Model) {
  "use strict";

  T1.Models.SegmentExport = T1Model.extend({
    name: "siteLists",
    apiRoot: T1.ENV.SEGMENT_API_BASE,
    urlRoot: "exports/segments",
    selectedSegments: [],
    url: function () {
      var query = this.id && this.id !== "new" ? "/" + this.id : "";
      return this.apiRoot + this.urlRoot + query;
    },
    refresh: function (segEx, opts) {
      var deferred = $.Deferred();
      var options = {
        dataType: "json",
        processAjaxResponse: this.processAjaxResponse,
        success: function (model, response) {
          var returnData;
          if (typeof response === "string") {
            response = $.parseJSON(response);
          }
          returnData = "data" in response ? response.data : response;
          deferred.resolve(returnData);
        },
        onError: function (err) {
          deferred.reject(err);
        }
      };
      var data = {
        segment_ids: segEx.segment_id,
        destination_endpoint_id: segEx.destination_endpoints_id,
        export_source_type: segEx.export_source_type === "DMC Segments" ? "DMC" : "adaptive"
      };
      delete this.id;
      T1Model.prototype.save.call(this, data, options);
      return deferred.promise();
    },
    extend: function (expiryDate, daysToExtend) {
      var deferred = $.Deferred();
      var options = {
        dataType: "json",
        processAjaxResponse: this.processAjaxResponse,
        success: function (model, response) {
          var returnData;
          if (typeof response === "string") {
            response = $.parseJSON(response);
          }
          returnData = "data" in response ? response.data : response;
          deferred.resolve(returnData);
        },
        onError: function (err) {
          deferred.reject(err);
        }
      };
      var data = {};
      data.expire_on = moment(expiryDate).add(daysToExtend, "days").format("YYYY-MM-DD");
      T1Model.prototype.save.call(this, data, options);
      return deferred.promise();
    },
    cancel: function () {
      var deferred = $.Deferred();
      var options = {
        httpAction: "delete",
        dataType: "json",
        processAjaxResponse: this.processAjaxResponse,
        success: function (model, response) {
          if (typeof response === "string") {
            response = $.parseJSON(response);
          }
          if ("meta" in response) {
            deferred.resolve(response);
          } else {
            deferred.reject();
          }
        },
        onError: function (err) {
          deferred.reject(err);
        }
      };
      T1Model.prototype.save.call(this, {}, options);
      return deferred.promise();
    },
    save: function (opts) {
      var self = this;
      var segments = {
        dmc: [],
        adaptive: []
      };
      var cbOpts = Object.assign({}, opts);
      var multiSaves = [],
        resp = [],
        errorArray = [];
      var prepareSegmentTypeSave = arr => {
        var saveCall = $.Deferred();
        var successFunct = (model, response) => {
          resp.push(response);
          saveCall.resolve();
        };
        var errorFunct = function (err) {
          errorArray.push(err);
          saveCall.resolve(err);
        };
        var data = {};
        if (arr.length) {
          data.segment_ids = arr.map(segment => {
            data.export_source_type = segment.segment_type;
            return parseInt(segment.id, 10);
          }).toString();
          data.destination_endpoint_id = this.get("destination");
          opts.dataType = "json";
          opts.processAjaxResponse = self.processAjaxResponse;
          opts.success = successFunct;
          opts.onError = errorFunct;
          if (data.export_source_type === "dmc") {
            data.export_source_type = data.export_source_type.toUpperCase();
          }
          T1Model.prototype.save.call(this, data, opts);
          multiSaves.push(saveCall.promise());
        }
      };
      this.selectedSegments.map(segment => {
        segments[segment.segment_type].push(segment);
      });
      prepareSegmentTypeSave(segments.adaptive);
      prepareSegmentTypeSave(segments.dmc);
      $.when.apply($, multiSaves).then(() => {
        if (errorArray.length) {
          cbOpts.error(errorArray, multiSaves.length);
        } else {
          cbOpts.success(this, resp);
        }
      });
    },
    fetchExports: function (options) {
      var self = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (data) {
        options.success(data);
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      var syncOpts = {
        dataType: "json",
        success: onSuccess,
        processAjaxResponse: self.processAjaxResponse
      };
      self.ajx = self.sync("read", "", syncOpts);
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.SegmentExport;
});
