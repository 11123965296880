define('T1Comm',['require','jQuery','T1','T1Cache','jQPlugins/jquery.form','T1Notifier'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Cache = require("T1Cache");
  const jQueryForm = require("jQPlugins/jquery.form");
  const T1Notifier = require("T1Notifier");
  var userLoggedIn = false;
  var utils = T1.Utils;
  var eventHub = T1.EventHub;
  var t1notify = T1.Notify;
  function buildRequestParameters(args, type) {
    var request = {
      onSuccess: function () {},
      onStatusInvalid: function () {},
      error: function () {},
      data: {},
      showNotification: false
    };
    if (args === undefined || args.sourceURL === undefined || args.sourceURL === "" || args.onSuccess === undefined || args.onSuccess === "") {
      return false;
    }
    $.extend(request, args);
    request.type = type;
    return request;
  }
  function defaultProcessAjaxResponse(responseData) {
    var statusCode = $(responseData).find("status").attr("code");
    var responseXML = typeof responseData === "string" ? $.parseXML(responseData) : responseData;
    var jsonData = utils.parseXMLtoJson(responseXML);
    return {
      statusCode: statusCode,
      jsonData: jsonData
    };
  }
  function processAjaxResponseHeaders(ajxRespHeaders) {
    if (ajxRespHeaders) {
      const headerObj = {};
      const spaceExclusion = 2;
      const headers = ajxRespHeaders.split("\n");
      while (headers.length) {
        const header = headers.shift();
        const splitIndex = header.indexOf(":");
        const key = header.substr(0, splitIndex);
        const value = header.substr(splitIndex + spaceExclusion, header.length);
        if (key) {
          headerObj[key] = value;
        }
      }
      return headerObj;
    }
  }
  function attachResponseHeaders(request, jqXHR) {
    if (request.getResponseHeaders && request.getResponseHeaders instanceof Object && jqXHR) {
      request.getResponseHeaders.data = processAjaxResponseHeaders(jqXHR.getAllResponseHeaders());
    }
  }
  function requestServer(request, isBypass) {
    const defaultTimeout = 15e3;
    var onStatusInvalid = function (jsonData, jqXHR) {
      var requestStatusInvalid = request.onStatusInvalid || function () {};
      var status = jsonData.status || {};
      if (!isBypass) {
        if (status.code === "auth_required" || status.code === "auth_error") {
          setUserLogin(false, request);
          T1.EventHub.publish("AuthRequired", jsonData);
        }
      }
      attachResponseHeaders(request, jqXHR);
      requestStatusInvalid(jsonData);
      if (request.showNotification && jsonData.errors) {
        if (jsonData.errors.field_error) {
          t1notify("warning", jsonData.errors.field_error.error);
        } else if (typeof jsonData.errors === "string") {
          t1notify("warning", jsonData.errors);
        } else {
          t1notify("warning", "Login error");
        }
      }
    };
    var publishError = function (code, detail) {
      eventHub.publish("comm:error", {
        code: code,
        detail: detail,
        url: ajaxConfigs.url
      });
    };
    var onSuccess = function (jsonData, jqXHR) {
      var requestOnSuccess = request.onSuccess || function () {};
      if (jsonData.session) {
        setUserLogin(true);
      }
      attachResponseHeaders(request, jqXHR);
      requestOnSuccess(jsonData);
      if (request.showNotification && request.successNotifications) {
        t1notify("message", request.successNotifications);
      }
    };
    var onError = function (jqXHR) {
      const conflictError = 409;
      const badRequestError = 400;
      const unauthorizedError = 401;
      const displayDelay = 2e3;
      var requestOnError = request.onError || function () {};
      var suppressedErrors = [conflictError, "abort"];
      var errorsHandledWithDelay = ["error"];
      var errorDescription = `Request URL: ${request.sourceURL}`;
      var codes = {
        timeout: "Timeout",
        parsererror: "Parser Error",
        error: "Server Error",
        unknown: "Unknown Error"
      };
      var errSuppressingConfig = request.errorDisplaySuppressingConfig;
      if (jqXHR.status === badRequestError) {
        suppressedErrors.push(badRequestError);
        optionSuccess(jqXHR.responseText, null, jqXHR);
      } else if (jqXHR.status === unauthorizedError) {
        suppressedErrors.push(unauthorizedError);
        optionSuccess(jqXHR.responseText, null, jqXHR);
      }
      if (isBypass && jqXHR.status === "500") {
        $(".page-box").empty();
        $("style,link").remove();
        T1.Utils.appendStylesheet("error-pages", function () {
          require(["Mustache", "text!templates/500_error.html"], function (T, template) {
            $(".page-box").html(T.to_html(template, {
              responseText: `${jqXHR.responseText} ${errorDescription}`
            }));
          });
        }, this, "errorStyles");
        return;
      }
      attachResponseHeaders(request, jqXHR);
      if (errSuppressingConfig) {
        if (errSuppressingConfig.errorCodes && errSuppressingConfig.errorCodes.includes(jqXHR.status)) {
          errSuppressingConfig.errorSuppressed = true;
          requestOnError(jqXHR);
          return;
        }
      }
      requestOnError(jqXHR.responseText);
      function showError() {
        const perCent = 100;
        const four = 4;
        const five = 5;
        const status = Math.floor(jqXHR.status / perCent);
        if (status === four || status === five) {
          publishError(jqXHR.status, jqXHR.responseText);
        } else {
          const errorName = codes[jqXHR.statusText] || codes.unknown;
          publishError(errorName, errorDescription);
        }
      }
      if (errorsHandledWithDelay.includes(jqXHR.statusText)) {
        setTimeout(showError, displayDelay);
      } else if (!suppressedErrors.includes(jqXHR.status) && !suppressedErrors.includes(jqXHR.statusText)) {
        showError();
      }
    };
    function optionSuccess(responseData, isCachedData, jqXHR) {
      let errorName;
      if (typeof responseData === "object") {
        const codes = {
          parsingerror: "Parsing Error",
          unknown: "Unknown Error"
        };
        if ($(responseData).find("parsererror").length) {
          errorName = codes.parsingerror || codes.unknown;
          publishError(errorName, responseData.documentElement.childNodes[0].nodeValue);
        } else if (responseData === null) {
          errorName = codes.unknown;
          publishError(errorName, errorName);
        } else if ($(responseData).find("status").attr("code") === "unavailable") {
          window.location = "maintenance.html";
          window.reload();
        }
      }
      if (request.canCacheBackToTrue) {
        request.canCache = true;
        request.canCacheBackToTrue = false;
      }
      if (isCachedData !== true && request.cacheKey && request.canCache) {
        T1Cache.setValue(request.cacheKey, responseData, request.cacheDuration);
      }
      function customProcessAjaxResponse(cpResp, req) {
        let rData;
        try {
          rData = request.processAjaxResponse(cpResp, req);
        } catch (e) {
          const validReq = req.dataType === "xml";
          if (validReq) {
            rData = defaultProcessAjaxResponse(cpResp, req);
            return rData;
          }
          detectSession();
          return {
            statusCode: "error",
            jsonData: {
              status: "error"
            }
          };
        }
        return rData;
      }
      const processResponse = request.processAjaxResponse ? customProcessAjaxResponse : defaultProcessAjaxResponse;
      const resp = processResponse(responseData, request);
      if (resp.statusCode === "ok") {
        onSuccess(resp.jsonData, jqXHR);
      } else {
        onStatusInvalid(resp.jsonData, jqXHR);
      }
    }
    const ajaxConfigs = {
      type: request.type,
      url: request.sourceURL,
      dataType: request.dataType || "xml",
      fixture: request.fixture || "",
      async: request.async || true,
      data: request.data,
      timeout: request.timeout || COMPASS_ENV.TIMEOUT || defaultTimeout,
      success: optionSuccess,
      error: onError,
      headers: {}
    };
    if (request.stringify) {
      ajaxConfigs.data = JSON.stringify(ajaxConfigs.data);
      ajaxConfigs.processData = false;
      ajaxConfigs.contentType = "application/json";
      ajaxConfigs.dataType = "json";
    }
    if (request.postJson) {
      ajaxConfigs.body = JSON.stringify(ajaxConfigs.data);
      ajaxConfigs.processData = false;
      ajaxConfigs.contentType = "application/json";
      ajaxConfigs.dataType = "json";
      if (!request.sendData) {
        delete ajaxConfigs.data;
      }
    }
    if (ajaxConfigs.url.includes("/api/v2.0") && !request.noHeader) {
      ajaxConfigs.headers = {
        "X-Request-ID": T1.Utils.getGUID(),
        "X-Client-ID": T1.Utils.getClientId()
      };
    }
    if (localStorage.getItem("mm.bogo.acc")) {
      ajaxConfigs.headers.Authorization = `Bearer ${localStorage.getItem("mm.bogo.acc")}`;
    }
    if (request.canCache || request.canCacheBackToTrue) {
      request.cacheKey = T1Cache.buildKeyFromQuery(request.sourceURL, request.apiRoot ? request.apiRoot : T1.API_ROOT, request.cacheExcludes);
    }
    if (request.forcedContentType !== undefined) {
      ajaxConfigs.contentType = request.forcedContentType;
    }
    if (request.forcedProcessData !== undefined) {
      ajaxConfigs.processData = request.forcedProcessData;
    }
    if (request.forcedDataType !== undefined) {
      ajaxConfigs.dataType = request.forcedDataType;
    }
    if (request.acceptHeader) {
      $.extend(ajaxConfigs.headers, {
        Accept: "text/csv"
      });
    }
    if (request.forcedHeaders !== undefined) {
      ajaxConfigs.headers = $.extend(ajaxConfigs.headers, request.forcedHeaders);
    }
    if (request.canCache && T1Cache.isCached(request.cacheKey)) {
      optionSuccess(T1Cache.retrieve(request.cacheKey), true);
    } else if (request.isForm && request.formEl) {
      ajaxConfigs.uploadProgress = request.uploadProgress;
      $(request.formEl).ajaxSubmit(ajaxConfigs);
    } else {
      return $.ajax(ajaxConfigs);
    }
  }
  function setUserLogin(state, request) {
    userLoggedIn = state;
    if (!state && request) {
      eventHub.subscribe("onSessionReset", function () {
        requestServer(request);
      });
    }
  }
  function getRequest(args, type) {
    const request = buildRequestParameters(args, type);
    return request ? requestServer(request) : false;
  }
  function get(args) {
    return getRequest(args, "GET");
  }
  function post(args) {
    return getRequest(args, "POST");
  }
  function put(args) {
    return getRequest(args, "PUT");
  }
  function deleteCall(args) {
    return getRequest(args, "DELETE");
  }
  function keepAlive() {
    $.ajax({
      url: `${T1.API_ROOT}session`,
      dataType: "xml",
      success: function (responseData) {
        var statusCode = $(responseData).find("status").attr("code");
        eventHub.publish(`onSession${utils.toInitialCaps(statusCode)}`, responseData);
      }
    });
  }
  function requestLogin(args) {
    var success = args.onSuccess || function () {};
    var preAuth = args.preAuth || function (resp, opts) {
      opts.success();
    };
    var onSuccess = function (loginResponse) {
      if (loginResponse.status && loginResponse.status.code === "ok") {
        localStorage.bogoResp = loginResponse.session.sessionid;
        setUserLogin(true);
        preAuth(loginResponse, {
          success: function () {
            eventHub.publish("onSessionReset");
            eventHub.unsubscribe("onSessionReset");
            eventHub.publish(`onUserLogin${T1.Utils.toInitialCaps(loginResponse.status.code)}`, loginResponse.entity);
            success(loginResponse.entity);
          }
        });
      }
    };
    args.onSuccess = onSuccess;
    args.onError = args.onStatusInvalid;
    args.sourceURL = `${T1.API_ROOT}login?now_time=${T1.getRandomNumber()}`;
    if (args.data) {
      args.data.api_key = T1.API_KEY;
    }
    const request = buildRequestParameters(args, "POST");
    let result = false;
    if (request) {
      requestServer(request, true);
      result = true;
    }
    return result;
  }
  function requestLogout(args) {
    var logoutSuccess = "Logged Out";
    var success = args.onSuccess || function () {};
    var onSuccess = function (logoutResponse) {
      if (logoutResponse.status && logoutResponse.status.code === "ok" && logoutResponse.status.text === logoutSuccess) {
        setUserLogin(false);
        eventHub.publish(`onUserLogout${utils.toInitialCaps(logoutResponse.status.code)}`, logoutResponse);
        success(logoutResponse);
      }
    };
    args.onSuccess = onSuccess;
    args.sourceURL = `${T1.API_ROOT}logout?now_time=${T1.getRandomNumber()}`;
    const request = buildRequestParameters(args, "GET");
    let result = false;
    if (request) {
      requestServer(request);
      result = true;
    }
    return result;
  }
  function detectSession(args = {}) {
    function onSuccess(sessionResponse) {
      var statusCode = sessionResponse.status.code;
      var userObj = sessionResponse.entity;
      var success = args.onSuccess || function () {};
      localStorage.bogoResp = T1.SESSION_ID = sessionResponse.session.sessionid;
      setUserLogin(true);
      success(userObj);
      eventHub.publish(`onSession${utils.toInitialCaps(statusCode)}`, userObj);
    }
    function onStatusInvalid(sessionResponse) {
      var statusCode = sessionResponse.status.code;
      var error = args.onError || function () {};
      if (statusCode === "auth_required" || statusCode === "auth_error") {
        setUserLogin(false);
        eventHub.publish("AuthRequired", sessionResponse);
        error(sessionResponse);
      }
    }
    const sessionParams = {
      sourceURL: `${T1.API_ROOT}session?now_time=${T1.getRandomNumber()}`,
      fixture: "-session",
      onSuccess: onSuccess,
      onStatusInvalid: onStatusInvalid
    };
    const request = buildRequestParameters(sessionParams, "GET");
    let result = false;
    if (request) {
      requestServer(request, true);
      result = true;
    }
    return result;
  }
  function isUserLoggedIn() {
    return userLoggedIn;
  }
  return {
    deleteCall: deleteCall,
    detectSession: detectSession,
    get: get,
    isUserLoggedIn: isUserLoggedIn,
    keepAlive: keepAlive,
    post: post,
    put: put,
    requestLogin: requestLogin,
    requestLogout: requestLogout
  };
});
