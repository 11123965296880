define('modules/campaign/views/strategiesPanel',["jQuery", "T1", "T1View", "T1Layout", "text!modules/campaign/templates/strategiesLayout.html", "models/userPreferences", "T1Preferences", "T1Signature", "T1Accordion", "T1Selectbox"], function ($, T1, T1View, T1Layout, LayoutTemplate, UserPreferences) {
  "use strict";

  return T1View.extend({
    template: '<div class="w-StrategiesPanel"></div>',
    loaderOpts: {
      target: ".w-StrategiesPanel",
      text: "Fetching Strategies",
      contentClass: "loading-indicator loader-overrides",
      action: "append"
    },
    listViewTypes: {
      options: [{
        value: "spendMode",
        text: "Spend"
      }, {
        value: "performanceMode",
        text: "Performance"
      }]
    },
    strategy_goal: {
      options: [{
        value: "strategyGoal",
        text: "Strategy Goal"
      }, {
        value: "campaignGoal",
        text: "Campaign Goal"
      }]
    },
    report_interval: {
      options: [{
        value: "1",
        text: "Previous Day"
      }, {
        value: "7",
        text: "Previous 7 Days"
      }, {
        value: "14",
        text: "Previous 14 Days"
      }, {
        value: "30",
        text: "Previous 30 Days"
      }, {
        value: "FTD",
        text: "Flight to Date"
      }, {
        value: "CTD",
        text: "Campaign to Date",
        state: 'selected="selected"'
      }]
    },
    dataEvents: {
      campaign: {
        "reset:strategies": function () {
          if (this.spinner) {
            this.spinner.stop();
          }
          this.initLayout();
        }
      }
    },
    eventhubEvents: {
      "strategies:changePageLimit": "changePageLimit"
    },
    events: {
      "click .sort .list-order-icon": "switchSortOrder",
      "change .active-only input": "showActiveOnly"
    },
    itemViewMode: "spendMode",
    selectedStrategyGoal: "strategyGoal",
    entitySortValue: "updated_on",
    entitySortOrder: "descending",
    entityInterval: "CTD",
    strategiesPageLimit: 10,
    showActiveChecked: "true",
    initialize: function ({
      campaign: campaign
    }) {
      const impPacingViewTypes = {
        options: [{
          value: "spendMode",
          text: "Spend"
        }, {
          value: "impressionMode",
          text: "Impressions"
        }, {
          value: "performanceMode",
          text: "Performance"
        }]
      };
      this.listViewTypes = impPacingViewTypes;
      this.campaign = campaign;
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "campaign"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["itemViewMode", "selectedStrategyGoal", "strategiesPageLimit", "entitySortValue", "entitySortOrder", "entityInterval", "showActiveChecked"]);
      this.itemViewMode = this.preferences.get("itemViewMode") || "spendMode";
      this.selectedStrategyGoal = this.preferences.get("selectedStrategyGoal") || "strategyGoal";
      this.entitySortValue = this.preferences.get("entitySortValue") || "updated_on";
      this.entitySortOrder = this.preferences.get("entitySortOrder") || "descending";
      this.entityInterval = this.preferences.get("entityInterval") || "CTD";
      this.showActiveChecked = this.preferences.get("showActiveChecked") || "true";
      if (this.entitySortValue === "total_budget") {
        this.entitySortValue = "budget";
      }
    },
    initLayout: function () {
      var self = this;
      const {
        entitySortOrder: sortOrder,
        entitySortValue: entitySortValue
      } = this;
      this.layout = new T1Layout({
        template: LayoutTemplate,
        el: () => this.$(".w-StrategiesPanel"),
        layout: {
          ".strategies-box": [{
            options: {
              collection: this.strategies,
              campaign_id: this.campaign.id,
              showAll: false,
              pageLimit: this.strategiesPageLimit,
              viewMode: this.itemViewMode,
              isIgnoreSmartScroll: true,
              currentGoalMode: this.selectedStrategyGoal,
              reportInterval: this.entityInterval,
              showListOptions: true
            },
            module: "strategy",
            viewType: "list"
          }]
        },
        serialize: function () {
          const {
            itemViewMode: viewMode,
            entityInterval: interval
          } = self;
          var sortConfig = self.strategies.getSortConfiguration();
          var sortByFields = [];
          $.each(sortConfig.fields, function (key, sort) {
            if (sort.value !== "status" && sort.state !== "selected") {
              sortByFields.push({
                text: sort.name,
                value: sort.value
              });
            }
          });
          const data = {
            order_by: self.entitySortOrder || sortConfig.order_by,
            sortByFields: sortByFields,
            selectedSortBy: self.entitySortValue || sortConfig.selectedValue,
            listViewTypes: self.listViewTypes.options,
            selectedListView: viewMode,
            strategyGoalFields: self.strategy_goal.options,
            selectedStrategyGoal: self.selectedStrategyGoal,
            strategyGoalEnabled: viewMode === "spendMode",
            reportIntervalFields: self.report_interval.options,
            selectedReportInterval: interval,
            viewMode: viewMode + interval,
            showActiveChecked: self.showActiveChecked === "true",
            getSelectOptions: function () {
              return function (attr) {
                var selected = data[attr] && this.value === data[attr] ? 'selected="selected"' : "";
                return `<option ${selected} value='${this.value}'>${this.text}</option>`;
              };
            }
          };
          return data;
        }
      });
      this.layout.load();
      this.setActiveOnly();
      this.sortStrategies(sortOrder, true, entitySortValue);
      T1.Selectbox(this.$(".list-view-field-list"), {
        onChange: this.changeViewMode.bind(this)
      });
      T1.Selectbox(this.$(".strategy-goal-field-list"), {
        onChange: this.changeCompareAgainst.bind(this)
      });
      T1.Selectbox(this.$(".report-interval-field-list"), {
        onChange: this.changeInterval.bind(this)
      });
      T1.Selectbox(this.$(".field-list"), {
        onChange: function () {
          this.sortStrategies();
        }.bind(this)
      });
      T1.Accordion(".strategies-box");
    },
    showActiveOnly: function (event) {
      this.showActiveChecked = `${$(event.currentTarget).is(":checked")}`;
      this.preferences.save();
      this.setActiveOnly();
      this.performFetch();
    },
    switchSortOrder: function (event) {
      var $target = $(event.currentTarget);
      var order = $target.hasClass("ascending") ? "descending" : "ascending";
      $target.on("click", $.noop).removeClass("ascending descending").addClass(order);
      this.entitySortOrder = order;
      this.sortStrategies(order, true);
    },
    sortStrategies: function (order, updateOrderBy, entitySortValue) {
      var selectedValue;
      const {
        strategies: collection,
        prevSortBy = "updated_on"
      } = this;
      collection.removeSort(prevSortBy);
      if (entitySortValue) {
        selectedValue = entitySortValue;
      } else {
        selectedValue = this.el.find(".field-list").val();
        this.prevSortBy = selectedValue;
        this.entitySortValue = selectedValue;
        this.preferences.save();
      }
      collection.sortBy(selectedValue, order, updateOrderBy);
      this.performFetch();
    },
    setActiveOnly: function () {
      if (this.showActiveChecked === "true") {
        this.strategies.search.set({
          params: [{
            term: "1",
            searchField: "status"
          }]
        });
      } else {
        this.strategies.search.set({});
      }
    },
    stopSpinner: function () {
      this.currentSpinner.stop();
      this.currentSpinner = null;
    },
    performFetch: function () {
      if (this.currentSpinner) {
        this.stopSpinner();
      }
      this.currentSpinner = this.loader();
      this.strategies.fetch({
        success: this.stopSpinner.bind(this)
      });
      this.currentSpinner.start();
    },
    changePageLimit: function ({
      pageLimit: pageLimit
    }) {
      this.strategiesPageLimit = pageLimit;
      this.strategies.updatePageLimit(pageLimit);
      this.preferences.save();
      this.performFetch();
    },
    changeCompareAgainst: function (event) {
      var value = $(event.currentTarget).val();
      this.selectedStrategyGoal = value;
      this.preferences.save();
      this.filterUpdate();
      T1.EventHub.publish("change:strategyGoalPerformances", value);
    },
    changeViewMode: function (event) {
      var value = event.currentTarget.value;
      var interval = this.entityInterval;
      this.el.find(".strategies-box").removeClass(`${this.itemViewMode + interval} change-on-interval-trigger`).addClass(value + interval);
      this.itemViewMode = value;
      if (value === "performanceMode") {
        this.el.find(".strategy-goal-field-list").attr("disabled", "disabled").trigger("liszt:updated");
      } else {
        this.el.find(".strategy-goal-field-list").removeAttr("disabled").trigger("liszt:updated");
      }
      this.preferences.save();
      this.filterUpdate();
    },
    changeInterval: function (event) {
      var value = $(event.currentTarget).val();
      var mode = this.itemViewMode;
      var viewMode = mode + this.entityInterval;
      const $strategiesBox = this.$(".strategies-box");
      $strategiesBox.removeClass(viewMode);
      this.entityInterval = value;
      viewMode = mode + value;
      $strategiesBox.addClass(viewMode);
      $strategiesBox.removeClass("change-on-interval-trigger").addClass("change-on-interval-trigger");
      this.preferences.save();
      this.filterUpdate();
    },
    filterUpdate: function () {
      T1.EventHub.publish("change:strategyFilter", {
        interval: this.entityInterval,
        viewMode: this.itemViewMode,
        goal: this.selectedStrategyGoal
      });
    },
    getStrategies: function () {
      var campaign = this.campaign;
      var strategies = campaign.getRelated("strategies");
      strategies.updatePageLimit(this.strategiesPageLimit);
      campaign.prepareStrategiesConfig(strategies);
      campaign.strategiesFetched = true;
      this.strategies = strategies;
    },
    render: function () {
      T1View.prototype.render.call(this);
      this.getStrategies();
      this.initLayout();
    },
    unload: function () {
      if (this.prevSortBy) {
        this.strategies.removeSort(this.prevSortBy || "updated_on");
      }
    }
  });
});
