define('modules/creatives/display/views/t1AsReviewGridInvalid',["jQuery", "Underscore", "T1", "T1View", "text!../templates/t1as_grid_item_invalid.html"], function ($, _, T1, T1View, template) {
  return T1View.extend({
    template: template,
    initialize: function (args) {
      this.model = args.model;
      this.unusables = this.model.get("unusables");
    },
    load: function () {
      var self = this;
      var $el = self.el;
      var $invalidGrid, serializedData;
      this.render().then(function () {
        serializedData = self.serialize();
        $invalidGrid = $el.find(".t1as-invalid-grid")[0];
        if ($invalidGrid) {
          $invalidGrid.data = serializedData.invalidTags;
          $el.show();
        }
      });
    },
    render: function () {
      var self = this;
      var $el = self.el;
      var $invalidGrid;
      var deferred = $.Deferred();
      T1View.prototype.render.call(this).then(function () {
        $invalidGrid = $el.find(".t1as-invalid");
        Polymer.dom($invalidGrid[0]).innerHTML = template;
        $invalidGrid.css("visibility", "visible");
        $invalidGrid.addClass("fade-in");
        self.buildPermissionedEvents();
        self.bindViewEvents();
      });
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    serialize: function () {
      var self = this;
      var hasData = !_.isEmpty(this.model.attributes);
      var output = {};
      function mapData(data) {
        return {
          id: data.index,
          name: data.name,
          size: self.model.formatFileSize(data.file_size),
          type: data.file_type.toUpperCase(),
          reason: data.note,
          selected: false
        };
      }
      if (hasData) {
        var invalidTagsList = this.unusables;
        output.invalidTags = invalidTagsList && invalidTagsList.map(mapData);
      }
      return output;
    }
  });
});
