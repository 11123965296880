define('models/videoCreative',["T1", "T1Model", "Backbone", "models/advertiser", "models/concept", "models/videoCreativeDetail"], function (T1, T1Model, Backbone, Advertiser, Concept, VideoCreativeDetail) {
  T1.Models.VideoCreative = T1Model.extend({
    url: "creatives",
    apiRoot: T1.VIDEO_API_BASE,
    initialize: function (args) {
      if (!args.hasOwnProperty("id") && !args.noDefault) {
        this.set({
          advertiser: new Advertiser(),
          concept: new Concept(),
          details: new VideoCreativeDetail()
        });
      }
    },
    relations: [{
      type: Backbone.HasOne,
      key: "advertiser",
      relatedModel: "Advertiser",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "concept",
      relatedModel: "Concept",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "details",
      relatedModel: "VideoCreativeDetail",
      includeInJSON: true
    }],
    fixture: "-video-creative"
  });
  return T1.Models.VideoCreative;
});
