define('models/adminCreativeComponent',["T1", "T1Model", "jQuery", "Underscore"], function (T1, T1Model, $) {
  T1.Models.AdminCreativeComponent = T1Model.extend({
    apiRoot: COMPASS_ENV.COMPONENT_API_BASE,
    url: function () {
      var url = this.apiRoot + "components";
      url = !this.isNew() ? url + "/" + this.get("component_id") : url;
      return url;
    },
    fixture: "-creativeComponent",
    deleteVersion: true,
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    isNew: function () {
      var componentID = this.get("component_id");
      return componentID === null || componentID === undefined;
    },
    validateAttrs: function (attrs) {
      var errors = [];
      var optionalFields = ["char_limit"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        component_human_name: function () {
          return !attrs.component_human_name;
        },
        value_type_name: function () {
          return !attrs.value_type_name;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    createEdit: function (rawData, options) {
      var self = this;
      var validationErrors;
      var deferred;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      var onError = options.error || $.noop;
      validationErrors = self.validateAttrs(rawData);
      delete rawData.component_id;
      delete rawData.created_at;
      delete rawData.updated_at;
      delete rawData.action;
      delete rawData.first;
      delete rawData.cid;
      delete rawData.status;
      delete rawData.index;
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        deferred = new Promise((resolve, reject) => {
          this.save(rawData, {
            success: function (model, response) {
              if (success) {
                success(model, response);
              }
              resolve(model, response);
            },
            statusInvalid: function (model, response) {
              if (statusInvalid) {
                statusInvalid(model, response);
              }
              reject(model, response);
            },
            conflict: function () {
              if (conflict) {
                conflict();
              }
              reject();
            },
            dataType: "json",
            contentType: "application/json",
            processData: false,
            stringify: true,
            processAjaxResponse: this.processAjaxResponse,
            errorDisplaySuppressingConfig: {
              errorSuppressed: true,
              errorCodes: [400]
            },
            error: function (model, response) {
              var jsonData = self.processAjaxResponse(response.responseText);
              if (onError) {
                onError(model, jsonData);
              }
              reject(model, jsonData);
            }
          });
        });
      }
      return deferred;
    },
    processAjaxResponse: function (resp) {
      var jsonData, meta, code, statusCode;
      function isJson(str) {
        try {
          jsonData = JSON.parse(str);
        } catch (e) {
          jsonData = false;
        }
        return jsonData;
      }
      jsonData = isJson(resp) || resp;
      meta = jsonData && jsonData.meta;
      statusCode = meta && meta.code;
      if (statusCode === 200 || statusCode === 201) {
        code = "ok";
      } else {
        code = "error";
        statusCode = statusCode === 400 ? code : statusCode;
      }
      if (code === "error" && jsonData) {
        jsonData.errors = {
          field_error: jsonData.errors.map(function (error) {
            return {
              name: error.param,
              error: error.msg
            };
          })
        };
      }
      return {
        jsonData: jsonData,
        statusCode: code === "ok" ? code : statusCode
      };
    }
  });
  return T1.Models.AdminCreativeComponent;
});
