define('modules/reporting/campaigns/geoPerformance/views/geoPerformance',["Underscore", "T1", "T1View", "T1Layout", "models/userPreferences", "modules/reporting/campaigns/models/additionalData", "text!../templates/geoPerformance.html", "T1Signature", "T1Preferences"], function (_, T1, T1View, T1Layout, UserPreferences, additionalData, template) {
  "use strict";

  let ReportModel, geoLevelConfig;
  const defaultDimension = "strategy_name";
  const id = "geo";
  const updateDimensions = function (geoNav, downloadURLOptions) {
    if (geoNav.country) {
      switch (geoNav.level) {
        case "country":
          downloadURLOptions.dimensions = "region_name";
          break;
        case "region":
          downloadURLOptions.dimensions = "metro_name";
          break;
      }
    }
  };
  return T1View.extend({
    settings_menu_config: {
      raw_data: {
        label: "Export CSV",
        items: {
          export_current: {
            contentType: "text/csv",
            item_type: "action-item",
            label: () => `By ${geoLevelConfig[ReportModel.getGeoNav().level].aggregate_level}`,
            subSection: " (Summary)",
            url: `std/${id}/download`,
            download_url: function () {
              const downloadURLOptions = {
                byDimension: true,
                full: false,
                isSummary: true,
                sourceURL: `std/${id}/download`
              };
              updateDimensions(ReportModel.getGeoNav(), downloadURLOptions);
              return ReportModel.getDownloadURL(downloadURLOptions);
            }
          },
          export_current_daily: {
            contentType: "text/csv",
            item_type: "action-item",
            label: () => `By ${geoLevelConfig[ReportModel.getGeoNav().level].aggregate_level}`,
            subSection: " (Daily)",
            url: `std/${id}/download`,
            download_url: function () {
              const downloadURLOptions = {
                byDimension: true,
                full: false,
                sourceURL: `std/${id}/download`
              };
              updateDimensions(ReportModel.getGeoNav(), downloadURLOptions);
              return ReportModel.getDownloadURL(downloadURLOptions);
            }
          },
          export_full: {
            contentType: "text/csv",
            item_type: "action-item",
            label: "By Country/Region/Metro",
            subSection: " (Summary)",
            url: `std/${id}/download`,
            download_url: function () {
              return ReportModel.getDownloadURL({
                byDimension: true,
                dimensions: "country_name,region_name,metro_name",
                full: true,
                isSummary: true,
                sourceURL: `std/${id}/download`
              });
            }
          },
          export_full_daily: {
            contentType: "text/csv",
            item_type: "action-item",
            label: "By Country/Region/Metro",
            subSection: " (Daily)",
            url: `std/${id}/download`,
            download_url: function () {
              return ReportModel.getDownloadURL({
                byDimension: true,
                dimensions: "country_name,region_name,metro_name",
                full: true,
                sourceURL: `std/${id}/download`
              });
            }
          }
        }
      }
    },
    defaultSubSection: "summary",
    dimension: "",
    displayDimension: "",
    displayMetric: "",
    displaySelectedColumns: "",
    displaySortKeyInfo: "",
    focus: "",
    geoLevel: "all",
    interval: "last_30_days",
    metric: "",
    section: "geoPerformance",
    selectedColumns: "",
    sortKeyInfo: "",
    subSection: "summary",
    template: template,
    videoDimension: "",
    videoMetric: "",
    videoSelectedColumns: "",
    videoSortKeyInfo: "",
    events: {
      click: "dehighlightItem"
    },
    dataEvents: {
      campaign: {
        "change:start_end": "reloadDimensionFilterUI"
      }
    },
    eventhubEvents: {
      "select:focus": function (focus) {
        this.focus = focus;
        this.getFocusKey = ReportModel.makeFocusKeyFunc(focus);
        this.preferences.save();
      },
      "select:dimension": function (dimension = defaultDimension) {
        this[this.getFocusKey("dimension")] = dimension;
        this.preferences.save();
        ReportModel.setDimension(dimension);
      },
      "select:interval": function (interval) {
        this.interval = interval;
        this.preferences.save();
        ReportModel.setInterval(interval);
      },
      "select:metric": function (metric) {
        this[this.getFocusKey("metric")] = metric;
        this.preferences.save();
        ReportModel.setMetric(metric);
      },
      "preference:change": function ({
        key: key,
        value: value
      }) {
        this[key] = value;
        this.preferences.save();
      },
      "reporting:campaign:load": function () {
        ReportModel.setMoreFilters("");
        T1.EventHub.publish("unload:noDataMessages");
        if (!this.loaded) {
          this.load();
        }
      },
      "select:campaign": "reloadDimensionFilterUI",
      "reporting:meta:load": function () {
        this.load();
      },
      "reporting:focus:meta:load": function () {
        this.initPreferences();
        this.refreshUI();
      },
      "select:geoLevel": "geoLevelNav",
      "select:chart": function (chartType) {
        this.subSection = chartType;
        this.preferences.save();
        this.mainModel.setSubSection(this.mainModel.get("section"), chartType);
      }
    },
    initialize(cfgs = {}) {
      const {
        models: models,
        report: report
      } = cfgs;
      const {
        campaign: campaign,
        mainModel: mainModel,
        reportModel: reportModel,
        summary: summary
      } = models;
      Object.assign(this, {
        campaign: campaign,
        mainModel: mainModel,
        models: models,
        needEvents: true,
        report: report,
        summary: summary
      });
      ReportModel = reportModel;
      ReportModel.setSection(id);
      this.mainModel.setReportMainView(this);
    },
    initPreferences() {
      let focus, getFocusKey;
      const signature = T1.Signature.create();
      const models = this.models;
      const reportMeta = models.reportMeta;
      const geoSubSection = models.mainMeta.get("geoSubSection");
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", id]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["dimension", "displayDimension", "displayMetric", "displaySelectedColumns", "displaySortKeyInfo", "focus", "geoLevel", "interval", "metric", "selectedColumns", "sortKeyInfo", "subSection", "videoDimension", "videoMetric", "videoSelectedColumns", "videoSortKeyInfo"]);
      ReportModel.setPreferences(this.preferences);
      ReportModel.setInterval(this.interval);
      if (geoSubSection) {
        this.subSection = geoSubSection;
      } else {
        this.subSection = UserPreferences.get("ui.reporting.geo.subSection") || this.defaultSubSection;
      }
      this.mainModel.setSubSection(this.mainModel.get("section"), this.subSection);
      this.focus = focus = this.focus || "all";
      this.getFocusKey = getFocusKey = ReportModel.makeFocusKeyFunc(focus);
      ReportModel.setFocus(focus);
      reportMeta.parseDimensions("dimensionOptions");
      reportMeta.parseMetrics();
      ReportModel.parseColumns();
      const defaultMetric = ReportModel.getDefaultMetric(this.campaign);
      const dimensionKey = getFocusKey("dimension");
      const dimension = this[dimensionKey];
      const verifiedDimensions = Object.keys(additionalData.dimensions.geo[focus]);
      const verifiedDimension = verifiedDimensions.includes(dimension) ? dimension : defaultDimension;
      this[dimensionKey] = verifiedDimension;
      this.preferences.save();
      ReportModel.setDimension(verifiedDimension, false, true);
      ReportModel.setMetric(this[getFocusKey("metric")] || defaultMetric, true);
    },
    initLayouts() {
      const models = this.models;
      const layoutConfig = {
        el: () => this.$(".w-geo-performance-panel"),
        template: `<div class="tabs-panel">\n          <div class="tabs-bar"></div>\n          <div class="tabs-control-focus"></div>\n          <div class="tabs-control-filter-by"></div>\n          <div class="tabs-control-view-by"></div>\n          <div class="tabs-control-bread-crumb"></div>\n          \x3c!-- right-aligned items, hence reverse order --\x3e\n          <div class="tabs-info"></div>\n          <div class="tabs-settings"></div>\n          <div class="site-panel"></div>\n        </div>`,
        layout: {
          ".tabs-control-focus": [{
            module: "reporting/campaigns",
            options: {
              models: models
            },
            viewType: "select_focus"
          }],
          ".tabs-control-filter-by": [{
            module: "reporting/campaigns",
            options: {
              index: 0,
              label: "Filter by:",
              models: models
            },
            viewType: "select_dimension"
          }],
          ".tabs-control-view-by": [{
            module: "reporting/campaigns",
            options: {
              label: "View by:",
              models: models
            },
            viewType: "select_geo_level"
          }],
          ".tabs-control-bread-crumb": [{
            module: "reporting/campaigns/geoPerformance",
            options: {
              models: models
            },
            viewType: "breadCrumb"
          }],
          ".tabs-settings": [{
            module: "reporting/campaigns",
            options: {
              menu_config: this.settings_menu_config,
              models: models
            },
            viewType: "settings_menu"
          }],
          ".tabs-info": [{
            module: "reporting/campaigns",
            viewType: "report_info"
          }],
          ".site-panel": [{
            module: "reporting/campaigns/geoPerformance",
            options: {
              models: models,
              report: this.report
            },
            viewType: "panel"
          }]
        }
      };
      this.layout = new T1Layout(layoutConfig);
    },
    load() {
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (this.needEvents) {
        this.bindViewEvents();
        this.needEvents = false;
      }
      ReportModel = this.models.reportModel;
      const reportMeta = ReportModel.reportMeta;
      geoLevelConfig = reportMeta.get("geoLevelConfig");
      ReportModel.setSection(id, true);
      if (this.campaign.id && reportMeta.loaded) {
        if (this.layout) {
          this.layout.unload();
        }
        this.initPreferences();
        this.initLayouts();
        this.render().then(() => {
          this.layout.load();
          this.loaded = true;
        });
      }
    },
    unload() {
      this.loaded = false;
      this.needEvents = true;
    },
    dehighlightItem() {
      this.el.children("div").trigger("dehighlight");
      ReportModel.setHighlightedItem();
    },
    reloadDimensionFilterUI() {
      if (this.layout) {
        const dimFilterUISelector = ".tabs-control-filter-by";
        const dimFilterUI = this.layout.layout[dimFilterUISelector][0].view;
        if (dimFilterUI) {
          this.$(dimFilterUISelector).empty();
          dimFilterUI.unload();
          this.layout.loadView(dimFilterUISelector);
        }
      }
    },
    refreshUI() {
      ReportModel.clearTempDimension();
      T1.EventHub.publish("unload:noDataMessages");
      this.load();
    },
    geoLevelNav(geoLevel) {
      const geoNav = ReportModel.getGeoNav();
      this.geoLevel = geoLevel;
      this.preferences.save();
      switch (geoLevel) {
        case "all":
          ReportModel.setGeoNav(geoLevel, "", "");
          break;
        case "country":
          ReportModel.setGeoNav(geoLevel, geoNav.level === "region" ? geoNav.country : "");
          break;
        case "region":
          ReportModel.setGeoNav(geoLevel, geoNav.country, "");
          break;
      }
    }
  });
});
