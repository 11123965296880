define('utils/T1AppUtils',["jQuery", "T1", "models/sessionUser", "models/t1AppTerms", "collections/t1AppTerms", "models/t1AppAcceptedTerms", "collections/t1AppAcceptedTerms"], function ($, T1, User, T1AppTerms, AppTerms, T1AppAcceptedTerms, AppAcceptedTerms) {
  "use strict";

  var T1AppUtils = {
    checkValidTerms: function (appId, termsId, acceptedTerms, fee, billingType) {
      var user = User.getUser();
      var userId = user.get("id");
      var validdata = [];
      var currentTerms, valid;
      for (const i in acceptedTerms) {
        if (T1.Utils.hasOwnProp(acceptedTerms, i)) {
          valid = true;
          currentTerms = acceptedTerms[i];
          if (currentTerms.app_id !== appId) {
            valid = false;
          }
          if (currentTerms.term_id !== termsId && termsId !== 0) {
            valid = false;
          }
          if (currentTerms.user_id !== parseInt(userId, 10)) {
            valid = false;
          }
          if (currentTerms.fee !== fee && currentTerms.fee) {
            valid = false;
          }
          if (currentTerms.billing_type !== billingType) {
            valid = false;
          }
          if (valid) {
            validdata = currentTerms;
            return validdata;
          }
        }
      }
      return false;
    },
    convertAppModalInfo: function (data, appId) {
      var newdata = [];
      for (const i in data) {
        if (data[i].id === appId) {
          newdata = data[i];
          return newdata;
        }
      }
    },
    checkLabAgreement: function (allTerms) {
      var user = User.getUser();
      var userId = user.get("id");
      var currentTerms;
      for (const i in allTerms) {
        if (T1.Utils.hasOwnProp(allTerms, i)) {
          currentTerms = allTerms[i];
          if (currentTerms.lab === 1 && currentTerms.user_id === parseInt(userId, 10)) {
            return true;
          }
        }
      }
      return false;
    },
    getTermsInfo: function (data, appId) {
      var newdata = false;
      var largestId = 0;
      for (const i in data) {
        if (data[i].app_id === appId && data[i].id > largestId) {
          largestId = data[i].id;
          newdata = data[i];
        }
      }
      return newdata;
    },
    fetchTerms: function () {
      this.appTerms = new T1AppTerms();
      this.collection = new AppTerms();
      this.collection.fetch({
        success: data => {
          if (data.meta.status === "success") {
            this.appTerms.set(data.data);
          }
        }
      });
    },
    fetchAcceptedTerms: function () {
      this.appAcceptedTerms = new T1AppAcceptedTerms();
      this.acceptedTerms = new AppAcceptedTerms();
      this.acceptedTerms.fetch({
        success: data => {
          if (data.meta.status === "success") {
            this.appAcceptedTerms.set(data.data);
          }
        }
      });
    },
    getEnv: function () {
      var env = window.location.href;
      var delimiter = "/";
      var start = 0;
      var end = 3;
      var tokens = env.split(delimiter).slice(start, end);
      var res = tokens.join(delimiter);
      var setenv;
      if (res.search(/t1qa\d+\./) !== -1) {
        setenv = `${res}/t1-apps/images/`;
      } else if (res.search(/t1\./) !== -1) {
        setenv = `${res}/t1-apps/images/`;
      } else {
        setenv = "https://t1qa12.mediamath.com/t1-apps/images/";
      }
      return setenv;
    }
  };
  return T1AppUtils;
});
