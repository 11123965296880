define('modules/reporting/segments/dataExport/createEdit/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "createEdit",
    name: "reporting/segments/dataExport/createEdit",
    viewCfgs: {
      createEdit: {},
      reportType: {},
      dateField: {},
      segmentExport: {},
      email: {},
      reportInfo: {},
      viewExample: {},
      selectSegments: {},
      selectCampaigns: {},
      panel: {},
      footer: {}
    }
  });
});
