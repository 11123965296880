define('modules/targeting/shared/itemSelectionBehavior',["jQuery"], function ($) {
  "use strict";

  function attachItemSelectionBehavior(view) {
    if (!view) {
      throw Error("You must pass in a view object");
    }
    if (!view.itemSelectionBehaviorConfigs) {
      throw Error('You must define a property block "itemSelectionBehaviorConfigs" on your view');
    }
    const defaultParentKey = "parent_value_id";
    const defaultCollectionKey = "collection";
    const configObj = view.itemSelectionBehaviorConfigs;
    const parentKey = configObj.parentIdKey ? configObj.parentIdKey : defaultParentKey;
    const checkAllowableKey = configObj.selectionAllowableKey ? configObj.selectionAllowableKey : null;
    const checkAllowableVal = configObj.selectionAllowableValue ? configObj.selectionAllowableValue : null;
    const collectionKey = configObj.collectionKey ? configObj.collectionKey : defaultCollectionKey;
    function getMissingCollectionErrMsg() {
      return `Failed resolving the collection with this key '${collectionKey}'. Please ensure\n        'itemSelectionBehaviorConfigs.collectionKey' is correctly set for your view.`;
    }
    view.sharedCollectionAddHandler = function (model) {
      var id = model.id;
      var self = this;
      var $el = this.el;
      var $item = $el.find(`[data-id="${id}"]`);
      var action = model.get("action");
      var actionOpposite = action === "include" ? "exclude" : "include";
      var collection = this[collectionKey];
      var partialCollection = this.partialCollection;
      var $children, $tmpEle, tmpId, tmpModel;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      $item.addClass("selected").removeClass("parent-selected").find(`.icon.${action}`).removeClass("active partial parentSelected").addClass("active");
      $item.find(`.icon.${actionOpposite}`).removeClass("active parentSelected");
      self.removeModelFromPartialCollection(id, action);
      $children = $item.closest(".item-wrapper").find(".children .item");
      $children.each(function (index, ele) {
        $tmpEle = $(ele);
        tmpId = $tmpEle.attr("data-id");
        if (self.isModelAllowedToBeSelected(tmpId) === true) {
          if (self.needParentSelectedState(tmpId, action) === true) {
            $tmpEle.addClass("parent-selected");
            $tmpEle.find(`.icon.${action}`).addClass("parentSelected");
          }
        }
      });
      self.processPartialStateForParents(model, action, true, function (parentObj) {
        if (self.isModelAllowedToBeSelected(parentObj.id) === true) {
          $item = $el.find(`[data-id="${parentObj.id}"]`);
          $item.find(`.icon.${action}`).removeClass("active parentSelected").addClass("partial");
        }
        tmpModel = parentObj.clone();
        tmpModel.set({
          action: action
        });
        if (partialCollection) {
          partialCollection.add(tmpModel, {
            silent: true
          });
        }
      });
    };
    view.sharedCollectionRemoveHandler = function (model) {
      var id = model.id;
      var self = this;
      var $el = this.el;
      var $item = $el.find(`[data-id="${id}"]`);
      var action = model.get("action");
      var actionOpposite = action === "include" ? "exclude" : "include";
      var collection = this[collectionKey];
      var $children, $tmpEle, tmpId;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      $item.removeClass("selected parent-selected");
      if (self.needParentSelectedState(id, action) === true) {
        $item.addClass("parent-selected");
        $item.find(`.icon.${action}`).removeClass("active").addClass("parentSelected");
      } else if (self.isAnyChildSelected(id, action) === true) {
        $item.find(`.icon.${action}`).removeClass("active").addClass("partial");
      } else {
        $item.find(".icon").removeClass("active");
        if (self.needParentSelectedState(id, actionOpposite) === true) {
          $item.addClass("parent-selected");
          $item.find(`.icon.${actionOpposite}`).addClass("parentSelected");
        }
      }
      $children = $item.closest(".item-wrapper").find(".children .item");
      $children.each(function (index, ele) {
        $tmpEle = $(ele);
        tmpId = $tmpEle.attr("data-id");
        if (self.isModelAllowedToBeSelected(tmpId) === true) {
          if (self.needParentSelectedState(tmpId, action) === false) {
            $tmpEle.removeClass("parent-selected");
            $tmpEle.find(`.icon.${action}`).removeClass("parentSelected");
          }
        }
      });
      self.processPartialStateForParents(model, action, false, function (parentObj) {
        if (self.isModelAllowedToBeSelected(parentObj.id) === true) {
          $item = $el.find(`[data-id="${parentObj.id}"]`);
          $item.find(`.icon.${action}`).removeClass("partial active");
          if (self.needParentSelectedState(parentObj.id, action) === true) {
            $item.addClass("parent-selected");
            $item.find(`.icon.${action}`).addClass("parentSelected");
          }
        }
        self.removeModelFromPartialCollection(parentObj.id, action);
      });
    };
    view.removeModelFromPartialCollection = function (id, action) {
      var partialColl = this.partialCollection;
      var model = partialColl ? partialColl.get(id) : null;
      if (model) {
        if (action) {
          if (action === model.get("action")) {
            partialColl.remove(model);
          }
        } else {
          partialColl.remove(model);
        }
      }
    };
    view.isModelAllowedToBeSelected = function (modelId) {
      var collection = this[collectionKey];
      var model;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      model = collection.get ? collection.get(modelId) : collection[modelId];
      if (model && checkAllowableKey) {
        return model.get(checkAllowableKey) === checkAllowableVal;
      }
      return true;
    };
    view.isModelInCollection = function (collection, modelId, action) {
      var model = collection.get ? collection.get(modelId) : collection[modelId];
      if (model) {
        if (action) {
          return model.get("action") === action;
        }
        return true;
      }
      return false;
    };
    view.needParentSelectedState = function (modelId, action, optionalColl) {
      var collection = this[collectionKey];
      var sharedCollection = this.sharedCollection;
      var model, parentNode, parent_id;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      if (this.isModelInCollection(sharedCollection, modelId, null) === true) {
        return false;
      }
      model = collection.get ? collection.get(modelId) : collection[modelId];
      if (!model && optionalColl) {
        model = optionalColl.get ? optionalColl.get(modelId) : optionalColl[modelId];
      }
      if (!model) {
        return false;
      }
      parent_id = model.get(parentKey);
      parentNode = collection.get ? collection.get(parent_id) : collection[parent_id];
      while (parentNode && parent_id !== undefined) {
        if (this.isModelInCollection(sharedCollection, parent_id, action) === true) {
          return true;
        }
        model = parentNode;
        parent_id = model.get(parentKey);
        parentNode = collection.get ? collection.get(parent_id) : collection[parent_id];
      }
      return false;
    };
    view.isAnyChildSelected = function (modelId, action) {
      var found = false;
      var collection = this[collectionKey];
      var sharedCollection = this.sharedCollection;
      var partialCollection = this.partialCollection;
      var tmpModelObj, tmpParentId;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      $.each(sharedCollection.models, function (indx, modelObject) {
        if (modelObject.get("action") === action) {
          tmpParentId = modelObject.get(parentKey);
          while (tmpParentId) {
            if (tmpParentId === modelId) {
              found = true;
              return false;
            }
            tmpModelObj = partialCollection ? partialCollection.get(tmpParentId) : null;
            if (!tmpModelObj) {
              tmpModelObj = collection.get ? collection.get(tmpParentId) : collection[tmpParentId];
            }
            tmpParentId = tmpModelObj ? tmpModelObj.get(parentKey) : null;
          }
        }
      });
      if (found === true) {
        if (partialCollection && partialCollection.get(modelId) === undefined) {
          let tmpModel = collection.get ? collection.get(modelId) : collection[modelId];
          if (tmpModel) {
            tmpModel = tmpModel.clone();
            tmpModel.set({
              action: action
            });
            partialCollection.add(tmpModel, {
              silent: true
            });
          }
        }
      }
      return found;
    };
    view.isDescendantOfTargetModel = function (modelId, targetParentId) {
      var collection = this[collectionKey];
      var parentId, tmpNode;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      tmpNode = collection.get ? collection.get(modelId) : collection[modelId];
      if (!tmpNode) {
        return false;
      }
      parentId = tmpNode.get(parentKey);
      while (parentId && tmpNode) {
        if (parentId === targetParentId) {
          return true;
        }
        tmpNode = collection.get ? collection.get(parentId) : collection[parentId];
        parentId = tmpNode ? tmpNode.get(parentKey) : undefined;
      }
      return false;
    };
    view.processPartialStateForParents = function (model, action, processorTrigger, processor) {
      var tmpModel = model;
      var parent_id = tmpModel.get(parentKey);
      var collection = this[collectionKey];
      var partialCollection = this.partialCollection;
      var hasSelectedChild, parentNode;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      parentNode = partialCollection ? partialCollection.get(parent_id) : null;
      if (!parentNode) {
        parentNode = collection.get ? collection.get(parent_id) : collection[parent_id];
      }
      while (parentNode && parent_id !== undefined && this.isModelInCollection(this.sharedCollection, parent_id, action) === false) {
        hasSelectedChild = this.isAnyChildSelected(parent_id, action);
        if (hasSelectedChild === processorTrigger) {
          processor(parentNode);
          tmpModel = parentNode;
          parent_id = tmpModel.get(parentKey);
          parentNode = partialCollection ? partialCollection.get(parent_id) : null;
          if (!parentNode) {
            parentNode = collection.get ? collection.get(parent_id) : collection[parent_id];
          }
        } else {
          break;
        }
      }
    };
    view.applySelectionStates = function (modelJSON, optionalColl) {
      var modelId = modelJSON.id;
      var collection = optionalColl ? optionalColl : this[collectionKey];
      var sharedCollection = this.sharedCollection;
      var matchingModelFromSharedColl = sharedCollection.get(modelId);
      var action = matchingModelFromSharedColl ? matchingModelFromSharedColl.get("action") : "";
      var actionInclude = "include";
      var actionExclude = "exclude";
      var matchingModelFromCollection, parentId;
      if (!collection) {
        throw getMissingCollectionErrMsg();
      }
      matchingModelFromCollection = collection.get ? collection.get(modelId) : collection[modelId];
      if (matchingModelFromCollection) {
        parentId = matchingModelFromCollection.get ? matchingModelFromCollection.get(parentKey) : matchingModelFromCollection[parentKey];
      }
      modelJSON.isParentIncluded = false;
      modelJSON.isParentExcluded = false;
      modelJSON.isIncluded = false;
      modelJSON.isExcluded = false;
      modelJSON.isIncludePartial = false;
      modelJSON.isExcludePartial = false;
      modelJSON.isParentSelected = false;
      if (this.isModelAllowedToBeSelected(modelId) === true) {
        if (matchingModelFromSharedColl && action !== "") {
          modelJSON.isIncluded = action === actionInclude;
          modelJSON.isExcluded = action === actionExclude;
        }
        if (modelJSON.isIncluded === false) {
          modelJSON.isIncludePartial = this.isAnyChildSelected(modelId, actionInclude);
          if (modelJSON.isIncludePartial === false && parentId && modelJSON.isExcluded === false) {
            modelJSON.isParentIncluded = this.needParentSelectedState(modelId, actionInclude, collection);
          }
        }
        if (modelJSON.isExcluded === false) {
          modelJSON.isExcludePartial = this.isAnyChildSelected(modelId, actionExclude);
          if (modelJSON.isExcludePartial === false && parentId && modelJSON.isIncluded === false) {
            modelJSON.isParentExcluded = this.needParentSelectedState(modelId, actionExclude, collection);
          }
        }
        if (modelJSON.isParentIncluded === true || modelJSON.isParentExcluded === true) {
          modelJSON.isParentSelected = true;
        }
      }
    };
  }
  return {
    attachBehavior: attachItemSelectionBehavior
  };
});
