define('modules/reporting/campaigns/dataExport/collections/savedReports',["T1", "Backbone", "../models/saveReport"], function (T1, Backbone, SaveReport) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  T1.Models.SavedReports = Backbone.Collection.extend({
    apiRoot: T1.RPT_SAVED_REPORTS_API_ROOT,
    model: SaveReport,
    canCache: true,
    sortBy: "name,report",
    cacheBuster: function () {
      return `currentTime=${new Date().getTime()}`;
    },
    initialize: function (models, options) {
      if (options) {
        this.canCache = options.canCache;
        this.filterBy = options.filterBy;
        this.sortBy = options.sortBy;
      }
    },
    fetch: function (options) {
      let filterBy, sortBy;
      let strandAjax = new Strand.Ajax();
      const collection = this;
      const successCallback = options.success || function () {};
      const progressCallback = options.progress;
      const errorCallback = options.error;
      const fetchObject = {};
      this.abort();
      function successHandler(event) {
        const response = event.detail.response;
        collection.add(response, options);
        successCallback(collection, response);
      }
      function progressHandler(event) {
        const progress = event.detail;
        progressCallback(progress.percent, progress.current, progress.total);
      }
      function errorHandler(event) {
        var errorInfo = event.detail;
        var response = errorInfo && errorInfo.response;
        var detail;
        if (errorCallback) {
          errorCallback(event);
        } else {
          detail = response ? `${response.errors[0].message}: ${response.errors[0].reason}` : "";
          T1Publish("comm:error", {
            code: errorInfo.error,
            detail: detail
          });
        }
      }
      const cacheBuster = this.canCache ? "" : `&${this.cacheBuster()}`;
      filterBy = this.filterBy instanceof Function ? this.filterBy() : this.filterBy;
      sortBy = this.sortBy instanceof Function ? this.sortBy() : this.sortBy;
      filterBy = filterBy ? `q=${encodeURIComponent(filterBy)}&` : "";
      sortBy = sortBy ? `order=${sortBy}` : "";
      fetchObject.contentType = "application/json";
      fetchObject.method = "GET";
      fetchObject.url = `${this.apiRoot}?${filterBy}${sortBy}${cacheBuster}`;
      fetchObject.withCredentials = true;
      if (localStorage.getItem("mm.bogo.acc")) {
        fetchObject.headers = [{
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("mm.bogo.acc")}`
        }];
      }
      strandAjax.addEventListener("data-ready", successHandler);
      strandAjax.addEventListener("data-error", errorHandler);
      if (progressCallback) {
        strandAjax.addEventListener("data-progress", progressHandler);
      }
      this.strandAjax = strandAjax;
      strandAjax.exec(null, fetchObject).then(function () {
        strandAjax.removeEventListener("data-ready", successHandler);
        strandAjax.removeEventListener("data-error", errorHandler);
        if (progressCallback) {
          strandAjax.removeEventListener("data-progress", progressHandler);
        }
        strandAjax = null;
      });
    },
    abort: function () {
      if (this.strandAjax) {
        this.strandAjax.abort();
      }
    }
  });
  return T1.Models.SavedReports;
});
