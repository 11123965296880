define('modules/admin/views/tabs',['require','Underscore','jQuery','models/admin','models/componentCreativePermission','text!modules/admin/templates/header.html','collections/organizations','T1Permissions','T1TabLayoutV2','T1View','text!modules/admin/templates/tabs.html','utils/AdminUtils'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const AdminModel = require("models/admin");
  const ComponentCreativePermission = require("models/componentCreativePermission");
  const headTemplate = require("text!modules/admin/templates/header.html");
  const Organizations = require("collections/organizations");
  const T1Permissions = require("T1Permissions");
  const T1TabLayout = require("T1TabLayoutV2");
  const T1View = require("T1View");
  const template = require("text!modules/admin/templates/tabs.html");
  const Utils = require("utils/AdminUtils");
  return T1View.extend({
    model: new AdminModel(),
    template: template,
    partials: {
      header: headTemplate
    },
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    initialize() {
      var self = this;
      this.componentPermission = new ComponentCreativePermission();
      Utils.updateBreadCrumb();
      this.componentPermission.fetch().then(function (result) {
        self.hasComponentPermission = result.data.includes("component_manager");
        self.initLayouts();
        self.load();
      }, function () {
        self.hasComponentPermission = T1Permissions.check("feature", "creative_component_admin");
        self.initLayouts();
        self.load();
      });
    },
    initLayouts() {
      var model = this.model;
      var tabs = [{
        label: "My Orgs",
        module: "admin/myOrgs",
        options: {},
        routeName: "myOrgs",
        viewType: ""
      }, {
        label: "Users",
        module: "admin/user",
        options: {},
        routeName: "users",
        viewType: ""
      }, {
        label: "Site Lists",
        module: "admin/siteLists",
        options: {},
        routeName: "siteLists",
        viewType: ""
      }];
      var orgs = Organizations.getOrganizations();
      var permissionsCheck = T1Permissions.check;
      if (permissionsCheck("user") === "REPORTER") {
        tabs.shift();
      }
      if (!permissionsCheck("siteLists.edit")) {
        tabs.pop();
      }
      if (permissionsCheck("feature", "admin_vendors")) {
        tabs.push({
          label: "Vendors",
          module: "admin/vendors",
          options: {},
          routeName: "vendors",
          viewType: ""
        });
      }
      if (this.hasComponentPermission) {
        tabs.push({
          label: "Creatives",
          module: "admin/creatives",
          options: {},
          routeName: "components",
          viewType: ""
        });
      }
      orgs.getSelected().then($.proxy(function (orgId) {
        var thisOrg = orgs.get(orgId);
        if (thisOrg) {
          model.set({
            org: thisOrg.toJSON()
          });
        }
      }));
      this.layout = new T1TabLayout({
        disabled: [],
        ignoreRouteDepth: true,
        level: 1,
        selected: model.get("mainTabSectionId") || 0,
        tabs: tabs,
        tabSetName: "AdminList"
      });
    },
    load() {
      this.render().then(() => {
        if (this.layout) {
          this.layout.el = this.$(".admin-tabs");
          this.layout.load();
        }
      });
    },
    saveSelectedTab(opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, tab => tab.label === selectedLabel), tabs);
      this.model.setMainTabSection(selectedIndex);
    },
    serialize() {
      return {
        subTitle: false,
        title: "Admin"
      };
    }
  });
});
