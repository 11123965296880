define('collections/supplySources',['require','models/supplySource','T1','T1Collection'],function (require) {
  "use strict";

  const SupplySource = require("models/supplySource");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  T1.Models.SupplySources = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: SupplySource,
    urlString: "supply_sources",
    fixture: "-supplySources",
    searchConfiguration: {
      fields: {
        seat_enabled: {
          type: "enum"
        },
        accountable_and_addressable: {
          type: "enum"
        },
        rtb_type: {
          type: "enum"
        }
      }
    },
    comparator(model) {
      return model.get("name");
    },
    filterGarbageData(isVideoStrategy) {
      const filteredData = this.models.filter(model => {
        let isValid;
        const attrs = model.attributes;
        const isSupplySource = attrs.type === "supply_source";
        const isRTBEnabled = attrs.rtb_enabled === "1";
        const hasDisplay = attrs.has_display === "1";
        const hasVideo = attrs.has_video === "1";
        const isVideoOnly = hasVideo && !hasDisplay;
        if (isVideoStrategy) {
          isValid = isSupplySource && isRTBEnabled && hasVideo;
        } else {
          const hasRTBType = attrs.rtb_type !== undefined;
          isValid = isSupplySource && isRTBEnabled && hasRTBType && !isVideoOnly;
        }
        return isValid;
      });
      this.reset();
      this.add(filteredData, {
        silent: true
      });
    },
    parse(data) {
      const modelArray = T1Collection.prototype.parse.call(this, data);
      return modelArray.filter(item => item.rtb_type);
    },
    getLength() {
      return this.browseModels ? this.browseModels.length : this.length;
    },
    getBrowseModels() {
      return this.browseModels ? this.browseModels : this.models;
    },
    searchSupplySources(searchTerm = "") {
      const term = searchTerm.toLowerCase();
      if (!this.browseModels) {
        this.browseModels = this.models;
      }
      if (term) {
        const searchResults = this.browseModels.filter(model => {
          const name = model.get("name").toLowerCase();
          const hasTerm = name.includes(term);
          return hasTerm;
        });
        this.reset(searchResults);
      } else {
        this.reset(this.browseModels);
      }
    }
  });
  return T1.Models.SupplySources;
});
