define('modules/reporting/segments/dataExport/createEdit/views/dateField',["jQuery", "Underscore", "T1", "T1View", "text!../templates/date-field.html"], function ($, _, T1, T1View, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {},
    eventhubEvents: {
      "createEdit.OverlapSelected": "showField",
      "createEdit.PerformanceSelected": "showField"
    },
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    load: function load() {
      var $el = this.el;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("faded");
      });
    },
    showField: function () {
      var $el = this.el;
      $el.removeClass("faded");
    },
    unload: function unload() {},
    serialize: function () {
      var d = new Date();
      return {
        "reporting-date": d.toLocaleString()
      };
    }
  });
});
