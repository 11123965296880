define('modules/strategy/supply/shared/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "strategy/supply/shared",
    viewCfgs: {
      bins: {},
      browse: {}
    }
  });
});
