define('modules/targeting/audience/views/panel',["jQuery", "Underscore", "T1", "T1View", "text!modules/targeting/audience/templates/panel.html", "text!modules/targeting/audience/templates/panel_layout.html", "text!templates/form_footer.html", "text!templates/filter-box.html", "text!modules/targeting/audience/templates/info_box.html", "T1Layout", "collections/audienceProviders", "collections/strategyAudienceSegments", "utils/CampaignStrategyUtils", "collections/strategyRetiredAudienceSegments", "utils/currencyConversionHelper", "collections/supernodes"], function ($, _, T1, T1View, template, panel_template, form_footer, filterBox, info_box, T1Layout, AudienceProviders, StrategyAudienceSegments, CampaignStrategyUtils, RetiredSegments, CurrencyUtils, Supernodes) {
  "use strict";

  const acxiomMsgState = {
    isDisplayed: false,
    hasBeenClosed: false
  };
  const liverampMsgState = {
    isDisplayed: false,
    hasBeenClosed: false
  };
  const providerTaxMsgState = {
    isDisplayed: false,
    hasBeenClosed: false
  };
  const usageWarningMessage = `By selecting use of any of the data or data segments\n      available below, you acknowledge and agree that you are\n      strictly prohibited from using any such data or data segments in connection with targeted ads that: 1)\n      indicate or refer to your selection criteria or any presumed knowledge about the intended recipient of the\n      ad; 2) market or advertise products or services related to creditworthiness or eligibility for credit,\n      insurance or employment; or 3) market or advertise products or services related to the sale or exchange of\n      illegal or illicit products (such as pornography, illegal drugs or illegal weapons). You are expressly\n      prohibited from using any such data or  data segments to exclude any individual(s) from your campaign(s)\n      based on the consumer belonging to any protected class (such as race or disability), but only to the extent\n      that such product or service you are marketing does not reasonably and inherently target consumers only\n      outside of such protected classes.`;
  const providerTaxWarningMessage = `Sales taxes may be charged for segments from this provider.\n      Where applicable this will be reflected in the total cost shown below and included in\n      the MM Data Cost in reporting and invoices.`;
  const providerTaxTimeToRead = 8e3;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var AudiencePanel = T1View.extend({
    template: template,
    partials: {
      form_footer: form_footer
    },
    audienceFilterList: [{
      text: "All Providers",
      preferred: 99999,
      value: 0
    }],
    providerTaxTimeout: null,
    eventhubEvents: {
      audienceCollectionIsLoaded: function () {
        this.setupSearchFilter();
      },
      formElementChanged: function () {
        this.isFormElementsChanged = true;
      },
      "bins.providerTaxAdded": function () {
        this.toggleUsageWarningInfo(true, {
          node: "providerTax"
        });
        if (this.providerTaxTimeout) {
          clearTimeout(this.providerTaxTimeout);
        }
        this.providerTaxTimeout = setTimeout(() => {
          this.toggleUsageWarningInfo(false, {
            node: "providerTax"
          });
        }, providerTaxTimeToRead);
      }
    },
    events: {
      "click .usage-warning-info .close": "toggleUsageWarningInfo",
      "click .tabWarning .hideIcon": "onCloseRetiredSegmentsPopup"
    },
    toggleUsageWarningInfo(e, opts) {
      const defaultTopMargin = 5;
      const defaultSpacing = 25;
      let newTopMargin = defaultTopMargin;
      const canShow = e === true;
      const action = canShow ? "show" : "hide";
      const provider = opts && opts.node || e.currentTarget.parentNode.className.replace(/\s*(?:usage-warning-info|info-box)\s*/g, "");
      const $listBody = this.$(".list-body");
      const $infoEls = this.$(".usage-warning-info");
      const $acxiomMsg = $infoEls.filter(".acxiom");
      const $liverampMsg = $infoEls.filter(".liveramp");
      const $providerTaxMsg = $infoEls.filter(".providerTax");
      if (typeof e === "object") {
        this.preventDefault(e);
      }
      switch (provider) {
        case "acxiom":
          if (liverampMsgState.isDisplayed) {
            liverampMsgState.isDisplayed = false;
            $liverampMsg.hide();
          }
          acxiomMsgState.isDisplayed = canShow;
          acxiomMsgState.hasBeenClosed = !canShow;
          $acxiomMsg[action]();
          break;
        case "liveramp":
          if (acxiomMsgState.isDisplayed) {
            acxiomMsgState.isDisplayed = false;
            $acxiomMsg.hide();
          }
          liverampMsgState.isDisplayed = canShow;
          liverampMsgState.hasBeenClosed = !canShow;
          $liverampMsg[action]();
          break;
        case "providerTax":
          providerTaxMsgState.isDisplayed = canShow;
          providerTaxMsgState.hasBeenClosed = !canShow;
          $providerTaxMsg[action]();
          break;
      }
      const acxiomMsgHeight = acxiomMsgState.isDisplayed ? $acxiomMsg.height() : 0;
      const liverampMsgHeight = liverampMsgState.isDisplayed ? $liverampMsg.height() : 0;
      const providerTaxMsgHeight = providerTaxMsgState.isDisplayed ? $providerTaxMsg.height() : 0;
      const msgsDisplaying = [acxiomMsgState, liverampMsgState, providerTaxMsgState].filter(state => state.isDisplayed);
      const spaceBelowInfo = msgsDisplaying.length ? defaultSpacing * msgsDisplaying.length : 0;
      newTopMargin += acxiomMsgHeight + liverampMsgHeight + providerTaxMsgHeight + spaceBelowInfo;
      $listBody.css("top", `${newTopMargin}px`);
    },
    needsWarningMessage: function (model) {
      var fullPath = model.get("full_path") || "";
      var lowerCasePath = fullPath.toLowerCase();
      if (lowerCasePath.includes("acxiom")) {
        return !acxiomMsgState.hasBeenClosed;
      } else if (lowerCasePath.includes("liveramp")) {
        return !liverampMsgState.hasBeenClosed;
      }
      return false;
    },
    isUsageWarnableSelected: function (model) {
      if (this.needsWarningMessage(model) && this.sharedCollection.loaded) {
        let lowerCasePath;
        const fullPath = model.get("full_path");
        if (fullPath) {
          lowerCasePath = fullPath.toLowerCase();
        }
        if (lowerCasePath.includes("acxiom")) {
          this.toggleUsageWarningInfo(true, {
            node: "acxiom"
          });
        } else if (lowerCasePath.includes("liveramp")) {
          this.toggleUsageWarningInfo(true, {
            node: "liveramp"
          });
        }
      }
    },
    onCloseRetiredSegmentsPopup: function () {
      const $warningPopUp = this.$(".tabWarning");
      const $grid = this.$(".grid-holder");
      $warningPopUp.css("display", "none");
      $grid.css("top", "0px");
    },
    dataEvents: {
      sharedCollection: {
        add: function (model) {
          this.isFormElementsChanged = true;
          this.isUsageWarnableSelected(model);
        },
        remove: function (model) {
          model.set({
            group_identifier: null
          });
          this.isFormElementsChanged = true;
        },
        "change:user_cpm": function () {
          this.isFormElementsChanged = true;
        },
        reset: function () {
          if (!this.layout) {
            this.initLayout();
          }
          this.load();
          this.isFormElementsChanged = false;
        }
      }
    },
    initialize({
      strategy: strategy,
      sharedCollection: sharedCollection,
      groupedSegments: groupedSegments,
      partialCollection: partialCollection,
      retiredCollection: retiredCollection,
      orgId: orgId
    }) {
      this.strategy = strategy;
      this.advertiserId = strategy.get("campaign").get("advertiser").get("id");
      this.sharedCollection = sharedCollection;
      this.sharedCollection.currencyCode = this.strategy.getCurrencyCode();
      this.groupedSegments = groupedSegments;
      this.partialCollection = partialCollection;
      this.retiredCollection = retiredCollection;
      this.orgId = orgId;
      if (this.sharedCollection.loaded === true) {
        this.initLayout();
      }
    },
    reload: function () {
      if (!this.layout && this.sharedCollection.loaded === true) {
        this.initLayout();
      }
      this.searchFilterLoaded = false;
      this.load();
    },
    unload: function () {
      this.retiredCollection.reset([], {
        silent: true
      });
      this.retiredCollection.loaded = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
      _.each(this.sharedCollection.models, model => {
        if (model.get("type") === "retired_audience_segments") {
          this.sharedCollection.remove(model.get("id"), {
            silent: true
          });
        }
      });
      this.retiredCollection.fetch({
        success: () => {
          this.retiredCollection.loaded = true;
        }
      });
      acxiomMsgState.isDisplayed = false;
      liverampMsgState.isDisplayed = false;
      providerTaxMsgState.isDisplayed = false;
    },
    setupSearchFilter: function () {
      var providersList = this.supernodes.toJSON();
      for (let i = 0; i < providersList.length; i++) {
        const vendor = providersList[i];
        if (vendor.name === "Integral Ad Science" || vendor.id === COMPASS_ENV.IAS_ID) {
          providersList.splice(i, 1);
          break;
        }
      }
      if (!this.searchFilterLoaded) {
        this.searchFilterLoaded = true;
        const filterList = $.map(providersList, function (provider) {
          return {
            text: provider.name || "",
            preferred: provider.preferred || 0,
            value: provider.vendor_id || ""
          };
        });
        if (this.audienceFilterList.length > 1) {
          this.audienceFilterList = [this.audienceFilterList[0]];
        }
        this.audienceFilterList = this.audienceFilterList.concat(filterList);
        this.sortProviderList();
        this.refreshFilterDropdown();
        this.delegateEvents();
        this.isFormElementsChanged = false;
      }
    },
    sortProviderList: function () {
      const allProviderHolder = this.audienceFilterList[0];
      let filterList = this.audienceFilterList;
      filterList = filterList.sort(function (a, b) {
        if (a.preferred > 0 || b.preferred > 0) {
          return a.preferred < b.preferred ? 1 : -1;
        }
        return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
      });
      const allProviderHolderIndex = filterList.indexOf(allProviderHolder);
      if (!allProviderHolderIndex === 0) {
        arraymove(filterList, allProviderHolderIndex, 0);
      }
      this.audienceFilterList = filterList;
      function arraymove(arr, fromIndex, toIndex) {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      T1.EventHub.publish("bins:resetDraggedDropTarget");
      return !isFormElementsChanged;
    },
    initLayout: function () {
      const {
        strategy: strategy,
        advertiserId: advertiserId,
        sharedCollection: sharedCollection,
        retiredCollection: retiredCollection
      } = this;
      var audienceProviders = new AudienceProviders([], {
        isPaginated: false,
        fetchOptions: {
          full: "*",
          only_brain_enabled: "false"
        }
      });
      var filterClass = "audienceProviders";
      var filterCallback = function (term) {
        const providerId = $(this.el).find(`.${filterClass}`).val();
        T1.EventHub.publish("searchAudience", {
          term: term,
          provider_id: providerId
        });
      };
      var clearFilterCallback = function () {
        T1.EventHub.publish("browseAudience");
      };
      this.audienceProviders = audienceProviders;
      this.supernodes = new Supernodes();
      this.supernodes.advertiserId = advertiserId;
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panel_template,
        partials: {
          info_box: info_box
        },
        layout: {
          ".audience-filter": [{
            module: "shared",
            viewType: "filter",
            options: {
              selectOptions: this.audienceFilterList,
              enableDirectionAuto: false,
              filterClass: filterClass,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback
            }
          }],
          ".browser-c": [{
            module: "targeting/audience",
            viewType: "browse",
            options: {
              advertiserId: advertiserId,
              audienceProviders: this.audienceProviders,
              collection: this.supernodes,
              orgId: this.orgId,
              partialCollection: this.partialCollection,
              sharedCollection: sharedCollection,
              strategy: strategy,
              supernodes: this.supernodes
            }
          }],
          ".bins-column": [{
            module: "targeting/audience",
            viewType: "bins",
            options: {
              retiredCollection: retiredCollection,
              sharedCollection: sharedCollection,
              strategy: strategy,
              supernodes: this.supernodes
            }
          }]
        },
        serialize: function () {
          return {
            msgForAcxiom: {
              infoBoxMessageLines: usageWarningMessage,
              infoMessageCanClose: true,
              infoBoxClassName: "usage-warning-info acxiom"
            },
            msgForLiveRamp: {
              infoBoxMessageLines: usageWarningMessage,
              infoMessageCanClose: true,
              infoBoxClassName: "usage-warning-info liveramp"
            },
            msgForProviderTax: {
              infoBoxMessageLines: providerTaxWarningMessage,
              infoMessageCanClose: true,
              infoBoxClassName: "usage-warning-info providerTax"
            },
            showRetiredSegments: Boolean(retiredCollection.models.length)
          };
        }
      });
    },
    refreshFilterDropdown: function () {
      var renderPartialConfig = {
        template: filterBox,
        templateTarget: ".select-input",
        targetEl: ".select-input",
        context: this.el,
        useTemplateHtml: false,
        data: {
          filterList: this.audienceFilterList,
          filterClass: "providers"
        }
      };
      this.renderPartialTemplate(renderPartialConfig);
      this.$(".select-input").trigger("liszt:updated");
    },
    showRetiredSegmentsInWarningPopup: function () {
      const $gridHolder = this.$(".grid-holder");
      const $msgHolder = this.$(".tabWarning");
      const $retiredSegmentsHolder = this.$(".retired-segments-holder");
      const retiredSegments = this.retiredCollection.models;
      var collLength = retiredSegments.length;
      var utils = T1.Utils;
      var boldLastPartOfFullPath = utils.boldLastPartOfFullPath;
      var str = "";
      $msgHolder.css("display", "block");
      $gridHolder.css("top", "160px");
      for (let i = 0; i < collLength; i++) {
        const item = retiredSegments[i];
        const itemToDisplay = CampaignStrategyUtils.getPath(item.get("full_retired_path"));
        itemToDisplay.title = boldLastPartOfFullPath.call(utils, {
          fullPath: itemToDisplay.title,
          separator: " &raquo ",
          searchTermToBeHighlighted: null
        });
        str += `<div>${itemToDisplay.title}</div>`;
      }
      $retiredSegmentsHolder.append(str);
    },
    load: function () {
      const currencyCode = this.sharedCollection.currencyCode;
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "audience_targeting"
          });
          if (this.retiredCollection.models.length) {
            this.showRetiredSegmentsInWarningPopup();
            this.retiredCollection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.retiredCollection, currencyCode);
          }
          if (this.sharedCollection.loaded === true) {
            if (this.searchFilterLoaded === false) {
              setTimeout(() => {
                this.setupSearchFilter();
              }, 0);
            }
            this.sharedCollection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.sharedCollection, currencyCode);
          }
          this.isFormElementsChanged = false;
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(this.strategy.get("campaign"));
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    prepareRetiredCollectionForSave: function () {
      const retiredAudienceSegments = this.sharedCollection.models.reduce(function (arr, model) {
        if (model.get("type") === "retired_audience_segment" || model.get("rel") === "retired_audience_segment") {
          arr.push(model.get("id"));
        }
        return arr;
      }, []);
      return this.retiredCollection.buildPostObjectForRetiredSegments(retiredAudienceSegments);
    },
    onDontSave: function () {
      const currencyCode = this.sharedCollection.currencyCode;
      this.sharedCollection.reset([], {
        silent: true
      });
      this.sharedCollection.loaded = false;
      this.retiredCollection.reset([], {
        silent: true
      });
      this.retiredCollection.loaded = false;
      this.groupedSegments.fetch({
        success: () => {
          this.sharedCollection.loaded = true;
          this.sharedCollection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.sharedCollection, currencyCode);
        },
        currency_code: currencyCode
      });
      this.retiredCollection.fetch({
        success: () => {
          this.retiredCollection.loaded = true;
          this.retiredCollection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(this.retiredCollection, currencyCode);
        }
      });
      T1.EventHub.publish("onDontSaveClicked");
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    postData: function (formValues, successCallback, statusInvalidCallback) {
      const self = this;
      const deferred = $.Deferred();
      T1.EventHub.publish("bins:checkEmptyGroups", deferred);
      deferred.then(() => {
        var postData = this.sharedCollection.buildPostObj();
        var strategyAudienceSegments = new StrategyAudienceSegments();
        var strategyRetiredSegments = new RetiredSegments();
        const retiredSegmentsPostData = this.prepareRetiredCollectionForSave();
        function saveRetiredSegments() {
          strategyRetiredSegments.strategyId = self.strategy.id;
          strategyRetiredSegments.save({
            data: retiredSegmentsPostData
          }, {
            success: retiredSucces,
            conflict: statusInvalidCallback,
            onStatusInvalid: statusInvalidCallback
          });
        }
        function success(...args) {
          if (!$.isEmptyObject(retiredSegmentsPostData)) {
            saveRetiredSegments();
          } else {
            self.strategy.fetch({
              silent: true
            });
            successCallback(...args);
          }
          T1.EventHub.publish("strategy-create-edit:reloadSubViews");
          T1.EventHub.publish("bins:resetDraggedDropTarget");
        }
        function retiredSucces(...args) {
          self.strategy.fetch({
            silent: true
          });
          successCallback(...args);
        }
        strategyAudienceSegments.id = this.strategy.id;
        if ($.isEmptyObject(postData) || !strategyAudienceSegments.id) {
          statusInvalidCallback(strategyAudienceSegments, []);
          if (!$.isEmptyObject(retiredSegmentsPostData)) {
            saveRetiredSegments();
          }
        }
        strategyAudienceSegments.action = "audience_segments";
        strategyAudienceSegments.save({
          data: postData
        }, {
          success: success,
          conflict: statusInvalidCallback,
          onStatusInvalid: statusInvalidCallback
        });
      }, () => {
        self.stopSpinner(self.$(".spinner").closest("[class^=save-btn]"));
        T1.EventHub.publish("unloadAlertDialog:close");
      });
    }
  });
  return T1.Form.ViewUtils.extend(AudiencePanel);
});
