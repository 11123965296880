define('modules/reporting/segments/dataExport/createEdit/views/email',["jQuery", "Underscore", "T1", "T1View", "text!../templates/email.html"], function ($, _, T1, T1View, template) {
  "use strict";

  var DataExportModel;
  var clearField = false;
  return T1View.extend({
    template: template,
    events: {
      "changed .report-recipients": "updateEmail"
    },
    eventhubEvents: {
      "createEdit.OverlapSelected": "showField",
      "createEdit.PerformanceSelected": "showField",
      clearSegmentsReportsEmail: "clearEmailField"
    },
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    load: function load() {
      var $el = this.el;
      var self = this;
      $el.css("visibility", "hidden");
      this.render().then(function () {
        $el.css("visibility", "hidden");
        document.querySelector(".segments-reports #email").validator = function () {
          var emailString = document.querySelector("#email").value;
          var emails = emailString.split(",");
          if (clearField) {
            clearField = false;
            return true;
          } else {
            return self.isValid(emails);
          }
        };
      });
    },
    showField: function () {
      var $el = this.el;
      $el.css("visibility", "visible");
    },
    updateEmail: function (event) {
      var emailString = $(event.currentTarget).attr("value");
      var emails = emailString.split(",");
      DataExportModel.setEmail(emails, this.isValid(emails));
    },
    clearEmailField: function () {
      $(".segments-reports .report-recipients").val("");
      clearField = true;
    },
    isValid: function (emailList) {
      var isValid = true;
      var regex = /^(([a-zA-Z]|[0-9])|([-]|[_]|[.]))+[@](([a-zA-Z0-9])|([-])){2,63}[.]((([a-zA-Z0-9]){2,63})+)([.](([a-zA-Z0-9]{2,63}))){0,1}$/;
      var email;
      for (var i = 0; i < emailList.length; i++) {
        email = $.trim(emailList[i]);
        if (email === "" || !regex.test(email)) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    unload: function unload() {
      DataExportModel.setEmail(null, false);
    }
  });
});
