define('modules/campaign/bulkedit/strategies/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Collection", "models/campaign", "collections/strategies", "utils/CampaignStrategyUtils", "text!modules/campaign/bulkedit/strategies/templates/panel.html"], function ($, _, T1, T1View, T1Layout, T1Collection, Campaign, Strategies, CampaignStrategyUtils, template) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var StrategiesPanel = T1View.extend({
    template: template,
    viewMode: "browse",
    isShowSelectedClicked: false,
    options: {
      searchInputDelay: 500,
      searchInputMinChar: 2,
      showActiveLabel: "Show Active Only"
    },
    formConfig: {
      bulkedit: true,
      bulkeditStrategies: true
    },
    dataEvents: {},
    eventhubEvents: {
      "bulkEditStrategies:clearSearchField": function () {
        this.$("#searchInput")[0].value = "";
        this.doNotResetCollection = true;
        this.$(".searchTxt").html("");
      }
    },
    events: {
      "click #showSelected": "onShowSelectedClick",
      "keyup #searchInput": "onSearchInputKeyUp",
      "changed #searchInput": "onClearBtnClick"
    },
    initialize: function ({
      campaignId: campaignId,
      bulkeditor: bulkeditor
    }) {
      this.campaignId = campaignId;
      this.bulkeditor = bulkeditor;
      this.campaign = new Campaign({
        id: this.campaignId
      });
      this.collection = new Strategies();
      this.sharedCollection = new T1Collection();
      this.checkBulkeditorReady();
    },
    initializeViews: function () {
      this.lazySearch = _.debounce(this.doSearch, this.options.searchInputDelay);
      this.initLayout();
    },
    load: function () {
      this.render().then(() => {
        if (this.layout) {
          this.layout.load().then(() => {
            T1.EventHub.publish("bulkEdit:hideLoadingIcon");
            if (window.hybridWorkflowParams.isHybrid) {
              updateCancelBtn();
              this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
              this.$cancelBtn = $(".cancelNewT1");
            }
          });
        }
      });
    },
    reload: function () {
      this.checkBulkeditorReady();
    },
    checkBulkeditorReady: function () {
      if (this.bulkeditor.editorReady) {
        this.initializeViews();
      } else {
        this.bulkeditor.getStatus({
          success: () => {
            this.initializeViews();
            this.load();
          }
        });
      }
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body-strategies",
        template: '<div class="w-grid"></div>',
        layout: {
          ".w-grid": [{
            module: "campaign/bulkedit/strategies",
            viewType: "browse",
            options: {
              campaignId: this.campaignId,
              bulkeditor: this.bulkeditor,
              campaign: this.campaign,
              collection: this.collection,
              sharedCollection: this.sharedCollection
            }
          }]
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              isBulkEdit: true,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertBulkeditDialog({}, onTabChange);
      }
      return !isFormElementsChanged;
    },
    unload: function () {
      T1.EventHub.publish("bulkEdit:showLoadingIcon");
      this.bulkeditor.editorReady = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    stage: function (successCallback) {
      const selectedStrategies = this.getSelected();
      const ids = [];
      const isSearchClear = this.$("#searchInput").val() === "";
      const isSelectAllChecked = this.sharedCollection.length === this.collection.length;
      if (isSelectAllChecked && isSearchClear && !this.isShowSelectedClicked) {
        ids.push("all");
      } else {
        selectedStrategies.forEach(function (strategy) {
          ids.push(strategy.get("id"));
        });
      }
      if (ids.length) {
        this.bulkeditor.stageStrategies({
          strategies: ids,
          success: (newModel, attributes) => {
            if (!attributes.entity.execution_errors.length) {
              this.bulkeditor.set({
                stagedStrategies: ids
              });
              T1.Notify("message", "Strategies have been added successfully!");
              T1.EventHub.publish("bulkEditStrategies:strategiesStagedSuccess");
              successCallback();
            }
          }
        });
      }
    },
    getSelected: function () {
      const selectedModels = this.collection.filter(model => model.get("selected") === true);
      return selectedModels;
    },
    onShowSelectedClick: function (e) {
      this.isShowSelectedClicked = e.currentTarget.value;
      T1.EventHub.publish("bulkEditStrategies:showSelected", e.currentTarget.value);
    },
    onSearchInputKeyUp: function (evt) {
      this.term = evt.target.value;
      this.lazySearch(evt.target);
    },
    onClearBtnClick: function (evt) {
      if ((evt.currentTarget.value === "" || evt.currentTarget.value === null) && !this.doNotResetCollection) {
        T1.EventHub.publish("bulkEditStrategies:resetStrategies");
      }
      this.doNotResetCollection = false;
    },
    doSearch: function (target) {
      const invalidShortSearch = `Please enter at least ${this.options.searchInputMinChar} characters.`;
      var publish = T1.EventHub.publish;
      var value = this.term;
      function showErrorTooltip(errorMsg) {
        var $target = $(target);
        var errorConfig = {
          trigger: "manual",
          getExternalTip: false,
          className: "w-FormError",
          offset: 10
        };
        errorConfig.tooltipEle = target;
        errorConfig.trigger = "hover";
        errorConfig.gravity = "s";
        target.error = true;
        $target.attr("title", errorMsg);
        const tip = T1.Tooltip(target, errorConfig);
        if ($target.data("tipsy")) {
          $target.tipsy("show");
        }
        return tip;
      }
      if (this.term.length) {
        if (this.term.length < this.options.searchInputMinChar) {
          if (target.getAttribute("original-title") !== invalidShortSearch) {
            this.errorTooltip = showErrorTooltip(invalidShortSearch);
          }
          return;
        }
        this.searchField = "name|id";
      }
      publish("bulkEditStrategies:onSearchRequest", {
        searchField: this.searchField,
        term: value
      });
      if (this.errorTooltip) {
        target.setAttribute("original-title", "");
        this.errorTooltip.unbind();
        $(".tipsy").remove();
      }
    }
  });
  return T1.Form.ViewUtils.extend(StrategiesPanel);
});
