define('modules/reporting/campaigns/views/dimension_filter_list',["jQuery", "T1", "T1FilterList"], function ($, T1, T1FilterList) {
  "use strict";

  var DimensionFilterList = T1FilterList.extend({
    dataEvents: {
      reportModel: {
        "update:dim1DDL": function () {
          if (this.isDim1Instance) {
            this.updateList();
          }
        },
        "update:dim2DDL": function () {
          if (this.isDim2Instance) {
            this.updateList();
          }
        }
      }
    },
    initialize: function (args) {
      var dimensionData, selectedDimension;
      var models = args.models;
      var section = models.mainModel.get("section") || "performance";
      var isTechnology = section === "device_technology";
      var reportMeta = models.reportMeta;
      var reportModel = models.reportModel;
      var dimensionObj = args.dimensionObj;
      var focus = reportModel.get("focus") || "all";
      this.isDim1Instance = args.index === 0;
      this.isDim2Instance = args.index === 1;
      this.models = models;
      this.section = section;
      this.reportModel = reportModel;
      this.dimensionObj = dimensionObj;
      this.reportModelObj = $.extend({}, reportModel.toJSON());
      this.collection = models.dimensionItemsCollection;
      selectedDimension = this.reportModelObj.dimension || null;
      this.selectedDimension = selectedDimension;
      if (isTechnology) {
        dimensionData = dimensionObj[selectedDimension];
      } else {
        dimensionData = reportMeta.get("dimensions")[section][focus][selectedDimension];
      }
      if (dimensionData && selectedDimension) {
        this.all_text = `All ${dimensionData.xAxisLabel}`;
      } else {
        this.all_text = "All Strategies";
      }
      reportModel.registerDimFilterList(this, args.index);
    },
    load: function () {
      var self = this;
      var $el = this.el;
      if (this.loaded) {
        return;
      }
      if (!this.showOnInit) {
        $el.hide();
      }
      this.render().then(function () {
        if (!self.unloaded) {
          $el.trigger("initFilterList");
          self.initializeUI();
          self.updateList();
        }
      });
      this.loaded = true;
      this.reportModel = this.models.reportModel;
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
    },
    unload: function () {
      this.selectedDimension = null;
      this.all_text = null;
      this.unloaded = true;
    },
    updateList: function () {
      var i, selected;
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var tempDimension = reportModel.getTempDimension(isDim2Instance);
      var dimension = reportModel.getDimension(isDim2Instance);
      var focus = reportModel.get("focus") || "all";
      var dim = tempDimension || dimension;
      var section = this.section;
      var reportMeta = this.models.reportMeta;
      var isTechnology = section === "device_technology";
      var dimensionData = isTechnology ? this.dimensionObj[dim] : reportMeta.get("dimensions")[section][focus][dim];
      var filteredInItems = reportModel.getFilteredInItems(isDim2Instance) || [];
      var filteredOutItems = reportModel.getFilteredOutItems(isDim2Instance) || [];
      var items = reportModel.getDimensionFilterItems(isDim2Instance) || null;
      var itemsLength = items ? items.length : 0;
      var temp = this.selectFilterListView.inTemporaryListMode();
      this.all_text = dimensionData ? `All ${dimensionData.xAxisLabel}` : "All Strategies";
      this.selectedDimension = dim;
      if (temp) {
        for (i = 0; i !== itemsLength; i++) {
          items[i].selected = true;
        }
      } else {
        if (filteredInItems) {
          for (i = 0; i !== itemsLength; i++) {
            selected = filteredInItems.indexOf(items[i].id) > -1;
            items[i].selected = selected;
          }
        }
      }
      this.allSelected = temp || filteredOutItems.length === 0;
      if (this.allSelected) {
        if ((!tempDimension || tempDimension === dimension) && !this.allCheckedOnApply) {
          this.allSelected = false;
        } else {
          for (let j = 0; j !== itemsLength; j++) {
            items[j].selected = true;
          }
        }
      }
      this.setItems(items);
      T1FilterList.prototype.updateList.call(this);
      this.updateSearch();
    },
    updateSearch: function () {
      var temp = this.selectFilterListView.inTemporaryListMode();
      var searchInput = this.searchInput;
      var searchTerm = searchInput ? searchInput.data("searchTerm") || "" : "";
      if (!temp && searchTerm !== "") {
        searchInput.val(searchTerm);
        this.search();
      }
    },
    getEmptyMessage: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var tempDimension = reportModel.getTempDimension(isDim2Instance);
      var dimensionInfo = tempDimension ? reportModel.getTempDimensionInfo(isDim2Instance) : reportModel.getDimensionInfo(isDim2Instance);
      if (dimensionInfo) {
        return `No ${dimensionInfo.xAxisLabel.toLowerCase()} found`;
      }
    },
    setAppliedFilteredItems: function (checkedItems) {
      this.reportModel.setAppliedFilteredItems($.extend(true, {}, checkedItems), this.isDim2Instance);
    },
    serialize: function () {
      return {
        all_text: this.all_text
      };
    }
  });
  return DimensionFilterList;
});
