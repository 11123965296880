define('modules/campaign/chart/controllers/commController',["jQuery", "T1", "T1PerformanceTimer", "collections/campaignReport"], function ($, T1, T1Timer, CampaignReport) {
  "use strict";

  const CommController = function (reportModel, addOptions = {}) {
    let collection;
    const entity = reportModel.get("entity");
    const entityObj = entity ? entity.toJSON() : {};
    const entityType = entityObj.type;
    const extOptions = addOptions;
    const fetchKey = reportModel.get("fetchKey");
    const reportMeta = reportModel.reportMeta;
    const timerValue = `${entityObj.name}-${entityObj.id}-${fetchKey}`;
    const fetchOptions = {
      dimensions: "campaign_id,campaign_timezone_code,campaign_timezone",
      filter: entityType === "campaign" ? "campaign_id=" : "strategy_id=",
      precision: 2,
      time_rollup: "all",
      order: "date"
    };
    function timer(category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    }
    function url() {
      const opts = $.extend({}, this.fetchOptions);
      opts.filter += `${entityObj.id}`;
      if (entityType !== "campaign") {
        opts.filter += `&campaign_id=${entityObj.campaign_id}`;
      }
      return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
    }
    function fetchMetaData() {
      const promise = new Promise(function (resolve) {
        reportMeta.fetch({
          onSuccess() {
            reportMeta.parseMetrics();
            reportModel.setDDLmetricOptions();
            resolve();
          }
        });
      });
      return promise;
    }
    function fetchChartData() {
      const promise = new Promise(function (resolve) {
        collection = new CampaignReport({
          fetchOptions: $.extend({}, fetchOptions, extOptions),
          url: url,
          urlString: `std/${fetchKey}`
        });
        timer("CHART:FETCH:", timerValue, "start");
        collection.fetch({
          onSuccess(resp) {
            timer("CHART:FETCH", timerValue, "stop");
            reportModel.chartData = resp;
            reportModel.setReportInfo(reportModel.get("reportType"), collection.ajxResponseHeaders);
            resolve();
          }
        });
      });
      return promise;
    }
    function abort() {
      if (reportMeta) {
        reportMeta.abort();
      }
      if (collection) {
        collection.abort();
      }
    }
    return {
      fetchMetaData: fetchMetaData,
      fetchChartData: fetchChartData,
      abort: abort
    };
  };
  return CommController;
});
