define('modules/reporting/campaigns/contextual/views/panel',['require','jQuery','Underscore','T1','T1View','collections/campaignReport','T1Layout','T1PerformanceTimer','When','modules/reporting/utils/reportingComm','modules/reporting/campaigns/utils/reportingUtils','text!../templates/panel.html','text!../templates/layout.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const CampaignReport = require("collections/campaignReport");
  const T1Layout = require("T1Layout");
  const T1Timer = require("T1PerformanceTimer");
  const When = require("When");
  const ReportingComm = require("modules/reporting/utils/reportingComm");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  const template = require("text!../templates/panel.html");
  const layout = require("text!../templates/layout.html");
  let comm, timerValue;
  const dateFormat = "yyyy-MM-dd";
  const GAMap = {
    contextualApiLoad: "RPTG:CONTEXTUAL:API:TARGET_NAME",
    filterDimensionsApiLoad: "RPTG:CONTEXTUAL:DIMENSION:API",
    totalLoad: "RPTG:CONTEXTUAL:API:LOAD:TOTAL"
  };
  return T1View.extend({
    template: template,
    section: "contextual",
    dataEvents: {
      reportModel: {
        "change:tempDimension": "updateData",
        "change:tempDim2": "updateData",
        "change:dimension": function () {
          this.changeDimension();
        },
        "change:dim2": function () {
          this.changeDimension();
        },
        "change:filteredOutItems": function () {
          if (this.dim2Key) {
            this.prevDim1Key = undefined;
            this.changeDimension();
          } else {
            T1.EventHub.publish("change:filteredOutItems");
          }
        },
        "change:filteredOutItemsDim2": function () {
          this.prevDim2Key = undefined;
          this.changeDimension();
        }
      },
      campaign: {
        "change:start_end": function () {
          const {
            campaign: campaign,
            reportModel: reportModel
          } = this;
          this.report.start = campaign.get("start");
          this.report.end = campaign.get("end");
          reportModel.setSearchTerm("");
          this.updateData();
        }
      }
    },
    eventhubEvents: {
      "reports:select:campaign": function (args) {
        if (this.currentReportId !== args.id) {
          this.report.id = args.id;
          this.updateData();
        }
      },
      "remove:dim2": function (isReloadNeeded) {
        this.dim2Key = undefined;
        if (isReloadNeeded) {
          this.changeDimension();
        }
      },
      "toggle:loader": function (show) {
        this.toggleLoader(show);
      }
    },
    initialize(args) {
      let reportModel;
      const dailyFetchOptions = {
        dimensions: "target_name",
        filter: "campaign_id=",
        precision: 2,
        time_rollup: "by_day"
      };
      comm = new ReportingComm({
        id: "contextual"
      });
      const cfgs = this.cfgs = args;
      cfgs.dataGroup = comm.dataGroup;
      this.report = cfgs.report;
      const models = cfgs.models;
      this.mainModel = models.mainModel;
      this.reportModel = reportModel = models.reportModel;
      this.currentReportId = this.report.id;
      this.parentClass = cfgs.parentClass;
      this.campaign = models.campaign;
      const dimensionInfo = reportModel.getDimensionInfo(false);
      this.dimensionData = dimensionInfo.key;
      cfgs.type = "summary";
      const focus = reportModel.get("focus");
      if (focus === "all") {
        this.filterDimension = comm.buildCollection("filterDimension", models.contextualFilterDimension);
        cfgs.collection = this.collection = comm.buildCollection("collection", models.contextualCollection);
      } else {
        const strategyChannelFilter = `target_name!="_root_"&strategy_channel="${focus.toUpperCase()}"&campaign_id=`;
        models.contextualFilterStrategyChannelDimension = $.extend(true, {}, models.contextualFilterDimension);
        models.contextualFilterStrategyChannelDimension.fetchOptions.filter = strategyChannelFilter;
        models.contextualStrategyChannelCollection = $.extend(true, {}, models.contextualCollection);
        models.contextualStrategyChannelCollection.fetchOptions.filter = strategyChannelFilter;
        this.filterDimension = comm.buildCollection("filterDimension", models.contextualFilterStrategyChannelDimension);
        cfgs.collection = this.collection = comm.buildCollection("collection", models.contextualStrategyChannelCollection);
      }
      cfgs.dailyCollection = this.dailyCollection = comm.buildCollection("dailyCollection", new CampaignReport({
        urlString: "std/contextual_insights",
        fetchOptions: dailyFetchOptions,
        url: function () {
          const opts = $.extend({}, this.fetchOptions);
          opts.filter += String(this.id);
          return `${T1.RPT_API_ROOT}${this.urlString}?${$.param(opts)}`;
        }
      }));
      this.updateFetchOptionsDate(dailyFetchOptions);
      this.initLayouts();
      this.setGetDataOnce();
    },
    initLayouts() {
      this.layout = new T1Layout({
        el: () => this.$(".w-report-panel"),
        template: layout,
        layout: {
          ".report-chart": [{
            module: "reporting/campaigns/contextual",
            viewType: "chartPanel",
            options: {
              config: this.cfgs,
              parentClass: this
            }
          }],
          ".report-controls": [{
            module: "reporting/campaigns/contextual",
            options: this.cfgs,
            viewType: "controls"
          }],
          ".report-grid": [{
            module: "reporting/campaigns/contextual",
            options: this.cfgs,
            viewType: "grid"
          }]
        }
      });
      this.layout.serialize = () => ({
        chartHidden: !this.chartShown
      });
    },
    load() {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const dimensionInfo = reportModel.getDimensionInfo(false);
      if (mainModel.leftTheSection(this.section)) {
        return;
      }
      this.focus = reportModel.get("focus") || "all";
      this.dimensionData = dimensionInfo.key;
      this.chartShown = mainModel.get("chartShown") === "true";
      this.getDateFetchOptions();
      mainModel.setTrackingSection("Reporting - Contextual");
      if (this.needsDataLoad()) {
        reportModel.resetColorData();
        this.setGetDataOnce();
      } else {
        reportModel.setCollectionFetchParams(this.collection.fetchOptions);
      }
      this.unloaded = false;
      this.layout.unload();
      this.render().then(() => {
        this.layout.load();
        this.$loader = this.parentClass.el.find(".loader-holder");
        this.getDataOnce();
        this.prevFocus = this.focus;
        this.prevDimensionData = this.dimensionData;
        this.prevDateOption = this.dateOption;
      });
    },
    toggleLoader(show) {
      const animTime = 200;
      if (show) {
        this.$loader.fadeIn(animTime);
      } else {
        this.$loader.fadeOut(animTime);
      }
    },
    changeDimension() {
      this.reportModel.resetHiddenItems();
      this.reportModel.setSearchTerm("");
      this.updateData();
    },
    getData() {
      let dim, dim2, dim2FilteredItems, dim2FilteredOutCount, dim2Key, dim2UrlParams, dimKey, originalFilter, originalFilterIndex;
      const self = this;
      const {
        filterDimension: filterDimension,
        collection: collection,
        dailyCollection: dailyCollection,
        reportModel: reportModel,
        cfgs: cfgs,
        timer: timer,
        report: report
      } = this;
      const reportID = report.id;
      const models = cfgs.models;
      const dimensionInfo = reportModel.get("dimensionInfo");
      const tempDimension = reportModel.get("tempDimension");
      const dim2Info = reportModel.get("dim2Info");
      const tempDim2 = reportModel.get("tempDim2");
      const tempDimensionInfo = reportModel.get("tempDimensionInfo");
      const tempDim2Info = reportModel.get("tempDim2Info");
      const section = "contextual_insights";
      const doLoadDim2 = Boolean(tempDim2Info && tempDim2Info.key || dim2Info && dim2Info.key);
      let dimensionName = "";
      let dimensionName2 = "";
      let dimName = "";
      let dim2Name = "";
      let silent = false;
      const filterDimensionDeferred = When.defer();
      const summaryDeferred = When.defer();
      const dailyDeferred = When.defer();
      const promises = [filterDimensionDeferred.promise, summaryDeferred.promise, dailyDeferred.promise];
      const campaignFetchOptionsExtend = {};
      let dimOptions = "";
      const focus = reportModel.get("focus") || "all";
      if (!reportID) {
        return;
      }
      if (!(tempDim2Info && tempDim2Info.key)) {
        this.toggleLoader(true);
      }
      this.prevDateOption = this.dateOption;
      const dateFetchOptions = this.getDateFetchOptions();
      this.updateFetchOptionsDate(campaignFetchOptionsExtend);
      this.campaignFetchOptionsExtend = campaignFetchOptionsExtend;
      if (tempDimensionInfo) {
        dimensionName = tempDimensionInfo.dimension;
      } else if (dimensionInfo) {
        dimensionName = dimensionInfo.dimension;
      }
      dimName = dimensionName.toUpperCase().replace(/[ ]/g, "_");
      reportModel.resetDateRangeChanged();
      const dateRangeChanged = this.prevDateOption !== undefined && this.prevDateOption !== this.dateOption;
      if (dateRangeChanged) {
        reportModel.resetOnDateRangeChange();
      }
      this.currentReportId = reportID;
      if (tempDimension) {
        this.dimensionData = tempDimensionInfo.key;
        silent = true;
      }
      if (doLoadDim2) {
        if (tempDim2Info) {
          dimensionName2 = tempDim2Info.dimension;
        } else if (dim2Info) {
          dimensionName2 = dim2Info.dimension;
        }
        dim2Name = dimensionName2.toUpperCase().replace(/[ ]/g, "_");
        if (tempDim2) {
          this.dim2Key = dim2Key = tempDim2Info.key;
          silent = true;
        }
        if (tempDim2 && tempDim2Info) {
          dim2 = tempDim2Info.dimension;
          this.dim2Key = dim2Key = tempDim2Info.key;
        } else if (dim2Info) {
          reportModel.setCollectionFetchParams(filterDimension.fetchOptions);
          dim2 = dim2Info.dimension;
          this.dim2Key = dim2Key = dim2Info.key;
          if (this.prevDim2Key && this.prevDim2Key !== this.dim2Key) {
            reportModel.set({
              filteredInItemsDim2: null,
              filteredOutCountDim2: 0
            }, {
              silent: true
            });
          }
        }
      }
      comm.reportID = reportID;
      comm.urlString = `std/${section}`;
      comm.resetDataItem("filterDimension", dateFetchOptions);
      comm.resetDataItem("collection", dateFetchOptions);
      comm.resetDataItem("dailyCollection", dateFetchOptions);
      this.dailyCollection.comparator = function (model) {
        return model.get("start_date");
      };
      if (!tempDimension) {
        reportModel.setCollectionFetchParams(collection.fetchOptions);
        dim = dimensionInfo.dimension;
        dimKey = dimensionInfo.key;
      } else {
        dim = tempDimensionInfo.dimension;
        this.dim1Key = dimKey = tempDimensionInfo.key;
      }
      if (!(tempDim2 && tempDim2Info)) {
        dim2FilteredItems = reportModel.get("filteredInItemsDim2") || [];
        dim2FilteredOutCount = reportModel.get("filteredOutCountDim2") || 0;
        if (dim2FilteredItems.length) {
          dim2FilteredItems = reportModel.getEscapedNames(dim2FilteredItems);
        }
        dim2UrlParams = dim2Key && dim2FilteredItems.length && dim2FilteredOutCount > 0 ? `${dim2Key}=${dim2FilteredItems.toString()}&` : "";
        originalFilterIndex = collection.fetchOptions.filter.lastIndexOf("target_name");
        originalFilter = collection.fetchOptions.filter.substring(originalFilterIndex);
        collection.fetchOptions.filter = dim2UrlParams + originalFilter;
        dailyCollection.fetchOptions.filter = dim2UrlParams + originalFilter;
      }
      filterDimension.fetchOptions.dimensions = dim2 === dim2Key ? dim2 : `${dim2},${dim2Key}`;
      dimOptions = dim === dimKey ? dim : `${dim},${dimKey}`;
      collection.fetchOptions.dimensions = `${dimOptions},path`;
      dailyCollection.fetchOptions.dimensions = `${dimOptions},path`;
      timerValue = reportID;
      this.abortAjaxCalls();
      timer(GAMap.totalLoad, timerValue, "start");
      function loadFilterDimensionHandler() {
        timer(GAMap.filterDimensionsApiLoad + dim2Name, timerValue, "start");
        filterDimension.loaded = false;
        if (tempDimension) {
          models.tempDim1Coll = filterDimension;
        }
        filterDimension.fetch({
          onSuccess: function () {
            timer(GAMap.filterDimensionsApiLoad + dim2Name, timerValue, "stop");
            filterDimension.loaded = true;
            self.dimensionData = null;
            reportModel.setDimensionItems({
              applying: false,
              dim1Data: null,
              dim1FilteredData: null,
              dim2Data: filterDimension
            });
            filterDimensionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`filterDimension fetch failed: ${error}`);
          }
        });
      }
      function loadContextualHandler() {
        timer(GAMap.contextualApiLoad + dimName, timerValue, "start");
        collection.loaded = false;
        collection.fetch({
          silent: silent,
          onSuccess: function (collectionData) {
            timer(GAMap.contextualApiLoad + dim2Name, timerValue, "stop");
            self.collectionData = collectionData;
            collection.loaded = true;
            reportModel.setReportInfo(`${section}-${focus}`, collection.ajxResponseHeaders);
            summaryDeferred.resolve();
            loadDailyContextualDataHandler();
          },
          onError: function (error) {
            throw new Error(`targetName fetch failed: ${error}`);
          }
        });
      }
      function loadDailyContextualDataHandler() {
        timer(GAMap.siteDomainsDailyApiLoad + dimName, timerValue, "start");
        dailyCollection.loaded = false;
        dailyCollection.fetch({
          silent: silent,
          onSuccess: function () {
            timer(GAMap.siteDomainsDailyApiLoad + dimName, timerValue, "stop");
            dailyCollection.loaded = true;
            dailyDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`targetNameDaily fetch failed: ${error}`);
          }
        });
      }
      if (!tempDimension && (dim2Info || tempDim2Info)) {
        loadFilterDimensionHandler();
      } else {
        filterDimension.loaded = true;
        filterDimensionDeferred.resolve();
      }
      if (tempDim2 && tempDim2Info) {
        collection.loaded = true;
        summaryDeferred.resolve();
      } else {
        reportModel.set({
          filteredInCount: 0,
          filteredInItems: null
        });
        loadContextualHandler();
      }
      When.all(promises).then(() => {
        if (this.mainModel.leftTheSection(this.section)) {
          return;
        }
        timer(GAMap.totalLoad, timerValue, "stop");
        if (tempDimension) {
          this.dimensionData = null;
          reportModel.setDimensionItems({
            applying: false,
            dim1Data: filterDimension,
            dim1FilteredData: null,
            dim2Data: null
          });
        } else {
          if (!tempDim2) {
            reportModel.setColorData(this.collectionData);
            reportModel.setDimensionItems({
              applying: !tempDimension,
              dim1Data: collection,
              dim1FilteredData: null,
              dim2Data: null
            });
            if (comm.dataGroup.dailyCollection.models.length) {
              reportModel.setTimeSeriesSlots(this.campaignFetchOptionsExtend, "contextual_insights");
            }
          }
          T1.EventHub.publish("responseHeadersReady", reportModel.get("reportInfo").reportTypes[`${section}-${focus}`]);
        }
        this.toggleLoader(false);
      });
    },
    setGetDataOnce() {
      this.getDataOnce = _.once(this.getData);
    },
    updateData() {
      this.setGetDataOnce();
      this.getDataOnce();
    },
    needsDataLoad() {
      return this.unloaded || this.currentReportId !== this.report.id || this.prevFocus !== this.focus || this.prevDim1Key !== this.dim1Key || this.prevDim2Key !== this.dim2Key || this.prevDateOption !== this.dateOption;
    },
    updateFetchOptionsDate(fetchOptions) {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      if (reportModel.get("startDate")) {
        fetchOptions.start_date = mainModel.getDateString(reportModel.get("startDate"));
        fetchOptions.end_date = mainModel.getDateString(reportModel.get("endDate"));
      } else {
        fetchOptions.start_date = Utils.defaultStartDate.toString(dateFormat);
        fetchOptions.end_date = Utils.defaultEndDate.toString(dateFormat);
      }
    },
    getDateFetchOptions() {
      let end_date, start_date;
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      if (reportModel.get("startDate")) {
        start_date = mainModel.getDateString(reportModel.get("startDate"));
        end_date = mainModel.getDateString(reportModel.get("endDate"));
      } else {
        start_date = Utils.defaultStartDate.toString(dateFormat);
        end_date = Utils.defaultEndDate.toString(dateFormat);
      }
      this.dateOption = `${start_date}:${end_date}`;
      return {
        start_date: start_date,
        end_date: end_date
      };
    },
    timer(category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    },
    abortAjaxCalls() {
      if (comm) {
        comm.abortAjaxCalls(["filterDimension", "collection"]);
      }
    },
    unload() {
      this.unloaded = true;
    }
  });
});
