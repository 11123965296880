define('modules/targeting/pixel/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "utils/CampaignStrategyUtils", "text!modules/targeting/pixel/templates/panel.html", "text!modules/targeting/pixel/templates/panel_layout.html", "text!modules/targeting/audience/templates/info_box.html", "text!templates/form_footer.html"], function ($, _, T1, T1View, T1Layout, CampaignStrategyUtils, template, panel_layout, info_box, form_footer) {
  "use strict";

  let previousList;
  var maxPixels = COMPASS_ENV.TARGETING_MAX_PIXELS;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  const providerTaxTimeToRead = 6e3;
  const providerTaxMsgState = {
    isDisplayed: false
  };
  const providerTaxWarningMessage = `Sales taxes may be charged for Pixels from this provider.\n  This will be included in the MM Data Cost in reporting and invoices.`;
  var PixelPanel = T1View.extend({
    template: template,
    viewMode: "browse",
    partials: {
      form_footer: form_footer
    },
    dataEvents: {},
    providerTaxTimeout: null,
    eventhubEvents: {
      "assigned:pixel": "assignPixel",
      "strategy-create-edit:savedPixelBundels": "updateTempCollection",
      "bins.providerTaxAdded": function () {
        if (this.providerTaxTimeout) {
          clearTimeout(this.providerTaxTimeout);
        }
        this.toggleUsageWarningInfo(true, {
          node: "providerTax"
        });
        this.providerTaxTimeout = setTimeout(() => {
          this.toggleUsageWarningInfo(false, {
            node: "providerTax"
          });
        }, providerTaxTimeToRead);
      }
    },
    events: {
      "click .usage-warning-info .close": "toggleUsageWarningInfo",
      "focus .text": "showTooltip",
      "blur .text": "hideTooltip",
      "keydown .text": "removeTooltip"
    },
    initialize: function ({
      strategy: strategy
    }) {
      var self = this;
      this.strategy = strategy;
      this.strategy.getOrgId().then(orgId => {
        this.orgId = orgId;
      });
      this.strategy.fetchPixelTargets(function (collection) {
        self.sharedCollection = collection;
        self.sharedCollection.tempCollection = [];
        self.updateTempCollection();
        collection.id = self.strategy.id;
        self.advertiser = self.strategy.get("campaign").get("advertiser").toJSON();
        self.initLayout();
        if (!self.layout.loaded) {
          self.layout.load();
        }
        self.addDataEvent({
          sharedCollection: {
            add: function () {
              this.isFormElementsChanged = true;
            },
            remove: function () {
              this.isFormElementsChanged = true;
            },
            reset: function () {
              this.isFormElementsChanged = false;
              this.load();
            }
          }
        });
      });
    },
    toggleUsageWarningInfo: function (e, opts) {
      const defaultTopMargin = 10;
      const defaultSpacing = 25;
      let newTopMargin = defaultTopMargin;
      const canShow = e === true;
      const action = canShow ? "show" : "hide";
      const provider = opts && opts.node || e.currentTarget.parentNode.className.replace(/\s*(?:usage-warning-info|info-box)\s*/g, "");
      const $listBody = this.$(".list-c");
      const $infoEls = this.$(".usage-warning-info");
      const $providerTaxMsg = $infoEls.filter(".providerTax");
      if (typeof e === "object") {
        this.preventDefault(e);
      }
      switch (provider) {
        case "providerTax":
          providerTaxMsgState.isDisplayed = canShow;
          $providerTaxMsg[action]();
          break;
      }
      const providerTaxMsgHeight = providerTaxMsgState.isDisplayed ? $providerTaxMsg.height() : 0;
      const msgsDisplaying = [providerTaxMsgState].filter(state => state.isDisplayed);
      const spaceBelowInfo = msgsDisplaying.length ? defaultSpacing * msgsDisplaying.length : 0;
      newTopMargin += providerTaxMsgHeight + spaceBelowInfo;
      $listBody.css("top", `${newTopMargin}px`);
    },
    showTooManyPixelsMsg: function () {
      this.updateFooterMessage({
        warning: true,
        message: `You may not make more than ${maxPixels} selections.`
      });
    },
    assignPixel: function (data) {
      let oldAction;
      var sharedCollection = this.sharedCollection;
      var selectedList = data.selectedList;
      if (selectedList && JSON.stringify(selectedList) === JSON.stringify(previousList) && selectedList.every(x => sharedCollection.get(x.id))) {
        return;
      }
      previousList = selectedList;
      this.updateFooterMessage({
        warning: false,
        message: ""
      });
      if (selectedList) {
        sharedCollection.remove(selectedList);
        if (sharedCollection.length < maxPixels) {
          sharedCollection.add(selectedList);
          this.isFormElementsChanged = true;
          this.checkUnsavedState();
        } else {
          this.showTooManyPixelsMsg();
        }
      } else {
        const itemInSharedCollection = sharedCollection.get(data.id);
        const newAction = data.action || data.get("action");
        if (itemInSharedCollection) {
          oldAction = itemInSharedCollection.get("action");
        }
        sharedCollection.remove(data);
        if (oldAction && newAction === oldAction) {
          return;
        }
        if (sharedCollection.length < maxPixels) {
          sharedCollection.add(data);
          this.isFormElementsChanged = true;
          this.checkUnsavedState();
        } else {
          this.showTooManyPixelsMsg();
        }
      }
    },
    load: function () {
      var self = this;
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(function () {
        const $entityInput = self.$(".select-input");
        T1.EventHub.publish("strategy-badges-edit:placementCheck", {
          currentView: "pixel_targeting"
        });
        T1.Selectbox($entityInput, {
          onChange: function () {
            var term = this.$(".term input").val().trim();
            const minSearchLength = 2;
            if (term.length >= minSearchLength) {
              T1.EventHub.publish("searchAudience", {
                term: term,
                provider_id: self.$(".providers").val()
              });
            }
          }
        });
        if (self.layout) {
          self.layout.load().then(function () {
            self.$(".search-c").hide();
            self.loaded = true;
          });
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(self.strategy.get("campaign"));
          self.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          self.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    updateTempCollection: function () {
      const origColl = this.strategy.pixel_bundles;
      const tempColl = {
        data: [],
        includeOp: this.strategy.pixel_bundles.includeOperator
      };
      origColl.each(function (pixelModel) {
        tempColl.data.push(pixelModel.clone());
      });
      this.sharedCollection.tempCollection = tempColl;
    },
    refreshDDL: function () {
      this.renderPartial(".select-input");
      this.$(".select-input").trigger("liszt:updated");
    },
    initLayout: function () {
      const advertiser = this.advertiser;
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panel_layout,
        partials: {
          info_box: info_box
        },
        layout: {
          ".column-A": [{
            module: "pixel",
            viewType: "list",
            options: {
              orgId: this.orgId,
              searchViewType: "filterSearch",
              sharedCollection: this.sharedCollection,
              allowMultipleSelection: true,
              strategy: this.strategy,
              getAdvertiser: () => advertiser,
              getAdvertiserId: () => advertiser.id,
              getAdvertiserName: () => advertiser.name
            }
          }],
          ".bins-column": [{
            module: "targeting/pixel",
            viewType: "bins",
            options: {
              sharedCollection: this.sharedCollection
            }
          }]
        },
        serialize: function () {
          return {
            msgForProviderTax: {
              infoBoxMessageLines: providerTaxWarningMessage,
              infoMessageCanClose: true,
              infoBoxClassName: "usage-warning-info providerTax"
            }
          };
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    unload() {
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    onDontSave: function () {
      this.sharedCollection.reset([]);
      this.strategy.fetchPixelTargets($.noop, false);
    },
    onVersionConflictClose: function () {
      this.sharedCollection.reset([]);
      this.strategy.fetchPixelTargets($.noop, false);
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    saveOverride: function (e, callbacks) {
      if (this.sharedCollection.length <= maxPixels) {
        return this.saveOriginal(e, callbacks);
      }
      this.showTooManyPixelsMsg();
      if (callbacks && callbacks.invalid) {
        callbacks.invalid();
      }
    },
    postData: function (formValues, successCallback, statusInvalidCallback, conflict) {
      this.strategy.savePixelTargets(successCallback, statusInvalidCallback, conflict);
    }
  });
  PixelPanel = T1.Form.ViewUtils.extend(PixelPanel);
  PixelPanel.prototype.saveOriginal = PixelPanel.prototype.save;
  PixelPanel.prototype.save = PixelPanel.prototype.saveOverride;
  return PixelPanel;
});
