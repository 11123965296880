define('collections/sitePlacements',["Underscore", "T1", "T1Collection", "T1Model", "models/publisher"], function (_, T1, T1Collection, T1Model, Publisher) {
  T1.Models.SitePlacements = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    model: Publisher,
    urlString: "publishers",
    fetchOptions: {
      full: "*",
      with: "sites,placements"
    },
    setOrgId: function (id, mediaType) {
      this.fetchOptions.q = "organization_id==" + id + "&sites.placements.media_type==" + mediaType;
    },
    parse: function () {
      var parsedData = T1Collection.prototype.parse.apply(this, arguments);
      return this.parsePublishers(parsedData);
    },
    parsePublishers: function (models) {
      var publisher;
      var site;
      var siteObj;
      var placementObj;
      var placement;
      var id;
      var parsedItems = [];
      function parseList(items, parse) {
        _.each(items, function (item) {
          parse(item);
        });
      }
      function chooseAction(obj, itemAction) {
        if (_.isArray(obj)) {
          parseList(obj, itemAction);
        } else if (obj) {
          itemAction(obj);
        }
      }
      function parsePlacement(item) {
        placement = item.display_text;
        id = item.id;
        if (placement) {
          parsedItems.push({
            publisher: publisher,
            site: site,
            placement: placement,
            id: id,
            type: "site_placement"
          });
        }
      }
      function parseSite(item) {
        site = item.name;
        placementObj = item.site_placement;
        chooseAction(placementObj, parsePlacement);
      }
      function parsePublisher(model) {
        publisher = model.name;
        siteObj = model.publisher_site;
        chooseAction(siteObj, parseSite);
      }
      parseList(models, parsePublisher);
      return parsedItems;
    },
    sortPlacements: function (sortKey, sortOrder) {
      this.models = _.sortBy(this.models, function (model) {
        return model.get(sortKey).toLowerCase();
      });
      if (sortOrder === -1) {
        this.models.reverse();
      }
      this.trigger.apply(this, ["sort"]);
      this.trigger.apply(this, ["reset"]);
    },
    searchPlacements: function (term, fieldName) {
      var filtered;
      if (!this.browseModels) {
        this.browseModels = this.models;
      }
      if (term && fieldName) {
        term = term && term.toString ? term.toString() : term + "";
        term = term.toLowerCase();
        fieldName = fieldName && fieldName.toString ? fieldName.toString() : fieldName + "";
        filtered = _.filter(this.browseModels, function (model) {
          var searched = model.get(fieldName).toLowerCase();
          return searched.indexOf(term) > -1;
        });
        this.reset(filtered);
      } else {
        this.reset(this.browseModels);
      }
    }
  });
  return T1.Models.SitePlacements;
});
