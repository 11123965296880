define('models/concept',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  T1.Models.Concept = T1Model.extend({
    url: "concepts/",
    fixture: "-concept",
    canEdit: function () {
      var def = $.Deferred();
      def.resolve(true);
      return def.promise();
    }
  });
  return T1.Models.Concept;
});
