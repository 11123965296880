define('T1PerformanceTimer',["T1", "models/timer", "models/sessionUser"], function (T1, TimeLogger, User) {
  "use strict";

  var T1Timer;
  if (T1.PERFORMANCE_TIMER) {
    return T1.PERFORMANCE_TIMER;
  }
  function getId(identifier) {
    var id = "";
    return id + identifier.category + identifier.variable + identifier.label;
  }
  T1Timer = {
    testMap: {},
    user: User.getUser(),
    clear: function (id) {
      this.testMap[getId(id)] = null;
    },
    start: function (identifier) {
      var timer;
      var id;
      var startTime;
      if (!identifier.category || !identifier.label) {
        return -1;
      }
      identifier.variable = this.user.get("name");
      id = getId(identifier);
      this.clear(id);
      timer = new TimeLogger(identifier.category, identifier.variable, identifier.label);
      startTime = timer.start();
      this.testMap[id] = timer;
      return startTime;
    },
    stop: function (identifier) {
      var timer;
      var id;
      var result;
      identifier.variable = this.user.get("name");
      id = getId(identifier);
      if (!this.testMap[id]) {
        return -1;
      }
      timer = this.testMap[id];
      if (timer) {
        timer.stop();
        result = timer.log();
        delete this.testMap[id];
        return result;
      }
    }
  };
  T1.PERFORMANCE_TIMER = T1Timer;
  return T1.PERFORMANCE_TIMER;
});
