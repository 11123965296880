define('modules/strategy/views/helpers/pixelSummaryTarget',["Underscore", "jQuery", "T1", "utils/CampaignStrategyUtils"], function (_, $, T1, Utils) {
  "use strict";

  const T1hasOwnProp = T1.Utils.hasOwnProp;
  return {
    prepareForSerialize: function (strategyModel, collection, pixelTitle) {
      let includeOperator, targetCollection;
      var collObj = collection;
      var resultJSON = {
        data: []
      };
      var firstEntry = true;
      var groupedDataHash = {
        DATA: {},
        EVENT: {},
        SEGMENT: {},
        CREATIVE: {},
        INVALID: {},
        DYNAMIC: {}
      };
      var getGroupNameFromModel = Utils.getGroupNameFromPixelModel;
      var formatData = function (dataObj) {
        var dataArr = [];
        for (const key in dataObj) {
          if (T1hasOwnProp(dataObj, key)) {
            const tempDataArr = dataObj[key];
            if (!$.isEmptyObject(tempDataArr)) {
              for (const i in tempDataArr) {
                if (T1hasOwnProp(tempDataArr, i)) {
                  dataArr.push({
                    label: tempDataArr[i].name,
                    action: tempDataArr[i].action,
                    boldText: tempDataArr[i].boldText
                  });
                }
              }
            }
          }
        }
        dataArr.sort(sortIncEx);
        return dataArr;
      };
      var sortIncEx = function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      };
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/targeting/myData`;
      }
      if (!collObj || !collObj.models || !collObj.models.length && collObj.tempCollection && !collObj.tempCollection.data.length) {
        return resultJSON;
      }
      if (collObj.tempCollection) {
        targetCollection = collObj.tempCollection.data;
        includeOperator = collObj.tempCollection.includeOp;
      } else {
        targetCollection = collObj.models;
        includeOperator = collection.includeOperator;
      }
      const groupedCollObj = _.groupBy(targetCollection, model => getGroupNameFromModel(model));
      for (const item in groupedCollObj) {
        if (T1hasOwnProp(groupedCollObj, item)) {
          const key = item;
          const tmpArr = groupedCollObj[key];
          for (const itemKey in tmpArr) {
            if (T1hasOwnProp(tmpArr, itemKey)) {
              const tmpObj = tmpArr[itemKey].toJSON();
              if (tmpObj.unsavedAdd) {
                continue;
              }
              let type = tmpArr[itemKey].get("pixel_type");
              type = type ? type.toUpperCase() : "INVALID";
              const targetArrHolder = groupedDataHash[type];
              if (!targetArrHolder[key]) {
                targetArrHolder[key] = [];
              }
              if (tmpObj.advertiser) {
                targetArrHolder[key].push({
                  action: tmpObj.action,
                  name: `${tmpObj.advertiser.name} &raquo `,
                  boldText: tmpObj.name
                });
              } else if (tmpObj.agency) {
                targetArrHolder[key].push({
                  action: tmpObj.action,
                  name: `${tmpObj.agency.name} &raquo `,
                  boldText: tmpObj.name
                });
              }
            }
          }
        }
      }
      for (let keyVal in groupedDataHash) {
        if (T1hasOwnProp(groupedDataHash, keyVal)) {
          const tempData = groupedDataHash[keyVal];
          keyVal = pixelTitle[keyVal] || keyVal;
          if (!$.isEmptyObject(tempData)) {
            if (firstEntry) {
              firstEntry = false;
              resultJSON.data.push({
                header: keyVal.capitalize(),
                items: formatData(tempData),
                includeOP: `(${includeOperator})`
              });
            } else {
              resultJSON.data.push({
                header: keyVal.capitalize(),
                items: formatData(tempData)
              });
            }
          }
        }
      }
      return resultJSON;
    }
  };
});
