define('modules/T1App/edit/access/users/views/users',["jQuery", "Underscore", "T1", "T1View", "T1Comm", "T1Layout", "text!modules/T1App/edit/access/users/templates/users.html", "text!modules/T1App/edit/access/users/templates/userLayout.html", "collections/appsAccessUsers", "../../models/accessUsersModel", "text!templates/form_footer.html", "models/sessionUser", "T1Model"], function ($, _, T1, T1View, T1Comm, T1Layout, template, userLayout, AppsAccessUsers, StateModel, form_footer, User) {
  "use strict";

  var T1Location = T1.Location;
  var AccessUsersView;
  AccessUsersView = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Creatives",
        contentClass: "search-loader loader-overrides",
        action: "append"
      }
    },
    template: template,
    partials: {
      footer: form_footer
    },
    eventhubEvents: {
      "change:formElements": "updateFormElementsChanged"
    },
    events: {
      "click .saveAndClose": "saveAndClose",
      "click .saveAndNew": "saveOverride"
    },
    actionsConfig: {
      formActions: [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#appstore/myApps"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: false
      }, {
        label: "save & Close",
        uuid: "save_close",
        class: "saveAndClose save-btn-primary",
        location: false
      }]
    },
    initialize: function () {
      var self = this;
      var locationHashArray = T1Location.get(true).split("/");
      var user = User.getUser();
      this.appID = T1.Utils.escapeHTML(locationHashArray[2]);
      this.model = new AppsAccessUsers();
      this.stateModel = new StateModel();
      this.stateModel.appID = self.appID;
      this.userID = parseInt(user.get("id"), 10);
      this.model.fetch({
        appID: self.appID,
        success: function (data) {
          self.stateModel.accessUsers = data.data[0].users;
          self.stateModel.selectedUsers(data.data[0].users);
          self.loadPage();
        }
      });
      this.initLayout();
    },
    loadPage: function () {
      var self = this;
      this.render().then(function () {
        self.layout.load();
      });
    },
    postData: function (formValues, success, statusInvalid, conflict) {
      var stateModel = this.stateModel;
      var self = this;
      stateModel.set({
        app_id: self.appID
      });
      stateModel.save(stateModel.toJSON(), {
        dataType: "json",
        statusInvalid: function () {},
        success: function () {
          self.stateModel.unset("addToDev");
          self.stateModel.unset("removeFromDev");
          success(stateModel);
        },
        conflict: conflict,
        error: function (data) {
          var errors = $.parseJSON(data.responseText);
          var email = "";
          var msg = "";
          var accessUsers = stateModel.accessUsers;
          var users = "";
          $.each(errors.data, function (index, value) {
            users = users + value.user_id + " ";
            $("strand-icon#" + value.user_id).addClass("selected");
            $(".dev-team-container").append('<div id="' + value.user_id + '" class="dev-team">' + value.user_name + '<strand-icon class="icon remove-dev pull-right" type="delete" width="10" height="10"></mm-icon></div>');
            for (var i = 0; i < accessUsers.length; i++) {
              if (parseInt(accessUsers[i].id, 10) === parseInt(value.user_id, 10)) {
                accessUsers[i].classlist = "selected";
              }
            }
            msg = "Some errors occured with the following users: " + users;
            return accessUsers;
          });
          self.stateModel.unset("removeFromDev");
          statusInvalid(errors, msg);
        },
        contentType: "application/json",
        processData: false,
        stringify: true
      });
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          T1.Notify("message", "App updated successfully!");
          setTimeout(function () {
            T1.Location.set("#appstore");
          }, 50);
        }
      };
      return this.saveOriginal(e, callback);
    },
    saveOverride: function (e, customCallbacks) {
      var callback = customCallbacks || {
        success: function () {
          T1.Notify("message", "App updated successfully!");
        }
      };
      return this.saveOriginal(e, callback);
    },
    updateFormElementsChanged: function (msg) {
      this.updateFooterMessage({
        warning: true,
        message: msg
      });
      this.isFormElementsChanged = true;
    },
    initLayout: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".access-container",
        template: userLayout,
        layout: {
          ".bin-holder": [{
            module: "T1App/edit/access/users",
            viewType: "userBin",
            options: {
              model: self.stateModel
            }
          }],
          ".grid-holder": [{
            module: "T1App/edit/access/users",
            viewType: "usersGrid",
            options: {
              model: self.stateModel
            }
          }],
          ".search-holder": [{
            module: "T1App/edit/access/users",
            viewType: "userSearch",
            options: {
              model: self.stateModel
            }
          }]
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        var title = $("div.strand-header").html();
        this.openUnloadAlertDialog({
          name: title
        }, onTabChange);
      }
      return !isFormElementsChanged;
    }
  });
  AccessUsersView = T1.Form.ViewUtils.extend(AccessUsersView);
  AccessUsersView.prototype.saveOriginal = AccessUsersView.prototype.save;
  AccessUsersView.prototype.save = AccessUsersView.prototype.saveOverride;
  return AccessUsersView;
});
