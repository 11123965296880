define('modules/audiences/views/tabs',["jQuery", "Underscore", "T1", "T1View", "T1TabLayoutV2", "T1Permissions", "models/audience", "text!modules/audiences/templates/tabs.html", "jQueryUI"], function ($, _, T1, T1View, T1TabLayout, T1Permissions, AudiencesModel, template) {
  "use strict";

  return T1View.extend({
    template: template,
    model: new AudiencesModel(),
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    initialize: function (args) {
      this.canLoad = true;
      this.initLayout(args);
      T1.EventHub.publish("updateNavPath", {});
    },
    canUnload: function () {
      return true;
    },
    initLayout: function (args) {
      var self = this;
      var tabs = [];
      var tabSetName = "AudiencesList";
      if (T1Permissions.check("feature", "pixels")) {
        tabs.push({
          label: "Pixels",
          module: "audiences/pixels",
          routeName: "pixels",
          viewType: "",
          options: args
        });
      }
      tabs.push({
        label: "Onboard",
        module: "audiences/onboarding",
        routeName: "onboard",
        viewType: "",
        options: args
      });
      tabs.push({
        label: "Segments",
        module: "audiences/segments",
        routeName: "segments",
        viewType: "",
        options: args
      });
      if (T1Permissions.check("feature", "lookalikes")) {
        tabs.push({
          label: "Lookalikes",
          module: "audiences/lookalikes",
          routeName: "lookalikes",
          viewType: "",
          options: args
        });
      }
      this.layout = new T1TabLayout({
        tabSetName: tabSetName,
        el: function () {
          return self.el.find(".audiences-tabs");
        },
        level: 1,
        disabled: [],
        tabs: tabs,
        ignoreRouteDepth: true,
        selected: this.model.get("mainTabSectionId") || 0
      });
    },
    load: function () {
      var self = this;
      if (!this.canLoad) {
        return;
      }
      this.render().then(function () {
        self.layout.el = $(self.el).find(".audiences-main-tabs");
        self.layout.load();
      });
    },
    saveSelectedTab: function (opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, function (tab) {
        return tab.label === selectedLabel;
      }), tabs);
      this.model.setMainTabSection(selectedIndex);
    }
  });
});
