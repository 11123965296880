define('modules/media/closedSupply/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "media/closedSupply",
      viewCfgs: {
        "pmp-d": {},
        grid: {}
      },
      defaultView: "closedSupply"
    });
  }
  return instance;
});
