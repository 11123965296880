define('models/charity',["T1", "T1Model", "Backbone"], function (T1, T1Model, Backbone) {
  T1.Models.Charity = T1Model.extend({
    url: "charity/",
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.Charity;
});
