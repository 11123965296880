define('appLoad',["jQuery", "Underscore", "T1", "T1Layout", "T1UtilsAsync", "router", "app.config", "router.config"], function ($, _, T1, T1Layout, utilsAsync, Router, appCfg, routerCfg) {
  "use strict";

  function isBrowserCompatible() {
    const lowestAllowedIEVersion = 9;
    const currentBrowserVersion = parseInt($.browser.version, 10);
    const isIE = $.browser.msie;
    return !isIE || currentBrowserVersion >= lowestAllowedIEVersion;
  }
  function getAppCfgKey() {
    const hashRoute = T1.Location.get();
    const baseRoute = hashRoute.substr(1);
    if (hashRoute === "" || hashRoute === "#" || hashRoute === "#index") {
      return "app";
    }
    return baseRoute.indexOf("/") > -1 ? baseRoute.split("/")[0] : baseRoute;
  }
  function getBaseSectionUrls(appCfgKey) {
    if (appCfg[appCfgKey]) {
      return _.pluck(appCfg[appCfgKey].sections, "url");
    }
    return _.pluck(appCfg.app.layout[".side-bar"][0].options.sections, "url");
  }
  function loadIncompatibleBrowserLayout() {
    return utilsAsync.makePromise(resolve => {
      Router.initialize();
      T1.Location.set("compatible-browsers.html", {
        external: true
      });
      resolve();
    });
  }
  function loadNoAccessLayout(reload) {
    return utilsAsync.makePromise((resolve, reject) => {
      new T1Layout(appCfg.index).load().done(() => {
        Router.initialize(routerCfg, reload, getBaseSectionUrls("index"));
        T1.Location.set("#index", {
          replace: true,
          viewOptions: {
            noAccess: true
          }
        });
        resolve();
      }).fail(reject);
    });
  }
  function hideNav() {
    if (window.top !== window) {
      $(".side-bar").css("display", "none");
      $(".page-head-c").css("display", "none");
      $("body.app").css("background-image", "none");
      $(".app-container").attr("style", "left: 0 !important");
      $(".filter-control-container").css("left", "0");
      $(".w-head-c").css("left", "0");
      $(".page-head").css("background-position-y", "-52px").css("height", "30px");
      $(".page-body").attr("style", "top: 20px !important");
    }
  }
  function loadMainLayout(reload) {
    const appCfgKey = getAppCfgKey();
    const layoutConfig = appCfg[appCfgKey] || appCfg.app;
    const appLayout = new T1Layout(layoutConfig);
    if (layoutConfig.class) {
      document.body.classList.add(layoutConfig.class);
    }
    return utilsAsync.makePromise((resolve, reject) => {
      appLayout.load().done(() => {
        Router.initialize(routerCfg, reload, getBaseSectionUrls(appCfgKey));
        resolve();
      }).fail(reject);
    });
  }
  function getloaderFunc() {
    return !isBrowserCompatible() ? loadIncompatibleBrowserLayout : T1.NO_ACCESS ? loadNoAccessLayout : loadMainLayout;
  }
  function load(reload) {
    return getloaderFunc()(reload).then(hideNav);
  }
  return load;
});
