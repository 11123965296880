define('appCookieAuthStart',["jQuery", "Underscore", "T1", "T1Comm", "T1Layout", "T1UtilsAsync", "utils/LaunchDarklyUtils", "router", "app.config", "router.config", "models/userPreferences", "models/sessionUser", "collections/organizations", "jslibs/CFInstall/CFInstall-min"], function ($, _, T1, T1Comm, T1Layout, utilsAsync, LaunchDarklyUtils, Router, appCfg, routerCfg, UserPrefs, User, Organizations, CFInstall) {
  "use strict";

  return function () {
    var incompatibleBrowserUrl = "compatible-browsers.html";
    var T1Location = T1.Location;
    localStorage.removeItem("mm.bogo.acc");
    localStorage.removeItem("t1_location");
    function initialize(hasAuthRequiredBeenPublished) {
      var eventHub = T1.EventHub;
      var skipLoad = false;
      const MIN_BROWSER_VERS = 8;
      function appStart(reload) {
        var location = T1Location.get();
        var appEnvContainer = $("body");
        var appLayout, baseSectionUrls, layoutCfg;
        if (T1.NO_ACCESS) {
          location = "#index";
          T1Location.set(location, {
            replace: true,
            viewOptions: {
              noAccess: true
            }
          });
          T1Comm.requestLogout({});
        } else if (location === "" || location === "#index") {
          location = T1Comm.isUserLoggedIn() ? "#monitor" : "#index";
          T1Location.set(location);
        }
        location = location.substr(1);
        location = location.indexOf("/") > -1 ? location.split("/")[0] : location;
        layoutCfg = appCfg[location];
        if (layoutCfg) {
          baseSectionUrls = _.pluck(layoutCfg.sections, "url");
        } else {
          layoutCfg = appCfg.app;
          baseSectionUrls = _.pluck(appCfg.app.layout[".side-bar"][0].options.sections, "url");
        }
        appLayout = new T1Layout(layoutCfg);
        appEnvContainer.addClass(layoutCfg.class);
        appLayout.load().then(function () {
          Router.initialize(routerCfg, reload, baseSectionUrls);
        });
        eventHub.unsubscribe("AuthRequired", appStart);
      }
      function loadPrefs(reloadPrefs) {
        UserPrefs.fetch({
          reloadPrefs: reloadPrefs,
          success: function () {
            prefsLoaded(reloadPrefs);
          }
        });
      }
      function prefsLoaded(reloadPrefs) {
        var T1Publish = eventHub.publish;
        var orgs = Organizations.getOrganizations();
        var orgPromise = orgs.getSelected();
        var internalUser = User.getUser().get("type") === "INTERNAL";
        var updateUI = function () {
          if (reloadPrefs) {
            T1Publish("organization:continue");
          } else {
            appStart(true);
          }
        };
        LaunchDarklyUtils.getPromise().then(() => {
          if (internalUser) {
            updateUI();
          } else {
            orgPromise.done(function () {
              T1.NO_ACCESS = !checkAccessPermission(orgs);
              updateUI();
            });
          }
        });
      }
      function checkAccessPermission(orgs) {
        var blocked_orgs = COMPASS_ENV.BLOCKED_ORGS;
        var blockedOrgs = blocked_orgs ? blocked_orgs.split(",") : [];
        var userOrgs = _.pluck(orgs.models, "id");
        var userOrgsLen = userOrgs.length;
        var i;
        for (i = 0; i < userOrgsLen; i++) {
          if (!_.contains(blockedOrgs, userOrgs[i])) {
            return true;
          }
        }
        return false;
      }
      if ($.browser.msie && parseInt($.browser.version, 10) <= MIN_BROWSER_VERS) {
        skipLoad = !CFInstall.isAvailable();
      }
      if (skipLoad) {
        return T1Location.set(incompatibleBrowserUrl, {
          external: true
        });
      }
      window.localStorage.removeItem("password");
      if (hasAuthRequiredBeenPublished) {
        appStart();
      } else {
        eventHub.subscribe("AuthRequired", appStart);
      }
      utilsAsync.linkPromises([User.getUser().initializationPromise, UserPrefs.initializationPromise]).then(function () {
        prefsLoaded(undefined);
        eventHub.subscribe("onUserLoaded", loadPrefs);
      }).catch(function (caught) {
        User.getUser().initialSessionPromise.then(function () {
          throw caught;
        }, function (err) {
          var rethrow = err.isSevere;
          if (rethrow) {
            throw err.error;
          }
        });
      });
      eventHub.subscribe("organization:refreshUI", loadPrefs);
      $(window).scroll().resize();
    }
    return {
      initialize: initialize
    };
  };
});
