define('modules/reporting/campaigns/models/chartMetrics',['require','Underscore','modules/reporting/campaigns/models/metricCollections','modules/organization/utils/Utils','T1Permissions','modules/reporting/campaigns/utils/reportingUtils'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const MetricCollections = require("modules/reporting/campaigns/models/metricCollections");
  const OrgUtils = require("modules/organization/utils/Utils");
  const T1Permissions = require("T1Permissions");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  let lookup;
  const masterList = Object.freeze({
    adserving_cost: {
      section: ["performance_mm"],
      text: "Ad Serving Cost",
      type: "currency"
    },
    adserving_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Ad Serving Cost",
      type: "currency"
    },
    adverification_cost: {
      section: ["performance_mm"],
      text: "Ad Verification Cost",
      type: "currency"
    },
    adverification_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Ad Verification Cost",
      type: "currency"
    },
    agency_margin: {
      section: ["by_hour_mm"],
      text: "Agency Margin",
      type: "currency"
    },
    agency_margin_usd: {
      section: ["by_hour_mm"],
      text: "(USD) Agency Margin",
      type: "currency"
    },
    audience_verification_cost: {
      section: ["performance_mm"],
      text: "Audience Verification Cost",
      type: "currency"
    },
    audience_verification_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Audience Verification Cost",
      type: "currency"
    },
    billed_spend: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Billed Spend",
      type: "currency"
    },
    billed_spend_usd: {
      section: ["performance_mm", "by_hour_mm"],
      text: "(USD) Billed Spend",
      type: "currency"
    },
    clicks: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Clicks",
      type: "integers"
    },
    client_exchange_cost: {
      section: ["performance_mm"],
      text: "Client Exchange Cost",
      type: "currency"
    },
    client_margin_share: {
      section: ["performance_mm"],
      text: "Client Margin Share",
      type: "currency"
    },
    client_margin_share_usd: {
      section: ["performance_mm"],
      text: "(USD) Client Margin Share",
      type: "currency"
    },
    contextual_cost: {
      section: ["performance_mm"],
      text: "Contextual Cost",
      type: "currency"
    },
    contextual_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Contextual Cost",
      type: "currency"
    },
    ctc: {
      section: ["performance_mm", "by_hour_mm"],
      text: "CTC",
      type: "percent"
    },
    ctr: {
      section: ["performance_mm", "by_hour_mm"],
      text: "CTR",
      type: "percent"
    },
    dynamic_creative_cost: {
      section: ["performance_mm"],
      text: "Dynamic Creative Cost",
      type: "currency"
    },
    dynamic_creative_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Dynamic Creative Cost",
      type: "currency"
    },
    ecosystem_cost: {
      featureFlag: "reporting_ecosystem_cost",
      section: ["performance_mm"],
      text: "Ecosystem Cost",
      type: "currency"
    },
    ecosystem_cost_usd: {
      featureFlag: "reporting_ecosystem_cost",
      section: ["performance_mm"],
      text: "(USD) Ecosystem Cost",
      type: "currency"
    },
    identity_fee: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Identity Fee",
      type: "currency"
    },
    identity_fee_usd: {
      section: ["performance_mm", "by_hour_mm"],
      text: "(USD) Identity Fee",
      type: "currency"
    },
    impressions: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Impressions",
      type: "integers"
    },
    in_view: {
      section: ["performance_mm", "by_hour_mm"],
      text: "In View Impressions",
      type: "integers"
    },
    in_view_100_percent: {
      section: ["performance_mm", "by_hour_mm"],
      text: "100% In View Impressions",
      type: "integers"
    },
    in_view_15_seconds: {
      section: ["performance_mm", "by_hour_mm"],
      text: "In View - 15 Seconds",
      type: "integers"
    },
    in_view_15_seconds_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "In View 15 Seconds Rate",
      type: "percent"
    },
    in_view_5_seconds: {
      section: ["performance_mm", "by_hour_mm"],
      text: "In View - 5 Seconds",
      type: "integers"
    },
    in_view_5_seconds_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "In View 5 Seconds Rate",
      type: "percent"
    },
    managed_service_fee: {
      section: ["performance_mm"],
      text: "Managed Service Fee",
      type: "currency"
    },
    managed_service_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) Managed Service Fee",
      type: "currency"
    },
    margin: {
      section: ["performance_mm"],
      text: "Margin",
      type: "currency"
    },
    margin_usd: {
      section: ["performance_mm"],
      text: "(USD) Margin",
      type: "currency"
    },
    measurement_cost: {
      section: ["performance_mm"],
      text: "Measurement Cost",
      type: "currency"
    },
    measurement_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Measurement Cost",
      type: "currency"
    },
    measurability_rate: {
      section: ["performance_mm"],
      text: "Measurability Rate",
      type: "percent"
    },
    measurable: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Measurable Impressions",
      type: "integers"
    },
    media_cost: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost",
      type: "currency"
    },
    media_cost_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost eCPA",
      type: "currency"
    },
    media_cost_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost eCPA",
      type: "currency"
    },
    media_cost_cpc: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost eCPC",
      type: "currency"
    },
    media_cost_cpc_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost eCPC",
      type: "currency"
    },
    media_cost_cpcv: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost eCPCV",
      type: "currency"
    },
    media_cost_cpcv_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost eCPCV",
      type: "currency"
    },
    media_cost_cpm: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost eCPM",
      type: "currency"
    },
    media_cost_cpm_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost eCPM",
      type: "currency"
    },
    media_cost_pc_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost PC CPA",
      type: "currency"
    },
    media_cost_pc_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost PC CPA",
      type: "currency"
    },
    media_cost_pv_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost PV CPA",
      type: "currency"
    },
    media_cost_pv_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost PV CPA",
      type: "currency"
    },
    media_cost_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Media Cost ROI",
      type: "integers"
    },
    media_cost_usd: {
      section: ["performance_mm", "by_hour_mm"],
      text: "(USD) Media Cost",
      type: "currency"
    },
    media_cost_vcpm: {
      section: ["performance_mm"],
      text: "Media Cost vCPM",
      type: "currency"
    },
    media_cost_vcpm_usd: {
      section: ["performance_mm"],
      text: "(USD) Media Cost vCPM",
      type: "currency"
    },
    mm_data_cost: {
      section: ["performance_mm"],
      text: "MM Data Cost",
      type: "currency"
    },
    mm_data_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) MM Data Cost",
      type: "currency"
    },
    mm_margin_share: {
      section: ["performance_mm"],
      text: "MM Margin Share",
      type: "currency"
    },
    mm_margin_share_usd: {
      section: ["performance_mm"],
      text: "(USD) MM Margin Share",
      type: "currency"
    },
    mm_total_fee: {
      section: ["performance_mm"],
      text: "MM Total Fee",
      type: "currency"
    },
    mm_total_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) MM Total Fee",
      type: "currency"
    },
    non_watermark_impressions: {
      abbr: "Non-WM Imps",
      section: ["watermark_mm"],
      text: "Non-Watermark Impressions",
      type: "integers"
    },
    non_watermark_spend: {
      abbr: "Non-WM Spend",
      section: ["watermark_mm"],
      text: "Non-Watermark Spend",
      type: "currency"
    },
    optimization_fee: {
      section: ["performance_mm"],
      text: "Optimization Fee",
      type: "currency"
    },
    optimization_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) Optimization Fee",
      type: "currency"
    },
    platform_access_fee: {
      section: ["performance_mm"],
      text: "Platform Access Fee",
      type: "currency"
    },
    platform_access_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) Platform Access Fee",
      type: "currency"
    },
    pmp_no_opto_fee: {
      section: ["performance_mm"],
      text: "PMP Standard Fee",
      type: "currency"
    },
    pmp_no_opto_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) PMP Standard Fee",
      type: "currency"
    },
    pmp_opto_fee: {
      section: ["performance_mm"],
      text: "PMP Optimization Fee",
      type: "currency"
    },
    pmp_opto_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) PMP Optimization Fee",
      type: "currency"
    },
    post_click_aov: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-Click AOV",
      type: "currency"
    },
    post_click_aov_usd: {
      section: ["performance_mm"],
      text: "(USD) Post-Click AOV",
      type: "currency"
    },
    post_click_conversions: {
      abbr: "PC Conversions",
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-Click Conversions",
      type: "integers"
    },
    post_click_media_cost_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-Click Media Cost ROI",
      type: "integers"
    },
    post_click_revenue: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-Click Revenue",
      type: "currency"
    },
    post_click_revenue_usd: {
      section: ["performance_mm"],
      text: "(USD) Post-Click Revenue",
      type: "currency"
    },
    post_click_total_ad_cost_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-Click Total Ad Cost ROI",
      type: "integers"
    },
    post_click_total_spend_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-Click Total Spend ROI",
      type: "integers"
    },
    post_view_aov: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View AOV",
      type: "currency"
    },
    post_view_aov_usd: {
      section: ["performance_mm"],
      text: "(USD) Post-View AOV",
      type: "currency"
    },
    post_view_conversions: {
      abbr: "PV Conversions",
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Conversions",
      type: "integers"
    },
    post_view_conversions_raw: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Conversions (Raw)",
      type: "integers"
    },
    post_view_media_cost_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Media Cost ROI",
      type: "integers"
    },
    post_view_revenue: {
      abbr: "PV Revenue",
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Revenue",
      type: "currency"
    },
    post_view_revenue_raw: {
      abbr: "PV Revenue (Raw)",
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Revenue (Raw)",
      type: "currency"
    },
    post_view_revenue_raw_usd: {
      abbr: "PV Revenue (Raw) (USD)",
      section: ["performance_mm"],
      text: "(USD) Post-View Revenue (Raw)",
      type: "currency"
    },
    post_view_revenue_usd: {
      abbr: "PV Revenue (USD)",
      section: ["performance_mm"],
      text: "(USD) Post-View Revenue",
      type: "currency"
    },
    post_view_total_ad_cost_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Total Ad Cost ROI",
      type: "integers"
    },
    post_view_total_spend_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Post-View Total Spend ROI",
      type: "integers"
    },
    privacy_compliance_cost: {
      section: ["performance_mm"],
      text: "Privacy Compliance Cost",
      type: "currency"
    },
    privacy_compliance_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Privacy Compliance Cost",
      type: "currency"
    },
    revenue: {
      section: ["performance_mm"],
      text: "Revenue (Raw)",
      type: "currency"
    },
    revenue_raw: {
      section: ["by_hour_mm"],
      text: "Revenue (Raw)",
      type: "currency"
    },
    revenue_usd: {
      section: ["performance_mm"],
      text: "(USD) Revenue (Raw)",
      type: "currency"
    },
    rr_per_1k_imps: {
      abbr: "RR/1K Imps",
      section: ["performance_mm", "by_hour_mm"],
      text: "Response Rate/1K Imps",
      type: "percent"
    },
    source_media_enablement_fee: {
      section: ["performance_mm"],
      text: "SOURCE Media Enablement Fee",
      type: "currency"
    },
    source_media_enablement_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) SOURCE Media Enablement Fee",
      type: "currency"
    },
    source_platform_access_fee: {
      section: ["performance_mm"],
      text: "SOURCE Platform Access Fee",
      type: "currency"
    },
    source_platform_access_fee_usd: {
      section: ["performance_mm"],
      text: "(USD) SOURCE Platform Access Fee",
      type: "currency"
    },
    total_ad_cost: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost",
      type: "currency"
    },
    total_ad_cost_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost eCPA",
      type: "currency"
    },
    total_ad_cost_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost eCPA",
      type: "currency"
    },
    total_ad_cost_cpc: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost eCPC",
      type: "currency"
    },
    total_ad_cost_cpc_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost eCPC",
      type: "currency"
    },
    total_ad_cost_cpcv: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost eCPCV",
      type: "currency"
    },
    total_ad_cost_cpcv_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost eCPCV",
      type: "currency"
    },
    total_ad_cost_cpm: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost eCPM",
      type: "currency"
    },
    total_ad_cost_cpm_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost eCPM",
      type: "currency"
    },
    total_ad_cost_pc_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost PC CPA",
      type: "currency"
    },
    total_ad_cost_pc_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost PC CPA",
      type: "currency"
    },
    total_ad_cost_pv_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost PV CPA",
      type: "currency"
    },
    total_ad_cost_pv_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost PV CPA",
      type: "currency"
    },
    total_ad_cost_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Ad Cost ROI",
      type: "integers"
    },
    total_ad_cost_usd: {
      section: ["performance_mm", "by_hour_mm"],
      text: "(USD) Total Ad Cost",
      type: "currency"
    },
    total_ad_cost_vcpm: {
      section: ["performance_mm"],
      text: "Total Ad Cost vCPM",
      type: "currency"
    },
    total_ad_cost_vcpm_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Ad Cost vCPM",
      type: "currency"
    },
    total_aov: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total AOV",
      type: "currency"
    },
    total_aov_usd: {
      section: ["performance_mm"],
      text: "(USD) Total AOV",
      type: "currency"
    },
    total_conversions: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Conversions",
      type: "integers"
    },
    total_revenue: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Revenue",
      type: "currency"
    },
    total_revenue_usd: {
      section: ["performance_mm", "by_hour_mm"],
      text: "(USD) Total Revenue",
      type: "currency"
    },
    total_spend: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend",
      type: "currency"
    },
    total_spend_usd: {
      section: ["performance_mm", "by_hour_mm"],
      text: "(USD) Total Spend",
      type: "currency"
    },
    total_spend_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend eCPA",
      type: "currency"
    },
    total_spend_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend eCPA",
      type: "currency"
    },
    total_spend_cpc: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend eCPC",
      type: "currency"
    },
    total_spend_cpc_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend eCPC",
      type: "currency"
    },
    total_spend_cpcv: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend eCPCV",
      type: "currency"
    },
    total_spend_cpcv_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend eCPCV",
      type: "currency"
    },
    total_spend_cpm: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend eCPM",
      type: "currency"
    },
    total_spend_cpm_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend eCPM",
      type: "currency"
    },
    total_spend_pc_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend PC CPA",
      type: "currency"
    },
    total_spend_pc_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend PC CPA",
      type: "currency"
    },
    total_spend_pv_cpa: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend PV CPA",
      type: "currency"
    },
    total_spend_pv_cpa_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend PV CPA",
      type: "currency"
    },
    total_spend_roi: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Total Spend ROI",
      type: "integers"
    },
    total_spend_vcpm: {
      section: ["performance_mm"],
      text: "Total Spend vCPM",
      type: "currency"
    },
    total_spend_vcpm_usd: {
      section: ["performance_mm"],
      text: "(USD) Total Spend vCPM",
      type: "currency"
    },
    udi_data_cost: {
      section: ["performance_mm"],
      text: "UDI Data Cost",
      type: "currency"
    },
    udi_data_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) UDI Data Cost",
      type: "currency"
    },
    uniques: {
      section: ["performance_mm"],
      text: "Uniques",
      type: "integers"
    },
    video_close: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Close",
      type: "integers"
    },
    video_collapse: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Collapse",
      type: "integers"
    },
    video_companion_clicks: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Companion Clicks",
      type: "integers"
    },
    video_companion_ctr: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Companion CTR",
      type: "percent"
    },
    video_companion_impressions: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Companion Impressions",
      type: "integers"
    },
    video_complete: {
      section: ["performance_mm", "by_hour_mm"],
      text: "100% Completed Views",
      type: "integers"
    },
    video_complete_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "100% Completed Rate",
      type: "percent"
    },
    video_complete_rate_impression_based: {
      abbr: "100% Completed Rate - IB",
      section: ["performance_mm", "by_hour_mm"],
      text: "100% Completed Rate - Impressions Based",
      type: "percent"
    },
    video_engaged_impressions: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Engaged Impressions",
      type: "integers"
    },
    video_engaged_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Engaged Rate",
      type: "percent"
    },
    video_expand: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Expand",
      type: "integers"
    },
    video_first_quartile: {
      section: ["performance_mm", "by_hour_mm"],
      text: "25% Completed Views",
      type: "integers"
    },
    video_first_quartile_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "25% Completed Rate",
      type: "percent"
    },
    video_first_quartile_rate_impression_based: {
      abbr: "25% Completed Rate - IB",
      section: ["performance_mm", "by_hour_mm"],
      text: "25% Completed Rate - Impressions Based",
      type: "percent"
    },
    video_fullscreen: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Fullscreen",
      type: "integers"
    },
    video_midpoint: {
      section: ["performance_mm", "by_hour_mm"],
      text: "50% Completed Views",
      type: "integers"
    },
    video_midpoint_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "50% Completed Rate",
      type: "percent"
    },
    video_midpoint_rate_impression_based: {
      abbr: "50% Completed Rate - IB",
      section: ["performance_mm", "by_hour_mm"],
      text: "50% Completed Rate - Impressions Based",
      type: "percent"
    },
    video_mute: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Mute",
      type: "integers"
    },
    video_pause: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Pause",
      type: "integers"
    },
    video_play_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Play Rate",
      type: "percent"
    },
    video_resume: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Resume",
      type: "integers"
    },
    video_rewind: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Rewind",
      type: "integers"
    },
    video_skip: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Skip",
      type: "integers"
    },
    video_skippable_impressions: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Skippable Impressions",
      type: "integers"
    },
    video_skipped_impressions: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Skipped Impressions",
      type: "integers"
    },
    video_skipped_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Skipped Rate",
      type: "percent"
    },
    video_start: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Start",
      type: "integers"
    },
    video_third_quartile: {
      section: ["performance_mm", "by_hour_mm"],
      text: "75% Completed Views",
      type: "integers"
    },
    video_third_quartile_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "75% Completed Rate",
      type: "percent"
    },
    video_third_quartile_rate_impression_based: {
      abbr: "75% Completed Rate - IB",
      section: ["performance_mm", "by_hour_mm"],
      text: "75% Completed Rate - Impressions Based",
      type: "percent"
    },
    video_unmute: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Unmute",
      type: "integers"
    },
    viewability_cost: {
      section: ["performance_mm"],
      text: "Viewability Cost",
      type: "currency"
    },
    viewability_cost_usd: {
      section: ["performance_mm"],
      text: "(USD) Viewability Cost",
      type: "currency"
    },
    viewability_rate: {
      section: ["performance_mm", "by_hour_mm"],
      text: "Viewability Rate",
      type: "percent"
    },
    viewability_rate_100_percent: {
      section: ["performance_mm", "by_hour_mm"],
      text: "100% In View Rate",
      type: "percent"
    },
    watermark_impressions: {
      abbr: "WM Imps",
      section: ["watermark_mm"],
      text: "Watermark Impressions",
      type: "integers"
    },
    watermark_spend: {
      abbr: "WM Spend",
      section: ["watermark_mm"],
      text: "Watermark Spend",
      type: "currency"
    },
    watermark_spend_pct: {
      abbr: "WM Spend %",
      section: ["watermark_mm"],
      text: "Watermark Spend Percent",
      type: "percent"
    }
  });
  function updateLookup(section, focus) {
    lookup = Utils.hideCostsAndFees ? _.omit(masterList, MetricCollections.fees) : masterList;
    lookup = _.omit(lookup, metric => metric.featureFlag && !T1Permissions.check("feature", metric.featureFlag));
    lookup = _.pick(lookup, metric => metric.section.includes(section));
    if (focus) {
      const lookupFocus = `focus${focus.toUpperCase()}`;
      lookup = _.pick(lookup, metric => metric[lookupFocus] && metric[lookupFocus].includes(section));
    }
    const hasVcrImpression = OrgUtils.getOrgVcrSetting();
    const impressionBasedMetrics = Object.keys(lookup).filter(key => key.includes("_impression_based"));
    const nonImpressionBasedMetrics = impressionBasedMetrics.map(key => key.replace("_impression_based", ""));
    lookup = hasVcrImpression ? _.omit(lookup, nonImpressionBasedMetrics) : _.omit(lookup, impressionBasedMetrics);
  }
  function getKeyList() {
    return Object.keys(lookup);
  }
  function getDisplayList() {
    return Object.entries(lookup).map(([key, value]) => ({
      abbr: value.abbr || value.text,
      text: value.text,
      type: value.type,
      value: key
    }));
  }
  function getKey(name) {
    const pick = _.pick(lookup, value => value.text === name);
    return Object.keys(pick)[0];
  }
  function getShortName(key) {
    return lookup[key].abbr || lookup[key].text;
  }
  return {
    getDisplayList: getDisplayList,
    getKey: getKey,
    getKeyList: getKeyList,
    getShortName: getShortName,
    updateLookup: updateLookup
  };
});
