define('modules/reporting/segments/dataExport/models/model',["jQuery", "Underscore", "T1", "T1Permissions", "T1Comm", "T1Model", "collections/organizations"], function ($, _, T1, T1Permissions, T1Comm, T1Model, Organizations) {
  "use strict";

  var DEFAULT_REPORT = "";
  var REPORTS = [{
    report: "Overlap"
  }, {
    report: "Performance"
  }];
  var PUBLISH;
  return T1Model.extend({
    canCache: true,
    cacheDuration: 18e5,
    url: function () {
      return T1.RPT_API_ROOT + "std/" + this.getReportItem().fetchName + "/meta";
    },
    fixture: "",
    dataType: "json",
    action: "read",
    orgId: "",
    overlapSegments: {
      segmentsA: {},
      segmentsB: {}
    },
    performanceSegments: [],
    selectedCampaign: "",
    initialize: function (options) {
      PUBLISH = T1.EventHub.publish;
      T1Model.prototype.initialize.call(this, options);
      this.setOrg();
    },
    setOrg: function () {
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.set({
          orgId: orgId
        });
      }, this));
    },
    getOrg: function () {
      return this.get("orgId");
    },
    setSelectedReport: function (reportName, silent) {
      this.set({
        selectedReport: reportName
      }, {
        silent: silent
      });
    },
    getSelectedReport: function () {
      return this.get("selectedReport") || DEFAULT_REPORT;
    },
    isReportSelected: function () {
      var selectedReport = this.getSelectedReport();
      if (selectedReport && selectedReport !== "") {
        return true;
      } else {
        return false;
      }
    },
    getReports: function () {
      return REPORTS;
    },
    getReportItem: function () {
      var self = this;
      var reportItem = _.find(REPORTS, function (reportEntry) {
        return reportEntry.report === self.getSelectedReport();
      });
      return reportItem;
    },
    setEmail: function (emailList, emailValidity) {
      var self = this;
      this.set({
        email: emailList
      });
      this.set({
        isEmailValid: emailValidity
      });
      self.checkIfAnythingToSaveAndUpdate();
    },
    getEmail: function () {
      return this.get("email");
    },
    addToBin: function (data) {
      var binType = data.binType;
      var dataId = data.id;
      this.overlapSegments[binType][dataId] = 1;
      this.checkIfAnythingToSaveAndUpdate();
    },
    removeFromBin: function (binType, dataId) {
      if (binType === "segmentsA" || binType === "segmentsB") {
        delete this.overlapSegments[binType][dataId];
        this.checkIfAnythingToSaveAndUpdate();
      }
    },
    clearPerformanceCampaign: function () {
      this.unset("selectedCampaign");
      this.checkIfAnythingToSaveAndUpdate();
    },
    updatePerformanceCampaign: function (campaign) {
      this.set({
        selectedCampaign: campaign
      });
      this.checkIfAnythingToSaveAndUpdate();
    },
    updatePerformanceSegments: function (segments, campaign) {
      this.set({
        performanceSegments: segments
      });
      this.checkIfAnythingToSaveAndUpdate();
    },
    checkIfAnythingToSaveAndUpdate: function () {
      var areChangesValid = this.isSegmentsChanged();
      var anyChanges = this.anyChanges();
      var isEmailValid = this.get("isEmailValid");
      var isEmailFieldEmpty = this.isEmailFieldEmpty();
      if (areChangesValid && isEmailValid) {
        PUBLISH("footer.updateSaveButtons", true);
      } else {
        PUBLISH("footer.updateSaveButtons", false);
      }
      if (anyChanges || !isEmailFieldEmpty) {
        PUBLISH("footer.updateClearButton", true);
      } else {
        PUBLISH("footer.updateClearButton", false);
      }
    },
    isEmailFieldEmpty: function () {
      var emails = this.get("email");
      var emailFieldEmpty = true;
      var email;
      if (emails && emails instanceof Array) {
        if (emails.length > 0) {
          if (emails.length === 1) {
            email = emails[0];
            if (email && email !== "") {
              emailFieldEmpty = false;
            }
          } else {
            emailFieldEmpty = false;
          }
        }
      }
      return emailFieldEmpty;
    },
    isSegmentsChanged: function (reportType) {
      var isChanged = false;
      var selectedCampaign, perfSegments;
      if (!reportType) {
        reportType = this.getSelectedReport();
      }
      if (reportType.toLowerCase() === "performance") {
        selectedCampaign = this.get("selectedCampaign");
        perfSegments = this.get("performanceSegments");
        if (perfSegments && perfSegments instanceof Array && perfSegments.length > 0 && selectedCampaign) {
          isChanged = true;
        }
      } else if (reportType.toLowerCase() === "overlap") {
        if ("segmentsA" in this.overlapSegments && !$.isEmptyObject(this.overlapSegments.segmentsA) && "segmentsB" in this.overlapSegments && !$.isEmptyObject(this.overlapSegments.segmentsB)) {
          isChanged = true;
        }
      }
      return isChanged;
    },
    anyChanges: function () {
      var isChanged = false;
      var selectedCampaign, perfSegments;
      if (this.getSelectedReport() === "Performance") {
        selectedCampaign = this.get("selectedCampaign");
        if (selectedCampaign && selectedCampaign != "") {
          isChanged = true;
        }
        perfSegments = this.get("performanceSegments");
        if (perfSegments && perfSegments instanceof Array && perfSegments.length > 0) {
          isChanged = true;
        }
      } else if (this.getSelectedReport() === "Overlap") {
        if ("segmentsA" in this.overlapSegments && !$.isEmptyObject(this.overlapSegments.segmentsA) || "segmentsB" in this.overlapSegments && !$.isEmptyObject(this.overlapSegments.segmentsB)) {
          isChanged = true;
        }
      }
      return isChanged;
    },
    getSelectedSegments: function (reportType) {
      var segments_A = [];
      var segments_B = [];
      var id_A;
      var id_B;
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (!reportType) {
        reportType = this.getSelectedReport();
      }
      var fullDate = function (num) {
        return num.toString().length > 1 ? num : "0" + num;
      };
      if (reportType.toLowerCase() === "performance") {
        var performanceSegments = this.get("performanceSegments");
        for (var i = 0; i < performanceSegments.length; i++) {
          performanceSegments[i].id = parseInt(performanceSegments[i].id, 10);
        }
        return {
          segments: performanceSegments,
          campaign_id: parseInt(this.get("selectedCampaign"), 10),
          start_date: yesterday.getFullYear() + "-" + fullDate(yesterday.getMonth() + 1) + "-" + fullDate(yesterday.getDate()),
          end_date: today.getFullYear() + "-" + fullDate(today.getMonth() + 1) + "-" + fullDate(today.getDate()),
          email: this.get("email")
        };
      } else if (reportType.toLowerCase() === "overlap") {
        for (id_A in this.overlapSegments.segmentsA) {
          segments_A.push({
            id: parseInt(id_A)
          });
        }
        for (id_B in this.overlapSegments.segmentsB) {
          segments_B.push({
            id: parseInt(id_B)
          });
        }
        return {
          segments_A: segments_A,
          segments_B: segments_B
        };
      }
    },
    setSavedState: function (state) {
      this.set({
        savedState: state
      });
    },
    getSavedState: function () {
      return this.get("savedState");
    }
  });
});
