define('modules/campaign/chart/winLoss/models/mCampaignDailyChart',["Underscore", "Backbone", "T1"], function (_, Backbone, T1) {
  "use strict";

  const COLOR_WHITE = "#fff";
  const MAX_LENGTH = 60;
  return Backbone.Model.extend({
    initialize: function (attr, opts) {
      this.ReportModel = opts;
    },
    setLegend: function (state) {
      this.set({
        legendState: state
      });
    },
    getLegend: function () {
      return this.get("legendState");
    },
    getChartColors: function () {
      return this.get("chartColors") || [];
    },
    getChartData: function () {
      let dataProvider, graphs;
      const ReportModel = this.ReportModel;
      const timeslots = ReportModel.getTimeSlots();
      if (ReportModel.chartData.length) {
        this._parseData(ReportModel, timeslots);
        this._createDataProvider(timeslots);
        this._createGraphs(ReportModel);
        dataProvider = this.get("dataProvider");
        graphs = this.get("graphs");
      }
      return {
        dataProvider: dataProvider || [],
        graphs: graphs || []
      };
    },
    _parseData: function (ReportModel, timeslots) {
      let parsedData = [];
      const dimension = ReportModel.getViewByDimension();
      const highestLowest = ReportModel.getHighestLowest();
      const {
        order: order,
        limit: limit
      } = highestLowest;
      const metric = ReportModel.getSelectedMetrics().campaign.daily[0].value;
      const dataJSON = ReportModel.chartData.toJSON();
      const dataSortedByMetric = this._sortByMetric(dataJSON, highestLowest);
      const dataFilteredByZeros = T1.Utils.valueToBool(highestLowest.excludeZeros) ? this._filterOutZeros(dataSortedByMetric, highestLowest) : dataSortedByMetric;
      if (dataFilteredByZeros.length) {
        this._limitRange(dataFilteredByZeros, order, limit);
        parsedData = _.chain(dataFilteredByZeros).groupBy(model => model[`${dimension}_id`]).map(function (arr, id) {
          let name = "";
          const values = [];
          _.each(timeslots, function (startDate) {
            const dataSet = _.find(arr, item => startDate === item.start_date);
            if (dataSet) {
              name = dataSet[`${dimension}_name`];
            }
            values.push(dataSet ? dataSet[metric] : "0");
          });
          return {
            id: id,
            name: name,
            values: values
          };
        }).value();
      }
      this.set({
        parsedData: parsedData
      });
    },
    _sortByMetric: function (dataJSON, highestLowest) {
      return _.sortBy(dataJSON, item => Number(item[highestLowest.metric]));
    },
    _filterOutZeros: function (dataSortedByMetric, highestLowest) {
      return _.filter(dataSortedByMetric, item => Number(item[highestLowest.metric]) > 0);
    },
    _limitRange: function (dataFilteredByZeros, order, limit) {
      switch (order) {
        case "hi":
          dataFilteredByZeros.reverse();
        case "lo":
          if (dataFilteredByZeros.length > limit) {
            dataFilteredByZeros.length = limit;
          }
          break;
      }
    },
    _createDataProvider: function (timeslots) {
      let dataProvider = [];
      const parsedData = this.get("parsedData");
      if (parsedData.length) {
        dataProvider = timeslots.map(date => ({
          date: date
        }));
        dataProvider.forEach(function (item, index) {
          parsedData.forEach(function (data) {
            item[data.id] = data.values[index];
          });
        });
      }
      this.set({
        dataProvider: dataProvider
      });
    },
    _createGraphs: function (ReportModel) {
      let i = 0;
      const parsedData = this.get("parsedData");
      const tooltipFormat = ReportModel.getTooltipFormat();
      const metric = ReportModel.getSelectedMetrics().campaign.daily[0].value;
      const label = ReportModel.getSelectedMetricLabel(metric);
      const metricType = ReportModel.getSelectedMetricType(metric);
      const chartColors = ReportModel.getColorList(parsedData);
      function formatBalloon(item) {
        let title = item.graph.title || "";
        if (title.length > MAX_LENGTH) {
          title = title.match(/([\S\s]{1,60})/g);
        }
        return `<p style="font-family:AB;">${title.toString().replace(/,/g, "<br>")}</p><br>${label}: ${ReportModel.formatValue(item.values.value, metricType)}<br>${moment(item.category).format(tooltipFormat)}<br>&nbsp;`;
      }
      const graphs = _.map(parsedData, data => {
        const color = chartColors[i++];
        return {
          balloonFunction: formatBalloon,
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 6,
          hideBulletsCount: 100,
          lineColor: color,
          lineThickness: 1.5,
          title: data.name,
          type: "smoothedLine",
          valueField: data.id
        };
      });
      this.set({
        chartColors: chartColors,
        graphs: graphs
      });
    }
  });
});
