define('collections/advertisers',["jQuery", "T1", "T1Collection", "T1Sortable", "models/advertiser", "collections/organizations"], function ($, T1, T1Collection, Sortable, Advertiser, Organizations) {
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: Advertiser,
    filterConfiguration: {
      filters: [{
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: true
      }],
      enableEvents: true
    },
    currentSearchConfig: [],
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        },
        status: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    initialize: function (models, options) {
      if (options && options.hasOwnProperty("filterConfiguration")) {
        this.filterConfiguration = options.filterConfiguration;
      }
      T1Collection.prototype.initialize.apply(this, arguments);
    },
    updateOrgFilter: function (opts) {
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.urlFilter.set({
          entity: "organization",
          id: orgId
        });
      }, this));
    },
    applySearchFilter: function (field, term) {
      var searchType;
      var fieldIndex = -1;
      if (field !== undefined && term !== undefined) {
        searchType = {
          term: term,
          searchField: field
        };
        $.each(this.currentSearchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
            return;
          }
        });
        if (fieldIndex === -1) {
          if (term !== "") {
            this.currentSearchConfig.push(searchType);
          }
        } else {
          if (term === "") {
            this.currentSearchConfig.splice(fieldIndex, 1);
          } else {
            this.currentSearchConfig[fieldIndex] = searchType;
          }
        }
      }
    },
    filterBy: function (field, term, success) {
      var self = this;
      var onSuccess = success || $.noop;
      this.applySearchFilter(field, term);
      this.search.set({
        params: this.currentSearchConfig,
        fetchRequired: true,
        success: function (coll) {
          self.trigger("reset", this);
          onSuccess(coll);
        }
      });
    },
    getSelectedAdvertisers: function () {
      var checkedModels = this.filter(function (model) {
        return model.get("checked") === true;
      });
      return new T1Collection(checkedModels);
    },
    toggleAllSelected: function (isChecked, isSilent) {
      var isSilent = isSilent || false;
      this.each(function (model) {
        model.set({
          checked: isChecked
        }, isSilent);
      });
    },
    urlString: "advertisers"
  });
  T1.Models.Advertisers = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.Advertisers;
});
