define('modules/reporting/campaigns/models/reportingModels',['require','jQuery','collections/campaignReport','modules/reporting/campaigns/contextual/models/model','modules/reporting/campaigns/dataExport/models/dataExport','modules/reporting/campaigns/geoPerformance/models/model','models/reportingMainMeta','modules/reporting/campaigns/models/reportingMainModel','models/reportingMetadata','modules/reporting/campaigns/performance/models/model','modules/reporting/campaigns/reachFrequency/models/model','models/report','modules/reporting/campaigns/siteTransparency/models/model','T1','modules/reporting/campaigns/technology/models/model'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const CampaignReport = require("collections/campaignReport");
  const ContextualModel = require("modules/reporting/campaigns/contextual/models/model");
  const DataExportModel = require("modules/reporting/campaigns/dataExport/models/dataExport");
  const GeoModel = require("modules/reporting/campaigns/geoPerformance/models/model");
  const MainMeta = require("models/reportingMainMeta");
  const MainModel = require("modules/reporting/campaigns/models/reportingMainModel");
  const MetaData = require("models/reportingMetadata");
  const PerformanceModel = require("modules/reporting/campaigns/performance/models/model");
  const ReachFrequencyModel = require("modules/reporting/campaigns/reachFrequency/models/model");
  const Report = require("models/report");
  const SiteTransparencyModel = require("modules/reporting/campaigns/siteTransparency/models/model");
  const T1 = require("T1");
  const TechModel = require("modules/reporting/campaigns/technology/models/model");
  const reportModelHash = {
    contextual_insights: ContextualModel,
    device_technology: TechModel,
    geo: GeoModel,
    performance: PerformanceModel,
    reach_frequency: ReachFrequencyModel,
    site_transparency: SiteTransparencyModel
  };
  const summaryCfgs = {
    urlString: "std/performance",
    fetchOptions: {
      dimensions: "organization_id",
      filter: "campaign_id=",
      order: "date",
      precision: 2,
      time_rollup: "all"
    },
    url() {
      const opts = $.extend({}, this.fetchOptions);
      const reportModel = reportingModels.reportModel;
      const techFilters = this.noTechFilters ? "" : reportModel.getTechFilters();
      opts.filter += String(this.id) + this.filteredDimensionIDs + techFilters;
      return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
    },
    loaded: false
  };
  const summaryCollectionCfgs = {
    fetchOptions: {
      order: "date"
    },
    urlString: "std/performance",
    url() {
      const opts = $.extend({}, this.fetchOptions);
      opts.filter += `${this.id}`;
      return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
    }
  };
  const siteDomainCfgs = {
    urlString: "std/site_transparency",
    fetchOptions: {
      dimensions: "site_domain",
      filter: "campaign_id=",
      precision: 2,
      time_rollup: "all",
      v2: ""
    }
  };
  const geoCfgs = {
    urlString: "std/geo",
    fetchOptions: {
      dimensions: "country_name",
      filter: "campaign_id=",
      precision: 2,
      time_rollup: "all",
      v2: ""
    }
  };
  const reachFrequencyCfgs = {
    urlString: "std/reach_frequency",
    fetchOptions: {
      dimensions: "frequency_bin",
      filter: "campaign_id=",
      precision: 2,
      time_rollup: "all"
    }
  };
  const contextualCfgs = {
    urlString: "std/contextual_insights",
    fetchOptions: {
      dimensions: "target_name",
      filter: 'target_name!="_root_"&campaign_id=',
      precision: 2,
      time_rollup: "all"
    }
  };
  const urlCfg = {
    url() {
      const opts = $.extend({}, this.fetchOptions);
      opts.filter += `${this.id}`;
      return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
    }
  };
  const moreFiltersUrlCfg = {
    url() {
      const opts = $.extend({}, this.fetchOptions);
      const reportModel = reportingModels.reportModel;
      const moreFilters = reportModel.get("moreFilters") || "";
      const geoFilters = reportModel.get("geoFilters") || "";
      opts.filter += String(this.id) + moreFilters + geoFilters;
      return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
    }
  };
  const reportingModels = {
    contextualCollection: new CampaignReport($.extend(true, {}, contextualCfgs, moreFiltersUrlCfg)),
    contextualFilterDimension: new CampaignReport($.extend(true, {}, contextualCfgs, urlCfg)),
    dimensionItemsCollection: new CampaignReport($.extend(true, {}, summaryCollectionCfgs)),
    geoCollection: new CampaignReport($.extend(true, {}, geoCfgs, moreFiltersUrlCfg)),
    geoFilterDimension: new CampaignReport($.extend(true, {}, geoCfgs, urlCfg)),
    mainMeta: new MainMeta(),
    mainModel: new MainModel(),
    reachFrequencyCollection: new CampaignReport($.extend(true, {}, reachFrequencyCfgs, moreFiltersUrlCfg)),
    reachFrequencyFilterDimension: new CampaignReport($.extend(true, {}, reachFrequencyCfgs, urlCfg)),
    reportMeta: null,
    reportModel: null,
    sectionMetas: {},
    sectionModels: {},
    siteDomainCollection: new CampaignReport($.extend(true, {}, siteDomainCfgs, moreFiltersUrlCfg)),
    siteFilterDimension: new CampaignReport($.extend(true, {}, siteDomainCfgs, urlCfg)),
    summary: new Report($.extend(true, {}, summaryCfgs)),
    summaryCollection: new CampaignReport($.extend(true, {}, summaryCollectionCfgs)),
    total: new Report($.extend(true, {}, summaryCfgs)),
    setupReportMeta(metaURL, section) {
      const str = "std/";
      const urlString = metaURL.substr(metaURL.indexOf(str));
      let key = urlString.substring(str.length, urlString.indexOf("/meta"));
      const {
        mainMeta: mainMeta,
        mainModel: mainModel
      } = this;
      if (key.includes("viewability")) {
        key = section;
      }
      if (key.includes("ecosystem_cost")) {
        key = section;
      }
      if (!this.sectionModels[key]) {
        const metaData = new MetaData({
          id: "reportMeta",
          key: key,
          mainMeta: mainMeta,
          mainModel: mainModel,
          urlString: urlString
        });
        this.sectionMetas[key] = metaData;
        if (reportModelHash[key]) {
          const reportModel = new reportModelHash[key]({
            mainMeta: mainMeta,
            mainModel: mainModel,
            key: key
          });
          this.sectionModels[key] = reportModel;
        }
      } else {
        this.sectionMetas[key].urlString = urlString;
      }
      this.reportMeta = this.sectionMetas[key];
      this.reportModel = this.sectionModels[key];
      this.reportModel.mainMeta = mainMeta;
      this.reportModel.mainModel = mainModel;
      this.reportModel.sectionKey = key;
      this.reportMeta.setReportModel(this.reportModel);
      this.reportModel.setReportMeta(this.reportMeta);
      return this.reportMeta;
    },
    setupDataExportModel() {
      if (!this.sectionModels.DataExport) {
        this.sectionModels.DataExport = new DataExportModel();
      }
    },
    timeSeries: {
      summary: {
        fetchKey: "all"
      },
      daily: {
        chartDateFormat: "yyyy-MMM-dd",
        fetchKey: "by_day",
        gridPivot: "start_date",
        label: "Daily",
        xAxisLabel: "Days",
        xKey: "start_date"
      }
    }
  };
  return reportingModels;
});
