define('modules/admin/siteLists/views/csvExportModal',['require','T1','T1View','text!modules/admin/siteLists/templates/csvExportModal.html','models/siteList','utils/TimezoneUtils'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1View = require("T1View");
  const tmpl = require("text!modules/admin/siteLists/templates/csvExportModal.html");
  const SiteList = require("models/siteList");
  const TimezoneUtils = require("utils/TimezoneUtils");
  var timezone = TimezoneUtils.getClientTimezoneAbbr();
  var convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
  var dateFormat = "yyyyMMdd";
  return T1View.extend({
    template: tmpl,
    eventhubEvents: {
      "csvExportModal:show": "show"
    },
    cancelled: false,
    fetching: false,
    events: {
      "click .cancel-modal": "hide"
    },
    load() {
      this.cancelled = false;
      this.fetching = false;
      this.render().then(() => {
        this.modal = this.$("strand-modal")[0];
        this.spinner = this.$("strand-spinner")[0];
      });
    },
    unload() {
      this.cancelled = true;
      this.fetching = false;
    },
    show(data) {
      const delay = 1e3;
      this.model = new SiteList({
        id: data.id
      });
      setTimeout(() => {
        if (this.fetching === true) {
          this.spinner.start();
          this.modal.show();
        }
      }, delay);
      this.export();
    },
    hide(evt) {
      if (evt) {
        if (evt.stopPropagation) {
          evt.stopPropagation();
        }
        if (evt.preventDefault) {
          evt.preventDefault();
        }
      }
      this.cancelled = true;
      this.modal.hide();
    },
    export() {
      var self = this;
      var model = this.model;
      var now = new Date();
      this.cancelled = false;
      this.fetching = true;
      model.canCache = false;
      model.sync("read", model, {
        action: "download.csv",
        dataType: "html",
        processAjaxResponse: function (resp) {
          var blob = new Blob([resp], {
            type: "attachment/csv"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var elem = document.body.appendChild(a);
          var handler = function () {
            const delay = 1e3;
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, delay);
            document.body.removeChild(elem);
            elem.removeEventListener("click", handler);
          };
          self.fetching = false;
          if (!self.cancelled) {
            self.hide();
            a.href = url;
            a.download = `site_list_${self.model.id}_${convertToLocalTime(now).toString(dateFormat)}.csv`;
            elem.addEventListener("click", handler);
            elem.click();
          }
          return {
            statusCode: "ok",
            jsonData: []
          };
        }
      });
    }
  });
});
