define('modules/reporting/segments/dataExport/createEdit/views/reportInfo',["jQuery", "Underscore", "Hogan", "T1", "T1View", "T1Layout", "text!../templates/report-info.html"], function ($, _, Hogan, T1, T1View, T1Layout, template) {
  "use strict";

  var DataExportModel;
  var ReportInfo = {
    Overlap: {
      title: "Segment Overlap Report",
      description: "Find out how many users exist within the intersection of any combination of segments. Report metrics will include Uniques, AB Uniques, %A in B, %B in A, Total % Overlap.",
      url: "https://mediamathsupport.force.com/s/article/Data-Export-Overview"
    },
    Performance: {
      title: "Segment Performance Report",
      description: "Find out how segments are performing by campaign and strategy. Report metrics will include: unique users, total campaign impressions, total segment users, post-view conversions, post-click conversions, click through rate (CTR), CTC, response rate, CPM, CPC, CPA. View example report.",
      url: "https://mediamathsupport.force.com/s/article/Data-Export-Overview"
    }
  };
  return T1View.extend({
    template: template,
    events: {},
    eventhubEvents: {
      "createEdit.OverlapSelected": "showReportInfo",
      "createEdit.PerformanceSelected": "showReportInfo"
    },
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    load: function load() {
      var self = this;
      var $el = this.el;
      $el.css("visibility", "hidden");
      this.render().then(function render() {
        if (!DataExportModel.isReportSelected()) {
          $el.css("visibility", "hidden");
        }
      });
    },
    showReportInfo: function () {
      var $el = this.el;
      this.load();
      $el.css("visibility", "visible");
    },
    unload: function unload() {},
    serialize: function serialize() {
      var selectedReport = DataExportModel.getSelectedReport();
      if (selectedReport && selectedReport in ReportInfo) {
        return ReportInfo[selectedReport];
      } else {
        return {
          title: "No report Selected",
          description: ""
        };
      }
    }
  });
});
