define('models/t1App',["Underscore", "T1", "T1Model", "models/userPreferences", "models/sessionUser", "jslibs/xdate/xdate", "T1Preferences", "T1Signature"], function (_, T1, T1Model, UserPreferences, User, XDate) {
  "use strict";

  var instance;
  var T1App = T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    url: "v1.0/apps",
    fixture: "-t1App",
    sessionModel: new (T1Model.extend({
      url: "session/clone",
      fixture: "-t1AppSessionID"
    }))(),
    initialize() {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["scope", "t1app"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
    },
    appLaunched: "",
    createCfgs(data) {
      const today = new XDate(true);
      const twoWeeks = 14;
      const oneMonth = 30;
      return data.map(item => {
        let lastUpdatedDiff, liveDiff;
        item.app_id = item.id;
        item.id = item.json_name;
        item.title = item.name;
        if (item.icon === "") {
          item.icon = false;
        }
        let liveDate = new XDate(item.live_date);
        let lastUpdated = new XDate(item.last_updated);
        if (liveDate.valid() !== false) {
          liveDate = new XDate(item.live_date, true);
          liveDiff = liveDate.diffDays(today);
        } else {
          liveDiff = false;
        }
        if (lastUpdated.valid() !== false) {
          lastUpdated = new XDate(item.last_updated, true);
          lastUpdatedDiff = lastUpdated.diffDays(today);
        } else {
          lastUpdatedDiff = false;
        }
        if (item.status === "beta") {
          item.flag = "beta";
        } else if (liveDiff <= oneMonth && liveDiff !== false) {
          item.flag = "new";
        } else if (lastUpdatedDiff <= twoWeeks && lastUpdatedDiff !== false) {
          item.flag = "updated";
        }
        return item;
      });
    },
    unload() {
      this.destroy();
    },
    sortApps(field) {
      var list = this.get("t1AppsCfgsArray");
      const result = _.sortBy(list, app => app[field]);
      this.set({
        t1AppsCfgsArray: result
      });
    },
    loadAppsConfig() {
      var user = User.getUser();
      this.fetch({
        params: {
          userId: user.get("id"),
          q: "lab==0"
        },
        dataType: "text",
        onSuccess: data => {
          this.set(data.apps);
          this.set({
            assets_path: data.assets_path,
            t1AppsCfgsArray: this.createCfgs(data.data)
          });
          this.trigger("change:T1AppsConfig");
        },
        processAjaxResponse: function (resp) {
          if (resp.indexOf("<?xml") !== -1) {
            throw new Error("App Config data not JSON");
          }
          return {
            jsonData: JSON.parse(resp),
            statusCode: "ok"
          };
        }
      });
    },
    loadClonedSessionID() {
      this.sessionModel.save({
        api_key: T1.API_KEY
      }, {
        onSuccess: data => {
          this.sessionModel.set({
            sessionID: data.session.sessionid
          });
          this.trigger("change:T1AppsSessionID");
        }
      });
    }
  });
  function getT1App(args) {
    if (instance === undefined) {
      instance = new T1App(args);
    }
    return instance;
  }
  return {
    getT1App: getT1App
  };
});
