define('modules/campaign/models/mmChartModel',['require','jQuery','moment','models/reportingAbstractModel','T1'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const moment = require("moment");
  const RPTGAbstractModel = require("models/reportingAbstractModel");
  const T1 = require("T1");
  const dateFormat = "YYYY-MM-DD";
  const dateWithHourFormat = "YYYY-MM-DD HH:mm:ss";
  const displayFormat = "MMM DD, YYYY";
  const MMChartModel = RPTGAbstractModel.extend({
    initialize(options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    getColorList(data) {
      let color;
      let colors = [];
      const colorArr = this.reportMeta.additionalData.chartColors;
      return data.map(() => {
        if (colors.length) {
          color = colors.shift();
        } else {
          colors = $.extend([], colorArr);
          color = colors.shift();
        }
        return color;
      });
    },
    convertHex(hex, opacity) {
      const color = hex.replace("#", "");
      const redEndIdx = 2;
      const greenEndIdx = 4;
      const blueEndIdx = 6;
      const r = parseInt(color.substring(0, redEndIdx), 16);
      const g = parseInt(color.substring(redEndIdx, greenEndIdx), 16);
      const b = parseInt(color.substring(greenEndIdx, blueEndIdx), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    formatValue(value, type) {
      return type === "currency" ? this.currencyFormatter(value, "USD") : this.dataFormatter(value, type);
    },
    currencyFormatter(value, currencyCode) {
      return T1.Utils.formatCurrency(value, null, currencyCode);
    },
    dataFormatter(value, format) {
      const formatter = T1.Formats.as.call(this, format);
      return formatter(value);
    },
    getDateFormat() {
      return dateFormat;
    },
    getDisplayFormat() {
      return displayFormat;
    },
    setStartEndDate(startEndDate, silent) {
      this.set({
        reportDates: startEndDate
      }, {
        silent: silent
      });
      this.setTimeSlots(silent);
    },
    getStartEndDate() {
      return this.get("reportDates") || {};
    },
    setInterval(interval, silent) {
      this.set({
        interval: interval
      });
      this.setTimeSlots(silent);
    },
    getInterval() {
      return this.get("interval");
    },
    getMinPeriod() {
      return this.getInterval() === "by_hour" ? "hh" : "DD";
    },
    getTooltipFormat() {
      return this.getInterval() === "by_hour" ? "ddd ha, MMM D, YYYY" : "ddd, MMM D, YYYY";
    },
    setTimeSlots(silent) {
      const {
        start: start,
        end: end
      } = this.getStartEndDate();
      let startDate = moment(start, dateFormat);
      const endDate = moment(`${end} 23:59:59`, dateWithHourFormat);
      const dates = [];
      const interval = this.getInterval();
      const isByHour = interval === "by_hour";
      const intervalType = isByHour ? "hours" : "days";
      const format = isByHour ? dateWithHourFormat : dateFormat;
      while (startDate.isSameOrBefore(endDate)) {
        dates.push(startDate.format(format));
        startDate = startDate.add(1, intervalType);
      }
      this.set({
        timeSlots: dates
      }, {
        silent: silent
      });
    },
    getTimeSlots() {
      return this.get("timeSlots") || [];
    },
    getIsChartCrowded(unit) {
      const {
        start: start,
        end: end
      } = this.getStartEndDate();
      const startDate = moment(start, dateFormat);
      const endDate = moment(end, dateFormat);
      const hourlyMaxDays = 6;
      const dailyMaxDays = 180;
      const crowdDate = endDate.subtract(unit === "hourly" ? hourlyMaxDays : dailyMaxDays, "days");
      return startDate.isBefore(crowdDate);
    },
    setEntity(entity, silent) {
      this.set({
        entity: entity
      }, {
        silent: true
      });
      if (!silent) {
        this.trigger("change:entity", this, entity);
      }
    },
    setEntityType(entityType, silent) {
      this.set({
        entityType: entityType
      }, {
        silent: silent
      });
    },
    getEntityType() {
      return this.get("entityType");
    },
    setChartType(chartType, silent) {
      this.set({
        chartType: chartType
      }, {
        silent: silent
      });
    },
    getChartType() {
      return this.get("chartType");
    },
    setViewByDimension(dimension, silent) {
      this.set({
        viewBy: dimension
      }, {
        silent: silent
      });
    },
    getViewByDimension() {
      return this.get("viewBy");
    },
    setHighestLowest(highestLowest) {
      const label = this.getSelectedMetricLabel(highestLowest.metric);
      const trim = 2;
      highestLowest.order = highestLowest.range.substring(0, trim);
      highestLowest.limit = highestLowest.order === "al" ? "" : Number(highestLowest.range.substring(trim));
      switch (highestLowest.order) {
        case "hi":
          highestLowest.title = `Highest ${highestLowest.limit} ${label}`;
          break;
        case "lo":
          highestLowest.title = `Lowest ${highestLowest.limit} ${label}`;
          break;
        default:
          highestLowest.title = `All ${label}`;
      }
      this.set({
        highestLowest: highestLowest
      });
    },
    getHighestLowest() {
      return this.get("highestLowest");
    },
    setSelectedMetrics(entityType, chartType, metricsObj) {
      const selectedMetrics = this.get("selectedMetrics") || {
        campaign: {
          daily: [],
          summary: []
        },
        strategy: {
          daily: [],
          summary: []
        }
      };
      metricsObj.label = this.getSelectedMetricLabel(metricsObj.value);
      selectedMetrics[entityType][chartType][metricsObj.id] = metricsObj;
      this.set({
        selectedMetrics: selectedMetrics
      });
    },
    getSelectedMetrics() {
      return this.get("selectedMetrics");
    },
    getSelectedMetricLabel(metricValue) {
      const metrics = this.getDDLmetricOptions();
      const metricObj = metrics.find(({
        value: value
      }) => value === metricValue) || {};
      return metricObj.label || "";
    },
    getSelectedMetricType(metricValue) {
      const metricOptions = this.reportMeta.get("metricOptions");
      const metricObj = metricOptions.find(({
        value: value
      }) => value === metricValue);
      return metricObj.type || "";
    },
    setDDLmetricOptions() {
      const metricOptions = this.reportMeta.get("metricOptions");
      const ddlMetricOptions = metricOptions.map(({
        value: value,
        text: label
      }) => ({
        value: value,
        label: label
      }));
      this.set({
        ddlMetricOptions: ddlMetricOptions
      }, {
        silent: true
      });
      this.trigger("change:ddlMetricOptions", this, ddlMetricOptions);
    },
    getDDLmetricOptions() {
      return $.extend(true, [], this.get("ddlMetricOptions"));
    }
  });
  return MMChartModel;
});
