define('collections/agencies',["jQuery", "T1", "T1Collection", "models/agency"], function ($, T1, T1Collection, Agency) {
  T1.Models.Agencies = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: Agency,
    filterConfiguration: {
      filters: [{
        entity: "organization",
        fetchOnChange: false
      }],
      enableEvents: false
    },
    currentSearchConfig: [],
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        },
        status: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    urlString: "agencies",
    applySearchFilter: function (field, term) {
      var searchType;
      var fieldIndex = -1;
      if (field !== undefined && term !== undefined) {
        searchType = {
          term: term,
          searchField: field
        };
        $.each(this.currentSearchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
            return;
          }
        });
        if (fieldIndex === -1) {
          if (term !== "") {
            this.currentSearchConfig.push(searchType);
          }
        } else {
          if (term === "") {
            this.currentSearchConfig.splice(fieldIndex, 1);
          } else {
            this.currentSearchConfig[fieldIndex] = searchType;
          }
        }
      }
    },
    filterBy: function (field, term, success) {
      var self = this;
      var onSuccess = success || $.noop;
      this.applySearchFilter(field, term);
      this.search.set({
        params: this.currentSearchConfig,
        fetchRequired: true,
        success: function (coll) {
          self.trigger("reset", this);
          onSuccess(coll);
        }
      });
    },
    getSelectedAgencies: function () {
      var checkedModels = this.filter(function (model) {
        return model.get("checked") === true;
      });
      return new T1Collection(checkedModels);
    },
    toggleAllSelected: function (isChecked, isSilent) {
      var isSilent = isSilent || false;
      this.each(function (model) {
        model.set({
          checked: isChecked
        }, isSilent);
      });
    }
  });
  return T1.Models.Agencies;
});
