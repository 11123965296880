define('models/targetDimension',["jQuery", "T1", "Backbone", "T1Model", "models/strategyTargetValue"], function ($, T1, Backbone, T1Model, StrategyTargetValue) {
  T1.Models.TargetDimension = T1Model.extend({
    url: "target_dimensions/",
    fixture: "-target_dimension",
    relations: [{
      type: Backbone.HasMany,
      key: "target_value",
      relatedModel: "StrategyTargetValue",
      collectionType: "T1Collection",
      includeInJSON: true,
      reverseRelation: {
        key: "parent",
        includeInJSON: false
      }
    }],
    search: function (term) {
      var isId = term.indexOf("#") === 0;
      var result = [];
      var valueHash = this.get("target_hash");
      var parent = this.toJSON();
      var highlightText = T1.Utils.highlightText;
      function getParentName(id) {
        var name;
        if (!id) {
          return parent.name + " &raquo; ";
        }
        name = valueHash[id].name;
        if (name !== "ROOT") {
          name = name + " &raquo; ";
          return name;
        }
        return "";
      }
      if (isId && valueHash[term.substr(1)]) {
        result.push(valueHash[term.substr(1)]);
      } else {
        $.each(valueHash, function (id, object) {
          if (object.name.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
            object.name = highlightText(object.name, term);
            object.nameWithParent = getParentName(object.parent_value_id) + object.name;
            result.push(object);
          }
        });
      }
      return result;
    }
  });
  return T1.Models.TargetDimension;
});
