define('modules/auth/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    name: "auth",
    viewCfgs: {
      bab: {},
      login: {
        mode: "dialog"
      },
      logout: {},
      resetPassword: {},
      unauthorized: {}
    },
    defaultView: "login"
  });
});
