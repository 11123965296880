define('modules/siteList/views/grid',["jQuery", "T1", "T1View", "T1GridView", "text!modules/siteList/templates/grid.html"], function ($, T1, T1View, createGrid, itemTemplate) {
  "use strict";

  var GridView = T1View.extend({
    selected: "",
    partials: {
      listItem: itemTemplate
    },
    headers: [{
      name: "name",
      label: "site list name"
    }, {
      name: "assignedToCampaign",
      label: "&nbsp;"
    }, {
      name: "type",
      label: "type"
    }, {
      name: "domains",
      label: "# domains"
    }, {
      name: "apps",
      label: "# apps"
    }, {
      name: "lastUpdated",
      label: "last updated"
    }, {
      name: "siteId",
      label: "id"
    }, {
      name: "download",
      label: ""
    }],
    dataEvents: {},
    events: {
      "click .item .include": "includeExcludeSiteList",
      "click .item .exclude": "includeExcludeSiteList"
    },
    includeExcludeSiteList: function (event) {
      var currentTarget = $(event.currentTarget);
      var id = currentTarget.closest(".item").data("id");
      this.preventDefault(event);
      this.removeDragClasses();
      this.assignedSiteList({
        id: id
      });
    },
    eventhubEvents: {
      "assigned:siteList": "assignedSiteList",
      "bins:droppedSiteList": function (data) {
        var self = this;
        var sharedCollection = self.sharedCollection;
        $.each(data.selectedList, function (index, item) {
          var model = sharedCollection.get(item.id);
          if (model && model.get("assigned") === "0" || model === undefined) {
            self.assignedSiteList.call(self, item);
          }
        });
      },
      "siteList:setSearchLoader": function () {
        this.setSearchLoader();
      }
    },
    assignedSiteList: function (data) {
      var assignToggle;
      var id = data.id;
      var itemInSharedCollection;
      var self = this;
      var item = this.collection.get(id);
      itemInSharedCollection = self.sharedCollection.get(id);
      if (itemInSharedCollection) {
        item.set({
          campaign_assigned: itemInSharedCollection.get("campaign_assigned")
        });
        self.sharedCollection.remove(item);
      }
      assignToggle = itemInSharedCollection && itemInSharedCollection.get("assigned") === "1" ? "0" : "1";
      item.set({
        assigned: assignToggle
      });
      self.sharedCollection.add(item);
    },
    initialize: function (args) {
      var self = this;
      if (args.collection) {
        self.collection = args.collection;
        this.addDataEvent({
          collection: {
            reset: function () {
              this.load();
            }
          }
        });
      }
      if (args.sharedCollection) {
        self.sharedCollection = args.sharedCollection;
        this.addDataEvent({
          sharedCollection: {
            add: function (data) {
              var $item = self.el.find('[data-id="' + data.id + '"]');
              var $itemRow = $item.children(".item-row");
              if (data.get("assigned") === "1") {
                $itemRow.addClass("selected").find(".icon").addClass("active").end().parent().addClass("selected-item");
              }
              this.isFormElementsChanged = true;
            },
            remove: function (data) {
              var $item = self.el.find('[data-id="' + data.id + '"]');
              var $itemRow = $item.children(".item-row");
              $itemRow.removeClass("selected").find(".icon").removeClass("active").end().parent().removeClass("selected-item");
              this.isFormElementsChanged = true;
            }
          }
        });
      }
      this.preventDoubleBind = true;
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 200
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".name"
    },
    serialize: function (inputCollection) {
      var sharedCollection = this.sharedCollection;
      var siteListData = [];
      var collection = inputCollection || this.collection;
      var collectionData;
      var isCampaign = sharedCollection.urlString === "campaigns";
      var collectionLength = 0;
      var collectionCount = 0;
      if (collection) {
        collectionData = collection.toJSON();
        collectionLength = collection.length;
        collectionCount = collection.count;
        $.each(collectionData, function (index, siteList) {
          var siteData = siteList;
          var sharedSite;
          siteData.isAllowList = siteList.restriction === "INCLUDE";
          siteData.isBlockList = siteList.restriction === "EXCLUDE";
          siteData.downloadLocation = COMPASS_ENV.API_BASE + "site_lists/" + siteList.id + "/download.csv";
          if (sharedCollection) {
            sharedSite = sharedCollection.get(siteList.id);
            if (sharedSite && sharedSite.get("assigned") === "1") {
              siteData.isIncluded = true;
              siteData.isSelected = true;
              siteData.assigned = "1";
            }
          }
          if (sharedSite) {
            siteData.assignedToCampaign = !isCampaign && sharedSite.get("campaign_assigned") === "1";
          }
          siteListData.push(siteData);
        });
      }
      return {
        list: siteListData,
        contentType: "site-list-grid",
        pageControls: {
          count: collectionLength,
          total: collectionCount
        }
      };
    }
  });
  return createGrid(GridView);
});
