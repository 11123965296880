define('models/organizationSetting',['require','T1','T1Model'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1Model = require("T1Model");
  T1.Models.OrganizationSetting = T1Model.extend({
    name: "organization",
    url() {
      return `${this.apiRoot}organizations/${this.id}/settings?currentTime=${T1.getRandomNumber()}`;
    },
    parse(data) {
      if (data && data.settings) {
        return Object.assign(...Object.entries(data.settings.prop).map(([, obj]) => ({
          [obj.name]: obj.value
        })));
      }
    },
    saveSettings(postObject, successCallback, statusInvalid) {
      this.set({
        version: this.get("version") ? parseInt(this.get("version"), 10) + 1 : 0
      });
      this.save(postObject, {
        conflict: statusInvalid,
        statusInvalid: statusInvalid,
        success: successCallback || function () {}
      });
    }
  });
  return T1.Models.OrganizationSetting;
});
