define('modules/creatives/display/control/formatters',[], function () {
  var identity = function (val) {
    return val;
  };
  var yesNo = function (val) {
    return val === "1" ? "Yes" : "No";
  };
  var file_type = function (val) {
    val = val === "" ? "unknown" : val;
    return val === "unknown" ? val : val.toUpperCase();
  };
  var ad_server_type = function (val) {
    var list = {
      OTHER: "Other",
      TERMINALONE: "TerminalOne",
      TERMINALONESZ: "TerminalOneSZ",
      ATLAS: "Atlas",
      DART: "DFA",
      EYEWONDER: "EyeWonder",
      MEDIAFORGE: "mediaFORGE",
      MEDIAMIND: "Sizmek MDX",
      MEDIAPLEX: "Mediaplex",
      POINTROLL: "Pointroll",
      YIELD_MANAGER: "Yield Manager",
      DOUBLECLICK: "DoubleClick",
      FLASHTALKING: "flashtalking",
      ADOCADO: "adocado",
      SIZMEK: "Sizmek"
    };
    return list[val] || val;
  };
  var expansion_trigger = function (val) {
    return {
      AUTOMATIC: "Automatic",
      MOUSEOVER: "Mouse-Over to Expand",
      CLICK: "Click to Expand"
    }[val];
  };
  var ad_format = function (val) {
    return {
      DISPLAY: "Display",
      MOBILE: "Mobile",
      EXPANDABLE: "Expandable",
      FBX: "FBX",
      HTML5: "HTML5"
    }[val];
  };
  var formatDate = function (val) {
    var df = "MM/dd/yy";
    if (val && val !== "") {
      return Date.parse(val).toString(df);
    }
    return null;
  };
  var specialFormatters = {
    is_https: yesNo,
    is_mraid: yesNo,
    has_sound: yesNo,
    file_type: file_type,
    ad_server_type: ad_server_type,
    expansion_trigger: expansion_trigger,
    ad_format: ad_format,
    last_modified: formatDate,
    start_date: formatDate,
    end_date: formatDate,
    created_on: formatDate
  };
  return function chooseFormatter(attr) {
    var specialFormatter = specialFormatters[attr];
    return specialFormatter || identity;
  };
});
