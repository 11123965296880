define('modules/reporting/campaigns/dataExport/createEdit/views/footer',["jQuery", "T1", "T1View", "models/sessionUser", "text!../templates/footer.html", "text!../templates/footerSpinner.html"], function ($, T1, T1View, User, template, footerSpinner) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const T1Location = T1.Location;
  const TIME_TO_VIEW_MSG = 5e3;
  return T1View.extend({
    template: template,
    events: {
      "click .cancel-btn": "cancelReportHandler",
      "click .export-btn": "saveExportHandler",
      "click .modal-cancel-btn": "cancelDialogHandler",
      "click .modal-dont-save-btn": "dontSaveDialogHandler",
      "click .modal-save-btn": "saveDialogHandler",
      "click .save-btn": "saveExportHandler",
      "click .save-export-btn": "saveExportHandler",
      "click .save-new-btn": "saveExportHandler"
    },
    eventhubEvents: {
      "change:formElements": "updateFormElementsChanged",
      "createEdit.footerError": "showMessage",
      "createEdit.footerHideModal": "hideModal",
      "createEdit.footerResetBtns": "resetButtons",
      "createEdit.footerShowSpinner": "showSpinner",
      "dataExport.footerResetBtns": "resetButtons",
      "dataExport.showFooterSaveAsNewBtn": "showSaveAsNewButton",
      "saved:report": function (args) {
        this.showMessage(args);
        this.resetButtons();
      }
    },
    initialize: function (model) {
      this.DataExportModel = model;
    },
    load: function () {
      const $el = this.el;
      const self = this;
      const currentLocation = T1Location.get(true);
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        self.$footer = $el.find(".export-footer");
        self.$modal = $el.find(".unsaved-modal");
        self.$saveAsNewBtn = self.$footer.find(".save-new-btn");
        if (currentLocation.includes("edit")) {
          self.showSaveAsNewButton();
        }
      });
    },
    unload: function () {
      this.$footer = null;
      this.$modal = null;
      this.formElementsChanged = null;
    },
    updateFormElementsChanged: function (state) {
      const self = this;
      this.formElementsChanged = state;
      if (state) {
        this.showMessage({
          msg: "There are unsaved changes on the page",
          timeout: false,
          type: "warning"
        });
      } else {
        this.hideMessage();
      }
      T1Publish("update:canUnload", {
        formElementsChanged: this.formElementsChanged,
        cancelUnloadFn: function (args) {
          self.showModal(args);
        }
      });
    },
    saveExportHandler: function (event) {
      T1Publish("footer.saveExportReport", {
        event: event
      });
    },
    cancelReportHandler: function (event) {
      event.preventDefault();
      if (this.formElementsChanged) {
        this.showModal();
      } else {
        this.dontSaveDialogHandler(event);
      }
    },
    cancelDialogHandler: function (event) {
      event.preventDefault();
      T1Location.resetAbort();
      this.hideModal();
    },
    dontSaveDialogHandler: function (event) {
      const areWeLeavingReporting = T1Location.getAbortedURL();
      const areWeSwitchingTabs = this.targetTabCall;
      event.preventDefault();
      this.updateFormElementsChanged(false);
      switch (true) {
        case Boolean(areWeLeavingReporting):
          T1Location.goToAbortedURL();
          break;
        case Boolean(areWeSwitchingTabs):
          this.hideModal();
          this.targetTabCall.onAlertClose();
          break;
        default:
          T1Publish("update:view", {
            location: "#reports/campaigns/dataExport",
            target: "manage"
          });
      }
    },
    saveDialogHandler: function (event) {
      const areWeLeavingReporting = T1Location.getAbortedURL();
      const areWeSwitchingTabs = this.targetTabCall;
      this.updateFormElementsChanged(false);
      switch (true) {
        case Boolean(areWeLeavingReporting):
          T1Publish("footer.cancelSaveReport", {
            event: event,
            sectionTarget: areWeLeavingReporting
          });
          break;
        case Boolean(areWeSwitchingTabs):
          T1Publish("footer.cancelSaveReport", {
            event: event,
            tabTarget: this.targetTabCall.onAlertClose
          });
          break;
        default:
          T1Publish("footer.cancelSaveReport", {
            event: event
          });
      }
    },
    toggleButtons: function (disable, $target) {
      $target.find("strand-button").each(function () {
        this.disabled = disable;
      });
    },
    resetButtons: function () {
      const {
        $footer: $footer,
        $modal: $modal
      } = this;
      $footer.find(".save-btn label").html("Save");
      $footer.find(".save-new-btn label").html("Save as New");
      $footer.find(".export-btn label").html("Export File");
      $footer.find(".save-export-btn label").html("Save & Export File");
      $modal.find(".modal-save-btn label").html("Save");
      this.toggleButtons(false, $footer);
      this.toggleButtons(false, $modal);
    },
    showSaveAsNewButton: function () {
      if (this.DataExportModel.get("editReport").id) {
        this.$saveAsNewBtn.show();
      }
    },
    showSpinner: function (btnName) {
      const $target = btnName === "modal-save-btn" ? this.$modal : this.$footer;
      this.toggleButtons(true, $target);
      $target.find(`.${btnName} label`).html(footerSpinner);
    },
    showModal: function (args) {
      const reportName = this.DataExportModel.get("fileName") || "untitled report";
      const $modal = this.$modal;
      this.targetTabCall = args;
      $modal.find(".modal-report-name").text(reportName);
      $modal[0].show();
    },
    hideModal: function () {
      this.$modal[0].hide();
    },
    showMessage: function ({
      timeout: timeout,
      msg: msg,
      type: type
    }) {
      const footer = this.$footer[0];
      footer.message = msg;
      footer.type = type;
      footer.showMessage();
      if (timeout) {
        setTimeout(() => {
          this.hideMessage();
        }, TIME_TO_VIEW_MSG);
      }
    },
    hideMessage: function () {
      if (this.$footer) {
        const footer = this.$footer[0];
        footer.message = "";
        footer.type = "";
        footer.hideMessage();
      }
    }
  });
});
