define('modules/reporting/campaigns/siteTransparency/models/model',["jQuery", "Underscore", "T1", "models/reportingAbstractModel", "modules/reporting/campaigns/utils/reportingUtils", "moment"], function ($, _, T1, RPTGAbstractModel, Utils, moment) {
  "use strict";

  const COLOR_WHITE = "#fff";
  const formatCurrency = T1.Utils.formatCurrency;
  const formatAs = T1.Formats.as;
  const SiteTransparencyModel = RPTGAbstractModel.extend({
    searchTerm: "",
    searchedItems: [],
    initialize: function (options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    setSearchTerm: function (value) {
      this.searchTerm = value;
    },
    getSearchTerm: function () {
      return this.searchTerm;
    },
    setSearchedItems: function (searchedItems) {
      this.searchedItems = searchedItems;
    },
    getSearchedItems: function () {
      return this.searchedItems;
    },
    setSiteList: function (siteList) {
      this.set({
        siteList: siteList
      });
      this.setSiteLookup(siteList);
    },
    setSiteLookup: function (siteList) {
      const length = siteList.length;
      const lookupTable = this.get("siteLookup") || {};
      let i = 0;
      const sitesMarked = [];
      let marked, site, siteId;
      while (i < length) {
        site = siteList[i];
        marked = site.marked;
        siteId = site.id;
        lookupTable[siteId] = {
          marked: marked,
          index: i++
        };
        if (marked && !site.invalid) {
          sitesMarked.push(siteId);
        }
      }
      this.set({
        siteLookup: lookupTable,
        sitesMarked: sitesMarked,
        sitesMarkedCount: sitesMarked.length
      });
    },
    resetSiteLookup: function () {
      this.set({
        siteLookup: null,
        validSiteList: null
      });
    },
    setMarkedSite: function (itemID, isMasterCheckbox, isChecked) {
      const siteList = this.get("siteList");
      const siteLookup = this.get("siteLookup");
      let siteIndex;
      let i = 0;
      if (isMasterCheckbox) {
        while (i < siteList.length) {
          siteList[i].marked = isChecked;
          i++;
        }
      } else {
        siteIndex = siteLookup[itemID].index;
        siteList[siteIndex].marked = !siteList[siteIndex].marked;
      }
      this.setSiteLookup(siteList);
    },
    setSiteFilter: function (siteFilterObj) {
      this.set({
        siteFilterExcludeZeros: siteFilterObj.excludeZeros,
        siteFilterMetric: siteFilterObj.metric,
        siteFilterRange: siteFilterObj.range
      });
    },
    getSiteValidation: function (domain) {
      return Utils.validateDomain(domain);
    },
    formatValue: function (value, type) {
      if (type === "currency") {
        value = formatCurrency(value, null, "USD");
      } else {
        value = formatAs(type)(value);
      }
      return value;
    },
    _showEmptyChartData: function (data) {
      let isChartEmpty = false;
      const searchTerm = this.getSearchTerm();
      const sitesHidden = this.getHiddenItemsCount();
      if (searchTerm !== "") {
        let searchedHiddenDomain = 0;
        const hiddenItems = this.getHiddenItems();
        const searchedDomains = this.getSearchedItems() || [];
        const searchedDomainsCount = searchedDomains.length;
        if (sitesHidden > 0) {
          _.each(searchedDomains, function (domain) {
            if (hiddenItems[domain] === true) {
              searchedHiddenDomain++;
            }
          });
          if (searchedDomainsCount - searchedHiddenDomain === 0) {
            isChartEmpty = true;
          }
        }
      } else {
        if (sitesHidden === data.length) {
          isChartEmpty = true;
        }
      }
      return isChartEmpty;
    },
    _filterData: function (dimensionKey) {
      let data = this.get("searchedData");
      const hiddenItems = this.get("hiddenItems") || {};
      if (!_.isEmpty(hiddenItems)) {
        _.each(hiddenItems, function (value, hiddenItem) {
          data = _.filter(data, function (dataItem) {
            return dataItem[dimensionKey] !== hiddenItem;
          });
        });
      }
      this.set({
        filteredData: data
      });
    },
    _processSearchData: function (section, chartData, dimension) {
      const searchTerm = this.getSearchTerm().toLowerCase();
      let searchedData = [];
      let searchedDomains = [];
      if (searchTerm === "") {
        this.set({
          searchedData: chartData
        });
        this.setSearchedItems(searchedDomains);
        return chartData;
      }
      searchedData = _.reject(chartData, function (data) {
        return searchTerm !== "" && data.site_domain.toLowerCase().indexOf(searchTerm) === -1;
      });
      if (section === "daily") {
        searchedDomains = _.chain(searchedData).groupBy(item => item[dimension]).keys().value();
      } else {
        searchedDomains = _.pluck(searchedData, dimension);
      }
      this.setSearchedItems(searchedDomains);
      this.set({
        searchedData: searchedData
      });
    },
    _createScatterDataProvider: function (dimension, xKey, yKey) {
      const dataProvider = [];
      const filteredData = this.get("filteredData");
      _.each(filteredData, function (item) {
        const dataItem = {};
        dataItem[`${item[dimension]}_X`] = item[xKey];
        dataItem[`${item[dimension]}_Y`] = item[yKey];
        dataProvider.push(dataItem);
      });
      this.set({
        dataProvider: dataProvider
      });
    },
    _createScatterGraphs: function (dimension, colorData, animateChart) {
      const dotColors = [];
      const filteredData = this.get("filteredData");
      const chartColors = this.reportMeta.get("chartColors");
      function tooltipFunction(item) {
        const xAxisLabel = this.get("xAxisLabel");
        const yAxisLabel = this.get("yAxisLabel");
        const xMetricObj = this.get("xMetricObj");
        const yMetricObj = this.get("yMetricObj");
        return `<p style="font-family:AB;">${item.graph.title}</p><br>\n          ${xAxisLabel}: ${this.formatValue(item.values.x, xMetricObj.type)}<br>\n          ${yAxisLabel}: ${this.formatValue(item.values.y, yMetricObj.type)}<br>\n          &nbsp;`;
      }
      const graphs = _.map(filteredData, dataItem => {
        const dimensionValue = dataItem[dimension];
        const color = chartColors[colorData[dimensionValue]];
        dotColors.push(color);
        return {
          animationPlayed: animateChart,
          balloonFunction: tooltipFunction.bind(this),
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 10,
          id: dimensionValue,
          labelPosition: "right",
          lineColor: color,
          title: dimensionValue,
          xField: `${dimensionValue}_X`,
          yField: `${dimensionValue}_Y`
        };
      });
      this.set({
        dotColors: dotColors,
        graphs: graphs
      });
    },
    _createEmptyScatterData: function () {
      const dataProvider = [{
        dummyData_X: "",
        dummyData_Y: ""
      }];
      const graphs = [{
        bullet: "round",
        bulletAlpha: 0,
        bulletBorderAlpha: 0,
        id: "",
        title: "name",
        xField: "dummyData_X",
        yField: "dummyData_Y"
      }];
      this.set({
        dataProvider: dataProvider,
        graphs: graphs
      });
    },
    getScatterChartData: function (dimension, xKey, yKey, colorData, animateChart) {
      let data, dataProvider, graphs;
      const collectionData = this.collectionData;
      if (collectionData && collectionData.length) {
        data = collectionData.toJSON();
        this._processSearchData("summary", data, dimension);
        this._filterData(dimension);
        if (this._showEmptyChartData(data)) {
          this._createEmptyScatterData();
        } else {
          this._createScatterDataProvider(dimension, xKey, yKey);
          this._createScatterGraphs(dimension, colorData, animateChart);
        }
        dataProvider = this.get("dataProvider");
        graphs = this.get("graphs");
      }
      return {
        dataProvider: dataProvider || [],
        graphs: graphs || []
      };
    },
    _parseData: function (yKey, timeSlots, dimensionKey) {
      const data = this.get("filteredData");
      const parsedData = _.chain(data).groupBy(function (model) {
        return model[dimensionKey];
      }).map(function (arr, key) {
        var values = [];
        var name = "";
        _.each(timeSlots, function (startDate) {
          var dataSet = _.find(arr, function (item) {
            return startDate === item.start_date;
          });
          if (dataSet) {
            name = dataSet[dimensionKey];
          }
          values.push(dataSet ? dataSet[yKey] : "");
        });
        return {
          id: key,
          name: name,
          values: values
        };
      }).value();
      this.set({
        parsedData: parsedData
      });
    },
    _createLineDataProvider: function (dimension, timeSlots) {
      const dataProvider = timeSlots.map(start_date => ({
        date: start_date
      }));
      const parsedData = this.get("parsedData");
      dataProvider.forEach(function (item, index) {
        parsedData.forEach(function (data) {
          item[data.id] = data.values[index];
        });
      });
      this.set({
        dataProvider: dataProvider
      });
    },
    _createLineGraphs: function (dimension, colorData, animateChart) {
      const parsedData = this.get("parsedData");
      const chartColors = this.reportMeta.get("chartColors");
      const dotColors = [];
      const MAX_LENGTH = 60;
      function tooltipFunction(item) {
        let title = item.graph.title || "";
        const yAxisLabel = this.get("yAxisLabel");
        const yMetricObj = this.get("yMetricObj");
        if (title.length > MAX_LENGTH) {
          title = title.match(/([\S\s]{1,60})/g);
        }
        return `<p style="font-family:AB;">${title.toString().replace(/,/g, "<br>")}</p><br>\n          ${yAxisLabel}: ${this.formatValue(item.values.value, yMetricObj.type)}<br>\n          ${moment(item.category).format("ddd, MMM D, YYYY")}<br>&nbsp;`;
      }
      const graphs = _.map(parsedData, data => {
        const value = data.id;
        const color = chartColors[colorData[value]];
        dotColors.push(color);
        return {
          animationPlayed: animateChart,
          balloonFunction: tooltipFunction.bind(this),
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 6,
          hideBulletsCount: 100,
          id: value,
          lineColor: color,
          lineThickness: 1.5,
          title: value,
          type: "smoothedLine",
          valueField: value
        };
      });
      this.set({
        dotColors: dotColors,
        graphs: graphs
      });
    },
    _createEmptyLineData: function (timeSlots) {
      const dataProvider = timeSlots.map(start_date => ({
        date: start_date
      }));
      dataProvider.forEach(function (item) {
        item.dummyData = "";
      });
      const graphs = [{
        balloonFunction: () => "",
        bulletAlpha: 0,
        id: "dummyData",
        lineAlpha: 0,
        lineColor: COLOR_WHITE,
        title: "dummyData",
        valueField: "dummyData"
      }];
      this.set({
        dataProvider: dataProvider,
        graphs: graphs
      });
    },
    getLineChartData: function (dimension, yKey, colorData, animateChart) {
      let dataProvider, graphs;
      const dailyData = this.get("dailyData");
      const timeSeriesSlots = this.get("timeSeriesSlots") || {
        slots: []
      };
      const tsSlots = _.pluck(timeSeriesSlots.slots, "start_date");
      if (dailyData && dailyData.length) {
        this._processSearchData("daily", dailyData.toJSON(), dimension);
        this._filterData(dimension);
        this._parseData(yKey, tsSlots, dimension);
        if (this._showEmptyChartData(this.collectionData)) {
          this._createEmptyLineData(tsSlots);
        } else {
          this._createLineDataProvider(dimension, tsSlots);
          this._createLineGraphs(dimension, colorData, animateChart);
        }
        dataProvider = this.get("dataProvider");
        graphs = this.get("graphs");
      }
      return {
        dataProvider: dataProvider || [],
        graphs: graphs || []
      };
    }
  });
  return SiteTransparencyModel;
});
