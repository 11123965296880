define('modules/admin/vendors/views/grid',['require','jQuery','utils/AdminUtils','text!../templates/grid_item.html','collections/organizations','T1','T1GridView','T1Menu','T1View'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const grid_item = require("text!../templates/grid_item.html");
  const Organizations = require("collections/organizations");
  const T1 = require("T1");
  const T1GridView = require("T1GridView");
  const T1Menu = require("T1Menu");
  const T1View = require("T1View");
  var isFireFox = navigator.userAgent.indexOf("Firefox") >= 0;
  var VendorsGridView = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Vendors",
        contentClass: "search-loader loader-overrides admin-infinite",
        action: "append"
      }
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "checkbox-input",
      label: '<div class="checkbox all"></div>'
    }, {
      name: "vendor",
      label: "Vendor Name"
    }, {
      name: "id",
      label: "ID"
    }, {
      name: "type",
      label: "Vendor<br/>Type"
    }, {
      name: "adx_ssl_app",
      label: "ADX Approved<br/>(Secure)"
    }, {
      name: "adx_app",
      label: "ADX Approved<br/>(Non-Secure)"
    }, {
      name: "adx_video_ssl_approved",
      label: "AdX Video<br/>Approved (Secure)"
    }, {
      name: "adx_video_approved",
      label: "AdX Video<br/>Approved (Non-Secure)"
    }, {
      name: "adx_declare",
      label: "Declare to<br/>AdX"
    }, {
      name: "mm_resell",
      label: "MM Resells<br/>Service"
    }, {
      name: "pricing_type",
      label: "Pricing<br/>Type"
    }, {
      name: "rate_card_price",
      label: "Ratecard<br/>Price"
    }, {
      name: "wholesale_price",
      label: "Wholesale<br/>Price"
    }],
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Edit Vendor",
          visible: true
        }, {
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: true
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }]
      }]
    },
    events: {
      "click .admin-edit": "editVendor",
      "click .headers .checkbox": "handleAllChecked",
      "click .item .checkbox": "handleItemChecked",
      "click .settings-icon": "showOrApplySettingsMenu",
      "contextmenu .admin-edit": "applyEditHover",
      "contextmenu .config": "stopEvent",
      "mousemove .item-wrapper": "removeEditHover"
    },
    dataEvents: {
      collection: {
        reset: "reload",
        "change:selected": function (model) {
          this.updateItemCheckbox(model);
          this.updateAllCheckbox(model);
        },
        fetchMoreComplete: "updateAllCheckbox",
        "change:is_eligible": "updateActive"
      }
    },
    eventhubEvents: {
      "vendorGrid:reload": function () {
        this.setSearchLoader();
      }
    },
    initialize(args) {
      this.collection = args.collection;
      this.collection.refreshOnNextFetch();
      this.vendors = args.vendors;
    },
    load() {
      var self = this;
      if (!this.initDataRequested) {
        this.resetCollection();
      }
      Organizations.getOrganizations().getSelected().then(function (orgId) {
        if (orgId === self.orgId) {
          self.render().then(function () {
            if (!self.initDataRequested) {
              self.initDataRequested = true;
              self.setSearchLoader();
              self.getData(self.orgId);
            }
          });
        } else {
          self.orgId = orgId;
          self.load();
          self.initDataRequested = true;
          self.setSearchLoader();
          self.getData(orgId);
        }
      });
      this.loaded = true;
    },
    unload() {
      var coll = this.collection;
      if (this.loaded) {
        if (this.inSearch || this.activationUpdated || isFireFox) {
          this.inSearch = false;
          this.activationUpdated = false;
          this.initDataRequested = false;
          this.resetCollection();
          coll.refreshOnNextFetch();
        } else {
          this.resetCollection(true);
        }
        if (coll && !coll.loaded && coll.currentFetch) {
          coll.currentFetch.abort();
          if (coll.infiniteScrolling) {
            this.isFetching = coll.isFetching = false;
          } else {
            this.initDataRequested = false;
          }
        }
        coll.infiniteScrolling = false;
        if (this.renderQueue) {
          this.renderQueue.flush();
        }
        this.loaded = false;
      }
    },
    resetCollection(leaveFirstPageData) {
      var coll = this.collection;
      if (leaveFirstPageData) {
        const models = coll.models;
        coll.reset(models.slice(0, Math.min(models.length, coll.pageLimit)), {
          silent: true
        });
      } else {
        coll.reset(undefined, {
          silent: true
        });
        coll.loaded = false;
      }
    },
    getData() {
      var coll = this.collection;
      coll.canCache = false;
      coll.page = 0;
      coll.pageLimit = 50;
      coll.isPaginated = true;
      coll.fetchOptions = {
        full: "*",
        sort_by: "-updated_on"
      };
      coll.filterBy();
    },
    itemContentRendered($item) {
      this.applyTruncate($item);
      try {
        this.showOrApplySettingsMenu($item.find(".settings-icon"));
      } catch (e) {}
    },
    showOrApplySettingsMenu(eventOrTarget) {
      var $menuTarget = eventOrTarget.currentTarget ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Edit: 0,
        Deactivate: 1,
        Activate: 2
      };
      if ($menuTarget) {
        const id = $menuTarget.data("id");
        const model = this.collection.get(id);
        const isActive = model.get("is_eligible") === "1";
        menuItems[menuIndexRef.Edit].url = `#admin/vendor/${id}`;
        menuItems[menuIndexRef.Activate].visible = !isActive;
        menuItems[menuIndexRef.Deactivate].visible = isActive;
        menuConfig.dataID = `data-id=${id}`;
        menuConfig.menuTriggerEleSelector = `div[id="id${id}"] .settings-icon`;
      }
      T1Menu(menuConfig, this);
      if (eventOrTarget.currentTarget) {
        $menuTarget.trigger("click");
      }
    },
    updateActive(model) {
      var $el = this.el;
      var id = model.get("id");
      var active = model.get("is_eligible");
      var targetDiv = `div[id="id${id}"]`;
      var $item = $el.find(`${targetDiv} .item`);
      var $config = $item.find(".settings-icon");
      if (active === "0") {
        $item.addClass("inactive");
      } else {
        $item.removeClass("inactive");
      }
      this.showOrApplySettingsMenu($config);
    },
    deactivateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(false, evt);
    },
    activateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(true, evt);
    },
    activationHandler(toActivate, evt) {
      var self = this;
      var loader;
      var $currentTarget = $(evt.currentTarget);
      var id = $currentTarget.closest("dl").data("id");
      var active = toActivate === true ? "1" : "0";
      function stopLoader() {
        loader.stop();
      }
      if (id) {
        const model = self.collection.get(id);
        const targetDiv = `div[id="id${id}"]`;
        loader = T1.Loader.create({
          contentClass: "inline-edit-loader",
          target: targetDiv,
          spinnerConfig: {
            lines: 10,
            length: 4,
            radius: 3,
            left: 15,
            top: 4
          }
        });
        loader.start();
        model.updateStatus({
          is_eligible: active
        }, {
          success: stopLoader,
          conflict: stopLoader
        });
        if (this.vendors.isActive === "true") {
          this.activationUpdated = true;
        }
      }
    },
    handleItemChecked(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const $target = $(evt.target);
      const id = $target.data("id");
      const model = this.collection.get(id);
      let selected = model.get("selected") || false;
      if ($target.hasClass("disabled")) {
        selected = true;
      }
      model.set({
        selected: !selected
      });
      T1.EventHub.publish("change:itemSelectedChange");
    },
    handleAllChecked(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const selected = this.collection.getSelected();
      if (this.collection.length === selected.length) {
        this.collection.toggleAllSelected(false);
      } else {
        this.collection.toggleAllSelected(true);
      }
      T1.EventHub.publish("change:itemSelectedChange");
    },
    updateItemCheckbox(model) {
      var $checkbox = this.$(`#id${model.get("id")} .checkbox`);
      if (!$checkbox.hasClass("disabled")) {
        if (model.get("selected")) {
          $checkbox.addClass("checked");
        } else {
          $checkbox.removeClass("checked");
        }
      }
    },
    updateAllCheckbox() {
      var $allCheckBox = this.$(".header .checkbox");
      var selected = this.collection.getSelected();
      var outClass = "";
      if (this.collection.length === selected.length) {
        outClass = "checked";
      } else if (selected.length > 0) {
        outClass = "partial";
      }
      $allCheckBox.removeClass("checked partial").addClass(outClass);
    },
    editVendor(event) {
      var $currentTarget = $(event.currentTarget);
      var id = $currentTarget.data("id");
      var $panels = $(document).find("strand-tooltip");
      $panels.each(function (panel) {
        if ($panels[panel].close) {
          $panels[panel].close();
        }
      });
      this.stopEvent(event);
      if (event.shiftKey || event.ctrlKey || event.metaKey) {
        window.open(`#admin/vendor/${id}`, "_blank");
      } else {
        T1.Location.set(`#admin/vendor/${id}`);
      }
    },
    applyEditHover(evt) {
      var $currentTarget = $(evt.currentTarget);
      $currentTarget.closest(".admin-edit-holder").addClass("hover");
    },
    removeEditHover() {
      this.$(".admin-edit-holder").removeClass("hover");
    },
    stopEvent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    serialize(inputCollection) {
      var self = this;
      var coll = inputCollection || this.collection;
      var models = coll.toJSON() || [];
      var len = models.length;
      var hideNoResultMessage = false;
      var list = [];
      var highlightText = T1.Utils.highlightText;
      const escapeHTML = T1.Utils.escapeHTML;
      var isSearchResult = this.collection.searchTerm;
      var i;
      function processData(data) {
        var searchedTerm = self.collection.searchTerm;
        var hasHash = searchedTerm && searchedTerm.indexOf("#") !== -1;
        var isIdSearch = searchedTerm && (hasHash || $.isNumeric(searchedTerm));
        const gridVendorName = escapeHTML(data.name, true);
        return {
          vendor: isSearchResult ? highlightText(gridVendorName, AdminUtils.htmlDecode(searchedTerm)) : gridVendorName,
          entityID: data.id,
          id: isSearchResult && isIdSearch ? highlightText(data.id, hasHash ? searchedTerm.split("#")[1] : searchedTerm) : data.id,
          type: data.vendor_type === "AD_SERVER" ? "Ad Server" : data.vendor_type === "MEASUREMENT" ? "Measurement" : data.vendor_type === "AUDIENCE_VERIFICATION" ? "Audience Verification" : data.vendor_type === "AD_VERIFICATION" ? "Ad Verification" : data.vendor_type === "CONTEXTUAL" ? "Contextual" : data.vendor_type === "DYNAMIC_CREATIVE" ? "Dynamic Creative" : data.vendor_type === "OBA_COMPLIANCE" ? "Privacy Compliance" : data.vendor_type === "VIEWABILITY" ? "Viewability" : data.vendor_type === "SURVEY" ? "Survey" : data.vendor_type === "OTHER" ? "Other" : data.vendor_type === "DATA" ? "Data" : "&nbsp;",
          adx_app: data.adx_approved === "1" ? "Yes" : "No",
          adx_ssl_app: data.adx_ssl_approved === "1" ? "Yes" : "No",
          adx_declare: data.adx_declaration_required === "1" ? "Yes" : "No",
          mm_resell: data.mm_contract_available === "1" ? "Yes" : "No",
          mm_resell_no: data.mm_contract_available === "0" ? 0 : 1,
          rate_card_price: data.rate_card_price ? data.rate_card_price : "&nbsp;",
          pricing_type: data.rate_card_type ? data.rate_card_type : "&nbsp;",
          wholesale_price: data.wholesale_price ? data.wholesale_price : "&nbsp;",
          is_active: data.is_eligible === "1" ? 0 : 1,
          adx_video_ssl_approved: data.adx_video_ssl_approved === "1" ? "Yes" : "No",
          adx_video_approved: data.adx_video_approved === "1" ? "Yes" : "No"
        };
      }
      this.inSearch = isSearchResult && isSearchResult !== "";
      if (coll.loaded === false && isFireFox) {
        hideNoResultMessage = true;
      }
      for (i = 0; i < len; i++) {
        list.push(processData(models[i]));
      }
      return {
        hideNoResultMessage: hideNoResultMessage,
        list: list,
        loading: inputCollection === undefined && !this.collection.loaded
      };
    }
  });
  return T1GridView(VendorsGridView);
});
