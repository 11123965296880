define('modules/targeting/digital/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!modules/targeting/digital/templates/bin_title.html", "text!modules/targeting/digital/templates/bin_group_item.html", "text!modules/targeting/digital/templates/bin_group_header.html"], function ($, _, T1, BinsView, bin_title, bin_group_item, bin_group_header) {
  "use strict";

  var DigitalBinsView = BinsView.extend({
    type: "Technology",
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      }
    },
    partials: {
      bin_group_name: bin_group_header,
      bin_title: bin_title,
      bin_group_item: bin_group_item
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      onDropEvent: "bins:droppedTechnology",
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    technologyCodes: {
      conn: "CSPD",
      isp: "ISPX",
      browser: "BSER",
      device: "DVCE",
      inventory: "INVT",
      devicemodel: "WURF",
      osversion: "WURFL/OS"
    },
    initialize: function ({
      strategy: strategy,
      collection: collection,
      sharedCollection: sharedCollection,
      partialCollection: partialCollection,
      targetDimensions: targetDimensions,
      filterConfigs: filterConfigs
    }) {
      this.strategy = strategy;
      this.collection = collection;
      this.sharedCollection = sharedCollection;
      this.partialCollection = partialCollection;
      this.targetDimensions = targetDimensions;
      this.filterConfigs = filterConfigs;
      this.updateAllTempCollections();
    },
    updateAllTempCollections: function () {
      for (const code in this.technologyCodes) {
        if (T1.Utils.hasOwnProp(this.technologyCodes, code)) {
          this.updateTempCollection(this.technologyCodes[code]);
        }
      }
    },
    updateTempCollection: function (code) {
      var targetDimensions = this.strategy.targetDimensions.get("target_dimensions");
      const locationTargets = targetDimensions.models.filter(model => model.id === code);
      if (locationTargets.length) {
        const origColl = locationTargets[0].get("target_value");
        const tempColl = {
          data: []
        };
        origColl.each(function (model) {
          tempColl.data.push(model.clone());
        });
        origColl.tempBadgesCollection = tempColl;
        origColl.tempBadgesCollection.strategyId = this.strategy.id;
      }
    },
    dataEvents: {
      sharedCollection: {
        "add:target_value": function (model) {
          clearTimeout(this.collAddTargetTimeoutId);
          this.collAddTargetTimeoutId = setTimeout($.proxy(this.updateBins, this, model), 0);
        },
        "remove:target_value": function (model) {
          clearTimeout(this.collRemoveTargetTimeoutId);
          this.collRemoveTargetTimeoutId = setTimeout($.proxy(this.updateBins, this, model, model.get("action")), 0);
        }
      }
    },
    eventhubEvents: {
      updateDigitalBins: "updateBins",
      "tech-targeting:updateTempColl": "updateAllTempCollections"
    },
    removeItem: function (event) {
      const $item = $(event.currentTarget).closest(".item");
      const id = $item.data("id");
      const parentId = $item.data("parent-id");
      const targetValue = this.collection.get(id) || this.sharedCollection.get(id);
      targetValue.set({
        isRemovedFromBin: true
      });
      event.preventDefault();
      event.stopPropagation();
      T1.EventHub.publish("change:formStatus", {
        formStatus: true
      });
      return this.sharedCollection.get(parentId).get("target_value").remove(id) && true;
    },
    load: function () {
      this.render().then(() => {
        this.updateBins();
      });
    },
    updateBins: function () {
      ["include", "exclude"].forEach(action => {
        this.updateAction = action;
        this.renderPartial(`.${action}-bin .content-container`, undefined, "html", ".content-container");
      });
      this.updateAction = null;
      return true;
    },
    getFullPath: function (model) {
      var result = model.get("full_path_name") || "";
      if (!result) {
        while (model && model.get("parent_value_id")) {
          const id = model.get("parent_value_id");
          result = result === "" ? model.get("name") : `${model.get("name")} &raquo; ${result}`;
          model = this.sharedCollection.get(id) || this.partialCollection.get(id);
        }
        if (model) {
          result = result === "" ? model.get("name") : `${model.get("name")} &raquo; ${result}`;
        }
      }
      return result;
    },
    mapItems: function (items, updateAction) {
      var self = this;
      var filterOptions = this.filterConfigs.filterOptions;
      var sharedCollection = this.sharedCollection;
      var boldLastPartOfFullPath = T1.Utils.boldLastPartOfFullPath;
      var defaultGroupObj = {
        bin_show_group_name: true,
        count: "any",
        showGroupNameOnly: true,
        bin_group_name: "",
        groupItems: []
      };
      var result = [];
      $.each(filterOptions, function (index, option) {
        var targetDimension = sharedCollection.get(option.value);
        var group = $.extend(true, {}, defaultGroupObj);
        if (targetDimension) {
          const targetValues = targetDimension.get("target_value");
          self.checkIfWURflData(targetDimension.get("target_value"), sharedCollection, option);
          targetValues.each(function (model) {
            if (model.get("action") === updateAction) {
              const targetValue = model.toJSON();
              targetValue.name = boldLastPartOfFullPath.call(T1.Utils, {
                fullPath: self.getFullPath(model),
                separator: "&raquo;",
                searchTermToBeHighlighted: null
              });
              targetValue.parentId = targetDimension.id;
              group.groupItems.push(targetValue);
            }
          });
          if (group.groupItems.length) {
            group.bin_group_name = option.text;
            group.count = "";
            result.push(group);
          }
        }
      });
      return result;
    },
    checkIfWURflData: function (targetValues, sharedCollection, option) {
      const dimCode = "WURFL/OS";
      if (targetValues.length && option.value === "WURF") {
        targetValues.each(function (model) {
          if (model) {
            if (model.get("dimension_code") === dimCode) {
              sharedCollection.get(dimCode).get("target_value").add(model.toJSON());
              sharedCollection.get(option.value).get("target_value").remove(model);
              T1.EventHub.publish("strategyTargetDimension:hasWurflOSData");
            }
          }
        });
      }
    }
  });
  return DigitalBinsView;
});
