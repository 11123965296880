define('modules/audiences/views/audiences',["jQuery", "Underscore", "T1", "T1Permissions", "T1View", "T1Layout", "text!modules/audiences/templates/audiences.html"], function ($, _, T1, T1Permissions, T1View, T1Layout, template) {
  "use strict";

  var T1Location = T1.Location;
  return T1View.extend({
    template: template,
    initialize: function (args) {
      var module = T1Location.get();
      if (module === "#segments" || module === "#pixels") {
        this.redirect();
      } else {
        if (args && args.section === "in-app" && !T1Permissions.check("feature", "umt_onboard_screen_inapp")) {
          T1Location.set("unauthorized");
        } else {
          this.loadRoute(args);
        }
      }
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.load();
      });
    },
    loadRoute: function (args) {
      if (!args.section) {
        this.initTabsView();
      } else {
        this.route(args);
      }
    },
    redirect: function () {
      var locationHash = T1Location.get(true).split("/");
      var mode = locationHash[0].substring(1);
      var section = locationHash[1];
      var id = locationHash[2];
      var urlArray = [mode, section, id];
      var routeObj = {
        mode: mode,
        section: section,
        id: id
      };
      if (mode === "segments" && section === "edit") {
        routeObj.section = id;
        urlArray.splice(1, 1);
      }
      this.loadRoute(routeObj);
      T1Location.set("audiences/" + _.compact(urlArray).join("/"), {
        silent: true
      });
    },
    initTabsView: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".audiences-content",
        template: '<div class="audiences-main"></div>',
        layout: {
          ".audiences-main": [{
            module: "audiences",
            viewType: "tabs",
            options: {}
          }]
        }
      });
    },
    route: function (args) {
      var self = this;
      var layoutObj = this.getLayoutInfo(args);
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".audiences-content",
        template: '<div class="audiences-body"></div>',
        layout: {
          ".audiences-body": [{
            module: "audiences/" + layoutObj.module,
            viewType: layoutObj.viewType,
            options: layoutObj.opts,
            showLoader: true
          }]
        }
      });
    },
    getLayoutInfo: function (args) {
      var layoutInfo = {
        opts: args
      };
      switch (args.mode) {
        case "pixels":
          layoutInfo.module = "pixels";
          layoutInfo.viewType = "createEdit";
          break;
        case "lookalikes":
          layoutInfo.module = "lookalikes/create";
          layoutInfo.viewType = "createEdit";
          break;
        case "onboard":
          switch (args.section) {
            case "web":
              layoutInfo.module = "onboarding/create";
              layoutInfo.viewType = "webCreateEdit";
              break;
            case "data":
              layoutInfo.module = "onboarding/create";
              layoutInfo.viewType = "otherDataCreateEdit";
              break;
            case "event":
              layoutInfo.module = "onboarding/create";
              layoutInfo.viewType = "otherEventCreateEdit";
              break;
            case "in-app":
              layoutInfo.module = "onboarding/create";
              layoutInfo.viewType = "inAppCreateEdit";
              break;
            case "media":
              layoutInfo.module = "onboarding/create";
              layoutInfo.viewType = "mediaCreateEdit";
          }
          break;
        case "segments":
          switch (args.section) {
            case "export":
              switch (args.id) {
                case "create":
                  layoutInfo.module = "segments/createExport";
                  layoutInfo.viewType = "createExport";
                  break;
                default:
                  layoutInfo.module = "segments/export";
                  layoutInfo.viewType = "export";
                  break;
              }
              break;
            case "bulkCreate":
              layoutInfo.module = "segments/bulkCreate";
              layoutInfo.viewType = "bulkCreate";
              break;
            case "adaptive":
            case "standard":
              layoutInfo.module = "segments/create";
              layoutInfo.viewType = "createEdit";
              break;
            default:
              layoutInfo.module = "segments/create";
              layoutInfo.viewType = "createEdit";
              layoutInfo.opts.id = args.section;
              layoutInfo.opts.section = null;
              break;
          }
      }
      return layoutInfo;
    }
  });
});
