define('models/strategyConcept',["T1", "T1Model"], function (T1, T1Model) {
  T1.Models.StrategyConcept = T1Model.extend({
    url: "strategy_concepts/",
    defaults: {
      action: "include"
    },
    fixture: "-strategyConcept"
  });
  return T1.Models.StrategyConcept;
});
