define('modules/campaign/bulkedit/creatives/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "campaign/bulkedit/creatives",
    viewCfgs: {
      bins: {},
      list: {},
      panel: {
        strategy: null
      },
      search: {}
    },
    defaultView: "panel"
  });
});
