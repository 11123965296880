define('modules/admin/shared/views/search',['require','jQuery','Underscore','T1','T1View','utils/AdminUtils','text!modules/admin/shared/templates/search.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const AdminUtils = require("utils/AdminUtils");
  const template = require("text!modules/admin/shared/templates/search.html");
  const SearchUI = T1View.extend({
    template: template,
    events: {
      "keyup .search-input": "onSearchInputKeyUp"
    },
    defaultOptions: {
      searchInputDelay: 500,
      searchInputMinChar: 3,
      showActiveLabel: "Show Active Only"
    },
    initialize(args) {
      this.options = $.extend(args || {}, this.defaultOptions);
      this.isActive = this.options.isActiveChecked ? "true" : "false";
      this.filterValue = this.options.filter ? this.options.filter.defaultValue : "ALL";
      this.subFilterValue = this.options.subFilter ? this.options.subFilter.defaultValue : "ALL";
      this.lazySearch = _.debounce(this.doSearch, this.options.searchInputDelay);
    },
    load() {
      this.render().then(() => {
        this.renderPlugins();
      });
      this.loaded = true;
    },
    unload() {
      if (this.errorTooltip) {
        this.errorTooltip.unbind();
        $(".tipsy").remove();
      }
      this.loaded = false;
    },
    renderPlugins() {
      var self = this;
      var $filter = this.$(".select-filter");
      var $searchInput = this.$(".search-input");
      var $clearButton = $($searchInput[0].$.clearBtn);
      var $showActive = this.$(".show-active");
      var $subFilter = this.$(".select-sub-filter");
      var publish = T1.EventHub.publish;
      $showActive.on("click", function (evt) {
        self.isActive = evt.currentTarget.value ? "true" : "false";
        publish("search:toggleActive", {
          value: self.isActive === "true" ? "1" : ""
        });
      });
      $filter.on("changed", function (evt) {
        self.filterValue = evt.currentTarget.value === "ALL" ? "" : evt.currentTarget.value;
        publish("search:filterChanged", {
          value: self.filterValue
        });
      });
      $subFilter.on("changed", function (evt) {
        self.subFilterValue = evt.currentTarget.value === "ALL" ? "" : evt.currentTarget.value;
        publish("search:subFilterChanged", {
          value: self.subFilterValue
        });
      });
      $clearButton.on("click", function () {
        var field = self.searchField || self.options.defaultSearchField || "multiField";
        self.searchField = undefined;
        publish("search:clearSearchClicked", {
          field: field,
          value: ""
        });
        if (self.errorTooltip) {
          $searchInput.attr("original-title", "");
          $searchInput.tipsy("hide");
          self.errorTooltip.unbind();
        }
      });
    },
    onSearchInputKeyUp(evt) {
      this.term = evt.target.value;
      this.lazySearch(evt.target);
    },
    doSearch(target) {
      var invalidShortSearch = `Please enter at least ${this.options.searchInputMinChar} characters.`;
      var publish = T1.EventHub.publish;
      var value = this.term;
      if (!this.loaded) {
        return;
      }
      function showErrorTooltip(trgt, errorMsg) {
        var $target = $(trgt);
        var errorConfig = {
          trigger: "manual",
          getExternalTip: false,
          className: "w-FormError",
          offset: 10
        };
        errorConfig.tooltipEle = trgt;
        errorConfig.trigger = "hover";
        errorConfig.gravity = "s";
        trgt.error = true;
        $target.attr("title", errorMsg);
        const tip = T1.Tooltip(trgt, errorConfig);
        if ($target.data("tipsy")) {
          $target.tipsy("show");
        }
        return tip;
      }
      if (this.term.length > 0) {
        if (this.term.length < this.options.searchInputMinChar) {
          if (target.getAttribute("original-title") !== invalidShortSearch) {
            this.errorTooltip = showErrorTooltip(target, invalidShortSearch);
          }
          return;
        }
        this.searchField = this.options.getSearchField ? this.options.getSearchField(this.term) : !$.isNumeric(this.term) ? "name|id" : this.options.defaultSearchField || "multiField";
      }
      publish("search:onSearchRequest", {
        field: this.searchField,
        value: AdminUtils.htmlEncode(value)
      });
      if (this.errorTooltip) {
        target.setAttribute("original-title", "");
        this.errorTooltip.unbind();
        $(".tipsy").remove();
      }
    },
    serialize() {
      var options = this.options;
      var output = $.extend({}, options);
      output.showActive = options.showActive !== undefined ? options.showActive : true;
      output.showFilter = options.filter && options.filter.value !== undefined;
      output.isActiveChecked = this.isActive === "true";
      if (output.showFilter) {
        output.filterEnabled = options.filter.enabled !== undefined ? options.filter.enabled : true;
        output.filterItems = options.filter.value;
        output.filterValue = this.filterValue || output.filterItems[0].value;
      }
      output.showSubFilter = options.subFilter && options.subFilter.value !== undefined;
      if (output.showSubFilter) {
        output.subFilterEnabled = options.subFilter.enabled !== undefined ? options.subFilter.enabled : true;
        output.subFilterName = options.subFilter.name;
        output.subFilterItems = options.subFilter.value;
        output.subFilterValue = this.subFilterValue || output.subFilterItems[0].value;
      }
      return output;
    }
  });
  return SearchUI;
});
