define('modules/shared/configs/charts/scatterChartConfig',[], function () {
  "use strict";

  const colorAxis = "#999999";
  const config = {
    hideCredits: true,
    type: "xy",
    theme: "light",
    fontFamily: "AR",
    dataProvider: [],
    graphs: [],
    valueAxes: [{
      axisColor: colorAxis,
      gridColor: colorAxis,
      id: "metric0Axis",
      labelFunction: function () {},
      position: "left",
      title: "",
      titleBold: true,
      titleFontSize: 13
    }, {
      axisColor: colorAxis,
      gridColor: colorAxis,
      id: "metric1Axis",
      labelFunction: function () {},
      position: "bottom",
      title: "",
      titleBold: true,
      titleFontSize: 13
    }],
    startDuration: .5,
    startEffect: "easeOutSine",
    sequencedAnimation: false,
    marginRight: 30,
    balloon: {
      borderAlpha: 0,
      color: "#FFFFFF",
      fillColor: "#000000",
      maxWidth: 500,
      textAlign: "left",
      verticalPadding: 8
    },
    listeners: [{
      event: "init",
      method: function () {}
    }, {
      event: "clickGraphItem",
      method: function () {}
    }],
    legend: {
      enabled: false
    },
    export: {
      enabled: true,
      fallback: false,
      libs: {
        autoLoad: false
      },
      menu: []
    }
  };
  return {
    copy: () => JSON.parse(JSON.stringify(config))
  };
});
