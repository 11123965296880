define('collections/mediaMetrics',['require','jQuery','T1','T1Collection'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  T1.Models.MediaMetrics = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    urlString: "deals",
    apiRoot: "/reporting/v1/std/",
    fetch(options = {}) {
      const onSuccess = data => {
        this.models = data;
        this.length = data.length;
        options.success();
      };
      const syncOpts = {
        dataType: "html",
        success: onSuccess,
        processAjaxResponse: this.processAjaxResponse
      };
      this.ajx = this.sync("read", "", syncOpts);
      if (this.ajx) {
        this.ajx.always(() => {
          this.ajx = null;
        });
      }
      return this.ajx;
    },
    processAjaxResponse(resp) {
      if (resp.includes("<?xml")) {
        throw new Error("chart data response is not csv.");
      }
      const data = d3.csv.parse.call(d3, resp);
      return {
        statusCode: "ok",
        jsonData: data
      };
    },
    url(params) {
      let searchObj, urlString;
      let appendString = "";
      const pageLimit = parseInt(this.pageLimit, 10);
      const searchOption = "";
      let fullOptions = $.extend(true, {}, this.fetchOptions, params || {});
      if (this.urlString) {
        urlString = typeof this.urlString === "function" ? this.urlString() : this.urlString;
      }
      if (this.urlFilter) {
        appendString += this.urlFilter.getString();
      }
      if (this.isPaginated) {
        fullOptions[this.pageLimitField] = pageLimit;
        fullOptions[this.pageField] = this.page * pageLimit;
      } else {
        delete fullOptions[this.pageLimitField];
        delete fullOptions[this.pageField];
      }
      if (this.search) {
        searchObj = this.search.getCriteria();
        if (Reflect.has(searchObj, this.searchField)) {
          fullOptions = $.extend(fullOptions, searchObj);
        } else {
          delete fullOptions[this.searchField];
        }
      }
      if (!$.isEmptyObject(fullOptions)) {
        appendString += `?${$.param(fullOptions, true)}`;
      }
      return this.apiRoot + urlString + appendString + searchOption;
    }
  });
  return T1.Models.MediaMetrics;
});
