define('models/admin',["T1", "Backbone", "T1Model"], function (T1, Backbone, T1Model) {
  T1.Models.Admin = T1Model.extend({
    setMainTabSection: function (sectionId) {
      this.set({
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    }
  });
  return T1.Models.Admin;
});
