define('modules/campaign/chart/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "",
    name: "campaign/chart",
    viewCfgs: {
      datePicker: {},
      dimensionsMenu: {},
      exportMenu: {},
      highestLowestMenu: {},
      intervalMenu: {},
      legendToggle: {},
      metricsMenu: {},
      newChart: {}
    }
  });
});
