define('modules/admin/siteLists/views/grid',['require','jQuery','utils/AdminUtils','text!../templates/grid_item.html','collections/organizations','T1','T1GridView','T1Menu','T1View'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const grid_item = require("text!../templates/grid_item.html");
  const Organizations = require("collections/organizations");
  const T1 = require("T1");
  const T1GridView = require("T1GridView");
  const T1Menu = require("T1Menu");
  const T1View = require("T1View");
  var T1Tooltip = T1.Tooltip;
  var isFireFox = navigator.userAgent.indexOf("Firefox") >= 0;
  var SiteListsGridView = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        action: "append",
        contentClass: "search-loader loader-overrides admin-infinite",
        target: ".w-DataGrid",
        text: "Fetching Site Lists"
      }
    },
    exportTooltipConfigs: {
      className: "export-icon-tip",
      delayIn: 0,
      delayOut: 0,
      fade: false,
      getExternalTip: false,
      html: true,
      tooltipEle: ".export-icon"
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "site_list",
      label: "Site List"
    }, {
      name: "id",
      label: "ID"
    }, {
      name: "type",
      label: "Type"
    }, {
      name: "num_domains",
      label: "# Domains"
    }, {
      name: "num_apps",
      label: "# Apps"
    }],
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Edit Site List",
          visible: true
        }, {
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: true
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }]
      }]
    },
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 9,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    events: {
      "click .admin-edit": "editSiteList",
      "click .export-icon": "exportCSVHandler",
      "click .settings-icon": "showOrApplySettingsMenu",
      "contextmenu .admin-edit": "applyEditHover",
      "contextmenu .config": "stopEvent",
      "mousemove .item-wrapper": "removeEditHover"
    },
    dataEvents: {
      collection: {
        reset: "reload",
        "change:status": "updateActive"
      }
    },
    eventhubEvents: {
      "organization:select": "reload",
      "siteListGrid:reload": function () {
        this.setSearchLoader();
      }
    },
    initialize(args) {
      this.collection = args.collection;
      this.collection.refreshOnNextFetch();
      this.siteLists = args.siteLists;
    },
    load() {
      if (!this.initDataRequested) {
        this.resetCollection();
      }
      Organizations.getOrganizations().getSelected().then(orgId => {
        if (orgId === this.orgId) {
          this.render().then(() => {
            if (!this.initDataRequested) {
              this.initDataRequested = true;
              this.setSearchLoader();
              this.getData(this.orgId);
            }
          });
        } else {
          this.orgId = orgId;
          this.load();
          this.initDataRequested = true;
          this.setSearchLoader();
          this.getData(orgId);
        }
      });
      this.loaded = true;
    },
    unload() {
      var coll = this.collection;
      if (this.loaded) {
        if (this.inSearch || this.activationUpdated || isFireFox) {
          this.inSearch = false;
          this.activationUpdated = false;
          this.initDataRequested = false;
          this.resetCollection();
          coll.refreshOnNextFetch();
        } else {
          this.resetCollection(true);
        }
        if (coll && !coll.loaded && coll.currentFetch) {
          coll.currentFetch.abort();
          if (coll.infiniteScrolling) {
            this.isFetching = coll.isFetching = false;
          } else {
            this.initDataRequested = false;
          }
        }
        coll.infiniteScrolling = false;
        if (this.renderQueue) {
          this.renderQueue.flush();
        }
        this.loaded = false;
      }
    },
    resetCollection(leaveFirstPageData) {
      var coll = this.collection;
      if (leaveFirstPageData) {
        const models = coll.models;
        coll.reset(models.slice(0, Math.min(models.length, coll.pageLimit)), {
          silent: true
        });
      } else {
        coll.reset(undefined, {
          silent: true
        });
        coll.loaded = false;
      }
    },
    getData(orgId) {
      var coll = this.collection;
      coll.canCache = false;
      coll.page = 0;
      coll.pageLimit = 50;
      coll.isPaginated = true;
      coll.fetchOptions = {
        full: "*",
        sort_by: "-updated_on"
      };
      coll.urlFilter.set({
        entity: "organization",
        id: orgId
      });
      coll.filterBy();
    },
    itemContentRendered($item) {
      T1Tooltip($item, this.exportTooltipConfigs);
      this.applyTruncate($item);
      try {
        this.showOrApplySettingsMenu($item.find(".settings-icon"));
      } catch (err) {}
    },
    showOrApplySettingsMenu(eventOrTarget) {
      var $menuTarget = eventOrTarget.currentTarget ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Edit: 0,
        Deactivate: 1,
        Activate: 2
      };
      if ($menuTarget) {
        const id = $menuTarget.data("id");
        const model = this.collection.get(id);
        const isActive = model.get("status") === "1";
        menuItems[menuIndexRef.Edit].url = `#admin/siteList/${id}`;
        menuItems[menuIndexRef.Activate].visible = !isActive;
        menuItems[menuIndexRef.Deactivate].visible = isActive;
        menuConfig.dataID = `data-id=${id}`;
        menuConfig.menuTriggerEleSelector = `div[id="id${id}"] .settings-icon`;
      }
      T1Menu(menuConfig, this);
      if (eventOrTarget.currentTarget) {
        $menuTarget.trigger("click");
      }
    },
    exportCSVHandler(evt) {
      var $currentTarget = $(evt.currentTarget);
      var id = $currentTarget.data("id");
      T1.EventHub.publish("csvExportModal:show", {
        id: id
      });
    },
    updateActive(model) {
      var id = model.get("id");
      var active = model.get("status");
      var targetDiv = `div[id="id${id}"]`;
      var $item = this.$(`${targetDiv} .item`);
      var $config = $item.find(".settings-icon");
      if (active === "0") {
        $item.addClass("inactive");
      } else {
        $item.removeClass("inactive");
      }
      this.showOrApplySettingsMenu($config);
    },
    deactivateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(false, evt);
    },
    activateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(true, evt);
    },
    activationHandler(toActivate, evt) {
      var $currentTarget = $(evt.currentTarget);
      var loader;
      var id = $currentTarget.closest("dl").data("id");
      var active = toActivate === true ? "1" : "0";
      function stopLoader() {
        loader.stop();
      }
      if (id) {
        const model = this.collection.get(id);
        const targetDiv = `div[id="id${id}"]`;
        loader = T1.Loader.create({
          contentClass: "inline-edit-loader",
          target: targetDiv,
          spinnerConfig: {
            left: 15,
            length: 4,
            lines: 10,
            radius: 3,
            top: 4
          }
        });
        loader.start();
        model.updateStatus({
          status: active
        }, {
          success: stopLoader,
          conflict: stopLoader
        });
        if (this.siteLists.isActive === "true") {
          this.activationUpdated = true;
        }
      }
    },
    editSiteList(event) {
      var $currentTarget = $(event.currentTarget);
      var id = $currentTarget.data("id");
      this.stopEvent(event);
      if (event.shiftKey || event.ctrlKey || event.metaKey) {
        window.open(`#admin/siteList/${id}`, "_blank");
      } else {
        T1.Location.set(`#admin/siteList/${id}`);
      }
    },
    applyEditHover(evt) {
      var $currentTarget = $(evt.currentTarget);
      $currentTarget.closest(".admin-edit-holder").addClass("hover");
    },
    removeEditHover() {
      $(this.el).find(".admin-edit-holder").removeClass("hover");
    },
    stopEvent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    serialize(inputCollection) {
      var self = this;
      var coll = inputCollection || this.collection;
      var models = coll.toJSON() || [];
      var len = models.length;
      var hideNoResultMessage = false;
      var list = [];
      var highlightText = T1.Utils.highlightText;
      const escapeHTML = T1.Utils.escapeHTML;
      var isSearchResult = self.collection.searchTerm;
      var downloadPrefix = `${COMPASS_ENV.API_BASE}site_lists/`;
      function processData(data) {
        var searchedTerm = self.collection.searchTerm;
        var hasHash = searchedTerm && searchedTerm.indexOf("#") !== -1;
        var isIdSearch = searchedTerm && (hasHash || $.isNumeric(searchedTerm));
        const gridSiteList = escapeHTML(data.name, true);
        return {
          downloadLocation: `${downloadPrefix + data.id}/download.csv`,
          entityID: data.id,
          entityType: data.filename,
          id: isSearchResult && isIdSearch ? highlightText(data.id, hasHash ? searchedTerm.split("#")[1] : searchedTerm) : data.id,
          is_active: data.status === "1",
          num_apps: data.sites_count_app || "0",
          num_domains: data.sites_count_domain || "0",
          siteList: isSearchResult ? highlightText(gridSiteList, AdminUtils.htmlDecode(searchedTerm)) : gridSiteList,
          type: data.restriction === "INCLUDE" ? "Allow list" : "Block list"
        };
      }
      this.inSearch = isSearchResult && isSearchResult !== "";
      if (coll.loaded === false && isFireFox) {
        hideNoResultMessage = true;
      }
      for (let i = 0; i < len; i++) {
        list.push(processData(models[i]));
      }
      return {
        hideNoResultMessage: hideNoResultMessage,
        list: list,
        loading: inputCollection === undefined && !this.collection.loaded
      };
    }
  });
  return T1GridView(SiteListsGridView);
});
