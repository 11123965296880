define('modules/reporting/segments/dataExport/createEdit/views/segmentExport',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/segmentExport.html"], function ($, T1, T1View, T1Permissions, template) {
  "use strict";

  return T1View.extend({
    template: template,
    events: {
      "click #segment-export": "jumpToSegmentExports"
    },
    initialize: function () {},
    load: function () {
      this.render();
    },
    unload: function () {},
    serialize: function () {
      var can_see_exports = T1Permissions.check("feature", "segments_export_toggle");
      return {
        canSeeExports: can_see_exports
      };
    },
    jumpToSegmentExports: function () {
      T1.EventHub.unsubscribe("organization:select", this.orgChanged);
      T1.Location.set("segments/export");
    }
  });
});
