define('modules/reporting/campaigns/views/report_info',['require','T1','T1View','text!../templates/report_info.html','utils/TimezoneUtils'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/report_info.html");
  const TimezoneUtils = require("utils/TimezoneUtils");
  const dateFormat = "yyyy-MM-dd HH:mm:ss";
  return T1View.extend({
    template: template,
    eventhubEvents: {
      responseHeadersReady: "loadHandler"
    },
    initialize() {
      this.initLoad = true;
    },
    load() {
      this.render().then(() => {
        T1.Tooltip(this.el, {
          className: "rptg-icon-info-tip",
          delayIn: 0,
          delayOut: 0,
          fade: false,
          getExternalTip: false,
          gravity: "se",
          html: true,
          offset: 0,
          tooltipEle: ".report-info strand-icon"
        });
        if (this.initLoad) {
          this.initLoad = false;
          T1.EventHub.publish("complete:layoutLoad");
        }
      });
    },
    unload() {
      this.reportInfo = null;
      this.deferred = null;
    },
    loadHandler(reportInfo) {
      this.reportInfo = reportInfo;
      this.load();
    },
    serialize() {
      const reportInfo = this.reportInfo;
      let lastModified = reportInfo ? reportInfo.lastModified : "";
      if (lastModified) {
        const date = new Date(lastModified);
        const formattedDate = date.toString(dateFormat);
        const timezone = TimezoneUtils.getClientTimezoneAbbr();
        lastModified = `${formattedDate} ${timezone}`;
      }
      return {
        lastModified: lastModified || "Currently unavailable."
      };
    }
  });
});
