define('router.config',{
  el: "#t1-page-container",
  sections: {
    index: {
      el: ".app-container",
      tmplPath: "templates/main.html",
      layout: {},
      bindings: {
        onSessionOk: {
          action: "changeLocation",
          params: ["monitor", {
            replace: true
          }]
        }
      }
    },
    oauthlogin: {
      el: ".app-container",
      tmplPath: "templates/main.html",
      layout: {}
    },
    "reset-password": {
      el: ".app-container",
      tmplPath: "templates/index.html",
      layout: {}
    },
    monitor: {
      el: ".app-container",
      tmplPath: "templates/main.html",
      layout: {
        ".campaign-chart-container": [{
          module: "campaign",
          viewType: "mmCharts"
        }],
        ".campaign-filters": [{
          module: "campaign",
          viewType: "filters"
        }],
        ".campaign-controls": [{
          module: "campaign",
          viewType: "controls"
        }],
        ".campaign-list-container": [{
          module: "campaign",
          viewType: "list"
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      }
    },
    reports: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "reporting",
          viewType: "tabs",
          showLoader: true
        }]
      },
      modes: {
        campaigns: [{
          module: "reporting",
          viewType: "tabs",
          showLoader: true
        }],
        segments: [{
          module: "reporting",
          viewType: "tabs",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      }
    },
    campaign: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {},
      modes: {
        create: [{
          module: "campaign",
          viewType: "createEdit",
          showLoader: true
        }],
        edit: [{
          module: "campaign",
          viewType: "createEdit",
          showLoader: true
        }],
        readonly: [{
          module: "campaign",
          viewType: "createEdit",
          showLoader: true
        }],
        editMargin: [{
          module: "campaign",
          viewType: "editMargin",
          showLoader: true
        }],
        bulkedit: [{
          module: "campaign/bulkedit",
          viewType: "edit",
          showLoader: true
        }],
        exportTags: [{
          module: "campaign/export",
          viewType: "exportTags",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        },
        "organization:explicitChange": {
          action: "changeLocation",
          params: ["monitor", {
            replace: true
          }]
        }
      }
    },
    preview: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {},
      modes: {
        "display-creative": [{
          module: "creatives/display",
          viewType: "preview"
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      },
      permission: "creatives"
    },
    creatives: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "creatives",
          viewType: "tabs"
        }]
      },
      modes: {
        concept: [{
          module: "creatives/concept",
          viewType: "createEdit",
          showLoader: true
        }],
        video: [{
          module: "creatives/video",
          viewType: "createEdit",
          showLoader: true
        }],
        "componentBased-bulk": [{
          module: "creatives/componentBased",
          viewType: "createEditBulk"
        }],
        display: [{
          module: "creatives/display",
          viewType: "createEdit",
          showLoader: true
        }],
        "display-preview": [{
          module: "creatives/display",
          viewType: "preview",
          showLoader: true
        }],
        "display-bulk": [{
          module: "creatives/display",
          viewType: "createEditBulk"
        }],
        "display-bulk-review": [{
          module: "creatives/display",
          viewType: "createEditBulkReview"
        }],
        "display-bulk-3pas-review": [{
          module: "creatives/display",
          viewType: "createBulk3PASReview"
        }],
        "display-t1as": [{
          module: "creatives/display",
          viewType: "createT1AS"
        }],
        "display-t1as-review": [{
          module: "creatives/display",
          viewType: "createT1ASReview"
        }],
        preview: [{
          module: "creatives/video",
          viewType: "createEdit",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      },
      permission: "video"
    },
    strategy: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {},
      modes: {
        create: [{
          module: "strategy",
          viewType: "createEdit",
          showLoader: true
        }],
        edit: [{
          module: "strategy",
          viewType: "createEdit",
          showLoader: true
        }],
        readonly: [{
          module: "strategy",
          viewType: "createEdit",
          showLoader: true
        }],
        summary: [{
          module: "strategy",
          viewType: "createEdit",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        },
        "organization:explicitChange": {
          action: "changeLocation",
          params: ["monitor", {
            replace: true
          }]
        }
      }
    },
    appstore: {
      el: ".app-container",
      tmplPath: "templates/T1Apps.html",
      layout: {
        ".overlay-view": [{
          module: "T1App",
          viewType: "tabs",
          showLoader: true
        }]
      },
      modes: {
        myApps: [{
          module: "T1App",
          viewType: "launch",
          showLoader: true
        }],
        apps: [{
          module: "T1App",
          viewType: "list",
          showLoader: true
        }],
        launch: [{
          module: "T1App/apps",
          viewType: "launch",
          showLoader: true
        }],
        edit: [{
          module: "T1App/edit",
          viewType: "tabs",
          showLoader: true
        }],
        labs: [{
          module: "T1App",
          viewType: "list",
          showLoader: true
        }],
        "launch-labs": [{
          module: "T1App/labs",
          viewType: "launch-labs",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      }
    },
    media: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "media",
          viewType: "tabs",
          showLoader: true
        }]
      },
      modes: {
        edit: [{
          module: "media/pmp-e",
          viewType: "dealEdit",
          showLoader: true
        }],
        create: [{
          module: "media/pmp-e",
          viewType: "dealEdit",
          showLoader: true
        }],
        editDealGroup: [{
          module: "media/pmp-e",
          viewType: "dealGroupEdit",
          showLoader: true
        }],
        createDealGroup: [{
          module: "media/pmp-e",
          viewType: "dealGroupEdit",
          showLoader: true
        }]
      },
      permission: "markets"
    },
    segments: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }]
      }
    },
    pixels: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }]
      }
    },
    audiences: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }]
      },
      modes: {
        segments: [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }],
        pixels: [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }],
        lookalikes: [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }],
        onboard: [{
          module: "audiences",
          viewType: "audiences",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      }
    },
    admin: {
      el: ".app-container",
      tmplPath: "templates/overlay_layout.html",
      layout: {
        ".overlay-view": [{
          module: "admin",
          viewType: "tabs",
          showLoader: true
        }]
      },
      modes: {
        organization: [{
          module: "admin/myOrgs/organization",
          viewType: "createEdit",
          showLoader: true
        }],
        agency: [{
          module: "admin/myOrgs/agency",
          viewType: "createEdit",
          showLoader: true
        }],
        advertiser: [{
          module: "admin/myOrgs/advertiser",
          viewType: "createEdit",
          showLoader: true
        }],
        user: [{
          module: "admin/user",
          viewType: "createEdit",
          showLoader: true
        }],
        siteList: [{
          module: "admin/siteLists",
          viewType: "createEdit",
          showLoader: true
        }],
        vendor: [{
          module: "admin/vendors",
          viewType: "createEdit",
          showLoader: true
        }],
        creatives: [{
          module: "admin/creatives",
          viewType: "createEdit",
          showLoader: true
        }]
      },
      bindings: {
        onUserLoginOk: {
          action: "reloadLocation"
        }
      }
    },
    logout: {
      el: ".app-container",
      tmplPath: "templates/logout.html",
      layout: {
        ".app-list-container": [{
          module: "auth",
          viewType: "logout"
        }]
      }
    },
    unauthorized: {
      el: ".app-container",
      tmplPath: "templates/T1Apps.html",
      layout: {
        ".app-list-container": [{
          module: "auth",
          viewType: "unauthorized"
        }]
      }
    }
  }
});
