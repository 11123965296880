define('modules/campaign/views/details',["jQuery", "T1View", "T1TabLayoutV2", "utils/CampaignStrategyUtils", "text!modules/campaign/templates/details.html", "jQueryUI", "T1DatePicker"], function ($, T1View, T1TabLayout, CampaignStrategyUtils, template) {
  "use strict";

  const tab1 = 1;
  const tab2 = 2;
  const DetailsTabView = T1View.extend({
    template: template,
    mode: "create",
    isProgrammaticGuaranteed: false,
    initialize: function ({
      model: model,
      id: id
    }) {
      this.model = model;
      this.id = id;
      this.initLayouts();
      $(document).on("mousedown", CampaignStrategyUtils.closeStickyTooltips);
    },
    events: {
      "click .t1-tooltip.tab-info": "openLink"
    },
    eventhubEvents: {
      "campaign:goal-type-change": function (type) {
        this.updateTabs(type, this.isProgrammaticGuaranteed);
      }
    },
    dataEvents: {
      model: {
        "change:id": function (model) {
          var goalType;
          if (model && model.get("goal_type")) {
            goalType = model.get("goal_type");
          }
          if (this.isCorrectGoalType(goalType)) {
            this.layout.execute("enable", tab2);
            this.$(".tab-3-level-2").show();
          }
          this.layout.execute("enable", tab1);
        },
        "change:is_programmatic_guaranteed": function (model) {
          this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(model);
          this.updateTabs({
            goalType: model.get("goal_type")
          }, this.isProgrammaticGuaranteed);
        }
      }
    },
    load: function () {
      this.render().then(() => {
        this.layout.el = this.$(".campaign-details-tabs");
        this.layout.load();
        this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(this.model);
        if (this.model.id) {
          if (this.isProgrammaticGuaranteed) {
            this.disableTabs([tab1, tab2]);
          } else {
            this.layout.execute("enable", tab1);
            if (this.isCorrectGoalType(this.model.get("goal_type"))) {
              this.$(".tab-3-level-2").show();
              this.layout.execute("enable", tab2);
            } else {
              this.$(".tab-3-level-2").hide();
              this.disableTabs([tab2]);
            }
          }
        } else {
          this.$(".tab-3-level-2").hide();
          this.disableTabs([tab1]);
        }
      });
    },
    canUnload: function (...args) {
      return this.layout.canUnload(...args);
    },
    unload: function () {
      $(document).unbind("mousedown", CampaignStrategyUtils.closeStickyTooltips);
    },
    initLayouts: function () {
      var model = this.model;
      const tabs = [{
        label: "General",
        module: "campaign",
        routeName: "general",
        viewType: "general",
        options: {
          model: model || null
        }
      }, {
        label: "Site Lists",
        module: "campaign/siteList",
        routeName: "siteList",
        viewType: "panel",
        options: {
          getCampaign: () => model
        }
      }, {
        label: "Audiences in Brain",
        module: "campaign/pixelSegment",
        routeName: "pixelSegment",
        viewType: "panel",
        options: {
          model: model || null
        }
      }];
      this.layout = new T1TabLayout({
        tabSetName: "CampaignDetails",
        level: 2,
        disabled: [2, 2],
        tabs: tabs
      });
    },
    openLink: function (e) {
      if ($(e.currentTarget).attr("data-tip-content") === "tab-3-level-2-tool-tip-content") {
        window.open("https://kb.mediamath.com/wiki/x/moii");
      }
    },
    disableTabs: function (tabNumbers) {
      this.layout.tabEl.tabs("option", "disabled", tabNumbers);
    },
    isCorrectGoalType: function (goalType) {
      return !(goalType === "reach" || goalType === "spend");
    },
    updateTabs: function (type, isPG) {
      const goalType = type.goalType;
      if (goalType !== "") {
        if (this.isCorrectGoalType(goalType)) {
          if (!this.model.id) {
            this.disableTabs([tab1, tab2]);
          } else {
            if (type.isDontSave || type.isSave || this.isCorrectGoalType(this.model.get("goal_type"))) {
              this.layout.execute("enable", tab2);
            } else {
              this.disableTabs([tab2]);
            }
          }
          this.$(".tab-3-level-2").show();
        } else {
          this.disableTabs([tab2]);
          this.$(".tab-3-level-2").hide();
        }
      }
      if (isPG) {
        this.disableTabs([tab1, tab2]);
      }
    }
  });
  return DetailsTabView;
});
