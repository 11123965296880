define('modules/reporting/campaigns/siteTransparency/views/chartPanel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/chartLayout.html", "text!../templates/chartPanel.html"], function ($, _, T1, T1View, T1Layout, chartLayout, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: chartLayout,
    events: {
      "click .line-icon": "switchGraph",
      "click .scatter-icon": "switchGraph",
      "mouseout strand-icon": "setChartIconState",
      "mouseover strand-icon": "changeChartIconColor"
    },
    dataEvents: {
      reportModel: {
        "change:dimensionItems": "reload"
      }
    },
    initialize: function (args) {
      this.cfgs = args;
      this.subSection = this.cfgs.models.mainModel.get("siteTransparencySubSection");
      this.reportModel = this.cfgs.models.reportModel;
      this.initLayout();
    },
    initLayout: function () {
      const self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".site-transparency-chart-panel");
        },
        template: template,
        layout: {
          ".site-transparency-chart-title-box": [{
            module: "reporting/campaigns/siteTransparency",
            options: this.cfgs,
            viewType: "chartTitle"
          }],
          ".metric-select-c1": [{
            module: "reporting/campaigns",
            options: this.cfgs,
            viewType: "selectMetric"
          }],
          ".metric-select-c2": [{
            module: "reporting/campaigns",
            viewType: "selectMetric",
            options: {
              config: this.cfgs,
              isMetric2: true
            }
          }],
          ".site-transparency-chart-box": [{
            module: "reporting/campaigns/siteTransparency",
            options: this.cfgs,
            viewType: "siteTransparencyChart"
          }]
        }
      });
    },
    load: function () {
      const self = this;
      const $el = this.el;
      this.subSection = this.reportModel.get("isCTD") ? "summary" : this.subSection;
      this.render().then(function () {
        self.layout.load();
        self.scatterIcon = $el.find(".scatter-icon");
        self.lineIcon = $el.find(".line-icon");
        self.metric1Container = $el.find(".metric1-container");
        self.lineIconContainer = $el.find(".line-icon-container");
        self.setChartIconState();
      });
    },
    setChartIconState: function () {
      const selectedClass = "selected";
      if (this.subSection === "daily") {
        this.scatterIcon.removeClass(selectedClass);
        this.lineIcon.addClass(selectedClass);
        this.metric1Container.addClass("no-display");
      } else {
        this.lineIcon.removeClass(selectedClass);
        this.scatterIcon.addClass(selectedClass);
        this.metric1Container.removeClass("no-display");
        if (this.reportModel.get("isCTD")) {
          this.lineIconContainer.addClass("no-display");
        }
      }
    },
    changeChartIconColor: function (event) {
      if (event) {
        $(event.currentTarget).addClass("selected");
      }
    },
    switchGraph: function (event) {
      let {
        subSection: subSection
      } = this;
      const {
        id: selectedSection
      } = event.currentTarget;
      event.stopPropagation();
      if (!this.reportModel.get("isCTD")) {
        if (subSection !== selectedSection) {
          subSection = subSection === "daily" ? "summary" : "daily";
          T1Publish("select:chart", subSection);
          this.subSection = subSection;
          this.setChartIconState();
          T1Publish("switch:chart", subSection);
        }
      }
    }
  });
});
