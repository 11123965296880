define('models/strategyForecast',["jQuery", "T1", "T1Model", "moment"], function ($, T1, T1Model, moment) {
  const DIMENSION_VIDEO = "[<55,1,2>]";
  const DIMENSION_DISPLAY = "[<55,1,1>]";
  var StrategyForecast = T1Model.extend({
    isValid: false,
    url: function () {
      return COMPASS_ENV.FORECASTER_API_BASE;
    },
    initialize: function (options) {
      const ONE_MONTH = 30;
      const {
        expressionString: expressionString,
        mediaType: mediaType,
        price: price,
        currencyCode = "USD",
        frequencyAmount: frequencyAmount,
        frequencyInterval: frequencyInterval,
        frequencyType: frequencyType,
        startDate = moment().add(1, "days"),
        endDate = startDate.clone().add(ONE_MONTH, "days")
      } = options;
      let channelFilterStr;
      this.isValid = expressionString !== undefined && mediaType !== undefined && price !== undefined;
      if (this.isValid) {
        this.expressionString = expressionString;
        this.mediaType = mediaType;
        this.price = price;
        this.currencyCode = currencyCode;
        this.frequencyAmount = frequencyAmount;
        this.frequencyInterval = frequencyInterval;
        this.frequencyType = frequencyType;
        this.startDate = startDate;
        this.endDate = endDate;
        channelFilterStr = `AND ( ${this.mediaType === "VIDEO" ? DIMENSION_VIDEO : DIMENSION_DISPLAY} )`;
        this.expression = this.appendToExpression(expressionString, channelFilterStr);
      }
      T1Model.prototype.initialize.call(this, options);
    },
    validate: function () {
      if (!this.isValid) {
        return ["Invalid Forecaster data"];
      }
    },
    save: function (options) {
      options = options || {};
      if (this.isValid) {
        options.data = this.getDataAsString();
      }
      options.statusInvalid = options.onError;
      return T1Model.prototype.save.call(this, {}, options) || $.Deferred().resolve();
    },
    appendToExpression: function (originalStr, strToAppend) {
      return originalStr.replace(/\)(?=[^)]*$)/, `${strToAppend} )`);
    },
    getDataAsString: function () {
      const dateFormat = "YYYY-MM-DD";
      const intervalMap = {
        hour: "_1hours",
        day: "_1days",
        week: "_7days",
        month: "_30days"
      };
      let returnStr = `expression=${this.expression}\nprice=${this.price} ${this.currencyCode}\nstart_date=${this.startDate.format(dateFormat)}\nend_date=${this.endDate.format(dateFormat)}`;
      const selectedInterval = intervalMap[this.frequencyInterval];
      if (this.frequencyType !== "no-limit" && selectedInterval) {
        returnStr += `\nfrequency_cap=${this.frequencyAmount}${selectedInterval}`;
      }
      return returnStr;
    }
  });
  T1.Models.StrategyForecast = StrategyForecast;
  return StrategyForecast;
});
