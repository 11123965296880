define('T1Loader',["jQuery", "T1", "Mustache", "T1Spinner", "text!templates/loader.html", "text!templates/progressbar.html"], function ($, T1, T, T1Spinner, loaderTemplate, progressBarTemplate) {
  T1.Loader = function () {
    var loader;
    var defaultContext = "body";
    var defaultLoaderClass = "ajaxSpinner";
    function spinner(opts) {
      opts || (opts = {});
      var context = opts.context || defaultContext;
      var loaderTarget = opts.target;
      var loaderClass = opts.loaderClass || defaultLoaderClass;
      var loaderText = opts.text || "";
      var uid = "loader-" + ($.guid ? $.guid++ : $.event.guid++);
      var spinnerColor = opts.spinnerColor || "#ffffff";
      var spinnerDefaults = {
        lines: 10,
        length: 2,
        width: 2,
        radius: 2,
        top: 0,
        left: 3,
        color: spinnerColor
      };
      var spinnerConfig = {};
      spinnerConfig = $.extend(true, spinnerDefaults, opts.spinnerConfig || {});
      function start() {
        var $context = $(context);
        var $targetEl = loaderTarget ? $context.find(loaderTarget) : $context;
        $("#" + uid).remove();
        $targetEl[opts.action || "prepend"](T.to_html(loaderTemplate, {
          uid: uid,
          loaderClass: loaderClass,
          loaderText: loaderText,
          loaderContentClass: opts.contentClass || ""
        }));
        T1Spinner.apply($targetEl.find(".spinner"), spinnerConfig);
      }
      function stop() {
        var loaderEl = $("#" + uid);
        return setTimeout(function () {
          loaderEl.fadeOut(200, function () {
            loaderEl.remove();
          });
        }, 0);
      }
      return {
        start: start,
        stop: stop
      };
    }
    function progressBar(opts) {
      var ctx = "body";
      var context;
      var count = 1;
      var step = 0;
      var active = false;
      var loaderClass = "progressbar";
      var uid = "loader-" + ($.guid ? $.guid++ : $.event.guid++);
      var loaderID = "#" + uid;
      var replace = false;
      var mode;
      var onProgress = function () {};
      var onEnd = function () {};
      opts || (opts = {});
      ctx = opts.context || ctx;
      context = typeof ctx === "function" ? ctx : function () {
        return ctx;
      };
      count = opts.count || count;
      onProgress = opts.onProgress || onProgress;
      onEnd = opts.onEnd || onEnd;
      loaderClass = opts.loaderClass || loaderClass;
      replace = opts.replace || replace;
      mode = replace ? "html" : "prepend";
      function remove() {
        $(context()).find(loaderID).remove();
      }
      function isRunning() {
        return active && step < count;
      }
      function end() {
        var args = arguments;
        active = false;
        $(context()).find(loaderID).remove();
        $(context())[mode](T.to_html(progressBarTemplate, {
          loaderClass: loaderClass,
          percent: 100,
          uid: uid
        }));
        $(context()).find(loaderID).fadeOut("slow", function () {
          remove();
          onEnd.apply(onEnd, args);
        });
      }
      function progress(percent) {
        var args = arguments;
        step++;
        if (percent && percent > 100 || !percent && step >= count) {
          return end();
        }
        active = true;
        percent = percent || Math.ceil(step / count * 100);
        $(context()).find(loaderID).remove();
        $(context())[mode](T.to_html(progressBarTemplate, {
          loaderClass: loaderClass,
          percent: percent,
          uid: uid
        }));
        setTimeout(function () {
          onProgress.apply(onProgress, args);
        }, 1);
      }
      return {
        progress: progress,
        end: end,
        isRunning: isRunning
      };
    }
    loader = {
      spinner: spinner,
      progress: progressBar
    };
    function create(opts) {
      var type = opts && opts.type && opts.type === "progress" ? "progress" : "spinner";
      return loader[type](opts);
    }
    return {
      create: create
    };
  }();
});
