define('modules/reporting/campaigns/contextual/views/contextualLineChart',["jQuery", "Underscore", "ChartJS2", "T1", "T1View", "modules/campaign/chart/utils/niceNumHelper"], function ($, _, Chart, T1, T1View, NiceNumHelper) {
  "use strict";

  var COLOR_WHITE = "#fff";
  var tooltipFormat = "ddd, MMM D, YYYY";
  return T1View.extend({
    initialize: function (args) {
      this.collection = args.collection;
      this.parentClass = args.parentClass;
      this.reportModel = args.reportModel;
      this.$canvas = this.parentClass.$canvas;
      this.initChartDefaults();
    },
    initChartDefaults: function () {
      var globals = Chart.defaults.global;
      globals.defaultColor = COLOR_WHITE;
      globals.responsive = true;
      globals.responsiveAnimationDuration = 1e3;
      globals.maintainAspectRatio = false;
      globals.elements.line.borderWidth = 2;
      globals.elements.line.fill = false;
      globals.elements.line.tension = .2;
      globals.elements.point.borderColor = COLOR_WHITE;
      globals.elements.point.borderWidth = 1;
      globals.elements.point.hitRadius = 0;
      globals.elements.point.hoverBorderWidth = 1;
      globals.elements.point.hoverRadius = 5.5;
      globals.elements.point.radius = 5;
      globals.legend.display = false;
      this.origHelper = Chart.helpers.niceNum;
      Chart.helpers.niceNum = NiceNumHelper;
    },
    setupChart: function () {
      var chartData = [];
      var self = this;
      var reportModel = this.reportModel;
      var parentClass = this.parentClass;
      var yKey = parentClass.yKey;
      var yAxisLabel = parentClass.yAxisLabel;
      var $canvas = this.$canvas;
      var ctx = $canvas[0].getContext("2d");
      var collection = this.collection;
      var colorData = reportModel.get("colorData");
      if (collection && collection.loaded && colorData) {
        chartData = collection.toJSON() || [];
        if (chartData.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideCanvas();
          return;
        }
        $canvas.off("click.currentElement").on("click.currentElement", function (event) {
          self.toggleHighlighted(self.contextualChart.getElementAtEvent(event));
        });
        this.config = this.getDailyChartConfig(chartData, yKey, yAxisLabel);
        if (this.contextualChart) {
          this.contextualChart.destroy();
          this.contextualChart = null;
        }
        parentClass.showCanvas();
        parentClass.hideNoDataChart();
        this.contextualChart = new Chart(ctx, this.config);
      }
    },
    getDailyChartConfig: function (chartData, yKey, yAxisLabel) {
      var self = this;
      var reportModel = this.reportModel;
      var maxYAxisValue;
      var numTicks = 5;
      const LABEL_MAX = 50;
      const MIN_SCALE = 3;
      chartData = reportModel.filterHiddenItems(chartData, this.parentClass.dimensionKey);
      maxYAxisValue = reportModel.getMaxMetricValue(chartData, yKey);
      chartData = this.getData(chartData);
      return {
        data: chartData,
        type: "line",
        options: {
          tooltips: {
            backgroundColor: "rgba(0, 0, 0, 1)",
            bodyFontFamily: "AR",
            displayColors: false,
            enabled: true,
            footerFontStyle: "normal",
            mode: "nearest",
            titleFontFamily: "AB",
            titleFontStyle: "normal",
            titleMarginBottom: 10,
            xPadding: 12,
            yPadding: 12,
            callbacks: {
              title: function (tooltipItem, tdata) {
                var label = tdata.datasets[tooltipItem[0].datasetIndex].label || "";
                var labelArr = [];
                if (label.length > LABEL_MAX) {
                  labelArr = label.match(/([\S\s]{1,50})/g);
                }
                return labelArr.length ? labelArr : label;
              },
              label: function (tooltipItem) {
                var metricObj = self.parentClass.metricObj;
                return `${metricObj.name}: ${$.trim(self.reportModel.formatValue(tooltipItem.yLabel, metricObj.type))}`;
              },
              footer: function (tooltipItem) {
                return tooltipItem[0].xLabel;
              }
            }
          },
          scales: {
            xAxes: [{
              id: "x-axis-1",
              type: "time",
              gridLines: {
                color: "rgba(0, 0, 0, 0.1)",
                zeroLineColor: "rgba(0, 0, 0, 0.5)"
              },
              ticks: {
                autoSkip: true,
                autoSkipPadding: 10,
                fontFamily: "AR",
                fontSize: 9,
                maxRotation: 0
              },
              time: {
                round: false,
                unit: "day",
                displayFormats: {
                  day: "MMM DD"
                },
                tooltipFormat: tooltipFormat
              },
              afterTickToLabelConversion: function (scaleInstance) {
                var currentDate, currentMonth, skipRatio;
                var ticks = scaleInstance.ticks;
                var plottedDates = {};
                var calculateSkipRatio = function () {
                  var ratio = (scaleInstance.longestLabelWidth + scaleInstance.options.ticks.autoSkipPadding) * scaleInstance.ticks.length / (scaleInstance.width - (scaleInstance.paddingLeft + scaleInstance.paddingRight));
                  return 1 + Math.floor(ratio);
                };
                var getFullDate = function (month, date, time) {
                  if (time) {
                    return `${month} ${date}, ${time}`;
                  }
                  return `${month} ${date}`;
                };
                if (ticks && ticks.length) {
                  const TIME_INDEX = 2;
                  if (scaleInstance.longestLabelWidth) {
                    skipRatio = calculateSkipRatio();
                    _.each(ticks, function (item, index) {
                      var labelParts = item.split(" ");
                      var month = labelParts[0];
                      var date = labelParts[1];
                      if (skipRatio > 1 && index % skipRatio === 0) {
                        if (currentMonth !== month) {
                          currentMonth = month;
                          plottedDates[month] = {
                            date: date
                          };
                        }
                      }
                    });
                    currentMonth = null;
                  }
                  scaleInstance.ticks = _.map(ticks, function (item) {
                    var labelParts = item.split(" ");
                    var month = labelParts[0];
                    var date = labelParts[1];
                    var time = labelParts[TIME_INDEX];
                    if (currentMonth === month) {
                      if (currentDate === date) {
                        return time;
                      }
                      currentDate = date;
                      if (plottedDates[month] && plottedDates[month].date === date) {
                        return getFullDate(month, date, time);
                      }
                      if (time) {
                        return `${date}, ${time}`;
                      }
                      return date;
                    }
                    currentMonth = month;
                    currentDate = date;
                    return getFullDate(month, date, time);
                  });
                }
              }
            }],
            yAxes: [{
              display: true,
              id: "y-axis-1",
              position: "left",
              type: "linear",
              scaleLabel: {
                display: true,
                fontColor: "#000",
                fontFamily: "AB",
                fontSize: 11,
                labelString: yAxisLabel
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0.1)",
                zeroLineColor: "rgba(0, 0, 0, 0.5)"
              },
              ticks: {
                beginAtZero: true,
                fontFamily: "AR",
                fontSize: 11,
                maxTicksLimit: numTicks,
                min: 0,
                suggestedMax: maxYAxisValue,
                callback: function (value) {
                  var type = self.parentClass.metricObj.type;
                  if (type === "integers" && value > MIN_SCALE) {
                    value = Math.round(value);
                  }
                  return self.reportModel.formatValue(value, type);
                }
              }
            }]
          }
        }
      };
    },
    toggleHighlighted: function (highlightedElement) {
      var datasets, newHighlighedId;
      var highlighted = this.reportModel.get("highlightedItem");
      event.stopPropagation();
      highlightedElement = highlightedElement && highlightedElement.length ? highlightedElement[0] : null;
      datasets = this.config.data.datasets;
      if (highlightedElement) {
        newHighlighedId = _.find(datasets, (dataset, index) => index === highlightedElement._datasetIndex);
        newHighlighedId = newHighlighedId ? newHighlighedId.lineId : null;
      } else {
        newHighlighedId = null;
      }
      this.reportModel.setHighlightedItem({
        from: "line",
        id: highlighted && highlighted.id === newHighlighedId ? null : newHighlighedId,
        type: newHighlighedId ? "strategy" : "campaign"
      });
    },
    updateChart: function (isHighlight) {
      var reportModel = this.reportModel;
      var colorData = reportModel.get("colorData");
      var parentClass = this.parentClass;
      var yKey = parentClass.yKey;
      var yAxisLabel = parentClass.yAxisLabel;
      var collection = this.collection;
      var chartData, config, maxYAxisValue, scales, xAxes0, yAxes0;
      if (!this.contextualChart) {
        this.setupChart();
        return;
      }
      if (collection && collection.loaded && colorData) {
        chartData = collection.toJSON() || [];
        if (chartData.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideCanvas();
          return;
        }
        chartData = reportModel.filterHiddenItems(chartData, parentClass.dimensionKey);
        maxYAxisValue = reportModel.getMaxMetricValue(chartData, yKey);
        config = this.config;
        scales = config.options.scales;
        config.data = this.getData(chartData);
        yAxes0 = scales.yAxes[0];
        yAxes0.ticks.suggestedMax = maxYAxisValue;
        yAxes0.scaleLabel.labelString = yAxisLabel;
        xAxes0 = scales.xAxes[0];
        xAxes0.time.tooltipFormat = tooltipFormat;
        parentClass.showCanvas();
        parentClass.hideNoDataChart();
        if (isHighlight) {
          this.contextualChart.update(0);
        } else {
          this.contextualChart.update();
        }
      }
    },
    getData: function (chartData) {
      var reportModel = this.reportModel;
      var highlightedItem = reportModel.get("highlightedItem");
      var colorData = reportModel.get("colorData");
      var parentClass = this.parentClass;
      var yKey = parentClass.yKey;
      var dimensionKey = parentClass.dimensionKey;
      var dimensionName = parentClass.dimensionName;
      var yAxisLabel = parentClass.yAxisLabel;
      var highlightedId = highlightedItem ? highlightedItem.id : null;
      var allColor = !highlightedItem || highlightedItem.id === null;
      if (allColor) {
        chartData = reportModel.getLineChartDataSet(chartData, dimensionKey, dimensionName, yKey, yAxisLabel, colorData);
      } else {
        chartData = reportModel.getHighlightLineChartDataSet(chartData, dimensionKey, dimensionName, yKey, yAxisLabel, colorData, highlightedId);
      }
      return chartData;
    },
    unloadChart: function () {
      if (this.contextualChart) {
        this.contextualChart.destroy();
        this.contextualChart = null;
      }
      if (this.$canvas) {
        this.$canvas = null;
      }
      if (this.origHelper) {
        Chart.helpers.niceNum = this.origHelper;
      }
      this.origHelper = null;
      this.config = null;
    }
  });
});
