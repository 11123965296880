define('models/attributeOptions',["T1", "T1Model", "Backbone", "modules/audiences/segments/segmentUtils"], function (T1, T1Model, Backbone, Utils) {
  T1.Models.AttributeOptions = T1Model.extend({
    urlRoot: "target_values",
    apiRoot: T1.ENV.API_BASE,
    offset: 0,
    dataTarget: [],
    url: function () {
      var dimension;
      var keyValue = this.get("keyValue");
      switch (keyValue) {
        case "5":
          dimension = "DMAX";
          break;
        case "3":
          dimension = "REGN";
          break;
        case "4":
          dimension = "REGN";
          break;
        case "12":
          dimension = "BSER";
          break;
        case "11":
          dimension = "OSXX";
          break;
        case "7":
          dimension = "ISPX";
          break;
        case "6":
          dimension = "CSPD";
          break;
        case "14":
        case "20":
          this.urlRoot = "supply_sources";
          break;
        case "19":
        case "13":
          this.urlRoot = "atomic_creatives/limit/advertiser=" + this.get("advertiserId");
          break;
        case "18":
        case "17":
          this.urlRoot = "strategies/limit/campaign=" + this.get("this.campaignId");
          break;
      }
      dimension = !dimension ? "" : "&dimension=" + dimension;
      var full = !(keyValue === "19" || keyValue === "13") ? "&full=*" : "";
      return this.apiRoot + this.urlRoot + "/?sort_by=name%2Cid&order_by=ascending" + dimension + full + "&page_offset=" + this.offset;
    },
    fetch: function (options) {
      if (options.offset) {
        this.offset = options.offset;
      }
      Utils.fetchHandler(this, options, this.dataTarget);
    }
  });
  return T1.Models.AttributeOptions;
});
