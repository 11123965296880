define('modules/reporting/campaigns/views/selectMetric',["jQuery", "Underscore", "Mustache", "T1", "T1View", "text!../templates/selectMetric.html"], function ($, _, Mustache, T1, T1View, template) {
  "use strict";

  var T1Publish = T1.EventHub.publish;
  const DEBOUNCE_DELAY = 200;
  const ROW_HEIGHT = 25;
  const SINGLE_HEIGHT = 45;
  const MAX_HEIGHT = 225;
  return T1View.extend({
    template: template,
    events: {
      "changed .search": "search",
      "changed strand-dropdown": "updateChartPanel",
      "click strand-dropdown": "stopBubbling",
      "open strand-dropdown": "initDDL"
    },
    initialize: function (args) {
      var models = args.models ? args.models : args.config.models;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.section = models.mainModel.get("section");
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.isMetric2 = args.isMetric2 || false;
    },
    load: function () {
      var self = this;
      var $el = this.el;
      this.setMetric();
      this.render().then(function () {
        self.$listItems = $el.find(".metrics-ddl strand-list-item").not(".no-result");
        self.$ddlPanel = $el.find(".metrics-ddl div#list");
        self.$noResult = $el.find(".no-result");
        self.searchInput = $el.find(".search");
      });
    },
    setMetric: function () {
      var reportMetaObj = this.reportMetaObj;
      var reportModel = this.reportModel;
      var focus = reportModel.get("focus") || "all";
      var metricKey = this.reportMetaObj.metrics[focus];
      var defaultYKey = this.reportMetaObj.metricOptions[0].value;
      var yKey = this.isMetric2 ? reportModel.get("metric2") : reportModel.get("metric");
      switch (this.section) {
        case "reach_frequency":
          this.yAxisLabel = reportMetaObj.metricsReachFrequency[focus][yKey].text;
          break;
        default:
          this.yAxisLabel = metricKey[yKey] ? metricKey[yKey].text : metricKey[defaultYKey].text;
          break;
      }
    },
    initDDL: function () {
      this.searchInput.val("");
      this.search();
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    search: _.debounce(function (event) {
      var term = event ? event.originalEvent.detail.value : "";
      var label;
      var searchCount = 0;
      var $noResult = this.$noResult;
      term = term ? term.toLowerCase() : "";
      this.$listItems.addClass("no-display");
      $noResult.addClass("no-display");
      this.$listItems.each(function (index, element) {
        label = $(element).text().toLowerCase();
        if (label.indexOf(term) !== -1) {
          searchCount++;
          $(element).removeClass("no-display");
        }
      });
      if (searchCount === 0) {
        $noResult.removeClass("no-display");
        $noResult.text(`No results found for "${term}"`);
      }
      this.updateHeight(searchCount);
    }, DEBOUNCE_DELAY),
    updateHeight: function (itemCnt) {
      var itemsHeight = itemCnt === 0 ? SINGLE_HEIGHT : itemCnt * ROW_HEIGHT;
      var $ddlPanel = this.$ddlPanel;
      if (itemsHeight < MAX_HEIGHT) {
        $ddlPanel.height(itemsHeight);
      } else {
        $ddlPanel.height(MAX_HEIGHT);
      }
    },
    updateChartPanel: function (event) {
      const metric = event.originalEvent.detail.value;
      const eventName = this.isMetric2 ? "select:metric2" : "select:metric";
      if (event.currentTarget.state === "closed") {
        T1Publish(eventName, metric);
        T1Publish("track:event", {
          action: "Choose metric",
          label: metric
        });
        T1Publish("update:metric");
      }
    },
    serialize: function () {
      return {
        metrics: this.reportMetaObj.metricOptions,
        selectedMetric: this.yAxisLabel
      };
    }
  });
});
