define('T1Accordion',["jQuery", "T1"], function ($, T1) {
  T1.Accordion = function (context, options) {
    var settings = {
      callback: function () {},
      effect: "blind",
      toggleClass: "accordion-open"
    };
    settings = $.extend(settings, options);
    $(context).on("click.accordion", ".accordion", function (e) {
      var $currentEle = $(this);
      var accordionName = $currentEle.data("accordion");
      var isOpen = false;
      $currentEle.toggleClass(settings.toggleClass);
      $("." + accordionName).toggle(settings.effect, function () {
        isOpen = $currentEle.hasClass(settings.toggleClass);
        $currentEle.trigger("accordionaction", {
          accordionTarget: accordionName,
          isOpen: isOpen
        });
      });
      settings.callback();
      e.preventDefault();
    });
    return {
      unbind: function () {
        $(context).off("click.accordion");
      }
    };
  };
});
