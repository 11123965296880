define('modules/reporting/campaigns/technology/views/technology',["moment", "T1", "T1View", "T1Layout", "models/userPreferences", "modules/reporting/campaigns/models/additionalData", "text!../templates/technology.html", "T1Signature", "T1Preferences"], function (moment, T1, T1View, T1Layout, UserPreferences, additionalData, template) {
  "use strict";

  let Campaign, ReportMeta, ReportModel;
  const three = 3;
  const dateLimit = moment().subtract(three, "months");
  const defaultDimension = "connection_speed";
  const defaultDimension2 = "strategy_name";
  const reportID = "device_technology";
  return T1View.extend({
    settings_menu_config: {
      edit_campaign: {
        label: "Campaign",
        items: {
          edit_campaign: {
            download_url: () => `#campaign/edit/${Campaign.id}/details/general`,
            item_type: "action-item",
            label: "Edit Campaign",
            url: "#campaign/edit/"
          }
        }
      },
      view_summary: {
        label: "Campaign",
        items: {
          view_summary: {
            download_url: () => `#campaign/readonly/${Campaign.id}/details/general`,
            item_type: "action-item",
            label: "View Summary",
            url: "#campaign/readonly/"
          }
        }
      },
      raw_data: {
        label: "Export CSV",
        items: {
          export_dimension: {
            contentType: "text/csv",
            download_url: () => ReportModel.getDownloadURL({
              byDimension: true,
              isSummary: true,
              sourceURL: `std/${reportID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: () => `By ${ReportModel.get("dimensionInfo").text}`,
            subSection: " (Summary)",
            url: `std/${reportID}/download`
          },
          export_dimension2: {
            contentType: "text/csv",
            download_url: () => ReportModel.getDownloadURL({
              byDimension: true,
              sourceURL: `std/${reportID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: () => `By ${ReportModel.get("dimensionInfo").text}`,
            subSection: " (Daily)",
            url: `std/${reportID}/download`
          },
          export_all_dimensions: {
            contentType: "text/csv",
            download_url: () => ReportModel.getDownloadURL({
              byDimension: true,
              dimensions: Object.keys(ReportMeta.get("dimensions").device_technology.viewBy).join(","),
              isSummary: true,
              sourceURL: `std/${reportID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: "By All Dimensions",
            subSection: " (Summary)",
            url: `std/${reportID}/download`
          },
          export_all_dimensions2: {
            contentType: "text/csv",
            download_url: () => ReportModel.getDownloadURL({
              byDimension: true,
              dimensions: Object.keys(ReportMeta.get("dimensions").device_technology.viewBy).join(","),
              sourceURL: `std/${reportID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: "By All Dimensions",
            subSection: " (Daily)",
            url: `std/${reportID}/download`
          }
        }
      }
    },
    campaignBenchmarkOn: "",
    defaultSubSection: "daily",
    dimension: "",
    dimension2: "",
    displayDimension: "",
    displayDimension2: "",
    displayMetric: "",
    displaySelectedColumns: "",
    displaySortKeyInfo: "",
    focus: "",
    interval: "last_30_days",
    metric: "",
    metric2: "",
    section: "technology",
    selectedColumns: "",
    sortKeyInfo: "",
    subSection: "daily",
    template: template,
    videoDimension: "",
    videoDimension2: "",
    videoMetric: "",
    videoSelectedColumns: "",
    videoSortKeyInfo: "",
    chartFiltersMetrics: ["impressions", "clicks", "post_click_conversions", "post_view_conversions", "total_spend"],
    events: {
      click: "dehighlightItem"
    },
    eventhubEvents: {
      "select:focus": function (focus) {
        this.focus = focus;
        this.preferences.save();
      },
      "select:dimension": function (dimension = defaultDimension) {
        const key = this.focus === "all" ? "dimension" : `${this.focus}Dimension`;
        this[key] = dimension;
        this.preferences.save();
        ReportModel.setDimension(dimension);
      },
      "select:dim2": function (dimension) {
        const key = this.focus === "all" ? "dimension2" : `${this.focus}Dimension2`;
        this[key] = dimension;
        this.preferences.save();
        ReportModel.setDimension(null, null, null, dimension);
      },
      "remove:dim2": function () {
        const key = this.focus === "all" ? "dimension2" : `${this.focus}Dimension2`;
        this[key] = "";
        this.preferences.save();
      },
      "select:metric": function (metric) {
        const key = this.focus === "all" ? "metric" : `${this.focus}Metric`;
        this[key] = metric;
        this.preferences.save();
        ReportModel.setMetric(this[key]);
      },
      "select:metric2": function (metric) {
        const key = this.focus === "all" ? "metric2" : `${this.focus}Metric2`;
        this[key] = metric;
        this.preferences.save();
        ReportModel.setMetric2(this[key]);
      },
      "select:interval": function (interval) {
        this.interval = interval;
        this.preferences.save();
        ReportModel.setInterval(interval);
      },
      "preference:change": function (args) {
        this[args.key] = args.value;
        this.preferences.save();
      },
      "reporting:campaign:load": "refreshUI",
      "reporting:meta:load": "reload",
      "reporting:focus:meta:load": function () {
        this.initPreferences();
        this.refreshUI();
      },
      "select:chart": function (chartType) {
        const mainModel = this.mainModel;
        this.subSection = chartType;
        this.preferences.save();
        mainModel.setSubSection(mainModel.get("section"), chartType);
      }
    },
    refreshUI() {
      ReportModel.clearTempDimension();
      T1.EventHub.publish("unload:noDataMessages");
      this.load();
    },
    initialize(cfgs = {}) {
      const {
        models = {},
        report: report
      } = cfgs;
      const {
        campaign: campaign,
        mainMeta: mainMeta,
        mainModel: mainModel,
        summary: summary,
        reportMeta: reportMeta,
        reportModel: reportModel
      } = models;
      Object.assign(this, {
        mainMeta: mainMeta,
        mainModel: mainModel,
        models: models,
        needEvents: true,
        report: report,
        summary: summary
      });
      Campaign = campaign;
      ReportMeta = reportMeta;
      ReportModel = reportModel;
      ReportModel.setSection(reportID);
      ReportModel.setDateLowerBounds(dateLimit);
      this.mainModel.setReportMainView(this);
    },
    initPreferences() {
      let dimensionKey, dimensionKey2, focus, metricKey, metricKey2;
      const {
        mainMeta: mainMeta,
        mainModel: mainModel
      } = this;
      const {
        filterBy: filterBy,
        viewBy: viewBy
      } = ReportMeta.get("dimensions").device_technology;
      const defaultMetric = ReportModel.getDefaultMetric(Campaign);
      const defaultMetric2 = "impressions";
      const technologySubSection = mainMeta.get("technologySubSection");
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", reportID]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["campaignBenchmarkOn", "dimension", "dimension2", "displayDimension", "displayDimension2", "displayMetric", "displaySelectedColumns", "displaySortKeyInfo", "focus", "interval", "metric", "metric2", "selectedColumns", "sortKeyInfo", "subSection", "videoDimension", "videoDimension2", "videoMetric", "videoSelectedColumns", "videoSortKeyInfo"]);
      ReportModel.setPreferences(this.preferences);
      ReportModel.setInterval(this.interval);
      if (technologySubSection) {
        this.subSection = technologySubSection;
      } else {
        this.subSection = UserPreferences.get("ui.reporting.device_technology.subSection") || this.defaultSubSection;
      }
      mainModel.setSubSection(mainModel.get("section"), this.subSection);
      this.focus = focus = this.focus || "all";
      ReportModel.setFocus(focus, true);
      ReportMeta.parseDimensions("dimensionOptions", viewBy);
      ReportMeta.parseDimensions("dimension2Options", filterBy);
      ReportMeta.parseMetrics();
      ReportModel.parseColumns();
      if (focus === "all") {
        dimensionKey = "dimension";
        dimensionKey2 = "dimension2";
        metricKey = "metric";
        metricKey2 = "metric2";
      } else {
        dimensionKey = `${focus}Dimension`;
        dimensionKey2 = `${focus}Dimension2`;
        metricKey = `${focus}Metric`;
        metricKey2 = `${focus}Metric2`;
      }
      const dimension = this[dimensionKey];
      const dimension2 = this[dimensionKey2];
      const {
        filterBy: dimFilterBy,
        viewBy: dimViewBy
      } = additionalData.dimensions.device_technology;
      const verifiedDimensions = Object.keys(dimViewBy);
      const verifiedDimension = verifiedDimensions.includes(dimension) ? dimension : defaultDimension;
      let verifiedDimension2 = "";
      if (dimension2) {
        const verifiedDimensions2 = Object.keys(dimFilterBy);
        if (verifiedDimensions2.includes(dimension2)) {
          verifiedDimension2 = dimension2;
        } else {
          verifiedDimension2 = defaultDimension2;
        }
      }
      this[dimensionKey] = verifiedDimension;
      this[dimensionKey2] = verifiedDimension2;
      this.preferences.save();
      ReportModel.setDimension(verifiedDimension, false, true, verifiedDimension2, false, true, true);
      ReportModel.setMetric(this[metricKey] || defaultMetric, true);
      ReportModel.setMetric2(this[metricKey2] || defaultMetric2, true);
    },
    initLayouts() {
      const {
        chartFiltersMetrics: chartFiltersMetrics,
        models: models,
        report: report
      } = this;
      const {
        filterBy: filterBy,
        viewBy: viewBy
      } = ReportMeta.get("dimensions").device_technology;
      const layoutConfig = {
        el: () => this.$(".w-technology-panel"),
        template: `<div class="tabs-panel">\n          <div class="tabs-control-focus"></div>\n          <div class="tabs-control-dimensions"></div>\n          <div class="tabs-control-dimensions-2"></div>\n          \x3c!-- right-aligned items, hence reverse order --\x3e\n          <div class="tabs-info"></div>\n          <div class="tabs-settings"></div>\n          <div class="technology-panel"></div>\n          <div class="report-chart-filters hidden"></div>\n          </div>`,
        layout: {
          ".tabs-control-focus": [{
            module: "reporting/campaigns",
            options: {
              models: models
            },
            viewType: "select_focus"
          }],
          ".tabs-control-dimensions": [{
            module: "reporting/campaigns",
            options: {
              dimensionObj: viewBy,
              dimensionOptions: ReportMeta.get("dimensionOptions"),
              index: 0,
              label: "View by:",
              models: models,
              report_type: reportID
            },
            viewType: "select_dimension"
          }],
          ".tabs-control-dimensions-2": [{
            module: "reporting/campaigns",
            options: {
              dimensionObj: filterBy,
              dimensionOptions: ReportMeta.get("dimension2Options"),
              index: 1,
              label: "+ Add Filter",
              models: models
            },
            viewType: "select_dimension"
          }],
          ".tabs-settings": [{
            module: "reporting/campaigns",
            options: {
              menu_config: this.settings_menu_config,
              models: models
            },
            viewType: "settings_menu"
          }],
          ".tabs-info": [{
            module: "reporting/campaigns",
            viewType: "report_info"
          }],
          ".technology-panel": [{
            module: "reporting/campaigns/technology",
            options: {
              chartFiltersMetrics: chartFiltersMetrics,
              models: models,
              report: report,
              subSection: this.subSection
            },
            viewType: "panel"
          }],
          ".report-chart-filters": [{
            module: "reporting/campaigns/technology",
            options: {
              chartFiltersMetrics: chartFiltersMetrics,
              models: models,
              report: report
            },
            viewType: "chartFilters"
          }]
        }
      };
      this.layout = new T1Layout(layoutConfig);
    },
    load() {
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (this.needEvents) {
        this.bindViewEvents();
        this.needEvents = false;
      }
      ReportModel = this.models.reportModel;
      ReportMeta = ReportModel.reportMeta;
      ReportModel.setSection(reportID, true);
      this.unloaded = false;
      if (Campaign.id && ReportMeta.loaded) {
        if (this.layout) {
          this.layout.unload();
        }
        this.initPreferences();
        this.initLayouts();
        this.render().then(() => {
          this.layout.load();
          this.loaded = true;
        });
      }
    },
    unload() {
      this.unloaded = true;
      this.needEvents = true;
    },
    dehighlightItem() {
      ReportModel.setHighlightedItem();
    }
  });
});
