define('models/audienceVendor',["T1", "T1Model"], function (T1, T1Model) {
  "use strict";

  T1.Models.AudienceVendor = T1Model.extend({
    apiRoot: T1.ENV.API_BASE,
    canCache: true,
    id: "",
    url() {
      return `${this.apiRoot}audience_vendors/${this.id}`;
    }
  });
  return T1.Models.AudienceVendor;
});
