define('modules/reporting/campaigns/performance/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "performance",
    name: "reporting/campaigns/performance",
    viewCfgs: {
      chartPanel: {
        model: null
      },
      chartTitle: {
        model: null
      },
      columnSelector: {
        model: null
      },
      controls: {
        model: null
      },
      grid: {
        model: null
      },
      panel: {
        model: null
      },
      performance: {},
      performanceBarChart: {
        model: null
      },
      performanceChart: {
        model: null
      },
      performanceLineChart: {
        model: null
      },
      strategyQuickEdit: {
        model: null
      }
    }
  });
});
