define('modules/campaign/pixelSegment/views/filterSearch',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "collections/pixelAdvertisers", "collections/agencies", "collections/dataproviders", "collections/audienceProviders", "text!modules/campaign/pixelSegment/templates/filterSearch.html"], function ($, _, T1, T1View, T1Permissions, Advertisers, Agencies, DataProviders, AudienceProviders, template) {
  "use strict";

  const minCharLength = 3;
  const enterKey = 13;
  const CAN_ACCESS_ADAPTIVE = T1Permissions.check("feature", "adaptive-segments");
  const adaptiveSegments = {
    text: "Segments",
    value: "dynamic_segment"
  };
  const pixelTypeFilterList = [{
    text: "Event Pixels",
    value: "advertisers"
  }, {
    text: "Data Pixels",
    value: "data"
  }];
  if (CAN_ACCESS_ADAPTIVE) {
    pixelTypeFilterList.push(adaptiveSegments);
  }
  const PixelFilterSearch = T1View.extend({
    template: template,
    pixelTypeFilterList: pixelTypeFilterList,
    pixelTypeLookupList: {
      advertisers: {
        type: "event",
        filter: "advertisers",
        placeholder: "Select One"
      },
      data: {
        type: "data",
        filter: "data",
        placeholder: "All data providers"
      },
      dynamic_segment: {
        type: "dynamic",
        filter: "advertisers",
        placeholder: "Select One"
      },
      audience_segments: {
        type: "audience_segments",
        filter: "audience_segments",
        placeholder: "Select Provider"
      }
    },
    audienceFilterList: [{
      name: "All Providers",
      id: 0
    }],
    activeCollectionTypeInfo: null,
    activeCollection: null,
    pixelScopeList: [],
    events: {
      "keyup input": "searchPixels",
      "click .clear-filter": "clearFilter"
    },
    initialize: function ({
      advertiser: advertiser,
      orgId: orgId,
      campaign_id: campaign_id,
      getStrategyAgencyId: getStrategyAgencyId
    }) {
      var agencies = new Agencies();
      var dataProviders = new DataProviders();
      var audienceSegments = new AudienceProviders([], {
        isPaginated: false,
        fetchOptions: {
          advertiser_id: advertiser.id
        }
      });
      if (!orgId) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
      this.advertiser = advertiser || null;
      const advertisers = new Advertisers([], {
        campaign_id: campaign_id
      });
      this.activeCollectionTypeInfo = this.pixelTypeLookupList.advertisers;
      this.availableCollections = {
        advertisers: advertisers,
        agencies: agencies,
        data: dataProviders,
        audienceSegments: audienceSegments
      };
      if (getStrategyAgencyId) {
        this.getStrategyAgencyId = getStrategyAgencyId;
        dataProviders.urlFilter.set({
          entity: "agency",
          id: this.getStrategyAgencyId(),
          fetchRequired: false
        });
      }
      const filterConfig = {
        entity: "organization",
        id: orgId,
        fetchRequired: false
      };
      agencies.urlFilter.set(filterConfig);
      this.availableCollections.audienceSegments.fetch({
        success: data => {
          const iasModel = _.find(data.models, function (model) {
            return model.get("name") === "Integral Ad Science" || model.id === COMPASS_ENV.IAS_ID;
          });
          data.remove(iasModel);
          if (data.models.length) {
            const alreadyHasAS = this.pixelTypeFilterList.find(({
              value: value
            }) => value === "audience_segments");
            if (!alreadyHasAS) {
              this.pixelTypeFilterList.push({
                text: "Audience Providers",
                value: "audience_segments"
              });
            }
            this.load();
          }
        }
      });
    },
    load: function () {
      var self = this;
      var wholeAudienceFilterList = [];
      this.render().then(() => {
        const primaryFilterBox = this.$(".primaryFilter select");
        const secondaryFilterBox = this.$(".secondaryFilter select");
        function applyFilter() {
          var primaryFilterValue = primaryFilterBox.val();
          var secondaryFilterValue = secondaryFilterBox.val();
          var secondaryFilterText = secondaryFilterBox.text();
          const isAdvertiserFilter = primaryFilterValue.includes("dynamic_segment") || primaryFilterValue.includes("advertisers");
          const advertiser = secondaryFilterValue && !isAdvertiserFilter ? {
            id: secondaryFilterValue,
            name: secondaryFilterText
          } : self.advertiser;
          self.activeCollectionTypeInfo = self.pixelTypeLookupList[primaryFilterValue];
          if (primaryFilterValue === "audience_segments") {
            setupAudienceSearchFilter();
            self.renderPartial(".secondaryFilter select", {
              data: wholeAudienceFilterList,
              isEventPixel: true
            });
            secondaryFilterBox.val(wholeAudienceFilterList[0]);
          } else {
            self.renderPartial(".secondaryFilter select", {
              data: [advertiser],
              isEventPixel: isAdvertiserFilter
            });
          }
          secondaryFilterBox.data("chosen").default_text = self.activeCollectionTypeInfo.placeholder;
          secondaryFilterBox.attr("data-placeholder", self.activeCollectionTypeInfo.placeholder);
          secondaryFilterBox.trigger("liszt:updated");
          secondaryFilterBox.trigger("clearDropDownPreviousSearch");
          self.applyFilter(self.activeCollectionTypeInfo.type, primaryFilterValue, isAdvertiserFilter ? advertiser.id : undefined);
        }
        function setupAudienceSearchFilter() {
          var providersList = self.availableCollections.audienceSegments.toJSON();
          for (let i = 0; i < providersList.length; i++) {
            const vendor = providersList[i];
            if (vendor.name === "Integral Ad Science" || vendor.id === COMPASS_ENV.IAS_ID) {
              providersList.splice(i, 1);
              break;
            }
          }
          const filterList = $.map(providersList, function (provider) {
            return {
              name: provider.name || "",
              id: provider.audience_vendor_id || ""
            };
          });
          filterList.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (b.name.toLowerCase() > a.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          wholeAudienceFilterList = self.audienceFilterList.concat(filterList);
        }
        const primaryConfig = {
          enableDirectionAuto: false,
          searchFieldDisabled: true,
          onChange: applyFilter
        };
        T1.Selectbox(primaryFilterBox, primaryConfig);
        const secondaryConfig = {
          enableDirectionAuto: false,
          searchFieldDisabled: false,
          onChange: function () {
            var primaryFilterValue = primaryFilterBox.val();
            var secondaryFilterValue = secondaryFilterBox.val();
            self.activeCollectionTypeInfo = self.pixelTypeLookupList[primaryFilterValue];
            self.applyFilter(self.activeCollectionTypeInfo.type, primaryFilterValue, secondaryFilterValue);
          }
        };
        const autoCompleteConfig = {
          autoComplete: true,
          searchField: "name",
          onSearchRequest: function (searchOptions) {
            if (self.activeCollectionTypeInfo.filter === "audience_segments") {
              self.activeCollection = self.availableCollections.audienceSegments;
              const searchTerm = searchOptions.params[0].term.substring(1, searchOptions.params[0].term.length - 1);
              if (searchTerm.length >= minCharLength) {
                searchOptions = $.extend({
                  fetchRequired: true
                }, searchOptions);
                self.activeCollection.search.set(searchOptions);
                $(secondaryFilterBox).parent().find(".search-loader").removeClass("search-loader");
              } else {
                $(secondaryFilterBox).parent().find(".search-loader-no-bg").removeClass("search-loader-no-bg");
              }
            } else {
              self.activeCollection = self.availableCollections[self.activeCollectionTypeInfo.filter];
              searchOptions = $.extend({
                fetchRequired: true
              }, searchOptions);
              self.activeCollection.search.set(searchOptions);
              $(secondaryFilterBox).parent().find(".search-loader").removeClass("search-loader");
            }
          }
        };
        T1.Selectbox(secondaryFilterBox, secondaryConfig, autoCompleteConfig);
      });
    },
    searchPixels: function (event) {
      var key = event.which;
      const $inputBox = this.$(".term input");
      var $inputParent = $inputBox.parent();
      var term = $inputBox.val().trim();
      const $filterClearer = this.$(".clear-filter");
      const hideMagGlass = "hide-magnifying-glass";
      $inputParent.removeClass("error");
      if (term.length) {
        $inputBox.addClass(hideMagGlass);
        $filterClearer.show();
      } else if (!this.isSearch) {
        $inputBox.removeClass(hideMagGlass);
        $filterClearer.hide();
      }
      if (key === enterKey) {
        if (!term.length) {
          this.clearFilter(event);
        } else if (term.length < minCharLength) {
          this.applyTooltip($inputBox, "Please enter at least 3 characters.");
          this.delegateEvents();
          this.showTooltip(event, $inputParent);
        } else {
          this.isSearch = true;
          T1.EventHub.publish("pixel:search", {
            term: term
          });
        }
      }
    },
    clearFilter: function (event) {
      const $inputBox = this.$(".term input");
      event.preventDefault();
      this.$(".clear-filter").hide();
      $inputBox.removeClass("hide-magnifying-glass").val("");
      this.removeTooltip({
        currentTarget: $inputBox
      });
      this.isSearch = false;
      T1.EventHub.publish("pixel:search", {
        term: ""
      });
    },
    applyFilter: function (entityType, filterType, id) {
      var filterConfiguration = {
        entity: filterType,
        type: entityType
      };
      if (id && entityType !== "data") {
        filterConfiguration[`${filterType}_id`] = id;
      } else if (id) {
        filterConfiguration.provider_id = id;
      }
      T1.EventHub.publish("pixel:applyFilter", filterConfiguration);
    },
    serialize: function () {
      return {
        pixelTypeFilterList: this.pixelTypeFilterList,
        data: [this.advertiser],
        placeholder: this.activeCollectionTypeInfo.placeholder,
        isEventPixel: true
      };
    }
  });
  return T1.Form.ViewUtils.extend(PixelFilterSearch, ["applyTooltip", "showTooltip", "removeTooltip"]);
});
