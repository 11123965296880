define('modules/campaign/views/duplicateStrategySelection',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!modules/campaign/templates/duplicateStrategySelection.html"], function ($, _, T1, T1View, T1Layout, template) {
  "use strict";

  var DuplicateStrategySelection = T1View.extend({
    template: template,
    tooltipConfig: {
      className: "w-FormError",
      delayIn: 0,
      delayOut: 0,
      fade: true,
      getExternalTip: false,
      gravity: "s",
      html: true,
      offset: 10,
      trigger: "manual"
    },
    events: {
      "click .showSelectedStrategies": "onShowActiveOnlyClick",
      "changed #strategies-search": "onStrategySearchChanged"
    },
    eventhubEvents: {
      "campaign:strategiesCopiedSuccessfully": function () {
        this.unload();
      }
    },
    initialize: function ({
      model: model
    }) {
      this.model = model;
      this.initLayout();
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.$(".w-StrategySelection"),
        selectorPath: ".duplicate-strategies-grid",
        template: '<div class="duplicate-grid-holder"></div>',
        layout: {
          ".duplicate-grid-holder": [{
            module: "campaign",
            viewType: "copyStrategiesGrid",
            options: {
              id: this.model.id,
              model: this.model
            }
          }]
        }
      });
    },
    load: function () {
      this.render().then(() => {
        this.layout.load();
      });
    },
    onShowActiveOnlyClick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.$("#strategies-search").attr("value", "");
      T1.EventHub.publish("strategyCopyGrid:reload", e.target.value);
    },
    onStrategySearchChanged: function (e) {
      const searchTerm = e.currentTarget.value;
      const debounceRate = 1e3;
      const lazySearch = _.debounce(term => {
        if (term.length <= 1 && term !== "") {
          e.currentTarget.error = true;
          $(e.currentTarget).tipsy("show");
        } else if (term === "") {
          e.currentTarget.error = false;
          $(e.currentTarget).tipsy("hide");
          T1.EventHub.publish("strategyCopyGrid:search", term);
        } else {
          e.currentTarget.error = false;
          $(e.currentTarget).tipsy("hide");
          T1.EventHub.publish("strategyCopyGrid:search", term);
        }
      }, debounceRate, true);
      T1.Tooltip(this.el, Object.assign({
        tooltipEle: "#strategies-search"
      }, this.tooltipConfig));
      lazySearch(searchTerm || "");
    },
    unload: function () {
      this.destroy();
    }
  });
  return DuplicateStrategySelection;
});
