define('modules/reporting/campaigns/reachFrequency/views/controls',["jQuery", "Underscore", "T1", "T1View", "text!../templates/controls.html", "T1Layout"], function ($, _, T1, T1View, template, T1Layout) {
  "use strict";

  var ControlsView = T1View.extend({
    selectedColumns: null,
    template: template,
    events: {
      "click strand-button": "showColumnSelector"
    },
    dataEvents: {
      reportModel: {
        "change:columnKeys": "updateMetadataSortKeys",
        "change:sortKeyInfo": "resortCollection"
      }
    },
    initialize: function (args) {
      var models = args.models;
      this.collection = args.collection;
      this.reportMeta = models.reportMeta;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
    },
    load: function () {
      var reportModel = this.reportModel;
      var preferences = reportModel.get("preferences");
      var focus = reportModel.get("focus");
      var getFocusKey = reportModel.makeFocusKeyFunc(focus);
      var selectedColumns = preferences.get(getFocusKey("selectedColumns"));
      var sortKeyInfo = preferences.get(getFocusKey("sortKeyInfo"));
      var defaultSortKeyInfo = this.mainModel.get("multiCampaigns") ? "impressions,-1,0" : "frequency_bin,-1,1";
      reportModel.setColumnKeys(selectedColumns === undefined || selectedColumns === "" ? reportModel.get("defaultColumnKeys") : selectedColumns.split(","));
      reportModel.setSortKeyInfo(sortKeyInfo === undefined || sortKeyInfo === "" ? defaultSortKeyInfo : sortKeyInfo);
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.getFocusKey = getFocusKey;
      this.updateMetadataSortKeys();
      this.render();
    },
    updateMetadataSortKeys: function () {
      var reportModel = this.reportModel;
      var sortKeyInfo = reportModel.get("sortKeyInfo");
      var sortKeyArray = sortKeyInfo ? sortKeyInfo.split(",") : [];
      var columnKeys = reportModel.get("columnKeys");
      var sortKeyIndexInColumnKeys = sortKeyArray.length > 0 ? $.inArray(sortKeyArray[0], columnKeys) : -1;
      if (sortKeyIndexInColumnKeys === -1 && sortKeyArray[0] !== "frequency_bin" && sortKeyArray[0] !== "uniques") {
        this.reportModel.setSortKeyInfo(columnKeys.length === 0 ? "frequency_bin,-1,1" : `${columnKeys[0]},-1`);
      }
    },
    resortCollection: function (reportModel) {
      var sortKeyArray;
      var sortKeyInfo = reportModel.get("sortKeyInfo");
      const TIME_SERIES = 2;
      if (sortKeyInfo && sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        this.collection.sortByKey(sortKeyArray[0], parseInt(sortKeyArray[1], 10), "summary", false, parseInt(sortKeyArray[TIME_SERIES], 10));
      }
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("sortKeyInfo"),
        value: sortKeyInfo
      });
    },
    prepareMultiSortableData: function () {
      var self = this;
      var reportMeta = this.reportMeta;
      var allColumnsObj = $.extend({}, reportMeta.additionalData.columns.reach_frequency);
      var selectedKeys = this.selectedColumns ? this.selectedColumns.split(",") : this.reportModel.get("defaultColumns");
      var selectedCols = [];
      var availableCols, availableKeys, customText;
      _.each(selectedKeys, function (obj) {
        var key = self.selectedColumns ? obj : obj.value;
        if (allColumnsObj[key]) {
          customText = allColumnsObj[key].text;
          delete allColumnsObj[key];
        } else {
          return;
        }
        selectedCols.push({
          value: key,
          text: customText || reportMeta.getFriendlyName("metrics", key)
        });
      });
      availableKeys = _.keys(allColumnsObj);
      availableCols = _.map(availableKeys, function (key) {
        customText = allColumnsObj[key].text;
        return {
          value: key,
          text: customText || reportMeta.getFriendlyName("metrics", key)
        };
      });
      this.availableColumnsArray = availableCols;
      this.selectedColumnsArray = selectedCols;
    },
    initLayout: function () {
      var self = this;
      const {
        onFormClose: onFormClose,
        saveColumns: saveColumns
      } = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".column-selector-dialog");
        },
        template: '<div class="column-selector"></div>',
        layout: {
          ".column-selector": [{
            module: "reporting/campaigns/reachFrequency",
            viewType: "columnSelector",
            options: {
              listA: self.availableColumnsArray,
              listB: self.selectedColumnsArray,
              onCloseCallback: function (obj) {
                if (obj) {
                  saveColumns.call(self, obj);
                }
                onFormClose.call(self);
              }
            }
          }]
        }
      });
    },
    saveColumns: function (obj) {
      var listB = obj.listB;
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("selectedColumns"),
        value: listB.toString()
      });
      this.reportModel.setColumnKeys(listB);
    },
    onFormClose: function () {
      delete this.layout.layout[".column-selector-dialog"];
    },
    showColumnSelector: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.selectedColumns = this.reportModel.get("preferences").get(this.getFocusKey("selectedColumns"));
      this.prepareMultiSortableData();
      this.initLayout();
      this.layout.load();
    }
  });
  return ControlsView;
});
