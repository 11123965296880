define('modules/shared/views/filter',["jQuery", "T1", "T1View", "text!../templates/filter-box.html", "Underscore"], function ($, T1, T1View, tmpl, _) {
  "use strict";

  var FilterView = T1View.extend({
    template: tmpl,
    events: {
      "keyup [data-bind=search-term]": "filter",
      "mousedown .clear-filter": "clearFilter",
      "click input:checkbox": "toggleShowActive"
    },
    initialize: function (args) {
      var hasSelectBox = args.selectOptions && args.filterClass;
      var hideSearch = args.hideSearch;
      var selectOptions = args.selectOptions;
      var showActive = args.showActiveCallback;
      var tdata = {};
      var customPlaceholderText = args.customPlaceholderText;
      var cachedDisable = args.isDisabled;
      var check_length = args.check_length !== undefined ? args.check_length : true;
      this.lazySearch = this.initializeLazySearch(this.search);
      this.check_length = check_length;
      this.isDisabled = function () {
        return typeof cachedDisable === "function" ? cachedDisable() : cachedDisable;
      };
      this.filterCallback = args.filterCallback;
      this.clearFilterCallback = args.clearFilterCallback;
      this.autoCompleteConfig = args.autoCompleteConfig || {};
      this.selectedId = args.selectedId || "";
      this.enableDirectionAuto = args.enableDirectionAuto === false ? false : true;
      if (hasSelectBox) {
        tdata.hasSelectBox = true;
        tdata.filterClass = args.filterClass;
      }
      if (hideSearch) {
        tdata.hideSearch = true;
      }
      if (showActive) {
        tdata.hasShowActiveCheckbox = true;
        this.showActiveCallback = args.showActiveCallback;
      }
      tdata.placeholderText = customPlaceholderText ? customPlaceholderText : "Search";
      tdata.disabled = this.isDisabled();
      tdata.defaultValue = this.defaultValue = args.defaultValue || "";
      tdata.maxlength = args.maxlength;
      this.serialize = function () {
        tdata.selectOptions = typeof selectOptions === "function" ? selectOptions() : selectOptions;
        return tdata;
      };
    },
    toggleShowActive: function (event) {
      this.showActiveCallback.call(this, event);
    },
    filter: function (event, flags) {
      var $el = $(this.el);
      var $inputBox = $el.find("[data-bind='search-term']");
      var $inputParent = $inputBox.parent();
      var term = $inputBox.val().trim();
      var $filterClearer = $el.find(".clear-filter");
      var hide_mag_glass = "hide-magnifying-glass";
      $inputParent.removeClass("error");
      if (term.length) {
        $inputBox.addClass(hide_mag_glass);
        $filterClearer.show();
      } else if (!this.isSearch) {
        $inputBox.removeClass(hide_mag_glass);
        $filterClearer.hide();
      }
      this.term = term;
      this.flags = flags;
      this.event = event;
      this.inputParent = $inputParent;
      this.inputBox = $inputBox;
      this.lazySearch();
    },
    initializeLazySearch: function (callback) {
      return _.debounce(callback, 1e3);
    },
    search: function () {
      var self = this;
      if (self.term.length === 0) {
        if (self.isSearch) {
          self.clearFilter(self.event);
        }
      } else if (self.check_length && self.term.length > 0 && self.term.length < 2) {
        self.applyTooltip(self.inputBox, "Please enter at least 2 characters.");
        self.delegateEvents();
        self.showTooltip(self.event, self.inputParent);
      } else {
        self.isSearch = true;
        if (self.selectedId && self.selectedId !== "") {
          self.flags = $.extend(self.flags, {
            id: self.selectedId
          });
        }
        self.filterCallback.call(self, self.term.replace(/%/g, "%25").replace(/&/g, "%26").replace(/\+/g, "%2B"), self.flags);
      }
    },
    clearFilter: function (event) {
      var $el = $(this.el);
      var $inputBox = $el.find("[data-bind=search-term]");
      if (this.isDisabled()) {
        return;
      }
      event.preventDefault();
      $el.find(".clear-filter").hide();
      $inputBox.removeClass("hide-magnifying-glass").val("").parent().removeClass("error");
      this.removeTooltip({
        currentTarget: $inputBox
      });
      this.clearingSearchTerm = true;
      if (this.isSearch) {
        this.clearFilterCallback.call(this, "", {
          id: this.selectedId
        });
        this.isSearch = false;
      }
    },
    load: function () {
      var self = this;
      var keyupEvent = $.Event("keyup", {
        which: 13
      });
      var filterConfig;
      this.render().then(function () {
        if (self.defaultValue !== "") {
          var $el = $(self.el);
          $el.find("[data-bind='search-term']").addClass("hide-magnifying-glass");
          $el.find(".clear-filter").show();
          self.isSearch = true;
        }
        filterConfig = {
          enableDirectionAuto: self.enableDirectionAuto,
          searchFieldDisabled: self.autoCompleteConfig === {},
          onChange: function (evt) {
            self.selectedId = evt.target.value;
            self.isSearch = true;
            self.filter.call(self, keyupEvent, {
              id: self.selectedId
            });
          }
        };
        T1.Selectbox($(self.el).find(".select-input"), filterConfig, self.autoCompleteConfig);
      });
    }
  });
  return T1.Form.ViewUtils.extend(FilterView);
});
