define('T1PicardVersion',["jQuery", "T1", "T1Model", "T1Comm"], function ($, T1, T1Model, T1Comm) {
  var picardVersion;
  if (T1.PICARD_VERSION) {
    return T1.PICARD_VERSION;
  }
  picardVersion = T1Model.extend({
    apiRoot: T1.RPT_API_ROOT,
    name: "version",
    url: "version/",
    fixture: "-version",
    parse: function (response) {
      var output = {};
      output.picard_build = response.version.build;
      output.picard_release = response.version.release;
      return output;
    },
    initialize: function () {
      this.fetch();
    }
  });
  T1.PICARD_VERSION = new picardVersion();
  return T1.PICARD_VERSION;
});
