define('modules/reporting/campaigns/dataExport/manage/views/grid',["jQuery", "Underscore", "T1", "T1View", "text!../templates/grid.html"], function ($, _, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const ManageGridView = T1View.extend({
    template: template,
    events: {
      "click .manage-grid": "gridClickHandler"
    },
    eventhubEvents: {
      "saved:report"() {
        this.DataExportModel.updateSavedReportsList();
      }
    },
    dataEvents: {
      DataExportModel: {
        "change:savedReports": "updateGrid"
      }
    },
    initialize({
      dataExportModel: dataExportModel
    }) {
      this.DataExportModel = dataExportModel;
    },
    load() {
      this.render().then(() => {
        window.requestAnimationFrame(() => {
          this.manageGrid = this.$(".manage-grid")[0];
          this.manageGrid.indicate = "";
          this.updateGrid();
        });
      });
    },
    unload() {
      this.manageGrid = null;
    },
    updateGrid() {
      const DataExportModel = this.DataExportModel;
      const status = DataExportModel.get("manageGridStatus");
      const savedReports = DataExportModel.get("savedReports");
      let gridData;
      if (savedReports[0].empty) {
        gridData = $.extend([], savedReports);
      } else {
        switch (status) {
          case "All":
            gridData = $.extend([], savedReports);
            break;
          case "Active":
            gridData = _.filter(savedReports, report => report.active);
            break;
          case "Inactive":
            gridData = _.filter(savedReports, report => !report.active);
            break;
        }
      }
      this.manageGrid.set("data", gridData);
      T1Publish("hide:manageLoader");
    },
    gridClickHandler(event) {
      const clickItem = event.target;
      const $parentItem = $(clickItem).parent();
      const isDownloadIcon = $parentItem.hasClass("download-icon");
      const isActionIcon = $parentItem.parent().hasClass("actions-menu");
      let $actionsMenu, reportID;
      switch (true) {
        case isDownloadIcon:
          T1Publish("grid.exportReport", $parentItem.attr("id").replace("download", ""));
          break;
        case isActionIcon:
          reportID = clickItem.value;
          switch (clickItem.textContent) {
            case "Edit":
              T1Publish("update:view", {
                location: `#reports/campaigns/dataExport/edit/${reportID}`,
                target: "edit"
              });
              break;
            case "Activate":
            case "Deactivate":
              this.DataExportModel.setReportStatus(reportID, clickItem.textContent);
              $actionsMenu = $(this.manageGrid).find(`#actionsMenu${reportID}`);
              $actionsMenu[0].close();
              break;
            case "Export":
              T1Publish("grid.exportReport", reportID);
              break;
          }
          break;
      }
    },
    render() {
      const $el = this.el;
      const deferred = $.Deferred();
      Polymer.dom($el[0]).innerHTML = template;
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return ManageGridView;
});
