define('modules/campaign/chart/views/legendToggle',["T1", "T1View", "text!../templates/legendToggle.html"], function (T1, T1View, template) {
  "use strict";

  const T1_PUBLISH = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "click .btn-toggle": "toggleHandler"
    },
    initialize: function ({
      getChartModel: getChartModel
    }) {
      this.chartModel = getChartModel();
    },
    load: function () {
      this.render().then(() => {
        this.$btnText = this.$(".btn-txt");
        T1_PUBLISH("complete:layoutLoad");
      });
    },
    unload: function () {
      this.$btnText = null;
    },
    getText: function () {
      return this.$btnText.text();
    },
    toggleHandler: function () {
      this.$btnText.text(this.getText() === "Show" ? "Hide" : "Show");
      T1_PUBLISH("toggle:legend", this.getText());
    },
    serialize: function () {
      return {
        state: this.chartModel.getLegend()
      };
    }
  });
});
