define('modules/strategy/views/strategyInlineForecaster',["jQuery", "T1", "T1View", "T1Model", "T1Spinner", "models/strategyTargetingAttachment", "models/strategyForecast", "text!modules/strategy/templates/strategyInlineForecaster.html", "modules/strategy/views/helpers/forecasterWarningHelper"], function ($, T1, T1View, T1Model, Spinner, TargetingAttachment, StrategyForecast, template, forecasterWarningHelper) {
  "use strict";

  const maxAmount = 100;
  const stdEN = {
    code: "en",
    decimals: 2
  };
  const errorCodes = Object.freeze({
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403,
    notFound: 404,
    requestTimeout: 408,
    payloadTooLarge: 413,
    internalServerError: 500,
    notImplemented: 501,
    badGateway: 502,
    serviceUnavailable: 503,
    gatewayTimeout: 504,
    httpVersionNotSupported: 505,
    variantAlsoNegotiates: 506,
    insufficientStorage: 507,
    loopDetected: 508,
    bandwidthLimitExceeded: 509,
    notExtended: 510,
    networkAuthenticationRequired: 511
  });
  const strategyInlineForecasterView = T1View.extend({
    template: template,
    isProcessing: false,
    isErrored: false,
    isDirty: false,
    localeMap: {
      ARS: {
        code: "es-ar",
        decimals: 2
      },
      AUD: stdEN,
      BRL: {
        code: "pt",
        decimals: 2
      },
      CAD: {
        code: "en",
        decimals: 2
      },
      CHF: {
        code: "de-ch",
        decimals: 2
      },
      CLP: {
        code: "es-cl",
        decimals: 0
      },
      COP: {
        code: "es-co",
        decimals: 2
      },
      EUR: stdEN,
      GBP: stdEN,
      INR: {
        code: "en-in",
        decimals: 2
      },
      JPY: {
        code: "ja",
        decimals: 0
      },
      KRW: {
        code: "ko",
        decimals: 0
      },
      MXN: {
        code: "es-mx",
        decimals: 2
      },
      NOK: {
        code: "nb",
        decimals: 2
      },
      NZD: stdEN,
      SGD: {
        code: "zh",
        decimals: 2
      },
      USD: stdEN
    },
    suppressedErrorCodesObj: {
      errorCodes: [errorCodes.badRequest, errorCodes.unauthorized, errorCodes.forbidden, errorCodes.notFound, errorCodes.requestTimeout, errorCodes.payloadTooLarge, errorCodes.internalServerError, errorCodes.notImplemented, errorCodes.badGateway, errorCodes.serviceUnavailable, errorCodes.gatewayTimeout, errorCodes.httpVersionNotSupported, errorCodes.variantAlsoNegotiates, errorCodes.insufficientStorage, errorCodes.loopDetected, errorCodes.bandwidthLimitExceeded, errorCodes.notExtended, errorCodes.networkAuthenticationRequired]
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    events: {
      "click .btn-incrementer": "incrementCPM"
    },
    eventhubEvents: {
      "t1Form:unsaved": "setAsDirty",
      "forecaster:inputClicked": "onForecasterInputClicked",
      "forecaster:warning:critical": "onCriticalWarning",
      "strategy-createEdit:loadForecasterData": "loadForecasterData"
    },
    initialize: function ({
      currencyCode: currencyCode,
      strategyId: strategyId,
      maxBid: maxBid,
      inputPrice: inputPrice,
      mediaType: mediaType,
      startDate: startDate,
      endDate: endDate,
      frequencyAmount: frequencyAmount,
      frequencyInterval: frequencyInterval,
      frequencyType: frequencyType,
      isRunOnAllExchanges: isRunOnAllExchanges
    }) {
      this.currencyCode = currencyCode;
      this.strategyId = strategyId;
      this.maxBid = maxBid;
      this.inputPrice = inputPrice;
      this.mediaType = mediaType;
      this.startDate = startDate;
      this.endDate = endDate;
      this.frequencyAmount = frequencyAmount;
      this.frequencyInterval = frequencyInterval;
      this.frequencyType = frequencyType;
      this.isRunOnAllExchanges = isRunOnAllExchanges;
      if (Object.keys(this.localeMap).indexOf(this.currencyCode) === -1) {
        this.currencyCode = "USD";
      }
      this.model = new T1Model({});
      this.initializeAjaxHandlers();
    },
    initializeAjaxHandlers: function () {
      this.handlers = {
        onError: () => this.setErroredState(),
        onStatusInvalid: () => this.setErroredState(this),
        success: (model, response) => this.forecastsSuccessHandler(response),
        processAjaxResponse: response => this.processForecasts(response)
      };
    },
    load: function () {
      if (this.strategyId) {
        this.isProcessing = true;
        this.render().then(() => {
          if (!this.isDirty) {
            Spinner.apply(this.el.find(".icon-spinner"), this.spinnerOpts);
            this.fetchForecasts();
          }
        });
      } else {
        this.isDirty = true;
        this.render();
      }
    },
    loadForecasterData: function () {
      const $opportunitiesFormatted = this.$(".opportunities-formatted");
      const $uniqueUserFormatted = this.$(".unique-users-formatted");
      const $spendFormatted = this.$(".spend-formatted");
      if ($opportunitiesFormatted.length && $uniqueUserFormatted.length && $spendFormatted.length) {
        const opportunitiesFormattedVal = $opportunitiesFormatted[0].innerHTML;
        const uniqueUserFormattedVal = $uniqueUserFormatted[0].innerHTML;
        const spendFormattedVal = $spendFormatted[0].innerHTML;
        if (opportunitiesFormattedVal !== "--" && uniqueUserFormattedVal !== "--" && spendFormattedVal !== "--") {
          this.render();
          this.isDirty = false;
          this.isProcessing = false;
        }
      } else {
        if (this.forecasts && this.forecasts.length) {
          this.load();
          this.isDirty = false;
          this.isProcessing = false;
        }
      }
    },
    getPrice: function () {
      return this.inputPrice || this.maxBid;
    },
    initStrategyForecastModel: function () {
      return new StrategyForecast({
        expressionString: this.expressionString,
        mediaType: this.mediaType,
        price: this.getPrice(),
        currencyCode: this.currencyCode,
        startDate: this.startDate,
        endDate: this.endDate,
        frequencyAmount: this.frequencyAmount,
        frequencyInterval: this.frequencyInterval,
        frequencyType: this.frequencyType
      });
    },
    getStrategyForecastModelSaveObject: function () {
      const {
        onError: onError,
        onStatusInvalid: onStatusInvalid,
        success: success,
        processAjaxResponse: processAjaxResponse
      } = this.handlers;
      return {
        dataType: "json",
        contentType: "text/plain",
        onError: onError,
        onStatusInvalid: onStatusInvalid,
        success: success,
        processAjaxResponse: processAjaxResponse,
        errorDisplaySuppressingConfig: this.suppressedErrorCodesObj
      };
    },
    onStrategyForecastModelSave: function () {
      this.isProcessing = false;
      if (!this.isDirty && !this.isErrored) {
        this.currentForecastIndex = this.getInitialForecastIndex(this.forecasts, this.getPrice());
        this.render();
      }
    },
    initTargetingAttachment: function () {
      return new TargetingAttachment({
        id: this.strategyId
      });
    },
    getTargetingAttachmentModelFetchObject: function () {
      const {
        onError: onError
      } = this.handlers;
      return {
        dataType: "json",
        contentType: "application/json",
        data: {},
        onError: onError,
        processAjaxResponse: this.processTargetingAttachments,
        errorDisplaySuppressingConfig: this.suppressedErrorCodesObj
      };
    },
    onTargetingAttachmentModelFetch: function () {
      if (!this.isDirty) {
        this.expressionString = this.TargetingAttachmentModel.get("expression_string");
        if (this.expressionString && this.maxBid) {
          this.StrategyForecastModel = this.initStrategyForecastModel();
          this.StrategyForecastModel.save(this.getStrategyForecastModelSaveObject()).then(this.onStrategyForecastModelSave.bind(this));
        } else {
          this.setErroredState();
        }
      }
    },
    fetchForecasts: function () {
      this.TargetingAttachmentModel = this.initTargetingAttachment();
      this.TargetingAttachmentModel.fetch(this.getTargetingAttachmentModelFetchObject()).then(this.onTargetingAttachmentModelFetch.bind(this));
    },
    getInitialForecastIndex: function (forecastArray, initialPrice) {
      const initialIndex = forecastArray.findIndex(function (forecast) {
        return parseFloat(forecast.bidPrice) === parseFloat(initialPrice);
      });
      return initialIndex === -1 ? 0 : initialIndex;
    },
    incrementCPM: function (e) {
      const direction = $(e.target).data("direction");
      if (direction === "up") {
        this.currentForecastIndex++;
      } else if (direction === "down") {
        this.currentForecastIndex--;
      }
      this.render();
    },
    forecastsSuccessHandler: function (response) {
      this.forecasts = response;
      if (this.extraInfo.warnings) {
        forecasterWarningHelper.handleWarnings(this.extraInfo.warnings, this.isRunOnAllExchanges);
      }
    },
    getForecastValue: function (forecastProp, currencyCode) {
      const dataObj = forecastProp.find(obj => obj.currency_code === currencyCode);
      return dataObj ? dataObj.value : "";
    },
    forecastValidationIterator: function (forecast) {
      if (typeof forecast.bid_price !== "undefined") {
        forecast.bidPrice = this.getForecastValue(forecast.bid_price, this.currencyCode);
      }
      if (typeof forecast.total_spend !== "undefined") {
        forecast.totalSpend = this.getForecastValue(forecast.total_spend, this.currencyCode);
      }
      if (typeof forecast.unique_users !== "undefined") {
        forecast.uniqueUsers = forecast.unique_users;
      }
      if (this.isValidForecast(forecast)) {
        return this.formatForecastValues(forecast, this.localeMap[this.currencyCode]);
      }
    },
    processForecasts: function (response) {
      const validForecasts = response.result.map(this.forecastValidationIterator, this).filter(Boolean).sort((a, b) => a.bidPrice - b.bidPrice);
      this.extraInfo = response.extra_info || {};
      return {
        jsonData: validForecasts,
        statusCode: !validForecasts.length ? "error" : "ok"
      };
    },
    processTargetingAttachments: function (response) {
      return {
        jsonData: response,
        statusCode: "ok"
      };
    },
    formatForecastValues: function (forecast, locale) {
      Object.keys(forecast).forEach(prop => {
        const val = forecast[prop];
        if (prop === "bidPrice") {
          forecast[`${prop}Formatted`] = this.getFormattedBidPrice(val, locale);
        } else if (!isNaN(forecast[prop])) {
          forecast[prop] = this.getFormattedNumber(val, locale.code, 0);
          forecast[`${prop}Formatted`] = this.getAbbreviatedNumber(val, locale.code);
        }
      });
      return forecast;
    },
    getFormattedNumber: function (val, localeCode, decimalPlaces) {
      const format = {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      };
      return new Intl.NumberFormat(localeCode, format).format(val);
    },
    getFormattedBidPrice: function (price, locale) {
      const priceDecimals = price >= maxAmount ? 0 : locale.decimals;
      return this.getFormattedNumber(price, locale.code, priceDecimals);
    },
    getAbbreviatedNumber: function (val, localeCode) {
      const suffixes = ["", "K", "M", "B", "T"];
      let suffix = "";
      let abbreviatedNumber = val;
      let decimalPlaces = 0;
      const power = 10;
      const exponent = 3;
      for (let i = suffixes.length - 1; i >= 0; i--) {
        const divisor = Math.pow(power, i * exponent);
        if (val >= divisor) {
          suffix = suffixes[i];
          abbreviatedNumber = val / divisor;
          decimalPlaces = i === 0 ? 0 : this.getDecimalPlaces(abbreviatedNumber);
          break;
        }
      }
      return this.getFormattedNumber(abbreviatedNumber, localeCode, decimalPlaces) + suffix;
    },
    getDecimalPlaces: function (val) {
      let decimals;
      const min_amount = 10;
      const twoDigits = 2;
      if (val >= maxAmount) {
        decimals = 0;
      } else if (val >= min_amount) {
        decimals = 1;
      } else {
        decimals = twoDigits;
      }
      return decimals;
    },
    isValidForecast: function (forecast) {
      return forecast.bidPrice !== undefined && !isNaN(forecast.bidPrice) && forecast.bidPrice >= 0 && forecast.opportunities !== undefined && !isNaN(forecast.opportunities) && forecast.opportunities >= 0 && forecast.uniqueUsers !== undefined && !isNaN(forecast.uniqueUsers) && forecast.uniqueUsers >= 0 && forecast.totalSpend !== undefined && !isNaN(forecast.totalSpend) && forecast.totalSpend >= 0;
    },
    onForecasterInputClicked: function () {
      this.isDirty = true;
      this.hasBeenClicked = true;
      this.render().then(() => {
        this.el.find(".price-value-input")[0].focus();
      });
    },
    setAsDirty: function () {
      this.isDirty = true;
      this.render();
    },
    setContent: function (obj, content) {
      obj.opportunitiesValue = content.opportunities.toString();
      obj.opportunitiesFormatted = content.opportunitiesFormatted.toString();
      obj.uniqueUsersValue = content.uniqueUsers.toString();
      obj.uniqueUsersFormatted = content.uniqueUsersFormatted.toString();
      obj.spendValue = content.totalSpend.toString();
      obj.spendFormatted = content.totalSpendFormatted.toString();
      if (content.bidPriceFormatted) {
        obj.priceValue = content.bidPriceFormatted.toString();
      }
    },
    setStaticContent: function (obj, str) {
      this.setContent(obj, {
        opportunities: str,
        opportunitiesFormatted: str,
        uniqueUsers: str,
        uniqueUsersFormatted: str,
        totalSpend: str,
        totalSpendFormatted: str
      });
    },
    onCriticalWarning: function () {
      this.forecasts = [];
      this.setErroredState();
    },
    setErroredState: function () {
      this.isProcessing = false;
      this.isErrored = !(this.forecasts && this.forecasts.length);
      this.render();
    },
    serialize: function () {
      let forecastContent;
      const returnObj = {
        currencyCode: this.currencyCode,
        currencySymbol: T1.Utils.getCurrencySymbol(this.currencyCode),
        forecastIsReady: false,
        forecastIsProcessing: this.isProcessing,
        forecastIsInitialProcessing: this.inputPrice === null && this.isProcessing,
        forecasts: this.forecasts || []
      };
      returnObj.disableIncrementDown = this.currentForecastIndex === 0;
      returnObj.disableIncrementUp = this.currentForecastIndex === returnObj.forecasts.length - 1;
      if (this.isDirty || this.isErrored || this.isProcessing) {
        const priceValue = this.getPrice();
        if (priceValue) {
          const cleanMaxBid = priceValue.replace(",", "");
          const locale = this.localeMap[this.currencyCode];
          returnObj.priceValue = this.getFormattedBidPrice(cleanMaxBid, locale);
        } else {
          returnObj.priceValue = "";
        }
        if (this.isDirty) {
          this.setStaticContent(returnObj, "--");
          if (this.hasBeenClicked) {
            returnObj.priceValue = "";
            this.hasBeenClicked = false;
          }
        } else if (this.isErrored) {
          this.setStaticContent(returnObj, "xx");
        }
      } else {
        if (this.currentForecastIndex < 0) {
          this.currentForecastIndex = 0;
        }
        forecastContent = returnObj.forecasts[this.currentForecastIndex];
        this.setContent(returnObj, forecastContent);
        returnObj.forecastIsReady = true;
      }
      return returnObj;
    }
  });
  return strategyInlineForecasterView;
});
