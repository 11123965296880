define('modules/strategy/views/helpers/audienceSummaryTarget',["Underscore", "jQuery", "T1", "T1Permissions", "utils/CampaignStrategyUtils"], function (_, $, T1, T1Permissions, Utils) {
  "use strict";

  return {
    hasGroupPermission: T1Permissions.check("feature", "campaigns_group_audience_segment"),
    prepareForSerialize: function (currencyCode, collection, strategy, partialCollection, iASId) {
      let iASElement;
      var collObj = collection;
      var firstEntry = true;
      var resultJSON = {
        data: []
      };
      const apiLimit = 100;
      if (collection.count > apiLimit) {
        resultJSON.numberOfItemsLeft = collection.count - apiLimit;
      }
      this.strategy = strategy;
      function getGroupByObj(models) {
        return _.groupBy(models, function (model) {
          return model.get("rel") !== "retired_audience_segment" ? model.get("full_path").split(" - ")[0] : model.get("full_retired_path").split(" - ")[0];
        });
      }
      function filterSegments(segments, action) {
        return segments.filter(segment => segment.get("action") === action);
      }
      function groupIncludedSegments(segments) {
        const includedGroups = {};
        const unsorted = _.groupBy(segments, model => {
          const identifier = model.get("group_identifier");
          return `Group ${parseInt(identifier.substring(1), 10)}`;
        });
        Object.keys(unsorted).sort().forEach(key => {
          const items = unsorted[key];
          const newKey = `${key} - (${items[0].get("operator")})`;
          includedGroups[newKey] = items;
        });
        return includedGroups;
      }
      const sortedCollObj = getGroupByObj(collObj.models);
      $.each(partialCollection.toJSON(), function (index, element) {
        if (element.id === iASId) {
          iASElement = element.name;
        }
      });
      delete sortedCollObj[iASElement];
      if (this.hasGroupPermission) {
        const includedSegments = [];
        const excludedSegments = [];
        for (const [, segments] of Object.entries(sortedCollObj)) {
          includedSegments.push(...filterSegments(segments, "include"));
          excludedSegments.push(...filterSegments(segments, "exclude"));
        }
        const combinedObject = Object.assign(groupIncludedSegments(includedSegments), getGroupByObj(excludedSegments));
        for (const [key, value] of Object.entries(combinedObject)) {
          resultJSON.data.push(this.getAudienceDataObject(value, key, currencyCode));
        }
      } else {
        for (const [key, value] of Object.entries(sortedCollObj)) {
          resultJSON.data.push(this.getAudienceDataObject(value, key, currencyCode, firstEntry));
          if (firstEntry) {
            firstEntry = false;
          }
        }
      }
      return resultJSON;
    },
    getAudienceDataObject: function (items, key, currencyCode, addOp) {
      var self = this;
      var targetArr = {
        header: key,
        items: []
      };
      var spaceEntity = "&nbsp;";
      var sortIncEx = function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      };
      if (addOp) {
        targetArr.includeOP = `(${this.strategy.get("audience_segment_include_op")})`;
      }
      $.each(items, function (index, model) {
        const tmpObj = model.toJSON();
        const action = tmpObj.action;
        let path;
        if (tmpObj.full_path || tmpObj.full_retired_path) {
          if (tmpObj.rel !== "retired_audience_segment") {
            path = tmpObj.full_path.replace(/ - /g, " &raquo ");
          } else {
            path = tmpObj.full_retired_path.replace(/ - /g, " &raquo ");
          }
        } else {
          path = spaceEntity;
        }
        targetArr.items.push({
          action: action,
          label: self.getPartialPathFromFull(path, tmpObj.name),
          targetingType: "audience",
          segmentType: tmpObj.rel,
          headerWarningUrl: Utils.perfDataWarningKbUrl,
          showRetiredWarningIcon: tmpObj.rel === "retired_audience_segment",
          boldText: tmpObj.name
        });
      });
      targetArr.items.sort(sortIncEx);
      return targetArr;
    },
    getPartialPathFromFull: function (fullPath, name) {
      return fullPath.replace(name, "");
    }
  };
});
