define('models/audienceVerification',['require','T1','T1Model','T1UtilsAsync'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1Model = require("T1Model");
  const utilsAsync = require("T1UtilsAsync");
  T1.Models.AudienceVerification = T1Model.extend({
    apiRoot: COMPASS_ENV.NIELSON_API_BASE,
    url: "campaigns",
    fetch(opts, campId) {
      const model = this;
      const options = opts || {};
      model.loaded = false;
      if (model.get("id") || campId) {
        const id = campId || model.get("id");
        this.url = `campaigns/${id}`;
      }
      const onSuccess = function (data) {
        options.success(data);
      };
      const onError = function (data) {
        options.onError(JSON.parse(data.responseText));
      };
      if (this.ajx) {
        this.ajx.abort();
      }
      const syncOpts = {
        dataType: "json",
        contentType: "application/json",
        stringify: true,
        success: onSuccess,
        error: onError,
        processAjaxResponse: this.processAjaxResponse,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true,
          errorCodes: [404]
        }
      };
      this.ajx = this.sync("read", "", syncOpts);
      if (this.ajx) {
        this.ajx.always(() => {
          this.ajx = null;
        });
      }
      return this.ajx;
    },
    processAjaxResponse(resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    parse(resp) {
      const data = resp.data || resp;
      const providerName = this.get("provider");
      const providerData = data.providers && data.providers[providerName];
      if (providerName === "nielsen" && data.active) {
        return Object.assign({}, {
          providerData: providerData,
          nielsen_enabled: true
        });
      }
      return {};
    },
    createEdit(model, formValues) {
      return utilsAsync.makePromise((resolve, reject) => {
        const {
          nielsen_enabled: nielsen_enabled
        } = formValues;
        let payload;
        if (model.get("id")) {
          this.url = `campaigns/${model.get("id")}`;
          if (nielsen_enabled) {
            const selectedDemographics = Object.keys(formValues).filter(key => key.startsWith("nielsen_age") && formValues[key]).map(demo => parseInt(demo.split("_").pop(), 10));
            payload = {
              active: nielsen_enabled,
              providers: {
                nielsen: {
                  demographics: selectedDemographics,
                  primary_country: formValues.nielsen_primary_country
                }
              }
            };
          } else if (this.attributes.campaignId && nielsen_enabled === undefined && this.get("nielsen_primary_country")) {
            payload = {
              active: false,
              providers: {
                nielsen: {
                  demographics: [],
                  primary_country: ""
                }
              }
            };
          } else {
            resolve();
          }
          this.save(payload, {
            success: resolve,
            error: reject,
            dataType: "json",
            stringify: true,
            contentType: "application/json",
            method: "PUT"
          });
          resolve();
        } else {
          resolve();
        }
      });
    }
  });
  return T1.Models.AudienceVerification;
});
