define('modules/campaign/bulkedit/views/createEdit',["jQuery", "T1", "T1View", "T1TabLayoutV2", "text!modules/campaign/bulkedit/templates/createEdit.html", "models/campaign", "utils/CampaignStrategyUtils", "utils/nextTabViewNavigation", "T1Spinner", "jQueryUI", "T1Accordion", "T1Truncate", "T1Selectbox", "T1DatePicker", "T1Form"], function ($, T1, T1View, T1TabLayout, template, Campaign, Utils, NextTabViewNavigation) {
  "use strict";

  function updateBreadCrumb(campaign) {
    let locations;
    if (campaign.id) {
      locations = [{
        label: campaign.get("name"),
        location: `#campaign/edit/${campaign.id}`
      }, {
        label: "Bulk Edit",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      locations: locations
    });
  }
  const updateBreadCrumbNewT1 = ({
    campaign: campaign,
    organization: organization,
    origin: origin
  }) => {
    let locations;
    if (campaign.id) {
      locations = [{
        label: "Campaigns Home",
        location: `${origin}/campaigns?organization=${organization}`,
        target: "_top"
      }, {
        label: campaign.get("name"),
        location: `#campaign/edit/${campaign.id}`
      }, {
        label: "Bulk Edit",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPathNewT1", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(campaign),
      locations: locations
    });
  };
  const StrategyCreateEditView = T1View.extend({
    template: template,
    mode: "create",
    eventhubEvents: {
      "bulkEdit:hideLoadingIcon": function () {
        this.el.find(".create-edit-main-loader").hide();
      },
      "bulkEdit:showLoadingIcon": function () {
        this.el.find(".create-edit-main-loader").show();
      },
      "bulkEditStrategies:strategiesStagedSuccess": function () {
        this.enableTabs();
      },
      "bulkEdit:Executing": function () {
        var tabLayout = this.layout;
        var tabs = this.tabs;
        tabLayout.execute("disable", tabs.strategies);
        tabLayout.execute("disable", tabs.concepts);
        tabLayout.execute("disable", tabs.targeting);
      },
      "tab:select": function ({
        tabLabel: tabLabel
      }) {
        const tabTitle = this.$(".tab-title");
        if (tabLabel !== "Targeting") {
          tabLabel = this.headerLabels[tabLabel.toLowerCase()];
          tabTitle.text(tabLabel);
        }
      },
      "targetingTab:select": function ({
        type: tabLabel
      }) {
        const tabTitle = this.$(".tab-title");
        tabLabel = this.headerLabels[tabLabel.toLowerCase()];
        tabTitle.text(tabLabel);
      },
      stageActionsSuccess: function () {
        var tabLayout = this.layout;
        var tabs = this.tabs;
        tabLayout.execute("enable", tabs.review);
      },
      selectStrategiesTab: function () {
        var tabLayout = this.layout;
        var tabs = this.tabs;
        tabLayout.execute("select", tabs.strategies);
      }
    },
    tabs: {
      strategies: 0,
      concepts: 1,
      review: 2
    },
    headerLabels: {
      create: "Strategies for Bulk Edit",
      strategies: "Strategies for Bulk Edit",
      creatives: "Bulk Edit Creatives",
      review: "Review Bulk Edit Changes",
      mydata: "Bulk Edit My Data",
      location: "Bulk Edit Location"
    },
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function ({
      deepLinks: deepLinks,
      bulkeditor: bulkeditor
    }) {
      const multiArgs = 2;
      this.dataLoaded = false;
      this.bulkeditor = bulkeditor;
      this.tabs.targeting = 2;
      this.tabs.review = 3;
      NextTabViewNavigation.attachBehavior(this);
      if (deepLinks.length === 1) {
        this.initialTabName = this.headerLabels[deepLinks[0]];
      } else if (deepLinks.length === multiArgs) {
        if (deepLinks[1] === "mydata") {
          this.initialTabName = this.headerLabels.mydata;
        } else if (deepLinks[1] === "location") {
          this.initialTabName = this.headerLabels.location;
        } else {
          this.initialTabName = this.headerLabels.strategies;
        }
      }
      this.campaign = new Campaign({
        id: this.bulkeditor.get("parentId")
      });
      this.campaign.ready = false;
      this.campaign.fetch({
        params: {
          with: ["advertiser", "agency", "strategies"],
          full: "*"
        },
        success: () => {
          this.campaign.ready = true;
          this.dataLoaded = true;
          this.isProgrammaticGuaranteed = Utils.isProgrammaticGuaranteed(this.campaign);
          this.bulkeditorFetched();
        }
      });
    },
    load: function () {
      var loader = this.loader();
      this.render().then(() => {
        if (this.dataLoaded) {
          loader.stop();
        } else {
          loader.start();
        }
        if (this.layout) {
          this.layout.load();
          this.enableTabs();
        }
      });
    },
    bulkeditorFetched: function () {
      const bulkeditor = this.bulkeditor;
      if (bulkeditor && bulkeditor.editorReady && this.campaign.ready) {
        if (bulkeditor.get("newEditor")) {
          T1.Location.set(`campaign/bulkedit/${bulkeditor.get("parentId")}-${bulkeditor.get("editorId")}/strategies`, {
            silent: true,
            replaceHistoryStack: true
          });
        }
        if (bulkeditor.get("parentType") === "campaign") {
          this.campaignId = bulkeditor.get("parentId");
        }
        const {
          isHybrid: isHybrid,
          origin: origin,
          organization: organization
        } = window.hybridWorkflowParams;
        const {
          campaign: campaign
        } = this;
        if (isHybrid) {
          updateBreadCrumbNewT1({
            campaign: campaign,
            organization: organization,
            origin: origin
          });
        } else {
          updateBreadCrumb(campaign);
        }
        this.initLayouts();
        this.dataLoaded = true;
        this.load();
      }
    },
    initLayouts: function () {
      const {
        model = null,
        tabs: tabs,
        campaignId: campaignId,
        bulkeditor: bulkeditor,
        campaign: campaign
      } = this;
      const tabInfo = [{
        label: "Strategies",
        module: "campaign/bulkedit/strategies",
        viewType: "panel",
        routeName: "strategies",
        showLoader: true,
        options: {
          model: model,
          campaignId: campaignId,
          bulkeditor: bulkeditor
        }
      }, {
        label: "Creatives",
        module: "campaign/bulkedit/creatives",
        viewType: "panel",
        routeName: "creatives",
        showLoader: true,
        options: {
          model: model,
          campaignId: campaignId,
          bulkeditor: bulkeditor,
          advertiserId: campaign.get("advertiser_id")
        }
      }];
      const disabled = [tabs.concepts, tabs.targeting, tabs.review];
      tabInfo.push({
        label: "Targeting",
        module: "campaign/bulkedit",
        viewType: "targeting",
        routeName: "targeting",
        showLoader: true,
        options: {
          model: model,
          bulkeditor: bulkeditor,
          campaign: campaign
        }
      });
      tabInfo.push({
        label: "Review",
        module: "campaign/bulkedit/review",
        viewType: "review",
        routeName: "review",
        showLoader: true,
        options: {
          model: model,
          campaignId: campaignId,
          bulkeditor: bulkeditor
        }
      });
      this.layout = new T1TabLayout({
        el: () => this.$(".edit-create-tabs"),
        tabSetName: "BulkEditCreate",
        level: 1,
        disabled: disabled,
        selected: tabs.strategies,
        tabs: tabInfo
      });
    },
    enableTabs: function () {
      const {
        layout: tabLayout,
        tabs: tabs,
        bulkeditor: bulkeditor
      } = this;
      if (bulkeditor.get("editor_status") === "open" || bulkeditor.get("state") === "open") {
        if (bulkeditor.get("stagedStrategies") && bulkeditor.get("stagedStrategies").length) {
          tabLayout.execute("enable", tabs.concepts);
          if (!this.isProgrammaticGuaranteed) {
            tabLayout.execute("enable", tabs.targeting);
          }
          const stagedActions = bulkeditor.get("stagedActions");
          if (stagedActions && (stagedActions[0].length || stagedActions[0].action_id)) {
            tabLayout.execute("enable", tabs.review);
          }
        } else {
          if (tabLayout.selected !== tabs.strategies) {
            tabLayout.execute("select", tabs.strategies);
            tabLayout.execute("disable", tabs.review);
          }
        }
      } else {
        tabLayout.execute("enable", tabs.review);
        if (tabLayout.selected !== tabs.review) {
          tabLayout.execute("select", tabs.review);
        }
        tabLayout.execute("disable", tabs.strategies);
        tabLayout.execute("disable", tabs.concepts);
        tabLayout.execute("disable", tabs.targeting);
      }
    },
    unload: function () {},
    canUnload: function () {
      return this.layout ? this.layout.canUnload() : true;
    },
    cancelUnload: function () {},
    serialize: function () {
      var returnObj = {};
      if (this.campaign.ready) {
        returnObj.headerReady = true;
        returnObj.campaignName = this.campaign.get("name");
        returnObj.advertiserName = this.campaign.get("advertiser").get("name");
        returnObj.agencyName = this.campaign.get("advertiser").get("agency").get("name");
        returnObj.tabName = this.initialTabName;
      } else {
        returnObj.headerReady = false;
      }
      return returnObj;
    }
  });
  return StrategyCreateEditView;
});
