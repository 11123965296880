define('models/strategyTargetGroupedSegments',["jQuery", "T1", "Backbone", "T1Model", "T1Collection", "collections/strategyTargetSegments"], function ($, T1, Backbone, T1Model, T1Collection, StrategyTargetSegments) {
  T1.Models.StrategyTargetGroupedSegments = T1Model.extend({
    url: "strategies",
    fixture: "-strategy_target_groupedSegments",
    relations: [{
      type: Backbone.HasMany,
      key: "user_saved",
      relatedModel: "T1Model",
      collectionType: "StrategyTargetSegments",
      includeInJSON: false
    }, {
      type: Backbone.HasMany,
      key: "partials",
      relatedModel: "T1Model",
      collectionType: "T1Collection",
      includeInJSON: false
    }],
    parse: function (data) {
      var result = {
        user_saved: [],
        partials: []
      };
      var topLevelEntityArr = data.entities || [];
      var fullPath;
      var vendorName;
      if (topLevelEntityArr && !$.isArray(topLevelEntityArr)) {
        topLevelEntityArr = [topLevelEntityArr];
      }
      $.each(topLevelEntityArr, function (index, entityGroup) {
        var resultEntities = T1Collection.prototype.parse({
          entities: entityGroup
        });
        if (entityGroup.set && entityGroup.set == "partial") {
          result.partials = resultEntities;
        } else {
          var tmpResult = [];
          var targeting_segment;
          $.each(resultEntities, function (index, value) {
            if (value.targeting_segment) {
              targeting_segment = value.targeting_segment;
              targeting_segment.action = value.restriction === "INCLUDE" ? "include" : "exclude";
              targeting_segment.operator = value.operator;
              targeting_segment.user_cpm = value.user_cpm;
              if (value.user_cpm && value.user_cpm_amount) {
                $.each(value.user_cpm_amount, function (index, currVal) {
                  if (currVal.currency_code && currVal.currency_code !== "USD") {
                    targeting_segment.user_cpm = currVal.value;
                  }
                });
              }
              targeting_segment.group_identifier = value.group_identifier;
              tmpResult.push(targeting_segment);
            }
          });
          result.user_saved = tmpResult;
        }
      });
      return result;
    },
    fetch: function (opts) {
      var args = opts || {};
      args.params = $.extend(true, {
        full: "*",
        partial: 1,
        with: "targeting_segments",
        currency_code: opts.currency_code
      }, args.params || {});
      args.action = "targeting_segments";
      T1Model.prototype.fetch.call(this, args);
    },
    canEdit: function () {
      var permissionDerferred = $.Deferred();
      permissionDerferred.resolve(true);
      return permissionDerferred.promise();
    }
  });
  return T1.Models.StrategyTargetGroupedSegments;
});
