define('modules/creatives/display/views/t1AsReviewGridValid',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!../templates/t1as_grid_item_valid.html"], function ($, _, T1, T1View, T1Permissions, template, template_mraid_flag) {
  "use strict";

  return T1View.extend({
    template: template,
    events: {
      "click .backup-select": "handleBackupSelect",
      "click .mraid-select": "handleMraidSelected",
      "changed .mraid-type-select": "handleMraidTypeSelect",
      "changed .expandable-select": "handleMraidExpandSelect"
    },
    eventhubEvents: {
      "reviewgrid:valid:update": "updateGridItems"
    },
    initialize: function (args) {
      this.model = args.model;
      this.primaries = this.model.get("primaries");
    },
    buildSelectBox: function () {
      var self = this;
      var el = self.el;
      var $validGrid = el.find(".t1as-valid-grid");
      var primary = self.primaries;
      var selectors = [];
      _.each(primary, function (item, i) {
        var model = self.model;
        var $backupFile = $validGrid.find("strand-grid-item[id=" + item.index + "] .backup-file");
        var options = item.options;
        var select;
        if (options && options.length) {
          select = "<strand-dropdown " + 'placeholder="' + item.backup + '" ' + 'value="' + item.backup + '" ' + 'type="secondary" ' + 'id="' + i + '" ' + 'class="backup-select" ' + 'data-size-key="' + item.size_key + '" ' + 'data-id="' + i + '">';
          select = options.reduce(function (acc, option) {
            return acc += "<strand-list-item " + 'data-size-key="' + option.size_key + '" ' + 'data-file-type="' + item.file_type + '">' + option.name + "</strand-list-item>";
          }, select);
          select += "</strand-dropdown>";
          $backupFile.html(select);
          selectors.push(select);
        } else {
          $backupFile.html("<div>--</div>");
        }
        setTimeout(function () {
          model.setPrimaryBackup(i, el.find("strand-dropdown[id=" + i + "]").val());
        }, 0);
      });
    },
    buildExpandSelectBox: function (target, ID) {
      var fileType;
      var isHtmlOrSwf = false;
      var primary = this.primaries;
      var selectList = "<strand-dropdown " + 'placeholder="Select Expandable File" ' + 'type="expandable" ' + 'id="' + ID + '" ' + 'class="expandable-select" ' + 'data-id="' + ID + '">';
      _.each(primary, function (item) {
        fileType = item.file_type;
        isHtmlOrSwf = fileType.indexOf("html5") > -1 || fileType.indexOf("swf") > -1;
        if (!isHtmlOrSwf) {
          selectList += "<strand-list-item " + 'value="' + item.file_name + '" ' + 'data-size-key="' + item.size_key + '" ' + 'data-file-type="' + item.file_type + '">' + item.file_name + "</strand-list-item>";
        }
      });
      selectList += "</strand-dropdown>";
      target.html(selectList);
    },
    handleMraidSelected: function (e) {
      var el = this.el;
      var selectId = e.currentTarget.id;
      var $selectValue = e.currentTarget.value;
      var $targetItem = $("strand-grid-item[id=" + selectId + "]");
      var $mraidRow = $targetItem.find(".mraid-row");
      var $toggle = $targetItem.find("#carat");
      var $toggleIcon = $toggle.find("strand-icon");
      var primaries = this.model.get("primaries");
      var primaryDataObj = primaries[selectId];
      if ($selectValue) {
        primaryDataObj.isMraid = $selectValue;
        if (!$toggle.hasClass("expanded")) {
          $toggleIcon.trigger("click");
        }
        setTimeout(function () {
          $targetItem.find(".mraid-row").show();
        }, 0);
      } else {
        primaryDataObj.isMraid = undefined;
        primaryDataObj.mraid_type = undefined;
        primaryDataObj.expandable = undefined;
        $mraidRow.hide();
      }
    },
    handleMraidTypeSelect: function (e) {
      var el = this.el;
      var selectValue = e.currentTarget.value.toLowerCase();
      var selectId = e.currentTarget.id;
      var $targetItem = $("strand-grid-item[id=" + selectId + "]");
      var $expandableItem = $targetItem.find(".mraid-expandable");
      var $expandableSelect = $targetItem.find(".mraid-expandable-select");
      var primaries = this.model.get("primaries");
      var primaryDataObj = primaries[selectId];
      if (selectValue === "banner") {
        selectValue = "static";
      }
      primaryDataObj.mraid_type = selectValue;
      if (selectValue === "expandable") {
        $expandableItem.show();
        this.buildExpandSelectBox($expandableSelect, selectId);
      } else {
        $expandableItem.hide();
        primaryDataObj.expandable = undefined;
      }
    },
    handleMraidExpandSelect: function (e) {
      var $select = $(e.currentTarget);
      var selectId = $select[0].id;
      var selectedName = $select.val();
      var primaries = this.model.get("primaries");
      var primaryDataObj = primaries[selectId];
      primaryDataObj.expandable = selectedName;
    },
    handleBackupSelect: function (e) {
      var $select = $(e.currentTarget);
      var selectId = $select[0].id;
      var $targetItem = $("strand-grid-item[id=" + selectId + "]");
      var $backupSize = $targetItem.find(".backup-size");
      var $htmlDimensions = $targetItem.find(".dimensions");
      var $selectedItem = $select.find("strand-list-item[selected]")[0];
      var sizeKey = $selectedItem.dataset.sizeKey;
      var fileType = $selectedItem.dataset.fileType;
      var selectedName = $select.val();
      var selectedKbSize = this.model.getBackupKbSize(sizeKey, selectedName);
      this.model.setPrimaryBackup(selectId, selectedName);
      $backupSize.attr({
        title: selectedKbSize
      });
      $backupSize.html(selectedKbSize);
      if (fileType.indexOf("html5") > -1) {
        $htmlDimensions.attr({
          title: sizeKey
        });
        $htmlDimensions.html(sizeKey);
      }
    },
    updateGridItems: function (e) {
      var type = e.type;
      switch (type) {
        case "click-thru":
          this.updateClickThru();
          break;
        case "concept":
          this.updateConcept();
          break;
        default:
          return;
      }
    },
    updateClickThru: function () {
      var self = this;
      var checkedItems = self.model.get("checked_items");
      var primaries = this.primaries;
      _.each(checkedItems, function (id) {
        var $gridItem = $("strand-grid-item#" + id);
        var $clickThruInput = $gridItem.find("strand-input");
        $clickThruInput.val(primaries[id].click_through_url);
      });
    },
    updateConcept: function () {
      var checkedItems = this.model.get("checked_items");
      var primaries = this.primaries;
      var grid = $(".t1as-valid-grid", this.el)[0];
      _.each(checkedItems, function (id) {
        grid.set("data." + id + ".concept", primaries[id].concept_name);
      });
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      var $validGrid, $staticDataColl, serializedData;
      this.render().then(function () {
        $validGrid = $el.find(".t1as-valid-grid");
        $staticDataColl = $el.find("#staticData");
        $staticDataColl = $staticDataColl && $staticDataColl[0];
        if ($validGrid) {
          setTimeout(function () {
            self.buildSelectBox();
          }, 1500);
          if ($staticDataColl) {
            $staticDataColl.get().then(function (data) {
              self.staticData = data.marshalled;
              serializedData = self.serialize();
              $validGrid[0].data = serializedData.validTags;
            });
          } else {
            serializedData = self.serialize();
            $validGrid[0].data = serializedData.validTags;
          }
        }
      });
    },
    render: function () {
      var self = this;
      var $el = self.el;
      var $validGrid;
      var deferred = $.Deferred();
      T1View.prototype.render.call(this).then(function () {
        $validGrid = $el.find(".t1as-valid");
        Polymer.dom($validGrid[0]).innerHTML = template;
        $validGrid.css("visibility", "visible");
        $validGrid.addClass("fade-in");
        self.buildPermissionedEvents();
        self.bindViewEvents();
      });
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    serialize: function () {
      var self = this;
      var hasData = !_.isEmpty(this.model.attributes);
      var output = {};
      function mapData(data, index) {
        var obj = {};
        var fileType = data.file_type;
        var isHtmlOrSwf = fileType.indexOf("html5") > -1 || fileType.indexOf("swf") > -1;
        $.extend(obj, data);
        obj.batch_index = index + 1;
        obj.name = data.name;
        obj.size = Math.ceil(data.file_size / 1e3) + "kb";
        obj.dimensions = data.size_key;
        obj.type = fileType.toUpperCase();
        obj.upload = "INCLUDE";
        obj.backup_file = data.backup_file;
        obj.backup_size = isHtmlOrSwf ? self.model.getSelectedOptionSize(data.backup, data.size_key) : "--";
        obj.concept = data.concept_name;
        obj.selected = false;
        obj.expanded = false;
        obj.classifications = data.classifications ? data.classifications : [];
        obj.staticData = self.staticData ? self.staticData : [];
        obj.is_mraid_disabled = isHtmlOrSwf;
        return obj;
      }
      if (hasData) {
        var validTagsList = self.model.get("primaries");
        output.validTags = validTagsList && validTagsList.map(mapData);
      }
      return output;
    }
  });
});
