define('modules/audiences/lookalikes/main',["T1Module"], function (T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "audiences/lookalikes",
      viewCfgs: {
        lookalikes: {},
        createEdit: {
          model: null
        }
      },
      defaultView: "lookalikes"
    });
  }
  return instance;
});
