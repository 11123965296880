define('collections/attributesWhiteList',["jQuery", "Underscore", "T1", "Backbone", "T1Sortable"], function ($, _, T1, Backbone) {
  "use strict";

  var AttributesWhiteList = Backbone.Collection.extend({
    url: T1.SEGMENT_API_BASE + "attributes/whitelist",
    fetch: function (options) {
      return Backbone.Collection.prototype.fetch.call(this, options);
    },
    parse: function (resp) {
      return _.sortBy(resp.data, "name");
    }
  });
  return AttributesWhiteList;
});
