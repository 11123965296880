define('modules/audiences/segments/createExport/views/createExport',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "T1Menu", "T1Permissions", "text!modules/audiences/segments/createExport/templates/createExport.html", "models/segmentExport", "models/segmentExportDestination", "collections/segments", "collections/organizations", "modules/audiences/segments/segmentUtils", "modules/audiences/segments/behavior/utils/binUtils"], function ($, _, Backbone, Hogan, T1, T1Menu, T1Permissions, template, Export, Destination, Segments, Organizations, Util, BinUtils) {
  "use strict";

  const CAN_ACCESS_ADAPTIVE = T1Permissions.check("feature", "adaptive-segments");
  var exportView = Backbone.View.extend({
    template: template,
    events: {
      "click .include-icon": "includeClickHandler",
      "click #add-button": "saveExport",
      "changed #source": "toggleGrids",
      "changed #destinations": "setDestination",
      "changed #destinations strand-input": "searchDestinations",
      "click #clear-button": "handleClear",
      "click #selected": "setIcons",
      "click #deselected": "setIcons"
    },
    initialize: function () {
      var compiledTemplate;
      var pathPieces = [{
        label: "Manage Segments",
        location: "#audiences/segments"
      }, {
        label: "Manage Exports",
        location: "#audiences/segments/export/"
      }, {
        label: "New Segment Export",
        location: "#audiences/segments/export/create"
      }];
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
      this.model = new Export();
      this.binCollection = [];
      this.destinationModel = new Destination();
      this.boundReload = this.reload.bind(this);
      T1.EventHub.subscribe("organization:select", this.boundReload);
      compiledTemplate = Hogan.compile(this.template);
      this.renderedTemplate = compiledTemplate.render({
        model: "{{model}}",
        scope: "{{scope}}",
        data: "{{data}}",
        page: "{{page}}",
        sortField: "{{sortField}}",
        sortOrder: "{{sortOrder}}",
        name: "{{model.name}}",
        id: "{{model.id}}",
        modelState: "{{model.state}}",
        isLoading: "{{isLoading}}",
        noResultsMessage: "{{noResultsMessage}}",
        canAccessAdapative: CAN_ACCESS_ADAPTIVE
      });
    },
    load: function () {
      var self = this;
      this.render().then(() => {
        if (T1Permissions.check("feature", "segments_export_toggle")) {
          requestAnimationFrame(() => {
            this.initBins();
            this.initCollections();
            this.initGrids();
            this.loadDestinations();
          });
        } else {
          this.$el.find(".segment-message").show();
        }
      });
    },
    render: function () {
      var deferred = $.Deferred();
      if (T1.Utils.hasPolymer(this.el[0])) {
        Polymer.dom(this.el[0]).innerHTML = this.renderedTemplate;
      } else {
        this.el.html(this.renderedTemplate);
      }
      this.$el = $(this.el);
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    setIcons: function (e, bin) {
      var id, state, newState;
      if (bin) {
        id = e.id;
        this.el.find("#icon-" + id + "> strand-icon").attr("id", "deselected");
      } else if (e) {
        state = e.currentTarget.id;
        newState = state === "selected" ? "deselected" : "selected";
        e.currentTarget.id = newState;
      } else {
        this.el.find("div[field='icon'] > strand-icon").attr("id", "deselected");
      }
    },
    initGrids: function () {
      this.grid = this.el[0].querySelector("#listOfSegments");
      this.bindCollectionEventsToGrid(this.segmentCollection, this.grid);
      this.grid.isLoading = true;
      this.dmcGrid = this.el[0].querySelector("#listOfDmcSegments");
      this.dmcGrid.isLoading = true;
      this.bindCollectionEventsToGrid(this.dmcCollection, this.dmcGrid);
    },
    initCollections: function () {
      this.segmentCollection = this.el[0].querySelector("#segment-collection");
      this.dmcCollection = this.el[0].querySelector("#dmc-collection");
      Organizations.getOrganizations().getSelected().then($.proxy(orgId => {
        this.orgId = orgId;
        this.segmentCollection.orgId = orgId;
        this.dmcCollection.orgId = orgId;
        this.loadSegments();
      }));
    },
    initBins: function () {
      this.binlist = this.el.find("#segments-bin-list")[0];
      this.binlist.addEventListener("item-include-or-exclude", BinUtils.removeFromBinsHandler.bind(this));
      this.binlist.grouped = [];
    },
    bindCollectionEventsToGrid: function (collection, grid) {
      collection.addEventListener("seg-collection:call", () => {
        grid.isLoading = true;
      });
      collection.addEventListener("seg-collection:load", () => {
        grid.isLoading = false;
      });
      collection.addEventListener("seg-collection:noResults", msg => {
        grid.noResultsMessage = msg.detail;
      });
    },
    loadDestinations: function () {
      var self = this;
      var destinations;
      this.destinationModel.fetch({
        success: function (data) {
          data.map(function (item) {
            item.name = item.label;
            return item;
          });
          self.destinations = data;
          destinations = document.querySelector("#destinations");
          destinations.set("data", data);
        }
      });
    },
    loadSegments: function () {
      this.segmentCollection.sourceUrl = T1.SEGMENT_API_BASE + "segments";
      this.segmentCollection.search = "";
      this.segmentCollection.searchOptions = [{
        term: "adaptive",
        type: "exact",
        searchField: "segment_type"
      }];
      this.dmcCollection.sourceUrl = T1.SEGMENT_API_BASE + "generic_segments/segments";
      this.dmcCollection.search = "";
      this.dmcCollection.searchOptions = [{
        term: "standard",
        type: "exact",
        searchField: "segment_type"
      }];
    },
    toggleGrids: function (e) {
      var source = e && e.originalEvent && e.originalEvent.target.value;
      if (source === "dmc") {
        $(this.dmcGrid).show();
        $(this.grid).hide();
        this.dmcGrid.set("data", this.dmcGrid.data.slice());
      } else {
        $(this.dmcGrid).hide();
        $(this.grid).show();
        this.grid.set("data", this.grid.data.slice());
      }
    },
    addOrRemoveRow: function (model, isSelected, grid) {
      model.state = "deselected";
      model.stateColor = self.colorDeselected;
      this.binCollection = this.binCollection.filter(function (obj) {
        return obj.id !== model.id;
      });
      this.binlist.set("data", this.binCollection.slice());
      this.setIcons(model, "bin");
      BinUtils.addBinLabel.call(this, model.segment_type);
    },
    setApplyButtonState: function () {
      this.checkCanSave();
    },
    setDestination: function (e) {
      this.model.set({
        destination: e.target.value
      });
      if (e.target.value !== "") {
        this.checkCanSave();
      }
    },
    searchDestinations: function (event) {
      var value = this.escapeRegExp(event.srcElement.value);
      var regexp = new RegExp(value, "g");
      var destinations = document.querySelector("#destinations");
      var searchResults;
      if (value) {
        searchResults = this.destinations.filter(function (destination) {
          return destination.name.match(regexp);
        });
        destinations.set("data", searchResults);
        destinations.set("state", "opened");
      } else {
        destinations.set("data", this.destinations);
        destinations.set("state", "opened");
      }
    },
    setFooter: function (message, type) {
      var footer = document.querySelector("strand-footer");
      footer.message = message;
      footer.type = type;
      footer.showMessage();
    },
    clearFooter: function () {
      var footer = document.querySelector("strand-footer");
      footer.hideMessage();
    },
    checkCanSave: function () {
      var destination = this.model.get("destination");
      var selectedNumber = this.binCollection.length;
      var saveButton = document.querySelector("#add-button");
      this.model.selectedSegments = this.binCollection;
      this.clearFooter();
      if (destination && selectedNumber) {
        saveButton.disabled = false;
      } else {
        saveButton.disabled = true;
      }
    },
    handleClear: function (e) {
      var self = this;
      e.preventDefault();
      document.querySelector("#destinations").reset();
      self.binCollection.map(function (segment) {
        segment.state = "deselected";
        segment.stateColor = self.colorDeselected;
        segment.hoverColor = self.hoverColor;
      });
      self.binCollection = [];
      self.binlist.set("data", null);
      self.setIcons();
      this.checkCanSave();
    },
    includeClickHandler: function (event) {
      var $includeIcon = $(event.target);
      var $gridRow = $includeIcon.closest("strand-grid-item");
      var rowModel = $gridRow[0].model;
      if (rowModel.state === "deselected" || rowModel.state === undefined) {
        rowModel.entity = rowModel.segment_type;
        rowModel.included = true;
        rowModel.state = "selected";
        this.binCollection.push(rowModel);
        BinUtils.addBinLabel.call(this, rowModel.segment_type);
      } else if (rowModel.state === "selected") {
        rowModel.state = "deselected";
        this.binCollection = this.binCollection.filter(function (obj) {
          return obj.id !== rowModel.id;
        });
      }
      this.binlist.set("data", this.binCollection.slice());
      this.checkCanSave();
    },
    saveExport: function (event) {
      var el = event.target;
      var buttonText = $(el).find("label").text();
      Util.buttonLoader(event);
      $("#save-segment-button").attr("disabled", "");
      this.model.save({
        success: (model, resp) => {
          var errors = [];
          resp.map(response => {
            if (typeof response === "string") {
              response = $.parseJSON(response);
            }
            if ("errors" in response) {
              var error = "ERROR: ";
              if (Array.isArray(response.errors) && response.errors.length > 0) {
                error = error + response.errors.join(";  ");
              }
              errors.push(error);
            }
            return response;
          });
          if (errors.length) {
            console.error(errors);
            this.setFooter("Segment export submission failed. Please contact T1 Support.", "error");
          } else {
            this.handleClear(event);
            this.setFooter("Save Successful", "success");
          }
          Util.buttonReset(event.target, buttonText);
        },
        error: (errors, numberOfSaves) => {
          var errorMsg = `${errors.length < numberOfSaves ? `Some segment export submissions have` : "Segment export submission"} failed. Please contact T1 Support.`;
          this.setFooter(errorMsg, "error");
          Util.buttonReset(event.target, buttonText);
          $("#save-segment-button").attr("enabled", "");
        }
      });
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      this.remove();
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
    },
    escapeRegExp: function (str) {
      if (!str) {
        return "";
      }
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }
  });
  return exportView;
});
