define('modules/user/views/badge',['require','T1View','text!modules/user/templates/badge.html'],function (require) {
  "use strict";

  const T1View = require("T1View");
  const template = require("text!modules/user/templates/badge.html");
  return T1View.extend({
    template: template,
    initialize() {
      this.model.bind("change", this.render, this);
    }
  });
});
