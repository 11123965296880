define('modules/T1App/edit/models/editModel',["jQuery", "Underscore", "Backbone", "T1", "T1Model"], function ($, _, Backbone, T1, T1Model) {
  "use strict";

  T1.Models.Edit = T1Model.extend({
    dataType: "json",
    url: function () {
      var url = T1.API_APPS_ROOT + "v1.0/apps/" + this.attributes.app_id;
      return url;
    },
    setData: function (rawData) {
      if (rawData.meta === "success") {
        this.set({
          appData: rawData.data[0].name,
          reviewStatus: rawData.data[0].review_status,
          reviewComments: rawData.data[0].review_comments
        }, {
          silent: true
        });
      }
    },
    setMetaData: function (rawData) {
      if (rawData.meta === "success") {
        this.set({
          app_id: rawData.data[0].id,
          name: rawData.data[0].name,
          description: rawData.data[0].description,
          learn_more_html: rawData.data[0].learn_more_html,
          support_email: rawData.data[0].support_email,
          learn_more_link: rawData.data[0].learn_more_link,
          organization_id: rawData.data[0].organization_id,
          version: rawData.data[0].version,
          icon: rawData.data[0].icon,
          learn_more_image: rawData.data[0].learn_more_image || "",
          lab: rawData.data[0].lab ? true : false,
          external: rawData.data[0].external ? true : false,
          billing_none: rawData.data[0].billing_type === "none" ? true : false,
          billing_cpm: rawData.data[0].billing_type === "cpm" ? true : false,
          fee: rawData.data[0].fee.toFixed(2)
        }, {
          silent: true
        });
      }
    },
    updateStatus: function (attributes, options) {
      T1Model.prototype.save.call(this, attributes, options);
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    save: function (attributes, options) {
      var statusInvalid = options.statusInvalid || $.noop;
      var onSuccess = options.success || $.noop;
      var error = options.error || $.noop;
      return this.sync("POST", attributes, {
        action: options.action,
        dataType: options.dataType,
        processData: options.processData,
        stringify: options.stringify,
        contentType: options.contentType,
        processAjaxResponse: this.processAjaxResponse,
        success: function (data) {
          onSuccess(data);
        },
        errorDisplaySuppressingConfig: {
          errorSuppressed: true,
          errorCodes: [400, 404]
        },
        error: function (data) {
          error(data);
        },
        statusInvalid: statusInvalid
      });
    },
    processAjaxResponse: function (resp) {
      var jsonData;
      var dataType;
      var statusCode;
      if (resp.meta === "success") {
        statusCode = "ok";
      } else {
        statusCode = "error";
      }
      function isJson(str) {
        try {
          jsonData = JSON.parse(str);
        } catch (e) {
          dataType = "plaintxt";
        }
      }
      return {
        jsonData: isJson(resp) ? jsonData : resp,
        statusCode: statusCode
      };
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    },
    reset: function () {
      this.attributes = {};
    },
    setMainTabSection: function (sectionId) {
      this.set({
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    }
  });
  return T1.Models.Edit;
});
