define('collections/creativesDisplay',["jQuery", "Underscore", "When", "T1", "T1Collection", "T1Sortable", "models/atomicCreative", "collections/organizations"], function ($, _, When, T1, T1Collection, Sortable, AtomicCreative, Orgs) {
  var sortConfiguration = {
    fields: {
      updated_on: {
        actual: "updated_on"
      }
    },
    default: {
      sort_by: "updated_on",
      order_by: "descending"
    }
  };
  var collection = T1Collection.extend({
    model: AtomicCreative,
    urlString: "atomic_creatives",
    fetchOptions: {
      full: "atomic_creative,vendor_pixel,vendor_pixel_domain,vendor_domain,vendor,creative_asset," + "atomic_creative_ssl_validator,atomic_creative_detected_tag_url",
      with: ["advertiser", "concept", "creatives,vendor_pixels,vendor_pixel_domains,vendor_domain,vendor", "creatives,creative_assets", "atomic_creative_ssl_validators", "atomic_creative_detected_tag_urls,vendor_domain,vendor"]
    },
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: false
      }, {
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: true
      }]
    },
    searchConfiguration: {
      isFetchSilent: false,
      fields: {
        media_type: {
          type: "exclude"
        },
        file_type: {
          type: "exact"
        },
        advertiser_id: {
          type: "exact"
        },
        status: {
          type: "exact"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    initialize: function (models, options) {
      T1Collection.prototype.initialize.apply(this, arguments);
      this.searchBuilder = options.searchBuilder;
    },
    updateOrgFilter: function () {
      Orgs.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.urlFilter.set({
          entity: "organization",
          id: orgId
        });
      }, this));
    },
    fetch: function () {
      var self = this;
      Orgs.getOrganizations().getSelected().then(function (orgId) {
        if (!self.orgId || self.orgId !== orgId) {
          self.orgId = orgId;
          self.urlFilter.set({
            entity: "organization",
            id: self.orgId
          });
          self.reset();
        }
      });
      return T1Collection.prototype.fetch.apply(this, arguments);
    },
    parse: function (data) {
      var parsedData = T1Collection.prototype.parse.call(this, data);
      var model = this.model;
      if (_.isArray(parsedData)) {
        parsedData = _.map(parsedData, function (pData) {
          var entity = model.prototype.parse.call(this, pData);
          var advertiser = entity.advertiser;
          if (advertiser && advertiser.concept) {
            advertiser.concept = T1.Utils.objectTOArray(advertiser.concept);
          }
          return entity;
        });
      }
      return parsedData;
    },
    filterBy: function (key, value) {
      var searcher = this.searchBuilder;
      var chznState;
      if (key === "active_state") {
        if (value === "0") {
          searcher.clearGroupFlags(key);
        } else {
          chznState = value === "1" ? "active" : "inactive";
          searcher.setFlag(key, chznState);
        }
      } else if (key === "advertiser") {
        if (value === "all") {
          searcher.clearGroupFlags(key);
        } else {
          searcher.setFlag(key, "id", {
            term: value
          });
        }
      } else if (key === "search") {
        if (value === "") {
          searcher.clearGroupFlags("multiField");
        } else {
          searcher.setFlag("multiField", "keyword", {
            term: value
          });
        }
      }
      this.loaded = false;
      this.trigger("startSearch");
      this.search.set({
        fetchRequired: true,
        params: this.searchBuilder.getAllFilters()
      });
    },
    resetParams: function () {
      var apiParams = this.searchBuilder;
      apiParams.clearGroupFlags("advertiser");
      apiParams.clearGroupFlags("search");
      apiParams.clearGroupFlags("multiField");
      apiParams.clearGroupFlags("file_type");
      this.search.set({
        params: this.searchBuilder.getAllFilters()
      });
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    toggleAllSelected: function (isSelected) {
      this.each(function (model) {
        if (!model.get("componentCreative")) {
          model.set({
            selected: isSelected
          });
        }
      });
    }
  });
  T1.Models.CreativesDisplay = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.CreativesDisplay;
});
