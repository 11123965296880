define('utils/CreativeUtils',["jQuery", "Underscore", "When", "T1", "T1Model", "T1Alert", "T1Comm", "T1UtilsAsync", "collections/vendors", "models/concept", "modules/creatives/views/flagViolationDialog"], function ($, _, When, T1, T1Model, T1Alert, T1Comm, T1UtilsAsync, Vendors, Concept, FlagViolationDialog) {
  "use strict";

  const CreativeUtils = () => {
    var sessionModel = new (T1Model.extend({
      url: "session/clone"
    }))();
    sessionModel.newSession = function () {
      sessionModel.save({
        api_key: COMPASS_ENV.API_KEY
      }, {
        onSuccess: function (data) {
          sessionModel.set({
            sessionID: data.session.sessionid
          });
        }
      });
    };
    sessionModel.newSession();
    function vendorCanRunOnADX(vendor) {
      return vendor.adx_approved === undefined ? false : vendor.adx_approved !== "0";
    }
    function vendorCanRunOnADXAndDeclarationRequired(vendor) {
      return (vendor.adx_approved === undefined ? false : vendor.adx_approved !== "0") || (vendor.adx_declaration_required === undefined ? false : vendor.adx_declaration_required !== "0");
    }
    function vendorCanRunOnADXSSL(vendor) {
      return vendor.adx_ssl_approved === undefined ? false : vendor.adx_ssl_approved !== "0";
    }
    function pixelParser(vendorPixel, setBy, isSecure, autoVendorList, manualVendorList, allVendorList) {
      var parseResult = true;
      var vendor, vendorPixelDomainOrList;
      function parseVendorPixelDomain() {
        if (_.isArray(vendorPixel.vendor_pixel_domain)) {
          if (vendorPixel.vendor_pixel_domain.length) {
            return vendorPixel.vendor_pixel_domain;
          }
          return undefined;
        }
        return vendorPixel.vendor_pixel_domain;
      }
      vendorPixelDomainOrList = vendorPixel && (parseVendorPixelDomain() || vendorPixel.vendor_domain || (vendorPixel[0] ? _.flatten(vendorPixel) : vendorPixel));
      if (_.isArray(vendorPixelDomainOrList)) {
        _.map(vendorPixelDomainOrList, function (vendorPixelDomain) {
          parseResult = parseResult && pixelParser(vendorPixelDomain, setBy || vendorPixelDomain.set_by, isSecure, autoVendorList, manualVendorList, allVendorList);
        });
        return parseResult;
      }
      vendor = vendorPixelDomainOrList && (vendorPixelDomainOrList.vendor_domain && vendorPixelDomainOrList.vendor_domain.vendor || vendorPixelDomainOrList.vendor);
      if (vendor) {
        vendor.set_by = setBy;
      } else {
        if (vendorPixelDomainOrList) {
          vendor = new T1Model({
            set_by: setBy,
            name: vendorPixelDomainOrList.domain,
            domain: vendorPixelDomainOrList.domain
          });
        } else {
          return false;
        }
      }
      if (setBy === "SYSTEM") {
        autoVendorList.push(vendor);
      } else {
        manualVendorList.push(vendor);
      }
      allVendorList.push(vendor);
      return true;
    }
    function parseVendorPixel(vendorPixels, isSecure) {
      var setBy = vendorPixels && vendorPixels.set_by;
      var auto = [];
      var manual = [];
      var allVendors = [];
      var nonSecureADXVendors = [];
      var secureADXVendors = [];
      var warningADXVendors = [];
      var worstCaseScenarioVendors = [];
      var nonSSLApproved = false;
      var sslApproved = false;
      var nonADXApproved = false;
      var errorVendorList = [];
      var errorType = "";
      var showNoPixelsWarning;
      var showSecureInWarning = false;
      if (_.isArray(vendorPixels)) {
        _.map(vendorPixels, function (vendorPixel) {
          pixelParser(vendorPixel, setBy || vendorPixel.set_by, isSecure, auto, manual, allVendors);
        });
      } else {
        showNoPixelsWarning = !pixelParser(vendorPixels, setBy, isSecure, auto, manual, allVendors);
      }
      _.each(allVendors, function (vendor) {
        if (isSecure) {
          if (vendorCanRunOnADXSSL(vendor) === false) {
            secureADXVendors.push(vendor);
          }
          if (vendorCanRunOnADXSSL(vendor) === false && vendorCanRunOnADX(vendor) === true) {
            warningADXVendors.push(vendor);
            nonSSLApproved = true;
          }
          if (vendorCanRunOnADXSSL(vendor) === true && vendorCanRunOnADX(vendor) === true) {
            sslApproved = true;
          }
          if (vendorCanRunOnADXSSL(vendor) === false && vendorCanRunOnADX(vendor) === false) {
            nonADXApproved = true;
            worstCaseScenarioVendors.push(vendor);
          }
        } else {
          if (vendorCanRunOnADXAndDeclarationRequired(vendor) === false) {
            nonSecureADXVendors.push(vendor);
          }
          showSecureInWarning = false;
        }
      });
      if (sslApproved && nonSSLApproved && nonADXApproved) {
        errorType = "adx-error";
        errorVendorList = errorVendorList.concat(worstCaseScenarioVendors.length && worstCaseScenarioVendors || secureADXVendors);
      } else if (nonSSLApproved && nonADXApproved) {
        errorType = "adx-error";
        errorVendorList = errorVendorList.concat(worstCaseScenarioVendors);
      } else if (sslApproved && nonADXApproved) {
        errorType = "adx-error";
        errorVendorList = errorVendorList.concat(worstCaseScenarioVendors);
      } else if (sslApproved || nonSSLApproved) {
        showSecureInWarning = true;
        errorType = "adx-warning";
        errorVendorList = errorVendorList.concat(secureADXVendors);
      } else if (nonSecureADXVendors.length) {
        errorType = "adx-error";
        errorVendorList = errorVendorList.concat(nonSecureADXVendors);
      } else {
        errorType = "adx-error";
        errorVendorList = errorVendorList.concat(secureADXVendors);
      }
      if (showNoPixelsWarning) {
        errorType = "adx-error";
      }
      auto = _.sortBy(auto, "name");
      manual = _.sortBy(manual, "name");
      errorVendorList = _.sortBy(errorVendorList, "name");
      if (errorVendorList && errorVendorList.length === 0) {
        T1.EventHub.publish("vendor_warning:hide");
      }
      return {
        autoVendors: auto,
        manualVendors: manual,
        adxErrorType: errorType,
        adxErrorVendors: errorVendorList,
        isSecure: isSecure,
        showNoPixelsWarning: showNoPixelsWarning,
        showSecureInWarning: showSecureInWarning,
        hasADXError: showNoPixelsWarning || errorVendorList.length > 0,
        autoHasADXError: isInArray(auto, errorVendorList).length > 0,
        manualHasADXError: isInArray(manual, errorVendorList).length > 0
      };
    }
    function parsePixels(jsonData) {
      var vendorPixels;
      var results = {};
      var creativeJSON = jsonData ? jsonData.creative : undefined || this.toJSON().creative;
      var isSecure = jsonData ? jsonData.is_https === "1" : undefined || this.get("is_https") === "1";
      if (creativeJSON !== undefined) {
        vendorPixels = creativeJSON.vendor_pixel;
        results = parseVendorPixel(vendorPixels, isSecure);
      }
      if (results.manualVendors) {
        results.manualVendors = new Vendors(results.manualVendors);
      }
      if (results.autoVendors) {
        results.autoVendors = new Vendors(results.autoVendors);
      }
      if (results.adxErrorVendors) {
        results.adxErrorVendors = new Vendors(results.adxErrorVendors);
      }
      return results;
    }
    function isInArray(arrayA, arrayB) {
      var output = [];
      if (arrayA && arrayA.length) {
        _.each(arrayA, function (aItem) {
          if (arrayB && arrayB.length) {
            _.each(arrayB, function (bItem) {
              if (aItem.id === bItem.id) {
                output.push(aItem);
              }
            });
          }
        });
      }
      return output;
    }
    function mergeArrays(a1, a2) {
      var i = -1;
      if (a1.length !== a2.length) {
        throw Error("Arrays to be merged must be of the same length!");
      }
      while ((i = i + 1) < a1.length) {
        if (a2) {
          for (const l in a2[i]) {
            if (T1.Utils.hasOwnProp(a2[i], l)) {
              if (!(l in a1[i])) {
                a1[i][l] = a2[i][l];
              }
            }
          }
        }
      }
      return a1;
    }
    function parseVendorListForDisplay(vendorList) {
      var nameMap = function (vendorOrDomain) {
        return vendorOrDomain.get("type") === "vendor" ? vendorOrDomain.get("name") : vendorOrDomain.get("domain");
      };
      return vendorList && vendorList.length ? vendorList.map(nameMap).join(", ") : "--";
    }
    function generateAbsoluteURL(relativeURL) {
      var a = document.createElement("a");
      var url;
      a.href = relativeURL;
      url = a.href;
      return url;
    }
    function showPreview(idList, creativeType) {
      const apiBase = generateAbsoluteURL(COMPASS_ENV.API_BASE);
      let idString, previewUrl, qaEnv, vastBase;
      const isProd = window.location.hostname.includes("t1.mediamath.com");
      if (!isProd && apiBase.indexOf("localhost") === -1) {
        qaEnv = apiBase.match(/t1(qa\w+)/);
        qaEnv = qaEnv[1];
      }
      const previewBaseUrl = isProd && !qaEnv ? COMPASS_ENV.SERVER_SIDE_CRTVE_REVIEW_URL : COMPASS_ENV.SERVER_SIDE_CRTVE_REVIEW_URL.replace(/{{ENV}}/, qaEnv);
      creativeType = creativeType || "display";
      switch (creativeType) {
        case "display":
          previewUrl = `${previewBaseUrl}preview`;
          break;
        case "video":
          previewUrl = `${previewBaseUrl}videopreview`;
          vastBase = isProd ? COMPASS_ENV.SERVER_SIDE_CRTVE_VAST_BASE : COMPASS_ENV.SERVER_SIDE_CRTVE_VAST_BASE.replace(/{{ENV}}/, qaEnv);
          break;
        case "component":
          previewUrl = `${previewBaseUrl}componentcreativepreview`;
          break;
      }
      if (idList) {
        if (idList.length > COMPASS_ENV.CRTVE_GRID_MAX_PREVIEWS) {
          displayAlert({
            title: `Cannot select more than ${COMPASS_ENV.CRTVE_GRID_MAX_PREVIEWS} creatives for bulk preview`,
            bodyMsg: `Please select ${COMPASS_ENV.CRTVE_GRID_MAX_PREVIEWS} creatives or less for bulk preview.`
          });
          return;
        }
        idString = `id${creativeType !== "video" ? "s" : ""}=${idList.join(",")}`;
        const url = `${previewUrl}?${idString}&apiBase=${apiBase}&sessionID=${sessionModel.get("sessionID")}${creativeType === "video" ? `&vastBase=${vastBase}` : ""}`;
        window.open(url);
        sessionModel.newSession();
      }
    }
    function generatePreSavePreview(tag, height, width, name, expandable, advertiser_name, agency_name, secure, mraid) {
      var previewURL = COMPASS_ENV.SERVER_SIDE_CRTVE_REVIEW_URL;
      var apiBase = generateAbsoluteURL(COMPASS_ENV.API_BASE);
      var saveObj = {
        tag: tag,
        height: height,
        width: width,
        name: name
      };
      sessionModel.newSession();
      return $.ajax({
        type: "POST",
        data: JSON.stringify(saveObj),
        async: false,
        headers: {
          "Content-Type": "application/json"
        },
        url: `${previewURL}previewtag?apiBase=${apiBase}&sessionID=${sessionModel.get("sessionID")}&adv=${advertiser_name}&agency=${agency_name}&secure=${secure}&expandable=${expandable}&mraid=${mraid}`,
        success: function (response) {
          window.open(previewURL + response.url);
        }
      });
    }
    function generateBulkPreSavePreview(bulkArray, advertiser_name, agency_name, secure, mraid) {
      var previewURL = COMPASS_ENV.SERVER_SIDE_CRTVE_REVIEW_URL;
      var apiBase = generateAbsoluteURL(COMPASS_ENV.API_BASE);
      sessionModel.newSession();
      if (bulkArray.length > COMPASS_ENV.BULK_GRID_MAX_PREVIEWS) {
        displayAlert({
          title: `Cannot select more than ${COMPASS_ENV.BULK_GRID_MAX_PREVIEWS} creatives for bulk preview`,
          bodyMsg: `Please select ${COMPASS_ENV.BULK_GRID_MAX_PREVIEWS} creatives or less for bulk preview.`
        });
        return;
      }
      return $.ajax({
        type: "POST",
        data: JSON.stringify(bulkArray),
        async: false,
        headers: {
          "Content-Type": "application/json"
        },
        url: `${previewURL}previewbulktag?apiBase=${apiBase}&sessionID=${sessionModel.get("sessionID")}&adv=${advertiser_name}&agency=${agency_name}&secure=${secure}&mraid=${mraid}`,
        success: function (response) {
          window.open(previewURL + response.url);
        }
      });
    }
    function displayAlert(alertData) {
      var alertMsg = new T1Alert();
      var defaultData = {
        buttons: [{
          text: "Close",
          class: "save",
          click: function () {
            var $dialog = $(this);
            $dialog.dialog("close");
          }
        }],
        icon: "error-icon"
      };
      $.extend(alertData, defaultData);
      alertMsg.initialize(alertData);
      alertMsg.show();
    }
    function stripHTML(value) {
      if (value !== undefined) {
        value = `<div>${value}</div>`;
        value = $(value);
        value = value.text();
      }
      return value;
    }
    function mapValues(model, formValues) {
      var modelValues = $.extend({}, formValues);
      modelValues = mapConcept(model, modelValues);
      modelValues = mapVendors(model, modelValues);
      delete modelValues.add_classifications;
      delete modelValues.remove_classifications;
      delete modelValues.current_classifications;
      delete modelValues.concept;
      return modelValues;
    }
    function mapConcept(model, modelValues) {
      var concept = modelValues.concept;
      if (concept) {
        modelValues.concept_id = concept.get ? concept.get("id") : concept;
      }
      return modelValues;
    }
    function mapClassifications(model, formValues) {
      var modelValues = $.extend({}, formValues);
      var currentClassifications, modelClassifications;
      var addedClassifications = modelValues.add_classifications;
      var removedClassifications = modelValues.remove_classifications;
      function stringify(obj) {
        return JSON.stringify(obj);
      }
      function objectify(str) {
        return JSON.parse(str);
      }
      if (modelValues.current_classifications) {
        if (_.isArray(modelValues.current_classifications)) {
          if (modelValues.current_classifications.length) {
            currentClassifications = modelValues.current_classifications[0];
          } else {
            currentClassifications = [];
          }
        } else {
          currentClassifications = modelValues.current_classifications.classifications;
        }
        modelClassifications = _.chain(currentClassifications).map(function (classificationInfo) {
          return Number(model.id) === Number(classificationInfo.atomic_creative_id) ? classificationInfo : null;
        }).reject(function (item) {
          return item === null;
        }).flatten().map(function (item) {
          delete item.cId;
          delete item.atomic_creative_id;
          delete item.parentCategory;
          delete item.default_order;
          delete item.children;
          return item;
        }).value();
      }
      if (addedClassifications) {
        addedClassifications = addedClassifications.map(function (item) {
          delete item.cId;
          delete item.parentCategory;
          delete item.default_order;
          delete item.children;
          delete item.data;
          return item;
        });
      }
      modelClassifications = modelClassifications && _.chain(modelClassifications).map(stringify).value();
      addedClassifications = addedClassifications && _.chain(addedClassifications).map(stringify).value();
      removedClassifications = removedClassifications && _.chain(removedClassifications).map(stringify).value();
      if (addedClassifications) {
        modelClassifications = _.union(modelClassifications, addedClassifications);
      }
      if (removedClassifications) {
        modelClassifications = _.difference(modelClassifications, removedClassifications);
      }
      if (addedClassifications || removedClassifications) {
        modelValues = _.chain(modelClassifications).map(objectify).uniq(function (item) {
          return item.entity + item.id;
        }).value();
      }
      return modelValues;
    }
    function mapVendors(model, formValues, entityType) {
      var modelValues = $.extend({}, formValues);
      var addedVendors = modelValues.add_vendors;
      var removedVendors = modelValues.remove_vendors;
      var currentVendors = [];
      var vendorsToRemove = [];
      var modelVendors, vendorIDs;
      if (entityType === "video") {
        vendorIDs = model.get("details").get("vendors");
        if (addedVendors) {
          vendorIDs = vendorIDs.concat(addedVendors.getFormValues(modelValues, entityType)[0]);
        }
        if (removedVendors) {
          currentVendors = JSON.stringify(vendorIDs).replace(/[^\w,]/g, "").split(",");
          vendorsToRemove = JSON.stringify(removedVendors.getFormValues(modelValues, entityType)).replace(/[^\w,]/g, "").split(",");
          vendorIDs = _.difference(currentVendors, vendorsToRemove);
        }
        if (addedVendors || removedVendors) {
          modelValues = vendorIDs;
        }
      } else {
        modelVendors = model.get("manualVendors") || new Vendors();
        if (modelVendors) {
          if (addedVendors) {
            modelVendors.add(addedVendors.models);
            delete modelValues.add_vendors;
          }
          if (removedVendors) {
            modelVendors.remove(removedVendors.models);
            delete modelValues.remove_vendors;
          }
          if (addedVendors || removedVendors) {
            modelValues = modelVendors.getFormValues(modelValues, entityType);
          }
        }
      }
      return modelValues;
    }
    function saveClassificationComplete(deferred) {
      return deferred.resolve();
    }
    function saveClassificationFailed(deferred) {
      return deferred.reject();
    }
    function saveClassifications(modelCollection) {
      var deferred = When.defer();
      var classifications = [];
      var isBatch = modelCollection && modelCollection.length > 1;
      var classificationCollection, classificationItem, firstModel, mappedClassfications;
      modelCollection = modelCollection && modelCollection.models || modelCollection;
      _.each(modelCollection, function (model) {
        mappedClassfications = mapClassifications(model, {
          add_classifications: model.get("current_classifications") || model.get("add_classifications"),
          current_classifications: model.get("current_classifications")
        });
        if (mappedClassfications) {
          if (isBatch) {
            classificationItem = {};
            classificationItem.atomic_creative_id = model.id;
            classificationItem.classification = mappedClassfications;
            classifications.push(classificationItem);
          } else {
            classifications = mappedClassfications;
          }
        }
      });
      if (classifications) {
        firstModel = modelCollection && modelCollection.at ? modelCollection.at(0) : modelCollection[0];
        if (firstModel && firstModel.id || isBatch) {
          classificationCollection = new T1ComponentLib.CreativesClassificationCollection();
          classificationCollection.auto = false;
          classificationCollection.crid = firstModel && firstModel.id;
          classificationCollection.isBatch = isBatch;
          classificationCollection.saveClassifications(classifications).then(saveClassificationComplete.bind(self, deferred), saveClassificationFailed.bind(self, deferred));
        } else {
          deferred.resolve();
        }
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }
    function saveCreativeWithDefaultConcept(model, formValues, statusInvalid, conflictCallback, deferred, advertiserId) {
      var conceptModel = new Concept();
      if (!model.get("concept_id")) {
        conceptModel.save({
          name: model.get("name"),
          status: "1",
          advertiser_id: advertiserId
        }, {
          success: function (concept) {
            formValues.concept = concept;
            saveAtomicCreativeModel(model, formValues, statusInvalid, conflictCallback, deferred);
          },
          statusInvalid: function () {
            deferred.reject();
          }
        });
      } else {
        saveAtomicCreativeModel(model, formValues, statusInvalid, conflictCallback, deferred);
      }
    }
    function saveAtomicCreativeModel(model, formValues, statusInvalid, conflictCallback, deferred) {
      var self = this;
      var modelValues = mapValues(model, formValues);
      if (!$.isEmptyObject(modelValues)) {
        model.save(modelValues, {
          success: function () {
            deferred.resolve();
          },
          statusInvalid: function (...args) {
            statusInvalid.apply(self, args);
            deferred.reject();
          },
          conflict: function (...args) {
            conflictCallback.apply(self, args);
            deferred.reject();
          }
        });
      } else {
        deferred.resolve();
      }
    }
    function setActiveStatus(creative, primaryIndex) {
      creative[primaryIndex].status = "1";
    }
    function setInactiveStatus(creative, primaryIndex) {
      creative[primaryIndex].status = "0";
    }
    function skipCreative(creative, primaryIndex) {
      creative[primaryIndex].valid = "0";
    }
    function unskipCreative(creative, primaryIndex) {
      creative[primaryIndex].valid = "1";
    }
    function validateAttrs(attrs) {
      var self = this;
      var errors = [];
      var optionalFields = ["click_url", "start_date", "end_date", "concept"];
      var requiredField = "This is a required field.";
      var isCustomDimension = attrs.dimensionType === "0";
      const maxInt = 1e4;
      const maxChars = 8e3;
      var validationResult;
      var reqFields = {
        advertiser_id: function () {
          return !attrs.advertiser_id;
        },
        dimension: function () {
          return !isCustomDimension && !attrs.dimension;
        },
        width: function () {
          if (!isCustomDimension) {
            if (!attrs.width) {
              return requiredField;
            }
            if (parseInt(attrs.width, 10) >= maxInt) {
              return `Width exceeds ${maxInt} pixels`;
            }
          }
          return false;
        },
        height: function () {
          if (!isCustomDimension) {
            if (!attrs.height) {
              return requiredField;
            }
            if (parseInt(attrs.height, 10) >= maxInt) {
              return `Height exceeds ${maxInt} pixels`;
            }
          }
          return false;
        },
        custom_width: function () {
          if (isCustomDimension) {
            if (!attrs.custom_width) {
              return requiredField;
            }
            if (parseInt(attrs.custom_width, 10) >= maxInt) {
              return `Width exceeds ${maxInt} pixels`;
            }
          }
          return false;
        },
        custom_height: function () {
          if (isCustomDimension) {
            if (!attrs.custom_height) {
              return requiredField;
            }
            if (parseInt(attrs.custom_height, 10) >= maxInt) {
              return `Height exceeds ${maxInt} pixels`;
            }
          }
          return false;
        },
        tag: function () {
          if (attrs.tag && attrs.tag.length > maxChars) {
            return "Tag value must not exceed 8,000 characters";
          }
          if (!attrs.tag.length) {
            return requiredField;
          }
          return false;
        },
        tpas_ad_tag: function () {
          if (attrs.tpas_ad_tag && attrs.tpas_ad_tag.length > maxChars) {
            return "Tag value must not exceed 8,000 characters";
          }
          return false;
        },
        click_url: function () {
          if (attrs.click_url) {
            return self.validateUrl(attrs.click_url, maxInt);
          }
          return false;
        },
        click_through_url: function () {
          if (attrs.click_through_url) {
            return self.validateUrl(attrs.click_through_url);
          }
          return false;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1 && key.indexOf("vendors.") === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (Reflect.has(attrs, "tag")) {
        validationResult = reqFields.tag();
        if (validationResult !== false) {
          errors.push({
            field: "tag",
            message: validationResult
          });
        }
      }
      if (Reflect.has(attrs, "click_url")) {
        validationResult = reqFields.click_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_url",
            message: validationResult
          });
        }
      }
      if (Reflect.has(attrs, "click_through_url")) {
        validationResult = reqFields.click_through_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_through_url",
            message: validationResult
          });
        }
      }
      if (errors.length) {
        return errors;
      }
    }
    function validateUrl(value, maxChar) {
      var urlValidation = /^(http|https):\/\/[^ "]+$/;
      const defaultMaxChar = 2048;
      maxChar = maxChar || defaultMaxChar;
      if (!urlValidation.test(value)) {
        return "Please provide a valid URL.";
      }
      if (value.length > maxChar) {
        return `URL must not exceed ${maxChar} characters.`;
      }
      return false;
    }
    const createInlineLoader = id => {
      var targetDiv = `#id${id}`;
      var loader;
      loader = T1.Loader.create({
        contentClass: "inline-edit-loader",
        target: targetDiv,
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 4
        }
      });
      return loader;
    };
    const resubmitSelectedForApproval = (displayCreativesCollection, videoCreativesCollection, options) => {
      const deferred = T1UtilsAsync.makeDeferral();
      const displaySelected = displayCreativesCollection && displayCreativesCollection.getSelected();
      const videoSelected = videoCreativesCollection && videoCreativesCollection.getSelected();
      const displayModels = displaySelected && displaySelected.models;
      const videoModels = videoSelected && videoSelected.models;
      let postData = {};
      const loaderList = [];
      let loader;
      const forbiddenError = 403;
      const badRequestError = 400;
      _.each(displayModels || videoModels, item => {
        loader = createInlineLoader(item.id);
        loader.start();
        loaderList.push(loader);
      });
      const stopLoaders = list => {
        if (list && list.length) {
          _.each(list, loaderToStop => {
            loaderToStop.stop();
          });
        }
      };
      let opts = {
        sourceURL: getCreativeEnvUrl(displayCreativesCollection ? COMPASS_ENV.DISPLAY_RESUBMIT_CRTVE_APPRV_URL : COMPASS_ENV.VIDEO_RESUBMIT_CRTVE_APPRV_URL, "/validation/resubmit"),
        dataType: "json",
        forcedContentType: "application/json",
        sendData: true,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true,
          errorCodes: [forbiddenError, badRequestError, 0]
        },
        onSuccess: response => {
          let failureOutputADX = "";
          let failureOutputAPN = "";
          let displaySuccessListADX = [];
          let displaySuccessListAPN = [];
          let successfulADXCount = 0;
          let failedADXCount = 0;
          let totalADXCount = 0;
          let successfulAPNCount = 0;
          let failedAPNCount = 0;
          let totalAPNCount = 0;
          var creative, end_str, step;
          let videoSuccessList = [];
          let videoFailList = [];
          let totalVideoCount = 0;
          let failedVideoCount = 0;
          let successfulVideoCount = 0;
          if (displayCreativesCollection) {
            successfulADXCount = response.ADX.success.length;
            failedADXCount = response.ADX.failed.length;
            totalADXCount = successfulADXCount + failedADXCount;
            successfulAPNCount = response.APN.success.length;
            failedAPNCount = response.APN.failed.length;
            totalAPNCount = successfulAPNCount + failedAPNCount;
            for (step = 0; step < failedADXCount; step++) {
              creative = response.ADX.failed[step];
              if (creative.msg === "Will not resubmit creative, it is approved.") {
                totalADXCount = totalADXCount - 1;
                continue;
              }
              if (step < failedADXCount - 1) {
                end_str = "<br>";
              } else {
                end_str = "";
              }
              failureOutputADX = failureOutputADX.concat("ID: ", creative.id, ", ERR: ", creative.msg, end_str);
            }
            for (step = 0; step < failedAPNCount; step++) {
              creative = response.APN.failed[step];
              if (creative.msg === "Will not resubmit creative, it is approved.") {
                totalAPNCount = totalAPNCount - 1;
                continue;
              }
              if (step < failedAPNCount - 1) {
                end_str = "<br>";
              } else {
                end_str = "";
              }
              failureOutputAPN = failureOutputAPN.concat("ID: ", creative.id, ", ERR: ", creative.msg, end_str);
            }
            displaySuccessListADX = response.ADX.success;
            displaySuccessListAPN = response.APN.success;
            _.each(displaySuccessListADX, modelID => {
              const model = displayCreativesCollection.get(modelID);
              if (model) {
                model.set({
                  net_status: "pending"
                });
              }
            });
            _.each(displaySuccessListAPN, modelID => {
              const model = displayCreativesCollection.get(modelID);
              if (model) {
                model.set({
                  net_status: "pending"
                });
              }
            });
          }
          if (videoCreativesCollection) {
            videoSuccessList = response.video.success;
            videoFailList = response.video.failed;
            successfulVideoCount = videoSuccessList.length;
            failedVideoCount = videoFailList.length;
            totalVideoCount = successfulVideoCount + failedVideoCount;
            _.each(videoSuccessList, modelID => {
              const model = videoCreativesCollection.get(modelID);
              if (model) {
                model.set({
                  net_status: "pending"
                });
              }
            });
          }
          stopLoaders(loaderList);
          const msg_duration = 1e4;
          if (displayCreativesCollection) {
            if (failureOutputADX) {
              if (successfulADXCount === 0) {
                T1.Notify("error", `Creative(s) could not be re-submitted to ADX. Submission Failures:<br>\n                          ${failureOutputADX}`, msg_duration);
              } else {
                T1.Notify("warning", `${successfulADXCount}/${totalADXCount} creative(s)\n                          were re-submitted successfully to ADX. Submission Failures:<br>\n                          ${failureOutputADX}`, msg_duration);
              }
            }
            if (successfulADXCount === totalADXCount && totalADXCount > 0) {
              T1.Notify("message", `${successfulADXCount}/${totalADXCount} creative(s)\n                        were re-submitted successfully to ADX.`, msg_duration);
            }
            if (failureOutputAPN) {
              if (successfulAPNCount === 0) {
                T1.Notify("error", `Creative(s) could not be re-submitted to APN. Submission Failures:<br>\n                          ${failureOutputAPN}`, msg_duration);
              } else {
                T1.Notify("warning", `${successfulAPNCount}/${totalAPNCount} creative(s)\n                          have been re-submitted successfully on APN. Submission Failures:<br>\n                          ${failureOutputAPN}`, msg_duration);
              }
            }
            if (successfulAPNCount === totalAPNCount && totalAPNCount > 0) {
              T1.Notify("message", `${successfulAPNCount}/${totalAPNCount} creative(s)\n                        were re-submitted successfully to APN.`, msg_duration);
            }
          }
          if (videoCreativesCollection) {
            T1.Notify("message", `${successfulVideoCount}/${totalVideoCount} video creative(s)\n                      were re-submitted successfully.`, msg_duration);
          }
          deferred.resolve(response);
        },
        onError: errorResponse => {
          const responseText = errorResponse.responseText;
          const errorJSON = responseText && responseText.length && JSON.parse(responseText);
          const errorList = errorJSON && errorJSON.errors || [];
          if (errorList && errorList.length) {
            errorList.forEach(error => {
              T1.Notify("error", error.message);
            });
          } else {
            T1.Notify("error", `Error resubmitting creative`);
          }
          stopLoaders(loaderList);
        },
        processAjaxResponse: resp => {
          const jsonData = typeof resp === "string" ? JSON.parse(resp) : resp;
          const returnObject = {
            jsonData: jsonData,
            statusCode: jsonData.meta && jsonData.meta.status || "ok"
          };
          return returnObject;
        }
      };
      if (displayCreativesCollection) {
        postData = {
          creatives: displayModels && _.chain(displayModels).map(model => {
            const netStatus = model.get("net_status_model");
            const status = [{
              supply_source: "ADX",
              status: netStatus.adx_open_auction
            }, {
              supply_source: "APN",
              status: netStatus.app_nexus
            }];
            return status.map(item => ({
              atomic_creative_id: model.get("id"),
              supply_source: item.supply_source,
              status: item.status
            }));
          }).flatten().value() || []
        };
      } else if (videoCreativesCollection) {
        postData = {
          type: {
            video: videoModels && videoModels.map(model => model.get("id")) || []
          }
        };
      } else {
        postData = {};
      }
      opts = $.extend(opts, options);
      opts.data = JSON.stringify(postData);
      T1Comm.post(opts);
      return deferred.promise;
    };
    const toggleResubmitMenuOption = (collection, menuOptions) => {
      const selected = collection.getSelected();
      const resubmitMenuItemIndex = _.findLastIndex(menuOptions, {
        itemLabel: "Resubmit for Approval"
      });
      let hasOnlyRejectedOrPending48 = selected.filter(item => {
        const netStatus = item.get("net_status").toLowerCase();
        return netStatus === "rejected" || netStatus === "pending > 48 hours";
      });
      const maxItems = 25;
      hasOnlyRejectedOrPending48 = hasOnlyRejectedOrPending48 && hasOnlyRejectedOrPending48.length > 0 && hasOnlyRejectedOrPending48.length === selected.length;
      if (hasOnlyRejectedOrPending48 && selected.length < maxItems) {
        T1.EventHub.publish("pullDownButton:bulk-action:enable", resubmitMenuItemIndex);
      } else {
        T1.EventHub.publish("pullDownButton:bulk-action:disable", resubmitMenuItemIndex);
      }
    };
    const replaceSymbolsInName = text => text && text.replace("<", "&lt;").replace(">", "&gt;");
    const applyDates = (targ, targModel, modelID, datePickerObj) => {
      datePickerObj.id = `date-picker-${modelID}`;
      datePickerObj.target = targ;
      datePickerObj.start = targModel.get("start_date") || "";
      datePickerObj.end = targModel.get("end_date") || "";
    };
    const openDatePickerHandler = (e, self) => {
      const modelId = $(e.currentTarget).data("id");
      const targetModel = self.collection.get(modelId);
      if (!targetModel.get("isDateSaving")) {
        const datePicker = self.$("strand-datepicker")[0];
        const target = self.$(`#start_date${modelId}`)[0];
        datePicker.closeLabel = "Cancel";
        datePicker.useDuration = false;
        setTimeout(() => {
          applyDates(target, targetModel, modelId, datePicker);
          datePicker.open();
        }, 0);
      }
    };
    const setDateSavingState = (model, state) => {
      model.set({
        isDateSaving: state
      });
    };
    const getDateFormatter = function () {
      function formatter(format, value) {
        return Date.parse(new Date(value)).toString(format);
      }
      return function (format) {
        return function (value) {
          return value && value !== "" ? formatter(format, value) : null;
        };
      };
    }();
    const formatDateForPost = getDateFormatter("yyyy-MM-ddTHH:mm:ss");
    const formatDateForDisplay = getDateFormatter("MM/dd/yyyy");
    const formatDateForShortDisplay = getDateFormatter("MM/dd/yy");
    const getLoaderSettings = value => ({
      contentClass: "inline-edit-loader",
      target: `div[id="id${value}"]`,
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15,
        top: 4
      }
    });
    const setDateInGrid = (self, id, position, value, isShortDisplay) => {
      const dateFormatter = isShortDisplay ? formatDateForShortDisplay : formatDateForDisplay;
      const dateString = dateFormatter(value);
      self.$(`#${position}-date-${id}`).attr("title", dateString).text(dateString);
    };
    const getDateSaveAttributes = (start, end) => ({
      start_date: formatDateForPost(start),
      end_date: formatDateForPost(end)
    });
    const getDateSaveOptions = (self, loader, model) => {
      function handleDatepickerSave() {
        loader.stop();
        setDateSavingState(model, false);
      }
      return {
        success: handleDatepickerSave,
        error: handleDatepickerSave
      };
    };
    const createDatePicker = function () {
      const DOMString = `<strand-datepicker\n        date-format="MM/DD/YYYY"\n        use-timezone="false"\n        use-range="false"\n        use-time="true"\n        use-commit="true"\n        dual="true"\n      ></strand-datepicker>`;
      const datePicker = document.createRange().createContextualFragment(DOMString).firstChild;
      return () => datePicker.cloneNode();
    }();
    const showFlagViolationDialog = (creativeJSON, creativeType) => {
      const flagViolationDialog = new FlagViolationDialog();
      flagViolationDialog.show(creativeJSON, creativeType);
    };
    const toggleViolationMenuOption = (collection, menuOptions) => {
      const selected = collection.getSelected();
      const violationMenuItemIndex = _.findLastIndex(menuOptions, {
        itemLabel: "Flag for Violation"
      });
      const maxItems = 2;
      if (selected.length < maxItems) {
        T1.EventHub.publish("pullDownButton:bulk-action:enable", violationMenuItemIndex);
      } else {
        T1.EventHub.publish("pullDownButton:bulk-action:disable", violationMenuItemIndex);
      }
    };
    function getCreativeEnvUrl(baseUrl, suffix) {
      const API = generateAbsoluteURL(COMPASS_ENV.API_BASE);
      let env, url;
      if (API.indexOf("localhost") === -1) {
        env = API.match(/t1(qa\w+)/);
        url = baseUrl + (env && env[1] || "prod") + suffix;
      } else {
        url = `${baseUrl}qa11${suffix}`;
      }
      return url;
    }
    return {
      generateBulkPreSavePreview: generateBulkPreSavePreview,
      generatePreSavePreview: generatePreSavePreview,
      showPreview: showPreview,
      parsePixels: parsePixels,
      parseVendorListForDisplay: parseVendorListForDisplay,
      stripHTML: stripHTML,
      mergeArrays: mergeArrays,
      mapValues: mapValues,
      mapConcept: mapConcept,
      mapVendors: mapVendors,
      mapClassifications: mapClassifications,
      saveClassifications: saveClassifications,
      saveAtomicCreativeModel: saveAtomicCreativeModel,
      saveCreativeWithDefaultConcept: saveCreativeWithDefaultConcept,
      setActiveStatus: setActiveStatus,
      setInactiveStatus: setInactiveStatus,
      skipCreative: skipCreative,
      unskipCreative: unskipCreative,
      validateAttrs: validateAttrs,
      validateUrl: validateUrl,
      resubmitSelectedForApproval: resubmitSelectedForApproval,
      toggleResubmitMenuOption: toggleResubmitMenuOption,
      createInlineLoader: createInlineLoader,
      replaceSymbolsInName: replaceSymbolsInName,
      showFlagViolationDialog: showFlagViolationDialog,
      toggleViolationMenuOption: toggleViolationMenuOption,
      openDatePickerHandler: openDatePickerHandler,
      setDateSavingState: setDateSavingState,
      getDateSaveOptions: getDateSaveOptions,
      getDateSaveAttributes: getDateSaveAttributes,
      setDateInGrid: setDateInGrid,
      getLoaderSettings: getLoaderSettings,
      createDatePicker: createDatePicker,
      getCreativeEnvUrl: getCreativeEnvUrl
    };
  };
  return CreativeUtils();
});
