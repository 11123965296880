define('modules/reporting/campaigns/geoPerformance/models/model',["jQuery", "T1", "models/reportingAbstractModel"], function ($, T1, RPTGAbstractModel) {
  "use strict";

  var GeoPerformanceModel = RPTGAbstractModel.extend({
    initialize: function (options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    setGeoCampaignId: function (campaignId) {
      this.set({
        geoCampaignId: campaignId
      });
    },
    setGeoNav: function (level, country, region) {
      if (this.getGeoNav().level !== level) {
        this.resetHiddenItems();
      }
      this.set({
        geoNav: {
          country: country || "",
          level: level,
          region: region || ""
        }
      });
    },
    geoNavigate: function (navDirection, geoName, geoCountry) {
      var geoNav = this.getGeoNav();
      if (navDirection === "into") {
        switch (geoNav.level) {
          case "all":
            this.setGeoNav("country", geoName, "");
            break;
          case "country":
            this.setGeoNav("region", geoCountry || "", geoName);
            break;
        }
      }
    },
    setMoreFilters: function (moreFilters) {
      this.set({
        moreFilters: moreFilters
      });
    },
    setGeoFilters: function (geoFilters) {
      this.set({
        geoFilters: geoFilters
      });
    }
  });
  return GeoPerformanceModel;
});
