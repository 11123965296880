define('modules/campaign/views/item',['require','jQuery','moment','Mustache','T1','T1Alert','T1Form','T1Layout','T1Menu','T1Permissions','T1PerformanceTimer','T1View','text!modules/campaign/templates/impressionMode.html','collections/organizations','text!modules/campaign/templates/performanceMode.html','text!modules/campaign/templates/spendMode.html','text!modules/campaign/templates/item.html','utils/CampaignStrategyUtils','modules/organization/utils/Utils','jQPlugins/jquery.smartscroll','T1Accordion','T1DatePicker','T1Tooltip'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const moment = require("moment");
  const Mustache = require("Mustache");
  const T1 = require("T1");
  const T1Alert = require("T1Alert");
  const T1Form = require("T1Form");
  const T1Layout = require("T1Layout");
  const t1Menu = require("T1Menu");
  const T1Permissions = require("T1Permissions");
  const T1Timer = require("T1PerformanceTimer");
  const T1View = require("T1View");
  const impressionMode = require("text!modules/campaign/templates/impressionMode.html");
  const Organizations = require("collections/organizations");
  const performanceMode = require("text!modules/campaign/templates/performanceMode.html");
  const spendMode = require("text!modules/campaign/templates/spendMode.html");
  const template = require("text!modules/campaign/templates/item.html");
  const Util = require("utils/CampaignStrategyUtils");
  const OrgUtils = require("modules/organization/utils/Utils");
  const SmartScroll = require("jQPlugins/jquery.smartscroll");
  const T1Accordion = require("T1Accordion");
  const T1DatePicker = require("T1DatePicker");
  const T1Tooltip = require("T1Tooltip");
  const hundredPercent = 100;
  const reportIntervals = Object.freeze(["1", "7", "14", "30", "FTD", "CTD"]);
  var defaultTextHandler = function () {
    return function (text) {
      return text === undefined || text === "" || text === "%" ? "&nbsp;--" : text;
    };
  };
  var goalTypeText = {
    spend: "SPEND",
    reach: "REACH",
    cpc: "CPC",
    cpe: "CPE",
    cpa: "CPA",
    roi: "ROI",
    vcr: "VCR",
    ctr: "CTR",
    viewability_rate: "V.RATE",
    vcpm: "vCPM"
  };
  var goalTypes = [{
    value: "spend",
    text: goalTypeText.spend
  }, {
    value: "reach",
    text: goalTypeText.reach
  }, {
    value: "cpc",
    text: goalTypeText.cpc
  }, {
    value: "cpe",
    text: goalTypeText.cpe
  }, {
    value: "cpa",
    text: goalTypeText.cpa
  }, {
    value: "roi",
    text: goalTypeText.roi
  }, {
    value: "vcr",
    text: goalTypeText.vcr
  }, {
    value: "ctr",
    text: goalTypeText.ctr
  }, {
    value: "viewability_rate",
    text: goalTypeText.viewability_rate
  }, {
    value: "vcpm",
    text: goalTypeText.vcpm
  }];
  var dropdownList = {
    goal_type: {
      options: goalTypes,
      bodyClassName: "goal-type-dropdown-body",
      headClassName: "goal-type-dropdown-head"
    }
  };
  const totalSpend = "total_spend";
  var statusActiveTitle = "Active";
  var statusInactiveTitle = "Inactive";
  var favoritedTitle = "Favorited";
  var notFavoritedTitle = "Not Favorited";
  var addToChartTitle = "Add to Chart";
  const readOnlyTitle = `You don't have permission to edit this campaign`;
  var Date = T1.Date;
  var spend_YDY = "";
  var formatsRolledUpNumbersNoAbbreviation = Util.formatsRolledUpNumbersNoAbbreviation;
  var divideByZero = Util.divideByZero;
  var calculateDailySpendToPace = Util.calculateDailySpendToPace;
  var populatePerformanceAlert = Util.populatePerformanceAlert;
  var hasEntityStarted = Util.hasEntityStarted;
  const getEntityDate = Util.getEntityDate;
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  var useAllocationPacing = T1Permissions.check("feature", "use_alloc_pacing");
  var isTotalAllocation = false;
  let CampaignItemView = T1View.extend({
    partials: {
      spend: spendMode,
      impression: impressionMode,
      performance: performanceMode
    },
    template: template,
    initialize({
      viewMode: viewMode,
      sortConfiguration: sortConfiguration
    }) {
      const model = this.model;
      var collection = model.collection;
      const {
        flag: canEditWithV2
      } = Util.allowV2CampaignOrStrategyEdit({
        campaign: model,
        view: self,
        showDialog: false
      });
      const canAccessReporting = !T1Permissions.check("feature", "reporting_hide");
      const canViewBrainLog = T1Permissions.check("feature", "use_log_brain");
      const canViewCampaign = T1Permissions.check("campaign.readonly", model);
      const canViewChangeLog = T1Permissions.check("changeLog.view");
      const canViewEditMargin = T1Permissions.check("campaign.editMargin", model);
      const createEditPermitted = canEditWithV2 && T1Permissions.check("campaign.edit", model);
      Object.assign(this, {
        asDecimalCurrency: T1.Utils.getDecimalCurrencyTemplateFunc(model.get("currency_code")),
        campaignCollection: collection,
        canAccessReporting: canAccessReporting,
        canEdit: canEditWithV2 && T1Permissions.check("campaign.edit", model),
        canViewBrainLog: canViewBrainLog,
        canViewCampaign: canViewCampaign,
        canViewChangeLog: canViewChangeLog,
        canViewEditMargin: canViewEditMargin,
        chartable: collection.chartableCampaignId === model.id,
        createEditPermitted: createEditPermitted,
        organizationInstance: Organizations.getOrganizations(),
        showPGAlert: true,
        strategiesFetched: false,
        viewMode: viewMode,
        canEditWithV2: canEditWithV2
      });
      this.showSettingsMenu = createEditPermitted || canViewCampaign || canViewChangeLog || canViewEditMargin || canAccessReporting;
      this.checkAllocationPacingEnabled(this.viewMode);
      if (sortConfiguration) {
        model.get("strategies").fetchOptions.sort_by = sortConfiguration.replace("total_budget", "budget");
      }
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".strategy-list",
        template: '<div class="strategies-box"></div>',
        wrapperEl: '<div class="list strategies"></div>',
        layout: {}
      });
    },
    menuConfig: {
      menuTriggerEleSelector: ".campaign-settings",
      menuColumns: [{
        columnTitle: null,
        menuItems: [{
          label: "Edit",
          visible: false
        }, {
          label: "Add Strategy",
          visible: false
        }, {
          label: "Bulk Edit",
          visible: false
        }, {
          label: "View Summary",
          visible: false
        }, {
          label: "View Report",
          visible: false
        }, {
          label: "Brain Insights",
          handler: "viewBrainLog",
          visible: false
        }, {
          label: "Change Log",
          handler: "viewChangeLog",
          visible: false
        }, {
          label: "Edit Margin",
          visible: false
        }, {
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: false
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }, {
          label: "Duplicate",
          handler: "duplicateHandler",
          visible: false
        }, {
          label: "Export Tracker Tags",
          visible: false
        }]
      }]
    },
    dataEvents: {
      model: {
        reset: "reload",
        "strategies:filter": "openStrategyList",
        "strategies:fetched": "strategyListFetched",
        remove: "unload",
        "change:status": "updateView",
        "change:performanceDataArrived": "performanceDataCompletedHandler",
        "change:goal_value": function () {
          T1.EventHub.publish("update:strategyItem");
        }
      }
    },
    eventhubEvents: {
      "chart.add": function ({
        model: model
      }) {
        var el = $(".chart-icon", this.el);
        if (model) {
          this.chartable = this.model.id === model.id;
          if (this.chartable) {
            el.removeClass("disabled");
          } else {
            el.addClass("disabled");
          }
        }
      },
      onCloseInlineEdit: function (event) {
        if (event && event.view && event.view.cid === this.cid) {
          this.updateView();
        }
      },
      "change:reportInterval": "changeReportInterval",
      onConfigClick: "applyGearHover",
      onConfigDismiss: "removeGearHover",
      list_viewMode: "updateViewMode"
    },
    strategyListFetched() {
      this.strategyListLoaderStartStop(false);
      this.toggleStrategiesList();
    },
    updateView() {
      const {
        name: name,
        status_class: status_class,
        statusTitle: statusTitle
      } = this.serialize();
      var startInterval = this.campaignCollection.report_interval;
      this.renderViewMode({
        mode: this.viewMode,
        interval: startInterval,
        force: true
      });
      this.renderPartial(".campaign-headers .header.name .header-c");
      this.updateDataBind({
        name: name,
        status_class: status_class,
        statusTitle: statusTitle
      }, this.el);
      this.campaignCollection.report_interval = startInterval;
      this.prepareDataPoints();
      this.prepareT1Menu();
    },
    updateViewMode(evt) {
      this.viewMode = evt.mode;
      this.checkAllocationPacingEnabled(this.viewMode);
      this.renderViewMode(evt);
    },
    checkAllocationPacingEnabled(viewMode) {
      var $campaignNameHeader = this.el.find(".campaign-headers .header.name");
      if (useAllocationPacing) {
        if (viewMode === "spendMode" || viewMode === "impressionMode") {
          isTotalAllocation = true;
          $campaignNameHeader.addClass("alloc-campaign-name");
        } else {
          isTotalAllocation = false;
          $campaignNameHeader.removeClass("alloc-campaign-name");
        }
      }
    },
    checkInlineImpValue({
      currentTarget: currentTarget
    }) {
      const $target = $(currentTarget);
      var budgetInfo = this.model.get("budget_flight");
      if (!$target.val()) {
        this.model.unset("total_impression_budget", {
          silent: true
        });
        budgetInfo.each(function (budgetModel) {
          if (budgetModel.get("is_relevant") === "1") {
            budgetModel.unset("total_impression_budget");
          }
        });
      }
    },
    events: {
      "keyup .views .imp-budget .w-InlineEditForm .form-input": "checkInlineImpValue",
      "click .campaign-headers": "fetchStrategies",
      "mousemove .campaign-headers .favorite-icon": "setClickableFavorite",
      "click .campaign-headers .favorite-clickable": "toggleFavorite",
      "click .checkbox-input .checkbox": "toggleCheckbox",
      "click .chart-icon span": "toggleForChart",
      "click .campaign-edit": "editCampaign",
      "contextmenu .campaign-edit": "applyEditHover",
      "mousemove .campaign": "removeEditHover",
      "contextmenu .campaign-actions-holder": "stopEvent",
      "click #cancel-deactivate-campaign": "closeDeactivateCampaignModal",
      "click #confirm-deactivate-campaign": "deactivatePgCampaignConfirm",
      "click .budget .amount .currency": "onPGBudgetClick",
      "focus .imp-budget .form-input": "onPGBudgetClick",
      "click [data-edit-mode], .date-range": "onInlineEditClick"
    },
    permissionedEvents: {
      "campaign.edit": {
        "mouseover .date-range .header-c": "addHoverState",
        "mouseout .date-range .header-c": "removeHoverState"
      }
    },
    stopEvent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    closeDeactivateCampaignModal() {
      this.$(".deactivate-campaign-modal")[0].hide();
    },
    deactivatePgCampaignConfirm() {
      const entityObj = {
        loader: this.loader(),
        status: this.toActivate === true ? "1" : "0",
        model: this.model,
        modalEle: this.$(".deactivate-campaign-modal")[0]
      };
      Util.onConfirmDeactivateEntity(entityObj);
    },
    onInlineEditClick(event) {
      if (!this.canEditWithV2) {
        Util.allowV2CampaignOrStrategyEdit({
          campaign: this.model,
          view: this,
          showDialog: true,
          cancelable: true
        });
      } else {
        event.data = {
          view: this
        };
      }
      this.stopEvent(event);
    },
    onPGBudgetClick() {
      if (this.isProgrammaticGuaranteed && (this.viewMode === "spendMode" || this.viewMode === "impressionMode") && this.showPGAlert) {
        this.showPGAlert = false;
        const alertMsg = new T1Alert();
        alertMsg.initialize({
          title: "Caution",
          bodyMsg: `Monetary & Impression budget caps are for\n                      directional purposes only and do not cut off spend\n                      in T1.\n                      Please ensure that the publisher sets budget and\n                      impression caps in their ad server.`,
          buttons: [{
            text: "Confirm",
            class: "save",
            click: function () {
              const $dialog = $(this);
              $dialog.dialog("close");
            }
          }]
        });
        alertMsg.show();
      }
    },
    changeReportInterval() {
      this.reportInterval = this.campaignCollection.report_interval;
    },
    attachDatePicker(viewMode, reportInterval) {
      const {
        model: model,
        updateView: updateView
      } = this;
      var startDate = model.get("start_date");
      var hasStarted = Util.hasEntityStarted(model);
      const currentInterval = $(`.views .${viewMode}${reportInterval}`, this.el);
      if (this.canEdit) {
        T1.DoubleDatePicker({
          topOffset: 80,
          leftOffset: 20,
          chooseTime: true,
          chooseTimezone: true,
          zoneName: () => model.get("zone_name"),
          timezoneEditable: !hasStarted,
          startTimeEditable: () => !hasStarted,
          lowerBounds: hasStarted ? Util.parseDate(startDate) : new Date(),
          startDisabled: () => hasStarted,
          getStartDate: () => model.get("start_date"),
          getEndDate: () => model.get("end_date"),
          onSave: ({
            data: data,
            success: success,
            error: error
          }) => {
            const {
              start: start,
              end: end,
              zoneName: zoneName
            } = data.saveValues;
            var saveObj = {
              start_date: `${start.date} ${zoneName}`,
              end_date: `${end.date} ${zoneName}`,
              zone_name: zoneName,
              version: this.model.get("version")
            };
            const loader = this.loader();
            loader.start();
            this.model.sync("save", saveObj, {
              success: opts => {
                const endDateObj = getEntityDate(opts.entity.prop, "end_date");
                const startDateObj = getEntityDate(opts.entity.prop, "start_date");
                this.model.set({
                  end_date_actual: endDateObj.value,
                  end_date: endDateObj.local_time.value,
                  start_date_actual: startDateObj.value,
                  start_date: startDateObj.local_time.value,
                  version: opts.entity.version,
                  zone_name: zoneName
                });
                loader.stop();
                success();
                updateView.call(this);
              },
              conflict: () => {
                loader.stop();
                updateView.call(this);
              },
              statusInvalid: function (statusData) {
                let customError;
                const errors = statusData.errors;
                let fieldError = errors ? errors.field_error ? errors.field_error : errors : statusData;
                if (Array.isArray(fieldError)) {
                  customError = {
                    field: fieldError[0].name,
                    message: fieldError[0].error
                  };
                } else {
                  if (fieldError.name) {
                    customError = {
                      field: fieldError.name,
                      message: fieldError.error
                    };
                  } else {
                    if (fieldError.toLowerCase().includes("overlapping flights")) {
                      fieldError = `The new 'end_date' field value must not\n                          overlap with the dates of a future flight.`;
                    }
                    customError = {
                      message: fieldError
                    };
                  }
                }
                loader.stop();
                error(customError);
              }
            });
            return true;
          }
        }, currentInterval);
      }
      currentInterval.find("[data-datepicker]").first().trigger("mousedown.attachpicker");
    },
    viewChangeLog(evt) {
      $(document).trigger("click");
      this.stopEvent(evt);
      const layout = new T1Layout({
        template: '<div class=".changeDialog"></div>',
        layout: {
          ".changeDialog": [{
            module: "changelog",
            viewType: "dialog",
            options: {
              model: this.model,
              entityType: "campaign"
            }
          }]
        }
      });
      layout.load();
      return layout;
    },
    viewBrainLog(evt) {
      $(document).trigger("click");
      evt.preventDefault();
      const T1Location = T1.Location;
      const abortedUrl = T1Location.getAbortedURL();
      const windowOpener = window.open();
      windowOpener.opener = null;
      if (abortedUrl === "" || abortedUrl === false) {
        windowOpener.location = `#appstore/launch/brain_insights/campaign_id=${this.model.get("id")}`;
      } else {
        windowOpener.location = abortedUrl;
      }
    },
    duplicateHandler(evt) {
      const {
        model: campaign,
        el: $el
      } = this;
      var $loader = $el.parents().find(".loader");
      const half = 2;
      const offset = 18;
      $(document).trigger("click");
      this.stopEvent(evt);
      if ($loader.length) {
        $loader = $loader.css("left", ($el.width() - $loader.width()) / half);
        $loader = $loader.css("top", $el.position().top + offset);
        $loader.show();
      } else {
        if (Polymer && Polymer.dom) {
          const node = document.createRange().createContextualFragment('<strand-loader class="loader"> <label>Loading...</label> </strand-loader>');
          Polymer.dom($el.parents()[0]).appendChild(node);
        }
        $loader = $el.parents().find(".loader");
        $loader.css({
          left: ($el.width() - $loader.width()) / half,
          top: $el.position().top + offset
        });
      }
      this.duplicationLayout = new T1Layout({
        el: () => $(".duplicate-container"),
        template: '<div class="duplicate-holder"></div>',
        layout: {
          ".duplicate-holder": [{
            module: "campaign",
            viewType: "duplicateCampaign",
            options: {
              model: campaign,
              organizationInstance: this.organizationInstance,
              entityType: "campaign"
            }
          }]
        }
      });
      this.duplicationLayout.load();
    },
    getTimeStamp(dateObj = new Date()) {
      return `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}:${dateObj.getMilliseconds()}`;
    },
    performanceDataCompletedHandler() {
      if (this.model.get("perfDataReady") !== true) {
        return;
      }
      const startInterval = this.campaignCollection.report_interval;
      const renderQueue = reportIntervals.filter(interval => interval !== startInterval);
      const mode = this.viewMode.replace("Mode", "");
      this.model.set({
        perfDataReady: false
      });
      this.reportInterval = startInterval;
      this.delayedRender(renderQueue);
      requestAnimationFrame(function () {
        this.attachDatePicker(mode, startInterval);
        T1.EventHub.publish("campaigns:itemLoaded");
      }.bind(this));
    },
    load() {
      this.render().then(() => {
        this.performanceDataCompletedHandler();
        this.$(".campaign").smartscroll({
          scrollHeader: ".campaign-headers",
          scrollList: ".list",
          headerOffset: 16,
          bottomZindex: 2,
          snapbuffer: 10
        });
        this.prepareT1Menu();
        T1.Tooltip(this.$(".advertiser"), {
          offset: 3,
          gravity: "sw",
          alignToOriginalEle: "left"
        });
        T1.Tooltip(this.$(".budget-flight-status-holder"), {
          offset: 3,
          gravity: "se"
        });
      });
    },
    prepareT1Menu() {
      var menuIndexRef = {
        CampaignEdit: 0,
        CreateStrategy: 1,
        BulkEdit: 2,
        CampaignReadonly: 3,
        Report: 4,
        Brain: 5,
        ChangeLog: 6,
        EditMargin: 7,
        Dectivate: 8,
        Activate: 9,
        Duplicate: 10,
        ExportTrackingTags: 11
      };
      const menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      const campaignId = this.model.id;
      const campaignGoalType = this.model.get("goal_type");
      this.el.find(menuConfig.menuTriggerEleSelector).off();
      if (this.createEditPermitted) {
        menuItems[menuIndexRef.CampaignEdit].visible = true;
        menuItems[menuIndexRef.CampaignEdit].url = `#campaign/edit/${campaignId}`;
        menuItems[menuIndexRef.CreateStrategy].visible = true;
        menuItems[menuIndexRef.CreateStrategy].url = `#strategy/create/${campaignId}`;
        if (this.model.get("status") === "0") {
          menuItems[menuIndexRef.Activate].visible = true;
        } else {
          menuItems[menuIndexRef.Dectivate].visible = true;
        }
      }
      if (this.canViewChangeLog) {
        menuItems[menuIndexRef.ChangeLog].visible = true;
      }
      if (this.canViewCampaign) {
        menuItems[menuIndexRef.CampaignReadonly].visible = true;
        menuItems[menuIndexRef.CampaignReadonly].url = `#campaign/readonly/${campaignId}`;
      }
      if (this.canAccessReporting) {
        menuItems[menuIndexRef.Report].visible = true;
        menuItems[menuIndexRef.Report].url = `#reports/campaigns/performance/${campaignId}`;
      }
      if (this.canViewEditMargin && this.canEditWithV2) {
        menuItems[menuIndexRef.EditMargin].visible = true;
        menuItems[menuIndexRef.EditMargin].url = `#campaign/editMargin/${campaignId}`;
      }
      if (this.createEditPermitted) {
        menuItems[menuIndexRef.Duplicate].visible = true;
        menuItems[menuIndexRef.BulkEdit].visible = true;
        menuItems[menuIndexRef.BulkEdit].url = `#campaign/bulkedit/${campaignId}/create`;
        menuItems[menuIndexRef.ExportTrackingTags].visible = true;
        menuItems[menuIndexRef.ExportTrackingTags].url = `#campaign/exportTags/${campaignId}`;
      }
      if (this.canViewBrainLog && campaignGoalType !== "reach" && campaignGoalType !== "spend") {
        menuItems[menuIndexRef.Brain].visible = true;
        menuItems[menuIndexRef.Brain].url = `#appstore/launch/brain_insights/campaign_id=${campaignId}`;
      }
      if (this.showSettingsMenu) {
        t1Menu(menuConfig, this);
      }
    },
    delayedRender() {
      this.prepareDataPoints();
      this.delegateEvents();
    },
    renderViewMode({
      interval: interval,
      mode: mode,
      force: force
    }) {
      if (this.intervalMode !== interval + mode || force) {
        this.intervalMode = interval + mode;
        requestAnimationFrame(() => {
          const modeClass = mode.replace("Mode", "");
          const dataset = this.serialize(interval, modeClass);
          this.assignAsCurrency(dataset);
          const viewTemplate = `<div class="${modeClass}${interval} toggle">{{>${modeClass}}}</div>`;
          const render = Mustache.to_html(viewTemplate, dataset, this.partials);
          if (T1.Utils.hasPolymer()) {
            Polymer.dom(this.el[0].querySelector(".views")).innerHTML = render;
          } else {
            this.$(".views").html(render);
          }
          if (modeClass === "spend" || modeClass === "impression") {
            requestAnimationFrame(() => {
              this.delegateEvents();
              this.attachDatePicker(modeClass, interval);
              this.prepareDataPoints();
            });
          }
        });
      }
    },
    prepareDataPoints() {
      T1.Tooltip(this.$(".label"), {
        offset: 8
      });
      T1.Tooltip(this.$(".pointer"), {
        offset: 20
      });
    },
    addHoverState(event) {
      $(event.currentTarget).parent().addClass("onhover");
    },
    removeHoverState(event) {
      $(event.currentTarget).parent().removeClass("onhover");
    },
    setClickableFavorite(evt) {
      this.clickableFavorite(evt, T1.Utils.getDiagonalPoints.call(evt.currentTarget, evt));
    },
    toggleForChart(event) {
      var model = this.model;
      this.stopEvent(event);
      this.chartable = true;
      T1Timer.start(`CHART:ADD:${model.get("id")}`);
      T1.EventHub.publish("chart.add", {
        model: model
      });
      return false;
    },
    editCampaign(event) {
      this.stopEvent(event);
      T1.Location.set(`campaign/edit/${this.model.id}/details/general`);
    },
    openStrategyList() {
      this.loadStrategies(true);
      this.$(".strategy-list").toggle("blind");
    },
    strategyListLoaderStartStop(toStart) {
      if (!this.strategyListLoader) {
        this.strategyListLoader = T1.Loader.create({
          target: ".view-loader-strategy-list",
          text: "Loading ...",
          loaderClass: "loader",
          context: this.el
        });
      }
      if (toStart === true) {
        this.strategyListLoader.start();
      } else {
        this.strategyListLoader.stop();
      }
    },
    fetchStrategies() {
      var campaign = this.model;
      var strategies = campaign.getRelated("strategies");
      const showAll = Boolean(campaign.collection.selectedStrategyId);
      var isSearchResult = $.isEmptyObject(strategies.search.getCriteria()) !== true;
      if (campaign.collection && campaign.collection.pageLimit !== strategies.pageLimit) {
        strategies.pageLimit = campaign.collection.pageLimit;
        if (isSearchResult !== true) {
          campaign.strategiesFetched = false;
          strategies.reset(showAll);
        }
      }
      if (!campaign.strategiesFetched) {
        campaign.prepareStrategiesConfig(strategies);
      }
      this.loadStrategies(showAll);
      this.closeInlineEditor();
    },
    loadStrategies(showAll) {
      const {
        model: model,
        viewMode: viewMode
      } = this;
      if (!this.strategiesLoaded) {
        this.strategyListLoaderStartStop(true);
        this.strategiesLoaded = true;
        this.layout.load();
        this.layout.append(".strategies-box", {
          module: "strategy",
          viewType: "list",
          options: {
            collection: model.getRelated("strategies"),
            campaign_id: model.id,
            showAll: showAll,
            viewMode: viewMode,
            currentGoalMode: model.collection.strategy_goal_performance,
            reportInterval: model.collection.report_interval
          }
        });
      } else {
        this.toggleStrategiesList();
      }
    },
    toggleStrategiesList() {
      const smartscrollInst = this.$(".campaign").data("smartscroll");
      this.$(".w-CampaignListItem.campaign").toggleClass("strategy-list-open");
      if (smartscrollInst && this.$(".campaign").hasClass("strategy-list-open")) {
        smartscrollInst.bind();
      } else if (smartscrollInst) {
        smartscrollInst.unbind();
        this.$(".campaign-headers").removeClass("smart-scroll-fixed");
      }
      this.$(".w-StrategySearch").trigger("campaign:toggleStrategies");
      this.$(".w-StrategyList").trigger("campaign:toggleStrategies");
    },
    applyEditHover() {
      this.$(".campaign-edit-holder").addClass("hover");
    },
    removeEditHover() {
      this.$(".campaign-edit-holder").removeClass("hover");
    },
    applyGearHover({
      view: view
    } = {}) {
      if (view && view.cid === this.cid) {
        this.$(".campaign-actions-holder").addClass("hover");
        this.$(".campaign-edit-holder").addClass("suppress-hover");
      }
    },
    removeGearHover() {
      this.$(".campaign-actions-holder").removeClass("hover");
      this.$(".campaign-edit-holder").removeClass("suppress-hover");
    },
    loaderOpts: {
      contentClass: "inline-edit-loader",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15
      }
    },
    deactivateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(false);
    },
    activateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(true);
    },
    activationHandler(toActivate) {
      const entityObj = {
        loader: this.loader(),
        status: toActivate === true ? "1" : "0",
        isProgrammaticGuaranteed: this.isProgrammaticGuaranteed,
        model: this.model,
        modalEle: this.$(".deactivate-campaign-modal")[0]
      };
      this.toActivate = status;
      const goalType = this.model.get("goal_type");
      if (toActivate && (goalType === "cpa" || goalType === "roi") && !this.model.get("merit_pixel_id")) {
        this.showActivationCampaignModal("Merit pixel required to activate campaign");
      } else if (toActivate && (goalType === "viewability_rate" || goalType === "vcpm") && (!this.model.get("viewability_vendor_id") || !this.model.get("viewability_type"))) {
        this.showActivationCampaignModal("Viewability vendor and measurement type required to activate campaign");
      } else {
        Util.deactivateEntity(entityObj);
      }
    },
    showActivationCampaignModal: function (errMsg) {
      T1.EventHub.publish("campaign:activationError", errMsg);
    },
    clickableFavorite(event, dd) {
      const isClickable = dd < 0;
      var el = $(event.currentTarget);
      el.toggleClass("favorite-clickable", isClickable).attr("title", "");
      if (isClickable) {
        el.attr("title", this.model.favorite ? favoritedTitle : notFavoritedTitle);
      }
    },
    toggleFavorite(event) {
      var campaign = this.model;
      var campaigns = campaign.collection;
      var currentTarget = $(event.currentTarget);
      var campaignId = campaign.id;
      var loader = this.loader();
      const icon = this.el.parents().find("#ribbonFavoriteStar");
      this.stopEvent(event);
      function unbindEvent() {
        return false;
      }
      loader.start();
      currentTarget.bind("click", unbindEvent);
      if (campaigns) {
        campaigns.setFavorite(campaignId, () => {
          const {
            favorite_class: favorite_class
          } = this.serialize(this.reportInterval);
          currentTarget.unbind("click", unbindEvent);
          loader.stop();
          this.updateDataBind({
            favorite_class: favorite_class
          }, this.el);
          Util.toggleFavStar(campaigns.favorites, icon);
        });
      }
    },
    toggleCheckbox(event) {
      event.stopPropagation();
      $(event.currentTarget).toggleClass("checked");
      T1.EventHub.publish("campaign:updateCheckAll");
    },
    bulkActionHandler() {
      T1.EventHub.unsubscribe("campaign:bulkAction");
    },
    unload() {
      var smartscrollInst = $(".campaign", this.el).data("smartscroll");
      if (smartscrollInst) {
        smartscrollInst.unbind();
      }
      this.layout.empty();
    },
    reload() {
      this.layout.unload();
      this.strategiesLoaded = false;
      T1View.prototype.reload.call(this);
    },
    reportInterval: "CTD",
    reports: {},
    spend_YDY: "",
    serializeAdditionalInformation(reportInterval) {
      let pacingRatio, pctImpsSpent, vcrValue;
      const dataset = {};
      let showImpCounts = true;
      const coloredBarWidth = 95;
      const dayInMilliSecs = 864e5;
      const endIndex = 2;
      const impColoredBarWidth = 95;
      const impStartOffsetX = 5;
      const outPacingRange = 10;
      const startOffsetX = 5;
      const model = this.model;
      const info = model.get("rpt_goal_monitoring").toJSON();
      const goalValue = model.get("goal_value");
      const start = Util.parseDate(model.get("start_date"));
      const end = Util.parseDate(model.get("end_date"));
      const budgetInformation = dataset.budgetInformation = Util.getBudgetInformation(model);
      const campaignGoalValue = goalValue.substring(0, goalValue.length - endIndex);
      function getTipDate(dateObj) {
        return `${Date.CultureInfo.abbreviatedMonthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;
      }
      function getCurrentReportValue(fieldName) {
        return currentReportInterval[fieldName] || "";
      }
      function getCurrentReportFormattedValueNoAbbreviation(fieldName) {
        return currentReportInterval[fieldName] ? $.trim(formatsRolledUpNumbersNoAbbreviation(parseInt(currentReportInterval[fieldName], 10))) : "";
      }
      this.reports = {};
      info.forEach(function (report) {
        this[report.mm_interval] = report;
      }, this.reports);
      const reports = this.reports;
      const loadDate = reports.CTD && reports.CTD.load_date ? Util.parseDate(reports.CTD.load_date) : new Date();
      if (T1.compareDates(start, end) !== 1) {
        if (T1.compareDates(start, loadDate) === 1) {
          dataset.tip_date = getTipDate(start);
          pacingRatio = 0;
          showImpCounts = false;
        } else if (T1.compareDates(loadDate, end) === 1) {
          pacingRatio = hundredPercent;
          showImpCounts = false;
        } else {
          dataset.tip_date = getTipDate(loadDate);
          pacingRatio = divideByZero(hundredPercent * (loadDate.getTime() - start.getTime()) / (end.getTime() - start.getTime()));
          showImpCounts = true;
        }
      }
      dataset.durationRatio = Math.round(startOffsetX + (coloredBarWidth - startOffsetX) * (pacingRatio / hundredPercent));
      dataset.displayPointer = pacingRatio === hundredPercent || !dataset.durationRatio ? "none" : "block";
      if (!info.length) {
        dataset.coloredBarWidth = 0;
        return dataset;
      }
      const currentReportInterval = reports[reportInterval] || {};
      this.model.set({
        imp_count_ydy: reports["1"] ? reports["1"].imp_count : "",
        imp_count_ftd: reports.FTD ? reports.FTD.imp_count : ""
      });
      dataset.mm_goal_performance = getCurrentReportValue("mm_goal_performance");
      dataset.total_conversion_count = getCurrentReportFormattedValueNoAbbreviation("total_conversion_count");
      dataset.clicks_count = getCurrentReportFormattedValueNoAbbreviation("clicks_count");
      dataset.imp_count = getCurrentReportFormattedValueNoAbbreviation("imp_count");
      dataset.totalSpendEcpa = getCurrentReportValue("total_spend_ecpa");
      dataset.totalSpendEcpc = getCurrentReportValue("total_spend_ecpc");
      dataset.totalSpendEcpm = getCurrentReportValue("total_spend_ecpm");
      dataset.mm_ctr = currentReportInterval.mm_ctr ? `${currentReportInterval.mm_ctr}%` : "";
      dataset.goal_value = campaignGoalValue;
      dataset.spent = budgetInformation.budget_spent || "";
      dataset.spend_remaining = budgetInformation.budget_remaining;
      dataset.days_remaining = Math.ceil((end.valueOf() - moment().startOf("day")) / dayInMilliSecs);
      dataset.days_remaining = dataset.days_remaining > 0 ? dataset.days_remaining : 0;
      dataset.totalSpend = getCurrentReportValue(totalSpend);
      dataset.totalSpend_YDY = reports["1"] ? reports["1"][totalSpend] : "";
      spend_YDY = dataset.totalSpend_YDY || spend_YDY;
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      if (hasVcrImpression) {
        vcrValue = currentReportInterval.video_complete_rate_impression_based ? `${currentReportInterval.video_complete_rate_impression_based}%` : "";
      } else {
        vcrValue = currentReportInterval.video_complete_rate ? `${currentReportInterval.video_complete_rate}%` : "";
      }
      dataset.video_complete_rate = vcrValue;
      dataset.viewability_rate = currentReportInterval.viewability_rate ? `${currentReportInterval.viewability_rate}%` : "";
      dataset.budgetMeterLabel = T1.Utils.formatCurrency(dataset.spend_remaining.toString(), null, this.getCurrencyCode());
      dataset.daily_spend_to_pace = calculateDailySpendToPace(model, budgetInformation);
      dataset.coloredBarWidth = budgetInformation.percnt_budget_spent ? startOffsetX + Math.round(budgetInformation.percnt_budget_spent * ((coloredBarWidth - startOffsetX) / hundredPercent)) : 0;
      dataset.coloredBarWidth = dataset.coloredBarWidth > coloredBarWidth ? coloredBarWidth : dataset.coloredBarWidth;
      dataset.coloredBarClass = Math.abs(budgetInformation.percnt_budget_spent - pacingRatio) >= outPacingRange ? "out-pacing" : "pacing";
      dataset.total_impression_budget = model.get("total_impression_budget") || "";
      if (dataset.total_impression_budget) {
        dataset.imp_count_ftd = model.get("imp_count_ftd");
        dataset.imps_remaining = dataset.imp_count_ftd ? dataset.total_impression_budget - dataset.imp_count_ftd : dataset.total_impression_budget;
        const impsSpent = dataset.total_impression_budget - dataset.imps_remaining;
        dataset.imps_spent = showImpCounts && impsSpent > 0 ? $.trim(formatsRolledUpNumbersNoAbbreviation(impsSpent)) : "--";
        pctImpsSpent = impsSpent / dataset.total_impression_budget * hundredPercent;
        let impsToPace = dataset.imps_remaining / dataset.days_remaining;
        impsToPace = !isFinite(impsToPace) ? 0 : Math.round(impsToPace);
        impsToPace = impsToPace < 0 ? 0 : impsToPace;
        dataset.impMeterLabel = $.trim(formatsRolledUpNumbersNoAbbreviation(parseInt(dataset.imps_remaining, 10)));
        dataset.total_impression_budget = $.trim(formatsRolledUpNumbersNoAbbreviation(parseInt(dataset.total_impression_budget, 10)));
        dataset.imps_to_pace = $.trim(formatsRolledUpNumbersNoAbbreviation(impsToPace));
        dataset.imps_to_pace = dataset.imps_to_pace !== "0" && showImpCounts ? dataset.imps_to_pace : "--";
      } else {
        dataset.total_impression_budget = "--";
        dataset.impMeterLabel = "--";
        dataset.imps_to_pace = "--";
      }
      const impsYdy = model.get("imp_count_ydy");
      dataset.imp_count_ydy = impsYdy && impsYdy > 0 && showImpCounts ? $.trim(formatsRolledUpNumbersNoAbbreviation(impsYdy)) : "--";
      dataset.percnt_imps_spent = pctImpsSpent;
      dataset.impColoredBarWidth = pctImpsSpent ? impStartOffsetX + Math.round(pctImpsSpent * ((impColoredBarWidth - impStartOffsetX) / hundredPercent)) : 0;
      dataset.impColoredBarWidth = dataset.impColoredBarWidth > impColoredBarWidth ? impColoredBarWidth : dataset.impColoredBarWidth;
      dataset.impColoredBarClass = Math.abs(pctImpsSpent - pacingRatio) >= outPacingRange ? "out-pacing" : "pacing";
      dataset.defaultText = defaultTextHandler;
      return dataset;
    },
    needBudgetFlightStatusIcon() {
      const today = new Date();
      const budgetFlights = this.model.get("budget_flight");
      if (budgetFlights.models.length) {
        const resultModel = budgetFlights.models.find(model => hasEntityStarted(model, today) !== true);
        if (resultModel) {
          return true;
        }
      }
      return false;
    },
    serialize(interval) {
      const {
        model: model,
        chartable: isCustom
      } = this;
      var advertiser = model.get("advertiser");
      var agency = advertiser ? advertiser.get("agency") : null;
      var dateFormat = "MM/dd/yy";
      var dataset = model.toJSON();
      var isActive = parseInt(dataset.status, 10);
      var isFavorite = model.favorite;
      const statusTitle = isActive ? statusActiveTitle : statusInactiveTitle;
      var reportInterval = interval || this.campaignCollection.report_interval;
      var initialMode = this.viewMode.replace("Mode", "");
      dataset.name = T1.Utils.escapeHTML(dataset.name, true);
      dataset.initiallyShowImpression = initialMode === "impression";
      dataset.initiallyShowPerformance = initialMode === "performance";
      dataset.initiallyShowSpend = !dataset.initiallyShowPerformance && !dataset.initiallyShowImpression;
      dataset.initialModeInterval = initialMode + reportInterval;
      this.isProgrammaticGuaranteed = Util.isProgrammaticGuaranteed(this.model);
      dataset.status_class = function (target) {
        var inactiveClass = "inactive";
        const result = !isActive ? inactiveClass : "";
        if (target) {
          $(target).toggleClass(inactiveClass, !isActive);
        }
        return result;
      };
      dataset.statusTitle = function (target) {
        $(target).attr("title", statusTitle);
        return statusTitle;
      };
      dataset.favorite_class = function (target) {
        var favoriteClass = "favorite-enabled";
        const result = !isFavorite ? "" : favoriteClass;
        if (target) {
          $(target).toggleClass(favoriteClass, isFavorite);
        }
        return result;
      };
      dataset.startDateAsString = moment(Util.parseDate(dataset.start_date)).format(dateFormat.toUpperCase());
      dataset.endDateAsString = moment(Util.parseDate(dataset.end_date)).format(dateFormat.toUpperCase());
      dataset.readOnlyTitle = this.canEdit ? "" : readOnlyTitle;
      dataset.chart_class = isCustom ? "" : "disabled";
      dataset.chartActionTitle = addToChartTitle;
      dataset.currency_code = `${getCurrencySymbol(dataset.currency_code)} ${dataset.currency_code}`;
      dataset.showCampaignEdit = this.createEditPermitted;
      dataset.showSettingsMenu = this.showSettingsMenu;
      dataset.cantEditRoi = dataset.goal_type && dataset.goal_type === "roi";
      dataset = $.extend(dataset, this.serializeAdditionalInformation(reportInterval));
      dataset.actual_goal_type = dataset.goal_type && dataset.goal_type === "roi" ? "ROI" : "";
      dataset.defaultText = defaultTextHandler;
      dataset.agency_name = agency ? agency.get("name") : "unknown";
      dataset.needBudgetFlightStatusTooltip = false;
      dataset.asDecimalCurrency = this.asDecimalCurrency;
      dataset = populatePerformanceAlert(dataset);
      if (dataset.actual_goal_type === "ROI") {
        dataset.isTypeROI = true;
        dataset.goal_value = "&nbsp;--";
        if (!dataset.mm_goal_performance) {
          dataset.mm_goal_performance = "&nbsp;--";
        } else {
          dataset.mm_goal_performance_data = true;
        }
      }
      if (dataset.goal_type === "vcr" || dataset.goal_type === "ctr" || dataset.goal_type === "viewability_rate") {
        dataset.goal_type_is_percentage = true;
      } else {
        dataset.goal_type_is_percentage = false;
      }
      dataset.goal_type = goalTypeText[dataset.goal_type];
      dataset.useAllocationPacing = useAllocationPacing;
      dataset.isTotalAllocation = isTotalAllocation;
      return dataset;
    }
  });
  CampaignItemView = T1Form.InlineEdit(CampaignItemView, dropdownList);
  return CampaignItemView;
});
