define('utils/ContractUtils',["T1"], function (T1) {
  var Date = T1.Date;
  var scrubTimeFromDate = function (value) {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate());
  };
  return {
    formatDate: function (val, dateFormat) {
      var df = dateFormat || "MM/dd/yy";
      if (val && val !== "") {
        return Date.parse(val).toString(df);
      }
      return null;
    },
    getContractStatus: function (contract) {
      var effective_start_date = contract.get ? contract.get("effective_start_date") : contract.effective_start_date;
      var effective_end_date = contract.get ? contract.get("effective_end_date") : contract.effective_end_date;
      var startTime = effective_start_date && scrubTimeFromDate(Date.parse(effective_start_date)).getTime();
      var endTime = effective_end_date && scrubTimeFromDate(Date.parse(effective_end_date)).getTime();
      var nowTime = scrubTimeFromDate(Date.parse("now")).getTime();
      var status = "";
      if (contract.id === "" || contract.id === undefined) {
        status = "New";
      } else if (startTime <= nowTime && endTime >= nowTime) {
        status = "Current";
      } else if (startTime >= nowTime) {
        status = "Pending";
      } else {
        status = "Expired";
      }
      return status;
    },
    getContractDisplayName: function (contract) {
      var start = this.formatDate(contract.get ? contract.get("effective_start_date") : contract.effective_start_date);
      var end = this.formatDate(contract.get ? contract.get("effective_end_date") : contract.effective_end_date);
      var status = this.getContractStatus(contract);
      var name = `${start} - ${end} (${status})`;
      return name;
    },
    validateContractDateOverlap: function (contractList, editedContractInfo, editedContractStartDate, editedContractEndDate) {
      var startDateTime = scrubTimeFromDate(editedContractStartDate).getTime();
      var endDateTime = scrubTimeFromDate(editedContractEndDate).getTime();
      var contractOverlap = false;
      var nowTime = scrubTimeFromDate(Date.parse("now")).getTime();
      var len = contractList.length;
      var contract, contractEndDate, contractStartDate, i, invalidDate, isValid, msg;
      for (i = 0; i < len; i++) {
        contract = contractList[i];
        if (contract.get("id") === editedContractInfo.get("id")) {
          break;
        }
        contractStartDate = scrubTimeFromDate(new Date(contract.get("effective_start_date"))).getTime();
        contractEndDate = scrubTimeFromDate(new Date(contract.get("effective_end_date"))).getTime();
        if (startDateTime >= contractStartDate && startDateTime <= contractEndDate) {
          contractOverlap = true;
          break;
        }
        if (endDateTime >= contractStartDate && endDateTime <= contractEndDate) {
          contractOverlap = true;
          break;
        }
        if (contractStartDate >= startDateTime && contractStartDate <= endDateTime) {
          contractOverlap = true;
          break;
        }
        if (contractEndDate >= startDateTime && contractEndDate <= endDateTime) {
          contractOverlap = true;
          break;
        }
      }
      if (contractOverlap) {
        msg = "Your selected Date Range cannot overlap with " + "any existing contracts.\nPlease adjust your settings and try saving again.";
      } else {
        if (startDateTime > endDateTime) {
          invalidDate = true;
          msg = "Your End Date must be after your Start Date.\nPlease adjust your settings and try saving again.";
        } else if (!editedContractInfo.id && startDateTime < nowTime || endDateTime < nowTime) {
          invalidDate = true;
          msg = "Your selected Date Range cannot be in the past.\nPlease adjust your settings and try saving again.";
        } else if (startDateTime === endDateTime) {
          invalidDate = true;
          msg = "Your End Date must be after Start Date.\nPlease adjust your settings and try saving again.";
        }
      }
      isValid = !invalidDate && !contractOverlap;
      return {
        isValid: isValid,
        message: msg
      };
    },
    isCurrency: function (feeUnit) {
      return feeUnit === "CPM" || feeUnit === "CPTS";
    },
    contractSortFunc: function (a, b) {
      return new Date(b.get ? b.get("effective_start_date") : b.effective_start_date) - new Date(a.get ? a.get("effective_start_date") : a.effective_start_date);
    },
    getContractCurrency: function (contractModel) {
      if (contractModel.get("type") === "contract") {
        if (contractModel.get("currency_code")) {
          return contractModel.get("currency_code");
        }
      } else if (contractModel.get("type") === "legacy_contract") {
        if (contractModel.get("currency_code")) {
          return contractModel.get("currency_code");
        }
        return "USD";
      }
    }
  };
});
