define('modules/reporting/segments/dataExport/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "dataExport",
    name: "reporting/segments/dataExport",
    viewCfgs: {
      dataExport: {}
    }
  });
});
