define('modules/admin/user/views/grid',['require','jQuery','utils/AdminUtils','text!modules/admin/user/templates/grid_item.html','models/sessionUser','T1','T1GridView','T1Menu','T1View','utils/TimezoneUtils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const grid_item = require("text!modules/admin/user/templates/grid_item.html");
  const SessionUser = require("models/sessionUser");
  const T1 = require("T1");
  const T1GridView = require("T1GridView");
  const T1Menu = require("T1Menu");
  const T1View = require("T1View");
  const TimezoneUtils = require("utils/TimezoneUtils");
  var timezone = TimezoneUtils.getClientTimezoneAbbr();
  var convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
  var dateFormat = "MM/dd/yyyy hh:mm TT ";
  var isFireFox = navigator.userAgent.indexOf("Firefox") >= 0;
  var UsersGridView = T1View.extend({
    truncateConfig: {
      ".truncate": {
        lines: 1
      }
    },
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Users",
        contentClass: "search-loader loader-overrides admin-infinite",
        action: "append"
      }
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "checkbox-input",
      label: '<div class="checkbox all"></div>'
    }, {
      name: "email",
      label: "email"
    }, {
      name: "id",
      label: "ID"
    }, {
      name: "first_name",
      label: "first name"
    }, {
      name: "last_name",
      label: "last name"
    }, {
      name: "role",
      label: "role"
    }, {
      name: "last_login",
      label: "last login"
    }],
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Edit",
          url: "#admin/user/",
          visible: true
        }, {
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: true
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }]
      }]
    },
    events: {
      "click .admin-edit": "editUser",
      "click .config": "showOrApplySettingsMenu",
      "click .headers .checkbox": "handleAllChecked",
      "click .item .checkbox": "handleItemChecked",
      "contextmenu .admin-edit": "applyEditHover",
      "contextmenu .config": "stopEvent",
      "mousemove .item-wrapper": "removeEditHover"
    },
    dataEvents: {
      collection: {
        reset: "load",
        "change:selected": function (model) {
          this.updateItemCheckbox(model);
          this.updateAllCheckbox(model);
        },
        fetchMoreComplete: "updateAllCheckbox",
        "change:active": "updateActive"
      }
    },
    eventhubEvents: {
      "userGrid:reload": function () {
        this.setSearchLoader();
      }
    },
    initialize(args) {
      this.sessionUser = SessionUser.getUser();
      this.collection = args.collection;
      this.collection.refreshOnNextFetch();
      this.users = args.users;
      this.userType = this.sessionUser.get("type");
      this.userRole = this.sessionUser.get("role");
      this.userScope = this.sessionUser.get("scope");
      this.isSessionUserGlobalAdmin = this.userRole === "ADMIN" && this.userScope === "GLOBAL";
      this.initDataRequested = false;
    },
    load() {
      if (!this.initDataRequested) {
        this.resetCollection();
      }
      this.render().then(() => {
        this.loaded = true;
        if (!this.initDataRequested) {
          this.initDataRequested = true;
          this.setSearchLoader();
          this.getData();
        }
      });
    },
    unload() {
      var coll = this.collection;
      if (this.loaded) {
        if (this.inSearch || this.activationUpdated || isFireFox) {
          this.inSearch = false;
          this.activationUpdated = false;
          this.initDataRequested = false;
          this.resetCollection();
          coll.refreshOnNextFetch();
        } else {
          this.resetCollection(true);
        }
        if (coll && !coll.loaded && coll.currentFetch) {
          coll.currentFetch.abort();
          if (coll.infiniteScrolling) {
            this.isFetching = coll.isFetching = false;
          } else {
            this.initDataRequested = false;
          }
        }
        coll.infiniteScrolling = false;
        if (this.renderQueue) {
          this.renderQueue.flush();
        }
        this.loaded = false;
      }
    },
    resetCollection(leaveFirstPageData) {
      var coll = this.collection;
      if (leaveFirstPageData) {
        const models = coll.models;
        coll.reset(models.slice(0, Math.min(models.length, coll.pageLimit)), {
          silent: true
        });
      } else {
        coll.reset(undefined, {
          silent: true
        });
        coll.loaded = false;
      }
    },
    getData() {
      if (this.userType === "AGENCY") {
        this.collection.searchConfiguration = $.extend(true, this.collection.searchConfiguration, {
          fields: {
            type: {
              type: "exclude"
            }
          }
        });
        this.collection.applySearchFilter("type", "INTERNAL");
      }
      this.collection.filterBy();
    },
    itemContentRendered($item) {
      this.applyTruncate($item);
      try {
        this.showOrApplySettingsMenu($item.find(".settings-icon"));
      } catch (err) {
        T1.log(err);
      }
    },
    showOrApplySettingsMenu(eventOrTarget) {
      var $menuTarget = Object.prototype.hasOwnProperty.call(eventOrTarget, "currentTarget") ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var id = $menuTarget.data && $menuTarget.data("id") || $menuTarget.find(".settings-icon").data("id");
      var $checkbox = this.$(`#id${id} .checkbox`);
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Edit: 0,
        Deactivate: 1,
        Activate: 2
      };
      var $jumpLink, permissions;
      function updateSettingsMenuConfig() {
        menuItems[menuIndexRef.Edit].visible = permissions.canEdit;
        menuItems[menuIndexRef.Activate].visible = permissions.canActivate;
        menuItems[menuIndexRef.Deactivate].visible = permissions.canDeactivate;
      }
      if ($menuTarget) {
        permissions = AdminUtils.checkUserPermissions(this.sessionUser, this.collection.get(id));
        $jumpLink = this.$(`#edit${id}`);
        if (permissions.canEdit) {
          menuItems[menuIndexRef.Edit].url = `#admin/user/${id}`;
        } else {
          $jumpLink.addClass("suppress-hover");
        }
        if (!permissions.canActivate && !permissions.canDeactivate) {
          $checkbox.addClass("disabled");
        }
        if (!permissions.canActivate && !permissions.canDeactivate && !permissions.canEdit) {
          $menuTarget.addClass("disabled");
        } else {
          menuConfig.updateSettingsMenuConfig = updateSettingsMenuConfig;
          menuConfig.dataID = `data-id=${id}`;
          menuConfig.menuTriggerEleSelector = `div[id="id${id}"] .config`;
          T1Menu(menuConfig, this);
        }
      }
    },
    updateActive(model) {
      var id = model.get("id");
      var active = model.get("active");
      var targetDiv = `div[id="id${id}"]`;
      var $item = this.$(`${targetDiv} .item`);
      var $config = $item.find(".config");
      if (active === "0") {
        $item.addClass("inactive");
      } else {
        $item.removeClass("inactive");
      }
      this.showOrApplySettingsMenu($config);
    },
    deactivateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(false, evt);
    },
    activateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(true, evt);
    },
    viewOnlyHandler() {},
    activationHandler(toActivate, evt) {
      var loader;
      var $currentTarget = $(evt.currentTarget);
      var id = $currentTarget.closest("dl").data("id");
      var active = toActivate === true ? "1" : "0";
      function stopLoader() {
        loader.stop();
      }
      if (id) {
        const model = this.collection.get(id);
        loader = T1.Loader.create({
          contentClass: "inline-edit-loader",
          target: `div[id="id${id}"]`,
          spinnerConfig: {
            left: 15,
            length: 4,
            lines: 10,
            radius: 3,
            top: 4
          }
        });
        loader.start();
        model.save({
          active: active
        }, {
          success: stopLoader,
          conflict: stopLoader
        });
        if (this.users.isActive === "true") {
          this.activationUpdated = true;
        }
      }
    },
    handleItemChecked(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const $target = $(evt.target);
      const id = $target.data("id");
      const model = this.collection.get(id);
      let selected = model.get("selected") || false;
      if ($target.hasClass("disabled")) {
        selected = true;
      }
      model.set({
        selected: !selected
      });
      T1.EventHub.publish("change:itemSelectedChange");
    },
    handleAllChecked(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const selected = this.collection.getSelected();
      if (this.collection.length === selected.length) {
        this.collection.toggleAllSelected(false);
      } else {
        this.collection.toggleAllSelected(true);
      }
      T1.EventHub.publish("change:itemSelectedChange");
    },
    updateItemCheckbox(model) {
      var $checkbox = this.$(`#id${model.get("id")} .checkbox`);
      if (!$checkbox.hasClass("disabled")) {
        if (model.get("selected")) {
          $checkbox.addClass("checked");
        } else {
          $checkbox.removeClass("checked");
        }
      }
    },
    updateAllCheckbox() {
      var $allCheckBox = this.$(".header .checkbox");
      var selected = this.collection.getSelected();
      var outClass = "";
      if (this.collection.length === selected.length) {
        outClass = "checked";
      } else if (selected.length > 0) {
        outClass = "partial";
      }
      $allCheckBox.removeClass("checked partial").addClass(outClass);
    },
    editUser(event) {
      var $currentTarget = $(event.currentTarget);
      var id = $currentTarget.data("id");
      var $panels = $(document).find("strand-tooltip");
      $panels.each(function (panel) {
        if ($panels[panel].close) {
          $panels[panel].close();
        }
      });
      this.stopEvent(event);
      if (event.shiftKey || event.ctrlKey || event.metaKey) {
        const newWindow = window.open();
        newWindow.opener = null;
        newWindow.location = `#admin/user/${id}`;
      } else {
        T1.Location.set(`#admin/user/${id}`);
      }
    },
    applyEditHover(evt) {
      var $currentTarget = $(evt.currentTarget);
      $currentTarget.closest(".admin-edit-holder").addClass("hover");
    },
    removeEditHover() {
      this.$(".admin-edit-holder").removeClass("hover");
    },
    stopEvent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    serialize(inputCollection) {
      var coll = inputCollection || this.collection;
      var models = coll.toJSON() || [];
      var len = models.length;
      var hideNoResultMessage = false;
      var list = [];
      var highlightText = T1.Utils.highlightText;
      const escapeHTML = T1.Utils.escapeHTML;
      var isSearchResult = coll.searchTerm;
      var i;
      function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
      function processData(data) {
        var searchedTerm = coll.searchTerm;
        var hasHash = searchedTerm && searchedTerm.indexOf("#") !== -1;
        var isIdSearch = searchedTerm && (hasHash || $.isNumeric(searchedTerm));
        const gridUserName = escapeHTML(data.username, true);
        const gridFirstName = escapeHTML(data.first_name, true);
        const gridLastName = escapeHTML(data.last_name, true);
        return {
          id: isSearchResult && isIdSearch ? highlightText(data.id, hasHash ? searchedTerm.split("#")[1] : searchedTerm) : data.id,
          entityID: data.id,
          email: isSearchResult ? highlightText(gridUserName, AdminUtils.htmlDecode(searchedTerm)) : gridUserName,
          first_name: isSearchResult ? highlightText(gridFirstName, AdminUtils.htmlDecode(searchedTerm)) : gridFirstName,
          last_name: isSearchResult ? highlightText(gridLastName, AdminUtils.htmlDecode(searchedTerm)) : gridLastName,
          role: toTitleCase(data.role),
          last_login_on: data.last_login_on ? convertToLocalTime(data.last_login_on).toString(dateFormat) + timezone : "--",
          is_active: data.active === "1"
        };
      }
      this.inSearch = isSearchResult && isSearchResult !== "";
      if (coll.loaded === false && isFireFox) {
        hideNoResultMessage = true;
      }
      for (i = 0; i < len; i++) {
        list.push(processData(models[i]));
      }
      return {
        hideNoResultMessage: hideNoResultMessage,
        list: list
      };
    }
  });
  return T1GridView(UsersGridView);
});
