define('modules/admin/myOrgs/organization/views/exchangeSeats',['require','Underscore','jQuery','models/exchangeSeat','collections/exchangeSeats','collections/supplySources','T1','T1View','text!../templates/exchangeSeats.html','When','T1Form'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const ExchangeSeat = require("models/exchangeSeat");
  const ExchangeSeats = require("collections/exchangeSeats");
  const SupplySources = require("collections/supplySources");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/exchangeSeats.html");
  const When = require("When");
  const T1Form = require("T1Form");
  var ExchangeSeatsEdit = T1View.extend({
    template: template,
    dataEvents: {
      exchangeSeats: {
        reset: "load"
      }
    },
    actionsConfig: {
      formActions: [{
        class: "cancel",
        label: "cancel",
        location: "#admin"
      }, {
        class: "save save-btn-secondary",
        label: "save",
        location: false
      }, {
        class: "saveAndContinue save-btn-primary",
        label: "save & continue",
        location: false
      }]
    },
    initialize() {
      this.exchangeSeats = new ExchangeSeats([], {
        isPaginated: false
      });
    },
    loadCollection() {
      var promises = [];
      const supplySourcedFetch = When.defer();
      const exchangeSeatsFetch = When.defer();
      promises.push(supplySourcedFetch.promise);
      promises.push(exchangeSeatsFetch.promise);
      this.exchangeSeats.id = this.model.id;
      const coll = this.collection = new SupplySources(false, {
        fetchOptions: {
          full: "supply_source"
        },
        isPaginated: false
      });
      coll.search.set({
        params: [{
          term: "1",
          searchField: "seat_enabled"
        }],
        fetchRequired: false
      });
      coll.fetch({
        success: () => {
          this.collection = coll;
          supplySourcedFetch.resolve();
        }
      });
      this.exchangeSeats.fetch({
        success: function () {
          exchangeSeatsFetch.resolve();
        }
      });
      When.all(promises).then(() => {
        this.load();
      });
    },
    load() {
      if (!this.exchangeSeats.loaded) {
        this.loadCollection();
      }
      if (this.exchangeSeats.loaded) {
        this.render().then(() => {
          T1.resizeWidth(this.el);
          T1.Form.Masks.attach($(".exchange-cost .wc-input"));
          if (this.collection.loaded) {
            setTimeout(() => {
              this.activateControls();
              this.selectCheckedBoxes();
            }, 0);
          }
        });
      }
    },
    unload() {
      this.exchangeSeats.reset(undefined, {
        silent: true
      });
      this.exchangeSeats.loaded = false;
    },
    selectCheckedBoxes() {
      var self = this;
      var data = this.exchangeSeats.toJSON();
      var $el = $(self.el);
      var $billMediaCheckbox = $el.find(".bill-media-checkbox").find(".wc-checkbox");
      var $allBillChecked = true;
      var $exchangeSeatInfo = $(".exchange-seat-info");
      data.forEach(function (val) {
        const srcID = 12;
        var supply_source_id = val.supply_source_id;
        var $div = $el.find(`.supply-source-${supply_source_id}`);
        var $input = $div.find(".seat-id").find(".wc-input");
        var $enabledCheckbox = $div.find(".exchange-seats-checkbox").find(".wc-checkbox");
        var $checkbox = $div.find(".bill-media-checkbox").find(".wc-checkbox");
        if ($enabledCheckbox.length === 0) {
          return;
        }
        if (val.status === "1") {
          $enabledCheckbox[0].checked = true;
          $input.attr("disabled", false);
          $checkbox.attr("disabled", false);
        }
        if (val.seat_identifier !== "undefined") {
          $input.val(val.seat_identifier);
        }
        $checkbox[0].checked = val.bill_media_to_client === "1";
        $div.attr("id", val.id);
        if (Number(supply_source_id) === srcID) {
          const $cost_input = $div.find(".exchange-cost .wc-input");
          const $dropdown = $div.find(".exchange-cost-type .wc-dropdown").attr("disabled", false);
          const tmpDisableDropdownEvent = function () {
            return false;
          };
          if (val.rmx_exchange_cost_unit !== "PCT_MEDIA") {
            $dropdown.one("changed", tmpDisableDropdownEvent);
          }
          if (val.rmx_exchange_cost_unit === "CPM" && val.rmx_exchange_cost_cpm !== "undefined") {
            $cost_input.val(val.rmx_exchange_cost_cpm);
          } else if (val.rmx_exchange_cost_pct !== "undefined") {
            $cost_input.val(val.rmx_exchange_cost_pct);
          }
          $dropdown.val(val.rmx_exchange_cost_unit);
          if (val.status === "1") {
            $cost_input.attr("disabled", false);
            $dropdown.attr("disabled", false);
          } else {
            $dropdown.attr("disabled", true);
          }
        }
      });
      $allBillChecked = true;
      $billMediaCheckbox.each(function () {
        if (!this.disabled && !this.checked) {
          $allBillChecked = false;
          return false;
        }
      });
      if (!$allBillChecked) {
        $exchangeSeatInfo.show();
      }
    },
    activateControls() {
      var $exchangeRow;
      var exchangeSeats = this.exchangeSeats;
      var $exchangeCheckbox = this.$(".exchange-seats-checkbox").find(".wc-checkbox");
      var $billMediaCheckbox = this.$(".bill-media-checkbox").find(".wc-checkbox");
      var $exchangeCostType = this.$(".exchange-cost-type").find(".wc-dropdown");
      var $exchangeCostInput = this.$(".exchange-cost").find(".wc-input");
      var $allBillChecked = true;
      var $exchangeSeatInfo = $(".exchange-seat-info");
      $exchangeCheckbox.on("click", function (evt) {
        $exchangeRow = $(evt.currentTarget).parents(".exchange-seat-row");
        const tempRowId = `temp${$exchangeRow.index()}`;
        const seat = exchangeSeats.get($exchangeRow.attr("id"));
        if (seat === null || seat === undefined) {
          $exchangeRow.attr("id", tempRowId);
          const exchangeSeat = new ExchangeSeat({
            supply_source_id: $exchangeRow.data("supply-id"),
            id: tempRowId,
            status: evt.currentTarget.checked ? "1" : "0"
          });
          exchangeSeats.add(exchangeSeat);
        } else {
          seat.set({
            status: evt.currentTarget.checked ? "1" : "0"
          });
        }
        if (evt.currentTarget.checked) {
          $exchangeRow.find(".bill-media-checkbox .wc-checkbox, .seat-id .wc-input, " + ".exchange-cost .wc-input, .exchange-cost-type .wc-dropdown").removeAttr("disabled");
          $exchangeRow.find(".bill-media-checkbox .wc-checkbox").attr("checked", "true");
        } else {
          $exchangeRow.find(".bill-media-checkbox .wc-checkbox").removeAttr("checked");
          $exchangeRow.find(".seat-id .wc-input, .exchange-cost .wc-input").removeAttr("error original-title").val("");
          $exchangeRow.find(".bill-media-checkbox .wc-checkbox, .seat-id .wc-input, " + ".exchange-cost .wc-input, .exchange-cost-type .wc-dropdown").attr("disabled", "true");
        }
      });
      $billMediaCheckbox.on("click", function (evt) {
        if (evt.currentTarget.checked) {
          $allBillChecked = true;
          $billMediaCheckbox.each(function () {
            if (!this.disabled && !this.checked) {
              $allBillChecked = false;
              return false;
            }
          });
          if ($allBillChecked) {
            $exchangeSeatInfo.hide();
          }
        } else {
          $exchangeSeatInfo.show();
        }
      });
      $exchangeCostType.on("changed", function (evt) {
        $exchangeRow = $(evt.currentTarget).closest(".exchange-seat-row");
        const $exchangeCost = $exchangeRow.find(".exchange-cost .wc-input");
        $exchangeCost.removeAttr("error original-title");
      });
      $exchangeCostInput.on("focusout", function () {
        const fourDecimals = 4;
        const twoDecimals = 2;
        var currVal = $exchangeCostInput.val();
        var newVal;
        if ($exchangeCostType.val() === "CPM") {
          newVal = parseFloat(currVal).toFixed(fourDecimals);
        } else {
          newVal = parseFloat(currVal).toFixed(twoDecimals);
        }
        if (!isNaN(newVal)) {
          $exchangeCostInput.val(newVal.toString());
        }
      });
    },
    postData(formValues, success, statusInvalid) {
      var self = this;
      var removeSeats = [];
      this.exchangeSeats.each(function (exchangeSeat) {
        const $row = self.$(`#${exchangeSeat.id}`);
        exchangeSeat.set({
          seat_identifier: $row.find(".seat-id").find(".wc-input").val(),
          bill_media_to_client: $row.find(".bill-media-checkbox .wc-checkbox").val() ? "on" : "off"
        });
        const seat = exchangeSeat.toJSON();
        if (seat.status === "0" && $row.find(".seat-id").find(".wc-input").val() === "") {
          removeSeats.push(exchangeSeat);
          return true;
        }
        if ($row.children().length > 0 && $row.find(".exchange-cost .wc-input").length > 0) {
          if ($row.find(".exchange-cost-type .wc-dropdown").val() === "PCT_MEDIA") {
            $row.find(".exchange-cost .wc-input").attr("data-bind", `rmx_exchange_cost_pct_${exchangeSeat.get("supply_source_id")}`);
            exchangeSeat.unset("rmx_exchange_cost_cpm", {
              silent: true
            });
            exchangeSeat.set({
              rmx_exchange_cost_unit: "PCT_MEDIA",
              rmx_exchange_cost_pct: $row.find(".exchange-cost .wc-input").val()
            });
          } else {
            $row.find(".exchange-cost .wc-input").attr("data-bind", `rmx_exchange_cost_cpm_${exchangeSeat.get("supply_source_id")}`);
            exchangeSeat.unset("rmx_exchange_cost_pct", {
              silent: true
            });
            exchangeSeat.set({
              rmx_exchange_cost_unit: "CPM",
              rmx_exchange_cost_cpm: $row.find(".exchange-cost .wc-input").val()
            });
          }
        }
      });
      for (let i = 0; i < removeSeats.length; i++) {
        self.exchangeSeats.remove(removeSeats[i]);
      }
      const onSuccess = function (newModel) {
        self.exchangeSeats.fetch({
          silent: true,
          success: function () {
            self.selectCheckedBoxes();
          }
        });
        T1.Notify("message", "Exchange Seats updated successfully!");
        success(newModel);
      };
      const onFailure = function (errors) {
        if (errors && errors.length) {
          _.map(errors, function (error) {
            error.field = error.field === "height" ? "custom_height" : error.field === "width" ? "custom_width" : error.field;
            return error;
          });
        }
        statusInvalid(errors, null, ".form-body");
      };
      this.exchangeSeats.saveSeats(onSuccess, onFailure);
    },
    canUnload(onTabChange) {
      const self = this;
      const isFormElementsChanged = this.isFormElementsChanged;
      const onTabChangeHandler = {
        onAlertClose: () => {
          const T1Location = T1.Location;
          const abortedUrl = T1Location.getAbortedURL();
          if (typeof onTabChange !== "object" && !abortedUrl) {
            T1Location.set(`#admin/organization/${self.id}/details`);
          } else if (!abortedUrl) {
            onTabChange.onAlertClose();
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.model.get("name")
        }, onTabChangeHandler);
      }
      return !isFormElementsChanged;
    },
    serialize(inputCollection) {
      const models = (inputCollection || this.collection).toJSON();
      const modelMap = {};
      const data = [];
      const remainingModels = [];
      const sortedIDs = ["8", "12", "4", "31", "11", "13", "3", "37", "78", "100", "81", "5", "9", "15", "17", "18", "19", "21", "25", "26", "28", "29", "33", "34", "35", "36", "38", "39", "40", "41", "42", "43", "44", "82", "83", "84", "85", "86", "89", "90", "91", "92", "93", "94", "95", "97", "98", "99", "100", "102", "103", "111", "112", "113", "114", "115", "116", "117", "118", "119", "120", "121", "123", "124", "125", "126", "127", "128", "129", "130", "131", "132", "133", "134", "135", "136", "137", "138", "139", "140", "142", "143", "148", "155", "156", "158", "159", "160"];
      models.forEach(model => {
        const idToNotInclude = 6;
        if (model.id !== idToNotInclude) {
          const rmxId = 12;
          if (model.id === rmxId) {
            model.name = "Right Media Exchange (RMX)";
            model.exchangeCost = true;
          } else {
            model.exchangeCost = false;
          }
          model.supply_source_id = model.id;
          if (modelMap[model.id]) {
            modelMap[model.id].push(model);
          } else {
            modelMap[model.id] = [model];
          }
        }
      });
      sortedIDs.forEach(id => {
        if (modelMap[id]) {
          data.push(...modelMap[id]);
          delete modelMap[id];
        }
      });
      for (const modelId in modelMap) {
        if (T1.Utils.hasOwnProp(modelMap, modelId)) {
          remainingModels.push(...modelMap[modelId]);
        }
      }
      remainingModels.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      data.push(...remainingModels);
      return {
        exchange_loaded: this.exchangeSeats.loaded,
        data: data
      };
    }
  });
  return T1.Form.ViewUtils.extend(ExchangeSeatsEdit);
});
