define('collections/targetSegmentsProviders',["jQuery", "T1", "T1Collection", "models/targetSegmentsProvider", "Underscore", "T1Sortable"], function ($, T1, T1Collection, TargetSegmentsProvider, _, Sortable) {
  "use strict";

  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      },
      targeting_vendor_id: {
        actual: "targeting_vendor_id"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending",
      firstSortBy: "targeting_vendor_id",
      firstSortByOrder: "ascending"
    }
  };
  var switchCollection = function (mode, collectionObj) {
    var defaultPageLimit = 50;
    delete collectionObj.fetchOptions.parent;
    if (mode === "search") {
      collectionObj.urlString = "path_targeting_segments";
      collectionObj.isPaginated = true;
      collectionObj.page = 0;
      collectionObj.pageLimit = defaultPageLimit;
    } else if (mode === "browse") {
      collectionObj.urlString = "targeting_segments";
      collectionObj.isPaginated = true;
      collectionObj.pageLimit = 100;
      if (collectionObj.search) {
        collectionObj.search.clear();
      }
    } else {
      collectionObj.urlString = "targeting_segments";
      collectionObj.isPaginated = false;
      if (collectionObj.search) {
        collectionObj.search.clear();
      }
    }
  };
  var TargetSegmentsProviders = T1Collection.extend({
    canCache: true,
    cacheDuration: 18e5,
    model: TargetSegmentsProvider,
    urlString: "targeting_segments",
    fixture: "-targeting_segments",
    fetchOptions: {
      full: "*"
    },
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        },
        targeting_vendor_id: {
          type: "enum"
        }
      }
    },
    getProviders: function (options) {
      var opts = options || {};
      var collection = this;
      var deferred = $.Deferred();
      switchCollection("default", this);
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      collection.fetch(opts);
      return deferred.promise();
    },
    getTargetingSegments: function (parent, currency) {
      var opts = {
        silent: true,
        params: {
          currency_code: currency
        }
      };
      var collection = this;
      var deferred = $.Deferred();
      switchCollection("browse", this);
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      if (!isNaN(parent)) {
        collection.fetchOptions = $.extend(true, {}, collection.fetchOptions, {
          parent: parent
        });
      }
      collection.fetch(opts);
      return deferred.promise();
    },
    getTargetSegment: function (query, vendor_id, currency) {
      var opts = {};
      var tmpQ;
      var collection = this;
      var params;
      var deferred = $.Deferred();
      switchCollection("search", this);
      tmpQ = "*" + query + "*";
      params = [{
        term: tmpQ,
        searchField: "name"
      }];
      if (parseInt(vendor_id, 10) !== 0) {
        params.push({
          term: vendor_id,
          searchField: "targeting_vendor_id"
        });
      }
      collection.search.set({
        params: params
      });
      opts.success = function (collection) {
        deferred.resolve(collection.toJSON());
      };
      if (parseInt(vendor_id, 10) !== 0) {
        collection.fetchOptions = $.extend(true, collection.fetchOptions, {
          currency_code: currency,
          parent: vendor_id
        });
      } else {
        collection.fetchOptions = $.extend(true, collection.fetchOptions, {
          currency_code: currency
        });
      }
      collection.fetch(opts);
      return deferred.promise();
    }
  });
  T1.Models.TargetSegmentsProviders = Sortable.extendCollection(TargetSegmentsProviders, sortConfiguration);
  return T1.Models.TargetSegmentsProviders;
});
