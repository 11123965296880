define('collections/exchangeSeats',["jQuery", "T1", "T1Collection", "models/exchangeSeat"], function ($, T1, T1Collection, ExchangeSeat) {
  T1.Models.ExchangeSeats = T1Collection.extend({
    model: ExchangeSeat,
    url: function (params) {
      var originalString = this.urlString;
      var url;
      this.urlString = "organizations/" + this.id + "/" + originalString;
      url = T1Collection.prototype.url.call(this, params);
      this.urlString = originalString;
      return url;
    },
    saveSeats: function (successCallback, statusInvalid) {
      var postObject = {};
      var data = this.toJSON();
      var onSuccess = successCallback || $.noop;
      var models = this.models;
      var errors = [];
      var len;
      var i;
      var y;
      var postIndex;
      var errorList;
      var datum;
      var seat;
      if (data === undefined) {
        throw new Error("Data is required to save the day parts");
      }
      for (i in models) {
        errorList = models[i].validateAttrs(models[i].toJSON());
        if (errorList !== undefined) {
          for (y in errorList) {
            errors.push(errorList[y]);
          }
        }
      }
      if (errors.length > 0) {
        statusInvalid(errors);
      } else {
        len = data.length;
        if (len > 0) {
          postObject.id = this.id;
          for (i = 0; i < len; i++) {
            datum = data[i];
            seat = "seats." + (i + 1) + ".";
            postObject[seat + "supply_source_id"] = datum.supply_source_id;
            postObject[seat + "seat_identifier"] = datum.seat_identifier;
            postObject[seat + "status"] = datum.status;
            postObject[seat + "bill_media_to_client"] = datum.bill_media_to_client;
            if (datum.rmx_exchange_cost_unit !== undefined && (datum.rmx_exchange_cost_pct !== undefined || datum.rmx_exchange_cost_cpm !== undefined)) {
              postObject[seat + "rmx_exchange_cost_unit"] = datum.rmx_exchange_cost_unit;
              if (datum.rmx_exchange_cost_unit === "CPM") {
                postObject[seat + "rmx_exchange_cost_unit"] = "CPM";
                postObject[seat + "rmx_exchange_cost_cpm"] = datum.rmx_exchange_cost_cpm;
              } else {
                postObject[seat + "rmx_exchange_cost_unit"] = "PCT_MEDIA";
                postObject[seat + "rmx_exchange_cost_pct"] = datum.rmx_exchange_cost_pct;
              }
            }
          }
        }
        this.save({
          data: postObject
        }, {
          success: onSuccess,
          statusInvalid: statusInvalid,
          conflict: statusInvalid
        });
      }
    },
    urlString: "seats"
  });
  return T1.Models.ExchangeSeats;
});
