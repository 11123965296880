define('modules/changelog/utils/mappingTable',["T1Permissions"], function (T1Permissions) {
  "use strict";

  return {
    strategy: {
      entity: "strategies",
      bid_aggressiveness: {
        label: "Bid Aggressiveness"
      },
      bid_price_is_media_only: {
        label: "Bid Price Type",
        values: {
          1: "Media Only",
          0: "Total Spend"
        }
      },
      pacing_amount: {
        label: "Budget Pacing Amount"
      },
      pacing_interval: {
        label: "Budget Pacing Interval",
        values: {
          day: "Day",
          hour: "Hour"
        }
      },
      pacing_type: {
        label: "Budget Pacing Type",
        values: {
          even: "Even",
          asap: "ASAP"
        }
      },
      impression_cap: {
        label: "Daily Impression Cap"
      },
      end_date: {
        label: "End Date"
      },
      frequency_amount: {
        label: "Frequency Amount"
      },
      frequency_interval: {
        label: "Frequency Interval",
        values: {
          "not-applicable": "--",
          day: "Day",
          hour: "Hour",
          week: "Week",
          month: "Month"
        }
      },
      frequency_type: {
        label: "Frequency Type",
        values: {
          asap: "ASAP",
          even: "Even",
          "no-limit": "No Cap"
        }
      },
      max_bid: {
        label: "Max Bid CPM"
      },
      min_bid: {
        label: "Min Bid CPM"
      },
      use_optimization: {
        label: T1Permissions.check("feature", "use_new_freq_cap") ? "Enable Optimization" : "Optimize",
        values: {
          1: "On",
          0: "Off"
        }
      },
      pixel_target_expr: {
        label: "My Data Targeting"
      },
      site_selectiveness: {
        label: "Site Selectiveness",
        values: {
          MATHSELECT_250: "MathSelect250 Sites",
          REDUCED: "All Sites (TerminalOne blocklist applied)",
          ALL: "Unfiltered exchange inventory"
        }
      },
      start_date: {
        label: "Start Date"
      },
      status: {
        label: "Status",
        values: {
          1: "Active",
          0: "Inactive"
        }
      },
      "strategy_target_values.id": {
        label: "Strategy Targeting",
        hide: true
      },
      site_restriction_transparent_urls: {
        label: "Transparent URLs Only",
        values: {
          1: "Enabled",
          0: "Disabled"
        }
      },
      budget: {
        label: "Strategy Budget"
      },
      goal_type: {
        label: "Strategy Goal Type",
        values: {
          reach: "CPM Reach",
          spend: "CPM Spend",
          vcpm: "Viewable CPM",
          viewability_rate: "Viewability Rate",
          ctr: "CTR",
          vcr: "Video Completion Rate",
          cpa: "CPA",
          cpc: "CPC",
          cpe: "CPE",
          roi: "ROI"
        }
      },
      roi_target: {
        label: "ROI Target"
      },
      goal_value: {
        label: "Strategy Goal Value"
      },
      name: {
        label: "Strategy Name"
      },
      type: {
        label: "Strategy Type"
      },
      use_campaign_end: {
        label: "Use Campaign End Date",
        values: {
          1: "TRUE",
          0: "FALSE"
        }
      },
      use_campaign_start: {
        label: "Use Campaign Start Date",
        values: {
          1: "TRUE",
          0: "FALSE"
        }
      },
      run_on_all_exchanges: {
        label: "Run On All Exchanges",
        values: {
          1: "Enabled",
          0: "Disabled"
        }
      },
      run_on_all_pmp: {
        label: "Run On All PMP",
        values: {
          1: "Enabled",
          0: "Disabled"
        }
      }
    },
    campaign: {
      entity: "campaigns",
      ad_server_id: {
        label: "Ad Server of Record"
      },
      total_budget: {
        label: "Campaign Budget"
      },
      dynamic_budget_allocation_available: {
        hide: true
      },
      accountable_and_addressable: {
        label: "SOURCE"
      },
      end_date: {
        label: "Campaign End Date"
      },
      frequency_optimization: {
        label: "Campaign Frequency Cap",
        values: {
          0: "TRUE",
          1: "FALSE"
        }
      },
      has_custom_attribution: {
        label: "Has Custom Attribution",
        values: {
          0: "TRUE",
          1: "FALSE"
        }
      },
      goal_type: {
        label: "Campaign Goal Type",
        values: {
          reach: "CPM Reach",
          spend: "CPM Spend",
          vcpm: "Viewable CPM",
          viewability_rate: "Viewability Rate",
          ctr: "CTR",
          vcr: "Video Completion Rate",
          cpa: "CPA",
          cpc: "CPC",
          cpe: "CPE",
          roi: "ROI"
        }
      },
      name: {
        label: "Campaign Name"
      },
      start_date: {
        label: "Campaign Start Date"
      },
      frequency_amount: {
        label: "Frequency Amount"
      },
      frequency_interval: {
        label: "Frequency Interval",
        values: {
          day: "Day",
          month: "30 Days",
          "not-applicable": "--",
          hour: "Hour",
          week: "7 Days"
        }
      },
      frequency_type: {
        label: "Frequency Type",
        values: {
          "no-limit": "No Cap",
          even: "Even",
          asap: "ASAP"
        }
      },
      goal_value: {
        label: "Goal Value"
      },
      io_name: {
        label: "IO Name"
      },
      io_reference_num: {
        label: "IO Ref #"
      },
      service_type: {
        label: "Managed By",
        values: {
          MANAGED: "MediaMath",
          SELF: "T1 Client"
        }
      },
      margin_pct: {
        label: "Margin"
      },
      pc_window_minutes: {
        label: "Post-click Window Minutes"
      },
      pv_pct: {
        label: "Post-view Attribution"
      },
      pv_window_minutes: {
        label: "Post-view Window Minutes"
      },
      conversion_type: {
        label: "Repeat Merit Event",
        values: {
          one: "One per user",
          variable: "One per user over variable time"
        }
      },
      conversion_variable_minutes: {
        label: "Repeat Merit Event Minutes"
      },
      merit_pixel_id: {
        label: "Merit Pixel Id"
      },
      status: {
        label: "Status",
        values: {
          0: "Inactive",
          1: "Active"
        }
      },
      minimize_multi_ads: {
        label: "Minimize Ad Collisions",
        values: {
          0: "Off",
          1: "On"
        }
      },
      spend_cap_automatic: {
        label: "Spend Cap Automatic",
        values: {
          0: "Disabled",
          1: "Enabled"
        }
      },
      spend_cap_amount: {
        label: "Spend Cap Amount"
      },
      spend_cap_enabled: {
        label: "Spend Cap Enabled",
        values: {
          0: "Disabled",
          1: "Enabled"
        }
      },
      override_suspicious_traffic_filter: {
        label: "Suspicious Traffic Filter Override",
        values: {
          0: "Off",
          1: "On"
        }
      },
      suspicious_traffic_filter_level: {
        label: "Suspicious Traffic Filter",
        values: {
          0: "T1 Standard Disabled",
          25: "T1 Standard + Light Increase",
          50: "T1 Standard + Moderate Increase",
          75: "T1 Standard + Strong Increase",
          100: "T1 Standard + Maximum Increase"
        }
      },
      restrict_targeting_to_same_device_id: {
        label: "Bid on Cross-Device",
        values: {
          0: "Enabled",
          1: "Disabled"
        }
      },
      restrict_targeting_to_deterministic_id: {
        label: "Bid on Cookieless Web",
        values: {
          0: "Enabled",
          1: "Disabled"
        }
      },
      zone_name: {
        label: "Time Zone"
      }
    },
    creative: {
      entity: "atomic_creatives",
      ad_format: {
        label: "Ad Format",
        values: {
          DISPLAY: "Display",
          MOBILE: "Mobile",
          EXPANDABLE: "Expandable"
        }
      },
      ad_server_type: {
        label: "Ad Server Name",
        values: {
          ATLAS: "Atlas",
          DART: "DFA",
          EYEWONDER: "EyeWonder",
          MEDIAFORGE: "mediaFORGE",
          MEDIAMIND: "MediaMind",
          MEDIAPLEX: "Mediaplex",
          POINTROLL: "Pointroll",
          YIELD_MANAGER: "Yield Manager",
          OTHER: "Other"
        }
      },
      advertiser_id: {
        label: "Advertiser"
      },
      click_url: {
        label: "Landing Page"
      },
      click_through_url: {
        label: "Click-through URL"
      },
      concept_id: {
        label: "Concept ID"
      },
      end_date: {
        label: "End Date"
      },
      edited_tag: {
        label: "HTML Code"
      },
      is_multi_creative: {
        label: "Multiple Creatives"
      },
      tpas_ad_tag: {
        label: "3rd Party Tags"
      },
      tpas_ad_tag_name: {
        label: "3rd Party Tag Name"
      },
      expansion_direction: {
        label: "Expansion Direction"
      },
      expansion_trigger: {
        label: "Expansion Trigger",
        values: {
          AUTOMATIC: "Automatic",
          MOUSEOVER: "Mouse-Over to Expand",
          CLICK: "Click to Expand"
        }
      },
      external_identifier: {
        label: "3PAS Tag ID"
      },
      file_type: {
        label: "File Type",
        values: {
          gif: "GIF",
          html5: "HTML5",
          swf: "SWF",
          jpg: "JPG",
          unknown: "unknown"
        }
      },
      has_sound: {
        label: "K-Size"
      },
      height: {
        label: "Height"
      },
      is_https: {
        label: "Security",
        values: {
          0: "No",
          1: "Yes"
        }
      },
      is_mraid: {
        label: "MRAID",
        values: {
          0: "No",
          1: "Yes"
        }
      },
      name: {
        label: "Name"
      },
      start_date: {
        label: "Start Date"
      },
      status: {
        label: "Status",
        values: {
          0: "Inactive",
          1: "Active"
        }
      },
      tag: {
        label: "Ad Tag Code"
      },
      tag_type: {
        label: "Ad Tag Type"
      },
      width: {
        label: "Width"
      },
      last_modified: {
        label: "Last Modified"
      }
    },
    pixel: {
      entity: "pixel_bundles",
      advertiser_id: {
        label: "Advertiser ID"
      },
      cost_cpm: {
        label: "CPM Cost"
      },
      cost_pct_cpm: {
        label: "Media % Cost"
      },
      eligible: {
        label: "Eligibility",
        values: {
          0: "Ineligible",
          1: "Eligible"
        }
      },
      external_identifier: {
        label: "External ID"
      },
      keywords: {
        label: "Keywords"
      },
      name: {
        label: "Pixel Name"
      },
      pixel_type: {
        label: "Pixel Type"
      },
      pricing: {
        label: "Pricing"
      },
      rmx_friendly: {
        label: "RMX Friendly"
      },
      rmx_merit: {
        label: "RMX Merit"
      },
      rmx_conversion_type: {
        label: "RMX Conversion Type"
      },
      rmx_conversion_minutes: {
        label: "RMX Conversion Minutes"
      },
      tags: {
        label: "Tags"
      },
      tag_type: {
        label: "Tag Type"
      }
    }
  };
});
