define('modules/campaign/chart/winLoss/shared/canvasText',[], function () {
  "use strict";

  const Txt = function (config) {
    return {
      draw: function () {
        config.context.font = `${config.fontsize}px ${config.font}`;
        config.context.textBaseline = config.baseline;
        config.context.textAlign = config.align;
        config.context.fillStyle = config.color;
        config.context.fillText(config.text, config.x, config.y);
      },
      getBounds: function () {
        return {
          xMin: config.x,
          yMin: config.y,
          xMax: config.x + config.context.measureText(config.text),
          yMax: config.y + config.fontsize
        };
      }
    };
  };
  return Txt;
});
