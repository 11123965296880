define('T1Spinner',["jQuery", "T1", "jQPlugins/jquery.spin"], function ($, T1) {
  return {
    apply: function (el, options) {
      var opts = {
        lines: 11,
        length: 4,
        width: 3,
        radius: 4,
        rotate: 0,
        speed: 1.2,
        trail: 40,
        shadow: false,
        hwaccel: false,
        className: "spinner",
        zIndex: 1,
        top: "auto",
        left: "auto"
      };
      opts = $.extend(opts, options);
      $(el).spin(opts);
    }
  };
});
