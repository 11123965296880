define('models/duplicateCampaignBrainSettings',['require','jQuery','T1','T1Model'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Model = require("T1Model");
  T1.Models.DuplicateCampaignBrainSettings = T1Model.extend({
    name: "duplicateCampaignBrainSettings",
    url: "campaigns/",
    canEdit: function () {
      const deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit(formValues, options) {
      const statusInvalid = options.statusInvalid || $.noop();
      const conflictCallback = options.conflict || $.noop();
      const successCallback = options.success || $.noop();
      this.save(formValues, {
        success: successCallback,
        dataType: this.dataType,
        statusInvalid: statusInvalid,
        conflict: conflictCallback
      });
    }
  });
  return T1.Models.DuplicateCampaignBrainSettings;
});
