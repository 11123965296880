define('utils/RegexUtils',["T1"], function () {
  "use strict";

  const RegexUtils = () => {
    const escapeRegExp = str => {
      if (!str) {
        return "";
      }
      return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
    };
    return {
      escapeRegExp: escapeRegExp
    };
  };
  return RegexUtils();
});
