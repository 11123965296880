define('modules/creatives/display/views/reviewGrid',["jQuery", "Underscore", "T1", "T1View", "T1GridView", "text!../templates/review_grid_header.html", "text!../templates/review_grid_item.html"], function ($, _, T1, T1View, gridDecorator, grid_header, grid_item) {
  var ReviewGridValid = T1View.extend({
    partials: {
      gridHeader: grid_header,
      listItem: grid_item
    },
    initialize: function (args) {
      this.model = args.model;
      this.checkable = args.checkable;
      var reasonLabel = !this.checkable ? "reason" : "";
      this.headers = [{
        name: "name",
        label: "name",
        checkable: this.checkable,
        checked: "checked"
      }, {
        name: "dimensions",
        label: "dimensions"
      }, {
        name: "type",
        label: "type"
      }, {
        name: "reason",
        label: reasonLabel
      }];
    },
    serialize: function () {
      return {
        list: this.model
      };
    }
  });
  return gridDecorator(ReviewGridValid);
});
