define('collections/companions',["jQuery", "T1", "T1Comm", "T1Collection", "models/companion", "T1Sortable"], function ($, T1, T1Comm, T1Collection, Companion) {
  T1.Models.Companions = T1Collection.extend({
    model: Companion,
    apiRoot: T1.VIDEO_API_BASE,
    validateModels: function () {
      var self = this;
      var output = [];
      var validation;
      if (self.models.length > 0) {
        this.each(function (model) {
          validation = model.validateAttrs();
          if (validation && model.get("action") !== "new") {
            output.push(validation);
          }
        });
      }
      if (output.length) {
        return output;
      }
    },
    fetch: function (creativeId) {
      var self = this;
      var companion, temp, output;
      return T1Comm.get({
        dataType: "json",
        onSuccess: function (data) {
          if (data.companions) {
            for (var i in data.companions) {
              if (data.companions[i]) {
                output = {};
                output.id = i;
                temp = data.companions[i];
                output = $.extend(output, temp.details);
                delete temp.details;
                output = $.extend(output, temp);
                companion = new Companion(output);
                self.add(companion);
              }
            }
            self.trigger("reset");
          }
        },
        processAjaxResponse: self.processAjaxResponse,
        onStatusInvalid: function (e) {
          T1.log(e, "status");
        },
        onError: function (e) {
          T1.log(e);
        },
        sourceURL: T1.VIDEO_API_BASE + "creatives/" + creativeId + "/companions"
      });
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: resp.status.code || "ok"
      };
    },
    batchSaveModels: function (creativeId, options) {
      var outCount = 0;
      var outArray = [];
      var output = [];
      var defArr = [];
      var theCount = this.length;
      var saveSuccessHandler = function (def) {
        outCount++;
        defArr.push(def);
        if (outCount < theCount) {
          saveNext();
        } else {
          defArr.forEach(function (deferred) {
            deferred.resolve();
          });
        }
      };
      function saveNext() {
        if (outArray[outCount]) {
          outArray[outCount].set({
            creativeId: creativeId
          });
          output.push(outArray[outCount].save(options, {
            handleSuccess: saveSuccessHandler
          }));
        } else {
          output.push($.Deferred().resolve());
        }
      }
      this.each(function (model) {
        if (!options.toggleStatus) {
          if (model.id) {
            model.set({
              action: "delete"
            });
          }
        }
        if (model.get("action") === "new") {
          theCount--;
        } else {
          if (!model.get("saved")) {
            outArray.push(model);
            model.set({
              saved: true
            });
          }
        }
      });
      saveNext();
      return $.when.apply($, output);
    }
  });
  return T1.Models.Companions;
});
