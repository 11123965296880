define('modules/campaign/chart/views/newChart',['require','T1View','T1Layout','models/reportingMetadata','../../models/mmChartModel','text!../templates/newChart.html'],function (require) {
  "use strict";

  const T1View = require("T1View");
  const T1Layout = require("T1Layout");
  const MetaData = require("models/reportingMetadata");
  const MMChartModel = require("../../models/mmChartModel");
  const template = require("text!../templates/newChart.html");
  const sessionStorage = {
    reportInfos: {},
    reportModels: {}
  };
  return T1View.extend({
    template: template,
    eventhubEvents: {
      "chart.add": "chartAdd",
      "mmCharts.switchReport": "switchReport"
    },
    initialize({
      getReportType: getReportType,
      getReportConfigs: getReportConfigs,
      getEntity: getEntity,
      updateTimezone: updateTimezone
    }) {
      Object.assign(this, {
        configs: getReportConfigs(),
        entity: getEntity(),
        reportType: getReportType(),
        updateTimezone: updateTimezone
      });
      this.initMetadata();
      this.initLayouts();
    },
    initLayouts() {
      const {
        module: module,
        viewType: viewType
      } = this.configs;
      this.layout = new T1Layout({
        el: () => this.el,
        template: '<div class="w-body-c w-chart-body"></div>',
        selectorPath: ".w-body",
        layout: {
          ".w-chart-body": [{
            module: module,
            viewType: viewType,
            options: {
              getEntity: () => this.entity,
              getReportModel: () => this.ReportModel,
              getHeaderEl: () => this.$(".chart-header"),
              updateTimezone: timezoneCode => {
                this.updateTimezone(timezoneCode);
              }
            }
          }]
        }
      });
    },
    load() {
      this.render().then(() => {
        this.layout.load();
      });
    },
    unload() {
      this.storeReportInfo();
      if (this.layout) {
        this.layout.destroy();
        this.layout = null;
      }
      Object.assign(this, {
        configs: null,
        entity: null,
        ReportModel: null,
        reportType: null,
        updateTimezone: null
      });
    },
    chartAdd({
      model: model
    }) {
      if (model) {
        this.entity = model;
        this.ReportModel.setEntity(this.entity);
      }
    },
    initMetadata() {
      let ReportModel;
      const reportType = this.reportType;
      const ReportMeta = new MetaData({
        id: "reportMeta",
        urlString: `std/${reportType}/meta`,
        canCache: true
      });
      if (sessionStorage.reportModels[reportType]) {
        ReportModel = sessionStorage.reportModels[reportType];
      } else {
        ReportModel = new MMChartModel({
          entity: this.entity,
          fetchKey: reportType
        });
        sessionStorage.reportModels[reportType] = ReportModel;
      }
      this.ReportModel = ReportModel;
      ReportMeta.setReportModel(ReportModel);
      ReportModel.setReportMeta(ReportMeta);
      ReportModel.setReportType(reportType);
      ReportModel.setEntity(this.entity);
      if (sessionStorage.reportInfos[reportType]) {
        ReportModel.setReportInfo(reportType, {
          data: {
            "X-Report-Cutoff-Time": sessionStorage.reportInfos[reportType].lastModified
          }
        });
        ReportModel.setStartEndDate(sessionStorage.reportInfos[reportType].reportDates, true);
      }
    },
    switchReport(reportType) {
      this.storeReportInfo();
      this.reportType = reportType;
      this.initMetadata();
    },
    storeReportInfo() {
      const {
        ReportModel: ReportModel,
        reportType: reportType
      } = this;
      const reportInfo = ReportModel.get("reportInfo");
      if (reportInfo) {
        const infoData = reportInfo.reportTypes[reportType];
        infoData.reportDates = ReportModel.getStartEndDate();
        if (!sessionStorage.reportInfos[reportType]) {
          sessionStorage.reportInfos[reportType] = infoData;
        }
      }
    }
  });
});
