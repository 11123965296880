define('modules/media/pmp-e/helpers/sortDeals',[], function () {
  "use strict";

  const isFieldCurrency = field => field === "price" || field === "spend" || field === "ecpm" || field === "ecpa";
  const isFieldNumber = field => field === "biddables" || field === "bids" || field === "wins" || field === "impressions" || field === "matched_bid_opportunities" || field === "strategies_count";
  const isFieldPercent = field => field === "win_rate" || field === "bid_rate" || field === "match_rate" || field === "viewability_rate";
  const isFieldString = field => field === "name" || field === "deal_identifier";
  const makeCurrencySortable = function (val) {
    return val === "N/A" ? -1 : Number(val.slice(1).split(",").join("").split(" ")[0]);
  };
  const makeNumberSortable = function (val) {
    return val === "N/A" ? -1 : Number(val.split(",").join(""));
  };
  const makePercentSortable = function (val) {
    return val === "N/A" ? -1 : Number(val.split("%")[0]);
  };
  var dateA, dateB, nameA, nameB;
  var dataObj = {
    sortDeals: function (gridData, field, sortOrder) {
      if (sortOrder === -1) {
        if (field === "price") {
          gridData.sort((a, b) => a[field] - b[field]);
        } else if (field === "start" || field === "end") {
          gridData.sort(function (a, b) {
            dateA = new Date(a[field]);
            dateB = new Date(b[field]);
            return dateA - dateB;
          });
        } else {
          gridData.sort(function (a, b) {
            nameA = String(a[field]).toLowerCase();
            nameB = String(b[field]).toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }
      } else if (sortOrder === 1) {
        if (field === "price") {
          gridData.sort((a, b) => b[field] - a[field]);
        } else if (field === "start" || field === "end") {
          gridData.sort(function (a, b) {
            dateA = new Date(a[field]);
            dateB = new Date(b[field]);
            return dateB - dateA;
          });
        } else {
          gridData.sort(function (a, b) {
            nameA = String(a[field]).toLowerCase();
            nameB = String(b[field]).toLowerCase();
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          });
        }
      }
      return gridData;
    },
    sortMetricsData: function (gridData, field, sortOrder) {
      let parseFunc;
      if (isFieldCurrency(field)) {
        parseFunc = makeCurrencySortable;
      } else if (isFieldPercent(field)) {
        parseFunc = makePercentSortable;
      } else if (isFieldNumber(field)) {
        parseFunc = makeNumberSortable;
      }
      if (!isFieldString(field)) {
        gridData.sort((a, b) => {
          let aVal, bVal;
          if (field === "price") {
            aVal = Number(a.price_value);
            bVal = Number(b.price_value);
          } else {
            aVal = parseFunc(a[field]);
            bVal = parseFunc(b[field]);
          }
          return sortOrder === -1 ? aVal - bVal : bVal - aVal;
        });
      } else {
        gridData.sort((a, b) => {
          const aVal = String(a[field]).toLowerCase();
          const bVal = String(b[field]).toLowerCase();
          if (aVal < bVal) {
            return sortOrder === -1 ? -1 : 1;
          }
          return sortOrder === -1 ? 1 : -1;
        });
      }
      return gridData;
    },
    sortDealGroups: function (gridData, field, sortOrder) {
      function mapToType(item) {
        return field === "group_name" ? String(item.name).toLowerCase() : Number(item.id);
      }
      function customSort(a, b) {
        const valueA = mapToType(a);
        const valueB = mapToType(b);
        const sortCondition = sortOrder < 0;
        if (valueA < valueB) {
          return sortCondition ? -1 : 1;
        }
        return sortCondition ? 1 : -1;
      }
      return gridData.sort(customSort);
    }
  };
  return dataObj;
});
