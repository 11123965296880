define('modules/reporting/campaigns/siteTransparency/views/siteTransparencyChart',["jQuery", "Underscore", "T1", "T1View", "./stScatterChart", "./stLineChart", "text!../templates/chart.html", "text!modules/shared/templates/chartNoDataGrid.html"], function ($, _, T1, T1View, SiteTransparencyScatterChart, SiteTransparencyLineChart, template, noDataChartTmpl) {
  "use strict";

  const COLOR_LINE_INACTIVE = "#E5E5E5";
  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    partials: {
      chartNoDataChart: noDataChartTmpl
    },
    xKey: "impressions",
    yKey: "total_spend_cpm",
    xAxisLabel: "Impressions",
    yAxisLabel: "eCPM",
    aggregateBy: "site_domain",
    dimensionKey: "site_domain",
    eventhubEvents: {
      "update:metric": function () {
        this.prepareRenderData();
        this.updateChart();
      },
      "switch:chart": function (chartType) {
        this.subSection = chartType;
        this.chart.unloadChart();
        this.setupChart();
      },
      "grid.searchData": function () {
        this.updateChart();
        T1Publish("update:grid:title");
      },
      "grid.resetSearch": function () {
        this.updateChart();
        T1Publish("update:grid:title");
      },
      "grid.markSite": function () {
        this.chart.togglePointText();
      }
    },
    dataEvents: {
      reportModel: {
        "change:dimensionItems": function () {
          this.reload();
        },
        "change:highlightedItem": function () {
          this.highlightChart();
        },
        "change:hiddenCount": "updateChart"
      }
    },
    initialize: function (args) {
      const models = args.models;
      this.subSection = args.models.mainModel.get("siteTransparencySubSection");
      this.summaryCollection = args.dataGroup.collection;
      this.dailyCollection = args.dataGroup.dailyCollection;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
    },
    load: function () {
      const self = this;
      const $el = self.el;
      this.prepareRenderData();
      this.render().then(function () {
        self.$noDataChart = $el.find(".no-data");
        self.chartContainer = $el.find("#site-transparency-chart");
        self.setupChart();
      });
    },
    prepareRenderData: function () {
      const reportModel = this.reportModel;
      const reportMetaObj = this.reportMetaObj;
      const focus = reportModel.get("focus") || "all";
      const metricKey = this.reportMetaObj.metrics[focus];
      this.subSection = reportModel.get("isCTD") ? "summary" : this.subSection;
      this.xKey = reportModel.get("metric");
      this.xAxisLabel = reportMetaObj.metrics[focus][this.xKey].text;
      this.xMetricObj = reportMetaObj.fields[this.xKey];
      this.yKey = reportModel.get("metric2");
      this.yKey = metricKey[this.yKey] ? this.yKey : this.reportMetaObj.metricOptions[0].value;
      this.yAxisLabel = reportMetaObj.metrics[focus][this.yKey].text;
      this.yMetricObj = reportMetaObj.fields[this.yKey];
      reportModel.set({
        xAxisLabel: this.xAxisLabel,
        xMetricObj: this.xMetricObj,
        yAxisLabel: this.yAxisLabel,
        yMetricObj: this.yMetricObj
      });
    },
    setupChart: function () {
      const reportModel = this.reportModel;
      switch (this.subSection) {
        case "daily":
          this.chart = new SiteTransparencyLineChart({
            collection: this.dailyCollection,
            parentClass: this,
            reportModel: reportModel
          });
          break;
        case "summary":
          this.chart = new SiteTransparencyScatterChart({
            collection: this.summaryCollection,
            parentClass: this,
            reportModel: reportModel
          });
          break;
      }
      this.showNoDataChart();
      this.hideChart();
      this.updateChart();
    },
    highlightChart: function () {
      if (!this.chart.siteTransparencyChart) {
        return;
      }
      const dotColors = this.reportModel.get("dotColors");
      const siteTransparencyChart = this.chart.siteTransparencyChart;
      const clickedElement = this.reportModel.get("highlightedItem");
      let clickedElementIndex;
      const graphs = siteTransparencyChart.graphs;
      function resetColors() {
        graphs.forEach(function (graph, index) {
          graph.lineColor = dotColors[index];
        });
      }
      if (clickedElement && clickedElement.id) {
        resetColors();
        graphs.forEach(function (graph, index) {
          if (graph.id !== clickedElement.id) {
            graph.lineColor = COLOR_LINE_INACTIVE;
          } else {
            clickedElementIndex = index;
          }
        });
        graphs.splice(graphs.length - 1, 0, graphs.splice(clickedElementIndex, 1)[0]);
        dotColors.splice(graphs.length - 1, 0, dotColors.splice(clickedElementIndex, 1)[0]);
      } else {
        resetColors();
      }
      siteTransparencyChart.validateData();
    },
    formatValue: function (value, type) {
      return this.reportModel.formatValue(value, type);
    },
    updateChart: function () {
      this.chart.updateChart();
    },
    showNoDataChart: function () {
      this.$noDataChart.removeClass("no-display");
    },
    hideNoDataChart: function () {
      this.$noDataChart.addClass("no-display");
    },
    showChart: function () {
      this.chartContainer.removeClass("no-display");
    },
    hideChart: function () {
      this.chartContainer.addClass("no-display");
    },
    unload: function () {
      this.chart.unloadChart();
      if (this.chartContainer) {
        this.chartContainer = null;
      }
    }
  });
});
