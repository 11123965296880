define('models/trackingEvent',["T1", "T1Model"], function (T1, T1Model) {
  "use strict";

  T1.Models.TrackingEvent = T1Model.extend({
    url: "trackingEvent/",
    fixture: "-tracking",
    validateAttrs: function (attrs, isSkippableChecked) {
      var output = [];
      var requiredField = "This is a required field.";
      var skippableError = "This event type is not supported for non-skippable creatives.";
      var id = this.id || this.cid;
      var isNew = this.get("action") === "new";
      if (!attrs.typ && !isNew) {
        output.push({
          id: id,
          field: "typ",
          message: requiredField
        });
      }
      if (!attrs.url && !isNew) {
        output.push({
          id: id,
          field: "url",
          message: requiredField
        });
      }
      if (attrs.url && attrs.typ && ["skip", "engagedView"].indexOf(attrs.typ) !== -1 && !isSkippableChecked) {
        output.push({
          id: id,
          field: "typ",
          message: skippableError
        });
      }
      if (output.length) {
        return output;
      }
    }
  });
  return T1.Models.TrackingEvent;
});
