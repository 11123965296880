define('modules/admin/myOrgs/organization/views/createEdit',['require','text!../../../templates/header.html','utils/nextTabViewNavigation','models/organization','T1','T1Permissions','T1TabLayoutV2','T1View','text!../templates/createEdit.html','models/sessionUser','utils/AdminUtils'],function (require) {
  "use strict";

  const headTemplate = require("text!../../../templates/header.html");
  const NextTabViewNavigation = require("utils/nextTabViewNavigation");
  const Organization = require("models/organization");
  const T1 = require("T1");
  const T1Permissions = require("T1Permissions");
  const T1TabLayout = require("T1TabLayoutV2");
  const T1View = require("T1View");
  const template = require("text!../templates/createEdit.html");
  const User = require("models/sessionUser");
  const Utils = require("utils/AdminUtils");
  var T1Location = T1.Location;
  var permissionsCheck = T1Permissions.check;
  var user = User.getUser().toJSON();
  var OrganizationCreateEdit = T1View.extend({
    isEdit: false,
    template: template,
    partials: {
      header: headTemplate
    },
    initialize(args) {
      var id = args.id;
      var route = T1Location.getRoute();
      this.id = id;
      this.isEdit = id !== "new";
      this.canLoad = false;
      this.canViewOrg = this.hasViewOrgPermission();
      if (route.id === "new" && route.deepLinks[0] !== "details") {
        T1Location.set("#admin/organization/new/details", {
          replace: true
        });
        return;
      }
      NextTabViewNavigation.attachBehavior(this);
      this.updateModel();
    },
    hasViewOrgPermission() {
      var hasEntityPermission = Utils.checkEntityPermission(user, "organization", this.id, ["w", "r"]);
      var isRestrictedAdmin = user.type === "INTERNAL" && user.scope === "SELECT" && user.role === "ADMIN";
      return isRestrictedAdmin ? !hasEntityPermission : permissionsCheck("organization.view");
    },
    updateModel() {
      if (this.isEdit) {
        const organization = new Organization({
          id: this.id
        });
        organization.fetch({
          success: data => {
            this.model = data;
            this.initDataEvents();
            this.canLoad = true;
            this.initLayouts();
            this.load();
            Utils.updateBreadCrumb("Organization", this.model.toJSON());
            this.renderPartial(".create-edit-head");
          }
        });
      } else {
        this.model = new Organization({
          action: "new"
        });
        this.initDataEvents();
        this.canLoad = true;
        this.initLayouts();
        Utils.updateBreadCrumb("Organization", this.model.toJSON());
      }
    },
    initDataEvents() {
      this.addDataEvent({
        model: {
          "change:version": function (model) {
            Utils.updateBreadCrumb("Organization", model.toJSON());
            this.renderPartial(".create-edit-head");
          },
          "change:id": function (model) {
            var layout = this.layout;
            var modelJSON = model.toJSON();
            const firstTab = 1;
            const secondTab = 2;
            const thirdTab = 3;
            this.isEdit = true;
            T1Location.set(`#admin/organization/${modelJSON.id}/details`, {
              replace: true,
              silent: true
            });
            layout.execute("enable", firstTab);
            layout.execute("enable", secondTab);
            layout.execute("enable", thirdTab);
            Utils.updateBreadCrumb("Organization", modelJSON);
            this.renderPartial(".create-edit-head");
          }
        }
      });
    },
    initLayouts() {
      const firstTab = 1;
      const secondTab = 2;
      const thirdTab = 3;
      var modeString = this.canViewOrg ? "_readOnly" : "";
      var tabs = [{
        label: "Details",
        module: "admin/myOrgs/organization",
        routeName: "details",
        viewType: `details${modeString}`,
        options: {
          id: this.id,
          isEdit: this.isEdit,
          model: this.model
        }
      }, {
        label: "Exchange Seats",
        module: "admin/myOrgs/organization",
        routeName: "exchangeSeats",
        viewType: `exchangeSeats${modeString}`,
        options: {
          id: this.id,
          isEdit: this.isEdit,
          model: this.model
        }
      }, {
        label: "Contracts",
        module: "admin/myOrgs/organization",
        routeName: "contract",
        viewType: "contract",
        options: {
          id: this.id,
          isEdit: this.isEdit,
          model: this.model
        }
      }, {
        label: "Settings",
        module: "admin/myOrgs/organization",
        routeName: "settings",
        viewType: `settings${modeString}`,
        options: {
          id: this.id,
          isEdit: this.isEdit,
          model: this.model
        }
      }];
      this.layout = new T1TabLayout({
        disabled: this.isEdit ? [] : [firstTab, secondTab, thirdTab],
        ignoreRouteDepth: false,
        level: 1,
        selected: 0,
        tabs: tabs,
        tabSetName: "OrgCreateEditTabs"
      });
    },
    load() {
      if (!this.canLoad) {
        return;
      }
      this.render().then(() => {
        this.layout.el = this.$(".edit-create-tabs");
        this.layout.load();
      });
    },
    canUnload() {
      return this.layout ? this.layout.canUnload() : true;
    },
    serialize() {
      var hasEntityPermission = Utils.checkEntityPermission(user, "organization", this.id, ["w", "r"]);
      var canCreateEditOrg = permissionsCheck(`organization.${this.isEdit ? "edit" : "create"}`);
      return {
        canCreateEditOrViewOrg: hasEntityPermission && canCreateEditOrg || this.canViewOrg,
        title: this.isEdit ? this.model.get("name") : "New Organization"
      };
    }
  });
  return OrganizationCreateEdit;
});
