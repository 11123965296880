define('modules/campaign/views/mmCharts',['require','Underscore','jQuery','T1','T1Layout','T1Permissions','T1View','text!../templates/mmCharts.html','models/userPreferences'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!../templates/mmCharts.html");
  const UserPreferences = require("models/userPreferences");
  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "changed .report-ddl": "switchReportHandler",
      "click .report-icon": "selectReportIcon",
      "mousedown .report-icons a": "closeTooltip",
      "toggled .w-chart-drawer": "toggleSpaceBehindDrawer"
    },
    eventhubEvents: {
      "chart.add": "chartAdd",
      "organization:select": "handleOrgChange",
      "winloss.updateChartType": function (chartType) {
        this.chartType = chartType;
        this.updateReportIcon();
      },
      "winloss.hideChartType": function (hide) {
        const $icons = this.$(".report-icons");
        if (hide) {
          $icons.addClass("no-display");
        } else {
          $icons.removeClass("no-display");
        }
      }
    },
    reportType: "performance",
    timezoneCode: "",
    openFlag: "true",
    reportTypeOptions: [{
      name: "Performance",
      value: "performance"
    }, {
      name: "By Hour",
      value: "by_hour"
    }, {
      name: "Watermark",
      value: "watermark"
    }, {
      name: "Win/Loss",
      value: "win_loss"
    }],
    reportLayouts: {
      performance: {
        layout: "old"
      },
      by_hour: {
        layout: "old"
      },
      watermark: {
        layout: "old"
      },
      win_loss: {
        layout: "new",
        module: "campaign/chart/winLoss",
        viewType: "winLoss"
      }
    },
    handleOrgChange() {
      this.unload();
      this.entity = null;
      this.initialize();
      this.load();
    },
    initialize() {
      this.initPreferences();
      this.initLayouts();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "monitor"], ["view", "campaign.chart"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["openFlag", "reportType"]);
    },
    initLayouts() {
      const self = this;
      this.layoutOptions = {
        getEntity: () => this.entity,
        getReportType: () => this.reportType,
        getReportConfigs: function () {
          const reportLayout = self.reportLayouts[self.reportType];
          return {
            module: reportLayout.module,
            viewType: reportLayout.viewType
          };
        },
        updateTimezone: function (timezoneCode, timezoneName) {
          self.updateTimezone(timezoneCode, timezoneName);
        },
        getTimeZoneName: () => this.timezoneName
      };
      this.newChartLayout = new T1Layout({
        el: () => this.$(".chart-holder"),
        template: '<div class="new-chart-holder"></div>',
        layout: {
          ".new-chart-holder": [{
            module: "campaign/chart",
            options: this.layoutOptions,
            viewType: "newChart"
          }]
        }
      });
      this.oldChartLayout = new T1Layout({
        el: () => this.$(".chart-holder"),
        template: '<div class="old-chart-holder"></div>',
        layout: {
          ".old-chart-holder": [{
            module: "campaign",
            options: this.layoutOptions,
            viewType: "mmChart"
          }]
        }
      });
      this.assignLayout();
    },
    assignLayout() {
      this.layout = this.reportLayouts[this.reportType].layout === "new" ? this.newChartLayout : this.oldChartLayout;
    },
    load() {
      this.render().then(() => {
        setTimeout(() => {
          this.$panel = this.$(".w-Campaign-Charts");
          this.drawer = this.$panel.find(".w-chart-drawer")[0];
          this.reportDDL = this.$(".report-ddl")[0];
          this.layout.load();
        }, 0);
      });
    },
    unload() {
      if (this.layout) {
        this.layout.destroy();
        this.layout = null;
      }
      this.$panel = null;
      this.drawer = null;
      this.entity = null;
      this.layoutOptions = null;
      this.newChartLayout = null;
      this.oldChartLayout = null;
      this.preferences = null;
      this.reportDDL = null;
      this.timezoneName = null;
    },
    switchReportHandler(event) {
      const prevLayoutType = this.reportLayouts[this.reportType].layout;
      if (!this.entity) {
        return;
      }
      this.reportType = event.originalEvent.detail.value;
      this.preferences.save();
      const nextLayoutType = this.reportLayouts[this.reportType].layout;
      if (prevLayoutType !== nextLayoutType) {
        this.layout.destroy();
        this.assignLayout();
        this.layout.load();
      } else {
        T1Publish("mmCharts.switchReport", this.reportType);
      }
      this.updateReportIcon();
    },
    chartAdd({
      model: model,
      fromListView: fromListView
    }) {
      if (model) {
        this.updateEntityInfo(model);
      }
      if (!fromListView) {
        if (this.openFlag === "false") {
          this.drawer.open();
        }
      }
    },
    toggleSpaceBehindDrawer() {
      const $panel = this.$panel;
      if ($panel) {
        if (this.openFlag === "true") {
          $panel.removeClass("open");
          $panel.addClass("close");
          this.openFlag = "false";
        } else {
          $panel.removeClass("close");
          $panel.addClass("open");
          this.openFlag = "true";
        }
        this.preferences.save();
      }
    },
    updateEntityInfo(entity) {
      this.entity = entity;
      this.entityType = entity.get("type") === "campaign" ? "campaign" : "strategy";
      this.renderPartial(".chart-title-box");
      if (this.entityType === "campaign") {
        this.updateReportIcon();
      }
    },
    getTitle() {
      const entity = this.entity;
      let titleObj = {};
      const entityObj = entity.toJSON();
      const entityInfo = {
        id: entityObj.id,
        name: entityObj.name
      };
      const truncate = function (str, len) {
        if (str.length > len) {
          return {
            name: T1.Utils.trunc(str, len),
            origText: str
          };
        }
      };
      if (this.entityType === "campaign") {
        const fullLength = 46;
        titleObj.campaignInfo = entityInfo;
        $.extend(titleObj.campaignInfo, truncate(titleObj.campaignInfo.name, fullLength));
      } else {
        const shortLength = 20;
        titleObj = {
          campaignInfo: {
            id: entityObj.campaign_id,
            name: entity.get("campaign").get("name")
          }
        };
        titleObj.strategyInfo = entityInfo;
        $.extend(titleObj.campaignInfo, truncate(titleObj.campaignInfo.name, shortLength));
        $.extend(titleObj.strategyInfo, truncate(titleObj.strategyInfo.name, shortLength));
      }
      return titleObj;
    },
    closeTooltip(event) {
      $(event.currentTarget).find("strand-tooltip")[0].close();
    },
    updateReportIcon() {
      const $reportIcons = this.$(".report-icons");
      if ($reportIcons && !$reportIcons.hasClass("no-display")) {
        this.renderPartial(".report-icons");
        this.selectReportIcon();
      }
    },
    selectReportIcon(event) {
      if (this.reportType === "win_loss") {
        if (event) {
          const $eventCurrentTarget = $(event.currentTarget);
          this.$(".report-icon").removeClass("selected");
          $eventCurrentTarget.addClass("selected");
          if ($eventCurrentTarget.attr("id") === "iconDaily") {
            T1Publish("mmCharts.changeChartType", "daily");
          } else {
            T1Publish("mmCharts.changeChartType", "summary");
          }
        } else {
          if (this.chartType === "daily") {
            this.$("#iconDaily").addClass("selected");
          } else {
            this.$("#iconSummary").addClass("selected");
          }
        }
      }
    },
    getReportURL() {
      return `reports/campaigns/${this.reportType}/${this.getID()}`;
    },
    getID() {
      return this.entity.get(this.entityType === "strategy" ? "campaign_id" : "id");
    },
    updateTimezone(timezoneCode, timezoneName) {
      this.timezoneCode = this.reportType === "watermark" && timezoneCode ? "UTC" : timezoneCode;
      this.timezoneName = this.reportType === "watermark" && timezoneName ? "Etc/GMT" : timezoneName;
      this.renderPartial(".chart-timezone");
    },
    serialize() {
      const {
        reportType: reportType,
        entity: entity
      } = this;
      const isExpanded = this.openFlag === "true";
      const titleObj = entity ? this.getTitle() : {};
      const showPerformanceIcon = Boolean(reportType === "performance" && entity);
      const showWinLossIcon = Boolean(reportType === "win_loss" && entity);
      const reportURL = entity ? this.getReportURL() : "";
      const reportTypeObj = _.find(this.reportTypeOptions, item => item.value === reportType);
      const canAccessReporting = !T1Permissions.check("feature", "reporting_hide");
      return {
        campaignInfo: titleObj.campaignInfo,
        isExpanded: isExpanded,
        reports: this.reportTypeOptions,
        reportType: reportTypeObj.name,
        reportURL: reportURL,
        showPerformanceIcon: showPerformanceIcon && canAccessReporting,
        showWinLossIcon: showWinLossIcon,
        strategyInfo: titleObj.strategyInfo,
        timezone: `(${this.timezoneCode})`
      };
    }
  });
});
