define('models/videoCreativeUpload',["jQuery", "T1", "T1Model", "async"], function ($, T1, T1Model, async) {
  T1.Models.VideoCreativeUpload = T1Model.extend({
    url: "creatives",
    parse: function (data) {
      delete data.status;
      return data;
    },
    apiRoot: T1.VIDEO_API_BASE,
    getUploadCredentials: function (opts) {
      opts = opts || {};
      if (!opts.creativeId) {
        throw new Error("a creativeId is required to retrieve upload key");
      }
      var self = this;
      var successCallback = opts.success || $.noop;
      return this.sync("read", {}, {
        sourceURL: this.getKeyUrl(opts.creativeId),
        dataType: "json",
        success: function (data) {
          delete data.status;
          self.set({
            uploaderInstance: data
          });
          if (successCallback) {
            successCallback();
          }
        },
        processAjaxResponse: self.processAjaxResponse
      });
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: $.extend(resp, {
          status: "ok"
        }),
        statusCode: "ok"
      };
    },
    getKeyUrl: function (creativeId) {
      return T1.VIDEO_API_BASE + "creatives/" + creativeId + "/upload";
    },
    getUploadUrl: function () {
      var uploaderInstance = this.get("uploaderInstance");
      var protocol = uploaderInstance.uploader.protocol || "https://";
      if (!uploaderInstance) {
        return "";
      }
      return protocol + uploaderInstance.uploader.host + ":" + uploaderInstance.uploader.port + "/";
    },
    getStatusUrl: function () {
      return this.getUploadUrl() + "status/";
    },
    fixture: "-creative"
  });
  return T1.Models.VideoCreativeUpload;
});
