define('modules/campaign/bulkedit/creatives/views/panel',["jQuery", "T1", "T1View", "T1Layout", "collections/concepts", "collections/creativesConcept", "T1Collection", "utils/CampaignStrategyUtils", "text!modules/campaign/bulkedit/creatives/templates/panel.html", "text!templates/form_footer.html", "text!templates/alert.html", "text!modules/campaign/bulkedit/creatives/templates/panel_layout.html"], function ($, T1, T1View, T1Layout, StagedConcepts, ConceptsCollection, T1Collection, CampaignStrategyUtils, template, form_footer, alertDialogTemplate, panel_layout) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var CreativesPanel = T1View.extend({
    template: template,
    partials: {
      form_footer: form_footer,
      alertDialog: alertDialogTemplate
    },
    formConfig: {
      bulkedit: true,
      bulkeditActionViews: true
    },
    loaderOpts: {
      target: ".strategy-creatives-loader",
      text: "loading...",
      contentClass: "loader"
    },
    dataEvents: {},
    eventhubEvents: {
      "strategyCreativesLoader:stop": function () {
        if (this.creativesLoader) {
          this.creativesLoader.stop();
        }
      },
      "resetIsFormElementsChanged:strategy-creatives": function () {
        this.isFormElementsChanged = false;
      },
      "formElementsChanged:creatives": function () {
        this.isFormElementsChanged = true;
      }
    },
    initialize: function ({
      bulkeditor: bulkeditor,
      advertiserId: advertiserId
    }) {
      var self = this;
      this.sharedCollection = new T1Collection();
      this.bulkeditor = bulkeditor;
      this.isFormElementsChanged = false;
      this.collection = new ConceptsCollection(null, {
        pageLimit: 50
      });
      this.collection.setAdvertiser(advertiserId);
      this.addDataEvent({
        sharedCollection: {
          add: function () {
            this.isFormElementsChanged = true;
          },
          remove: function () {
            this.isFormElementsChanged = true;
          },
          reset: function () {
            this.isFormElementsChanged = false;
            if (!self.layout.loaded) {
              self.layout.load();
            }
            this.load();
          }
        }
      });
      this.checkBulkeditorReady();
    },
    initializeViews: function () {
      this.initLayout();
    },
    prepareSharedCollection: function () {
      var bulkeditorIds = [];
      var concepts = new StagedConcepts();
      const stagedConcepts = this.stagedConcepts;
      for (let i = 0; i < stagedConcepts.ids.length; i++) {
        const bulkeditorModel = stagedConcepts.ids[i];
        bulkeditorIds.push(bulkeditorModel.id);
      }
      concepts.fetchOptions = {
        full: "*"
      };
      concepts.pageLimit = 100;
      concepts.isPaginated = false;
      concepts.search.set({
        idList: bulkeditorIds,
        fetchRequired: true,
        success: coll => {
          this.concepts = coll;
          coll.bulkeditAction = this.stagedConcepts.action;
          this.sharedCollection = concepts;
          for (let j = 0; j < stagedConcepts.ids.length; j++) {
            const bulkeditorModel = stagedConcepts.ids[j];
            if (this.sharedCollection.get(bulkeditorModel.id)) {
              const sharedCollectionModel = this.sharedCollection.get(bulkeditorModel.id);
              sharedCollectionModel.set({
                percent: bulkeditorModel.percent
              });
              sharedCollectionModel.set({
                weighting: bulkeditorModel.weighting
              });
            }
          }
          this.initializeViews();
          this.load();
        }
      });
    },
    checkBulkeditorReady: function () {
      if (this.bulkeditor.editorReady) {
        this.retrieveStagedConceptsIds();
        if (this.stagedConcepts.ids.length) {
          this.prepareSharedCollection();
        } else {
          this.initializeViews();
        }
      } else {
        this.bulkeditor.getStatus({
          success: () => {
            this.retrieveStagedConceptsIds();
            if (this.stagedConcepts.ids.length) {
              this.prepareSharedCollection();
            } else {
              this.initializeViews();
              this.load();
            }
          }
        });
      }
    },
    retrieveStagedConceptsIds: function () {
      const stagedActions = this.bulkeditor.get("stagedActions");
      const stagedConcepts = {
        ids: []
      };
      $.each(stagedActions, function (index, {
        tag: tag,
        add: add,
        remove: remove,
        overwrite: overwrite
      }) {
        if (tag === "concepts") {
          if (add) {
            stagedConcepts.action = "add";
            stagedConcepts.ids.push(add);
          } else if (remove) {
            stagedConcepts.action = "remove";
            stagedConcepts.ids.push(remove);
          } else if (overwrite) {
            stagedConcepts.action = "overwrite";
            stagedConcepts.ids.push(overwrite);
          }
        }
      });
      this.stagedConcepts = stagedConcepts;
    },
    reload: function () {
      this.checkBulkeditorReady();
    },
    initLayout: function () {
      const {
        collection: collection,
        sharedCollection: sharedCollection
      } = this;
      function filterCallback(term) {
        collection.trigger("search", term);
      }
      function clearFilterCallback() {
        collection.trigger("search.clear");
      }
      function showActiveCallback(e) {
        T1.EventHub.publish("strategyBulkEditCreatives.showActive", $(e.currentTarget).is(":checked"));
      }
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".creatives-list",
        template: panel_layout,
        layout: {
          ".filters": [{
            module: "shared",
            viewType: "filter",
            options: {
              collection: collection,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback,
              showActiveCallback: showActiveCallback
            }
          }],
          ".browse-c": [{
            module: "campaign/bulkedit/creatives",
            viewType: "list",
            options: {
              sharedCollection: sharedCollection,
              collection: collection
            }
          }],
          ".bins-column": [{
            module: "campaign/bulkedit/creatives",
            viewType: "bins",
            options: {
              sharedCollection: sharedCollection
            }
          }]
        }
      });
    },
    load: function () {
      this.render().then(() => {
        if (this.layout && !this.loaded) {
          this.creativesLoader = this.loader();
          this.layout.load();
          this.loaded = true;
          T1.EventHub.publish("bulkEdit:hideLoadingIcon");
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "creatives_targeting"
          });
          if (window.hybridWorkflowParams.isHybrid) {
            updateCancelBtn();
            this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
            this.$cancelBtn = $(".cancelNewT1");
          }
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              isBulkEdit: true,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertBulkeditDialog({}, onTabChange);
      }
      return !isFormElementsChanged;
    },
    unload: function () {
      T1.EventHub.publish("bulkEdit:showLoadingIcon");
      this.bulkeditor.editorReady = false;
      this.sharedCollection.reset([], {
        silent: true
      });
      this.collection.reset([], {
        silent: true
      });
      this.loaded = false;
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    validateStrategyConcepts: function (type, strategyId) {
      var total = 0;
      const fullBudget = 100;
      const twoDigits = 2;
      const errorMsg = `This strategy will not run until the concept weighting adds up to 100% of\n        budget or impressions.`;
      var json = this.sharedCollection.toJSON();
      if (type === "No Weighting" || type === "None") {
        return {
          error: false
        };
      }
      for (let i = 0; i < json.length; i++) {
        const item = json[i];
        const concept = item.strategy_concept ? this.getStrategyConcept(strategyId, item.strategy_concept) : item;
        const savedPercent = concept && concept.percent;
        const percent = item.percent !== undefined ? item.percent : savedPercent;
        const val = percent ? parseFloat(percent) : 0;
        total += val;
      }
      total = parseFloat(total.toFixed(twoDigits));
      return total !== fullBudget ? {
        error: true,
        message: errorMsg,
        total: total
      } : {
        error: false,
        total: total
      };
    },
    prepareStagingData: function () {
      var self = this;
      const conceptsData = {};
      const json = this.sharedCollection.toJSON();
      var first = json[0];
      var type = first && first.weighting ? first.weighting : "No Weighting";
      var alertDialog = $("<div></div>");
      const dropdown = this.$(".weighting-type")[0];
      var noWeighting = dropdown.value === "No Weighting" || dropdown.value === "None";
      conceptsData.ids = [];
      T1.EventHub.publish("strategyBulkEditConcepts:save");
      const validationResult = this.validateStrategyConcepts(type);
      if (validationResult.error && !noWeighting) {
        this.renderPartialTemplate({
          template: this.partials.alertDialog,
          data: {
            message: "This strategy will not run until the concept weighting adds up to 100% of budget or impressions.",
            header: "Invalid Weighting"
          },
          useTemplateHtml: false,
          targetEl: alertDialog
        });
        alertDialog.dialog({
          autoOpen: true,
          modal: true,
          dialogClass: "w-WarningAlert",
          buttons: [{
            text: "Close",
            class: "save",
            click: function () {
              self.stopSpinner();
              self.setSaveAndContinueMode(false);
              self.initUnsavedStateCheck();
              $(this).dialog("close");
            }
          }],
          close: function () {
            $(this).remove();
          }
        });
        return false;
      }
      conceptsData.bulkeditAction = this.sharedCollection.bulkeditAction;
      conceptsData.tag = "concepts";
      conceptsData.collection = "concepts";
      this.sharedCollection.each(function (model) {
        const tempObj = {};
        if (model.get("percent") && model.get("weighting")) {
          tempObj.weighting = model.get("weighting");
          tempObj.percent = model.get("percent");
        }
        tempObj.id = model.get("id");
        conceptsData.ids.push(tempObj);
      });
      return conceptsData;
    },
    stage: function (successCallback, invalidCallback) {
      var stagingData = this.prepareStagingData();
      if (!stagingData) {
        invalidCallback();
        return;
      }
      this.bulkeditor.stageActions(stagingData, {
        success: function (newModel, attributes) {
          if (!attributes.entity.execution_errors.length) {
            T1.EventHub.publish("stageActionsSuccess");
            T1.Notify("message", "Concepts have been staged successfully!");
            successCallback();
          }
        }
      });
    }
  });
  return T1.Form.ViewUtils.extend(CreativesPanel);
});
