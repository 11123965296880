define('modules/media/pmp-e/views/dealEdit',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1UtilsAsync", "T1Permissions", "T1Currencies", "T1Spinner", "models/sessionUser", "models/userPreferences", "text!../templates/dealEdit.html", "collections/agencies", "collections/timezones", "collections/advertisers", "collections/organizations", "collections/supplySources", "collections/mediaDeals", "models/advertiser", "models/agency", "models/mediaDeal", "T1Timezones", "moment-timezone", "models/organization"], function ($, _, When, T1, T1View, T1Layout, T1UtilsAsync, T1Permissions, T1Currencies, T1Spinner, User, UserPreferences, template, Agencies, Timezones, Advertisers, Organizations, SupplySources, Deals, Advertiser, Agency, Deal, T1Timezones, moment, OrganizationModel) {
  "use strict";

  const renderDelay = 50;
  const searchDebounce = 350;
  const changeIdToInt = obj => parseInt(obj.id, 10);
  const infinityDate = "2999-12-31T05:00:00+00:00";
  const dealOwnerTypeMap = {
    organization: "organization",
    agency: "agency",
    advertiser: "advertiser"
  };
  const dealEdit = T1View.extend({
    template: template,
    initialPageLoad: true,
    events: {
      "click .generated-deal-id-radio": "idCheck",
      "click .publisher-deal-id-radio": "idCheck",
      "changed .deals-search-advertisers": "loadPermissionSearch",
      "changed .show-selected-checkbox": "handleShowSelectedOnly",
      "click .date-checkbox1": "handleDates",
      "click .date-checkbox2": "handleDates",
      "saved .deals-dp": "handleDatePicker",
      "click .active-deal": "handleStatus",
      "click .inactive-deal": "handleStatus",
      "changed .deal-status-group": "handleStatusChanged",
      "changed .deal-name-input": "handleName",
      "changed .publisher-deal-id": "handleDealId",
      "blur .publisher-deal-id": "trimDealId",
      "selected .search-advertisers-dropdown": "handleDealOwnerSelected",
      "changed .search-supply-sources-dropdown": "handleSupplyPost",
      "changed .deal-currency-drop": "handleCurrencyPost",
      "keypress .deal-price-input": "handlePrice",
      "keyup .deal-price-input": "handlePriceError",
      "paste .deal-price-input": "handlePrice",
      "blur .deal-price-input": "handleCurrency",
      "click .deal-floor": "handlePriceType",
      "click .deal-fixed": "handlePriceType",
      "changed .deals-text-area": "handleDescription",
      "click .post-deals-close": "validatePost",
      "click .post-deals-another": "validatePost",
      "click .cancel-deal-post": "cancelPost",
      "changed .entity-type-dropdown": "handleOwnerTypeSelected",
      "changed .date-checkbox1, .date-checkbox2": "handleDateCheckboxChanged",
      "click .deal-PG-checkbox": "handlePGCheckboxChanged"
    },
    eventhubEvents: {
      "organization:select": "handleOrgChange",
      dealShowWarningStatus: "showWarningStatus",
      "binGridChanged:updatePermissions": "updatePermissions"
    },
    initialize: function () {
      this.zones = [];
      this.advertiserList = [];
      this.agencyList = [];
      this.agencyListDealOwner = [];
      this.supplyList = [];
      this.advertiserListForAgency = [];
      this.postObject = {};
      this.wasStartDateUnchecked = false;
      this.wasEndDateUnchecked = false;
      this.isPriceOk = true;
      this.dealObject = {};
      this.singleAdObject = {};
      this.isGoodToPost = false;
      this.dealOwnerType = dealOwnerTypeMap.agency;
      this.agenciesChildCountNotFull = [];
      this.organizationChildCountNotFull = [];
      this.dealPermissionsLoaded = false;
      this.dealDataLoaded = false;
      this.fullPermEdit = true;
      this.partialAgencyList = [];
      this.allUserEntities = {};
      this.hasFullOrgAccess = false;
      this.userIsGlobal = false;
      this.isNewDeal = Backbone.history.getFragment().includes("create");
      this.isEditingDeal = !this.isNewDeal;
      this.filterList = this.filterList.bind(this);
      this.filterPartial = this.filterPartial.bind(this);
      const viewOptions = T1.Location.getViewOptions();
      if (viewOptions) {
        this.showDealGroups = viewOptions.showDealGroups;
      }
    },
    handleOrgChange: function () {
      if (this.isEditingDeal) {
        T1.Location.set("#media/pmp-e");
      } else {
        this.firstCall = 0;
        T1.EventHub.publish("orgChanged:hideBinGrid");
        this.load();
      }
    },
    handleOwnerTypeSelected: function () {
      const searchAdvertiserDropdown = this.$(".search-advertisers-dropdown")[0];
      const searchAdvertiserDropdownInput = this.$(".deals-search-advertisers")[0];
      if (!(this.dealOwnerType.toLowerCase() === dealOwnerTypeMap.organization) && this.initialPageLoad) {
        this.initialPageLoad = false;
        return;
      }
      this.postObject.owner_id = "";
      this.dealOwnerType = this.$(".entity-type-dropdown")[0].value;
      T1.EventHub.publish("ownerType:selected", this.dealOwnerType);
      searchAdvertiserDropdownInput.value = "";
      searchAdvertiserDropdownInput.placeholder = this.dealOwnerType.capitalize();
      if (this.dealOwnerType === dealOwnerTypeMap.organization) {
        const selectedOrgID = this.setOwner();
        searchAdvertiserDropdown.set("data", [{
          value: selectedOrgID,
          name: `${this.owner.name} (${selectedOrgID})`
        }]);
        this.permissionedEntities = [this.owner];
        searchAdvertiserDropdown.value = selectedOrgID;
        searchAdvertiserDropdown.setAttribute("disabled", "");
      } else {
        searchAdvertiserDropdown.set("data", []);
        searchAdvertiserDropdown.value = "";
        searchAdvertiserDropdown.removeAttribute("disabled");
      }
    },
    handleDealOwnerSelected: function (event) {
      const entityData = Object.assign({}, event.originalEvent.detail.item || {
        owner: this.dealObject.owner
      });
      const permissionType = this.$(".entity-type-dropdown")[0].value;
      const advertiserID = entityData.value;
      const endIdx = -9;
      entityData.name = entityData.name.slice(0, endIdx);
      this.setOwner(permissionType, entityData);
      if (advertiserID && advertiserID !== "none") {
        this.postObject.owner_id = advertiserID;
      }
      this.showWarningStatus();
    },
    handleStatusChanged: function () {
      this.showWarningStatus();
    },
    handleDateCheckboxChanged: function () {
      this.showWarningStatus();
    },
    handlePGCheckboxChanged: function (event) {
      const mmProvidedDeal = this.$(".generated-deal-id-radio");
      const publisherProvided = this.$(".publisher-deal-id-radio");
      const publisherIDInput = this.$(".publisher-deal-id");
      const cpmFloor = this.$(".deal-floor");
      const cpmFixed = this.$(".deal-fixed");
      const startDateCheck = this.$(".date-checkbox1");
      const endDateCheck = this.$(".date-checkbox2");
      const datePicker = this.$(".deals-dp");
      const dateBtn = this.$(".deals-date-btn");
      const dateInfoBox = this.$(".deal-date-PG-info-inline");
      const inactiveInfoBox = this.$(".deal-PG-inactive-info-inline");
      const endDateObj = new Date(this.postObject.end_datetime);
      const endDateIsInfinity = endDateObj.getFullYear() === new Date(infinityDate).getFullYear();
      const disabledElements = this.$(".PG-disabled");
      if (event.target.checked) {
        cpmFloor.removeAttr("checked");
        cpmFixed.attr("checked", "");
        this.postObject.price_type = "FIXED";
        endDateCheck.removeAttr("checked");
        dateInfoBox.removeAttr("hidden");
        disabledElements.attr("disabled", "");
        if (this.isEditingDeal && startDateCheck.attr("checked")) {
          const startDateObj = new Date(this.postObject.start_datetime);
          this.postObject.end_datetime = undefined;
          this.startDatePassed = true;
          datePicker.attr("start-enabled", "false");
          datePicker.attr("end-enabled", "true");
          datePicker[0].start = this.postObject.start_datetime;
          dateBtn[0].style.visibility = "visible";
          dateBtn.html(`<p>${startDateObj} - End Date</p>`);
          if (endDateIsInfinity) {
            datePicker[0].end = moment().add(1, "months").format();
          }
        } else {
          startDateCheck.removeAttr("checked");
          this.postObject.start_datetime = this.postObject.end_datetime = undefined;
          datePicker.attr("start-enabled", "true");
          datePicker.attr("end-enabled", "true");
          datePicker.attr("dual", "true");
          dateBtn[0].style.visibility = "visible";
          dateBtn.html(`<p>Start Date - End Date</p>`);
          if (endDateIsInfinity) {
            datePicker[0].end = moment().add(1, "months").format();
          }
        }
        this.postObject.deal_type = "PG";
        if (!this.postObject.status) {
          inactiveInfoBox.removeAttr("hidden");
        }
        if (mmProvidedDeal.attr("checked")) {
          publisherProvided.attr("checked", "");
          publisherIDInput.removeAttr("disabled");
        }
      } else {
        disabledElements.removeAttr("disabled");
        dateInfoBox.attr("hidden", "");
        inactiveInfoBox.attr("hidden", "");
        if (this.startDatePassed) {
          startDateCheck.attr("disabled", "");
        }
        this.postObject.deal_type = "STANDARD";
      }
      this.showWarningStatus();
    },
    fetchTimeZones: function () {
      var self = this;
      var deferred = $.Deferred();
      var timeZones;
      timeZones = new Timezones();
      timeZones.fetchOptions = {
        full: "*"
      };
      timeZones.isPaginated = false;
      timeZones.fetch({
        success: function (data) {
          self.zones = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchAdvertisers: function (searchTerm, more, agencyId, isAdSearch) {
      var self = this;
      var deferred = $.Deferred();
      var orgs = Organizations.getOrganizations();
      var fetchFunc;
      var fetchOptions = {
        full: "*",
        with: "agency"
      };
      if (!this.advertisers || isAdSearch) {
        this.advertisers = new Advertisers(false, {
          fetchOptions: fetchOptions,
          pageLimit: 100,
          isPaginated: true
        });
      }
      if (isAdSearch) {
        this.advertisers.urlString = `advertisers/limit/agency.organization=${orgs.selected}`;
      } else {
        this.advertisers.urlString = `advertisers/limit/agency=${agencyId}`;
      }
      if (searchTerm && searchTerm !== "") {
        this.advertisers.search.set({
          params: [{
            term: `*${searchTerm}*`,
            searchField: "name"
          }]
        });
      } else if (searchTerm === "") {
        this.advertisers.search.clear();
      }
      fetchFunc = more ? this.advertisers.fetchMore : this.advertisers.fetch;
      fetchFunc.call(this.advertisers, {
        success: function (data) {
          self.advertiserList = data.models;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchAgencies: function (searchTerm, isDealOwner) {
      var self = this;
      var deferred = $.Deferred();
      var orgs = Organizations.getOrganizations();
      var fetchFunc;
      this.agencies = new Agencies(false, {
        pageLimit: 100,
        isPaginated: true,
        fetchOptions: {
          with: ["advertisers"]
        }
      });
      this.agencies.urlString = `agencies/limit/organization=${orgs.selected}`;
      if (searchTerm && searchTerm !== "") {
        this.agencies.search.set({
          params: [{
            term: `*${searchTerm}*`,
            searchField: "name"
          }]
        });
      } else if (searchTerm === "") {
        this.agencies.search.clear();
      }
      fetchFunc = isDealOwner ? this.agencies.fetch : this.agencies.fetchAllPages;
      fetchFunc.call(this.agencies, {
        success: function (data) {
          if (isDealOwner) {
            self.agencyListDealOwner = data.models.filter(function (agency) {
              return !self.partialAgencyList[agency.id];
            });
          } else {
            self.agencyList = data.models;
          }
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchSingleAdvertiser: function (id) {
      var self = this;
      var deferred = $.Deferred();
      var advertiser = new Advertiser({
        id: id || this.dealObject.owner.id
      });
      advertiser.fetch({
        params: {
          with: ["agency", "organization"]
        },
        success: function (data) {
          self.singleAdObject = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchSingleAgency: function () {
      var self = this;
      var deferred = $.Deferred();
      var agency = new Agency({
        id: self.dealObject.owner.id
      });
      agency.fetch({
        success: function (data) {
          self.singleAdObject = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchSupply: function () {
      var self = this;
      var deferred = $.Deferred();
      var supply = new SupplySources(false, {
        fetchOptions: {
          full: "*"
        },
        isPaginated: false
      });
      if (!T1Permissions.check("feature", "source")) {
        supply.search.set({
          params: [{
            searchField: "accountable_and_addressable",
            term: "0"
          }]
        });
      }
      supply.fetch({
        success: function (data) {
          self.supplyList = data.models;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchDeal: function () {
      const self = this;
      const deferred = T1UtilsAsync.makeDeferral();
      const ID_INDEX = 2;
      const ID = Backbone.history.getFragment().split("/")[ID_INDEX];
      if (isNaN(parseInt(ID, 10))) {
        return;
      }
      const dealInfo = new Deal({
        id: ID
      });
      dealInfo.fetch({
        success: function (response) {
          let data, message, status;
          if (typeof response === "string") {
            data = JSON.parse(response);
            if (data.meta.status === "error") {
              status = data.meta.status;
              message = data.errors[0].message;
              T1.Notify(status, message);
            }
          }
          data = response.data;
          self.dealObject = Object.assign({}, data, {
            owner: Object.assign({}, data.owner, {
              type: data.owner.type.toLowerCase()
            })
          });
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    load: function () {
      this.initializeLayout();
      this.render().then(() => {
        this.layout.load().then(() => {
          setTimeout(() => {
            this.loadExecute();
          }, renderDelay);
        });
      });
    },
    loadExecute: function () {
      var self = this;
      var fetchList, orgModel, pathPieces;
      var newDealLabel = this.$(".create-deal-id-label");
      var oldDealLabel = this.$(".edit-deal-id-label");
      var dealIdGroup = this.$(".deal-id-group");
      var datePickr = this.$(".deals-dp");
      var orgID = Organizations.getOrganizations().selected;
      var currencyDrop = this.$(".deal-currency-drop")[0];
      var currencyList = T1.Utils.getCurrencyList().map(obj => ({
        name: obj.text,
        value: obj.value
      }));
      var dealPGCheckbox = this.$(".deal-PG-checkbox");
      var dealPGCheckContainer = this.$(".deal-PG-check-container");
      if (T1Permissions.check("feature", "programmatic_guaranteed_campaign")) {
        dealPGCheckContainer.removeAttr("hidden");
      }
      dealPGCheckbox.attr("disabled", "");
      this.user = User.getUser();
      const partialOrgObj = this.user.attributes.partialEntities.org;
      const partialAgencyObj = this.user.attributes.partialEntities.ag;
      const allAgencyObj = this.user.attributes.entitiesObj.ag;
      this.binList = this.$("#selected-items-bin")[0];
      this.binCollection = [];
      this.userIsGlobal = this.user.attributes.scope === "GLOBAL";
      this.hasFullOrgAccess = partialOrgObj[orgID] === false || this.userIsGlobal;
      this.partialAgencyList = partialAgencyObj;
      this.allUserEntities = this.user.attributes.entitiesObj;
      this.hasAgencies = Object.keys(partialAgencyObj).some(function (k) {
        var isOrg = allAgencyObj[k].organization_id === orgID;
        var notPartial = partialAgencyObj[k] === false;
        var isNotZero = self.allUserEntities.ag[k].value !== "0";
        return self.userIsGlobal || isOrg && notPartial && isNotZero;
      });
      orgModel = new OrganizationModel({
        id: orgID
      });
      orgModel.fetch({
        success: function () {
          self.currentOrgCurrency = orgModel.get("currency_code");
        }
      });
      currencyDrop.set("data", currencyList);
      if (this.isNewDeal) {
        this.postObject.start_datetime = moment().format();
        this.postObject.end_datetime = infinityDate;
        this.postObject.status = true;
        this.postObject.price_method = "CPM";
        this.postObject.currency_code = "USD";
        this.postObject.deal_type = "STANDARD";
        pathPieces = [{
          label: "New Deal",
          location: ""
        }];
        fetchList = [this.fetchTimeZones(), this.fetchSupply()];
      }
      if (this.isEditingDeal) {
        dealIdGroup.attr("hidden", "");
        oldDealLabel.hide();
        newDealLabel.show();
        this.postObject.currency_code = "USD";
        fetchList = [this.fetchTimeZones(), this.fetchSupply(), this.fetchDeal()];
      }
      When.all(fetchList).then(function () {
        T1.EventHub.publish("load:dealData", {
          user: self.user,
          hasFullOrgAccess: self.hasFullOrgAccess,
          hasAgencies: self.hasAgencies,
          isNewDeal: self.isNewDeal,
          isEditingDeal: self.isEditingDeal,
          deal: self.dealObject
        });
        self.setSupplySourceData();
        self.setEntityDropDown();
        if (self.isEditingDeal) {
          self.checkUserAccess();
          dealPGCheckbox.removeAttr("disabled");
          self.populateEditForm();
          pathPieces = [{
            label: self.dealObject.name,
            location: ""
          }];
          T1.EventHub.publish("updateNavPath", {
            locations: pathPieces
          });
        } else {
          self.el.find(".deal-currency-label").text(T1.Utils.getCurrencySymbol(self.currentOrgCurrency));
          currencyDrop.value = self.currentOrgCurrency;
          datePickr[0].set("allowedStart", new Date());
          self.dealDataLoaded = true;
          dealPGCheckbox.removeAttr("disabled");
          T1.EventHub.publish("updateNavPath", {
            locations: pathPieces
          });
        }
      });
    },
    initializeLayout: function () {
      const self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        template: template,
        layout: {
          ".deal-entity-grid-container": [{
            module: "media/pmp-e",
            viewType: "binGrid"
          }]
        }
      });
    },
    setEntityDropDown: function () {
      const $entityTypeDropdown = this.$(".entity-type-dropdown");
      const entityTypeDropdown = $entityTypeDropdown[0];
      const searchPermissionInput = this.$(".deals-search-advertisers");
      if (this.hasFullOrgAccess) {
        entityTypeDropdown.data = [{
          value: dealOwnerTypeMap.organization,
          name: "Organization"
        }, {
          value: dealOwnerTypeMap.agency,
          name: "Agency"
        }, {
          value: dealOwnerTypeMap.advertiser,
          name: "Advertiser"
        }];
        if (this.isNewDeal) {
          entityTypeDropdown.value = dealOwnerTypeMap.agency;
        }
      } else if (this.hasAgencies && !this.hasFullOrgAccess) {
        entityTypeDropdown.data = [{
          value: dealOwnerTypeMap.agency,
          name: "Agency"
        }, {
          value: dealOwnerTypeMap.advertiser,
          name: "Advertiser"
        }];
        if (this.isNewDeal) {
          entityTypeDropdown.value = dealOwnerTypeMap.agency;
        }
      } else {
        entityTypeDropdown.placeholder = "Advertiser";
        searchPermissionInput[0].placeholder = "Advertiser";
        this.dealOwnerType = dealOwnerTypeMap.advertiser;
        entityTypeDropdown.data = [{
          value: dealOwnerTypeMap.advertiser,
          name: "Advertiser"
        }];
        entityTypeDropdown.value = dealOwnerTypeMap.advertiser;
      }
    },
    checkUserAccess: function () {
      const owners = {
        agency: "ag",
        organization: "org",
        advertiser: "adv"
      };
      const dealOwner = this.dealObject.owner;
      const dealOwnerType = owners[dealOwner.type];
      const permToOwner = this.allUserEntities[dealOwnerType][dealOwner.id];
      if (permToOwner !== undefined && permToOwner.value === "0") {
        T1.Location.set("#media/pmp-e");
      }
    },
    updatePermissions: function ({
      permissionedEntities: permissionedEntities
    }) {
      this.permissionedEntities = permissionedEntities;
    },
    setOwner: function (ownerType, entityData) {
      let selectedOrgID;
      if (this.dealOwnerType === dealOwnerTypeMap.organization) {
        const orgs = Organizations.getOrganizations();
        selectedOrgID = orgs.selected;
        const selectedOrg = orgs.get(selectedOrgID);
        this.owner = {
          id: selectedOrg.attributes.id,
          name: selectedOrg.attributes.name,
          type: selectedOrg.attributes.type
        };
      } else {
        this.owner = {
          id: entityData.value,
          name: entityData.name,
          type: ownerType
        };
      }
      T1.EventHub.publish("owner:selected", {
        dealOwner: this.owner
      });
      return selectedOrgID;
    },
    loadPermissionSearch: _.debounce(function (val) {
      var value = val.originalEvent.detail.value;
      var term = value ? value : "";
      var searchTypeCalls;
      term = term.trim().length === 0 ? "" : term;
      switch (this.dealOwnerType) {
        case dealOwnerTypeMap.agency:
          searchTypeCalls = [this.fetchAgencies(term, true)];
          break;
        case dealOwnerTypeMap.advertiser:
          searchTypeCalls = [this.fetchAdvertisers(term, false, false, true)];
          break;
      }
      if (term.length > 0) {
        When.all(searchTypeCalls).then(() => {
          if (!this.userIsGlobal && this.dealOwnerType === dealOwnerTypeMap.agency) {
            this.agencyList = this.agencyListDealOwner.filter(agency => this.allUserEntities.ag[agency.id].value !== "0");
          } else {
            this.agencyList = this.agencyListDealOwner;
          }
          this.populatePermissionList();
        });
      }
    }, searchDebounce),
    populatePermissionList: function () {
      var dropDownList, typeList;
      var dropDown = this.$(".search-advertisers-dropdown");
      var ownerIsOrg = this.dealOwnerType === dealOwnerTypeMap.organization;
      const SETTLE_DELAY = 100;
      if (ownerIsOrg) {
        return;
      }
      switch (this.dealOwnerType) {
        case dealOwnerTypeMap.agency:
          typeList = this.agencyList;
          break;
        case dealOwnerTypeMap.advertiser:
          typeList = this.advertiserList;
          break;
      }
      dropDown.removeAttr("disabled");
      if (typeList.length > 0) {
        dropDownList = typeList.map(function (obj) {
          return {
            value: obj.attributes.id,
            name: `${obj.attributes.name} (${obj.attributes.id})`
          };
        });
      } else {
        dropDownList = [{
          value: "none",
          name: "No Results Found"
        }];
      }
      dropDown[0].set("data", dropDownList);
      dropDown[0].set("state", "opened");
      dropDown[0].async(function () {
        dropDown[0].maxItems = 10;
      }, SETTLE_DELAY);
    },
    populateEditForm: function () {
      let promise;
      const nameInput = this.$(".deal-name-input");
      const dealIdInput = this.$(".publisher-deal-id");
      const adOwner = this.$(".search-advertisers-dropdown");
      const supplySource = this.$(".search-supply-sources-dropdown");
      const activeDeal = this.$(".active-deal");
      const inactiveDeal = this.$(".inactive-deal");
      const currencyDrop = this.$(".deal-currency-drop");
      const dealsPriceInput = this.$(".deal-price-input");
      const descriptionArea = this.$(".deals-text-area");
      const dealsFloor = this.$(".deal-floor");
      const dealsFixed = this.$(".deal-fixed");
      const dateBtn = this.$(".deals-date-btn");
      const date1 = this.$(".date-checkbox1");
      const date2 = this.$(".date-checkbox2");
      const checkBox = this.$(".publisher-deal-id-radio");
      const datePickr = this.$(".deals-dp");
      const loaderEdit = this.$(".deal-edit-loader");
      const entityTypeDropDown = this.$(".entity-type-dropdown");
      const immediateDateLabel = this.$(".immediate-deal");
      const dealPGCheckbox = this.$(".deal-PG-checkbox");
      const dateInfoBox = this.$(".deal-date-PG-info-inline");
      const statusInfoBox = this.$(".deal-PG-inactive-info-inline");
      const disabledElements = this.$(".PG-disabled");
      const orgs = Organizations.getOrganizations();
      const dealObject = this.dealObject;
      const startDate = this.dealObject.start_datetime;
      const startDateObj = new Date(startDate);
      const today = new Date();
      checkBox.attr("disabled", "");
      this.dealOwnerType = dealObject.owner.type;
      if (startDateObj < today) {
        date1.attr({
          checked: "",
          disabled: ""
        });
        datePickr.attr("end-enabled", "true");
        datePickr.attr("start-enabled", "false");
        immediateDateLabel.html("Start Date/Time Passed");
      } else {
        date1.removeAttr("checked");
        datePickr[0].set("allowedStart", today);
      }
      loaderEdit.removeAttr("hidden");
      if (this.dealOwnerType === dealOwnerTypeMap.advertiser) {
        promise = this.fetchSingleAdvertiser();
      } else if (this.dealOwnerType === dealOwnerTypeMap.agency) {
        promise = this.fetchSingleAgency();
      } else if (this.dealOwnerType === dealOwnerTypeMap.organization) {
        this.singleAdObject.attributes = {};
        this.singleAdObject.attributes.id = orgs.selected;
        this.singleAdObject.attributes.name = orgs.get(orgs.selected).attributes.name;
        this.singleAdObject.attributes.type = "organization";
        promise = Promise.resolve();
      }
      promise.then(() => {
        let orgOfDeal;
        const dealTitleEditEl = Polymer.dom(this.el.find(".deal-header")[0]);
        if (this.singleAdObject.attributes.type === "advertiser") {
          orgOfDeal = parseInt(this.singleAdObject.attributes.agency.attributes.organization_id, 10);
        } else if (this.singleAdObject.attributes.type === "agency") {
          orgOfDeal = parseInt(this.singleAdObject.attributes.organization_id, 10);
        } else {
          orgOfDeal = dealObject.owner.id;
        }
        if (parseInt(orgs.selected, 10) !== orgOfDeal) {
          T1.EventHub.publish("dealEdit:populateEditForm", {
            id: orgOfDeal
          });
        }
        loaderEdit.attr("hidden", "");
        dealTitleEditEl.textContent = dealObject.name;
        nameInput[0].value = dealObject.name;
        dealIdInput[0].value = dealObject.deal_identifier;
        adOwner[0].set("data", [{
          value: this.singleAdObject.attributes.id,
          name: `${this.singleAdObject.attributes.name} (${this.singleAdObject.attributes.id})`
        }]);
        supplySource[0].value = this.postObject.supply_source_id = dealObject.supply_source_id;
        adOwner[0].value = this.singleAdObject.attributes.id;
        if (this.dealOwnerType === dealOwnerTypeMap.organization) {
          adOwner.attr("disabled", "");
        }
        entityTypeDropDown[0].value = dealObject.owner.type.charAt(0).toUpperCase() + dealObject.owner.type.slice(1);
        if (dealObject.status === true) {
          activeDeal[0].checked = true;
          inactiveDeal[0].checked = false;
          this.postObject.status = true;
        } else if (dealObject.status === false) {
          this.postObject.status = false;
          activeDeal[0].checked = false;
          inactiveDeal[0].checked = true;
        }
        currencyDrop[0].value = dealObject.price.currency_code;
        dealsPriceInput[0].value = T1Currencies.formatNumber(currencyDrop[0].value, dealObject.price.value);
        if (dealObject.price_type === "FIXED") {
          dealsFixed.attr("checked", "");
          this.postObject.price_type = "FIXED";
        } else if (dealObject.price_type === "FLOOR") {
          dealsFloor.attr("checked", "");
          this.postObject.price_type = "FLOOR";
        }
        if (dealObject.description) {
          descriptionArea[0].value = dealObject.description;
        }
        if (dealObject.deal_type === "PG") {
          dealPGCheckbox.attr("checked", "");
          dealPGCheckbox.attr("disabled", "");
          disabledElements.attr("disabled", "");
          dateInfoBox.removeAttr("hidden");
          this.postObject.deal_type = "PG";
          if (!dealObject.status) {
            statusInfoBox.removeAttr("hidden");
          }
        }
        const endDate = dealObject.end_datetime;
        const endDateObj = new Date(endDate);
        const endDateYear = endDateObj.getFullYear();
        const infinity = new Date(infinityDate).getFullYear();
        dateBtn[0].style.visibility = "visible";
        datePickr[0].start = startDate;
        datePickr[0].end = endDate;
        if (endDateYear !== infinity) {
          dateBtn.html(`<p>${startDateObj} - ${endDateObj}</p>`);
          date2.removeAttr("checked");
        }
        if (endDateYear === infinity && startDateObj > today) {
          datePickr.attr("end-enabled", "false");
          datePickr.attr("start-enabled", "true");
          dateBtn.html(`<p>${startDateObj} - Never</p>`);
        }
        if (date1[0].checked && date2[0].checked) {
          dateBtn[0].style.visibility = "hidden";
        }
        this.postObject.start_datetime = startDate;
        this.postObject.end_datetime = endDate;
        this.dealDataLoaded = true;
      });
    },
    setSupplySourceData: function () {
      const supplyDropDown = this.$(".search-supply-sources-dropdown")[0];
      const disableRubicon = !T1Permissions.check("feature", "rubicon_direct_connect");
      const cleanSupplyList = this.supplyList.filter(obj => ["1", true, "t"].includes(obj.attributes.pmp_enabled) && !(disableRubicon && obj.attributes.id === "196"));
      const supplyDropDownList = cleanSupplyList.map(obj => ({
        value: obj.attributes.id,
        name: obj.attributes.name
      }));
      supplyDropDown.set("data", supplyDropDownList);
    },
    changeDateTimezoneForDatepicker: function (date, timezone) {
      var dt = moment(date);
      var newDt = dt.clone();
      newDt.tz(timezone);
      newDt.add(dt.utcOffset() - newDt.utcOffset(), "minutes");
      return newDt;
    },
    handleDatePicker: function () {
      var endDateObj, newEnddt, newStartdt, startDateObj;
      var nowDateBox = this.$(".date-checkbox1")[0];
      var foreverDateBox = this.$(".date-checkbox2")[0];
      var dateBtn = this.$(".deals-date-btn");
      var datePickr = this.$(".deals-dp");
      dateBtn.removeClass("date-error");
      if (!foreverDateBox.checked && !nowDateBox.checked) {
        newStartdt = this.changeDateTimezoneForDatepicker(datePickr[0].start, datePickr[0].timezone);
        newEnddt = this.changeDateTimezoneForDatepicker(datePickr[0].end, datePickr[0].timezone);
        this.postObject.start_datetime = newStartdt.format();
        this.postObject.end_datetime = newEnddt.format();
        startDateObj = new Date(this.postObject.start_datetime);
        endDateObj = new Date(this.postObject.end_datetime);
        datePickr.attr("start-enabled", "true");
        datePickr.attr("end-enabled", "true");
        datePickr.attr("dual", "true");
        dateBtn[0].style.visibility = "visible";
        dateBtn.html(`<p>${startDateObj} - ${endDateObj}</p>`);
      }
      if (foreverDateBox.checked && !nowDateBox.checked) {
        newStartdt = this.changeDateTimezoneForDatepicker(datePickr[0].start, datePickr[0].timezone);
        this.postObject.start_datetime = newStartdt.format();
        startDateObj = new Date(this.postObject.start_datetime);
        datePickr.attr("end-enabled", "false");
        datePickr.attr("start-enabled", "true");
        dateBtn[0].style.visibility = "visible";
        dateBtn.html(`<p>${startDateObj} - Never</p>`);
      }
      if (!foreverDateBox.checked && nowDateBox.checked) {
        newEnddt = this.changeDateTimezoneForDatepicker(datePickr[0].end, datePickr[0].timezone);
        this.postObject.end_datetime = newEnddt.format();
        startDateObj = new Date(this.postObject.start_datetime);
        endDateObj = new Date(this.postObject.end_datetime);
        datePickr.attr("start-enabled", "false");
        datePickr.attr("end-enabled", "true");
        dateBtn[0].style.visibility = "visible";
        dateBtn.html(`<p>${startDateObj} - ${endDateObj}</p>`);
      }
      this.showWarningStatus();
    },
    handleShowSelectedOnly: function (e) {
      var value = e.target.checked;
      var dealsTree = this.$(".tree-permissions");
      dealsTree[0].set("showSelectedOnly", value);
    },
    handleDates: function () {
      var nowDateBox = this.$(".date-checkbox1")[0];
      var foreverDateBox = this.$(".date-checkbox2")[0];
      var dateBtn = this.$(".deals-date-btn");
      var datePickr = this.$(".deals-dp");
      var endDateObj = new Date(this.postObject.end_datetime);
      var endDateIsInfinity = endDateObj.getFullYear() === new Date(infinityDate).getFullYear();
      dateBtn.removeClass("date-error");
      if (foreverDateBox.checked) {
        this.postObject.end_datetime = infinityDate;
      }
      if (this.isNewDeal) {
        if (nowDateBox.checked) {
          this.postObject.start_datetime = datePickr[0].start = moment().format();
        }
      } else {
        if (new Date(this.dealObject.start_datetime) > new Date() && nowDateBox.checked) {
          this.postObject.start_datetime = datePickr[0].start = moment().format();
        }
      }
      const startDateObj = new Date(this.postObject.start_datetime);
      if (foreverDateBox.checked && nowDateBox.checked) {
        dateBtn[0].style.visibility = "hidden";
      } else {
        dateBtn[0].style.visibility = "visible";
        dateBtn.html(`<p>${startDateObj} - ${endDateIsInfinity ? "Never" : endDateObj}</p>`);
      }
      if (!foreverDateBox.checked && !nowDateBox.checked) {
        datePickr.attr("start-enabled", "true");
        datePickr.attr("end-enabled", "true");
        datePickr.attr("dual", "true");
        if (endDateIsInfinity) {
          datePickr[0].end = moment().add(1, "months").format();
        }
      }
      if (foreverDateBox.checked && !nowDateBox.checked) {
        datePickr.attr("end-enabled", "false");
        datePickr.attr("start-enabled", "true");
        if (endDateIsInfinity) {
          datePickr[0].end = this.postObject.end_datetime;
        }
        dateBtn.html(`<p>${startDateObj} - Never</p>`);
      }
      if (!foreverDateBox.checked && nowDateBox.checked) {
        datePickr.attr("start-enabled", "false");
        datePickr.attr("end-enabled", "true");
        datePickr[0].start = this.postObject.start_datetime;
        if (endDateIsInfinity) {
          datePickr[0].end = moment().add(1, "months").format();
        }
      }
    },
    handleStatus: function () {
      var activeStatus = this.$(".active-deal")[0];
      var inactiveStatus = this.$(".inactive-deal")[0];
      var inactiveInfoBox = this.$(".deal-PG-inactive-info-inline");
      if (activeStatus.checked) {
        this.postObject.status = true;
        if (this.postObject.deal_type === "PG") {
          inactiveInfoBox.attr("hidden", "");
        }
      } else if (inactiveStatus.checked) {
        this.postObject.status = false;
        if (this.postObject.deal_type === "PG") {
          inactiveInfoBox.removeAttr("hidden");
        }
      }
    },
    handlePriceType: function () {
      var dealFloor = this.$(".deal-floor")[0];
      var dealFixed = this.$(".deal-fixed")[0];
      if (dealFloor.checked) {
        this.postObject.price_type = "FLOOR";
      } else if (dealFixed.checked) {
        this.postObject.price_type = "FIXED";
      }
      this.showWarningStatus();
    },
    handleName: function () {
      var nameInput = this.$(".deal-name-input");
      if (nameInput[0].value && nameInput[0].value.trim().length > 0) {
        nameInput.removeAttr("error");
        this.checkError();
      }
      this.postObject.name = nameInput[0].value;
      this.showWarningStatus();
    },
    handleDealId: function () {
      var dealInput = this.$(".publisher-deal-id");
      if (dealInput[0].value && dealInput[0].value.trim().length > 0) {
        dealInput.removeAttr("error");
        this.checkError();
      }
      this.postObject.deal_identifier = dealInput[0].value;
      this.showWarningStatus();
    },
    trimDealId: function () {
      const dealIdInput = this.$(".publisher-deal-id")[0];
      if (dealIdInput.value && dealIdInput.value.trim().length > 0) {
        dealIdInput.value = dealIdInput.value.trim();
        this.postObject.deal_identifier = dealIdInput.value;
      }
    },
    handleSupplyPost: function () {
      var postObject = this.postObject;
      var supplyDrop = this.$(".search-supply-sources-dropdown")[0];
      if (supplyDrop.value) {
        postObject.supply_source_id = supplyDrop.value;
        this.showWarningStatus();
      }
    },
    handleCurrencyPost: function () {
      var currencyDrop = this.$(".deal-currency-drop")[0];
      var currencyLabel = this.$(".deal-currency-label");
      var currentSymbol = T1.Utils.getCurrencySymbol(currencyDrop.value);
      var dealsPriceInput = this.$(".deal-price-input")[0];
      var currency = this.dealObject.price ? this.dealObject.price.currency_code : this.currentOrgCurrency;
      var unformattedNumber = T1Currencies.unformatNumber(currency, dealsPriceInput.value);
      dealsPriceInput.value = T1Currencies.formatNumber(currencyDrop.value, unformattedNumber);
      dealsPriceInput.value = dealsPriceInput.value === "--" ? "" : dealsPriceInput.value;
      if (this.dealObject.price) {
        this.dealObject.price.currency_code = currencyDrop.value;
      } else {
        this.currentOrgCurrency = currencyDrop.value;
      }
      this.postObject.currency_code = currencyDrop.value;
      currencyLabel.text(currentSymbol);
      this.showWarningStatus();
    },
    handleCurrency: function (event) {
      var priceInput = $(event.target);
      var priceInputValue = priceInput.val();
      var currencyCode = this.$(".deal-currency-drop").val();
      var formattedNumber = T1Currencies.formatNumber(currencyCode, T1Currencies.unformatNumber(currencyCode, priceInputValue));
      if (priceInputValue && priceInputValue !== "") {
        priceInputValue = formattedNumber;
      }
      this.isPriceOk = true;
      this.checkError();
      this.postObject.price = T1Currencies.unformatNumber(currencyCode, priceInputValue).toString();
      priceInput.val(priceInputValue);
      this.showWarningStatus();
    },
    handlePrice: function (event) {
      event.preventDefault();
      let key = "";
      let keyIsDecimalSeperator = false;
      let priceInputHasNoDecimal = false;
      let canHaveDecimalSeperator = false;
      let isAnythingSelected = false;
      const priceInput = $(event.target);
      const strandPriceInput = this.$(".deal-price-input");
      let priceInputValue = priceInput.val();
      const priceInputSelectionStart = priceInput[0].selectionStart;
      const priceInputSelectionEnd = priceInput[0].selectionEnd;
      let priceInputSelectionRange = priceInputSelectionStart + 1;
      const keyCode = event.keyCode || event.which;
      const copyPasteText = event.originalEvent.clipboardData ? event.originalEvent.clipboardData.getData("Text") : "";
      const currencyCode = this.$(".deal-currency-drop").val();
      const regularExpression = `[${T1Currencies.getDecimalSeparator(currencyCode)}]`;
      const decimalSeparatorRegularExpression = new RegExp(regularExpression);
      key = keyCode ? String.fromCharCode(keyCode) : copyPasteText;
      keyIsDecimalSeperator = decimalSeparatorRegularExpression.test(key);
      priceInputHasNoDecimal = !decimalSeparatorRegularExpression.test(priceInputValue);
      canHaveDecimalSeperator = keyIsDecimalSeperator && priceInputHasNoDecimal;
      const isNumber = typeof priceInputSelectionStart === "number";
      const hasSelection = priceInputSelectionStart !== priceInputSelectionEnd;
      const hasValue = Boolean(priceInputValue);
      isAnythingSelected = isNumber && hasSelection && hasValue;
      if (canHaveDecimalSeperator) {
        if (isAnythingSelected) {
          priceInputValue = priceInputValue.replace(priceInputValue.slice(priceInputSelectionStart, priceInputSelectionEnd), key);
        } else {
          priceInputValue = priceInputValue.substring(0, priceInputSelectionStart) + key + priceInputValue.substring(priceInputSelectionStart);
        }
        priceInputSelectionRange = priceInputSelectionStart + key.toString().length;
        this.handlePriceError();
      } else {
        key = parseInt(key, 10);
        if (!Number.isNaN(key) && key >= 0) {
          if (isAnythingSelected) {
            priceInputValue = priceInputValue.replace(priceInputValue.slice(priceInputSelectionStart, priceInputSelectionEnd), key);
          } else {
            priceInputValue = priceInputValue.substring(0, priceInputSelectionStart) + key + priceInputValue.substring(priceInputSelectionStart);
          }
          priceInputSelectionRange = priceInputSelectionStart + key.toString().length;
          this.handlePriceError();
        } else {
          priceInputSelectionRange = priceInputSelectionStart;
        }
      }
      strandPriceInput[0].value = priceInputValue;
      priceInput.focus();
      priceInput[0].setSelectionRange(priceInputSelectionRange, priceInputSelectionRange);
    },
    handlePriceError: function () {
      this.$(".deal-price-input").removeAttr("error");
      this.$(".price-error-warning").attr("hidden", "");
      this.checkError();
      this.showWarningStatus();
    },
    isNumeric: function (input) {
      return typeof input === "number" && String(input).trim().length;
    },
    handleDescription: function () {
      var descriptionInput = this.$(".deals-text-area")[0];
      this.postObject.description = descriptionInput.value;
      this.showWarningStatus();
    },
    idCheck: function () {
      const publisherRadio = this.$(".publisher-deal-id-radio");
      const publisherDealIDInput = this.$(".publisher-deal-id");
      if (publisherRadio[0].checked) {
        publisherDealIDInput.removeAttr("disabled");
      } else {
        delete this.postObject.deal_identifier;
        publisherDealIDInput.attr("disabled", "");
        publisherDealIDInput.removeAttr("error");
        publisherDealIDInput[0].value = "";
      }
    },
    showErrorStatus: function () {
      this.setCurrentStatus("error", "There are errors on the page.");
    },
    showWarningStatus: function () {
      if (this.dealPermissionsLoaded && this.dealDataLoaded) {
        this.setCurrentStatus("warning", "There are unsaved changes on the page.");
      }
    },
    showSuccessStatus: function () {
      this.setCurrentStatus("success", "Save Successful.");
    },
    hideStatusMessages: function () {
      this.setCurrentStatus("all");
    },
    setCurrentStatus: function (status, message) {
      const dealsFooter = $(".deals-footer")[0];
      if (status === "all") {
        dealsFooter.hideMessage();
      } else {
        dealsFooter.type = status;
        dealsFooter.message = message;
        dealsFooter.showMessage();
      }
    },
    isFormValid: function () {
      var dealNameInput = this.$(".deal-name-input");
      var publisherInput = this.$(".publisher-deal-id");
      var advertiserInput = this.$(".search-advertisers-dropdown");
      var supplyInput = this.$(".search-supply-sources-dropdown");
      var mmProvidedDeal = this.$(".generated-deal-id-radio");
      var priceInput = this.$(".deal-price-input");
      var validName = dealNameInput[0].value && dealNameInput[0].value.trim().length > 0;
      var validId = publisherInput[0].value && publisherInput[0].value.trim().length > 0 || mmProvidedDeal[0].checked;
      var validOwner = advertiserInput[0].value;
      var validSupply = supplyInput[0].value;
      var validPrice = this.isPriceOk && priceInput[0].value;
      var validDate = this.postObject.start_datetime && this.postObject.end_datetime;
      var neverYear = new Date(infinityDate).getFullYear();
      if (this.postObject.deal_type === "PG" && validDate && new Date(this.postObject.end_datetime).getFullYear() === neverYear) {
        validDate = false;
      }
      return validName && validId && validOwner && validSupply && validPrice && validDate;
    },
    checkError: function () {
      if (this.isFormValid()) {
        this.hideStatusMessages();
      }
    },
    validatePost: function (event) {
      var dealNameInput = this.$(".deal-name-input");
      var publisherInput = this.$(".publisher-deal-id");
      var advertiserInput = this.$(".search-advertisers-dropdown");
      var supplyInput = this.$(".search-supply-sources-dropdown");
      var mmProvidedDeal = this.$(".generated-deal-id-radio");
      var priceInput = this.$(".deal-price-input");
      var postButtonContent = event.originalEvent.target.textContent;
      var priceWarning = this.$(".price-error-warning");
      var currencyDrop = this.$(".deal-currency-drop")[0];
      var saveDealButtons = this.$(".post-deals-close, .post-deals-another");
      const ownerTypeInput = this.$(".entity-type-dropdown");
      const datePicker = this.$(".deals-date-btn");
      const unformatNumber = T1Currencies.unformatNumber;
      const endDateYear = new Date(this.postObject.end_datetime).getFullYear();
      const neverYear = new Date(infinityDate).getFullYear();
      const MAX_NUMBER = 9999999.99;
      if (this.isFormValid() && advertiserInput[0].value !== "none") {
        this.hideStatusMessages();
        this.postDeals(postButtonContent, event);
        T1Spinner.apply($(event.target).addClass("save-action"));
        saveDealButtons.attr("disabled", "disabled");
      } else {
        this.showErrorStatus();
      }
      const numberTooHigh = parseFloat(unformatNumber(currencyDrop.value, priceInput[0].value)) > MAX_NUMBER;
      const numberTooLow = parseFloat(unformatNumber(currencyDrop.value, priceInput[0].value)) < 0;
      const isNotNumber = this.isNumeric(unformatNumber(currencyDrop.value, priceInput[0].value)) === false;
      if (!priceInput[0].value || numberTooHigh || numberTooLow || isNotNumber) {
        priceInput[0].error = true;
        this.isPriceOk = false;
        priceWarning.removeAttr("hidden");
        this.showErrorStatus();
      } else {
        priceInput[0].error = false;
        this.isPriceOk = true;
        priceWarning.attr("hidden", "");
      }
      if (!dealNameInput[0].value || dealNameInput[0].value.trim().length === 0) {
        dealNameInput[0].error = true;
      }
      if (!(mmProvidedDeal[0].checked || publisherInput[0].value)) {
        publisherInput[0].error = true;
      }
      if (!advertiserInput[0].value || advertiserInput[0].value === "none") {
        advertiserInput[0].error = true;
      }
      if (!ownerTypeInput[0].value) {
        ownerTypeInput[0].error = true;
      }
      if (!supplyInput[0].value) {
        supplyInput[0].error = true;
      }
      if (!this.postObject.start_datetime || !this.postObject.end_datetime || this.postObject.deal_type === "PG" && endDateYear === neverYear) {
        datePicker.addClass("date-error");
      }
    },
    filterList: function (obj) {
      const isAgency = obj.type.toLowerCase() === dealOwnerTypeMap.agency;
      const isChecked = obj.selectedState === "checked";
      const hasEntities = this.allUserEntities.ag[obj.id] && this.allUserEntities.ag[obj.id].value !== "0";
      return isAgency && isChecked && (this.userIsGlobal || !this.partialAgencyList[obj.id] && hasEntities);
    },
    filterPartial: function (obj) {
      const isAgency = obj.type === dealOwnerTypeMap.agency;
      const isPartial = obj.selectedState === "partial";
      const id = obj.id;
      const hasEntities = this.allUserEntities.ag[id] && this.allUserEntities.ag[id].value !== "0";
      const userIsGlobal = this.userIsGlobal;
      if (obj.selectedState === "checked") {
        return false;
      }
      return isAgency && (isPartial || !userIsGlobal && this.partialAgencyList[id] || !userIsGlobal && hasEntities);
    },
    postDeals: function (val, event) {
      var self = this;
      var advertiserIdPostList, agencyIdPostList, currentSelection, noSelection, organizationIdPostList;
      var formatPostObject = {};
      var dealPostObject = new Deal();
      var orgs = Organizations.getOrganizations();
      var dealsPriceInput = this.$(".deal-price-input")[0];
      var mmProvidedDeal = this.$(".generated-deal-id-radio")[0].checked;
      var saveDealButtons = this.$(".post-deals-close, .post-deals-another");
      var dealsPGCheck = this.$(".deal-PG-checkbox");
      var partialSelectedAgencies = [];
      if (!mmProvidedDeal) {
        this.trimDealId();
      }
      if (this.postObject.deal_type === "PG") {
        dealsPGCheck.attr("disabled", "");
      }
      if (this.fullPermEdit) {
        currentSelection = this.permissionedEntities;
        noSelection = !currentSelection.length;
        organizationIdPostList = [];
        agencyIdPostList = currentSelection.filter(this.filterList).map(changeIdToInt);
        partialSelectedAgencies = currentSelection.filter(this.filterPartial);
        advertiserIdPostList = currentSelection.filter(obj => obj.type.toLowerCase() === dealOwnerTypeMap.advertiser && (obj.selectedState === "checked" || obj.selected)).map(changeIdToInt);
        for (let i = 0; i < partialSelectedAgencies.length; i++) {
          for (let j = 0; j < partialSelectedAgencies[i].children.length; j++) {
            if (partialSelectedAgencies[i].children[j].selectedState === "checked") {
              advertiserIdPostList.push(parseInt(partialSelectedAgencies[i].children[j].id, 10));
            }
          }
        }
        if (this.dealOwnerType === dealOwnerTypeMap.agency) {
          organizationIdPostList = [];
        } else if (this.dealOwnerType === dealOwnerTypeMap.advertiser) {
          organizationIdPostList = [];
          agencyIdPostList = [];
        } else if (!noSelection && this.dealOwnerType === dealOwnerTypeMap.organization && currentSelection[0].type === dealOwnerTypeMap.organization) {
          organizationIdPostList = [parseInt(orgs.selected, 10)];
          agencyIdPostList = [];
          advertiserIdPostList = [];
        }
      }
      if (this.isEditingDeal) {
        dealPostObject.url = `deals/${this.dealObject.id}`;
      }
      const ownerId = this.dealOwnerType === dealOwnerTypeMap.organization ? orgs.selected : this.postObject.owner_id;
      formatPostObject.owner = {
        type: this.dealOwnerType.toUpperCase(),
        id: parseInt(ownerId, 10)
      };
      if (this.postObject.price_type) {
        formatPostObject.price_type = this.postObject.price_type;
      } else {
        formatPostObject.price_type = "FLOOR";
      }
      if (this.postObject.supply_source_id === "83" && this.isNewDeal) {
        formatPostObject.sub_supply_source_id = 2639;
      }
      if (this.isEditingDeal) {
        if (this.postObject.supply_source_id === "83" && this.dealObject.sub_supply_source_id) {
          formatPostObject.sub_supply_source_id = this.dealObject.sub_supply_source_id;
        } else if (this.postObject.supply_source_id === "83" && !this.dealObject.sub_supply_source_id) {
          formatPostObject.sub_supply_source_id = 2639;
        }
      }
      if (this.postObject.deal_identifier) {
        formatPostObject.deal_identifier = this.postObject.deal_identifier;
      }
      formatPostObject.name = this.postObject.name;
      formatPostObject.status = this.postObject.status;
      formatPostObject.supply_source_id = parseInt(this.postObject.supply_source_id, 10);
      formatPostObject.start_datetime = this.postObject.start_datetime;
      formatPostObject.end_datetime = this.postObject.end_datetime;
      formatPostObject.deal_type = this.postObject.deal_type;
      formatPostObject.price = {
        currency_code: this.postObject.currency_code,
        value: this.postObject.price || T1Currencies.unformatNumber(this.postObject.currency_code, dealsPriceInput.value).toString()
      };
      if (this.fullPermEdit) {
        formatPostObject.permissions = {
          organization_ids: organizationIdPostList,
          agency_ids: agencyIdPostList,
          advertiser_ids: advertiserIdPostList,
          all_organizations: false
        };
      }
      if (this.postObject.description) {
        formatPostObject.description = this.postObject.description;
      }
      dealPostObject.createDeal(formatPostObject, {
        success: function (data) {
          var dealName = data.get("name");
          var saveButton = $(event.target);
          var dealTitleEditEl = Polymer.dom(self.el.find(".deal-header")[0]);
          saveButton.spin(false);
          saveButton.removeClass("save-action");
          saveDealButtons.removeAttr("disabled");
          if (val === "Save & Add Another") {
            T1.Location.set("#media/create/pmp-e", {
              reload: true
            });
            dealTitleEditEl.textContent = "New Deal";
          } else if (val === "Save") {
            T1.EventHub.publish("updateNavPath", {
              locations: [{
                label: dealName,
                location: ""
              }]
            });
            if (self.isEditingDeal) {
              dealsPGCheck.attr("disabled", "");
            }
            T1.Location.set(`#media/edit/${data.get("id")}/pmp-e`);
            dealTitleEditEl.textContent = dealName;
          }
          self.showSuccessStatus();
          T1.Notify("message", "Deal Successfully Saved");
        },
        error: function (model, responseString) {
          const saveButton = $(event.target);
          const publisherInput = self.el.find(".publisher-deal-id");
          const priceValueInput = self.el.find(".deal-price-input");
          const response = JSON.parse(responseString);
          const status = response.meta.status;
          const isDealIdentifierError = status === "error" && response.errors[0].field === "deal_identifier";
          const isPriceValueError = status === "error" && response.errors[0].field === "price.value";
          if (isDealIdentifierError) {
            publisherInput[0].error = true;
          }
          if (isPriceValueError) {
            priceValueInput[0].error = true;
          }
          self.showErrorStatus();
          saveButton.spin(false);
          saveButton.removeClass("save-action");
          dealsPGCheck.removeAttr("disabled");
          saveDealButtons.removeAttr("disabled");
        }
      });
    },
    preparePost: function () {
      this.$(".search-tree")[0].clearInput();
      this.$(".show-selected-checkbox")[0].disabled = false;
      this.agencyList.forEach(agency => {
        if (agency.selectedState === "checked" && this.getAdvertiserCheckState(agency, false)) {
          agency.selectedState = "partial";
        }
      });
      this.populateTree(this.agencyList, "", true);
    },
    cancelPost: function () {
      if (this.isNewDeal && this.showDealGroups) {
        T1.Location.set("#media/pmp-e", {
          viewOptions: {
            showDealGroups: this.showDealGroups
          }
        });
      } else {
        T1.Location.set("#media/pmp-e");
      }
    },
    render: function () {
      var deferred = $.Deferred();
      Polymer.dom(this.el[0]).innerHTML = template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return dealEdit;
});
