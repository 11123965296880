define('modules/media/dealAnalytics/views/dealAnalytics',['require','jQuery','Underscore','T1','T1View','T1Currencies','T1UtilsAsync','T1Permissions','text!../templates/dealAnalytics.html','text!../templates/dealAnalyticsWithMetrics.html','../../pmp-e/helpers/sortDeals','collections/organizations','models/mediaDeal','collections/mediaDeals','collections/mediaMetrics','collections/mediaPerformances'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Currencies = require("T1Currencies");
  const T1UtilsAsync = require("T1UtilsAsync");
  const T1Permissions = require("T1Permissions");
  const template = require("text!../templates/dealAnalytics.html");
  const templateWithMetrics = require("text!../templates/dealAnalyticsWithMetrics.html");
  const SortDeals = require("../../pmp-e/helpers/sortDeals");
  const Organizations = require("collections/organizations");
  const Deal = require("models/mediaDeal");
  const Deals = require("collections/mediaDeals");
  const DealMetrics = require("collections/mediaMetrics");
  const DealPerformances = require("collections/mediaPerformances");
  const apiLimit = 100;
  const searchDelay = 350;
  const scrollDebouceDelay = 400;
  const padding = 25;
  const renderDelay = 50;
  const PRECISION = 2;
  const multiplyByHundred = 100;
  const dealAnalytics = T1View.extend({
    template: template,
    dealNameGet: true,
    dealIdGet: false,
    grid: null,
    modal: null,
    events: {
      "changed .deal-search-select": "handleSearchType",
      "changed .search-deals": "loadSearch",
      "changed .deal-status-select": "handleStatusType",
      "changed .interval-select": "handleInterval",
      "column-sort .deal-analytics-column": "sortDeals",
      "click .export-selected": "handleExportSelected",
      "click .export-all": "handleExportAll",
      "item-selected .deal-analytics-row": "handleSelect",
      "click .deal-item-assignment": "handleShowDealAssignment"
    },
    eventhubEvents: {
      "organization:select": "handleOrgChange"
    },
    initialize: function () {
      this.pageCount = 100;
      this.selected = {};
      if (!T1Permissions.check("feature", "deal_analytics")) {
        T1.Location.set("/#media/pmp-e");
      }
      this.showDealAnalyticsEnhancements = T1Permissions.check("feature", "deals-analytics-grid-enhancements");
      this.template = this.showDealAnalyticsEnhancements ? templateWithMetrics : template;
    },
    load: function () {
      const orgs = Organizations.getOrganizations();
      this.orgId = orgs.selected;
      this.selectedOrg = orgs._byId[Number(orgs.selected)];
      this.timeInterval = localStorage.timeInterval || "last_7_days";
      this.render().then(() => {
        const timeInterval = this.$(".interval-select")[0];
        if (T1Permissions.check("feature", "deal_analytics_export")) {
          this.$(".choose-action").removeAttr("hidden");
        }
        timeInterval.value = this.timeInterval;
        this.allSearchGet = true;
        this.setStatus();
        setTimeout(() => {
          const selectAllCheck = this.$("#selectAll");
          selectAllCheck.attr("hidden", "");
        }, renderDelay);
        this.grid = this.$(".deal-analytics-grid")[0];
        this.modal = this.$("#assignments-modal")[0];
        this.getDealFetch().then(parsedDeals => {
          this.enableScroll();
          this.grid.data = parsedDeals;
          this.grid.isLoading = false;
        });
      });
    },
    handleSearchType: function () {
      var searchIdName = this.$(".deal-search-select")[0];
      var searchValue = this.$(".search-deals")[0];
      if (searchIdName.value === "name") {
        this.dealNameGet = true;
        this.dealIdGet = false;
      } else if (searchIdName.value === "id") {
        this.dealIdGet = true;
        this.dealNameGet = false;
      }
      this.searchTerm = "";
      searchValue.clearInput();
    },
    handleStatusType: function (val) {
      if (val.originalEvent.detail.value === "all") {
        this.inactiveStatusGet = false;
        this.activeStatusGet = false;
        this.allStatusGet = true;
      } else if (val.originalEvent.detail.value === "active") {
        this.inactiveStatusGet = false;
        this.activeStatusGet = true;
        this.allStatusGet = false;
      } else if (val.originalEvent.detail.value === "inactive") {
        this.inactiveStatusGet = true;
        this.activeStatusGet = false;
        this.allStatusGet = false;
      }
      this.getDealFetch(this.searchTerm).then(parsedDeals => {
        this.grid.sortField = "";
        this.updateGrid(parsedDeals, 0, true);
      });
    },
    handleInterval: function (val) {
      this.timeInterval = val.originalEvent.detail.value;
      localStorage.setItem("timeInterval", this.timeInterval);
      this.getDealFetch(this.searchTerm).then(parsedDeals => {
        this.grid.sortField = "";
        this.updateGrid(parsedDeals, 0, true);
      });
    },
    handleExportSelected: function (event) {
      if (!Object.keys(this.selected).length) {
        T1.Notify("error", "Please select some deals first");
        return;
      }
      if (event.target.className.includes("option-disabled")) {
        return;
      }
      this.fetchDealExport();
    },
    handleExportAll: function () {
      if (event.target.className.includes("option-disabled")) {
        return;
      }
      this.fetchDealExport(true);
    },
    handleSelect(event) {
      const model = event.originalEvent.detail;
      const selected = model.selected;
      const modelId = model.deal_id ? model.deal_id : model.id;
      const bulkActivate = this.$(".bulk-activate");
      const bulkDeactivate = this.$(".bulk-deactivate");
      if (selected) {
        this.selected[modelId] = model;
        if (model.status) {
          bulkActivate.addClass("option-disabled");
          this.activateDisabled = true;
        } else {
          bulkDeactivate.addClass("option-disabled");
          this.deactivateDisabled = true;
        }
      } else {
        delete this.selected[modelId];
        if (model.status) {
          if (Object.values(this.selected).every(selectedModel => !selectedModel.status)) {
            bulkActivate.removeClass("option-disabled");
            this.activateDisabled = false;
          }
        } else {
          if (Object.values(this.selected).every(selectedModel => selectedModel.status)) {
            bulkDeactivate.removeClass("option-disabled");
            this.deactivateDisabled = false;
          }
        }
      }
    },
    handleShowDealAssignment: function (event) {
      event.preventDefault();
      const $target = $(event.target);
      const $gridRow = $target.closest("strand-grid-item");
      const model = $gridRow[0].model;
      if (model.assigned) {
        if (this.modal) {
          let dialogText = '<div class="loadContainer"><strand-loader><label>Loading, please wait..</label></strand-loader></div>';
          this.modal.$.dialogMessage.innerHTML = dialogText;
          this.fetchDealStrategyCampaignMapping(model.id).then(mappingData => {
            dialogText = "";
            Object.keys(mappingData).forEach(campaignID => {
              const campaign = mappingData[campaignID];
              const strategies = campaign.strategies;
              const campName = T1.Utils.escapeHTML(campaign.name);
              dialogText += `<strand-header size='medium'>${campName} (<strand-action href="#campaign/edit/${campaign.id}/strategies" external><label>${campaign.id}</label></strand-action>)</strand-header>`;
              dialogText += `<ul>`;
              strategies.forEach(strategy => {
                const stratName = T1.Utils.escapeHTML(strategy.name);
                dialogText += `<li class='strategy'>${stratName} (<strand-action href="#strategy/edit/${strategy.id}" external><label>${strategy.id}</label></strand-action>)</li>`;
              });
              dialogText += `</ul><br />`;
            });
            this.modal.$.dialogMessage.innerHTML = dialogText;
          });
          this.modal.show();
        }
      }
    },
    handleOrgChange: function (val) {
      const searchInput = this.el.find(".search-deals");
      const orgs = Organizations.getOrganizations();
      this.orgId = val.id;
      this.selectedOrg = orgs._byId[Number(orgs.selected)];
      searchInput[0].value = "";
      this.grid.sortField = "";
      this.getDealFetch().then(parsedDeals => {
        this.grid.data = parsedDeals;
        this.grid.isLoading = false;
      });
    },
    fetchDealStrategyCampaignMapping: function (dealID) {
      const deferred = T1UtilsAsync.makeDeferral();
      const dealInfo = new Deal({
        id: dealID
      });
      dealInfo.fetch({
        params: {
          active: 1,
          with: ["strategy,campaign"]
        },
        action: "strategy_deals",
        success: data => {
          const mappingData = {};
          const dealData = data.data;
          Object.keys(dealData).forEach(strategy_deal_id => {
            const strategyDeal = dealData[strategy_deal_id];
            const strategy = strategyDeal.strategy;
            const campaign = strategy.campaign;
            if (!mappingData[campaign.id]) {
              mappingData[campaign.id] = {
                id: campaign.id,
                name: campaign.name
              };
            }
            if (!mappingData[campaign.id].strategies) {
              mappingData[campaign.id].strategies = [];
            }
            mappingData[campaign.id].strategies.push({
              id: strategy.id,
              name: strategy.name
            });
          });
          deferred.resolve(mappingData);
        }
      });
      return deferred.promise;
    },
    getDealFetch: function (args) {
      this.grid.isLoading = true;
      this.grid.noResultsMessage = "";
      return this.showDealAnalyticsEnhancements ? this.fetchDealsWithAnalytics.bind(this)(args) : this.fetchDealsWithMetrics.bind(this)(args);
    },
    fetchDealsWithAnalytics: function (searchTerm, add) {
      const deferred = T1UtilsAsync.makeDeferral();
      let fetchOptions, qUrl;
      if (searchTerm) {
        qUrl = this.populateQUrl(searchTerm);
        fetchOptions = {
          "owner.organization_id": this.orgId,
          q: qUrl
        };
      } else {
        fetchOptions = {
          "owner.organization_id": this.orgId
        };
      }
      if (this.activeStatusGet) {
        fetchOptions.status = "true";
      } else if (this.inactiveStatusGet) {
        fetchOptions.status = "false";
      }
      if (this.timeInterval) {
        fetchOptions.time_window = this.timeInterval;
      }
      if (!add) {
        if (this.collection) {
          this.collection.abort();
        }
        this.collection = new Deals(false, {
          fetchOptions: fetchOptions,
          pageLimit: this.pageCount,
          isPaginated: true
        });
      }
      this.grid.isLoading = true;
      this.collection.fetch({
        add: add,
        success: (parsed, data) => {
          deferred.resolve(this.parseDealsWithAnalytics(data.deal_id ? data.deal_id : data.data));
        },
        analytics: true
      });
      return deferred.promise;
    },
    fetchMoreDealsWithAnalytics: _.debounce(function (index) {
      const deferred = T1UtilsAsync.makeDeferral();
      const searchTerm = this.searchTerm ? this.searchTerm : "";
      const gridBottomIndex = index + padding;
      const size = this.pageCount;
      const page = Math.floor(gridBottomIndex / size);
      if (this.grid.data[page * apiLimit] || this.grid.isLoading) {
        return;
      }
      this.collection.page = page;
      this.fetchDealsWithAnalytics(searchTerm, true).then(parsedDeals => {
        this.updateGrid(parsedDeals, page);
      });
      return deferred.promise;
    }, scrollDebouceDelay),
    parseDealsWithAnalytics: function (dealsData) {
      const parsedData = [];
      const formatOutput = (value, formatFunc) => {
        if (value !== undefined && value !== null && value !== "N/A") {
          return formatFunc(value);
        }
        return value;
      };
      const mapDealData = (deal, deal_id) => {
        const price_value = deal.price;
        const price_display = T1Currencies.formatNumber(deal.currency, price_value);
        const currency_symbol = T1.Utils.getCurrencySymbol(deal.currency);
        const price = `${currency_symbol}${price_display} ${deal.price_type}`;
        const winRate = formatOutput(deal.win_rate, () => `${this.formatToPercent(deal.win_rate)}%`);
        const bidRate = formatOutput(deal.bid_rate, () => `${this.formatToPercent(deal.bid_rate)}%`);
        const matchRate = formatOutput(deal.match_rate, () => `${this.formatToPercent(deal.match_rate)}%`);
        const spend = formatOutput(deal.total_spend, () => `${currency_symbol}${this.formatNumber(this.formatPrecision(deal.total_spend))}`);
        const viewabilityRate = formatOutput(deal.viewability_rate, () => `${this.formatPrecision(deal.viewability_rate)}%`);
        const ecpm = formatOutput(deal.eCPM, () => `${currency_symbol}${this.formatNumber(this.formatPrecision(deal.eCPM))}`);
        const ecpa = formatOutput(deal.eCPA, () => `${currency_symbol}${this.formatNumber(this.formatPrecision(deal.eCPA))}`);
        parsedData.push({
          name: deal.name,
          id: deal_id,
          deal_identifier: deal.deal_identifier,
          status: deal.status,
          bids: this.formatNumber(deal.bids),
          bid_rate: bidRate,
          biddables: this.formatNumber(deal.biddables),
          matched_bid_opportunities: this.formatNumber(deal.matched_bid_opportunities),
          match_rate: matchRate,
          win_rate: winRate,
          wins: this.formatNumber(deal.wins),
          price: price,
          price_value: price_value,
          currency_symbol: currency_symbol,
          spend: spend,
          impressions: this.formatNumber(deal.impressions),
          viewability_rate: viewabilityRate,
          ecpm: ecpm,
          ecpa: ecpa,
          strategies_count: this.formatNumber(deal.strategies_count),
          assigned: deal.strategies_count && deal.strategies_count > 0
        });
      };
      if (dealsData) {
        if (Array.isArray(dealsData)) {
          dealsData.forEach(deal => {
            mapDealData(deal, deal.id);
          });
        } else {
          Object.keys(dealsData).forEach(deal_id => {
            mapDealData(dealsData[deal_id], deal_id);
          });
        }
      }
      return parsedData;
    },
    fetchDealsWithMetrics: function (searchTerm) {
      const deferred = T1UtilsAsync.makeDeferral();
      this.fetchDeals(searchTerm).then(() => {
        if (this.currentDealData.length === 0) {
          this.updateGrid(this.currentDealData);
        } else {
          this.fetchDealMetrics(this.currentDealData).then(() => {
            this.fetchDealPerformance(this.currentDealData).then(() => {
              this.parseDealMetricsData();
              deferred.resolve(this.parsedData);
            });
          });
        }
      });
      return deferred.promise;
    },
    fetchMoreDealsWithMetrics: _.debounce(function (index) {
      const deferred = T1UtilsAsync.makeDeferral();
      const searchTerm = this.searchTerm ? this.searchTerm : "";
      const gridBottomIndex = index + padding;
      const size = this.pageCount;
      const page = Math.floor(gridBottomIndex / size);
      if (this.grid.data[page * apiLimit] || this.grid.isLoading) {
        return;
      }
      this.grid.isLoading = true;
      this.fetchMoreDeals(searchTerm, page).then(() => {
        if (this.currentDealData.length === 0) {
          this.updateGrid(this.currentDealData);
        } else {
          this.fetchDealMetrics(this.currentDealData).then(() => {
            this.fetchDealPerformance(this.currentDealData).then(() => {
              this.parseDealMetricsData();
              this.grid.isLoading = false;
              this.grid.splice(...["data", this.grid.data.length, 0].concat(this.parsedData));
              deferred.resolve();
            });
          });
        }
      });
      return deferred.promise;
    }, scrollDebouceDelay),
    fetchDeals: function (searchTerm) {
      const deferred = T1UtilsAsync.makeDeferral();
      let fetchOptions, qUrl;
      if (searchTerm) {
        qUrl = this.populateQUrl(searchTerm);
        fetchOptions = {
          "owner.organization_id": this.orgId,
          q: qUrl
        };
      } else {
        fetchOptions = {
          "owner.organization_id": this.orgId
        };
      }
      if (this.activeStatusGet) {
        fetchOptions.status = "true";
      } else if (this.inactiveStatusGet) {
        fetchOptions.status = "false";
      }
      this.collection = new Deals(false, {
        fetchOptions: fetchOptions,
        pageLimit: this.pageCount,
        isPaginated: true
      });
      this.collection.fetch({
        success: (parsed, data) => {
          this.grid.set("data", new Array(data.data.length));
          this.grid.isLoading = true;
          this.dealMeta = data;
          this.dealGridData = this.currentDealData = data.data;
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    fetchMoreDeals: function (searchTerm, page) {
      const deferred = T1UtilsAsync.makeDeferral();
      this.collection.page = page;
      this.collection.fetch({
        add: true,
        success: (parsed, data) => {
          this.dealMeta = data;
          this.dealGridData = this.dealGridData ? this.dealGridData.concat(data.data) : data.data;
          this.currentDealData = data.data;
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    parseDealMetricsData: function () {
      this.parsedData = [];
      this.currentDealData.forEach(deal => {
        const price_value = deal.price.value;
        const price_display = T1Currencies.formatNumber(deal.price.currency_code, price_value);
        const currency_symbol = T1.Utils.getCurrencySymbol(deal.price.currency_code);
        const price = `${currency_symbol}${price_display} ${deal.price_type}`;
        let hasBeenAdded = false;
        this.dealMetrics.models.forEach(metrics => {
          if (deal.id === Number(metrics.deal_id)) {
            const winRate = `${this.formatPrecision(metrics.win_rate)}%`;
            const bidRate = `${this.formatPrecision(metrics.bid_rate)}%`;
            this.parsedData.push({
              name: deal.name,
              id: deal.id,
              deal_identifier: deal.deal_identifier,
              status: deal.status,
              bids: this.formatNumber(metrics.bids),
              bid_rate: bidRate,
              biddables: this.formatNumber(metrics.biddables),
              win_rate: winRate,
              wins: this.formatNumber(metrics.wins),
              price: price,
              price_value: price_value,
              currency_symbol: currency_symbol
            });
            hasBeenAdded = true;
          }
        });
        if (!hasBeenAdded) {
          this.parsedData.push({
            name: deal.name,
            id: deal.id,
            deal_identifier: deal.deal_identifier,
            status: deal.status,
            bids: "N/A",
            bid_rate: "N/A",
            biddables: "N/A",
            win_rate: "N/A",
            wins: "N/A",
            price: price,
            price_value: price_value,
            currency_symbol: currency_symbol
          });
        }
      });
      this.parsedData.map(deal => {
        let hasBeenUpdated = false;
        let updatedDeal;
        this.dealPerformances.models.forEach(performance => {
          if (deal.id === Number(performance.deal_id)) {
            let dealECPA, dealECPM;
            const oneThousand = 1e3;
            const spend = `${deal.currency_symbol}${this.formatNumber(this.formatPrecision(performance.total_spend))}`;
            hasBeenUpdated = true;
            if (Number(performance.impressions) !== 0) {
              dealECPM = performance.total_spend / (performance.impressions / oneThousand);
            } else {
              dealECPM = 0;
            }
            if (Number(performance.total_conversions) !== 0) {
              dealECPA = performance.total_spend / performance.total_conversions;
            } else {
              dealECPA = 0;
            }
            const ecpm = `${deal.currency_symbol}${this.formatNumber(this.formatPrecision(dealECPM))}`;
            const ecpa = `${deal.currency_symbol}${this.formatNumber(this.formatPrecision(dealECPA))}`;
            updatedDeal = $.extend(true, deal, {
              spend: spend,
              impressions: this.formatNumber(performance.impressions),
              ecpm: ecpm,
              ecpa: ecpa
            });
          }
        });
        if (!hasBeenUpdated) {
          updatedDeal = $.extend(true, deal, {
            spend: "N/A",
            impressions: "N/A",
            ecpm: "N/A",
            ecpa: "N/A"
          });
        }
        return updatedDeal;
      });
    },
    fetchDealMetrics: function (deals) {
      const deferred = T1UtilsAsync.makeDeferral();
      const formattedDealIds = this.formatDealIds(deals);
      const dealMetrics = new DealMetrics(false, {
        fetchOptions: {
          filter: `organization_id=${this.orgId}&deal_id=${formattedDealIds}`,
          time_window: this.timeInterval,
          time_rollup: "all",
          dimensions: "deal_id"
        }
      });
      if (T1Permissions.check("feature", "deals-reporting-endpoint")) {
        dealMetrics.apiRoot = "/reporting/v1/std/";
      }
      dealMetrics.fetchAllPages({
        success: data => {
          this.dealMetrics = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    fetchDealPerformance: function (deals) {
      const deferred = T1UtilsAsync.makeDeferral();
      const formattedDealIds = this.formatDealIds(deals);
      const dealPerformances = new DealPerformances(false, {
        fetchOptions: {
          filter: `organization_id=${this.orgId}&deal_id=${formattedDealIds}`,
          time_window: this.timeInterval,
          time_rollup: "all",
          dimensions: "deal_id"
        }
      });
      if (T1Permissions.check("feature", "deals-reporting-endpoint")) {
        dealPerformances.apiRoot = "/reporting/v1/std/";
      }
      dealPerformances.fetchAllPages({
        success: data => {
          this.dealPerformances = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    fetchDealExport: function (exportAll) {
      const deferred = T1UtilsAsync.makeDeferral();
      const el = this.el[0];
      const spinner = this.$(".deals-export-loader");
      spinner.removeAttr("hidden");
      const fetchOptions = {
        "owner.organization_id": this.orgId,
        export: "true",
        time_window: this.timeInterval
      };
      if (!exportAll) {
        const ids = Object.keys(this.selected).join(",");
        fetchOptions.ids = ids;
      } else {
        if (this.activeStatusGet) {
          fetchOptions.status = "true";
        } else if (this.inactiveStatusGet) {
          fetchOptions.status = "false";
        }
      }
      const exportData = new Deals(false, {
        fetchOptions: fetchOptions
      });
      exportData.isPaginated = false;
      exportData.fetch({
        success: (parsed, csvContent) => {
          let jsonContent;
          try {
            jsonContent = JSON.parse(csvContent);
          } catch (e) {}
          if (jsonContent && jsonContent.status === "Not Found") {
            T1.Notify("warning", jsonContent.message);
          } else {
            const blob = new Blob([csvContent], {
              type: "attachment/csv"
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            const timeString = this.getFormattedTime();
            const timeWindow = this.timeInterval;
            const prefix = exportAll ? "all" : "selected";
            const orgName = this.selectedOrg.get("name");
            if (exportAll) {
              let status = "allstatus";
              if (this.activeStatusGet) {
                status = "active";
              } else if (this.inactiveStatusGet) {
                status = "inactive";
              }
              a.download = `${orgName}_${this.orgId}_${status}_${prefix}_deal_analytics_${timeWindow}_${timeString}.csv`;
            } else {
              a.download = `${orgName}_${this.orgId}_${prefix}_deal_analytics_${timeWindow}_${timeString}.csv`;
            }
            a.href = url;
            el.appendChild(a);
            a.click();
            el.removeChild(a);
            T1.Notify("message", "Deal Analytics Export Completed");
          }
          spinner.attr("hidden", "");
          deferred.resolve();
        },
        analytics: true
      });
      return deferred.promise;
    },
    getFormattedTime: function (timeInput) {
      const time = timeInput || new Date(Date.now());
      const formattedTime = moment(time).format("YYYY-MM-DD_HH-mm-ss");
      return formattedTime;
    },
    enableScroll: function () {
      const dealGridHelper = this.$(".grid-helper");
      if (dealGridHelper[0]) {
        dealGridHelper[0].callback = this.showDealAnalyticsEnhancements ? this.fetchMoreDealsWithAnalytics.bind(this) : this.fetchMoreDealsWithMetrics.bind(this);
      }
    },
    sortDeals: function (event) {
      const sortField = event.originalEvent.detail.field;
      const sortOrder = event.originalEvent.detail.val;
      const gridData = SortDeals.sortMetricsData(this.grid.data, sortField, sortOrder);
      this.grid.splice(...["data", 0, gridData.length].concat(gridData));
    },
    updateGrid: function (array, page = 0, replaceData = false) {
      if (replaceData) {
        this.grid.set("data", array);
      } else {
        this.grid.splice(...["data", page, this.apiLimit].concat(array));
      }
      this.grid.isLoading = false;
      this.grid.noResultsMessage = page === 0 && array.length === 0 ? "No deals available." : "";
    },
    populateQUrl: function (searchTerm) {
      var qUrl;
      if (searchTerm && searchTerm.length) {
        if (this.dealNameGet) {
          qUrl = `name=:*${searchTerm}*`;
        } else if (this.dealIdGet) {
          qUrl = this.showDealGroups ? `id=:*${searchTerm}*` : `deal_identifier=:*${searchTerm}*`;
        }
      }
      return qUrl;
    },
    formatPrecision: value => (typeof value === "string" ? Number(value) : value).toFixed(PRECISION),
    formatToPercent: value => (typeof value === "string" ? Number(value) * multiplyByHundred : value * multiplyByHundred).toFixed(PRECISION),
    formatNumber(num) {
      const num_parts = num.toString().trim().split(".");
      num_parts[0] = num_parts[0].replace(/(.)(?=(\d{3})+$)/g, "$1,");
      return num_parts.join(".");
    },
    formatDealIds: function (dealIds) {
      return dealIds.map(deal => deal.id);
    },
    loadSearch: _.debounce(function (val) {
      const minSearchReq = 2;
      this.searchTerm = val.originalEvent.detail.value;
      this.grid.isLoading = true;
      if (!this.dealNameGet && !this.dealIdGet && (this.activeStatusGet || this.inactiveStatusGet)) {
        this.dealNameGet = true;
      }
      if (this.searchTerm === "" || this.searchTerm === null) {
        this.$(".search-warning-deals").attr("hidden", "");
        this.$(".search-deals").removeAttr("error");
        this.getDealFetch("").then(parsedDeals => {
          this.updateGrid(parsedDeals, 0, true);
        });
      } else if (this.searchTerm.length > 0 && this.searchTerm.length < minSearchReq && this.searchTerm !== " ") {
        this.$(".search-warning-deals").removeAttr("hidden");
        this.$(".search-deals").attr("error", "");
        this.grid.isLoading = false;
      } else if (this.searchTerm.trim().length === 0) {
        this.grid.isLoading = false;
      } else {
        this.$(".search-warning-deals").attr("hidden", "");
        this.$(".search-deals").removeAttr("error");
        this.getDealFetch(this.searchTerm).then(parsedDeals => {
          this.updateGrid(parsedDeals, 0, true);
          this.grid.sortField = "";
        });
      }
    }, searchDelay),
    setStatus: function () {
      const status = this.$(".deal-status-select")[0].value;
      switch (status) {
        case "all":
          this.activeStatusGet = false;
          this.allStatusGet = true;
          this.inactiveStatusGet = false;
          break;
        case "inactive":
          this.activeStatusGet = false;
          this.allStatusGet = false;
          this.inactiveStatusGet = true;
          break;
        default:
          this.activeStatusGet = true;
          this.allStatusGet = false;
          this.inactiveStatusGet = false;
          break;
      }
    },
    render: function () {
      var deferred = $.Deferred();
      Polymer.dom(this.el[0]).innerHTML = this.template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return dealAnalytics;
});
