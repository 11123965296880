define('models/audienceProvider',["T1", "Backbone", "T1Model"], function (T1, Backbone, T1Model) {
  "use strict";

  T1.Models.AudienceProvider = T1Model.extend({
    url: "audience_segments/",
    fixture: "-audience_segments",
    initialize: function (options) {
      var self = this;
      var prefSegments = (COMPASS_ENV.PREFERRED_AUDIENCE_VENDORS || []).concat([]);
      T1Model.prototype.initialize.call(self, options);
      if (prefSegments.length > 0) {
        prefSegments.reverse().forEach(function (preferredSegment, i) {
          if (self.get("audience_vendor_id") === preferredSegment && !self.get("parent_audience_segment_id")) {
            self.set({
              preferred: i + 1
            });
          }
        });
      }
    }
  });
  return T1.Models.AudienceProvider;
});
