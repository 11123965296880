define('modules/admin/vendors/views/createEdit',['require','Underscore','jQuery','text!modules/admin/vendors/templates/createEditBody.html','text!modules/admin/templates/header.html','models/organization','collections/organizations','T1','T1Layout','T1Permissions','T1View','text!modules/admin/vendors/templates/createEdit.html','utils/TimezoneUtils','utils/AdminUtils','models/vendorAdmin','When'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const bodyTemplate = require("text!modules/admin/vendors/templates/createEditBody.html");
  const headTemplate = require("text!modules/admin/templates/header.html");
  const Organization = require("models/organization");
  const Organizations = require("collections/organizations");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!modules/admin/vendors/templates/createEdit.html");
  const TimezoneUtils = require("utils/TimezoneUtils");
  const Utils = require("utils/AdminUtils");
  const Vendor = require("models/vendorAdmin");
  const When = require("When");
  var timezone = TimezoneUtils.getClientTimezoneAbbr();
  var convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
  var dateFormat = "MM/dd/yyyy hh:mm TT ";
  var VendorCreateEdit;
  var orgs = Organizations.getOrganizations();
  VendorCreateEdit = T1View.extend({
    defaultFormat: "domains",
    defaultMode: "add",
    isEdit: false,
    template: template,
    partials: {
      header: headTemplate,
      body: bodyTemplate
    },
    events: {
      "changed .adx_declaration": "RTBRequired",
      "changed .mm_contract": "displayMMResell",
      "click .special_detection": "specialDetection",
      "mouseout .error": "hideTooltip",
      "mouseover .error": "showTooltip"
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveAndNew"
      }
    },
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 8,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    eventhubEvents: {
      "organization:select": "handleOrgChange"
    },
    initialize(args) {
      var formActions = [];
      var actionButtons = {
        cancel: {
          class: "cancel",
          label: "cancel",
          location: "#admin/vendors",
          uuid: "cancel"
        },
        save: {
          class: "save save-btn-secondary",
          label: "save",
          location: false,
          uuid: "save"
        },
        saveAndClose: {
          class: "saveAndClose save-btn-primary",
          label: "save & close",
          location: false,
          uuid: "save_close"
        }
      };
      if (args.id) {
        this.setEditMode(args.id);
      }
      this.canLoad = false;
      this.canSave = true;
      formActions.push(actionButtons.cancel);
      if (this.isEdit) {
        formActions.push(actionButtons.save);
      }
      formActions.push(actionButtons.saveAndClose);
      if (!this.isEdit) {
        formActions.push(actionButtons.saveAndNew);
      }
      this.actionsConfig.formActions = formActions;
      this.updateModel(args);
    },
    updateModel(args) {
      var self = this;
      var model = this.model || new Vendor({});
      var deferred = When.defer();
      var vendor = {};
      this.model = model;
      if (args.id) {
        vendor = new Vendor({
          id: args.id
        });
        this.setEditMode(args.id);
        vendor.fetch({
          success: function (data) {
            self.model = data;
            model = self.model;
            model.isEdit = self.isEdit;
            Utils.fetchData(Organization, model.get("organization_id")).then(function () {
              self.canLoad = true;
              Utils.updateBreadCrumb("Vendor", model.toJSON());
              self.initLayout();
              self.load();
              deferred.resolve(model);
            });
          }
        });
      } else {
        this.model = new Vendor({
          new: true,
          is_eligible: "0",
          name: "",
          type: "vendor"
        });
        model = this.model;
        this.canLoad = true;
        this.initLayout();
        Utils.updateBreadCrumb("Vendor", model.toJSON());
        deferred.resolve(model);
      }
      return deferred.promise;
    },
    initLayout() {
      var self = this;
      var model = this.model;
      var organization_id = model.get("organization_id");
      var createEditLayout = {};
      if (!this.canLoad) {
        return;
      }
      orgs.getSelected().then($.proxy(function (orgId) {
        self.org = orgId;
        if (!self.isEdit) {
          createEditLayout[".organization-select"] = [{
            module: "shared",
            viewType: "select",
            options: {
              clearOnClose: true,
              dataBind: "organization_id",
              entity: "organizations",
              fetchSelected: true,
              initialLoad: true,
              isSearchable: true,
              notFound: "No Organization Found",
              placeholder: "Select an Organization",
              preselect: "Select an Organization",
              selectedId: organization_id || orgId,
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              }
            }
          }];
        }
      }, this));
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: "_wrapper",
        template: template,
        serialize: $.proxy(self.serialize, self),
        layout: createEditLayout
      });
    },
    load() {
      if (this.canLoad) {
        this.render().then(() => {
          this.layout.load();
          this.cacheElems();
        });
      }
    },
    cacheElems() {
      this.$content = this.$('[data-bind="content"]');
      this.$domains = this.$('[data-bind="domains"]');
      this.$saveButtons = this.$(".save-btn-primary,.save-btn-secondary");
      this.$spinnerTarget = this.$(".icon-spinner");
      this.$domainBtn = this.$('[value="domains"]');
      this.$domainsPanel = this.$(".domains");
      this.elemsCached = true;
    },
    toggleSaveButtons(enable) {
      this.$saveButtons.removeClass("disabled");
      if (!enable) {
        this.$saveButtons.addClass("disabled");
      }
    },
    postData(formValues, success, statusInvalid, conflict) {
      var self = this;
      var model = this.model;
      model.createEdit(formValues, {
        success: function (returnedModel) {
          var data = returnedModel.entity ? returnedModel.entity.prop : [];
          var output = {};
          var siteCountMsg = [];
          data.push({
            name: "version",
            value: returnedModel.attributes.version
          });
          $.each(data, function (i, o) {
            output[o.name] = o.value;
          });
          siteCountMsg.push(`Vendor ${self.isEdit ? "updated" : "created"} successfully!`);
          T1.Notify("message", siteCountMsg.join("<br />"));
          model.set(output);
          self.isFormElementsChanged = false;
          success(returnedModel);
        },
        statusInvalid: function (errors) {
          if (errors && errors.length) {
            _.map(errors, function (error) {
              error.field = error.field === "csv" ? "csverror" : error.field;
              return error;
            });
          }
          statusInvalid(errors, null, ".form-body");
        },
        conflict: function (...args) {
          conflict.apply(self, args);
        },
        action: self.isEdit ? "" : ""
      });
    },
    setEditMode(id) {
      this.isEdit = true;
      this.format = this.defaultFormat;
      this.mode = this.defaultMode;
      T1.Location.set(`#admin/vendor/${id}`, {
        silent: true
      });
    },
    updateBreadCrumb(data, header) {
      var pathPieces = [];
      var headerInfo;
      data.entityName = "vendor";
      if (header !== undefined) {
        if (Polymer.dom) {
          Polymer.dom(header[0]).textContent = data.name;
        } else {
          header.text(data.name);
        }
      }
      if (data.id) {
        pathPieces.push({
          label: data.name,
          location: `#admin/vendor/${data.id}`
        });
      } else {
        pathPieces = [{
          label: "New Vendor",
          location: ""
        }];
      }
      if (data) {
        headerInfo = Utils.getBreadCrumbHeaderInfo(data);
      }
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces,
        headerInfo: headerInfo
      });
    },
    saveOverride(evt, customCallbacks) {
      var callbacks = customCallbacks || {
        success: () => {
          this.$domainBtn.trigger("click");
          this.updateBreadCrumb(this.model.toJSON(), this.$(".title strand-header"));
          this.removeDuplicateDomain();
        }
      };
      if (this.canSave) {
        return this.saveOriginal(evt, callbacks);
      } else if (customCallbacks && customCallbacks.invalid) {
        customCallbacks.invalid();
      }
    },
    saveAndClose(evt) {
      var callback = {
        success: () => {
          const delay = 1e3;
          this.startSpinner($(evt.currentTarget));
          setTimeout(function () {
            T1.Location.set("#admin/vendors");
          }, delay);
        }
      };
      if (this.canSave) {
        return this.saveOriginal(evt, callback);
      }
    },
    saveAndNew(evt) {
      var callback = {
        success: () => {
          if (this.model.get("new")) {
            const currOrg = this.model.get("organization_id");
            this.model = new Vendor({
              new: true,
              status: "1",
              organization_id: currOrg
            });
            this.initLayout();
            this.load();
          }
          T1.Location.set("#admin/vendor");
        }
      };
      if (this.canSave) {
        this.setSaveAndContinueMode(true);
        return this.saveOriginal(evt, callback);
      }
    },
    canUnload() {
      var isFormElementsChanged = this.isFormElementsChanged;
      var title = this.model.get("name");
      var cancelHandler = {
        onAlertClose: function () {
          var T1Location = T1.Location;
          var abortedUrl = T1Location.getAbortedURL();
          if (abortedUrl === "" || abortedUrl === false) {
            setTimeout(function () {
              T1Location.set("#admin/vendors");
            }, 1);
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.isEdit ? title : "New Vendor"
        }, cancelHandler);
      }
      return !isFormElementsChanged;
    },
    cancelUnload() {
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          this.doNotProcessOrgChange = true;
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    handleOrgChange(data) {
      if (!this.doNotProcessOrgChange) {
        T1.EventHub.publish("dropdown:organizations", data);
        T1.Location.set("admin/vendors");
      }
      this.doNotProcessOrgChange = false;
    },
    stopBubbling(evt) {
      if (evt) {
        if (evt.stopPropagation) {
          evt.stopPropagation();
        }
        if (evt.preventDefault) {
          evt.preventDefault();
        }
      }
    },
    displayErrors(errors) {
      this.showErrors(this.model, errors, ".form-body");
      this.canSave = false;
    },
    clearErrors() {
      this.updateFooterMessage();
      this.canSave = true;
    },
    removeDuplicateDomain() {
      var $domains = this.$(".detect_domains").find(".form-input").val();
      var str = $domains.replace(/\s+/g, "");
      var arr = str.split(",");
      var result = [];
      $.each(arr, function (i, e) {
        if ($.inArray(` ${e}`, result) === -1) {
          result.push(` ${e}`);
        }
      });
      this.$(".detect_domains").find(".form-input").val(result.sort());
    },
    displayMMResell() {
      var $radio = this.$(".mm_contract");
      var $rateCardType = this.$(".rate_card_type");
      var $rateCardTypeEnum = this.$(".rate_card_price");
      var $wholesalePrice = this.$(".wholesale_price");
      if ($radio.val() === "0") {
        $rateCardType.hide();
        $rateCardType.find("strand-dropdown").val("").removeAttr("original-title title");
        $rateCardTypeEnum.hide();
        $rateCardTypeEnum.find(".form-input").val("").removeAttr("original-title title");
        $wholesalePrice.hide();
        $wholesalePrice.find(".form-input").val("").removeAttr("original-title title");
      } else {
        $rateCardType.show();
        $rateCardTypeEnum.show();
        $wholesalePrice.show();
      }
    },
    RTBRequired() {
      var $adxDeclare = this.$(".adx_declaration");
      var $rtb = this.$(".adx_vendor_identifier");
      if ($adxDeclare.val() === "0") {
        $rtb.hide();
        $rtb.find(".form-input").removeAttr("original-title title");
      } else {
        $rtb.show();
      }
    },
    specialDetection() {
      var $special_detection = this.$("#special_detection");
      var $special_input = this.$(".special_detection_input");
      if ($special_detection.attr("checked") === "checked") {
        $special_input.show();
      } else {
        $special_input.hide();
      }
    },
    serialize() {
      var model = this.model;
      var output = model.toJSON();
      output.isEdit = this.isEdit;
      output.status = output.status !== "0";
      output.allow_x_adv_pixels = output.allow_x_adv_pixels !== "0";
      output.adx_approved = output.adx_approved !== "0";
      output.adx_declaration_required = output.adx_declaration_required !== "0";
      output.adx_ssl_approved = output.adx_ssl_approved !== "0";
      output.adx_video_approved = output.adx_video_approved !== "0";
      output.adx_video_ssl_approved = output.adx_video_ssl_approved !== "0";
      output.is_eligible = output.is_eligible !== "0";
      output.mm_contract_available = output.mm_contract_available !== "0";
      output.hide_vendor_type = output.vendor_type === "DATA";
      output.manualDomainLimit = model.manualDomainLimit;
      output.title = output.id ? output.name : "New Vendor";
      if (output.org) {
        output.subTitle = output.org.name;
      }
      output.canEditVendors = T1Permissions.check("admin_vendors");
      if (output.updated_on) {
        output.updated_on = convertToLocalTime(output.updated_on).toString(dateFormat) + timezone;
      }
      return output;
    }
  });
  VendorCreateEdit = T1.Form.ViewUtils.extend(VendorCreateEdit);
  VendorCreateEdit.prototype.saveOriginal = VendorCreateEdit.prototype.save;
  VendorCreateEdit.prototype.save = VendorCreateEdit.prototype.saveOverride;
  return VendorCreateEdit;
});
