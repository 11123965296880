define('modules/campaign/views/mmChart',['require','jQuery','Underscore','T1ChartBenchmarkOptions','collections/campaignReport','modules/reporting/campaigns/models/chartMetrics','Hogan','models/reportingMetadata','text!../templates/chart_metric_controls.html','../models/mmChartModel','./utils/changeLogMultiMarkerCalc','models/report','modules/reporting/campaigns/utils/reportingUtils','text!../templates/chart_select_metric.html','T1','T1Animate','T1Chart','T1Layout','T1PerformanceTimer','T1UtilsAsync','text!../templates/chart_panel.html','text!../templates/chart_ttip.html','text!../templates/chart_change_log_ttip.html','models/userPreferences','T1Permissions','modules/reporting/campaigns/views/report_info'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const BenchmarkOptions = require("T1ChartBenchmarkOptions");
  const CampaignReport = require("collections/campaignReport");
  const ChartMetrics = require("modules/reporting/campaigns/models/chartMetrics");
  const Hogan = require("Hogan");
  const MetaData = require("models/reportingMetadata");
  const metricControls = require("text!../templates/chart_metric_controls.html");
  const MMChartModel = require("../models/mmChartModel");
  const MultiMarkerCalc = require("./utils/changeLogMultiMarkerCalc");
  const Report = require("models/report");
  const ReportUtils = require("modules/reporting/campaigns/utils/reportingUtils");
  const selectMetric = require("text!../templates/chart_select_metric.html");
  const T1 = require("T1");
  const T1Animate = require("T1Animate");
  const T1Chart = require("T1Chart");
  const T1Layout = require("T1Layout");
  const T1Timer = require("T1PerformanceTimer");
  const T1UtilsAsync = require("T1UtilsAsync");
  const template = require("text!../templates/chart_panel.html");
  const ttipTemplate = require("text!../templates/chart_ttip.html");
  const ttipTemplateChangeLog = require("text!../templates/chart_change_log_ttip.html");
  const UserPreferences = require("models/userPreferences");
  const T1Permissions = require("T1Permissions");
  const ReportInfo = require("modules/reporting/campaigns/views/report_info");
  const mainDateFormat = "yyyy-MM-dd";
  const performanceStr = "performance";
  const byHourStr = "by_hour";
  const watermarkStr = "watermark";
  const dashLineID = 2;
  const maxCrowdFactor = 2;
  const T1Publish = T1.EventHub.publish;
  const T1Bool = T1.Utils.valueToBool;
  const sessionStorage = {
    reportInfo: {
      reportTypes: {}
    },
    reportModels: {}
  };
  const getDays = value => {
    switch (value) {
      case "last_24_hours_inclusive":
        return 1;
      case "last_48_hours_inclusive":
        return 2;
      case "last_168_hours_inclusive":
        return 7;
    }
  };
  const mmChartOptions = {
    template: template,
    documentEvents: "mousedown.T1Chart keyup.T1Chart",
    multiMarkerEvent: "mousedown.MultiMarker",
    $document: $(document),
    xAxisLabelRotation: 0,
    xAxisLabelTextAnchor: "middle",
    xAxisLabelOffsetY: -29,
    benchmarkLabelLeftPadding: 23,
    benchmarkLabelRightPadding: 23,
    benchmarkTagOpenH: 30,
    padMatrix: {
      bottom: 42,
      left: 138,
      right: 143,
      top: 15
    },
    partials: {
      metric_controls: metricControls,
      select_metric: selectMetric
    },
    xKey: "",
    yKey: "clicks",
    yKey2: "impressions",
    yAxisLabel: "Clicks",
    yAxisLabel2: "Impressions",
    chartView: "campaign",
    yColor1Performance: "#00a8cc",
    yColor2Performance: "#ea661e",
    yColor1ByHour: "#1ca591",
    yColor2ByHour: "#90245c",
    yColor1Watermark: "#1860c2",
    yColor2Watermark: "#c32921",
    yColor1: "#00a8cc",
    yColor2: "#ea661e",
    yColorDim: "#e5e5e5",
    highlightedId: 0,
    metricOptions: [],
    metric1Performance: "Clicks",
    metric2Performance: "Impressions",
    metric1ByHour: "Clicks",
    metric2ByHour: "Impressions",
    metric1Watermark: "Watermark Spend Percent",
    metric2Watermark: "Watermark Impressions",
    leftTagOpen: "0",
    rightTagOpen: "0",
    byHourInterval: "last_24_hours_inclusive",
    performanceAttribution: "MediaMath Last Touch",
    byHourAttribution: "MediaMath Last Touch",
    watermarkAttribution: "MediaMath Last Touch",
    byHourIntervalOptions: [{
      text: "Last 24 Hours",
      value: "last_24_hours_inclusive",
      selected: false
    }, {
      text: "Last 48 Hours",
      value: "last_48_hours_inclusive",
      selected: false
    }, {
      text: "Last 7 Days",
      value: "last_168_hours_inclusive",
      selected: false
    }],
    events: {
      "click .benchmark-tag-c": "pinBenchmarkTag",
      "click .export-options li": "closeExportMenu",
      "click .w-head": "wHeadClick",
      "click g.campaign-circles": "highlightBenchmark1",
      "click g.campaign2-circles": "highlightBenchmark2",
      "mousedown .btn-export": "toggleExportMenu",
      "mousedown .report-export-menu": "stopEventPropagation",
      "mouseenter .benchmark-tag-c": "openBenchmarkTag",
      "mouseleave .benchmark-tag-c": "closeBenchmarkTag"
    },
    eventhubEvents: {
      "chart.add": "chartAdd",
      "mmCharts.switchReport": "switchReport"
    },
    handleDateChange(model) {
      if (!this.viewLoaded) {
        return;
      }
      const {
        start: start,
        end: end
      } = model.getStartEndDate();
      Object.assign(this, {
        reportStart: start.toString(mainDateFormat),
        reportEnd: end.toString(mainDateFormat),
        reportChanged: false,
        selectedMarker: null
      });
      this.el.off("onChartComplete.changeLogMarkers");
      this.changeLogLayout.unload();
      this.loadNewData();
    },
    displayLoadingMessage(isLoading) {
      this.message.show(isLoading ? "requesting chart data ..." : "no chart data available");
      return this;
    },
    graphGoalVsActual() {
      const goalValue = this.campaign.get("goal_value") || 0;
      const processedData = this.dataProcessor(this.getCampaignData());
      if (this.highlightedId === dashLineID) {
        this.graphSolidLine(processedData, goalValue);
        this.graphDashedLine(processedData, goalValue);
      } else {
        this.graphDashedLine(processedData, goalValue);
        this.graphSolidLine(processedData, goalValue);
      }
      return this;
    },
    graphSolidLine(processedData, goalValue) {
      this.graphBenchMarkSeries({
        benchmarkTagValue: this.summary.get(this.yKey),
        circleRadius: 3,
        circleStrokeColor: "#fff",
        color: this.yColor1,
        goalValue: goalValue,
        hideCircles: this.crowdLevel === maxCrowdFactor,
        lineColor: this.yColor1,
        name: "campaign",
        noGoal: true,
        processedData: processedData
      });
    },
    graphDashedLine(processedData, goalValue) {
      this.graphBenchMarkSeries({
        benchmarkTagValue: this.summary.get(this.yKey2),
        circleRadius: 3,
        circleStrokeColor: "#fff",
        color: this.yColor2,
        dash: "4,3",
        goalValue: goalValue,
        hideCircles: this.crowdLevel === maxCrowdFactor,
        line: this.line2,
        lineColor: this.yColor2,
        name: "campaign2",
        noGoal: true,
        processedData: processedData,
        y: "y_2",
        yRadius: this.yRadius2
      });
    },
    graphYValues() {
      return this.graphGoalVsActual();
    },
    getCampaignData() {
      return this.seriesSummary ? this.seriesSummary.toJSON() : [];
    },
    getMaxBenchmarks() {
      const goalType = this.campaign.get("goal_type");
      const showGoal = this.matchedMetricKeyToGoalType(this.yKey, goalType);
      const campaignData = this.getCampaignData();
      const maxObj = _.max(campaignData, campaignDatum => Number(campaignDatum[this.yKey]));
      return {
        campaignGoalValue: showGoal ? Number(this.campaign.get("goal_value")) || 0 : 0,
        campaignMaxValue: this.reportModel.get("campaignBenchmarkOn") && maxObj ? Number(maxObj[this.yKey]) : 0
      };
    },
    chartAdd({
      model: model
    }) {
      if (model) {
        this.el.off("onChartComplete.changeLogMarkers");
        this.entityType = model.get("type") === "campaign" ? "campaign" : "strategy";
        this.entityInfo = {
          getEndDate: () => this.reportEnd,
          getStartDate: () => this.reportStart,
          id: model.get("id"),
          name: model.get("name"),
          type: this.entityType
        };
        this.dataParamsChanged = false;
        this.append(model);
      } else {
        this.displayLoadingMessage(false);
      }
    },
    resetMetrics() {
      const {
        reportType: reportType
      } = this;
      const metrics = ChartMetrics.getKeyList();
      const resetMetric1 = metric => {
        this[metric] = "Clicks";
        this.yKey = "clicks";
      };
      const resetMetric2 = metric => {
        this[metric] = "Impressions";
        this.yKey2 = "impressions";
      };
      if (reportType === performanceStr) {
        this.yKey = ChartMetrics.getKey(this.metric1Performance);
        this.yKey2 = ChartMetrics.getKey(this.metric2Performance);
        if (!metrics.includes(this.yKey)) {
          resetMetric1("metric1Performance");
        }
        if (!metrics.includes(this.yKey2)) {
          resetMetric2("metric2Performance");
        }
      } else if (reportType === byHourStr) {
        this.yKey = ChartMetrics.getKey(this.metric1ByHour);
        this.yKey2 = ChartMetrics.getKey(this.metric2ByHour);
        if (!metrics.includes(this.yKey)) {
          resetMetric1("metric1ByHour");
        }
        if (!metrics.includes(this.yKey2)) {
          resetMetric2("metric2ByHour");
        }
      } else if (reportType === watermarkStr) {
        this.yKey = ChartMetrics.getKey(this.metric1Watermark);
        this.yKey2 = ChartMetrics.getKey(this.metric2Watermark);
        if (!metrics.includes(this.yKey)) {
          resetMetric1("metric1Watermark");
        }
        if (!metrics.includes(this.yKey2)) {
          resetMetric2("metric2Watermark");
        }
      }
    },
    initialize(...args) {
      let reportEnd, reportStart;
      const [opts] = args;
      const reportType = opts.getReportType();
      const ReportModel = sessionStorage.reportModels[reportType];
      const defaultStartDate = ReportUtils.defaultStartDate.toString(mainDateFormat);
      const defaultEndDate = ReportUtils.defaultEndDate.toString(mainDateFormat);
      if (ReportModel) {
        const {
          start: start,
          end: end
        } = ReportModel.getStartEndDate();
        reportStart = start ? start.toString(mainDateFormat) : defaultStartDate;
        reportEnd = end ? end.toString(mainDateFormat) : defaultEndDate;
      } else {
        reportStart = defaultStartDate;
        reportEnd = defaultEndDate;
      }
      Object.assign(this, {
        canLoad: false,
        dateformat: "yyyy-MMM-dd",
        entity: opts.getEntity(),
        getTimeZoneName: opts.getTimeZoneName,
        hasEcoCostFlag: T1Permissions.check("feature", "reporting_ecosystem_cost"),
        reportEnd: reportEnd,
        reportStart: reportStart,
        reportType: reportType,
        timeSeriesLabel: "Daily",
        toolTipDateFormatter: {
          Daily: str => {
            let dateObj;
            let dateformat = "ddd, MMM d, yyyy";
            if (this.reportType === byHourStr) {
              dateObj = T1.parseDateAsUTC(str);
              dateformat += " htt";
            } else {
              dateObj = Date.parse(str);
            }
            return dateObj && dateObj.toString(dateformat);
          }
        },
        updateTimezone: opts.updateTimezone,
        useUTC: false,
        xAxisLabel: "Days",
        xAxisLabelAdjustment: () => 0,
        xKey: "start_date"
      });
      ChartMetrics.updateLookup(`${reportType}_mm`);
      this.initPreferences();
      this.initLayouts();
      if (this.entity) {
        T1Chart.prototype.initialize.apply(this, args);
        this.chartAdd({
          model: this.entity
        });
      } else {
        this.displayLoadingMessage(false);
      }
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "monitor"], ["view", "campaign.chart"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["byHourInterval", "leftTagOpen", "metric1ByHour", "metric1Performance", "metric1Watermark", "metric2ByHour", "metric2Performance", "metric2Watermark", "performanceAttribution", "rightTagOpen"]);
      this.resetMetrics();
      this.byHourInterval = this.byHourInterval || "last_24_hours_inclusive";
      this.preferences.save();
      if (this.reportType === byHourStr) {
        this.dateformat = "yyyy-MMM-dd-htt-H";
        this.useUTC = true;
      }
      for (const item of this.byHourIntervalOptions) {
        item.selected = item.value === this.byHourInterval;
      }
      this.initMetadata();
      this.setColors();
    },
    initLayouts() {
      this.datePickerLayout = new T1Layout({
        el: () => this.$(".date-picker-holder"),
        template: '<div class="control-dates"></div>',
        layout: {
          ".control-dates": [{
            viewType: "chart_date_picker",
            module: "campaign",
            options: {
              isWaterMark: () => this.reportType === watermarkStr,
              reportChanged: () => this.reportChanged,
              getReportModel: () => this.reportModel
            }
          }]
        }
      });
      this.reportInfoLayout = new T1Layout({
        el: () => this.$(".report-info-holder"),
        template: '<div class="info-report"></div>',
        layout: {
          ".info-report": [{
            module: "reporting/campaigns",
            viewType: "report_info"
          }]
        }
      });
      this.changeLogLayout = new T1Layout({
        el: () => this.$(".chart-change-log-holder"),
        template: '<div class="chart-change-log"></div>',
        layout: {
          ".chart-change-log": [{
            module: "campaign",
            viewType: "chartChangeLog",
            options: {
              toggleLog: state => {
                this.toggleChangeLog(state);
              },
              getEntityInfo: () => this.entityInfo,
              getTimeZoneName: this.getTimeZoneName,
              setupMarkers: markerDates => {
                this.setupChangeLogMarkers(markerDates);
              },
              selectMarker: date => {
                this.selectedMarker = date;
                this.selectChangeLogMarker();
              }
            }
          }]
        }
      });
    },
    initMetadata() {
      let reportMeta, reportModel;
      const reportType = this.reportType;
      let urlString = `std/${reportType}/meta`;
      if (this.hasEcoCostFlag && reportType === performanceStr) {
        urlString = `std/performance_ecosystem_cost/meta`;
      }
      this.reportMeta = reportMeta = new MetaData({
        id: "reportMeta",
        key: reportType,
        urlString: urlString
      });
      if (this.reportModel) {
        this.reportModel.unbind("change:reportDates", this.handleDateChange);
      }
      this.reportModel = reportModel = this.getReportModel(reportType);
      this.reportModel.bind("change:reportDates", this.handleDateChange, this);
      reportMeta.setReportModel(reportModel);
      reportModel.setReportMeta(reportMeta);
      reportModel.setReportType(reportType);
    },
    getReportModel(reportType) {
      let reportModel;
      if (sessionStorage.reportModels[reportType]) {
        reportModel = sessionStorage.reportModels[reportType];
      } else {
        reportModel = new MMChartModel({
          key: reportType,
          entity: this.campaign
        });
        sessionStorage.reportModels[reportType] = reportModel;
      }
      return reportModel;
    },
    getMeta() {
      this.reportMeta.fetch({
        onSuccess: () => {
          let metric1, metric2;
          this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
          switch (this.reportType) {
            case performanceStr:
              metric1 = this.metric1Performance;
              metric2 = this.metric2Performance;
              break;
            case byHourStr:
              metric1 = this.metric1ByHour;
              metric2 = this.metric2ByHour;
              break;
            case watermarkStr:
              metric1 = this.metric1Watermark;
              metric2 = this.metric2Watermark;
              break;
          }
          this.yKey = ChartMetrics.getKey(metric1);
          this.yAxisLabel = ChartMetrics.getShortName(this.yKey);
          this.yKey2 = ChartMetrics.getKey(metric2);
          this.yAxisLabel2 = ChartMetrics.getShortName(this.yKey2);
          this.preferences.save();
          this.getData();
        }
      });
    },
    getData() {
      const self = this;
      const {
        reportType: reportType,
        reportModel: reportModel
      } = this;
      const fetchMetrics = ChartMetrics.getKeyList();
      let urlString = `std/${reportType}`;
      switch (reportType) {
        case performanceStr:
          if (this.hasEcoCostFlag) {
            urlString = `std/performance_ecosystem_cost`;
          }
          break;
      }
      const fetchOptions = {
        dimensions: "campaign_id,campaign_timezone_code,campaign_timezone",
        filter: this.entityType === "campaign" ? "campaign_id=" : "strategy_id=",
        metrics: fetchMetrics.toString(),
        order: "date",
        precision: 2,
        time_rollup: "all"
      };
      const url = function () {
        const opts = $.extend({}, this.fetchOptions);
        opts.filter += String(this.id);
        if (self.entityType === "strategy") {
          opts.filter += `&campaign_id=${self.campaign.get("campaign_id")}`;
        }
        return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
      };
      let seriesTimeRollup = "by_day";
      const timerValue = `${this.timerValue}-${reportType}`;
      const summaryFetchOptionsExtend = {
        start_date: this.reportStart,
        end_date: this.reportEnd
      };
      const summaryDeferred = T1UtilsAsync.makeDeferral();
      const seriesDeferred = T1UtilsAsync.makeDeferral();
      const promises = [summaryDeferred.promise, seriesDeferred.promise];
      this.updateTimezone("");
      switch (reportType) {
        case performanceStr:
          fetchOptions.filter = `attribution_group=${this.performanceAttribution}&${fetchOptions.filter}`;
          $.extend(fetchOptions, summaryFetchOptionsExtend);
          break;
        case byHourStr:
          $.extend(fetchOptions, {
            time_window: this.byHourInterval
          });
          seriesTimeRollup = "by_hour";
          break;
        default:
          $.extend(fetchOptions, summaryFetchOptionsExtend);
      }
      this.abortAjaxCalls();
      const summary = this.summary = new Report({
        fetchOptions: fetchOptions,
        loaded: false,
        url: url,
        urlString: urlString
      });
      this.series = new CampaignReport({
        fetchOptions: $.extend({}, fetchOptions, {
          time_rollup: seriesTimeRollup
        }),
        url: url,
        urlString: urlString
      });
      this.seriesSummary = this.series;
      this.timer("CHART:FETCH:", timerValue, "start");
      summary.id = this.series.id = this.campaign.id;
      this.series.loaded = false;
      summary.fetch({
        onSuccess: function () {
          summary.loaded = true;
          summaryDeferred.resolve();
        }
      });
      this.series.fetch({
        onSuccess: seriesData => {
          const models = seriesData.models;
          const dataJSON = seriesData.toJSON();
          this.timer("CHART:FETCH", timerValue, "stop");
          if (models.length) {
            if (reportType === byHourStr) {
              reportModel.setTimeSeriesSlots({
                start_date: models[0].get("start_date"),
                end_date: models[models.length - 1].get("start_date")
              }, byHourStr);
            } else {
              reportModel.setTimeSeriesSlots(summaryFetchOptionsExtend);
            }
          }
          const timezoneCode = dataJSON[0] && dataJSON[0].campaign_timezone_code || "";
          const timezoneName = dataJSON[0] && dataJSON[0].campaign_timezone || "";
          this.updateTimezone(timezoneCode, timezoneName);
          reportModel.setReportInfo(`${reportType}-${this[`${reportType}Attribution`]}`, this.series.ajxResponseHeaders);
          seriesDeferred.resolve();
        }
      });
      Promise.all(promises).then(() => {
        let reportInfo;
        const reportInfoType = `${reportType}-${this[`${reportType}Attribution`]}`;
        if (sessionStorage.reportInfo.reportTypes[reportInfoType]) {
          reportInfo = sessionStorage.reportInfo;
        } else {
          reportInfo = this.reportModel.get("reportInfo");
        }
        this.canLoad = true;
        this.load();
        T1Publish("responseHeadersReady", reportInfo.reportTypes[reportInfoType]);
      });
    },
    storeReportInfo() {
      const reportType = this.reportType;
      const reportInfo = this.reportModel.get("reportInfo");
      const reportInfoType = `${reportType}-${this[`${reportType}Attribution`]}`;
      if (reportInfo) {
        const infoData = reportInfo.reportTypes[reportInfoType];
        if (!sessionStorage.reportInfo.reportTypes[reportInfoType]) {
          sessionStorage.reportInfo.reportTypes[reportInfoType] = infoData;
        }
      }
    },
    timer(category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    },
    append(campaign) {
      if (!campaign) {
        return;
      }
      this.timerValue = `${campaign.name}-${campaign.id}`;
      this.campaign = campaign;
      this.canLoad = false;
      this.load();
      this.getMeta();
      this.loadReportIcon();
    },
    loadReportIcon() {
      this.renderPartial(".icon-view-report");
      setTimeout(() => {
        this.activateToolTip();
      }, 0);
    },
    activateToolTip() {
      this.el.on("onChartComplete.tooltip", () => {
        this.attachTooltips({
          header: () => this.yAxisLabel,
          header2: () => this.yAxisLabel2,
          paths: ["circle.circle"],
          template: ttipTemplate,
          tooltipClass: this.reportType,
          renderKey: data => data[this.dimensionName] ? data[this.dimensionName] : this.campaign.get("name"),
          renderInfo: ({
            xLabel: xLabel
          }) => {
            const formatter = this.toolTipDateFormatter[this.timeSeriesLabel];
            return formatter(xLabel);
          }
        });
      });
    },
    switchReport(reportType) {
      const restoreOriginalWidth = () => {
        if (this.benchmarkLabelRightPaddingOriginal && this.reportType !== performanceStr) {
          this.benchmarkLabelRightPadding = this.benchmarkLabelRightPaddingOriginal;
          this.padMatrix.right = this.padMatrixRightOriginal;
        }
      };
      if (!this.canLoad) {
        return;
      }
      ChartMetrics.updateLookup(`${reportType}_mm`);
      this.storeReportInfo();
      this.reportType = reportType;
      this.initMetadata();
      $(window).off("resize.charting").on("resize.charting", () => {
        this.chart();
      });
      this.updateCurrency();
      switch (this.reportType) {
        case byHourStr:
          restoreOriginalWidth();
          break;
        case watermarkStr:
          restoreOriginalWidth();
          break;
      }
      const {
        start: start,
        end: end
      } = this.reportModel.getStartEndDate();
      const startDate = start ? start.toString(mainDateFormat) : ReportUtils.defaultStartDate.toString(mainDateFormat);
      const endDate = end ? end.toString(mainDateFormat) : ReportUtils.defaultEndDate.toString(mainDateFormat);
      this.reportStart = startDate;
      this.reportEnd = endDate;
      this.datePickerLayout.unload();
      this.reportInfoLayout.unload();
      this.changeLogLayout.unload();
      this.el.off("onChartComplete.changeLogMarkers");
      this.selectedMarker = null;
      this.reportChanged = true;
      this.dehighlight();
      this.loadNewData();
    },
    updateControls() {
      const reportType = this.reportType;
      T1.Selectbox(this.$(".select-metric"), {
        allow_single_deselect: false,
        dropDownRightPosition: "14px",
        enableDirectionAuto: false,
        highlightInSearchResult: true,
        no_results_text: "No results found for",
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search",
        onChange: evt => {
          let metric1, metric2;
          const select = $(evt.target);
          const parent = select.parent();
          const selectedOption = select.find("option:selected");
          const whichMetric = () => {
            if (parent.data("axis") === "yLeftAxis") {
              this.yKey = select.val();
              this.yAxisLabel = selectedOption.data("tag");
              metric1 = selectedOption.text();
            } else {
              this.yKey2 = select.val();
              this.yAxisLabel2 = selectedOption.data("tag");
              metric2 = selectedOption.text();
            }
          };
          const updateMetric = (metricType1, metricType2) => {
            if (metric1) {
              this[metricType1] = metric1;
            }
            if (metric2) {
              this[metricType2] = metric2;
            }
          };
          switch (reportType) {
            case performanceStr:
              whichMetric();
              updateMetric("metric1Performance", "metric2Performance");
              break;
            case byHourStr:
              whichMetric();
              updateMetric("metric1ByHour", "metric2ByHour");
              break;
            case watermarkStr:
              whichMetric();
              updateMetric("metric1Watermark", "metric2Watermark");
              break;
          }
          this.update();
          this.preferences.save();
        }
      });
      if (reportType === byHourStr) {
        T1.Selectbox(this.$(".by-hour-interval-type"), {
          allow_single_deselect: false,
          dropDownRightPosition: "14px",
          enableDirectionAuto: false,
          highlightInSearchResult: true,
          searchFieldDisabled: true,
          onChange: evt => {
            const byHourIntervalOptions = this.byHourIntervalOptions;
            this.byHourInterval = $(evt.target).val();
            byHourIntervalOptions.forEach(item => {
              item.selected = item.value === this.byHourInterval;
            });
            if (window.pendo) {
              window.pendo.track("Campaigns - MM Chart - Date Range Selection", {
                timeWindow: this.byHourInterval,
                days: getDays(this.byHourInterval),
                report: "by_hour"
              });
            }
            this.preferences.save();
            this.loadNewData();
          }
        });
      } else {
        this.datePickerLayout.load();
      }
      this.reportInfoLayout.load();
    },
    load() {
      this.updateControls();
      if (this.canLoad) {
        this.el.off("click.mmchart").on("click.mmchart", () => {
          this.dehighlight();
        });
        this.viewLoaded = true;
        if (this.reportType === performanceStr && this.series.length) {
          this.changeLogLayout.load();
        }
      }
    },
    unload() {
      this.el.off("click.mmchart");
      this.el.off("onChartComplete.changeLogMarkers");
      this.reportModel.unbind("change:reportDates", this.handleDateChange);
      this.abortAjaxCalls();
      this.datePickerLayout.unload();
      this.reportInfoLayout.unload();
      this.changeLogLayout.unload();
      this.storeReportInfo();
      if (this.benchmarkLabelRightPaddingOriginal) {
        this.benchmarkLabelRightPadding = this.benchmarkLabelRightPaddingOriginal;
        this.padMatrix.right = this.padMatrixRightOriginal;
      }
      Object.assign(this, {
        campaign: null,
        reportChanged: null,
        selectedMarker: null
      });
    },
    loadNewData() {
      const isByHour = this.reportType === byHourStr;
      Object.assign(this, {
        canLoad: false,
        dataParamsChanged: true,
        dateformat: `yyyy-MMM-dd${isByHour ? "-htt-H" : ""}`,
        useUTC: isByHour
      });
      this.chartCanvas.empty();
      this.hideNoDataMessage();
      this.displayLoadingMessage();
      this.getMeta();
    },
    animateTag(target, opening) {
      T1Animate({
        duration: 240,
        easing: "ease",
        options: {
          height: opening ? `${this.benchmarkTagOpenH}px` : "16px"
        },
        target: target
      });
    },
    openBenchmarkTag({
      currentTarget: currentTarget
    }) {
      this.animateTag($(currentTarget).children(".tag"), true);
    },
    closeBenchmarkTag({
      currentTarget: currentTarget
    }) {
      const $currentTarget = $(currentTarget);
      const leftTag = $currentTarget.hasClass("left");
      const isLeftTagOpen = T1Bool(this.leftTagOpen);
      const isRightTagOpen = T1Bool(this.rightTagOpen);
      if (leftTag && !isLeftTagOpen) {
        this.animateTag($currentTarget.children(".tag"), false);
      } else if (!leftTag && !isRightTagOpen) {
        this.animateTag($currentTarget.children(".tag"), false);
      }
    },
    pinBenchmarkTag(event) {
      const currentTarget = $(event.currentTarget);
      const isLeftTagOpen = () => T1Bool(this.leftTagOpen);
      const isRightTagOpen = () => T1Bool(this.rightTagOpen);
      this.stopEventPropagation(event);
      if (currentTarget.hasClass("left")) {
        this.leftTagOpen = isLeftTagOpen() ? "0" : "1";
        if (!isLeftTagOpen()) {
          this.animateTag(currentTarget.children(".tag"), false);
        }
      } else {
        this.rightTagOpen = isRightTagOpen() ? "0" : "1";
        if (!isRightTagOpen()) {
          this.animateTag(currentTarget.children(".tag"), false);
        }
      }
      this.preferences.save();
    },
    toggleChangeLog(state) {
      if (!this.benchmarkLabelRightPaddingOriginal) {
        this.benchmarkLabelRightPaddingOriginal = this.benchmarkLabelRightPadding;
        this.padMatrixRightOriginal = this.padMatrix.right;
      }
      if (state === "open") {
        const changeLogWidth = 385;
        this.chartChangeLogIsOpen = true;
        this.benchmarkLabelRightPadding = this.benchmarkLabelRightPaddingOriginal + changeLogWidth;
        this.padMatrix.right = this.padMatrixRightOriginal + changeLogWidth;
      } else {
        this.chartChangeLogIsOpen = false;
        this.benchmarkLabelRightPadding = this.benchmarkLabelRightPaddingOriginal;
        this.padMatrix.right = this.padMatrixRightOriginal;
        this.el.off("onChartComplete.changeLogMarkers");
      }
      this.chart();
    },
    setupChangeLogMarkers(markerDates) {
      this.selectedMarker = null;
      this.attachChangeLogMarkers(markerDates);
      this.el.off("onChartComplete.changeLogMarkers").on("onChartComplete.changeLogMarkers", () => {
        this.attachChangeLogMarkers(markerDates);
      });
    },
    attachChangeLogMarkers(markerDates) {
      const self = this;
      const markerSize = 15;
      const multiMarkerWidth = 40;
      const leftCorrection = $(".app-container").position().left;
      const markerData = MultiMarkerCalc(this.dataStack[0], this.x, markerDates, markerSize, multiMarkerWidth);
      const {
        markerPosLookup: markerPosLookup,
        multiMarkerDates: multiMarkerDates,
        overlapDates: overlapDates
      } = markerData;
      this.vis.append("g").attr("class", "change-log-markers").selectAll(".marker").data(markerDates).enter().append("g").attr("class", "marker").append("foreignObject").attr("class", "foreign-object").attr("x", d => markerPosLookup[d]).attr("y", -markerSize).attr("width", multiMarkerWidth).attr("height", markerSize).append("xhtml:div").html(`<div class='icon' width='${markerSize}' height='${markerSize}'></div>` + `<div class='number' width='${markerSize}' height='${markerSize}'></div>`);
      d3.selectAll(".marker .foreign-object").attr("x", function (d) {
        let currentXpos = markerPosLookup[d];
        const thisMarker = d3.select(this);
        if (overlapDates[d]) {
          thisMarker.classed("no-display", true);
        }
        if (multiMarkerDates[d]) {
          const maxItems = 99;
          const combinedData = multiMarkerDates[d];
          const combinedDates = _.pluck(combinedData, "date");
          const combinedDatesLength = combinedDates.length;
          const combinedPos = _.chain(combinedData).pluck("pos").reduce((memo, pos) => memo + pos).value();
          currentXpos = combinedPos / combinedDatesLength;
          thisMarker.classed("multi-marker", true).attr("data-combined-dates", combinedDates.toString());
          const icon = d3.select(this.firstChild.firstChild);
          const number = d3.select(this.firstChild.lastChild);
          icon.classed("multi-marker", true);
          number.html(combinedDatesLength > maxItems ? `${maxItems}+` : combinedDatesLength);
        }
        return currentXpos;
      }).on("click", function (d) {
        const scope = this;
        const marker = d3.select(scope.parentNode);
        const foreignObject = d3.select(scope);
        d3.event.stopPropagation();
        if (foreignObject.classed("multi-marker")) {
          self.resetChangeLogMarkers();
          marker.classed("selected", true);
          let ttip = new Strand.Tooltip();
          ttip.target = "#chartMultiMarkerTtipTrigger";
          ttip.tipWidth = 90;
          ttip.auto = false;
          const $tooltipHolder = self.el.find(".chart-multi-marker-tooltip");
          $tooltipHolder.empty();
          $tooltipHolder.append('<div id="chartMultiMarkerTtipTrigger" class="tooltip-trigger"></div>');
          Polymer.dom($tooltipHolder[0]).appendChild(ttip);
          const compiledTemplate = Hogan.compile(ttipTemplateChangeLog);
          const leftPosition = $(scope).position().left;
          const tooltipPosition = leftPosition - leftCorrection;
          const combinedDates = foreignObject.attr("data-combined-dates").split(",");
          const dateList = combinedDates.map(date => ({
            date: date
          }));
          window.requestAnimationFrame(function () {
            if (Polymer && Polymer.dom) {
              Polymer.dom(ttip).innerHTML = compiledTemplate.render({
                dates: dateList
              });
            } else {
              $(ttip).html(compiledTemplate.render({
                dates: dateList
              }));
            }
            window.requestAnimationFrame(function () {
              const $tooltipTrigger = $tooltipHolder.find(".tooltip-trigger");
              $tooltipTrigger.css("left", `${tooltipPosition}px`);
              ttip.open();
              self.$document.on(self.multiMarkerEvent, function (evt) {
                const target = evt.target;
                const className = target.className;
                const reset = function () {
                  $tooltipTrigger.hide();
                  ttip.close();
                  ttip = null;
                  self.$document.off(self.multiMarkerEvent);
                  if (!self.selectedMarker) {
                    self.resetChangeLogMarkers();
                  }
                };
                evt.stopPropagation();
                if (className && className.includes) {
                  switch (true) {
                    case className.includes("multi-marker-btn"):
                      self.selectedMarker = target.textContent;
                      T1Publish("mmChart.selectMarker", target.textContent);
                      break;
                    case className.includes("chart-multi-marker-tooltip-list"):
                      break;
                    case className.includes("tooltip-trigger"):
                      self.resetChangeLogMarkers();
                    default:
                      reset();
                  }
                } else {
                  reset();
                }
              });
            });
          });
        } else {
          if (marker.classed("selected")) {
            self.resetChangeLogMarkers();
          } else {
            self.resetChangeLogMarkers();
            marker.classed("selected", true);
            self.selectedMarker = d;
            T1Publish("mmChart.selectMarker", d);
          }
        }
      });
      this.selectChangeLogMarker();
    },
    resetChangeLogMarkers() {
      d3.selectAll(".marker ").classed("selected", false);
      this.selectedMarker = null;
      T1Publish("mmChart.selectMarker", null);
    },
    selectChangeLogMarker() {
      const selectedMarker = this.selectedMarker;
      d3.selectAll(".marker").each(function (d) {
        const marker = d3.select(this);
        const foreignObject = d3.select(this.firstChild);
        let isSelected = false;
        if (!foreignObject.classed("no-display")) {
          if (foreignObject.attr("data-combined-dates")) {
            const combinedDates = foreignObject.attr("data-combined-dates").split(",");
            if (combinedDates.includes(selectedMarker)) {
              isSelected = true;
            }
          } else {
            isSelected = selectedMarker === d;
          }
        }
        marker.classed("selected", isSelected);
      });
    },
    highlightBenchmark1(event) {
      this.stopEventPropagation(event);
      this.highlightedId = 1;
      this.setColors();
      this.chart();
    },
    highlightBenchmark2(event) {
      this.stopEventPropagation(event);
      this.highlightedId = 2;
      this.setColors();
      this.chart();
    },
    dehighlight() {
      this.highlightedId = 0;
      this.setColors();
      this.chart();
    },
    setColors() {
      const reportType = this.reportType;
      switch (this.highlightedId) {
        case 0:
          switch (reportType) {
            case performanceStr:
              this.yColor1 = this.yColor1Performance;
              this.yColor2 = this.yColor2Performance;
              break;
            case byHourStr:
              this.yColor1 = this.yColor1ByHour;
              this.yColor2 = this.yColor2ByHour;
              break;
            case watermarkStr:
              this.yColor1 = this.yColor1Watermark;
              this.yColor2 = this.yColor2Watermark;
              break;
          }
          break;
        case 1:
          switch (reportType) {
            case performanceStr:
              this.yColor1 = this.yColor1Performance;
              break;
            case byHourStr:
              this.yColor1 = this.yColor1ByHour;
              break;
            case watermarkStr:
              this.yColor1 = this.yColor1Watermark;
              break;
          }
          this.yColor2 = this.yColorDim;
          break;
        case dashLineID:
          switch (reportType) {
            case performanceStr:
              this.yColor2 = this.yColor2Performance;
              break;
            case byHourStr:
              this.yColor2 = this.yColor2ByHour;
              break;
            case watermarkStr:
              this.yColor2 = this.yColor2Watermark;
              break;
          }
          this.yColor1 = this.yColorDim;
          break;
      }
    },
    wHeadClick(event) {
      this.stopEventPropagation(event);
    },
    getMetricOptions() {
      const metricOptions = ChartMetrics.getDisplayList();
      function setSelected(options, key) {
        options.forEach(item => {
          item.selected = item.value === key;
        });
      }
      const optionsLeft = $.extend(true, [], metricOptions);
      const optionsRight = $.extend(true, [], metricOptions);
      setSelected(optionsLeft, this.yKey);
      setSelected(optionsRight, this.yKey2);
      return {
        left: {
          metrics: optionsLeft
        },
        right: {
          metrics: optionsRight
        }
      };
    },
    abortAjaxCalls() {
      if (this.summary) {
        this.summary.abort();
      }
      if (this.series) {
        this.series.abort();
      }
    },
    toggleExportMenu(event) {
      const self = this;
      const $exportOptions = this.$(".export-options");
      const $menuArrow = this.$(".btn-export .icon-arrow");
      function createUrl({
        fetchOpts: fetchOpts,
        urlString: urlString
      }) {
        return `${T1.RPT_API_ROOT + urlString}?${$.param(fetchOpts)}`;
      }
      if (!$menuArrow.hasClass("flip-v")) {
        this.stopEventPropagation(event);
        this.$document.mousedown();
        $exportOptions.toggle();
        $menuArrow.toggleClass("flip-v");
        this.$document.one(this.documentEvents, function (evt) {
          const tabKey = 9;
          const escapeKey = 27;
          if (evt.keyCode === tabKey || evt.keyCode === escapeKey || !evt.keyCode) {
            self.closeExportMenu();
          }
        });
        const $linkCurrentView = $exportOptions.find("a:eq(0)");
        const $linkAllMetrics = $exportOptions.find("a:eq(1)");
        const fetchOpts = Object.assign({}, this.series.fetchOptions);
        let urlString = this.series.urlString;
        switch (this.reportType) {
          case byHourStr:
          case watermarkStr:
            urlString = urlString.replace(this.reportType, `${this.reportType}/download`);
            break;
        }
        fetchOpts.dimensions = fetchOpts.dimensions.split(",").filter(dim => dim !== "campaign_timezone_code").join();
        switch (this.entityType) {
          case "campaign":
            fetchOpts.dimensions = ["campaign_name", ...fetchOpts.dimensions.split(",")].sort().join();
            fetchOpts.filter = fetchOpts.filter + this.series.id;
            break;
          case "strategy":
            fetchOpts.dimensions = ["campaign_name", "strategy_id", "strategy_name", ...fetchOpts.dimensions.split(",")].sort().join();
            fetchOpts.filter = `campaign_id=${this.campaign.get("campaign_id")}&${fetchOpts.filter + this.series.id}`;
            break;
        }
        const urlAllMetrics = createUrl({
          fetchOpts: fetchOpts,
          urlString: urlString
        });
        fetchOpts.metrics = [this.yKey, this.yKey2].join();
        const urlCurrentView = createUrl({
          fetchOpts: fetchOpts,
          urlString: urlString
        });
        $linkCurrentView.attr("href", urlCurrentView);
        $linkAllMetrics.attr("href", urlAllMetrics);
      } else {
        this.$document.mousedown();
      }
    },
    closeExportMenu() {
      const $menuArrow = this.$(".btn-export .icon-arrow");
      const $exportOptions = this.$(".export-options");
      $exportOptions.toggle();
      $menuArrow.toggleClass("flip-v");
      this.$document.off(this.documentEvents);
    },
    stopEventPropagation(event) {
      event.stopPropagation();
    },
    serialize() {
      let isLastTouch;
      const metricOptions = this.getMetricOptions();
      const reportType = this.reportType;
      const isPerformanceReport = reportType === performanceStr && this.campaign;
      const isByHourReport = reportType === byHourStr;
      switch (reportType) {
        case performanceStr:
          isLastTouch = this.performanceAttribution === "MediaMath Last Touch";
          break;
      }
      return $.extend(T1Chart.prototype.serialize(), {
        attribution0: isLastTouch,
        attribution1: !isLastTouch,
        isAttributionReport: false,
        isStreaming: isPerformanceReport || isByHourReport,
        metricLeft: metricOptions.left,
        metricRight: metricOptions.right,
        performanceReport: isPerformanceReport,
        byHourIntervals: this.byHourIntervalOptions,
        byHourReport: isByHourReport,
        reportType: reportType,
        watermarkReport: reportType === watermarkStr
      });
    }
  };
  return T1Chart.extend(BenchmarkOptions.extend(mmChartOptions));
});
