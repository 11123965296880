define('modules/creatives/display/control/searchConfigs',[], function () {
  return function () {
    return {
      active_state: {
        active: {
          term: "1",
          searchField: "status"
        },
        inactive: {
          term: "0",
          searchField: "status"
        }
      },
      advertiser: {
        id: {
          term: "",
          searchField: "advertiser_id"
        }
      },
      multiField: {
        keyword: {
          term: "",
          searchField: "multiField"
        }
      },
      file_type: {
        file_type: {
          term: "",
          searchField: "file_type"
        }
      }
    };
  };
});
