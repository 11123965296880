define('modules/reporting/segments/dataExport/createEdit/performance/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "performance",
    name: "reporting/segments/dataExport/createEdit/performance",
    viewCfgs: {
      list: {},
      bins: {}
    }
  });
});
