define('models/atomicCreative',["jQuery", "Underscore", "T1", "T1Model", "Backbone", "models/advertiser", "models/concept", "models/creative", "models/videoCreativeStatus", "models/videoCreativeDetail", "models/videoCreativeVariants", "utils/CreativeUtils"], function ($, _, T1, T1Model, Backbone, Advertiser, Concept, Creative, VideoCreativeStatus, VideoCreativeDetail, VideoCreativeVariants, CreativeUtils) {
  T1.Models.AtomicCreative = T1Model.extend({
    url: "atomic_creatives/",
    name: "creative",
    defaults: {
      action: "include",
      trait: "",
      videoClickUrl: "",
      videoLandUrl: ""
    },
    allowInlineValidation: true,
    relations: [{
      type: Backbone.HasOne,
      key: "advertiser",
      relatedModel: "Advertiser",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "concept",
      relatedModel: "Concept",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "details",
      relatedModel: "VideoCreativeDetail",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "progress",
      relatedModel: "VideoCreativeStatus",
      includeInJSON: true,
      reverseRelation: {
        key: "creative",
        includeInJSON: "id"
      }
    }, {
      type: Backbone.HasMany,
      key: "variants",
      relatedModel: "VideoCreativeVariants",
      includeInJSON: true,
      reverseRelation: {
        key: "creative",
        includeInJSON: "id"
      }
    }, {
      type: Backbone.HasOne,
      key: "creative",
      relatedModel: "Creative",
      includeInJSON: true
    }],
    parse: function (data) {
      var parsedData = data.entity ? T1.Utils.parseEntity(data.entity) : data;
      var crtve = parsedData.creative;
      var copies = _.chain(crtve).values().compact().value();
      var hasCopies = crtve && crtve[0] !== undefined;
      var mergeAttrs = ["creative_asset", "vendor_pixel"];
      var newAttrs = {};
      if (hasCopies) {
        mergeAttrs.forEach(function (attr) {
          copies.forEach(function (copy) {
            if (copy[attr]) {
              newAttrs[attr] = copy[attr];
            }
          });
        });
        _.extend(newAttrs, crtve[0]);
        delete newAttrs.id;
        parsedData.creative = newAttrs;
      }
      if (parsedData.creative && parsedData.creative.creative_asset) {
        parsedData.creative.creative_asset = T1.Utils.objectTOArray(parsedData.creative.creative_asset);
      }
      _.extend(parsedData, CreativeUtils.parsePixels(parsedData));
      if (!parsedData.start_date) {
        parsedData.start_date = null;
      }
      if (!parsedData.end_date) {
        parsedData.end_date = null;
      }
      return parsedData;
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    },
    fetchCreativeStatus: function () {
      if (!this.get("progress")) {
        this.set({
          progress: new VideoCreativeStatus({
            id: this.get("id")
          })
        });
      }
      this.fetchRelated("progress");
    },
    fetchCreativeVariants: function () {
      if (!this.get("variants")) {
        this.set({
          variants: new VideoCreativeVariants({
            id: this.get("id")
          })
        });
      }
      this.fetchRelated("variants");
    },
    getConceptName: function (id) {
      var concepts = this.get("advertiser").get("concept");
      var selected = concepts.find(function (concept) {
        return concept.get("id") === id;
      });
      if (selected) {
        return selected.get("name");
      }
    },
    validateAttrs: function (attrs) {
      var self = this;
      var errors = [];
      var optionalFields = ["concept_id", "click_url", "start_date", "end_date"];
      var requiredField = "This is a required field.";
      var isCustomDimension = attrs.dimensionType === "0";
      var maxInt = 1e4;
      var validationResult;
      var reqFields = {
        advertiser_id: function () {
          return !attrs.advertiser_id;
        },
        dimension: function () {
          return !isCustomDimension && !attrs.dimension;
        },
        width: function () {
          if (!isCustomDimension) {
            if (!attrs.width) {
              return requiredField;
            }
            if (parseInt(attrs.width) >= maxInt) {
              return "Width exceeds " + maxInt + " pixels";
            }
          }
          return false;
        },
        height: function () {
          if (!isCustomDimension) {
            if (!attrs.height) {
              return requiredField;
            }
            if (parseInt(attrs.height) >= maxInt) {
              return "Height exceeds " + maxInt + " pixels";
            }
          }
          return false;
        },
        custom_width: function () {
          if (isCustomDimension) {
            if (!attrs.custom_width) {
              return requiredField;
            }
            if (parseInt(attrs.custom_width) >= maxInt) {
              return "Width exceeds " + maxInt + " pixels";
            }
          }
          return false;
        },
        custom_height: function () {
          if (isCustomDimension) {
            if (!attrs.custom_height) {
              return requiredField;
            }
            if (parseInt(attrs.custom_height) >= maxInt) {
              return "Height exceeds " + maxInt + " pixels";
            }
          }
          return false;
        },
        tag: function () {
          if (!attrs.tag.length) {
            return requiredField;
          }
          return false;
        },
        tpas_ad_tag: function () {
          return false;
        },
        click_url: function () {
          if (attrs.click_url) {
            return self.validateUrl(attrs.click_url, 1e4);
          }
          return false;
        },
        click_through_url: function () {
          if (attrs.click_through_url) {
            return self.validateUrl(attrs.click_through_url);
          }
          return false;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1 && key.indexOf("vendors.") === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (attrs.hasOwnProperty("tag")) {
        validationResult = reqFields.tag();
        if (validationResult !== false) {
          errors.push({
            field: "tag",
            message: validationResult
          });
        }
      }
      if (attrs.hasOwnProperty("click_url")) {
        validationResult = reqFields.click_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_url",
            message: validationResult
          });
        }
      }
      if (attrs.hasOwnProperty("click_through_url")) {
        validationResult = reqFields.click_through_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_through_url",
            message: validationResult
          });
        }
      }
      if (errors.length) {
        return errors;
      }
    },
    validateUrl: function (value, maxChar) {
      var urlValidation = /^([a-zA-Z\d]+):\/\/[^ "]+$/;
      maxChar = maxChar || 2048;
      if (!urlValidation.test(value)) {
        return "Please provide a valid URL.";
      }
      if (value.length > maxChar) {
        return "URL must not exceed " + maxChar + " characters.";
      }
      return false;
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      var dimension;
      validationErrors = self.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        if (rawData.dimensionType === "0") {
          rawData.width = rawData.custom_width;
          rawData.height = rawData.custom_height;
        } else if (rawData.dimensionType === "1") {
          dimension = rawData.dimension.split("x");
          rawData.width = dimension[0];
          rawData.height = dimension[1];
        }
        delete rawData.dimensionType;
        delete rawData.dimension;
        delete rawData.custom_width;
        delete rawData.custom_height;
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    },
    reset: function () {
      this.url = "";
      this.attributes = {};
    },
    fixture: "-atomic_creatives"
  });
  return T1.Models.AtomicCreative;
});
