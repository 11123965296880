define('modules/campaign/views/duplicateStrategiesErrorGrid',["T1View", "T1GridView", "text!modules/campaign/templates/duplicateStrategiesErrorGrid.html"], function (T1View, T1GridView, grid_item) {
  "use strict";

  var duplicateStrategiesErrorGrid = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder"
    },
    truncateConfig: {
      ".truncate": {
        lines: 1
      }
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "errored-strategy-name",
      label: "Strategy Id"
    }, {
      name: "errored-reason",
      label: "Reason"
    }],
    initialize: function ({
      errors: errors
    }) {
      this.errors = errors;
    },
    load: function () {
      this.render();
    },
    unload: function () {
      this.destroy();
    },
    serialize: function () {
      return {
        list: this.errors
      };
    }
  });
  return T1GridView(duplicateStrategiesErrorGrid);
});
