define('modules/reporting/views/tabs',["T1", "T1View", "T1Permissions", "text!../templates/tabs.html", "T1TabLayoutV2"], function (T1, T1View, T1Permissions, template, T1TabLayout) {
  "use strict";

  var _previousLocationCampaigns, _previousLocationSegments, _tabID;
  return T1View.extend({
    template: template,
    eventhubEvents: {
      "update:canUnload": function (args) {
        this.formElementsChanged = args.formElementsChanged;
        this.cancelUnload = args.cancelUnloadFn;
      },
      "tab:select": function (args) {
        var T1Location = T1.Location;
        var location;
        var currentLocation = T1Location.get(true).replace("#", "");
        if (!args.tabLabel && !this.formElementsChanged) {
          if (_tabID) {
            _tabID = 0;
            _previousLocationCampaigns = currentLocation;
            location = _previousLocationSegments ? _previousLocationSegments : "reports/segments";
          } else {
            _tabID = 1;
            _previousLocationSegments = currentLocation;
            location = _previousLocationCampaigns ? _previousLocationCampaigns : "reports/campaigns";
          }
          T1Location.set(location, {
            silent: false
          });
        }
      }
    },
    initialize: function (args) {
      _tabID = args.mode === "segments" ? 0 : 1;
      this.initLayout(args);
    },
    initLayout: function (args) {
      var tabs = [{
        module: "reporting/segments",
        options: args,
        routeName: "segments",
        viewType: "segment_reports"
      }, {
        module: "reporting/campaigns",
        options: args,
        routeName: "campaigns",
        viewType: "campaign_reports"
      }];
      this.layout = new T1TabLayout({
        disabled: [],
        destroyViewOnSwitch: true,
        ignoreRouteDepth: false,
        level: 1,
        selected: _tabID,
        tabs: tabs,
        tabSetName: "ReportingSections"
      });
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.el = self.el.find(".reporting-main-tabs");
        self.layout.load();
      });
    },
    unload: function () {
      this.layout.unload();
      this.layout.panels[this.layout.selectedContext].view = null;
    },
    canUnload: function () {
      if (this.formElementsChanged) {
        this.cancelUnload();
      }
      return !this.formElementsChanged;
    }
  });
});
