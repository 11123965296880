define('modules/reporting/segments/dataExport/createEdit/views/footer',["jQuery", "T1", "T1View", "models/segmentReport", "text!../templates/footer.html"], function ($, T1, T1View, SegmentReport, template) {
  "use strict";

  var PUBLISH;
  var T1Location = T1.Location;
  var modalOptions = {};
  var _$modal;
  var timeOut;
  return T1View.extend({
    template: template,
    events: {
      "click .clear-form": "clearInputs",
      "click .email-btn": "saveHandler",
      "click .modal-cancel-btn": "cancelDialogHandler",
      "click .modal-dont-save-btn": "dontSaveDialogHandler",
      "click .modal-save-email-xls-btn": "saveDialogHandler"
    },
    eventhubEvents: {
      "footer.updateSaveButtons": "updateSaveButtons",
      "footer.updateClearButton": "updateClearButton",
      "footer.showModal": "showModal"
    },
    initialize: function (model) {
      this.model = model;
      this.justSaved = false;
      this.segmentReport = new SegmentReport();
      PUBLISH = T1.EventHub.publish;
    },
    load: function () {
      var $el = this.el;
      var $self = this;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        $self.updateSaveButtons();
        $self.updateClearButton();
        _$modal = $el.find(".unsaved-modal");
        $self.updateClearButton();
      });
    },
    unload: function () {
      modalOptions = {};
      _$modal = null;
    },
    clearInputs: function () {
      var anySegmentsChanged = this.model.anyChanges();
      var isEmailEmpty = this.model.isEmailFieldEmpty();
      var publishName;
      if (anySegmentsChanged || !isEmailEmpty) {
        publishName = "clear" + this.model.getSelectedReport() + "Report";
        PUBLISH(publishName);
        PUBLISH("clearSegmentsReportsEmail");
      }
      this.hideMessage();
    },
    saveHandler: function () {
      var self = this;
      var data = this.model.getSelectedSegments();
      var email = this.model.getEmail();
      if (email) {
        data.email = email;
        data.async = true;
      }
      self.showSpinner();
      self.showMessage({
        msg: "Saving report...",
        type: "info",
        timeout: false
      });
      this.segmentReport.save(data, {
        report: this.model.getSelectedReport().toLowerCase(),
        success: function (req, resp) {
          var report_id, error;
          var error_msg = "";
          if (typeof resp === "string") {
            resp = JSON.parse(resp);
          }
          self.resetButtonsAndState();
          if (resp.status.code === "error") {
            if ("errors" in resp && Array.isArray(resp.errors) && resp.errors.length > 0) {
              error = resp.errors[0];
              if ("message" in error) {
                error_msg = error.message;
              }
            }
            self.showMessage({
              msg: "Report failed with message: '" + error_msg + "'",
              type: "error",
              timeout: true
            });
          } else {
            report_id = resp.data.report_id;
            self.showMessage({
              msg: "Report successfully submitted and will be delivered by email. Report ID: " + report_id + ".",
              type: "success",
              timeout: true
            });
          }
        },
        error: function () {
          self.resetButtonsAndState();
          self.showMessage({
            msg: "Save failed.",
            type: "error",
            timeout: true
          });
        },
        fail: function () {
          self.resetButtonsAndState();
          self.showMessage({
            msg: "Save failed.",
            type: "error",
            timeout: true
          });
        }
      });
    },
    showSpinner: function () {
      var spinner = '<strand-spinner radius="9" fill-color="#333333"></strand-spinner>';
      var $el = this.el;
      var $target = $el.find(".export-footer");
      this.updateSaveButtons(false);
      this.updateClearButton(false);
      $target.find(".email-btn label").html(spinner);
    },
    resetButtonsAndState: function () {
      var self = this;
      var $el = this.el;
      var $footer = $el.find(".export-footer");
      this.updateSaveButtons(false);
      this.updateClearButton(true);
      $footer.find(".email-btn label").html("Save & Email XLS");
      PUBLISH("update:canUnload", {
        formElementsChanged: false,
        cancelUnloadFn: function (args) {
          self.showModalForTabUnload.call(self, args);
        }
      });
      this.model.setSavedState(true);
    },
    updateSaveButtons: function (isChanged) {
      var $el = this.el;
      var $save = $el.find(".email-btn");
      if (isChanged === null) {
        isChanged = false;
      }
      if (isChanged) {
        $save.removeAttr("disabled");
      } else {
        $save.attr("disabled", "disabled");
      }
    },
    updateClearButton: function (isChanged) {
      var $el = this.el;
      var $clear = $el.find(".clear-form");
      if (isChanged === null) {
        isChanged = false;
      }
      this.updateFormElementsChanged(isChanged);
      this.model.setSavedState(false);
      if (isChanged) {
        $clear.removeAttr("disabled");
      } else {
        $clear.attr("disabled", "disabled");
      }
    },
    showMessage: function (args) {
      var self = this;
      var $el = this.el;
      var _$footer = $el.find(".export-footer");
      var $footer = _$footer[0];
      $footer.message = args.msg;
      $footer.type = args.type;
      $footer.showMessage();
      if (timeOut) {
        clearTimeout(timeOut);
        timeOut = null;
      }
      if (args.timeout) {
        timeOut = setTimeout(function () {
          self.hideMessage();
          timeOut = null;
        }, 2e4);
      }
    },
    hideMessage: function () {
      var $el = this.el;
      var _$footer = $el.find(".export-footer");
      var $footer;
      if (_$footer) {
        $footer = _$footer[0];
        if ($footer) {
          $footer.message = "";
          $footer.type = "";
          window.addEventListener("polymer-ready", function () {
            $footer.hideMessage();
          });
        }
      }
    },
    updateFormElementsChanged: function (state) {
      var self = this;
      this.formElementsChanged = state;
      if (state) {
        this.showMessage({
          msg: "There are unsaved changes on the page.",
          type: "warning",
          timeout: false
        });
      } else {
        this.hideMessage();
      }
      PUBLISH("update:canUnload", {
        formElementsChanged: this.formElementsChanged,
        cancelUnloadFn: function (args) {
          self.showModalForTabUnload.call(self, args);
        }
      });
    },
    showModalForTabUnload: function (args) {
      var areChangesValid = this.model.isSegmentsChanged();
      var isEmailValid = this.model.get("isEmailValid");
      var isSaveDisabled = false;
      var reportType = this.model.getSelectedReport();
      var location = T1.Location.get(true);
      var data = {};
      isSaveDisabled = areChangesValid && isEmailValid ? false : true;
      data = {
        reportType: reportType,
        isSaveDisabled: isSaveDisabled
      };
      if (args) {
        data.unloadType = "tab select";
        data.targetTabCall = args;
      } else if (location !== "#reports/segments/") {
        data.unloadType = "url";
        data.oneTruePath = location;
      }
      this.showModal(data);
    },
    showModal: function (args) {
      modalOptions = args;
      _$modal.find(".modal-save-email-xls-btn").attr("disabled", modalOptions.isSaveDisabled);
      _$modal.find(".modal-report-name").text(args.reportType);
      _$modal[0].show();
    },
    hideModal: function () {
      _$modal[0].hide();
    },
    cancelDialogHandler: function (event) {
      event.preventDefault();
      if (modalOptions.unloadType === "segment report change") {
        $(".report-select").attr("value", modalOptions.reportType);
      } else if (modalOptions.unloadType === "url") {
        T1Location.resetAbort();
      }
      this.hideModal();
    },
    dontSaveDialogHandler: function () {
      var oneTruePath;
      this.hideModal();
      this.hideMessage();
      if (modalOptions.unloadType === "segment report change") {
        $(".report-select").attr("value", modalOptions.newReportType);
        this.model.setSelectedReport(modalOptions.newReportType);
      } else if (modalOptions.unloadType === "url") {
        this.updateFormElementsChanged(false);
        oneTruePath = modalOptions.oneTruePath;
        T1.Location.set(oneTruePath);
      } else if (modalOptions.unloadType === "tab select") {
        this.updateFormElementsChanged(false);
        modalOptions.targetTabCall.onAlertClose();
      }
    },
    saveDialogHandler: function () {
      if (modalOptions.unloadType === "segment report change") {
        $(".report-select").attr("value", modalOptions.reportType);
      } else if (modalOptions.unloadType === "url") {
        T1Location.resetAbort();
      }
      this.hideModal();
      this.saveHandler();
    }
  });
});
