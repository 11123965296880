define('collections/creativesDisplayT1as',["jQuery", "T1", "T1Collection", "models/t1asAsset"], function ($, T1, T1Collection, t1asAsset) {
  T1.Models.t1asAsset = T1Collection.extend({
    model: t1asAsset,
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }]
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    urlString: "t1asAsset"
  });
  return T1.Models.t1asAsset;
});
