define('modules/T1App/myApps/views/chart_date_picker',["jQuery", "T1", "T1View", "text!modules/T1App/myApps/templates/chart_date_picker.html", "moment"], function ($, T1, T1View, template, moment) {
  "use strict";

  return T1View.extend({
    template: template,
    events: {
      "click .dates span": "openDatePickerHandler",
      "saved strand-datepicker": "saveDatesHandler",
      "mouseover .dates span": "addHoverState",
      "mouseout .dates span": "removeHoverState"
    },
    initialize: function (args) {
      var yesterday = this.getDate(1);
      var weekAgo = this.getDate(7);
      var twoWeeksAgo = this.getDate(14);
      var monthAgo = this.getDate(30);
      this.model = args.model;
      this.displayFormat = "MMM dd, yyyy";
      this.rangePresets = [{
        name: "Yesterday",
        value: "Yesterday",
        start: yesterday,
        end: yesterday
      }, {
        name: "Last 7 Days",
        value: "Last 7 Days",
        start: weekAgo,
        end: yesterday
      }, {
        name: "Last 14 Days",
        value: "Last 14 Days",
        start: twoWeeksAgo,
        end: yesterday
      }, {
        name: "Last 30 Days",
        value: "Last 30 Days",
        start: monthAgo,
        end: yesterday
      }];
    },
    load: function () {
      this.render().then(() => {
        setTimeout(() => {
          var $el = this.el;
          var datePicker = this.datePicker = $el.find(".date-picker")[0];
          datePicker.rangePresets = this.rangePresets;
          datePicker.rangeDescription = "Custom Date Range";
          datePicker.target = $el.find(".dates")[0];
          datePicker.closeLabel = "Cancel";
          datePicker.useDuration = false;
        }, 0);
      });
    },
    getDate: function (daysAgo) {
      return moment().add(-daysAgo, "days")._d;
    },
    addHoverState: function (event) {
      $(event.currentTarget).parent().addClass("onhover");
    },
    removeHoverState: function (event) {
      $(event.currentTarget).parent().removeClass("onhover");
    },
    openDatePickerHandler: function () {
      var datePicker = this.datePicker;
      var today = new Date();
      var dates = this.getDates();
      datePicker.start = dates.startDate;
      datePicker.end = dates.endDate;
      datePicker.allowedEnd = today;
      datePicker.open();
    },
    saveDatesHandler: function (event) {
      var eventData = event.originalEvent.detail;
      var newStartDate = moment(eventData.start).toDate();
      var newEndDate = moment(eventData.end).toDate();
      this.model.setStartAndEndDate(newStartDate, newEndDate);
      this.updateDate();
    },
    getDates: function () {
      var displayFormat = this.displayFormat;
      var startAndEndDate = this.model.getStartAndEndDate();
      return {
        startDate: startAndEndDate.startDate.toString(displayFormat),
        endDate: startAndEndDate.endDate.toString(displayFormat)
      };
    },
    updateDate: function () {
      var $el = this.el;
      var dates = this.getDates();
      $el.find(".start-date").text(dates.startDate);
      $el.find(".end-date").text(dates.endDate);
    },
    serialize: function () {
      var dates = this.getDates();
      var params = {
        start: dates.startDate,
        end: dates.endDate
      };
      return {
        params: params
      };
    }
  });
});
