define('modules/targeting/contextual/views/browse',["Underscore", "jQuery", "T1", "T1View", "T1Layout", "text!../templates/grid_item.html", "text!../templates/grid_footer.html", "T1GridView", "T1Spinner", "collections/targetSegmentsProviders", "modules/targeting/shared/itemSelectionBehavior", "utils/currencyConversionHelper"], function (_, $, T1, T1View, T1Layout, gridItem, gridFooter, createGrid, Spinner, TargetSegmentProviders, ItemSelectionBehavior, CurrencyUtils) {
  "use strict";

  var ContextualBrowseView = T1View.extend({
    partials: {
      listItem: gridItem,
      gridFooter: gridFooter
    },
    headers: [{
      name: "segment",
      label: "channels"
    }, {
      name: "cpm",
      label: "cpm"
    }],
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    events: {
      "click .item": "toggle",
      "click .item .icon.include": "includeTarget",
      "click .item .icon.exclude": "excludeTarget",
      "click .w-foot-c .checkbox": "toggleSettings"
    },
    eventhubEvents: {
      searchTargetSegments: function (params) {
        this.searchTerm = params.term;
        this.viewType = "search";
        this.setSearchLoader();
        this.collection.getTargetSegment(params.term, params.provider_id, this.currencyCode);
      },
      browseTargetSegments: function () {
        this.viewType = "browser";
        this.collection.getProviders();
      },
      "bins:droppedContextual": function ({
        selectedList: selectedList
      }) {
        const {
          objectsRendered: objectsRendered,
          sharedCollection: sharedCollection
        } = this;
        for (let i = 0; i < selectedList.length; i++) {
          this.addTarget(objectsRendered[selectedList[i].id] || sharedCollection.get(selectedList[i].id), selectedList[i].action);
        }
      }
    },
    dataEvents: {
      collection: {
        reset: function () {
          this.doFetch = false;
          this.load();
          T1.EventHub.publish("targetSegmentsCollectionIsLoaded", {});
        }
      },
      sharedCollection: {
        add: function (model) {
          this.sharedCollectionAddHandler(model);
        },
        remove: function (model) {
          this.sharedCollectionRemoveHandler(model);
        }
      }
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".segment"
    },
    updateSafetyCollection: function () {
      var models = [];
      var resetIds = [];
      const maturityFilter = this.$(".maturity-select").val();
      const ugcFilter = this.$(".ugc-select").val();
      if (maturityFilter !== "") {
        resetIds.push(maturityFilter);
      }
      if (ugcFilter !== "") {
        resetIds.push(ugcFilter);
      }
      resetIds = resetIds.join().split(",");
      _.each(resetIds, id => {
        if (id !== undefined && id !== "") {
          models.push(this.safetyCollection.get(id));
        }
      });
      T1.EventHub.publish("safetySettingsModified");
    },
    toggleSettings: function (evt) {
      var $currentTarget = $(evt.currentTarget);
      var isChecked = $currentTarget.is(":checked");
      const elTarget = `.${$currentTarget.data("bind")}-settings .form-list`;
      const target = `${elTarget} .disabled`;
      var templateTarget = ".advanced-settings-c .disabled";
      const selectEl = this.$(`${elTarget} .select`);
      this.$(target).remove();
      if (!isChecked) {
        selectEl.val("").trigger("change").trigger("liszt:updated");
        this.renderPartialTemplate({
          template: $("<div></div>").append($(this.partials.gridFooter).find(templateTarget).clone()).html(),
          targetEl: elTarget,
          action: "append",
          useTemplateHtml: false
        });
      } else {
        this.updateSafetyCollection();
      }
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      partialCollection: partialCollection,
      safetyCollection: safetyCollection,
      validateSafetySettings: validateSafetySettings,
      collection: collection
    }) {
      this.viewType = "browse";
      this.searchTerm = "";
      this.sharedCollection = sharedCollection;
      this.currencyCode = sharedCollection.currencyCode;
      this.partialCollection = partialCollection;
      this.objectsRendered = {};
      this.doFetch = true;
      this.safetyCollection = safetyCollection;
      this.validateSafetySettings = validateSafetySettings;
      ItemSelectionBehavior.attachBehavior(this);
      this.bindViewEvents();
      this.collection = collection;
      this.collection.fetchOptions.currency_code = this.currencyCode;
      this.collectionList = {};
    },
    load: function () {
      if (this.doFetch) {
        this.collection.fetch({
          success: () => {
            T1.EventHub.publish("strategyTargetingLoader:stop");
            T1.EventHub.publish("targetingVendorsFetched");
            T1.EventHub.publish("reloadSearchDropDown", {});
            this.render().then(() => {
              this.validateSafetySettings();
            });
          }
        });
      } else {
        this.render();
        this.doFetch = true;
      }
    },
    unload: function () {
      this.viewType = "browse";
      this.collection.getProviders({
        silent: true
      });
      if (this.paginationLayout && this.paginationLayout.unload) {
        this.paginationLayout.unload();
      }
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 300,
      editData: function (data) {
        var vendor = "targeting_vendor_id";
        var coll = this.collection;
        var firstNewVendor = coll.at(this.initCount).get(vendor);
        var lastOldVendor = coll.at(this.initCount - 1).get(vendor);
        if (firstNewVendor === lastOldVendor) {
          delete data.list[0].group;
        }
        return data;
      }
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_targeting_segment_id",
      selectionAllowableKey: "buyable",
      selectionAllowableValue: "1",
      collectionKey: "objectsRendered"
    },
    includeTarget: function (evt) {
      var id = $(evt.currentTarget).closest(".item").attr("data-id");
      var item = this.objectsRendered[id];
      this.preventDefault(evt);
      this.removeDragClasses();
      this.renderPartialTemplate({
        template: this.partials.gridFooter,
        templateTarget: ".browse-path",
        targetEl: this.$(".browse-path"),
        data: this.getPath(item.get("full_path"))
      });
      this.addTarget(item, "include");
    },
    excludeTarget: function (evt) {
      var id = $(evt.currentTarget).closest(".item").attr("data-id");
      var item = this.objectsRendered[id];
      this.preventDefault(evt);
      this.removeDragClasses();
      this.renderPartialTemplate({
        template: this.partials.gridFooter,
        templateTarget: ".browse-path",
        targetEl: this.$(".browse-path"),
        data: this.getPath(item.get("full_path"))
      });
      this.addTarget(item, "exclude");
    },
    addTarget: function (item, action) {
      let lastAction;
      const sharedCollection = this.sharedCollection;
      const itemInSharedCollection = sharedCollection.get(item.id);
      if (itemInSharedCollection) {
        const userCPMInSharedCollection = itemInSharedCollection.get("user_cpm");
        lastAction = itemInSharedCollection.get("action");
        if (userCPMInSharedCollection) {
          item.set({
            user_cpm: userCPMInSharedCollection
          });
        }
      }
      if (lastAction === action) {
        sharedCollection.remove(item);
      } else {
        if (lastAction) {
          sharedCollection.remove(item);
        }
        item.set({
          action: action
        });
        const vendorName = this.getSegmentType(item);
        if (!sharedCollection.targetingVendorOperators[vendorName]) {
          sharedCollection.targetingVendorOperators[vendorName] = sharedCollection.defaultOperators[vendorName];
        }
        sharedCollection.add(item);
      }
    },
    getSegmentType: function (item) {
      var fullPath;
      if (item.get("type") !== "retired_targeting_segment") {
        fullPath = item.get("full_path");
      } else {
        fullPath = item.get("full_retired_path");
      }
      if (fullPath.split(" - ")) {
        const vendorName = fullPath.split(" - ")[0];
        return vendorName;
      }
    },
    addChildren: function ({
      entityId = ""
    }) {
      const item = this.$(`[data-id='${entityId}']`);
      var arrow = item.find(".arrow");
      var text = item.find(".text");
      const margin = 4;
      const padding = 20;
      const indent = parseInt(arrow.css("marginLeft").replace(/[^-\d.]/g, ""), 10);
      const perCent = 100;
      const textWidth = Math.round(perCent * parseFloat(text.css("width")) / parseFloat(text.parent().css("width")));
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var collection = this.collectionList[entityId];
      const serializedData = this.serialize(collection);
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: childrenContainer,
        data: serializedData
      });
      const marginSetting = `${-(indent + padding)}px 0 ${indent + padding}px`;
      childrenContainer.find(".arrow").css("margin", `4px ${marginSetting}`);
      childrenContainer.find(".text").css("margin", `0 ${marginSetting}`).css("width", `${textWidth - margin}%`);
      this.applyDraggable();
      if (collection.count > collection.pageLimit) {
        const offset = 74;
        collection.entityType = "Segment";
        collection.parent = entityId;
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: `.pagination-${entityId}`,
          layout: {}
        });
        this.paginationLayout.layout[`.pagination-${entityId}`] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: collection,
            onSuccess: $.proxy(this.addChildren, this),
            indent: `margin: 5px -55px 0 ${indent + offset}px`
          }
        }];
        this.paginationLayout.load();
      }
    },
    toggle: function (evt) {
      var self = this;
      var item = $(evt.currentTarget);
      var arrow = item.find(".arrow");
      const itemId = String(item.data("id"));
      var iconSpinner = arrow.find(".icon-spinner");
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var paginationContainer = childrenContainer.next();
      var collectionList = this.collectionList;
      if (!arrow.length || arrow.hasClass("hide")) {
        return;
      }
      this.preventDefault(evt);
      this.renderPartialTemplate({
        template: this.partials.gridFooter,
        templateTarget: ".browse-path",
        targetEl: this.$(".browse-path"),
        data: this.getPath(item)
      });
      if (arrow.hasClass("expanded")) {
        iconSpinner.spin(false);
        arrow.removeClass("expanded loading");
        childrenContainer.hide();
        paginationContainer.hide();
        this.updateScrollBar();
      } else {
        let collection = collectionList[itemId] || new TargetSegmentProviders([]);
        arrow.addClass("loading expanded hide");
        collectionList[itemId] = collection;
        if (collection.length && childrenContainer.children().length) {
          arrow.removeClass("loading hide");
          childrenContainer.show();
          if (collection.count > collection.pageLimit) {
            paginationContainer.show();
          }
        } else {
          Spinner.apply(iconSpinner, this.spinnerOpts);
          collection.getTargetingSegments(collection.parent || itemId, this.currencyCode).done(function () {
            collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(collection, self.currencyCode);
            iconSpinner.spin(false);
            arrow.removeClass("loading hide");
            if (childrenContainer.is(":visible")) {
              self.addChildren({
                entityId: itemId
              });
            } else {
              childrenContainer.show();
              paginationContainer.show();
            }
            self.updateScrollBar();
          });
        }
      }
    },
    getPath: function (fullPath) {
      let path;
      if (typeof fullPath === "string") {
        path = fullPath.replace(/ - /g, " | ");
      } else {
        fullPath = $(fullPath);
        path = [];
        while (fullPath.hasClass("ui-draggable")) {
          path.push(fullPath.find(".segment").text().trim());
          fullPath = fullPath.closest(".children").closest(".item-wrapper").children(".ui-draggable");
        }
        path = path.reverse().join(" | ");
      }
      const browsePathStrLen = 3;
      const lastSlashIndex = path.lastIndexOf(" | ");
      const noSlash = lastSlashIndex === -1;
      const browsePath = noSlash ? "" : path.substring(0, lastSlashIndex + browsePathStrLen);
      const browsePathEnd = noSlash ? path : path.substr(lastSlashIndex + browsePathStrLen);
      return {
        browse_path: browsePath.replace(/ \| /g, " &raquo "),
        browse_path_end: browsePathEnd.replace(/ \| /g, " &raquo ")
      };
    },
    serialize: function (collection = this.collection) {
      var self = this;
      var renderedCollection = this.objectsRendered;
      var getSerializedDataForBrowseMode = function (segmentsData) {
        var segments = segmentsData;
        if (!segments) {
          segments = _.filter(collection.toJSON(), model => !model.parent_targeting_segment_id);
        }
        $.each(segments.models, function (index, model) {
          renderedCollection[model.id] = model;
        });
        function sortBySegment(a, b) {
          return a.segment.toLowerCase() > b.segment.toLowerCase() ? 1 : -1;
        }
        const contextualSegments = self.mapData(segments.toJSON()).sort(sortBySegment);
        return {
          list: contextualSegments,
          contentType: "browse-list"
        };
      };
      var getSerializedDataForSearchMode = function () {
        const result = {
          list: []
        };
        var utilsObj = T1.Utils;
        var boldLastPartOfFullPath = utilsObj.boldLastPartOfFullPath;
        var childCountGetter = function () {
          return false;
        };
        CurrencyUtils.updateCpmTargetingValuesWithCurrency(collection, self.currencyCode);
        $.each(collection.models, function (index, model) {
          renderedCollection[model.id] = model;
        });
        const contextualInformation = _.groupBy(collection.toJSON(), model => model.full_path.split(" - ")[0]);
        $.each(contextualInformation, function (vendor, targets) {
          var searchTerm = self.searchTerm;
          var displayNameGetter = function (model) {
            var text = model.full_path;
            text = text.replace(`${vendor} - `, "");
            text = text.replace(/ - /g, " &raquo ");
            const name = boldLastPartOfFullPath.call(utilsObj, {
              fullPath: text,
              separator: "&raquo",
              searchTermToBeHighlighted: searchTerm ? searchTerm : null
            });
            return name;
          };
          let groupResult = self.mapData(targets, displayNameGetter, childCountGetter);
          groupResult = _.filter(groupResult, model => model.buyable);
          if (groupResult.length) {
            groupResult[0].group = vendor;
            result.list = result.list.concat(groupResult);
          }
        });
        return result;
      };
      if (this.viewType === "search") {
        return $.extend({}, getSerializedDataForSearchMode());
      }
      return $.extend({}, getSerializedDataForBrowseMode(collection));
    },
    mapData: function (segments, displayNameGetter, childCountGetter) {
      var self = this;
      var segmentsArray = segments;
      const currencyCode = this.currencyCode;
      if (!$.isArray(segmentsArray) && $.isPlainObject(segments)) {
        segmentsArray = [segments];
      }
      return $.map(segmentsArray, function (segment) {
        let cpmValue;
        if (segment.retail_cpm_amount && segment.retail_cpm_amount.length > 1) {
          $.each(segment.retail_cpm_amount, function (index, curVal) {
            if (curVal.currency_code === currencyCode) {
              cpmValue = curVal.value;
            }
          });
        } else {
          cpmValue = segment.retail_cpm;
        }
        const tmpModelJSON = {
          id: segment.id || "",
          segment: displayNameGetter ? displayNameGetter(segment) : segment.name || "",
          cpm: cpmValue || "--",
          child_count: childCountGetter ? childCountGetter(segment) : parseInt(segment.child_count || "0", 10) > 0,
          buyable: (segment.buyable || "0") === "1",
          asCurrency: T1.Utils.getCurrencyTemplateFunc(currencyCode),
          isIncluded: false,
          isExcluded: false
        };
        self.applySelectionStates(tmpModelJSON);
        return tmpModelJSON;
      });
    }
  });
  return createGrid(ContextualBrowseView);
});
