define('modules/audiences/segments/bulkCreate/views/bulkCreate',["jQuery", "Underscore", "Backbone", "When", "Hogan", "T1", "T1Permissions", "models/segment", "models/behavior", "models/pixel", "text!modules/audiences/segments/bulkCreate/templates/bulkCreate.html", "../models/bulkSegments", "../views/bulkErrors", "modules/audiences/segments/segmentUtils"], function ($, _, Backbone, When, Hogan, T1, T1Permissions, Segment, Behavior, Pixel, template, bulkSegmentsModel, BulkErrorsView, Util) {
  "use strict";

  function updateBreadCrumb() {
    var pathPieces = [{
      label: "Bulk Create",
      location: "#audiences/segments/bulkCreate/"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  var BULK_CREATE_DISABLED_MESSAGE = "This feature is not currently available.";
  var SEGMENTS_URL = "#audiences/segments/";
  var bulkCreateView = Backbone.View.extend({
    template: template,
    events: {
      "click #submit-bulk-segments": "submit",
      "click #browse-bulk-segments": "browse",
      "change #file-browser": "fileBrowserHandler",
      "click #done-button": "backToSegments"
    },
    initialize: function () {
      Util.jQueryExtend();
    },
    load: function () {
      updateBreadCrumb();
      this.render();
      T1.EventHub.subscribe("organization:select", this.orgChanged);
    },
    render: function () {
      var renderedTemplate = Hogan.compile(this.template).render({
        version: Util.bulkSegmentsCurrentVersion,
        canViewBulkCreateSegments: T1Permissions.check("feature", "segments_bulk_create"),
        bulkCreateDisabledMessage: BULK_CREATE_DISABLED_MESSAGE,
        saveCompleteMessage: self.saveCompleteMessage,
        model: "{{model}}",
        scope: "{{scope}}",
        segmentLink: SEGMENTS_URL + "{{model.segmentID}}",
        saved: "{{model.saved}}",
        failed: "{{!model.saved}}",
        placeholder: "Select file",
        format: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        versionError: "Please download the latest spreadsheet.",
        currentVersion: Util.bulkSegmentsCurrentVersion,
        kbLink: "https://mediamathsupport.force.com/s/article/Segments-Overview"
      });
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = renderedTemplate;
      } else {
        $(this.el).html(renderedTemplate);
      }
      this.grid = document.querySelector("#bulk-results-grid");
      this.footer = document.querySelector("strand-footer");
      T1.resizeWidth();
    },
    browse: function () {
      this.el.find("#file-browser").trigger("click");
    },
    backToSegments: function () {
      T1.Location.set(SEGMENTS_URL);
    },
    fileBrowserHandler: function (e) {
      var button = this.el.find("#submit-bulk-segments");
      var input = document.querySelector("#file-browser-display");
      this.clearReports();
      this.clearData();
      if (e.target.files.length > 0) {
        $(input).val(e.target.files[0].name);
      } else {
        input.clearInput();
      }
      this.model = new bulkSegmentsModel();
      if (e.target.files.length) {
        button.removeAttr("disabled");
      } else {
        button.attr("disabled", "");
      }
    },
    clearAfterSubmit: function () {
      var fileDisplay = this.el.find("#file-browser-display");
      var fileName = fileDisplay.attr("value");
      fileDisplay[0].clearInput();
      this.el.find("#file-browser").val("");
      fileDisplay.attr("placeholder", fileName);
    },
    submit: function (e) {
      var self = this;
      var doc = document.querySelector("#file-browser").files;
      var doneButton = this.el.find("#done-button");
      var errors;
      this.clearData();
      this.clearReports();
      doneButton.attr("disabled", true);
      Util.buttonLoader(e);
      this.model.parse(doc).then(function () {
        return self.model.convertStandardAttributes();
      }).then(function () {
        errors = self.model.validate();
        if (_.isEmpty(errors)) {
          self.batchSaveSegments();
        } else {
          self.displayValidationErrors(errors);
        }
        self.clearAfterSubmit();
      }, function (error) {
        self.clearAfterSubmit();
        self.displayTemplateError(error);
      });
    },
    clearData: function () {
      this.savedSegments = [];
      this.failedSegments = [];
      this.grid.data = [];
    },
    clearReports: function () {
      var $el = $(this.el);
      var input = document.querySelector("#file-browser-display");
      input.error = false;
      $el.find("#template-error").hide();
      $(".sticky-container").remove();
    },
    displayValidationErrors: function (errors) {
      var button = this.el.find("#submit-bulk-segments");
      if (errors.versionError) {
        this.displayTemplateError(errors.versionError);
      }
      if (errors.globalErrors || errors.segmentErrors) {
        this.displayErrors(errors);
        Util.buttonReset(button, "Submit");
      }
    },
    displayErrors: function (errors) {
      var el = this.el.find("#bulk-errors-el");
      new BulkErrorsView({
        el: el,
        errors: errors
      });
    },
    displayTemplateError: function () {
      var input = document.querySelector("#file-browser-display");
      var errorEl = document.querySelector("#template-error");
      var button = $(this.el).find("#submit-bulk-segments");
      Util.buttonReset(button, "Submit");
      $(errorEl).attr("style", "display:block;");
      input.error = true;
    },
    displaySaveComplete: function () {
      var self = this;
      var $el = $(this.el);
      var button = $el.find("#submit-bulk-segments");
      var doneButton = $el.find("#done-button");
      var messageObj = createMessage();
      T1.Notify(messageObj.type, messageObj.text, messageObj.duration, false);
      doneButton.removeAttr("disabled");
      Util.buttonReset(button, "Submit");
      function createMessage() {
        var message = {
          text: "Bulk Create Complete. ",
          duration: 60 * 1e3
        };
        var numberSaved = self.savedSegments.length;
        var numberFailed = self.failedSegments.length;
        if (numberSaved === 0) {
          message.type = "error";
          message.text += "No segments were created.";
        } else if (numberFailed === 0) {
          message.type = "message";
          message.text += "All segments successfully created.";
        } else {
          message.type = "warning";
          message.text = numberSaved + " segment(s) were successfully created. " + numberFailed + " segment(s) could not be created.";
        }
        return message;
      }
    },
    batchSaveSegments: function () {
      var self = this;
      var bulkObj = this.model;
      var rows = bulkObj.segmentRows;
      var i = 0;
      function asyncLoop(callback) {
        callback(rows[i]).always(function () {
          i++;
          if (i < rows.length) {
            asyncLoop(callback);
          }
        });
      }
      function saveCallback(row) {
        return bulkObj.saveSegment(row).then(function (savedSegmentObj) {
          self.savedSegments.push(savedSegmentObj);
          self.displaySegment(savedSegmentObj, {
            saved: true
          });
          Promise.resolve();
        }, function (failedSegmentObj) {
          self.failedSegments.push(failedSegmentObj);
          self.displaySegment(failedSegmentObj, {
            saved: false
          });
          Promise.resolve();
        });
      }
      asyncLoop(saveCallback);
    },
    displaySegment: function (segment, options) {
      var saved = options.saved;
      var segmentID = saved ? segment.id : "n/a";
      var model = {
        bulk_number: segment.bulkID,
        segment_name: segment.name,
        segmentID: segmentID,
        saved: saved
      };
      this.grid.push("data", model);
      if (this.grid.data.length === this.model.segmentRows.length) {
        this.displaySaveComplete();
      }
    },
    orgChanged: function () {
      T1.Location.reload();
    },
    unload: function () {
      T1.EventHub.unsubscribe("organization:select");
    }
  });
  return bulkCreateView;
});
