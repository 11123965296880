define('modules/user/main',["jQuery", "T1", "T1Module", "models/sessionUser", "models/feedback"], function ($, T1, T1Module, UserModel, Feedback) {
  var user = UserModel.getUser();
  return new T1Module({
    name: "user",
    viewCfgs: {
      badge: {
        model: user
      },
      feedback: {
        model: null
      },
      feedbackForm: {
        model: new Feedback()
      },
      tools: {
        model: null
      }
    },
    defaultView: "badge"
  });
});
