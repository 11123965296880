define('modules/vertical/views/select',["jQuery", "T1", "T1View", "text!modules/vertical/templates/select.html", "T1Selectbox"], function ($, T1, T1View, template) {
  return T1View.extend({
    template: template,
    initialize: function (args) {
      var self = this;
      var collection = this.collection;
      self.currentId = args.id;
      self.model = args.model;
      collection.fetch();
      collection.bind("reset", self.load, self);
    },
    selected: null,
    fetchRequired: true,
    load: function () {
      var selectBox;
      var self = this;
      var model = self.model;
      var selectBoxConfig = {
        data: {},
        onChange: $.noop
      };
      if (self.currentId) {
        self.selected = self.currentId;
      } else if (model) {
        self.selected = model ? model.id : undefined;
      }
      self.render().then(function () {
        selectBox = $(self.el).find(".select-input");
        T1.Selectbox(selectBox, selectBoxConfig);
      });
    },
    reload: function () {
      this.load();
    },
    serialize: function () {
      var self = this;
      var verticals = self.collection;
      var result = [];
      var obj = {};
      verticals.each(function (vertical) {
        obj = vertical.toJSON();
        if (vertical.id === self.selected) {
          obj.selected = "selected";
        }
        result.push(obj);
      });
      return {
        options: result
      };
    }
  });
});
