define('modules/nav/views/breadcrumb',["jQuery", "T1", "T1View", "text!modules/nav/templates/breadCrumb.html"], function ($, T1, T1View, template) {
  "use strict";

  var BreadcrumbView;
  var currentLinks;
  var MMConfig = {
    label: "Campaigns Home",
    location: "#monitor"
  };
  BreadcrumbView = T1View.extend({
    template: template,
    locationMapping: {
      campaign: MMConfig,
      strategy: MMConfig,
      monitor: MMConfig,
      reports: {
        label: "Reports",
        location: "#reports",
        supressLink: true
      },
      creatives: {
        label: "Creatives",
        location: "#creatives"
      },
      appstore: {
        label: "Appstore",
        location: "#appstore"
      },
      media: {
        label: "Media",
        location: "#media"
      },
      audiences: {
        label: "Audiences",
        location: "#audiences"
      },
      admin: {
        label: "Admin",
        location: "#admin"
      },
      segments: {
        label: "Audiences",
        location: "#audiences"
      },
      pixels: {
        label: "Audiences",
        location: "#audiences"
      }
    },
    eventhubEvents: {
      updateNavPath: function (data) {
        var module = T1.Location.get().substr(1);
        var locations = data && data.locations ? data.locations : [];
        var links = [this.locationMapping[module]];
        this.links = links.concat(locations);
        this.headerInfo = data && data.headerInfo ? data.headerInfo : {};
        this.data = data;
        this.load();
        currentLinks = this.links;
      },
      updateNavPathNewT1: function (data) {
        var locations = data && data.locations ? data.locations : [];
        this.links = locations;
        this.headerInfo = data && data.headerInfo ? data.headerInfo : {};
        this.data = data;
        this.load();
        currentLinks = this.links;
      }
    },
    initialize: function () {
      this.links = [];
    },
    serialize: function () {
      var links = this.links || [];
      var returnObj = {};
      var length = links.length;
      $.each(links, function (index, link) {
        if (index !== length - 1) {
          delete link.last;
        } else {
          link.last = true;
          link.secondLevel = index === 1;
        }
      });
      returnObj.links = links;
      returnObj.headerInfo = this.headerInfo;
      return returnObj;
    }
  });
  BreadcrumbView.getCurrentLinks = function () {
    return currentLinks;
  };
  return BreadcrumbView;
});
