define('modules/audiences/segments/bulkCreate/views/bulkErrors',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "text!modules/audiences/segments/bulkCreate/templates/bulkErrors.html"], function ($, _, Backbone, Hogan, T1, template) {
  "use strict";

  var bulkErrorsView = Backbone.View.extend({
    template: template,
    events: {
      "click #bulk-segment-errors-close": "close"
    },
    initialize: function (options) {
      this.errors = options.errors;
      this.render();
    },
    render: function () {
      var $el = $(this.el);
      this.compiledTemplate = Hogan.compile(this.template);
      this.compiledErrors = this.compileErrors(this.errors);
      $el.html(this.compiledTemplate.render({
        detail: this.compiledErrors
      }));
      this.modal = document.querySelector("#bulk-segment-errors-modal");
      this.modal.show();
      T1.resizeWidth($el);
    },
    compileErrors: function (errors) {
      var compiledErrors = [];
      if (errors.globalErrors) {
        $.each(errors.globalErrors, function (i, error) {
          compiledErrors.push(error);
        });
      }
      if (errors.segmentErrors) {
        $.each(errors.segmentErrors, function (i, error) {
          var errorMessage;
          errorMessage = "The segment with the " + error.marker + " " + error.id + ": " + error.errors.join(", ");
          compiledErrors.push(errorMessage);
        });
      }
      return compiledErrors.join("\n");
    },
    close: function () {
      this.modal.hide();
    }
  });
  return bulkErrorsView;
});
