define('modules/reporting/campaigns/dataExport/models/saveReport',["T1", "Backbone"], function (T1, Backbone) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  T1.Models.SaveReport = Backbone.Model.extend({
    apiRoot: T1.RPT_SAVED_REPORTS_API_ROOT,
    initialize: function () {},
    fetch: function (options) {
      if (!(options && options.id)) {
        throw new Error("ID required for report fetch functionality");
      }
      options.method = "GET";
      options.url = this.apiRoot + options.id;
      this.sync(options);
    },
    save: function (options) {
      const isNew = this.isNew();
      const apiRoot = this.apiRoot;
      if (!(options && options.data)) {
        throw new Error("Data object required for report save functionality");
      }
      options.method = isNew ? "POST" : "PUT";
      options.url = isNew ? apiRoot : apiRoot + this.id;
      this.sync(options);
    },
    destroy: function (options) {
      if (!(options && options.id)) {
        throw new Error("ID required for report destroy functionality");
      }
      options.method = "DELETE";
      options.url = this.apiRoot + options.id;
      this.sync(options);
    },
    sync: function (options) {
      const model = this;
      let strandAjax = new Strand.Ajax();
      const successCallback = options.success || function () {};
      const progressCallback = options.progress;
      const errorCallback = options.error;
      const fetchObject = {};
      this.abort();
      function successHandler(event) {
        const response = event.detail.response;
        model.set(response, options);
        successCallback(model, response);
      }
      function progressHandler(event) {
        const progress = event.detail;
        progressCallback(progress.percent, progress.current, progress.total);
      }
      function errorHandler(event) {
        const errorInfo = event.detail;
        const response = errorInfo && errorInfo.response;
        let detail;
        if (errorCallback) {
          errorCallback(event);
        } else {
          detail = response ? `${response.errors[0].message}: ${response.errors[0].reason}` : "";
          T1Publish("comm:error", {
            code: errorInfo.error,
            detail: detail
          });
        }
      }
      if (options.data) {
        fetchObject.body = options.data;
      }
      fetchObject.contentType = "application/json";
      fetchObject.method = options.method;
      fetchObject.url = options.url;
      fetchObject.withCredentials = true;
      if (localStorage.getItem("mm.bogo.acc")) {
        fetchObject.headers = [{
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("mm.bogo.acc")}`
        }];
      }
      strandAjax.addEventListener("data-ready", successHandler);
      strandAjax.addEventListener("data-error", errorHandler);
      if (progressCallback) {
        strandAjax.addEventListener("data-progress", progressHandler);
      }
      this.strandAjax = strandAjax;
      strandAjax.exec(null, fetchObject).then(function () {
        strandAjax.removeEventListener("data-ready", successHandler);
        strandAjax.removeEventListener("data-error", errorHandler);
        if (progressCallback) {
          strandAjax.removeEventListener("data-progress", progressHandler);
        }
        strandAjax = null;
      });
    },
    abort: function () {
      if (this.strandAjax) {
        this.strandAjax.abort();
      }
    }
  });
  return T1.Models.SaveReport;
});
