define('modules/media/dealAnalytics/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  let instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "media/dealAnalytics",
      viewCfgs: {
        dealAnalytics: {}
      },
      defaultView: "dealAnalytics"
    });
  }
  return instance;
});
