define('models/strategyTargetDimensions',["Underscore", "jQuery", "T1", "Backbone", "T1Model", "models/targetDimension", "T1Collection"], function (_, $, T1, Backbone, T1Model, TargetDimension, T1Collection) {
  "use strict";

  T1.Models.StrategyTargetDimensions = T1Model.extend({
    url: "strategies",
    fixture: "-strategy_target_values",
    relations: [{
      type: Backbone.HasMany,
      key: "target_dimensions",
      relatedModel: "TargetDimension",
      collectionType: "T1Collection",
      includeInJSON: false
    }, {
      type: Backbone.HasMany,
      key: "partial",
      relatedModel: "T1Model",
      collectionType: "T1Collection",
      includeInJSON: false
    }],
    parse: function (data) {
      var result = {
        target_dimensions: [],
        partial: []
      };
      var dimensionsHash = {};
      var dataDimensions = data.dimension || [];
      function transformDimension(dimension) {
        var entities = dimension.entities;
        var code = dimension.code;
        var target_dimension = dimensionsHash[code] || {
          id: code,
          code: code,
          target_value: []
        };
        var target_values = target_dimension.target_value;
        if (entities && !$.isArray(entities)) {
          entities = [entities];
        }
        $.each(entities, function (index, entityArray) {
          var ents = T1Collection.prototype.parse({
            entities: entityArray
          });
          $.each(ents, function (idx, entity) {
            entity.dimension_code = code;
            entity.action = entityArray.set === "INCLUDE" ? "include" : "exclude";
          });
          target_values = target_values.concat(ents);
          target_dimension[`${entityArray.set}_OP`] = entityArray.operator;
          target_dimension.target_value = target_values;
        });
        target_dimension.INCLUDE_OP = target_dimension.INCLUDE_OP || "OR";
        target_dimension.EXCLUDE_OP = target_dimension.EXCLUDE_OP || "AND";
        if (target_dimension.code === "USZC" && parseInt(dimension.count, 10) > 0) {
          target_dimension.target_value = [{
            action: dimension.entities.set.toLowerCase(),
            type: "target_value",
            dimension_code: "USZC",
            id: "USZC",
            name: "postal code",
            count: dimension.count
          }];
        }
        dimensionsHash[code] = target_dimension;
        return target_dimension;
      }
      if (dataDimensions && !$.isArray(dataDimensions)) {
        dataDimensions = [dataDimensions];
      }
      $.each(dataDimensions, function (index, dimension) {
        transformDimension(dimension);
      });
      $.each(dimensionsHash, function (key, dimension) {
        result.target_dimensions.push(dimension);
      });
      if (data.entities) {
        result.partial = T1Collection.prototype.parse({
          entities: data.entities
        });
      }
      return result;
    },
    fetch: function (opts) {
      var args = opts || {};
      var success = args.success || $.noop;
      var self = this;
      args.success = function (data) {
        self.initTargetDimensions();
        success(data);
        T1.EventHub.publish("strategyTargetDimension:reset", self);
      };
      args.params = $.extend(true, {
        full: "*",
        partial: 1
      }, args.params || {});
      args.action = "target_values";
      return T1Model.prototype.fetch.call(this, args);
    },
    targetDimensions: ["CSPD", "ISPX", "BSER", "OSXX", "DVCE", "INVT", "WURF", "SFTY", "CHNL", "USZC", "REGN", "DMAX", "FOLD", "VPSZ", "VSKP", "VLIN", "VCON", "VAUD", "WURFL/OS", "VPLC"],
    initTargetDimensions: function () {
      var targetDimensions = this.get("target_dimensions");
      var targetDimension = this.targetDimensions;
      var i;
      function createTargetDimension(code) {
        return {
          INCLUDE_OP: "OR",
          EXCLUDE_OP: "AND",
          target_value: [],
          code: code,
          id: code
        };
      }
      for (i = 0; i < targetDimension.length; i++) {
        if (!targetDimensions.get(targetDimension[i])) {
          targetDimensions.add(createTargetDimension(targetDimension[i]), {
            silent: true
          });
        }
      }
      return targetDimensions;
    },
    canEdit: function () {
      var permissionDerferred = $.Deferred();
      permissionDerferred.resolve(true);
      return permissionDerferred.promise();
    },
    buildPostObj: function (dimensionCodeFilter) {
      var targetDimensions = this.get("target_dimensions");
      var postObj = {};
      var counter = 1;
      var prefix = "dimensions.";
      targetDimensions.each(function (dimension) {
        var targetValues = dimension.get("target_value");
        var includes = [];
        var excludes = [];
        var dimensionJSON = dimension.toJSON();
        var needToCollectData = true;
        var valueObj;
        if (dimensionCodeFilter && dimensionCodeFilter !== dimension.id) {
          needToCollectData = false;
        }
        if (needToCollectData === true) {
          if (dimension.id === "USZC") {
            return;
          }
          targetValues.each(function (value) {
            valueObj = value.toJSON();
            if ((valueObj.is_targetable === "1" || valueObj.dimension === "FOLD" || valueObj.dimension === "SFTY") && valueObj.dimension_code !== "CHNL" && valueObj.dimension_code !== "HYPL" && valueObj.dimension_code !== "hyperlocal") {
              if (valueObj.action === "include") {
                includes.push(value.id);
              } else {
                excludes.push(value.id);
              }
            }
          });
          includes.reverse();
          excludes.reverse();
          if (includes.length) {
            postObj[`${prefix + counter}.code`] = dimensionJSON.code;
            postObj[`${prefix + counter}.restriction`] = "INCLUDE";
            postObj[`${prefix + counter}.operation`] = targetValues.INCLUDE_OP || "OR";
            postObj[`${prefix + counter}.value_ids`] = includes.join(",");
            counter++;
          }
          if (excludes.length) {
            postObj[`${prefix + counter}.code`] = dimensionJSON.code;
            postObj[`${prefix + counter}.restriction`] = "EXCLUDE";
            postObj[`${prefix + counter}.operation`] = targetValues.EXCLUDE_OP || "OR";
            postObj[`${prefix + counter}.value_ids`] = excludes.join(",");
            counter++;
          }
        }
      });
      return postObj;
    },
    saveTargetValues: function (successCallback, invalidCallBack, conflict, preBuiltObj, targetView) {
      var strategy = this.strategy;
      var postObj;
      if (preBuiltObj !== undefined && _.isEmpty(preBuiltObj) !== true) {
        postObj = preBuiltObj;
      } else {
        postObj = this.buildPostObj();
      }
      if ($.isEmptyObject(postObj) && targetView && !targetView.wipeTargetValues && targetView.zipcodecount < 0 && targetView.ipTargetsCount < 0) {
        invalidCallBack([], "You will be wiping out all target values!", undefined, undefined, {
          wipeTargetValues: true
        });
      } else {
        if ($.isEmptyObject(postObj)) {
          postObj._ = "true";
        }
        T1Model.prototype.save.call(this, postObj, {
          action: "target_values",
          success: function () {
            var callback = successCallback || $.noop;
            callback();
            if (strategy) {
              strategy.fetch();
            }
          },
          conflict: function () {
            var callback = conflict || $.noop;
            callback();
            if (strategy) {
              strategy.fetch();
            }
          },
          invalid: invalidCallBack || $.noop
        });
      }
    },
    save: function (postObj, opt) {
      if (opt === undefined || opt === null) {
        this.saveTargetValues();
        return;
      }
      const successCallback = opt.success || $.noop;
      const invalidCallBack = opt.invalid || $.noop;
      const conflict = opt.conflict || $.noop;
      this.saveTargetValues(successCallback, invalidCallBack, conflict);
    }
  });
  return T1.Models.StrategyTargetDimensions;
});
