define('modules/admin/creatives/views/components',['require','collections/adminCreativeComponents','T1','T1Layout','T1View','text!../templates/components.html','When'],function (require) {
  "use strict";

  const AdminCreativeComponentCollection = require("collections/adminCreativeComponents");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const tmpl = require("text!../templates/components.html");
  const When = require("When");
  let ComponentsView = T1View.extend({
    template: tmpl,
    eventhubEvents: {
      "component:list:updated": function () {
        this.isFormElementsChanged = true;
      }
    },
    isFormElementsChanged: false,
    initialize() {
      this.componentCollection = new AdminCreativeComponentCollection();
      this.componentCollection.comparator = "component_id";
      this.canLoad = false;
      this.fetchCreativeComponents().then(() => {
        this.canLoad = true;
        this.initLayout();
        this.load();
      });
    },
    fetchCreativeComponents() {
      const deferred = When.defer();
      this.componentCollection.fetch({
        success: function (data) {
          deferred.resolve(data);
        },
        statusInvalid: function () {
          deferred.reject();
        }
      });
      return deferred.promise;
    },
    initLayout() {
      if (!this.canLoad) {
        return;
      }
      const listLayout = {
        ".components-list": [{
          module: "admin/creatives",
          viewType: "components-list",
          options: {
            collection: this.componentCollection
          }
        }]
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".list-body",
        template: "<div />",
        layout: listLayout
      });
    },
    setFormActions() {
      this.actionsConfig.formActions = [{
        class: "cancel",
        label: "cancel",
        location: "#"
      }, {
        class: "save btn-primary",
        label: "save",
        location: false
      }];
    },
    load() {
      if (this.canLoad) {
        this.setFormActions();
        this.render().then(() => {
          if (this.layout) {
            this.layout.load();
          }
        });
      }
    },
    preparePostData(formValues) {
      const $componentsList = this.$("#creative-component-list");
      const componentsListView = $componentsList[0];
      const listData = componentsListView.data;
      this.componentCollection.reset(listData, {
        silent: true
      });
      return formValues;
    },
    postData(formValues, success, statusInvalid, conflict) {
      const self = this;
      const $componentsList = this.$("#creative-component-list");
      const componentsListView = $componentsList[0];
      let deferred;
      const queue = [];
      const completedList = [];
      const failedList = [];
      let validationErrors = [];
      let errors = [];
      function handleSuccess() {
        success(completedList);
        T1.Notify("message", "Components updated successfully!");
      }
      function handleError() {
        const failedCount = failedList.length;
        const completedCount = completedList.length;
        const totalCount = completedCount + failedCount;
        statusInvalid(failedList);
        T1.Notify("warning", `${completedCount} of ${totalCount} component(s) updated successfully!`);
        T1.Notify("error", `${failedCount} of ${totalCount} component(s) failed to update.`);
      }
      this.componentCollection.each(model => {
        validationErrors = model.validateAttrs(model.toJSON());
        if (validationErrors && validationErrors.length) {
          this.showErrors(model, validationErrors, `.components-list ._component_list_item[id='${model.get("index")}']`);
          errors = errors.concat({
            model: model,
            errors: validationErrors
          });
        }
      });
      if (errors && errors.length) {
        statusInvalid([], null, ".components-list");
        return;
      }
      this.componentCollection.forEach(function (model) {
        const componentID = model.get("component_id");
        const action = model.get("action");
        if (action === "changed" || action === "new") {
          deferred = model.createEdit(model.toJSON(), {
            success: function (collectionModel, response) {
              const data = response.data;
              if (!componentID || componentID === "") {
                model.set(data);
                componentsListView.set(`data.${model.get("index")}.component_id`, data.component_id);
              }
              completedList.push(model || data);
            },
            statusInvalid: function () {},
            conflict: function (...args) {
              conflict.apply(self, args);
            },
            error: function (errorModel, response) {
              const jsonData = response.jsonData;
              let fieldErrors;
              if (jsonData && jsonData.errors) {
                fieldErrors = jsonData.errors.field_error;
                if (fieldErrors) {
                  fieldErrors = fieldErrors.map(function (error) {
                    return {
                      field: error.name,
                      message: error.error
                    };
                  });
                  self.showErrors(errorModel, fieldErrors, `.components-list ._component_list_item[id='${errorModel.get("index")}']`);
                }
              }
              failedList.push(errorModel);
            }
          });
          queue.push(deferred);
        }
      });
      Promise.all(queue).then(handleSuccess).catch(handleError);
    },
    saveAndClose(evt) {
      const callback = {
        success: function () {
          const delay = 1e3;
          setTimeout(function () {
            T1.Location.set("#admin/myOrgs");
          }, delay);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    cancelClicked(evt) {
      evt.preventDefault();
      if (this.isNew) {
        this.isFormElementsChanged = false;
        this.load();
      } else {
        const delay = 1e3;
        setTimeout(function () {
          T1.Location.set("#admin/myOrgs");
        }, delay);
      }
    },
    canUnload() {
      const isFormElementsChanged = this.isFormElementsChanged;
      const cancelHandler = {
        onAlertClose: function () {
          const T1Location = T1.Location;
          const abortedUrl = T1Location.getAbortedURL();
          if (abortedUrl === "" || abortedUrl === false) {
            setTimeout(function () {
              T1Location.set("#admin/myOrgs");
            }, 1);
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: "Components"
        }, cancelHandler);
      }
      return !isFormElementsChanged;
    }
  });
  ComponentsView = T1.Form.ViewUtils.extend(ComponentsView);
  return ComponentsView;
});
