define('T1Versions',["jQuery", "T1", "T1Model", "T1Comm"], function ($, T1, T1Model, T1Comm) {
  var Versions;
  if (T1.VERSIONS) {
    return T1.VERSIONS;
  }
  Versions = T1Model.extend({
    name: "versions",
    url: "version/",
    fixture: "-versions",
    parse: function (response) {
      var output = {};
      output.api_build = response.version.build;
      output.api_release = response.version.release;
      return output;
    },
    initialize: function () {
      var self = this;
      this.set({
        compass_version: COMPASS_ENV.VERSION
      });
      this.set({
        compass_build: COMPASS_ENV.BUILD
      });
      this.fetch();
    }
  });
  T1.VERSIONS = new Versions();
  return T1.VERSIONS;
});
