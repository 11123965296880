define('modules/reporting/campaigns/technology/views/controls',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/controls.html", "T1Layout", "T1Animate"], function ($, T1, T1View, T1Permissions, template, T1Layout, T1Animate) {
  "use strict";

  const ControlsView = T1View.extend({
    selectedColumns: null,
    template: template,
    events: {
      "click .columns": "showColumnSelector",
      "click .toggle-btn": "toggleChart",
      "keyup input": "searchStrategyInGrid"
    },
    dataEvents: {
      reportModel: {
        "change:columnKeys": "updateMetadataSortKeys",
        "change:sortKeyInfo": "resortCollection",
        "change:campaignBenchmarkOn": function () {
          T1.EventHub.publish("preference:change", {
            key: "campaignBenchmarkOn",
            value: String(this.reportModel.get("campaignBenchmarkOn"))
          });
        }
      }
    },
    initialize(args) {
      const {
        models: models,
        type: type,
        dataGroup: dataGroup,
        timeSeries: timeSeries
      } = args;
      const {
        campaign: campaign,
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel
      } = models;
      Object.assign(this, {
        campaign: campaign,
        cfgs: args,
        collection: dataGroup.collection,
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel,
        summaryCollection: dataGroup.dim1Collection,
        timeSeries: this.type === "series" ? timeSeries : "summary",
        type: type
      });
    },
    load() {
      const {
        el: $el,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel
      } = this;
      const reportMetaObj = $.extend({}, reportMeta.toJSON());
      const reportModelObj = $.extend({}, reportModel.toJSON());
      const {
        focus = "all",
        preferences: preferences
      } = reportModelObj;
      const getFocusKey = reportModel.makeFocusKeyFunc(focus);
      const selectedColumns = preferences.get(getFocusKey("selectedColumns"));
      const sortKeyInfo = preferences.get(getFocusKey("sortKeyInfo"));
      const campaignBenchmarkOn = preferences.get("campaignBenchmarkOn");
      const campaignGoalType = mainModel.get("multiCampaigns") ? "multi" : this.campaign.get("goal_type");
      reportModel.setColumnKeys(selectedColumns === undefined || selectedColumns === "" ? reportModelObj.defaultColumnKeys : selectedColumns.split(","));
      reportModel.setSortKeyInfo(sortKeyInfo === undefined || sortKeyInfo === "" ? reportMeta.get("defaultSortForGoalType")[campaignGoalType] || "" : sortKeyInfo);
      reportModel.setCampaignBenchmarkState(campaignBenchmarkOn === undefined || campaignBenchmarkOn === "" ? true : campaignBenchmarkOn === "true");
      Object.assign(this, {
        getFocusKey: getFocusKey,
        reportMetaObj: reportMetaObj,
        reportModelObj: reportModelObj
      });
      this.updateMetadataSortKeys();
      this.chartShown = mainModel.get("chartShown") === "true";
      this.render().then(() => {
        this.chartCover = $el.find(".chart-cover");
        this.reportControls = $el.closest(".report-controls");
        this.reportPanel = this.reportControls.closest(".w-report-panel");
        this.reportChartFilters = this.reportPanel.closest(".tabs-panel").children(".report-chart-filters");
        this.toggleReportPanel();
        if (this.chartShown) {
          this.reportChartFilters.removeClass("hidden");
        }
      });
    },
    unload() {
      this.chartCover = null;
      this.reportControls = null;
    },
    prepareMultiSortableData() {
      const {
        reportMeta: reportMeta,
        reportModelObj: reportModelObj,
        selectedColumns: selectedColumns
      } = this;
      const allColumnsObj = $.extend({}, reportMeta.additionalData.columns[reportModelObj.focus]);
      const displayMetrics = Object.keys(reportMeta.getDisplayMetrics());
      const selectedKeys = selectedColumns ? selectedColumns.split(",") : reportModelObj.defaultColumns;
      Object.entries(allColumnsObj).forEach(([key, val]) => {
        if (val.featureFlag && !T1Permissions.check("feature", val.featureFlag)) {
          delete allColumnsObj[key];
        }
        if (val.section && !val.section.includes(reportModelObj.key)) {
          delete allColumnsObj[key];
        }
        if (!displayMetrics.includes(key)) {
          delete allColumnsObj[key];
        }
      });
      const selectedCols = selectedKeys.reduce((arr, item) => {
        const key = selectedColumns ? item : item.value;
        if (allColumnsObj[key]) {
          const customText = allColumnsObj[key].text;
          delete allColumnsObj[key];
          arr.push({
            text: customText || reportMeta.getFriendlyName("metrics", key),
            value: key
          });
        }
        return arr;
      }, []);
      const availableKeys = Object.keys(allColumnsObj);
      const availableCols = availableKeys.map(key => {
        const customText = allColumnsObj[key].text;
        return {
          text: customText || reportMeta.getFriendlyName("metrics", key),
          value: key
        };
      });
      this.availableColumnsArray = availableCols;
      this.selectedColumnsArray = selectedCols;
    },
    initLayout() {
      this.layout = new T1Layout({
        el: () => this.$(".column-selector-dialog"),
        template: '<div class="column-selector"></div>',
        layout: {
          ".column-selector": [{
            module: "reporting/campaigns/technology",
            viewType: "columnSelector",
            options: {
              listA: this.availableColumnsArray,
              listB: this.selectedColumnsArray,
              onCloseCallback: obj => {
                if (obj) {
                  this.saveColumns(obj);
                }
                this.onFormClose();
              }
            }
          }]
        }
      });
    },
    saveColumns({
      listB: listB
    }) {
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("selectedColumns"),
        value: listB.toString()
      });
      this.reportModel.setColumnKeys(listB);
    },
    updateMetadataSortKeys() {
      const reportModelObj = $.extend({}, this.reportModel.toJSON());
      const {
        columnKeys: columnKeys,
        sortKeyInfo: sortKeyInfo
      } = reportModelObj;
      const sortKeyArray = sortKeyInfo ? sortKeyInfo.split(",") : [];
      const sortKeyIndexInColumnKeys = sortKeyArray.length ? columnKeys.indexOf(sortKeyArray[0]) : -1;
      if (sortKeyIndexInColumnKeys === -1) {
        this.reportModel.setSortKeyInfo(!columnKeys.length ? "Strategy_Name,1,1" : `${columnKeys[0]},-1`);
      }
    },
    resortCollection(reportModel) {
      const sortKeyInfo = reportModel.get("sortKeyInfo");
      if (sortKeyInfo && sortKeyInfo.length) {
        const [sortKey, order, name] = sortKeyInfo.split(",");
        const sortOrder = parseInt(order, 10);
        const isName = parseInt(name, 10);
        this.collection.sortByKey(sortKey, sortOrder, "daily", false, isName);
        this.summaryCollection.sortByKey(sortKey, sortOrder, "summary", false, isName);
      }
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("sortKeyInfo"),
        value: sortKeyInfo
      });
    },
    onFormClose() {
      delete this.layout.layout[".column-selector-dialog"];
    },
    showColumnSelector(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.selectedColumns = this.reportModel.get("preferences").get(this.getFocusKey("selectedColumns"));
      this.prepareMultiSortableData();
      this.initLayout();
      this.layout.load();
    },
    searchStrategyInGrid(event) {
      let inputText;
      const enterKey = 13;
      switch (event.keyCode) {
        case enterKey:
          inputText = $(event.currentTarget).val();
          event.preventDefault();
          T1.EventHub.publish(`reporting_${this.timeSeries}:search`, {
            term: inputText
          });
          break;
      }
    },
    toggleChart() {
      const chartShown = this.chartShown;
      const publish = T1.EventHub.publish;
      const animDelay = 140;
      if (!chartShown) {
        this.reportChartFilters.removeClass("hidden");
      }
      T1Animate({
        duration: 400,
        easing: "ease",
        options: {
          marginTop: chartShown ? "4px" : "358px"
        },
        target: this.reportControls,
        complete: () => {
          if (chartShown) {
            this.reportChartFilters.addClass("hidden");
          }
          publish("chart.toggleEnd");
          publish("track:event", {
            action: "Toggle chart",
            label: this.chartShown ? "show" : "hide"
          });
        }
      });
      setTimeout(() => {
        this.chartCover.toggle().removeClass("no-display");
        this.renderPartial(".toggle-btn");
        this.toggleReportPanel();
      }, animDelay);
      this.chartShown = !this.chartShown;
      publish("chart.toggle", this.chartShown);
    },
    toggleReportPanel() {
      if (this.chartShown) {
        this.reportPanel.removeClass("chart-hidden");
      } else {
        this.reportPanel.addClass("chart-hidden");
      }
    },
    serialize() {
      return {
        btnLabel: this.chartShown ? "hide chart" : "show chart",
        btnState: this.chartShown ? "hide" : "show",
        chartHidden: !this.chartShown
      };
    }
  });
  return ControlsView;
});
