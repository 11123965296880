define('modules/targeting/audience/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "T1Spinner", "T1InlineEditPopover", "T1Permissions", "collections/organizations", "models/audienceVendor", "text!modules/targeting/audience/templates/bins.html", "text!modules/targeting/audience/templates/bin_group_name.html", "text!modules/targeting/audience/templates/bin_header.html", "text!modules/targeting/audience/templates/bin_title.html", "text!modules/targeting/audience/templates/bin_item.html", "text!modules/targeting/audience/templates/bin_footer.html", "text!modules/targeting/audience/templates/inline_cpm_edit.html", "utils/CampaignStrategyUtils", "utils/currencyConversionHelper"], function ($, _, T1, BinsView, Spinner, T1InlineEditPopover, T1Permissions, Organizations, AudienceVendor, template, bin_group_name, bin_header, bin_title, bin_item, bin_footer, inline_cpm_edit, CampaignStrategyUtils, CurrencyUtils) {
  "use strict";

  var formatNumber = T1.Formats.rolledUpNumbersShort();
  var formatCurrency = T1.Utils.formatCurrency;
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  const hasGroupPermission = T1Permissions.check("feature", "campaigns_group_audience_segment");
  const audienceBins = BinsView.extend({
    type: "Audience",
    template: template,
    configs: {
      include: {
        showAndOr: true,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      },
      exclude: {
        hasFooter: true
      }
    },
    eventhubEvents: {
      "bins:inclExclClicked": function ({
        item: item,
        itemRemoved: itemRemoved,
        lastAction: lastAction,
        dropped: dropped
      }) {
        if (hasGroupPermission && itemRemoved && lastAction === "include" && !dropped) {
          const currentGroup = this.includeGroups.find(group => group.binList.find(segment => parseInt(item.get("id"), 10) === parseInt(segment.id, 10)));
          const segmentIndex = currentGroup.binList.findIndex(segment => parseInt(item.get("id"), 10) === parseInt(segment.id, 10));
          currentGroup.binList.splice(segmentIndex, 1);
          if (!currentGroup.binList.length) {
            this.includeGroups.splice(this.includeGroups.findIndex(g => g.radioName === currentGroup.radioName), 1);
            this.includeGroups.forEach((group, index) => {
              group.bin_group_name = `Group ${index + 1}`;
              group.radioName = `includeGroup${index + 1}`;
              group.identifier = `g${index + 1}`;
              group.showAndSeparator = index > 0;
              group.showAdd = index === 0;
            });
          }
        }
      },
      "bins:checkEmptyGroups": function (deferred) {
        const $blankModal = document.getElementById("blankGroupModal");
        if (hasGroupPermission) {
          const checkEmpty = this.includeGroups.length > 1 && this.includeGroups.some(group => !group.binList.length);
          if (checkEmpty) {
            $blankModal.show();
          } else {
            deferred.resolve();
          }
        } else {
          deferred.resolve();
        }
        document.getElementById("modalCancel").addEventListener("click", () => {
          $blankModal.hide();
          deferred.reject();
        });
        document.getElementById("modalContinue").addEventListener("click", () => {
          $blankModal.hide();
          this.includeGroups = this.includeGroups.filter(group => group.binList.length);
          this.includeGroups.forEach((group, index) => {
            group.bin_group_name = `Group ${index + 1}`;
            group.radioName = `includeGroup${index + 1}`;
            group.identifier = `g${index + 1}`;
            group.showAndSeparator = index > 0;
            group.showAdd = index === 0;
          });
          this.sharedCollection.includeGroups = this.includeGroups;
          this.renderPartial(`.include-bin .content-container`, {
            hasGroupPermission: hasGroupPermission,
            showGroupNames: true,
            binItems: this.includeGroups
          }, "html", ".content-container");
          deferred.resolve();
        });
        return deferred.promise();
      },
      "bins:resetDraggedDropTarget": function () {
        this.droppedGroupId = null;
      },
      onDontSaveClicked: function () {
        this.droppedGroupId = null;
        if ("includeGroups" in this.sharedCollection) {
          delete this.sharedCollection.includeGroups;
        }
        this.includeGroups = [{
          bin_group_name: "Group 1",
          identifier: "g1",
          radioName: "includeGroup1",
          use_or: true,
          binGroupOperator: "OR",
          showAdd: true,
          showAndSeparator: false,
          action: "include",
          binList: []
        }];
      }
    },
    events: {
      "click input:radio[name=and_or]": "andOrClicked",
      "click .group-coll .radio": "groupAndOrClicked",
      "click .add-group": "onAddGroup",
      "click .group-container .detail.remove-icon": "onDeleteGroupSegment",
      "click .group-container .group-coll .close.remove-icon": "onDeleteGroup"
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_item: bin_item,
      bin_footer: bin_footer,
      bin_group_name: bin_group_name
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      onDropEvent: "bins:droppedAudience",
      allowMultipleSelection: true,
      handlerTextEl: ".segment"
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      strategy: strategy,
      retiredCollection: retiredCollection,
      supernodes: supernodes
    }) {
      this.strategy = strategy;
      this.sharedCollection = sharedCollection;
      this.currencyCode = sharedCollection.currencyCode;
      this.retiredCollection = retiredCollection;
      this.setIncludeUseOrState();
      this.handleDropEvent = this.handleSegmentDrop;
      this.applyDroppable = this.applyAudienceDroppable;
      this.isGroupDrop = true;
      this.allowBYOPriceEdit = true;
      this.supernodes = supernodes;
      this.includeGroups = [{
        bin_group_name: "Group 1",
        identifier: "g1",
        radioName: "includeGroup1",
        use_or: true,
        binGroupOperator: "OR",
        showAdd: true,
        showAndSeparator: false,
        action: "include",
        binList: []
      }];
      this.sharedCollection.add(this.retiredCollection.models, {
        silent: true
      });
      this.audienceVendor = new AudienceVendor();
      Organizations.getOrganizations().getSelected().then(id => {
        const org = Organizations.getOrganizations().get(id);
        org.fetchOptions = $.extend({
          full: "*"
        }, org.fetchOptions);
        org.fetch({
          success: () => {
            this.allowBYOPriceEdit = org.get("allow_byo_price") === "1";
            this.prepareCpmEditor();
          }
        });
      });
    },
    load: function () {
      const delay = 100;
      this.render().then(() => {
        if (this.sharedCollection && this.sharedCollection.models.length) {
          this.sharedCollection.models = _.filter(this.sharedCollection.models, function (model) {
            return model.get("audience_vendor_id") !== COMPASS_ENV.IAS_AUDIENCE_VENDOR_ID || model.get("group_identifier") !== "IAS_INCLUDE" && model.get("group_identifier") !== "IAS_EXCLUDE";
          });
        }
        setTimeout(() => {
          this.updateBins();
        }, delay);
        T1.Tooltip(this.$(".warning-holder"), {});
      });
    },
    reload: function () {
      this.sharedCollection.add(this.retiredCollection.models, {
        silent: true
      });
      this.setIncludeUseOrState();
      this.load();
    },
    unload() {
      this.audienceVendor = new AudienceVendor();
    },
    setIncludeUseOrState: function () {
      const includeOp = this.strategy.get("audience_segment_include_op");
      this.sharedCollection.includedBinOperation = includeOp;
      this.retiredCollection.includeBinOperation = includeOp;
      this.configs.include.use_or = includeOp === "OR";
    },
    andOrClicked: function () {
      this.sharedCollection.includedBinOperation = this.$("input:radio[name=and_or]:checked").val();
      this.updateBins();
    },
    groupAndOrClicked(evt) {
      const currentName = evt.currentTarget.getAttribute("name");
      this.includeGroups.forEach(group => {
        if (group.radioName === currentName) {
          const value = evt.currentTarget.value;
          group.binGroupOperator = value;
          group.use_or = value === "OR";
        }
      });
      this.updateBins();
    },
    onAddGroup() {
      const displayLength = this.includeGroups.length + 1;
      const jsonObj = {
        hasGroupPermission: hasGroupPermission,
        showGroupNames: true
      };
      this.includeGroups.push({
        bin_group_name: `Group ${displayLength}`,
        identifier: `g${displayLength}`,
        use_or: true,
        binGroupOperator: "OR",
        radioName: `includeGroup${displayLength}`,
        showAdd: false,
        binList: [],
        showAndSeparator: true,
        action: "include"
      });
      jsonObj.binItems = this.includeGroups;
      this.renderPartial(`.include-bin .content-container`, jsonObj, "html", ".content-container");
      this.updateBins();
      T1.EventHub.publish("formElementChanged");
    },
    onDeleteGroupSegment(evt) {
      this.droppedGroupId = null;
      const groupName = $(evt.target).closest(".group-container").data("id");
      const segmentId = $(evt.target).closest("[data-id]").data("id");
      const currentGroup = this.includeGroups.find(group => group.radioName === groupName);
      const segmentIndex = currentGroup.binList.findIndex(segment => parseInt(segmentId, 10) === parseInt(segment.id, 10));
      currentGroup.binList.splice(segmentIndex, 1);
      if (!currentGroup.binList.length) {
        this.includeGroups.splice(this.includeGroups.findIndex(g => g.radioName === currentGroup.radioName), 1);
        this.includeGroups.forEach((group, index) => {
          group.bin_group_name = `Group ${index + 1}`;
          group.radioName = `includeGroup${index + 1}`;
          group.identifier = `g${index + 1}`;
          group.showAndSeparator = index > 0;
          group.showAdd = index === 0;
        });
      }
      this.updateBins();
    },
    onDeleteGroup(evt) {
      this.droppedGroupId = null;
      const groupName = $(evt.target).closest(".group-container").data("id");
      const groupIndex = this.includeGroups.findIndex(group => group.radioName === groupName);
      this.includeGroups[groupIndex].binList.forEach(segment => {
        this.sharedCollection.remove(this.sharedCollection.get(segment.id));
      });
      this.includeGroups[groupIndex].binList = [];
      this.includeGroups.splice(groupIndex, 1);
      this.includeGroups.forEach((group, index) => {
        group.bin_group_name = `Group ${index + 1}`;
        group.radioName = `includeGroup${index + 1}`;
        group.identifier = `g${index + 1}`;
        group.showAndSeparator = index > 0;
        group.showAdd = index === 0;
      });
      this.updateBins();
      T1.EventHub.publish("formElementChanged");
    },
    applyAudienceDroppable() {
      const self = this;
      const sharedCollection = this.sharedCollection || this.collection;
      this.$(".bin-content").droppable({
        hoverClass: "hovered",
        tolerance: "pointer",
        accept(ui) {
          ui.draggable();
          return true;
        },
        drop(event, ui) {
          self.handleSegmentDrop($(this), ui, sharedCollection);
        }
      });
    },
    handleSegmentDrop($dropTarget, ui, sharedCollection) {
      const self = this;
      let $dragElemLength, $dropElemLength, $selectedItems, draggedfromGrid;
      const selectedList = [];
      const checkExistenceSharedCollection = this.draggableConfigs.checkExistenceSharedCollection;
      if (!$dropTarget || typeof $dropTarget !== "object") {
        throw new Error('"$dropTarget" object is required for handleDropEvent');
      }
      if (!ui.draggable || typeof ui.draggable !== "object") {
        throw new Error('"draggable" object is required for handleDropEvent');
      }
      if (!sharedCollection || typeof sharedCollection !== "object") {
        throw new Error('"sharedCollection" is required for handleDropEvent');
      }
      const action = $dropTarget.closest(".bin").data("action");
      const $droppedItem = $(ui.draggable);
      let $itemContainer = $droppedItem.closest(".content-c");
      if (!$itemContainer.length) {
        $itemContainer = $droppedItem.closest(".bin-content");
        $selectedItems = $itemContainer.find(".drag-selected");
        $dragElemLength = $droppedItem.closest(".group-container").length;
        $dropElemLength = $dropTarget.children(".group-container").length;
        if ($dragElemLength && $dropElemLength || !action) {
          return true;
        }
        this.isGroupDrop = action === "include" || !action;
      } else {
        $selectedItems = $itemContainer.find(".drag-selected").children(".item");
        draggedfromGrid = true;
      }
      $selectedItems = $selectedItems.not($itemContainer.find('[data-ignore-drag-select="true"]'));
      function removeGroupAndSegment(groupNo, segmentId) {
        const currentGroup = self.includeGroups.find(group => group.radioName === groupNo);
        const segmentIndex = currentGroup.binList.findIndex(segment => parseInt(segmentId, 10) === parseInt(segment.id, 10));
        currentGroup.binList.splice(segmentIndex, 1);
        if (!currentGroup.binList.length) {
          self.includeGroups.splice(self.includeGroups.findIndex(g => g.radioName === currentGroup.radioName), 1);
          self.includeGroups.forEach((group, index) => {
            group.bin_group_name = `Group ${index + 1}`;
            group.radioName = `includeGroup${index + 1}`;
            group.showAndSeparator = index > 0;
            group.showAdd = index === 0;
          });
        }
      }
      function pushItemInSelectedList(dataId) {
        const model = sharedCollection.get(dataId);
        let lastAction;
        if (checkExistenceSharedCollection === false || !model || action !== (model.action || model.get("action"))) {
          selectedList.push({
            id: dataId,
            action: action
          });
          if (hasGroupPermission && $dragElemLength) {
            removeGroupAndSegment($droppedItem.closest(".group-container").data("id"), dataId);
          }
          if (hasGroupPermission && draggedfromGrid) {
            const itemInSharedCollection = sharedCollection.get($droppedItem.data("id"));
            if (itemInSharedCollection) {
              lastAction = itemInSharedCollection.get("action");
              if (lastAction === "include" && action === "exclude") {
                const groupNo = $(".include-bin").find(`[data-id="${dataId}"]`).closest(".group-container").data("id");
                removeGroupAndSegment(groupNo, dataId);
              }
            }
          }
        }
      }
      if (!$selectedItems.length) {
        pushItemInSelectedList($droppedItem.data("id"));
      } else {
        $.each($selectedItems, function (index, item) {
          pushItemInSelectedList($(item).data("id"));
        });
      }
      $selectedItems.closest(".item-wrapper").removeClass("drag-selected shift-selected last-clicked last-shift-clicked");
      T1.EventHub.publish(this.draggableConfigs.onDropEvent, {
        selectedList: selectedList
      });
    },
    applyGroupDroppable() {
      const self = this;
      const $elem = this.$(".group-container");
      const sharedCollection = this.sharedCollection || this.collection;
      $elem.each(function () {
        $(this).droppable({
          hoverClass: "hovered",
          tolerance: "pointer",
          accept(ui) {
            ui.draggable();
            return true;
          },
          drop(event, ui) {
            self.handleGroupDrop($(this), ui, sharedCollection);
          }
        });
      });
    },
    handleGroupDrop($dropTarget, ui) {
      const self = this;
      const selectedList = [];
      const $droppedItem = $(ui.draggable);
      const $dragContainer = $droppedItem.closest(".group-container");
      const $dropContainer = $dropTarget.closest(".group-container");
      const dragGroup = this.includeGroups.find(group => group.radioName === $dragContainer.data("id"));
      const dropGroup = this.includeGroups.find(group => group.radioName === $dropContainer.data("id"));
      this.droppedGroupId = $dropContainer.data("id");
      if (!$dragContainer.length || !this.isGroupDrop) {
        return;
      }
      const $selectedItems = $dragContainer.find(".drag-selected");
      function pushInDropContainer(segmentId) {
        const currentSegment = dragGroup.binList.find(segment => parseInt(segment.id, 10) === segmentId);
        currentSegment.bin_group_name = dropGroup.bin_group_name;
        dropGroup.binList.push(currentSegment);
      }
      function removeFromDragContainer(segmentId) {
        const segmentIndex = dragGroup.binList.findIndex(segment => parseInt(segment.id, 10) === segmentId);
        dragGroup.binList.splice(segmentIndex, 1);
        if (!dragGroup.binList.length) {
          self.includeGroups.splice(self.includeGroups.findIndex(g => g.radioName === dragGroup.radioName), 1);
          self.includeGroups.forEach((group, index) => {
            group.bin_group_name = `Group ${index + 1}`;
            group.radioName = `includeGroup${index + 1}`;
            group.showAndSeparator = index > 0;
            group.showAdd = index === 0;
          });
        }
      }
      if (!$selectedItems.length) {
        selectedList.push($droppedItem.data("id"));
      } else {
        $selectedItems.forEach(segment => {
          selectedList.push($(segment).data("id"));
        });
      }
      if ($dragContainer.data("action") === "include") {
        selectedList.forEach(segment => {
          pushInDropContainer(segment);
          removeFromDragContainer(segment);
        });
      } else {
        selectedList.forEach(segment => {
          removeFromDragContainer(segment);
        });
      }
      this.renderPartial(`.include-bin .content-container`, {
        hasGroupPermission: hasGroupPermission,
        showGroupNames: true,
        binItems: this.includeGroups
      }, "html", ".content-container");
      $selectedItems.closest(".item-wrapper").removeClass("drag-selected shift-selected last-clicked last-shift-clicked");
      T1.EventHub.publish("formElementChanged");
      this.updateBins();
    },
    onToggleUserCPM: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $userCPMField = $currentTarget.closest("div").find("input:text");
      $userCPMField.trigger("keydown");
      if ($currentTarget.val() === "0") {
        $userCPMField.removeAttr("disabled");
      } else {
        $userCPMField.val("").attr("disabled", true);
      }
    },
    updateBins: function (currentModel, hasBeenRemoved) {
      var self = this;
      const iconSpinner = this.$(".icon-spinner");
      const iconSpinnerFooter = this.$(".icon-spinner-footer");
      const postObj = this.sharedCollection.buildPostObjForPostingSizeAndCpm();
      const strategy = this.strategy;
      var isAndSelected = postObj.include_op === "AND";
      var includedIds = [];
      if (hasGroupPermission) {
        const excludeBin = this.bins.find(bin => bin.bin_action === "exclude");
        if (this.includeGroups.length && this.includeGroups[0].binList.length) {
          this.renderPartial(`.include-bin .content-container`, {
            hasGroupPermission: hasGroupPermission,
            showGroupNames: true,
            binItems: this.includeGroups
          }, "html", ".content-container");
        }
        if (excludeBin) {
          this.renderPartial(`.exclude-bin .content-container`, excludeBin, "html", ".content-container");
        }
      }
      setTimeout(() => {
        this.prepareCpmEditor();
      }, 0);
      if (this.sharedCollection && this.sharedCollection.models.length) {
        this.sharedCollection.models = _.filter(this.sharedCollection.models, function (model) {
          return model.get("audience_vendor_id") !== COMPASS_ENV.IAS_AUDIENCE_VENDOR_ID || model.get("group_identifier") !== "IAS_INCLUDE" && model.get("group_identifier") !== "IAS_EXCLUDE";
        });
      }
      function updateBins(data) {
        self.updateDataBind(data, self.el, {
          doNotEscape: true
        });
      }
      function applySpinner(spinnerTarget, updateBinsData) {
        if (!spinnerTarget.children().length) {
          updateBins(updateBinsData);
          Spinner.apply(spinnerTarget, self.spinnerOpts);
        }
      }
      function checkProviderTax(resp) {
        const vendorID = resp && Number(resp.get("vendor_id"));
        const vendor = self.supernodes.models.find(model => model.attributes.vendor_id === vendorID);
        if (vendor && vendor.get("tax_flag") === true) {
          T1.EventHub.publish("bins.providerTaxAdded");
        }
      }
      $.each(postObj, function (key, value) {
        if (value === "INCLUDE") {
          includedIds.push(key);
        }
        if (includedIds.length > 1) {
          return false;
        }
      });
      applySpinner(iconSpinner, {
        "include-count": "",
        "exclude-count": ""
      });
      applySpinner(iconSpinnerFooter, {
        "audience-cpm-cost": ""
      });
      if (currentModel && !hasBeenRemoved) {
        const audienceVendorID = currentModel.get("audience_vendor_id");
        this.audienceVendor.id = audienceVendorID || "";
        this.audienceVendor.fetch({
          success: checkProviderTax
        });
      }
      if (this.ajxSizeReq) {
        this.ajxSizeReq.abort();
      }
      if (this.ajxCpmReq) {
        this.ajxCpmReq.abort();
      }
      this.ajxSizeReq = strategy.requestAudienceTargetData(postObj, function (audienceSizes) {
        var obj = T1.Utils.parseEntity(audienceSizes);
        var sizeObj = obj.strategy_audience_segments_size;
        const includedSize = sizeObj.included_size === "" || sizeObj.included_size === "0" ? 0 : sizeObj.included_size;
        const excludedSize = sizeObj.excluded_size === "" || sizeObj.excluded_size === "0" ? 0 : sizeObj.excluded_size;
        var includedSizeText = includedSize === 0 ? 0 : formatNumber(parseInt(includedSize, 10));
        updateBins({
          "include-count": isAndSelected && includedIds.length > 1 ? `Up to ${includedSizeText}` : includedSizeText,
          "exclude-count": excludedSize === 0 ? 0 : formatNumber(parseInt(excludedSize, 10))
        });
        iconSpinner.spin(false);
      }, function () {
        updateBins({
          "include-count": 0,
          "exclude-count": 0
        });
        iconSpinner.spin(false);
      }, "size");
      if (this.ajxSizeReq) {
        this.ajxSizeReq.always(function () {
          this.ajxSizeReq = null;
        });
      }
      this.ajxCpmReq = strategy.requestAudienceTargetData(postObj, function (cpmResult) {
        var propertyAry = cpmResult.entity.prop.amount;
        var formattedCurrency = CurrencyUtils.getCpmResultForTargeting(propertyAry, self.currencyCode);
        let cpmCost = formattedCurrency === "0" ? "--" : formatCurrency(parseFloat(formattedCurrency), null, self.currencyCode);
        if (self.sharedCollection.includedBinOperation === "OR" && cpmCost !== "--") {
          cpmCost = `At most ${cpmCost}`;
        }
        updateBins({
          "audience-cpm-cost": cpmCost
        });
        iconSpinnerFooter.spin(false);
      }, function () {
        updateBins({
          "audience-cpm-cost": "--"
        });
        iconSpinnerFooter.spin(false);
      }, "cpm");
      if (this.ajxCpmReq) {
        this.ajxCpmReq.always(function () {
          this.ajxCpmReq = null;
        });
      }
      this.applyGroupDroppable();
    },
    prepareCpmEditor: function () {
      var self = this;
      if (this.allowBYOPriceEdit && this.sharedCollection.models.length) {
        const currencyCode = this.currencyCode;
        const inlineEditorView = T1InlineEditPopover({
          menuTriggerEleSelector: ".cpm .editable",
          template: inline_cpm_edit,
          onOpen: function (target) {
            T1.Form.Masks.attach($("input", $(target.el)), currencyCode);
            T1.Tooltip(inlineEditorView.el, {
              className: "audienceSegmentEdit-tipsy",
              gravity: "swe"
            });
            $(target.el).find("input:radio[name=cpm]").on("click", function (e) {
              self.onToggleUserCPM.call(inlineEditorView, e);
            });
          },
          generateData: function ({
            target: target
          }) {
            const $targetSegment = target.closest(".item");
            const targetSegmentID = $targetSegment.data("id");
            const targetModel = self.sharedCollection.get(targetSegmentID);
            const standardCPM = targetModel.get("retail_cpm");
            const userCPM = targetModel.get("user_cpm");
            const isStandardCPM = userCPM === undefined;
            const currencySym = getCurrencySymbol(currencyCode);
            const formattedStdCPM = formatCurrency(parseFloat(standardCPM), null, currencyCode);
            const formattedUserCPM = isStandardCPM ? undefined : userCPM;
            return {
              isStandardCPM: isStandardCPM,
              standardCPM: formattedStdCPM,
              userCPM: formattedUserCPM,
              currency_symbol: currencySym
            };
          },
          validate: function ({
            isStandardCPM: isStandardCPM,
            userCPM: userCPM
          }) {
            if (isStandardCPM === "0" && (userCPM === undefined || userCPM === "")) {
              return {
                errors: [{
                  field: "userCPM",
                  message: "The field is required."
                }]
              };
            }
            return {
              errors: []
            };
          },
          onSave: function ({
            isStandardCPM: isStandardCPM,
            userCPM: userCPM
          }) {
            const $targetSegment = this.currentData.target.closest(".item");
            const targetSegmentID = $targetSegment.data("id");
            const targetModel = self.sharedCollection.get(targetSegmentID);
            if (targetModel) {
              if (isStandardCPM === "1") {
                targetModel.set({
                  user_cpm: undefined
                });
              } else {
                targetModel.set({
                  user_cpm: userCPM
                });
              }
              if (hasGroupPermission) {
                const groupName = $targetSegment.closest(".group-container").data("id");
                if (groupName) {
                  const currentGroup = self.includeGroups.find(group => group.radioName === groupName);
                  currentGroup.binList.forEach(segment => {
                    if (parseInt(segment.id, 10) === parseInt(targetSegmentID, 10)) {
                      segment.cpm = isStandardCPM === "1" ? targetModel.get("retail_cpm") : userCPM;
                    }
                  });
                }
              }
              self.configs.include.use_or = self.sharedCollection.includedBinOperation === "OR";
              self.load();
            }
          }
        }, this);
      }
      if (this.allowBYOPriceEdit === false) {
        $(".type").removeClass("editable");
      }
    },
    mapItems(items) {
      var self = this;
      var result = [];
      var currencyCode = this.currencyCode;
      items = _.groupBy(items, function (item) {
        if (item.get("type") !== "retired_audience_segment") {
          if (item.get("full_path")) {
            return item.get("full_path").split(" - ")[0];
          }
          return item.get("full_retired_path").split(" - ")[0];
        }
        return item.get("full_retired_path").split(" - ")[0];
      });
      for (const item in items) {
        if (T1.Utils.hasOwnProp(items, item)) {
          const modelArray = items[item];
          for (let i = 0; i < modelArray.length; i++) {
            let path;
            const mappedItem = {};
            const model = modelArray[i];
            mappedItem.id = model.id;
            mappedItem.bin_show_group_name = i === 0;
            if (model.get("type") !== "retired_audience_segment") {
              if (model.get("full_path")) {
                path = CampaignStrategyUtils.getPath(model.get("full_path"));
                mappedItem.bin_group_name = model.get("full_path").split(" - ")[0];
              } else {
                path = CampaignStrategyUtils.getPath(model.get("full_retired_path"));
                mappedItem.bin_group_name = model.get("full_retired_path").split(" - ")[0];
              }
            } else {
              path = CampaignStrategyUtils.getPath(model.get("full_retired_path"));
              mappedItem.bin_group_name = model.get("full_path").split(" - ")[0];
            }
            const userCPM = model.get("user_cpm");
            mappedItem.hasGroupPermission = hasGroupPermission;
            mappedItem.displayText = path.displayText;
            mappedItem.title = path.title;
            mappedItem.uniques = model.get("uniques") ? formatNumber(parseInt(model.get("uniques"), 10)) : "--";
            mappedItem.cpm = userCPM === undefined ? model.get("retail_cpm") : userCPM;
            mappedItem.allowBYOPriceEdit = self.allowBYOPriceEdit;
            mappedItem.asCurrency = T1.Utils.getCurrencyTemplateFunc(currencyCode);
            mappedItem.showWarningIcon = model.get("rel") === "retired_audience_segment";
            mappedItem.headerTooltipRetiredSegmentsWarningText = CampaignStrategyUtils.headerTooltipRetiredSegmentsWarningText;
            result.push(mappedItem);
          }
        }
      }
      return result;
    },
    mapGroupItems(items) {
      const asCurrency = T1.Utils.getCurrencyTemplateFunc(this.currencyCode);
      const groupArr = this.includeGroups;
      const lastGroup = groupArr[groupArr.length - 1];
      const getGroupName = function (segment) {
        return groupArr.find(group => group.binList.find(item => item.id === segment.id));
      };
      const createNewGroup = function ({
        segment: segment,
        identifier: identifier
      }) {
        const number = parseInt(identifier.substring(1), 10);
        const group = {
          bin_group_name: `Group ${number}`,
          identifier: identifier,
          use_or: !segment.operator || segment.operator === "OR",
          binGroupOperator: segment.operator || "OR",
          radioName: `includeGroup${number}`,
          showAdd: number === 1,
          binList: [],
          showAndSeparator: number > 1,
          action: "include"
        };
        groupArr.push(group);
        return group;
      };
      items.map(model => {
        const key = model.get("type") !== "retired_audience_segment" && model.get("full_path") ? "full_path" : "full_retired_path";
        const path = CampaignStrategyUtils.getPath(model.get(key));
        return {
          id: model.id,
          asCurrency: asCurrency,
          displayText: path.displayText,
          title: path.title,
          uniques: model.get("uniques") ? formatNumber(parseInt(model.get("uniques"), 10)) : "--",
          cpm: model.get("user_cpm") || model.get("retail_cpm"),
          allowBYOPriceEdit: this.allowBYOPriceEdit,
          showWarningIcon: model.get("rel") === "retired_audience_segment",
          headerTooltipRetiredSegmentsWarningText: CampaignStrategyUtils.headerTooltipRetiredSegmentsWarningText,
          group_identifier: model.get("group_identifier"),
          operator: model.get("operator")
        };
      }).forEach(segment => {
        const groupName = getGroupName(segment);
        const identifier = segment.group_identifier;
        if (!groupName) {
          if (identifier && identifier !== "g0") {
            const groupWithIdentifier = groupArr.find(group => group.identifier === identifier);
            if (!groupWithIdentifier) {
              const newGroup = createNewGroup({
                segment: segment,
                identifier: identifier
              });
              segment.bin_group_name = newGroup.bin_group_name;
              newGroup.binList.push(segment);
            } else {
              groupWithIdentifier.use_or = !segment.operator || segment.operator === "OR";
              groupWithIdentifier.binGroupOperator = groupWithIdentifier.use_or ? "OR" : "AND";
              segment.bin_group_name = groupWithIdentifier.bin_group_name;
              groupWithIdentifier.binList.push(segment);
            }
          } else {
            if (this.droppedGroupId) {
              this.includeGroups.forEach(item => {
                if (item.radioName === this.droppedGroupId) {
                  item.binList.push(segment);
                }
              });
            } else {
              segment.bin_group_name = lastGroup.bin_group_name;
              lastGroup.binList.push(segment);
            }
          }
        }
      });
      groupArr.sort((a, b) => parseInt(a.identifier.substring(1), 10) - parseInt(b.identifier.substring(1), 10));
      return groupArr;
    },
    generateBinData: function (updateAction) {
      let binItems;
      const binConfig = this.configs[updateAction] || {};
      const hasSharedCollection = this.sharedCollection && this.sharedCollection.length;
      const binArray = hasSharedCollection ? this.sharedCollection.filter(model => model.get("action") === updateAction) : [];
      if (hasGroupPermission && updateAction === "include") {
        binItems = this.mapGroupItems(binArray, updateAction);
      } else {
        binItems = this.mapItems(binArray, updateAction);
      }
      let returnObj = {
        bin_kind: `${updateAction}-bin`,
        bin_action: updateAction,
        binItems: binItems,
        bin_label: this.type
      };
      returnObj = $.extend(true, {}, binConfig, returnObj);
      if (binConfig.showWarning && binConfig.warningMessage) {
        if (hasSharedCollection) {
          delete returnObj.showWarning;
        } else {
          returnObj.showWarning = true;
        }
      }
      return returnObj;
    },
    serialize: function () {
      const self = this;
      const bins = [];
      const updateAction = this.updateAction;
      let currentBinData = {};
      let items;
      $.each(this.configs, function (key) {
        let generatedBin;
        if (!updateAction || updateAction === key) {
          generatedBin = self.generateBinData(key);
          if (key === "include" && hasGroupPermission) {
            generatedBin.showAndOr = false;
          }
          bins.push(generatedBin);
        }
        if (updateAction === key) {
          currentBinData = generatedBin;
        }
      });
      const returnData = updateAction ? {
        binItems: currentBinData.binItems || [],
        showWarning: currentBinData.showWarning,
        warningMessage: currentBinData.warningMessage,
        tooltipText: currentBinData.tooltipText,
        binAction: updateAction
      } : {
        type: this.type,
        bin_label: this.type,
        bins: bins
      };
      returnData.showGroupNames = Boolean(returnData.binItems && returnData.binItems.length);
      returnData.hasGroupPermission = hasGroupPermission;
      if (updateAction === "exclude") {
        delete returnData.hasGroupPermission;
      }
      if (updateAction) {
        items = currentBinData.binItems;
        returnData.showDragDropArea = returnData.hasGroupPermission ? !items.length || !items[0].binList.length : !items.length;
        bins[0].showDragDropArea = returnData.showDragDropArea;
      } else {
        returnData.bins.forEach(bin => {
          items = bin.binItems;
          if (bin.bin_action === "include") {
            if (items && items.length) {
              bin.showDragDropArea = hasGroupPermission ? !items[0].binList.length : !items.length;
            } else {
              bin.showDragDropArea = !items.length;
            }
          } else {
            bin.showDragDropArea = !items.length;
          }
        });
      }
      this.sharedCollection.includeGroups = this.includeGroups;
      this.bins = bins;
      if (!this.includeGroups.length) {
        this.includeGroups.push({
          bin_group_name: "Group 1",
          identifier: "g1",
          use_or: true,
          binGroupOperator: "OR",
          radioName: "includeGroup1",
          showAdd: true,
          binList: [],
          showAndSeparator: false,
          action: "include"
        });
      }
      return returnData;
    }
  });
  return audienceBins;
});
