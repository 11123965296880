define('modules/reporting/campaigns/geoPerformance/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "geoPerformance",
    name: "reporting/campaigns/geoPerformance",
    viewCfgs: {
      breadCrumb: {
        model: null
      },
      chart: {
        model: null
      },
      columnSelector: {
        model: null
      },
      controls: {
        model: null
      },
      geoPerformance: {},
      grid: {
        model: null
      },
      panel: {
        model: null
      }
    }
  });
});
