define('models/reportingMainMeta',["jQuery", "Underscore", "T1", "T1Comm", "T1Model"], function ($, _, T1, T1Comm, T1Model) {
  "use strict";

  T1.Models.mainMeta = T1Model.extend({
    urlString: "std/meta",
    url: function () {
      return T1.RPT_API_ROOT + this.urlString;
    },
    fixture: "-reportsMeta",
    action: "read",
    dataType: "json",
    defaultOffset: -29,
    id: "",
    initialize: function (options) {
      T1Model.prototype.initialize.call(this, options);
      options = options || {};
      this.urlString = options.urlString || this.urlString;
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.url = options.url || this.url;
    },
    fetch: function (args) {
      var model = this;
      var options = args || {};
      model.loaded = false;
      this.ajx = T1Comm.get({
        sourceURL: model.url(),
        dataType: "json",
        onSuccess: function (resp) {
          model.loaded = true;
          model.set(resp, options);
          if (options.onSuccess) {
            options.onSuccess(model, resp);
          }
        },
        processAjaxResponse: function (resp) {
          var result = {
            jsonData: resp,
            statusCode: "ok"
          };
          return result;
        }
      });
    },
    abort: function () {
      if (this.ajx) {
        this.ajx.abort();
      }
    }
  });
  return T1.Models.mainMeta;
});
