define('modules/campaign/chart/utils/niceNumHelper',["ChartJS2"], function (Chart) {
  "use strict";

  const POWER = 10;
  const SUBDIVISION = 4;
  const MINIMUM = 1;
  const MAXIMUM = 10;
  const helpers = Chart.helpers;
  function NiceNumHelper(range) {
    const exponent = Math.floor(helpers.log10(range));
    const fraction = range / Math.pow(POWER, exponent);
    function clamp(value, lowerBound, upperBound) {
      return Math.max(Math.min(value, upperBound), lowerBound);
    }
    const niceFraction = clamp(Math.ceil(fraction * SUBDIVISION) / SUBDIVISION, MINIMUM, MAXIMUM);
    return niceFraction * Math.pow(POWER, exponent);
  }
  return NiceNumHelper;
});
