define('modules/creatives/display/control/headers',[], function () {
  return [{
    name: "checkbox-input",
    label: '<div class="checkbox all"></div>'
  }, {
    name: "toggle-arrow",
    label: "&nbsp;"
  }, {
    name: "name",
    label: "name"
  }, {
    name: "id",
    label: "id"
  }, {
    name: "approval",
    label: "approval"
  }, {
    name: "advertiser",
    label: "advertiser"
  }, {
    name: "concept",
    label: "concept"
  }, {
    name: "third-pas-id",
    label: "3pas id"
  }, {
    name: "file-type",
    label: "type"
  }, {
    name: "dimensions",
    label: "dimensions"
  }, {
    name: "secure",
    label: "secure",
    infoTooltip: {
      info: "BETA - this is the auto-detected secure (https:) status of your creative. Visit the KB for more information.",
      url: "https://mediamathsupport.force.com/s/article/Creative-Approvals"
    }
  }, {
    name: "last-modified",
    label: "modified"
  }, {
    name: "start-date",
    label: "start date"
  }, {
    name: "end-date",
    label: "end date"
  }, {
    name: "config",
    label: "&nbsp;"
  }];
});
