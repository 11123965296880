define('modules/admin/siteLists/views/usage',['require','jQuery','Underscore','T1','T1View','T1Layout','collections/siteLists','text!modules/admin/siteLists/templates/usage.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Layout = require("T1Layout");
  const SiteLists = require("collections/siteLists");
  const tmpl = require("text!modules/admin/siteLists/templates/usage.html");
  return T1View.extend({
    template: tmpl,
    events: {
      "changed .search-box": "onSearchInputChangeHandler"
    },
    initialize(opts) {
      if (this.collection) {
        this.collection.reset();
        this.collection.loaded = false;
      }
      const coll = this.collection = this.collection || new SiteLists([], {
        id: opts.id,
        action: "assignments"
      });
      coll.searchConfiguration = {
        fields: {
          name: {
            type: "string"
          }
        }
      };
      coll.canCache = false;
      coll.page = 0;
      coll.pageLimit = 25;
      coll.isPaginated = true;
      coll.fetchOptions = {
        full: "*",
        sort_by: "name"
      };
      coll.fetch();
      this.initLayout(opts);
    },
    initLayout() {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-UsageGrid",
        template: '<div class="w-grid"></div>',
        layout: {
          ".w-grid": [{
            module: "admin/siteLists",
            viewType: "usageItem",
            options: {
              collection: this.collection
            }
          }]
        }
      });
    },
    load() {
      this.render().then(() => {
        this.layout.load();
      });
    },
    unload() {
      var searchFieldVal = this.term;
      this.viewChanged = true;
      this.collection.searchTerm = null;
      this.term = "";
      if (searchFieldVal && searchFieldVal !== "") {
        this.collection.filterBy("multiField", this.term);
      }
    },
    onSearchInputChangeHandler(evt) {
      var self = this;
      const debounceDelay = 1e3;
      var collection = self.collection;
      var invalidShortSearch = "Please enter at least 3 characters.";
      var invalidSearchTerm = "This search term is not valid.";
      var showErrorTooltip = function (target, errorMsg) {
        var $target = $(target);
        var errorConfig = {
          className: "w-FormError",
          getExternalTip: false,
          gravity: "s",
          offset: 10,
          tooltipEle: target,
          trigger: "hover"
        };
        target.error = true;
        $target.attr("title", errorMsg);
        const tip = T1.Tooltip(target, errorConfig);
        if ($target.data("tipsy")) {
          $target.tipsy("show");
        }
        return tip;
      };
      var lazySearch = _.debounce(function (target) {
        const minSearchLen = 3;
        const half = 2;
        const base = 2;
        const exponent = 32;
        const signedInt = Math.pow(base, exponent) / half;
        self.term = self.term || "";
        if (self.term.length > 0 && self.term.length < minSearchLen) {
          if (target.getAttribute("original-title") !== invalidShortSearch) {
            self.errorTooltip = showErrorTooltip(target, invalidShortSearch);
          }
          return;
        } else if (!isNaN(self.term) && self.term > signedInt - 1) {
          if (target.getAttribute("original-title") !== invalidSearchTerm) {
            self.errorTooltip = showErrorTooltip(target, invalidSearchTerm);
          }
          return;
        }
        self.collection.searchTerm = self.term.length > 0 ? self.term : undefined;
        T1.EventHub.publish("usageGrid:reload");
        collection.filterBy("multiField", self.term);
        if (self.errorTooltip) {
          target.setAttribute("original-title", "");
          self.errorTooltip.unbind();
          $(".tipsy").remove();
        }
      }, debounceDelay, true);
      this.term = evt.target.value;
      lazySearch(evt.target);
    }
  });
});
