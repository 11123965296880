define('modules/creatives/componentBased/views/list',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Permissions", "collections/organizations", "collections/advertisers", "collections/creativesConceptsStrategies", "collections/componentCreatives", "collections/componentCreativeNetStats", "../control/formatters", "text!../templates/list.html", "text!../templates/listBody.html", "utils/CreativeUtils", "models/advertiser", "models/userPreferences", "T1Selectbox", "T1Tooltip", "T1Preferences", "T1Notifier", "jQPlugins/jquery.cookie"], function ($, _, When, T1, T1View, T1Layout, T1Permissions, Organizations, Advertisers, StrategyConcepts, ComponentCreatives, ComponentCreativeNetStats, Formaters, tmpl, templateBody, CreativeUtils, Advertiser, UserPreferences) {
  "use strict";

  var _mainGrid;
  return T1View.extend({
    template: tmpl,
    events: {
      "changed .component-list-filter": "onDropdownChanged",
      "click .component-grid #selectAll": "onItemSelected",
      "item-selected .component-grid": "onItemSelected",
      "click .componentBased-new": "createNew",
      "click .columns": "showColumnSelector",
      "column-sort strand-grid-column": "sortGrid",
      "focus .w-Filters input.text": "showSearchTooltip",
      "click strand-popover .close": "closeFBStatusPopover"
    },
    dataEvents: {
      collection: {
        reset: function (args) {
          this.onCollectionEvent(args);
        },
        startSearch: "onCollectionEvent",
        fetchMore: "onCollectionEvent",
        fetchMoreComplete: "onCollectionEvent"
      }
    },
    eventhubEvents: {
      "organization:select": function () {
        this.advID = this.advIDPref = null;
        this.reload();
      },
      "dataGrid:loaded": function (grid) {
        _mainGrid = grid;
        this.handleColumns(this.columnObj);
      }
    },
    onCollectionEvent: function () {
      this.isDisabled = this.collection.loaded === false;
      this.toggleFilters(this.isDisabled);
    },
    termPref: "",
    advIDPref: "",
    componentStatus: "1",
    inactiveClass: "#c3c3c3",
    activeClass: "#525252",
    columnsArray: [{
      text: "APPROVAL",
      value: "fb_status"
    }, {
      text: "ADVERTISER NAME",
      value: "advertiser_name"
    }, {
      text: "CONCEPT",
      value: "concept_name"
    }, {
      text: "MODIFIED",
      value: "updated_on"
    }, {
      text: "START DATE",
      value: "start_date"
    }, {
      text: "END DATE",
      value: "end_date"
    }, {
      text: "3PAS ID",
      value: "external_identifier"
    }, {
      text: "STRATEGIES",
      value: "strategies"
    }, {
      text: "CAMPAIGNS",
      value: "campaigns"
    }],
    availableColumnsArray: [],
    selectedColumnsArray: [{
      text: "APPROVAL",
      value: "fb_status"
    }, {
      text: "ADVERTISER NAME",
      value: "advertiser_name"
    }, {
      text: "CONCEPT",
      value: "concept_name"
    }, {
      text: "MODIFIED",
      value: "updated_on"
    }, {
      text: "START DATE",
      value: "start_date"
    }, {
      text: "END DATE",
      value: "end_date"
    }],
    defaultColumns: {
      listA: ["external_identifier", "strategies", "campaigns"],
      listB: ["fb_status", "advertiser_name", "concept_name", "updated_on", "start_date", "end_date"]
    },
    storedGridColumns: [],
    columnsStored: false,
    columnObj: {},
    fetchedAllCreatives: false,
    initialize: function (args) {
      var self = this;
      var signature = T1.Signature.create();
      var isReturning = $.cookie("isReturning");
      var componentPreview = T1Permissions.check("feature", "creatives.component_preview_flag");
      var isFeedbackEnabled = T1Permissions.check("feature", "creatives.component_approval_feedback");
      this.fetchedAllCreatives = false;
      this.allAdvertisersLoaded = false;
      this.stratCampMap = {};
      signature.set(new Map([["root", "ui"], ["module", "creatives.componentBased"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["termPref", "advIDPref"]);
      this.componentStatus = this.preferences.get("componentStatus") || "1";
      this.isFacebookApprovalEnabled = T1Permissions.check("feature", "creatives.facebook_approval_enabled");
      this.menuOptions = [{
        itemLabel: "Activate",
        itemCallback: $.proxy(self.bulkAction, self, {
          status: "1"
        })
      }, {
        itemLabel: "Deactivate",
        itemCallback: $.proxy(self.bulkAction, self, {
          status: "0"
        })
      }, {
        itemLabel: "Edit",
        itemCallback: $.proxy(self.showBulkEdit, self)
      }];
      if (componentPreview) {
        this.menuOptions.push({
          itemLabel: "Preview",
          itemCallback: $.proxy(self.showBulkPreview, self)
        });
      }
      if (isFeedbackEnabled && this.defaultColumns.listA.indexOf("feedback") === -1) {
        if (this.isFacebookApprovalEnabled) {
          this.columnsArray.push({
            text: "FACEBOOK REJECTION REASONS",
            value: "feedback"
          });
        } else {
          this.columnsArray.push({
            text: "REJECTION REASONS",
            value: "feedback"
          });
        }
        this.defaultColumns.listA.push("feedback");
      }
      if (isReturning === "true") {
        $.removeCookie("isReturning");
        self.termPref = "";
        self.advIDPref = "";
        self.preferences.save();
      } else {
        this.term = this.preferences.get("termPref") || "";
        this.advID = this.preferences.get("advIDPref") || null;
      }
      function newComponentCreativesCollection() {
        return new ComponentCreatives(null, {
          isPaginated: true,
          pageLimit: 50
        });
      }
      if (window.localStorage.getItem("columnData")) {
        this.columnObj = JSON.parse(window.localStorage.getItem("columnData"));
      }
      self.collection = args.componentCreatives || newComponentCreativesCollection();
      self.collection.fetchOptions.status = this.componentStatus;
      var orgPromise = Organizations.getOrganizations().getSelected();
      this.advertiserCollection = new Advertisers(null, {
        isPaginated: false
      });
      orgPromise.then(function (orgId) {
        self.collection.fetchOptions.organization_id = orgId;
        self.advertiserCollection.urlFilter.set({
          entity: "organization",
          id: orgId
        });
        self.getAdvertiserData().then(function () {
          self.initLayout();
          self.load();
        });
      });
      $(window).one("beforeunload", function () {
        $.cookie("isReturning", true);
        return null;
      });
    },
    enableScroll: function () {
      var gridHelper = this.el.find(".grid-helper");
      gridHelper[0].callback = this.loadMore.bind(this);
    },
    initLayout: function () {
      var self = this;
      var advertiserList;
      var searchTerm = this.termPref;
      if (this.allAdvertisersLoaded) {
        advertiserList = $.proxy(self.getAdvertiserList, this);
        if (this.layout) {
          this.layout.destroy();
        }
        if (searchTerm) {
          searchTerm = decodeURIComponent(searchTerm);
        }
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".list-filters",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: {
            ".search": [{
              module: "shared",
              viewType: "filter",
              options: {
                defaultValue: searchTerm,
                filterClass: "advertiser-select",
                selectOptions: advertiserList,
                hideSearch: false,
                isDisabled: function () {
                  return false;
                },
                filterCallback: $.proxy(self.filterAndClearCallback, this),
                clearFilterCallback: $.proxy(self.filterAndClearCallback, this),
                autoCompleteConfig: {
                  autoComplete: true,
                  searchField: "name",
                  onSearchInputCleared: $.proxy(self.clearTheFilter, this),
                  onSearchRequest: function (searchOptions) {
                    self.searchOptions = searchOptions;
                    searchOptions = $.extend({
                      fetchRequired: true
                    }, searchOptions);
                    self.advertiserCollection.search.set(searchOptions);
                    self.el.find(".advertiser-select:first").val("All Advertiser");
                  }
                }
              }
            }],
            ".bulk-action": [{
              module: "shared",
              viewType: "pullDownButton",
              options: {
                id: "bulk-action",
                menuLabel: "Choose Action",
                items: this.menuOptions,
                disabled: true
              }
            }]
          }
        });
      }
    },
    load: function () {
      var self = this;
      var $el = self.el;
      var gridHolder;
      this.render().then(function () {
        if (self.layout && !self.layoutLoaded) {
          self.layout.load().then(function () {
            self.enableScroll();
            self.layoutLoaded = true;
            gridHolder = $el[0].querySelector(".w-strand-grid");
            _mainGrid = gridHolder.querySelector("#componentGrid");
            _mainGrid.isLoading = true;
            self.collection.filterBy("", "", function (data) {
              var gridData = [];
              if (data.meta && data.meta.status !== "error") {
                gridData = self.createGridData();
              }
              self.totalCount = data.meta && data.meta.total_count || 0;
              if (data.meta.next_page) {
                _mainGrid.set("data", new Array(80));
              } else {
                _mainGrid.set("data", new Array(gridData.length));
              }
              self.loadGridData(gridData);
            });
          });
        }
      });
    },
    reload: function () {
      var self = this;
      this.layoutLoaded = false;
      this.initialize({
        componentCreatives: self.collection
      });
    },
    render: function () {
      var deferred = $.Deferred();
      Polymer.dom(this.el[0]).innerHTML = tmpl;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    onDropdownChanged: function (e) {
      var self = this;
      var collection = this.collection;
      var $dropdown = $(e.currentTarget);
      var ddVal = $(e.currentTarget).val();
      var key;
      _mainGrid.isLoading = true;
      if ($dropdown.hasClass("status-select")) {
        this.componentStatus = ddVal || "1";
        key = "status";
      }
      this.preferences.save();
      collection.trigger("startSearch");
      collection.filterBy(key, ddVal, $.proxy(self.updateGrid, self));
    },
    getAdvertiserData: function () {
      var self = this;
      var deferred = When.defer();
      self.advertiserCollection.isPaginated = true;
      self.advertiserCollection.pageLimit = 50;
      self.advertiserCollection.fetch({
        success: function () {
          self.filterAndClearCallback("", null, false);
          self.allAdvertisersLoaded = true;
          if (self.advIDPref && self.advIDPref !== "null") {
            var model = self.advertiserCollection.get(self.advID);
            if (model) {
              model.set({
                selected: true
              });
              self.advertiserCollection.add(model, {
                merge: true
              });
              deferred.resolve(self.advertiserCollection);
            } else {
              model = new Advertiser({
                id: self.advID
              });
              model.fetch({
                success: function (adModel) {
                  adModel.set({
                    selected: true
                  });
                  self.advertiserCollection.add(adModel);
                  deferred.resolve(self.advertiserCollection);
                }
              });
            }
          } else {
            deferred.resolve(self.advertiserCollection);
          }
        }
      });
      return deferred.promise;
    },
    getAdvertiserList: function () {
      var options = this.advertiserCollection.map(function (adver) {
        return {
          text: adver.get("name"),
          value: adver.id,
          selected: adver.get("selected")
        };
      });
      options.unshift({
        text: "All Advertisers",
        value: "all"
      });
      return options;
    },
    createGridData: function () {
      var self = this;
      var gridData = [];
      var conceptIDs = [];
      var creativeIDs = [];
      var dateFormat = new Formaters("standard_date");
      var gridItem, hasConcept;
      _.each(this.collection.toJSON(), function (item) {
        if (item.atomic_creative_id) {
          hasConcept = Boolean(item.concept_id);
          gridItem = self.serializeGridObject(item, dateFormat, hasConcept, self.isFacebookApprovalEnabled);
          if (hasConcept) {
            conceptIDs.push(item.concept_id);
          }
          creativeIDs.push(item.atomic_creative_id);
          gridData.push(gridItem);
        }
      });
      this.mapStrategy(_.uniq(conceptIDs), gridData, 0).then($.proxy(this.checkNetStatus, this, _.uniq(creativeIDs), gridData, self.isFacebookApprovalEnabled));
      if (!self.columnsStored) {
        self.storedGridColumns = _mainGrid.getAllColumns();
        self.columnsStored = true;
      }
      return gridData;
    },
    serializeGridObject: function (item, dateFormat, hasConcept, isFacebookApprovalEnabled) {
      return {
        name: item.creative_name,
        item_id: String(item.atomic_creative_id),
        advertiser_name: item.advertiser_name || "--",
        concept_name: item.concept_name || "--",
        concept_id: item.concept_id || undefined,
        updated_on: dateFormat(item.updated_at, false) || "--",
        start_date: dateFormat(item.start_date, true) || "--",
        end_date: dateFormat(item.end_date, true) || "--",
        fb_status: isFacebookApprovalEnabled ? item.fb_status : null,
        strategies_count: hasConcept ? "" : "No Active Strategies",
        campaigns_count: hasConcept ? "" : "No Active Campaigns",
        external_identifier: !item.external_identifier || item.external_identifier === "NA" ? "--" : item.external_identifier,
        active: item.status === "1",
        active_class: item.status === "1" ? this.activeClass : this.inactiveClass,
        feedback: item.feedback || "--",
        searchTerm: this.termPref,
        netStatus: {
          loading: true,
          showDash: false,
          icon: null,
          iconClass: null
        }
      };
    },
    addToGridData: function (data) {
      var self = this;
      var newGridData = [];
      var conceptIDs = [];
      var creativeIDs = [];
      var dateFormat = new Formaters("standard_date");
      var nextPage = data.meta.next_page;
      var offset = data.meta.offset;
      var startingIndex = offset;
      _mainGrid.isLoading = false;
      _.each(data.data, function (item) {
        var hasConcept = item.concept_id;
        var gridItem = self.serializeGridObject(item, dateFormat, hasConcept, self.isFacebookApprovalEnabled);
        if (hasConcept) {
          conceptIDs.push(item.concept_id);
        }
        creativeIDs.push(item.atomic_creative_id);
        newGridData.push(gridItem);
      });
      if (nextPage) {
        _mainGrid.splice.apply(_mainGrid, ["data", startingIndex, 0].concat(newGridData));
      } else {
        self.fetchedAllCreatives = true;
        _mainGrid.splice.apply(_mainGrid, ["data", startingIndex, 30].concat(newGridData));
      }
      this.mapStrategy(_.uniq(conceptIDs), newGridData, startingIndex).then($.proxy(this.checkNetStatus, this, _.uniq(creativeIDs), newGridData));
      return newGridData;
    },
    mapStrategy: function (conceptIDs, newGridData, startingIndex) {
      var self = this;
      var deferred = When.defer();
      var conceptToStrategy = new StrategyConcepts(null, {
        pageLimit: 100
      });
      var conceptIDLength = conceptIDs.length;
      var conceptsToFetch = [];
      if (conceptIDLength) {
        for (var i = 0; i < conceptIDLength; i++) {
          if (!self.stratCampMap[conceptIDs[i]]) {
            conceptsToFetch.push(conceptIDs[i]);
            self.stratCampMap[conceptIDs[i]] = {
              strategies: {},
              campaigns: {}
            };
          }
        }
        if (conceptsToFetch.length) {
          conceptToStrategy.urlFilter.set({
            entity: "concept",
            id: `(${conceptsToFetch})`
          });
          conceptToStrategy.fetch({
            success: function (models) {
              models.each(function (model) {
                var conceptID = model.get("concept").id;
                var stratName = model.get("strategy").name;
                var stratID = model.get("strategy").id;
                var stratStatus = model.get("strategy").status;
                var campName = model.get("strategy").campaign.name;
                var campID = model.get("strategy").campaign.id;
                var campStatus = model.get("strategy").campaign.status;
                if (stratStatus === "1") {
                  self.stratCampMap[conceptID].strategies[stratID] = stratName;
                }
                if (campStatus === "1") {
                  self.stratCampMap[conceptID].campaigns[campID] = campName;
                }
              });
              self.updateGridCampStrat(newGridData, startingIndex);
              deferred.resolve(models);
            }
          });
        } else {
          self.updateGridCampStrat(newGridData, startingIndex);
          deferred.resolve(null);
        }
      }
      return deferred.promise;
    },
    updateGridCampStrat: function (newGridData, startingIndex) {
      var campCount, campaigns, conceptId, gridIndex, stratCount, strategies;
      for (var i = 0; i < newGridData.length; i++) {
        conceptId = newGridData[i].concept_id;
        gridIndex = startingIndex + i;
        if (conceptId) {
          strategies = this.stratCampMap[conceptId].strategies;
          stratCount = _.keys(strategies).length;
          campaigns = this.stratCampMap[conceptId].campaigns;
          campCount = _.keys(campaigns).length;
        }
        _mainGrid.set(`data.${gridIndex}.strategies_count`, stratCount ? stratCount : "No Active Strategies");
        _mainGrid.set(`data.${gridIndex}.strategies`, stratCount ? strategies : "");
        _mainGrid.set(`data.${gridIndex}.campaigns_count`, campCount ? campCount : "No Active Campaigns");
        _mainGrid.set(`data.${gridIndex}.campaigns`, campCount ? campaigns : "");
        this.el.find(`#spinner-${newGridData[i].item_id}`).hide();
      }
    },
    getSortOnField: function (sortField) {
      var sortOn;
      switch (sortField) {
        case "name":
          sortOn = "creative_name";
          break;
        case "item_id":
          sortOn = "atomic_creative_id";
          break;
        case "updated_on":
          sortOn = "last_modified";
          break;
        default:
          sortOn = sortField;
      }
      return sortOn;
    },
    sortGrid: function (event) {
      var sortOn = this.getSortOnField(event.originalEvent.detail.field);
      var sortOrder = event.originalEvent.detail.val === 1 ? "-" : "";
      this.sortedOn = event.originalEvent.detail.field;
      _mainGrid.isLoading = true;
      this.collection.fetchOptions.sort_by = sortOrder + sortOn;
      this.collection.filterBy("", "", $.proxy(this.updateGrid, this));
    },
    filterAndClearCallback: function (term, flags) {
      this.collection.toggleAllSelected(false);
      if (this.layoutLoaded) {
        this.clearGridSelected();
      }
      this.advIDPref = flags && flags.id !== "" ? flags.id : this.advIDPref;
      if (this.advIDPref && this.advIDPref !== "all" && this.advIDPref !== "null") {
        this.collection.fetchOptions.advertiser_id = this.advIDPref;
      } else {
        if (this.advIDPref === "all") {
          this.clearTheFilter();
        }
        this.advID = null;
        this.advIDPref = null;
        delete this.collection.fetchOptions.advertiser_id;
      }
      this.termPref = term || "";
      this.preferences.save();
      if (term) {
        this.collection.fetchOptions.search = term;
        _mainGrid.noResultsMessage = "";
        _mainGrid.isLoading = true;
        return this.collection.filterBy("search", term, $.proxy(this.updateGrid, this));
      }
      delete this.collection.fetchOptions.search;
      if (term || flags) {
        _mainGrid.noResultsMessage = "";
        _mainGrid.isLoading = true;
        this.collection.filterBy("", "", $.proxy(this.updateGrid, this));
      }
    },
    clearTheFilter: function () {
      var searchOptions = $.extend({
        fetchRequired: true
      }, this.searchOptions);
      this.advertiserCollection.search.clear();
      this.advertiserCollection.fetch(searchOptions);
    },
    updateGrid: function (data) {
      var newData;
      this.stratCampMap = {};
      newData = this.createGridData();
      if (data.meta.next_page) {
        this.fetchedAllCreatives = false;
      } else {
        this.fetchedAllCreatives = true;
      }
      this.loadGridData(newData, true);
    },
    loadGridData: function (gridData, fromUpdate) {
      let placeHolders;
      const scrollPane = $(_mainGrid).find("#pane");
      this.layoutLoaded = true;
      _mainGrid.isLoading = false;
      _mainGrid.noResultsMessage = "No results found.";
      if (gridData.length) {
        if (fromUpdate) {
          if (!this.fetchedAllCreatives) {
            placeHolders = new Array(30);
            gridData = gridData.concat(placeHolders);
          }
          if (scrollPane) {
            scrollPane.scrollTop(0);
          }
          _mainGrid.set("data", gridData);
        } else {
          _mainGrid.splice.apply(_mainGrid, ["data", 0, 50].concat(gridData));
          T1.EventHub.publish("dataGrid:loaded", _mainGrid);
        }
      } else {
        this.fetchedAllCreatives = true;
        _mainGrid.set("data", gridData);
        if (scrollPane) {
          scrollPane.scrollTop(0);
        }
      }
    },
    checkNetStatus: function (atomicCreativeIDs, gridData, isFacebookApprovalEnabled) {
      var self = this;
      var netStatsCollection = new ComponentCreativeNetStats();
      if (atomicCreativeIDs && atomicCreativeIDs.length) {
        netStatsCollection.atomicCreativeIDs = atomicCreativeIDs.join(",");
        netStatsCollection.fetch({
          success: function (data) {
            _.each(data, function (model) {
              _.each(_mainGrid.data, function (item, index) {
                var facebookIconInfo, iconInfo, netStatus, sharethroughIconInfo, tripleliftIconInfo;
                if (item && item.item_id === model.atomic_creative_id) {
                  iconInfo = self.getIconTypeFromNetStatus(model.net_status);
                  if (isFacebookApprovalEnabled && model.sources.facebook) {
                    facebookIconInfo = self.getIconTypeFromNetStatus(model.sources.facebook);
                  }
                  tripleliftIconInfo = self.getIconTypeFromNetStatus(model.sources.triplelift);
                  sharethroughIconInfo = self.getIconTypeFromNetStatus(model.sources.sharethrough);
                  netStatus = {
                    loading: false,
                    triplelift: tripleliftIconInfo,
                    sharethrough: sharethroughIconInfo
                  };
                  if (isFacebookApprovalEnabled) {
                    netStatus.facebook = isFacebookApprovalEnabled ? facebookIconInfo : null;
                  }
                  if (iconInfo.icon && iconInfo.icon !== "") {
                    netStatus.icon = iconInfo.icon;
                    netStatus.iconClass = `net-status-icon ${iconInfo.iconClass}`;
                  } else {
                    netStatus.showDash = true;
                  }
                  _mainGrid.set(`data.${index}.netStatus`, netStatus);
                }
              });
            });
          }
        });
      }
    },
    getIconTypeFromNetStatus: function (netStatus) {
      var iconClass = "";
      var icon = "warning";
      netStatus = netStatus.toLowerCase();
      if (netStatus === "approved") {
        icon = "success";
        iconClass = "net-status-approved";
      } else if (netStatus === "pending") {
        iconClass = "net-status-pending";
      } else if (netStatus === "pending > 48 hours") {
        icon = "pending";
        iconClass = "net-status-pending48";
      } else if (netStatus === "rejected") {
        iconClass = "net-status-rejected";
      } else if (netStatus === "n/a") {
        icon = null;
        iconClass = null;
      } else if (netStatus === "missing vendor" || netStatus === "vendor unsupported") {
        iconClass = "net-status-rejected";
      }
      return {
        icon: icon,
        iconClass: iconClass
      };
    },
    loadMore: _.debounce(function (indx) {
      var self = this;
      var collection = this.collection;
      var pageSize = 50;
      var page = Math.floor(indx / pageSize);
      if (_mainGrid.data[page * 50] || _mainGrid.isLoading || self.fetchedAllCreatives) {
        return;
      }
      _mainGrid.isLoading = true;
      collection.page++;
      collection.fetch({
        add: true,
        success: function (data) {
          collection.loaded = true;
          collection.trigger("fetchMoreComplete");
          self.addToGridData(data);
        }
      });
      collection.trigger("fetchMore");
    }, 100),
    showColumnSelector: function (e) {
      var self = this;
      e.preventDefault();
      e.stopPropagation();
      this.columnPickLayout = new T1Layout({
        el: function () {
          return $(self.el).find(".column-selector-dialog");
        },
        template: "<div class='column-selector'></div>",
        layout: {
          ".column-selector": [{
            module: "creatives/componentBased",
            viewType: "columnSelector",
            options: {
              listA: self.availableColumnsArray,
              listB: self.selectedColumnsArray,
              onCloseCallback: function (obj) {
                if (obj) {
                  self.handleColumns.call(self, obj);
                }
                self.onColumnSelectClose.call(self);
              }
            }
          }]
        }
      });
      this.columnPickLayout.load();
    },
    handleColumns: function (pickerObj) {
      var obj = pickerObj && !$.isEmptyObject(pickerObj) ? pickerObj : this.defaultColumns;
      var hideColumns = obj.listA;
      var showColumns = obj.listB;
      this.columnObj = pickerObj;
      window.localStorage.setItem("columnData", JSON.stringify(this.columnObj));
      this.hideColumns(hideColumns, _mainGrid);
      this.showColumns(showColumns, _mainGrid);
      $(_mainGrid).show();
      if (_.contains(hideColumns, this.sortedOn)) {
        _mainGrid.sortField = "";
        this.sortedOn = "";
        _mainGrid.isLoading = true;
        delete this.collection.fetchOptions.sort_by;
        this.collection.filterBy("", "", $.proxy(this.updateGrid, this));
      }
    },
    showColumns: function (showColumns, componentGrid) {
      var self = this;
      var getColumn;
      var columnCount = showColumns.length;
      self.availableColumnsArray = self.columnsArray;
      _.each(showColumns, function (column) {
        self.availableColumnsArray = self.availableColumnsArray.filter(function (el) {
          return el.value !== column;
        });
        setTimeout(function () {
          getColumn = componentGrid.getColumnByField(column);
          if (getColumn) {
            componentGrid.removeColumn(getColumn);
          }
        }, 0);
      });
      setTimeout(function () {
        if (componentGrid.getColumnByField("item_id")) {
          componentGrid.removeColumn(componentGrid.getColumnByField("item_id"));
          showColumns.unshift("item_id");
          columnCount++;
        }
        if (componentGrid.getColumnByField("name")) {
          componentGrid.removeColumn(componentGrid.getColumnByField("name"));
          showColumns.unshift("name");
          columnCount++;
        }
        _.each(showColumns, function (column) {
          setTimeout(function () {
            getColumn = componentGrid.getColumnByField(column);
            if (!getColumn) {
              _.each(self.storedGridColumns, function (item) {
                if (column === item.field) {
                  item.hidden = false;
                  item.width = `${98 / columnCount}%`;
                  componentGrid.insertBeforeColumn(item, componentGrid.getColumnByField("actions"));
                }
              });
            }
          }, 0);
        });
      }, 0);
    },
    hideColumns: function (hideColumns, componentGrid) {
      var self = this;
      var getColumn;
      self.selectedColumnsArray = self.columnsArray;
      _.each(hideColumns, function (column) {
        self.selectedColumnsArray = self.selectedColumnsArray.filter(function (el) {
          return el.value !== column;
        });
        setTimeout(function () {
          getColumn = componentGrid.getColumnByField(column);
          if (getColumn) {
            componentGrid.removeColumn(getColumn);
          }
        }, 0);
      });
    },
    onColumnSelectClose: function () {
      delete this.columnPickLayout.layout[".column-selector-dialog"];
    },
    createNew: function () {
      T1.Location.set("#creatives/componentBased-bulk");
    },
    onItemSelected: function () {
      this.toggleBulkAction(this.isDisabled);
    },
    toggleFilters: function (isDisabled) {
      var self = this;
      var $el = self.el;
      var $statusDDL = $el.find("strand-dropdown");
      var $columnsBtn = $el.find(".column-action").find("strand-button");
      $statusDDL.attr("disabled", isDisabled);
      $columnsBtn.attr("disabled", isDisabled);
    },
    clearGridSelected: function () {
      _mainGrid.setAllSelections(false);
    },
    toggleBulkAction: function (isDisabled) {
      var self = this;
      var selectedItem;
      var selected = [];
      selected = _mainGrid.selected;
      _.each(self.collection.models, function (item) {
        item.set({
          selected: false
        });
      });
      _.each(selected, function (item) {
        selectedItem = self.getCollectionModel(item.item_id);
        selectedItem.set({
          selected: true
        });
      });
      if (!isDisabled && selected.length > 0) {
        T1.EventHub.publish("pullDownButton:bulk-action:enable");
      } else {
        T1.EventHub.publish("pullDownButton:bulk-action:disable");
      }
    },
    bulkAction: function (modelValues) {
      var self = this;
      var queue = [];
      var selected = this.collection.getSelected();
      var entityType = "creative";
      selected.each(function (model) {
        var atomicCreativeId = model.get("atomic_creative_id");
        var loader = self.createInlineLoader(atomicCreativeId);
        var deferred = When.defer();
        loader.start();
        model.id = atomicCreativeId;
        model.save(modelValues, {
          success: function () {
            model.set({
              status: modelValues.status
            });
            self.clearGridSelected();
            self.toggleBulkAction();
            self.updateGridMenuItem(atomicCreativeId, modelValues.status);
            loader.stop();
            deferred.resolve();
          },
          conflict: function () {
            loader.stop();
            deferred.reject();
          }
        });
        queue.push(deferred.promise);
      });
      When.all(queue).then(function () {
        T1.EventHub.publish("bulkaction:success");
        T1.Notify("message", `${selected.length} ${entityType}(s) updated successfully!`);
      });
    },
    getCollectionModel: function (atomicID) {
      var collectionModel;
      _.each(this.collection.models, function (item) {
        if (item.get("atomic_creative_id").toString() === atomicID.toString()) {
          collectionModel = item;
        }
      });
      return collectionModel;
    },
    updateGridMenuItem: function (modelID, status) {
      var activeStatus = status === "1";
      var statusClass = status === "1" ? this.activeClass : this.inactiveClass;
      _.each(_mainGrid.data, function (gridItem, index) {
        if (gridItem && gridItem.item_id === modelID) {
          _mainGrid.set(`data.${index}.active_class`, statusClass);
          _mainGrid.set(`data.${index}.active`, activeStatus);
        }
      });
    },
    createInlineLoader: function (id) {
      var targetDiv = `#id${id}`;
      var loader;
      loader = T1.Loader.create({
        contentClass: "inline-edit-loader",
        target: targetDiv,
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 4
        }
      });
      return loader;
    },
    showBulkPreview: function () {
      var selected = this.collection.getSelected();
      var viewOptions = {};
      if (selected) {
        if (selected.length > 0) {
          viewOptions.selectedModels = selected.models;
        }
      }
      CreativeUtils.showPreview(_.map(viewOptions.selectedModels, function (item) {
        return item.get("atomic_creative_id");
      }), "component");
    },
    showBulkEdit: function () {
      var selected = this.collection.getSelected();
      var viewOptions = {};
      if (selected) {
        if (selected.length > 0) {
          viewOptions.selectedModels = selected.models;
          this.collection.toggleAllSelected(false);
          this.clearGridSelected();
          T1.Location.set("#creatives/componentBased-bulk", {
            viewOptions: viewOptions
          });
        } else {
          T1.Location.set(`#creatives/componentBased/${selected.at(0).id}`, {
            viewOptions: viewOptions
          });
        }
      }
    },
    closeFBStatusPopover: function (e) {
      var $popover = $(e.currentTarget).closest("strand-popover");
      e.preventDefault();
      $popover[0].toggle();
    },
    showSearchTooltip: function (e) {
      var $target = $(e.currentTarget);
      var $parent = $target.parent();
      var className = "w-DisplaySearchTooltip";
      var msg = "Search by Name, ID";
      $(`.${className}`).remove();
      T1.Tooltip($target, {
        trigger: "manual",
        tooltipEle: $parent,
        getExternalTip: false,
        gravity: "s",
        className: className,
        offset: 10
      });
      $parent.attr("title", msg).tipsy("show");
    },
    getColumnHeaderOrder: function () {
      var columns = _mainGrid.getAllColumns();
      var orderedHeaders = columns.map(function (column) {
        return column.field;
      });
      return orderedHeaders;
    },
    formatKeyForExcelHeader: function (key) {
      return key.split("_").join(" ").toUpperCase();
    },
    formatDataForExport: function (gridData) {
      var currentConceptId, data, formattedKey;
      var selectedColumns = this.getColumnHeaderOrder();
      var noResultsMap = {
        campaigns: "No Active Campaigns",
        strategies: "No Active Strategies"
      };
      for (var j = 0; j < gridData.length; j++) {
        if (!gridData[j].campaigns) {
          gridData[j].campaigns = "";
        }
        if (!gridData[j].strategies) {
          gridData[j].strategies = "";
        }
        if (gridData[j].concept_id) {
          currentConceptId = gridData[j].concept_id;
        }
        gridData[j] = _.pick(gridData[j], selectedColumns);
        data = gridData[j];
        for (var key in data) {
          if (data.hasOwnProperty(key) && selectedColumns.indexOf(key) > -1) {
            switch (key) {
              case "item_id":
                data.ID = data[key];
                delete data[key];
                break;
              case "concept_name":
                if (data[key] === "--") {
                  data["CONCEPT (ID)"] = "";
                } else {
                  data["CONCEPT (ID)"] = `${data.concept_name} (${currentConceptId})`;
                }
                delete data.concept_name;
                delete data.concept_id;
                break;
              case "campaigns":
              case "strategies":
                var formattedArr = [];
                if (data[key]) {
                  var currentMap = data[key];
                  for (var id in currentMap) {
                    if (currentMap.hasOwnProperty(id)) {
                      var formattedItem = `${currentMap[id]} (${id})`;
                      formattedArr.push(formattedItem);
                    }
                  }
                  data[key.toUpperCase()] = formattedArr;
                } else {
                  formattedArr.push(noResultsMap[key]);
                  data[key.toUpperCase()] = formattedArr;
                }
                delete data[key];
                break;
              case "end_date":
              case "start_date":
                formattedKey = this.formatKeyForExcelHeader(key);
                if (data[key] === "--") {
                  data[formattedKey] = "";
                } else {
                  data[formattedKey] = data[key];
                }
                delete data[key];
                break;
              case "updated_on":
                data.MODIFIED = data[key];
                delete data[key];
                break;
              case "fb_status":
                data.APPROVAL = data[key];
                delete data[key];
                break;
              case "feedback":
                if (data[key] === "--") {
                  data["FACEBOOK REJECTION REASONS"] = "";
                } else {
                  data["FACEBOOK REJECTION REASONS"] = data[key];
                }
                delete data[key];
                break;
              case "external_identifier":
                data["3PAS ID"] = data[key];
                delete data[key];
                break;
              case "advertiser_name":
                data.ADVERTISER = data[key];
                delete data[key];
                break;
              default:
                formattedKey = this.formatKeyForExcelHeader(key);
                data[formattedKey] = data[key];
                delete data[key];
                break;
            }
          } else {
            delete data[key];
          }
        }
      }
      return JSON.stringify(gridData);
    },
    unload: function () {
      this.collection.abort();
      if (this.collection.fetchOptions.sort_by) {
        _mainGrid.sortField = "";
        delete this.collection.fetchOptions.sort_by;
      }
      if (this.layout) {
        this.layout.unload();
        this.layoutLoaded = false;
      }
      $(window).off("resize.creativesComponent");
      this.allAdvertisersLoaded = false;
      this.collection.toggleAllSelected(false);
    },
    serialize: function () {
      return {
        statusVal: this.componentStatus,
        isCollectionLoaded: this.collection && this.collection.loaded,
        isFeedbackEnabled: T1Permissions.check("feature", "creatives.component_approval_feedback")
      };
    }
  });
});
