/*global define, jQuery, window*/
define('jQPlugins/jquery.smartscroll',['jQuery'], function (jQuery) {
	'use strict';
    (function ($) {
        var ct = 0;
	    $.fn.smartscroll = function (options) {
            var defaults = {
	                scrollHeader: ".header",
	                scrollList: ".list",
	                flexHeader: true,
	                headerOffset: 0,
	                topZindex: 3,
	                bottomZindex: "auto",
	                snapbuffer: 0,
	                topClass: "smart-scroll-top",
	                scrollClass: "smart-scroll-fixed",
	                bottomClass: "smart-scroll-bottom"
				},
				opts;

            options = options || {};
            opts = $.extend({}, defaults, options);

            $(window).resize(function () {
                var fixedHeaders = $("." + opts.scrollClass);
                $.each(fixedHeaders, function () {
                    var $this = $(this),
                        containerWidth = $this.parent().innerWidth();
                    $this.width(containerWidth);
                });
            });

            return this.each(function () {
                var scrollItem = $(this),
                    scrollHeader = scrollItem.find(opts.scrollHeader),
                    scrollHeaderParent = scrollHeader.parent(),
                    scrollList = scrollItem.closest(opts.scrollList),
                    offset = opts.headerOffset,

					list_orig_top = scrollList.offset().top - offset,
                    list_item_orig_height = scrollItem.innerHeight(),
                    list_item_margin = parseInt(scrollItem.css("marginBottom"), 10),

                    hdr_width,
                    hdr_setwidth,
                    hdr_height,
                    hdr_offsetleft,
                    hdr_outerheight = parseInt(scrollHeader.outerHeight(), 10),
                    hdr_border_top,

                    currentScrollClass = '',
                    tempScrollTop = 0,

                    win_top,
                    list_top,
                    list_item_top,
                    prev_distance,
                    distance,
                    dir,
                    interval,
                    threshold,
                    timer;

                function computeSettings(e, scrollLeftPos) {
					var docLeft = scrollLeftPos ? scrollLeftPos :  $(document).scrollLeft(),
						docOffset = $(document).width() - $(window).width();

					docLeft = docLeft < 0 ? 0 : docLeft > docOffset ? docOffset : docLeft;
                    hdr_width = scrollHeader.width();
                    hdr_setwidth = opts.flexHeader ? '100%' : scrollHeader.innerWidth();
                    hdr_height = parseInt(scrollHeader.height(), 10);
                    hdr_offsetleft = (scrollHeaderParent.offset().left +
						parseInt(scrollHeaderParent.css("borderLeftWidth"), 10) + 
						parseInt(scrollHeaderParent.css("paddingLeft"), 10) - 
						docLeft);
                    hdr_border_top = parseInt(scrollHeader.css("borderTopWidth"), 10);
                }

                function repositionHeader(dir, list_item_height, scrollTop) {
                    hdr_setwidth = opts.flexHeader ? '100%' : scrollHeader.innerWidth();

                    win_top = scrollTop;
                    list_top = scrollList.offset().top - offset;
                    list_item_top = scrollItem.offset().top;

                    distance = Math.floor(list_item_top - win_top);
                    prev_distance = prev_distance || distance;
                    dir = dir || -1;
                    interval = (1 + ((prev_distance * dir) - (distance * dir)) * 0.5);
                    interval = interval > 10 ? 10 : interval;
                    prev_distance = distance;
                    threshold = (list_top + interval);
					list_orig_top = list_top;
					
                    if (list_item_top && (distance < threshold)) {

                        if ((distance + list_item_height) >= (list_top + hdr_outerheight)) {

                            if (currentScrollClass === opts.scrollClass) {
								return true;
                            }
                            currentScrollClass = opts.scrollClass;
                            scrollHeader.css({
                                position: "fixed",
                                top: list_top,
                                bottom: "auto",
                                width: hdr_width,
                                left: hdr_offsetleft,
                                zIndex: opts.topZindex
                            }).addClass(opts.scrollClass)
								.removeClass(opts.topClass)
								.removeClass(opts.bottomClass);
							return false;
                        } else {
                            if (currentScrollClass === opts.bottomClass) {
								return true;
                            }
                            currentScrollClass = opts.bottomClass;
                            scrollHeader.addClass(opts.bottomClass).css({
                                position: "absolute",
                                width: hdr_setwidth,
                                top: "auto",
                                bottom: 0,
                                left: 0,
                                zIndex: opts.bottomZindex
                            }).removeClass(opts.topClass)
								.removeClass(opts.scrollClass);
                            return false;
                        }
                    } else {

                        if (currentScrollClass === opts.topClass) {
							return true;
                        }
                        currentScrollClass = opts.topClass;
                        scrollHeader.addClass(opts.topClass).css({
                            position: "absolute",
                            width: hdr_setwidth,
                            top: 0,
                            bottom: "auto",
                            left: 0,
                            zIndex: 'inherit'
                        }).removeClass(opts.bottomClass)
							.removeClass(opts.scrollClass);
                        return false;
                    }
                }

                function adjustPosition(e) {
                    var win_top = $(window).scrollTop(),
                        list_top = scrollList.offset().top - offset,
                        list_item_top = scrollItem.offset().top,
                        dir = (tempScrollTop <= list_item_top) ? -1 : 1,
                        distance = (list_item_top - win_top),
                        list_item_height;

                    if (distance && (distance <= list_top)) {
                        prev_distance = null;
                        $(window).scrollTop(win_top - list_top + distance);
                        list_item_height = scrollItem.innerHeight();
                        if (list_item_orig_height !== list_item_height) {
                            repositionHeader(dir, list_item_height, win_top);
                        }
                    }
                }

				function resetHeaderCSS() {
					scrollHeader.addClass(opts.topClass).css({
						position: "absolute",
						width: hdr_setwidth,
						top: 0,
						bottom: "auto",
						left: 0,
						zIndex: 'inherit'
					}).removeClass(opts.bottomClass)
						.removeClass(opts.scrollClass);
				}
				
                function onscroll(e) {
	                var win_top = $(window).scrollTop(),
	                    list_top = scrollList.offset().top - offset,
	                    list_item_top = scrollItem.offset().top - win_top,
	                    list_item_height = scrollItem.innerHeight(),
                        currentScrollTop,
                        dir;

                    if ((list_item_height > hdr_outerheight) && (list_item_orig_height !== list_item_height)) {
                        currentScrollTop = win_top;
						dir = (tempScrollTop <= currentScrollTop) ? 1 : -1;
                        tempScrollTop = currentScrollTop;
                        repositionHeader(dir, list_item_height, win_top);
					} else if (currentScrollClass === opts.scrollClass) {
                        currentScrollClass = opts.topClass;
						resetHeaderCSS();
                    }
                }
                
                function reposition(e) {
	                var win_top = $(window).scrollTop(),
						list_top = scrollList.offset().top - offset,
						list_item_height = scrollItem.innerHeight(),
                        dir = list_orig_top < list_top? 1 : -1;

                    if (list_orig_top !== list_top) { 
						if (currentScrollClass === opts.scrollClass) {
							scrollHeader.css({
	                            top: list_top
	                        });
	                    }
                        repositionHeader(dir, list_item_height, win_top);
					}
                }
                
                function fixPosition(e, scrollLeftPos) {
					computeSettings(e, scrollLeftPos);
					if (scrollHeader.hasClass(opts.scrollClass)) {
						scrollHeader.css({left: hdr_offsetleft});
					}
				}
	
                function unbind(e) {
					$(document).off("scrollLeft.smartscroll", fixPosition);
					$(window).off("scroll.smartscroll", onscroll);
					$(window).off("resize.smartscroll", computeSettings);
					$(window).off("reposition.smartscroll", reposition);
					resetHeaderCSS();
                }
                
                function bind(e) {
					unbind(e);
					$(document).on("scrollLeft.smartscroll", fixPosition);
					$(window).on("scroll.smartscroll", onscroll);
					$(window).on("resize.smartscroll", computeSettings);
					$(window).on("reposition.smartscroll", reposition);
                }

                computeSettings();

                scrollItem.css({
                    position: "relative",
                    "padding-top": hdr_outerheight
                });

                scrollHeader.css({
                    position: "absolute",
                    width: opts.flexHeader ? '100%' : scrollHeader.innerWidth(),
                    top: 0,
                    left: 0,
                    zIndex: 'inherit'
                });

                scrollHeader.on("click", function () {
					computeSettings();
					adjustPosition();
				});

                if (opts.autoBind) {
	                setTimeout(function () {
						bind(scrollItem);
					}, 0);
                }

                scrollItem.data("smartscroll", {
                    unbind: unbind,
                    bind: bind
                });
            });
        };
    }(jQuery));
});
