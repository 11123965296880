define('modules/reporting/campaigns/technology/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  return new T1Module({
    defaultView: "technology",
    name: "reporting/campaigns/technology",
    viewCfgs: {
      chart: {
        model: null
      },
      chartFilters: {
        model: null
      },
      columnSelector: {
        model: null
      },
      controls: {
        model: null
      },
      donutChart: {
        model: null
      },
      grid: {
        model: null
      },
      panel: {
        model: null
      },
      technology: {}
    }
  });
});
