define('modules/companion/views/list',["jQuery", "T1", "T1View", "text!../templates/list.html", "text!../templates/item.html", "text!../templates/select_item.html", "../models/companionTypes", "models/companion", "collections/companions", "T1FileUpload", "T1Animate", "T1Form"], function ($, T1, T1View, listTemplate, itemTemplate, selectTemplate, companionTypes, Companion, Companions, T1FileUpload, T1Animate, T1Form) {
  var CompanionEditListView;
  CompanionEditListView = T1View.extend({
    template: listTemplate,
    partials: {
      item: itemTemplate
    },
    initialize: function (args) {
      this.fileHandles = [];
      if (args.collection) {
        this.collection = args.collection;
      } else {
        this.collection = new Companions();
      }
    },
    events: {
      "click .add-companion": "addItem",
      "click .remove": "removeItem",
      "click .companion-chk": "togglePanel",
      "change * input[type=text]": "inputChangeHandler",
      "change * textarea": "inputChangeHandler"
    },
    defaultCompanion: {
      adServer: "MediaMath",
      typ: "static/gif"
    },
    load: function () {
      var self = this;
      var $titles;
      this.render().then(function () {
        self.renderPlugins();
        $titles = $(".companion-wrapper .titles", self.el);
        if ($(".companion-holder .companion").length < 1) {
          $($titles).css("opacity", "0.5");
        } else {
          $($titles).css("opacity", "1");
        }
        if ($(".companion-holder .companion").length > 1 && self.collection.models[0].get("action") === "new") {
          $(".companion-holder .companion").each(function () {
            if ($(this).find(".height input").attr("value") === "") {
              $(this).remove();
              $(".companion-holder .companion").last().find(".add-companion").removeClass("hidden");
            }
          });
        }
      });
    },
    canUnload: function () {
      var self = this;
      var isFormElementsChanged = self.isFormElementsChanged;
      self.isFormElementsChanged = false;
      return !isFormElementsChanged;
    },
    renderPlugins: function (context) {
      context = context || this.el;
      var self = this;
      T1.Selectbox($(".server select", context), {
        onChange: function (e) {
          var $target = $(e.currentTarget);
          var $companion = $target.closest(".companion");
          var id = $companion.data("id");
          var model = self.collection.get(id) || self.collection.getByCid(id);
          model.set({
            adServer: $target.val()
          });
          self.toggleServer($target.val(), model, $companion, $target);
          self.formChangeHandler(model);
        }
      });
      T1.Selectbox($(".type select", context), {
        onChange: $.proxy(self.toggleType, self)
      });
      T1Form.Masks.attach($("input", context));
      $(context).find(".type").each(function (index, e) {
        var targetMenu;
        if (e.closest("select")) {
          targetMenu = e.closest("select");
          self.disable = $(targetMenu).is(":disabled");
        }
      });
      $(context).find(".companion-upload").each(function (index, e) {
        var $el = $(e);
        var $companion = $el.closest(".companion");
        var id = $companion.data("id");
        var model = self.collection.get(id) || self.collection.getByCid(id);
        var ext = model.get("fileExtension");
        var file = model.get("fileName") || (ext ? "VideoAsset." + ext : "");
        self.fileHandles[id] = T1FileUpload.create({
          $el: $el,
          gatherFileInfo: true,
          useReader: true,
          onDataReady: $.proxy(self.fileDataHandler, self),
          onChange: $.proxy(self.fileChangeHandler, self)
        });
        self.fileHandles[id].render({
          inputFields: [{
            name: "key",
            value: "test"
          }],
          formSelector: "upload",
          placeholder: file || "Select a file to upload",
          action: "html",
          dataBind: "companion-resourceData",
          accept: "image/jpg,image/png,image/gif,application/x-shockwave-flash",
          disabled: self.disable
        });
      });
    },
    toggleType: function (e) {
      var $target = $(e.currentTarget);
      var $companion = $target.closest(".companion");
      var id = $companion.data("id");
      var model = this.collection.get(id) || this.collection.getByCid(id);
      var $file = $companion.find(".companion-upload");
      var $tag = $companion.find(".companion-tag");
      var $url = $companion.find(".companion-url");
      var $clickThrough = $companion.find(".click input");
      var result = $target.val().split("/");
      var mode = result[0];
      var mime = result[1];
      var time = "400";
      switch (mode) {
        case "static":
          $file.show(time).removeClass("hidden");
          $tag.hide(time);
          $url.hide(time);
          $clickThrough.removeAttr("disabled");
          break;
        case "iframe":
          $file.hide(time);
          $tag.hide(time);
          $url.show(time).removeClass("hidden");
          $clickThrough.attr("disabled", "").val("");
          model.set({
            clickthroughUrl: null
          });
          break;
        case "html":
          $file.hide(time);
          $tag.show(time).removeClass("hidden");
          $url.hide(time);
          $tag.removeClass("hidden");
          $clickThrough.attr("disabled", "").val("");
          model.set({
            clickthroughUrl: null
          });
          break;
      }
      model.set({
        typ: mode,
        mimeType: mime
      });
      this.formChangeHandler(model);
    },
    toggleServer: function (adServer, model, $companion) {
      var $type = $companion.find("select.type");
      this.renderPartialTemplate({
        template: selectTemplate,
        data: this.mapItem(model, true),
        action: "html",
        targetEl: $type,
        useTemplateHtml: false,
        skipDelegateEvents: true
      });
      $type.trigger("liszt:updated");
      this.toggleType({
        currentTarget: $type
      });
    },
    togglePanel: function (e) {
      var self = this;
      var $target = $(e.target);
      var $holder = $(".companion-holder", this.el);
      var $titles = $(".companion-wrapper .titles", this.el);
      var time = "400";
      var $companion = $(".companion-holder .companion");
      T1.EventHub.publish("companionCheck:checked", $target.is(":checked"));
      if ($target.is(":checked")) {
        if ($companion.length < 1) {
          this.addItem();
        }
        $holder.show(time);
        T1Animate({
          target: $titles,
          options: {
            opacity: 1
          },
          duration: time
        });
        self.resetModels("putback");
      } else {
        $holder.hide(time);
        T1Animate({
          target: $titles,
          options: {
            opacity: .5
          },
          duration: time
        });
        self.resetModels("remove");
      }
    },
    resetModels: function (backto) {
      var models = this.collection.models;
      $.each(models, function (index, model) {
        if (backto === "remove") {
          model.set({
            action: "new"
          });
        } else {
          delete model.attributes.action;
        }
      });
    },
    inputChangeHandler: function (e) {
      var $target = $(e.currentTarget);
      var id = $target.closest(".companion").data("id");
      var field = $target.attr("name");
      var model = this.collection.get(id) || this.collection.getByCid(id);
      var data = {};
      data[field] = $target.val();
      model.set(data);
      this.formChangeHandler(model);
    },
    fileChangeHandler: function (e) {
      var data = e && e.data && e.data.fileInfo;
      var $target = $(e.currentTarget);
      var $companion = $target.closest(".companion");
      var $input = $companion.find(".file-name input");
      var id = $companion.data("id");
      var model = this.collection.get(id) || this.collection.getByCid(id);
      var val, $select;
      $input.removeClass("error");
      $input.parent().removeAttr("original-title");
      if (data) {
        model.set({
          mimeType: data.type,
          fileExtension: data.name.split(".")[1],
          fileName: data.name.substring(data.name.lastIndexOf("\\") + 1),
          fileSize: data.size
        });
        val = companionTypes.mimeMap[data.type];
        $select = $companion.find(".type select");
        $select.val("static/" + val);
        $select.trigger("liszt:updated");
        this.formChangeHandler(model);
      }
    },
    fileDataHandler: function (el, data) {
      var $target = $(el);
      var id = $target.closest(".companion").data("id");
      var model = this.collection.get(id) || this.collection.getByCid(id);
      model.set({
        resourceData: data
      });
      this.formChangeHandler(model);
    },
    formChangeHandler: function (model) {
      if (model.get("action") === "new") {
        delete model.attributes.action;
      }
      this.isFormElementsChanged = true;
    },
    addItem: function (e) {
      var model = new Companion(this.defaultCompanion);
      var $target = e ? $(e.currentTarget) : "";
      var $companion;
      if ($target && $target.hasClass("disabled")) {
        return;
      }
      this.collection.add(model);
      $companion = this.el.find(".companion-holder");
      $companion.children().last().find(".add-companion").addClass("hidden");
      this.renderPartialTemplate({
        template: itemTemplate,
        data: this.mapItem(model, true),
        action: "append",
        targetEl: $companion,
        useTemplateHtml: false,
        skipDelegateEvents: true
      });
      this.renderPlugins($companion.children().last());
    },
    removeItem: function (e) {
      var $companion = $(e.currentTarget).closest(".companion");
      var $companions = $(".companion");
      var id = $companion.data("id");
      var companion = this.collection.get(id);
      var $target = $(e.currentTarget);
      if ($target.hasClass("disabled")) {
        return;
      }
      $companion.remove();
      if (companion) {
        companion.set({
          action: "delete"
        });
      } else {
        companion = this.collection.getByCid(id);
        if (companion.id) {
          companion.set({
            action: "delete"
          });
        } else {
          this.collection.remove(companion);
        }
      }
      $companions = $(".companion");
      if ($companions.length) {
        $companions.last().find(".add-companion").removeClass("hidden");
      } else {
        this.addItem();
      }
      this.isFormElementsChanged = true;
    },
    mapItem: function (model, isLast) {
      var out = model.toJSON();
      var typ = model.get("typ");
      var temp, mimeType, typs;
      if (typ.indexOf("/") !== -1) {
        temp = typ.split("/");
        model.set({
          typ: temp[0]
        });
      } else {
        temp = [typ];
        mimeType = model.get("mimeType");
        if (mimeType) {
          mimeType = mimeType.split("/");
          if (mimeType[1] === "jpeg") {
            mimeType[1] = "jpg";
          }
          typ += "/" + mimeType[1];
        }
      }
      out.is3pas = companionTypes.check3pas(model.get("adServer") || "MediaMath");
      typs = out.is3pas ? companionTypes.typ_3pas : companionTypes.typ;
      out.isStatic = temp[0] === "static";
      out.isHTML = temp[0] === "html";
      out.isIframe = temp[0] === "iframe";
      out.serverOptions = companionTypes.toOptionsList(companionTypes.adServer, model.get("adServer") || "MediaMath");
      out.typeOptions = companionTypes.toOptionsList(typs, typ || "static/gif");
      out.last = isLast;
      out.id = model.id || model.cid;
      if (model.get("action") !== "delete") {
        return out;
      }
    },
    serialize: function () {
      var models = this.collection.models;
      var self = this;
      var listItems = [];
      var count = models.length;
      var hasCompanions;
      hasCompanions = count > 0;
      $.each(models, function (index, model) {
        var companion = self.mapItem(model, index === this.collection.models.length - 1);
        if (companion) {
          companion.id = model.id || model.cid;
          companion.preExist = true;
          listItems.push(companion);
        }
      });
      return {
        listItems: listItems,
        hasCompanions: hasCompanions
      };
    }
  });
  return T1.Form.ViewUtils.extend(CompanionEditListView);
});
