define('modules/campaign/siteList/views/bins',["jQuery", "Underscore", "T1BinsView", "text!modules/campaign/siteList/templates/bin_header.html", "text!modules/campaign/siteList/templates/bin_title.html", "text!modules/campaign/siteList/templates/bin_group_name.html", "text!modules/campaign/siteList/templates/bin_item.html"], function ($, _, BinsView, bin_header, bin_title, bin_group_name, bin_item) {
  "use strict";

  var SiteListBinsView = BinsView.extend({
    type: "SiteList",
    configsOverride: true,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: false,
        count: 0,
        use_or: true
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      bin_item: bin_item
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDroppable: true,
      checkExistenceSharedCollection: false,
      onDropEvent: "bins:droppedSiteList"
    },
    dataEvents: {
      sharedCollection: {
        reset: "updateBins"
      }
    },
    initialize: function ({
      campaign: campaign
    }) {
      this.campaign = campaign;
    },
    updateBins: function () {},
    removeItem: function (event) {
      var id = $(event.currentTarget).closest(".item").data("id");
      event.preventDefault();
      event.stopPropagation();
      this.unset(id);
    },
    unset: function (id) {
      var sharedCollection = this.sharedCollection;
      var item = sharedCollection.get(id);
      sharedCollection.remove(id);
      item.set({
        assigned: "0"
      });
      sharedCollection.add(item);
    },
    mapItems: function (items) {
      if (!Array.isArray(items)) {
        throw new Error("mapItems requires an input array");
      }
      return items.reduce((arr, model) => {
        const isActive = model.get("status") === "1";
        if (model.get("assigned") === "1" && isActive) {
          arr.push({
            assignedToCampaign: model.get("campaign_assigned") === "1",
            full_path_end: this.getPath(model.get("name")),
            full_path: model.get("name"),
            id: model.get("id"),
            isBlockList: model.get("restriction") === "EXCLUDE",
            isAllowList: model.get("restriction") === "INCLUDE"
          });
        }
        return arr;
      }, []);
    },
    getPath: function (fullPath) {
      var path = fullPath;
      var goalLength = 30;
      path = path.length > goalLength ? `...${path.substr(-goalLength)}` : path;
      return path;
    }
  });
  return SiteListBinsView;
});
