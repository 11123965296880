define('modules/creatives/display/views/classification',["require", "jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Form", "T1Permissions", "models/atomicCreative", "collections/organizations", "text!../templates/classification.html", "utils/CreativeUtils"], function (require, $, _, T1, T1View, T1Layout, T1Form, T1Permissions, AtomicCreative, Organizations, template, CreativeUtils) {
  "use strict";

  var ClassificationView = T1View.extend({
    template: template,
    actionsConfig: {
      events: {
        "click .cancel": "cancelOverride",
        "click .saveAndClose": "saveAndClose"
      }
    },
    events: {
      "click .save-btn-secondary": "onShowPreview"
    },
    initialize: function (args) {
      var self = this;
      var mode = args.mode;
      var resizeLayout = _.debounce(self.adjustGridHeight, 500);
      self.mode = mode;
      self.model = args.model;
      self.isEdit = args.id !== undefined || self.model.id !== null;
      self.orgID = args.orgID;
      self.isT1AS = self.model.get("t1as") === "1";
      self.canLoad = true;
      $(window).on("resize.creativesClassification", resizeLayout);
    },
    setFormActions: function () {
      var self = this;
      if (!self.isEdit) {
        self.actionsConfig.formActions = [{
          label: "cancel",
          class: "cancel",
          uuid: "cancel",
          location: false
        }, {
          label: "save",
          uuid: "save",
          class: "save btn-primary",
          location: false
        }, {
          label: "save & close",
          uuid: "save_close",
          class: "saveAndClose btn-primary",
          location: false
        }];
      } else {
        self.actionsConfig.formActions = [{
          label: "cancel",
          class: "cancel",
          uuid: "cancel",
          location: false
        }, {
          label: "preview",
          class: "save-btn-secondary",
          location: false
        }, {
          label: "save",
          uuid: "save",
          class: "save btn-primary",
          location: false
        }, {
          label: "save & close",
          uuid: "save_close",
          class: "saveAndClose btn-primary",
          location: false
        }];
      }
    },
    load: function () {
      var self = this;
      var model = self.model;
      var $assignmentView, assignmentView, $el;
      if (!this.canLoad) {
        return;
      }
      this.setFormActions();
      this.render().then(function () {
        $el = $(self.el);
        $assignmentView = $el.find("#creativeAssignments");
        setTimeout(function () {
          $assignmentView.on("classification-add-remove", self.onClassificationAddRemove.bind(self));
          assignmentView = $assignmentView[0];
          assignmentView.updateBin(model.id);
        }, 0);
      });
    },
    postData: function (formValues, success, statusInvalid) {
      var self = this;
      var model = self.model;
      var $el = $(self.el);
      var $assignmentView = $el.find("#creativeAssignments");
      var assignmentView, errors;
      function successHandler() {
        T1.Notify("message", "Creative updated successfully!");
        success(model);
        self.delegateEvents();
      }
      function errorHandler(caller, promise, errorObj) {
        if (errorObj) {
          var errorResponse = errorObj.response.errors;
          errors = !errorResponse.field_error.length ? [errorResponse.field_error] : errorResponse.field_error;
          errors = errors.map(errors, function (error) {
            return {
              field: error.name,
              message: error.error
            };
          });
          self.showErrors(model, errors, ".w-CreativesCreateEdit");
          statusInvalid(errors, null, ".form-body");
        }
      }
      assignmentView = $assignmentView[0];
      assignmentView.saveClassification().then(successHandler, errorHandler);
    },
    onShowPreview: function () {
      CreativeUtils.showPreview([this.model.get("id")]);
    },
    onClassificationAddRemove: function () {
      this.isFormElementsChanged = true;
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          setTimeout(function () {
            T1.Location.set("#creatives/displays");
          }, 1e3);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    adjustGridHeight: function () {
      var gridHeight = Math.round(window.innerHeight) - 350;
      $("#viewport", this.el).height(gridHeight);
    },
    cancelClassification: {
      onAlertClose: function () {
        T1.Location.set("#creatives/displays");
      }
    },
    cancelOverride: function (e, fromUnloadAlertDlg) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var displayName = {
        name: this.isEdit ? "Display Creative Edit" : "New Display Creative"
      };
      this.preventDefault(e);
      e.stopImmediatePropagation();
      if (fromUnloadAlertDlg === true || !isFormElementsChanged) {
        this.cancelClassification.onAlertClose();
      } else {
        this.openUnloadAlertDialog(displayName, this.cancelClassification);
      }
    },
    unload: function () {
      $(window).off("resize.creativesClassification");
    },
    canUnload: function (args) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var tagType = this.isT1AS ? "TerminalOne Ad Tag" : "3rd Party Ad Tag";
      var message = this.isEdit ? "Edit " + tagType : "Add " + tagType;
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: message
        }, args);
      }
      return !isFormElementsChanged;
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgID;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    serialize: function () {
      var model = this.model;
      var output = model.toJSON();
      output.crid = output.id;
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(ClassificationView);
});
