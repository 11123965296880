define('modules/admin/user/models/userPermissions',[], function () {
  "use strict";

  const userPermissions = {
    edit_campaigns: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: false
              }
            },
            REPORTER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            }
          }
        }
      }
    },
    edit_site_lists: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            REPORTER: {
              view: {
                isChecked: false,
                isDisabled: true
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            }
          }
        }
      }
    },
    edit_pixels: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            REPORTER: {
              view: {
                isChecked: false,
                isDisabled: true
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            }
          }
        }
      }
    },
    view_reports: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: true
              }
            },
            REPORTER: {
              view: {
                isChecked: true,
                isDisabled: true
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  }
                }
              }
            }
          }
        }
      }
    },
    edit_margins: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: false
              }
            },
            REPORTER: {
              view: {
                isChecked: false,
                isDisabled: false
              },
              edit: {
                isChecked: false,
                isDisabled: false
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: false
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: false
                  }
                }
              }
            }
          }
        }
      }
    },
    edit_users: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: true,
                isDisabled: true
              }
            },
            REPORTER: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            }
          }
        }
      }
    },
    view_organizations: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: true
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            },
            REPORTER: {
              view: {
                isChecked: false,
                isDisabled: true
              },
              edit: {
                isChecked: false,
                isDisabled: true
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: true
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: false,
                    isDisabled: true
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: true
                  }
                }
              }
            }
          }
        }
      }
    },
    edit_segments: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: true,
                isDisabled: false
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: true,
                isDisabled: false
              }
            },
            REPORTER: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: true,
                isDisabled: false
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                }
              }
            }
          }
        }
      }
    },
    edit_data_definition: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: true,
                isDisabled: false
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: false,
                isDisabled: false
              }
            },
            REPORTER: {
              view: {
                isChecked: true,
                isDisabled: false
              },
              edit: {
                isChecked: false,
                isDisabled: false
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: false
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  },
                  edit: {
                    isChecked: false,
                    isDisabled: false
                  }
                }
              }
            }
          }
        }
      }
    },
    view_dmp_reports: {
      type: {
        AGENCY: {
          role: {
            ADMIN: {
              view: {
                isChecked: true,
                isDisabled: false
              }
            },
            MANAGER: {
              view: {
                isChecked: true,
                isDisabled: false
              }
            },
            REPORTER: {
              view: {
                isChecked: true,
                isDisabled: false
              }
            }
          }
        },
        INTERNAL: {
          scope: {
            GLOBAL: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  }
                }
              }
            },
            SELECT: {
              role: {
                ADMIN: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                MANAGER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  }
                },
                REPORTER: {
                  view: {
                    isChecked: true,
                    isDisabled: false
                  }
                }
              }
            }
          }
        }
      }
    }
  };
  return {
    userPermissions: userPermissions
  };
});
