define('utils/validatorComponentCreatives',["Underscore", "T1"], function (_, T1) {
  "use strict";

  function validateDateFormat(val) {
    if (val && val.replace(/\s/g, "") === "") {
      return true;
    }
    if (val && val !== "") {
      return Date.parse(val);
    }
    return null;
  }
  function isJustWhitespace(val) {
    if (val && val.replace(/\s/g, "") === "") {
      return "must not be empty";
    }
    return false;
  }
  function isAlphaNumeric(val) {
    return /^[a-z0-9]+$/i.test(val);
  }
  function isFBUrl(val) {
    return /^(http|https):\/\/[^ "]+$/.test(val);
  }
  const CreativeComponentValidator = {
    isEdit: false,
    validateName: function (name) {
      var validations = [];
      var length = name.length;
      var isEmpty = isJustWhitespace(name);
      const MAX_CHARS = 256;
      if (length < 1 || length > MAX_CHARS) {
        validations.push({
          reason: "Creative name too long",
          tooltip: "256 character max."
        });
      }
      if (isEmpty) {
        validations.push({
          reason: `Name ${isEmpty}`,
          tooltip: 'View "specifications" tab in template.'
        });
      }
      return validations;
    },
    validateExternalIdentifier: function (id) {
      var validations = [];
      const MAX_CHARS = 64;
      if (id.length > MAX_CHARS) {
        validations.push({
          reason: "3PAS Tag ID must not exceed 64 characters.",
          tooltip: "3PAS Tag ID must not exceed 64 characters."
        });
      }
      return validations;
    },
    validateConceptNameForBulk: function (conceptName) {
      var validations = [];
      var hasNumbers = /\(\d+\)$/.test(conceptName);
      var toBeCreated = /\(Not yet created\)$/.test(conceptName);
      if (!hasNumbers && !toBeCreated) {
        validations.push({
          reason: "Concept does not exist",
          tooltip: "Copy and paste IDs from .xls template"
        });
      }
      return validations;
    },
    validateDateFormat: function (val) {
      var validations = [];
      if (!validateDateFormat(val)) {
        validations.push({
          reason: "Invalid date format",
          tooltip: "Date must be in MM/DD/YY format"
        });
      }
      return validations;
    },
    validateImage: function (name, asset) {
      var validations = [];
      var hasImageAsset;
      hasImageAsset = asset.image_filename === name;
      if (!hasImageAsset) {
        validations.push({
          reason: "Invalid image file reference",
          tooltip: "Image name must match a file name in zip"
        });
      }
      return validations;
    },
    validatePageId: function (pageId) {
      var validations = [];
      if (!isFBUrl(pageId) && !isAlphaNumeric(pageId)) {
        validations.push({
          reason: "Invalid Page ID format",
          tooltip: "Page ID must be alphanumeric"
        });
      }
      return validations;
    },
    validateAttributes: function (attributes, componentsMap) {
      var self = this;
      var assetValue, assets, attr, componentId, componentName, prop, validationErrors;
      var errors = {
        missingRequired: [],
        general: []
      };
      var required = ["id", "creative_name", "concept"];
      for (let r = 0; r < required.length; r++) {
        attr = required[r];
        prop = attributes[attr];
        if (prop === undefined) {
          switch (attr) {
            case "id":
              if (self.isEdit && attributes.id === undefined) {
                errors.general.push({
                  reason: "Missing ID",
                  tooltip: "Cannot create new Creatives while Bulk Editing"
                });
              }
              break;
            case "creative_name":
              if (attributes.creative_name === undefined) {
                errors.general.push({
                  reason: "Missing name",
                  tooltip: "Cannot create or edit Creative without a name"
                });
              }
              break;
          }
        }
      }
      for (const a in attributes) {
        if (T1.Utils.hasOwnProp(attributes, a)) {
          prop = attributes[a];
          switch (a) {
            case "creative_name":
              validationErrors = self.validateName(prop);
              break;
            case "start_date":
            case "end_date":
              validationErrors = self.validateDateFormat(prop);
              break;
          }
          errors.general = _.union(errors.general, validationErrors);
        }
      }
      if (attributes.assets) {
        assets = JSON.parse(attributes.assets);
        for (let i = 0; i < assets.length; i++) {
          assetValue = assets[i].asset_value;
          componentId = assets[i].component_id;
          componentName = componentsMap[componentId];
          switch (componentName) {
            case "Image":
              validationErrors = self.validateImage(attributes.image_filename, assets[i]);
              break;
            case "Page ID":
              validationErrors = self.validatePageId(assetValue);
              break;
          }
          errors.general = _.union(errors.general, validationErrors);
        }
      }
      return errors;
    }
  };
  return CreativeComponentValidator;
});
