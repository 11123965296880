define('modules/campaign/siteList/views/panel',["jQuery", "T1", "T1View", "T1Layout", "utils/CampaignStrategyUtils", "text!modules/campaign/siteList/templates/panel.html", "text!templates/form_footer.html"], function ($, T1, T1View, T1Layout, CampaignStrategyUtils, template, form_footer) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var SiteListPanel = T1View.extend({
    template: template,
    viewMode: "browse",
    partials: {
      form_footer: form_footer
    },
    loaderOpts: {
      target: ".view-loader",
      text: "Loading ...",
      contentClass: "loader"
    },
    events: {
      "focus .text": "showTooltip",
      "blur .text": "hideTooltip",
      "keydown .text": "removeTooltip"
    },
    initialize: function ({
      getCampaign: getCampaign
    }) {
      this.campaign = getCampaign();
      this.campaign.getOrgId().then(orgId => {
        this.orgId = orgId;
        this.sharedCollection = this.campaign.fetchSiteLists($.proxy(this.initLayout, this));
      });
    },
    dataEvents: {
      sharedCollection: {
        add: function () {
          this.isFormElementsChanged = true;
        },
        remove: function () {
          this.isFormElementsChanged = true;
        },
        reset: function () {
          if (this.layout) {
            this.layout.load();
          }
          this.isFormElementsChanged = false;
        }
      }
    },
    load: function () {
      this.render().then(() => {
        if (this.layout && this.sharedCollection.loaded) {
          this.layout.load();
        } else {
          this.loader().start();
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn();
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: '<div class="column-A"></div><div class="bins-column"></div>',
        layout: {
          ".column-A": [{
            module: "siteList",
            viewType: "list",
            options: {
              sharedCollection: this.sharedCollection,
              orgId: this.orgId
            }
          }],
          ".bins-column": [{
            module: "campaign/siteList",
            viewType: "bins",
            options: {
              sharedCollection: this.sharedCollection
            }
          }]
        }
      });
      this.load();
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var campaignName = "untitled campaign";
      if (isFormElementsChanged) {
        const model = this.campaign;
        if (model.get("name")) {
          campaignName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: campaignName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: campaignName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    unload() {
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    onDontSave: function () {
      this.sharedCollection.reset([]);
      this.sharedCollection.fetch();
    },
    cancelUnload: function () {
      T1.EventHub.publish("campaign-create-edit:cancelUnload");
    },
    postData: function (formValues, successCallback, conflictCallback) {
      this.sharedCollection.saveSiteList(formValues, successCallback, conflictCallback);
    }
  });
  return T1.Form.ViewUtils.extend(SiteListPanel);
});
