define('collections/creatives',["jQuery", "Underscore", "T1", "T1Collection", "models/atomicCreative", "T1Sortable"], function ($, _, T1, T1Collection, AtomicCreative) {
  T1.Models.AtomicCreatives = T1Collection.extend({
    model: AtomicCreative,
    canCache: true,
    cacheDuration: 18e5,
    filterConfiguration: {
      filters: [{
        entity: "concept",
        fetchOnChange: false
      }]
    },
    searchConfiguration: {
      fields: {
        status: {
          type: "enum"
        }
      }
    },
    fetchOptions: {
      full: "*",
      with: ["creatives,vendor_pixels,vendor_pixel_domains,vendor_domain,vendor", "creatives,creative_assets", "atomic_creative_ssl_validators"]
    },
    fetch: function (opts) {
      T1Collection.prototype.fetch.call(this, opts);
    },
    urlString: "atomic_creatives",
    parse: function (data) {
      var parsedData = T1Collection.prototype.parse.call(this, data);
      var ac;
      if ($.isArray(parsedData)) {
        $.each(parsedData, function (key, entity) {
          ac = new AtomicCreative(entity);
          _.extend(entity, ac.parse.call(ac, entity));
        });
      }
      return parsedData;
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    toggleAllSelected: function (isSelected) {
      this.each(function (model) {
        model.set({
          selected: isSelected
        });
      });
    }
  });
  return T1.Models.AtomicCreatives;
});
