define('modules/T1App/models/t1AppViews',["jQuery", "T1", "T1Model", "Backbone"], function ($, T1, T1Model) {
  "use strict";

  T1.Models.newAppViewModel = T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    url: "v1.0/app-views",
    dataType: "json",
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred;
    },
    processAjaxResponse: function (resp) {
      var jsonData;
      var dataType;
      function isJson(str) {
        try {
          jsonData = JSON.parse(str);
        } catch (e) {
          dataType = "plaintxt";
        }
      }
      return {
        jsonData: isJson(resp) ? jsonData : resp,
        statusCode: resp.status.code || "ok"
      };
    },
    createAppView: function (userId, appId, orgId, appVersion) {
      var self = this;
      self.set({
        app_id: parseInt(appId, 10),
        user_id: parseInt(userId, 10),
        org_id: parseInt(orgId, 10),
        app_version: appVersion
      });
      self.createEdit(self.toJSON(), {}).pipe(function () {
        var def = $.Deferred();
        var saveOpts = {
          dataType: "json",
          stringify: true,
          contentType: "application/json",
          processData: false,
          processAjaxResponse: self.processAjaxResponse
        };
        self.save(self.toJSON(), saveOpts);
        return def.promise();
      });
    }
  });
  return T1.Models.newAppViewModel;
});
