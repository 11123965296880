define('T1UtilsAsync',[], function () {
  var Async = {
    makePromise: function (fn) {
      return new Promise(fn);
    },
    linkPromises: function (promises) {
      return Promise.all(promises);
    },
    makeDeferral: function () {
      var deferral = {};
      deferral.promise = new Promise(function (resolve, reject) {
        deferral.resolve = resolve;
        deferral.reject = reject;
      });
      return deferral;
    },
    wrapExistingPromiseFunction: function (existingFunction) {
      var wrappingFunction = function (resolve, reject) {
        existingFunction(function resolver(fn, fallback) {
          return function wrapped(...args) {
            var value = typeof fn === "function" ? fn.apply(this, args) : fallback;
            resolve(value);
            return value;
          };
        }, function rejecter(fn, fallback) {
          return function wrapped(...args) {
            var value = typeof fn === "function" ? fn.apply(this, args) : fallback;
            reject(value);
            return value;
          };
        });
      };
      return Async.makePromise(wrappingFunction);
    }
  };
  return Async;
});
