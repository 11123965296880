define('models/vendorPixel',["T1", "T1Model", "Backbone", "models/vendorPixelDomain"], function (T1, T1Model, Backbone, VendorPixelDomain) {
  T1.Models.VendorPixel = T1Model.extend({
    url: "vendor_pixel/",
    relations: [{
      type: Backbone.HasMany,
      key: "vendor_pixel_domain",
      relatedModel: "VendorPixelDomain",
      includeInJSON: true
    }]
  });
  return T1.Models.VendorPixel;
});
