define('modules/reporting/segments/dataExport/createEdit/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "collections/segments", "text!../templates/panel.html"], function ($, _, T1, T1View, T1Layout, Segments, template) {
  "use strict";

  var PUBLISH;
  var PixelPanel = T1View.extend({
    template: template,
    eventhubEvents: {
      "createEdit.OverlapSelected": "showOverlap",
      "createEdit.PerformanceSelected": "showPerformance"
    },
    initialize: function (model) {
      this.model = model;
      this.sharedCollection = new Segments(null, {
        pageLimit: 10,
        pageOffset: 0
      });
      PUBLISH = T1.EventHub.publish;
    },
    load: function () {
      var self = this;
      var $el = this.el;
      this.initLayout();
      this.render().then(function render() {
        var args = {};
        var prevReportType, newReportType;
        self.layout.load();
        $el.css("visibility", "visible");
        $(".nav-list, .knowledge-base, .logout, .active").unbind().click(function (event) {
          args = {};
          args.type = "url";
          args.path = event.target.hash;
          self.warnForUnsavedChanges(event, args);
        });
        $(".report-select").on("changed", function (event) {
          prevReportType = self.model.getSelectedReport();
          if (prevReportType) {
            newReportType = event.target.value;
            if (prevReportType.toLowerCase() !== newReportType.toLowerCase()) {
              args = {};
              args.type = "segment report change";
              args.newReportType = newReportType;
              self.warnForUnsavedChanges(event, args);
            }
          }
        });
      });
    },
    initLayout: function () {
      var self = this;
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-panel-body",
        template: "<div class='column-A'></div><div class='bins-column'></div>",
        layout: {}
      });
    },
    showFields: function (page) {
      var self = this;
      self.layout.destroy();
      self.initLayout();
      this.layout.append(".column-A", {
        module: "reporting/segments/dataExport/createEdit/" + page,
        viewType: "list",
        options: {
          sharedCollection: self.sharedCollection,
          model: self.model
        }
      });
      this.layout.append(".bins-column", {
        module: "reporting/segments/dataExport/createEdit/" + page,
        viewType: "bins",
        options: {
          sharedCollection: self.sharedCollection
        }
      });
    },
    showOverlap: function () {
      var $el = this.el;
      $el.removeClass("faded");
      this.showFields("overlap");
    },
    showPerformance: function () {
      var $el = this.el;
      $el.removeClass("faded");
      this.showFields("performance");
    },
    unload: function unload() {
      T1.EventHub.publish("unloadSegmentsReports");
    },
    warnForUnsavedChanges: function (event, args) {
      var areChangesValid = this.model.isSegmentsChanged();
      var anySegmentsChanged = this.model.anyChanges();
      var isEmailValid = this.model.get("isEmailValid");
      var isEmailFieldEmpty = this.model.isEmailFieldEmpty();
      var reportType = this.model.getSelectedReport();
      var isReportJustSaved = this.model.getSavedState();
      var isSaveDisabled = false;
      var data = {};
      event.stopPropagation();
      event.preventDefault();
      if ((anySegmentsChanged || !isEmailFieldEmpty) && !isReportJustSaved) {
        isSaveDisabled = areChangesValid && isEmailValid ? false : true;
        data = {
          unloadType: args.type,
          reportType: reportType,
          isSaveDisabled: isSaveDisabled
        };
        if (args.type === "url") {
          data.oneTruePath = args.path;
        } else if (args.type === "segment report change") {
          data.newReportType = args.newReportType;
        }
        PUBLISH("footer.showModal", data);
      } else {
        if (args.type === "url") {
          T1.Location.set(args.path);
          return;
        } else {
          PUBLISH("reportType.changed", event);
        }
      }
    },
    serialize: function serialize() {
      return {};
    }
  });
  PixelPanel = T1.Form.ViewUtils.extend(PixelPanel);
  PixelPanel.prototype.saveOriginal = PixelPanel.prototype.save;
  PixelPanel.prototype.save = PixelPanel.prototype.saveOverride;
  return PixelPanel;
});
