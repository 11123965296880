define('modules/strategy/views/helpers/videoSummaryTarget',["Underscore", "jQuery", "T1"], function (_, $, T1) {
  "use strict";

  const T1hasOwnProp = T1.Utils.hasOwnProp;
  return {
    prepareForSerialize: function (strategyModel, digitalCollection) {
      var collObj = digitalCollection;
      const partialCollObj = strategyModel.targetDimensions;
      var codeNameRef = {
        VPSZ: "Player Size",
        VLIN: "Linear Format",
        VCON: "Content Initiation",
        VAUD: "Audio",
        VSKP: "Skippability",
        VPLC: "Video Placement"
      };
      const resultJSON = {
        data: []
      };
      var formatName = function (model) {
        let parent;
        var parentId = model.get("parent_value_id");
        if (parentId) {
          partialCollObj.get("partial").each(function (parentModel) {
            if (parentModel.get("id") === parentId) {
              parent = parentModel;
            }
          });
          if (!parent) {
            collObj.each(function (parentModel) {
              if (parentModel.get("id") === parentId) {
                parent = parentModel;
              }
            });
          }
          if (parent) {
            return `${parent.get("name")} &raquo; ${model.get("name")}`;
          }
        }
        return model.get("name");
      };
      var codeNameHash = {
        VPSZ: [],
        VLIN: [],
        VCON: [],
        VAUD: [],
        VSKP: [],
        VPLC: []
      };
      var sortIncEx = function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      };
      var createDataBlock = function (groupedHash, titleResolverHash) {
        var dataArray = [];
        for (const key in groupedHash) {
          if (T1hasOwnProp(groupedHash, key)) {
            const itemHolderArray = [];
            const tmpArr = groupedHash[key];
            if (tmpArr && tmpArr.length) {
              let tmpTitle = null;
              if (titleResolverHash) {
                tmpTitle = titleResolverHash[key];
              }
              if (!tmpTitle) {
                tmpTitle = key;
              }
              for (const listItem in tmpArr) {
                if (T1hasOwnProp(tmpArr, listItem)) {
                  itemHolderArray.push({
                    action: tmpArr[listItem].action,
                    label: tmpArr[listItem].name
                  });
                }
              }
              itemHolderArray.sort(sortIncEx);
              dataArray.push({
                header: tmpTitle,
                items: itemHolderArray
              });
            }
          }
        }
        return dataArray;
      };
      const models = collObj.models;
      models.forEach(function (model) {
        if (model.get("dimension_code") === "VSKP") {
          model.set({
            name: model.get("name").split(" ")[0]
          });
        }
      });
      if (!collObj || !models || !models.length) {
        this.hasData = false;
        return resultJSON;
      }
      $.each(models, function (index, model) {
        var action = model.get("action");
        var dimCode = model.get("dimension_code");
        var tmpArr = codeNameHash[dimCode];
        if (tmpArr) {
          tmpArr.push({
            name: formatName(model),
            action: action
          });
        }
      });
      const tempData = createDataBlock(codeNameHash, codeNameRef);
      if (tempData.length) {
        resultJSON.data = tempData;
      }
      return resultJSON;
    }
  };
});
