define('modules/reporting/campaigns/siteTransparency/views/panel',['require','jQuery','Underscore','collections/campaignReport','text!../templates/layout.html','modules/reporting/utils/reportingComm','T1','T1Layout','T1Permissions','T1PerformanceTimer','T1View','text!../templates/panel.html','modules/reporting/campaigns/utils/reportingUtils','When'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const CampaignReport = require("collections/campaignReport");
  const layout = require("text!../templates/layout.html");
  const ReportingComm = require("modules/reporting/utils/reportingComm");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1Timer = require("T1PerformanceTimer");
  const T1View = require("T1View");
  const template = require("text!../templates/panel.html");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  const When = require("When");
  const GAMap = {
    filterDimensionsApiLoad: "RPTG:SITE:API",
    siteDomainsApiLoad: "RPTG:SITE:API:SITE_DOMAIN",
    siteDomainsDailyApiLoad: "RPTG:SITE:API:SITE_DOMAIN_DAILY",
    totalLoad: "RPTG:SITE:API:LOAD:TOTAL"
  };
  const trackingSection = "Reporting - Site Transparency";
  const dateFormat = "yyyy-MM-dd";
  let comm, timerValue;
  return T1View.extend({
    template: template,
    dimensionData: "strategy_id",
    section: "siteTransparency",
    dataEvents: {
      reportModel: {
        "change:tempDimension": "updateData",
        "change:tempDim2": "updateData",
        "change:dimension": function () {
          this.changeDimension();
        },
        "change:dim2": function () {
          this.el.trigger("action:resetFirstDimFilter");
          this.changeDimension();
        },
        "change:filteredOutItems": function () {
          if (this.dim2Key) {
            this.prevDim1Key = undefined;
            this.changeDimension();
          } else {
            T1.EventHub.publish("change:filteredOutItems");
          }
        },
        "change:filteredOutItemsDim2": function () {
          this.el.trigger("action:resetFirstDimFilter");
          this.prevDim2Key = undefined;
          this.changeDimension();
        }
      },
      campaign: {
        "change:start_end": function () {
          const {
            campaign: campaign,
            reportModel: reportModel
          } = this;
          this.report.start = campaign.get("start");
          this.report.end = campaign.get("end");
          this.report.isCTD = campaign.get("isCTD");
          reportModel.set({
            isCTD: this.report.isCTD
          });
          reportModel.resetUIStates();
          reportModel.setSearchTerm("");
          this.updateData();
        }
      }
    },
    eventhubEvents: {
      "reports:select:campaign": function (args) {
        if (this.currentReportId !== args.id) {
          this.report.id = args.id;
          this.load();
        }
      },
      "select:siteFilter": function () {
        const reportModel = this.reportModel;
        this.filterRange = reportModel.get("siteFilterRange");
        this.filterMetric = reportModel.get("siteFilterMetric");
        this.filterExcludeZeros = reportModel.get("siteFilterExcludeZeros");
        reportModel.resetHiddenItems();
        this.load();
      },
      "select:interval": "load",
      "remove:dim2": function (isReloadNeeded) {
        this.dim2Key = undefined;
        if (isReloadNeeded) {
          this.changeDimension();
        }
      },
      "controls.showSiteList": "showSiteList",
      "close:siteList": "closeSiteList",
      "toggle:loader": function (show) {
        this.toggleLoader(show);
      }
    },
    initialize(args) {
      let reportModel;
      let strategyChannelFilter = "";
      const self = this;
      const dailyFetchOptions = {
        dimensions: "site_domain",
        filter: "campaign_id=",
        precision: 2,
        time_rollup: "by_day",
        v2: ""
      };
      comm = new ReportingComm({
        id: "siteTransparency"
      });
      this.cfgs = args;
      this.cfgs.dataGroup = comm.dataGroup;
      this.report = this.cfgs.report;
      const models = this.cfgs.models;
      this.mainModel = models.mainModel;
      this.reportMeta = models.reportMeta;
      this.reportModel = reportModel = models.reportModel;
      this.currentReportId = this.report.id;
      this.campaign = models.campaign;
      const dimensionInfo = reportModel.getDimensionInfo(false);
      this.dimensionData = dimensionInfo.key;
      this.hasViewabilityFlag = T1Permissions.check("feature", "reporting_viewability");
      this.cfgs.type = "summary";
      const focus = reportModel.get("focus");
      if (focus === "all") {
        this.filterDimension = comm.buildCollection("filterDimension", models.siteFilterDimension);
        this.cfgs.collection = this.collection = comm.buildCollection("collection", models.siteDomainCollection);
      } else {
        strategyChannelFilter = `strategy_channel="${focus.toUpperCase()}"&campaign_id=`;
        models.siteFilterStrategyChannelDimension = $.extend(true, {}, models.siteFilterDimension);
        models.siteFilterStrategyChannelDimension.fetchOptions.filter = strategyChannelFilter;
        models.siteDomainStrategyChannelCollection = $.extend(true, {}, models.siteDomainCollection);
        models.siteDomainStrategyChannelCollection.fetchOptions.filter = strategyChannelFilter;
        this.filterDimension = comm.buildCollection("filterDimension", models.siteFilterStrategyChannelDimension);
        this.cfgs.collection = this.collection = comm.buildCollection("collection", models.siteDomainStrategyChannelCollection);
      }
      this.cfgs.dailyCollection = this.dailyCollection = comm.buildCollection("dailyCollection", new CampaignReport({
        urlString: "std/site_transparency",
        fetchOptions: dailyFetchOptions,
        url: function () {
          const opts = $.extend({}, this.fetchOptions);
          const limitedSites = self.limitedSites = self.collectionData.pluck("site_domain");
          const moreFilters = `&site_domain=${self.reportModel.getEscapedNames(limitedSites)}`;
          opts.filter += String(this.id) + moreFilters;
          return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
        }
      }));
      this.updateFetchOptionsDate(dailyFetchOptions);
      this.initLayouts();
      this.setGetDataOnce();
    },
    initLayouts() {
      this.layout = new T1Layout({
        el: () => this.$(".w-report-panel"),
        template: layout,
        layout: {
          ".report-chart": [{
            module: "reporting/campaigns/siteTransparency",
            options: this.cfgs,
            viewType: "chartPanel"
          }],
          ".report-controls": [{
            module: "reporting/campaigns/siteTransparency",
            options: this.cfgs,
            viewType: "controls"
          }],
          ".report-grid": [{
            module: "reporting/campaigns/siteTransparency",
            options: this.cfgs,
            viewType: "grid"
          }]
        }
      });
      this.siteListLayout = new T1Layout({
        el: () => this.$(".w-sitelist"),
        template: '<div class="site-list-holder"></div>',
        layout: {
          ".site-list-holder": [{
            module: "reporting/campaigns/siteTransparency",
            viewType: "addToSiteList",
            options: {
              orgID: this.campaign.get("advertiser").get("agency").get("organization_id"),
              reportMeta: this.reportMeta,
              reportModel: this.reportModel
            }
          }]
        }
      });
      this.layout.serialize = () => ({
        chartHidden: !this.chartShown
      });
    },
    updateFetchOptionsDate(fetchOptions) {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      if (reportModel.get("isCTD")) {
        delete fetchOptions.start_date;
        delete fetchOptions.end_date;
        fetchOptions.time_window = "campaign_to_date";
      } else {
        delete fetchOptions.time_window;
        if (reportModel.get("startDate")) {
          fetchOptions.start_date = mainModel.getDateString(reportModel.get("startDate"));
          fetchOptions.end_date = mainModel.getDateString(reportModel.get("endDate"));
        } else {
          fetchOptions.start_date = Utils.defaultStartDate.toString(dateFormat);
          fetchOptions.end_date = Utils.defaultEndDate.toString(dateFormat);
        }
      }
    },
    showSiteList() {
      this.siteListLayout.load();
    },
    closeSiteList() {
      this.siteListLayout.destroy();
    },
    getDateFetchOptions() {
      let end_date, start_date;
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      if (reportModel.get("isCTD")) {
        this.dateOption = "ctd";
        return {
          time_window: "campaign_to_date"
        };
      }
      if (reportModel.get("startDate")) {
        start_date = mainModel.getDateString(reportModel.get("startDate"));
        end_date = mainModel.getDateString(reportModel.get("endDate"));
      } else {
        start_date = Utils.defaultStartDate.toString(dateFormat);
        end_date = Utils.defaultEndDate.toString(dateFormat);
      }
      this.dateOption = `${start_date}:${end_date}`;
      return {
        start_date: start_date,
        end_date: end_date
      };
    },
    changeDimension() {
      var reportModel = this.reportModel;
      reportModel.resetHiddenItems();
      reportModel.setSearchTerm("");
      this.load();
    },
    timer(category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    },
    getUrlString(section) {
      return `std/${this.hasViewabilityFlag ? `${section}_viewability` : section}`;
    },
    getData() {
      let dim, dim2, dim2FilteredItems, dim2FilteredOutCount, dim2Key, dim2UrlParams, dimKey, originalFilter, originalFilterIndex;
      const self = this;
      const {
        cfgs: cfgs,
        timer: timer,
        filterDimension: filterDimension,
        collection: siteDomain,
        dailyCollection: siteDomainDaily,
        reportModel: reportModel,
        report: report
      } = this;
      const reportID = report.id;
      const models = cfgs.models;
      const dimensionInfo = reportModel.get("dimensionInfo");
      const tempDimension = reportModel.get("tempDimension");
      const dim2Info = reportModel.get("dim2Info");
      const tempDim2 = reportModel.get("tempDim2");
      const tempDimensionInfo = reportModel.get("tempDimensionInfo");
      const tempDim2Info = reportModel.get("tempDim2Info");
      const focus = reportModel.get("focus") || "all";
      const section = "site_transparency";
      let dateFetchOptions = this.dateFetchOptions;
      const doLoadDim2 = Boolean(tempDim2Info && tempDim2Info.key || dim2Info && dim2Info.key);
      let dimensionName = "";
      let dimensionName2 = "";
      let dimName = "";
      let dim2Name = "";
      let silent = false;
      const filterDimensionDeferred = When.defer();
      const siteDomainDeferred = When.defer();
      const siteDomainDailyDeferred = When.defer();
      const promises = [filterDimensionDeferred.promise, siteDomainDeferred.promise, siteDomainDailyDeferred.promise];
      const filterMetric = this.filterMetric || reportModel.get("siteFilterMetric");
      const filterLookup = filterMetric.replace("-", "");
      const metricType = this.reportMeta.getDisplayMetrics()[filterLookup].type;
      const isInteger = metricType === "integers";
      const base = 10;
      const dataThreshold = isInteger ? 0 : 1 / Math.pow(base, siteDomain.fetchOptions.precision);
      const thresholdOperator = isInteger ? ">" : ">=";
      const excludeZeros = this.filterExcludeZeros || reportModel.get("siteFilterExcludeZeros");
      const havingParam = T1.Utils.valueToBool(excludeZeros) ? filterLookup + thresholdOperator + dataThreshold : "";
      const requestMetrics = Object.keys(this.reportMeta.getRequestMetrics()).toString();
      const campaignFetchOptionsExtend = {};
      if (!reportID) {
        return;
      }
      if (!(tempDim2Info && tempDim2Info.key)) {
        this.toggleLoader(true);
      }
      this.prevDateOption = this.dateOption;
      dateFetchOptions = this.getDateFetchOptions();
      this.updateFetchOptionsDate(campaignFetchOptionsExtend);
      this.campaignFetchOptionsExtend = campaignFetchOptionsExtend;
      if (tempDimensionInfo) {
        dimensionName = tempDimensionInfo.dimension;
      } else if (dimensionInfo) {
        dimensionName = dimensionInfo.dimension;
      }
      dimName = dimensionName.toUpperCase().replace(/[ ]/g, "_");
      reportModel.resetDateRangeChanged();
      const dateRangeChanged = this.prevDateOption !== undefined && this.prevDateOption !== this.dateOption;
      if (dateRangeChanged) {
        reportModel.resetOnDateRangeChange();
      }
      this.currentReportId = reportID;
      if (tempDimension) {
        this.dimensionData = tempDimensionInfo.key;
        silent = true;
      }
      if (doLoadDim2) {
        if (tempDim2Info) {
          dimensionName2 = tempDim2Info.dimension;
        } else if (dim2Info) {
          dimensionName2 = dim2Info.dimension;
        }
        dim2Name = dimensionName2.toUpperCase().replace(/[ ]/g, "_");
        if (tempDim2) {
          this.dim2Key = dim2Key = tempDim2Info.key;
          silent = true;
        }
        if (tempDim2 && tempDim2Info) {
          dim2 = tempDim2Info.dimension;
          this.dim2Key = dim2Key = tempDim2Info.key;
        } else if (dim2Info) {
          reportModel.setCollectionFetchParams(filterDimension.fetchOptions);
          dim2 = dim2Info.dimension;
          this.dim2Key = dim2Key = dim2Info.key;
        }
      }
      comm.reportID = reportID;
      comm.urlString = this.getUrlString(section);
      comm.resetDataItem("filterDimension", dateFetchOptions);
      comm.resetDataItem("collection", $.extend(true, {
        having: havingParam,
        order: filterMetric,
        page_limit: this.filterRange || reportModel.get("siteFilterRange"),
        page_offset: 0
      }, dateFetchOptions));
      comm.resetDataItem("dailyCollection", $.extend(true, {
        having: havingParam
      }, dateFetchOptions));
      this.dailyCollection.comparator = function (model) {
        return model.get("start_date");
      };
      if (!tempDimension) {
        reportModel.setCollectionFetchParams(siteDomain.fetchOptions);
        dim = dimensionInfo.dimension;
        dimKey = dimensionInfo.key;
      } else {
        dim = tempDimensionInfo.dimension;
        this.dim1Key = dimKey = tempDimensionInfo.key;
      }
      if (!(tempDim2 && tempDim2Info)) {
        dim2FilteredItems = reportModel.getFilteredInItems(doLoadDim2) || [];
        dim2FilteredOutCount = reportModel.getFilteredOutCount(doLoadDim2) || 0;
        if (dim2FilteredItems.length) {
          dim2FilteredItems = reportModel.getEscapedNames(dim2FilteredItems);
        }
        dim2UrlParams = dim2Key && dim2FilteredItems.length && dim2FilteredOutCount > 0 ? `${dim2Key}=${dim2FilteredItems.toString()}&` : "";
        if (focus === "all") {
          originalFilterIndex = siteDomain.fetchOptions.filter.lastIndexOf("campaign_id");
          originalFilter = siteDomain.fetchOptions.filter.substring(originalFilterIndex);
        } else {
          originalFilterIndex = siteDomain.fetchOptions.filter.lastIndexOf("strategy_channel");
          originalFilter = siteDomain.fetchOptions.filter.substring(originalFilterIndex);
        }
        siteDomain.fetchOptions.filter = dim2UrlParams + originalFilter;
        siteDomainDaily.fetchOptions.filter = dim2UrlParams + originalFilter;
      }
      filterDimension.fetchOptions.dimensions = dim2 === dim2Key ? dim2 : `${dim2},${dim2Key}`;
      siteDomain.fetchOptions.dimensions = dim === dimKey ? dim : `${dim},${dimKey}`;
      siteDomainDaily.fetchOptions.dimensions = dim === dimKey ? dim : `${dim},${dimKey}`;
      siteDomain.fetchOptions.metrics = requestMetrics;
      siteDomainDaily.fetchOptions.metrics = requestMetrics;
      this.abortAjaxCalls();
      timerValue = this.report.id;
      timer(GAMap.totalLoad, timerValue, "start");
      function loadFilterDimensionHandler() {
        timer(GAMap.filterDimensionsApiLoad + dim2Name, timerValue, "start");
        filterDimension.loaded = false;
        if (tempDimension) {
          models.tempDim1Coll = filterDimension;
        }
        filterDimension.fetch({
          onSuccess: function () {
            timer(GAMap.filterDimensionsApiLoad + dim2Name, timerValue, "stop");
            filterDimension.loaded = true;
            self.dimensionData = null;
            reportModel.setDimensionItems({
              applying: false,
              dim1Data: null,
              dim1FilteredData: null,
              dim2Data: filterDimension
            });
            filterDimensionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`filterDimension fetch failed: ${error}`);
          }
        });
      }
      function loadSiteDomainHandler() {
        timer(GAMap.siteDomainsApiLoad + dimName, timerValue, "start");
        siteDomain.loaded = false;
        siteDomain.fetch({
          silent: silent,
          onSuccess: function (collectionData) {
            timer(GAMap.siteDomainsApiLoad + dimName, timerValue, "stop");
            siteDomain.loaded = true;
            self.collectionData = collectionData;
            reportModel.collectionData = collectionData;
            reportModel.setReportInfo(`${section}-${focus}`, siteDomain.ajxResponseHeaders);
            siteDomainDeferred.resolve();
            if (self.reportModel.get("isCTD")) {
              siteDomainDailyDeferred.resolve();
            } else {
              loadDailySiteDataHandler();
            }
          },
          onError: function (error) {
            throw new Error(`siteDomain fetch failed: ${error}`);
          }
        });
      }
      function loadDailySiteDataHandler() {
        timer(GAMap.siteDomainsDailyApiLoad + dimName, timerValue, "start");
        siteDomainDaily.loaded = false;
        siteDomainDaily.fetch({
          silent: silent,
          onSuccess: function () {
            timer(GAMap.siteDomainsDailyApiLoad + dimName, timerValue, "stop");
            siteDomainDaily.loaded = true;
            reportModel.setCollectionDailyFilterParams(self.limitedSites);
            siteDomainDailyDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`siteDomainDaily fetch failed: ${error}`);
          }
        });
      }
      if (!tempDimension && (dim2Info || tempDim2Info)) {
        loadFilterDimensionHandler();
      } else {
        filterDimension.loaded = true;
        filterDimensionDeferred.resolve();
      }
      if (tempDim2 && tempDim2Info) {
        siteDomain.loaded = true;
        siteDomainDeferred.resolve();
      } else {
        reportModel.set({
          filteredInCount: 0,
          filteredInItems: null
        });
        loadSiteDomainHandler();
      }
      When.all(promises).then(function () {
        if (self.mainModel.leftTheSection(self.section)) {
          return;
        }
        timer(GAMap.totalLoad, timerValue, "stop");
        if (tempDimension) {
          self.dimensionData = null;
          reportModel.setDimensionItems({
            applying: false,
            dim1Data: filterDimension,
            dim1FilteredData: null,
            dim2Data: null
          });
        } else {
          if (!tempDim2) {
            reportModel.setColorData(self.collectionData);
            reportModel.setDimensionItems({
              applying: !tempDimension,
              dim1Data: siteDomain,
              dim1FilteredData: null,
              dim2Data: null
            });
            if (comm.dataGroup.dailyCollection.models.length && !reportModel.get("isCTD")) {
              reportModel.setTimeSeriesSlots(self.campaignFetchOptionsExtend, "site_transparency");
            }
          }
          T1.EventHub.publish("responseHeadersReady", reportModel.get("reportInfo").reportTypes[`${section}-${focus}`]);
        }
        self.toggleLoader(false);
      });
    },
    setGetDataOnce() {
      this.getDataOnce = _.once(this.getData);
    },
    updateData() {
      this.setGetDataOnce();
      this.getDataOnce();
    },
    needsDataLoad() {
      return this.unloaded || this.currentReportId !== this.report.id || this.prevFocus !== this.focus || this.prevDim1Key !== this.dim1Key || this.prevDim2Key !== this.dim2Key || this.prevFilterRange !== this.filterRange || this.prevFilterMetric !== this.filterMetric || this.prevFilterExcludeZeros !== this.filterExcludeZeros || this.prevDateOption !== this.dateOption;
    },
    resetData() {
      if (comm) {
        comm.clearDataGroup();
        comm = null;
      }
    },
    load() {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const dimensionInfo = reportModel.getDimensionInfo(false);
      if (mainModel.leftTheSection(this.section)) {
        return;
      }
      this.focus = reportModel.get("focus") || "all";
      this.dim1Key = this.dimensionData = dimensionInfo.key;
      this.chartShown = mainModel.get("chartShown") === "true";
      this.dateFetchOptions = this.getDateFetchOptions();
      mainModel.setTrackingSection(trackingSection);
      if (this.needsDataLoad()) {
        reportModel.resetColorData();
        reportModel.resetSiteLookup();
        this.setGetDataOnce();
      } else {
        reportModel.setCollectionFetchParams(this.collection.fetchOptions);
      }
      this.unloaded = false;
      this.layout.unload();
      this.initLayouts();
      this.render().then(() => {
        this.layout.load();
        this.$loader = this.$(".loader-container");
        this.getDataOnce();
        this.prevFocus = this.focus;
        this.prevDim1Key = this.dim1Key;
        this.prevDim2Key = this.dim2Key;
        this.prevFilterRange = this.filterRange;
        this.prevFilterMetric = this.filterMetric;
        this.prevFilterExcludeZeros = this.filterExcludeZeros;
        this.prevDateOption = this.dateOption;
      });
    },
    toggleLoader(show) {
      const animTime = 200;
      if (show) {
        this.$loader.fadeIn(animTime);
      } else {
        this.$loader.fadeOut(animTime);
      }
    },
    abortAjaxCalls() {
      if (comm) {
        comm.abortAjaxCalls(["filterDimension", "collection"]);
      }
    },
    unload() {
      this.abortAjaxCalls();
      this.siteListLayout.unload();
      this.layout.unload();
      this.resetData();
      this.unloaded = true;
      this.limitedSites = null;
      this.reportModel.resetSiteLookup();
    }
  });
});
