define('modules/reporting/segments/dataExport/createEdit/views/selectCampaigns',["jQuery", "Underscore", "T1", "T1View", "text!../templates/select-campaigns.html", "collections/campaigns"], function ($, _, T1, T1View, template, Campaigns) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {
      "changed #search-campaigns": "searchCampaigns",
      "changed #campaigns": "selectCampaigns"
    },
    eventhubEvents: {
      "createEdit.OverlapSelected": "showOverlap",
      "createEdit.PerformanceSelected": "showPerformance",
      clearPerformanceReport: "clearCampaign"
    },
    initialize: function initialize(model) {
      DataExportModel = model;
      model.setOrg();
    },
    dropdown: {
      load: function () {
        var dropdown = $("#campaigns", this.el)[0];
        dropdown.isLoading = true;
        dropdown.disabled = true;
      },
      done: function (entities) {
        var dropdown = $("#campaigns", this.el)[0];
        dropdown.isLoading = false;
        dropdown.disabled = false;
        dropdown.set("data", formatEntities(entities));
        function formatEntities(models) {
          var formattedArray = [];
          $.each(models, function (i, model) {
            formattedArray.push({
              name: model.name,
              value: model.id
            });
          });
          return formattedArray;
        }
      }
    },
    loadCampaigns: function () {
      var self = this;
      var collection = new Campaigns();
      collection.fetchOptions = {
        sort_by: "name",
        order_by: "ascending"
      };
      collection.setOrganization(DataExportModel.getOrg());
      collection.pageLimit = 100;
      self.dropdown.load();
      collection.fetch({
        success: function (collection, response) {
          var entities = response.entities;
          if (entities.count === "1") {
            var one_entity = entities.entity;
            entities.entity = [];
            entities.entity[0] = one_entity;
          }
          self.dropdown.done(entities.entity);
        }
      });
    },
    clearCampaign: function () {
      this.el.find("#campaigns").val(null);
      DataExportModel.clearPerformanceCampaign();
    },
    searchCampaigns: function (e) {
      var value = e.srcElement.value || "";
      var self = this;
      var collection = null;
      var search = function (term) {
        self.dropdown.load();
        collection = new Campaigns();
        collection.fetchOptions = {
          sort_by: "name",
          order_by: "ascending"
        };
        collection.pageLimit = 100;
        collection.search.set({
          params: [{
            term: term,
            searchField: "name|id"
          }]
        });
        collection.fetch({
          success: function (coll, response) {
            var results = [];
            if (response.entities.count === "1") {
              results[0] = response.entities.entity;
            } else {
              results = response.entities.entity;
            }
            if (results) {
              self.dropdown.done(results);
            } else {
              self.dropdown.done([{
                name: "No results match" + '"' + term + '"',
                value: null
              }]);
            }
          }
        });
      };
      if (value && value.length >= 3) {
        search(value, e);
      } else if (value && value.length < 3) {
        return;
      } else {
        self.loadCampaigns(e);
      }
    },
    selectCampaigns: function () {
      var campId = document.querySelector("#campaigns").value;
      DataExportModel.updatePerformanceCampaign(campId);
    },
    load: function load() {
      var self = this;
      var $el = this.el;
      $el.css("display", "none");
      this.render().then(function render() {
        $el.css("display", "none");
      });
      self.loadCampaigns();
    },
    showOverlap: function () {
      var $el = this.el;
      $el.css("display", "none");
    },
    showPerformance: function () {
      var $el = this.el;
      $el.css("display", "block");
      document.querySelector("#campaigns").value = null;
      document.querySelector("#campaigns").placeholder = "Select Campaign";
    },
    unload: function unload() {
      DataExportModel.updatePerformanceCampaign(null);
    },
    serialize: function serialize() {
      return {};
    }
  });
});
