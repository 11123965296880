define('modules/T1App/myApps/views/myApps',["jQuery", "Underscore", "T1", "T1View", "text!modules/T1App/myApps/templates/myApps.html", "../models/myApps", "collections/t1AppDashboard", "T1Layout", "utils/AdminUtils"], function ($, _, T1, T1View, template, StateModel, T1AppCollection, T1Layout, Utils) {
  "use strict";

  var DATE_FORMAT = "yyyy-MM-dd";
  return T1View.extend({
    fetchOptions: {
      endDate: null,
      precision: 2,
      startDate: null,
      urlString: ""
    },
    eventhubEvents: {
      "complete:layoutLoad": function () {
        this.layoutsLoaded();
      }
    },
    dataEvents: {
      stateModel: {
        "change:chartGridData": function () {
          T1.EventHub.publish("myApps.graphDataChange");
          T1.EventHub.publish("myApps.gridDataChange");
        },
        "change:dates": function (dates) {
          var startDate = dates.startDate;
          var endDate = dates.endDate;
          this.startDate = startDate.toString(DATE_FORMAT);
          this.startDate = endDate.toString(DATE_FORMAT);
        },
        "change:start_end": function () {
          this.getData();
        }
      }
    },
    template: template,
    initialize: function () {
      this.collection = new T1AppCollection();
      this.stateModel = new StateModel();
      Utils.updateBreadCrumb("");
      this.initLayout();
    },
    load: function () {
      this.render().then(() => {
        this.layoutsLoaded = _.after(2, () => {
          this.getData();
        });
        this.layout.load();
      });
    },
    getData: function () {
      var self = this;
      this.updateFetchOptionsDate();
      this.toggleLoader(true);
      this.collection.fetch({
        success: function (data) {
          self.stateModel.setData(data);
          self.toggleLoader(false);
        },
        startDate: this.fetchOptions.startDate,
        endDate: this.fetchOptions.endDate
      });
    },
    toggleLoader: function (state) {
      T1.EventHub.publish("myApps.toggleLoader", state);
    },
    updateFetchOptionsDate: function () {
      var fetchOptions = this.fetchOptions;
      var dates = this.stateModel.getStartAndEndDate();
      fetchOptions.startDate = dates.startDate.toString(DATE_FORMAT);
      fetchOptions.endDate = dates.endDate.toString(DATE_FORMAT);
    },
    initLayout: function () {
      var self = this;
      var options = {
        model: this.stateModel
      };
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".t1-apps-container");
        },
        template: '<div class="date-picker-holder"></div>' + '<div class="chart-holder"></div>' + '<div class="grid-header-section"></div>' + '<div class="grid-holder"></div>',
        layout: {
          ".date-picker-holder": [{
            module: "T1App/myApps",
            viewType: "chart_date_picker",
            options: options
          }],
          ".chart-holder": [{
            module: "T1App/myApps",
            viewType: "chart",
            options: options
          }],
          ".grid-holder": [{
            module: "T1App/myApps",
            viewType: "grid",
            options: options
          }],
          ".grid-header-section": [{
            module: "T1App/myApps",
            viewType: "grid_header_section",
            options: options
          }]
        }
      });
    }
  });
});
