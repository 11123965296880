define('modules/audiences/pixels/views/pixels',["jQuery", "T1", "T1Layout", "T1View", "When", "collections/organizations", "collections/advertisers", "collections/dataproviders", "models/userPreferences", "text!modules/audiences/pixels/templates/pixels.html", "text!modules/audiences/pixels/templates/layout.html", "text!modules/audiences/pixels/templates/select_type.html", "text!modules/audiences/pixels/templates/select_status.html", "text!modules/audiences/pixels/templates/select_action.html", "text!modules/audiences/pixels/templates/pulldown_add_pixel.html", "jQPlugins/jquery.cookie"], function ($, T1, T1Layout, T1View, When, Organizations, Advertisers, DataProviders, UserPreferences, template, layout, selectType, selectStatus, selectAction, pulldownAddPixel) {
  return T1View.extend({
    template: template,
    orgID: NaN,
    partials: {
      select_type: selectType,
      select_status: selectStatus,
      select_action: selectAction,
      pulldown_add_pixel: pulldownAddPixel
    },
    pixelType: "event",
    pixelStatus: "1",
    searchTerm: "",
    events: {
      "focus .w-Filters input.text": "showSearchTooltip"
    },
    eventhubEvents: {
      "PixelselectboxType:change": function (pixel_type) {
        var self = this;
        if (pixel_type === "data") {
          if (!this.searchLayoutDataProvider) {
            this.getDataProviders().then(function (providers) {
              self.initSearchLayoutDataProvider(providers);
              self.searchLayoutDataProvider.load();
              this.getAdvertisers().then(function (advertisers) {
                self.initSearchLayoutAdvertisers(advertisers);
              });
            });
          } else {
            this.searchLayoutDataProvider.load();
          }
        } else {
          if (!this.searchLayoutAdvertisers) {
            this.getAdvertisers().then(function (advertisers) {
              self.initSearchLayoutAdvertisers(advertisers);
              self.searchLayoutAdvertisers.load();
              this.getDataProviders().then(function (providers) {
                self.initSearchLayoutDataProvider(providers);
              });
            });
          } else {
            this.searchLayoutAdvertisers.load();
          }
        }
      },
      "organization:select": function (data) {
        if (!data.id || data.id === "") {
          throw new Error("Organization id must be provided");
        }
        var self = this;
        this.getAdvertisers().then(function (advertisers) {
          self.initialize({
            selectOptions: advertisers,
            pixelType: "event"
          });
          self.searchLayoutAdvertisers.refresh();
        });
      },
      "searchTerm:set": function (data) {
        this.searchTerm = data;
      }
    },
    initialize: function (args) {
      var self = this;
      var isReturningPixel = $.cookie("isReturningPixel");
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "audiences/pixels"], ["view", "pixels"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["pixelType", "pixelStatus", "searchTerm"]);
      this.pixelType = this.preferences.get("pixelType") || "event";
      this.pixelStatus = this.preferences.get("pixelStatus") || "1";
      if (isReturningPixel === "true") {
        $.removeCookie("isReturningPixel");
        this.searchTerm = "";
        this.preferences.save();
      } else {
        this.searchTerm = this.preferences.get("searchTerm") || "";
      }
      this.initLayout();
      if (this.pixelType === "event" || args.pixelType === "event") {
        this.pixelType = "event";
        this.getAdvertisers().then(function (advertisers) {
          self.initSearchLayoutAdvertisers(advertisers);
          self.searchLayoutAdvertisers.load();
        });
      } else {
        this.getDataProviders().then(function (providers) {
          self.initSearchLayoutDataProvider(providers);
          self.searchLayoutDataProvider.load();
        });
      }
      this.ajax_loader = T1.Loader.create({
        target: ".main-column",
        text: "Loading ...",
        contentClass: "search-loader loader-overrides",
        spinnerConfig: {
          lines: 11,
          length: 1,
          radius: 3,
          left: 1,
          top: 0
        }
      });
      $(window).one("beforeunload", function () {
        $.cookie("isReturningPixel", true);
        return null;
      });
      T1.EventHub.publish("updateNavPath");
    },
    initLayout: function () {
      var self = this;
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-content",
        template: layout,
        layout: {
          ".bulk-email-modal": [{
            module: "audiences/pixels",
            viewType: "bulkEmailModal"
          }],
          ".bulk-export-modal": [{
            module: "audiences/pixels",
            viewType: "csvExportModal"
          }],
          ".w-grid": [{
            module: "audiences/pixels",
            viewType: "grid",
            options: {
              pixel_type: self.pixelType,
              pixel_status: self.pixelStatus,
              searchTerm: self.searchTerm
            }
          }]
        }
      });
    },
    initSearchLayoutAdvertisers: function (advertisersList) {
      var self = this;
      var searchTerm = this.searchTerm;
      var publish = T1.EventHub.publish;
      var filterAndClearCallback = function (term, flags) {
        if (flags && flags.id === "all") {
          self.clearTheFilter();
        }
        publish("PixelSearchBox", {
          term: term || "",
          flags: flags
        });
      };
      if (searchTerm) {
        searchTerm = decodeURIComponent(searchTerm);
      }
      self.searchLayoutAdvertisers = new T1Layout({
        el: function () {
          return $(self.el).find(".filter-search");
        },
        template: '<div class="search"></div>',
        layout: {
          ".search ": [{
            module: "shared",
            viewType: "filter",
            options: {
              defaultValue: searchTerm,
              filterClass: "advertiser-select",
              selectOptions: advertisersList,
              eventName: "changePixelSearchSelect",
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback,
              customPlaceholderText: "Search",
              check_length: false,
              autoCompleteConfig: {
                autoComplete: true,
                searchField: "name",
                onSearchInputCleared: $.proxy(self.clearTheFilter, this),
                onSearchRequest: function (searchOptions) {
                  self.searchOptions = searchOptions;
                  searchOptions = $.extend({
                    fetchRequired: true
                  }, searchOptions);
                  self.advertiserCollection.search.set(searchOptions);
                }
              }
            }
          }]
        }
      });
    },
    clearTheFilter: function () {
      var searchOptions = $.extend({
        fetchRequired: true
      }, this.searchOptions);
      this.advertiserCollection.search.clear();
      this.advertiserCollection.fetch(searchOptions);
    },
    initSearchLayoutDataProvider: function (dataProvidersList) {
      var self = this;
      var publish = T1.EventHub.publish;
      var filterAndClearCallback = function (term, flags) {
        publish("PixelSearchBox", {
          term: term || "",
          flags: flags
        });
      };
      self.searchLayoutDataProvider = new T1Layout({
        el: function () {
          return $(self.el).find(".filter-search");
        },
        template: '<div class="search"></div>',
        layout: {
          ".search": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterClass: "provider-select",
              selectOptions: dataProvidersList,
              eventName: "changePixelSearchSelect",
              filterCallback: filterAndClearCallback,
              customPlaceholderText: "Search",
              clearFilterCallback: filterAndClearCallback,
              check_length: false,
              autoCompleteConfig: {
                autoComplete: true,
                searchField: "name",
                onSearchRequest: function (searchOptions) {
                  searchOptions = $.extend({
                    fetchRequired: true
                  }, searchOptions);
                  self.dataProviderCollection.search.set(searchOptions);
                }
              }
            }
          }]
        }
      });
    },
    getAdvertisers: function () {
      var self = this;
      var deferred = When.defer();
      this.advertiserCollection = new Advertisers(null, {
        pageLimit: 50
      });
      Organizations.getOrganizations().getSelected().then(function (orgId) {
        self.advertiserCollection.urlFilter.set({
          entity: "organization",
          id: orgId,
          fetchRequired: false
        });
        function getAdvertiserList() {
          var options = self.advertiserCollection.map(function (adver) {
            return {
              text: adver.get("name"),
              value: adver.id
            };
          });
          options.unshift({
            text: "All Advertisers",
            value: "all"
          });
          return options;
        }
        self.advertiserCollection.fetch({
          success: function () {
            deferred.resolve(getAdvertiserList());
          }
        });
      });
      return deferred.promise;
    },
    getDataProviders: function () {
      var self = this;
      var deferred = When.defer();
      this.dataProviderCollection = new DataProviders(null, {
        pageLimit: 50
      });
      function getDataProviderList() {
        var options = self.dataProviderCollection.map(function (provider) {
          return {
            text: provider.get("name"),
            value: provider.id
          };
        });
        options.unshift({
          text: "All Data Providers",
          value: "all"
        });
        return options;
      }
      self.dataProviderCollection.fetch({
        success: function () {
          deferred.resolve(getDataProviderList());
        }
      });
      return deferred.promise;
    },
    activeSelectboxes: function () {
      var self = this;
      var $el = $(self.el);
      var selectType = $el.find(".select-type");
      var selectStatus = $el.find(".select-status");
      var selectAction = $el.find(".select-action");
      var pulldownAdd = $el.find(".pulldown_add_pixel");
      var publish = T1.EventHub.publish;
      selectType.val(self.pixelType);
      publish("PixelselectboxType:change", self.pixelType);
      selectType.on("changed", function () {
        if (self.pixelType !== this.value) {
          publish("PixelselectboxType:change", this.value);
          self.pixelType = this.value;
          self.preferences.save();
        }
      });
      selectStatus.val(self.pixelStatus);
      selectStatus.on("changed", function () {
        if (self.pixelStatus !== this.value) {
          publish("PixelselectboxStatus:change", this.value);
          self.pixelStatus = this.value;
          self.preferences.save();
        }
      });
      selectAction.on("selected", function (e) {
        var value = e.originalEvent.detail.value;
        if (value) {
          publish("PixelselectboxAction:change", value);
        }
        this.selectedIndex = false;
      });
      pulldownAdd.on("selected", function (e) {
        var type = e.originalEvent.detail.value;
        if (type) {
          T1.Location.set(`audiences/pixels/${type}/create`);
        }
      });
    },
    showSearchTooltip: function (e) {
      var $target = $(e.currentTarget);
      var $parent = $target.parent();
      var className = "w-DisplaySearchTooltip";
      var msg = "Type pixel name, ID number";
      if (this.pixelType === "event") {
        msg = `${msg} or Advertiser`;
      } else {
        msg = `${msg} or Data Provider`;
      }
      $(`.${className}`).remove();
      T1.Tooltip($target, {
        trigger: "manual",
        tooltipEle: $parent,
        getExternalTip: false,
        gravity: "s",
        className: className,
        offset: 10
      });
      $parent.attr("title", msg).tipsy("show");
    },
    load: function () {
      var $el = $(this.el);
      var self = this;
      var advDDLid;
      this.render().then(function () {
        setTimeout(function () {
          advDDLid = $el.find(".advertiser-select").attr("id");
          $el.find(`#${advDDLid}_chzn`).click(function () {
            if (self.advertiserCollection.length <= 0) {
              self.clearTheFilter();
            }
          });
        }, 1e3);
        self.activeSelectboxes();
        self.layout.load();
        if (self.searchLayoutAdvertisers) {
          self.searchLayoutAdvertisers.load();
        }
      });
    },
    unload: function () {
      if (this.searchLayoutAdvertisers) {
        this.searchLayoutAdvertisers.destroy();
      }
      if (this.layout) {
        this.layout.destroy();
      }
      if (this.searchLayoutDataProvider) {
        this.searchLayoutDataProvider.destroy();
      }
      this.preferences.save();
    },
    serialize: function () {
      return {
        pixelType: this.pixelType,
        pixelStatus: this.pixelStatus
      };
    }
  });
});
