define('modules/admin/myOrgs/views/grid',['require','Underscore','jQuery','utils/AdminUtils','models/advertiser','collections/advertisers','collections/agencies','models/agency','utils/ContractUtils','text!../templates/grid_item.html','T1Spinner','T1','T1Collection','T1GridView','T1Layout','T1Menu','T1View','models/sessionUser','jslibs/xdate/xdate','InflectionJS'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const Advertiser = require("models/advertiser");
  const Advertisers = require("collections/advertisers");
  const Agencies = require("collections/agencies");
  const Agency = require("models/agency");
  const ContractUtils = require("utils/ContractUtils");
  const grid_item = require("text!../templates/grid_item.html");
  const Spinner = require("T1Spinner");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  const T1GridView = require("T1GridView");
  const T1Layout = require("T1Layout");
  const T1Menu = require("T1Menu");
  const T1View = require("T1View");
  const User = require("models/sessionUser");
  const xdate = require("jslibs/xdate/xdate");
  const InflectionJS = require("InflectionJS");
  var checkEntityPermission = AdminUtils.checkEntityPermission;
  var explicitlyEnabledAgencyFound = AdminUtils.explicitlyEnabledAgencyFound;
  var user = User.getUser().toJSON();
  var isFireFox = navigator.userAgent.indexOf("Firefox") >= 0;
  var OrgsGridView = T1View.extend({
    truncateConfig: {
      ".truncate": {
        lines: 2,
        stripHTML: true
      }
    },
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        action: "append",
        contentClass: "search-loader loader-overrides admin-infinite",
        target: ".w-DataGrid",
        text: "Fetching Organizations"
      },
      editData: function () {
        var self = this;
        var newCol = new T1Collection(this.collection.models.slice(this.initCount));
        if (newCol && newCol.models) {
          $.each(newCol.models, function (i, item) {
            self.addToEntityGlobalCollection(item.get("type"), item);
          });
        }
        return this.serialize(newCol);
      }
    },
    partials: {
      listItem: grid_item
    },
    headers: [{
      name: "entity",
      label: "Organization/Agency/Advertiser"
    }, {
      name: "id",
      label: "ID"
    }, {
      name: "shared-pixel-targeting",
      label: "Shared Pixel Targeting"
    }, {
      name: "details",
      label: "Details"
    }],
    entityMap: {
      adv: "advertisers",
      ag: "agencies",
      org: "organizations"
    },
    entityMapReverse: {
      advertiser: "adv",
      agency: "ag",
      organization: "org"
    },
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 9,
      radius: 2,
      rotate: 0,
      speed: 1.4,
      trail: 14,
      width: 2
    },
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Edit Organization",
          url: "#admin/organization/",
          visible: false
        }, {
          label: "View Organization",
          url: "#admin/organization/",
          visible: false
        }, {
          label: "Add Agency",
          handler: "addEntity",
          visible: false
        }, {
          label: "Edit Agency",
          url: "#admin/agency/",
          visible: false
        }, {
          label: "Add Advertiser",
          handler: "addEntity",
          visible: false
        }, {
          label: "Edit Advertiser",
          url: "#admin/advertiser/",
          visible: false
        }, {
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: false
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }]
      }]
    },
    menuIndexRef: {
      editOrg: 0,
      viewOrg: 1,
      addAg: 2,
      editAg: 3,
      addAdv: 4,
      editAdv: 5,
      Deactivate: 6,
      Activate: 7,
      toggle: -1
    },
    menuPermissions: {
      INTERNAL: {
        GLOBAL: {
          ADMIN: {
            org: "editOrg,addAg,toggle",
            ag: "editAg,addAdv,toggle",
            adv: "editAdv,toggle"
          },
          MANAGER: {
            org: "addAg",
            ag: "editAg,addAdv,toggle",
            adv: "editAdv,toggle"
          },
          MANAGER_viewOrg: {
            org: "viewOrg,addAg",
            ag: "editAg,addAdv,toggle",
            adv: "editAdv,toggle"
          },
          REPORTER: {
            org: "",
            ag: "",
            adv: ""
          }
        },
        SELECT: {
          ADMIN: {
            org: "editOrg,viewOrg,addAg,toggle",
            ag: "editAg,addAdv,toggle",
            adv: "editAdv,toggle"
          },
          MANAGER: {
            org: "addAg",
            ag: "editAg,addAdv,toggle",
            adv: "editAdv,toggle"
          },
          MANAGER_viewOrg: {
            org: "viewOrg,addAg",
            ag: "editAg,addAdv,toggle",
            adv: "editAdv,toggle"
          },
          REPORTER: {
            org: "",
            ag: "",
            adv: ""
          }
        }
      },
      AGENCY: {
        ADMIN: {
          org: "viewOrg,addAg",
          ag: "editAg,addAdv,toggle",
          adv: "editAdv,toggle"
        },
        MANAGER: {
          org: "addAg",
          ag: "editAg,addAdv,toggle",
          adv: "editAdv,toggle"
        },
        MANAGER_viewOrg: {
          org: "viewOrg,addAg",
          ag: "editAg,addAdv,toggle",
          adv: "editAdv,toggle"
        },
        REPORTER: {
          org: "",
          ag: "",
          adv: ""
        }
      }
    },
    events: {
      "click .admin-edit, .admin-preview": "viewOrEditOrg",
      "click .item": "toggle",
      "click .settings-icon": "showOrApplySettingsMenu",
      "contextmenu .admin-edit": "applyEditHover",
      "contextmenu .config": "stopEvent",
      "mousemove .item-wrapper": "removeEditHover"
    },
    dataEvents: {
      collection: {
        reset: function () {
          this.refactorCollection();
          this.load();
        }
      }
    },
    eventhubEvents: {
      permissionUpdated: function () {
        user = User.getUser().toJSON();
        this.initDataRequested = false;
        this.canLoad = true;
        this.load();
      },
      "organizationGrid:resetCollection": function (args) {
        if (args.collection) {
          this.setCollection(args.collection);
        }
      },
      "organizationGrid:reload": function (args) {
        this.reset(this.collection);
        if (args.collection) {
          this.setCollection(args.collection);
        }
        this.setSearchLoader();
      }
    },
    entityGlobalCollection: {},
    entityChildrenCollections: {},
    initialize(args) {
      this.initMenuConfig();
      this.initDataRequested = false;
      this.initLoaderShown = false;
      this.myOrgs = args.myOrgs;
      this.setCollection(args.collection);
      this.collection.refreshOnNextFetch();
      this.bindViewEvents();
      if (user.scope === "GLOBAL" && user.role === "ADMIN") {
        this.canLoad = true;
      } else {
        this.canLoad = false;
        User.updatePermissions(user.id, "permissionUpdated");
      }
    },
    setCollection(inColl) {
      this.unbindDataEvents();
      this.collection = inColl;
      this.infiniteLoader = this.loader({
        action: "append",
        contentClass: "search-loader loader-overrides admin-infinite",
        target: ".w-DataGrid",
        text: `Fetching ${this.collection.entityType.pluralize().capitalize()}`
      });
      this.delegateDataEvents();
    },
    refactorCollection(inputColl = this.collection) {
      inputColl.each(item => {
        this.addToEntityGlobalCollection(inputColl.entityType, item);
      });
    },
    addToEntityGlobalCollection(entityType, entity) {
      let modelRef, prefix;
      switch (entityType.toUpperCase()) {
        case "AGENCY":
          prefix = "ag";
          modelRef = Agency;
          break;
        case "ADVERTISER":
          prefix = "adv";
          modelRef = Advertiser;
      }
      if (prefix) {
        this.entityGlobalCollection[`${prefix}_${entity.id}`] = new modelRef(entity);
      }
    },
    load() {
      const entityTypeLabels = {
        ORGANIZATION: "Organization/Agency/Advertiser",
        AGENCY: "Agency/Advertiser",
        ADVERTISER: "Advertiser"
      };
      if (!this.canLoad) {
        return;
      }
      if (!this.initDataRequested) {
        this.resetCollection();
      }
      if (this.collection) {
        this.headers[0].label = entityTypeLabels[this.collection.entityType] || entityTypeLabels.ORGANIZATION;
      }
      this.render().then(() => {
        if (!this.initDataRequested) {
          this.initDataRequested = true;
          this.setSearchLoader();
          this.collection.filterBy();
        }
      });
      this.loaded = true;
    },
    unload() {
      var coll = this.collection;
      if (this.paginationLayout && this.paginationLayout.unload) {
        this.paginationLayout.unload();
      }
      if (this.loaded) {
        if (this.inSearch || this.activationUpdated || isFireFox) {
          this.inSearch = false;
          this.activationUpdated = false;
          this.initDataRequested = false;
          this.resetCollection();
          coll.refreshOnNextFetch();
        } else {
          this.resetCollection(true);
        }
        this.reset(coll);
        this.loaded = false;
      }
    },
    resetCollection(leaveFirstPageData) {
      var coll = this.collection;
      if (leaveFirstPageData) {
        const models = coll.models;
        coll.reset(models.slice(0, Math.min(models.length, coll.pageLimit)), {
          silent: true
        });
      } else {
        coll.reset(undefined, {
          silent: true
        });
        coll.loaded = false;
      }
    },
    reset(coll) {
      if (coll && !coll.loaded && coll.currentFetch) {
        coll.currentFetch.abort();
        if (coll.infiniteScrolling) {
          this.isFetching = coll.isFetching = false;
        } else {
          this.initDataRequested = false;
        }
      }
      coll.infiniteScrolling = false;
      if (this.renderQueue) {
        this.renderQueue.flush();
      }
    },
    initMenuConfig() {
      var self = this;
      var permissions;
      var userType = user.type;
      var userScope = user.scope;
      var userRole = user.role;
      var menuPermissions = this.menuPermissions;
      var menuIndexRef = this.menuIndexRef;
      if (userRole === "MANAGER" && user.view_organizations === "1") {
        userRole = "MANAGER_viewOrg";
      }
      function parsePermissions() {
        var orgActions = permissions.org.split(",");
        var agActions = permissions.ag.split(",");
        var advActions = permissions.adv.split(",");
        var menuItems = [];
        for (let i = 0; i < orgActions.length; i++) {
          menuItems.push(menuIndexRef[orgActions[i]]);
        }
        self.orgMenuItems = menuItems.concat();
        menuItems = [];
        for (let i = 0; i < agActions.length; i++) {
          menuItems.push(menuIndexRef[agActions[i]]);
        }
        self.agMenuItems = menuItems.concat();
        menuItems = [];
        for (let i = 0; i < advActions.length; i++) {
          menuItems.push(menuIndexRef[advActions[i]]);
        }
        self.advMenuItems = menuItems.concat();
      }
      if (userType === "INTERNAL") {
        permissions = menuPermissions[userType][userScope][userRole];
      } else {
        permissions = menuPermissions[userType][userRole];
      }
      parsePermissions();
    },
    itemContentRendered($item) {
      var self = this;
      try {
        this.applyTruncate($item);
        const $settingsIcon = $item.find(".settings-icon");
        if ($settingsIcon.length > 1) {
          $.each($settingsIcon, function (index, icon) {
            self.initSettingsMenu($(icon));
          });
        } else {
          this.initSettingsMenu($settingsIcon);
        }
        $settingsIcon.removeClass("disabled");
      } catch (e) {}
    },
    checkEditPerms(model, entityType) {
      var typeMenuItems = this[`${entityType}MenuItems`];
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var id = model.id;
      var menuIndexRef = this.menuIndexRef;
      const {
        type: userType,
        scope: userScope,
        role: userRole
      } = user;
      var isRestrictedAdmin = userType === "INTERNAL" && userScope === "SELECT" && userRole === "ADMIN";
      var index;
      for (let i = 0; i < typeMenuItems.length; i++) {
        index = typeMenuItems[i];
        if (index > -1) {
          menuItems[index].visible = true;
        }
      }
      if (!checkEntityPermission(user, "organization", id, ["w", "r"])) {
        menuItems[menuIndexRef.editOrg].visible = false;
      }
      if (isRestrictedAdmin) {
        menuItems[menuIndexRef.viewOrg].visible = !menuItems[menuIndexRef.editOrg].visible;
      }
      return menuItems;
    },
    initSettingsMenu(eventOrTarget) {
      var hasToggle;
      var $menuTarget = Reflect.has(eventOrTarget, "currentTarget") ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var type = $menuTarget.data("type");
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var typeMenuItems = this[`${type}MenuItems`];
      var menuIndexRef = this.menuIndexRef;
      var hideToggleOption = false;
      var userType = user.type;
      var userScope = user.scope;
      var userRole = user.role;
      var isRestrictedAdmin = userType === "INTERNAL" && userScope === "SELECT" && userRole === "ADMIN";
      function updateSettingsMenuConfig() {
        var $container, parentActive;
        if (hasToggle && !hideToggleOption) {
          if (type !== "org") {
            $container = $menuTarget.closest(".children");
            parentActive = !$container.hasClass("inactive");
          }
          if (type === "org" || type === "ag" && parentActive || type === "adv" && !$container.parent().closest(".children").hasClass("inactive") && parentActive) {
            const isActive = !$menuTarget.closest(".item").hasClass("inactive");
            menuItems[menuIndexRef.Activate].visible = !isActive;
            menuItems[menuIndexRef.Deactivate].visible = isActive;
          } else {
            menuItems[menuIndexRef.Activate].visible = false;
            menuItems[menuIndexRef.Deactivate].visible = false;
          }
        }
      }
      if ($menuTarget) {
        const id = $menuTarget.data("id");
        const len = typeMenuItems.length;
        for (let i = 0; i < len; i++) {
          const index = typeMenuItems[i];
          if (index > -1) {
            if (menuItems[index].label.indexOf("Add") === -1) {
              menuItems[index].url += id;
            }
            menuItems[index].visible = true;
          } else {
            hasToggle = true;
          }
        }
        if (type === "org") {
          if (!checkEntityPermission(user, "organization", id, ["w", "r"])) {
            menuItems[menuIndexRef.editOrg].visible = false;
            menuItems[menuIndexRef.Activate].visible = false;
            menuItems[menuIndexRef.Deactivate].visible = false;
            hideToggleOption = true;
          }
          if (isRestrictedAdmin) {
            menuItems[menuIndexRef.viewOrg].visible = !menuItems[menuIndexRef.editOrg].visible;
          }
          if (!explicitlyEnabledAgencyFound(user, id)) {
            menuItems[menuIndexRef.addAg].visible = false;
          }
        } else if (type === "ag") {
          const orgId = $menuTarget.data("parent-id");
          if (!explicitlyEnabledAgencyFound(user, orgId)) {
            menuItems[menuIndexRef.editAg].visible = false;
            menuItems[menuIndexRef.Activate].visible = false;
            menuItems[menuIndexRef.Deactivate].visible = false;
            hideToggleOption = true;
          }
        }
        menuConfig.updateSettingsMenuConfig = updateSettingsMenuConfig;
        menuConfig.dataID = `data-id=${id} data-type=${type}`;
        menuConfig.menuTriggerEleSelector = `div[data-uid="${type}${id}"] .settings-icon`;
      }
      T1Menu(menuConfig, this);
    },
    addEntity(evt) {
      var $currentTarget = $(evt.currentTarget);
      var $menuCol = $currentTarget.closest("dl");
      var $menuLabel = $currentTarget.find(".menuLabel");
      var parentId = $menuCol.data("id");
      var type = $menuLabel.text().split(" ")[1].toLowerCase();
      var viewOpts = {};
      evt.preventDefault();
      evt.stopPropagation();
      if (parentId) {
        viewOpts = {
          parent: parentId
        };
      }
      T1.Location.set(`#admin/${type}`, {
        viewOptions: viewOpts
      });
    },
    showOrApplySettingsMenu(eventOrTarget) {
      var $menuTarget = eventOrTarget.currentTarget ? $(eventOrTarget.currentTarget) : eventOrTarget;
      if (eventOrTarget.currentTarget) {
        eventOrTarget.stopPropagation();
        if (!$menuTarget.hasClass("disabled")) {
          $menuTarget.trigger("click");
        }
      }
    },
    deactivateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(false, evt);
    },
    activateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(true, evt);
    },
    activationHandler(toActivate, evt) {
      var loader, model;
      var $currentTarget = $(evt.currentTarget);
      var $menuCol = $currentTarget.closest("dl");
      var id = $menuCol.data("id");
      var type = $menuCol.data("type");
      var status = toActivate === true ? "1" : "0";
      function stopLoader() {
        loader.stop();
      }
      if (id) {
        switch (type) {
          case "org":
            model = this.collection.get(id);
            break;
          case "ag":
          case "adv":
            model = this.entityGlobalCollection[`${type}_${id}`];
            break;
        }
        const targetDiv = `div[id="id${id}"]`;
        loader = T1.Loader.create({
          contentClass: "inline-edit-loader",
          target: targetDiv,
          spinnerConfig: {
            left: 15,
            length: 4,
            lines: 10,
            radius: 3,
            top: 4
          }
        });
        loader.start();
        if (model.get("terminated") === "1") {
          T1.EventHub.publish("terminatedOrgModalShow");
          stopLoader();
        } else {
          model.save({
            status: status
          }, {
            success: () => {
              stopLoader();
              this.updateStatus(model);
            },
            conflict: stopLoader
          });
          if (this.myOrgs.isActive === "true") {
            this.activationUpdated = true;
          }
        }
      }
    },
    updateStatus(model) {
      var id = model.get("id");
      var status = model.get("status");
      var typeAbbr = this.entityMapReverse[model.get("type")];
      var $item = this.$(`div[data-uid="${typeAbbr}${id}"]`);
      var $children = $item.siblings();
      var $settingsIcon = $item.find(".settings-icon");
      if (status === "0") {
        $item.addClass("inactive");
        $children.addClass("inactive");
      } else {
        $item.removeClass("inactive");
        $children.removeClass("inactive");
      }
      this.showOrApplySettingsMenu($settingsIcon);
    },
    addChildren(args) {
      var self = this;
      var collection = args.collection || this.collection;
      var entityId = args.entityId || "glob";
      var entityType = args.entityType || "org";
      var container = args.container || this.$(`.children-${entityType}${entityId}`);
      var serializedData = this.serialize(collection);
      if (collection.models) {
        $.each(collection.models, function (k, item) {
          self.addToEntityGlobalCollection(collection.entityType, item);
        });
      }
      if (serializedData.list.length === 0) {
        serializedData.list.push({
          hasChildren: false,
          entity: entityType === "org" ? "No agencies exist" : "No advertisers exist",
          is_active: true,
          is_null_item: true
        });
      }
      this.renderPartialTemplate({
        action: "html",
        data: serializedData,
        targetEl: container,
        template: this.partials.gridSubItem,
        useTemplateHtml: false
      });
      setTimeout(() => {
        this.itemContentRendered(container);
      }, 0);
    },
    getEntityChildrenCollection(entityType, entityId) {
      var collection;
      var parent = entityType + entityId;
      if (this.entityChildrenCollections[parent]) {
        return this.entityChildrenCollections[parent];
      }
      switch (entityType) {
        case "org":
          collection = new Agencies();
          collection.urlFilter.set({
            entity: "organization",
            id: entityId
          });
          collection.entityType = "agency";
          break;
        case "ag":
          collection = new Advertisers();
          collection.urlFilter.set({
            entity: "agency",
            id: entityId
          });
          collection.entityType = "advertiser";
          break;
        default:
          return {};
      }
      collection.parent = parent;
      this.entityChildrenCollections[parent] = collection;
      return collection;
    },
    toggle(evt) {
      var self = this;
      var item = $(evt.currentTarget);
      var arrow = item.find(".arrow");
      var iconSpinner = arrow.find(".icon-spinner");
      var entityId = item.attr("data-id");
      var entityType = item.attr("data-type");
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var paginationContainer = childrenContainer.next();
      var collection, isActive;
      function getSearchConfig(field) {
        var searchConfig = self.collection.currentSearchConfig;
        var fieldIndex = -1;
        $.each(searchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
          }
        });
        if (fieldIndex !== -1) {
          return searchConfig[fieldIndex];
        }
        return null;
      }
      if (!arrow.length || arrow.hasClass("hidden") || item.data("fetchingData")) {
        return;
      }
      this.preventDefault(evt);
      collection = this.getEntityChildrenCollection(entityType, entityId);
      collection.currentSearchConfig = [];
      if (arrow.hasClass("expanded")) {
        item.data("fetchingData", false);
        iconSpinner.spin(false);
        arrow.removeClass("expanded loading");
        childrenContainer.hide();
        paginationContainer.hide();
        this.updateScrollBar();
      } else {
        if (childrenContainer.children && childrenContainer.children().length > 0) {
          arrow.addClass("expanded");
          childrenContainer.show();
          this.updateScrollBar();
          if (collection.count > collection.pageLimit) {
            paginationContainer.show();
          }
        } else {
          arrow.addClass("loading expanded hide");
          item.data("fetchingData", true);
          Spinner.apply(iconSpinner, self.spinnerOpts);
          isActive = getSearchConfig("status");
          isActive = isActive === null ? "" : isActive.term;
          collection.canCache = false;
          collection.isPaginated = true;
          collection.pageLimit = 100;
          collection.fetchOptions = {
            full: "*",
            sort_by: "-updated_on"
          };
          collection.filterBy("status", isActive, function (data) {
            var model;
            var modelEntity = entityType === "org" ? "ag" : "adv";
            var models = data.models;
            var len = models.length;
            for (let i = 0; i < len; i++) {
              model = models[i];
              self.entityGlobalCollection[`${modelEntity}_${model.get("id")}`] = model;
            }
            iconSpinner.spin(false);
            arrow.removeClass("loading hide");
            if (childrenContainer.is(":visible")) {
              self.addChildren({
                collection: collection,
                container: childrenContainer,
                entityType: entityType
              });
            } else {
              childrenContainer.show();
              paginationContainer.show();
            }
            self.updateScrollBar();
            item.data("fetchingData", false);
          });
        }
      }
    },
    getContractInfo(org) {
      var contractToDisplay, currentContract, endDate, i, output, pendingContract, status;
      var contract = org.contract || [];
      var legacyContract = org.legacy_contract || [];
      var contracts = _.isArray(contract) ? contract : [contract];
      var legacyContracts = _.isArray(legacyContract) ? legacyContract : [legacyContract];
      contracts = contracts.concat(legacyContracts);
      contracts.sort(ContractUtils.contractSortFunc);
      const contractsLen = contracts.length;
      if (contractsLen) {
        for (i = 0; i < contractsLen; i++) {
          contract = contracts[i];
          status = ContractUtils.getContractStatus(contract);
          if (status === "Current" && !currentContract) {
            currentContract = contract;
          } else if (status === "Pending" && !pendingContract) {
            pendingContract = contract;
          }
        }
        contractToDisplay = contractToDisplay || currentContract || pendingContract;
        if (!contractToDisplay) {
          contractToDisplay = contracts[0];
        }
        status = ContractUtils.getContractStatus(contractToDisplay);
        endDate = ContractUtils.formatDate(contractToDisplay.effective_end_date, "MM/dd/yyyy");
        output = `${status} contract ${status === "Expired" ? "ended " : "ends "}${endDate}`;
      } else {
        output = "No contracts";
      }
      return output;
    },
    viewOrEditOrg(event) {
      var $currentTarget = $(event.currentTarget);
      var id = $currentTarget.data("id");
      var entityType = $currentTarget.data("type");
      var typeHash = {
        org: "organization",
        ag: "agency",
        adv: "advertiser"
      };
      this.stopEvent(event);
      if (event.shiftKey || event.ctrlKey || event.metaKey) {
        window.open(`#admin/${typeHash[entityType]}/${id}`, "_blank");
      } else {
        T1.Location.set(`#admin/${typeHash[entityType]}/${id}`);
      }
    },
    applyEditHover(evt) {
      var $currentTarget = $(evt.currentTarget);
      $currentTarget.closest(".admin-edit-holder").addClass("hover");
    },
    removeEditHover() {
      this.$(".admin-edit-holder").removeClass("hover");
    },
    stopEvent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    serialize(inputCollection) {
      var pixelFlag, processed;
      var self = this;
      var coll = inputCollection || this.collection;
      var models = coll.toJSON() || [];
      var hideNoResultMessage = false;
      var list = [];
      var spaceEntity = "&nbsp;";
      var typeHash = {
        ORGANIZATION: "org",
        AGENCY: "ag",
        ADVERTISER: "adv"
      };
      var searchedTerm = self.collection.searchTerm;
      var hasHash = searchedTerm && searchedTerm.indexOf("#") !== -1;
      var highlightText = T1.Utils.highlightText;
      const escapeHTML = T1.Utils.escapeHTML;
      var isIdSearch = searchedTerm && (hasHash || $.isNumeric(searchedTerm));
      var getDetails = {
        org: this.getContractInfo,
        ag: () => spaceEntity,
        adv: model => model.domain
      };
      var boundAddChildren = this.addChildren.bind(this);
      function trunc(text, maxLength) {
        var textArry = [];
        if (!text || text.length <= maxLength) {
          return text;
        }
        textArry.push(text.slice(0, maxLength).trim());
        textArry.push("...");
        return textArry.join("");
      }
      function getAncestry(data) {
        var result = "";
        var agency = data.agency || "";
        var organization = (agency ? agency.organization : data.organization) || "";
        const length1 = 35;
        const length2 = 70;
        var maxLength = agency && organization ? length1 : length2;
        if (organization) {
          result += `${trunc(organization.name, maxLength)} &raquo; `;
        }
        if (agency) {
          result += `${trunc(agency.name, maxLength)} &raquo; `;
        }
        return result;
      }
      function processData(data) {
        var isEdit, isExpanded, subList;
        var entityType = typeHash[data.type.toUpperCase()];
        var isSearchResult = searchedTerm;
        var ancestry = "";
        var permObj = self.checkEditPerms(data, entityType);
        var menuIndexRef = self.menuIndexRef;
        isSearchResult = self.collection.entityType ? isSearchResult && entityType === typeHash[self.collection.entityType] : isSearchResult;
        if (isSearchResult && entityType !== "org") {
          ancestry = getAncestry(data);
        }
        switch (entityType) {
          case "org":
            pixelFlag = "allow_x_agency_pixels";
            isEdit = permObj[menuIndexRef.editOrg].visible;
            break;
          case "ag":
            pixelFlag = "allow_x_adv_pixels";
            isEdit = permObj[menuIndexRef.editAg].visible;
            break;
          case "adv":
            isEdit = permObj[menuIndexRef.editAdv].visible;
            break;
        }
        if (data.children) {
          subList = _.map(data.children, child => processData(child));
          isExpanded = true;
        }
        const gridName = escapeHTML(data.name, true);
        return {
          entity: isSearchResult ? ancestry + highlightText(gridName, AdminUtils.htmlDecode(searchedTerm)) : gridName,
          entityID: data.id,
          parentEntityID: entityType === "ag" ? data.organization_id : entityType === "adv" ? data.agency_id : "",
          id: isSearchResult && isIdSearch ? highlightText(data.id, hasHash ? searchedTerm.split("#")[1] : searchedTerm) : data.id,
          sharedPixelTargeting: entityType === "adv" ? spaceEntity : data[pixelFlag] === "1" ? "Enabled" : "Disabled",
          details: isSearchResult ? highlightText(getDetails[entityType](data), AdminUtils.htmlDecode(searchedTerm)) : getDetails[entityType](data),
          entityType: entityType,
          indent: entityType === "org" || isSearchResult ? "" : entityType === "ag" ? "margin: 0 -20px 0 20px;" : "margin: 0 -40px 0 40px;",
          indent_arrow: entityType === "org" || isSearchResult ? "" : "margin: 2px -20px 0 20px;",
          indent_page: entityType === "org" || isSearchResult ? "margin: 5px -25px - 25px;" : entityType === "ag" ? "margin: 5px -55px 0 55px;" : "margin: 5px -75px 0 75px;",
          hasChildren: entityType !== "adv",
          is_active: data.status === "1",
          list: subList,
          isExpanded: isExpanded,
          urlEntity: data.type,
          isEdit: isEdit
        };
      }
      this.inSearch = searchedTerm && searchedTerm !== "";
      if (coll.loaded === false && isFireFox) {
        hideNoResultMessage = true;
      }
      $.each(models, function (i, model) {
        processed = processData(model);
        list.push(processed);
      });
      if (coll.count > coll.pageLimit) {
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: `.pagination-${coll.parent}`,
          layout: {}
        });
        this.paginationLayout.layout[`.pagination-${coll.parent}`] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: coll,
            onSuccess: boundAddChildren,
            indent: processed.indent_page
          }
        }];
        this.paginationLayout.load();
      }
      return {
        hideNoResultMessage: hideNoResultMessage,
        list: list
      };
    }
  });
  return T1GridView(OrgsGridView);
});
