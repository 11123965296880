define('currencies.config',{
  defaultFormat: {
    symbol: "$",
    groupSep: ",",
    decimalSep: ".",
    decimalCnt: 2
  },
  currencies: {
    ARS: {
      name: "Argentine Peso",
      format: {
        groupSep: ".",
        decimalSep: ","
      }
    },
    AUD: {
      name: "Australian Dollar",
      format: {
        groupSep: " "
      }
    },
    BRL: {
      name: "Brazilian Real",
      format: {
        symbol: "R$",
        groupSep: ".",
        decimalSep: ","
      }
    },
    GBP: {
      name: "British Pound",
      format: {
        symbol: "£"
      }
    },
    CAD: {
      name: "Canadian Dollar"
    },
    CLP: {
      name: "Chilean Peso",
      format: {
        decimalCnt: 0
      }
    },
    CNY: {
      name: "Chinese Yuan Renminbi",
      format: {
        symbol: "¥"
      }
    },
    COP: {
      name: "Colombian Peso",
      format: {
        groupSep: ".",
        decimalSep: ","
      }
    },
    CZK: {
      name: "Czech Koruna",
      format: {
        symbol: "Kč",
        groupSep: ".",
        decimalSep: ","
      }
    },
    DKK: {
      name: "Danish Krone",
      format: {
        symbol: "kr",
        groupSep: ".",
        decimalSep: ","
      }
    },
    EGP: {
      name: "Egyptian Pound",
      format: {
        symbol: "£"
      }
    },
    AED: {
      name: "Emirati Dirham",
      format: {
        symbol: " د.إ"
      }
    },
    EUR: {
      name: "Euro",
      format: {
        symbol: "€"
      }
    },
    HKD: {
      name: "Hong Kong Dollar",
      format: {
        symbol: "HK$"
      }
    },
    INR: {
      name: "Indian Rupee",
      format: {
        symbol: "₹",
        specialGroupings: [3, 2]
      }
    },
    ILS: {
      name: "Israeli Shekel",
      format: {
        symbol: "₪"
      }
    },
    JPY: {
      name: "Japanese Yen",
      format: {
        symbol: "¥",
        decimalCnt: 0
      }
    },
    KWD: {
      name: "Kuwaiti Dinar",
      format: {
        symbol: "د.ك",
        decimalCnt: 3
      }
    },
    MYR: {
      name: "Malaysian Ringgit",
      format: {
        symbol: "RM"
      }
    },
    MXN: {
      name: "Mexican Peso"
    },
    MAD: {
      name: "Moroccan Dirham",
      format: {
        symbol: String.fromCharCode(46, 1583, 46, 1605)
      }
    },
    NZD: {
      name: "New Zealand Dollar"
    },
    NOK: {
      name: "Norwegian Krone",
      format: {
        symbol: "kr",
        groupSep: ".",
        decimalSep: ","
      }
    },
    PLN: {
      name: "Polish Zloty",
      format: {
        symbol: "zł",
        groupSep: " ",
        decimalSep: ","
      }
    },
    QAR: {
      name: "Qatari Riyal",
      format: {
        symbol: "﷼"
      }
    },
    RUB: {
      name: "Russian Ruble",
      format: {
        symbol: "₽",
        groupSep: ".",
        decimalSep: ","
      }
    },
    SAR: {
      name: "Saudi Arabian Riyal",
      format: {
        symbol: "﷼"
      }
    },
    SGD: {
      name: "Singapore Dollar"
    },
    ZAR: {
      name: "South African Rand",
      format: {
        symbol: "R",
        groupSep: " "
      }
    },
    KRW: {
      name: "South Korean Won",
      format: {
        symbol: "₩",
        decimalCnt: 0
      }
    },
    SEK: {
      name: "Swedish Krona",
      format: {
        symbol: "kr",
        groupSep: ".",
        decimalSep: ","
      }
    },
    CHF: {
      name: "Swiss Franc",
      format: {
        symbol: "CHF",
        groupSep: `'`
      }
    },
    TRY: {
      name: "Turkish Lira",
      format: {
        symbol: "₺"
      }
    },
    USD: {
      name: "US Dollar"
    },
    BGN: {
      name: "Bulgarian Lev",
      format: {
        symbol: "лв"
      }
    },
    FJD: {
      name: "Fijian Dollar",
      format: {
        symbol: "FJ$"
      }
    },
    HUF: {
      name: "Hungarian Forint",
      format: {
        symbol: "Ft"
      }
    },
    ISK: {
      name: "Icelandic Króna",
      format: {
        symbol: "kr"
      }
    },
    IDR: {
      name: "Indonesian Rupiah",
      format: {
        symbol: "Rp"
      }
    },
    JMD: {
      name: "Jamaican Dollar",
      format: {
        symbol: "J$"
      }
    },
    JOD: {
      name: "Jordanian Dinar",
      format: {
        symbol: " د.إ"
      }
    },
    PEN: {
      name: "Peruvian Sol",
      format: {
        symbol: "S/."
      }
    },
    PKR: {
      name: "Pakistani Rupee",
      format: {
        symbol: "Rs"
      }
    },
    PHP: {
      name: "Philippine Peso",
      format: {
        symbol: "₱"
      }
    },
    TWD: {
      name: "Taiwanese New Dollar",
      format: {
        symbol: "NT$"
      }
    },
    THB: {
      name: "Thai Baht",
      format: {
        symbol: "฿"
      }
    },
    VEB: {
      name: "Venezuelan Bolivar",
      format: {
        symbol: "Bs"
      }
    }
  },
  currenciesRequireDirectionFlowSpan: ["AED", "SAR", "QAR", "KWD", "MAD"]
});
