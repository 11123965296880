define('models/mediaDeal',["jQuery", "moment", "T1", "T1Model", "T1Comm"], function ($, moment, T1, T1Model, T1Comm) {
  "use strict";

  T1.Models.Deal = T1Model.extend({
    canCache: false,
    cacheDuration: 6e4,
    url: "deals",
    apiRoot: "/media/v1.0/",
    validateAttrs: function (attrs, options) {
      var errors = [];
      var requiredField = "This is a required field.";
      var optionalFields = ["description", "publisher_id"];
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1 || key === "publisher_id" && !options.skipPublisherIdValidation) {
          errors.push({
            field: key,
            message: requiredField
          });
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var permissionDerferred = $.Deferred();
      permissionDerferred.resolve(true);
      return permissionDerferred.promise();
    },
    fetch: function (options) {
      var onSuccess;
      options = options || {};
      onSuccess = function (data) {
        options.success(data);
      };
      if (this.ajx) {
        this.ajx.abort();
      }
      const syncOpts = {
        dataType: "json",
        success: onSuccess,
        processAjaxResponse: this.processAjaxResponse
      };
      if (options.params) {
        syncOpts.params = options.params;
      }
      if (options.action) {
        syncOpts.action = options.action;
      }
      this.ajx = this.sync("read", "", syncOpts);
      if (this.ajx) {
        this.ajx.always(() => {
          this.ajx = null;
        });
      }
      return this.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    doDisable: function (data) {
      var dealOwner = data.owner;
      var dealPermissions = data.permissions;
      return !(dealOwner.type === "ADVERTISER" && dealPermissions.advertiser_ids.length === 1 && dealOwner.id === dealPermissions.advertiser_ids[0]);
    },
    parse: function (resp) {
      var data = resp.data || resp;
      const dealTypeMap = Object.freeze({
        PG: "Programmatic Guaranteed",
        STANDARD: "PMP-E"
      });
      return !data || data.message ? {} : {
        type: "deal",
        name: data.name,
        id: data.id.toString(),
        deal_identifier: data.deal_identifier,
        supply_source_id: data.supply_source_id.toString(),
        start: moment(data.start_datetime).format("M/D/YYYY"),
        end: moment(data.end_datetime).format("M/D/YYYY"),
        price: data.price.value ? data.price.value : 0,
        status: data.status ? "1" : "0",
        description: data.description,
        publisher_id: data.sub_supply_source_id ? data.sub_supply_source_id.toString() : "",
        advertiser_id: data.owner ? data.owner.id.toString() : "",
        currency_code: data.price.currency_code,
        deal_source: data.partner_source,
        price_method: data.price_method,
        price_type: data.price_type,
        start_datetime: data.start_datetime,
        end_datetime: data.end_datetime,
        zone_name: data.zone_name,
        created_on: data.created_on,
        updated_on: data.updated_on,
        owner: data.owner,
        permissions: data.permissions,
        deal_type: dealTypeMap[data.deal_type],
        disableDealAddEdit: data.owner && data.permissions ? this.doDisable(data) : true
      };
    },
    createOldDeal: function (rawData, options) {
      var self = this;
      var dealName, validationErrors;
      var nameField = "name";
      var exceedMaxCharLength = "This field cannot be more than 64 characters.";
      var exceedMinCharLength = "This field must have at least one character, not including spaces.";
      var statusInvalid = options.invalid || $.noop;
      const maxLength = 256;
      const maxLength2 = 254;
      validationErrors = self.validateAttrs(rawData, options);
      if (validationErrors === undefined) {
        if (rawData[nameField]) {
          dealName = rawData[nameField].trim();
          if (dealName.length > maxLength || dealName === this.get(nameField) && dealName.length >= maxLength2) {
            validationErrors = [{
              field: nameField,
              message: exceedMaxCharLength
            }];
          } else if (dealName.length === 0) {
            validationErrors = [{
              field: nameField,
              message: exceedMinCharLength
            }];
          }
        }
      }
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
        return;
      }
      this.createDeal(rawData, options);
    },
    createDeal: function (rawData, options) {
      var statusInvalid = options.invalid || $.noop;
      var conflict = options.conflict || $.noop;
      var error = options.error || $.noop;
      this.save(rawData, {
        success: function (model) {
          var onSuccess = options.success || $.noop;
          onSuccess(model);
        },
        error: function (model, responseString) {
          var response = JSON.parse(responseString);
          var status = response.meta.status;
          var message = response.errors[0].message;
          T1.Notify(status, message);
          error(model, responseString);
        },
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        statusInvalid: statusInvalid,
        conflict: conflict,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.meta.status || "ok"
          };
        }
      });
    },
    bulkUpdate: function (ids, options) {
      const statusInvalid = options.invalid || $.noop;
      const conflict = options.conflict || $.noop;
      const error = options.error || $.noop;
      this.save(ids, {
        success: function (model) {
          const onSuccess = options.success || $.noop;
          onSuccess(model);
        },
        error: function (model, responseString) {
          const response = JSON.parse(responseString);
          const status = response.meta.status;
          const message = response.errors[0].message;
          T1.Notify(status, message);
          error(model, responseString);
        },
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        statusInvalid: statusInvalid,
        conflict: conflict,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.success || resp.meta.status || "ok"
          };
        }
      });
    },
    sync: function (action, attributes, options) {
      var self = this;
      var method = action === "read" ? "GET" : "POST";
      var request = method === "GET" ? T1Comm.get : T1Comm.post;
      var params = options.params;
      var modelUrl = T1.Utils.constructModelUrl(this, options.action, params);
      var postObj;
      var success = options.success || $.noop;
      var error = options.error || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      var errSuppressingConfig = options.errorDisplaySuppressingConfig;
      var showNotification = typeof self.showNotification === "boolean" ? self.showNotification : false;
      if (options.httpAction === "delete") {
        method = "DELETE";
        request = T1Comm.deleteCall;
      }
      const onError = function (data) {
        var freshData, jsonData, responseText, statusCode;
        if (errSuppressingConfig && errSuppressingConfig.errorSuppressed === true) {
          error(data);
          return;
        }
        if (method === "POST" || method === "DELETE") {
          responseText = data;
          try {
            statusCode = $(responseText).find("status").attr("code");
            if (statusCode === "conflict") {
              jsonData = T1.Utils.parseXMLtoJson($.parseXML(responseText), self.modelTemplate);
              T1.VersionConflictDialog.open();
              freshData = self.parse(jsonData);
              self.set(freshData);
              conflict();
            } else if (statusCode === "error") {
              error(data);
            }
          } catch (e) {
            error(data);
          }
        }
      };
      if (options.dataType === "json") {
        postObj = attributes || {};
      } else {
        postObj = method === "POST" || method === "DELETE" ? $.param(attributes, true) : {};
      }
      params = $.extend({
        sourceURL: modelUrl,
        data: postObj,
        onSuccess: success,
        onError: onError,
        onStatusInvalid: statusInvalid,
        showNotification: showNotification,
        fixture: this.fixture || "",
        async: options.async || true,
        canCache: this.canCache,
        cacheDuration: this.cacheDuration,
        cacheExcludes: this.cacheExcludes,
        apiRoot: this.apiRoot
      }, options);
      return request(params);
    }
  });
  return T1.Models.Deal;
});
