define('modules/reporting/campaigns/utils/reportingUtils',['require','moment','T1','models/sessionUser'],function (require) {
  "use strict";

  const moment = require("moment");
  const T1 = require("T1");
  const SessionUser = require("models/sessionUser");
  const defaultRange = 7;
  const yesterday = 1;
  const domainValidator = new RegExp(/^[-.\w]+\.[-.\w]+$/);
  return Object.freeze({
    get defaultStartDate() {
      return moment().subtract(defaultRange, "days").toDate();
    },
    get defaultEndDate() {
      return moment().subtract(yesterday, "days").toDate();
    },
    get hideCostsAndFees() {
      const canEditMargins = T1.Utils.valueToBool(SessionUser.getUser().get("edit_margins_and_performance"));
      return !canEditMargins;
    },
    validateDomain(domain) {
      return domainValidator.test(domain);
    },
    removeStrFromObjKey(obj, str) {
      return Object.entries(obj).reduce((newObj, [key, value]) => {
        if (key.includes(str)) {
          key = key.replace(str, "");
        }
        newObj[key] = value;
        return newObj;
      }, {});
    }
  });
});
