define('models/organization',["jQuery", "T1", "Backbone", "T1Model", "collections/contracts", "collections/legacyContracts"], function ($, T1, Backbone, T1Model, Contracts, LegacyContracts) {
  T1.Models.Organization = T1Model.extend({
    url: "organizations/",
    relations: [{
      type: Backbone.HasMany,
      key: "contracts",
      relatedModel: "Contract",
      collectionType: "Contracts",
      includeInJSON: false,
      reverseRelation: {
        key: "organization",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: "contract",
      relatedModel: "Contract",
      collectionType: "Contracts",
      includeInJSON: true,
      reverseRelation: {
        key: "organization",
        includeInJSON: true
      }
    }, {
      type: Backbone.HasMany,
      key: "legacy_contract",
      relatedModel: "LegacyContract",
      collectionType: "LegacyContracts",
      includeInJSON: true,
      reverseRelation: {
        key: "organization",
        includeInJSON: true
      }
    }],
    postEntityParse: {
      legacy_contract: T1.Utils.objectTOArray,
      contract: T1.Utils.objectTOArray
    },
    fixture: "-Organization",
    validateAttrs: function (attrs) {
      var errors = [];
      var optionalFields = ["status", "address_2", "country", "currency_code", "billing_country_code", "allow_x_agency_pixels", "use_evidon_optout", "allow_byo_price", "dmp_enabled", "eligible_for_data_sharing"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        name: function () {
          return !attrs.name;
        },
        contact_name: function () {
          return !attrs.contact_name;
        },
        phone: function () {
          return !attrs.phone;
        },
        address_1: function () {
          return !attrs.address_1;
        },
        city: function () {
          return !attrs.city;
        },
        state: function () {
          return !attrs.state;
        },
        zip: function () {
          return !attrs.zip;
        },
        mm_contact_name: function () {
          return !attrs.mm_contact_name;
        },
        adx_seat_account_id: function () {
          return !attrs.adx_seat_account_id;
        }
      };
      var extraValFields = {
        name: function () {
          if (attrs.name.length > 64) {
            return "Name cannot be longer than 64 characters.";
          }
        },
        adx_seat_account_id: function () {
          if (attrs.adx_seat_account_id.length > 9) {
            return "ADX Seat Account ID cannot be longer than 9 digits.";
          } else if (isNaN(parseInt(attrs.adx_seat_account_id, 10))) {
            return "ADX Seat Account ID must be a number.";
          }
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        } else if (errors.length === 0 && extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    }
  });
  return T1.Models.Organization;
});
