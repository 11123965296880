define('modules/reporting/campaigns/dataExport/createEdit/views/emailSettings',['require','Underscore','jQuery','T1','T1Timezones','T1View','text!../templates/emailSettings.html','models/sessionUser','models/userPreferences'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Timezones = require("T1Timezones");
  const T1View = require("T1View");
  const template = require("text!../templates/emailSettings.html");
  const User = require("models/sessionUser");
  const UserPreferences = require("models/userPreferences");
  const T1Publish = T1.EventHub.publish;
  const userEmail = User.getUser().get("email");
  const dayMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  const emailPreview = {
    date: "yyyy-mm-ddThh:mm:Z",
    defaultMessage: "Enter your message here",
    id: "sampleID-123a-456b-78cd-9012345e45678",
    location: "http://example_scheduled_report_location_url",
    name: "Example_Scheduled_Report",
    request: "endpoint?parameters&of&example&api&call"
  };
  const bufferTime = 2;
  const debounceDelay = 200;
  const maxChars = 20;
  const midday = 12;
  const scrollLimit = 2e3;
  const settleDelay = 3e3;
  const toggleDelay = 300;
  return T1View.extend({
    emailSettingsDrawerState: "",
    template: template,
    events: {
      "blur .message-input": "saveMessageInputHandler",
      "changed .am-pm-ddl": "selectAMPMHandler",
      "changed .frequency-ddl": "updateFrequencyGrainHandler",
      "changed .monthly-ddl": "selectMonthlyValueHandler",
      "changed .subject-input": "saveSubjectInputHandler",
      "changed .time-ddl": "selectTimeHandler",
      "changed .timezone-ddl": "selectTimezoneHandler",
      "changed .to-input": "saveEmailInputHandler",
      "click .settings-toggle": "toggleSettingsDrawerHandler",
      "click .start-end-date": "openDatePickerHandler",
      "click .weekly-bl": "selectWeeklyValueHandler",
      "saved .date-picker": "saveDatesHandler"
    },
    eventhubEvents: {
      "createEdit.emailSettingsError": "displayError",
      "createEdit.updateFileName": "updateEmailPreview",
      "dateRange.toggle": function (state) {
        const editReport = this.DataExportModel.get("editReport");
        if (editReport && editReport.schedule && state === "fixed") {
          this.publishChanges();
        }
        this.fixedRollingState = state;
        this.toggleFixedDateWarning();
      },
      "days.change": function (showEmailSettings) {
        const editReport = this.DataExportModel.get("editReport");
        if (editReport && editReport.schedule && !showEmailSettings) {
          T1Publish("change:formElements", true);
          T1Publish("emailSettings.changed", showEmailSettings);
        }
        this.showEmailSettings = showEmailSettings;
        this.toggleEmailSettingsBlock();
      }
    },
    initialize(model) {
      const DataExportModel = this.DataExportModel = model;
      const dateBasedType = DataExportModel.getDateBasedType();
      this.dateFormat = DataExportModel.getDateFormat();
      this.displayFormat = DataExportModel.getDisplayFormat();
      this.fixedRollingState = dateBasedType;
      this.showEmailSettings = true;
      DataExportModel.setEmailFromAddress(userEmail);
      this.initPreferences();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", "data_export"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["emailSettingsDrawerState"]);
    },
    load() {
      const $el = this.el;
      const self = this;
      const DataExportModel = this.DataExportModel;
      this.render().then(() => {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        this.$emailAddressesWarning = this.$(".email-address-error");
        this.$errorBox = this.$(".error-holder");
        this.$fixedDateBlock = this.$(".settings-container .blocker");
        this.$fixedDateWarning = this.$(".fixed-warning");
        this.$settingsToggle = this.$(".settings-toggle");
        this.ampmDDL = this.$(".am-pm-ddl")[0];
        this.datePicker = this.$(".date-picker")[0];
        this.emailAddresses = this.$(".to-input")[0];
        this.frequencyDDL = this.$(".frequency-ddl")[0];
        this.message = this.$(".message-input")[0];
        this.messagePreview = this.$(".message-preview")[0];
        this.settingsDrawer = this.$(".settings-drawer")[0];
        this.subject = this.$(".subject-input")[0];
        this.timeDDL = this.$(".time-ddl")[0];
        this.timezonesDDL = this.$(".timezone-ddl")[0];
        window.requestAnimationFrame(function () {
          const frequencyGrain = DataExportModel.getEmailFrequencyGrain();
          const emailTime = DataExportModel.getEmailTime();
          const ampm = DataExportModel.getEmailAMPM();
          const datePicker = self.datePicker;
          const today = new Date();
          datePicker.target = $el.find(".start-end-date")[0];
          datePicker.closeLabel = "Cancel";
          datePicker.allowedStart = today;
          datePicker.allowedEnd = DataExportModel.getEmailMaxEndDate(today);
          if (frequencyGrain !== "-1") {
            self.frequencyDDL.set("initialValue", true);
          }
          self.frequencyDDL.set("value", T1.Utils.toInitialCaps(frequencyGrain));
          if (emailTime !== "-1") {
            self.timeDDL.set("initialValue", true);
          }
          self.timeDDL.set("value", emailTime);
          if (ampm !== "-1") {
            self.ampmDDL.set("initialValue", true);
          }
          self.ampmDDL.set("value", ampm);
          if (DataExportModel.getEmailFrequencyGrain() === "weekly") {
            self.updateWeeklySetting();
          }
          self.updateEmailPreview();
          self.settingsDrawer.expanded = self.emailSettingsDrawerState === "expanded";
        });
        T1Timezones.done(function (mthds) {
          const zoneName = DataExportModel.get("emailTimezone");
          const timezonesDDL = self.timezonesDDL;
          const timezonesObj = mthds.getTimeZones();
          const timezones = _.map(timezonesObj, function (timezoneObj) {
            const description = timezoneObj.description.replace("&amp;", "&");
            return {
              name: description,
              value: timezoneObj.zone_name
            };
          });
          timezonesDDL.set("initialValue", true);
          timezonesDDL.set("data", timezones);
          timezonesDDL.set("value", zoneName || mthds.getDefaultZoneName());
          if (!zoneName) {
            DataExportModel.setEmailTimezone(mthds.getDefaultZoneName());
          }
        });
      });
    },
    unload() {
      this.$fixedDateBlock = null;
      this.$fixedDateWarning = null;
      this.$settingsToggle = null;
      this.ampmDDL = null;
      this.dateFormat = null;
      this.displayFormat = null;
      this.emailAddresses = null;
      this.emailSettingsDrawerState = null;
      this.frequencyDDL = null;
      this.message = null;
      this.messagePreview = null;
      this.preferences = null;
      this.settingsDrawer = null;
      this.subject = null;
      this.timeDDL = null;
      this.timezonesDDL = null;
    },
    toggleSettingsDrawerHandler() {
      const $settingsToggle = this.$settingsToggle;
      $settingsToggle.toggleClass("expanded");
      this.settingsDrawer.toggle();
      if ($settingsToggle.hasClass("expanded")) {
        this.emailSettingsDrawerState = "expanded";
        this.toggleFixedDateWarning();
        clearTimeout(this.toggleTimeout);
        this.toggleTimeout = setTimeout(function () {
          $(document).scrollTop(scrollLimit);
        }, toggleDelay);
      } else {
        this.emailSettingsDrawerState = "";
        this.toggleFixedDateWarning();
      }
      this.preferences.save();
    },
    toggleFixedDateWarning() {
      const $fixedDateBlock = this.$fixedDateBlock;
      const $fixedDateWarning = this.$fixedDateWarning;
      if (this.fixedRollingState === "fixed") {
        if (this.$settingsToggle.hasClass("expanded")) {
          $fixedDateBlock.removeClass("no-display");
          $fixedDateWarning.removeClass("no-display");
        } else {
          $fixedDateBlock.addClass("no-display");
          $fixedDateWarning.addClass("no-display");
        }
      } else {
        $fixedDateBlock.addClass("no-display");
        $fixedDateWarning.addClass("no-display");
        this.toggleEmailSettingsBlock();
      }
    },
    toggleEmailSettingsBlock() {
      const $fixedDateBlock = this.$fixedDateBlock;
      if (this.showEmailSettings === false) {
        if (this.$settingsToggle.hasClass("expanded")) {
          $fixedDateBlock.removeClass("no-display");
        } else {
          $fixedDateBlock.addClass("no-display");
        }
      } else {
        $fixedDateBlock.addClass("no-display");
      }
    },
    saveEmailInputHandler: _.debounce(function (event) {
      this.DataExportModel.setEmailToAddresses(event.currentTarget.value);
      this.publishChanges();
      this.updateEmailWarning(event.currentTarget.value.slice(-1));
    }, debounceDelay),
    updateEmailWarning(key) {
      const DataExportModel = this.DataExportModel;
      const isEmailAddressValid = DataExportModel.getEmailAddressesVerified();
      const isEmailAddressPresent = Boolean(DataExportModel.get("emailToAddresses"));
      const isKeyDelimiter = key === "," || key === " ";
      let emailErrorState = this.emailErrorState;
      if (!emailErrorState && isKeyDelimiter && !isEmailAddressValid) {
        T1Publish("createEdit.emailSettingsError", "address");
        emailErrorState = true;
      } else if (emailErrorState && (isEmailAddressValid || !isEmailAddressPresent)) {
        this.$emailAddressesWarning.hide();
        emailErrorState = false;
      }
      this.emailErrorState = emailErrorState;
    },
    saveSubjectInputHandler: _.debounce(function (event) {
      this.DataExportModel.setEmailSubject(event.currentTarget.value);
      this.publishChanges();
    }, debounceDelay),
    updateFrequencyGrainHandler(evt) {
      this.frequencyDDL.set("error", false);
      this.DataExportModel.setEmailFrequencyGrain(evt.currentTarget.value);
      this.renderPartial(".frequency-selector .selectors");
      this.publishChanges();
      window.requestAnimationFrame(() => {
        switch (evt.currentTarget.value) {
          case "Weekly":
            this.updateWeeklySetting();
            break;
          case "Monthly":
            this.updateMonthlySetting();
            break;
        }
      });
    },
    updateWeeklySetting() {
      const weeklyValue = this.DataExportModel.getEmailWeeklyValue();
      this.$(".weekly-bl")[0].selectedIndex = dayMap[weeklyValue];
    },
    updateMonthlySetting() {
      const dayOfMonth = this.DataExportModel.getEmailMonthlyValue().id;
      const monthlyDDL = this.$(".monthly-ddl")[0];
      if (dayOfMonth === "-1") {
        monthlyDDL.set("initialValue", false);
      }
      monthlyDDL.set("value", dayOfMonth);
    },
    selectWeeklyValueHandler() {
      const selectedDay = this.$(".weekly-bl strand-button[selected]");
      this.$(".weekly-selector").removeClass("selector-error");
      this.DataExportModel.setEmailWeeklyValue(selectedDay[0].id);
      this.publishChanges();
    },
    selectMonthlyValueHandler(event) {
      const currentTarget = event.currentTarget;
      const selectedDayArr = this.$(".monthly-ddl strand-list-item[selected]");
      const id = selectedDayArr.length > 1 ? selectedDayArr[1].id : selectedDayArr[0].id;
      this.$(".monthly-ddl")[0].error = false;
      this.DataExportModel.setEmailMonthlyValue({
        value: currentTarget.value,
        id: id
      });
      this.publishChanges();
    },
    openDatePickerHandler() {
      const datePicker = this.datePicker;
      const startEndDate = this.DataExportModel.getEmailStartEndDate();
      const startDate = moment(startEndDate.start).toDate();
      const endDate = moment(startEndDate.end).toDate();
      datePicker.start = startDate;
      datePicker.end = endDate;
      datePicker.open();
    },
    saveDatesHandler(event) {
      const eventData = event.originalEvent.detail;
      const startDate = moment(eventData.start).toDate();
      const endDate = moment(eventData.end).toDate();
      this.DataExportModel.setEmailStartEndDate({
        start: startDate.toString(this.dateFormat),
        end: endDate.toString(this.dateFormat)
      });
      this.togglePreferredTimeError();
      this.publishChanges();
      this.renderPartial(".start-end-date");
    },
    selectTimeHandler(event) {
      this.timeDDL.error = false;
      this.DataExportModel.setEmailTime(event.currentTarget.value);
      this.togglePreferredTimeError();
      this.publishChanges();
    },
    selectAMPMHandler(event) {
      this.ampmDDL.error = false;
      this.DataExportModel.setEmailAMPM(event.currentTarget.value);
      this.togglePreferredTimeError();
      this.publishChanges();
    },
    selectTimezoneHandler(event) {
      this.DataExportModel.setEmailTimezone(event.currentTarget.value);
      this.publishChanges();
    },
    saveMessageInputHandler(event) {
      const value = event.currentTarget.value;
      if (value) {
        this.DataExportModel.setEmailMessage(value);
        this.publishChanges();
      }
      this.updateEmailPreview();
    },
    updateEmailPreview() {
      this.messagePreview.value = this.generatePreview();
    },
    generatePreview() {
      const message = this.$(".message-input")[0].value || emailPreview.defaultMessage;
      const fileName = this.DataExportModel.get("fileName") || emailPreview.name;
      return `${message}\n\n      Report Name: ${fileName}\n      Report ID: ${emailPreview.id}\n      Result Location: ${emailPreview.location}\n\n      Run Date: ${emailPreview.date}\n      Result Expiration: ${emailPreview.date}\n      Request: ${emailPreview.request}\n      `;
    },
    publishChanges() {
      T1Publish("change:formElements", true);
      T1Publish("emailSettings.changed", true);
    },
    displayError(type) {
      switch (type) {
        case "address":
          this.emailAddresses.error = true;
          this.showEmailAddressesWarning();
          break;
        case "subject":
          this.subject.error = true;
          break;
        case "frequency":
          this.frequencyDDL.error = true;
          break;
        case "weekly":
          this.$(".weekly-selector").addClass("selector-error");
          break;
        case "monthly":
          this.$(".monthly-ddl")[0].error = true;
          break;
        case "time":
          this.timeDDL.error = true;
          break;
        case "ampm":
          this.ampmDDL.error = true;
          break;
        case "preferredTime":
          this.togglePreferredTimeError();
          break;
        case "message":
          this.message.error = true;
          break;
      }
    },
    togglePreferredTimeError() {
      const isValid = this.DataExportModel.isPreferredTimeValid();
      const $errorBox = this.$errorBox;
      this.timeDDL.error = !isValid;
      this.ampmDDL.error = !isValid;
      $errorBox.toggleClass("no-display", isValid);
      $errorBox.toggleClass("show", !isValid);
      if (!isValid) {
        this.updatePreferredTime();
      }
    },
    updatePreferredTime() {
      const currDate = moment();
      const newTime = currDate.add(bufferTime, "hour");
      const nextHour = newTime.hour();
      const timeDDL = this.timeDDL;
      const ampmDDL = this.ampmDDL;
      setTimeout(function () {
        if (nextHour === 0) {
          timeDDL.value = "12:00";
          ampmDDL.value = "AM";
        } else if (nextHour === midday) {
          timeDDL.value = `${nextHour}:00`;
          ampmDDL.value = "PM";
        } else if (nextHour > midday) {
          timeDDL.value = `${nextHour - midday}:00`;
          ampmDDL.value = "PM";
        } else {
          timeDDL.value = `${nextHour}:00`;
          ampmDDL.value = "AM";
        }
      }, settleDelay);
    },
    showEmailAddressesWarning() {
      const emailAddresses = this.DataExportModel.get("emailToAddresses");
      const addressArr = emailAddresses ? emailAddresses.replace(/ /g, "").split(",").filter(Boolean) : [];
      const $emailAddressesWarning = this.$emailAddressesWarning;
      let text;
      switch (true) {
        case addressArr.length === 0:
          text = "You must enter valid, comma separated email addresses.";
          break;
        case addressArr.length > maxChars:
          text = "The maximum allowed email addresses is 20";
          break;
        case addressArr.length < maxChars && addressArr.length > 0:
          text = "Contains an invalid email address.";
          break;
      }
      this.emailErrorState = true;
      if (Polymer && Polymer.dom) {
        Polymer.dom($emailAddressesWarning[0]).textContent = text;
      } else {
        $emailAddressesWarning.text(text);
      }
      $emailAddressesWarning.show();
    },
    serialize() {
      const {
        DataExportModel: DataExportModel,
        displayFormat: displayFormat
      } = this;
      const frequencyGrain = DataExportModel.getEmailFrequencyGrain();
      const startEndDate = DataExportModel.getEmailStartEndDate();
      const startDate = moment(startEndDate.start).toDate();
      const endDate = moment(startEndDate.end).toDate();
      const isExpanded = this.emailSettingsDrawerState === "expanded";
      const dateBasedType = DataExportModel.getDateBasedType();
      const isFixed = dateBasedType === "fixed";
      let isUnblocked = true;
      if (isExpanded && isFixed) {
        isUnblocked = false;
      }
      return {
        dailyView: frequencyGrain === "daily",
        endDate: endDate.toString(displayFormat),
        expanded: isExpanded,
        frequencyValue: DataExportModel.getEmailFrequencyValue(true),
        isFixed: isFixed,
        isUnblocked: isUnblocked,
        message: DataExportModel.getEmailMessage(),
        monthlyView: frequencyGrain === "monthly",
        startDate: startDate.toString(displayFormat),
        subject: DataExportModel.get("emailSubject"),
        toEmails: DataExportModel.get("emailToAddresses"),
        toggleClass: this.emailSettingsDrawerState,
        userEmail: userEmail,
        weeklyView: frequencyGrain === "weekly"
      };
    }
  });
});
