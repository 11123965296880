define('modules/admin/vendors/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "admin/vendors",
      viewCfgs: {
        createEdit: {},
        vendors: {},
        grid: {}
      },
      defaultView: "vendors"
    });
  }
  return instance;
});
