define('collections/batchSupplySources',["jQuery", "Underscore", "T1", "T1Collection", "models/batchSupplySource"], function ($, _, T1, T1Collection, BatchSupplySource) {
  T1.Models.BatchSupplySources = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: BatchSupplySource,
    urlString: "batch_supply_sources",
    fixture: "-batch_supply_sources"
  });
  return T1.Models.BatchSupplySources;
});
