define('collections/mediaDeals',["jQuery", "Underscore", "When", "T1", "T1Collection", "T1Permissions", "models/mediaDeal"], function ($, _, When, T1, T1Collection, T1Permissions, Deal) {
  "use strict";

  const useV2 = T1Permissions.check("feature", "deals_v2_api");
  const apiRoot = useV2 ? "/deals/v1.0/" : "/media/v1.0/";
  T1.Models.Deals = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    model: Deal,
    urlString: "deals",
    apiRoot: apiRoot,
    fetch: function (options) {
      const self = this;
      options = options || {};
      const onSuccess = function (data) {
        var parsedData = self.parse(data);
        options.success(parsedData, data);
      };
      const syncOpts = {
        dataType: "json",
        success: onSuccess,
        processAjaxResponse: this.processAjaxResponse
      };
      if (options.analytics) {
        this.apiRoot = "/media/v1.0/";
        this.urlString = "deals_analytics";
        delete options.analytics;
      }
      if (this.fetchOptions.export) {
        syncOpts.dataType = "html";
      }
      this.ajx = this.sync("read", "", syncOpts);
      if (this.ajx) {
        this.ajx.always(() => {
          this.ajx = null;
        });
      }
      return this.ajx;
    },
    fetchAllPages: function (options) {
      var self = this;
      const API_LIMIT = 500;
      options = options || {};
      this.isPaginated = true;
      this.page = 0;
      this.pageLimit = options.pageLimit || API_LIMIT;
      this.fetchOptions = $.extend(true, {}, this.fetchOptions, options.fetchOptions || {});
      this.fetch({
        silent: options.silent || false,
        success: function () {
          var pageLimit = self.pageLimit;
          var morePagesLen = self.count > 0 ? Math.ceil(self.count / pageLimit) - 1 : 0;
          var success = options.success;
          var models = self.models;
          var deferred = [];
          var promises = [];
          var morePages = [];
          var fetchMoreSuccess = function (collection, resp) {
            const page = parseInt(resp.meta.offset, 10) / pageLimit;
            var index = page - 1;
            morePages[index] = models.splice(pageLimit, models.length - pageLimit);
            deferred[index].resolve();
          };
          var i;
          for (i = 0; i < morePagesLen; i++) {
            deferred.push(When.defer());
            promises.push(deferred[i].promise);
            self.fetchMore({
              silent: true,
              success: fetchMoreSuccess
            });
          }
          self.isPaginated = false;
          if (success) {
            if (morePagesLen > 0) {
              When.all(promises).then(function () {
                self.models = models.concat(...morePages);
                success.call(self, self);
              });
            } else {
              success.call(self, self);
            }
          }
        }
      });
    },
    fetchMore: function (args) {
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          silent: args.silent || false,
          add: true,
          success: args.success || $.noop
        });
      }
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    parse: function (resp) {
      var deals = [];
      var data = resp.data;
      var deal;
      if (!data) {
        return deals;
      }
      for (let i = 0; i < data.length; i++) {
        deal = new Deal();
        deals.push(deal.parse(data[i]));
      }
      this.add(deals);
      this.count = resp.meta.total_count;
      this.offset = resp.meta.offset;
      this.loaded = true;
      return deals;
    },
    filterGarbageData: function () {
      var result = _.filter(this.models, model => model.get("type") === "deal");
      this.reset();
      this.add(result, {
        silent: true
      });
    },
    sortGrid: function (sortKey, sortOrder) {
      if (sortKey === "price") {
        this.models = this.models.sort((a, b) => a.get(sortKey) - b.get(sortKey));
      } else if (sortKey === "start" || sortKey === "end") {
        this.models = this.models.sort(function (a, b) {
          const dateA = new Date(a.get(sortKey));
          const dateB = new Date(b.get(sortKey));
          return dateA - dateB;
        });
      } else {
        this.models = _.sortBy(this.models, function (model) {
          if (sortKey === "id") {
            return Number(model.get(sortKey));
          }
          return model.get(sortKey).trim().toLowerCase();
        });
      }
      if (sortOrder === -1) {
        this.models.reverse();
      }
      this.trigger("sort", this);
      this.trigger("reset", this);
    },
    filterFor: function (state) {
      var filtered;
      var shouldFilter = state !== "both";
      if (!this.browseModels) {
        this.browseModels = $.extend(true, [], this.models);
      }
      if (shouldFilter) {
        filtered = _.filter(this.browseModels, model => model.get("status") === state);
        this.reset(filtered);
      } else {
        this.reset(this.browseModels);
      }
    },
    getLength: function () {
      return this.browseModels ? this.browseModels.length : this.length;
    },
    getBrowseModels: function () {
      return this.browseModels || this.models;
    },
    searchDeals: function (term, filter, activeState, sortKey, sortOrder) {
      var filtered;
      var shouldShow = activeState === "both";
      if (!this.browseModels) {
        this.browseModels = this.models;
      }
      if (term) {
        term = term.toLowerCase();
        filtered = _.filter(this.browseModels, function (model) {
          var searched = model.get(filter).toLowerCase();
          var hasTerm = searched.indexOf(term) !== -1;
          var state = model.get("status");
          var correctState = state === activeState || shouldShow;
          return hasTerm && (correctState || shouldShow);
        });
        this.reset(filtered);
      } else if (!shouldShow) {
        filtered = _.filter(this.browseModels, model => model.get("status") === activeState);
        this.reset(filtered);
      } else {
        this.reset(this.browseModels);
      }
      if (sortKey) {
        this.sortGrid(sortKey, sortOrder);
      }
    },
    abort: function () {
      if (this.ajx) {
        this.ajx.abort();
      }
    }
  });
  return T1.Models.Deals;
});
