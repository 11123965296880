define('modules/audiences/segments/main',["T1Module"], function (T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "audiences/segments",
      viewCfgs: {
        segments: {},
        createEdit: {
          model: null
        }
      },
      defaultView: "segments"
    });
  }
  return instance;
});
