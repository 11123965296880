define('models/lookalikesModel',["jQuery", "Underscore", "moment", "T1", "Backbone", "T1Model"], function ($, _, Moment, T1, Backbone, T1Model) {
  "use strict";

  T1.Models.LookalikesModel = T1Model.extend({
    sort_by: "-model_id",
    with: "segments",
    page_limit: "-1",
    q: "",
    url: function () {
      var params = [];
      if (this.with) {
        params.push("with=" + this.with);
      }
      if (this.sort_by) {
        params.push("sort_by=" + this.sort_by);
      }
      if (this.page_limit) {
        params.push("page_limit=" + this.page_limit);
      }
      if (this.q) {
        params.push(this.q);
      }
      return T1.ENV.SEGMENT_API_BASE + "lookalikes/orgs/" + this.get("org") + "/models?" + params.join("&");
    },
    save: function (saveObj, options) {
      var self = this;
      var multiSaves = Object.keys(options.audienceObj).map(function (type) {
        var saveCall = $.Deferred();
        var successFunct = function () {
          saveCall.resolve();
        };
        var errorFunct = function (err) {
          saveCall.resolve(err);
        };
        var data = Object.assign({}, saveObj);
        if (type === "remarket") {
          data.prospecting = false;
        } else if (type === "prospect") {
          delete data.start_window;
          delete data.end_window;
        }
        $.ajax({
          type: "POST",
          contentType: "application/json",
          url: self.url(),
          success: successFunct,
          error: errorFunct,
          dataType: "json",
          data: JSON.stringify(data)
        });
        return saveCall.promise();
      });
      $.when.apply($, multiSaves).then(data => {
        if (data) {
          options.onError(data);
        } else {
          if (options.path) {
            T1.Location.set(options.path);
            return;
          }
          options.onSuccess();
        }
      });
    },
    pixelFetch: function (cb, advertiser, type, target, altTarget) {
      var self = this;
      var dataRadio = $('strand-radio[value="data"]');
      var eventRadio = $('strand-radio[value="event"]');
      var searchProp = function (data, term) {
        var value;
        data.some(function (item) {
          if (item.name === term) {
            return value = item.value;
          }
        });
        return value;
      };
      var fetcher = function () {
        var filter = "full=pixel_bundle&q=pixel_type%3D%3D" + type;
        var filterLevel = type === "event" ? "advertiser=" + advertiser : "agency=" + self.modelAgency;
        var url = T1.ENV.API_BASE + "pixel_bundles/limit/" + filterLevel + "?sort_by=name&" + filter;
        loader(target);
        $.ajax({
          type: "GET",
          url: url,
          success: function (data) {
            var jsonData = $.xml2json(data);
            var jsonObj = jsonData.entities.entity || [];
            jsonObj = Array.isArray(jsonObj) ? jsonObj : [jsonObj];
            var multipleLoad = Math.ceil(jsonData.entities.count / 100);
            var count = 1;
            var ajaxLoader = function (loopVal) {
              $.ajax({
                type: "GET",
                url: url + "&page_offset=" + count * 100,
                success: function (newData) {
                  var newObj = $.xml2json(newData).entities.entity;
                  if (!Array.isArray(newObj)) {
                    newObj = [newObj];
                  }
                  cb(target, newObj, "add");
                  if (altTarget) {
                    cb(altTarget, newObj, "add");
                  }
                  if (count !== loopVal - 1) {
                    if (type === "event") {
                      count++;
                      ajaxLoader(loopVal);
                      dataRadio.attr("disabled", true);
                    } else {
                      count++;
                      ajaxLoader(loopVal);
                      eventRadio.attr("disabled", true);
                    }
                  } else {
                    dataRadio.attr("disabled", false);
                    eventRadio.attr("disabled", false);
                  }
                }
              });
            };
            if (multipleLoad > 1) {
              ajaxLoader(multipleLoad);
            }
            cb(target, jsonObj);
            loader(target, "stop");
            if (altTarget) {
              cb(altTarget, jsonObj);
              loader(altTarget, "stop");
            }
          },
          error: function () {}
        });
      };
      var loader = function (tar, end) {
        var spinClass = tar.substring(1);
        var drop = $(tar).closest("strand-pulldown-button");
        var carrot = drop.children("#target").children("strand-icon");
        var spinner = $("." + spinClass + "spinner");
        if (!spinner.length) {
          drop.children("#target").append('<strand-spinner class="' + spinClass + 'spinner" radius="9" fill-color="#333333"></strand-spinner>');
        }
        if (end) {
          spinner.hide();
          carrot.show();
        } else {
          spinner.show();
          carrot.hide();
        }
      };
      if (advertiser === null) {
        return;
      }
      if (type !== "event") {
        $.ajax({
          type: "GET",
          url: T1.ENV.API_BASE + "advertisers/" + advertiser,
          success: function (data) {
            var jsonData = $.xml2json(data);
            var agency = searchProp(jsonData.entity.prop, "agency_id");
            var tacCheck = searchProp(jsonData.entity.prop, "data_sharing_enabled");
            self.modelAgency = agency;
            self.prevCheck = tacCheck === "1" ? true : false;
            fetcher();
          }
        });
      } else {
        fetcher();
      }
    },
    countryFetch: function (target) {
      var regionName = this.region;
      var countries = this.regionsObj.filter(region => region.region === regionName)[0].countries;
      var options = countries.map(country => '<option data-region="' + regionName + '" value="' + country.id + '">' + country.name + "</option>").join("");
      $(`${target} option`).remove();
      $(target).append(options);
    },
    setupFetchAndBindRegions: function (target, view) {
      $.ajax({
        type: "GET",
        url: `${T1.ENV.SEGMENT_API_BASE}lookalikes/advertisers/${this.modelAdvertiser}/model_permissions`,
        dataType: "json",
        success: data => {
          var jsonObj = data.data;
          var disableRegions = () => {
            requestAnimationFrame(() => {
              if (this.disabledRegions.length) {
                var currentMethod = this.disabledRegions.shift();
                var methodRow = $(`#regions strand-list-item[value='${currentMethod.region}']`).closest(".recycler-panel");
                if (methodRow.length) {
                  methodRow.addClass("disabled");
                } else {
                  this.disabledRegions.push(currentMethod);
                }
                disableRegions();
              }
            });
          };
          this.dataSharing = jsonObj.dataSharing;
          this.regionsObj = jsonObj.regions;
          this.disabledRegions = this.regionsObj.filter(region => !region.userTypes.length);
          this.defaultNoDataSharing = (this.regionsObj.length === 1 || this.disabledRegions.length == 2) && this.regionsObj.some(obj => obj.region === "EMEA") && !this.dataSharing;
          this.regionList = this.regionsObj.map(item => ({
            value: item.region,
            name: item.region
          }));
          if (this.defaultNoDataSharing) {
            $("#regionInfo").show();
          } else {
            $("#regionInfo").hide();
          }
          target.set("data", this.regionList);
          [...view.el[0].querySelectorAll("#regions .recycler-panel")].map(el => el.classList.remove("disabled"));
          disableRegions();
        }
      });
    },
    modelFetch: function (opts) {
      if (opts.q) {
        this.q = "q=" + opts.q;
      } else {
        this.q = "";
      }
      this.url();
      this.fetch({
        forcedContentType: "application/json",
        postJson: true,
        success: (model, response) => {
          if (!response.data) {
            var jsonResponse = JSON.parse(response);
            if (jsonResponse.errors) {
              console.error(jsonResponse.errors[0].message);
              T1.Notify("error", "Internal Error. Please contact T1 Support.");
              opts.error(jsonResponse.errors[0].message);
              return;
            }
          }
          var modifiedData = response.data;
          modifiedData.map(function (respModel) {
            var seedPixels = [];
            if (respModel.build_date === "None") {
              respModel.build_date = "--";
            } else {
              respModel.build_date = Moment(respModel.build_date).format("MM/DD/YYYY");
            }
            if (respModel.status === 1) {
              respModel.isBuilt = true;
            } else {
              respModel.isNotBuilt = true;
              if (respModel.status === 0) {
                respModel.buildIcon = "clock";
                respModel.buildText = "In Progress";
                respModel.buildColor = "#6C6C6C";
              } else {
                respModel.buildIcon = "fail";
                respModel.buildText = respModel.status === -1 ? "Not Available" : "Not Enough Seeds";
                respModel.buildColor = "#EA5151";
              }
            }
            if (respModel.countries === "None") {
              respModel.displayCountries = "--";
            } else {
              respModel.displayCountries = respModel.countries.sort().join(", ");
            }
            Object.keys(respModel.seed_pixels).map(function (pixel) {
              seedPixels.push(respModel.seed_pixels[pixel] + " (" + pixel + ")");
            });
            respModel.seedPixels = seedPixels.join(", ");
            if (respModel.prospecting) {
              respModel.audienceType = "Prospecting";
              respModel.siteRecency = "--";
            } else {
              respModel.audienceType = "Online Scoring";
              if (respModel.end_window === 30 || respModel.end_window === "*") {
                if (respModel.start_window === 0) {
                  respModel.siteRecency = "All Visitors";
                } else {
                  respModel.siteRecency = respModel.start_window + "-30 Days";
                }
              } else {
                respModel.siteRecency = "--";
              }
            }
            respModel.seed_type = respModel.seed_type === 2 ? "Data" : respModel.seed_type === 1 ? "Event" : "--";
          });
          opts.success(modifiedData);
        },
        onError: function (err) {
          opts.error(err);
        },
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: "ok"
          };
        }
      });
      return this;
    }
  });
  return T1.Models.LookalikesModel;
});
