define('modules/strategy/views/item',['require','Underscore','jQuery','Hogan','moment','T1','T1Form','T1Layout','T1Menu','T1Model','T1Permissions','T1View','utils/CampaignStrategyUtils','modules/organization/utils/Utils','text!modules/strategy/templates/item.html','text!modules/strategy/templates/spendMode.html','text!modules/strategy/templates/impressionMode.html','text!modules/strategy/templates/performanceMode.html','T1Tooltip','T1Accordion','T1DatePicker'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Hogan = require("Hogan");
  const moment = require("moment");
  const T1 = require("T1");
  const T1Form = require("T1Form");
  const T1Layout = require("T1Layout");
  const T1Menu = require("T1Menu");
  const T1Model = require("T1Model");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const Util = require("utils/CampaignStrategyUtils");
  const OrgUtils = require("modules/organization/utils/Utils");
  let template = require("text!modules/strategy/templates/item.html");
  const spendMode = require("text!modules/strategy/templates/spendMode.html");
  const impressionMode = require("text!modules/strategy/templates/impressionMode.html");
  const performanceMode = require("text!modules/strategy/templates/performanceMode.html");
  const T1Tooltip = require("T1Tooltip");
  const T1Accordion = require("T1Accordion");
  const T1DatePicker = require("T1DatePicker");
  let currentViewMode;
  const totalSpendEcpm = "total_spend_ecpm";
  const totalSpend = "total_spend";
  const effectivePerformanceField = Object.freeze({
    cpa: "total_spend_ecpa",
    cpc: "total_spend_ecpc",
    cpe: "total_spend_ecpe",
    ctr: "mm_ctr",
    roi: "total_spend_roi",
    vcpm: "total_spend_vcpm",
    vcr: "video_complete_rate",
    viewability_rate: "viewability_rate"
  });
  var partials = {
    spend: Hogan.compile(spendMode),
    performance: Hogan.compile(performanceMode),
    impression: Hogan.compile(impressionMode)
  };
  var t1Masks = T1.Form.Masks;
  var useAllocPacing = T1Permissions.check("feature", "use_alloc_pacing");
  var toInitialCaps = T1.Utils.toInitialCaps;
  var frequencyIntervalText = {
    hour: "Hour",
    day: "Day",
    week: "7 Days",
    month: "30 Days"
  };
  var frequencyIntervalDisplayText = {
    hour: "/h",
    day: "/d",
    week: "/w",
    month: "/m"
  };
  var frequencyNoLimitText = "No Cap";
  var impressionNoLimitText = "No Cap";
  var goalTypeText = {
    spend: "SPEND",
    reach: "REACH",
    cpc: "CPC",
    cpe: "CPE",
    cpa: "CPA",
    roi: "ROI",
    vcr: "VCR",
    ctr: "CTR",
    viewability_rate: "V.RATE",
    vcpm: "vCPM",
    cpcv: "CPCV"
  };
  var goalTypes = [{
    value: "spend",
    text: goalTypeText.spend
  }, {
    value: "reach",
    text: goalTypeText.reach
  }, {
    value: "cpc",
    text: goalTypeText.cpc
  }, {
    value: "cpe",
    text: goalTypeText.cpe
  }, {
    value: "cpa",
    text: goalTypeText.cpa
  }, {
    value: "roi",
    text: goalTypeText.roi
  }, {
    value: "vcr",
    text: goalTypeText.vcr
  }, {
    value: "ctr",
    text: goalTypeText.ctr
  }, {
    value: "viewability_rate",
    text: goalTypeText.viewability_rate
  }, {
    value: "vcpm",
    text: goalTypeText.vcpm
  }, {
    value: "cpcv",
    text: goalTypeText.cpcv
  }];
  var pacingOptimizationText = "ALLOC";
  var dropdownList = {
    goal_type: {
      options: goalTypes,
      bodyClassName: "goal-type-dropdown-body",
      headClassName: "goal-type-dropdown-head"
    },
    type: {
      options: [{
        value: "REM",
        text: "REM"
      }, {
        value: "GBO",
        text: "GBO"
      }, {
        value: "AUD",
        text: "AUD"
      }],
      bodyClassName: "small-body",
      headClassName: "small-head"
    },
    pacing_type: function (model) {
      var useOptimization = model.get("use_optimization") === "1";
      var pacingAlloc = model.get("pacing_optimization_type");
      var pacingOptimizationOption = {
        value: "",
        text: "Allocation"
      };
      var options = [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }];
      if (useAllocPacing && useOptimization) {
        if (currentViewMode === "spendMode") {
          pacingOptimizationOption.value = "monetary";
          if (!pacingAlloc) {
            options.push(pacingOptimizationOption);
          } else if (pacingAlloc === "monetary") {
            options.push(pacingOptimizationOption);
          }
        }
      }
      return {
        options: options
      };
    },
    pacing_amount: {
      isCurrency: true
    },
    pacing_interval: {
      options: [{
        value: "hour",
        text: "hour"
      }, {
        value: "day",
        text: "day"
      }]
    },
    impression_pacing_type: function (model) {
      var useOptimization = model.get("use_optimization") === "1";
      var pacingAlloc = model.get("pacing_optimization_type");
      var pacingOptimizationOption = {
        value: "",
        text: "Allocation"
      };
      var options = [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: impressionNoLimitText
      }];
      if (useAllocPacing && useOptimization) {
        if (currentViewMode === "impressionMode") {
          pacingOptimizationOption.value = "impression";
          if (!pacingAlloc) {
            options.push(pacingOptimizationOption);
          } else if (pacingAlloc === "impression") {
            options.push(pacingOptimizationOption);
          }
        }
      }
      return {
        options: options
      };
    },
    impression_pacing_amount: {
      isCurrency: false
    },
    impression_pacing_interval: {
      options: [{
        value: "hour",
        text: "hour"
      }, {
        value: "day",
        text: "day"
      }]
    },
    pacing_optimization_type: function (model) {
      var useOptimization = model.get("use_optimization") === "1";
      var pacingAlloc = model.get("pacing_optimization_type");
      var pacingOptimizationOption = {
        value: "",
        text: "Allocation"
      };
      var noCapOption = {
        value: "no-limit",
        text: impressionNoLimitText
      };
      var options = [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }];
      if (useAllocPacing && useOptimization) {
        if (currentViewMode === "spendMode") {
          pacingOptimizationOption.value = "monetary";
          if (!pacingAlloc) {
            options.push(pacingOptimizationOption);
          } else if (pacingAlloc === "monetary") {
            options.push(pacingOptimizationOption);
          }
        }
        if (currentViewMode === "impressionMode") {
          options.push(noCapOption);
          pacingOptimizationOption.value = "impression";
          if (!pacingAlloc) {
            options.push(pacingOptimizationOption);
          } else if (pacingAlloc === "impression") {
            options.push(pacingOptimizationOption);
          }
        }
      }
      return {
        options: options
      };
    },
    frequency_type: function (model) {
      var goalType = model && model.get("goal_type");
      var options = [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: frequencyNoLimitText
      }];
      if (goalType !== "reach" && goalType !== "spend") {
        options.unshift({
          value: "standard",
          text: "T1 Optimized"
        });
      }
      return {
        options: options
      };
    },
    frequency_interval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }, {
        value: "week",
        text: frequencyIntervalText.week
      }, {
        value: "month",
        text: frequencyIntervalText.month
      }]
    }
  };
  var pacingASAPCaution = "<p>ASAP pacing is designed to bolster spend for strategies targeting small audiences. " + "When targeting larger audiences, ASAP pacing may result in spending more than your pacing amount.</p>" + '<p class="confirm-message">By clicking the "Confirm"' + " button, you are acknowledging the possibility of overspend.</p>";
  var impPacingASAPCaution = "<p>ASAP pacing is designed to bolster delivery for strategies targeting small " + "audiences. When targeting larger audiences, ASAP pacing may result in delivering more than your pacing amount." + '</p><p class="confirm-message">By clicking the "Confirm" button, you are acknowledging the ' + "possibility of over delivery.</p>";
  var frequencyCap = function ($groupEditEl, frequencyType) {
    const isNoCap = frequencyType === "no-limit" || frequencyType === "standard";
    var $frequencyEls = $("[data-bind]", $groupEditEl).not('[data-bind="frequency_type"]');
    var freqType = dropdownList.frequency_type(new T1Model());
    const freqTypeIndex = freqType.options.findIndex(freqTypeItem => freqTypeItem.value === frequencyType);
    $groupEditEl.toggleClass("no-cap", isNoCap);
    if (isNoCap) {
      $('[data-bind="frequency_type"]', $groupEditEl).val(freqType.options[freqTypeIndex].value).trigger("liszt:updated");
    }
    $.each($frequencyEls, function (key, el) {
      $(el).closest("dd").toggle(!isNoCap);
    });
    $(".info", $groupEditEl).toggle(!isNoCap);
  };
  var impressionCap = function ($groupEditEl, impressionType) {
    var isNoImpCap = impressionType === "no-limit";
    var impType = dropdownList.impression_pacing_type(new T1Model());
    const impTypeIndex = impType.options.findIndex(impTypeItem => impTypeItem.value === impressionType);
    $groupEditEl.toggleClass("no-cap", isNoImpCap);
    const $impressionEls = $("[data-bind]", $groupEditEl).not('[data-bind="impression_pacing_type"]');
    if (isNoImpCap) {
      $('[data-bind="impression_pacing_type"]', $groupEditEl).val(impType.options[impTypeIndex].value).trigger("liszt:updated");
    }
    $.each($impressionEls, function (key, el) {
      $(el).closest("dd").toggle(!isNoImpCap);
    });
    $(".info", $groupEditEl).toggle(!isNoImpCap);
  };
  var pacingType = function ($groupEditEl) {
    var $typeElem = $(".select:first", $groupEditEl);
    var $infoElem = $(".info", $groupEditEl);
    var $intervalElem = $(".select:last", $groupEditEl);
    const typeVal = $typeElem.find("select").val();
    if (typeVal === "monetary" || typeVal === "impression") {
      $infoElem.css("display", "none");
      $intervalElem.css("display", "none");
    } else {
      $infoElem.css("display", "block");
      $intervalElem.css("display", "block");
    }
  };
  var useCampFreqCap = Util.useCampaignFrequencyCap;
  var statusActiveTitle = "Active";
  var statusInactiveTitle = "Inactive";
  const readOnlyTitle = `You don't have permission to edit this strategy`;
  var addToChartTitle = "Add to Chart";
  var Date = T1.Date;
  var formatsRolledUpNumbersNoAbbreviation = Util.formatsRolledUpNumbersNoAbbreviation;
  var divideByZero = Util.divideByZero;
  var getDatePickerLowerBoundForStrategy = Util.getDatePickerLowerBoundForStrategy;
  var populatePerformanceAlert = Util.populatePerformanceAlert;
  const T1hasOwnProp = T1.Utils.hasOwnProp;
  function getValueObject(dataEls) {
    var saveObj = {};
    $.each(dataEls, function (key, el) {
      var targetEl = $(el);
      var isTargetElHidden = targetEl.attr("type") === "hidden";
      var bindTo = targetEl.data("bind");
      if (bindTo && bindTo !== "") {
        if (targetEl.closest("dd").is(":visible") || isTargetElHidden) {
          let mask, val;
          if (targetEl.is("div")) {
            val = targetEl.attr("value");
          } else {
            mask = targetEl.attr("alt");
            val = T1.Form.Masks.revert(mask, targetEl);
          }
          saveObj[bindTo] = val;
        }
      }
    });
    if (T1hasOwnProp(saveObj, "pacing_optimization_type")) {
      saveObj.pacing_amount = "";
      saveObj.impression_pacing_amount = "";
    } else {
      if (T1hasOwnProp(saveObj, "pacing_type") || T1hasOwnProp(saveObj, "impression_pacing_type")) {
        saveObj.pacing_optimization_type = "";
        saveObj.pacing_optimization_amount = "";
      }
    }
    return {
      saveObj: saveObj,
      currentValues: {}
    };
  }
  function refreshModel(obj, model) {
    if (T1hasOwnProp(obj, "pacing_optimization_type")) {
      if (obj.pacing_optimization_amount === "") {
        model.unset("pacing_optimization_type");
        model.unset("pacing_optimization_amount");
      }
    }
    if (Reflect.has(obj, "frequency_amount")) {
      this.utils.setFrequencyAmount(this.$("[data-bind=frequency_amount]"), obj.frequency_amount);
    }
  }
  function sanitize(tmpl) {
    var good = "area|base|br|col|command|embed|hr|img|input|keygen|link|meta|param|source|track|wbr)".split("|");
    var bad = /<(\w+)(\s[^>]*\/|\/)>/gi;
    function replacer(orig, tag) {
      if (good.indexOf(tag) === -1) {
        console.warn("Invalid markup detected in template", tag);
        return orig.replace("/>", `></${tag}>`);
      }
      return orig;
    }
    return tmpl.replace(bad, replacer);
  }
  template = Hogan.compile(sanitize(template));
  const ItemView = T1View.extend({
    partials: {
      spend: spendMode,
      performance: performanceMode,
      impression: impressionMode
    },
    menuConfig: {
      menuTriggerEleSelector: ".strategy-settings",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Edit",
          visible: false
        }, {
          label: "View Summary",
          visible: false
        }, {
          label: "Duplicate",
          visible: false,
          handler: "duplicateStrategy"
        }, {
          label: "Change log",
          visible: false,
          handler: "viewChangeLog"
        }, {
          label: "View Summary",
          visible: false
        }, {
          label: "CheckList",
          visible: true,
          handler: "showHealth"
        }, {
          label: "Deactivate",
          visible: false,
          handler: "deactivateHandler"
        }, {
          label: "Activate",
          visible: false,
          handler: "activateHandler"
        }]
      }]
    },
    dataEvents: {
      model: {
        reset: "reload",
        "change:status": "updateView",
        remove: "unload",
        "change:performanceDataArrived": "performanceDataCompletedHandler"
      },
      campaign: {
        reset: "reload",
        "change:status": "updateView",
        "change:start_date": "updateView",
        "change:end_date": "updateView"
      }
    },
    loaderOpts: {
      contentClass: "inline-edit-loader",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15
      }
    },
    eventhubEvents: {
      "change:strategyFilter": "updateFromFilterValues",
      list_viewMode: "updateFromFilterValues",
      strategyList_showAll: "updateFromFilterValues",
      "chart.add": function (data) {
        var el = $(".chart-icon", this.el);
        if (data.model) {
          this.chartable = this.model.id === data.model.id;
          if (this.chartable) {
            el.removeClass("disabled");
          } else {
            el.addClass("disabled");
          }
        }
      },
      onCloseInlineEdit: function (event) {
        if (event && event.view && event.view.cid === this.cid) {
          this.updateView();
        }
      },
      "change:reportInterval": "changeReportInterval",
      onConfigClick: "applyGearHover",
      onConfigDismiss: "removeGearHover",
      "update:strategyItem": "updateView"
    },
    events: {
      "accordionaction .accordion": "onAccordionAction",
      "click .section .chart-icon": "toggleForChart",
      "mouseenter .spendMode .date-range .section-c": "datesMouseEnterHandler",
      "mouseleave .spendMode .date-range .section-c": "datesMouseLeaveHandler",
      "mouseenter .impressionMode .date-range .section-c": "datesMouseEnterHandler",
      "mouseleave .impressionMode .date-range .section-c": "datesMouseLeaveHandler",
      summaryButtonClick: "strategyButtonClickHandler",
      summaryPanelClose: "summaryPanelCloseHandler",
      "click .strategy-edit": "editStrategy",
      "contextmenu .strategy-edit-link": "applyEditHover",
      "mousemove .strategy": "removeEditHover",
      "contextmenu .strategy-actions-holder": "stopEvent",
      "click #cancel-deactivate-strategy": "closeDeactivateStrategyModal",
      "click #confirm-deactivate-strategy": "deactivatePgStrategyConfirm",
      "click [data-edit-mode], .date-range": "onInlineEditClick"
    },
    initialize({
      viewModeFields: viewModeFields,
      viewMode: viewMode,
      reportInterval: reportInterval,
      currentGoalMode: currentGoalMode,
      defaultDrawerExpandedState: defaultDrawerExpandedState
    }) {
      var model = this.model;
      var campaign = model.get("campaign");
      const {
        flag: canEditWithV2
      } = Util.allowV2CampaignOrStrategyEdit({
        campaign: campaign,
        strategy: model,
        view: self,
        showDialog: false
      });
      var currencyCode = model.get("currency_code");
      var isVideoStrategy = model.get("media_type") === "VIDEO";
      const canEditStrategy = canEditWithV2 && T1Permissions.check("strategy.edit", model);
      const hasVideoPermission = T1Permissions.check("feature", "video", model);
      model.allowInlineValidation = true;
      Object.assign(this, {
        allowHover: true,
        asCurrency: T1.Utils.getCurrencyTemplateFunc(currencyCode),
        asDecimalCurrency: T1.Utils.getDecimalCurrencyTemplateFunc(currencyCode),
        campaign: campaign,
        campaignCollection: campaign.collection,
        canEdit: canEditStrategy && (isVideoStrategy ? hasVideoPermission : true),
        canEditStrategy: canEditStrategy,
        canViewChangeLog: T1Permissions.check("changeLog.view"),
        canViewStrategy: T1Permissions.check("strategy.readonly", model),
        currentGoalMode: currentGoalMode,
        defaultDrawerExpandedState: defaultDrawerExpandedState,
        getValueObject: getValueObject,
        hasVideoPermission: hasVideoPermission,
        isVideoStrategy: isVideoStrategy,
        pacingOptimizationAmount: model.get("pacing_optimization_amount"),
        pacingOptimizationType: model.get("pacing_optimization_type"),
        refreshModel: refreshModel,
        reportInterval: reportInterval,
        strategyEditable: canEditStrategy && !isVideoStrategy || canEditStrategy && hasVideoPermission,
        useAllocPacing: T1Permissions.check("feature", "use_alloc_pacing"),
        viewMode: viewMode,
        viewModeFields: viewModeFields,
        canEditWithV2: canEditWithV2
      });
      this.layout = new T1Layout({
        el: this.el,
        selectorPath: ".strategy-drawer",
        layout: {}
      });
      this.layout.append(".summary-badges", {
        module: "strategy",
        viewType: "summaryInfo",
        options: {
          model: model
        }
      });
      T1.EventHub.subscribe(`action:drawer:load-${model.id}`, this.lazyLoadBadges());
    },
    lazyLoadBadges() {
      const delay = 100;
      return _.debounce(() => {
        const expandAction = this.isDrawerExpanded();
        if (!this.isBadgesDataLoaded || !expandAction) {
          this.isBadgesDataLoaded = false;
          this.fetchStrategyBadgesData();
        } else {
          this.loadStrategyBadges();
        }
      }, delay);
    },
    updateView() {
      var serialize = this.serialize();
      const freqCapValGroupNode = this.$(".frequencyCapValGroup");
      const impCapValGroupNode = this.$(".impCapValGroup");
      var $element = this.renderViewMode({
        mode: this.viewMode,
        interval: this.reportInterval,
        force: true,
        data: serialize
      });
      this.renderPartial(".item.accordion .name .name-part");
      this.attachDatePicker($element);
      this.updateDataBind({
        name: serialize.name,
        type: serialize.type,
        status_class: serialize.status_class,
        statusTitle: serialize.statusTitle,
        frequency_type: serialize.frequency_optimization ? "T1 Optimized" : serialize.frequency_type,
        frequency_amount: serialize.frequency_optimization ? "" : serialize.frequency_amount,
        frequency_interval: serialize.frequency_optimization ? "" : serialize.frequency_interval,
        frequency_optimization: serialize.frequency_optimization ? "1" : "0",
        pacing_type: serialize.pacing_type,
        pacing_amount: serialize.pacing_amount,
        pacing_interval: serialize.pacing_interval,
        impression_pacing_type: serialize.impression_pacing_type,
        impression_pacing_amount: formatsRolledUpNumbersNoAbbreviation(serialize.impression_pacing_amount.replace(/,/g, "")).trim(),
        impression_pacing_interval: serialize.impression_pacing_interval,
        roi_target: `${Util.parseNumberToWhole(serialize.roi_target)}:1`,
        pacing_optimization_amount: serialize.pacing_optimization_amount
      }, this.el);
      this.bindFrequencyCap();
      this.disableBidPrice();
      this.bindImpressionCap();
      this.prepareDataPoints();
      this.delegateEvents();
      this.prepareT1Menu();
      this.bindOptimizationType();
      const addNoCapClass = serialize.frequency_type === frequencyNoLimitText;
      const impNoCapClass = serialize.impression_pacing_type === impressionNoLimitText;
      freqCapValGroupNode.toggleClass("frequency-no-cap", addNoCapClass);
      impCapValGroupNode.toggleClass("impression-no-cap", impNoCapClass);
      this.updateDrawerExpander();
    },
    stopEvent(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    changeReportInterval(data) {
      this.reportInterval = data.reportInterval;
    },
    attachDatePicker($parent) {
      var mode = this.viewMode.replace("Mode", "");
      if (mode !== "performance") {
        requestAnimationFrame(this.doAttachDatePicker.bind(this, $parent));
      }
    },
    doAttachDatePicker($parent) {
      var self = this;
      var model = this.model;
      var campaign = model.get("campaign");
      var startDate = model.get("start_date");
      var hasStarted = Util.hasEntityStarted(model);
      var hasCampaignStarted = Util.hasEntityStarted(campaign);
      hasStarted = model.get("use_campaign_start") !== "0" ? hasCampaignStarted : hasStarted;
      if (this.canEdit) {
        T1.DoubleDatePicker({
          topOffset: 80,
          leftOffset: 20,
          chooseTime: true,
          chooseTimezone: true,
          timezoneEditable: false,
          defaultStartMessage: "use campaign date",
          useDefaultStart: parseInt(model.get("use_campaign_start"), 10),
          useDefaultEnd: parseInt(model.get("use_campaign_end"), 10),
          defaultEndMessage: "use campaign date",
          openPickerSelector: ".date-range .data",
          zoneName: () => campaign.get("zone_name"),
          startTimeEditable: () => !hasStarted,
          lowerBounds: function () {
            const campaignKey = model.get("use_campaign_start") !== "0" ? "initial_start_date" : "start_date";
            return getDatePickerLowerBoundForStrategy(hasStarted, hasCampaignStarted, Util.parseDate(startDate), Util.parseDate(campaign.get(campaignKey)));
          },
          startDisabled: () => hasStarted,
          defaultStartDate: () => campaign.get("start_date"),
          defaultEndDate: () => campaign.get("end_date"),
          getStartDate: () => model.get("start_date"),
          getEndDate: () => model.get("end_date"),
          onSave: function ({
            data: data,
            success: success,
            error: error
          }) {
            const saveValues = data.saveValues;
            var saveStartDate = campaign.get("start_date");
            var saveEndDate = campaign.get("end_date");
            var zoneName = campaign.get("zone_name");
            var loader = self.loader();
            var saveObj = {
              use_campaign_start: saveValues.start.useDefault ? 1 : 0,
              use_campaign_end: saveValues.end.useDefault ? 1 : 0
            };
            if (!saveObj.use_campaign_start) {
              saveStartDate = saveValues.start.date;
            }
            if (!saveObj.use_campaign_end) {
              saveEndDate = saveValues.end.date;
            }
            saveObj.start_date = saveObj.use_campaign_start === 0 ? `${saveStartDate} ${zoneName}` : "";
            saveObj.end_date = saveObj.use_campaign_end === 0 ? `${saveEndDate} ${zoneName}` : "";
            loader.start();
            self.model.save(saveObj, {
              success: function () {
                loader.stop();
                success();
                self.updateView();
              },
              conflict: function () {
                loader.stop();
                self.updateView();
              },
              statusInvalid: function (errData) {
                loader.stop();
                error(errData[0]);
              }
            });
            return true;
          }
        }, $parent);
      }
      $parent.find("[data-datepicker]").trigger("mousedown.attachpicker");
    },
    viewStrategySummary(evt) {
      T1.Location.set(`#strategy/summary/${this.model.id}`);
      this.preventDefault(evt);
    },
    datesMouseEnterHandler(event) {
      this.datesMouseEventHandler(event, true);
    },
    datesMouseLeaveHandler(event) {
      this.datesMouseEventHandler(event, false);
    },
    datesMouseEventHandler(event, isMouseEnter) {
      var $currentTarget = $(event.currentTarget);
      const $elDates = this.$(".date-range .dates");
      const $elRemaining = this.$(".days-remaining");
      var $targetToShow = isMouseEnter === true ? $elDates : $elRemaining;
      var $targetToHide = isMouseEnter === true ? $elRemaining : $elDates;
      var onHoverClass = this.canEdit === true ? "onhover" : "onhover-noedit";
      if (isMouseEnter === true) {
        $currentTarget.addClass(onHoverClass);
      } else {
        $currentTarget.removeClass(onHoverClass);
      }
      $targetToShow.show();
      $targetToHide.hide();
    },
    onAccordionAction() {
      this.updateDrawerExpander();
      T1.EventHub.publish("action:drawerExpandContract", {});
    },
    onInlineEditClick(event) {
      if (!this.canEditWithV2) {
        Util.allowV2CampaignOrStrategyEdit({
          campaign: this.campaign,
          strategy: this.model,
          view: this,
          showDialog: true,
          cancelable: true
        });
      } else {
        event.data = {
          view: this
        };
      }
      this.stopEvent(event);
    },
    updateDrawerExpander() {
      const $accordion = this.$(".accordion");
      const needToAddExpendedClass = this.isDrawerExpanded();
      $accordion.find(".drawer-expander").toggleClass("expanded", needToAddExpendedClass);
      if (needToAddExpendedClass !== true) {
        const drawerLayout = this.layout && this.layout.layout ? this.layout.layout : null;
        const viewList = drawerLayout ? drawerLayout[".strategy-drawer"] : null;
        const drawerView = viewList && Array.isArray(viewList) && viewList.length ? viewList[0].view : null;
        if (drawerView && drawerView.closeInlineEditor) {
          drawerView.closeInlineEditor(true);
        }
      }
    },
    fetchStrategyBadgesData() {
      const view = this.layout.layout[".summary-badges"][0].view;
      if (view) {
        view.fetchData(true).then(() => {
          this.loadStrategyBadges();
          this.isBadgesDataLoaded();
        });
      }
    },
    loadStrategyBadges() {
      this.layout.loadView(".summary-badges");
    },
    strategyButtonClickHandler() {
      this.$(".cancel-action").click();
      this.$strategyList.addClass("strategy-badge-panel-open");
      this.$strategyListItem.addClass("strategy-badge-panel-open");
    },
    summaryPanelCloseHandler() {
      this.$strategyList.removeClass("strategy-badge-panel-open");
      this.$strategyListItem.removeClass("strategy-badge-panel-open");
    },
    applyEditHover() {
      this.$(".strategy-edit-holder").addClass("hover");
    },
    removeEditHover() {
      this.$(".strategy-edit-holder").removeClass("hover");
    },
    applyGearHover({
      view: view
    } = {}) {
      if (view && view.cid === this.cid) {
        this.$(".strategy-actions-holder").addClass("hover");
        this.$(".strategy-edit-holder").addClass("suppress-hover");
      }
    },
    removeGearHover() {
      this.$(".strategy-actions-holder").removeClass("hover");
      this.$(".strategy-edit-holder").removeClass("suppress-hover");
    },
    expandCloseDrawer(toExpand) {
      const $drawer = this.$(".accordion").parent().find(".drawer");
      var isExpanded = this.isDrawerExpanded();
      if ($drawer.length && toExpand !== isExpanded) {
        $drawer[toExpand === true ? "show" : "hide"]();
        this.updateDrawerExpander();
      }
    },
    isDrawerExpanded() {
      const $drawer = this.$(".accordion").parent().find(".drawer");
      return $drawer.length && $drawer.css("display") !== "none";
    },
    toggleForChart(event) {
      this.stopEvent(event);
      this.chartable = true;
      T1.EventHub.publish("chart.add", {
        model: this.model
      });
      return false;
    },
    prepareDataPoints() {
      T1.Tooltip(this.$(".pointer"), {
        offset: "4"
      });
      T1.Tooltip(this.$(".label"));
    },
    disableBidPrice() {
      if (this.isProgrammaticGuaranteed && (this.viewMode === "spendMode" || this.viewMode === "impressionMode")) {
        this.$(".bid-price.spend-to-pace").addClass("disabled-strategy-items");
      }
    },
    render() {
      var templateData = this.serialize();
      var deferred = $.Deferred();
      var html = template.render(templateData);
      if (T1.Utils.hasPolymer()) {
        Polymer.dom(this.el[0]).innerHTML = html;
      } else {
        this.el.html(html);
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      deferred.resolve(templateData);
      return deferred.promise();
    },
    afterRender(serialize) {
      this.performanceDataCompletedHandler(serialize);
      this.prepareT1Menu();
      this.$strategyList = this.el.closest(".w-StrategyList");
      this.$strategyListItem = this.$(".w-StrategyListItem");
      this.utils.setFrequencyAmount(this.$("[data-bind=frequency_amount]"), serialize.frequency_amount);
    },
    load() {
      var updateDrawerExpander = this.updateDrawerExpander.bind(this);
      this.render().then(this.afterRender.bind(this));
      this.layout.append(".strategy-drawer", {
        module: "strategy",
        viewType: "drawer",
        options: {
          model: this.model,
          defaultDrawerExpandedState: this.defaultDrawerExpandedState
        }
      }).then(function () {
        const delay = 200;
        setTimeout(function () {
          updateDrawerExpander();
        }, delay);
      });
    },
    unload() {
      this.$strategyList = null;
      this.$strategyListItem = null;
      T1.EventHub.unsubscribe(`action:drawer:load-${this.model.id}`);
      $(".editable-content", this.el).off();
    },
    performanceDataCompletedHandler(serializedData) {
      if (this.model.get("perfDataReady") !== true) {
        return;
      }
      this.model.set({
        perfDataReady: false
      });
      const $element = this.delayedRender({
        mode: this.viewMode,
        interval: this.reportInterval,
        force: false,
        data: serializedData || this.serialize()
      });
      this.bindFrequencyCap();
      this.bindImpressionCap();
      this.disableBidPrice();
      this.activateFrequencyCap();
      this.attachDatePicker($element);
      this.bindOptimizationType();
      T1.EventHub.publish("strategies:itemLoaded");
    },
    prepareT1Menu() {
      const {
        model: model,
        isVideoStrategy: isVideoStrategy,
        hasVideoPermission: hasVideoPermission,
        canEditStrategy: canEditStrategy,
        canViewStrategy: canViewStrategy,
        canViewChangeLog: canViewChangeLog,
        canEditWithV2: canEditWithV2
      } = this;
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Edit: 0,
        ViewSummary: 1,
        Duplicate: 2,
        ChangeLog: 3,
        ViewReadOnly: 4,
        CheckList: 5,
        Deactivate: 6,
        Activate: 7
      };
      this.$(menuConfig.menuTriggerEleSelector).off();
      if (canViewChangeLog === true) {
        menuItems[menuIndexRef.ChangeLog].visible = true;
      }
      if (canEditWithV2 && (canEditStrategy && !isVideoStrategy || canEditStrategy && hasVideoPermission)) {
        menuItems[menuIndexRef.Edit].visible = true;
        menuItems[menuIndexRef.Edit].url = `#strategy/edit/${model.id}`;
        menuItems[menuIndexRef.ViewSummary].visible = true;
        menuItems[menuIndexRef.ViewSummary].url = `#strategy/summary/${model.id}`;
        menuItems[menuIndexRef.Duplicate].visible = true;
        if (this.campaign && this.campaign.get("status") === "1") {
          if (model.get("status") === "0") {
            menuItems[menuIndexRef.Activate].visible = true;
          } else {
            menuItems[menuIndexRef.Deactivate].visible = true;
          }
        }
      } else if (canEditWithV2 && (canViewStrategy === true || isVideoStrategy)) {
        menuItems[menuIndexRef.ViewReadOnly].visible = true;
        menuItems[menuIndexRef.ViewReadOnly].url = `#strategy/readonly/${model.id}`;
      }
      T1Menu(menuConfig, this);
    },
    editStrategy(event) {
      this.stopEvent(event);
      T1.Location.set(`strategy/edit/${this.model.id}/details`);
    },
    activateFrequencyCap() {
      T1.Tooltip(this.el, {
        getExternalTip: true,
        tooltipEle: ".frequency-cap-icon",
        className: "w-StrategyFrequencyCap"
      });
      this.bindFrequencyCap();
    },
    renderViewMode(renderData) {
      const $element = this.$(".views");
      const mode = renderData.mode.replace("Mode", "");
      var html = partials[mode].render(renderData.data);
      if (T1.Utils.hasPolymer()) {
        $element.empty();
        Polymer.dom($element[0]).innerHTML = html;
      } else {
        $element.empty().html(html);
      }
      return $element;
    },
    getTimeStamp(date = new Date()) {
      return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
    },
    bindFrequencyCap() {
      const {
        el: $el,
        model: model
      } = this;
      const $frequencyEl = this.$(".budget");
      if (this.isProgrammaticGuaranteed) {
        if (this.viewMode === "spendMode" || this.viewMode === "impressionMode") {
          this.$(".frequency-cap-text").addClass("disabled-strategy-items");
        } else {
          this.$(".frequency-cap-text").removeClass("disabled-strategy-items");
        }
      } else {
        $(".editable-content", $frequencyEl).on("click", function () {
          function onFrequencyCapEdit() {
            const $groupEditEl = $(".w-GroupInlineEditForm", $frequencyEl);
            const freqType = model.get("frequency_optimization") === "1" ? "standard" : model.get("frequency_type");
            frequencyCap($groupEditEl, freqType);
            $el.unbind("inlineFormEdit.open", onFrequencyCapEdit);
          }
          $el.bind("inlineFormEdit.open", onFrequencyCapEdit);
        });
      }
    },
    bindImpressionCap() {
      const {
        el: $el,
        model: model
      } = this;
      const modeInterval = `.impression${this.reportInterval}`;
      const $impressionEl = $el.find(`.views ${modeInterval} .remaining`);
      if (this.isProgrammaticGuaranteed) {
        this.$(".impression-cap-programmatic").addClass("disabled-strategy-items");
      } else {
        $(".editable-content", $impressionEl).on("click", function () {
          function onImpressionCapEdit() {
            const $groupEditEl = $(".w-GroupInlineEditForm", $impressionEl);
            const impType = model.get("impression_pacing_type");
            impressionCap($groupEditEl, impType);
            $el.unbind("inlineFormEdit.open", onImpressionCapEdit);
          }
          $el.bind("inlineFormEdit.open", onImpressionCapEdit);
        });
      }
    },
    bindOptimizationType() {
      const $el = this.el;
      const $pacingEl = this.$(".remaining");
      if (this.isProgrammaticGuaranteed) {
        this.$(".spend-cap-programmatic").addClass("disabled-strategy-items");
      } else {
        $(".editable-content", $pacingEl).on("click", function () {
          function onPacingEdit() {
            var $groupEditEl = $(".w-GroupInlineEditForm", $pacingEl);
            pacingType($groupEditEl);
            $el.unbind("inlineFormEdit.open", onPacingEdit);
          }
          $el.bind("inlineFormEdit.open", onPacingEdit);
        });
      }
    },
    editElementBindings: {
      pacing_type: {
        type: "change",
        action: function ({
          currentTarget: currentTarget
        }) {
          const $target = $(currentTarget);
          const $pacingEl = this.$(".remaining");
          var $groupEditEl = $(".w-GroupInlineEditForm", $pacingEl);
          pacingType($groupEditEl);
          const $typeElem = $("select", $(".select:first", $groupEditEl))[0];
          const $inputElem = $("input", $(".input:first", $groupEditEl))[0];
          const $currencyElem = $("span", $(".input:first", $groupEditEl))[0];
          if ($target.val() === "monetary") {
            $($typeElem).attr("data-bind", "pacing_optimization_type");
            $($inputElem).attr("data-bind", "pacing_optimization_amount");
            $($inputElem).attr("alt", "number");
            $($currencyElem).css("display", "none");
          } else {
            $($typeElem).attr("data-bind", "pacing_type");
            $($inputElem).attr("data-bind", "pacing_amount");
            $($inputElem).attr("alt", "currency");
            $($currencyElem).css("display", "inline");
          }
          t1Masks.attach($("input", $groupEditEl));
          if ($target.val() === "asap") {
            $(`<div>${pacingASAPCaution}</div>`).dialog({
              autoOpen: true,
              modal: true,
              title: "<b></b>ASAP pacing confirmation",
              dialogClass: "w-PacingASAPAlert",
              buttons: [{
                text: "Confirm",
                class: "confirm",
                click: function () {
                  $(this).dialog("close");
                }
              }, {
                text: "Cancel",
                class: "cancel",
                click: function () {
                  $target.val("even").trigger("liszt:updated");
                  $(this).dialog("close");
                }
              }],
              close: function () {
                $(this).remove();
              }
            });
          }
        }
      },
      pacing_optimization_type: {
        type: "change",
        action: function ({
          currentTarget: currentTarget
        }) {
          const $target = $(currentTarget);
          const $pacingEl = this.$(".remaining");
          var $groupEditEl = $(".w-GroupInlineEditForm", $pacingEl);
          pacingType($groupEditEl);
          const $typeElem = $("select", $(".select:first", $groupEditEl))[0];
          const $inputElem = $("input", $(".input:first", $groupEditEl))[0];
          const $currencyElem = $("span", $(".input:first", $groupEditEl))[0];
          let currentValue = $($inputElem).val();
          if (currentViewMode === "spendMode") {
            if ($target.val() === "monetary") {
              $($typeElem).attr("data-bind", "pacing_optimization_type");
              $($inputElem).attr("data-bind", "pacing_optimization_amount");
              $($inputElem).attr("alt", "number");
              $($currencyElem).css("display", "none");
            } else {
              $($typeElem).attr("data-bind", "pacing_type");
              $($inputElem).attr("data-bind", "pacing_amount");
              $($inputElem).attr("alt", "currency");
              $($currencyElem).css("display", "inline");
            }
          }
          if (currentViewMode === "impressionMode") {
            if ($target.val() === "impression") {
              currentValue = currentValue === "" ? "" : parseFloat(currentValue);
              $($inputElem).val(currentValue);
              $($typeElem).attr("data-bind", "pacing_optimization_type");
              $($inputElem).attr("data-bind", "pacing_optimization_amount");
              $($inputElem).attr("alt", "number");
            } else {
              currentValue = currentValue === "" ? "" : parseInt(currentValue, 10);
              $($inputElem).val(currentValue);
              $($typeElem).attr("data-bind", "impression_pacing_type");
              $($inputElem).attr("data-bind", "impression_pacing_amount");
              $($inputElem).attr("alt", "integer");
            }
          }
          t1Masks.attach($("input", $groupEditEl));
          if ($target.val() === "asap") {
            $(`<div>${pacingASAPCaution}</div>`).dialog({
              autoOpen: true,
              modal: true,
              title: "<b></b>ASAP pacing confirmation",
              dialogClass: "w-PacingASAPAlert",
              buttons: [{
                text: "Confirm",
                class: "confirm",
                click: function () {
                  $(this).dialog("close");
                }
              }, {
                text: "Cancel",
                class: "cancel",
                click: function () {
                  $target.val(this.pacingOptimizationType).trigger("liszt:updated");
                  $(this).dialog("close");
                }
              }],
              close: function () {
                $(this).remove();
              }
            });
          }
        }
      },
      frequency_type: {
        type: "change",
        action: function ({
          currentTarget: currentTarget
        }) {
          const $target = $(currentTarget);
          var $groupEditEl = $target.closest(".w-GroupInlineEditForm");
          frequencyCap($groupEditEl, $target.val());
        }
      },
      impression_pacing_type: {
        type: "change",
        action: function ({
          currentTarget: currentTarget
        }) {
          const $target = $(currentTarget);
          const $pacingEl = this.$(".remaining");
          var $groupEditEl = $(".w-GroupInlineEditForm", $pacingEl);
          pacingType($groupEditEl);
          const $typeElem = $("select", $(".select:first", $groupEditEl))[0];
          const $inputElem = $("input", $(".input:first", $groupEditEl))[0];
          let currentValue = $($inputElem).val();
          if ($target.val() === "impression") {
            currentValue = currentValue === "" ? "" : parseFloat(currentValue);
            $($inputElem).val(currentValue);
            $($typeElem).attr("data-bind", "pacing_optimization_type");
            $($inputElem).attr("data-bind", "pacing_optimization_amount");
            $($inputElem).attr("alt", "number");
          } else {
            currentValue = currentValue === "" ? "" : parseInt(currentValue, 10);
            $($inputElem).val(currentValue);
            $($typeElem).attr("data-bind", "impression_pacing_type");
            $($inputElem).attr("data-bind", "impression_pacing_amount");
            $($inputElem).attr("alt", "integer");
          }
          t1Masks.attach($("input", $groupEditEl));
          if ($target.val() === "asap") {
            $(`<div>${impPacingASAPCaution}</div>`).dialog({
              autoOpen: true,
              modal: true,
              title: "<b></b>ASAP pacing confirmation",
              dialogClass: "w-PacingASAPAlert",
              buttons: [{
                text: "Confirm",
                class: "confirm",
                click: function () {
                  $(this).dialog("close");
                }
              }, {
                text: "Cancel",
                class: "cancel",
                click: function () {
                  $target.val("even").trigger("liszt:updated");
                  $(this).dialog("close");
                }
              }],
              close: function () {
                $(this).remove();
              }
            });
          }
        }
      }
    },
    delayedRender(renderData) {
      var $element = this.renderViewMode(renderData);
      this.prepareDataPoints();
      this.delegateEvents();
      return $element;
    },
    deactivateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(false);
    },
    activateHandler(evt) {
      this.preventDefault(evt);
      this.activationHandler(true);
    },
    activationHandler(toActivate) {
      const entityObj = {
        loader: this.loader(),
        status: toActivate === true ? "1" : "0",
        isProgrammaticGuaranteed: this.isProgrammaticGuaranteed,
        model: this.model,
        modalEle: this.$(".deactivate-strategy-modal")[0]
      };
      this.toActivate = status;
      Util.deactivateEntity(entityObj);
    },
    closeDeactivateStrategyModal() {
      this.$(".deactivate-strategy-modal")[0].hide();
    },
    deactivatePgStrategyConfirm() {
      const entityObj = {
        loader: this.loader(),
        status: this.toActivate === true ? "1" : "0",
        model: this.model,
        modalEle: this.$(".deactivate-strategy-modal")[0]
      };
      Util.onConfirmDeactivateEntity(entityObj);
    },
    showHealth(event) {
      $(document).trigger("click");
      this.stopEvent(event);
      this.layout.append(".healthDialogue", {
        module: "strategy",
        viewType: "health",
        options: {
          model: this.model
        }
      });
    },
    duplicateStrategy(evt) {
      $(document).trigger("click");
      this.stopEvent(evt);
      this.layout.append(".duplicationDialog", {
        module: "strategy",
        viewType: "duplicateStrategy",
        options: {
          model: this.model
        }
      });
    },
    viewChangeLog(evt) {
      $(document).trigger("click");
      this.stopEvent(evt);
      this.layout.append(".changeDialog", {
        module: "changelog",
        viewType: "dialog",
        options: {
          model: this.model,
          entityType: "strategy"
        }
      });
    },
    utils: {
      constructReportsObject(models) {
        var reports = {};
        while (models.length) {
          const report = models.shift();
          reports[report.mm_interval] = report;
        }
        return reports;
      },
      getDate(self, dateType, campaignKey, modelKey) {
        const date = self.model.get(dateType) !== "0" ? self.campaign.get(campaignKey) : self.model.get(modelKey);
        return Util.parseDate(date);
      },
      getTipDate(dateObj) {
        return `${Date.CultureInfo.abbreviatedMonthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;
      },
      comparePerformanceAndValue(performance, value, isROI, goalType) {
        const multiplier = .75;
        const multiplier2 = 1.25;
        let performanceAlert;
        if (isROI) {
          performanceAlert = performance < value / multiplier2;
        } else {
          if (goalType === "ctr" || goalType === "vcr" || goalType === "viewability_rate") {
            performanceAlert = performance < value * multiplier;
          } else {
            performanceAlert = performance > value * multiplier2;
          }
        }
        return performanceAlert;
      },
      getAlertStatus(performance, value, isROI, goalType) {
        return this.comparePerformanceAndValue(parseFloat(performance), parseFloat(value), isROI, goalType) ? "alert-item" : "";
      },
      setFrequencyAmount($element, frequencyAmount) {
        const amount = frequencyAmount === undefined || frequencyAmount === "0" ? "1" : String(frequencyAmount);
        $element.data("current-value", amount);
      }
    },
    doUpdate({
      interval: interval,
      viewMode: viewMode,
      mode: mode,
      goal: goal
    }) {
      this.reportInterval = interval;
      this.viewMode = viewMode || mode;
      this.currentGoalMode = goal;
      const $element = this.renderViewMode({
        mode: this.viewMode,
        interval: this.reportInterval,
        force: true,
        data: this.serialize()
      });
      this.disableBidPrice();
      this.bindImpressionCap();
      this.bindFrequencyCap();
      this.attachDatePicker($element);
      this.bindOptimizationType();
    },
    updateFromFilterValues(values) {
      requestAnimationFrame(this.doUpdate.bind(this, values));
    },
    serializeAdditionalInformation(interval, dataset) {
      let budgetInformation, pacingRatio, vcrValue;
      const {
        model: model,
        campaign: campaign
      } = this;
      const info = model.get("rpt_goal_monitoring");
      var startOffsetX = 5;
      var outPacingRange = 10;
      var coloredBarWidth = 95;
      var showImpCounts = true;
      var reports = this.utils.constructReportsObject(info.toJSON());
      var start = this.utils.getDate(this, "use_campaign_start", "initial_start_date", "start_date");
      var end = this.utils.getDate(this, "use_campaign_end", "end_date", "end_date");
      var loadDate = reports.CTD && reports.CTD.load_date ? Util.parseDate(reports.CTD.load_date) : new Date();
      var data = Util.compareDates(start, end, loadDate);
      const dayInMilliSeconds = 864e5;
      const hundredPct = 100;
      if (data !== undefined) {
        pacingRatio = data.pacingRatio;
        showImpCounts = data.showImpCounts;
        if (data.tipDate !== undefined) {
          dataset.tip_date = data.tipDate;
        }
      }
      dataset.imp_count_ydy = showImpCounts && reports["1"] && reports["1"].imp_count ? formatsRolledUpNumbersNoAbbreviation(reports["1"].imp_count).trim() : "--";
      dataset.days_remaining = Math.ceil((end.valueOf() - moment().startOf("day")) / dayInMilliSeconds);
      dataset.days_remaining = dataset.days_remaining > 0 ? dataset.days_remaining : 0;
      dataset.durationRatio = startOffsetX + Math.round((coloredBarWidth - startOffsetX) * (pacingRatio / hundredPct));
      dataset.displayPointer = pacingRatio === hundredPct || !dataset.durationRatio ? "none" : "block";
      if (!info.length) {
        dataset.coloredBarWidth = 0;
        return dataset;
      }
      const currentReportInterval = reports[interval] || {};
      function getCurrentReportValue(fieldName) {
        return currentReportInterval[fieldName] || "";
      }
      function getCurrentReportFormattedValueNoAbbreviation(fieldName) {
        return formatsRolledUpNumbersNoAbbreviation(getCurrentReportValue(fieldName)).trim();
      }
      const ctdReport = reports.CTD || {};
      dataset.daily_spend_to_pace = ctdReport.daily_spend_to_pace || "--";
      dataset.mm_goal_performance = getCurrentReportValue("mm_goal_performance");
      dataset.daily_spend_to_pace = ctdReport.daily_spend_to_pace || "--";
      dataset.total_conversion_count = getCurrentReportFormattedValueNoAbbreviation("total_conversion_count");
      dataset.clicks_count = getCurrentReportFormattedValueNoAbbreviation("clicks_count");
      dataset.imp_count = getCurrentReportFormattedValueNoAbbreviation("imp_count");
      dataset.totalSpendEcpa = getCurrentReportValue(effectivePerformanceField.cpa);
      dataset.totalSpendEcpc = getCurrentReportValue(effectivePerformanceField.cpc);
      dataset.totalSpendEcpm = getCurrentReportValue(totalSpendEcpm);
      dataset.totalSpendRoi = getCurrentReportValue(effectivePerformanceField.roi);
      dataset.mm_ctr = currentReportInterval.mm_ctr ? `${currentReportInterval.mm_ctr}%` : "--";
      dataset.useAllocPacing = this.useAllocPacing;
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      if (hasVcrImpression) {
        vcrValue = currentReportInterval.video_complete_rate_impression_based ? `${currentReportInterval.video_complete_rate_impression_based}%` : "";
      } else {
        vcrValue = currentReportInterval.video_complete_rate ? `${currentReportInterval.video_complete_rate}%` : "";
      }
      dataset.video_complete_rate = vcrValue;
      dataset.viewability_rate = currentReportInterval.viewability_rate ? `${currentReportInterval.viewability_rate}%` : "--";
      dataset.budgetInformation = budgetInformation = Util.getBudgetInformation(model);
      dataset.spent = budgetInformation.budget_spent || "";
      dataset.spend_remaining = budgetInformation.budget_remaining;
      dataset.total_budget = budgetInformation.total_budget;
      dataset.campaignEffectiveGoalValue = campaign.get("goal_value");
      dataset.strategyEffectiveGoalValue = model.get("goal_value");
      dataset.strategyEffectiveGoalValueRoi = model.get("roi_target");
      const fieldNameForCampaignGoalPerformance = effectivePerformanceField[campaign.get("goal_type")];
      dataset.campaignEffectiveGoalPerformance = getCurrentReportValue(fieldNameForCampaignGoalPerformance || totalSpendEcpm);
      dataset.strategyEffectiveGoalPerformance = dataset.mm_goal_performance;
      const isROI = model.get("goal_type") === "roi";
      dataset.campaignPerformanceAlert = this.utils.getAlertStatus(dataset.campaignEffectiveGoalPerformance, dataset.campaignEffectiveGoalValue, isROI, dataset.campaignTypeInfo);
      dataset.strategyPerformanceAlert = this.utils.getAlertStatus(dataset.strategyEffectiveGoalPerformance, dataset[isROI ? "strategyEffectiveGoalValueRoi" : "strategyEffectiveGoalValue"], isROI, dataset.goal_type);
      dataset.totalSpend = getCurrentReportValue(totalSpend);
      dataset.totalSpend_YDY = reports["1"] ? reports["1"][totalSpend] : "";
      dataset.budgetMeterLabel = T1.Utils.formatCurrency(dataset.spend_remaining.toString(), "", this.getCurrencyCode());
      dataset.daily_spend_to_pace = divideByZero(dataset.spend_remaining / dataset.days_remaining, true);
      dataset = populatePerformanceAlert(dataset);
      dataset.coloredBarWidth = budgetInformation.percnt_budget_spent ? startOffsetX + Math.round(budgetInformation.percnt_budget_spent * ((coloredBarWidth - startOffsetX) / hundredPct)) : 0;
      dataset.coloredBarWidth = dataset.coloredBarWidth > coloredBarWidth ? coloredBarWidth : dataset.coloredBarWidth;
      dataset.coloredBarClass = Math.abs(budgetInformation.percnt_budget_spent - pacingRatio) >= outPacingRange ? "out-pacing" : "pacing";
      return dataset;
    },
    serializeFrequencyCapInfo(dataset) {
      var model = this.model;
      var campaignDataset = model.get("campaign").toJSON();
      var advertiserDataset = campaignDataset.advertiser;
      var useT1Optimized = model.get("frequency_optimization");
      var frequencyType = model.get("frequency_type");
      var frequencyInterval = model.get("frequency_interval");
      var goalType = model.get("goal_type");
      var isRMXAPI = model.get("supply_type") === "RMX_API";
      const isNoLimit = frequencyType === "no-limit" || useT1Optimized !== "0";
      var campaignCap = useCampFreqCap(campaignDataset);
      var strategyCap = useT1Optimized === "0";
      var impType = model.get("impression_pacing_type");
      var isNoImpLimit = impType === "no-limit";
      dataset.frequency_interval = isRMXAPI ? "" : frequencyIntervalDisplayText[frequencyInterval];
      dataset.isBidPrice = goalType === "spend";
      dataset.isNoLimit = isNoLimit;
      dataset.frequency_cap_frequency_header = "Campaign Cap: ";
      dataset.frequency_cap_frequency_type = campaignDataset.frequency_type;
      dataset.frequency_cap_frequency_amount = campaignDataset.frequency_amount;
      dataset.frequency_cap_frequency_interval = campaignDataset.frequency_interval;
      dataset.campaignCap = campaignCap;
      if (campaignDataset.frequency_optimization === "1") {
        dataset.upstream_cap_text = `${dataset.frequency_cap_frequency_header}T1 Optimized`;
      } else if (dataset.frequency_cap_frequency_type !== "no-limit") {
        dataset.upstream_cap_text = `${dataset.frequency_cap_frequency_header + (dataset.frequency_cap_frequency_type.toLowerCase() === "asap" ? dataset.frequency_cap_frequency_type.toUpperCase() : toInitialCaps(dataset.frequency_cap_frequency_type))} ${dataset.frequency_cap_frequency_amount} per ${toInitialCaps(dataset.frequency_cap_frequency_interval)}`;
      }
      dataset.upstream_cap = campaignCap || campaignDataset.frequency_optimization === "1";
      if (advertiserDataset && advertiserDataset.frequency_type !== "no-limit") {
        dataset.upstream_cap_text = dataset.upstream_cap_text ? `${dataset.upstream_cap_text}<br />` : "";
        dataset.upstream_cap_text += `Advertiser Cap: ${advertiserDataset.frequency_type.toLowerCase() === "asap" ? advertiserDataset.frequency_type.toUpperCase() : toInitialCaps(advertiserDataset.frequency_type)} ${advertiserDataset.frequency_amount} per ${toInitialCaps(advertiserDataset.frequency_interval)}`;
        dataset.upstream_cap = true;
      }
      if (!dataset.upstream_cap_text) {
        dataset.upstream_cap = false;
      }
      if (isNoLimit || !strategyCap && campaignCap) {
        dataset.frequency_type = frequencyNoLimitText;
        dataset.frequencyNoCapClass = "frequency-no-cap";
      }
      if (isNoImpLimit) {
        dataset.impression_pacing_type = impressionNoLimitText;
        dataset.impNoCapClass = "impression-no-cap";
      }
      if (!campaignCap) {
        dataset.freq_cap_state = "frequency-cap-icon-hide";
      }
      dataset.frequency_optimization = useT1Optimized === "1";
    },
    serialize(interval) {
      var model = this.model;
      var dateFormat = "MM/dd/yy";
      var campaign = this.campaign;
      var dataset = model.toJSON();
      var filler = "--";
      var campaign_status = parseInt(campaign.get("status"), 10);
      var status = parseInt(dataset.status, 10);
      const isActive = Boolean(campaign_status && status);
      const statusTitle = isActive ? statusActiveTitle : statusInactiveTitle;
      const reportInterval = interval || this.reportInterval;
      var isCustom = this.chartable;
      const isBatch = model.get("supply_type") === "BATCH";
      const datasetGoalTypes = ["vcr", "ctr", "viewability_rate", "vcpm"];
      this.pacingOptimizationType = model.get("pacing_optimization_type");
      this.isProgrammaticGuaranteed = Util.isProgrammaticGuaranteed(this.campaign);
      currentViewMode = this.viewMode;
      dataset.viewIntervalOptions = false;
      dataset.isSpend = dataset.goal_type.toLowerCase() === "spend";
      const start_date = dataset.use_campaign_start !== "0" ? campaign.get("initial_start_date") : dataset.start_date;
      const end_date = dataset.use_campaign_end !== "0" ? campaign.get("end_date") : dataset.end_date;
      const parsedStartDate = Util.parseDate(start_date);
      const parsedEndDate = Util.parseDate(end_date);
      dataset.startDateAsString = parsedStartDate ? parsedStartDate.toString(dateFormat) : filler;
      dataset.endDateAsString = parsedEndDate ? parsedEndDate.toString(dateFormat) : filler;
      dataset.readOnlyTitle = this.canEdit ? "" : readOnlyTitle;
      dataset.currentStatusTitle = statusTitle;
      dataset.status_class = function (target) {
        var inactiveClass = "inactive";
        const result = !isActive ? inactiveClass : "";
        if (target) {
          $(target).toggleClass(inactiveClass, !isActive);
        }
        return result;
      };
      dataset.statusTitle = function (target) {
        $(target).attr("title", statusTitle);
        return statusTitle;
      };
      dataset.isActive = isActive;
      dataset.typeInfo = this.currentGoalMode === "campaignGoal" ? campaign.get("goal_type") : dataset.goal_type;
      dataset.strategyTypeInfo = dataset.goal_type === "roi" ? "ROI" : dataset.goal_type;
      dataset.campaignTypeInfo = campaign.get("goal_type") === "roi" ? "ROI" : campaign.get("goal_type");
      dataset.chart_class = isCustom ? "" : "disabled";
      dataset.chartActionTitle = addToChartTitle;
      this.serializeAdditionalInformation(reportInterval, dataset);
      dataset.hasFrequencyCap = !isBatch;
      this.serializeFrequencyCapInfo(dataset);
      dataset.hasBatchPacing = !isBatch;
      dataset.isBidPriceEditable = !isBatch || model.get("goal_type").toLowerCase() === "spend";
      dataset.defaultText = function () {
        return function (text) {
          var value = Hogan.compile(text).render(this);
          return value === "" ? "&nbsp;--" : value;
        };
      };
      dataset.pacing_interval_value = dataset.pacing_interval;
      dataset.pacing_interval = frequencyIntervalDisplayText[dataset.pacing_interval] || "";
      dataset.impression_pacing_interval = frequencyIntervalDisplayText[dataset.impression_pacing_interval] || "";
      dataset.impression_pacing_amount = formatsRolledUpNumbersNoAbbreviation(dataset.impression_pacing_amount).trim();
      dataset.hasMaxBid = dataset.use_optimization === "1";
      dataset.isStrategyROI = dataset.goal_type === "roi";
      dataset.roi_target = `${Util.parseNumberToWhole(dataset.roi_target)}:1`;
      dataset.asDecimalCurrency = this.asDecimalCurrency;
      dataset.asCurrency = this.asCurrency;
      dataset.isProgrammaticGuaranteed = this.isProgrammaticGuaranteed;
      if (dataset.strategyTypeInfo === "ROI" && dataset.strategyEffectiveGoalPerformance) {
        dataset.isTypeROI = true;
      }
      dataset.showStrategyEdit = this.strategyEditable;
      dataset.hasPacingOptimizationType = Boolean(this.pacingOptimizationType);
      dataset.hasMonetaryPacing = this.pacingOptimizationType === "monetary";
      dataset.pacing_optimization_amount = `${model.get("pacing_optimization_amount")}%`;
      dataset.pacing_optimization_type = pacingOptimizationText;
      if (dataset.frequency_optimization) {
        dataset.frequency_type = "T1 Optimized";
        dataset.frequency_amount = "";
        dataset.frequency_interval = "";
      }
      dataset.enableTrackingSupplyTypeFeatureFlag = model.get("supply_type") === "RMX_API";
      if (datasetGoalTypes.includes(dataset.goal_type)) {
        const maxLength = 2;
        if (dataset.goal_type !== "vcpm") {
          dataset.goal_type_is_percentage = true;
          dataset.strategy_goal_value_is_percentage = true;
        }
        if (dataset.goal_value !== undefined && dataset.goal_value.length > maxLength) {
          dataset.goal_value = dataset.goal_value.substring(0, dataset.goal_value.length - maxLength);
        }
        if (dataset.goal_type === "ctr") {
          if (this.campaign.get("goal_type") === "ctr") {
            dataset.strategyEffectiveGoalPerformance = dataset.mm_ctr.replace(/%/g, "");
            dataset.strategy_goal_value_is_percentage = !isNaN(parseFloat(dataset.mm_ctr));
          }
        }
      } else {
        dataset.goal_type_is_percentage = false;
        dataset.strategy_goal_value_is_percentage = false;
      }
      if (dataset.campaignTypeInfo === "vcr" || dataset.campaignTypeInfo === "ctr" || dataset.campaignTypeInfo === "viewability_rate") {
        dataset.campaign_goal_value_is_percentage = true;
      } else {
        dataset.campaign_goal_value_is_percentage = false;
      }
      const spaces = (dataset.name.match(/\s/g) || []).length;
      const minSpaces = 2;
      dataset.wordBreakName = !(spaces > minSpaces);
      dataset.goal_type = goalTypeText[dataset.goal_type];
      dataset.strategyTypeInfo = goalTypeText[dataset.strategyTypeInfo] || dataset.strategyTypeInfo;
      dataset.campaignTypeInfo = goalTypeText[dataset.campaignTypeInfo] || dataset.campaignTypeInfo;
      if (dataset.enableTrackingSupplyTypeFeatureFlag) {
        dataset.isStrategyROI = false;
        dataset.goal_type = "tracker";
        dataset.goal_value = "";
        dataset.strategyEffectiveGoalPerformance = filler;
        dataset.frequency_type = filler;
        dataset.pacing_amount = filler;
        dataset.frequency_amount = "";
      }
      return dataset;
    }
  });
  return T1Form.InlineEdit(ItemView, dropdownList);
});
