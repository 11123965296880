define('modules/T1App/myApps/views/grid',["jQuery", "Underscore", "Mustache", "T1", "T1View", "T1Permissions", "text!modules/T1App/myApps/templates/grid.html", "text!modules/T1App/myApps/templates/terms.html", "T1Menu", "models/userPreferences", "models/sessionUser", "../../models/t1AppViews", "collections/organizations", "models/t1AppTerms", "collections/t1AppTerms", "models/t1AppAcceptedTerms", "collections/t1AppAcceptedTerms", "utils/T1AppUtils", "T1Tooltip", "jQueryUI", "T1Layout", "T1Model", "T1Preferences", "T1Signature"], function ($, _, Mustache, T1, T1View, T1Permissions, template, termsTemplate, T1Menu, UserPreferences, User, t1AppViews, Organizations, T1AppTerms, AppTerms, T1AppAcceptedTerms, AppAcceptedTerms, T1AppUtils) {
  "use strict";

  function generateAbsoluteURL(relativeURL) {
    var a = document.createElement("a");
    var url;
    a.href = relativeURL;
    url = a.href;
    return url;
  }
  return T1View.extend({
    template: template,
    partials: {
      tmodal: termsTemplate
    },
    eventhubEvents: {
      "myApps.gridDataChange": function () {
        this.updateGrid(this.model.get("gridData"));
      }
    },
    events: {
      "click .settings-icon": "showOrApplySettingsMenu",
      "click #terms-link": "showTerms",
      "click #terms-link-alt": "showTerms",
      "click .terms-checkbox": "handleItemChecked",
      "click .close-terms-modal": "closeTermsModal",
      "click .terms-modal .launch": "launchAppModal",
      "click .reject-all": "rejectMoreLink"
    },
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Launch App",
          handler: "launchApp",
          visible: false
        }, {
          label: "Edit App",
          handler: "editApp",
          visible: false
        }]
      }]
    },
    initialize: function (args) {
      this.model = args.model;
      this.canSave = true;
      this.lastUsed();
      this.lastUsedLabs();
    },
    load: function () {
      const $el = this.el;
      T1AppUtils.fetchTerms();
      T1AppUtils.fetchAcceptedTerms();
      this.render().then(() => {
        this.grid = $el.find("#data-grid")[0];
        this.$noDataGrid = $el.find(".no-data-grid");
        this.updateGrid();
        T1.EventHub.publish("complete:layoutLoad");
      });
    },
    updateGrid: function (data) {
      if (data) {
        this.grid.data = data;
        if (!data.isInternalUser) {
          this.el.find('strand-grid-column[field="dev_team"]').attr("width", "32%");
          const appTypeColumn = this.grid.getColumnByField("app_type");
          this.grid.removeColumn(appTypeColumn);
        }
        this.$noDataGrid.addClass("no-display");
      } else {
        this.$noDataGrid.removeClass("no-display");
      }
    },
    handleItemChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var self = this;
      var $el = $(self.el);
      var $accept_terms = $el.find("#terms-button");
      var notChecked = 0;
      $(".terms-checkbox", $el).each(function () {
        if ($(this).attr("checked") !== "checked") {
          notChecked++;
        }
      });
      if (notChecked === 0) {
        $accept_terms.removeAttr("disabled");
      } else {
        $accept_terms.attr("disabled", true);
      }
    },
    rejectMoreLink: function (e) {
      var appID = $(e.currentTarget).data("app-id");
      T1.Location.set(`appstore/edit/${appID}/meta`);
    },
    getTermsInfo: function (data, appId) {
      var newdata = false;
      var largestId = 0;
      for (var i in data) {
        if (data[i].app_id === appId && data[i].id > largestId) {
          largestId = data[i].id;
          newdata = data[i];
        }
      }
      return newdata;
    },
    showTerms: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $ct = $(e.currentTarget).attr("id");
      var url = $(`#${$ct}`).data("id");
      var fullUrl = T1AppUtils.getEnv() + url;
      window.open(fullUrl, "_blank");
    },
    closeTermsModal: function (tmodal) {
      tmodal.preventDefault();
      $(".terms-modal")[0].hide();
    },
    lastUsed: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "apps"], ["view", "list"]]));
      this.prefLastUsed = T1.Preferences.create.call(this, signature, UserPreferences);
      this.prefLastUsed.mark(["lastUsed"]);
    },
    lastUsedLabs: function () {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "labs"], ["view", "list"]]));
      this.prefLastUsedLab = T1.Preferences.create.call(this, signature, UserPreferences);
      this.prefLastUsedLab.mark(["lastUsed"]);
    },
    launchAppModal: function (event) {
      var id;
      var self = this;
      var $el = $(self.el);
      var notChecked = 0;
      $(".terms-checkbox", $el).each(function () {
        if ($(this).attr("checked") !== "checked") {
          notChecked++;
        }
      });
      id = $(event.currentTarget).attr("data-app-id");
      $(".app-loader-holder", $el).show();
      if (notChecked === 0) {
        $(".terms-modal")[0].hide();
        T1AppUtils.appAcceptedTerms.acceptTerms(id).then(function () {
          T1AppUtils.appAcceptedTerms.fetch({
            success: function (data) {
              if (data.meta.status === "success") {
                T1AppUtils.appAcceptedTerms.set(data.data);
              }
            }
          }).then(function () {
            $(".app-loader-holder", $el).hide();
            self.launchApp(event, id);
          });
        });
      }
    },
    checkLabAgreement: function (allTerms) {
      var user = User.getUser();
      var userId = user.get("id");
      var currentTerms;
      for (var i in allTerms) {
        if (allTerms.hasOwnProperty(i)) {
          currentTerms = allTerms[i];
          if (currentTerms.lab === 1 && currentTerms.user_id === parseInt(userId)) {
            return true;
          }
        }
      }
      return false;
    },
    checkValidTerms: function (appId, termsId, acceptedTerms, fee, billingType) {
      var user = User.getUser();
      var userId = user.get("id");
      var validdata = [];
      var currentTerms, valid;
      for (var i in acceptedTerms) {
        if (acceptedTerms.hasOwnProperty(i)) {
          valid = true;
          currentTerms = acceptedTerms[i];
          if (currentTerms.app_id !== appId) {
            valid = false;
          }
          if (currentTerms.term_id !== termsId && termsId !== 0) {
            valid = false;
          }
          if (currentTerms.user_id !== parseInt(userId)) {
            valid = false;
          }
          if (currentTerms.fee !== fee && currentTerms.fee) {
            valid = false;
          }
          if (currentTerms.billing_type !== billingType) {
            valid = false;
          }
          if (valid) {
            validdata = currentTerms;
            return validdata;
          }
        }
      }
      return false;
    },
    getSessionID: function () {
      var self = this;
      self.model.bind("change:T1AppsSessionID", function () {
        self.model.unbind("change:T1AppsSessionID");
        self.load();
      });
      self.model.loadClonedSessionID();
    },
    editApp: function (e) {
      var $currentTarget = $(e.currentTarget);
      var id = $currentTarget.closest("dl").data("id");
      T1.Location.set(`#appstore/edit/${id}/meta`);
    },
    launchApp: function (e, di) {
      var self = this;
      var el = $(this.el);
      var model = this.model;
      var $currentTarget = $(e.currentTarget);
      var id = di ? di : e.id || $currentTarget.closest("dl").data("id");
      var appInfo = this.getAppInfo(model.get("gridData"), parseInt(id));
      var appsApiBase, checkLab, createAppView, lastUsedList, orgId, render, termId, terms, unique, url, validTerms;
      var user = User.getUser();
      var termsData = [];
      var allTerms = T1AppUtils.appTerms.attributes;
      var allAcceptedTerms = T1AppUtils.appAcceptedTerms.attributes;
      terms = T1AppUtils.getTermsInfo(allTerms, appInfo.id);
      termId = terms ? terms.id : 0;
      validTerms = T1AppUtils.checkValidTerms(appInfo.id, termId, allAcceptedTerms, appInfo.fee, appInfo.billing_type);
      checkLab = T1AppUtils.checkLabAgreement(allAcceptedTerms);
      e.preventDefault();
      e.stopPropagation();
      if (appInfo.lab === 0) {
        if (!terms && appInfo.billing_type === "none" || validTerms) {
          lastUsedList = self.prefLastUsed.get("lastUsed") || "";
          lastUsedList = `${id}, ${lastUsedList}`;
          lastUsedList = lastUsedList.split(", ");
          unique = $.grep(lastUsedList, function (item, index) {
            return index === $.inArray(item, lastUsedList);
          });
          unique = unique.join(", ");
          self.lastUsed = unique;
          self.prefLastUsed.save();
          T1.Location.set(`appstore/launch/${appInfo.json_name}`);
        } else {
          termsData.id = appInfo.id;
          termsData.app_id = appInfo.json_name;
          termsData.env = T1AppUtils.getEnv();
          termsData.app = true;
          if (appInfo.billing_type !== "none") {
            termsData.billing = true;
            termsData.checkboxBilling = true;
            termsData.title = `${appInfo.name}   Billing Agreement`;
            termsData.text = `You are attempting to access ${appInfo.name} ( “Application”), which incures a` + ` fee of $${appInfo.fee.toFixed(2)} CPM (“Fee”). By clicking below, you agree to the` + `fee and authorize MediaMath Inc. to invoice you for the fee`;
            termsData.button = "Accept Pricing";
          } else if (terms) {
            termsData.terms = true;
            termsData.checkboxTerms = true;
            termsData.title = `${appInfo.name}   Terms & Conditions`;
            termsData.text = `By using ${appInfo.name}, you agree to be bound by the`;
            termsData.link = `agreements/terms/${terms.upload}`;
            termsData.button = "Accept Terms";
          }
          if (appInfo.billing_type !== "none" && terms) {
            termsData.checkboxTerms = true;
            termsData.checkboxBilling = true;
            termsData.bothTerms = true;
            termsData.link = `agreements/terms/${terms.upload}`;
          }
          render = Mustache.to_html(termsTemplate, termsData, this.partials);
          if (Polymer && Polymer.dom) {
            Polymer.dom(Polymer.dom(el[0]).querySelector(".terms-modal")).innerHTML = render;
          } else {
            el.find(".terms-modal").html(render);
          }
          $(".terms-modal")[0].show();
        }
      } else if (appInfo.lab === 1) {
        if (validTerms || checkLab && appInfo.billing_type === "none") {
          lastUsedList = self.prefLastUsedLab.get("lastUsed") || "";
          lastUsedList = `${id}, ${lastUsedList}`;
          lastUsedList = lastUsedList.split(", ");
          unique = $.grep(lastUsedList, function (item, index) {
            return index === $.inArray(item, lastUsedList);
          });
          unique = unique.join(", ");
          self.lastUsed = unique;
          self.prefLastUsedLab.save();
          if (appInfo.external === 1) {
            appsApiBase = COMPASS_ENV.APP_BASE + appInfo.api_version;
            appsApiBase = generateAbsoluteURL(appsApiBase);
            appsApiBase = encodeURIComponent(`${appsApiBase}/`);
            Organizations.getOrganizations().getSelected().then(function (oid) {
              orgId = oid;
            });
            createAppView = new t1AppViews();
            createAppView.createAppView(user.get("id"), appInfo.id, orgId, "0.0.0");
            url = `${appInfo.url}?session_id=${T1.SESSION_ID}&api_base=${appsApiBase}&org_id=${orgId}`;
            window.open(url, "_blank");
          } else {
            T1.Location.set(`appstore/launch-labs/${appInfo.json_name}`);
          }
        } else {
          termsData.id = appInfo.id;
          termsData.app_id = appInfo.json_name;
          termsData.env = T1AppUtils.getEnv();
          termsData.lab = true;
          if (!checkLab) {
            termsData.title = "Labs   Terms & Conditions";
            termsData.terms = true;
            termsData.checkboxTerms = true;
            termsData.button = "Accept Terms";
          }
          if (appInfo.billing_type !== "none") {
            termsData.billing = true;
            termsData.checkboxBilling = true;
            termsData.title = `${appInfo.name}   Billing Agreement`;
            termsData.text = `You are attempting to access ${appInfo.name} ( “Application”), which incures a` + ` fee of $${appInfo.fee.toFixed(2)} CPM (“Fee”). By clicking below, you agree to the` + ` fee and authorize MediaMath Inc. to invoice you for the fee`;
            termsData.button = "Accept Pricing";
          }
          if (!checkLab && appInfo.billing_type !== "none") {
            termsData.bothTerms = true;
          }
          render = Mustache.to_html(termsTemplate, termsData, this.partials);
          if (Polymer && Polymer.dom) {
            Polymer.dom(Polymer.dom(el[0]).querySelector(".terms-modal")).innerHTML = render;
          } else {
            el.find(".terms-modal").html(render);
          }
          $(".terms-modal")[0].show();
        }
      }
    },
    showOrApplySettingsMenu: function (eventOrTarget) {
      var appInfo, devTeamList, id;
      var model = this.model;
      var $menuTarget = eventOrTarget.currentTarget ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var user = User.getUser().toJSON();
      var isGlobalAdmin = user.type === "INTERNAL" && user.role === "ADMIN" && user.scope === "GLOBAL";
      var isReporter = user.role === "REPORTER";
      var menuConfig = $.extend(true, {}, this.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Launch: 0,
        Edit: 1
      };
      if ($menuTarget) {
        id = $menuTarget[0].id;
        appInfo = this.getAppInfo(model.get("gridData"), parseInt(id));
        devTeamList = appInfo.dev_ids;
        if (isGlobalAdmin || $.inArray(parseInt(user.id), devTeamList) > -1) {
          menuItems[menuIndexRef.Launch].visible = true;
          if (!isReporter) {
            menuItems[menuIndexRef.Edit].visible = true;
          }
        }
        menuConfig.dataID = `data-id=${id}`;
        menuConfig.menuTriggerEleSelector = `strand-icon[id="${id}"]`;
      }
      T1Menu(menuConfig, this);
      if (eventOrTarget.currentTarget) {
        $menuTarget.trigger("click");
      }
    },
    getAppInfo: function (data, appId) {
      var newdata = [];
      for (var i in data) {
        if (data[i].id === appId) {
          newdata = data[i];
          return newdata;
        }
      }
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      if ($el[0] && Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
