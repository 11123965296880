define('collections/customBrainSelections',["jQuery", "Underscore", "T1", "T1Collection", "models/customBrainSelection"], function ($, _, T1, T1Collection, CustomBrainSelection) {
  T1.Models.CustomBrainSelections = T1Collection.extend({
    model: CustomBrainSelection,
    urlString: "campaigns",
    action: "custom_brain_selections",
    fixture: "-custom_brain_selections",
    url: function () {
      var originalString = this.urlString;
      var url;
      if (this.id === undefined) {
        throw new Error("Campaign id needs to be set in id property of collection to construct URL");
      }
      this.urlString = originalString + "/" + this.id + "/" + this.action;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    buildPostObj: function () {
      var resultObject = {};
      var counter = 1;
      this.each(function (model) {
        var assigned = model.get("assigned");
        resultObject["custom_brain_selections." + counter + ".selection_id"] = model.get("selection_id");
        resultObject["custom_brain_selections." + counter + ".selection_type"] = model.get("selection_type");
        counter++;
      });
      return resultObject;
    },
    saveCustomBrainSelections: function (options, success, conflict) {
      var additionalOptions = $.extend(true, {
        data: this.buildPostObj()
      }, options || {});
      this.action = this.action + "/bulk";
      this.save(additionalOptions, {
        success: success,
        conflict: conflict
      });
    }
  });
  return T1.Models.CustomBrainSelections;
});
