define('modules/reporting/campaigns/technology/views/chart',["jQuery", "Underscore", "T1", "T1Layout", "T1Chart", "T1ChartBenchmarkOptions", "text!templates/chart_panel.html", "text!../templates/chart_ttip_daily.html", "text!../templates/chart_ttip_summary.html", "text!../../templates/chart_title_box.html", "text!../templates/chart_metric_controls.html", "text!../../templates/select_metric.html"], function ($, _, T1, T1Layout, T1Chart, BenchmarkOptions, template, ttipTemplateDaily, ttipTemplateSummary, titleBox, metricControls, selectMetric) {
  "use strict";

  var chartOptions = {
    template: template,
    xAxisLabelRotation: 0,
    xAxisLabelTextAnchor: "middle",
    xAxisLabelOffsetY: -42,
    maxW: 10,
    selectKey: null,
    padMatrix: {
      bottom: 55,
      left: 130,
      right: 130,
      top: 25
    },
    partials: {
      metric_controls: metricControls,
      select_metric: selectMetric,
      title_box: titleBox
    },
    xKey: "",
    yKey: "",
    xAxisLabel: "",
    yAxisLabel: "",
    xAxisSubLabel: "",
    aggregateBy: "strategy_id",
    dimmedColor: "#e5e5e5",
    dim1: "strategy",
    dim2: null,
    dimensionKey: "strategy_id",
    dimensionName: "strategy_name",
    chartView: "series",
    section: "technology",
    isCTD: false,
    events: {
      "click .metric-icon": "switchGraph",
      "click .metric-icon2": "switchGraph",
      "click circle.circle": "toggleHighlighted",
      "click g.bar": "toggleHighlighted",
      "mouseout g.bar": "barOut",
      "mouseover g.bar": "barOver"
    },
    eventhubEvents: {
      "change:campaignBenchmarkOn": "toggleBenchmark",
      "change:dimensionItems:device_technology": function () {
        if (this.subSection !== "summary") {
          this.initDataReady = true;
          this.reload();
        }
      },
      "change:filteredOutItems": "updateChart",
      "change:hiddenCount": "updateChart",
      "change:highlightedItem": "chart",
      "sort:grid:device_technology": function () {
        if (this.subSection === "summary") {
          this.reload();
        }
      }
    },
    switchGraph: function (event) {
      var $el = this.el;
      var $clickedIcon = $(event.currentTarget);
      var $icons = $el.find(".metric-icon").add($el.find(".metric-icon2"));
      var publish = T1.EventHub.publish;
      event.stopPropagation();
      if (!$clickedIcon.hasClass("selected")) {
        const {
          load: load
        } = this;
        $icons.toggleClass("selected");
        if (this.subSection === "daily") {
          this.subSection = "summary";
          publish("select:chart", "summary");
        } else {
          this.subSection = "daily";
          publish("select:chart", "daily");
        }
        load.call(this);
      }
    },
    prepareRenderData: function () {
      var reportModel = this.reportModel;
      var timeSeriesInfo = this.timeSeriesInfo;
      var focus = reportModel.get("focus") || "all";
      var dim1Info = reportModel.get("dimensionInfo");
      var dim2Info = reportModel.get("dim2Info");
      var collection = $.extend({}, this.dim1FilteredCollection);
      var nestedCollection = $.extend({}, this.dim1FilteredNestedCollection);
      var metricKey;
      var isCTD = this.isCTD = reportModel.get("isCTD");
      this.subSection = isCTD ? "summary" : this.subSection;
      if (this.subSection === "daily") {
        this.aggregateBy = dim1Info.key;
        this.chartView = "series";
        this.collection = nestedCollection;
        this.maxW = 10;
        this.padMatrix = {
          bottom: 55,
          left: 130,
          right: 130,
          top: 25
        };
        this.xAxisLabel = timeSeriesInfo.xAxisLabel;
        this.xAxisLabelOffsetY = -42;
        this.xAxisLabelRotation = 0;
        this.xAxisLabelTextAnchor = "middle";
        this.xKey = timeSeriesInfo.xKey;
      } else {
        this.aggregateBy = null;
        this.chartView = "summary";
        this.collection = collection;
        this.maxW = 250;
        this.padMatrix = {
          bottom: 116,
          left: 130,
          right: 130,
          top: 25
        };
        this.xAxisLabel = dim1Info.xAxisLabel;
        this.xAxisLabelOffsetY = -110;
        this.xAxisLabelRotation = -45;
        this.xAxisLabelTextAnchor = "end";
        this.xKey = dim1Info.dimension;
      }
      this.dim1 = dim1Info.text;
      this.dimensionKey = dim1Info.key;
      this.dimensionName = dim1Info.dimension;
      this.dim2 = dim2Info && dim2Info.text;
      this.yKey = reportModel.get("metric");
      metricKey = this.reportMetaObj.metrics[focus];
      this.yKey = metricKey[this.yKey] ? this.yKey : this.reportMetaObj.metricOptions[0].value;
      this.yAxisLabel = metricKey[this.yKey].text;
    },
    barOver: function (event) {
      this.overLabel = this.wChartBody.find(`#xlb${d3.select(event.currentTarget)[0][0].__data__.x}`);
      this.overLabelWasVisible = this.overLabel.css("visibility") === "visible";
      this.overLabel.css("visibility", "visible");
    },
    barOut: function () {
      if (!this.overLabelWasVisible) {
        this.overLabel.css("visibility", "hidden");
      }
    },
    toggleHighlighted: function (event) {
      var id = d3.select(event.currentTarget)[0][0].__data__[this.dimensionKey];
      var newID = id && id.replace(/[']/g, "");
      var highlighted = this.reportModel.get("highlightedItem");
      event.stopPropagation();
      if (this.subSection === "daily") {
        this.reportModel.setHighlightedItem({
          from: "line",
          id: highlighted && highlighted.id === newID ? null : newID,
          type: newID ? "strategy" : "campaign"
        });
      } else {
        this.reportModel.setHighlightedItem({
          from: "bar",
          id: highlighted && highlighted.id === newID ? null : newID,
          type: "strategy"
        });
      }
    },
    setupHighlight: function () {
      var i, id, obj;
      var isDaily = this.subSection === "daily";
      var reportMeta = this.reportMeta;
      var reportModel = this.reportModel;
      var highlightedItem = reportModel.get("highlightedItem");
      var campaignType = highlightedItem && highlightedItem.type === "campaign";
      var chartColors = reportMeta.get("chartColors");
      var colorData = reportModel.get("colorData");
      var highlightedIndex = -1;
      var highlightedID = highlightedItem ? highlightedItem.id : null;
      var allColor = !highlightedItem || highlightedItem.id === null;
      var len = this.dataStack[0].length;
      if (len > 0) {
        if (isDaily) {
          len = this.dataStack.length;
          for (i = 0; i < len; i++) {
            obj = this.dataStack[i][0];
            id = obj[this.dimensionKey];
            id = id && id.replace(/[']/g, "");
            if (allColor || !campaignType && id === highlightedID) {
              if (id === highlightedID) {
                highlightedIndex = i;
              }
              obj.color = chartColors[colorData[id]];
            } else {
              obj.color = this.dimmedColor;
            }
          }
        } else {
          for (i = 0; i < len; i++) {
            obj = this.dataStack[0][i];
            id = obj[this.dimensionKey];
            id = id && id.replace(/[']/g, "");
            if (allColor || !campaignType && id === highlightedID) {
              this.highlightedX = obj.x;
              obj.color = chartColors[colorData[id]];
            } else {
              obj.color = this.dimmedColor;
            }
          }
        }
      }
      if (isDaily) {
        if (highlightedIndex > -1) {
          this.dataStack.push(this.dataStack.splice(highlightedIndex, 1)[0]);
        }
      } else {
        if (!highlightedItem || !highlightedItem.id) {
          this.highlightedX = -1;
        }
      }
      return this;
    },
    graphGoalVsActual: function () {
      var isDaily = this.subSection === "daily";
      var campaign = this.campaign;
      var goalValue = this.getGoalValue();
      var goalType = campaign.get("goal_type");
      var showGoal = !this.mainModel.get("multiCampaigns") && this.matchedMetricKeyToGoalType(this.yKey, goalType);
      var campaignValue = this.campaignModel.get(this.yKey) || 0;
      var campaignData = this.getCampaignData();
      var lastCampaignData = campaignData.length > 1 ? Number(campaignData[campaignData.length - 1][this.yKey]) : 0;
      var difference = isDaily ? lastCampaignData - goalValue : campaignValue - goalValue;
      const CROWD_LIMIT = 2;
      if (showGoal) {
        this.graphBenchMark({
          dash: "4,3",
          difference: difference,
          mark: goalValue,
          name: "goal",
          series: isDaily
        });
      }
      if (isDaily) {
        this.graphBenchMarkSeries({
          circleRadius: 2.5,
          circleStrokeColor: "#fff",
          color: "#000",
          data: campaignData,
          goalValue: goalValue,
          hideCircles: this.crowdLevel === CROWD_LIMIT,
          name: "campaign",
          noGoal: !showGoal
        });
      } else {
        this.graphBenchMark({
          circleRadius: 2.5,
          color: "#fff",
          difference: showGoal ? difference : 0,
          hideCircles: false,
          mark: campaignValue,
          name: "campaign",
          noGoal: !showGoal
        });
      }
      this.setBenchmarkVisibility();
      return this;
    },
    graphYValues: function () {
      const CROWD_LIMIT = 2;
      if (this.subSection === "daily") {
        return this.graphYAxisLabels(true).graphLines({
          circleRadius: 2.5,
          circleStrokeColor: "#fff",
          hideCircles: this.crowdLevel === CROWD_LIMIT
        }).graphGoalVsActual();
      }
      return this.graphYAxisLabels(true).graphBars(this).graphGoalVsActual();
    },
    getCampaignData: function () {
      return this.campaignNestedCollection ? this.campaignNestedCollection.toJSON() : [];
    },
    getMaxBenchmarks: function () {
      var campaignData, maxObj;
      var self = this;
      var goalValue = this.getGoalValue();
      var maxValue = this.reportModel.get("campaignBenchmarkOn");
      if (this.subSection === "daily") {
        campaignData = this.getCampaignData();
        maxObj = _.max(campaignData, function (campaignDatum) {
          return Number(campaignDatum[self.yKey]);
        });
        return {
          campaignGoalValue: goalValue,
          campaignMaxValue: maxValue && maxObj ? Number(maxObj[this.yKey]) : 0
        };
      }
      return {
        campaignGoalValue: goalValue,
        campaignMaxValue: maxValue ? Number(this.campaignModel.get(this.yKey)) || 0 : 0
      };
    },
    initialize: function (args) {
      var models = args.models;
      this.mainMeta = models.mainMeta;
      this.mainModel = models.mainModel;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
      this.subSection = this.mainModel.get("technologySubSection");
      this.campaign = models.campaign;
      this.campaignModel = args.dataGroup.campaignModel;
      this.campaignNestedCollection = args.dataGroup.campaignNestedCollection;
      this.dim1FilteredCollection = args.dataGroup.dim1FilteredCollection;
      this.dim1FilteredNestedCollection = args.dataGroup.dim1FilteredNestedCollection;
      const timeSeriesInfo = models.timeSeries.daily;
      this.timeSeriesInfo = timeSeriesInfo;
      this.timeSeriesLabel = timeSeriesInfo.label;
      if (timeSeriesInfo.chartDateFormat) {
        this.dateformat = timeSeriesInfo.chartDateFormat;
      }
      this.xAxisLabelAdjustment = function () {
        return 0;
      };
      this.toolTipDateFormatter = {
        Daily: function (str) {
          var dateObj = T1.Date.parse(str);
          return dateObj && dateObj.toString("ddd, MMM d, yyyy");
        }
      };
    },
    activateToolTips: function () {
      var $el = this.el;
      var self = this;
      var mainModel = this.mainModel;
      var campaignCount = mainModel.get("campaignCount");
      var campaignTitle = campaignCount > 1 ? mainModel.get("multiCampaignsLabel") : this.campaign.get("name");
      $el.on("onChartComplete", function () {
        if (self.subSection === "daily") {
          self.attachTooltips({
            header: function () {
              return self.yAxisLabel;
            },
            paths: ["circle.circle"],
            renderInfo: function (data) {
              var formatter = self.toolTipDateFormatter[self.timeSeriesLabel];
              return formatter(data.xLabel);
            },
            renderKey: function (data) {
              return data[self.dimensionName] || campaignTitle;
            },
            template: ttipTemplateDaily
          });
        } else {
          self.attachTooltips({
            header: function () {
              return self.yAxisLabel;
            },
            paths: ["circle.line", "g.bar"],
            template: ttipTemplateSummary
          });
        }
      });
      T1.Tooltip($el, {
        className: "rptg-icon-edit-tip",
        delayIn: 0,
        delayOut: 0,
        fade: false,
        getExternalTip: false,
        html: true,
        tooltipEle: ".metric-icon"
      });
      T1.Tooltip($el, {
        className: "rptg-icon-edit-tip",
        delayIn: 0,
        delayOut: 0,
        fade: false,
        getExternalTip: false,
        html: true,
        tooltipEle: ".metric-icon2"
      });
    },
    activateSelectbox: function () {
      var self = this;
      var selectMetricUIs = $(self.el).find(".select-metric");
      var publish = T1.EventHub.publish;
      const {
        update: update
      } = this;
      T1.Selectbox(selectMetricUIs, {
        dropDownRightPosition: "14px",
        highlightInSearchResult: true,
        no_results_text: "No results found for",
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search",
        onChange: function (event) {
          var select = $(event.target);
          self.yKey = select.val();
          self.yAxisLabel = select.find("option:selected").text();
          self.updateDataBind({
            chart_subtitle: self.getSubtitle(),
            chart_title: self.getTitle()
          }, self.el);
          publish("select:metric", self.yKey);
          publish("track:event", {
            action: "Choose metric",
            label: self.yKey
          });
          update.call(self);
        }
      });
    },
    load: function () {
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
      if (this.campaign.id) {
        this.activateToolTips();
        this.activateSelectbox();
        if (this.subSection === "summary") {
          this.updateXAxisSubLabels();
          this.wChartBody = $(this.el).find(".w-chart-body");
        }
      }
    },
    unload: function () {
      this.unbind();
      this.el.removeData().unbind();
      this.remove();
    },
    updateXAxisSubLabels: function () {
      var sortKeyInfo = this.reportModel.get("sortKeyInfo") || "";
      var sortKeyArray = sortKeyInfo.split(",");
      if (sortKeyArray.length > 0 && sortKeyArray[0] !== "") {
        this.xAxisSubLabel = `(Sorted by ${this.reportMetaObj.fields[sortKeyArray[0].toLowerCase()].name})`;
      }
    },
    getTitle: function () {
      return `${this.yAxisLabel} by ${this.dim1}`;
    },
    getSubtitle: function () {
      var reportModel = this.reportModel;
      var yAxisLabel = this.yAxisLabel;
      var chartCount = 0;
      var dim1 = this.dim1;
      var dim1HiddenCount = reportModel.get("hiddenCount") || 0;
      var dim1FilteredItems = reportModel.get("dim1FilteredItemsLookup");
      var dim1FilteredKeys = dim1FilteredItems ? _.keys(dim1FilteredItems) : [];
      var filteredInItems = reportModel.get("filteredInItems") || [];
      var filteredTotal = _.filter(dim1FilteredKeys, key => filteredInItems.includes(key));
      var total = filteredTotal.length ? filteredTotal.length : filteredInItems.length || this.collection.length;
      if (dim1HiddenCount === 0) {
        chartCount = ` (all ${total})`;
      } else {
        chartCount = total > 0 ? ` (${total - dim1HiddenCount} of ${total})` : ` (0 of ${total})`;
      }
      if (this.subSection === "daily") {
        return `Lines show ${this.timeSeriesLabel.toLowerCase()} ${yAxisLabel} by ${dim1}${chartCount}`;
      }
      return `Bars show summary of ${yAxisLabel} by ${dim1}${chartCount}`;
    },
    updateChart: function () {
      this.updateDataBind({
        chart_subtitle: this.getSubtitle()
      }, this.el);
      this.processData().chart();
    },
    serialize: function () {
      var metricValue;
      var metricOptions = [];
      var reportMeta = this.reportMeta;
      var reportModel = this.reportModel;
      var isDaily = this.subSection === "daily";
      var currencyType = this.mainModel.get("currency");
      var doResetMetric = false;
      var metricsReference = reportMeta.additionalData.metrics[reportModel.get("focus")];
      var yKey = this.yKey === "video_complete_rate_impression_based" ? "video_complete_rate" : this.yKey;
      $.each(this.reportMetaObj.metricOptions, function (i, option) {
        metricValue = option.value;
        option.selected = metricValue === yKey;
        if (currencyType === "usd") {
          if (metricsReference[metricValue]) {
            if (metricsReference[metricValue].onlyViewableInCampaignCurrency) {
              doResetMetric = true;
              return;
            }
          }
        }
        metricOptions.push(option);
      });
      if (doResetMetric) {
        T1.EventHub.publish("select:metric", reportModel.getDefaultMetric(this.campaign));
      }
      return $.extend(T1Chart.prototype.serialize(), {
        barChart: !isDaily,
        chart_subtitle: this.getSubtitle(),
        chart_title: this.getTitle(),
        isCTD: this.isCTD,
        lineChart: isDaily,
        metrics: metricOptions
      });
    }
  };
  return T1Chart.extend(BenchmarkOptions.extend(chartOptions));
});
