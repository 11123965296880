define('modules/T1App/edit/models/versionModel',["jQuery", "Underscore", "Backbone", "T1", "T1Model"], function ($, _, Backbone, T1, T1Model) {
  "use strict";

  T1.Models.Version = T1Model.extend({
    dataType: "json",
    url: function () {
      var url;
      var attr = this.attributes;
      if (attr.url1) {
        url = attr.app_url;
      } else if (attr.url2) {
        url = attr.deploy_url;
      } else if (attr.url3) {
        url = attr.app_version_url;
      } else if (attr.url4) {
        url = attr.app_version_edit_url;
      }
      return url;
    },
    save: function (attributes, options) {
      var canEdit;
      var self = this;
      var modelErrors;
      var statusInvalid = options && options.statusInvalid ? options.statusInvalid : $.noop;
      function i(a, b, c) {
        return function (d) {
          return a ? a(b, d, c) : b.trigger("error", b, d, c);
        };
      }
      function sync() {
        var model = self;
        var success = options.success;
        var method;
        var errorDisplaySuppressingConfig;
        options.success = function (returnedAttributes, e, f) {
          if (!model.set(returnedAttributes)) {
            return !1;
          }
          return success && success(model, returnedAttributes, f);
        };
        options.statusInvalid = function (data) {
          var errors = [];
          var newData = JSON.parse(data);
          if ($.isArray(newData.errors)) {
            $.each(newData.errors, function (key, value) {
              errors.push({
                field: value.field,
                message: value.message
              });
            });
          }
          statusInvalid(errors);
        };
        options.error = i(options.error, model, options);
        method = "create";
        attributes = $.isEmptyObject(attributes) ? {} : $.extend({
          version: 0
        }, attributes);
        errorDisplaySuppressingConfig = options.errorDisplaySuppressingConfig;
        options = _.extend(options, {
          dataType: "json",
          errorDisplaySuppressingConfig: {
            errorSuppressed: true
          },
          processAjaxResponse: function (resp) {
            var jsonData;
            var dataType;
            var statusCode;
            if (resp.meta === "success") {
              statusCode = "ok";
            } else {
              statusCode = "error";
            }
            function isJson(str) {
              try {
                jsonData = JSON.parse(str);
              } catch (e) {
                dataType = "plaintxt";
              }
            }
            return {
              jsonData: isJson(resp) ? jsonData : resp,
              statusCode: statusCode
            };
          }
        });
        if (errorDisplaySuppressingConfig) {
          options.errorDisplaySuppressingConfig = errorDisplaySuppressingConfig;
        }
        return self.sync.call(self, method, attributes, options);
      }
      options = options || {};
      if (!this.isNew()) {
        canEdit = this.canEdit();
        canEdit.done(function (editable) {
          if (!editable) {
            return;
          }
          return sync();
        });
      } else {
        modelErrors = this.validate ? this.validate(attributes) : {};
        if ($.isArray(modelErrors) && modelErrors.length) {
          statusInvalid(modelErrors);
        } else {
          $.each(attributes, function (attr, val) {
            if (val === "" || val === undefined) {
              delete attributes[attr];
            }
          });
          return sync();
        }
      }
    },
    setVersionData: function (rawData) {
      if (rawData.meta === "success") {
        this.set({
          name: rawData.data[0].name,
          url: rawData.data[0].url,
          organization_id: rawData.data[0].organization_id,
          app_id: rawData.data[0].id,
          version: rawData.data[0].version,
          status: rawData.data[0].status,
          deploy_status: rawData.data[0].deploy_status,
          data_version: rawData.data[0].data_version,
          description: rawData.data[0].description,
          learn_more_html: rawData.data[0].learn_more_html,
          support_email: rawData.data[0].support_email,
          learn_more_link: rawData.data[0].learn_more_link,
          learn_more_image: rawData.data[0].learn_more_image,
          icon: rawData.data[0].icon,
          disableStatus: rawData.data[0].disableStatus,
          lab: rawData.data[0].lab ? true : false,
          review_status: rawData.data[0].review_status
        }, {
          silent: true
        });
      }
    },
    setData: function (rawData) {
      if (rawData.meta === "success") {
        this.set({
          appData: rawData.data
        }, {
          silent: true
        });
      } else if (rawData.meta === "error") {
        console.log("error");
      }
    },
    updateStatus: function (attributes, options) {
      T1Model.prototype.save.call(this, attributes, options);
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred;
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    },
    reset: function () {
      this.attributes = {};
    },
    setMainTabSection: function (sectionId) {
      this.set({
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    }
  });
  return T1.Models.Version;
});
