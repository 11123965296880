define('T1BinsView',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!templates/bins.html", "text!templates/bin_header.html", "text!templates/bin_title.html", "text!templates/bin_group_name.html", "text!templates/bin_item.html", "text!templates/drag_drop_helper.html", "Mustache"], function ($, _, T1, T1View, T1Layout, template, bin_header, bin_title, bin_group_name, bin_item, DragDropHelper, T) {
  "use strict";

  var extendBins;
  var BinsView = T1View.extend({
    template: template,
    partials: {
      bin_header: bin_header,
      bin_item: bin_item,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      dragDropHelper: DragDropHelper
    },
    type: "Common",
    updateAction: null,
    configs: {
      include: {
        showAndOr: false,
        showWarning: false,
        showBinKindIcon: true,
        showTargetingOperator: false,
        showTooltip: false
      },
      exclude: {
        showAndOr: false,
        showWarning: false,
        showBinKindIcon: true,
        showTargetingOperator: false,
        showTooltip: false
      }
    },
    events: {
      "click .remove-icon": "removeItem"
    },
    removeItem: function (event) {
      var id = $(event.currentTarget).closest(".item").data("id");
      event.preventDefault();
      event.stopPropagation();
      return this.sharedCollection.remove(id) && true;
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      this.render().then(function () {
        $el.trigger("binsContent:loaded");
      });
    },
    updateBins: function (model, binRemoveItemsFrom) {
      if (!model || !model.get || typeof model.get !== "function") {
        return false;
      }
      this.updateAction = binRemoveItemsFrom === "include" || binRemoveItemsFrom === "exclude" ? binRemoveItemsFrom : model.get("action");
      if (this.updateAction) {
        this.renderPartial("." + this.updateAction + "-bin .content-container", undefined, "html", ".content-container");
        this.updateAction = null;
      }
      return true;
    },
    mapItems: function (items) {
      return $.map(items, function (obj) {
        return obj.toJSON ? obj.toJSON() : obj;
      });
    },
    generateBinData: function (updateAction) {
      var self = this;
      var actionBinName = updateAction + "-bin";
      var binConfig, returnObj;
      var binArray = [];
      binConfig = self.configs[updateAction] || {};
      if (self.sharedCollection && self.sharedCollection.length > 0) {
        binArray = self.sharedCollection.groupBy(function (model) {
          return model.get("action") === updateAction;
        });
        binArray = binArray.true || [];
      }
      binArray = self.mapItems(binArray, updateAction);
      returnObj = {
        bin_kind: actionBinName,
        bin_action: updateAction,
        binItems: binArray,
        bin_label: self.type
      };
      returnObj = $.extend(true, {}, binConfig, returnObj);
      if (binConfig.showWarning && binConfig.warningMessage) {
        if (self.sharedCollection.length < 1) {
          returnObj.showWarning = self.sharedCollection.length < 1;
        } else {
          delete returnObj.showWarning;
        }
      }
      return returnObj;
    },
    serialize: function () {
      var self = this;
      var bins = [];
      var binsConfigs = self.configs;
      var updateAction = self.updateAction;
      var currentBinData = {};
      var returnData;
      $.each(binsConfigs, function (key) {
        var generatedBin;
        if (!updateAction || updateAction === key) {
          generatedBin = self.generateBinData(key);
          bins.push(generatedBin);
        }
        if (updateAction === key) {
          currentBinData = generatedBin;
        }
      });
      returnData = updateAction ? {
        binItems: currentBinData.binItems || [],
        showWarning: currentBinData.showWarning,
        warningMessage: currentBinData.warningMessage,
        tooltipText: currentBinData.tooltipText
      } : {
        type: self.type,
        bin_label: self.type,
        bins: bins
      };
      return returnData;
    }
  });
  function addDraggable(view) {
    var runOriginalRender = view.prototype.render;
    var runOriginalUpdateBins = view.prototype.updateBins;
    view.prototype.showDragDropHelper = function (data) {
      var $helper = $(T.to_html(this.partials.dragDropHelper, data || {}));
      return $helper;
    };
    view.prototype.removeDragClasses = function ($scope) {
      var target = $scope ? $scope.find(".item") : this.el.find(".content .item");
      target.removeClass("drag-selected last-clicked last-shift-clicked");
    };
    view.prototype.applyDraggable = function () {
      var self = this;
      this.el.find(".item").not($(".read-only")).draggable({
        handle: ".text",
        revert: "invalid",
        cursor: "pointer",
        cursorAt: {
          bottom: 8,
          left: 8
        },
        appendTo: "body",
        zIndex: 2700,
        helper: function (event) {
          var $currentTarget = $(event.currentTarget);
          var $parent = $currentTarget.closest(".content");
          var $selectedItems = $parent.find(".item.drag-selected");
          var handlerTextEl = self.draggableConfigs.handlerTextEl || ".name";
          var labelTxt = $.trim($currentTarget.find(handlerTextEl + " .text").text());
          var $isCurrentItemSelected = $selectedItems.is($currentTarget);
          var count, isMultiple;
          $selectedItems = $selectedItems.not($parent.find('[data-ignore-drag-select="true"]'));
          count = $selectedItems.length;
          isMultiple = count > 1;
          if (!$isCurrentItemSelected) {
            self.removeDragClasses();
            isMultiple = false;
          }
          return self.showDragDropHelper({
            label: labelTxt,
            count: count,
            isMultiple: isMultiple
          });
        }
      }).disableSelection().click(function (event) {
        var selectedClass = "selected";
        var shiftSelectedClass = "shift-selected";
        var dragSelectedClass = "drag-selected";
        var lastClickedClass = "last-clicked";
        var lastShiftClickedClass = "last-shift-clicked";
        var currentTarget = $(event.currentTarget);
        var parent = currentTarget.closest(".content");
        var children = parent.find(".item");
        var myIndex = children.index(currentTarget);
        var previousLastClickedIndex = children.index(parent.find("." + lastClickedClass));
        var shiftRootIndex = children.index(parent.find("." + lastShiftClickedClass));
        var lowerBound, upperBound;
        if (self.draggableConfigs.allowMultipleSelection) {
          if (event.shiftKey === true) {
            if (shiftRootIndex === -1 && previousLastClickedIndex !== -1) {
              shiftRootIndex = previousLastClickedIndex;
              $(children[shiftRootIndex]).addClass(lastShiftClickedClass);
            }
            if (shiftRootIndex > -1 && shiftRootIndex !== myIndex) {
              lowerBound = shiftRootIndex < myIndex ? shiftRootIndex : myIndex;
              upperBound = shiftRootIndex > myIndex ? shiftRootIndex + 1 : myIndex;
              parent.find("." + shiftSelectedClass).removeClass(shiftSelectedClass + " " + dragSelectedClass);
              children.slice(lowerBound, upperBound).addClass(shiftSelectedClass + " " + dragSelectedClass);
              if (currentTarget.hasClass(shiftSelectedClass) === false) {
                currentTarget.addClass(shiftSelectedClass + " " + dragSelectedClass);
              }
            }
            currentTarget.addClass(dragSelectedClass + " " + lastClickedClass);
          } else {
            if (event.metaKey === false) {
              parent.find("." + dragSelectedClass).removeClass(dragSelectedClass);
            }
            parent.find("." + shiftSelectedClass + " ." + dragSelectedClass).removeClass(shiftSelectedClass);
            parent.find("." + lastClickedClass).removeClass(lastClickedClass);
            parent.find("." + lastShiftClickedClass).removeClass(lastShiftClickedClass);
            currentTarget.toggleClass(dragSelectedClass).addClass(lastClickedClass);
          }
        } else {
          parent.find("." + selectedClass).removeClass(selectedClass);
          currentTarget.addClass(selectedClass);
        }
      });
    };
    view.prototype.render = function () {
      var self = this;
      var deferred = $.Deferred();
      runOriginalRender.apply(this).then(function () {
        self.applyDraggable.call(self);
        deferred.resolve();
      });
      return deferred.promise();
    };
    view.prototype.updateBins = function () {
      var updated = runOriginalUpdateBins.apply(this, arguments);
      this.applyDraggable();
      return updated;
    };
    return view;
  }
  function addDroppable(view) {
    var runOriginalRender = view.prototype.render;
    var runOriginalUpdateBins = view.prototype.updateBins;
    view.prototype.draggableConfigs = $.extend(true, {}, {
      checkExistenceSharedCollection: true
    }, view.prototype.draggableConfigs);
    view.prototype.applyDroppable = function () {
      var self = this;
      var sharedCollection = self.sharedCollection || self.collection;
      this.el.find(".bin-content").droppable({
        hoverClass: "hovered",
        tolerance: "pointer",
        accept: function (ui) {
          ui.draggable();
          return true;
        },
        drop: function (event, ui) {
          self.handleDropEvent($(this), ui, sharedCollection);
        }
      });
    };
    view.prototype.handleDropEvent = function ($dropTarget, ui, sharedCollection) {
      var action, $itemContainer, $droppedItem, $selectedItems;
      var selectedList = [];
      var draggableConfigs = this.draggableConfigs;
      var checkExistenceSharedCollection = draggableConfigs.checkExistenceSharedCollection;
      if (!$dropTarget || typeof $dropTarget !== "object") {
        throw new Error("'$dropTarget' object is required for handleDropEvent");
      }
      if (!ui.draggable || typeof ui.draggable !== "object") {
        throw new Error("'draggable' object is required for handleDropEvent");
      }
      if (!sharedCollection || typeof sharedCollection !== "object") {
        throw new Error("'sharedCollection' is required for handleDropEvent");
      }
      action = $dropTarget.closest(".bin").data("action");
      $droppedItem = $(ui.draggable);
      $itemContainer = $droppedItem.closest(".content-c");
      if (!$itemContainer.length) {
        $itemContainer = $droppedItem.closest(".bin-content");
        $selectedItems = $itemContainer.find(".drag-selected");
      } else {
        $selectedItems = $itemContainer.find(".drag-selected").children(".item");
      }
      $selectedItems = $selectedItems.not($itemContainer.find('[data-ignore-drag-select="true"]'));
      function pushItemInSelectedList(dataId) {
        var model = sharedCollection.get(dataId);
        if (checkExistenceSharedCollection === false || !model || action !== (model.action || model.get("action"))) {
          selectedList.push({
            id: dataId,
            action: action
          });
        }
      }
      if ($selectedItems.length === 0) {
        pushItemInSelectedList($droppedItem.data("id"));
      } else {
        $.each($selectedItems, function (index, item) {
          pushItemInSelectedList($(item).data("id"));
        });
      }
      $selectedItems.closest(".item-wrapper").removeClass("drag-selected shift-selected last-clicked last-shift-clicked");
      T1.EventHub.publish(draggableConfigs.onDropEvent, {
        selectedList: selectedList
      });
    };
    view.prototype.render = function () {
      var self = this;
      var deferred = $.Deferred();
      runOriginalRender.apply(this).then(function () {
        self.applyDroppable.call(self);
        deferred.resolve();
      });
      return deferred.promise();
    };
    view.prototype.updateBins = function () {
      var updated = runOriginalUpdateBins.apply(this, arguments);
      this.applyDroppable();
      return updated;
    };
    return view;
  }
  BinsView = T1.Form.ViewUtils.extend(BinsView);
  extendBins = BinsView.extend;
  BinsView.extend = function () {
    var Bins = this;
    var originalUpdateBins, originalInitialize, originalEvents, originalConfigs, originalConfigsOverride, originalPartials, originalLoad, originalSerialize, ViewSubClass, isDraggable, isDroppable, dragConfigs;
    ViewSubClass = extendBins.apply(this, arguments);
    originalUpdateBins = ViewSubClass.prototype.updateBins || $.noop;
    originalInitialize = ViewSubClass.prototype.initialize || $.noop;
    originalEvents = ViewSubClass.prototype.events;
    originalConfigs = ViewSubClass.prototype.configs;
    originalConfigsOverride = ViewSubClass.prototype.configsOverride;
    originalPartials = ViewSubClass.prototype.partials;
    originalLoad = ViewSubClass.prototype.load || $.noop;
    originalSerialize = ViewSubClass.prototype.serialize || $.noop;
    dragConfigs = ViewSubClass.prototype.draggableConfigs;
    isDraggable = dragConfigs && dragConfigs.isDraggable;
    isDroppable = dragConfigs && dragConfigs.isDroppable;
    ViewSubClass.prototype.partials = $.extend({}, Bins.prototype.partials, originalPartials);
    ViewSubClass.prototype.updateBins = function () {
      var updated = Bins.prototype.updateBins.apply(this, arguments);
      originalUpdateBins.apply(this, arguments);
      return updated;
    };
    ViewSubClass.prototype.initialize = function (args) {
      var self = this;
      if (args && args.sharedCollection && args.sharedCollection.bind) {
        self.sharedCollection = args.sharedCollection;
        self.addDataEvent({
          sharedCollection: {
            add: function (model) {
              clearTimeout(this.collAddTimeoutId);
              this.collAddTimeoutId = setTimeout($.proxy(this.updateBins, this, model), 0);
            },
            remove: function (model) {
              clearTimeout(this.collRemoveTimeoutId);
              this.collRemoveTimeoutId = setTimeout($.proxy(this.updateBins, this, model, model.get("action")), 0);
            }
          }
        });
        self.preventDoubleBind = true;
      }
      if (args && args.partials) {
        self.partials = $.extend({}, self.partials, args.partials);
      }
      return originalInitialize.call(this, args);
    };
    ViewSubClass.prototype.events = $.extend(true, {}, Bins.prototype.events, originalEvents);
    if (originalConfigsOverride) {
      ViewSubClass.prototype.configs = originalConfigs;
    } else {
      ViewSubClass.prototype.configs = $.extend(true, {}, Bins.prototype.configs, originalConfigs);
    }
    ViewSubClass.prototype.load = function () {
      Bins.prototype.load.apply(this, arguments);
      originalLoad.apply(this, arguments);
    };
    ViewSubClass.prototype.serialize = function () {
      var tmplObj = Bins.prototype.serialize.apply(this, arguments);
      return originalSerialize.call(this, tmplObj) || tmplObj;
    };
    if (isDraggable) {
      ViewSubClass = addDraggable(ViewSubClass);
    }
    if (isDroppable) {
      ViewSubClass = addDroppable(ViewSubClass);
    }
    return ViewSubClass;
  };
  return BinsView;
});
