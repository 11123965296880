define('utils/ClassUtils',[], function () {
  "use strict";

  function constructIgnoreList(ignoreList) {
    ignoreList = ignoreList || [];
    ignoreList = Array.isArray(ignoreList) ? ignoreList : [ignoreList];
    if (ignoreList.indexOf("constructor") === -1) {
      ignoreList.push("constructor");
    }
    return ignoreList.filter(function (item) {
      return typeof item === "string";
    });
  }
  function getProto(obj, iterator) {
    var proto;
    if (iterator === 0) {
      proto = Object.getPrototypeOf(obj);
    } else {
      iterator--;
      proto = getProto(obj, iterator);
    }
    return proto;
  }
  function getPrototypeAtDepth(cls, depth) {
    var proto = cls.prototype;
    depth = depth === undefined ? 0 : depth;
    return depth ? getProto(proto, depth) : proto;
  }
  function cloneClass(cls, depth, ignoreList) {
    var proto = getPrototypeAtDepth(cls, depth);
    ignoreList = constructIgnoreList(ignoreList);
    return Object.keys(proto).reduce(function (acc, key) {
      if (ignoreList.indexOf(key) === -1) {
        acc[key] = proto[key];
      }
      return acc;
    }, {});
  }
  return {
    cloneClass: cloneClass
  };
});
