define('modules/audiences/segments/behavior/utils/binUtils',["jQuery", "Underscore", "moment", "T1"], function ($, _, moment, T1) {
  function removeFromBinsHandler(e) {
    var self = this;
    var model = e.detail.model;
    var grid = findGrid(model);
    var type = model.type;
    var gridModel = _.find(grid.data, function (item) {
      return item.targetId === model.targetId;
    });
    var index = grid.data.indexOf(gridModel);
    if (type === "strategy") {
      let action = _.invert(model).selected === "clickState" ? "click" : "impression";
      grid.set("data." + index + "." + action + "State", "deselected");
      this.addOrRemoveStrategy(model, false, grid, action);
    }
    if (type === "campaign") {
      grid.set("data." + index + "." + "allImpressionsState", "deselected");
      this.addOrRemoveCampaign(model, false, grid, "allImpressions");
    } else {
      grid.set("data." + index + ".state", "deselected");
      this.addOrRemoveRow(model, false, grid);
    }
    this.setApplyButtonState();
    function findGrid(model) {
      switch (model.category) {
        case "eventPixels":
          return self.eventPixelsGrid;
        case "dataPixels":
          return self.dataPixelsGrid;
        case "strategies":
          return self.strategiesGrid;
        case "campaigns":
          return self.campaignsGrid;
      }
      switch (model.segment_type) {
        case "adaptive":
          return self.grid;
        case "dmc":
          return self.dmcGrid;
      }
    }
  }
  function removeFromBinCollection(model) {
    var pixelToRemove = _.findWhere(this.binCollection, {
      targetId: model.targetId
    });
    var indexToRemove = this.binCollection.indexOf(pixelToRemove);
    this.binCollection.splice(indexToRemove, 1);
  }
  function inBins(id, action) {
    if (!action) {
      return _.some(this.binCollection, function (row) {
        return row.targetId === id;
      });
    } else {
      return _.some(this.binCollection, function (row) {
        return row.targetId === id && row[action + "State"] === "selected";
      });
    }
  }
  function addBinLabel(label) {
    var containsLabel = this.binlist.grouped.some(function (e) {
      return e.label === label;
    });
    if (!containsLabel) {
      this.binlist.grouped.push({
        label: label,
        entity: label
      });
    } else {
      return;
    }
  }
  return {
    removeFromBinsHandler: removeFromBinsHandler,
    removeFromBinCollection: removeFromBinCollection,
    inBins: inBins,
    addBinLabel: addBinLabel
  };
});
