define('models/user',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  "use strict";

  T1.Models.User = T1Model.extend({
    url: "users/",
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options, isReset) {
      var self = this;
      var validationErrors;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData, !this.get("id"), isReset);
      delete rawData.password_retype;
      delete rawData.selected;
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    },
    validateAttrs: function (attrs, isNew, isReset) {
      isNew = isNew === undefined ? false : isNew;
      var errors = [];
      var optionalFields = ["status"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        first_name: function () {
          return !attrs.first_name;
        },
        last_name: function () {
          return !attrs.last_name;
        },
        title: function () {
          return !attrs.title;
        },
        username: function () {
          return !attrs.username;
        },
        phone: function () {
          return !attrs.phone;
        },
        password: function () {
          return !attrs.password && (isNew || isReset);
        },
        password_retype: function () {
          return !attrs.password_retype && (isNew || isReset);
        }
      };
      var extraValFields = {
        first_name: function () {
          if (attrs.first_name.length > 64) {
            return "Name cannot be longer than 64 characters.";
          }
        },
        password_retype: function () {
          if (attrs.password_retype !== attrs.password) {
            return "Password does not match.";
          }
        },
        has_assigned_entities: function () {
          if (!attrs.has_assigned_entities) {
            return "User must be assigned at least one entity.";
          }
        },
        username: function () {
          if (isNew && attrs.type !== "INTERNAL" && (attrs.username.indexOf("@") <= 0 || (attrs.username.split("@")[1] || "").indexOf(".") <= 0)) {
            return "Please enter a valid email address.";
          }
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
        if (extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        }
      });
      delete attrs.has_assigned_entities;
      if (errors.length) {
        return errors;
      }
    }
  });
  return T1.Models.User;
});
