define('collections/nemoTargets',["T1NemoCollection", "models/nemoTarget"], function (NemoCollection, NemoTarget) {
  "use strict";

  var collection = NemoCollection.extend({
    model: NemoTarget,
    urlString: "target",
    init: function () {
      this.initOriginal({
        isPaginated: false
      });
    }
  });
  collection.prototype.initOriginal = collection.prototype.initialize;
  collection.prototype.initialize = collection.prototype.init;
  return collection;
});
