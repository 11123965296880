define('modules/reporting/campaigns/reachFrequency/views/chartTitle',["jQuery", "Underscore", "T1", "T1View", "text!../../templates/chart_title_box.html"], function ($, _, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    eventhubEvents: {
      "update:metric": function () {
        this.reload();
      },
      "panel.filteredItemsChange": function () {
        this.reload();
      }
    },
    dataEvents: {
      reportModel: {
        "change:dimensionItems": function () {
          this.reload();
        }
      }
    },
    initialize: function (args) {
      var models = args.models;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
    },
    getSubtitle: function () {
      var reportMetaObj = this.reportMetaObj;
      var reportModel = this.reportModel;
      var focus = reportModel.get("focus") || "all";
      var lineMetric = reportModel.get("metric") || reportMetaObj.metricOptions[0].value;
      var lineMetricLabel = reportMetaObj.metricsReachFrequency[focus][lineMetric].text;
      return `'Bars show summary of Uniques by Frequency. Line shows summary of ${lineMetricLabel} by Frequency.`;
    },
    serialize: function () {
      return {
        chart_subtitle: this.getSubtitle(),
        chart_title: "Reach & Frequency"
      };
    }
  });
});
