define('modules/campaign/controller/actions',["jQuery", "T1"], function ($, T1) {
  "use strict";

  function actions(view, dataStore) {
    function changeReportInterval({
      value: value
    }) {
      view.entityInterval = value;
      view.preferences.save();
      dataStore.report_interval = value;
      dataStore.trigger("change:reportInterval");
    }
    function changeStrategyGoal({
      value: value
    }) {
      view.selectedStrategyGoal = value;
      dataStore.strategy_goal_performance = value;
      view.preferences.save();
      T1.EventHub.publish("change:strategyGoalPerformances", value);
    }
    function changeListViewMode({
      value: value
    }) {
      view.itemViewMode = value;
      view.preferences.save();
      dataStore.trigger("change:itemViewMode", value);
    }
    function changeStatus(data, status) {
      var campaigns = data.campaigns;
      var collection = dataStore;
      $.each(campaigns, function (index, id) {
        const model = collection.get(id);
        model.save({
          status: status
        });
      });
    }
    function activateCampaigns(data) {
      changeStatus(data, 1);
    }
    function deactivateCampaigns(data) {
      changeStatus(data, 0);
    }
    function resolve(action, data) {
      var actionMapping = {
        activate: activateCampaigns,
        deactivate: deactivateCampaigns,
        selectAll: function () {
          view.checkAll();
        },
        selectNone: function () {
          view.checkNone();
        },
        changeReportInterval: changeReportInterval,
        changeStrategyGoal: changeStrategyGoal,
        changeListViewMode: changeListViewMode
      };
      if (action === undefined) {
        throw new Error("Action not specified");
      }
      const handler = actionMapping[action];
      if (handler === undefined) {
        throw new Error(`Action Mapping not found for Action::${action}`);
      } else {
        handler(data);
      }
    }
    return {
      resolve: resolve
    };
  }
  return actions;
});
