require.config({
  paths: {
    "auth0-js": "libs/auth0/auth0.min",
    Backbone: "libs/backbone/backbone",
    "backbone-relational": "libs/backbone/backbone-relational",
    jQPlugins: "libs/jquery/plugins",
    jQuery: "libs/jquery/jquery",
    jQueryUI: "libs/jquery/plugins/jqueryui",
    LaunchDarkly: "libs/ldclient-js/ldclient.min",
    Underscore: "libs/underscore/underscore",
    Validator: "libs/backbone/backbone.validation.min",
    When: "libs/when/when",
    window: "window",
    T1: "libs/T1/T1",
    T1Accordion: "libs/T1/T1.Accordion",
    T1Alert: "libs/T1/T1.Alert",
    T1Animate: "libs/T1/T1.Animate",
    T1BinsView: "libs/T1/T1.BinsView",
    T1Cache: "libs/T1/T1.Cache",
    T1Chart: "libs/T1/T1.Chart",
    T1ChartBenchmarkOptions: "libs/T1/T1.ChartBenchmarkOptions",
    T1Collection: "libs/T1/T1.Collection",
    T1ColumnSortable: "libs/T1/T1.ColumnSortable",
    T1Comm: "libs/T1/T1.Comm",
    T1Countries: "libs/T1/T1.Countries",
    T1Currencies: "libs/T1/T1.Currencies",
    T1DatePicker: "libs/T1/T1.DatePicker",
    T1FileUpload: "libs/T1/T1.FileUpload",
    T1Filterable: "libs/T1/T1.Filterable",
    T1FilterList: "libs/T1/T1.FilterList",
    T1Form: "libs/T1/T1.Form",
    T1GridView: "libs/T1/T1.GridView",
    T1GridViewV2: "libs/T1/T1.GridView-v2",
    T1InlineEditPopover: "libs/T1/T1.InlineEditPopover",
    T1Layout: "libs/T1/T1.Layout",
    T1Loader: "libs/T1/T1.Loader",
    T1Menu: "libs/T1/T1.Menu",
    T1Model: "libs/T1/T1.Model",
    T1Module: "libs/T1/T1.Module",
    T1NemoCollection: "libs/T1/T1.NemoCollection",
    T1Notifier: "libs/T1/T1.Notifier",
    T1PerfDataFulfiller: "libs/T1/T1.PerformanceDataFulfiller",
    T1PerformanceTimer: "libs/T1/T1.PerformanceTimer",
    T1Permissions: "libs/T1/T1.Permissions",
    T1PicardVersion: "libs/T1/T1.PicardVersion",
    T1PolymerView: "libs/T1/T1.T1.PolymerView.js",
    T1PopoverPositionable: "libs/T1/T1.PopoverPositionable",
    T1Preferences: "libs/T1/T1.Preferences",
    T1Searchable: "libs/T1/T1.Searchable",
    T1Selectbox: "libs/T1/T1.Selectbox",
    T1SelectFilterList: "libs/T1/T1.SelectFilterList",
    T1Signature: "libs/T1/T1.Signature",
    T1Sortable: "libs/T1/T1.Sortable",
    T1Spinner: "libs/T1/T1.Spinner",
    T1TabButton: "libs/T1/T1.TabButton",
    T1TabLayout: "libs/T1/T1.TabLayout",
    T1TabLayoutV2: "libs/T1/T1.TabLayout-v2",
    T1Timezones: "libs/T1/T1.Timezones",
    T1Tooltip: "libs/T1/T1.Tooltip",
    T1Truncate: "libs/T1/T1.Truncate",
    T1UtilsAsync: "libs/T1/T1.UtilsAsync",
    T1Versions: "libs/T1/T1.Versions",
    T1View: "libs/T1/T1.View",
    async: "libs/async/async",
    ChartJS2: "libs/chart/Chart2.min",
    flowplayer: "libs/flowplayer/flowplayer",
    Hogan: "libs/hogan/hogan-2.0.0.amd",
    iframeResize: "libs/iframeResize/iframeResize.min",
    InflectionJS: "libs/inflection/inflection",
    Jath: "libs/jath/jath",
    jsTimezoneDetect: "libs/jsTimezoneDetect/jstz.min",
    JSZip: "libs/jszip/jszip",
    moment: "libs/moment/moment.min",
    "moment-timezone": "libs/moment/moment-timezone-with-data.min",
    Mustache: "libs/mustache/mustache",
    NaturalSort: "libs/naturalSort/naturalSort",
    sha256: "libs/js-sha256/sha256.min",
    swfobject: "libs/swfobject/swfobject.shim",
    xlsx: "libs/jsXLSX/xlsx",
    zipUtil: "libs/zipJs/zip",
    jslibs: "libs",
    collections: "collections",
    models: "models",
    modules: "modules",
    templates: "templates",
    text: "libs/require/text"
  },
  shim: {
    xlsx: {
      deps: ["JSZip"],
      exports: "XLSX"
    },
    jQuery: {
      attach: "$",
      exports: "jQuery"
    },
    Backbone: {
      exports: "Backbone"
    },
    Underscore: {
      deps: ["jQuery"],
      exports: "_"
    },
    "backbone-relational": {
      deps: ["Backbone"]
    },
    jQueryUI: {
      deps: ["jQuery"]
    },
    "jQPlugins/jquery.spin": {
      deps: ["jQuery"]
    },
    "jQPlugins/jquery.tinypubsub": {
      deps: ["jQuery"]
    },
    "jQPlugins/jquery.cookie": {
      deps: ["jQuery"]
    },
    T1Model: {
      deps: ["backbone-relational"]
    },
    "jslibs/T1/T1.PopoverPositionable": {
      deps: ["jQuery"]
    },
    T1GridViewV2: {
      deps: ["jQuery", "Underscore"]
    },
    T1GridView: {
      deps: ["jQuery", "Underscore"]
    },
    app: {
      deps: ["jslibs/d3/d3.v2", "jQuery", "Underscore"]
    }
  },
  noGlobal: true
});
require.onError = function (err) {
  console.log(err);
};
require([], function () {
  "use strict";

  function callInitalize() {
    require(["app"], function (App) {
      App.initialize();
    });
  }
  if (window.HTMLImports && !window.HTMLImports.ready) {
    window.addEventListener("HTMLImportsLoaded", callInitalize);
  } else {
    callInitalize();
  }
});
define("main", function(){});

