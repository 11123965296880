define('modules/reporting/campaigns/reachFrequency/views/panel',["jQuery", "Underscore", "T1", "T1View", "collections/campaignReport", "T1Layout", "T1PerformanceTimer", "When", "../../../utils/reportingComm", "text!../templates/panel.html", "text!../templates/layout.html"], function ($, _, T1, T1View, CampaignReport, T1Layout, T1Timer, When, ReportingComm, template, layout) {
  "use strict";

  var GAMap = {
    filterDimensionsApiLoad: "RPTG:REACHFREQUENCY:DIMENSION:API",
    reachFrequencyApiLoad: "RPTG:REACHFREQUENCY:API:FREQUENCY_BIN",
    totalLoad: "RPTG:REACHFREQUENCY:API:LOAD:TOTAL"
  };
  var comm, timerValue;
  const ANIM_TIME = 200;
  return T1View.extend({
    dimensionData: "strategy_id",
    template: template,
    section: "reachFrequency",
    dataEvents: {
      reportModel: {
        "change:tempDimension": function () {
          this.updateData();
        },
        "change:dimension": function () {
          this.changeDimension();
        }
      }
    },
    eventhubEvents: {
      "reports:select:campaign": function (args) {
        if (this.currentReportId !== args.id) {
          this.report.id = args.id;
          this.updateData();
        }
      },
      "select:dimension": function () {
        this.moreFilters = this.getFilterParams();
        this.reportModel.setMoreFilters(this.moreFilters);
        this.updateData();
      },
      "select:interval": function () {
        this.updateData();
      },
      "toggle:loader": function (show) {
        this.toggleLoader(show);
      }
    },
    initialize: function (args) {
      var cfgs, dimensionInfo, models;
      var self = this;
      var fetchOptions = {
        dimensions: null,
        filter: "campaign_id=",
        precision: 2,
        time_rollup: null,
        time_window: null
      };
      var fetchURL = function () {
        var opts = $.extend({}, this.fetchOptions);
        opts.filter += String(this.id) + self.getFilterParams();
        return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
      };
      comm = new ReportingComm({
        id: "reachFrequency"
      });
      cfgs = this.cfgs = args;
      cfgs.dataGroup = comm.dataGroup;
      this.report = cfgs.report;
      models = cfgs.models;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
      this.currentReportId = this.report.id;
      this.parentClass = cfgs.parentClass;
      dimensionInfo = this.reportModel.getDimensionInfo(false);
      this.dimensionData = dimensionInfo.key;
      cfgs.type = "summary";
      this.filterDimension = comm.buildCollection("filterDimension", models.reachFrequencyFilterDimension);
      cfgs.collection = this.collection = comm.buildCollection("collection", models.reachFrequencyCollection);
      comm.buildCollection("totalCollection", new CampaignReport({
        urlString: "std/reach_frequency",
        fetchOptions: $.extend({}, fetchOptions, {
          dimensions: "organization_id",
          time_rollup: "all"
        }),
        url: fetchURL
      }));
      this.initLayouts();
      this.setGetDataOnce();
    },
    initLayouts: function () {
      var self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".w-report-panel");
        },
        template: layout,
        layout: {
          ".report-chart": [{
            module: "reporting/campaigns/reachFrequency",
            viewType: "chartPanel",
            options: {
              config: this.cfgs,
              parentClass: this
            }
          }],
          ".report-controls": [{
            module: "reporting/campaigns/reachFrequency",
            options: this.cfgs,
            viewType: "controls"
          }],
          ".report-grid": [{
            module: "reporting/campaigns/reachFrequency",
            options: this.cfgs,
            viewType: "grid"
          }]
        }
      });
      this.layout.serialize = function () {
        return {
          chartHidden: !self.chartShown
        };
      };
    },
    changeDimension: function () {
      this.reportModel.resetHiddenItems();
      this.load();
    },
    timer: function (category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    },
    getSelectedTimeWindow: function () {
      return this.reportModel.get("interval");
    },
    getData: function () {
      var self = this;
      var cfgs = this.cfgs;
      var report = this.report;
      var reportID = report.id;
      var models = cfgs.models;
      var reachFrequencyFilterDimension = this.filterDimension;
      var reachFrequencyCollection = this.collection;
      var reportModel = this.reportModel;
      var dimensionInfo = reportModel.get("dimensionInfo");
      var tempDimension = reportModel.get("tempDimension");
      var tempDimensionInfo = reportModel.get("tempDimensionInfo");
      var section = "reach_frequency";
      var timeWindow = this.getSelectedTimeWindow() || "last_30_days";
      var dimensionName = "";
      var silent = false;
      var urlString = `std/${section}`;
      var dateRangeChanged, dim, dimKey, loadFilterDimensionHandler, loadReachFrequencyHandler, totalCollection;
      var timer = this.timer;
      var filterDimensionDeferred = When.defer();
      var reachFrequencyDataDeferred = When.defer();
      var promises = [filterDimensionDeferred.promise, reachFrequencyDataDeferred.promise];
      if (!reportID) {
        return;
      }
      this.toggleLoader(true);
      if (tempDimensionInfo) {
        dimensionName = tempDimensionInfo.dimension;
      } else if (dimensionInfo) {
        dimensionName = dimensionInfo.dimension;
      }
      dimensionName = dimensionName.toUpperCase().replace(/[ ]/g, "_");
      reportModel.resetDateRangeChanged();
      dateRangeChanged = this.timeWindow !== undefined && this.timeWindow !== timeWindow;
      if (dateRangeChanged) {
        reportModel.resetOnDateRangeChange();
      }
      this.timeWindow = timeWindow;
      this.currentReportId = reportID;
      comm.reportID = reportID;
      comm.urlString = urlString;
      comm.resetDataItem("filterDimension", {
        time_window: timeWindow
      });
      if (tempDimension) {
        this.dimensionData = tempDimensionInfo.key;
        silent = true;
      }
      comm.resetDataItem("collection", {
        time_window: timeWindow
      });
      totalCollection = comm.resetDataItem("totalCollection", {
        time_window: timeWindow
      });
      this.totalCollection = totalCollection;
      if (!tempDimension) {
        reportModel.setCollectionFetchParams(reachFrequencyCollection.fetchOptions);
        dim = dimensionInfo.dimension;
        dimKey = dimensionInfo.key;
      } else {
        dim = tempDimensionInfo.dimension;
        dimKey = tempDimensionInfo.key;
      }
      reachFrequencyFilterDimension.fetchOptions.dimensions = dim === dimKey ? dim : `${dim},${dimKey}`;
      timerValue = reportID;
      this.abortAjaxCalls();
      loadFilterDimensionHandler = function () {
        timer(GAMap.filterDimensionsApiLoad + dimensionName, timerValue, "start");
        reachFrequencyFilterDimension.loaded = false;
        if (tempDimension) {
          models.tempDim1Coll = reachFrequencyFilterDimension;
        }
        reachFrequencyFilterDimension.fetch({
          onSuccess: function () {
            timer(GAMap.filterDimensionsApiLoad + dimensionName, timerValue, "stop");
            reachFrequencyFilterDimension.loaded = true;
            filterDimensionDeferred.resolve();
          }
        });
      };
      loadReachFrequencyHandler = function () {
        timer(GAMap.reachFrequencyApiLoad + dimensionName, timerValue, "start");
        reachFrequencyCollection.loaded = false;
        reachFrequencyCollection.fetch({
          silent: silent,
          onSuccess: function (collectionData) {
            timer(GAMap.reachFrequencyApiLoad + dimensionName, timerValue, "stop");
            reportModel.collectionData = collectionData;
            reachFrequencyCollection.loaded = true;
            reportModel.setReportInfo(section, reachFrequencyCollection.ajxResponseHeaders);
            reachFrequencyDataDeferred.resolve();
          }
        });
      };
      loadFilterDimensionHandler();
      loadReachFrequencyHandler();
      When.all(promises).then(function () {
        if (self.mainModel.leftTheSection(self.section)) {
          return;
        }
        if (tempDimension) {
          self.dimensionData = null;
          reportModel.setDimensionItems({
            applying: false,
            dim1Data: reachFrequencyFilterDimension,
            dim1FilteredData: null,
            dim2Data: null
          });
        } else {
          reportModel.setDimensionItems({
            applying: true,
            dim1Data: reachFrequencyFilterDimension,
            dim1FilteredData: null,
            dim2Data: null
          });
          T1.EventHub.publish("responseHeadersReady", reportModel.get("reportInfo").reportTypes[section]);
        }
        self.getTotalData();
      });
    },
    getTotalData: function () {
      var mainModel = this.mainModel;
      var self = this;
      var timer = this.timer;
      var totalCollection = comm.dataGroup.totalCollection;
      var loadTotalSeriesHandler;
      var totalCollectionDeferred = When.defer();
      var promises = [totalCollectionDeferred.promise];
      if (mainModel.leftTheSection(this.section)) {
        return;
      }
      this.abortTotalAjaxCalls();
      totalCollection.loaded = false;
      loadTotalSeriesHandler = function () {
        timer(GAMap.totalLoad, timerValue, "start");
        totalCollection.loaded = false;
        totalCollection.fetch({
          onSuccess: function () {
            timer(GAMap.totalLoad, timerValue, "stop");
            totalCollection.loaded = true;
            totalCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`total nested fetch failed: ${error}`);
          }
        });
      };
      loadTotalSeriesHandler();
      When.all(promises).then(function () {
        if (!mainModel.leftTheSection(self.section)) {
          self.toggleLoader(false);
          T1.EventHub.publish("panel.filteredItemsChange");
        }
      });
    },
    getFilterParams: function () {
      var reportModel = this.reportModel;
      var filteredInItems = reportModel.get("filteredInItems") || [];
      var filteredOutItems = reportModel.get("filteredOutItems") || [];
      var dimensionInfoKey = reportModel.get("dimensionInfo").key;
      var moreFilters = "";
      if (filteredInItems.length) {
        filteredInItems = reportModel.getEscapedNames(filteredInItems);
      }
      if (filteredInItems.length > 0 && filteredOutItems.length !== 0) {
        moreFilters = `&${dimensionInfoKey}=${filteredInItems.toString()}`;
      }
      return moreFilters;
    },
    setGetDataOnce: function () {
      this.getDataOnce = _.once(this.getData);
    },
    updateData: function () {
      this.setGetDataOnce();
      this.getDataOnce();
    },
    needsDataLoad: function () {
      return this.unloaded || this.currentReportId !== this.report.id || this.timeWindow !== this.reportModel.get("interval") || this.prevDimensionData !== this.dimensionData || this.prevMoreFilters !== this.moreFilters;
    },
    resetData: function () {
      if (comm) {
        comm.clearDataGroup();
        comm = null;
      }
    },
    load: function () {
      var self = this;
      var reportModel = this.reportModel;
      var mainModel = this.mainModel;
      var dimensionInfo = reportModel.getDimensionInfo(false);
      const {
        getDataOnce: getDataOnce
      } = this;
      if (mainModel.leftTheSection(this.section)) {
        return;
      }
      this.dimensionData = dimensionInfo.key;
      mainModel.setTrackingSection("Reporting - Reach Frequency");
      this.chartShown = mainModel.get("chartShown") === "true";
      if (this.needsDataLoad()) {
        reportModel.resetColorData();
        this.setGetDataOnce();
      } else {
        reportModel.setCollectionFetchParams(this.collection.fetchOptions);
      }
      this.unloaded = false;
      this.layout.unload();
      this.render().then(function () {
        self.layout.load();
        self.$loader = self.parentClass.el.find(".loader-holder");
        getDataOnce.call(self);
        self.prevFocus = self.focus;
        self.prevDimensionData = self.dimensionData;
        self.prevMoreFilters = self.moreFilters;
      });
    },
    toggleLoader: function (show) {
      if (show) {
        this.$loader.fadeIn(ANIM_TIME);
      } else {
        this.$loader.fadeOut(ANIM_TIME);
      }
    },
    abortAjaxCalls: function () {
      if (comm) {
        comm.abortAjaxCalls(["filterDimension", "collection"]);
      }
    },
    abortTotalAjaxCalls: function () {
      if (comm) {
        comm.abortAjaxCalls(["totalCollection"]);
      }
    },
    unload: function () {
      this.abortAjaxCalls();
      this.layout.unload();
      this.resetData();
      this.unloaded = true;
    }
  });
});
