define('modules/campaign/bulkedit/views/targeting',["T1View", "text!modules/campaign/bulkedit/templates/targeting.html", "T1TabLayoutV2"], function (T1View, template, T1TabLayout) {
  "use strict";

  var TargetingView = T1View.extend({
    template: template,
    eventhubEvents: {},
    initialize: function ({
      bulkeditor: bulkeditor,
      campaign: campaign
    }) {
      var strategy = this.model;
      var tabsList = [];
      tabsList.push({
        label: "My Data",
        module: "campaign/bulkedit/targeting/mydata",
        viewType: "panel",
        routeName: "mydata",
        options: {
          strategy: strategy,
          bulkeditor: bulkeditor,
          campaign: campaign
        }
      });
      tabsList.push({
        label: "Location",
        module: "campaign/bulkedit/targeting/location",
        viewType: "panel",
        routeName: "location",
        options: {
          strategy: strategy,
          bulkeditor: bulkeditor
        }
      });
      this.layout = new T1TabLayout({
        tabSetName: "BulkeditStrategiesTargeting",
        level: 2,
        tabs: tabsList
      });
    },
    load: function () {
      this.render().then(() => {
        this.layout.el = this.$(".targeting-tabs");
        this.layout.load();
      });
    },
    canUnload: function (...args) {
      return this.layout.canUnload(...args);
    }
  });
  return TargetingView;
});
