define('modules/shared/views/advertiserSelect',["jQuery", "Underscore", "When", "InflectionJS", "T1", "T1View", "T1Model", "T1Collection", "T1Sortable", "text!../templates/advertiserSelect.html", "Hogan", "T1Selectbox"], function ($, _, When, InflectionJS, T1, T1View, T1Model, T1Collection, T1Sortable, template, Hogan) {
  return T1View.extend({
    template: template,
    runOnce: 0,
    initialize: function (args) {
      var collRef;
      var modelRef;
      var heirarchy = ["organization", "agency", "advertiser", "concept", "creative"];
      var filterItem;
      var filters;
      var fallback;
      var selectedId;
      this.options = {
        entity: args.entity || "concepts",
        selectedId: args.selectedId,
        boundEntity: args.boundEntity || "advertisers",
        boundEntityId: args.boundEntityId,
        boundEntityHeirarchy: args.boundEntityHeirarchy || heirarchy,
        placeholder: args.placeholder || "Select One",
        preSelect: args.preSelect || "Please select an item first!",
        notFound: args.notFound || "No Items Found",
        dataBind: args.dataBind || "concept_id",
        refreshEvent: args.refreshEvent || args.boundEntity || "advertisers",
        selectEvent: args.selectEvent || args.entity || "concepts",
        initialLoad: args.initialLoad,
        disableCheck: args.disableCheck,
        isSearchable: args.isSearchable,
        clearOnClose: args.clearOnClose,
        defaultSearchParams: args.defaultSearchParams || {},
        defaultSortParams: args.defaultSortParams,
        extraLinkLabel: args.extraLinkLabel,
        extraLinkHandler: args.extraLinkHandler || $.noop,
        extraLinkClass: args.extraLinkClass,
        isPaginated: typeof args.isPaginated !== undefined ? args.isPaginated : true,
        fetchOptions: typeof args.fetchOptions !== undefined ? args.fetchOptions : {},
        customLimit: args.customLimit || 100,
        isLocalCollection: typeof args.isLocalCollection !== undefined ? args.isLocalCollection : false,
        preRender: args.preRender || false
      };
      this.canLoad = false;
      if (args.collection) {
        this.collection = args.collection;
      } else {
        modelRef = T1Model.extend({
          url: this.options.entity + "/"
        });
        heirarchy = this.options.boundEntityHeirarchy;
        filterItem = this.options.boundEntity.singularize();
        filters = heirarchy.slice(heirarchy.indexOf(filterItem), heirarchy.indexOf(this.options.entity.singularize()));
        filters = filters.map(function (filter) {
          return {
            entity: filter,
            fetchOnChange: true
          };
        });
        filters.reverse();
        collRef = T1Collection.extend({
          urlString: this.options.entity,
          model: modelRef,
          filterConfiguration: {
            filters: filters,
            enableEvents: false
          },
          searchConfiguration: {
            fields: {
              name: {
                type: "string"
              }
            }
          }
        });
        if (this.options.defaultSortParams) {
          collRef = T1Sortable.extendCollection(collRef, this.options.defaultSortParams);
        }
        this.collection = new collRef(null, {
          isPaginated: this.options.isPaginated,
          pageLimit: this.options.customLimit
        });
        this.collection.fetchOptions = $.extend(args.fetchOptions, this.collection.fetchOptions);
      }
      if (this.options.initialLoad) {
        if (this.options.boundEntity && this.options.boundEntityId) {
          this.collection.urlFilter.set({
            entity: this.options.boundEntity.singularize(),
            id: this.options.boundEntityId
          });
        }
        this.collection.fetch({
          success: $.proxy(function () {
            selectedId = this.options.selectedId;
            if (args.fetchSelected && (selectedId || selectedId === 0) && !this.collection.get(selectedId)) {
              fallback = new modelRef({
                id: selectedId
              });
              this.collection.add(fallback);
              fallback.fetch().then($.proxy(function () {
                this.canLoad = true;
                this.load();
                this.hideLoading();
              }, this));
            } else {
              this.canLoad = true;
              this.load();
              this.hideLoading();
            }
          }, this)
        });
      } else {
        this.canLoad = true;
      }
      if (args.model) {
        this.collection.add(args.model);
      }
    },
    showLoading: function () {
      $(this.el).find(".select-loader").show();
      this.close();
    },
    hideLoading: function () {
      $(this.el).find(".select-loader").hide();
    },
    reset: function (data) {
      this.collection.urlFilter.clear();
      this.options.selectedId = "";
      this.options.boundEntityId = null;
      this.collection.reset();
      this.renderList();
    },
    refresh: function (data) {
      if (this.runOnce === 0) {
        this.runOnce++;
        if (this.options.isLocalCollection) {
          if (data && data.preventFetch) {
            this.options.boundEntityId = data.id;
            if (data.collection) {
              this.collection = data.collection || this.collection;
              this.hideLoading();
              this.renderList();
            } else {
              this.showLoading();
            }
          }
        } else {
          this.showLoading();
          this.collection.urlFilter.set({
            entity: this.options.boundEntity.singularize(),
            id: data.id
          });
          this.options.boundEntityId = data.id;
          this.collection.fetch({
            success: $.proxy(function () {
              this.hideLoading();
              this.renderList();
            }, this)
          });
        }
      }
    },
    close: function () {
      var chosen = $(this.el).find("select").data("chosen");
      if (chosen) {
        chosen.close_field();
      }
    },
    reloadResults: function () {
      var $el = $(this.el);
      $el.find("input").addClass("search-loader");
      this.collection.search.clear();
      this.collection.urlFilter.set({
        entity: this.options.boundEntity.singularize(),
        id: this.options.boundEntityId
      });
      this.collection.fetch({
        success: $.proxy(function () {
          $el.find("input").removeClass("search-loader");
          this.renderList();
        }, this)
      });
    },
    setSelection: function (data) {
      if (data.model) {
        if (!this.collection.get(data.model.id)) {
          this.collection.add(data.model);
        }
      }
      if (data.entity === this.options.entity) {
        this.options.selectedId = data.selectedId;
        this.renderList();
      }
    },
    renderList: function () {
      var $select = $(this.el).find(".select-input");
      var tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      var partials = this.partials || {};
      var render = this.compiledTemplate.render(tdata, partials);
      var chosen;
      render = $(render).find(".select-input").html();
      $select.html(render);
      if (this.collection.search) {
        this.collection.search.clear();
        this.collection.fetch(this.searchOptions);
      }
      if (tdata.isDisabled) {
        $select.attr("disabled", "disable");
      } else {
        $select.removeAttr("disabled");
      }
      chosen = $select.data("chosen");
      if (chosen) {
        chosen.default_text = tdata.placeholder;
      }
      $select.trigger("liszt:updated");
    },
    load: function () {
      var self = this;
      var preRender = self.options.preRender;
      self.refreshProxy = $.proxy(self.refresh, self);
      self.resetProxy = $.proxy(self.reset, self);
      self.selectionProxy = $.proxy(self.setSelection, self);
      self.closeProxy = $.proxy(self.close, self);
      T1.EventHub.subscribe("dropdown:" + self.options.refreshEvent, self.refreshProxy);
      T1.EventHub.subscribe("dropdown:" + self.options.entity + ":reset", self.resetProxy);
      T1.EventHub.subscribe("dropdown:" + self.options.entity + ":selection", self.selectionProxy);
      T1.EventHub.subscribe("dropdown:" + self.options.entity + ":close", self.closeProxy);
      self.render().then($.proxy(function () {
        if (self.canLoad) {
          var selectBox;
          var selectBoxConfig;
          var autoCompleteConfig;
          var $el = $(self.el);
          var $input;
          self.dataReady = true;
          selectBoxConfig = {
            default_text_default: self.boundEntityId ? self.placeholder : self.preSelect,
            onChange: $.proxy(function () {
              var id = selectBox.val();
              self.options.selectedId = id;
              $el.find("ul").attr("id", id);
              self.options.selectedId = $el.find("ul").attr("id");
              T1.EventHub.publish("dropdown:" + self.options.selectEvent, {
                id: id,
                model: self.collection.get(id),
                view: self,
                instance: selectBox
              });
            }, self)
          };
          if (self.options.isSearchable) {
            autoCompleteConfig = {
              autoComplete: true,
              searchField: "name",
              onSearchInputCleared: $.proxy(function (searchOpts) {
                var searchOptions = $.extend({
                  fetchRequired: true
                }, searchOpts);
                self.collection.search.clear();
                self.collection.fetch(searchOptions);
              }, self),
              onSearchRequest: $.proxy(function (searchOptions) {
                var success = searchOptions.success;
                var passedModels = [];
                $el.find("ul").addClass("search-results");
                if (self.options.isLocalCollection) {
                  $input = $el.find("input");
                  $input.val($.trim($input.val()));
                  $input.removeClass("search-loader");
                } else {
                  if (preRender) {
                    searchOptions.success = $.proxy(function (data) {
                      $.each(data.models, function (i, model) {
                        if (model.toJSON()) {
                          passedModels.push(model);
                        }
                      });
                      data.models = passedModels;
                      success(data);
                    }, this);
                  }
                  searchOptions = $.extend(self.options.defaultSearchParams, {
                    fetchRequired: true
                  }, searchOptions);
                  self.collection.search.set(searchOptions);
                }
              }, self)
            };
            selectBoxConfig.searchFieldDisabled = false;
          }
          if (self.options.extraLinkLabel) {
            selectBoxConfig.extraLink = {
              label: self.options.extraLinkLabel,
              onClick: self.options.extraLinkHandler,
              class: self.options.extraLinkClass
            };
          }
          selectBox = $el.find(".select-input");
          T1.Selectbox(selectBox, selectBoxConfig, autoCompleteConfig);
          if (self.options.clearOnClose) {
            selectBox.on("liszt:hiding_dropdown", $.proxy(function () {
              if (self.collection.search) {
                self.collection.search.clear();
              }
            }, self));
          }
          if (!self.options.initialLoad) {
            self.hideLoading();
          }
          self.handleListLoad();
        }
      }, self));
    },
    handleListLoad: function () {
      var self = this;
      var $el = $(self.el);
      $el.find(".select").click(function () {
        var $hasULClass = $el.find("ul").hasClass("search-results");
        var $hasLIClass = $el.find("li").hasClass("result-selected");
        if (!$hasULClass && !$hasLIClass || !$hasULClass || $el.find("li").hasClass("no-results")) {
          self.reloadResults();
        }
      });
      $el.find("ul").click(function () {
        $el.find("ul").removeClass("search-results");
      });
    },
    unload: function () {
      T1.EventHub.unsubscribe("dropdown:" + this.options.refreshEvent, this.refreshProxy);
      T1.EventHub.unsubscribe("dropdown:" + this.options.entity + ":reset", this.resetProxy);
      T1.EventHub.unsubscribe("dropdown:" + this.options.entity + ":selection", this.selectionProxy);
      T1.EventHub.unsubscribe("dropdown:" + this.options.entity + ":close", this.closeProxy);
      this.refreshProxy = null;
      this.resetProxy = null;
      this.selectionProxy = null;
      this.closeProxy = null;
      if (this.collection) {
        if (this.collection.urlFilter) {
          this.collection.urlFilter.destroy();
        }
        this.collection.unregister();
        this.collection.reset([]);
      }
      this.collection = null;
    },
    render: function () {
      var tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      var partials = this.partials || {};
      var deferred = $.Deferred();
      var $el = $(this.el);
      if (!this.compiledTemplate) {
        this.compiledTemplate = Hogan.compile(this.template);
      }
      tdata.appLocation = T1.Location.get();
      this.assignAsCurrency(tdata);
      $el.html(this.compiledTemplate.render(tdata, partials));
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        $el.find("input, textarea").placeholder();
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    serialize: function () {
      var isDisabled;
      var result = [];
      var placeholder;
      var obj;
      var $chosenText = $(this.el).find(".chzn-single span").html();
      if (this.collection) {
        this.collection.each($.proxy(function (model) {
          obj = model.toJSON();
          if (Number(model.id) === Number(this.options.selectedId)) {
            obj.selected = ' selected="true"';
          }
          result.push(obj);
        }, this));
      }
      this.options.placeholder = $chosenText ? $chosenText : this.options.placeholder;
      if (this.options.boundEntityId) {
        placeholder = result.length > 0 ? this.options.placeholder : this.options.notFound;
      } else {
        placeholder = this.options.preSelect;
      }
      if (this.options.disableCheck && typeof this.options.disableCheck === "function") {
        isDisabled = this.options.disableCheck(result.length, !!this.options.boundEntityId, result, this.options.boundEntityId);
      } else {
        isDisabled = this.collection && this.collection.models && this.collection.models.length === 0;
      }
      return {
        dataReady: this.canLoad,
        dataBind: this.options.dataBind,
        isDisabled: isDisabled,
        placeholder: placeholder,
        options: result
      };
    }
  });
});
