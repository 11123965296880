define('collections/legacyContracts',["jQuery", "T1", "T1Collection", "models/legacyContract"], function ($, T1, T1Collection, LegacyContract) {
  T1.Models.LegacyContracts = T1Collection.extend({
    canCache: true,
    cacheDuration: 18e5,
    fetchOptions: {
      full: "*",
      currency_code: "USD"
    },
    fixture: "-legacy_contracts",
    model: LegacyContract,
    filterConfiguration: {
      filters: [{
        entity: "organization",
        fetchOnChange: false
      }],
      enableEvents: true
    }
  });
  return T1.Models.LegacyContracts;
});
