define('modules/reporting/campaigns/dataExport/createEdit/models/filterBinModel',['require','Underscore','jQuery','Backbone'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Backbone = require("Backbone");
  return Backbone.Model.extend({
    initialize(attributes, options) {
      this.SharedModel = options.sharedModel;
      if (this.SharedModel.getBinChangeItems().length) {
        this.updateData();
      }
    },
    updateData() {
      const newBinData = [];
      const changeItems = this.SharedModel.getBinChangeItems();
      let binData = this.getBinData();
      if (binData.length) {
        let isAdditionalFilter;
        binData = binData.filter(item => !item.groupClass);
        if (changeItems.remove.length) {
          isAdditionalFilter = changeItems.remove[0].isAdditionalFilter;
          binData = _.reject(binData, function (binItem) {
            return changeItems.remove.find(removeItem => removeItem.id === binItem.id);
          });
        }
        if (changeItems.add.length) {
          isAdditionalFilter = changeItems.add[0].isAdditionalFilter;
          binData = _.reject(binData, function (binItem) {
            return changeItems.add.find(function (item) {
              return item.parentID === binItem.id || item.topParentID === binItem.id || item.id === binItem.parentID || item.id === binItem.topParentID;
            });
          });
        }
        if (!isAdditionalFilter) {
          binData = _.reject(binData, item => item.type === "organization");
        }
        binData = [...binData, ...changeItems.add];
      } else {
        binData = changeItems.add;
      }
      const groupedData = _.groupBy(binData, gridItem => gridItem.groupName);
      _.each(groupedData, function (value, key) {
        const groupRow = {
          children: [],
          groupClass: "group-row",
          isGroupRow: "none",
          name: key
        };
        newBinData.push(groupRow);
        for (let i = 0, numObjs = value.length; i !== numObjs; ++i) {
          newBinData.push(value[i]);
          groupRow.children.push(value[i].id);
        }
      });
      this.setBinData(newBinData);
    },
    removeData(id) {
      let isAdditionalFilter, isOrg;
      let binData = this.getBinData();
      binData = _.reject(binData, function (item) {
        if (item.id === id) {
          isOrg = item.type === "organization";
          isAdditionalFilter = item.isAdditionalFilter;
          return true;
        }
        return false;
      });
      binData = _.reject(binData, function (item) {
        if (item.groupClass) {
          item.children = _.reject(item.children, childID => childID === id);
          if (!item.children.length) {
            return true;
          }
          return false;
        }
        return false;
      });
      this.setBinData(binData);
      if (isOrg) {
        this.trigger("deselect:all");
      } else if (isAdditionalFilter) {
        this.trigger("deselect:additionalFilter", id);
      } else {
        this.trigger("deselect:items", id);
      }
    },
    selectAll() {
      const orgInfo = this.SharedModel.getOrgInfo();
      const orgID = orgInfo.id;
      const orgName = orgInfo.name;
      const newBinData = [{
        children: [orgID],
        groupClass: "group-row",
        isGroupRow: "none",
        name: orgName
      }, {
        groupName: orgName,
        hasChildren: "",
        iconColor: "",
        iconState: "",
        iconType: "",
        id: orgID,
        isExpanded: "",
        level: "",
        name: orgName,
        parentID: orgID,
        parentName: orgName,
        path: "",
        showIncludeIcon: "",
        title: orgName,
        topParentID: orgID,
        type: "organization"
      }];
      this.retainAdditionalFilters(newBinData);
      this.setBinData(newBinData);
    },
    deselectAll() {
      const newBinData = [];
      this.retainAdditionalFilters(newBinData);
      this.setBinData(newBinData);
      this.trigger("deselect:all");
    },
    retainAdditionalFilters(newBinData) {
      const binData = this.getBinData();
      const additionalFilterItems = binData.filter(item => item.isAdditionalFilter);
      if (additionalFilterItems.length) {
        newBinData.push({
          children: additionalFilterItems.map(item => item.id),
          groupClass: "group-row",
          isGroupRow: "none",
          name: "Exchanges"
        });
        newBinData.push(...additionalFilterItems);
      }
    },
    setBinData(binData) {
      this.SharedModel.setBinItems(binData);
      this.set({
        binData: binData
      }, {
        silent: true
      });
      this.trigger("change:binData", this);
    },
    getBinData() {
      return $.extend([], this.get("binData"));
    }
  });
});
