define('modules/changelog/views/dialog',["jQuery", "T1", "T1View", "text!modules/changelog/templates/changeLog.html", "T1Layout", "jQPlugins/jquery.trunk8"], function ($, T1, T1View, template, T1Layout) {
  var ChangeLogView = T1View.extend({
    template: template,
    initialize: function (args) {
      var entityType = args.entityType;
      if (entityType === undefined) {
        throw new Error("Entity Type not defined");
      }
      this.entityType = entityType;
    },
    initLayout: function () {
      var self = this;
      var layout;
      layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-body",
        template: "<div class='box-fix filter-container'></div><div class='grid-container'></div>",
        layout: {
          ".filter-container": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: function (term) {
                T1.EventHub.publish("search:changelog", term);
              },
              clearFilterCallback: function () {
                T1.EventHub.publish("clear-search:changelog");
              }
            }
          }],
          ".grid-container": [{
            module: "changelog",
            viewType: "grid",
            options: {
              entityType: self.entityType,
              id: this.model.id
            }
          }]
        }
      });
      this.layout = layout;
    },
    events: {
      "click .w-foot .actions .close": "closeDialog"
    },
    closeDialog: function (event) {
      event.preventDefault();
      $(this.el).dialog("close");
    },
    load: function (data) {
      var self = this;
      var $el = $(this.el);
      var $dialog;
      if (!$el.hasClass("change-log-dialog")) {
        $el.addClass("change-log-dialog");
      }
      $dialog = $el.dialog({
        closeText: "",
        autoOpen: true,
        modal: true,
        width: 690,
        open: function () {
          $(this).closest(".ui-dialog").css("overflow", "visible");
        },
        close: function () {
          $el.dialog("destroy").remove();
        }
      }).css("visibility", "hidden");
      this.render().then(function () {
        $dialog.position({
          my: "center",
          at: "center",
          of: window
        }).css("visibility", "visible");
        self.initLayout();
        self.layout.load();
        $(self.el).find(".w-head .truncate").trunk8();
      });
    },
    serialize: function () {
      var isCampaign = this.entityType === "campaign";
      var isPixel = this.entityType === "pixel";
      var showCampaignInfo = isCampaign || this.entityType === "strategy";
      var model = this.model;
      var campaign = showCampaignInfo ? isCampaign ? this.model : model.get("campaign") : undefined;
      var advertiser;
      var modelJSON = model.toJSON();
      advertiser = campaign ? campaign.get("advertiser") : model.get("advertiser");
      modelJSON.isCampaign = isCampaign;
      modelJSON.showCampaignInfo = showCampaignInfo;
      modelJSON.campaignName = campaign ? campaign.get("name") : "";
      modelJSON.advertiserName = advertiser ? advertiser.get("name") : "";
      if (isPixel) {
        if (modelJSON.advertiser !== undefined) {
          modelJSON.advertiserName = modelJSON.advertiser.name;
        } else {
          modelJSON.advertiserName = modelJSON.pixel_provider.name;
        }
      }
      modelJSON.created_on_actual = modelJSON.created_on_actual || modelJSON.created_on;
      modelJSON.entityType = this.entityType;
      return modelJSON;
    }
  });
  return $.extend(ChangeLogView, {
    showDialog: function (originalConfigs) {
      var configs = {
        el: originalConfigs.el || $("<div></div>")
      };
      var changeLogModal;
      configs = $.extend({}, originalConfigs, configs);
      changeLogModal = new ChangeLogView(configs);
      changeLogModal.load();
      return changeLogModal;
    }
  });
});
