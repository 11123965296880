define('modules/T1App/edit/team/views/filterGrid',["jQuery", "Underscore", "T1", "T1View", "T1Comm", "text!modules/T1App/edit/team/templates/filterGrid.html", "collections/appsT1Users", "models/sessionUser", "T1Model"], function ($, _, T1, T1View, T1Comm, template, AppsT1Users, User) {
  "use strict";

  var MetaTeamGridCreateEdit;
  var T1Publish = T1.EventHub.publish;
  var _currentDevteam = [];
  var _oldDevteam = [];
  MetaTeamGridCreateEdit = T1View.extend({
    template: template,
    eventhubEvents: {
      "teamModel.gridDataChange": function () {
        this.clearFilter();
      },
      "change:binElements": "binClickHandler",
      "search:onSearchRequest": function (args) {
        this.appsT1UsersColl.searchTerm = args.value.length > 0 ? args.value : undefined;
        if (this.searchTerm === this.appsT1UsersColl.searchTerm) {
          return;
        }
        this.searchTerm = this.appsT1UsersColl.searchTerm;
        this.filterCollection(args.field, args.value);
      }
    },
    initialize: function (args) {
      var user = User.getUser();
      this.appsT1UsersColl = new AppsT1Users();
      this.userID = parseInt(user.get("id"), 10);
      this.model = args.model;
      _oldDevteam = this.model.parseDevTeam();
      _currentDevteam = _oldDevteam;
      this.totalCheckeditems = this.model.devTeam.length;
    },
    events: {
      "click .team-grid-include-icon": "gridClickHandler"
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      var usersGrid;
      var model = this.model;
      this.gridData = this.addGridData(model.devTeam);
      this.render().then(function () {
        usersGrid = $el.find("#usersGrid")[0];
        usersGrid.data = self.gridData;
      });
    },
    addGridData: function (loadDevTeam) {
      var orgUser = this.model.parsedOrgUsers;
      var devTeam = loadDevTeam || _currentDevteam;
      for (var i = 0; i < orgUser.length; i++) {
        orgUser[i].searchTerm = "";
        for (var k = 0; k < devTeam.length; k++) {
          if (loadDevTeam) {
            if (parseInt(orgUser[i].id, 10) === parseInt(devTeam[k].user_id, 10)) {
              orgUser[i].classlist = "selected";
            }
          } else {
            if (parseInt(orgUser[i].id, 10) === parseInt(devTeam[k], 10)) {
              orgUser[i].classlist = "selected";
            }
          }
        }
      }
      return orgUser;
    },
    filterCollection: function (field, term) {
      var self = this;
      var orgID = self.model.orgID;
      var usersGrid = $(this.el).find("#usersGrid")[0];
      var gridData;
      usersGrid.isLoading = true;
      this.appsT1UsersColl.filterBy(field, term, orgID, function (data) {
        usersGrid.isLoading = false;
        gridData = self.searchGridData(data, term);
        usersGrid.data = gridData;
      });
    },
    searchGridData: function (data, term) {
      var self = this;
      var gridData = [];
      var currentDev = self.markFilterDev(data.models, _currentDevteam);
      if (currentDev.length > 0) {
        _.each(currentDev, function (item) {
          gridData.push({
            id: item.get("id"),
            email: item.get("email"),
            name: item.get("name"),
            first_name: item.get("first_name"),
            last_name: item.get("last_name"),
            role: item.get("role"),
            classlist: item.get("classlist") || "",
            searchTerm: term
          });
        });
      } else {
        gridData.push({
          id: "",
          email: "",
          name: "",
          first_name: "No result found",
          last_name: "",
          role: "",
          classlist: "hidden",
          searchTerm: term
        });
      }
      return gridData;
    },
    markFilterDev: function (gridData, currentDevTeam) {
      for (var i = 0; i < gridData.length; i++) {
        for (var k = 0; k < currentDevTeam.length; k++) {
          if (parseInt(gridData[i].id, 10) === parseInt(currentDevTeam[k], 10)) {
            gridData[i].attributes.classlist = "selected";
          }
        }
      }
      return gridData;
    },
    clearFilter: function () {
      var usersGrid = $(this.el).find("#usersGrid")[0];
      usersGrid.isLoading = true;
      usersGrid.data = this.addGridData();
      usersGrid.isLoading = false;
    },
    binClickHandler: function (event) {
      var $devID = $(event.currentTarget).closest(".dev-team");
      var email = $devID.text();
      var id = parseInt($devID.attr("id"), 10);
      var msg = "";
      if (this.userID === id) {
        msg = "Unable to remove " + email + ", you cannot remove yourself";
        T1Publish("change:formElements", msg);
        return false;
      }
      if (this.totalCheckeditems < 2) {
        msg = "Unable to remove " + email + ", there must be at least 1 user on the Team at all times";
        T1Publish("change:formElements", msg);
        return false;
      } else if (this.totalCheckeditems > 1) {
        $(".dev-team-container").find("#" + id).remove();
        $("strand-icon#" + id).removeClass("selected");
        this.totalCheckeditems = this.totalCheckeditems - 1;
        _currentDevteam = _.without(_currentDevteam, id);
        this.model.currentDevteam = _currentDevteam;
      }
      this.markCurrentDev(id, "remove");
      T1Publish("change:formElements", msg);
    },
    gridClickHandler: function (event) {
      var $icon = $(event.currentTarget);
      var id = parseInt($icon.attr("id"), 10);
      var action = "";
      var msg = "";
      var email = $(event.delegateTarget).find("strand-highlight#" + id).text();
      if (this.userID === id && !$icon.hasClass("selected")) {
        msg = "You cannot add yourself to the development team.";
        T1Publish("change:formElements", msg);
        return false;
      }
      if (this.userID === id && $icon.hasClass("selected")) {
        msg = "Unable to remove " + email + ", you cannot remove yourself";
        T1Publish("change:formElements", msg);
        return false;
      }
      if ($icon.hasClass("selected") && this.totalCheckeditems < 2) {
        msg = "Unable to remove " + email + ", there must be at least 1 user on the Team at all times";
        T1Publish("change:formElements", msg);
        return false;
      } else if ($icon.hasClass("selected") && this.totalCheckeditems > 1) {
        $icon.removeClass("selected");
        action = "remove";
        $(".dev-team-container").find("#" + id).remove();
        this.totalCheckeditems = this.totalCheckeditems - 1;
      } else {
        $icon.addClass("selected");
        action = "add";
        $(".dev-team-container").append('<div id="' + id + '" class="dev-team">' + email + '<strand-icon class="icon remove-dev pull-right" type="delete" width="10" height="10"></strand-icon></div>');
        this.totalCheckeditems = this.totalCheckeditems + 1;
      }
      T1Publish("change:formElements", msg);
      this.devTeamHandler(id, action);
      this.markCurrentDev(id, action);
    },
    devTeamHandler: function (id, action) {
      if (action === "remove") {
        _currentDevteam = _.without(_currentDevteam, id);
      } else if (action === "add") {
        _currentDevteam.push(id);
      }
      this.model.currentDevteam = _currentDevteam;
      return;
    },
    markCurrentDev: function (id, action) {
      var gridData = this.model.orgUser;
      action === "add" ? action = "selected" : action = "";
      for (var i = 0; i < gridData.length; i++) {
        if (parseInt(gridData[i].id, 10) === parseInt(id, 10)) {
          gridData[i].attributes.classlist = action;
        }
      }
      return gridData;
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      if ($el[0] && Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return MetaTeamGridCreateEdit;
});
