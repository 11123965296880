define('models/componentCreative',["T1", "T1Model", "jQuery", "Underscore"], function (T1, T1Model, $, _) {
  T1.Models.ComponentCreative = T1Model.extend({
    apiRoot: COMPASS_ENV.COMPONENT_API_BASE,
    url: "creatives",
    fixture: "-componentCreative",
    save: function (attributes, options) {
      var canEdit, modelErrors;
      var self = this;
      var statusInvalid = options && options.statusInvalid ? options.statusInvalid : $.noop;
      function sync() {
        var model = self;
        var success = options.success;
        var errorCallback = options.error;
        var method, errorDisplaySuppressingConfig;
        options.success = function (returnedAttributes) {
          var responseData = returnedAttributes.data;
          if (!model.set(responseData)) {
            return !1;
          }
          model.id = responseData.atomic_creative_id;
          if (success) {
            success(model, returnedAttributes);
          }
        };
        options.statusInvalid = function (data) {
          statusInvalid(data);
        };
        options.error = function (data) {
          if (errorCallback) {
            errorCallback(model, data, options);
          } else {
            model.trigger("error", model, data, options);
          }
        };
        method = self.isNew() ? "create" : "update";
        if (self.isNew()) {
          attributes = $.isEmptyObject(attributes) ? {} : $.extend({
            version: 0
          }, attributes);
        } else {
          attributes = $.isEmptyObject(attributes) ? self.attributes : $.extend({
            version: self.get("version")
          }, attributes);
        }
        if (self.includeIdInPost) {
          attributes.id = self.id;
        }
        errorDisplaySuppressingConfig = options.errorDisplaySuppressingConfig;
        options = _.extend(options, {
          dataType: "json",
          errorDisplaySuppressingConfig: {
            errorSuppressed: true
          },
          processAjaxResponse: function (data) {
            var jsonData = typeof data === "string" ? JSON.parse(data) : data;
            var statusCode = jsonData.data && jsonData.data.creative_id ? "ok" : "error";
            return {
              statusCode: statusCode,
              jsonData: jsonData
            };
          }
        });
        if (errorDisplaySuppressingConfig) {
          options.errorDisplaySuppressingConfig = errorDisplaySuppressingConfig;
        }
        return self.sync.call(self, method, attributes, options);
      }
      options = options || {};
      if (!this.isNew()) {
        canEdit = this.canEdit();
        canEdit.done(function (editable) {
          if (!editable) {
            return;
          }
          return sync();
        });
      } else {
        modelErrors = this.validate ? this.validate(attributes) : {};
        if ($.isArray(modelErrors) && modelErrors.length) {
          statusInvalid(modelErrors);
        } else {
          $.each(attributes, function (attr, val) {
            if (val === "" || val === undefined) {
              delete attributes[attr];
            }
          });
          return sync();
        }
      }
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    }
  });
  return T1.Models.ComponentCreative;
});
