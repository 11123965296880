define('T1Signature',["T1"], function (T1) {
  "use strict";

  T1.Signature = function () {
    function Signature() {
      const hash = new Map();
      function set(key, val) {
        if (typeof key === "function" || typeof val === "function") {
          throw Error("Signature: keys or values cannot be functions");
        }
        if (!key || key === null) {
          throw Error("Signature: need a key to call set method");
        }
        if (typeof key === "object") {
          const entries = key instanceof Map ? key.entries() : Object.entries(key);
          for (const [objKey, objVal] of entries) {
            set(objKey, objVal);
          }
          return true;
        }
        if (typeof val !== "string") {
          throw Error("Signature: need a value as a string to call set method");
        }
        if (!val || val === null) {
          throw Error("Signature: need a value to call set method");
        }
        hash.set(key, val);
        return true;
      }
      function get(key) {
        if (!key) {
          throw Error("Signature: need a key to call get method");
        }
        return hash.get(key);
      }
      function sign(key, template) {
        const arry = [];
        const hasTmpl = Array.isArray(template) && template.length;
        if (typeof key === "function") {
          throw Error("Signature: cannot sign with key as a function");
        }
        if (key !== null && typeof key === "object") {
          throw Error("Signature: cannot sign with key as an object");
        }
        if (hasTmpl) {
          template.forEach(item => {
            if (hash.get(item)) {
              arry.push(hash.get(item));
            } else if (typeof item === "string") {
              arry.push(item);
            }
          });
        } else {
          for (const hashVal of hash.values()) {
            arry.push(hashVal);
          }
        }
        if (key) {
          arry.push(key);
        }
        return arry.join(".");
      }
      return {
        get: get,
        set: set,
        sign: sign
      };
    }
    return {
      create: () => new Signature()
    };
  }();
});
