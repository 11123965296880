define('models/creative',["T1", "T1Model", "Backbone", "models/vendorPixel", "models/creativeAsset", "collections/creativeAssets"], function (T1, T1Model, Backbone, VendorPixel, CreativeAsset, CreativeAssets) {
  T1.Models.Creative = T1Model.extend({
    url: "creative/",
    relations: [{
      type: Backbone.HasOne,
      key: "vendor_pixel",
      relatedModel: "VendorPixel",
      includeInJSON: true
    }, {
      type: Backbone.HasMany,
      key: "creative_asset",
      relatedModel: CreativeAsset,
      collectionType: CreativeAssets,
      includeInJSON: true
    }],
    setMainTabSection: function (sectionId) {
      this.set({
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    }
  });
  return T1.Models.Creative;
});
