define('collections/users',["jQuery", "Underscore", "T1", "T1Collection", "models/user", "models/sessionUser", "T1Sortable"], function ($, _, T1, T1Collection, User, SessionUser, Sortable) {
  var sortConfiguration = {
    fields: {
      admin: {
        actual: "admin",
        display: "admin"
      },
      manager: {
        actual: "manager",
        display: "manager"
      },
      reporter: {
        actual: "reporter",
        display: "reporter"
      }
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: User,
    currentSearchConfig: [],
    searchConfiguration: {
      fields: {
        email: {
          type: "exact"
        },
        active: {
          type: "enum"
        },
        role: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    urlString: "users",
    fetchOptions: {
      sort_by: "-updated_on",
      full: "*"
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    toggleAllSelected: function (isSelected) {
      this.each(function (model) {
        model.set({
          selected: isSelected
        });
      });
    },
    applySearchFilter: function (field, term) {
      var searchType;
      var fieldIndex = -1;
      if (field !== undefined && term !== undefined) {
        searchType = {
          term: term,
          searchField: field
        };
        $.each(this.currentSearchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
            return;
          }
        });
        if (fieldIndex === -1) {
          if (term !== "") {
            this.currentSearchConfig.push(searchType);
          }
        } else {
          if (term === "") {
            this.currentSearchConfig.splice(fieldIndex, 1);
          } else {
            this.currentSearchConfig[fieldIndex] = searchType;
          }
        }
      }
    },
    filterBy: function (field, term, success) {
      var self = this;
      var onSuccess = success || $.noop;
      this.applySearchFilter(field, term);
      this.search.set({
        params: this.currentSearchConfig,
        fetchRequired: true,
        success: function (coll) {
          self.trigger("reset");
          onSuccess(coll);
        }
      });
    }
  });
  return T1.Models.Users = Sortable.extendCollection(collection, sortConfiguration);
});
