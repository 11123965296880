define('collections/creativesAssign',["jQuery", "Underscore", "T1", "T1Collection", "models/atomicCreative", "T1Sortable"], function ($, _, T1, T1Collection, AtomicCreative) {
  T1.Models.AtomicCreatives = T1Collection.extend({
    model: AtomicCreative,
    canCache: false,
    cors: COMPASS_ENV.VIDEO_API_BASE + "/creatives/proxy/?url=",
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }, {
        entity: "agency",
        fetchOnChange: false
      }, {
        entity: "organization",
        fetchOnChange: false
      }]
    },
    searchConfiguration: {
      fields: {
        status: {
          type: "enum"
        },
        concept_id: {
          type: "exact"
        },
        media_type: {
          type: "exact"
        },
        multiField: {
          type: "multiField"
        },
        advertiser_id: {
          type: "exact"
        },
        file_type: {
          type: "exact"
        }
      }
    },
    fetchOptions: {
      full: "*",
      with: "concept"
    },
    fetch: function (opts) {
      T1Collection.prototype.fetch.call(this, opts);
    },
    urlString: "atomic_creatives",
    parse: function (data) {
      var parsedData = T1Collection.prototype.parse.call(this, data);
      var ac;
      if ($.isArray(parsedData)) {
        $.each(parsedData, function (key, entity) {
          ac = new AtomicCreative(entity);
          _.extend(entity, ac.parse.call(ac, entity));
        });
      }
      return parsedData;
    }
  });
  return T1.Models.AtomicCreatives;
});
