define('modules/creatives/display/main',["jQuery", "T1", "T1Module", "models/userPreferences", "models/bulk3Pas", "models/t1asAsset", "collections/organizations", "collections/advertisers", "T1Permissions", "collections/creativesConceptsStrategies", "T1Preferences", "T1Signature"], function ($, T1, T1Module, UserPreferences, Bulk3Pas, T1asAsset, Organizations, Advertisers, T1Permissions, StrategyConcepts) {
  "use strict";

  var instance;
  var bulk3Pas = new Bulk3Pas();
  var t1asAsset = new T1asAsset();
  var advertisers = new Advertisers(null, {
    isPaginated: false
  });
  var strategies = new StrategyConcepts(null, {
    pageLimit: 50
  });
  if (instance === undefined) {
    instance = new T1Module({
      name: "creatives/display",
      viewCfgs: {
        details: {},
        classification: {},
        createEdit: {},
        createEditBulk: {
          model: bulk3Pas
        },
        createEditBulkReview: {
          model: bulk3Pas
        },
        createBulk3PASReview: {
          model: bulk3Pas
        },
        createT1AS: {
          model: t1asAsset
        },
        createT1ASReview: {
          model: t1asAsset
        },
        grid: {},
        list: {
          advertisers: advertisers
        },
        reviewGrid: {},
        t1AsReviewGridInvalid: {},
        t1AsReviewGridValid: {},
        addCreativeType: {},
        clickThruModal: {},
        conceptModal: {},
        preview: {},
        creative_grid: {},
        strategy_list: {
          collection: strategies
        }
      },
      defaultView: "createEdit",
      initialize: function () {
        var orgPromise = Organizations.getOrganizations().getSelected();
        window.Config = window.Config || {};
        window.Config.CLASSIFICATION = COMPASS_ENV.CREATIVES_CLASSIFICATION_URL || "/";
        orgPromise.then(function (orgId) {
          advertisers.urlFilter.set({
            entity: "organization",
            id: orgId
          });
        });
      }
    });
  }
  return instance;
});
