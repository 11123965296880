define('modules/nav/views/list',["jQuery", "T1", "T1View", "text!modules/nav/templates/vertical.html", "T1Permissions", "models/userPreferences"], function ($, T1, T1View, template, T1Permissions, UserPreferences) {
  return T1View.extend({
    template: template,
    selected: null,
    defaultSelected: "campaigns",
    sections: {},
    events: {
      "guide-dismiss strand-guide": "closeGuide"
    },
    audiences: "",
    initialize: function () {
      var self = this;
      var eventHub = T1.EventHub;
      var signature = T1.Signature.create();
      eventHub.subscribe("route.changeLocation", function (loc) {
        self.setSelected(loc);
      });
      signature.set(new Map([["root", "ui"], ["module", "nav"], ["view", "list"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["audiences"]);
      $.map(self.options.sections, function (s) {
        return self.sections[s.uid] = true;
      });
    },
    load: function () {
      this.setSelected({
        module: T1.Location.get().substr(1)
      });
      if (!this.preferences.get("audiences")) {
        this.loadGuide();
      }
    },
    loadGuide: function () {
      var parent = this.el.parent();
      this.initialIndex = parent.css("z-index");
      parent.css("z-index", "10000");
      this.el.find("strand-guide")[0].show();
    },
    closeGuide: function () {
      this.el.parent().css("z-index", this.initialIndex);
      this.audiences = "1";
      this.preferences.save();
    },
    setSelected: function (loc) {
      var module = loc.module;
      function findDefault() {
        if (module === "pixels" || module === "segments") {
          return "audiences";
        }
        return this.defaultSelected;
      }
      this.selected = this.sections[module] ? module : findDefault.call(this);
      this.render();
    },
    serialize: function () {
      var sections;
      var self = this;
      sections = $.map(self.options.sections, function (sectionObj) {
        var hasPermission, returnVal;
        if (sectionObj.uid === "reports") {
          hasPermission = !T1Permissions.check("feature", "reporting_hide");
        } else {
          hasPermission = sectionObj.permission ? T1Permissions.check("feature", sectionObj.permission) : true;
        }
        if (hasPermission && sectionObj.url) {
          sectionObj.selected = self.selected === sectionObj.uid;
          returnVal = sectionObj;
        }
        return returnVal;
      });
      return {
        uid: self.options.uid,
        name: self.options.name,
        sections: sections,
        showAudiencesGuide: !this.preferences.get("audiences")
      };
    }
  });
});
