define('modules/audiences/lookalikes/create/views/createEdit',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "T1Permissions", "text!modules/audiences/lookalikes/create/templates/createEdit.html", "models/user", "models/advertiser", "models/organization", "collections/organizations", "models/lookalikesModel", "../../../segments/create/views/advertisers", "text!modules/audiences/segments/create/templates/unsavedChanges.html", "modules/audiences/segments/segmentUtils", "jQueryUI"], function ($, _, Backbone, Hogan, T1, T1Permissions, template, User, Advertiser, Organization, Organizations, Model, Advertisers, unsavedChangesTemplate, Util) {
  "use strict";

  function updateBreadCrumb() {
    T1.EventHub.publish("updateNavPath", {
      locations: [{
        label: "New Lookalike Model",
        location: "#audiences/lookalikes/create"
      }]
    });
  }
  var createEditView = Backbone.View.extend({
    template: Hogan.compile(template),
    unsavedChangesTemplate: unsavedChangesTemplate,
    initialize: function () {
      this.boundReload = this.reload.bind(this);
      T1.EventHub.subscribe("organization:select", this.boundReload);
    },
    load: function () {
      updateBreadCrumb();
      this.render();
    },
    events: {
      "changed #advertisers": "advertiserChanged",
      "changed #advertisers strand-input": "searchAdvertisers",
      "changed #modelName": "nameChanged",
      "changed #regions": "regionChanged",
      "click #seedType": "seedTypeChanged",
      "click #seedPixelMulti": "seedPixelsChanged",
      "changed .multiSearch": "multiSelectSearch",
      "click #countryMulti": "countriesChanged",
      "changed #tacCheck": "tacCheckChanged",
      "click #dataPixelMulti": "dataPixelChanged",
      "changed #prospectCheck": "prospectCheckChanged",
      "changed #remarketCheck": "remarketCheckChanged",
      "changed #crmCheck": "crmCheckChanged",
      "changed #visitorRecency": "visitorRecencyChanged",
      "click #save-model-button": "saveModel",
      "click #cancel-model-button": "cancelCreate"
    },
    render: function () {
      var self = this;
      var renderedTemplate = this.template.render({
        model: "{{model}}",
        scope: "{{scope}}",
        id: "{{model.id}}"
      });
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = renderedTemplate;
      } else {
        $(this.el).html(renderedTemplate);
      }
      this.delegateEvents(this.events);
      T1.resizeWidth();
      T1.EventHub.subscribe("organization:select", self.orgChanged);
      Organizations.getOrganizations().getSelected().then(function (orgId) {
        self.orgId = orgId;
        self.model = new Model({
          org: orgId
        });
        self.model.audienceTypeObj = {};
        self.model.seedType = "event";
        self.loadAdvertisers();
      });
    },
    orgChanged: function () {
      T1.Location.reload();
    },
    loadAdvertisers: function () {
      this.advertisersObj = new Advertisers({
        parent: this,
        allAdv: true
      });
      this.advertisersObj.render();
      this.advertisersObj.loadAdvertisers(this.owner_id);
    },
    loadCountries: function () {
      this.model.countryFetch("#countryMulti");
    },
    searchAdvertisers: function (event) {
      var value = event.srcElement.value;
      if (value) {
        this.advertisersObj.searchAdvertisers(value, event);
      } else {
        this.advertisersObj.loadAdvertisers(event);
      }
    },
    advertiserChanged: function () {
      var advertiserValue = this.el[0].querySelector("#advertisers").value;
      if (typeof advertiserValue === "object") {
        return;
      }
      this.model.modelAdvertiser = advertiserValue;
      this.model.setupFetchAndBindRegions(this.el[0].querySelector("#regions"), this);
      this.sectionTwoEnableDisable();
      this.populateMultiSelects();
      this.saveCheck();
    },
    nameChanged: function () {
      this.model.modelName = this.el[0].querySelector("#modelName").value;
      this.sectionTwoEnableDisable();
      this.saveCheck();
    },
    seedTypeChanged: function () {
      this.model.seedType = this.el[0].querySelector('strand-group[group="seedType"]').value;
      this.populateMultiSelects(this.model.seedType);
    },
    seedPixelsChanged: function (event) {
      this.updateMultiSelect(event);
      this.model.seedPixels = $("#seedPixelMulti").val();
      this.sectionThreeEnableDisable();
      this.saveCheck();
    },
    regionChanged: function (event) {
      this.model.region = this.el[0].querySelector("#regions").value;
      this.countriesEnableDisable();
      this.setUserTypesAndTaC();
      this.sectionThreeEnableDisable();
      this.saveCheck();
    },
    countriesChanged: function (event) {
      this.updateCountrySelect(event);
      this.updateMultiSelect(event);
      this.model.countries = $("#countryMulti").val();
      this.sectionThreeEnableDisable();
      this.saveCheck();
    },
    tacCheckChanged: function () {
      this.model.tacCheck = this.el[0].querySelector("#tacCheck strand-checkbox").checked;
      this.saveCheck();
    },
    prospectCheckChanged: function (event) {
      this.modifyAudienceType(event);
      this.setTaC();
      this.saveCheck();
    },
    remarketCheckChanged: function (event) {
      this.modifyAudienceType(event);
      this.setTaC();
      this.visitorRecencyEnableDisable(event);
      this.saveCheck();
    },
    crmCheckChanged: function (event) {
      this.modifyAudienceType(event);
      this.setTaC();
      this.dataPixelEnableDisable(event);
      this.saveCheck();
    },
    visitorRecencyChanged: function () {
      this.updateAudienceReadyState();
      this.saveCheck();
    },
    dataPixelChanged: function (event) {
      this.updateMultiSelect(event);
      this.updateAudienceReadyState();
      this.saveCheck();
    },
    updateMultiSelect: function (event) {
      var selections = $(event.currentTarget).val();
      var pullDown = $(event.currentTarget).closest("strand-pulldown-button");
      var limit = pullDown[0].dataset.limit;
      var label = pullDown.find("label");
      if (selections && selections.length > limit) {
        $(event.currentTarget).find(":selected:not(.selected)").prop("selected", false);
        return;
      }
      $(event.currentTarget).find(":selected").addClass("selected");
      $(event.currentTarget).find(":not(:selected)").removeClass("selected");
      if (selections === null) {
        label.text("Select");
      } else {
        label.text(selections.length + " selected");
      }
    },
    updateCountrySelect: function (event) {
      var selections = $(event.currentTarget).val();
      var region = event.target.dataset.region;
      if (selections) {
        if (selections.length === 1) {
          $('#countryMulti [data-region!="' + region + '"]').attr("disabled", "disabled");
        }
      } else {
        $("#countryMulti option").removeAttr("disabled");
      }
    },
    populateMultiSelects: function (type) {
      var self = this;
      var advertiser = this.model.modelAdvertiser;
      var seed_type = this.model.seedType;
      var cb = self.bindDataToSelect.bind(this);
      if (!type) {
        if (seed_type !== "data") {
          self.model.pixelFetch(cb, advertiser, seed_type, "#seedPixelMulti");
          self.model.pixelFetch(cb, advertiser, "data", "#dataPixelMulti");
        } else {
          self.model.pixelFetch(cb, advertiser, seed_type, "#seedPixelMulti", "#dataPixelMulti");
        }
      } else {
        self.model.pixelFetch(cb, advertiser, seed_type, "#seedPixelMulti");
      }
    },
    bindDataToSelect: function (target, data, add) {
      if (!add) {
        $(target).val("").click();
        $(target).html("");
      }
      if (data.length === 0) {
        $(target).append('<option class="disabled">No pixels to display</option>');
      }
      data.map(function (pixel) {
        $(target).append('<option value="' + pixel.id + '">' + pixel.name + "</option>");
      });
    },
    multiSelectSearch: function (event) {
      var value = event.srcElement.value;
      var options = $(event.currentTarget).siblings().children();
      if (!value) {
        options.show();
      } else {
        options.map(function (index, item) {
          if ($(item).text().toLowerCase().search(value.toLowerCase()) === -1) {
            $(item).hide();
          } else {
            $(item).show();
          }
        });
      }
    },
    countriesEnableDisable: function () {
      $("#countryMulti").val("").click();
      if (this.model.region) {
        this.loadCountries();
        $("#countryDrop").removeClass("disabled").removeAttr("disabled");
      } else {
        $("#countryDrop").attr("disabled", true);
      }
    },
    sectionTwoEnableDisable: function () {
      if (this.model.modelName && this.model.modelAdvertiser) {
        $("#sectionTwo").children().children('[id!="countryDrop"]').removeClass("disabled").removeAttr("disabled");
      } else {
        $("#sectionTwo").children().children().addClass("disabled").attr("disabled", true);
        $("#visitorGroup").children().addClass("disabled").attr("disabled", true);
        $("#dataPixelGroup").children().addClass("disabled").attr("disabled", true);
      }
    },
    sectionThreeEnableDisable: function () {
      if (this.model.countries && this.model.seedPixels && !this.seedWarning) {
        $("#sectionThree").children('[id!="visitorGroup"]').children().removeClass("disabled").removeAttr("disabled");
      } else {
        $("#sectionThree").children().children().addClass("disabled").attr("disabled", true).removeAttr("checked");
      }
    },
    setUserTypesAndTaC: function () {
      var currentRegion = this.model.regionsObj.filter(region => region.region === this.model.region)[0];
      this.model.userTypes = currentRegion.userTypes;
      var userTypeList = _.pluck(this.model.userTypes, "userType");
      this.model.recencyOptions = _.pluck(this.model.userTypes, "recency").filter(Boolean);
      this.$("#sectionThree strand-checkbox, #visitorGroup").hide();
      userTypeList.map(userType => {
        if (userType === "PROS") {
          this.$("#prospectCheck").show();
        } else {
          this.$("#remarketCheck").show();
          let recencyOptions = this.model.recencyOptions[0].map(item => ({
            name: item.label,
            value: item.label
          }));
          this.el[0].querySelector("#visitorRecency").set("data", recencyOptions);
          this.$("#visitorGroup").show().children().addClass("disabled").attr("disabled", true);
        }
      });
      if (userTypeList.length === 1) {
        requestAnimationFrame(() => {
          var prospect = this.el[0].querySelector("#prospectCheck");
          prospect.checked = true;
          this.prospectCheckChanged({
            currentTarget: prospect
          });
        });
      }
    },
    setTaC: function () {
      var selectedUserTypes = Object.keys(this.model.audienceTypeObj);
      var termsOfUse = [];
      var termsOfUseChecked = [];
      this.model.userTypes.map(userType => {
        var userTypeName = userType.userType === "PROS" ? "prospect" : "remarket";
        if (selectedUserTypes.includes(userTypeName)) {
          termsOfUse.push(userType.termsOfUse);
          termsOfUseChecked.push(userType.termsOfUseChecked);
        }
      });
      this.model.termsOfUseChecked = termsOfUseChecked.includes(true);
      this.model.tacRequired = termsOfUse.includes("required");
      this.el[0].querySelector("#tacCheck strand-checkbox").checked = this.model.termsOfUseChecked;
      if (termsOfUse.includes("hidden") || !termsOfUse.length) {
        $("#tacCheck").css({
          display: "none"
        });
      } else {
        $("#tacCheck").css({
          display: "inline-block"
        });
      }
    },
    modifyAudienceType: function (event) {
      var checkState = event.currentTarget.checked;
      var target = event.currentTarget.id.slice(0, -5);
      if (checkState) {
        this.model.audienceTypeObj[target] = {
          ready: target === "prospect"
        };
      } else {
        delete this.model.audienceTypeObj[target];
      }
    },
    visitorRecencyEnableDisable: function (event) {
      var checkState = event.currentTarget.checked;
      if (checkState) {
        $("#visitorGroup").children().removeClass("disabled").removeAttr("disabled");
      } else {
        $("#visitorGroup").children().addClass("disabled").attr("disabled", true);
        this.el[0].querySelector("#visitorRecency").reset();
      }
    },
    dataPixelEnableDisable: function (event) {
      var checkState = event.currentTarget.checked;
      if (checkState) {
        $("#dataPixelGroup").children().removeClass("disabled").removeAttr("disabled");
      } else {
        $("#dataPixelGroup").children().addClass("disabled").attr("disabled", true);
        $("#dataPixelMulti").val("").click();
      }
    },
    updateAudienceReadyState: function () {
      if (this.model.audienceTypeObj.crm) {
        this.model.audienceTypeObj.crm.ready = Boolean($("#dataPixelMulti").val());
      }
      if (this.model.audienceTypeObj.remarket) {
        this.model.audienceTypeObj.remarket.ready = Boolean($("#visitorRecency").val());
      }
    },
    saveCheck: function () {
      var self = this;
      var audienceTypeObjReady = function () {
        var objKeys = Object.keys(self.model.audienceTypeObj);
        if (!objKeys.length) {
          return false;
        }
        for (var i = 0; i < objKeys.length; i++) {
          if (!self.model.audienceTypeObj[objKeys[i]].ready) {
            return false;
          }
        }
        return true;
      };
      var saveInfo = this.model.modelName && this.model.modelAdvertiser && this.model.seedPixels && audienceTypeObjReady() && this.model.countries;
      var tacConditionMet = this.model.tacRequired ? this.model.tacCheck : true;
      if (saveInfo && tacConditionMet) {
        $("#save-model-button").removeAttr("disabled");
      } else {
        $("#save-model-button").attr("disabled", true);
      }
    },
    hideFooterMsg: function () {
      var footer = $("#model-create-footer")[0];
      footer.hideMessage();
    },
    showFooterMsg: function (msg) {
      var footer = $("#model-create-footer")[0];
      if (msg) {
        footer.type = "error";
        footer.message = msg;
        footer.showMessage();
      } else {
        footer.type = "success";
        footer.message = "Save Successful";
        footer.showMessage();
      }
    },
    warnForUnsavedChanges: function (event, path) {
      var trueSelf = this;
      var saveDisabled = "";
      var unsavedChangesDialog;
      var $saveButton = this.el.find("#save-model-button")[0];
      if (!this.model.modelAdvertiser && !this.model.modelName) {
        T1.Location.set(path);
        return;
      }
      if ($saveButton && $saveButton.hasAttribute("disabled")) {
        saveDisabled = "disabled";
      }
      unsavedChangesDialog = $(Hogan.compile(this.unsavedChangesTemplate).render({
        saveDisabled: saveDisabled,
        isStandardSegmentEdit: false
      }));
      event.stopPropagation();
      event.preventDefault();
      unsavedChangesDialog.dialog({
        modal: true,
        width: 600,
        autoOpen: true,
        draggable: false,
        dialogClass: "unsaved-segment-changes-dialog"
      });
      $(self.el).append(unsavedChangesDialog);
      $(".ui-dialog-titlebar", $(".unsaved-segment-changes-dialog")).hide();
      $("#unsaved-changes-cancel-button").on("click", function (e) {
        e.preventDefault();
        unsavedChangesDialog.remove();
      });
      $("#unsaved-changes-continue-button").on("click", function (e) {
        unsavedChangesDialog.remove();
        if (path) {
          e.preventDefault();
          if (path.search(/^https:/) !== -1) {
            window.location.assign(path);
          } else {
            T1.Location.set(path);
          }
        } else {
          T1.Location.reload();
        }
      });
      $("#unsaved-changes-save-button").on("click", e => {
        e.preventDefault();
        this.saveModel(e, path);
        unsavedChangesDialog.remove();
      });
    },
    saveModel: function (e, path) {
      var self = this;
      var saveObj = {
        model_name: self.model.modelName,
        advertiser_id: self.model.modelAdvertiser,
        seed_type: self.model.seedType === "data" ? 2 : 1,
        seed_pixels: self.model.seedPixels.map(function (num) {
          return Number.parseInt(num, 10);
        }),
        countries: self.model.countries
      };
      Util.buttonLoader({
        currentTarget: "#save-model-button"
      });
      if (this.model.audienceTypeObj.prospect) {
        saveObj.prospecting = true;
      }
      if (this.model.audienceTypeObj.crm) {
        saveObj.crm_id = $("#dataPixelMulti").val();
      }
      if (this.model.audienceTypeObj.remarket) {
        let recency = this.el[0].querySelector("#visitorRecency").value;
        let [start,, end] = recency.split(/(\s|-)/);
        saveObj.end_window = end;
        saveObj.start_window = start;
        if (recency === "All Visitors") {
          saveObj.end_window = "30";
          saveObj.start_window = "0";
        }
      }
      saveObj.start_sharing = this.model.tacCheck;
      var options = {
        onSuccess: function () {
          self.showFooterMsg();
          self.resetScreen();
          Util.buttonReset(e.target, "Build");
        },
        onError: function (err) {
          var error = err.statusText ? err.statusText : err;
          self.showFooterMsg(error);
        },
        audienceObj: this.model.audienceTypeObj,
        path: path
      };
      self.model.save(saveObj, options);
    },
    resetScreen: function (event) {
      this.el[0].querySelector("#advertisers").reset();
      this.advertiserChanged();
      this.el[0].querySelector("#modelName").value = "";
      this.nameChanged();
      $("#seedPixelMulti").val("").click();
      this.sectionThreeEnableDisable();
      this.el[0].querySelector("#visitorRecency").reset();
      $("#dataPixelMulti").val("").click();
      this.el[0].querySelector("#regions").reset();
      $("#countryMulti").val("").click();
      this.model.audienceTypeObj = {};
      if (event) {
        this.hideFooterMsg();
      }
    },
    cancelCreate: function (e) {
      this.warnForUnsavedChanges(e, "audiences/lookalikes");
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      if (this.layout) {
        this.layout.unload();
      }
      $(this.el).empty();
      this.unbind();
      T1.EventHub.unsubscribe("organization:select");
    }
  });
  return createEditView;
});
