define('models/behavior',["jQuery", "T1", "T1Model", "models/segment"], function ($, T1, T1Model, Segment) {
  "use strict";

  T1.Models.Behavior = T1Model.extend({
    urlRoot: "behaviors",
    apiRoot: T1.SEGMENT_API_BASE,
    url: function () {
      var query = "";
      var id;
      id = this.get("id");
      if (id && id.indexOf("new") === -1 && this.id !== -1) {
        query = "/" + this.id;
      }
      return this.apiRoot + this.urlRoot + query;
    },
    fixture: "-behavior",
    id: "new",
    placeholderId: "",
    parentId: -1,
    advertiserId: -1,
    campaignId: -1,
    parentName: "",
    name: "",
    targetId: -1,
    type: "",
    category: "",
    expression: "",
    canEdit: function () {
      var def = $.Deferred();
      def.resolve(true);
      return def.promise();
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: resp.status.code || "ok"
      };
    }
  });
  return T1.Models.Behavior;
});
