define('modules/changelog/main',["jQuery", "T1", "T1Module", "./utils/mappingTable", "collections/changeLogs"], function ($, T1, T1Module, MappingTable, ChangeLogs) {
  "use strict";

  return new T1Module({
    name: "changelog",
    viewCfgs: {
      dialog: {
        model: null
      },
      grid: {
        collection: null
      }
    },
    defaultView: "grid",
    getView: function (type, opts) {
      var collection;
      var entity;
      var mapping;
      if (type === "grid") {
        mapping = MappingTable[opts.entityType] || {};
        entity = mapping.entity;
        if (!entity) {
          throw new Error("Entity not defined");
        }
        collection = new ChangeLogs([], {
          entity: entity,
          id: opts.id
        });
        this.viewCfgs[type].collection = collection;
      }
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
