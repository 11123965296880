define('modules/campaign/bulkedit/strategies/views/readOnlyBrowse',["jQuery", "Underscore", "T1View", "collections/strategies", "text!../templates/readOnlyGridItem.html", "text!../templates/readOnlyGridHeader.html", "T1GridView", "text!templates/alert.html"], function ($, _, T1View, Strategies, gridItemTemplate, gridHeader, T1GridView) {
  "use strict";

  var BulkEditReviewStrategiesGrid = T1View.extend({
    partials: {
      listItem: gridItemTemplate,
      gridHeader: gridHeader
    },
    initialize: function ({
      executionStatus: executionStatus,
      bulkeditor: bulkeditor,
      strategyErrors: strategyErrors
    }) {
      this.executionStatus = executionStatus;
      this.bulkeditor = bulkeditor;
      this.strategyErrors = strategyErrors;
      this.strategyCollection = new Strategies();
      this.errorList = [];
      this.fetchStrategies();
    },
    fetchStrategies: function () {
      var collection = this.strategyCollection;
      var strategyIds = [];
      $.each(this.strategyErrors, function (index, obj) {
        strategyIds.push(obj.id);
      });
      this.setSearchLoader();
      collection.fetchOptions = {
        full: "*"
      };
      collection.pageLimit = 100;
      collection.isPaginated = false;
      collection.search.set({
        idList: strategyIds,
        fetchRequired: true,
        success: () => {
          this.retrieveStrategyDetails();
        }
      });
    },
    retrieveStrategyDetails: function () {
      var self = this;
      $.each(this.strategyErrors, function (index, {
        id: id,
        error: error,
        type: type
      }) {
        const strategyModel = self.strategyCollection.get(id);
        if (strategyModel) {
          const errorObj = strategyModel.toJSON();
          errorObj.errorMessage = error;
          if (type === "target_values") {
            errorObj.errorType = "Location";
          } else if (type === "pixel_bundles") {
            errorObj.errorType = "Pixel";
          } else {
            errorObj.errorType = "Concepts";
          }
          self.errorList.push(errorObj);
        }
      });
      this.load();
    },
    load: function () {
      this.render();
    },
    serialize: function () {
      return {
        list: this.errorList
      };
    }
  });
  return T1GridView(BulkEditReviewStrategiesGrid);
});
