define('modules/campaign/chart/views/metricsMenu',["jQuery", "Underscore", "T1", "T1View", "text!../templates/metricsMenu.html"], function ($, _, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const ROW_HEIGHT = 25;
  const MAX_HEIGHT = 225;
  const DEBOUNCE_DELAY = 200;
  return T1View.extend({
    template: template,
    events: {
      "changed .metrics-ddl": "metricsChangeHandler",
      "changed .search": "searchHandler",
      "open strand-dropdown": "initDDL"
    },
    eventhubEvents: {
      "change:metrics": function (obj) {
        if (obj.id === this.id) {
          this.default = obj.default;
          this.metrics = obj.metrics;
          this.selected = obj.selected;
          this.render().then(() => {
            this.assignElements();
          });
        }
      }
    },
    initialize: function (configs = {}) {
      this.id = configs.id || 0;
      this.default = configs.default || "Select One";
      this.selected = configs.selected || "";
      this.metrics = (configs.metrics instanceof Function ? configs.metrics() : configs.metrics) || [];
      this.maxItems = configs.maxItems || "";
      this.overflow = configs.overflow || "hidden";
      this.overflowWidth = configs.overflowWidth || 0;
    },
    load: function () {
      this.render().then(() => {
        this.assignElements();
        T1Publish("complete:layoutLoad");
      });
    },
    unload: function () {
      this.$listItems = null;
      this.default = null;
      this.id = null;
      this.maxItems = null;
      this.metrics = null;
      this.overflow = null;
      this.overflowWidth = null;
      this.selected = null;
    },
    assignElements: function () {
      this.$listItems = this.$(".metrics-ddl strand-list-item").not(".no-result");
      this.$ddlPanel = this.$(".metrics-ddl div#list");
      this.$noResult = this.$(".no-result");
      this.searchInput = this.$(".search");
    },
    metricsChangeHandler: function (event) {
      if (event.currentTarget.state === "closed") {
        T1Publish("change:metric", {
          id: this.id,
          value: event.originalEvent.detail.value,
          label: event.currentTarget.$.label.innerText
        });
      }
    },
    initDDL: function () {
      this.searchInput.val("");
      this.searchHandler();
    },
    searchHandler: _.debounce(function (event) {
      let term = event ? event.originalEvent.detail.value : "";
      let searchCount = 0;
      const $noResult = this.$noResult;
      term = term ? term.toLowerCase() : "";
      this.$listItems.addClass("no-display");
      $noResult.addClass("no-display");
      this.$listItems.each(function (index, element) {
        const label = $(element).text().toLowerCase();
        if (label.includes(term)) {
          searchCount++;
          $(element).removeClass("no-display");
        }
      });
      if (searchCount === 0) {
        $noResult.removeClass("no-display");
        $noResult.text(`No results found for "${term}"`);
      }
      this.updateHeight(searchCount);
    }, DEBOUNCE_DELAY),
    updateHeight: function (itemCnt) {
      var itemsHeight = itemCnt * ROW_HEIGHT;
      var $ddlPanel = this.$ddlPanel;
      if (itemsHeight < MAX_HEIGHT) {
        if (itemCnt === 0) {
          $ddlPanel.height("auto");
        } else {
          $ddlPanel.height(itemsHeight);
        }
      } else {
        $ddlPanel.height(MAX_HEIGHT);
      }
    },
    serialize: function () {
      return {
        default: this.default,
        selected: this.selected,
        metrics: this.metrics,
        maxItems: this.maxItems,
        overflow: this.overflow,
        overflowWidth: this.overflowWidth
      };
    }
  });
});
