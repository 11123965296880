define('collections/appsAccessUsers',["jQuery", "Underscore", "T1", "T1Collection", "models/appsAccessUsers", "T1Sortable"], function ($, _, T1, T1Collection, Model, Sortable) {
  "use strict";

  T1Collection.extend({
    apiRoot: T1.API_APPS_ROOT,
    canCache: false,
    cacheDuration: 6e4,
    model: Model,
    urlString: ""
  });
  return T1.Models.AppsAccessUsers;
});
