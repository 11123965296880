define('models/appsAccessUsers',["jQuery", "T1", "T1Model", "T1Comm"], function ($, T1, T1Model, T1Comm) {
  "use strict";

  var API_ROOT = T1.API_APPS_ROOT + "v1.0/acl/access/";
  var DEFAULT_OFFSET = 30;
  T1.Models.AppsAccessUsers = T1Model.extend({
    dataType: "json",
    isPaginated: true,
    filterBy: function (field, term, appID, success) {
      var onSuccess = success || $.noop;
      var url = term ? appID + "?offset=0&q=type==users&name=" + term : this.attributes.app_id + "/user";
      var options = {
        success: onSuccess,
        url: API_ROOT + url
      };
      this.fetch(options);
    },
    fetch: function (options) {
      if (!options.url) {
        options.url = API_ROOT + options.appID + "?filter=users&offset=" + (options.offset || DEFAULT_OFFSET);
      }
      T1Comm.get({
        dataType: this.dataType,
        onError: this.handleError,
        onStatusInvalid: this.handleStatusInvalid,
        onSuccess: options.success,
        processAjaxResponse: this.processAjaxResponse,
        sourceURL: options.url
      });
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    handleStatusInvalid: function (evt) {
      T1.log(evt, "status");
    },
    handleError: function (evt) {
      T1.log(evt);
    }
  });
  return T1.Models.AppsAccessUsers;
});
