define('modules/pixel/views/search',["jQuery", "T1", "T1View", "text!modules/pixel/templates/search.html"], function ($, T1, T1View, template) {
  return T1View.extend({
    template: template,
    events: {
      "keyup input": "searchPixels"
    },
    searchPixels: function (event) {
      var self = this;
      var inputText;
      switch (event.keyCode) {
        case 13:
          inputText = $(event.currentTarget).val();
          event.preventDefault();
          T1.EventHub.publish("pixel:search", {
            term: inputText
          });
          break;
        case "default":
      }
    },
    serialize: function () {
      return {};
    }
  });
});
