define('utils/MathtagUtils',["jQuery", "models/sessionUser", "sha256"], function ($, User, sha256) {
  "use strict";

  var mathTagParams = {
    mt_id: "513136",
    mt_adid: "101341",
    mt_exem: sha256(User.getUser().get("email") || ""),
    v1: "",
    v2: "",
    v3: "",
    s1: "",
    s2: "",
    s3: ""
  };
  var MathtagUtils = function () {
    function generatePixel(data) {
      var px;
      $("#mm_sync_back_ground, #mm_script").remove();
      $.extend(mathTagParams, data);
      px = document.createElement("script");
      px.src = COMPASS_ENV.MATH_TAG_API + $.param(mathTagParams);
      px.id = "mm_script";
      px.async = true;
      document.getElementsByTagName("head")[0].appendChild(px);
    }
    return {
      generatePixel: generatePixel
    };
  };
  return MathtagUtils();
});
