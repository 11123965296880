define('modules/audiences/segments/create/views/agencies',["jQuery", "Backbone", "collections/organizations", "collections/agencies", "jQPlugins/chosen.jquery", "jQueryUI"], function ($, Backbone, Organizations, Agencies) {
  "use strict";

  return Backbone.View.extend({
    el: $(".agency-select"),
    initialize: function (options) {
      this.parent = options.parent;
    },
    render: function () {
      $("#agencies").chosen({
        disable_search: true
      });
      var self = this;
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.loadAgencies();
      }, this));
    },
    loadAgencies: function () {
      $(".agencies-wrapper").hide();
      $("#agencies-loader").show();
      var agencies_collection = new Agencies(null, {
        pageLimit: 50
      });
      agencies_collection.urlFilter.set({
        entity: "organization",
        id: this.orgId,
        fetchRequired: false,
        sort_by: ["name", "id"],
        order_by: "ascending",
        page_limit: 50
      });
      agencies_collection.fetch({
        success: function (collection, response) {
          var entities = response.entities;
          var options = "<option></option>";
          var one_entity;
          if (entities.count >= 1) {
            if (entities.count === 1) {
              one_entity = entities.entity;
              entities.entity = [];
              entities.entity[0] = one_entity;
            }
            $.each(entities.entity, function (index, entity) {
              options = options + '<option value="' + entity.id + '">' + entity.name + "</option>";
            });
          }
          $(".agencies-wrapper").show();
          $("#agencies").empty().append(options);
          $("#agencies-loader").hide();
          $("#agencies").trigger("liszt:updated");
        }
      });
    }
  });
});
