define('modules/shared/views/searchBox',["jQuery", "T1", "T1View", "text!modules/shared/templates/searchBox.html", "T1Layout"], function ($, T1, T1View, template, T1Layout) {
  var SearchBox;
  var documentEvents = "mousedown.searchBox";
  var $document = $(document);
  SearchBox = T1View.extend({
    eventsMap: {
      search: "searchBox:search",
      loadAll: "searchBox:loadAll",
      clearAll: "searchBox:clearAll"
    },
    template: template,
    initialize: function (args) {
      this.model = args.model;
      this.searchResultHandler = args.searchOptions.searchResultHandler || $.noop;
      this.customPlaceholderText = args.searchOptions.customPlaceholderText || "";
      this.initializeLayout(args);
    },
    initializeLayout: function (args) {
      var self = this;
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: args.selectorPath,
        template: "<div class='search-box-holder'></div><div class='search-result-holder'></div>",
        layout: {
          ".search-box-holder": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: function (term) {
                $(self).trigger({
                  type: self.eventsMap.search,
                  term: term
                });
              },
              clearFilterCallback: function () {
                if (self.searchResultVisible) {
                  $(self).trigger(self.eventsMap.loadAll);
                } else {
                  $(self).trigger(self.eventsMap.clearAll);
                }
              },
              customPlaceholderText: self.customPlaceholderText
            }
          }],
          ".search-result-holder": [{
            module: "shared",
            viewType: "searchBoxGrid",
            options: {
              model: self.model,
              eventDispatcher: self,
              extendedOptions: args.searchOptions,
              urlFilterValue: args.urlFilterValue,
              searchCollection: args.searchCollection
            }
          }]
        }
      });
      $(self).on("searchItem:click", function (e) {
        self.hideMe(e);
        self.searchResultHandler(e);
      });
    },
    events: {
      "click .search-box-holder": "loadAll",
      "mousedown .w-InfiniteScrollSearchBox": "stopBubbling"
    },
    stopBubbling: function (e) {
      e.stopPropagation();
      T1.EventHub.publish("strategy-badges-close");
    },
    hideMe: function () {
      $document.off(documentEvents);
      if (!this.clearingSearchTerm) {
        this.showHideSearchResult();
        $(this.el).trigger("searchbox:hide");
      }
      this.clearingSearchTerm = false;
      this.open = false;
    },
    loadAll: function () {
      if (!this.open) {
        var self = this;
        var $searchInput = $(this.el).find("input");
        var value = $searchInput.val();
        $document.mousedown();
        $document.one(documentEvents, function (event) {
          self.hideMe(event);
        });
        if (!this.searchResultVisible) {
          this.showHideSearchResult(true);
          if (!value || value.length <= 0) {
            $(this).trigger(this.eventsMap.loadAll);
          }
        }
        this.open = true;
      }
    },
    showHideSearchResult: function (toShow) {
      var self = this;
      var $el = $(self.el);
      var $searchResult = $el.find(".search-result-holder");
      if (toShow) {
        $searchResult.show();
        self.searchResultVisible = true;
      } else {
        $searchResult.hide();
        self.searchResultVisible = false;
      }
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.layout.load();
      });
    },
    unload: function () {
      $document.off(documentEvents);
    }
  });
  return SearchBox;
});
