define('collections/strategyTargets',["jQuery", "T1", "T1Collection", "models/strategyTargetValue"], function ($, T1, T1Collection, StrategyTargetValue) {
  var collection = T1Collection.extend({
    model: StrategyTargetValue,
    urlString: "strategies",
    buildPostObject: function () {
      var result = {};
      return result;
    },
    save: function (dimensionId, successCallback, statusInvalid) {
      var self = this;
      var strategy = this.strategy;
      var postObject = {
        include_op: this.includedBinOperation || "OR",
        exclude_op: "OR",
        include: [],
        exclude: [],
        id: dimensionId,
        active: 1
      };
      var onSuccess = successCallback || $.noop;
      this.each(function (target_value) {
        var action = target_value.get("action");
        postObject[action].push(target_value.id);
      });
      strategy.save(postObject, {
        action: "target_dimensions/" + dimensionId,
        success: function () {
          strategy.fetch();
          onSuccess.apply(self, arguments);
        },
        conflict: statusInvalid
      });
    }
  });
  T1.Models.StrategyTargets = collection;
  return T1.Models.StrategyTargets;
});
