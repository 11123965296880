define('modules/targeting/audience/views/browse',["Underscore", "jQuery", "T1", "T1View", "T1Layout", "text!../templates/grid_item.html", "text!../templates/grid_footer.html", "T1GridView", "T1Spinner", "collections/audienceProviders", "modules/targeting/shared/itemSelectionBehavior", "utils/currencyConversionHelper"], function (_, $, T1, T1View, T1Layout, listItem, gridFooter, createGrid, Spinner, AudienceProviders, ItemSelectionBehavior, CurrencyUtils) {
  "use strict";

  var formatNumber = T1.Formats.rolledUpNumbersShort();
  var AudienceBrowseView = T1View.extend({
    preventDoubleBind: true,
    partials: {
      listItem: listItem,
      gridFooter: gridFooter
    },
    headers: [{
      name: "segment",
      label: "segment"
    }, {
      name: "size",
      label: "size"
    }, {
      name: "cpm",
      label: "cpm"
    }],
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    events: {
      "click .item": "toggle",
      "click .item .icon.include": "includeTarget",
      "click .item .icon.exclude": "excludeTarget"
    },
    eventhubEvents: {
      searchAudience: function ({
        term: term,
        provider_id: provider_id
      }) {
        const fnArgs = [term, provider_id, this.currencyCode];
        this.collection = this.audienceProviders;
        this.delegateDataEvents();
        this.searchTerm = term;
        this.viewType = "search";
        this.setSearchLoader();
        if (provider_id === "0") {
          this.collection.getAudience(...fnArgs);
        } else {
          this.collection.getAudienceFromSupernodes(...fnArgs);
        }
      },
      browseAudience: function () {
        this.viewType = "browse";
        this.collection = this.supernodes;
        this.delegateDataEvents();
        this.setSearchLoader();
        this.collection.fetch();
      },
      "bins:droppedAudience": function ({
        selectedList: selectedList
      }) {
        const {
          objectsRendered: objectsRendered,
          sharedCollection: sharedCollection
        } = this;
        const dropped = true;
        selectedList.forEach(({
          id: id,
          action: action
        }) => {
          this.addTarget(objectsRendered[id] || sharedCollection.get(id), action, dropped);
        });
      }
    },
    dataEvents: {
      collection: {
        reset: function () {
          var coll = this.collection;
          const modelsToBeRemoved = coll.reduce(function (arr, model) {
            if (model.get("audience_vendor_id") === COMPASS_ENV.IAS_AUDIENCE_VENDOR_ID || model.get("id") === COMPASS_ENV.IAS_ID) {
              arr.push(model);
            }
            return arr;
          }, []);
          if (modelsToBeRemoved.length) {
            coll.remove(modelsToBeRemoved, {
              silent: true
            });
          }
          this.doFetch = false;
          this.load();
          T1.EventHub.publish("audienceCollectionIsLoaded", {});
        }
      },
      sharedCollection: {
        add: function (model) {
          this.sharedCollectionAddHandler(model);
        },
        remove: function (model) {
          this.sharedCollectionRemoveHandler(model);
        }
      }
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".segment"
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      partialCollection: partialCollection,
      strategy: strategy,
      orgId: orgId,
      advertiserId: advertiserId,
      supernodes: supernodes,
      audienceProviders: audienceProviders,
      collection: collection
    }) {
      this.viewType = "browse";
      this.searchTerm = "";
      this.sharedCollection = sharedCollection;
      this.currencyCode = sharedCollection.currencyCode;
      this.partialCollection = partialCollection;
      this.strategy = strategy;
      this.objectsRendered = {};
      this.doFetch = true;
      this.orgId = orgId;
      this.advertiserId = advertiserId;
      this.supernodes = supernodes;
      this.audienceProviders = audienceProviders;
      ItemSelectionBehavior.attachBehavior(this);
      this.bindViewEvents();
      this.collection = collection;
      this.audienceProviders.fetchOptions.advertiser_id = this.advertiserId;
      this.collectionList = {};
    },
    load: function () {
      if (this.doFetch) {
        const hasBatchSupplyType = this.strategy.get("supply_type").toLowerCase() === "batch";
        if (hasBatchSupplyType) {
          this.collection.fetchOptions.include_batch_segments = true;
        }
        this.collection.fetch({
          success: () => {
            T1.EventHub.publish("strategyTargetingLoader:stop");
            this.render();
          }
        });
      } else {
        this.render();
        this.doFetch = true;
      }
    },
    unload: function () {
      this.viewType = "browse";
      if (this.paginationLayout && this.paginationLayout.unload) {
        this.paginationLayout.unload();
      }
      T1.EventHub.publish("browseAudience");
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 300,
      editData: function (data) {
        var vendor = "audience_vendor_id";
        var coll = this.collection;
        var firstNewVendor = coll.at(this.initCount).get(vendor);
        var lastOldVendor = coll.at(this.initCount - 1).get(vendor);
        if (firstNewVendor === lastOldVendor) {
          delete data.list[0].group;
        }
        return data;
      }
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_audience_segment_id",
      selectionAllowableKey: "buyable",
      selectionAllowableValue: "1",
      collectionKey: "objectsRendered"
    },
    includeTarget: function (evt) {
      var id = $(evt.currentTarget).closest(".item").attr("data-id");
      var item = this.objectsRendered[id];
      this.preventDefault(evt);
      T1.EventHub.publish("bins:resetDraggedDropTarget");
      this.removeDragClasses();
      this.renderPartial(".w-foot", this.getPath(item.get("full_path")));
      this.addTarget(item, "include");
    },
    excludeTarget: function (evt) {
      var id = $(evt.currentTarget).closest(".item").attr("data-id");
      var item = this.objectsRendered[id];
      this.preventDefault(evt);
      this.removeDragClasses();
      this.renderPartial(".w-foot", this.getPath(item.get("full_path")));
      T1.EventHub.publish("bins:resetDraggedDropTarget");
      this.addTarget(item, "exclude");
    },
    addTarget: function (item, action, dropped) {
      let lastAction;
      const itemInSharedCollection = this.sharedCollection.get(item.id);
      let itemRemoved = false;
      if (itemInSharedCollection) {
        lastAction = itemInSharedCollection.get("action");
        const userCPMInSharedCollection = itemInSharedCollection.get("user_cpm");
        if (userCPMInSharedCollection) {
          item.set({
            user_cpm: userCPMInSharedCollection
          });
        }
      }
      if (lastAction === action) {
        this.sharedCollection.remove(item);
        itemRemoved = true;
      } else {
        if (lastAction) {
          this.sharedCollection.remove(item);
          itemRemoved = true;
        }
        item.set({
          action: action
        });
        this.sharedCollection.add(item);
      }
      T1.EventHub.publish("bins:inclExclClicked", {
        item: item,
        itemRemoved: itemRemoved,
        lastAction: lastAction,
        dropped: dropped
      });
    },
    addChildren: function ({
      entityId = "",
      type: type
    }) {
      let serializedData;
      const item = this.$(`[data-id="${entityId}"]`);
      var arrow = item.find(".arrow");
      var text = item.find(".text");
      const spacing = 20;
      const margin = 4;
      const indent = parseInt(arrow.css("marginLeft").replace(/[^-\d.]/g, ""), 10);
      const perCent = 100;
      const textWidth = Math.round(perCent * parseFloat(text.css("width")) / parseFloat(text.parent().css("width")));
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var collection = this.collectionList[entityId];
      collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(collection, this.currencyCode);
      if (type === "supernode") {
        serializedData = this.serializeTaxonomies(collection);
      } else {
        serializedData = this.serialize(collection);
      }
      this.renderPartialTemplate({
        template: this.partials.gridSubItem,
        useTemplateHtml: false,
        action: "html",
        targetEl: childrenContainer,
        data: serializedData
      });
      const marginSetting = `${-(indent + spacing)}px 0 ${indent + spacing}px`;
      childrenContainer.find(".arrow").css("margin", `4px ${marginSetting}`);
      childrenContainer.find(".text").css("margin", `0 ${marginSetting}`).css("width", `${textWidth - margin}%`);
      this.applyDraggable();
      if (collection.count > collection.pageLimit) {
        const offset = 74;
        collection.entityType = "Segment";
        collection.parent = entityId;
        collection.fetchOptions.currency_code = this.currencyCode;
        this.paginationLayout = new T1Layout({
          el: () => this.el,
          template: "<div></div>",
          selectorPath: `.pagination-${entityId}`,
          layout: {}
        });
        this.paginationLayout.layout[`.pagination-${entityId}`] = [{
          module: "shared",
          viewType: "pagination",
          options: {
            parentEl: this.el,
            collection: collection,
            onSuccess: $.proxy(this.addChildren, this),
            indent: `margin: 5px -55px 0 ${indent + offset}px`
          }
        }];
        this.paginationLayout.load();
      }
    },
    toggle: function (evt) {
      let collection;
      var self = this;
      var item = $(evt.currentTarget);
      var arrow = item.find(".arrow");
      const itemId = String(item.data("id"));
      var type = item.data("code");
      var iconSpinner = arrow.find(".icon-spinner");
      var childrenContainer = item.closest(".item-wrapper").children(".children");
      var paginationContainer = childrenContainer.next();
      var collectionList = this.collectionList;
      if (!arrow.length || arrow.hasClass("hide")) {
        return;
      }
      this.preventDefault(evt);
      this.renderPartial(".w-foot", this.getPath(item));
      if (arrow.hasClass("expanded")) {
        iconSpinner.spin(false);
        arrow.removeClass("expanded loading");
        childrenContainer.hide();
        paginationContainer.hide();
        this.updateScrollBar();
      } else {
        switch (type) {
          case "supernode":
            toggleSupernode();
            break;
          default:
            toggleAudienceProviders();
            break;
        }
      }
      function toggleSupernode() {
        arrow.addClass("expanded");
        collection = collectionList[itemId];
        collectionList[itemId] = collection;
        if (collection && collection.length && childrenContainer.children().length) {
          childrenContainer.show();
        } else {
          collectionList[itemId] = getTaxonomies(itemId);
          self.addChildren({
            entityId: itemId,
            type: "supernode"
          });
        }
      }
      function toggleAudienceProviders() {
        arrow.addClass("loading expanded hide");
        collection = collectionList[itemId] || new AudienceProviders([]);
        collectionList[itemId] = collection;
        if (collection.length && childrenContainer.children().length) {
          arrow.removeClass("loading hide");
          childrenContainer.show();
          if (collection.count > collection.pageLimit) {
            paginationContainer.show();
          }
        } else {
          Spinner.apply(iconSpinner, self.spinnerOpts);
          collection.getAudienceSegments(collection.parent || itemId, self.currencyCode).done(function () {
            collection = CurrencyUtils.updateCpmTargetingValuesWithCurrency(collection, self.currencyCode);
            iconSpinner.spin(false);
            arrow.removeClass("loading hide");
            if (childrenContainer.is(":visible")) {
              self.addChildren({
                entityId: itemId
              });
            } else {
              childrenContainer.show();
              paginationContainer.show();
            }
            self.updateScrollBar();
          });
        }
      }
      function getTaxonomies(id) {
        const supernode = self.collection.models.find(node => node.get("vendor_id") === parseInt(id, 10));
        return supernode.get("taxonomies");
      }
    },
    getPath: function (fullPath) {
      let path;
      if (typeof fullPath === "string") {
        path = fullPath.replace(/ - /g, " | ");
      } else {
        fullPath = $(fullPath);
        path = [];
        while (fullPath.hasClass("ui-draggable")) {
          path.push(fullPath.find(".segment").text().trim());
          fullPath = fullPath.closest(".children").closest(".item-wrapper").children(".ui-draggable");
        }
        path = path.reverse().join(" | ");
      }
      const browsePathStrLen = 3;
      const lastSlashIndex = path.lastIndexOf(" | ");
      const noSlash = lastSlashIndex === -1;
      const browsePath = noSlash ? "" : path.substring(0, lastSlashIndex + browsePathStrLen);
      const browsePathEnd = noSlash ? path : path.substr(lastSlashIndex + browsePathStrLen);
      return {
        browse_path: browsePath.replace(/ \| /g, " &raquo "),
        browse_path_end: browsePathEnd.replace(/ \| /g, " &raquo ")
      };
    },
    serialize: function (collection = this.collection) {
      var self = this;
      var renderedCollection = this.objectsRendered;
      var getSerializedDataForBrowseMode = function (segmentsData) {
        var segments = segmentsData;
        var audienceSegments;
        if (!segments) {
          segments = _.filter(collection.toJSON(), model => model.parent_audience_segment_id === undefined);
        }
        $.each(segments.models, function (index, model) {
          renderedCollection[model.id] = model;
        });
        function sortBySegment(a, b) {
          if (a.preferred > 0 || b.preferred > 0) {
            return a.preferred < b.preferred ? 1 : -1;
          }
          return a.segment.toLowerCase() > b.segment.toLowerCase() ? 1 : -1;
        }
        audienceSegments = self.mapData(segments.toJSON()).sort(sortBySegment);
        return {
          list: audienceSegments,
          contentType: "browse-list"
        };
      };
      var getSerializedDataForSearchMode = function () {
        const result = {
          list: []
        };
        var utilsObj = T1.Utils;
        var boldLastPartOfFullPath = utilsObj.boldLastPartOfFullPath;
        var childCountGetter = function () {
          return false;
        };
        const searchTerm = self.searchTerm;
        CurrencyUtils.updateCpmTargetingValuesWithCurrency(collection, self.currencyCode);
        $.each(collection.models, function (index, model) {
          renderedCollection[model.id] = model;
        });
        const audienceInformation = _.groupBy(collection.toJSON(), model => model.full_path.split(" - ")[0]);
        $.each(audienceInformation, function (vendor, targets) {
          var displayNameGetter = function (model) {
            var text = model.full_path;
            text = text.replace(`${vendor} - `, "");
            text = text.replace(/ - /g, " &raquo ");
            const name = boldLastPartOfFullPath.call(utilsObj, {
              fullPath: text,
              separator: "&raquo",
              searchTermToBeHighlighted: searchTerm ? searchTerm : null
            });
            return name;
          };
          let groupResult = self.mapData(targets, displayNameGetter, childCountGetter);
          groupResult = _.filter(groupResult, model => model.buyable);
          if (groupResult.length) {
            groupResult[0].group = vendor;
            result.list = result.list.concat(groupResult);
          }
        });
        return result;
      };
      if (this.viewType === "search") {
        return getSerializedDataForSearchMode();
      }
      return getSerializedDataForBrowseMode(collection);
    },
    serializeTaxonomies: function (data) {
      return {
        list: this.mapData(data),
        contentType: "browse-list"
      };
    },
    mapData: function (segments, displayNameGetter, childCountGetter) {
      var self = this;
      var segmentsArray = segments;
      const currencyCode = this.currencyCode;
      if (!$.isArray(segmentsArray) && $.isPlainObject(segments)) {
        segmentsArray = [segments];
      }
      return $.map(segmentsArray, function (segment) {
        const tmpModelJSON = {
          id: segment.id || segment.audience_segment_id || segment.vendor_id || "",
          segment: displayNameGetter ? displayNameGetter(segment) : segment.name || "",
          size: segment.uniques ? formatNumber(parseInt(segment.uniques, 10)) : "--",
          cpm: segment.retail_cpm || "--",
          child_count: childCountGetter ? childCountGetter(segment) : parseInt(segment.child_count || "0", 10) > 0,
          buyable: (segment.buyable || "0") === "1",
          asCurrency: T1.Utils.getCurrencyTemplateFunc(currencyCode),
          preferred: segment.preferred || 0,
          isIncluded: false,
          isExcluded: false,
          type: segment.type || "audience_segment",
          vendor_id: segment.vendor_id,
          audience_segment_id: segment.audience_segment_id
        };
        self.applySelectionStates(tmpModelJSON);
        return tmpModelJSON;
      });
    }
  });
  return createGrid(AudienceBrowseView);
});
