define('modules/error/models/error',[],function () {
  "use strict";

  var instance;
  var descriptions = {
    400: "Bad Request.",
    401: "Unauthorized Access.",
    402: "Payment Required.",
    403: "Forbidden.",
    404: "Not Found.",
    405: "Method Not Allowed.",
    406: "Not Acceptable.",
    407: "Proxy Authentication Required.",
    408: "Request Timeout.",
    409: "Conflict.",
    410: "Gone.",
    411: "Length Required.",
    412: "Precondition Failed.",
    413: "Request Entity Too Large.",
    414: "Request-URI Too Long.",
    415: "Unsupported Media Type.",
    416: "Requested Range Not Satisfiable.",
    417: "Expectation Failed.",
    500: "Internal Server Error.",
    501: "Not Implemented.",
    502: "Bad Gateway.",
    503: "Service Unavailable.",
    504: "Gateway Timeout.",
    505: "HTTP Version Not Supported",
    Timeout: "We couldn’t complete your request. The network timed out.",
    "Server Error": "Could not connect to server.",
    "Parsing Error": "Parsing Error."
  };
  function T1Error() {
    var errorCollection = [];
    function add({
      code: code,
      detail: detail,
      url: url
    }) {
      const description = descriptions[String(code)] !== undefined ? descriptions[`${code}`] : "Unknown Error.";
      errorCollection.push({
        code: code,
        description: description,
        detail: detail,
        url: url
      });
    }
    function reset() {
      errorCollection = [];
    }
    function toJSON() {
      let browserInfo = "";
      const idx = 3;
      const info = {
        code: [],
        description: [],
        detail: []
      };
      const browserProperties = ["platform", "userAgent", "appCodeName", "appName", "appVersion", "language", "oscpu", "product", "cookieEnabled", "onLine", "buildID"];
      errorCollection.forEach(item => {
        if (!info.code.includes(item.code)) {
          info.code.push(item.code);
        }
        if (!info.description.includes(item.description)) {
          info.description.push(item.description);
        }
        if (!info.detail.includes(item.detail)) {
          info.detail.push(item.detail);
        }
      });
      for (let i = 0; i < info.detail.length; i++) {
        info.detail[i] = `Error Code: ${errorCollection[i].code}\n${errorCollection[i].detail}\n`;
      }
      info.detail.push(`Error Time: ${new Date().toString()}`);
      browserProperties.forEach(prop => {
        if (navigator[prop]) {
          browserInfo += `${prop}: ${navigator[prop]} / `;
        }
      });
      info.detail.push(`Browser Info: ${browserInfo.substr(0, browserInfo.length - idx)}\n`);
      return info;
    }
    function getErrorCollection() {
      return errorCollection;
    }
    return {
      add: add,
      reset: reset,
      toJSON: toJSON,
      getErrorCollection: getErrorCollection
    };
  }
  if (!instance) {
    instance = new T1Error();
  }
  return instance;
});
