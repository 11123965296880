define('modules/shared/configs/charts/lineBarChartTwoAxesConfig',[], function () {
  "use strict";

  const colorXaxis = "#999999";
  const colorYaxis = "#CCCCCC";
  const colorWhite = "#FFFFFF";
  const colorBlack = "#000000";
  const config = {
    hideCredits: true,
    type: "serial",
    theme: "light",
    dataProvider: [],
    fontFamily: "AR",
    synchronizeGrid: true,
    valueAxes: [{
      id: "barAxis",
      autoGridCount: false,
      gridAlpha: .1,
      gridCount: 5,
      axisColor: colorYaxis,
      position: "left",
      title: "",
      titleBold: true,
      titleFontSize: 13,
      labelFunction: function () {}
    }, {
      id: "lineAxis",
      autoGridCount: false,
      gridAlpha: .1,
      gridCount: 5,
      axisColor: colorYaxis,
      position: "right",
      title: "",
      titleBold: true,
      titleFontSize: 13,
      labelFunction: function () {}
    }],
    categoryAxis: {
      gridPosition: "start",
      gridAlpha: 0,
      axisColor: colorXaxis
    },
    categoryField: "frequency_bin",
    startDuration: .3,
    startEffect: "easeOutSine",
    sequencedAnimation: true,
    bezierX: 20,
    bezierY: 20,
    balloon: {
      color: colorWhite,
      fillColor: colorBlack,
      borderAlpha: 0,
      maxWidth: 500,
      textAlign: "left",
      verticalPadding: 8
    },
    legend: {
      enabled: false
    },
    graphs: []
  };
  return {
    copy: () => JSON.parse(JSON.stringify(config))
  };
});
