define('models/performanceDataForModels',["Underscore", "jQuery", "T1", "T1Model", "T1PerfDataFulfiller"], function (_, $, T1, T1Model, T1PerfDataFulfiller) {
  var PerfDataForModel = function PerformanceDataForModel(options) {
    var hostModel, intervalCodeMap, perfDataFulfiller;
    initialize(options);
    function initialize(options) {
      var argDataOptions;
      options = options || {};
      hostModel = options.hostModel;
      if (!hostModel) {
        throw new Error("The hostModel must be passed in via constructor via option parameter.");
      }
      if (undefined !== hostModel.get("strategies")) {
        argDataOptions = T1PerfDataFulfiller.getConfigForCampaign();
      } else {
        argDataOptions = T1PerfDataFulfiller.getConfigForStrategy();
      }
      perfDataFulfiller = new T1PerfDataFulfiller({
        contextEntity: hostModel,
        dataOptions: argDataOptions
      });
      intervalCodeMap = perfDataFulfiller.getIntervalCodeMap();
    }
    function hostModelFetchHandler(opts) {
      var onSuccess, options, withArr;
      var isPerformanceDataNeeded = false;
      var isSilent = false;
      options = $.extend(true, {}, opts || {});
      isSilent = options && options.silent === true;
      withArr = options.params && options.params.with ? options.params.with : [];
      isPerformanceDataNeeded = T1PerfDataFulfiller.removeRestoreGoalMonitoringClause(withArr, true);
      onSuccess = function (data) {
        var startIndex;
        function callBackAndTriggerEvents() {
          if (isSilent !== true) {
            hostModel.trigger("reset");
          }
          if (opts && opts.success) {
            opts.success(data);
          }
        }
        if (data && data.get("created_on_actual") && isPerformanceDataNeeded === true) {
          perfDataFulfiller.fulfillDataForModel().then(function () {
            callBackAndTriggerEvents();
          });
          T1PerfDataFulfiller.removeRestoreGoalMonitoringClause(withArr, false);
        } else {
          callBackAndTriggerEvents();
        }
      };
      options.success = onSuccess;
      options.silent = true;
      T1Model.prototype.fetch.call(hostModel, options);
    }
    return {
      hostModelFetchHandler: hostModelFetchHandler
    };
  };
  return PerfDataForModel;
});
