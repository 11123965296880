define('modules/admin/myOrgs/views/myOrgs',['require','Underscore','jQuery','collections/advertisers','collections/agencies','collections/organizations','T1Permissions','T1','T1Layout','T1View','text!../templates/terminatedOrgModal.html','text!../templates/myOrgs.html','models/sessionUser','models/userPreferences','utils/AdminUtils','T1Signature'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Advertisers = require("collections/advertisers");
  const Agencies = require("collections/agencies");
  const Organizations = require("collections/organizations");
  const Permissions = require("T1Permissions");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const terminatedOrgModal = require("text!../templates/terminatedOrgModal.html");
  const tmpl = require("text!../templates/myOrgs.html");
  const User = require("models/sessionUser");
  const UserPreferences = require("models/userPreferences");
  const Utils = require("utils/AdminUtils");
  const T1Signature = require("T1Signature");
  var user = User.getUser().toJSON();
  var OrgsView = T1View.extend({
    template: tmpl,
    partials: {
      terminatedOrgModal: terminatedOrgModal
    },
    userPreferenceViewName: "myorg",
    isActive: "1",
    filterValue: "ORGANIZATION",
    defaultFilterValue: "ORGANIZATION",
    eventhubEvents: {
      "search:onSearchRequest": function (args) {
        this.collection.searchTerm = args.value.length > 0 ? args.value : undefined;
        this.searchField = args.field;
        if (this.searchTerm === this.collection.searchTerm) {
          return;
        }
        this.searchTerm = this.collection.searchTerm;
        if (this.searchTerm && this.searchTerm !== "") {
          this.applyEntityCollectionFilter(this.filterValue);
        } else {
          this.applyEntityCollectionFilter(this.defaultFilterValue);
        }
        this.filterCollection(args.field, args.value);
      },
      "search:clearSearchClicked": function (args) {
        var coll = this.collection;
        coll.refreshOnNextFetch();
        coll.searchTerm = this.searchTerm = undefined;
        this.searchField = args.field;
        if (this.searchTerm && this.searchTerm !== "") {
          this.applyEntityCollectionFilter(this.filterValue);
        } else {
          this.applyEntityCollectionFilter(this.defaultFilterValue);
        }
        this.filterCollection(args.field, args.value);
      },
      "search:toggleActive": function (args) {
        this.isActive = args.value === "1" ? "true" : "false";
        this.filterCollection("status", args.value);
        this.preferences.save();
      },
      "search:filterChanged": function (args) {
        this.filterValue = args.value;
        this.applyEntityCollectionFilter(args.value);
        if (this.collection.searchTerm && this.collection.searchTerm !== "") {
          this.filterCollection();
        }
        this.preferences.save();
      },
      terminatedOrgModalShow: function () {
        this.renderPartialTemplate({
          template: terminatedOrgModal,
          context: this.el,
          targetEl: ".terminated-org-modal",
          useTemplateHtml: false,
          data: this.serialize()
        });
        setTimeout(() => {
          this.el.find(".terminated-modal")[0].show();
        }, 0);
      }
    },
    events: {
      "click .action": "addEntity",
      "click .close-modal": "closeTerminatedOrgModal",
      "selected .add-action": "addEntity"
    },
    initialize() {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "admin.search"], ["view", this.userPreferenceViewName]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["isActive", "filterValue"]);
      this.isActive = this.preferences.get("isActive");
      this.isActive = this.isActive !== undefined ? this.isActive : "true";
      this.filterValue = this.preferences.get("filterValue");
      this.filterValue = this.filterValue !== undefined ? this.filterValue : this.defaultFilterValue;
      this.orgCollection = Organizations.createOrganizations();
      this.agencyCollection = new Agencies();
      this.advertiserCollection = new Advertisers([], {
        filterConfiguration: {
          filters: [{
            entity: "agency",
            fetchOnChange: false
          }, {
            entity: "organization",
            fetchOnChange: false
          }],
          enableEvents: false
        }
      });
      this.applyEntityCollectionFilter(this.defaultFilterValue);
      this.collection.applySearchFilter("status", this.isActive === "true" ? "1" : "");
      this.initLayout();
    },
    closeTerminatedOrgModal(evt) {
      this.el.find(".terminated-modal")[0].hide();
      evt.preventDefault();
    },
    addEntity(evt) {
      var entity = evt.type === "selected" ? evt.originalEvent.detail.value : $(evt.currentTarget).attr("value");
      var loc = "admin/";
      if (entity) {
        loc += entity;
        if (entity === "organization") {
          loc += "/new";
        }
        T1.Location.set(loc, {});
      }
    },
    initLayout() {
      var orgLayout = {
        ".list-search": [{
          module: "admin/shared",
          viewType: "search",
          options: {
            searchInputPlaceHolder: "Type Name or ID Number",
            isActiveChecked: this.isActive === "true",
            filter: {
              defaultValue: this.filterValue,
              value: [{
                name: "Organization",
                value: "ORGANIZATION"
              }, {
                name: "Agency",
                value: "AGENCY"
              }, {
                name: "Advertiser",
                value: "ADVERTISER"
              }]
            }
          }
        }],
        ".w-grid": [{
          module: "admin/myOrgs",
          viewType: "grid",
          options: {
            collection: this.collection,
            myOrgs: this
          }
        }]
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".list-body",
        template: '<div class="w-grid"></div>',
        layout: orgLayout
      });
    },
    load() {
      this.render().then(() => {
        this.layout.load();
      });
    },
    unload() {
      var coll = this.collection;
      var searchFieldVal;
      this.viewChanged = true;
      if (coll) {
        searchFieldVal = coll.searchTerm;
        coll.searchTerm = this.searchTerm = null;
        this.applyEntityCollectionFilter(this.defaultFilterValue);
        if (searchFieldVal && searchFieldVal !== "") {
          coll.applySearchFilter(this.searchField || "multiField", "");
        }
        T1.EventHub.publish("organizationGrid:resetCollection", {
          collection: this.collection
        });
      }
    },
    filterCollection(field, term) {
      T1.EventHub.publish("organizationGrid:reload", {
        collection: this.collection
      });
      this.collection.filterBy(field, term);
    },
    applyEntityCollectionFilter(entity) {
      var coll, pageLimit;
      var withArr = [];
      const maxAgencies = 25;
      const maxAdvertisers = 17;
      const maxOrgs = 50;
      switch (entity) {
        case "AGENCY":
          coll = this.agencyCollection;
          pageLimit = maxAgencies;
          withArr = ["organization"];
          break;
        case "ADVERTISER":
          coll = this.advertiserCollection;
          pageLimit = maxAdvertisers;
          withArr = ["agency,organization"];
          break;
        default:
          coll = this.orgCollection;
          pageLimit = maxOrgs;
          withArr = ["contracts", "legacy_contracts"];
      }
      coll.entityType = entity;
      coll.canCache = false;
      coll.page = 0;
      coll.pageLimit = pageLimit;
      coll.isPaginated = true;
      coll.fetchOptions = {
        full: "*",
        sort_by: "-updated_on",
        with: withArr
      };
      coll.searchTerm = this.collection ? this.collection.searchTerm : coll.searchTerm;
      coll.currentSearchConfig = this.collection ? this.collection.currentSearchConfig : coll.currentSearchConfig;
      this.collection = coll;
    },
    canAddAgency() {
      var explicitlyEnabledAgencyFound = Utils.explicitlyEnabledAgencyFound;
      var permissions = user.permissions;
      var orgAccess = permissions.entities && permissions.entities.organization.access || [];
      return _.every(orgAccess, function (org) {
        return explicitlyEnabledAgencyFound(user, org.id);
      });
    },
    serialize() {
      var canAddAgencyAdvertiser = Permissions.check("agency_advertiser.add");
      var output = {
        allowAddOrg: Permissions.check("organization.create"),
        allowAddAgency: canAddAgencyAdvertiser && this.canAddAgency(),
        allowAddAdvertiser: canAddAgencyAdvertiser
      };
      var addableCount = _.countBy(output, function (allowAdd) {
        return allowAdd === true ? "yes" : "no";
      });
      output.showPullDown = addableCount.yes > 1;
      return output;
    }
  });
  return OrgsView;
});
