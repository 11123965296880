define('models/healthCheckFacebook',["T1", "T1Model"], function (T1, T1Model) {
  var HealthCheckFacebook = T1Model.extend({
    url: function () {
      var id = this.get("id");
      return id ? T1.API_ROOT + "strategies/" + id + "/facebook_validation_state" : "";
    },
    fetch: function (opts) {
      opts = opts || {};
      opts.url = this.url();
      return T1Model.prototype.fetch.call(this, opts);
    },
    initialize: function () {
      this.set({
        errors: []
      });
    }
  });
  HealthCheckFacebook.INVALID = "INVALID";
  HealthCheckFacebook.VALID = "VALID";
  HealthCheckFacebook.PENDING = "PENDING";
  return T1.Models.HealthCheckFacebook = HealthCheckFacebook;
});
