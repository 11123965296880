define('modules/T1App/edit/version/models/versionModel',["jQuery", "Underscore", "Backbone", "T1", "T1Model", "T1Collection"], function ($, _, Backbone, T1, T1Model, T1Collection) {
  "use strict";

  T1.Models.Version = T1Model.extend({
    setVersionData: function (rawData) {
      if (rawData.meta.status === "success") {
        var data = rawData.data;
        var newData = [];
        var release = [];
        var date;
        for (var i = 0, len = data.length; i < len; i++) {
          date = data[i].date.split("T");
          if (i === 0) {
            release.push({
              releaseTitle: data[i].title,
              releaseNote: data[i].description,
              versionID: data[i].version_id,
              appVersionID: data[i].id,
              appVersionVersion: data[i].version
            });
          }
          if (!data[i].description || data[i].description === "null") {
            data[i].description = false;
          }
          newData.push({
            appID: data[i].app_id,
            date: date[0],
            description: data[i].description,
            id: data[i].id,
            title: data[i].title,
            version: data[i].version,
            versionID: data[i].version_id,
            historyID: i
          });
        }
        this.set({
          versionData: newData
        }, {
          silent: true
        });
        this.set({
          releaseData: release
        }, {
          silent: true
        });
      }
    }
  });
  return T1.Models.Version;
});
