define('modules/campaign/chart/winLoss/models/mCampaignSummaryChart',["Underscore", "Backbone", "T1"], function (_, Backbone, T1) {
  "use strict";

  const COLOR_WHITE = "#fff";
  const MAX_LENGTH = 60;
  const DECIMALS = 2;
  const PERCENTAGE = 100;
  return Backbone.Model.extend({
    initialize: function (attr, opts) {
      this.ReportModel = opts;
    },
    setClicked: function (id) {
      this.set({
        clickedID: id
      });
    },
    getChartColors: function () {
      return this.get("dotColors") || [];
    },
    getChartData: function () {
      let dataProvider, graphs;
      const ReportModel = this.ReportModel;
      if (ReportModel.chartData.length) {
        this._parseData(ReportModel);
        this._createDataProvider(ReportModel);
        this._createGraphs(ReportModel);
        dataProvider = this.get("dataProvider");
        graphs = this.get("graphs");
      }
      return {
        dataProvider: dataProvider || [],
        graphs: graphs || []
      };
    },
    _parseData: function (ReportModel) {
      let parsedData = [];
      const highestLowest = ReportModel.getHighestLowest();
      const {
        order: order,
        limit: limit
      } = highestLowest;
      const dataJSON = ReportModel.chartData.toJSON();
      const dataSortedByIncludeOnly = this._sortByIncludeOnly(dataJSON, highestLowest);
      const dataFilteredByZeros = T1.Utils.valueToBool(highestLowest.excludeZeros) ? this._filterOutZeros(dataSortedByIncludeOnly, highestLowest) : dataSortedByIncludeOnly;
      if (dataFilteredByZeros.length) {
        this._limitRange(dataFilteredByZeros, order, limit);
        parsedData = dataFilteredByZeros;
      }
      this.set({
        parsedData: parsedData
      });
    },
    _sortByIncludeOnly: function (dataJSON, highestLowest) {
      return _.sortBy(dataJSON, item => Number(item[highestLowest.metric]));
    },
    _filterOutZeros: function (dataSortedByIncludeOnly, highestLowest) {
      return _.filter(dataSortedByIncludeOnly, item => Number(item[highestLowest.metric]) > 0);
    },
    _limitRange: function (dataFilteredByZeros, order, limit) {
      switch (order) {
        case "hi":
          dataFilteredByZeros.reverse();
        case "lo":
          if (dataFilteredByZeros.length > limit) {
            dataFilteredByZeros.length = limit;
          }
          break;
      }
    },
    _createDataProvider: function (ReportModel) {
      const parsedData = this.get("parsedData");
      const dataProvider = [];
      if (parsedData.length) {
        const viewBy = ReportModel.getViewByDimension();
        const viewById = `${viewBy}_id`;
        const metrics = this._getMetrics(ReportModel);
        const dataset = {};
        parsedData.forEach(function (item) {
          const value = item[viewById];
          dataset[`${value}_Y`] = item[metrics.metric0];
          dataset[`${value}_X`] = item[metrics.metric1];
        });
        dataProvider.push(dataset);
      }
      this.set({
        dataProvider: dataProvider
      });
    },
    _getMetrics: function (ReportModel) {
      const selectedMetrics = ReportModel.getSelectedMetrics();
      return {
        metric0: selectedMetrics.campaign.summary[0].value,
        metric1: selectedMetrics.campaign.summary[1].value
      };
    },
    _createGraphs: function (ReportModel) {
      let i = 0;
      const formatValue = ReportModel.formatValue;
      const parsedData = this.get("parsedData");
      const viewBy = ReportModel.getViewByDimension();
      const viewById = `${viewBy}_id`;
      const viewByName = `${viewBy}_name`;
      const metrics = this._getMetrics(ReportModel);
      const {
        metric0: metric0,
        metric1: metric1
      } = metrics;
      const label0 = ReportModel.getSelectedMetricLabel(metric0);
      const label1 = ReportModel.getSelectedMetricLabel(metric1);
      const metricType0 = ReportModel.getSelectedMetricType(metric0);
      const metricType1 = ReportModel.getSelectedMetricType(metric1);
      const chartColors = ReportModel.getColorList(parsedData);
      const dotColors = [];
      function formatBalloon(item) {
        let label = item.graph.title || "";
        if (label.length > MAX_LENGTH) {
          label = label.match(/([\S\s]{1,60})/g);
        }
        return `<p style="font-family:AB;">${label.toString().replace(/,/g, "<br>")}</p><br>${label0}: ${formatValue.call(ReportModel, item.values.y, metricType0)}<br>${label1}: ${formatValue.call(ReportModel, item.values.x, metricType1)}<br>&nbsp;`;
      }
      const graphs = _.map(parsedData, dataItem => {
        const color = chartColors[i++];
        const id = dataItem[viewById];
        dotColors.push(color);
        return {
          balloonFunction: formatBalloon,
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 10,
          id: id,
          lineColor: color,
          title: dataItem[viewByName],
          xField: `${id}_X`,
          yField: `${id}_Y`
        };
      });
      this.set({
        dotColors: dotColors,
        graphs: graphs
      });
    },
    getTableData: function () {
      const datasets = this.get("datasets");
      const clickedID = this.get("clickedID");
      const gridData = [];
      _.each(datasets, function (dataset) {
        gridData.push({
          color: dataset.pointBackgroundColor,
          entity: dataset.label,
          highlight: clickedID === dataset.id ? "#bfe5f9" : "#fff",
          id: dataset.id,
          metric0: dataset.data[0].x,
          metric1: dataset.data[0].y
        });
      });
      return gridData;
    },
    getFunnelHeader: function () {
      const ReportModel = this.ReportModel;
      const filteredData = this.get("parsedData");
      const selectedID = this.get("clickedID");
      let label = "";
      if (filteredData && filteredData.length) {
        if (selectedID) {
          const viewBy = ReportModel.getViewByDimension();
          const viewById = `${viewBy}_id`;
          const viewByName = `${viewBy}_name`;
          _.each(filteredData, function (item) {
            if (selectedID === item[viewById]) {
              label = `${item[viewByName]} (${item[viewById]})`;
            }
          });
        } else {
          const entity = ReportModel.get("entity");
          label = `${entity.get("name")} (${entity.get("id")})`;
        }
      }
      return label;
    },
    getFunnelData: function () {
      let bidOppts, bidRate, bids, winRate, wins;
      const filteredData = this.get("parsedData");
      const viewBy = this.ReportModel.getViewByDimension();
      const viewById = `${viewBy}_id`;
      const isCreative = viewBy === "creative";
      const selectedID = this.get("clickedID");
      if (selectedID) {
        const dataset = _.find(filteredData, item => selectedID === item[viewById]);
        bids = dataset.bids;
        wins = dataset.wins;
        winRate = dataset.win_rate;
        if (!isCreative) {
          bidOppts = dataset.matched_bid_opportunities;
          bidRate = dataset.bid_rate;
        }
      } else {
        bids = this._pluckReduce("bids", filteredData);
        wins = this._pluckReduce("wins", filteredData);
        winRate = (PERCENTAGE * wins / bids).toFixed(DECIMALS);
        if (!isCreative) {
          bidOppts = this._pluckReduce("matched_bid_opportunities", filteredData);
          bidRate = (PERCENTAGE * bids / bidOppts).toFixed(DECIMALS);
        }
      }
      return {
        bidOppts: bidOppts,
        bids: bids,
        wins: wins,
        bidRate: bidRate,
        winRate: winRate
      };
    },
    _pluckReduce: function (metric, filteredData) {
      return _.chain(filteredData).pluck(metric).reduce((memo, num) => Number(memo) + Number(num)).value();
    }
  });
});
