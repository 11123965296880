define('T1Chart',['require','jQuery','Mustache','T1','T1View','jQueryUI','T1Tooltip'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Mustache = require("Mustache");
  const T1 = require("T1");
  const T1View = require("T1View");
  const jQueryUI = require("jQueryUI");
  const T1Tooltip = require("T1Tooltip");
  var T1Chart, T1ChartExtend, currencyCode, currencyCode2;
  var Date = T1.Date;
  T1Chart = T1View.extend({
    title: "",
    chartData: [],
    origChartData: [],
    toolTipData: {},
    chartElement: null,
    chartSelector: ".w-chart-box",
    chartPanelSelector: ".w-chart-body, .w-chart-placeholder",
    toggleClass: "chart-open",
    openFlag: "true",
    labels: [],
    xKey: "XKey",
    yKey: "YKey",
    yKey2: "YKey2",
    aggregateBy: null,
    selectedIndex: null,
    selectKey: null,
    defaultYValue: 0,
    maxW: 120,
    tickSize: 5,
    tickPadding: 10,
    padMatrix: {
      top: 25,
      right: 50,
      bottom: 25,
      left: 50
    },
    xAxisOrientation: "bottom",
    yAxisOrientation: "left",
    xAxisLabelRotation: 0,
    xAxisLabelTextAnchor: "start",
    xAxisLabelOffsetY: 0,
    xAxisLabelAdjustment() {
      var adjustment = this.padMatrix && this.padMatrix.bottom;
      adjustment = adjustment || 0;
      return adjustment;
    },
    chartColors: d3.scale.category10(),
    interval: 5,
    gridW: 0,
    targetW: 0,
    xAxisLabel: "",
    yAxisLabel: "",
    xAxisSubLabel: "",
    chartPanels: null,
    chartCanvas: null,
    colorIndex: 0,
    dataStack: null,
    width: null,
    height: null,
    dataCount: 0,
    units: 0,
    maxX: 0,
    maxY: 0,
    maxY2: 0,
    effectiveInterval: 0,
    xInterval: 0,
    yInterval: 0,
    yInterval2: 0,
    xDomain: [0],
    yDomain: [0],
    yDomain2: [0],
    maxXLabelLength: 20,
    x: null,
    y: null,
    y_2: null,
    xOrdinal: null,
    yOrdinal: null,
    yOrdinal2: null,
    xAxis: null,
    yAxis: null,
    yAxis2: null,
    xWidth: null,
    xRadius: null,
    yRadius: null,
    yGridLines: null,
    yAxisLabelEl: null,
    pnl: null,
    vis: null,
    layers: null,
    line: null,
    line2: null,
    bars: null,
    bar: null,
    circleRadius: 2,
    dateformat: "yyyy-MM-dd",
    prepareRenderData: $.noop,
    xItemsCount: 0,
    crowdLevel: 0,
    crowdLevel0Limit: 30,
    crowdLevel1Limit: 90,
    chartView: null,
    currencyFormatter(value) {
      return T1.Utils.formatCurrency(value, null, currencyCode);
    },
    currencyFormatter2(value) {
      return T1.Utils.formatCurrency(value, null, currencyCode2);
    },
    currencyFormatter_chartAxis(value) {
      return T1.Utils.formatCurrency(value, null, currencyCode, "svgText");
    },
    currencyFormatter_chartAxis2(value) {
      return T1.Utils.formatCurrency(value, null, currencyCode2, "svgText");
    },
    dataFormatters(format) {
      return T1.Formats.as.call(this, format);
    },
    dataFilter(dataArry) {
      if (!dataArry) {
        return undefined;
      }
      if (!dataArry.filter) {
        return dataArry;
      }
      if (this.selectedIndex !== null) {
        return dataArry.filter((node, index) => index === this.selectedIndex);
      }
      if (this.selectKey) {
        return dataArry.filter(node => node.key && node.key === this.selectKey);
      }
      return dataArry;
    },
    dataMapper(data, keys) {
      if (!data || !keys) {
        throw Error('chart dataMapper method requires both "data" and "keys" arguments');
      }
      const isText = this.reportMetaObj && this.reportMetaObj.fields[this.yKey].type === "string";
      if (this.mmChart) {
        const isText2 = this.reportMetaObj && this.reportMetaObj.fields[this.yKey2].type === "string";
        return data.map(function (a) {
          return a.values.map(function (d, i) {
            var yValue = d[keys.yKey] || keys.defaultYValue;
            var yValue2 = d[keys.yKey2] || keys.defaultYValue;
            return $.extend({}, d, {
              x: i,
              y: isText ? yValue : parseFloat(yValue),
              y_2: isText2 ? yValue2 : parseFloat(yValue2),
              xLabel: d[keys.xKey],
              yLabel: d[keys.yKey],
              yLabel2: d[keys.yKey2]
            });
          });
        });
      } else if (this.siteChart && this.chartView !== "series") {
        return data.map(function (a) {
          return a.values.map(function (d) {
            var xValue = d[keys.xKey] || keys.defaultXValue;
            var yValue = d[keys.yKey] || keys.defaultYValue;
            return $.extend({}, d, {
              x: parseFloat(xValue),
              y: isText ? yValue : parseFloat(yValue),
              xLabel: d[keys.xKey],
              yLabel: d[keys.yKey]
            });
          });
        });
      }
      return data.map(function (a) {
        return a.values.map(function (d, i) {
          var yValue = d[keys.yKey] || keys.defaultYValue;
          return $.extend({}, d, {
            x: i,
            y: isText ? yValue : parseFloat(yValue),
            xLabel: d[keys.xKey] === "" ? "Unassigned" : d[keys.xKey],
            yLabel: d[keys.yKey]
          });
        });
      });
    },
    dataAligner(data) {
      var len = data.length;
      var reportModel = this.reportModel;
      var timeField = "start_date";
      var timeSeriesSlots = reportModel.get("timeSeriesSlots") || {
        slots: []
      };
      var tsSlots = timeSeriesSlots.slots;
      var tsLen = tsSlots.length;
      var dimKey = this.dimensionKey;
      var dimName = this.dimensionName;
      if (tsSlots.length > 0) {
        for (let i = 0; i < len; i++) {
          const datum = data[i];
          let datumValues = datum.values;
          if (datumValues.length < tsLen) {
            const datumKey = datum.key;
            datumValues = datumValues.concat();
            const datumName = datumValues[0][dimName];
            let ptr = 0;
            for (let j = 0; j < tsLen; j++) {
              const datumValue = datumValues[ptr];
              const tsSlotTimeField = tsSlots[j][timeField];
              if (datumValue && tsSlotTimeField === datumValue[timeField]) {
                datum.values[j] = datumValue;
                ptr++;
              } else {
                const obj = {};
                obj[timeField] = tsSlotTimeField;
                obj[dimKey] = datumKey;
                obj[dimName] = datumName;
                obj.campaign_timezone_code = datumValue && datumValue.campaign_timezone_code;
                obj.campaign_timezone = datumValue && datumValue.campaign_timezone;
                datum.values[j] = obj;
              }
            }
          }
        }
      }
      return data;
    },
    dataProcessor(data, context = this) {
      var filteredData;
      if (context.aggregateBy) {
        const nestedData = d3.nest().key(d => d[context.aggregateBy]).entries(data);
        filteredData = context.dataFilter ? context.dataFilter(nestedData) : nestedData;
        filteredData = this.dataAligner(filteredData);
      } else {
        filteredData = [{
          values: data
        }];
        if (this.mmChart) {
          filteredData = this.dataAligner(filteredData);
        }
      }
      return d3.values(this.dataMapper(filteredData, {
        xKey: this.xKey,
        yKey: this.yKey,
        yKey2: this.yKey2,
        defaultYValue: this.defaultYValue
      }));
    },
    processHiddenItems(chartData) {
      var currentDimension;
      var self = this;
      var hiddenItems = this.reportModel.get("hiddenItems") || {};
      var filteredOutItems = this.reportModel.get("filteredOutItems") || [];
      if (hiddenItems) {
        hiddenItems = $.extend({}, hiddenItems);
        $.each(hiddenItems, function (key) {
          for (let i = 0, len = chartData.length; i < len; i++) {
            currentDimension = chartData[i];
            if (currentDimension) {
              currentDimension = currentDimension[0] || currentDimension;
              const name = currentDimension[self.dimensionKey];
              if (name && key === name.replace(/[']/g, "")) {
                chartData.splice(i, 1);
                break;
              }
            }
          }
        });
      }
      if (filteredOutItems) {
        for (let itemsLength = filteredOutItems.length, j = 0; j !== itemsLength; j++) {
          const filteredOutItem = filteredOutItems[j];
          for (let dataLength = chartData.length, k = 0; k !== dataLength; k++) {
            currentDimension = chartData[k];
            if (currentDimension) {
              currentDimension = currentDimension[0] || currentDimension;
              if (filteredOutItem === currentDimension[this.dimensionKey]) {
                chartData.splice(k, 1);
                break;
              }
            }
          }
        }
      }
      return chartData;
    },
    processSearchData(chartData) {
      return chartData;
    },
    processData() {
      var chartData;
      var mmChart = this.mmChart;
      if (mmChart) {
        if (!this.canLoad) {
          return this;
        }
        chartData = this.seriesSummary.toJSON();
      } else {
        if (!this.reportModel.get("colorData")) {
          return this;
        }
        chartData = this.collection && this.collection.loaded && this.collection.toJSON() || [];
      }
      this.chartData.length = 0;
      if (!chartData || !chartData.length) {
        return this;
      }
      if (!mmChart && !this.aggregateBy) {
        chartData = this.processSearchData(chartData);
        chartData = this.processHiddenItems(chartData);
        this.xItemsCount = chartData.length;
      }
      this.chartData = this.dataProcessor(chartData);
      if (!mmChart && this.aggregateBy) {
        this.origChartData = this.chartData[0];
        this.chartData = this.processSearchData(this.chartData);
        this.chartData = this.processHiddenItems(this.chartData);
        if (this.chartData.length === 0) {
          this.chartData = [[]];
        }
        this.xItemsCount = this.chartData[0].length;
      }
      if (mmChart) {
        this.xItemsCount = this.chartData[0].length;
      }
      return this;
    },
    initialize() {
      this.mmChart = this.chartView === "campaign";
      this.siteChart = this.chartView === "site";
      this.chartLoader = this.loader();
    },
    load() {
      var self = this;
      var mainModel = this.mainModel;
      if (mainModel && mainModel.leftTheSection(this.section)) {
        return this;
      }
      $(window).off("resize.charting").on("resize.charting", function () {
        self.chart();
      });
      this.prepareRenderData();
      this.render().then(() => {
        this.loaded = true;
        this.setup().displayLoadingMessage().processData().chart();
      });
      this.message.hide(this);
      this.displayLoadingMessage(true);
      return this;
    },
    unload() {
      if (this.loaded) {
        this.loaded = false;
        this.message.hide(this);
        $(window).off("resize.charting");
        if (this.tooltips) {
          this.tooltips.unbind();
        }
        this.unbind();
        this.remove();
      }
    },
    setup() {
      return this;
    },
    displayLoadingMessage(isLoading) {
      var requesting = isLoading || this.collection && !this.collection.loaded;
      var message = requesting ? "requesting chart data ..." : "no chart data available";
      this.message.show(message, this);
      return this;
    },
    isOpen(open) {
      if (open !== undefined) {
        this.openFlag = open ? "true" : "false";
      }
      return this.openFlag === "true";
    },
    trunc(text, pre) {
      var maxLength = this.maxXLabelLength;
      var textArry = [];
      if (!text || text.length <= maxLength) {
        return text;
      }
      if (pre) {
        textArry.push("...");
        textArry.push(text.slice(text.length - maxLength).trim());
      } else {
        textArry.push(text.slice(0, maxLength).trim());
        textArry.push("...");
      }
      return textArry.join("");
    },
    updateCurrency() {
      var mainModel = this.mainModel;
      if (mainModel) {
        if (mainModel.get("section") === "performance") {
          currencyCode = mainModel.getCurrencyCode(this.campaign, this.reportModel.get("focus"));
        } else {
          currencyCode = "USD";
        }
      } else {
        if (this.reportType === "performance" || this.reportType === "by_hour") {
          if (this.campaign && this.campaign.get("currency_code")) {
            currencyCode = this.yKey.includes("usd") ? "USD" : this.campaign.get("currency_code");
            currencyCode2 = this.yKey2.includes("usd") ? "USD" : this.campaign.get("currency_code");
          } else {
            currencyCode = "USD";
            currencyCode2 = "USD";
          }
        } else {
          currencyCode = "USD";
          currencyCode2 = "USD";
        }
      }
    },
    attachTooltips(args = {}) {
      var self = this;
      var $ttParentEle = `#${$(this.el).attr("id")}`;
      var renderInfo, renderKey, renderValue, settings, yFormatter2;
      var selectors = [];
      var defaults = {
        live: true,
        getExternalTip: false,
        delayIn: 0,
        delayOut: 0,
        html: true,
        fade: false,
        fallback: "No info available",
        title: function () {
          var key = renderKey(this.__data__);
          var value = renderValue(this.__data__);
          var info = renderInfo(this.__data__);
          return Mustache.to_html(settings.template || "{{key}}:{{value}}", {
            title: key,
            key: (typeof settings.header === "function" ? settings.header() : settings.header) || "",
            value: settings.valueProp ? value[settings.valueProp] : value.y,
            key2: (typeof settings.header2 === "function" ? settings.header2() : settings.header2) || "",
            value2: settings.value2Prop ? value[settings.value2Prop] : value.y_2,
            info: info,
            tooltipClass: settings.tooltipClass || ""
          });
        }
      };
      const paths = args.paths || [];
      const yFormat = this.reportMetaObj.fields[this.yKey].type;
      const xFormat = this.reportMetaObj.fields[this.xKey].type;
      const yFormatter = yFormat === "currency" ? this.currencyFormatter : this.dataFormatters(yFormat);
      const xFormatter = xFormat === "currency" ? this.currencyFormatter : this.dataFormatters(xFormat);
      if (this.mmChart) {
        const yFormat2 = this.reportMetaObj.fields[this.yKey2].type;
        yFormatter2 = yFormat2 === "currency" ? this.currencyFormatter2 : this.dataFormatters(yFormat2);
      }
      renderKey = args.renderKey || function (data) {
        return xFormatter(data.xLabel);
      };
      renderValue = args.renderValue || function (data) {
        return {
          y: yFormatter(data.y),
          y_2: data.y_2 !== undefined ? yFormatter2(data.y_2) : "",
          x: data.x !== undefined ? xFormatter(data.x) : ""
        };
      };
      renderInfo = args.renderInfo || function (data) {
        var formatter = self.dataFormatters("date");
        return formatter(data.xLabel);
      };
      settings = $.extend({}, defaults, args);
      for (let i = 0; i < paths.length; i++) {
        selectors.push(`${$ttParentEle} ${paths[i]}`);
      }
      settings.tooltipEle = selectors.join(", ");
      $(document).find(".tipsy").remove();
      this.tooltips = T1.Tooltip("body", settings);
    },
    graphLines(args = {}) {
      var self = this;
      var circleColors = {};
      var line = args.line || this.line;
      const side = args.side || "left";
      const data = args.data || this.dataStack;
      const strokeWidth = args.strokeWidth || 1;
      const circleStroke = args.circles === false ? 0 : strokeWidth;
      const circleColor = args.color || this.chartColors(this.colorIndex);
      const circleStrokeColor = args.circleStrokeColor || circleColor;
      const radius = args.circleRadius || this.circleRadius;
      if (data[0].length > 0) {
        const lines = this.vis.selectAll("path.line").data(data);
        lines.exit().remove();
        lines.enter().append("path").attr("class", "line").style("stroke", function (d) {
          return d[0].color;
        }).attr("d", line).attr("stroke-width", strokeWidth);
        const circles = this.vis.selectAll("g.circles").data(data);
        circles.exit().remove();
        circles.enter().append("g").attr("class", "circles").selectAll("circle.line").data(function (d) {
          circleColors[d[0][self.dimensionKey]] = d[0].color;
          return d;
        }).enter().append("circle").attr("class", "circle").attr("stroke", circleStrokeColor).attr("stroke-width", circleStroke).attr("fill", function (d) {
          return circleColors[d[self.dimensionKey]];
        }).attr("cx", function (d) {
          const half = .5;
          return self.units === 1 ? (self.width - radius) * half : self.x(d.x);
        }).attr("cy", function (d) {
          return self.yRadius(d.y);
        }).attr("r", radius).attr("style", function () {
          return args.hideCircles ? "opacity: 0" : "";
        });
      }
      const lineLabels = $(`<span class="y-label axis-label ${side}-axis-label">${this.yAxisLabel}</span>`);
      this.chartCanvas.find(`.${side}-axis-label`).remove().end().append(lineLabels);
      return this;
    },
    graphBars(args = {}) {
      var self = this;
      const defaultGutter = 6;
      const side = args.side || "left";
      const gutter = args.gutter || defaultGutter;
      const barWidth = this.targetW - gutter;
      if (this.dataStack[0].length > 0) {
        const layers = this.vis.selectAll("g.layer").data(this.dataStack);
        layers.exit().remove();
        layers.enter().append("g").attr("class", "layer").attr("transform", function () {
          const half = .5;
          const halfWidth = self.targetW * half;
          var xpos = halfWidth - self.targetW;
          return `translate(${xpos}, 0)`;
        });
        const bars = layers.selectAll("g.bar").data(d => d);
        bars.exit().remove();
        bars.enter().append("g").attr("class", "bar").attr("transform", function (d) {
          const half = .5;
          var xpos = self.units === 1 ? self.width * half : self.x(d.x);
          return `translate(${xpos}, 0)`;
        }).style("fill", d => d.color);
        bars.append("rect").attr("width", function () {
          const minWidth = 2;
          return barWidth < minWidth ? minWidth : barWidth;
        }).attr("x", function () {
          const minWidth = 2;
          const defaultWidth = 3;
          return barWidth < minWidth ? 0 : defaultWidth;
        }).attr("y", d => self.y(d.y)).attr("height", d => self.height - self.y(d.y));
      }
      const barLabels = $(`<span class="y-label axis-label ${side}-axis-label">${this.yAxisLabel}</span>`);
      this.chartCanvas.find(`.${side}-axis-label`).remove().end().append(barLabels);
      return this;
    },
    graphDots(args = {}) {
      var circles;
      var self = this;
      const side = args.side || "left";
      const data = args.data || this.dataStack;
      const strokeWidth = args.strokeWidth || 1;
      const circleStroke = args.circles === false ? 0 : strokeWidth;
      const circleColor = args.color || this.chartColors(this.colorIndex);
      const circleStrokeColor = args.circleStrokeColor || circleColor;
      const radius = args.circleRadius || this.circleRadius;
      const extendFactor = 1.25;
      const biggerRadius = radius * extendFactor;
      if (data[0].length > 0) {
        const layer = this.vis.selectAll("g.circles").data(data);
        layer.exit().remove();
        layer.enter().append("g").attr("class", "circles");
        circles = layer.selectAll("g.circle").data(d => d);
        circles.exit().remove();
        const duration = 20;
        circles.enter().append("g").attr("class", "circle").append("circle").attr("class", "circle").attr("stroke", circleStrokeColor).attr("stroke-width", circleStroke).attr("fill", d => d.color).attr("cx", d => self.xRadius(d.x)).attr("cy", d => self.yRadius(d.y)).attr("r", radius).on("mouseover", function () {
          d3.select(this).transition().duration(duration).ease("linear").attr("r", biggerRadius);
        }).on("mouseout", function () {
          d3.select(this).transition().duration(duration).ease("linear").attr("r", radius);
        });
        if (this.dataPointDescriptors && this.dataPointDescriptors.add) {
          circles.enter().append("g").attr("class", "label").append("text").attr("class", "descriptor").attr("x", d => self.xRadius(d.x) + radius).attr("y", d => self.yRadius(d.y) - radius).text(d => d[self.dimensionKey]).attr("display", this.dataPointDescriptors.display);
        }
      }
      const lineLabels = $(`<span class="y-label axis-label ${side}-axis-label">${this.yAxisLabel}</span>`);
      this.chartCanvas.find(`.${side}-axis-label`).remove().end().append(lineLabels);
      return this;
    },
    canDisplay() {
      return this.isOpen();
    },
    initChart() {
      this.chartPanels = this.$(this.chartPanelSelector);
      this.chartCanvas = this.chartPanels.eq(0);
      this.chartCanvas.empty();
      return this;
    },
    updateSettings() {
      this.dataCount = this.chartData[0] ? this.chartData[0].length : 0;
      if (this.chartView === "site") {
        this.units = 50;
      } else {
        this.units = this.dataCount < 1 ? this.origChartData ? this.origChartData.length : 1 : this.dataCount;
      }
      const startEndPoints = 2;
      const topOffset = this.padMatrix.top * startEndPoints;
      this.width = this.chartCanvas.width() - (this.padMatrix.left + this.padMatrix.right);
      this.height = this.chartCanvas.height() - topOffset - this.padMatrix.bottom;
      this.gridW = this.width / this.units;
      this.targetW = this.gridW > this.maxW ? this.maxW : this.gridW;
      this.width -= this.targetW;
      this.colorIndex = 0;
      this.effectiveInterval = this.interval - startEndPoints;
      this.xDomain = [0];
      this.yDomain = [0];
      this.yDomain2 = [0];
      this.updateCurrency();
      return this;
    },
    setupCanvas() {
      const multiplier = 4;
      const half = 2;
      const padMatrixTop = this.padMatrix.top * multiplier;
      const halfTargetW = this.targetW / half;
      this.pnl = d3.select(this.chartCanvas[0]);
      this.pnl.select("svg").remove();
      this.vis = this.pnl.insert("svg", ":first-child").attr("width", this.width + (this.padMatrix.left + this.padMatrix.right) + this.targetW).attr("height", this.height + (padMatrixTop + this.padMatrix.bottom)).classed("chart", true).append("g").attr("transform", `translate(${this.padMatrix.left + halfTargetW}, ${this.padMatrix.top})`);
      if (this.reportType) {
        this.vis.classed(this.reportType, true);
      }
      return this;
    },
    setupDataProcessors() {
      var self = this;
      var i;
      var benchmarks = this.getMaxBenchmarks ? this.getMaxBenchmarks() : null;
      const defaultLength = 5;
      const decimals = 2;
      if (this.chartView === "site") {
        this.maxX = d3.max(this.dataStack, function (datum1) {
          return d3.max(datum1, datum2 => datum2.x) || 0;
        });
      } else {
        this.maxX = d3.max(this.dataStack, function (datum1) {
          return d3.max(datum1, (d, index) => index) || (self.origChartData && self.origChartData.length ? self.origChartData.length - 1 : defaultLength);
        });
      }
      if (benchmarks) {
        this.dataStack[0].push({
          y: benchmarks.campaignMaxValue
        });
        this.dataStack[0].push({
          y: this.mmChart ? 0 : benchmarks.campaignGoalValue
        });
      }
      this.maxY = d3.max(this.dataStack, function (datum1) {
        return d3.max(datum1, datum2 => datum2.y) || 1;
      });
      if (this.mmChart) {
        this.maxY2 = d3.max(this.dataStack, function (datum1) {
          return d3.max(datum1, datum2 => datum2.y_2) || 1;
        });
      }
      if (benchmarks) {
        this.dataStack[0].pop();
        this.dataStack[0].pop();
      }
      if (this.chartView === "site") {
        this.xInterval = this.maxX / this.effectiveInterval;
        for (i = 1; i < this.effectiveInterval; i++) {
          this.xDomain.push((Number(this.xInterval) * i).toFixed(decimals));
        }
        this.xDomain.push(this.maxX);
        this.xOrdinal = d3.scale.ordinal().rangePoints([0, this.width]).domain(this.xDomain);
      }
      this.yInterval = this.maxY / this.effectiveInterval;
      for (i = 1; i < this.effectiveInterval; i++) {
        this.yDomain.push((Number(this.yInterval) * i).toFixed(decimals));
      }
      this.yDomain.push(this.maxY);
      this.yOrdinal = d3.scale.ordinal().rangePoints([this.height, 0]).domain(this.yDomain);
      this.x = d3.scale.linear().nice().range([0, this.width]).domain([0, this.maxX]);
      this.y = d3.scale.linear().nice().range([this.height, 0]).domain([0, this.maxY]);
      this.xWidth = () => this.targetW;
      this.xRadius = function (x) {
        var rx = parseInt(x * self.width / self.maxX, 10);
        return isNaN(rx) ? 0 : rx;
      };
      this.yRadius = function (y) {
        const height1 = y * self.height;
        const height2 = height1 / self.maxY;
        var ry = parseInt(self.height - height2, 10);
        return isNaN(ry) ? self.height : ry;
      };
      if (this.mmChart) {
        this.yInterval2 = this.maxY2 / this.effectiveInterval;
        for (i = 1; i < this.effectiveInterval; i++) {
          this.yDomain2.push((Number(this.yInterval2) * i).toFixed(decimals));
        }
        this.yDomain2.push(this.maxY2);
        this.yOrdinal2 = d3.scale.ordinal().rangePoints([this.height, 0]).domain(this.yDomain2);
        this.y_2 = d3.scale.linear().nice().range([this.height, 0]).domain([0, this.maxY2]);
        this.yRadius2 = function (y) {
          const height1 = y * self.height;
          const height2 = height1 / self.maxY2;
          var ry = parseInt(self.height - height2, 10);
          return isNaN(ry) ? self.height : ry;
        };
      }
      return this;
    },
    xAxisGenerator() {
      var format = this.reportMetaObj.fields[this.xKey].type;
      var formatter = format === "currency" ? this.currencyFormatter_chartAxis : this.dataFormatters(format);
      return d3.svg.axis().scale(this.xOrdinal || this.x).tickFormat(formatter).tickSize(this.units === 1 ? 0 : -this.height).tickSubdivide(1).tickPadding(this.tickPadding).orient(this.xAxisOrientation);
    },
    yAxisGenerator(rightAxis) {
      let formatter2;
      var format = this.reportMetaObj.fields[rightAxis ? this.yKey2 : this.yKey].type;
      var formatter = format === "currency" ? this.currencyFormatter_chartAxis : this.dataFormatters(format);
      const half = .5;
      const halfTargetW = this.targetW * half;
      if (this.mmChart) {
        formatter2 = format === "currency" ? this.currencyFormatter_chartAxis2 : this.dataFormatters(format);
      }
      return d3.svg.axis().scale(rightAxis ? this.yOrdinal2 : this.yOrdinal).tickFormat(rightAxis ? formatter2 : formatter).tickSize((rightAxis ? this.width : 0) + this.tickSize + halfTargetW).tickPadding(this.tickPadding).orient(this.yAxisOrientation);
    },
    lineGenerator(yRadiusParam = this.yRadius, yParam = "y") {
      return d3.svg.line().interpolate("linear").x(d => this.x(d.x)).y(d => yRadiusParam(d[yParam]));
    },
    setupAxis() {
      this.xAxis = this.xAxisGenerator();
      this.yAxisOrientation = "left";
      this.yAxis = this.yAxisGenerator();
      this.line = this.lineGenerator();
      if (this.mmChart) {
        this.yAxisOrientation = "right";
        this.yAxis2 = this.yAxisGenerator(true);
        this.line2 = this.lineGenerator(this.yRadius2, "y_2");
      }
      return this;
    },
    generateGrid() {
      const half = 2;
      const halfTargetW = this.targetW / half;
      this.vis.append("g").attr("class", "x axis").attr("transform", `translate(0,${this.height})`).call(this.xAxis);
      this.vis.append("g").attr("class", "y ybar axis").call(this.yAxis);
      if (this.mmChart) {
        this.vis.append("g").attr("class", "y ybar2 axis").call(this.yAxis2);
      }
      const yGrid = this.vis.selectAll("g.yline").data(this.dataStack);
      yGrid.enter().append("g").attr("class", "y yline axis");
      const yGridLines = yGrid.selectAll("line.yline").data(this.yDomain, d => d);
      yGridLines.enter().append("line").attr("class", "yline").attr("x1", 0).attr("x2", this.width + halfTargetW).attr("y1", d => this.yOrdinal(d)).attr("y2", d => this.yOrdinal(d));
      this.vis.append("line").attr("x1", 0).attr("x2", this.width - this.xWidth()).attr("y1", this.height).attr("y2", this.height);
      return this;
    },
    graphXValues() {
      return this.graphXAxisLabels();
    },
    graphYValues() {
      return this.graphYAxisLabels().graphBars(this);
    },
    graphXAxisLabels() {
      var self = this;
      var xAxisGroupLabel, xLabel;
      const limit = 2;
      const half = .5;
      var lastIndex = this.xItemsCount - 1;
      var chartView = this.chartView;
      var xValuesData = this.dataStack[0].length ? this.dataStack[0] : this.origChartData;
      this.crowdLevel = this.xItemsCount > this.crowdLevel1Limit ? limit : this.xItemsCount > this.crowdLevel0Limit ? 1 : 0;
      const crowdedSummary = chartView === "summary" && this.crowdLevel > 0;
      const xValues = this.vis.selectAll("g.label").data(xValuesData);
      const g = xValues.enter().append("g").attr("class", "label").attr("transform", function (d) {
        var xpos = self.units === 1 ? self.width * half : self.x(d.x) - self.xAxisLabelAdjustment();
        var ypos = self.height + self.padMatrix.bottom + self.xAxisLabelOffsetY;
        const offset = 10;
        if (crowdedSummary) {
          if (d.x === 0) {
            xpos -= offset;
          } else if (d.x === lastIndex) {
            xpos += offset;
          }
        }
        return `translate(${xpos}, ${ypos})`;
      });
      g.append("text").attr("dy", ".75em").attr("text-anchor", this.xAxisLabelTextAnchor).attr("id", d => `xlb${d.x}`).attr("title", d => d.xLabel).text(function (d) {
        var type = self.reportMetaObj.fields[self.xKey].type;
        var formatter = self.dataFormatters(type);
        var formattedLabel = formatter(d.xLabel || d.start_date);
        if (type === "date") {
          const formattedDate = formattedLabel.split("-");
          const [year, month, day, hour, unit] = formattedDate;
          const hr = parseInt(unit, 10);
          const maxCrowdLevel = 2;
          const quarterDay = 3;
          const halfDay = 12;
          d.dateInfo = {
            year: year,
            month: month,
            day: day,
            hour: hour,
            hr: hr
          };
          if (hour) {
            switch (self.crowdLevel) {
              case 0:
                return hour.toLowerCase();
              case 1:
                return hr % quarterDay === 0 ? hour.toLowerCase() : "";
              case maxCrowdLevel:
                return hr % halfDay === 0 ? hour.toLowerCase() : "";
            }
          } else if (day) {
            switch (self.crowdLevel) {
              case 0:
                return day;
              case 1:
                return Date.parse(formattedLabel, self.dateformat).getDay() === 1 ? day : "";
              case maxCrowdLevel:
                return d.x === 0 || day === "01" ? month : "";
            }
          } else {
            return year;
          }
        } else {
          return self.trunc(formattedLabel, false);
        }
      }).attr("transform", () => `rotate(${self.xAxisLabelRotation})`).style("visibility", function (d) {
        if (chartView === "summary") {
          if (!crowdedSummary) {
            return "visible";
          }
          if (self.highlightedX === -1) {
            return d.x === 0 || d.x === lastIndex ? "visible" : "hidden";
          }
          return d.x === self.highlightedX ? "visible" : "hidden";
        }
        return "visible";
      });
      if (chartView === "summary") {
        const xSubLabel = this.xAxisSubLabel === "" ? "" : `<span class="x-axis-sublabel" data-bind="x-axis-sublabel">${this.xAxisSubLabel}</span>`;
        xLabel = $(`${'<span class="x-label axis-label x-axis-label"><span>'}${this.xAxisLabel}</span>${xSubLabel}</span>`);
        this.chartCanvas.find(".x-axis-label").remove().end().append(xLabel);
      } else if (chartView === "site") {
        xLabel = $(`<span class="x-label axis-label x-axis-label">${this.xAxisLabel}</span>`);
        this.chartCanvas.find(".x-axis-label").remove().end().append(xLabel);
      } else {
        g.append("text").attr("dy", "2.7em").attr("text-anchor", this.xAxisLabelTextAnchor).text(function (d) {
          var groupLabel;
          var dateInfo = d.dateInfo;
          var year = dateInfo.year;
          var month = dateInfo.month;
          var day = dateInfo.day;
          var hour = dateInfo.hour;
          const maxCrowdLevel = 2;
          if (hour) {
            groupLabel = `${month} ${parseInt(day, 10)}`;
          } else {
            groupLabel = month ? self.crowdLevel === maxCrowdLevel ? year : month : "";
          }
          if (groupLabel !== xAxisGroupLabel) {
            xAxisGroupLabel = groupLabel;
            return groupLabel;
          }
        }).attr("transform", () => `rotate(${self.xAxisLabelRotation})`).classed("x-axis-group-label", true);
      }
      return this;
    },
    graphYAxisLabels(hideLabel) {
      if (!hideLabel) {
        const yLabel = $(`<span class="y-label axis-label left-axis-label">${this.yAxisLabel}</span>`);
        this.chartCanvas.find(".left-axis-label").remove().end().append(yLabel);
        if (this.yAxisLabel2) {
          const yLabel2 = $(`<span class="y-label axis-label right-axis-label">${this.yAxisLabel2}</span>`);
          this.chartCanvas.find(".right-axis-label").remove().end().append(yLabel2);
        }
      }
      return this;
    },
    exitChart() {
      this.message.hide(this);
      this.el.trigger("onChartComplete");
      return this;
    },
    setupHighlight() {
      return this;
    },
    chart() {
      var mmChart = this.mmChart;
      if (mmChart) {
        if (!this.canLoad || !this.isOpen()) {
          return this;
        }
      } else {
        if (!this.reportModel.get("colorData")) {
          return this;
        }
      }
      if (this.collection && (!this.collection.loaded || !this.canDisplay() || this.reportModel.get("noDataRender"))) {
        if (this.collection.loaded) {
          this.showNoDataMessage();
          this.exitChart();
        }
        return null;
      }
      this.dataStack = this.chartData;
      this.initChart().updateSettings();
      if (this.height < 0) {
        this.exitChart();
        return null;
      }
      if (!this.dataStack.length) {
        if (mmChart || !mmChart && this.collection.loaded) {
          this.showNoDataMessage();
          this.exitChart();
          return null;
        }
      }
      this.hideNoDataMessage();
      return this.setupDataProcessors().setupHighlight().setupAxis().setupCanvas().generateGrid().graphXValues().graphYValues().exitChart();
    },
    update(selectedItem) {
      this.selectKey = selectedItem ? selectedItem.get(this.aggregateBy) : null;
      return this.processData().chart();
    },
    loaderOpts: {
      context: ".w-box.w-Chart",
      target: ".view-loader",
      text: "requesting data",
      contentClass: "loader"
    },
    message: {
      show: function (msg, context) {
        if (!context) {
          $(".chart-msg-panel").show().html(msg);
        } else {
          if (!context.loaderStarted) {
            context.chartLoader.start();
          }
          context.loaderStarted = true;
        }
      },
      hide: function (context) {
        context.chartLoader.stop();
        context.loaderStarted = false;
        $(".chart-msg-panel").empty();
      }
    },
    showNoDataMessage() {
      this.$(".w-Chart .no-data").removeClass("no-display");
    },
    hideNoDataMessage() {
      this.$(".w-Chart .no-data").removeClass("no-display").addClass("no-display");
    },
    serialize() {
      const defaultVcells = 10;
      const defaultHcells = 3;
      var vcells = [];
      var hcells = [];
      var bars = [];
      for (let i = 0; i < defaultVcells; i++) {
        vcells.push({});
      }
      for (let i = 0; i < defaultHcells; i++) {
        hcells.push({});
        bars.push({
          "bar-id": i
        });
      }
      return {
        bars: bars,
        hcells: hcells,
        labels: this.labels,
        msg: "No data available for this selection",
        title: this.entity ? this.entity.get("name") : "",
        toggleClass: this.toggleClass,
        vcells: vcells,
        yKeyLabel: this.yKey
      };
    }
  });
  T1ChartExtend = T1Chart.extend;
  T1Chart.extend = function (...args) {
    const ViewSubClass = T1ChartExtend.apply(this, args);
    const originalInit = ViewSubClass.prototype.initialize || $.noop;
    const originalDataEvents = ViewSubClass.prototype.dataEvents || {};
    const originalLoad = ViewSubClass.prototype.load || $.noop;
    const originalUnLoad = ViewSubClass.prototype.unload || $.noop;
    const originalPadMatrix = ViewSubClass.prototype.padMatrix || {};
    ViewSubClass.prototype.padMatrix = $.extend(true, {}, T1Chart.prototype.padMatrix, originalPadMatrix);
    ViewSubClass.prototype.dataEvents = $.extend(true, {}, T1Chart.prototype.dataEvents, originalDataEvents);
    ViewSubClass.prototype.initialize = function (...args2) {
      originalInit.apply(this, args2);
      T1Chart.prototype.initialize.apply(this, args2);
    };
    ViewSubClass.prototype.load = function (...args3) {
      originalLoad.apply(T1Chart.prototype.load.apply(this, args3), args3);
    };
    ViewSubClass.prototype.unload = function (...args4) {
      T1Chart.prototype.unload.apply(this, args4);
      originalUnLoad.apply(this, args4);
    };
    return ViewSubClass;
  };
  return T1Chart;
});
