define('models/customBrainSelection',["jQuery", "Underscore", "T1", "T1Model", "T1Collection", "collections/customBrainSelections"], function ($, _, T1, T1Model, T1Collection, CustomBrainSelections) {
  T1.Models.CustomBrainSelection = T1Model.extend({
    urlString: "campaigns",
    url: "campaigns",
    canCache: false,
    action: "custom_brain_selections/",
    fixture: "-custom_brain_selections",
    relations: [{
      type: Backbone.HasMany,
      key: "user_saved",
      relatedModel: "T1Model",
      collectionType: "CustomBrainSelections",
      includeInJSON: false
    }, {
      type: Backbone.HasMany,
      key: "partials",
      relatedModel: "T1Model",
      collectionType: "T1Collection",
      includeInJSON: false
    }],
    url: function () {
      var originalString = this.urlString;
      var url;
      if (this.id === undefined) {
        throw new Error("Campaign id needs to be set in id property of collection to construct URL");
      }
      this.urlString = originalString + "/" + this.id + "/" + this.action;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    parse: function (data) {
      var result = {
        user_saved: [],
        partials: []
      };
      var topLevelEntityArr = data.entities || [];
      if (topLevelEntityArr && !$.isArray(topLevelEntityArr)) {
        topLevelEntityArr.set = "direct";
        topLevelEntityArr = [topLevelEntityArr];
      }
      $.each(topLevelEntityArr, function (index, entityGroup) {
        var resultEntities = T1Collection.prototype.parse({
          entities: entityGroup
        });
        if (entityGroup.set && entityGroup.set == "partial") {
          result.partials = resultEntities;
        }
        if (entityGroup.set && entityGroup.set == "direct") {
          $.each(resultEntities, function (index, entity) {
            entity.action = "include";
          });
          result.user_saved = resultEntities;
        }
      });
      return result;
    }
  });
  return T1.Models.CustomBrainSelection;
});
