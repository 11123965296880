define('modules/shared/main',["T1Module", "collections/creativesConceptsStrategies"], function (T1Module, StrategyConcepts) {
  "use strict";

  var strategies = new StrategyConcepts(null, {
    pageLimit: 50
  });
  return new T1Module({
    name: "shared",
    viewCfgs: {
      filter: {
        collection: null
      },
      gridSorter: {
        collection: null,
        sortableHeaders: {}
      },
      list: {},
      pagination: {
        collection: null
      },
      pullDownButton: {},
      searchBox: {
        collection: null
      },
      searchBoxGrid: {
        collection: null
      },
      select: {},
      advertiserSelect: {},
      selectForRibbonStrip: {},
      strategy_list: {
        collection: strategies
      },
      strategy_grid: {}
    }
  });
});
