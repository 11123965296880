define('modules/error/main',["jQuery", "T1", "T1Module", "modules/error/models/error"], function ($, T1, T1Module, errorModel) {
  return new T1Module({
    name: "error",
    viewCfgs: {
      errorMessage: {
        model: errorModel
      },
      errorPopup: {
        model: null
      }
    },
    defaultView: "errorMessage"
  });
});
