define('T1TabLayoutV2',["jQuery", "Underscore", "Mustache", "T1", "T1View", "text!templates/tabs.html", "text!templates/tabItem.html", "jQueryUI", "T1Tooltip"], function ($, _, Mustache, T1, T1View, tabsTmpl, tabContentTmpl) {
  "use strict";

  return T1View.extend({
    template: tabsTmpl,
    partials: {
      tabContent: tabContentTmpl
    },
    selected: 0,
    contextSuffix: "-item",
    transformConfigs: function (cfgs) {
      var tabs = [];
      var tabLevelId;
      var selectedContext = null;
      var context;
      var tabsConfigs;
      var length;
      var i = 0;
      var self = this;
      var tab;
      if (!cfgs) {
        throw new Error("need configuration to transform for tabs");
      }
      cfgs.tabs = cfgs.tabs || [];
      cfgs.level = cfgs.level || 1;
      tabsConfigs = cfgs.tabs;
      length = tabsConfigs.length;
      for (i; i < length; i++) {
        tab = tabsConfigs[i];
        tabLevelId = "tab-" + (i + 1) + "-level-" + cfgs.level;
        tab.opts = {
          infoIcon: tab.infoIcon,
          itemClass: tabLevelId + this.contextSuffix,
          label: tab.label,
          linkClass: tabLevelId,
          linkId: tabLevelId,
          tooltipText: tab.tooltipText
        };
        context = "." + tab.opts.itemClass;
        self.routeConfig[tab.routeName] = {
          context: context,
          index: i
        };
        if (i === this.selected) {
          selectedContext = context;
          this.originalSelectedRouteConfig = self.routeConfig[tab.routeName];
        }
        tabs.push(tab.opts);
        this.panels[context] = {
          module: tab.module,
          options: tab.options,
          routeName: tab.routeName,
          viewType: tab.viewType
        };
      }
      return {
        ignoreRouteDepth: cfgs.ignoreRouteDepth,
        level: cfgs.level,
        selectedContext: selectedContext,
        tabs: tabs,
        tabSetName: cfgs.tabSetName
      };
    },
    loadPanel: function (context) {
      var panel = this.panels[context];
      var $el = $(this.el).find(context);
      var location = T1.Location.getRoute();
      var tabLevel = this.tabCfgs.level - 1;
      var deepLinks = location.deepLinks;
      var url = [];
      var isReportsModule = location.module === "#reports";
      function fetchView(module, viewType, options) {
        var deferred = $.Deferred();
        require(["modules/" + module + "/main"], function (module) {
          var viewPromise = module.getView.call(module, viewType, options);
          viewPromise.done(function (view) {
            deferred.resolve(view);
          });
        });
        return deferred.promise();
      }
      if (this.tabCfgs.ignoreRouteDepth) {
        location = T1.Location.get();
        deepLinks = location.split("/");
        if (deepLinks[deepLinks.length - 1] !== panel.routeName) {
          location += "/" + panel.routeName;
          T1.Location.set(location, {
            replaceHistoryStack: true,
            silent: true
          });
        }
      }
      if (deepLinks[tabLevel] !== panel.routeName && !this.tabCfgs.ignoreRouteDepth) {
        if (!isReportsModule) {
          deepLinks = deepLinks.slice(0, tabLevel);
          deepLinks[tabLevel] = panel.routeName;
        }
        url.push(location.module);
        if (location.mode) {
          url.push(location.mode);
        }
        if (location.section) {
          url.push(location.section);
        }
        if (location.id) {
          if (_.has(this.routeConfig, location.id)) {
            location.id = undefined;
          } else {
            url.push(location.id);
          }
        }
        location.deepLinks = deepLinks;
        T1.EventHub.publish("router:updateCurrentLocation", location);
        url = deepLinks.length > 0 ? url.join("/") + "/" + deepLinks.join("/") : url.join("/");
        url = url.replace("//", "/");
        T1.Location.set(url, {
          replace: true,
          replaceHistoryStack: true,
          silent: true
        });
      }
      if (panel.view) {
        panel.view.el = $el;
        panel.view.reload();
      } else {
        fetchView(panel.module, panel.viewType, panel.options).done(function (view) {
          panel.view = view;
          view.el = $el;
          view.load.call(view);
        });
      }
    },
    execute: function () {
      if (!arguments) {
        return;
      }
      this.tabEl.tabs.apply(this.tabEl, arguments);
    },
    initialize: function (opts) {
      this.panels = {};
      this.routeConfig = {};
      this.originalSelectedRouteConfig = {};
      this.disabled = [];
      opts = opts || {};
      this.selected = opts.selected || 0;
      this.tabCfgs = this.transformConfigs(opts);
      this.disabled = opts.disabled || [];
      this.destroyViewOnSwitch = opts.destroyViewOnSwitch;
    },
    load: function (loadOpts) {
      var self = this;
      var tabCfgs = this.tabCfgs;
      var tabLevelContext = ".tab-level-" + tabCfgs.level;
      var selectedContext = loadOpts ? loadOpts.selectedContext : tabCfgs.selectedContext;
      var el;
      var originalLoad = T1View.prototype.load;
      var routeConfig = this.routeConfig;
      var location = T1.Location.getRoute();
      var currentTab = location.deepLinks[tabCfgs.level - 1];
      var currentTabRouteConfig = routeConfig[currentTab];
      if (this.tabCfgs.ignoreRouteDepth) {
        currentTab = T1.Location.get(true).split("/").pop();
        currentTabRouteConfig = routeConfig[currentTab];
      }
      if (!this.resolveEl) {
        this.resolveEl = typeof self.el === "function" ? self.el : function () {
          return self.el;
        };
      }
      this.el = el = this.resolveEl();
      if (this.loaded) {
        return;
      }
      originalLoad.call(this);
      if (currentTabRouteConfig) {
        selectedContext = currentTabRouteConfig.context;
        this.selected = currentTabRouteConfig.index;
      } else {
        selectedContext = this.originalSelectedRouteConfig.context;
        this.selected = this.originalSelectedRouteConfig.index;
      }
      if (selectedContext) {
        this.loadPanel(selectedContext);
        this.selectedContext = selectedContext;
      }
      this.tabEl = $(el).find(tabLevelContext);
      this.tabEl.tabs({
        disabled: this.disabled,
        selected: this.selected,
        select: $.proxy(this.switchTab, this),
        show: function (event, ui) {
          setTimeout(function () {
            T1.resizeWidth($(ui.panel));
          }, 0);
        }
      });
      T1.Tooltip(this.el.find(".ui-tab"), {
        delayIn: "200",
        delayOut: "600",
        gravity: "n",
        offset: 15
      });
      this.loaded = true;
    },
    switchTab: function (event, ui) {
      var tabId = $(ui.panel).attr("id");
      var panelView = this.panels[this.selectedContext].view;
      var context = "." + tabId + this.contextSuffix;
      var canUnloadSelected = this.canUnloadSelectedTab(tabId);
      setTimeout($.proxy(function () {
        T1.EventHub.publish("tab:select", {
          tabLevel: ui.tab.hash.substr(ui.tab.hash.indexOf("level")),
          tabLabel: ui.tab.innerText ? ui.tab.innerText : ui.tab.textContent
        });
        if (canUnloadSelected) {
          if (panelView && panelView.unload) {
            panelView.unload.call(panelView);
            if (this.destroyViewOnSwitch) {
              this.panels[this.selectedContext].view = null;
            }
          }
          this.loadPanel(context);
          this.selectedContext = context;
        }
      }, this), 0);
      return canUnloadSelected;
    },
    unload: function () {
      var currentPanel = this.panels[this.selectedContext];
      var currentPanelView;
      this.loaded = false;
      if (currentPanel) {
        currentPanelView = currentPanel.view;
        if (currentPanelView) {
          currentPanelView.unload();
        }
      }
    },
    canUnloadSelectedTab: function (tabId, customRedirectCallback) {
      var panel = this.panels[this.selectedContext];
      var tabEl = this.tabEl;
      var currentView = panel.view;
      var canUnloadMethod;
      var customRedirect = tabId ? {
        onAlertClose: function () {
          tabEl.tabs("select", "#" + tabId);
        }
      } : customRedirectCallback;
      if (currentView === undefined || currentView === null) {
        return true;
      }
      canUnloadMethod = currentView.canUnloadViews || currentView.canUnload;
      return canUnloadMethod ? canUnloadMethod.call(currentView, customRedirect) : true;
    },
    canUnload: function () {
      var currentPanelView = this.panels[this.selectedContext].view;
      return currentPanelView && currentPanelView.canUnload ? currentPanelView.canUnload.apply(currentPanelView, arguments) : true;
    },
    serialize: function () {
      return this.tabCfgs;
    }
  });
});
