define('modules/reporting/segments/dataExport/createEdit/performance/views/bins',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/bins.html"], function ($, _, T1, T1View, T1Layout, template) {
  "use strict";

  return T1View.extend({
    template: template,
    events: {},
    eventhubEvents: {
      campaignSelected: "showField"
    },
    initialize: function (args) {
      this.sharedCollection = args.sharedCollection;
    },
    load: function () {
      this.render();
    },
    showField: function () {
      var $el = this.el;
      $el.removeClass("faded");
      var bl = document.querySelector("#bl");
      bl.groupDataBy = [{
        label: "Segments",
        entity: "segments"
      }];
    },
    unload: function () {},
    serialize: function () {
      return {};
    }
  });
});
