define('router',['require','jQuery','Backbone','utils/sectionSwitchAssistant','T1','T1Comm','T1Layout','T1Permissions'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Backbone = require("Backbone");
  const SectionSwitchAssistant = require("utils/sectionSwitchAssistant");
  const T1 = require("T1");
  const T1Comm = require("T1Comm");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  let appRouter, currentAppView, initialized, previousAppView, sectionSwitchAssistant;
  const cfgs = {};
  const layouts = {};
  var currentLocation = {};
  var defaultLocation = "index";
  var locationNotFound = "404";
  var locationNotFoundUrl = "not-found.html";
  var defaultSections = {
    404: {
      tmplPath: "templates/404.html",
      layout: {}
    }
  };
  var defaultLayoutConfigs = {
    el: "body",
    sections: {}
  };
  function publishRouteEvent(location) {
    T1.EventHub.publish("route.changeLocation", location);
  }
  function resolveTargetLocation(route) {
    let location = T1.Location.getRoute(route);
    if (location.module === "") {
      location = {
        module: defaultLocation
      };
    }
    if (!cfgs[location.module]) {
      location = locationNotFound;
    }
    if (location.module === "index") {
      location.module = T1Comm.isUserLoggedIn() && !T1.NO_ACCESS ? "monitor" : "index";
      T1.Location.set(`#${location.module}`);
    }
    currentLocation = location;
    return location;
  }
  const AppRouter = Backbone.Router.extend({
    routes: {
      "*actions": "defaultAction"
    },
    layoutCfgs: $.extend({}, defaultLayoutConfigs),
    initialize(cfgSrc, baseSectionUrls) {
      var layoutCfgs = $.extend(this.layoutCfgs, cfgSrc);
      var sections = $.extend(layoutCfgs.sections, defaultSections);
      for (const section in sections) {
        if (T1.Utils.hasOwnProp(sections, section)) {
          const containerPath = `.${section} ${layoutCfgs.el}`;
          cfgs[section] = {
            cfgs: $.extend({}, {
              el: containerPath
            }, sections[section])
          };
        }
      }
      sectionSwitchAssistant = new SectionSwitchAssistant({
        baseSectionUrls: baseSectionUrls
      });
    },
    defaultAction(actions) {
      let currentLayout;
      var abortionRootURL = T1.Location.getAbortionRootURL();
      var previousLocation = currentLocation;
      const targetLocation = resolveTargetLocation(actions);
      if (!$.isEmptyObject(abortionRootURL) && T1.Utils.equalObjects(abortionRootURL, targetLocation)) {
        return;
      }
      const {
        module: module,
        mode: mode,
        section: section,
        id: id,
        deepLinks: deepLinks
      } = targetLocation;
      const switchingToNewLocation = T1.Utils.equalObjects(currentLocation, targetLocation);
      if (switchingToNewLocation) {
        const sectionSwitchDetectionResult = sectionSwitchAssistant.detectAppSectionChange(previousLocation, targetLocation);
        const layoutCfgs = cfgs[module] ? $.extend(true, {}, cfgs[module]) : null;
        if (targetLocation.module === locationNotFound || !layoutCfgs) {
          return T1.Location.set(locationNotFoundUrl, {
            external: true,
            replace: true
          });
        }
        const layOutPermission = layoutCfgs.cfgs.permission;
        if (T1Comm.isUserLoggedIn() && layOutPermission && !T1Permissions.check("feature", layOutPermission)) {
          return T1.Location.set("unauthorized", {
            replace: true
          });
        }
        layouts[module] = layouts[module] || {};
        const layoutModes = layoutCfgs.cfgs.modes || {};
        if (mode && layoutModes[mode]) {
          const modeOpts = {
            options: {}
          };
          if (mode) {
            modeOpts.options.mode = mode;
          }
          if (section) {
            modeOpts.options.section = section;
          }
          if (id) {
            modeOpts.options.id = id;
          }
          if (deepLinks.length) {
            modeOpts.options.deepLinks = deepLinks;
          }
          layoutModes[mode][0] = $.extend({}, layoutModes[mode][0] || {}, modeOpts);
          layoutCfgs.cfgs.layout = {
            ".routed-view": layoutModes[mode]
          };
        }
        if (mode) {
          layouts[module][mode] = new T1Layout(layoutCfgs.cfgs);
          currentLayout = layouts[module][mode];
        } else {
          currentLayout = new T1Layout(layoutCfgs.cfgs);
        }
        previousAppView = currentAppView;
        const canUnloadCurrentLayout = previousAppView ? previousAppView.canUnloadViews() : true;
        if (canUnloadCurrentLayout) {
          if (sectionSwitchDetectionResult.hasSectionChanged === true && sectionSwitchDetectionResult.savedUrl) {
            sectionSwitchAssistant.registerAppSectionChange(previousLocation);
            T1.Location.set(sectionSwitchDetectionResult.savedUrl, {
              replace: true
            });
            return;
          }
          currentAppView = currentLayout;
          T1.Location.resetAbort();
          if (previousAppView) {
            previousAppView.destroy();
          }
          publishRouteEvent(targetLocation);
          T1.Analytics.logPageview(window.location.hash.substr(1));
          $(window).scrollTop();
          currentAppView.load();
          T1.Location.push(actions);
          sectionSwitchAssistant.registerAppSectionChange(previousLocation);
        } else {
          currentLocation = previousLocation;
          T1.Location.push(actions);
          T1.Location.setAbortionRootURL(previousLocation);
          T1.Location.setAbortedURL(actions);
        }
      } else {
        publishRouteEvent(targetLocation);
      }
    }
  });
  function initialize(initCfgs, reload, baseSectionUrls) {
    if (initialized && !reload) {
      return false;
    }
    if (reload) {
      initCfgs = $.extend({}, defaultLayoutConfigs, initCfgs);
    }
    initialized = true;
    appRouter = appRouter || new AppRouter(initCfgs, baseSectionUrls);
    try {
      Backbone.history.start();
    } catch (e) {
      currentAppView.load();
    }
    T1.EventHub.subscribe("router:updateCurrentLocation", function (loc) {
      currentLocation = loc;
    });
    return true;
  }
  return {
    initialize: initialize
  };
});
