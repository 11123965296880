define('modules/reporting/segments/dataExport/createEdit/overlap/views/bins',["jQuery", "Underscore", "T1", "T1View", "text!../templates/bins.html"], function ($, _, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    initialize: function () {},
    load: function () {
      this.render();
    },
    unload: function () {},
    serialize: function () {
      return {};
    }
  });
});
