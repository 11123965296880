define('modules/audiences/onboarding/create/utils/bulkCreateUtils',["Underscore", "jQuery", "T1", "modules/audiences/segments/segmentUtils"], function (_, $, T1, Util) {
  "use strict";

  return {
    saveModel: function (e) {
      if (e) {
        Util.buttonLoader(e);
      }
      this.el[0].querySelector("#onboarding-form").classList.add("disabled");
      return this.model.save({
        success: (model, resp) => {
          this.successCallback.call(this, model, resp, e);
        },
        error: (e, status, error) => {
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
          this.enableSave();
          Util.buttonReset($("#save-button"), "Save");
          this.el[0].querySelector("#onboarding-form").classList.remove("disabled");
        }
      });
    },
    successCallback: function (resp) {
      if (this.model.delivery_method.includes("download")) {
        let deliveryFormat = this.model.delivery_format.toLowerCase();
        let blob = new Blob([resp], {
          type: deliveryFormat === "csv" ? "attachment/csv" : "text/plain"
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        let elem = document.body.appendChild(a);
        let handler = function () {
          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 1e3);
          document.body.removeChild(elem);
          elem.removeEventListener("click", handler);
        };
        a.href = url;
        a.download = "pixel_data." + deliveryFormat;
        elem.addEventListener("click", handler);
        elem.click();
      }
      T1.Notify("message", "Pixels successfully created!");
      T1.Location.set("#audiences/onboard");
    },
    save: function (opts) {
      const saveData = this.dataToSaveObj(true);
      return $.ajax({
        type: "POST",
        contentType: "application/json",
        url: this.apiRoot + this.urlRoot + "pixel_bundles",
        success: opts.success,
        error: opts.error,
        dataType: "text",
        data: JSON.stringify(saveData)
      });
    },
    pixelsListChanged: function () {
      this.model.pixels = this.pixelsRepeater.data;
      this.saveCheck();
    },
    assignDelivery: function (saveObj) {
      const deliveryProps = ["delivery_method", "email_to", "email_message"];
      deliveryProps.forEach(prop => {
        if (this[prop]) {
          saveObj[prop] = this[prop];
        }
      });
    }
  };
});
