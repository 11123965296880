define('modules/reporting/campaigns/performance/views/chartTitle',["jQuery", "Underscore", "T1", "T1View", "text!../../templates/chart_title_box.html"], function ($, _, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    eventhubEvents: {
      "update:metric": function () {
        this.reload();
      },
      "switch:chart": function (chartType) {
        this.subSection = chartType;
        this.reload();
      }
    },
    dataEvents: {
      reportModel: {
        "change:dimensionItems": function () {
          this.reload();
        },
        "change:hiddenCount": "updateSubtitle"
      }
    },
    initialize: function (args) {
      const models = args.models;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.dim1Collection = args.dataGroup.dim1Collection;
      this.dim1NestedCollection = args.dataGroup.collection;
      this.dim1FilteredCollection = args.dataGroup.dim1FilteredCollection;
      this.dim1FilteredNestedCollection = args.dataGroup.dim1FilteredNestedCollection;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.subSection = models.mainModel.get("performanceSubSection");
      this.timeSeriesInfo = models.timeSeries.daily;
      this.timeSeriesLabel = this.timeSeriesInfo.label;
      this.prepareRenderData();
    },
    prepareRenderData: function () {
      let collection = $.extend({}, this.dim1Collection);
      let nestedCollection = $.extend({}, this.dim1NestedCollection);
      const dim1Info = this.reportModel.get("dimensionInfo");
      const dim2Info = this.reportModel.get("dim2Info");
      const focus = this.reportModel.get("focus") || "all";
      const metricKey = this.reportMetaObj.metrics[focus];
      let yKey;
      if (dim2Info) {
        collection = $.extend({}, this.dim1FilteredCollection);
        nestedCollection = $.extend({}, this.dim1FilteredNestedCollection);
      }
      if (this.subSection === "daily") {
        this.collection = nestedCollection;
      } else {
        this.collection = collection;
      }
      yKey = this.reportModel.get("metric");
      yKey = metricKey[yKey] ? yKey : this.reportMetaObj.metricOptions[0].value;
      this.yAxisLabel = this.reportMetaObj.metrics[focus][yKey].text;
      this.dim1 = dim1Info.text;
      this.dim2 = dim2Info && dim2Info.text;
    },
    getTitle: function () {
      return `${this.yAxisLabel} by ${this.dim1}`;
    },
    getSubtitle: function () {
      const reportModel = this.reportModel;
      const yAxisLabel = this.yAxisLabel;
      const dim1 = this.dim1;
      const dim1HiddenCount = reportModel.get("hiddenCount") || 0;
      const dim1FilteredItems = reportModel.get("dim1FilteredItemsLookup");
      const dim1FilteredKeys = dim1FilteredItems ? _.keys(dim1FilteredItems) : [];
      const filteredInItems = reportModel.get("filteredInItems") || [];
      const filteredTotal = _.filter(dim1FilteredKeys, key => filteredInItems.indexOf(key) > -1);
      const total = this.dim2 ? filteredTotal.length : filteredInItems.length || this.collection.length;
      let chartCount = 0;
      if (dim1HiddenCount === 0) {
        chartCount = ` (all ${total})`;
      } else {
        chartCount = total > 0 ? ` (${total - dim1HiddenCount} of ${total})` : ` (0 of ${total})`;
      }
      if (this.subSection === "daily") {
        return `Lines show ${this.timeSeriesLabel.toLowerCase()} ${yAxisLabel} by ${dim1}${chartCount}`;
      }
      return `Bars show summary of ${yAxisLabel} by ${dim1}${chartCount}`;
    },
    updateSubtitle: function () {
      this.updateDataBind({
        chart_subtitle: this.getSubtitle()
      }, this.el);
    },
    serialize: function () {
      this.prepareRenderData();
      return {
        chart_subtitle: this.getSubtitle(),
        chart_title: this.getTitle()
      };
    }
  });
});
