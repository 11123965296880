define('modules/auth/views/resetPassword',["jQuery", "Underscore", "T1", "T1Comm", "T1View", "models/passwordReset", "text!modules/auth/templates/reset-password.html", "Mustache", "models/sessionUser", "models/userPreferences", "T1Spinner", "T1Layout", "jQueryUI", "T1Loader"], function ($, _, T1, T1Comm, T1View, Model, template, Mustache, User, UserPrefs, Spinner, T1Layout) {
  var errorCodes = {
    ERROR_CODE_MISSING_FIELD: 1,
    ERROR_CODE_INCORRECT: 2,
    ERROR_CODE_API: 4,
    ERROR_CODE_TOO_SHORT: 8
  };
  var errorStrings = {
    ERROR_YOUR: "Your ",
    ERROR_INCORRECT: " do not match",
    ERROR_ENTER_INFO: "Please enter your ",
    ERROR_PASSWORD: "new password",
    ERROR_PASSWORD_RETYPE: "re-typed password",
    ERROR_AND: " and ",
    ERROR_OR: " or ",
    ERROR_API: "An error occurred re-setting your password with the server",
    ERROR_TOO_SHORT: " is too short"
  };
  var viewContainers = {
    reset: ".w-reset-c",
    success: ".w-success-c"
  };
  var animDuration = 300;
  var paramFields = ["user", "user_id", "token"];
  return T1View.extend({
    resetData: {},
    template: template,
    model: new Model(),
    currentViewContainer: "",
    inServiceRequest: false,
    events: {
      "click .reset-action": "performReset",
      "keydown .password": "performResetOnEnter",
      "keydown .password-retype": "performResetOnEnter",
      "click .cancel": "returnToLogin",
      "click .return-to-login": "returnToLogin",
      "click .return-to-home .action": "returnToLogin"
    },
    initialize: function (args) {
      var self = this;
      var resetData = {};
      var params = self.parseQuery(document.location.search);
      self.versionInfo = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".version-information",
        template: '<div class="version-content"></div>',
        layout: {
          ".version-content": [{
            module: "version",
            viewType: "info"
          }]
        }
      });
      if (typeof Storage !== "undefined") {
        $.each(paramFields, function (i, k) {
          var v = sessionStorage.getItem("reset." + k);
          if (v) {
            resetData[k] = v;
            sessionStorage.removeItem("reset." + k);
          }
        });
      } else if (!$.isEmptyObject(params)) {
        resetData = params;
      }
      if ($.isEmptyObject(resetData) || !resetData.user_id || !resetData.token) {
        self.returnToLogin();
      } else {
        self.resetData = resetData;
      }
      self.currentViewContainer = viewContainers.reset;
      self.load();
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      self.render();
      self.versionInfo.load();
    },
    showErrors: function (errorCode) {
      var self = this;
      var $el = $(this.el);
      var password = $el.find(".password-new").not(".placeholder");
      var passwordRetype = $el.find(".password-retype").not(".placeholder");
      var errors = [];
      var errorTemplate = "{{#errors}}<li>{{.}}</li>{{/errors}}";
      function requiredError() {
        var passwordVal = $.trim(password.val());
        var passwordRetypeVal = $.trim(passwordRetype.val());
        var requiredFields = [];
        function setRequiredError(field, message) {
          var fieldVal = $.trim(field.val());
          if (!fieldVal.length) {
            requiredFields.push(message);
            field.addClass("error");
          }
        }
        if (self.currentViewContainer === viewContainers.reset) {
          setRequiredError(password, errorStrings.ERROR_PASSWORD);
          setRequiredError(passwordRetype, errorStrings.ERROR_PASSWORD_RETYPE);
        }
        return requiredFields.length ? [errorStrings.ERROR_ENTER_INFO + requiredFields.join(errorStrings.ERROR_AND)] : [];
      }
      function inValidInfo() {
        var fields = [errorStrings.ERROR_PASSWORD, errorStrings.ERROR_PASSWORD_RETYPE];
        return [errorStrings.ERROR_YOUR + fields.join(errorStrings.ERROR_AND) + errorStrings.ERROR_INCORRECT];
      }
      function APIError() {
        var fields = [errorStrings.ERROR_PASSWORD, errorStrings.ERROR_PASSWORD_RETYPE];
        return [errorStrings.ERROR_API];
      }
      function tooShort() {
        var fields = [errorStrings.ERROR_PASSWORD, errorStrings.ERROR_PASSWORD_RETYPE];
        return [errorStrings.ERROR_YOUR + errorStrings.ERROR_PASSWORD + errorStrings.ERROR_TOO_SHORT];
      }
      if (errorCode) {
        if (errorCode & errorCodes.ERROR_CODE_MISSING_FIELD) {
          errors.push(requiredError());
        }
        if (errorCode & errorCodes.ERROR_CODE_INCORRECT) {
          errors.push(inValidInfo());
        }
        if (errorCode & errorCodes.ERROR_CODE_API) {
          errors.push(APIError());
        }
        if (errorCode & errorCodes.ERROR_CODE_TOO_SHORT) {
          errors.push(tooShort());
        }
      }
      if (errors.length) {
        $el.find(".error-list").html(Mustache.to_html(errorTemplate, {
          errors: errors
        }));
        $el.find(".w-errorBox").fadeIn();
      }
    },
    hideErrors: function () {
      var self = this;
      var $el = $(this.el);
      var password = $el.find(".password-new");
      var passwordRetype = $el.find(".password-retype");
      password.removeClass("error");
      passwordRetype.removeClass("error");
      $el.find(".w-errorBox").fadeOut(animDuration);
    },
    performResetOnEnter: function (e) {
      var $el = $(this.el);
      var resetBtn;
      $(e.currentTarget).removeClass("error");
      if (e.which === 13) {
        e.preventDefault();
        resetBtn = $el.find(".reset-action");
        if (resetBtn.length) {
          this.performReset(resetBtn);
        }
      }
    },
    performReset: function (e) {
      var element = $(e.currentTarget);
      var self = this;
      var $el = $(self.el);
      var errorCode = 0;
      var data = {
        token: this.resetData.token,
        user_id: this.resetData.user_id,
        user: this.resetData.user,
        password: $.trim($el.find(".password-new").not(".placeholder").val()),
        passwordRetype: $.trim($el.find(".password-retype").not(".placeholder").val())
      };
      var resetBtn = $el.find(".reset-action");
      if (!data.password.length || !data.passwordRetype.length) {
        self.showErrors(errorCodes.ERROR_CODE_MISSING_FIELD);
        return;
      } else {
        if (data.password.length < self.model.minPasswordLength) {
          errorCode += errorCodes.ERROR_CODE_TOO_SHORT;
        }
        if (data.password !== data.passwordRetype) {
          errorCode += errorCodes.ERROR_CODE_INCORRECT;
        }
        if (errorCode > 0) {
          self.showErrors(errorCode);
          return;
        }
      }
      delete data.passwordRetype;
      if (self.model.set(data) !== false) {
        self.inServiceRequest = true;
        resetBtn.toggleClass("reset-action", false);
        Spinner.apply(resetBtn);
        self.model.sync("POST", data, {
          success: function () {
            self.changeViewContainer("success");
          },
          error: function () {
            this.inServiceRequest = false;
            self.showErrors(errorCodes.ERROR_CODE_API);
            resetBtn.addClass("reset-action").spin(false);
          },
          errorDisplaySuppressingConfig: {
            errorCodes: [400]
          }
        });
      }
    },
    returnToLogin: function (e) {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      T1.Location.set("index", {
        reload: true
      });
    },
    changeViewContainer: function (newViewContainerID) {
      var self = this;
      var $el = $(this.el);
      var resetBtn = $el.find(".reset-action");
      var newViewContainer = viewContainers[newViewContainerID];
      if (this.currentViewContainer !== newViewContainer) {
        self.hideErrors();
        $el.find(this.currentViewContainer).fadeOut(animDuration, function () {
          self.resetForm();
          resetBtn.toggleClass("reset-action", true);
          resetBtn.spin(false);
          self.currentViewContainer = newViewContainer;
          $el.find(self.currentViewContainer).fadeIn(animDuration);
          if (newViewContainerID === "reset") {
            $el.find(".password-new").focus();
          }
        });
      }
    },
    unload: function () {
      $(this.el).remove();
      this.destroy();
    },
    resetForm: function () {
      var $el = $(this.el);
      $el.find(".password").val("");
      $el.find(".password-retype").val("");
      this.inServiceRequest = false;
    },
    parseQuery: function (queryString) {
      var qs = queryString || document.location.search;
      return _.reduce(qs.replace("?", "").split("&"), function (obj, pair) {
        var i = pair.indexOf("=");
        var key = pair.slice(0, i);
        var val = pair.slice(++i);
        obj[key] = decodeURIComponent(val);
        return obj;
      }, {});
    }
  });
});
