define('modules/reporting/campaigns/reachFrequency/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "reachFrequency",
    name: "reporting/campaigns/reachFrequency",
    viewCfgs: {
      chart: {
        model: null
      },
      chartPanel: {
        model: null
      },
      chartTitle: {
        model: null
      },
      columnSelector: {
        model: null
      },
      controls: {
        model: null
      },
      grid: {
        model: null
      },
      panel: {
        model: null
      },
      reachFrequency: {}
    }
  });
});
