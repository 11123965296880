define('modules/reporting/campaigns/dataExport/createEdit/views/dimensionsMetrics',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/dimensionsMetrics.html", "text!../templates/selectors.html", "text!../templates/columnSelector.html"], function ($, _, T1, T1View, T1Layout, template, selectorsTemplate, columnSelectorTemplate) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const INPUT_DELAY = 500;
  const PASTE_DELAY = 100;
  const SEARCH_DELAY = 250;
  const WARNING_POS = 750;
  return T1View.extend({
    template: template,
    events: {
      "click .search-input": "clearInputHandler",
      "keyup .search-input": "searchInputHandler",
      "paste .search-input": "pasteInputHandler"
    },
    eventhubEvents: {
      "createEdit.reportInfoChange": "dataChangeHandler",
      "createEdit.attributionChange": "dataChangeHandler"
    },
    initialize: function (model) {
      this.DataExportModel = model;
      this.availableDimensions = [];
      this.availableMetrics = [];
      this.selectedDimensions = [];
      this.selectedMetrics = [];
      this.initLayout();
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.$(".column-selectors"),
        template: selectorsTemplate,
        layout: {
          ".dimensions-selector": [{
            module: "reporting/campaigns/dataExport/createEdit",
            viewType: "columnSelector",
            options: {
              columnAHeader: "Available Dimensions",
              columnBHeader: "Selected Dimensions",
              doNotdisplayDialog: true,
              listA: () => this.availableDimensions,
              listB: () => this.selectedDimensions,
              onUpdateCallback: selectedItems => this.updateSelectorItems("dimensions", selectedItems),
              showErrorState: true,
              template: columnSelectorTemplate,
              warningText: "You must select at least one dimension",
              warningType: "error"
            }
          }],
          ".metrics-selector": [{
            module: "reporting/campaigns/dataExport/createEdit",
            viewType: "columnSelector",
            options: {
              columnAHeader: "Available Metrics",
              columnBHeader: "Selected Metrics",
              doNotdisplayDialog: true,
              listA: () => this.availableMetrics,
              listB: () => this.selectedMetrics,
              onUpdateCallback: selectedItems => this.updateSelectorItems("metrics", selectedItems),
              template: columnSelectorTemplate,
              warningText: "If you specify no metrics, the report will contain all of them",
              warningType: "info"
            }
          }]
        }
      });
    },
    getInputValue: function () {
      return this.$input.attr("value");
    },
    load: function () {
      const $el = this.el;
      const repositionWarnings = this.repositionWarnings.bind(this);
      this.render().then(() => {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        this.$columnSelectors = $el.find(".column-selectors");
        this.$contextualWarning = $el.find(".contextual-warning");
        this.$hyperlocalWarning = $el.find(".hyperlocal-warning");
        this.$input = $el.find(".search-input");
        this.$referrerWarning = $el.find(".referrer-warning");
        this.$uniquesWarning = $el.find(".uniques-warning");
        this.$reachAndFrequencyWarning = $el.find(".reach-frequency-warning");
        T1Publish("load:metaData");
        $(window).on("scroll.warnings", repositionWarnings);
      });
    },
    unload: function () {
      this.$columnSelectors = null;
      this.$contextualWarning = null;
      this.$hyperlocalWarning = null;
      this.$input = null;
      this.$referrerWarning = null;
      this.$uniquesWarning = null;
      this.DimensionsSelector = null;
      this.MetricsSelector = null;
      this.$reachAndFrequencyWarning = null;
      $(window).off("scroll.warnings");
    },
    assignViews: function () {
      const views = this.layout.layout;
      this.DimensionsSelector = views[".dimensions-selector"][0].view;
      this.MetricsSelector = views[".metrics-selector"][0].view;
    },
    toggleWarning: function (options) {
      options.ele.addClass("no-display");
      if (options.showReportWarning) {
        options.ele.toggleClass("no-display", false);
      } else {
        if (_.contains(options.reports, options.selectedReport)) {
          const selectedItems = _.pluck(this.DataExportModel.get("reportInfo")[options.columns], "value");
          const checkColumnArr = options.checkColumns;
          let isColumnPresent = false;
          if (checkColumnArr && checkColumnArr.length) {
            isColumnPresent = _.some(checkColumnArr, function (item) {
              if (selectedItems.includes(item)) {
                return true;
              }
            });
            options.ele.toggleClass("no-display", !isColumnPresent);
          }
        }
      }
    },
    toggleAllWarnings: function () {
      const selectedReport = this.DataExportModel.getSelectedReport();
      this.toggleWarning({
        checkColumns: ["uniques"],
        columns: "selectedMetrics",
        ele: this.$uniquesWarning,
        reports: ["Data Pixel Loads", "Event Pixel Loads"],
        selectedReport: selectedReport
      });
      this.toggleWarning({
        checkColumns: ["referrer"],
        columns: "availableDimensions",
        ele: this.$referrerWarning,
        reports: ["Data Pixel Loads", "Event Pixel Loads"],
        selectedReport: selectedReport
      });
      this.toggleWarning({
        showReportWarning: selectedReport === "Hyperlocal",
        ele: this.$hyperlocalWarning
      });
      this.toggleWarning({
        showReportWarning: selectedReport === "Contextual Discovery" || selectedReport === "Contextual Insights",
        ele: this.$contextualWarning
      });
      this.toggleWarning({
        showReportWarning: selectedReport === "Reach and Frequency",
        ele: this.$reachAndFrequencyWarning
      });
    },
    repositionWarnings: _.debounce(function () {
      const $columnSelectors = this.$columnSelectors;
      if ($(window).scrollTop() > WARNING_POS) {
        this.$uniquesWarning.insertAfter($columnSelectors);
        this.$referrerWarning.insertAfter($columnSelectors);
        this.$hyperlocalWarning.insertAfter($columnSelectors);
        this.$contextualWarning.insertAfter($columnSelectors);
        this.$reachAndFrequencyWarning.insertAfter($columnSelectors);
      } else {
        this.$uniquesWarning.insertBefore($columnSelectors);
        this.$referrerWarning.insertBefore($columnSelectors);
        this.$hyperlocalWarning.insertBefore($columnSelectors);
        this.$contextualWarning.insertBefore($columnSelectors);
        this.$reachAndFrequencyWarning.insertBefore($columnSelectors);
      }
    }, INPUT_DELAY),
    dataChangeHandler: function () {
      const reportInfo = this.DataExportModel.get("reportInfo");
      const selectedReport = this.DataExportModel.getSelectedReport();
      this.availableDimensions = reportInfo.availableDimensions;
      this.availableMetrics = reportInfo.availableMetrics;
      this.selectedDimensions = reportInfo.selectedDimensions;
      this.selectedMetrics = reportInfo.selectedMetrics;
      if (this.$input) {
        this.$input.attr("value", "");
      }
      this.layout.load().then(() => {
        if (selectedReport === "Audience Verification") {
          const settleDelay = 1e3;
          setTimeout(() => {
            const $dimensionColumn = this.$columnSelectors.find('.dimensions-selector .connectedSortable[data-btn=".list-remove"]');
            const $metric1 = $dimensionColumn.find('.ui-state-default[data-item="age"]');
            const $metric2 = $dimensionColumn.find('.ui-state-default[data-item="gender"]');
            $metric1.addClass("deactivate");
            $metric2.addClass("deactivate");
          }, settleDelay);
        }
      });
      this.toggleAllWarnings();
    },
    searchInputHandler: _.debounce(function () {
      const searchValue = this.getInputValue();
      this.inSearchMode = searchValue !== "";
      if (!this.DimensionsSelector) {
        this.assignViews();
      }
      const {
        DimensionsSelector: DimensionsSelector,
        MetricsSelector: MetricsSelector
      } = this;
      if (this.inSearchMode) {
        DimensionsSelector.trigger("search:columns", searchValue);
        MetricsSelector.trigger("search:columns", searchValue);
      } else {
        DimensionsSelector.trigger("reset:search:columns", "");
        MetricsSelector.trigger("reset:search:columns", "");
      }
    }, SEARCH_DELAY),
    pasteInputHandler: function () {
      setTimeout(() => {
        const pasteValue = this.getInputValue();
        this.searchInputHandler(pasteValue);
      }, PASTE_DELAY);
    },
    clearInputHandler: function () {
      if (!this.getInputValue()) {
        this.searchInputHandler();
      }
    },
    updateSelectorItems: function (type, selectedItems) {
      if (this.inSearchMode) {
        this.searchInputHandler();
      }
      this.DataExportModel.updateSelectorItems(type, selectedItems, this.inSearchMode);
      this.toggleAllWarnings();
      T1Publish("change:formElements", true);
    }
  });
});
