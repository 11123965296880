define('models/currencyConverter',["jQuery", "Underscore", "T1", "T1Model"], function ($, _, T1, T1Model) {
  T1.Models.CurrencyConverter = T1Model.extend({
    url: "currency_rates",
    getRate: function (opts) {
      var convertCurrency = this.convertCurrency.bind(this);
      var def = $.Deferred();
      var resultData = [];
      this.fetch({
        params: {
          full: "*",
          page_limit: 1,
          q: "currency_code==" + opts.toCode,
          sort_by: "-date"
        },
        success: function (newModel, attributes) {
          if (attributes && attributes.entities && attributes.entities.entity) {
            if (attributes.entities.entity.length) {
              const amount = attributes.entities.entity[0].prop.find(currencyProp => currencyProp.name === "rate");
              resultData = convertCurrency(parseFloat(amount.value), opts.fromAmounts);
              convertCurrency = null;
              def.resolve(resultData);
            }
          }
        },
        postJson: true,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      });
      return def.promise();
    },
    convertCurrency: function (conversionRatio, fromAmounts) {
      var toAmounts = [];
      $.each(fromAmounts, function (key, fromAmount) {
        toAmounts.push({
          index: fromAmount.index,
          amount: fromAmount.amount * conversionRatio
        });
      });
      return toAmounts;
    }
  });
  return T1.Models.CurrencyConverter;
});
