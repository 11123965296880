define('modules/creatives/video/views/classification',["require", "jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1Form", "T1Permissions", "models/atomicCreative", "models/videoCreative", "collections/organizations", "utils/CreativeUtils", "text!../templates/classification.html"], function (require, $, _, T1, T1View, T1Layout, T1Form, T1Permissions, AtomicCreative, VideoCreative, Organizations, CreativeUtils, template) {
  "use strict";

  var ClassificationView = T1View.extend({
    template: template,
    events: {
      "click .preview-creative": "previewCreative",
      "click .back": "returnFromPreview"
    },
    actionsConfig: {
      events: {
        "click .cancel": "cancelOverride",
        "click .saveAndClose": "saveAndClose"
      }
    },
    initialize: function (args) {
      var self = this;
      var vc = {};
      var mode = args.mode;
      var data = args.model && args.model.toJSON();
      var details = data && data.details;
      var disabledRegex = /^disabled | disabled | disabled$/g;
      var resizeLayout = _.debounce(self.adjustGridHeight, 500);
      var previewAction;
      self.mode = mode;
      self.isEdit = args.id !== undefined || args.model.id !== undefined;
      self.orgID = args.orgID;
      vc = new VideoCreative({
        id: args.id
      });
      self.canLoad = false;
      $(window).on("resize.creativesClassification", resizeLayout);
      vc.sync("read", {
        id: args.id || args.model.id
      }, {
        success: function (modelData) {
          vc.set(modelData);
          details = modelData && modelData.details;
          self.model = vc;
          self.isUploaded = modelData.isUploaded;
          if (self.isUploaded) {
            self.isPreviewable = modelData && modelData.thumbnail;
          } else if (details.customVAST) {
            self.isPreviewable = details && details.customVAST;
          } else if (details.customVASTUrl) {
            self.isPreviewable = details && details.customVASTUrl;
          }
          self.setFormActions();
          previewAction = _.findWhere(self.actionsConfig.formActions, {
            uuid: "preview"
          });
          if (!self.isPreviewable) {
            if (!disabledRegex.test(previewAction.class)) {
              previewAction.class += " disabled";
            }
          } else {
            if (disabledRegex.test(previewAction.class)) {
              previewAction.class = previewAction.class.replace(disabledRegex, " ").trim();
            }
          }
          self.canLoad = true;
          self.load();
        },
        dataType: "json",
        processAjaxResponse: function (jData) {
          return {
            statusCode: "ok",
            jsonData: jData
          };
        }
      });
    },
    setFormActions: function () {
      var self = this;
      self.actionsConfig.formActions = [{
        label: "cancel",
        class: "cancel",
        uuid: "cancel",
        location: false
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: false
      }, {
        label: "preview",
        uuid: "preview",
        class: "btn-primary preview-creative",
        location: "#"
      }, {
        label: "save & close",
        uuid: "save_close",
        class: "saveAndClose btn-primary",
        location: false
      }];
    },
    load: function () {
      var self = this;
      var model = self.model;
      var $assignmentView, assignmentView, $el;
      if (!this.canLoad) {
        return;
      }
      this.render().then(function () {
        $el = $(self.el);
        $assignmentView = $el.find("#creativeAssignments");
        setTimeout(function () {
          $assignmentView.on("classification-add-remove", self.onClassificationAddRemove.bind(self));
          $(".preview-creative").addClass("disabled");
          assignmentView = $assignmentView[0];
          if (!assignmentView.updateBin) {
            $(window).on("WebComponentsReady", function () {
              assignmentView.updateBin(model.id);
            });
          } else {
            assignmentView.updateBin(model.id);
          }
          if (self.isEdit) {
            self.checkServeStatus();
          }
        }, 0);
      });
    },
    checkServeStatus: function () {
      if (this.model.get("readyToServe")) {
        $(".preview-creative").removeClass("disabled");
      }
    },
    postData: function (formValues, success, statusInvalid) {
      var self = this;
      var model = self.model;
      var $el = $(self.el);
      var $assignmentView = $el.find("#creativeAssignments");
      var assignmentView, errors;
      function successHandler() {
        T1.Notify("message", "Creative updated successfully!");
        success(model);
        self.delegateEvents();
      }
      function errorHandler(caller, promise, errorObj) {
        if (errorObj) {
          var errorResponse = errorObj.response.errors;
          errors = !errorResponse.field_error.length ? [errorResponse.field_error] : errorResponse.field_error;
          errors = errors.map(errors, function (error) {
            return {
              field: error.name,
              message: error.error
            };
          });
          self.showErrors(model, errors, ".details");
          statusInvalid(errors, null, ".form-body");
        }
      }
      assignmentView = $assignmentView[0];
      assignmentView.saveClassification().then(successHandler, errorHandler);
    },
    onClassificationAddRemove: function () {
      this.isFormElementsChanged = true;
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          setTimeout(function () {
            T1.Location.set("#creatives/videos");
          }, 1e3);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    adjustGridHeight: function () {
      var gridHeight = Math.round(window.innerHeight) - 350;
      $("#viewport", this.el).height(gridHeight);
    },
    cancelClassification: {
      onAlertClose: function () {
        T1.Location.set("#creatives/videos");
      }
    },
    cancelOverride: function (e, fromUnloadAlertDlg) {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      var modelName = self.model && self.model.get("name");
      var newName = "New Video Creative";
      var genericName = "Video Creative Edit";
      var nameObj = {
        name: this.isEdit ? modelName || genericName : newName
      };
      this.preventDefault(e);
      e.stopImmediatePropagation();
      if (fromUnloadAlertDlg === true || !isFormElementsChanged) {
        this.cancelClassification.onAlertClose();
      } else {
        this.openUnloadAlertDialog(nameObj, this.cancelClassification);
      }
    },
    canUnload: function (args) {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      var tagType = "Video Creative";
      var message = this.isEdit ? self.model && self.model.get("name") || "Edit " + tagType : "New " + tagType;
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: message
        }, args);
      }
      return !isFormElementsChanged;
    },
    unload: function () {
      $(window).off("resize.creativesClassification");
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgID;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    previewCreative: function (e) {
      var details = this.model.get("details");
      this.preventDefault(e);
      if (this.model.id && details) {
        CreativeUtils.showPreview([this.model.id], "video");
      }
    },
    returnFromPreview: function (e) {
      T1.Location.set("#creatives/video/" + this.model.id);
      this.preventDefault(e);
    },
    serialize: function () {
      var model = this.model;
      var output = model.toJSON();
      output.crid = output.id;
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(ClassificationView);
});
