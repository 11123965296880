define('modules/concept/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  return new T1Module({
    name: "concept",
    viewCfgs: {
      select: {},
      popup: {}
    },
    defaultView: "select"
  });
});
