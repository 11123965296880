define('modules/strategy/supply/shared/views/bins',["jQuery", "T1BinsView", "text!../templates/bin_title.html", "text!../templates/bin_group_header.html", "text!../templates/bin_group_item.html"], function ($, T1BinsView, bin_title, bin_group_header, bin_group_item) {
  "use strict";

  return T1BinsView.extend({
    configsOverride: true,
    configs: {
      include: {
        showAndOr: false,
        showWarning: true,
        showBinKindIcon: true,
        count: 0,
        use_or: false,
        hasUnpermissionedDeals: false,
        warningMessage: "You need to select at least one supply source."
      }
    },
    draggableConfigs: {
      isDraggable: false,
      isDroppable: false,
      allowMultipleSelection: true,
      handlerTextEl: ".segment",
      onDropEvent: "pmpe:dropInBins"
    },
    partials: {
      bin_title: bin_title,
      bin_group_name: bin_group_header,
      bin_group_item: bin_group_item
    },
    dataEvents: {
      model: {
        "change:run_on_all_pmp": "load",
        "change:run_on_all_exchanges": "load"
      }
    },
    eventhubEvents: {
      "sharedsupply:updateBins": "conditionalUpdateBins",
      "hideUnpermissionedDealsWarning:reloadBins": "hideUnpermissionedDealsWarning",
      "hasUnpermissionedDeals:updateBins": "registerUnpermissionedDeals"
    },
    initialize: function ({
      contentType: contentType,
      mapItems: mapItems,
      updateBins: updateBins,
      hasUnpermissionedDeals: hasUnpermissionedDeals
    }) {
      this.contentType = contentType;
      this.mapItems = mapItems;
      this.customUpdateBins = updateBins;
      this.configs.include.hasUnpermissionedDeals = hasUnpermissionedDeals;
    },
    removeItem: function (event) {
      const id = $(event.currentTarget).closest(".item").data("id");
      event.preventDefault();
      event.stopPropagation();
      return this.sharedCollection.remove(id) && true;
    },
    conditionalUpdateBins: function (matchingSharedCollection) {
      if (matchingSharedCollection === this.sharedCollection) {
        this.updateBins();
      }
    },
    updateBins: function () {
      if (this.customUpdateBins) {
        this.customUpdateBins();
      }
    },
    hideUnpermissionedDealsWarning: function () {
      this.options.hasUnpermissionedDeals = false;
      this.configs.include.hasUnpermissionedDeals = false;
      this.reload();
    },
    registerUnpermissionedDeals: function () {
      this.options.hasUnpermissionedDeals = true;
      this.configs.include.hasUnpermissionedDeals = true;
    },
    isVideoStrategy: function (context) {
      var model = context ? context.model : this.model;
      return model.get("media_type") === "VIDEO";
    },
    ensureCorrectWarning: function (allExchanges) {
      var config = this.configs.include;
      config.showWarning = !allExchanges;
    },
    generateBinData: function (updateAction) {
      var actionBinName = `${updateAction}-bin`;
      var binArray = [];
      const binConfig = this.configs[updateAction] || {};
      if (this.sharedCollection && this.sharedCollection.length) {
        binArray = this.sharedCollection.groupBy(model => model.get("action") === updateAction);
        binArray = binArray.true || [];
      }
      binArray = this.mapItems(binArray, updateAction);
      let returnObj = {
        bin_kind: actionBinName,
        bin_action: updateAction,
        binItems: binArray,
        bin_label: this.type
      };
      returnObj = $.extend(true, {}, binConfig, returnObj);
      if (binConfig.showWarning && binConfig.warningMessage) {
        const runOnAll = Number(this.model.get("run_on_all_exchanges")) || Number(this.model.get("run_on_all_pmp"));
        if (!this.sharedCollection.length && !runOnAll) {
          returnObj.showWarning = true;
        } else {
          delete returnObj.showWarning;
        }
      }
      if (this.options.hasUnpermissionedDeals) {
        returnObj.hasUnpermissionedDeals = true;
      } else {
        returnObj.hasUnpermissionedDeals = false;
      }
      if (!returnObj.binItems.length) {
        returnObj.empty = true;
      }
      return returnObj;
    },
    serialize: function () {
      var self = this;
      var bins = [];
      const {
        configs: binsConfigs,
        updateAction: updateAction
      } = this;
      var currentBinData = {};
      $.each(binsConfigs, function (key) {
        var generatedBin;
        if (!updateAction || updateAction === key) {
          generatedBin = self.generateBinData(key);
          bins.push(generatedBin);
        }
        if (updateAction === key) {
          currentBinData = generatedBin;
        }
      });
      const returnData = updateAction ? {
        binItems: currentBinData.binItems || [],
        showWarning: currentBinData.showWarning,
        warningMessage: currentBinData.warningMessage,
        tooltipText: currentBinData.tooltipText,
        hasUnpermissionedDeals: currentBinData.hasUnpermissionedDeals,
        empty: !currentBinData.binItems.length
      } : {
        type: this.type,
        bin_label: this.type,
        bins: bins
      };
      return returnData;
    }
  });
});
