define('modules/reporting/segments/dataExport/createEdit/views/selectSegments',["jQuery", "Underscore", "T1", "T1View", "text!../templates/select-segments.html"], function ($, _, T1, T1View, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {},
    eventhubEvents: {
      "createEdit.OverlapSelected": "showField",
      "createEdit.PerformanceSelected": "showField"
    },
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    load: function load() {
      var $el = this.el;
      this.render().then(function render() {
        $("#entities-dropdown").chosen({
          disable_search_threshold: 10
        });
        $el.css("visibility", "visible");
        $el.addClass("faded");
      });
    },
    showField: function () {
      var $el = this.el;
      $el.removeClass("faded");
    },
    unload: function unload() {},
    serialize: function serialize() {
      return {};
    }
  });
});
