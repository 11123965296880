define('T1Module',["jQuery", "Underscore", "Backbone", "T1", "T1View", "models/userPreferences", "T1Signature", "T1Preferences"], function ($, _, Backbone, T1, T1View, UserPreferences) {
  var T1Module;
  var extend = Backbone.View.extend;
  T1Module = function (...args) {
    const [cfgs] = args;
    if (!cfgs || !cfgs.name) {
      return Error;
    }
    this.views = {
      default: T1View
    };
    $.extend(this, cfgs);
    this.initialize(args);
  };
  const prepareViewClass = function (t1, userPreferences) {
    var store = {};
    return function prepareViewClass(viewClass, viewType, modulename) {
      const key = `${viewType}::${modulename}`;
      var _init;
      if (store[key] !== true) {
        store[key] = true;
        _init = viewClass.prototype.initialize;
        viewClass.prototype.initialize = function (...args) {
          var signature = t1.Signature.create();
          signature.set(new Map([["root", "ui"], ["location", t1.Location.get().slice(1) || "index"], ["module", modulename], ["view", viewType]]));
          this.preferences = t1.Preferences.create.call(this, signature, userPreferences);
          _init.apply(this, args);
        };
      }
    };
  }(T1, UserPreferences);
  function getView(type, opts) {
    var deferredView = $.Deferred();
    var self = this;
    var configs = this.viewCfgs;
    var viewType = configs[type] ? type : this.defaultView;
    var config = typeof configs[viewType] === "function" ? configs[viewType]() : configs[viewType];
    var viewClass = this.views[viewType];
    var tCfgs = $.extend({}, config);
    if (opts && typeof opts === "object") {
      tCfgs = $.extend(tCfgs, opts);
    }
    if (viewClass) {
      deferredView.resolve(new viewClass(tCfgs));
    } else {
      require([`modules/${this.name}/views/${viewType}`], function (moduleView) {
        prepareViewClass(moduleView, viewType, self.name);
        self.views[type] = moduleView;
        deferredView.resolve(new moduleView(tCfgs));
        if (!self.ignoreResizeWidth) {
          T1.resizeWidth();
        }
      });
    }
    return deferredView.promise();
  }
  $.extend(T1Module.prototype, {
    initialize: $.noop,
    getView: getView,
    defaultView: "default"
  });
  T1Module.extend = extend;
  return T1Module;
});
