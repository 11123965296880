define('models/t1AppPermission',["jQuery", "Underscore", "Backbone", "T1", "T1Model"], function ($, _, Backbone, T1, T1Model) {
  "use strict";

  T1.Models.T1AppPermission = T1Model.extend({
    apiRoot: COMPASS_ENV.API_BASE,
    page_limit: "",
    url: "organization_partner_types?with=partner_types&full=*&q=partner_types_id==16"
  });
  return T1.Models.T1AppPermission;
});
