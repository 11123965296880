define('modules/campaign/views/chart_date_picker',['require','jQuery','moment','modules/reporting/campaigns/utils/reportingUtils','T1','T1View','text!../templates/chart_date_picker.html','T1DatePicker'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const moment = require("moment");
  const ReportUtils = require("modules/reporting/campaigns/utils/reportingUtils");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/chart_date_picker.html");
  const T1DatePicker = require("T1DatePicker");
  const DAY_MULTIPLIER = 1e3 * 60 * 60 * 24;
  return T1View.extend({
    template: template,
    events: {
      "mouseout .dates span": "removeHoverState",
      "mouseover .dates span": "addHoverState"
    },
    initialize({
      isWaterMark: isWaterMark,
      reportChanged: reportChanged,
      getReportModel: getReportModel
    }) {
      Object.assign(this, {
        displayFormat: getReportModel().getDisplayFormat(),
        isWaterMark: isWaterMark,
        reportChanged: reportChanged,
        getReportModel: getReportModel
      });
    },
    load() {
      const restrictedRange = 30;
      const {
        defaultStartDate: defaultStartDate,
        defaultEndDate: defaultEndDate
      } = ReportUtils;
      const {
        start = defaultStartDate,
        end = defaultEndDate
      } = this.getReportModel().getStartEndDate();
      this.startDate = start;
      this.endDate = end;
      if (this.reportChanged()) {
        if (this.isWaterMark()) {
          this.endMinDate = moment().subtract(restrictedRange, "days").toDate();
        } else {
          this.endMinDate = null;
        }
      } else {
        if (this.isWaterMark()) {
          this.endMinDate = moment().subtract(restrictedRange, "days").toDate();
          if (this.startDate.getTime() < this.endMinDate.getTime()) {
            this.startDate = defaultStartDate;
          }
        } else {
          this.endMinDate = null;
        }
      }
      this.render();
      this.attachDatePicker();
    },
    attachDatePicker() {
      T1.DoubleDatePicker({
        endMinDate: () => this.startDate,
        getEndDate: () => this.endDate,
        getStartDate: () => this.startDate,
        lowerBounds: this.endMinDate,
        positionBy: "bottomTop",
        topOffset: 19,
        upperBounds: Date.parse("today"),
        onSave: ({
          data: data,
          success: success
        }) => {
          const {
            start: start,
            end: end
          } = data.saveValues;
          const startDate = Date.parse(start.date);
          const endDate = Date.parse(end.date);
          const reportModel = this.getReportModel();
          if (window.pendo) {
            window.pendo.track("Campaigns - MM Chart - Date Range Selection", {
              startDate: start.date.split("T")[0],
              endDate: end.date.split("T")[0],
              days: Math.floor((endDate - startDate) / DAY_MULTIPLIER) + 1,
              report: reportModel.get("key")
            });
          }
          reportModel.setStartEndDate({
            start: startDate,
            end: endDate
          }, false);
          this.$(".start-date").text(moment(startDate).format(this.displayFormat));
          this.$(".end-date").text(moment(endDate).format(this.displayFormat));
          success();
          return true;
        }
      }, this.el);
    },
    addHoverState(event) {
      $(event.currentTarget).parent().addClass("onhover");
    },
    removeHoverState(event) {
      $(event.currentTarget).parent().removeClass("onhover");
    },
    serialize() {
      return {
        start: moment(this.startDate).format(this.displayFormat),
        end: moment(this.endDate).format(this.displayFormat)
      };
    }
  });
});
