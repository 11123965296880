define('modules/adServer/main',["jQuery", "T1", "T1Module", "collections/adServers"], function ($, T1, T1Module, AdServers) {
  return new T1Module({
    name: "adServer",
    viewCfgs: {
      select: {
        collection: new AdServers()
      }
    },
    defaultView: "select"
  });
});
