define('modules/audiences/segments/create/views/createEdit',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "T1Comm", "T1Permissions", "T1Spinner", "modules/audiences/segments/segmentUtils", "text!modules/audiences/segments/create/templates/createEdit.html", "text!modules/audiences/segments/create/templates/leftParen.html", "text!modules/audiences/segments/create/templates/rightParen.html", "text!modules/audiences/segments/create/templates/operator.html", "text!modules/audiences/segments/create/templates/operand.html", "text!modules/audiences/segments/create/templates/expressionGrid.html", "text!modules/audiences/segments/create/templates/saveAsStandardSegment.html", "models/segment", "collections/segments", "models/advertiser", "models/strategy", "models/campaign", "collections/organizations", "models/behavior", "models/eventAttributes", "../views/advertisers", "../../behavior/views/behavior", "../../behavior/views/addBehaviorV2", "utils/CampaignStrategyUtils", "text!modules/audiences/segments/create/templates/unsavedChanges.html", "models/sessionUser", "jQueryUI"], function ($, _, Backbone, Hogan, T1, T1Comm, T1Permissions, Spinner, Util, template, leftParenTemplate, rightParenTemplate, operatorTemplate, operandTemplate, expressionGrid, SaveAsTemplate, Segment, segments, Advertiser, Strategy, Campaign, Organizations, BehaviorModel, Attributes, Advertisers, EditBehaviorView, AddBehaviorViewV2, Utils, unsavedChangesTemplate, User) {
  "use strict";

  const DIFFERENT_AGENCIES_MSG = "Behaviors in a segment cannot belong to advertisers from multiple agencies";
  var SEGMENTS_URL = "audiences/segments/";
  var createEditView = Backbone.View.extend({
    template: template,
    leftParenTemplate: leftParenTemplate,
    rightParenTemplate: rightParenTemplate,
    operatorTemplate: operatorTemplate,
    operandTemplate: operandTemplate,
    unsavedChangesTemplate: unsavedChangesTemplate,
    isDmpEnabled: false,
    ADVERTISER_TYPE: "advertiser",
    OPERATOR_OPTIONS: ["and", "and not", "or", "or not"],
    events: {
      "click .close-control": "closeBehavior",
      "click #add-behavior-button": "addBehaviorButtonClick",
      "click .edit-control": "editBehavior",
      "click #edit-icon": "editBehavior",
      "click #refresh-button": "refreshClicked",
      "click .refresh-overlay": "refreshClicked",
      "click .refresh-overlay-with-tooltip": "refreshClicked",
      "changed #segment-name-wc": "nameChanged",
      "changed #advertisers": "advertiserChanged",
      "changed #advertisers strand-input": "searchAdvertisers",
      "click #save-segment-button": "saveSegment",
      "click #save-and-add-segment-button": "saveSegment",
      "click #cancel-segment-button": "cancelSegment",
      "click #open-save-as": "openSaveAsDialog",
      "click #save-as-cancel": "closeSaveAsDialog",
      "click #save-as": "saveAsStandardSegment",
      "changed #save-as-name": "updateSaveAsModal",
      "click #segment-expression-drawer #toggle": "drawerRefresh"
    },
    initialize: function (options) {
      var self = this;
      var viewOptions = T1.Location.getViewOptions();
      this.id = options.id === "create" ? "new" : options.id;
      this.mode = options.id === "create" ? "create" : "edit";
      this.changed = false;
      this.queryNum = -1;
      this.user = User.getUser();
      this.segmentType = options.section || "adaptive";
      this.titleText = "New " + T1.Utils.capitalize(this.segmentType) + " Segment";
      this.readOnly = User.getUser().get("edit_segments") === "0";
      if (viewOptions) {
        if (viewOptions.new) {
          this.newlySaved = true;
        }
        if (viewOptions.savedAs) {
          this.savedAs = true;
        }
        if (viewOptions.duplicated) {
          this.duplicated = true;
        }
        if (viewOptions.saveAndAdd) {
          this.owner_id = viewOptions.advertiser_id;
          this.newlySaved = true;
        }
      }
      T1.EventHub.subscribe("organization:select", $.proxy(function () {
        var saveDisabled = "";
        if (self.doNotProcessOrgChange) {
          self.doNotProcessOrgChange = false;
          return;
        }
        if (!this.changed) {
          T1.Location.set(SEGMENTS_URL);
          return;
        }
        if ($("#save-segment-button,#open-save-as")[0].hasAttribute("disabled")) {
          saveDisabled = "disabled";
        }
        var unsavedChangesDialog = $(Hogan.compile(this.unsavedChangesTemplate).render({
          saveDisabled: saveDisabled
        }));
        unsavedChangesDialog.dialog({
          modal: true,
          width: 600,
          autoOpen: true,
          draggable: false,
          dialogClass: "unsaved-segment-changes-dialog"
        });
        $(".ui-dialog-titlebar", $(".unsaved-segment-changes-dialog")).hide();
        $("#unsaved-changes-cancel-button").on("click", function (e) {
          var orgs = Organizations.getOrganizations();
          e.preventDefault();
          unsavedChangesDialog.remove();
          orgs.getSelected().then(function (id) {
            var existingOrg = self.org;
            if (existingOrg && existingOrg.toString() !== id.toString()) {
              self.doNotProcessOrgChange = true;
              orgs.setSelected(existingOrg, {
                implicitChange: true
              });
              $(".org-name").html(self.orgName);
            }
          });
        });
        $("#unsaved-changes-continue-button").on("click", function () {
          unsavedChangesDialog.remove();
          T1.Location.set(SEGMENTS_URL);
        });
        $("#unsaved-changes-save-button").on("click", function (e) {
          e.preventDefault();
          self.saveSegment(e, SEGMENTS_URL);
          unsavedChangesDialog.remove();
        });
      }, this));
      T1.EventHub.subscribe({
        "versionConflictDialog:close": function () {
          T1.Location.reload();
        }
      });
      Organizations.getOrganizations().fetchSelectedOrg(function (orgModel) {
        self.org = orgModel.id;
        self.orgName = $(".org-name").html();
        self.crossAgencyEnabled = orgModel.get("allow_x_agency_pixels") === "1";
        if (orgModel.get("dmp_enabled") === "enabled") {
          self.isDmpEnabled = true;
        }
        if (self.getPagePermissions()) {
          $(".segment-message").hide();
          $("#segment-name-wc").removeAttr("disabled");
        } else {
          $(".segment-message").show();
          $("#segment-name-wc").attr("disabled", true);
          $("#advertiser-button-container").hide();
        }
      });
      self.removeProgressBar = function () {
        $("#sizing-progress-wrapper", this.el).hide();
      };
      self.queryState = {
        _current: "start",
        queryStart: function () {
          this._current = "start";
          this.updateUi();
        },
        queryProcessing: function () {
          this._current = "processing";
        },
        isProcessing: function () {
          return this._current === "processing";
        },
        queryComplete: function () {
          this._current = "complete";
          this.updateUi();
        },
        needsRefresh: function () {
          if (this._current !== "start") {
            this._current = "needsRefresh";
          }
          this.updateUi();
        },
        queryError: function () {
          this._current = "error";
          this.updateUi();
        },
        updateUi: function () {
          $("#sizing-success").hide();
          $("#sizing-warning").hide();
          $("#sizing-error").hide();
          if (this._current === "complete") {
            $("#sizing-success").show();
          }
          if (this._current === "needsRefresh") {
            $("#sizing-warning").show();
          }
          if (this._current === "error") {
            $("#sizing-error").show();
            self.removeProgressBar();
          }
        }
      };
    },
    getPagePermissions: function () {
      var pixels = T1Permissions.check("feature", "pixels");
      var dataMapping = this.user.get("view_data_definition") === "1";
      var viewSegments = this.user.get("view_segments") === "1";
      var basicPermissions = pixels && dataMapping && viewSegments;
      return basicPermissions;
    },
    load: function () {
      var self = this;
      var attributes = new Attributes({
        source: "top",
        advertiserId: this.advertiserId
      });
      var loadPromises = [];
      this.model = new Segment({
        id: this.id
      });
      if (this.mode === "edit") {
        var advertiserStorage = {};
        var editFetch = function () {
          var loading = $.Deferred();
          self.model.fetch({
            dataType: "XML"
          }).pipe(function () {
            self.advertiserId = self.model.get("owner_id");
            if (!self.advertiserStorage || !self.advertiserStorage[self.model.get("owner_id")]) {
              const advertiser = new Advertiser({
                id: self.model.get("owner_id")
              });
              return advertiser.fetch({
                data: {
                  with: "agency"
                },
                success: function (response) {
                  advertiserStorage[response.get("id")] = {
                    id: response.get("id"),
                    value: response.get("id"),
                    name: response.get("name"),
                    agency: response.get("agency").get("name"),
                    agencyId: response.get("agency").id,
                    crossAdv: response.get("agency").get("allow_x_adv_pixels")
                  };
                }
              });
            }
          }).pipe(function () {
            var behaviors = self.model.get("behaviors");
            var strategyStorage = {};
            var campaignStorage = {};
            var arrayOfPromises = [];
            var type = self.model.get("segment_type");
            if (type !== self.segmentType) {
              T1.Location.set(SEGMENTS_URL + type + "/" + self.model.id);
              return;
            }
            if (!Array.isArray(behaviors)) {
              behaviors = [];
              behaviors.push(self.model.get("behaviors"));
            }
            behaviors.map(function (behavior) {
              self.model.addBehavior(behavior, "load");
            });
            self.model.behaviors.map(function (behavior) {
              var entity_type = behavior.get("entity_type");
              var deferred = $.Deferred();
              var completionsNeeded = behavior.get("attr_id") !== 1 && entity_type !== "agency" ? 2 : 1;
              var resolver = function () {
                completionsNeeded--;
                if (completionsNeeded === 0) {
                  deferred.resolve();
                }
              };
              var getAdvertiser = function () {
                advertiserStorage[behavior.get("advertiserId")] = {};
                var advertiser = new Advertiser({
                  id: behavior.get("advertiserId")
                });
                advertiser.fetch({
                  data: {
                    with: "agency"
                  },
                  success: function (response) {
                    advertiserStorage[response.get("id")] = {
                      id: response.get("id"),
                      value: response.get("id"),
                      name: response.get("name"),
                      agency: response.get("agency").get("name"),
                      agencyId: response.get("agency").id,
                      crossAdv: response.get("agency").get("allow_x_adv_pixels")
                    };
                    resolver();
                  }
                });
              };
              var getStrategy = function () {
                strategyStorage[behavior.get("targetId")] = {};
                var strategy = new Strategy({
                  id: behavior.get("targetId")
                });
                strategy.fetch({
                  data: {
                    with: "campaign"
                  },
                  success: function (response) {
                    strategyStorage[response.get("id")] = {
                      strategy: response.get("name"),
                      campaign: response.get("campaign"),
                      strategyId: response.get("id")
                    };
                    resolver();
                  }
                });
              };
              var getCampaign = function () {
                campaignStorage[behavior.get("targetId")] = {};
                var campaign = new Campaign({
                  id: behavior.get("targetId")
                });
                campaign.fetch({
                  success: function (response) {
                    campaignStorage[response.get("id")] = {
                      campaign: response.get("name"),
                      campaignId: response.get("id")
                    };
                    resolver();
                  }
                });
              };
              if (advertiserStorage[behavior.get("advertiserId")]) {
                resolver();
              } else {
                getAdvertiser();
              }
              if (behavior.get("attr_id") !== 1 && entity_type !== "agency") {
                if (strategyStorage[behavior.get("targetId")]) {
                  resolver();
                } else {
                  if (behavior.get("attr_id") === 10) {
                    getCampaign();
                  } else {
                    getStrategy();
                  }
                }
              }
              arrayOfPromises.push(deferred.promise());
            });
            $.when.apply($, arrayOfPromises).then(function () {
              self.model.behaviors.map(function (behavior) {
                behavior.set({
                  gridName: behavior.get("name")
                });
                var titleInfo = [advertiserStorage[behavior.get("advertiserId")].agency, advertiserStorage[behavior.get("advertiserId")].name];
                if (behavior.get("attr_id") === 1) {
                  behavior.set({
                    parentName: advertiserStorage[behavior.get("advertiserId")].name
                  });
                  titleInfo.push(behavior.get("name") + " (" + behavior.get("targetId") + ")");
                } else if (behavior.get("attr_id") === 10) {
                  behavior.set({
                    parentName: campaignStorage[behavior.get("targetId")].campaign
                  });
                  behavior.set({
                    gridName: `${behavior.get("parentName")} - ${behavior.get("name")}`
                  });
                  titleInfo.push(campaignStorage[behavior.get("targetId")].campaign + " (" + behavior.get("targetId") + ")");
                } else if (behavior.get("entity_type") === "agency") {
                  behavior.set({
                    parentName: advertiserStorage[behavior.get("advertiserId")].agency
                  });
                  titleInfo = [advertiserStorage[behavior.get("advertiserId")].agency];
                  titleInfo.push(behavior.get("name") + " (" + behavior.get("targetId") + ")");
                } else {
                  var strategyStore = strategyStorage[behavior.get("targetId")];
                  behavior.set({
                    parentName: strategyStorage[behavior.get("targetId")].strategy
                  });
                  behavior.set({
                    campaignId: strategyStorage[behavior.get("targetId")].campaign.id
                  });
                  try {
                    titleInfo.push(strategyStore.campaign.get("name") + " (" + behavior.get("campaignId") + ")");
                  } catch (e) {
                    titleInfo.push(strategyStore.campaign.name + " (" + behavior.get("campaignId") + ")");
                  }
                  titleInfo.push(strategyStore.strategy + " (" + strategyStore.strategyId + ")");
                }
                behavior.set({
                  titleInfo: titleInfo
                });
              });
              self.advertiserStorage = advertiserStorage;
              self.render();
              self.renderExpression();
              loading.resolve();
            });
          });
          loadPromises.push(loading.promise());
        };
        editFetch();
        $.when.apply($, loadPromises).then(function () {
          self.model.behaviors.map(function (behavior) {
            if (!behavior.get("filter_by").length) {
              return;
            }
            var idArray = behavior.get("readable_filter").split(", ").map(function (id) {
              var attributeObj = $.grep(self.model.get("behavior_attributes"), function (e) {
                return e.id === id;
              })[0];
              return attributeObj ? attributeObj.name : id;
            });
            behavior.set({
              readable_filter: idArray.join(", ")
            });
            self.updateExpressionGrid();
          });
        });
      } else {
        this.model.resetBehaviors();
        this.render();
        this.model.set({
          segment_type: this.segmentType
        });
      }
      this.model.set({
        owner_type: this.ADVERTISER_TYPE
      });
    },
    render: function () {
      var self = this;
      var $el = this.el;
      var isEditMode = this.mode === "edit";
      var titleText = isEditMode ? this.model.get("name") : this.titleText;
      var displayDropDown = isEditMode ? "display:none" : null;
      var isStandard = this.segmentType === "standard";
      var standardSegmentEdit = isStandard && isEditMode;
      var addBehaviorContainer = '<div class="add-behavior-container-v2"></div>';
      this.compiledTemplate = Hogan.compile(this.template);
      this.compiledLeftParenTemplate = Hogan.compile(this.leftParenTemplate);
      this.compiledRightParenTemplate = Hogan.compile(this.rightParenTemplate);
      var renderedTemplate = this.compiledTemplate.render({
        title: titleText,
        displayDropDown: displayDropDown,
        namePlaceholder: T1.Utils.capitalize(this.segmentType) + " Segment Name",
        expanded: "{{expanded}}",
        model: "{{model}}",
        scope: "{{scope}}",
        id: "{{model.id}}",
        name: "{{model.attributes.name}}",
        gridName: "{{model.attributes.gridName}}",
        noFreq: "{{!model.attributes.frequency.op}}",
        frequencyOp: "{{model.attributes.frequency.op}}",
        frequencyMin: "{{model.attributes.frequency.min}}",
        frequencyMax: "{{model.attributes.frequency.max}}",
        noLimitMax: "{{!model.attributes.frequency.max}}",
        noRec: "{{!model.attributes.recency.op}}",
        recencyOp: "{{model.attributes.recency.op}}",
        recencyCount: "{{model.attributes.recency.count}}",
        recencyUnit: "{{model.attributes.recency.unit}}",
        recencyDate: "{{model.attributes.recency.date}}",
        filterBy: "{{model.attributes.readable_filter}}",
        emptyFilter: "{{!model.attributes.readable_filter}}",
        startCount: "{{model.attributes.recency.start_count}}",
        endCount: "{{model.attributes.recency.end_count}}",
        startUnit: "{{model.attributes.recency.start_unit}}",
        endUnit: "{{model.attributes.recency.end_unit}}",
        startDate: "{{model.attributes.recency.start_date}}",
        endDate: "{{model.attributes.recency.end_date}}",
        isAdaptive: !isStandard,
        standardSegmentEdit: standardSegmentEdit,
        readOnly: this.readOnly
      }, {
        expressionGrid: Hogan.compile(expressionGrid)
      });
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = renderedTemplate;
      } else {
        this.el.html(renderedTemplate);
      }
      this.saveDialog = $("#segment-save-dialog", $el).dialog({
        modal: true,
        width: 440,
        height: 200,
        autoOpen: false,
        draggable: false,
        dialogClass: "segment-save-dialog"
      });
      try {
        $("#segment-save-dialog-spinner")[0].start();
      } catch (e) {
        window.addEventListener("polymer-ready", function () {
          $("#segment-save-dialog-spinner")[0].start();
        });
      }
      if (isEditMode) {
        requestAnimationFrame(() => {
          self.el.find("#advertisers").val(self.advertiserId);
          self.showAdvertiserText(self.advertiserStorage[self.advertiserId].name);
          self.changed = false;
          self.loadedUp = true;
          self.showFooterMsg();
        });
      } else {
        this.advertisersObj = new Advertisers({
          parent: this,
          allAdv: true
        });
        this.advertisersObj.render();
        this.advertisersObj.loadAdvertisers(this.owner_id);
        this.changed = false;
        this.loadedUp = true;
      }
      setTimeout(function () {
        self.hideFooterMsg();
        var currentUrl = window.location.href;
        var url = currentUrl.substring(0, currentUrl.indexOf("?"));
        window.history.pushState("", "", url);
      }, 5e3);
      T1.resizeWidth($el);
      $("#left-paren-placeholder").hide();
      $("#right-paren-placeholder").hide();
      this.makeSortable();
      $(".spinner").hide();
      if (!standardSegmentEdit) {
        $("#segment-name-wc", this.el)[0].value = self.model.get("name");
      }
      this.setNameInput();
      this.showSize();
      this.updateExpressionGrid();
      this.updateBreadCrumb();
      this.updateAddBehavior();
      this.updateParens();
      this.queryState.queryStart();
      this.showFooterMsg();
      if (standardSegmentEdit) {
        this.el.find("segments-inline-edit")[0].onsave = this.inlineEditSave.bind(this);
      }
      $(".nav-list, .knowledge-base, .logout, .active").unbind().click(function (event) {
        self.warnForUnsavedChanges(event, event.target.hash || event.target.parentNode.hash);
      });
      requestAnimationFrame(() => {
        if (!$(".add-behavior-container-v2")[0]) {
          this.el.after(addBehaviorContainer);
        }
      });
    },
    loadAdvertisers: function () {
      this.advertiserId = null;
      this.advertisersObj.loadAdvertisers();
    },
    setNameInput: function () {
      if (this.mode === "edit") {
        $("#segment-name-wc", this.el)[0].value = this.model.get("name");
      }
    },
    showSize: function () {
      var segmentSize = parseInt(this.model.get("size"), 10);
      if (this.mode === "edit") {
        if (isNaN(segmentSize)) {
          $(".segment-size").html(this.model.get("size"));
        } else {
          $(".segment-size").html(Number(this.model.get("size")).toLocaleString("en"));
        }
      }
    },
    searchAdvertisers: function (event) {
      var value = event.srcElement.value;
      if (value) {
        this.advertisersObj.searchAdvertisers(value, event);
      } else {
        this.advertisersObj.loadAdvertisers(event);
      }
    },
    updateBreadCrumb: function () {
      var pathPieces;
      if (this.mode === "edit") {
        pathPieces = [{
          label: this.model.get("name"),
          location: "#audiences/segments" + this.id
        }];
      } else {
        pathPieces = [{
          label: this.titleText,
          location: "#audiences/segments"
        }];
      }
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
    },
    advertiserChanged: function () {
      this.updateAddBehavior();
      this.setAdvertiserId();
      this.updateSaveButton();
      this.updateUnsavedChanges();
    },
    nameChanged: function () {
      this.setName();
      this.setMeritEvent();
      this.updateSaveButton();
      this.hideFooterMsg();
      this.showFooterMsg();
      this.validateName();
      if (this.segmentType === "adaptive" || this.mode === "create") {
        this.updateUnsavedChanges();
      }
    },
    behaviorsChanged: function () {
      this.updateAdvertiserDropdown();
      this.updateSaveButton();
      this.updateRefresh();
      this.updateParens();
      this.hideFooterMsg();
      this.updateExpressionGrid();
      this.refreshQueryText();
      this.updateUnsavedChanges();
      this.updateSizingProgress();
      this.showFooterMsg();
      this.validateName();
    },
    expressionChanged: function () {
      this.updateSaveButton();
      this.updateRefresh();
      this.hideFooterMsg();
      this.updateExpressionGrid();
      this.refreshQueryText();
      this.updateUnsavedChanges();
      this.updateSizingProgress();
      this.showFooterMsg();
    },
    updateSizingProgress: function (result) {
      if (result) {
        this.queryProgress(result);
      } else {
        this.removeProgressBar();
      }
    },
    refreshClicked: function (event) {
      if (this.queryState.isProcessing()) {
        return;
      }
      this.queryState.queryProcessing();
      this.querySteelrain(event);
    },
    updateSaveAsButton: function () {
      if (this.segmentType === "standard" && this.mode === "edit" && !this.readOnly) {
        if (this.getQueryText()) {
          this.enableSaveAsButton();
        } else {
          this.disableSaveAsButton();
        }
      }
    },
    updateSaveAsModal: function () {
      var $button = this.el.find("#save-as");
      if (this.validateName({
        saveAs: true
      })) {
        $button.removeAttr("disabled");
      } else {
        $button.attr("disabled", "");
      }
    },
    getSegmentElements: function (additionalSelectorString) {
      var selectorString = "#segment-creation-items li";
      if (typeof additionalSelectorString !== "undefined") {
        selectorString += additionalSelectorString;
      }
      var results = $(selectorString).not("#add-behavior-button, .false, #paren-tool, .ui-sortable-helper, .operator-options");
      return results;
    },
    shortenPixelName: function (name) {
      if (name.length > 25) {
        return name.substring(0, 25) + "...";
      } else {
        return name;
      }
    },
    commafy: function (number) {
      while (/(\d+)(\d{3})/.test(number.toString())) {
        number = number.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return number;
    },
    validateName: function (opts) {
      opts = opts || {};
      var id = opts.saveAs ? "#save-as-name" : "#segment-name-wc";
      var segmentName = $(id, this.el);
      var segmentNameLength = segmentName.val().trim().length;
      if (segmentNameLength === 256) {
        segmentName[0].error = true;
        this.showFooterMsg("Name length should be 255 characters or less");
      } else {
        segmentName[0].error = false;
      }
      return segmentNameLength > 0 && segmentNameLength < 256;
    },
    enableSaveButtons: function () {
      $("#save-segment-button").removeAttr("disabled");
      $("#save-and-add-segment-button").removeAttr("disabled");
    },
    disableSaveButtons: function () {
      $("#save-segment-button").attr("disabled", "");
      $("#save-and-add-segment-button").attr("disabled", "");
    },
    enableAddBehavior: function () {
      $("#add-behavior-button").removeClass("disabled");
    },
    disableAddBehavior: function () {
      $("#add-behavior-button").addClass("disabled");
    },
    showAdvertiserText: function (advertiserName) {
      var dropDownEl = $("#advertisers")[0];
      var selectedItem = $("#advertisers > #target > span > #label").text();
      var advertiserText;
      if (advertiserName) {
        advertiserText = getDisplayName(this, advertiserName);
      } else if (selectedItem) {
        advertiserText = getDisplayName(this, selectedItem);
      }
      $(dropDownEl).hide();
      $(".selected-advertiser").text(advertiserText);
      $(".selected-advertiser").show();
      function getDisplayName(ctx, createModeText) {
        return ctx.mode === "create" ? createModeText : ctx.advertiserStorage[ctx.advertiserId].name;
      }
    },
    getParentName: function (type) {
      var id;
      if (this.mode === "create") {
        return _.find(this.advertisersObj.advertiserDrop.data, function (adv) {
          return adv.value === this.model.get("owner_id");
        }.bind(this))[type];
      }
      if (this.mode === "edit") {
        id = this.model.get("owner_id") || this.advertiserId;
        return this.advertiserStorage[id][type];
      }
    },
    showDropDown: function () {
      $(".selected-advertiser").hide();
      $("#advertisers").show();
    },
    hideFooterMsg: function () {
      var footer = $("#create-edit-footer")[0];
      if (footer && this.loadedUp && footer.type === "success") {
        footer.hideMessage();
      }
    },
    showFooterMsg: function (msg) {
      var footer = $("#create-edit-footer")[0];
      if (msg) {
        footer.type = "error";
        footer.message = msg;
        footer.showMessage();
      } else if (this.mode === "edit" || this.mode === "create") {
        if (this.newlySaved || this.duplicated || this.savedAs) {
          footer.type = "success";
          if (this.newlySaved) {
            footer.message = "Save Successful";
          }
          if (this.duplicated) {
            footer.message = "Duplication Successful";
          }
          if (this.savedAs) {
            footer.message = "Save As Successful";
          }
          footer.showMessage();
          this.duplicated = false;
          this.newlySaved = false;
          this.savedAs = false;
        } else if (this.changed && this.loadedUp) {
          footer.type = "warning";
          footer.message = "There are unsaved changes on the page.";
          footer.showMessage();
        }
      }
    },
    updateSaveButton: function () {
      if (!this.readOnly && this.el.find("#advertisers").val() && this.validateName() && this.getQueryText(false)) {
        if (this.segmentType === "standard" && this.mode === "create") {
          this.enableSaveButtons();
        } else {
          this.disableSaveButtons();
        }
        if (this.segmentType === "adaptive") {
          this.enableSaveButtons();
        }
      } else {
        this.disableSaveButtons();
      }
    },
    enableSaveAsButton: function () {
      var $openSaveAs = this.el.find("#open-save-as");
      if ($openSaveAs) {
        $openSaveAs.removeAttr("disabled");
        this.el.find("segments-inline-edit")[0].disabled = true;
      }
    },
    disableSaveAsButton: function () {
      var $openSaveAs = this.el.find("#open-save-as");
      if ($openSaveAs) {
        $openSaveAs.attr("disabled", "");
        this.el.find("segments-inline-edit")[0].disabled = true;
      }
    },
    closeSaveAsDialog: function () {
      this.saveAsDialog.remove();
    },
    saveAsStandardSegment: function () {
      var $el = this.el;
      var newName = $el.find("#save-as-name")[0].value;
      var newSegment = this.model.clone();
      var buttonTemplate = this.buttonTemplate;
      var $button = $el.find("#save-as");
      var width = $button.width();
      var footer;
      newSegment.set({
        id: "new",
        name: newName,
        merit_event: true
      });
      newSegment.behaviors = this.model.behaviors;
      buttonTemplate.spin = true;
      $button.width(width);
      $button[0].disabled = true;
      newSegment.save().pipe(function () {
        T1.Location.set(SEGMENTS_URL + "standard/" + newSegment.id, {
          viewOptions: {
            savedAs: true
          }
        });
      }, function (error) {
        buttonTemplate.spin = false;
        $button[0].disabled = false;
        footer = $el.find("#save-as-footer")[0];
        footer.type = "error";
        footer.message = error.responseText.includes("different agencies") ? DIFFERENT_AGENCIES_MSG : "Could not save as";
        footer.showMessage();
      });
    },
    drawerRefresh: function () {
      const behaviorGrid = this.el[0].querySelector("#segment-expression-grid");
      T1.resizeWidth(this.el);
      if (behaviorGrid && !behaviorGrid.data) {
        this.updateExpressionGrid();
      }
    },
    updateRefresh: function () {
      if (this.model.behaviors.length === 0) {
        this.queryState.queryStart();
      } else {
        this.queryState.needsRefresh();
      }
      if (this.getSegmentElements(".segment-operand").length === 0) {
        $("#refresh-button").addClass("disabled");
      } else {
        $("#refresh-button").removeClass("disabled");
      }
    },
    updateAdvertiserDropdown: function () {
      if (this.model.behaviors.length === 0 && this.mode !== "edit") {
        this.showDropDown();
        $("#advertisers").prop("disabled", false).trigger("liszt:updated");
      } else {
        this.showAdvertiserText();
        $("#advertisers").prop("disabled", true).trigger("liszt:updated");
      }
    },
    updateAddBehavior: function () {
      var advertiserId = $("#advertisers").val();
      var noResultsRegex = new RegExp("^No results match ");
      if (advertiserId !== "" && typeof advertiserId !== "object" && !noResultsRegex.test(advertiserId)) {
        this.enableAddBehavior();
      } else if (this.mode === "edit") {
        this.enableAddBehavior();
      } else {
        this.disableAddBehavior();
      }
    },
    updateParens: function () {
      if (this.model.behaviors.length <= 1) {
        $("#paren-tools-container").addClass("disabled");
      } else {
        $("#paren-tools-container").removeClass("disabled");
      }
    },
    updateExpressionGrid: function () {
      const behaviorGrid = this.el[0].querySelector("#segment-expression-grid");
      if (behaviorGrid) {
        behaviorGrid.set("data", this.model.behaviors.models.slice());
      }
    },
    refreshQueryText: function () {
      var expression = this.getQueryText(false);
      var expressionEnd = expression.length > 400 ? "..." : "";
      $("#expression-text").text(expression.substring(0, 400) + expressionEnd);
      $("#expression-text").attr("title", expression);
      this.model.set({
        expression: this.getQueryText(true)
      });
    },
    getQueryText: function (useId) {
      var segmentElements = this.getSegmentElements();
      var expressionArray = [];
      for (var i = 0; i < segmentElements.length; i++) {
        if ($(segmentElements.get(i)).hasClass("left-paren")) {
          expressionArray.push("(");
        } else if ($(segmentElements.get(i)).hasClass("right-paren")) {
          expressionArray.push(")");
        } else if ($(segmentElements.get(i)).hasClass("segment-operator")) {
          var operatorText = $(".operator-text", $(segmentElements.get(i))).text();
          if (!useId) {
            operatorText = operatorText.toUpperCase();
          }
          expressionArray.push(operatorText);
        } else if ($(segmentElements.get(i)).hasClass("segment-operand")) {
          if (useId) {
            expressionArray.push(segmentElements.get(i).id);
          } else {
            expressionArray.push($(segmentElements.get(i)).find("small").text());
          }
        }
        expressionArray.push();
      }
      return expressionArray.join(" ");
    },
    updateUnsavedChanges: function () {
      this.changed = true;
    },
    setAdvertiserId: function () {
      var noResultsRegex = new RegExp("^No results match ");
      if ($("#advertisers").val() !== "" && !noResultsRegex.test($("#advertisers").val())) {
        this.model.set({
          owner_id: $("#advertisers").val()
        });
      }
    },
    setName: function () {
      this.model.set({
        name: $("#segment-name-wc", this.el)[0].value
      });
    },
    setMeritEvent: function () {
      this.model.set({
        merit_event: "true"
      });
    },
    queryProgress: function (result) {
      $("#sizing-progress-wrapper", this.el).show();
      var progressBar = $("#sizing-progress", this.el)[0];
      progressBar.percentComplete = parseInt(result.complete * 100, 10);
    },
    querySteelrain: function (event) {
      event.preventDefault();
      if ($("#refresh-button").hasClass("disabled")) {
        return;
      }
      T1Comm.detectSession();
      var v = T1.ENV;
      var self = this;
      var query = this.model.getSteelrainJson().expression;
      var queryCreation = $.ajax({
        url: v.SEGMENT_API_BASE + "sizing/",
        contentType: "application/json",
        data: JSON.stringify({
          expression: query
        }),
        type: "POST",
        error: function (jqXHR, textStatus, errorThrown) {
          self.failedSteelrainQuery("Error getting translation: " + textStatus + "\n" + errorThrown);
        }
      });
      self.updateSizingProgress({
        complete: "0"
      });
      var pollQuery = queryCreation.pipe(function (result) {
        self.queryNum = JSON.parse(result).query_id;
        return $.ajax({
          url: v.SEGMENT_API_BASE + "sizing/" + self.queryNum,
          type: "GET",
          dataType: "json",
          error: function (jqXHR, textStatus, errorThrown) {
            self.failedSteelrainQuery("Error getting translation: " + textStatus + "\n" + errorThrown);
          }
        });
      });
      pollQuery.then(function (result) {
        if ($.type(result) === "array" && result.length !== 0 || $.type(result.error) === "array" && result.error.length !== 0 || result.num_realtime_comm_errors !== 0) {
          $(".segment-size").html("Error");
          self.failedSteelrainQuery();
        } else {
          if (result.result === 0) {
            $(".segment-size").html("N/A");
          } else {
            $(".segment-size").html(self.commafy(Math.floor(result.result)));
          }
          self.updateQuery();
        }
      });
    },
    updateQuery: function () {
      var v = T1.ENV;
      var self = this;
      var options = {
        url: v.SEGMENT_API_BASE + "sizing/" + self.queryNum,
        type: "GET",
        dataType: "json",
        error: function (jqXHR, textStatus, errorThrown) {
          self.failedSteelrainQuery("Error getting query update: " + textStatus + "\n" + errorThrown);
        },
        success: function (result) {
          self.updateSizingProgress(result);
          if (result.result === 0) {
            $(".segment-size").html("N/A");
          } else {
            $(".segment-size").html(self.commafy(Math.floor(result.result)));
          }
          if (result.complete === 1) {
            self.queryNum = -1;
            self.queryState.queryComplete();
            return;
          }
          setTimeout(function () {
            self.updateQuery();
          }, 1e3);
        }
      };
      $.ajax(options);
    },
    failedSteelrainQuery: function () {
      $(".segment-size").html("Error");
      this.queryState.queryError();
    },
    saveSegment: function (event, forwardingLocation) {
      var self = this;
      var el = event.target;
      var $label = $(el).find("label");
      var buttonText = $label.text();
      var buttonId = $(el).parent()[0].id;
      var initializedForwardingLocation = forwardingLocation || SEGMENTS_URL;
      var redirectURL = SEGMENTS_URL + this.segmentType + "/";
      var footer, viewOptions;
      T1Comm.detectSession();
      Util.buttonLoader(event);
      $("#save-segment-button").attr("disabled", "");
      $("#save-and-add-segment-button").attr("disabled", "");
      $("#segment-name-wc").attr("disabled", "");
      $(".segment-operator").addClass("disabled");
      $("#add-behavior-button").addClass("disabled");
      $("#segment-creation-items, #advanced-tools-items").sortable("disable");
      footer = $("#create-edit-footer")[0];
      this.model.save().pipe(function () {
        footer.type = "success";
        footer.message = "Save Successful";
        this.changed = false;
        self.loadedUp = false;
        Util.buttonReset(event.target, buttonText);
        $("#segment-name-wc").removeAttr("disabled");
        $(".segment-operator").removeClass("disabled");
        $("#add-behavior-button").removeClass("disabled");
        $("#segment-creation-items, #advanced-tools-items").sortable("enable");
        if (buttonId === "save-and-add-segment-button") {
          self.owner_id = self.model.get("owner_id");
          if (self.mode === "create") {
            self.changed = false;
            self.newlySaved = true;
            self.load();
            self.loadedUp = true;
            self.addBehaviorView.unload();
            self.addBehaviorView = null;
            self.showFooterMsg();
          } else {
            viewOptions = {
              viewOptions: {
                saveAndAdd: true,
                advertiser_id: self.owner_id
              }
            };
            T1.Location.set(redirectURL + "create/", viewOptions);
          }
        } else if (buttonId === "save-segment-button") {
          if (self.mode === "create") {
            viewOptions = {
              viewOptions: {
                new: true
              }
            };
            T1.Location.set(redirectURL + self.model.get("id"), viewOptions);
          } else {
            self.changed = false;
            self.load();
            viewOptions = {
              viewOptions: {
                new: false
              }
            };
            T1.Location.set(redirectURL + self.model.get("id"), viewOptions);
          }
        } else if (buttonId === "unsaved-changes-save-button") {
          T1.Location.set(initializedForwardingLocation);
          T1.Notify("message", "Segment " + (self.mode !== "create" ? "updated" : "created") + " successfully!");
        }
        $(".segment-operand").each(function (index, operand) {
          if (operand.id.indexOf("new") !== -1) {
            operand.id = self.model.getNewIdFromOldId(operand.id);
          }
        });
        self.updateBreadCrumb();
        footer.showMessage();
      }, function (errors) {
        if (Array.isArray(errors) && errors[0].status === 409) {
          T1.VersionConflictDialog.open();
        }
        footer.type = "error";
        footer.message = self.getErrorMessage(errors);
        footer.showMessage();
        Util.buttonReset(event.target, buttonText);
        $("#segment-name-wc").removeAttr("disabled");
        $(".segment-operator").removeClass("disabled");
        $("#add-behavior-button").removeClass("disabled");
        $("#segment-creation-items, #advanced-tools-items").sortable("enable");
      });
    },
    getErrorMessage: function (errors) {
      const readOnlyMessage = "Save failed. User does not have edit segment permission." + "Please contact your administrator if you believe these permissions are incorrect.";
      const genericMessage = "Save Failed.";
      const crossAdvMessage = "Cross advertiser is not enabled.";
      const responseText = errors.responseText ? errors.responseText.toLowerCase() : "";
      if (this.readOnly) {
        return readOnlyMessage;
      }
      if (responseText.includes("cross") && responseText.includes("advertiser")) {
        return crossAdvMessage;
      }
      if (responseText.includes("different agencies")) {
        return DIFFERENT_AGENCIES_MSG;
      }
      return genericMessage;
    },
    cancelSegment: function (e) {
      this.warnForUnsavedChanges(e, SEGMENTS_URL);
    },
    warnForUnsavedChanges: function (event, path) {
      var trueSelf = this;
      var saveDisabled = "";
      var unsavedChangesDialog;
      var $saveButton = this.el.find("#save-segment-button,#open-save-as")[0];
      if (!this.changed) {
        T1.Location.set(path);
        return;
      }
      if ($saveButton && $saveButton.hasAttribute("disabled")) {
        saveDisabled = "disabled";
      }
      unsavedChangesDialog = $(Hogan.compile(this.unsavedChangesTemplate).render({
        saveDisabled: saveDisabled,
        isStandardSegmentEdit: this.segmentType === "standard" && this.mode === "edit"
      }));
      event.stopPropagation();
      event.preventDefault();
      unsavedChangesDialog.dialog({
        modal: true,
        width: 600,
        autoOpen: true,
        draggable: false,
        dialogClass: "unsaved-segment-changes-dialog"
      });
      $(self.el).append(unsavedChangesDialog);
      $(".ui-dialog-titlebar", $(".unsaved-segment-changes-dialog")).hide();
      $("#unsaved-changes-cancel-button").on("click", function (e) {
        e.preventDefault();
        unsavedChangesDialog.remove();
      });
      $("#unsaved-changes-continue-button").on("click", function (e) {
        unsavedChangesDialog.remove();
        if (path) {
          e.preventDefault();
          if (path.search(/^https:/) !== -1) {
            window.location.assign(path);
          } else {
            T1.Location.set(path);
          }
        } else {
          T1.Location.reload();
        }
      });
      $("#unsaved-changes-saveas-button").on("click", function (e) {
        e.preventDefault();
        unsavedChangesDialog.remove();
        trueSelf.openSaveAsDialog();
      });
      $("#unsaved-changes-save-button").on("click", function (e) {
        e.preventDefault();
        trueSelf.saveSegment(e, path);
        unsavedChangesDialog.remove();
      });
    },
    inlineEditSave: function () {
      var def = $.Deferred();
      var self = this;
      var model = this.model;
      model.save().pipe(function () {
        self.updateBreadCrumb();
        self.el.find("#segment-title").text(model.get("name"));
        self.model.set({
          id: self.model.get("id").toString()
        });
        def.resolve();
      }, function () {
        def.reject("Could not change name");
      });
      return def.promise();
    },
    closeBehavior: function (event) {
      event.preventDefault();
      var parentTarget = $(event.target).parents(".segment-element").eq(0);
      if (parentTarget.hasClass("segment-operand")) {
        var prevElem = parentTarget.prev().eq(0);
        if (prevElem.hasClass("segment-operator")) {
          prevElem.detach();
        } else if (parentTarget.next().hasClass("segment-operator")) {
          parentTarget.next().detach();
        }
      } else if (parentTarget.hasClass("left-paren") || parentTarget.hasClass("right-paren")) {
        this.findMatchingParen(parentTarget).detach();
      } else {
        var msg = "ERROR - UNKNOWN ELEMENT TYPE CLOSED";
        throw Error(msg, event.target, parentTarget);
      }
      while (parentTarget.prev().prev().hasClass("left-paren") && parentTarget.next().hasClass("right-paren")) {
        parentTarget.prev().prev().detach();
        parentTarget.next().detach();
      }
      while (parentTarget.prev().hasClass("left-paren") && parentTarget.next().next().hasClass("right-paren")) {
        parentTarget.prev().detach();
        parentTarget.next().next().detach();
      }
      parentTarget.detach();
      this.model.removeBehavior(parentTarget[0].id);
      this.behaviorsChanged();
      this.updateSaveAsButton();
    },
    editBehavior: function (evt) {
      evt.preventDefault();
      var modelId;
      if ($(evt.target).parent().attr("id") === "edit-icon") {
        modelId = $(evt.target).parent().parent().attr("id");
      } else {
        modelId = $(evt.target).parents(".segment-operand").attr("id");
      }
      var model = this.model.getBehavior(modelId);
      var name = model.get("name");
      new EditBehaviorView({
        segment: this,
        applyFunction: this.applyBehaviorEdit,
        behaviorObj: model,
        name: name,
        segmentType: this.segmentType
      });
    },
    applyBehaviorEdit: function (model) {
      var expression = this.model.get("expression");
      this.behaviorsChanged();
      this.updateSaveAsButton();
      var tempId = model.get("id");
      this.model.removeBehavior(tempId);
      if (tempId.indexOf("new") === -1) {
        model.set({
          id: this.model.getPlaceholderId()
        });
        var find = new RegExp(tempId + "\\b");
        this.model.set({
          expression: expression.replace(find, model.id)
        });
        this.model.addBehavior(model);
        this.renderExpression();
      } else {
        this.model.addBehavior(model);
      }
    },
    addAttributesToSegment: function (response) {
      this.model.set({
        behavior_attributes: response
      });
    },
    addBehaviorButtonClick: function () {
      var advertiser;
      if (this.mode === "create") {
        advertiser = this.advertisersObj.advertiserDrop.data.find(advertiser => advertiser.value === this.el.find("#advertisers").val());
        advertiser.id = advertiser.value;
      } else {
        let advertiserId = this.model.get("owner_id") || this.advertiserId;
        advertiser = this.advertiserStorage[advertiserId];
      }
      if (!$("#add-behavior-button").hasClass("disabled")) {
        T1Comm.detectSession();
        if (this.addBehaviorView) {
          this.addBehaviorView.segment = this;
          this.addBehaviorView.advertiser = advertiser;
          this.addBehaviorView.load();
        } else {
          this.addBehaviorView = new AddBehaviorViewV2({
            el: $(".add-behavior-container-v2"),
            segment: this,
            addFunction: this.addBehavior,
            advertiser: advertiser,
            segmentType: this.segmentType,
            crossAgencyEnabled: this.crossAgencyEnabled,
            orgId: this.org
          });
        }
      }
    },
    openSaveAsDialog: function () {
      var container;
      this.saveAsDialog = new Strand.Modal();
      container = this.saveAsDialog.$.container;
      this.saveAsDialog.dismiss = false;
      this.saveAsDialog.noscroll = true;
      this.saveAsDialog.className = "save-as-wrapper";
      container.innerHTML = SaveAsTemplate;
      container.style.width = "58%";
      this.el.append(this.saveAsDialog);
      this.buttonTemplate = this.el.find("#save-template")[0];
      this.buttonTemplate.spin = false;
      this.saveAsDialog.show();
    },
    renderExpression: function () {
      var self = this;
      var arrayOfRenderedDomNodes = [];
      var expression = this.model.get("expression");
      this.getSegmentElements().detach();
      expression = expression.replace(/or not/g, "or_not").replace(/and not/g, "and_not");
      $.each(expression.split(" "), function (index, expressionElement) {
        expressionElement = expressionElement.replace(/or\_not/g, "or not").replace(/and\_not/g, "and not");
        if (expressionElement === "(") {
          arrayOfRenderedDomNodes.push(self.compiledLeftParenTemplate.render({}));
        } else if (expressionElement === ")") {
          arrayOfRenderedDomNodes.push(self.compiledRightParenTemplate.render({}));
        } else if ($.inArray(expressionElement, self.OPERATOR_OPTIONS) !== -1) {
          var operatorDom = Hogan.compile(self.operatorTemplate).render({
            selectedOperator: expressionElement,
            operatorOptions: self.OPERATOR_OPTIONS,
            isAdaptive: self.segmentType === "adaptive"
          });
          operatorDom = $(operatorDom);
          var dropdownMenu = $(".segment-operator-dropdown", operatorDom);
          $("li", dropdownMenu).hover(function () {
            $(this).addClass("operator-hover");
          }, function () {
            $(this).removeClass("operator-hover");
          });
          dropdownMenu.hide();
          var segmentOperator = $(operatorDom).filter(".segment-operator");
          self.toggleOperatorDropdown(segmentOperator, dropdownMenu, operatorDom);
          arrayOfRenderedDomNodes.push(operatorDom);
        } else {
          arrayOfRenderedDomNodes.push(self.renderOperand(expressionElement));
        }
      });
      $.each(arrayOfRenderedDomNodes, function (index, element) {
        $("#add-behavior-button").before(element);
      });
      self.expressionChanged();
    },
    renderOperand: function (operandId) {
      var self = this;
      var behaviorModel = this.model.getBehavior(operandId);
      var titleInfo;
      try {
        titleInfo = behaviorModel.get("titleInfo").join(" > ");
      } catch (e) {
        try {
          titleInfo = self.shortenPixelName(behaviorModel.get("name"));
        } catch (e) {
          Util.showErrorModal(e, self.response);
        }
      } finally {
        var operandTemplateVariables = {
          id: behaviorModel.get("id"),
          name: behaviorModel.get("name"),
          parentName: behaviorModel.get("parentName"),
          displayName: self.shortenPixelName(behaviorModel.get("name")),
          title: titleInfo
        };
        return Hogan.compile(self.operandTemplate).render(operandTemplateVariables);
      }
    },
    addBehavior: function (pixelNames) {
      var expression = this.model.get("expression") || "";
      var behaviorModel;
      var ownerType;
      if (pixelNames.length === 0) {
        return;
      }
      if (this.model.getBehaviorCount() !== 0) {
        expression += " or ";
      }
      for (var i = 0; i < pixelNames.length; i++) {
        if (typeof pixelNames[i] === "undefined") {
          continue;
        }
        ownerType = pixelNames[i].category === "dataPixels" ? "agency" : "advertiser";
        behaviorModel = new BehaviorModel({
          parentId: parseInt(pixelNames[i].parentId),
          parentName: pixelNames[i].parentName,
          campaignId: parseInt(pixelNames[i].campaignId),
          type: pixelNames[i].type,
          category: pixelNames[i].category,
          owner_type: ownerType,
          id: this.model.getPlaceholderId(),
          name: pixelNames[i].name,
          targetId: pixelNames[i].targetId,
          titleInfo: pixelNames[i].titleInfo,
          gridName: pixelNames[i].gridName
        });
        if (pixelNames[i].category === "dataPixels") {
          behaviorModel.set({
            agencyId: pixelNames[i].agencyId,
            owner_id: parseInt(pixelNames[i].agencyId)
          });
        } else {
          behaviorModel.set({
            advertiserId: pixelNames[i].advertiserId,
            owner_id: parseInt(pixelNames[i].advertiserId)
          });
        }
        expression += behaviorModel.id;
        if (pixelNames.length > 0 && i !== pixelNames.length - 1) {
          expression += " or ";
        }
        this.model.addBehavior(behaviorModel);
      }
      this.model.set({
        expression: expression
      });
      this.renderExpression();
      this.behaviorsChanged();
      this.updateSaveAsButton();
    },
    toggleOperatorDropdown: function (operator, dropdown, block) {
      var self = this;
      operator.on("click", function (evt) {
        if (!$(this).hasClass("disabled")) {
          evt.stopPropagation();
          dropdown.toggle();
          $(".arrow-down", operator).toggleClass("switch");
          $(".segment-operator-dropdown").not(dropdown).hide();
          $(".arrow-down", $(".segment-operator").not(operator)).removeClass("switch");
          self.refreshQueryText();
        }
      });
      $(document).on("click", function () {
        if (!dropdown.is(":hidden")) {
          dropdown.hide();
          $(".arrow-down", operator).removeClass("switch");
        }
      });
      $("li", dropdown).on("click", function () {
        var currentOpperator = $(".operator-text", block);
        var newOpperatorText = $(this).text();
        if (currentOpperator.text() !== newOpperatorText) {
          currentOpperator.text(newOpperatorText);
          self.expressionChanged();
        }
      });
    },
    makeSortable: function () {
      var self = this;
      $("#segment-creation-items, #advanced-tools-items").sortable({
        connectWith: "#segment-creation-items",
        items: ".segment-element:not(#add-behavior-button,.segment-operator),.paren-tool",
        tolerance: "intersect",
        placeholder: "false",
        helper: "clone",
        cancel: "#paren-tools-container.disabled .paren-tool",
        start: function (event, ui) {
          var nextElem = $(ui.item).nextAll(":not(.false)").eq(0);
          ui.item.nextSegmentElement = nextElem.length !== 0 ? nextElem : undefined;
          var prevElem = $(ui.item).prevAll(":not(.false)").eq(0);
          ui.item.prevSegmentElement = prevElem.length !== 0 ? prevElem : undefined;
          ui.item.show().css("opacity", .2);
          ui.item.customPlaceholder = $("<li class='segment-placeholder segment-element'></li>").width(ui.item.width());
          ui.item.customPlaceholderClone = ui.item.customPlaceholder.clone();
        },
        stop: function (event, ui) {
          self.resetOpacity();
          if (ui.item.hasClass("segment-operand") && ui.item.closestOperand === undefined || (ui.item.hasClass("right-paren") || ui.item.hasClass("left-paren") || ui.item.hasClass("paren-tool")) && ui.item.closestValidElement === undefined) {
            $(this).sortable("cancel");
          } else {
            self.expressionChanged();
          }
          ui.item.nextSegmentElement = undefined;
          ui.item.prevSegmentElement = undefined;
          ui.item.closestValidElement = undefined;
          ui.item.closestOperand = undefined;
          ui.item.css("opacity", "");
          self.refreshQueryText();
        },
        sort: function (event, ui) {
          if (ui.item.hasClass("segment-operand")) {
            self.getSegmentElements().addClass("shadowed");
            ui.item.removeClass("shadowed");
            var segmentOperands = self.getSegmentElements(".segment-operand");
            var closestOperand = self.getClosestOperand(ui.helper, ui.item, segmentOperands, "center");
            if (closestOperand !== undefined) {
              closestOperand.removeClass("shadowed");
            }
            ui.item.closestOperand = closestOperand;
          } else if (ui.item.hasClass("left-paren")) {
            var validLeftParenElements = self.findValidLeftParenElements(ui.item);
            var leftParen;
            for (var i = 0; i < 2; i++) {
              ui.item.closestValidElement = self.getClosestOperand(ui.helper, ui.item, validLeftParenElements, "left");
              if (!(ui.item.closestValidElement === undefined || ui.item.closestValidElement.prevAll(".segment-element").eq(0).is(ui.item))) {
                ui.item.closestValidElement.before(ui.item.customPlaceholder);
                leftParen = ui.item.customPlaceholder;
              } else {
                ui.item.customPlaceholder.detach();
                ui.item.closestValidElement = undefined;
                leftParen = ui.item;
              }
            }
            var rightParen = self.findMatchingParen(leftParen, ui.item, "left");
            self.resetOpacity(ui.item);
            self.highlightInnerElements(leftParen, rightParen, ui.item);
          } else if (ui.item.hasClass("right-paren")) {
            var validRightParenElements = self.findValidRightParenElements(ui.item);
            var rightParen;
            for (var i = 0; i < 2; i++) {
              ui.item.closestValidElement = self.getClosestOperand(ui.helper, ui.item, validRightParenElements, "right");
              if (!(ui.item.closestValidElement === undefined || ui.item.closestValidElement.nextAll(".segment-element").eq(1).is(ui.item))) {
                ui.item.closestValidElement.after(ui.item.customPlaceholder);
                rightParen = ui.item.customPlaceholder;
              } else {
                ui.item.customPlaceholder.detach();
                ui.item.closestValidElement = undefined;
                rightParen = ui.item;
              }
            }
            var leftParen = self.findMatchingParen(rightParen, ui.item, "right");
            self.resetOpacity(ui.item);
            self.highlightInnerElements(leftParen, rightParen, ui.item);
          } else if (ui.item.attr("id") === "paren-left-tool") {
            var segmentElems = self.getSegmentElements().not(".segment-operand:last");
            var validPositions = [];
            for (var i = 0; i < segmentElems.length - 1; i++) {
              var currentElem = segmentElems.eq(i);
              var nextElem = segmentElems.eq(i + 1);
              if ((currentElem.hasClass("segment-operand") || currentElem.hasClass("left-paren")) && !nextElem.hasClass("right-paren")) {
                validPositions.push(currentElem[0]);
              }
            }
            var validLeftParenElements = $(validPositions).add(ui.item);
            for (var i = 0; i < 2; i++) {
              ui.item.closestValidElement = self.getClosestOperand(ui.helper, ui.item, validLeftParenElements, "left");
              if (ui.item.closestValidElement === undefined) {
                ui.item.customPlaceholder.detach();
                ui.item.customPlaceholderClone.detach();
              } else if (!ui.item.closestValidElement.prev(".segment-element").is(ui.item.customPlaceholder)) {
                ui.item.closestValidElement.before(ui.item.customPlaceholder);
                if (ui.item.closestValidElement.hasClass("left-paren")) {
                  var endSubExpressionElement = self.findMatchingParen(ui.item.closestValidElement);
                } else {
                  var nextOperator = ui.item.customPlaceholder.nextAll(".segment-operator").first();
                  var endSubExpressionElement = nextOperator.nextAll(".segment-element").first();
                  if (endSubExpressionElement.hasClass("left-paren")) {
                    endSubExpressionElement = self.findMatchingParen($(endSubExpressionElement));
                  }
                }
                endSubExpressionElement.after(ui.item.customPlaceholderClone);
              }
            }
            self.resetOpacity(ui.item);
            self.highlightInnerElements(ui.item.customPlaceholder, ui.item.customPlaceholderClone, ui.item);
          } else if (ui.item.attr("id") === "paren-right-tool") {
            var segmentElems = self.getSegmentElements().not(".segment-operand:first");
            var validPositions = [];
            for (var i = 1; i < segmentElems.length; i++) {
              var currentElem = segmentElems.eq(i);
              var prevElem = segmentElems.eq(i - 1);
              if ((currentElem.hasClass("segment-operand") || currentElem.hasClass("right-paren")) && !prevElem.hasClass("left-paren")) {
                validPositions.push(currentElem[0]);
              }
            }
            var validRightParenElements = $(validPositions).add(ui.item);
            for (var i = 0; i < 2; i++) {
              ui.item.closestValidElement = self.getClosestOperand(ui.helper, ui.item, validRightParenElements, "right");
              if (ui.item.closestValidElement === undefined) {
                ui.item.customPlaceholder.detach();
                ui.item.customPlaceholderClone.detach();
              } else if (!ui.item.closestValidElement.next(".segment-element").is(ui.item.customPlaceholder)) {
                ui.item.closestValidElement.after(ui.item.customPlaceholder);
                if (ui.item.closestValidElement.hasClass("right-paren")) {
                  var startSubExpressionElement = self.findMatchingParen(ui.item.closestValidElement);
                } else {
                  var prevOperator = ui.item.customPlaceholder.prevAll(".segment-operator").first();
                  var startSubExpressionElement = prevOperator.prevAll(".segment-element").first();
                  if (startSubExpressionElement.hasClass("right-paren")) {
                    startSubExpressionElement = self.findMatchingParen($(startSubExpressionElement));
                  }
                }
                startSubExpressionElement.before(ui.item.customPlaceholderClone);
              }
            }
            self.resetOpacity(ui.item);
            self.highlightInnerElements(ui.item.customPlaceholderClone, ui.item.customPlaceholder, ui.item);
          }
        },
        beforeStop: function (event, ui) {
          if (ui.item.hasClass("segment-operand")) {
            if (typeof ui.item.closestOperand !== "undefined") {
              $(ui.item.closestOperand).before(ui.item);
              $(ui.item.closestOperand).detach();
              if (typeof ui.item.prevSegmentElement !== "undefined") {
                ui.item.closestOperand.insertAfter(ui.item.prevSegmentElement);
              } else if (typeof ui.item.nextSegmentElement !== "undefined") {
                ui.item.closestOperand.insertBefore(ui.item.nextSegmentElement);
              }
            }
          } else if (ui.item.closestValidElement !== undefined) {
            if (ui.item.hasClass("left-paren")) {
              $(ui.item.customPlaceholder).replaceWith(self.compiledLeftParenTemplate.render({}));
              ui.item.remove();
            } else if (ui.item.hasClass("right-paren")) {
              $(ui.item.customPlaceholder).replaceWith(self.compiledRightParenTemplate.render({}));
              ui.item.remove();
            } else if (ui.item.hasClass("paren-tool")) {
              if (ui.item.attr("id") === "paren-left-tool") {
                $(ui.item.customPlaceholder).replaceWith(self.compiledLeftParenTemplate.render({}));
                $(ui.item.customPlaceholderClone).replaceWith(self.compiledRightParenTemplate.render({}));
              } else {
                $(ui.item.customPlaceholder).replaceWith(self.compiledRightParenTemplate.render({}));
                $(ui.item.customPlaceholderClone).replaceWith(self.compiledLeftParenTemplate.render({}));
              }
            }
          }
          self.getSegmentElements().removeClass("shadowed");
        },
        receive: function (event, ui) {
          if (ui.item.hasClass("paren-tool")) {
            ui.sender.sortable("cancel");
            self.expressionChanged();
          }
        }
      }).disableSelection();
    },
    findValidLeftParenElements: function () {
      var segmentElems = this.getSegmentElements();
      var validPositions = [];
      for (var i = 0; i < segmentElems.length - 1; i++) {
        var currentElem = segmentElems.eq(i);
        var nextElem = segmentElems.eq(i + 1);
        if (nextElem.hasClass("right-paren")) {
          break;
        }
        if (currentElem.hasClass("segment-operand") || currentElem.hasClass("left-paren")) {
          validPositions.push(currentElem[0]);
        }
      }
      return $(validPositions);
    },
    findValidRightParenElements: function (item) {
      var segmentElems = this.getSegmentElements();
      var validPositions = [];
      for (var i = segmentElems.length - 1; i > 0; i--) {
        var currentElem = segmentElems.eq(i);
        var prevElem = segmentElems.eq(i - 1);
        if (prevElem.hasClass("left-paren")) {
          break;
        }
        if (currentElem.hasClass("segment-operand") || currentElem.hasClass("right-paren")) {
          validPositions.push(currentElem[0]);
        }
      }
      return $(validPositions);
    },
    getClosestOperand: function (helper, item, validElements, justification) {
      var left = helper.offset().left;
      var top = helper.offset().top;
      top += helper.height() / 2;
      left += helper.width() / 2;
      var distanceOfClosest = Infinity;
      var closestOperand = undefined;
      for (var i = 0; i < validElements.length; i++) {
        var x = validElements.eq(i).offset().left - left;
        var y = validElements.eq(i).offset().top - top;
        y += validElements.eq(i).height() / 2;
        if (justification === "center") {
          x += validElements.eq(i).width() / 2;
        } else if (justification === "right") {
          x += validElements.eq(i).width();
        }
        var distance = Math.sqrt(x * x + y * y);
        if (distance < distanceOfClosest) {
          closestOperand = validElements.eq(i);
          distanceOfClosest = distance;
        }
      }
      if (closestOperand.is(item.context)) {
        return undefined;
      }
      return closestOperand;
    },
    findMatchingParen: function (parenJqueryElem, actualItem, side) {
      if (parenJqueryElem.hasClass("left-paren") || side == "left") {
        var followingElements = parenJqueryElem.nextAll(".left-paren,.right-paren").not(".ui-sortable-helper").not(actualItem);
        var origParenClass = "left-paren";
      } else {
        var origParenClass = "right-paren";
        var followingElements = parenJqueryElem.prevAll(".left-paren,.right-paren").not(".ui-sortable-helper").not(actualItem);
      }
      var parenDepthCount = 0;
      for (var i = 0; i < followingElements.length; i++) {
        var followingElement = followingElements.eq(i);
        if (followingElement.hasClass(origParenClass)) {
          parenDepthCount++;
        } else {
          if (parenDepthCount === 0) {
            return followingElement;
          } else {
            parenDepthCount--;
          }
        }
      }
    },
    highlightInnerElements: function (left, right, exclude) {
      var innerElements = left.nextUntil(right).add(left).add(right);
      var outerElements = this.getSegmentElements().not(innerElements).not(exclude);
      outerElements.css("opacity", .5);
    },
    resetOpacity: function (exclude) {
      this.getSegmentElements().not(exclude).css("opacity", "");
    },
    unload: function () {
      T1.EventHub.unsubscribe("organization:select");
      T1.EventHub.unsubscribe("versionConflictDialog:close");
      if (this.addBehaviorView) {
        this.addBehaviorView.unload();
        this.addBehaviorView = null;
      }
      $(".nav-list, .knowledge-base, .logout, .active").unbind();
    }
  });
  return createEditView;
});
