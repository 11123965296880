define('modules/shared/views/selectForRibbonStrip',["jQuery", "T1", "T1View", "text!../templates/selectForRibbonStrip.html"], function ($, T1, T1View, template) {
  var documentEvents = "mousedown.focusUI keyup.focusUI";
  var $document = $(document);
  var $window = $(window);
  return T1View.extend({
    template: template,
    events: {
      "mousedown .w-SelectForRibbonStrip": "stopBubbling",
      "mousedown .w-head": "toggle",
      "click .item-list .item.enabled": "itemClick",
      "mousedown .current-option .item-trailing-icon": "trailingIconHandler"
    },
    initialize: function (args) {
      this.currentSelectionId = args.selectedValue + "";
      this.itemList = args.itemList;
      this.onChange = args.onChange;
      this.itemLabel = args.itemLabel;
      this.isStacked = args.isStacked === false ? args.isStacked : true;
      this.trailingIconCssClass = args.trailingIconCssClass;
      this.hideArrow = args.hideArrow;
      if (!this.itemList || !$.isArray(this.itemList)) {
        throw new Error("The incoming param 'itemList' must be a valid array");
      }
      this.isDropdownOpen = false;
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.initializeUI();
        if (self.hideArrow === true) {
          $(self.el).find(".head-label").addClass("hide-arrow");
        }
      });
    },
    unload: function () {
      this.unregisterDocEvents();
      this.wBody = null;
      this.labelArea = null;
      this.isDropdownOpen = false;
    },
    initializeUI: function () {
      var el = $(this.el);
      this.wBody = el.find(".w-body");
      this.labelArea = el.find(".w-head");
    },
    stopBubbling: function (e) {
      e.stopPropagation();
    },
    toggle: function (e) {
      e.stopPropagation();
      if (this.disabled !== true) {
        this.isDropdownOpen ? this.close() : this.open();
      }
    },
    trailingIconHandler: function (e) {
      e.stopPropagation();
      if (this.isDropdownOpen) {
        this.close();
      }
    },
    documentEventHandler: function (e) {
      var self = this;
      if (e.type === "resize" || e.keyCode === 9 || e.keyCode === 27 || !e.keyCode) {
        self.close();
      }
    },
    unregisterDocEvents: function () {
      $document.off(documentEvents, $.proxy(this.documentEventHandler, this));
      $window.off("resize", $.proxy(this.documentEventHandler, this));
    },
    registerDocEvents: function () {
      var self = this;
      $document.one(documentEvents, $.proxy(self.documentEventHandler, self));
      $window.one("resize", $.proxy(self.documentEventHandler, self));
    },
    open: function () {
      $document.mousedown();
      if (this.wBody) {
        this.wBody.removeClass("no-display").fadeIn(200);
      }
      if (this.labelArea) {
        this.labelArea.addClass("white-bg");
      }
      this.isDropdownOpen = true;
      this.unregisterDocEvents();
      this.registerDocEvents();
    },
    close: function () {
      if (this.labelArea) {
        this.labelArea.removeClass("white-bg");
      }
      if (this.wBody) {
        this.wBody.hide();
      }
      this.isDropdownOpen = false;
      this.unregisterDocEvents();
    },
    itemClick: function (e) {
      var self = this;
      var selectedId = $(e.currentTarget).data("id") + "";
      var onChangeCallBack = self.onChange || $.noop;
      if (self.currentSelectionId !== selectedId) {
        self.currentSelectionId = selectedId;
        setTimeout(function () {
          onChangeCallBack(self.currentSelectionId);
        }, 50);
        self.reload();
      }
      self.close();
    },
    getCurrentSelectionText: function (selectedId) {
      var len = this.itemList.length;
      var i;
      var result;
      var list = this.itemList;
      selectedId = selectedId + "";
      for (i = 0; i < len; i++) {
        if (list[i].value + "" === selectedId) {
          result = list[i].text;
          break;
        }
      }
      return result;
    },
    getSelectedValue: function () {
      return this.currentSelectionId;
    },
    enableDisable: function (toDisable) {
      var self = this;
      var $el = $(self.el).find(".w-SelectForRibbonStrip");
      self.close();
      $el.removeClass("disabled");
      if (toDisable === true) {
        $el.addClass("disabled");
        self.disabled = true;
      } else {
        self.disabled = false;
      }
    },
    serialize: function () {
      var len = this.itemList.length;
      var i;
      var item;
      for (i = 0; i < len; i++) {
        item = this.itemList[i];
        item.selected = item.value === this.currentSelectionId;
        if (item.disabled) {
          item.name = item.disabledName || item.name;
          item.state = "disabled";
        } else {
          item.state = "enabled";
        }
      }
      return {
        currentSelectionText: this.getCurrentSelectionText(this.currentSelectionId),
        itemList: this.itemList,
        itemLabel: this.itemLabel,
        hasTrailingIcon: this.trailingIconCssClass,
        trailingIconCssClass: this.trailingIconCssClass,
        isStacked: this.isStacked
      };
    }
  });
});
