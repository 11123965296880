define('modules/strategy/supply/open/views/panel',['require','jQuery','Underscore','T1','T1View','T1Layout','T1Permissions','collections/supplySources','collections/marketplaces','text!../../shared/templates/panel.html','text!../templates/panel_layout.html','text!templates/form_footer.html','text!templates/alert.html','text!../templates/grid_header.html','text!../../shared/templates/grid_footer.html','text!../templates/grid_item.html','text!../templates/bin_title.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const SupplySources = require("collections/supplySources");
  const Marketplaces = require("collections/marketplaces");
  const tmpl = require("text!../../shared/templates/panel.html");
  const layout_tmpl = require("text!../templates/panel_layout.html");
  const form_footer = require("text!templates/form_footer.html");
  const alert_tmpl = require("text!templates/alert.html");
  const gridHeader = require("text!../templates/grid_header.html");
  const gridFooter = require("text!../../shared/templates/grid_footer.html");
  const gridItem = require("text!../templates/grid_item.html");
  const bin_title = require("text!../templates/bin_title.html");
  const isFraudColEnabled = T1Permissions.check("feature", "fraud_filtered_column");
  var OpenSupplyPanel = T1View.extend({
    template: tmpl,
    noCheckUnsavedState: true,
    contentType: "supply_source",
    partials: {
      form_footer: form_footer,
      alertDialog: alert_tmpl
    },
    events: {
      "click .hideIcon": "hideWarning",
      "change .checkBoxHolder label": "toggleRunOnAll",
      "changed .run-all-opts": "toggleRunOnAll",
      "click .run-all-opts": "selectSupplyOptions",
      "click .open-unpermissioned-deals-modal": "openUnpermissionedDealsModal",
      "click .close-unpermissioned-deals-modal": "closeUnpermissionedDealsModal"
    },
    dataEvents: {
      model: {
        "change:run_on_all_exchanges": "changeRunOnAllExchanges"
      }
    },
    eventhubEvents: {
      "supply:saveSuccessful": "onSaveSuccessful",
      "supply:cancelTypeChange": "changeRunOnAllExchanges",
      "supply:open:regrid": "regrid",
      "hasUnpermissionedDeals:updateBins": "showUnpermissionedDealsWarning",
      "hideUnpermissionedDealsWarning:reloadBins": "hideUnpermissionedDealsWarning"
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      sharedFptCollection: sharedFptCollection,
      foldTargets: foldTargets,
      hasUnpermissionedDeals: hasUnpermissionedDeals,
      addOrRemoveSharedCollection: addOrRemoveSharedCollection,
      targetAllFlagEnabled: targetAllFlagEnabled,
      mapItemsBins: mapItemsBins
    }) {
      this.sharedCollection = sharedCollection;
      this.sharedFptCollection = sharedFptCollection;
      this.foldTargets = foldTargets;
      this.hasUnpermissionedDeals = hasUnpermissionedDeals;
      this.addOrRemoveSharedCollection = addOrRemoveSharedCollection;
      this.targetAllFlagEnabled = targetAllFlagEnabled;
      this.mapItemsBins = mapItemsBins;
      const foldTargetValues = this.sharedFptCollection.models.slice();
      this.model.set({
        cached_fold_target_values: foldTargetValues
      });
      const advSourceProp = this.model.get("campaign").get("advertiser").get("accountable_and_addressable");
      this.isSourceFeatureEnabled = T1Permissions.check("feature", "source");
      this.isSourceEnabledOnAdv = advSourceProp === "1";
      this.updateStrategyModel();
      this.initializeLayout();
    },
    updateStrategyModel: function () {
      this.model.tempSupplyInfo = {
        run_on_all_exchanges: this.model.get("run_on_all_exchanges"),
        run_on_all_pmp: this.model.get("run_on_all_pmp")
      };
    },
    load: function () {
      if (!this.targetAllFlagEnabled) {
        this.sharedFptCollection.reset(this.model.get("cached_fold_target_values"));
      }
      this.isActualChange = false;
      if (!this.model.get("cached_run_on_all_exchanges")) {
        this.model.set({
          cached_run_on_all_exchanges: this.model.get("run_on_all_exchanges")
        });
      }
      if (!this.model.get("cached_exchange_type_for_run_on_all")) {
        this.model.set({
          cached_exchange_type_for_run_on_all: this.model.get("exchange_type_for_run_on_all")
        });
      }
      if (this.targetAllFlagEnabled) {
        if (!this.sharedCollection.hasUnpermissionedDeals) {
          T1.EventHub.publish("hideUnpermissionedDealsWarning:reloadBins");
          if (this.layout.layout[".bins-column"][0].view) {
            this.layout.layout[".bins-column"][0].view.options.hasUnpermissionedDeals = false;
          }
        }
      }
      this.render().then(() => {
        if (this.layout) {
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "supply_targeting"
          });
          this.layout.load().then(() => {
            var viewObj = this.layout.options ? this.layout.options.layout[".browser-c"][0].view : undefined;
            if (viewObj) {
              viewObj.searchTerm = undefined;
            }
            this.el.trigger("subViewLoad");
          });
          this.regrid();
        }
        this.changeRunOnAllExchanges();
        if (!this.targetAllFlagEnabled) {
          this.checkWarning(this.sharedCollection.length, this.sharedCollection.contentType);
        }
      });
    },
    regrid: function () {
      if (this.grid) {
        this.grid.createCollection();
      }
    },
    checkWarning: function (len, type) {
      var viewType = this.contentType;
      var action = len > 0 && type !== viewType ? "show" : "hide";
      T1.EventHub.publish(`supply:${action}Warning`, viewType);
    },
    hideWarning: function (evt) {
      this.preventDefault(evt);
      this.checkWarning(0, "hide-warning");
      this.grid.updateScrollBar();
    },
    openUnpermissionedDealsModal: function (event) {
      event.preventDefault();
      const modal = this.$(".unpermissioned-deals-modal");
      const grid = modal.find(".unpermissioned-deals-grid")[0];
      grid.data = this.sharedCollection.generateUnpermissionedGridData();
      modal[0].show();
    },
    closeUnpermissionedDealsModal: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.$(".unpermissioned-deals-modal")[0].hide();
    },
    hideUnpermissionedDealsWarning: function () {
      this.hasUnpermissionedDeals = false;
    },
    initializeLayout: function () {
      var self = this;
      const filterAndClearCallback = function (term = "") {
        T1.EventHub.publish("opensupplysearch", {
          term: term
        });
      };
      const videoBinPartial = {
        bin_title: bin_title
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: layout_tmpl,
        layout: {
          ".filter": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback
            }
          }],
          ".browser-c": [{
            module: "strategy/supply/shared",
            viewType: "browse",
            showLoader: true,
            options: this.getBrowseOptions()
          }],
          ".bins-column": [{
            module: "strategy/supply/shared",
            viewType: "bins",
            showLoader: true,
            options: {
              partials: this.isVideoStrategy(this) ? videoBinPartial : null,
              sharedCollection: this.sharedCollection,
              contentType: this.contentType,
              mapItems: this.mapItemsBins,
              model: this.model,
              updateBins: !this.isVideoStrategy(this) ? null : function () {
                var data, value;
                var amount = this.sharedCollection.length;
                if (amount > 0 && amount < self.readonlyBrowseModels.length) {
                  value = ` (${amount})`;
                } else {
                  value = "";
                }
                data = {
                  optional_amount: value
                };
                this.updateDataBind(data, this.el, {
                  doNotEscape: true
                });
              },
              hasUnpermissionedDeals: this.hasUnpermissionedDeals
            }
          }]
        },
        serialize: () => ({
          supplyType: "Open supply sources",
          isVideoStrategy: this.isVideoStrategy(this),
          isSourceFeatureEnabled: this.isSourceFeatureEnabled,
          isSourceEnabledOnAdv: this.isSourceEnabledOnAdv,
          unpermissionedDealCount: this.sharedCollection.unpermissionedDeals ? this.sharedCollection.unpermissionedDeals.length : undefined
        })
      });
    },
    isVideoStrategy: function (context) {
      var model = context ? context.model : this.model;
      return model.get("media_type") === "VIDEO";
    },
    updateScrollBarSize: function () {
      const $contentHolder = this.$(".content-holder");
      if ($contentHolder.length) {
        $contentHolder.perfectScrollbar({}, "update");
      }
    },
    getBrowseOptions: function () {
      var outerSelf = this;
      return {
        model: this.model,
        sharedCollection: this.sharedCollection,
        sharedFptCollection: this.sharedFptCollection,
        foldTargets: this.foldTargets,
        contentType: this.contentType,
        targetAllFlagEnabled: this.targetAllFlagEnabled,
        checkboxAttr: "run_on_all_exchanges",
        isVideoStrategy: this.isVideoStrategy(this),
        createCollection: function () {
          const self = this;
          const {
            model: strategy,
            sharedCollection: shColl
          } = this;
          const coll = this.collection = outerSelf.readonlyBrowseModels = new SupplySources(false, {
            fetchOptions: {
              full: "*"
            },
            isPaginated: false
          });
          coll.search.set({
            params: [{
              searchField: "rtb_type",
              term: "STANDARD"
            }, ...[(!this.isSourceFeatureEnabled || !outerSelf.isSourceEnabledOnAdv) && {
              searchField: "accountable_and_addressable",
              term: "0"
            }]]
          });
          const advertiserId = this.model.get("campaign").get("advertiser").get("id");
          const marketplaceColl = new Marketplaces(false, {
            fetchOptions: {
              full: "*",
              advertiser_id: advertiserId
            },
            isPaginated: false
          });
          const marketplaceSearchField = this.model.get("media_type") === "DISPLAY" ? "has_display" : "has_video";
          marketplaceColl.search.set({
            params: [{
              term: "1",
              searchField: marketplaceSearchField
            }]
          });
          marketplaceColl.fetchAllPages({
            success: () => {
              coll.fetch({
                success: function () {
                  coll.add(marketplaceColl.toJSON(), {
                    silent: true
                  });
                  coll.filterGarbageData(self.isVideoStrategy, self.model.get("run_on_mobile") === "1", self.model.get("run_on_streaming") === "1");
                  coll.sort();
                  if (self.targetAllFlagEnabled) {
                    shColl.setTypeIds(coll);
                  } else {
                    shColl.setModelIds(coll);
                  }
                  shColl.filterSupplySharedColl(coll);
                  if (strategy.get("run_on_all_exchanges") === "1") {
                    if (self.targetAllFlagEnabled) {
                      shColl.add(coll.models, {
                        silent: true
                      });
                    } else {
                      if (outerSelf.isSourceFeatureEnabled && outerSelf.isSourceEnabledOnAdv && outerSelf.model.get("exchange_type_for_run_on_all") === "SOURCE") {
                        shColl.reset(coll.models.filter(model => model.get("accountable_and_addressable") === "1"), {
                          silent: true
                        });
                      } else {
                        shColl.reset(coll.models, {
                          silent: true
                        });
                      }
                    }
                    T1.EventHub.publish("opensupply:updateBins");
                  }
                  self.load();
                  outerSelf.grid = self;
                  setTimeout(function () {
                    T1.EventHub.publish("sharedsupply:updateBins", [shColl]);
                  }, 0);
                },
                silent: true
              });
            }
          });
        },
        toggleNode: function (event) {
          var $target = $(event.currentTarget);
          var type = $target.data("type");
          var kids = `[data-type="${type}-child"]`;
          var $children = $target.closest(".content-c").find(kids);
          var $childContainer = $children.closest(".children");
          var expanding = Boolean($childContainer.length);
          event.preventDefault();
          $target.find(".icon-arrow").toggleClass("expanded");
          if (expanding) {
            $childContainer.toggle("blind", function () {
              $(this).children(".item-wrapper").unwrap();
              outerSelf.updateScrollBarSize();
            });
          } else {
            $children.parent(".item-wrapper").wrapAll('<div class="children"></div>').parent().toggle("blind", function () {
              outerSelf.updateScrollBarSize();
            });
          }
        },
        searchCollection: function (data) {
          this.collection.searchSupplySources(data.term);
        },
        partials: {
          gridHeader: gridHeader,
          gridFooter: outerSelf.isVideoStrategy(this) ? null : gridFooter,
          listItem: gridItem
        },
        dataEvents: {
          collection: {
            reset: "load",
            "change:deal": "updateShColl"
          },
          sharedCollection: {
            add: function (model) {
              this.addOrRemoveSharedCollection(this, model, "add");
            },
            remove: function (model) {
              this.addOrRemoveSharedCollection(this, model, "remove");
            },
            toggleAllOpen: "renderAllItems"
          }
        },
        eventhubEvents: {
          opensupplysearch: "search",
          "openSupply:toggleAll": "toggleRunOnAllCheckbox",
          "supply:changeTypeByItem:supply_source": function (data) {
            this.closeChangeTypeBy("item")(data);
          },
          "supply:changeActualChangeItem:supply_source": function () {
            this.isActualChange = true;
          },
          "supply:changeTypeByItems:supply_source": function (data) {
            this.closeChangeTypeBy("items")(data);
          },
          "supply:changeTypeByAll:supply_source": "closeChangeTypeByAll"
        },
        onDragableItemClick: function (e) {
          this.preventDefault(e);
        },
        serialize: this.serializeBrowse,
        styleForSearch: this.styleForSearch,
        styleForBrowse: this.styleForBrowse,
        addOrRemoveSharedCollection: this.addOrRemoveSharedCollection
      };
    },
    serializeBrowse: function (models) {
      var tdata = {};
      const {
        collection: collection,
        sharedFptCollection: folds,
        targetAllFlagEnabled: targetAllFlagEnabled
      } = this;
      var contentType = this.sharedCollection.contentType;
      if (!models) {
        models = collection ? collection.models : [];
      }
      if (folds) {
        tdata.aboveFPT = folds.get("45054");
        tdata.belowFPT = folds.get("45055");
        tdata.uncategorizedFPT = folds.get("45056");
      }
      tdata.isVideoStrategy = this.isVideoStrategy;
      tdata.isFraudColEnabled = isFraudColEnabled;
      tdata.canWriteFpt = targetAllFlagEnabled || contentType !== "site_placement";
      tdata.isPartial = this.model.get("run_on_all_exchanges") === "1";
      tdata.list = this.chooseRenderStyle(this.searchTerm, models);
      return tdata;
    },
    styleForSearch: function (models, term) {
      var self = this;
      var highlight = T1.Utils.highlightText;
      return _.chain(_.clone(models)).tap(function serialize(model) {
        var toBeSerialized = model.splice(0, model.length);
        var serialized = self.styleForBrowse(toBeSerialized);
        for (let i = 0, l = serialized.length; i < l; i++) {
          model.push(serialized[i]);
        }
      }).map(function (item) {
        if (item.name) {
          item.name = highlight(item.name, term);
        }
        return item;
      }).value();
    },
    styleForBrowse: function (models) {
      const shColl = this.sharedCollection;
      const isVideoStrategy = this.isVideoStrategy;
      const type = this.contentType;
      const targetAllFlagEnabled = this.targetAllFlagEnabled;
      const isRunAll = this.isSourceFeatureEnabled ? this.model.get("exchange_type_for_run_on_all") === "ALL_ELIGIBLE" : this.model.get(this.checkboxAttr) === "1";
      function createParentItem(item) {
        const name = {
          STANDARD: "Open Exchanges & SSPs",
          MARKETPLACE: "Marketplaces"
        }[item];
        return {
          group: name,
          type: item,
          isParent: true
        };
      }
      function createChildItem(model) {
        const isActive = targetAllFlagEnabled ? isRunAll || Boolean(shColl.get(model.id)) && shColl.get(model.id).get("type") === "supply_source" : isRunAll || shColl.contentType === "supply_source" && Boolean(shColl.get(model.id));
        return {
          name: model.get("name"),
          isParent: false,
          isActive: isActive,
          id: model.id,
          type: model.get("rtb_type"),
          hasMobile: parseInt(model.get("has_mobile_video"), 10) === 1,
          hasDesktop: parseInt(model.get("has_desktop_video"), 10) === 1,
          hasConnectedTv: parseInt(model.get("has_connected_tv"), 10) === 1,
          hasFraudFiltered: parseInt(model.get("fraud_filter"), 10) === 1,
          isFraudColEnabled: isFraudColEnabled
        };
      }
      return _.chain(models).filter(function removeWrongContentType(item) {
        return item.get("type") === type;
      }).groupBy(model => model.get("rtb_type")).pairs().tap(function correctOrder(pairs) {
        pairs.reverse();
      }).flatten().map(function (item) {
        const isParentItem = typeof item === "string";
        return isParentItem ? createParentItem(item) : $.extend({
          isVideoStrategy: isVideoStrategy
        }, createChildItem(item));
      }).value();
    },
    selectSupplyOptions: function () {
      if (!this.isActualChange) {
        this.isActualChange = true;
      }
      T1.EventHub.publish("openSupply:toggleAll");
    },
    toggleRunOnAll: function (evt) {
      this.preventDefault(evt);
      if (this.isSourceFeatureEnabled) {
        this.model.set({
          exchange_type_for_run_on_all: evt.target.value
        });
      }
      if (this.isSourceFeatureEnabled && this.contentType === "supply_source" && !this.isActualChange) {
        this.isActualChange = true;
      } else {
        T1.EventHub.publish("openSupply:toggleAll");
      }
    },
    changeRunOnAllExchanges: function (isOnChange = false) {
      if (!isOnChange) {
        this.isActualChange = false;
      }
      if (this.isSourceFeatureEnabled) {
        this.setRadioValue(isOnChange);
      } else {
        const $checker = $(".checkBoxHolder input", this.el);
        const allExchanges = this.model.get("run_on_all_exchanges");
        const isAllExchanges = allExchanges === "1";
        $checker.prop("checked", isAllExchanges);
      }
    },
    setRadioValue: function (isOnChange) {
      const $runOpts = this.$(".run-all-opts");
      let exchangeType = this.model.get("exchange_type_for_run_on_all");
      if (exchangeType === "SOURCE" && !this.isSourceEnabledOnAdv) {
        exchangeType = "ALL_ELIGIBLE";
      }
      if (!isOnChange && exchangeType !== "SELECTED_ONLY" && this.model.get("run_on_all_exchanges") === "0") {
        exchangeType = "SELECTED_ONLY";
      }
      $runOpts.prop("value", exchangeType);
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    postData: function () {
      var isAllPmp = this.model.get("run_on_all_pmp") === "1";
      var isAllExchange = this.model.get("run_on_all_exchanges") === "1";
      var hasSelections = this.sharedCollection.length;
      var publishEvent = isAllPmp || isAllExchange || hasSelections ? "supply:save" : "supply:noSelection";
      T1.EventHub.publish(publishEvent);
    },
    canUnload: function (fromT1FormCancelButton) {
      return fromT1FormCancelButton !== true;
    },
    onSaveSuccessful: function () {
      this.stopSpinner();
      this.updateFooterMessage({
        success: true
      });
      if (!this.sharedCollection.changedWhileSaving) {
        this.sharedCollection.isFormElementsChanged = false;
      }
      this.processSaveAndContinueMode();
      T1.EventHub.publish("strategy-create-edit:reloadSubViews");
      this.updateStrategyModel();
      this.model.set({
        cached_run_on_all_exchanges: this.model.get("run_on_all_exchanges")
      });
      this.model.set({
        cached_exchange_type_for_run_on_all: this.model.get("exchange_type_for_run_on_all")
      });
    },
    chooseRenderStyle: function () {}
  });
  return T1.Form.ViewUtils.extend(OpenSupplyPanel);
});
