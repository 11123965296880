define('modules/creatives/concept/control/headers',[], function () {
  return [{
    name: "checkbox-input default",
    label: '<div class="checkbox main-check all"></div>'
  }, {
    name: "toggle-arrow",
    label: "&nbsp;"
  }, {
    name: "default name",
    label: "name"
  }, {
    name: "default id",
    label: "id"
  }, {
    name: "default status",
    label: "Concept<br>Status"
  }, {
    name: "default advertiser",
    label: "advertiser"
  }, {
    name: "default activecount",
    label: "# of<br>Creatives"
  }, {
    name: "default strategy",
    label: "Strategy Name (ID)"
  }, {
    name: "default modified",
    label: "Modified"
  }, {
    name: "config",
    label: "&nbsp;"
  }];
});
