define('modules/targeting/location/views/panel',["jQuery", "Underscore", "T1", "When", "T1View", "T1Permissions", "collections/nemoTargets", "collections/nemoAttachments", "text!../templates/panel.html", "text!../templates/panel_layout.html", "T1Layout", "collections/targetValues", "modules/targeting/location/models/hyperLocalTargeting", "T1FileUpload", "utils/CampaignStrategyUtils", "text!templates/info_box.html", "modules/strategy/views/helpers/defaultLocationPersistenceHelper", "text!templates/alert.html", "T1Tooltip", "T1Preferences", "T1Signature"], function ($, _, T1, When, T1View, T1Permissions, NemoTargets, NemoIPAttachments, template, panelLayout, T1Layout, TargetValues, HyperLocalTargeting, T1FileUpload, Utils, info_box, DefaultLocPersistHelper, alert_tmpl) {
  "use strict";

  var targetDimensionKey = Utils.names.targetDimensions;
  var isTargetDimensionReady = Utils.isStrategyTargetDimensionDataReady;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = Utils;
  const timeout = 100;
  const LocationTargetingView = T1View.extend({
    template: template,
    viewMode: "browse",
    isIncluded: {
      zip: true,
      ip: true
    },
    events: {
      "click .postal-codes .icon": "setPostalCodeRestriction",
      "click .save-location-default": "saveDefaultLocations",
      "click .save-defaultlocation-info .icon-close": "hideSaveDefaultLocationInfoBox",
      "click .save-default-group .info": "launchSaveDefaultLocationKbPage",
      "click #closeBtn": "onCloseInvalidIpsClick"
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": function () {
        this.initializeDataEventsAndLayout();
        this.load();
      },
      clearzipcodes: function () {
        const ipCodesInfo = this.ipCodesInfo;
        this.isExcludePostalTargetCode = false;
        this.isIncluded.zip = true;
        this.resetUploadForm("USZC");
        this.onZipCodeChange();
        this.showHidePostalExcludeIncludeIcons(false);
        this.$(".postal-codes form .restriction").attr("value", "INCLUDE");
        if (ipCodesInfo && ipCodesInfo.count && ipCodesInfo.action) {
          this.showHidePostalExcludeIncludeIcons(true);
          this.syncUploadRestrictionFieldWithIconVals(ipCodesInfo.action);
          this.syncIconValues(ipCodesInfo.action);
        }
      },
      clearIPTargets: function () {
        const zipcodeinfo = this.zipcodeinfo;
        this.resetUploadForm("IPTA");
        this.isIncluded.ip = true;
        this.ipCodesInfo.deleteAttachments = true;
        delete this.ipCodesInfo.action;
        delete this.ipCodesInfo.count;
        delete this.ipCodesInfo.filename;
        delete this.ipTargetsCount;
        this.showHidePostalExcludeIncludeIcons(false);
        this.isFormElementsChanged = true;
        if (zipcodeinfo && zipcodeinfo.count && zipcodeinfo.action) {
          this.showHidePostalExcludeIncludeIcons(true);
          this.syncUploadRestrictionFieldWithIconVals(zipcodeinfo.action);
          this.syncIconValues(zipcodeinfo.action);
        }
      },
      updateFileAction: function (action) {
        var isPostalCodes = this.el.find(".drop-down-holder .select").val() === "Postal Codes";
        if (isPostalCodes) {
          this.zipcodeinfo.action = action;
        } else {
          this.ipCodesInfo.action = action;
        }
      },
      clearUploadForm: "resetUploadForm",
      uploadzipcodes: "onZipCodeChange",
      uploadIPTargets: "onIPTargetingChange"
    },
    initialize: function ({
      strategy: strategy,
      strategyTargetDimensions: strategyTargetDimensions
    }) {
      this.strategy = strategy;
      this.collection = new TargetValues([], {
        pageLimit: 100,
        dimension: "REGN"
      });
      this.collection.isPaginated = true;
      this.sharedCollection = new TargetValues([], {
        dimension: "REGN",
        isPaginated: false
      });
      this.ipCodesInfo = {};
      this.strategyTargetDimensions = strategyTargetDimensions;
      this.zipcodeUrl = `${T1.API_ROOT}strategies/${this.strategy.id}/target_postcodes`;
      this.preventDoubleBind = true;
      this.bindViewEvents();
      this.initializeHyperLocal();
      this.nemoIPTargets = new NemoTargets();
      this.nemoIPAttachments = new NemoIPAttachments();
      delete this.nemoIPAttachments.pageField;
      delete this.nemoIPAttachments.pageLimit;
      delete this.nemoIPAttachments.pageLimitField;
      delete this.nemoIPAttachments.page;
      this.strategy.nemoIPAttachments = this.nemoIPAttachments;
      if (isTargetDimensionReady(this.strategyTargetDimensions) === true) {
        this.initializeDataEventsAndLayout();
      }
      this.defaultLocationPersister = new DefaultLocPersistHelper();
      this.defaultLocationPersister.init();
      this.useIPTargeting = T1Permissions.check("feature", "use_ip_targeting");
    },
    resetUploadForm: function (type) {
      var dropDownSelected = $(".postal-codes .drop-down-holder .select").val();
      this.$(".postal-codes .icon").removeClass("active");
      this.$(".postal-codes .include").addClass("active");
      if (!type || type === dropDownSelected) {
        this.$(".file-input").val("");
        this.$(".file-selector .file-name input").val("");
      }
    },
    showHidePostalExcludeIncludeIcons: function (toShow) {
      var settingVal = "hidden";
      if (toShow === true) {
        settingVal = "visible";
      }
      this.$(".postal-codes .icons .icon").css("visibility", settingVal);
    },
    initializeHyperLocal: function () {
      this.hyperLocalTargeting = new HyperLocalTargeting({
        strategy: this.strategy
      });
      this.hyperLocalCollection = this.hyperLocalTargeting.hyperLocalCollection;
      this.nemoTargets = this.hyperLocalTargeting.nemoTarget;
      this.hyperLocalAttachments = this.hyperLocalTargeting.hyperLocalAttachments;
      this.strategy.hyperLocalTargeting = this.hyperLocalTargeting;
    },
    initializeDataEventsAndLayout: function () {
      this.initSharedCollection();
      this.addDataEvent({
        sharedCollection: {
          reset: () => {
            this.isFormElementsChanged = false;
          },
          add: () => {
            this.isFormElementsChanged = true;
            this.disableEnableSaveDefaultButton();
          },
          remove: () => {
            this.isFormElementsChanged = true;
            this.disableEnableSaveDefaultButton();
          }
        }
      });
      this.initLayout();
      this.isFormElementsChanged = false;
      this.removeEventHubEvents(["strategyTargetDimension:reset"]);
    },
    initSharedCollection: function () {
      const promises = [];
      var self = this;
      var stratTargetDims = this.strategyTargetDimensions;
      var sharedColl = this.sharedCollection;
      var dims = stratTargetDims.get(targetDimensionKey);
      const ipTargetingFetch = When.defer();
      const hyperlocalFetch = When.defer();
      promises.push(ipTargetingFetch.promise);
      promises.push(hyperlocalFetch.promise);
      function addToSharedCollection(dim) {
        var targetVals = dim.get("target_value");
        _.each(targetVals.models, function (model) {
          sharedColl.add(model, {
            silent: true
          });
        });
      }
      function geofenceFetchedCallback(model, geofence) {
        model.geofence = geofence;
        geofence.set({
          action: geofence.get("action").toLowerCase()
        });
        sharedColl.add(geofence, {
          silent: true
        });
      }
      function hyperLocalLoadedCallback() {
        self.hyperLocalTargeting.attachmentsLoaded = true;
        hyperlocalFetch.resolve();
      }
      this.nemoIPAttachments.fetch({
        fetchOptions: {
          strategy_id: this.strategy.id,
          dimension: "IPTA"
        },
        success: function (data) {
          var restriction;
          _.each(data.models, function (model) {
            model.set({
              dimension: "IPTA"
            }, {
              silent: true
            });
            model.set({
              count: data.models.length
            }, {
              silent: true
            });
            restriction = model.get("restriction");
          });
          self.ipCodesInfo.filename = "ipList.csv";
          self.ipCodesInfo.action = restriction || "";
          self.ipCodesInfo.count = data.count || "0";
          self.ipTargetsCount = data.models.length;
          self.ipCodesInfo.showURL = true;
          self.sharedCollection.add({
            dimension_code: "IPTA",
            filename: self.ipCodesInfo.filename,
            action: self.ipCodesInfo.action,
            count: self.ipCodesInfo.count,
            ipTargetsCount: self.ipCodesInfo.ipTargetsCount,
            showURL: self.ipCodesInfo.showURL,
            selected: true
          }, {
            silent: true
          });
          ipTargetingFetch.resolve();
          T1.EventHub.publish("uploadIPTargets", self.ipCodesInfo);
        }
      });
      sharedColl.reset([], {
        silent: true
      });
      this.partialCollection = stratTargetDims.get("partial");
      if (dims && dims.length) {
        const dimCodes = ["REGN", "DMAX", "USZC"];
        dims.each(function (dim) {
          var dimCode = dim.get("code");
          if (dimCodes.includes(dimCode)) {
            addToSharedCollection(dim);
          }
        });
      }
      When.all(promises).then(function () {
        setTimeout(function () {
          T1.EventHub.publish("panel:attachmentsLoaded");
          T1.EventHub.publish("strategyTargetingLoader:stop");
          T1.EventHub.publish("location:dataLoaded");
          self.enableSaveButtons();
        }, timeout);
      });
      this.hyperLocalTargeting.fetchAttachedGeofences(geofenceFetchedCallback, hyperLocalLoadedCallback);
    },
    onZipCodeChange: function (data) {
      this.isFormElementsChanged = true;
      this.changedZipCodes = true;
      if (data) {
        this.zipcodecount = data.count;
      } else {
        delete this.zipcodecount;
      }
    },
    onIPTargetingChange: function (data) {
      if (data) {
        this.ipTargetsCount = data.count;
      } else {
        delete this.ipTargetsCount;
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    onDontSave: function () {
      this.collection.trigger("search.clear");
      this.strategyTargetDimensions.fetch({
        success: () => {
          this.initSharedCollection();
        }
      });
      this.unbind();
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    onCloseInvalidIpsClick: function () {
      this.el.find("#errorModal")[0].hide();
    },
    setPostalCodeRestriction: function (event) {
      var $target = $(event.currentTarget);
      const action = $target.hasClass("include") ? "INCLUDE" : "EXCLUDE";
      if (this.zipcodeUploading === true) {
        return;
      }
      this.isIncluded[this.dropDownTypeSelected] = action === "INCLUDE";
      this.setIncludeExcludeIcons();
      if (this.isIncluded.zip === true) {
        this.isExcludePostalTargetCode = false;
        $target.closest(".postal-codes").find("form .restriction").attr("value", "INCLUDE");
      } else {
        this.isExcludePostalTargetCode = true;
        $target.closest(".postal-codes").find("form .restriction").attr("value", action);
      }
      if (this.zipcodeinfo && !this.isIPList) {
        this.zipcodeinfo.action = action;
        T1.EventHub.publish("uploadzipcodes", this.zipcodeinfo);
      }
      if (this.ipCodesInfo && this.isIPList && this.ipCodesInfo.count > 0) {
        this.ipCodesInfo.action = action;
        T1.EventHub.publish("uploadIPTargets", this.ipCodesInfo);
      }
    },
    fileUploaderData: {
      inputFields: [{
        name: "restriction",
        value: "INCLUDE"
      }, {
        name: "validate_only",
        value: "1"
      }, {
        name: "ignore_errors",
        value: "1"
      }, {
        name: "active",
        value: "true"
      }],
      formSelector: "zipcode-upload",
      showUploadBtn: true,
      disabled: "disabled",
      showDropDown: true,
      nemoInput: true,
      uploadType: {
        options: [{
          value: "USZC",
          text: "Postal Codes"
        }, {
          value: "IPTA",
          text: "IP List - Beta"
        }]
      }
    },
    load: function () {
      function showGuide(guide, attempts) {
        if (attempts) {
          if (guide && guide.show) {
            guide.show();
          } else {
            window.setTimeout(function () {
              showGuide(guide, --attempts);
            }, timeout);
          }
        }
      }
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        if (this.layout) {
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "location_targeting"
          });
          this.layout.load().then(() => {
            T1.Tooltip(this.$(".filter-strip .save-default-group"), {
              offset: 5,
              delayIn: "100",
              delayOut: "100"
            });
            this.$(".upload-progress-bar")[0].style.display = "none";
            this.disableEnableSaveDefaultButton();
            this.disableSaveButtons();
            this.ensureLocationTargetDimensions(this.strategy.targetDimensions.get("target_dimensions"));
          });
          if (!this.fileUploader) {
            this.fileUploader = T1FileUpload.create({
              $el: this.$(".file-uploader-c"),
              onChange: $.proxy(this.changeFile, this),
              onDropDownChange: $.proxy(this.onDropDownChange, this),
              sourceURL: this.zipcodeUrl,
              ipTargetingPostObj: {
                strategy_id: this.strategy.id,
                restriction: "INCLUDE",
                operator: "OR"
              }
            });
          }
        }
        if (this.fileUploader) {
          this.fileUploader.render(this.fileUploaderData);
        }
        this.showHidePostalExcludeIncludeIcons(false);
        const $guide = $('[name="city-targeting"]');
        if (!window.localStorage.getItem("guide-city-targeting") && $guide.length) {
          const attempts = 10;
          showGuide($guide[0], attempts);
        }
        if (!this.useIPTargeting) {
          const dropdown = this.$(".drop-down-holder .select");
          if (dropdown) {
            dropdown.val("USZC");
            dropdown.attr("disabled", true);
            this.isIPList = false;
            this.$(".file-upload-btn").removeClass("disabled");
          }
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(this.strategy.get("campaign"));
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    reload: function () {
      this.strategyTargetDimensions.fetch({
        success: () => {
          this.initSharedCollection();
          T1View.prototype.reload.call(this);
        }
      });
    },
    unload: function () {
      var link = this.el.find(".actions-container .messages .warning-message #linkToErrorModal");
      if (link.length) {
        link.off("click");
      }
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
      delete this.zipcodeinfo;
      if (this.fileUploader) {
        this.fileUploader.destroy();
        this.fileUploader = null;
      }
      T1View.prototype.unload.call(this);
    },
    syncIconValues: function (value) {
      if (value && value === "EXCLUDE") {
        this.$(".postal-codes .icons .active").removeClass("active");
        this.$(".postal-codes .icons .icon.exclude").addClass("active");
      }
    },
    syncUploadRestrictionFieldWithIconVals: function (action) {
      var resultAction = action || "INCLUDE";
      const excludeIcon = this.$(".postal-codes .icons .icon.exclude");
      if (excludeIcon.hasClass("active") === true && !action) {
        resultAction = "EXCLUDE";
      }
      resultAction = this.isExcludePostalTargetCode === true ? "EXCLUDE" : "INCLUDE";
      this.$(".postal-codes").find("form .restriction").attr("value", resultAction);
    },
    onDropDownChange: function (event) {
      var targetType = event.currentTarget.value;
      const placeholderText = targetType === "USZC" ? " Select a CSV file with your complete list of postal code targets" : "Select CSV file with a list of up to 40K IP Addresses";
      const isIconVisible = this.nemoIPTargets.length && targetType === "IPTA" || this.zipcodecount > 0 && targetType === "USZC";
      var $input = this.el.find(".file-name .text");
      $input.attr("placeholder", placeholderText);
      this.isIPList = targetType === "IPTA";
      this.showHidePostalExcludeIncludeIcons(isIconVisible);
      this.el.find(".file-upload-btn").removeClass("disabled");
      this.el.find(".text-input").removeClass("error");
      this.dropDownTypeSelected = targetType === "USZC" ? "zip" : "ip";
      this.setIncludeExcludeIcons();
    },
    onUploadClick: function () {
      T1.EventHub.publish("uploadIPTargets", this.ipCodesInfo);
      this.syncUploadRestrictionFieldWithIconVals();
    },
    setIncludeExcludeIcons: function () {
      var activeIcon = this.isIncluded[this.dropDownTypeSelected] ? "include" : "exclude";
      $(".postal-codes .icon").removeClass("active");
      $(`.postal-codes .${activeIcon}`).addClass("active");
    },
    changeFile: function (event) {
      var self = this;
      var $target = $(event.target);
      var action = "INCLUDE";
      var fileName = $target.val().substring($target.val().lastIndexOf("\\") + 1);
      var zipcodeinfo = this.zipcodeinfo || {};
      var ipCodesInfo = this.ipCodesInfo || {};
      const progressBar = value => {
        this.$(".upload-progress-bar")[0].style.display = value;
      };
      if (this.zipcodeUploading === true || !event.currentTarget.files.length) {
        this.isFormElementsChanged = true;
        return;
      }
      if (!/\.(csv)$/i.test(fileName) && fileName !== "") {
        handleInvalidExtension();
        return;
      }
      if (this.dropDownTypeSelected === "ip") {
        handleLargeFile($target[0].files);
      } else {
        uploadFile();
      }
      function handleInvalidExtension() {
        self.$(".text-input").addClass("error");
        self.updateFooterMessage({
          error: true,
          message: "Invalid file type. Please upload a csv file."
        });
      }
      function handleLargeFile(files) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const rows = e.target.result.split("\n");
          const maxRows = 4e4;
          if (rows.length > maxRows) {
            self.$(".text-input").addClass("error");
            self.updateFooterMessage({
              error: true,
              message: "File not uploaded. Please use a csv containing 40K or fewer rows."
            });
          } else {
            uploadFile();
          }
        };
        reader.readAsText(files[0]);
      }
      function handleIPTargets(targets) {
        if (targets.length) {
          ipCodesInfo = {
            filename: fileName,
            count: targets.length,
            action: action,
            showURL: false
          };
          self.nemoIPTargets.reset({
            name: "ip_targets",
            list: targets
          }, {
            silent: true
          });
          resetSharedCollection();
          self.sharedCollection.add({
            dimension_code: "IPTA",
            filename: ipCodesInfo.filename,
            action: action,
            count: ipCodesInfo.count,
            showURL: ipCodesInfo.showURL,
            selected: true
          }, {
            silent: true
          });
        }
        self.ipCodesInfo = ipCodesInfo;
        self.showHidePostalExcludeIncludeIcons(true);
        self.syncUploadRestrictionFieldWithIconVals(ipCodesInfo.action);
        self.zipcodeUploading = false;
        T1.EventHub.publish("uploadIPTargets", ipCodesInfo);
        const link = self.$(".actions-container .messages .warning-message #linkToErrorModal");
        if (link.length) {
          link.off("click");
        }
        self.updateFooterMessage({
          message: "There are unsaved changes on the page.",
          warning: true
        });
        self.isFormElementsChanged = true;
      }
      function resetSharedCollection() {
        const ipTargetingModel = self.sharedCollection.filter(model => model.get("dimension_code") === "IPTA");
        self.sharedCollection.remove(ipTargetingModel, {
          silent: true
        });
      }
      function successHandler(response) {
        if (response.targets) {
          handleIPTargets(response.targets);
        } else {
          zipcodeinfo = {
            filename: fileName,
            action: action,
            count: response.entities.count
          };
          self.zipcodeinfo = zipcodeinfo;
          T1.EventHub.publish("uploadzipcodes", zipcodeinfo);
          self.zipcodeUploading = false;
          self.syncUploadRestrictionFieldWithIconVals();
          self.showHidePostalExcludeIncludeIcons(true);
        }
        progressBar("none");
        self.$(".text-input").removeClass("error");
      }
      function errorHandler(args) {
        if (args) {
          const error = JSON.parse(args);
          const errorModal = self.$("#errorModal");
          self.updateFooterMessage({
            warning: true,
            message: `${error.meta.count} invalid IP addresses detected. ` + `See invalid IP addresses <a href="#" id="linkToErrorModal">here.</a>`
          });
          self.$(".text-input").removeClass("error");
          progressBar("none");
          const link = self.$(".actions-container .messages .warning-message #linkToErrorModal");
          link.on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            const grid = errorModal.find("#ipTargetingErrorGrid")[0];
            grid.data = generateIPTargetingGridData(error.errors);
            errorModal[0].show();
            grid.$.viewport.scrollToIndex(0);
          });
          self.zipcodeUploading = false;
          return;
        }
        zipcodeinfo = {
          filename: fileName,
          action: action,
          count: 0
        };
        self.zipcodeinfo = zipcodeinfo;
        T1.EventHub.publish("uploadzipcodes", zipcodeinfo);
        self.zipcodeUploading = false;
        progressBar("none");
      }
      function generateIPTargetingGridData(errors) {
        var data = [];
        Object.keys(errors).forEach(function (key) {
          data.push({
            ip: key,
            row: errors[key][1],
            reason: errors[key][0]
          });
        });
        return data;
      }
      function uploadFile() {
        self.$(".validate_only").val("1");
        self.$(".postal-codes .icon").removeClass("active");
        self.$(".postal-codes .include").addClass("active");
        progressBar("block");
        self.fileUploader.upload({
          onSuccess: successHandler,
          onError: errorHandler
        });
      }
    },
    initLayout: function () {
      var self = this;
      const {
        collection: collection,
        hyperLocalCollection: hyperLocalCollection,
        hyperLocalTargeting: hyperLocalTargeting,
        sharedCollection: sharedCollection,
        strategy: strategy
      } = this;
      var selectOptions = [{
        text: "Country/Region/City",
        value: "REGN"
      }, {
        text: "DMA",
        value: "DMAX"
      }];
      if (strategy.get("supply_type") !== "BATCH") {
        selectOptions.push({
          text: "Geofence",
          value: "hyperlocal"
        });
      }
      const filterClass = "locationTargets";
      const filterCallback = function (term) {
        const target = $(this.el).find(`.${filterClass}`).val();
        const searchInputFilterConfig = {
          searchTerm: term,
          inputBox: this.inputBox,
          event: this.event,
          inputParent: this.inputParent,
          context: this
        };
        if (Utils.validateSearchFilterMinCharacter(searchInputFilterConfig)) {
          return;
        }
        T1.EventHub.publish("panel:selectSearchCategoy", {
          type: target
        });
        if (target !== "hyperlocal") {
          collection.trigger("search", {
            term: term,
            type: target
          });
        } else {
          hyperLocalCollection.trigger("search", {
            term: term
          });
        }
      };
      const clearFilterCallback = function (empty, opts) {
        if (opts.id === "hyperlocal") {
          hyperLocalCollection.trigger("search.clear");
        } else {
          collection.trigger("search.clear");
        }
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panelLayout,
        partials: {
          info_box: info_box
        },
        layout: {
          ".filters-c": [{
            module: "shared",
            viewType: "filter",
            options: {
              collection: collection,
              hyperLocalCollection: hyperLocalCollection,
              filterClass: filterClass,
              selectOptions: selectOptions,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback
            }
          }],
          ".browser-c": [{
            module: "targeting/location",
            viewType: "browse",
            options: {
              collection: collection,
              hyperLocalTargeting: hyperLocalTargeting,
              sharedCollection: sharedCollection,
              strategy: strategy
            }
          }],
          ".bins-column": [{
            module: "targeting/location",
            viewType: "bins",
            options: {
              strategy: strategy,
              sharedCollection: sharedCollection,
              filterConfigs: {
                filterOptions: selectOptions,
                filterClass: filterClass
              },
              collection: collection,
              hyperLocalTargeting: hyperLocalTargeting,
              partialCollection: self.partialCollection,
              ipCodesInfo: self.ipCodesInfo,
              ipTargetingAttachments: self.nemoIPAttachments
            }
          }]
        },
        serialize: function () {
          return {
            msgForSaveDefaultLocation: {
              infoBoxMessageLines: ["Your default Location targeting settings have been updated and will be applied " + "to all future strategies unless modified.", "&nbsp;NOTE: Does not include Postal Codes."],
              infoMessageCanClose: true,
              infoBoxClassName: "save-defaultlocation-info"
            }
          };
        }
      });
    },
    getPostObject: function () {
      var stratTargetDims = this.strategyTargetDimensions;
      var targetDims = stratTargetDims.get(targetDimensionKey);
      this.ensureLocationTargetDimensions(targetDims);
      return stratTargetDims.buildPostObj();
    },
    disableEnableSaveDefaultButton: function () {
      var postObj = this.getPostObject();
      const saveDefaultButton = this.$(".save-location-default");
      if (postObj && _.isEmpty(postObj) !== true) {
        saveDefaultButton.removeClass("disabled");
      } else {
        saveDefaultButton.removeClass("disabled").addClass("disabled");
      }
    },
    disableSaveButtons: function () {
      this.$(".save").addClass("disabled").css("pointer-events", "none");
      this.$(".saveAndContinue").addClass("disabled").css("pointer-events", "none");
    },
    enableSaveButtons: function () {
      setTimeout(() => {
        this.$(".save").removeClass("disabled").css("pointer-events", "");
        this.$(".saveAndContinue").removeClass("disabled").css("pointer-events", "");
      }, timeout);
    },
    saveDefaultLocations: function (e) {
      this.preventDefault(e);
      if (this.$(".save-location-default").hasClass("disabled")) {
        return;
      }
      const postObj = this.getPostObject();
      const validationResult = this.defaultLocationPersister.validatePostObj(postObj);
      if (validationResult.success === true) {
        const convertResult = this.defaultLocationPersister.convertToPrefText(postObj);
        this.defaultLocationPersister.saveSettings(convertResult.include, convertResult.exclude, $.proxy(function onSuccess() {
          const delay = 200;
          setTimeout(function () {
            T1.EventHub.publish("defaultLocationSaved");
          }, delay);
        }), this);
        this.toggleSaveLocationInfo(true);
      } else {
        this.showSaveDefaultLocationAlert(validationResult.category);
      }
    },
    launchSaveDefaultLocationKbPage: function (e) {
      window.open("https://kb.mediamath.com/wiki/display/t1kb/Location+Targeting");
      this.preventDefault(e);
    },
    hideSaveDefaultLocationInfoBox: function (e) {
      this.preventDefault(e);
      this.toggleSaveLocationInfo(false);
    },
    toggleSaveLocationInfo: function (toShow) {
      var needToShow = toShow === true;
      const animTime = 20;
      this.$(".save-defaultlocation-info")[needToShow ? "show" : "hide"](animTime);
      this.$(".list-body")[needToShow ? "addClass" : "removeClass"]("info-message-on");
    },
    ensureLocationTargetDimensions: function (targetDims) {
      var locationCodes = ["REGN", "DMAX", "USZC", "hyperlocal", "IPTA"];
      const groupedTargetValues = _.groupBy(this.sharedCollection.toJSON(), model => model.dimension_code);
      for (let i = 0; i < locationCodes.length; i++) {
        const dimCode = locationCodes[i];
        let targetDimension = targetDims.get(dimCode);
        if (targetDimension === undefined) {
          targetDims.add({
            INCLUDE_OP: "OR",
            EXCLUDE_OP: "AND",
            target_value: [],
            code: dimCode,
            id: dimCode
          });
          targetDimension = targetDims.get(dimCode);
        }
        targetDimension.set({
          target_value: groupedTargetValues[dimCode] || []
        });
      }
    },
    deleteIPAttachments: function (success) {
      this.nemoIPAttachments.isPaginated = false;
      this.nemoIPAttachments.processAjaxResponse = function (resp) {
        return {
          jsonData: resp,
          statusCode: "ok"
        };
      };
      this.nemoIPAttachments.sync("DELETE", {
        data: {}
      }, {
        params: {
          dimension_code: "IPTA",
          strategy_id: this.strategy.id
        },
        httpAction: "delete",
        dataType: "json",
        success: success,
        processAjaxResponse: this.nemoIPAttachments.processAjaxResponse
      });
    },
    saveIPAttachemnts: function () {
      var self = this;
      var postObj = {
        "target.id": this.nemoIPTargets.models[0].get("list") || [],
        operator: "OR",
        restriction: this.ipCodesInfo.action,
        strategy_id: this.strategy.id
      };
      this.nemoIPAttachments.fetchOptions = {};
      this.nemoIPAttachments.isPaginated = false;
      this.nemoIPAttachments.processAjaxResponse = function (resp) {
        return {
          jsonData: resp,
          statusCode: "ok"
        };
      };
      this.nemoIPAttachments.sync("POSTJSON", {
        data: postObj
      }, {
        stringify: true,
        sendData: true,
        dataType: "json",
        processAjaxResponse: this.nemoIPAttachments.processAjaxResponse,
        success: function ({
          data: data
        }) {
          self.nemoIPTargets = new NemoTargets();
          _.each(data, function (model) {
            model.count = data.length;
          });
          self.nemoIPAttachments.reset(data, {
            silent: true
          });
          self.nemoIPAttachments.count = data.length;
          self.ipCodesInfo.filename = "ipList.csv";
          self.ipCodesInfo.showURL = true;
          T1.EventHub.publish("uploadIPTargets", self.ipCodesInfo);
          T1.EventHub.publish("panel:updateIPTargetingAttachments", self);
          T1.EventHub.publish("clearUploadForm");
        }
      });
    },
    postData: function (formValues, successCallback, invalidCallback) {
      var self = this;
      var stratTargetDims = this.strategyTargetDimensions;
      var targetDims = stratTargetDims.get(targetDimensionKey);
      function onSuccess() {
        var deferred = $.Deferred();
        const $form = self.$(".zipcode-upload");
        function successHandler() {
          deferred.resolve();
        }
        function errorHandler() {
          deferred.reject();
        }
        if (self.changedZipCodes) {
          $(".validate_only", $form).val("0");
          self.fileUploader.upload({
            dontSaveIP: true,
            sourceURL: self.zipcodeUrl,
            onSuccess: successHandler,
            onError: errorHandler
          });
        } else {
          deferred.resolve();
        }
        if (self.ipCodesInfo) {
          if (self.ipCodesInfo.deleteAttachments || self.nemoIPAttachments.length && self.nemoIPTargets.length) {
            self.deleteIPAttachments(function () {
              if (self.ipCodesInfo.action && self.ipCodesInfo.filename) {
                self.saveIPAttachemnts();
              } else {
                self.nemoIPAttachments.reset([]);
              }
              self.ipCodesInfo.deleteAttachments = undefined;
            });
          } else if (self.nemoIPTargets.length) {
            self.saveIPAttachemnts();
          }
        }
        deferred.done(function () {
          stratTargetDims.fetch({
            success: function () {
              self.partialCollection = stratTargetDims.get("partial");
              self.isFormElementsChanged = false;
              if (self.changedZipCodes) {
                const postalcodeDim = targetDims.get("USZC");
                if (postalcodeDim) {
                  self.sharedCollection.add(postalcodeDim.get("target_value").toJSON(), {
                    silent: true
                  });
                  T1.EventHub.publish("uploadzipcodes");
                  T1.EventHub.publish("clearzipcodes");
                }
                self.changedZipCodes = false;
              }
              self.ensureLocationTargetDimensions(self.strategyTargetDimensions.get("target_dimensions"));
              self.showHidePostalExcludeIncludeIcons(false);
              T1.EventHub.publish("strategy-create-edit:reloadSubViews");
              T1.EventHub.publish("location-targeting:updateTempColl");
              self.strategy.fetch({
                success: function () {
                  successCallback();
                }
              });
            }
          });
        });
        deferred.fail(function () {
          invalidCallback([]);
        });
      }
      this.ensureLocationTargetDimensions(targetDims);
      if (this.zipcodecount !== undefined && this.zipcodecount === "0") {
        invalidCallback([], "All targeted postal codes are invalid.");
      } else {
        if (!this.wipeTargetValues) {
          T1.EventHub.publish("panel:saveTargetValues");
          this.hyperLocalTargeting.saveHyperLocalAttachments(this.strategy).then(function () {
            stratTargetDims.saveTargetValues(onSuccess, invalidCallback, undefined, undefined, self);
          });
        } else {
          stratTargetDims.saveTargetValues(onSuccess, invalidCallback, undefined, undefined, this);
        }
      }
    },
    showSaveDefaultLocationAlert: function () {
      var alertDialog = $("<div></div>");
      this.renderPartialTemplate({
        template: alert_tmpl,
        data: {
          header: "Too Many Defaults Selected",
          message: "You may only include and/or exclude up to 100 locations as your geo-targeting default."
        },
        useTemplateHtml: false,
        targetEl: alertDialog
      });
      $(alertDialog).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: "w-WarningAlert",
        closeOnEscape: false,
        open: function () {
          var btn = $(".ui-button-text-only");
          btn.blur();
        },
        buttons: [{
          text: "Close",
          class: "cancel",
          click: function () {
            $(this).dialog("close");
          }
        }],
        close: function () {
          $(this).remove();
          $(this).dialog("destroy");
        }
      });
    }
  });
  return T1.Form.ViewUtils.extend(LocationTargetingView);
});
