define('T1TabButton',["jQuery", "Underscore", "Mustache", "T1", "text!templates/tabbutton.html", "jQueryUI"], function ($, _, Mustache, T1, template) {
  T1.TabButton = function (selector, config, context) {
    var localConfig = $.extend(true, {}, config);
    var name;
    var $target;
    var $templateFragment;
    var data;
    var buttonConfig;
    if (!selector) {
      return;
    }
    $target = context ? $(context).find(selector) : $(selector);
    $target.addClass("w-TabButton");
    name = selector.split(".")[1];
    _.each(localConfig.buttons, function (value, key, list) {
      data = {};
      data.id = key;
      data.name = name;
      data.value = value.dataValue;
      data.isChecked = value.checked !== undefined ? value.checked : false;
      data.isDisabled = value.disabled !== undefined ? value.disabled : false;
      data.hasDataBind = value.dataBind !== undefined ? value.dataBind : false;
      data.dataBind = value.dataBind;
      buttonConfig = {};
      if (value.label) {
        buttonConfig.label = value.label;
        data.label = value.label;
      }
      if (value.icon) {
        buttonConfig.icons = {
          primary: value.icon
        };
      }
      $templateFragment = $(Mustache.to_html(template, data));
      $target[localConfig.action || "append"]($templateFragment);
      $target.find("#" + key).button(buttonConfig);
    });
    $target.buttonset();
    if (localConfig.disabled !== undefined) {
      if (localConfig.disabled) {
        $target.addClass("disabled");
      }
    }
  };
});
