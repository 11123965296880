define('modules/vendor/views/autoList',["jQuery", "Underscore", "T1", "T1View", "text!../templates/auto_list.html", "text!templates/adx_error_display.html", "collections/vendors", "utils/CreativeUtils"], function ($, _, T1, T1View, template, adxErrorPartial, VendorCollection, CreativeUtils) {
  return T1View.extend({
    template: template,
    partials: {
      adxErrorDisplay: adxErrorPartial
    },
    dataEvents: {
      collection: {
        reset: "updateBody"
      }
    },
    tipConfig: {
      getExternalTip: true
    },
    initialize: function (args) {
      if (args.collection) {
        this.collection = args.collection;
      } else {
        this.collection = new VendorCollection();
      }
      this.showVendorTooltip = args.showVendorTooltip || false;
      this.isEdit = args.isEdit;
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        if (self.showVendorTooltip) {
          T1.Tooltip("body", {
            fade: false,
            tooltipEle: self.el.find(".t1-tooltip").not(".adx-warning, .adx-warning-list, .adx-error, .adx-error-list")
          });
          T1.Tooltip("body", {
            fade: false,
            tooltipEle: self.el.find(".adx-warning, .adx-warning-list"),
            className: "adx-warning-tipsy"
          });
          T1.Tooltip("body", {
            fade: false,
            tooltipEle: self.el.find(".adx-error, .adx-error-list"),
            className: "adx-error-tipsy"
          });
        }
      });
    },
    updateBody: function () {
      this.load();
    },
    serialize: function () {
      var modelJSON = this.model && this.model.toJSON();
      var output = {
        showVendorTooltip: this.showVendorTooltip,
        isEdit: this.isEdit
      };
      $.extend(output, modelJSON || {});
      output.autoVendors = _.pluck(this.collection.toJSON(), "name").join(", ");
      output.dataID = modelJSON && modelJSON.id;
      output.error_vendors = CreativeUtils.parseVendorListForDisplay(modelJSON && modelJSON.adxErrorVendors || {});
      output.vendorMessage = "Auto-Vendor detection will occur after hitting save. Please return to manually declare any that may have been missed.";
      return output;
    }
  });
});
