define('utils/SearchBuilder',["jQuery", "Underscore", "T1"], function ($, _, T1) {
  return function SearchBuilder(config, defaultParams) {
    var data = {};
    var defaults = [];
    var key, minorKey, origData;
    if (defaultParams) {
      if (!defaultParams.indexOf) {
        defaults = [defaultParams];
      }
    }
    if (config) {
      data = config;
      for (key in data) {
        if (T1.Utils.hasOwnProp(data, key)) {
          for (minorKey in data[key]) {
            if (T1.Utils.hasOwnProp(data[key], minorKey)) {
              origData = data[key][minorKey];
              data[key][minorKey] = {
                flag: false,
                data: origData,
                extras: {}
              };
            }
          }
        }
      }
    }
    function addGroup(group) {
      if (!data[group]) {
        data[group] = {};
      }
      return this;
    }
    function addFlag(group, flag, params) {
      addGroup(group);
      data[group][flag] = {
        flag: false,
        data: params,
        extras: {}
      };
      return this;
    }
    function setFlag(group, flag, extras) {
      this.clearGroupFlags(group);
      data[group][flag].flag = true;
      if (extras) {
        data[group][flag].extras = extras;
      }
      return this;
    }
    function clearFlag(group, flag) {
      if (data[group][flag] && data[group]) {
        data[group][flag].flag = false;
        data[group][flag].extras = {};
      } else {
        throw new Error(`Could not find group -> flag${group},${flag}`);
      }
    }
    function clearGroupFlags(group) {
      var i;
      var groupObj = data[group];
      for (i in groupObj) {
        if (T1.Utils.hasOwnProp(groupObj, i)) {
          groupObj[i].flag = false;
        }
      }
    }
    function getGroupFilter(group) {
      var output = [];
      var groupObj = data[group];
      var i, tempObj;
      for (i in groupObj) {
        if (T1.Utils.hasOwnProp(groupObj, i)) {
          if (groupObj[i].flag) {
            tempObj = $.extend({}, groupObj[i].data, groupObj[i].extras);
            output.push(tempObj);
            break;
          }
        }
      }
      output = output.concat(defaults);
      return output;
    }
    function getAllFilters() {
      var groupKey;
      var output = [];
      for (groupKey in data) {
        if (T1.Utils.hasOwnProp(data, groupKey)) {
          output = output.concat(this.getGroupFilter(groupKey));
        }
      }
      output = output.concat(defaults);
      output = _.uniq(output);
      return output;
    }
    return {
      addGroup: addGroup,
      addFlag: addFlag,
      setFlag: setFlag,
      clearFlag: clearFlag,
      clearGroupFlags: clearGroupFlags,
      getGroupFilter: getGroupFilter,
      getAllFilters: getAllFilters
    };
  };
});
