define('modules/media/pmp-e/helpers/parseDeals',[], function () {
  var gridDataObj = {
    getDeals: function (sendArray, supply_obj) {
      var newGridArray = [];
      let end_datetime, start_datetime;
      const dealTypeMap = Object.freeze({
        PG: "Programmatic Guaranteed",
        STANDARD: "PMP-E"
      });
      for (let i = 0; i < sendArray.length; i++) {
        start_datetime = new Date(sendArray[i].start_datetime);
        end_datetime = new Date(sendArray[i].end_datetime);
        newGridArray.push({
          name: sendArray[i].name,
          id: sendArray[i].deal_identifier,
          supply_source_id: sendArray[i].supply_source_id,
          supply_source_name: supply_obj[sendArray[i].supply_source_id],
          start: `${start_datetime.getMonth() + 1}/${start_datetime.getDate()}/${start_datetime.getFullYear()}`,
          end: `${end_datetime.getMonth() + 1}/${end_datetime.getDate()}/${end_datetime.getFullYear()}`,
          price: sendArray[i].price.value ? sendArray[i].price.value : 0,
          status: sendArray[i].status,
          description: sendArray[i].description,
          publisher_id: sendArray[i].sub_supply_source_id,
          advertiser_id: sendArray[i].owner.id,
          currency_code: sendArray[i].price.currency_code,
          deal_source: sendArray[i].partner_source,
          end_datetime: sendArray[i].end_datetime,
          price_method: sendArray[i].price_method,
          price_type: sendArray[i].price_type,
          start_datetime: sendArray[i].start_datetime,
          zone_name: sendArray[i].zone_name,
          deal_id: sendArray[i].id,
          price_display: sendArray[i].price_display,
          deal_type: dealTypeMap[sendArray[i].deal_type]
        });
      }
      return newGridArray;
    }
  };
  return gridDataObj;
});
