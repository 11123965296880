define('collections/t1AppTerms',["jQuery", "Underscore", "T1", "T1Collection", "models/t1AppTerms", "T1Sortable"], function ($, _, T1, T1Collection, Model) {
  "use strict";

  T1Collection.extend({
    apiRoot: COMPASS_ENV.API_APPS_ROOT,
    canCache: false,
    cacheDuration: 6e4,
    model: Model,
    urlString: ""
  });
  return T1.Models.T1AppTerms;
});
