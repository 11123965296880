define('models/t1AppTerms',["Underscore", "T1", "T1Model", "T1Comm"], function (_, T1, T1Model, T1Comm) {
  "use strict";

  T1.Models.T1AppTerms = T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    dataType: "json",
    url: "",
    fetch: function (options) {
      var self = this;
      var sourceURL = T1.API_APPS_ROOT + "v1.0/terms";
      if (options.appID) {
        sourceURL += "?q=app_id==" + options.appID;
      }
      return T1Comm.get({
        dataType: "json",
        processAjaxResponse: self.processAjaxResponse,
        onSuccess: options.success,
        sourceURL: sourceURL
      });
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.T1AppTerms;
});
