define('modules/audiences/pixels/views/bulkEmailModal',["jQuery", "Underscore", "T1", "T1View", "text!../templates/buldEmailModal.html", "models/pixelEmail"], function ($, _, T1, T1View, tmpl, PixelEmail) {
  return T1View.extend({
    template: tmpl,
    events: {
      "keydown .text-input.click-thru": "handleKey",
      "changed .to-field": "handleEmailChange"
    },
    eventhubEvents: {
      "buldEmailModal:show": "show"
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.modal = self.el;
        self.modal.dialog({
          autoOpen: false,
          modal: true,
          dialogClass: "w-customAlert pixels-export-email-modal",
          buttons: [{
            text: "Send Email",
            class: "save",
            click: $.proxy(self.sendEmail, self)
          }, {
            text: "Cancel",
            class: "cancel",
            click: $.proxy(self.hide, self)
          }]
        });
      });
    },
    unload: function () {
      if (this.modal) {
        this.modal.remove();
        this.modal.dialog("destroy");
        this.modal = null;
      }
    },
    show: function (data) {
      var pixelsEl = data.pixels_checked;
      var self = this;
      this.pixels = [];
      this.el.find("input[type=text]").val("");
      this.modal.dialog("open");
      $.each(pixelsEl, function (index, value) {
        self.pixels.push(data.collection.get($(value).data("id")).toJSON());
      });
      this.reload();
    },
    hide: function () {
      this.hideError();
      this.modal.dialog("close");
    },
    formatURL: function (url) {
      return url.replace("http", "https");
    },
    showError: function (errors) {
      this.options.hasError = true;
      this.options.errors = errors;
      this.el.find("input[type=text]").addClass("error");
      this.renderPartial(".error-wrapper");
    },
    hideError: function () {
      this.options.hasError = false;
      this.options.errors = "";
      this.el.find("input[type=text]").removeClass("error");
      this.renderPartial(".error-wrapper");
      this.el.find("strand-input").removeAttr("error original-title");
      $(".footer-message, .tipsy").remove();
    },
    handleKey: function (e) {
      if (e.keyCode === 13) {
        this.sendEmail();
      }
    },
    handleEmailChange: function (e) {
      if (e.target.error) {
        $(e.target).removeAttr("error original-title");
        $(".tipsy").remove();
      }
    },
    sendEmail: function () {
      var self = this;
      var email_data = {};
      var $to_field = $(".to-field");
      var to_emails = $to_field.val().split(",");
      self.el.parent().find(".ui-dialog-buttonpane .save").attr("disabled", "disabled");
      self.el.parent().find(".ui-dialog-buttonpane .save").addClass("disabled");
      $to_field.removeAttr("error");
      $.each(this.pixels, function (index, value) {
        var key = index + 1;
        var name = "pixel_bundles." + key + ".";
        var pixel_id = value.id;
        email_data[name + "id"] = pixel_id;
      });
      $.each(to_emails, function (index, value) {
        var key = index + 1;
        var name = "emails." + key + ".";
        email_data[name + "address"] = value.trim();
      });
      email_data.message = $(".pixels-export-email-modal").find(".email-message").val();
      email = new PixelEmail();
      email.save(email_data, {
        success: function () {
          T1.Notify("message", "Pixel code(s) was sent successfully");
          self.hide();
          self.el.parent().find(".ui-dialog-buttonpane .save").removeAttr("disabled");
          self.el.parent().find(".ui-dialog-buttonpane .save").removeClass("disabled");
        },
        statusInvalid: function (model, response) {
          $to_field.attr("error", true);
          errorConfig = {
            tooltipEle: $to_field,
            trigger: "manual",
            getExternalTip: false,
            className: "w-toToolTipError",
            gravity: "s",
            offset: 21
          };
          T1.Tooltip($to_field, errorConfig);
          $to_field.attr("title", model[0].message).tipsy("show");
          self.el.parent().find(".ui-dialog-buttonpane .save").attr("disabled", false);
          self.el.parent().find(".ui-dialog-buttonpane .save").removeClass("disabled");
          var message = self.el.parent().find(".messages");
          var removeError;
          message.find(".error-message div").text("There are errors on page");
          $div_error = $("<div></div>", {
            class: "footer-message",
            html: message
          });
          self.el.parent().find(".ui-dialog-buttonpane").prepend($div_error);
          $div_error.find(".messages").show();
          removeError = function (e) {
            $div_error.remove();
            $to_field.off("keydown", removeError);
          };
          $to_field.on("keydown", removeError);
        }
      });
    },
    serialize: function () {
      return {
        pixels: this.pixels
      };
    }
  });
});
