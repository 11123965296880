define('modules/reporting/campaigns/views/select_site_filter',["jQuery", "Hogan", "T1", "T1View", "T1Permissions", "text!../templates/select_site_filter.html", "text!templates/filter_list_footer.html", "jQPlugins/perfect-scrollbar"], function ($, Hogan, T1, T1View, T1Permissions, template, footerTemplate) {
  "use strict";

  var documentEvents = "mousedown.siteFilter keyup.siteFilter";
  var $document = $(document);
  return T1View.extend({
    template: template,
    partials: {
      footer: footerTemplate
    },
    siteFilterUIOpen: false,
    columnData: {
      rangeList: [{
        value: "h10",
        text: "Highest 10"
      }, {
        value: "h20",
        text: "Highest 20"
      }, {
        value: "h50",
        text: "Highest 50"
      }, {
        value: "h100",
        text: "Highest 100"
      }, {
        value: "l10",
        text: "Lowest 10"
      }, {
        value: "l20",
        text: "Lowest 20"
      }, {
        value: "l50",
        text: "Lowest 50"
      }, {
        value: "l100",
        text: "Lowest 100"
      }],
      metricList: []
    },
    selectedRange: "",
    selectedMetric: "",
    excludeZeros: "",
    events: {
      "click .exclude-zero-values strand-checkbox": "excludeZeroHandler",
      "click .ui-cancel": "cancel",
      "click .ui-submit": "applyAction",
      "mousedown .head-label": "toggle",
      "mousedown .w-SiteFilter": "stopBubbling",
      "mouseout li": "itemOut",
      "mouseover li": "itemOver",
      "mouseup .metric-list li": "metricItemClick",
      "mouseup .range-list li": "rangeItemClick"
    },
    initialize: function (args) {
      var reportModel, siteFilterMetric, siteFilterRange;
      this.models = args.models;
      this.metadata = args.models.reportMeta;
      this.meta = $.extend({}, this.metadata.toJSON());
      this.campaign = args.models.campaign;
      reportModel = this.models.reportModel;
      siteFilterRange = reportModel.get("siteFilterRange");
      siteFilterMetric = reportModel.get("siteFilterMetric");
      if (siteFilterMetric) {
        if (siteFilterMetric.charAt(0) === "-") {
          this.selectedRange = `h${siteFilterRange}`;
          this.selectedMetric = siteFilterMetric.substr(1);
        } else {
          this.selectedRange = `l${siteFilterRange}`;
          this.selectedMetric = siteFilterMetric;
        }
        this.excludeZeros = reportModel.get("siteFilterExcludeZeros");
      } else {
        this.selectedRange = "h50";
        this.selectedMetric = "total_spend";
        this.excludeZeros = "false";
      }
      this.initList();
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.initializeUI();
      });
    },
    unload: function () {
      $document.off(documentEvents);
      this.wBody = null;
      this.headerLabel = null;
      this.siteFilterUIOpen = null;
    },
    initList: function () {
      var metrics = $.extend([], this.meta.metricOptions);
      var metric;
      var metricList = [];
      while (metrics.length) {
        metric = metrics.shift();
        metricList.push({
          value: metric.value,
          text: metric.text
        });
      }
      this.columnData.metricList = metricList;
    },
    updateList: function () {
      var defaultKey;
      var columnData = this.columnData;
      var rangeList = columnData.rangeList;
      var metricList = columnData.metricList;
      defaultKey = this.selectedRange;
      for (let i = 0; i < rangeList.length; i++) {
        rangeList[i].selected = rangeList[i].value === defaultKey;
      }
      defaultKey = this.selectedMetric;
      for (let i = 0; i < metricList.length; i++) {
        metricList[i].selected = metricList[i].value === defaultKey;
      }
      this.tempRange = this.tempMetric = this.tempExcludeZeros = null;
    },
    initializeUI: function () {
      var el = $(this.el);
      this.wBody = el.find(".w-body");
      this.headerLabel = el.find(".head-label");
      this.metricList = el.find(".metric-list");
      this.updateCurrentText();
    },
    stopBubbling: function (e) {
      e.stopPropagation();
    },
    toggle: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.siteFilterUIOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open: function () {
      var metricItemPos, metricList;
      var self = this;
      var el = $(this.el);
      const animTime = 200;
      const tabKey = 9;
      const escapeKey = 27;
      const offset = 192;
      const offset2 = 100;
      $document.mousedown();
      this.updateList();
      this.wBody.fadeIn(animTime).removeClass("no-display");
      this.renderPartial(".w-body");
      this.rangeList = el.find(".range-list");
      metricList = this.metricList = el.find(".metric-list");
      metricList.perfectScrollbar("destroy");
      metricItemPos = metricList.find(".selected").position();
      if (metricItemPos && metricItemPos.top > offset) {
        metricList.scrollTop(offset2);
      }
      metricList.perfectScrollbar();
      this.headerLabel.addClass("white-bg");
      this.siteFilterUIOpen = true;
      $document.one(documentEvents, function (e) {
        if (e.keyCode === tabKey || e.keyCode === escapeKey || !e.keyCode) {
          self.close();
        }
      });
    },
    close: function () {
      this.metricList.perfectScrollbar("destroy");
      this.headerLabel.removeClass("white-bg");
      this.wBody.hide();
      this.siteFilterUIOpen = false;
      $document.off(documentEvents);
    },
    itemOver: function (e) {
      $(e.currentTarget).addClass("highlighted");
    },
    itemOut: function (e) {
      $(e.currentTarget).removeClass("highlighted");
    },
    rangeItemClick: function (e) {
      var cTarget = $(e.currentTarget);
      this.rangeList.find(".selected").removeClass("selected");
      cTarget.addClass("selected");
      this.tempRange = cTarget.data("id");
    },
    metricItemClick: function (e) {
      var cTarget = $(e.currentTarget);
      this.metricList.find(".selected").removeClass("selected");
      cTarget.addClass("selected");
      this.tempMetric = cTarget.data("id");
    },
    getSelectedText: function (list, value) {
      var i, result;
      var len = list.length;
      for (i = 0; i < len; i++) {
        if (list[i].value === value) {
          result = list[i].text;
          break;
        }
      }
      return result;
    },
    updateCurrentText: function () {
      var rangeText = this.getSelectedText(this.columnData.rangeList, this.selectedRange);
      var metricText = this.getSelectedText(this.columnData.metricList, this.selectedMetric);
      this.updateDataBind({
        currentFilterText: `${rangeText} ${metricText}`
      }, this.el);
    },
    excludeZeroHandler: function (event) {
      this.tempExcludeZeros = String(event.currentTarget.checked);
    },
    cancel: function () {
      $document.mousedown();
    },
    applyAction: function () {
      var filterRange = "";
      var filterOrder = "";
      $document.mousedown();
      if (this.tempRange !== null || this.tempMetric !== null || this.tempExcludeZeros !== null) {
        this.selectedRange = this.tempRange || this.selectedRange;
        this.selectedMetric = this.tempMetric || this.selectedMetric;
        this.excludeZeros = this.tempExcludeZeros || this.excludeZeros;
        this.updateCurrentText();
        filterRange = this.selectedRange.substr(1);
        if (this.selectedRange.charAt(0) === "h") {
          filterOrder = "-";
        }
        T1.EventHub.publish("select:siteFilter", {
          excludeZeros: this.excludeZeros,
          metric: filterOrder + this.selectedMetric,
          range: filterRange
        });
      }
    },
    render: function () {
      var tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      var partials = this.partials || {};
      var deferred = $.Deferred();
      var $el = this.el;
      if (!this.compiledTemplate) {
        this.compiledTemplate = Hogan.compile(this.template);
      }
      tdata.appLocation = T1.Location.get();
      this.assignAsCurrency(tdata);
      $el.html(this.compiledTemplate.render(tdata, partials));
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    renderPartial: function (selector) {
      var tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      var partials = this.partials || {};
      var render = this.compiledTemplate.render(tdata, partials);
      var partialContent = $(render).find(selector).html();
      this.el.find(selector).html(partialContent);
    },
    serialize: function () {
      return {
        currentFilterText: "",
        isChecked: T1.Utils.valueToBool(this.excludeZeros),
        label: "Include only sites with:",
        siteFilterMetricList: this.columnData.metricList,
        siteFilterRangeList: this.columnData.rangeList,
        submit_label: "Apply"
      };
    }
  });
});
