define('modules/targeting/siteList/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "targeting/siteList",
    viewCfgs: {
      panel: {
        strategy: null
      },
      bins: {
        sharedCollection: null
      }
    },
    defaultView: "panel"
  });
});
