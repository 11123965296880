define('modules/reporting/segments/dataExport/createEdit/overlap/views/list',["jQuery", "T1", "T1View", "Mustache", "text!../templates/list.html"], function ($, T1, T1View, T, template) {
  "use strict";

  return T1View.extend({
    template: template,
    fetchRequired: true,
    segmentData: [],
    events: {
      "click .segmentsA": "includeClickHandler",
      "click .segmentsB": "includeClickHandler",
      "click .show-more-segments": "loadSegments"
    },
    eventhubEvents: {
      clearOverlapReport: "clearBins",
      unloadSegmentsReports: "unload"
    },
    initialize: function (args) {
      this.collection = args.sharedCollection;
      this.model = args.model;
      this.model.overlapSegmentsA = [];
      this.model.overlapSegmentsB = [];
      this.collection.pageOffset = 0;
      this.collection.pageLimit = 100;
      this.pageNum = 1;
      this.segmentData = [];
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.grid = self.el[0].querySelector("#strandGridOverlap");
        self.loadSegments();
      });
    },
    loadSegments: function () {
      var self = this;
      this.collection.sortBy = "-created_on";
      this.collection.with = "advertiser";
      this.grid.isLoading = true;
      if (this.pageNum > 1) {
        self.grid.splice("data", self.grid.data.length - 1, 1);
      }
      this.collection.fetch({
        onSuccess: function (response) {
          var parsedResponse = T1.Utils.parseXMLtoJson(response);
          var numOfPages = Math.ceil(parsedResponse.entities.count / self.collection.pageLimit);
          var segments = [];
          if (parsedResponse.entities.entity instanceof Array) {
            segments = parsedResponse.entities.entity;
          } else if (parsedResponse.entities.entity instanceof Object) {
            segments[0] = parsedResponse.entities.entity;
          }
          $.each(segments, function (index, seg) {
            seg.advertiserName = seg.entity.name;
            seg.lastSegment = false;
            seg.segmentsAState = seg.segmentsAState || "deselected";
            seg.segmentsBState = seg.segmentsBState || "deselected";
          });
          self.segmentData = self.segmentData.concat(segments);
          if (numOfPages > self.pageNum) {
            self.segmentData.push({
              lastSegment: true
            });
          }
          self.grid.set("data", self.segmentData);
          self.pageNum++;
          self.collection.pageOffset = self.collection.pageOffset + self.collection.pageLimit;
          self.grid.isLoading = false;
          self.attachRemoveSegmentClickHandler();
        }
      });
    },
    attachRemoveSegmentClickHandler: function () {
      var self = this;
      var blA = document.querySelector("#blA");
      blA.addEventListener("item-include-or-exclude", function (e) {
        var rowModel = e.detail.model;
        self.removeSegment("A", rowModel, self.model);
      });
      var blB = document.querySelector("#blB");
      blB.addEventListener("item-include-or-exclude", function (e) {
        var rowModel = e.detail.model;
        self.removeSegment("B", rowModel, self.model);
      });
    },
    removeSegment: function (binType, rowModel, model) {
      var bin;
      var segmentsType = "segments" + binType;
      var id = rowModel.id;
      if (!model) {
        model = this.model;
      }
      rowModel["segments" + binType + "State"] = "deselected";
      this.el.find("#icon" + binType + "-" + id + " > strand-icon").attr("id", "deselected");
      model.removeFromBin(segmentsType, id);
      delete rowModel.binSelected;
      model["overlapSegments" + binType] = model["overlapSegments" + binType].filter(function (obj) {
        return obj.id !== id;
      });
      bin = document.querySelector("#bl" + binType);
      bin.data = this.model["overlapSegments" + binType];
    },
    includeClickHandler: function (event) {
      var icon = event.currentTarget;
      var segmentType = icon.title;
      var gridRow = icon.closest("strand-grid-item");
      var rowModel = gridRow.model;
      var selectedSegments = this.model.getSelectedSegments();
      var blA = document.querySelector(".data-export-grid #blA");
      var blB = document.querySelector(".data-export-grid #blB");
      var oldBin;
      if (rowModel.binSelected) {
        oldBin = rowModel.binSelected;
        this.removeSegment(oldBin, rowModel);
        if (segmentType === "segments" + oldBin) {
          return;
        }
      }
      rowModel[segmentType + "State"] = "selected";
      icon.id = "selected";
      if (selectedSegments.segments_A.length + selectedSegments.segments_B.length < 10) {
        this.model.addToBin({
          binType: segmentType,
          id: rowModel.id
        });
        rowModel.entity = "segments";
        rowModel.included = true;
        if (segmentType === "segmentsA") {
          rowModel.binSelected = "A";
          this.model.overlapSegmentsA.push(rowModel);
        } else if (segmentType === "segmentsB") {
          rowModel.binSelected = "B";
          this.model.overlapSegmentsB.push(rowModel);
        }
      }
      if (segmentType === "segmentsA") {
        blA.set("data", this.model.overlapSegmentsA.slice());
      } else if (segmentType === "segmentsB") {
        blB.set("data", this.model.overlapSegmentsB.slice());
      }
    },
    clearBins: function () {
      var self = this;
      var blA = document.querySelector(".data-export-grid #blA");
      var blB = document.querySelector(".data-export-grid #blB");
      $.each(this.model.overlapSegmentsA, function (index, model) {
        self.model.removeFromBin("segmentsA", model.id);
        delete model.binSelected;
        model.segmentsAState = "deselected";
        self.el.find("#iconA-" + model.id + " > strand-icon").attr("id", "deselected");
      });
      this.model.overlapSegmentsA = [];
      if (blA) {
        blA.set("data", null);
      }
      $.each(this.model.overlapSegmentsB, function (index, model) {
        self.model.removeFromBin("segmentsB", model.id);
        delete model.binSelected;
        model.segmentsBState = "deselected";
        self.el.find("#iconB-" + model.id + " > strand-icon").attr("id", "deselected");
      });
      this.model.overlapSegmentsB = [];
      if (blB) {
        blB.set("data", null);
      }
    },
    unload: function () {
      this.clearBins();
      this.pageNum = 0;
      this.segmentData = [];
      this.collection = null;
    },
    serialize: function () {
      return {
        model: "{{model}}",
        scope: "{{scope}}",
        name: "{{model.name}}",
        advertiserName: "{{model.advertiserName}}",
        id: "{{model.id}}",
        isLastSegment: "{{model.lastSegment}}",
        isNotLastSegment: "{{!model.lastSegment}}",
        segmentsAState: "{{model.segmentsAState}}",
        segmentsBState: "{{model.segmentsBState}}"
      };
    }
  });
});
