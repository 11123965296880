define('models/termsPdfModel',["jQuery", "Underscore", "Backbone", "T1", "T1Comm", "T1Model"], function ($, _, Backbone, T1, T1Comm, T1Model) {
  "use strict";

  T1.Models.TermsPdfModel = T1Model.extend({
    dataType: "json",
    type: "multipart/form-data",
    url: function (options) {
      var url;
      if (options === "delete") {
        url = T1.API_APPS_ROOT + "v1.0/terms/";
      } else {
        url = T1.API_APPS_ROOT + "v1.0/terms/app-id/";
      }
      return url;
    },
    initialize: function (options) {
      T1Model.prototype.initialize.call(this, options);
    },
    save: function (attributes, options) {
      var postURL;
      if (options.requestType === "delete") {
        postURL = this.url("delete") + options.termsId;
      } else {
        postURL = this.url() + options.appId;
      }
      var saveOptions = {
        sourceURL: postURL,
        dataType: "json",
        onSuccess: options.success || $.noop,
        onError: options.error || $.noop,
        forcedProcessData: false,
        forcedContentType: false,
        processAjaxResponse: this.processAjaxResponse,
        data: attributes
      };
      if (options.requestType === "delete") {
        return T1Comm.deleteCall(saveOptions);
      } else {
        return T1Comm.post(saveOptions);
      }
    },
    processAjaxResponse: function (resp) {
      var jsonData;
      var statusCode;
      return {
        jsonData: resp,
        statusCode: resp.meta === "success" ? "ok" : "error"
      };
    },
    createEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred;
    }
  });
  return T1.Models.TermsPdfModel;
});
