define('T1PopoverPositionable',["jQuery"], function ($) {
  function extendView(view) {
    view.prototype.getScopeContainer = function () {
      var $body = $("body");
      var pageBody = $body.find(".page-body");
      return pageBody.length > 0 ? pageBody : $body;
    };
    view.prototype.getPopoverContainer = function (popoverSelector) {
      if (!popoverSelector) {
        throw new Error("The incoming 'popoverSelector' cannot be null or undefined.");
      }
      var targetSelector = "." + popoverSelector;
      var $container = this.getScopeContainer();
      var $el = $container.find(targetSelector);
      if ($el.length <= 0) {
        $container.append("<div class='" + popoverSelector + "'></div>");
        $el = $container.find(targetSelector);
      }
      return $el[0];
    };
    view.prototype.showPopover = function (event, viewHolder, configs, originEle) {
      if (!event) {
        throw new Error("the incoming event must be present.");
      }
      if (this.detachEvents) {
        this.detachEvents();
      }
      this.popoverConfigs = configs;
      if (this.preLoadProcessor) {
        this.preLoadProcessor.apply(this, arguments);
      }
      this.load();
      if (this.attachEvents) {
        this.attachEvents(viewHolder, configs, originEle);
      }
      if (this.positionPopover) {
        this.positionPopover(event.target, configs);
      }
      if (this.activateDismissalLogic) {
        this.activateDismissalLogic.apply(this, arguments);
      }
      this.preventDefault(event);
    };
    view.prototype.positionPopover = function (targetEl, configs) {
      var self = this;
      var $originEl;
      var originPos;
      var originWidth;
      var $el = $(self.el);
      var $doc = $(document);
      var docScrollLeft = $doc.scrollLeft();
      var $window = $(window);
      var menuHeight;
      var menuWidth;
      var winWidth = $window.width();
      var menuBottom;
      var xPositionOffset = configs.popoverXPosOffset || 0;
      var yPositionOffset = configs.popoverYPosOffset || 32;
      var fadeDuration = 300;
      var xPosPadding = configs.xPosPadding ? configs.xPosPadding : 5;
      var isBelowView = false;
      var yPosition;
      var xPosition;
      var arrowRightPosition = 0;
      var $arrowBorder;
      var $arrow;
      var arrowRightOffset = 4;
      var topLevelContainer = self.getScopeContainer();
      var topContainerPos = topLevelContainer.offset();
      if (!targetEl) {
        throw new Error("incoming element cannot be null or undefined");
      }
      $el.css("display", "none");
      menuHeight = $el.height();
      menuWidth = $el.width();
      $originEl = targetEl.innerHTML !== undefined ? $(targetEl) : targetEl;
      originPos = $originEl.offset();
      originWidth = $originEl.width();
      yPosition = originPos.top;
      xPosition = originPos.left + originWidth + 22 - menuWidth;
      xPosition = xPosition - topContainerPos.left;
      yPosition = yPosition - topContainerPos.top;
      if (xPosition + menuWidth + xPosPadding > winWidth) {
        xPosition = winWidth - menuWidth - xPosPadding;
        xPosition = xPosition + docScrollLeft;
      } else {
        if (docScrollLeft > xPosition) {
          xPosition = xPosition + (docScrollLeft - xPosition);
        }
      }
      menuBottom = yPosition + 2 * menuHeight - $doc.scrollTop() + 20;
      isBelowView = menuBottom > $window.height();
      if (configs.orientation) {
        switch (configs.orientation) {
          case "n":
            isBelowView = true;
            break;
          case "s":
            isBelowView = false;
            break;
          case "window":
            isBelowView = originPos.top + menuHeight + $originEl.height() + yPositionOffset >= $window.height();
            break;
        }
      }
      $arrowBorder = $el.find(".arrow-border");
      $arrow = $el.find(".arrow");
      arrowRightPosition = xPosition + menuWidth - (originPos.left + originWidth);
      arrowRightPosition = arrowRightPosition - arrowRightOffset;
      if (arrowRightPosition < 0) {
        arrowRightPosition = 0;
      }
      if (arrowRightPosition > menuWidth - $arrowBorder.width()) {
        arrowRightPosition = menuWidth - $arrowBorder.width();
      }
      if (!configs.skipArrowRightPositioning || configs.skipArrowRightPositioning && configs.skipArrowRightPositioning !== true) {
        $arrowBorder.css({
          right: arrowRightPosition
        });
        $arrow.css({
          right: arrowRightPosition + .5
        });
      }
      $el.css({
        top: isBelowView ? yPosition - (menuHeight + yPositionOffset) + 13 : yPosition + yPositionOffset,
        left: xPosition + xPositionOffset
      });
      $el.removeClass("down up").addClass(isBelowView ? "up" : "down");
      $el.fadeIn(fadeDuration).css("display", "block");
    };
    return view;
  }
  return extendView;
});
