define('modules/admin/myOrgs/organization/views/contract',['require','Underscore','jQuery','models/contract','utils/ContractUtils','text!../templates/contractCreateEdit.html','models/currencyConverter','T1','T1Layout','T1Permissions','T1View','text!../templates/contract.html','models/sessionUser','models/userPreferences','When','T1Notifier','T1Preferences','T1Signature','T1Tooltip'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const Contract = require("models/contract");
  const ContractUtils = require("utils/ContractUtils");
  const createEditTemplate = require("text!../templates/contractCreateEdit.html");
  const CurrencyConverter = require("models/currencyConverter");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!../templates/contract.html");
  const User = require("models/sessionUser");
  const UserPreferences = require("models/userPreferences");
  const When = require("When");
  const T1Notifier = require("T1Notifier");
  const T1Preferences = require("T1Preferences");
  const T1Signature = require("T1Signature");
  const T1Tooltip = require("T1Tooltip");
  var user = User.getUser().toJSON();
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  const roundingFactor = 1e3;
  const ContractEdit = T1View.extend({
    template: template,
    tipConfig: {
      className: "tooltip-contracts",
      delayIn: 0,
      delayOut: 0,
      getExternalTip: false,
      gravity: "s",
      html: true,
      offset: 10
    },
    actionsConfig: {
      formActions: [{
        class: "cancel",
        label: "cancel",
        location: "#admin"
      }, {
        class: "save save-btn-secondary",
        label: "save",
        location: false
      }, {
        class: "saveAndContinue save-btn-primary",
        label: "save & continue",
        location: false
      }]
    },
    selectedContractID: null,
    events: {
      "changed strand-dropdown": "onDropDownChange",
      "keydown strand-input": "onKeyDownInput",
      "focusout strand-input input": "onFocusoutInput"
    },
    contractFees: {
      t1_as_fee_cpm: ".05",
      peer39_safety_fee_cpm: ".03",
      peer39_quality_fee_cpm: ".03",
      t1_vads_fee_cpm: ".25",
      t1_html5_fee_cpm: ".05",
      standard_segment_cpm: ".10",
      adaptive_segment_cpm: ".4",
      fbx_dynamic_cpm: ".18",
      evidon_privacy_cost_cpm: ".03",
      external_media_tracking_cpm: ".005",
      connected_id_cost: ".3",
      audience_verification_cost: "0.18"
    },
    viewabilityFees: {
      viewability_display_sample: ".03",
      viewability_display_all_imps: ".10",
      viewability_video_sample: ".06",
      viewability_video_all_imps: ".20"
    },
    initialize(args) {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "admin.organization"], ["view", "contract"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["selectedContractID"]);
      this.selectedContractID = this.preferences.get("selectedContractID") || null;
      this.canEditContract = this.hasContractEditPermission();
      this.currencyConverter = new CurrencyConverter();
      this.isProgrammaticGuaranteed = T1Permissions.check("feature", "programmatic_guaranteed_rate");
      this.isAaSuppEnabled = T1Permissions.check("feature", "source");
      $.extend(this.contractFees, this.viewabilityFees);
      this.initializeData(args).then(() => {
        this.canLoad = true;
        this.load();
      });
    },
    hasContractEditPermission() {
      var isMMUser = user.type === "INTERNAL";
      return isMMUser && user.scope === "GLOBAL" && user.role === "ADMIN";
    },
    initializeData(args) {
      var deferred = When.defer();
      this.canLoad = false;
      if (args.model && args.model.get("type") === "organization") {
        this.orgModel = args.model;
        if (args.contracts && args.legacyContracts) {
          this.contracts = args.contracts;
          this.legacyContracts = args.legacyContracts;
        }
        this.buildContractList();
        deferred.resolve();
      }
      return deferred.promise;
    },
    buildContractList() {
      this.collection = this.contracts.add(this.legacyContracts.models).models;
      this.collection.sort(ContractUtils.contractSortFunc);
    },
    onFocusoutInput(event) {
      const $strandEl = $(event.currentTarget).parent();
      event.preventDefault();
      $strandEl.trigger("blur");
    },
    updateModels(args) {
      var model;
      var deferred = When.defer();
      var fromAmounts = [];
      var newCurrencySettings = {};
      const {
        contractFees: contractFees,
        orgModel: orgModel
      } = this;
      if (args.id && args.id !== "new") {
        model = _.find(this.collection, item => item.id === args.id);
        this.isNew = false;
        deferred.resolve(model);
      } else {
        if (orgModel.get("currency_code") === "USD") {
          model = new Contract({
            action: "new",
            adaptive_segment_cpm: contractFees.adaptive_segment_cpm,
            audience_verification_cost: contractFees.audience_verification_cost,
            connected_id_cost: contractFees.connected_id_cost,
            currency_code: "USD",
            evidon_privacy_cost_cpm: contractFees.evidon_privacy_cost_cpm,
            exclude_agency_margin: "0",
            external_media_tracking_cpm: contractFees.external_media_tracking_cpm,
            fbx_dynamic_cpm: contractFees.fbx_dynamic_cpm,
            managed_service_fee_unit: "PCT_TOTAL_COST",
            media_enablement_fee_unit: "PCT_TOTAL_COST",
            partnership_rebate_fee_unit: "PCT_CLEAR",
            country_tax_withholding_fee_unit: "PCT_CLEAR",
            name: "",
            optimization_fee_unit: "PCT_TOTAL_COST",
            organization_id: orgModel.id,
            peer39_quality_fee_cpm: contractFees.peer39_quality_fee_cpm,
            peer39_safety_fee_cpm: contractFees.peer39_safety_fee_cpm,
            platform_access_fee_unit: "PCT_TOTAL_COST",
            pmp_optimization_off_unit: "CPM",
            pmp_optimization_on_unit: "CPM",
            programmatic_guaranteed_platform_access_fee_unit: "PCT_TOTAL_COST",
            source_off_unit: "CPM",
            source_on_unit: "CPM",
            source_platform_access_fee_unit: "PCT_TOTAL_COST",
            standard_segment_cpm: contractFees.standard_segment_cpm,
            t1_as_fee_cpm: contractFees.t1_as_fee_cpm,
            t1_html5_fee_cpm: contractFees.t1_html5_fee_cpm,
            t1_vads_fee_cpm: contractFees.t1_vads_fee_cpm,
            type: "contract",
            viewability_display_all_imps: contractFees.viewability_display_all_imps,
            viewability_display_sample: contractFees.viewability_display_sample,
            viewability_video_all_imps: contractFees.viewability_video_all_imps,
            viewability_video_sample: contractFees.viewability_video_sample
          });
          this.originalContractCurrency = "USD";
          this.isNew = true;
          deferred.resolve(model);
        } else {
          for (const key in contractFees) {
            if (T1.Utils.hasOwnProp(contractFees, key)) {
              fromAmounts.push({
                index: key,
                amount: Number(contractFees[key])
              });
            }
          }
          const currencySettings = {
            fromAmounts: fromAmounts,
            fromCode: "USD",
            toCode: orgModel.get("currency_code")
          };
          this.currencyConverter.getRate(currencySettings).then(result => {
            $.each(result, function (index, value) {
              newCurrencySettings[value.index] = Math.round(value.amount * roundingFactor) / roundingFactor;
            });
            model = new Contract({
              action: "new",
              adaptive_segment_cpm: newCurrencySettings.adaptive_segment_cpm,
              audience_verification_cost: newCurrencySettings.audience_verification_cost,
              connected_id_cost: newCurrencySettings.connected_id_cost,
              currency_code: orgModel.get("currency_code"),
              evidon_privacy_cost_cpm: newCurrencySettings.evidon_privacy_cost_cpm,
              exclude_agency_margin: "0",
              external_media_tracking_cpm: newCurrencySettings.external_media_tracking_cpm,
              fbx_dynamic_cpm: newCurrencySettings.fbx_dynamic_cpm,
              managed_service_fee_unit: "PCT_TOTAL_COST",
              media_enablement_fee_unit: "PCT_TOTAL_COST",
              partnership_rebate_fee_unit: "PCT_CLEAR",
              country_tax_withholding_fee_unit: "PCT_CLEAR",
              name: "",
              optimization_fee_unit: "PCT_TOTAL_COST",
              organization_id: orgModel.id,
              peer39_quality_fee_cpm: newCurrencySettings.peer39_quality_fee_cpm,
              peer39_safety_fee_cpm: newCurrencySettings.peer39_safety_fee_cpm,
              platform_access_fee_unit: "PCT_TOTAL_COST",
              pmp_optimization_off_unit: "CPM",
              pmp_optimization_on_unit: "CPM",
              programmatic_guaranteed_platform_access_fee_unit: "PCT_TOTAL_COST",
              source_off_unit: "CPM",
              source_on_unit: "CPM",
              source_platform_access_fee_unit: "PCT_TOTAL_COST",
              standard_segment_cpm: newCurrencySettings.standard_segment_cpm,
              t1_as_fee_cpm: newCurrencySettings.t1_as_fee_cpm,
              t1_html5_fee_cpm: newCurrencySettings.t1_html5_fee_cpm,
              t1_vads_fee_cpm: newCurrencySettings.t1_vads_fee_cpm,
              type: "contract",
              viewability_display_all_imps: newCurrencySettings.viewability_display_all_imps,
              viewability_display_sample: newCurrencySettings.viewability_display_sample,
              viewability_video_all_imps: newCurrencySettings.viewability_video_all_imps,
              viewability_video_sample: newCurrencySettings.viewability_video_sample
            });
            this.originalContractCurrency = orgModel.get("currency_code");
            this.isNew = true;
            deferred.resolve(model);
          });
        }
      }
      return deferred.promise;
    },
    setFormActions() {
      this.actionsConfig.formActions = [{
        class: "cancel",
        label: "cancel",
        location: "#"
      }, {
        class: "save btn-secondary",
        label: "save",
        location: false
      }, {
        class: "saveAndClose btn-primary",
        label: "save & close",
        location: false,
        uuid: "save_close"
      }];
    },
    load() {
      if (!this.canLoad) {
        return;
      }
      this.render().then(() => {
        this.renderPlugins();
        this.openMostRecentContract();
      });
    },
    reload() {
      const contractsFetch = When.defer();
      const legacyContractsFetch = When.defer();
      const promises = [contractsFetch.promise, legacyContractsFetch.promise];
      this.contracts.fetch({
        success: function () {
          contractsFetch.resolve();
        }
      });
      this.legacyContracts.fetch({
        success: function () {
          legacyContractsFetch.resolve();
        }
      });
      When.all(promises).then(() => {
        this.initializeData({
          model: this.orgModel,
          id: this.id,
          contracts: this.contracts,
          legacyContracts: this.legacyContracts
        }).then(() => {
          this.canLoad = true;
          this.load();
        });
      });
    },
    unload() {
      this.contracts.reset([], {
        silent: true
      });
      this.legacyContracts.reset([], {
        silent: true
      });
    },
    renderPlugins(context = this.el) {
      var self = this;
      var contractData = [];
      var currencyData = [];
      const $selectContract = context.find(".select-contract");
      const $selectCurrency = context.find(".select-currency");
      $selectContract[0].initialValue = true;
      $selectContract[0].set("data", null);
      $selectCurrency[0].initialValue = true;
      $selectCurrency[0].set("data", null);
      if (this.canEditContract) {
        contractData.push({
          name: "Add Contract",
          value: "new"
        });
      }
      if (this.collection && this.collection.length) {
        _.each(this.collection, function (item) {
          if (item.get("type") === "contract") {
            const name = ContractUtils.getContractDisplayName(item);
            contractData.push({
              name: name,
              value: item.id
            });
          }
        });
      } else {
        this.selectContract(null);
      }
      $selectContract[0].set("data", contractData);
      const currencyList = T1.Utils.getCurrencyList({
        selectedId: "USD"
      });
      _.each(currencyList, function (currencyItem) {
        currencyData.push({
          name: currencyItem.text,
          value: currencyItem.value
        });
      });
      $selectCurrency[0].set("data", currencyData);
      setTimeout(function () {
        $selectContract.on("selected", function (e) {
          var id = e.originalEvent.detail.value;
          function doSelectContract() {
            self.selectContract(id);
            self.rememberSelectedContract(id);
          }
          self.isCancelled = id === self.selectedContractID;
          if (!self.isCancelled && self.canUnload({
            onAlertClose: doSelectContract
          })) {
            doSelectContract();
          }
        });
        $selectCurrency.on("selected", function (e) {
          if (!$selectCurrency[0].isClosed) {
            const currencyCode = e.originalEvent.detail.value;
            const currencySymbol = getCurrencySymbol(currencyCode);
            self.$(".currencyDisplay").text(currencySymbol);
            self.convertContractCurrencies(self.originalContractCurrency, currencyCode);
          }
        });
      }, 0);
    },
    convertContractCurrencies(fromCurrency, toCurrency) {
      var self = this;
      var $feeInputs = this.$(".additional-cost-and-fees");
      var fromAmounts = [];
      var otherFeesInputIds = ["platform_access_fee_cpm", "managed_service_fee_cpm", "media_enablement_fee_cpm", "optimization_fee_cpm", "pmp_optimization_off_fee_cpm", "pmp_optimization_on_fee_cpm", "source_platform_access_fee_cpm", "programmatic_guaranteed_platform_access_fee_cpm", "partnership_rebate_fee_cpm", "country_tax_withholding_fee_cpm"];
      $feeInputs.push(this.$("strand-input[data-bind=spend_cap]"));
      $feeInputs.push(this.$("strand-input[data-bind=platform_monthly_min]"));
      $.each(otherFeesInputIds, function (index, otherFeeId) {
        if (self.$(`strand-input[data-bind=${otherFeeId}]`).length) {
          $feeInputs.push(self.$(`strand-input[data-bind=${otherFeeId}]`));
        }
      });
      $.each($feeInputs, function (index, inputBox) {
        if (inputBox.value) {
          inputBox.setAttribute("disabled", true);
        }
        T1.Form.Masks.attach($(inputBox), toCurrency);
      });
      for (const key in this.contractFees) {
        if (T1.Utils.hasOwnProp(this.contractFees, key)) {
          fromAmounts.push({
            index: key,
            amount: Number(this.contractFees[key])
          });
        }
      }
      const currencySettings = {
        fromAmounts: fromAmounts,
        fromCode: "USD",
        toCode: toCurrency
      };
      this.currencyConverter.getRate(currencySettings).then(result => {
        $.each(result, function (index, amountObject) {
          const feeInputBox = self.$(`strand-input[data-bind="${amountObject.index}"]`);
          const decimalSeparator = T1.Utils.getCurrencyDecimalSeparator(toCurrency);
          const calculatedAmount = String(Math.round(amountObject.amount * roundingFactor) / roundingFactor);
          feeInputBox[0].value = calculatedAmount.replace(".", decimalSeparator);
          feeInputBox[0].removeAttribute("disabled");
        });
        this.originalContractCurrency = toCurrency;
      });
    },
    openMostRecentContract() {
      var contractToOpen, currentContract, pendingContract;
      var self = this;
      if (this.collection && this.collection.length) {
        for (let i = 0; i < this.collection.length; i++) {
          const contract = this.collection[i];
          if (this.selectedContractID && contract.id === this.selectedContractID) {
            contractToOpen = contract;
            break;
          } else {
            const status = ContractUtils.getContractStatus(contract);
            if (status === "Current" && !currentContract) {
              currentContract = contract;
            } else if (status === "Pending" && !pendingContract) {
              pendingContract = contract;
            }
          }
        }
        contractToOpen = contractToOpen || currentContract || pendingContract;
        if (!contractToOpen) {
          contractToOpen = self.collection[0];
        }
      }
      if (contractToOpen) {
        this.selectContract(contractToOpen.id);
        const $selectCurrency = this.$(".select-currency");
        $selectCurrency.attr("value", ContractUtils.getContractCurrency(contractToOpen));
        const contractToOpenStatus = ContractUtils.getContractStatus(contractToOpen);
        if (contractToOpenStatus === "Current") {
          $selectCurrency.attr("disabled", false);
        } else {
          $selectCurrency.attr("disabled", true);
        }
      }
    },
    rememberSelectedContract(id) {
      if (id && id !== "new") {
        this.selectedContractID = id;
        this.preferences.save();
      }
    },
    selectContract(id) {
      const $selectContract = this.$(".select-contract");
      const $noContractDisplay = this.$(".no-contracts");
      const $saveButton = this.$(".save");
      const $saveAndCloseButton = this.$(".saveAndClose");
      const $selectCurrency = this.$(".select-currency");
      if (id) {
        this.updateModels({
          id: id
        }).then(model => {
          this.model = model;
          const data = this.serialize();
          const datePickerLayout = new T1Layout({
            el: () => this.el,
            selectorPath: ".date-picker",
            template: "<div></div>",
            serialize: $.proxy(this.serialize, this),
            layout: {
              ".date-picker": [{
                module: "admin",
                viewType: "date_picker",
                options: {
                  startDate: model.get ? model.get("effective_start_date") : model.effective_start_date,
                  endDate: model.get ? model.get("effective_end_date") : model.effective_end_date,
                  startDataBind: "effective_start_date",
                  endDataBind: "effective_end_date",
                  isEnabled: data.isPendingOrCurrent,
                  isStartDisabled: data.status === "Current",
                  onChanged: () => {
                    this.isFormElementsChanged = true;
                  }
                }
              }]
            }
          });
          this.renderPartialTemplate({
            action: "html",
            targetEl: ".w-OrgContractCreateEdit",
            template: createEditTemplate,
            useTemplateHtml: false
          });
          datePickerLayout.load();
          $noContractDisplay.hide();
          $selectCurrency.attr("value", ContractUtils.getContractCurrency(model));
          this.originalContractCurrency = ContractUtils.getContractCurrency(model);
          if (data.isEditable) {
            $selectCurrency.removeAttr("disabled");
            const $ddls = this.$("strand-dropdown:not(.select-contract,.select-currency)");
            if ($ddls && $ddls.length) {
              $ddls.on("selected", $.proxy(this.mmDropdownChanged, this));
            }
          } else {
            $selectCurrency.attr("disabled", true);
          }
          setTimeout(() => {
            if (!id || id === "new") {
              $selectContract.attr("disabled", true);
            } else {
              $selectContract.val(id);
            }
            $saveButton.toggleClass("disabled", !data.isPendingOrCurrent);
            $saveAndCloseButton.toggleClass("disabled", !data.isPendingOrCurrent);
            this.isFormElementsChanged = false;
          }, 0);
          T1.Tooltip(this.el, this.tipConfig);
          T1.Form.Masks.attach(this.$("strand-input"), this.originalContractCurrency);
        });
      } else {
        if (!this.canEditContract && this.collection && !this.collection.length) {
          $selectContract.attr("disabled", true);
        }
        $noContractDisplay.show();
        $saveButton.toggleClass("disabled", true);
        $saveAndCloseButton.toggleClass("disabled", true);
      }
    },
    mmDropdownChanged(evt) {
      var $selectCurrency;
      var $target = $(evt.target);
      var model = this.model;
      if (!$target.hasClass("select-contract")) {
        const field = {};
        const fieldName = $target.attr("data-bind");
        const fieldValue = $target.val();
        const percentValue = 100;
        const percentValueException = 200;
        const percentExceptionFields = ["partnership_rebate_fee_unit", "country_tax_withholding_fee_unit"];
        const percentMax = percentExceptionFields.includes(fieldName) ? percentValueException : percentValue;
        field[fieldName] = fieldValue;
        const splitFieldName = fieldName.replace(/_fee|_unit/g, "");
        const targetEl = $target.closest("dd");
        const $currencyDisplay = targetEl.find(".currencyDisplay");
        const $input = targetEl.find("strand-input");
        let currencyCode;
        if (fieldValue !== "--") {
          let bindFieldName, unBindFieldName;
          const isCurrency = ContractUtils.isCurrency(fieldValue);
          if (isCurrency) {
            if (fieldValue === "CPTS") {
              bindFieldName = `${splitFieldName}_fee_cpts`;
            } else {
              bindFieldName = `${splitFieldName}_fee_cpm`;
            }
            unBindFieldName = `${splitFieldName}_fee_pct`;
            $currencyDisplay.removeClass("hidden");
            $selectCurrency = this.$(".select-currency");
            currencyCode = $selectCurrency.val();
            const currencySymbol = getCurrencySymbol(currencyCode);
            $currencyDisplay.text(currencySymbol);
            $input.attr({
              alt: "currency",
              "data-bind": bindFieldName
            });
            $input.data("bind", bindFieldName);
            $input.removeAttr("max");
          } else {
            bindFieldName = `${splitFieldName}_fee_pct`;
            unBindFieldName = `${splitFieldName}_fee_cpm`;
            $currencyDisplay.addClass("hidden");
            $input.attr({
              alt: "percentage",
              max: percentMax,
              "data-bind": bindFieldName
            });
            $input.data("bind", bindFieldName);
            const inputVal = Number($input.val().replace(/[^0-9.]+/g, ""));
            if (inputVal > percentMax) {
              $input.val(percentMax);
            }
          }
          model.set(field);
          model.unset(unBindFieldName);
        } else {
          $input.val("");
          $currencyDisplay.addClass("hidden");
          model.unset(`${splitFieldName}_fee_unit`);
          model.unset(`${splitFieldName}_fee_cpts`);
          model.unset(`${splitFieldName}_fee_cpm`);
          model.unset(`${splitFieldName}_fee_pct`);
        }
        this.removeTooltipOnElement($target);
        this.removeTooltipOnElement($input);
        T1.Form.Masks.attach($input, currencyCode);
      }
    },
    onKeyDownInput(evt) {
      var $target = $(evt.target);
      var $ddl = $target.nextAll("strand-dropdown");
      if ($ddl && $ddl.length) {
        this.removeTooltipOnElement($ddl);
        $ddl.removeClass("error");
      }
    },
    onDropDownChange(evt) {
      if (evt.target.value === "CPM") {
        $(evt.target).closest(".inner-col").find(".form-input").attr("data-precision", "4");
      } else {
        $(evt.target).closest(".inner-col").find(".form-input").removeAttr("data-precision");
      }
    },
    preparePostData(formValues) {
      formValues.currency_code = this.$(".select-currency").val();
      formValues.organization_id = this.orgModel.id;
      if (formValues.spend_cap === "") {
        this.model.set({
          spend_cap: ""
        });
      }
      delete formValues.ignore;
      $.each(formValues, function (key, value) {
        if (value === "--") {
          formValues[key] = "";
        }
      });
      return formValues;
    },
    postData(formValues, success, statusInvalid, conflict) {
      var dateValidationResult = ContractUtils.validateContractDateOverlap(this.collection, this.model, new Date(formValues.effective_start_date), new Date(formValues.effective_end_date));
      if (!dateValidationResult.isValid) {
        const errors = [{
          field: "start_end_date",
          message: dateValidationResult.message
        }];
        statusInvalid(errors, null, errors.message, true);
      } else {
        this.removeTooltipOnElement($(".date-picker"));
        if (this.model.get("type") === "organization") {
          delete formValues.organization_id;
        }
        this.model.createEdit(formValues, {
          success: model => {
            T1.Notify("message", `Contract ${this.isNew ? "created" : "updated"} successfully!`);
            if (this.getSaveAndContinueMode && !this.getSaveAndContinueMode()) {
              if (this.isNew) {
                this.contracts.add(model);
              }
              this.selectedContractID = model.id;
              this.initializeData({
                id: model.id,
                model: this.orgModel
              }).then(() => {
                this.canLoad = true;
                this.load();
              });
            }
            success(model);
          },
          statusInvalid: function (errors) {
            statusInvalid(errors, null, ".form-body");
          },
          conflict: conflict
        });
      }
    },
    saveAndClose(evt) {
      var callback = {
        success: function () {
          const delay = 1e3;
          setTimeout(function () {
            T1.Location.set("#admin/myOrgs");
          }, delay);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    cancelClicked(evt) {
      evt.preventDefault();
      if (this.isNew) {
        this.isFormElementsChanged = false;
        this.load();
      } else {
        const delay = 1e3;
        setTimeout(function () {
          T1.Location.set("#admin/myOrgs");
        }, delay);
      }
    },
    canUnload(onTabChange) {
      const self = this;
      const isFormElementsChanged = this.isFormElementsChanged;
      const onTabChangeHandler = {
        onAlertClose: () => {
          const T1Location = T1.Location;
          const abortedUrl = T1Location.getAbortedURL();
          if (typeof onTabChange !== "object" && !abortedUrl) {
            T1Location.set(`#admin/organization/${self.id}/details`);
          } else if (!abortedUrl) {
            onTabChange.onAlertClose();
          } else {
            T1Location.set(abortedUrl);
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.orgModel.get("name")
        }, onTabChangeHandler);
      }
      return !isFormElementsChanged;
    },
    cancelUnload() {
      this.el.find(".select-contract").val(this.selectedContractID);
    },
    serialize() {
      var output = {};
      if (this.canLoad && this.model) {
        output.status = ContractUtils.getContractStatus(this.model);
        output.isEditable = (output.status === "Pending" || output.status === "New") && this.canEditContract;
        output.isPendingOrCurrent = (output.status === "Current" || output.status === "Pending" || output.status === "New") && this.canEditContract;
        output = $.extend(output, this.model.toJSON ? this.model.toJSON() : this.model);
        for (const prop in this.viewabilityFees) {
          if (T1.Utils.hasOwnProp(this.viewabilityFees, prop) && !output[prop]) {
            output[prop] = this.viewabilityFees[prop];
          }
        }
        output.canEditContract = this.canEditContract;
        output.isLegacyContract = output.type === "legacy_contract";
        output.hasContracts = this.collection && this.collection.length || output.isEditable || output.isPendingOrCurrent;
        output.dmpPctSign = output.signalsPctSign = output.t1AppsPctSign = "%";
        if (output.isLegacyContract) {
          output.contractTypeValue = `${(output.contract_type === "fixed_gross" ? "Fixed % gross media, " : "Shared % of gross profit, ") + output.contract_fee_pct}% to MediaMath`;
          if (output.lesser_tech_fee === "1") {
            output.lesserTechFeeValue = `${output.tech_fee_pct} or ${output.tech_fee_cpm}`;
          } else {
            output.isTechFeeCurrency = output.tech_fee_cpm !== undefined;
            output.techFeeValue = output.isTechFeeCurrency ? output.tech_fee_cpm : output.tech_fee_pct;
          }
        } else {
          output.isAgencyMarginExcluded = output.exclude_agency_margin === "1";
          output.isPlatformAccessFeeCurrency = ContractUtils.isCurrency(output.platform_access_fee_unit);
          output.platformAccessFeeValue = output.isPlatformAccessFeeCurrency ? output.platform_access_fee_cpm : output.platform_access_fee_pct;
          output.isManagedServiceFeeCurrency = ContractUtils.isCurrency(output.managed_service_fee_unit);
          output.managedServiceFeeValue = output.isManagedServiceFeeCurrency ? output.managed_service_fee_cpm : output.managed_service_fee_pct;
          output.isSourceMediaEnablementFeeCurrency = ContractUtils.isCurrency(output.media_enablement_fee_unit);
          output.sourceMediaEnablementFeeValue = output.isSourceMediaEnablementFeeCurrency ? output.media_enablement_fee_cpm : output.media_enablement_fee_pct;
          output.isOptimizationFeeCurrency = ContractUtils.isCurrency(output.optimization_fee_unit);
          output.optimizationFeeValue = output.isOptimizationFeeCurrency ? output.optimization_fee_cpm : output.optimization_fee_pct;
          output.isSourceFeeCurrency = ContractUtils.isCurrency(output.source_platform_access_fee_unit);
          output.sourceFlatformAceess = output.isSourceFeeCurrency ? output.source_platform_access_fee_cpm : output.source_platform_access_fee_pct;
          output.partnership_rebate_fee_unit = output.partnership_rebate_fee_unit || "PCT_CLEAR";
          output.isPartnershipRebateFeeCurrency = ContractUtils.isCurrency(output.partnership_rebate_fee_unit);
          output.partnershipRebateValue = output.isPartnershipRebateFeeCurrency ? output.partnership_rebate_fee_cpm : output.partnership_rebate_fee_pct;
          output.country_tax_withholding_fee_unit = output.country_tax_withholding_fee_unit || "PCT_CLEAR";
          output.isCountryTaxWithholdingFeeCurrency = ContractUtils.isCurrency(output.country_tax_withholding_fee_unit);
          output.countryTaxWithholdingValue = output.isCountryTaxWithholdingFeeCurrency ? output.country_tax_withholding_fee_cpm : output.country_tax_withholding_fee_pct;
          output.isPMPStandardFeeCurrency = ContractUtils.isCurrency(output.pmp_optimization_off_unit);
          output.pmpStandardFeeValue = output.isPMPStandardFeeCurrency ? output.pmp_optimization_off_fee_cpm : output.pmp_optimization_off_fee_pct;
          output.isPMPOptimizationFeeCurrency = ContractUtils.isCurrency(output.pmp_optimization_on_unit);
          output.pmpOptimizationFeeValue = output.isPMPOptimizationFeeCurrency ? output.pmp_optimization_on_fee_cpm : output.pmp_optimization_on_fee_pct;
          if (this.isProgrammaticGuaranteed) {
            output.programmatic_guaranteed_platform_access_fee_unit = output.programmatic_guaranteed_platform_access_fee_unit || "PCT_TOTAL_COST";
            output.isPGPAFCurrency = ContractUtils.isCurrency(output.programmatic_guaranteed_platform_access_fee_unit);
            output.PGPAFValue = output.isPGPAFCurrency ? output.programmatic_guaranteed_platform_access_fee_cpm : output.programmatic_guaranteed_platform_access_fee_pct;
          }
        }
        const currencyCode = ContractUtils.getContractCurrency(this.model);
        output.currency_symbol = getCurrencySymbol(currencyCode);
        output.asCurrency = T1.Utils.getCurrencyTemplateFunc(currencyCode);
        output.asDecimalCurrency = T1.Utils.getDecimalCurrencyTemplateFunc(currencyCode);
      }
      output.isProgrammaticGuaranteed = this.isProgrammaticGuaranteed;
      output.isAcctAndAddr = T1Permissions.check("feature", "source");
      output.isAaSuppEnabled = this.isAaSuppEnabled;
      output.hasPartnershipRebateFees = T1Permissions.check("feature", "partnership_rebate");
      output.hasCountryTaxWithholdingFees = T1Permissions.check("feature", "country_tax_withholding");
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(ContractEdit);
});
