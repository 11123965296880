define('modules/creatives/display/views/preview',["jQuery", "Underscore", "When", "T1", "T1Comm", "T1View", "T1Layout", "T1Form", "T1Permissions", "models/atomicCreative", "text!../templates/preview.html", "text!../templates/previewBody.html", "text!../templates/previewItem.html", "jslibs/postscribe/postscribe"], function ($, _, When, T1, T1Comm, T1View, T1Layout, T1Form, T1Permissions, AtomicCreative, template, templateBody, previewItemTemplate, postscribe) {
  var CreativePreview;
  CreativePreview = T1View.extend({
    template: template,
    loaderOpts: {
      contentClass: "inline-edit-loader",
      target: ".w-CreateEditForm",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15,
        top: 4
      }
    },
    canLoad: true,
    initialize: function (args) {
      var self = this;
      if (!T1Permissions.check("feature", "creatives")) {
        T1.Location.set("#unauthorized", {
          replace: true
        });
        return;
      }
      self.idList = args.id.split("|");
      this.initLayout();
      this.load();
      this.canLoad = false;
      When.map(self.idList, self.fetchCreative).then(function (modelArr) {
        self.canLoad = true;
        self.modelArr = modelArr;
        self.load();
      });
    },
    startStopLoader: function (toStart) {
      var $el = $(this.el);
      var $loaderEl;
      this.previewLoader = T1.Loader.create({
        target: ".view-loader-preview-list",
        text: "Loading ...",
        loaderClass: "loader",
        context: $el
      });
      if (toStart === true) {
        this.previewLoader.start();
      } else {
        $loaderEl = $el.find(".view-loader-preview-list");
        setTimeout(function () {
          $loaderEl.fadeOut(200, function () {
            $loaderEl.remove();
          });
        }, 0);
        this.previewLoader = null;
      }
    },
    fetchCreative: function (id) {
      var self = this;
      var deferred = When.defer();
      var displayCreative;
      var advertiser;
      if (id) {
        displayCreative = new AtomicCreative({
          id: id
        });
        displayCreative.fetch({
          params: {
            with: ["advertiser,agency", "creatives,creative_assets", "concept"]
          },
          success: function (model, jsonData) {
            displayCreative.set({
              isActive: displayCreative.get("status") === "1",
              isSecure: displayCreative.get("is_https") === "1"
            });
            deferred.resolve(displayCreative);
          }
        });
      }
      return deferred.promise;
    },
    initLayout: function () {
      var self = this;
      if (!this.canLoad) {
        return;
      }
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".main-content-preview",
        template: templateBody,
        partials: this.partials,
        serialize: $.proxy(self.serialize, self)
      });
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      var renderQueue;
      var $contentHolder;
      if (this.canLoad) {
        this.render().then(function () {
          self.startStopLoader(true);
          if (self.layout) {
            self.layout.load();
            $contentHolder = $el.find(".w-DisplayPreview");
            if (self.modelArr && self.modelArr.length) {
              $contentHolder.empty();
              renderQueue = new T1.RequestQueue.create({
                collection: _.map(self.modelArr, function (model) {
                  return self.serializeCreative(model);
                }),
                action: function (source) {
                  var iframe;
                  var doc;
                  var contentDoc;
                  var script;
                  var endcodedTag;
                  endcodedTag = window.btoa(source.tag).replace(/\//g, "_").replace(/\+/g, "-").replace(/\=$/g, "");
                  source.scriptData = "https://tags.mathtag.com/preview/js/?size=" + source.width + "x" + source.height + "&adcode=" + endcodedTag;
                  self.renderPartialTemplate({
                    template: previewItemTemplate,
                    context: self.el,
                    data: source,
                    action: "append",
                    targetEl: $contentHolder,
                    useTemplateHtml: false,
                    skipDelegateEvents: false,
                    skipAttachFormMasks: true
                  });
                }
              });
              renderQueue.load();
              self.startStopLoader(false);
            }
          }
        });
      }
    },
    getAgencyAndAdvertiserName: function (model) {
      var advertiser;
      var agency;
      var agencyDisplay;
      advertiser = model && model.get ? model.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        agency = advertiser.get("agency");
        agencyDisplay = agency ? agency.get("name") + "&nbsp;&raquo;&nbsp;" : "";
        return agencyDisplay + advertiser.get("name");
      }
    },
    getConceptName: function (model) {
      var cName = "--";
      var concept;
      concept = model && model.get ? model.get("concept") : null;
      if (concept && concept.get) {
        cName = concept.get("name") + " (" + concept.get("id") + ")";
      }
      return cName;
    },
    getFileSize: function (model) {
      var fSize = "--";
      var creative = model ? model.get("creative") : null;
      var creativeAsset = creative ? creative.get("creative_asset") : null;
      primaryAsset = creativeAsset ? creativeAsset.getAsset("PRIMARY") : null;
      if (primaryAsset) {
        fSize = primaryAsset.get("file_size");
        fSize = Math.round(parseInt(fSize) / 1e3) + "kb";
      }
      return fSize;
    },
    serializeCreative: function (model) {
      var modelJSON = model.toJSON();
      var is3PAS = modelJSON.t1as === "0";
      var isSecure = modelJSON.is_https === "1";
      var obj = {
        conceptName: this.getConceptName(model),
        tpasID: is3PAS ? modelJSON.external_identifier : "None - T1AS",
        isSecure: isSecure ? "Secure" : "Non-secure",
        is3PAS: modelJSON.t1as === "0",
        isT1AS: modelJSON.t1as === "1",
        filesize: this.getFileSize(model)
      };
      var returnObj = $.extend(obj, modelJSON);
      returnObj.agencyAndAdvertiser = this.getAgencyAndAdvertiserName(model);
      return returnObj;
    },
    serialize: function () {
      var count = this.idList && this.idList.length ? this.idList.length : 0;
      return {
        isChrome: window.navigator.userAgent.indexOf("Chrome") !== -1,
        previewCount: count + " Creative" + (count > 1 ? "s" : "")
      };
    }
  });
  return T1.Form.ViewUtils.extend(CreativePreview);
});
