define('modules/campaign/chart/winLoss/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "winLoss",
    name: "campaign/chart/winLoss",
    viewCfgs: {
      campaignDailyChart: {},
      campaignSummaryChart: {},
      strategyDailyChart: {},
      winLoss: {}
    }
  });
});
