define('modules/campaign/views/editMargin',["jQuery", "T1", "T1View", "text!modules/campaign/templates/editMargin.html", "T1Layout", "T1Permissions", "collections/organizations", "utils/CampaignStrategyUtils"], function ($, T1, T1View, template, T1Layout, T1Permissions, Organizations, Utils) {
  "use strict";

  var getAgencyAndAdvertizerDisplay = Utils.getAgencyAdvertiserDisplay;
  var getUnitLabelForActualFieldOnHeaderRegion = Utils.getUnitLabelForActualFieldOnHeaderRegion;
  var calculateDailySpendToPace = Utils.calculateDailySpendToPace;
  var getCtdReportJsonObjByModel = Utils.getCtdReportJsonObjByModel;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = Utils;
  const updateBreadCrumb = ({
    isReadOnly: isReadOnly,
    model: model
  }) => {
    const locations = [{
      label: model.get("name"),
      location: (isReadOnly ? "#campaign/readonly/" : "#campaign/edit/") + model.id
    }, {
      label: "Edit Margin",
      location: ""
    }];
    T1.EventHub.publish("updateNavPath", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(model),
      locations: locations
    });
  };
  const updateBreadCrumbNewT1 = ({
    isReadOnly: isReadOnly,
    model: model,
    organization: organization,
    origin: origin
  }) => {
    const locations = [{
      label: "Campaigns Home",
      location: `${origin}/campaigns?organization=${organization}`,
      target: "_top"
    }, {
      label: model.get("name"),
      location: (isReadOnly ? "#campaign/readonly/" : "#campaign/edit/") + model.id
    }, {
      label: "Edit Margin",
      location: ""
    }];
    T1.EventHub.publish("updateNavPathNewT1", {
      headerInfo: Utils.getBreadCrumbHeaderInfo(model),
      locations: locations
    });
  };
  var CampaignEditMarginView = T1View.extend({
    template: template,
    initialize: function () {
      Organizations.getOrganizations().getSelected().then(id => {
        this.org = id;
      });
      this.initLayout();
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".margin-management",
        template: '<div class="margin-c"></div>',
        layout: {
          ".margin-c": [{
            module: "campaign",
            viewType: "editMarginBrowse",
            showLoader: true,
            options: {
              campaign: this.model
            }
          }]
        }
      });
    },
    formConfig: {
      doNotShowSaveAndContinueButton: true
    },
    actionsConfig: {
      formActions: [{
        label: "cancel",
        class: "cancel",
        location: "#monitor"
      }, {
        label: "commit margin",
        class: "save save-btn-primary",
        location: "#monitor"
      }]
    },
    load: function () {
      const {
        isHybrid: isHybrid,
        origin: origin,
        organization: organization
      } = window.hybridWorkflowParams;
      const {
        isReadOnly: isReadOnly,
        model: model
      } = this;
      if (isHybrid) {
        updateBreadCrumbNewT1({
          isReadOnly: isReadOnly,
          model: model,
          organization: organization,
          origin: origin
        });
      } else {
        updateBreadCrumb({
          isReadOnly: isReadOnly,
          model: model
        });
      }
      this.render().then(() => {
        this.layout.load();
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn();
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      orgs.getSelected().then(id => {
        const existingOrg = this.org;
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var campaignName = this.model.get("name");
      if (isFormElementsChanged) {
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: campaignName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: campaignName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    unload() {
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    postData: function (formValues, successCB, invalidCB, conflictCB) {
      this.model.campaignMargins.saveMargin(successCB, invalidCB, conflictCB);
    },
    serialize: function () {
      var noData = "&nbsp;--";
      var noAmountData = "$&nbsp;--";
      var dateFormat = "MM/dd/yyyy";
      var model = this.model;
      var campaignJson = model.toJSON();
      var defaultData = {
        canEditMargin: T1Permissions.check("campaign.editMargin", model),
        startDateAsString: campaignJson.start_date ? Date.parse(campaignJson.start_date).toString(dateFormat) : noData,
        endDateAsString: campaignJson.end_date ? Date.parse(campaignJson.end_date).toString(dateFormat) : noData,
        total_budget: campaignJson.total_budget || noAmountData,
        goal_value: campaignJson.goal_value || noAmountData,
        agencyAndAdvertiser: getAgencyAndAdvertizerDisplay(model),
        campaignLink: `#campaign/${this.isReadOnly ? "readonly/" : "edit/"}${campaignJson.id}`,
        campaignName: campaignJson.name
      };
      var resultObj = {};
      var ctdReport = getCtdReportJsonObjByModel(model);
      $.extend(resultObj, defaultData, campaignJson);
      resultObj.mm_goal_performance = ctdReport.mm_goal_performance;
      resultObj.goal_type_text = getUnitLabelForActualFieldOnHeaderRegion(resultObj.mm_goal_performance, campaignJson.goal_type);
      resultObj.daily_spend_to_pace = calculateDailySpendToPace(model);
      resultObj.isROICampaign = campaignJson.goal_type === "roi";
      return resultObj;
    }
  });
  return T1.Form.ViewUtils.extend(CampaignEditMarginView);
});
