define('modules/reporting/campaigns/performance/views/controls',['require','jQuery','Underscore','T1','T1Animate','T1Layout','T1View','text!../templates/controls.html','T1Permissions'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1Animate = require("T1Animate");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const template = require("text!../templates/controls.html");
  const T1Permissions = require("T1Permissions");
  var ControlsView = T1View.extend({
    selectedColumns: null,
    template: template,
    events: {
      "click .columns": "showColumnSelector",
      "click .toggle-btn": "toggleChart",
      "keyup input": "searchStrategyInGrid"
    },
    dataEvents: {
      reportModel: {
        "change:columnKeys": "updateMetadataSortKeys",
        "change:sortKeyInfo": "resortCollection",
        "change:campaignBenchmarkOn": function () {
          T1.EventHub.publish("preference:change", {
            key: "campaignBenchmarkOn",
            value: String(this.reportModel.get("campaignBenchmarkOn"))
          });
        }
      }
    },
    initialize({
      models: models,
      type: type,
      dataGroup: dataGroup,
      timeSeries: timeSeries
    }) {
      const {
        mainMeta: mainMeta,
        reportMeta: reportMeta,
        mainModel: mainModel,
        reportModel: reportModel,
        campaign: campaign
      } = models;
      Object.assign(this, {
        campaign: campaign,
        collection: dataGroup.collection,
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel,
        summaryCollection: dataGroup.dim1Collection,
        timeSeries: this.type === "series" ? timeSeries : "summary",
        type: type
      });
    },
    load() {
      const {
        mainMeta: mainMeta,
        reportMeta: reportMeta,
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const reportMetaObj = $.extend({}, reportMeta.toJSON());
      const reportModelObj = $.extend({}, reportModel.toJSON());
      const preferences = reportModelObj.preferences;
      const focus = reportModelObj.focus || "all";
      const selectedColumns = preferences.get(focus === "all" ? "selectedColumns" : `${focus}SelectedColumns`);
      const sortKeyInfo = preferences.get(focus === "all" ? "sortKeyInfo" : `${focus}SortKeyInfo`);
      const campaignBenchmarkOn = preferences.get("campaignBenchmarkOn");
      const campaignGoalType = mainModel.get("multiCampaigns") ? "multi" : this.campaign.get("goal_type");
      const defaultSortForGoalType = reportMeta.get("defaultSortForGoalType")[campaignGoalType] || "";
      reportModel.setColumnKeys(selectedColumns ? selectedColumns.split(",") : reportModelObj.defaultColumnKeys);
      reportModel.setSortKeyInfo(sortKeyInfo ? sortKeyInfo : defaultSortForGoalType);
      reportModel.setCampaignBenchmarkState(campaignBenchmarkOn === "true" || true);
      Object.assign(this, {
        mainMeta: mainMeta,
        reportMetaObj: reportMetaObj,
        reportModelObj: reportModelObj
      });
      this.updateMetadataSortKeys();
      this.chartShown = this.mainModel.get("chartShown") === "true";
      this.render().then(() => {
        this.chartCover = this.$(".chart-cover");
        this.reportControls = this.el.closest(".report-controls");
      });
    },
    unload() {
      this.chartCover = null;
      this.reportControls = null;
    },
    prepareMultiSortableData() {
      const {
        reportModelObj: reportModelObj,
        reportMeta: reportMeta
      } = this;
      const allColumnsObj = $.extend({}, reportMeta.additionalData.columns[reportModelObj.focus]);
      const selectedKeys = this.selectedColumns ? this.selectedColumns.split(",") : reportModelObj.defaultColumns;
      const selectedCols = [];
      const availableCols = [];
      _.each(allColumnsObj, (obj, prop) => {
        if (obj.section && !obj.section.includes(reportModelObj.key)) {
          delete allColumnsObj[prop];
        }
        if (obj.featureFlag && !T1Permissions.check("feature", obj.featureFlag)) {
          delete allColumnsObj[prop];
        }
      });
      _.each(selectedKeys, obj => {
        const key = this.selectedColumns ? obj : obj.value;
        let customText;
        if (allColumnsObj[key]) {
          customText = allColumnsObj[key].text || reportMeta.getFriendlyName("metrics", key);
          delete allColumnsObj[key];
        } else {
          return;
        }
        if (customText) {
          selectedCols.push({
            value: key,
            text: customText
          });
        }
      });
      const availableKeys = _.keys(allColumnsObj);
      _.each(availableKeys, function (key) {
        const customText = allColumnsObj[key].text || reportMeta.getFriendlyName("metrics", key);
        if (customText) {
          availableCols.push({
            value: key,
            text: customText
          });
        }
      });
      this.availableColumnsArray = availableCols;
      this.selectedColumnsArray = selectedCols;
    },
    initLayout() {
      const {
        onFormClose: onFormClose,
        saveColumns: saveColumns
      } = this;
      this.layout = new T1Layout({
        el: () => this.$(".column-selector-dialog"),
        template: '<div class="column-selector"></div>',
        layout: {
          ".column-selector": [{
            module: "reporting/campaigns/performance",
            viewType: "columnSelector",
            options: {
              listA: this.availableColumnsArray,
              listB: this.selectedColumnsArray,
              showCurrencyNotice: true,
              onCloseCallback: obj => {
                if (obj) {
                  saveColumns.call(this, obj);
                }
                onFormClose.call(this);
              }
            }
          }]
        }
      });
    },
    saveColumns({
      listB: listB
    }) {
      const focus = this.reportModelObj.focus;
      T1.EventHub.publish("preference:change", {
        key: focus === "all" ? "selectedColumns" : `${focus}SelectedColumns`,
        value: listB.toString()
      });
      this.reportModel.setColumnKeys(listB);
    },
    updateMetadataSortKeys() {
      const reportModelObj = $.extend({}, this.reportModel.toJSON());
      const sortKeyInfo = reportModelObj.sortKeyInfo;
      const sortKeyArray = sortKeyInfo ? sortKeyInfo.split(",") : [];
      const columnKeys = reportModelObj.columnKeys;
      const sortKeyIndexInColumnKeys = sortKeyArray.length ? $.inArray(sortKeyArray[0], columnKeys) : -1;
      if (sortKeyIndexInColumnKeys === -1) {
        this.reportModel.setSortKeyInfo(columnKeys.length ? `${columnKeys[0]},-1` : "Strategy_Name,1,1");
      }
    },
    resortCollection(reportModel) {
      const focus = reportModel.get("focus");
      const sortKeyInfo = reportModel.get("sortKeyInfo");
      if (sortKeyInfo && sortKeyInfo.length) {
        const [sortKey, sortOrderStr, isNameStr] = sortKeyInfo.split(",");
        const sortOrder = parseInt(sortOrderStr, 10);
        const isName = parseInt(isNameStr, 10);
        this.collection.sortByKey(sortKey, sortOrder, "daily", false, isName);
        this.summaryCollection.sortByKey(sortKey, sortOrder, "summary", false, isName);
      }
      T1.EventHub.publish("preference:change", {
        key: focus === "all" ? "sortKeyInfo" : `${focus}SortKeyInfo`,
        value: sortKeyInfo
      });
    },
    onFormClose() {
      delete this.layout.layout[".column-selector-dialog"];
    },
    showColumnSelector(evt) {
      const focus = this.reportModelObj.focus || "all";
      const selectedColumnsProp = focus === "all" ? "selectedColumns" : `${focus}SelectedColumns`;
      evt.preventDefault();
      evt.stopPropagation();
      this.selectedColumns = this.reportModel.get("preferences").get(selectedColumnsProp);
      this.prepareMultiSortableData();
      this.initLayout();
      this.layout.load();
    },
    searchStrategyInGrid(evt) {
      let inputText;
      const enterKey = 13;
      switch (evt.keyCode) {
        case enterKey:
          inputText = $(evt.currentTarget).val();
          evt.preventDefault();
          T1.EventHub.publish(`reporting_${this.timeSeries}:search`, {
            term: inputText
          });
          break;
      }
    },
    toggleChart() {
      const chartShown = this.chartShown;
      const publish = T1.EventHub.publish;
      const settleDelay = 140;
      T1Animate({
        duration: 400,
        easing: "ease",
        options: {
          marginTop: chartShown ? "4px" : "358px"
        },
        target: this.reportControls,
        complete: () => {
          publish("chart.toggleEnd");
          publish("track:event", {
            action: "Toggle chart",
            label: this.chartShown ? "show" : "hide"
          });
        }
      });
      setTimeout(() => {
        this.chartCover.toggle().removeClass("no-display");
        this.renderPartial(".toggle-btn");
      }, settleDelay);
      this.chartShown = !this.chartShown;
      publish("chart.toggle", this.chartShown);
    },
    serialize() {
      const chartShown = this.chartShown;
      return {
        btnLabel: chartShown ? "hide chart" : "show chart",
        btnState: chartShown ? "hide" : "show",
        chartHidden: !chartShown
      };
    }
  });
  return ControlsView;
});
