define('modules/organization/main',["jQuery", "T1", "T1Module", "collections/organizations"], function ($, T1, T1Module, Organizations) {
  var collection = Organizations.getOrganizations();
  return new T1Module({
    name: "organization",
    viewCfgs: {
      search: {
        collection: collection
      }
    },
    defaultView: "search"
  });
});
