define('modules/campaign/views/editMarginBrowse',["jQuery", "T1", "T1View", "text!../templates/edit_margin_grid_item.html", "text!../templates/edit_margin_grid_footer.html", "T1GridView", "utils/CampaignStrategyUtils", "modules/organization/utils/Utils"], function ($, T1, T1View, gridItem, gridFooter, createGrid, Utils, OrgUtils) {
  "use strict";

  let asCurrency, asDecimalCurrency, formatCurrency;
  const perCent = 100;
  const perMille = 1e3;
  const fourDigits = 4;
  var divideByZero = Utils.divideByZero;
  var EditMarginBrowseView = T1View.extend({
    partials: {
      listItem: gridItem,
      gridFooter: gridFooter
    },
    headers: [{
      name: "date",
      label: "date"
    }, {
      name: "margin-pct",
      label: "margin &#37;"
    }, {
      name: "total-ad-cost",
      label: "total ad cost"
    }, {
      name: "total-spend",
      label: "total spend"
    }, {
      name: "ecpm",
      label: "eCPM"
    }, {
      name: "goal-performance",
      label: "goal performance"
    }],
    headersTooltips: true,
    goalTypeText: {
      spend: "SPEND",
      reach: "REACH",
      cpc: "CPC",
      cpe: "CPE",
      cpa: "CPA",
      roi: "ROI",
      vcr: "VCR",
      ctr: "CTR",
      viewability_rate: "V.RATE",
      vcpm: "vCPM"
    },
    events: {
      "click .item": "toggleNode",
      "click input": "preventDefault",
      "blur input": "onMarginChange"
    },
    initialize: function () {
      var campaign = this.collection ? this.collection.campaign : null;
      this.formattedMapData = {};
      this.currencyCode = campaign ? campaign.get("currency_code") : "USD";
      asCurrency = T1.Utils.getCurrencyTemplateFunc(this.currencyCode);
      asDecimalCurrency = T1.Utils.getDecimalCurrencyTemplateFunc(this.currencyCode);
      formatCurrency = T1.Utils.formatCurrency;
      this.hasVcrImpression = OrgUtils.getOrgVcrSetting();
    },
    getCalculatedMarginAndTotalSpend: function (obj, _margin_pct) {
      var marginPct = parseFloat(_margin_pct);
      if (isNaN(marginPct) === true) {
        throw new Error("The incoming _margin_pct must be a valid number.");
      }
      function getMarginCalculated() {
        const marginVal = marginPct / perCent;
        return parseFloat(obj.cost_sum) * marginVal;
      }
      const marginCalculated = getMarginCalculated();
      function getTotalSpend() {
        var totalSpend = parseFloat(obj.total_spend_original);
        if (isNaN(totalSpend) === true) {
          throw new Error("The obj.total_spend_original cannot be missing. It must be provided by the api server.");
        }
        return marginCalculated + totalSpend - parseFloat(obj.margin);
      }
      return {
        total_spend: getTotalSpend(),
        marginCalculated: marginCalculated
      };
    },
    getCalculatedECPM: function (totalSpend, impressions) {
      let calculatedECPM = totalSpend / impressions * perMille;
      calculatedECPM = isNaN(calculatedECPM) || calculatedECPM === Number.POSITIVE_INFINITY || calculatedECPM === Number.NEGATIVE_INFINITY ? "0" : calculatedECPM;
      return calculatedECPM;
    },
    updateCampaignMarginData: function (id, _margin_pct, $itemWrapper, isCurrency) {
      var collection = this.collection;
      var model = collection.get(id);
      var modelJSON = model.toJSON();
      var self = this;
      var strategies = modelJSON.strategies;
      var getGoalPerformanceTotal = $.proxy(this.getGoalPerformanceTotal, this);
      isCurrency = isCurrency === undefined ? modelJSON.isCurrency : isCurrency;
      _margin_pct = parseFloat(_margin_pct);
      let vcrValue = this.hasVcrImpression ? modelJSON.video_complete_rate_impression_based : modelJSON.video_complete_rate;
      const calculatedMarginAndTotalSpend = this.getCalculatedMarginAndTotalSpend(modelJSON, _margin_pct);
      const calculatedECPM = this.getCalculatedECPM(calculatedMarginAndTotalSpend.total_spend, modelJSON.impressions);
      const campaignGoalPerformance = getGoalPerformanceTotal(modelJSON.campaign_goal_type, {
        totalSpendSum: calculatedMarginAndTotalSpend.total_spend,
        impressionsSum: modelJSON.impressions,
        totalConversionSum: modelJSON.total_conversions,
        clicksSum: modelJSON.clicks,
        revenue: parseFloat(modelJSON.revenue),
        videoCompletions: modelJSON.video_complete,
        videoStarts: modelJSON.video_start,
        videoCompleteRate: parseFloat(vcrValue),
        viewabilityRate: parseFloat(modelJSON.viewability_rate),
        inViewSum: modelJSON.in_view
      });
      const formattedCampaignGoalPerformance = isCurrency ? formatCurrency(campaignGoalPerformance, "--", this.currencyCode) : campaignGoalPerformance;
      model.set({
        _margin_pct: _margin_pct,
        total_spend: calculatedMarginAndTotalSpend.total_spend,
        _goal_performance: formattedCampaignGoalPerformance,
        ecpm: calculatedECPM,
        campaignGoalType: this.goalTypeText[this.collection.campaign.get("goal_type")],
        isCurrency: isCurrency
      });
      $.map(strategies, function (strategy) {
        const strategyCalculatedMarginAndTotalSpend = self.getCalculatedMarginAndTotalSpend(strategy, _margin_pct);
        const strategyCalculatedECPM = self.getCalculatedECPM(strategyCalculatedMarginAndTotalSpend.total_spend, strategy.impressions);
        vcrValue = self.hasVcrImpression ? strategy.video_complete_rate_impression_based : strategy.video_complete_rate;
        const strategyGoalPerformance = getGoalPerformanceTotal(strategy.campaign_goal_type, {
          totalSpendSum: strategyCalculatedMarginAndTotalSpend.total_spend,
          impressionsSum: strategy.impressions,
          totalConversionSum: strategy.total_conversions,
          clicksSum: strategy.clicks,
          revenue: parseFloat(strategy.revenue),
          videoCompletions: strategy.video_complete,
          videoStarts: strategy.video_start,
          videoCompleteRate: parseFloat(vcrValue),
          viewabilityRate: parseFloat(modelJSON.viewability_rate),
          inViewSum: strategy.in_view
        });
        const formattedStrategyGoalPerformance = isCurrency ? formatCurrency(strategyGoalPerformance, "--", self.currencyCode) : strategyGoalPerformance;
        strategy._margin_pct = parseFloat(_margin_pct).toFixed(fourDigits);
        strategy.total_spend = strategyCalculatedMarginAndTotalSpend.total_spend;
        strategy._goal_performance = formattedStrategyGoalPerformance;
        strategy.campaignGoalType = self.goalTypeText[self.collection.campaign.get("goal_type")];
        strategy.isCurrency = isCurrency;
        strategy.ecpm = strategyCalculatedECPM;
        if ($itemWrapper) {
          self.updateDataBind(strategy, $itemWrapper.find(`[data-id='${strategy.id}']`));
        }
        return strategy;
      });
      model.set({
        strategies: strategies
      });
      return model.toJSON();
    },
    onMarginChange: function (e) {
      var currentTarget = $(e.currentTarget);
      var $item = currentTarget.closest(".item");
      var $itemWrapper = $item.closest(".item-wrapper");
      var id = $item.data("id");
      var collection = this.collection;
      var model = collection.get(id);
      var modelJSON = model.toJSON();
      const changedMargin = `margin.${model.get("margin_date").replace("T00:00:00", "")}`;
      let marginPct = parseFloat(currentTarget.val());
      if (marginPct.toFixed(fourDigits) === parseFloat(modelJSON._margin_pct).toFixed(fourDigits)) {
        return;
      }
      if (!marginPct) {
        marginPct = 0;
      }
      if (marginPct < -perCent) {
        marginPct = -perCent;
      }
      this.updateCampaignMarginData(id, marginPct, $itemWrapper);
      this.mapItems();
      collection.changedMargins[changedMargin] = marginPct.toFixed(fourDigits);
      this.updateDataBind(this.formattedMapData[id], $item);
      this.updateDataBind(this.formattedMapData.totals, this.$(".w-foot"));
    },
    load: function () {
      this.render().then(() => {
        T1.Form.Masks.attach(this.$("input"));
      });
    },
    unload: function () {
      this.collection.changedMargins = {};
      this.formattedMapData = {};
    },
    getGoalPerformanceTotal: function (goal_type, data) {
      const twoDigits = 2;
      var cpmGoalPerformance = function () {
        return data.totalSpendSum / data.impressionsSum * perMille;
      };
      var cpaAndCpeGoalPerformance = function () {
        return data.totalSpendSum / data.totalConversionSum;
      };
      var goalPerformanceCalculations = {
        spend: cpmGoalPerformance,
        reach: cpmGoalPerformance,
        cpa: cpaAndCpeGoalPerformance,
        cpe: cpaAndCpeGoalPerformance,
        roi: () => (data.revenue / data.totalSpendSum).toFixed(twoDigits),
        cpc: () => data.totalSpendSum / data.clicksSum,
        ctr: () => (data.clicksSum / data.impressionsSum * perCent).toFixed(twoDigits),
        vcpm: () => data.totalSpendSum / data.inViewSum * perMille,
        vcr: () => data.videoCompleteRate.toFixed(twoDigits),
        viewability_rate: () => data.viewabilityRate.toFixed(twoDigits)
      };
      if (!(goal_type in goalPerformanceCalculations)) {
        return "--";
      }
      return divideByZero(goalPerformanceCalculations[goal_type]());
    },
    mapItems: function () {
      let blendedSumTotal = 0;
      let clicksSum = 0;
      let impressionsSum = 0;
      let inViewSum = 0;
      let measurableSum = 0;
      let revenue = 0;
      let totalAdCostSum = 0;
      let totalConversionSum = 0;
      let totalECPMSum = 0;
      let totalGoalPerformanceSum = 0;
      let totalSpendSum = 0;
      let videoCompleteSum = 0;
      let videoStartSum = 0;
      var self = this;
      var collection = this.collection;
      var goalType = collection.campaign.get("goal_type");
      var isCurrency = !(goalType === "ctr" || goalType === "vcr" || goalType === "viewability_rate");
      var marginData = collection.toJSON();
      function formatMarginDate(margin) {
        margin.margin_date_formatted = Date.parse(margin.margin_date).toString("MM/dd/yy");
        margin._margin_pct = parseFloat(margin._margin_pct).toFixed(fourDigits);
        return margin;
      }
      const resultArry = $.map(marginData, function (margin) {
        if (!self.formattedMapData[margin.id]) {
          margin = self.updateCampaignMarginData(margin.id, margin._margin_pct, null, isCurrency);
        }
        const data = formatMarginDate(margin);
        data.isCurrency = isCurrency;
        if (margin.campaign_goal_type === "roi") {
          data.isTypeROI = true;
        }
        const costSum = parseFloat(data.cost_sum);
        self.formattedMapData[data.id] = data;
        totalSpendSum += parseFloat(data.total_spend);
        totalAdCostSum += costSum;
        totalGoalPerformanceSum += parseFloat(data._goal_performance);
        blendedSumTotal += data._margin_pct * costSum;
        impressionsSum += parseFloat(data.impressions);
        totalConversionSum += parseFloat(data.total_conversions);
        clicksSum += parseFloat(data.clicks);
        videoStartSum += parseFloat(data.video_start);
        videoCompleteSum += parseFloat(data.video_complete);
        measurableSum += parseFloat(data.measurable);
        inViewSum += parseFloat(data.in_view);
        if (data.revenue) {
          revenue += parseFloat(data.revenue);
        }
        return data;
      });
      let totalMarginPctSum = blendedSumTotal / totalAdCostSum;
      totalMarginPctSum = `${isNaN(totalMarginPctSum) ? "--" : totalMarginPctSum.toFixed(fourDigits)} %`;
      totalECPMSum = (totalSpendSum / impressionsSum * perMille).toFixed(fourDigits);
      totalECPMSum = isNaN(totalECPMSum) ? "0" : totalECPMSum;
      let totalVideoCompleteRate;
      if (this.hasVcrImpression) {
        totalVideoCompleteRate = videoCompleteSum / impressionsSum * perCent;
      } else {
        totalVideoCompleteRate = videoCompleteSum / videoStartSum * perCent;
      }
      const totalViewabilityRate = inViewSum / measurableSum * perCent;
      totalGoalPerformanceSum = this.getGoalPerformanceTotal(collection.campaign.get("goal_type"), {
        totalSpendSum: totalSpendSum,
        impressionsSum: impressionsSum,
        totalConversionSum: totalConversionSum,
        clicksSum: clicksSum,
        revenue: revenue,
        videoCompleteRate: totalVideoCompleteRate,
        viewabilityRate: totalViewabilityRate,
        inViewSum: inViewSum
      });
      const formattedTotalGoalPerformanceSum = isCurrency ? formatCurrency(totalGoalPerformanceSum, "--", this.currencyCode) : totalGoalPerformanceSum;
      totalSpendSum = totalSpendSum.toFixed(fourDigits);
      totalAdCostSum = totalAdCostSum.toFixed(fourDigits);
      this.formattedMapData.totals = {
        total_spend_sum: totalSpendSum,
        total_ad_cost_sum: totalAdCostSum,
        total_goal_performance_sum: formattedTotalGoalPerformanceSum,
        total_margin_pct_sum: totalMarginPctSum,
        ecpm_sum: totalECPMSum
      };
      return {
        list: resultArry,
        total_spend_sum: totalSpendSum,
        total_ad_cost_sum: totalAdCostSum,
        asCurrency: asCurrency,
        asDecimalCurrency: asDecimalCurrency,
        total_goal_performance_sum: formattedTotalGoalPerformanceSum,
        total_margin_pct_sum: totalMarginPctSum,
        totalCampaignGoalType: this.goalTypeText[this.collection.campaign.get("goal_type")],
        ecpm_sum: totalECPMSum,
        isCurrency: isCurrency
      };
    },
    toggleNode: function (event) {
      var item = $(event.currentTarget);
      var arrow = item.find(".arrow");
      var iconArrow = arrow.find(".icon-arrow");
      var state = iconArrow.hasClass("expanded");
      if (!arrow.length || arrow.hasClass("hidden")) {
        return;
      }
      this.preventDefault(event);
      const children = item.closest(".item-wrapper").children(".children");
      if (!children.find(".item-wrapper").length) {
        const id = item.data("id");
        const strategiesData = this.collection.get(id).get("strategies");
        if (strategiesData.length) {
          $.each(strategiesData, function (index, data) {
            if (data.campaign_goal_type === "roi") {
              data.isTypeROI = true;
            } else if (data.campaign_goal_type === "ctr") {
              data.symbol = "%";
            }
            data.asCurrency = asCurrency;
            data.asDecimalCurrency = asDecimalCurrency;
            strategiesData[index] = data;
          });
        }
        this.renderPartialTemplate({
          useTemplateHtml: false,
          template: "{{#list}} {{>listItem}}{{/list}}",
          targetEl: ".children",
          context: item.closest(".item-wrapper"),
          data: {
            list: strategiesData
          },
          action: "append",
          skipDelegateEvents: true,
          skipAttachFormMasks: true
        });
        this.updateScrollBar();
      }
      if (!state) {
        iconArrow.addClass("expanded");
        children.show();
      } else {
        children.hide();
        iconArrow.removeClass("expanded");
      }
      this.updateScrollBar();
    },
    serialize: function () {
      var defaultData = {
        widgetClassName: "w-CampaignMarginBrowse",
        loading: false
      };
      var returnObj = {};
      var mapData = this.mapItems();
      $.extend(returnObj, defaultData, mapData);
      returnObj.isTypeROI = returnObj.totalCampaignGoalType === "roi";
      return returnObj;
    }
  });
  return createGrid(EditMarginBrowseView);
});
