define('modules/reporting/campaigns/reachFrequency/views/grid',['require','jQuery','Underscore','Hogan','T1PerformanceTimer','T1View','text!../templates/grid.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const Hogan = require("Hogan");
  const T1Timer = require("T1PerformanceTimer");
  const T1View = require("T1View");
  const template = require("text!../templates/grid.html");
  var GAMap = {
    render: "RPTG:REACHFREQUENCY:GRID:RENDER"
  };
  var GridView = T1View.extend({
    selected: "",
    keys: [],
    headers: [],
    total: 0,
    count: 0,
    mainKey: "frequency_bin",
    section: "reachFrequency",
    template: template,
    events: {
      "column-sort strand-grid-column": "sort"
    },
    dataEvents: {
      collection: {
        sort: function () {
          this.updateGridData();
        }
      },
      reportModel: {
        "change:columnKeys": "reload"
      }
    },
    eventhubEvents: {
      "panel.filteredItemsChange": function () {
        this.updateGridData();
      }
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    initialize: function (args) {
      var models = args.models;
      this.reportMeta = models.reportMeta;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
      this.campaign = models.campaign;
      this.collection = args.collection;
      this.totalCollection = args.dataGroup.totalCollection;
      this.type = args.type;
      this.compiledTemplate = Hogan.compile(this.template);
      this.bindViewEvents();
    },
    load: function () {
      var self = this;
      var $el = this.el;
      var timerData;
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (!this.reportModel.get("columnKeys")) {
        return;
      }
      timerData = {
        category: GAMap.render,
        label: this.campaign.id
      };
      T1Timer.start(timerData);
      this.prepareGridHeaders();
      this.render().then(function () {
        T1Timer.stop(timerData);
        self.$noDataGrid = $el.find(".no-data-grid");
        self.rfGrid = $el.find("#rfGrid")[0];
        setTimeout(function () {
          self.updateGridData();
        }, 0);
      });
    },
    prepareGridHeaders: function () {
      var headers = [];
      var reportModel = this.reportModel;
      var columns = {};
      var defaultHeaders = {};
      var label, reportModelObj;
      var sortKeyInfo = reportModel.get("sortKeyInfo") || "";
      var sortKeyArray = sortKeyInfo.length > 0 ? sortKeyInfo.split(",") : [];
      var sortKey = sortKeyInfo.length > 0 ? sortKeyArray[0] : "";
      var sortKeyOrder = sortKeyInfo.length > 0 ? parseInt(sortKeyArray[1], 10) : -1;
      var totalHeaders = 0;
      var secondColumn = "uniques";
      const MAX_HEADERS = 9;
      reportModelObj = this.reportModelObj = $.extend({}, reportModel.toJSON());
      this.keys = [this.mainKey, secondColumn].concat(reportModelObj.columnKeys);
      defaultHeaders[this.mainKey] = "FREQUENCY";
      defaultHeaders[secondColumn] = secondColumn.toUpperCase();
      columns = $.extend(defaultHeaders, reportModelObj.formattedHeaderValues);
      totalHeaders = this.keys.length;
      _.each(this.keys, function (metric, i) {
        var isFirstColumn = i === 0;
        label = columns[metric];
        headers.push({
          align: isFirstColumn ? "left" : "right",
          field: metric,
          label: label.replace(" ", "\n"),
          width: isFirstColumn ? "15%" : totalHeaders > MAX_HEADERS ? "9%" : "10%"
        });
      });
      this.sortOrder = sortKeyOrder === -1 ? 1 : -1;
      this.sortField = sortKey;
      this.headers = headers;
    },
    updateGridData: function () {
      var totalRow = {};
      var gridData = [];
      var self = this;
      var reportModel = this.reportModel;
      var $noDataGrid = this.$noDataGrid;
      var reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      var collection = this.collection;
      $noDataGrid.addClass("no-display");
      this.formatGridFooter();
      if (this.totalCollection.loaded && collection && collection.loaded) {
        gridData = this.sortGridData(true) || collection.toJSON() || [];
        this.formatGridFooter(gridData);
        if (gridData.length > 0) {
          totalRow = this.totalCollection.toJSON()[0];
          totalRow.frequency_bin = reportModel.getTotalFrequencyBin(totalRow);
          gridData.splice(0, 0, totalRow);
          gridData = reportModel.formatGridData(gridData, this.keys, reportMetaObj);
          self.rfGrid.set("data", gridData);
        } else {
          $noDataGrid.removeClass("no-display");
        }
      }
    },
    sortGridData: function (silent) {
      var reportModel = this.reportModel;
      var sortKeyInfo = reportModel.get("sortKeyInfo") || "";
      var gridData, key, order, sortKeyArray;
      var collection = this.collection;
      const TIMES_SERIES = 2;
      if (sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        key = sortKeyArray[0];
        order = parseInt(sortKeyArray[1], 10);
        if (key === "frequency_bin") {
          gridData = collection.toJSON() || [];
          gridData = reportModel.sortByFrequencyBin(gridData, order);
        } else {
          collection.sortByKey(key, order, this.type, silent, parseInt(sortKeyArray[TIMES_SERIES], 10));
        }
      }
      return gridData;
    },
    formatGridFooter: function (data) {
      this.total = this.count = data ? data.length : 0;
      this.renderPartial(".footer");
    },
    sort: function (event) {
      var eventTarget = event.target;
      var sortField = eventTarget.sortField;
      var sortOrder = eventTarget.sortOrder === -1 ? 1 : -1;
      var isName = this.keys[0] === sortField ? "1" : "0";
      this.reportModel.setSortKeyInfo(`${sortField},${sortOrder},${isName}`);
    },
    render: function () {
      var deferred = $.Deferred();
      var $el = this.el;
      var renderedTemplate = this.compiledTemplate.render(this.serialize());
      if (Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = renderedTemplate;
      } else {
        $el.html(renderedTemplate);
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      window.requestAnimationFrame(function () {
        deferred.resolve();
      });
      return deferred.promise();
    },
    serialize: function () {
      return {
        count: this.count,
        headers: this.headers,
        model: "{{model}}",
        scope: "{{scope}}",
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        total: this.total,
        totalRow: "{{model.totalRow}}"
      };
    }
  });
  return GridView;
});
