define('models/attribute',["jQuery", "T1", "Backbone"], function ($, T1, Backbone) {
  "use strict";

  var Attribute = Backbone.Model.extend({
    defaults: {
      source: "site"
    },
    urlRoot: T1.SEGMENT_API_BASE + "attributes",
    parse: function (resp) {
      return resp.data;
    }
  });
  return Attribute;
});
