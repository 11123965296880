define('modules/strategy/views/supply',['require','Underscore','jQuery','T1','T1Permissions','async','T1View','T1TabLayout','T1UtilsAsync','utils/LaunchDarklyUtils','modules/strategy/supply/open/views/panel','modules/strategy/supply/pmpe/views/panel','modules/strategy/supply/pmpd/views/panel','modules/strategy/supply/batch/views/panel','collections/strategySupplySources','collections/supplySources','collections/marketplaces','collections/targetValues','collections/organizations','models/strategyTargetDimensions','text!modules/strategy/templates/supplyTab.html','text!templates/alert.html','utils/currencyConversionHelper','utils/CampaignStrategyUtils'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Permissions = require("T1Permissions");
  const Async = require("async");
  const T1View = require("T1View");
  const T1TabLayout = require("T1TabLayout");
  const T1UtilsAsync = require("T1UtilsAsync");
  const LaunchDarklyUtils = require("utils/LaunchDarklyUtils");
  const OpenPanel = require("modules/strategy/supply/open/views/panel");
  const PmpePanel = require("modules/strategy/supply/pmpe/views/panel");
  const PmpdPanel = require("modules/strategy/supply/pmpd/views/panel");
  const BatchPanel = require("modules/strategy/supply/batch/views/panel");
  const StrategySupplySources = require("collections/strategySupplySources");
  const SupplySources = require("collections/supplySources");
  const Marketplaces = require("collections/marketplaces");
  const TargetValues = require("collections/targetValues");
  const Orgs = require("collections/organizations");
  const StratTargetDims = require("models/strategyTargetDimensions");
  const tmpl = require("text!modules/strategy/templates/supplyTab.html");
  const alert_tmpl = require("text!templates/alert.html");
  const CurrencyUtils = require("utils/currencyConversionHelper");
  const CampaignStrategyUtils = require("utils/CampaignStrategyUtils");
  var foldIds = {
    above: "45054",
    below: "45055",
    unknown: "45056"
  };
  var currencyFormatterGetter = T1.Utils.getCurrencyTemplateFunc;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var SupplyView = T1View.extend({
    template: tmpl,
    cache: function () {
      return {};
    }(),
    hasFormSubViews: true,
    events: {
      subViewLoad: "subViewLoadHandler"
    },
    eventhubEvents: {
      "supplyFpt:update": "updateFpt",
      "supply:onTypeChange": "onTypeChange",
      "supply:save": "postData",
      "supply:noSelection": "showNoSelectionAlert",
      "supply:showWarning": function (type) {
        this.toggleWarning("show", "add", type);
      },
      "supply:hideWarning": function (type) {
        this.toggleWarning("hide", "remove", type);
      },
      "supply:resetFpt": function () {
        this.sharedFptCollection.reset();
      },
      "strategy-create-edit:cancelUnload": "cancelUnload"
    },
    initialize: function () {
      const {
        cache: cache,
        model: model
      } = this;
      const advSourceProp = this.model.get("campaign").get("advertiser").get("accountable_and_addressable");
      cache.exchanges = model.get("run_on_all_exchanges");
      cache.pmp = model.get("run_on_all_pmp");
      this.canViewPmpdVideo = T1Permissions.check("feature", "pmp_d.video");
      this.targetAllFlagEnabled = T1Permissions.check("feature", "target_all_supply");
      this.isSourceFeatureEnabled = T1Permissions.check("feature", "source");
      this.isSourceEnabledOnAdv = advSourceProp === "1";
      if (this.model.get("source_only") === "1") {
        this.model.set({
          exchange_type_for_run_on_all: "SOURCE"
        });
      } else if (this.model.get("run_on_all_exchanges") === "1") {
        this.model.set({
          exchange_type_for_run_on_all: "ALL_ELIGIBLE"
        });
      } else {
        this.model.set({
          exchange_type_for_run_on_all: "SELECTED_ONLY"
        });
      }
      this.setOrg();
      this.prepareFetch();
    },
    subViewLoadHandler: function (e) {
      e.stopPropagation();
      this.initUnsavedStateCheck();
      if (window.hybridWorkflowParams.isHybrid) {
        updateCancelBtn(this.model.get("campaign"));
        this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
        this.$cancelBtn = $(".cancelNewT1");
        this.setNewT1ClickHandlers();
      }
    },
    setOrg: function () {
      var setOrgId = function (id) {
        this.org = id;
      };
      Orgs.getOrganizations().getSelected().then(_.bind(setOrgId, this));
    },
    toggleWarning: function (hideOrShow, addOrRemove, currentType) {
      var otherTypes = {
        supply_source: ["Private Marketplace Exchange", "Private Marketplace Direct"],
        deals: ["Open Supply", "Private Marketplace Direct"],
        site_placement: ["Open Supply", "Private Marketplace Exchange"]
      }[currentType];
      if (!otherTypes) {
        otherTypes = ["", ""];
      }
      this.$(".tabWarning")[hideOrShow]().end().find(".w-OpenSupply")[`${addOrRemove}Class`]("warning").end().find("#first-type").html(otherTypes[0]).end().find("#second-type").html(otherTypes[1]);
    },
    prepareFetch: function () {
      const campaign = this.model.get("campaign");
      const advertiserId = campaign.get("advertiser_id");
      const currencyCode = campaign.get("currency_code");
      const marketplaceSearchField = this.model.get("media_type") === "DISPLAY" ? "has_display" : "has_video";
      const sharedCollection = this.sharedCollection = new StrategySupplySources(false, {
        isPaginated: false,
        fetchOptions: {
          currency_code: this.model.get("currency_code")
        }
      });
      sharedCollection.advertiserId = advertiserId;
      sharedCollection.currencyCode = currencyCode;
      sharedCollection.id = this.model.id;
      sharedCollection.strategy = this.model;
      sharedCollection.singleFetch = true;
      const dims = this.strategyTargetDimensions = new StratTargetDims({
        id: this.model.id
      });
      dims.strategy = this.model;
      this.supplyColl = new SupplySources(false, {
        fetchOptions: {
          full: "*"
        },
        isPaginated: false
      });
      this.supplyColl.search.set({
        params: [{
          term: "STANDARD",
          searchField: "rtb_type"
        }]
      });
      this.marketplaceColl = new Marketplaces(false, {
        fetchOptions: {
          full: "*",
          advertiser_id: advertiserId
        },
        isPaginated: false
      });
      this.marketplaceColl.search.set({
        params: [{
          term: "1",
          searchField: marketplaceSearchField
        }]
      });
      this.fetchData(sharedCollection, dims, _.bind(this.prepareLayout, this));
    },
    fetchData: function (sharedCollection, dims, callback) {
      const supplyColl = this.supplyColl;
      const marketplaceColl = this.marketplaceColl;
      const agencyId = this.model.get("campaign").get("advertiser").get("agency_id");
      const advertiserId = this.model.get("campaign").get("advertiser_id");
      Async.parallel([function fetchSharedCollection(cb) {
        LaunchDarklyUtils.getPromise().then(() => {
          LaunchDarklyUtils.updateAgency(agencyId);
          LaunchDarklyUtils.updateAdvertiser(advertiserId);
          sharedCollection.fetch({
            success: function () {
              const unpermissionedFetch = T1UtilsAsync.makeDeferral();
              sharedCollection.fetchPermissionedDeals(sharedCollection.advertiserId, sharedCollection.currencyCode, unpermissionedFetch);
              unpermissionedFetch.promise.then(() => {
                sharedCollection.fetched = true;
                cb(null, sharedCollection);
                sharedCollection.cachedSupplySources = sharedCollection.models.slice();
                sharedCollection.cachedContentType = sharedCollection.contentType;
              });
            }
          });
        });
      }, function fetchStratTargetDims(cb) {
        dims.fetch({
          success: function (data) {
            sharedCollection.fetched = true;
            cb(null, data);
          }
        });
      }, function fetchSupplySources(cb) {
        supplyColl.fetchAllPages({
          success: function () {
            supplyColl.fetched = true;
            cb(null, supplyColl);
          }
        });
      }, function fetchMarketplaces(cb) {
        marketplaceColl.fetchAllPages({
          success: function () {
            marketplaceColl.fetched = true;
            cb(null, marketplaceColl);
          }
        });
      }], callback);
    },
    prepareLayout: function () {
      var sharedCollection = this.sharedCollection;
      var foldTargets = this.getFoldTargets();
      var currencyCode = this.model.get("currency_code");
      const advertiser = sharedCollection.strategy.get("campaign").get("advertiser");
      const isSourceDisabledOnAdv = advertiser.get("accountable_and_addressable") === "0";
      const isSourceFlagOff = !T1Permissions.check("feature", "source");
      this.supplyColl.add(this.marketplaceColl.toJSON(), {
        silent: true
      });
      this.fptModelOptions = this.createFptOptions();
      this.createSharedFptCollection(foldTargets);
      sharedCollection.setContentType(this.targetAllFlagEnabled ? "" : this.getContentType());
      sharedCollection.filterGarbageData();
      if (sharedCollection.contentType === "supply_source") {
        if (isSourceDisabledOnAdv || isSourceFlagOff) {
          sharedCollection.reset(sharedCollection.filter(model => {
            const isNonSourceSupply = model.get("accountable_and_addressable") === "0";
            return isNonSourceSupply;
          }), {
            silent: true
          });
        }
      }
      if (sharedCollection.contentType === "deals") {
        if (sharedCollection.length) {
          if (isSourceDisabledOnAdv || isSourceFlagOff) {
            sharedCollection.reset(sharedCollection.filter(model => {
              if (model.get("entity_type") === "deal_group") {
                return true;
              } else if (model.get("type") === "deal") {
                const supplySourceId = model.get("supply_source_id");
                const supplySource = this.supplyColl.get(supplySourceId);
                if (supplySource && supplySource.get("id") === supplySourceId) {
                  const accountableAndAddressable = supplySource.get("accountable_and_addressable");
                  model.set({
                    accountable_and_addressable: accountableAndAddressable
                  });
                }
                const isNonSourceSupply = model.get("accountable_and_addressable") === "0";
                return isNonSourceSupply;
              }
              return false;
            }), {
              silent: true
            });
            if (sharedCollection.unpermissionedDeals) {
              sharedCollection.unpermissionedDeals = sharedCollection.unpermissionedDeals.filter(model => {
                const supplySourceId = model.get("supply_source_id");
                const supplySource = this.supplyColl.get(supplySourceId);
                if (supplySource && supplySource.get("id") === supplySourceId) {
                  const accountableAndAddressable = supplySource.get("accountable_and_addressable");
                  model.set({
                    accountable_and_addressable: accountableAndAddressable
                  });
                }
                const isNonSourceSupply = model.get("accountable_and_addressable") === "0";
                return isNonSourceSupply;
              });
              sharedCollection.hasUnpermissionedDeals = Boolean(sharedCollection.unpermissionedDeals.length);
            }
          }
          sharedCollection = CurrencyUtils.updatePMPePriceWithCurrency(sharedCollection, currencyCode);
        }
      }
      sharedCollection.strategyTargetDimensions = this.strategyTargetDimensions;
      this.selectedTab = this.getSelectedTab(sharedCollection.contentType);
      this.initializeTabs();
    },
    getFoldTargets: function () {
      var dims = this.strategyTargetDimensions.get("target_dimensions");
      return _.chain(dims.models).filter(function findFold(dim) {
        return dim.get("code") === "FOLD";
      }).map(function getTargetValues(fold_dim) {
        return fold_dim.get("target_value").models;
      }).first().value();
    },
    createSharedFptCollection: function (targets) {
      this.sharedFptCollection = new TargetValues(targets, {
        dimension: "FOLD"
      });
      this.cache.folds = this.sharedFptCollection.map(model => model.get("code").toLowerCase());
      this.sharedCollection.foldTargetValues = this.sharedFptCollection;
    },
    createFptOptions: function () {
      return {
        above: {
          action: "include",
          id: foldIds.above,
          dimension: "FOLD",
          type: "above"
        },
        below: {
          action: "include",
          id: foldIds.below,
          dimension: "FOLD",
          type: "below"
        },
        unknown: {
          action: "include",
          id: foldIds.unknown,
          dimension: "FOLD",
          type: "unknown"
        }
      };
    },
    updateFpt: function ({
      isChecked: isChecked,
      name: name
    }) {
      const toggled = this.fptModelOptions[name];
      const action = isChecked ? "add" : "remove";
      this.sharedFptCollection[action](toggled);
      this.sharedCollection.isFormElementsChanged = true;
    },
    restoreSharedFptCollection: function () {
      var models = _.map(this.cache.folds, foldType => this.fptModelOptions[foldType]);
      this.sharedFptCollection.reset(models);
    },
    hideUnpermissionedDealsWarning: function () {
      T1.EventHub.publish("hideUnpermissionedDealsWarning:reloadBins");
    },
    getContentType: function () {
      const {
        model: model,
        sharedCollection: sharedCollection
      } = this;
      const isAllPMP = model.get("run_on_all_pmp") === "1";
      const isAllExchanges = model.get("run_on_all_exchanges") === "1";
      const firstModel = sharedCollection.at(0);
      const isFirstModelDealGroup = !(firstModel && firstModel.get("type"));
      const isOpenSupplyDefault = isAllExchanges || !firstModel;
      if (isAllPMP) {
        return "deals";
      } else if (isOpenSupplyDefault) {
        return "supply_source";
      } else if (isFirstModelDealGroup) {
        return "deals";
      }
      return firstModel.get("type") === "deal" ? "deals" : firstModel.get("type");
    },
    getSelectedTab: function (contentType) {
      if (!this.isPGStrategy) {
        this.isPGStrategy = CampaignStrategyUtils.isProgrammaticGuaranteed(this.model.get("campaign"));
      }
      if (this.isPGStrategy) {
        return 1;
      }
      if (this.targetAllFlagEnabled) {
        return 0;
      }
      return {
        supply_source: 0,
        deals: 1,
        site_placement: 2
      }[contentType];
    },
    getCssSelector: function (tabIndex) {
      return `.tab-${tabIndex + 1}-level-2-item`;
    },
    initializeTabs: function () {
      const allowVideoPMPE = true;
      const isVideoStrategy = this.model.get("media_type").toLocaleLowerCase() === "video";
      const isFacebookStrategy = this.model.get("supply_type").toLocaleLowerCase() === "batch";
      const opts = {
        model: this.model,
        sharedCollection: this.sharedCollection,
        sharedFptCollection: this.sharedFptCollection,
        foldTargets: this.foldTargets,
        mapItemsBins: this.mapItemsBins,
        hasUnpermissionedDeals: this.sharedCollection.hasUnpermissionedDeals,
        isPGStrategy: this.isPGStrategy,
        addOrRemoveSharedCollection: this.addOrRemoveSharedCollection,
        targetAllFlagEnabled: this.targetAllFlagEnabled
      };
      let tabs = [{
        label: "Open Supply",
        panel: new OpenPanel(opts)
      }];
      if (!isVideoStrategy || isVideoStrategy && allowVideoPMPE) {
        tabs.push({
          label: "Private Marketplace Exchange",
          panel: new PmpePanel(opts)
        });
      }
      if (!isVideoStrategy || isVideoStrategy && this.canViewPmpdVideo) {
        tabs.push({
          label: "Private Marketplace Direct",
          panel: new PmpdPanel(opts)
        });
      }
      if (isFacebookStrategy) {
        tabs = [{
          label: "Facebook",
          panel: new BatchPanel(opts)
        }];
      }
      this.layout = new T1TabLayout({
        el: () => this.$(".supply-tabs"),
        tabSetName: "StrategySupply",
        level: 2,
        selected: this.selectedTab,
        tabs: tabs
      });
      this.layout.load();
    },
    mapItemsBins: function (items) {
      const bins = [];
      const isAllPMP = this.model.get("run_on_all_pmp") === "1";
      const allExchanges = this.model.get("run_on_all_exchanges") === "1";
      const maxLen = 25;
      const dealBinsGroup = {
        bin_show_group_name: true,
        showGroupNameOnly: true,
        bin_group_name: "Deals",
        groupItems: [],
        count: "any",
        has_unpermissioned_deals: this.options.hasUnpermissionedDeals
      };
      const pmpdBinsGroup = {
        bin_show_group_name: true,
        bin_group_name: "PRIVATE MARKETPLACE DIRECT",
        showGroupNameOnly: true,
        groupItems: []
      };
      function getBinGroup(name) {
        return {
          bin_show_group_name: true,
          showGroupNameOnly: true,
          bin_group_name: name,
          groupItems: [],
          count: "any"
        };
      }
      const dealGroupBinsGroup = getBinGroup("Deal Groups");
      let marketplacesBinsGroup, openSupplyBinsGroup;
      this.ensureCorrectWarning(isAllPMP);
      if (!isAllPMP) {
        dealBinsGroup.groupItems = items.filter(item => item.get("type") === "deal").map(item => ({
          name: item.get("name"),
          truncated: T1.Utils.trunc(item.get("name"), maxLen),
          id: item.id,
          price: item.get("price"),
          price_type: item.get("price_type"),
          asCurrency: currencyFormatterGetter(item.get("currency_code"))
        }));
        dealGroupBinsGroup.groupItems = items.filter(item => item.get("entity_type") === "deal_group").map(item => ({
          name: item.get("name"),
          truncated: T1.Utils.trunc(item.get("name"), maxLen),
          id: `${item.id}`
        }));
      }
      pmpdBinsGroup.groupItems = _.chain(items).filter(function getOnlyCorrectType(model) {
        return model.get("type") === "site_placement";
      }).map(function getTemplateData(model) {
        return {
          name: model.get("placement") || model.get("display_text"),
          id: model.id
        };
      }).forEach(function (item) {
        item.truncated = T1.Utils.trunc(item.name, maxLen);
      }).value();
      if (allExchanges) {
        const exchangeType = this.sharedCollection.strategy.get("exchange_type_for_run_on_all");
        const isSourceFeatureEnabled = T1Permissions.check("feature", "source");
        const isSourceEnabledOnAdv = this.model.get("campaign").get("advertiser").get("accountable_and_addressable");
        const groupName = exchangeType === "ALL_ELIGIBLE" || !isSourceFeatureEnabled || !isSourceEnabledOnAdv ? "Open Exchanges & SSPs" : "SOURCE Exchanges & SSPs";
        openSupplyBinsGroup = getBinGroup(groupName);
        marketplacesBinsGroup = getBinGroup("Marketplaces");
      } else {
        [openSupplyBinsGroup, marketplacesBinsGroup] = _.chain(items).filter(item => item.get("type") === "supply_source").groupBy(model => model.get("rtb_type")).map((group, rtbType) => {
          var mapped = {
            bin_show_group_name: true,
            showGroupNameOnly: true
          };
          mapped.bin_group_name = {
            STANDARD: "Open Exchanges & SSPs",
            MARKETPLACE: "Marketplaces"
          }[rtbType];
          mapped.groupItems = allExchanges ? [] : group.map(function (model) {
            var name = model.get("name");
            return {
              name: name,
              truncated: T1.Utils.trunc(name, maxLen),
              id: model.id
            };
          });
          mapped.count = allExchanges ? "ANY" : "";
          return mapped;
        }).tap(function correctOrdering(groups) {
          const second = "Marketplaces";
          const len = second.length;
          const switchGroupLen = 2;
          const shouldSwitch = groups.length === switchGroupLen && groups[0].bin_group_name.slice(0, len) === second;
          if (shouldSwitch) {
            groups.reverse();
          }
        }).value();
      }
      dealBinsGroup.count = isAllPMP ? "ANY" : "";
      dealGroupBinsGroup.count = isAllPMP ? "ANY" : "";
      function addGroupBins(binsArr, group) {
        if (group && group.groupItems && group.groupItems.length) {
          group.bin_group_name += ` (${group.groupItems.length})`;
          binsArr.push(group);
        }
        return binsArr;
      }
      [dealBinsGroup, dealGroupBinsGroup, openSupplyBinsGroup, marketplacesBinsGroup, pmpdBinsGroup].reduce(addGroupBins, bins);
      if (isAllPMP) {
        bins.push(dealBinsGroup);
      }
      if (allExchanges) {
        bins.push(openSupplyBinsGroup);
      }
      return bins;
    },
    addOrRemoveSharedCollection: function (self, model, action) {
      if (self.sharedCollection.isSaving) {
        self.sharedCollection.changedWhileSaving = true;
      }
      self.sharedCollectionHasChanged(model, action);
    },
    onTypeChange: function (params) {
      const self = this;
      if (!this.alertOpen) {
        this.alertOpen = true;
        const sharedCollection = this.sharedCollection;
        const $alert = $("<div></div>");
        this.renderPartialTemplate({
          template: alert_tmpl,
          data: {
            header: params.header,
            message: params.message
          },
          useTemplateHtml: false,
          targetEl: $alert
        });
        $alert.dialog({
          autoOpen: true,
          modal: true,
          dialogClass: "w-WarningAlert",
          buttons: [{
            text: "Confirm",
            class: "save",
            click: function () {
              $alert.dialog("close");
              sharedCollection.reset();
              T1.EventHub.publish(params.callbackEvent, params);
            }
          }, {
            text: "Cancel",
            class: "cancel",
            click: function () {
              $alert.dialog("close");
              T1.EventHub.publish("supply:cancelTypeChange");
            }
          }],
          close: function () {
            $(this).remove();
            self.alertOpen = false;
          }
        });
      }
    },
    setNewT1ClickHandlers() {
      const {
        $breadcrumbLink: $breadcrumbLink,
        $cancelBtn: $cancelBtn
      } = this;
      if ($breadcrumbLink && $cancelBtn) {
        const handlerProps = {
          name: this.model.get("name"),
          scope: this
        };
        $breadcrumbLink.off("click", newt1RedirectHandler);
        $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
        $cancelBtn.off("click", newt1RedirectHandler);
        $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.sharedCollection.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        if (this.model.get("name")) {
          strategyName = this.model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          this.setNewT1ClickHandlers();
        }
        if (this.checkingUnsavedState !== true) {
          this.openUnloadAlertDialog({
            name: strategyName
          }, {
            onAlertClose: function () {
              const abortedUrl = T1.Location.getAbortedURL();
              if (onTabChange && onTabChange.onAlertClose) {
                onTabChange.onAlertClose();
              } else if (abortedUrl) {
                T1.Location.set(abortedUrl);
              }
            }
          }, false, false, true);
        }
        return !isFormElementsChanged;
      }
      if (!this.sharedCollection.length && this.sharedCollection.loaded && !this.model.get("run_on_all_exchanges")) {
        this.showNoSelectionAlert();
        return false;
      }
      return !isFormElementsChanged;
    },
    unload() {
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    onDontSave: function () {
      var sharedCollection = this.sharedCollection;
      sharedCollection.isFormElementsChanged = false;
      if (!sharedCollection.isSaving) {
        sharedCollection.reset(sharedCollection.cachedSupplySources);
        sharedCollection.contentType = sharedCollection.cachedContentType;
      }
      this.sharedFptCollection.reset(this.model.get("cached_fold_target_values"));
      this.model.set({
        run_on_all_pmp: this.model.get("cached_run_on_all_pmp"),
        run_on_all_exchanges: this.model.get("cached_run_on_all_exchanges"),
        exchange_type_for_run_on_all: this.model.get("cached_exchange_type_for_run_on_all")
      }, {
        silent: true
      });
    },
    postData: function (formValues, successCallback, statusInvalidCallback) {
      const self = this;
      const {
        model: strategy,
        sharedCollection: sharedCollection
      } = this;
      const isRunOnAllExchanges = strategy.get("run_on_all_exchanges") === "1";
      const isRunOnAllPmp = strategy.get("run_on_all_pmp") === "1";
      const saveOverlay = this.$(".supply-save-overlay");
      const shouldntSave = !(sharedCollection.length || isRunOnAllExchanges || isRunOnAllPmp) && sharedCollection.fetched;
      const additionalData = this.isPGStrategy ? {} : {
        all_pmp: strategy.get("run_on_all_pmp") || "0",
        all_exchanges: strategy.get("run_on_all_exchanges") || "0",
        source_only: "0"
      };
      if (!this.isPGStrategy && this.sharedCollection.contentType === "supply_source") {
        if (this.isSourceFeatureEnabled) {
          const exchangeType = strategy.get("exchange_type_for_run_on_all");
          const isSource = exchangeType === "SOURCE";
          additionalData.all_exchanges = exchangeType === "ALL_ELIGIBLE" || isSource ? "1" : "0";
          additionalData.source_only = isSource ? "1" : "0";
        }
      }
      saveOverlay.css({
        display: "block"
      });
      if (sharedCollection.hasUnpermissionedDeals) {
        sharedCollection.hasUnpermissionedDeals = false;
        this.hideUnpermissionedDealsWarning();
      }
      if (sharedCollection.isFormElementsChanged) {
        this.isFormElementsChanged = sharedCollection.isFormElementsChanged;
      }
      function saveSuccessful() {
        sharedCollection.reset(sharedCollection.models);
        sharedCollection.setContentType(self.getContentType());
        let targetValuesPromise;
        if (self.isPGStrategy) {
          const targetValues = T1UtilsAsync.makeDeferral();
          targetValuesPromise = targetValues.promise;
          targetValues.resolve();
        } else {
          targetValuesPromise = sharedCollection.saveFoldTargets(self, statusInvalidCallback);
        }
        targetValuesPromise.then(function (args) {
          sharedCollection.cachedSupplySources = sharedCollection.models.slice();
          sharedCollection.cachedContentType = sharedCollection.contentType;
          if (args && args.hasErr === true && args.emptyTargetValues) {
            self.stopSpinner();
            self.updateFooterMessage({
              error: true,
              message: "You will be wiping out all target values!"
            });
            self.openWipeTargetAlertDialog(undefined, {
              wipeTargetValues: true
            });
            self.stopSpinner($(".saveAndContinue"));
          } else if (args && args.hasErr === true) {
            throw new Error("Saving action failed on strategyTargetDimension object. Check post log for details.");
          } else {
            sharedCollection.isFormElementsChanged = false;
            self.initUnsavedStateCheck();
            self.strategyTargetDimensions.fetch({
              success: function () {
                saveOverlay.css({
                  display: "none"
                });
                self.selectedTab = self.getSelectedTab(sharedCollection.contentType);
                const foldTargetValues = self.sharedFptCollection.models.slice();
                if (successCallback) {
                  successCallback();
                }
                self.cache.exchanges = strategy.get("run_on_all_exchanges");
                self.cache.exchangeType = strategy.get("exchange_type_for_run_on_all");
                self.cache.pmp = strategy.get("run_on_all_pmp");
                self.cache.folds = self.sharedFptCollection.pluck("type");
                self.model.set({
                  cached_run_on_all_exchanges: self.cache.exchanges,
                  cached_exchange_type_for_run_on_all: self.cache.exchangeType,
                  cached_run_on_all_pmp: self.cache.pmp,
                  cached_fold_target_values: foldTargetValues
                });
                T1.EventHub.publish("supply:saveSuccessful");
                sharedCollection.isSaving = false;
                self.enableStrategyUI();
              }
            });
          }
        });
      }
      if (shouldntSave) {
        $(document).find(".unload-alert").parent().find("button.cancel").trigger("click");
        self.showNoSelectionAlert();
        sharedCollection.isFormElementsChanged = true;
        return false;
      }
      sharedCollection.isSaving = true;
      self.disableStrategyUI();
      const saveSharedCollection = $.extend(true, {}, sharedCollection);
      saveSharedCollection.saveStrategySupplySources(additionalData, saveSuccessful, statusInvalidCallback, this.isPGStrategy);
    },
    disableStrategyUI: function () {
      $(".checkBoxHolder input").attr("disabled", "disabled");
      $(".ui-tab").addClass("ui-tab-disabled");
    },
    enableStrategyUI: function () {
      if (!this.isPGStrategy) {
        $(".checkBoxHolder input").removeAttr("disabled");
      }
      $(".ui-tab").removeClass("ui-tab-disabled");
    },
    showNoSelectionAlert: function () {
      var self = this;
      var alertDialog = $("<div></div>");
      this.renderPartialTemplate({
        template: alert_tmpl,
        data: {
          header: "No Supply Selected",
          message: "You must select at least one supply source."
        },
        useTemplateHtml: false,
        targetEl: alertDialog
      });
      $(alertDialog).dialog({
        autoOpen: true,
        modal: true,
        dialogClass: "w-WarningAlert",
        closeOnEscape: false,
        open: function () {
          var btn = $(".ui-button-text-only");
          btn.blur();
        },
        buttons: [{
          text: "Close",
          class: "cancel",
          click: function () {
            $(this).dialog("close");
          }
        }],
        close: function () {
          self.stopSpinner();
          self.stopSpinner($(".saveAndContinue"));
          $(this).remove();
          $(this).dialog("destroy");
        }
      });
    },
    cancelUnload: function () {
      var orgs = Orgs.getOrganizations();
      var sameOrg = (neww, old) => neww.toString() === old.toString();
      var revertBack = function (oldOrg) {
        orgs.setSelected(oldOrg, {
          implicitChange: true
        });
      };
      orgs.getSelected().then(id => {
        if (!sameOrg(id, this.org)) {
          revertBack(this.org);
        }
      });
    },
    load: function () {
      var sharedCollection = this.sharedCollection;
      var selectedTab = this.getSelectedTab(sharedCollection.contentType);
      var selector = this.getCssSelector(selectedTab);
      var strategyId = sharedCollection.strategy ? sharedCollection.strategy.get("id") : "";
      if (strategyId) {
        T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      }
      this.render().then(() => {
        if (this.layout) {
          this.layout.selected = selectedTab;
          this.layout.load({
            selectedContext: selector
          });
        }
      });
    }
  });
  return T1.Form.ViewUtils.extend(SupplyView);
});
