define('modules/reporting/campaigns/models/reportingMainModel',["jQuery", "Underscore", "T1", "T1Model", "T1Currencies", "T1Permissions"], function ($, _, T1, T1Model, T1Currencies, T1Permissions) {
  "use strict";

  var Date = T1.Date;
  var ReportingMainModel = T1Model.extend({
    id: "",
    initialize: function () {},
    setChartShown: function (chartShown) {
      this.set({
        chartShown: chartShown
      });
    },
    setReportState: function (reportStateObj) {
      this.set({
        reportState: reportStateObj
      });
    },
    setSection: function (section, silent) {
      this.set({
        section: section
      }, {
        silent: true
      });
      if (!silent) {
        this.trigger("change:section");
      }
    },
    setSubSection: function (section, subSection) {
      switch (section) {
        case "performance":
          this.set({
            performanceSubSection: subSection
          });
          break;
        case "site_transparency":
          this.set({
            siteTransparencySubSection: subSection
          });
          break;
        case "geo":
          this.set({
            geoSubSection: subSection
          });
          break;
        case "device_technology":
          this.set({
            technologySubSection: subSection
          });
          break;
        case "contextual_insights":
          this.set({
            contextualSubSection: subSection
          });
          break;
      }
    },
    setMainTabSection: function (section, sectionId) {
      this.set({
        mainTabSection: section,
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    },
    leftTheSection: function (section) {
      if (section === undefined) {
        throw new Error(`'section' property should be defined in the view to use leftTheSection() method.`);
      }
      return section !== this.get("mainTabSection");
    },
    setReportMainView: function (view) {
      this.resetReportMainView();
      this.set({
        reportMainView: view
      });
    },
    resetReportMainView: function () {
      var reportMainView = this.get("reportMainView");
      if (reportMainView) {
        if (!reportMainView.unloaded) {
          reportMainView.unload();
        }
        this.set({
          reportMainView: null
        });
      }
    },
    setTrackingSection: function (section) {
      this.set({
        trackingSection: section
      });
    },
    setCurrency: function (currencyType, currencyCode) {
      this.set({
        currency: currencyType,
        currencyCode: T1Currencies.getSelectedCurrencyCode(currencyType, currencyCode)
      });
    },
    getCurrencyCode: function () {
      return this.get("currencyCode");
    },
    setReportId: function (reportId) {
      var campaignCount = reportId && reportId.split(",").length || 0;
      var multiCampaigns = campaignCount > 1;
      var multiCampaignsText = "Multiple Campaigns";
      this.set({
        campaignCount: campaignCount,
        multiCampaigns: multiCampaigns,
        multiCampaignsLabel: multiCampaigns ? `${multiCampaignsText} (${campaignCount})` : "",
        multiCampaignsText: multiCampaigns ? multiCampaignsText : "",
        reportId: reportId,
        reportIdList: reportId && reportId.replace(/,/g, "-")
      });
    },
    setDataExportLastPath: function (path) {
      this.set({
        dataExportLastPath: path
      });
    },
    setCampaignAccess: function (campaignData) {
      var permission;
      var creativePermission = "denied";
      if (T1Permissions.check("user") === "REPORTER") {
        permission = "denied";
      } else if (T1Permissions.check("campaign.readonly", campaignData)) {
        permission = "readonly";
      } else if (T1Permissions.check("campaign.edit", campaignData)) {
        permission = "edit";
      } else {
        throw new Error("unknown permission");
      }
      if (T1Permissions.check("creative.edit", campaignData)) {
        creativePermission = "edit";
      }
      this.set({
        campaignAccess: this.get("multiCampaigns") ? "denied" : permission,
        creativeAccess: creativePermission,
        strategyAccess: permission
      }, {
        silent: true
      });
      this.trigger("change:campaignAccess", permission);
    },
    setAdvertiserItems: function (advertiserItems, advertiserLookup) {
      this.set({
        advertiserItems: advertiserItems,
        advertiserLookup: advertiserLookup
      });
    },
    updateAdvertiserItems: function (oldAdvertiserID, newAdvertiserID) {
      var advertiserLookup = this.get("advertiserLookup");
      advertiserLookup[oldAdvertiserID].selected = false;
      advertiserLookup[newAdvertiserID].selected = true;
    },
    setSelectedAdvertiser: function (selectedAdvertiser, options) {
      var currentAdvertiser;
      if (options && options.apply) {
        if (selectedAdvertiser) {
          currentAdvertiser = this.get("selectedAdvertiser");
          if (currentAdvertiser) {
            this.updateAdvertiserItems(currentAdvertiser.id, selectedAdvertiser.id);
          }
          this.set({
            selectedAdvertiser: $.extend(true, {}, selectedAdvertiser, {
              temp: false
            })
          }, {
            silent: options && options.silent || false
          });
        }
      } else {
        this.set({
          tempAdvertiser: $.extend(true, {}, selectedAdvertiser, {
            temp: true
          })
        });
      }
    },
    setCampaignItems: function (campaignItems, campaignLookup) {
      this.set({
        campaignItems: campaignItems,
        campaignLookup: campaignLookup
      });
    },
    getMultiCampaignExtCols: function (dimensionKey) {
      var fetchDimension = dimensionKey === "strategy_id" || dimensionKey === "concept_id" || dimensionKey === "creative_id";
      return fetchDimension ? "campaign_id,campaign_name," : "";
    },
    getCampaignName: function (campaignId) {
      var campaignLookup = this.get("campaignLookup");
      var campaign = campaignLookup && campaignLookup[campaignId];
      return campaign && campaign.name || "";
    },
    setFilteredCampaignItems: function (filteredList) {
      var campaignItems = this.get("campaignItems");
      var length = campaignItems && campaignItems.length || 0;
      var multiCampaignCurrency = {};
      var campaignCurrencies = {};
      var campaignCurrencyKeys, currency, id, isUnifiedCurrency;
      var campaignCount = 0;
      var campaignIds = [];
      var i = 0;
      var publish = T1.EventHub.publish;
      while (i < length) {
        id = campaignItems[i].id;
        campaignItems[i].selected = filteredList[id];
        if (filteredList[id]) {
          campaignCount++;
          campaignIds.push(id);
          campaignCurrencies[campaignItems[i].currencyCode] = true;
        }
        i++;
      }
      if (campaignCount > 1) {
        campaignCurrencyKeys = _.keys(campaignCurrencies);
        isUnifiedCurrency = campaignCurrencyKeys.length === 1;
        multiCampaignCurrency.type = isUnifiedCurrency ? "campaign" : "usd";
        multiCampaignCurrency.code = isUnifiedCurrency ? campaignCurrencyKeys[0] : "USD";
        this.set({
          multiCampaignCurrency: multiCampaignCurrency
        });
        currency = multiCampaignCurrency.type;
      }
      if (filteredList && !this.get("currency")) {
        publish("select:currency", currency);
        publish("reports.initTabLayout");
      }
      this.set({
        filteredCampaignCount: campaignCount,
        filteredCampaignItems: filteredList
      });
      return campaignIds.toString();
    },
    resetFilteredCampaignItems: function () {
      this.set({
        filteredCampaignItems: null
      }, {
        silent: true
      });
    },
    getDateString: function (date, dateFormat) {
      dateFormat = dateFormat || "yyyy-MM-dd";
      const isDateStringAlready = typeof date === "string" && date.length === dateFormat.length;
      return isDateStringAlready ? date : Date.parse(date).toString(dateFormat);
    }
  });
  return ReportingMainModel;
});
