define('modules/shared/views/select',["jQuery", "Underscore", "When", "InflectionJS", "T1", "T1View", "T1Model", "T1Collection", "T1Sortable", "text!../templates/select.html", "Hogan", "T1Selectbox"], function ($, _, When, InflectionJS, T1, T1View, T1Model, T1Collection, T1Sortable, template, Hogan) {
  return T1View.extend({
    template: template,
    initialize: function (args) {
      var i;
      var collRef;
      var modelRef;
      var heirarchy = ["organization", "agency", "advertiser", "concept", "creative"];
      var filterItem;
      var filters;
      var fallback;
      var selectedId;
      this.options = {
        entity: args.entity || "concepts",
        selectedId: args.selectedId,
        boundEntity: args.boundEntity || "advertisers",
        boundEntityId: args.boundEntityId,
        boundEntityHeirarchy: args.boundEntityHeirarchy || heirarchy,
        placeholder: args.placeholder || "Select One",
        preSelect: args.preSelect || "Please select an item first!",
        notFound: args.notFound || "No Items Found",
        dataBind: args.dataBind || "concept_id",
        refreshEvent: args.refreshEvent || args.boundEntity || "advertisers",
        selectEvent: args.selectEvent || args.entity || "concepts",
        initialLoad: args.initialLoad,
        disableCheck: args.disableCheck,
        isSearchable: args.isSearchable,
        clearOnClose: args.clearOnClose,
        defaultSearchParams: args.defaultSearchParams || {},
        defaultSortParams: args.defaultSortParams,
        extraLinkLabel: args.extraLinkLabel,
        extraLinkHandler: args.extraLinkHandler || $.noop,
        extraLinkClass: args.extraLinkClass,
        isPaginated: typeof args.isPaginated !== undefined ? args.isPaginated : true,
        fetchOptions: typeof args.fetchOptions !== undefined ? args.fetchOptions : {},
        customLimit: args.customLimit || 100,
        isLocalCollection: typeof args.isLocalCollection !== undefined ? args.isLocalCollection : false,
        preRender: args.preRender || false
      };
      this.canLoad = false;
      if (args.collection) {
        this.collection = args.collection;
      } else {
        modelRef = T1Model.extend({
          url: this.options.entity + "/"
        });
        heirarchy = this.options.boundEntityHeirarchy;
        filterItem = this.options.boundEntity.singularize();
        filters = heirarchy.slice(heirarchy.indexOf(filterItem), heirarchy.indexOf(this.options.entity.singularize()));
        filters = filters.map(function (filter) {
          return {
            entity: filter,
            fetchOnChange: true
          };
        });
        filters.reverse();
        collRef = T1Collection.extend({
          urlString: this.options.entity,
          model: modelRef,
          filterConfiguration: {
            filters: filters,
            enableEvents: false
          },
          searchConfiguration: {
            fields: {
              name: {
                type: "string"
              }
            }
          }
        });
        if (this.options.defaultSortParams) {
          collRef = T1Sortable.extendCollection(collRef, this.options.defaultSortParams);
        }
        this.collection = new collRef(null, {
          isPaginated: this.options.isPaginated,
          pageLimit: this.options.customLimit
        });
        this.collection.fetchOptions = $.extend(args.fetchOptions, this.collection.fetchOptions);
      }
      if (this.options.initialLoad) {
        if (this.options.boundEntity && this.options.boundEntityId) {
          this.collection.urlFilter.set({
            entity: this.options.boundEntity.singularize(),
            id: this.options.boundEntityId
          });
        }
        this.collection.fetch({
          success: $.proxy(function () {
            selectedId = this.options.selectedId;
            if (args.fetchSelected && (selectedId || selectedId === 0) && !this.collection.get(selectedId)) {
              fallback = new modelRef({
                id: selectedId
              });
              this.collection.add(fallback);
              fallback.fetch().then($.proxy(function () {
                this.canLoad = true;
                this.load();
                this.hideLoading();
              }, this));
            } else {
              this.canLoad = true;
              this.load();
              this.hideLoading();
            }
          }, this)
        });
      } else {
        this.canLoad = true;
      }
      if (args.model) {
        this.collection.add(args.model);
      }
    },
    showLoading: function () {
      $(this.el).find(".select-loader").show();
      this.close();
    },
    hideLoading: function () {
      $(this.el).find(".select-loader").hide();
    },
    reset: function (data) {
      this.collection.urlFilter.clear();
      this.options.selectedId = "";
      this.options.boundEntityId = null;
      this.collection.reset();
      this.renderList();
    },
    refresh: function (data) {
      if (this.options.isLocalCollection) {
        if (data && data.preventFetch) {
          this.options.boundEntityId = data.id;
          if (data.collection) {
            this.collection = data.collection || this.collection;
            this.hideLoading();
            this.renderList();
          } else {
            this.showLoading();
          }
        }
      } else {
        this.showLoading();
        this.collection.urlFilter.set({
          entity: this.options.boundEntity.singularize(),
          id: data.id
        });
        this.options.boundEntityId = data.id;
        this.collection.fetch({
          success: $.proxy(function () {
            this.hideLoading();
            this.renderList();
          }, this)
        });
      }
    },
    close: function (data) {
      var chosen = $(this.el).find("select").data("chosen");
      if (chosen) {
        chosen.close_field();
      }
    },
    setSelection: function (data) {
      if (data.model) {
        if (!this.collection.get(data.model.id)) {
          this.collection.add(data.model);
        }
      }
      if (data.entity === this.options.entity) {
        this.options.selectedId = data.selectedId;
        this.renderList();
      }
    },
    renderList: function () {
      var $select = $(this.el).find(".select-input");
      var tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      var partials = this.partials || {};
      var render = this.compiledTemplate.render(tdata, partials);
      var chosen;
      render = $(render).find(".select-input").html();
      $select.html(render);
      if (this.collection.search) {
        this.collection.search.clear();
      }
      if (tdata.isDisabled) {
        $select.attr("disabled", "disable");
      } else {
        $select.removeAttr("disabled");
      }
      chosen = $select.data("chosen");
      if (chosen) {
        chosen.default_text = tdata.placeholder;
      }
      $select.trigger("liszt:updated");
    },
    load: function () {
      var preRender = this.options.preRender;
      this.refreshProxy = $.proxy(this.refresh, this);
      this.resetProxy = $.proxy(this.reset, this);
      this.selectionProxy = $.proxy(this.setSelection, this);
      this.closeProxy = $.proxy(this.close, this);
      T1.EventHub.subscribe("dropdown:" + this.options.refreshEvent, this.refreshProxy);
      T1.EventHub.subscribe("dropdown:" + this.options.entity + ":reset", this.resetProxy);
      T1.EventHub.subscribe("dropdown:" + this.options.entity + ":selection", this.selectionProxy);
      T1.EventHub.subscribe("dropdown:" + this.options.entity + ":close", this.closeProxy);
      this.render().then($.proxy(function () {
        if (this.canLoad) {
          var selectBox;
          var selectBoxConfig;
          var autoCompleteConfig;
          var $el = $(this.el);
          var $input;
          this.dataReady = true;
          selectBoxConfig = {
            default_text_default: this.boundEntityId ? this.placeholder : this.preselect,
            onChange: $.proxy(function () {
              var id = selectBox.val();
              this.options.selectedId = id;
              T1.EventHub.publish("dropdown:" + this.options.selectEvent, {
                id: id,
                model: this.collection.get(id),
                view: this,
                instance: selectBox
              });
            }, this)
          };
          if (this.options.isSearchable) {
            autoCompleteConfig = {
              autoComplete: true,
              searchField: "name",
              onSearchRequest: $.proxy(function (searchOptions) {
                var success = searchOptions.success;
                var passedModels = [];
                if (this.options.isLocalCollection) {
                  $input = $el.find("input");
                  $input.val($.trim($input.val()));
                  $input.removeClass("search-loader-no-bg");
                } else {
                  if (preRender) {
                    searchOptions.success = $.proxy(function (data) {
                      $.each(data.models, function (i, model) {
                        if (model.toJSON) {
                          if ((preRender({
                            options: [model.toJSON()]
                          }).options || []).length) {
                            passedModels.push(model);
                          }
                        }
                      });
                      data.models = passedModels;
                      success(data);
                    }, this);
                  }
                  searchOptions = $.extend(this.options.defaultSearchParams, {
                    fetchRequired: true
                  }, searchOptions);
                  this.collection.search.set(searchOptions);
                }
              }, this)
            };
            selectBoxConfig.searchFieldDisabled = false;
          }
          if (this.options.extraLinkLabel) {
            selectBoxConfig.extraLink = {
              label: this.options.extraLinkLabel,
              onClick: this.options.extraLinkHandler,
              class: this.options.extraLinkClass
            };
          }
          selectBox = $el.find(".select-input");
          T1.Selectbox(selectBox, selectBoxConfig, autoCompleteConfig);
          if (this.options.clearOnClose) {
            selectBox.on("liszt:hiding_dropdown", $.proxy(function () {
              if (this.collection.search) {
                this.collection.search.clear();
              }
            }, this));
          }
          if (!this.options.initialLoad) {
            this.hideLoading();
          }
        }
      }, this));
    },
    unload: function () {
      T1.EventHub.unsubscribe("dropdown:" + this.options.refreshEvent, this.refreshProxy);
      T1.EventHub.unsubscribe("dropdown:" + this.options.entity + ":reset", this.resetProxy);
      T1.EventHub.unsubscribe("dropdown:" + this.options.entity + ":selection", this.selectionProxy);
      T1.EventHub.unsubscribe("dropdown:" + this.options.entity + ":close", this.closeProxy);
      this.refreshProxy = null;
      this.resetProxy = null;
      this.selectionProxy = null;
      this.closeProxy = null;
      if (this.collection) {
        if (this.collection.urlFilter) {
          this.collection.urlFilter.destroy();
        }
        this.collection.unregister();
        this.collection.reset([]);
      }
      this.collection = null;
    },
    render: function () {
      var tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      var partials = this.partials || {};
      var deferred = $.Deferred();
      var $el = $(this.el);
      if (this.options.preRender) {
        tdata = this.options.preRender(tdata);
      }
      if (!this.compiledTemplate) {
        this.compiledTemplate = Hogan.compile(this.template);
      }
      tdata.appLocation = T1.Location.get();
      this.assignAsCurrency(tdata);
      $el.html(this.compiledTemplate.render(tdata, partials));
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        $el.find("input, textarea").placeholder();
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    serialize: function () {
      var isDisabled;
      var result = [];
      var placeholder;
      this.collection.each($.proxy(function (model) {
        obj = model.toJSON();
        if (parseInt(model.id, 10) == this.options.selectedId) {
          obj.selected = ' selected="true"';
        }
        result.push(obj);
      }, this));
      if (this.options.boundEntityId) {
        placeholder = result.length > 0 ? this.options.placeholder : this.options.notFound;
      } else {
        placeholder = this.options.preSelect;
      }
      if (this.options.disableCheck && typeof this.options.disableCheck === "function") {
        isDisabled = this.options.disableCheck(result.length, !!this.options.boundEntityId, result, this.options.boundEntityId);
      } else {
        isDisabled = this.collection && this.collection.models && this.collection.models.length === 0;
      }
      return {
        dataReady: this.canLoad,
        dataBind: this.options.dataBind,
        isDisabled: isDisabled,
        placeholder: placeholder,
        options: result
      };
    }
  });
});
