define('modules/strategy/views/helpers/dayPartSummaryTarget',["Underscore", "jQuery"], function (_, $) {
  "use strict";

  return {
    prepareForSerialize: function (strategyModel, collection) {
      var dayPartLists = [];
      var collObj = collection;
      const resultJSON = {
        data: []
      };
      var blankEntity = "&nbsp;";
      var daysValRef = {
        MTWRFSU: "Everyday",
        MTWRF: "Weekdays",
        SU: "Weekends"
      };
      var dayCodeDisplayValRef = {
        M: "Mon",
        T: "Tue",
        W: "Wed",
        R: "Thu",
        F: "Fri",
        S: "Sat",
        U: "Sun"
      };
      var getDayPartLabel = function (days, hours, model) {
        let returnStr = `${days}  `;
        if (hours.startH === "All" && hours.endH === "All") {
          returnStr += "All day  ";
        } else {
          returnStr += `${hours.startH} - ${hours.endH}  `;
        }
        if (model.get("user_time") === "1") {
          returnStr += "Relative to End User";
        } else {
          returnStr += "EST";
        }
        return returnStr;
      };
      var getDaysDisplay = function (model) {
        var daysVal = model.get("days");
        var result = daysValRef[daysVal];
        if (!result) {
          const tmpArr = daysVal.split("");
          result = "";
          $.each(tmpArr, function (index, strVal) {
            result += `${dayCodeDisplayValRef[strVal]}, `;
          });
          result = result.replace(/, $/, "");
        }
        return result;
      };
      var getStartEndHours = function (model) {
        var startH = model.get("start_hour");
        var endH = model.get("end_hour");
        var result = {
          startH: blankEntity,
          endH: blankEntity
        };
        const fullDay = 23;
        function getDisplayValsFromHour(hr, isEndHour) {
          var minPart = ":00";
          const halfDay = 12;
          if (isEndHour) {
            minPart = ":59";
          }
          if (hr === 0) {
            return `12${minPart} AM`;
          } else if (hr === halfDay) {
            return `12${minPart} PM`;
          }
          if (hr > halfDay) {
            hr = hr - halfDay;
            return `${hr + minPart} PM`;
          }
          return `${hr + minPart} AM`;
        }
        startH = parseInt(startH, 10);
        endH = parseInt(endH, 10);
        if (isNaN(startH) || isNaN(endH)) {
          return result;
        }
        if (startH === 0 && endH === fullDay) {
          result.startH = "All";
          result.endH = "All";
        } else {
          result.startH = getDisplayValsFromHour(startH, false);
          result.endH = getDisplayValsFromHour(endH, true);
        }
        return result;
      };
      if (collObj && collObj.models && collObj.models.length) {
        $.each(collObj.models, function (index, model) {
          const startEndResult = getStartEndHours(model);
          const days = getDaysDisplay(model);
          if (startEndResult.startH === "All" && startEndResult.endH === "All" && days === "Everyday") {
            dayPartLists.push({
              label: "Run on all day parts",
              action: "none"
            });
          } else {
            dayPartLists.push({
              label: getDayPartLabel(days, startEndResult, model),
              action: "none"
            });
          }
        });
      }
      if (dayPartLists.length) {
        resultJSON.data.push({
          items: dayPartLists
        });
      } else {
        resultJSON.data.push({
          items: [{
            label: "Run on all day parts",
            action: "none"
          }]
        });
      }
      return resultJSON;
    }
  };
});
