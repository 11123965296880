define('modules/admin/myOrgs/organization/views/exchangeSeats_readOnly',['require','jQuery','collections/exchangeSeats','collections/supplySources','T1View','text!../templates/exchangeSeats_readOnly.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const ExchangeSeats = require("collections/exchangeSeats");
  const SupplySources = require("collections/supplySources");
  const T1View = require("T1View");
  const template = require("text!../templates/exchangeSeats_readOnly.html");
  return T1View.extend({
    template: template,
    dataEvents: {
      exchangeSeats: {
        reset: "load"
      }
    },
    initialize() {
      var exchangeSeats = new ExchangeSeats([], {
        isPaginated: false
      });
      this.exchangeSeats = exchangeSeats;
      this.exchangeSeats.id = this.model.id;
      const coll = this.collection = new SupplySources(false, {
        fetchOptions: {
          full: "supply_source"
        },
        isPaginated: false
      });
      coll.search.set({
        params: [{
          term: "1",
          searchField: "seat_enabled"
        }],
        fetchRequired: false
      });
      coll.fetch({
        success: () => {
          this.collection = coll;
          this.exchangeSeats.fetch();
        }
      });
    },
    load() {
      this.render();
    },
    serialize(inputCollection) {
      var collection = inputCollection || this.collection;
      var supplySources = collection.toJSON();
      var exchangeSeats = this.exchangeSeats.toJSON();
      var data = [];
      var finalMatches = [];
      var sortedIDs = ["8", "12", "4", "31", "11", "13", "3", "37", "78", "100", "81", "5", "9", "15", "17", "18", "19", "21", "25", "26", "28", "29", "33", "34", "35", "36", "38", "39", "40", "41", "42", "43", "44", "82", "83", "84", "85", "86", "89", "90", "91", "92", "93", "94", "95", "97", "98", "99", "100", "102", "103", "111", "112", "113", "114", "115", "116", "117", "118", "119", "120", "121", "123", "124", "125", "126", "127", "128", "129", "130", "131", "132", "133", "134", "135", "136", "137", "138", "139", "140"];
      var nameChange = [{
        id: "12",
        name: "Right Media Exchange (RMX)"
      }];
      var hasData = true;
      if (exchangeSeats.length > 0) {
        hasData = true;
      }
      $.each(nameChange, function () {
        for (const i in supplySources) {
          if (supplySources[i].id === this.id) {
            supplySources[i].name = this.name;
            break;
          }
        }
      });
      $.each(exchangeSeats, function () {
        const supplySourceId = this.supply_source_id;
        const seat = this;
        $.each(supplySources, function () {
          if (supplySourceId === this.id) {
            const match = {
              exchange_name: this.name,
              exchange_identifier: seat.seat_identifier,
              supply_source_id: supplySourceId
            };
            if (seat.bill_media_to_client === "1") {
              match.bill_media = "On";
            } else {
              match.bill_media = "Off";
            }
            if (seat.rmx_exchange_cost_pct !== undefined) {
              match.exchange_cost = `${seat.rmx_exchange_cost_pct}% of Media`;
            } else if (seat.rmx_exchange_cost_cpm !== undefined) {
              match.exchange_cost = `$${seat.rmx_exchange_cost_cpm} of CPM`;
            } else {
              match.exchange_cost = "--";
            }
            if (seat.status === "1") {
              finalMatches.push(match);
            }
          }
        });
      });
      $.each(sortedIDs, function (index) {
        const results = $.grep(finalMatches, function (e) {
          return e.supply_source_id === sortedIDs[index];
        });
        const result = results[0];
        if (result !== undefined) {
          data.push(result);
          finalMatches = $.grep(finalMatches, function (e) {
            return e.supply_source_id !== sortedIDs[index];
          });
        }
      });
      if (data.length === 0) {
        hasData = false;
      }
      return {
        finishedLoad: this.exchangeSeats.loaded,
        hasData: hasData,
        data: data
      };
    }
  });
});
