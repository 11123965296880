define('modules/reporting/campaigns/dataExport/createEdit/views/fileType',["jQuery", "T1", "T1View", "text!../templates/fileType.html"], function ($, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "changed .file-type-selector": "selectFileType"
    },
    eventhubEvents: {
      "saved:report"() {
        this.load();
      }
    },
    initialize(model) {
      this.DataExportModel = model;
    },
    load() {
      const $el = this.el;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
      });
    },
    unload() {},
    selectFileType(evt) {
      const fileType = $(evt.currentTarget).attr("value");
      this.DataExportModel.setSelectedFileType({
        fileType: fileType
      });
      T1Publish("change:formElements", true);
    },
    serialize() {
      return {
        selectedType: this.DataExportModel.getSelectedFileType()
      };
    }
  });
});
