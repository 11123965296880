define('modules/reporting/segments/dataExport/manage/views/header',["jQuery", "Underscore", "T1", "T1View", "text!../templates/header.html"], function ($, _, T1, T1View, template) {
  "use strict";

  var DataExportModel;
  return T1View.extend({
    template: template,
    events: {
      "click .btn-add-report strand-button": "loadCreateEdit"
    },
    eventhubEvents: {},
    initialize: function initialize(model) {
      DataExportModel = model;
    },
    load: function load() {
      var $el = this.el;
      this.render().then(function render() {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
      });
    },
    unload: function unload() {
      DataExportModel = null;
    },
    loadCreateEdit: function loadCreateEdit() {
      T1.EventHub.publish("manage.createEditReport");
    },
    serialize: function serialize() {
      return {};
    }
  });
});
