define('modules/campaign/pixelSegment/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "collections/pixels", "text!modules/targeting/pixel/templates/bin_header.html", "text!modules/targeting/pixel/templates/bin_title.html", "text!modules/targeting/pixel/templates/bin_group_name.html", "text!modules/campaign/pixelSegment/templates/bin_item.html"], function ($, _, T1, BinsView, Pixels, bin_header, bin_title, bin_group_name, bin_item) {
  "use strict";

  const pixelTypeMap = Object.freeze({
    "Audience Target": "A",
    "Data Pixel": "D",
    Dynamic: "S",
    "Event Pixel": "E"
  });
  var postCollection = new Pixels();
  postCollection.urlFilter.disableEventsListeners();
  const PixelBinsView = BinsView.extend({
    type: "Pixel",
    configsOverride: true,
    postCollection: postCollection,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      bin_item: bin_item
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      allowMultipleSelection: true,
      onDropEvent: "bins:droppedPixel",
      checkExistenceSharedCollection: false,
      handlerTextEl: ".name"
    },
    initialize: function ({
      strategy: strategy
    }) {
      this.strategy = strategy;
    },
    mapItems: function (items) {
      var result = [];
      if (!items || typeof items !== "object") {
        throw new Error(`mapItems fails if input doesn't exist`);
      }
      items = _.groupBy(items, function (item) {
        return item.get("full_path") ? item.get("full_path").split(" - ")[0] : item.get("selection_type");
      });
      for (const item in items) {
        if (T1.Utils.hasOwnProp(items, item)) {
          const modelArray = items[item];
          for (let i = 0; i < modelArray.length; i++) {
            const mappedItem = {};
            const model = modelArray[i];
            const fullPath = model.get("full_path");
            mappedItem.id = model.id;
            mappedItem.bin_show_group_name = i === 0;
            const path = fullPath ? this.getPath(fullPath) : this.getPath(model.get("selection_name") || model.get("name"));
            mappedItem.full_path = path.shortPath;
            mappedItem.full_path_end = path.shortPathEnd;
            mappedItem.title = path.title;
            mappedItem.pixel_type = pixelTypeMap[model.get("selection_type")] || "INVALID";
            mappedItem.bin_group_name = fullPath ? fullPath.split(" - ")[0] : model.get("owner_name");
            mappedItem.action = model.action || model.get("action");
            result.push(mappedItem);
          }
        }
      }
      return result;
    },
    getPath: function (fullPath) {
      var path = fullPath;
      var goalLength = 30;
      if (!fullPath) {
        throw new Error("Pixel has no name attribute");
      }
      path = path.replace(/ - /g, " &raquo ");
      path = path.length > goalLength ? `...${path.substr(-goalLength)}` : path;
      const lastSlashIndex = path.lastIndexOf("|");
      const noSlash = lastSlashIndex === -1;
      path = path.replace(/[|]/g, " - ");
      return {
        shortPath: noSlash ? "" : path.substring(0, lastSlashIndex + 1),
        shortPathEnd: noSlash ? path : path.substr(lastSlashIndex + 1),
        title: fullPath.replace(/ - /g, " &raquo ")
      };
    },
    getPixelTotalLoads: function (ids, operation, passThru, callback) {
      const ajxOperation = `${passThru}Ajx`;
      var idCount = ids.length;
      if (!ids || !operation || !passThru || !callback) {
        throw new Error("getPixelTotalLoads requires all function arguments");
      }
      if (!(operation === "AND" || operation === "OR")) {
        throw new Error('getPixelTotalLoads requires operation be either "AND" or "OR"');
      }
      if (typeof callback !== "function") {
        throw new Error("getPixelTotalLoads requires a callback function");
      }
      const fetchOptions = {
        full: "*"
      };
      const opts = {
        success: data => {
          var dataList = data.toJSON();
          var loads = 0;
          var totalCount = 0;
          var nonDynamicCount = 0;
          this.$(`.header-${passThru}-bin .icon-spinner`).spin(false);
          $.each(dataList, function (index, value) {
            if (value.pixel_type !== "dynamic") {
              let num;
              if (value.rpt_pixel_placement_qa_top) {
                num = parseFloat(value.rpt_pixel_placement_qa_top.load_count);
              } else {
                num = 0;
              }
              if (operation === "OR") {
                loads += num;
              } else {
                loads = nonDynamicCount === 0 ? num : loads;
                loads = loads > num ? num : loads;
              }
              nonDynamicCount++;
            }
            totalCount++;
          });
          const dynamicCount = totalCount - nonDynamicCount;
          if (totalCount === dynamicCount) {
            loads = "N/A";
          }
          callback({
            totalLoads: loads
          }, passThru, operation, idCount - dynamicCount);
        }
      };
      postCollection.fetchOptions = $.extend(fetchOptions, postCollection.fetchOptions);
      postCollection.isPaginated = false;
      postCollection.urlFilter.clear();
      postCollection.search.clear();
      postCollection.search.set({
        idList: ids.join(",")
      });
      if (this[ajxOperation]) {
        this[ajxOperation].abort();
      }
      this[ajxOperation] = postCollection.fetch(opts);
      if (this[ajxOperation]) {
        this[ajxOperation].always(() => {
          this[ajxOperation] = null;
        });
      }
    },
    updateBins: function () {
      this.updateDataBind({
        totalLoads: this.sharedCollection.length
      }, ".header-include-bin");
    },
    load: function () {
      this.render().then(() => {
        this.updateDataBind({
          totalLoads: this.sharedCollection.length
        }, ".header-include-bin");
      });
    },
    serialize: function (dataset) {
      const includeBinHeader = "Included 1st & 3rd party pixels";
      const excludeBinHeader = "";
      if (dataset && dataset.bins) {
        dataset.bins[0].use_or = this.sharedCollection.includeOperator === "OR";
        $.each(dataset.bins, function (index, bin) {
          bin.bin_label = bin.bin_action === "include" ? includeBinHeader : excludeBinHeader;
        });
        dataset.totalLoads = this.sharedCollection.length;
        return dataset;
      }
    }
  });
  return PixelBinsView;
});
