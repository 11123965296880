define('modules/reporting/utils/reportingComm',["jQuery", "T1"], function ($, T1) {
  "use strict";

  var ReportingComm = function (args) {
    this.initialize(args);
  };
  var methods = {
    initialize: function (args) {
      this.args = args;
      this.id = args.id;
      this.fetchOptions = args.fetchOptions || {};
      this.reportID = null;
      this.urlString = null;
      this.dataGroup = {};
    },
    buildModel: function (name, base) {
      this.dataGroup[name] = base;
    },
    buildCollection: function (name, base, moreFetchOpts) {
      var dataGroup = this.dataGroup;
      if (moreFetchOpts === undefined) {
        dataGroup[name] = base;
      } else {
        dataGroup[name] = $.extend(true, {}, base);
        dataGroup[name].fetchOptions = $.extend(true, {}, this.fetchOptions, moreFetchOpts);
      }
      return dataGroup[name];
    },
    resetDataItem: function (name, moreFetchOpts, dataRef) {
      var dataGroup = this.dataGroup;
      var dataItem = dataRef || dataGroup[name];
      var fetchOptions = dataItem.fetchOptions;
      if (fetchOptions) {
        delete fetchOptions.start_date;
        delete fetchOptions.end_date;
        delete fetchOptions.time_window;
      }
      $.extend(true, dataItem.fetchOptions, moreFetchOpts);
      dataItem.id = this.reportID;
      dataItem.urlString = this.urlString;
      return dataItem;
    },
    clearDataGroup: function () {
      var dataGroup = this.dataGroup;
      for (const i in dataGroup) {
        if (T1.Utils.hasOwnProp(dataGroup, i)) {
          dataGroup[i] = null;
        }
      }
      dataGroup = null;
      this.args = null;
    },
    abortAjaxCalls: function (calls) {
      var dataGroup = this.dataGroup;
      var len = calls === undefined ? 0 : calls.length;
      var i;
      if (len === 0) {
        for (i in dataGroup) {
          if (T1.Utils.hasOwnProp(dataGroup, i)) {
            dataGroup[i].abort();
          }
        }
      } else {
        for (i = 0; i < len; i++) {
          if (dataGroup[calls[i]]) {
            dataGroup[calls[i]].abort();
          }
        }
      }
    }
  };
  $.extend(ReportingComm.prototype, methods);
  return ReportingComm;
});
