define('modules/creatives/display/models/mediaTypesT1AS',["jQuery"], function ($) {
  function checkMedia(input) {
    var ext = input.split(".");
    var types = ["zip", "swf", "gif", "jpg", "jpeg", "tif", "tiff", "png"];
    ext = ext[ext.length - 1].toLowerCase();
    if (ext) {
      return $.inArray(ext, types) !== -1;
    }
    return false;
  }
  var restrictTypes = ["application/zip", "application/x-shockwave-flash", "image/gif", "image/jpeg", "image/tiff", "image/png"];
  return {
    checkMedia: checkMedia,
    restrictTypes: restrictTypes
  };
});
