define('modules/creatives/concept/views/grid',["jQuery", "Underscore", "When", "async", "moment", "T1", "T1View", "T1Layout", "T1GridViewV2", "T1Spinner", "../control/headers", "text!../templates/grid_header.html", "text!../templates/grid_item.html", "text!../templates/grid_creative_drawer_item.html", "utils/CreativeUtils", "models/videoCreative", "models/videoCreativeDetail", "models/concept", "collections/creatives", "T1Form", "T1Menu", "T1Loader", "T1Tooltip"], function ($, _, When, Async, moment, T1, T1View, T1Layout, createGrid, Spinner, headers, headerTemplate, itemTemplate, conceptCreativeGridRowTemplate, CreativeUtils, VideoCreative, VideoCreativeDetail, Concept, Creatives, T1Form, T1Menu) {
  "use strict";

  var ConceptGridView = T1View.extend({
    dataCount: {},
    itemCount: {},
    hasLoaded: {},
    conceptCreatives: {},
    ajx: {},
    activationID: null,
    hasRun: false,
    cors: COMPASS_ENV.VIDEO_API_BASE + "/creatives/proxy/?url=",
    disableUISorter: false,
    truncateConfig: {
      ".column .concept": {
        stripHTML: true
      },
      ".column .advertiser": {
        stripHTML: true
      }
    },
    scrollConfigs: {
      triggerPoint: 400,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-head",
        text: "Fetching Concepts",
        contentClass: "search-loader loader-overrides",
        action: "append"
      }
    },
    spinnerOpts: {
      lines: 14,
      length: 3,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 10,
      speed: 1
    },
    loaderOpts: {
      contentClass: "inline-edit-loader",
      target: ".w-DataGrid .w-body.content",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15,
        top: 4
      }
    },
    partials: {
      gridHeader: headerTemplate,
      listItem: itemTemplate
    },
    headersTooltips: true,
    headers: headers,
    menuConfig: {
      menuTriggerEleSelector: ".config",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: false
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }, {
          label: "Edit",
          visible: true
        }, {
          label: "Assign/Unassign Creatives",
          handler: "assignUnassignHandler",
          visible: true
        }]
      }]
    },
    events: {
      "click .item .icon-arrow, .item": "clickToggleNode",
      "click .main-check": "handleCheckbox",
      "click .sub-check": "handleSubCheckbox",
      "click .config": "showOrApplySettingsMenu",
      "click .sub-foot .more-creatives-action": "addMore",
      "click .delete-icon": "handleDeleteIcon",
      "click .secure-warning": "handleSecurityIcon",
      "mouseleave .subitem": "hideAllDelete",
      "mouseenter .subitem": "showDeleteIcon",
      "click .edit": "openEditPage",
      "contextmenu .edit-icon": "showEditIcon",
      "mousemove .edit-icon": "hideEditIcon"
    },
    dataEvents: {
      collection: {
        reset: "load",
        "change:status": "updateStatus"
      }
    },
    eventhubEvents: {
      "creativegrid:setSearchLoader": function () {
        this.setSearchLoader();
      },
      "creativegrid:setHighlightValue": function (data) {
        this.highlightValue = decodeURIComponent(CreativeUtils.replaceSymbolsInName(data));
      },
      "organization:select": function () {
        this.setSearchLoader();
      },
      "creativegrid:clearLoaded": function () {
        this.hasLoaded = {};
      },
      onConfigClick: "useCloseInline",
      "confirmAction:show": "showConfirmAction",
      "confirmAction:hide": "hideConfirmAction"
    },
    useCloseInline: function () {
      this.closeInlineEditor();
    },
    renderChange: function (attr, model, newVal) {
      var $edited = $("#" + attr + model.id, this.el);
      $edited.text(newVal || "--");
      $edited.attr("title", newVal || "--");
    },
    initialize: function (args) {
      var self = this;
      var sortHeaders = {
        name: {},
        id: {},
        advertiser: {
          fieldName: "advertiser.name"
        }
      };
      this.sortableHeadersView = new T1Layout({
        el: function () {
          return $(self.el);
        },
        template: '<div class="sortable-content"></div>',
        selectorPath: ".w-foot",
        layout: {
          ".sortable-content": [{
            module: "shared",
            viewType: "gridSorter",
            options: {
              parentEl: self.el,
              collection: self.collection,
              sortableHeaders: sortHeaders,
              onSort: function () {
                self.disableUISorter = true;
                self.setSearchLoader();
              },
              onSortFinished: function () {
                self.sortFinished();
              }
            }
          }]
        }
      });
      this.stratArray = [];
      if (args.collection) {
        self.collection = args.collection;
        this.addDataEvent({
          collection: {
            reset: "reload"
          }
        });
      }
      if (args.searchTerm) {
        this.highlightValue = decodeURIComponent(CreativeUtils.replaceSymbolsInName(args.searchTerm));
      }
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.sortableHeadersView.load();
        if (self.collection && self.collection.loaded) {
          if (!self.hasRun) {
            self.hasRun = true;
            self.postRender();
          }
        }
      });
      if (!this.collection.loaded) {
        this.setSearchLoader();
      }
    },
    handleSecurityIcon: function (e) {
      var icon = e.target.parentNode;
      var iconType = icon.attributes.type.value;
      var iconIsWarningIcon = iconType.indexOf("warning") > -1;
      if (iconIsWarningIcon) {
        window.open("https://mediamathsupport.force.com/s/article/Creative-Approvals", "_blank");
      }
      this.preventDefault(e);
    },
    assignUnassignHandler: function (e) {
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      e.preventDefault();
      T1.EventHub.publish("assignModal:show", {
        model: this.collection.get(id),
        advertiserID: this.collection.get(id).get("advertiser").id
      });
    },
    handleDeleteIcon: function (e) {
      var $target = $(e.currentTarget);
      var $item = $target.closest(".item");
      var $itemWrapper = $target.closest(".item-wrapper");
      var id = $item.data("id");
      var conceptID = $target.closest(".open").data("id");
      var model = this.conceptCreatives[conceptID].get(id);
      var $activeItem = $itemWrapper.find("#act" + conceptID);
      var $creativeHeader = $itemWrapper.find("#head" + conceptID);
      var $creativeGrid = $itemWrapper.find("#grid" + conceptID);
      var $creativeMore = $itemWrapper.find("#more" + conceptID);
      var $creativeItem = $itemWrapper.find("#item" + id);
      var modelValue, loader;
      var deferred = $.Deferred();
      function hideCreative() {
        var creativeCount = Number($activeItem.html());
        creativeCount--;
        loader.stop();
        $activeItem.html(creativeCount);
        $creativeItem.remove();
        if ($activeItem.html() === "0") {
          $creativeHeader.html("No Creatives Assigned").css("text-align", "center").addClass("creative-header");
          $creativeGrid.empty();
          $creativeMore.hide();
        }
      }
      $(e.target).parent().hide();
      loader = this.createInlineLoader("#grid" + conceptID);
      loader.start();
      modelValue = {
        concept_id: ""
      };
      if (model.get("media_type") === "video") {
        var outputModel = new VideoCreativeDetail({
          id: model.id
        });
        var vc = new VideoCreative({
          id: model.id
        });
        var saveOpts = {
          dataType: "json",
          stringify: true,
          contentType: "application/json",
          processData: false,
          success: function () {
            deferred.resolve();
          },
          conflict: function () {
            deferred.reject();
          },
          processAjaxResponse: outputModel.processAjaxResponse
        };
        self.conceptModel = new Concept({
          action: "new"
        });
        self.conceptModel.save({
          name: model.get("name"),
          status: "1",
          advertiser_id: model.get("advertiser_id")
        }, {
          success: $.proxy(function () {
            deferred.resolve(self.conceptModel);
            vc.sync("read", {
              id: model.id
            }, {
              onSuccess: function (data) {
                var details = data.details;
                if (details.customVAST) {
                  outputModel.set({
                    customVAST: details.customVAST
                  });
                } else if (details.customVASTUrl) {
                  outputModel.set({
                    customVASTUrl: details.customVASTUrl
                  });
                } else {
                  outputModel.set({
                    clickthroughUrl: details.clickthroughUrl
                  });
                }
                outputModel.set({
                  name: details.name,
                  advertiser: details.advertiser,
                  concept: self.conceptModel.id,
                  landingUrl: details.landingUrl,
                  startTime: details.startTime,
                  endTime: details.endTime,
                  active: details.active
                });
                outputModel.save(outputModel.toJSON(), saveOpts);
                T1.EventHub.publish("assignModal:saved");
                hideCreative();
              },
              errorDisplaySuppressingConfig: {
                errorCodes: [404, 502, 503]
              },
              dataType: "json",
              processAjaxResponse: function (resp) {
                return {
                  jsonData: resp,
                  statusCode: resp.status.code || "ok"
                };
              }
            });
          })
        });
      } else {
        model.save(modelValue, {
          success: function () {
            hideCreative();
          }
        });
      }
    },
    showDeleteIcon: function (e) {
      var $target = $(e.target);
      $target.find(".delete-icon-hold").show();
    },
    hideAllDelete: function () {
      $(".delete-icon-hold").hide();
    },
    addMore: function (e) {
      var self = this;
      var $target = $(e.currentTarget);
      var $itemWrapper = $target.closest(".item-wrapper");
      var $moreBtn = $target;
      var $creativeGrid = $itemWrapper.find("#grid" + conceptID);
      var conceptID = $moreBtn.data("id");
      var $itemID;
      var creativeMatch = {};
      this.origList = [];
      $creativeGrid.find(".checkbox").each(function (i, item) {
        $itemID = Number($(item).data("id"));
        self.origList.push($itemID);
      });
      $moreBtn.toggleClass("more-strategies-action", false);
      Spinner.apply($moreBtn);
      this.conceptCreatives[conceptID].fetchMore({
        success: function (data) {
          var creativesData = [];
          data.each(function (model) {
            if ($.inArray(Number(model.id), self.origList) === -1) {
              var that = this;
              var eachConceptID = model.attributes.concept_id;
              var name = model.get("name");
              var id = model.get("id");
              var status = model.get("status") === "1" ? "Active" : "Inactive";
              var externalID = model.get("external_identifier") === "STUB" ? "--" : model.get("external_identifier");
              var type = model.get("file_type") === "vast" ? "video" : model.get("file_type");
              var width, height, vc, duration, vast, verifiedSecure;
              var secure = model.get("is_https") === "1" ? "Yes" : "No";
              var mediaType = model.get("media_type");
              var detail = "--";
              var t1as = model.get("t1as") === "1" ? true : false;
              var sslValidator = model.get("atomic_creative_ssl_validator");
              var $creativeItem = $itemWrapper.find("#item" + model.id);
              if (sslValidator && sslValidator.ignored !== "YES") {
                secure = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
                verifiedSecure = secure;
              }
              if (mediaType === "video") {
                vc = new VideoCreative({
                  id: model.id
                });
                self.ajx[model.id] = vc.sync("read", {
                  id: model.id
                }, {
                  onSuccess: function (successdata) {
                    if (successdata.duration) {
                      duration = moment.utc(successdata.duration * 1e3).format("mm:ss");
                      $creativeItem.children(".details").html(duration);
                    } else if (successdata.details) {
                      if (successdata.details.customVAST) {
                        vast = successdata.details.customVAST;
                        that.xmlDoc = $.parseXML(vast.trim()).documentElement;
                        duration = $(that.xmlDoc).find("Duration").first().text().substr(-5);
                        duration = duration ? duration : "--";
                        $creativeItem.children(".details").html(duration);
                      } else if (successdata.details.customVASTUrl) {
                        vast = successdata.details.customVASTUrl;
                        that.xmlDoc = self.cors + encodeURIComponent(vast);
                        $.get(that.xmlDoc, function (xmldata) {
                          that.xmlDoc = xmldata;
                          duration = $(that.xmlDoc).find("Duration").first().text().substr(-5);
                          duration = duration ? duration : "--";
                          $creativeItem.children(".details").html(duration);
                        }).fail(function () {
                          var transcodeError = '<div class="transcode-error"><b class="icon"></b></div>';
                          $creativeItem.addClass("inactive");
                          $creativeItem.children(".details").html(transcodeError);
                        });
                      }
                    }
                  },
                  onError: function () {
                    var transcodeError = '<div class="transcode-error"><b class="icon"></b></div>';
                    $creativeItem.addClass("inactive");
                    $creativeItem.children(".details").html(transcodeError);
                  },
                  errorDisplaySuppressingConfig: {
                    errorCodes: [404, 502, 503]
                  },
                  dataType: "json",
                  processAjaxResponse: function (resp) {
                    return {
                      jsonData: resp,
                      statusCode: resp.status.code || "ok"
                    };
                  }
                });
              } else {
                width = model.get("width");
                height = model.get("height");
                detail = width + "x" + height;
              }
              if (t1as) {
                externalID = "None - T1AS";
              }
              if (creativeMatch[eachConceptID]) {
                creativeMatch[eachConceptID] = creativeMatch[eachConceptID] + 1;
              } else {
                creativeMatch[eachConceptID] = 1;
              }
              creativesData.push({
                id: id,
                name: name,
                status: status,
                externalID: externalID,
                type: type,
                detail: detail,
                secure: secure,
                verifiedSecure: verifiedSecure,
                conceptID: eachConceptID
              });
            }
          });
          for (var j = 0; j < creativesData.length; j++) {
            self.conceptCreatives[conceptID].gridDrawerTemplate.dataToSerialize.push(creativesData[j]);
          }
          self.conceptCreatives[conceptID].gridDrawerTemplate.render();
          $moreBtn.toggleClass("more-strategies-action", true);
          $moreBtn.spin(false);
          if (self.conceptCreatives[conceptID].canFetchMore()) {
            $moreBtn.show();
          } else {
            $moreBtn.hide();
          }
        }
      });
    },
    setTemplate: function (creativesData) {
      var self = this;
      var template;
      template = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: "#grid" + creativesData[0].conceptID,
        template: conceptCreativeGridRowTemplate,
        layout: {},
        dataToSerialize: creativesData,
        serialize: function () {
          var current, showSecureWarning, showSecureVerified, creativeTemplateData;
          var templateData = {
            creatives: []
          };
          for (var i = 0; i < this.dataToSerialize.length; i++) {
            current = this.dataToSerialize[i];
            showSecureWarning = false;
            showSecureVerified = false;
            if (current.verifiedSecure) {
              showSecureWarning = current.verifiedSecure === "No";
              showSecureVerified = current.verifiedSecure === "Yes";
            }
            creativeTemplateData = _.extend(current, {
              showSecureVerified: showSecureVerified,
              showSecureWarning: showSecureWarning
            });
            templateData.creatives.push(creativeTemplateData);
          }
          return templateData;
        }
      });
      return template;
    },
    abort: function () {
      $.each(this.ajx, function (name, value) {
        value.abort();
      });
    },
    durationLoader: function (id) {
      var iconSpinner = $("#item" + id, this.el).find(".sub-spinner");
      var loader;
      loader = iconSpinner;
      return loader;
    },
    createItemLoader: function (id) {
      var targetDiv = "#id" + id;
      var loader;
      var item = $(targetDiv);
      var arrow = item.find(".icon");
      var iconArrow = arrow.find(".icon-arrow");
      var iconSpinner = arrow.find(".icon-spinner");
      iconArrow.hide();
      iconSpinner.show();
      loader = iconSpinner;
      return loader;
    },
    clickToggleNode: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $target = $(e.target);
      var $itemWrapper = $(e.currentTarget).closest(".item-wrapper");
      var $itemID = $currentTarget.closest(".item-hold").attr("id");
      var $active = $itemWrapper.find("#act" + $itemID);
      var loaderObj;
      if ($target.hasClass("checkbox") || $target.hasClass("sub") || $target.hasClass("config") || $target.hasClass("settings-icon") || $target.hasClass("cancel") || $target.hasClass("delete-icon")) {
        return;
      }
      this.preventDefault(e);
      if (this.hasLoaded[$itemID] || $active.html() === "0") {
        this.activateToggleNode($target, $currentTarget);
      } else {
        loaderObj = this.createItemLoader($itemID);
        Spinner.apply(loaderObj, this.spinnerOpts);
        this.fetchCreatives($itemID, $target, $currentTarget, loaderObj);
      }
    },
    activateToggleNode: function (toggleTarget, toggleCurrTarget, loaderObj) {
      var $arrow = toggleCurrTarget.hasClass("icon-arrow") ? toggleCurrTarget : toggleCurrTarget.find(".icon-arrow");
      var expanded = $arrow.hasClass("expanded");
      var busy = $arrow.hasClass("busy");
      var $itemWrapper = $arrow.closest(".item-wrapper");
      var $child = $itemWrapper.children(".children");
      if (!busy) {
        $arrow.addClass("busy");
        $arrow.toggleClass("expanded");
        $itemWrapper.toggleClass("open");
        if (loaderObj) {
          loaderObj.spin(false);
          $arrow.show();
          $arrow.addClass("expanded");
          $itemWrapper.addClass("open");
          $arrow.closest(".icon-spinner").hide();
        }
        this.toggleNode(expanded, $child, $arrow);
        this.makeTooltips();
      }
    },
    toggleNode: function (shouldHideChild, $child, $arrow) {
      $child[shouldHideChild ? "hide" : "show"]("blind", {}, 500, this.updateScrollBar).promise().done(function () {
        $arrow.removeClass("busy");
      });
    },
    loadCount: function (conceptID, thisCount, toggleTarget, toggleCurrTarget, loaderObj) {
      this.dataCount[conceptID] = thisCount;
      if (this.itemCount[conceptID]) {
        this.itemCount[conceptID] = this.itemCount[conceptID] + 1;
      } else {
        this.itemCount[conceptID] = 1;
      }
      if (this.itemCount[conceptID] && this.itemCount[conceptID] >= this.dataCount[conceptID] && !this.hasLoaded[conceptID]) {
        this.activateToggleNode(toggleTarget, toggleCurrTarget, loaderObj);
        this.hasLoaded[conceptID] = true;
      }
    },
    fetchCreatives: function (conceptID, toggleTarget, toggleCurrTarget, loaderObj) {
      var self = this;
      var creativeMatch = {};
      this.conceptCreatives[conceptID] = new Creatives(null, {
        pageLimit: 50
      });
      this.conceptCreatives[conceptID].canCache = false;
      this.conceptCreatives[conceptID].urlFilter.set({
        entity: "concept",
        id: "(" + conceptID + ")"
      });
      this.conceptCreatives[conceptID].fetchOptions = {
        full: "*",
        with: ["atomic_creative_ssl_validators"],
        sort_by: "-status"
      };
      this.conceptCreatives[conceptID].fetch({
        success: function (data) {
          var creativesData = [];
          if (self.conceptCreatives[conceptID].canFetchMore()) {
            $("#more" + conceptID).show();
          } else {
            $("#more" + conceptID).hide();
          }
          data.each(function (model) {
            var that = this;
            var eachConceptID = model.attributes.concept_id;
            var name = model.get("name");
            var id = model.get("id");
            var status = model.get("status") === "1" ? "Active" : "Inactive";
            var externalID = model.get("external_identifier") === "STUB" ? "--" : model.get("external_identifier");
            var type = model.get("file_type") === "vast" ? "video" : model.get("file_type");
            var secure = model.get("is_https") === "1" ? "Yes" : "No";
            var mediaType = model.get("media_type");
            var detail = "--";
            var t1as = model.get("t1as") === "1" ? true : false;
            var thisCount = data.length;
            var sslValidator = model.get("atomic_creative_ssl_validator");
            var width, height, vc, duration, vast, verifiedSecure;
            if (sslValidator) {
              sslValidator = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
              switch (sslValidator) {
                case "Yes":
                case "No":
                  secure = sslValidator;
                  verifiedSecure = secure;
                  break;
                case "Pending":
                  secure = sslValidator;
                  break;
              }
            }
            if (mediaType === "video") {
              vc = new VideoCreative({
                id: model.id
              });
              self.ajx[model.id] = vc.sync("read", {
                id: model.id
              }, {
                onSuccess: function (successdata) {
                  if (successdata.duration) {
                    duration = moment.utc(successdata.duration * 1e3).format("mm:ss");
                    $("#item" + model.id).children(".details").html(duration);
                  } else if (successdata.details) {
                    if (successdata.details.customVAST) {
                      vast = successdata.details.customVAST;
                      that.xmlDoc = $.parseXML(vast.trim()).documentElement;
                      duration = $(that.xmlDoc).find("Duration").first().text().substr(-5);
                      duration = duration ? duration : "--";
                      $("#item" + model.id).children(".details").html(duration);
                    } else if (successdata.details.customVASTUrl) {
                      vast = successdata.details.customVASTUrl;
                      that.xmlDoc = self.cors + encodeURIComponent(vast);
                      $.get(that.xmlDoc, function (xmldata) {
                        that.xmlDoc = xmldata;
                        duration = $(that.xmlDoc).find("Duration").first().text().substr(-5);
                        duration = duration ? duration : "--";
                        $("#item" + model.id).children(".details").html(duration);
                      }).fail(function () {
                        var transcodeError = '<div class="transcode-error"><b class="icon"></b></div>';
                        $("#item" + model.id).addClass("inactive");
                        $("#item" + model.id).children(".details").html(transcodeError);
                      });
                    }
                  }
                },
                onError: function () {
                  var transcodeError = '<div class="transcode-error"><b class="icon"></b></div>';
                  $("#item" + model.id).addClass("inactive");
                  $("#item" + model.id).children(".details").html(transcodeError);
                },
                errorDisplaySuppressingConfig: {
                  errorCodes: [404, 502, 503]
                },
                dataType: "json",
                processAjaxResponse: function (resp) {
                  return {
                    jsonData: resp,
                    statusCode: resp.status.code || "ok"
                  };
                }
              });
            } else {
              width = model.get("width");
              height = model.get("height");
              detail = width + "x" + height;
              self.loadCount(conceptID, thisCount, toggleTarget, toggleCurrTarget, loaderObj);
            }
            if (t1as) {
              externalID = "None - T1AS";
            }
            creativesData.push({
              id: id,
              name: name,
              status: status,
              externalID: externalID,
              type: type,
              detail: detail,
              secure: secure,
              verifiedSecure: verifiedSecure,
              conceptID: eachConceptID
            });
            if (creativeMatch[eachConceptID]) {
              creativeMatch[eachConceptID] = creativeMatch[eachConceptID] + 1;
            } else {
              creativeMatch[eachConceptID] = 1;
            }
            self.loadCount(eachConceptID, thisCount, toggleTarget, toggleCurrTarget, loaderObj);
          });
          self.conceptCreatives[conceptID].gridDrawerTemplate = self.setTemplate(creativesData);
          self.conceptCreatives[conceptID].gridDrawerTemplate.render();
        }
      });
    },
    sortFinished: function () {
      this.scrollContentRendered(this.collection);
    },
    postRender: function () {
      var self = this;
      var $item;
      var mClose = document.getElementById("modalClose");
      var mContinue = document.getElementById("modalContinueSingle");
      mClose.addEventListener("click", self.hideConfirmAction);
      mContinue.addEventListener("click", $.proxy(self.confirmAction, self));
      if (self.collection.models.length) {
        $(".item").each(function (i, e) {
          $item = $(e).closest(".item");
          self.applyTruncate($item);
        });
        self.makeTooltips();
      }
    },
    scrollContentRendered: function (newColl) {
      var self = this;
      var conceptCount = 0;
      var conceptArray = [];
      var loaderArray = [];
      var loaderObj = {};
      if (newColl.length) {
        newColl.each(function (model) {
          loaderObj[model.id] = self.createItemLoader(model.id);
          Spinner.apply(loaderObj[model.id], self.spinnerOpts);
          conceptArray.push(model.id);
          loaderArray.push({
            id: model.id,
            loader: loaderObj[model.id]
          });
          if (conceptCount === newColl.length - 1) {
            Async.parallel([function () {
              self.collection.mapStrategy(conceptArray, loaderArray, true, self.highlightValue);
            }, function () {
              self.collection.countCreatives(conceptArray, loaderArray);
            }]);
          }
          conceptCount++;
          if (model.get("status") === "1") {
            $("#stat" + model.id).html("Active");
          } else {
            $("#stat" + model.id).html("Inactive");
          }
          $("#last" + model.id).html(self.expandDate(model.get("updated_on")));
        });
      }
      this.postRender();
    },
    showEditIcon: function (e) {
      var $target = $(e.currentTarget);
      $target.closest(".item-wrapper").addClass("hover");
    },
    hideEditIcon: function () {
      var self = this;
      var $allEdit = $(self.el).find(".item-wrapper");
      $allEdit.removeClass("hover");
    },
    openEditPage: function (e) {
      var $target = $(e.currentTarget);
      var id = Number($target.attr("id"));
      var $hidePanel = $(document).find("mm-close-panel");
      $hidePanel.each(function (i) {
        if ($hidePanel[i].close) {
          $hidePanel[i].close();
        }
      });
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        window.open("#creatives/concept/" + id, "_blank");
      } else {
        T1.Location.set("#creatives/concept/" + id);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    showOrApplySettingsMenu: function (eventOrTarget) {
      var self = this;
      var id, model, isActive;
      var $currentTarget = eventOrTarget.currentTarget ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var $menuTarget = $currentTarget ? $currentTarget.find(".settings-icon") : null;
      var menuConfig = $.extend(true, {}, self.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Deactivate: 0,
        Activate: 1,
        Edit: 2
      };
      if ($menuTarget) {
        id = $menuTarget.data("id");
        model = self.collection.get(id);
        isActive = model.get("status") === "1";
        menuItems[menuIndexRef.Activate].visible = !isActive;
        menuItems[menuIndexRef.Deactivate].visible = isActive;
        menuItems[menuIndexRef.Edit].url = "#creatives/concept/" + id;
        menuConfig.dataID = "data-id=" + id;
        menuConfig.menuTriggerEleSelector = 'div[id="id' + id + '"] .config';
      }
      T1Menu(menuConfig, self);
      if (eventOrTarget.currentTarget) {
        $currentTarget.find(".settings-icon").trigger("click");
      }
    },
    makeTooltips: function () {
      T1.Tooltip("body", {
        tooltipEle: $(this.el).find(".tip").not(".adx-warning, .adx-error"),
        getExternalTip: true
      });
      T1.Tooltip("body", {
        getExternalTip: true,
        tooltipEle: $(this.el).find(".t1-tooltip").not(".tip, .adx-warning, .adx-error"),
        className: "w-InlineEditError"
      });
      if (this.collection.models.length) {
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".adx-warning"),
          className: "adx-warning-tipsy"
        });
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".adx-error"),
          className: "adx-error-tipsy"
        });
      }
    },
    createInlineLoader: function (id) {
      var targetDiv = id;
      var loader;
      loader = T1.Loader.create({
        contentClass: "inline-edit-loader",
        target: targetDiv,
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 4
        }
      });
      return loader;
    },
    expandDate: function (value) {
      var dateFormat = "MM/DD/YY";
      return value === "" ? "" : new moment.utc(value).format(dateFormat);
    },
    confirmAction: function () {
      var tm = document.getElementById("confirmModal");
      this.activationHandler(false, this.activationID);
      tm.hide();
    },
    showConfirmAction: function () {
      var tm = document.getElementById("confirmModal");
      tm.show();
    },
    hideConfirmAction: function (e) {
      var tm = document.getElementById("confirmModal");
      if (e) {
        e.preventDefault();
      }
      tm.hide();
    },
    deactivateHandler: function (e) {
      var id = $(e.currentTarget).closest("dl").data("id");
      var stratHTML = $("#st" + id).html();
      this.activationID = id;
      this.preventDefault(e);
      if (stratHTML !== "--" && stratHTML !== "<em>No Active Strategies</em>") {
        this.showConfirmAction();
        $("#modalContinueSingle").show();
        $("#modalContinueBulk").hide();
      } else {
        this.activationHandler(false, id);
      }
    },
    activateHandler: function (e) {
      var id = $(e.currentTarget).closest("dl").data("id");
      this.preventDefault(e);
      this.activationHandler(true, id);
    },
    activationHandler: function (toActivate, id) {
      var self = this;
      var model, loader;
      var status = toActivate === false ? "0" : "1";
      var stopLoader = function () {
        T1.Notify("message", "Concept updated successfully!");
        loader.stop();
      };
      if (id) {
        model = self.collection.get(id);
        loader = self.createInlineLoader("#id" + id);
        loader.start();
        model.save({
          status: status
        }, {
          success: stopLoader,
          conflict: stopLoader
        });
      }
    },
    updateStatus: function (model) {
      var id = model.get("id");
      var status = model.get("status");
      var $item = $("#id" + id).find(".item");
      var $itemStatus = $("#stat" + id);
      var $config = $("#id" + id).find(".config");
      if (status === "0") {
        $item.addClass("inactive");
        $itemStatus.html("Inactive");
      } else {
        $item.removeClass("inactive");
        $itemStatus.html("Active");
      }
      this.showOrApplySettingsMenu($config);
    },
    edit: function (e) {
      e.preventDefault();
      var $target = $(e.data.buttonEl);
      var $item = $target.closest(".item-wrapper");
      var id = $item.data("id");
      T1.Location.set("creatives/concept/" + id);
    },
    handleCheckbox: function (e) {
      var self = this;
      var $target = $(e.target);
      var checked, current, id, model, selected;
      var count = 0;
      var outClass = "";
      e.preventDefault();
      e.stopPropagation();
      if ($target.hasClass("all")) {
        current = $target.attr("class").split(" ");
        if (current.indexOf("checked") !== -1) {
          $(".main-check").removeClass("checked");
          this.collection.toggleAllSelected(false);
        } else if (current.indexOf("partial") !== -1) {
          outClass = "checked";
          $target.removeClass("partial").addClass("checked");
          $(".main-check").addClass("checked");
        } else {
          outClass = "checked";
          $target.addClass("checked");
          $(".main-check").addClass("checked");
        }
        $(".main-check.checked:not(.all)").each(function () {
          id = $(this).data("id");
          model = self.collection.get(id);
          selected = model.get("selected") || false;
          model.set({
            selected: !selected
          });
        });
      } else {
        $target.toggleClass("checked");
        count = $(".main-check:not(.all)").size();
        checked = $(".main-check.checked:not(.all)").size();
        if (count === checked) {
          outClass = "checked";
        } else if (checked < count && checked > 0) {
          outClass = "partial";
        }
        $(".main-check.all").removeClass("checked partial").addClass(outClass);
        id = $target.data("id");
        model = this.collection.get(id);
        selected = model.get("selected") || false;
        model.set({
          selected: !selected
        });
      }
      $(".w-grid").trigger("changed:checkbox", {
        state: outClass === "" ? "none" : outClass
      });
    },
    handleSubCheckbox: function (e) {
      var $target = $(e.target);
      var checked, current;
      var count = 0;
      var outClass = "";
      var wrapperID = $target.closest(".item-wrapper").data("id");
      var allClass = "all" + wrapperID;
      e.preventDefault();
      e.stopPropagation();
      if ($target.hasClass(allClass)) {
        current = $target.attr("class").split(" ");
        if (current.indexOf("checked") !== -1) {
          $target.removeClass("checked");
          $("#grid" + wrapperID).find(".sub-check").removeClass("checked");
        } else if (current.indexOf("partial") !== -1) {
          outClass = "checked";
          $target.removeClass("partial").addClass("checked");
          $("#grid" + wrapperID).find(".sub-check").addClass("checked");
        } else {
          outClass = "checked";
          $target.addClass("checked");
          $("#grid" + wrapperID).find(".sub-check").addClass("checked");
        }
      } else {
        $target.toggleClass("checked").toggleClass("omit");
        count = $("#grid" + wrapperID).find(".sub-check:not(." + allClass + ")").size();
        checked = $("#grid" + wrapperID).find(".sub-check.checked:not(." + allClass + ")").size();
        if (count === checked) {
          outClass = "checked";
        } else if (checked < count && checked > 0) {
          outClass = "partial";
        }
        $("." + allClass).removeClass("checked partial").addClass(outClass);
      }
      $(".w-grid").trigger("changed:checkbox", {
        state: outClass === "" ? "subnone" : "subchecked"
      });
    },
    unload: function () {
      this.collection.abortCountCalls();
      var creativesData;
      for (var conceptID in this.conceptCreatives) {
        if (this.conceptCreatives.hasOwnProperty(conceptID)) {
          creativesData = this.conceptCreatives[conceptID];
          creativesData.gridDrawerTemplate.unload();
        }
      }
      $(".tipsy").remove();
      this.collection.reset([], {
        silent: true
      });
      if (this.collection.currentFetch) {
        this.collection.currentFetch.abort();
      }
      if ($("body").css("pointer-events") === "none") {
        $("body").css("pointer-events", "");
      }
    },
    serialize: function (models) {
      var self = this;
      var data = models || this.collection;
      var output;
      output = data.map(function (model) {
        var out = model.toJSON();
        out.showName = CreativeUtils.replaceSymbolsInName(out.name);
        out.showId = out.id;
        out.showAdName = CreativeUtils.replaceSymbolsInName(out.advertiser.name);
        if (self.highlightValue) {
          out.showName = T1.Utils.highlightText(out.showName, self.highlightValue);
          out.showId = T1.Utils.highlightText(out.id, self.highlightValue);
          out.showAdName = T1.Utils.highlightText(out.showAdName, self.highlightValue);
        }
        if (out.status === "1") {
          out.isActive = "true";
        } else {
          out.isActive = false;
        }
        return out;
      });
      return {
        pageControls: {
          count: data.models.length,
          total: data.count
        },
        list: output,
        contentType: "concept-creative-list-grid"
      };
    },
    postData: function () {},
    noop: function () {}
  });
  var inlineEditable = T1Form.InlineEdit(ConceptGridView);
  (function addInlineEditRenderers(collEvents) {
    var changeAttrs = ["name"];
    changeAttrs.forEach(function (attr) {
      collEvents["change:" + attr] = function (model, newVal) {
        this.renderChange(attr, model, newVal);
      };
    });
  })(ConceptGridView.prototype.dataEvents.collection);
  inlineEditable.prototype.actionsConfig = {
    events: {
      "click .save": "noop",
      "click .cancel": "noop"
    }
  };
  return createGrid(inlineEditable);
});
