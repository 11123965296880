define('models/segment',["jQuery", "Underscore", "T1", "Backbone", "T1Model", "models/behavior", "collections/behaviors", "modules/audiences/segments/segmentUtils", "modules/audiences/segments/behavior/utils/dateAttributeUtils"], function ($, _, T1, Backbone, T1Model, Behavior, BehaviorCollection, Util, DateUtil) {
  "use strict";

  var BEHAVIOR_TYPE_IDS = {
    eventPixel: 1,
    dataPixel: 1558,
    impression: 17,
    click: 18,
    allImpressions: 10
  };
  T1.Models.Segment = T1Model.extend({
    initialize: function () {
      this.behaviors = new BehaviorCollection();
    },
    urlRoot: "segments",
    apiRoot: T1.SEGMENT_API_BASE,
    url: function () {
      var query = "";
      if (this.get("id").indexOf("new") !== -1) {
        this.set({
          id: undefined
        });
      }
      if (this.id && this.id !== "new") {
        query = "/" + this.id + "?with=behaviors,attributes";
      }
      return this.apiRoot + this.urlRoot + query;
    },
    fixture: "-segment",
    name: "",
    SDLString: "",
    expression: "",
    ids: [],
    resetBehaviors: function () {
      this.behaviors = new BehaviorCollection();
    },
    getPlaceholderId: function () {
      var self = this;
      var getNextNewIdNumber = function () {
        var idNums = self.behaviors.models.map(function (item) {
          if (item.id.indexOf("new") < 0) {
            return item.id.substring(3);
          } else {
            return parseInt(item.id.substring(7));
          }
        });
        var highestId = idNums.length !== 0 ? Math.max.apply(null, idNums) : 0;
        return highestId + 1;
      };
      return "id_new_" + getNextNewIdNumber();
    },
    getNewIdFromOldId: function (oldId) {
      var newId = "";
      this.behaviors.each(function (behavior) {
        if (behavior.get("placeholderId") === oldId) {
          newId = behavior.get("id");
        }
      });
      return newId;
    },
    addBehavior: function (behavior, load) {
      var self = this;
      var behaviorCategory;
      var loadingData = function () {
        var json = JSON.parse(self.searchProp(behavior.prop, "expression"));
        var owner_id = self.searchProp(behavior.prop, "owner_id");
        var filterArray = [];
        var attributeMapper = function (array) {
          var attributes = [];
          var assignId = function (item) {
            var id;
            var prefix = item.expression ? item.expression : item;
            id = prefix.left ? prefix.left.attr_id : prefix.attr_id;
            return id;
          };
          var assignOp = function (item, catchBetween, not) {
            var op;
            var betweenOps = ["<", "<=", ">", ">="];
            if (item.expression || not) {
              var tempOp = not ? item.op : item.expression.op;
              op = Util.operatorToFromSDL("not " + tempOp);
            } else if (catchBetween && betweenOps.indexOf(item.op) !== -1) {
              op = item.op === "<" || item.op === ">" ? "not between" : "between";
            } else {
              op = Util.operatorToFromSDL(item.op) ? Util.operatorToFromSDL(item.op) : item.op;
            }
            return op;
          };
          var assignValues = function (item, numericArray, stringArray) {
            item = item.expression ? item.expression : item;
            if (item.left) {
              numericArray.push(item.right);
              return numericArray;
            } else {
              stringArray.push(item.value);
              return stringArray.join(", ");
            }
          };
          var assignAlt = function (attribute) {
            var altValues = [];
            if (attribute.id === 8) {
              attribute.values.map(function (value) {
                altValues.push(json.altValues[parseInt(value, 10)]);
              });
              if (altValues.length === 1) {
                altValues = altValues[0];
              }
            }
            return altValues;
          };
          array.map(function (filter) {
            var attribute = {};
            var numericArray = [];
            var stringArray = [];
            if (filter.values) {
              filter.values.map(function (value, i) {
                attribute.id = assignId(value);
                attribute.op = assignOp(value, "catchBetween");
                attribute.values = assignValues(value, numericArray, stringArray);
              });
            } else if (filter.op === "not" && filter.expression.values) {
              filter.expression.values.map(function (value, i) {
                attribute.id = assignId(value);
                attribute.op = assignOp(value, false, true);
                attribute.values = assignValues(value, numericArray, stringArray);
              });
            } else if (filter.op === "datetime") {
              attribute = DateUtil.convertJSONSDLforLoading(filter);
            } else {
              attribute.id = assignId(filter);
              attribute.op = assignOp(filter);
              attribute.values = assignValues(filter, numericArray, stringArray);
            }
            attribute.altValues = assignAlt(attribute);
            attributes.push(attribute);
          });
          attributes.map(function (attribute) {
            if (attribute.op === "not between" && attribute.op !== "datetime") {
              var once = false;
              attributes.map(function (n, i) {
                if (n.id === attribute.id && n.op === ">" && n.values[0] === 0 && n.values.length === 1 && !once) {
                  once = true;
                  attributes.splice(i, 1);
                }
              });
            }
          });
          return attributes;
        };
        if (json.filter_by.values) {
          json.filter_by.values.map(function (item) {
            if (item.left) {
              var attr_id = item.left.attr_id;
              var ids = _.values(BEHAVIOR_TYPE_IDS);
              if (_.contains(ids, attr_id)) {
                behavior.attr_id = attr_id;
                behavior.targetId = item.right;
              } else {
                filterArray.push(item);
              }
            } else {
              filterArray.push(item);
            }
          });
        } else {
          behavior.attr_id = json.filter_by.left.attr_id;
          behavior.targetId = json.filter_by.right;
        }
        behavior.filter_by = attributeMapper(filterArray);
        behavior.readable_filter = behavior.filter_by.length ? behavior.filter_by.map(function (filter) {
          return filter.id;
        }).join(", ") : "";
        behavior.entity_type = json.owner[0].entity_type;
        behavior.parentId = json.owner[0].id;
        behavior.owner_id = behavior.entity_type === "agency" ? owner_id : behavior.parentId;
        behavior.advertiserId = behavior.entity_type === "agency" ? owner_id : behavior.parentId;
        if (self.get("segment_type") === "adaptive") {
          behavior.recency = json.recency;
          behavior.frequency = json.frequency;
        }
        behaviorCategory = _.findKey(BEHAVIOR_TYPE_IDS, function (value) {
          return value === behavior.attr_id;
        });
        switch (behaviorCategory) {
          case "eventPixel":
            behavior.type = "pixel";
            behavior.category = "eventPixels";
            break;
          case "dataPixel":
            behavior.type = "pixel";
            behavior.category = "dataPixels";
            break;
          case "impression":
            behavior.type = "impression";
            behavior.category = "campaigns";
            break;
          case "click":
            behavior.type = "click";
            behavior.category = "campaigns";
            break;
          case "allImpressions":
            behavior.type = "allImpressions";
            behavior.category = "campaigns";
            break;
          default:
            break;
        }
        var new_id = "id_" + behavior.id;
        var behaviorModel = new Behavior(behavior);
        behaviorModel.set({
          id: new_id
        });
        self.behaviors.add(behaviorModel);
      };
      if (load) {
        loadingData();
      } else {
        if (behavior.attributes.filter_by) {
          behavior.attributes.readable_filter = behavior.attributes.filter_by.length ? behavior.attributes.filter_by.map(function (filter) {
            return filter.id;
          }).join(", ") : "";
          if (self.get("behavior_attributes")) {
            var idArray = behavior.get("readable_filter").split(", ").map(function (id) {
              var attributeObj = $.grep(self.get("behavior_attributes"), function (e) {
                return e.id === id;
              })[0];
              return attributeObj ? attributeObj.name : id;
            });
            behavior.attributes.readable_filter = idArray.join(", ");
          }
        }
        this.behaviors.add(new Behavior(behavior));
      }
    },
    getBehaviorCount: function () {
      return this.behaviors.size();
    },
    searchProp: function (data, term) {
      return $.grep(data, function (e) {
        return e.name === term;
      })[0].value;
    },
    removeBehavior: function (behaviorId) {
      this.behaviors.remove(this.behaviors.get(behaviorId));
    },
    getBehavior: function (behaviorId) {
      behaviorId = behaviorId.replace(/["]+/g, "");
      return this.behaviors.get(behaviorId);
    },
    parse: function (response) {
      var expression = "";
      var user_expression = "";
      var version = "";
      var name = "";
      var pixelId = "";
      var entityId = "";
      var sdlString, behaviors, segmentFields, segmentType, meritEvent, size, behavior_attributes;
      segmentFields = response.entity.prop;
      version = response.entity.version;
      name = response.entity.name;
      if (segmentFields) {
        if ($.isArray(segmentFields) && segmentFields.length > 0) {
          $.each(segmentFields, function (index, field) {
            switch (field.name) {
              case "expression":
                expression = field.value;
                break;
              case "user_expression":
                user_expression = field.value;
                break;
              case "name":
                name = field.value;
                break;
              case "version":
                version = field.value;
                break;
              case "pixel_id":
                pixelId = field.value;
                break;
              case "owner_id":
                entityId = field.value;
                break;
              case "SDLString":
                sdlString = field.value;
                break;
              case "behaviors":
                behaviors = field.entity;
                break;
              case "segment_type":
                segmentType = field.value;
                break;
              case "merit_event":
                meritEvent = field.value;
                break;
              case "size":
                size = field.value;
                break;
              case "attributes":
                behavior_attributes = field.entity;
                break;
            }
          });
        }
      }
      if (!user_expression) {
        user_expression = sdlString.match(/return .*/)[0].replace("return ", "").replace(/(id_\d*)/g, '"$1"').replace(/(\()/g, "$1 ").replace(/(\))/g, " $1");
      }
      this.set({
        expression: user_expression,
        version: version,
        name: name,
        pixel_id: pixelId,
        owner_id: entityId,
        user_expression: user_expression,
        behaviors: behaviors,
        merit_event: meritEvent,
        segment_type: segmentType,
        size: size === "0" ? "N/A" : size,
        behavior_attributes: behavior_attributes
      });
    },
    save: function (opts) {
      opts = opts || {};
      var requestPayload = {};
      var def = $.Deferred();
      var self = this;
      var showErrors = opts.showErrors;
      var statusInvalid = opts.onStatusInvalid;
      var v = T1.ENV;
      var id = self.id || self.cid;
      var handleInvalid = function (errors, message, formEl) {
        var names;
        var err;
        if (!statusInvalid || !showErrors) {
          throw new Error("Please make sure T1Form.showErrors and the save statusInvalid methods are in scope");
        }
      };
      var options = {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: def.resolve,
        error: function () {
          def.reject();
        },
        statusInvalid: handleInvalid,
        processAjaxResponse: self.processAjaxResponse
      };
      this.isDuplicate = opts.isDuplicate || false;
      var steelRainJson = this.getSteelrainJson();
      requestPayload.expression = steelRainJson.expression;
      requestPayload.user_expression = steelRainJson.user_expression;
      requestPayload.id = this.get("id");
      requestPayload.name = this.get("name");
      requestPayload.version = steelRainJson.version;
      requestPayload.owner_id = this.get("owner_id");
      requestPayload.owner_type = "advertiser";
      requestPayload.segment_type = this.get("segment_type");
      if (requestPayload.segment_type === "standard") {
        requestPayload.merit_event = this.get("merit_event");
      }
      return $.ajax({
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(requestPayload),
        url: self.url()
      }).pipe(function (response) {
        self.set({
          version: response.data.version
        });
        self.set({
          id: response.data.id
        });
      });
    },
    quoteIds: function (expression) {
      var tokens = expression.split(" ");
      var newTokens = [];
      tokens.map(function (token) {
        if (token.indexOf("id_") !== -1) {
          token = '"' + token + '"';
        }
        newTokens.push(token);
      });
      return newTokens.join(" ");
    },
    getSteelrainJson: function () {
      var self = this;
      var completeJson = {};
      var booleanExpression = "";
      var behaviors = {};
      var retval = {};
      var version = self.get("version");
      this.behaviors.each(function (model) {
        var behaviorJson = self.modelToSteelrain(model);
        var name = Object.keys(behaviorJson)[0];
        if (name.indexOf("new") > -1) {
          behaviors[name] = behaviorJson[name];
        }
      });
      booleanExpression = this.get("expression").trim();
      _.isEmpty(behaviors) === false ? completeJson.behaviors = behaviors : null;
      completeJson.expression = Util.expressionToJSONSdl(booleanExpression);
      retval.expression = this.isDuplicate ? JSON.stringify(completeJson).replace(/\\"/g, "") : JSON.stringify(completeJson).replace("'", "%27");
      retval.user_expression = this.isDuplicate ? this.get("user_expression") : this.quoteIds(booleanExpression);
      version >= 0 ? retval.version = parseInt(version, 10) : retval.version = 0;
      return retval;
    },
    modelToSteelrain: function (backboneModel) {
      var arithCompareOps = ["<", "<=", ">", ">=", "=", "!=", "+", "-", "/", "*", "%", "&", "|", "^"];
      var model = backboneModel.toJSON();
      var behaviorObj = {};
      var targetFilter = {};
      var name = model.id;
      var entityType = "advertiser";
      var isAdaptive = this.get("segment_type") === "adaptive";
      var valueArray;
      var attrId;
      var filter;
      var currentFilter;
      var isBetween;
      var firstFilter;
      var secondFilter;
      var filterValue;
      var numberFilterValue;
      var tempOp;
      var tempLeft;
      var tempValues;
      var min;
      var max;
      var firstRangeValue;
      var secondRangeValue;
      switch (backboneModel.get("type")) {
        case "impression":
          attrId = BEHAVIOR_TYPE_IDS.impression;
          break;
        case "click":
          attrId = BEHAVIOR_TYPE_IDS.click;
          break;
        case "allImpressions":
          attrId = BEHAVIOR_TYPE_IDS.allImpressions;
          break;
        case "pixel":
          if (backboneModel.get("category") === "eventPixels") {
            attrId = BEHAVIOR_TYPE_IDS.eventPixel;
            break;
          } else {
            attrId = BEHAVIOR_TYPE_IDS.dataPixel;
            entityType = "agency";
            break;
          }
      }
      targetFilter = {
        op: "=",
        left: {
          op: "attribute",
          attr_id: attrId
        },
        right: parseInt(backboneModel.get("targetId"))
      };
      behaviorObj[name] = {};
      behaviorObj[name].name = model.name;
      behaviorObj[name].owner = [{
        entity_type: entityType,
        id: parseInt(model.owner_id, 10)
      }];
      if (isAdaptive) {
        if (model.recency !== undefined && isAdaptive) {
          behaviorObj[name].recency = model.recency;
          behaviorObj[name].recency.op = behaviorObj[name].recency.op;
        } else {
          behaviorObj[name].recency = {
            count: 30,
            unit: "days",
            op: "within"
          };
        }
        if (model.frequency !== undefined) {
          if (model.frequency.min === "*") {
            min = 1;
          } else {
            min = parseInt(model.frequency.min, 10);
          }
          if (model.frequency.max === "*") {
            max = null;
          } else {
            max = parseInt(model.frequency.max, 10);
          }
          behaviorObj[name].frequency = {};
          behaviorObj[name].frequency.min = min;
          behaviorObj[name].frequency.max = max;
          behaviorObj[name].frequency.op = model.frequency.op;
        } else {
          behaviorObj[name].frequency = {
            min: 1,
            max: null,
            op: "between"
          };
        }
      }
      if (model.filter_by !== undefined && model.filter_by.length > 0) {
        behaviorObj[name].filter_by = {};
        behaviorObj[name].filter_by.op = "and";
        behaviorObj[name].filter_by.values = [];
        for (var i = 0; i < model.filter_by.length; i++) {
          filter = {};
          currentFilter = model.filter_by[i];
          tempValues = [];
          if (currentFilter.op === "includes" || currentFilter.op === "excludes") {
            tempOp = currentFilter.op === "includes" ? "=" : "!=";
            tempLeft = {
              op: "attribute",
              attr_id: parseInt(currentFilter.id)
            };
            valueArray = Array.isArray(currentFilter.values) ? currentFilter.values : currentFilter.values.split(",");
            var altArray = Array.isArray(currentFilter.altValues) ? currentFilter.altValues : currentFilter.altValues.split(",");
            if (currentFilter.altValues) {
              behaviorObj[name].altValues = behaviorObj[name].altValues === undefined ? {} : behaviorObj[name].altValues;
              valueArray.map(function (value, index) {
                behaviorObj[name].altValues[value.trim()] = altArray[index];
              });
            }
            if (valueArray.length === 1) {
              filter.op = tempOp;
              filter.left = tempLeft;
              filter.right = parseInt(valueArray[0]);
            } else {
              for (var o = 0; o < valueArray.length; o++) {
                tempValues.push({
                  op: tempOp,
                  left: tempLeft,
                  right: parseInt(valueArray[o])
                });
              }
              filter.op = currentFilter.op === "includes" ? "or" : "and";
              filter.values = tempValues;
            }
          } else if (Util.operatorToFromSDL(currentFilter.op)) {
            var values = currentFilter.values.split(",");
            if (values.length === 1) {
              filter.attr_id = parseInt(currentFilter.id);
              filter.op = Util.operatorToFromSDL(currentFilter.op);
              filter.value = currentFilter.values;
            } else {
              var filterValues = "";
              $.each(values, function (index, value) {
                tempValues.push({
                  op: Util.operatorToFromSDL(currentFilter.op),
                  attr_id: parseInt(currentFilter.id),
                  value: value
                });
              });
              filter.op = Util.operatorToFromSDL(currentFilter.op).substr(0, 3) === "not" ? "and" : "or";
              filter.values = tempValues;
            }
          } else if ((currentFilter.op === "between" || currentFilter.op === "not between") && !currentFilter.datetime) {
            isBetween = currentFilter.op === "between" ? true : false;
            firstFilter = {};
            secondFilter = {};
            filter.op = isBetween ? "and" : "or";
            filter.values = [];
            firstRangeValue = parseFloat(currentFilter.values[0]);
            secondRangeValue = parseFloat(currentFilter.values[1]);
            max = firstRangeValue > secondRangeValue ? firstRangeValue : secondRangeValue;
            min = firstRangeValue < secondRangeValue ? firstRangeValue : secondRangeValue;
            if (isBetween && min === 0) {
              min = 1;
            }
            if (!isBetween && min !== 0) {
              behaviorObj[name].filter_by.values.push({
                op: ">",
                right: 0,
                left: {
                  op: "attribute",
                  attr_id: parseInt(currentFilter.id)
                }
              });
            }
            firstFilter.op = isBetween ? ">=" : "<";
            firstFilter.right = parseFloat(min);
            if (isNaN(firstFilter.right)) {
              firstFilter.right = parseInt(filterValue);
            }
            firstFilter.left = {
              op: "attribute",
              attr_id: parseInt(currentFilter.id)
            };
            secondFilter.op = isBetween ? "<=" : ">";
            secondFilter.right = parseFloat(max);
            if (isNaN(secondFilter.right)) {
              secondFilter.right = parseInt(max);
            }
            secondFilter.left = {
              op: "attribute",
              attr_id: parseInt(currentFilter.id)
            };
            filter.values.push(firstFilter);
            filter.values.push(secondFilter);
          } else if ($.inArray(currentFilter.op, arithCompareOps) > -1) {
            filter.op = currentFilter.op;
            filterValue = Array.isArray(currentFilter.values) ? currentFilter.values[0] : currentFilter.values;
            numberFilterValue = parseFloat(filterValue);
            if (isNaN(numberFilterValue)) {
              numberFilterValue = parseInt(filterValue);
            }
            filter.right = numberFilterValue;
            filter.left = {
              op: "attribute",
              attr_id: parseInt(currentFilter.id)
            };
          } else if (currentFilter.datetime) {
            filter = DateUtil.toSteelRain(currentFilter);
          }
          behaviorObj[name].filter_by.values.push(filter);
        }
        behaviorObj[name].filter_by.values.push(targetFilter);
      } else {
        behaviorObj[name].filter_by = targetFilter;
      }
      return behaviorObj;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: resp.status.code || "ok"
      };
    }
  });
  return T1.Models.Segment;
});
