define('modules/reporting/campaigns/siteTransparency/views/chartTitle',["jQuery", "Underscore", "T1", "T1View", "text!../../templates/chart_title_box.html"], function ($, _, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    eventhubEvents: {
      "update:metric": function () {
        this.reload();
      },
      "switch:chart": function (chartType) {
        this.subSection = chartType;
        this.reload();
      },
      "update:grid:title": function () {
        this.reload();
      }
    },
    dataEvents: {
      reportModel: {
        "change:dimensionItems": function () {
          this.reload();
        },
        "change:hiddenCount": "updateSubtitle"
      }
    },
    initialize: function (args) {
      const models = args.models;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.subSection = models.mainModel.get("siteTransparencySubSection");
      this.timeSeriesInfo = models.timeSeries.daily;
      this.timeSeriesLabel = this.timeSeriesInfo.label;
      this.setAxisLabel();
    },
    setAxisLabel: function () {
      let xKey;
      const reportModel = this.reportModel;
      const focus = reportModel.get("focus");
      const metricKey = this.reportMetaObj.metrics[focus];
      const defaultYKey = this.reportMetaObj.metricOptions[0].value;
      if (this.subSection === "daily") {
        this.xAxisLabel = this.timeSeriesInfo.xAxisLabel;
      } else {
        xKey = reportModel.get("metric");
        this.xAxisLabel = this.reportMetaObj.metrics[focus][xKey].text;
      }
      const yKey = reportModel.get("metric2");
      this.yAxisLabel = metricKey[yKey] ? metricKey[yKey].text : metricKey[defaultYKey].text;
    },
    getTitle: function () {
      if (this.subSection === "summary") {
        return `${this.xAxisLabel} & ${this.yAxisLabel} by Site`;
      }
      return `${this.yAxisLabel} by Site`;
    },
    getSubtitle: function () {
      const reportModel = this.reportModel;
      const filterRange = reportModel.get("siteFilterRange");
      let filterMetric = reportModel.get("siteFilterMetric");
      const yAxisLabel = this.yAxisLabel;
      const pos = filterMetric.charAt(0) === "-" ? "top" : "bottom";
      const dimensionItemsCount = reportModel.get("dimensionItemsCount");
      const filteredInCount = reportModel.get("filteredInCount");
      const totalCount = reportModel.get("filteredOutCount") === 0 ? dimensionItemsCount : filteredInCount;
      const sitesHidden = reportModel.getHiddenItemsCount();
      let siteCount = sitesHidden === 0 ? ` (all ${totalCount})` : ` (${totalCount - sitesHidden} of ${totalCount})`;
      const searchTerm = reportModel.getSearchTerm();
      const hiddenItems = reportModel.getHiddenItems();
      const searchedDomains = reportModel.getSearchedItems() || [];
      const searchedDomainsCount = searchedDomains.length;
      let searchedHiddenDomain = 0;
      const dim1 = reportModel.get("dimensionInfo").text;
      if (pos === "top") {
        filterMetric = filterMetric.substr(1);
      }
      if (searchTerm !== "") {
        if (sitesHidden > 0) {
          _.each(searchedDomains, function (domain) {
            if (hiddenItems[domain] === true) {
              searchedHiddenDomain++;
            }
          });
          siteCount = ` (${searchedDomainsCount - searchedHiddenDomain} of ${searchedDomainsCount})`;
        } else {
          siteCount = ` (all ${searchedDomainsCount})`;
        }
      }
      if (this.subSection === "daily") {
        return `Lines show ${this.timeSeriesLabel.toLowerCase()} ${yAxisLabel} by ${dim1} ${siteCount}`;
      }
      return `Dots show ${this.xAxisLabel} & ${yAxisLabel} of ${pos} ${filterRange} domains by\n        ${this.reportMeta.getFriendlyName("metrics", filterMetric)} ${siteCount}`;
    },
    updateSubtitle: function () {
      this.updateDataBind({
        chart_subtitle: this.getSubtitle()
      }, this.el);
    },
    serialize: function () {
      this.setAxisLabel();
      return {
        chart_subtitle: this.getSubtitle(),
        chart_title: this.getTitle()
      };
    }
  });
});
