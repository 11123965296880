define('modules/creatives/views/date_picker',['require','jQuery','moment','T1','T1View','text!../templates/date_picker.html','T1DatePicker','T1Timezones'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const moment = require("moment");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/date_picker.html");
  const T1DatePicker = require("T1DatePicker");
  const T1Timezones = require("T1Timezones");
  var Date = T1.Date;
  return T1View.extend({
    template: template,
    defaults: {
      allowMultipleSelection: false,
      dateFormat: "YYYY-MM-DDTHH:mm:ss",
      displayFormat: "MMMM D, YYYY h:mma",
      endChecked: false,
      endDate: null,
      endDateCheckLabel: "End Date",
      onChanged: $.noop,
      positionBy: "bottomTop",
      showCheckboxes: false,
      showRemoveScheduleCheck: false,
      showScheduleCheck: false,
      showTitle: true,
      startChecked: false,
      startDate: null,
      startDateCheckLabel: "Start Date",
      startEndOrderError: "Please make sure your selected END DATE is after START DATE.",
      utc: true
    },
    getDefaultStartDate(inputDate) {
      const oneHour = 60;
      var date = inputDate || moment.utc();
      var zoneOffset = date.utcOffset();
      var defaultStartDate = moment.utc(date.format()).add(oneHour - zoneOffset, "minutes").startOf("hour");
      return defaultStartDate;
    },
    initialize(args) {
      $.extend(this, this.defaults, args);
      this.isEnabled = this.showScheduleCheck ? this.startDate !== null || this.endDate !== null : true;
      if (this.showScheduleCheck) {
        this.useStartChecked = this.useStartChecked === "true" || !this.startDate;
        this.useEndChecked = this.useEndChecked === "true" || !this.endDate;
      } else {
        this.useStartChecked = this.showCheckboxes && !this.startDate && this.endDate;
        this.useEndChecked = this.showCheckboxes && !this.endDate && this.startDate;
      }
      this.defaultStartDate = this.defaultStartDate || this.getDefaultStartDate();
      this.defaultEndDate = this.defaultEndDate || this.defaultStartDate.clone().add(1, "month");
      this.startDate = this.startDate || this.defaultStartDate;
      this.endDate = this.endDate || this.defaultEndDate;
      this.startDate = this.utc ? moment.utc(this.startDate) : moment(this.startDate);
      this.endDate = this.utc ? moment.utc(this.endDate) : moment(this.endDate);
    },
    events: {
      "click .end-date": "onEndDateCheckClick",
      "click .remove-schedule input[type=checkbox]": "onRemoveScheduleCheckClick",
      "click .schedule input[type=checkbox]": "onScheduleCheckClick",
      "click .start-date": "onStartDateCheckClick"
    },
    load() {
      this.render().then(() => {
        this.attachDatePicker();
      });
    },
    attachDatePicker() {
      const topIndex = 9999;
      var self = this;
      this.datePicker = T1.DoubleDatePicker({
        topOffset: 25,
        positionBy: self.positionBy,
        chooseTime: true,
        chooseTimezone: false,
        lowerBounds: Date.parse("today"),
        startTimeEditable: () => !self.useStartChecked,
        startDisabled: () => self.useStartChecked,
        endTimeEditable: () => !self.useEndChecked,
        endDisabled: () => self.useEndChecked,
        endMinDate: () => Date.parse("today"),
        getStartDate: () => self.startDate.format(),
        getEndDate: () => self.endDate.format(),
        zoneName: () => self.zoneName || "",
        onOpen: function () {
          $(".cluetip-datepicker").css("z-index", topIndex);
        },
        onSave: function (obj) {
          var saveValues = obj.data.saveValues;
          var newStartDate = moment.utc(saveValues.start.date);
          var newEndDate = moment.utc(saveValues.end.date);
          if (newStartDate.isAfter(newEndDate)) {
            return obj.error({
              message: self.startEndOrderError
            });
          }
          self.startDate = newStartDate;
          self.endDate = newEndDate;
          self.update(true);
          obj.success();
          return true;
        }
      }, this.el);
    },
    onStartDateCheckClick(evt) {
      this.onStartOrEndDateClick(evt, true);
    },
    onEndDateCheckClick(evt) {
      this.onStartOrEndDateClick(evt, false);
    },
    onScheduleCheckClick(evt) {
      var $target = $(evt.currentTarget);
      var isChecked = $target.is(":checked");
      this.isEnabled = isChecked;
      this.useStartChecked = !isChecked;
      this.useEndChecked = !isChecked;
      this.update(true);
    },
    onRemoveScheduleCheckClick(evt) {
      var $target = $(evt.currentTarget);
      var isChecked = $target.is(":checked");
      this.isEnabled = !isChecked;
      this.useRemoveScheduleCheck = isChecked;
      this.useStartChecked = isChecked;
      this.useEndChecked = isChecked;
      this.update(true);
    },
    onStartOrEndDateClick(evt, isStartDate) {
      var $target = $(evt.currentTarget);
      var isChecked = $target.is(":checked");
      if (!this.allowMultipleSelection && isChecked) {
        $target.closest("dt").find(".checkbox").not($target).attr("checked", isChecked);
        if (isStartDate) {
          this.useEndChecked = false;
        } else {
          this.useStartChecked = false;
        }
      }
      if (isStartDate) {
        this.useStartChecked = isChecked;
      } else {
        this.useEndChecked = isChecked;
      }
      this.update(true);
    },
    getDates(format, defaultStart, defaultEnd) {
      return {
        start: !this.isEnabled || this.useStartChecked ? defaultStart || "" : this.startDate.format(format),
        end: !this.isEnabled || this.useEndChecked ? defaultEnd || "" : this.endDate.format(format)
      };
    },
    update(render) {
      var dates = this.getDates(this.dateFormat);
      this.onChanged(dates);
      if (render) {
        this.load();
      }
    },
    serialize() {
      var dates = this.getDates(this.dateFormat);
      var displayDates = this.getDates(this.displayFormat, this.startDateCheckLabel, this.endDateCheckLabel);
      return {
        dates: dates,
        displayDates: displayDates,
        endChecked: this.endChecked,
        endDataBind: this.endDataBind,
        endDateCheckLabel: this.endDateCheckLabel,
        isEnabled: this.isEnabled,
        scheduleDisabled: this.scheduleDisabled,
        showCheckboxes: this.showCheckboxes,
        showRemoveScheduleCheck: this.showRemoveScheduleCheck,
        showScheduleCheck: this.showScheduleCheck,
        showTitle: this.showTitle,
        startChecked: this.startChecked,
        startDataBind: this.startDataBind,
        startDateCheckLabel: this.startDateCheckLabel,
        timezone: this.utc ? "Campaign" : "Local",
        useEndChecked: this.useEndChecked,
        useRemoveScheduleCheck: this.useRemoveScheduleCheck,
        useStartChecked: this.useStartChecked
      };
    }
  });
});
