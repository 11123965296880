define('modules/T1App/edit/access/models/accessUsersModel',["jQuery", "Underscore", "T1", "T1Comm", "T1Model", "T1Collection"], function ($, _, T1, T1Comm, T1Model, T1Collection) {
  "use strict";

  var teamModel = T1Model.extend({
    dataType: "json",
    currentSearchConfig: [],
    searchConfiguration: {
      fields: {
        email: {
          type: "enum"
        },
        role: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    url: function () {
      var appID = this.appID || this.attributes.app_id;
      var url = T1.API_APPS_ROOT + "v1.0/acl/";
      if (this.searchTerm) {
        url = url + appID + "?offset=0&q=type==users&name=" + this.searchTerm;
      } else {
        url = url + this.attributes.app_id + "/user";
      }
      return url;
    },
    fetch: function (options) {
      var self = this;
      var success = options.success || $.noop;
      var opts = $.extend({
        dataType: "json",
        onSuccess: onSuccess,
        sourceURL: this.url()
      }, options);
      function onSuccess(resp, status, xhr) {
        success.call(self, resp.data[0]);
      }
      return T1Model.prototype.fetch.call(this, opts);
    },
    selectedUsers: function () {
      var users = this.accessUsers;
      var selectedUsers = [];
      for (var i = 0; i < users.length; i++) {
        if (users[i].access) {
          selectedUsers.push({
            user_id: users[i].id,
            email: users[i].user_name
          });
        }
      }
      this.devTeam = selectedUsers;
      return this.devTeam;
    },
    getInitialDevTeam: function () {
      var users = [];
      var data = this.devTeam;
      for (var i = 0; i < data.length; i++) {
        users.push(data[i].user_id);
      }
      return users;
    },
    compareChanges: function (currentDevteam) {
      var newArr = currentDevteam;
      var oldArr = this.oldDevTeam || this.initialUsers;
      var oldDeleteArr = this.attributes.removeFromDev || [];
      var deleteArr = [];
      var addArr = [];
      for (var i = 0; i < newArr.length; i++) {
        var newMatch = false;
        for (var j = 0; j < oldArr.length; j++) {
          if (newArr[i] === oldArr[j]) {
            newMatch = true;
            break;
          }
        }
        if (!newMatch) {
          addArr.push(newArr[i]);
        }
      }
      for (var k = 0; k < oldArr.length; k++) {
        var oldMatch = false;
        for (var l = 0; l < oldArr.length; l++) {
          if (oldArr[k] === newArr[l]) {
            oldMatch = true;
            break;
          }
        }
        if (!oldMatch) {
          deleteArr.push(oldArr[k]);
        }
      }
      this.currentDevteam = currentDevteam;
      this.set({
        addToDev: addArr,
        removeFromDev: deleteArr
      });
    },
    save: function (attributes, options) {
      var self = this;
      var statusInvalid = options.statusInvalid || $.noop;
      var onSuccess = options.success || $.noop;
      var error = options.error || $.noop;
      var oldDevTeam;
      if (self.currentDevteam) {
        self.compareChanges(self.currentDevteam);
      }
      oldDevTeam = [].concat(self.currentDevteam);
      attributes.addToDev = self.attributes.addToDev || [];
      attributes.removeFromDev = self.attributes.removeFromDev || [];
      if (attributes.addToDev.length > 0) {
        attributes.user_id = attributes.addToDev;
        this.sync("POST", attributes, {
          action: options.action,
          dataType: options.dataType,
          processData: options.processData,
          stringify: options.stringify,
          contentType: options.contentType,
          processAjaxResponse: this.processAjaxResponse,
          success: function (data) {
            self.oldDevTeam = oldDevTeam;
            onSuccess(data);
          },
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400, 404]
          },
          error: error,
          statusInvalid: statusInvalid
        });
      }
      if (attributes.removeFromDev.length > 0) {
        attributes.user_id = attributes.removeFromDev;
        this.sync("POST", attributes, {
          action: options.action,
          httpAction: "delete",
          dataType: options.dataType,
          processData: options.processData,
          stringify: options.stringify,
          contentType: options.contentType,
          processAjaxResponse: this.processAjaxResponse,
          success: function (data) {
            self.oldDevTeam = oldDevTeam;
            onSuccess(data);
          },
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400, 404]
          },
          error: error,
          statusInvalid: statusInvalid
        });
      }
      if (attributes.addToDev.length === 0 && attributes.removeFromDev.length === 0) {
        onSuccess();
      }
    },
    processAjaxResponse: function (resp) {
      var jsonData;
      var dataType;
      var statusCode;
      if (resp.meta === "success") {
        statusCode = "ok";
      } else {
        statusCode = "error";
      }
      return {
        jsonData: resp,
        statusCode: statusCode
      };
    },
    setMainTabSection: function (sectionId) {
      this.set({
        mainTabSectionId: sectionId
      }, {
        silent: true
      });
    }
  });
  return teamModel;
});
