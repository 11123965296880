define('modules/reporting/campaigns/views/selectDimension',["jQuery", "Underscore", "Hogan", "T1", "T1View", "T1Layout", "text!../templates/selectDimension.html"], function ($, _, Hogan, T1, T1View, T1Layout, template) {
  "use strict";

  var documentEvents = "mousedown.dimension keyup.dimension";
  var $document = $(document);
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  const SINGLE_HEIGHT = 45;
  const ROW_HEIGHT = 30;
  const MAX_HEIGHT = 200;
  return T1View.extend({
    template: template,
    ddlOpen: false,
    allChecked: false,
    currentLabelText: "All Strategies",
    events: {
      "changed .search": "search",
      "click .all-search-results": "checkAllSearchedItems",
      "click .all": "checkAll",
      "click .clear-filter": "clearFilter",
      "click .dimension-list": "onSelectChange",
      "click .items-container .items": "checkItem",
      "click .remove-dimension": "removeDim2",
      "click #apply": "applyFilteredItems",
      "click #cancel": "close",
      "mousedown .head-label": "toggle",
      "mousedown .select-dimension": "stopBubbling"
    },
    dataEvents: {
      reportModel: {
        "change:dimension": function () {
          if (this.isDim1Instance && !this.isFromReachFrequency) {
            this.updateDimensionText();
          }
        },
        "change:dim2": function () {
          if (this.isDim2Instance) {
            this.updateDimensionText();
          }
        },
        "update:dim1DDL": function () {
          if (this.isDim1Instance) {
            this.updateFilterList();
          }
        },
        "update:dim2DDL": function () {
          if (this.isDim2Instance) {
            this.updateFilterList();
          }
        }
      }
    },
    eventhubEvents: {
      "select:campaign": function () {
        this.resetToAll();
      }
    },
    initialize: function (args) {
      var models = args.models;
      var dimensionData, selectedDimension;
      var reportModel = models.reportModel;
      var section = models.mainModel.get("section") || "performance";
      var focus = reportModel.get("focus") || "all";
      this.dim2InitState = true;
      this.dimensionOptions = args.dimensionOptions;
      this.label = args.label;
      this.isFromReachFrequency = args.isFromReachFrequency || false;
      this.models = models;
      this.isDim1Instance = args.index === 0;
      this.isDim2Instance = args.index === 1;
      this.reportMeta = models.reportMeta;
      this.reportModel = reportModel;
      this.selectedDimension = selectedDimension = reportModel.get("dimension") || null;
      dimensionData = this.reportMeta.get("dimensions")[section][focus][selectedDimension];
      if (dimensionData && selectedDimension) {
        this.allText = `All ${dimensionData.xAxisLabel}`;
      } else {
        this.allText = "All Strategies";
      }
      if (!this.isFromReachFrequency) {
        this.updateDimensionInfo();
      }
      reportModel.registerDimFilterList(this, args.index);
    },
    load: function () {
      var reportModel;
      var self = this;
      var models = this.models;
      this.reportMeta = models.reportMeta;
      this.reportModel = reportModel = models.reportModel;
      if (this.isDim2Instance) {
        this.dim2InitState = !reportModel.getIsDim2Active();
        this.selectedDimension = reportModel.get("dim2");
      }
      if (!this.isFromReachFrequency) {
        this.updateDimensionInfo();
      }
      this.render().then(function () {
        self.unloaded = false;
        self.initUI();
        if (self.isFromReachFrequency) {
          self.el.find(".dimension-list").addClass("no-display");
        }
      });
    },
    initUI: function () {
      var $el = this.el;
      this.$wBody = $el.find(".w-body");
      this.$headLabel = $el.find(".head-label");
      this.$clearFilter = $el.find(".clear-filter");
      this.$searchInput = $el.find(".search");
    },
    toggle: function (event) {
      event.stopPropagation();
      if (this.ddlOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open: function () {
      var self = this;
      var items = this.items || [];
      this.ddlOpen = true;
      $document.mousedown();
      this.closeByApply = false;
      this.updateHeight(items.length);
      this.$wBody.removeClass("no-display");
      this.$headLabel.addClass("white-bg");
      this.$clearFilter.addClass("white-bg");
      if (this.inSearchMode) {
        this.search();
      }
      if (this.isDim2Instance) {
        this.updateDim2UI(this.ddlOpen);
      }
      if (this.isFromReachFrequency || !this.dim2InitState) {
        this.toggleFooterButtons();
      }
      $document.one(documentEvents, function (event) {
        if (event.keyCode === TAB_KEY || event.keyCode === ESCAPE_KEY || !event.keyCode) {
          self.close();
        }
      });
    },
    close: function () {
      this.ddlOpen = false;
      this.$wBody.addClass("no-display");
      this.$headLabel.removeClass("white-bg");
      this.$clearFilter.removeClass("white-bg");
      const inTemporaryListMode = this.inTemporaryListMode();
      if (!this.closeByApply) {
        if (inTemporaryListMode) {
          this.reportModel.restoreOriginalDDLItems();
        }
        this.resetFilter();
        if (!this.inSearchMode) {
          this.$searchInput.val("");
          this.updateFilterList();
        }
      }
      if (this.inSearchMode) {
        this.$searchInput.val(this.searchTerm);
        this.updateFilterList();
      }
      if (this.isDim2Instance) {
        this.updateDim2UI(this.ddlOpen);
      }
      $document.off(documentEvents);
    },
    updateDim2UI: function (showUI) {
      var $el = this.el;
      var $headLabel = this.$headLabel;
      var $dimensionLabel = $headLabel.find(".add-dim2");
      var $xIcon = $headLabel.find(".x-icon");
      var $removeDimension = $el.find(".remove-dimension");
      var whiteBgClass = "white-bg";
      var selectionStateClass = "selection-state";
      var dividerAfterClass = "divider-after";
      var initState = this.dim2InitState;
      var selectedDimension = this.reportModel.get("dim2");
      var dimensionList = $el.find(".dimension-list");
      if (showUI) {
        if (initState) {
          $dimensionLabel.addClass(selectionStateClass);
          $xIcon.show();
          $headLabel.addClass(dividerAfterClass);
          this.updateDataBind({
            initLabelText: "Dimension Filter"
          }, $el);
        } else {
          $removeDimension.addClass(whiteBgClass);
        }
      } else {
        $headLabel.removeClass(dividerAfterClass);
        if (initState) {
          $dimensionLabel.removeClass(selectionStateClass);
          $xIcon.hide();
          this.updateDataBind({
            initLabelText: "+ Add Filter"
          }, $el);
        } else {
          $removeDimension.removeClass(whiteBgClass);
        }
      }
      if (initState) {
        $el.find(".dimension-action-container").addClass("no-display");
        dimensionList.val("");
        this.prevSelectedDim = "";
        $el.find("#apply").attr("disabled", true);
      } else {
        $el.find(".dimension-action-container").removeClass("no-display");
        dimensionList.val(selectedDimension);
        this.prevSelectedDim = selectedDimension;
      }
    },
    removeDim2: function () {
      var reportModel = this.reportModel;
      var total = reportModel.get("filteredOutCount") > 0 ? "Total (filtered)" : "Total";
      var isReloadNeeded = reportModel.get("filteredOutCountDim2") > 0;
      $document.mousedown();
      this.dim2InitState = true;
      reportModel.set({
        dim1FilteredDDLItems: null,
        dim1FilteredItems: null,
        dim1FilteredItemsCount: null,
        dim1FilteredItemsLookup: null,
        dim2: null,
        dim2DDLItems: null,
        dim2Info: null,
        dim2Items: null,
        dim2ItemsCount: null,
        dim2ItemsLookup: null,
        filteredInCountDim2: null,
        filteredInItemsDim2: null,
        filteredOutCountDim2: null,
        filteredOutItemsDim2: null,
        hiddenCountDim2: null,
        hiddenItemsDim2: null,
        tempDim2: null,
        tempDim2Info: null,
        totalLabel: total
      }, {
        silent: true
      });
      this.$headLabel.removeClass("white-bg");
      this.$wBody.addClass("no-display");
      this.el.find(".remove-dimension").addClass("no-display");
      this.renderPartial(".head-label.dim2");
      T1.EventHub.publish("remove:dim2", isReloadNeeded);
    },
    applyFilteredItems: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var selectEvent = this.isDim1Instance ? "select:dimension" : "select:dim2";
      var currentDimension = reportModel.getDimension(isDim2Instance);
      var selectedDimension = this.selectedDimension || currentDimension;
      var filteredKey = reportModel.getFilteredKey(isDim2Instance);
      var dimChanged = selectedDimension !== currentDimension;
      var $checkedItems = this.el.find(".items[checked]:not(.no-display)");
      var checkedItemsObj = {};
      this.$clearFilter.removeClass("no-display");
      this.searchTerm = this.$searchInput.val();
      this.inSearchMode = this.searchTerm !== "";
      this.closeByApply = true;
      if (this.isFromReachFrequency) {
        this.currentLabelText = "Strategy";
        this.renderPartial(".head-label");
      }
      _.each(this.items, function (item) {
        checkedItemsObj[item.id] = false;
      });
      _.each($checkedItems, function (item) {
        checkedItemsObj[item.getAttribute("data-id")] = true;
      });
      if (this.ddlOpen) {
        if (isDim2Instance) {
          if (this.dim2InitState) {
            this.dim2InitState = false;
            this.el.find(".remove-dimension").removeClass("no-display");
            this.renderPartial(".head-label.dim2");
          }
        }
      }
      reportModel.clearTempDimension();
      reportModel.setFilteredOutItems(checkedItemsObj, dimChanged, true, isDim2Instance, filteredKey);
      T1.EventHub.publish(selectEvent, selectedDimension);
      $document.mousedown();
    },
    resetFilter: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var currentDimension = reportModel.getDimension(this.isDim2Instance);
      if (isDim2Instance) {
        reportModel.setDimension(null, null, null, currentDimension, false);
      } else {
        reportModel.setDimension(currentDimension, false);
      }
      reportModel.clearTempDimension();
    },
    clearFilter: function () {
      this.resetUI();
      this.applyFilteredItems();
      this.close();
    },
    resetToAll: function () {
      var reportModel = this.reportModel;
      this.resetUI();
      reportModel.resetFilteredInItems();
      reportModel.setAppliedFilteredItems(null, false);
      reportModel.setAppliedFilteredItems(null, true);
      this.updateFilterList();
    },
    resetUI: function () {
      var $el = this.el;
      var $items = $el.find(".items");
      $el.find(".all").attr("checked", true);
      $el.find(".all-search-results").removeAttr("checked");
      $items.attr("checked", true);
      $items.removeClass("no-display");
      this.inSearchMode = false;
      this.searchTerm = "";
      this.$searchInput.val("");
      this.$clearFilter.addClass("no-display");
      if (this.isFromReachFrequency) {
        this.currentLabelText = "All Strategies";
        this.renderPartial(".head-label");
      }
    },
    inTemporaryListMode: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var tempDimension = reportModel.getTempDimension(isDim2Instance);
      var dimension = reportModel.getDimension(isDim2Instance);
      return dimension === null || tempDimension && dimension && tempDimension !== dimension;
    },
    onSelectChange: function (event) {
      var selection = $(event.target).val();
      var $el = this.el;
      var currentDimension = this.reportModel.getDimension(this.isDim2Instance);
      if (this.prevSelectedDim !== selection) {
        if (this.isDim2Instance && event.currentTarget.state === "closed") {
          if (this.inSearchMode && currentDimension === selection) {
            this.$searchInput.val(this.searchTerm);
          } else {
            this.$searchInput.val("");
          }
          $el.find(".dimension-action-container").removeClass("no-display");
          $el.find(".dimension-container").addClass("no-display");
          $el.find(".loading-message-container").removeClass("no-display");
          $el.find("#apply").attr("disabled", true);
          this.prevSelectedDim = this.selectedDimension = selection;
          if (this.isDim1Instance) {
            this.reportModel.setDimension(selection, true);
          } else {
            this.reportModel.setDimension(null, null, null, selection, true);
          }
          this.updateDimensionInfo(true);
        }
      }
    },
    updateFilterList: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var tempDimension = reportModel.getTempDimension(isDim2Instance);
      var dimension = reportModel.getDimension(isDim2Instance);
      var focus = reportModel.get("focus") || "all";
      var dim = tempDimension || dimension;
      var section = this.models.mainModel.get("section") || "performance";
      var dimensionData = this.reportMeta.get("dimensions")[section][focus][dim];
      var filteredInItems = reportModel.getFilteredInItems(isDim2Instance) || [];
      var filteredOutItems = reportModel.getFilteredOutItems(isDim2Instance) || [];
      var items = reportModel.getDimensionFilterItems(isDim2Instance) || null;
      var i, selected;
      var itemsLength = items ? items.length : 0;
      var temp = this.inTemporaryListMode();
      var $clearFilter = this.$clearFilter;
      var $el = this.el;
      var self = this;
      if (this.isFromReachFrequency && $clearFilter && $clearFilter.length > 0) {
        if (filteredOutItems.length > 0) {
          $clearFilter.removeClass("no-display");
          this.currentLabelText = "Strategy";
        } else {
          this.currentLabelText = "All Strategies";
          $clearFilter.addClass("no-display");
        }
        this.renderPartial(".head-label");
      }
      this.allText = dimensionData ? `All ${dimensionData.xAxisLabel}` : "All Strategies";
      this.selectedDimension = dim;
      if (temp) {
        for (i = 0; i !== itemsLength; i++) {
          items[i].selected = true;
        }
      } else {
        if (filteredInItems) {
          for (i = 0; i !== itemsLength; i++) {
            selected = filteredInItems.indexOf(items[i].id) > -1;
            items[i].selected = selected;
          }
        }
      }
      this.allChecked = temp || filteredOutItems.length === 0;
      this.setItems(items);
      $el.find(".dimension-container").removeClass("no-display");
      $el.find(".loading-message-container").addClass("no-display");
      this.renderPartial(".dimension-container");
      setTimeout(function () {
        self.search();
      }, 0);
      if (isDim2Instance && this.allChecked) {
        $el.find("#apply").removeAttr("disabled");
      }
    },
    setItems: function (items) {
      if (items && items[0] && items[0].name) {
        this.items = this.reportModel.sortItems(items);
      } else {
        this.items = items;
      }
    },
    search: function () {
      var $el = this.el;
      var searchValue = this.$searchInput.val();
      var regExp = new RegExp(searchValue, "i");
      var searchItemCount = 0;
      var $noResult = $el.find(".no-result");
      if (this.items && this.items.length > 0) {
        $noResult.addClass("no-display");
        $el.find(".items").removeClass("no-display");
        _.each(this.items, function (item) {
          if (item.searchKey.search(regExp) === -1) {
            $el.find(`strand-checkbox[data-id="${item.id}"]`).addClass("no-display");
            item.searched = false;
          } else {
            searchItemCount++;
            item.searched = true;
          }
        });
        if (searchItemCount === 0) {
          $noResult.removeClass("no-display");
          $noResult.text(`No results found for "${searchValue}"`);
        }
        this.updateAllCheckbox();
        this.updateHeight(searchItemCount);
      }
    },
    updateHeight: function (itemCnt) {
      var itemsHeight = itemCnt === 0 ? SINGLE_HEIGHT : (itemCnt + 1) * ROW_HEIGHT;
      var $scrollPanel = this.el.find("strand-scroll-panel");
      if (itemsHeight < MAX_HEIGHT) {
        $scrollPanel.height(itemsHeight);
      } else {
        $scrollPanel.height(MAX_HEIGHT);
      }
    },
    doCheck: function (isChecked, $el) {
      if (isChecked) {
        $el.attr("checked", true);
      } else {
        $el.removeAttr("checked");
      }
      this.toggleFooterButtons();
    },
    checkAll: function (event) {
      this.doCheck(event.target.checked, this.el.find(".items"));
    },
    checkAllSearchedItems: function (event) {
      this.doCheck(event.target.checked, this.el.find(".items:not(.no-display)"));
    },
    checkItem: function (event) {
      var itemSelected = $(event.target).attr("checked");
      var itemId = $(event.target).data("id");
      event.stopPropagation();
      _.each(this.items, function (item) {
        if (itemId === item.id) {
          item.selected = itemSelected;
        }
      });
      this.updateAllCheckbox();
    },
    updateAllCheckbox: function () {
      var $el = this.el;
      var searchValue = this.$searchInput.val();
      var itemsCheckedLength = 0;
      var itemsLength = 0;
      var searchedSelectedItems = 0;
      var searchedItems = 0;
      var $all = $el.find(".all");
      var $allSearchResults = $el.find(".all-search-results");
      $all.addClass("no-display");
      $allSearchResults.addClass("no-display");
      if (searchValue.length === 0) {
        itemsCheckedLength = $el.find(".items[checked]").length;
        itemsLength = $el.find(".items").length;
        $all.removeClass("no-display");
        if (itemsLength === itemsCheckedLength) {
          $all.attr("checked", true);
        } else {
          $all.removeAttr("checked");
        }
      } else {
        searchedSelectedItems = $el.find(".items[checked]:not(.no-display)").length;
        searchedItems = $el.find(".items:not(.no-display)").length;
        if (searchedItems > 0) {
          $allSearchResults.removeClass("no-display");
          if (searchedSelectedItems === searchedItems) {
            $allSearchResults.attr("checked", true);
          } else {
            $allSearchResults.removeAttr("checked");
          }
        }
      }
      this.toggleFooterButtons();
    },
    getEmptyMessage: function () {
      var reportModel = this.reportModel;
      var isDim2Instance = this.isDim2Instance;
      var tempDimension = reportModel.getTempDimension(isDim2Instance);
      var dimensionInfo = tempDimension ? reportModel.getTempDimensionInfo(isDim2Instance) : reportModel.getDimensionInfo(isDim2Instance);
      if (dimensionInfo) {
        return `No ${dimensionInfo.xAxisLabel.toLowerCase()} found.`;
      }
    },
    updateDimensionInfo: function (withTempInfo) {
      var defaultLabelTxt = "Strategy";
      var isDim2Instance = this.isDim2Instance;
      var reportModel = this.models.reportModel;
      var dimensionInfo = withTempInfo ? reportModel.getTempDimensionInfo(isDim2Instance) : reportModel.getDimensionInfo(isDim2Instance);
      this.currentLabel = dimensionInfo && dimensionInfo.dimension || defaultLabelTxt;
      this.currentLabelText = dimensionInfo && dimensionInfo.text || defaultLabelTxt;
    },
    updateDimensionText: function () {
      this.updateDataBind({
        currentLabelText: this.reportModel.getDimensionInfo(this.isDim2Instance).text
      }, this.el);
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    toggleFooterButtons: function () {
      var $el = this.el;
      var selectedItems = $el.find(".items[checked]:not(.no-display)").length;
      var $apply = $el.find("#apply");
      if (selectedItems > 0) {
        $apply.removeAttr("disabled");
      } else {
        $apply.attr("disabled", true);
      }
    },
    unload: function () {
      this.unloaded = true;
      $document.off(documentEvents);
    },
    serialize: function () {
      var options = this.dimensionOptions || this.reportMeta.get("dimensionOptions");
      var isDim1Instance = this.isDim1Instance;
      var isDim2Instance = this.isDim2Instance;
      var initState = this.dim2InitState;
      var currentLabel = this.currentLabel;
      var selectedDimension = "";
      var items = this.items;
      var len = items && items.length || 0;
      var noItem = len === 0;
      $.each(options, function (i, option) {
        if (isDim1Instance && option.value === currentLabel || isDim2Instance && !initState) {
          selectedDimension = currentLabel;
        }
      });
      return {
        allSelected: this.allChecked,
        allText: this.allText,
        currentLabelText: this.currentLabelText,
        dim1: isDim1Instance,
        dim2: isDim2Instance,
        dimensions: options,
        emptyMsg: noItem ? this.getEmptyMessage() : "No result found",
        initState: initState,
        items: items,
        label: this.label,
        noItem: noItem,
        selectedDimension: selectedDimension
      };
    }
  });
});
