define('modules/targeting/video/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!modules/targeting/video/templates/bin_group_header.html", "text!modules/targeting/video/templates/bin_title.html", "text!modules/targeting/video/templates/bin_group_item.html"], function ($, _, T1, BinsView, bin_group_header, bin_title, bin_group_item) {
  "use strict";

  const VideoTargetingBinsView = BinsView.extend({
    type: "Video",
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      }
    },
    partials: {
      bin_group_name: bin_group_header,
      bin_title: bin_title,
      bin_group_item: bin_group_item
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      onDropEvent: "bins:droppedVideoTargeting",
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    videoCodes: {
      playerSize: "VPSZ",
      linearFormat: "VLIN",
      contentInit: "VCON",
      audio: "VAUD",
      skippability: "VSKP",
      videoPlacement: "VPLC"
    },
    initialize: function ({
      strategy: strategy,
      sharedCollection: sharedCollection,
      targetDimensions: targetDimensions,
      filterConfigs: filterConfigs,
      partialCollection: partialCollection
    }) {
      this.strategy = strategy;
      this.sharedCollection = sharedCollection;
      this.targetDimensions = targetDimensions;
      this.filterConfigs = filterConfigs;
      this.partialCollection = partialCollection;
      this.updateAllTempCollections();
    },
    updateAllTempCollections: function () {
      for (const code in this.videoCodes) {
        if (T1.Utils.hasOwnProp(this.videoCodes, code)) {
          this.updateTempCollection(this.videoCodes[code]);
        }
      }
    },
    updateTempCollection: function (code) {
      const targetDimensions = this.strategy.targetDimensions.get("target_dimensions");
      const videoTargets = targetDimensions.models.filter(model => model.id === code);
      if (!videoTargets.length) {
        return;
      }
      const origColl = videoTargets[0].get("target_value");
      const tempColl = {};
      tempColl.data = [];
      origColl.each(function (model) {
        tempColl.data.push(model.clone());
      });
      origColl.tempBadgesCollection = tempColl;
      origColl.tempBadgesCollection.strategyId = this.strategy.id;
    },
    dataEvents: {
      sharedCollection: {
        "add:target_value": function (model) {
          clearTimeout(this.collAddTargetTimeoutId);
          this.collAddTargetTimeoutId = setTimeout($.proxy(this.updateBins, this, model), 0);
        },
        "remove:target_value": function (model) {
          clearTimeout(this.collRemoveTargetTimeoutId);
          this.collRemoveTargetTimeoutId = setTimeout($.proxy(this.updateBins, this, model, model.get("action")), 0);
        }
      }
    },
    eventhubEvents: {
      updateVideoTargetingBins: "updateBins",
      "video-targeting:updateTempColl": "updateAllTempCollections"
    },
    removeItem: function (event) {
      var $item = $(event.currentTarget).closest(".item");
      var id = $item.data("id");
      var parentId = $item.data("parent-id");
      event.preventDefault();
      event.stopPropagation();
      T1.EventHub.publish("change:formStatus", true);
      return this.sharedCollection.get(parentId).get("target_value").remove(id) && true;
    },
    load: function () {
      this.updateBins();
    },
    updateBins: function () {},
    mapItems: function (items, updateAction) {
      var filterOptions = this.filterConfigs.filterOptions;
      var sharedCollection = this.sharedCollection;
      var partialCollection = this.partialCollection;
      const collection = this.collection;
      var defaultGroupObj = {
        bin_show_group_name: true,
        count: "any",
        showGroupNameOnly: true,
        bin_group_name: "",
        groupItems: []
      };
      var result = [];
      function ensureMaxLength(fullPath) {
        var goalLength = 57;
        return fullPath.length > goalLength ? `...${fullPath.substr(-goalLength)}` : fullPath;
      }
      function formatPath(path, isBolded) {
        var displayed = path.replace(/\|/g, "&raquo");
        if (isBolded) {
          return T1.Utils.boldLastPartOfFullPath({
            fullPath: displayed,
            separator: "&raquo",
            searchTermToBeHighlighted: null
          });
        }
        return displayed;
      }
      $.each(filterOptions, function (index, option) {
        var targetDimension = sharedCollection.get(option.value);
        var group = $.extend(true, {}, defaultGroupObj);
        if (targetDimension) {
          const targetValues = targetDimension.get("target_value");
          targetValues.each(function (model) {
            if (model.get("action") === updateAction) {
              const targetValue = model.toJSON();
              const parentId = model.get("parent_value_id");
              targetValue.parentId = targetDimension.id;
              if (parentId) {
                const parentObj = partialCollection.get(parentId) || sharedCollection.get(parentId) || collection.get(parentId);
                const name = `${parentObj.get("name")} | ${targetValue.name}`;
                targetValue.name = formatPath(ensureMaxLength(name), true);
              } else {
                targetValue.name = targetValue.dimension_code === "VSKP" ? targetValue.name.split(" ")[0] : targetValue.name;
              }
              group.groupItems.push(targetValue);
            }
          });
          if (group.groupItems.length) {
            group.bin_group_name = option.text;
            group.count = "";
            result.push(group);
          }
        }
      });
      return result;
    }
  });
  return VideoTargetingBinsView;
});
