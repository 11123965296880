define('T1Timezones',["jQuery", "T1", "T1Comm", "collections/timezones"], function ($, T1, T1Comm, Timezones) {
  var timeZones;
  var defaultZone = "Etc/GMT";
  var defaultZoneShortName = "UTC";
  var timeConverter = {
    url: "mm",
    action: "convert_time"
  };
  var convertTimeURL = T1.Utils.constructModelUrl(timeConverter, timeConverter.action);
  var deferred = $.Deferred();
  if (T1.Timezones) {
    return T1.Timezones;
  }
  timeZones = new Timezones();
  timeZones.fetchOptions = {
    full: "*"
  };
  timeZones.isPaginated = false;
  timeZones.fetch({
    success: function (args) {
      deferred.resolve({
        getDefaultZoneShortName: function () {
          return defaultZoneShortName;
        },
        getDefaultZoneName: function () {
          return defaultZone;
        },
        getByZoneName: function (name) {
          var timeZone;
          name = name && name !== "" ? name : defaultZone;
          timeZone = timeZones.detect(function (model) {
            return model.get("zone_name") === name;
          });
          return timeZone;
        },
        getByZoneId: function (id) {
          return timeZones.get(id);
        },
        getTimeZones: function (options) {
          var selected;
          var zones;
          selected = options && options.selected ? options.selected : defaultZone;
          zones = timeZones.toJSON();
          zones = $.map(zones, function (zone) {
            if (zone.zone_name === selected) {
              zone.selected = true;
            }
            return zone;
          });
          return zones;
        },
        convertTimeByZone: function (params, callback) {
          var postArgs;
          function success(resp) {
            if (callback && typeof callback === "function") {
              callback(resp);
            }
          }
          function onError() {
            throw Error("convertTimeByZone error");
          }
          if (!params) {
            throw Error("convertTimeByZone method requires params");
          }
          if (typeof params !== "object") {
            throw Error("convertTimeByZone method requires params to be an object");
          }
          if (!params.to_zone || params.to_zone === "") {
            throw Error("convertTimeByZone method requires params to have a 'to_zone' property");
          }
          if (!this.getByZoneName(params.to_zone)) {
            throw Error("convertTimeByZone: 'to_zone' set to invalid zone name");
          }
          postArgs = {
            sourceURL: convertTimeURL,
            data: params,
            onSuccess: success,
            onError: onError,
            fixture: "-convertTime"
          };
          T1Comm.post(postArgs);
          return true;
        },
        validateStartDate: function (buffer, startDate, timeZone, opts) {
          this.convertTimeByZone({
            time: startDate.toString("yyyy-MM-ddTHH:mm:ss"),
            from_zone: timeZone,
            to_zone: "Etc/GMT"
          }, function (obj) {
            var validStart = new Date().add(buffer);
            var startDate;
            var onSuccess = opts && opts.onSuccess || $.noop;
            var onError = opts && opts.onError || $.noop;
            var hasStarted = opts && opts.hasStarted || false;
            var isStartDateNotUTC = opts && opts.isStartDateNotUTC || false;
            if (isStartDateNotUTC) {
              startDate = Date.parse(obj.times.time[1].utc);
            } else {
              startDate = Date.parse(obj.times.time[1].utc).setTimezone("UTC");
            }
            Date.compare(startDate, validStart) === 1 || hasStarted ? onSuccess() : onError();
          });
        }
      });
    }
  });
  T1.Timezones = deferred.promise();
  return T1.Timezones;
});
