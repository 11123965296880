define('modules/strategy/views/strategySearchBox',["jQuery", "T1", "text!modules/strategy/templates/searchBoxGrid.html", "modules/shared/views/searchBox", "collections/searchResults", "collections/performanceDataForCollections"], function ($, T1, searchItemTemplate, SearchBox, SearchResults, PerformanceData) {
  "use strict";

  var currentStrategyId;
  const rptIntervalToUse = "1";
  var searchItemClicked = function (event) {
    T1.Location.set(`#strategy/summary/${event.id}`);
  };
  var searchResultFormatter = function (models) {
    var output = [];
    var getYDYReport = function (model) {
      let reportYDY;
      var reports = model.get("rpt_goal_monitoring") || [];
      if (reports && reports.models) {
        reports = reports.toJSON();
      }
      function getYDYData(report) {
        if (report.mm_interval === rptIntervalToUse) {
          reportYDY = report;
        }
      }
      if ($.isArray(reports)) {
        $.each(reports, function (index, report) {
          if (reportYDY) {
            return false;
          }
          getYDYData(report);
        });
      } else if (reports) {
        getYDYData(reports);
      }
      return reportYDY;
    };
    var getSpendYDY = function (model) {
      var report = getYDYReport(model);
      return report ? report.total_spend : "";
    };
    $.each(models, function (index, model) {
      output.push({
        name: model.get("name"),
        isActive: model.get("status") === "1",
        ydy: getSpendYDY(model),
        id: model.id,
        isCurrent: currentStrategyId === model.id
      });
    });
    return output;
  };
  var searchBoxOptions = {
    customPlaceholderText: "Search Strategies",
    searchResultHeaders: [{
      name: "strategy-name",
      label: "Strategy"
    }, {
      name: "spend-ydy",
      label: "Spend YDY"
    }],
    searchBoxItemTemplate: searchItemTemplate,
    searchResultHandler: searchItemClicked,
    searchResultFormatter: searchResultFormatter,
    fetchOptions: {
      full: "*",
      with: ["rpt_goal_monitoring"],
      sort_by: "-status,name"
    },
    filterConfiguration: {
      filters: [{
        entity: "campaign",
        fetchOnChange: true
      }]
    },
    pageLimit: 20,
    urlString: "strategies"
  };
  const StrategySearchBox = SearchBox.extend({
    initialize: function (args) {
      this.strategyModel = args.model;
      currentStrategyId = this.strategyModel.id;
      this.campaignModel = this.strategyModel.get("campaign");
      const localArgs = $.extend(true, {}, args || {});
      localArgs.searchOptions = searchBoxOptions;
      localArgs.urlFilterValue = {
        entity: "campaign",
        id: this.campaignModel.id
      };
      localArgs.selectorPath = ".w-InfiniteScrollSearchBox";
      localArgs.searchCollection = this.buildCustomSearchCollection(searchBoxOptions);
      SearchBox.prototype.initialize.call(this, localArgs);
    },
    buildCustomSearchCollection: function (options) {
      var collectionObj = new SearchResults(null, options);
      collectionObj.name = "strategies";
      collectionObj.report_interval = rptIntervalToUse;
      collectionObj.cacheDuration = 100;
      collectionObj.fetch = function (opts = {}) {
        opts.singleRequestOnly = true;
        const perfData = new PerformanceData({
          hostCollection: collectionObj
        });
        perfData.hostCollectionFetchHandler(opts);
      };
      return collectionObj;
    }
  });
  return StrategySearchBox;
});
