define('modules/campaign/views/chartChangeLog',['require','Underscore','jQuery','modules/changelog/utils/mapper','collections/changeLogs','moment-timezone','T1','T1View','text!../templates/chart_change_log.html','When'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const ChangeLogMapper = require("modules/changelog/utils/mapper");
  const ChangeLogs = require("collections/changeLogs");
  const moment = require("moment-timezone");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/chart_change_log.html");
  const When = require("When");
  const classNoDisplay = "no-display";
  const classHidden = "hidden";
  const dateFormatMain = "ddd, YYYY-MM-DD";
  const dateFormatMarker = "YYYY-MM-DD";
  const dateFormatTimestamp = "YYYY-MM-DD, HH:mm:ss";
  const debounceDelay = 500;
  return T1View.extend({
    keepLogOpen: false,
    template: template,
    events: {
      "changed .search-input": "searchInputHandler",
      "click .btn-toggle": "btnToggleHandler",
      "click .cycle-arrow": "cycleDatesHandler",
      "click .list": "toggleMetadataHandler"
    },
    eventhubEvents: {
      "mmChart.selectMarker": "toggleChangeLogInfo"
    },
    initialize({
      toggleLog: toggleLog,
      getRequestInfo: getRequestInfo,
      getEntityInfo: getEntityInfo,
      getTimeZoneName: getTimeZoneName,
      setupMarkers: setupMarkers,
      selectMarker: selectMarker
    }) {
      this.toggleLog = toggleLog;
      this.getRequestInfo = getRequestInfo;
      this.getEntityInfo = getEntityInfo;
      this.getTimeZoneName = getTimeZoneName;
      this.setupMarkers = setupMarkers;
      this.selectMarker = selectMarker;
    },
    load() {
      const restoreOpenView = this.keepLogOpen;
      if (restoreOpenView) {
        this.showData = false;
      }
      this.render().then(() => {
        this.assignElements();
        if (restoreOpenView) {
          this.openLog();
        }
      });
    },
    unload() {
      this.$btnToggle = null;
      this.$emptyMsg = null;
      this.$loader = null;
      this.$log = null;
      this.timezoneName = null;
      this.mainDate = null;
      this.abortLoad();
    },
    abortLoad() {
      if (this.changeLogColl) {
        if (this.changeLogColl.currentFetch) {
          this.changeLogColl.currentFetch.abort();
        }
        this.changeLogColl.reset();
        this.changeLogColl = null;
      }
      this.displayData = null;
      this.parsedData = null;
      this.searchData = null;
      this.showData = null;
    },
    assignElements() {
      this.$btnToggle = this.$(".btn-toggle .btn-txt");
      this.$emptyMsg = this.$(".empty-msg");
      this.$loader = this.$(".change-loader");
      this.$log = this.$(".log");
    },
    btnToggleHandler() {
      if (this.$btnToggle.text() === "Hide") {
        this.closeLog();
      } else {
        this.openLog();
      }
    },
    openLog() {
      this.toggleLog("open");
      this.keepLogOpen = true;
      this.$btnToggle.text("Hide");
      this.$log.removeClass(classNoDisplay);
      this.loadInfo();
    },
    closeLog() {
      this.toggleLog("close");
      this.keepLogOpen = false;
      this.abortLoad();
      this.renderPartial(".log");
      this.assignElements();
      this.$btnToggle.text("Show");
      this.$log.addClass(classNoDisplay);
    },
    convertUTCtoTZ(utcDate, format) {
      return moment.utc(utcDate).tz(this.timezoneName).format(format);
    },
    loadInfo() {
      const entityInfo = this.getEntityInfo();
      const fetchProps = {
        deferred: When.defer(),
        endDate: entityInfo.getEndDate(),
        entityID: entityInfo.id,
        entityName: entityInfo.name,
        entityType: entityInfo.type,
        startDate: entityInfo.getStartDate()
      };
      this.toggleLoader("show");
      this.loadChangeLogs(fetchProps).then(() => {
        this.parseInfo();
      });
    },
    loadChangeLogs({
      deferred: deferred,
      entityID: entityID,
      startDate: startDate,
      endDate: endDate,
      entityType: entityType,
      entityName: entityName
    }) {
      const types = entityType === "campaign" ? "campaigns" : "strategies";
      const changeLogColl = this.changeLogColl = new ChangeLogs([], {
        entity: types,
        id: entityID
      });
      changeLogColl.type = entityType;
      changeLogColl.id = entityID;
      changeLogColl.name = entityName;
      changeLogColl.fetchOptions = {
        q: `created_on>=${startDate}&created_on<=${endDate} 23:59:59`
      };
      if (entityType === "campaign") {
        changeLogColl.fetchOptions.with = "strategies";
      }
      changeLogColl.fetchAllPages({
        pageLimit: 100,
        silent: true,
        wrapper: "log_entries",
        success: function () {
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    parseInfo() {
      const changeLogColl = this.changeLogColl;
      const changeLogs = [changeLogColl];
      const withStrategies = changeLogColl.strategies;
      this.timezoneName = this.getTimeZoneName();
      this.parsedData = null;
      if (withStrategies && withStrategies.length) {
        _.each(withStrategies, function (collection) {
          collection.type = "strategy";
          changeLogs.push(collection);
        });
      }
      this.stripNonEssentialChanges(changeLogs);
      if (changeLogs.length) {
        this.parseData(changeLogs);
        this.generateMarkerDates();
      }
      this.toggleLoader("hide");
      this.renderPartial(".log");
      this.assignElements();
    },
    stripNonEssentialChanges(changeLogs) {
      changeLogs.forEach(function (collection) {
        const validModels = collection.models.filter(function (model) {
          const attr = model.attributes;
          return attr.action === "update" && attr.name && attr.name !== "name";
        });
        collection.reset(validModels);
      });
      changeLogs = changeLogs.filter(collection => collection.length);
    },
    parseData(changeLogs) {
      const self = this;
      const endDate = this.getEntityInfo().getEndDate();
      const gridDataGroup = {};
      const parsedData = {};
      _.each(changeLogs, function (collection) {
        const mappedLogs = ChangeLogMapper(collection.type, collection, null);
        const gridData = [];
        _.each(mappedLogs.list, function (item) {
          const field = item.name;
          const oldValue = item.old_value;
          const newValue = item.new_value;
          const isSimplified = item.name === "My Data Targeting";
          const title = isSimplified ? `${field} changed` : `${field} changed from ${oldValue} to ${newValue}`;
          gridData.push({
            date: self.convertUTCtoTZ(item.date, dateFormatMarker),
            field: field,
            from: oldValue,
            id: collection.id,
            isDataRow: !isSimplified,
            isSimplifiedRow: isSimplified,
            name: collection.name,
            timestamp: self.convertUTCtoTZ(item.date, dateFormatTimestamp),
            title: title,
            to: newValue,
            type: collection.type,
            user: `by ${item.user_name}`
          });
          gridDataGroup[`item_${collection.id}`] = gridData;
        });
      });
      _.each(gridDataGroup, function (groupItem, key) {
        parsedData[key] = _.chain(groupItem).reject(function (item) {
          return moment(item.date).isAfter(endDate);
        }).groupBy("date").mapObject(function (changesPerDate) {
          return _.groupBy(changesPerDate, "type");
        }).value();
        if (_.isEmpty(parsedData[key])) {
          delete parsedData[key];
        }
      });
      this.parsedData = _.isEmpty(parsedData) ? null : parsedData;
    },
    generateMarkerDates() {
      const dates = [];
      _.each(this.parsedData, function (item) {
        dates.push(_.keys(item));
      });
      const markerDates = _.chain(dates).flatten().uniq().sortBy().value();
      if (markerDates.length) {
        this.markerDates = markerDates;
        this.setupMarkers(markerDates);
      }
    },
    toggleChangeLogInfo(date) {
      let lastItemID;
      if (date) {
        let displayDataAll = [];
        _.each(this.parsedData, function (itemData) {
          let displayData = [];
          _.each(itemData[date], function (value) {
            if (lastItemID !== value[0].id) {
              displayData.push({
                entityName: value[0].name,
                isEntityRow: true
              });
              lastItemID = value[0].id;
            }
            displayData = _.union(displayData, value);
          });
          displayDataAll = displayDataAll.concat(displayData);
        });
        this.displayData = displayDataAll;
        this.mainDate = moment(date, dateFormatMarker).format(dateFormatMain);
        this.showData = true;
      } else {
        this.showData = false;
      }
      this.searchData = [];
      this.renderPartial(".log");
      this.assignElements();
    },
    cycleDatesHandler(event) {
      const leftClick = $(event.currentTarget).hasClass("flip");
      const availableDates = this.markerDates;
      const currentDate = moment(this.mainDate, dateFormatMain).format(dateFormatMarker);
      const currentIndex = availableDates.indexOf(currentDate);
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : availableDates.length - 1;
      const nextIndex = currentIndex < availableDates.length - 1 ? currentIndex + 1 : 0;
      const selectedDate = leftClick ? availableDates[prevIndex] : availableDates[nextIndex];
      event.stopPropagation();
      this.toggleChangeLogInfo(selectedDate);
      this.selectMarker(selectedDate);
    },
    toggleMetadataHandler(event) {
      const $targetRow = $(event.target).closest(".list-item");
      const $btnMetadataToggle = $targetRow.find(".metadata-toggle");
      const $metadata = $targetRow.find(".metadata");
      $btnMetadataToggle.toggleClass("expanded");
      $metadata.toggleClass(classNoDisplay);
    },
    searchInputHandler: _.debounce(function (event) {
      const evtValue = event.currentTarget.value;
      let searchData = [];
      if (evtValue) {
        const sortedArr = [];
        let prevWasEntityRow = false;
        const searchTerm = evtValue.toLowerCase();
        const noResultsFoundRow = {
          isEmptyRow: true
        };
        _.each(this.displayData, function (item) {
          if (item.isEntityRow) {
            searchData.push(item);
          } else {
            _.each(item, function (value, key) {
              var highlightedItem;
              if (key === "field" || key === "from" || key === "to" || key === "user") {
                if (value.toLowerCase().includes(searchTerm)) {
                  highlightedItem = $.extend(true, {}, item);
                  highlightedItem[key] = T1.Utils.highlightText(value, searchTerm);
                  searchData.push(highlightedItem);
                }
              }
            });
          }
        });
        _.each(searchData, function (item) {
          if (item.isEntityRow) {
            if (prevWasEntityRow) {
              sortedArr.push(noResultsFoundRow);
            }
            sortedArr.push(item);
            prevWasEntityRow = true;
          } else {
            sortedArr.push(item);
            prevWasEntityRow = false;
          }
        });
        if (searchData[searchData.length - 1].isEntityRow) {
          sortedArr.push(noResultsFoundRow);
        }
        searchData = sortedArr;
      }
      this.searchData = searchData;
      this.renderPartial(".list");
      this.assignElements();
    }, debounceDelay),
    toggleLoader(state) {
      if (state === "show") {
        this.$emptyMsg.addClass(classHidden);
        this.$loader.removeClass(classNoDisplay);
      } else {
        this.$loader.addClass(classNoDisplay);
        this.$emptyMsg.removeClass(classHidden);
      }
    },
    serialize() {
      const searchData = this.searchData;
      const inSearchMode = Boolean(searchData && searchData.length);
      return {
        dataAvailable: Boolean(this.parsedData),
        inSearchMode: inSearchMode,
        items: inSearchMode ? searchData : this.displayData,
        mainDate: this.mainDate,
        showData: this.showData
      };
    }
  });
});
