define('modules/creatives/componentBased/control/formatters',["moment"], function (moment) {
  "use strict";

  var identity = function (val) {
    return val;
  };
  var yesNo = function (val) {
    return val === "1" ? "Yes" : "No";
  };
  var formatDate = function (val, showTime) {
    var dateFormat = "YYYY-MM-DD";
    if (showTime) {
      dateFormat += " HH:mm";
    }
    if (val) {
      return moment(val).format(dateFormat);
    }
    return null;
  };
  var specialFormatters = {
    is_https: yesNo,
    standard_date: formatDate
  };
  return function chooseFormatter(attr) {
    var specialFormatter = specialFormatters[attr];
    return specialFormatter || identity;
  };
});
