define('modules/creatives/display/views/previewT1AS',["jQuery", "Underscore", "When", "T1", "T1View", "models/atomicCreative", "models/advertiser", "models/hybridAd", "text!../templates/previewT1AS.html"], function ($, _, When, T1, T1View, AtomicCreative, Advertiser, HybridAd, template) {
  var PreviewT1ASView = T1View.extend({
    template: template,
    events: {
      "click .modalClose": "closeDialog"
    },
    loaderOpts: {
      target: ".notify",
      text: "loading...",
      contentClass: "search-loader"
    },
    initialize: function (args) {
      var self = this;
      this.role = args.role;
      this.model = args.model;
      this.hybridAdState = args.hybridAdState || "";
      self.load();
    },
    fetchAdvertiser: function (model) {
      var self = this;
      var advertiser = model.get("advertiser") || new Advertiser({
        id: model.get("advertiser_id")
      });
      model.set({
        advertiser: advertiser
      });
      if (advertiser.get("agency")) {
        $("#agencyAndAdvertiser").html(self.getAgencyAndAdvertiserName());
      } else {
        advertiser.fetch({
          params: {
            with: ["agency"]
          },
          success: function () {
            $("#agencyAndAdvertiser").html(self.getAgencyAndAdvertiserName());
          }
        });
      }
    },
    getAgencyAndAdvertiserName: function () {
      var advertiser = this.model.get("advertiser");
      var advertiserName = advertiser.get("name");
      var agency = advertiser.get("agency");
      var agencyName = agency.get("name");
      var agencyDisplay = agencyName + "&nbsp;&raquo;&nbsp;" + advertiserName;
      return agencyDisplay;
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      var $dialog;
      if (!$el.hasClass("previewDialog")) {
        $el.addClass("previewDialog");
      }
      self.render().then(function () {
        $dialog = $el.dialog({
          closeText: "",
          autoOpen: true,
          modal: false,
          resize: "auto",
          open: function () {
            $(this).closest(".ui-dialog").css("overflow", "visible");
          },
          close: function () {
            self.closeDialog.call(self);
          }
        });
        if (self.hybridAdState) {
          self.refreshHybrid = document.getElementById("refreshHybrid");
          self.refreshHybrid.addEventListener("click", $.proxy(self.setHybridIframes, self));
          self.approveHybrid = document.getElementById("approveHybrid");
          self.approveHybrid.addEventListener("click", $.proxy(self.saveReview, self));
          self.rejectHybrid = document.getElementById("rejectHybrid");
          self.rejectHybrid.addEventListener("click", $.proxy(self.saveReview, self));
          self.setHybridIframes();
          setTimeout(function () {
            self.toggleButtons(false);
          }, 500);
          $el.find(".ad-status").addClass("ff-ajdust");
        }
        if (self.model.get("file_type") === "html5") {
          self.setHTML5Iframes();
        }
        self.fetchAdvertiser(self.model);
      });
    },
    setHTML5Iframes: function () {
      var self = this;
      var $html5frame = $("#html5Frame");
      $html5frame.ready(function () {
        var frame = document.getElementById("html5Frame");
        if (frame) {
          var doc = frame.contentWindow.document;
          doc.open();
          doc.write(self.model.get("tag"));
          doc.close();
        }
      });
    },
    setHybridIframes: function (e) {
      if (e) {
        e.preventDefault();
      }
      var self = this;
      var $flashframe = $("#flashFrame");
      $flashframe.ready(function () {
        var doc = document.getElementById("flashFrame").contentWindow.document;
        doc.open();
        doc.write('<script type="text/javascript" src="' + self.sizmekHybridURL + '"><\/script>');
        doc.close();
      });
      var $html5frame = $("#sizmekHtml5Frame");
      $html5frame.ready(function () {
        var doc = document.getElementById("sizmekHtml5Frame").contentWindow.document;
        doc.open();
        doc.write('<script>var ebUseHtml5 = 1;<\/script><script type="text/javascript" src="' + self.sizmekHybridURL + '"><\/script>');
        doc.close();
      });
    },
    toggleButtons: function (isLoading) {
      this.approveHybrid.removeAttribute("disabled");
      this.rejectHybrid.removeAttribute("disabled");
      if (!isLoading) {
        if (this.hybridAdState === "approved" || this.hybridAdState === "Approved for Use") {
          this.approveHybrid.disabled = "true";
        } else if (this.hybridAdState === "rejected" || this.hybridAdState === "Rejected for Use") {
          this.rejectHybrid.disabled = "true";
        }
      } else {
        this.approveHybrid.disabled = "true";
        this.rejectHybrid.disabled = "true";
      }
    },
    saveReview: function (e) {
      if (e) {
        e.preventDefault();
      }
      var self = this;
      var $target = $(e.target);
      var targetID = $target[0].id;
      var outputModel = new HybridAd();
      var primaryAsset = this.model.get("creative").get("creative_asset").getAsset("primary");
      var fileStoragePath = primaryAsset.get("file_storage_path");
      var loader = this.loader();
      var outputData = {
        primary_asset_file_storage_path: fileStoragePath
      };
      var options = {
        success: function () {
          loader.stop();
          $("#notify").removeClass();
          self.toggleButtons(false);
          T1.Notify("message", "1 Creative successfully updated");
          self.closeDialog();
          T1.EventHub.publish("hybridAdReview:" + self.outputAction, self.model.id);
        },
        statusInvalid: function () {
          loader.stop();
          $("#notify").addClass("center");
          self.toggleButtons(false);
          $(".notify").html('<strand-inline-box unresolved type="error">There was an error. Please try again.</strand-inline-box>');
        }
      };
      this.toggleButtons(true);
      $("#notify").addClass("notify");
      loader.start();
      if (targetID === "approveHybrid") {
        this.outputAction = "approve";
      } else if (targetID === "rejectHybrid") {
        this.outputAction = "reject";
      }
      outputModel.url = "atomic_creatives/" + this.model.id + "/hybrid_ad/" + this.outputAction;
      outputModel.save(outputData, options);
    },
    closeDialog: function (e) {
      var $el = $(this.el);
      if (e) {
        e.preventDefault();
        $el.dialog("close");
      }
      $el.dialog("destroy");
      $el.remove();
    },
    toggleLoadingState: function (isLoading, isError) {
      var self = this;
      var loader = this.loader();
      var $el = $(self.el);
      if (isLoading) {
        loader.start();
        $el.find(".w-head, .form-body").css({
          opacity: .05
        }).disableSelection();
        $el.find(".form-body :input").attr("disabled", true);
        $el.find(".ui-submit").addClass("disabled");
      } else {
        loader.stop();
        if (isError) {
          $el.find(".w-head, .form-body").css({
            opacity: 1
          }).enableSelection();
          $el.find(".form-body :input").attr("disabled", false);
          $el.find(".ui-submit").removeClass("disabled");
        }
      }
    },
    getTargetAsset: function (model, role) {
      var creativeAsset = model.get("creative").get("creative_asset").getAsset(role);
      return creativeAsset.toJSON();
    },
    removeProtocol: function (url) {
      var newURL = url.substr(url.indexOf("://") + 3);
      return newURL;
    },
    serialize: function () {
      var self = this;
      var model = this.model;
      var modelJSON = model.toJSON();
      var assetURL;
      var ssl;
      var protocol = document.location.protocol;
      if (protocol.indexOf("https") > -1) {
        ssl = true;
      }
      var creativeAsset = this.role === "html5" ? this.getTargetAsset(model, "primary") : this.getTargetAsset(model, this.role);
      var creativeFileSize = creativeAsset.file_size ? Math.round(parseInt(creativeAsset.file_size) / 1e3) + "kb" : "N/A";
      var hybridFileSize = model.get("mm_hybrid_child_ad_size") ? Math.round(parseInt(model.get("mm_hybrid_child_ad_size"))) + "kb" : "N/A";
      var hybridID = model.get("mm_hybrid_child_ad_id");
      var is3PAS = modelJSON.t1as === "0";
      var iWidth = Number(modelJSON.width) + 8;
      var iHeight = Number(modelJSON.height) + 8;
      var isSWF = creativeAsset.file_type && creativeAsset.file_type === "swf" ? true : false;
      var isHTML5 = creativeAsset.file_type && creativeAsset.file_type === "html5" ? true : false;
      var titlePrefix;
      var isSizmekHTML5 = this.hybridAdState ? true : false;
      if (self.role === "primary") {
        titlePrefix = "Main Asset";
      } else if (self.role === "html5") {
        titlePrefix = "HTML5 Backup";
      } else {
        titlePrefix = T1.Utils.capitalize(self.role) + " Asset";
      }
      this.sizmekHybridURL = protocol + "//bs.preview.serving-sys.com/BurstingPipe/adServer.bs?cn=rsb&c=28&pli=&PluID=0&pr=1&ai=" + hybridID + "&w=" + modelJSON.width + "&h=" + modelJSON.height + '&ord="';
      var sizmekBackupURL = model.get("mm_backup_asset_url");
      var sizmekPrimaryURL = model.get("mm_primary_asset_url") ? model.get("mm_primary_asset_url") : sizmekBackupURL;
      if (model.get("mm_hybrid_ad_state")) {
        if (this.role === "primary") {
          assetURL = this.removeProtocol(sizmekPrimaryURL);
        } else if (this.role === "backup") {
          assetURL = this.removeProtocol(sizmekBackupURL);
        }
        if (ssl && this.role !== "html5") {
          assetURL = assetURL.replace("ds.serving", "secure-ds.serving");
        }
      } else {
        assetURL = creativeAsset.asset_url;
      }
      var obj = {
        previewTitle: titlePrefix + " Preview",
        iWidth: iWidth,
        iHeight: iHeight,
        isSWF: isSWF && !isHTML5 && !isSizmekHTML5,
        isHTML5: isHTML5,
        isSizmekHTML5: isSizmekHTML5,
        isImg: !isSWF && !isHTML5 && !isSizmekHTML5,
        tpasID: is3PAS ? modelJSON.external_identifier : "None - T1AS",
        fileSize: isSizmekHTML5 ? hybridFileSize : creativeFileSize,
        renderedURL: isSizmekHTML5 ? "" : protocol + "//" + assetURL,
        hybridAdState: this.hybridAdState
      };
      var returnObj = $.extend(obj, modelJSON);
      returnObj.name = model.get("name");
      returnObj.width = creativeAsset.width;
      returnObj.height = creativeAsset.height;
      return returnObj;
    }
  });
  return $.extend(PreviewT1ASView, {
    showDialog: function (originalConfigs) {
      var configs = {
        el: originalConfigs.el || $("<div></div>")
      };
      var previewDialog;
      configs = $.extend({}, originalConfigs, configs);
      previewDialog = new PreviewT1ASView(configs);
      return previewDialog;
    }
  });
});
