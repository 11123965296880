define('modules/strategy/views/targeting',["T1", "T1View", "T1Permissions", "T1TabLayoutV2", "text!modules/strategy/templates/targeting.html", "utils/CampaignStrategyUtils"], function (T1, T1View, T1Permissions, T1TabLayout, template, CampaignStrategyUtils) {
  "use strict";

  var TargetingView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".strategy-targeting-loader",
      text: "loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "strategyTargetingLoader:stop": function () {
        this.loader.stop();
        if (window.hybridWorkflowParams.isHybrid) {
          CampaignStrategyUtils.updateCancelBtn(this.model.get("campaign"));
        }
      },
      "strategyTargetingLoader:start": function () {
        this.loader.start();
      }
    },
    initialize: function () {
      var strategy = this.model;
      this.canViewVideoStrategy = T1Permissions.check("feature", "video", strategy);
      this.isFacebookStrategy = strategy.get("supply_type").toUpperCase() === "BATCH";
      let tabsList = [{
        label: "Audience",
        module: "targeting/audience",
        viewType: "panel",
        routeName: "audience",
        options: {
          strategy: strategy
        }
      }, {
        label: "Contextual",
        module: "targeting/contextual",
        viewType: "panel",
        routeName: "contextual",
        options: {
          strategy: strategy
        }
      }, {
        label: "My Data",
        module: "targeting/pixel",
        viewType: "panel",
        routeName: "myData",
        options: {
          strategy: strategy
        }
      }, {
        label: "Site List",
        module: "targeting/siteList",
        viewType: "panel",
        routeName: "siteList",
        options: {
          strategy: strategy
        }
      }, {
        label: "Location",
        module: "targeting/location",
        viewType: "panel",
        routeName: "location",
        options: {
          strategy: strategy
        }
      }, {
        label: "Day Part",
        module: "targeting/dayPart",
        viewType: "list",
        routeName: "dayPart",
        options: {
          strategy: strategy
        }
      }, {
        label: "Technology",
        module: "targeting/digital",
        routeName: "technology",
        viewType: "panel",
        options: {
          strategy: strategy
        }
      }];
      if (this.isFacebookStrategy) {
        const baseFilterTabs = function ({
          label: label
        }) {
          return label !== "Contextual" && label !== "Site List" && label !== "Day Part";
        };
        let filterTabs = obj => baseFilterTabs(obj);
        if (T1Permissions.check("feature", "batch_supply_fb_video")) {
          filterTabs = obj => baseFilterTabs(obj) && obj.label !== "Video";
        }
        tabsList = tabsList.filter(filterTabs);
      } else if (this.canViewVideoStrategy) {
        tabsList.push({
          label: "Video/Audio",
          module: "targeting/video",
          routeName: "video",
          viewType: "panel",
          options: {
            strategy: strategy
          }
        });
      }
      this.layout = new T1TabLayout({
        tabSetName: "StrategyTargeting",
        level: 2,
        tabs: tabsList
      });
    },
    load: function () {
      var strategyId = this.model.get("id");
      if (strategyId) {
        T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      }
      this.render().then(() => {
        if (this.loader.start) {
          this.loader.start();
        } else {
          this.loader = this.loader();
          this.loader.start();
        }
        this.layout.el = this.$(".targeting-tabs");
        this.layout.load();
      });
    },
    canUnload: function (...args) {
      return this.layout.canUnload(...args);
    }
  });
  return TargetingView;
});
