define('T1GridView',["jQuery", "Underscore", "T1", "T1View", "text!templates/grid.html", "text!templates/grid_header.html", "text!templates/grid_subItem.html", "text!templates/grid_footer.html", "text!templates/drag_drop_helper.html", "Mustache", "T1Collection", "T1Tooltip", "jQueryUI", "jQPlugins/jquery.ba-resize", "jQPlugins/jquery.trunk8", "jQPlugins/perfect-scrollbar"], function ($, _, T1, T1View, gridTemplate, gridHeaderTemplate, gridSubItemTemplate, gridFooterTemplate, DragDropHelper, T, T1Collection) {
  "use strict";

  function addInfiniteScrolling(view) {
    var scrCfg = view.prototype.scrollConfigs;
    var runOriginalLoad = view.prototype.load;
    var runOriginalUnload = view.prototype.unload;
    view.prototype.scrollInfinitely = function () {
      var self = this;
      var coll = this.collection;
      var canScroll = coll.canFetchMore();
      var scrollConfigs = this.scrollConfigs;
      var loader = this.infiniteLoader || this.loader(scrollConfigs.loaderOpts || {
        target: ".content-holder",
        text: "loading",
        contentClass: "search-loader",
        action: "append"
      });
      var editData = scrollConfigs.editData || function (data) {
        return data;
      };
      if (canScroll) {
        this.infiniteLoader = loader;
        this.isFetching = true;
        loader.start();
        this.updateScrollBar();
        this.initCount = coll.length;
        coll.isFetching = this.isFetching;
        coll.trigger("fetchMore", {
          isFetching: self.isFetching
        });
        coll.infiniteScrolling = true;
        coll.refreshOnNextFetch();
        coll.fetchMore({
          success: function () {
            var newColl = new T1Collection(coll.models.slice(self.initCount));
            var newData = self.serialize(newColl);
            newData = editData.call(self, newData);
            loader.stop();
            self.render(newData, {
              action: scrollConfigs.renderAction || "append"
            }).then(function () {
              self.updateScrollBar();
              if (self.scrollContentRendered) {
                self.scrollContentRendered();
              }
            });
            self.isFetching = false;
            coll.infiniteScrolling = false;
            coll.trigger("fetchMoreComplete", {
              isFetching: self.isFetching
            });
            self.renderPartial(".w-foot", newData);
          }
        });
      }
    };
    view.prototype.checkScroll = function (e) {
      var scrollTop = e.target.scrollTop;
      var offsetHeight = e.target.offsetHeight;
      var scrollHeight = e.target.scrollHeight;
      var trigger = scrCfg.triggerPoint || 0;
      var inRange = trigger + scrollTop + offsetHeight > scrollHeight;
      this.preventDefault(e);
      if (!this.isFetching && inRange) {
        this.scrollInfinitely();
      }
    };
    view.prototype.load = function () {
      var self = this;
      var $scrollTarget;
      function scrollHandler(e) {
        self.checkScroll(e);
      }
      runOriginalLoad.apply(self);
      $scrollTarget = $(self.el).find(scrCfg.target);
      if ($scrollTarget.length > 0) {
        $scrollTarget.off("scroll", scrollHandler);
        $scrollTarget.on("scroll", scrollHandler);
      }
    };
    view.prototype.unload = function () {
      runOriginalUnload.apply(this);
      this.infiniteLoader = undefined;
      $(this.el).find(scrCfg.target).off("scroll");
    };
    return view;
  }
  function addDraggable(view) {
    var runOriginalRender = view.prototype.render;
    view.prototype.events = $.extend({}, view.prototype.events, {
      "click .drag": "selectDragItem"
    });
    view.prototype.onDragableItemClick = view.prototype.events["click .item"] || "";
    view.prototype.events["click .item"] = "draggableItemClick";
    view.prototype.draggableItemClick = function (event) {
      this.removeDragClasses($(event.currentTarget).closest(".item-wrapper"));
      if (this.onDragableItemClick !== "") {
        this[this.onDragableItemClick](event);
      }
    };
    view.prototype.showDragDropHelper = function (data) {
      var $helper = $(T.to_html(this.partials.dragDropHelper, data || {}));
      return $helper;
    };
    view.prototype.removeDragClasses = function ($scope) {
      var target = $scope ? $scope.find(".item-wrapper") : $(this.el).find(".content-c .item-wrapper");
      target.removeClass("drag-selected last-clicked last-shift-clicked");
    };
    view.prototype.selectDragItem = function (event) {
      var selectedClass = "selected";
      var shiftSelectedClass = "shift-selected";
      var dragSelectedClass = "drag-selected";
      var lastClickedClass = "last-clicked";
      var lastShiftClickedClass = "last-shift-clicked";
      var itemEl = $(event.currentTarget).closest(".item");
      var currentTarget = itemEl.closest(".item-wrapper");
      var closestChildrenEl = currentTarget.closest(".children");
      var isChildrenNode = closestChildrenEl.length;
      var contentContainer = $(this.el).find(".content-c");
      var parent = isChildrenNode ? closestChildrenEl : contentContainer;
      var children = parent.children(".item-wrapper").not(parent.children(".item-wrapper").has(parent.find('[data-ignore-drag-select="true"]'))).not(parent.find(".children .item-wrapper"));
      var myIndex = children.index(currentTarget);
      var previousLastClickedIndex = children.index(parent.find("." + lastClickedClass));
      var shiftRootIndex = children.index(parent.find("." + lastShiftClickedClass));
      var lowerBound, upperBound, lastShiftEl, lastShiftElChildrenItem, currentTargetTop;
      var draggableConfigs = this.draggableConfigs;
      if (itemEl.data("ignore-drag-select") === true) {
        return;
      }
      this.preventDefault(event);
      if (draggableConfigs.allowMultipleSelection) {
        if (event.shiftKey === true) {
          if (isChildrenNode && closestChildrenEl.closest(".item-wrapper").hasClass(dragSelectedClass)) {
            return;
          }
          lastShiftEl = contentContainer.find("." + lastShiftClickedClass);
          lastShiftElChildrenItem = lastShiftEl.nextAll().not(contentContainer.find('[data-ignore-drag-select="true"]'));
          currentTargetTop = currentTarget.offset().top;
          if (lastShiftEl.length && lastShiftElChildrenItem.length && lastShiftEl.offset().top < currentTargetTop && shiftRootIndex === -1) {
            lastShiftElChildrenItem.each(function (index, element) {
              var $el = $(element);
              if ($el.offset().top < currentTargetTop) {
                $el.addClass(shiftSelectedClass + " " + dragSelectedClass);
              }
            });
            $(lastShiftElChildrenItem[lastShiftElChildrenItem.length - 1]).addClass(dragSelectedClass + " " + lastClickedClass);
            return;
          }
          if (shiftRootIndex === -1) {
            this.removeDragClasses();
            if (previousLastClickedIndex !== -1) {
              shiftRootIndex = previousLastClickedIndex;
              $(children[shiftRootIndex]).addClass(lastShiftClickedClass);
            } else if (previousLastClickedIndex === -1) {
              currentTarget.addClass(lastShiftClickedClass);
            }
          }
          if (shiftRootIndex > -1 && shiftRootIndex !== myIndex) {
            lowerBound = shiftRootIndex < myIndex ? shiftRootIndex : myIndex;
            upperBound = shiftRootIndex > myIndex ? shiftRootIndex + 1 : myIndex;
            parent.children("." + shiftSelectedClass).removeClass(shiftSelectedClass + " " + dragSelectedClass);
            children.slice(lowerBound, upperBound).addClass(shiftSelectedClass + " " + dragSelectedClass);
            if (currentTarget.hasClass(shiftSelectedClass) === false) {
              currentTarget.addClass(shiftSelectedClass + " " + dragSelectedClass);
            }
          }
          currentTarget.addClass(dragSelectedClass + " " + lastClickedClass);
        } else {
          if (event.metaKey === false && !(navigator.platform.toUpperCase().indexOf("WIN") > -1 && event.ctrlKey)) {
            this.removeDragClasses();
          }
          parent.children("." + shiftSelectedClass + " ." + dragSelectedClass).removeClass(shiftSelectedClass);
          parent.children("." + lastClickedClass).removeClass(lastClickedClass);
          parent.children("." + lastShiftClickedClass).removeClass(lastShiftClickedClass);
          currentTarget.toggleClass(dragSelectedClass).addClass(lastClickedClass);
        }
      } else {
        parent.find("." + selectedClass).removeClass(selectedClass);
        currentTarget.addClass(selectedClass);
      }
    };
    view.prototype.applyDraggable = function () {
      var self = this;
      var $el = $(self.el);
      var $items = $el.find(".item");
      var $containerEl = $items.closest(".content");
      $items = $items.not($containerEl.find('[data-ignore-drag-select="true"]'));
      $items.draggable({
        handle: ".drag",
        revert: "invalid",
        cursor: "pointer",
        cursorAt: {
          bottom: 8,
          left: 8
        },
        appendTo: "body",
        zIndex: 2700,
        helper: function (event) {
          var $currentTarget = $(event.currentTarget);
          var closestChildrenEl = $currentTarget.closest(".children");
          var isChildrenNode = closestChildrenEl.length;
          var $contentContainer = $el.find(".content-c");
          var $parent = isChildrenNode ? closestChildrenEl : $contentContainer;
          var $selectedItems = $parent.children(".item-wrapper.drag-selected");
          var handlerTextEl = self.draggableConfigs.handlerTextEl || ".name";
          var labelTxt = $.trim($currentTarget.find(handlerTextEl + " .text").text());
          var count, isMultiple;
          $selectedItems = $selectedItems.not($selectedItems.has($parent.find('[data-ignore-drag-select="true"]')));
          $selectedItems = $selectedItems.children(".item");
          count = $selectedItems.length;
          isMultiple = count > 1;
          if (!isMultiple) {
            self.removeDragClasses();
          }
          return self.showDragDropHelper({
            label: labelTxt,
            count: count,
            isMultiple: isMultiple
          });
        }
      }).mousedown(function (event) {
        var $currentTarget = $(event.currentTarget);
        var parent = $currentTarget.closest(".content");
        if (event.shiftKey === true) {
          parent.disableSelection();
        }
      }).mouseup(function (event) {
        var $currentTarget = $(event.currentTarget);
        var parent = $currentTarget.closest(".content");
        parent.enableSelection();
      });
    };
    view.prototype.render = function () {
      var self = this;
      var deferred = $.Deferred();
      runOriginalRender.apply(this, arguments).then(function () {
        self.applyDraggable.call(self);
        deferred.resolve();
      });
      return deferred.promise();
    };
    return view;
  }
  function addSorting(view) {
    view.prototype.events = $.extend({}, view.prototype.events, {
      "click .header[data-field]": "updateSort"
    });
    view.prototype.updateSort = function (e) {
      var sortConfigs = this.sortConfigs;
      var currentTarget = $(e.currentTarget);
      var field = currentTarget.data("field");
      var orderIcon = currentTarget.find(".order-icon");
      var defaultSortOrder = sortConfigs.order.default || "ascending";
      var customDefaultSortOrder = sortConfigs.order.custom || {};
      var sortKey = field || "";
      var sortOrder;
      e.stopPropagation();
      if (sortKey !== "") {
        if (orderIcon.hasClass("ascending")) {
          sortOrder = "descending";
        } else if (orderIcon.hasClass("descending")) {
          sortOrder = "ascending";
        } else {
          sortOrder = customDefaultSortOrder[field] || defaultSortOrder;
        }
        if (sortConfigs.sortBy && typeof sortConfigs.sortBy === "function") {
          sortConfigs.sortBy.call(this, sortKey, sortOrder);
        }
      }
      T1.EventHub.publish("track:event", {
        action: "Sort table",
        label: field,
        value: sortOrder === "ascending" ? 1 : -1
      });
    };
    return view;
  }
  function extendView(view) {
    var originalSerialize = view.prototype.serialize;
    var originalPartials = view.prototype.partials;
    var isInfScroll = view.prototype.scrollConfigs;
    var isDraggable = view.prototype.draggableConfigs;
    var isSortable = view.prototype.sortConfigs;
    var truncateConfig = view.prototype.truncateConfig || {};
    var perfectScrollConfig = view.prototype.perfectScrollConfig;
    view.prototype.template = gridTemplate;
    view.prototype.partials = $.extend({}, {
      gridHeader: gridHeaderTemplate,
      gridFooter: gridFooterTemplate,
      gridSubItem: gridSubItemTemplate,
      listItem: "<a>{{.}}</a>",
      dragDropHelper: DragDropHelper
    }, originalPartials);
    view.prototype.serialize = function () {
      var altSearchSelected = this.altCollection && this.altSearchSelected;
      var serializedData = originalSerialize && originalSerialize.apply(this, arguments);
      var gridInformation = {
        headers: this.headers || []
      };
      var collection = altSearchSelected ? this.altCollection : this.collection;
      gridInformation.loading = !!(collection && !collection.loaded);
      return $.extend({}, gridInformation, serializedData);
    };
    view.prototype.setSearchLoader = function () {
      var coll = this.collection;
      var $currentEl = $(this.el);
      if ($currentEl.find(".view-loader").length > 0) {
        return;
      }
      $currentEl.append('<div class="view-loader" />');
      this.searchLoader = T1.Loader.create({
        target: ".view-loader",
        text: "Loading ...",
        loaderClass: "loader",
        context: $currentEl
      });
      if (coll) {
        coll.loaded = false;
      }
      this.searchLoader.start();
    };
    view.prototype.updateScrollBar = function () {
      var $contentHolder = $(this.el).find(".content-holder");
      if ($contentHolder.length > 0) {
        $contentHolder.perfectScrollbar({}, "update");
      }
    };
    view.prototype.render = function (data, opts) {
      var tdata = data || (this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {});
      var partials = this.partials || {};
      var deferred = $.Deferred();
      var self = this;
      var $el = $(this.el);
      var location = T1.Location.get();
      var templateCurrency = T1.Utils.getCurrencyTemplateFunc;
      var templateDecimalCurrency = T1.Utils.getDecimalCurrencyTemplateFunc;
      var $content, renderAction;
      var actions = {
        render: "render",
        reRenderList: "reRenderList",
        append: "append"
      };
      var shouldRemoveLoader = !!this.searchLoader;
      var itemContentRendered = typeof this.itemContentRendered === "function";
      opts = opts || {};
      renderAction = actions[opts.action] || "render";
      if (renderAction === "render") {
        $el.html(T.to_html(this.template, tdata, partials));
        $el.find(".content-holder").perfectScrollbar({
          wheelPropagation: !(perfectScrollConfig && perfectScrollConfig.allowWheelPropagation === false),
          minScrollBarSize: 20
        });
      }
      $content = $el.find(".content-c");
      this.renderQueue = new T1.RequestQueue.create({
        collection: tdata.list,
        action: function (source) {
          var $row;
          var partial = self.altSearchSelected && self.viewType === "search" ? partials.altListItem : partials.listItem;
          source.appLocation = location;
          if (!source.asCurrency) {
            source.asCurrency = templateCurrency(self.getCurrencyCode());
          }
          if (!source.asDecimalCurrency) {
            source.asDecimalCurrency = templateDecimalCurrency(self.getCurrencyCode());
          }
          $row = $(T.to_html(partial, source, partials));
          $content.append($row);
          if (itemContentRendered) {
            setTimeout(function () {
              self.itemContentRendered($row);
            }, 0);
          }
          self.applyTruncate($row);
        },
        onEnd: function () {
          setTimeout(function () {
            $el.find("input, textarea").placeholder();
            self.updateScrollBar();
            if (self.headersTooltips) {
              T1.Tooltip(".header", {
                getExternalTip: true,
                delayIn: 0,
                delayOut: 0
              });
            }
            deferred.resolve();
          }, 0);
        }
      });
      if (tdata.list.length && renderAction !== "append") {
        $content.empty();
      }
      this.renderQueue.load();
      if (renderAction === "render") {
        self.buildPermissionedEvents();
        self.bindViewEvents();
      }
      if (shouldRemoveLoader) {
        this.searchLoader.stop();
        delete this.searchLoader;
      }
      return deferred.promise();
    };
    view.prototype.applyTruncate = function ($target) {
      function stripHTMLFromTitle($col) {
        var title = $col.attr("title");
        var $title;
        if (title !== undefined) {
          title = "<div>" + title + "</div>";
          $title = $(title);
          $col.attr("title", $title.text());
        }
      }
      _.each(truncateConfig, function (options, key) {
        var $key = $target.find(key);
        $key.trunk8(options);
        if (options.stripHTML) {
          if ($key.length > 1) {
            _.each($key, function (value) {
              stripHTMLFromTitle($(value));
            });
          } else {
            stripHTMLFromTitle($key);
          }
        }
      });
    };
    if (isInfScroll) {
      view = addInfiniteScrolling(view);
    }
    if (isDraggable) {
      view = addDraggable(view);
    }
    if (isSortable) {
      view = addSorting(view);
    }
    return T1.Form.ViewUtils.extend(view);
  }
  extendView.addFeatures = function () {
    this.prototype = this.__proto__;
    if (this.scrollConfigs) {
      addInfiniteScrolling(this);
    }
    if (this.sortConfigs) {
      addSorting(this);
    }
    if (this.draggableConfigs) {
      addDraggable(this);
      this.partials.dragDropHelper = DragDropHelper;
    }
    delete this.prototype;
  };
  return extendView;
});
