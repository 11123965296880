define('collections/creativesConceptsStrategies',["jQuery", "Underscore", "T1", "T1Collection", "models/conceptStrategy", "T1Sortable"], function ($, _, T1, T1Collection, ConceptStrategy) {
  T1.Models.CreativesStrategyConcepts = T1Collection.extend({
    model: ConceptStrategy,
    canCache: false,
    filterConfiguration: {
      filters: [{
        entity: "concept",
        fetchOnChange: false
      }]
    },
    searchConfiguration: {
      fields: {
        status: {
          type: "enum"
        }
      }
    },
    fetchOptions: {
      full: "*",
      with: ["concept", "strategy,campaign"]
    },
    fetch: function (opts) {
      T1Collection.prototype.fetch.call(this, opts);
    },
    urlString: "strategy_concepts"
  });
  return T1.Models.CreativesStrategyConcepts;
});
