define('modules/audiences/lookalikes/views/lookalikes',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "T1Menu", "T1Permissions", "text!modules/audiences/lookalikes/templates/lookalikes.html", "modules/audiences/segments/segmentUtils", "models/user", "models/advertiser", "models/organization", "collections/organizations", "modules/campaign/models/multiSearch", "models/lookalikesModel", "jQPlugins/chosen.jquery", "jQueryUI"], function ($, _, Backbone, Hogan, T1, T1Menu, T1Permissions, template, Util, User, Advertiser, Organization, Organizations, MultiSearch, LookalikesModel) {
  "use strict";

  var multiSearch = new MultiSearch();
  var utils = T1.Utils;
  var LOOKALIKES_URL = "audiences/lookalikes/";
  function updateBreadCrumb() {
    T1.EventHub.publish("updateNavPath", {});
  }
  var lookalikesView = Backbone.View.extend({
    template: Hogan.compile(template),
    pageNum: 0,
    pageData: [],
    collection: multiSearch,
    modelCollection: null,
    grid: "",
    orgId: -1,
    defaultEntity: "audiences",
    models: [],
    initialize: function () {
      var self = this;
      self.isDmpEnabled = false;
      Util.jQueryExtend();
      this.boundReload = this.reload.bind(this);
      T1.EventHub.subscribe("organization:select", this.boundReload);
    },
    load: function () {
      this.pageNum = 0;
      updateBreadCrumb();
      this.render();
    },
    events: {
      "click #add-model": "addModel",
      "click .clear-filter": "resetSearch"
    },
    source: function (request, response) {
      var promise = this.collection.find(request.term, $(this.el).find(".select-input").val());
      promise.done(function (result) {
        var data = result.data.map(function (item) {
          return Object.assign(item, {
            label: item.name,
            value: item.name
          });
        });
        response(data);
      }).fail(function () {
        response([]);
      });
    },
    render: function () {
      var self = this;
      var el = $(self.el);
      var org = new Organization();
      var entityInput = null;
      var searchInput = null;
      var filterList = [{
        text: "Lookalike Models",
        value: "audiences"
      }, {
        text: "Agencies",
        value: "agencies"
      }, {
        text: "Advertisers",
        value: "advertisers"
      }];
      this.setDropDownEntity(filterList);
      var renderedTemplate = this.template.render({
        model: "{{model}}",
        scope: "{{scope}}",
        name: "{{model.name}}",
        id: "{{model.model_id}}",
        segments: "{{model.segments}}",
        countries: "{{model.displayCountries}}",
        seedType: "{{model.seed_type}}",
        audienceType: "{{model.audienceType}}",
        siteRecency: "{{model.siteRecency}}",
        seedPixels: "{{model.seedPixels}}",
        advertiser: "{{model.advertiser_name}}",
        isBuilt: "{{model.isBuilt}}",
        isNotBuilt: "{{model.isNotBuilt}}",
        buildColor: "{{model.buildColor}}",
        buildIcon: "{{model.buildIcon}}",
        buildText: "{{model.buildText}}",
        filterList: filterList
      });
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = renderedTemplate;
      } else {
        $(this.el).html(renderedTemplate);
      }
      self.grid = this.el[0].querySelector("#list-of-models");
      self.grid.isLoading = true;
      $(".list-size-list").chosen({
        disable_search_threshold: 10
      });
      entityInput = el.find(".select-input");
      searchInput = el.find(".term .text");
      el.find(".select-input").chosen({
        disable_search_threshold: 10
      }).change(function () {
        self.entityType = entityInput.val();
        searchInput.val("");
      });
      $("#entities-results").autocomplete({
        minLength: 1,
        delay: 1,
        autoFocus: true,
        appendTo: ".entities-auto-complete",
        source: function (request, response) {
          if ($.trim(request.term) === "#") {
            searchInput.removeClass("ui-autocomplete-loading");
            return;
          }
          self.searchTerm = request.term;
          if (self.entityType === "audiences") {
            $(self.el).find(".clear-filter").hide();
            self.populateModels({
              term: request.term
            });
          } else {
            self.source.call(self, request, function (data) {
              searchInput.autocomplete({
                autoFocus: true
              });
              if (!data.length) {
                data = [{
                  summary: 'No results found for "' + utils.escapeHTML(request.term) + '"'
                }];
                searchInput.autocomplete({
                  autoFocus: false
                });
              }
              searchInput.removeClass("ui-autocomplete-loading");
              response(data);
            });
          }
        },
        focus: function () {
          return;
        },
        select: function (event, ui) {
          var item = ui.item;
          var args = {
            entityId: item.id,
            ownerType: null
          };
          self.ownerType = $(self.el).find(".select-input").val();
          args.ownerType = self.ownerType;
          searchInput.val(item.label).select();
          self.populateModels(args);
          return false;
        }
      }).keyup(function (e) {
        if (self.entityType === "audiences" && !e.currentTarget.value) {
          self.resetSearch();
        }
      }).data("autocomplete")._renderItem = self.renderItem;
      searchInput.data("autocomplete")._renderMenu = self.renderMenu;
      Organizations.getOrganizations().getSelected().then(function (orgId) {
        self.orgId = orgId;
        self.collection.orgId = orgId;
        self.modelCollection = new LookalikesModel({
          org: orgId
        });
        self.populateModels();
      });
      T1.resizeWidth();
    },
    populateModels: function (args) {
      var searchInput = this.el.find(".term .text");
      var self = this;
      var searchValue = searchInput.val();
      var q;
      if (args && args.ownerType) {
        var ownerType = args.ownerType === "agencies" ? "agency" : "advertiser";
        q = ownerType + "_id%3D%3D" + args.entityId;
      } else if (args && args.term) {
        if (args.term[0] === "#") {
          q = "model_id%3D%3D" + encodeURIComponent(args.term.slice(1));
        } else {
          q = "name%3D%3A%2A" + encodeURIComponent(args.term) + "%2A";
        }
      }
      if (q) {
        searchInput.removeClass("ui-autocomplete-loading");
        self.searchIconSwap();
      }
      clearTimeout(self.typingTimer);
      var populateFromModel = function () {
        self.modelCollection.modelFetch({
          q: q,
          success: function (results) {
            if (results.length === 0) {
              self.grid.noResultsMessage = "No results found";
            }
            self.grid.isLoading = false;
            self.grid.set("data", results);
          },
          error: function () {
            self.grid.isLoading = false;
            self.grid.data = [];
            self.grid.noResultsMessage = "No results found";
          }
        });
        if (args && "term" in args) {
          searchInput.removeClass("ui-autocomplete-loading");
          self.searchIconSwap();
        }
      };
      if (searchValue) {
        self.typingTimer = setTimeout(populateFromModel, 1e3);
      } else {
        populateFromModel();
      }
    },
    addModel: function () {
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
      T1.Location.set(LOOKALIKES_URL + "create");
    },
    searchIconSwap: function () {
      var $el = $(this.el);
      $el.find("#entities-results").addClass("hide-magnifying-glass");
      $el.find(".clear-filter").show();
    },
    resetSearch: function (e) {
      var $el = $(this.el);
      var $searchBox = $el.find("#entities-results");
      $el.find(".clear-filter").hide();
      $searchBox.removeClass("hide-magnifying-glass").val("");
      this.populateModels();
    },
    findEntity: function (entities, value) {
      var entity = entities.filter(function (obj) {
        return obj.value === value;
      });
      return entity[0];
    },
    setDropDownEntity: function (entities) {
      if (this.ownerType) {
        this.findEntity(entities, this.ownerType).selected = "selected";
      } else {
        this.findEntity(entities, this.defaultEntity).selected = "selected";
        this.entityType = this.defaultEntity;
      }
    },
    renderMenu: function (ul, items) {
      var self = this;
      var currentCategory = "";
      $.each(items, function (index, item) {
        if (item.category && item.category !== currentCategory) {
          ul.append('<li class="ui-autocomplete-category"><b></b>' + item.category + "</li>");
          currentCategory = item.category;
        }
        if (item.hasOwnProperty("summary")) {
          ul.append("<li class='ui-autocomplete-summary'>" + item.summary + "</li>");
        } else {
          item.label = item.value = item.label + " (" + item.id + ")";
          self._renderItem(ul, item);
        }
      });
    },
    renderItem: function (ul, item) {
      return $("<li></li>").data("item.autocomplete", item).append("<a>" + item.label + "</a>").appendTo(ul);
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      if (this.layout) {
        this.layout.unload();
      }
      $("#entities-dropdown").off("change");
      $(this.el).empty();
      this.unbind();
      T1.EventHub.unsubscribe("organization:select", this.boundReload);
    }
  });
  return lookalikesView;
});
