define('modules/reporting/campaigns/views/select_focus',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/select_focus.html"], function ($, T1, T1View, T1Permissions, template) {
  "use strict";

  var documentEvents = "mousedown.focusUI keyup.focusUI";
  var $document = $(document);
  const ANIM_TIME = 200;
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  return T1View.extend({
    template: template,
    currentFocus: "all",
    focusUIOpen: false,
    list: [{
      icon: "all",
      name: "All Channels"
    }, {
      icon: "video",
      name: "Video/Audio"
    }, {
      icon: "display",
      name: "Display"
    }],
    events: {
      "mousedown .head-label": "toggle",
      "mousedown .w-Focus": "stopBubbling",
      "mouseout li": "itemOut",
      "mouseover li": "itemOver",
      "mouseup .focus-list li.enabled": "itemClick"
    },
    initialize: function (args) {
      this.models = args.models;
      this.reportModel = this.models.reportModel;
      this.reportModelObj = $.extend({}, this.reportModel.toJSON());
      this.campaign = args.models.campaign;
      this.currentFocus = this.reportModel.get("focus");
      this.updateList();
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.initializeUI();
      });
    },
    unload: function () {
      $document.off(documentEvents);
      this.wBody = null;
      this.focusLabel = null;
      this.focusUIOpen = null;
    },
    updateList: function () {
      var i, item;
      var len = this.list.length;
      if (len > 1) {
        for (i = 1; i < len; i++) {
          item = this.list[i];
          item.disabled = item.disabledName && !T1Permissions.check("feature", `reporting_${item.icon}`);
        }
      }
    },
    initializeUI: function () {
      var el = $(this.el);
      this.wBody = el.find(".w-body");
      this.focusLabel = el.find(".head-label");
    },
    stopBubbling: function (e) {
      e.stopPropagation();
    },
    toggle: function (e) {
      e.stopPropagation();
      if (this.focusUIOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open: function () {
      var self = this;
      $document.mousedown();
      this.wBody.removeClass("no-display").fadeIn(ANIM_TIME);
      this.focusLabel.addClass("white-bg");
      this.focusUIOpen = true;
      $document.one(documentEvents, function (e) {
        if (e.keyCode === TAB_KEY || e.keyCode === ESCAPE_KEY || !e.keyCode) {
          self.close();
        }
      });
    },
    close: function () {
      this.focusLabel.removeClass("white-bg");
      this.wBody.hide();
      this.focusUIOpen = false;
      $document.off(documentEvents);
    },
    itemOver: function (e) {
      $(e.currentTarget).addClass("highlighted");
    },
    itemOut: function (e) {
      $(e.currentTarget).removeClass("highlighted");
    },
    itemClick: function (e) {
      var selectedId = $(e.currentTarget).data("id");
      if (this.currentFocus !== selectedId) {
        this.currentFocus = selectedId;
        T1.EventHub.publish("select:focus", this.currentFocus);
        this.reload();
      }
      this.close();
    },
    getCurrentFocusText: function (currentFocus) {
      var i, result;
      var len = this.list.length;
      for (i = 0; i < len; i++) {
        if (this.list[i].icon === currentFocus) {
          result = this.list[i].name;
          break;
        }
      }
      return result;
    },
    serialize: function () {
      var i, item;
      var len = this.list.length;
      for (i = 0; i < len; i++) {
        item = this.list[i];
        item.selected = item.icon === this.currentFocus;
        if (item.disabled) {
          item.name = item.disabledName || item.name;
          item.state = "disabled";
        } else {
          item.state = "enabled";
        }
      }
      return {
        current_focus: this.currentFocus,
        currentFocusText: this.getCurrentFocusText(this.currentFocus),
        focusList: this.list
      };
    }
  });
});
