define('modules/strategy/supply/batch/views/panel',["Underscore", "T1", "T1View", "T1Layout", "text!modules/strategy/supply/batch/templates/panel.html", "text!modules/strategy/supply/batch/templates/panel_layout.html", "text!templates/form_footer.html"], function (_, T1, T1View, T1Layout, template, panel_template, form_footer) {
  "use strict";

  var BatchSupplyPanel = T1View.extend({
    contentType: "supply_source",
    template: template,
    partials: {
      form_footer: form_footer
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".item"
    },
    dataEvents: {
      sharedCollection: {
        add: function () {
          this.sharedCollection.isFormElementsChanged = true;
        },
        remove: function () {
          this.sharedCollection.isFormElementsChanged = true;
        }
      }
    },
    initialize: function ({
      model: model,
      sharedCollection: sharedCollection
    }) {
      this.strategy = model;
      this.sharedCollection = sharedCollection;
      this.initLayout();
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panel_template,
        layout: {
          ".browser-c": [{
            module: "strategy/supply/batch",
            viewType: "browse",
            options: {
              orgId: this.orgId,
              agencyId: this.agencyId,
              sharedCollection: this.sharedCollection,
              strategy: this.strategy
            }
          }],
          ".bins-column": [{
            module: "strategy/supply/shared",
            viewType: "bins",
            options: {
              sharedCollection: this.sharedCollection,
              model: this.model,
              mapItems: this.mapItemsBins,
              contentType: this.contentType
            }
          }]
        }
      });
    },
    load: function () {
      this.setRunOnAllExchanges();
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "supply_targeting"
          });
        }
      });
    },
    mapItemsBins: function (items) {
      let groupItems;
      var type = this.contentType;
      var group = {
        bin_show_group_name: true,
        showGroupNameOnly: true,
        bin_group_name: "Facebook",
        groupItems: [],
        count: "any"
      };
      function truncate(name) {
        var maxLen = 25;
        return name.length < maxLen ? name : `${name.slice(0, maxLen)}...`;
      }
      if (!items.length) {
        return [];
      }
      group.groupItems = groupItems = items.filter(model => model.get("type") === type).map(function (model) {
        var name = model.get("name");
        return {
          name: name,
          truncated: truncate(name),
          id: model.id
        };
      });
      group.count = "";
      if (group.groupItems.length) {
        group.bin_group_name += ` (${group.groupItems.length})`;
      }
      return groupItems.length ? [group] : [];
    },
    onDontSave: function () {
      this.sharedCollection.fetch();
    },
    postData: function () {
      var self = this;
      if (!this.sharedCollection.length) {
        this.stopSpinner();
        return;
      }
      function saveSuccessful() {
        self.strategy.fetch({
          success: function () {
            self.stopSpinner();
            self.sharedCollection.isFormElementsChanged = false;
            self.updateFooterMessage({
              success: true
            });
            self.processSaveAndContinueMode();
            T1.EventHub.publish("strategy-create-edit:reloadSubViews");
          }
        });
      }
      function statusInvalidCallback(args) {
        if (args && args.hasErr === true) {
          throw new Error(`Saving action failed on strategy supply object. Please check post log for details.`);
        }
      }
      this.sharedCollection.saveStrategySupplySources(false, saveSuccessful, statusInvalidCallback);
    },
    setRunOnAllExchanges: function () {
      var strategy = this.strategy;
      var runOnAll = strategy.get("run_on_all_exchanges");
      var runOnPmp = strategy.get("run_on_all_pmp");
      if (runOnAll && runOnAll !== "0") {
        strategy.set({
          run_on_all_exchanges: "0"
        });
      }
      if (runOnPmp && runOnPmp !== "0") {
        strategy.set({
          run_on_all_pmp: "0"
        });
      }
    }
  });
  return T1.Form.ViewUtils.extend(BatchSupplyPanel);
});
