define('T1DatePicker',["jQuery", "T1", "T1Timezones", "Mustache", "text!templates/datepicker.html", "jQueryUI", "T1Selectbox", "T1Tooltip", "jQPlugins/jquery.meio.mask", "jQPlugins/jquery.cluetip"], function ($, T1, T1Timezones, T, datePickerTemplate) {
  var Date = T1.Date;
  var Timezones;
  $.extend($.mask.options, {
    autoTab: false
  });
  function closeDatePickers() {
    $(document).trigger("hideCluetip");
    $(window).unbind("scroll", closeDatePickers);
  }
  function returnVal(val) {
    return function () {
      return val;
    };
  }
  var returnNull = returnVal(null);
  var returnFalse = returnVal(false);
  var returnTrue = returnVal(true);
  T1.DoubleDatePicker = function (cfgs, scopeEl) {
    var returnDateFormat = "yyyy-MM-dd";
    var dayFormat = "yyyy-MM-dd";
    var timeFormat = "hh:mm tt";
    var hourFormat = "hh";
    var minFormat = "mm";
    var meridianFormat = "tt";
    var meridianDefault = "AM";
    var fullTimeFormat = "yyyy-MM-ddTHH:mm:ss";
    var pickContainerId = ".date-picker-calendar";
    var startCalenderId = ".start-calendar";
    var endCalenderId = ".end-calendar";
    var startContainerId = ".start-picker";
    var endContainerId = ".end-picker";
    var hoursInputId = ".hours-input";
    var minsInputId = ".mins-input";
    var positions = {
      up: 100,
      right: 100,
      down: 100,
      left: 100
    };
    var defaultPosition = "right";
    var tooltipErrorClassName = "w-date-picker-error";
    var tooltipErrorSelector = "." + tooltipErrorClassName;
    var initd = false;
    var settings = {
      supportMultipleFields: false,
      chooseTime: false,
      chooseTimezone: false,
      zoneName: "",
      timezoneEditable: false,
      startTimeEditable: true,
      endTimeEditable: true,
      minDate: null,
      maxDate: null,
      limitDates: true,
      endMinDate: null,
      position: defaultPosition,
      dateFormat: "MM/dd/yyyy",
      defaultStartDate: null,
      defaultEndDate: null,
      useDefaultStart: false,
      useDefaultEnd: false,
      defaultStartMessage: "use default start date",
      defaultEndMessage: "use default end date",
      startDisabled: false,
      endDisabled: false,
      getStartDate: function () {
        return Date.parse("today").toDateString();
      },
      getEndDate: function () {
        return Date.parse("today").toDateString();
      },
      closeOnScroll: true,
      onSave: function (options) {
        options.success();
        return true;
      },
      onClose: function () {},
      onOpen: function () {}
    };
    var dataNode = {
      useDefault: false,
      date: "",
      day: "",
      time: "",
      hours: "",
      mins: "",
      meridian: "",
      am: false
    };
    var dateValue = {
      zoneName: "",
      start: $.extend(true, {}, dataNode),
      end: $.extend(true, {}, dataNode)
    };
    var timeOptions = {};
    var data = {};
    var pickContainer, startDatePicker, endDatePicker, currentStartDate, currentEndDate, updateTime, done, $contextEl;
    if (this === window) {
      throw new Error("T1 Double Picker requires that the scopeEl not be global to initialized");
    }
    if (cfgs === undefined) {
      throw new Error("T1 Double Picker requires a config argument to initialize");
    }
    if (cfgs && typeof cfgs !== "object") {
      throw new Error("T1 Double Picker requires a config object to initialize");
    }
    data.saveValues = $.extend(true, {}, dateValue);
    data.currentValues = $.extend(true, {}, dateValue);
    settings = $.extend({}, settings, cfgs);
    scopeEl = scopeEl || settings.el || "body";
    pickContainer = $(scopeEl).find(pickContainerId);
    if (!pickContainer.size()) {
      pickContainer = $('<div class="' + pickContainerId.split(".")[1] + '"></div>');
      $(scopeEl).append(pickContainer);
    }
    function parseDefaultDateValue(key) {
      var val = settings[key];
      if (typeof val === "function") {
        val = val();
      }
      return val;
    }
    function getDefaultStart() {
      return Date.parse(parseDefaultDateValue("defaultStartDate") || settings.defaultStart);
    }
    function getDefaultEnd() {
      return Date.parse(parseDefaultDateValue("defaultEndDate") || settings.defaultEnd);
    }
    function resetDateValues() {
      var defaultStart = getDefaultStart();
      var defaultEnd = getDefaultEnd();
      data.currentValues.zoneName = settings.zoneName();
      data.saveValues.zoneName = settings.zoneName();
      settings.useDefaultStart = data.currentValues.start.useDefault ? true : false;
      settings.useDefaultEnd = data.currentValues.end.useDefault ? true : false;
      currentStartDate = settings.useDefaultStart ? Date.parse(defaultStart) : Date.parse(data.currentValues.start.date);
      currentEndDate = settings.useDefaultEnd ? Date.parse(defaultEnd) : Date.parse(data.currentValues.end.date);
      if (!currentStartDate || !currentEndDate) {
        return;
      }
      settings.startDay = currentStartDate.toString(dayFormat);
      data.currentValues.start.date = currentStartDate;
      data.currentValues.start.day = settings.startDay;
      data.saveValues.start.day = settings.startDay;
      settings.endDay = currentEndDate.toString(dayFormat);
      data.currentValues.end.date = currentEndDate;
      data.currentValues.end.day = settings.endDay;
      data.saveValues.end.day = settings.endDay;
      if (settings.chooseTime) {
        settings.startTime = currentStartDate.toString(timeFormat);
        settings.startHours = currentStartDate.toString(hourFormat);
        settings.startMins = currentStartDate.toString(minFormat);
        settings.startMeridian = currentStartDate.toString(meridianFormat);
        data.currentValues.start.time = settings.startTime;
        data.saveValues.start.time = settings.startTime;
        data.currentValues.start.hours = settings.startHours;
        data.saveValues.start.hours = settings.startHours;
        data.currentValues.start.mins = settings.startMins;
        data.saveValues.start.mins = settings.startMins;
        data.currentValues.start.meridian = settings.startMeridian;
        data.saveValues.start.meridian = settings.startMeridian;
        settings.startInAM = settings.startMeridian === meridianDefault;
        settings.endTime = currentEndDate.toString(timeFormat);
        settings.endHours = currentEndDate.toString(hourFormat);
        settings.endMins = currentEndDate.toString(minFormat);
        settings.endMeridian = currentEndDate.toString(meridianFormat);
        data.currentValues.end.day = settings.endDay;
        data.saveValues.end.day = settings.endDay;
        data.currentValues.end.time = settings.endTime;
        data.saveValues.end.time = settings.endTime;
        data.currentValues.end.hours = settings.endHours;
        data.saveValues.end.hours = settings.endHours;
        data.currentValues.end.mins = settings.endMins;
        data.saveValues.end.mins = settings.endMins;
        data.currentValues.end.meridian = settings.endMeridian;
        data.saveValues.end.meridian = settings.endMeridian;
        settings.endInAM = settings.endMeridian === meridianDefault;
      }
      settings.defaultTimeData = {};
      if (defaultStart) {
        settings.defaultTimeData.start = {
          time: defaultStart.toString(timeFormat),
          hours: defaultStart.toString(hourFormat),
          mins: defaultStart.toString(minFormat),
          meridian: defaultStart.toString(meridianFormat)
        };
      }
      if (defaultEnd) {
        settings.defaultTimeData.end = {
          time: defaultEnd.toString(timeFormat),
          hours: defaultEnd.toString(hourFormat),
          mins: defaultEnd.toString(minFormat),
          meridian: defaultEnd.toString(meridianFormat)
        };
      }
    }
    function padInput() {
      var input_limit = $(this).data("limit");
      var pad = {
        1: "0",
        0: input_limit,
        2: ""
      };
      $(this).val(pad[$(this).val().length] + $(this).val());
      if ($(this).val() === "00") {
        $(this).val(input_limit);
      }
    }
    function setUseStartDefault() {
      var checked = $(this).is(":checked");
      var defaultDate;
      data.saveValues.start.useDefault = checked;
      if (checked) {
        defaultDate = getDefaultStart();
        startDatePicker.datepicker("setDate", defaultDate.toString(settings.dateFormat));
        updateTime(true, "start");
      }
    }
    function setUseEndDefault() {
      var checked = $(this).is(":checked");
      var defaultDate;
      data.saveValues.end.useDefault = checked;
      if (checked) {
        defaultDate = getDefaultEnd();
        endDatePicker.datepicker("setDate", defaultDate.toString(settings.dateFormat));
        updateTime(true, "end");
      }
    }
    function uncheckUseStartDefault() {
      data.saveValues.start.useDefault = false;
      $(".use-start-date input", pickContainer).attr("checked", false);
      T1.EventHub.publish("startDatePicker:select");
    }
    function uncheckUseEndDefault() {
      data.saveValues.end.useDefault = false;
      $(".use-end-date input", pickContainer).attr("checked", false);
      T1.EventHub.publish("endDatePicker:select");
    }
    function resetBindings() {
      var endMinDate;
      var minDate = Date.parse(settings.minDate || currentStartDate);
      var maxDate = Date.parse(settings.maxDate || currentEndDate);
      var upperBounds = settings.upperBounds ? typeof settings.upperBounds === "string" ? Date.parse(settings.upperBounds) : typeof settings.upperBounds === "function" ? settings.upperBounds(data.saveValues) : settings.upperBounds : null;
      var lowerBounds = settings.lowerBounds ? typeof settings.lowerBounds === "string" ? Date.parse(settings.lowerBounds) : typeof settings.lowerBounds === "function" ? settings.lowerBounds(data.saveValues) : settings.lowerBounds : null;
      pickContainer = $(pickContainerId, "#cluetip");
      pickContainer.html(T.to_html(datePickerTemplate, settings));
      startDatePicker = $(startContainerId, pickContainer);
      startDatePicker.datepicker({
        maxDate: settings.limitDates ? maxDate : null,
        minDate: lowerBounds,
        disabled: settings.startDisabled(),
        onSelect: function (dateText) {
          startDatePicker.datepicker("setDate", Date.parse(dateText));
          data.saveValues.start.day = Date.parse(dateText).toString(returnDateFormat);
          uncheckUseStartDefault();
          if (settings.limitDates) {
            endDatePicker.datepicker("option", "minDate", Date.parse(dateText));
          }
          return false;
        }
      });
      startDatePicker.datepicker("setDate", currentStartDate, settings.getEndDate());
      endDatePicker = $(endContainerId, pickContainer);
      if (!settings.endMinDate) {
        settings.endMinDate = Date.compare(Date.parse("today"), minDate) === 1 ? Date.parse("today") : minDate;
      }
      endMinDate = typeof settings.endMinDate === "function" ? settings.endMinDate() : settings.endMinDate;
      endDatePicker.datepicker({
        maxDate: upperBounds,
        minDate: settings.limitDates ? endMinDate : null,
        disabled: settings.endDisabled(),
        onSelect: function (dateText) {
          endDatePicker.datepicker("setDate", Date.parse(dateText));
          data.saveValues.end.day = Date.parse(dateText).toString(returnDateFormat);
          uncheckUseEndDefault();
          if (settings.limitDates) {
            startDatePicker.datepicker("option", "maxDate", Date.parse(dateText));
          }
          return false;
        }
      });
      endDatePicker.datepicker("setDate", currentEndDate);
      if (settings.useStartDefaultCheckInput) {
        settings.useStartDefaultCheckInput.click(setUseStartDefault);
      } else {
        $(".use-start-date", pickContainer).on("click", "input", setUseStartDefault);
      }
      if (settings.useEndDefaultCheckInput) {
        settings.useEndDefaultCheckInput.click(setUseEndDefault);
      } else {
        $(".use-end-date", pickContainer).on("click", "input", setUseEndDefault);
      }
      pickContainer.on("mouseenter", ".display-time,.edit-timezone", function () {
        if ($(this).hasClass("editable")) {
          $(this).addClass("isEditable");
        }
      }).on("mouseleave", ".display-time, .edit-timezone", function () {
        $(this).removeClass("isEditable");
      });
      pickContainer.find(".done-action").on("click", function (e) {
        e.preventDefault();
        done(settings.onSave);
      });
      pickContainer.find(".cancel-action").on("click", function (e) {
        close();
        e.preventDefault();
      });
      pickContainer.find(".display-time").on("click", function (e) {
        $(".time-selector", pickContainer).hide();
        $(".display-time", pickContainer).show();
        if ($(this).next(".time-selector").length) {
          $(this).hide().next(".time-selector").show();
        }
        e.stopPropagation();
      });
      pickContainer.bind("click.timepicker", function () {
        var choosers = pickContainer.find(".choose-time");
        $.each(choosers, function () {
          var timeChooser = $(this);
          function pad(num) {
            var s = "00" + num;
            return s.substr(s.length - 2);
          }
          timeChooser.find(".display-time.editable").text(timeChooser.find(hoursInputId).val() + ":" + pad(timeChooser.find(minsInputId).val()) + " " + timeChooser.find(".meridian-input").val());
          timeChooser.find(".time-selector").hide();
          timeChooser.find(".display-time").show();
        });
      });
      $(".time-selector", pickContainer).bind("click.timepicker", function (e) {
        e.stopPropagation();
      });
      $(minsInputId, pickContainer).setMask({
        mask: "99",
        type: "reverse"
      }).keypress(function () {
        $(this).setMask({
          mask: "59"
        });
      });
      $(hoursInputId, pickContainer).setMask({
        mask: "99",
        type: "reverse"
      }).keypress(function () {
        var currentMask = $(this).data("mask").mask;
        var newMask = $(this).val().match(/^([0]){1}/) ? "99" : $(this).val().match(/^([1]){1}/) ? "12" : "9";
        if (newMask !== currentMask) {
          $(this).setMask({
            mask: newMask
          });
        }
      });
      $(".time-input", pickContainer).blur(function (e) {
        padInput.call(this, e);
      });
      T1.Selectbox($(".timezone-selector", pickContainer), {
        searchFieldDisabled: false,
        onChange: function (e) {
          var selectedZoneId = parseInt($(e.currentTarget).val(), 10);
          var timeZone = Timezones.getByZoneId(selectedZoneId);
          data.saveValues.zoneName = timeZone ? timeZone.get("zone_name") : "";
        }
      });
      T1.Selectbox($(".meridian-input", pickContainer));
      $(window).on("resize.datepicker", function () {
        close();
        $(window).off("resize.datepicker");
      });
      $(window).on("keypress.datepicker", function (e) {
        var code = e.keyCode || e.which;
        if (code === 13) {
          done(settings.onSave);
        }
      });
      pickContainer.find(".start-calendar .time-input").each(function () {
        $(this).data("oldVal", $(this).val());
        $(this).bind("propertychange keypress input paste", function () {
          if ($(this).data("oldVal") !== $(this).val()) {
            $(this).data("oldVal", $(this).val());
            uncheckUseStartDefault();
          }
        });
      });
      pickContainer.find(".end-calendar .time-input").each(function () {
        $(this).data("oldVal", $(this).val());
        $(this).bind("propertychange keypress input paste", function () {
          if ($(this).data("oldVal") !== $(this).val()) {
            $(this).data("oldVal", $(this).val());
            uncheckUseEndDefault();
          }
        });
      });
      pickContainer.find(".start-calendar .meridian-input").change(function () {
        uncheckUseStartDefault();
      });
      pickContainer.find(".end-calendar .meridian-input").change(function () {
        uncheckUseEndDefault();
      });
    }
    function init() {
      var zoneName = settings.zoneName;
      var timeZone;
      settings.zoneName = typeof settings.zoneName === "function" ? settings.zoneName : function () {
        return zoneName;
      };
      data.currentValues.start.useDefault = data.saveValues.start.useDefault = settings.useDefaultStart && true;
      data.currentValues.start.date = data.saveValues.start.date = settings.getStartDate();
      data.currentValues.end.useDefault = data.saveValues.end.useDefault = settings.useDefaultEnd && true;
      data.currentValues.end.date = data.saveValues.end.date = settings.getEndDate();
      settings.start = $.extend(true, {}, timeOptions);
      settings.end = $.extend(true, {}, timeOptions);
      settings.defaultStartDate = typeof settings.defaultStartDate === "function" ? settings.defaultStartDate : returnNull;
      settings.defaultEndDate = typeof settings.defaultEndDate === "function" ? settings.defaultEndDate : returnNull;
      settings.startTimeEditable = typeof settings.startTimeEditable === "function" ? settings.startTimeEditable : returnTrue;
      settings.endTimeEditable = typeof settings.endTimeEditable === "function" ? settings.endTimeEditable : returnTrue;
      settings.startDisabled = typeof settings.startDisabled === "function" ? settings.startDisabled : returnFalse;
      settings.endDisabled = typeof settings.endDisabled === "function" ? settings.endDisabled : returnFalse;
      if (Timezones && settings.chooseTimezone) {
        timeZone = Timezones.getByZoneName(settings.zoneName());
        settings.timeZoneAsString = timeZone.get("description");
        settings.timezones = Timezones.getTimeZones({
          selected: settings.zoneName()
        });
      }
      settings.position = positions[settings.position] ? settings.position : defaultPosition;
      resetDateValues();
      initd = true;
    }
    function captureTime() {
      var startTimeSelector = $(startCalenderId, pickContainer);
      var endTimeSelector = $(endCalenderId, pickContainer);
      var startHours = startTimeSelector.find(hoursInputId).val();
      var endHours = endTimeSelector.find(hoursInputId).val();
      var startMins = startTimeSelector.find(minsInputId).val();
      var endMins = endTimeSelector.find(minsInputId).val();
      var startMeridian = startTimeSelector.find(".meridian-input").val();
      var endMeridian = endTimeSelector.find(".meridian-input").val();
      var useStartDateInput = startTimeSelector.find(" .use-start-date input");
      var useEndDateInput = endTimeSelector.find(" .use-end-date input");
      var startdateStr = data.saveValues.start.day;
      var enddateStr = data.saveValues.end.day;
      var startdate;
      var enddate;
      var parse = Date.parse;
      if (useStartDateInput.length) {
        data.saveValues.start.useDefault = useStartDateInput.is(":checked");
      }
      if (useEndDateInput.length) {
        data.saveValues.end.useDefault = useEndDateInput.is(":checked");
      }
      if (settings.chooseTime) {
        if (settings.startTimeEditable()) {
          data.saveValues.start.time = startHours + ":" + startMins + " " + startMeridian;
          data.saveValues.start.hours = startHours;
          data.saveValues.start.mins = startMins;
        }
        if (settings.endTimeEditable()) {
          data.saveValues.end.time = endHours + ":" + endMins + " " + endMeridian;
          data.saveValues.end.hours = endHours;
          data.saveValues.end.mins = endMins;
        }
        startdateStr += ", " + data.saveValues.start.time;
        enddateStr += ", " + data.saveValues.end.time;
      }
      startdate = parse(startdateStr);
      enddate = parse(enddateStr);
      data.saveValues.start.date = startdate.toString(fullTimeFormat);
      data.saveValues.end.date = enddate.toString(fullTimeFormat);
    }
    updateTime = function (doRevert, setToDefault) {
      var startTimeDisplay = $(startCalenderId + " .display-time", pickContainer);
      var endTimeDisplay = $(endCalenderId + " .display-time", pickContainer);
      var startHoursInput = $(startCalenderId + " " + hoursInputId, pickContainer);
      var endHoursInput = $(endCalenderId + " " + hoursInputId, pickContainer);
      var startMinsInput = $(startCalenderId + " " + minsInputId, pickContainer);
      var endMinsInput = $(endCalenderId + " " + minsInputId, pickContainer);
      var startMeridianInput = $(startCalenderId + " .meridian-input", pickContainer);
      var endMeridianInput = $(endCalenderId + " .meridian-input", pickContainer);
      var startObj;
      var endObj;
      if (!setToDefault) {
        if (!doRevert) {
          data.currentValues = $.extend(true, {}, data.currentValues, data.saveValues);
        } else {
          data.saveValues = $.extend(true, {}, data.saveValues, data.currentValues);
        }
      }
      startObj = data.currentValues.start;
      endObj = data.currentValues.end;
      if (!setToDefault || setToDefault === "start") {
        if (setToDefault) {
          startObj = settings.defaultTimeData.start;
        }
        startTimeDisplay.text(startObj.time);
        startMeridianInput.val(startObj.meridian);
        startHoursInput.val(startObj.hours);
        startMinsInput.val(startObj.mins);
        startMeridianInput.trigger("liszt:updated");
      }
      if (!setToDefault || setToDefault === "end") {
        if (setToDefault) {
          endObj = settings.defaultTimeData.end;
        }
        endTimeDisplay.text(endObj.time);
        endMeridianInput.val(endObj.meridian);
        endHoursInput.val(endObj.hours);
        endMinsInput.val(endObj.mins);
        endMeridianInput.trigger("liszt:updated");
      }
    };
    function revertTimeZone() {
      var timeZone;
      if (settings.chooseTimezone) {
        data.saveValues.zoneName = data.currentValues.zoneName;
        timeZone = Timezones.getByZoneName(data.currentValues.zoneName);
        $(".timezone-selector", pickContainer).val(timeZone.get("id")).trigger("liszt:updated");
      }
    }
    function revertTime() {
      updateTime(true);
    }
    function resetDateDisplay() {
      currentStartDate = Date.parse(data.currentValues.start.date);
      currentEndDate = Date.parse(data.currentValues.end.date);
      $(startCalenderId, pickContainer).find(" .use-start-date input").attr("checked", data.currentValues.start.useDefault);
      $(endCalenderId, pickContainer).find(" .use-end-date input").attr("checked", data.currentValues.end.useDefault);
      if (currentEndDate) {
        endDatePicker.datepicker("setDate", currentEndDate.toString(settings.dateFormat));
      }
      if (currentStartDate) {
        startDatePicker.datepicker("setDate", currentStartDate.toString(settings.dateFormat));
      }
    }
    function updateDateDisplay() {
      data.currentValues.start.date = data.saveValues.start.date;
      data.currentValues.end.date = data.saveValues.end.date;
      resetDateDisplay();
    }
    function revert() {
      revertTimeZone();
      revertTime();
      resetDateValues();
      resetDateDisplay();
    }
    function close(el) {
      var pickErrorMsg = pickContainer.data("tipsy");
      if (pickErrorMsg) {
        pickErrorMsg.hide();
        pickContainer.removeClass("error");
      }
      if (settings.useStartDefaultCheckInput) {
        settings.useStartDefaultCheckInput.unbind("click", setUseStartDefault);
      }
      if (settings.useEndDefaultCheckInput) {
        settings.useEndDefaultCheckInput.unbind("click", setUseEndDefault);
      }
      T1.EventHub.publish("onCloseInlineEdit");
      $(".display-time", pickContainer).show().next(".time-selector").hide();
      $(window).off("keypress.datepicker");
      $(document).trigger("hideCluetip");
      settings.onClose(el || scopeEl);
      revert();
      return true;
    }
    function open() {
      if (!Timezones) {
        return setTimeout(open, 300);
      }
      $("[data-datepicker]", scopeEl).first().click();
      return true;
    }
    done = function (fn) {
      var complete = typeof fn === "function" ? fn : function (args) {
        args.success();
        return true;
      };
      var loader = T1.Loader.create({
        context: pickContainer
      });
      var equalObjects = T1.Utils.equalObjects;
      var start;
      var end;
      if (!initd) {
        return;
      }
      captureTime();
      loader.start();
      if (equalObjects(data.saveValues.start, data.currentValues.start, ["date"]) && equalObjects(data.saveValues.end, data.currentValues.end, ["date"]) && data.saveValues.zoneName === data.currentValues.zoneName) {
        loader.stop();
        updateDateDisplay();
        close(scopeEl);
        return;
      }
      start = Date.parse(data.saveValues.start.date);
      end = Date.parse(data.saveValues.end.date);
      T1.EventHub.publish("track:event", {
        action: "Choose date range",
        label: "start date - end date",
        value: T1.getNumDays(start, end)
      });
      return complete({
        contextEl: $contextEl,
        data: data,
        success: function () {
          loader.stop();
          updateTime();
          updateDateDisplay();
          close(scopeEl);
        },
        error: function (errorData) {
          var errorConfig = {
            tooltipEle: pickContainer,
            trigger: "manual",
            getExternalTip: false,
            className: tooltipErrorClassName,
            offset: 1
          };
          loader.stop();
          pickContainer.attr("title", errorData && errorData.message);
          pickContainer.addClass("error");
          T1.Tooltip("body", errorConfig);
          pickContainer.tipsy("show");
          if (settings.keepUserDatesOnValidationError !== true) {
            revert();
          }
        }
      });
    };
    T1Timezones.done(function (mthds) {
      var onActivateHandler;
      var $targetEl;
      Timezones = mthds;
      if (settings.supportMultipleFields === true) {
        $targetEl = $("[data-datepicker]", scopeEl);
        $targetEl.cluetip("destroy");
        $targetEl.cluetip("destroy");
        onActivateHandler = function (context) {
          var dtStartText;
          var dtEndText;
          $contextEl = context;
          $(document).trigger("hideCluetip");
          settings.useDefaultStart = true;
          settings.contextEl = $contextEl;
          dtStartText = $contextEl.first().data("startdate");
          dtEndText = $contextEl.first().data("enddate");
          if (dtStartText) {
            settings.defaultStart = dtStartText;
          }
          if (dtEndText) {
            settings.defaultEnd = dtEndText;
          }
          init();
        };
      } else {
        $targetEl = $("[data-datepicker]", scopeEl).first();
        $targetEl.cluetip("destroy");
        $targetEl.cluetip("destroy");
        onActivateHandler = function () {
          $(document).trigger("hideCluetip");
          init();
        };
      }
      $targetEl.cluetip({
        waitImage: false,
        local: true,
        cluezIndex: "auto",
        width: parseInt(pickContainer.outerWidth(), 10),
        height: parseInt(pickContainer.outerHeight(), 10),
        attribute: "data-datepicker",
        sticky: true,
        positionBy: settings.positionBy || "auto",
        topOffset: settings.topOffset || 0,
        leftOffset: settings.leftOffset || 0,
        closePosition: "title",
        cluetipClass: "datepicker",
        activation: "click",
        clickThrough: false,
        dropShadow: false,
        showTitle: false,
        arrows: function () {
          return settings.arrows === "false" ? false : true;
        }(),
        onActivate: onActivateHandler,
        onShow: function () {
          $(document).trigger("mousedown");
          T1.EventHub.unsubscribe("onOpenInlineEdit", closeDatePickers);
          T1.EventHub.publish("onOpenInlineEdit");
          T1.EventHub.subscribe("onOpenInlineEdit", closeDatePickers);
          if (settings.closeOnScroll) {
            $(window).bind("scroll", closeDatePickers);
          }
          settings.onOpen(scopeEl);
          resetBindings();
          $(document).bind("mousedown.cluedatepicker", function (e) {
            if (settings.canClose && !settings.canClose(e)) {
              return;
            }
            $(document).unbind("mousedown.cluedatepicker");
            close(scopeEl);
          });
          pickContainer.on("mousedown.cluedatepicker", function (e) {
            e.stopPropagation();
          });
        },
        onHide: function () {
          $(document).unbind("mousedown.cluedatepicker");
          pickContainer.off("mousedown.cluedatepicker");
          $(tooltipErrorSelector).fadeOut("fast");
          settings.onClose(scopeEl);
        }
      });
    });
    return {
      open: open,
      close: close,
      revert: revert,
      done: done
    };
  };
  T1.DatePicker = function (cfgs) {
    var settings = {
      containerClass: "date-picker-calendar",
      el: null,
      scopeEl: "body"
    };
    var el;
    var scopeEl;
    var picker;
    if (!cfgs || !cfgs.el) {
      return null;
    }
    settings = $.extend({}, settings, cfgs);
    el = $(settings.el);
    scopeEl = $(settings.scopeEl);
    if (scopeEl.size()) {
      scopeEl.append('<div class="' + settings.containerClass + '"></div>');
    }
    function open() {
      T1.EventHub.unsubscribe("onOpenInlineEdit", closeDatePickers);
      T1.EventHub.publish("onOpenInlineEdit");
      T1.EventHub.subscribe("onOpenInlineEdit", closeDatePickers);
      if (picker) {
        return;
      }
      picker = $("." + settings.containerClass, scopeEl).datepicker(settings);
    }
    $(el, scopeEl).click(function (e) {
      $("." + settings.containerClass).hide();
      open();
      $("." + settings.containerClass, scopeEl).show();
      e.stopPropagation();
    });
    return {
      open: open
    };
  };
});
