define('T1Layout',["jQuery", "Mustache", "Backbone", "T1", "T1Comm", "T1Loader"], function ($, Mustache, Backbone, T1, T1Comm) {
  "use strict";

  var uid = 0;
  var uidStr = "lo-";
  var resolveEl;
  var addCfgsToSection;
  var indexSection;
  var indexAllViews;
  var loadModuleView;
  var loadViews;
  var unloadViews;
  var canUnloadViews;
  var emptyLayout;
  var destroyViews;
  var Layout;
  resolveEl = function () {
    return typeof this.el === "function" ? this.el() : this.el;
  };
  addCfgsToSection = function (section, cfgs, callback) {
    var i;
    var self = this;
    if (!section) {
      return false;
    }
    callback = callback || function () {};
    i = section.length;
    cfgs.index = i;
    section[i] = cfgs;
    return callback.call(self, cfgs);
  };
  indexSection = function (section, callback) {
    var i;
    var newIndex;
    var self = this;
    if (!section) {
      return;
    }
    callback = callback || function () {};
    for (i = 0; i < section.length; i++) {
      newIndex = section[i].index === undefined;
      section[i].index = i;
      callback.call(self, section[i], newIndex);
    }
  };
  indexAllViews = function (callback) {
    var self = this;
    var layout = this.layout;
    var section;
    var l;
    callback = callback || function () {};
    function indexHandler(l) {
      return function (cfg, newIndex) {
        callback.call(self, l, cfg, newIndex);
      };
    }
    for (l in layout) {
      if (layout.hasOwnProperty(l)) {
        section = layout[l];
        indexSection.call(self, section, indexHandler(l));
      }
    }
  };
  loadModuleView = function (context, cfgs) {
    var self = this;
    var availView = self.layout[context][cfgs.index].view;
    var dom = $(self.wrapperEl);
    var modules = {};
    var el = resolveEl.call(self);
    var guid = uidStr + uid++;
    var loader;
    function assignView(view) {
      var isUserLoggedIn;
      var boundEl;
      if (!view) {
        return null;
      }
      isUserLoggedIn = T1Comm.isUserLoggedIn();
      boundEl = $(view.el)[0];
      view.el = dom;
      if (!isUserLoggedIn) {
        view.pause.call(view);
      } else if (view.loaded) {
        view.reload.call(view);
      } else {
        view.load.call(view);
        view.loaded = true;
      }
      if (boundEl !== dom[0]) {
        $(boundEl).remove();
      }
      return view;
    }
    function fetchView() {
      require(["modules/" + cfgs.module + "/main"], function (module) {
        var subview_promise;
        var configOptions;
        modules[cfgs.module] = true;
        configOptions = $.extend(cfgs.options, {
          el: dom
        });
        subview_promise = module.getView.call(module, cfgs.viewType, configOptions);
        subview_promise.then(function (view) {
          if (!self.layout[context][cfgs.index]) {
            return;
          }
          self.layout[context][cfgs.index].view = view;
          assignView(view);
        });
      });
    }
    dom.attr("id", guid);
    $(context, el).first().append(dom);
    if (cfgs.showLoader) {
      dom.append("<div class='view-loader' />");
      loader = T1.Loader.create({
        target: ".view-loader",
        text: "Loading ...",
        loaderClass: "loader"
      });
      loader.start();
    }
    if (availView) {
      assignView(availView);
    } else {
      fetchView();
    }
  };
  loadViews = function () {
    var self = this;
    indexAllViews.call(self, function (context, cfgs) {
      return loadModuleView.call(self, context, cfgs);
    });
  };
  unloadViews = function () {
    var self = this;
    var layout = this.layout;
    var section;
    var count;
    var l;
    var i;
    function unloadModule(cfgs, context) {
      var viewIndex = self.layout[context][cfgs.index] || {};
      var moduleView = viewIndex.view;
      if (moduleView && moduleView.unload) {
        if (moduleView.el && typeof moduleView.el !== "function") {
          moduleView.el.remove();
        }
        moduleView.unload.call(moduleView);
      }
    }
    for (l in layout) {
      if (layout.hasOwnProperty(l)) {
        section = layout[l];
        count = section.length;
        for (i = 0; i < count; i++) {
          section[i].index = section[i].index || i;
          unloadModule(section[i], l);
        }
      }
    }
  };
  canUnloadViews = function (args) {
    var self = this;
    var layout = this.layout;
    var section;
    var l;
    var i;
    var canViewsUnload = true;
    function canUnloadView(cfgs, context) {
      var viewIndex = self.layout[context][cfgs.index] || {};
      var moduleView = viewIndex.view;
      if (moduleView && moduleView.canUnload !== undefined) {
        canViewsUnload = moduleView.canUnload(args);
      }
    }
    for (l in layout) {
      if (layout.hasOwnProperty(l) && canViewsUnload) {
        section = layout[l];
        for (i = 0; i < section.length; i++) {
          section[i].index = i;
          if (canViewsUnload) {
            canUnloadView(section[i], l);
          } else {
            break;
          }
        }
      }
    }
    return canViewsUnload;
  };
  emptyLayout = function () {
    var layout = this.layout;
    var el = resolveEl.call(this);
    var l;
    var selectorPath = this.selectorPath;
    destroyViews.call(this);
    for (l in layout) {
      if (layout.hasOwnProperty(l)) {
        layout[l].length = 0;
      }
    }
    el = selectorPath ? $(selectorPath, el) : el;
    return el.empty && el.empty();
  };
  destroyViews = function () {
    var self = this;
    var layout = this.layout;
    var section;
    var l;
    var i;
    function destroyModule(cfgs, context) {
      var viewIndex = self.layout[context][cfgs.index] || {};
      var moduleView = viewIndex.view;
      if (moduleView && moduleView.destroy) {
        moduleView.destroy.call(moduleView);
        viewIndex.view = null;
      }
    }
    for (l in layout) {
      if (layout.hasOwnProperty(l)) {
        section = layout[l];
        for (i = 0; i < section.length; i++) {
          destroyModule(section[i], l);
        }
      }
    }
  };
  Layout = Backbone.View.extend({
    isActive: false,
    initialize: function (opts) {
      var settings = {
        tmplPath: "",
        layout: {},
        wrapperEl: '<div class="_wrapper"></div>'
      };
      var bindings;
      var binding;
      var self = this;
      settings = opts ? $.extend({}, settings, opts) : settings;
      $.extend(this, settings);
      bindings = this.bindings || {};
      function attachBindings(evt, action, params) {
        if (!self[action] || typeof self[action] !== "function") {
          return;
        }
        T1.EventHub.subscribe(evt, function () {
          if (!self.isActive) {
            return;
          }
          self[action].apply(self, params);
        });
      }
      for (binding in bindings) {
        if (bindings.hasOwnProperty(binding)) {
          attachBindings(binding, bindings[binding].action, bindings[binding].params);
        }
      }
    },
    changeLocation: function (loc, opts) {
      return T1.Location.set(loc, opts);
    },
    reloadLocation: function () {
      return T1.Location.reload();
    },
    load: function () {
      var promise;
      if (T1.Location.isReloading()) {
        return;
      }
      unloadViews.call(this);
      this.isActive = true;
      promise = this.render();
      T1.resizeWidth(this.el);
      return promise;
    },
    loadView: function (path) {
      var self = this;
      indexAllViews.call(this, function (context, cfgs) {
        if (context === path) {
          loadModuleView.call(self, context, cfgs);
        }
      });
    },
    reload: function () {
      this.load();
    },
    unload: function () {
      this.isActive = false;
      unloadViews.call(this);
    },
    canUnloadViews: function (args) {
      return canUnloadViews.call(this, args);
    },
    canUnload: function (args) {
      return canUnloadViews.call(this, args);
    },
    pause: function () {
      return this.requestQueue && this.requestQueue.pause();
    },
    empty: function () {
      this.pause();
      emptyLayout.call(this);
    },
    destroy: function () {
      this.unload();
      destroyViews.call(this);
      this.unbind();
    },
    append: function (context, cfgs, sourceArray, optionsGenerator) {
      var self = this;
      var deferred = $.Deferred();
      if (!context || typeof context !== "string" || !cfgs || typeof cfgs !== "object" || $.isArray(cfgs) || !cfgs.module || !cfgs.viewType) {
        return false;
      }
      if (!self.layout[context]) {
        self.layout[context] = [];
      }
      function resolveAndRender(cfg) {
        loadModuleView.call(self, context, cfg);
        setTimeout(function () {
          deferred.resolve();
        }, 0);
      }
      if (sourceArray && optionsGenerator) {
        if (self.requestQueue) {
          self.requestQueue.flush();
        }
        self.requestQueue = new T1.RequestQueue.create({
          collection: sourceArray,
          action: function (source) {
            var cfgsTmpl = $.extend({}, cfgs);
            cfgsTmpl.options = optionsGenerator(source);
            return addCfgsToSection.call(self, self.layout[context], cfgsTmpl);
          },
          onEnd: function () {
            loadViews.call(self);
            deferred.resolve();
          }
        });
        self.requestQueue.load();
      } else {
        addCfgsToSection.call(self, self.layout[context], cfgs, resolveAndRender);
      }
      return deferred.promise();
    },
    render: function () {
      var oSelf = this;
      var tmplPath = oSelf.tmplPath || "";
      var deferred = $.Deferred();
      function updateView() {
        var template = oSelf.template;
        var data = oSelf.serialize ? oSelf.serialize() : {};
        var partials = oSelf.partials ? oSelf.partials : {};
        var el = resolveEl.call(oSelf);
        var targetEl = oSelf.selectorPath ? $(oSelf.selectorPath, el) : $(el);
        data.appLocation = T1.Location.get();
        data.asCurrency = T1.Utils.templateCurrency;
        targetEl.empty();
        if (targetEl[0] && Polymer && Polymer.dom) {
          Polymer.dom(targetEl[0]).innerHTML = Mustache.to_html(template, data, partials);
        } else {
          targetEl.html(Mustache.to_html(template, data, partials));
        }
        loadViews.call(oSelf);
      }
      if (oSelf.template) {
        updateView();
        deferred.resolve();
        return deferred.promise();
      }
      require(["text!" + tmplPath], function (tmpl) {
        oSelf.template = tmpl;
        updateView();
        deferred.resolve();
      });
      return deferred.promise();
    }
  });
  return Layout;
});
