define('modules/campaign/views/controls',["jQuery", "Underscore", "T1", "T1View", "text!modules/campaign/templates/controls.html", "collections/organizations", "T1Permissions"], function ($, _, T1, T1View, template, Organizations, T1Permissions) {
  "use strict";

  return T1View.extend({
    template: template,
    dataEvents: {
      collection: {
        reset: "loadOnReset"
      }
    },
    currentList: "all",
    reportingIntervalCodes: {
      30: "last_30_days",
      0: "current_day",
      14: "last_14_days",
      7: "last_7_days",
      1: "yesterday",
      FTD: "flight_to_date",
      CTD: "campaign_to_date"
    },
    eventhubEvents: {
      "organization:select": function ({
        id: id
      }) {
        if (this.currentList === "favorites" && id === this.org) {
          this.currentList = "all";
        }
        this.org = id;
      },
      "campaigns:filter, campaigns:clearFilter": function () {
        if (this.currentList === "favorites") {
          this.currentList = "all";
        }
      },
      "campaign.changelistview": function (data) {
        this.currentList = data;
      }
    },
    initialize: function ({
      currentList = this.currentList
    }) {
      Organizations.getOrganizations().getSelected().then(id => {
        this.org = id;
      });
      this.currentList = currentList;
    },
    load: function () {
      this.load = $.noop;
      this.render();
    },
    loadOnReset: function () {
      this.loadOnReset = this.render;
    },
    reload: function () {
      this.render();
    },
    permissionedEvents: {
      "campaign.create": {
        "click .add-campaign .add-action": "createCampaign"
      }
    },
    createCampaign: function () {
      return T1.Location.set("campaign/create/new");
    },
    serialize: function () {
      var currentList = this.currentList;
      return {
        canCreateCampaign: T1Permissions.check("campaign.create"),
        hasCampaigns: this.collection.length,
        showAll: currentList === "all" ? "selected" : "",
        showFavorites: currentList === "favorites" ? "selected" : ""
      };
    }
  });
});
