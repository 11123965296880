define('modules/campaign/pixelSegment/views/readOnly',["jQuery", "Underscore", "T1", "T1View", "text!../templates/readOnly.html", "utils/CampaignStrategyUtils", "models/customBrainSelection"], function ($, _, T1, T1View, template, Utils, CustomBrainSelectionModel) {
  "use strict";

  const formatNumber = T1.Formats.rolledUpNumbersShort();
  const readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    initialize: function ({
      model: model,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit,
      collection: collection
    }) {
      const adID = model.get("advertiser_id");
      const customBrainSelectionModel = new CustomBrainSelectionModel({
        id: model.id
      });
      this.collection = collection;
      this.pixelDataCollection = {};
      customBrainSelectionModel.fetchOptions = {
        full: "*"
      };
      this.campaign = model;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.canLoad = false;
      customBrainSelectionModel.fetch({
        success: () => {
          this.customBrainSelections = customBrainSelectionModel.get("user_saved");
          if (adID) {
            this.collection.urlFilter.set({
              entity: "advertiser",
              id: adID
            });
            this.collection.search.set({
              params: [{
                term: "event",
                searchField: "pixel_type"
              }, {
                term: "1",
                searchField: "eligible"
              }]
            });
            this.collection.fetch({
              success: response => {
                this.pixelDataCollection = response.toJSON();
                this.canLoad = true;
                this.load();
              }
            });
          }
        }
      });
    },
    load: function () {
      var loader = this.loader();
      this.render().then(() => {
        if (!this.canLoad || this.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
            this.$(".no-selection").css({
              display: "inline-block"
            });
          }
        }
      });
    },
    serialize: function () {
      const eventPixelsSelections = [];
      const dataPixelsSelections = [];
      const audienceTargetSelections = [];
      const segmentsTargetSelections = [];
      var createDataBlock = Utils.createGroupedDataArray;
      var spaceEntity = "&nbsp;";
      const collObj = this.customBrainSelections;
      var resultJSON = {};
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/details/pixelSegment`;
      }
      if (!this.canLoad) {
        return resultJSON;
      }
      if (this.pixelDataCollection && collObj && collObj.models && collObj.models.length) {
        this.pixelDataCollection.forEach(function (pixelModel) {
          collObj.models.forEach(function (customBrainModel) {
            if (pixelModel.unique) {
              if (pixelModel.unique.pixel_id === customBrainModel.get("selection_id")) {
                const report = pixelModel.unique;
                customBrainModel.set({
                  load_count: report && report.loads !== "0" ? formatNumber(parseInt(report.loads, 10)) : "--",
                  uniques: report && report.uniques !== "0" ? formatNumber(parseInt(report.uniques, 10)) : "--",
                  refer_url: report && report.referrer !== "" ? report.referrer : "--"
                });
              }
            }
          });
        });
      }
      if (collObj && collObj.models && collObj.models.length) {
        $.each(collObj.models, function (index, model) {
          if (model.get("selection_type") === "Event Pixel") {
            eventPixelsSelections.push({
              name: model.get("selection_name"),
              id: model.get("selection_id"),
              load_count: model.get("load_count") ? model.get("load_count") : "--",
              uniques: model.get("uniques") ? model.get("uniques") : "--",
              refer_url: model.get("refer_url") && model.get("refer_url") !== "" ? model.get("refer_url") : "--",
              owner_name: model.get("owner_name")
            });
          }
          if (model.get("selection_type") === "Data Pixel") {
            dataPixelsSelections.push({
              name: model.get("selection_name"),
              id: model.get("selection_id"),
              load_count: model.get("load_count") ? model.get("load_count") : "--",
              uniques: model.get("uniques") ? model.get("uniques") : "--",
              refer_url: model.get("refer_url") && model.get("refer_url") !== "" ? model.get("refer_url") : "--",
              owner_name: model.get("owner_name")
            });
          }
          if (model.get("selection_type") === "Audience Target") {
            audienceTargetSelections.push({
              name: model.get("selection_name"),
              id: model.get("selection_id"),
              full_path: model.get("full_path") ? model.get("full_path").replace(/ - /g, " &raquo ") : "",
              size: model.get("uniques") ? formatNumber(parseInt(model.get("uniques"), 10)) : "--",
              owner_name: model.get("owner_name")
            });
          }
          if (model.get("selection_type") === "Dynamic") {
            segmentsTargetSelections.push({
              name: model.get("selection_name"),
              id: model.get("selection_id"),
              owner_name: model.get("owner_name")
            });
          }
        });
      }
      resultJSON.eventPixelsSideTitle = "Event Pixels";
      resultJSON.dataPixelsSideTitle = "Data Pixels";
      resultJSON.audienceSideTitle = "Audience Providers";
      resultJSON.segmentsSideTitle = "Segments";
      const groupedEventPixelsSelections = _.groupBy(eventPixelsSelections, item => item.owner_name);
      const groupedDataPixelsSelections = _.groupBy(dataPixelsSelections, item => item.owner_name);
      const groupedAudienceTargetSelections = _.groupBy(audienceTargetSelections, item => item.owner_name);
      const groupedSegmentsTargetSelections = _.groupBy(segmentsTargetSelections, item => item.owner_name);
      const eventTempData = createDataBlock(groupedEventPixelsSelections);
      const dataTempData = createDataBlock(groupedDataPixelsSelections);
      const audienceTempData = createDataBlock(groupedAudienceTargetSelections);
      const segmentsTempData = createDataBlock(groupedSegmentsTargetSelections);
      resultJSON.eventPixelDataMain = [{
        sideTitle: spaceEntity,
        data: eventTempData,
        isFirstSideTitle: true,
        hideTitle: !_.isEmpty(eventTempData)
      }];
      resultJSON.dataPixelMain = [{
        sideTitle: spaceEntity,
        data: dataTempData,
        isFirstSideTitle: true,
        hideTitle: !_.isEmpty(dataTempData)
      }];
      resultJSON.dataAudienceTargetMain = [{
        sideTitle: spaceEntity,
        data: audienceTempData,
        isFirstSideTitle: true,
        hideTitle: !_.isEmpty(audienceTempData)
      }];
      resultJSON.dataSegmentsTargetMain = [{
        sideTitle: spaceEntity,
        data: segmentsTempData,
        isFirstSideTitle: true,
        hideTitle: !_.isEmpty(segmentsTempData)
      }];
      if (!resultJSON.eventPixelDataMain[0].data.length) {
        resultJSON.eventPixelDataMain[0].data.push({
          items: [{
            name: "No event pixels applied",
            id: ""
          }],
          title: ""
        });
      }
      if (!resultJSON.dataPixelMain[0].data.length) {
        resultJSON.dataPixelMain[0].data.push({
          items: [{
            name: "No data pixels applied",
            id: ""
          }],
          title: ""
        });
      }
      if (!resultJSON.dataAudienceTargetMain[0].data.length) {
        resultJSON.dataAudienceTargetMain[0].data.push({
          items: [{
            name: "No audience segments applied",
            id: ""
          }],
          title: ""
        });
      }
      if (!resultJSON.dataSegmentsTargetMain[0].data.length) {
        resultJSON.dataSegmentsTargetMain[0].data.push({
          items: [{
            name: "No segments applied",
            id: ""
          }],
          title: ""
        });
      }
      this.hasData = true;
      resultJSON.hasEventPixelData = eventPixelsSelections.length;
      resultJSON.hasDataPixelData = dataPixelsSelections.length;
      resultJSON.hasSegmentTargetData = segmentsTargetSelections.length;
      resultJSON.hasAudienceTargetData = audienceTargetSelections.length;
      return resultJSON;
    }
  });
  return readOnlyView;
});
