define('T1TabLayout',["jQuery", "Mustache", "T1", "T1View", "text!templates/tabs.html", "text!templates/tabItem.html", "jQueryUI", "T1Tooltip"], function ($, Mustache, T1, T1View, tabsTmpl, tabContentTmpl) {
  return T1View.extend({
    selected: 0,
    contextSuffix: "-item",
    transformConfigs: function (cfgs) {
      var tabs = [];
      var tabLevelId;
      var selectedContext = null;
      var context;
      var arry;
      var l;
      var i = 0;
      var t;
      if (!cfgs) {
        throw Error("need configuration to transform for tabs");
      }
      cfgs.tabs = cfgs.tabs || [];
      cfgs.level = cfgs.level || 1;
      arry = cfgs.tabs;
      l = arry.length;
      for (i; i < l; i++) {
        t = arry[i];
        tabLevelId = "tab-" + (i + 1) + "-level-" + cfgs.level;
        t.opts = {
          label: t.label,
          infoIcon: t.infoIcon,
          tooltipText: t.tooltipText,
          linkClass: tabLevelId,
          linkId: tabLevelId,
          itemClass: tabLevelId + this.contextSuffix
        };
        context = "." + t.opts.itemClass;
        selectedContext = i === this.selected ? context : selectedContext;
        tabs.push(t.opts);
        if (!t.panel || typeof t.panel !== "object") {
          break;
        }
        this.panels[context] = t.panel;
      }
      return {
        tabSetName: cfgs.tabSetName,
        level: cfgs.level,
        tabs: tabs,
        panels: this.panels,
        selectedContext: selectedContext
      };
    },
    loadPanel: function (context) {
      var panel = this.panels[context];
      if (panel && !panel.isActive) {
        panel.el = $(this.el).find(context);
        if (panel.loaded) {
          panel.reload && panel.reload();
        } else {
          panel.load && panel.load();
          panel.loaded = true;
        }
      }
    },
    execute: function () {
      if (!arguments) {
        return;
      }
      this.tabEl.tabs.apply(this.tabEl, arguments);
    },
    initialize: function (opts) {
      var tabCfgs;
      var serialize;
      var originalLoad = this.load;
      this.panels = {};
      this.partials = {
        tabContent: tabContentTmpl
      };
      this.disabled = [];
      opts = opts || {};
      this.selected = opts.selected || 0;
      tabCfgs = this.transformConfigs(opts);
      serialize = this.serialize || $.noop;
      this.template = opts.template || tabsTmpl;
      this.partials = $.extend({}, this.partials, opts.partials);
      this.disabled = opts.disabled;
      this.serialize = function () {
        return $.extend({}, tabCfgs, serialize.call(this));
      };
      this.load = function (loadOpts) {
        var self = this;
        var tabLevelContext = ".tab-level-" + tabCfgs.level;
        var selectedContext = loadOpts ? loadOpts.selectedContext : tabCfgs.selectedContext;
        var el;
        if (!this.resolveEl) {
          this.resolveEl = typeof self.el == "function" ? self.el : function () {
            return self.el;
          };
        }
        this.el = el = this.resolveEl();
        if (this.loaded) {
          return;
        }
        originalLoad.call(this);
        if (selectedContext) {
          this.loadPanel.call(this, selectedContext);
          this.selectedContext = selectedContext;
        }
        this.tabEl = $(el).find(tabLevelContext);
        this.tabEl.tabs({
          disabled: this.disabled,
          selected: this.selected,
          select: function (event, ui) {
            var tabId = $(ui.panel).attr("id");
            var panel = self.panels[self.selectedContext];
            var context = "." + tabId + self.contextSuffix;
            var canUnloadSelected = self.canUnloadSelectedTab(tabId);
            setTimeout(function () {
              T1.EventHub.publish("tab:select", {
                tabLevel: ui.tab.hash.substr(ui.tab.hash.indexOf("level")),
                tabLabel: ui.tab.innerText ? ui.tab.innerText : ui.tab.textContent
              });
              if (canUnloadSelected) {
                panel && panel.unload && panel.unload(panel);
                self.loadPanel.call(self, context);
                self.selectedContext = context;
              }
            }, 0);
            return canUnloadSelected;
          },
          show: function (event, ui) {
            T1.resizeWidth($(ui.panel));
          }
        });
        T1.Tooltip(this.el.find(".ui-tab"), {
          offset: 15,
          gravity: "n",
          delayIn: "200",
          delayOut: "600"
        });
        this.loaded = true;
      };
    },
    reload: function () {
      this.load();
    },
    unload: function () {
      this.loaded = false;
      $.each(this.panels, function (i, n) {
        n.unload && n.unload();
      });
    },
    canUnloadSelectedTab: function (tabId, customRedirectCallback) {
      var selectedContext = this.selectedContext;
      var panel;
      var tabEl;
      var canUnloadMethod;
      var customRedirect;
      if (!selectedContext) {
        return true;
      }
      panel = this.panels[selectedContext];
      tabEl = this.tabEl;
      canUnloadMethod = panel.canUnloadViews || panel.canUnload;
      customRedirect = tabId ? {
        onAlertClose: function () {
          tabEl.tabs("select", "#" + tabId);
        }
      } : customRedirectCallback || undefined;
      return canUnloadMethod ? canUnloadMethod.call(panel, customRedirect) : true;
    },
    canUnload: function () {
      var currentPanel = this.panels[this.selectedContext];
      return currentPanel.canUnload.apply(currentPanel, arguments);
    }
  });
});
