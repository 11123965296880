define('modules/creatives/video/views/details',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1FileUpload", "T1Permissions", "models/videoCreative", "models/videoCreativeDetail", "models/videoCreativeVariants", "models/videoCreativeUpload", "models/videoCreativeStatus", "models/advertiser", "models/concept", "models/userPreferences", "collections/creativesVideo", "collections/companions", "collections/trackingEvents", "collections/organizations", "text!../templates/details.html", "text!../templates/detailsBody.html", "text!../templates/createEditWarning.html", "utils/VideoUtils", "utils/CreativeUtils", "async", "moment", "collections/vendors", "../control/VastValidator", "../control/ParallelDeferred", "T1Form", "jQPlugins/jquery.trunk8"], function ($, _, T1, T1View, T1Layout, T1FileUpload, T1Permissions, VideoCreative, VideoCreativeDetail, VideoCreativeVariants, VideoCreativeUpload, VideoCreativeStatus, Advertiser, Concept, UserPrefs, VideoCreativesCollection, Companions, TrackingEvents, Organizations, template, templateBody, templateWarning, VideoUtils, CreativeUtils, async, moment, Vendors, VastValidator, ParallelDeferred) {
  "use strict";

  var DetailsView = T1View.extend({
    template: template,
    callOnce: 0,
    showURLs: 0,
    companionStatus: false,
    disabledVlist: [],
    strategyStartChecked: "",
    strategyEndChecked: "",
    cors: `${COMPASS_ENV.VIDEO_API_BASE}creatives/proxy/?url=`,
    partials: {
      warning: templateWarning
    },
    events: {
      "click .assets": "showEl",
      "change .vasttype": "chooseVastType",
      "change .typexml": "validateVast",
      "change .typetag": "validateVast",
      "click .preview-creative": "previewCreative",
      "click .save-action": "noop",
      "change .landing-page": "updateLandings",
      "click .skippable input[type=checkbox]": "onSkippableClicked",
      "click .advanced-settings-btn": "advancedClicked",
      "click .advanced-btn-enable": "advancedActivate",
      "click .advanced-btn-disable": "advancedActivate",
      "click .advanced-item": "advancedItemClicked",
      "change .companion-holder .type select": "removeCompanionError"
    },
    actionsConfig: {
      events: {
        "click .cancel": "cancelOverride",
        "click .saveAndClose": "saveAndClose",
        "click .save": "saveOverride"
      }
    },
    eventhubEvents: {
      "organization:select": function (data) {
        T1.Location.set("#creatives/videos");
        T1.EventHub.publish("dropdown:organizations", data);
        T1.EventHub.publish("dropdown:concepts:reset");
      },
      "companionCheck:checked": function (data) {
        this.companionStatus = data;
      },
      "dropdown:advertisers": "handleAdvertiserSelect"
    },
    fileUploaderData: {
      inputFields: [{
        name: "key",
        value: "test"
      }],
      formSelector: "video-upload-form",
      placeholder: "Select a video asset",
      action: "append"
    },
    removeCompanionError: function (e) {
      var $parent = $(e.currentTarget).closest(".companion");
      var $errorElement = $parent.find(".error");
      $errorElement.each(function () {
        $(this).parent().removeAttr("original-title");
        $(this).parent().attr("title", "");
        $(this).removeClass("error");
      });
    },
    initialize: function (args) {
      var self = this;
      var mode = args.mode;
      var model = args.model;
      var advertiser = model && model.get("advertiser");
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "creatives.video"], ["view", "details"]]));
      this.advertiserName = advertiser ? advertiser.get("name") : "";
      this.advertiserID = advertiser ? advertiser.get("id") : "";
      this.holdAdParam = "";
      if (!T1Permissions.check("feature", "video")) {
        T1.Location.set("#unauthorized", {
          replace: true
        });
      }
      self.vendorCollection = new Vendors();
      self.autoVendorCollection = new Vendors();
      self.eventCollection = new TrackingEvents();
      self.companionCollection = new Companions();
      self.isEdit = false;
      self.mode = mode;
      self.externalIdentifierFlag = T1Permissions.check("feature", "creatives_video_external_identifier");
      self.actionsConfig.formActions = [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: "#"
      }, {
        label: "preview",
        uuid: "preview",
        class: "btn-primary preview-creative",
        location: "#"
      }, {
        label: "save & continue",
        uuid: "save_continue",
        class: "saveAndContinue btn-primary",
        location: false
      }];
      self.updateModels(args);
    },
    advancedClicked: function () {
      var $drawer = $(".advanced-vid-settings", this.el);
      var $advancedBtn = $drawer.find(".advanced-settings-btn");
      if ($drawer.hasClass("open")) {
        $advancedBtn.html("+ Advanced Settings");
        $advancedBtn.switchClass("adv-btn-on", "adv-btn-off");
        $drawer.find(".form-stack").hide();
        $drawer.removeClass("box-shading");
        $drawer.removeClass("open");
      } else {
        $advancedBtn.html("- Advanced Settings");
        $advancedBtn.switchClass("adv-btn-off", "adv-btn-on");
        $drawer.addClass("open");
        $drawer.find(".form-stack").show();
        $drawer.addClass("box-shading");
      }
    },
    advancedItemClicked: function (e) {
      var $currentTarget = $(e.currentTarget);
      var currentState = $currentTarget.parent().attr("id");
      if ($currentTarget.hasClass("selected")) {
        $currentTarget.removeClass("selected");
      } else {
        $currentTarget.addClass("selected");
      }
      this.handleVariantBtns(currentState);
    },
    handleVariantBtns: function (currentState) {
      var $el = $(this.el);
      var $enableBox = $el.find("#boxEnabled");
      var $disableBox = $el.find("#boxDisabled");
      var $enableBtn = $el.find("#advancedEnable");
      var $disableBtn = $el.find("#advancedDisable");
      if (currentState === "boxEnabled") {
        $disableBox.find(".advanced-item").removeClass("selected");
        $disableBtn.removeClass("disabled");
        $enableBtn.addClass("disabled");
      } else if (currentState === "boxDisabled") {
        $enableBox.find(".advanced-item").removeClass("selected");
        $enableBtn.removeClass("disabled");
        $disableBtn.addClass("disabled");
      } else {
        $enableBtn.addClass("disabled");
        $disableBtn.addClass("disabled");
      }
    },
    groupVariantsBy: function (orig) {
      var newArr = [];
      var variantGroup = {};
      var item;
      for (var i = 0; i < orig.length; i++) {
        item = orig[i];
        if (!(item.sizeGroup in variantGroup)) {
          variantGroup[item.sizeGroup] = {
            group: item.sizeGroup,
            bitrate: [],
            format: [],
            id: [],
            data: []
          };
          newArr.push(variantGroup[item.sizeGroup]);
        }
        variantGroup[item.sizeGroup].bitrate.push(item.bitrateKbps);
        variantGroup[item.sizeGroup].format.push(item.format);
        variantGroup[item.sizeGroup].id.push(item.id);
        variantGroup[item.sizeGroup].data.push({
          bitrate: item.bitrateKbps,
          format: item.format,
          id: item.id
        });
      }
      return newArr;
    },
    loadVariants: function (id) {
      var self = this;
      var vsv = new VideoCreativeVariants({
        id: id
      });
      var details = this.model.get("details");
      this.variantList = [];
      this.disabledVlist = [];
      this.disabledVariants = details && details.get("disabledVariants") ? details.get("disabledVariants") : [];
      vsv.fetch({
        onSuccess: function (data) {
          self.variantList = data.get("variants");
          self.setActiveVariants(self.variantList, self.disabledVariants);
        }
      });
    },
    setActiveVariants: function (variantList, disabledIDs) {
      var self = this;
      if (disabledIDs.length > 0) {
        for (var i = 0; i <= disabledIDs.length; i++) {
          self.setVariantActive(disabledIDs[i], true);
          if (i === disabledIDs.length - 1) {
            self.setVariantBox();
          }
        }
      } else {
        self.setVariantBox();
      }
    },
    setVariantBox: function () {
      var $el = $(this.el);
      var self = this;
      var activeList = [];
      var inactiveList = [];
      var variantList = this.variantList;
      var count = 0;
      var mp4present = false;
      variantList.forEach(function (model) {
        count++;
        model.sizeGroup = `${String(model.width)}*${String(model.height)}`;
        if (model.disabled !== true) {
          activeList.push(model);
        } else {
          inactiveList.push(model);
        }
        if (count >= variantList.length) {
          self.populateBox(activeList, "enable");
          $.map(activeList, function (val) {
            if (val.format === "mp4") {
              mp4present = true;
            }
          });
          if (activeList.length >= 1) {
            $el.find("#variantError").hide();
          } else if (activeList < 1) {
            $el.find("#variantError").show();
          }
          if (activeList.length === 0) {
            self.variantBoxEmpty("active");
          }
          if (mp4present) {
            $el.find("#variantWarning").hide();
          } else {
            $el.find("#variantWarning").show();
          }
          self.populateBox(inactiveList, "disable");
          self.disabledVlist = inactiveList;
          self.handleVariantBtns("none");
          self.checkVariantChanges();
        }
      });
    },
    populateBox: function (variantList, action) {
      var $el = $(this.el);
      var self = this;
      var targetBox = action === "enable" ? $el.find("#boxEnabled") : $el.find("#boxDisabled");
      var subGroup = [];
      var i, key;
      targetBox.html("");
      variantList = self.groupVariantsBy(variantList);
      variantList = _.sortBy(variantList, "group");
      variantList = variantList.reverse();
      variantList.forEach(function (item) {
        targetBox.append(`<div class="advanced-header">${item.group}</div>`);
        subGroup = _.groupBy(item.data, "format");
        for (key in subGroup) {
          if (subGroup.hasOwnProperty(key)) {
            subGroup[key].sort(function (a, b) {
              var x = a.bitrate;
              var y = b.bitrate;
              return x === y ? 0 : x < y ? -1 : 1;
            });
            subGroup[key].reverse();
            for (i = 0; i < subGroup[key].length; i++) {
              targetBox.append(`<div class="advanced-item" id="${subGroup[key][i].id}">Bitrate: ${subGroup[key][i].bitrate} | Format: ${subGroup[key][i].format}</div>`);
            }
          }
        }
      });
    },
    advancedActivate: function (e) {
      var buttonType = e.currentTarget.id;
      var $el = $(this.el);
      var selectedIDs = [];
      var $selectedBox, disable;
      if (buttonType === "advancedDisable") {
        $selectedBox = $el.find("#boxEnabled");
        disable = true;
      } else if (buttonType === "advancedEnable") {
        $selectedBox = $el.find("#boxDisabled");
        disable = false;
      }
      $selectedBox.find(".selected").each(function () {
        selectedIDs.push(Number(this.id));
      });
      for (var i = 0; i <= selectedIDs.length; i++) {
        this.setVariantActive(selectedIDs[i], disable);
        if (i === selectedIDs.length - 1) {
          this.setVariantBox();
        }
      }
    },
    setVariantActive: function (variantID, isEnabled) {
      this.variantList.forEach(function (model) {
        if (variantID === model.id) {
          model.disabled = isEnabled;
        }
      });
    },
    checkVariantChanges: function () {
      var origIDs = this.disabledVariants;
      var newIDs = [];
      this.disabledVlist.forEach(function (model) {
        newIDs.push(model.id);
      });
      if (origIDs.sort().join(",") !== newIDs.sort().join(",")) {
        this.isFormElementsChanged = true;
      }
      if (newIDs.length <= 0) {
        this.variantBoxEmpty("inactive");
      }
    },
    variantBoxEmpty: function (boxType) {
      var $el = $(this.el);
      var boxTarget = boxType === "active" ? "#boxEnabled" : "#boxDisabled";
      $el.find(boxTarget).html('<div class="disabled">No Selections</div>');
    },
    updateModels: function (args) {
      var self = this;
      var vc = {};
      var details, loader, previewAction, previewable;
      var disabledRegex = /^disabled | disabled | disabled$/g;
      var partial = args.partial;
      var $currentEl = $(this.el);
      T1.EventHub.publish("creative:updateModel", {
        model: args.model
      });
      $currentEl.append('<div class="view-loader video-view-loader"></div>');
      $currentEl.find(".details").hide();
      loader = T1.Loader.create({
        target: ".view-loader",
        text: "Loading ...",
        loaderClass: "loader",
        context: $currentEl
      });
      if (args.id) {
        vc = new VideoCreative({
          id: args.id
        });
        this.canLoad = false;
        this.isEdit = true;
        this.isCustomVastUrl = false;
        this.isCustomVastXML = false;
        vc.sync("read", {
          id: args.id
        }, {
          success: function (data) {
            vc.set(data);
            details = data && data.details;
            if (details && details.eventPixels && details.eventPixels.length) {
              self.eventCollection = new TrackingEvents(details.eventPixels);
            }
            self.isSkippableChecked = details.skippableDuration !== undefined;
            self.companionCollection = new Companions();
            self.model = vc;
            self.isUploaded = data.isUploaded;
            self.isSecure = args.model.get("is_https") === 1;
            previewAction = _.findWhere(self.actionsConfig.formActions, {
              uuid: "preview"
            });
            if (previewAction) {
              if (self.isUploaded) {
                previewable = data && data.thumbnail;
              } else if (details.customVAST) {
                previewable = details && details.customVAST;
                self.isCustomVastXML = true;
              } else if (details.customVASTUrl) {
                previewable = details && details.customVASTUrl;
                self.isCustomVastUrl = true;
              }
              if (!previewable) {
                if (!disabledRegex.test(previewAction.class)) {
                  previewAction.class += " disabled";
                  partial = false;
                }
              } else {
                if (disabledRegex.test(previewAction.class)) {
                  previewAction.class = previewAction.class.replace(disabledRegex, " ").trim();
                  partial = false;
                }
              }
            }
            loader.start();
            self.companionCollection.fetch(args.id).then(function () {
              self.canLoad = true;
              if (partial) {
                self.renderPartialTemplate({
                  template: templateBody,
                  context: self.el,
                  targetEl: ".w-body",
                  useTemplateHtml: false
                });
                self.load();
              }
              if (self.isEdit) {
                loader.stop();
                $currentEl.find(".details").show();
                self.getCreativesCollection(args).then(function (videoCreativeObj) {
                  self.videoCreativeObj = videoCreativeObj;
                }).then(self.fetchCreativeStatus(args).then(function (videoFileName) {
                  self.videoFileName = videoFileName;
                  self.load();
                }));
              } else {
                self.load();
                loader.stop();
                $currentEl.find(".details").show();
              }
            });
          },
          dataType: "json",
          processAjaxResponse: function (data) {
            return {
              statusCode: "ok",
              jsonData: data
            };
          }
        }).pipe($.proxy(self.fetchVendorsById, self)).pipe($.proxy(self.fetchAutoVendorsById, self));
      } else {
        this.model = new VideoCreative({
          action: "new"
        });
        this.canLoad = true;
        previewAction = _.findWhere(self.actionsConfig.formActions, {
          uuid: "preview"
        });
        if (previewAction && !disabledRegex.test(previewAction.class)) {
          previewAction.class += " disabled";
        }
        this.load();
      }
    },
    getCreativesCollection: function () {
      var onSuccess, videoCreativeObj;
      var modelId = this.model.id;
      var deferred = $.Deferred();
      var creativesColl = new VideoCreativesCollection({
        id: modelId
      });
      creativesColl.singleFetch = true;
      creativesColl.id = modelId;
      onSuccess = function () {
        videoCreativeObj = creativesColl._byId[modelId].get("details");
        deferred.resolve(videoCreativeObj);
      };
      creativesColl.fetchVideoObj({
        success: onSuccess
      });
      return deferred.promise();
    },
    fetchVendorsById: function () {
      var deferred = $.Deferred();
      var vendorIds = this.model.get("details").get("vendors");
      var self = this;
      var success = function () {
        self.vendorCollection.each(function (model) {
          model.set({
            isChecked: true
          });
        });
        self.vendorCollection.trigger("reset");
        deferred.resolve();
      };
      if (vendorIds && vendorIds.length > 0) {
        this.vendorCollection.isPaginated = false;
        this.vendorCollection.search.set({
          idList: vendorIds,
          fetchRequired: true,
          success: success
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise();
    },
    fetchAutoVendorsById: function () {
      var deferred = $.Deferred();
      var vendorIds = this.model.get("autoVendors");
      var self = this;
      var success = function (data) {
        self.vendListData = data;
        self.autoVendorCollection.trigger("reset");
        deferred.resolve();
      };
      if (vendorIds && vendorIds.length > 0) {
        this.autoVendorCollection.search.set({
          idList: vendorIds,
          fetchRequired: true,
          success: success
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise();
    },
    fetchCreativeStatus: function () {
      var self = this;
      var opts = {};
      var deferred = $.Deferred();
      var videoFileName;
      opts.onSuccess = function (data) {
        videoFileName = data.get("uploadInfo") ? data.get("uploadInfo").name : "--";
        deferred.resolve(videoFileName);
      };
      self.videoCreativeStatus = new VideoCreativeStatus({
        id: self.model.id
      });
      self.videoCreativeStatus.fetch(opts);
      return deferred.promise();
    },
    inVendorArray: function (value, array) {
      var theName;
      array.forEach(function (item) {
        if (value === Number(item.id)) {
          theName = item.get("name");
        }
      });
      return theName;
    },
    groupUrlsBy: function (orig, vendList) {
      var newArr = [];
      var vendorGroup = {};
      var cur, vendorName;
      for (var i = 0; i < orig.length; i++) {
        cur = orig[i];
        vendorName = "";
        if (!(cur.vendors[0] in vendorGroup)) {
          vendorName = cur.vendors[0] ? this.inVendorArray(cur.vendors[0], vendList) : "Unknown vendor";
          vendorGroup[cur.vendors[0]] = {
            group: vendorName,
            groupID: cur.vendors[0],
            urls: []
          };
          newArr.push(vendorGroup[cur.vendors[0]]);
        }
        vendorGroup[cur.vendors[0]].urls.push(cur.url);
      }
      return newArr;
    },
    showUnsecureURLs: function () {
      var $el = $(this.el);
      var $targetDiv = $el.find("#nonSecure");
      var $targetUL = "";
      var list = this.model.get("unsecureUrls") || {};
      var groupList, returnName, vendorName;
      var vendList = this.vendListData;
      if (list.length > 0 || !$.isEmptyObject(list)) {
        $el.find(".unsecure-urls").show();
        if ($.isArray(list)) {
          groupList = this.groupUrlsBy(list, vendList);
          groupList.forEach(function (item) {
            $targetDiv.append(`<div class="vendor-name">${item.group}</div>`);
            $targetDiv.append(`<ul id="id_${item.groupID}"></ul>`);
            $targetUL = $targetDiv.find(`#id_${item.groupID}`);
            for (var i = 0; i < item.urls.length; i++) {
              $targetUL.append(`<li class="secure-list">${item.urls[i]}</li>`);
            }
          });
        } else {
          for (var i in list) {
            if (list.hasOwnProperty(i)) {
              returnName = this.inVendorArray(list[i].id, vendList);
              vendorName = returnName ? returnName : "Unknow vendor";
              $targetDiv.append(`<div class="vendor-name">${vendorName}</div>`);
              $targetDiv.append(`<ul id="id_${list[i].id}"></ul>`);
              $targetUL = $targetDiv.find(`#id_${list[i].id}`);
              for (var j = 0; j < list[i].urls.length; j++) {
                $targetUL.append(`<li class="secure-list">${list[i].urls[j]}</li>`);
              }
            }
          }
        }
      }
    },
    doShowHide: function ($element, doShow) {
      $element[doShow ? "show" : "hide"]("400");
    },
    setEventAction: function (event) {
      if (event.get("typ") && event.get("typ") === "skip") {
        event.unset("typ").unset("url").set({
          action: "new"
        });
      }
    },
    showEl: function (e) {
      var isUpload = e.currentTarget.value === "upload";
      var isVast = e.currentTarget.value === "vast";
      if (!isUpload && !isVast) {
        return;
      }
      this.doShowHide($(".video-upload-assets"), isUpload);
      this.doShowHide($(".vast-tag-c"), isVast);
      if (isVast) {
        _.each(this.eventCollection.models, this.setEventAction);
      }
      T1.EventHub.publish("skippable:change", {
        isVast: isVast,
        isSkippable: isVast ? false : this.isSkippableChecked
      });
      this.doShowHide(this.el.find(".mezzanine-file"), isUpload);
      this.doShowHide(this.el.find(".skippable"), isUpload);
      this.doShowHide(this.el.find(".three-pas-id"), isVast);
    },
    chooseVastType: function (e) {
      var targetEl = $(e.currentTarget).val();
      var $typeTag = $(".typetag", this.el);
      var $typeXML = $(".typexml", this.el);
      var vastTypes = {
        vastxml: function () {
          $typeTag.hide();
          $typeXML.show();
          setTimeout(function () {
            $typeXML.trigger("change");
          }, 1e3);
        },
        vasttag: function () {
          $typeTag.show();
          $typeXML.hide();
          setTimeout(function () {
            $typeTag.trigger("change");
          }, 1e3);
        }
      };
      vastTypes[targetEl]();
    },
    handleAdvertiserSelect: function (data) {
      this.advertiserName = data.model.get("name");
      this.advertiserID = data.model.get("id");
    },
    initLayout: function () {
      var self = this;
      var details = this.model.get("details");
      var advertiser = details.get("advertiser");
      var concept = details.get("concept");
      var createEditLayout;
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        createEditLayout = {
          ".advertiser-select": [{
            module: "shared",
            viewType: "advertiserSelect",
            options: {
              entity: "advertisers",
              selectedId: advertiser,
              dataBind: "advertiser_id",
              boundEntity: "organizations",
              boundEntityId: orgId,
              isSearchable: true,
              initialLoad: true,
              placeholder: "Select an Advertiser",
              preSelect: "Select an Advertiser",
              notFound: "No Advertisers Found",
              refreshEvent: "refresh",
              preRender: function () {},
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              },
              disableCheck: $.proxy(function () {
                return this.isEdit;
              }, this)
            }
          }],
          ".concept-select": [{
            module: "shared",
            viewType: "select",
            options: {
              selectedId: concept,
              fetchSelected: true,
              boundEntityId: advertiser,
              dataBind: "concept_id",
              preSelect: "Default (same as 'Name')",
              notFound: "No Concepts Found",
              placeholder: "Default (same as 'Name')",
              isSearchable: true,
              initialLoad: Boolean(concept),
              extraLinkLabel: "+ Add New Concept",
              extraLinkClass: "extra-link-add clearfix",
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              },
              extraLinkHandler: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                T1.EventHub.publish("popup:show");
              },
              disableCheck: function (count, hasId) {
                return !hasId;
              }
            }
          }],
          ".concept-popup": [{
            module: "concept",
            viewType: "popup",
            options: {
              advertiser_id: advertiser
            }
          }],
          ".date-picker": [{
            module: "creatives",
            viewType: "date_picker",
            options: {
              startDate: details.get("startTime") ? details.get("startTime") * 1e3 : null,
              endDate: details.get("endTime") ? details.get("endTime") * 1e3 : null,
              startDataBind: "startTime",
              endDataBind: "endTime",
              showCheckboxes: true,
              dateFormat: "X",
              showTitle: false,
              showScheduleCheck: true,
              startDateCheckLabel: "Strategy Start Date",
              endDateCheckLabel: "Strategy End Date",
              onChanged: function (data) {
                self.isFormElementsChanged = true;
              }
            }
          }],
          ".event-tracking": [{
            module: "eventTracking",
            viewType: "list",
            options: {
              collection: self.eventCollection,
              isSkippable: self.isSkippableChecked
            }
          }],
          ".vendor-list": [{
            module: "vendor",
            viewType: "boxList",
            options: {
              collection: self.vendorCollection,
              vendorCollection: self.autoVendorCollection,
              showVendorTooltip: true
            }
          }],
          ".auto-vendor-list": [{
            module: "vendor",
            viewType: "autoList",
            options: {
              collection: self.autoVendorCollection,
              isEdit: self.isEdit
            }
          }],
          ".companion-banner": [{
            module: "companion",
            viewType: "list",
            options: {
              collection: self.companionCollection
            }
          }]
        };
        if (self.isEdit) {
          createEditLayout[".strategy-list-body"] = [{
            module: "shared",
            viewType: "strategy_list",
            options: {
              conceptID: String(concept)
            }
          }];
        }
        this.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          partials: {
            warning: templateWarning
          },
          selectorPath: ".details",
          template: templateBody,
          serialize: $.proxy(self.serialize, self),
          layout: createEditLayout
        });
      }, this));
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      if (!this.canLoad) {
        return;
      }
      this.render().then(function () {
        var $fileNameInput, $fileSelector;
        if (self.isEdit) {
          self.loadVariants(self.model.id);
        }
        self.initLayout();
        self.layout.load().then(function () {
          self.companionStatus = self.companionCollection.length !== 0;
          setTimeout(function () {
            if (self.showURLs === 0) {
              self.showUnsecureURLs();
              self.showURLs++;
            }
            if (self.isTranscoded) {
              $el.find(".advanced-vid-settings").show();
            }
            self.reloadAd = _.once(self.reloadDDL);
            self.reloadAd();
          }, 1e3);
          setTimeout(function () {
            $fileSelector = $el.find(".video-upload .file-selector");
            $fileNameInput = $fileSelector.find(".file-name input");
            $fileNameInput.attr("data-bind", "file-name");
            if (self.videoFileName && !self.hasError && self.isUploaded) {
              $fileNameInput.attr("placeholder", self.videoFileName);
              $fileSelector.find(".file-upload-btn").addClass("disabled");
            }
            self.checkCustomVastAdParam();
          }, 0);
          self.fileUploader = T1FileUpload.create({
            $el: $el.find(".video-upload"),
            onChange: function () {
              $fileNameInput.removeClass("error");
              self.uploadWarning = null;
              self.renderPartialTemplate({
                template: templateWarning,
                context: self.el,
                targetEl: ".file-upload-warning",
                useTemplateHtml: false,
                data: self.uploadWarning || {}
              });
            }
          });
          self.fileUploader.render(self.fileUploaderData);
          $el.find(".file-upload-btn").click(function () {
            var $fileName = $el.find(".file-name");
            var $fileInput = $fileName.find("input");
            self.hasError = false;
            self.isFormElementsChanged = true;
            $fileName.removeAttr("original-title");
            $fileInput.removeClass("error");
            $fileInput.attr("disabled", false);
          });
          self.makeTooltips();
          if (self.isCustomVastUrl) {
            $el.find(".typetag").show();
            $el.find(".typexml").hide();
          } else if (self.isCustomVastXML) {
            $el.find(".typetag").hide();
            $el.find(".typexml").show();
          }
          if (!self.isCustomVastUrl && !self.isCustomVastXML && self.isEdit) {
            if (self.callOnce === 0) {
              $el.find(".preview-creative").addClass("disabled");
              self.checkServeStatus();
              self.callOnce++;
            }
          }
        });
      });
    },
    reloadDDL: function () {
      var self = this;
      var $advDDL = $(".advertiser-select", self.el);
      if (!self.isEdit) {
        $advDDL.find("._wrapper").addClass("ad-wrapper");
        $(self.el).not('div[class="ad-wrapper"]').click(function () {
          var $chosenDDL = $advDDL.find("ul");
          if ($chosenDDL.hasClass("search-results")) {
            self.layout.layout[".advertiser-select"][0].view.reloadResults();
            $chosenDDL.removeClass("search-results");
          }
        });
      }
    },
    checkCollection: function () {
      var self = this;
      this.collectReadyCheck.forEach(function (model) {
        var modelDetails = model.get("details");
        if (!modelDetails.get("customVAST") && !modelDetails.get("customVASTUrl") && !modelDetails.get("isUploaded") || modelDetails.get("status") === "error") {
          clearInterval(self.checkReadyInterval);
        }
        if (modelDetails.get("readyToServe")) {
          $(".preview-creative", self.el).removeClass("disabled");
          clearInterval(self.checkReadyInterval);
        }
      });
    },
    checkServeStatus: function () {
      var self = this;
      var limitCount = 0;
      if (this.model.get("readyToServe")) {
        $(".preview-creative", this.el).removeClass("disabled");
      } else {
        this.checkReadyInterval = setInterval(function () {
          self.collectReadyCheck = new VideoCreativesCollection();
          self.collectReadyCheck.search.set({
            fetchRequired: true,
            idList: self.model.id,
            success: function () {
              self.checkCollection();
              limitCount++;
              if (limitCount >= 65) {
                clearInterval(self.checkReadyInterval);
              }
            }
          });
        }, 6e3);
      }
    },
    checkCustomVastAdParam: function () {
      var varValue = this.holdAdParam;
      var $typeXML = $(".typexml", this.el);
      var textValue = $typeXML.val();
      if (varValue && textValue && varValue !== textValue) {
        $typeXML.val(varValue);
      }
    },
    reload: function () {
      this.showURLs = 0;
      this.load();
    },
    makeTooltips: function () {
      var self = this;
      var context = self.el;
      T1.Tooltip("body", {
        fade: false,
        tooltipEle: context.find(".t1-tooltip")
      });
    },
    unload: function () {
      this.showURLs = 0;
      if (this.fileUploader) {
        this.fileUploader.destroy();
      }
      clearInterval(this.checkReadyInterval);
    },
    previewCreative: function (e) {
      var details = this.model.get("details");
      this.preventDefault(e);
      if (this.model.id && details) {
        CreativeUtils.showPreview([this.model.id], "video");
      }
    },
    showVastError: function (tiptarget, message, show) {
      var self = this;
      var cache = self.vastWarning && self.vastWarning.warningMessage;
      var $vastWarning = $(".vast-validation-warning", this.el);
      if (show) {
        self.applyTooltip(tiptarget, "");
        self.vastError = true;
        self.vastWarning = {
          showError: true,
          warningMessage: message,
          cssClasses: "column-A vast-tag-c vast-validation-warning-style",
          tipTarget: tiptarget
        };
        $vastWarning.show();
      } else {
        self.vastError = false;
        self.vastWarning = null;
        $vastWarning.hide();
      }
      if (cache !== (self.vastWarning && self.vastWarning.warningMessage)) {
        self.renderPartialTemplate({
          template: templateWarning,
          context: self.el,
          targetEl: ".vast-validation-warning",
          useTemplateHtml: false,
          data: self.vastWarning || {}
        });
      }
    },
    showVastWarnings: function (messages, show) {
      var self = this;
      var multipleWarnings = messages.length > 1;
      var i, vastWarning;
      for (i = 0; i < messages.length; i++) {
        messages[i] = messages[i].replace("KB", '<a href="https://mediamathsupport.force.com/s/article/Video-Audio-Tab" target="_blank">KB</a>');
      }
      if (show) {
        vastWarning = {
          showWarning: true,
          warningMessage: multipleWarnings ? messages : messages[0],
          multipleWarnings: multipleWarnings
        };
        $(".vast-validation-warning", this.el).show();
        self.renderPartialTemplate({
          template: templateWarning,
          context: self.el,
          targetEl: ".vast-validation-warning",
          useTemplateHtml: false,
          data: vastWarning || {}
        });
      }
    },
    validateVast: function (e) {
      var $el = $(this.el);
      var $target = $(e.currentTarget);
      var vast = $target.val();
      var vastType = $el.find("input[name=vasttype]:checked").val();
      var $typeTag = $el.find(".typetag");
      var $typeXML = $el.find(".typexml");
      var self = this;
      function vastValidator($type, vastXml) {
        VastValidator.validateVast(vastXml).then(function (response) {
          if (response.error) {
            self.showVastError($type, response.error, true);
          } else if (response.warnings && response.warnings.length > 0) {
            self.showVastWarnings(response.warnings, true);
          } else {
            self.showVastError(null, null, false);
          }
        });
      }
      function onSuccess(data) {
        vast = new XMLSerializer().serializeToString(data);
        vastValidator($typeTag, vast);
      }
      function onError() {
        self.vastError = true;
        vastValidator($typeTag, vast);
      }
      if (vastType === "vasttag" && vast !== "") {
        vast = vast && vast.trim();
        if (vast.indexOf("http") > -1) {
          $.get(vast.replace(/^http:\/\//, "https://")).done(function () {
            VideoUtils.fetchCustomVASTData(vast.replace(/^http:\/\//, "https://"), $.proxy(onSuccess, this), onError);
          }).fail(function () {
            $.get(self.cors + encodeURIComponent(vast)).done(function () {
              VideoUtils.fetchCustomVASTData(self.cors + encodeURIComponent(vast), $.proxy(onSuccess, this), onError);
            }).fail(function () {
              vastValidator($typeTag, vast);
            });
          });
        } else {
          vastValidator($typeTag, vast);
        }
      } else if (vast !== "") {
        vastValidator($typeXML, vast);
      } else {
        self.showVastError(null, null, false);
      }
    },
    updateLandings: function (e) {
      var $target = $(e.currentTarget);
      var $landings = $(".landing-page", this.el);
      $landings.val($target.val());
    },
    onSkippableClicked: function (e) {
      var $target = $(e.target);
      var isChecked = $target.is(":checked");
      this.isSkippableChecked = isChecked;
      $target.closest("dl").find("dd").toggleClass("disabled", !isChecked);
      T1.EventHub.publish("skippable:change", {
        isSkippable: this.isSkippableChecked
      });
    },
    preparePostData: function (formValues) {
      var $el = $(this.el);
      formValues = T1.Form.dataAsObject($(".details", $el));
      formValues.advertiser_id = $el.find(".advertiser-select").find("ul").attr("id");
      return formValues;
    },
    handleErrors: function (errors, outModel) {
      var self = this;
      var err, errTarget, errmessage, names;
      $.each(errors, function (i, error) {
        if (error.field) {
          names = error.field.split(".");
          errmessage = error.message;
          if (error.message.length > 100) {
            errmessage = `${error.message.substring(0, 100)}...`;
          }
          if (names[0] === "eventPixels") {
            err = [{
              field: names[2],
              message: error.message
            }];
            errTarget = `.tracking-event[data-index=${names[1]}]`;
          } else if (names[0] === "customVast") {
            err = [{
              field: "vastxml",
              message: errmessage
            }];
            errTarget = ".details";
          } else if (names[0] === "customVASTUrl") {
            err = [{
              field: "vasttag",
              message: errmessage
            }];
            errTarget = ".details";
          } else if (names[1] === "vast") {
            err = [{
              field: "vasttag",
              message: errmessage
            }];
            errTarget = ".details";
          } else {
            err = [{
              field: names[0],
              message: errmessage
            }];
            errTarget = ".details";
          }
          self.showErrors(outModel, err, errTarget);
        }
      });
    },
    postData: function (formValues, successCallback, statusInvalid, conflictCallback) {
      var self = this;
      var model = this.model;
      var id = this.model.get("id");
      var $el = this.el;
      var $videoUpload = $(".video-upload", this.el);
      var $keyInput = $videoUpload.find("input[name=key]");
      var progressLoader = {};
      var progressMessage = {
        progress: true,
        message: "Upload in progress. Please do not navigate away."
      };
      var eventMap, vendorMap;
      var outputModel = new VideoCreativeDetail();
      var uploadModel = new VideoCreativeUpload();
      var uploader = this.fileUploader;
      var variantIDs = [];
      var deferred = $.Deferred();
      self.updateFooterMessage(progressMessage);
      progressLoader = T1.Loader.create({
        count: 100,
        context: $(this.el).find(".progress-holder"),
        loaderClass: "upload-bar",
        type: "progress"
      });
      self.progressLoader = progressLoader;
      function handleSuccess() {
        T1.Notify("message", `Creative ${self.isEdit ? "updated" : "created"} successfully!`);
        self.setEditMode();
        successCallback(model);
        T1.EventHub.publish("creative:updateModel", {
          model: model
        });
      }
      outputModel.set({
        name: formValues.name,
        advertiser: parseInt(formValues.advertiser_id, 10) || this.advertiserID,
        advertiser_id: formValues.advertiser_id || this.advertiserID || "",
        concept: parseInt(formValues.concept_id, 10),
        concept_id: formValues.concept_id,
        clickthroughUrl: formValues.clickthroughUrl,
        landingUrl: formValues.landingUrl,
        externalIdentifier: formValues.externalIdentifier || null,
        desktopEncoding: formValues.desktopEncoding,
        mobileEncoding: formValues.mobileEncoding,
        active: formValues.status === "1",
        viewabilityTemplate: formValues.assets !== "vast" && formValues.viewabilityTemplate || null,
        skippableDuration: formValues.skippable && formValues.assets !== "vast" ? 5 : null
      });
      if (model.get("isUploaded") && this.disabledVlist.length > 0) {
        $el.find("#boxDisabled").find(".advanced-item").each(function () {
          variantIDs.push(Number(this.id));
        });
        outputModel.set({
          disabledVariants: variantIDs
        });
      }
      if (formValues.assets === "vast") {
        var vastType = $el.find("input[name=vasttype]:checked").val();
        if (vastType !== "vasttag") {
          outputModel.set({
            customVAST: formValues.vastxml
          });
        } else {
          outputModel.set({
            customVASTUrl: formValues.vasttag.trim()
          });
        }
        this.isVAST = true;
        delete outputModel.attributes.clickthroughUrl;
      } else {
        if ($el.find("input[name=mezzanineFile]").is(":checked")) {
          outputModel.set({
            vastVersion: 4
          });
        }
      }
      if (self.vendorCollection.models.length) {
        vendorMap = self.vendorCollection.map(function (vendorModel) {
          return vendorModel.get("id");
        });
        outputModel.set({
          vendors: _.compact(vendorMap)
        });
      } else {
        outputModel.set({
          vendors: []
        });
      }
      if (self.eventCollection.models.length) {
        eventMap = self.eventCollection.map(function (eventModel) {
          if (eventModel.get("action") !== "new") {
            return eventModel.toJSON();
          }
        });
        outputModel.set({
          eventPixels: _.compact(eventMap)
        });
      } else {
        outputModel.set({
          eventPixels: []
        });
      }
      new ParallelDeferred(outputModel.createEdit(outputModel.toJSON(), {
        statusInvalid: statusInvalid,
        conflict: conflictCallback
      }), function () {
        var def = $.Deferred();
        var errors = self.eventCollection.validateModels(self.isSkippableChecked);
        var $trackingEvents = $el.find(".tracking-event-list");
        var $event;
        if (errors) {
          $.each(errors, function (index, error) {
            $event = $trackingEvents.find(`.tracking-event[data-id=${error[0].id}]`);
            self.showErrors(self.eventCollection, error, $event);
          });
          statusInvalid([]);
          def.reject();
        } else {
          def.resolve();
        }
        return def;
      }(), function () {
        var def = $.Deferred();
        var errors = self.companionStatus ? self.companionCollection.validateModels() : "";
        var $formEl, errorMessage, field;
        if (formValues["companion-banner"] && errors) {
          _.each(errors[0], function (error) {
            $formEl = $el.find(`.companion[data-id=${error.id}]`);
            field = $formEl.find(`[data-bind="${error.field}"]`);
            errorMessage = error.message;
            if (errorMessage.length > 250) {
              errorMessage = `${error.message.substring(0, 250)}...`;
            }
            self.applyTooltip(field, errorMessage);
          });
          statusInvalid([]);
          def.reject();
        } else {
          def.resolve();
        }
        return def;
      }(), function () {
        var def = $.Deferred();
        var typeVast = $el.find("input[name=vasttype]:checked").val();
        var valvastxml = $el.find(".typexml").val();
        var valvasttag = $el.find(".typetag").val();
        var uploadInputValue = $videoUpload.find("input").val();
        var error;
        if (formValues.assets !== "vast" && !uploadInputValue && !self.isEdit) {
          error = [{
            field: "file-name",
            message: "This is a required field."
          }];
          self.showErrors(outputModel, error, ".details");
          statusInvalid([]);
          def.reject();
        } else if (self.vastError && formValues.assets === "vast") {
          self.vastWarning.tipTarget.addClass("error");
          statusInvalid([]);
          if (self.vastWarning.showError) {
            $el.find(".vast-validation-warning .info-box").addClass("error");
          }
          def.reject();
        } else {
          if (formValues.assets === "vast" && typeVast === "vastxml" && valvastxml.length === 0) {
            error = [{
              field: "vastxml",
              message: "This is a required field."
            }];
            self.showErrors(outputModel, error, ".details");
            statusInvalid([]);
            def.reject();
          } else if (formValues.assets === "vast" && typeVast === "vasttag" && valvasttag.length === 0) {
            error = [{
              field: "vasttag",
              message: "This is a required field."
            }];
            self.showErrors(outputModel, error, ".details");
            statusInvalid([]);
            def.reject();
          } else {
            def.resolve();
          }
        }
        return def;
      }()).then($.noop, function () {
        statusInvalid([]);
      }).pipe(function () {
        if (!formValues.concept_id) {
          self.conceptModel = new Concept({
            action: "new"
          });
          self.conceptModel.save({
            name: formValues.name,
            status: "1",
            advertiser_id: formValues.advertiser_id || self.advertiserID
          }, {
            success: $.proxy(function () {
              outputModel.set({
                concept: Number(self.conceptModel.id)
              });
              formValues.concept_id = Number(self.conceptModel.id);
              deferred.resolve();
            })
          });
          return deferred.promise();
        }
      }).pipe(function () {
        if (formValues.startTime) {
          outputModel.set({
            startTime: formValues.startTime
          });
        }
        if (formValues.endTime) {
          outputModel.set({
            endTime: formValues.endTime
          });
        }
      }).pipe(function () {
        var def = $.Deferred();
        var saveOpts = {
          dataType: "json",
          stringify: true,
          contentType: "application/json",
          processData: false,
          success: function (newModel, attributes) {
            self.showURLs = 0;
            self.callOnce = 0;
            outputModel.set({
              creativeId: attributes.creativeId || model.get("id")
            });
            if (!self.isEdit && !model.get("id")) {
              self.model.set({
                id: attributes.creativeId
              });
            }
            $el.find("#main-header").html(formValues.name);
            if (attributes.status && attributes.status.code === "ok") {
              def.resolve();
            }
          },
          statusInvalid: function (errors) {
            statusInvalid(errors, "There are errors on the page.", ".details");
            self.handleErrors(errors, outputModel);
            def.reject();
          },
          conflict: conflictCallback,
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400, 413, 502]
          },
          error: function (data, response) {
            var errorMessage, errors, jsonData;
            var dataType = "json";
            isJson(response.responseText);
            function isJson(str) {
              try {
                jsonData = JSON.parse(str);
              } catch (e) {
                dataType = "plaintxt";
              }
            }
            if (dataType === "json") {
              if (jsonData.errors && jsonData.errors.field_error) {
                errors = !$.isArray(jsonData.errors.field_error) ? [jsonData.errors.field_error] : jsonData.errors.field_error;
                errors = _.map(errors, function (error) {
                  return {
                    field: error.name.replace(/customVast/g, "vast").replace(/external_identifier/g, "externalIdentifier"),
                    message: error.error
                  };
                });
                self.handleErrors(errors, outputModel);
              }
            } else {
              if (response.status === 502) {
                errorMessage = response.statusText ? response.statusText : response;
              } else {
                errorMessage = response.responseText ? response.responseText : response;
              }
              errors = [{
                field: "vastxml",
                message: errorMessage
              }];
              self.showErrors(outputModel, errors, ".details");
              self.showVastError($el.find(".typexml"), errorMessage, true);
            }
            statusInvalid([]);
            def.reject();
          },
          processAjaxResponse: outputModel.processAjaxResponse
        };
        if (id) {
          outputModel.set({
            id: id
          });
        }
        outputModel.save(outputModel.toJSON(), saveOpts);
        return def.promise();
      }).pipe(function () {
        var def = $.Deferred();
        if (formValues.assets !== "vast") {
          if (self.companionCollection.models.length) {
            return self.companionCollection.batchSaveModels(outputModel.get("creativeId"), {
              onStatusInvalid: statusInvalid,
              showErrors: $.proxy(self.showErrors, self),
              toggleStatus: self.companionStatus
            });
          }
        } else {
          def.resolve();
          return def.promise();
        }
      }).pipe(function () {
        if (formValues.assets !== "vast" && (!self.isUploaded || self.videoCreativeObj.get("status").toLowerCase() === "error")) {
          return uploadModel.getUploadCredentials({
            creativeId: outputModel.get("creativeId")
          });
        }
        handleSuccess();
        return $.Deferred().reject().promise();
      }).pipe(function () {
        var def = $.Deferred();
        var key = uploadModel.get("uploaderInstance").key;
        if (key) {
          $keyInput.val(key);
          def.resolve();
        } else {
          def.reject();
        }
        return def.promise();
      }).pipe(function () {
        var def = $.Deferred();
        return uploader.upload({
          sourceURL: uploadModel.getUploadUrl(),
          dataType: "json",
          timeout: 72e5,
          onSuccess: function (data) {
            var err, name;
            if (data.errors) {
              $.each(data.errors.field_error, function (i, error) {
                name = error.name;
                if (name === "fileName") {
                  err = [{
                    field: "file-name",
                    message: error.error
                  }];
                }
              });
              self.showErrors(self.outputModel, err, ".details");
              self.hasError = true;
              def.reject();
              statusInvalid([]);
            } else {
              handleSuccess();
            }
          },
          uploadProgress: function (event, position, total, percentComplete) {
            progressLoader.progress(percentComplete);
          },
          processAjaxResponse: uploadModel.processAjaxResponse
        });
      });
    },
    setEditMode: function () {
      if (!this.isEdit) {
        T1.EventHub.publish("video_creative:created", this.model);
      } else {
        T1.EventHub.publish("video_creative:updated", this.model);
      }
      this.isEdit = true;
      this.updateModels({
        id: this.model.id,
        partial: true,
        model: this.model
      });
      T1.Location.set(`#creatives/video/${this.model.id}/details`, {
        silent: true
      });
    },
    changeProgress: function () {},
    saveOverride: function (e) {
      var self = this;
      var callback = {
        success: function () {
          if (!self.getSaveAndContinueMode()) {
            self.setEditMode();
          }
        },
        error: function () {}
      };
      return this.save(e, callback);
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          setTimeout(function () {
            T1.Location.set("#creatives/videos");
          }, 1e3);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    cancelCreativeViewEdit: {
      onAlertClose: function () {
        T1.Location.set("#creatives/videos");
      }
    },
    cancelOverride: function (e, fromUnloadAlertDlg) {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      this.preventDefault(e);
      e.stopImmediatePropagation();
      if (fromUnloadAlertDlg === true || !isFormElementsChanged) {
        this.cancelCreativeViewEdit.onAlertClose();
      } else {
        this.openUnloadAlertDialog({
          name: this.isEdit ? self.model && self.model.get("name") || "Video Creative Edit" : "New Video Creative"
        }, this.cancelClassification);
      }
    },
    canUnload: function (args) {
      var self = this;
      var isFormElementsChanged = this.hasError ? false : this.isFormElementsChanged;
      var tagType = "Video Creative";
      var message = this.isEdit ? self.model && self.model.get("name") || `Edit ${tagType}` : `New ${tagType}`;
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: message
        }, args);
      }
      return !isFormElementsChanged;
    },
    noop: function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    },
    serialize: function () {
      var output = this.model.toJSON();
      var warningMessage;
      var durationFormat = "mm:ss";
      var seconds = output.duration * 1e3;
      output.duration = moment.utc(seconds).format(durationFormat);
      output.isEdit = this.isEdit;
      output.isPreview = this.isPreview;
      output.isFileEditable = !this.isEdit || !output.isUploaded;
      output.isSecure = this.isSecure ? "Secure" : "Non-secure";
      output.dateCreated = this.dateCreated;
      output.isSecure = this.isSecure ? "Secure" : "Non-secure";
      output.isExternalIdentifier = this.externalIdentifierFlag;
      if (this.videoCreativeObj) {
        output.isTranscoded = this.videoCreativeObj.get("status").toLowerCase() === "finished";
        this.isTranscoded = output.isTranscoded;
        output.hasError = this.videoCreativeObj.get("status").toLowerCase() === "error" || !output.isUploaded;
        this.hasError = output.hasError;
        output.isTranscoding = this.videoCreativeObj.get("status").toLowerCase() !== "finished" && this.videoCreativeObj.get("status").toLowerCase() !== "error";
      }
      output.showInput = output.action === "new" || output.hasError && !output.details.customVAST && !output.details.customVASTUrl;
      if (this.isEdit) {
        output.advertiser_name = this.advertiserName;
      }
      if (this.isEdit && (output.hasError === true || !output.isUploaded) && !output.details.customVAST && !output.details.customVASTUrl) {
        warningMessage = "There was an error uploading the video asset. Please upload again!";
      }
      if (this.uploadWarning && this.uploadWarning.warningMessage) {
        warningMessage = this.uploadWarning.warningMessage;
      }
      output.uploadWarning = !warningMessage ? null : {
        showError: true,
        warningMessage: warningMessage,
        cssClasses: "column-video video-upload-assets"
      };
      if (!output.details.customVAST && !output.details.customVASTUrl) {
        output.isVideoAssets = true;
      }
      output.isMezzanineFile = output.isVideoAssets && output.details.vastVersion === 4;
      if (output.details.customVAST) {
        this.holdAdParam = output.details.customVAST;
        output.isVastXML = true;
      }
      output.isSkippableChecked = this.isSkippableChecked;
      if (output.action === "new") {
        output.details.active = true;
        output.details.desktopEncoding = true;
        output.details.mobileEncoding = true;
      }
      output.pageTitle = "Video Asset Details";
      if (output.details.name) {
        output.name = output.details.name;
        output.name_id = `${output.details.name} (${output.id})`;
      } else {
        output.name = "Create New";
      }
      if (T1Permissions.check("feature", "creative_video_skippable")) {
        output.isSkippableEnabled = true;
      }
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(DetailsView);
});
