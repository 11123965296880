define('collections/appsT1Users',["jQuery", "Underscore", "T1", "T1Collection", "models/appsT1Users", "models/sessionUser", "T1Sortable"], function ($, _, T1, T1Collection, Model, SessionUser, Sortable) {
  "use strict";

  var sortConfiguration = {
    fields: {}
  };
  var collection = T1Collection.extend({
    canCache: false,
    cacheDuration: 6e4,
    model: Model,
    urlString: "",
    fetchOptions: {
      full: "*",
      pageLimit: 100
    },
    currentSearchConfig: [],
    searchConfiguration: {
      fields: {
        email: {
          type: "enum"
        },
        role: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    url: function (args) {
      var orgID = this.orgID || args.orgID;
      var searchTerm;
      if (this.searchTerm) {
        searchTerm = "<" + this.searchTerm + ">&";
      } else {
        searchTerm = "";
      }
      return T1.API_ROOT + "users?with=organizations&full=*&q=" + searchTerm + "organizations.id==" + orgID;
    },
    filterBy: function (field, term, orgID, success) {
      var self = this;
      var onSuccess = success || $.noop;
      this.orgID = orgID;
      this.search.set({
        params: self.currentSearchConfig,
        fetchRequired: true,
        success: function (data) {
          onSuccess(data);
        }
      });
    },
    fetch: function (opts) {
      T1Collection.prototype.fetch.call(this, opts);
    }
  });
  return T1.Models.AppsT1Users = Sortable.extendCollection(collection, sortConfiguration);
});
