define('models/contract',["jQuery", "Underscore", "T1", "T1Model"], function ($, _, T1, T1Model) {
  T1.Models.Contract = T1Model.extend({
    url: "contracts/",
    fixture: "-contract",
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    validateAttrs: function (attrs) {
      var errors = [];
      var optionalFields = ["spend_cap"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        dmp_fee_cpm: function () {
          if (attrs.dmp_fee_unit && attrs.dmp_fee_unit === "CPM") {
            if (!attrs.dmp_fee_cpm) {
              return requiredField;
            }
          }
          return false;
        },
        dmp_fee_cpts: function () {
          if (attrs.dmp_fee_unit && attrs.dmp_fee_unit === "CPTS") {
            if (!attrs.dmp_fee_cpts) {
              return requiredField;
            }
          }
          return false;
        },
        dmp_fee_pct: function () {
          if (attrs.dmp_fee_unit && attrs.dmp_fee_unit === "PCT_TOTAL_COST") {
            if (!attrs.dmp_fee_pct) {
              return requiredField;
            }
          }
          return false;
        },
        dmp_fee_unit: function () {
          if (attrs.dmp_fee_cpm || attrs.dmp_fee_cpts || attrs.dmp_fee_pct) {
            if (!attrs.dmp_fee_unit) {
              return requiredField;
            }
          }
          return false;
        },
        signals_fee_cpm: function () {
          if (attrs.signals_fee_unit && attrs.signals_fee_unit === "CPM") {
            if (!attrs.signals_fee_cpm) {
              return requiredField;
            }
          }
          return false;
        },
        signals_fee_pct: function () {
          if (attrs.signals_fee_unit && attrs.signals_fee_unit === "PCT_TOTAL_COST") {
            if (!attrs.signals_fee_pct) {
              return requiredField;
            }
          }
          return false;
        },
        signals_fee_unit: function () {
          if (attrs.signals_fee_cpm || attrs.signals_fee_pct) {
            if (!attrs.signals_fee_unit) {
              return requiredField;
            }
          }
          return false;
        },
        t1_apps_fee_cpm: function () {
          if (attrs.t1_apps_fee_unit && attrs.t1_apps_fee_unit === "CPM") {
            if (!attrs.t1_apps_fee_cpm) {
              return requiredField;
            }
          }
          return false;
        },
        t1_apps_fee_pct: function () {
          if (attrs.t1_apps_fee_unit && attrs.t1_apps_fee_unit === "PCT_TOTAL_COST") {
            if (!attrs.t1_apps_fee_pct) {
              return requiredField;
            }
          }
          return false;
        },
        t1_apps_fee_unit: function () {
          if (attrs.t1_apps_fee_cpm || attrs.t1_apps_fee_pct) {
            if (!attrs.t1_apps_fee_unit) {
              return requiredField;
            }
          }
          return false;
        },
        spend_cap: function () {
          if (attrs.spend_cap) {
            if (parseFloat(attrs.spend_cap) > parseFloat(9999999)) {
              return "Spend Cap must be less than or equal to $9999999.00";
            }
          }
          return false;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (attrs.hasOwnProperty("spend_cap")) {
        validationResult = reqFields.spend_cap();
        if (validationResult !== false) {
          errors.push({
            field: "spend_cap",
            message: validationResult
          });
        }
      }
      if (errors.length) {
        return errors;
      }
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      validationErrors = self.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: success,
          statusInvalid: statusInvalid,
          conflict: conflict
        });
      }
    }
  });
  return T1.Models.Contract;
});
