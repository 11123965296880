define('modules/audiences/lookalikes/create/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "audiences/lookalikes/create",
      viewCfgs: {
        lookalikes: {}
      },
      defaultView: "createEdit"
    });
  }
  return instance;
});
