define('models/nemoTarget',["Underscore", "jQuery", "T1", "Backbone"], function (_, $, T1, Backbone) {
  "use strict";

  var nemoTarget = Backbone.Model.extend({
    urlRoot: T1.NEMO_API_BASE + "target",
    parse: function (response) {
      return response.data;
    }
  });
  return nemoTarget;
});
