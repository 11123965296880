define('models/sessionUser',["jQuery", "T1", "T1Comm", "T1Model", "T1UtilsAsync", "utils/UserDataUtils", "models/sessionUserPermissions"], function ($, T1, Comm, T1Model, utilsAsync, UserDataUtils, UserPermissions) {
  var instance;
  var User = T1Model.extend({
    name: "User",
    url: "users/",
    fixture: "-users",
    initialSessionPromise: null,
    initialDataPromise: null,
    initialPermissionsPromise: null,
    initializationPromise: null,
    initialize: function (args) {
      var self = this;
      var sessionPromise;
      T1.EventHub.subscribe({
        "onUserLoginOk.loadUser": function (args) {
          self.loadUser.call(self, args);
        },
        onUserLogoutOk: function () {
          self.clear();
        }
      });
      sessionPromise = window.WebAuth.tokenPromise.then(() => self.detectSession());
      self.initialSessionPromise = sessionPromise;
      self.initialDataPromise = sessionPromise.then(function (promises) {
        return promises.dataPromise;
      });
      self.initialPermissionsPromise = sessionPromise.then(function (promises) {
        return promises.permissionPromise;
      });
      self.initializationPromise = utilsAsync.linkPromises([self.initialDataPromise, self.initialPermissionsPromise]).then(function () {
        UserDataUtils.addData("onUserLoaded", self);
      }).catch($.noop);
    },
    loadUser: function (args) {
      var self = this;
      var userId;
      var promises;
      var dataPromise;
      var permissionPromise;
      if (!args || !args.id) {
        return false;
      }
      userId = args.id || "";
      self.set({
        id: userId
      });
      dataPromise = utilsAsync.makePromise(function (resolve, reject) {
        self.fetch({
          success: function (model, resp) {
            if (resp && resp.status && resp.status.code === "ok") {
              resolve();
            } else {
              reject();
            }
          },
          async: false
        }).then(null, reject);
      });
      permissionPromise = updatePermissions(userId);
      promises = {
        dataPromise: dataPromise,
        permissionPromise: permissionPromise
      };
      return promises;
    },
    detectSession: function () {
      var self = this;
      function successFunction(data) {
        self.set(data, {
          silent: true
        });
        return self.loadUser.call(self, data);
      }
      function errorFunction(sessionResponse) {
        var isSevere = sessionResponse && sessionResponse.status && sessionResponse.status.code === "auth_required" ? false : true;
        return {
          error: new Error("failed to detect session"),
          isSevere: isSevere,
          sessionResponse: sessionResponse
        };
      }
      return utilsAsync.wrapExistingPromiseFunction(function (okWrap, failWrap) {
        Comm.detectSession({
          onSuccess: okWrap(successFunction),
          onError: failWrap(errorFunction)
        });
      });
    }
  });
  function getUser(args) {
    if (instance === undefined) {
      instance = new User(args);
    }
    return instance;
  }
  function updatePermissions(userId, evtString) {
    return utilsAsync.wrapExistingPromiseFunction(function (okWrap, failWrap) {
      UserPermissions.getPermissions().loadPermissions({
        id: userId,
        parseEntities: true
      }).then(function (permissionsModel) {
        var value = getUser().set({
          permissions: permissionsModel.permissions,
          entitiesObj: permissionsModel.entitiesObj,
          partialEntities: permissionsModel.partialEntities
        });
        var resolver = okWrap(null, value);
        T1.EventHub.publish(evtString || "onUserLoaded");
        resolver();
      }, failWrap(null, new Error("failed to load permissions")));
    });
  }
  return {
    getUser: getUser,
    updatePermissions: updatePermissions
  };
});
