define('modules/campaign/pixelSegment/main',["T1Module", "collections/pixels", "collections/customBrainSelections"], function (T1Module, PixelBundles) {
  "use strict";

  return new T1Module({
    name: "campaign/pixelSegment",
    viewCfgs: {
      panel: {
        campaign: null
      },
      list: {
        campaign: null
      },
      bins: {
        sharedCollection: null
      },
      grid: {
        collection: null
      },
      filterSearch: {
        model: null
      },
      readOnly: {
        model: null
      }
    },
    defaultView: "panel",
    getView: function (type, opts) {
      switch (type) {
        case "search":
        case "filterSearch":
          break;
        default:
          {
            const collection = new PixelBundles();
            collection.fetchOptions = Object.assign({
              with: ["advertiser", "agency", "provider"],
              full: "*",
              sort_by: "name"
            }, collection.fetchOptions);
            if (type === "select") {
              collection.search.set({
                params: [{
                  term: "1",
                  searchField: "eligible"
                }],
                fetchRequired: false
              });
            }
            this.viewCfgs[type].collection = collection;
            break;
          }
      }
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
