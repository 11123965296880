define('modules/campaign/bulkedit/strategies/views/browse',["jQuery", "Underscore", "T1", "T1View", "text!modules/campaign/bulkedit/strategies/templates/grid_item.html", "text!modules/campaign/bulkedit/strategies/templates/grid_header.html", "T1GridView", "text!templates/alert.html"], function ($, _, T1, T1View, gridItemTemplate, gridHeader, T1GridView) {
  "use strict";

  var BulkEditStrategiesGrid = T1View.extend({
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Strategies",
        contentClass: "search-loader loader-overrides admin-infinite",
        action: "append"
      }
    },
    sortConfigs: {
      order: "ascending",
      sortBy: function (sortKey, sortOrder) {
        const {
          sortOrder: order,
          collection: collection
        } = this;
        var sortObj = collection.toJSON();
        this.currentSortField = sortKey;
        this.sortOrder = order ? -order : 1;
        if (sortOrder === "descending") {
          sortObj.sort(function (a, b) {
            if (a[sortKey] < b[sortKey]) {
              return -1;
            }
            if (a[sortKey] > b[sortKey]) {
              return 1;
            }
            return 0;
          });
        }
        if (sortOrder === "ascending") {
          sortObj.sort(function (a, b) {
            if (a[sortKey] > b[sortKey]) {
              return -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return 1;
            }
            return 0;
          });
        }
        this.collection.reset(sortObj);
        if (this.sortOrder === -1) {
          sortKey = `-${sortKey}`;
        }
      }
    },
    partials: {
      listItem: gridItemTemplate,
      gridHeader: gridHeader
    },
    events: {
      "click .headers .checkbox": "handleAllChecked",
      "click .item .checkbox": "handleItemChecked"
    },
    dataEvents: {
      collection: {
        reset: "load",
        "change:selected": function (model) {
          this.updateItemCheckbox(model);
          this.updateAllCheckbox(model);
        },
        fetchMoreComplete: "onFetchMoreComplete"
      },
      sharedCollection: {
        add: function () {
          this.updateApplyStrategiesBtn();
        },
        reset: function () {
          this.updateApplyStrategiesBtn();
        },
        remove: function () {
          this.updateApplyStrategiesBtn();
        }
      }
    },
    eventhubEvents: {
      "bulkEditStrategies:showSelected": "onShowSelectedClick",
      "bulkEditStrategies:onSearchRequest": "searchStrategies",
      "bulkEditStrategies:resetStrategies": "resetStrategies"
    },
    initialize: function ({
      campaign: campaign,
      collection: collection,
      sharedCollection: sharedCollection,
      campaignId: campaignId,
      bulkeditor: bulkeditor
    }) {
      this.campaign = campaign;
      this.collection = collection;
      this.sharedCollection = sharedCollection;
      this.campaignId = campaignId;
      this.bulkeditor = bulkeditor;
      this.loadStrategies("-status,-updated_on", "descending");
    },
    load: function () {
      if (this.collection.loaded) {
        this.render();
        this.updateAllCheckbox();
        this.updateApplyStrategiesBtn();
      }
    },
    loadStrategies: function () {
      const collection = this.collection;
      this.setSearchLoader();
      collection.fetchOptions = {
        full: "*"
      };
      collection.urlFilter.set({
        entity: "campaign",
        id: this.campaignId
      });
      collection.fetchAllPages({
        silent: true,
        success: data => {
          if (!this.originalCollection) {
            this.originalCollection = data.toJSON();
          }
          this.collection.each(function (strategyModel) {
            strategyModel.set({
              selected: false
            });
          });
          this.addStagedStrategies();
          this.collectionCount = data.count;
          this.load();
        }
      });
    },
    reload: function () {
      this.loadStrategies("-status,-updated_on", "descending");
    },
    unload: function () {
      this.toggleAllSelected(false);
      this.collection.reset([], {
        silent: true
      });
    },
    serialize: function (inputCollection = this.collection) {
      var list = [];
      var searchTerm = this.searchTerm;
      const isSearchResult = searchTerm && searchTerm !== "";
      const highlightText = T1.Utils.highlightText;
      const models = inputCollection.toJSON();
      function processData({
        id: id,
        name: name,
        type: type,
        status: status,
        selected: selected
      }) {
        return {
          entityID: id,
          name: isSearchResult ? highlightText(name, searchTerm) : name,
          id: id,
          type: type,
          status: status === "1" ? "Active" : "Inactive",
          is_active: status === "1",
          selected: selected
        };
      }
      for (let i = 0; i < models.length; i++) {
        list.push(processData(models[i]));
      }
      return {
        list: list,
        ascending: this.sortOrder === 1,
        isNameSortArrowVisible: this.currentSortField === "name",
        isIDSortArrowVisible: this.currentSortField === "id",
        isTypeSortArrowVisible: this.currentSortField === "type",
        isStatusSortArrowVisible: this.currentSortField === "status",
        count: this.countString()
      };
    },
    countString: function () {
      var collectionCount = this.collectionCount;
      const selected = _.filter(this.originalCollection, model => model.selected === true);
      return `(${selected.length}/${collectionCount})`;
    },
    handleItemChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      const $target = $(e.target);
      const id = $target.data("id");
      const model = this.collection.get(id);
      const selected = model.get("selected");
      const sharedCollectionModel = this.sharedCollection.get(id);
      model.set({
        selected: !selected
      });
      this.syncOrigianlCollection(model);
      if (sharedCollectionModel && sharedCollectionModel.get("selected") === true) {
        this.sharedCollection.remove(sharedCollectionModel);
      } else {
        this.sharedCollection.add(model.clone());
      }
      if (Boolean(this.sharedCollection.length) && this.sharedCollection.length === this.collection.length) {
        this.isSelectAllChecked = true;
        this.sharedCollection.isSelectAllChecked = true;
      } else {
        this.isSelectAllChecked = false;
        this.sharedCollection.isSelectAllChecked = false;
      }
      this.changeCountText();
    },
    changeCountText: function () {
      const $count = this.$(".strategies-count");
      const count = this.countString();
      $count.text(count);
    },
    handleAllChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      const selected = this.getSelected();
      if (!selected.length && this.searchTerm && this.searchTerm !== "") {
        if (!this.collection.length) {
          return;
        }
      }
      if (this.collection.length === selected.length) {
        this.isSelectAllChecked = false;
        this.toggleAllSelected(false);
        if (this.el.parents().find("#showSelected")[0].value) {
          this.el.parents().find("#showSelected")[0].value = false;
          this.searchTerm = undefined;
          this.collection.reset(this.originalCollection);
          this.showSelectedClicked = false;
        }
      } else {
        this.isSelectAllChecked = true;
        this.toggleAllSelected(true);
      }
    },
    toggleAllSelected: function (isSelected) {
      this.collection.each(model => {
        model.set({
          selected: isSelected
        });
        this.syncOrigianlCollection(model);
      });
      if (!isSelected) {
        this.sharedCollection.reset([]);
      } else {
        _.each(this.collection.models, model => {
          this.sharedCollection.add(model.clone());
          model.set({
            selected: true
          });
        });
      }
      this.changeCountText();
    },
    updateItemCheckbox: function (model) {
      const $checkbox = this.$(`#id${model.get("id")} .checkbox`);
      if (!$checkbox.hasClass("disabled")) {
        if (model.get("selected")) {
          $checkbox.addClass("checked");
        } else {
          $checkbox.removeClass("checked");
        }
      }
    },
    updateAllCheckbox: function () {
      const $allCheckBox = this.$(".header .checkbox");
      const $count = this.$(".strategies-count");
      const selected = this.getSelected();
      var outClass = "";
      if (Boolean(selected.length) && selected.length === this.collection.length) {
        outClass = "checked";
        $count.text(this.countString(true));
        $allCheckBox.removeClass("disabled");
      } else if (selected.length) {
        outClass = "partial";
        $allCheckBox.removeClass("disabled");
      } else if (!selected.length && this.searchTerm && this.searchTerm !== "") {
        $allCheckBox.attr("disabled", true);
        $allCheckBox.addClass("disabled");
      }
      $allCheckBox.removeClass("checked partial").addClass(outClass);
    },
    updateApplyStrategiesBtn: function () {
      var sharedSelected = this.getOriginalSelected();
      var selected = this.getSelected();
      const $applyStrategiesBtn = this.el.parents().find(".stage-btn-primary");
      if ($applyStrategiesBtn && this.sharedCollection) {
        if (selected.length || sharedSelected.length) {
          $applyStrategiesBtn.removeClass("disabled");
        } else {
          $applyStrategiesBtn.addClass("disabled");
        }
      }
    },
    getSelected: function () {
      const selectedModels = this.collection.filter(model => model.get("selected") === true);
      return selectedModels;
    },
    getSharedSelected: function () {
      const selectedModels = this.sharedCollection.filter(model => model.get("selected") === true);
      return selectedModels;
    },
    getOriginalSelected: function () {
      const selectedModels = this.originalCollection.filter(model => model.selected === true);
      return selectedModels;
    },
    resetStrategies: function () {
      const $searchText = this.el.parents().find(".searchTxt");
      $searchText.html("");
      this.searchTerm = undefined;
      this.collection.reset(this.originalCollection);
    },
    syncOrigianlCollection: function (model) {
      var orgColl = this.originalCollection;
      var modelId = model.get("id");
      for (let i = 0; i < orgColl.length; i++) {
        const orgCollId = orgColl[i].id;
        if (orgCollId === modelId) {
          orgColl[i].selected = model.get("selected");
        }
      }
    },
    addStagedStrategies: function () {
      const stagedStrategies = this.bulkeditor.get("stagedStrategies");
      if (stagedStrategies && stagedStrategies.length) {
        this.collection.each(model => {
          const spliceIndex = stagedStrategies.indexOf(model.get("id"));
          if (spliceIndex !== -1) {
            stagedStrategies.splice(spliceIndex, 1);
            model.set({
              selected: true
            });
            const clonedModel = model.clone();
            this.syncOrigianlCollection(model);
            this.sharedCollection.add(clonedModel);
          }
        });
      }
    },
    syncCollection: function (coll = this.collection) {
      var sharedColl = this.sharedCollection;
      var sharedModels = sharedColl.models;
      for (let i = 0; i < sharedModels.length; i++) {
        const orgCollID = sharedModels[i].id;
        if (coll.get(orgCollID)) {
          const model = coll.get(orgCollID);
          model.set({
            selected: sharedModels[i].get("selected")
          });
        }
      }
    },
    onShowSelectedClick: function (data) {
      var selectedStrategies = this.getOriginalSelected();
      const $headerCheckbox = this.$(".header .checkbox");
      var outClass = "";
      this.showSelectedClicked = true;
      this.searchTerm = undefined;
      this.setSearchLoader();
      T1.EventHub.publish("bulkEditStrategies:clearSearchField");
      if (data) {
        this.collection.loaded = true;
        this.collection.reset(selectedStrategies);
      } else {
        this.collection.loaded = true;
        this.collection.reset(this.originalCollection);
      }
      if (Boolean(selectedStrategies.length) && selectedStrategies.length === this.collection.models.length) {
        outClass = "checked";
      } else if (selectedStrategies.length) {
        outClass = "partial";
      }
      $headerCheckbox.removeClass("checked partial").addClass(outClass);
    },
    uncheckSelectedChkb: function () {
      const $uncheckSelectedChkb = this.el.parents().find("#showSelected");
      $uncheckSelectedChkb[0].checked = false;
    },
    clearSearchInput: function () {
      const $searchInput = this.el.parents().find("#searchInput");
      $searchInput[0].value = "";
      this.searchTerm = undefined;
    },
    searchStrategies: function (searchValue) {
      const $searchText = this.el.parents().find(".searchTxt");
      const searchModels = this.originalCollection.filter(function (item) {
        return item.name.toLowerCase().includes(searchValue.term.toLowerCase());
      });
      const searchTxt = `${searchModels.length} search results with '${searchValue.term}'`;
      this.searchTerm = searchValue.term;
      this.uncheckSelectedChkb();
      if (searchValue.term === "") {
        $searchText.html("");
        this.collection.reset(this.originalCollection);
      } else {
        $searchText.html(searchTxt);
        this.collection.reset(searchModels);
      }
    },
    onFetchMoreComplete: function () {
      if (this.originalCollection.length < this.collection.count) {
        this.originalCollection = this.collection.toJSON();
      }
      if (this.isSelectAllChecked) {
        this.toggleAllSelected(true);
      }
    }
  });
  return T1GridView(BulkEditStrategiesGrid);
});
