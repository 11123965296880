define('models/strategyDealGroup',["jQuery", "moment", "T1", "T1Model"], function ($, moment, T1, T1Model) {
  "use strict";

  T1.Models.DealGroup = T1Model.extend({
    urlString: "strategies",
    apiRoot: "/api/v2.0/",
    parse: function ({
      id: id,
      type: type
    }) {
      return {
        id: id,
        entity_type: type
      };
    }
  });
  return T1.Models.DealGroup;
});
