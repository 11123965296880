define('modules/reporting/campaigns/siteTransparency/views/controls',["jQuery", "Underscore", "T1", "T1View", "text!../templates/controls.html", "T1Layout", "T1Permissions", "T1Animate"], function ($, _, T1, T1View, template, T1Layout, T1Permissions, T1Animate) {
  "use strict";

  var T1Publish = T1.EventHub.publish;
  var ControlsView = T1View.extend({
    selectedColumns: null,
    template: template,
    events: {
      "changed .search": "searchData",
      "click .columns": "showColumnSelector",
      "click .site-list": "showSiteList",
      "click .toggle-btn": "toggleChart",
      "keyup input": "searchStrategyInGrid"
    },
    dataEvents: {
      reportModel: {
        "change:columnKeys": "updateMetadataSortKeys",
        "change:sortKeyInfo": "resortCollection",
        "change:campaignBenchmarkOn": function () {
          T1Publish("preference:change", {
            key: "campaignBenchmarkOn",
            value: String(this.reportModel.get("campaignBenchmarkOn"))
          });
        }
      }
    },
    initialize: function (args) {
      var models = args.models;
      this.cfgs = args;
      this.type = args.type;
      this.collection = args.collection;
      this.mainMeta = models.mainMeta;
      this.reportMeta = models.reportMeta;
      this.mainModel = models.mainModel;
      this.reportModel = models.reportModel;
      this.campaign = models.campaign;
      this.timeSeries = "summary";
    },
    load: function () {
      var self = this;
      var mainMeta = this.mainMeta;
      var reportMeta = this.reportMeta;
      var mainModel = this.mainModel;
      var reportModel = this.reportModel;
      var mainMetaObj = $.extend({}, mainMeta.toJSON());
      var reportMetaObj = $.extend({}, reportMeta.toJSON());
      var reportModelObj = $.extend({}, reportModel.toJSON());
      var preferences = reportModelObj.preferences;
      var focus = reportModelObj.focus || "all";
      var getFocusKey = reportModel.makeFocusKeyFunc(focus);
      var selectedColumns = preferences.get(getFocusKey("selectedColumns"));
      var sortKeyInfo = preferences.get(getFocusKey("sortKeyInfo"));
      var campaignBenchmarkOn = preferences.get("campaignBenchmarkOn");
      var campaignGoalType = mainModel.get("multiCampaigns") ? "multi" : this.campaign.get("goal_type");
      var editAccess = mainModel.get("strategyAccess") === "edit";
      if (editAccess) {
        this.accessLevel = 2;
      } else {
        this.accessLevel = 0;
      }
      reportModel.setColumnKeys(selectedColumns === undefined || selectedColumns === "" ? reportModelObj.defaultColumnKeys : selectedColumns.split(","));
      reportModel.setSortKeyInfo(sortKeyInfo === undefined || sortKeyInfo === "" ? reportMeta.get("defaultSortForGoalType")[campaignGoalType] || "" : sortKeyInfo);
      reportModel.setCampaignBenchmarkState(campaignBenchmarkOn === undefined || campaignBenchmarkOn === "" ? true : campaignBenchmarkOn === "true");
      this.mainMetaObj = mainMetaObj;
      this.reportMetaObj = reportMetaObj;
      this.reportModelObj = reportModelObj;
      this.getFocusKey = getFocusKey;
      this.updateMetadataSortKeys();
      this.chartShown = this.mainModel.get("chartShown") === "true";
      this.render().then(function () {
        self.chartCover = $(self.el).find(".chart-cover");
        self.reportControls = $(self.el).closest(".report-controls");
        self.$searchWarning = self.el.find(".search-warning");
      });
    },
    unload: function () {
      this.chartCover = null;
      this.reportControls = null;
    },
    prepareMultiSortableData: function () {
      var availableCols, availableKeys;
      var self = this;
      var reportModelObj = this.reportModelObj;
      var reportMeta = this.reportMeta;
      var allColumnsObj = $.extend({}, reportMeta.additionalData.columns[reportModelObj.focus]);
      var selectedKeys = this.selectedColumns ? this.selectedColumns.split(",") : reportModelObj.defaultColumns;
      var selectedCols = [];
      _.each(allColumnsObj, function (obj, prop) {
        if (obj.onlyViewableInCampaignCurrency) {
          delete allColumnsObj[prop];
        }
        if (obj.onlyInPerformance) {
          delete allColumnsObj[prop];
        }
        if (obj.featureFlag && !T1Permissions.check("feature", obj.featureFlag)) {
          delete allColumnsObj[prop];
        }
        if (obj.section && !obj.section.includes(reportModelObj.key)) {
          delete allColumnsObj[prop];
        }
      });
      _.each(selectedKeys, function (obj) {
        var key = self.selectedColumns ? obj : obj.value;
        if (allColumnsObj[key]) {
          delete allColumnsObj[key];
        } else {
          return;
        }
        selectedCols.push({
          text: reportMeta.getFriendlyName("metrics", key),
          value: key
        });
      });
      availableKeys = _.keys(allColumnsObj);
      availableCols = _.map(availableKeys, function (key) {
        return {
          text: reportMeta.getFriendlyName("metrics", key),
          value: key
        };
      });
      this.availableColumnsArray = availableCols;
      this.selectedColumnsArray = selectedCols;
    },
    initColumnSelectorLayout: function () {
      var self = this;
      const {
        onFormClose: onFormClose,
        saveColumns: saveColumns
      } = this;
      this.columnSelectorLayout = new T1Layout({
        el: function () {
          return $(self.el).find(".column-selector-dialog");
        },
        template: '<div class="column-selector"></div>',
        layout: {
          ".column-selector": [{
            module: "reporting/campaigns/siteTransparency",
            viewType: "columnSelector",
            options: {
              listA: self.availableColumnsArray,
              listB: self.selectedColumnsArray,
              onCloseCallback: function (obj) {
                if (obj) {
                  saveColumns.call(self, obj);
                }
                onFormClose.call(self);
              }
            }
          }]
        }
      });
    },
    saveColumns: function (obj) {
      var listB = obj.listB;
      T1Publish("preference:change", {
        key: this.getFocusKey("selectedColumns"),
        value: listB.toString()
      });
      this.reportModel.setColumnKeys(listB);
    },
    updateMetadataSortKeys: function () {
      var reportModelObj = $.extend({}, this.reportModel.toJSON());
      var sortKeyInfo = reportModelObj.sortKeyInfo;
      var sortKeyArray = sortKeyInfo ? sortKeyInfo.split(",") : [];
      var columnKeys = reportModelObj.columnKeys;
      var sortKeyIndexInColumnKeys = sortKeyArray.length > 0 ? $.inArray(sortKeyArray[0], columnKeys) : -1;
      if (sortKeyIndexInColumnKeys === -1) {
        this.reportModel.setSortKeyInfo(columnKeys.length === 0 ? "Strategy_Name,1,1" : `${columnKeys[0]},-1`);
      }
    },
    resortCollection: function (reportModel) {
      var sortKeyArray;
      var sortKeyInfo = reportModel.get("sortKeyInfo");
      const TIMES_SERIES = 2;
      if (sortKeyInfo && sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        this.collection.sortByKey(sortKeyArray[0], parseInt(sortKeyArray[1], 10), this.timeSeries, false, parseInt(sortKeyArray[TIMES_SERIES], 10));
      }
      T1Publish("preference:change", {
        key: this.getFocusKey("sortKeyInfo"),
        value: sortKeyInfo
      });
    },
    onFormClose: function () {
      delete this.columnSelectorLayout.layout[".column-selector-dialog"];
    },
    showColumnSelector: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.selectedColumns = this.reportModel.get("preferences").get(this.getFocusKey("selectedColumns"));
      this.prepareMultiSortableData();
      this.initColumnSelectorLayout();
      this.columnSelectorLayout.load();
    },
    showSiteList: function (evt) {
      if (!$(evt.currentTarget).hasClass("disabled")) {
        T1Publish("controls.showSiteList");
      }
    },
    searchStrategyInGrid: function (event) {
      var inputText;
      const ENTER_KEY = 13;
      switch (event.keyCode) {
        case ENTER_KEY:
          inputText = $(event.currentTarget).val();
          event.preventDefault();
          T1Publish(`reporting_${this.timeSeries}:search`, {
            term: inputText
          });
          break;
        case "default":
      }
    },
    toggleChart: function () {
      var self = this;
      var chartShown = this.chartShown;
      const ANIM_DELAY = 140;
      T1Animate({
        duration: 400,
        easing: "ease",
        options: {
          marginTop: chartShown ? "-4px" : "350px"
        },
        target: this.reportControls,
        complete: function () {
          T1Publish("chart.toggleEnd");
          T1Publish("track:event", {
            action: "Toggle chart",
            label: self.chartShown ? "show" : "hide"
          });
        }
      });
      setTimeout(function () {
        self.chartCover.toggle().removeClass("no-display");
        self.renderPartial(".toggle-btn");
      }, ANIM_DELAY);
      this.chartShown = !this.chartShown;
      T1Publish("chart.toggle", this.chartShown);
    },
    searchData: function (event) {
      var value = event.currentTarget.value;
      var length = value ? value.length : 0;
      var $searchWarning = this.$searchWarning;
      var reportModel = this.reportModel;
      const MIN_SEARCH_LENGTH = 3;
      if (value && length) {
        if (length < MIN_SEARCH_LENGTH) {
          $searchWarning.removeClass("no-display");
          reportModel.setSearchTerm("");
        } else {
          $searchWarning.addClass("no-display");
          reportModel.setSearchTerm(value);
          T1Publish("grid.searchData", value);
        }
      } else {
        $searchWarning.addClass("no-display");
        reportModel.setSearchTerm("");
        T1Publish("grid.resetSearch", "");
      }
    },
    serialize: function () {
      var chartShown = this.chartShown;
      return {
        btnLabel: chartShown ? "hide chart" : "show chart",
        btnState: chartShown ? "hide" : "show",
        chartHidden: !chartShown,
        showSiteListManageBtn: this.accessLevel > 0
      };
    }
  });
  return ControlsView;
});
