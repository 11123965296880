define('modules/creatives/display/views/createBulk3PASReview',["jQuery", "Underscore", "When", "T1", "models/advertiser", "T1View", "T1Layout", "text!../templates/createBulk3PASReview.html", "text!../templates/createBulk3PASReviewGrid.html", "T1Form", "T1Notifier"], function ($, _, When, T1, Advertiser, T1View, T1Layout, template, invalidGridTemplate) {
  "use strict";

  var Bulk3PASCreateReview;
  function updateBreadCrumb() {
    var pathPieces = [{
      label: "New Bulk 3PAS Ad Tag (Step 2 of 2)",
      location: "creatives/display-bulk-3pas-review"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  Bulk3PASCreateReview = T1View.extend({
    template: template,
    isFormElementsChanged: false,
    initialize: function () {
      var self = this;
      if (_.isEmpty(this.model.attributes)) {
        T1.Location.set("creatives/display-bulk");
        return;
      }
      self.loadCompleted = false;
      self.isFormElementsChanged = true;
      self.actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel",
          location: "#"
        }, {
          label: "Re-Upload File",
          class: "btn-primary",
          location: "#creatives/display-bulk"
        }]
      };
      self.updateModels().then(function () {
        self.loadCompleted = true;
        self.initLayout();
        self.load();
      });
    },
    updateModels: function () {
      var self = this;
      var deferred = When.defer();
      var advertiser = new Advertiser({
        id: self.model.get("advertiser_id")
      });
      advertiser.fetch({
        params: {
          with: ["agency"]
        },
        success: function (data) {
          self.model.set({
            advertiser: data
          });
          deferred.resolve(self.model);
        }
      });
      return deferred.promise;
    },
    initLayout: function () {
      var self = this;
      updateBreadCrumb();
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        }
      });
    },
    getAdvertiserName: function () {
      var advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        return advertiser.get("name");
      }
      return null;
    },
    getAgencyName: function () {
      var advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
      if (advertiser && advertiser.get) {
        return advertiser.get("agency").get("name");
      }
      return null;
    },
    load: function () {
      var self = this;
      var $el = self.el;
      var hasData = !_.isEmpty(this.model.attributes);
      var invalidTagsList = self.model.get("invalid_placement");
      var $invalidGrid;
      if (!self.loadCompleted) {
        return;
      }
      this.render().then(function () {
        $invalidGrid = $el.find("#invalid-bulk-grid");
        if (hasData && $invalidGrid) {
          $invalidGrid[0].data = invalidTagsList;
        }
      });
    },
    render: function () {
      var self = this;
      var $el = self.el;
      var $bulk3PASGridContainer;
      var deferred = $.Deferred();
      T1View.prototype.render.call(this).then(function () {
        $bulk3PASGridContainer = $el.find(".bulk-3pas-grid-container");
        Polymer.dom($bulk3PASGridContainer[0]).innerHTML = invalidGridTemplate;
        self.buildPermissionedEvents();
        self.bindViewEvents();
      });
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    serialize: function () {
      var hasData = !_.isEmpty(this.model.attributes);
      var returnObj = {};
      if (hasData) {
        var resultData = this.model.get("result_data");
        var totalTags = resultData.batch.total;
        var invalidTagsList = this.model.get("invalid_placement");
        returnObj.invalidCount = invalidTagsList && invalidTagsList.length;
        returnObj.totalCount = totalTags;
        returnObj.agencyAndAdvertiser = this.getAgencyName() ? `${this.getAgencyName()}&nbsp;&raquo;&nbsp;${this.getAdvertiserName()}` : "";
      }
      return returnObj;
    }
  });
  return T1.Form.ViewUtils.extend(Bulk3PASCreateReview);
});
