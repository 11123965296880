define('modules/shared/views/gridSorter',["jQuery", "Underscore", "T1", "T1View"], function ($, _, T1, T1View) {
  var listView = T1View.extend({
    currentSort: {},
    newOrderBy: undefined,
    parentEl: function () {
      return $(".w-DataGrid");
    },
    initialize: function (args) {
      var self = this;
      this.collection = args.collection;
      this.sortableHeaders = args.sortableHeaders;
      this.onSort = args.onSort;
      this.onSortFinished = args.onSortFinished;
    },
    sort: function (e) {
      var self = this;
      var $currentTarget = $(e.currentTarget);
      var name = $currentTarget.data("sorter-name");
      var headerInfo = self.sortableHeaders[name];
      var fieldName = headerInfo.fieldName;
      var currentOrderBy;
      var newOrderBy;
      if (e.target.tagName.toLowerCase() === "a") {
        return;
      }
      if (self.currentSort && self.currentSort.name === name) {
        currentOrderBy = self.currentSort.options.order_by;
      }
      if (headerInfo.order_by) {
        currentOrderBy = headerInfo.order_by;
      }
      self.currentSort = {
        name: name,
        options: headerInfo
      };
      if (currentOrderBy === "descending") {
        newOrderBy = "ascending";
        self.currentSort.options.order_by = "ascending";
      } else {
        newOrderBy = "descending";
        fieldName = "-" + fieldName;
        self.currentSort.options.order_by = "descending";
      }
      self.collection.fetchOptions = $.extend(true, this.collection.fetchOptions, {
        sort_by: fieldName,
        full: "*"
      });
      self.onSort();
      self.newOrderBy = newOrderBy;
      self.collection.refreshOnNextFetch();
      self.collection.fetch({
        success: function () {
          if (self.onSortFinished) {
            self.onSortFinished();
          }
        }
      });
    },
    addSorterArrow: function (orderBy) {
      var $div = $("<div></div>", {
        class: "sorter-arrow"
      });
      var $a = $("<a></a>", {
        class: "order-icon"
      });
      var $el = this.currentSort.options.$el.find(".header-c");
      var width = $el.width();
      var marginLeft = this.currentSort.options.marginLeft;
      $(".sorter-arrow").remove();
      $a.addClass(orderBy);
      $div.append($a);
      $div.css({
        display: "inline-block",
        height: "11px",
        position: "absolute"
      });
      if (marginLeft) {
        $div.css({
          "margin-left": marginLeft + "px"
        });
      } else {
        $div.css({
          "margin-left": "3px"
        });
      }
      $el.append($div);
    },
    load: function () {
      var self = this;
      var $parent = self.parentEl();
      var $header;
      $.each(this.sortableHeaders, function (index, val) {
        if (val.fieldName === undefined) {
          val.fieldName = index;
        }
        $header = $parent.find(".column.header." + index);
        $header.addClass("sort");
        $header.css({
          cursor: "pointer"
        });
        $header.attr("data-sorter-name", index);
        val.$el = $header;
      });
      $(".sort").off("click").on("click", function (e) {
        self.sort(e);
      });
      if (self.newOrderBy) {
        self.addSorterArrow(self.newOrderBy);
      }
    }
  });
  return listView;
});
