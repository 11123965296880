define('models/timer',["T1"], function (T1) {
  function TimeLogger(category, variable, opt_label) {
    this.category = category;
    this.variable = variable;
    this.label = opt_label ? opt_label : undefined;
    this.startTime;
    this.endTime;
    return this;
  }
  TimeLogger.prototype.start = function () {
    this.startTime = new Date().getTime();
    return this.startTime;
  };
  TimeLogger.prototype.stop = function () {
    this.endTime = new Date().getTime();
    this.timeElapsed = this.endTime - this.startTime;
    return this.timeElapsed;
  };
  TimeLogger.prototype.send = function () {
    var timeSpent = this.timeElapsed;
    if (!timeSpent) {
      return -1;
    }
    T1.Analytics.logTiming(this.category, this.variable, timeSpent, this.label, 100);
  };
  TimeLogger.prototype.log = function () {
    var timeSpent = this.timeElapsed;
    if (timeSpent) {
      this.send();
      return timeSpent;
    } else {
      return -1;
    }
  };
  return TimeLogger;
});
