define('modules/creatives/componentBased/views/createEditBulkTableSubmit',["jQuery", "Underscore", "Backbone", "When", "T1", "T1Comm", "T1View", "T1Layout", "models/componentCreative", "text!../templates/createEditBulkTableSubmitBody.html", "T1Loader"], function ($, _, Backbone, When, T1, T1Comm, T1View, T1Layout, ComponentCreative, template) {
  "use strict";

  var ComponentCreateEditTableSubmit = T1View.extend({
    template: template,
    creativesData: {},
    initialize: function (args) {
      var self = this;
      self.progressMap = {};
      self.avgDuration = 0;
      self.uploadableCreatives = args.uploadableCreatives;
      self.gridData = _.map(self.uploadableCreatives, function (creative, i) {
        creative.gridDataIndex = i;
        creative.gridData.index = i;
        creative.gridData.progress = "0";
        return creative.gridData;
      });
    },
    events: {},
    eventhubEvents: {
      "component:pauseBulkUpload": "handlePaused",
      "component:restartPausedBulkUpload": "handleRestartPaused",
      "component:cancelBulkUpload": "handleCancel",
      "component:bulkUploadCreativeUploadProgress": "handleBulkUploadCreativeUploadProgress"
    },
    submitCreatives: function (creativesToUpload) {
      var self = this;
      self.completed = self.completed || 0;
      self.successes = self.successes || 0;
      self.creativesToUpload = creativesToUpload || self.uploadableCreatives;
      var promisesObj = {};
      var conceptPromises = [];
      _.each(self.creativesToUpload, function (creative) {
        conceptPromises.push(self.createAndAssignConcepts(creative).then(function () {
          promisesObj[creative.gridDataIndex] = function (c) {
            return self.initAttemptSave(c);
          }(creative);
        }));
      });
      var promiseChain = When(true);
      When.all(conceptPromises).then(function () {
        var promises = _.values(promisesObj);
        _.each(promises, function (f) {
          promiseChain = promiseChain.then(f);
        });
      });
      return promiseChain;
    },
    createAndAssignConcepts: function (creative) {
      var self = this;
      var def = When.defer();
      var newConcept;
      if (creative.newConcept) {
        newConcept = creative.newConcept;
        if (!newConcept.created) {
          createConcept(newConcept);
          newConcept.created = true;
        } else {
          def.resolve();
        }
      } else {
        def.resolve();
      }
      function createConcept(conceptModel) {
        var attrs = conceptModel.attributes;
        conceptModel.save(attrs, {
          success: function (concept) {
            var conceptJson = concept.toJSON();
            var creativesWithConcept = self.creativesToUpload.filter(function (c) {
              var currConcept = c.newConcept;
              var currConceptId = currConcept && currConcept.id;
              return currConceptId === concept.id;
            });
            for (var j = 0; j < creativesWithConcept.length; j++) {
              creativesWithConcept[j].attributes.concept_id = conceptJson.id;
              creativesWithConcept[j].gridData.concept = creativesWithConcept[j].gridData.concept.replace("Not yet created", conceptJson.id);
            }
            conceptModel.created = true;
            def.resolve(concept);
          },
          statusInvalid: function () {
            def.resolve();
          }
        });
      }
      return def.promise;
    },
    restartPausedCreatives: function () {
      var self = this;
      if (self.pausedCreatives && self.pausedCreatives.length) {
        return self.submitCreatives(self.pausedCreatives);
      } else {
        return When([]);
      }
    },
    saveVideoToS3: function (creativeModel) {
      const def = When.defer();
      const attributes = creativeModel.attributes;
      const videoObject = attributes.videoObject;
      const videoBlob = videoObject.videoBinaryData;
      const uploadUrl = COMPASS_ENV.COMPONENT_API_BASE + "upload?filename=" + videoObject.videoName;
      const videoAssetModel = attributes.videoAsset;
      const assetsArr = JSON.parse(attributes.assets);
      let presignedS3Url;
      function upload(url, file) {
        const xhr = new XMLHttpRequest();
        xhr.onerror = function (e) {
          def.reject(e);
        };
        xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status >= 200 && xhr.status <= 299) {
              videoAssetModel.asset_value = presignedS3Url.split("?")[0];
              assetsArr.push(videoAssetModel);
              creativeModel.set({
                assets: JSON.stringify(assetsArr)
              });
              creativeModel.unset("videoObject");
              creativeModel.unset("videoAsset");
              def.resolve();
            } else {
              def.reject();
            }
          }
        };
        xhr.open("PUT", url, true);
        xhr.send(file);
      }
      T1Comm.get({
        sourceURL: uploadUrl,
        dataType: "json",
        onSuccess: function (response) {
          presignedS3Url = response.data;
          upload(presignedS3Url, videoBlob);
        },
        processAjaxResponse: function (response) {
          return {
            jsonData: response,
            statusCode: "ok"
          };
        }
      });
      return def.promise;
    },
    initAttemptSave: function (creative) {
      const self = this;
      return function attemptSave() {
        const d = When.defer();
        const componentApiResponseHandler = function (model, responseBody) {
          let body, errorFromComponentService;
          if (typeof responseBody === "string") {
            body = JSON.parse(responseBody);
          } else {
            body = responseBody;
          }
          errorFromComponentService = body.meta && body.meta.status === "error";
          if (errorFromComponentService) {
            self.handleFailure(creative, body.meta.message, attemptSave);
          } else {
            self.successes++;
            window.localStorage.setItem("componentBulkSuccessfulUploads", self.successes);
            self.handleSuccess(creative);
          }
          self.completed++;
          T1.EventHub.publish("component:bulkUploadCreativeUploaded");
          if (self.completed === self.uploadableCreatives.length) {
            T1.EventHub.publish("component:bulkCreateUploadCompleted");
          }
          d.resolve(true);
        };
        const opts = {
          errorDisplaySuppressingConfig: {
            errorSuppressed: true
          },
          success: componentApiResponseHandler,
          statusInvalid: function (errResponse) {
            componentApiResponseHandler(undefined, errResponse);
          }
        };
        if (self.doNotPostCreatives) {
          self.pausedCreatives.push(creative);
          d.resolve(true);
        } else {
          creative.model = new ComponentCreative(creative.attributes);
          T1.EventHub.publish("component:bulkUploadCreativeUploadStarting");
          if (creative.attributes.videoObject) {
            self.saveVideoToS3(creative.model).then(function () {
              creative.model.save(creative.model.attributes, opts);
            }, function () {
              d.resolve(false);
            });
          } else {
            creative.model.save(creative.model.attributes, opts);
          }
          self.showProgressBarForCreative(creative);
          self.checkProgress(creative);
        }
        return d.promise;
      };
    },
    showProgressBarForCreative: function (creative) {
      var self = this;
      var $el = $(self.el);
      var identifier = creative.gridData.htmlIdentifier;
      var loaderEL = $el.find("#progress-" + identifier);
      if (!self.progressMap[identifier]) {
        self.progressMap[identifier] = {
          loaderEl: loaderEL
        };
        creative.gridData.startTime = new Date();
        self.setProgress(creative, 0);
      }
    },
    checkProgress: function (creative) {
      var self = this;
      var progress, progressEventID, progressIncr;
      progress = Math.min(creative.getProgress(), 100);
      if (progress < 100) {
        progressEventID = setInterval(function () {
          creative.progressEventID = progressEventID;
          progressIncr = self.avgDuration > 0 ? 100 / (self.avgDuration * 4) : 5;
          progress += progressIncr;
          T1.EventHub.publish("component:bulkUploadCreativeUploadProgress", {
            creative: creative,
            progress: progress
          });
        }, 250);
      }
    },
    setProgress: function (creative, percent, clear) {
      var self = this;
      var identifier = creative.gridData.htmlIdentifier;
      var sum = 0;
      var durationList, i;
      if (creative.setProgress) {
        creative.setProgress(percent);
      }
      if (creative.toggleLoader && !creative.failureMessage) {
        creative.toggleLoader(true);
      }
      if (clear) {
        if (creative.progressEventID) {
          creative.gridData.endTime = new Date();
          creative.gridData.duration = (creative.gridData.endTime - creative.gridData.startTime) / 1e3;
          durationList = _.chain(self.uploadableCreatives).map(function (c) {
            return c.gridData.duration;
          }).compact().value();
          for (i = 0; i < durationList.length; i++) {
            sum += durationList[i];
          }
          self.avgDuration = sum / durationList.length;
          creative.toggleLoader(false);
          clearInterval(creative.progressEventID);
          delete self.progressMap[identifier];
        }
      }
      this.updateGridCollection(creative.gridData);
    },
    updateGridCollection: function (creative) {
      var attributes = _.keys(creative);
      var key;
      for (var i = 0; i < attributes.length; i++) {
        key = attributes[i];
        this.grid.set("data." + creative.index + "." + key, creative[key]);
      }
    },
    handleSuccess: function (creative) {
      creative.created = true;
      creative.setCreateStatus("success");
      creative.gridData.id = creative.model.id;
      this.setProgress(creative, 100, true);
    },
    handleFailure: function (creative, failureMsg, retryFunc) {
      var self = this;
      var $grid = $(self.grid);
      creative.failureMessage = failureMsg;
      creative.created = false;
      creative.setCreateStatus("failure");
      creative.toggleRetryButton(true);
      creative.toggleLoader(false);
      if (creative.failureMessage.match("not associated with advertiser")) {
        creative.toggleConceptInvalidError(true);
      }
      var retryBtnSelector = "#create-status-retry-" + creative.gridData.htmlIdentifier + " a";
      $grid.on("click", retryBtnSelector, function (e) {
        e.preventDefault();
        self.completed--;
        T1.EventHub.publish("component:bulkCreateUploadStarted");
        creative.toggleRetryButton(false);
        creative.setCreateStatus("pending");
        creative.toggleConceptInvalidError(false);
        $grid.off("click", retryBtnSelector);
        retryFunc(creative);
      });
      this.setProgress(creative, null, true);
    },
    handlePaused: function () {
      this.doNotPostCreatives = true;
      this.pausedCreatives = [];
    },
    handleRestartPaused: function () {
      this.doNotPostCreatives = false;
      this.restartPausedCreatives();
    },
    handleCancel: function () {
      this.doNotPostCreatives = true;
    },
    handleBulkUploadCreativeUploadProgress: function (evtObj) {
      this.setProgress(evtObj.creative, evtObj.progress, evtObj.progress >= 100);
    },
    load: function () {
      var self = this;
      self.pausedCreatives = [];
      window.localStorage.setItem("componentBulkSuccessfulUploads", "0");
      T1.EventHub.publish("component:bulkUploadStarted");
      var $el = $(self.el);
      self.render().then(function () {
        $el.show();
        self.grid = document.querySelector("#componentUploadableGrid");
        self.grid.data = self.gridData;
        setTimeout(function () {
          self.submitCreatives();
        }, 0);
      });
    },
    render: function () {
      var self = this;
      var $el = self.el;
      var deferred = $.Deferred();
      Polymer.dom($el[0]).innerHTML = template;
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      self.bindViewEvents();
      return deferred.promise();
    }
  });
  return ComponentCreateEditTableSubmit;
});
