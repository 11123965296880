define('modules/media/pmp-e/views/dealGroupEdit',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Permissions", "T1Currencies", "T1UtilsAsync", "T1Spinner", "models/sessionUser", "models/userPreferences", "text!../templates/dealGroupEdit.html", "collections/organizations", "collections/supplySources", "collections/mediaDeals", "collections/agencies", "collections/advertisers", "models/advertiser", "models/agency", "models/mediaDeal", "models/mediaDealGroup", "../helpers/parseDeals"], function ($, _, When, T1, T1View, T1Layout, T1Permissions, T1Currencies, T1UtilsAsync, T1Spinner, User, UserPreferences, template, Organizations, SupplySources, Deals, Agencies, Advertisers, Advertiser, Agency, Deal, DealGroup, ParseDeals) {
  "use strict";

  const DEBOUNCE_SEARCH = 350;
  const DEAL_OWNER_TYPE = {
    organization: "organization",
    agency: "agency",
    advertiser: "advertiser"
  };
  const dealGroupEdit = T1View.extend({
    template: template,
    events: {
      "click .post-deal-group-close": "validatePost",
      "click .post-deal-group-another": "validatePost",
      "click .cancel-deal-group-post": "cancelPost",
      "changed .deal-group-name-input": "handleName",
      "changed .deal-group-status-group": "handleStatus",
      "changed .deal-group-description-text-area": "handleDescription",
      "selected .select-group-owner-type": "handleOwnerTypeSelected",
      "changed .search-group-owner": "loadPermissionSearch",
      "selected .select-group-owner": "handleOwnerSelected"
    },
    eventhubEvents: {
      "organization:select": "handleOrgChange",
      "dealGroupDealsChanged:updateDealGroupDeals": "updateDealGroupDeals",
      "binGridLoaded:fetchSupply": "loadSupply",
      "entityDropdownUpdated:populateEditData": "populateEditOwnerData",
      "supplyLoaded:setOwner": "setEditOwner"
    },
    initialize: function () {
      this.pageCount = 100;
      this.dealCount = 0;
      this.postObject = {
        deal_ids: []
      };
      this.selectedDeals = {};
      this.isNewDealGroup = Backbone.history.getFragment().includes("createDealGroup");
      this.isEditingDealGroup = !this.isNewDealGroup;
      const viewOptions = T1.Location.getViewOptions();
      if (viewOptions) {
        this.showDealGroups = viewOptions.showDealGroups;
      }
    },
    load: function () {
      const orgs = Organizations.getOrganizations();
      this.orgId = orgs.selected;
      this.user = User.getUser();
      this.allUserEntities = this.user.attributes.entitiesObj;
      this.initializeLayout();
      this.render().then(() => {
        this.layout.load().then(() => {
          if (this.isEditingDealGroup) {
            this.populateEditFormData();
          } else {
            const saveDealGroupButtons = this.el.find(".post-deal-group-close, .post-deal-group-another");
            const pathPieces = [{
              label: "New Deal Group",
              location: ""
            }];
            saveDealGroupButtons.attr("disabled", "");
            T1.EventHub.publish("updateNavPath", {
              locations: pathPieces
            });
          }
        });
      });
    },
    checkUserAccess: function () {
      const owners = {
        agency: "ag",
        organization: "org",
        advertiser: "adv"
      };
      const dealOwner = this.dealGroup.owner;
      const dealOwnerType = owners[dealOwner.type.toLowerCase()];
      const permToOwner = this.allUserEntities[dealOwnerType][dealOwner.id];
      if (permToOwner !== undefined && permToOwner.value === "0") {
        T1.Location.set("#media/pmp-e");
      }
    },
    loadSupply: function () {
      const deferred = T1UtilsAsync.makeDeferral();
      this.binGridLoaded = true;
      if (this.isEditingDealGroup && !this.dealGroup) {
        deferred.resolve();
      } else {
        this.fetchSupply().then(() => {
          if (this.isNewDealGroup) {
            T1.EventHub.publish("loadSupplyOnBinGrid", {
              supplyCollection: this.supplyCollection
            });
          }
          this.setUserAccessLevel();
          this.isFirstDealCall = true;
          this.supplyLoaded = true;
          deferred.resolve();
        });
      }
      return deferred.promise;
    },
    initializeLayout: function (layoutOptions) {
      const self = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        template: template,
        layout: {
          ".deals-grid-container": [{
            module: "media/pmp-e",
            viewType: "binStrandGrid",
            options: layoutOptions
          }]
        }
      });
    },
    setUserAccessLevel: function () {
      var user = User.getUser();
      var orgID = Organizations.getOrganizations().selected;
      var partialOrgObj = user.attributes.partialEntities.org;
      var partialAgencyObj = user.attributes.partialEntities.ag;
      var allAgencyObj = user.attributes.entitiesObj.ag;
      this.user = user;
      this.userIsGlobal = user.attributes.scope === "GLOBAL";
      this.hasFullOrgAccess = partialOrgObj[orgID] === false || this.userIsGlobal;
      this.partialAgencyList = partialAgencyObj;
      this.allUserEntities = user.attributes.entitiesObj;
      this.hasAgencies = Object.keys(partialAgencyObj).some(k => {
        var isOrg = allAgencyObj[k].organization_id === orgID;
        var notPartial = partialAgencyObj[k] === false;
        var isNotZero = this.allUserEntities.ag[k].value !== "0";
        return this.userIsGlobal || isOrg && notPartial && isNotZero;
      });
      this.setEntityDropDown();
    },
    setEntityDropDown: function () {
      const $entityTypeDropdown = this.$(".select-group-owner-type");
      const entityTypeDropdown = $entityTypeDropdown[0];
      const searchPermissionInput = this.$(".search-group-owner");
      if (this.hasFullOrgAccess) {
        entityTypeDropdown.data = [{
          value: DEAL_OWNER_TYPE.organization,
          name: "Organization"
        }, {
          value: DEAL_OWNER_TYPE.agency,
          name: "Agency"
        }, {
          value: DEAL_OWNER_TYPE.advertiser,
          name: "Advertiser"
        }];
      } else if (this.hasAgencies && !this.hasFullOrgAccess) {
        entityTypeDropdown.data = [{
          value: DEAL_OWNER_TYPE.agency,
          name: "Agency"
        }, {
          value: DEAL_OWNER_TYPE.advertiser,
          name: "Advertiser"
        }];
      } else {
        entityTypeDropdown.placeholder = "Advertiser";
        searchPermissionInput[0].placeholder = "Advertiser";
        this.dealOwnerType = DEAL_OWNER_TYPE.advertiser;
        entityTypeDropdown.data = [{
          value: DEAL_OWNER_TYPE.advertiser,
          name: "Advertiser"
        }];
      }
      if (this.isNewDealGroup) {
        entityTypeDropdown.value = "agency";
      }
    },
    setOwner: function (ownerType, entityData) {
      let selectedOrgID;
      if (this.dealOwnerType === DEAL_OWNER_TYPE.organization) {
        const orgs = Organizations.getOrganizations();
        selectedOrgID = orgs.selected;
        const selectedOrg = orgs.get(selectedOrgID);
        this.owner = {
          id: selectedOrg.attributes.id,
          name: selectedOrg.attributes.name,
          type: selectedOrg.attributes.type
        };
      } else {
        this.owner = {
          id: entityData.value,
          name: entityData.name,
          type: ownerType
        };
      }
      T1.EventHub.publish("owner:selected", {
        dealOwner: this.owner
      });
      this.postObject.owner = this.owner;
      return selectedOrgID;
    },
    setEditOwner: function () {
      if (this.isEditingDealGroup) {
        if (this.dealGroup.owner.type === DEAL_OWNER_TYPE.organization.toUpperCase()) {
          this.setOwner();
        } else {
          const ownerData = {
            value: this.ownerData.id,
            name: this.ownerData.attributes.name
          };
          this.setOwner(this.ownerData.attributes.type, ownerData);
        }
      }
    },
    parseDealCollection: function (dealCollection) {
      const dealArr = [];
      dealCollection.forEach(deal => {
        const mappedDeal = deal.attributes;
        mappedDeal.supply_source_name = this.supply_obj[deal.supply_source_id];
        dealArr.push(mappedDeal);
      });
      return dealArr;
    },
    parseData: function () {
      var gridArray = this.gridArray = [];
      var supply_obj = this.supplyCollection.models.reduce(function (result, item) {
        result[item.attributes.id] = item.attributes.name;
        return result;
      }, {});
      this.sendArray.forEach(function (obj) {
        obj.price_display = T1Currencies.formatNumber(obj.price.currency_code, obj.price.value);
      });
      gridArray = ParseDeals.getDeals(this.sendArray, supply_obj);
      gridArray.forEach(function (row) {
        row.currency_symbol = T1.Utils.getCurrencySymbol(row.currency_code);
      });
    },
    handleOrgChange: function () {
      if (this.isEditingDealGroup) {
        T1.Location.set("#media/pmp-e");
      } else {
        T1.EventHub.publish("organizationChanged:hideBinGrid");
        this.setUserAccessLevel();
        this.setEntityDropDown();
      }
    },
    handleName: function () {
      const nameInput = this.el.find(".deal-group-name-input");
      if (nameInput[0].value && nameInput[0].value.trim().length > 0) {
        nameInput.removeAttr("error");
        this.checkError();
      }
      this.postObject.name = nameInput[0].value.trim();
      this.showWarningStatus();
    },
    handleStatus: function (event) {
      this.postObject.status = event.originalEvent.detail.value === "Active";
    },
    handleDescription: function (event) {
      this.postObject.description = event.originalEvent.detail.value;
    },
    handleOwnerTypeSelected: function () {
      const searchAdvertiserDropdown = this.$(".select-group-owner")[0];
      const searchAdvertiserDropdownInput = this.$(".search-group-owner")[0];
      if (!this.isEditingDeal) {
        this.postObject.owner_id = "";
      }
      this.dealOwnerType = this.$(".select-group-owner-type")[0].value;
      searchAdvertiserDropdownInput.value = "";
      searchAdvertiserDropdownInput.placeholder = this.dealOwnerType.capitalize();
      if (this.dealOwnerType === DEAL_OWNER_TYPE.organization) {
        const selectedOrgID = this.setOwner();
        searchAdvertiserDropdown.set("data", [{
          value: selectedOrgID,
          name: `${this.owner.name} (${selectedOrgID})`
        }]);
        searchAdvertiserDropdown.value = selectedOrgID;
        searchAdvertiserDropdown.setAttribute("disabled", "");
        T1.EventHub.publish("dealGroupOwnerType:selected", this.owner);
      } else if (!this.isEditingDeal) {
        searchAdvertiserDropdown.set("data", []);
        searchAdvertiserDropdown.value = "";
        searchAdvertiserDropdown.removeAttribute("disabled");
        T1.EventHub.publish("dealGroupOwnerType:selected", this.dealOwnerType);
      } else {}
    },
    handleOwnerSelected: function (event) {
      this.setOwner(this.dealOwnerType, event.originalEvent.detail);
    },
    updateDealGroupDeals: function ({
      dealGroupDealIds: dealGroupDealIds
    }) {
      const saveDealGroupButtons = this.el.find(".post-deal-group-close, .post-deal-group-another");
      if (dealGroupDealIds.length === 0) {
        saveDealGroupButtons.attr("disabled", "");
      } else {
        saveDealGroupButtons.removeAttr("disabled");
      }
      this.postObject.deal_ids = dealGroupDealIds;
    },
    loadPermissionSearch: _.debounce(function (val) {
      var term = val.originalEvent.detail.value ? val.originalEvent.detail.value : "";
      var searchTypeCalls;
      term = term.trim().length === 0 ? "" : term;
      switch (this.dealOwnerType) {
        case DEAL_OWNER_TYPE.agency:
          searchTypeCalls = [this.fetchAgencies(term, true)];
          break;
        case DEAL_OWNER_TYPE.advertiser:
          searchTypeCalls = [this.fetchAdvertisers(term, false, false, true)];
          break;
      }
      if (term.length > 0) {
        When.all(searchTypeCalls).then(() => {
          this.populatePermissionList();
        });
      }
    }, DEBOUNCE_SEARCH),
    populateEditFormData: function () {
      const inactiveRadioButton = this.$(".inactive-deal-group");
      const nameInput = this.$(".deal-group-name-input")[0];
      const descriptionInput = this.$(".deal-group-description-text-area")[0];
      const loader = this.$(".deal-group-edit-loader");
      const header = this.$(".deal-group-edit-header")[0];
      loader.removeAttr("hidden");
      this.fetchDealGroup().then(() => {
        const promises = [];
        const selectedDeals = new Deals(false, {
          fetchOptions: {
            full: "*",
            q: `(${this.dealGroup.deal_ids.join(",")})`
          },
          isPaginated: false
        });
        const selectedDealsFetch = () => {
          const deferred = T1UtilsAsync.makeDeferral();
          selectedDeals.fetchAllPages({
            success: data => {
              this.originalTargeting = data.models;
              deferred.resolve();
            }
          });
          return deferred.promise;
        };
        promises.push(selectedDealsFetch());
        const pathPieces = [{
          label: this.dealGroup.name,
          location: ""
        }];
        T1.EventHub.publish("updateNavPath", {
          locations: pathPieces,
          headerInfo: [{
            label: "Group ID",
            value: this.dealGroup.id,
            className: "id"
          }]
        });
        header.innerText = this.dealGroup.name;
        this.postObject.deal_ids = this.dealGroup.deal_ids;
        if (this.dealGroup.owner.type === DEAL_OWNER_TYPE.organization.toUpperCase()) {
          this.dealOwnerType = DEAL_OWNER_TYPE.organization;
        } else {
          const fetchFunc = this.dealGroup.owner.type === DEAL_OWNER_TYPE.agency.toUpperCase() ? this.fetchSingleAgency : this.fetchSingleAdvertiser;
          promises.push(fetchFunc.call(this, this.dealGroup.owner.id));
        }
        promises.push(this.loadSupply());
        T1UtilsAsync.linkPromises(promises).then(() => {
          this.checkUserAccess();
          let orgOfDeal;
          if (this.dealGroup.owner.type !== DEAL_OWNER_TYPE.organization.toUpperCase()) {
            if (this.ownerData.attributes.type === "advertiser") {
              orgOfDeal = parseInt(this.ownerData.attributes.agency.attributes.organization_id, 10);
            } else if (this.ownerData.attributes.type === "agency") {
              orgOfDeal = parseInt(this.ownerData.attributes.organization_id, 10);
            }
          } else {
            orgOfDeal = this.dealGroup.owner.id;
          }
          if (parseInt(this.orgId, 10) !== orgOfDeal) {
            T1.EventHub.publish("dealEdit:populateEditForm", {
              id: orgOfDeal
            });
          }
          T1.EventHub.publish("loadSupplyOnBinGrid", {
            supplyCollection: this.supplyCollection
          });
          if (!this.dealGroup.status) {
            inactiveRadioButton.attr("checked", "");
          }
          this.originalTargeting = this.parseDealCollection(this.originalTargeting);
          T1.EventHub.publish("dealGroupLoaded:updateBinGrid", {
            dealGroup: this.dealGroup,
            originalTargeting: this.originalTargeting
          });
          nameInput.value = this.dealGroup.name.trim();
          descriptionInput.value = this.dealGroup.description;
          this.populateEditOwnerData();
        });
      });
    },
    populateEditOwnerData: function () {
      const $ownerTypeDropdown = this.el.find(".select-group-owner-type");
      const $ownerDropdown = this.el.find(".select-group-owner");
      const loader = this.el.find(".deal-group-edit-loader");
      $ownerTypeDropdown[0].value = this.owner.type;
      $ownerTypeDropdown.attr("disabled", "");
      $ownerDropdown[0].data = [{
        value: this.owner.id,
        name: this.owner.name
      }];
      $ownerDropdown[0].value = this.owner.id;
      $ownerDropdown.attr("disabled", "");
      loader.attr("hidden", "");
    },
    populatePermissionList: function () {
      var dropDownList, typeList;
      var dropDown = this.el.find(".select-group-owner");
      var ownerIsOrg = this.dealOwnerType === DEAL_OWNER_TYPE.organization;
      const SETTLE_DELAY = 100;
      if (ownerIsOrg) {
        return;
      }
      switch (this.dealOwnerType) {
        case DEAL_OWNER_TYPE.agency:
          typeList = this.agencyListDealOwner;
          break;
        case DEAL_OWNER_TYPE.advertiser:
          typeList = this.advertiserList;
          break;
      }
      dropDown.removeAttr("disabled");
      if (typeList.length > 0) {
        dropDownList = typeList.map(function (obj) {
          return {
            value: obj.attributes.id,
            name: `${obj.attributes.name} (${obj.attributes.id})`
          };
        });
      } else {
        dropDownList = [{
          value: "none",
          name: "No Results Found"
        }];
      }
      dropDown[0].set("data", dropDownList);
      dropDown[0].set("state", "opened");
      dropDown[0].async(function () {
        dropDown[0].maxItems = 10;
      }, SETTLE_DELAY);
    },
    populateQUrl: function (val) {
      var qUrl;
      if (val) {
        val = val.replace(/&/g, "%26");
      }
      if (this.dealNameGet) {
        qUrl = `name=:*${val}*`;
      } else if (this.dealIdGet) {
        qUrl = `deal_identifier=:*${val}*`;
      }
      return qUrl;
    },
    fetchDealGroup: function () {
      const DEAL_INDEX = 2;
      const deferred = T1UtilsAsync.makeDeferral();
      const dealGroupInfo = new DealGroup({
        id: Backbone.history.getFragment().split("/")[DEAL_INDEX]
      });
      dealGroupInfo.fetch({
        success: response => {
          if (typeof response === "string") {
            const data = JSON.parse(response);
            if (data.meta.status === "error") {
              const status = data.meta.status;
              const message = data.errors[0].message;
              if (message === "Unauthorized") {
                T1.Location.set("#unauthorized", {
                  replace: true
                });
              }
              T1.Notify(status, message);
            }
          } else {
            this.dealGroup = response.data;
            deferred.resolve();
          }
        }
      });
      return deferred.promise;
    },
    fetchSupply: function () {
      var self = this;
      var deferred = $.Deferred();
      var supply = new SupplySources(false, {
        fetchOptions: {
          full: "*"
        },
        isPaginated: false
      });
      supply.fetch({
        success: function (data) {
          self.supplyCollection = data;
          self.supply_obj = data.models.reduce(function (result, item) {
            result[item.attributes.id] = item.attributes.name;
            return result;
          }, {});
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    fetchAgencies: function (searchTerm, isDealOwner) {
      var deferred = T1UtilsAsync.makeDeferral();
      var orgs = Organizations.getOrganizations();
      var fetchFunc;
      this.agencies = new Agencies(false, {
        pageLimit: 100,
        isPaginated: true,
        fetchOptions: {
          with: ["advertisers"]
        }
      });
      this.agencies.urlString = `agencies/limit/organization=${orgs.selected}`;
      if (searchTerm && searchTerm !== "") {
        this.agencies.search.set({
          params: [{
            term: `*${searchTerm}*`,
            searchField: "name"
          }]
        });
      } else if (searchTerm === "") {
        this.agencies.search.clear();
      }
      fetchFunc = isDealOwner ? this.agencies.fetch : this.agencies.fetchAllPages;
      fetchFunc.call(this.agencies, {
        success: data => {
          if (isDealOwner) {
            this.agencyListDealOwner = data.models.filter(agency => !this.partialAgencyList[agency.id]);
          } else {
            this.agencyList = data.models;
          }
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    fetchSingleAgency: function (id) {
      const deferred = T1UtilsAsync.makeDeferral();
      const ownerID = id || this.dealGroup.owner.id;
      const agency = new Agency({
        id: ownerID
      });
      agency.fetch({
        success: data => {
          this.ownerData = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    fetchAdvertisers: function (searchTerm, more, agencyId, isAdSearch) {
      var deferred = $.Deferred();
      var orgs = Organizations.getOrganizations();
      var fetchFunc;
      var fetchOptions = {
        full: "*",
        with: "agency"
      };
      if (!this.advertisers || isAdSearch) {
        this.advertisers = new Advertisers(false, {
          fetchOptions: fetchOptions,
          pageLimit: 100,
          isPaginated: true
        });
      }
      if (isAdSearch) {
        this.advertisers.urlString = `advertisers/limit/agency.organization=${orgs.selected}`;
      } else {
        this.advertisers.urlString = `advertisers/limit/agency=${agencyId}`;
      }
      if (searchTerm && searchTerm !== "") {
        this.advertisers.search.set({
          params: [{
            term: `*${searchTerm}*`,
            searchField: "name"
          }]
        });
      } else if (searchTerm === "") {
        this.advertisers.search.clear();
      }
      fetchFunc = more ? this.advertisers.fetchMore : this.advertisers.fetch;
      fetchFunc.call(this.advertisers, {
        success: data => {
          this.advertiserList = data.models;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchSingleAdvertiser: function (id) {
      const deferred = T1UtilsAsync.makeDeferral();
      const ownerID = id || this.dealGroup.owner.id;
      const advertiser = new Advertiser({
        id: ownerID
      });
      advertiser.fetch({
        params: {
          with: ["agency", "organization"]
        },
        success: data => {
          this.ownerData = data;
          deferred.resolve(data);
        }
      });
      return deferred.promise;
    },
    showErrorStatus: function () {
      this.setCurrentStatus("error", "There are errors on the page.");
    },
    showWarningStatus: function () {
      if (this.dealPermissionsLoaded && this.dealDataLoaded) {
        this.setCurrentStatus("warning", "There are unsaved changes on the page.");
      }
    },
    showSuccessStatus: function () {
      this.setCurrentStatus("success", "Save Successful.");
    },
    hideStatusMessages: function () {
      this.setCurrentStatus("all");
    },
    setCurrentStatus: function (status, message) {
      const dealGroupsFooter = $(".deal-groups-footer")[0];
      if (status === "all") {
        dealGroupsFooter.hideMessage();
      } else {
        dealGroupsFooter.type = status;
        dealGroupsFooter.message = message;
        dealGroupsFooter.showMessage();
      }
    },
    checkError: function () {
      var dealGroupNameInput = this.el.find(".deal-group-name-input");
      if (dealGroupNameInput[0].value && dealGroupNameInput[0].value.trim().length > 0) {
        this.hideStatusMessages();
      }
    },
    cancelPost: function () {
      if (this.isEditingDealGroup || this.showDealGroups) {
        T1.Location.set("#media/pmp-e", {
          viewOptions: {
            showDealGroups: true
          }
        });
      } else {
        T1.Location.set("#media/pmp-e");
      }
    },
    validatePost: function (event) {
      const nameInput = this.$(".deal-group-name-input")[0];
      const ownerTypeDropdown = this.$(".select-group-owner-type")[0];
      const ownerDropdown = this.$(".select-group-owner")[0];
      const saveDealButtons = this.$(".post-deal-group-close, .post-deal-group-another");
      if (!nameInput.value || !nameInput.value.trim().length) {
        nameInput.error = true;
      }
      if (!ownerTypeDropdown.value) {
        ownerTypeDropdown.error = true;
      }
      if (!ownerDropdown.value) {
        ownerDropdown.error = true;
      }
      if (nameInput.value && nameInput.value.trim().length && ownerTypeDropdown.value && ownerDropdown.value) {
        this.hideStatusMessages();
        this.preparePost(event);
        T1Spinner.apply($(event.target).addClass("save-action"));
        saveDealButtons.attr("disabled", "disabled");
      } else {
        this.showErrorStatus();
      }
    },
    preparePost: function (event) {
      const $saveButton = $(event.target);
      const saveDealButtons = this.el.find(".post-deal-group-close, .post-deal-group-another");
      const enableButtons = () => {
        $saveButton.spin(false);
        $saveButton.removeClass("save-action");
        saveDealButtons.removeAttr("disabled");
      };
      this.dealGroupPostObject = new DealGroup();
      this.postObject.deal_ids = this.postObject.deal_ids.map(deal_id => Number(deal_id));
      this.postObject.owner.type = this.postObject.owner.type.toUpperCase();
      this.postObject.owner.id = Number(this.postObject.owner.id);
      if (this.isEditingDealGroup) {
        this.dealGroupPostObject.url = `deal_groups/${this.dealGroup.id}`;
      }
      this.dealGroupPostObject.createDealGroup(this.postObject, {
        success: data => {
          const dealGroupTitleEl = this.el.find(".deal-group-edit-header")[0];
          const dealName = data.get("name");
          const dealId = data.get("id");
          $saveButton.spin(false);
          $saveButton.removeClass("save-action");
          saveDealButtons.removeAttr("disabled");
          if (event.target.innerText.includes("Save & Add Another")) {
            T1.Location.set("#media/createDealGroup/pmp-e", {
              reload: true
            });
          } else {
            T1.Location.set(`#media/editDealGroup/${dealId}/pmp-e`);
            T1.EventHub.publish("updateNavPath", {
              locations: [{
                label: dealName,
                location: ""
              }],
              headerInfo: [{
                label: "Group ID",
                value: dealId,
                className: "id"
              }]
            });
            dealGroupTitleEl.innerText = dealName;
          }
          this.showSuccessStatus();
          T1.Notify("message", "Deal Group Successfully Saved");
        },
        error: () => {
          this.showErrorStatus();
          enableButtons();
        },
        invalid: response => {
          const dealGroupNameInput = this.el.find(".deal-group-name-input");
          const isDealGroups = response[0].field === "deal_groups";
          if (isDealGroups) {
            dealGroupNameInput[0].error = true;
          }
          this.showErrorStatus();
          enableButtons();
        }
      });
    },
    render: function () {
      var deferred = T1UtilsAsync.makeDeferral();
      Polymer.dom(this.el[0]).innerHTML = this.template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise;
    }
  });
  return dealGroupEdit;
});
