define('T1FilterList',["jQuery", "Underscore", "T1", "T1View", "T1Spinner", "text!templates/filter_list.html", "text!templates/filter_list_search.html", "text!templates/filter_list_item_all.html", "text!templates/filter_list_item_all_search_results.html", "text!templates/filter_list_item.html", "text!templates/filter_list_footer.html", "jQPlugins/perfect-scrollbar"], function ($, _, T1, T1View, Spinner, filterListTemplate, searchTemplate, itemAllTemplate, itemAllSearchResultsTemplate, itemTemplate, footerTemplate) {
  "use strict";

  var T1FilterList;
  var T1FilterListExtend;
  var documentEvents = "mousedown.t1filterlist keyup.t1filterlist";
  var $document = $(document);
  const DEBOUNCE_DELAY = 200;
  T1FilterList = T1View.extend({
    template: filterListTemplate,
    partials: {
      search: searchTemplate,
      item_all: itemAllTemplate,
      item_all_search_results: itemAllSearchResultsTemplate,
      item: itemTemplate,
      footer: footerTemplate
    },
    spinnerOpts: {
      corners: 1,
      length: 2,
      lines: 12,
      radius: 3,
      rotate: 0,
      speed: 1.4,
      trail: 40,
      width: 2
    },
    events: {
      "click .list input[type=checkbox]": "itemClick",
      "click .ui-cancel": "cancel",
      "click .ui-submit": "applyAction",
      "close .w-FilterList": "close",
      "emptyList .w-FilterList": "emptyList",
      "filterClear .w-FilterList": "filterClear",
      "inputFocus .w-FilterList": "inputFocus",
      "mousedown .w-FilterList": "stopBubbling",
      "open .w-FilterList": "open",
      "reset .w-FilterList": "reset",
      "searchClear .w-FilterList": "searchClear",
      "updateList .w-FilterList": "updateList",
      "updateScrollBar .w-FilterList": "updateScrollBar",
      "organizeSearchResult .w-FilterList": "organizeSearchResult",
      "search .w-FilterList": "search"
    },
    initialize: function (args) {
      args = args || {};
      this.collection = args.collection;
      this.selectFilterListView = args.selectFilterListView;
      this.keyInputDelay = args.keyInputDelay || 0;
      this.showOnInit = args.showOnInit || false;
      this.clearFieldOnUpdate = args.clearFieldOnUpdate || false;
      this.clearOnClose = args.clearOnClose || false;
      this.onApply = args.onApply || $.noop;
      this.onOpen = args.onOpen || $.noop;
      this.onClose = args.onClose || $.noop;
      this.submit_label = args.submit_label || "Apply";
      this.loadDataOnSearch = args.loadDataOnSearch || false;
      this.clearSearchInputAfterDelay = args.clearSearchInputAfterDelay || true;
      this.checkedItems = {};
      this.searchResultList = [];
      this.allChecked = false;
      this.allSelected = true;
      this.allCheckedOnApply = true;
      this.searchApplied = false;
      this.noCheckAllSync = false;
    },
    load: function () {
      var self = this;
      if (this.loaded) {
        return;
      }
      if (!this.showOnInit) {
        $(this.el).hide();
      }
      this.render().then(function () {
        self.initializeUI();
        self.updateList();
      });
      this.loaded = true;
    },
    unload: function () {
      $document.off(documentEvents);
      if (this.searchInput) {
        this.searchInput.off("keyup");
      }
      this.emptyList();
      this.killIconSpinner();
      this.searchInput = null;
      this.list = null;
      this.allItem = null;
      this.filteredItems = null;
      this.allItemCheckbox = null;
      this.filteredItemsCheckbox = null;
      this.submitBtn = null;
      this.cancelBtn = null;
    },
    initializeUI: function () {
      var $el = $(this.el);
      this.searchInput = $el.find(".search input");
      this.searchInput.on("keyup", $.proxy(_.debounce(this.updateFilterList, this.keyInputDelay), this));
      this.list = $el.find(".list");
      this.submitBtn = $el.find(".ui-submit");
      this.cancelBtn = $el.find(".ui-cancel");
      this.showLoadingMsg();
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    inputFocus: function () {
      if (this.searchInput) {
        this.searchInput.focus();
      }
    },
    emptyList: function () {
      if (this.list) {
        if (this.list.perfectScrollbar) {
          this.list.perfectScrollbar("destroy");
        }
        this.list.find("input").off();
        this.list.empty();
      }
      if (this.items || this.iconSpinner) {
        this.setItems();
      }
      if (!this.loadDataOnSearch) {
        this.showLoadingMsg();
      }
    },
    setItems: function (items) {
      this.killIconSpinner();
      if (items && items[0] && items[0].name) {
        this.sortItems(items);
      } else {
        this.items = items;
      }
    },
    sortItems: function (items) {
      items.sort(function (a, b) {
        var nameA = a.name.toLowerCase();
        var nameB = b.name.toLowerCase();
        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      });
      this.items = items;
    },
    filterClear: function (event, doApply) {
      var selectedClass = "selected";
      var checkedClass = "checked";
      this.filteredItems.removeClass(selectedClass);
      this.allItem.addClass(selectedClass);
      this.searchInput.val("").focus();
      this.allItemCheckbox.prop(checkedClass, true);
      this.filteredItemsCheckbox.prop(checkedClass, false);
      this.updateFilterList();
      if (doApply) {
        this.checkAll(true);
        this.applyAction(event);
      }
      this.submitBtn.toggleClass("disabled", false);
    },
    searchClear: function () {
      if (this.searchInput) {
        this.searchInput.val("").focus();
      }
      this.updateFilterList();
    },
    reset: function () {
      this.allCheckedOnApply = true;
    },
    updateList: function () {
      var $el = $(this.el);
      var $list = this.list;
      var $searchInput = this.searchInput;
      var elHidden = $el.css("display") === "none";
      var checkedItems;
      var items = this.items;
      var itemsLength = items ? items.length : 0;
      var selectedCount = 0;
      var selected;
      var item;
      var inputSelector = "input";
      this.renderPartial(".list");
      this.toggleFooterButtons(false);
      this.toggleSearchInput(!this.loadDataOnSearch && itemsLength === 0);
      if (this.clearFieldOnUpdate && $searchInput) {
        $searchInput.val("").focus();
      }
      if ($list) {
        $list.perfectScrollbar("destroy");
        $list.scrollTop(0);
        $list.perfectScrollbar();
        this.allItem = $list.find(".all");
        this.allSearchResultsItem = $list.find(".all-search-results");
      } else {
        return;
      }
      this.filteredItems = $list.find(".filtered");
      this.allItemCheckbox = this.allItem.find(inputSelector);
      this.allSearchResultsItemCheckbox = this.allSearchResultsItem.find(inputSelector);
      this.filteredItemsCheckbox = this.filteredItems.find(inputSelector);
      this.hasAllItem = this.allItem.length > 0;
      this.hasAllSearchResultsItem = this.allSearchResultsItem.length > 0;
      checkedItems = this.checkedItems = {};
      if (itemsLength > 0 && this.hasAllItem && items[0].selected !== undefined) {
        for (var i = 0; i !== itemsLength; i++) {
          item = items[i];
          selected = item.selected;
          if (selected) {
            selectedCount++;
          }
          checkedItems[item.id] = selected;
        }
        this.allChecked = selectedCount === itemsLength;
        if (this.allChecked) {
          this.checkAll(true);
        }
        this.toggleFooterButtons(selectedCount === 0, true);
      }
      if (itemsLength === 0) {
        this.toggleFooterButtons(true, true);
      }
      $el.show();
      if (elHidden) {
        $el.hide();
      }
    },
    checkAllItemsInData: function (checkAll) {
      var checkedItems = this.checkedItems = {};
      var items = this.items;
      var len = items ? items.length : 0;
      var i;
      for (i = 0; i !== len; ++i) {
        checkedItems[items[i].id] = checkAll;
      }
      this.allChecked = checkAll;
      this.allSelected = checkAll;
    },
    checkAllSearchItemsInData: function (checkAllSearchResults) {
      var checkedItems = this.checkedItems;
      var items = this.searchResultList;
      var len = items.length;
      var i;
      for (i = 0; i !== len; ++i) {
        checkedItems[items[i].data("id")] = checkAllSearchResults;
      }
    },
    updateFilterList: function () {
      this.list.find(".no-result").remove();
      this.search();
      this.list.perfectScrollbar("update");
    },
    search: _.debounce(function () {
      var term = this.searchInput.val().toLowerCase();
      var filteredItemsLength = this.filteredItems ? this.filteredItems.length : 0;
      var items = this.items;
      var hitCount = 0;
      var searchResult = this.searchResultList = [];
      var selectedCount = 0;
      var needToCheckAll = (term.length === 0 && this.prevTermLen > 0 || term.length > 0) && this.allSearchResultsItem.hasClass("selected");
      var sync = needToCheckAll && !this.noCheckAllSync;
      var $submitBtn = this.submitBtn;
      var $item;
      if (!items) {
        return;
      }
      if (filteredItemsLength > 0 && items.length > 0 && items[0].searchKey === undefined) {
        throw new Error("'searchKey' needs to be defined in each item in 'items' array for search.");
      }
      for (var i = 0; i !== filteredItemsLength; i++) {
        if (!items[i].searchKey.toLowerCase().includes(term)) {
          $(this.filteredItems[i]).hide();
        } else {
          $item = $(this.filteredItems[i]);
          $item.show();
          searchResult.push($item);
          hitCount++;
          if (sync) {
            $item.find("input").prop("checked", true);
            $item.addClass("selected");
          }
          if ($item.hasClass("selected")) {
            selectedCount++;
          }
        }
      }
      if (term.length === 0) {
        this.checkedItems = $.extend({}, this.checkedItems);
        if (this.hasAllItem) {
          this.allItem.show();
          if (needToCheckAll) {
            this.allItem.find("input").prop("checked", true);
            this.allItem.addClass("selected");
            this.allChecked = true;
          }
        }
        if (this.hasAllSearchResultsItem) {
          this.allSearchResultsItem.addClass("no-display").removeClass("selected");
          this.allSearchResultsItemCheckbox.prop("checked", false);
        }
      } else {
        if (this.hasAllItem) {
          this.allItem.hide();
        }
        if (this.hasAllSearchResultsItem) {
          if (hitCount > 0) {
            this.allSearchResultsItem.removeClass("no-display");
            if (hitCount === selectedCount) {
              this.allSearchResultsItemCheckbox.prop("checked", true);
              this.allSearchResultsItem.addClass("selected");
            } else {
              this.allSearchResultsItemCheckbox.prop("checked", false);
              this.allSearchResultsItem.removeClass("selected");
            }
          } else {
            this.allSearchResultsItem.addClass("no-display");
            this.displayNoResults();
          }
        }
      }
      this.prevTermLen = term.length;
      if ($submitBtn) {
        if (selectedCount === 0 || term.length > 0 && hitCount === 0) {
          $submitBtn.toggleClass("disabled", true);
        } else {
          $submitBtn.toggleClass("disabled", false);
        }
      }
    }, DEBOUNCE_DELAY),
    displayNoResults: function () {
      var term = this.searchInput.val().trim();
      if (term !== "") {
        this.list.append('<div class="no-result">No results found for "' + term + '"</div>');
      }
    },
    showLoadingMsg: function () {
      var list = this.list;
      if (!this.iconSpinner) {
        if (list) {
          list.append('<div class="icon-spinner"></div><div class="loading-msg">Loading ...</div>');
          this.iconSpinner = list.find(".icon-spinner");
          Spinner.apply(this.iconSpinner, this.spinnerOpts);
        }
        this.toggleFooterButtons(true);
        this.toggleSearchInput(true);
      }
    },
    killIconSpinner: function () {
      if (this.iconSpinner) {
        this.iconSpinner.spin(false);
        this.iconSpinner = undefined;
      }
    },
    toggleFooterButtons: function (disable, submitBtnOnly) {
      var $submitBtn = this.submitBtn;
      var $cancelBtn = this.cancelBtn;
      var disabledClass = "disabled";
      if (disable === undefined) {
        disable = !_.some(this.checkedItems, function (item) {
          return item;
        });
      }
      if ($submitBtn) {
        $submitBtn.toggleClass(disabledClass, disable);
      }
      if (!submitBtnOnly && $cancelBtn) {
        $cancelBtn.toggleClass(disabledClass, disable);
      }
    },
    toggleSearchInput: function (disable) {
      var $searchInput = this.searchInput;
      if (!this.clearSearchInputAfterDelay && $searchInput) {
        $searchInput.val("").prop("readonly", disable);
      }
    },
    itemClick: function (event) {
      var item = $(event.currentTarget).closest(".fl-item");
      var id = item.attr("data-id");
      var list = this.list;
      var itemList = list.find(".filtered");
      var searchResultList = this.searchResultList;
      var len = searchResultList.length;
      var inSearchMode = this.inSearchMode();
      var selectedClass = "selected";
      var selectedState;
      var selectedItems;
      var count = 0;
      var $item;
      var i;
      event.stopPropagation();
      item.toggleClass(selectedClass);
      selectedState = item.hasClass(selectedClass);
      if (item.hasClass("all")) {
        this.checkAll(selectedState);
        this.toggleFooterButtons(!selectedState, true);
      } else if (item.hasClass("all-search-results")) {
        this.checkAllInSearchResult(selectedState);
        this.toggleFooterButtons(!selectedState, true);
      } else {
        selectedItems = list.find(".filtered.selected");
        if (this.hasAllItem) {
          this.toggleFooterButtons(selectedItems.length === 0, true);
        }
        if (this.hasAllItem && inSearchMode) {
          for (i = 0; i < len; i++) {
            $item = searchResultList[i];
            if ($item.hasClass("selected")) {
              count++;
            }
          }
          if (len === count) {
            this.checkAllInSearchResult(true);
          } else {
            this.allSearchResultsItem.removeClass(selectedClass);
            this.allSearchResultsItemCheckbox.prop("checked", false);
            this.checkedItems[id] = selectedState;
            this.allItem.removeClass(selectedClass);
            this.allItemCheckbox.prop("checked", false);
            this.allChecked = false;
          }
        } else {
          if (selectedItems.length === itemList.length) {
            this.checkAll(true);
          } else {
            this.allItem.removeClass(selectedClass);
            this.allItemCheckbox.prop("checked", false);
            this.allChecked = false;
            this.checkedItems[id] = selectedState;
          }
        }
      }
    },
    checkAll: function (selectedState) {
      var checkedProp = "checked";
      var selectedClass = "selected";
      var action = selectedState ? "addClass" : "removeClass";
      this.allChecked = selectedState;
      this.allItemCheckbox.prop(checkedProp, selectedState);
      this.checkAllItemsInData(selectedState);
      this.allItem[action](selectedClass);
      this.filteredItems[action](selectedClass);
      this.filteredItemsCheckbox.prop(checkedProp, selectedState);
    },
    checkAllInSearchResult: function (selectedState) {
      var searchResultList = this.searchResultList;
      var len = searchResultList.length;
      var checkedProp = "checked";
      var selectedClass = "selected";
      var action = selectedState ? "addClass" : "removeClass";
      var $item;
      var i;
      this.allSearchResultsItemCheckbox.prop("checked", selectedState);
      this.checkAllSearchItemsInData(selectedState);
      this.allSearchResultsItem[action]("selected");
      if (!this.noCheckAllSync) {
        this.filteredItems[action](selectedClass);
        this.filteredItemsCheckbox.prop(checkedProp, selectedState);
        this.allChecked = selectedState;
        this.allItem[action]("selected");
        this.allItemCheckbox.prop(checkedProp, selectedState);
      }
      for (i = 0; i !== len; ++i) {
        $item = searchResultList[i];
        $item[action](selectedClass);
        $item.find("input").prop(checkedProp, selectedState);
      }
    },
    cancel: function (event) {
      event.stopPropagation();
      if ($(event.currentTarget).hasClass("disabled")) {
        return;
      }
      $document.mousedown();
    },
    applyAction: function (event) {
      var items = this.items;
      var hasAllItem = this.hasAllItem;
      var inSearchMode = this.inSearchMode();
      event.stopPropagation();
      if (hasAllItem) {
        if (inSearchMode) {
          this.getCheckedItems(this.searchResultList, true);
        } else {
          if (this.allChecked) {
            this.checkAllItemsInData(true);
          } else {
            this.getCheckedItems(this.filteredItems);
            this.allChecked = false;
            this.allSelected = false;
          }
        }
      }
      if ($(event.currentTarget).hasClass("disabled") || !items) {
        return;
      }
      if (items.length === 0) {
        this.allChecked = true;
      }
      this.allCheckedOnApply = this.allChecked;
      if (hasAllItem) {
        this.searchApplied = inSearchMode;
        this.selectFilterListView.searchApplied = inSearchMode;
        this.setAppliedFilteredItems(this.checkedItems);
      }
      this.onApply.call(this, this.checkedItems, this.allChecked);
      $document.mousedown();
    },
    getCheckedItems: function (list, inSearchResult) {
      var checkedItems = this.checkedItems;
      var len = list.length;
      var $item;
      var i;
      $.each(checkedItems, function (k) {
        checkedItems[k] = false;
      });
      for (i = 0; i !== len; ++i) {
        $item = $(list[i]);
        checkedItems[$item.data("id")] = $item.hasClass("selected");
      }
      this.checkedItems = checkedItems;
      if (inSearchResult) {
        this.checkedItemsInSearchResult = $.extend({}, this.checkedItems);
      }
    },
    organizeSearchResult: function () {
      var searchResult = this.searchResultList;
      var checkedItems = this.checkedItemsInSearchResult || {};
      var len = searchResult.length;
      var checkedCount = 0;
      var $item;
      var i;
      for (i = 0; i !== len; ++i) {
        $item = searchResult[i];
        if (checkedItems[$item.data("id")]) {
          $item.addClass("selected");
          $item.find("input").prop("checked", true);
          checkedCount++;
        } else {
          $item.removeClass("selected");
          $item.find("input").prop("checked", false);
        }
      }
      if (this.allSearchResultsItem) {
        if (checkedCount === len) {
          this.allSearchResultsItem.addClass("selected");
          this.allSearchResultsItemCheckbox.prop("checked", true);
        } else {
          this.allSearchResultsItem.removeClass("selected");
          this.allSearchResultsItemCheckbox.prop("checked", false);
        }
      }
    },
    open: function () {
      var self = this;
      $document.mousedown();
      this.el.hide().fadeIn(200, this.onOpen);
      this.inputFocus();
      if (!this.filteredItems) {
        this.updateList();
      } else {
        this.list.scrollTop(0);
        this.list.perfectScrollbar("update");
      }
      if (!this.loadDataOnSearch && !this.items) {
        this.showLoadingMsg();
      }
      $document.one(documentEvents, function (event) {
        if (event.keyCode === 9 || event.keyCode === 27 || !event.keyCode) {
          self.close(event);
        }
      });
    },
    close: function (event) {
      event.stopPropagation();
      $document.off(documentEvents);
      this.el.hide();
      if (this.clearOnClose) {
        this.searchInput.val("");
        this.emptyList();
      }
      this.onClose();
    },
    getEmptyMessage: function () {
      return "No result found";
    },
    updateScrollBar: function () {
      this.list.scrollTop(0);
      this.list.perfectScrollbar("update");
    },
    inSearchMode: function () {
      return this.searchInput && this.searchInput.val().length > 0;
    },
    setAppliedFilteredItems: function () {},
    serialize: function () {
      var items = this.items;
      var len = items && items.length || 0;
      var noItem = len === 0;
      var item, i;
      for (i = 0; i !== len; i++) {
        item = items[i];
        if (item.name === "") {
          item.name = item.searchKey = "Unassigned";
        }
      }
      return {
        allSelected: this.allSelected,
        emptyMsg: noItem ? this.getEmptyMessage() : null,
        items: items,
        loading: !items,
        noItem: noItem,
        submit_label: this.submit_label
      };
    }
  });
  T1FilterListExtend = T1FilterList.extend;
  T1FilterList.extend = function () {
    var originalPartials;
    var originalEvents;
    var originalDataEvents;
    var originalInit;
    var originalLoad;
    var originalUnLoad;
    var originalSerialize;
    var ViewSubClass;
    ViewSubClass = T1FilterListExtend.apply(this, arguments);
    originalInit = ViewSubClass.prototype.initialize || $.noop;
    originalPartials = ViewSubClass.prototype.partials || $.noop;
    originalEvents = ViewSubClass.prototype.events || {};
    originalDataEvents = ViewSubClass.prototype.dataEvents || {};
    originalLoad = ViewSubClass.prototype.load || $.noop;
    originalUnLoad = ViewSubClass.prototype.unload || $.noop;
    originalSerialize = ViewSubClass.prototype.serialize || $.noop;
    ViewSubClass.prototype.partials = $.extend(true, {}, T1FilterList.prototype.partials, originalPartials);
    ViewSubClass.prototype.events = $.extend(true, {}, T1FilterList.prototype.events, originalEvents);
    ViewSubClass.prototype.dataEvents = $.extend(true, {}, T1FilterList.prototype.dataEvents, originalDataEvents);
    ViewSubClass.prototype.initialize = function () {
      T1FilterList.prototype.initialize.apply(this, arguments);
      originalInit.apply(this, arguments);
    };
    ViewSubClass.prototype.unload = function () {
      T1FilterList.prototype.unload.apply(this, arguments);
      originalUnLoad.apply(this, arguments);
    };
    ViewSubClass.prototype.serialize = function () {
      var tmplObj = T1FilterList.prototype.serialize.apply(this, arguments);
      return $.extend(tmplObj, originalSerialize.call(this));
    };
    return ViewSubClass;
  };
  return T1FilterList;
});
