define('modules/campaign/bulkedit/review/views/readOnly',["T1", "T1View", "T1Layout", "text!../templates/readOnly.html"], function (T1, T1View, T1Layout, template) {
  "use strict";

  var ReviewActionsReadOnly = T1View.extend({
    template: template,
    events: {
      "click .strategies-link": "strategiesLinkClick"
    },
    eventhubEvents: {},
    initialize: function ({
      executionStatus: executionStatus,
      bulkeditor: bulkeditor
    }) {
      this.executionStatus = executionStatus;
      this.bulkeditor = bulkeditor;
      this.checkBulkeditorReady();
    },
    load: function () {
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
        }
      });
    },
    checkBulkeditorReady: function () {
      if (this.bulkeditor.editorReady) {
        this.initLayout();
      } else {
        this.bulkeditor.getStatus({
          success: () => {
            this.initLayout();
            this.load();
          }
        });
      }
    },
    initLayout: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body-review",
        template: '<div class="w-grid"></div>',
        layout: {
          ".w-grid": [{
            module: "campaign/bulkedit/review",
            viewType: "readOnlyBrowse",
            options: {
              executionStatus: this.executionStatus,
              bulkeditor: this.bulkeditor
            }
          }]
        }
      });
    },
    strategiesLinkClick: function () {
      T1.EventHub.publish("selectStrategiesTab");
    },
    unload: function () {
      this.bulkeditor.editorReady = false;
    },
    serialize: function () {
      var returnObj = {};
      var numOfChanges = 0;
      const bulkeditor = this.bulkeditor;
      if (bulkeditor.get("stagedActions").length) {
        bulkeditor.get("stagedActions").forEach(function (actionObject) {
          if (actionObject.add || actionObject.remove || actionObject.overwrite) {
            numOfChanges++;
          }
        });
      }
      returnObj.numOfChanges = numOfChanges;
      returnObj.changesAddS = returnObj.numOfChanges > 1;
      returnObj.numOfStrategies = bulkeditor.get("stagedStrategies").length;
      returnObj.strategiesAddS = returnObj.numOfStrategies > 1;
      returnObj.editorOpen = bulkeditor.get("state") === "open" && bulkeditor.get("editor_status") !== "closed";
      return returnObj;
    }
  });
  return ReviewActionsReadOnly;
});
