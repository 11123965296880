define('modules/campaign/chart/views/highestLowestMenu',["jQuery", "Underscore", "T1", "T1View", "text!../templates/highestLowestMenu.html"], function ($, _, T1, T1View, template) {
  "use strict";

  var T1Publish = T1.EventHub.publish;
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  return T1View.extend({
    template: template,
    documentEvents: "mousedown.CanvasChart keyup.CanvasChart",
    $document: $(document),
    events: {
      "click .btn-highest-lowest": "toggleHandler",
      "click .range-list strand-list-item": "rangeClickHandler",
      "click .metrics-list strand-list-item": "metricsClickHandler",
      "click .exclude-zeros": "excludeZerosHandler",
      "click .cancel": "cancelHandler",
      "click .apply": "applyHandler",
      "mousedown .list-highest-lowest": "stopBubbling",
      "mousedown .footer-highest-lowest": "stopBubbling"
    },
    eventhubEvents: {
      "change:metricsHighestLowest": function ({
        id: id,
        excludeZeros: excludeZeros,
        filter1: filter1,
        filter2: filter2,
        metrics: metrics,
        title: title
      }) {
        if (id === this.id) {
          this.excludeZeros = excludeZeros;
          this.filter1 = filter1;
          this.filter2 = filter2;
          this.metrics = metrics;
          this.title = title;
          this.render().then(() => {
            window.requestAnimationFrame(() => {
              this.assignElements();
            });
          });
        }
      }
    },
    initialize: function (configs = {}) {
      this.id = configs.id || 0;
      this.title = configs.title || "Select One";
      this.selected = configs.selected || "";
      this.metrics = (configs.metrics instanceof Function ? configs.metrics() : configs.metrics) || [];
      this.ranges = configs.ranges || [];
    },
    load: function () {
      this.render().then(() => {
        window.requestAnimationFrame(() => {
          this.assignElements();
          T1Publish("complete:layoutLoad");
        });
      });
    },
    unload: function () {
      this.$carat = null;
      this.$excludeZeros = null;
      this.$list = null;
      this.$metrics = null;
      this.$range = null;
      this.excludeZeros = null;
      this.excludeZerosTemp = null;
      this.filter1 = null;
      this.filter1Temp = null;
      this.filter2 = null;
      this.filter2Temp = null;
      this.metrics = null;
      this.ranges = null;
      this.title = null;
    },
    stopBubbling: function (event) {
      event.stopPropagation();
    },
    assignElements: function () {
      this.$carat = this.$(".carat");
      this.$list = this.$(".list-highest-lowest");
      this.$metrics = this.$(".metrics-list");
      this.$range = this.$(".range-list");
      this.btnExcludeZeros = this.$(".exclude-zeros")[0];
      this.btnExcludeZeros.set("checked", T1.Utils.valueToBool(this.excludeZeros));
    },
    toggleHandler: function () {
      const $list = this.$list;
      if ($list.hasClass("no-display")) {
        $list.toggleClass("no-display");
        this.$carat.toggleClass("menu-open");
        this.$document.one(this.documentEvents, evt => {
          if (evt.keyCode === TAB_KEY || evt.keyCode === ESCAPE_KEY || !evt.keyCode) {
            if (!$(evt.target).closest(".highest-lowest-menu").length) {
              this.closeMenu();
            }
          }
        });
      } else {
        this.closeMenu();
      }
    },
    closeMenu: function () {
      this.$list.toggleClass("no-display");
      this.$carat.toggleClass("menu-open");
      this.resetActiveStates();
      this.$document.off(this.documentEvents);
    },
    rangeClickHandler: function (event) {
      this.toggleActiveState(event);
      this.filter1Temp = event.currentTarget.id;
    },
    metricsClickHandler: function (event) {
      this.toggleActiveState(event);
      this.filter2Temp = event.currentTarget.id;
    },
    excludeZerosHandler: function (event) {
      this.excludeZerosTemp = event.currentTarget.checked;
    },
    cancelHandler: function (event) {
      event.preventDefault();
      this.closeMenu();
    },
    applyHandler: function () {
      this.filter1 = this.filter1Temp || this.filter1;
      this.filter2 = this.filter2Temp || this.filter2;
      this.excludeZeros = this.excludeZerosTemp;
      T1Publish("change:highestLowest", {
        range: this.filter1,
        metric: this.filter2,
        excludeZeros: this.excludeZeros
      });
      this.toggleHandler();
      this.updateMenuBtn();
    },
    updateMenuBtn: function () {
      let metric, range;
      const $rangeItem = this.$range.find(".selected-filter");
      const $metricItem = this.$metrics.find(".selected-filter");
      if (Polymer && Polymer.dom) {
        range = Polymer.dom($rangeItem[0]).textContent;
        metric = Polymer.dom($metricItem[0]).textContent;
      } else {
        range = $rangeItem.text();
        metric = $metricItem.text();
      }
      this.$("#selection-highest-lowest").text(`${range} ${metric}`);
    },
    toggleActiveState: function (event) {
      var currentTarget = event.currentTarget;
      $(currentTarget.parentNode).find("strand-list-item").removeClass("selected-filter");
      $(currentTarget).addClass("selected-filter");
    },
    resetActiveStates: function () {
      function reset($selector, filter) {
        $selector.find("strand-list-item").removeClass("selected-filter");
        $selector.find(`strand-list-item[id="${filter}"]`).addClass("selected-filter");
      }
      reset(this.$range, this.filter1);
      reset(this.$metrics, this.filter2);
      this.btnExcludeZeros.set("checked", this.excludeZeros);
    },
    serialize: function () {
      const rangeData = _.each(this.ranges, item => {
        item.selected = item.value === this.filter1 ? "selected-filter" : "";
      });
      const metricData = _.each(this.metrics, item => {
        item.selected = item.value === this.filter2 ? "selected-filter" : "";
      });
      return {
        metrics: metricData,
        ranges: rangeData,
        title: this.title
      };
    }
  });
});
