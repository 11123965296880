define('modules/reporting/campaigns/reachFrequency/models/model',["jQuery", "Underscore", "T1", "models/reportingAbstractModel"], function ($, _, T1, RPTGAbstractModel) {
  "use strict";

  const _COLOR_BAR_CHART = "#E7E7E7";
  const _COLOR_LINE_CHART = "#FD9303";
  const _COLOR_WHITE = "#FFFFFF";
  var formatCurrency = T1.Utils.formatCurrency;
  var formatAs = T1.Formats.as;
  var ReachFrequencyModel = RPTGAbstractModel.extend({
    initialize: function (options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    setMoreFilters: function (moreFilters) {
      this.set({
        moreFilters: moreFilters
      });
    },
    getTotalFrequencyBin: function (totalRow) {
      var totalFrequencyBin;
      const DECIMALS = 2;
      totalFrequencyBin = (totalRow.impressions / totalRow.uniques).toFixed(DECIMALS);
      totalFrequencyBin = _.isFinite(totalFrequencyBin) ? totalFrequencyBin : "0.00";
      return `${totalFrequencyBin} (Avg)`;
    },
    formatGridData: function (data, keys, reportMetaObj) {
      var format, value;
      var self = this;
      if (data) {
        _.each(data, function (item, index) {
          _.each(keys, function (metric) {
            format = reportMetaObj.fields[metric].type;
            value = !_.isUndefined(item[metric]) && !_.isNull(item[metric]) ? item[metric] : "--";
            item[metric] = self.formatValue(value, format);
            item.totalRow = index === 0 ? "total-row" : "";
          });
        });
      }
      return data;
    },
    formatValue: function (value, type) {
      if (type === "currency") {
        value = formatCurrency(value, null, "USD");
      } else {
        value = formatAs(type)(value);
      }
      return value;
    },
    sortByFrequencyBin: function (items, order) {
      var pattern = /\d+/g;
      return items.sort(function (a, b) {
        var frequencyBinA = Number(a.frequency_bin.match(pattern)[0]);
        var frequencyBinB = Number(b.frequency_bin.match(pattern)[0]);
        if (order === 1) {
          return frequencyBinA > frequencyBinB ? 1 : frequencyBinA < frequencyBinB ? -1 : 0;
        }
        return frequencyBinA < frequencyBinB ? 1 : frequencyBinA > frequencyBinB ? -1 : 0;
      });
    },
    getChartData: function (xKey, barMetric, lineMetric, barMetricLabel, lineMetricLabel) {
      let dataProvider, graphs;
      const COLL_DATA = this.collectionData;
      if (COLL_DATA && COLL_DATA.length) {
        this._parseData(COLL_DATA);
        this._createDataProvider(xKey, barMetric, lineMetric);
        this._createGraphs(barMetric, lineMetric, barMetricLabel, lineMetricLabel);
        dataProvider = this.get("dataProvider");
        graphs = this.get("graphs");
      }
      return {
        dataProvider: dataProvider || [],
        graphs: graphs || []
      };
    },
    _parseData: function (COLL_DATA) {
      const PARSED_DATA = this.sortByFrequencyBin(COLL_DATA.toJSON(), 1);
      this.set({
        parsedData: PARSED_DATA
      });
    },
    _createDataProvider: function (xKey, barMetric, lineMetric) {
      const DATA_PROVIDER = [];
      _.each(this.get("parsedData"), function (item) {
        const CHART_DATA_ITEM = {};
        CHART_DATA_ITEM.frequency_bin = item[xKey];
        CHART_DATA_ITEM[barMetric] = Number(item[barMetric]);
        CHART_DATA_ITEM[lineMetric] = Number(item[lineMetric]);
        DATA_PROVIDER.push(CHART_DATA_ITEM);
      });
      this.set({
        dataProvider: DATA_PROVIDER
      });
    },
    _createGraphs: function (barMetric, lineMetric, barMetricLabel, lineMetricLabel) {
      const SELF = this;
      function tooltipFunction(item) {
        const LINE_METRIC = SELF.get("lineMetric");
        const LINE_METRIC_OBJ = SELF.get("lineMetricObj");
        const BAR_METRIC_TYPE = SELF.get("barMetricObj").type;
        const LINE_METRIC_TYPE = LINE_METRIC_OBJ.type;
        const FREQUENCY_BIN = item.dataContext.frequency_bin;
        const LINE_METRIC_LABEL = LINE_METRIC_OBJ.name;
        const barMetricValue = item.dataContext[barMetric];
        const lineMetricValue = item.dataContext[LINE_METRIC];
        return `<span style="font-family:AB;"> Frequency Bin: ${FREQUENCY_BIN}</span><br><br>\n          <div>\n            <span style="color:${_COLOR_BAR_CHART};">●</span>\n            <span>${barMetricLabel}: ${SELF.formatValue(barMetricValue, BAR_METRIC_TYPE)}</span>\n          </div>\n          <div>\n            <span style="color:${_COLOR_LINE_CHART};">●</span>\n            <span>${LINE_METRIC_LABEL}: ${SELF.formatValue(lineMetricValue, LINE_METRIC_TYPE)}</span>\n          </div>`;
      }
      const GRAPHS = [{
        alphaField: "alpha",
        balloonFunction: tooltipFunction,
        fillAlphas: 1,
        fillColors: _COLOR_BAR_CHART,
        lineColor: _COLOR_BAR_CHART,
        title: barMetricLabel,
        type: "column",
        valueAxis: "barAxis",
        valueField: barMetric
      }, {
        balloonFunction: tooltipFunction,
        bullet: "round",
        bulletBorderAlpha: 1,
        bulletBorderColor: _COLOR_WHITE,
        bulletBorderThickness: 1,
        bulletSize: 5,
        fillAlphas: 0,
        id: "graph2",
        lineAlpha: 1,
        lineColor: _COLOR_LINE_CHART,
        lineThickness: 2,
        title: lineMetricLabel,
        type: "smoothedLine",
        valueAxis: "lineAxis",
        valueField: lineMetric
      }];
      this.set({
        graphs: GRAPHS
      });
    }
  });
  return ReachFrequencyModel;
});
