define('T1Filterable',["jQuery", "T1"], function ($, T1) {
  var Filter = function (config, fetch, resetPage, enableEvents) {
    var sortConfig;
    var criteria;
    var filterCriterias = [];
    var originalCriteria;
    var selected = false;
    var disableEvents = false;
    var bindings = {};
    function setFilter(criteria) {
      var complete = false;
      if (criteria === undefined) {
        throw Error("setFilter needs criteria as a parameter");
      }
      if (criteria.entity === undefined) {
        throw Error("entity is required in the criteria");
      }
      if (criteria.id === undefined) {
        throw Error("id is required in the criteria");
      }
      if (filterCriterias.length) {
        $.each(filterCriterias, function (index, value) {
          if (value.entity === criteria.entity) {
            value.id = criteria.id;
            criteria.fetchOnChange = value.fetchOnChange;
            selected = true;
            complete = true;
            return;
          } else if (!complete) {
            value.id = "";
          }
        });
        if (complete && criteria.fetchOnChange && criteria.fetchRequired) {
          fetch(criteria);
        }
        originalCriteria = filterCriterias;
      }
    }
    function setConfiguration(criterias) {
      if (!$.isArray(criterias)) {
        throw Error("Filter needs criteria has to be an array");
      }
      if (criterias.length <= 0) {
        throw Error("Filters is required in the criteria");
      }
      originalCriteria = filterCriterias = criterias;
    }
    function getString() {
      var prefix = "/limit";
      var filterString = "";
      var complete = false;
      if (selected) {
        $.each(filterCriterias, function (index, value) {
          if (value.id && !complete) {
            prefix = prefix + "/" + ((filterString ? filterString + "." : filterString) + value.entity + "=" + value.id);
            complete = true;
          } else {
            filterString = (filterString ? filterString + "." : filterString) + value.entity;
          }
        });
      }
      return complete ? prefix : "";
    }
    function clear(entity) {
      var complete = false;
      if (!entity) {
        selected = false;
      } else {
        $.each(filterCriterias, function (index, value) {
          if (!complete) {
            value.id = "";
          }
          if (entity === value.entity) {
            complete = true;
          }
        });
      }
    }
    function clearAll() {
      filterCriterias = [];
    }
    function resetCriteria() {
      if (originalCriteria) {
        filterCriterias = originalCriteria;
      }
    }
    function bindEvent(entity) {
      var eventName = entity + ":select";
      var callback = function (data) {
        var criteria = {
          entity: entity,
          id: data.id,
          fetchRequired: data.fetchRequired || true
        };
        if (disableEvents) {
          return;
        }
        if (data.id !== "") {
          setFilter(criteria);
        } else {
          clear(entity);
        }
        resetPage();
      };
      bindings[eventName] = callback;
      T1.EventHub.subscribe(eventName, callback);
    }
    function enableEventsListeners() {
      disableEvents = false;
    }
    function disableEventsListeners() {
      disableEvents = true;
    }
    function destroy() {
      for (var eventName in bindings) {
        T1.EventHub.unsubscribe(eventName, bindings[eventName]);
        bindings[eventName] = null;
      }
    }
    if (config === undefined) {
      throw Error("Paramerer Config undefined");
    }
    enableEvents = typeof enableEvents === "boolean" ? enableEvents : true;
    sortConfig = config;
    resetPage = resetPage || $.noop;
    if (sortConfig.length) {
      $.each(sortConfig, function (index, value) {
        criteria = {};
        criteria.entity = value.entity;
        criteria.id = "";
        criteria.fetchOnChange = value.fetchOnChange;
        filterCriterias.push(criteria);
        if (enableEvents) {
          bindEvent(value.entity);
        }
      });
    }
    return {
      destroy: destroy,
      set: setFilter,
      getString: getString,
      clear: clear,
      clearAll: clearAll,
      resetCriteria: resetCriteria,
      setConfiguration: setConfiguration,
      enableEventsListeners: enableEventsListeners,
      disableEventsListeners: disableEventsListeners
    };
  };
  return Filter;
});
