define('modules/audiences/onboarding/create/views/webCreateEdit',["jQuery", "T1", "modules/audiences/onboarding/create/views/onboardingForm", "modules/audiences/segments/segmentUtils"], function ($, T1, OnboardingCreateEdit, Util) {
  "use strict";

  var webPixelCreateEditView = OnboardingCreateEdit.extend({
    renderConfigs: {
      viewInBrowserEnabled: true
    },
    updateBreadCrumb: function () {
      var pathPieces;
      this.title = this.mode === "edit" ? this.model.name : "New Web Events";
      if (this.mode === "edit") {
        pathPieces = [{
          label: "Edit " + (this.model.name || ""),
          location: "#audiences/onboarding/web/edit/" + this.id
        }];
      } else {
        pathPieces = [{
          label: "New Web Events",
          location: "#audiences/onboarding/web/create"
        }];
      }
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
    },
    showViewTemplate: function () {
      let viewText = "";
      this.model.templates.map(pixel => {
        viewText += "<pre>" + pixel.replace(/</g, "&lt;").replace(/>/g, "&gt;") + "</pre>";
      });
      Polymer.dom(this.el[0].querySelector("#onboarding-modal-text")).innerHTML = viewText;
      this.el[0].querySelector("#view-onboarding-modal").show();
      this.deliveryChanged();
      this.viewOptions.makeViewCall = false;
      this.model.postLoadView = false;
    },
    successCallback: function (respObj, resp, e, postLoad) {
      var error = "";
      var viewOptions = {
        postSave: true
      };
      var returnedId;
      if (e) {
        Util.buttonReset(e.target, "Save");
        this.$(e.currentTarget).removeAttr("disabled");
      }
      this.el[0].querySelector("#onboarding-form").classList.remove("disabled");
      if (typeof resp === "string" && resp !== "success") {
        resp = $.parseJSON(resp);
        if ("errors" in resp) {
          if (Array.isArray(resp.errors) && resp.errors.length > 0) {
            error = error + resp.errors.join(";  ");
          }
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
        }
      } else {
        if (postLoad && postLoad == "download") {
          this.model.postLoadDownload = false;
          this.viewOptions.makeDownloadCall = false;
          var deliveryFormat = this.model.delivery_format.toLowerCase();
          var blob = new Blob([respObj], {
            type: deliveryFormat === "csv" ? "attachment/csv" : "text/plain"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var elem = document.body.appendChild(a);
          var handler = function () {
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, 1e3);
            document.body.removeChild(elem);
            elem.removeEventListener("click", handler);
          };
          a.href = url;
          a.download = "pixel_data." + deliveryFormat;
          elem.addEventListener("click", handler);
          elem.click();
          return;
        } else {
          this.model.id = respObj.data.id;
          viewOptions.makeViewCall = this.model.postLoadView;
          viewOptions.makeDownloadCall = this.model.postLoadDownload;
          if (this.mode === "edit") {
            this.load({
              viewOptions: viewOptions
            });
          } else {
            T1.Location.set("audiences/onboard/web/edit/" + this.model.id, {
              viewOptions: viewOptions
            });
          }
        }
      }
    },
    fetchDownload: function () {
      this.el[0].querySelector("#onboarding-form").classList.add("disabled");
      Util.buttonLoader({
        currentTarget: "#save-button"
      });
      this.model.delivery_format = "TXT";
      this.model.fetchDownload({
        success: (model, resp) => {
          Util.buttonReset($("#save-button"), "Save");
          this.successCallback.call(this, model, resp, null, "download");
        },
        error: (e, status, error) => {
          Util.buttonReset($("#save-button"), "Save");
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
        }
      });
    },
    handlePostLoadOptions: function (options, data) {
      if (options && options.postSave) {
        this.showFooterMsg();
      }
      if (options && options.makeViewCall) {
        this.showViewTemplate();
      }
      if (options && options.makeDownloadCall) {
        this.fetchDownload();
      }
    }
  });
  return webPixelCreateEditView;
});
