define('models/siteList',["jQuery", "Underscore", "Backbone", "T1", "T1Model", "utils/AdminUtils", "utils/SiteUtils"], function ($, _, Backbone, T1, T1Model, AdminUtils, SiteUtils) {
  "use strict";

  T1.Models.SiteList = T1Model.extend({
    sizeLimit: COMPASS_ENV.ADMIN_SITELIST_MAX_FILESIZE,
    chunkThreshold: COMPASS_ENV.ADMIN_SITELIST_CHUNKING_THRESHOLD,
    chunkLimit: COMPASS_ENV.ADMIN_SITELIST_CHUNK_SIZE,
    manualEntriesLimit: COMPASS_ENV.ADMIN_SITELIST_MAX_ENTRIES,
    url: "site_lists/",
    fixture: "-site_lists",
    defaults: {
      action: "include"
    },
    fetchOptions: {
      target_type: "*"
    },
    entryTypes: ["app", "domain"],
    initialize: function (options) {
      options.fetchOptions = $.extend(options.fetchOptions, this.fetchOptions);
      T1Model.prototype.initialize.apply(this, arguments);
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    updateStatus: function (attributes, options) {
      T1Model.prototype.save.call(this, attributes, options);
    },
    save: function (args) {
      var self = this;
      var dataObj = args.dataObj || null;
      var successCallback = args.successCallback || null;
      var errorCallback = args.errorCallback || null;
      var saveOpts = {
        success: function (data) {
          var onSuccess = successCallback || $.noop;
          onSuccess(data);
        },
        statusInvalid: errorCallback || $.noop,
        error: errorCallback || $.noop,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true
        },
        action: args.action
      };
      $.each(self.attributes, function (k) {
        if (k.indexOf("sites_count") === 0) {
          delete self.attributes[k];
        }
      });
      if (args.action) {
        self.sync("POST", dataObj, saveOpts);
      } else {
        T1Model.prototype.save.call(self, dataObj, saveOpts);
      }
    },
    delete: function (args) {
      var opts = args || {};
      if (!opts.action || opts.action !== "delete") {
        opts.action = "delete";
        opts.dataObj = {
          version: this.get("version")
        };
      }
      this.save(opts);
    },
    validateAttrs: function (attrs) {
      var validationResult, content;
      var errors = [];
      var optionalFields = ["status", "csv", "csverror", "entries"];
      var requiredField = "This is a required field.";
      var reqFields = {
        organization_id: function () {
          return !attrs.organization_id;
        },
        name: function () {
          return !attrs.name;
        },
        restriction: function () {
          return !attrs.restriction;
        }
      };
      var extraValFields = {
        name: function () {
          if (attrs.name.length > 64) {
            return "Name cannot be longer than 64 characters.";
          }
        },
        restriction: function () {
          if (attrs.restriction !== "EXCLUDE" && attrs.restriction !== "INCLUDE") {
            return "Restriction must be 'EXCLUDE' or 'INCLUDE'.";
          }
        }
      };
      var cleanedContent = [];
      var badCount = 0;
      if (this.isEdit) {
        optionalFields.push("filename");
        optionalFields.push("content");
        attrs.isEdit = true;
      } else {
        attrs.format = "csv";
        attrs.mode = "replace";
        reqFields.content = function () {
          if (!attrs.content || attrs.content.length === 0) {
            return requiredField;
          }
        };
      }
      content = (attrs.content || "").replace(/app:\s+/gi, "app:").replace(/(\r|\n|,)/g, " ").split(/\s+/);
      if (attrs.format !== "csv") {
        $.each(content, function (i, member) {
          var type = "bad";
          if (member !== "") {
            if (SiteUtils.isApp(member)) {
              member = SiteUtils.normalizeApp(member);
              type = "app";
            } else if (SiteUtils.isIPAddress(member) || SiteUtils.isExtendedDomain(member) || SiteUtils.isIDNDomain(member, true)) {
              member = SiteUtils.normalizeExtendedDomain(member);
              type = "domain";
            }
            if (type === "bad") {
              badCount++;
            } else {
              if (member.indexOf(type + ":") !== 0) {
                member = type + ":" + member;
              }
              cleanedContent.push(member);
            }
          }
        });
        if (badCount > 0) {
          errors.push({
            field: "entries",
            message: badCount + " entr" + (badCount === 1 ? "y" : "ies") + " in this list " + (badCount === 1 ? "is" : "are") + " invalid."
          });
        } else if (cleanedContent.length > this.manualEntriesLimit) {
          errors.push({
            field: "entries",
            message: cleanedContent.length + " entries were detected.  That exceeds the maximum of " + this.manualEntriesLimit + "."
          });
        }
        attrs.content = cleanedContent.join(" ");
      } else {
        attrs.content = content.join(" ");
      }
      if ("string" === typeof attrs.name) {
        attrs.name = attrs.name.trim();
      }
      $.each(attrs, function (key, value) {
        var errorKey = key === "content" ? attrs.format === "csv" ? "csverror" : attrs.format : key;
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: errorKey,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: errorKey,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: errorKey,
              message: requiredField
            });
          }
        } else if (errors.length === 0 && extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: errorKey,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    createEdit: function (rawData, options) {
      var validationErrors, chunkSize, chunkStart, chunkEnd, i;
      var self = this;
      var metaFields = ["csv", "csverror", "entries", "format", "isEdit", "mode"];
      var metaData = {};
      var action = options.action || "";
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var startChunking = options.startChunking || $.noop;
      var onChunk = options.onChunk || $.noop;
      var chunks = 0;
      var chunkedData = [];
      var content = "";
      var entryData = [];
      var chunkAction = function (count, returnedModel) {
        var next = count + 1;
        onChunk(next, chunkedData.length);
        if (!self.id && returnedModel && returnedModel.entity && returnedModel.entity.id) {
          self.id = returnedModel.entity.id;
        }
        self.save({
          dataObj: chunkedData[count],
          successCallback: chunkedData[next] ? function () {
            chunkAction(next, returnedModel);
          } : function () {
            AdminUtils.fetchData(T1.Models.SiteList, self.get("id")).then(function (data) {
              var returnedData = data.toJSON ? data.toJSON() : {};
              if (returnedModel && returnedModel.attributes) {
                returnedModel.attributes["version"] = returnedData.version;
                $.each(returnedModel.attributes, function (propName) {
                  if (propName.indexOf("sites_count") === 0) {
                    returnedModel.attributes[propName] = returnedData[propName];
                  }
                  delete returnedData[propName];
                });
                $.each(returnedData, function (k, v) {
                  if (k.indexOf("sites_count") === 0) {
                    returnedModel.attributes[k] = v;
                  }
                });
              }
              success(returnedModel);
            });
          },
          errorCallback: statusInvalid,
          action: "domains"
        });
      };
      validationErrors = self.validateAttrs(rawData);
      $.each(metaFields, function (ix, v) {
        metaData[v] = rawData[v];
        delete rawData[v];
      });
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        content = rawData.content || content;
        if (content) {
          chunks = 1;
          content = content.replace(/(\r|\n|,)/g, " ");
          if (content.length > self.chunkThreshold) {
            chunks = Math.floor(content.length / self.chunkLimit) + 1;
          }
        }
        if (metaData.mode === "add" && chunks > 0 || chunks > 1) {
          startChunking();
          delete rawData.content;
          content = content.split(/\s+/);
          $.each(content, function (iy, v) {
            var type, value;
            var foundType = false;
            $.each(self.entryTypes, function (j, t) {
              if (v.indexOf(t + ":") === 0) {
                var vParts = v.split(":");
                type = vParts.shift();
                value = vParts.join(":");
                foundType = true;
                return true;
              }
            });
            if (!foundType) {
              type = "domain";
              value = v;
            }
            entryData.push({
              name: type,
              value: value
            });
          });
          chunkSize = Math.floor(entryData.length / chunks);
          for (i = 0; i < chunks; i++) {
            chunkStart = i * chunkSize;
            chunkEnd = chunkStart + chunkSize;
            chunkedData.push(entryData.slice(chunkStart, chunkEnd));
          }
          if (metaData.mode !== "add") {
            rawData.content = [];
            $.each(chunkedData.shift(), function (iz, v) {
              if (!v.name || v.name === "domain") {
                v = v.value;
              } else {
                v = v.name + ":" + v.value;
              }
              if (v.indexOf(",") > 0) {
                v = '"' + v + '"';
              }
              rawData.content.push(v);
            });
            rawData.content = rawData.content.join(",");
          }
          self.save({
            dataObj: rawData,
            successCallback: function (returnedModel) {
              chunkAction(0, returnedModel);
            },
            errorCallback: statusInvalid,
            action: action
          });
        } else {
          if (rawData.content) {
            startChunking();
            rawData.content = rawData.content.replace(/domain:/gi, "");
          }
          self.save({
            dataObj: rawData,
            successCallback: success,
            errorCallback: statusInvalid,
            action: action
          });
        }
      }
    },
    addDomains: function (domainData, options) {
      var opts = options || {};
      var success = opts.success || $.noop;
      var statusInvalid = opts.statusInvalid || $.noop;
      this.save({
        dataObj: domainData,
        successCallback: success,
        errorCallback: statusInvalid,
        action: "domains"
      });
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    },
    reset: function () {
      this.attributes = {};
    }
  });
  return T1.Models.SiteList;
});
