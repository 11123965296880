define('modules/admin/myOrgs/organization/views/settings_readOnly',['require','modules/organization/utils/Utils','T1View','text!../templates/settings_readOnly.html'],function (require) {
  "use strict";

  const OrgUtils = require("modules/organization/utils/Utils");
  const T1View = require("T1View");
  const template = require("text!../templates/settings_readOnly.html");
  return T1View.extend({
    template: template,
    initialize() {},
    load() {
      this.render().then(() => {
        this.getData();
      });
    },
    getData() {
      OrgUtils.fetchOrgSettings(this.model.id).then(() => {
        this.render();
      });
    },
    serialize() {
      const {
        impressions_by_vcr: impressions_by_vcr,
        default_us_settings: default_us_settings
      } = OrgUtils.getOrgSettings(this.model.id);
      return {
        impressions_by_vcr: impressions_by_vcr ? "Enabled" : "Disabled",
        default_us_settings: default_us_settings ? "Enabled" : "Disabled"
      };
    }
  });
});
