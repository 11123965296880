define('modules/strategy/views/health',["jQuery", "Hogan", "T1View", "text!modules/strategy/templates/health.html", "text!modules/strategy/templates/healthChecklistColumn.html", "modules/strategy/views/helpers/healthInfoGenerator"], function ($, Hogan, T1View, template, healthChecklistColumn, HealthInfoGenerator) {
  "use strict";

  return T1View.extend({
    template: template,
    partials: {
      healthChecklistColumn: Hogan.compile(healthChecklistColumn)
    },
    events: {
      "click .w-foot .actions .close": "closeDialog"
    },
    formatDataIntoTwoColumns: function (data) {
      var index;
      var isFound = data.some(function (item, idx) {
        index = idx + 1;
        return item.message === "Current day/time within Strategy start/end";
      });
      return isFound ? [data.splice(0, index), data] : [];
    },
    formatDataForTemplate: function (data) {
      data = this.formatDataIntoTwoColumns(data);
      return {
        healthColumn: [{
          healthInformation: data[0]
        }, {
          healthInformation: data[1]
        }]
      };
    },
    renderUponSuccess: function (html, modalBody) {
      if (Polymer && Polymer.dom) {
        Polymer.dom(modalBody).innerHTML = html;
      } else {
        $(modalBody).html(html);
      }
    },
    initialize: function () {
      this.healthInfoGenerator = new HealthInfoGenerator({
        strategy: this.model
      });
    },
    closeDialog: function () {
      this.el.dialog("close");
    },
    renderModalContent: function (data) {
      var html = this.partials.healthChecklistColumn.render(data);
      this.renderUponSuccess(html, this.el.find(".w-body")[0]);
      if (this.el.dialog("isOpen") !== true && this.hasOpened === undefined) {
        this.el.dialog({
          closeText: "",
          autoOpen: true,
          modal: true,
          width: 760,
          close: function () {
            $(this).dialog("destroy").remove();
          }
        });
        this.hasOpened = true;
      }
    },
    load: function () {
      this.render().then(this.renderModalContent.bind(this));
    },
    updateModal: function (data) {
      if (this.el.dialog("isOpen") === true) {
        this.renderModalContent(this.formatDataForTemplate(data));
      }
    },
    serialize: function () {
      var templateData = this.healthInfoGenerator.getHealthInformation({
        success: this.updateModal.bind(this)
      });
      var result = this.formatDataForTemplate(templateData);
      result.name = this.model.get("name");
      return result;
    }
  });
});
