define('collections/targetingVendors',["jQuery", "T1", "T1Collection", "models/targetingVendor"], function ($, T1, T1Collection, TargetingVendor) {
  "use strict";

  T1.Models.TargetingVendors = T1Collection.extend({
    canCache: true,
    fetchOptions: {
      full: "*",
      with: "vendor"
    },
    searchConfiguration: {},
    model: TargetingVendor,
    urlString: "targeting_vendors"
  });
  return T1.Models.TargetingVendors;
});
