define('modules/auth/views/logout',["T1", "T1Comm", "T1View", "T1Permissions", "utils/Auth"], function (T1, T1Comm, T1View, T1Permissions, Auth) {
  return T1View.extend({
    initialize: function (args) {
      T1Comm.requestLogout({
        onSuccess: function () {
          if (T1Permissions.check("feature", "auth_0")) {
            Auth.logout();
          } else {
            T1.Location.set("", {
              external: true
            });
          }
        }
      });
    }
  });
});
