define('modules/campaign/chart/winLoss/views/campaignDailyChart',['require','Underscore','jQuery','modules/shared/configs/charts/lineChartOneAxisSeriesConfig','../models/mCampaignDailyChart','text!../templates/controlsCampaignDaily.html','T1','T1Layout','T1View','models/userPreferences'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const ChartConfig = require("modules/shared/configs/charts/lineChartOneAxisSeriesConfig");
  const ChartModel = require("../models/mCampaignDailyChart");
  const controlsTemplate = require("text!../templates/controlsCampaignDaily.html");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const UserPreferences = require("models/userPreferences");
  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    dataEvents: {
      ReportModel: {
        "change:ddlMetricOptions": "updateMetricsMenu"
      }
    },
    eventhubEvents: {
      "change:export": "exportChartHandler",
      "change:viewBy": function ({
        value: value
      }) {
        this.winLossCampaignDailyViewBy = value;
        this.preferences.save();
        this.ReportModel.setViewByDimension(value);
        T1Publish("fetch:initData");
      },
      "change:metric": function (metricObj) {
        this.winLossCampaignDailyMetric = metricObj.value;
        this.preferences.save();
        this.ReportModel.setSelectedMetrics("campaign", "daily", metricObj);
        this.updateChart();
      },
      "change:highestLowest": function ({
        range: range,
        metric: metric,
        excludeZeros: excludeZeros
      }) {
        this.winLossCampaignDailyIncludeOnlyRange = range;
        this.winLossCampaignDailyIncludeOnlyMetric = metric;
        this.winLossCampaignDailyIncludeOnlyExcludeZeros = excludeZeros;
        this.preferences.save();
        this.ReportModel.setHighestLowest({
          range: range,
          metric: metric,
          excludeZeros: excludeZeros
        });
        this.updateChart();
      },
      "change:interval": function ({
        value: value
      }) {
        this.winLossCampaignDailyInterval = value;
        this.preferences.save();
        this.ReportModel.setInterval(value);
      },
      "toggle:legend": function (value) {
        this.winLossCampaignDailyLegendTxt = value;
        this.preferences.save();
        this.ChartModel.setLegend(value);
        $(this.lineChart.legendDiv).toggle();
        this.lineChart.invalidateSize();
      },
      "complete:layoutLoad": function () {
        this.layoutsLoaded();
      }
    },
    intervalOptions: [{
      value: "by_day",
      label: "by Day"
    }, {
      value: "by_hour",
      label: "by Hour"
    }],
    highestLowestOptions: [{
      value: "all",
      label: "All"
    }, {
      value: "hi10",
      label: "Highest 10"
    }, {
      value: "hi20",
      label: "Highest 20"
    }, {
      value: "hi50",
      label: "Highest 50"
    }, {
      value: "hi100",
      label: "Highest 100"
    }, {
      value: "lo10",
      label: "Lowest 10"
    }, {
      value: "lo20",
      label: "Lowest 20"
    }, {
      value: "lo50",
      label: "Lowest 50"
    }, {
      value: "lo100",
      label: "Lowest 100"
    }],
    viewByOptions: [{
      value: "strategy",
      label: "Strategy"
    }, {
      value: "deal",
      label: "Deal"
    }, {
      value: "creative",
      label: "Creative"
    }, {
      value: "exchange",
      label: "Exchange"
    }],
    winLossCampaignDailyViewBy: "strategy",
    winLossCampaignDailyMetric: "win_rate",
    winLossCampaignDailyIncludeOnlyRange: "hi50",
    winLossCampaignDailyIncludeOnlyMetric: "wins",
    winLossCampaignDailyIncludeOnlyExcludeZeros: "false",
    winLossCampaignDailyInterval: "by_day",
    winLossCampaignDailyLegendTxt: "Hide",
    initialize({
      ReportModel: ReportModel,
      ParentClass: ParentClass
    }) {
      this.ReportModel = ReportModel;
      this.ParentClass = ParentClass;
      this.ChartModel = new ChartModel({}, this.ReportModel);
      this.buildPermissionedEvents();
      this.bindViewEvents();
      this.initPreferences();
      this.initLayout();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "monitor"], ["view", "campaign.chart"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["winLossCampaignDailyViewBy", "winLossCampaignDailyMetric", "winLossCampaignDailyIncludeOnlyRange", "winLossCampaignDailyIncludeOnlyMetric", "winLossCampaignDailyIncludeOnlyExcludeZeros", "winLossCampaignDailyInterval", "winLossCampaignDailyLegendTxt"]);
      this.ReportModel.setInterval(this.winLossCampaignDailyInterval, true);
      this.ReportModel.setViewByDimension(this.winLossCampaignDailyViewBy, true);
      this.ChartModel.setLegend(this.winLossCampaignDailyLegendTxt);
    },
    initLayout() {
      const selectedDimension = this.winLossCampaignDailyViewBy;
      const selectedInterval = this.winLossCampaignDailyInterval;
      const options = {
        getReportModel: () => this.ReportModel
      };
      function getSelectedDimensionName() {
        return this.viewByOptions.find(item => selectedDimension === item.value).label;
      }
      function getSelectedIntervalName() {
        return this.intervalOptions.find(item => selectedInterval === item.value).label;
      }
      this.layout = new T1Layout({
        el: () => this.ParentClass.$headerEl,
        template: controlsTemplate,
        layout: {
          ".dimensions-menu": [{
            module: "campaign/chart",
            viewType: "dimensionsMenu",
            options: {
              default: getSelectedDimensionName.call(this),
              dimensions: this.viewByOptions,
              id: 0,
              maxItems: 4,
              overflow: "visible",
              selected: selectedDimension
            }
          }],
          ".highest-lowest-menu": [{
            module: "campaign/chart",
            viewType: "highestLowestMenu",
            options: {
              ranges: this.highestLowestOptions
            }
          }],
          ".metrics-menu": [{
            module: "campaign/chart",
            viewType: "metricsMenu",
            options: {
              id: 0,
              maxItems: 7,
              overflow: "visible",
              overflowWidth: 165
            }
          }],
          ".date-picker": [{
            module: "campaign/chart",
            options: options,
            viewType: "datePicker"
          }],
          ".interval-menu": [{
            module: "campaign/chart",
            viewType: "intervalMenu",
            options: {
              default: getSelectedIntervalName.call(this),
              id: 0,
              intervals: this.intervalOptions,
              selected: selectedInterval
            }
          }],
          ".export-menu": [{
            module: "campaign/chart",
            options: options,
            viewType: "exportMenu"
          }],
          ".info": [{
            module: "reporting/campaigns",
            options: options,
            viewType: "report_info"
          }],
          ".legend-toggle": [{
            module: "campaign/chart",
            options: {
              getChartModel: () => this.ChartModel
            },
            viewType: "legendToggle"
          }]
        }
      });
      this.layoutsLoaded = _.after(_.size(this.layout.layout), () => {
        this.ParentClass.layoutLoaded();
      });
      this.layout.load();
    },
    updateMetricsMenu(model, metricOpts) {
      const ReportModel = this.ReportModel;
      let metric = this.winLossCampaignDailyMetric;
      let highestLowest = this.winLossCampaignDailyIncludeOnlyMetric;
      const metricExists = _.find(metricOpts, ({
        value: value
      }) => value === metric);
      const highestLowestExists = _.find(metricOpts, ({
        value: value
      }) => value === highestLowest);
      if (!metricExists) {
        this.winLossCampaignDailyMetric = metric = "win_rate";
        this.preferences.save();
      }
      if (!highestLowestExists) {
        this.winLossCampaignDailyIncludeOnlyMetric = highestLowest = "wins";
        this.preferences.save();
      }
      ReportModel.setSelectedMetrics("campaign", "daily", {
        id: 0,
        value: metric
      });
      ReportModel.setHighestLowest({
        excludeZeros: this.winLossCampaignDailyIncludeOnlyExcludeZeros,
        metric: highestLowest,
        range: this.winLossCampaignDailyIncludeOnlyRange
      });
      T1Publish("change:metrics", {
        default: ReportModel.getSelectedMetricLabel(metric),
        id: 0,
        metrics: metricOpts,
        selected: metric
      });
      T1Publish("change:metricsHighestLowest", {
        excludeZeros: this.winLossCampaignDailyIncludeOnlyExcludeZeros,
        filter1: this.winLossCampaignDailyIncludeOnlyRange,
        filter2: highestLowest,
        id: 0,
        metrics: metricOpts,
        title: ReportModel.getHighestLowest().title
      });
    },
    updateMetricType() {
      const selectedMetric = this.ReportModel.getSelectedMetrics().campaign.daily[0];
      const metricOptions = this.ReportModel.reportMeta.get("metricOptions");
      const metricObj = metricOptions.find(item => item.value === selectedMetric.value);
      metricObj.label = selectedMetric.label;
      this.metricObj = metricObj;
    },
    formatValue(value) {
      return this.ReportModel.formatValue(value, this.metricObj.type);
    },
    setupChart() {
      const ParentClass = this.ParentClass;
      const lineData = this.ChartModel.getChartData();
      const self = this;
      if (!lineData.dataProvider.length) {
        ParentClass.showNoDataGrid();
        return;
      }
      if (this.lineChart) {
        this.lineChart.clear();
        this.lineChart = null;
      }
      this.updateMetricType();
      const lineConfig = ChartConfig.copy();
      lineConfig.dataProvider = lineData.dataProvider;
      lineConfig.graphs = lineData.graphs;
      lineConfig.valueAxes[0].labelFunction = value => this.formatValue(value);
      lineConfig.valueAxes[0].title = this.metricObj.label;
      lineConfig.categoryAxis.minPeriod = this.ReportModel.getMinPeriod();
      lineConfig.legend.valueFunction = function (GraphDataItem) {
        return GraphDataItem.values ? self.formatValue(GraphDataItem.values.value) : "";
      };
      ParentClass.hideNoDataGrid();
      this.lineChart = AmCharts.makeChart("chartCanvas", lineConfig);
      if (this.ChartModel.getLegend() === "Show") {
        $(this.lineChart.legendDiv).hide();
        this.lineChart.invalidateSize();
      }
    },
    updateChart() {
      const lineChart = this.lineChart;
      if (!lineChart) {
        this.setupChart();
        return;
      }
      const ParentClass = this.ParentClass;
      const lineData = this.ChartModel.getChartData();
      if (!lineData.dataProvider.length) {
        ParentClass.showNoDataGrid();
        return;
      }
      this.updateMetricType();
      ParentClass.hideNoDataGrid();
      lineChart.dataProvider = lineData.dataProvider;
      lineChart.graphs = lineData.graphs;
      lineChart.valueAxes[0].title = this.metricObj.label;
      lineChart.categoryAxis.minPeriod = this.ReportModel.getMinPeriod();
      lineChart.validateData();
    },
    exportChartHandler(type) {
      switch (type) {
        case "png":
          this.lineChart.export.capture({}, function () {
            this.toPNG({}, function (data) {
              this.download(data, this.defaults.formats.PNG.mimeType, "CampaignDailyChart.png");
            });
          });
          break;
        case "print":
          this.lineChart.export.capture({}, function () {
            this.toPRINT();
          });
          break;
        case "pdf":
          break;
      }
    },
    unloadChart() {
      if (this.lineChart) {
        this.lineChart.clear();
        this.lineChart = null;
      }
      if (this.layout) {
        this.layout.destroy();
        this.layout = null;
      }
      this.unbind();
      this.ChartModel = null;
      this.metricObj = null;
      this.ParentClass = null;
      this.ReportModel = null;
    }
  });
});
