define('modules/reporting/campaigns/contextual/models/model',["jQuery", "Underscore", "T1", "moment", "models/reportingAbstractModel"], function ($, _, T1, moment, RPTGAbstractModel) {
  "use strict";

  var formatCurrency = T1.Utils.formatCurrency;
  var formatAs = T1.Formats.as;
  var COLOR_WHITE = "#fff";
  var COLOR_GREY = "#e5e5e5";
  var DATE_FORMAT = "YYYY-MM-DD";
  const LINE_THICKNESS = 3;
  var ContextualModel = RPTGAbstractModel.extend({
    searchTerm: "",
    initialize: function (options) {
      RPTGAbstractModel.prototype.initialize(options);
    },
    setSearchTerm: function (value) {
      this.searchTerm = value;
    },
    getSearchTerm: function () {
      return this.searchTerm;
    },
    formatValue: function (value, type) {
      if (type === "currency") {
        value = formatCurrency(value, null, "USD");
      } else {
        value = formatAs(type)(value);
      }
      return value;
    },
    getMaxMetricValue: function (data, metric) {
      var maxDataItem = _.max(data, item => Number(item[metric]));
      return Number(maxDataItem[metric]) || 1;
    },
    filterHiddenItems: function (data, dimensionKey) {
      var hiddenItems = this.get("hiddenItems") || {};
      if (!_.isEmpty(hiddenItems)) {
        _.each(hiddenItems, function (value, hiddenItem) {
          data = _.filter(data, dataItem => dataItem[dimensionKey] !== hiddenItem);
        });
      }
      return data;
    },
    getIsChartCrowded: function () {
      var endDate, startDate;
      var timeSeriesSlots = this.get("timeSeriesSlots") || null;
      const THREE_MONTHS = 90;
      if (timeSeriesSlots) {
        startDate = moment(timeSeriesSlots.start_date, DATE_FORMAT);
        endDate = moment(timeSeriesSlots.end_date, DATE_FORMAT);
        return endDate.diff(startDate, "days") > THREE_MONTHS;
      }
      return false;
    },
    getBarChartDataSet: function (data, dimensionKey, dimensionName, yKey, yAxisLabel, colorData) {
      var chartColors = this.reportMeta.get("chartColors");
      var chartData = {
        labels: [],
        datasets: [{
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          data: [],
          label: yAxisLabel,
          targetIds: [],
          yAxisID: "y-axis-1"
        }]
      };
      _.each(data, function (item) {
        var targetId = item[dimensionKey];
        var color = chartColors[colorData[targetId]];
        chartData.labels.push(item[dimensionName]);
        chartData.datasets[0].borderColor.push(color);
        chartData.datasets[0].backgroundColor.push(color);
        chartData.datasets[0].data.push(item[yKey]);
        chartData.datasets[0].targetIds.push(targetId);
      });
      return chartData;
    },
    getHighlightBarChartDataSet: function (data, dimensionKey, dimensionName, yKey, yAxisLabel, colorData, hightlightId) {
      var chartColors = this.reportMeta.get("chartColors");
      var chartData = {
        labels: [],
        datasets: [{
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          data: [],
          label: yAxisLabel,
          targetIds: [],
          yAxisID: "y-axis-1"
        }]
      };
      _.each(data, function (item) {
        var targetId = item[dimensionKey];
        var color = chartColors[colorData[targetId]];
        if (hightlightId === targetId) {
          chartData.datasets[0].borderColor.push(color);
          chartData.datasets[0].backgroundColor.push(color);
        } else {
          chartData.datasets[0].borderColor.push(COLOR_GREY);
          chartData.datasets[0].backgroundColor.push(COLOR_GREY);
        }
        chartData.labels.push(item[dimensionName]);
        chartData.datasets[0].data.push(item[yKey]);
        chartData.datasets[0].targetIds.push(targetId);
      });
      return chartData;
    },
    parseData: function (yKey, timeSlots, data, dimensionKey, dimensionName) {
      return _.chain(data).groupBy(model => model[dimensionKey]).mapObject(function (arr, key) {
        var values = [];
        var name = "";
        _.each(timeSlots, function (startDate) {
          var dataSet = _.find(arr, item => startDate === item.start_date);
          if (dataSet) {
            name = dataSet[dimensionName];
          }
          values.push(dataSet ? dataSet[yKey] : "");
        });
        return {
          id: key,
          name: name,
          values: values
        };
      }).value();
    },
    getLineChartDataSet: function (data, dimensionKey, dimensionName, yKey, yAxisLabel, colorData) {
      var chartColors = this.reportMeta.get("chartColors");
      var chartData = {
        datasets: [],
        labels: []
      };
      var timeSeriesSlots = this.get("timeSeriesSlots") || {
        slots: []
      };
      var tsSlots = _.pluck(timeSeriesSlots.slots, "start_date");
      var radiusValue = this.getIsChartCrowded() ? 0 : LINE_THICKNESS;
      chartData.labels = tsSlots;
      data = this.parseData(yKey, tsSlots, data, dimensionKey, dimensionName);
      chartData.datasets = _.map(data, function (item) {
        var color = chartColors[colorData[item.id]];
        return {
          borderColor: color,
          data: item.values,
          label: item.name,
          lineId: item.id,
          pointBackgroundColor: color,
          pointBorderColor: COLOR_WHITE,
          pointHoverRadius: radiusValue,
          radius: radiusValue,
          yAxisID: "y-axis-1"
        };
      });
      return chartData;
    },
    getHighlightLineChartDataSet: function (data, dimensionKey, dimensionName, yKey, yAxisLabel, colorData, hightlightId) {
      var chartColors = this.reportMeta.get("chartColors");
      var chartData = {
        datasets: [],
        labels: []
      };
      var timeSeriesSlots = this.get("timeSeriesSlots") || {
        slots: []
      };
      var tsSlots = _.pluck(timeSeriesSlots.slots, "start_date");
      var radiusValue = this.getIsChartCrowded() ? 0 : LINE_THICKNESS;
      var highlightLineIndex = -1;
      var highlightDataset = {};
      chartData.labels = tsSlots;
      data = this.parseData(yKey, tsSlots, data, dimensionKey, dimensionName);
      chartData.datasets = _.map(data, function (item) {
        var targetId = item.id;
        var color = COLOR_GREY;
        if (hightlightId === targetId) {
          color = chartColors[colorData[targetId]];
        }
        return {
          borderColor: color,
          data: item.values,
          label: item.name,
          lineId: item.id,
          pointBackgroundColor: color,
          pointBorderColor: COLOR_WHITE,
          pointHoverRadius: radiusValue,
          radius: radiusValue,
          yAxisID: "y-axis-1"
        };
      });
      highlightLineIndex = _.findIndex(chartData.datasets, {
        lineId: hightlightId
      });
      if (highlightLineIndex > 0) {
        highlightDataset = chartData.datasets.splice(highlightLineIndex, 1)[0];
        chartData.datasets.splice(0, 0, highlightDataset);
      }
      return chartData;
    }
  });
  return ContextualModel;
});
