define('modules/campaign/chart/winLoss/shared/canvasBar',[], function () {
  "use strict";

  const DURATION = 80;
  const Bar = function ({
    context: ctx,
    x: xPos,
    y: yPos,
    width: width,
    height: height,
    color: color
  }) {
    const getTime = () => new Date().getTime();
    return {
      animateHeight: function () {
        const start = getTime();
        const end = start + DURATION;
        const distance = height;
        const promise = new Promise(function (resolve) {
          const step = function () {
            const timer = getTime();
            const progress = Math.min((DURATION - (end - timer)) / DURATION, 1);
            const animHeight = distance * progress;
            ctx.fillStyle = color;
            ctx.fillRect(xPos, yPos, width, animHeight);
            if (progress < 1) {
              window.requestAnimationFrame(step);
            } else {
              resolve();
            }
          };
          step();
        });
        return promise;
      },
      draw: function (animateProp) {
        switch (animateProp) {
          case "animateHeight":
            return this.animateHeight();
          default:
            ctx.fillStyle = color;
            ctx.fillRect(xPos, yPos, width, height);
            return Promise.resolve();
        }
      },
      getBounds: function () {
        return {
          xMin: xPos,
          yMin: yPos,
          xMax: xPos + width,
          yMax: yPos + height
        };
      },
      contains: function (x, y) {
        const bounds = this.getBounds();
        if (bounds.xMin < x && bounds.xMax > x && bounds.yMin < y && bounds.yMax > y) {
          return true;
        }
        return false;
      }
    };
  };
  return Bar;
});
