define('modules/reporting/campaigns/contextual/views/chartTitle',["jQuery", "Underscore", "T1", "T1View", "text!../../templates/chart_title_box.html"], function ($, _, T1, T1View, template) {
  "use strict";

  return T1View.extend({
    template: template,
    eventhubEvents: {
      "update:metric": function () {
        this.reload();
      },
      "switch:chart": function (chartType) {
        this.subSection = chartType;
        this.reload();
      }
    },
    dataEvents: {
      reportModel: {
        "change:dimensionItems": function () {
          this.reload();
        },
        "change:hiddenCount": "updateSubtitle"
      }
    },
    initialize: function (args) {
      var models = args.models;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.summaryCollection = args.dataGroup.collection;
      this.dailyCollection = args.dataGroup.dailyCollection;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
      this.subSection = models.mainModel.get("contextualSubSection");
      this.timeSeriesLabel = models.timeSeries.daily.label;
    },
    getSubtitle: function () {
      var reportMetaObj = this.reportMetaObj;
      var reportModel = this.reportModel;
      var dimensionKey = reportModel.get("dimensionInfo").key;
      var focus = reportModel.get("focus") || "all";
      var metricKey = reportMetaObj.metrics[focus];
      var yKey = reportModel.get("metric");
      var chartCount, groupedData, yAxisLabel;
      var isDaily = this.subSection === "daily";
      var collection = isDaily ? this.dailyCollection : this.summaryCollection;
      var colorData = reportModel.get("colorData");
      var hiddenCount = colorData ? reportModel.getHiddenItemsCount() : 0;
      var total = 0;
      yKey = metricKey[yKey] ? yKey : reportMetaObj.metricOptions[0].value;
      yAxisLabel = metricKey[yKey].text;
      if (colorData) {
        if (isDaily) {
          groupedData = collection.groupBy(model => model.attributes[dimensionKey]);
          total = _.size(groupedData);
        } else {
          total = collection.length;
        }
      }
      if (hiddenCount === 0) {
        chartCount = ` (all ${total})`;
      } else {
        chartCount = total > 0 ? ` (${total - hiddenCount} of ${total})` : ` (0 of ${total})`;
      }
      if (isDaily) {
        return `Lines show ${this.timeSeriesLabel.toLowerCase()} ${yAxisLabel} by Target ${chartCount}`;
      }
      return `Bars show summary of ${yAxisLabel} by Target ${chartCount}`;
    },
    updateSubtitle: function () {
      this.updateDataBind({
        chart_subtitle: this.getSubtitle()
      }, this.el);
    },
    serialize: function () {
      return {
        chart_subtitle: this.getSubtitle(),
        chart_title: "Contextual"
      };
    }
  });
});
