define('collections/campaignSiteLists',["jQuery", "T1", "T1Collection", "models/siteList"], function ($, T1, T1Collection, SiteList) {
  "use strict";

  var originalInitialize;
  var collection = T1Collection.extend({
    model: SiteList,
    urlString: "campaigns",
    includedBinOperation: "OR",
    action: "site_lists",
    fixture: "-campaignSiteLists",
    url: function () {
      var originalString = this.urlString;
      var url;
      if (this.id === undefined) {
        throw new Error("Campaign id needs to be set in id property of collection to construct URL");
      }
      this.urlString = originalString + "/" + this.id + "/" + this.action;
      url = T1Collection.prototype.url.call(this);
      this.urlString = originalString;
      return url;
    },
    buildPostObj: function () {
      var resultObject = {};
      var counter = 1;
      this.each(function (model) {
        var assigned = model.get("assigned");
        resultObject["site_lists." + counter + ".id"] = model.id;
        resultObject["site_lists." + counter + ".assigned"] = assigned;
        counter++;
      });
      return resultObject;
    },
    saveSiteList: function (options, success, conflict) {
      var additionalOptions = $.extend(true, {
        data: this.buildPostObj()
      }, options || {});
      this.save(additionalOptions, {
        success: success,
        conflict: conflict
      });
    },
    parse: function () {
      var parsedData = T1Collection.prototype.parse.apply(this, arguments);
      $.each(parsedData, function (index, element) {
        element.action = "include";
      });
      return parsedData;
    }
  });
  originalInitialize = collection.prototype.initialize;
  collection.prototype.initialize = function (models, options) {
    options = options || {};
    options.isPaginated = false;
    return originalInitialize.call(this, models, options);
  };
  T1.Models.CampaignSiteList = collection;
  return T1.Models.CampaignSiteList;
});
