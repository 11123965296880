define('collections/organizations',['require','jQuery','models/organization','./../modules/organization/utils/Utils','T1Sortable','T1','T1Collection','utils/UserDataUtils','models/userPreferences','T1Preferences','T1Signature'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Organization = require("models/organization");
  const OrgUtils = require("./../modules/organization/utils/Utils");
  const Sortable = require("T1Sortable");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  const UserDataUtils = require("utils/UserDataUtils");
  const UserPreferences = require("models/userPreferences");
  const T1Preferences = require("T1Preferences");
  const T1Signature = require("T1Signature");
  var instance;
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name"
      }
    },
    default: {
      sort_by: "name"
    }
  };
  var OrganizationCollection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    model: Organization,
    urlString: "organizations",
    selected: null,
    checked: null,
    searchConfiguration: {
      fields: {
        name: {
          type: "string",
          useWildCard: true
        },
        status: {
          type: "enum"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    postEntityParse: {
      legacy_contract: T1.Utils.objectTOArray,
      contract: T1.Utils.objectTOArray
    },
    initialize(options = {}) {
      T1Collection.prototype.initialize.call(this, null, options);
      this.fetchOptions = options.fetchOptions || this.fetchOptions;
      this.currentSearchConfig = [];
    },
    getSelected() {
      var collection = this;
      var deferredOrgs = $.Deferred();
      var selected = collection.selected;
      var callback = function () {
        deferredOrgs.resolve(collection.selected);
        callback = function () {};
      };
      function markSelected() {
        collection.setSelected(selected || collection.at(0).id);
        deferredOrgs.resolve(collection.selected);
        collection.fetching = false;
        UserDataUtils.addData("onOrganizationLoaded", collection);
      }
      function fetchOrgs() {
        collection.fetching = true;
        collection.fetch({
          success: function () {
            if (collection.length) {
              if (selected && !collection.get(selected)) {
                const notAvailable = 404;
                const selectedOrg = collection.at(collection.length - 1);
                const originalId = selectedOrg.id;
                selectedOrg.id = selected;
                selectedOrg.fetch({
                  success: function (orgModel) {
                    collection.selectedOrgModel = orgModel;
                    markSelected();
                  },
                  error: function () {
                    selected = null;
                    selectedOrg.id = originalId;
                    markSelected();
                  },
                  errorDisplaySuppressingConfig: {
                    errorCodes: [notAvailable]
                  }
                });
              } else {
                markSelected();
              }
            } else {
              deferredOrgs.resolve(selected);
              collection.fetching = false;
            }
          }
        });
      }
      if (!collection.length && !collection.fetching) {
        fetchOrgs();
      } else {
        if (selected) {
          deferredOrgs.resolve(collection.selected);
        } else {
          collection.bind("organization:select", callback, collection);
        }
      }
      return deferredOrgs.promise();
    },
    fetchSelectedOrg(successCallback) {
      var collection = this;
      if (collection.length && collection.selected) {
        const selectedOrg = collection.at(collection.length - 1);
        selectedOrg.id = collection.selected;
        selectedOrg.fetch({
          success: successCallback || function (orgModel) {
            collection.selectedOrgModel = orgModel;
          }
        });
      }
    },
    getSelectedOrgs() {
      const checkedModels = this.filter(model => model.get("checked") === true);
      return new T1Collection(checkedModels);
    },
    toggleAllSelected(isSelected) {
      this.each(function (model) {
        model.set({
          checked: isSelected
        });
      });
    },
    setSelected(orgId, options) {
      var collection = this;
      var previousOrg = collection.selected;
      var deferredOrgs = $.Deferred();
      var settings = $.extend({}, options);
      const isFromSelect = Boolean(settings.fromSelect);
      var implicitChange = settings.implicitChange || false;
      function setOrg() {
        var T1EventHub = T1.EventHub;
        var continueProcess = function () {
          T1EventHub.unsubscribe("organization:continue", continueProcess);
          T1EventHub.publish("organization:select", {
            id: orgId
          });
          if (previousOrg === orgId) {
            deferredOrgs.resolve();
            return;
          }
          if (!implicitChange) {
            T1EventHub.publish("organization:explicitChange", {
              id: orgId
            });
          }
          deferredOrgs.resolve();
        };
        collection.selected = orgId;
        selectedOrg.set({
          selected: true
        });
        OrgUtils.fetchOrgSettings(orgId, true);
        collection.preferences.save({
          success: function () {
            T1EventHub.subscribe("organization:continue", continueProcess);
            T1EventHub.publish("organization:refreshUI", true);
            if (isFromSelect) {
              UserDataUtils.addData("onOrganizationLoaded", collection);
            }
          }
        });
        collection.trigger("organization:select");
      }
      if (collection.selected && collection.get(collection.selected)) {
        collection.get(collection.selected).set({
          selected: false
        });
      }
      let selectedOrg = collection.get(orgId);
      if (!selectedOrg) {
        collection.add({
          id: orgId
        });
        selectedOrg = collection.get(orgId);
        selectedOrg.fetch({
          success: function () {
            setOrg();
          }
        });
      } else {
        setOrg();
      }
      return deferredOrgs.promise();
    },
    applySearchFilter(field, term) {
      if (field !== undefined && term !== undefined) {
        let fieldIndex = -1;
        const searchType = {
          term: term,
          searchField: field
        };
        $.each(this.currentSearchConfig, function (index, value) {
          if (value.searchField === "name|id" && field === "multiField") {
            value.searchField = "multiField";
          }
          if (value.searchField === "multiField" && field === "name|id") {
            value.searchField = "name|id";
          }
          if (value.searchField === field) {
            fieldIndex = index;
          }
        });
        if (fieldIndex === -1) {
          if (term !== "") {
            this.currentSearchConfig.push(searchType);
          }
        } else {
          if (term === "") {
            this.currentSearchConfig.splice(fieldIndex, 1);
          } else {
            this.currentSearchConfig[fieldIndex] = searchType;
          }
        }
      }
    },
    filterBy(field, term, onSuccess = $.noop) {
      this.applySearchFilter(field, term);
      this.search.set({
        params: this.currentSearchConfig,
        fetchRequired: true,
        success: coll => {
          this.trigger("reset");
          onSuccess(coll);
        }
      });
    }
  });
  T1.Models.Organizations = Sortable.extendCollection(OrganizationCollection, sortConfiguration);
  function getOrganizations(args) {
    if (instance === undefined) {
      const signature = T1.Signature.create();
      instance = new T1.Models.Organizations(args);
      signature.set(new Map([["root", "ui"], ["model", "organizations"]]));
      instance.preferences = T1.Preferences.create.call(instance, signature, UserPreferences);
      instance.preferences.mark(["selected"]);
    }
    return instance;
  }
  return {
    getOrganizations: getOrganizations,
    createOrganizations: function (args) {
      return new T1.Models.Organizations(args);
    }
  };
});
