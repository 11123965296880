define('modules/strategy/supply/open/views/readOnly',['require','jQuery','T1','T1View','text!../templates/readOnly.html','utils/LaunchDarklyUtils','collections/strategySupplySources','utils/CampaignStrategyUtils','utils/currencyConversionHelper','T1UtilsAsync','T1Permissions'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/readOnly.html");
  const LaunchDarklyUtils = require("utils/LaunchDarklyUtils");
  const StrategySupplySources = require("collections/strategySupplySources");
  const Utils = require("utils/CampaignStrategyUtils");
  const CurrencyUtils = require("utils/currencyConversionHelper");
  const T1UtilsAsync = require("T1UtilsAsync");
  const T1Permissions = require("T1Permissions");
  var currencyFormatterGetter = T1.Utils.getCurrencyTemplateFunc;
  var readOnlyView = T1View.extend({
    template: template,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": "prepareData"
    },
    initialize: function ({
      model: model,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit,
      targetDimensions: targetDimensions
    }) {
      this.model = model;
      this.collection = new StrategySupplySources([], {
        isPaginated: false
      });
      this.collection.id = model.id;
      this.collection.strategy = model;
      this.canLoad = false;
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.isFacebookStrategy = model.get("supply_type") === "BATCH";
      this.isSourceFeatureEnabled = T1Permissions.check("feature", "source");
      const advSourceProp = this.model.get("campaign").get("advertiser").get("accountable_and_addressable");
      this.checkSourceForAll = advSourceProp === "1" && this.isSourceFeatureEnabled;
      if (this.isFacebookStrategy && model.get("run_on_all_exchanges")) {
        model.unset("run_on_all_exchanges");
      }
      this.targetDimensions = targetDimensions;
      const isDimensionDataReady = this.targetDimensions.get(Utils.names.targetDimensions).loaded;
      if (isDimensionDataReady && isDimensionDataReady === true || this.isFacebookStrategy) {
        this.prepareData();
      } else {
        this.bindViewEvents();
      }
    },
    load: function () {
      this.render().then(() => {
        const loader = this.loader();
        if (!this.canLoad || this.canLoad === false) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!this.hasData) {
            this.$(".section-heading").css({
              opacity: .5
            });
          }
          this.$(".no-selection").css({
            display: "inline-block"
          });
          T1.Truncate(this.el, {
            el: ".deal-id .title",
            tooltip: true
          });
        }
      });
    },
    prepareData: function () {
      var self = this;
      var prepareData = Utils.prepareTargetDimensionData;
      var data = this.targetDimensions;
      this.foldPositionData = prepareData(data, dimCode => dimCode === "FOLD");
      this.sourceType = "open";
      this.isSelectionAll = false;
      this.collection.fetchOptions = {
        currency_code: this.getCurrencyCode()
      };
      this.collection.singleFetch = true;
      this.collection.isPaginated = false;
      const agencyId = this.model.get("campaign").get("advertiser").get("agency_id");
      LaunchDarklyUtils.getPromise().then(() => {
        LaunchDarklyUtils.updateAgency(agencyId);
        this.collection.fetch({
          success: function (resp) {
            let collection = self.collection;
            const model = self.model;
            let exchangeSupplyType, runOnAllExchanges, runOnAllPmp;
            const campaign = model.get("campaign");
            const advertiserId = campaign.get("advertiser_id");
            const currencyCode = campaign.get("currency_code");
            const permissionedFetch = T1UtilsAsync.makeDeferral();
            const permissionedPromise = permissionedFetch.promise;
            self.isSelectionAll = true;
            collection.reset(resp, {
              silent: true
            });
            if (collection.containsPMPDeals()) {
              collection.fetchPermissionedDeals(advertiserId, currencyCode, permissionedFetch, false);
            } else {
              permissionedFetch.resolve();
            }
            permissionedPromise.then(() => {
              self.canLoad = true;
              exchangeSupplyType = model.get("exchange_type_for_run_on_all");
              runOnAllExchanges = model.get("run_on_all_exchanges") === "1";
              runOnAllPmp = model.get("run_on_all_pmp") === "1";
              if (self.checkSourceForAll) {
                if (exchangeSupplyType === "SOURCE" && runOnAllExchanges === true) {
                  self.supplySelectionText = "Run on all SOURCE exchanges";
                  self.openSelectionAll = true;
                } else if (exchangeSupplyType === "ALL_ELIGIBLE" && runOnAllExchanges === true) {
                  self.supplySelectionText = "Run on all Open supply sources";
                  self.openSelectionAll = true;
                }
              } else {
                if (runOnAllExchanges === true) {
                  self.supplySelectionText = "Run on all Open supply sources ";
                  self.openSelectionAll = true;
                }
              }
              if (runOnAllPmp === true) {
                self.pmpeSelectionAll = true;
              }
              if (collection.contentType === "deal") {
                collection = CurrencyUtils.updatePMPePriceWithCurrency(collection, currencyCode);
              }
              self.load();
            });
          }
        });
      });
    },
    serialize: function () {
      var self = this;
      const {
        model: strategyModel,
        collection: collObj,
        foldPositionData: collObjDims
      } = this;
      var createDataBlock = Utils.createGroupedDataArray;
      var openSupplyTypeRef = {
        MARKETPLACE: "Marketplaces",
        STANDARD: "Open Exchanges & SSPs",
        BATCH: "Facebook"
      };
      var resultJSON = {
        dataMain: [],
        dataSecondary: []
      };
      var codeNameHash = {
        STANDARD: [],
        MARKETPLACE: [],
        BATCH: []
      };
      const deals = [];
      const dealGroups = [];
      const sitePlacements = [];
      const displayDateFormat = "MM/dd/yyyy";
      var getDateDisplay = function (rawVal, isEndDate) {
        var tmpDt = rawVal ? Date.parse(rawVal) : null;
        if (tmpDt) {
          if (isEndDate === true) {
            const neverYear = 2999;
            const neverMonth = 11;
            const neverDay = 31;
            const neverDate = new Date(neverYear, neverMonth, neverDay, 0, 0, 0);
            if (tmpDt >= neverDate) {
              return "Never";
            }
          }
          return tmpDt.toString(displayDateFormat);
        }
        return rawVal;
      };
      var getFoldPositionData = function () {
        if (collObjDims) {
          const targetList = [{
            id: 45054,
            txt: "Above"
          }, {
            id: 45055,
            txt: "Below"
          }, {
            id: 45056,
            txt: "Uncategorized"
          }];
          const list = [];
          $.each(targetList, function (index, target) {
            if (collObjDims.get(target.id)) {
              list.push(target.txt);
            }
          });
          if (list.length) {
            return {
              sideTitle: "fold position",
              data: list
            };
          }
        }
        return null;
      };
      if (this.canEdit) {
        resultJSON.editLink = `${this.editLinkPrefix}/supply`;
      }
      resultJSON.hasData = this.canLoad;
      resultJSON.isSelectionAll = this.isSelectionAll;
      resultJSON.openSelectionAll = this.openSelectionAll;
      resultJSON.pmpeSelectionAll = this.pmpeSelectionAll;
      function testForEntityExistence(models = [], key, value) {
        return models.some(entity => entity.get(key) === value);
      }
      const models = this.collection.models;
      resultJSON.hasOpen = testForEntityExistence(models, "type", "supply_source") || resultJSON.openSelectionAll;
      resultJSON.hasDeals = testForEntityExistence(models, "type", "deal") || resultJSON.pmpeSelectionAll;
      resultJSON.hasDealGroups = testForEntityExistence(models, "entity_type", "deal_group");
      resultJSON.hasPmpd = testForEntityExistence(models, "type", "site_placement");
      resultJSON.hasPmpe = resultJSON.hasDeals || resultJSON.hasDealGroups;
      resultJSON.hasDealsAndDealGroups = resultJSON.hasDeals && resultJSON.hasDealGroups;
      if (!resultJSON.hasPmpe && !resultJSON.hasPmpd && !resultJSON.hasOpen) {
        resultJSON.hasData = false;
        resultJSON.noSelectionContent = "no selections";
      }
      if (resultJSON.hasOpen) {
        if (resultJSON.openSelectionAll) {
          resultJSON.supplySelectionText = this.supplySelectionText;
        }
        resultJSON.supplyTitle = "open supply";
      }
      if (resultJSON.hasDeals || resultJSON.pmpeSelectionAll) {
        resultJSON.dealSupplyTitle = "private marketplace<br/>exchange deals";
      }
      if (resultJSON.hasDealGroups) {
        resultJSON.dealGroupsSupplyTitle = "private marketplace<br/>exchange groups";
      }
      if (resultJSON.hasPmpd) {
        resultJSON.pmpdSupplyTitle = "private marketplace<br/>direct";
      }
      if (strategyModel.get("supply_type") === "RMX_API") {
        resultJSON.hasData = false;
        resultJSON.noSelectionContent = "External Media Tracking";
      }
      if (strategyModel.get("supply_type") === "BATCH" && !collObj.models.length) {
        resultJSON.hasData = false;
        resultJSON.noSelectionContent = "no selections";
      }
      if (!resultJSON.hasData) {
        this.hasData = resultJSON.hasData;
        return resultJSON;
      }
      resultJSON.foldPositionData = getFoldPositionData();
      resultJSON.hasFold = resultJSON.foldPositionData !== null;
      if (resultJSON.isSelectionAll) {
        if (resultJSON.pmpeSelectionAll) {
          resultJSON.pmpeSelectionAllText = "Run on all deals";
        }
        this.hasData = resultJSON.hasData;
      } else if (!collObj.models.length && !resultJSON.hasFold) {
        resultJSON.hasData = false;
        resultJSON.noSelectionContent = "no selections";
        this.hasData = resultJSON.hasData;
        return resultJSON;
      }
      $.each(collObj.models, function (index, model) {
        const type = model.get("type") || model.get("entity_type");
        if (type === "deal") {
          deals.push({
            name: model.get("name"),
            deal_id: model.get("deal_identifier"),
            start: getDateDisplay(model.get("start_datetime")),
            end: getDateDisplay(model.get("end_datetime"), true),
            asCurrency: currencyFormatterGetter(model.get("currency_code")),
            price: model.get("price"),
            price_type: model.get("price_type")
          });
        } else if (type === "deal_group") {
          dealGroups.push({
            name: model.get("name"),
            group_id: model.get("id")
          });
        } else if (type === "site_placement") {
          sitePlacements.push({
            name: model.get("display_text"),
            publisher: model.get("name"),
            site: model.get("publisher_site_id")
          });
        } else {
          let tmpArr = codeNameHash[model.get("rtb_type")];
          if (tmpArr) {
            if (self.checkSourceForAll || model.get("accountable_and_addressable") !== "1") {
              tmpArr.push(model.get("name"));
            }
          } else if (self.isFacebookStrategy) {
            tmpArr = codeNameHash[model.get("supply_type")];
            tmpArr.push(model.get("name"));
          }
        }
      });
      if (resultJSON.hasDeals) {
        resultJSON.pmpe = [{
          items: deals
        }];
      }
      if (resultJSON.hasDealGroups) {
        resultJSON.dealGroups = [{
          items: dealGroups
        }];
      }
      if (resultJSON.hasPmpd) {
        resultJSON.sitePlacements = [{
          items: sitePlacements
        }];
      }
      if (resultJSON.hasOpen) {
        resultJSON.openSupply = [{
          sideTitle: "will not show",
          data: createDataBlock(codeNameHash, openSupplyTypeRef)
        }];
        if (resultJSON.openSupply[0].data.length === 0 && !resultJSON.supplySelectionText) {
          resultJSON.hasData = false;
          resultJSON.noSelectionContent = "no selections";
        }
      }
      this.hasData = resultJSON.hasData;
      return resultJSON;
    }
  });
  return readOnlyView;
});
