define('modules/strategy/views/duplicateStrategySettings',["jQuery", "async", "T1", "T1View", "models/campaign", "models/strategy", "T1Timezones", "moment", "text!modules/strategy/templates/duplicateStrategySettings.html", "utils/CampaignStrategyUtils", "T1DatePicker"], function ($, async, T1, T1View, CampaignModel, StrategyModel, T1Timezones, Moment, template, CampaignStrategyUtils) {
  "use strict";

  const displayDateFormat = "MMMM d, yyyy";
  const displayTimeFormat = "h:mm tt";
  const startBufferMin = Object.freeze({
    minutes: 10
  });
  var DuplicateStrategySettingsView = T1View.extend({
    template: template,
    status: 0,
    startTime: "12:00 AM",
    endTime: "11:59 PM",
    useCampaignStart: true,
    useCampaignEnd: true,
    events: {
      "click .campaign-start-date": "onCampaignStartDateClick",
      "click .campaign-end-date": "onCampaignEndDateClick"
    },
    eventhubEvents: {
      "campaign:selected": function (val) {
        this.fetchCampaign(val.id || val.value);
      },
      "strategy:containsVideo": function ({
        containsVideo: containsVideo,
        initialLoad: initialLoad
      }) {
        this.containsVideo = containsVideo;
        if (!initialLoad) {
          this.updateVideoTargeting();
        }
      },
      "startDatePicker:select": function () {
        this.onDatePickerSelect(this.el, "start");
      },
      "endDatePicker:select": function () {
        this.onDatePickerSelect(this.el, "end");
      },
      onCloseInlineEdit: function () {
        this.onDatePickerClose(this.el);
      },
      "campaign:datesUpdated": "handleDateChange"
    },
    initialize: function ({
      isStandalone: isStandalone,
      campaign: campaign,
      startDate: startDate,
      endDate: endDate,
      zone_name: zone_name
    }) {
      this.isStandalone = isStandalone !== undefined ? isStandalone : true;
      this.preventDoubleBind = true;
      this.bindViewEvents();
      if (!this.isStandalone) {
        this.model = new StrategyModel();
        this.model.set({
          campaign: campaign,
          use_campaign_start: "1",
          use_campaign_end: "1",
          start_date: startDate,
          end_date: endDate,
          zone_name: zone_name
        });
        this.model.startDate = startDate;
        this.model.endDate = endDate;
        this.containsVideo = false;
      }
    },
    fetchCampaign: function (campaignId) {
      var campaign = new CampaignModel({
        id: campaignId
      });
      var deferred = $.Deferred();
      campaign.fetch({
        success: data => {
          this.campaign = data;
          T1.EventHub.publish("campaign:toSelected", data);
          this.model.set({
            campaign: data
          });
          this.prepareDatePicker();
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    validateDateHasPassed: function (opts, callback) {
      this.timezones.validateStartDate(startBufferMin, opts.date, opts.tz, {
        onSuccess: function () {
          callback(null, false);
        },
        onError: function () {
          callback(null, true);
        }
      });
    },
    prepareDatePicker: function () {
      var strategy = this.model;
      var today = new Date();
      var startDate = strategy.startDate || today.clone();
      var endDate = strategy.endDate || today.clone();
      var campaign = strategy.get("campaign") || this.model;
      var campaignStartDate = strategy.get("use_campaign_start") === "1" ? Date.parse(campaign.get("initial_start_date")) : Date.parse(campaign.get("start_date"));
      var campaignEndDate = Date.parse(campaign.get("end_date"));
      var strategyStartDate = Date.parse(strategy.get("start_date"));
      var strategyEndDate = Date.parse(strategy.get("end_date"));
      var timeZone = campaign.get("zone_name");
      var newStartDate = today.clone();
      var useCampaignStart = strategy.get("use_campaign_start") === "1";
      var useCampaignEnd = strategy.get("use_campaign_end") === "1";
      if (useCampaignStart) {
        strategyStartDate = campaignStartDate;
      }
      if (useCampaignEnd) {
        strategyEndDate = campaignEndDate;
      }
      strategy.zoneName = timeZone;
      async.parallel({
        campaignStart: callback => {
          this.validateDateHasPassed({
            date: campaignStartDate,
            tz: timeZone
          }, callback);
        },
        campaignEnd: callback => {
          this.validateDateHasPassed({
            date: campaignEndDate,
            tz: timeZone
          }, callback);
        },
        strategyStart: callback => {
          this.validateDateHasPassed({
            date: strategyStartDate,
            tz: timeZone
          }, callback);
        },
        strategyEnd: callback => {
          this.validateDateHasPassed({
            date: strategyEndDate,
            tz: timeZone
          }, callback);
        }
      }, (err, results) => {
        let addThirtyDaysToStart;
        this.campaignHasStarted = results.campaignStart;
        this.campaignHasEnded = results.campaignEnd;
        this.strategyHasStarted = results.strategyStart;
        this.strategyHasEnded = results.strategyEnd;
        this.useCampaignStart = strategy.get("use_campaign_start") === "1" && !this.campaignHasStarted;
        this.useCampaignEnd = strategy.get("use_campaign_end") === "1" && !this.campaignHasEnded;
        this.disableCampaignStart = this.campaignHasStarted;
        this.disableCampaignEnd = this.campaignHasEnded;
        if (this.campaignHasStarted) {
          if (this.strategyHasStarted) {
            newStartDate = Moment().tz(this.model.get("zone_name")).format("ddd MMM D YYYY hh:mm:ss");
            newStartDate = Date.parse(newStartDate);
            newStartDate = this.getStartDateWithBuffer(newStartDate);
            if (!this.campaignHasEnded && Date.compare(newStartDate, campaignEndDate) === 1) {
              newStartDate.setDate(startDate.getDate());
            }
            startDate = newStartDate;
          } else {
            startDate = strategyStartDate;
          }
        } else {
          startDate = this.useCampaignStart ? campaignStartDate : strategyStartDate;
        }
        if (this.campaignHasEnded) {
          if (this.strategyHasEnded) {
            addThirtyDaysToStart = true;
          } else {
            endDate = strategyEndDate;
          }
        } else {
          endDate = this.useCampaignEnd ? campaignEndDate : strategyEndDate;
        }
        if (Date.compare(startDate, endDate) === 1) {
          addThirtyDaysToStart = true;
        }
        if (addThirtyDaysToStart) {
          this.useCampaignEnd = false;
          endDate = this.getEndDateWithBuffer(startDate);
        }
        strategy.startDate = startDate;
        strategy.endDate = endDate;
        this.updateDates(startDate, endDate);
        this.startTime = startDate.toString(displayTimeFormat);
        this.endTime = endDate.toString(displayTimeFormat);
        this.render().then(() => {
          var $dateDisplay = this.$(".date-display");
          var self = this;
          this.updateVideoTargeting();
          this.datePicker = T1.DoubleDatePicker({
            getStartDate: () => strategy.startDate,
            getEndDate: () => strategy.endDate,
            chooseTime: true,
            chooseTimezone: false,
            lowerBounds: new Date(),
            startTimeEditable: true,
            defaultStart: campaign.get("start_date"),
            defaultEnd: campaign.get("end_date"),
            useDefaultStart: this.isStandalone && this.useCampaignStart,
            useDefaultEnd: this.isStandalone && this.useCampaignEnd,
            closeOnScroll: false,
            useStartDefaultCheckInput: this.$(".campaign-start-date"),
            useEndDefaultCheckInput: this.$(".campaign-end-date"),
            zoneName: () => strategy.zoneName,
            positionBy: "bottomTop",
            topOffset: 25,
            canClose: function (e) {
              var parent = $(e.target).parent();
              var closestInput = parent.find("input");
              return !(parent.is("label") && (closestInput.hasClass("campaign-start-date") || closestInput.hasClass("campaign-end-date")));
            },
            onOpen: function () {
              const topIndex = 9999;
              $(".cluetip-datepicker").css("z-index", topIndex);
            },
            onSave: function ({
              data: data,
              error: error,
              success: success
            }) {
              const saveValues = data.saveValues;
              var savedStartDate = Date.parse(saveValues.start.date);
              self.timezones.validateStartDate(startBufferMin, savedStartDate, saveValues.zoneName, {
                onSuccess: () => {
                  const successfulSaveValues = data.saveValues;
                  var successfulNewStartDate = Date.parse(successfulSaveValues.start.date);
                  var newEndDate = Date.parse(successfulSaveValues.end.date);
                  if (Date.compare(newEndDate, successfulNewStartDate) !== 1) {
                    error({
                      message: self.startEndOrderError
                    });
                    return;
                  }
                  strategy.startDate = successfulNewStartDate;
                  strategy.endDate = newEndDate;
                  self.startTime = successfulNewStartDate.toString(displayTimeFormat);
                  self.endTime = newEndDate.toString(displayTimeFormat);
                  self.renderPartial(".date-display");
                  self.updateDates(successfulNewStartDate, newEndDate);
                  self.removeTooltip({
                    currentTarget: $dateDisplay
                  });
                  self.useCampaignStart = self.$(".campaign-start-date")[0].checked;
                  self.useCampaignEnd = self.$(".campaign-end-date")[0].checked;
                  success();
                },
                onError: () => {
                  error({
                    message: self.startDateError
                  });
                }
              });
            }
          }, this.el);
        });
      });
    },
    getStartDateWithBuffer: function (initialDate) {
      const date = initialDate.clone();
      date.addDays(1).clearTime();
      return date;
    },
    getEndDateWithBuffer: function (initialDate) {
      const date = initialDate.clone();
      const fullMonth = 30;
      const fullDay = 23;
      const fullHour = 59;
      date.addDays(fullMonth).addHours(fullDay).addMinutes(fullHour);
      return date;
    },
    load: function () {
      T1Timezones.done(mthds => {
        this.timezones = mthds;
        this.prepareDatePicker();
      });
    },
    onCampaignStartOrEndDateClick: function ($target, isStartDate) {
      var model = this.model;
      var campaign = model.get("campaign");
      var isChecked = $target[0].checked;
      if (isStartDate) {
        this.useCampaignStart = isChecked;
        model.startDate = this.useCampaignStart ? Date.parse(campaign.get("initial_start_date")) : Date.parse(campaign.get("start_date"));
        if (Date.compare(model.startDate, model.endDate) === 1) {
          model.endDate = this.getEndDateWithBuffer(model.startDate);
        }
      } else {
        this.useCampaignEnd = isChecked;
        model.endDate = Date.parse(campaign.get("end_date"));
        if (Date.compare(model.startDate, model.endDate) === 1) {
          model.startDate = this.getStartDateWithBuffer(new Date());
        }
      }
      this.startTime = model.startDate.toString(displayTimeFormat);
      this.endTime = model.endDate.toString(displayTimeFormat);
      if (this.datePicker) {
        this.datePicker.close();
      }
      this.renderPartial(".date-display");
    },
    onCampaignStartDateClick: function (e) {
      this.onCampaignStartOrEndDateClick($(e.currentTarget), true);
    },
    onCampaignEndDateClick: function (e) {
      this.onCampaignStartOrEndDateClick($(e.currentTarget), false);
    },
    onDatePickerSelect: function ($details, startOrEnd) {
      $(`.campaign-${startOrEnd}-date`, $details).attr("checked", false);
    },
    onDatePickerClose: function ($details) {
      $(".campaign-start-date", $details).attr("checked", this.useCampaignStart);
      $(".campaign-end-date", $details).attr("checked", this.useCampaignEnd);
    },
    handleDateChange: function (data) {
      const strategy = this.model;
      const campaign = strategy.get("campaign");
      if (this.useCampaignStart) {
        campaign.set({
          initial_start_date: data.startDate,
          start_date: data.startDate
        });
        strategy.startDate = data.startDate;
        strategy.startTime = data.startDate.toString(displayTimeFormat);
      }
      if (this.useCampaignEnd) {
        campaign.set({
          end_date: data.endDate
        });
        strategy.endDate = data.endDate;
        strategy.endTime = data.endDate.toString(displayTimeFormat);
      }
      this.renderPartial(".date-display");
      this.updateDates(data.startDate, data.endDate);
    },
    updateVideoTargeting: function () {
      const $videoElem = this.$(".bulk-option");
      if (this.containsVideo) {
        $videoElem.removeAttr("disabled");
      } else {
        $videoElem.attr("disabled", "disabled");
      }
    },
    updateDates: function (startDate, endDate) {
      T1.EventHub.publish("strategy:datesUpdated", {
        startDate: startDate,
        endDate: endDate
      });
    },
    serialize: function () {
      var model = this.model;
      var modelJson = model.toJSON();
      var timezone = this.timezones && this.timezones.getByZoneName(model.zoneName);
      var strategyName = modelJson.name;
      var getStartEndDatesDisplay = CampaignStrategyUtils.getDisplayTextForStartAndEndDates;
      var obj = {
        dateTimeDisplay: getStartEndDatesDisplay({
          startDate: model.startDate,
          endDate: model.endDate,
          dateFormat: displayDateFormat,
          timeFormat: displayTimeFormat
        }),
        timeZone: timezone && timezone.toJSON() || {},
        disableCampaignStart: this.disableCampaignStart,
        disableCampaignEnd: this.disableCampaignEnd,
        useCampaignStart: this.useCampaignStart,
        useCampaignEnd: this.useCampaignEnd,
        isStandalone: this.isStandalone
      };
      var returnObj = $.extend(obj, modelJson);
      returnObj.isVideoStrategy = modelJson.media_type && modelJson.media_type.toUpperCase() === "VIDEO";
      returnObj.isStatusActive = this.status === "1";
      returnObj.status = returnObj.isStatusActive ? "active" : "inactive";
      returnObj.name = strategyName;
      returnObj.withVideoClass = returnObj.isVideoStrategy || !this.isStandalone;
      return returnObj;
    }
  });
  DuplicateStrategySettingsView = T1.Form.ViewUtils.extend(DuplicateStrategySettingsView);
  return DuplicateStrategySettingsView;
});
