define('collections/strategies',['require','jQuery','modules/organization/utils/Utils','collections/performanceDataForCollections','T1Sortable','models/strategy','T1','T1Collection'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const OrgUtils = require("modules/organization/utils/Utils");
  const PerformanceData = require("collections/performanceDataForCollections");
  const Sortable = require("T1Sortable");
  const Strategy = require("models/strategy");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  function objectToArray(entity) {
    return Array.isArray(entity) ? entity : [entity];
  }
  const sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      },
      start_date: {
        actual: "start_date",
        display: "start date"
      },
      end_date: {
        actual: "end_date",
        display: "end date"
      },
      created_on: {
        actual: "created_on",
        display: "date created"
      },
      updated_on: {
        actual: "updated_on",
        display: "last updated"
      },
      budget: {
        actual: "budget",
        display: "budget"
      },
      status: {
        actual: "status",
        display: ""
      }
    },
    default: {
      sort_by: "updated_on",
      order_by: "descending"
    }
  };
  const collection = T1Collection.extend({
    model: Strategy,
    canCache: false,
    postEntityParse: {
      rpt_goal_monitoring: objectToArray,
      rpt_legacy_goal_monitoring: objectToArray
    },
    filterConfiguration: {
      filters: [{
        entity: "campaign",
        fetchOnChange: true
      }]
    },
    searchConfiguration: {
      isFetchSilent: false,
      fields: {
        status: {
          type: "enum"
        }
      }
    },
    urlString: "strategies",
    getPerformanceDataObj() {
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      const requestMetrics = ["clicks", "ctr", "impressions", "post_click_conversions", "revenue", "total_conversions", "total_revenue", "total_spend", "total_spend_cpa", "total_spend_cpc", "total_spend_cpcv", "total_spend_cpm", "total_spend_roi", "total_spend_vcpm", "viewability_rate"];
      if (hasVcrImpression) {
        requestMetrics.push("video_complete_rate_impression_based");
      } else {
        requestMetrics.push("video_complete_rate");
      }
      this.performanceData = new PerformanceData({
        hostCollection: this,
        metrics: requestMetrics
      });
      return this.performanceData;
    },
    fetch(opts = {}) {
      if (this.currentFetch && !opts.fromFetchAll) {
        this.currentFetch.abort();
        this.currentFetch = null;
      }
      this.getPerformanceDataObj().hostCollectionFetchHandler(opts);
    },
    fetchMore(args) {
      if (this.canFetchMore()) {
        this.page += 1;
        this.fetch({
          add: true,
          data: args.data || "",
          fromFetchAll: true,
          silent: args.silent || false,
          success: args.success || $.noop
        });
      }
    }
  });
  T1.Models.Strategies = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.Strategies;
});
