define('modules/creatives/bulkedit/views/review',["jQuery", "Underscore", "When", "T1", "T1Permissions", "T1View", "text!../templates/form_template.html", "text!../templates/review_body.html", "text!../templates/form_footer.html", "T1Layout", "models/videoCreativeDetail", "models/concept", "collections/vendors", "../control/ParallelDeferred", "utils/CreativeUtils", "models/concept", "T1Notifier", "T1Form"], function ($, _, When, T1, T1Permissions, T1View, template, formBody, formFooter, T1Layout, VideoCreativeDetail, Concept, Vendors, ParallelDeferred, CreativeUtils) {
  "use strict";

  var BulkEditReview = T1View.extend({
    template: template,
    partials: {
      footer: formFooter
    },
    actionsConfig: {
      events: {
        "click .back": "handleBack",
        "click .cancel": "handleCancel"
      }
    },
    eventhubEvents: {
      "bulkedit:review": "handleReview"
    },
    initialize: function (args) {
      this.collection = args.collection;
      this.bulkAdvertiser = args.bulkAdvertiser;
      this.consistencyEnabled = args.consistencyEnabled;
      this.advertiser = args.advertiser;
      this.entityType = args.entityType;
      this.isBulkReviewEnabled = T1Permissions.check("feature", "creatives.bulk_review");
      this.actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel ui-cancel",
          location: "#"
        }, {
          label: this.consistencyEnabled ? "apply" : "save & close",
          class: "saveAndContinue btn-primary",
          location: false
        }]
      };
    },
    initLayout: function () {
      var self = this;
      var formValuesForGrid = $.extend({}, self.formValues);
      delete formValuesForGrid.current_classifications;
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-body",
        template: formBody,
        layout: {
          ".entity-grid": [{
            module: "creatives/bulkedit",
            viewType: "entityGrid",
            options: {
              collection: self.collection,
              consistencyEnabled: self.consistencyEnabled
            }
          }],
          ".change-grid": [{
            module: "creatives/bulkedit",
            viewType: "changeGrid",
            options: {
              formValues: formValuesForGrid,
              entityType: self.entityType
            }
          }]
        },
        serialize: function () {
          return {
            entityType: self.entityType,
            entityCount: self.collection.length,
            changeCount: Object.keys(formValuesForGrid).length
          };
        }
      });
    },
    handleBack: function (e) {
      e.preventDefault();
      if (this.isAfterAssignment) {
        T1.EventHub.publish("bulkedit:assignment", {
          formValues: this.formValues
        });
      } else {
        T1.EventHub.publish("bulkedit:edit");
      }
    },
    handleCancel: function (e) {
      e.preventDefault();
      T1.EventHub.publish("bulkedit:cancel");
    },
    handleReview: function (data) {
      this.formValues = data.formValues;
      this.isAfterAssignment = data.isAfterAssignment;
      this.initLayout();
      this.load();
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        if (self.layout) {
          self.layout.load();
          self.makeTooltips();
        }
      });
    },
    makeTooltips: function () {
      T1.Tooltip(this.el, {
        gravity: "ne",
        tooltipEle: ".entity-tip"
      });
    },
    preparePostData: function () {
      return this.formValues;
    },
    postData: function (formValues, success, statusInvalid, conflictCallback) {
      var self = this;
      var collection = self.collection;
      var queue = [];
      var conceptQueue = [];
      var conceptMap = {};
      var vendorIDs, advertiserId;
      self.successCount = 0;
      self.collectionCount = this.collection.length;
      self.existingVendors = new Vendors();
      if (self.isBulkReviewEnabled && self.consistencyEnabled) {
        if (formValues.concept) {
          if (!formValues.concept.id) {
            advertiserId = self.bulkAdvertiser.id;
            collection.each(function (creative) {
              conceptQueue.push(createDefaultConcept(creative, formValues, advertiserId));
            });
          } else {
            collection.each(function (creative) {
              var index = creative.get("pos") - 1;
              conceptMap[index] = formValues.concept;
            });
          }
        }
        When.all(conceptQueue).then(function () {
          if (formValues.concept) {
            formValues.concept = conceptMap;
          }
          T1.EventHub.publish("bulkedit:reviewSaved", {
            requireRefresh: true,
            formValues: formValues
          });
        });
        return;
      }
      function handleSuccess() {
        T1.EventHub.publish("bulkedit:saved", {
          requireRefresh: true
        });
        T1.Notify("message", collection.length + " " + self.entityType + "(s) updated successfully!");
        success();
      }
      function handleError() {
        T1.Notify("message", "Error updating " + self.entityType + "(s)");
      }
      function saveClassificationComplete(deferred) {
        return deferred.resolve();
      }
      function saveClassificationFailed(deferred) {
        return deferred.reject();
      }
      function saveClassifications(modelCollection, fValues) {
        var deferred = When.defer();
        var classifications = [];
        var classificationItem, classificationCollection, mappedClassfications;
        var isBatch = modelCollection && modelCollection.length > 1;
        modelCollection.each(function (model) {
          mappedClassfications = CreativeUtils.mapClassifications(model, fValues);
          if (formValues.add_classifications || formValues.remove_classifications) {
            if (isBatch) {
              classificationItem = {};
              classificationItem.atomic_creative_id = model.id;
              classificationItem.classification = mappedClassfications;
              classifications.push(classificationItem);
            } else {
              classifications = mappedClassfications;
            }
          }
        });
        if (classifications) {
          classificationCollection = new T1ComponentLib.CreativesClassificationCollection();
          classificationCollection.auto = false;
          classificationCollection.crid = modelCollection.at(0).id;
          classificationCollection.isBatch = isBatch;
          classificationCollection.saveClassifications(classifications).then(saveClassificationComplete.bind(self, deferred), saveClassificationFailed.bind(self, deferred));
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      }
      function saveAtomicCreativeModel(model, fValues, deferred) {
        var modelValues = CreativeUtils.mapValues(model, fValues);
        if (!$.isEmptyObject(modelValues)) {
          model.save(modelValues, {
            success: function () {
              deferred.resolve();
            },
            statusInvalid: function () {
              statusInvalid.apply(self, arguments);
              deferred.reject();
            },
            conflict: function () {
              conflictCallback.apply(self, arguments);
              deferred.reject();
            }
          });
        } else {
          deferred.resolve();
        }
      }
      function createDefaultConcept(model, formVals, advertiser_id) {
        var deferred = $.Deferred();
        var conceptModel = new Concept();
        var gridIndex = model.get("pos") - 1;
        conceptModel.save({
          name: model.get("name"),
          status: "1",
          advertiser_id: model.get("advertiser_id") || advertiser_id
        }, {
          success: function (concept) {
            if (self.isBulkReviewEnabled && self.consistencyEnabled) {
              conceptMap[gridIndex] = concept;
            } else {
              formVals.concept = concept;
            }
            deferred.resolve(concept);
          },
          statusInvalid: function () {
            deferred.reject();
          }
        });
        return deferred.promise();
      }
      this.collection.each(function (model) {
        if (self.entityType === "video") {
          var outputModel = new VideoCreativeDetail();
          var details = model.get("details");
          var externalIdentifier = model.get("external_identifier") || null;
          var name = details.get("name");
          var advertiser = details.get("advertiser");
          var concept, active, conceptId;
          var landingUrl = formValues.click_url || details.get("landingUrl");
          var startTime = formValues.start_date || details.get("startTime");
          var endTime = formValues.end_date || details.get("endTime");
          var skippableDuration = details.get("skippableDuration");
          var eventPixels = details.get("eventPixels");
          var vendors = details.get("vendors");
          var id = model.get("id");
          var vendorIdList = [];
          if (formValues.status) {
            active = formValues.status === "true";
          } else {
            active = details.get("active");
          }
          if (details.get("customVAST")) {
            outputModel.set({
              customVAST: details.get("customVAST")
            });
          } else if (details.get("customVASTUrl")) {
            outputModel.set({
              customVASTUrl: details.get("customVASTUrl")
            });
          } else {
            outputModel.set({
              clickthroughUrl: details.get("clickthroughUrl")
            });
          }
          concept = formValues.concept;
          if (concept) {
            concept = concept.get("id");
          } else {
            concept = details.get("concept");
          }
          outputModel.set({
            name: name,
            advertiser: advertiser,
            concept: parseInt(concept, 10),
            landingUrl: landingUrl,
            active: active,
            skippableDuration: skippableDuration,
            creativeId: id,
            id: id,
            eventPixels: eventPixels,
            externalIdentifier: externalIdentifier
          });
          if (formValues.start_date) {
            outputModel.set({
              startTime: startTime
            });
          }
          if (formValues.end_date) {
            outputModel.set({
              endTime: endTime
            });
          }
          if (formValues.add_vendors || formValues.remove_vendors) {
            vendorIDs = CreativeUtils.mapVendors(model, formValues, "video");
            vendorIdList = vendorIDs && vendorIDs.map(function (vID) {
              return Number(vID);
            });
            if (vendorIdList.length) {
              outputModel.set({
                vendors: _.compact(vendorIdList)
              });
            } else {
              outputModel.set({
                vendors: []
              });
            }
          } else {
            outputModel.set({
              vendors: vendors
            });
          }
          new ParallelDeferred(outputModel.createEdit(outputModel.toJSON(), {
            statusInvalid: statusInvalid,
            conflict: conflictCallback
          }), function () {
            var def = $.Deferred();
            def.resolve();
            return def;
          }()).then($.noop, function () {
            statusInvalid([]);
          }).pipe(function () {
            var def = $.Deferred();
            var saveOpts = {
              dataType: "json",
              stringify: true,
              contentType: "application/json",
              processData: false,
              success: function () {
                def.resolve();
                self.successCount++;
                if (self.successCount === self.collectionCount) {
                  handleSuccess();
                }
              },
              statusInvalid: function (errors) {
                statusInvalid(errors, "There are errors on the page.", ".w-CreativesCreateEdit");
              },
              conflict: conflictCallback,
              errorDisplaySuppressingConfig: {
                errorSuppressed: true,
                errorCodes: [400, 413]
              },
              error: function (data, response) {
                var jsonData = JSON.parse(response.responseText);
                var errors;
                if (jsonData.errors && jsonData.errors.field_error) {
                  errors = !$.isArray(jsonData.errors.field_error) ? [jsonData.errors.field_error] : jsonData.errors.field_error;
                  errors = _.map(errors, function (error) {
                    return {
                      field: error.name.replace(/customVast/g, "vast"),
                      message: error.error
                    };
                  });
                  self.showErrors(outputModel, errors, ".w-CreativesCreateEdit");
                  statusInvalid([]);
                  handleError();
                }
              },
              processAjaxResponse: outputModel.processAjaxResponse
            };
            if (id) {
              outputModel.set({
                id: id
              });
            }
            if (outputModel.get("concept")) {
              outputModel.save(outputModel.toJSON(), saveOpts);
            } else {
              createDefaultConcept(outputModel, formValues, outputModel.get("advertiser")).then(function () {
                conceptId = parseInt(formValues.concept.id, 10);
                outputModel.set({
                  concept: conceptId
                });
                outputModel.save(outputModel.toJSON(), saveOpts);
              });
            }
            return def.promise();
          });
        } else {
          var deferred = When.defer();
          if (formValues.concept && !formValues.concept.id) {
            queue.push(deferred.promise);
            createDefaultConcept(model, formValues).then(function () {
              saveAtomicCreativeModel(model, formValues, deferred);
            });
          } else {
            saveAtomicCreativeModel(model, formValues, deferred);
            queue.push(deferred.promise);
          }
        }
      });
      if (formValues.add_classifications || formValues.remove_classifications) {
        queue.push(saveClassifications(this.collection, formValues));
      }
      if (self.entityType !== "video") {
        When.all(queue).then(handleSuccess, handleError);
      }
    },
    serialize: function () {
      var data = {};
      var advertiser = this.advertiser;
      var agency = advertiser ? advertiser.get("agency") : null;
      var stepCount = "2";
      if (this.collection.length) {
        if (this.isAfterAssignment) {
          stepCount = "3";
        }
        data.title = "Review Changes (Step " + stepCount + ")";
        data.advertiser = advertiser ? advertiser.get("name") : "";
        data.agency = agency ? agency.get("name") : "";
        data.count = this.collection.length;
        data.entityType = this.entityType;
        data.entities = this.collection.pluck("name");
        data.backAction = {
          label: "back",
          class: "back ui-cancel",
          location: "#"
        };
      }
      return data;
    }
  });
  return T1.Form.ViewUtils.extend(BulkEditReview);
});
