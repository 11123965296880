define('models/staticData',["T1", "T1Model", "Backbone"], function (T1, T1Model, Backbone) {
  T1.Models.StaticData = T1Model.extend({
    apiRoot: COMPASS_ENV.STATIC_DATA_API_BASE,
    url: function () {
      var action = this.get("action");
      if (!action) {
        throw new Error("An action is required to return static data");
      }
      return this.apiRoot + "/" + action;
    },
    fetch: function (options) {
      var self = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (data) {
        if (options.onSuccess) {
          options.onSuccess(data);
        }
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      self.ajx = self.sync("read", "", {
        action: self.action,
        dataType: "json",
        onSuccess: onSuccess,
        errorDisplaySuppressingConfig: {
          errorCodes: [404]
        },
        processAjaxResponse: function (data) {
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    fixture: "-static-data"
  });
  return T1.Models.StaticData;
});
