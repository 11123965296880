define('modules/creatives/shared/videosMap',['require','moment'],function (require) {
  "use strict";

  const moment = require("moment");
  function expandDate(value) {
    var dateFormat = "MMMM D, YYYY h:mma";
    return value === "" ? "" : moment.utc(value, "X").format(dateFormat);
  }
  function expandClassifications(value) {
    var output = [];
    if (Object.prototype.toString.call(value) === "[object Array]") {
      [{
        label: "Attributes",
        entity: "attributes"
      }, {
        label: "Categories",
        entity: "verticals"
      }, {
        label: "Languages",
        entity: "languages"
      }].forEach(function (obj) {
        const entityList = value.filter(function (item) {
          var itemEntity = item.data && item.data.entity || item.entity;
          return itemEntity === obj.entity;
        });
        if (entityList && entityList.length) {
          output.push(`${obj.label.toUpperCase()} (${entityList.length}): ${entityList.map(item => item.data && item.data.name || item.name).join(", ")}`);
        }
      });
    }
    return output.join(", ");
  }
  const map = {
    entity: "video",
    click_url: {
      label: "Landing Page"
    },
    concept_id: {
      label: "Concept"
    },
    end_date: {
      label: "End Date",
      values: expandDate,
      suffix: " (Campaign Timezone)"
    },
    start_date: {
      label: "Start Date",
      values: expandDate,
      suffix: " (Campaign Timezone)"
    },
    status: {
      label: "Status",
      values: {
        false: "Inactive",
        true: "Active"
      }
    },
    vendors: {
      label: "Vendors"
    },
    add_vendors: {
      label: "Add Vendors"
    },
    remove_vendors: {
      label: "Remove Vendors"
    },
    add_classifications: {
      label: "Add Classifications",
      values: expandClassifications
    },
    remove_classifications: {
      label: "Remove Classifications",
      values: expandClassifications
    }
  };
  return map;
});
