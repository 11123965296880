define('app',["jQuery", "Underscore", "T1", "T1Permissions", "models/userPreferences", "appInit", "utils/Auth", "appCookieAuthStart", "utils/LaunchDarklyUtils", "utils/PendoUtils"], function ($, _, T1, T1Permissions, UserPrefs, appInit, Auth, cookieAuthStart, LaunchDarklyUtils) {
  "use strict";

  function initialize() {
    let hasAuthRequiredBeenPublished = false;
    localStorage.removeItem("has_auth_required_been_published");
    T1.EventHub.subscribe("AuthRequired", () => {
      hasAuthRequiredBeenPublished = true;
      localStorage.setItem("has_auth_required_been_published", hasAuthRequiredBeenPublished);
    });
    LaunchDarklyUtils.getAnonymousClient().then(() => {
      const useAuth0 = T1Permissions.check("feature", "auth_0");
      T1.EventHub.unsubscribe("AuthRequired");
      window.WebAuth.tokenPromise.then(() => {
        if (useAuth0) {
          if (!localStorage.getItem("mm.bogo.acc")) {
            hasAuthRequiredBeenPublished = true;
          }
          AuthAppStart(hasAuthRequiredBeenPublished);
        } else {
          cookieAuthStart().initialize(hasAuthRequiredBeenPublished);
        }
      });
    }).catch(() => {
      window.WebAuth.tokenPromise.then(() => {
        cookieAuthStart().initialize(hasAuthRequiredBeenPublished);
      });
    });
  }
  function AuthAppStart(hasAuthRequiredBeenPublished) {
    if (hasAuthRequiredBeenPublished) {
      Auth.authorize();
    } else {
      window.localStorage.removeItem("password");
      T1.EventHub.subscribe("organization:refreshUI", reloadUserPrefs);
      T1.EventHub.subscribe("AuthRequired", Auth.authorize);
      appInit().then(() => {
        T1.EventHub.subscribe("onUserLoaded", reloadUserPrefs);
      }).catch(Auth.authorize);
    }
  }
  function reloadUserPrefs(isOrgChange) {
    UserPrefs.reload(isOrgChange).then(() => appInit(isOrgChange));
  }
  return {
    initialize: initialize
  };
});
