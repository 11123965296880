define('utils/PendoUtils',["T1", "T1UtilsAsync"], function (T1, utilsAsync) {
  "use strict";

  let deferral;
  var trackableFeatureFlags = ["ui.features.standard.segments"];
  var changePeriodToUnderscore = function (str) {
    return str.split(".").join("_");
  };
  var processFlagValue = function () {
    var booleanTest = /^(1|0|true|false)$/;
    var truthyTest = /^(1|true)$/;
    return function (value) {
      return booleanTest.test(value) ? truthyTest.test(value) : value;
    };
  }();
  var addFeatureFlagValueToObject = function (flags) {
    var requiredFlags = flags.reduce(function (acc, flag) {
      acc[changePeriodToUnderscore(flag)] = true;
      return acc;
    }, {});
    return function (obj, key, value) {
      key = changePeriodToUnderscore(key);
      if (requiredFlags[key]) {
        obj[`flag__${key}`] = processFlagValue(value);
      }
    };
  }(trackableFeatureFlags);
  var createPermissionIterator = function (key, obj) {
    return function (item) {
      addFeatureFlagValueToObject(obj, item[key], item.value);
    };
  };
  function noop() {}
  function loadPendo() {
    (function (p, e, n, d, o) {
      var v, y, z;
      o = p[d] = p[d] || {};
      o._q = [];
      v = ["initialize", "identify", "updateOptions", "pageLoad"];
      for (let w = 0; w < v.length; ++w) {
        (function (m) {
          o[m] = o[m] || function (...args) {
            o._q[m === v[0] ? "unshift" : "push"]([m, ...args]);
          };
        })(v[w]);
      }
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/67305bc6-d2b3-4c82-4d89-023678e15711/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  }
  function getPendoData(results) {
    const user = getUserInfo(results.addUserInfo);
    const org = getOrgPermissions(results.addOrgPermissions);
    const permissions = getUserPermissions(results.addUserPermissions);
    return {
      apiKey: "67305bc6-d2b3-4c82-4d89-023678e15711",
      visitor: Object.assign({}, user, permissions),
      account: org
    };
  }
  function parseDate(str) {
    var unixTime = Date._parse(str);
    return new Date(unixTime).toUTCString();
  }
  function getUserInfo(userModel) {
    var permissions;
    var obj = {};
    if (userModel !== undefined) {
      obj.id = userModel.get("id");
      obj.role = userModel.get("role");
      obj.type = userModel.get("type");
      obj.scope = userModel.get("scope");
      obj.title = userModel.get("title");
      obj.creation_date = parseDate(userModel.get("created_on"));
      permissions = userModel.get("permissions");
      if (permissions && permissions.flags && Array.isArray(permissions.flags.access)) {
        permissions.flags.access.forEach(createPermissionIterator("type", obj));
      }
    }
    return obj;
  }
  function getUserPermissions(permissions) {
    var obj = {};
    if (permissions && Array.isArray(permissions.prop)) {
      permissions.prop.forEach(createPermissionIterator("name", obj));
    }
    return obj;
  }
  function getOrgPermissions(org) {
    var obj = {};
    var model;
    if (org !== undefined) {
      model = org.selectedOrgModel || org.get(org.selected);
      if (!model) {
        if (org.selected) {
          obj.id = org.selected;
        }
      } else {
        obj.id = model.get("id");
        obj.country = model.get("country");
      }
    }
    return obj;
  }
  function onDataLoaded(results) {
    window.pendo.initialize(getPendoData(results));
  }
  function onResetUser() {
    deferral.reject();
    constructDeferral();
  }
  function constructDeferral() {
    deferral = utilsAsync.makeDeferral();
    deferral.promise.catch(noop);
  }
  function initializeSubscriptions() {
    T1.EventHub.subscribe({
      "UserDataUtils:dataLoaded": onDataLoaded,
      "UserDataUtils:reset": onResetUser
    });
  }
  function initialize() {
    var doPendo = COMPASS_ENV.TIER === "PRODUCTION" || window.location.href.indexOf("pendo=1") !== -1;
    if (doPendo) {
      constructDeferral();
      initializeSubscriptions();
      loadPendo();
    }
  }
  return initialize();
});
