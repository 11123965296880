define('modules/reporting/campaigns/views/select_interval',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/select_interval.html"], function ($, T1, T1View, T1Permissions, template) {
  "use strict";

  var documentEvents = "mousedown.intervalUI keyup.intervalUI";
  var $document = $(document);
  const ANIM_TIME = 200;
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  return T1View.extend({
    template: template,
    currentInterval: "last_30_days",
    siteFilterUIOpen: false,
    list: [{
      value: "yesterday",
      text: "Yesterday"
    }, {
      value: "last_7_days",
      text: "Last 7 Days"
    }, {
      value: "last_30_days",
      text: "Last 30 Days"
    }, {
      value: "campaign_to_date",
      text: "Campaign to Date"
    }],
    events: {
      "mousedown .head-label": "toggle",
      "mousedown .w-Interval": "stopBubbling",
      "mouseout li": "itemOut",
      "mouseover li": "itemOver",
      "mouseup .interval-list li.enabled": "itemClick"
    },
    initialize: function (args) {
      this.models = args.models;
      this.metadata = args.models.reportModel;
      this.meta = $.extend({}, args.models.reportModel.toJSON());
      this.campaign = args.models.campaign;
      this.currentInterval = this.metadata.get("interval") || this.currentInterval;
      this.list = args.list ? args.list : this.list;
      this.updateList();
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.initializeUI();
      });
    },
    unload: function () {
      $document.off(documentEvents);
      this.wBody = null;
      this.intervalLabel = null;
      this.siteFilterUIOpen = null;
    },
    updateList: function () {
      var len = this.list.length;
      var i, item;
      if (len > 1) {
        for (i = 1; i < len; i++) {
          item = this.list[i];
          item.disabled = item.disabledName && !T1Permissions.check("feature", "reporting_all");
        }
      }
    },
    initializeUI: function () {
      var el = $(this.el);
      this.wBody = el.find(".w-body");
      this.intervalLabel = el.find(".head-label");
    },
    stopBubbling: function (e) {
      e.stopPropagation();
    },
    toggle: function (e) {
      e.stopPropagation();
      if (this.siteFilterUIOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open: function () {
      var self = this;
      $document.mousedown();
      this.wBody.removeClass("no-display").fadeIn(ANIM_TIME);
      this.intervalLabel.addClass("white-bg");
      this.siteFilterUIOpen = true;
      $document.one(documentEvents, function (e) {
        if (e.keyCode === TAB_KEY || e.keyCode === ESCAPE_KEY || !e.keyCode) {
          self.close();
        }
      });
    },
    close: function () {
      this.intervalLabel.removeClass("white-bg");
      this.wBody.hide();
      this.siteFilterUIOpen = false;
      $document.off(documentEvents);
    },
    itemOver: function (e) {
      $(e.currentTarget).addClass("highlighted");
    },
    itemOut: function (e) {
      $(e.currentTarget).removeClass("highlighted");
    },
    itemClick: function (e) {
      var selectedId = $(e.currentTarget).data("id");
      var publish = T1.EventHub.publish;
      if (this.currentInterval !== selectedId) {
        this.currentInterval = selectedId;
        publish("select:interval", this.currentInterval);
        publish("track:event", {
          action: "Choose interval",
          label: this.currentInterval
        });
        this.reload();
      }
      this.close();
    },
    getCurrentIntervalText: function (currentInterval) {
      var len = this.list.length;
      var i, result;
      for (i = 0; i < len; i++) {
        if (this.list[i].value === currentInterval) {
          result = this.list[i].text;
          break;
        }
      }
      return result;
    },
    serialize: function () {
      var len = this.list.length;
      var i, item;
      for (i = 0; i < len; i++) {
        item = this.list[i];
        item.selected = item.value === this.currentInterval;
        if (item.disabled) {
          item.name = item.disabledName || item.name;
          item.state = "disabled";
        } else {
          item.state = "enabled";
        }
      }
      return {
        currentIntervalText: this.getCurrentIntervalText(this.currentInterval),
        intervalList: this.list
      };
    }
  });
});
