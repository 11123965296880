define('modules/strategy/creatives/views/panel',["jQuery", "T1", "T1View", "collections/strategyConcepts", "collections/creativesConcept", "text!modules/strategy/creatives/templates/panel.html", "text!templates/form_footer.html", "text!templates/alert.html", "text!../templates/panel_layout.html", "T1Layout", "When", "utils/CampaignStrategyUtils"], function ($, T1, T1View, StrategyConcepts, ConceptsCollection, template, form_footer, alertDialogTemplate, panel_layout, T1Layout, When, CampaignStrategyUtils) {
  "use strict";

  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = CampaignStrategyUtils;
  var CreativesPanel = T1View.extend({
    template: template,
    partials: {
      form_footer: form_footer,
      alertDialog: alertDialogTemplate
    },
    loaderOpts: {
      target: ".strategy-creatives-loader",
      text: "Loading...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "strategyCreativesLoader:stop": function () {
        this.creativesLoader.stop();
      },
      "resetIsFormElementsChanged:strategy-creatives": function () {
        this.isFormElementsChanged = false;
      }
    },
    initialize: function ({
      strategy: strategy
    }) {
      const sharedCollection = new StrategyConcepts([], {
        isPaginated: true
      });
      var self = this;
      const assignedConceptsFetch = When.defer();
      const gridConceptsFetch = When.defer();
      const promises = [assignedConceptsFetch.promise, gridConceptsFetch.promise];
      this.strategy = strategy;
      this.isFormElementsChanged = false;
      this.sharedCollection = sharedCollection;
      this.collection = new ConceptsCollection(null, {
        pageLimit: 50
      });
      this.collection.setAdvertiser(strategy.get("campaign").get("advertiser_id"));
      this.isProgrammaticGuaranteed = CampaignStrategyUtils.isProgrammaticGuaranteed(strategy.get("campaign"));
      sharedCollection.id = strategy.id;
      sharedCollection.fetch({
        success: function () {
          self.initLayout();
          self.addDataEvent({
            sharedCollection: {
              add: function () {
                this.isFormElementsChanged = true;
              },
              remove: function () {
                this.isFormElementsChanged = true;
              },
              reset: function () {
                this.isFormElementsChanged = false;
                if (!self.layout.loaded) {
                  self.layout.load();
                }
                this.load();
              }
            }
          });
          assignedConceptsFetch.resolve();
        }
      });
      this.collection.search.set({
        params: [{
          term: "1",
          searchField: "status"
        }],
        fetchRequired: true,
        success: function () {
          gridConceptsFetch.resolve();
        }
      });
      When.all(promises).then(function () {
        self.collection.models.forEach(function (model) {
          model.set({
            action: "include"
          });
        });
        self.load();
      });
    },
    initLayout: function () {
      var coll = this.collection;
      var filterCallback = function (term) {
        coll.trigger("search", term);
      };
      var clearFilterCallback = function () {
        coll.trigger("search.clear");
      };
      var showActiveCallback = function (e) {
        T1.EventHub.publish("strategyCreatives.showActive", $(e.currentTarget).is(":checked"));
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".creatives-list",
        template: panel_layout,
        layout: {
          ".filters": [{
            module: "shared",
            viewType: "filter",
            options: {
              collection: coll,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback,
              showActiveCallback: showActiveCallback
            }
          }],
          ".browse-c": [{
            module: "strategy/creatives",
            viewType: "list",
            options: {
              sharedCollection: this.sharedCollection,
              collection: coll
            }
          }],
          ".bins-column": [{
            module: "strategy/creatives",
            viewType: "bins",
            options: {
              sharedCollection: this.sharedCollection,
              strategy: this.strategy
            }
          }]
        }
      });
    },
    load: function () {
      if (this.strategy && this.strategy.id) {
        T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      }
      this.render().then(() => {
        if (this.layout) {
          this.creativesLoader = this.loader();
          this.creativesLoader.start();
          this.layout.load();
          this.loaded = true;
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "creatives_targeting"
          });
        }
        if (window.hybridWorkflowParams.isHybrid) {
          updateCancelBtn(this.strategy.get("campaign"));
          this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
          this.$cancelBtn = $(".cancelNewT1");
        }
      });
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    unload() {
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    onDontSave: function () {
      this.sharedCollection.fetch();
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    serialize: function () {
      return {
        isProgrammaticGuaranteed: this.isProgrammaticGuaranteed
      };
    },
    postData: function (formValues, successCallback, statusInvalidCallback) {
      var sharedCollection = this.sharedCollection;
      var json = sharedCollection.toJSON();
      var first = json[0];
      var type = first && first.weighting ? first.weighting : "No Weighting";
      var creativesCaution = "<p>The strategy will be ineligible to run until you assign creatives." + '</p><p class="question">Do you want to proceed anyway?</p>';
      var self = this;
      const $el = this.el;
      var $dropdown = $el.find(".weighting-type")[0];
      var noWeighting = $dropdown.value === "No Weighting" || $dropdown.value === "None";
      var alertDialog = $("<div></div>");
      this.renderPartialTemplate({
        template: this.partials.alertDialog,
        data: {
          header: "No Creatives Assigned",
          message: creativesCaution
        },
        useTemplateHtml: false,
        targetEl: alertDialog
      });
      if (!sharedCollection.length) {
        this.stopSpinner();
        statusInvalidCallback([]);
        alertDialog.dialog({
          autoOpen: true,
          modal: true,
          dialogClass: "w-WarningAlert",
          buttons: [{
            text: "Confirm",
            class: "save",
            click: function (e) {
              const $dialog = $(this);
              const $saveButtonContext = $dialog.closest(".w-WarningAlert");
              const saveBtn = $(e.currentTarget);
              self.startSpinner();
              self.startSpinner($saveButtonContext, "save");
              saveBtn.off("click");
              sharedCollection.saveConcepts(function () {
                self.stopSpinner($saveButtonContext, "save");
                self.strategy.fetch({
                  success: function () {
                    successCallback();
                  }
                });
                $dialog.dialog("close");
              }, function () {
                self.stopSpinner($saveButtonContext, "save");
                statusInvalidCallback();
                $dialog.dialog("close");
              });
            }
          }, {
            text: "Cancel",
            class: "cancel",
            click: function () {
              statusInvalidCallback([]);
              $(this).dialog("close");
            }
          }],
          close: function () {
            $(this).remove();
          }
        });
      } else {
        const validationResult = sharedCollection.validateStrategyConcepts(type, this.strategy.id);
        T1.EventHub.publish("strategyConcepts:save");
        if (validationResult.error && !noWeighting) {
          this.stopSpinner();
          statusInvalidCallback([]);
          this.renderPartialTemplate({
            template: this.partials.alertDialog,
            data: {
              message: "This strategy will not run until the concept weighting adds up to 100% of budget or impressions.",
              header: "Invalid Weighting"
            },
            useTemplateHtml: false,
            targetEl: alertDialog
          });
          alertDialog.dialog({
            autoOpen: true,
            modal: true,
            dialogClass: "w-WarningAlert",
            buttons: [{
              text: "Close",
              class: "save",
              click: function () {
                self.stopSpinner($el, "saveAndContinue");
                self.stopSpinner($el, "save");
                $(".save").removeClass("save-disabled");
                self.setSaveAndContinueMode(false);
                self.initUnsavedStateCheck();
                $(this).dialog("close");
              }
            }],
            close: function () {
              $(this).remove();
            }
          });
        } else {
          sharedCollection.saveConcepts(function () {
            self.strategy.fetch({
              success: function () {
                successCallback();
              }
            });
          }, statusInvalidCallback);
        }
      }
    }
  });
  return T1.Form.ViewUtils.extend(CreativesPanel);
});
