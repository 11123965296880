define('modules/strategy/views/helpers/healthInfoGenerator',['require','jQuery','async','T1','collections/creatives','collections/creativeNetStatuses','models/healthCheckFacebook','utils/CampaignStrategyUtils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const async = require("async");
  const T1 = require("T1");
  const Creatives = require("collections/creatives");
  const CreativeNetStatuses = require("collections/creativeNetStatuses");
  const HealthCheckFacebook = require("models/healthCheckFacebook");
  const Utils = require("utils/CampaignStrategyUtils");
  var orderOfMessages = ["Administrative Status", "Organization", "Contract Active", "Agency", "Advertiser", "Campaign Active", "Strategy Active", "Current day/time within Campaign start/end", "Current day/time within Strategy start/end", "Budgets", "Campaign Budget", "Strategy Budget", "Active Creative Assigned", "Creative Approval", "Valid for Facebook"];
  var titlesOnlyMessages = ["Administrative Status", "Campaign Active", "Strategy Active", "Current day/time within Campaign start/end", "Current day/time within Strategy start/end", "Budgets", "Active Creative Assigned", "Creative Approval"];
  function flattenSparseArray(array) {
    return array.filter(item => item);
  }
  function hasFacebook(array) {
    var idx = orderOfMessages.indexOf("Valid for Facebook");
    return array[idx] !== undefined;
  }
  function createLoadingResults() {
    var results = [];
    titlesOnlyMessages.forEach(function (message) {
      addItem(this, message, "", "", []);
    }, results);
    return flattenSparseArray(results);
  }
  function createComplexItemParams(notes) {
    const hasPassed = notes.every(item => item.status() === "pass");
    notes = notes.map(function ({
      message: message,
      note: note,
      status: status
    }) {
      return {
        noteMessage: message + (note ? ` - ${note}` : ""),
        noteStatus: status
      };
    });
    return {
      note: notes,
      status: hasPassed ? "pass" : "fail"
    };
  }
  function createItemObj(message, status, note, notes, downloadLink) {
    return {
      message: message,
      status: () => status || "checking",
      notes: notes.length ? notes : null,
      note: note || null,
      downloadLink: downloadLink
    };
  }
  function addItem(result, message, status, note, notes, downloadLink) {
    var index = orderOfMessages.indexOf(message);
    if (index !== -1) {
      result[index] = createItemObj(message, status, note, notes, downloadLink);
    }
  }
  function testDateValidity(todayDate, startDate, endDate) {
    return T1.compareDates(todayDate, startDate) === 1 && T1.compareDates(todayDate, endDate) === -1 ? {
      status: "pass",
      note: undefined
    } : {
      status: "fail",
      note: undefined
    };
  }
  function getCampaignDateParams(todayDate, campaign) {
    var startDate = campaign.get("start_date_actual");
    var endDate = campaign.get("end_date_actual");
    return testDateValidity(todayDate, startDate, endDate);
  }
  function getStrategyDateParams(todayDate, strategy, campaign) {
    const startDate = strategy.get("use_campaign_start") !== "0" ? campaign.get("start_date_actual") : strategy.get("start_date_actual");
    const endDate = strategy.get("use_campaign_end") !== "0" ? campaign.get("end_date_actual") : strategy.get("end_date_actual");
    return testDateValidity(todayDate, startDate, endDate);
  }
  function getContractParams(data) {
    return data.expiryDate === undefined ? {
      status: "fail",
      note: "No contracts found"
    } : {
      status: "pass",
      note: `expires on ${Date.parse(data.expiryDate).toString("MM/d/yyyy")}`
    };
  }
  function getEntityParams(model) {
    return model.get("status") === "1" ? {
      status: "pass",
      note: undefined
    } : {
      status: "fail",
      note: undefined
    };
  }
  function getEntityWithNotesParams(model) {
    var data = getEntityParams(model);
    data.note = data.status === "pass" ? "Active" : "Inactive";
    return data;
  }
  function getBudgetParams(model) {
    const budgetInformation = Utils.getBudgetInformation(model);
    if (model.get("type") !== "campaign" && Number.isNaN(budgetInformation.total_budget)) {
      return {
        status: "pass",
        note: "not applicable"
      };
    }
    return budgetInformation.budget_remaining > 0 ? {
      status: "pass",
      note: "available"
    } : {
      status: "fail",
      note: "none available"
    };
  }
  function pluckItemFromResultArray(str) {
    let foundItem, index;
    var isFound = this.some(function (item, idx) {
      index = idx;
      return item && item.message === str;
    });
    if (isFound) {
      foundItem = this[index];
      this[index] = undefined;
    }
    return foundItem;
  }
  function convertToDatePartOnly(dateObj) {
    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  }
  function processFacebookErrors(errors) {
    var notes = errors.map(function (error) {
      return createItemObj(T1.Utils.toInitialCaps(error.error_message.trim()), "fail", "", []);
    });
    return createComplexItemParams(notes);
  }
  function prepareCreativesInstance(conceptId) {
    var creativesCollection = new Creatives([], {
      isPaginated: false,
      fetchOptions: {
        with: ["creatives"]
      }
    });
    creativesCollection.urlFilter.set({
      entity: "concept",
      id: conceptId
    });
    return creativesCollection;
  }
  function prepareContractsInstance(advertiser) {
    var agency = advertiser.get("agency");
    var organization = agency.get("organization");
    var contracts = organization.get("contracts");
    contracts.urlFilter.set({
      entity: "organization",
      id: organization.id
    });
    return contracts;
  }
  function checkCreativeNetApprovalStatus(creativesColl, callback) {
    const pageLimit = 50;
    const creativeNetStatuses = new CreativeNetStatuses(null, {
      pageLimit: pageLimit
    });
    const ids = creativesColl.pluck("id").splice(-pageLimit);
    if (ids.length) {
      creativeNetStatuses.fetchOptions.atomic_creative_ids = `(${ids})`;
      creativeNetStatuses.fetch({
        success: function (data) {
          var approvalJson = data.toJSON();
          if (approvalJson.length) {
            $.each(approvalJson, function (i, approvalModel) {
              var creative = creativesColl.get(approvalModel.atomic_creative_id);
              creative.set({
                net_status: approvalModel.net_status
              }, {
                silent: true
              });
            });
          }
          callback(null, creativesColl);
        }
      });
    } else {
      callback(null, []);
    }
  }
  const HealthInfoGenerator = function ({
    strategy: strategy
  } = {}) {
    if (!strategy) {
      throw new Error('The incoming args and its "strategy" property must be present.');
    }
    function processCreative(strategyConcepts, dtToday) {
      var deferred = $.Deferred();
      if ($.type(dtToday) !== "date") {
        throw new Error("Incoming param dtToday must be an object of Date type.");
      }
      if (!strategyConcepts || !Array.isArray(strategyConcepts.models)) {
        throw new Error('Incoming param "strategyConcepts" must be a valid collection');
      }
      const arrFuncs = strategyConcepts.models.map(function (conceptModel) {
        return function (callback) {
          var creativesCollection = prepareCreativesInstance(conceptModel.id);
          creativesCollection.fetch({
            success: function () {
              checkCreativeNetApprovalStatus(creativesCollection, callback);
            }
          });
        };
      });
      async.parallel(arrFuncs, function (err, results) {
        var today = convertToDatePartOnly(dtToday);
        var assignedCreativeResult = {
          status: "fail",
          note: ""
        };
        var creativeApprovalResult = {
          status: "fail",
          note: ""
        };
        var hasRejectedCreative = false;
        var hasPendingCreative = false;
        var rejectedCount = 0;
        var pendingCount = 0;
        var hasCreative = false;
        var hasActiveCreative = false;
        var hasActiveAndNotScheduledCreative = false;
        var hasActiveAndScheduledAndInWindowCreative = false;
        var hasActiveScheduledButNotStartedCreative = false;
        var hasActiveScheduledButExpiredCreative = false;
        var rejectedCreatives = [];
        var combinedResult = {};
        $.each(results, function (idx, creativesColl) {
          if (creativesColl.length) {
            $.each(creativesColl.models, function (index, creativeModel) {
              var approvalStatus = creativeModel.get("net_status");
              hasCreative = true;
              if (approvalStatus === "REJECTED") {
                hasRejectedCreative = true;
                rejectedCount++;
                rejectedCreatives.push(creativeModel.id);
              } else if (approvalStatus === "PENDING") {
                hasPendingCreative = true;
                pendingCount++;
              }
              if (creativeModel.get("status") === "1") {
                let startDate = Date.parse(creativeModel.get("start_date"));
                let endDate = Date.parse(creativeModel.get("end_date"));
                hasActiveCreative = true;
                if (endDate && startDate) {
                  startDate = convertToDatePartOnly(startDate);
                  endDate = convertToDatePartOnly(endDate);
                  if (endDate < today) {
                    hasActiveScheduledButExpiredCreative = true;
                  } else if (startDate > today) {
                    hasActiveScheduledButNotStartedCreative = true;
                  } else {
                    hasActiveAndScheduledAndInWindowCreative = true;
                    return true;
                  }
                } else {
                  hasActiveAndNotScheduledCreative = true;
                }
              }
            });
          }
        });
        if (!hasRejectedCreative && !hasPendingCreative && hasCreative) {
          creativeApprovalResult.status = "pass";
          creativeApprovalResult.note = "";
        } else if (hasRejectedCreative) {
          creativeApprovalResult.status = "fail";
          creativeApprovalResult.note = `${rejectedCount} creatives rejected`;
          const rejectedIds = rejectedCreatives.join(",");
          creativeApprovalResult.downloadLink = `${T1.API_ROOT}atomic_creatives/creative_approvals_report?atomic_creative_ids=(${rejectedIds})`;
        } else if (hasPendingCreative) {
          creativeApprovalResult.status = "warning";
          creativeApprovalResult.note = `${pendingCount} pending creatives`;
        } else {
          creativeApprovalResult.note = "Unknown problem";
        }
        if (hasActiveAndNotScheduledCreative === true || hasActiveAndScheduledAndInWindowCreative === true) {
          assignedCreativeResult.status = "pass";
          assignedCreativeResult.note = "";
        } else if (hasActiveScheduledButNotStartedCreative === true) {
          assignedCreativeResult.note = `Creative(s) hasn't started`;
        } else if (hasActiveScheduledButExpiredCreative === true) {
          assignedCreativeResult.note = "Creative(s) has expired";
        } else if (hasCreative === false || hasActiveCreative === false) {
          assignedCreativeResult.note = "No active creative assigned";
        } else {
          assignedCreativeResult.note = "Unknown problem";
        }
        combinedResult.assignedCreatives = assignedCreativeResult;
        combinedResult.creativeApprovals = creativeApprovalResult;
        deferred.resolve(combinedResult);
      });
      return deferred;
    }
    function getHealthInformation(options) {
      var self = this;
      var result = [];
      $.when(getAdvertiser(), getCreative(), getFacebook()).done(returnDataForRender);
      function performAddItem(message, params) {
        let note, notes;
        if (Array.isArray(params.note)) {
          notes = params.note;
          note = "";
        } else {
          notes = [];
          note = params.note || "";
        }
        addItem(result, message, params.status, note, notes, params.downloadLink);
      }
      function addComplexItem(message, notesAsMessages) {
        if (orderOfMessages.includes(message)) {
          const arrayOfFoundMessages = notesAsMessages.map(pluckItemFromResultArray, result);
          performAddItem(message, createComplexItemParams(arrayOfFoundMessages));
        }
      }
      function getAdvertiser() {
        var deferred = $.Deferred();
        var campaign = strategy.get("campaign");
        var advertiser = campaign.get("advertiser");
        advertiser.fetch({
          params: {
            with: "agency,organization",
            full: "*"
          }
        }).then(function () {
          setEntityHealth(advertiser);
          prepareContractsInstance(advertiser).getHealth().then(function (data) {
            performAddItem("Contract Active", getContractParams(data));
            deferred.resolve();
          });
        });
        return deferred;
      }
      function getCreative() {
        var deferred = $.Deferred();
        var strategyConcepts = strategy.get("strategyConcepts");
        function setConceptsAndApprovalHealth(params) {
          if (params) {
            performAddItem("Active Creative Assigned", params.assignedCreatives);
            performAddItem("Creative Approval", params.creativeApprovals);
          } else {
            performAddItem("Active Creative Assigned", {
              status: "fail",
              note: "No concept assigned"
            });
            performAddItem("Creative Approval", {
              status: "fail",
              note: "No concept assigned"
            });
          }
          deferred.resolve();
        }
        strategyConcepts.id = strategy.id;
        strategyConcepts.fetch().then(function () {
          if (strategyConcepts.length) {
            self.processCreative(strategyConcepts, new Date()).then(setConceptsAndApprovalHealth);
          } else {
            setConceptsAndApprovalHealth();
          }
        });
        return deferred;
      }
      function getFacebook() {
        var deferred = $.Deferred();
        var healthCheckFacebook = new HealthCheckFacebook({
          id: strategy.get("id")
        });
        healthCheckFacebook.fetch().then(function () {
          let params;
          var state = healthCheckFacebook.get("state");
          if (state) {
            switch (state) {
              case HealthCheckFacebook.VALID:
                params = {
                  status: "pass",
                  note: undefined
                };
                break;
              case HealthCheckFacebook.INVALID:
                params = processFacebookErrors(healthCheckFacebook.get("errors"));
                break;
              case HealthCheckFacebook.PENDING:
                params = {
                  status: "warning",
                  note: "Pending"
                };
                break;
            }
          }
          if (params !== undefined) {
            performAddItem("Valid for Facebook", params);
          }
          deferred.resolve();
        });
        return deferred;
      }
      function setEntityHealth(advertiser) {
        var campaign = strategy.get("campaign");
        var agency = advertiser.get("agency");
        var organization = agency.get("organization");
        var todayDate = T1.today();
        performAddItem("Organization", getEntityWithNotesParams(organization));
        performAddItem("Agency", getEntityWithNotesParams(agency));
        performAddItem("Advertiser", getEntityWithNotesParams(advertiser));
        performAddItem("Campaign Active", getEntityParams(campaign));
        performAddItem("Strategy Active", getEntityParams(strategy));
        performAddItem("Current day/time within Campaign start/end", getCampaignDateParams(todayDate, campaign));
        performAddItem("Current day/time within Strategy start/end", getStrategyDateParams(todayDate, strategy, campaign));
        performAddItem("Campaign Budget", getBudgetParams(campaign));
        performAddItem("Strategy Budget", getBudgetParams(strategy));
      }
      function returnDataForRender() {
        if (options && options.success) {
          addComplexItem("Administrative Status", ["Organization", "Contract Active", "Agency", "Advertiser"]);
          if (hasFacebook(result)) {
            result[orderOfMessages.indexOf("Strategy Budget")] = undefined;
            addComplexItem("Budgets", ["Campaign Budget"]);
          } else {
            addComplexItem("Budgets", ["Campaign Budget", "Strategy Budget"]);
          }
          result = flattenSparseArray(result);
          options.success(result);
        }
      }
      return createLoadingResults();
    }
    return {
      getHealthInformation: getHealthInformation,
      processCreative: processCreative
    };
  };
  return HealthInfoGenerator;
});
