define('modules/reporting/campaigns/dataExport/createEdit/views/reportType',["jQuery", "T1", "T1View", "text!../templates/reportType.html"], function ($, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "changed .report-selector": "selectReport"
    },
    eventhubEvents: {
      "saved:report": function () {
        this.disableDDL = true;
        this.load();
      }
    },
    initialize: function (model) {
      this.DataExportModel = model;
    },
    load: function () {
      const $el = this.el;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
      });
    },
    unload: function () {
      this.disableDDL = null;
    },
    selectReport: function (event) {
      this.DataExportModel.setSelectedReport($(event.currentTarget).attr("value"));
      T1Publish("change:formElements", true);
    },
    serialize: function () {
      const editReport = this.DataExportModel.get("editReport");
      const disable = editReport || this.disableDDL;
      return {
        disableDDL: disable ? "disabled" : "",
        reports: this.DataExportModel.getReports(),
        selectedReport: this.DataExportModel.getSelectedReport()
      };
    }
  });
});
