define('utils/PromiseProvider',["When", "utils/ExpiringCache"], function () {
  var PromiseProvider = function (id, cache, promiseFactory, factoryHelper) {
    this.__id = id;
    this.__cache = cache;
    this.__promiseFactory = promiseFactory;
    this.__factoryHelper = factoryHelper;
  };
  PromiseProvider.prototype.then = function (...args) {
    var factory = this.__promiseFactory;
    var promise = null;
    if (!this.__cache) {
      promise = factory(this.__factoryHelper);
    } else {
      promise = this.__cache.get(this.__id);
      if (!promise) {
        promise = factory(this.__factoryHelper);
        this.__cache.set(this.__id, promise);
      }
    }
    promise.then(...args);
  };
  return PromiseProvider;
});
