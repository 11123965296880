define('modules/audiences/pixels/views/eventURLPopover',["jQuery", "T1", "T1Layout", "T1View", "text!modules/audiences/pixels/templates/eventURLPopover.html"], function ($, T1, T1Layout, T1View, template) {
  return T1View.extend({
    template: template,
    initialize: function (args) {
      this.pixel_model = args.pixel_model;
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        this.popover = $(self.el).find("#popover-" + self.pixel_model.get("id"));
        this.popover[0].toggle();
      });
    },
    serialize: function () {
      var data = this.pixel_model.toJSON();
      var pixel_urls = data.pixel_bundle_url;
      var halfLenght;
      var leftSide;
      if (pixel_urls !== undefined) {
        if (pixel_urls.hasOwnProperty("url")) {
          data.pixel_bundle_url.last_item = true;
        } else {
          var length = pixel_urls.length;
          if (length !== 0 && length < 10) {
            data.pixel_bundle_url[pixel_urls.length - 1].last_item = true;
          }
        }
      }
      if (pixel_urls.length > 5) {
        halfLenght = Math.ceil(pixel_urls.length / 2);
        leftSide = pixel_urls.splice(0, halfLenght);
      } else {
        leftSide = pixel_urls;
        pixel_urls = [];
      }
      return {
        pixel_info: data,
        left: leftSide,
        right: pixel_urls
      };
    }
  });
});
