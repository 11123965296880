define('modules/strategy/supply/pmpd/views/panel',['require','T1','T1View','T1Layout','collections/sitePlacements','collections/organizations','text!../../shared/templates/panel.html','text!../templates/panel_layout.html','text!../templates/grid_item.html','text!../templates/grid_header.html','text!templates/form_footer.html','text!templates/alert.html'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Layout = require("T1Layout");
  const SitePlacements = require("collections/sitePlacements");
  const Orgs = require("collections/organizations");
  const tmpl = require("text!../../shared/templates/panel.html");
  const layout_tmpl = require("text!../templates/panel_layout.html");
  const grid_item = require("text!../templates/grid_item.html");
  const grid_header = require("text!../templates/grid_header.html");
  const form_footer = require("text!templates/form_footer.html");
  const alert_tmpl = require("text!templates/alert.html");
  var resetSortArrows;
  const highLightFunc = T1.Utils.highlightText;
  const PmpdPanel = T1View.extend({
    contentType: "site_placement",
    template: tmpl,
    noCheckUnsavedState: true,
    partials: {
      form_footer: form_footer,
      alertDialog: alert_tmpl
    },
    events: {
      "click .hideIcon": "hideWarning",
      "click .open-unpermissioned-deals-modal": "openUnpermissionedDealsModal",
      "click .close-unpermissioned-deals-modal": "closeUnpermissionedDealsModal"
    },
    eventhubEvents: {
      "supply:saveSuccessful": "onSaveSuccessful",
      "pmpd:coll:fetched": "load",
      "filter:clearFilter": "resetSortArrows",
      "hideUnpermissionedDealsWarning:reloadBins": "hideUnpermissionedDealsWarning"
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      targetAllFlagEnabled: targetAllFlagEnabled,
      mapItemsBins: mapItemsBins,
      hasUnpermissionedDeals: hasUnpermissionedDeals,
      addOrRemoveSharedCollection: addOrRemoveSharedCollection
    }) {
      this.sharedCollection = sharedCollection;
      this.targetAllFlagEnabled = targetAllFlagEnabled;
      this.mapItemsBins = mapItemsBins;
      this.hasUnpermissionedDeals = hasUnpermissionedDeals;
      this.addOrRemoveSharedCollection = addOrRemoveSharedCollection;
      this.initializeLayout();
      this.updateStrategyModel();
    },
    updateStrategyModel: function () {
      this.model.tempSupplyInfo = {
        run_on_all_exchanges: this.model.get("run_on_all_exchanges"),
        run_on_all_pmp: this.model.get("run_on_all_pmp")
      };
    },
    load: function () {
      const {
        collection: collection,
        sharedCollection: shColl
      } = this;
      if (!this.sharedCollection.hasUnpermissionedDeals) {
        T1.EventHub.publish("hideUnpermissionedDealsWarning:reloadBins");
      }
      this.render().then(() => {
        if (this.layout) {
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "supply_targeting"
          });
          this.layout.load().then(() => {
            if (collection) {
              collection.currentSearchTerm = undefined;
              collection.searchPlacements();
            }
            this.el.trigger("subViewLoad");
          });
        }
        if (!this.targetAllFlagEnabled) {
          this.checkWarning(shColl.length, shColl.contentType);
        }
      });
    },
    checkWarning: function (len, type) {
      var viewType = this.contentType;
      const action = len > 0 && type !== viewType ? "show" : "hide";
      T1.EventHub.publish(`supply:${action}Warning`, viewType);
    },
    hideWarning: function (evt) {
      this.preventDefault(evt);
      this.checkWarning(0, "hide-warning");
    },
    openUnpermissionedDealsModal: function (event) {
      event.preventDefault();
      const modal = this.$(".unpermissioned-deals-modal");
      const grid = modal.find(".unpermissioned-deals-grid")[0];
      grid.data = this.sharedCollection.generateUnpermissionedGridData();
      modal[0].show();
    },
    closeUnpermissionedDealsModal: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.$(".unpermissioned-deals-modal")[0].hide();
    },
    hideUnpermissionedDealsWarning: function () {
      this.hasUnpermissionedDeals = false;
    },
    initializeLayout: function () {
      var self = this;
      var filterClass = "filter-pmpd-selectbox";
      var filterAndClearCallbackPmpd = function (term) {
        var collection = self.collection;
        const fieldName = self.$(`.${filterClass}`).val();
        if (!term) {
          resetSortArrows = true;
        }
        collection.currentSearchTerm = term;
        collection.searchPlacements(term, fieldName);
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: layout_tmpl,
        layout: {
          ".filters-c": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallbackPmpd,
              clearFilterCallback: filterAndClearCallbackPmpd,
              selectOptions: [{
                text: "Placement Name",
                value: "placement"
              }, {
                text: "Publisher",
                value: "publisher"
              }, {
                text: "Site",
                value: "site"
              }],
              filterClass: filterClass
            }
          }],
          ".browse-list": [{
            module: "strategy/supply/shared",
            viewType: "browse",
            showLoader: true,
            options: this.getBrowseOptions()
          }],
          ".bins-column": [{
            module: "strategy/supply/shared",
            viewType: "bins",
            options: {
              sharedCollection: this.sharedCollection,
              model: this.model,
              mapItems: this.mapItemsBins,
              contentType: this.contentType,
              hasUnpermissionedDeals: this.sharedCollection.hasUnpermissionedDeals
            }
          }]
        },
        serialize: function () {
          return {
            unpermissionedDealCount: self.sharedCollection.unpermissionedDeals ? self.sharedCollection.unpermissionedDeals.length : undefined
          };
        }
      });
    },
    getBrowseOptions: function () {
      var self = this;
      return {
        sharedCollection: this.sharedCollection,
        model: this.model,
        contentType: this.contentType,
        createCollection: function () {
          var coll = this.collection = new SitePlacements([], {
            isPaginated: true
          });
          var mediaType = self.model.get("media_type").toLowerCase();
          self.collection = coll;
          Orgs.getOrganizations().getSelected().then(function (id) {
            coll.setOrgId(id, mediaType);
            coll.fetchAllPages({
              success: function () {
                if (self.targetAllFlagEnabled) {
                  self.sharedCollection.setTypeIds(coll);
                } else {
                  self.sharedCollection.setModelIds(coll);
                }
                self.load();
              }
            });
          });
        },
        partials: {
          gridHeader: grid_header,
          listItem: grid_item
        },
        sortConfigs: {
          order: "ascending",
          sortBy: function (data) {
            var order = this.sortOrder;
            this.currentSortField = data;
            this.sortOrder = order ? -order : 1;
            this.collection.sortPlacements(data, this.sortOrder);
          }
        },
        eventhubEvents: {
          "supply:changeTypeByItem:site_placement": function (data) {
            this.closeChangeTypeBy("item")(data);
          },
          "supply:changeTypeByItems:site_placement": function (data) {
            this.closeChangeTypeBy("items")(data);
          },
          "supply:changeTypeByAll:site_placement": "closeChangeTypeByAll"
        },
        dataEvents: {
          collection: {
            reset: "load",
            "change:deal": "updateShColl"
          },
          sharedCollection: {
            add: function (model) {
              this.addOrRemoveSharedCollection(this, model, "add");
            },
            remove: function (model) {
              this.addOrRemoveSharedCollection(this, model, "remove");
            },
            toggleAll: "renderAllItems"
          }
        },
        styleForBrowse: this.styleForBrowse,
        serialize: this.serializeBrowse,
        addOrRemoveSharedCollection: this.addOrRemoveSharedCollection
      };
    },
    serializeBrowse: function (collection) {
      var coll = collection || this.collection;
      var models = coll.models;
      var list = this.chooseRenderStyle(null, models);
      if (resetSortArrows) {
        this.currentSortField = "";
        resetSortArrows = false;
      }
      return {
        list: list,
        ascending: this.sortOrder === 1,
        isPlacementSortArrowVisible: this.currentSortField === "placement",
        isPublisherSortArrowVisible: this.currentSortField === "publisher",
        isSiteSortArrowVisible: this.currentSortField === "site"
      };
    },
    styleForBrowse: function (models) {
      const {
        collection: coll,
        sharedCollection: sharedColl,
        contentType: type
      } = this;
      var searchTerm = coll.currentSearchTerm;
      searchTerm = searchTerm && searchTerm.toString ? searchTerm.toString() : "";
      if (!models) {
        models = coll ? coll.models : [];
      }
      function isActive(id) {
        var shared = sharedColl.get(id);
        const isType = shared ? shared.get("type") === type : false;
        return isType;
      }
      return models.map(function (model) {
        let placementVal = model.get("placement");
        let publisherVal = model.get("publisher");
        let siteVal = model.get("site");
        const modelType = model.get("type");
        if (searchTerm.length) {
          placementVal = highLightFunc(placementVal, searchTerm);
          publisherVal = highLightFunc(publisherVal, searchTerm);
          siteVal = highLightFunc(siteVal, searchTerm);
        }
        return {
          isActive: isActive(model.id),
          placement: placementVal,
          publisher: publisherVal,
          site: siteVal,
          id: model.id,
          type: modelType
        };
      });
    },
    canUnload: function (fromT1FormCancelButton) {
      return fromT1FormCancelButton !== true;
    },
    onSaveSuccessful: function () {
      this.stopSpinner();
      if (!this.sharedCollection.changedWhileSaving) {
        this.isFormElementsChanged = false;
      }
      this.updateFooterMessage({
        success: true
      });
      this.processSaveAndContinueMode();
      T1.EventHub.publish("strategy-create-edit:reloadSubViews");
      this.updateStrategyModel();
    },
    postData: function () {
      var publishEvent = this.sharedCollection.length ? "supply:save" : "supply:noSelection";
      T1.EventHub.publish(publishEvent);
    }
  });
  return T1.Form.ViewUtils.extend(PmpdPanel);
});
