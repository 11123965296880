define('modules/reporting/campaigns/performance/views/strategyQuickEdit',['require','jQuery','Underscore','models/campaign','moment','models/strategy','T1','T1Permissions','T1View','text!../templates/strategyQuickEdit.html','utils/CampaignStrategyUtils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const CampaignModel = require("models/campaign");
  const moment = require("moment");
  const StrategyModel = require("models/strategy");
  const T1 = require("T1");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!../templates/strategyQuickEdit.html");
  const Util = require("utils/CampaignStrategyUtils");
  const ctrlKey = 17;
  const cursorDown = 40;
  const cursorLeft = 37;
  const cursorRight = 39;
  const cursorUp = 38;
  const deleteKey = 8;
  const endKey = 35;
  const homeKey = 36;
  const macCMDkey = 224;
  const pasteDelay = 100;
  const tabKey = 9;
  const keysToBypass = Object.freeze([ctrlKey, cursorDown, cursorLeft, cursorRight, cursorUp, deleteKey, endKey, homeKey, macCMDkey, tabKey]);
  const defaultCurrency = "USD";
  const currencySymbol = T1.Utils.getCurrencySymbol;
  const formatCurrencyNoSymbol = T1.Utils.formatCurrencyNoSymbol;
  const calculateDailySpendToPace = Util.calculateDailySpendToPace;
  const yesterday = moment().subtract(1, "days")._d.toString("yyyy-MM-dd");
  const frequencyIntervalText = Object.freeze({
    hour: "Hour",
    day: "Day",
    week: "7 Days",
    month: "30 Days"
  });
  const goalTypeMap = Object.freeze({
    cpa: "CPA",
    cpc: "CPC",
    cpe: "CPE",
    ctr: "CTR",
    reach: "CPM REACH",
    roi: "ROI",
    spend: "CPM SPEND",
    vcpm: "Viewable CPM",
    vcr: "Video Completion Rate",
    viewability_rate: "Viewability Rate"
  });
  const dropdownList = Object.freeze({
    pacingType: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }]
    },
    pacingInterval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }]
    },
    impressionPacingInterval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }]
    },
    impressionPacingType: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    frequencyType: {
      options: [{
        value: "even",
        text: "Even"
      }, {
        value: "asap",
        text: "ASAP"
      }, {
        value: "no-limit",
        text: "No Cap"
      }]
    },
    frequencyInterval: {
      options: [{
        value: "hour",
        text: frequencyIntervalText.hour
      }, {
        value: "day",
        text: frequencyIntervalText.day
      }, {
        value: "week",
        text: frequencyIntervalText.week
      }, {
        value: "month",
        text: frequencyIntervalText.month
      }]
    },
    targetAllocationOptions: {
      options: [{
        value: "monetary",
        text: "Monetary"
      }, {
        value: "impression",
        text: "Impression"
      }]
    }
  });
  const strategyQuickEdit = T1View.extend({
    template: template,
    events: {
      "changed .caps-frequency-ddl": "toggleFrequencyCaps",
      "changed .pacing-impression-ddl": "toggleImpressionPacing",
      "changed .pacing-monetary-ddl": "toggleMonetaryPacing",
      "click .btn-apply-strategyedit": "saveStrategy",
      "click .btn-close-strategyedit": "publishClosePopupEvent",
      "click .btn-edit-newt1": "editInNewT1",
      "click .custom-amount": "toggleAllocationPacing",
      "click .custom": "setFrequencyCapsState",
      "click .strategy-quickedit": "stopBubbling",
      "click .t1-optimized": "setFrequencyCapsState",
      "click .target-allocation": "toggleAllocationPacing",
      "close strand-popover": "closePopup",
      "keyup .bid-aggressiveness": "validateInteger",
      "keyup .bid-price": "validateCurrency",
      "keyup .budget": "validateCurrency",
      "keyup .caps-frequency-ip": "validateNumber",
      "keyup .max-bid": "validateCurrency",
      "keyup .min-bid": "validateCurrency",
      "keyup .pacing-impression-ip": "validateInteger",
      "keyup .pacing-monetary-ip": "validateCurrency",
      "keyup .strategy-goal-input": "validateGoalValue",
      "paste .bid-aggressiveness": "pasteInteger",
      "paste .bid-price": "pasteCurrency",
      "paste .budget": "pasteCurrency",
      "paste .caps-frequency-ip": "pasteNumber",
      "paste .max-bid": "pasteCurrency",
      "paste .min-bid": "pasteCurrency",
      "paste .pacing-impression-ip": "pasteInteger",
      "paste .pacing-monetary-ip": "pasteCurrency",
      "paste .strategy-goal-input": "pasteGoalValue"
    },
    initialize(args) {
      Object.assign(this, {
        dim1Collection: args.arguments.dataGroup.dim1Collection,
        dim1NestedCollection: args.arguments.dataGroup.collection,
        selectedCurrency: args.selectedCurrency,
        strategyQuickEditID: args.strategyId,
        useAllocPacing: T1Permissions.check("feature", "use_alloc_pacing")
      });
    },
    loadQuickEditData() {
      this.strategyModel = new StrategyModel({
        id: this.strategyQuickEditID
      });
      this.strategyModel.fetch({
        success: data => {
          this.strategyData = data.toJSON();
          this.strategyModel.campaign = new CampaignModel({
            id: this.strategyData.campaign_id
          });
          this.strategyModel.campaign.fetch({
            params: {
              with: ["rpt_goal_monitoring"]
            },
            success: campaignData => {
              const settleDelay = 100;
              this.campaignData = campaignData.toJSON();
              this.campaignModel = campaignData;
              const {
                flag: canEditWithV2,
                entityUrl: mmBetaEditUrl
              } = Util.allowV2CampaignOrStrategyEdit({
                campaign: this.strategyModel.campaign,
                strategy: this.strategyModel,
                view: self,
                showDialog: false
              });
              this.renderPartial(".content");
              Object.assign(this, {
                allocationPacingContainer: this.$(".allocation-pacing-container"),
                capsDdl: this.$(".caps-frequency-ddl"),
                capsInput: this.$(".caps-frequency-ip"),
                capsIntervalDdl: this.$(".caps-frequency-interval"),
                customAmountContainer: this.$(".custom-amount-container"),
                footer: this.$("strand-footer")[0],
                frequencyCapsAmount: this.$(".frequency-caps-amount-container"),
                frequencyCapsInterval: this.$(".frequency-caps-interval-container"),
                impressionPacingAmount: this.$(".impression-pacing-amount-container"),
                impressionPacingInterval: this.$(".impression-pacing-interval-container"),
                monetaryPacingAmount: this.$(".monetary-pacing-amount-container"),
                monetaryPacingInterval: this.$(".monetary-pacing-interval-container"),
                targetAllocationContainer: this.$(".target-allocation-container"),
                canEditWithV2: canEditWithV2,
                mmBetaEditUrl: mmBetaEditUrl
              });
              setTimeout(() => {
                this.setUiComponents(this.strategyData, this.campaignData);
              }, settleDelay);
              this.strategyLoader.hide();
            }
          });
        }
      });
    },
    load() {
      this.render().then(() => {
        this.strategyQuickEdit = this.$(".strategy-quickedit")[0];
        this.strategyLoader = $(this.strategyQuickEdit).find(".strategy-loader");
        this.strategyLoader.show();
        this.strategyQuickEdit.target = `#card${this.strategyQuickEditID}`;
        this.strategyQuickEdit.open();
        this.loadQuickEditData();
      });
    },
    setUiComponents(strategyData, campaignData) {
      let frequencyOptimization = false;
      const campaignReachOrSpend = campaignData.goal_type === "reach" || campaignData.goal_type === "spend";
      const t1OptimizedElement = this.$(".t1-optimized");
      const customElement = this.$(".custom");
      const targetAllocation = this.$(".target-allocation");
      const customAmount = this.$(".custom-amount");
      if (strategyData.status === "1") {
        this.$(".strategy-active").attr("checked", "checked");
      } else {
        this.$(".strategy-inactive").attr("checked", "checked");
      }
      if (strategyData.frequency_optimization) {
        frequencyOptimization = strategyData.frequency_optimization === "1";
      } else {
        frequencyOptimization = !campaignReachOrSpend;
      }
      if (frequencyOptimization) {
        t1OptimizedElement.attr("checked", "checked");
        this.capsDdl[0].value = "";
        this.capsDdl.attr("disabled", "disabled");
      } else {
        customElement.attr("checked", "checked");
      }
      if (campaignReachOrSpend || strategyData.goal_type === "spend") {
        customElement.attr("checked", "checked");
        t1OptimizedElement.attr("disabled", "disabled");
        customElement.attr("disabled", "disabled");
      }
      if (this.useAllocPacing) {
        this.allocationPacingContainer.removeClass("no-display");
        if (strategyData.use_optimization === "1") {
          if (strategyData.pacing_optimization_type) {
            this.customAmountContainer.addClass("no-display");
            this.targetAllocationContainer.removeClass("no-display");
            targetAllocation.attr("checked", "checked");
          } else {
            customAmount.attr("checked", "checked");
            this.customAmountContainer.removeClass("no-display");
            this.targetAllocationContainer.addClass("no-display");
          }
        } else {
          targetAllocation.attr("disabled", "disabled");
          customAmount.attr("checked", "checked");
          this.customAmountContainer.removeClass("no-display");
          this.targetAllocationContainer.addClass("no-display");
        }
      } else {
        this.allocationPacingContainer.addClass("no-display");
        this.customAmountContainer.removeClass("no-display");
      }
    },
    setFrequencyCapsState() {
      const t1Optimized = this.$(".t1-optimized").attr("checked");
      if (t1Optimized) {
        this.capsInput[0].value = "";
        this.capsInput.error = false;
        this.capsDdl[0].value = "";
        this.capsIntervalDdl[0].value = "";
        this.capsDdl.attr("disabled", "disabled");
        this.capsInput.attr("disabled", "disabled");
        this.capsIntervalDdl.attr("disabled", "disabled");
      } else {
        this.frequencyCapsAmount.removeClass("no-display");
        this.frequencyCapsInterval.removeClass("no-display");
        this.capsDdl.removeAttr("disabled");
        this.capsInput.removeAttr("disabled");
        this.capsIntervalDdl.removeAttr("disabled");
      }
    },
    toggleAllocationPacing() {
      const targetAllocation = this.$(".target-allocation").attr("checked");
      this.$(".pacing-monetary-ip").error = false;
      this.$(".pacing-error").addClass("no-display");
      this.$(".pacing-impression-ip").error = false;
      this.$(".impression-pacing-error").addClass("no-display");
      this.$(".target-allocation-ip").error = false;
      this.$(".target-allocation-error").addClass("no-display");
      this.$(".target-allocation-ddl").error = false;
      this.$(".target-allocation-error").addClass("no-display");
      if (targetAllocation) {
        this.customAmountContainer.addClass("no-display");
        this.targetAllocationContainer.removeClass("no-display");
      } else {
        this.customAmountContainer.removeClass("no-display");
        this.targetAllocationContainer.addClass("no-display");
      }
    },
    toggle(type, value, element1, element2) {
      switch (type) {
        case "frequency_type":
        case "pacing_type":
        case "impression_pacing_type":
          if (value === "no-limit") {
            element1.addClass("no-display");
            element2.addClass("no-display");
          } else {
            element1.removeClass("no-display");
            element2.removeClass("no-display");
          }
          break;
      }
    },
    toggleImpressionPacing({
      originalEvent: originalEvent
    }) {
      const pacingValue = originalEvent.detail.value;
      this.toggle("impression_pacing_type", pacingValue, this.impressionPacingInterval, this.impressionPacingAmount);
    },
    toggleMonetaryPacing({
      originalEvent: originalEvent
    }) {
      const pacingValue = originalEvent.detail.value;
      this.toggle("pacing_type", pacingValue, this.monetaryPacingInterval, this.monetaryPacingAmount);
    },
    toggleFrequencyCaps({
      originalEvent: originalEvent
    }) {
      const capsValue = originalEvent.detail.value;
      this.toggle("frequency_type", capsValue, this.frequencyCapsInterval, this.frequencyCapsAmount);
    },
    bypassKey(evt) {
      const code = evt.charCode || evt.keyCode;
      let doBypass = keysToBypass.includes(code);
      if (evt.shiftKey) {
        doBypass = false;
      }
      if (evt.ctrlKey || evt.metaKey) {
        doBypass = true;
      }
      return doBypass;
    },
    validate(event, type) {
      let rgx;
      const value = event.target.value;
      const unMaskedValue = type === "currency" ? this.unMaskCurrency(value) : this.unMaskNumber(value);
      const currencyCode = this.campaignData.currency_code;
      const decimalSeparator = T1.Utils.getCurrencyDecimalSeparator(currencyCode);
      switch (type) {
        case "integer":
          rgx = new RegExp(/^[0-9]*$/);
          break;
        case "number":
          rgx = new RegExp(/^[0-9]+(\.?([0-9]*))?$/);
          break;
        case "currency":
          rgx = new RegExp(`^[0-9]*\\${decimalSeparator}?[0-9]*$`);
          break;
      }
      if (!this.bypassKey(event) && !rgx.test(value)) {
        event.currentTarget.set("value", unMaskedValue);
      }
    },
    pasteCurrency(event) {
      setTimeout(() => {
        this.validateCurrency(event);
      }, pasteDelay);
    },
    validateCurrency(event) {
      this.validate(event, "currency");
    },
    pasteNumber(event) {
      setTimeout(() => {
        this.validateNumber(event);
      }, pasteDelay);
    },
    validateNumber(event) {
      this.validate(event, "number");
    },
    pasteInteger(event) {
      setTimeout(() => {
        this.validateInteger(event);
      }, pasteDelay);
    },
    validateInteger(event) {
      this.validate(event, "integer");
    },
    pasteGoalValue(event) {
      setTimeout(() => {
        switch (this.strategyData.goal_type) {
          case "viewability_rate":
          case "vcr":
          case "ctr":
          case "roi":
            this.validateNumber(event);
            break;
          default:
            this.validateCurrency(event);
        }
      }, pasteDelay);
    },
    validateGoalValue(event) {
      switch (this.strategyData.goal_type) {
        case "viewability_rate":
        case "vcr":
        case "ctr":
        case "roi":
          this.validateNumber(event);
          break;
        default:
          this.validateCurrency(event);
      }
    },
    getStrategySpendYesterday() {
      const strategySpend = this.dim1NestedCollection.models.find(model => {
        const {
          start_date: start_date,
          strategy_id: strategy_id
        } = model.attributes;
        return start_date === yesterday && strategy_id === this.strategyQuickEditID;
      });
      if (strategySpend) {
        return formatCurrencyNoSymbol(strategySpend.attributes.total_spend, null, this.getSelectedCurrencyCode(this.strategyData));
      }
      return null;
    },
    getCampaignSpendYesterday() {
      let totalSpend = 0;
      const campaignData = this.campaignData;
      const campaignSpend = this.dim1NestedCollection.models.filter(({
        attributes: {
          start_date: start_date,
          campaign_id: campaign_id
        }
      }) => start_date === yesterday && campaign_id === campaignData.id);
      campaignSpend.forEach(function (item) {
        if (item.attributes) {
          totalSpend += Number(item.attributes.total_spend);
        }
      });
      return formatCurrencyNoSymbol(totalSpend, null, this.getSelectedCurrencyCode(campaignData));
    },
    getRemainingCampaignBudget() {
      const {
        budget_remaining: budget_remaining
      } = Util.getBudgetInformation(this.campaignModel);
      return formatCurrencyNoSymbol(budget_remaining, null, this.campaignData.currency_code);
    },
    getSelectedCurrencyCode(data) {
      return this.selectedCurrency === "campaign" ? data.currency_code : defaultCurrency;
    },
    publishClosePopupEvent() {
      T1.EventHub.publish("close.editStrategyPopup");
    },
    closePopup(event) {
      if (event.currentTarget.state === "closed") {
        this.publishClosePopupEvent();
      }
    },
    editInNewT1(evt) {
      evt.preventDefault();
      window.parent.location.replace(this.mmBetaEditUrl);
    },
    unMaskNumber(value) {
      const val = value ? parseFloat(value) : "";
      return !isNaN(val) ? val : "";
    },
    unMaskCurrency(value) {
      const campaignCurrency = this.campaignData.currency_code;
      const resultVal = T1.Utils.unformatCurrency(value, campaignCurrency || defaultCurrency);
      return !isNaN(resultVal) ? resultVal : "";
    },
    displayErrors(errors, emptyFields) {
      let element, targetElement;
      const [{
        field: field,
        message = ""
      } = {}] = errors;
      this.footer.message = "";
      this.footer.type = "";
      this.footer.hideMessage();
      this.$(".errors").addClass("no-display");
      this.$("strand-input").each(function (index, item) {
        item.error = false;
      });
      if (errors.length) {
        this.footer.message = emptyFields ? "All fields must be completed" : "This form contains errors.";
        this.footer.type = "error";
        this.footer.showMessage();
      } else {
        return;
      }
      switch (field) {
        case "budget":
          targetElement = this.$(".budget");
          element = this.$(".goal-value-error");
          break;
        case "roi_target":
        case "goal_value":
          targetElement = this.$(".strategy-goal-input");
          element = this.$(".goal-value-error");
          break;
        case "min_bid":
          targetElement = this.$(".min-bid");
          element = this.$(".bid-error");
          break;
        case "max_bid":
          if (this.strategyData.goal_type === "spend") {
            targetElement = this.$(".bid-price");
            element = this.$(".goal-value-error");
          } else {
            targetElement = this.$(".max-bid");
            element = this.$(".bid-error");
          }
          break;
        case "bid_aggressiveness":
          targetElement = this.$(".bid-aggressiveness");
          element = this.$(".bid-error");
          break;
        case "frequency_amount":
          targetElement = this.capsInput;
          element = this.$(".frequency-error");
          break;
        case "frequency_type":
          targetElement = this.capsDdl;
          element = this.$(".frequency-error");
          break;
        case "frequency_interval":
          targetElement = this.capsIntervalDdl;
          element = this.$(".frequency-error");
          break;
        case "pacing_amount":
        case "pacing_type":
        case "pacing_interval":
          targetElement = this.$(".pacing-monetary-ip");
          element = this.$(".pacing-error");
          break;
        case "impression_pacing_amount":
        case "impression_pacing_type":
        case "impression_pacing_interval":
          targetElement = this.$(".pacing-impression-ip");
          element = this.$(".impression-pacing-error");
          break;
        case "pacing_optimization_amount":
          targetElement = this.$(".target-allocation-ip");
          element = this.$(".target-allocation-error");
          break;
        case "pacing_optimization_type":
          targetElement = this.$(".target-allocation-ddl");
          element = this.$(".target-allocation-error");
          break;
      }
      if (targetElement) {
        targetElement[0].error = true;
      }
      if (element) {
        if (!emptyFields) {
          if (Polymer && Polymer.dom) {
            Polymer.dom(element[0]).innerHTML = message;
          } else {
            element.html(message);
          }
          element.removeClass("no-display");
        }
      }
    },
    validateFields(saveObj) {
      const errors = [];
      _.each(saveObj, function (value, key) {
        switch (key) {
          case "bid_aggressiveness":
          case "goal_value":
          case "roi_target":
          case "max_bid":
          case "min_bid":
          case "frequency_amount":
          case "pacing_amount":
          case "impression_pacing_amount":
          case "frequency_type":
          case "frequency_interval":
            if (value === null || value === "") {
              errors.push({
                field: key
              });
            }
            break;
        }
      });
      return errors;
    },
    saveStrategy() {
      const saveObj = {};
      const twoDigits = 2;
      const strategyData = this.strategyData;
      const goalValue = this.$(".strategy-goal-input")[0].value;
      const activeStatus = this.$(".strategy-active").attr("checked");
      const t1Optimized = this.$(".t1-optimized").attr("checked");
      const targetAllocation = this.$(".target-allocation").attr("checked");
      function setCustomAmountValues() {
        if (saveObj.pacing_type !== "no-limit") {
          saveObj.pacing_amount = this.unMaskCurrency(this.$(".pacing-monetary-ip")[0].value);
          saveObj.pacing_interval = this.$(".pacing-monetary-interval")[0].value;
        }
        if (saveObj.impression_pacing_type !== "no-limit") {
          saveObj.impression_pacing_amount = this.unMaskNumber(this.$(".pacing-impression-ip")[0].value);
          saveObj.impression_pacing_interval = this.$(".pacing-impression-interval")[0].value;
        }
      }
      saveObj.budget = this.unMaskCurrency(this.$(".budget")[0].value);
      saveObj.max_bid = this.unMaskCurrency(this.$(".max-bid")[0].value);
      saveObj.min_bid = this.unMaskCurrency(this.$(".min-bid")[0].value);
      saveObj.bid_aggressiveness = 50;
      if (strategyData.goal_type === "reach") {
        saveObj.bid_aggressiveness = this.unMaskNumber(this.$(".bid-aggressiveness")[0].value);
      }
      if (strategyData.goal_type === "roi") {
        saveObj.roi_target = this.unMaskNumber(goalValue);
      } else {
        saveObj.goal_value = this.unMaskCurrency(goalValue);
      }
      if (strategyData.goal_type === "spend") {
        saveObj.max_bid = this.unMaskCurrency(this.$(".bid-price")[0].value);
      }
      saveObj.pacing_type = this.$(".pacing-monetary-ddl")[0].value;
      saveObj.impression_pacing_type = this.$(".pacing-impression-ddl")[0].value;
      saveObj.use_optimization = this.strategyData.use_optimization;
      if (t1Optimized) {
        saveObj.frequency_type = "standard";
        saveObj.frequency_amount = 0;
        saveObj.frequency_interval = "not-applicable";
        saveObj.frequency_optimization = "1";
      } else {
        saveObj.frequency_type = this.$(".caps-frequency-ddl")[0].value;
        if (saveObj.frequency_type !== "no-limit") {
          saveObj.frequency_amount = this.unMaskCurrency(this.$(".caps-frequency-ip")[0].value);
          saveObj.frequency_interval = this.$(".caps-frequency-interval")[0].value;
        }
        saveObj.frequency_optimization = "0";
      }
      saveObj.status = activeStatus ? 1 : 0;
      let validationErrors = this.validateFields(saveObj);
      this.displayErrors(validationErrors, true);
      if (validationErrors.length) {
        return;
      }
      if (this.useAllocPacing) {
        if (targetAllocation) {
          saveObj.pacing_amount = "";
          saveObj.pacing_optimization_type = this.$(".target-allocation-ddl")[0].value;
          saveObj.pacing_optimization_type = saveObj.pacing_optimization_type && saveObj.pacing_optimization_type !== "" ? saveObj.pacing_optimization_type : "monetary";
          saveObj.pacing_optimization_amount = this.unMaskNumber(this.$(".target-allocation-ip")[0].value);
          saveObj.pacing_optimization_amount = saveObj.pacing_optimization_amount ? saveObj.pacing_optimization_amount.toFixed(twoDigits) : saveObj.pacing_optimization_amount;
          this.$(".target-allocation-ip")[0].value = saveObj.pacing_optimization_amount;
        } else {
          setCustomAmountValues.call(this);
          saveObj.pacing_optimization_type = "";
          saveObj.pacing_optimization_amount = "";
        }
      } else {
        setCustomAmountValues.call(this);
      }
      validationErrors = this.strategyModel.validateAttrs(saveObj) || [];
      this.displayErrors(validationErrors);
      if (validationErrors.length) {
        return;
      }
      this.strategyModel.save(saveObj, {
        success: () => {
          T1.Notify("message", "Strategy has been updated.");
          this.publishClosePopupEvent();
        },
        statusInvalid: errors => {
          this.displayErrors(errors);
        }
      });
    },
    stopBubbling(event) {
      event.stopPropagation();
    },
    serialize() {
      if (!this.strategyData) {
        return {};
      }
      let strategyGoalValue;
      let bidAggressiveness = 0;
      let isReachStrategy = false;
      let isSpendStrategy = false;
      let strategyGoalCurrency = "";
      let strategyGoalOptions = "";
      const {
        campaignData: campaignData,
        strategyData: strategyData
      } = this;
      const budgetAmount = strategyData.budget_amount;
      const budgetCurrency = budgetAmount ? budgetAmount.currency_code : "";
      const budgetValue = budgetAmount ? budgetAmount.value : "";
      const maxBidCurrency = strategyData.max_bid_amount.currency_code;
      const campaignCurrency = campaignData.currency_code;
      const campaignSpendYesterday = this.getCampaignSpendYesterday();
      const strategySpendYesterday = this.getStrategySpendYesterday();
      const remainingBudget = this.getRemainingCampaignBudget();
      const dailySpendToPace = formatCurrencyNoSymbol(calculateDailySpendToPace(this.campaignModel), null, campaignCurrency);
      const strategyGoalType = goalTypeMap[strategyData.goal_type];
      const minBidCurrency = strategyData.min_bid_amount.currency_code;
      const pacingOptimizationType = strategyData.pacing_optimization_type;
      const pacingOptimizationAmount = strategyData.pacing_optimization_amount;
      if (strategyData.goal_type !== "roi") {
        strategyGoalValue = formatCurrencyNoSymbol(strategyData.goal_value, null, strategyGoalCurrency);
        strategyGoalCurrency = strategyData.goal_value_amount ? strategyData.goal_value_amount.currency_code : campaignCurrency;
      } else {
        strategyGoalValue = strategyData.roi_target_amount.value;
      }
      switch (strategyData.goal_type) {
        case "viewability_rate":
        case "vcr":
        case "ctr":
          strategyGoalOptions = "%";
          strategyGoalCurrency = "";
          strategyGoalValue = strategyData.goal_value;
          break;
        case "roi":
          strategyGoalOptions = ":1";
          strategyGoalCurrency = "";
          break;
        case "spend":
          isSpendStrategy = true;
          break;
        case "reach":
          isReachStrategy = true;
          bidAggressiveness = this.unMaskNumber(strategyData.bid_aggressiveness);
          break;
      }
      return {
        bidAggressiveness: bidAggressiveness,
        budgetGoalCurrency: currencySymbol(budgetCurrency),
        budgetValue: formatCurrencyNoSymbol(budgetValue, null, budgetCurrency),
        campaignCurrency: currencySymbol(campaignCurrency),
        campaignGoalType: goalTypeMap[campaignData.goal_type],
        campaignName: campaignData.name,
        campaignSepndPace: dailySpendToPace && dailySpendToPace !== "--" ? String(currencySymbol(campaignCurrency)) + dailySpendToPace : "--",
        campaignSpendYesterday: campaignSpendYesterday ? String(currencySymbol(this.getSelectedCurrencyCode(campaignData))) + campaignSpendYesterday : "--",
        frequencyAmount: strategyData.frequency_amount,
        frequencyIntervalOptions: dropdownList.frequencyInterval.options,
        frequencyNoCap: strategyData.frequency_type === "no-limit",
        frequencyTypeOptions: dropdownList.frequencyType.options,
        impressionNoCap: strategyData.impression_pacing_type === "no-limit",
        impressionPacingAmount: strategyData.impression_pacing_amount,
        impressionPacingIntervalOptions: dropdownList.impressionPacingInterval.options,
        impressionPacingOptions: dropdownList.impressionPacingType.options,
        isReachStrategy: isReachStrategy,
        isSpendStrategy: isSpendStrategy,
        maxBidCurrency: currencySymbol(maxBidCurrency),
        maxBidValue: formatCurrencyNoSymbol(strategyData.max_bid_amount.value, null, maxBidCurrency),
        minBidCurrency: currencySymbol(minBidCurrency),
        minBidValue: formatCurrencyNoSymbol(strategyData.min_bid_amount.value, null, maxBidCurrency),
        pacingAmount: formatCurrencyNoSymbol(strategyData.pacing_amount, null, campaignCurrency),
        pacingIntervalOptions: dropdownList.pacingInterval.options,
        pacingOptimizationAmount: pacingOptimizationAmount,
        pacingOptimizationType: pacingOptimizationType,
        pacingTypeOptions: dropdownList.pacingType.options,
        remainingBudget: remainingBudget ? String(currencySymbol(campaignCurrency)) + remainingBudget : "--",
        selectedFrequencyInterval: strategyData.frequency_interval,
        selectedFrequencyType: strategyData.frequency_type,
        selectedImpressionPacing: strategyData.impression_pacing_type,
        selectedImpressionPacingInterval: strategyData.impression_pacing_interval,
        selectedPacingInterval: strategyData.pacing_interval,
        selectedPacingType: strategyData.pacing_type,
        strategyGoalCurrency: strategyGoalCurrency === "" ? "" : currencySymbol(strategyGoalCurrency),
        strategyGoalOptions: strategyGoalOptions,
        strategyGoalType: strategyGoalType,
        strategyGoalValue: strategyGoalValue,
        strategyId: this.strategyQuickEditID,
        strategyName: strategyData.name,
        strategySpendYesterday: strategySpendYesterday ? String(currencySymbol(this.getSelectedCurrencyCode(strategyData))) + strategySpendYesterday : "--",
        targetAllocationOptions: dropdownList.targetAllocationOptions.options,
        canEditWithV2: this.canEditWithV2
      };
    }
  });
  return strategyQuickEdit;
});
