define('modules/auth/views/unauthorized',["jQuery", "T1", "T1View", "text!modules/auth/templates/unauthorized.html"], function ($, T1, T1View, template) {
  return T1View.extend({
    template: template,
    returnToHome: function (e) {
      e.preventDefault();
      T1.Location.set("monitor", {
        replace: true
      });
    },
    events: {
      "click .return-to-home .action": "returnToHome"
    }
  });
});
