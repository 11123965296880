define('T1Animate',["jQuery", "jQPlugins/jquery.transit"], function ($) {
  "use strict";

  return function (configs) {
    if (configs === undefined) {
      throw new Error("A config object needs to be passed in as argument.");
    }
    var cfgs = configs || {};
    var target = cfgs.target;
    var options = cfgs.options;
    var duration = cfgs.duration === undefined ? 400 : cfgs.duration;
    var easing = cfgs.easing || "ease";
    var complete = cfgs.complete || $.noop;
    if (target === undefined) {
      throw new Error("target needs to be specified in config object.");
    }
    if (options === undefined) {
      throw new Error("options needs to be specified in config object.");
    }
    target = target instanceof $ ? target : $(target);
    if (!$.support.transition) {
      $.fn.transition = $.fn.animate;
      easing = "linear";
    }
    if (duration > 0) {
      target.transition(options, duration, easing, complete);
    } else {
      target.css(options);
      complete.call(this);
    }
  };
});
