define('modules/audiences/segments/create/views/advertisers',["Underscore", "jQuery", "Backbone", "collections/advertisers", "collections/organizations", "jQPlugins/chosen.jquery", "jQueryUI"], function (_, $, Backbone, Advertisers, Organizations) {
  "use strict";

  function findCrossAdv(props) {
    return _.find(props, function (prop) {
      return prop.name === "allow_x_adv_pixels";
    }).value;
  }
  return Backbone.View.extend({
    el: $(".advertiser-select"),
    initialize: function (options) {
      var self = this;
      this.dropdownID = options.dropdownID || "#advertisers";
      this.parent = options.parent;
      this.typingTimer;
      this.allAdv = options.allAdv;
      this.dataSharing = options.dataSharing;
      Organizations.getOrganizations().getSelected().then(function (id) {
        self.orgId = id;
      });
      this.advertiserDrop = this.parent.el[0].querySelector(this.dropdownID);
    },
    render: function () {},
    loadAdvertisers: function (advertiser_id) {
      var self = this;
      var collection = new Advertisers(null, {
        pageLimit: 50
      });
      var params = [];
      collection.searchConfiguration.fields["agency.status"] = {
        type: "enum"
      };
      if (!self.allAdv) {
        collection.searchConfiguration.fields["agency.dmp_enabled"] = {
          type: "enum"
        };
        collection.searchConfiguration.fields.dmp_enabled = {
          type: "enum"
        };
      }
      if (self.dataSharing) {
        collection.searchConfiguration.fields["agency.eligible_for_data_sharing"] = {
          type: "enum"
        };
      }
      self.advertiserDrop.isLoading = true;
      self.advertiserDrop.disabled = true;
      collection.urlFilter.set({
        entity: "organization",
        id: self.orgId,
        fetchRequired: false
      });
      params = [{
        searchField: "status",
        term: "1"
      }, {
        searchField: "agency.status",
        term: "1"
      }];
      if (!self.allAdv) {
        Array.prototype.push.apply(params, [{
          term: "inherits",
          type: "enum",
          with: "agency",
          searchField: "dmp_enabled"
        }, {
          searchField: "agency.dmp_enabled",
          term: "inherits"
        }]);
      }
      if (self.dataSharing) {
        params.push({
          term: "1",
          searchField: "agency.eligible_for_data_sharing"
        });
      }
      collection.search.set({
        params: params,
        fetchRequired: false
      });
      collection.fetchOptions = $.extend({
        full: "agency",
        with: ["agency"]
      }, collection.fetchOptions);
      collection.fetch({
        success: function (collection, response) {
          var entities = response.entities;
          var options = [];
          var $advertisers = $(self.dropdownID);
          if (entities.count === "0") {
            self.advertiserDrop.isLoading = false;
            self.advertiserDrop.disabled = false;
            return;
          }
          if (entities.count === "1") {
            var one_entity = entities.entity;
            entities.entity = [];
            entities.entity[0] = one_entity;
          }
          $.each(entities.entity, function (index, entity) {
            options.push({
              name: entity.name,
              value: entity.id,
              agency: entity.entity.name,
              agencyId: entity.entity.id,
              crossAdv: findCrossAdv(entity.entity.prop)
            });
          });
          self.advertiserDrop.set("data", options.slice());
          self.advertiserDrop.isLoading = false;
          self.advertiserDrop.disabled = false;
          if (advertiser_id) {
            $advertisers.val(advertiser_id);
          }
        }
      });
    },
    searchAdvertisers: function (term) {
      var collection = null;
      var self = this;
      clearTimeout(self.typingTimer);
      self.advertiserDrop.isLoading = true;
      self.advertiserDrop.disabled = true;
      self.parent.el.find(this.dropdownID + " #list").css({
        opacity: .5,
        "pointer-events": "none"
      });
      collection = new Advertisers(null, {
        pageLimit: 50,
        sort_by: ["name", "id"],
        order_by: "ascending",
        placeholder_text_single: "Select Advertiser",
        enable_split_word_search: false
      });
      collection.urlFilter.set({
        entity: "organization",
        id: self.orgId,
        fetchRequired: false
      });
      var params = [{
        term: term,
        searchField: "name|id"
      }, {
        searchField: "status",
        term: "1"
      }, {
        searchField: "agency.status",
        term: "1"
      }];
      if (!self.allAdv) {
        params.push({
          term: "inherits",
          searchField: "dmp_enabled"
        }, {
          searchField: "agency.dmp_enabled",
          term: "inherits"
        });
      }
      if (self.dataSharing) {
        params.push({
          term: "1",
          searchField: "agency.eligible_for_data_sharing"
        });
      }
      collection.search.set({
        params: params
      });
      collection.fetchOptions = $.extend({
        full: "agency",
        with: ["agency"]
      }, collection.fetchOptions);
      var fetchSearch = function () {
        collection.fetch({
          success: function (collection, response, options) {
            var results = [];
            options = [];
            if (response.entities.count === "1") {
              results[0] = response.entities.entity;
            } else {
              results = response.entities.entity;
            }
            if (results) {
              options = [];
              $.each(results, function (index, item) {
                options.push({
                  name: item.name,
                  value: item.id,
                  agency: item.entity.name,
                  agencyId: item.entity.id,
                  crossAdv: findCrossAdv(item.entity.prop)
                });
              });
            } else {
              options = [{
                name: "No results match " + term
              }];
            }
            self.advertiserDrop.set("data", options.slice());
            self.advertiserDrop.isLoading = false;
            self.advertiserDrop.disabled = false;
            self.parent.el.find(self.dropdownID + " #list").css({
              opacity: 1,
              "pointer-events": "initial"
            });
            self.advertiserDrop.open();
          }
        });
      };
      self.typingTimer = setTimeout(fetchSearch, 5e3);
    }
  });
});
