define('modules/T1App/edit/access/views/tabs',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!../templates/tabs.html", "T1TabLayoutV2", "../models/accessUsersModel"], function ($, _, T1, T1View, T1Permissions, template, T1TabLayout, Model) {
  "use strict";

  return T1View.extend({
    template: template,
    model: new Model(),
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    initLayout: function () {
      this.layout = new T1TabLayout({
        tabSetName: "AccessDetails",
        level: 2,
        tabs: [{
          label: "Users",
          module: "T1App/edit/access/users",
          routeName: "user",
          viewType: "users",
          options: {}
        }]
      });
    },
    load: function () {
      var self = this;
      self.initLayout();
      self.render().then(function () {
        self.layout.el = $(self.el).find(".apps-access-tabs");
        self.layout.load();
      });
    },
    saveSelectedTab: function (opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, function (tab) {
        return tab.label === selectedLabel;
      }), tabs);
      this.model.setMainTabSection(selectedIndex);
    },
    canUnload: function () {
      return this.layout.canUnload.apply(this.layout, arguments);
    }
  });
});
