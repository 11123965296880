define('modules/strategy/supply/pmpe/views/dealModal',["jQuery", "async", "Underscore", "T1", "T1View", "T1Timezones", "text!modules/strategy/supply/pmpe/templates/dealModal.html", "models/mediaDeal", "collections/publishers", "collections/supplySources", "jQPlugins/jquery.columizer.min", "T1Selectbox", "T1DatePicker", "T1Form"], function ($, async, _, T1, T1View, T1Timezones, template, Deal, Publishers, SupplySources) {
  "use strict";

  var dropdownList = {
    price_type: {
      options: [{
        value: "FLOOR",
        text: "Floor"
      }, {
        value: "FIXED",
        text: "Fixed"
      }]
    }
  };
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  var dealModalView = T1View.extend({
    mode: "create",
    template: template,
    status: 0,
    advertiserId: "",
    displayDateFormat: "MMMM d, yyyy",
    displayTimeFormat: "h:mm tt",
    startTime: "12:00 AM",
    endTime: "11:59 PM",
    startBuffer: 1,
    endBuffer: 30,
    startBufferMin: {
      minutes: 10
    },
    hasStarted: false,
    isImmediateChecked: true,
    isNeverChecked: true,
    isImmediateAndNeverChecked: true,
    isDDLRendered: false,
    isNewIdDisabled: false,
    events: {
      "click .w-foot .ui-cancel": "closeDialog",
      "click .w-foot .ui-submit": "createDeal",
      "click .status": "onStatusClick",
      "click .immediate-date": "onImmediateCheckboxClick",
      "click .never-date": "onNeverCheckboxClick",
      "click .create-deal-id": "onDealIDClick",
      "keypress .currency": "keyPressClicked"
    },
    settings: {
      success: $.noop,
      closeDialogOnComplete: true,
      dispatchNotification: true
    },
    loaderOpts: {
      target: ".w-body",
      text: "saving deal...",
      contentClass: "search-loader"
    },
    initialize: function ({
      mode: mode,
      strategy: strategy,
      model: model,
      advertiser_id: advertiser_id
    }) {
      const deal = new Deal();
      this.strategy = strategy;
      this.model = mode !== "create" ? model : deal;
      this.mode = mode;
      this.advertiserId = this.model.get("advertiser_id") || advertiser_id;
      this.isCreate = mode === "create";
      this.currencyCode = strategy.get("currency_code");
      if (this.isCreate) {
        this.isNewIdDisabled = true;
      }
    },
    fetchDeal: function () {
      this.model.fetch({
        success: data => {
          this.dealObject = data;
        }
      });
    },
    keyPressClicked: function (e) {
      var keyCode = e.keyCode || e.charCode;
      const backspaceKey = 8;
      const deleteKey = 46;
      const nineKey = 57;
      return keyCode === backspaceKey || keyCode >= deleteKey && keyCode <= nineKey;
    },
    onImmediateCheckboxClick: function (event) {
      var model = this.model;
      this.isImmediateChecked = $(event.currentTarget).is(":checked");
      model.startDate = this.isImmediateChecked ? new Date().addHours(1) : model.startDate;
      this.renderPartialTemplate({
        templateTarget: ".date-display",
        targetEl: ".date-display",
        context: this.el
      });
    },
    onNeverCheckboxClick: function (event) {
      var model = this.model;
      this.isNeverChecked = $(event.currentTarget).is(":checked");
      model.endDate = this.isNeverChecked ? new Date().addDays(this.endBuffer) : model.endDate;
      this.renderPartialTemplate({
        templateTarget: ".date-display",
        targetEl: ".date-display",
        context: this.el
      });
    },
    onStatusClick: function (event) {
      this.status = parseInt($(event.currentTarget).attr("value"), 10);
    },
    onDealIDClick: function () {
      const dealIdHolder = this.$(".create-deal-id");
      const newIDInput = this.$(".deal-id");
      var useExistingCheck = dealIdHolder.find(".use_existing_id");
      var textInput = newIDInput.find(".text");
      if (useExistingCheck.is(":checked")) {
        textInput.removeAttr("disabled");
        this.isNewIdDisabled = false;
      } else {
        textInput.val("").attr("disabled", "disabled");
        this.isNewIdDisabled = true;
      }
    },
    onDatePickerSelect: function ($details, startOrEnd) {
      $(startOrEnd, $details).attr("checked", false);
    },
    onDatePickerClose: function ($details) {
      $(".immediate-date", $details).attr("checked", this.isImmediateChecked);
      $(".never-date", $details).attr("checked", this.isNeverChecked);
    },
    renderData: function () {
      var $dialog;
      const self = this;
      const $el = this.el;
      this.prepareDatePicker(true);
      this.render().then(function () {
        setTimeout(function () {
          self.activateSelectbox();
        }, 0);
        self.addEventHubEvent({
          "startDatePicker:select": function () {
            self.onDatePickerSelect($el, ".immediate-date");
          },
          "endDatePicker:select": function () {
            self.onDatePickerSelect($el, ".never-date");
          },
          onCloseInlineEdit: function () {
            self.onDatePickerClose($el);
          }
        });
        $dialog.position({
          my: "center",
          at: "center",
          of: window
        }).css("visibility", "visible");
      });
      $dialog = $el.dialog({
        dialogClass: "w-DealModal",
        closeText: "",
        autoOpen: true,
        modal: false,
        open: function () {
          $(this).closest(".ui-dialog").css("overflow", "visible");
        },
        close: function () {
          self.closeDialog();
        }
      }).css("visibility", "hidden");
    },
    prepareDatePicker: function (isNew) {
      var self = this;
      const {
        model: deal,
        el: $el
      } = this;
      var today = T1.today();
      const fullDay = 23;
      const fullHour = 59;
      const monthFromTomorrow = Date.parse("tomorrow").addDays(this.endBuffer).addHours(fullDay).addMinutes(fullHour);
      var never = Date.parse("2999-12-31");
      const startDate = this.isCreate ? today.clone().addHours(1) : Date.parse(deal.get("start_datetime"));
      const endDate = this.isCreate ? monthFromTomorrow : Date.parse(deal.get("end_datetime"));
      const hasStarted = today.clone() > Date.parse(startDate);
      const isImmediateChecked = isNew ? Boolean(this.isCreate) : this.isImmediateChecked;
      const isImmediateDisabled = isNew ? !this.isCreate : this.isImmediateDisabled;
      const isNeverChecked = isNew ? this.isCreate ? true : Date.compare(never, endDate) === 0 : this.isNeverChecked;
      const zoneName = this.isCreate ? "Etc/GMT" : deal.get("zone_name");
      var $dateDisplay = $el.find(".date-display");
      const topIndex = 9999;
      deal.startDate = startDate;
      deal.endDate = isNeverChecked ? monthFromTomorrow : endDate;
      deal.startTime = startDate.toString(this.displayTimeFormat);
      deal.endTime = endDate.toString(this.displayTimeFormat);
      deal.zoneName = zoneName;
      this.isImmediateChecked = isImmediateChecked;
      this.isNeverChecked = isNeverChecked;
      this.isImmediateDisabled = isImmediateDisabled;
      this.hasStarted = hasStarted;
      this.datePicker = T1.DoubleDatePicker({
        getStartDate: () => deal.startDate,
        getEndDate: () => deal.endDate,
        chooseTime: true,
        chooseTimezone: true,
        defaultStart: deal.startDate,
        defaultEnd: deal.endDate,
        useDefaultStart: isImmediateChecked,
        useDefaultEnd: isNeverChecked,
        closeOnScroll: false,
        timezoneEditable: !this.hasStarted,
        startTimeEditable: () => !this.hasStarted && !this.isImmediateChecked,
        endTimeEditable: () => !this.isNeverChecked,
        lowerBounds: this.hasStarted ? Date.parse(deal.startDate) : today,
        startDisabled: () => this.hasStarted || this.isImmediateChecked,
        endDisabled: () => this.isNeverChecked,
        useStartDefaultCheckInput: $(".immediate-date", $el),
        useEndDefaultCheckInput: $(".never-date", $el),
        zoneName: () => deal.zoneName,
        positionBy: "bottomTop",
        topOffset: 25,
        onOpen: function () {
          $(".cluetip-datepicker").css("z-index", topIndex);
        },
        onSave: function ({
          data: data,
          error: error,
          success: success
        }) {
          let saveValues = data.saveValues;
          var newStartDate = Date.parse(saveValues.start.date);
          self.timezones.validateStartDate(self.startBufferMin, newStartDate, saveValues.zoneName, {
            onSuccess: function () {
              saveValues = data.saveValues;
              newStartDate = Date.parse(saveValues.start.date);
              const newEndDate = Date.parse(saveValues.end.date);
              const newZoneName = saveValues.zoneName;
              if (Date.compare(newEndDate, newStartDate) !== 1) {
                error({
                  message: self.startEndOrderError
                });
                return;
              }
              deal.startDate = newStartDate;
              deal.endDate = newEndDate;
              deal.zoneName = newZoneName;
              deal.startTime = newStartDate.toString(self.displayTimeFormat);
              deal.endTime = newEndDate.toString(self.displayTimeFormat);
              self.isImmediateChecked = $(".immediate-date", $el).is(":checked");
              self.isNeverChecked = $(".never-date", $el).is(":checked");
              self.renderPartial(".date-display");
              self.removeTooltip({
                currentTarget: $dateDisplay
              });
              success();
            },
            onError: function () {
              error({
                message: self.startDateError
              });
            },
            hasStarted: self.hasStarted,
            isStartDateNotUTC: true
          });
        }
      }, this.el);
    },
    activateSelectbox: function () {
      const selectRTBProviders = this.$(".select-rtb-providers");
      const selectFloor = this.$(".select-price-type");
      const selectPublisher = this.$(".select-publisher-id");
      T1.Selectbox(selectRTBProviders, {
        onChange: function () {}
      });
      T1.Selectbox(selectFloor, {
        onChange: function () {}
      });
      T1.Selectbox(selectPublisher, {
        onChange: function () {}
      });
      selectRTBProviders.trigger("liszt:updated");
      selectFloor.trigger("liszt:updated");
      selectPublisher.trigger("liszt:updated");
    },
    load: function () {
      var self = this;
      var supplySources = SupplySources.extend({
        urlString: "deals/supply_sources"
      });
      var publishers = new Publishers();
      var rtbProviders = new supplySources([], {
        isPaginated: false,
        fetchOptions: {
          full: "*",
          q: `pmp_enabled==1&${this.strategy.get("media_type") === "VIDEO" ? "has_video==1" : "has_display==1"}`
        }
      });
      function comparator(model) {
        return model.get("name");
      }
      publishers.comparator = comparator;
      rtbProviders.comparator = comparator;
      this.fetchDeal();
      async.parallel({
        getPublishers: function (callback) {
          publishers.search.set({
            fetchRequired: true,
            params: [{
              searchField: "organization_id",
              term: "null"
            }],
            success: function (data) {
              callback(null, data);
            }
          });
        },
        getRTBProviders: function (callback) {
          rtbProviders.fetch({
            params: {
              full: "*"
            },
            success: function (data) {
              callback(null, data);
            }
          });
        }
      }, function (error, results) {
        self.publishers = results.getPublishers;
        self.rtbProviders = results.getRTBProviders;
        T1Timezones.done(function (mthds) {
          self.timezones = mthds;
          self.renderData();
        });
      });
      this.activateInputMasks();
    },
    closeDialog: function (e) {
      const el = this.el;
      if (e) {
        e.preventDefault();
        el.dialog("close");
      }
      el.dialog("destroy");
    },
    createDeal: function () {
      var deal = this.dealObject.data;
      const dealIsObject = deal instanceof Object;
      const dealIsArray = Array.isArray(deal);
      var advertiserId = parseInt(this.advertiserId, 10);
      const formValues = T1.Form.dataAsObject(this.$(".form-body").not($('[data-bind="ignore"]', this.el)));
      if (dealIsObject && !dealIsArray) {
        if (deal.owner.type === "ADVERTISER" && deal.owner.id === advertiserId) {
          this.mediaDealsPermission = deal.permissions.advertiser_ids[0] !== advertiserId ? deal.permissions.advertiser_ids[0] : advertiserId;
          this.postData(this.preparePostData(formValues));
        } else {
          T1.Notify("error", `User unauthorized to edit deal ${this.model.attributes.name}`);
        }
      } else {
        this.mediaDealsPermission = advertiserId;
        this.postData(this.preparePostData(formValues));
      }
    },
    preparePostData: function (formValues) {
      const deal = this.model;
      const status = formValues.status === "1";
      const startDate = `${deal.startDate.toString("s")}+00:00`;
      var never = "2999-12-31T00:00:00+00:00";
      const endDate = `${deal.endDate.toString("s")}+00:00`;
      var price = formValues.price !== undefined ? {
        value: formValues.price.toString(),
        currency_code: this.currencyCode
      } : undefined;
      const supplySourceId = formValues.supply_source_id ? parseInt(formValues.supply_source_id, 10) : undefined;
      const advertiserId = parseInt(this.advertiserId, 10);
      var settingsObj = {};
      if (this.isNewIdDisabled) {
        delete formValues.deal_identifier;
      }
      delete formValues.ignore;
      const preparedValues = {
        status: status,
        start_datetime: startDate,
        end_datetime: this.isNeverChecked ? never : endDate,
        deal_source: "INTERNAL",
        price: price,
        price_method: "CPM",
        supply_source_id: supplySourceId,
        owner: {
          type: "ADVERTISER",
          id: advertiserId
        },
        permissions: {
          all_organizations: false,
          organization_ids: [],
          agency_ids: [],
          advertiser_ids: [this.mediaDealsPermission]
        }
      };
      if (formValues.supply_source_id === "83") {
        preparedValues.sub_supply_source_id = parseInt(formValues.publisher_id, 10);
      }
      $.extend(formValues, preparedValues, settingsObj);
      return formValues;
    },
    postData: function (formValues) {
      var self = this;
      const deal = this.model;
      const msg = this.isCreate ? "created" : "updated";
      this.toggleLoadingState(true);
      deal.createOldDeal(formValues, {
        skipPublisherIdValidation: formValues.supply_source_id !== "83",
        success: function ({
          entity: entity
        }) {
          var coll = self.collection;
          self.toggleLoadingState(false);
          if (self.settings !== undefined) {
            self.renderPartial(".form-footer .messages", {
              success: true
            });
            if (self.settings.dispatchNotification) {
              T1.Notify("message", `${deal.get("name")} was ${msg} successfully!`);
            }
            if (self.settings.closeDialogOnComplete) {
              self.closeDialog();
            }
            self.settings.success(entity);
            coll.trigger("change:deal", self.model);
          }
        },
        invalid: function (errors) {
          if (errors.length) {
            self.toggleLoadingState(false, true);
            self.showErrors(deal, errors, ".form-body");
          }
        },
        conflict: function () {
          self.closeDialog();
        }
      });
    },
    toggleLoadingState: function (isLoading, isError) {
      if (isLoading) {
        this.currentLoader = this.loader();
        this.currentLoader.start();
        this.$(".w-head, .form-body").css({
          opacity: .05
        }).disableSelection();
        this.$(".form-body :input").attr("disabled", true);
        this.$(".ui-submit").addClass("disabled");
      } else {
        if (this.currentLoader) {
          this.currentLoader.stop();
        }
        if (isError) {
          this.$(".w-head, .form-body").css({
            opacity: 1
          }).enableSelection();
          this.$(".form-body :input").attr("disabled", false);
          this.$(".ui-submit").removeClass("disabled");
        }
      }
    },
    buildDDLListFromCollection: function (collection, selectedId) {
      var arr = [];
      collection.each(function (model) {
        const obj = model.toJSON();
        if (selectedId && model.id === selectedId) {
          obj.selected = "selected";
        }
        arr.push(obj);
      });
      return arr;
    },
    serialize: function () {
      var model = this.model;
      var modelJson = model.toJSON();
      const timezone = this.timezones && this.timezones.getByZoneName(model.zoneName);
      var dealName = modelJson.name || "";
      var description = modelJson.description || "";
      var price = modelJson.price || "";
      var dealId = modelJson.deal_identifier || "";
      var publisherID = modelJson.publisher_id;
      var supplySourceID = modelJson.supply_source_id;
      const createdOn = modelJson.created_on ? Date.parse(modelJson.created_on).toString(this.displayDateFormat) : undefined;
      const updatedOn = modelJson.updated_on ? Date.parse(modelJson.updated_on).toString(this.displayDateFormat) : undefined;
      const priceType = this.isCreate ? "FLOOR" : modelJson.price_type;
      var obj = {
        dates: {
          start: model.startDate.toString(this.displayDateFormat),
          end: model.endDate.toString(this.displayDateFormat)
        },
        times: {
          start: model.startTime,
          end: model.endTime,
          zone: timezone && timezone.toJSON() || {}
        },
        rtbProviders: this.buildDDLListFromCollection(this.rtbProviders, supplySourceID),
        publishers: this.buildDDLListFromCollection(this.publishers, publisherID),
        priceTypeOptions: dropdownList.price_type.options,
        selectedSupplySourceName: this.isCreate ? "" : this.rtbProviders.get(supplySourceID).get("name"),
        hasStarted: this.hasStarted
      };
      var returnObj = $.extend(obj, modelJson);
      const status = modelJson.status === "1";
      returnObj.status = status ? "active" : "inactive";
      returnObj.isStatusActive = this.isCreate ? false : status;
      returnObj.name = dealName;
      returnObj.description = description;
      returnObj.price = price;
      returnObj.dealId = dealId;
      returnObj.dealState = this.isCreate ? "New Deal" : "Edit Deal";
      returnObj.createdModifiedInfoTxt = `Created ${createdOn}&nbsp;&nbsp;|&nbsp;&nbsp;Last Modified: ${updatedOn}`;
      returnObj.isCreate = this.isCreate;
      returnObj.isImmediateChecked = this.isImmediateChecked;
      returnObj.isImmediateDisabled = this.isImmediateDisabled;
      returnObj.isNeverChecked = this.isNeverChecked;
      returnObj.isImmediateAndNeverChecked = this.isImmediateChecked && this.isNeverChecked;
      returnObj.isNewIdDisabled = this.isNewIdDisabled;
      returnObj.price_type = priceType;
      returnObj.currency_symbol = getCurrencySymbol(this.currencyCode);
      return returnObj;
    }
  });
  dealModalView = T1.Form.ViewUtils.extend(dealModalView);
  return $.extend(dealModalView, {
    showDialog: function (originalConfigs) {
      let configs = {
        el: originalConfigs.el || $("<div></div>")
      };
      configs = $.extend({}, originalConfigs, configs);
      const dealModal = new dealModalView(configs);
      dealModal.load();
      return dealModal;
    }
  });
});
