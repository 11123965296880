define('modules/campaign/main',['require','jQuery','modules/nav/views/breadcrumb','models/campaign','utils/campaignListStateMaintainer','collections/campaignMargins','collections/campaignReport','collections/campaigns','utils/MathtagUtils','modules/campaign/models/multiSearch','collections/organizations','modules/organization/utils/Utils','T1','T1Module','models/userPreferences','T1Preferences','T1Signature'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const BreadCrumb = require("modules/nav/views/breadcrumb");
  const Campaign = require("models/campaign");
  const CampaignListStateMaintainer = require("utils/campaignListStateMaintainer");
  const CampaignMargins = require("collections/campaignMargins");
  const CampaignReport = require("collections/campaignReport");
  const Campaigns = require("collections/campaigns");
  const Mathtag = require("utils/MathtagUtils");
  const MultiSearch = require("modules/campaign/models/multiSearch");
  const Organizations = require("collections/organizations");
  const OrgUtils = require("modules/organization/utils/Utils");
  const T1 = require("T1");
  const T1Module = require("T1Module");
  const UserPreferences = require("models/userPreferences");
  const T1Preferences = require("T1Preferences");
  const T1Signature = require("T1Signature");
  var campaigns = new Campaigns();
  var multiSearch = new MultiSearch();
  var stateMaintainerForCampaignList = new CampaignListStateMaintainer(campaigns);
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "campaign",
      viewCfgs: {
        budgetFlights: {
          model: null
        },
        chart_date_picker: {
          model: null
        },
        chartChangeLog: {},
        controls: {
          collection: campaigns
        },
        copyStrategiesGrid: {
          model: null
        },
        createEdit: {
          model: null
        },
        details: {
          model: null
        },
        detailsReadOnly: {
          model: null
        },
        duplicateCampaign: {
          model: null
        },
        duplicateCampaignSettings: {
          model: null
        },
        duplicateStrategiesErrorGrid: {
          model: null
        },
        duplicateStrategySelection: {
          model: null
        },
        editMargin: {
          model: null
        },
        editMarginBrowse: {
          collection: null
        },
        filters: {
          collection: multiSearch
        },
        general: {
          model: null
        },
        item: {
          model: null
        },
        list: {
          collection: campaigns
        },
        mmChart: {},
        mmCharts: {},
        strategiesPanel: {
          model: null
        }
      },
      defaultView: "list",
      initFetchOptions() {
        campaigns.fetchOptions = $.extend({
          with: ["advertiser,agency", "rpt_goal_monitoring", "budget_flights"],
          full: "*"
        }, campaigns.fetchOptions);
      },
      pageLimit: 10,
      favoriteViewMode: "all",
      filterViewMode: "campaigns",
      initialize() {
        var self = this;
        var signature = T1.Signature.create();
        var getIsFavoriteMode = () => this.favoriteViewMode === "favorites";
        signature.set(new Map([["root", "ui"], ["module", "campaign"], ["view", "list"]]));
        this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
        this.preferences.mark(["pageLimit", "favoriteViewMode", "filterViewMode"]);
        const orgPromise = Organizations.getOrganizations().getSelected();
        this.favoriteViewMode = this.preferences.get("favoriteViewMode") || "all";
        this.filterViewMode = this.preferences.get("filterViewMode") || "campaigns";
        this.entitySortOrder = this.preferences.get("entitySortOrder") || "descending";
        this.entitySortValue = this.preferences.get("entitySortValue") || "updated_on";
        T1.EventHub.subscribe({
          "update:filter": function ({
            data: data
          }) {
            const currentCampaign = data;
            stateMaintainerForCampaignList.saveFilterData({
              id: currentCampaign.id,
              type: currentCampaign.type,
              label: currentCampaign.name
            });
          },
          "campaigns:filter": function (data) {
            stateMaintainerForCampaignList.saveFilterData(data);
            self.favoriteViewMode = "all";
            self.preferences.save();
            Mathtag.generatePixel({
              s2: data.type === "advertiser" ? data.id : ""
            });
          },
          "campaigns:clearFilter": function () {
            stateMaintainerForCampaignList.clearAllStates();
          },
          "organization:select": function ({
            id: id
          }) {
            if (!id || id === "") {
              throw new Error("Organization id must be provided");
            }
            multiSearch.orgId = id;
            campaigns.clearFilters();
            campaigns.search.clear();
            campaigns.setSearchOptions();
            campaigns.setOrganization(id, true, getIsFavoriteMode());
            Mathtag.generatePixel({
              s1: id
            });
          },
          "campaign:changePageLimit": function ({
            pageLimit: pageLimit
          }) {
            self.pageLimit = pageLimit;
            campaigns.updatePageLimit(pageLimit);
            campaigns.fetch({
              success: function () {
                self.preferences.save();
              }
            });
          },
          "campaign.changelistview": function (data) {
            self.favoriteViewMode = data;
            self.preferences.save();
          },
          "campaign.updateSearchFilter": function (data) {
            self.filterViewMode = data;
            multiSearch.filterViewMode = self.filterViewMode;
            self.preferences.save();
          },
          "route.changeLocation": function (location) {
            function getCampaignIdFromLinkObjs(linkObjs) {
              if (Array.isArray(linkObjs)) {
                const idLocation = 2;
                for (let i = 0; i < linkObjs.length; i++) {
                  let locationStr = linkObjs[i].location || "";
                  locationStr = locationStr.toLowerCase();
                  if (locationStr.indexOf("#campaign/edit/") === 0) {
                    return locationStr.split("/")[idLocation];
                  }
                }
              }
              return undefined;
            }
            if (location.module === "monitor") {
              campaigns.unregister();
              campaigns.reset([], {
                silent: true
              });
              campaigns.clearFilters();
              campaigns.search.clear();
              campaigns.setSearchOptions();
              Mathtag.generatePixel();
              const campaignId = getCampaignIdFromLinkObjs(BreadCrumb.getCurrentLinks());
              stateMaintainerForCampaignList.restorePreviousState(getIsFavoriteMode(), campaignId, {
                data: campaigns.getExpiredFilterParam()
              });
            }
          }
        });
        const loadMonitor = this.monitor();
        loadMonitor.initialize();
        orgPromise.then(function (orgId) {
          self.orgId = orgId;
          self.initFetchOptions();
          Mathtag.generatePixel({
            s1: self.orgId
          });
          campaigns.pageLimit = self.pageLimit;
          campaigns.sortBy(self.entitySortValue, self.entitySortOrder);
          campaigns.search.clear();
          campaigns.setSearchOptions();
          campaigns.setOrganization(orgId, true, getIsFavoriteMode());
          multiSearch.filterViewMode = self.filterViewMode;
          multiSearch.orgId = orgId;
        });
      },
      monitor() {
        var isFocused, itemsLoaded, listQty, startTime, visibleRows;
        function canMonitor(tests) {
          return !tests ? false : Object.keys(tests).every(key => Boolean(tests[key]));
        }
        function doMonitoring() {
          setStartTime(0);
          manageSubscriptions(true);
        }
        function logTiming(qty) {
          var label;
          var timeTaken = window.performance.now() - startTime;
          const logDelay = 100;
          const tenRows = 10;
          const twentyRows = 20;
          if (qty < tenRows) {
            label = `campaign rows < 10 (${qty})`;
          } else if (qty === tenRows) {
            label = "campaign rows = 10";
          } else if (qty < twentyRows) {
            label = `campaign rows > 20 (${qty})`;
          } else {
            label = "campaign rows = 20";
          }
          T1.Analytics.logTiming("Page Load", "/monitor", parseInt(timeTaken, 10), label, logDelay);
        }
        function initialize(tests) {
          var monitoringTests = tests || {
            hasPageVisibilityAPI: "hidden" in document,
            hasFocus: !document.hidden,
            hasPerformanceAPI: "performance" in window,
            isCorrectPage: /monitor/.test(T1.Location.get())
          };
          if (canMonitor(monitoringTests)) {
            doMonitoring();
          }
        }
        function manageSubscriptions(attach) {
          var subMethod = attach === true ? "subscribe" : "unsubscribe";
          var listenerMethod = attach === true ? "addEventListener" : "removeEventListener";
          document[listenerMethod]("visibilitychange", visibilityChangeHandler);
          T1.EventHub[subMethod]("campaigns:listQtyKnown", onListQtyKnown);
          T1.EventHub[subMethod]("campaigns:itemLoaded", onItemLoaded);
          T1.EventHub[subMethod]("campaigns:stopMonitoring", stopMonitoring);
        }
        function onListQtyKnown(obj) {
          if (isFocused === true) {
            stopMonitoring();
          } else {
            setIsFocused(true);
            setItemsLoaded(0);
            setListQty(obj.count - obj.pageLimit < 0 ? obj.count : obj.pageLimit);
            setVisibleRows(obj.pageLimit);
          }
        }
        function onItemLoaded() {
          setItemsLoaded(itemsLoaded + 1);
          if (isFocused && itemsLoaded === listQty) {
            logTiming(listQty, visibleRows);
            stopMonitoring();
          }
        }
        function setIsFocused(bool) {
          isFocused = Boolean(bool);
          return isFocused;
        }
        function setItemsLoaded(num) {
          itemsLoaded = num;
          return itemsLoaded;
        }
        function setListQty(int) {
          listQty = int;
          return listQty;
        }
        function setStartTime(float) {
          startTime = float;
          return startTime;
        }
        function setVisibleRows(int) {
          visibleRows = int;
          return visibleRows;
        }
        function stopMonitoring() {
          setIsFocused(false);
          manageSubscriptions(false);
        }
        function visibilityChangeHandler() {
          T1.EventHub.publish("campaigns:stopMonitoring");
        }
        return {
          canMonitor: canMonitor,
          doMonitoring: doMonitoring,
          onItemLoaded: onItemLoaded,
          onListQtyKnown: onListQtyKnown,
          initialize: initialize,
          logTiming: logTiming,
          manageSubscriptions: manageSubscriptions,
          setIsFocused: setIsFocused,
          setItemsLoaded: setItemsLoaded,
          setListQty: setListQty,
          setStartTime: setStartTime,
          setVisibleRows: setVisibleRows,
          stopMonitoring: stopMonitoring,
          visibilityChangeHandler: visibilityChangeHandler
        };
      },
      getView(...rest) {
        var originalArgs = rest;
        const [type, opts] = rest;
        var self = this;
        var viewCfgs = this.viewCfgs;
        var onViewTypeLoad = {
          editMargin: function () {
            var campaign = new Campaign({
              id: opts.id
            });
            var campaignMargins = new CampaignMargins([]);
            var deferredView = $.Deferred();
            var args = originalArgs;
            campaign.campaignMargins = campaignMargins;
            campaign.fetch({
              params: {
                with: ["advertiser", "rpt_goal_monitoring"],
                full: "*"
              },
              success: function () {
                viewCfgs.editMargin.model = campaign;
                onViewTypeLoad.defaultViewLoad(args).then(function (view) {
                  deferredView.resolve(view);
                });
              }
            });
            return deferredView.promise();
          },
          editMarginBrowse: function () {
            var deferredView = $.Deferred();
            var args = originalArgs;
            var campaign = args[1].campaign;
            var campaignMargins = campaign.campaignMargins;
            var requestMetrics = ["clicks", "impressions", "margin", "revenue", "total_ad_cost", "total_ad_cost_cpa", "total_ad_cost_cpc", "total_ad_cost_cpm", "total_conversions", "total_spend"];
            var reportUrl = "std/performance";
            if (campaign.get("goal_type") === "viewability_rate" || campaign.get("goal_type") === "vcr" || campaign.get("goal_type") === "vcpm") {
              reportUrl = "std/performance";
              requestMetrics.push("in_view", "measurable", "total_spend_vcpm", "video_complete", "video_start", "viewability_rate");
              const hasVcrImpression = OrgUtils.getOrgVcrSetting();
              if (hasVcrImpression) {
                requestMetrics.push("video_complete_rate_impression_based");
              } else {
                requestMetrics.push("video_complete_rate");
              }
            }
            const campaignReports = new CampaignReport({
              urlString: reportUrl,
              fetchOptions: {
                dimensions: "campaign_id",
                filter: `campaign_id=${campaign.id}`,
                order: "date",
                precision: 2,
                start_date: Date.parse("t - 5 d").toString("yyyy-MM-dd"),
                end_date: Date.parse("yesterday").toString("yyyy-MM-dd"),
                time_rollup: "by_day",
                metrics: requestMetrics.join(",")
              },
              url: function () {
                return `${T1.RPT_API_ROOT + this.urlString}?${$.param(this.fetchOptions)}`;
              }
            });
            const strategyReports = new CampaignReport({
              urlString: reportUrl,
              fetchOptions: {
                dimensions: "campaign_id,strategy_id,strategy_name",
                filter: `campaign_id=${campaign.id}`,
                order: "date",
                precision: 2,
                start_date: Date.parse("t - 5 d").toString("yyyy-MM-dd"),
                end_date: Date.parse("yesterday").toString("yyyy-MM-dd"),
                time_rollup: "by_day",
                metrics: requestMetrics.join(",")
              },
              url: function () {
                return `${T1.RPT_API_ROOT + this.urlString}?${$.param(this.fetchOptions)}`;
              }
            });
            campaignMargins.id = campaign.id;
            campaignMargins.campaign = campaign;
            campaignMargins.changedMargins = {};
            campaignMargins.fetch({
              params: {
                start: Date.parse("t - 5 d").toString("yyyy-MM-dd"),
                end: Date.parse("yesterday").toString("yyyy-MM-dd"),
                full: "*"
              },
              success: function () {
                campaignReports.fetch({
                  onSuccess: function () {
                    const campaignReportsObj = campaignReports.toJSON();
                    strategyReports.fetch({
                      onSuccess: function () {
                        const strategyReportsObj = strategyReports.toJSON();
                        campaignMargins.syncPicardDataWithCollection(strategyReportsObj, campaignReportsObj);
                        viewCfgs.editMarginBrowse.collection = campaignMargins;
                        onViewTypeLoad.defaultViewLoad(args).then(function (view) {
                          view.collection = campaignMargins;
                          deferredView.resolve(view);
                        });
                      }
                    });
                  }
                });
              }
            });
            return deferredView.promise();
          },
          controls: function () {
            var deferredView = $.Deferred();
            var args = originalArgs;
            viewCfgs.controls.currentList = self.favoriteViewMode;
            onViewTypeLoad.defaultViewLoad(args).then(function (view) {
              deferredView.resolve(view);
            });
            return deferredView.promise();
          },
          defaultViewLoad: function (args = originalArgs) {
            if (type === "list") {
              args[1].stateMaintainer = stateMaintainerForCampaignList;
              args[1].currentList = self.favoriteViewMode;
            }
            return T1Module.prototype.getView.apply(self, args);
          }
        };
        this.ignoreResizeWidth = true;
        const currentOnViewTypeLoad = onViewTypeLoad[type] || onViewTypeLoad.defaultViewLoad;
        return currentOnViewTypeLoad();
      }
    });
  }
  return instance;
});
