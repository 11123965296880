define('modules/creatives/display/views/grid',["jQuery", "Underscore", "T1", "T1View", "T1GridViewV2", "T1Form", "T1Permissions", "T1Menu", "../control/headers", "../control/formatters", "text!../templates/grid_item.html", "text!templates/adx_error_display.html", "../../../changelog/views/dialog", "utils/CreativeUtils", "modules/creatives/display/views/previewT1AS", "collections/creativeNetStatuses", "collections/concepts", "models/concept", "models/classifications", "When", "T1Layout", "T1DatePicker", "T1Tooltip", "T1Notifier"], function ($, _, T1, T1View, createGrid, T1Form, T1Permissions, T1Menu, headers, format, grid_item, adxErrorPartial, ChangeLog, CreativeUtils, PreviewT1ASDialog, CreativeNetStatuses, Concepts, ConceptsModel, Classifications, When, T1Layout) {
  "use strict";

  var DisplayGridView = T1View.extend({
    headers: headers,
    headersTooltips: true,
    itemViewed: {},
    componentCreationDate: "2016-11-01T00:00:00",
    componentCreativeCount: 0,
    partials: {
      listItem: grid_item,
      adxErrorDisplay: adxErrorPartial
    },
    truncateConfig: {
      ".truncate": {
        lines: 1
      }
    },
    scrollConfigs: {
      triggerPoint: 100,
      target: ".content-holder",
      loaderOpts: {
        target: ".w-DataGrid",
        text: "Fetching Creatives",
        contentClass: "search-loader loader-overrides creative-infinite",
        action: "append"
      }
    },
    loaderOpts: {
      contentClass: "inline-edit-loader",
      target: ".w-DataGrid .w-body.content",
      spinnerConfig: {
        lines: 10,
        length: 4,
        radius: 3,
        left: 15,
        top: 4
      }
    },
    disableUISorter: false,
    menuConfig: {
      orientation: "window",
      menuColumns: [{
        columnTitle: null,
        hideIcons: true,
        menuItems: [{
          label: "Deactivate",
          handler: "deactivateHandler",
          visible: false
        }, {
          label: "Activate",
          handler: "activateHandler",
          visible: false
        }, {
          label: "Edit",
          visible: true
        }, {
          label: "Preview",
          handler: "previewCreative",
          visible: true
        }, {
          label: "Flag For Violation",
          handler: "showFlagViolationDialog",
          visible: false
        }, {
          label: "Change Log",
          handler: "viewChangeLog",
          visible: true
        }]
      }]
    },
    events: {
      "click .item .icon-arrow, .item-toggle": "clickToggleNode",
      "click .config": "showOrApplySettingsMenu",
      "click .item .checkbox": "handleItemChecked",
      "click .header .checkbox": "handleAllChecked",
      "click .main-file .icon, .backup-file .icon": "showPreview",
      "click .icon-group .view, .icon-group .alert": "showPreview",
      "click .edit": "openEditPage",
      "click strand-popover .close": "closeNetStatusPopover",
      "click .dates": "openDatePickerHandler",
      "keyup .dimensions .form-input": "onInlineKeyPress",
      "contextmenu .edit-icon": "showEditIcon",
      "mousemove .edit-icon": "hideEditIcon",
      saved: "savedDatepickerHandler"
    },
    initialize: function (args) {
      var self = this;
      var gridHeaders = {
        name: {},
        id: {},
        advertiser: {
          fieldName: "advertiser.name"
        },
        concept: {
          fieldName: "concept.name"
        },
        "third-pas-id": {
          fieldName: "external_identifier"
        },
        "file-type": {
          fieldName: "file_type"
        },
        dimensions: {
          fieldName: "width"
        },
        secure: {
          fieldName: "is_https",
          marginLeft: 18
        },
        "last-modified": {
          fieldName: "last_modified",
          current: true,
          order_by: "descending"
        },
        "start-date": {
          fieldName: "start_date"
        },
        "end-date": {
          fieldName: "end_date"
        }
      };
      this.staticVerticals = args.staticVerticals;
      this.searchTerm = args.searchTerm;
      this.violationFlaggingEnabled = T1Permissions.check("feature", "creatives_violation");
      this.sortableHeadersView = new T1Layout({
        el: function () {
          return $(self.el);
        },
        template: '<div class="sortable-content"></div>',
        selectorPath: ".w-foot",
        layout: {
          ".sortable-content": [{
            module: "shared",
            viewType: "gridSorter",
            options: {
              parentEl: self.el,
              collection: self.collection,
              sortableHeaders: gridHeaders,
              onSort: function () {
                self.disableUISorter = true;
                self.useSearchLoader();
              }
            }
          }]
        }
      });
    },
    dataEvents: {
      collection: {
        reset: "load",
        startSearch: "useSearchLoader",
        "change:ad_format": function (model, adFormat) {
          var $togglable = $("#expansion-row" + model.id);
          $togglable[adFormat === "EXPANDABLE" ? "removeClass" : "addClass"]("hide-row");
          this.toggleExpansionRow($togglable, adFormat);
          this.renderChange("ad_format", model, adFormat);
        },
        "change:concept_id": function (model, conceptId) {
          var self = this;
          var concept = new ConceptsModel({
            id: conceptId
          });
          concept.fetch({
            success: function () {
              self.renderChange("concept", model, concept.get("name"));
            }
          });
        },
        "change:selected": function (model) {
          this.updateItemCheckbox(model);
          this.updateAllCheckbox(model);
        },
        fetchMoreComplete: "updateAllCheckbox",
        "change:status": "updateStatus",
        "change:net_status": "updateNetStatus"
      }
    },
    eventhubEvents: {
      "organization:select": "useSearchLoader",
      "bulkedit:saved": "handleBulkEditSaved",
      "inlineSelectBoxDefaults:change": "handleInlineSelectChanged",
      onConfigClick: "useCloseInline",
      "hybridAdReview:approve": "hybridApprove",
      "hybridAdReview:reject": "hybridReject",
      onOpenInlineEdit: "onInlineEditOpen",
      "creativegrid:setHighlightValue": function (data) {
        this.highlightValue = data;
      }
    },
    closeNetStatusPopover: function (e) {
      var $popover = $(e.currentTarget).closest("strand-popover");
      e.preventDefault();
      $popover[0].toggle();
    },
    hybridApprove: function (id) {
      var displayState = "Approved for Use";
      var adServerType = "TERMINALONESZ";
      this.hybridState(id, displayState, adServerType);
    },
    hybridReject: function (id) {
      var displayState = "Rejected for Use";
      var adServerType = "TERMINALONE";
      this.hybridState(id, displayState, adServerType);
    },
    hybridState: function (id, displayState, adServerType) {
      var $el = $(this.el).find("#id" + id);
      var model = this.collection.get(id);
      model.set({
        displayState: displayState
      });
      $el.find(".reviewHybrid").hide();
      $el.find(".alert").hide();
      $el.find("#adState").html(displayState);
      $el.find("#adServerType").html(adServerType);
    },
    useCloseInline: function () {
      this.closeInlineEditor();
    },
    onInlineEditOpen: function (e) {
      var id, $el;
      if (e && e.target) {
        id = $(e.target).closest(".item-wrapper").data("id");
        $el = $(this.el).find("#id" + id);
        setTimeout(function () {
          var $input = $el.find(".dimensions .form-input");
          if ($input) {
            $input.attr("maxlength", "4");
          }
        }, 100);
      }
    },
    onInlineKeyPress: function (e) {
      var id = $(e.target).closest(".item-wrapper").data("id");
      var $el = $(this.el).find("#id" + id);
      var $input = $el.find(".dimensions .form-input");
      if ($input.val() === "0") {
        $input.val("");
      }
    },
    useSearchLoader: function () {
      this.itemViewed = {};
      this.setSearchLoader();
    },
    toggleExpansionRow: function ($node, adFormat) {
      var shouldHide = adFormat !== "EXPANDABLE";
      this.toggleNode(shouldHide, $node);
    },
    renderChange: function (attr, model, newVal) {
      var $edited = $("#" + attr + model.id);
      newVal = format(attr)(newVal);
      $edited.text(newVal || "--");
      $edited.attr("title", newVal || "--");
    },
    showPreview: function (e) {
      var self = this;
      var $target = $(e.target);
      var id = $target.data("id");
      var role = $target.data("role");
      var model = self.collection.get(id);
      var displayState = model.get("displayState");
      e.preventDefault();
      e.stopPropagation();
      PreviewT1ASDialog.showDialog({
        model: model,
        role: role,
        hybridAdState: displayState
      });
    },
    clickToggleNode: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $target = $(e.target);
      var $arrow = $currentTarget.hasClass("icon-arrow") ? $currentTarget : $currentTarget.find(".icon-arrow");
      var expanded = $arrow.hasClass("expanded");
      var busy = $arrow.hasClass("busy");
      var $itemWrapper = $arrow.closest(".item-wrapper");
      var itemID = $itemWrapper.data("id");
      var $child = $itemWrapper.children(".children");
      if (!$target.is("textarea")) {
        this.closeInlineEditor();
      }
      if (busy || $target.hasClass("settings-icon") || $target.hasClass("creative-settings") || $target.is("#iconGlyph") || $target.hasClass("strand-button") || $target.closest("strand-icon").hasClass("non-secure-warning")) {
        return;
      }
      if (itemID && !this.itemViewed[itemID]) {
        this.itemViewed[itemID] = true;
        this.showClassifications($itemWrapper, itemID);
      }
      this.preventDefault(e);
      $arrow.addClass("busy");
      $arrow.toggleClass("expanded");
      this.toggleNode(expanded, $child, $arrow);
      this.makeTooltips();
    },
    toggleNode: function (shouldHideChild, $child, $arrow) {
      $child[shouldHideChild ? "hide" : "show"]("blind", {}, 500).promise().done(function () {
        $arrow.removeClass("busy");
      });
    },
    showClassifications: function (itemWrapper, itemID) {
      var self = this;
      var $subChild = itemWrapper.find(".sub-child" + itemID);
      var $loader = $subChild.find("#appliedLoader");
      var $classAttr = $subChild.find(".classAttr");
      var $classCat = $subChild.find(".classCat");
      var $classLang = $subChild.find(".classLang");
      var classifications = new Classifications({
        id: itemID
      });
      var highest = null;
      var initialHeight = 0;
      var categories, currHeight, catParent, category;
      $loader.show();
      classifications.fetch({
        onSuccess: function (data) {
          $loader.hide();
          categories = _.sortBy(data.verticals, "parent");
          if (categories.length) {
            $.each(categories, function (i, item) {
              category = item.description;
              if (item.parent !== 0) {
                catParent = self.getVerticalsParent(String(item.parent), self.staticVerticals);
                catParent = catParent ? '<span class="catParent">' + catParent + " >></span> " : "";
                category = catParent + category;
              }
              $classCat.append("<dd>" + category + "</dd>");
            });
          } else {
            $classCat.append("<dd>--</dd>");
          }
          if (data.attributes.length) {
            $.each(data.attributes, function (i, item) {
              $classAttr.append("<dd>" + item.name + "</dd>");
            });
          } else {
            $classAttr.append("<dd>--</dd>");
          }
          if (data.language) {
            $classLang.append("<dd>" + data.language.name + "</dd>");
          } else {
            $classLang.append("<dd>--</dd>");
          }
          $subChild.find(".classification-block dl").each(function () {
            currHeight = $(this).height();
            if (currHeight > initialHeight) {
              initialHeight = currHeight;
              highest = $(this).height();
            }
          });
          $subChild.find(".classification-block dl").height(highest);
        },
        onError: function () {
          var emptyClassifications = "<dd>--</dd>";
          $classCat.append(emptyClassifications);
          $classAttr.append(emptyClassifications);
          $classLang.append(emptyClassifications);
          $loader.hide();
        }
      });
    },
    getVerticalsParent: function (ID, staticData) {
      var mainData = staticData ? staticData.get("data") : "";
      var returnName;
      $.each(mainData, function (i, item) {
        if (ID === item.id) {
          returnName = item.description;
        }
      });
      return returnName;
    },
    processExpandable: function (json, adFormat) {
      var dirs;
      json.expandable = adFormat === "EXPANDABLE";
      if (json.expandable) {
        json.expansion_trigger = format("expansion_trigger")(json.expansion_trigger);
        dirs = json.expansion_direction;
        json.expansion_direction = dirs === "NONRESTRICTED" ? "No" : "Yes: " + this.getDir(dirs);
      }
      return json;
    },
    getDir: function (dirs) {
      var dirMap = {
        L: "Left",
        R: "Right",
        U: "Up",
        D: "Down"
      };
      var spellDirection = function (dir) {
        var shouldReverse = function () {
          var hasLength = dir.length > 1;
          var wrongFirstDir = dir[0] !== "U" && dir[0] !== "D";
          return hasLength && wrongFirstDir;
        };
        var mapped, joined;
        if (shouldReverse()) {
          dir = dir.split("").reverse().join("");
        }
        mapped = dir.split("").map(function (letter) {
          return dirMap[letter];
        });
        joined = mapped.join(" and ");
        return joined;
      };
      var mapped = dirs.split(",").map(function (dir) {
        return spellDirection(dir);
      });
      var joined = mapped.join(", ");
      return joined;
    },
    ensureValues: function (tdata, attrs) {
      attrs.forEach(function ensureAttr(attr) {
        var i = attr.indexOf(".");
        var deepNestedAttr = i !== -1;
        var lv1, lv2;
        if (deepNestedAttr) {
          lv1 = attr.slice(0, i);
          lv2 = attr.slice(i + 1);
          tdata[lv1] = tdata[lv1] || {};
          tdata[lv1][lv2] = tdata[lv1][lv2] || "--";
        } else {
          tdata[attr] = tdata[attr] || "--";
        }
      });
      return tdata;
    },
    load: function () {
      var self = this;
      var criteria, match, searchTerm;
      this.componentCreativeCount = 0;
      if (this.collection && this.collection.search) {
        criteria = this.collection.search.getCriteria();
        match = criteria.q && criteria.q.match(/<(.*?)>|(jpg|swf|png|gif|tif|unknown|html5)$/);
        searchTerm = match && match.length ? match[1] || match[0] : "";
      }
      this.viewType = searchTerm && searchTerm !== "" ? "search" : "browse";
      this.render().then(function () {
        self.sortableHeadersView.load();
        if (self.collection && self.collection.loaded) {
          self.postRender();
        }
      });
      this.itemViewed = {};
      if (!this.collection.loaded) {
        this.setSearchLoader();
      }
    },
    scrollContentRendered: function () {
      this.postRender();
    },
    postRender: function () {
      var self = this;
      const datePickerEl = CreativeUtils.createDatePicker();
      this.makeTooltips();
      setTimeout(function () {
        self.checkNetStatus();
        self.applyTruncate($(self.el));
      }, 0);
      this.el.append(datePickerEl);
    },
    openDatePickerHandler: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.closeInlineEditor();
      setTimeout(CreativeUtils.openDatePickerHandler(e, this), 0);
    },
    savedDatepickerHandler: function (e) {
      const {
        start: start,
        end: end,
        id: id
      } = e.target;
      const value = id.split("-")[2];
      const model = this.collection.get(value);
      const modelID = model.get("id");
      const isDisplay = true;
      let loader;
      CreativeUtils.setDateSavingState(model, true);
      loader = T1.Loader.create(CreativeUtils.getLoaderSettings(value));
      loader.start();
      model.save(CreativeUtils.getDateSaveAttributes(start, end), CreativeUtils.getDateSaveOptions(this, loader, model));
      CreativeUtils.setDateInGrid(this, modelID, "end", end, isDisplay);
      CreativeUtils.setDateInGrid(this, modelID, "start", start, isDisplay);
    },
    checkNetStatus: function () {
      var self = this;
      var el = this.el;
      var creativeNetStatuses = new CreativeNetStatuses(null, {
        pageLimit: 50
      });
      var ids = this.collection.map(function (model) {
        return model.id;
      }).splice(-50);
      if (ids.length) {
        creativeNetStatuses.fetchOptions.atomic_creative_ids = "(" + ids + ")";
        creativeNetStatuses.fetch({
          success: function (data) {
            data.each(function (model) {
              var ID = model.get("atomic_creative_id");
              var netStatus = model.get("net_status").toLowerCase();
              var collectionModel = self.collection.get(ID);
              var $netStatusIcon, $spinner, $popOver, $adxOpen, $adxDeals, $appNexus, $microsoft, $yahoo, $brightroll, $mopub, hasADXerror, hasADXvendor;
              if (model.get("atomic_creative_id") === collectionModel.get("id")) {
                $netStatusIcon = el.find("#displayNetStatus" + ID);
                $spinner = el.find("#spinner-" + ID);
                $popOver = el.find("#netStatusPopover" + ID);
                $adxOpen = $popOver.find("#adxOpen");
                $adxDeals = $popOver.find("#adxDeals");
                $appNexus = $popOver.find("#appNexus");
                $microsoft = $popOver.find("#microsoft");
                $yahoo = $popOver.find("#yahoo");
                $brightroll = $popOver.find("#brightroll");
                $mopub = $popOver.find("#mopub");
                hasADXerror = collectionModel.get("hasADXError");
                hasADXvendor = hasADXerror ? collectionModel.get("adxErrorVendors").length > 0 : false;
                $spinner.hide();
                $netStatusIcon.show();
                collectionModel.set({
                  net_status: netStatus
                }, {
                  silent: true
                });
                collectionModel.set({
                  net_status_model: model.toJSON()
                }, {
                  silent: true
                });
                if (hasADXerror) {
                  netStatus = hasADXvendor ? "vendor unsupported" : "missing vendor";
                  self.setNetStatusIcon($adxOpen, netStatus, true);
                  self.setNetStatusIcon($adxDeals, netStatus, true);
                } else {
                  self.setNetStatusIcon($adxOpen, model.get("adx_open_auction").toLowerCase(), true);
                  self.setNetStatusIcon($adxDeals, model.get("adx_deals").toLowerCase(), true);
                }
                self.setNetStatusIcon($netStatusIcon, netStatus, false);
                self.setNetStatusIcon($appNexus, model.get("app_nexus").toLowerCase(), true);
                self.setNetStatusIcon($microsoft, model.get("microsoft_ad_exchange").toLowerCase(), true);
                self.setNetStatusIcon($yahoo, model.get("right_media_exchange").toLowerCase(), true);
                self.setNetStatusIcon($brightroll, model.get("brightroll_for_display").toLowerCase(), true);
                self.setNetStatusIcon($mopub, model.get("mopub_direct").toLowerCase(), true);
              }
            });
          }
        });
      }
    },
    setNetStatusIcon: function (iconTarget, netStatus, isPopover) {
      if (isPopover) {
        iconTarget.find(".status-text").text(netStatus);
        iconTarget = iconTarget.find("#" + iconTarget.attr("id") + "Icon");
      }
      if (netStatus === "approved") {
        iconTarget.attr("type", "success");
        iconTarget.removeClass("net-status-pending").addClass("net-status-approved");
      } else if (netStatus === "pending") {} else if (netStatus === "pending > 48 hours") {
        iconTarget.attr("type", "pending");
        iconTarget.attr("width", "20").attr("height", "20");
        if (isPopover) {
          iconTarget.removeClass("net-status-pending").addClass("net-status-pending48");
        }
      } else if (netStatus === "rejected") {
        iconTarget.removeClass("net-status-pending").addClass("net-status-rejected");
      } else if (netStatus === "n/a") {
        iconTarget.parent().html("&nbsp;--&nbsp;");
      } else if (netStatus === "missing vendor" || netStatus === "vendor unsupported") {
        iconTarget.removeClass("net-status-pending").addClass("net-status-rejected");
      }
    },
    handleBulkEditSaved: function (opts) {
      if (opts.requireRefresh) {
        this.setSearchLoader();
        this.collection.fetch({
          reset: true
        });
      }
    },
    handleInlineSelectChanged: function (e) {
      var target = $(e.currentTarget);
      var bind = target.data("bind");
      if (bind === "concept_id") {
        T1.Notify("warning", "This creative may be currently assigned to a strategy. By changing the concept, the " + "creative may stop bidding &amp; adversely affect the strategy's performance", false, false);
      }
    },
    showEditIcon: function (e) {
      var $target = $(e.currentTarget);
      $target.closest(".item-wrapper").addClass("hover");
    },
    hideEditIcon: function () {
      var self = this;
      var $allEdit = $(self.el).find(".item-wrapper");
      $allEdit.removeClass("hover");
    },
    openEditPage: function (e) {
      var $target = $(e.currentTarget);
      var id = Number($target.attr("id"));
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        window.open("#creatives/display/" + id, "_blank");
      } else {
        T1.Location.set("#creatives/display/" + id);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    showOrApplySettingsMenu: function (eventOrTarget) {
      var self = this;
      var id, model, isActive;
      var $currentTarget = eventOrTarget.currentTarget ? $(eventOrTarget.currentTarget) : eventOrTarget;
      var $menuTarget = $currentTarget ? $currentTarget.find(".settings-icon") : null;
      var menuConfig = $.extend(true, {}, self.menuConfig);
      var menuItems = menuConfig.menuColumns[0].menuItems;
      var menuIndexRef = {
        Deactivate: 0,
        Activate: 1,
        Edit: 2,
        FlagViolation: 4
      };
      if ($menuTarget) {
        id = $menuTarget.data("id");
        model = self.collection.get(id);
        isActive = model.get("status") === "1";
        menuItems[menuIndexRef.Activate].visible = !isActive;
        menuItems[menuIndexRef.Deactivate].visible = isActive;
        menuItems[menuIndexRef.Edit].url = "#creatives/display/" + id;
        menuItems[menuIndexRef.FlagViolation].visible = self.violationFlaggingEnabled;
        menuConfig.dataID = "data-id=" + id;
        menuConfig.menuTriggerEleSelector = 'div[id="id' + id + '"] .config';
      }
      T1Menu(menuConfig, self);
      if (eventOrTarget.currentTarget) {
        $currentTarget.find(".settings-icon").trigger("click");
      }
    },
    handleItemChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.useCloseInline();
      var self = this;
      var $target = $(e.target);
      var id = $target.data("id");
      var model = self.collection.get(id);
      var selected = model.get("selected") || false;
      model.set({
        selected: !selected
      });
    },
    handleAllChecked: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var self = this;
      var selected = self.collection.getSelected();
      if (self.collection.length === selected.length + self.componentCreativeCount) {
        self.collection.toggleAllSelected(false);
      } else {
        self.collection.toggleAllSelected(true);
      }
    },
    updateItemCheckbox: function (model) {
      var $checkbox = this.el.find("#id" + model.get("id") + " .checkbox");
      if (model.get("selected")) {
        $checkbox.addClass("checked");
      } else {
        $checkbox.removeClass("checked");
      }
    },
    updateAllCheckbox: function () {
      var self = this;
      var $allCheckBox = self.el.find(".header .checkbox");
      var selected = self.collection.getSelected();
      var outClass = "";
      if (self.collection.length === selected.length) {
        outClass = "checked";
      } else if (selected.length > 0) {
        outClass = "partial";
      }
      $allCheckBox.removeClass("checked partial").addClass(outClass);
    },
    deactivateHandler: function (e) {
      this.preventDefault(e);
      this.activationHandler(false, e);
    },
    activateHandler: function (e) {
      this.preventDefault(e);
      this.activationHandler(true, e);
    },
    activationHandler: function (toActivate, e) {
      var self = this;
      var $currentTarget = $(e.currentTarget);
      var model, targetDiv, loader;
      var id = $currentTarget.closest("dl").data("id");
      var status = toActivate === true ? "1" : "0";
      if (id) {
        model = self.collection.get(id);
        targetDiv = 'div[id="id' + id + '"]';
        loader = T1.Loader.create({
          contentClass: "inline-edit-loader",
          target: targetDiv,
          spinnerConfig: {
            lines: 10,
            length: 4,
            radius: 3,
            left: 15,
            top: 4
          }
        });
        loader.start();
        model.save({
          status: status
        }, {
          success: function () {
            T1.Notify("message", "Creative updated successfully!");
            loader.stop();
          },
          conflict: function () {
            loader.stop();
          }
        });
      }
    },
    updateStatus: function (model) {
      var id = model.get("id");
      var status = model.get("status");
      var targetDiv = 'div[id="id' + id + '"]';
      var $item = this.el.find(targetDiv + " .item");
      var $config = this.el.find(targetDiv + " .config");
      if (status === "0") {
        $item.addClass("inactive");
      } else {
        $item.removeClass("inactive");
      }
      this.showOrApplySettingsMenu($config);
    },
    updateNetStatus: function (model) {
      const el = this.el;
      const id = model.get("id");
      const netStatus = model.get("net_status");
      const $netStatusIcon = el.find("#displayNetStatus" + id);
      $netStatusIcon.removeClass("net-status-approved").removeClass("net-status-rejected").removeClass("net-status-pending48").addClass("net-status-pending");
      $netStatusIcon.attr("type", "warning");
      this.setNetStatusIcon($netStatusIcon, netStatus, false);
    },
    editCreative: function (e) {
      var self = this;
      var model;
      var id = $(e.currentTarget).closest("dl").data("id");
      e.preventDefault();
      e.stopPropagation();
      if (id) {
        model = self.collection.get(id);
        T1.Location.set("#creatives/display/" + model.get("id"), {
          replace: true
        });
      }
    },
    previewCreative: function (e) {
      var id = $(e.currentTarget).closest("dl").data("id");
      e.preventDefault();
      e.stopPropagation();
      if (id) {
        CreativeUtils.showPreview([id], "display");
      }
    },
    showFlagViolationDialog: function (e) {
      const id = $(e.currentTarget).closest("dl").data("id");
      e.preventDefault();
      e.stopPropagation();
      if (id) {
        let model = this.collection.get(id);
        CreativeUtils.showFlagViolationDialog(model.toJSON(), "display");
      }
    },
    viewChangeLog: function (e) {
      var self = this;
      var model;
      var id = $(e.currentTarget).closest("dl").data("id");
      e.preventDefault();
      e.stopPropagation();
      if (id) {
        model = self.collection.get(id);
        $(document).trigger("click");
        ChangeLog.showDialog({
          model: model,
          entityType: "creative"
        });
      }
    },
    makeTooltips: function () {
      if (this.collection.models.length) {
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".t1-tooltip").not(".adx-warning, .adx-warning-list, .adx-error, .adx-error-list")
        });
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".adx-warning, .adx-warning-list"),
          className: "adx-warning-tipsy"
        });
        T1.Tooltip("body", {
          fade: false,
          tooltipEle: $(this.el).find(".adx-error, .adx-error-list"),
          className: "adx-error-tipsy"
        });
      }
    },
    serialize: function (inputCollection) {
      var collection = inputCollection || this.collection;
      var models = collection.map($.proxy(this.mapModel, this));
      if (!this.disableUISorter) {
        models = _.sortBy(models, "updated_on").reverse();
      }
      return {
        list: models
      };
    },
    mapModel: function (model) {
      var self = this;
      var json = model.toJSON();
      var searchTerm = self.highlightValue || this.searchTerm || "";
      var searchableFields = ["name", "id", "advertiser.name", "concept_name", "external_identifier", "file_type", "width", "height"];
      var isIDSearch, dimensionMatch, length, vendors;
      var highlightText = T1.Utils.highlightText;
      var concept = model.get("concept");
      var sslValidator = json.atomic_creative_ssl_validator;
      var detectedUrls = json.atomic_creative_detected_tag_url;
      json.concept_name = concept !== null ? concept.get("name") : "";
      json.advertiser_title = json.advertiser.name;
      json.is_https = format("is_https")(json.is_https);
      json.file_type = format("file_type")(json.file_type);
      json.last_modified = format("last_modified")(json.last_modified);
      json.start_date = format("start_date")(json.start_date);
      json.end_date = format("end_date")(json.end_date);
      json.ad_format = format("ad_format")(json.ad_format);
      json.is_3pas = json.t1as === "0";
      json.isFBX = json.ad_format === "FBX";
      json.isEditable = json.ad_format === "FBX" || json.ad_format === "HTML5";
      json.showMRAID = !json.isEditable;
      json.is_mraid = format("is_mraid")(json.is_mraid);
      json.approval = "--";
      json.landingPageTooltipTxt = "Declare the page URL a user will arrive on after clicking the ad. This should be" + " the destination URL (e.g. http://www.advertiser.com), not a click tracking URL " + "(e.g. http://ad.3pserver.com/clicktracker).";
      json.isActive = json.status === "1";
      json.dataID = json.id;
      if (json.isFBX) {
        if (Date.parse(json.created_on) >= Date.parse(this.componentCreationDate)) {
          json.file_type = "Component";
          json.isComponent = "true";
          model.set({
            componentCreative: true
          });
          this.componentCreativeCount++;
        }
      }
      json = self.serializeToggleablePortion(model, json);
      if (sslValidator && sslValidator.ignored !== "YES") {
        json.ksizeWarning = sslValidator.ksize > 200;
        json.non_secure_calls = nonSecureCallData();
        sslValidator.is_https = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
      } else {
        json.sslSecurity = false;
      }
      function nonSecureCallData() {
        var non_secure_calls = {};
        var vendorId;
        json.sslSecurity = true;
        switch (sslValidator.is_https.toLowerCase()) {
          case "no":
            json.secureWarning = true;
            if (detectedUrls) {
              vendors = {};
              detectedUrls = detectedUrls.length ? detectedUrls : [detectedUrls];
              length = detectedUrls.length;
              for (var i = 0; i < length; i++) {
                if (detectedUrls[i].is_https === "YES") {
                  continue;
                }
                vendorId = detectedUrls[i].vendor_domain_id || "Unknown vendor";
                if (vendors.hasOwnProperty(vendorId)) {
                  vendors[vendorId].urls.push(detectedUrls[i].url);
                } else {
                  vendors[vendorId] = {
                    name: detectedUrls[i].vendor_domain ? detectedUrls[i].vendor_domain.vendor.name : "Unknown vendor",
                    urls: [detectedUrls[i].url]
                  };
                }
              }
              non_secure_calls.data = _.values(vendors);
            }
            break;
          case "inactive":
            non_secure_calls.message = "N/A - creative is inactive and paused from security scanning.";
            break;
          case "pending":
            non_secure_calls.message = "N/A - pending detection.";
            break;
          case "yes":
            json.verifiedSecure = true;
            non_secure_calls.message = "None found - creative is secure.";
            break;
          case "error":
          case "unknown":
            json.sslSecurity = false;
            non_secure_calls.message = "N/A - creative could not be scanned. Self declared security status is in use.";
            break;
        }
        return non_secure_calls;
      }
      function resolveField(parent, field, newFieldValue, newFieldName) {
        var fieldList;
        if (field.indexOf(".") !== -1) {
          fieldList = field.split(".");
          if (fieldList.length > 1) {
            return resolveField(parent[fieldList.shift()], fieldList.join(","), newFieldValue);
          }
        } else {
          if (newFieldValue !== undefined) {
            parent[newFieldName || field] = newFieldValue;
          }
          return parent[newFieldName || field];
        }
      }
      function isTermInField(term, fieldName) {
        var field = resolveField(json, fieldName).toLowerCase();
        return field.indexOf(term) !== -1;
      }
      function applySearchResults(term) {
        _.each(searchableFields, function (fieldName) {
          if (isTermInField(term, fieldName)) {
            resolveField(json, fieldName, highlightText(resolveField(json, fieldName), term));
          }
        });
      }
      _.each(searchableFields, function (fieldName) {
        resolveField(json, fieldName, resolveField(json, fieldName), fieldName + "_title");
      });
      if (self.viewType === "search") {
        searchTerm = searchTerm.toLowerCase();
        if (searchTerm) {
          searchTerm = decodeURIComponent(CreativeUtils.replaceSymbolsInName(searchTerm));
        }
        isIDSearch = searchTerm.charAt(0) === "#";
        dimensionMatch = searchTerm.match(/^(\d+?)[x](\d+)/);
        if (dimensionMatch && dimensionMatch.length) {
          _.each(dimensionMatch, function (dimension) {
            applySearchResults(dimension);
          });
        } else {
          applySearchResults(isIDSearch ? searchTerm.replace("#", "") : searchTerm);
        }
      }
      return json;
    },
    serializeToggleablePortion: function (model, json) {
      var crtve = model.get("creative");
      var crtve_asset = crtve ? crtve.get("creative_asset") : null;
      json.name = CreativeUtils.replaceSymbolsInName(json.name);
      json.concept_name = CreativeUtils.replaceSymbolsInName(json.concept_name);
      json.external_identifier = CreativeUtils.replaceSymbolsInName(json.external_identifier);
      json.auto_detected_vendors = CreativeUtils.parseVendorListForDisplay(json.autoVendors);
      json.manual_vendors = CreativeUtils.parseVendorListForDisplay(json.manualVendors);
      json.error_vendors = CreativeUtils.parseVendorListForDisplay(json.adxErrorVendors);
      json = this.processExpandable(json, json.ad_format);
      json.main_file = crtve_asset ? crtve_asset.getAssetName("PRIMARY") : "--";
      json.backup_file = crtve_asset ? crtve_asset.getAssetName("BACKUP") : "--";
      json.has_main_file = json.main_file !== "--";
      json.has_backup_file = json.backup_file !== "--";
      json.ad_server_type = format("ad_server_type")(json.ad_server_type);
      json = this.ensureValues(json, ["click_url", "ad_format", "expansion_trigger", "expansion_direction", "tag", "click_through_url", "main_file", "backup_file", "edited_tag", "tpas_ad_tag", "concept_name"]);
      json.hybridAdState = json.mm_hybrid_ad_state;
      if (json.hybridAdState === "accepted") {
        json.hybridAdState = "Approved for Use";
      } else if (json.hybridAdState === "rejected") {
        json.hybridAdState = "Rejected for Use";
      }
      model.set({
        displayState: json.hybridAdState
      });
      json.hybridAdName = "(" + json.mm_hybrid_child_ad_id + ") " + json.name;
      if (!json.hybridAdState || json.hybridAdState === "not_used" || json.hybridAdState === "needs_conversion") {
        json.isHybridActive = false;
      } else {
        json.isHybridActive = true;
        if (json.hybridAdState === "ready") {
          json.hybridAdReview = true;
        } else if (json.hybridAdState === "pending") {
          json.isHybridPending = true;
        }
      }
      return json;
    },
    canUnload: function () {
      return false;
    },
    postData: function () {},
    noop: function () {},
    unload: function () {
      $(".tipsy").remove();
      this.collection.reset([], {
        silent: true
      });
      for (var key in this.itemViewed) {
        if (this.itemViewed.hasOwnProperty(key)) {
          this.itemViewed[key] = null;
        }
      }
    }
  });
  (function addInlineEditRenderers(collEvents) {
    var changeAttrs = ["ad_server_type", "click_through_url", "click_url", "edited_tag", "expansion_trigger", "external_identifier", "file_type", "height", "is_https", "is_mraid", "name", "start_date", "end_date", "tag", "tpas_ad_tag", "width"];
    changeAttrs.forEach(function (attr) {
      collEvents["change:" + attr] = function (model, newVal) {
        this.renderChange(attr, model, newVal);
      };
    });
  })(DisplayGridView.prototype.dataEvents.collection);
  var dropdownOpts = {
    file_type: {
      options: [{
        value: "gif",
        text: "GIF"
      }, {
        value: "html5",
        text: "HTML5"
      }, {
        value: "swf",
        text: "SWF"
      }, {
        value: "jpg",
        text: "JPG"
      }, {
        value: "unknown",
        text: "unknown"
      }]
    },
    ad_server_type: {
      options: [{
        value: "ATLAS",
        text: "Atlas"
      }, {
        value: "DART",
        text: "DFA"
      }, {
        value: "EYEWONDER",
        text: "EyeWonder"
      }, {
        value: "MEDIAFORGE",
        text: "mediaFORGE"
      }, {
        value: "MEDIAPLEX",
        text: "Mediaplex"
      }, {
        value: "POINTROLL",
        text: "Pointroll"
      }, {
        value: "MEDIAMIND",
        text: "Sizmek MDX"
      }, {
        value: "YIELD_MANAGER",
        text: "Yield Manager"
      }, {
        value: "OTHER",
        text: "Other"
      }]
    },
    expansion_trigger: {
      options: [{
        value: "AUTOMATIC",
        text: "Automatic"
      }, {
        value: "MOUSEOVER",
        text: "Mouse-Over to Expand"
      }, {
        value: "CLICK",
        text: "Click to Expand"
      }]
    },
    ad_format: {
      options: [{
        value: "DISPLAY",
        text: "Display"
      }, {
        value: "MOBILE",
        text: "Mobile"
      }, {
        value: "EXPANDABLE",
        text: "Expandable"
      }]
    },
    is_mraid: {
      options: [{
        value: "1",
        text: "Yes"
      }, {
        value: "0",
        text: "No"
      }]
    },
    concept_id: function (model) {
      var concepts, loader;
      var deferred = When.defer();
      var advertiser = model.get("advertiser");
      var config = {
        contentClass: "inline-edit-loader",
        target: "#id" + model.get("id"),
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 4
        }
      };
      concepts = new Concepts();
      concepts.search.clear();
      concepts.setAdvertiser(advertiser.get("id"));
      concepts.pageLimit = 100;
      concepts.fetchOptions.sort_by = "name,id";
      loader = T1.Loader.create(config);
      loader.stop = function () {
        var el = $(".inline-edit-loader");
        el.fadeOut(200, function () {
          el.remove();
        });
      };
      loader.start();
      concepts.fetch({
        success: function () {
          var concept = model.get("concept");
          var options = concepts && concepts.map ? concepts.map(function (mappedConcept) {
            return {
              value: mappedConcept.get("id"),
              text: mappedConcept.get("name")
            };
          }) : [];
          if (concept !== null) {
            var conceptId = concept.get("id");
            var collModel = concepts.get(conceptId);
            if (collModel === undefined) {
              options.push({
                text: concept.get("name"),
                value: conceptId
              });
            }
          }
          loader.stop();
          return deferred.resolve({
            searchFieldDisabled: false,
            options: options,
            autoComplete: {
              autoComplete: true,
              searchField: "name",
              onSearchInputCleared: function (searchOptions) {
                concepts.search.clear();
                concepts.fetch({
                  success: function () {
                    searchOptions.success(concepts);
                  }
                });
              },
              onSearchRequest: function (searchOptions) {
                concepts.search.set(searchOptions);
                concepts.fetch({
                  success: function () {
                    searchOptions.success(concepts);
                  }
                });
              }
            }
          });
        }
      });
      return deferred.promise;
    }
  };
  var inlineEditable = T1Form.InlineEdit(DisplayGridView, dropdownOpts);
  inlineEditable.prototype.actionsConfig = {
    events: {
      "click .save": "noop"
    }
  };
  return createGrid(inlineEditable);
});
