define('collections/creativesVideo',["jQuery", "Underscore", "Backbone", "moment", "T1", "T1Comm", "T1Collection", "models/atomicCreative", "models/videoCreativeDetail", "models/vendor", "collections/organizations", "When", "utils/VideoUtils", "utils/providers/VendorProvider", "T1Sortable"], function ($, _, Backbone, moment, T1, T1Comm, T1Collection, AtomicCreative, Detail, Vendor, Organizations, When, VideoUtils, VendorProvider, Sortable) {
  var sortConfiguration = {
    fields: {
      last_payload_modified: {
        actual: "last_payload_modified"
      }
    },
    default: {
      sort_by: "last_payload_modified",
      order_by: "descending"
    }
  };
  var collection = T1Collection.extend({
    model: AtomicCreative,
    fixture: "-atomic_creatives",
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: false
      }, {
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: true
      }]
    },
    searchConfiguration: {
      isFetchSilent: true,
      fields: {
        media_type: {
          type: "enum"
        },
        name: {
          type: "string",
          useWildCard: true
        },
        id: {
          type: "enum"
        },
        advertiser_id: {
          type: "exact"
        },
        status: {
          type: "exact"
        },
        multiField: {
          type: "multiField"
        }
      }
    },
    fetchOptions: {
      full: "atomic_creative",
      with: ["advertiser", "concept"],
      sort_by: "-last_modified"
    },
    urlString: "atomic_creatives",
    updateOrgFilter: function () {
      Organizations.getOrganizations().getSelected().then($.proxy(function (orgId) {
        this.orgId = orgId;
        this.urlFilter.set({
          entity: "organization",
          id: orgId
        });
      }, this));
    },
    fetch: function (opts) {
      if (opts) {
        this.isAdding = opts.add;
        this.successCallback = $.proxy(opts.success, this);
        opts.success = null;
      }
      var self = this;
      var p = T1Collection.prototype.fetch.apply(this, arguments);
      p.then(function () {
        self.fetchVideoCreatives();
      });
      return p;
    },
    fetchVideoCreatives: function () {
      var ids = this.pluck("id");
      var self = this;
      if (ids.length > 100) {
        ids = _.last(ids, 100);
      }
      if (ids.length > 0) {
        VideoUtils.fetchVACSData(ids, $.proxy(self.mixInCreatives, self));
      } else {
        this.mixInCreatives();
      }
    },
    fetchVideoObj: function (opts) {
      var self = this;
      var ids = [];
      ids.push(this.id);
      if (opts) {
        this.isAdding = opts.add;
        this.successCallback = $.proxy(opts.success, this);
        opts.success = null;
      }
      if (ids.length > 0) {
        VideoUtils.fetchVACSData(ids, $.proxy(self.mixInCreatives, self));
      } else {
        this.mixInCreatives();
      }
    },
    getSelected: function () {
      var selectedModels = this.filter(function (model) {
        return model.get("selected") === true;
      });
      return new T1Collection(selectedModels);
    },
    toggleAllSelected: function (isSelected) {
      this.each(function (model) {
        model.set({
          selected: isSelected
        });
      });
    },
    getDurationDisplay: function (rawVal) {
      return moment.utc(rawVal * 1e3).format("mm:ss");
    },
    getDateDisplay: function (rawVal) {
      var tmpDt = rawVal ? Date.parse(rawVal) : null;
      if (tmpDt) {
        return tmpDt.toString("MM/dd/yy");
      } else {
        return rawVal;
      }
    },
    getVACSDateDisplay: function (timestamp) {
      var d = new Date(timestamp * 1e3);
      return d.toString("MM/dd/yy");
    },
    mixInCreatives: function (data) {
      var self = this;
      var tester = /[^0:]/;
      var videoCreativeObj, outputObj, details, modelJSON;
      this.each(function (model) {
        var xmlDoc = null;
        var creativeId = self.id;
        var vast, duration, companionElements;
        if (data) {
          videoCreativeObj = data.creatives[model.id] || data.creatives[creativeId];
          if (videoCreativeObj) {
            if (videoCreativeObj.details) {
              videoCreativeObj.duration = videoCreativeObj.duration ? self.getDurationDisplay(videoCreativeObj.duration) : null;
              videoCreativeObj.percent = Math.floor(videoCreativeObj.percent);
              videoCreativeObj.hasCompanions = videoCreativeObj.hasCompanions;
              videoCreativeObj.isTranscoded = videoCreativeObj.percent === 100;
              videoCreativeObj.hasError = videoCreativeObj.status.toLowerCase() === "error";
              videoCreativeObj.isTranscoding = videoCreativeObj.percent < 100 && videoCreativeObj.status.toLowerCase() !== "error";
              details = videoCreativeObj.details;
              delete videoCreativeObj.details;
              videoCreativeObj = $.extend(details, videoCreativeObj);
              vast = videoCreativeObj.customVAST;
              if (!videoCreativeObj.duration || !tester.test(videoCreativeObj.duration)) {
                if (vast && xmlDoc !== false) {
                  try {
                    if (xmlDoc === null) {
                      xmlDoc = false;
                      xmlDoc = $.parseXML(vast.trim()).documentElement;
                    }
                    duration = $(xmlDoc).find("Duration").first().text().substr(-5);
                  } catch (e) {
                    duration = null;
                  }
                  if (duration) {
                    videoCreativeObj.duration = duration;
                  }
                }
              }
              if (!videoCreativeObj.hasCompanions) {
                if (vast && xmlDoc !== false) {
                  try {
                    if (xmlDoc === null) {
                      xmlDoc = false;
                      xmlDoc = $.parseXML(vast.trim()).documentElement;
                    }
                    companionElements = $(xmlDoc).find("Companion");
                  } catch (e) {
                    companionElements = null;
                  }
                  if (companionElements) {
                    videoCreativeObj.hasCompanions = companionElements.length > 0;
                  }
                }
              }
              model.set({
                details: new Detail(videoCreativeObj)
              });
            }
            if (videoCreativeObj.isTranscoding) {
              model.fetchCreativeStatus();
            }
          }
        }
        modelJSON = model.toJSON();
        outputObj = {};
        outputObj.added_on = self.getDateDisplay(modelJSON.created_on);
        if (modelJSON.details) {
          outputObj.start = self.getVACSDateDisplay(modelJSON.details.startTime);
          outputObj.end = self.getVACSDateDisplay(modelJSON.details.endTime);
        } else {
          outputObj.start = "--";
          outputObj.end = "--";
        }
        model.set(outputObj);
      });
      if (this.successCallback) {
        this.successCallback();
      }
      if (!this.isAdding) {
        this.trigger("reset");
      } else {
        this.isAdding = null;
      }
    }
  });
  T1.Models.CreativesVideo = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.CreativesVideo;
});
