define('modules/strategy/views/helpers/forecasterWarningHelper',["T1", "collections/targetingDimensions", "models/targetingVendor", "models/audienceVendor"], function (T1, TargetingDimensions, TargetingVendor, AudienceVendor) {
  "use strict";

  const warningTypeEnums = Object.freeze({
    ignoredDimensions: "ignoredDimensions",
    insufficientDataDimensions: "insufficientDataDimensions",
    noDataDimensions: "noDataDimensions",
    unapprovedVendors: "unapprovedVendors",
    outdatedSiteList: "outdatedSiteList"
  });
  const getModelInstance = (() => {
    const _modelMap = new Map();
    return (type, item) => {
      const vendorType = getVendorType(item);
      const vendor = _modelMap.get(vendorType);
      const id = getId(type, item);
      if (vendor && !vendor[id]) {
        vendor[id] = new vendorType({
          id: id
        });
      } else {
        _modelMap.set(vendorType, {
          [id]: new vendorType({
            id: id
          })
        });
      }
      return vendor[id];
    };
  })();
  function getVendorType(item) {
    return item.dimension === "2" ? AudienceVendor : TargetingVendor;
  }
  function getId(type, item) {
    switch (type) {
      case warningTypeEnums.ignoredDimensions:
        return item.dimension;
      case warningTypeEnums.insufficientDataDimensions:
      case warningTypeEnums.outdatedSiteList:
        return item.term.substring(item.term.indexOf("<") + 1, item.term.indexOf(","));
      case warningTypeEnums.noDataDimensions:
        return item.substring(item.indexOf("<") + 1, item.indexOf(","));
      case warningTypeEnums.unapprovedVendors:
        return item.vendor;
    }
  }
  function getModel(type, itemData) {
    return new Promise((resolve, reject) => {
      const modelInstance = getModelInstance(type, itemData);
      modelInstance.fetch({
        success: returnedModel => resolve(returnedModel),
        error: (returnedModel, response) => reject(response)
      });
    });
  }
  function getTargetingDimensions(type, data) {
    return new Promise((resolve, reject) => {
      const collectionInstance = new TargetingDimensions();
      collectionInstance.fetch({
        success: returnedColl => resolve(getTargetingDimensionNames(type, data, returnedColl)),
        error: (returnedColl, response) => reject(response)
      });
    });
  }
  function getTargetingDimensionNames(type, data, coll) {
    const dimensions = coll.models.reduce((arr, model) => {
      arr[model.get("id")] = model.get("name") === "Market" ? "Curated Market" : model.get("name");
      return arr;
    }, []);
    return data.map(itemData => dimensions[getId(type, itemData)]);
  }
  function convertModelArrToNameString(models) {
    return formatDimensionsString(models.map(model => model.get("name")));
  }
  function formatDimensionsString(namesArr) {
    namesArr = getArrOfUniques(namesArr);
    return namesArr.toString().replace(/,/g, ", ").replace(/,\s([^,]+)$/, " and $1");
  }
  function getArrOfUniques(arr) {
    return [...new Set(arr)];
  }
  function buildMsgString(type, models) {
    switch (type) {
      case warningTypeEnums.ignoredDimensions:
        {
          const linkingVerb = models.length === 1 ? "is" : "are";
          return `${formatDimensionsString(models)} ${linkingVerb} not supported by forecaster.`;
        }
      case warningTypeEnums.insufficientDataDimensions:
        {
          return `Some ${formatDimensionsString(models)} ` + `selections don't have the required 7 days of data yet ` + `- please check back after a few days.`;
        }
      case warningTypeEnums.noDataDimensions:
        {
          return `Some of your ${formatDimensionsString(models)} selections have no data.`;
        }
      case warningTypeEnums.outdatedSiteList:
        {
          return "Forecasts are based on an older version of your sitelists - check back in a few hours.";
        }
      case warningTypeEnums.unapprovedVendors:
        {
          const targetingVendorModels = models.filter(model => model.get("type") === "targeting_vendor");
          const audienceVendorsModels = models.filter(model => model.get("type") === "audience_vendor");
          let linkingVerb = "is";
          let errorMsg = "";
          if (audienceVendorsModels.length > 1 || targetingVendorModels.length > 1 || audienceVendorsModels.length === 1 && targetingVendorModels.length === 1) {
            linkingVerb = "are";
          }
          if (audienceVendorsModels.length) {
            errorMsg = `${convertModelArrToNameString(audienceVendorsModels)} audience` + ` segment${audienceVendorsModels.length > 1 ? "s" : ""}`;
          }
          if (targetingVendorModels.length) {
            errorMsg += `${errorMsg ? ", and " : ""}${convertModelArrToNameString(targetingVendorModels)}` + ` contextual segment${targetingVendorModels.length > 1 ? "s" : ""}`;
          }
          errorMsg += ` ${linkingVerb} not currently available for forecasting. ` + "Please reach out to your account rep to request enablement.";
          return errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
        }
    }
  }
  function getModelPromisesArr(type, data) {
    return data.map(itemData => getModel(type, itemData));
  }
  function getWarningMsg(type, dataSet) {
    return new Promise((resolve, reject) => {
      if (warningTypeEnums[type]) {
        if (type === warningTypeEnums.unapprovedVendors) {
          Promise.all(getModelPromisesArr(type, dataSet)).then(models => resolve(buildMsgString(type, models))).catch((models, response) => reject(response));
        } else if (type === warningTypeEnums.outdatedSiteList) {
          resolve(buildMsgString(type, []));
        } else {
          getTargetingDimensions(type, dataSet).then(dimensions => resolve(buildMsgString(type, dimensions))).catch((models, response) => reject(response));
        }
      } else {
        reject(new Error(`Invalid model type "${type}" passed to function getWarningMsg(). ` + `Only warningTypeEnums are guaranteed to work with this function.`));
      }
    });
  }
  function getUnapprovedVendors(warnings) {
    const items = warnings.ignored_items;
    if (items && items.vendors && items.vendors.unapproved_for_forecasting) {
      return items.vendors.unapproved_for_forecasting;
    }
    return [];
  }
  function getInsufficientDataDimensions(warnings) {
    const items = warnings.ignored_items;
    if (items && items.terms && items.terms.insufficient_data) {
      return items.terms.insufficient_data;
    }
    return [];
  }
  function getIgnoredDimensions(warnings) {
    const items = warnings.ignored_items;
    if (items && items.dimensions) {
      let ignoredWarnings = [];
      if (items.dimensions.not_supported) {
        ignoredWarnings = ignoredWarnings.concat(items.dimensions.not_supported);
      }
      if (items.dimensions.unapproved_for_forecasting) {
        ignoredWarnings = ignoredWarnings.concat(items.dimensions.unapproved_for_forecasting);
      }
      return ignoredWarnings;
    }
    return [];
  }
  function getNoDataDimensions(warnings) {
    const items = warnings.ignored_items;
    if (items && items.terms && items.terms.no_data) {
      return items.terms.no_data;
    }
    return [];
  }
  function publishWarningMessage(warningType, data) {
    getWarningMsg(warningType, data).then(message => {
      T1.EventHub.publish(warningType === warningTypeEnums.outdatedSiteList || warningType === warningTypeEnums.noDataDimensions ? "forecaster:warning:noncritical" : "forecaster:warning:critical", message);
    });
  }
  function handleWarnings(warningData, isRunOnAllExchanges) {
    const unapprovedVendors = getUnapprovedVendors(warningData);
    if (unapprovedVendors.length) {
      publishWarningMessage(warningTypeEnums.unapprovedVendors, unapprovedVendors);
      return;
    }
    const insufficientDataDimensions = getInsufficientDataDimensions(warningData);
    if (insufficientDataDimensions.length) {
      publishWarningMessage(warningTypeEnums.insufficientDataDimensions, insufficientDataDimensions);
      return;
    }
    const ignoredDimensions = getIgnoredDimensions(warningData);
    if (ignoredDimensions.length) {
      if (!isRunOnAllExchanges) {
        publishWarningMessage(warningTypeEnums.ignoredDimensions, ignoredDimensions);
        return;
      }
    }
    const noDataDimensions = getNoDataDimensions(warningData);
    if (noDataDimensions.length) {
      publishWarningMessage(warningTypeEnums.noDataDimensions, noDataDimensions);
      return;
    }
    if (warningData.outdated_site_list_sketch && warningData.outdated_site_list_sketch.length) {
      publishWarningMessage(warningTypeEnums.outdatedSiteList, warningData.outdated_site_list_sketch);
    }
  }
  return {
    handleWarnings: handleWarnings
  };
});
