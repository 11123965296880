define('modules/reporting/campaigns/views/select_geo_level',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/select_geo_level.html"], function ($, T1, T1View, T1Permissions, template) {
  "use strict";

  var documentEvents = "mousedown.geoLevelUI keyup.geoLevelUI";
  var $document = $(document);
  const ANIM_TIME = 200;
  const TAB_KEY = 9;
  const ESCAPE_KEY = 27;
  return T1View.extend({
    template: template,
    geoLevel: "all",
    geoLevelUIOpen: false,
    list: [{
      value: "all",
      text: "Country"
    }, {
      value: "country",
      text: "Region"
    }, {
      value: "region",
      text: "Metro"
    }],
    events: {
      "mousedown .head-label": "toggle",
      "mousedown .w-GeoLevel": "stopBubbling",
      "mouseout li": "itemOut",
      "mouseover li": "itemOver",
      "mouseup .geo-level-list li.enabled": "itemClick"
    },
    dataEvents: {
      reportModel: {
        "change:geoNav": function (reportModel, geoNav) {
          this.geoLevel = geoNav.level;
          this.reload();
        }
      }
    },
    initialize: function (args) {
      this.models = args.models;
      this.reportMeta = this.models.reportMeta;
      this.reportModel = this.models.reportModel;
      this.reportMetaObj = $.extend({}, this.models.reportMeta.toJSON());
      this.campaign = args.models.campaign;
      this.geoLevel = this.reportModel.getGeoNav().level;
      this.updateList();
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.initializeUI();
      });
    },
    unload: function () {
      $document.off(documentEvents);
      this.wBody = null;
      this.geoLevelLabel = null;
      this.geoLevelUIOpen = null;
    },
    updateList: function () {
      var i, item;
      var len = this.list.length;
      if (len > 1) {
        for (i = 1; i < len; i++) {
          item = this.list[i];
          item.disabled = false;
        }
      }
    },
    initializeUI: function () {
      var el = $(this.el);
      this.wBody = el.find(".w-body");
      this.geoLevelLabel = el.find(".head-label");
    },
    stopBubbling: function (e) {
      e.stopPropagation();
    },
    toggle: function (e) {
      e.stopPropagation();
      if (this.geoLevelUIOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open: function () {
      var self = this;
      $document.mousedown();
      this.wBody.removeClass("no-display").fadeIn(ANIM_TIME);
      this.geoLevelLabel.addClass("white-bg");
      this.geoLevelUIOpen = true;
      $document.one(documentEvents, function (e) {
        if (e.keyCode === TAB_KEY || e.keyCode === ESCAPE_KEY || !e.keyCode) {
          self.close();
        }
      });
    },
    close: function () {
      this.geoLevelLabel.removeClass("white-bg");
      this.wBody.hide();
      this.geoLevelUIOpen = false;
      $document.off(documentEvents);
    },
    itemOver: function (e) {
      $(e.currentTarget).addClass("highlighted");
    },
    itemOut: function (e) {
      $(e.currentTarget).removeClass("highlighted");
    },
    itemClick: function (e) {
      var selectedId = $(e.currentTarget).data("id");
      var publish = T1.EventHub.publish;
      if (this.geoLevel !== selectedId) {
        this.geoLevel = selectedId;
        publish("select:geoLevel", this.geoLevel);
        publish("track:event", {
          action: "Choose geo level",
          label: this.geoLevel
        });
        this.reload();
      }
      this.close();
    },
    getGeoLevelText: function (geoLevel) {
      var i, result;
      var len = this.list.length;
      for (i = 0; i < len; i++) {
        if (this.list[i].value === geoLevel) {
          result = this.list[i].text;
          break;
        }
      }
      return result;
    },
    serialize: function () {
      var i, item;
      var len = this.list.length;
      for (i = 0; i < len; i++) {
        item = this.list[i];
        item.selected = item.value === this.geoLevel;
        if (item.disabled) {
          item.name = item.disabledName || item.name;
          item.state = "disabled";
        } else {
          item.state = "enabled";
        }
      }
      return {
        geoLevelList: this.list,
        geoLevelText: this.getGeoLevelText(this.geoLevel),
        label: "View by:"
      };
    }
  });
});
