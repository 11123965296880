define('modules/creatives/display/views/conceptModal',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "models/concept", "text!../templates/concept_dialog.html"], function ($, _, When, T1, T1View, T1Layout, Concept, tmpl) {
  var conceptModal = T1View.extend({
    template: tmpl,
    events: {
      "keydown .text-input.concept": "handleKey"
    },
    eventhubEvents: {
      "conceptmodal:show": "show",
      "dropdown:concepts": "handleConceptSelect"
    },
    initialize: function (args) {
      this.model = args.model;
      this.advertiser_id = this.model.get("advertiser_id");
      this.options = {
        title: "Assign Concept",
        message: "type Concept Name here"
      };
      this.initLayout();
    },
    initLayout: function () {
      var self = this;
      var advertiser = self.model.get("advertiser_id");
      this.layout = new T1Layout({
        el: self.el,
        selectorPath: ".concept-select",
        template: '<div class="concept-select-box"></div>',
        layout: {
          ".concept-select": [{
            module: "shared",
            viewType: "select",
            options: {
              entity: "concepts",
              boundEntityId: advertiser,
              dataBind: "concept_id",
              notFound: "Default (same as 'Name')",
              placeholder: "Default (same as 'Name')",
              preSelect: "Default (same as 'Name')",
              refreshEvent: "conceptmodal",
              isSearchable: true,
              initialLoad: true,
              extraLinkLabel: "+ Add New Concept",
              extraLinkClass: "extra-link-add clearfix",
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              },
              extraLinkHandler: function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                self.showConceptInput();
              },
              disableCheck: function (count, hasId) {
                return !hasId;
              }
            }
          }]
        }
      });
    },
    load: function () {
      this.renderView();
    },
    renderView: function () {
      var self = this;
      this.render().then(function () {
        self.modal = self.el;
        self.modal.dialog({
          autoOpen: false,
          modal: true,
          dialogClass: "w-customAlert concept",
          buttons: [{
            text: "Save",
            class: "save",
            click: $.proxy(self.saveConcept, self)
          }, {
            text: "Cancel",
            class: "cancel",
            click: $.proxy(self.hide, self)
          }]
        });
        self.layout.load();
      });
    },
    unload: function () {
      if (this.modal) {
        this.modal.remove();
        this.modal.dialog("destroy");
        this.modal = null;
      }
    },
    show: function () {
      this.modal.dialog("open");
      this.conceptModel = new Concept();
      this.resetConceptSelect();
    },
    hide: function () {
      this.modal.dialog("close");
      this.hideConceptInput();
      this.hideError();
      T1.EventHub.publish("dropdown:conceptmodal", {
        id: this.model.get("advertiser_id")
      });
    },
    showConceptInput: function () {
      var $inputHolder = $(this.el.find(".input-holder"));
      var $inputField = $($inputHolder.find(".text-input"));
      var $dropdownResults = $(this.el).find("select").data("chosen");
      this.isNewConcept = true;
      T1.EventHub.publish("dropdown:concepts:close");
      if ($dropdownResults.results_showing) {
        $dropdownResults.close_field();
      }
      this.resetConceptSelect();
      this.hideError();
      $inputHolder.show();
      $inputField.focus();
    },
    hideConceptInput: function () {
      var $inputHolder = $(this.el.find(".input-holder"));
      var $inputField = $($inputHolder.find(".text-input"));
      this.isNewConcept = false;
      $inputHolder.hide();
      $inputField.val("");
    },
    showError: function (errors) {
      this.options.hasError = true;
      this.options.errors = errors;
      if (this.isNewConcept) {
        var $inputHolder = $(this.el.find(".input-holder"));
        var $inputField = $($inputHolder.find(".text-input"));
        $inputField.addClass("error");
      } else {
        var $chznSingle = $(".chzn-single");
        $chznSingle.addClass("error");
      }
      this.renderPartial(".error-wrapper");
    },
    hideError: function () {
      var $inputField = $(this.el.find(".input-holder .text-input"));
      var $chznSingle = $(".chzn-single");
      this.options.hasError = false;
      this.options.errors = "";
      $inputField.removeClass("error");
      $chznSingle.removeClass("error");
      this.renderPartial(".error-wrapper");
    },
    handleConceptSelect: function (e) {
      var conceptId = e.id || e.selectedId;
      var conceptName = e.model.get("name");
      this.selectedConcept = {
        id: conceptId,
        name: conceptName
      };
      this.hideError();
      this.hideConceptInput();
    },
    resetConceptSelect: function () {
      var $hiddenSelect = $(this.el.find(".select-input"));
      this.selectedConcept = null;
      $hiddenSelect.val("").trigger("liszt:updated");
    },
    handleKey: function (e) {
      if (e.keyCode === 13) {
        this.saveConcept();
      }
    },
    saveConcept: function (e) {
      if (this.isNewConcept && !this.selectedConcept) {
        var $inputField = $(this.el.find(".input-holder .text-input"));
        var value = $inputField.val();
        if (value.length && value !== null) {
          this.sendData(value).then($.proxy(function () {
            this.model.setConcept({
              id: this.conceptModel.id,
              name: this.conceptModel.get("name")
            });
            T1.EventHub.publish("reviewgrid:valid:update", {
              type: "concept"
            });
            this.hide();
          }, this), $.proxy(function (errors) {
            this.showError(errors);
          }, this), function () {});
        } else {
          this.showError("Please enter a Concept Name");
        }
      } else if (!this.isNewConcept && this.selectedConcept) {
        this.model.setConcept(this.selectedConcept);
        T1.EventHub.publish("reviewgrid:valid:update", {
          type: "concept"
        });
        this.hide();
      } else {
        var $button = $(e.currentTarget);
        var creativeIndex = this.model.get("checked_items")[0];
        var creative = this.model.get("primaries") ? this.model.get("primaries")[creativeIndex] : this.model.get("valid_placement")[creativeIndex];
        this.startSpinner($button);
        this.sendData(creative.name).then($.proxy(function (concept) {
          this.stopSpinner($button);
          this.model.setConcept({
            id: concept.id,
            name: concept.get("name")
          });
          T1.EventHub.publish("reviewgrid:valid:update", {
            type: "concept"
          });
          this.hide();
        }, this), $.proxy(function (errors) {
          this.showError(errors);
        }, this), function () {});
      }
    },
    sendData: function (value) {
      var deferred = When.defer();
      this.conceptModel.save({
        name: value,
        status: 1,
        advertiser_id: this.advertiser_id
      }, {
        success: $.proxy(function () {
          deferred.resolve(this.conceptModel);
        }, this),
        statusInvalid: $.proxy(function (errors) {
          var batchedErrors = _.pluck(errors, "message");
          batchedErrors = batchedErrors.join("<br/>\n");
          deferred.reject(batchedErrors);
        }, this)
      });
      return deferred.promise;
    },
    serialize: function () {
      return {
        title: this.options.title,
        message: this.options.message,
        hasError: this.options.hasError,
        error: this.options.errors
      };
    }
  });
  return T1.Form.ViewUtils.extend(conceptModal);
});
