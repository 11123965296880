define('modules/campaign/pixelSegment/views/list',["Underscore", "T1", "T1View", "collections/audienceProviders", "text!modules/campaign/pixelSegment/templates/list.html", "text!templates/info_box.html", "T1Layout", "T1Selectbox", "T1Truncate"], function (_, T1, T1View, AudienceProviders, template, info_box, T1Layout) {
  "use strict";

  const pixelTypeMap = Object.freeze({
    data: " Data Pixels",
    dynamic: " Segments",
    event: " Event Pixels",
    segment: " Segments"
  });
  return T1View.extend({
    template: template,
    list_size_options: {
      options: [{
        value: "10",
        text: "10"
      }, {
        value: "25",
        text: "25"
      }]
    },
    partials: {
      info_box: info_box
    },
    audienceFilterList: [{
      text: "All Providers",
      value: 0
    }],
    selected: "",
    fetchRequired: true,
    allowMultipleSelection: false,
    eventhubEvents: {
      "advertiser:select": function ({
        id: id
      }) {
        this.advertiser_id = id;
      },
      "pixel:search": function (args) {
        this.searchPixels(args);
      },
      "pixel:applyFilter": function (args) {
        this.searchFilters = args;
        this.applyFilters();
      }
    },
    dataEvents: {
      sharedCollection: {
        add: function (model) {
          this.isAcxiomSelected(model);
        },
        remove: function (model) {
          this.isAcxiomSelected(model);
        }
      }
    },
    events: {
      "click .w-head .close": "hideList",
      "click .eligible label": "applyFilters",
      "click .acxiom-info .close": "toggleAcxiomInfo"
    },
    previousSearchKey: "",
    initialize: function ({
      searchViewType = "search",
      orgId: orgId,
      strategy: strategy,
      getAdvertiserId: getAdvertiserId,
      getAdvertiserName: getAdvertiserName,
      getAdvertiser: getAdvertiser,
      sharedCollection: sharedCollection,
      allowMultipleSelection: allowMultipleSelection,
      campaign: campaign,
      partialCollection: partialCollection,
      selectionMode: selectionMode
    }) {
      var self = this;
      const collection = this.collection;
      const pageSize = 40;
      collection.updatePageLimit(pageSize);
      this.isEligible = 1;
      this.searchFilters = null;
      this.searchViewType = searchViewType;
      this.strategy = strategy || {};
      if (!orgId) {
        throw new Error("orgId must be passed in as part of the option parameter.");
      }
      this.orgId = orgId;
      this.audienceSegmentsCollection = new AudienceProviders([], {
        fetchOptions: {
          only_brain_enabled: "true"
        }
      });
      if (getAdvertiserId) {
        const adID = getAdvertiserId();
        this.advertiser_id = adID !== "" ? adID : null;
        collection.search.set({
          params: [{
            term: "event",
            searchField: "pixel_type"
          }, {
            term: "1",
            searchField: "eligible"
          }],
          fetchRequired: false
        });
        if (adID !== undefined) {
          collection.urlFilter.set({
            entity: "advertiser",
            id: adID,
            fetchRequired: true
          });
        }
      }
      if (getAdvertiserName) {
        this.advertiser_name = getAdvertiserName();
      }
      if (getAdvertiser) {
        this.advertiser = getAdvertiser();
      }
      if (sharedCollection) {
        this.sharedCollection = sharedCollection;
      }
      if (allowMultipleSelection) {
        this.allowMultipleSelection = allowMultipleSelection;
      }
      if (campaign) {
        this.campaign = campaign;
      }
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".search",
        template: '<div class="filters-box"></div>',
        wrapperEl: "<div></div>",
        layout: {
          ".filters-box": [{
            module: "campaign/pixelSegment",
            viewType: searchViewType,
            options: {
              orgId: this.orgId,
              campaign_id: this.campaign.get("id"),
              advertiser: {
                id: this.advertiser_id,
                name: this.advertiser_name
              },
              getStrategyAgencyId: function () {
                return self.campaign ? self.campaign.get("advertiser").get("agency_id") : self.strategy.get("campaign").get("advertiser").get("agency_id");
              }
            }
          }]
        }
      });
      this.gridLayout = new T1Layout({
        el: () => this.el,
        selectorPath: ".pixel-grid",
        template: '<div class="grid-holder"></div>',
        wrapperEl: "<div></div>",
        layout: {
          ".grid-holder": [{
            module: "campaign/pixelSegment",
            viewType: "grid",
            options: {
              collection: this.collection,
              audienceSegmentsCollection: this.audienceSegmentsCollection,
              sharedCollection: sharedCollection,
              partialCollection: partialCollection,
              getAdvertiser: getAdvertiserName,
              selectionMode: selectionMode,
              allowMultipleSelection: this.allowMultipleSelection,
              campaign: this.campaign
            }
          }]
        }
      });
    },
    load: function () {
      this.currencyCode = this.campaign.get("currency_code");
      this.render().then(() => {
        this.onRenderComplete();
      });
    },
    onRenderComplete: function () {
      var collection = this.collection;
      const selectBox = this.$(".list-size-select");
      var selectBoxConfig = {
        searchFieldDisabled: true,
        onChange: () => {
          const pageSize = selectBox.val();
          this.selected = pageSize;
          collection.updatePageLimit(pageSize);
          collection.fetch();
        }
      };
      this.layout.load();
      this.gridLayout.load();
      T1.Selectbox(selectBox, selectBoxConfig);
    },
    applyFilters: function () {
      this.isEligible = true;
      this.search();
    },
    unload: function () {
      if (this.searchViewType === "filterSearch") {
        this.collection.search.clear();
        this.searchTerm = "";
        this.searchFilters = {
          type: "event"
        };
        this.search(false);
      }
    },
    search: function (isFetchRequired) {
      const {
        isEligible: isEligible,
        searchTerm: searchTerm,
        collection: collection,
        audienceSegmentsCollection: audienceCollection
      } = this;
      var searchConfig = [];
      var filterConfiguration = [];
      collection.urlFilter.resetCriteria();
      if (this.searchFilters) {
        const searchFilterType = this.searchFilters.type;
        collection.urlFilter.clear();
        if (searchFilterType === "event") {
          filterConfiguration.push({
            entity: "advertiser",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "advertiser",
            id: this.searchFilters.advertisers_id || this.advertiser_id
          });
        } else if (searchFilterType === "data") {
          collection.urlFilter.clearAll();
          filterConfiguration.push({
            entity: "agency",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "agency",
            id: this.advertiser.agency.id
          });
        } else if (searchFilterType === "audience_segments") {
          let iasModel;
          if (searchTerm && searchTerm !== "") {
            audienceCollection.getAudience(searchTerm, this.searchFilters.audience_segments_id ? this.searchFilters.audience_segments_id : "0", this.currencyCode).then(function () {
              collection.audienceViewType = "search";
              collection.searchTerm = searchTerm;
              audienceCollection.audienceViewType = "search";
              audienceCollection.searchTerm = searchTerm;
              iasModel = _.filter(audienceCollection.models, function (model) {
                return model.get("name") === "Integral Ad Science" || model.get("audience_vendor_id") === COMPASS_ENV.IAS_AUDIENCE_VENDOR_ID;
              });
              if (iasModel) {
                audienceCollection.remove(iasModel);
              }
              collection.reset(audienceCollection.models);
            });
          } else {
            audienceCollection.getProviders().then(function () {
              collection.audienceViewType = "browse";
              audienceCollection.audienceViewType = "browse";
              iasModel = _.find(audienceCollection.models, function (model) {
                return model.get("name") === "Integral Ad Science" || model.id === COMPASS_ENV.IAS_ID;
              });
              if (iasModel) {
                audienceCollection.remove(iasModel);
              }
              collection.reset(audienceCollection.models);
            });
          }
          return;
        } else {
          collection.urlFilter.clearAll();
          filterConfiguration.push({
            entity: "advertiser",
            fetchRequired: false
          });
          collection.urlFilter.setConfiguration(filterConfiguration);
          collection.urlFilter.set({
            entity: "advertiser",
            id: this.searchFilters.dynamic_segment_id || this.advertiser_id
          });
        }
        if (searchFilterType !== "audience_segments") {
          searchConfig.push({
            term: searchFilterType,
            searchField: "pixel_type"
          });
        }
        if (searchFilterType === "data" && this.searchFilters.provider_id) {
          searchConfig.push({
            term: this.searchFilters.provider_id,
            searchField: "provider_id"
          });
        }
      } else {
        searchConfig.push({
          term: "event",
          searchField: "pixel_type"
        });
      }
      if (isEligible) {
        searchConfig.push({
          term: "1",
          searchField: "eligible"
        });
      }
      if (searchTerm && searchTerm !== "") {
        searchConfig.push({
          term: searchTerm,
          searchField: "name|id"
        });
      }
      T1.EventHub.publish("pixel:setSearchLoader");
      collection.search.set({
        params: searchConfig,
        fetchRequired: isFetchRequired || true,
        success: function () {
          collection.trigger("reset", collection);
        }
      });
    },
    hideList: function (event) {
      this.preventDefault(event);
      T1.EventHub.publish("pixel:hideDetails");
    },
    searchPixels: function ({
      term: term
    }) {
      if (term !== this.previousSearchKey) {
        this.previousSearchKey = term;
        this.searchTerm = term;
        this.search();
      }
    },
    toggleAcxiomInfo: function (e) {
      var canShow = e === true;
      const animTime = 20;
      this.$(".acxiom-info")[canShow ? "show" : "hide"](animTime);
      this.$(".list-c")[canShow ? "addClass" : "removeClass"]("info-message-on");
      if (!canShow) {
        this.preventDefault(e);
        T1.appVariables.strategy_acxiom_message_closed = true;
      }
    },
    isAcxiomSelected: function (model) {
      if (!T1.appVariables.strategy_acxiom_message_closed && this.sharedCollection.loaded) {
        const fullPath = model.get("full_path");
        if (fullPath && fullPath.toLowerCase().includes("acxiom")) {
          this.toggleAcxiomInfo(true);
        }
      }
    },
    serialize: function () {
      return {
        list_size_options: this.list_size_options.options,
        pixel_type: this.searchFilters ? pixelTypeMap[this.searchFilters.type] : " Merit Pixels",
        msgForAcxion: {
          infoBoxMessageLines: [`According to the terms of MediaMath's license with Acxiom,\n            you are prohibited from using Acxiom data segments to target ads for consumer credit,\n            credit repair, insurance or employment.`],
          infoMessageCanClose: true,
          infoBoxClassName: "acxiom-info"
        }
      };
    }
  });
});
