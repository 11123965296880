define('modules/audiences/segments/models/segmentColumns',["jQuery"], function ($) {
  "use strict";

  return {
    tableColumns: [{
      name: "segment-name",
      label: "adaptive segment"
    }, {
      name: "advertiser",
      label: "advertiser"
    }, {
      name: "id",
      label: "id"
    }, {
      name: "creator",
      label: "creator"
    }, {
      name: "date-modified",
      label: "date modified"
    }, {
      name: "settings",
      label: ""
    }]
  };
});
