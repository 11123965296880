define('modules/admin/user/views/users',['require','jQuery','utils/AdminUtils','models/sessionUser','T1','T1Layout','T1View','text!modules/admin/user/templates/users.html','models/userPreferences','collections/users','When'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const SessionUser = require("models/sessionUser");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const template = require("text!modules/admin/user/templates/users.html");
  const UserPreferences = require("models/userPreferences");
  const Users = require("collections/users");
  const When = require("When");
  return T1View.extend({
    template: template,
    userPreferenceViewName: "user",
    subFilterValue: "ALL",
    loaderOpts: {
      target: ".w-grid",
      text: "Loading ...",
      contentClass: "loader"
    },
    eventhubEvents: {
      "change:itemSelectedChange": function () {
        this.toggleBulkAction();
      },
      "search:onSearchRequest": function (args) {
        this.collection.searchTerm = args.value.length > 0 ? args.value : undefined;
        if (this.searchTerm === this.collection.searchTerm) {
          return;
        }
        this.searchTerm = this.collection.searchTerm;
        this.filterCollection(args.field, args.value);
      },
      "search:clearSearchClicked": function (args) {
        var coll = this.collection;
        coll.refreshOnNextFetch();
        coll.searchTerm = this.searchTerm = undefined;
        this.filterCollection(args.field, args.value);
      },
      "search:toggleActive": function (args) {
        this.filterCollection("active", args.value);
        this.isActive = args.value === "1" ? "true" : "false";
        this.preferences.save();
      },
      "search:subFilterChanged": function (args) {
        this.filterCollection("role", args.value);
        this.subFilterValue = args.value;
        this.preferences.save();
      }
    },
    events: {
      "click .add-user": "handleAddButtons",
      "selected .choose-action": "onChooseActionChange"
    },
    isActive: "true",
    viewChanged: false,
    initialize() {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "admin.search"], ["view", this.userPreferenceViewName]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["isActive", "subFilterValue"]);
      this.isActive = this.preferences.get("isActive");
      this.isActive = this.isActive !== undefined ? this.isActive : "true";
      this.subFilterValue = this.preferences.get("subFilterValue");
      this.subFilterValue = this.subFilterValue !== undefined ? this.subFilterValue : "ALL";
      this.sessionUser = SessionUser.getUser();
      this.userRole = this.sessionUser.get("role");
      this.collection = new Users();
      this.collection.pageLimit = 50;
      this.collection.isPaginated = true;
      this.collection.loaded = false;
      this.collection.applySearchFilter("active", this.isActive === "true" ? "1" : "");
      this.collection.applySearchFilter("role", this.subFilterValue === "ALL" ? "" : this.subFilterValue);
      this.initLayout();
    },
    initLayout() {
      var userLayout = {
        ".list-search": [{
          module: "admin/shared",
          viewType: "search",
          options: {
            searchInputPlaceHolder: "Type Email, Name or ID number",
            getSearchField: () => "multiField",
            isActiveChecked: this.isActive === "true",
            subFilter: {
              name: "Role",
              defaultValue: this.subFilterValue,
              value: [{
                name: "View All",
                value: "ALL"
              }, {
                name: "Admin",
                value: "ADMIN"
              }, {
                name: "Manager",
                value: "MANAGER"
              }, {
                name: "Reporter",
                value: "REPORTER"
              }]
            }
          }
        }],
        ".w-grid": [{
          module: "admin/user",
          viewType: "grid",
          options: {
            collection: this.collection,
            users: this
          }
        }]
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".list-body",
        template: '<div class="w-grid"></div>',
        layout: userLayout
      });
    },
    unload() {
      var searchFieldVal = this.collection.searchTerm;
      this.viewChanged = true;
      this.collection.searchTerm = this.searchTerm = null;
      if (searchFieldVal && searchFieldVal !== "") {
        this.collection.applySearchFilter(searchFieldVal.indexOf("#") !== -1 ? "name|id" : "multiField", "");
      }
    },
    filterCollection(field, term) {
      var $chooseAction = this.$(".choose-action");
      T1.EventHub.publish("userGrid:reload");
      this.collection.filterBy(field, term, function () {
        $chooseAction.attr("disabled", true);
      });
    },
    handleAddButtons(evt) {
      var $el = $(evt.currentTarget);
      if ($el.hasClass("add-user")) {
        T1.Location.set("admin/user", {});
      }
    },
    toggleBulkAction() {
      var self = this;
      var selected = this.collection.getSelected();
      var $chooseAction = this.$(".choose-action");
      $chooseAction.attr("disabled", true);
      if (selected.length) {
        $.each(selected.models, function (i, model) {
          var perms = AdminUtils.checkUserPermissions(self.sessionUser, model);
          if (perms.canActivate || perms.canDeactivate) {
            $chooseAction.attr("disabled", false);
          }
        });
      }
    },
    onChooseActionChange(evt) {
      var val = evt.originalEvent.detail.value;
      if (val === "active") {
        this.bulkAction({
          active: "1"
        });
      }
      if (val === "inactive") {
        this.bulkAction({
          active: "0"
        });
      }
    },
    bulkAction(modelValues) {
      var self = this;
      var newActive = modelValues.active;
      var queue = [];
      var selected = this.collection.getSelected();
      var entityType = "User";
      selected.each(function (model) {
        var loader = self.createInlineLoader(model.get("id"));
        var deferred = When.defer();
        var saveThisModel = true;
        var perms = AdminUtils.checkUserPermissions(self.sessionUser, model);
        if (typeof newActive !== "undefined") {
          if (newActive === "0" && !perms.canDeactivate) {
            saveThisModel = false;
          } else if (newActive === "1" && !perms.canActivate) {
            saveThisModel = false;
          }
        }
        if (saveThisModel) {
          loader.start();
          model.save(modelValues, {
            success: function () {
              loader.stop();
              deferred.resolve();
            },
            conflict: function () {
              loader.stop();
              deferred.reject();
            }
          });
          queue.push(deferred.promise);
        }
      });
      When.all(queue).then(function () {
        if (queue.length) {
          T1.Notify("message", `${queue.length + AdminUtils.pluralize(entityType, queue.length, AdminUtils.spcOpts.BOTH)}updated successfully!`);
        } else {
          T1.Notify("warning", `No${AdminUtils.pluralize(entityType, queue.length, AdminUtils.spcOpts.BOTH)}qualified for the selected action.`);
        }
      });
    },
    createInlineLoader(id) {
      var targetDiv = `#id${id}`;
      const loader = T1.Loader.create({
        contentClass: "inline-edit-loader",
        target: targetDiv,
        spinnerConfig: {
          lines: 10,
          length: 4,
          radius: 3,
          left: 15,
          top: 4
        }
      });
      return loader;
    },
    load() {
      this.render().then(() => {
        if (this.userRole !== "ADMIN") {
          this.$(".add-user")[0].disabled = true;
        }
        if (this.layout) {
          this.layout.load();
        }
      });
    }
  });
});
