define('modules/audiences/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "audiences",
    viewCfgs: {
      tabs: {},
      audiences: {}
    },
    defaultView: "audiences"
  });
});
