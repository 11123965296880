define('modules/strategy/creatives/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "strategy/creatives",
    viewCfgs: {
      panel: {
        strategy: null
      },
      list: {},
      search: {},
      bins: {},
      readOnly: {
        strategy: null
      }
    },
    defaultView: "panel"
  });
});
