define('modules/T1App/edit/models/teamModel',["jQuery", "Underscore", "T1", "T1Comm", "T1Model"], function ($, _, T1, T1Comm, T1Model) {
  "use strict";

  var teamModel = T1Model.extend({
    dataType: "json",
    url: function () {
      var url = T1.API_APPS_ROOT + "v1.0/dev-team/" + this.attributes.app_id;
      return url;
    },
    parseGridData: function () {
      var parsedData = [];
      var data = this.orgUser;
      for (var i = 0; i < data.length; i++) {
        if (data[i].attributes.classlist) {
          data[i].attributes.classlist = "";
        }
        parsedData.push(data[i].attributes);
      }
      this.parsedOrgUsers = parsedData;
      return this.parsedOrgUsers;
    },
    parseDevTeam: function () {
      var newData = [];
      var data = this.devTeam;
      for (var i = 0; i < data.length; i++) {
        newData.push(data[i].user_id);
      }
      return newData;
    },
    addBinUser: function (devTeam) {
      var newDevTeam = [];
      var orgUsers = this.parsedOrgUsers;
      for (var i = 0; i < devTeam.length; i++) {
        for (var j = 0; j < orgUsers.length; j++) {
          if (parseInt(orgUsers[j].id, 10) === parseInt(devTeam[i].user_id, 10)) {
            newDevTeam.push({
              user_id: devTeam[i].user_id,
              email: orgUsers[j].email
            });
            break;
          }
        }
      }
      return newDevTeam;
    },
    compareChanges: function (currentDevteam) {
      var newArr = currentDevteam;
      var oldArr = this.oldDevTeam || this.parseDevTeam();
      var deleteArr = [];
      var addArr = [];
      for (var i = 0; i < newArr.length; i++) {
        var newMatch = false;
        for (var j = 0; j < oldArr.length; j++) {
          if (newArr[i] === oldArr[j]) {
            newMatch = true;
            break;
          }
        }
        if (!newMatch) {
          addArr.push(newArr[i]);
        }
      }
      for (var k = 0; k < oldArr.length; k++) {
        var oldMatch = false;
        for (var l = 0; l < oldArr.length; l++) {
          if (oldArr[k] === newArr[l]) {
            oldMatch = true;
            break;
          }
        }
        if (!oldMatch) {
          deleteArr.push(oldArr[k]);
        }
      }
      this.currentDevteam = currentDevteam;
      this.set({
        addToDev: addArr,
        removeFromDev: deleteArr
      });
    },
    save: function (attributes, options) {
      var self = this;
      var statusInvalid = options.statusInvalid || $.noop;
      var onSuccess = options.success || $.noop;
      var error = options.error || $.noop;
      var oldDevTeam;
      if (self.currentDevteam) {
        self.compareChanges(self.currentDevteam);
      }
      oldDevTeam = [].concat(self.currentDevteam);
      attributes.addToDev = self.attributes.addToDev || [];
      attributes.removeFromDev = self.attributes.removeFromDev || [];
      if (attributes.addToDev.length > 0) {
        attributes.user_id = attributes.addToDev;
        this.sync("POST", attributes, {
          action: options.action,
          dataType: options.dataType,
          processData: options.processData,
          stringify: options.stringify,
          contentType: options.contentType,
          processAjaxResponse: this.processAjaxResponse,
          success: function (data) {
            self.oldDevTeam = oldDevTeam;
            onSuccess(data);
          },
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400, 404]
          },
          error: error,
          statusInvalid: statusInvalid
        });
      }
      if (attributes.removeFromDev.length > 0) {
        attributes.user_id = attributes.removeFromDev;
        this.sync("POST", attributes, {
          action: options.action,
          httpAction: "delete",
          dataType: options.dataType,
          processData: options.processData,
          stringify: options.stringify,
          contentType: options.contentType,
          processAjaxResponse: this.processAjaxResponse,
          success: function (data) {
            self.oldDevTeam = oldDevTeam;
            onSuccess(data);
          },
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400, 404]
          },
          error: error,
          statusInvalid: statusInvalid
        });
      }
      if (attributes.addToDev.length === 0 && attributes.removeFromDev.length === 0) {
        onSuccess();
      }
    },
    processAjaxResponse: function (resp) {
      var statusCode;
      if (resp.meta === "success") {
        statusCode = "ok";
      } else {
        statusCode = "error";
      }
      return {
        jsonData: resp,
        statusCode: statusCode
      };
    },
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    }
  });
  return teamModel;
});
