define('modules/reporting/campaigns/siteTransparency/views/stScatterChart',['require','modules/shared/configs/charts/scatterChartConfig','T1View'],function (require) {
  "use strict";

  const ChartConfig = require("modules/shared/configs/charts/scatterChartConfig");
  const T1View = require("T1View");
  return T1View.extend({
    initialize(args) {
      this.collection = args.collection;
      this.parentClass = args.parentClass;
      this.reportModel = args.reportModel;
    },
    setupChart() {
      const {
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      if (collection && collection.loaded && colorData) {
        const scatterConfig = ChartConfig.copy();
        const chartData = reportModel.getScatterChartData(parentClass.dimensionKey, parentClass.xKey, parentClass.yKey, colorData, false);
        const labelFnX = function (value) {
          return parentClass.formatValue(value, parentClass.xMetricObj.type);
        };
        const labelFnY = function (value) {
          return parentClass.formatValue(value, parentClass.yMetricObj.type);
        };
        if (chartData.dataProvider.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        if (this.siteTransparencyChart) {
          this.siteTransparencyChart.clear();
          this.siteTransparencyChart = null;
        }
        scatterConfig.dataProvider = chartData.dataProvider;
        scatterConfig.graphs = chartData.graphs;
        scatterConfig.valueAxes[0].labelFunction = labelFnY;
        scatterConfig.valueAxes[1].labelFunction = labelFnX;
        scatterConfig.valueAxes[0].title = parentClass.yAxisLabel;
        scatterConfig.valueAxes[1].title = parentClass.xAxisLabel;
        scatterConfig.listeners[0].method = evt => {
          evt.chart.chartDiv.addEventListener("click", this.toggleHighlighted.bind(this));
        };
        scatterConfig.listeners[1].method = evt => {
          this.toggleHighlighted(evt.graph);
          evt.event.stopPropagation();
        };
        scatterConfig.export = {};
        parentClass.showChart();
        parentClass.hideNoDataChart();
        this.siteTransparencyChart = AmCharts.makeChart("site-transparency-chart", scatterConfig);
        parentClass.highlightChart();
        this.togglePointText();
      }
    },
    updateChart() {
      const {
        collection: collection,
        parentClass: parentClass,
        reportModel: reportModel
      } = this;
      const colorData = reportModel.get("colorData");
      if (!this.siteTransparencyChart) {
        this.setupChart();
        return;
      }
      if (collection && collection.loaded && colorData) {
        const chartData = reportModel.getScatterChartData(parentClass.dimensionKey, parentClass.xKey, parentClass.yKey, colorData, true);
        const labelFnX = function (value) {
          return parentClass.formatValue(value, parentClass.xMetricObj.type);
        };
        const labelFnY = function (value) {
          return parentClass.formatValue(value, parentClass.yMetricObj.type);
        };
        if (chartData.dataProvider.length === 0) {
          parentClass.showNoDataChart();
          parentClass.hideChart();
          return;
        }
        this.siteTransparencyChart.dataProvider = chartData.dataProvider;
        this.siteTransparencyChart.graphs = chartData.graphs;
        this.siteTransparencyChart.valueAxes[0].labelFunction = labelFnY;
        this.siteTransparencyChart.valueAxes[1].labelFunction = labelFnX;
        this.siteTransparencyChart.valueAxes[0].title = parentClass.yAxisLabel;
        this.siteTransparencyChart.valueAxes[1].title = parentClass.xAxisLabel;
        parentClass.highlightChart();
        this.togglePointText();
        parentClass.showChart();
        parentClass.hideNoDataChart();
      }
    },
    toggleHighlighted(highlightedElement) {
      event.stopPropagation();
      const highlighted = this.reportModel.get("highlightedItem");
      let newHighlighedId = highlightedElement.id;
      if (highlighted && highlighted.id === newHighlighedId) {
        newHighlighedId = null;
      }
      this.reportModel.setHighlightedItem({
        from: "dot",
        id: newHighlighedId,
        type: "strategy"
      });
    },
    togglePointText() {
      const graphs = this.siteTransparencyChart.graphs;
      const sitesMarked = this.reportModel.get("sitesMarked");
      for (const graph of graphs) {
        graph.labelText = sitesMarked.includes(graph.title) ? graph.title : "";
      }
      this.siteTransparencyChart.validateData();
    },
    unloadChart() {
      if (this.siteTransparencyChart) {
        this.siteTransparencyChart.clear();
        this.siteTransparencyChart = null;
      }
    }
  });
});
