define('utils/SiteUtils',["jQuery", "T1", "models/youtubeChannel"], function ($, T1, YouTubeChannel) {
  "use strict";

  return {
    youTubeChannelList: null,
    youTubeChannelSigs: {
      long: "googleadsensevideosite.com/ca-pub-6219811747049371::afv_user_id_",
      short: "UC",
      default: "afv_user_id_"
    },
    fetchYouTubeChannelList: function () {
      var self = this;
      var model = new YouTubeChannel();
      model.sync("read", model, {
        action: "download.csv",
        dataType: "html",
        processAjaxResponse: function (resp) {
          var input = d3.csv.parse.call(d3, resp);
          var output = {};
          $.each(input, function (i, v) {
            if (v.yt_channel_name) {
              output[v.yt_channel_name] = v.readable_channel_name;
            }
          });
          self.youTubeChannelList = output;
          return {
            statusCode: "ok",
            jsonData: []
          };
        }
      });
    },
    getYouTubeChannelFormat: function (channel) {
      var youTubeChannelFormat = "invalid";
      $.each(this.youTubeChannelSigs, function (k, v) {
        if (channel.indexOf(v) === 0) {
          youTubeChannelFormat = k;
          return false;
        }
      });
      return youTubeChannelFormat;
    },
    isApp: function (cand) {
      if (cand.toLowerCase().indexOf("app:") !== 0) {
        return false;
      }
      cand = cand.replace(/^app:/i, "");
      if (!cand) {
        return false;
      } else if (!isNaN(parseInt(cand, 10))) {
        return true;
      }
      return this.isAndroidOrFQDN(cand, "android");
    },
    isAndroidOrFQDN: function (cand, type, isAdditionalSiteList) {
      var isAndroidOrFQDN = true;
      var mode = (type || "fqdn").toLowerCase();
      var parts;
      const MAX_CHARS = 253;
      const MIN_PARTS = 2;
      const MAX_PARTS = 63;
      const MIN_GTLD = 2;
      if (typeof cand !== "string") {
        return false;
      }
      if (cand.length > MAX_CHARS) {
        return false;
      }
      parts = cand.split(/[\s.-]+/);
      if (mode === "android") {
        parts = parts.reverse();
      }
      if (isAdditionalSiteList || mode === "android") {
        if (parts.length < 1) {
          return false;
        }
      } else {
        if (parts.length < MIN_PARTS) {
          return false;
        }
      }
      $.each(parts, function (i, part) {
        var isGTLD = i === parts.length - 1;
        var len = part.length;
        var firstChar = part.slice(0, 1);
        var lastChar = part.slice(-1);
        if (part === "") {
          isAndroidOrFQDN = false;
        } else if (len > MAX_PARTS) {
          isAndroidOrFQDN = false;
        } else if (len < MIN_GTLD && isGTLD) {
          isAndroidOrFQDN = false;
        } else if (mode === "fqdn") {
          if (firstChar === "-" || lastChar === "-") {
            isAndroidOrFQDN = false;
          } else if (isGTLD && part.indexOf("xn") !== 0 && part.match(/\d/)) {
            isAndroidOrFQDN = false;
          } else if (part.match(/`|~|!|@|#|\$|%|\^|&|\*|\(|\)|_|\+|=|{|}|\||\[|]|\\|\/|:|"|;|'|<|>|\?|,|\s/)) {
            isAndroidOrFQDN = false;
          }
        } else if (mode === "android") {
          if (part.match(/\W/)) {
            isAndroidOrFQDN = false;
          } else if (firstChar.match(/\d|_/)) {
            isAndroidOrFQDN = false;
          }
        }
        if (!isAndroidOrFQDN) {
          return false;
        }
      });
      return isAndroidOrFQDN;
    },
    isExtendedDomain: function (cand, isAdditionalSiteList) {
      var domain, parts, path;
      if (cand.toLowerCase().match(/^https?:\/\//) || !cand.match(/^[a-zA-Z0-9]/)) {
        return false;
      }
      parts = this.splitExtendedDomain(this.normalizeExtendedDomain(cand));
      domain = parts[0];
      path = parts[1] || "";
      if (!this.isFQDN(domain, isAdditionalSiteList)) {
        return false;
      }
      if (path.match(/`|!|@|#|\$|%|\^|&|\*|\(|\)|=|{|}|\||\[|]|\\|\/|:|"|;|'|<|>|\?|\.|,|\s/)) {
        return false;
      }
      return true;
    },
    isIDNDomain: function (domain, isAdditionalSiteList) {
      var idnRegEx = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
      if (isAdditionalSiteList) {
        return domain.match(idnRegEx);
      }
    },
    isFQDN: function (cand, isAdditionalSiteList) {
      return this.isAndroidOrFQDN(cand, "fqdn", isAdditionalSiteList);
    },
    isIPAddress: function (cand) {
      var isIPv4Address = function (v4Cand) {
        const MAX_PARTS = 4;
        const MAX_RANGE = 255;
        var isV4Address = true;
        var parts;
        if (typeof v4Cand !== "string") {
          return false;
        }
        if (v4Cand.match(/[^0-9.]/)) {
          return false;
        }
        parts = v4Cand.split(/\./);
        if (parts.length !== MAX_PARTS) {
          return false;
        }
        $.each(parts, function (i, part) {
          var parsed = parseInt(part, 10);
          if (isNaN(parsed) || parsed < 0 || parsed > MAX_RANGE) {
            isV4Address = false;
          }
        });
        return isV4Address;
      };
      var isIPv6Address = function (v6Cand) {
        const MIN_PARTS = 4;
        const MAX_PARTS = 8;
        const MAX_RANGE = 65535;
        var isV6Address = true;
        var parts;
        if (typeof v6Cand !== "string") {
          return false;
        }
        if (v6Cand.toLowerCase().match(/[^0-9a-f:]/)) {
          return false;
        }
        parts = v6Cand.split(/:/);
        if (parts.length < MIN_PARTS || parts.length > MAX_PARTS) {
          return false;
        }
        $.each(parts, function (i, part) {
          var parsed = parseInt(part || 0, 16);
          if (isNaN(parsed) || parsed < 0 || parsed > MAX_RANGE) {
            isV6Address = false;
          }
        });
        return isV6Address;
      };
      return isIPv4Address(cand) || isIPv6Address(cand);
    },
    isYouTubeChannel: function (cand) {
      var isYouTubeChannel = false;
      var validList = this.youTubeChannelList;
      cand = this.normalizeYouTubeChannel(cand);
      if (!validList) {
        isYouTubeChannel = cand.indexOf(`youtube:${this.youTubeChannelSigs.default}`) === 0;
      } else {
        isYouTubeChannel = validList && typeof validList[cand] === "string";
      }
      return isYouTubeChannel;
    },
    normalizeApp: function (app) {
      return app.replace(/^app:/i, "app:");
    },
    normalizeExtendedDomain: function (extendedDomain) {
      var parts = this.splitExtendedDomain(extendedDomain);
      var domain = parts[0].toLowerCase();
      var path = parts[1] || "";
      if (path === "") {
        return domain;
      }
      return `${domain}/${path.slice(-1) === "/" ? path.slice(0, -1) : path}`;
    },
    normalizeYouTubeChannel: function (channel) {
      var normalized = `youtube:${(channel || "").replace(/^youtube:/i, "").replace(this.youTubeChannelSigs[this.getYouTubeChannelFormat(channel)], this.youTubeChannelSigs.default)}`;
      if (normalized.indexOf(`youtube:${this.youTubeChannelSigs.default}`) === 0) {
        return normalized;
      }
      return channel;
    },
    splitExtendedDomain: function (extendedDomain) {
      var parts = extendedDomain.split("/");
      var domain = parts.shift();
      var path = parts.join("/");
      if (path) {
        return [domain, path];
      }
      return [domain];
    }
  };
});
