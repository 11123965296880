define('modules/strategy/supply/shared/views/browse',['require','jQuery','Underscore','T1','T1View','T1Permissions','T1GridView','utils/currencyConversionHelper','jQPlugins/jquery.trunk8'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const T1 = require("T1");
  const T1View = require("T1View");
  const T1Permissions = require("T1Permissions");
  const createGrid = require("T1GridView");
  const CurrencyUtils = require("utils/currencyConversionHelper");
  const trunk8 = require("jQPlugins/jquery.trunk8");
  function selectBinItems($items) {
    $items.addClass("selected").find(".icon").addClass("active");
  }
  function deselectBinItems($items) {
    $items.removeClass("selected").find(".icon").removeClass("active");
  }
  const SharedBrowse = T1View.extend({
    events: {
      "click .item.can-toggle": "toggleNode",
      "click .icons .include": "clickItem",
      "click .icons .icon-arrow": "handleToggleGridExpanison",
      "click .checkboxAbove": "onFoldCheckboxClick",
      "click .checkboxBelow": "onFoldCheckboxClick",
      "click .checkboxUnknown": "onFoldCheckboxClick",
      "click .deal-edit .strategy-settings": "showSettings"
    },
    truncateConfig: {
      ".truncate": {
        fill: " &hellip;"
      }
    },
    scrollConfigs: {
      triggerPoint: 400,
      target: ".content-holder"
    },
    initialize: function (opts) {
      var chkbx = opts.checkboxAttr;
      if (chkbx) {
        this.otherAttr = this.setOtherAttr(chkbx);
      }
      this.isSourceFeatureEnabled = T1Permissions.check("feature", "source");
      this.targetAllFlagEnabled = T1Permissions.check("feature", "target_all_supply");
      this.extendView(opts);
      this.createCollection(() => {
        this.load();
      });
    },
    sharedCollectionHasChanged: function (model, action) {
      let shCollLen;
      const {
        collection: coll,
        sharedCollection: shColl
      } = this;
      const modelType = model.get("type") || model.get("entity_type");
      const isDeal = modelType === "deal";
      const isSupplySource = modelType === "supply_source";
      const isPMPD = modelType === "site_placement";
      const checkboxAttr = this.checkboxAttr || isPMPD;
      const isOpenOrPMPModel = isSupplySource || isDeal || isPMPD && this.viewInstance !== "dealView";
      const shouldCheckOrUncheck = this.viewInstance !== "dealGroupView" && isOpenOrPMPModel && checkboxAttr && !this.isPGStrategy;
      const browseModels = coll.browseModels ? coll.browseModels : coll.models;
      const collLen = browseModels.length;
      if (isSupplySource) {
        const filteredshColl = shColl.models.filter(item => item.get("type") === "supply_source");
        shCollLen = filteredshColl.filter(item => _.findWhere(browseModels, {
          id: item.id
        })).length;
      } else if (isDeal) {
        shCollLen = shColl.models.filter(item => item.get("type") === "deal").length;
      } else {
        shCollLen = shColl.length;
      }
      if (shouldCheckOrUncheck) {
        this.checkOrUncheck(action, shCollLen, collLen);
      }
      this.renderItem(model.id, `${action}Class`);
      this.finishRendering();
    },
    setRunOnAllProperty(selectingAll) {
      if (this.checkboxAttr) {
        this.setModel(this.checkboxAttr, selectingAll ? "1" : "0");
        if (!this.targetAllFlagEnabled) {
          this.setModel(this.otherAttr, "0");
        }
      }
    },
    selectSourceBinItems($items) {
      const $sourceItems = $items.filter((idx, DOMNode) => {
        const id = $(DOMNode).data("id").toString();
        const model = this.collection.find({
          id: id
        });
        return model.get("accountable_and_addressable") === "1";
      });
      selectBinItems($sourceItems);
    },
    selectAllItemsBasedOnAction($items, action) {
      const selectingAll = action === "addClass";
      this.setRunOnAllProperty(selectingAll);
      $items[action]("selected").find(".icon")[action]("active");
    },
    renderAllItems: function (action) {
      const $items = this.$(".item").not(".can-toggle");
      if (this.checkboxAttr === "run_on_all_pmp") {
        this.selectAllItemsBasedOnAction($items, action);
      }
      if (this.checkboxAttr === "run_on_all_exchanges") {
        if (!this.isSourceFeatureEnabled) {
          this.selectAllItemsBasedOnAction($items, action);
        } else {
          const isAllSourceSelected = this.model.get("exchange_type_for_run_on_all") === "SOURCE";
          const isAllEligibleSelected = this.model.get("exchange_type_for_run_on_all") === "ALL_ELIGIBLE";
          const selectingAll = isAllSourceSelected || isAllEligibleSelected;
          this.setRunOnAllProperty(selectingAll);
          if (isAllSourceSelected) {
            deselectBinItems($items);
            this.selectSourceBinItems($items);
          } else if (isAllEligibleSelected) {
            selectBinItems($items);
          } else if (this.isOnUncheck) {
            delete this.isOnUncheck;
          } else {
            deselectBinItems($items);
          }
        }
      }
      this.finishRendering();
    },
    finishRendering: function () {
      var hasFpt = Boolean(this.sharedFptCollection);
      var prefix = hasFpt ? "en" : "dis";
      this.sharedCollection.isFormElementsChanged = true;
      this.sharedCollection.inWarningState = false;
      if (!this.targetAllFlagEnabled) {
        this[`${prefix}ableFoldTargeting`]();
      }
    },
    checkOrUncheck: function (action, shCollLen, collLen) {
      var isAllSelected = action === "add" && collLen === shCollLen && collLen > 1;
      var isOneUnselected = action === "remove" && collLen === shCollLen + 1;
      var newPmpeDealsAdded = action === "remove" && this.contentType === "deals" && this.model.get(this.checkboxAttr) === "1";
      if (this.contentType === "supply_source") {
        if (!this.isSourceFeatureEnabled && isAllSelected) {
          this.setModel(this.checkboxAttr, "1");
        } else if (isOneUnselected || newPmpeDealsAdded || this.model.get("exchange_type_for_run_on_all") === "SOURCE") {
          if (this.isSourceFeatureEnabled) {
            this.isOnUncheck = true;
          }
          this.model.set({
            exchange_type_for_run_on_all: "SELECTED_ONLY"
          });
          this.setModel(this.checkboxAttr, "0");
        }
      } else {
        if (isAllSelected) {
          this.setModel(this.checkboxAttr, "1");
        } else if (isOneUnselected || newPmpeDealsAdded) {
          this.setModel(this.checkboxAttr, "0");
        }
      }
      if (!this.targetAllFlagEnabled) {
        if (this.otherAttr) {
          this.setModel(this.otherAttr, "0");
        } else {
          this.setModel("run_on_all_pmp", "0");
          this.setModel("run_on_all_exchanges", "0");
        }
      }
    },
    setModel: function (attr, val) {
      this.model.attributes[attr] = val;
      this.model.trigger(`change:${attr}`, val);
    },
    clickItem: function (evt) {
      const $item = $(evt.currentTarget).closest(".item");
      const canWrite = this.canWriteSharedCollection();
      const id = $item.data("id");
      const target = this.collection.get(id) || this.collection.find(model => model.get("id") === id);
      this.preventDefault(evt);
      this.chooseItemAction(canWrite, target);
    },
    chooseItemAction: function (canWrite, item) {
      const isMultiple = _.isArray(item);
      let evt = isMultiple ? "Items" : "Item";
      const getToggleFunc = isArray => {
        if (isArray) {
          return this.toggleItems.bind(this);
        }
        return this.toggleItem.bind(this);
      };
      if (!this.targetAllFlagEnabled) {
        if (canWrite) {
          getToggleFunc(isMultiple)(item);
        } else {
          T1.EventHub.publish(`supply:changeActualChange${evt}:${this.contentType}`);
          evt = `supply:changeTypeBy${evt}:${this.contentType}`;
          this.showConfirmation(evt, item);
        }
      } else {
        getToggleFunc(isMultiple)(item);
      }
    },
    toggleItems: function (items) {
      var coll = this.collection;
      var toggleItem = _.bind(this.toggleItem, this);
      _.chain(items).map(item => coll.get(item.id)).each(toggleItem);
    },
    toggleItem: function (item) {
      var shColl = this.sharedCollection;
      var isInShColl = shColl.get(item.id);
      var action = isInShColl ? "remove" : "add";
      item.set({
        action: "include"
      });
      shColl.contentType = this.contentType === "deal_groups" ? "deals" : this.contentType;
      shColl[action](item);
    },
    toggleRunOnAllCheckbox: function () {
      const type = this.contentType;
      if (this.sharedCollection && this.sharedCollection.isSaving) {
        return;
      }
      if (this.targetAllFlagEnabled || this.canWriteSharedCollection()) {
        this.toggleAll();
      } else {
        this.showConfirmation(`supply:changeTypeByAll:${type}`);
      }
    },
    closeChangeTypeBy: function (itemOrItems) {
      var toggleAction = itemOrItems === "items" ? _.bind(this.toggleItems, this) : _.bind(this.toggleItem, this);
      return _.bind(function (params) {
        if (this.otherAttr) {
          this.setModel(this.otherAttr, "0");
        }
        this.sharedCollection.reset();
        toggleAction(params.item);
        T1.EventHub.publish("supply:hideWarning");
      }, this);
    },
    closeChangeTypeByAll: function () {
      this.toggleAll();
      T1.EventHub.publish("supply:hideWarning");
    },
    toggleAll: function () {
      const {
        model: strategy,
        sharedCollection: shColl
      } = this;
      const models = this.collection.getBrowseModels();
      const toggleAllType = this.checkboxAttr === "run_on_all_pmp" ? "PMP" : "Open";
      const isCheckingSource = toggleAllType === "Open" && this.isSourceFeatureEnabled && strategy.get("exchange_type_for_run_on_all") === "SOURCE";
      let isCheckingAll = strategy.get(this.checkboxAttr) === "0";
      let otherTypeModels = [];
      if (this.isSourceFeatureEnabled && toggleAllType === "Open") {
        isCheckingAll = strategy.get("exchange_type_for_run_on_all") === "ALL_ELIGIBLE";
      }
      if (toggleAllType === "PMP") {
        otherTypeModels = shColl.models.filter(model => model.get("type") === "site_placement" || model.get("type") === "supply_source");
      } else {
        otherTypeModels = shColl.models.filter(model => model.get("type") !== "supply_source");
      }
      if (!this.isOnUncheck) {
        shColl.reset([], {
          silent: true
        });
      }
      if (isCheckingAll) {
        if (toggleAllType === "PMP" || !this.targetAllFlagEnabled) {
          shColl.dealObj = {};
        }
        shColl.contentType = this.contentType;
        shColl.add(models.map(function (model) {
          model.set({
            action: "include"
          }, {
            silent: true
          });
          if (model.get("type") === "deal") {
            shColl.dealObj[model.id] = model;
          }
          return model;
        }), {
          silent: true
        });
      } else {
        shColl.dealObj = {};
      }
      if (isCheckingSource) {
        shColl.contentType = this.contentType;
        shColl.add(models.filter(model => {
          if (model.get("accountable_and_addressable") === "1") {
            model.set({
              action: "include"
            }, {
              silent: true
            });
            if (model.get("type") === "deal") {
              shColl.dealObj[model.id] = model;
            }
            return true;
          }
          return false;
        }), {
          silent: true
        });
      }
      if (otherTypeModels.length > 0) {
        shColl.add(otherTypeModels.map(model => {
          model.set({
            action: "include"
          }, {
            silent: true
          });
          return model;
        }), {
          silent: true
        });
      }
      shColl.trigger(`toggleAll${toggleAllType}`, isCheckingAll ? "addClass" : "removeClass");
    },
    renderItem: function (id, action) {
      const $item = this.$(`[data-id="${id}"]`);
      $item[action]("selected").find(".icon")[action]("active");
    },
    enableFoldTargeting: function () {
      this.$(".checkboxholder input").removeAttr("disabled");
    },
    disableFoldTargeting: function () {
      T1.EventHub.publish("supply:resetFpt");
    },
    trimName: function (name) {
      var len = "checkbox".length;
      return name.substring(len).toLowerCase();
    },
    onFoldCheckboxClick: function (evt) {
      const $checkbox = $(evt.currentTarget);
      const $allFptCheckboxes = $(`.${$checkbox.attr("class")}`);
      const isChecked = $checkbox.is(":checked");
      const params = {
        isChecked: isChecked,
        name: this.trimName(evt.currentTarget.className)
      };
      evt.stopPropagation();
      if (isChecked) {
        $allFptCheckboxes.attr("checked", isChecked);
      } else {
        $allFptCheckboxes.removeAttr("checked");
      }
      T1.EventHub.publish("supplyFpt:update", params);
    },
    canWriteSharedCollection: function () {
      var shColl = this.sharedCollection;
      var isRightType = shColl.contentType === this.contentType;
      var isEmpty = !shColl.length && !shColl.contentType;
      if (shColl.contentType === "deals" && this.contentType === "deal_groups") {
        isRightType = true;
      }
      return isRightType || isEmpty;
    },
    search: function (data) {
      this.collection.bind("reset", this.load, this);
      this.searchTerm = data.term || false;
      this.searchCollection(data);
    },
    chooseRenderStyle: function (term, models) {
      return term ? this.styleForSearch(models, term) : this.styleForBrowse(models);
    },
    getAlertHeader: function (type) {
      var pick = {
        supply_source: "Open Supply",
        deals: "PMP-E",
        deal_groups: "PMP-E",
        site_placement: "PMP-D"
      };
      return `Change to ${pick[type]}?`;
    },
    showConfirmation: function (callbackEvent, item) {
      var params = {
        item: item,
        callbackEvent: callbackEvent,
        header: this.getAlertHeader(this.contentType),
        message: this.getAlertBody(this.contentType)
      };
      T1.EventHub.publish("supply:onTypeChange", params);
    },
    getAlertBody: function (type) {
      const openSupplyTab = 0;
      const dealsTab = 1;
      const pmpdTab = 2;
      const sources = ["Open Supply", "Private Marketplace Exchange", "Private Marketplace Direct"];
      const pick = {
        supply_source: [dealsTab, pmpdTab],
        deals: [openSupplyTab, pmpdTab],
        deal_groups: [openSupplyTab, pmpdTab],
        site_placement: [openSupplyTab, dealsTab]
      }[type];
      return `Making this change will remove any selections made in ${sources[pick[0]]} or ${sources[pick[1]]}`;
    },
    load: function () {
      var coll = this.collection;
      var hasTooltips = this.contentType === "deals";
      var currencyCode = this.getCurrencyCode();
      if (this.contentType === "deals" && coll.length) {
        coll = CurrencyUtils.updatePMPePriceWithCurrency(coll, currencyCode);
      }
      this.ensureGridItems();
      if (this.contentType !== "deals" || this.contentType === "deals" && coll.loaded) {
        this.render().then(() => {
          if (hasTooltips) {
            this.makeTooltips();
          }
        });
        if (coll && !coll.loaded) {
          this.$(".no-results").hide();
          this.setSearchLoader();
        } else {
          this.$(".no-results").show();
          if (this.isPGStrategy) {
            this.$(".fptHolder").addClass("li-disabled");
          }
        }
      }
    },
    makeTooltips: function () {
      const elems = this.$(".column.deal-name");
      $.each(elems, function (index, elem) {
        T1.Tooltip(elem, {
          className: "pmpe-tipsy"
        });
      });
    },
    ensureGridItems: function () {
      const {
        sharedCollection: shColl,
        collection: coll
      } = this;
      const formElementsUnchanged = !shColl.isFormElementsChanged;
      const isSource = this.isSourceFeatureEnabled && this.model.get("exchange_type_for_run_on_all");
      const isAll = this.model.get(this.checkboxAttr) === "1";
      const binsNeedModels = isAll && coll && !shColl.length && !isSource;
      if (shColl.contentType === "deals" && isAll && shColl.length !== coll.length) {
        shColl.add(coll.models);
        if (formElementsUnchanged) {
          shColl.isFormElementsChanged = false;
        }
      }
      if (binsNeedModels) {
        shColl.add(coll.models);
        if (formElementsUnchanged) {
          shColl.isFormElementsChanged = false;
        }
      }
      shColl.each(function includeForBins(model) {
        model.set({
          action: "include"
        });
      });
    },
    setOtherAttr: function (attr) {
      if (attr === "run_on_all_pmp") {
        return "run_on_all_exchanges";
      } else if (attr === "run_on_all_exchanges") {
        return "run_on_all_pmp";
      }
    },
    extendView: function (opts) {
      var shouldTrackActiveState = Boolean(opts.changeActiveState);
      _.extend(this, opts);
      if (shouldTrackActiveState) {
        this.state = "both";
      }
      if (this.eventhubEvents) {
        this.eventhubEvents["pmpe:dropInBins"] = "addDroppedItems";
      }
      createGrid.addFeatures.call(this);
    },
    addDroppedItems: function ({
      selectedList: selectedList
    }) {
      const canWrite = this.canWriteSharedCollection();
      this.chooseItemAction(canWrite, selectedList);
    },
    toggleNode: function () {},
    showSettings: function () {},
    createCollection: function () {},
    searchCollection: function () {},
    styleForSearch: function () {},
    styleForBrowse: function () {},
    updateShColl: function () {},
    handleToggleGridExpanison: function () {}
  });
  const browse = createGrid(SharedBrowse);
  browse.draggableConfigs = {
    allowDrag: false,
    allowMultipleSelection: true,
    handlerTextEl: ".type"
  };
  return browse;
});
