define('models/componentCreativePermission',["T1", "T1Model", "Backbone"], function (T1, T1Model, Backbone) {
  T1.Models.ComponentCreativePermission = T1Model.extend({
    apiRoot: COMPASS_ENV.COMPONENT_API_BASE,
    url: "permissions",
    fixture: "-creativeComponent",
    fetch: function (options) {
      var self = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (data) {
        self.loaded = true;
        self.set(data);
        if (options.onSuccess) {
          options.onSuccess(self, data);
        }
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      this.loaded = false;
      self.ajx = self.sync("read", "", {
        action: self.action,
        dataType: "json",
        errorDisplaySuppressingConfig: {
          errorCodes: [502, 404]
        },
        success: onSuccess,
        processAjaxResponse: self.processAjaxResponse
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    }
  });
  return T1.Models.ComponentCreativePermission;
});
