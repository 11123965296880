define('utils/UserDataUtils',["T1", "T1UtilsAsync"], function (T1, utilsAsync) {
  "use strict";

  var deferrals = {};
  var results = {
    addUserInfo: {},
    addOrgPermissions: {},
    addUserPermissions: {}
  };
  var deferralsState = {
    addUserInfo: false,
    addOrgPermissions: false,
    addUserPermissions: false
  };
  function noop() {}
  function updateDataState(route, key, data) {
    results[key] = data;
    deferralsState[key] = true;
    deferrals[key].resolve({
      route: route,
      data: data
    });
  }
  function changeOrgPermissions() {
    deferralsState.addOrgPermissions = false;
    deferralsState.addUserPermissions = false;
    reset();
  }
  function update(route) {
    switch (route) {
      case "organization":
        return changeOrgPermissions();
      default:
        throw new Error(`${route} is not published`);
    }
  }
  function addData(route, data) {
    let key;
    switch (route) {
      case "onUserLoaded":
        key = "addUserInfo";
        break;
      case "onPreferencesReceived":
        key = "addUserPermissions";
        break;
      case "onOrganizationLoaded":
        key = "addOrgPermissions";
        break;
      default:
        throw new Error(`${route} is not published`);
    }
    updateDataState(route, key, data);
  }
  function constructDeferral() {
    var deferral = utilsAsync.makeDeferral();
    deferral.promise.catch(noop);
    return deferral;
  }
  function rejectAll() {
    Object.keys(deferrals).forEach(function (key) {
      deferrals[key].reject();
    });
  }
  function reset() {
    T1.EventHub.publish("UserDataUtils:reset", results);
    rejectAll();
    constructPromises();
  }
  function constructPromises() {
    var promises = [];
    Object.keys(deferralsState).forEach(function (key) {
      if (deferralsState[key] === false) {
        deferrals[key] = constructDeferral();
        promises.push(deferrals[key].promise);
      }
    });
    utilsAsync.linkPromises(promises).catch(() => {
      results = {};
    }).finally(() => {
      T1.EventHub.publish("UserDataUtils:dataLoaded", results);
    });
  }
  function initialize() {
    constructPromises();
    return {
      addData: addData,
      update: update
    };
  }
  return initialize();
});
