define('modules/reporting/campaigns/performance/views/performance',["Underscore", "T1", "T1View", "T1Layout", "T1Permissions", "models/userPreferences", "modules/reporting/campaigns/models/additionalData", "text!../templates/performance.html"], function (_, T1, T1View, T1Layout, T1Permissions, UserPreferences, additionalData, template) {
  "use strict";

  let _campaign, _reportModel;
  const DEFAULT_DIMENSION = "strategy_name";
  const DEFAULT_DIMENSION_2 = "creative_size";
  const ID = "performance";
  return T1View.extend({
    settings_menu_config: {
      currency: {
        label: "Currency",
        items: {
          campaign_currency: {
            id: "campaign",
            item_type: "currency-item",
            label: "Campaign Currency",
            url: ""
          },
          us_dollar: {
            id: "usd",
            item_type: "currency-item",
            label: "US Dollar",
            url: ""
          }
        }
      },
      edit_campaign: {
        label: "Campaign",
        items: {
          edit_campaign: {
            download_url: () => `#campaign/edit/${_campaign.id}/details/general`,
            item_type: "action-item",
            label: "Edit Campaign",
            url: "#campaign/edit/"
          }
        }
      },
      view_summary: {
        label: "Campaign",
        items: {
          view_summary: {
            download_url: () => `#campaign/readonly/${_campaign.id}/details/general`,
            item_type: "action-item",
            label: "View Summary",
            url: "#campaign/readonly/"
          }
        }
      },
      raw_data: {
        label: "Export CSV",
        items: {
          export_campaign: {
            contentType: "text/csv",
            download_url: () => _reportModel.getDownloadURL({
              isSummary: true,
              sourceURL: `std/${ID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: function () {
              if (this.mainModel.get("campaignCount") > 1) {
                return "By Multiple Campaigns Total";
              }
              return "By Campaign Total";
            },
            subSection: " (Summary)",
            url: `std/${ID}/download`
          },
          export_campaign2: {
            contentType: "text/csv",
            download_url: () => _reportModel.getDownloadURL({
              sourceURL: `std/${ID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: function () {
              if (this.mainModel.get("campaignCount") > 1) {
                return "By Multiple Campaigns Total";
              }
              return "By Campaign Total";
            },
            subSection: " (Daily)",
            url: `std/${ID}/download`
          },
          export_dimension: {
            contentType: "text/csv",
            download_url: () => _reportModel.getDownloadURL({
              byDimension: true,
              isSummary: true,
              sourceURL: `std/${ID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: () => `By ${_reportModel.get("dimensionInfo").text}`,
            subSection: " (Summary)",
            url: `std/${ID}/download`
          },
          export_dimension2: {
            contentType: "text/csv",
            download_url: () => _reportModel.getDownloadURL({
              byDimension: true,
              sourceURL: `std/${ID}/download`
            }),
            isExport: true,
            item_type: "action-item",
            label: () => `By ${_reportModel.get("dimensionInfo").text}`,
            subSection: " (Daily)",
            url: `std/${ID}/download`
          }
        }
      }
    },
    defaultSubSection: "daily",
    subSection: "daily",
    attributionGroup: "MediaMath Last Touch",
    campaignBenchmarkOn: "",
    dimension: "",
    dimension2: "",
    focus: "",
    metric: "",
    selectedColumns: "",
    sortKeyInfo: "",
    videoDimension: "",
    videoDimension2: "",
    videoMetric: "",
    videoSelectedColumns: "",
    videoSortKeyInfo: "",
    displayDimension: "",
    displayDimension2: "",
    displayMetric: "",
    displaySelectedColumns: "",
    displaySortKeyInfo: "",
    template: template,
    section: "performance",
    events: {
      click: "dehighlightItem"
    },
    eventhubEvents: {
      "select:focus": function (focus) {
        this.focus = focus;
        this.preferences.save();
      },
      "select:dimension": function (dimension = DEFAULT_DIMENSION) {
        const key = this.focus === "all" ? "dimension" : `${this.focus}Dimension`;
        this[key] = dimension;
        this.preferences.save();
        _reportModel.setDimension(dimension);
      },
      "select:dim2": function (dimension) {
        const key = this.focus === "all" ? "dimension2" : `${this.focus}Dimension2`;
        this[key] = dimension;
        this.preferences.save();
        _reportModel.setDimension(null, null, null, dimension);
      },
      "remove:dim2": function () {
        const key = this.focus === "all" ? "dimension2" : `${this.focus}Dimension2`;
        this[key] = "";
        this.preferences.save();
      },
      "select:metric": function (metric) {
        const key = this.focus === "all" ? "metric" : `${this.focus}Metric`;
        this[key] = metric;
        this.preferences.save();
        _reportModel.setMetric(this[key]);
      },
      "preference:change": function (args) {
        this[args.key] = args.value;
        this.preferences.save();
      },
      "reporting:campaign:load": "refreshUI",
      "reporting:meta:load": function () {
        this.reload();
      },
      "reporting:focus:meta:load": function () {
        this.initPreferences();
        this.refreshUI();
      },
      "select:chart": function (chartType) {
        const mainModel = this.mainModel;
        this.subSection = chartType;
        this.preferences.save();
        mainModel.setSubSection(mainModel.get("section"), chartType);
      },
      "select:attribution": function (attribution) {
        this.attributionGroup = attribution;
        this.preferences.save();
      }
    },
    refreshUI: function () {
      _reportModel.clearTempDimension();
      T1.EventHub.publish("unload:noDataMessages");
      this.load();
    },
    initialize: function (cfgs = {}) {
      const models = this.models = cfgs.models;
      this.report = cfgs.report;
      this.campaignReports = cfgs.campaignReports;
      _campaign = this.campaign = models.campaign;
      this.summary = models.summary;
      this.needEvents = true;
      _reportModel = models.reportModel;
      this.mainModel = models.mainModel;
      _reportModel.setSection(ID);
      this.viewOptions = T1.Location.getViewOptions();
      if (this.viewOptions) {
        this.setDateRange();
      }
      this.mainModel.setReportMainView(this);
    },
    setDateRange: function () {
      const viewOptions = this.viewOptions;
      if (viewOptions.start_date && viewOptions.end_date) {
        const startDate = viewOptions.start_date;
        const endDate = viewOptions.end_date;
        _reportModel.setStartAndEndDate(startDate, endDate);
        this.campaignReports.viewOptionsDate = true;
        T1.EventHub.publish("reports:saveDate");
      }
    },
    initPreferences: function () {
      let dimensionKey, dimensionKey2, metricKey, viewOptionsFocus;
      const signature = T1.Signature.create();
      const mainModel = this.mainModel;
      const reportMeta = this.models.reportMeta;
      const defaultMetric = _reportModel.getDefaultMetric(this.campaign);
      const viewOptions = this.viewOptions;
      const performanceSubSection = mainModel.get("performanceSubSection");
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", ID]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["attributionGroup", "campaignBenchmarkOn", "dimension", "dimension2", "displayDimension", "displayDimension2", "displayMetric", "displaySelectedColumns", "displaySortKeyInfo", "focus", "metric", "selectedColumns", "sortKeyInfo", "subSection", "videoDimension", "videoDimension2", "videoMetric", "videoSelectedColumns", "videoSortKeyInfo"]);
      _reportModel.setPreferences(this.preferences);
      if (performanceSubSection) {
        this.subSection = performanceSubSection;
      } else {
        this.subSection = UserPreferences.get("ui.reporting.performance.subSection") || this.defaultSubSection;
      }
      mainModel.setSubSection(mainModel.get("section"), this.subSection);
      this.focus = this.focus || "all";
      if (viewOptions) {
        viewOptionsFocus = viewOptions.focus;
        if (viewOptionsFocus !== "all" && viewOptionsFocus !== "video") {
          viewOptionsFocus = "all";
        }
        if (this.focus !== viewOptionsFocus) {
          this.focus = viewOptionsFocus;
        }
        delete this.viewOptions;
      }
      const focus = this.focus;
      _reportModel.setFocus(focus);
      reportMeta.parseDimensions("dimensionOptions");
      reportMeta.parseMetrics();
      _reportModel.parseColumns();
      if (focus === "all") {
        dimensionKey = "dimension";
        dimensionKey2 = "dimension2";
        metricKey = "metric";
      } else {
        dimensionKey = `${focus}Dimension`;
        dimensionKey2 = `${focus}Dimension2`;
        metricKey = `${focus}Metric`;
      }
      if (!reportMeta.getDisplayMetrics()[this[metricKey]]) {
        this[metricKey] = defaultMetric;
      }
      const dimension = this[dimensionKey];
      const dimension2 = this[dimensionKey2];
      const verifiedDimensions = Object.keys(additionalData.dimensions.performance[focus]);
      const verifiedDimension = verifiedDimensions.includes(dimension) ? dimension : DEFAULT_DIMENSION;
      let verifiedDimension2 = "";
      if (dimension2) {
        if (verifiedDimensions.includes(dimension2)) {
          verifiedDimension2 = dimension2;
        } else {
          verifiedDimension2 = verifiedDimension === DEFAULT_DIMENSION ? DEFAULT_DIMENSION_2 : DEFAULT_DIMENSION;
        }
      }
      this[dimensionKey] = verifiedDimension;
      this[dimensionKey2] = verifiedDimension2;
      this.preferences.save();
      _reportModel.setDimension(verifiedDimension, false, true, verifiedDimension2, false, true, true);
      _reportModel.setMetric(this[metricKey] || defaultMetric, true);
    },
    initLayouts: function () {
      const models = this.models;
      const layoutConfig = {
        el: () => this.$(".w-performance-panel"),
        template: `<div class="tabs-panel">\n            <div class="tabs-control-focus"></div>\n            <div class="tabs-control-attribution"></div>\n            <div class="tabs-control-dimensions"></div>\n            <div class="tabs-control-dimensions-2"></div>\n            <div class="tabs-settings"></div>\n            <div class="performance-panel"></div>\n          </div>`,
        layout: {
          ".tabs-control-focus": [{
            module: "reporting/campaigns",
            options: {
              models: models
            },
            viewType: "select_focus"
          }],
          ".tabs-control-dimensions": [{
            module: "reporting/campaigns",
            options: {
              index: 0,
              label: "View by:",
              models: models
            },
            viewType: "select_dimension"
          }],
          ".tabs-control-dimensions-2": [{
            module: "reporting/campaigns",
            options: {
              index: 1,
              label: "+ Add Filter",
              models: models
            },
            viewType: "select_dimension"
          }],
          ".tabs-settings": [{
            module: "reporting/campaigns",
            options: {
              menu_config: this.settings_menu_config,
              models: models
            },
            viewType: "settings_menu"
          }],
          ".performance-panel": [{
            module: "reporting/campaigns/performance",
            options: {
              models: models,
              report: this.report,
              subSection: this.subSection
            },
            viewType: "panel"
          }]
        }
      };
      this.layout = new T1Layout(layoutConfig);
    },
    load: function () {
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (this.needEvents) {
        this.bindViewEvents();
        this.needEvents = false;
      }
      _reportModel = this.models.reportModel;
      const reportMeta = _reportModel.reportMeta;
      _reportModel.setSection(ID, true);
      this.unloaded = false;
      if (this.campaign.id && reportMeta.loaded) {
        if (this.layout) {
          this.layout.unload();
        }
        this.initPreferences();
        this.initLayouts();
        this.render().then(() => {
          this.layout.load();
        });
      }
    },
    unload: function () {
      this.unloaded = true;
      this.needEvents = true;
    },
    dehighlightItem: function () {
      _reportModel.setHighlightedItem();
    }
  });
});
