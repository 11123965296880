define('modules/campaign/chart/views/intervalMenu',["Underscore", "T1", "T1View", "text!../templates/intervalMenu.html"], function (_, T1, T1View, template) {
  "use strict";

  var T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    events: {
      "changed .interval-ddl": "intervalChangeHandler"
    },
    initialize: function (configs = {}) {
      this.id = configs.id || 0;
      this.default = configs.default || "Select One";
      this.selected = configs.selected || "";
      this.intervals = (configs.intervals instanceof Function ? configs.intervals() : configs.intervals) || [];
      this.maxItems = configs.maxItems || "";
      this.overflow = configs.overflow || "hidden";
      this.overflowWidth = configs.overflowWidth || 0;
    },
    load: function () {
      this.render().then(function () {
        T1Publish("complete:layoutLoad");
      });
    },
    unload: function () {
      this.default = null;
      this.id = null;
      this.maxItems = null;
      this.intervals = null;
      this.overflow = null;
      this.overflowWidth = null;
      this.selected = null;
    },
    intervalChangeHandler: function (event) {
      T1Publish("change:interval", {
        id: this.id,
        value: event.originalEvent.detail.value,
        label: event.currentTarget.$.label.innerText
      });
    },
    serialize: function () {
      return {
        default: this.default,
        selected: this.selected,
        intervals: this.intervals,
        maxItems: this.maxItems,
        overflow: this.overflow,
        overflowWidth: this.overflowWidth
      };
    }
  });
});
