define('appInit',["jQuery", "Underscore", "T1", "T1UtilsAsync", "utils/LaunchDarklyUtils", "models/userPreferences", "models/sessionUser", "collections/organizations", "appLoad"], function ($, _, T1, utilsAsync, LaunchDarklyUtils, UserPrefs, User, Organizations, appLoad) {
  "use strict";

  const populateT1NoAccessFlag = function () {
    const blockedOrgsStr = COMPASS_ENV.BLOCKED_ORGS;
    const blockedOrgs = blockedOrgsStr ? blockedOrgsStr.split(",") : [];
    const isBlockedOrg = org => blockedOrgs.includes(org);
    const areOrgsPermitted = orgs => !_.pluck(orgs.models, "id").every(isBlockedOrg);
    return function () {
      return utilsAsync.makePromise(resolve => {
        const orgs = Organizations.getOrganizations();
        orgs.getSelected().done(() => {
          if (User.getUser().get("type") === "INTERNAL") {
            T1.NO_ACCESS = false;
          } else {
            T1.NO_ACCESS = !areOrgsPermitted(orgs);
          }
          resolve();
        });
      });
    };
  }();
  const confirmSessionAndUserLoaded = () => utilsAsync.makePromise((resolve, reject) => {
    utilsAsync.linkPromises([User.getUser().initializationPromise, UserPrefs.initializationPromise]).then(resolve).catch(reject);
  });
  const init = isOrgChange => utilsAsync.makePromise((resolve, reject) => {
    confirmSessionAndUserLoaded().then(populateT1NoAccessFlag).then(() => {
      LaunchDarklyUtils.getPromise().then(() => {
        if (isOrgChange) {
          T1.EventHub.publish("organization:continue");
          resolve();
        } else {
          appLoad(true).then(resolve).catch(reject);
        }
      });
    }).catch(reject);
  });
  return init;
});
