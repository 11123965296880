define('modules/creatives/shared/creativesMap',['require','moment'],function (require) {
  "use strict";

  const moment = require("moment");
  function expandDate(value) {
    var dateFormat = "MMMM D, YYYY h:mma";
    return value === "" ? "" : moment.utc(value, "X").format(dateFormat);
  }
  function expandClassifications(value) {
    var output = [];
    if (Object.prototype.toString.call(value) === "[object Array]") {
      [{
        label: "Attributes",
        entity: "attributes"
      }, {
        label: "Categories",
        entity: "verticals"
      }, {
        label: "Languages",
        entity: "languages"
      }].forEach(function (obj) {
        const entityList = value.filter(function (item) {
          var itemEntity = item.data && item.data.entity || item.entity;
          return itemEntity === obj.entity;
        });
        if (entityList && entityList.length) {
          output.push(`${obj.label.toUpperCase()} (${entityList.length}): ${entityList.map(item => item.data && item.data.name || item.name).join(", ")}`);
        }
      });
    }
    return output.join(", ");
  }
  const map = {
    entity: "atomic_creatives",
    ad_format: {
      label: "Ad Format",
      values: {
        DISPLAY: "Display",
        MOBILE: "Mobile",
        EXPANDABLE: "Expandable"
      }
    },
    ad_server_type: {
      label: "Ad Server Name",
      values: {
        ATLAS: "Atlas",
        DART: "DFA",
        EYEWONDER: "EyeWonder",
        MEDIAFORGE: "mediaFORGE",
        MEDIAMIND: "MediaMind",
        MEDIAPLEX: "Mediaplex",
        POINTROLL: "Pointroll",
        YIELD_MANAGER: "Yield Manager",
        OTHER: "Other"
      }
    },
    advertiser_id: {
      label: "Advertiser"
    },
    click_url: {
      label: "Landing Page"
    },
    click_through_url: {
      label: "Click-through URL"
    },
    concept: {
      label: "Concept"
    },
    end_date: {
      label: "End Date",
      values: expandDate,
      suffix: " (Campaign Timezone)"
    },
    edited_tag: {
      label: "HTML Code"
    },
    is_multi_creative: {
      label: "Multiple Creatives"
    },
    mraid_type: {
      label: "MRAID Type"
    },
    expandable: {
      label: "MRAID Expandable File"
    },
    tpas_ad_tag: {
      label: "3rd Party Tags"
    },
    tpas_ad_tag_name: {
      label: "3rd Party Tag Name"
    },
    expansion_direction: {
      label: "Expansion Direction"
    },
    expansion_trigger: {
      label: "Expansion Trigger",
      values: {
        AUTOMATIC: "Automatic",
        MOUSEOVER: "Mouse-Over to Expand",
        CLICK: "Click to Expand"
      }
    },
    external_identifier: {
      label: "3PAS Tag ID"
    },
    file_type: {
      label: "File Type",
      values: {
        gif: "GIF",
        html5: "HTML5",
        swf: "SWF",
        jpg: "JPG",
        unknown: "Unknown"
      }
    },
    has_sound: {
      label: "K-Size"
    },
    height: {
      label: "Height"
    },
    is_https: {
      label: "Security",
      values: {
        0: "Non-Secure",
        1: "Secure"
      }
    },
    is_mraid: {
      label: "MRAID",
      values: {
        0: "No",
        1: "Yes"
      }
    },
    name: {
      label: "Name"
    },
    start_date: {
      label: "Start Date",
      values: expandDate,
      suffix: " (Campaign Timezone)"
    },
    status: {
      label: "Status",
      values: {
        0: "Inactive",
        1: "Active"
      }
    },
    tag: {
      label: "Ad Tag Code"
    },
    tag_type: {
      label: "Ad Tag Type"
    },
    width: {
      label: "Width"
    },
    last_modified: {
      label: "Last Modified"
    },
    vendors: {
      label: "Vendors"
    },
    add_vendors: {
      label: "Add Vendors"
    },
    remove_vendors: {
      label: "Remove Vendors"
    },
    add_classifications: {
      label: "Add Classifications",
      values: expandClassifications
    },
    remove_classifications: {
      label: "Remove Classifications",
      values: expandClassifications
    }
  };
  return map;
});
