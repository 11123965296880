define('modules/vendor/views/boxList',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "collections/vendors", "text!../templates/box_list.html", "text!../templates/box_list_item.html", "text!templates/adx_error_display.html", "utils/CreativeUtils"], function ($, _, T1, T1View, T1Layout, Vendors, template, itemTemplate, adxErrorPartial, CreativeUtils) {
  return T1View.extend({
    template: template,
    partials: {
      listItem: itemTemplate,
      adxErrorDisplay: adxErrorPartial
    },
    tipConfig: {
      getExternalTip: true
    },
    initialize: function (args) {
      this.collection = args.collection || new Vendors();
      this.removedCollection = args.removedCollection || new Vendors();
      this.searchCollection = new Vendors();
      this.searchCollection.pageLimit = 100;
      this.searchCollection.isPaginated = false;
      this.isFormElementsChanged = false;
      this.showLabel = typeof args.showLabel !== "undefined" ? args.showLabel : true;
      this.showVendorTooltip = typeof args.showVendorTooltip !== "undefined" ? args.showVendorTooltip : false;
      this.showAddItem = typeof args.showAddItem !== "undefined" ? args.showAddItem : true;
      this.clearSearchInputAfterDelay = typeof args.clearSearchInputAfterDelay !== "undefined" ? args.clearSearchInputAfterDelay : true;
      if (args.model) {
        this.autoVendors = args.model.get("autoVendors").models;
      } else if (args.vendorCollection) {
        this.autoVendors = args.vendorCollection.models;
      } else {
        this.autoVendors = [];
      }
    },
    events: {
      "click .remove": "removeItem",
      "click .add": "addItem"
    },
    dataEvents: {
      collection: {
        reset: "updateBody"
      }
    },
    eventhubEvents: {
      "vendor_warning:hide": function () {
        $(".adx_error_display").hide();
      }
    },
    load: function () {
      var self = this;
      this.initLayouts();
      this.render().then(function () {
        self.layout.load();
        if (self.showVendorTooltip) {
          T1.Tooltip("body", {
            fade: false,
            tooltipEle: self.el.find(".t1-tooltip").not(".adx-warning, .adx-warning-list, .adx-error, .adx-error-list")
          });
          T1.Tooltip("body", {
            fade: false,
            tooltipEle: self.el.find(".adx-warning, .adx-warning-list"),
            className: "adx-warning-tipsy"
          });
          T1.Tooltip("body", {
            fade: false,
            tooltipEle: self.el.find(".adx-error, .adx-error-list"),
            className: "adx-error-tipsy"
          });
        }
      });
    },
    updateBody: function () {
      var self = this;
      self.renderPartialTemplate({
        templateTarget: ".form-item-container",
        targetEl: self.el.find(".w-boxList .form-item-container")
      });
    },
    initLayouts: function () {
      var self = this;
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".popup-container",
        template: '<div class="popup"></div>',
        layout: {
          ".popup": [{
            module: "vendor",
            viewType: "popup",
            options: {
              clearOnClose: true,
              keyInputDelay: 300,
              collection: self.searchCollection,
              loadDataOnSearch: true,
              clearSearchInputAfterDelay: true,
              autoVendors: self.autoVendors,
              onApply: function (checkedItems) {
                var wasAdded;
                $.each(checkedItems, function (key, value) {
                  var model = self.searchCollection.get(key);
                  if (value) {
                    self.collection.add(model);
                    wasAdded = true;
                  }
                });
                self.updateBody();
                if (wasAdded) {
                  T1.EventHub.publish("vendor:list:updated");
                }
              }
            }
          }]
        }
      });
    },
    getWFilterList: function () {
      this.wFilterList = this.wFilterList || this.el.find(".w-FilterList");
      return this.wFilterList;
    },
    addItem: function (e) {
      this.getWFilterList().trigger("open", {
        collection: this.collection
      });
    },
    removeItem: function (e) {
      var $target = $(e.target).parent();
      var model = this.collection.get($target.data("id"));
      this.collection.remove(model);
      this.removedCollection.add(model);
      $target.hide(function () {
        $(this).remove();
      });
      T1.EventHub.publish("vendor:list:updated");
    },
    serialize: function () {
      var modelJSON = this.model && this.model.toJSON();
      var output = {
        showLabel: this.showLabel,
        showVendorTooltip: this.showVendorTooltip,
        showAddItem: this.showAddItem,
        items: this.collection.toJSON()
      };
      $.extend(output, modelJSON || {});
      output.dataID = modelJSON && modelJSON.id;
      output.error_vendors = CreativeUtils.parseVendorListForDisplay(modelJSON && modelJSON.adxErrorVendors || {});
      return output;
    },
    unload: function () {
      this.wFilterList = null;
    }
  });
});
