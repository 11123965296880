define('modules/reporting/segments/dataExport/createEdit/views/createEdit',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/create-edit.html"], function ($, _, T1, T1View, T1Layout, template) {
  "use strict";

  var PanelView;
  var DataExportModel;
  var PAGE_LIMIT;
  var PUBLISH;
  PanelView = T1View.extend({
    template: template,
    dataEvents: {
      DataExportModel: {
        "change:selectedReport": "loadFilters"
      }
    },
    initialize: function initialize(cfgs) {
      PAGE_LIMIT = 100;
      PUBLISH = T1.EventHub.publish;
      DataExportModel = this.DataExportModel = cfgs.dataExportModel;
    },
    initLayouts: function initLayouts() {
      var self = this;
      this.layout = new T1Layout({
        el: function el() {
          return self.el;
        },
        template: template,
        layout: {
          ".report-type": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "reportType",
            options: DataExportModel
          }],
          ".date-field": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "dateField",
            options: DataExportModel
          }],
          ".segment-export": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "segmentExport"
          }],
          ".report-info": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "reportInfo",
            options: DataExportModel
          }],
          ".email": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "email",
            options: DataExportModel
          }],
          ".select-campaigns": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "selectCampaigns",
            options: DataExportModel
          }],
          ".segments-panel": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "panel",
            options: DataExportModel
          }],
          ".footer": [{
            module: "reporting/segments/dataExport/createEdit",
            viewType: "footer",
            options: DataExportModel
          }]
        }
      });
    },
    load: function load() {
      var self = this;
      this.initLayouts();
      this.render().then(function render() {
        self.layout.load();
      });
    },
    unload: function unload() {},
    loadFilters: function loadMetaData() {
      var reportType = DataExportModel.getSelectedReport();
      var publishString = "createEdit." + reportType + "Selected";
      PUBLISH(publishString);
    }
  });
  return PanelView;
});
