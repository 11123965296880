define('modules/reporting/campaigns/contextual/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "contextual",
    name: "reporting/campaigns/contextual",
    viewCfgs: {
      chartPanel: {
        model: null
      },
      chartTitle: {
        model: null
      },
      contextual: {},
      contextualBarChart: {
        model: null
      },
      contextualChart: {
        model: null
      },
      contextualLineChart: {
        model: null
      },
      controls: {
        model: null
      },
      grid: {
        model: null
      },
      panel: {
        model: null
      }
    }
  });
});
