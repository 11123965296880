define('modules/T1App/edit/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  instance = new T1Module({
    name: "T1App/edit",
    viewCfgs: {
      tabs: {}
    },
    defaultView: "tabs"
  });
  return instance;
});
