define('modules/advertiser/views/select',["jQuery", "T1", "T1View", "text!modules/advertiser/templates/select.html", "T1Selectbox"], function ($, T1, T1View, template) {
  return T1View.extend({
    template: template,
    dataEvents: {
      collection: {
        reset: "load"
      }
    },
    initialize: function (args) {
      var noSearch = this.noSearch = args.disableSearch;
      this.disabled = args.disabled;
      this.placeholder = args.placeholder || "Select One";
      this.eventName = args.eventName || "advertiser:select";
      this.selectBoxConfigs = this.getSBConfigs(noSearch);
      this.autoCompleteConfigs = this.getACConfigs(noSearch);
      noSearch && this.ensureDropdown();
      this.model && this.ensureModel(this.model, this.collection);
      if (args.bindOrg) {
        this.collection.updateOrgFilter();
      }
    },
    ensureDropdown: function () {
      this.collection.fetch();
    },
    ensureModel: function (model) {
      var hasntFetched = function () {
        return !model.get("name");
      };
      var prepCollAndLoad = $.proxy(function () {
        this.collection.add(model);
        this.load();
      }, this);
      this.selected = model.id;
      this.collection.add(model);
      if (hasntFetched()) {
        model.fetch().promise().then(prepCollAndLoad);
      }
    },
    getSBConfigs: function (noSearch) {
      return {
        searchFieldDisabled: !!noSearch,
        onChange: $.proxy(this.onSelectChange, this)
      };
    },
    getACConfigs: function (noAutoComp) {
      return noAutoComp ? null : {
        autoComplete: true,
        searchField: "name",
        onSearchRequest: $.proxy(this.onSearchRequest, this)
      };
    },
    onSelectChange: function (evt) {
      var index = evt.currentTarget.selectedIndex;
      var advertiserName = evt.currentTarget.options[0].text !== "" ? evt.currentTarget.options[0].text : evt.target.selectedOptions[0].text;
      T1.EventHub.publish(this.eventName, {
        id: evt.target.value,
        name: evt.currentTarget[index] ? evt.currentTarget[index].text : advertiserName
      });
    },
    onSearchRequest: function (opts) {
      this.collection.search.set($.extend({
        fetchRequired: true,
        pageLimit: 50
      }, opts));
    },
    makeSelectBox: function () {
      var $box = $(this.el).find(".select-input");
      var select = this.selectBoxConfigs;
      var autoComp = this.autoCompleteConfigs;
      T1.Selectbox($box, select, autoComp);
    },
    load: function () {
      this.render().then($.proxy(this.makeSelectBox, this));
    },
    serialize: function () {
      var options = this.collection.map($.proxy(function (model) {
        var out = model.toJSON();
        if (this.selected) {
          out.selected = this.selected.toString() === out.id.toString();
        }
        return out;
      }, this));
      this.noSearch && options.unshift({
        id: "all",
        name: "All"
      });
      return {
        placeholder: this.placeholder,
        disabled: this.disabled,
        options: options
      };
    }
  });
});
