define('modules/T1App/labs/main',["jQuery", "T1", "T1Module", "models/t1Lab"], function ($, T1, T1Module, T1LabModel) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "T1App/labs",
      viewCfgs: {
        list: {
          model: T1LabModel.getT1Lab()
        },
        "launch-labs": {
          model: T1LabModel.getT1Lab()
        }
      },
      defaultView: "list"
    });
  }
  return instance;
});
