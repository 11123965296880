define('modules/targeting/digital/views/panel',["jQuery", "Underscore", "T1UtilsAsync", "T1", "T1View", "T1Permissions", "text!modules/targeting/digital/templates/panel.html", "text!modules/targeting/digital/templates/panel_layout.html", "T1Layout", "collections/targetValues", "collections/nemoAttachments", "collections/nemoTargets", "utils/CampaignStrategyUtils"], function ($, _, T1UtilsAsync, T1, T1View, T1Permissions, template, panelLayout, T1Layout, TargetValues, NemoWURFLttachments, WurflTargetValues, Utils) {
  "use strict";

  const targetDimensionKey = Utils.names.targetDimensions;
  var isTargetDimensionReady = Utils.isStrategyTargetDimensionDataReady;
  const {
    newt1RedirectHandler: newt1RedirectHandler,
    updateCancelBtn: updateCancelBtn
  } = Utils;
  function onNemoWURFLAttachmentsFetch(self, deferral, isRefresh) {
    return function (attachmentData) {
      if (isTargetDimensionReady(self.strategyTargetDimensions) === false || !isRefresh) {
        isRefresh = true;
      }
      if (isTargetDimensionReady(self.strategyTargetDimensions) === true || isRefresh) {
        const targetsList = attachmentData.models.map(function (model) {
          const id = model.get("target_id");
          self.attachments[id] = model.get("restriction");
          return id;
        });
        if (targetsList.length) {
          self.wurflTargetValues.fetchOptions = {
            id: [].concat(targetsList)
          };
          self.wurflTargetValues.fetch({
            success: onWurfTargetValuesFetch(self, deferral)
          });
        } else {
          deferral.resolve();
        }
      } else {
        deferral.resolve();
      }
    };
  }
  function onWurfTargetValuesFetch(self, deferral) {
    return function (data) {
      const regex = /^WURFL\/Brand\/([^/]+)\/Device\/([^/]+)/;
      const parentRegex = /^WURFL\/Brand\/([^/]+)/;
      const osRegex = /^WURFL\/OS\/([^/]+)\/OS[ ]Version\/([^/]+)/;
      const osParentRegex = /^WURFL\/OS\/([^/]+)/;
      data.models.forEach(addPathNamesToModels(self, regex, parentRegex, osRegex, osParentRegex));
      deferral.resolve(data.toJSON());
    };
  }
  function addPathNamesToModels(self, regex, parentRegex, osRegex, osParentRegex) {
    return function (model) {
      const nameIdx = 2;
      const name = model.get("name");
      const osRegexName = name.match(osRegex);
      const osParentRegexName = name.match(osParentRegex);
      const regexName = name.match(regex);
      const parentRegexName = name.match(parentRegex);
      let hasOsVersionData = false;
      if (regexName) {
        model.set({
          full_path_name: `${regexName[1]} &raquo; ${regexName[nameIdx]}`,
          name: regexName[nameIdx],
          parent_name: regexName[1]
        });
      } else if (parentRegexName) {
        model.set({
          name: parentRegexName[1]
        });
      }
      if (osRegexName) {
        hasOsVersionData = true;
        model.set({
          full_path_name: `${osRegexName[1]} &raquo; ${osRegexName[nameIdx]}`,
          name: osRegexName[nameIdx],
          parent_name: osRegexName[1]
        });
      } else if (osParentRegexName) {
        hasOsVersionData = true;
        model.set({
          name: osParentRegexName[1]
        });
      }
      model.set({
        action: self.attachments[model.get("id")].toLowerCase(),
        parent_value_id: model.get("parent_id"),
        dimension_code: hasOsVersionData ? "WURFL/OS" : "WURF"
      });
      addDataToStrategyTargetDimension(self, model, hasOsVersionData);
    };
  }
  function addDataToStrategyTargetDimension(self, model, hasOsVersionData) {
    const dimensionCode = hasOsVersionData ? "WURFL/OS" : "WURF";
    if (self.strategyTargetDimensions.get("target_dimensions").get(dimensionCode)) {
      self.strategyTargetDimensions.get("target_dimensions").get(dimensionCode).get("target_value").add(model.toJSON());
    }
  }
  function processAjaxResponse(response) {
    return {
      jsonData: response,
      statusCode: "ok"
    };
  }
  function postAttachments(self, deferral, postObj) {
    self.nemoWURFLattachments.sync("POSTJSON", {
      data: postObj
    }, {
      stringify: true,
      sendData: true,
      dataType: "json",
      processAjaxResponse: processAjaxResponse,
      success: () => deferral.resolve()
    });
  }
  function processAttachmentArray(self, deferral, action, postObj) {
    const arr = getAttachmentsIDs(self, action);
    if (arr.length) {
      postObj["target.id"] = arr;
      postObj.restriction = action.toUpperCase();
      postAttachments(self, deferral, postObj);
    } else {
      deferral.resolve();
    }
  }
  function getAttachmentsIDs(self, action) {
    const saveWurfModels = self.sharedCollection.get("WURF").get("target_value").models;
    const saveOSVersionModels = self.sharedCollection.get("WURFL/OS").get("target_value").models;
    const saveModels = saveWurfModels.concat(saveOSVersionModels);
    return saveModels.filter(model => model.get("action") === action).map(model => model.id);
  }
  function deleteWURFLAttachments(self, deferrals, postObj) {
    self.nemoWURFLattachments.sync("DELETE", {
      data: {}
    }, {
      params: {
        dimension_code: "WURF",
        strategy_id: self.strategy.id
      },
      httpAction: "delete",
      dataType: "json",
      processAjaxResponse: processAjaxResponse,
      success: () => {
        saveAttachments(self, deferrals, postObj);
      }
    });
  }
  function saveAttachments(self, deferrals, postObj) {
    Object.keys(deferrals).forEach(function (action) {
      processAttachmentArray(self, deferrals[action], action, postObj);
    });
  }
  function addBackToSharedCollection(data, self) {
    const osVersionData = data.filter(item => item.dimension_code === "WURFL/OS");
    const wurfData = data.filter(item => item.dimension_code === "WURF");
    self.sharedCollection.get("WURFL/OS").get("target_value").add(osVersionData);
    self.sharedCollection.get("WURF").get("target_value").add(wurfData);
  }
  const DigitalTargetingView = T1View.extend({
    template: template,
    viewMode: "browse",
    filterConfigs: {
      filterClass: "digitalTargets",
      filterOptions: [{
        text: "Device Type",
        value: "DVCE"
      }, {
        text: "Device Model",
        value: "WURF"
      }, {
        text: "Inventory Type",
        value: "INVT"
      }, {
        text: "Connection Speed",
        value: "CSPD"
      }, {
        text: "ISP",
        value: "ISPX"
      }, {
        text: "Browser",
        value: "BSER"
      }, {
        text: "OS Version",
        value: "WURFL/OS"
      }]
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": function () {
        this.initializeDataEventsAndLayout();
        this.load();
      },
      "tech-targeting:setFormElementStatus": "checkFormElementsChangedStatus",
      "change:formStatus": function (hasChanged) {
        this.isFormElementsChanged = hasChanged;
      }
    },
    featureFlags: {
      hasWurfl: T1Permissions.check("feature", "use_wurfl_targeting"),
      hasOSVersion: T1Permissions.check("feature", "use_os_version_targeting")
    },
    initialize: function ({
      strategy: strategy,
      strategyTargetDimensions: strategyTargetDimensions
    }) {
      this.strategy = strategy;
      const technologyTabConfig = {
        tabName: "Technology",
        tabId: "technology",
        isBatch: strategy.get("supply_type") === "BATCH",
        filterClass: "digitalTargets"
      };
      this.filterConfigs = Utils.getFilterConfigs(technologyTabConfig);
      this.preventDoubleBind = true;
      this.init = true;
      this.attachments = {};
      this.strategyMediaType = strategy.get("media_type");
      this.collection = new TargetValues([], {
        dimension: "DVCE"
      });
      this.strategyTargetDimensions = strategyTargetDimensions;
      this.nemoWURFLattachments = new NemoWURFLttachments();
      this.wurflTargetValues = new WurflTargetValues();
      this.initializeDataEventsAndLayout();
      this.bindViewEvents();
    },
    fetchWURFLData: function (isRefresh) {
      var deferral = T1UtilsAsync.makeDeferral();
      this.nemoWURFLattachments.fetch({
        fetchOptions: {
          strategy_id: this.strategy.id,
          dimension: "WURF"
        },
        success: onNemoWURFLAttachmentsFetch(this, deferral, isRefresh)
      });
      return deferral.promise;
    },
    reload: function () {
      this.strategyTargetDimensions.fetch({
        success: () => {
          this.fetchWURFLData().then(() => {
            this.prepareSharedCollection();
            if (!this.layout && isTargetDimensionReady(this.strategyTargetDimensions) === true) {
              this.initializeDataEventsAndLayout();
            }
            T1View.prototype.reload.call(this);
          });
        }
      });
    },
    checkFormElementsChangedStatus: function ({
      badgesData: badgesData
    }) {
      let binData = [];
      const dimCodes = ["CSPD", "ISPX", "BSER", "INVT", "DVCE", "WURF", "WURFL/OS"];
      const models = this.sharedCollection ? this.sharedCollection.models : undefined;
      function reducer(arr, model) {
        if (dimCodes.includes(model.get("code"))) {
          arr.push(model.get("target_value").models);
        }
        return arr;
      }
      if (models) {
        const targetValueModels = models.reduce(reducer, []);
        binData = targetValueModels.filter(targetModels => targetModels && targetModels.length).flat();
      }
      this.isFormElementsChanged = badgesData.length !== binData.length;
    },
    initializeDataEventsAndLayout: function () {
      this.targetDimensions = this.strategyTargetDimensions.get(targetDimensionKey);
      this.initLayout(this.targetDimensions);
      T1.EventHub.publish("tech-targeting:updateTempColl");
      this.addDataEvent({
        targetDimensions: {
          reset: function () {
            this.isFormElementsChanged = false;
          }
        }
      });
      this.isFormElementsChanged = false;
      this.removeEventHubEvents(["strategyTargetDimension:reset"]);
    },
    load: function () {
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        if (this.layout) {
          const delay = 200;
          this.layout.load();
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "technology_targeting"
          });
          if (this.init) {
            this.fetchWURFLData().then(wurflData => {
              if (wurflData) {
                this.strategyTargetDimensions.fetch({
                  success: function (dimensionData) {
                    const osVersionData = wurflData.filter(item => item.dimension_code === "WURFL/OS");
                    const wurfData = wurflData.filter(item => item.dimension_code === "WURF");
                    dimensionData.get("target_dimensions").get("WURF").get("target_value").add(wurfData ? wurfData : []);
                    dimensionData.get("target_dimensions").get("WURFL/OS").get("target_value").add(osVersionData ? osVersionData : []);
                    T1.EventHub.publish("strategyTargetingLoader:stop");
                  }
                });
                this.isFormElementsChanged = false;
              } else {
                T1.EventHub.publish("strategyTargetingLoader:stop");
              }
              this.initializeDataEventsAndLayout();
            });
            this.init = false;
          } else {
            T1.EventHub.publish("strategyTargetingLoader:stop");
          }
          setTimeout(() => {
            this.isFormElementsChanged = false;
          }, delay);
          if (window.hybridWorkflowParams.isHybrid) {
            updateCancelBtn(this.strategy.get("campaign"));
            this.$breadcrumbLink = $('.redirect-new-t1[href^="http"]');
            this.$cancelBtn = $(".cancelNewT1");
          }
        }
      });
    },
    unload: function () {
      T1.EventHub.publish("strategyTargetingLoader:start");
      if (window.hybridWorkflowParams.isHybrid) {
        const {
          $breadcrumbLink: $breadcrumbLink,
          $cancelBtn: $cancelBtn
        } = this;
        if ($breadcrumbLink && $cancelBtn) {
          $breadcrumbLink.off("click", newt1RedirectHandler);
          $cancelBtn.off("click", newt1RedirectHandler);
        }
      }
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        const strategyName = this.strategy.get("name");
        if (window.hybridWorkflowParams.isHybrid) {
          const {
            $breadcrumbLink: $breadcrumbLink,
            $cancelBtn: $cancelBtn
          } = this;
          if ($breadcrumbLink && $cancelBtn) {
            const handlerProps = {
              name: strategyName,
              scope: this
            };
            $breadcrumbLink.off("click", newt1RedirectHandler);
            $breadcrumbLink.on("click", handlerProps, newt1RedirectHandler);
            $cancelBtn.off("click", newt1RedirectHandler);
            $cancelBtn.on("click", handlerProps, newt1RedirectHandler);
          }
        }
        this.openUnloadAlertDialog({
          name: strategyName || "untitled strategy"
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    onDontSave: function () {
      var strategyTargetDimensions = this.strategyTargetDimensions;
      this.unbind();
      strategyTargetDimensions.fetch();
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    initLayout: function (targetDimensions) {
      var collection = this.collection;
      var viewConfigs = {
        strategy: this.strategy,
        collection: collection,
        sharedCollection: targetDimensions,
        partialCollection: this.strategyTargetDimensions.get("partial"),
        filterConfigs: this.filterConfigs
      };
      var filterClass = viewConfigs.filterConfigs.filterClass;
      var filtersOptions = {
        collection: collection,
        filterClass: filterClass,
        selectOptions: viewConfigs.filterConfigs.filterOptions,
        filterCallback: function (term) {
          const searchInputFilterConfig = {
            searchTerm: term,
            inputBox: this.inputBox,
            event: this.event,
            inputParent: this.inputParent,
            context: this
          };
          if (Utils.validateSearchFilterMinCharacter(searchInputFilterConfig)) {
            return;
          }
          collection.trigger("search", {
            term: term,
            type: self.$(`.${filterClass}`).val()
          });
        },
        clearFilterCallback: function () {
          collection.trigger("search.clear");
        }
      };
      this.sharedCollection = targetDimensions;
      this.prepareSharedCollection();
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panelLayout,
        layout: {
          ".filters-c": [{
            module: "shared",
            viewType: "filter",
            options: filtersOptions
          }],
          ".browser-c": [{
            module: "targeting/digital",
            viewType: "browse",
            options: viewConfigs
          }],
          ".bins-column": [{
            module: "targeting/digital",
            viewType: "bins",
            options: viewConfigs
          }]
        }
      });
    },
    prepareSharedCollection: function () {
      const {
        strategyTargetDimensions: strategyTargetDimensions,
        sharedCollection: sharedCollection
      } = this;
      var targetDimensions = strategyTargetDimensions.get(targetDimensionKey);
      if (!(targetDimensions && targetDimensions.length)) {
        strategyTargetDimensions.set({
          target_dimensions: [],
          partial: []
        });
        targetDimensions = strategyTargetDimensions.get(targetDimensionKey);
      }
      targetDimensions.each(function (dimension) {
        sharedCollection.add(dimension.get("target_value").models);
        sharedCollection.INCLUDE_OP = dimension.get("INCLUDE_OP");
        sharedCollection.EXCLUDE_OP = dimension.get("EXCLUDE_OP");
      });
      this.targetDimensions = targetDimensions;
      this.sharedCollection = sharedCollection;
    },
    saveWURFLAttachments: function () {
      const deferrals = {
        exclude: T1UtilsAsync.makeDeferral(),
        include: T1UtilsAsync.makeDeferral()
      };
      const promises = [deferrals.include.promise, deferrals.exclude.promise];
      const postObj = {
        operator: "OR",
        strategy_id: this.strategy.id
      };
      this.nemoWURFLattachments.isPaginated = false;
      this.nemoWURFLattachments.processAjaxResponse = processAjaxResponse;
      deleteWURFLAttachments(this, deferrals, postObj);
      return T1UtilsAsync.linkPromises(promises);
    },
    postData: function (formValues, successCallback, statusInvalidCallback) {
      var dimensionsSave = T1UtilsAsync.makeDeferral();
      var wurflSave = T1UtilsAsync.makeDeferral();
      const onStrategyFetchSuccess = () => {
        this.isFormElementsChanged = false;
        successCallback();
        T1.EventHub.publish("strategy-create-edit:reloadSubViews");
        T1.EventHub.publish("tech-targeting:updateTempColl");
        T1.EventHub.publish("updateDigitalBins");
      };
      const onTargetDimensionsFetchSuccess = function (wurflData, self) {
        return dimensionsData => {
          dimensionsData.get("target_dimensions").get("WURF").get("target_value").add(wurflData ? wurflData : []);
          self.wurflData = wurflData;
          if (wurflData) {
            addBackToSharedCollection(wurflData, self);
          }
          self.strategy.fetch({
            success: onStrategyFetchSuccess
          });
          dimensionsSave.resolve();
        };
      };
      const onTargetValuesSuccess = () => {
        this.fetchWURFLData(true).then(wurflData => {
          this.strategyTargetDimensions.fetch({
            success: onTargetDimensionsFetchSuccess(wurflData, this)
          });
        });
      };
      this.saveWURFLAttachments().then(() => {
        this.strategyTargetDimensions.saveTargetValues(onTargetValuesSuccess, statusInvalidCallback, undefined, undefined, this);
        wurflSave.resolve();
      });
    }
  });
  return T1.Form.ViewUtils.extend(DigitalTargetingView);
});
