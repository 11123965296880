define('modules/shared/views/searchBoxGrid',["jQuery", "T1", "T1View", "collections/searchResults", "text!modules/shared/templates/searchBoxGrid.html", "T1GridView"], function ($, T1, T1View, SearchResults, template, createGrid) {
  var StrategySearchBoxGrid;
  StrategySearchBoxGrid = T1View.extend({
    partials: {
      listItem: template
    },
    headers: [{
      name: "name",
      label: "Name"
    }],
    truncateConfig: {
      ".truncate": {
        lines: 1
      }
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 100,
      loaderOpts: {
        target: ".content-holder",
        contentClass: "search-loader-no-background",
        action: "append",
        spinnerColor: "#383838"
      }
    },
    events: {
      "click .item": "onItemClick"
    },
    perfectScrollConfig: {
      allowWheelPropagation: false
    },
    initialize: function (args) {
      this.collection = args.searchCollection ? args.searchCollection : new SearchResults(null, args.extendedOptions);
      if (args.urlFilterValue) {
        this.collection.urlFilter.set(args.urlFilterValue);
      }
      this.searchResultFormatter = args.extendedOptions.searchResultFormatter || this.searchResultFormatter;
      this.partials.listItem = args.extendedOptions.searchBoxItemTemplate || template;
      this.eventDispatcher = args.eventDispatcher;
      this.setupEventDispatcher();
      this.headers = args.extendedOptions.searchResultHeaders || this.headers;
      this.hideNoResultMessage = true;
    },
    setupEventDispatcher: function () {
      var ed = $(this.eventDispatcher);
      var $proxy = $.proxy;
      ed.on("searchBox:loadAll", $proxy(this.loadAll, this));
      ed.on("searchBox:search", $proxy(this.search, this));
      ed.on("searchBox:clearAll", $proxy(this.clearAll, this));
    },
    load: function () {
      var self = this;
      self.render().then(function () {
        self.adjustContentHeight();
        self.setContentVisibility(true);
        self.updateScrollBar();
      });
    },
    setContentVisibility: function (setVisible) {
      var $content = $(this.el).find(".w-body.content");
      var val = setVisible === true ? "visible" : "hidden";
      $content.css({
        visibility: val
      });
    },
    adjustContentHeight: function () {
      var maxHeight = 345;
      var minHeight = 140;
      var $el = $(this.el);
      var $holder = $el.find(".w-body");
      var winHeight = $(window).height();
      var $parent = $el.parent().parent();
      var parentPos;
      var projectedBottom;
      var heightToUse = minHeight;
      var $resultHolder;
      function ensureMinMaxHeight(height) {
        var result = height;
        if (result < minHeight) {
          result = minHeight;
        } else if (result > maxHeight) {
          result = maxHeight;
        }
        return result;
      }
      if ($holder.length <= 0 || $parent.length <= 0) {
        return;
      }
      parentPos = $parent.offset();
      $resultHolder = $parent.find(".search-result-holder");
      heightToUse = $parent.find(".content-c").height() + 20;
      heightToUse = ensureMinMaxHeight(heightToUse);
      projectedBottom = parentPos.top + heightToUse + 56;
      if (projectedBottom > winHeight) {
        heightToUse = heightToUse - (projectedBottom - winHeight) - 20;
        heightToUse = ensureMinMaxHeight(heightToUse);
      }
      $holder.height(heightToUse);
      $resultHolder.height(heightToUse + 29);
    },
    onItemClick: function (event) {
      var id = $(event.currentTarget).closest(".item").data("id");
      var ed = $(this.eventDispatcher);
      $(document).mousedown();
      this.preventDefault(event);
      ed.trigger({
        type: "searchItem:click",
        id: id
      });
    },
    search: function (e) {
      var self = this;
      var collection = self.collection;
      var isValidSearchTerm;
      var term = e.term;
      if (self.ajx) {
        self.ajx.abort();
      }
      isValidSearchTerm = collection.search.set({
        params: [{
          term: term,
          searchField: "name|id"
        }],
        fetchRequired: false
      });
      collection.reset([], {
        silent: true
      });
      self.setContentVisibility(false);
      self.adjustContentHeight();
      self.setSearchLoader();
      self.hideNoResultMessage = false;
      if (isValidSearchTerm) {
        collection.fetch({
          success: function (data) {
            self.load();
          }
        });
      } else {
        self.load();
      }
    },
    loadAll: function (e) {
      var self = this;
      var collection = self.collection;
      collection.search.clear();
      collection.reset([], {
        silent: true
      });
      self.setContentVisibility(false);
      self.adjustContentHeight();
      self.setSearchLoader();
      self.hideNoResultMessage = false;
      self.ajx = collection.fetch({
        success: function () {
          self.load();
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
    },
    clearAll: function () {
      var collection = this.collection;
      collection.search.clear();
      collection.reset([], {
        silent: true
      });
      this.load();
    },
    searchResultFormatter: function (models) {
      var output = [];
      $.each(models, function (index, model) {
        output.push({
          name: model.get("name"),
          isActive: model.get("status") === "1",
          id: model.id
        });
      });
      return output;
    },
    serialize: function (inputCollection) {
      var resultJson = {};
      var models;
      models = inputCollection ? inputCollection.models : this.collection.models;
      resultJson.list = this.searchResultFormatter(models);
      resultJson.hideNoResultMessage = this.hideNoResultMessage;
      return resultJson;
    }
  });
  return createGrid(StrategySearchBoxGrid);
});
