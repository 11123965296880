define('models/creativesStrategy',["jQuery", "T1", "T1Model", "Backbone", "collections/strategyConcepts"], function ($, T1, T1Model, Backbone, StrategyConcepts) {
  T1.Models.CreativesStrategy = T1Model.extend({
    url: "strategies/",
    name: "strategy",
    fixture: "-strategy",
    relations: [{
      type: Backbone.HasMany,
      key: "strategyConcepts",
      relatedModel: "StrategyConcept",
      collectionType: "StrategyConcepts",
      includeInJSON: false,
      reverseRelation: {
        key: "strategy",
        includeInJSON: false
      }
    }],
    fetch: function (opts) {
      T1Model.prototype.fetch.call(this, opts);
    }
  });
  return T1.Models.Strategy;
});
