define('modules/shared/configs/charts/donutChartConfigEmpty',[], function () {
  "use strict";

  const config = {
    hideCredits: true,
    data: [{
      color: "#c3c3c3",
      disabled: true,
      label: "dummyData",
      opacity: 1,
      tooltip: "",
      value: 100
    }],
    innerRadius: 47,
    radius: 66,
    series: [{
      colors: {
        list: []
      },
      dataFields: {
        category: "label",
        value: "value"
      },
      hiddenState: {
        properties: {
          endAngle: -90
        }
      },
      labels: {
        disabled: true
      },
      legend: {
        disabled: true
      },
      slices: {
        propertyFields: {
          fill: "color",
          fillOpacity: "opacity",
          stroke: "color",
          strokeDasharray: "strokeDasharray",
          tooltipText: "tooltip"
        },
        states: {
          active: {
            properties: {
              radius: 66,
              shiftRadius: 0
            }
          },
          hover: {
            properties: {
              radius: 66,
              scale: 1
            }
          }
        }
      },
      ticks: {
        disabled: true
      },
      tooltip: {
        disabled: true
      },
      type: "PieSeries"
    }]
  };
  return {
    copy: () => JSON.parse(JSON.stringify(config))
  };
});
