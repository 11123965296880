define('models/bulkeditor',["jQuery", "T1", "Backbone", "T1Model"], function ($, T1, Backbone, T1Model) {
  T1.Models.Bulkeditor = T1Model.extend({
    url: "campaigns",
    createNewEditor: function (opts) {
      var self = this;
      var postObj;
      var successCallback = opts.success;
      var saveOpts = {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: function (newModel, attributes) {
          self.set({
            editorId: attributes.entity.editor_id
          });
          self.editorReady = true;
          successCallback();
        },
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      };
      postObj = [];
      this.url = "campaigns/" + this.get("parentId") + "/bulk_edits";
      this.wrapPostObj = true;
      this.createNewBulkeditor = true;
      this.stagingActions = false;
      this.save(postObj, saveOpts);
      return this;
    },
    executeBulkedit: function (opts) {
      var saveOpts = {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: function () {},
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      };
      this.id = undefined;
      this.stagingActions = false;
      if (opts.success) {
        saveOpts.success = opts.success;
      }
      this.url = "campaigns/" + this.get("parentId") + "/bulk_edits/" + this.get("editorId") + "/execute";
      this.wrapPostObj = true;
      this.save([], saveOpts);
    },
    getStatus: function (opts) {
      var self = this;
      var editorId = this.get("editorId");
      var campaignId = this.get("parentId");
      var errors;
      this.url = "campaigns/" + campaignId + "/bulk_edits/" + editorId;
      this.fetch({
        success: function () {
          if (self.get("errors")) {
            errors = JSON.parse(self.get("errors"));
            self.set({
              executionErrors: errors
            });
          }
          self.getStagedStrategies(opts);
        },
        postJson: true,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      });
      return this;
    },
    checkExecutionStatus: function (successCallback, invalidCallback) {
      var self = this;
      var editorId = this.get("editorId");
      var campaignId = this.get("parentId");
      var statusEntity;
      this.url = "campaigns/" + campaignId + "/bulk_edits/" + editorId + "/status";
      this.fetch({
        success: function (newModel, attributes) {
          statusEntity = attributes.entity;
          if (statusEntity.execution_status === "finished") {
            if (statusEntity.execution_errors && statusEntity.execution_errors.length > 0) {
              self.set({
                executionErrors: statusEntity.execution_errors
              });
              T1.EventHub.publish("bulkEdit:ErroredExecution");
              if (invalidCallback) {
                invalidCallback();
              }
            } else {
              T1.EventHub.publish("bulkEdit:FinishedExecution");
              if (successCallback) {
                successCallback();
              }
            }
          } else {
            setTimeout(function () {
              self.checkExecutionStatus(successCallback, invalidCallback);
            }, 3e3);
          }
        },
        postJson: true,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      });
      return this;
    },
    getStagedStrategies: function (opts) {
      var self = this;
      var strategies = [];
      this.url = "campaigns/" + this.get("parentId") + "/bulk_edits/" + this.get("editorId") + "/targets";
      this.id = undefined;
      this.fetch({
        success: function (newModel, attributes) {
          if (attributes && attributes.entities && attributes.entities.entity && attributes.entities.entity[0] && attributes.entities.entity[0].entities) {
            if (attributes.entities.entity[0].entities.length) {
              $.each(attributes.entities.entity[0].entities, function () {
                strategies.push(this.entity_id.toString());
              });
            } else {
              strategies.push(attributes.entities.entity[0].entities.entity_id.toString());
            }
          }
          self.set({
            stagedStrategies: strategies
          });
          self.getStagedActions(opts);
        },
        postJson: true,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      });
    },
    stageStrategies: function (opts) {
      var postObj;
      var allStrats = false;
      var saveOpts = {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: function () {},
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      };
      this.id = undefined;
      this.stagingActions = false;
      if (opts.success) {
        saveOpts.success = opts.success;
      }
      if (opts.strategies[0] === "all") {
        allStrats = true;
        opts.strategies = [];
      }
      postObj = [{
        collection: "strategies",
        entities: opts.strategies,
        parent_collection: "campaigns",
        parent_id: parseInt(this.get("parentId")),
        all: allStrats,
        overwrite_existing_targets: true
      }];
      this.url = "campaigns/" + this.get("parentId") + "/bulk_edits/" + this.get("editorId") + "/targets";
      this.wrapPostObj = true;
      this.save(postObj, saveOpts);
    },
    getStagedActions: function (opts) {
      var self = this;
      var params;
      var onSuccess = opts.success || $.noop;
      var stagedActions = [];
      this.url = "campaigns/" + this.get("parentId") + "/bulk_edits/" + this.get("editorId") + "/actions";
      this.id = undefined;
      if (opts.tag) {
        params = {
          tag: opts.tag
        };
      }
      this.fetch({
        params: params,
        success: function (newModel, attributes) {
          if (attributes && attributes.entities && attributes.entities.entity) {
            if (attributes.entities.entity.length) {
              $.each(attributes.entities.entity, function () {
                stagedActions.push(this);
              });
            } else {
              stagedActions.push(attributes.entities.entity);
            }
          }
          self.set({
            stagedActions: stagedActions
          });
          self.editorReady = true;
          onSuccess();
        },
        postJson: true,
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      });
    },
    getPostDataForStagingActions: function (data) {
      var postObj = [];
      var tempData;
      var action = data.bulkeditAction;
      var tag = data.tag;
      var collection = data.collection;
      $.each(data.ids, function (index, value) {
        tempData = {};
        tempData.collection = "strategies";
        tempData.tag = tag;
        tempData[action] = {};
        tempData[action].collection = collection;
        tempData[action].id = value.id;
        if (collection === "pixel_bundles" && action === "overwrite") {
          tempData[action].operator = value.pixelAction === "include" ? value.includeOperator : "OR";
        }
        if (value.segmentAction) {
          tempData[action].exclude = value.segmentAction === "include" ? false : true;
        }
        if (value.pixelAction) {
          tempData[action].exclude_flag = value.pixelAction === "include" ? false : true;
        }
        if (value.update) {
          delete tempData[action];
          tempData.update = value.update;
        }
        if (value.targetOp) {
          tempData[action].target_op = value.targetOp;
        }
        if (value.percent && value.weighting) {
          tempData[action].percent = value.percent;
          tempData[action].weighting = value.weighting;
        }
        postObj.push(tempData);
      });
      return postObj;
    },
    stageActions: function (data, opts) {
      var self = this;
      var postObj;
      var saveOpts = {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        success: function () {},
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: resp.status.code || "ok"
          };
        }
      };
      this.id = undefined;
      this.stagingActions = false;
      if (opts.success) {
        saveOpts.success = opts.success;
      }
      this.url = "campaigns/" + this.get("parentId") + "/bulk_edits/" + this.get("editorId") + "/actions";
      this.wrapPostObj = true;
      if (data.deleteCallOnly) {
        this.save([], {
          httpAction: "delete",
          params: {
            tag: data.tag
          },
          success: function (result) {
            opts.success(result);
          }
        });
        return;
      }
      postObj = this.getPostDataForStagingActions(data);
      this.save([], {
        httpAction: "delete",
        params: {
          tag: data.tag
        },
        success: function () {
          if (data.deleteZipcodes) {
            self.save([], {
              httpAction: "delete",
              params: {
                tag: "zipcodes"
              },
              success: function () {
                self.stagingActions = true;
                self.save(postObj, saveOpts);
              }
            });
          } else {
            self.stagingActions = true;
            self.save(postObj, saveOpts);
          }
        }
      });
    }
  });
  return T1.Models.Bulkeditor;
});
