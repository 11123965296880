define('T1Alert',["jQuery", "T1", "Hogan", "text!templates/custom_alert_dialog.html"], function ($, T1, Hogan, fileTemplate) {
  return function T1Alert() {
    var template;
    var data;
    var dialog;
    function initialize(opts) {
      template = Hogan.compile(fileTemplate);
      data = {
        title: opts.title || "Title Here",
        bodyMsg: opts.bodyMsg || "Body message would appear here.",
        buttons: opts.buttons || defaultButtonsConfig(),
        icon: opts.icon || "warning",
        showCallback: opts.showCallback || $.noop,
        hideCallback: opts.hideCallback || $.noop
      };
    }
    function defaultButtonsConfig() {
      return btnsArray = [{
        text: "OK",
        class: "save",
        click: function (e) {
          var $dialog = $(this);
          $dialog.dialog("close");
        }
      }];
    }
    function show() {
      var self = this;
      var alertMessage = template.render(data);
      var showCallback = data.showCallback;
      dialog = $(alertMessage);
      dialog.dialog({
        autoOpen: true,
        modal: true,
        dialogClass: "w-customAlert",
        buttons: data.buttons,
        close: function () {
          self.destroy();
        }
      });
      showCallback();
    }
    function hide() {
      var hideCallback = data.hideCallback;
      destroy();
      hideCallback();
    }
    function destroy() {
      dialog.remove();
      dialog.dialog("destroy");
    }
    return {
      initialize: initialize,
      show: show,
      hide: hide,
      destroy: destroy
    };
  };
});
