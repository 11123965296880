define('modules/campaign/bulkedit/targeting/location/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!../templates/bin_group_header.html", "text!../templates/bin_title.html", "text!../templates/bin_group_item.html", "text!../templates/bin_footer.html", "modules/strategy/views/helpers/defaultLocationPersistenceHelper"], function ($, _, T1, BinsView, bin_group_header, bin_title, bin_group_item, bin_footer, DefaultLocPersistHelper) {
  "use strict";

  const bulkEditActions = Object.freeze(["add", "remove", "overwrite"]);
  const LocationBinsView = BinsView.extend({
    type: "Locations",
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      }
    },
    partials: {
      overall_header: bin_footer,
      bin_group_name: bin_group_header,
      bin_title: bin_title,
      bin_group_item: bin_group_item
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      onDropEvent: "bins:droppedLocation",
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    locationCodes: {
      region: "REGN",
      dma: "DMAX",
      postalCode: "USZC"
    },
    events: {
      "changed #edit-options": "onEditOptionsChange"
    },
    initialize: function ({
      collection: collection,
      sharedCollection: sharedCollection,
      filterConfigs: initConfigs,
      partialCollection: partialCollection
    }) {
      var filterConfigs;
      var filters = [];
      this.collection = collection;
      this.sharedCollection = sharedCollection;
      this.filterConfigs = filterConfigs = $.extend(true, {}, initConfigs);
      filterConfigs.filterOptions.push({
        text: "Postal Codes",
        value: "USZC"
      });
      $.each(filterConfigs.filterOptions, function (i, option) {
        filters.push(option.value);
      });
      this.filters = filters;
      this.partialCollection = partialCollection;
      this.refreshDefaultLocations();
    },
    onEditOptionsChange: function (event) {
      var value = event.currentTarget.value;
      if (bulkEditActions.includes(value)) {
        this.sharedCollection.bulkeditAction = value;
      }
      T1.EventHub.publish("strategyBulkEditLocations:bulkeditActionChanged");
    },
    selectBulkEditOptions: function () {
      var bulkEditAction = this.sharedCollection.bulkeditAction;
      const $bulkEditGroup = this.$("#edit-options");
      $bulkEditGroup.find(`[value=${bulkEditAction}]`)[0].checked = true;
    },
    disableAddAndRemoveButtons: function (disableStatus) {
      const $bulkEditAdd = this.$("#edit-options-add");
      const $bulkEditRemove = this.$("#edit-options-remove");
      if (disableStatus) {
        $bulkEditAdd.attr("disabled", "true");
        $bulkEditRemove.attr("disabled", "true");
      } else {
        $bulkEditAdd.removeAttr("disabled");
        $bulkEditRemove.removeAttr("disabled");
      }
    },
    checkForDisablingBulkeditActionButtons: function () {
      if (!this.sharedCollection.length && this.zipcodesfile || this.sharedCollection.length === 1 && this.sharedCollection.models[0].get("name") === "postal code") {
        this.sharedCollection.bulkeditAction = "overwrite";
        this.selectBulkEditOptions();
        this.disableAddAndRemoveButtons(true);
      } else {
        this.disableAddAndRemoveButtons(false);
      }
    },
    refreshDefaultLocations: function () {
      var arrAll = [];
      const defaultLocationPersister = new DefaultLocPersistHelper();
      defaultLocationPersister.init();
      const defaultSettings = defaultLocationPersister.getSettings();
      const arrCategories = defaultSettings.include.split("|").concat(defaultSettings.exclude.split("|"));
      _.each(arrCategories, function (categoryText) {
        if (categoryText) {
          arrAll = arrAll.concat(categoryText.split(","));
        }
      });
      this.allDefaultLocations = arrAll;
    },
    eventhubEvents: {
      uploadzipcodes: function (data) {
        if (data) {
          this.removePostalCodes();
          this.zipcodesfile = data.filename;
          this.zipcodecount = data.count;
          this.zipcodeaction = data.action;
        } else {
          this.removePostalCodes();
          this.zipcodesfile = undefined;
          this.zipcodecount = undefined;
          this.zipcodeaction = undefined;
        }
        this.updateBins();
      },
      defaultLocationSaved: function () {
        this.refreshDefaultLocations();
        this.updateBins();
      }
    },
    removePostalCodes: function () {
      const postalCodes = this.sharedCollection.filter(model => model.get("dimension_code") === "USZC");
      this.sharedCollection.remove(postalCodes, {
        silent: true
      });
    },
    removeItem: function (event) {
      var $item = $(event.currentTarget).closest(".item");
      var id = $item.data("id");
      var sharedCollection = this.sharedCollection;
      event.preventDefault();
      event.stopPropagation();
      if (id === "placeholder") {
        this.removePostalCodes();
        delete this.zipcodecount;
        delete this.zipcodesfile;
        delete this.zipcodeaction;
        this.updateBins();
        T1.EventHub.publish("clearzipcodes", {
          fileName: this.zipcodesfile || "postalcodes.csv"
        });
      } else {
        sharedCollection.remove(id);
      }
    },
    load: function () {
      this.updateBins();
      T1.EventHub.publish("strategyTargetingLoader:stop");
      this.render().then(() => {
        if (!this.sharedCollection.bulkeditAction || !this.sharedCollection.length) {
          this.sharedCollection.bulkeditAction = "add";
        }
        this.selectBulkEditOptions();
        this.checkForDisablingBulkeditActionButtons();
      });
    },
    updateBins: function () {
      var binsArry = ["include", "exclude"];
      for (let i = 0; i < binsArry.length; i++) {
        this.updateAction = binsArry[i];
        this.renderPartial(`.${this.updateAction}-bin .content-container`, undefined, "html", ".content-container");
      }
      this.updateAction = null;
      this.checkForDisablingBulkeditActionButtons();
      return true;
    },
    unload: function () {
      delete this.zipcodecount;
      delete this.zipcodesfile;
      delete this.zipcodeaction;
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    mapItems: function (items, updateAction) {
      var self = this;
      const {
        sharedCollection: sharedColl,
        partialCollection: partialColl,
        filters: filters,
        allDefaultLocations: defaultLocs
      } = this;
      var filterOptions = this.filterConfigs.filterOptions;
      var defaultGroupObj = {
        bin_show_group_name: true,
        count: "any",
        showGroupNameOnly: true,
        bin_group_name: "",
        groupItems: []
      };
      var result = [];
      var groups = {};
      function ensureMaxLength(fullPath) {
        var goalLength = 57;
        return fullPath.length > goalLength ? `...${fullPath.substr(-goalLength)}` : fullPath;
      }
      function formatPath(path, isBolded) {
        var displayed = path.replace(/\||\//g, "&raquo");
        if (isBolded) {
          return T1.Utils.boldLastPartOfFullPath({
            fullPath: displayed,
            separator: "&raquo",
            searchTermToBeHighlighted: null
          });
        }
        return displayed;
      }
      function buildFullPath(model, path) {
        var parentId = model.get("parent_value_id");
        var name = path || model.get("name");
        if (parentId) {
          const parent = partialColl.get(parentId) || sharedColl.get(parentId) || self.collection.get(parentId);
          if (parent) {
            name = `${parent.get("name")} | ${name}`;
            const grandParentId = parent.get("parent_value_id");
            if (grandParentId) {
              name = buildFullPath(parent, name);
            }
          }
        }
        return name;
      }
      $.each(sharedColl.models, function (j, model) {
        var json = model.toJSON();
        const dimensionCode = json.dimension_code;
        if (json.action === updateAction && filters.includes(dimensionCode)) {
          const name = buildFullPath(model);
          const group = groups[dimensionCode] || $.extend(true, {}, defaultGroupObj);
          json.name = formatPath(ensureMaxLength(name), true);
          if (!defaultLocs.length && `${json.id}` === "251") {
            json.isDefault = true;
          } else if (defaultLocs.includes(`${json.id}`)) {
            json.isDefault = true;
          }
          json.tooltip = formatPath(name, false);
          group.groupItems.push(json);
          groups[dimensionCode] = group;
        }
      });
      $.each(filterOptions, function (index, option) {
        var group = groups[option.value];
        if (group && group.groupItems.length) {
          group.bin_group_name = option.text;
          group.count = group.groupItems.length;
          if (option.value === "USZC") {
            group.count = group.groupItems[0].count;
            group.groupItems = [{
              name: "postalcodes.csv",
              id: "placeholder"
            }];
          }
          result.push(group);
        } else if (option.value === "USZC" && self.zipcodesfile && self.zipcodeaction === updateAction.toUpperCase()) {
          result.push({
            bin_group_name: option.text,
            bin_show_group_name: true,
            showGroupNameOnly: true,
            count: self.zipcodecount,
            groupItems: [{
              name: self.zipcodesfile,
              id: "placeholder"
            }]
          });
        }
      });
      return result;
    }
  });
  return LocationBinsView;
});
