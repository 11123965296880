define('collections/changeLogs',["Backbone", "Underscore", "jQuery", "T1", "T1Collection", "T1Model"], function (Backbone, _, $, T1, T1Collection, T1Model) {
  "use strict";

  var ObjectToArray = T1.Utils.objectTOArray;
  T1.Models.ChangeLog = T1Collection.extend({
    urlString: "",
    fixture: "-changeLog",
    model: T1Model,
    initialize: function (models, configuration) {
      if (configuration === undefined) {
        throw new Error("Configuration missing to create changelog collection");
      }
      if (configuration.entity === undefined) {
        throw new Error("Entity is missing in the configuration");
      }
      if (configuration.id === undefined) {
        throw new Error("Id is missing in the configuration");
      }
      T1Collection.prototype.initialize.call(this, models, configuration);
      this.urlString = configuration.entity + "/" + configuration.id + "/history";
      this.fetchOptions.full = "*";
    },
    parse: function (data) {
      var logResult = [];
      var logData = data.log_entries;
      var logEntries = logData.count !== "0" && logData.entry ? ObjectToArray(logData.entry) : {
        entry: []
      };
      var withData = data.with;
      var withEntries;
      var withEntity;
      var withCollections;
      var fieldParser = function (items, list) {
        _.each(items, function (item) {
          var fields = ObjectToArray(item.field);
          delete item.field;
          _.each(fields, function (field) {
            var row = $.extend({}, item, field);
            list.push(row);
          });
        });
      };
      var dateSorter = function (items) {
        return _.sortBy(items, function (item) {
          return Date.parse(item.date);
        }).reverse();
      };
      if (withData && withData.count !== "0" && logData.start === "0") {
        withEntries = ObjectToArray(withData.log_entries);
        withEntity = withEntries[0].type;
        withCollections = [];
        _.each(withEntries, function (item) {
          var list = [];
          var entityColl;
          if (item.count !== "0") {
            fieldParser(ObjectToArray(item.entry), list);
            dateSorter(list);
            entityColl = new Backbone.Collection(list);
            entityColl.id = item.id;
            entityColl.name = item.name;
            entityColl.type = item.type;
            withCollections.push(entityColl);
          }
        });
        this[withEntity] = withCollections;
      }
      fieldParser(logEntries, logResult);
      dateSorter(logResult);
      this.loaded = true;
      this.count = parseInt(logData.count, 10);
      return logResult;
    }
  });
  return T1.Models.ChangeLog;
});
