define('modules/user/views/feedbackForm',["jQuery", "T1", "T1Comm", "T1View", "collections/organizations", "text!modules/user/templates/feedbackForm.html", "T1Form", "T1Notifier"], function ($, T1, T1Comm, T1View, Organizations, template) {
  var FeedbackFormView = T1View.extend({
    template: template,
    initialize: function (args) {
      var self = this;
      var model = this.model;
      var orgs = Organizations.getOrganizations();
      self.model.deleteVersion = true;
      orgs.getSelected().then(function (orgId) {
        self.orgID = orgId;
      });
      this.onCloseCallback = args.onCloseCallback || $.noop;
      model.set(model.defaults);
    },
    events: {
      "click .ui-cancel": "closeDialog",
      "click .ui-submit": "send"
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      this.render().then(function () {
        $el.dialog({
          dialogClass: "w-UserFeedbackDialog",
          closeText: "",
          autoOpen: true,
          modal: true,
          close: function () {
            self.closeDialog.call(self);
          }
        });
        T1.Selectbox($el.find('[data-bind="component"]'), {
          onChange: function (e) {
            self.removeTooltip.call(self, e);
          }
        });
        T1.Selectbox($el.find('[data-bind="type"]'), {
          onChange: function (e) {
            self.removeTooltip.call(self, e);
          }
        });
        self.delegateEvents();
      });
    },
    send: function () {
      var $el = $(this.el);
      var formValues = T1.Form.dataAsObject($el.find("form").not($('[data-bind="ignore"]', $el)));
      var model = this.model;
      var loader = this.loader();
      var self = this;
      var validationErrors;
      var newDescription;
      var location;
      var browser = detectBrowser();
      location = T1.Location.getRoute();
      function assembleAdditionalValues() {
        var id = !isNaN(location.id) ? location.id : "";
        switch (location.module) {
          case "#campaign":
            formValues.campaign_id = id;
            break;
          case "#strategy":
            formValues.strategy_id = id;
            break;
          case "#pixels":
            formValues.pixel_id = id;
            break;
          case "#creatives":
            formValues.creative_id = id;
            break;
          case "#admin":
            if (location.mode === "agency") {
              formValues.agency_id = id;
            }
            if (location.mode === "advertiser") {
              formValues.advertiser_id = id;
            }
            break;
        }
      }
      function detectBrowser() {
        var ua = navigator.userAgent;
        var browserName = navigator.appName;
        var fullVersion = "" + parseFloat(navigator.appVersion);
        var majorVer = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;
        if ((verOffset = ua.indexOf("Chrome")) !== -1) {
          browserName = "Chrome";
          fullVersion = ua.substring(verOffset + 7);
        } else if ((verOffset = ua.indexOf("OPR/")) !== -1) {
          browserName = "Opera";
          fullVersion = ua.substring(verOffset + 4);
        } else if ((verOffset = ua.indexOf("Opera")) !== -1) {
          browserName = "Opera";
          fullVersion = ua.substring(verOffset + 6);
          if ((verOffset = ua.indexOf("Version")) !== -1) fullVersion = ua.substring(verOffset + 8);
        } else if ((verOffset = ua.indexOf("MSIE")) !== -1) {
          browserName = "Microsoft Internet Explorer";
          fullVersion = ua.substring(verOffset + 5);
        } else if ((verOffset = ua.indexOf("Safari")) !== -1) {
          browserName = "Safari";
          fullVersion = ua.substring(verOffset + 7);
          if ((verOffset = ua.indexOf("Version")) !== -1) fullVersion = ua.substring(verOffset + 8);
        } else if ((verOffset = ua.indexOf("Firefox")) !== -1) {
          browserName = "Firefox";
          fullVersion = ua.substring(verOffset + 8);
        } else if ((nameOffset = ua.lastIndexOf(" ") + 1) < (verOffset = ua.lastIndexOf("/"))) {
          browserName = ua.substring(nameOffset, verOffset);
          fullVersion = ua.substring(verOffset + 1);
          if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
          }
        }
        if ((ix = fullVersion.indexOf(";")) !== -1) fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) !== -1) fullVersion = fullVersion.substring(0, ix);
        majorVer = parseInt("" + fullVersion, 10);
        if (isNaN(majorVer)) {
          fullVersion = "" + parseFloat(navigator.appVersion);
          majorVer = parseInt(navigator.appVersion, 10);
        }
        return {
          browserName: browserName,
          fullVersion: fullVersion,
          majorVer: majorVer,
          appName: navigator.appName
        };
      }
      function changeText() {
        var button = $el.find(".send");
        var str = "Sending...";
        $el.find(".messages").empty();
        if (self.isSending) {
          button.addClass("disabled").text(str);
        } else {
          button.removeClass("disabled").text("Send");
        }
      }
      if (self.isSending) {
        return;
      }
      validationErrors = model.validate(formValues);
      if (validationErrors && validationErrors.length) {
        this.showErrors(model, validationErrors);
        return;
      }
      loader.start();
      self.isSending = true;
      changeText();
      newDescription = formValues.description ? formValues.description : "";
      newDescription = newDescription;
      formValues.description = newDescription;
      formValues.organization_id = this.orgID;
      formValues.url = window.location.href;
      formValues.browser = browser.browserName + "/" + browser.fullVersion;
      formValues.language = navigator.language;
      formValues.cookies_enabled = navigator.cookieEnabled ? "on" : "off";
      assembleAdditionalValues();
      formValues["module"] = formValues["component"];
      delete formValues["component"];
      formValues.module = T1.Utils.toInitialCaps(formValues.module);
      model.save(formValues, {
        success: function () {
          loader.stop();
          self.isSending = false;
          changeText();
          self.renderPartial(".messages", {
            success: true
          });
          T1.Notify("message", $el.find(".success-message").html());
          self.closeDialog();
        },
        statusInvalid: function () {
          loader.stop();
          self.renderPartial(".messages", {
            error: true
          });
          self.isSending = false;
          changeText();
        }
      });
    },
    closeDialog: function (e) {
      var el = $(this.el);
      if (e) {
        e.preventDefault();
        el.dialog("close");
      }
      this.onCloseCallback();
      el.dialog("destroy");
      this.unload();
    },
    serialize: function () {
      var model = this.model;
      return {
        typeOptions: model.getTypeOptions(),
        componentOptions: model.getComponentOptions()
      };
    }
  });
  return T1.Form.ViewUtils.extend(FeedbackFormView);
});
