define('modules/reporting/campaigns/siteTransparency/views/addToSiteList',["jQuery", "Underscore", "T1", "T1View", "collections/siteLists", "models/siteList", "utils/TimezoneUtils", "text!../templates/add-to-site-list.html"], function ($, _, T1, T1View, SiteLists, SiteList, TimezoneUtils, template) {
  "use strict";

  var timezone = TimezoneUtils.getClientTimezoneAbbr();
  var convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
  var T1Publish = T1.EventHub.publish;
  var AddToSiteListView = T1View.extend({
    template: template,
    events: {
      "click .btn-cancel": "closeDialog",
      "click .btn-save": "apply",
      "click .custom-grid-item": "gridItemClick",
      "click .download-icon": "downloadHandler",
      "click .new-site-list-active": "newSiteListActive",
      "click .show-active": "updateActive",
      "keyup .new-site-list-input": "checkInput",
      "keyup .search-input": "onSearchInputKeyUp",
      "mousedown .new-site-list-input": "clearInput",
      "mousedown .site-list-type-ddl": "clearDDL"
    },
    initialize: function (args) {
      var collection, reportModel;
      const DEBOUNCE_DELAY = 500;
      if (!args.orgID) {
        throw new Error("orgID required for AddToSiteList");
      }
      if (!args.reportModel) {
        throw new Error("reportModel required for AddToSiteList");
      }
      this.inputDefaultValue = "Type New Site List Name";
      this.orgID = args.orgID;
      reportModel = args.reportModel;
      this.reportModel = reportModel;
      this.sitesTotal = reportModel.get("siteList").length;
      this.sitesMarkedCount = reportModel.get("sitesMarkedCount");
      this.selectSomethingWarning = "Please select an existing site list or create a new one.";
      this.inputWarningDefault = "There are errors on this page";
      this.inputWarningMaxLength = "Name must not exceed 64 characters.";
      this.connectionError = "An error has occurred trying to save this site list. " + 'Press "Add & Save" to try again or "Cancel" to quit.';
      collection = this.collection = new SiteLists();
      collection.canCache = false;
      collection.pageLimit = 100;
      collection.fetchOptions = {
        full: "*"
      };
      collection.urlFilter.set({
        entity: "organization",
        id: this.orgID
      });
      this.lazySearch = _.debounce(this.doSearch, DEBOUNCE_DELAY);
    },
    load: function () {
      var self = this;
      var $el = this.el;
      this.render().then(function () {
        $el.find(".site-count").text(`${self.sitesMarkedCount} of ${self.sitesTotal} domains selected`);
        self.$clearBtn = $($el.find(".search-input")[0].$.clearBtn);
        self.$clearBtn.on("click", $.proxy(self.clearSearchInput, self));
        self.$input = $el.find(".new-site-list-input");
        self.$newSiteListInput = $el.find(".new-site-list-input");
        self.$newSLCheckbox = $el.find(".new-site-list-active");
        self.$noResultsMsg = $el.find(".no-result");
        self.$select = $el.find(".site-list-type-ddl");
        self.$siteListTypeDDL = $el.find(".site-list-type-ddl");
        self.$spinner = $el.find(".spinner-btn");
        window.requestAnimationFrame(function () {
          self.footer = $el.find(".site-list-footer")[0];
          self.footer.type = "error";
          self.gridEl = $el.find(".site-list-grid")[0];
          self.mmLoader = $el.find(".loader")[0];
          self.submit = $el.find(".btn-save")[0];
          self.getData();
        });
      });
    },
    getData: function () {
      var self = this;
      var $el = this.el;
      var collection = this.collection;
      var searchInputValue = $el.find(".search-input")[0].value || "";
      this.resetWarnings();
      this.$noResultsMsg.addClass("no-display");
      this.mmLoader.show();
      collection.applySearchFilter("status", $el.find(".show-active")[0].checked ? "1" : "");
      collection.isPaginated = false;
      collection.filterBy("multiField", searchInputValue, function () {
        self.mmLoader.hide();
        self.parseData(collection);
      });
    },
    parseData: function (coll) {
      var collObj = coll.toJSON();
      var data = [];
      var len = collObj.length;
      var i, item;
      for (i = 0; i < len; i++) {
        item = collObj[i];
        data.push({
          id: i,
          lastUpdated: item.updated_on,
          selected: false,
          siteListName: item.name,
          sites: item.sites_count,
          slID: item.id,
          status: item.status === "1" ? "" : "inactive",
          type: item.restriction === "INCLUDE" ? "WL" : "BL"
        });
      }
      this.gridEl.$.selectAll.state = "unchecked";
      this.gridEl.data = data;
      if (len === 0) {
        this.$noResultsMsg.removeClass("no-display");
      }
    },
    newSiteListActive: function (e) {
      this.resetWarnings();
      if ($(e.target)[0].checked) {
        this.$newSiteListInput.removeAttr("disabled");
        this.$siteListTypeDDL.removeAttr("disabled");
      } else {
        this.$newSiteListInput.attr("disabled", "true");
        this.$siteListTypeDDL.attr("disabled", "true");
      }
    },
    doSearch: function () {
      this.getData();
    },
    updateActive: function () {
      this.getData();
    },
    onSearchInputKeyUp: function () {
      this.lazySearch();
    },
    clearSearchInput: function () {
      this.getData();
    },
    gridItemClick: function () {
      this.resetWarnings();
    },
    checkInput: function () {
      const MAX_LENGTH = 64;
      if (this.$input[0].value.length >= MAX_LENGTH) {
        this.toggleFooterMsg(this.inputWarningMaxLength);
      }
    },
    validateForm: function () {
      var $input = this.$input;
      var $select = this.$select;
      var inputValue = $.trim($input[0].value);
      var selectionValue = $select[0].value;
      var inputNotValid, isFormValid, selectionNotValid;
      inputNotValid = inputValue === this.inputDefaultValue || !inputValue;
      selectionNotValid = !selectionValue;
      isFormValid = !inputNotValid && !selectionNotValid;
      if (inputNotValid) {
        $input[0].error = true;
      }
      if (selectionNotValid) {
        $select[0].error = true;
      }
      if (!isFormValid) {
        this.toggleFooterMsg(this.inputWarningDefault);
      }
      return isFormValid;
    },
    toggleFooterMsg: function (msg) {
      var footer = this.footer;
      if (msg) {
        footer.message = msg;
        footer.showMessage();
      } else {
        footer.hideMessage();
      }
    },
    clearInput: function () {
      this.resetForm("input");
    },
    clearDDL: function () {
      this.resetForm("select");
    },
    resetForm: function (itemToReset) {
      switch (itemToReset) {
        case "input":
          this.$input[0].error = false;
          break;
        case "select":
          this.$select[0].error = false;
          break;
      }
      this.toggleFooterMsg();
    },
    resetWarnings: function () {
      this.$input[0].error = false;
      this.$select[0].error = false;
      this.toggleFooterMsg();
    },
    apply: function () {
      var self = this;
      var submit = this.submit;
      var selected = this.gridEl.selected;
      var len = selected.length;
      var createNewSiteList = this.$newSLCheckbox[0].checked;
      if (submit.disabled) {
        return;
      }
      if (len === 0 && !createNewSiteList) {
        this.toggleFooterMsg(this.selectSomethingWarning);
        return;
      }
      if (!createNewSiteList || createNewSiteList && this.validateForm()) {
        submit.disabled = true;
        $(submit).find("label").text("");
        this.$spinner.show();
        this.submitForm().done(function () {
          T1Publish("track:event", {
            action: "Add To Site List",
            label: $.trim(self.$input[0].value),
            value: self.sitesMarkedCount
          });
          self.closeDialog();
        });
      }
    },
    submitForm: function () {
      var self = this;
      var orgID = this.orgID;
      var deferred = $.Deferred();
      var selected = this.gridEl.selected;
      var sitesMarkedCount = this.sitesMarkedCount;
      var len = selected.length;
      var sitesMarked = this.reportModel.get("sitesMarked");
      var domainData = [];
      var added = 0;
      var createNewSiteList = this.$newSLCheckbox[0].checked;
      var newSiteListName = $.trim(this.$input[0].value);
      var newSiteListType = this.$select[0].value;
      var domainWord = sitesMarkedCount + (sitesMarkedCount === 1 ? " domain" : " domains");
      var siteListWord = len + (len === 1 ? " site list" : " site lists");
      const TIME_TO_READ = 7e3;
      const TIME_TO_READ2 = 6e3;
      var reEnableBtn = function () {
        self.submit.disabled = false;
        $(self.submit).find("label").text("Add & Save");
        self.$spinner.hide();
      };
      var successCallback = function (response, addOnly, more) {
        if (!addOnly && $(response).find("status").attr("code") === "invalid" || addOnly && response.status.code !== "ok") {
          reEnableBtn();
          self.toggleFooterMsg(self.connectionError);
          deferred.reject();
        } else {
          if (addOnly) {
            T1.Notify("message", `${domainWord} added to ${siteListWord} successfully`, TIME_TO_READ);
          } else {
            T1.Notify("message", `${newSiteListName} created successfully`, TIME_TO_READ2);
          }
          if (!more) {
            reEnableBtn();
            T1Publish("createSiteList.done");
            deferred.resolve();
          }
        }
      };
      var errorCallback = function () {
        reEnableBtn();
        self.toggleFooterMsg(self.connectionError);
        deferred.reject();
      };
      var createNewSL = function () {
        model = new SiteList();
        model.save({
          dataObj: {
            content: sitesMarked.toString(),
            filename: newSiteListName.split(" ").join(""),
            name: newSiteListName,
            organization_id: orgID,
            restriction: newSiteListType === "Allow list" ? "INCLUDE" : "EXCLUDE",
            status: "on"
          },
          successCallback: successCallback,
          errorCallback: errorCallback
        });
      };
      var addSuccessCallback = function (response) {
        if (++added === len) {
          successCallback(response, true, createNewSiteList);
          if (createNewSiteList) {
            createNewSL();
          }
        }
      };
      var i, model;
      this.toggleFooterMsg();
      for (i = 0; i < sitesMarkedCount; i++) {
        domainData.push({
          name: "domain",
          value: sitesMarked[i]
        });
      }
      for (i = 0; i < len; i++) {
        model = new SiteList({
          id: selected[i].slID
        });
        model.addDomains(domainData, {
          success: addSuccessCallback,
          statusInvalid: errorCallback
        });
      }
      if (len === 0 && createNewSiteList) {
        createNewSL();
      }
      return deferred.promise();
    },
    downloadHandler: function (e) {
      var dialogPanel = this.el[0];
      var model = new SiteList({
        id: $(e.target).parent().data("id")
      });
      model.sync("read", model, {
        action: "download.csv",
        dataType: "html",
        processAjaxResponse: function (resp) {
          var blob = new Blob([resp], {
            type: "attachment/csv"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `site_list_${model.id}_${convertToLocalTime(new Date()).toString("yyyyMMdd")}.csv`;
          dialogPanel.appendChild(a);
          a.click();
          dialogPanel.removeChild(a);
          return {
            jsonData: [],
            statusCode: "ok"
          };
        }
      });
    },
    closeDialog: function (event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.unload();
      T1Publish("close:siteList");
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      if (Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    },
    unload: function () {
      if (this.$clearBtn) {
        this.$clearBtn.off("click");
      }
      this.$clearBtn = null;
      this.$input = null;
      this.$newSiteListInput = null;
      this.$newSLCheckbox = null;
      this.$noResultsMsg = null;
      this.$select = null;
      this.$siteListTypeDDL = null;
      this.$spinner = null;
      this.collection = null;
      this.connectionError = null;
      this.inputDefaultValue = null;
      this.inputWarningDefault = null;
      this.inputWarningMaxLength = null;
      this.orgID = null;
      this.reportModel = null;
      this.selectSomethingWarning = null;
      this.sitesMarkedCount = null;
      this.sitesTotal = null;
      this.submit = null;
    }
  });
  return AddToSiteListView;
});
