define('modules/reporting/segments/main',["T1Module", "modules/reporting/segments/dataExport/models/model"], function (T1Module, DataExportModel) {
  "use strict";

  var models = {
    setupDataExportModel: function setupDataExportModel() {
      if (!this.DataExport) {
        this.DataExport = new DataExportModel();
      }
    }
  };
  return new T1Module({
    defaultView: "segment_reports",
    name: "reporting/segments",
    viewCfgs: {
      segment_reports: {
        models: models
      }
    }
  });
});
