define('modules/shared/configs/charts/lineChartOneAxisSeriesConfig',[], function () {
  "use strict";

  const colorAxis = "#999999";
  const colorLineInactive = "#E5E5E5";
  const config = {
    hideCredits: true,
    type: "serial",
    theme: "light",
    fontFamily: "AR",
    dataDateFormat: "YYYY-MM-DD HH:NN",
    dataProvider: [],
    graphs: [],
    valueAxes: [{
      axisColor: colorAxis,
      gridColor: colorAxis,
      labelFunction: function () {},
      position: "left",
      title: "",
      titleBold: true,
      titleFontSize: 13
    }],
    categoryAxis: {
      axisColor: colorAxis,
      dateFormats: [{
        period: "hh",
        format: "LA"
      }, {
        period: "DD",
        format: "MMM DD"
      }, {
        period: "MM",
        format: "MMM"
      }, {
        period: "YYYY",
        format: "YYYY"
      }],
      gridColor: colorAxis,
      minPeriod: "DD",
      parseDates: true
    },
    categoryField: "date",
    startDuration: .3,
    startEffect: "easeOutSine",
    sequencedAnimation: false,
    bezierX: 20,
    bezierY: 20,
    marginRight: 0,
    balloon: {
      color: "#FFFFFF",
      fillColor: "#000000",
      maxWidth: 500,
      textAlign: "left",
      verticalPadding: 8
    },
    chartCursor: {
      balloonPointerOrientation: "vertical",
      categoryBalloonEnabled: false,
      cursorAlpha: 0,
      leaveCursor: true,
      oneBalloonOnly: true,
      zoomable: false
    },
    legend: {
      autoMargins: false,
      enabled: true,
      marginRight: 20,
      markerDisabledColor: colorLineInactive,
      labelWidth: 300,
      position: "right",
      useGraphSettings: true,
      valueFunction: function () {},
      verticalGap: 3
    },
    export: {
      enabled: true,
      fallback: false,
      libs: {
        autoLoad: false
      },
      menu: []
    }
  };
  return {
    copy: () => JSON.parse(JSON.stringify(config))
  };
});
