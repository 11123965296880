define('T1InlineEditPopover',["jQuery", "Underscore", "T1", "T1View", "T1PopoverPositionable"], function ($, _, T1, T1View, createPositionable) {
  var instance;
  var InlineEditPopover;
  function validateMenuCreateParameters(configs, view) {
    var result = {
      success: false,
      errMsg: ""
    };
    var generateErrResult = function (msg) {
      result.errMsg = msg;
      return result;
    };
    if (!configs) {
      return generateErrResult("menu config parameter cannot be null or undefined");
    }
    if (!configs.menuTriggerEleSelector) {
      return generateErrResult("configs.menuTriggerEleSelector must contain a valid class name for the UI that triggers the menu to be displayed.");
    }
    result.success = true;
    return result;
  }
  InlineEditPopover = T1View.extend({
    initialize: function (args) {
      this.el = this.getPopoverContainer("w-InlineEditPopoverContainer");
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      this.render().then(function () {
        if (self.popoverConfigs.onOpen) {
          self.popoverConfigs.onOpen(self);
        }
      });
    },
    activateDismissalLogic: function (event, viewHolder, configs, originEle) {
      var self = this;
      var $doc = $(document);
      var $window = $(window);
      var originEleLocal = originEle;
      var parentEles;
      self.parentsOfOriginEl = null;
      $doc.on("mouseup", $.proxy(self.docScopeEventHandler, self));
      $doc.on("scroll", $.proxy(self.docScopeEventHandler, self));
      $window.on("resize", $.proxy(self.docScopeEventHandler, self));
      if (originEleLocal) {
        if ($.isArray(originEleLocal) === true) {
          originEleLocal = originEleLocal[0];
        }
        parentEles = $(originEleLocal).parents();
        parentEles.on("scroll", $.proxy(self.docScopeEventHandler, self));
        self.parentsOfOriginEl = parentEles;
      }
    },
    docScopeEventHandler: function (event) {
      var $el = $(this.el);
      var outsidePopover = $(event.target).parents().index($el) <= -1;
      var needToDismissPopover = true;
      if (event.type === "mouseup" && outsidePopover !== true) {
        needToDismissPopover = false;
      }
      if (needToDismissPopover === true) {
        this.dismissPopover();
      }
    },
    dismissPopover: function () {
      var self = this;
      var $el = $(self.el);
      var $doc = $(document);
      var $window = $(window);
      $el.css("display", "none");
      $doc.off("mouseup", $.proxy(self.docScopeEventHandler, self));
      $doc.off("scroll", $.proxy(self.docScopeEventHandler, self));
      $window.off("resize", $.proxy(self.docScopeEventHandler, self));
      if (self.parentsOfOriginEl) {
        self.parentsOfOriginEl.off("scroll", $.proxy(self.docScopeEventHandler, self));
      }
      this.detachEvents();
    },
    attachEvents: function (viewHolder, configs, originEle) {
      var self = this;
      var $doc = $(document);
      var $el = $(self.el);
      var formValues;
      var validationResult;
      $el.find(".ui-submit").on("click", function (e) {
        formValues = T1.Form.dataAsObject($el.find("form").not($('[data-bind="ignore"]', $el)));
        if (configs.validate) {
          validationResult = configs.validate(formValues);
          if (validationResult.errors && validationResult.errors.length) {
            self.showErrors(formValues, validationResult.errors, ".form-body");
            self.updateFooterMessage({
              error: false
            });
            return;
          }
        }
        self.dismissPopover();
        e.preventDefault();
        if (configs.onSave) {
          configs.onSave(formValues, self);
        }
      });
      $el.find(".ui-cancel").on("click", function (e) {
        self.dismissPopover();
        e.preventDefault();
      });
    },
    detachEvents: function () {
      var $el = $(this.el);
      var $doc = $(document);
      $el.find(".ui-submit").off("click");
      $el.find(".ui-cancel").off("click");
    },
    preLoadProcessor: function (event) {
      var $currentTarget = $(event.currentTarget);
      this.template = this.popoverConfigs ? this.popoverConfigs.template : "";
      this.popoverConfigs.currentData = {
        target: $currentTarget
      };
    },
    serialize: function () {
      var result = this.popoverConfigs && this.popoverConfigs.generateData ? this.popoverConfigs.generateData(this.popoverConfigs.currentData) : {};
      return result;
    },
    createPopover: function (config, view) {
      var self = instance;
      var localConfig = $.extend(true, {}, config);
      var viewHolder = {
        view: view
      };
      var validationResult = validateMenuCreateParameters(localConfig, view);
      var $scopeToFindButton = view ? $(view.el) : $(self.getScopeContainer());
      var $menuButton;
      if (validationResult.success !== true) {
        throw new Error(validationResult.errMsg);
      }
      localConfig.xPosPadding = 20;
      localConfig.skipArrowRightPositioning = true;
      $menuButton = $scopeToFindButton.find(localConfig.menuTriggerEleSelector);
      if ($menuButton.length <= 0) {
        throw new Error("config.menuTriggerEleSelector is not a valid selector");
      }
      $menuButton.off("click").on("click", function (event) {
        self.showPopover(event, viewHolder, localConfig, $menuButton);
      });
      return instance;
    }
  });
  InlineEditPopover = T1.Form.ViewUtils.extend(createPositionable(InlineEditPopover));
  if (!instance) {
    instance = new InlineEditPopover();
  }
  return instance.createPopover;
});
