define('modules/targeting/video/views/panel',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!modules/targeting/video/templates/panel.html", "text!modules/targeting/video/templates/panel_layout.html", "T1Layout", "collections/targetValues", "utils/CampaignStrategyUtils"], function ($, _, T1, T1View, T1Permissions, template, panelLayout, T1Layout, TargetValues, Utils) {
  "use strict";

  const targetDimensionKey = Utils.names.targetDimensions;
  var isTargetDimensionReady = Utils.isStrategyTargetDimensionDataReady;
  const VideoTargetingView = T1View.extend({
    template: template,
    viewMode: "browse",
    filterConfigs: {
      filterClass: "videoTargets",
      skippableElementsAdded: false,
      filterOptions: [{
        text: "Player Size",
        value: "VPSZ"
      }, {
        text: "Linear Format",
        value: "VLIN"
      }, {
        text: "Content Initiation",
        value: "VCON"
      }, {
        text: "Audio",
        value: "VAUD"
      }, {
        text: "Skippability",
        value: "VSKP"
      }, {
        text: "Video Placement",
        value: "VPLC"
      }]
    },
    eventhubEvents: {
      "strategyTargetDimension:reset": function () {
        this.initializeDataEventsAndLayout();
        this.load();
      },
      "change:formStatus": function (hasChanged) {
        this.isFormElementsChanged = hasChanged;
      }
    },
    initialize: function ({
      strategy: strategy,
      strategyTargetDimensions: strategyTargetDimensions
    }) {
      this.strategy = strategy;
      this.preventDoubleBind = true;
      this.collection = new TargetValues([], {
        dimension: "VPSZ"
      });
      this.strategyTargetDimensions = strategyTargetDimensions;
      const videoTabConfig = {
        tabName: "Video",
        tabId: "video",
        filterClass: "videoTargets"
      };
      this.bindViewEvents();
      this.filterConfigs = Utils.getFilterConfigs(videoTabConfig);
      if (isTargetDimensionReady(this.strategyTargetDimensions) === true) {
        this.initializeDataEventsAndLayout();
      }
    },
    reload: function () {
      this.strategyTargetDimensions.fetch({
        success: () => {
          this.prepareSharedCollection();
          if (!this.layout && isTargetDimensionReady(this.strategyTargetDimensions) === true) {
            this.initializeDataEventsAndLayout();
          }
          T1View.prototype.reload.call(this);
        }
      });
    },
    initializeDataEventsAndLayout: function () {
      var targetDimensions = this.strategyTargetDimensions.get(targetDimensionKey);
      this.targetDimensions = targetDimensions;
      this.initLayout(targetDimensions);
      this.addDataEvent({
        targetDimensions: {
          reset: function () {
            this.isFormElementsChanged = false;
          }
        }
      });
      this.isFormElementsChanged = false;
      this.removeEventHubEvents(["strategyTargetDimension:reset"]);
    },
    load: function () {
      T1.EventHub.publish("strategy-createEdit:loadForecasterData");
      this.render().then(() => {
        if (this.layout) {
          const delay = 200;
          this.layout.load();
          T1.EventHub.publish("strategy-badges-edit:placementCheck", {
            currentView: "video_targeting"
          });
          T1.EventHub.publish("strategyTargetingLoader:stop");
          setTimeout(() => {
            this.isFormElementsChanged = false;
          }, delay);
        }
      });
    },
    unload: function () {
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var strategyName = "untitled strategy";
      if (isFormElementsChanged) {
        const model = this.strategy;
        if (model.get("name")) {
          strategyName = model.get("name");
        }
        this.openUnloadAlertDialog({
          name: strategyName
        }, onTabChange);
      }
      return !isFormElementsChanged;
    },
    onDontSave: function () {
      var strategyTargetDimensions = this.strategyTargetDimensions;
      this.unbind();
      strategyTargetDimensions.fetch();
    },
    cancelUnload: function () {
      T1.EventHub.publish("strategy-create-edit:cancelUnload");
    },
    initLayout: function (targetDimensions) {
      var collection = this.collection;
      var viewConfigs = {
        strategy: this.strategy,
        collection: collection,
        sharedCollection: targetDimensions,
        filterConfigs: this.filterConfigs
      };
      var filterClass = this.filterConfigs.filterClass;
      var filterOptions = this.filterConfigs.filterOptions;
      var filterCallback = function (term) {
        var target = $(this.el).find(`.${filterClass}`).val();
        const searchInputFilterConfig = {
          searchTerm: term,
          inputBox: this.inputBox,
          event: this.event,
          inputParent: this.inputParent,
          context: this
        };
        if (Utils.validateSearchFilterMinCharacter(searchInputFilterConfig)) {
          return;
        }
        collection.trigger("search", {
          term: term,
          type: target
        });
      };
      var clearFilterCallback = function () {
        collection.trigger("search.clear");
      };
      this.sharedCollection = targetDimensions;
      this.prepareSharedCollection();
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".w-panel-body",
        template: panelLayout,
        layout: {
          ".filters-c": [{
            module: "shared",
            viewType: "filter",
            options: {
              collection: collection,
              filterClass: filterClass,
              selectOptions: filterOptions,
              filterCallback: filterCallback,
              clearFilterCallback: clearFilterCallback
            }
          }],
          ".browser-c": [{
            module: "targeting/video",
            viewType: "browse",
            options: viewConfigs
          }],
          ".bins-column": [{
            module: "targeting/video",
            viewType: "bins",
            options: viewConfigs
          }]
        }
      });
    },
    prepareSharedCollection: function () {
      const {
        strategyTargetDimensions: strategyTargetDimensions,
        sharedCollection: sharedCollection
      } = this;
      let targetDimensions = strategyTargetDimensions.get(targetDimensionKey);
      if (!(targetDimensions && targetDimensions.length)) {
        strategyTargetDimensions.set({
          target_dimensions: [],
          partial: []
        });
        targetDimensions = strategyTargetDimensions.get(targetDimensionKey);
      }
      targetDimensions.each(dimension => {
        sharedCollection.add(dimension.get("target_value").models);
        sharedCollection.INCLUDE_OP = dimension.get("INCLUDE_OP");
        sharedCollection.EXCLUDE_OP = dimension.get("EXCLUDE_OP");
      });
      this.targetDimensions = targetDimensions;
      this.sharedCollection = sharedCollection;
    },
    postData: function (formValues, successCallback, invalidCallback) {
      var self = this;
      var onSuccess = function () {
        self.strategyTargetDimensions.fetch({
          success: function () {
            self.strategy.fetch({
              success: function () {
                self.isFormElementsChanged = false;
                successCallback();
                T1.EventHub.publish("strategy-create-edit:reloadSubViews");
                T1.EventHub.publish("video-targeting:updateTempColl");
              }
            });
          }
        });
      };
      this.strategyTargetDimensions.saveTargetValues(onSuccess, invalidCallback, undefined, undefined, self);
    }
  });
  return T1.Form.ViewUtils.extend(VideoTargetingView);
});
