define('modules/campaign/export/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "campaign/export",
    viewCfgs: {
      exportTags: {}
    },
    defaultView: "exportTags"
  });
});
