define('T1Form',["jQuery", "Underscore", "When", "T1", "Mustache", "T1Spinner", "text!templates/form_footer.html", "text!templates/unload_alert.html", "text!templates/unload_alert_bulkedit.html", "text!templates/save_target_values.html", "T1Selectbox", "T1Tooltip"], function ($, _, When, T1, T, Spinner, FormFooter, UnloadAlert, UnloadAlertBulkedit, saveTargetValuesTmpl) {
  "use strict";

  var currencyDataKey = "currencyCode";
  var defaultCurrency = "USD";
  var t1IntegerFormat = T1.Formats.integers();
  var longIntFormat = function (val) {
    return isNaN(val) || val === "" ? "" : t1IntegerFormat(val).replace(/ /g, "");
  };
  var pacingElems = ["pacing_type", "pacing_optimization_type", "impression_pacing_type"];
  T1.Form = {
    getUnmaskedVal: function ($el) {
      var mask = $el.attr("alt");
      var val = T1.Form.Masks.revert(mask, $el);
      return val;
    },
    dataAsObjectClassic: function (form) {
      var $form = $(form);
      var formEls = $form.find(":input").not(":button, :submit");
      var dataObj = {};
      $.each(formEls, function (index, el) {
        if ($(el).closest('[data-bind="hidden"]').length) {
          return;
        }
        var $el = $(this);
        var name = $el.attr("name");
        var attrName = $el.data("bind");
        var val;
        var autoComplete = $el.attr("autocomplete");
        var isNotAutoComplete = autoComplete === undefined;
        if (isNotAutoComplete) {
          switch (this.type) {
            case "hidden":
            case "select-one":
            case "select":
            case "password":
            case "textarea":
              val = $el.val();
              if (val === null) {
                val = "";
              }
              break;
            case "text":
              val = T1.Form.getUnmaskedVal($el);
              break;
            case "checkbox":
              val = this.checked;
              break;
            case "radio":
              val = $("input[name=" + name + "]:checked", $form).val();
              break;
            default:
              break;
          }
          if (attrName !== undefined) {
            dataObj[attrName] = val;
          }
        }
      });
      return dataObj;
    },
    dataAsObject: function (form) {
      var dataObj = this.dataAsObjectClassic(form);
      var $form = $(form);
      var routine = function (subroutine) {
        var $el = $(this);
        var group = $el.attr("group");
        var attrName = $el.data("bind");
        var autoComplete = $el.attr("autocomplete");
        var isNotAutoComplete = autoComplete === undefined;
        if ($el.closest('[data-bind="hidden"]').length) {
          return;
        }
        if (isNotAutoComplete) {
          if (attrName !== void 0) {
            dataObj[attrName] = subroutine(this, group, $form);
          }
        }
      };
      $form.find("strand-input,strand-textarea").each(routine, [function subroutineInput(el) {
        return T1.Form.getUnmaskedVal($(el));
      }]);
      $form.find("strand-checkbox").each(routine, [function subroutineCheckbox(el) {
        if (el.checked) {
          return el.getAttribute("value") || true;
        }
      }]);
      $form.find("strand-group").each(routine, [function subroutineGroup(el, group, $container) {
        return $container.find('strand-radio[group="' + group + '"][checked]')[0].getAttribute("value");
      }]);
      $form.find("strand-dropdown").each(routine, [function subroutineDropdown(el) {
        return el.nodeName === "STRAND-DROPDOWN" && el.value === null ? "" : el.value;
      }]);
      return dataObj;
    }
  };
  T1.Form.Masks = function () {
    var revert = function (el) {
      var val = el ? parseFloat(el.val()) : "";
      if (isNaN(val)) {
        val = "";
      }
      return val;
    };
    var revertCurrency = function (el) {
      var currencyCodeVal = el.data(currencyDataKey);
      var resultVal;
      resultVal = T1.Utils.unformatCurrency(el.val(), currencyCodeVal || defaultCurrency);
      return !isNaN(resultVal) ? resultVal : "";
    };
    var revertLong = function (el) {
      var val = el ? el.val().replace(/\,/g, "") : "";
      val = parseFloat(val);
      if (isNaN(val)) {
        val = "";
      }
      return val;
    };
    var masks = {
      currency: {
        revert: revertCurrency
      },
      number: {
        revert: revert
      },
      percentage: {
        revert: revert
      },
      negativeNumber: {
        revert: revert
      },
      ratio: {
        revert: revert
      },
      long: {
        revert: revertLong
      }
    };
    return {
      attach: function (o, currencyCode, maskFormatObj) {
        var currencyToUse = currencyCode || defaultCurrency;
        var phoneValidation = new RegExp(/^[0-9\+\-\(\)\.\ ]*$/);
        function onCurrencyNumberKeyUp(e, type) {
          var keyUnicode = e.charCode || e.keyCode;
          var $target, valAsNumber, max, currencyCodeVal;
          if (e !== undefined && !e.ctrlKey) {
            switch (keyUnicode) {
              case 16:
              case 17:
              case 35:
              case 36:
              case 37:
              case 38:
              case 39:
              case 40:
              case 78:
              case 91:
              case 110:
              case 190:
                break;
              case 27:
                this.value = "";
                break;
              default:
                $target = $(this);
                if (type === "currency") {
                  max = parseFloat($target.attr("max"));
                  max = isNaN(max) ? COMPASS_ENV.CURRENCY_MAX : Math.min(max, COMPASS_ENV.CURRENCY_MAX);
                  valAsNumber = revertCurrency($target);
                  if (isNaN(valAsNumber)) {
                    valAsNumber = 0;
                  } else {
                    valAsNumber = valAsNumber >= max ? max : valAsNumber;
                  }
                  currencyCodeVal = $target.data(currencyDataKey);
                  if ($target.data("precision") && $target.data("precision") == 4) {
                    $target.val(T1.Utils.formatCurrencyNoSymbol(valAsNumber, "", currencyCodeVal, true));
                  } else {
                    $target.val(T1.Utils.formatCurrencyNoSymbol(valAsNumber, "", currencyCodeVal));
                  }
                }
            }
          }
        }
        function formatCurrencyPercent($element, type) {
          var maxVal = $element.attr("max");
          var max = parseFloat(maxVal);
          var eleVal = $element.val();
          if (eleVal !== "") {
            if (type !== "keyup") {
              if (maskFormatObj) {
                eleVal = maskFormatObj.isMaskCurrency ? revertCurrency($element) : false;
              }
            }
            var value, result;
            if (maxVal !== undefined && eleVal > max) {
              value = maxVal;
            } else {
              value = eleVal;
            }
            value = String(value);
            value = parseFloat(value.replace(/\,/g, ""));
            result = value.toFixed(2);
            return result;
          }
        }
        $.each(o, function (key, element) {
          var $element = $(element);
          var msk = $element.attr("alt");
          var max, maxVal, decimalSeparator, valAsNumber;
          function bypassKey(e) {
            var result = true;
            var code = e.charCode || e.keyCode;
            switch (code) {
              case 224:
              case 40:
              case 39:
              case 38:
              case 37:
              case 36:
              case 35:
              case 17:
              case 9:
              case 8:
                break;
              default:
                result = false;
                break;
            }
            if (e.shiftKey) {
              result = false;
            }
            if (e.ctrlKey || e.metaKey) {
              result = true;
            }
            return result;
          }
          if (msk === "currency") {
            decimalSeparator = T1.Utils.getCurrencyDecimalSeparator(currencyToUse);
            if (maskFormatObj) {
              if (maskFormatObj.isMaskPercent) {
                valAsNumber = $element.val().replace(/\./g, decimalSeparator);
                $element.val(valAsNumber);
                return;
              } else {
                valAsNumber = revertCurrency($element);
              }
            } else {
              valAsNumber = revertCurrency($element);
            }
            $element.unbind("keypress");
            $element.keypress(function (e) {
              var keyUnicode = e.charCode || e.keyCode;
              var rgx = new RegExp("[0-9|" + decimalSeparator + "]", "g");
              var chr = String.fromCharCode(keyUnicode);
              var currentValue;
              if (!bypassKey(e)) {
                if (!chr.match(rgx)) {
                  return false;
                }
                currentValue = $(e.currentTarget).val();
                if (currentValue.split(decimalSeparator).length > 1 && chr === decimalSeparator) {
                  return false;
                }
              }
            });
            $element.data(currencyDataKey, currencyToUse);
            if ($element.data("precision") && $element.data("precision") == 4) {
              $element.val(T1.Utils.formatCurrencyNoSymbol(valAsNumber, "", currencyToUse, true));
            } else {
              $element.val(T1.Utils.formatCurrencyNoSymbol(valAsNumber, "", currencyToUse));
            }
            $element.unbind("blur");
            $element.blur(function (e) {
              onCurrencyNumberKeyUp.call(this, e, "currency");
            });
          } else if (msk === "number" || msk === "percentage" || msk === "integer" || msk === "negativeNumber" || msk === "ratio" || msk === "long" || msk === "phone" || msk === "currencyPercent") {
            if (msk !== "integer") {
              if (msk === "long") {
                valAsNumber = revertLong($element);
                $element.val(longIntFormat(valAsNumber));
              }
              if (msk === "currencyPercent") {
                valAsNumber = formatCurrencyPercent($element);
                $element.val(valAsNumber).change();
              }
              $element.unbind("blur");
              $element.blur(function (e) {
                var value;
                if (msk === "phone") {
                  if (!phoneValidation.test($element.val())) {
                    $element.val("");
                  }
                } else {
                  value = msk === "long" ? revertLong($element) : parseFloat($(this).val());
                  if (msk === "percentage") {
                    maxVal = $element.attr("max");
                    max = parseFloat(maxVal);
                    if (maxVal !== undefined && value > max) {
                      $(this).val(maxVal);
                    } else if (value > 100 && !maxVal) {
                      $(this).val(isNaN(max) ? "99" : max);
                    }
                    return;
                  }
                  if (msk === "ratio") {
                    $element.val(isNaN(value) ? "" : "" + value.toFixed(2));
                    return;
                  }
                  if (msk === "long") {
                    $element.val(longIntFormat(value));
                  } else {
                    $element.val(isNaN(value) ? "" : "" + value.toFixed(2));
                  }
                  if (msk === "negativeNumber") {
                    $element.val(isNaN(value) ? "" : "" + value.toFixed(4));
                  }
                  if (msk === "currencyPercent") {
                    valAsNumber = formatCurrencyPercent($element, "keyup");
                    $element.val(valAsNumber);
                  }
                }
              });
            }
            $element.unbind("keypress");
            $element.keypress(function (e) {
              var keyUnicode = e.charCode || e.keyCode;
              var chr = String.fromCharCode(keyUnicode);
              var newVal = $element.val() + chr;
              var rgx;
              switch (msk) {
                case "integer":
                  rgx = new RegExp(/^[0-9]*$/);
                  break;
                case "long":
                  rgx = new RegExp(/^[0-9|\,]*$/);
                  break;
                case "negativeNumber":
                  rgx = new RegExp(/^-?[0-9]+(\.([0-9]*))?$/);
                  break;
                case "ratio":
                  rgx = new RegExp(/^[0-9]+(\.?([0-9]{0,2}))?$/);
                  break;
                case "phone":
                  rgx = phoneValidation;
                  break;
                default:
                  rgx = new RegExp(/^[0-9]+(\.?([0-9]*))?$/);
              }
              if (msk === "phone") {
                if (chr === "'") {
                  return false;
                }
              } else {
                if (chr === "-" && $element.val() !== "-") {
                  newVal = chr + $element.val();
                } else if (msk === "ratio" && !isNaN(parseFloat(chr)) && $element.val().indexOf(".") > -1) {
                  return true;
                }
              }
              if (!bypassKey(e)) {
                if (msk === "negativeNumber" && newVal === "-") {
                  return true;
                } else if (!rgx.test(newVal)) {
                  return false;
                }
              }
            });
          }
        });
      },
      revert: function (mask, el) {
        var unmsk = masks[mask] && masks[mask].revert ? masks[mask].revert : function (v) {
          return v.val();
        };
        var result;
        result = unmsk && el ? unmsk(el) : "";
        return result;
      }
    };
  }();
  T1.Form.ViewUtils = function () {
    function prepareFormUtils(originalView) {
      var formEvents = {
        "focus .text": "showTooltip",
        "blur .text": "hideTooltip",
        "focus .textarea": "showTooltip",
        "blur .textarea": "hideTooltip",
        "keydown .text,strand-input": "removeTooltip",
        "keydown .textarea,strand-textarea": "removeTooltip",
        "change .select": "removeTooltip",
        "changed strand-dropdown": "removeTooltip",
        "mousedown .radio,strand-radio": "setRadioFormValuesChanged",
        "mousedown .checkbox,strand-checkbox": "setCheckBoxFormValuesChanged",
        "mouseover .chzn-single": "showSelectBoxTooltip",
        "mouseover .date-display": "showDateDisplayTooltip"
      };
      var actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel",
          location: "#monitor"
        }, {
          label: "save",
          class: "save save-btn-secondary",
          location: false
        }, {
          label: "save & continue",
          class: "saveAndContinue save-btn-primary",
          location: false
        }],
        events: {
          "click .save": "saveHandler",
          "click .cancel": "cancel",
          "click .saveAndContinue": "saveAndContinueHandler"
        }
      };
      var formConfig = $.extend(true, {}, originalView.prototype.formConfig);
      var extendedFormEvents, utilFunctionsObj;
      if (formConfig.doNotShowSaveAndContinueButton === true) {
        delete actionsConfig.formActions[2];
        actionsConfig.formActions[1].class = "save save-btn-primary";
        delete actionsConfig.events["click .saveAndContinue"];
      }
      if (formConfig.bulkedit) {
        delete actionsConfig.formActions[1];
        delete actionsConfig.formActions[2];
        delete actionsConfig.events["click .save"];
        delete actionsConfig.events["click .saveAndContinue"];
        if (formConfig.bulkeditStrategies) {
          actionsConfig.formActions.push({
            label: "Apply Strategies & Continue",
            class: "stageAndContinue stage-btn-primary",
            location: false
          });
          actionsConfig.events["click .stageAndContinue"] = "stageAndContinueHandler";
        } else if (formConfig.bulkeditActionViews) {
          actionsConfig.formActions.push({
            label: "Apply & Continue Editing",
            class: "stage stage-btn-secondary",
            location: false
          });
          actionsConfig.formActions.push({
            label: "Review & Submit Changes",
            class: "stageAndReview stage-btn-primary",
            location: false
          });
          actionsConfig.events["click .stage"] = "stageHandler";
          actionsConfig.events["click .stageAndReview"] = "stageAndReviewHandler";
        } else if (formConfig.bulkeditReview) {
          actionsConfig.formActions.push({
            label: "Submit Bulk Changes",
            class: "execute execute-btn-primary",
            location: false
          });
          actionsConfig.events["click .execute"] = "executeHandler";
        }
      }
      actionsConfig = $.extend(true, actionsConfig, originalView.prototype.actionsConfig || {});
      extendedFormEvents = $.extend(formEvents, originalView.prototype.events || {}, actionsConfig.events);
      utilFunctionsObj = {
        events: extendedFormEvents,
        actionsConfig: actionsConfig,
        isFormElementsChanged: false,
        startDateError: "Please make sure your selected START DATE is in the future.",
        startEndOrderError: "Please make sure your selected END DATE is after START DATE.",
        checkingUnsavedState: false,
        actionButtons: ["save", "saveAndContinue", "saveAndClose", "saveAndNew", "stage", "stageAndContinue", "stageAndReview", "execute"],
        partials: {
          footer: FormFooter,
          unloadAlert: UnloadAlert,
          unloadAlertBulkedit: UnloadAlertBulkedit,
          saveTargetValuesAlert: saveTargetValuesTmpl
        },
        load: function () {
          var uid;
          originalView.prototype.load.call(this);
          if (this.checkUnsavedStateReady !== true && this.canUnload && this.noCheckUnsavedState !== true) {
            uid = new Date().getTime();
            this.mouseupEvt = "mouseup." + uid;
            this.keyupEvt = "keyup." + uid;
            this.checkUnsavedStateHandler = _.debounce($.proxy(this.checkUnsavedState, this), 300);
            setTimeout($.proxy(this.initUnsavedStateCheck, this), 40);
            this.checkUnsavedStateReady = true;
          }
          if (this.formConfig && this.formConfig.bulkedit) {
            this.addEventHubEvent({
              "update:footerMessage": function (data) {
                this.updateFooterMessage(data);
              }
            });
          }
        },
        unload: function () {
          if (this.mouseupEvt) {
            this.clearUnsavedStateCheck();
          }
          this.checkUnsavedStateReady = false;
          originalView.prototype.unload.call(this);
        },
        initUnsavedStateCheck: function () {
          var mouseupEvt = this.mouseupEvt;
          var keyupEvt = this.keyupEvt;
          $(document).off(mouseupEvt).on(mouseupEvt, this.checkUnsavedStateHandler);
          $(this.el).off(keyupEvt).on(keyupEvt, this.checkUnsavedStateHandler);
          if (this.hasFormSubViews) {
            this.checkUnsavedState();
          }
        },
        clearUnsavedStateCheck: function () {
          $(document).off(this.mouseupEvt);
          $(this.el).off(this.keyupEvt);
        },
        checkUnsavedState: function () {
          this.checkingUnsavedState = true;
          if (!this.canUnload()) {
            this.clearUnsavedStateCheck();
            if (this.formConfig && this.formConfig.bulkedit) {
              this.updateFooterMessage({
                warning: true,
                message: "There are unstaged changes on the page."
              });
            } else {
              this.updateFooterMessage({
                unsaved: true
              });
              T1.EventHub.publish("t1Form:unsaved");
            }
          }
          this.checkingUnsavedState = false;
        },
        showErrors: function (model, errors, formEl, tooltipOnSelf) {
          var $el = $(this.el);
          var $formEl = $el.find(formEl || "form");
          var applyTooltip = this.applyTooltip;
          $.each(errors, function (key, value) {
            applyTooltip($formEl.find("[data-bind='" + value.field + "']"), value.message, tooltipOnSelf);
          });
          this.updateFooterMessage({
            error: true
          });
          this.delegateEvents();
        },
        applyTooltip: function (currentEl, message, tooltipOnSelf) {
          var parent = currentEl.parent();
          var errorConfig = {
            trigger: "manual",
            getExternalTip: false,
            className: "w-FormError",
            offset: 10
          };
          var prop = currentEl && currentEl.prop("tagName");
          var isStrandComponent = prop && prop.indexOf("STRAND") !== -1;
          if (tooltipOnSelf === true || isStrandComponent) {
            errorConfig.tooltipEle = currentEl;
            errorConfig.trigger = "hover";
            currentEl.attr("title", message);
          } else {
            errorConfig.tooltipEle = parent;
            parent.attr("title", message);
          }
          if (currentEl.is("select")) {
            errorConfig.offset = 15;
            errorConfig.gravity = "w";
            parent.find(".chzn-single").addClass("error");
          } else if (currentEl.hasClass("date-display")) {
            errorConfig.offset = -11;
            errorConfig.gravity = "s";
            currentEl.addClass("error");
          } else if (currentEl.attr("disabled")) {
            delete errorConfig.trigger;
            currentEl.addClass("error");
          } else if (currentEl.is("strand-input") || currentEl.is("strand-textarea")) {
            errorConfig.gravity = "s";
            currentEl.attr("error", true);
          } else {
            errorConfig.gravity = "s";
            currentEl.addClass("error");
          }
          T1.Tooltip(currentEl, errorConfig);
        },
        openUnloadAlertBulkeditDialog: function (data, onCustomRedirect) {
          if (this.checkingUnsavedState === true) {
            return;
          }
          var alertMessage = T.to_html(this.partials.unloadAlertBulkedit, data || {});
          var self = this;
          var isCustomRedirect = typeof onCustomRedirect === "object";
          function cancel(e) {
            if (isCustomRedirect) {
              onCustomRedirect.onAlertClose();
            } else {
              self.cancel.call(self, e, true);
            }
          }
          $(alertMessage).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: "w-FormUnloadAlert",
            buttons: [{
              text: "Continue Editing",
              class: "save",
              click: function () {
                var onCancelUnload = self.cancelUnload || $.noop;
                T1.Location.resetAbort();
                onCancelUnload.call(self);
                $(this).dialog("close");
              }
            }, {
              text: "Discard changes",
              class: "donot-save",
              click: function (e) {
                var onDontSave = self.onDontSave || $.noop;
                onDontSave.call(self);
                self.isFormElementsChanged = false;
                $(this).dialog("close");
                cancel(e);
              }
            }],
            close: function () {
              $(this).remove();
              $(this).dialog("destroy");
            }
          });
        },
        openUnloadAlertDialog: function (data, onCustomRedirect, saveAndContinueModeArg, retainListener, disableButtons) {
          if (this.checkingUnsavedState === true) {
            return;
          }
          var alertMessage = T.to_html(this.partials.unloadAlert, data || {});
          var self = this;
          var isCustomRedirect = typeof onCustomRedirect === "object";
          var saveAndContinueMode = saveAndContinueModeArg !== undefined ? saveAndContinueModeArg : false;
          function cancel(e) {
            if (isCustomRedirect) {
              onCustomRedirect.onAlertClose();
            } else {
              self.cancel.call(self, e, true);
            }
          }
          this.addEventHubEvent({
            "unloadAlertDialog:close": "closeUnloadAlertDialog"
          });
          this.$unloadAlertDialog = $(alertMessage).dialog({
            autoOpen: true,
            modal: true,
            dialogClass: "w-FormUnloadAlert",
            buttons: [{
              text: "Save",
              class: "save",
              click: function (e) {
                var $dialog = $(this);
                var saveBtn = $(e.currentTarget);
                function stopSpinnerAndCloseDialog() {
                  self.stopSpinner($dialog, "save");
                  self.stopSpinner.call(self);
                  $dialog.dialog("close");
                }
                if (disableButtons) {
                  var $modal = $($dialog[0].parentElement);
                  $modal.find(".donot-save").attr("disabled", "disabled");
                  $modal.find(".cancel").attr("disabled", "disabled");
                }
                self.startSpinner.call(self, $dialog.closest(".w-FormUnloadAlert"));
                if (!retainListener) {
                  saveBtn.off("click");
                }
                self.setSaveAndContinueMode(saveAndContinueMode);
                self.save(e, {
                  success: function () {
                    stopSpinnerAndCloseDialog();
                    cancel(e);
                  },
                  conflict: stopSpinnerAndCloseDialog,
                  invalid: stopSpinnerAndCloseDialog
                }, {
                  fromUnloadAlert: true,
                  isCustomRedirect: isCustomRedirect,
                  onCustomRedirect: onCustomRedirect,
                  saveAndContinueMode: saveAndContinueMode
                });
              }
            }, {
              text: "Don't Save",
              class: "donot-save",
              click: function (e) {
                var onDontSave = self.onDontSave || $.noop;
                onDontSave.call(self);
                self.isFormElementsChanged = false;
                $(this).dialog("close");
                cancel(e);
              }
            }, {
              text: "Cancel",
              class: "cancel",
              click: function () {
                var onCancelUnload = self.cancelUnload || $.noop;
                T1.Location.resetAbort();
                onCancelUnload.call(self);
                $(this).dialog("close");
                T1.EventHub.publish("unloadAlertDialog:close");
              }
            }],
            close: function () {
              self.removeEventHubEvents(["unloadAlertDialog:close"]);
              $(this).remove();
              $(this).dialog("destroy");
            }
          });
        },
        closeUnloadAlertDialog: function () {
          this.$unloadAlertDialog.dialog("close");
        },
        openWipeTargetAlertDialog: function (onCustomRedirect, extraOptions) {
          var self = this;
          var alertMessage = T.to_html(self.partials.saveTargetValuesAlert, {} || {});
          var wipeTargetValues;
          if (extraOptions && extraOptions.wipeTargetValues) {
            wipeTargetValues = extraOptions.wipeTargetValues;
          }
          if (wipeTargetValues) {
            $(alertMessage).dialog({
              autoOpen: true,
              modal: true,
              dialogClass: "w-TargetValuesWipeAlert",
              buttons: [{
                text: "Yes I am sure",
                class: "save",
                click: function (e) {
                  var $dialog = $(this);
                  var saveBtn = $(e.currentTarget);
                  function stopSpinnerAndCloseDialog() {
                    self.wipeTargetValues = false;
                    self.stopSpinner($dialog, "save");
                    self.stopSpinner.call(self);
                    $dialog.dialog("close");
                  }
                  self.wipeTargetValues = true;
                  self.startSpinner.call(self, $dialog.closest(".w-TargetValuesWipeAlert"));
                  saveBtn.off("click");
                  self.save(e, {
                    success: function () {
                      stopSpinnerAndCloseDialog();
                      self.processSaveAndContinueMode();
                    },
                    conflict: stopSpinnerAndCloseDialog,
                    invalid: stopSpinnerAndCloseDialog
                  });
                }
              }, {
                text: "Go back to edit",
                class: "cancel",
                click: function () {
                  $(this).dialog("close");
                }
              }],
              close: function () {
                $(this).remove();
                $(this).dialog("destroy");
              }
            });
          }
        },
        cancel: function (e, fromUnloadAlertDlg) {
          var canGoBack = true;
          var isFormCancelAction = true;
          var location = T1.Location;
          var isOperationAborted = location.getAbortedURL() !== false;
          var cancel_location = $(e.target).parent().attr("href");
          e.preventDefault();
          if (this.canUnload) {
            canGoBack = this.canUnload(true);
          }
          if (canGoBack) {
            isFormCancelAction = !fromUnloadAlertDlg;
            if (isOperationAborted && !isFormCancelAction) {
              location.goToAbortedURL();
            } else if (isFormCancelAction) {
              if (cancel_location !== undefined) {
                T1.Location.set(cancel_location, {
                  replace: true
                });
              } else {
                location.goBack(null, true);
              }
            } else {
              location.goBack(2);
            }
          }
        },
        save: function (e, callback, additionalOptions) {
          var self = this;
          var $el = $(this.el);
          var $btn = $(e.currentTarget);
          var formValues = T1.Form.dataAsObject($el.find("form").not($('[data-bind="ignore"]', $el)));
          var model = this.model;
          var isCustomRedirect, onCustomRedirect, fromUnloadAlert;
          if (additionalOptions) {
            fromUnloadAlert = additionalOptions.fromUnloadAlert;
            isCustomRedirect = additionalOptions.isCustomRedirect;
            onCustomRedirect = additionalOptions.onCustomRedirect || $.noop;
          }
          e.preventDefault();
          callback = callback || {};
          $(".tipsy").remove();
          $el.find(".error").removeClass("error");
          $el.find("[error]").removeAttr("error");
          this.startSpinner($btn);
          if (this.preparePostData) {
            formValues = this.preparePostData(formValues);
          }
          function statusInvalid(errors, message, formEl, tooltipOnSelf, extraOptions) {
            var invalidCallback = callback.invalid || $.noop;
            var alertMessage = T.to_html(self.partials.saveTargetValuesAlert, {} || {});
            var wipeTargetValues;
            if (extraOptions && extraOptions.wipeTargetValues) {
              wipeTargetValues = extraOptions.wipeTargetValues;
            }
            if (errors.length) {
              self.showErrors(model, errors, formEl, tooltipOnSelf);
            } else {
              self.updateFooterMessage({
                message: message,
                error: true
              });
            }
            function cancel(e) {
              if (isCustomRedirect) {
                onCustomRedirect.onAlertClose();
              } else {
                self.cancel.call(self, e, true);
              }
            }
            self.stopSpinner($btn);
            self.initUnsavedStateCheck();
            invalidCallback();
            if (wipeTargetValues) {
              $(alertMessage).dialog({
                autoOpen: true,
                modal: true,
                dialogClass: "w-TargetValuesWipeAlert",
                buttons: [{
                  text: "Yes I am sure",
                  class: "save",
                  click: function (e) {
                    var $dialog = $(this);
                    var saveBtn = $(e.currentTarget);
                    function stopSpinnerAndCloseDialog() {
                      self.wipeTargetValues = false;
                      self.stopSpinner($dialog, "save");
                      self.stopSpinner.call(self);
                      $dialog.dialog("close");
                    }
                    self.wipeTargetValues = true;
                    self.startSpinner.call(self, $dialog.closest(".w-TargetValuesWipeAlert"));
                    saveBtn.off("click");
                    self.save(e, {
                      success: function () {
                        stopSpinnerAndCloseDialog();
                        if (fromUnloadAlert) {
                          cancel(e);
                        } else {
                          self.processSaveAndContinueMode();
                        }
                      },
                      conflict: stopSpinnerAndCloseDialog,
                      invalid: stopSpinnerAndCloseDialog
                    });
                  }
                }, {
                  text: "Go back to edit",
                  class: "cancel",
                  click: function () {
                    $(this).dialog("close");
                    if (isCustomRedirect && onCustomRedirect.supplyInWarningState) {
                      onCustomRedirect.supplyInWarningState();
                    }
                  }
                }],
                close: function () {
                  $(this).remove();
                  $(this).dialog("destroy");
                }
              });
            }
          }
          function conflict() {
            var conflictCallback = callback.conflict || $.noop;
            self.stopSpinner($btn);
            self.setSaveAndContinueMode(false);
            self.initUnsavedStateCheck();
            conflictCallback();
            function onConflictDialogClose() {
              if (self.onVersionConflictClose) {
                self.onVersionConflictClose();
                self.removeEventHubEvents(["versionConflictDialog:close"]);
              }
            }
            self.addEventHubEvent({
              "versionConflictDialog:close": function () {
                onConflictDialogClose();
              }
            });
          }
          function success(model, options) {
            var successCallback = callback.success || $.noop;
            var doNotNavigateAway = options && options.doNotNavigateAway === true;
            self.stopSpinner($btn);
            self.isFormElementsChanged = false;
            self.updateFooterMessage({
              success: true
            });
            self.initUnsavedStateCheck();
            successCallback();
            if (!doNotNavigateAway) {
              self.processSaveAndContinueMode();
            }
          }
          this.postData(formValues, success, statusInvalid, conflict);
        },
        saveHandler: function (e) {
          this.setSaveAndContinueMode(false);
          this.save(e);
        },
        saveAndContinueHandler: function (e) {
          this.setSaveAndContinueMode(true);
          this.save(e);
        },
        stageData: function (e, callback) {
          var self = this;
          var $btn = $(e.currentTarget);
          this.startSpinner($btn);
          e.preventDefault();
          callback = callback || {};
          function statusInvalid(errors, message, formEl, tooltipOnSelf) {
            var invalidCallback = callback.invalid || $.noop;
            if (errors && errors.length) {
              self.showErrors(model, errors, formEl, tooltipOnSelf);
            } else {
              self.updateFooterMessage({
                message: message,
                error: true
              });
            }
            self.stopSpinner($btn);
            self.initUnsavedStateCheck();
            invalidCallback();
          }
          function conflict() {
            var conflictCallback = callback.conflict || $.noop;
            self.stopSpinner($btn);
            self.setSaveAndContinueMode(false);
            self.initUnsavedStateCheck();
            conflictCallback();
            function onConflictDialogClose() {
              if (self.onVersionConflictClose) {
                self.onVersionConflictClose();
                self.removeEventHubEvents(["versionConflictDialog:close"]);
              }
            }
            self.addEventHubEvent({
              "versionConflictDialog:close": function () {
                onConflictDialogClose();
              }
            });
          }
          function success(model, options) {
            var successCallback = callback.success || $.noop;
            var doNotNavigateAway = options && options.doNotNavigateAway === true;
            self.stopSpinner($btn);
            self.isFormElementsChanged = false;
            self.updateFooterMessage({
              message: "Your changes have been staged!",
              success: true
            });
            self.initUnsavedStateCheck();
            successCallback();
            if (!doNotNavigateAway) {
              self.processStageAndReviewMode();
              self.processSaveAndContinueMode();
            }
          }
          this.stage(success, statusInvalid, conflict);
        },
        executeData: function (e, callback) {
          var self = this;
          var $btn = $(e.currentTarget);
          this.startSpinner($btn);
          e.preventDefault();
          callback = callback || {};
          function statusInvalid(errors) {
            var invalidCallback = callback.invalid || $.noop;
            self.updateFooterMessage({
              message: "Finished but some strategies were not updated",
              warning: true
            });
            self.stopSpinner($btn);
            self.disableActions();
            invalidCallback();
          }
          function conflict() {
            var conflictCallback = callback.conflict || $.noop;
            self.stopSpinner($btn);
            self.setSaveAndContinueMode(false);
            self.initUnsavedStateCheck();
            conflictCallback();
            function onConflictDialogClose() {
              if (self.onVersionConflictClose) {
                self.onVersionConflictClose();
                self.removeEventHubEvents(["versionConflictDialog:close"]);
              }
            }
            self.addEventHubEvent({
              "versionConflictDialog:close": function () {
                onConflictDialogClose();
              }
            });
          }
          function success(model) {
            var successCallback = callback.success || $.noop;
            self.stopSpinner($btn);
            self.isFormElementsChanged = false;
            self.updateFooterMessage({
              message: "Bulk edits executed successfully!",
              success: true
            });
            self.initUnsavedStateCheck();
            successCallback();
            self.disableActions();
          }
          function cancel() {
            self.stopSpinner($btn);
          }
          this.execute(success, statusInvalid, conflict, cancel);
        },
        stageAndContinueHandler: function (e) {
          this.setSaveAndContinueMode(true);
          this.setStageAndReviewMode(false);
          this.stageData(e);
        },
        stageHandler: function (e) {
          this.setSaveAndContinueMode(false);
          this.setStageAndReviewMode(false);
          this.stageData(e);
        },
        processStageAndReviewMode: function () {
          if (this.getStageAndReviewMode() === true) {
            T1.EventHub.publish("form:save-and-last-tab");
            this.setStageAndReviewMode(false);
          }
        },
        stageAndReviewHandler: function (e) {
          this.setSaveAndContinueMode(false);
          this.setStageAndReviewMode(true);
          this.stageData(e);
        },
        executeHandler: function (e) {
          this.executeData(e);
        },
        disableActions: function () {
          $(this.el).find(".form-footer").find(".action").hide();
        },
        getSaveAndContinueMode: function () {
          return $(this.el).find(".form-footer").data("saveAndContinueMode");
        },
        getStageAndReviewMode: function () {
          return $(this.el).find(".form-footer").data("stageAndReviewMode");
        },
        setStageAndReviewMode: function (targetMode) {
          $(this.el).find(".form-footer").data("stageAndReviewMode", targetMode);
        },
        setSaveAndContinueMode: function (targetMode) {
          $(this.el).find(".form-footer").data("saveAndContinueMode", targetMode);
        },
        processSaveAndContinueMode: function () {
          if (this.getSaveAndContinueMode() === true) {
            T1.EventHub.publish("form:save-and-continue");
            this.setSaveAndContinueMode(false);
          }
        },
        updateFooterMessage: function (data) {
          var template = $(FormFooter).find(".messages").html();
          $(this.el).find(".form-footer .messages").html(T.to_html(template, data));
        },
        setRadioFormValuesChanged: function (e) {
          this.removeTooltip(e);
        },
        setCheckBoxFormValuesChanged: function (e) {
          var $currentTarget = $(e.currentTarget);
          var shouldIgnoreFormChange = !!$currentTarget.closest(".filter").length;
          if (!shouldIgnoreFormChange) {
            shouldIgnoreFormChange = $currentTarget.data("no-formchange") === true;
          }
          if (!shouldIgnoreFormChange) {
            this.isFormElementsChanged = true;
          }
        },
        showTooltip: function (e, targetEl) {
          var currentTarget = targetEl || $(e.currentTarget).parent();
          if (currentTarget.data("tipsy")) {
            currentTarget.tipsy("show");
          }
        },
        showSelectBoxTooltip: function (e) {
          var self = this;
          self.showTooltip(e, $(e.currentTarget).closest("dd"));
          $(e.currentTarget).bind("mouseout", function (event) {
            self.hideTooltip.call(self, event);
            $(e.currentTarget).unbind("mouseout");
          });
        },
        showDateDisplayTooltip: function (e) {
          var self = this;
          self.showTooltip(e);
          $(e.currentTarget).bind("mouseout", function (event) {
            self.hideDateDisplayTooltip(event);
          });
        },
        hideDateDisplayTooltip: function (e) {
          this.hideTooltip.call(this, e);
          $(e.currentTarget).unbind("mouseout");
        },
        hideTooltip: function (e) {
          var $target = $(e.currentTarget);
          var parent = $target.parent();
          if (parent.data("tipsy")) {
            parent.tipsy("hide");
          } else if ($target.closest("dd").data("tipsy")) {
            $target.closest("dd").tipsy("hide");
          }
        },
        removeTooltipOnElement: function (targetElement) {
          var elem, tipsyElem, isStrandComponent;
          if (targetElement && targetElement.length) {
            isStrandComponent = targetElement.prop("tagName").indexOf("STRAND") !== -1;
            elem = targetElement.attr("original-title", "");
            if (elem.length > 0) {
              tipsyElem = elem.data("tipsy") ? elem : elem.closest("dd").data("tipsy") ? elem.closest("dd") : undefined;
              if (tipsyElem) {
                tipsyElem.attr("original-title", "");
                tipsyElem.tipsy("hide");
              }
              targetElement.removeData("tipsy");
              if (isStrandComponent) {
                targetElement.removeClass("error").removeAttr("error");
              }
            }
          } else {
            $(document.body).find(".tipsy-s").css("visibility", "hidden");
          }
        },
        removeTooltip: function (e) {
          if (this.checkUnsavedStateHandler) {
            this.checkUnsavedStateHandler();
          }
          var type = e.currentTarget ? e.currentTarget.type : undefined;
          var selectPressed = type === "select-one" || type === "select";
          var radioPressed = type === "radio";
          var $currentTarget = $(e.currentTarget);
          var $formList = radioPressed ? $currentTarget.parents(".form-list") : undefined;
          var shouldIgnoreFormChange = !!$currentTarget.closest(".w-Filters").length;
          var isStrandComponent = $currentTarget.prop("tagName").indexOf("STRAND") !== -1;
          var $target, parent;
          if (!shouldIgnoreFormChange) {
            shouldIgnoreFormChange = $currentTarget.data("no-formchange") === true;
          }
          if (radioPressed && $formList.find($currentTarget.attr("value")).hasClass("error")) {
            return;
          }
          $target = radioPressed ? $formList.find(".error") : $currentTarget;
          parent = $target.parent();
          function removeTipsyData(targetElement) {
            var elem = targetElement.attr("original-title", "");
            var tipsyElem;
            if (elem) {
              tipsyElem = elem.data("tipsy") ? elem : elem.closest("dd").data("tipsy") ? elem.closest("dd") : undefined;
              if (tipsyElem) {
                tipsyElem.attr("original-title", "");
                tipsyElem.tipsy("hide");
              }
              targetElement.removeData("tipsy");
            }
          }
          if (parent.data("tipsy")) {
            removeTipsyData(parent);
            $target.removeClass("error");
            if (selectPressed) {
              parent.find(".chzn-single").removeClass("error");
            }
          } else if ($target.closest("dd").data("tipsy") || isStrandComponent) {
            removeTipsyData($target);
            if (isStrandComponent) {
              $target.removeClass("error").removeAttr("error");
            } else {
              parent.find(".chzn-single").removeClass("error");
            }
          }
          if (!shouldIgnoreFormChange) {
            this.isFormElementsChanged = true;
          }
        },
        startSpinner: function (el, className) {
          this.toggleSpinner(true, el, className);
        },
        stopSpinner: function (el, className) {
          this.toggleSpinner(false, el, className);
        },
        toggleSpinner: function (start, el, className) {
          var $el = el || $(this.el);
          var actionButtons = this.getActionButtons($el);
          var foundTarget = actionButtons.target;
          var foundNonTargets = actionButtons.nonTarget;
          var targetClassName = className || _.keys(foundTarget).join(",") || (this.getSaveAndContinueMode() === true ? "saveAndContinue" : "save");
          var nonTargetClassNames = _.keys(foundNonTargets) || _.without(this.actionButtons, targetClassName);
          var targetBtn, nonTargetBtns;
          if (foundTarget[targetClassName]) {
            targetBtn = foundTarget[targetClassName];
          } else {
            targetBtn = $el.find("." + targetClassName + (start ? "" : "-action"));
          }
          if (start) {
            targetBtn.addClass(targetClassName + "-action").removeClass(targetClassName);
            Spinner.apply(targetBtn);
          } else {
            targetBtn.spin(false);
            targetBtn.addClass(targetClassName).removeClass(targetClassName + "-action");
          }
          $.each(nonTargetClassNames, function (i, nonTargetClassName) {
            if (foundNonTargets[nonTargetClassName]) {
              nonTargetBtns = foundNonTargets[nonTargetClassName];
            } else {
              nonTargetBtns = $el.find("." + nonTargetClassName + (start ? "" : "-disabled"));
            }
            if (start) {
              nonTargetBtns.addClass(nonTargetClassName + "-disabled").removeClass(nonTargetClassName);
            } else {
              nonTargetBtns.removeClass(nonTargetClassName + "-disabled").addClass(nonTargetClassName);
            }
          });
        },
        getActionButtons: function (el) {
          var $el = el.parent();
          var retObj = {
            target: {},
            nonTarget: {}
          };
          var actionClasses = this.actionButtons;
          $.each(actionClasses, function (i, actionClass) {
            var metaActionClasses = ["." + actionClass, "." + actionClass + "-action", "." + actionClass + "-disabled"].join(",");
            var foundTargets = $el.find(metaActionClasses);
            var foundNonTargets;
            if (foundTargets.length > 0 && $.isEmptyObject(retObj.target)) {
              retObj.target[actionClass] = foundTargets;
            } else {
              foundNonTargets = $(metaActionClasses);
              if (foundNonTargets.length > 0) {
                retObj.nonTarget[actionClass] = foundNonTargets;
              }
            }
          });
          return retObj;
        },
        serialize: function (options) {
          var originalSerialize = originalView.prototype.serialize;
          var actionCfg = this.actionsConfig;
          var data = originalSerialize && typeof originalSerialize === "function" ? originalSerialize.call(this, options) : {};
          var serializeUtils = {
            getSelectOptions: function () {
              return function (attr) {
                var selected = data[attr] && this.value === data[attr] ? "selected='selected'" : "";
                return "<option " + selected + " value='" + this.value + "'>" + this.text + "</option>";
              };
            },
            formActions: actionCfg && actionCfg.formActions ? actionCfg.formActions : []
          };
          return $.extend(data, serializeUtils);
        }
      };
      return utilFunctionsObj;
    }
    return {
      extend: function (originalView, arry) {
        var utilFunctionsObj = prepareFormUtils(originalView);
        var selectedFunctions = {};
        var originalPartials = originalView.prototype.partials;
        var extendedView, i;
        if (Array.isArray(arry)) {
          for (i = 0; i < arry.length; i++) {
            selectedFunctions[arry[i]] = utilFunctionsObj[arry[i]] || $.noop;
          }
          utilFunctionsObj = selectedFunctions;
        }
        extendedView = originalView.extend(utilFunctionsObj);
        extendedView.prototype.partials = $.extend({}, extendedView.prototype.partials || {}, originalPartials);
        return extendedView;
      }
    };
  }();
  T1.Form.InlineEdit = function (view, dropdownList) {
    var containerEditClass = {
      group: "group-edit-enabled"
    };
    var extendedView, closeAllOnEvent;
    dropdownList = dropdownList || {};
    if (!view && typeof view.extend === "function") {
      return false;
    }
    function stopPropagation(event) {
      event.stopPropagation();
      event.preventDefault();
    }
    function removeError(el) {
      var formEl = el.find(".w-GroupInlineEditForm, .w-InlineEditForm");
      var formList = formEl.find(".form-list");
      formList.removeClass("error");
      if (formList.data("tipsy")) {
        formList.tipsy("hide");
      }
      formList.find(".input, .select").removeClass("error");
      formList.find("strand-input").removeAttr("error");
    }
    function selectBoxDefaults(e) {
      var currentView = e.data.view;
      stopPropagation(e);
      T1.EventHub.publish("inlineSelectBoxDefaults:change", e);
      removeError($(currentView.el));
    }
    function getTemplateData(dataBindEls, model, isGroupEdit, view) {
      var templateData = isGroupEdit ? [] : {};
      var isPromise = false;
      var templateDeferred = When.defer();
      var dataBind, editMode, editMask, currentValue, obj, dropdownOptions, dropdownConfigs, i;
      $.each(dataBindEls, function (key, el) {
        var dataEl = $(el);
        var loadTemplate, editCurrentValue, isInputHidden;
        dataBind = dataEl.data("bind");
        editMode = dataEl.data("edit-mode");
        editMask = dataEl.data("mask");
        editCurrentValue = dataEl.data("current-value");
        currentValue = function getCurrentValue(bind) {
          var parentAttr, childAttr;
          var i = bind.indexOf(".");
          var nestedAttr = i !== -1;
          if (nestedAttr) {
            parentAttr = bind.slice(0, i);
            childAttr = bind.slice(i + 1);
            return model.get(parentAttr) ? model.get(parentAttr)[childAttr] : "";
          } else {
            return dataBind ? model.get(dataBind) : "";
          }
        }(dataBind);
        if (currentValue === undefined) {
          currentValue = "";
        }
        if (editCurrentValue !== undefined) {
          currentValue = editCurrentValue;
        }
        if (editMode === "info-text") {
          currentValue = dataEl.data("info-text");
        }
        obj = {
          value: currentValue,
          bind: dataBind,
          mask: editMask
        };
        dropdownConfigs = dropdownList[dataBind];
        if (dropdownConfigs && dropdownConfigs.isCurrency && dropdownConfigs.isCurrency === true) {
          dropdownConfigs.currencySymbol = T1.Utils.getCurrencySymbol(view.getCurrencyCode());
        }
        if (typeof dropdownConfigs === "function") {
          dropdownConfigs = dropdownConfigs(model);
        }
        loadTemplate = function (dropdownConfigs) {
          if (dataBind && dropdownConfigs) {
            dropdownOptions = dropdownConfigs.options || [];
            for (i = 0; i < dropdownOptions.length; i++) {
              dropdownOptions[i].isSelected = dropdownOptions[i].value === obj.value;
            }
            obj = $.extend(obj, dropdownConfigs);
          }
          if (dropdownConfigs !== undefined && dropdownConfigs.autoComplete !== undefined) {
            obj.autoComplete = dropdownConfigs.autoComplete;
          } else {
            obj.autoComplete = {};
          }
          if (dropdownConfigs !== undefined && dropdownConfigs.searchFieldDisabled !== undefined) {
            obj.searchFieldDisabled = dropdownConfigs.searchFieldDisabled;
          }
          if (isGroupEdit) {
            obj.isSelectElement = editMode === "inline-dropdown";
            obj.isInputElement = editMode === "inline";
            isInputHidden = editMode === "hidden";
            obj.isHiddenElement = isInputHidden;
            obj.isInfoTextElement = editMode === "info-text";
            if (isInputHidden) {
              obj.value = dataEl.data("value");
            }
            if (key === 0) {
              obj.specialCssClass = "first";
            }
            if (key === dataBindEls.length - 1) {
              obj.specialCssClass = "last";
            }
            templateData.push(obj);
          } else {
            obj.editMode = editMode;
            templateData = obj;
          }
          templateDeferred.resolve(templateData);
        };
        if (dropdownConfigs !== undefined && typeof dropdownConfigs.then === "function") {
          isPromise = true;
          dropdownConfigs.then(function (configs) {
            loadTemplate(configs);
          });
        } else {
          loadTemplate(dropdownConfigs);
        }
      });
      if (isPromise) {
        return templateDeferred.promise;
      } else {
        return templateData;
      }
    }
    function closeEdit(event) {
      var currentView = event.data.view;
      var isGroupEdit = event.data.isGroupEdit;
      var limitToViewInstance = event.data.limitToViewInstance;
      var applyToAll = event.data.applyToAll;
      var groupClass = ".w-GroupInlineEditForm";
      var singleClass = ".w-InlineEditForm";
      var viewEl = $(currentView.el);
      var formEl = viewEl.find(applyToAll ? groupClass + ", " + singleClass : isGroupEdit ? groupClass : singleClass);
      var sectionEl = formEl.closest(".editable-c");
      var editableContent = sectionEl.find(".editable-content");
      var targetContainer = "." + editableContent.data("edit-group");
      var containerClass = isGroupEdit ? containerEditClass.group : "";
      event.preventDefault();
      event.stopPropagation();
      removeError(viewEl);
      if (formEl.length === 0 && limitToViewInstance !== true) {
        formEl = $(".w-GroupInlineEditForm:visible");
        if (formEl.length <= 0) {
          formEl = $(".w-InlineEditForm:visible");
        }
        sectionEl = formEl.closest(".editable-c");
      }
      formEl.fadeOut("fast", function () {
        formEl.remove();
        viewEl.find(targetContainer).removeClass(containerClass);
        sectionEl.find(".editable-content").hide().css("visibility", "visible").fadeIn("fast");
      });
      $(window).unbind("scroll", closeEdit);
      T1.EventHub.publish("onCloseInlineEdit", {
        view: currentView
      });
    }
    function open(currentEvent, inlineEditTemplate) {
      var currentView = currentEvent.data.view;
      var isGroupEdit = currentEvent.data.isGroupEdit;
      var currentTarget = $(currentEvent.currentTarget);
      var insertEl = currentTarget.closest(".editable-c");
      var editableContentEl = currentTarget.closest(".editable-content");
      var targetContainer = "." + editableContentEl.data("edit-group");
      var singleTemplates = {
        inline: ".single-text-input-edit-template",
        "inline-dropdown": ".single-dropdown-edit-template"
      };
      var editTemplate = isGroupEdit ? ".multiple-inline-edit-template" : singleTemplates;
      var formEl = isGroupEdit ? ".w-GroupInlineEditForm" : ".w-InlineEditForm";
      var containerClass = isGroupEdit ? containerEditClass.group : "";
      var isAmount = currentTarget.hasClass("amount");
      var elementBindings = currentView.editElementBindings || {};
      var $el = $(currentView.el);
      var currencyCode = currentView.getCurrencyCode ? currentView.getCurrencyCode() : null;
      var model = currentView.model || function () {
        var id = currentTarget.data("id") || currentEvent.target.dataset.id;
        return currentView.collection.get(id);
      }();
      var dataBindEls = editableContentEl.find("[data-bind]").filter("[data-edit-mode]");
      var templateData = getTemplateData(dataBindEls, model, isGroupEdit, currentView);
      var firstEl, $formEl, template;
      var loadTemplate = function (templateData) {
        templateData.value = T1.Utils.unescapeHTML(templateData.value);
        if (isGroupEdit) {
          templateData = {
            elements: templateData
          };
          template = $(inlineEditTemplate).find(editTemplate).html();
        } else {
          editTemplate = singleTemplates[templateData.editMode] || singleTemplates.inline;
          template = $(inlineEditTemplate).find(editTemplate).html();
        }
        $(insertEl).prepend(T.to_html(template, templateData));
        $el.trigger("inlineFormEdit.open");
        T1.Form.Masks.attach($("input", insertEl), currencyCode);
        T1.Selectbox($(insertEl).find(".select-input"), {
          data: currentEvent.data,
          onChange: selectBoxDefaults,
          searchFieldDisabled: templateData.searchFieldDisabled
        }, templateData.autoComplete);
        $el.find(targetContainer).addClass(containerClass);
        $formEl = $el.find(formEl);
        if (!$.isEmptyObject(elementBindings)) {
          $.each(elementBindings, function (key, value) {
            $formEl.find("[data-bind='" + key + "']").bind(value.type, value.action);
          });
        }
        insertEl.find(".editable-content").css("visibility", "hidden");
        if (isAmount) {
          $formEl.addClass("w-NumberEdit");
        }
        $formEl.fadeIn("fast");
        firstEl = $formEl.find(".form-input, textarea").first();
        if (firstEl.is("select")) {
          firstEl = firstEl.next();
          firstEl.find('.chzn-drop .chzn-search input[type="text"]').focus();
          firstEl.addClass("chzn-container-active");
        } else {
          firstEl.focus();
        }
        $formEl.find("input, .info").on("click", function (e) {
          stopPropagation(e);
        });
        $(window).bind("scroll", {
          view: currentView
        }, closeEdit);
      };
      if (typeof templateData.then === "function") {
        templateData.then(function (templateData) {
          loadTemplate(templateData);
        });
      } else {
        loadTemplate(templateData);
      }
    }
    function openInlineEdit(event) {
      var currentEvent = event;
      var eventObjectCopy = $.extend(true, {}, currentEvent);
      var classList = event.target.classList;
      var isCode10 = classList.contains("code-10");
      var isCode3 = classList.contains("code-3");
      currentEvent.preventDefault();
      currentEvent.stopPropagation();
      T1.EventHub.unsubscribe("onOpenInlineEdit", closeAllOnEvent);
      T1.EventHub.publish("onOpenInlineEdit", eventObjectCopy);
      T1.EventHub.subscribe("onOpenInlineEdit", closeAllOnEvent);
      require(["text!templates/inlineEdit.html"], function (incompleteTmpl) {
        var input = '<input class="text form-input" data-precision="4" data-bind="{{bind}}"' + 'value="{{value}}" alt="{{mask}}"/>';
        var textarea10 = '<textarea rows="10" cols="80" data-bind="{{bind}}" alt="{{mask}}">{{value}}</textarea>';
        var textarea3 = '<textarea rows="3" cols="80" data-bind="{{bind}}" alt="{{mask}}">{{value}}</textarea>';
        var html = isCode10 ? textarea10 : isCode3 ? textarea3 : input;
        var completedTmpl = incompleteTmpl.replace(/REPLACE_ME/, html);
        $(".w-InlineEditForm, .w-GroupInlineEditForm").find(".cancel-action").trigger("click");
        open(currentEvent, completedTmpl);
      });
    }
    function getValueObject(dataEls) {
      var saveObj = {};
      $.each(dataEls, function (key, el) {
        var targetEl = $(el);
        var isTargetElHidden = targetEl.attr("type") === "hidden";
        var bindTo = targetEl.data("bind");
        var mask, val;
        if (bindTo && bindTo !== "") {
          if (targetEl.closest("dd").is(":visible") || isTargetElHidden) {
            if (targetEl.is("div")) {
              val = targetEl.attr("value");
            } else {
              mask = targetEl.attr("alt");
              val = T1.Form.Masks.revert(mask, targetEl);
            }
            saveObj[bindTo] = val;
          }
        }
      });
      return {
        saveObj: saveObj,
        currentValues: {}
      };
    }
    function saveModel(event, formEl, valueObj) {
      var data = event.data;
      var currentView = data.view;
      var el = $(currentView.el);
      var isGroupEdit = data.isGroupEdit;
      var model = currentView.model || function () {
        var id = formEl.siblings().find("span:not(.t1-tooltip, .style-scope)").data("id");
        return currentView.collection.get(id);
      }();
      var loader = currentView.loader($.extend(true, currentView.loaderOpts || {}, {
        id: model.get("id")
      }));
      var validationErrors;
      loader.start();
      if (model.allowInlineValidation && model.validateAttrs && typeof model.validateAttrs === "function") {
        validationErrors = model.validateAttrs(valueObj.saveObj);
        if (validationErrors && validationErrors.length) {
          onStatusInvalid(validationErrors);
          return;
        }
      }
      function onStatusInvalid(errors) {
        var formList = formEl.find(".form-list");
        var errorConfig = {
          tooltipEle: formList,
          trigger: "manual",
          getExternalTip: false,
          className: "w-InlineEditError",
          gravity: "s",
          offset: 21
        };
        var errorMessage = errors[0] ? errors[0].message : "";
        loader.stop();
        formList.attr("title", errorMessage);
        if (isGroupEdit) {
          formList.addClass("error");
        } else {
          formEl.find(".input, .select").addClass("error");
        }
        T1.Tooltip(el, errorConfig);
        formList.tipsy("show");
      }
      model.save(valueObj.saveObj, {
        success: function () {
          event.data || (event.data = data);
          if (currentView && currentView.refreshModel) {
            currentView.refreshModel(valueObj.saveObj, model);
          }
          closeEdit(event);
          loader.stop();
        },
        conflict: function () {
          closeEdit(event);
          loader.stop();
        },
        statusInvalid: onStatusInvalid
      });
    }
    function save(event) {
      var currentView = event.data.view;
      var isGroupEdit = event.data.isGroupEdit;
      var formEl = $(currentView.el).find(isGroupEdit ? ".w-GroupInlineEditForm" : ".w-InlineEditForm");
      var dataEl = formEl.find("[data-bind]");
      var bind = dataEl.attr("data-bind");
      var valueObj = {};
      event.preventDefault();
      event.stopPropagation();
      if (pacingElems.indexOf(bind) > -1) {
        valueObj = currentView.getValueObject(dataEl, bind);
      } else {
        valueObj = bind.indexOf(".") !== -1 ? currentView.getValueObject(dataEl, bind) : getValueObject(dataEl);
      }
      saveModel(event, formEl, valueObj);
    }
    function handleKeypress(e) {
      var currentView = $.extend(e.data, e.data.view);
      var enterDisabled = e.data.enterDisabled;
      var keyCode = e.which;
      removeError($(currentView.el));
      if (keyCode === 13 && !enterDisabled) {
        save(e);
      } else if (keyCode === 27) {
        closeEdit(e);
      }
    }
    function delegateEditEvents(currentView) {
      var self = currentView;
      var el = $(self.el);
      var data = {
        view: self
      };
      var textareaData = {
        view: self,
        enterDisabled: true
      };
      var groupData = {
        view: self,
        isGroupEdit: true
      };
      el.off();
      el.on("click", ".w-InlineEditForm .form-input", data, stopPropagation);
      el.on("keydown", ".w-InlineEditForm .form-input", data, handleKeypress);
      el.on("keydown", ".w-InlineEditForm textarea", textareaData, handleKeypress);
      el.on("keydown", ".w-InlineEditForm .chzn-search input", data, handleKeypress);
      el.on("click", ".w-InlineEditForm .save-action", data, save);
      el.on("click", ".editable", data, openInlineEdit);
      el.on("click", ".w-InlineEditForm .cancel-action", data, closeEdit);
      el.on("click", ".w-InlineEditForm .select", data, stopPropagation);
      el.on("click", '[data-edit-type]="multiple"', groupData, openInlineEdit);
      el.on("keydown", ".w-GroupInlineEditForm .form-input", groupData, handleKeypress);
      el.on("keydown", ".w-GroupInlineEditForm .chzn-search input", groupData, handleKeypress);
      el.on("click", ".w-GroupInlineEditForm .select", groupData, stopPropagation);
      el.on("click", ".w-GroupInlineEditForm .save-action", groupData, save);
      el.on("click", ".w-GroupInlineEditForm .cancel-action", groupData, closeEdit);
    }
    extendedView = view.extend({
      delegateEvents: function (events) {
        var promise;
        var chooseInlineEditMode = function (env) {
          var $el = $(env.el);
          return function addOrRemoveInlineEdit(data) {
            view.prototype.delegateEvents.call(env, env.events);
            if (data) {
              $el.addClass("editable-view");
              delegateEditEvents(env);
            } else {
              $el.addClass("read-only-view");
              $el.find(".editable").removeClass("editable");
              $el.find(".editable-c").removeClass("editable-c");
              $el.find(".group-edit-content").removeClass("group-edit-content");
              $el.off();
            }
          };
        }(this);
        if (this.model || this.collection && this.collection.at(0)) {
          promise = this.model ? this.model.canEdit() : this.collection.at(0).canEdit();
          if (promise) {
            promise.then(chooseInlineEditMode);
            return;
          }
        }
        view.prototype.delegateEvents.call(this, events);
      }
    });
    closeAllOnEvent = function (e, dataPayload) {
      e = e || {};
      e.preventDefault = e.preventDefault || $.noop;
      e.stopPropagation = e.stopPropagation || $.noop;
      e.data = dataPayload || {
        view: {
          el: "body"
        },
        applyToAll: true
      };
      closeEdit(e);
    };
    view.prototype.closeInlineEditor = function (limitToViewInstance) {
      var $el = this.el;
      closeAllOnEvent(null, {
        view: {
          el: $el
        },
        applyToAll: true,
        limitToViewInstance: limitToViewInstance === true
      });
    };
    return extendedView;
  };
  return T1.Form;
});
