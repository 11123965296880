define('modules/campaign/chart/winLoss/models/mStrategyDailyChart',["Underscore", "Backbone"], function (_, Backbone) {
  "use strict";

  const COLOR_LINE_0 = "#fc6062";
  const COLOR_LINE_1 = "#4dc1e7";
  const COLOR_WHITE = "#fff";
  const CHART_COLORS = [COLOR_LINE_0, COLOR_LINE_1];
  return Backbone.Model.extend({
    initialize: function (attr, opts) {
      this.ReportModel = opts;
    },
    setLegend: function (state) {
      this.set({
        legendState: state
      });
    },
    getLegend: function () {
      return this.get("legendState");
    },
    getChartColors: function () {
      return CHART_COLORS;
    },
    getChartData: function () {
      let dataProvider, graphs;
      const ReportModel = this.ReportModel;
      if (ReportModel.chartData.length) {
        const timeSlots = ReportModel.getTimeSlots();
        const metrics = this._getMetrics(ReportModel);
        this._parseData(ReportModel, timeSlots, metrics);
        this._createDataProvider(ReportModel, timeSlots, metrics);
        this._createGraphs(ReportModel, metrics);
        dataProvider = this.get("dataProvider");
        graphs = this.get("graphs");
      }
      return {
        dataProvider: dataProvider || [],
        graphs: graphs || []
      };
    },
    _parseData: function (ReportModel, timeSlots, metrics) {
      const parsedData0 = [];
      const parsedData1 = [];
      const dataJSON = ReportModel.chartData.toJSON();
      timeSlots.forEach(function (startDate) {
        const dataSet = _.find(dataJSON, item => startDate === item.start_date);
        if (dataSet) {
          parsedData0.push(dataSet[metrics.metric0]);
          parsedData1.push(dataSet[metrics.metric1]);
        } else {
          parsedData0.push("0");
          parsedData1.push("0");
        }
      });
      this.set({
        parsedData0: parsedData0,
        parsedData1: parsedData1
      });
    },
    _createDataProvider: function (ReportModel, timeSlots, metrics) {
      const dataProvider = timeSlots.map(date => ({
        date: date
      }));
      const parsedData0 = this.get("parsedData0");
      const parsedData1 = this.get("parsedData1");
      dataProvider.forEach(function (item, index) {
        item[metrics.metric0] = parsedData0[index];
        item[metrics.metric1] = parsedData1[index];
      });
      this.set({
        dataProvider: dataProvider
      });
    },
    _getMetrics: function (ReportModel) {
      const selectedMetrics = ReportModel.getSelectedMetrics();
      return {
        metric0: selectedMetrics.strategy.daily[0].value,
        metric1: selectedMetrics.strategy.daily[1].value
      };
    },
    _createGraphs: function (ReportModel, metrics) {
      const formatValue = ReportModel.formatValue;
      const metric0 = metrics.metric0;
      const metric1 = metrics.metric1;
      const label0 = ReportModel.getSelectedMetricLabel(metric0);
      const label1 = ReportModel.getSelectedMetricLabel(metric1);
      const metricType0 = ReportModel.getSelectedMetricType(metric0);
      const metricType1 = ReportModel.getSelectedMetricType(metric1);
      const tooltipFormat = ReportModel.getTooltipFormat();
      function formatBalloon(item) {
        return `<p style="font-family:AB;">${moment(item.category).format(tooltipFormat)}</p><br>\n          <span style="color:${COLOR_LINE_0};">●</span> ${label0}: ${formatValue.call(ReportModel, item.dataContext[metric0], metricType0)}<br>\n          <span style="color:${COLOR_LINE_1};">●</span> ${label1}: ${formatValue.call(ReportModel, item.dataContext[metric1], metricType1)}<br>\n          &nbsp;`;
      }
      this.set({
        graphs: [{
          balloonFunction: formatBalloon,
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 6,
          dashLength: 3,
          hideBulletsCount: 100,
          lineColor: COLOR_LINE_0,
          lineThickness: 1.5,
          title: label0,
          type: "smoothedLine",
          valueAxis: "metric0Axis",
          valueField: metric0
        }, {
          balloonFunction: formatBalloon,
          bullet: "round",
          bulletBorderAlpha: 1,
          bulletBorderColor: COLOR_WHITE,
          bulletBorderThickness: 1.5,
          bulletSize: 6,
          hideBulletsCount: 100,
          lineColor: COLOR_LINE_1,
          lineThickness: 1.5,
          title: label1,
          type: "smoothedLine",
          valueAxis: "metric1Axis",
          valueField: metric1
        }]
      });
    }
  });
});
