define('modules/user/views/feedback',['require','jQuery','T1','T1Permissions','T1View','T1Layout','models/charity','collections/organizations','text!modules/user/templates/feedback.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const T1Layout = require("T1Layout");
  const Charity = require("models/charity");
  const Organizations = require("collections/organizations");
  const template = require("text!modules/user/templates/feedback.html");
  return T1View.extend({
    template: template,
    initialize: function (args) {
      this.showFeedbackLink = !(args && args.showFeedbackLink === false);
      this.charity = new Charity({});
      this.numSurgeries = 0;
      this.charity.fetch({
        dataType: "json",
        contentType: "application/json",
        data: {},
        success: (model, resp) => {
          this.numSurgeries = resp.data && resp.data.global_eye_surgeries;
          if (this.numSurgeries > 0) {
            this.showCharityLink = true;
            this.numSurgeries = this.numSurgeries.toString();
            this.serialize();
            this.render();
          }
        },
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: "ok"
          };
        }
      });
      this.render();
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".feedback-dialog",
        template: '<div class="feedback-form"></div>',
        layout: {
          ".feedback-form": [{
            module: "user",
            viewType: "feedbackForm",
            options: {
              onCloseCallback: function () {
                this.onFormClose();
              }
            }
          }]
        }
      });
    },
    events: {
      "click .feedback": "contactSupport",
      "click .t1-upcast-logo": "mmSocialClick",
      "click .t1-upcast-ribbon": "mmSocialClick",
      "click .charity-popover-delete": "onCharityDelete"
    },
    onCharityDelete: function () {
      this.$(".charity-popover")[0].close();
    },
    onFormClose: function () {
      $(this.el).find(".active").removeClass("active");
    },
    mmSocialClick: function () {
      window.open("https://kb.mediamath.com/wiki/");
    },
    contactSupport: function (e) {
      Organizations.getOrganizations().getSelected().then(orgId => {
        const newWindow = window.open();
        const caseUrl = "https://mediamathsupport.force.com/s/submit-a-case";
        newWindow.opener = null;
        newWindow.location = `${caseUrl}?00NF000000DmP97=${orgId}&description=${encodeURIComponent(window.location.href)}`;
      });
      e.preventDefault();
    },
    serialize: function () {
      return {
        mmSocialAllowed: T1Permissions.check("feature", "mm_social"),
        showFeedbackLink: this.showFeedbackLink,
        showCharityLink: this.showCharityLink,
        kb_url: T1.ENV.KB_URL || T1.Location.get(),
        mediamath_academy: T1.ENV.MEDIAMATH_ACADEMY || T1.Location.get(),
        numSurgeries: this.numSurgeries
      };
    }
  });
});
