define('modules/audiences/onboarding/create/models/otherDataBulkModel',["jQuery", "T1", "models/onboarding", "modules/audiences/onboarding/create/utils/bulkCreateUtils"], function ($, T1, OnboardingModel, BulkUtils) {
  "use strict";

  T1.Models.BulkCreateOtherDataPixels = OnboardingModel.extend({
    delivery_format: "CSV",
    formatOptions: [{
      format: "CSV",
      checked: true
    }],
    save: function (opts) {
      BulkUtils.save.call(this, opts);
    },
    dataToSaveObj: function () {
      const saveObj = {
        data: this.pixels.map(pixel => {
          const obj = {
            agency_id: parseInt(this.agency_id, 10),
            provider_id: parseInt(this.data_provider_id),
            pixel_name: pixel.name
          };
          if (pixel.cost_cpm) {
            obj.cost_cpm = +(+pixel.cost_cpm).toFixed(4);
          }
          if (pixel.cost_pct_cpm) {
            obj.cost_pct_cpm = parseFloat(pixel.cost_pct_cpm, 10);
            if (obj.cost_pct_cpm > 99) {
              obj.cost_pct_cpm = 99;
            }
          }
          return obj;
        })
      };
      BulkUtils.assignDelivery.call(this, saveObj);
      return saveObj;
    }
  });
  return T1.Models.BulkCreateOtherDataPixels;
});
