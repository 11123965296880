define('modules/media/pmp-e/views/pmp-e',["jQuery", "Underscore", "When", "Mustache", "moment", "T1", "T1View", "T1Layout", "T1Permissions", "T1Currencies", "T1UtilsAsync", "models/sessionUser", "models/userPreferences", "text!../templates/pmp-e.html", "text!../templates/sub.html", "../helpers/additions", "../helpers/parseDeals", "../helpers/sortDeals", "collections/organizations", "collections/supplySources", "collections/mediaDeals", "collections/mediaDealGroups", "models/mediaDeal"], function ($, _, When, T, moment, T1, T1View, T1Layout, T1Permissions, T1Currencies, T1UtilsAsync, User, UserPreferences, template, sub, Addme, ParseDeals, SortDeals, Organizations, SupplySources, Deals, DealGroups, Deal) {
  "use strict";

  const renderDelay = 50;
  const apiLimit = 100;
  const debounceDelay = 100;
  const searchDelay = 350;
  const padding = 25;
  var pmpe = T1View.extend({
    template: template,
    isFirstDealCall: true,
    allSearchGet: true,
    dealNameGet: true,
    dealIdGet: false,
    allStatusGet: true,
    activeStatusGet: false,
    inactiveStatusGet: false,
    totalCount: 0,
    events: {
      "changed .deals-grid": "toggleCanActivate",
      "click .deal-activate": "updateActivation",
      "changed .media-type-switcher": "handleMediaTypeSwitcher",
      "changed .search-deals": "loadSearch",
      "changed .deal-search-select": "handleSearchType",
      "changed .deal-status-select": "handleStatusType",
      "changed .deal-amount-select": "changePageCount",
      "click .bulk-activate": "handleBulkUpdate",
      "click .bulk-deactivate": "handleBulkUpdate",
      "click .export-selected": "handleExportSelected",
      "click .export-all": "handleExportAll",
      "column-sort .deals-column": "sort",
      "column-sort .deal-group-deal-grid-column": "sortDealGroupDealGrid",
      "column-sort .deal-group-column": "sortDealGroups",
      "item-resized .deal-group-row": "handleExpandRow",
      "select-all .deals-grid": "handleSelectAll",
      "select-all .deal-groups-grid": "handleSelectAll",
      "item-selected .deal-row": "handleSelect",
      "item-selected .deal-group-row": "handleSelect"
    },
    eventhubEvents: {
      "organization:select": "handleOrgChange"
    },
    initialize: function () {
      this.initialPageLoad = true;
      this.pageCount = 100;
      this.selected = {};
      const viewOptions = T1.Location.getViewOptions();
      if (viewOptions) {
        this.showDealGroups = viewOptions.showDealGroups || false;
      }
    },
    enableScroll: function () {
      let dealGridHelper;
      if (this.showDealGroups) {
        dealGridHelper = this.$(".deal-groups-grid-helper");
        if (dealGridHelper[0]) {
          dealGridHelper[0].callback = this.fetchMoreDealGroups.bind(this);
        }
      } else {
        dealGridHelper = this.$(".grid-helper");
        if (dealGridHelper[0]) {
          dealGridHelper[0].callback = this.fetchMoreDeals.bind(this);
        }
      }
    },
    changePageCount: function (val) {
      this.pageCount = parseInt(val.originalEvent.detail.value, 10);
      this.collection.pageLimit = this.pageCount;
    },
    handleOrgChange: function (val) {
      const searchInput = this.el.find(".search-deals");
      const grid = this.showDealGroups ? this.el.find(".deal-groups-grid")[0] : this.el.find(".deals-grid")[0];
      const orgs = Organizations.getOrganizations();
      searchInput[0].value = "";
      this.orgId = val.id;
      this.selectedOrg = orgs._byId[Number(this.orgId)];
      this.selected = {};
      this.$(".bulk-activate").removeClass("option-disabled");
      this.$(".bulk-deactivate").removeClass("option-disabled");
      this.activateDisabled = false;
      this.deActivateDisabled = false;
      grid.sortField = "";
      this.loadExecute();
    },
    handleMediaTypeSwitcher: function () {
      const value = this.$(".media-type-switcher")[0].value;
      const pmpeGrid = this.$("#pmpeGridTemplate")[0];
      const searchTypeSelector = this.$(".deal-search-select")[0];
      const searchInput = this.$(".search-deals")[0];
      const exportSelected = this.$(".export-selected");
      const exportAll = this.$(".export-all");
      const bulkActivate = this.$(".bulk-activate");
      const bulkDeactivate = this.$(".bulk-deactivate");
      bulkDeactivate.removeClass("option-disabled");
      bulkActivate.removeClass("option-disabled");
      this.deactivateDisabled = false;
      this.activateDisabled = false;
      this.selected = {};
      if (value === "Deal Groups") {
        this.initialPageLoad = false;
        pmpeGrid.showDealGroups = true;
        this.showDealGroups = true;
        searchTypeSelector.placeholder = "Group Name";
        searchTypeSelector.data = [{
          value: "name",
          name: "Group Name"
        }];
        this.dealNameGet = true;
        this.dealIdGet = false;
        searchInput.value = "";
        exportSelected.addClass("option-disabled");
        exportAll.addClass("option-disabled");
        setTimeout(() => {
          const selectAllCheck = this.$("#selectAll");
          const dealGrid = this.$(".deal-groups-grid")[0];
          selectAllCheck.attr("hidden", "");
          dealGrid.isLoading = true;
          dealGrid.noResultsMessage = "No deal groups available.";
        }, renderDelay);
        this.fetchDealGroups(false).then(data => {
          if (this.showDealGroups) {
            const dealGrid = this.el.find(".deal-groups-grid")[0];
            this.isFirstDealCall = true;
            this.enableScroll();
            this.updateGrid(data.data);
            this.dealGroups = data.data;
            this.isFirstDealCall = false;
            dealGrid.isLoading = false;
          }
        });
      } else {
        pmpeGrid.showDealGroups = false;
        this.showDealGroups = false;
        searchTypeSelector.placeholder = "Deal Name";
        searchTypeSelector.data = [{
          value: "name",
          name: "Deal Name"
        }, {
          value: "id",
          name: "Deal ID"
        }];
        searchInput.value = "";
        exportSelected.removeClass("option-disabled");
        exportAll.removeClass("option-disabled");
        setTimeout(() => {
          const dealGrid = this.el.find(".deals-grid")[0];
          dealGrid.isLoading = true;
          dealGrid.noResultsMessage = "No deals available.";
        }, renderDelay);
        if (!this.initialPageLoad) {
          this.fetchDeals(false).then(() => {
            if (!this.showDealGroups) {
              this.parseDealData();
            }
          });
        }
      }
    },
    handleExpandRow: function (event) {
      if (event.originalEvent.detail) {
        const index = event.currentTarget.dataItemIndex;
        const dealGroupGrid = this.el.find(".deal-groups-grid")[0];
        const dealGroupRowData = dealGroupGrid.data[index];
        const dealGroupQuery = `(${dealGroupRowData.deal_ids.join(",")})`;
        const dealsCollection = new Deals(false, {
          fetchOptions: {
            "owner.organization_id": this.orgId,
            q: dealGroupQuery
          }
        });
        if (this.activeStatusGet || this.inactiveStatusGet) {
          dealsCollection.fetchOptions.status = String(this.activeStatusGet);
        }
        dealsCollection.fetchAllPages({
          success: response => {
            const dealGrid = $(event.currentTarget).find(".deal-group-deal-grid")[0];
            this.isFirstDealCall = true;
            this.showDealGroups = true;
            dealGrid.noResultsMessage = "No results found.";
            dealGrid.data = response.models.map(model => {
              const modelAttrs = model.attributes;
              modelAttrs.supply_source_name = this.supplyCollection.get(model.attributes.supply_source_id).attributes.name;
              modelAttrs.price_display = T1Currencies.formatNumber(modelAttrs.currency_code, modelAttrs.price);
              modelAttrs.currency_symbol = T1.Utils.getCurrencySymbol(modelAttrs.currency_code);
              modelAttrs.deal_id = modelAttrs.id;
              modelAttrs.id = modelAttrs.deal_identifier;
              return modelAttrs;
            });
            this.isFirstDealCall = false;
          }
        });
      }
    },
    handleSelect(event) {
      const model = event.originalEvent.detail;
      const selected = model.selected;
      const modelId = model.deal_id ? model.deal_id : model.id;
      const bulkActivate = this.$(".bulk-activate");
      const bulkDeactivate = this.$(".bulk-deactivate");
      if (selected) {
        this.selected[modelId] = model;
        if (model.status) {
          bulkActivate.addClass("option-disabled");
          this.activateDisabled = true;
        } else {
          bulkDeactivate.addClass("option-disabled");
          this.deactivateDisabled = true;
        }
      } else {
        delete this.selected[modelId];
        if (model.status) {
          if (Object.values(this.selected).every(selectedModel => !selectedModel.status)) {
            bulkActivate.removeClass("option-disabled");
            this.activateDisabled = false;
          }
        } else {
          if (Object.values(this.selected).every(selectedModel => selectedModel.status)) {
            bulkDeactivate.removeClass("option-disabled");
            this.deactivateDisabled = false;
          }
        }
      }
    },
    handleSelectAll(event) {
      const selected = event.originalEvent.detail.selected;
      const gridData = event.currentTarget.data;
      const numRows = gridData.length;
      for (let i = 0; i < numRows; i++) {
        if (gridData[i]) {
          const row = gridData[i];
          const rowId = row.deal_id ? row.deal_id : row.id;
          if (selected) {
            this.selected[rowId] = row;
          } else {
            delete this.selected[rowId];
          }
        } else {
          break;
        }
      }
    },
    handleStatusType: function (val) {
      if (val.originalEvent.detail.value === "all") {
        this.inactiveStatusGet = false;
        this.activeStatusGet = false;
        this.allStatusGet = true;
      } else if (val.originalEvent.detail.value === "active") {
        this.inactiveStatusGet = false;
        this.activeStatusGet = true;
        this.allStatusGet = false;
      } else if (val.originalEvent.detail.value === "inactive") {
        this.inactiveStatusGet = true;
        this.activeStatusGet = false;
        this.allStatusGet = false;
      }
      this.loadStatus(val);
    },
    handleBulkUpdate: function (event) {
      const deferred = T1UtilsAsync.makeDeferral();
      const dealModel = new Deal();
      const ids = {
        ids: Object.keys(this.selected)
      };
      const bulkDeactivate = this.$(".bulk-deactivate");
      const bulkActivate = this.$(".bulk-activate");
      const updateLoader = this.$(".bulk-update-loader");
      const isActivate = event.target.className.includes("bulk-activate");
      const typePrefix = this.showDealGroups ? "deal_groups" : "deals";
      const fetchFunc = this.showDealGroups ? "fetchDealGroups" : "fetchDeals";
      const parseFunc = this.showDealGroups ? "parseDealGroupData" : "parseDealData";
      if (ids.ids.length === 0) {
        T1.Notify("error", "Please select some deals or deal groups first");
        return;
      } else if (this.activateDisabled && isActivate) {
        T1.Notify("error", "Please remove active deal or deal group selections first");
        return;
      } else if (this.deactivateDisabled && !isActivate) {
        T1.Notify("error", "Please remove inactive deal or deal group selections first");
        return;
      }
      dealModel.url = `${typePrefix}/status/${isActivate}`;
      updateLoader.removeAttr("hidden");
      dealModel.bulkUpdate(ids, {
        success: () => {
          this.selected = {};
          bulkDeactivate.removeClass("option-disabled");
          bulkActivate.removeClass("option-disabled");
          this.deactivateDisabled = false;
          this.activateDisabled = false;
          this[fetchFunc](false).then(() => {
            const successText = isActivate ? "Activate" : "Deactivate";
            this[parseFunc]();
            T1.Notify("message", `Bulk ${successText} Completed`);
            updateLoader.attr("hidden", "");
            deferred.resolve();
          });
        },
        error: () => {
          updateLoader.attr("hidden", "");
          deferred.resolve();
        }
      });
    },
    handleExportSelected: function (event) {
      if (!Object.keys(this.selected).length) {
        T1.Notify("error", "Please select some deals first");
        return;
      }
      if (event.target.className.includes("option-disabled")) {
        return;
      }
      this.fetchDealExport();
    },
    handleExportAll: function () {
      if (event.target.className.includes("option-disabled")) {
        return;
      }
      this.fetchDealExport(true);
    },
    handleSearchType: function () {
      var searchIdName = this.$(".deal-search-select")[0];
      var searchValue = this.$(".search-deals")[0];
      if (searchIdName.value === "name") {
        this.dealNameGet = true;
        this.dealIdGet = false;
        this.allSearchGet = false;
      } else if (searchIdName.value === "id") {
        this.dealIdGet = true;
        this.dealNameGet = false;
        this.allSearchGet = false;
      }
      searchValue.clearInput();
    },
    fetchSupply: function () {
      var deferred = $.Deferred();
      var supply = new SupplySources(false, {
        fetchOptions: {
          full: "*"
        },
        isPaginated: false
      });
      supply.fetch({
        success: data => {
          this.supplyCollection = data;
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    populateQUrl: function (val) {
      var qUrl;
      if (val) {
        if (this.dealNameGet) {
          qUrl = `name=:*${val}*`;
        } else if (this.dealIdGet) {
          qUrl = this.showDealGroups ? `id=:*${val}*` : `deal_identifier=:*${val}*`;
        }
      }
      return qUrl;
    },
    fetchDeals: function (val) {
      var deferred = $.Deferred();
      var fetchOptions;
      var qUrl = this.populateQUrl(val);
      if (this.allSearchGet) {
        fetchOptions = {
          "owner.organization_id": this.orgId
        };
      } else {
        fetchOptions = {
          "owner.organization_id": this.orgId,
          q: qUrl
        };
      }
      if (this.activeStatusGet) {
        fetchOptions.status = "true";
      } else if (this.inactiveStatusGet) {
        fetchOptions.status = "false";
      }
      this.collection = new Deals(false, {
        fetchOptions: fetchOptions,
        pageLimit: this.pageCount,
        isPaginated: true
      });
      this.collection.fetch({
        success: (parsed, data) => {
          const dealGrid = this.el.find(".deals-grid")[0];
          this.initialPageLoad = false;
          dealGrid.set("data", new Array(data.data.length));
          this.sendArray = data.data;
          this.deals = data;
          deferred.resolve();
        }
      });
      return deferred.promise();
    },
    fetchDealExport: function (exportAll) {
      const deferred = T1UtilsAsync.makeDeferral();
      const el = this.el[0];
      const spinner = this.$(".deals-export-loader");
      spinner.removeAttr("hidden");
      const fetchOptions = {
        "owner.organization_id": this.orgId,
        export: "true"
      };
      if (!exportAll) {
        const ids = Object.keys(this.selected).join(",");
        fetchOptions.ids = ids;
      } else {
        if (this.activeStatusGet) {
          fetchOptions.status = "true";
        } else if (this.inactiveStatusGet) {
          fetchOptions.status = "false";
        }
      }
      const exportData = new Deals(false, {
        fetchOptions: fetchOptions
      });
      exportData.fetch({
        success: (parsed, csvContent) => {
          const blob = new Blob([csvContent], {
            type: "attachment/csv"
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          const timeString = this.getFormattedTime();
          const prefix = exportAll ? "all" : "selected";
          const orgName = this.selectedOrg.get("name");
          if (exportAll) {
            let status = "allstatus";
            if (this.activeStatusGet) {
              status = "active";
            } else if (this.inactiveStatusGet) {
              status = "inactive";
            }
            a.download = `${orgName}_${this.orgId}_${status}_${prefix}_deals_${timeString}.csv`;
          } else {
            a.download = `${orgName}_${this.orgId}_${prefix}_deals_${timeString}.csv`;
          }
          a.href = url;
          el.appendChild(a);
          a.click();
          el.removeChild(a);
          spinner.attr("hidden", "");
          T1.Notify("message", "Deal Export Completed");
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    fetchMoreDeals: _.debounce(function (index) {
      const size = this.pageCount;
      const gridBottomIndex = index + padding;
      const page = Math.floor(gridBottomIndex / size);
      const grid = this.el.find(".deals-grid")[0];
      if (this.showDealGroups || grid.data[page * apiLimit]) {
        return;
      }
      this.isFirstDealCall = false;
      this.collection.page = page;
      grid.isLoading = true;
      this.collection.fetch({
        add: true,
        success: (parsed, data) => {
          this.sendArray = data.data;
          this.deals = data;
          this.parseDealData(data.meta.offset);
        }
      });
    }, debounceDelay),
    fetchDealGroups: function (query) {
      const deferred = $.Deferred();
      const orgId = this.orgId;
      let fetchOptions;
      if (query) {
        const queryUrl = this.populateQUrl(query);
        fetchOptions = {
          "owner.organization_id": orgId,
          q: queryUrl
        };
      } else {
        fetchOptions = {
          "owner.organization_id": orgId
        };
      }
      if (this.activeStatusGet) {
        fetchOptions.status = "true";
      } else if (this.inactiveStatusGet) {
        fetchOptions.status = "false";
      }
      this.dealGroupsCollection = new DealGroups(false, {
        fetchOptions: fetchOptions,
        pageLimit: this.pageCount,
        isPaginated: true
      });
      this.dealGroupsCollection.fetch().then(data => {
        const isCurrentOrg = this.orgId === orgId;
        if (this.showDealGroups && isCurrentOrg) {
          const dealGroupsGrid = this.el.find(".deal-groups-grid")[0];
          if (dealGroupsGrid) {
            dealGroupsGrid.set("data", new Array(data.data.length));
          }
          this.numDealsFetched = 100;
          this.dealGroupsPage = 0;
          this.dealGroupData = data;
          this.dealGroups = data.data;
          deferred.resolve(data);
        }
      });
      return deferred.promise();
    },
    fetchMoreDealGroups: _.debounce(function (index) {
      const bottomGridIndex = index + padding;
      const size = this.pageCount;
      const grid = this.el.find(".deal-groups-grid")[0];
      const allDealsFetched = this.numDealsFetched >= this.dealGroupData.meta.total_count;
      const page = Math.floor(bottomGridIndex / size);
      if (!this.showDealGroups || allDealsFetched || grid.data[page * apiLimit]) {
        return;
      }
      if (!this.isFetching) {
        this.isFetching = true;
        this.isFirstDealCall = false;
        this.dealGroupsCollection.page = ++this.dealGroupsPage;
        grid.isLoading = true;
        grid.setAllExpansions(false);
        this.dealGroupsCollection.fetch({
          add: true
        }).then(data => {
          this.numDealsFetched += apiLimit;
          this.dealGroupData = data;
          this.dealGroups = data.data;
          this.parseDealGroupData(data.meta.offset);
          this.isFetching = false;
        });
      }
    }, debounceDelay),
    setStatus: function () {
      const status = this.$(".deal-status-select")[0].value;
      switch (status) {
        case "all":
          this.activeStatusGet = false;
          this.allStatusGet = true;
          this.inactiveStatusGet = false;
          break;
        case "inactive":
          this.activeStatusGet = false;
          this.allStatusGet = false;
          this.inactiveStatusGet = true;
          break;
        default:
          this.activeStatusGet = true;
          this.allStatusGet = false;
          this.inactiveStatusGet = false;
          break;
      }
    },
    load: function () {
      const orgs = Organizations.getOrganizations();
      this.orgId = orgs.selected;
      this.selectedOrg = orgs._byId[Number(orgs.selected)];
      this.render().then(() => {
        this.loadExecute();
      });
    },
    loadExecute: function () {
      const dealsRadioButton = this.$(".media-type-deals")[0];
      const dealGroupsRadioButton = this.$(".media-type-deal-groups")[0];
      const fetchFunc = this.showDealGroups ? "fetchDealGroups" : "fetchDeals";
      const parseFunc = this.showDealGroups ? "parseDealGroupData" : "parseDealData";
      if (T1Permissions.check("feature", "deal_export")) {
        this.$(".choose-action").removeAttr("hidden");
      }
      dealsRadioButton.checked = !this.showDealGroups;
      dealGroupsRadioButton.checked = this.showDealGroups;
      this.allSearchGet = true;
      this.setStatus();
      setTimeout(() => {
        const selectAllCheck = this.$("#selectAll");
        const dealGrid = this.showDealGroups ? this.$(".deal-groups-grid")[0] : this.$(".deals-grid")[0];
        selectAllCheck.attr("hidden", "");
        dealGrid.isLoading = true;
        dealGrid.noResultsMessage = this.showDealGroups ? "No deal groups available." : "No deals available.";
      }, renderDelay);
      When.all([this.fetchSupply(), this[fetchFunc](false)]).then(() => {
        this.enableScroll();
        this.$(".deals-bottom-remaining").html(`<div>${this.totalCount} Deals Remaining</div>`);
        this[parseFunc]();
      });
    },
    loadSearch: _.debounce(function (val) {
      const searchIdName = this.$(".deal-search-select")[0];
      const searchValue = val.originalEvent.detail.value;
      const grid = this.showDealGroups ? this.$(".deal-groups-grid")[0] : this.$(".deals-grid")[0];
      const fetchFunc = this.showDealGroups ? "fetchDealGroups" : "fetchDeals";
      const parseFunc = this.showDealGroups ? "parseDealGroupData" : "parseDealData";
      const notDealNameGet = this.dealNameGet === false;
      const notDealIdGet = this.dealIdGet === false;
      const isActiveStatusGet = this.activeStatusGet === true;
      const isAllStatusGet = this.allStatusGet === true;
      const isInactiveStatusGet = this.inactiveStatusGet === true;
      const minSearchReq = 2;
      grid.isLoading = true;
      if (notDealNameGet && notDealIdGet && (isActiveStatusGet || isAllStatusGet || isInactiveStatusGet)) {
        this.dealNameGet = true;
      }
      if (searchValue === "" || searchValue === null) {
        this.$(".search-warning-deals").attr("hidden", "");
        this.$(".search-deals").removeAttr("error");
        this.allSearchGet = true;
        this.dealNameGet = false;
        this.dealIdGet = false;
        When.all([this[fetchFunc](false)]).then(() => {
          this.$(".deals-bottom-remaining").html(`<div>${this.totalCount} Deals Remaining</div>`);
          this.isFirstDealCall = true;
          this[parseFunc]();
        });
      } else if (searchValue.length > 0 && searchValue.length < minSearchReq && searchValue !== " ") {
        this.$(".search-warning-deals").removeAttr("hidden");
        this.$(".search-deals").attr("error", "");
        grid.isLoading = false;
      } else if (searchValue.trim().length === 0) {
        grid.isLoading = false;
      } else {
        this.$(".search-warning-deals").attr("hidden", "");
        this.$(".search-deals").removeAttr("error");
        this.allSearchGet = false;
        if (searchIdName.value === "id") {
          this.dealIdGet = true;
          this.dealNameGet = false;
        } else if (searchIdName.value === "name") {
          this.dealNameGet = true;
          this.dealIdGet = false;
        }
        When.all([this[fetchFunc](searchValue)]).then(() => {
          this.$(".deals-bottom-remaining").html(`<div>${this.totalCount} Deals Remaining</div>`);
          this.isFirstDealCall = true;
          this[parseFunc]();
        });
      }
    }, searchDelay),
    loadStatus: function () {
      const searchValue = this.$(".search-deals")[0].value;
      const grid = this.showDealGroups ? this.$(".deal-groups-grid")[0] : this.$(".deals-grid")[0];
      const fetchType = this.showDealGroups ? "fetchDealGroups" : "fetchDeals";
      const parseType = this.showDealGroups ? "parseDealGroupData" : "parseDealData";
      grid.isLoading = true;
      if (searchValue === "" || searchValue === null || searchValue === undefined) {
        this.dealNameGet = false;
        this.dealIdGet = false;
        this.allSearchGet = false;
        When.all([this[fetchType](false)]).then(() => {
          this.$(".deals-bottom-remaining").html(`<div>${this.totalCount} Deals Remaining</div>`);
          this.isFirstDealCall = true;
          this[parseType]();
        });
      } else if (searchValue.length > 1) {
        this.allSearchGet = false;
        When.all([this[fetchType](searchValue)]).then(() => {
          this.$(".deals-bottom-remaining").html(`<div>${this.totalCount} Deals Remaining</div>`);
          this.isFirstDealCall = true;
          this[parseType]();
        });
      }
    },
    getFormattedTime: function (timeInput) {
      const time = timeInput || new Date(Date.now());
      const formattedTime = moment(time).format("YYYY-MM-DD_HH-mm-ss");
      return formattedTime;
    },
    parseDealData: function (page) {
      var gridArray = this.gridArray = [];
      var supply_obj = this.supplyCollection.models.reduce(function (result, item) {
        result[item.attributes.id] = item.attributes.name;
        return result;
      }, {});
      this.sendArray.forEach(function (obj) {
        obj.price_display = T1Currencies.formatNumber(obj.price.currency_code, obj.price.value);
      });
      gridArray = ParseDeals.getDeals(this.sendArray, supply_obj);
      gridArray.forEach(row => {
        row.currency_symbol = T1.Utils.getCurrencySymbol(row.currency_code);
        row.selected = Boolean(this.selected[row.deal_id]);
      });
      this.updateGrid(gridArray, page);
    },
    parseDealGroupData: function (page) {
      if (this.dealGroups.length) {
        this.updateGrid(this.dealGroups, page);
      } else {
        const dealGroupsGrid = this.el.find(".deal-groups-grid")[0];
        dealGroupsGrid.data = this.dealGroups;
        dealGroupsGrid.isLoading = false;
      }
    },
    updateGrid: function (array, page) {
      const grid = this.showDealGroups ? this.$(".deal-groups-grid")[0] : this.$(".deals-grid")[0];
      const gridPage = page ? page : 0;
      if (this.isFirstDealCall) {
        grid.splice(...["data", 0, array.length].concat(array));
      } else {
        grid.splice(...["data", gridPage, apiLimit].concat(array));
      }
      grid.isLoading = false;
    },
    sort: function (event) {
      var grid = this.el.find(".deals-grid")[0];
      var field = event.originalEvent.detail.field;
      var sortOrder = event.originalEvent.detail.val;
      var gridData = SortDeals.sortDeals(grid.data, field, sortOrder);
      var sortList = ["data", 0, gridData.length].concat(gridData);
      grid.splice(...sortList);
    },
    sortDealGroups: function (event) {
      var grid = this.el.find(".deal-groups-grid")[0];
      grid.setAllExpansions(false);
      const field = event.originalEvent.detail.field;
      const sortOrder = event.originalEvent.detail.val;
      const gridData = SortDeals.sortDealGroups(grid.data, field, sortOrder);
      const sortList = ["data", 0, gridData.length].concat(gridData);
      grid.splice(...sortList);
    },
    sortDealGroupDealGrid: function (event) {
      var grid = event.originalEvent.target.closest("strand-grid");
      var field = event.originalEvent.detail.field;
      var sortOrder = event.originalEvent.detail.val;
      var gridData = SortDeals.sortDeals(grid.data, field, sortOrder);
      var sortList = ["data", 0, gridData.length].concat(gridData);
      grid.splice(...sortList);
    },
    toggleCanActivate: function () {
      var dealActivate = $(this.el).find(".dealActivate");
      var rows = this.gridArray;
      var nRows = rows.length;
      var canDeal = false;
      var i;
      for (i = 0; i < nRows; ++i) {
        if (rows[i].selected) {
          canDeal = true;
          break;
        }
      }
      if (canDeal) {
        dealActivate.removeAttr("disabled");
      } else {
        dealActivate.attr("disabled", "");
      }
    },
    updateActivation: function (e) {
      if (e.srcElement.innerText === "Activate") {
        this.postDeals(1);
      } else if (e.srcElement.innerText === "Deactivate") {
        this.postDeals(0);
      }
    },
    postDeals: function (param) {
      var dealPostObject = new Deal();
      var postArray = this.gridArray.filter(val => val.selected === true);
      for (let i = 0; i < postArray.length; i++) {
        const postObj = {
          advertiser_id: postArray[i].advertiser_id,
          currency_code: postArray[i].currency_code,
          deal_source: postArray[i].deal_source,
          description: postArray[i].description,
          end_datetime: postArray[i].end_datetime,
          media_type: postArray[i].media_type,
          name: postArray[i].price,
          price: postArray[i].price,
          price_method: postArray[i].price_method,
          price_type: postArray[i].price_type,
          publisher_id: postArray[i].publisher_id,
          start_datetime: postArray[i].start_datetime,
          status: param,
          supply_source_id: postArray[i].supply_source_id,
          zone_name: postArray[i].zone_name
        };
        dealPostObject.createDeal(postArray[i].currencyCode, postObj, {
          success: () => {
            this.updateGrid(this.gridArray.concat([postArray[i]]).filter(Boolean));
          }
        });
      }
    },
    render: function () {
      var deferred = $.Deferred();
      Polymer.dom(this.el[0]).innerHTML = this.template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  return pmpe;
});
