define('collections/t1AppPermission',["jQuery", "Underscore", "T1", "T1Collection", "models/t1AppPermission", "T1Sortable"], function ($, _, T1, T1Collection, Model, Sortable) {
  "use strict";

  var sortConfiguration = {
    fields: {
      currentTime: {
        actual: ""
      }
    },
    page_limit: {
      actual: ""
    }
  };
  var collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    currentTime: "",
    model: Model,
    urlString: "organization_partner_types?with=partner_types&full=*&q=partner_types_id==16&"
  });
  T1.Models.T1AppsDashboard = Sortable.extendCollection(collection, sortConfiguration);
  return T1.Models.T1AppsDashboard;
});
