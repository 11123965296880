define('modules/auth/views/login',["jQuery", "T1", "T1Comm", "T1Permissions", "T1View", "models/auth", "models/passwordReset", "text!modules/auth/templates/login.html", "Mustache", "models/sessionUser", "models/userPreferences", "T1Spinner", "T1Layout", "utils/Auth", "jQueryUI", "T1Loader"], function ($, T1, T1Comm, T1Permissions, T1View, AuthModel, PasswordResetModel, template, Mustache, User, UserPrefs, Spinner, T1Layout, Auth) {
  "use strict";

  var viewContainers = {
    login: ".w-login-c",
    forgotPassword: ".w-forgot-password-c",
    emailSent: ".w-email-sent-c"
  };
  const ANIM_DURATION = 300;
  const ENTER_KEY = 13;
  const isValidEmail = function () {
    const maxEmailLength = 254;
    const maxEmailUsernameLength = 64;
    const maxEmailDomainPartsLength = 63;
    const emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    return function (email) {
      if (!email) {
        return false;
      }
      if (email.length > maxEmailLength) {
        return false;
      }
      const valid = emailRegex.test(email);
      if (!valid) {
        return false;
      }
      const parts = email.split("@");
      if (parts[0].length > maxEmailUsernameLength) {
        return false;
      }
      const domainParts = parts[1].split(".");
      if (domainParts.some(part => part.length > maxEmailDomainPartsLength)) {
        return false;
      }
      return true;
    };
  }();
  return T1View.extend({
    template: template,
    model: new AuthModel(),
    passwordResetModel: new PasswordResetModel(),
    currentViewContainer: "",
    widgetMode: false,
    inServiceRequest: false,
    isRememberMeChecked: false,
    adBlockDetected: sessionStorage.getItem("browser.adBlockEnabled"),
    initialize: function (args) {
      var self = this;
      var eventHub = T1.EventHub;
      this.widgetMode = args && args.mode ? args.mode : "dialog";
      if (args.mode !== "dialog") {
        this.versionInfo = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".version-information",
          template: '<div class="version-content"></div>',
          layout: {
            ".version-content": [{
              module: "version",
              viewType: "info"
            }]
          }
        });
      }
      eventHub.subscribe("onUserLoginOk", function () {
        var element = $(self.el);
        if (self.isDialog) {
          element.dialog("close");
          self.resetForm();
        } else {
          element.fadeOut("fast", function () {
            element.remove();
          });
        }
      });
      if (localStorage.getItem("has_auth_required_been_published") && self.widgetMode === "dialog") {
        self.render();
        self.displayDialog();
      } else if (!T1Permissions.check("feature", "auth_0")) {
        eventHub.subscribe("AuthRequired", function () {
          if (self.widgetMode === "dialog") {
            self.render();
            self.displayDialog();
          }
        });
      }
      self.currentViewContainer = viewContainers.login;
      self.isRememberMeChecked = window.localStorage.getItem("isRememberMeChecked");
      localStorage.removeItem("has_auth_required_been_published");
    },
    load: function () {
      var self = this;
      var queryString;
      queryString = window.location.search;
      if (T1Permissions.check("feature", "mm_social")) {
        this.mmSocial = this.checkIfMmSocial("mmsocial", queryString);
      }
      if (this.widgetMode !== "dialog") {
        this.render().then(function () {
          var viewOptions = T1.Location.getViewOptions();
          var $el = $(self.el);
          var $userName = $el.find(".user-name");
          var $checkbox = $el.find(".remember-me");
          var userName = window.localStorage.getItem("user");
          if (self.isRememberMeChecked === "true") {
            $checkbox.attr("checked", "checked");
            if (userName !== "") {
              $userName.val(userName);
            }
          }
          if (viewOptions && viewOptions.noAccess === true) {
            self.showNoAccessMessage();
          }
        });
        self.displayInlineView();
      }
    },
    checkIfMmSocial: function (name, queryString) {
      var regex, results;
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
      results = regex.exec(queryString);
      if (results === null) {
        return false;
      } else if (decodeURIComponent(results[1].replace(/\+/g, " ")) === "1" || decodeURIComponent(results[1].replace(/\+/g, " ")) === "true") {
        return true;
      }
      return false;
    },
    pause: function () {
      this.load();
    },
    displayInlineView: function () {
      var self = this;
      var $el = $(self.el);
      var widget = $el.find(".w-Auth");
      widget.addClass("w-AuthInline");
      setTimeout(function () {
        $el.find(".user-name").focus();
      }, 0);
      self.versionInfo.load();
      return true;
    },
    displayDialog: function () {
      var $el = $(this.el);
      var setAsOpen = !T1Comm.isUserLoggedIn();
      var $userName = $el.find(".user-name");
      var $password = $el.find(".password");
      var $checkbox = $el.find(".remember-me");
      var name = window.localStorage.getItem("user");
      var password = window.localStorage.getItem("password");
      var user, userName;
      $el.find(".user-name").focus();
      user = User.getUser();
      userName = user.get("first_name");
      userName = userName && userName.length ? ` ${userName}` : "";
      $el.find(".w-head").html(`<div class="session-msg">Sorry ${userName}, your session has timed out.</div>` + `<div class="login-msg">Please login again.</div>`);
      if (this.isRememberMeChecked === "true") {
        $checkbox.attr("checked", "checked");
        if (name !== "") {
          $userName.val(name);
        }
        if (password !== "") {
          $password.val(password);
        }
      }
      $el.dialog({
        dialogClass: "w-LoginDialog",
        closeText: "",
        autoOpen: setAsOpen,
        modal: true
      });
      $el.dialog("open");
      this.isDialog = true;
    },
    events: {
      "click .login-action": "performLogin",
      "keydown .password": "performLoginOnEnter",
      "keydown .user-name": "performLoginOnEnter",
      "click .forgot-password": "forgotPassword",
      "click .email-action": "submitEmail",
      "keydown .email-address": "submitEmailOnEnter",
      "click .cancel": "returnToLogin",
      "click .return-to-login": "returnToLogin",
      "click .remember-me": "onRememberMeCheckboxClick"
    },
    performLoginOnEnter: function (e) {
      var $el = $(this.el);
      var loginBtn;
      $(e.currentTarget).removeClass("error");
      if (e.which === ENTER_KEY) {
        e.preventDefault();
        loginBtn = $el.find(".login-action");
        if (loginBtn.length) {
          this.performLogin(loginBtn);
        }
      }
    },
    showError: function (errorMessage) {
      const errorTemplate = "{{#errors}}<li>{{.}}</li>{{/errors}}";
      this.el.find(".error-list").html(Mustache.to_html(errorTemplate, {
        errors: [errorMessage]
      }));
      this.el.find(".w-errorBox").fadeIn();
    },
    hideErrors: function () {
      var $el = $(this.el);
      var userName = $el.find(".user-name");
      var password = $el.find(".password");
      var email = $el.find(".email-address");
      userName.removeClass("error");
      password.removeClass("error");
      email.removeClass("error");
      $el.find(".w-errorBox").fadeOut(ANIM_DURATION);
    },
    showNoAccessMessage: function () {
      var $el = $(this.el);
      var msgTemplate = "<li>You do not have the necessary permissions to access TerminalOne.</li>";
      $el.find(".error-list").html(Mustache.to_html(msgTemplate));
      $el.find(".w-errorBox").fadeIn();
    },
    updateCredentialDataOnInstance: function () {
      this.usernameField = this.el.find(".user-name");
      this.passwordField = this.el.find(".password").not(".placeholder");
      this.emailField = this.el.find(".email-address");
      this.username = $.trim(this.usernameField.val());
      this.password = $.trim(this.passwordField.val());
      this.email = $.trim(this.emailField.val());
      this.credentials = {
        user: this.username,
        password: this.password
      };
    },
    validateCredentialsAsync: function () {
      return new Promise((resolve, reject) => {
        if (!this.username.length && !this.password.length) {
          this.usernameField.addClass("error");
          this.passwordField.addClass("error");
          reject(new Error("Please enter your username and password"));
        } else if (!this.username.length) {
          this.usernameField.addClass("error");
          reject(new Error("Please enter your username"));
        } else if (!this.password.length) {
          this.passwordField.addClass("error");
          reject(new Error("Please enter your password"));
        } else if (COMPASS_ENV.TIER === "PRODUCTION" && !isValidEmail(this.username)) {
          this.usernameField.addClass("error");
          reject(new Error("Username must be an email address"));
        } else if (this.username.toLowerCase().indexOf("@mediamath.com") !== -1) {
          Auth.authorize();
        } else {
          resolve();
        }
      });
    },
    handleCredentialRemembrance: function () {
      if (this.isRememberMeChecked) {
        window.localStorage.setItem("user", this.username);
        window.localStorage.setItem("password", this.password);
      } else {
        window.localStorage.setItem("user", "");
        window.localStorage.setItem("password", "");
      }
    },
    requestLoginAsync: function () {
      const unauthorizedErrorCode = 401;
      return new Promise((resolve, reject) => {
        T1Comm.requestLogin({
          data: this.credentials,
          fixture: "-login",
          showNotification: !this.isDialog,
          successNotifications: "You are now logged in!",
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [unauthorizedErrorCode]
          },
          onStatusInvalid: function () {
            reject(new Error("Your email address or password are incorrect"));
          },
          preAuth: function (args, options) {
            const user = User.getUser();
            const id = args.entity.id;
            user.set({
              id: id
            });
            UserPrefs.fetch({
              success: () => {
                resolve(options && options.success());
              }
            });
          }
        });
      });
    },
    toggleLoginButtonSpinner: function (turnOn) {
      const loginButton = this.el.find(".login>button");
      loginButton.toggleClass("login-action", !turnOn);
      if (turnOn) {
        Spinner.apply(loginButton);
      } else {
        loginButton.spin(false);
      }
    },
    performLogin: function () {
      this.updateCredentialDataOnInstance();
      this.validateCredentialsAsync().then(() => {
        this.handleCredentialRemembrance();
        if (this.model.set(this.credentials) !== false) {
          this.inServiceRequest = true;
          this.toggleLoginButtonSpinner(true);
          this.requestLoginAsync().catch(err => {
            this.showError(err.message);
            this.toggleLoginButtonSpinner(false);
            this.inServiceRequest = false;
          });
        }
      }).catch(err => this.showError(err.message));
    },
    forgotPassword: function (e) {
      e.preventDefault();
      if (!this.inServiceRequest) {
        this.changeViewContainer("forgotPassword");
      }
    },
    submitEmail: function () {
      var self = this;
      var $el = $(self.el);
      var credentials = {
        email: $.trim($el.find(".email-address").val().replace(/@mediamath\.com/i, ""))
      };
      var emailBtn = $el.find(".email-action");
      if (!credentials.email.length) {
        self.showErrors();
        return;
      }
      credentials.uri = `${document.location.href.replace(/index\.html/, "").split("#")[0]}reset-password.html`;
      if (self.passwordResetModel.set(credentials) !== false) {
        self.inServiceRequest = true;
        emailBtn.toggleClass("email-action", false);
        Spinner.apply(emailBtn);
        self.passwordResetModel.fetch({
          success: function () {
            self.changeViewContainer("emailSent");
          },
          params: credentials
        });
      }
    },
    submitEmailOnEnter: function (e) {
      var $el = $(this.el);
      var emailBtn;
      $(e.currentTarget).removeClass("error");
      if (e.which === ENTER_KEY) {
        e.preventDefault();
        emailBtn = $el.find(".email-action");
        if (emailBtn.length) {
          this.submitEmail(emailBtn);
        }
      }
    },
    returnToLogin: function (e) {
      e.preventDefault();
      if (!this.inServiceRequest) {
        this.changeViewContainer("login");
      }
    },
    onRememberMeCheckboxClick: function (e) {
      this.isRememberMeChecked = e.currentTarget.checked;
      if (this.isRememberMeChecked) {
        window.localStorage.setItem("isRememberMeChecked", "true");
      } else {
        window.localStorage.setItem("isRememberMeChecked", "false");
      }
    },
    changeViewContainer: function (newViewContainerID) {
      var self = this;
      var $el = $(this.el);
      var emailBtn = $el.find(".email .action");
      var newViewContainer = viewContainers[newViewContainerID];
      if (this.currentViewContainer !== newViewContainer) {
        this.hideErrors();
        $el.find(this.currentViewContainer).fadeOut(ANIM_DURATION, function () {
          self.resetForm();
          emailBtn.toggleClass("email-action", true);
          emailBtn.spin(false);
          self.currentViewContainer = newViewContainer;
          $el.find(self.currentViewContainer).fadeIn(ANIM_DURATION);
          if (newViewContainerID === "login") {
            $el.find(".user-name").focus();
          } else if (newViewContainerID === "forgotPassword") {
            $el.find(".email-address").focus();
          }
        });
      }
    },
    unload: function () {
      var eventHub = T1.EventHub;
      eventHub.publish("login:unload");
      eventHub.unsubscribe("login:unload");
      eventHub.unsubscribe("AuthRequired");
      $(this.el).dialog("destroy");
      $(this.el).remove();
      this.destroy();
    },
    resetForm: function () {
      var $el = $(this.el);
      $el.find(".user-name").val("");
      $el.find(".password").val("");
      $el.find(".email-address").val("");
      this.inServiceRequest = false;
    },
    detectIE: function () {
      if (navigator.appName === "Microsoft Internet Explorer" && navigator.userAgent.indexOf("MSIE") !== -1) {
        return true;
      } else if (navigator.appName === "Netscape" && navigator.userAgent.indexOf("Trident") !== -1) {
        return true;
      }
      return false;
    },
    serialize: function () {
      var obj = {};
      var IE, fireFox, windowsOS;
      if (this.widgetMode !== "dialog") {
        IE = this.detectIE();
        fireFox = navigator.userAgent.indexOf("Firefox") !== -1;
        windowsOS = navigator.appVersion.indexOf("Win") !== -1;
        if (IE) {
          obj.warning = "For the best experience, we recommend that you use the most recent version of either " + "Chrome, Firefox, or Safari.";
        } else if (windowsOS && fireFox) {
          obj.warning = "T1 performs best in a browser that supports Web Components. We recommend using Chrome for " + "optimal experience. For more information, visit the " + '<a href="https://kb.mediamath.com/wiki/x/XgCR" target="_blank">Knowledge Base</a>.';
        }
        if (this.adBlockDetected) {
          obj.adBlockDetected = true;
        }
      }
      obj.mmSocial = this.mmSocial;
      return obj;
    }
  });
});
