define('models/onboarding',["jQuery", "T1", "T1Model", "T1Comm"], function ($, T1, T1Model) {
  "use strict";

  T1.Models.Onboarding = T1Model.extend({
    apiRoot: T1.ENV.SEGMENT_API_BASE,
    urlRoot: "onboarding/",
    formatOptions: [{
      format: "TXT",
      checked: true
    }],
    url: function () {
      var setupMethod = this.method === "Full Audience View" ? "CMS" : this.method;
      var implementation = setupMethod ? "setup?method=" + setupMethod + "&onboarding_method=" + this.onboardMethod : "";
      if (this.onboardMethod === "media") {
        implementation = "setup?onboarding_method=" + this.onboardMethod;
      }
      return this.apiRoot + this.urlRoot + implementation;
    },
    save: function (opts) {
      var self = this;
      var saveData = self.dataToSaveObj(true);
      var ids = this.id ? this.id : "";
      $.ajax({
        type: "POST",
        contentType: "application/json",
        url: self.apiRoot + self.urlRoot + "groups/" + ids,
        success: opts.success,
        error: opts.error,
        dataType: "json",
        data: JSON.stringify(saveData)
      });
    },
    fetchDownload: function (opts) {
      $.ajax({
        type: "GET",
        url: `${T1.ENV.SEGMENT_API_BASE}onboarding/groups/${this.id}?delivery_method=download`,
        success: opts.success,
        error: opts.error,
        dataType: "text"
      });
    },
    dataToSaveObj: function (save, delivery, data) {
      var self = data ? data : this;
      if (self.events) {
        var {
          undefined: standard_events,
          true: custom_events
        } = _.groupBy(self.events, "custom");
        self.standard_events = standard_events || [];
        self.custom_events = custom_events || [];
      }
      var saveObj = {
        advertiser_id: Number.parseInt(self.advertiser_id, 10),
        name: self.name,
        method: self.method,
        standard_events: self.standard_events.filter(event => event.key).map(event => {
          var obj = {};
          if (event.id) {
            obj.id = event.id;
          }
          return Object.assign(obj, {
            event_name: event.key,
            pixel_name: event.name || event.key
          });
        }),
        custom_events: self.custom_events.filter(event => event.name).map(event => {
          var obj = {};
          if (event.id) {
            obj.id = event.id;
          }
          return Object.assign(obj, {
            event_name: event.key,
            pixel_name: event.name
          });
        }),
        standard_attributes: self.standard_attributes.filter(attr => attr.name).map(attr => {
          if (!attr.note) {
            delete attr.note;
          }
          return attr;
        }),
        custom_attributes: self.custom_attributes.filter(attr => attr.key).map(attr => {
          Object.assign(attr, {
            key: !attr.key.includes("query") ? "query." + attr.key.toLowerCase() : attr.key.toLowerCase()
          });
          if (!attr.note) {
            delete attr.note;
          }
          return attr;
        })
      };
      if (save) {
        if (this.delivery_method.includes("view")) {
          this.postLoadView = true;
        }
        if (this.delivery_method.includes("download")) {
          if (this.delivery_method.length > 1) {
            saveObj.delivery_method = [...this.delivery_method];
            let downloadIndex = saveObj.delivery_method.indexOf("download");
            saveObj.delivery_method.splice(downloadIndex, 1);
          } else {
            saveObj.delivery_method = ["view"];
          }
          this.postLoadDownload = true;
        } else {
          saveObj.delivery_method = this.delivery_method;
        }
        if (this.email_to) {
          saveObj.email_to = this.email_to;
        }
        if (this.email_message) {
          saveObj.email_message = this.email_message;
        }
        if (this.delivery_format) {
          saveObj.delivery_format = this.delivery_format;
        }
      } else if (delivery) {
        saveObj.delivery_method = [delivery];
      }
      if (self.tagManagement && self.method === "Tag Manager") {
        saveObj.tag_manager = self.tagManagement;
      }
      if (self.tag_manager_other) {
        saveObj.tag_manager_other = self.tag_manager_other;
      }
      if (self.method_other) {
        saveObj.method_other = self.method_other;
      }
      if (self.revenuePairEnabled) {
        saveObj.revenue = self.revenue;
        saveObj.currency = self.currency;
      }
      return saveObj;
    },
    dataToRepeater: function (arr, type) {
      return arr.map(function (item) {
        if (item.name === "Floodlight Container" || item.name === "CMS") {
          item.value = item.name;
          item.name = item.name === "Floodlight Container" ? "Floodlight / iFrame" : "CMS / Direct on Site";
        }
        return Object.assign(item, {
          name: item.name || item.key,
          value: item.value || item.name || item.key,
          key: item.key || item.value
        });
      });
    },
    loadGroup: function (id, loadOptions) {
      var fetchPromise = $.Deferred();
      var viewOption = loadOptions && loadOptions.makeViewCall ? "?delivery_method=view" : "";
      var formatEventData = function (data) {
        data.standard_events = data.standard_events.filter(event => event.eligible).map(event => {
          delete event.eligible;
          return event;
        });
        data.custom_events = data.custom_events.filter(event => event.eligible).map(event => {
          delete event.eligible;
          return Object.assign(event, {
            custom: true
          });
        });
        data.events = [...data.standard_events, ...data.custom_events];
        return data;
      };
      var formatReturnedData = function (data) {
        var nonZeroRepeater = function (arr) {
          if (arr.length === 0) {
            arr = [{
              cId: 0
            }];
          }
          return arr;
        };
        data.events = nonZeroRepeater(data.events).map(event => {
          let tempEvent = {
            id: event.id,
            key: event.event_name,
            name: event.pixel_name || event.event_name
          };
          if (event.custom) {
            tempEvent.custom = true;
          }
          return tempEvent;
        });
        data.standard_attributes = data.standard_attributes.map(attr => {
          if (!attr.note) {
            delete attr.note;
          }
          return attr;
        });
        data.custom_attributes = data.custom_attributes.map(attr => {
          if (attr.key) {
            Object.assign(attr, {
              key: attr.key.substr(6).toUpperCase()
            });
          }
          return attr;
        });
        return data;
      };
      $.ajax({
        type: "GET",
        url: T1.ENV.SEGMENT_API_BASE + "onboarding/groups/" + id + viewOption,
        success: obj => {
          var data = formatEventData(obj.data);
          this.loadedData = JSON.parse(JSON.stringify(data));
          data = formatReturnedData(data);
          Object.assign(this, data);
          fetchPromise.resolve(data);
        },
        error: err => {
          fetchPromise.resolve(err);
        }
      });
      return fetchPromise.promise();
    },
    fetchImplementationMethods: function (cb, adv) {
      var fetchPromise = $.Deferred();
      var advertiserId = adv ? "&advertiser_id=" + this.advertiser_id : "";
      $.ajax({
        type: "GET",
        url: T1.ENV.SEGMENT_API_BASE + "onboarding/implementation_methods?onboarding_method=" + this.onboardMethod + advertiserId,
        success: data => {
          var {
            methods: methods,
            tag_managers: tag_managers
          } = data;
          cb(this.dataToRepeater(methods), this.dataToRepeater(tag_managers));
          fetchPromise.resolve(data);
        },
        error: error => {
          fetchPromise.resolve(error);
        }
      });
      return fetchPromise.promise();
    },
    fetchSetup: function () {
      var fetchPromise = $.Deferred();
      this.fetch({
        forcedContentType: "application/json",
        postJson: true,
        success: function (model, response) {
          model.standard_events = response.standard_events;
          model.standard_attributes = response.standard_attributes;
          model.attribution = response.attribution;
          model.standard_events.optional.push({
            name: "Custom",
            key: "custom"
          });
          fetchPromise.resolve();
        },
        onError: function (err) {
          fetchPromise.resolve(err);
        },
        processAjaxResponse: function (resp) {
          return {
            jsonData: resp,
            statusCode: "ok"
          };
        }
      });
      return fetchPromise.promise();
    },
    fetchEventDefaults: function (section) {
      return this.dataToRepeater(this.standard_events[section]);
    },
    fetchAttributeDefaults: function () {
      return this.dataToRepeater(this.standard_attributes.default);
    },
    fetchCustomAttributeKeys: function () {
      const numOfKeys = 20;
      const keyArr = [];
      function addKeys(prefix) {
        for (let i = 1; i < numOfKeys + 1; i++) {
          keyArr.push({
            value: "query." + prefix.toLowerCase() + i,
            name: prefix + i,
            key: "query." + prefix.toLowerCase() + i
          });
        }
      }
      addKeys("V");
      addKeys("S");
      return this.dataToRepeater(keyArr.slice(2));
    },
    fetchAttributeOptions: function () {
      return this.dataToRepeater(this.standard_attributes.default.concat(this.standard_attributes.optional));
    }
  });
  return T1.Models.Onboarding;
});
