define('modules/admin/user/views/createEdit',['require','Underscore','jQuery','utils/AdminUtils','utils/Auth','text!modules/admin/user/templates/createEditBody.html','text!modules/admin/user/templates/email.html','text!modules/admin/templates/header.html','text!modules/admin/user/templates/password.html','models/sessionUser','T1','T1Layout','T1Permissions','T1UtilsAsync','T1View','text!modules/admin/user/templates/createEdit.html','utils/TimezoneUtils','models/user','modules/admin/user/models/userPermissions','modules/admin/user/views/permissionsGrid'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const AdminUtils = require("utils/AdminUtils");
  const Auth = require("utils/Auth");
  const bodyTemplate = require("text!modules/admin/user/templates/createEditBody.html");
  const emailTemplate = require("text!modules/admin/user/templates/email.html");
  const headTemplate = require("text!modules/admin/templates/header.html");
  const passwordTemplate = require("text!modules/admin/user/templates/password.html");
  const SessionUser = require("models/sessionUser");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Permissions = require("T1Permissions");
  const T1UtilsAsync = require("T1UtilsAsync");
  const T1View = require("T1View");
  const template = require("text!modules/admin/user/templates/createEdit.html");
  const TimezoneUtils = require("utils/TimezoneUtils");
  const User = require("models/user");
  const UserPermissions = require("modules/admin/user/models/userPermissions");
  const PermissionsGrid = require("modules/admin/user/views/permissionsGrid");
  const isAuth0On = T1Permissions.check("feature", "auth_0");
  const timezone = TimezoneUtils.getClientTimezoneAbbr();
  const convertToLocalTime = timezone === "GMT" ? T1.parseDateAsUTC : TimezoneUtils.convertUTCToLocalTime;
  const dateFormat = "MM/dd/yyyy hh:mm TT ";
  function updateBreadCrumb(data, header) {
    let pathPieces;
    data.entityName = "Users";
    if (header !== undefined) {
      if (Polymer && Polymer.dom) {
        Polymer.dom(header[0]).innerHTML = data.name;
      } else {
        header.text(data.name);
      }
    }
    if (data.id) {
      pathPieces = [];
      pathPieces.push({
        label: data.name,
        location: `#admin/user/${data.id}`
      });
    } else {
      pathPieces = [{
        label: "New User",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: AdminUtils.getBreadCrumbHeaderInfo(data, true)
    });
  }
  const UserCreateEdit = T1View.extend({
    template: template,
    partials: {
      header: headTemplate,
      body: bodyTemplate,
      email: emailTemplate,
      password: passwordTemplate
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveAndNew"
      }
    },
    events: {
      "click .edit_campaigns .edit": "editCampaignsClick",
      "click .edit_margins .edit": "editMarginClick",
      "click .edit-password": "onEditPasswordClick",
      "click .reset-password-link": "onResetPasswordLinkClick",
      "click .show-allowed-entities": "onShowAllowedEntitiesClick",
      "click .user_role": "removeNoChange",
      "click .user_scope": "removeNoChange",
      "click .user_type": "removeNoChange",
      "keypress .userNameInput": "filterUserName",
      "mousedown .entities-grid .checkbox": "clearNoEntitiesError",
      "toggleShowAllowed .permission-grid": "toggleShowAllowed",
      "value-changed strand-checkbox": "savePermissionState"
    },
    onResetPasswordLinkClick(evt) {
      const usersEmail = this.model.get("email");
      const spinnerElem = this.$(".reset-password-message-spinner");
      const successMessage = this.$(".reset-password-message-success");
      evt.preventDefault();
      successMessage.hide();
      spinnerElem.show();
      Auth.sendPasswordResetEmail(usersEmail).then(() => {
        spinnerElem.hide();
        successMessage.show();
      });
    },
    canLoad: false,
    isShowAllowedEntities: false,
    eventhubEvents: {
      "search:onSearchRequest": function ({
        field: field,
        value: value
      }) {
        this.filterCollection(field, value, value.length ? value : undefined);
      },
      "search:clearSearchClicked": function ({
        field: field,
        value: value
      }) {
        this.filterCollection(field, value, undefined);
      },
      "search:filterChanged": function ({
        value: value
      }) {
        this.filterValue = value;
        this.applyEntityCollectionFilter(value);
        if (this.collection.searchTerm && this.collection.searchTerm !== "") {
          this.filterCollection();
        }
        this.preferences.save();
      },
      "user:savedPermissions": function () {
        T1.Notify("message", `User ${this.model.get("action") !== "new" ? "updated" : "created"} successfully!`);
        this.success(this.model);
      }
    },
    initialize(args) {
      const sessionUser = SessionUser.getUser();
      const formActions = [];
      const actionButtons = {
        cancel: {
          class: "cancel",
          label: "cancel",
          location: "#admin/users",
          uuid: "cancel"
        },
        save: {
          class: "save save-btn-secondary",
          label: "save",
          location: false,
          uuid: "save"
        },
        saveAndClose: {
          class: "saveAndClose save-btn-primary",
          label: "save & Close",
          location: false,
          uuid: "save_close"
        },
        saveAndNew: {
          class: "saveAndNew save-btn-primary",
          label: "save & add another",
          location: false,
          uuid: "save_add"
        }
      };
      this.canLoad = false;
      this.isEdit = args.id && args.id !== "create";
      formActions.push(actionButtons.cancel);
      if (this.isEdit) {
        formActions.push(actionButtons.save);
        formActions.push(actionButtons.saveAndClose);
      } else {
        formActions.push(actionButtons.saveAndClose);
        formActions.push(actionButtons.saveAndNew);
      }
      this.actionsConfig.formActions = formActions;
      this.sessionUser = sessionUser;
      this.isAuth0 = T1Permissions.check("feature", "auth_0");
      const userRole = sessionUser.get("role");
      const route = T1.Location.getRoute();
      if (!route.id && userRole !== "ADMIN") {
        T1.Location.set("#admin/users");
        return;
      }
      this.initializeData(args);
    },
    initializeData(args, inConflict) {
      this.updateModel(args).then(function modelUpdated(model) {
        const modelJSON = model.toJSON();
        this.model = model;
        this.userType = modelJSON.type;
        this.userScope = modelJSON.scope;
        this.userRole = modelJSON.role;
        this.setPreviousUser(this.userRole, this.userScope, this.userType);
        this.determineLDAP();
        updateBreadCrumb(modelJSON, undefined);
        this.canLoad = true;
        if (!inConflict) {
          this.initLayout();
        }
        this.load();
      }.bind(this));
    },
    unload() {
      if (this.layout && this.gridLayout) {
        this.layout.unload();
        this.gridLayout.unload();
      }
    },
    saveAndNew(evt) {
      const callback = {
        success: () => {
          if (this.model.get("action") === "new") {
            if (isAuth0On) {
              Auth.sendPasswordResetEmail(this.model.get("email"));
            }
            this.userType = "";
            this.userScope = "";
            this.userRole = "";
            this.setPreviousUser("", "", "");
            this.unload();
            this.model = new User({
              action: "new",
              status: 1
            });
            this.initLayout();
            this.load();
            updateBreadCrumb(this.model.toJSON(), this.$(".title strand-header"));
          }
          T1.Location.set("#admin/user");
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    saveAndClose(evt) {
      const navigationDelay = 1e3;
      const callback = {
        success: () => {
          if (isAuth0On && this.model.get("action") === "new") {
            Auth.sendPasswordResetEmail(this.model.get("email"));
          }
          this.startSpinner($(evt.currentTarget));
          setTimeout(function () {
            T1.Location.set("#admin/users");
          }, navigationDelay);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    updateModel(args) {
      let user;
      const deferred = T1UtilsAsync.makeDeferral();
      if (args.id) {
        user = new User({
          id: args.id
        });
        user.fetch({
          success: model => {
            this.loadedAsGlobalAdmin = model.get("type") === "INTERNAL" && model.get("scope") === "GLOBAL";
            this.isShowAllowedEntities = true;
            deferred.resolve(model);
          }
        });
      } else {
        user = new User({
          action: "new",
          active: "1",
          name: ""
        });
        this.isShowAllowedEntities = false;
        deferred.resolve(user);
      }
      return deferred.promise;
    },
    initLayout() {
      const model = this.model;
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: "_wrapper",
        template: template,
        serialize: this.serialize.bind(this)
      });
      this.gridLayout = new T1Layout({
        el: () => this.el,
        selectorPath: ".permission-grid",
        template: '<div class="entities-grid"></div>',
        layout: {
          ".list-search": [{
            module: "admin/shared",
            viewType: "search",
            options: {
              searchInputPlaceHolder: "Type Name",
              getSearchField: () => "name",
              showActive: false,
              filter: {
                enabled: false,
                defaultValue: this.filterValue,
                value: [{
                  name: "Organization",
                  value: "ORGANIZATION"
                }]
              }
            }
          }],
          ".entities-grid": [{
            module: "admin/user",
            viewType: "permissionsGrid",
            options: {
              id: model.id,
              isShowAllowedEntities: this.isShowAllowedEntities,
              model: model,
              createEditView: this
            }
          }]
        }
      });
    },
    load() {
      if (this.canLoad) {
        this.render().then(function rendered() {
          this.layout.load();
          this.gridLayout.load();
          this.renderPlugins();
        }.bind(this));
      }
    },
    renderPlugins() {
      const self = this;
      const $userType = this.$(".user_type");
      const $userScope = this.$(".user_scope");
      const $userRole = this.$(".user_role");
      function removeError(element) {
        if (element.hasClass("error")) {
          element.attr("original-title", "");
          element.removeClass("error");
        }
      }
      if (this.sessionUser.get("type") === "AGENCY") {
        this.userType = "AGENCY";
      }
      $userType.on("selected", function (evt) {
        removeError($(this));
        self.removeTooltip(evt);
        self.userType = evt.target.value;
        self.updateUserActions();
        self.toggleLDAP();
      });
      $userScope.on("selected", function (evt) {
        removeError($(this));
        self.userScope = evt.target.value;
        self.updateUserActions();
      });
      $userRole.on("selected", function (evt) {
        removeError($(this));
        self.removeTooltip(evt);
        self.userRole = evt.target.value;
        self.updateUserActions();
      });
      this.updateUserActions();
      T1.Form.Masks.attach($('strand-input[data-bind="phone"]'));
    },
    setPreviousUser(role, scope, type) {
      this.previousRole = role;
      this.previousScope = scope;
      this.previousType = type;
    },
    onShowAllowedEntitiesClick(evt) {
      this.toggleShowAllowed(evt, false);
      this.isShowAllowedEntities = evt.target.value;
      T1.EventHub.publish("permissions:isShowAllowedEntities", [this.isShowAllowedEntities, evt.switchedFromGlobalAdmin]);
    },
    onEditPasswordClick(evt) {
      const $password = this.$(".user-password");
      const $passwordRetype = this.$(".user-retype-password");
      if (evt.target.value === true) {
        $password.attr("disabled", false);
        $passwordRetype.attr("disabled", false);
        this.isPasswordReset = true;
      } else {
        $password.attr("disabled", true);
        $password.removeAttr("error title original-title");
        $passwordRetype.attr("disabled", true);
        $passwordRetype.removeAttr("error title original-title");
        $password.attr("value", "");
        $passwordRetype.attr("value", "");
        this.isPasswordReset = false;
      }
    },
    editCampaignsClick(evt) {
      const $siteListsCheckbox = this.$(".edit_site_lists .edit, .edit_site_lists .view");
      if ($(evt.target).val()) {
        $siteListsCheckbox.attr("checked", "checked");
      } else {
        $siteListsCheckbox.removeAttr("checked");
      }
    },
    editMarginClick(evt) {
      const $viewCheckbox = this.$(".edit_margins .view");
      if ($(evt.target).val()) {
        $viewCheckbox.attr("checked", "checked");
      } else {
        $viewCheckbox.removeAttr("checked");
      }
    },
    removeNoChange(evt) {
      if (evt && evt.currentTarget) {
        $(evt.currentTarget).data("no-formchange", false);
      }
    },
    updateUserActions(arg) {
      setTimeout(function () {
        this._updateUserActions(arg);
      }.bind(this), 0);
    },
    _updateUserActions(noChangeInShowAllowedCheckbox) {
      const {
        model: model,
        sessionUser: sessionUser,
        userRole: userRole,
        userScope: userScope,
        userType: userType
      } = this;
      let isEdit = this.isEdit;
      const isRoleChanged = this.previousRole !== userRole;
      const isTypeChanged = this.previousType !== userType;
      const isScopeChanged = this.previousScope !== userScope;
      let useExistingEditCampaignCheckboxState = false;
      let useExistingEditMarginCheckboxState = false;
      let useExistingViewOrgCheckboxState = false;
      let useExistingViewSegmentsCheckboxState = false;
      let useExistingEditSegmentsCheckboxState = false;
      let useExistingViewDataDefinitionCheckboxState = false;
      let useExistingEditDataDefinitionCheckboxState = false;
      let useExistingViewDMPReportsCheckboxState = false;
      let selfEditNotPermitted = false;
      let editForAgencyManagers = false;
      let isInternalAdmin = false;
      if ((isRoleChanged || isTypeChanged || isScopeChanged) && isEdit) {
        isEdit = false;
        model.set({
          edit_campaigns: undefined,
          edit_data_definition: undefined,
          edit_margins_and_performance: undefined,
          edit_segments: undefined,
          view_data_definition: undefined,
          view_dmp_reports: undefined,
          view_organizations: undefined,
          view_segments: undefined
        });
      }
      this.setPreviousUser(userRole, userScope, userType);
      const isAgencyMng = userType === "AGENCY" && userRole === "MANAGER";
      const isAgencyReporter = userType === "AGENCY" && userRole === "REPORTER";
      const isInternalRestrictedAdmin = userType === "INTERNAL" && userRole === "ADMIN" && userScope === "SELECT";
      const isInternalRestrictedMng = userType === "INTERNAL" && userRole === "MANAGER" && userScope === "SELECT";
      const isInternalRestrictedReporter = userType === "INTERNAL" && userRole === "REPORTER" && userScope === "SELECT";
      const isGlobalAdmin = userType === "INTERNAL" && userRole === "ADMIN" && userScope === "GLOBAL";
      const isNotReporter = !isAgencyReporter && !isInternalRestrictedReporter;
      const isSessionUser = sessionUser.get("id") === model.get("id");
      function getPermissionValue(permissionState) {
        const type = userType && permissionState.type[userType];
        const scope = userScope && type && type.scope && type.scope[userScope];
        const role = userRole && (type && type.role && type.role[userRole] || scope && scope.role[userRole]);
        return role;
      }
      if (isSessionUser && (isAgencyMng || isAgencyReporter || isInternalRestrictedAdmin || isInternalRestrictedMng || isInternalRestrictedReporter)) {
        selfEditNotPermitted = true;
      }
      if (isEdit && sessionUser.get("type") === "AGENCY" && sessionUser.get("role") === "MANAGER") {
        editForAgencyManagers = true;
      }
      if (isEdit && (isInternalRestrictedAdmin || isGlobalAdmin)) {
        isInternalAdmin = true;
      }
      const editCampaignCheckboxState = model.get("edit_campaigns") === "1" && isNotReporter || isInternalAdmin;
      const editMarginCheckboxState = model.get("edit_margins_and_performance") === "1" || isInternalAdmin;
      const viewOrgCheckboxState = model.get("view_organizations") === "1" && isNotReporter || isInternalAdmin;
      const viewSegmentsCheckboxState = model.get("view_segments") === "1";
      const editSegmentsCheckboxState = model.get("edit_segments") === "1";
      const viewDataDefinitionCheckboxState = model.get("view_data_definition") === "1";
      const editDataDefinitionCheckboxState = model.get("edit_data_definition") === "1";
      const viewDMPReportsCheckboxState = model.get("view_dmp_reports") === "1";
      if (!isEdit) {
        useExistingEditCampaignCheckboxState = model.get("edit_campaigns") !== undefined;
        useExistingEditMarginCheckboxState = model.get("edit_margins_and_performance") !== undefined;
        useExistingViewOrgCheckboxState = model.get("view_organizations") !== undefined;
        useExistingViewSegmentsCheckboxState = model.get("view_segments") !== undefined;
        useExistingEditSegmentsCheckboxState = model.get("edit_segments") !== undefined;
        useExistingViewDataDefinitionCheckboxState = model.get("view_data_definition") !== undefined;
        useExistingEditDataDefinitionCheckboxState = model.get("edit_data_definition") !== undefined;
        useExistingViewDMPReportsCheckboxState = model.get("view_dmp_reports") !== undefined;
      }
      for (const [permission, permissionState] of Object.entries(UserPermissions.userPermissions)) {
        const permissionValue = getPermissionValue(permissionState);
        if (permissionValue) {
          let $editCheckbox;
          const targetName = `.${permission}`;
          const $viewCheckbox = this.$(`${targetName} .view`);
          $viewCheckbox.attr({
            checked: permissionValue.view.isChecked,
            disabled: permissionValue.view.isDisabled
          });
          if (permission !== "view_reports" && permission !== "view_dmp_reports") {
            $editCheckbox = this.$(`${targetName} .edit`);
            $editCheckbox.attr({
              checked: permissionValue.edit.isChecked,
              disabled: permissionValue.edit.isDisabled
            });
          }
          if (permission === "edit_campaigns") {
            if (isEdit || useExistingEditCampaignCheckboxState) {
              $editCheckbox.attr({
                checked: editCampaignCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.edit.isDisabled
              });
            }
          }
          if (permission === "edit_site_lists") {
            if (isEdit || useExistingEditCampaignCheckboxState) {
              $editCheckbox.attr({
                checked: editCampaignCheckboxState,
                disabled: true
              });
              $viewCheckbox.attr({
                checked: editCampaignCheckboxState,
                disabled: true
              });
            }
          }
          if (permission === "edit_margins") {
            $editCheckbox = this.$(`${targetName} .edit`);
            const isAdmin = userRole === "ADMIN";
            const canViewMargin = isAdmin || editMarginCheckboxState === true;
            $editCheckbox.attr({
              checked: isEdit || useExistingEditMarginCheckboxState ? editMarginCheckboxState : isAdmin,
              disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.edit.isDisabled
            });
            $viewCheckbox.attr({
              checked: canViewMargin,
              disabled: true
            });
          }
          if (permission === "view_organizations") {
            if (isEdit || useExistingViewOrgCheckboxState) {
              $viewCheckbox.attr({
                checked: viewOrgCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.view.isDisabled
              });
            }
          }
          if (permission === "edit_segments") {
            if (isEdit || useExistingViewSegmentsCheckboxState) {
              $viewCheckbox.attr({
                checked: viewSegmentsCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.view.isDisabled
              });
            }
            if (isEdit || useExistingEditSegmentsCheckboxState) {
              $editCheckbox.attr({
                checked: editSegmentsCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.edit.isDisabled
              });
            }
          }
          if (permission === "edit_data_definition") {
            if (isEdit || useExistingViewDataDefinitionCheckboxState) {
              $viewCheckbox.attr({
                checked: viewDataDefinitionCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.view.isDisabled
              });
            }
            if (isEdit || useExistingEditDataDefinitionCheckboxState) {
              $editCheckbox.attr({
                checked: editDataDefinitionCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.edit.isDisabled
              });
            }
          }
          if (permission === "view_dmp_reports") {
            if (isEdit || useExistingViewDMPReportsCheckboxState) {
              $viewCheckbox.attr({
                checked: viewDMPReportsCheckboxState,
                disabled: selfEditNotPermitted || editForAgencyManagers || permissionValue.view.isDisabled
              });
            }
          }
        }
      }
      this.toggleScopeDisplay(selfEditNotPermitted, noChangeInShowAllowedCheckbox);
    },
    toggleScopeDisplay(selfEditNotPermitted, noChangeInShowAllowedCheckbox) {
      const {
        isEdit: isEdit,
        sessionUser: sessionUser,
        userType: userType
      } = this;
      const $userScopeDDl = this.$(".user_scope");
      const $userScopeContainer = $userScopeDDl.closest("dl");
      const $userRoleDDl = this.$(".user_role");
      const $userTypeDDl = this.$(".user_type");
      const $permissionGrid = this.$(".entities-grid");
      const $permissionSearch = this.$(".list-filters");
      const $radioBtn = this.$(".statusRadioBtn");
      const $userName = this.$(".userNameInput");
      const $editPasswordCheckbox = this.$(".edit-password");
      const $showAllowedEntitiesCheckbox = this.$(".show-allowed-entities");
      const wasPermsHidden = $permissionGrid.is(":hidden");
      if (sessionUser.get("type") === "AGENCY") {
        $userScopeContainer.hide();
      }
      $userScopeContainer[userType === "INTERNAL" ? "show" : "hide"]();
      if (this.userScope === "GLOBAL" && userType === "INTERNAL" && $userScopeDDl.val() === "GLOBAL") {
        $userRoleDDl.attr({
          value: "ADMIN"
        });
        $userRoleDDl.attr({
          disabled: true
        });
        $showAllowedEntitiesCheckbox.hide();
        $permissionGrid.hide();
        $permissionSearch.addClass("hidden");
      } else {
        $userRoleDDl.attr("disabled", false);
        $showAllowedEntitiesCheckbox.show();
        $permissionGrid.show();
        $permissionSearch.removeClass("hidden");
        if (wasPermsHidden) {
          $showAllowedEntitiesCheckbox.prop("checked", false);
          this.onShowAllowedEntitiesClick({
            target: {
              value: false
            },
            switchedFromGlobalAdmin: true
          });
          this.filterCollection(undefined, undefined, undefined);
        }
      }
      if (!noChangeInShowAllowedCheckbox) {
        $showAllowedEntitiesCheckbox.attr("disabled", true);
      }
      if (isEdit) {
        $userName.attr("disabled", true);
      }
      if (sessionUser.get("type") === "INTERNAL" && sessionUser.get("scope") === "SELECT" && $userTypeDDl.val() === "INTERNAL") {
        $userScopeDDl.attr({
          value: "SELECT"
        });
        $userScopeDDl.attr({
          disabled: true
        });
      } else {
        $userScopeDDl.attr({
          disabled: false
        });
      }
      if (isEdit && sessionUser.get("type") === "AGENCY" && sessionUser.get("role") === "MANAGER") {
        $userRoleDDl.attr("disabled", true);
      }
      if (selfEditNotPermitted && sessionUser.get("role") !== "ADMIN" && sessionUser.get("scope") !== "SELECT") {
        $userTypeDDl.attr("disabled", true);
        $userRoleDDl.attr("disabled", true);
        $userScopeDDl.attr("disabled", true);
        $userName.attr("disabled", true);
        $editPasswordCheckbox.attr("disabled", true);
        $showAllowedEntitiesCheckbox.hide();
      }
      if (sessionUser.get("id") === this.id) {
        $radioBtn.attr("disabled", true);
      }
    },
    applyEntityCollectionFilter(entity) {
      T1.EventHub.publish("permissionsGrid:applyFilter", {
        entity: entity
      });
    },
    filterCollection(field, term, searchTerm) {
      T1.EventHub.publish("permissionsGrid:reload", {
        field: field,
        term: term,
        searchTerm: searchTerm
      });
    },
    determineLDAP() {
      const disallowLDAP = T1Permissions.check("feature", "mmuser_password_input");
      if (this.userType === "INTERNAL") {
        this.useLDAP = this.model.get("link_ldap") !== "0";
      } else {
        this.useLDAP = false;
      }
      if (disallowLDAP) {
        this.useLDAP = false;
      }
    },
    toggleLDAP() {
      const model = this.model;
      const userName = this.$(".userNameInput").val();
      const password = this.$(".user-password").val();
      const passwordRetype = this.$(".user-retype-password").val();
      this.determineLDAP();
      if (password) {
        model.set({
          password: password
        });
      }
      if (passwordRetype) {
        model.set({
          password_retype: passwordRetype
        });
      }
      if (userName && !this.isEdit) {
        model.set({
          username: ""
        });
      }
      const data = model.toJSON();
      data.isEdit = this.isEdit;
      data.useLDAP = this.useLDAP;
      data.isAuth0 = this.isAuth0;
      this.renderPartialTemplate({
        action: "html",
        data: data,
        targetEl: ".password",
        template: passwordTemplate,
        useTemplateHtml: false
      });
      this.renderPartialTemplate({
        action: "html",
        data: data,
        targetEl: ".email",
        template: emailTemplate,
        useTemplateHtml: false
      });
    },
    canUnload(args) {
      const isFormElementsChanged = this.isFormElementsChanged;
      if (!isFormElementsChanged) {
        if (args === true) {
          this.cancelUserViewEdit.onAlertClose();
        }
      } else {
        this.showUnloadAlertDiag(args === true ? this.cancelUserViewEdit : args);
      }
      return !isFormElementsChanged;
    },
    cancelUserViewEdit: {
      onAlertClose: function () {
        const T1Location = T1.Location;
        const abortedUrl = T1Location.getAbortedURL();
        if (abortedUrl === "" || abortedUrl === false) {
          setTimeout(function () {
            T1Location.set("#admin/users");
          }, 1);
        } else {
          T1Location.set(abortedUrl);
        }
      }
    },
    showUnloadAlertDiag(customRedirect) {
      const message = this.isEdit ? this.model.get("username") : "New User";
      this.openUnloadAlertDialog({
        name: message
      }, customRedirect, true);
    },
    filterUserName(evt) {
      const key = evt.key || String.fromCharCode(evt.keyCode) || "";
      if (this.allowLDAP && this.userType === "INTERNAL" && key.match(/[^a-z]/) && key.length === 1 || key.match(/,|\/|\?|<|>|;|:|"/)) {
        evt.key = "";
        evt.keyCode = null;
        evt.preventDefault();
      }
    },
    hasAssignedEntities() {
      let hasChildEntities = true;
      const isGlobalAdmin = this.userType === "INTERNAL" && this.userRole === "ADMIN" && this.userScope === "GLOBAL";
      if (!isGlobalAdmin) {
        const $entityBoxes = $(".entities-grid strand-checkbox");
        hasChildEntities = false;
        $entityBoxes.each(function (i) {
          const boxState = $entityBoxes[i].state;
          if (boxState === "partial" || boxState === "checked") {
            hasChildEntities = true;
            return false;
          }
        });
      }
      return hasChildEntities;
    },
    preparePostData(formValues) {
      formValues.edit_campaigns = formValues.edit_campaigns === undefined || false ? "0" : "1";
      formValues.edit_margins_and_performance = formValues.edit_margins_and_performance === undefined || false ? "0" : "1";
      formValues.view_organizations = formValues.view_organizations === undefined || false ? "0" : "1";
      formValues.active = formValues.active === "1" ? "on" : "off";
      formValues.view_segments = formValues.view_segments === undefined || false ? "0" : "1";
      formValues.edit_segments = formValues.edit_segments === undefined || false ? "0" : "1";
      formValues.view_data_definition = formValues.view_data_definition === undefined || false ? "0" : "1";
      formValues.edit_data_definition = formValues.edit_data_definition === undefined || false ? "0" : "1";
      formValues.view_dmp_reports = formValues.view_dmp_reports === undefined || false ? "0" : "1";
      if (this.userType === "AGENCY") {
        formValues.scope = "SELECT";
        formValues.type = "AGENCY";
      }
      if (this.id && !formValues.password && !this.isPasswordReset) {
        delete formValues.password;
      }
      if (this.id && !formValues.password_retype && !this.isPasswordReset) {
        delete formValues.password_retype;
      }
      formValues.has_assigned_entities = this.hasAssignedEntities();
      return formValues;
    },
    postData(formValues, success, statusInvalid, conflict) {
      this.model.createEdit(formValues, {
        success: returnModel => {
          if (this.userScope === "GLOBAL" && this.userType === "INTERNAL") {
            T1.EventHub.publish("user:savedSuccessful", {
              id: returnModel.get("id"),
              isGlobalAdmin: true
            });
          } else {
            T1.EventHub.publish("user:savedSuccessful", {
              id: returnModel.get("id"),
              isGlobalAdmin: false
            });
          }
          updateBreadCrumb(returnModel.toJSON(), this.$(".title strand-header"));
        },
        statusInvalid: function (errors) {
          if (errors && errors.length) {
            _.map(errors, error => error);
          }
          statusInvalid(errors, null, ".form-body");
        },
        conflict: conflict
      }, this.isPasswordReset);
      this.success = success;
    },
    onVersionConflictClose() {
      this.initializeData(this, true);
    },
    clearNoEntitiesError(evt) {
      const newCurrentTarget = this.$(".entities-grid .headers .checkbox");
      if (newCurrentTarget.length === 1) {
        this.removeTooltip({
          type: evt.type,
          currentTarget: newCurrentTarget[0]
        });
        this.updateFooterMessage();
        this.checkUnsavedState();
      }
    },
    savePermissionState(evt) {
      const origEvt = evt.originalEvent;
      const $target = $(evt.currentTarget);
      const state = origEvt && origEvt.detail && origEvt.detail.state;
      const field = $target.data("bind");
      const obj = {};
      if (field && state && this.model) {
        obj[field] = state === "checked" ? "1" : "0";
        this.model.set(obj);
      }
    },
    toggleShowAllowed(evt, enable, checked) {
      if (this.isEdit) {
        const $chkBox = this.$(".show-allowed-entities");
        $chkBox.attr("disabled", !enable);
        if (checked) {
          $chkBox.attr("checked", true);
        }
      }
    },
    serialize() {
      const modelObj = this.model ? this.model.toJSON() : {};
      const {
        isAuth0: isAuth0,
        isEdit: isEdit,
        isShowAllowedEntities: isShowAllowedEntities,
        sessionUser: sessionUser,
        useLDAP: useLDAP
      } = this;
      return Object.assign(modelObj, {
        edit_data_definition: modelObj.edit_data_definition === "1",
        edit_segments: modelObj.edit_segments === "1",
        isActive: isEdit ? modelObj.active === "1" : true,
        isAdmin: sessionUser.get("role") === "ADMIN",
        isAgencyUser: sessionUser.get("type") === "AGENCY",
        isAuth0: isAuth0,
        isEdit: isEdit,
        isManager: sessionUser.get("role") === "MANAGER",
        isReporter: sessionUser.get("role") === "REPORTER",
        isShowAllowedEntities: isShowAllowedEntities,
        last_login: modelObj.last_login_on ? convertToLocalTime(modelObj.last_login_on).toString(dateFormat) + timezone : "--",
        role: isEdit ? modelObj.role : "",
        scope: isEdit ? modelObj.scope : "",
        title: isEdit ? modelObj.username : "New User",
        type: isEdit ? modelObj.type : "",
        useLDAP: useLDAP,
        user_title: modelObj.title,
        view_data_definition: modelObj.view_data_definition === "1",
        view_dmp_reports: modelObj.view_dmp_reports === "1",
        view_organizations: modelObj.view_organizations === "1",
        view_segments: modelObj.view_segments === "1"
      });
    }
  });
  return T1.Form.ViewUtils.extend(UserCreateEdit);
});
