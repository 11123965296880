define('modules/admin/myOrgs/organization/views/details',['require','Underscore','jQuery','models/countryToRegionMapper','models/organization','models/sessionUser','T1','T1Countries','T1Permissions','T1View','text!../templates/details.html','text!../templates/terminationModal.html','When','T1Form','T1Tooltip'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const CountryToRegionMapper = require("models/countryToRegionMapper");
  const Organization = require("models/organization");
  const SessionUser = require("models/sessionUser");
  const T1 = require("T1");
  const T1Countries = require("T1Countries");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!../templates/details.html");
  const terminationModal = require("text!../templates/terminationModal.html");
  const When = require("When");
  const T1Form = require("T1Form");
  const T1Tooltip = require("T1Tooltip");
  const CAN_ACCESS_ADAPTIVE = T1Permissions.check("feature", "adaptive-segments");
  var DetailsEdit = T1View.extend({
    template: template,
    partials: {
      terminationModal: terminationModal
    },
    dataEvents: {
      model: {
        "change:id": function (model) {
          this.id = model.get("id");
          this.isEdit = true;
        }
      }
    },
    events: {
      "changed .verify-delete-org strand-input": "orgInputChanged",
      "changed strand-dropdown": "onDropDownChange",
      "change input[name=service_provider_ccpa]": "formElementsToggle",
      "click .cancel-delete": "cancelTermination",
      "click .confirm-delete": "confirmTermination",
      "click .status": "enableDisableTermination",
      "click .termination": "terminationChange"
    },
    actionsConfig: {
      formActions: [{
        class: "cancel",
        label: "cancel",
        location: "#admin"
      }, {
        class: "save save-btn-secondary",
        label: "save",
        location: false
      }, {
        class: "saveAndContinue save-btn-primary",
        label: "save & continue",
        location: false
      }],
      events: {
        "click .save": "isTerminationChecked",
        "click .saveAndContinue": "isTerminationChecked"
      }
    },
    suspiciousTrafficValue: "",
    terminationChanged: false,
    suspiciousTrafficDescription: {
      STL_STANDARD: "(filters confirmed fraudulent traffic)",
      STL_NONE: "(no traffic is filtered - NOT RECOMMENDED)",
      STL_LIGHT: "(filters confirmed fraudulent traffic and mildly suspicious traffic)",
      STL_MODERATE: "(filters confirmed fraudulent traffic and moderately suspicious traffic)",
      STL_STRONG: "(filters confirmed fraudulent traffic and highly suspicious traffic)",
      STL_MAXIMUM: "(filters confirmed fraudulent traffic and all suspicious traffic)"
    },
    initialize(args) {
      this.id = args.id;
      this.isEdit = args.isEdit;
      this.model = args.model;
      this.user = SessionUser.getUser();
      this.getUserPermissions();
      this.countryToRegionMapper = new CountryToRegionMapper();
    },
    initializeData(args) {
      this.updateModel(args).then(model => {
        this.model = model;
        this.load();
      });
    },
    formElementsToggle() {
      this.isFormElementsChanged = true;
    },
    updateModel(args) {
      var deferred = When.defer();
      if (args.id) {
        const org = new Organization({
          id: args.id
        });
        org.fetch({
          success: function (model) {
            deferred.resolve(model);
          }
        });
      }
      return deferred.promise;
    },
    load() {
      var self = this;
      var T1Selectbox = T1.Selectbox;
      this.render().then(function () {
        T1.Form.Masks.attach($('strand-input[data-bind="phone"]'));
        const toolTip = {
          className: "tooltip-orgs",
          delayIn: 0,
          delayOut: 0,
          getExternalTip: false,
          gravity: "s",
          html: true,
          offset: 10
        };
        const $deactivationGroup = self.$(".termination");
        const $deactivationRadio = $deactivationGroup.find(".delete-org");
        const $reactivationRadio = $deactivationGroup.find(".undelete-org");
        if (self.model.get("terminated") === "1") {
          $deactivationRadio.attr("checked", true);
        } else {
          $reactivationRadio.attr("checked", true);
        }
        T1.Tooltip(self.el, toolTip);
        $(".billing-country-dropdown").chosen().change(function () {
          const countryName = $(this).find(`option[value="${$(this).val()}"]`).text();
          if (!self.model.id) {
            self.billingCountryChanged(countryName);
          }
        });
      });
      T1Selectbox(self.$(".country-ddl .select"), {
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search Country"
      });
      T1Selectbox(self.$(".currency-ddl .select"), {
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search Currency"
      });
      T1Selectbox(self.$(".billing-country-ddl .select"), {
        searchFieldDisabled: false,
        searchInputPlaceHolder: "Search Country"
      });
    },
    enableDisableTermination(evt) {
      var $statusGroup = $(evt.currentTarget);
      var $deactivationGroup = this.$(".termination");
      var $reactivationRadio = $deactivationGroup.find(".undelete-org");
      if ($statusGroup.find('strand-radio[value="off"]')[0].checked) {
        $deactivationGroup.attr("disabled", false);
        $reactivationRadio.attr("value", "0");
      } else if ($statusGroup.find('strand-radio[value="on"]')[0].checked) {
        $deactivationGroup.attr("disabled", true);
        $reactivationRadio.attr("checked", true);
        $reactivationRadio.attr("value", "0");
        if (this.model.get("terminated") === "1") {
          this.terminationChanged = true;
        }
      }
    },
    orgInputChanged(evt) {
      var $modal = this.$(".verify-delete-org");
      var entryValue = T1.Utils.escapeHTML(evt.currentTarget.value.trim());
      $modal.find(".delete-org-entry").html(entryValue);
    },
    getUserPermissions() {
      var userRole = this.user.get("role");
      var userScope = this.user.get("scope");
      var userType = this.user.get("type");
      if (userRole.toLowerCase() === "admin" && userScope.toLowerCase() === "global" && userType.toLowerCase() === "internal") {
        this.isInternalGlobalAdmin = true;
      }
    },
    terminationChange(evt) {
      var $target = $(evt.target);
      var $reactivationRadio = this.$(".undelete-org");
      var terminated = this.model.get("terminated");
      if ($target.hasClass("delete-org") && terminated === "0") {
        this.terminationChanged = true;
      } else if ($target.hasClass("undelete-org") && terminated === "1") {
        this.terminationChanged = true;
        $reactivationRadio.attr("value", "0");
      }
    },
    isTerminationChecked(evt) {
      var self = this;
      var $terminationGroup = this.$(".termination");
      var $deactivationRadio = $terminationGroup.find(".delete-org");
      var $reactivationRadio = $terminationGroup.find(".undelete-org");
      var terminated = this.model.get("terminated");
      this.targetSave = $(evt.target).parent().hasClass("saveAndContinue");
      function save() {
        if (self.targetSave) {
          self.saveAndContinue(evt);
        } else {
          self.saveOverride(evt);
        }
      }
      if (this.terminationChanged) {
        if ($deactivationRadio[0].checked && terminated === "0") {
          this.showTerminationModal("terminate");
        } else if ($reactivationRadio[0].checked && terminated === "1") {
          this.showTerminationModal("reactivate");
        } else {
          save();
        }
      } else {
        save();
      }
    },
    showTerminationModal(orgStatus) {
      this.isDeactivating = orgStatus === "terminate";
      this.renderPartialTemplate({
        template: terminationModal,
        context: this.el,
        targetEl: ".termination-modal",
        useTemplateHtml: false,
        serialize: this.serialize()
      });
      setTimeout(() => {
        this.$(".verify-delete-org")[0].show();
      }, 0);
    },
    confirmTermination(evt) {
      var $modal = this.$(".verify-delete-org");
      var entryValue = $modal.find("strand-input")[0].value;
      if (this.model.get("id") === entryValue) {
        $modal.find(".delete-org-inline-box").hide();
        $modal[0].hide();
        this.checkingUnsavedState = true;
        if (this.targetSave) {
          this.saveAndContinue(evt);
        } else {
          this.saveOverride(evt);
          if (this.unsavedEnabled) {
            const delay = 1e3;
            this.unsavedEnabled = false;
            setTimeout(function () {
              T1.Location.set("#admin/myOrgs");
            }, delay);
          }
        }
      } else {
        $modal.find(".delete-org-inline-box").show();
      }
      evt.preventDefault();
    },
    cancelTermination(evt) {
      this.terminationChanged = true;
      this.$(".verify-delete-org").hide();
      evt.preventDefault();
    },
    setEditMode(id) {
      this.isEdit = true;
      T1.Location.set(`#admin/organization/${id}`, {
        silent: true
      });
    },
    saveOverride(evt) {
      var callback = {
        success: () => {
          if (!this.getSaveAndContinueMode()) {
            this.setEditMode(this.model.id);
          }
        }
      };
      return this.save(evt, callback);
    },
    saveAndContinue(evt) {
      var callback = {
        success: () => {
          setTimeout(() => {
            T1.Location.set(`#admin/organization/${this.model.get("id")}/exchangeSeats`);
          }, 0);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(evt, callback);
    },
    prepPostData(formValues) {
      delete formValues.suspicious_traffic_level;
      switch (this.suspiciousTrafficValue) {
        case "STL_STANDARD":
          formValues.override_suspicious_traffic_filter = "0";
          formValues.suspicious_traffic_filter_level = "25";
          break;
        case "STL_NONE":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "0";
          break;
        case "STL_LIGHT":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "25";
          break;
        case "STL_MODERATE":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "50";
          break;
        case "STL_STRONG":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "75";
          break;
        case "STL_MAXIMUM":
          formValues.override_suspicious_traffic_filter = "1";
          formValues.suspicious_traffic_filter_level = "100";
          break;
      }
      switch (formValues.restrict_targeting_to_same_device_id) {
        case "deterministic":
          formValues.restrict_targeting_to_same_device_id = "0";
          formValues.connected_id_type = "DETERMINISTIC_ONLY";
          break;
        case "hybrid":
          formValues.restrict_targeting_to_same_device_id = "0";
          formValues.connected_id_type = "DETERMINISTIC_FIRST";
          break;
        default:
          formValues.restrict_targeting_to_same_device_id = "1";
      }
      return formValues;
    },
    postData(formValues, success, statusInvalid, conflict) {
      var self = this;
      var model = this.model;
      var isEdit = this.isEdit;
      var $unloadAlertDialog = $(document).find(".w-FormUnloadAlert");
      var terminationStatus;
      function getTerminationStatus(values) {
        var status;
        if (values.terminated === "1" && self.terminationChanged) {
          status = "terminated";
        } else if (values.terminated === "0" && self.terminationChanged) {
          status = "reactivated";
        }
        return status;
      }
      function doModelSave() {
        model.createEdit(formValues, {
          success: function (newModel) {
            if (self.checkingUnsavedState) {
              success(newModel);
              switch (terminationStatus) {
                case "terminated":
                  self.terminationChanged = false;
                  T1.Notify("message", `${model.id} Terminated!`);
                  break;
                case "reactivated":
                  T1.Notify("message", `${model.id} Reactivated!`);
                  break;
                default:
                  T1.Notify("message", `Organization ${isEdit ? "updated" : "created"} successfully!`);
              }
            } else {
              success(newModel);
            }
          },
          statusInvalid: function (errors) {
            if (errors && errors.length) {
              _.map(errors, function (error) {
                error.field = error.field === "height" ? "custom_height" : error.field === "width" ? "custom_width" : error.field;
                return error;
              });
            }
            statusInvalid(errors, null, ".form-body");
          },
          conflict: conflict
        });
      }
      function removeDialog(dialogContainer) {
        self.stopSpinner(dialogContainer, "save");
        self.stopSpinner();
        dialogContainer.remove();
        dialogContainer.dialog("destroy");
      }
      formValues = this.prepPostData(formValues);
      terminationStatus = getTerminationStatus(formValues);
      if ($unloadAlertDialog && $unloadAlertDialog.length) {
        switch (terminationStatus) {
          case "terminated":
            this.showTerminationModal("terminate");
            removeDialog($unloadAlertDialog);
            break;
          case "reactivated":
            this.showTerminationModal("reactivate");
            removeDialog($unloadAlertDialog);
            break;
          default:
            doModelSave();
        }
      } else {
        doModelSave();
      }
    },
    onVersionConflictClose() {
      this.initializeData(this);
    },
    canUnload(onTabChange) {
      var $terminationGroup = this.$(".termination");
      var $deactivationRadio = $terminationGroup.find(".delete-org");
      var $reactivationRadio = $terminationGroup.find(".undelete-org");
      var terminated = this.model.get("terminated");
      var isFormElementsChanged = this.isFormElementsChanged;
      var cancelHandler = {
        onAlertClose: () => {
          var T1Location = T1.Location;
          var abortedUrl = T1Location.getAbortedURL();
          var isAbortedUrlEmpty = abortedUrl === "" || abortedUrl === false;
          this.unsavedEnabled = true;
          if (onTabChange) {
            onTabChange.onAlertClose();
          } else if (!isAbortedUrlEmpty) {
            T1Location.set(abortedUrl);
          } else {
            if (this.terminationChanged) {
              if ($deactivationRadio[0].checked && terminated === "0") {
                this.showTerminationModal("terminate");
              } else if ($reactivationRadio[0].checked && terminated === "1") {
                this.showTerminationModal("reactivate");
              }
            } else {
              setTimeout(function () {
                T1Location.set("#admin/myOrgs");
              }, 1);
            }
          }
        }
      };
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: this.isEdit ? "Organization Edit" : "New Organization"
        }, cancelHandler);
      }
      return !isFormElementsChanged;
    },
    getSuspiciousTrafficLevel(overrideFlag, level) {
      var key = "STL_STANDARD";
      if (overrideFlag === "0" && level === "25") {
        key = "STL_STANDARD";
      } else if (overrideFlag === "1") {
        switch (level) {
          case "0":
            key = "STL_NONE";
            break;
          case "25":
            key = "STL_LIGHT";
            break;
          case "50":
            key = "STL_MODERATE";
            break;
          case "75":
            key = "STL_STRONG";
            break;
          case "100":
            key = "STL_MAXIMUM";
            break;
        }
      }
      return key;
    },
    onDropDownChange(evt) {
      if (evt.target.value.indexOf("STL_") === -1) {
        return;
      }
      this.suspiciousTrafficValue = evt.target.value;
      this.isFormElementsChanged = true;
      const $target = $(evt.target).closest(".form-stack").next();
      const $info = $(".w-body.w-OrgDetail").find(".stl-info");
      const $info2 = $(".w-body.w-OrgDetail").find(".stl-info2");
      const $label = $target.find(".stl-message");
      $label.text(this.suspiciousTrafficDescription[this.suspiciousTrafficValue]);
      if (this.suspiciousTrafficValue !== "STL_NONE") {
        $info.removeClass("stl-info-hide");
        $info2.addClass("stl-info-hide2");
      } else {
        $info.addClass("stl-info-hide");
        $info2.removeClass("stl-info-hide2");
      }
    },
    billingCountryChanged(countryName) {
      var exchangeSeatId = this.countryToRegionMapper.getExchangeSeatID(countryName);
      this.$(".adx-seat-account-id").attr("value", exchangeSeatId);
    },
    serialize() {
      var restrictSameDeviceTargeting, restrictTpasTargeting;
      const defaultSeatID = 41519752;
      var isEdit = this.isEdit;
      var model = this.model.toJSON();
      var currencyList = T1.Utils.getCurrencyList({
        selectedId: isEdit ? model.currency_code : "USD"
      });
      var getCountryList = T1Countries.getCountryList;
      var countryList = getCountryList({
        selectedId: isEdit ? model.country : "US"
      });
      var billingCountryList = getCountryList({
        selectedId: isEdit ? model.billing_country_code : "US"
      });
      var suspicious_traffic_level = this.getSuspiciousTrafficLevel(model.override_suspicious_traffic_filter, model.suspicious_traffic_filter_level);
      if (model.restrict_targeting_to_deterministic_id) {
        restrictTpasTargeting = model.restrict_targeting_to_deterministic_id !== "1";
      } else {
        restrictTpasTargeting = true;
      }
      if (model.restrict_targeting_to_same_device_id === "0") {
        if (model.connected_id_type === "DETERMINISTIC_ONLY") {
          restrictSameDeviceTargeting = "deterministic";
        } else {
          restrictSameDeviceTargeting = "hybrid";
        }
      } else {
        restrictSameDeviceTargeting = "disabled";
      }
      this.suspiciousTrafficValue = suspicious_traffic_level;
      return {
        address_1: model.address_1,
        address_2: model.address_2,
        adx_seat_account_id: model.adx_seat_account_id || defaultSeatID,
        allow_byo_price: model.allow_byo_price === "1",
        allow_x_agency_pixels: model.allow_x_agency_pixels === "1",
        billingCountryList: billingCountryList,
        canAccessAdaptive: CAN_ACCESS_ADAPTIVE,
        city: model.city,
        contact_name: model.contact_name,
        countryList: countryList,
        currencyList: currencyList,
        dmp_enabled: model.dmp_enabled !== "disabled",
        eligible_for_data_sharing: model.eligible_for_data_sharing === "1",
        isAaSuppEnabled: model.accountable_and_addressable === "1",
        isServiceProviderCcpaEnabled: model.service_provider_ccpa === "1",
        isServiceProviderCcpaDisabled: model.service_provider_ccpa === "0",
        isCookielessHidden: T1Permissions.check("feature", "hide_cookieless"),
        isDeactivating: this.isDeactivating,
        isOrgServiceProviderPermitted: T1Permissions.check("feature", "organization_service_provider_flag"),
        isEdit: isEdit,
        isInternalGlobalAdmin: this.isInternalGlobalAdmin,
        mm_contact_name: model.mm_contact_name,
        name: model.name,
        phone: model.phone,
        restrictSameDeviceTargeting: restrictSameDeviceTargeting,
        restrictTpasTargeting: restrictTpasTargeting,
        state: model.state,
        status: isEdit ? model.status === "1" : true,
        suspicious_traffic_description: this.suspiciousTrafficDescription[suspicious_traffic_level],
        suspicious_traffic_level: suspicious_traffic_level,
        use_evidon_optout: model.use_evidon_optout === "0",
        useAaSupp: T1Permissions.check("feature", "source"),
        zip: model.zip
      };
    }
  });
  return T1.Form.ViewUtils.extend(DetailsEdit);
});
