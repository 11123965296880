define('modules/creatives/display/models/dimensions',["jQuery", "Underscore"], function ($, _) {
  var DIM_728x90 = "728x90";
  var DIM_468x60 = "468x60";
  var DIM_450x50 = "450x50";
  var DIM_425x600 = "425x600";
  var DIM_336x280 = "336x280";
  var DIM_300x600 = "300x600";
  var DIM_300x250 = "300x250";
  var DIM_250x250 = "250x250";
  var DIM_234x60 = "234x60";
  var DIM_200x200 = "200x200";
  var DIM_160x600 = "160x600";
  var DIM_180x150 = "180x150";
  var DIM_120x600 = "120x600";
  var DIM_120x90 = "120x90";
  var DIM_120x60 = "120x60";
  var DIM_320x480 = "320x480";
  var DIM_320x50 = "320x50";
  var DIM_300x50 = "300x50";
  var DIM_300x75 = "300x75";
  var DIM_216x36 = "216x36";
  var DIM_216x54 = "216x54";
  var DIM_186x28 = "168x28";
  var DIM_186x42 = "168x42";
  var DIM_120x20 = "120x20";
  var DIM_120x30 = "120x30";
  function getDimensions(adFormat) {
    var arr;
    var divider;
    var mobile;
    switch (adFormat) {
      case "MOBILE":
        arr = getMobileDimensions();
        break;
      case "EXPANDABLE":
        arr = getExpandableDimensions();
        break;
      case "DISPLAY":
        arr = getDisplayDimensions();
        break;
      default:
        divider = {
          value: "",
          text: "- - - - - - - - - -",
          disabled: ""
        };
        mobile = {
          value: "",
          text: "MOBILE",
          disabled: ""
        };
        arr = [].concat(getDisplayDimensions()).concat(divider).concat(mobile).concat(getMobileDimensions());
    }
    return arr;
  }
  function getDisplayDimensions() {
    return [{
      value: DIM_728x90,
      text: DIM_728x90
    }, {
      value: DIM_468x60,
      text: DIM_468x60
    }, {
      value: DIM_450x50,
      text: DIM_450x50
    }, {
      value: DIM_425x600,
      text: DIM_425x600
    }, {
      value: DIM_336x280,
      text: DIM_336x280
    }, {
      value: DIM_300x250,
      text: DIM_300x250
    }, {
      value: DIM_250x250,
      text: DIM_250x250
    }, {
      value: DIM_234x60,
      text: DIM_234x60
    }, {
      value: DIM_200x200,
      text: DIM_200x200
    }, {
      value: DIM_180x150,
      text: DIM_180x150
    }, {
      value: DIM_160x600,
      text: DIM_160x600
    }, {
      value: DIM_120x600,
      text: DIM_120x600
    }, {
      value: DIM_120x90,
      text: DIM_120x90
    }];
  }
  function getMobileDimensions() {
    return [{
      value: DIM_320x480,
      text: DIM_320x480
    }, {
      value: DIM_320x50,
      text: DIM_320x50
    }, {
      value: DIM_300x75,
      text: DIM_300x75
    }, {
      value: DIM_300x50,
      text: DIM_300x50
    }, {
      value: DIM_216x54,
      text: DIM_216x54
    }, {
      value: DIM_216x36,
      text: DIM_216x36
    }, {
      value: DIM_186x42,
      text: DIM_186x42
    }, {
      value: DIM_186x28,
      text: DIM_186x28
    }, {
      value: DIM_120x30,
      text: DIM_120x30
    }, {
      value: DIM_120x20,
      text: DIM_120x20
    }];
  }
  function getExpandableDimensions() {
    return [{
      value: DIM_120x600,
      text: DIM_120x600
    }, {
      value: DIM_160x600,
      text: DIM_160x600
    }, {
      value: DIM_300x250,
      text: DIM_300x250
    }, {
      value: DIM_468x60,
      text: DIM_468x60
    }, {
      value: DIM_728x90,
      text: DIM_728x90
    }, {
      value: DIM_234x60,
      text: DIM_234x60
    }, {
      value: DIM_120x60,
      text: DIM_120x60
    }, {
      value: DIM_180x150,
      text: DIM_180x150
    }, {
      value: DIM_300x600,
      text: DIM_300x600
    }];
  }
  function isCustomDimension(adFormat, width, height) {
    var dimOptions = getDimensions(adFormat);
    var isCustom;
    isCustom = _.find(dimOptions, function (dim) {
      return dim.value === width + "x" + height;
    });
    return isCustom === "" || !isCustom || false;
  }
  return {
    getDimensions: getDimensions,
    isCustomDimension: isCustomDimension
  };
});
