define('utils/Auth',[],function () {
  "use strict";

  const WebAuth = window.WebAuth;
  function _clearOldNonces() {
    const now = Date.now();
    const nonceLifetimeInMinutes = 60;
    const minutesToMsMultiplier = 6e4;
    const time = nonceLifetimeInMinutes * minutesToMsMultiplier;
    const expirationTime = now - time;
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith("com.auth0.auth")) {
        const nonce = JSON.parse(localStorage.getItem(key));
        if (!nonce.sweepTime) {
          nonce.sweepTime = now;
          localStorage.setItem(key, JSON.stringify(nonce));
        } else if (nonce.sweepTime < expirationTime) {
          localStorage.removeItem(key);
        }
      }
    });
  }
  function _storeCurrLoc() {
    const ignoredHashes = ["#oauthlogin", "#logout"];
    const currHash = ignoredHashes.includes(location.hash) ? "" : location.hash;
    localStorage.setItem("t1_location", `${location.pathname}${currHash}`);
  }
  function _prepareForExit() {
    _clearOldNonces();
    delete COMPASS_ENV.JWT_TO;
    localStorage.removeItem("mm.bogo.acc");
    localStorage.removeItem("bogoResp");
  }
  function authorize() {
    _storeCurrLoc();
    _prepareForExit();
    WebAuth.authorize({
      prompt: "login"
    });
  }
  function logout() {
    _prepareForExit();
    WebAuth.logout({
      returnTo: window.location.origin
    });
  }
  function getConnection() {
    let connection = "T1DB-Production";
    if (COMPASS_ENV.TIER !== "PRODUCTION") {
      const connectionString = "IAD-T1DB-QA";
      const domain = COMPASS_ENV.API_PROXY_DOMAIN || window.location.hostname;
      let qaBox = "1";
      if (domain.includes("t1qa")) {
        qaBox = domain.substring(domain.indexOf("t1qa") + "t1qa".length, domain.indexOf("."));
      }
      connection = `${connectionString}${qaBox}`;
    }
    return connection;
  }
  function sendPasswordResetEmail(emailAddr) {
    return new Promise((resolve, reject) => {
      WebAuth.changePassword({
        connection: getConnection(),
        email: emailAddr
      }, (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    });
  }
  return {
    authorize: authorize,
    logout: logout,
    sendPasswordResetEmail: sendPasswordResetEmail
  };
});
