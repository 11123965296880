define('modules/admin/siteLists/views/siteLists',['require','collections/siteLists','T1','T1Layout','T1View','text!../templates/siteLists.html','models/userPreferences','T1Signature'],function (require) {
  "use strict";

  const SiteLists = require("collections/siteLists");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1View = require("T1View");
  const tmpl = require("text!../templates/siteLists.html");
  const UserPreferences = require("models/userPreferences");
  const T1Signature = require("T1Signature");
  return T1View.extend({
    template: tmpl,
    userPreferenceViewName: "sitelist",
    isActive: "1",
    subFilterValue: "ALL",
    eventhubEvents: {
      "search:onSearchRequest": function (args) {
        this.collection.searchTerm = args.value.length > 0 ? args.value : undefined;
        if (this.searchTerm === this.collection.searchTerm) {
          return;
        }
        this.searchTerm = this.collection.searchTerm;
        this.filterCollection(args.field, args.value);
      },
      "search:clearSearchClicked": function (args) {
        var coll = this.collection;
        coll.refreshOnNextFetch();
        coll.searchTerm = this.searchTerm = undefined;
        this.filterCollection(args.field, args.value);
      },
      "search:toggleActive": function (args) {
        this.filterCollection("status", args.value);
        this.isActive = args.value === "1" ? "true" : "false";
        this.preferences.save();
      },
      "search:subFilterChanged": function (args) {
        this.filterCollection("restriction", args.value);
        this.subFilterValue = args.value;
        this.preferences.save();
      }
    },
    events: {
      "click .add-site-list": "addSiteList"
    },
    initialize() {
      var signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "admin.search"], ["view", this.userPreferenceViewName]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["isActive", "subFilterValue"]);
      this.isActive = this.preferences.get("isActive");
      this.isActive = this.isActive !== undefined ? this.isActive : "true";
      this.subFilterValue = this.preferences.get("subFilterValue");
      this.subFilterValue = this.subFilterValue !== undefined ? this.subFilterValue : "ALL";
      this.collection = new SiteLists();
      this.collection.applySearchFilter("status", this.isActive === "true" ? "1" : "");
      this.collection.applySearchFilter("restriction", this.subFilterValue === "ALL" ? "" : this.subFilterValue);
      this.initLayout();
    },
    addSiteList() {
      T1.Location.set("admin/siteList", {});
    },
    initLayout() {
      var listLayout = {
        ".list-search": [{
          module: "admin/shared",
          viewType: "search",
          options: {
            searchInputPlaceHolder: "Type Name or ID Number",
            isActiveChecked: this.isActive === "true",
            subFilter: {
              name: "Type",
              defaultValue: this.subFilterValue,
              value: [{
                name: "View All",
                value: "ALL"
              }, {
                name: "Allow list",
                value: "INCLUDE"
              }, {
                name: "Block list",
                value: "EXCLUDE"
              }]
            }
          }
        }],
        ".bulk-export-modal": [{
          module: "admin/siteLists",
          viewType: "csvExportModal"
        }],
        ".w-grid": [{
          module: "admin/siteLists",
          viewType: "grid",
          options: {
            collection: this.collection,
            siteLists: this
          }
        }]
      };
      this.layout = new T1Layout({
        el: () => this.el,
        selectorPath: ".list-body",
        template: '<div class="w-grid"></div><div class="bulk-export-modal"></div>',
        layout: listLayout
      });
    },
    load() {
      this.render().then(() => {
        if (this.layout) {
          this.layout.load();
        }
      });
    },
    unload() {
      var searchFieldVal = this.collection.searchTerm;
      this.viewChanged = true;
      this.collection.searchTerm = this.searchTerm = null;
      if (searchFieldVal && searchFieldVal !== "") {
        this.collection.applySearchFilter(searchFieldVal.indexOf("#") !== -1 ? "name|id" : "multiField", "");
      }
    },
    filterCollection(field, term) {
      var $chooseAction = this.$(".choose-action");
      T1.EventHub.publish("siteListGrid:reload");
      this.collection.filterBy(field, term, function () {
        $chooseAction.attr("disabled", true);
      });
    }
  });
});
