define('modules/admin/myOrgs/organization/views/details_readOnly',['require','T1','T1Countries','T1Currencies','T1Permissions','T1View','text!../templates/details_readOnly.html','T1Tooltip'],function (require) {
  "use strict";

  const T1 = require("T1");
  const T1Countries = require("T1Countries");
  const T1Currencies = require("T1Currencies");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!../templates/details_readOnly.html");
  const T1Tooltip = require("T1Tooltip");
  return T1View.extend({
    template: template,
    initialize(args) {
      this.model = args.model;
    },
    getRadioValue(value, onValue) {
      return value === (onValue || "1") ? "Enabled" : "Disabled";
    },
    load() {
      this.render().then(() => {
        var toolTipAdChoices = {
          className: "tooltip-orgs",
          delayIn: 0,
          delayOut: 0,
          getExternalTip: false,
          gravity: "s",
          leftPosAdjust: 395,
          offset: 40
        };
        var toolTipBYOP = {
          className: "tooltip-orgs",
          delayIn: 0,
          delayOut: 0,
          getExternalTip: false,
          gravity: "s",
          leftPosAdjust: -5,
          offset: 20
        };
        var toolTipTpas = {
          className: "tooltip-orgs",
          delayIn: 0,
          delayOut: 0,
          getExternalTip: false,
          gravity: "s",
          leftPosAdjust: 435,
          offset: 35
        };
        var toolTipSameDevice = {
          className: "tooltip-orgs",
          delayIn: 0,
          delayOut: 0,
          getExternalTip: false,
          gravity: "s",
          leftPosAdjust: 342,
          offset: 35
        };
        T1.Tooltip(this.$(".ad-choices"), toolTipAdChoices);
        T1.Tooltip(this.$(".byop"), toolTipBYOP);
        T1.Tooltip(this.$(".tpasTargeting"), toolTipTpas);
        T1.Tooltip(this.$(".sameDeviceTargeting"), toolTipSameDevice);
      });
    },
    suspiciousTrafficShortDescription: {
      STL_STANDARD: "T1 Standard",
      STL_NONE: "None (T1 Standard Disabled)",
      STL_LIGHT: "T1 Standard + Light Increase",
      STL_MODERATE: "T1 Standard + Moderate increase",
      STL_STRONG: "T1 Standard + Strong increase",
      STL_MAXIMUM: "T1 Standard + Maximum increase"
    },
    getSuspiciousTrafficLevel(overrideFlag, level) {
      var key = "STL_STANDARD";
      if (overrideFlag === "0" && level === "25") {
        key = "STL_STANDARD";
      } else if (overrideFlag === "1") {
        switch (level) {
          case "0":
            key = "STL_NONE";
            break;
          case "25":
            key = "STL_LIGHT";
            break;
          case "50":
            key = "STL_MODERATE";
            break;
          case "75":
            key = "STL_STRONG";
            break;
          case "100":
            key = "STL_MAXIMUM";
            break;
        }
      }
      return key;
    },
    serialize() {
      var sameDeviceTargetingValue;
      var model = this.model.toJSON();
      var getRadioValue = this.getRadioValue;
      let suspiciousTrafficLevel = this.getSuspiciousTrafficLevel(model.override_suspicious_traffic_filter, model.suspicious_traffic_filter_level);
      suspiciousTrafficLevel = this.suspiciousTrafficShortDescription[suspiciousTrafficLevel];
      const obj = {
        details: [{
          label: "Status",
          value: model.status === "1" ? "Active" : "Inactive"
        }, {
          label: "Org Name",
          value: model.name
        }, {
          label: "Primary Contact Name",
          value: model.contact_name
        }, {
          label: "Primary Contact Phone",
          value: model.phone
        }, {
          label: "Address Line 1",
          value: model.address_1
        }, {
          label: "Address Line 2",
          value: model.address_2
        }, {
          label: "City",
          value: model.city
        }, {
          label: "State / Province / Region / Territory",
          value: model.state
        }, {
          label: "Zip / Postcode / Postal Code",
          value: model.zip
        }, {
          label: "Country",
          value: T1Countries.getCountryName(model.country)
        }, {
          label: "MediaMath Contact Name",
          value: model.mm_contact_name
        }, {
          label: "Currency",
          value: T1Currencies.getCurrencyName(model.currency_code)
        }, {
          label: "Billing Country",
          value: T1Countries.getCountryName(model.billing_country_code)
        }, {
          label: "ADX Seat Account ID",
          value: model.adx_seat_account_id
        }, {
          label: "Cross-Agency Pixel Targeting",
          value: getRadioValue(model.allow_x_agency_pixels)
        }, {
          label: "Adaptive Segments",
          value: getRadioValue(model.dmp_enabled, "enabled")
        }, {
          label: "ELIGIBLE FOR DATA SHARING ACROSS MEDIAMATH PRODUCTS",
          value: getRadioValue(model.eligible_for_data_sharing, "1")
        }, {
          field: "byop",
          label: "3rd Party Data BYO Price",
          value: getRadioValue(model.allow_byo_price),
          tooltip: "BYO Price for 3rd Party data does not include Peer39 by Sizmek."
        }]
      };
      if (!T1Permissions.check("feature", "hide_cookieless")) {
        const tpasTargetingValue = model.restrict_targeting_to_deterministic_id === "0" ? "Enabled" : "Disabled";
        obj.details.push({
          field: "tpasTargeting",
          label: "Bid on Cookieless Web",
          value: tpasTargetingValue
        });
      }
      if (model.restrict_targeting_to_same_device_id === "0") {
        if (model.connected_id_type === "DETERMINISTIC_ONLY") {
          sameDeviceTargetingValue = "Deterministic";
        } else {
          sameDeviceTargetingValue = "Deterministic/Probabilistic";
        }
      } else {
        sameDeviceTargetingValue = "Disabled";
      }
      obj.details.push({
        field: "sameDeviceTargeting",
        label: "Bid on Cross Device",
        value: sameDeviceTargetingValue
      });
      obj.details.push({
        field: "ad-choices",
        label: "Display AdChoices icon on interest-based ads",
        value: getRadioValue(model.use_evidon_optout),
        tooltip: "When enabled, the AdChoices icon will be displayed on impressions with interest-based " + "advertising. The icon provides consumers control over their exposure to interest-based ads in the " + "future. Refer to the Contracts page for the associated cost of enabling the AdChoices icon."
      });
      if (T1Permissions.check("feature", "source")) {
        obj.details.push({
          field: "accountable_and_addressable",
          label: "SOURCE",
          value: model.accountable_and_addressable === "1" ? "Enabled" : "Disabled"
        });
      }
      obj.details.push({
        field: "service_provider_ccpa",
        label: "Service Provider CCPA",
        value: model.service_provider_ccpa === undefined ? "Null" : model.service_provider_ccpa === "1" ? "Enabled" : "Disabled"
      });
      obj.details.push({
        label: "Suspicious Traffic Filter Strength",
        value: suspiciousTrafficLevel
      });
      return obj;
    }
  });
});
