define('modules/campaign/chart/winLoss/views/winLoss',['require','jQuery','./campaignDailyChart','./campaignSummaryChart','../../controllers/commController','text!modules/shared/templates/chartLoader.html','Mustache','text!modules/shared/templates/chartNoDataGrid.html','modules/reporting/campaigns/utils/reportingUtils','./strategyDailyChart','T1','T1View','text!../templates/winLoss.html','models/userPreferences'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const CampaignDailyChart = require("./campaignDailyChart");
  const CampaignSummaryChart = require("./campaignSummaryChart");
  const CommController = require("../../controllers/commController");
  const loaderTmpl = require("text!modules/shared/templates/chartLoader.html");
  const Mustache = require("Mustache");
  const noDataGridTmpl = require("text!modules/shared/templates/chartNoDataGrid.html");
  const ReportUtils = require("modules/reporting/campaigns/utils/reportingUtils");
  const StrategyDailyChart = require("./strategyDailyChart");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!../templates/winLoss.html");
  const UserPreferences = require("models/userPreferences");
  const dateFormat = "yyyy-MM-dd";
  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    partials: {
      chartLoader: loaderTmpl,
      chartNoDataGrid: noDataGridTmpl
    },
    dataEvents: {
      ReportModel: {
        "change:entity": "entityChange",
        "change:timeSlots": "fetchChartData"
      }
    },
    eventhubEvents: {
      "fetch:initData": function () {
        this.fetchInitData();
      },
      "mmCharts.changeChartType": "chartTypeChange"
    },
    fetchOptions: {
      dimensions: "campaign_id,campaign_timezone_code,campaign_timezone",
      time_rollup: "by_day"
    },
    viewByFetchOptions: {
      creative: ",creative_id,creative_name",
      deal: ",deal_id,deal_name",
      exchange: ",exchange_id,exchange_name",
      strategy: ",strategy_id,strategy_name"
    },
    entityType: "campaign",
    winLossChartType: "summary",
    startDate: ReportUtils.defaultStartDate.toString(dateFormat),
    endDate: ReportUtils.defaultEndDate.toString(dateFormat),
    initLoad: true,
    initialize({
      getReportModel: getReportModel,
      getHeaderEl: getHeaderEl,
      updateTimezone: updateTimezone,
      getEntity: getEntity
    }) {
      const ReportModel = this.ReportModel = getReportModel();
      this.$headerEl = getHeaderEl();
      this.updateTimezone = updateTimezone;
      this.entity = getEntity();
      if (this.entity) {
        this.entityType = this.entity.get("type") === "campaign" ? "campaign" : "strategy";
      }
      this.isCampaign = this.entityType === "campaign";
      this.chartColors = ReportModel.reportMeta.additionalData.chartColors;
      ReportModel.setEntityType(this.entityType);
      const {
        start: start,
        end: end
      } = ReportModel.getStartEndDate();
      if (start) {
        this.startDate = start;
        this.endDate = end;
      } else {
        ReportModel.setStartEndDate({
          start: this.startDate,
          end: this.endDate
        }, true);
      }
      this.initPreferences();
    },
    initPreferences() {
      const signature = T1.Signature.create();
      signature.set(new Map([["root", "ui"], ["module", "monitor"], ["view", "campaign.chart"]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["winLossChartType"]);
      this.ReportModel.setChartType(this.winLossChartType);
    },
    load() {
      this.render().then(() => {
        Object.assign(this, {
          $labelLeftAxisDot: this.$(".axis-label.left-axis .win-loss-strategy-daily-metric-dot"),
          $labelLeftAxisTxt: this.$(".axis-label.left-axis .label-txt"),
          $labelRightAxisDot: this.$(".axis-label.right-axis .win-loss-strategy-daily-metric-dot"),
          $labelRightAxisTxt: this.$(".axis-label.right-axis .label-txt"),
          $loader: this.$(".loader-holder"),
          $noDataGrid: this.$(".no-data"),
          $noDataTxt: this.$(".no-data").find(".msg-c")
        });
        this.setupChart();
        this.updateChartType();
      });
    },
    unload() {
      if (this.chart) {
        this.chart.unloadChart();
        this.chart = null;
      }
      this.abortCalls();
      Object.assign(this, {
        $headerEl: null,
        $labelLeftAxisDot: null,
        $labelLeftAxisTxt: null,
        $labelRightAxisDot: null,
        $labelRightAxisTxt: null,
        $loader: null,
        $noDataGrid: null,
        $noDataTxt: null,
        chartColors: null,
        endDate: null,
        entity: null,
        entityType: null,
        initLoad: null,
        isCampaign: null,
        startDate: null,
        viewBy: null
      });
    },
    abortCalls() {
      if (this.loaderMetadata) {
        this.loaderMetadata = null;
      }
      if (this.loaderChartData) {
        this.loaderChartData.abort();
        this.loaderChartData = null;
      }
    },
    layoutLoaded() {
      if (this.entity) {
        this.initLoad = false;
        if (!this.isCampaign) {
          T1Publish("winloss.hideChartType", true);
        }
      }
      if (this.initLoad) {
        this.initLoad = false;
      } else {
        this.fetchInitData();
      }
    },
    entityChange(model, entity) {
      const isCampaign = entity.get("type") === "campaign";
      Object.assign(this, {
        entity: entity,
        entityType: isCampaign ? "campaign" : "strategy",
        isCampaign: isCampaign,
        prevEntityType: this.entityType || "campaign"
      });
      this.ReportModel.setEntityType(this.entityType);
      if (!this.isCampaign) {
        if (this.prevEntityType !== this.entityType) {
          this.chartTypeChange("daily");
        } else {
          this.fetchInitData();
        }
        T1Publish("winloss.hideChartType", true);
        return;
      }
      T1Publish("winloss.hideChartType", false);
      if (this.prevEntityType !== this.entityType) {
        this.chartTypeChange("daily");
      } else {
        this.fetchInitData();
      }
    },
    chartTypeChange(chartType) {
      this.winLossChartType = chartType;
      this.preferences.save();
      this.ReportModel.setChartType(chartType);
      this.chart.unloadChart();
      this.load();
    },
    fetchInitData() {
      this.fetchMetaData().then(() => {
        this.fetchChartData();
      });
    },
    fetchMetaData() {
      const ReportModel = this.ReportModel;
      const viewBy = ReportModel.getViewByDimension();
      const promise = new Promise(resolve => {
        ReportModel.reportMeta.urlString = viewBy === "creative" ? "std/win_loss_creative/meta" : "std/win_loss/meta?v2=";
        const loaderMetadata = this.loaderMetadata = new CommController(ReportModel);
        loaderMetadata.fetchMetaData().then(function () {
          resolve();
        });
      });
      return promise;
    },
    fetchChartData() {
      const {
        ReportModel: ReportModel,
        isCampaign: isCampaign,
        $loader: $loader,
        $noDataTxt: $noDataTxt
      } = this;
      const fetchOptions = $.extend({}, this.fetchOptions);
      const {
        start: start,
        end: end
      } = ReportModel.getStartEndDate();
      const entity = ReportModel.get("entity");
      const isDaily = this.winLossChartType === "daily";
      const viewBy = ReportModel.getViewByDimension();
      const isCreative = viewBy === "creative";
      const interval = ReportModel.getInterval();
      if (!entity) {
        return;
      }
      this.abortCalls();
      this.updateTimezone("");
      $loader.fadeIn();
      $noDataTxt.hide();
      fetchOptions.start_date = start;
      fetchOptions.end_date = end;
      if (!isCreative) {
        fetchOptions.v2 = "";
      }
      switch (true) {
        case isCampaign && isDaily:
          fetchOptions.dimensions += this.viewByFetchOptions[viewBy];
          fetchOptions.time_rollup = interval;
          ReportModel.attributes.fetchKey = isCreative ? "win_loss_creative" : "win_loss";
          break;
        case isCampaign && !isDaily:
          fetchOptions.dimensions += this.viewByFetchOptions[viewBy];
          fetchOptions.time_rollup = "all";
          ReportModel.attributes.fetchKey = isCreative ? "win_loss_creative" : "win_loss";
          break;
        case !isCampaign && isDaily:
          fetchOptions.time_rollup = interval;
          break;
        case !isCampaign && !isDaily:
          break;
      }
      this.loaderChartData = new CommController(ReportModel, fetchOptions);
      this.loaderChartData.fetchChartData().then(() => {
        const chartData = ReportModel.chartData;
        const dataJSON = chartData.toJSON();
        const reportInfo = ReportModel.get("reportInfo");
        $loader.stop(true, true).fadeOut();
        $noDataTxt.show();
        const timezoneCode = dataJSON[0] && dataJSON[0].campaign_timezone_code || "";
        this.updateTimezone(timezoneCode);
        T1Publish("responseHeadersReady", reportInfo.reportTypes.win_loss);
        this.chart.updateChart();
      });
    },
    setupChart() {
      const {
        ReportModel: ReportModel,
        isCampaign: isCampaign
      } = this;
      const ParentClass = this;
      const isDaily = this.winLossChartType === "daily";
      switch (true) {
        case isCampaign && isDaily:
          this.chart = new CampaignDailyChart({
            ParentClass: ParentClass,
            ReportModel: ReportModel
          });
          break;
        case isCampaign && !isDaily:
          this.chart = new CampaignSummaryChart({
            ParentClass: ParentClass,
            ReportModel: ReportModel
          });
          break;
        case !isCampaign && isDaily:
          this.chart = new StrategyDailyChart({
            ParentClass: ParentClass,
            ReportModel: ReportModel
          });
          break;
        case !isCampaign && !isDaily:
          break;
      }
      this.$noDataTxt.hide();
      this.showNoDataGrid();
    },
    updateChartType() {
      T1Publish("winloss.updateChartType", this.winLossChartType);
    },
    showNoDataGrid() {
      this.$noDataGrid.show();
    },
    hideNoDataGrid() {
      this.$noDataGrid.hide();
    },
    serialize() {
      return {
        color: "{{model.color}}",
        entity: "{{model.entity}}",
        highlight: "{{model.highlight}}",
        id: "{{model.id}}",
        isSummary: this.winLossChartType === "summary",
        metric0: "{{model.metric0}}",
        metric1: "{{model.metric1}}",
        model: "{{model}}",
        scope: "{{scope}}"
      };
    },
    render() {
      const tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      const partials = this.partials || {};
      const deferred = $.Deferred();
      const $el = this.el;
      tdata.appLocation = T1.Location.get();
      this.assignAsCurrency(tdata);
      if (Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = Mustache.to_html(this.template, tdata, partials);
      } else {
        $el.html(Mustache.to_html(this.template, tdata, partials));
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        $el.find("input, textarea").placeholder();
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
