define('modules/targeting/contextual/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "T1Spinner", "T1InlineEditPopover", "collections/targetingVendors", "text!modules/targeting/contextual/templates/bin_header.html", "text!modules/targeting/contextual/templates/bin_title.html", "text!modules/targeting/contextual/templates/bin_item.html", "text!modules/targeting/contextual/templates/bin_footer.html", "text!modules/targeting/contextual/templates/inline_cpm_edit.html", "text!modules/targeting/contextual/templates/bin_operator.html", "utils/CampaignStrategyUtils", "utils/currencyConversionHelper"], function ($, _, T1, BinsView, Spinner, T1InlineEditPopover, TargetingVendors, bin_header, bin_title, bin_item, bin_footer, inline_cpm_edit, bin_operator, CampaignStrategyUtils, CurrencyUtils) {
  "use strict";

  var formatNumber = T1.Formats.rolledUpNumbersShort();
  var formatCurrency = T1.Utils.formatCurrency;
  var getCurrencySymbol = T1.Utils.getCurrencySymbol;
  const contextualBins = BinsView.extend({
    type: "Contextual",
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      },
      exclude: {
        hasFooter: true
      }
    },
    events: {
      "click input:radio[data-type=targeting_operators]": "andOrClicked"
    },
    eventhubEvents: {
      safetySettingsModified: function () {
        this.updateBins();
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_item: bin_item,
      bin_footer: bin_footer,
      bin_operator: bin_operator
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      onDropEvent: "bins:droppedContextual",
      allowMultipleSelection: true,
      handlerTextEl: ".segment"
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    initialize({
      strategy: strategy,
      sharedCollection: sharedCollection,
      retiredCollection: retiredCollection,
      allowBYOPriceEdit: allowBYOPriceEdit,
      safetySharedCollection: safetySharedCollection
    }) {
      this.strategy = strategy;
      this.sharedCollection = sharedCollection;
      this.retiredCollection = retiredCollection;
      this.currencyCode = this.sharedCollection.currencyCode;
      this.setIncludeUseOrState();
      this.allowBYOPriceEdit = allowBYOPriceEdit;
      this.safetySharedCollection = safetySharedCollection;
      if (this.allowBYOPriceEdit === undefined) {
        throw new Error("Incoming args.allowBYOPriceEdit cannot be undefined.");
      }
      this.sharedCollection.add(this.retiredCollection.models, {
        silent: true
      });
      this.targetingVendorColl = new TargetingVendors();
    },
    load: function () {
      this.updateBins();
      T1.Tooltip(this.$(".warning-holder"), {});
    },
    reload: function () {
      this.sharedCollection.add(this.retiredCollection.models, {
        silent: true
      });
      this.setIncludeUseOrState();
      this.load();
    },
    unload() {
      this.targetingVendorColl = new TargetingVendors();
    },
    setIncludeUseOrState: function () {
      var targetingVendorOperators = {};
      this.sharedCollection.each(function (model) {
        const modelObj = model.toJSON();
        const fullPath = modelObj.full_path;
        if (fullPath.split(" - ")) {
          const vendorName = fullPath.split(" - ")[0];
          if (modelObj.action === "include") {
            targetingVendorOperators[vendorName] = modelObj.operator;
          }
        }
      });
      this.sharedCollection.targetingVendorOperators = targetingVendorOperators;
      this.retiredCollection.targetingVendorOperators = targetingVendorOperators;
    },
    andOrClicked: function (e) {
      const vendorName = e.currentTarget.getAttribute("name");
      const vendorOperator = e.currentTarget.getAttribute("value");
      this.sharedCollection.targetingVendorOperators[vendorName] = vendorOperator;
      this.updateBins();
    },
    onToggleUserCPM: function (e) {
      var $currentTarget = $(e.currentTarget);
      var $userCPMField = $currentTarget.closest("div").find("input:text");
      $userCPMField.trigger("keydown");
      if ($currentTarget.val() === "0") {
        $userCPMField.removeAttr("disabled");
      } else {
        $userCPMField.val("").attr("disabled", true);
      }
    },
    updateBins(currentModel, hasBeenRemoved) {
      var self = this;
      const iconSpinnerFooter = this.$(".icon-spinner-footer");
      const postObj = this.sharedCollection.buildPostObjForPostingSizeAndCpm();
      const safetyPostObj = this.sharedCollection.buildPostObjForPostingSafetyCpm(this.safetySharedCollection);
      const strategy = this.strategy;
      var includedIds = [];
      this.prepareCpmEditor();
      this.prepareAndOrButtons();
      function updateBins(data) {
        self.updateDataBind(data, self.el, {
          doNotEscape: true
        });
      }
      function applySpinner(spinnerTarget, updateBinsData) {
        if (!spinnerTarget.children().length) {
          updateBins(updateBinsData);
          Spinner.apply(spinnerTarget, self.spinnerOpts);
        }
      }
      function getTotalCpmWithTargetSegmentsAndSafety(targetingSegmentsCpm, safetySegmentsCpm) {
        if (targetingSegmentsCpm.currency_code === safetySegmentsCpm.currency_code) {
          return {
            currency_code: targetingSegmentsCpm.currency_code,
            value: (parseFloat(targetingSegmentsCpm.value) + parseFloat(safetySegmentsCpm.value)).toString()
          };
        } else if (safetySegmentsCpm.length) {
          let safetyValue;
          for (let i = 0; i < safetySegmentsCpm.length; i++) {
            if (safetySegmentsCpm[i].currency_code !== "USD") {
              safetyValue = safetySegmentsCpm[i].value;
            }
          }
          return {
            currency_code: targetingSegmentsCpm.currency_code,
            value: (parseFloat(targetingSegmentsCpm.value) + parseFloat(safetyValue)).toString()
          };
        }
        return {
          currency_code: targetingSegmentsCpm.currency_code,
          value: "0"
        };
      }
      $.each(postObj, function (key, value) {
        if (value === "INCLUDE") {
          includedIds.push(key);
        }
        if (includedIds.length > 1) {
          return false;
        }
      });
      applySpinner(iconSpinnerFooter, {
        "contextual-cpm-cost": ""
      });
      if (this.ajxCpmReq) {
        this.ajxCpmReq.abort();
      }
      if (this.ajxCpmReqSafety) {
        this.ajxCpmReqSafety.abort();
      }
      this.ajxCpmReq = strategy.requestTargetSegmentsTargetData(postObj, function (cpmResult) {
        let propertyAry;
        const targetingSegmentsCpm = cpmResult.entity.prop.amount;
        const updateCPMcostWithTax = () => {
          const getProviderTax = model => {
            const targetingVendorID = model.get("targeting_vendor_id");
            const targetingModel = self.targetingVendorColl.get(targetingVendorID);
            const vendor = targetingModel ? targetingModel.get("vendor") : null;
            return vendor ? vendor.provider_tax : null;
          };
          const objectivesLUT = self.sharedCollection.models.reduce((obj, model) => {
            const objectiveID = model.get("objective_id") || model.get("targeting_vendor_id");
            const retailCPM = model.get("retail_cpm") ? Number(model.get("retail_cpm")) : null;
            const providerTax = getProviderTax(model);
            if (providerTax && retailCPM) {
              if (obj[objectiveID]) {
                obj[objectiveID].retailCPMs.push(retailCPM);
              } else {
                obj[objectiveID] = {
                  retailCPMs: [retailCPM],
                  providerTax: Number(providerTax)
                };
              }
            }
            return obj;
          }, {});
          Object.values(objectivesLUT).forEach(item => {
            item.retailCPMs.sort().reverse();
          });
          const totalProviderTaxAmount = Object.values(objectivesLUT).reduce((totalTax, item) => {
            const percent = 100;
            const maxCPM = item.retailCPMs[0];
            const taxAmount = maxCPM * item.providerTax / percent;
            totalTax += taxAmount;
            return totalTax;
          }, 0);
          const formattedCurrency = CurrencyUtils.getCpmResultForTargeting(propertyAry, self.currencyCode);
          const cpmCostWithTax = Number(formattedCurrency) + totalProviderTaxAmount;
          const totalCPMcost = formatCurrency(parseFloat(cpmCostWithTax), null, self.currencyCode);
          updateBins({
            "contextual-cpm-cost": totalCPMcost
          });
          iconSpinnerFooter.spin(false);
          if (!hasBeenRemoved && currentModel && getProviderTax(currentModel)) {
            T1.EventHub.publish("bins.providerTaxAdded");
          }
        };
        const getVendorProviderTax = () => {
          const binIDs = self.sharedCollection.pluck("targeting_vendor_id");
          const uniqueIDs = new Set(binIDs);
          if (binIDs.length) {
            self.targetingVendorColl.search.set({
              fetchRequired: false,
              idList: [...uniqueIDs]
            });
            self.targetingVendorColl.fetch({
              success: updateCPMcostWithTax
            });
          } else {
            updateCPMcostWithTax();
          }
        };
        if (safetyPostObj) {
          self.ajxCpmReqSafety = strategy.requestSafetyTargetData(safetyPostObj, function (safetyCpmResult) {
            propertyAry = getTotalCpmWithTargetSegmentsAndSafety(targetingSegmentsCpm, safetyCpmResult.entity.prop.amount);
            getVendorProviderTax();
          }, function () {
            updateBins({
              "contextual-cpm-cost": "--"
            });
            iconSpinnerFooter.spin(false);
          });
        } else {
          propertyAry = getTotalCpmWithTargetSegmentsAndSafety(targetingSegmentsCpm, {
            currency_code: self.currencyCode,
            value: "0"
          });
          getVendorProviderTax();
        }
      }, function () {
        updateBins({
          "contextual-cpm-cost": "--"
        });
        iconSpinnerFooter.spin(false);
      }, "cpm");
      if (this.ajxCpmReq) {
        this.ajxCpmReq.always(function () {
          this.ajxCpmReq = null;
        });
      }
      if (this.ajxCpmReqSafety) {
        this.ajxCpmReqSafety.always(function () {
          this.ajxCpmReqSafety = null;
        });
      }
    },
    prepareCpmEditor: function () {
      var self = this;
      const currencyCode = this.currencyCode;
      if (this.allowBYOPriceEdit && this.sharedCollection.models.length) {
        const inlineEditorView = T1InlineEditPopover({
          menuTriggerEleSelector: ".cpm .editable",
          template: inline_cpm_edit,
          onOpen: function (target) {
            T1.Form.Masks.attach($("input", $(target.el)), currencyCode);
            T1.Tooltip(inlineEditorView.el, {
              className: "contextualSegmentEdit-tipsy",
              gravity: "swe"
            });
            $(target.el).find("input:radio[name=cpm]").on("click", function (e) {
              self.onToggleUserCPM.call(inlineEditorView, e);
            });
          },
          generateData: function (currentData) {
            const $targetSegment = currentData.target.closest(".item");
            const targetSegmentID = $targetSegment.data("id");
            const targetModel = self.sharedCollection.get(targetSegmentID);
            const standardCPM = targetModel.get("retail_cpm");
            const userCPM = targetModel.get("user_cpm");
            const isStandardCPM = userCPM === undefined;
            const currencySym = getCurrencySymbol(currencyCode);
            const formattedStdCPM = formatCurrency(parseFloat(standardCPM), null, currencyCode);
            const formattedUserCPM = isStandardCPM ? undefined : userCPM;
            return {
              isStandardCPM: isStandardCPM,
              standardCPM: formattedStdCPM,
              userCPM: formattedUserCPM,
              currency_symbol: currencySym
            };
          },
          validate: function (data) {
            const maxCPM = 9999.99;
            if (data.isStandardCPM === "0" && (data.userCPM === undefined || data.userCPM === "")) {
              return {
                errors: [{
                  field: "userCPM",
                  message: "The field is required."
                }]
              };
            } else if (data.userCPM > maxCPM) {
              return {
                errors: [{
                  field: "userCPM",
                  message: "CPM value must be <br> less than 10000.00."
                }]
              };
            }
            return {
              errors: []
            };
          },
          onSave: function (formValues) {
            const isStandardCPM = formValues.isStandardCPM === "1";
            const userCPM = formValues.userCPM;
            const $targetSegment = this.currentData.target.closest(".item");
            const targetSegmentID = $targetSegment.data("id");
            const targetModel = self.sharedCollection.get(targetSegmentID);
            if (targetModel) {
              if (isStandardCPM) {
                targetModel.set({
                  user_cpm: undefined
                });
              } else {
                targetModel.set({
                  user_cpm: userCPM
                });
              }
              self.configs.include.use_or = self.sharedCollection.includedBinOperation === "OR";
              self.load();
            }
          }
        }, this);
      }
    },
    prepareAndOrButtons: function () {
      var self = this;
      $.each(this.$(".include-bin .group-name"), function (index, val) {
        const $item = $(val);
        $item.append($(self.partials.bin_operator));
        const vendorName = $item.find(".group-c").text();
        $item.find("input").attr("name", vendorName);
        const vendorOperator = self.sharedCollection.targetingVendorOperators[vendorName];
        if (vendorOperator === "AND") {
          $item.find(".and-val").attr("checked", "checked");
        } else {
          $item.find(".and-or").attr("checked", "checked");
        }
      });
    },
    mapItems: function (items) {
      let retailCpm;
      var result = [];
      var currencyCode = this.currencyCode;
      function assignRetailCPM(index, curVal) {
        if (curVal.currency_code === currencyCode) {
          retailCpm = curVal.value;
        }
      }
      items = _.groupBy(items, function (grItem) {
        if (grItem.get("type") !== "retired_targeting_segment") {
          return grItem.get("full_path").split(" - ")[0];
        }
        return grItem.get("full_retired_path").split(" - ")[0];
      });
      for (const item in items) {
        if (T1.Utils.hasOwnProp(items, item)) {
          const modelArray = items[item];
          for (let i = 0; i < modelArray.length; i++) {
            const mappedItem = {};
            const model = modelArray[i].toJSON();
            mappedItem.id = model.id;
            mappedItem.bin_show_group_name = i === 0;
            const path = model.type !== "retired_targeting_segment" ? CampaignStrategyUtils.getPath(model.full_path) : CampaignStrategyUtils.getPath(model.full_retired_path);
            const userCPM = model.user_cpm;
            if (model.retail_cpm_amount && model.retail_cpm_amount.length) {
              $.each(model.retail_cpm_amount, assignRetailCPM);
            } else {
              retailCpm = model.retail_cpm;
            }
            mappedItem.displayText = path.displayText;
            mappedItem.title = path.title;
            mappedItem.bin_group_name = model.type !== "retired_targeting_segment" ? model.full_path.split(" - ")[0] : model.full_retired_path.split(" - ")[0];
            mappedItem.uniques = model.uniques ? formatNumber(parseInt(model.uniques, 10)) : "--";
            mappedItem.cpm = userCPM === undefined ? retailCpm : userCPM;
            mappedItem.allowBYOPriceEdit = this.allowBYOPriceEdit;
            mappedItem.asCurrency = T1.Utils.getCurrencyTemplateFunc(currencyCode);
            mappedItem.showWarningIcon = model.type === "retired_targeting_segment";
            mappedItem.headerTooltipRetiredSegmentsWarningText = CampaignStrategyUtils.headerTooltipRetiredSegmentsWarningText;
            mappedItem.headerWarningUrl = CampaignStrategyUtils.perfDataWarningKbUrl;
            result.push(mappedItem);
          }
        }
      }
      return result;
    }
  });
  return contextualBins;
});
