define('collections/behaviors',["jQuery", "T1", "T1Comm", "T1Collection", "models/behavior", "T1Sortable"], function ($, T1, T1Comm, T1Collection, Behavior) {
  "use strict";

  T1.Models.Behaviors = T1Collection.extend({
    model: Behavior,
    apiRoot: T1.SEGMENT_API_BASE,
    urlString: "behaviors",
    url: function () {
      alert("AWS 20");
    }
  });
  return T1.Models.Behaviors;
});
