define('modules/reporting/campaigns/dataExport/createEdit/views/filterBin',["jQuery", "T1", "T1View", "text!../templates/filterBin.html"], function ($, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    eventhubEvents: {
      "createEdit.updateBinGrid": "updateBinGrid"
    },
    initialize: function (args) {
      this.DataExportModel = args.dataExportModel;
      this.BinModel = args.binModel;
    },
    load: function () {
      const $el = this.el;
      const parentView = this;
      const removeItemHandler = this.removeItemHandler;
      this.render().then(() => {
        this.$filterBin = $el.find(".filter-bin");
        this.$filterBin[0].parentView = parentView;
        this.$filterBin[0].mixinFindable = {
          mixinsForValuePath: function () {
            return null;
          },
          mixinsForValue: function () {
            return {
              removeItemHandler: removeItemHandler
            };
          }
        };
        if (this.DataExportModel.get("editReport")) {
          T1Publish("load:savedData");
        }
      });
    },
    unload: function () {
      this.$filterBin = null;
      this.BinModel = null;
    },
    updateBinGrid: function () {
      this.$filterBin[0].set("index", 0);
      this.$filterBin[0].set("data", this.BinModel.getBinData());
    },
    removeItemHandler: function (e) {
      const parentView = this.scope.parentView;
      const event = Polymer && Polymer.dom ? Polymer.dom(e) : e;
      let clickedRow;
      event.path.some(function (elem) {
        clickedRow = elem;
        return elem.tagName && elem.tagName.toLowerCase() === "strand-grid-item";
      });
      const id = clickedRow.getAttribute("data-id");
      parentView.updateRemove(id);
    },
    updateRemove: function (id) {
      this.BinModel.removeData(id);
      T1Publish("change:formElements", true);
    },
    render: function () {
      const $el = this.el;
      const deferred = $.Deferred();
      if (Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
});
