define('modules/reporting/campaigns/models/additionalData',{
  fields: {
    "3pas_adjusted_gross_cpa": {
      type: "currency",
      name: "(3PAS) Adjusted Gross eCPA"
    },
    "3pas_adjusted_gross_cpc": {
      type: "currency",
      name: "(3PAS) Adjusted Gross eCPC"
    },
    "3pas_adjusted_gross_cpm": {
      type: "currency",
      name: "(3PAS) Adjusted Gross eCPM"
    },
    "3pas_clicks": {
      type: "integers",
      name: "(3PAS) Clicks"
    },
    "3pas_creative": {
      type: "name",
      name: "Name"
    },
    "3pas_ctc": {
      type: "percent",
      name: "(3PAS) CTC"
    },
    "3pas_ctr": {
      type: "percent",
      name: "(3PAS) CTR"
    },
    "3pas_gross_cpa": {
      type: "currency",
      name: "(3PAS) Gross eCPA"
    },
    "3pas_gross_cpc": {
      type: "currency",
      name: "(3PAS) Gross eCPC"
    },
    "3pas_gross_cpm": {
      type: "currency",
      name: "(3PAS) Gross eCPM"
    },
    "3pas_impressions": {
      type: "integers",
      name: "(3PAS) Impressions"
    },
    "3pas_media_cost_cpa": {
      type: "currency",
      name: "(3PAS) Media Cost eCPA"
    },
    "3pas_media_cost_cpc": {
      type: "currency",
      name: "(3PAS) Media Cost eCPC"
    },
    "3pas_media_cost_cpm": {
      type: "currency",
      name: "(3PAS) Media Cost eCPM"
    },
    "3pas_pc_conversions": {
      type: "integers",
      name: "(3PAS) PC Conversions"
    },
    "3pas_pv_conversions": {
      type: "integers",
      name: "(3PAS) PV Conversions"
    },
    "3pas_rr_per_1k_imps": {
      type: "percent",
      name: "(3PAS) Response Rate/1K Imps"
    },
    "3pas_total_conversions": {
      type: "integers",
      name: "(3PAS) Total Conversions"
    },
    browser: {
      type: "name",
      name: "Browser"
    },
    browser_version: {
      type: "name",
      name: "Browser Version"
    },
    campaign_id: {
      type: "integers",
      name: "Campaign ID"
    },
    campaign_name: {
      type: "name",
      name: "Campaign Name"
    },
    clicks: {
      type: "integers",
      name: "Clicks"
    },
    concept_id: {
      type: "name",
      name: "Name"
    },
    concept_name: {
      type: "name",
      name: "Name"
    },
    connection_speed: {
      type: "name",
      name: "Connection Speed"
    },
    connection_type: {
      type: "name",
      name: "Connection Type"
    },
    country_name: {
      type: "name",
      name: "Countries"
    },
    creative_name: {
      type: "name",
      name: "Name"
    },
    creative_size: {
      type: "name",
      name: "Name"
    },
    ctc: {
      type: "percent",
      name: "CTC"
    },
    ctr: {
      type: "percent",
      name: "CTR"
    },
    date_range: {
      type: "name",
      name: "Date Range"
    },
    date: {
      type: "date",
      name: "Date"
    },
    day: {
      type: "date",
      name: "Day"
    },
    deal_name: {
      type: "name",
      name: "Name"
    },
    device_make: {
      type: "name",
      name: "Device Make"
    },
    device_model: {
      type: "name",
      name: "Device Model"
    },
    device_type: {
      type: "name",
      name: "Device Type"
    },
    ecosystem_cost: {
      type: "currency",
      name: "Ecosystem Cost"
    },
    end_date: {
      type: "date",
      name: "Date"
    },
    exchange_name: {
      type: "name",
      name: "Name"
    },
    facebook_actions: {
      type: "integers",
      name: "Facebook Actions"
    },
    facebook_app_installs: {
      type: "integers",
      name: "Facebook App Installs"
    },
    facebook_likes: {
      type: "integers",
      name: "Facebook Likes"
    },
    facebook_page_engagement: {
      type: "integers",
      name: "Facebook Page Engagement"
    },
    facebook_post_comments: {
      type: "integers",
      name: "Facebook Post Comments"
    },
    facebook_post_engagement: {
      type: "integers",
      name: "Facebook Post Engagement"
    },
    facebook_post_reactions: {
      type: "integers",
      name: "Facebook Post Reactions"
    },
    facebook_post_shares: {
      type: "integers",
      name: "Facebook Post Shares"
    },
    frequency_bin: {
      type: "name",
      name: "Frequency"
    },
    impressions: {
      type: "integers",
      name: "Impressions"
    },
    inventory_type: {
      type: "name",
      name: "Inventory Type"
    },
    media_cost_cpa: {
      type: "currency",
      name: "Media Cost eCPA"
    },
    media_cost_cpc: {
      type: "currency",
      name: "Media Cost eCPC"
    },
    media_cost_cpcv: {
      type: "money",
      name: "Media Cost eCPCV"
    },
    media_cost_cpm: {
      type: "currency",
      name: "Media Cost eCPM"
    },
    media_cost: {
      type: "currency",
      name: "Media Cost"
    },
    metro_name: {
      type: "name",
      name: "Metros"
    },
    operating_system: {
      type: "name",
      name: "Operating System"
    },
    os_version: {
      type: "name",
      name: "OS Version"
    },
    path: {
      type: "name",
      name: "Path"
    },
    post_click_activities: {
      type: "integers",
      name: "Post-Click Conversions"
    },
    post_click_aov: {
      type: "currency",
      name: "Post-Click AOV"
    },
    post_click_conversions: {
      type: "integers",
      name: "Post-Click Conversions"
    },
    post_click_media_cost_roi: {
      type: "integers",
      name: "Media Cost PC ROI"
    },
    post_click_revenue: {
      type: "currency",
      name: "Post-Click Revenue"
    },
    post_click_total_ad_cost_roi: {
      type: "integers",
      name: "Total Ad Cost PC ROI"
    },
    post_click_total_spend_roi: {
      type: "integers",
      name: "Total Spend PC ROI"
    },
    post_view_activities: {
      type: "integers",
      name: "Post-View Conversions"
    },
    post_view_aov: {
      type: "currency",
      name: "Post-View AOV"
    },
    post_view_conversions: {
      type: "integers",
      name: "Post-View Conversions"
    },
    post_view_media_cost_roi: {
      type: "integers",
      name: "Media Cost PV ROI"
    },
    post_view_revenue_raw: {
      type: "currency",
      name: "Post-View Revenue (Raw)"
    },
    post_view_revenue: {
      type: "currency",
      name: "Post-View Revenue"
    },
    post_view_total_ad_cost_roi: {
      type: "integers",
      name: "Total Ad Cost PV ROI"
    },
    post_view_total_spend_roi: {
      type: "integers",
      name: "Total Spend PV ROI"
    },
    publisher: {
      type: "name",
      name: "Name"
    },
    region_name: {
      type: "name",
      name: "Regions"
    },
    rr_per_1k_imps: {
      type: "percent",
      name: "Response Rate/1K Imps"
    },
    site_domain: {
      type: "name",
      name: "Name"
    },
    start_date: {
      type: "date",
      name: "Date"
    },
    strategy_id: {
      type: "integers",
      name: "Strategy ID"
    },
    strategy_name: {
      type: "name",
      name: "Name"
    },
    strategy_supply_type: {
      type: "name",
      name: "Name"
    },
    strategy_type: {
      type: "name",
      name: "Name"
    },
    target_id: {
      type: "integers",
      name: "Target ID"
    },
    target_name: {
      type: "name",
      name: "Target Name"
    },
    total_ad_cost_cpa: {
      type: "currency",
      name: "Total Ad Cost eCPA"
    },
    total_ad_cost_cpc: {
      type: "currency",
      name: "Total Ad Cost eCPC"
    },
    total_ad_cost_cpcv: {
      type: "money",
      name: "Total Ad Cost eCPCV"
    },
    total_ad_cost_cpm: {
      type: "currency",
      name: "Total Ad Cost eCPM"
    },
    total_ad_cost: {
      type: "currency",
      name: "Total Ad Cost"
    },
    total_aov: {
      type: "currency",
      name: "Total AOV"
    },
    total_conversions: {
      type: "integers",
      name: "Total Conversions"
    },
    total_revenue: {
      type: "currency",
      name: "Total Revenue"
    },
    total_spend_cpa: {
      type: "currency",
      name: "Total Spend eCPA"
    },
    total_spend_cpc: {
      type: "currency",
      name: "Total Spend eCPC"
    },
    total_spend_cpcv: {
      type: "currency",
      name: "Total Spend eCPCV"
    },
    total_spend_cpm: {
      type: "currency",
      name: "Total Spend eCPM"
    },
    total_spend: {
      type: "currency",
      name: "Total Spend"
    },
    tpas_placement_name: {
      type: "name",
      name: "Name"
    },
    uniques: {
      type: "integers",
      name: "Uniques"
    }
  },
  dimensions: {
    performance: {
      all: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        concept_name: {
          text: "Creative Concept",
          xAxisLabel: "Creative Concepts",
          key: "concept_id",
          nameField: "concept_name"
        },
        creative_size: {
          text: "Creative Size",
          xAxisLabel: "Creative Sizes",
          key: "creative_size",
          nameField: "creative_size"
        },
        creative_name: {
          text: "Creative",
          xAxisLabel: "Creatives",
          key: "creative_id",
          nameField: "creative_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        deal_name: {
          text: "Deal",
          xAxisLabel: "Deals",
          key: "deal_id",
          nameField: "deal_name"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        tpas_placement_name: {
          text: "3PAS Placement",
          xAxisLabel: "3PAS Placement",
          key: "tpas_placement_id",
          nameField: "tpas_placement_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        }
      },
      video: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        concept_name: {
          text: "Creative Concept",
          xAxisLabel: "Creative Concepts",
          key: "concept_id",
          nameField: "concept_name"
        },
        creative_size: {
          text: "Creative Size",
          xAxisLabel: "Creative Sizes",
          key: "creative_size",
          nameField: "creative_size"
        },
        creative_name: {
          text: "Creative",
          xAxisLabel: "Creatives",
          key: "creative_id",
          nameField: "creative_name"
        },
        deal_name: {
          text: "Deal",
          xAxisLabel: "Deals",
          key: "deal_id",
          nameField: "deal_name"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        tpas_placement_name: {
          text: "3PAS Placement",
          xAxisLabel: "3PAS Placement",
          key: "tpas_placement_id",
          nameField: "tpas_placement_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        }
      },
      display: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        concept_name: {
          text: "Creative Concept",
          xAxisLabel: "Creative Concepts",
          key: "concept_id",
          nameField: "concept_name"
        },
        creative_name: {
          text: "Creative",
          xAxisLabel: "Creatives",
          key: "creative_id",
          nameField: "creative_name"
        },
        creative_size: {
          text: "Creative Size",
          xAxisLabel: "Creative Sizes",
          key: "creative_size",
          nameField: "creative_size"
        },
        deal_name: {
          text: "Deal",
          xAxisLabel: "Deals",
          key: "deal_id",
          nameField: "deal_name"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        tpas_placement_name: {
          text: "3PAS Placement",
          xAxisLabel: "3PAS Placement",
          key: "tpas_placement_id",
          nameField: "tpas_placement_name"
        }
      }
    },
    site_transparency: {
      all: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        },
        site_domain: {
          text: "Domain",
          xAxisLabel: "Domains",
          key: "site_domain",
          nameField: "Name"
        }
      },
      video: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        },
        site_domain: {
          text: "Domain",
          xAxisLabel: "Domains",
          key: "site_domain",
          nameField: "Name"
        }
      },
      display: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        },
        site_domain: {
          text: "Domain",
          xAxisLabel: "Domains",
          key: "site_domain",
          nameField: "Name"
        }
      },
      viewBy: {
        site_domain: {
          text: "Domain",
          xAxisLabel: "Domains",
          key: "site_domain",
          nameField: "Name"
        }
      },
      filterBy: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        }
      }
    },
    geo: {
      all: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        }
      },
      video: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        }
      },
      display: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        }
      }
    },
    device_technology: {
      viewBy: {
        browser: {
          text: "Browser",
          xAxisLabel: "Browser",
          key: "browser",
          nameField: "browser"
        },
        connection_speed: {
          text: "Connection Speed",
          xAxisLabel: "Connection Speed",
          key: "connection_speed",
          nameField: "connection_speed"
        },
        device_make: {
          text: "Device Make",
          xAxisLabel: "Device Make",
          key: "device_make",
          nameField: "device_make"
        },
        device_model: {
          text: "Device Model",
          xAxisLabel: "Device Model",
          key: "device_model",
          nameField: "device_model"
        },
        device_type: {
          text: "Device Type",
          xAxisLabel: "Device Type",
          key: "device_type",
          nameField: "device_type"
        },
        inventory_type: {
          text: "Inventory Type",
          xAxisLabel: "Inventory Type",
          key: "inventory_type",
          nameField: "inventory_type"
        },
        operating_system: {
          text: "Operating System",
          xAxisLabel: "Operating System",
          key: "operating_system",
          nameField: "operating_system"
        }
      },
      filterBy: {
        browser_version: {
          text: "Browser Version",
          xAxisLabel: "Browser Version",
          key: "browser_version",
          nameField: "browser_version"
        },
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        exchange_name: {
          text: "Exchange",
          xAxisLabel: "Exchanges",
          key: "exchange_id",
          nameField: "exchange_name"
        },
        os_version: {
          text: "OS Version",
          xAxisLabel: "OS Version",
          key: "os_version",
          nameField: "os_version"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        strategy_supply_type: {
          text: "Strategy Supply Type",
          xAxisLabel: "Strategy Supply Type",
          key: "strategy_supply_type",
          nameField: "strategy_supply_type"
        },
        strategy_type: {
          text: "Strategy Type",
          xAxisLabel: "Strategy Types",
          key: "strategy_type",
          nameField: "strategy_type"
        }
      }
    },
    reach_frequency: {
      all: {
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        }
      }
    },
    contextual_insights: {
      all: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        parent: {
          text: "Parent Name",
          xAxisLabel: "Parent Names",
          key: "parent",
          nameField: "parent"
        },
        vendor_name: {
          text: "Vendor",
          xAxisLabel: "Vendors",
          key: "vendor_id",
          nameField: "vendor_name"
        },
        target_name: {
          text: "Target Name",
          xAxisLabel: "Target Name",
          key: "target_id",
          nameField: "target_name"
        }
      },
      video: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        parent: {
          text: "Parent Name",
          xAxisLabel: "Parent Names",
          key: "parent",
          nameField: "parent"
        },
        vendor_name: {
          text: "Vendor",
          xAxisLabel: "Vendors",
          key: "vendor_id",
          nameField: "vendor_name"
        },
        target_name: {
          text: "Target Name",
          xAxisLabel: "Target Name",
          key: "target_id",
          nameField: "target_name"
        }
      },
      display: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        parent: {
          text: "Parent Name",
          xAxisLabel: "Parent Names",
          key: "parent",
          nameField: "parent"
        },
        vendor_name: {
          text: "Vendor",
          xAxisLabel: "Vendors",
          key: "vendor_id",
          nameField: "vendor_name"
        },
        target_name: {
          text: "Target Name",
          xAxisLabel: "Target Name",
          key: "target_id",
          nameField: "target_name"
        }
      },
      filterBy: {
        campaign_name: {
          text: "Campaign",
          xAxisLabel: "Campaigns",
          key: "campaign_id",
          nameField: "campaign_name"
        },
        strategy_name: {
          text: "Strategy",
          xAxisLabel: "Strategies",
          key: "strategy_id",
          nameField: "strategy_name"
        },
        parent: {
          text: "Parent Name",
          xAxisLabel: "Parent Names",
          key: "parent",
          nameField: "parent"
        },
        vendor_name: {
          text: "Vendor",
          xAxisLabel: "Vendors",
          key: "vendor_id",
          nameField: "vendor_name"
        }
      },
      viewBy: {
        target_name: {
          text: "Target Name",
          xAxisLabel: "Target Name",
          key: "target_id",
          nameField: "target_name"
        }
      }
    }
  },
  metrics: {
    all: {
      clicks: {
        text: "Clicks"
      },
      ctc: {
        text: "CTC"
      },
      ctr: {
        text: "CTR"
      },
      ecosystem_cost: {
        text: "Ecosystem Cost",
        onlyInPerformance: true,
        featureFlag: "reporting_ecosystem_cost"
      },
      impressions: {
        text: "Impressions",
        mm: "Imps"
      },
      measurability_rate: {
        text: "Measurability Rate",
        featureFlag: "reporting_viewability"
      },
      post_click_conversions: {
        text: "Post-Click Conversions",
        abbr: "PC Conversions"
      },
      post_click_revenue: {
        text: "Post-Click Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true,
        abbr: "PC Revenue"
      },
      post_view_conversions: {
        text: "Post-View Conversions",
        abbr: "PV Conversions"
      },
      post_view_revenue: {
        text: "Post-View Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true,
        abbr: "PV Revenue"
      },
      revenue: {
        text: "Revenue (Raw)",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      rr_per_1k_imps: {
        text: "Response Rate/1K Imps",
        abbr: "RR/1K Imps",
        mm: "Response Rate per 1000 Imps"
      },
      total_conversions: {
        text: "Total Conversions"
      },
      total_revenue: {
        text: "Total Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      total_spend: {
        text: "Total Spend"
      },
      total_spend_cpa: {
        text: "eCPA",
        mm: "Media eCPA"
      },
      total_spend_cpc: {
        text: "eCPC",
        mm: "Media eCPC"
      },
      total_spend_cpm: {
        text: "eCPM",
        mm: "Media eCPM"
      },
      total_spend_pc_cpa: {
        text: "PC CPA"
      },
      total_spend_pv_cpa: {
        text: "PV CPA"
      },
      total_spend_roi: {
        text: "Total Spend ROI",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      total_spend_vcpm: {
        text: "Total Spend vCPM",
        featureFlag: "reporting_viewability"
      },
      uniques: {
        text: "Uniques",
        onlyInPerformance: true
      },
      viewability_rate: {
        text: "Viewability Rate",
        featureFlag: "reporting_viewability"
      },
      viewability_rate_100_percent: {
        text: "100% In View Rate",
        featureFlag: "reporting_viewability"
      }
    },
    video: {
      clicks: {
        text: "Clicks"
      },
      ctc: {
        text: "CTC"
      },
      ctr: {
        text: "CTR"
      },
      ecosystem_cost: {
        text: "Ecosystem Cost",
        onlyInPerformance: true,
        featureFlag: "reporting_ecosystem_cost"
      },
      impressions: {
        text: "Impressions"
      },
      measurability_rate: {
        text: "Measurability Rate",
        featureFlag: "reporting_viewability"
      },
      post_click_conversions: {
        text: "Post-Click Conversions"
      },
      post_click_revenue: {
        text: "Post-Click Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true,
        abbr: "PC Revenue"
      },
      post_view_conversions: {
        text: "Post-View Conversions"
      },
      post_view_revenue: {
        text: "Post-View Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true,
        abbr: "PV Revenue"
      },
      revenue: {
        text: "Revenue (Raw)",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      rr_per_1k_imps: {
        text: "Response Rate/1K Imps"
      },
      total_conversions: {
        text: "Total Conversions"
      },
      total_revenue: {
        text: "Total Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      total_spend: {
        text: "Total Spend"
      },
      total_spend_cpa: {
        text: "eCPA"
      },
      total_spend_cpc: {
        text: "eCPC"
      },
      total_spend_cpcv: {
        text: "eCPCV"
      },
      total_spend_cpm: {
        text: "eCPM"
      },
      total_spend_pc_cpa: {
        text: "PC CPA"
      },
      total_spend_pv_cpa: {
        text: "PV CPA"
      },
      total_spend_roi: {
        text: "Total Spend ROI",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      total_spend_vcpm: {
        text: "Total Spend vCPM",
        featureFlag: "reporting_viewability"
      },
      video_close: {
        text: "Close"
      },
      video_collapse: {
        text: "Collapse"
      },
      video_companion_clicks: {
        text: "Companion Clicks"
      },
      video_companion_ctr: {
        text: "Companion CTR"
      },
      video_companion_impressions: {
        text: "Companion Impressions"
      },
      video_complete: {
        text: "100% Completed Views"
      },
      video_complete_rate: {
        text: "100% Completed Rate"
      },
      video_complete_rate_impression_based: {
        text: "100% Completed Rate - Impressions Based"
      },
      video_engaged_impressions: {
        text: "Engaged Impressions"
      },
      video_engaged_rate: {
        text: "Engaged Rate"
      },
      video_expand: {
        text: "Expand"
      },
      video_first_quartile: {
        text: "25% Completed Views"
      },
      video_first_quartile_rate: {
        text: "25% Completed Rate"
      },
      video_first_quartile_rate_impression_based: {
        text: "25% Completed Rate - Impressions Based"
      },
      video_fullscreen: {
        text: "Fullscreen"
      },
      video_midpoint: {
        text: "50% Completed Views"
      },
      video_midpoint_rate: {
        text: "50% Completed Rate"
      },
      video_midpoint_rate_impression_based: {
        text: "50% Completed Rate - Impressions Based"
      },
      video_mute: {
        text: "Mute"
      },
      video_pause: {
        text: "Pause"
      },
      video_play_rate: {
        text: "Play Rate"
      },
      video_resume: {
        text: "Resume"
      },
      video_rewind: {
        text: "Rewind"
      },
      video_skip: {
        text: "Skip"
      },
      video_skippable_impressions: {
        text: "Skippable Impressions"
      },
      video_skipped_impressions: {
        text: "Skipped Impressions"
      },
      video_skipped_rate: {
        text: "Skipped Rate"
      },
      video_start: {
        text: "Start"
      },
      video_third_quartile: {
        text: "75% Completed Views"
      },
      video_third_quartile_rate: {
        text: "75% Completed Rate"
      },
      video_third_quartile_rate_impression_based: {
        text: "75% Completed Rate - Impressions Based"
      },
      video_unmute: {
        text: "Unmute"
      },
      uniques: {
        text: "Uniques",
        onlyInPerformance: true
      },
      viewability_rate: {
        text: "Viewability Rate",
        featureFlag: "reporting_viewability"
      },
      viewability_rate_100_percent: {
        text: "100% In View Rate",
        featureFlag: "reporting_viewability"
      }
    },
    display: {
      clicks: {
        text: "Clicks"
      },
      ctc: {
        text: "CTC"
      },
      ctr: {
        text: "CTR"
      },
      ecosystem_cost: {
        text: "Ecosystem Cost",
        onlyInPerformance: true,
        featureFlag: "reporting_ecosystem_cost"
      },
      impressions: {
        text: "Impressions",
        mm: "Imps"
      },
      measurability_rate: {
        text: "Measurability Rate",
        featureFlag: "reporting_viewability"
      },
      post_click_conversions: {
        text: "Post-Click Conversions",
        abbr: "PC Conversions"
      },
      post_click_revenue: {
        text: "Post-Click Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true,
        abbr: "PC Revenue"
      },
      post_view_conversions: {
        text: "Post-View Conversions",
        abbr: "PV Conversions"
      },
      post_view_revenue: {
        text: "Post-View Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true,
        abbr: "PV Revenue"
      },
      revenue: {
        text: "Revenue (Raw)",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      rr_per_1k_imps: {
        text: "Response Rate/1K Imps",
        abbr: "RR/1K Imps",
        mm: "Response Rate per 1000 Imps"
      },
      total_conversions: {
        text: "Total Conversions"
      },
      total_revenue: {
        text: "Total Revenue",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      total_spend: {
        text: "Total Spend"
      },
      total_spend_cpa: {
        text: "eCPA",
        mm: "Media eCPA"
      },
      total_spend_cpc: {
        text: "eCPC",
        mm: "Media eCPC"
      },
      total_spend_cpm: {
        text: "eCPM",
        mm: "Media eCPM"
      },
      total_spend_pc_cpa: {
        text: "PC CPA"
      },
      total_spend_pv_cpa: {
        text: "PV CPA"
      },
      total_spend_roi: {
        text: "Total Spend ROI",
        onlyViewableInCampaignCurrency: true,
        onlyInPerformance: true
      },
      total_spend_vcpm: {
        text: "Total Spend vCPM",
        featureFlag: "reporting_viewability"
      },
      uniques: {
        text: "Uniques",
        onlyInPerformance: true
      },
      viewability_rate: {
        text: "Viewability Rate",
        featureFlag: "reporting_viewability"
      },
      viewability_rate_100_percent: {
        text: "100% In View Rate",
        featureFlag: "reporting_viewability"
      }
    }
  },
  metricsWinLoss: {
    all: {
      average_bid_amount_cpm: {
        text: "Average Bid Amount CPM",
        abbr: "Av. Bid Amount CPM"
      },
      average_win_amount_cpm: {
        text: "Average Win Amount CPM",
        abbr: "Av. Win Amount CPM"
      },
      bid_rate: {
        text: "Bid Rate",
        abbr: "Bid Rate"
      },
      bids: {
        text: "Bids",
        abbr: "Bids"
      },
      matched_bid_opportunities: {
        text: "Matched Bid Opportunities",
        abbr: "Matched Bid Opport."
      },
      max_bid_amount_cpm: {
        text: "Max Bid Amount CPM",
        abbr: "Max Bid Amt. CPM"
      },
      max_win_amount_cpm: {
        text: "Max Win Amount CPM",
        abbr: "Max Win Amt. Cpm"
      },
      min_bid_amount_cpm: {
        text: "Min Bid Amount CPM",
        abbr: "Min Bid Amt. CPM"
      },
      min_win_amount_cpm: {
        text: "Min Win Amount CPM",
        abbr: "Min Win Amt. CPM"
      },
      total_bid_amount_cpm: {
        text: "Total Bid Amount CPM",
        abbr: "Total Bid Amt. CPM"
      },
      total_win_amount_cpm: {
        text: "Total Win Amount CPM",
        abbr: "Total Win Amt. CPM"
      },
      win_rate: {
        text: "Win Rate",
        abbr: "Win Rate"
      },
      wins: {
        text: "Wins",
        abbr: "Wins"
      }
    }
  },
  metricsReachFrequency: {
    all: {
      clicks: {
        text: "Clicks"
      },
      ctc: {
        text: "CTC"
      },
      ctr: {
        text: "CTR"
      },
      impressions: {
        text: "Impressions",
        mm: "Imps"
      },
      post_click_conversions: {
        text: "Post-Click Conversions",
        abbr: "PC Conversions"
      },
      post_view_conversions: {
        text: "Post-View Conversions",
        abbr: "PV Conversions"
      },
      rr_per_1k_imps: {
        text: "Response Rate/1K Imps",
        abbr: "RR/1K Imps",
        mm: "Response Rate per 1000 Imps"
      },
      total_conversions: {
        text: "Total Conversions"
      }
    }
  },
  columns: {
    all: {
      adserving_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Ad Serving Cost*"
      },
      adverification_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Ad Verification Cost*"
      },
      audience_verification_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Audience Verification Cost*"
      },
      billed_spend: {
        onlyViewableInCampaignCurrency: true,
        text: "Billed Spend*"
      },
      clicks: {
        default: "1"
      },
      client_exchange_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Client Exchange Cost*"
      },
      client_margin_share: {
        onlyViewableInCampaignCurrency: true,
        text: "Client Margin Share*"
      },
      contextual_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Contextual Cost*"
      },
      ctc: {
        default: "1"
      },
      ctr: {
        default: "1"
      },
      dynamic_creative_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Dynamic Creative Cost*"
      },
      ecosystem_cost: {
        text: "Ecosystem Cost",
        featureFlag: "reporting_ecosystem_cost",
        onlyInPerformance: true,
        section: ["performance"]
      },
      identity_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Identity Fee*"
      },
      impressions: {
        default: "1"
      },
      in_view_100_percent: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_15_seconds_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_15_seconds: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_5_seconds_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_5_seconds: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      managed_service_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Managed Service Fee*"
      },
      measurement_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Measurement Cost*"
      },
      measurability_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      measurable: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      media_cost_cpa: {
        text: "Media Cost eCPA"
      },
      media_cost_cpc: {
        text: "Media Cost eCPC"
      },
      media_cost_cpm: {
        text: "Media Cost eCPM"
      },
      media_cost_pc_cpa: {
        text: "Media Cost PC CPA"
      },
      media_cost_pv_cpa: {
        text: "Media Cost PV CPA"
      },
      media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost ROI*",
        onlyInPerformance: true
      },
      media_cost_vcpm: {
        text: "Media Cost vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      media_cost: {
        text: "Media Cost"
      },
      mm_data_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Data Cost*"
      },
      mm_margin_share: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Margin Share*"
      },
      mm_total_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Total Fee*"
      },
      optimization_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Optimization Fee*"
      },
      platform_access_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Platform Access Fee*"
      },
      pmp_no_opto_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "PMP Standard Fee*"
      },
      pmp_opto_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "PMP Optimization Fee*"
      },
      post_click_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-Click AOV*"
      },
      post_click_conversions: {},
      post_click_media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost PC ROI*"
      },
      post_click_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-Click Revenue*"
      },
      post_click_total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost PC ROI*"
      },
      post_click_total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend PC ROI*"
      },
      post_view_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View AOV*"
      },
      post_view_conversions: {},
      post_view_media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost PV ROI*"
      },
      post_view_revenue_raw: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View Revenue (Raw)*"
      },
      post_view_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View Revenue*"
      },
      post_view_total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost PV ROI*"
      },
      post_view_total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend PV ROI*"
      },
      privacy_compliance_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Privacy Compliance Cost*"
      },
      revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Revenue (Raw)*",
        onlyInPerformance: true
      },
      rr_per_1k_imps: {
        default: "1"
      },
      source_media_enablement_fee: {
        text: "SOURCE Media Enablement Fee",
        section: ["performance"]
      },
      source_platform_access_fee: {
        text: "SOURCE Platform Access Fee",
        section: ["performance"]
      },
      total_ad_cost_cpa: {
        text: "Total Ad Cost eCPA"
      },
      total_ad_cost_cpc: {
        text: "Total Ad Cost eCPC"
      },
      total_ad_cost_cpm: {
        text: "Total Ad Cost eCPM"
      },
      total_ad_cost_pc_cpa: {
        text: "Total Ad Cost PC CPA"
      },
      total_ad_cost_pv_cpa: {
        text: "Total Ad Cost PV CPA"
      },
      total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost ROI*",
        onlyInPerformance: true
      },
      total_ad_cost_vcpm: {
        text: "Total Ad Cost vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      total_ad_cost: {
        text: "Total Ad Cost"
      },
      total_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Total AOV*"
      },
      total_conversions: {
        default: "1"
      },
      total_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Revenue*"
      },
      total_spend_cpa: {
        default: "1",
        text: "Total Spend eCPA"
      },
      total_spend_cpc: {
        default: "1",
        text: "Total Spend eCPC"
      },
      total_spend_cpm: {
        default: "1",
        text: "Total Spend eCPM"
      },
      total_spend_pc_cpa: {
        text: "Total Spend PC CPA"
      },
      total_spend_pv_cpa: {
        text: "Total Spend PV CPA"
      },
      total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend ROI*",
        onlyInPerformance: true
      },
      total_spend_vcpm: {
        text: "Total Spend vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      total_spend: {
        default: "1",
        text: "Total Spend"
      },
      udi_data_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "UDI Data Cost*"
      },
      uniques: {
        text: "Uniques",
        onlyInPerformance: true
      },
      viewability_cost: {
        text: "Viewability Cost",
        featureFlag: "reporting_viewability",
        section: ["performance"]
      },
      viewability_rate_100_percent: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      viewability_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      }
    },
    video: {
      adserving_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Ad Serving Cost*"
      },
      adverification_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Ad Verification Cost*"
      },
      audience_verification_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Audience Verification Cost*"
      },
      billed_spend: {
        onlyViewableInCampaignCurrency: true,
        text: "Billed Spend*"
      },
      clicks: {
        default: "1"
      },
      client_exchange_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Client Exchange Cost*"
      },
      client_margin_share: {
        onlyViewableInCampaignCurrency: true,
        text: "Client Margin Share*"
      },
      contextual_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Contextual Cost*"
      },
      ctc: {
        default: "1"
      },
      ctr: {
        default: "1"
      },
      dynamic_creative_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Dynamic Creative Cost*"
      },
      ecosystem_cost: {
        text: "Ecosystem Cost",
        featureFlag: "reporting_ecosystem_cost",
        onlyInPerformance: true,
        section: ["performance"]
      },
      identity_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Identity Fee*"
      },
      impressions: {
        default: "1"
      },
      in_view_100_percent: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_15_seconds_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_15_seconds: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_5_seconds_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_5_seconds: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      managed_service_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Managed Service Fee*"
      },
      measurement_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Measurement Cost*"
      },
      measurability_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      measurable: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      media_cost_cpa: {
        text: "Media Cost eCPA"
      },
      media_cost_cpc: {
        text: "Media Cost eCPC"
      },
      media_cost_cpcv: {
        text: "Media Cost eCPCV"
      },
      media_cost_cpm: {
        text: "Media Cost eCPM"
      },
      media_cost_pc_cpa: {
        text: "Media Cost PC CPA"
      },
      media_cost_pv_cpa: {
        text: "Media Cost PV CPA"
      },
      media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost ROI*",
        onlyInPerformance: true
      },
      media_cost_vcpm: {
        text: "Media Cost vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      media_cost: {
        text: "Media Cost"
      },
      mm_data_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Data Cost*"
      },
      mm_margin_share: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Margin Share*"
      },
      mm_total_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Total Fee*"
      },
      optimization_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Optimization Fee*"
      },
      platform_access_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Platform Access Fee*"
      },
      pmp_no_opto_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "PMP Standard Fee*"
      },
      pmp_opto_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "PMP Optimization Fee*"
      },
      post_click_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-Click AOV*"
      },
      post_click_conversions: {},
      post_click_media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost PC ROI*"
      },
      post_click_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-Click Revenue*"
      },
      post_click_total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost PC ROI*"
      },
      post_click_total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend PC ROI*"
      },
      post_view_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View AOV*"
      },
      post_view_conversions: {},
      post_view_media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost PV ROI*"
      },
      post_view_revenue_raw: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View Revenue (Raw)*"
      },
      post_view_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View Revenue*"
      },
      post_view_total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost PV ROI*"
      },
      post_view_total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend PV ROI*"
      },
      privacy_compliance_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Privacy Compliance Cost*"
      },
      revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Revenue (Raw)*",
        onlyInPerformance: true
      },
      rr_per_1k_imps: {
        default: "1"
      },
      source_media_enablement_fee: {
        text: "SOURCE Media Enablement Fee",
        section: ["performance"]
      },
      source_platform_access_fee: {
        text: "SOURCE Platform Access Fee",
        section: ["performance"]
      },
      total_ad_cost_cpa: {
        text: "Total Ad Cost eCPA"
      },
      total_ad_cost_cpc: {
        text: "Total Ad Cost eCPC"
      },
      total_ad_cost_cpcv: {
        text: "Total Ad Cost eCPCV"
      },
      total_ad_cost_cpm: {
        text: "Total Ad Cost eCPM"
      },
      total_ad_cost_pc_cpa: {
        text: "Total Ad Cost PC CPA"
      },
      total_ad_cost_pv_cpa: {
        text: "Total Ad Cost PV CPA"
      },
      total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost ROI*",
        onlyInPerformance: true
      },
      total_ad_cost_vcpm: {
        text: "Total Ad Cost vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      total_ad_cost: {
        text: "Total Ad Cost"
      },
      total_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Total AOV*"
      },
      total_conversions: {
        default: "1"
      },
      total_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Revenue*"
      },
      total_spend_cpa: {
        default: "1",
        text: "Total Spend eCPA"
      },
      total_spend_cpc: {
        default: "1",
        text: "Total Spend eCPC"
      },
      total_spend_cpcv: {
        text: "Total Spend eCPCV"
      },
      total_spend_cpm: {
        default: "1",
        text: "Total Spend eCPM"
      },
      total_spend_pc_cpa: {
        text: "Total Spend PC CPA"
      },
      total_spend_pv_cpa: {
        text: "Total Spend PV CPA"
      },
      total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend ROI*",
        onlyInPerformance: true
      },
      total_spend_vcpm: {
        text: "Total Spend vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      total_spend: {
        default: "1",
        text: "Total Spend"
      },
      udi_data_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "UDI Data Cost*"
      },
      uniques: {
        text: "Uniques",
        onlyInPerformance: true
      },
      video_close: {},
      video_collapse: {},
      video_companion_clicks: {},
      video_companion_ctr: {
        default: "1"
      },
      video_companion_impressions: {
        default: "1"
      },
      video_complete: {},
      video_complete_rate: {
        default: "1"
      },
      video_complete_rate_impression_based: {
        default: "1"
      },
      video_engaged_impressions: {},
      video_engaged_rate: {},
      video_expand: {},
      video_first_quartile: {},
      video_first_quartile_rate: {
        default: "1"
      },
      video_first_quartile_rate_impression_based: {
        default: "1"
      },
      video_fullscreen: {},
      video_midpoint: {},
      video_midpoint_rate: {
        default: "1"
      },
      video_midpoint_rate_impression_based: {
        default: "1"
      },
      video_mute: {},
      video_pause: {},
      video_play_rate: {},
      video_resume: {},
      video_rewind: {},
      video_skip: {},
      video_skippable_impressions: {},
      video_skipped_impressions: {},
      video_skipped_rate: {},
      video_start: {},
      video_third_quartile: {},
      video_third_quartile_rate: {
        default: "1"
      },
      video_third_quartile_rate_impression_based: {
        default: "1"
      },
      video_unmute: {},
      viewability_cost: {
        text: "Viewability Cost",
        featureFlag: "reporting_viewability",
        section: ["performance"]
      },
      viewability_rate_100_percent: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      viewability_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      }
    },
    display: {
      adserving_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Ad Serving Cost*"
      },
      adverification_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Ad Verification Cost*"
      },
      audience_verification_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Audience Verification Cost*"
      },
      billed_spend: {
        onlyViewableInCampaignCurrency: true,
        text: "Billed Spend*"
      },
      clicks: {
        default: "1"
      },
      client_exchange_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Client Exchange Cost*"
      },
      client_margin_share: {
        onlyViewableInCampaignCurrency: true,
        text: "Client Margin Share*"
      },
      contextual_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Contextual Cost*"
      },
      ctc: {
        default: "1"
      },
      ctr: {
        default: "1"
      },
      dynamic_creative_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Dynamic Creative Cost*"
      },
      ecosystem_cost: {
        text: "Ecosystem Cost",
        featureFlag: "reporting_ecosystem_cost",
        onlyInPerformance: true,
        section: ["performance"]
      },
      identity_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Identity Fee*"
      },
      impressions: {
        default: "1"
      },
      in_view_100_percent: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_15_seconds_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_15_seconds: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_5_seconds_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view_5_seconds: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      in_view: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      managed_service_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Managed Service Fee*"
      },
      measurement_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Measurement Cost*"
      },
      measurability_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      measurable: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      media_cost_cpa: {
        text: "Media Cost eCPA"
      },
      media_cost_cpc: {
        text: "Media Cost eCPC"
      },
      media_cost_cpm: {
        text: "Media Cost eCPM"
      },
      media_cost_pc_cpa: {
        text: "Media Cost PC CPA"
      },
      media_cost_pv_cpa: {
        text: "Media Cost PV CPA"
      },
      media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost ROI*",
        onlyInPerformance: true
      },
      media_cost_vcpm: {
        text: "Media Cost vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      media_cost: {
        text: "Media Cost"
      },
      mm_data_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Data Cost*"
      },
      mm_margin_share: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Margin Share*"
      },
      mm_total_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "MM Total Fee*"
      },
      optimization_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Optimization Fee*"
      },
      platform_access_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "Platform Access Fee*"
      },
      pmp_no_opto_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "PMP Standard Fee*"
      },
      pmp_opto_fee: {
        onlyViewableInCampaignCurrency: true,
        text: "PMP Optimization Fee*"
      },
      post_click_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-Click AOV*"
      },
      post_click_conversions: {},
      post_click_media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost PC ROI*"
      },
      post_click_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-Click Revenue*"
      },
      post_click_total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost PC ROI*"
      },
      post_click_total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "PC ROI*"
      },
      post_view_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View AOV*"
      },
      post_view_conversions: {},
      post_view_media_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Media Cost PV ROI*"
      },
      post_view_revenue_raw: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View Revenue (Raw)*"
      },
      post_view_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Post-View Revenue*"
      },
      post_view_total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost PV ROI*"
      },
      post_view_total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "PV ROI*"
      },
      privacy_compliance_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "Privacy Compliance Cost*"
      },
      revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Revenue*",
        onlyInPerformance: true
      },
      rr_per_1k_imps: {
        default: "1"
      },
      source_media_enablement_fee: {
        text: "SOURCE Media Enablement Fee",
        section: ["performance"]
      },
      source_platform_access_fee: {
        text: "SOURCE Platform Access Fee",
        section: ["performance"]
      },
      total_ad_cost_cpa: {
        text: "Total Ad Cost eCPA"
      },
      total_ad_cost_cpc: {
        text: "Total Ad Cost eCPC"
      },
      total_ad_cost_cpm: {
        text: "Total Ad Cost eCPM"
      },
      total_ad_cost_pc_cpa: {
        text: "Total Ad Cost PC CPA"
      },
      total_ad_cost_pv_cpa: {
        text: "Total Ad Cost PV CPA"
      },
      total_ad_cost_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Ad Cost ROI*",
        onlyInPerformance: true
      },
      total_ad_cost_vcpm: {
        text: "Total Ad Cost vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      total_ad_cost: {
        text: "Total Ad Cost"
      },
      total_aov: {
        onlyViewableInCampaignCurrency: true,
        text: "Total AOV*"
      },
      total_conversions: {
        default: "1"
      },
      total_revenue: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Revenue*"
      },
      total_spend_cpa: {
        default: "1",
        text: "Total Spend eCPA"
      },
      total_spend_cpc: {
        default: "1",
        text: "Total Spend eCPC"
      },
      total_spend_cpm: {
        default: "1",
        text: "Total Spend eCPM"
      },
      total_spend_pc_cpa: {
        text: "Total Spend PC CPA"
      },
      total_spend_pv_cpa: {
        text: "Total Spend PV CPA"
      },
      total_spend_roi: {
        onlyViewableInCampaignCurrency: true,
        text: "Total Spend ROI*",
        onlyInPerformance: true
      },
      total_spend_vcpm: {
        text: "Total Spend vCPM",
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      total_spend: {
        default: "1",
        text: "Total Spend"
      },
      udi_data_cost: {
        onlyViewableInCampaignCurrency: true,
        text: "UDI Data Cost*"
      },
      uniques: {
        text: "Uniques",
        onlyInPerformance: true
      },
      viewability_cost: {
        text: "Viewability Cost",
        featureFlag: "reporting_viewability",
        section: ["performance"]
      },
      viewability_rate_100_percent: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      },
      viewability_rate: {
        featureFlag: "reporting_viewability",
        section: ["performance", "site_transparency", "device_technology"]
      }
    },
    reach_frequency: {
      clicks: {
        default: "1"
      },
      ctc: {},
      ctr: {
        default: "1"
      },
      impressions: {
        default: "1"
      },
      rr_per_1k_imps: {
        default: "1"
      },
      post_click_conversions: {
        default: "1"
      },
      post_view_conversions: {
        default: "1"
      },
      total_conversions: {}
    }
  },
  columnsOverrides: {
    site_transparency: {
      clicks: {
        default: "1"
      }
    },
    contextual_insights: {
      video_companion_ctr: {},
      video_companion_impressions: {},
      video_complete_rate: {},
      video_first_quartile_rate: {},
      video_midpoint_rate: {},
      video_third_quartile_rate: {}
    }
  },
  defaultSortForGoalType: {
    cpa: "total_spend_cpa,-1",
    cpe: "total_spend_cpa,-1",
    cpm: "total_spend_cpm,-1",
    cpc: "total_spend_cpc,-1",
    spend: "total_spend_cpm,-1",
    reach: "total_spend_cpm,-1",
    roi: '"",-1',
    vcr: '"",-1',
    ctr: '"",-1',
    viewability_rate: '"",-1',
    vcpm: '"",-1',
    multi: "impressions,-1"
  },
  chartColors: ["#ea661e", "#00a8cc", "#1ca591", "#eac616", "#7f87d0", "#9c8369", "#c32921", "#4a89fa", "#71873e", "#dedb08", "#fd9303", "#90245c", "#c6186d", "#1860c2", "#b2b453", "#f0bf2a", "#b04299", "#c28a69", "#d5384d", "#4694a8", "#6cc068", "#f2981e", "#bc96be", "#ce842d", "#fc6062", "#4dc1e7", "#1ca591", "#ffd527", "#3e537b", "#d49719"],
  geoLevelConfig: {
    all: {
      dimension: "country_name",
      aggregate_level: "Country"
    },
    country: {
      dimension: "region_name",
      aggregate_level: "Region"
    },
    region: {
      dimension: "metro_name",
      aggregate_level: "Metro"
    }
  }
});
