define('modules/audiences/onboarding/create/views/onboardingForm',["jQuery", "Underscore", "Backbone", "Hogan", "T1", "T1Permissions", "T1Spinner", "modules/audiences/segments/segmentUtils", "text!modules/audiences/onboarding/create/templates/createEdit.html", "text!modules/audiences/onboarding/create/templates/advertisers.html", "text!modules/audiences/onboarding/create/templates/customAttributes.html", "text!modules/audiences/onboarding/create/templates/delivery.html", "text!modules/audiences/onboarding/create/templates/footer.html", "text!modules/audiences/onboarding/create/templates/header.html", "text!modules/audiences/onboarding/create/templates/implementations.html", "text!modules/audiences/onboarding/create/templates/revenueAttribution.html", "text!modules/audiences/onboarding/create/templates/standardAttributes.html", "text!modules/audiences/onboarding/create/templates/events.html", "models/user", "models/advertiser", "models/organization", "models/onboarding", "collections/organizations", "../../../segments/create/views/advertisers", "text!modules/audiences/segments/create/templates/unsavedChanges.html", "jQueryUI"], function ($, _, Backbone, Hogan, T1, T1Permissions, Spinner, Util, template, AdvertisersTemplate, CustomAttributeTemplate, DeliveryTemplate, FooterTemplate, HeaderTemplate, ImplementationsTemplate, RevenueTemplate, StandardAttributesTemplate, EventsTemplate, User, Advertiser, Organization, Onboarding, Organizations, Advertisers, unsavedChangesTemplate) {
  "use strict";

  var createEditView = Backbone.View.extend({
    template: Hogan.compile(template),
    unsavedChangesTemplate: unsavedChangesTemplate,
    AdvertisersTemplate: Hogan.compile(AdvertisersTemplate),
    CustomAttributeTemplate: Hogan.compile(CustomAttributeTemplate),
    DeliveryTemplate: Hogan.compile(DeliveryTemplate),
    FooterTemplate: Hogan.compile(FooterTemplate),
    HeaderTemplate: Hogan.compile(HeaderTemplate),
    ImplementationsTemplate: Hogan.compile(ImplementationsTemplate),
    RevenueTemplate: Hogan.compile(RevenueTemplate),
    StandardAttributesTemplate: Hogan.compile(StandardAttributesTemplate),
    EventsTemplate: Hogan.compile(EventsTemplate),
    initialize: function (options) {
      this.mode = options.id === "create" ? "create" : "edit";
      this.onboardMethod = options.section;
      if (this.mode === "edit") {
        this.id = options.deepLinks[0];
      }
    },
    updateBreadCrumb(locations) {
      T1.EventHub.publish("updateNavPath", locations);
    },
    load: function (opt) {
      this.viewOptions = opt && opt.viewOptions || T1.Location.getViewOptions();
      this.model = this.model || new Onboarding();
      this.model.onboardMethod = this.onboardMethod === "in-app" ? "app" : this.onboardMethod;
      this.updateBreadCrumb(this.breadcrumb);
      this.render().then(() => {
        this.renderCallback();
      });
    },
    renderConfigs: {},
    events: {
      "changed #advertisers": "advertiserChanged",
      "changed #advertisers strand-input": "searchAdvertisers",
      "changed #groupName": "groupNameChanged",
      "click #save-button": "saveModel",
      "click #cancel-button": "resetScreen",
      "changed #implementationMethod": "implementationChanged",
      "changed #otherImplementation": "otherImplementationChanged",
      "changed #otherTagManagement": "otherTagManagementChanged",
      "changed #tagManagement": "tagManagementChanged",
      "changed #eventOptions": "standardEventAdded",
      "changed #attributeOptions": "standardAttributeAdded",
      "click .add-row": "repeaterRowAdded",
      "changed #currencyDynamic": "revenueAttributionChanged",
      "changed #currencyFixed": "revenueAttributionChanged",
      "changed #revenue": "revenueAttributionChanged",
      "click #attrPair": "revenueAttributionChanged",
      "changed #currencyType": "switchCurrencyDropdowns",
      "changed #deliveryOptions": "deliveryChanged",
      "changed #emailTo": "emailOptionsChanged",
      "changed #emailMessage": "emailOptionsChanged",
      "click #onboarding-modal-close": "closeModal",
      'changed #custom-events strand-input[name="key"]': "modifyKey",
      "click #events": "repeatersChanged",
      "click #standard-attributes": "repeatersChanged",
      "click .remove-row": "repeatersChanged",
      "changed #custom-events": "repeatersChanged",
      "changed #custom-attributes": "repeatersChanged",
      "click #custom-attributes": "repeatersChanged",
      "click .attribute": "repeatersChanged",
      "changed .attribute": "repeatersChanged",
      "changed .note": "repeatersChanged",
      "changed #attributeSearch": "searchOptions",
      "changed #eventSearch": "searchOptions",
      "click .clearRow": "clearRepeaterRow",
      "keypress .float-input": "onPricingTypeInput",
      "changed #currencyFixed strand-input": "searchCurrencies",
      "click .add-attr-row": "addRepeaterRow"
    },
    modifyKey: function (e) {
      e.target.value = e.target.value ? e.target.value.toLowerCase().replace(/\s/g, "_") : "";
    },
    render: function () {
      var def = $.Deferred();
      var viewInBrowserEnabled = this.renderConfigs.viewInBrowserEnabled;
      var baseConfigs = {
        model: "{{model}}",
        scope: "{{scope}}",
        index: "{{index}}",
        data: "{{data}}",
        id: "{{model.id}}",
        key: "{{model.key}}",
        newKey: "{{!model.key}}",
        name: "{{model.name}}",
        newName: "{{!model.name}}",
        value: "{{model.value}}",
        note: "{{model.note}}",
        existing: "{{model.existing}}",
        newAttr: "{{!model.existing}}",
        dataType: "{{model.data_type}}",
        showClear: "{{!scope._showRemove}}",
        title: this.title,
        editMode: this.mode === "edit",
        viewInBrowserEnabled: viewInBrowserEnabled,
        deliverySectionClass: viewInBrowserEnabled ? "col-2-4" : "col-1-4",
        formatOptions: this.model.formatOptions
      };
      var renderObj = Object.assign(baseConfigs, this.renderConfigs);
      var renderedTemplate = this.template.render(renderObj, {
        header: this.HeaderTemplate,
        advertisers: this.AdvertisersTemplate,
        implementations: this.ImplementationsTemplate,
        events: this.EventsTemplate,
        standardAttributes: this.StandardAttributesTemplate,
        customAttributes: this.CustomAttributeTemplate,
        revenueAttribution: this.RevenueTemplate,
        delivery: this.DeliveryTemplate,
        footer: this.FooterTemplate
      });
      if (Polymer && Polymer.dom) {
        Polymer.dom(this.el[0]).innerHTML = renderedTemplate;
      } else {
        $(this.el).html(renderedTemplate);
      }
      this.delegateEvents(this.events);
      T1.resizeWidth();
      T1.EventHub.subscribe("organization:select", this.orgChanged);
      $(".nav-list, .knowledge-base, .logout, .active").unbind().click(event => {
        this.warnForUnsavedChanges(event, event.target.hash || event.target.parentNode.hash);
      });
      Organizations.getOrganizations().getSelected().then(orgId => {
        this.orgId = orgId;
        def.resolve();
      });
      return def.promise();
    },
    renderCallback: function () {
      this.loadImplementations();
      if (this.mode === "edit") {
        this.loadModel();
      } else {
        this.loadAdvertisers();
      }
    },
    orgChanged: function () {
      T1.Location.reload();
    },
    loadAdvertisers: function () {
      this.advertisersObj = new Advertisers({
        parent: this,
        allAdv: true,
        dataSharing: false
      });
      this.advertisersObj.render();
      this.advertisersObj.loadAdvertisers(this.owner_id);
    },
    loadImplementations: function () {
      var bindToDropdowns = (methods, tag_managers) => {
        this.el[0].querySelector("#implementationMethod").set("data", methods);
        this.el[0].querySelector("#tagManagement").set("data", tag_managers);
      };
      return this.model.fetchImplementationMethods(bindToDropdowns);
    },
    searchAdvertisers: function (event) {
      var value = event.srcElement.value;
      if (value) {
        this.advertisersObj.searchAdvertisers(value, event);
      } else {
        this.advertisersObj.loadAdvertisers(event);
      }
    },
    resetImplementationOptions: function () {
      this.el[0].querySelector("#tagManagement").reset();
      this.el[0].querySelector("#otherImplementation").clearInput();
      this.el[0].querySelector("#otherTagManagement").clearInput();
      this.$("#tagManagementGroup, #otherImplementation, #otherTagManagement, #implementationHeader, #tagHeader").hide();
    },
    populateEvents: function () {
      this.eventsRepeater = this.el[0].querySelector("#events");
      this.eventsRepeater.set("data", this.model.fetchEventDefaults("default"));
      this.eventsRepeater.validation = this.validateEvents;
    },
    populateStandardAttributes: function () {
      this.standardAttributesRepeater = this.el[0].querySelector("#standard-attributes");
      this.standardAttributesRepeater.set("data", this.model.fetchAttributeDefaults());
    },
    populateCustomAttributes: function () {
      this.customAttributesRepeater = this.el[0].querySelector("#custom-attributes");
      this.el[0].querySelector("#attributeKey").set("data", this.customAttributeKeys);
      this.customAttributesRepeater.set("data", []);
      this.customAttributesRepeater.validation = this.validateCustomAttributes;
    },
    populateCurrencyOptions: function (data) {
      var currencyValues = T1.Utils.getCurrencyList({
        selectedId: "USD"
      });
      var currencyFixed = this.el[0].querySelector("#currencyFixed");
      var currencyDynamic = this.el[0].querySelector("#currencyDynamic");
      var revenue = this.el[0].querySelector("#revenue");
      var defaultFixed = "USD";
      var defaultDynamic = "query.v2";
      if (data && data.currency) {
        if (data.currency.includes("query")) {
          defaultDynamic = data.currency;
          this.el[0].querySelector("#currencyType").value = "Dynamic";
        } else {
          defaultFixed = data.currency;
        }
      }
      this.currencyValues = currencyValues.map(currency => Object.assign(currency, {
        name: currency.text
      }));
      currencyDynamic.data = this.model.attribution.currency[0].map(opt => Object.assign(opt, {
        value: opt.key
      }));
      revenue.data = this.model.attribution.revenue[0].map(opt => Object.assign(opt, {
        value: opt.key
      }));
      currencyFixed.data = this.currencyValues;
      currencyFixed.value = defaultFixed;
      currencyDynamic.value = defaultDynamic;
      revenue.value = data && data.revenue ? data.revenue : "query.v1";
    },
    validateEvents: function (data, added, modified, removed) {
      return data.map(function (model) {
        var isEmptyRow = !model.key && !model.name;
        var isStandardRow = !model.custom;
        if (isEmptyRow) {
          return {
            cId: model.cId
          };
        }
        if (isStandardRow) {
          return model;
        }
        return {
          cId: model.cId,
          key: !model.key ? "Event name required" : model.key.length > 30 ? "Event name is limited to 30 characters" : !model.key.match(/^(\w|\s)*$/) ? "Event name must be alphanumeric" : null,
          name: model.name ? null : "Pixel name required"
        };
      });
    },
    validateCustomAttributes: function (data, added, modified, removed) {
      return data.map(function (model, index, data) {
        var isEmptyRow = !model.key && !model.name && !model.data_type && !model.note;
        if (isEmptyRow) {
          return {
            cId: model.cId
          };
        }
        return {
          cId: model.cId,
          key: !model.key ? "Attribute required" : _.pluck(data, "key").find((el, i) => el === model.key && i < index) ? "Attribute must be unique" : null
        };
      });
    },
    groupNameChanged: function () {
      var groupName = this.el[0].querySelector("#groupName").value;
      this.model.name = groupName;
      this.saveCheck();
    },
    advertiserChanged: function () {
      var advertiserValue = this.el[0].querySelector("#advertisers").value || this.model.advertiser_id;
      if (typeof advertiserValue === "object") {
        return;
      }
      this.model.advertiser_id = advertiserValue;
      this.el[0].querySelector("#implementationMethod").disabled = false;
      this.saveCheck();
    },
    implementationChanged: function (e) {
      var initialEditLoad = e.originalEvent.detail.value === this.model.method;
      var implementation = this.el[0].querySelector("#implementationMethod").value;
      this.model.method = implementation;
      if (!initialEditLoad) {
        this.resetImplementationOptions();
      }
      if (implementation === "Tag Manager") {
        this.$("#tagManagementGroup").show();
      } else if (implementation === "Other") {
        this.$("#otherImplementation").show();
        this.$("#implementationHeader").show();
      }
      if (!initialEditLoad) {
        if (this.mode === "edit") {
          if (implementation === "Floodlight Container") {
            this.model.fetchSetup().then(() => {
              var missingDefaults = this.model.fetchAttributeDefaults().filter(attr => attr.name === "Referrer" || attr.name === "Document Title");
              this.standardAttributesRepeater.set("data", [...this.standardAttributesRepeater.data, ...this.model.dataToRepeater(missingDefaults)]);
            });
          } else {
            this.standardAttributesRepeater.set("data", this.standardAttributesRepeater.data.filter(attr => attr.name !== "Referrer" && attr.name !== "Document Title"));
          }
        } else {
          this.model.fetchSetup().then(() => this.populateRepeaters());
        }
      }
      this.saveCheck();
    },
    otherImplementationChanged: function () {
      this.model.method_other = this.el[0].querySelector("#otherImplementation").value;
      this.saveCheck();
    },
    tagManagementChanged: function () {
      var tagManagement = this.el[0].querySelector("#tagManagement").value;
      this.model.tagManagement = tagManagement;
      if (tagManagement === "Other" && this.model.method === "Tag Manager") {
        $("#otherTagManagement, #tagHeader").show();
      } else {
        this.el[0].querySelector("#otherTagManagement").clearInput();
        this.$("#otherTagManagement, #tagHeader").hide();
      }
      this.saveCheck();
    },
    otherTagManagementChanged: function () {
      this.model.tag_manager_other = this.el[0].querySelector("#otherTagManagement").value;
      this.saveCheck();
    },
    revenueAttributionChanged: function (e) {
      this.enableDisableRevenuePair();
      this.checkRevenuePair(e);
      this.saveCheck();
    },
    repeatersChanged: function (e) {
      var isAddRow = e.srcElement.parentNode.className.includes("add-row");
      var isRemoveIcon = e.currentTarget.className.includes("remove-row");
      if (isRemoveIcon) {
        var removedRepeater = $(e.currentTarget).closest("strand-box").find("label")[0].innerText.toLowerCase().replace(/(\+add )/, "").replace(/\s/, "-").concat("s");
      }
      var targetId = e.currentTarget.id || removedRepeater;
      var targetType = e.srcElement.localName;
      var isAttr = targetId === "attributeKey" || targetId === "standard-attributes" || targetId === "custom-attributes";
      var isDrop = targetType === "strand-list-item";
      if (isAttr && (isDrop || isRemoveIcon || isAddRow)) {
        this.updateRepeaterDropdownData(targetId);
      }
      this.checkRevenuePair();
      this.saveCheck();
    },
    deliveryChanged: function () {
      this.model.delivery_method = this.el[0].querySelector("#deliveryOptions").items.filter(item => item.value === true).map(item => item.id);
      this.updateDeliveryFields();
      this.saveCheck();
    },
    emailOptionsChanged: function () {
      this.model.email_to = this.el[0].querySelector("#emailTo").value;
      this.model.email_message = this.el[0].querySelector("#emailMessage").value;
      this.saveCheck();
    },
    standardEventAdded: function (e) {
      if (e.srcElement.nodeName === "STRAND-INPUT") {
        return;
      }
      var keyValue = e.currentTarget.value;
      var nameInput = e.currentTarget.parentNode.parentNode.querySelector("input");
      if (keyValue === "Custom") {
        let customRow = this.eventsRepeater.data.find(row => row.key === "Custom");
        let customIndex = customRow.cId;
        customRow.custom = true;
        customRow.key = "";
        let eventsData = [...this.eventsRepeater.data];
        this.eventsRepeater.data = [];
        requestAnimationFrame(() => {
          this.eventsRepeater.data = eventsData;
        });
      }
      nameInput.value = keyValue;
    },
    standardAttributeAdded: function (e) {
      var repeater = $(e.srcElement).closest("strand-repeater")[0];
      var newlyAdded = repeater.data[repeater.data.length - 1];
      repeater.data.map(row => {
        let currentOption = this.attributeOptions.find(x => x.name === row.name);
        if (currentOption) {
          return (row.key = currentOption.key) && row;
        } else {
          return row;
        }
      });
      repeater.set("data", this.model.dataToRepeater(repeater.data));
    },
    repeaterRowAdded: function (e) {
      var repeater = $(e.srcElement).closest("strand-repeater")[0].id;
      if (repeater === "standard-attributes") {
        [...this.el[0].querySelectorAll("#attributeOptions")].pop().data = this.currentData["standard-attributes"];
      } else if (repeater === "events") {
        [...this.el[0].querySelectorAll("#eventOptions")].pop().data = this.eventOptions;
      } else if (repeater === "custom-attributes") {
        [...this.el[0].querySelectorAll("#attributeKey")].pop().data = this.currentData["custom-attributes"];
      }
    },
    populateRepeaters: function (cb, data) {
      this.attributeOptions = this.model.fetchAttributeOptions();
      this.eventOptions = this.model.fetchEventDefaults("optional");
      this.customAttributeKeys = this.model.fetchCustomAttributeKeys();
      this.currentData = {
        "standard-attributes": this.attributeOptions,
        "custom-attributes": this.customAttributeKeys
      };
      this.populateEvents();
      this.populateStandardAttributes();
      this.populateCustomAttributes();
      this.populateCurrencyOptions(data);
      this.revenueAttributionChanged();
      this.$(".events-attributes-section").show();
      if (cb) {
        cb();
      }
    },
    searchOptions: function (e) {
      var searchValue = e.srcElement.value;
      var dropdown = $(e.target).closest("strand-dropdown")[0];
      var searchType = dropdown.id;
      var masterData = searchType === "eventOptions" ? this.model.fetchEventDefaults("optional") : this.currentData["standard-attributes"];
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        let searchResults = masterData.filter(item => item.name.toLowerCase().includes(searchValue));
        dropdown.set("data", searchResults);
      } else {
        dropdown.set("data", masterData);
      }
      dropdown.open();
    },
    enableDisableRevenuePair: function () {
      this.model.revenuePairEnabled = this.el[0].querySelector("#attrPair").checked;
      const toggleSection = disable => {
        this.el[0].querySelector("#revenue").disabled = disable;
        this.el[0].querySelector("#currencyFixed").disabled = disable;
        this.el[0].querySelector("#currencyDynamic").disabled = disable;
        if (disable) {
          this.el[0].querySelector("#currencyType").classList.add("disabled");
        } else {
          this.el[0].querySelector("#currencyType").classList.remove("disabled");
        }
      };
      toggleSection(!this.model.revenuePairEnabled);
    },
    switchCurrencyDropdowns: function () {
      var currencyType = this.el[0].querySelector("#currencyType").value;
      var fixed = this.el[0].querySelector("#currencyFixed");
      var dynamic = this.el[0].querySelector("#currencyDynamic");
      if (currencyType === "Fixed") {
        this.$(fixed).show();
        this.$(dynamic).hide();
        dynamic.reset();
        dynamic.value = "query.v2";
      } else {
        this.$(dynamic).show();
        this.$(fixed).hide();
        fixed.reset();
        fixed.value = "USD";
      }
      this.checkRevenuePair({
        currentTarget: {
          id: "currencyDynamic"
        }
      });
    },
    checkRevenuePair: function (e) {
      let viewEl = this.el[0];
      var revenue = viewEl.querySelector("#revenue").value;
      var dynamic = viewEl.querySelector("#currencyDynamic").value;
      var fixed = viewEl.querySelector("#currencyFixed").value;
      var currencyType = viewEl.querySelector("#currencyType").value;
      var errorBox = viewEl.querySelector("#revenueErrorBox");
      if (this.model.revenuePairEnabled) {
        let standard_attributes = _.pluck(this.standardAttributesRepeater.data, "name");
        let custom_attributes = _.pluck(this.customAttributesRepeater.data, "key");
        let [rev_compare, rev_attr, rev_attr_type] = revenue === "query.v1" ? ["Revenue", standard_attributes, "standard"] : [revenue, custom_attributes, "custom"];
        let [cur_compare, cur_attr, cur_attr_type] = dynamic === "query.v2" ? ["Currency", standard_attributes, "standard"] : [dynamic, custom_attributes, "custom"];
        let revenueAndCurrencyMatch = revenue && revenue === dynamic;
        let revenueHasNoAttr = !rev_attr.includes(rev_compare);
        let currencyHasNoAttr = currencyType === "Dynamic" && !cur_attr.includes(cur_compare);
        if (!revenueAndCurrencyMatch && !revenueHasNoAttr && !currencyHasNoAttr) {
          this.model.currency = currencyType === "Fixed" ? fixed : dynamic;
          this.model.revenue = revenue;
          this.$(revenueErrorBox).hide();
          this.revenueAttributionError = false;
        }
        if (revenueAndCurrencyMatch) {
          this.showRevenueError("The revenue and currency dropdowns cannot have the same value.", "match");
          if (e && (e.currentTarget.id === "revenue" || e.currentTarget.id === "currencyDynamic")) {
            e.currentTarget.error = true;
          }
        } else {
          viewEl.querySelector("#revenue").error = false;
          viewEl.querySelector("#currencyDynamic").error = false;
          this.$(`#matchError`).hide();
        }
        if (revenueHasNoAttr) {
          let selected_rev = this.model.attribution.revenue[0].filter(obj => obj.selected)[0].name;
          this.showRevenueError(`Error: you have selected ${selected_rev} within the Revenue Attribution Pair,\n                but ${rev_compare.replace(/.*\./, "").toUpperCase()} has not been defined as a ${rev_attr_type} attribute.`);
        } else {
          this.$(`#revenueError`).hide();
        }
        if (currencyHasNoAttr) {
          let selected_cur = this.model.attribution.currency[0].filter(obj => obj.selected)[0].name;
          this.showRevenueError(`Error: you have selected ${selected_cur} within the Revenue Attribution Pair,\n                but ${cur_compare.replace(/.*\./, "").toUpperCase()} has not been defined as a ${cur_attr_type} attribute.`, "currency");
        } else {
          this.$(`#currencyError`).hide();
        }
      } else {
        this.$(revenueErrorBox).hide();
        this.revenueAttributionError = false;
        viewEl.querySelector("#revenue").error = false;
        viewEl.querySelector("#currencyDynamic").error = false;
      }
      this.model.currencyType = currencyType;
    },
    showRevenueError: function (msg, revenueOrCurrency = "revenue") {
      var error = this.el[0].querySelector(`#${revenueOrCurrency}Error`);
      var errorBox = this.$(revenueErrorBox);
      Polymer.dom(error).innerHTML = msg;
      this.$(`#${revenueOrCurrency}Error`).show();
      errorBox.show();
      this.revenueAttributionError = true;
    },
    searchCurrencies: function (e) {
      const currencyFixed = this.el[0].querySelector("#currencyFixed");
      const searchTerm = e.currentTarget.value;
      if (searchTerm) {
        let filteredList = this.currencyValues.filter(currency => currency.text.toLowerCase().includes(searchTerm));
        currencyFixed.data = filteredList;
      } else {
        currencyFixed.data = this.currencyValues;
      }
      currencyFixed.open();
    },
    updateDeliveryFields: function () {
      var methods = this.model.delivery_method;
      var containsEmail = methods.includes("email");
      if (containsEmail) {
        this.$(".onboarding-email-container").show();
      } else {
        this.$(".onboarding-email-container").hide();
      }
    },
    clearRepeaterRow: function (e) {
      var targetIcon = e.srcElement;
      var repeater = this.$(targetIcon).closest("strand-repeater")[0];
      var emptyData = repeater.id.includes("attributes") ? [] : [{
        cId: 0
      }];
      repeater.set("data", emptyData);
      if (emptyData.length) {
        requestAnimationFrame(() => this.repeaterRowAdded(e));
      }
    },
    addRepeaterRow: function (e) {
      var target = e.srcElement;
      var repeater = this.$(target).closest(".form-stack").find("strand-repeater")[0];
      repeater.set("data", [{
        cId: 0
      }]);
      e.srcElement = repeater.children[0];
      requestAnimationFrame(() => this.repeaterRowAdded(e));
    },
    closeModal: function () {
      var modal = this.el[0].querySelector("#view-onboarding-modal");
      modal.hide();
    },
    saveCheck: function (opts) {
      opts = opts || {};
      var implementationSet = () => {
        var implementation = this.model.method;
        if (implementation) {
          if (!this.el[0].querySelector("#implementationMethod").data.some(el => el.value === implementation)) {
            this.invalidImplementationMethod = true;
            this.showFooterMsg(`${implementation} is not a valid Implementation Method`);
            return false;
          } else {
            this.invalidImplementationMethod = false;
          }
          if (implementation === "Tag Manager") {
            return this.model.tagManagement;
          }
          return true;
        }
        return false;
      };
      var eventsExist = () => {
        if (!this.eventsRepeater) {
          return false;
        }
        return this.eventsRepeater.data.some(x => x.key);
      };
      var validateRepeaters = () => {
        if (this.repeaterValidationError) {
          var eventsError = !this.eventsRepeater.validate();
          var customAttributesError = !this.el[0].querySelector("#custom-attributes").validate();
          this.repeaterValidationError = eventsError || customAttributesError;
          return !this.repeaterValidationError;
        } else {
          return true;
        }
      };
      var createCanSave = this.mode === "create" && this.model.advertiser_id && this.model.name;
      var editCanSave = this.mode === "edit" && this.eventsRepeater;
      if (editCanSave) {
        let oldData = JSON.parse(JSON.stringify(this.model.loadedData));
        let newData = JSON.parse(JSON.stringify(this.createSaveObj()));
        delete oldData.id;
        delete oldData.type;
        delete oldData.events;
        this.editHasChanged = Object.keys(oldData).filter(key => oldData[key] || newData[key]).some(key => {
          var prepareToCompare = function (value) {
            if (Array.isArray(value)) {
              value = value.map(item => {
                if (!item.note) {
                  delete item.note;
                }
                delete item.id;
                delete item.cId;
                delete item.custom;
                return item;
              }).sort((a, b) => {
                var matcher = a.key ? "key" : a.event_name ? "event_name" : "";
                if (matcher) {
                  return a[matcher] < b[matcher] ? -1 : 1;
                } else {
                  return a < b ? -1 : 1;
                }
              });
            }
            return JSON.stringify(value);
          };
          var matchedData = prepareToCompare(newData[key]) === prepareToCompare(oldData[key]);
          return !matchedData;
        });
      }
      var canSave = (createCanSave || editCanSave) && implementationSet() && !this.revenueAttributionError && eventsExist() && validateRepeaters() && this.validateDelivery();
      if (!this.repeaterValidationError && !(this.mode === "edit" && !this.editHasChanged) && !this.invalidImplementationMethod) {
        this.hideFooterMsg();
      }
      if (canSave) {
        this.enableSave();
      } else {
        this.disableSave();
      }
    },
    validateDelivery: function () {
      const delivery = this.model.delivery_method && this.model.delivery_method.length > 0;
      if (delivery) {
        return !this.model.delivery_method.includes("email") ? true : this.model.email_to;
      } else {
        return false;
      }
    },
    enableSave: function () {
      this.$("#save-button").removeAttr("disabled");
    },
    disableSave: function () {
      this.$("#save-button").attr("disabled", true);
    },
    hideFooterMsg: function () {
      var footer = $("#onboarding-create-footer")[0];
      footer.hideMessage();
    },
    showFooterMsg: function (msg) {
      var footer = this.$("#onboarding-create-footer")[0];
      if (msg) {
        footer.type = "error";
        footer.message = msg;
        footer.showMessage();
      } else {
        footer.type = "success";
        footer.message = "Save Successful";
        footer.showMessage();
      }
    },
    updateRepeaterDropdownData: function (repeaterId) {
      repeaterId = repeaterId === "attributeKey" ? "custom-attributes" : repeaterId;
      var releventData = repeaterId === "standard-attributes" ? this.attributeOptions : this.customAttributeKeys;
      var releventDrop = repeaterId === "standard-attributes" ? "#attributeOptions" : "#attributeKey";
      var repeaterData = this.el[0].querySelector("#" + repeaterId).data;
      var repeaterKeys = _.pluck(repeaterData.filter(item => item.key), "key");
      this.currentData[repeaterId] = releventData.filter(item => {
        let optKey = item.key;
        return !repeaterKeys.includes(optKey);
      });
      [...this.el[0].querySelectorAll(releventDrop)].filter(drop => !drop.value).map(drop => drop.data = this.currentData[repeaterId]);
    },
    createSaveObj: function () {
      this.model.events = this.eventsRepeater.data;
      this.model.standard_attributes = this.standardAttributesRepeater.data;
      this.model.custom_attributes = this.customAttributesRepeater.data;
      this.model.delivery_format = this.el[0].querySelector("#deliveryFormat").value;
      return this.model.dataToSaveObj();
    },
    saveModel: function (e) {
      if (e) {
        var eventsError = !this.eventsRepeater.validate();
        var customAttributesError = !this.el[0].querySelector("#custom-attributes").validate();
        this.repeaterValidationError = eventsError || customAttributesError;
      }
      if (this.repeaterValidationError) {
        var errors = [];
        if (eventsError) {
          errors.push("Events");
        }
        if (customAttributesError) {
          errors.push("Custom Attributes");
        }
        this.saveCheck();
        this.showFooterMsg("Please address the errors with the " + errors.join(" & ") + " section" + (errors.length > 1 ? "s" : "") + ".");
        return;
      }
      this.createSaveObj();
      if (e) {
        Util.buttonLoader(e);
      }
      this.el[0].querySelector("#onboarding-form").classList.add("disabled");
      this.model.save({
        success: (model, resp) => {
          this.successCallback.call(this, model, resp, e);
        },
        error: (e, status, error) => {
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
          this.enableSave();
          Util.buttonReset($("#save-button"), "Save");
          this.el[0].querySelector("#onboarding-form").classList.remove("disabled");
        }
      });
    },
    loadModel: function () {
      this.loadImplementations().then(() => {
        this.model.loadGroup(this.id, this.viewOptions).then(data => {
          this.handlePostLoadOptions(this.viewOptions, data);
          let advertiser = new Advertiser({
            id: data.advertiser_id
          }).fetch({
            success: response => {
              this.el[0].querySelector("#selected-advertiser").innerHTML = response.get("name");
            },
            error: err => {
              this.showFooterMsg("Error loading advertiser");
            }
          });
          this.el[0].querySelector("#selected-name").innerHTML = this.model.name;
          Polymer.dom(this.el[0].querySelector(".onboarding-head strand-header")).innerHTML = this.model.name;
          this.advertiserChanged();
          this.model.method = data.method;
          this.el[0].querySelector("#implementationMethod").value = data.method;
          this.el[0].querySelector("#tagManagement").value = data.tag_manager;
          this.el[0].querySelector("#otherTagManagement").value = data.tag_manager_other;
          this.el[0].querySelector("#otherImplementation").value = data.method_other;
          this.updateBreadCrumb();
          this.model.fetchSetup().then(() => this.populateRepeaters(() => {
            this.eventsRepeater.set("data", data.events);
            this.standardAttributesRepeater.set("data", data.standard_attributes);
            this.customAttributesRepeater.set("data", data.custom_attributes);
            requestAnimationFrame(() => {
              [...this.el[0].querySelectorAll("#attributeKey")].map(dropdown => dropdown.set("data", this.customAttributeKeys));
              this.updateRepeaterDropdownData("custom-attributes");
              this.saveCheck({
                postLoad: true,
                viewOptions: this.viewOptions
              });
            });
            if (data.revenue) {
              this.el[0].querySelector("#attrPair").click();
            }
          }, data));
        });
      });
    },
    successCallback: function (model, response, e) {
      Util.buttonReset(e.target, "Save");
      this.resetScreen();
      this.$(e.currentTarget).removeAttr("disabled");
      this.showFooterMsg();
    },
    handlePostLoadOptions: function (options) {
      if (options && options.postSave) {
        this.showFooterMsg();
      }
    },
    resetScreen: function (e) {
      this.el[0].querySelector("#onboarding-form").classList.remove("disabled");
      if (!e) {
        this.resetImplementationOptions();
        this.el[0].querySelector("#advertisers").reset();
        this.el[0].querySelector("#groupName").clearInput();
        this.el[0].querySelector("#implementationMethod").reset();
        this.customAttributesRepeater.set("data", []);
        this.populateCurrencyOptions();
        this.$(".events-attributes-section").hide();
      } else {
        this.warnForUnsavedChanges(e, T1.Location.get());
      }
    },
    warnForUnsavedChanges: function (event, path) {
      var formStarted = this.mode === "create" && this.model.advertiser_id && this.model.method || this.editHasChanged;
      var saveDisabled = "";
      var unsavedChangesDialog;
      var $saveButton = this.el.find("#save-button")[0];
      if (!formStarted) {
        T1.Location.set(path);
        return;
      }
      if ($saveButton && $saveButton.hasAttribute("disabled")) {
        saveDisabled = "disabled";
      }
      unsavedChangesDialog = $(Hogan.compile(this.unsavedChangesTemplate).render({
        saveDisabled: saveDisabled,
        isStandardSegmentEdit: false
      }));
      event.stopPropagation();
      event.preventDefault();
      unsavedChangesDialog.dialog({
        modal: true,
        width: 600,
        autoOpen: true,
        draggable: false,
        dialogClass: "unsaved-segment-changes-dialog"
      });
      $(self.el).append(unsavedChangesDialog);
      $(".ui-dialog-titlebar", $(".unsaved-segment-changes-dialog")).hide();
      $("#unsaved-changes-cancel-button").on("click", function (e) {
        e.preventDefault();
        unsavedChangesDialog.remove();
      });
      $("#unsaved-changes-continue-button").on("click", function (e) {
        unsavedChangesDialog.remove();
        if (path) {
          e.preventDefault();
          if (path.search(/^https:/) !== -1) {
            window.location.assign(path);
          } else {
            T1.Location.set(path);
          }
        } else {
          T1.Location.reload();
        }
      });
      $("#unsaved-changes-save-button").on("click", e => {
        e.preventDefault();
        this.saveModel(e, path);
        unsavedChangesDialog.remove();
      });
    },
    onPricingTypeInput: function (e) {
      const input = String.fromCharCode(e.which);
      const isNumeric = $.isNumeric(input);
      const isPeriod = input === "." && !e.currentTarget.value.includes(".");
      if (isNumeric || isPeriod) {
        return;
      } else {
        e.preventDefault();
      }
    },
    reload: function () {
      this.load.call(this);
    },
    unload: function () {
      if (this.layout) {
        this.layout.unload();
      }
      $(this.el).empty();
      this.unbind();
      T1.EventHub.unsubscribe("organization:select");
    }
  });
  return createEditView;
});
