define('modules/concept/views/popup',["jQuery", "Underscore", "T1", "T1View", "T1Animate", "collections/concepts", "models/concept", "text!../templates/popup.html", "When", "T1Selectbox"], function ($, _, T1, T1View, T1Animate, Concepts, Concept, template, When) {
  return T1View.extend({
    template: template,
    events: {
      "click .ui-cancel": "handleCancel",
      "click .ui-submit": "handleSubmit",
      "mousedown .popup-container:not(input)": "handleSilent",
      "keydown input": "handleKey"
    },
    eventhubEvents: {
      "popup:show": "showPopup",
      "popup:hide": "hidePopup",
      "dropdown:advertiser": "setAdvertiserId"
    },
    initialize: function (args) {
      var eventName = args.entity || "advertisers";
      this.advertiser_id = args.advertiser_id;
      this.model = new Concept();
      T1.EventHub.subscribe("dropdown:" + eventName, $.proxy(this.handleSelection, this));
      this.hideProxy = $.proxy(this.hidePopup, this);
    },
    handleSelection: function (data) {
      this.advertiser_id = data.id;
    },
    handleCancel: function () {
      var $input = $(this.el).find("input");
      $input.val("");
      this.hidePopup();
    },
    handleSubmit: function (e) {
      var $input = $(this.el).find("input");
      var val = $input.val();
      if (val.length > 0) {
        var a = this.sendData(val).then($.proxy(function () {
          this.hidePopup();
          $input.val("");
          T1.EventHub.publish("dropdown:concepts:selection", {
            model: this.model,
            entity: "concepts",
            selectedId: this.model.id
          });
        }, this), $.proxy(function (errors) {
          this.showError(errors);
        }, this), function () {});
      } else {
        this.showError("Concept Name is required!");
      }
    },
    handleSilent: function (e) {
      e.stopImmediatePropagation();
    },
    sendData: function (val) {
      var deferred = When.defer();
      this.model.save({
        name: val,
        status: 1,
        advertiser_id: this.advertiser_id
      }, {
        success: $.proxy(function () {
          deferred.resolve(this.model);
        }, this),
        statusInvalid: $.proxy(function (errors) {
          var batchedErrors = _.pluck(errors, "message");
          batchedErrors = batchedErrors.join("<br/>\n");
          deferred.reject(batchedErrors);
        }, this)
      });
      return deferred.promise;
    },
    showError: function (errors) {
      this.errors = errors;
      this.hasError = true;
      this.renderPartial(".error-wrapper");
      $(this.el).find("input").addClass("error");
    },
    handleKey: function (e) {
      if (e.keyCode === 13) {
        this.handleSubmit();
      }
    },
    showPopup: function () {
      var $input = $(this.el).find("input");
      this.model = new Concept();
      setTimeout(function () {
        $input.focus();
      }, 0);
      T1.EventHub.publish("dropdown:concepts:close");
      $(this.el).find(".popup-container").show();
      $(document).one("mousedown", this.hideProxy);
    },
    hidePopup: function (e) {
      if (e) {
        if ($.contains($(".popup-container")[0], e.target)) {
          return;
        }
      }
      $(this.el).find(".popup-container").hide();
      this.hasError = false;
      this.errors = "";
      this.render();
    },
    unload: function () {
      $(document).off("mousedown", this.hideProxy);
    },
    serialize: function () {
      return {
        hasError: this.hasError,
        error: this.errors
      };
    }
  });
});
