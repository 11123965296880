define('modules/targeting/audience/main',["jQuery", "T1Module", "models/strategyAudienceGroupedSegments", "collections/organizations", "collections/strategyRetiredAudienceSegments"], function ($, T1Module, GroupedSegments, Organizations, StrategyRetiredAudienceSegments) {
  "use strict";

  return new T1Module({
    name: "targeting/audience",
    viewCfgs: {
      panel: {},
      browse: {},
      bins: {},
      readOnly: {
        model: null
      }
    },
    defaultView: "panel",
    getView: function (type, opts) {
      let resultPromise;
      var self = this;
      if (type === "bins") {
        const deferred = $.Deferred();
        T1Module.prototype.getView.call(self, type, opts).then(function (view) {
          deferred.resolve(view);
        });
        resultPromise = deferred.promise();
      } else if (type === "panel") {
        const deferred = $.Deferred();
        const strategy = opts.strategy;
        const strategyVersion = strategy.get("version");
        const groupedSegments = new GroupedSegments({
          id: strategy.id,
          version: strategyVersion
        });
        const retiredSegments = new StrategyRetiredAudienceSegments({
          version: strategyVersion
        });
        const currencyCode = strategy.getCurrencyCode();
        opts.partialCollection = groupedSegments.get("partials");
        opts.sharedCollection = groupedSegments.get("user_saved");
        opts.groupedSegments = groupedSegments;
        opts.sharedCollection.loaded = false;
        opts.retiredCollection = retiredSegments;
        opts.retiredCollection.loaded = false;
        groupedSegments.fetch({
          success: function () {
            const sharedCollection = opts.sharedCollection;
            const modelsToBeRemoved = sharedCollection.models.reduce(function (arr, model) {
              if (model.get("audience_vendor_id") === COMPASS_ENV.IAS_AUDIENCE_VENDOR_ID || model.get("id") === COMPASS_ENV.IAS_ID) {
                arr.push(model);
              }
              return arr;
            }, []);
            sharedCollection.remove(modelsToBeRemoved, {
              silent: true
            });
            sharedCollection.loaded = true;
          },
          currency_code: currencyCode
        });
        retiredSegments.strategyId = strategy.id;
        retiredSegments.fetchOptions.currency_code = currencyCode;
        retiredSegments.fetch({
          success: function () {
            Organizations.getOrganizations().getSelected().then(function (id) {
              opts.orgId = id;
              T1Module.prototype.getView.call(self, type, opts).then(function (view) {
                deferred.resolve(view);
              });
            });
          },
          action: "retired_audience_segments"
        });
        resultPromise = deferred.promise();
      } else {
        resultPromise = T1Module.prototype.getView.call(this, type, opts);
      }
      return resultPromise;
    }
  });
});
