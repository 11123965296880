define('modules/reporting/campaigns/dataExport/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "dataExport",
    name: "reporting/campaigns/dataExport",
    viewCfgs: {
      dataExport: {}
    }
  });
});
