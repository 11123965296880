define('models/bulk3Pas',["jQuery", "Underscore", "T1", "T1Model", "Backbone", "utils/CreativeUtils", "collections/vendors"], function ($, _, T1, T1Model, Backbone, CreativeUtils, Vendors) {
  "use strict";

  T1.Models.Bulk3Pas = T1Model.extend({
    apiRoot: T1.API_ROOT,
    initialize: function () {},
    validateAdvertiserId: function () {
      if (this.attributes.advertiser_id) {
        return true;
      } else {
        return false;
      }
    },
    setResultData: function (resultData) {
      this.set({
        result_data: resultData
      });
      this.setBatchId(resultData.batch.id);
      this.setInvalidPlacement(resultData.batch.placements.invalid.placement);
      this.setValidPlacement(resultData.batch.placements.valid.placement);
      this.resetCheckedItems();
    },
    setValidPlacement: function (validData) {
      var validPlacement = this.ensurePlacementIsArray(validData);
      this.set({
        valid_placement: validPlacement
      });
      this.setBatchIndex(validPlacement);
    },
    setInvalidPlacement: function (invalidData) {
      var invalidPlacement = this.ensurePlacementIsArray(invalidData);
      this.set({
        invalid_placement: invalidPlacement
      });
    },
    setBatchId: function (batchId) {
      this.set({
        batch_id: batchId
      });
      this.setPostUrl(batchId);
    },
    setBatchIndex: function (validData) {
      var batchIndex = this.getBatchIndices(validData);
      this.updateBatchIndex(batchIndex);
    },
    updateBatchIndex: function (batchIndex) {
      this.set({
        batch_index: batchIndex
      });
    },
    setCheckedItem: function (id) {
      var checkedItems = this.get("checked_items");
      checkedItems.push(id);
    },
    resetCheckedItems: function () {
      this.set({
        checked_items: []
      });
    },
    setConcept: function (conceptObj) {
      var placements = this.get("valid_placement");
      var checkedItems = this.get("checked_items");
      _.each(checkedItems, function (id) {
        placements[id].concept_id = conceptObj.id;
        placements[id].concept_name = conceptObj.name;
      });
    },
    getErrors: function (errors) {
      if (errors && errors.error !== undefined) {
        return errors.error;
      }
    },
    ensurePlacementIsArray: function (data) {
      var output = [];
      if (data !== undefined) {
        if (!data.length) {
          output = new Array(data);
        } else {
          _.each(data, function (item, index) {
            item.add_classifications = [];
            item.current_classifications = [];
            item.click_url = "";
            item.id = index;
            item.manualVendors = new Vendors();
            item.manual_vendors = CreativeUtils.parseVendorListForDisplay(item.manualVendors);
            item.remove_classifications = [];
            item.selected = false;
            output.push(item);
          });
        }
      }
      return output;
    },
    validateAttrs: function (attrs) {
      var self = this;
      var errors = [];
      var optionalFields = ["click_url"];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        click_url: function () {
          if (attrs.click_url) {
            return self.validateUrl(attrs.click_url, 1e4);
          }
          return false;
        },
        name: function () {
          if (attrs.name) {
            if (attrs.name.length > 256) {
              return "Name must not exceed 256 characters.";
            }
          }
          return false;
        }
      };
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (!validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (attrs.hasOwnProperty("click_url")) {
        validationResult = reqFields.click_url();
        if (validationResult !== false) {
          errors.push({
            field: "click_url",
            message: validationResult
          });
        }
      }
      if (attrs.hasOwnProperty("name")) {
        validationResult = reqFields.name();
        if (validationResult !== false) {
          errors.push({
            field: "name",
            message: validationResult
          });
        }
      }
      if (errors.length) {
        return errors;
      }
    },
    validateUrl: function (value, maxChar) {
      var urlValidation = /^(http|https):\/\/[^ "]+$/;
      maxChar = maxChar || 2048;
      if (!urlValidation.test(value)) {
        return "Please provide a valid URL.";
      }
      if (value.length > maxChar) {
        return "URL must not exceed " + maxChar + " characters.";
      }
      return false;
    },
    getBatchIndices: function (data) {
      var output = [];
      _.each(data, function (item) {
        var batchIndex = item.batch_index;
        output.push(batchIndex);
      });
      return output;
    },
    setPostUrl: function (urlId) {
      var urlStr = "creatives/upload/";
      this.url = urlStr + urlId;
    },
    reset: function () {
      this.url = "";
      this.attributes = {};
    }
  });
  return T1.Models.Bulk3Pas;
});
