define('utils/TimezoneUtils',["T1", "moment-timezone", "jsTimezoneDetect"], function (T1, moment) {
  var parseDateAsUTC = T1.parseDateAsUTC;
  var offset = new Date().getTimezoneOffset();
  var TimezoneUtils = function () {
    function getClientTimezoneAbbr() {
      var tzAbbr;
      try {
        tzAbbr = moment().tz(jstz.determine().name()).zoneAbbr();
      } catch (e) {
        tzAbbr = "GMT";
      }
      return tzAbbr;
    }
    function convertUTCToLocalTime(time) {
      return parseDateAsUTC(time).addMinutes(-offset);
    }
    return {
      getClientTimezoneAbbr: getClientTimezoneAbbr,
      convertUTCToLocalTime: convertUTCToLocalTime
    };
  };
  return TimezoneUtils();
});
