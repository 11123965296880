define('modules/reporting/segments/views/segment_reports',["jQuery", "Underscore", "T1", "T1View", "T1TabLayoutV2", "text!../templates/segment_reports.html"], function ($, _, T1, T1View, T1TabLayout, template) {
  "use strict";

  return T1View.extend({
    template: template,
    trackingSection: "Reporting - Segments - Data Export",
    events: {},
    dataEvents: {},
    eventhubEvents: {
      "update:canUnload": function (args) {
        this.formElementsChanged = args.formElementsChanged;
        this.cancelUnloadFn = args.cancelUnloadFn;
      }
    },
    initialize: function (args) {
      this.initLayout(args);
    },
    initLayout: function (args) {
      var self = this;
      var models;
      var tabs = [{
        label: "Data Export",
        module: "reporting/segments/dataExport",
        options: args,
        routeName: "segments/dataExport",
        viewType: "dataExport"
      }];
      this.layout = new T1TabLayout({
        destroyViewOnSwitch: true,
        el: function () {
          return self.el.find(".reporting-tabs");
        },
        ignoreRouteDepth: false,
        level: 1,
        selected: 0,
        tabs: tabs,
        tabSetName: "SegmentReporting"
      });
      args = args || {};
      this.models = models = args.models;
      models.setupDataExportModel();
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.load();
      });
    },
    canUnload: function (args) {
      if (this.formElementsChanged) {
        if (this.cancelUnloadFn instanceof Function) {
          this.cancelUnloadFn(args);
        }
        return false;
      }
      return true;
    },
    unload: function () {}
  });
});
