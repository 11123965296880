define('modules/media/pmp-e/views/binStrandGrid',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1UtilsAsync", "T1Permissions", "T1Currencies", "T1Spinner", "models/sessionUser", "models/userPreferences", "text!../templates/binStrandGrid.html", "collections/agencies", "collections/timezones", "collections/advertisers", "collections/organizations", "collections/supplySources", "collections/mediaDeals", "../helpers/parseDeals", "../helpers/sortDeals"], function ($, _, When, T1, T1View, T1Layout, T1UtilsAsync, T1Permissions, T1Currencies, T1Spinner, User, UserPreferences, template, Agencies, Timezones, Advertisers, Organizations, SupplySources, Deals, ParseDeals, SortDeals) {
  "use strict";

  const apiLimit = 100;
  const searchDebounce = 350;
  const fetchDelay = 100;
  const dealOwnerTypeMap = {
    organization: "organization",
    agency: "agency",
    advertiser: "advertiser"
  };
  const binStrandGrid = T1View.extend({
    template: template,
    searchTerm: "",
    items: {},
    selectedItems: {},
    dealOwner: {},
    events: {
      "changed .deal-group-search-tree-input": "loadDealSearch",
      "item-selected .tree-permissions": "handleTargetingPermissionsChanged",
      "item-include-or-exclude creatives-bin-list-item": "handleRemoveFromBin",
      "select-all .tree-permissions": "handleSelectAllEntities",
      "item-selected .deals-grid": "handleSelectItem",
      "changed .deal-status-toggle-dropdown": "handleShowStatusType",
      "column-sort strand-grid-column": "sort",
      "changed .deal-group-deal-search-select": "handleSearchType"
    },
    eventhubEvents: {
      "dealGroupOwnerType:selected": "handleOwnerTypeSelected",
      "owner:selected": "handleOwnerSelected",
      "deal:showTargetingPermissionsGrid": "handleShowTargetingPermissionsGrid",
      loadSupplyOnBinGrid: "handleSupplyData",
      "dealGroupLoaded:updateBinGrid": "handleDealGroupData",
      "organizationChanged:hideBinGrid": "hideGrid"
    },
    initialize() {
      this.firstCall = 0;
      this.pageCount = 100;
      this.dealNameGet = true;
      this.dealIdGet = false;
      this.activeStatusGet = true;
      this.selectItem = this.selectItem.bind(this);
      this.hidden = true;
    },
    load() {
      this.render().then(() => {
        T1.EventHub.publish("binGridLoaded:fetchSupply");
        this.el.find("#selectAll").hide();
        this.el.find("#headerIcon").hide();
        this.el.find(".grid-helper")[0].callback = this.handleScroll.bind(this);
        this.binList = this.el.find("#selected-items-bin")[0];
        this.dealGrid = this.el.find(".deals-grid")[0];
        this.searchInput = this.el.find(".deal-group-search-tree-input")[0];
        this.dealGrid.noResultsMessage = "No deals available.";
      });
    },
    hideGrid() {
      const dealsSearchTree = this.el.find(".deals-search-tree");
      dealsSearchTree.attr("hidden", "");
      this.searchInput.clearInput();
      this.binList.header = "Assigned Deals (0)";
      this.binList.data = [];
      this.dealGrid.data = [];
      this.selectedItems = {};
      this.hidden = true;
    },
    showGrid() {
      const dealsSearchTree = this.el.find(".deals-search-tree");
      const entityGrid = this.dealGrid;
      entityGrid.isLoading = true;
      dealsSearchTree.removeAttr("hidden");
      this.hidden = false;
      this.fetchDeals().then(() => {
        this.parseData();
        this.firstCall += 1;
        if (this.isNewDeal && this.firstCall > 0) {
          this.dealPermissionsLoaded = true;
        }
      });
    },
    handleSupplyData({
      supplyCollection: supplyCollection
    }) {
      T1.EventHub.publish("supplyLoaded:setOwner");
      this.supplyCollection = supplyCollection;
      this.supply_obj = this.supplyCollection.models.reduce(function (result, item) {
        result[item.attributes.id] = item.attributes.name;
        return result;
      }, {});
    },
    handleDealGroupData({
      dealGroup: dealGroup,
      originalTargeting: originalTargeting
    }) {
      this.dealGroup = dealGroup;
      this.isEditingDealGroup = true;
      this.originalTargeting = {};
      originalTargeting.forEach(deal => {
        this.selectItem(deal);
        this.originalTargeting[deal.id] = deal;
      });
      this.isFirstPopulate = true;
    },
    handleOwnerTypeSelected(data) {
      if (data && data.type !== dealOwnerTypeMap.organization) {
        this.hideGrid();
        const dealIdObj = {
          dealGroupDealIds: Object.keys(this.selectedItems)
        };
        T1.EventHub.publish("dealGroupDealsChanged:updateDealGroupDeals", dealIdObj);
      }
    },
    handleOwnerSelected({
      dealOwner: dealOwner
    }) {
      this.hideGrid();
      this.dealOwner = dealOwner;
      this.showGrid();
    },
    handleSearchType() {
      const searchIdName = this.el.find(".deal-group-deal-search-select")[0];
      const searchValue = this.searchInput;
      if (searchIdName.value === "name") {
        this.dealNameGet = true;
        this.dealIdGet = false;
      } else if (searchIdName.value === "id") {
        this.dealIdGet = true;
        this.dealNameGet = false;
      }
      this.dealGrid.sortField = "none";
      searchValue.clearInput();
    },
    handleScroll(event) {
      const maxCount = 15;
      if (this.dealCount - event < maxCount && this.deals.meta.total_count > this.dealCount) {
        if (!this.isFetching) {
          this.isFetching = true;
          this.fetchMoreDeals(event);
        }
      }
    },
    handleShowStatusType(event) {
      this.dealGrid.sortField = "none";
      if (event.originalEvent.detail.value === "All") {
        this.inactiveStatusGet = false;
        this.activeStatusGet = false;
        this.allStatusGet = true;
      } else if (event.originalEvent.detail.value === "Active") {
        this.inactiveStatusGet = false;
        this.activeStatusGet = true;
        this.allStatusGet = false;
      } else if (event.originalEvent.detail.value === "Inactive") {
        this.inactiveStatusGet = true;
        this.activeStatusGet = false;
        this.allStatusGet = false;
      }
      this.loadStatus();
    },
    handleRemoveFromBin(event) {
      const model = event.originalEvent.detail.model;
      const entityGrid = this.dealGrid;
      const entityGridData = entityGrid.data;
      const dealId = model.deal_id || model.id;
      delete this.selectedItems[dealId];
      entityGridData.forEach(row => {
        if (row.selected && !this.selectedItems[row.deal_id]) {
          row.selected = false;
          row.selectedState = "unchecked";
        }
      });
      this.binList.header = `Assigned Deals (${Object.keys(this.selectedItems).length})`;
      entityGrid.data = entityGridData.slice();
      const dealIdObj = {
        dealGroupDealIds: Object.keys(this.selectedItems)
      };
      T1.EventHub.publish("dealGroupDealsChanged:updateDealGroupDeals", dealIdObj);
    },
    handleSelectItem(event) {
      const model = event.originalEvent.detail;
      const isChecked = model.selected;
      if (isChecked) {
        this.selectItem(model);
      } else {
        this.setEntityUnselected(model);
      }
      const selectedItemLength = Object.keys(this.selectedItems).length;
      const dealIdObj = {
        dealGroupDealIds: Object.keys(this.selectedItems)
      };
      this.binList.header = `Assigned Deals (${selectedItemLength})`;
      this.binList.set("data", Object.values(this.selectedItems));
      T1.EventHub.publish("dealGroupDealsChanged:updateDealGroupDeals", dealIdObj);
    },
    setEntityUnselected(entity) {
      delete this.selectedItems[entity.deal_id];
      entity.selected = false;
      entity.selectedState = "unchecked";
    },
    handleShowTargetingPermissionsGrid(data) {
      this.showGrid(data.shouldSelectAll);
    },
    handleTargetingPermissionsChanged(event) {
      T1.EventHub.publish("dealShowWarningStatus");
      this.handleSelectItem(event);
      if (this.dealDataLoaded) {
        this.dealPermissionsLoaded = true;
      }
    },
    handleSelectAllEntities(event) {
      const isSelected = event.originalEvent.detail.selected;
      const isSearching = event.target.searchTerm !== "";
      const gridData = event.target.data;
      const hasGridData = Boolean(gridData.length);
      if (isSelected && !isSearching) {
        this.addElementsToBin(true);
      } else if (isSelected && isSearching && hasGridData) {
        gridData.forEach(entity => {
          this.handleSelectItem(event, entity);
        });
      } else if (!isSelected && isSearching && hasGridData) {
        const isOwnerSelected = this.selectedItems[this.dealOwner.id];
        if (isOwnerSelected) {
          this.selectedItems = {};
          const isOrgOwned = this.dealOwner.type === dealOwnerTypeMap.organization;
          let otherEntities = isOrgOwned ? this.agencyList : this.advertiserList;
          gridData.forEach(entity => {
            otherEntities = otherEntities.filter(otherEntity => otherEntity.id !== entity.id);
          });
          otherEntities.forEach(this.selectItem);
        } else {
          gridData.forEach(entity => {
            delete this.selectedItems[entity.id];
          });
        }
      } else if (!isSearching) {
        this.selectedItems = {};
      } else {
        const prevState = this.dealGrid.get("_selectAllState") === "checked" ? "unchecked" : "checked";
        this.dealGrid.set("_selectAllState", prevState);
      }
      const selectedArr = Object.values(this.selectedItems);
      this.binList.set("data", selectedArr);
      T1.EventHub.publish("binGridChanged:updatePermissions", {
        permissionedEntities: selectedArr
      });
    },
    loadStatus() {
      var grid = this.$(".deals-grid")[0];
      var searchValue = this.$(".deal-group-search-tree-input")[0].value;
      var hasNoValue = searchValue === "" || searchValue === null || searchValue === undefined;
      grid.isLoading = true;
      if (hasNoValue) {
        this.dealNameGet = false;
        this.dealIdGet = false;
        this.allSearchGet = false;
        T1UtilsAsync.linkPromises([this.fetchDeals(false)]).then(() => {
          this.isFirstDealCall = true;
          this.parseData();
        });
      } else if (searchValue.length > 1) {
        this.allSearchGet = false;
        T1UtilsAsync.linkPromises([this.fetchDeals(searchValue)]).then(() => {
          this.isFirstDealCall = true;
          this.parseData();
        });
      }
    },
    parseData(page) {
      var gridArray = this.gridArray = [];
      this.deals.data.forEach(function (obj) {
        obj.price_display = T1Currencies.formatNumber(obj.price.currency_code, obj.price.value);
      });
      gridArray = ParseDeals.getDeals(this.deals.data, this.supply_obj);
      gridArray.forEach(function (row) {
        row.currency_symbol = T1.Utils.getCurrencySymbol(row.currency_code);
      });
      if (this.isEditingDealGroup && this.isFirstPopulate) {
        gridArray.forEach(row => {
          if (this.originalTargeting[row.deal_id]) {
            row.selected = true;
            row.selectedState = "checked";
            row.included = true;
            this.selectedItems[row.deal_id] = row;
          }
        });
        const originalTargetingLength = Object.keys(this.originalTargeting).length;
        this.binList.header = `Assigned Deals (${originalTargetingLength})`;
        this.isFirstPopulate = false;
      }
      this.binList.set("data", Object.values(this.selectedItems));
      this.updateGrid(gridArray, page);
    },
    updateGrid(array) {
      const grid = this.dealGrid;
      array.forEach(deal => {
        if (this.selectedItems[deal.deal_id]) {
          this.selectItem(deal);
        }
      });
      if (this.isFirstDealCall) {
        grid.data = array;
      } else {
        grid.data = grid.data.concat(array);
      }
      grid.isLoading = false;
    },
    fetchDeals(val) {
      var self = this;
      var deferred = T1UtilsAsync.makeDeferral();
      var fetchOptions = this.getFetchOptions(val);
      this.collection = new Deals(false, {
        fetchOptions: fetchOptions,
        pageLimit: this.pageCount,
        isPaginated: true
      });
      this.collection.fetch({
        success: function (parsed, data) {
          self.isFirstDealCall = true;
          self.sendObj = {};
          data.data.forEach(deal => {
            self.sendObj[deal.id] = self.formatDeal(deal);
          });
          self.deals = data;
          self.prevDealCount = self.dealCount;
          self.dealCount = data.meta.total_count > apiLimit ? apiLimit : data.meta.total_count;
          deferred.resolve(data.data);
        }
      });
      return deferred.promise;
    },
    fetchMoreDeals: _.debounce(function (index) {
      const self = this;
      const size = this.pageCount;
      const page = Math.ceil(index / size);
      const grid = this.dealGrid;
      if (grid.data[page * apiLimit]) {
        return;
      }
      this.isFirstDealCall = false;
      this.collection.page = page;
      grid.isLoading = true;
      T1.EventHub.publish("fetchingDealData:showLoader");
      this.collection.fetch({
        add: true,
        success: function (parsed, data) {
          data.data.forEach(deal => {
            self.sendObj[deal.id] = self.formatDeal(deal);
          });
          self.deals = data;
          self.dealCount += 100;
          self.parseData(data.meta.offset);
          self.isFetching = false;
        }
      });
    }, fetchDelay),
    populateQUrl(val) {
      let qUrl;
      if (val) {
        val = val.replace(/&/g, "%26");
      }
      if (!this.dealNameGet && !this.dealIdGet) {
        const dealSearchType = this.el.find(".deal-group-deal-search-select")[0].value;
        if (!dealSearchType || dealSearchType === "name") {
          this.dealNameGet = true;
          this.dealIdGet = false;
        } else {
          this.dealIdGet = true;
          this.dealNameGet = false;
        }
      }
      if (this.dealNameGet) {
        qUrl = `name=:*${val}*`;
      } else if (this.dealIdGet) {
        qUrl = `deal_identifier=:*${val}*`;
      }
      return qUrl;
    },
    getFetchOptions(val) {
      const fetchOptions = {};
      switch (this.dealOwner.type.toLowerCase()) {
        case dealOwnerTypeMap.organization:
          fetchOptions["owner.organization_id"] = this.dealOwner.id;
          fetchOptions["owner.type"] = dealOwnerTypeMap.organization.toUpperCase();
          break;
        case dealOwnerTypeMap.agency:
          fetchOptions["owner.agency_id"] = this.dealOwner.id;
          fetchOptions["owner.type"] = dealOwnerTypeMap.agency.toUpperCase();
          break;
        case dealOwnerTypeMap.advertiser:
          fetchOptions["owner.advertiser_id"] = this.dealOwner.id;
          break;
      }
      if (val) {
        fetchOptions.q = this.populateQUrl(val);
      }
      if (this.activeStatusGet) {
        fetchOptions.status = "true";
      } else if (this.inactiveStatusGet) {
        fetchOptions.status = "false";
      }
      return fetchOptions;
    },
    selectItem(item) {
      const dealIdAttribute = item.deal_id ? "deal_id" : "id";
      item.included = true;
      item.selected = true;
      item.selectedState = "checked";
      this.selectedItems[item[dealIdAttribute]] = item;
    },
    formatDeal(deal) {
      const currency_symbol = T1.Utils.getCurrencySymbol(deal.price.currency_code);
      const price_display = T1Currencies.formatNumber(deal.price.currency_code, deal.price.value);
      const supply_source = this.supplyCollection._byId[deal.supply_source_id];
      const supply_source_name = supply_source ? supply_source.attributes.name : "";
      return {
        name: deal.name,
        id: deal.id,
        supply_source_name: supply_source_name,
        price_display: price_display,
        currency_symbol: currency_symbol,
        price_type: deal.price_type,
        status: deal.status
      };
    },
    sort(val) {
      var grid = this.dealGrid;
      var field = val.originalEvent.detail.field;
      var sortOrder = val.originalEvent.detail.val;
      var gridData = SortDeals.sortDeals(grid.data, field, sortOrder);
      var sortList = ["data", 0, gridData.length].concat(gridData);
      grid.splice(...sortList);
    },
    loadDealSearch: _.debounce(function (val) {
      const grid = this.dealGrid;
      let searchValue;
      const minSearchReq = 2;
      if (this.hidden) {
        return;
      }
      if (typeof val === "string") {
        searchValue = val;
      } else {
        searchValue = val.originalEvent.detail.value;
      }
      grid.isLoading = true;
      if (searchValue === "" || searchValue === null) {
        this.$(".search-warning-deals").attr("hidden", "");
        this.$(".deal-group-search-tree-input").removeAttr("error");
        this.dealGrid.noResultsMessage = "No deals available.";
        T1UtilsAsync.linkPromises([this.fetchDeals(false)]).then(() => {
          this.dealGrid.sortField = "none";
          this.isFirstDealCall = true;
          this.parseData();
        });
      } else if (searchValue.length > 0 && searchValue.length < minSearchReq && searchValue !== " ") {
        this.$(".search-warning-deals").removeAttr("hidden");
        this.$(".deal-group-search-tree-input").attr("error", "");
        grid.isLoading = false;
      } else if (searchValue.trim().length === 0) {
        grid.isLoading = false;
      } else {
        this.$(".search-warning-deals").attr("hidden", "");
        this.$(".deal-group-search-tree-input").removeAttr("error");
        this.dealGrid.noResultsMessage = "No results found.";
        T1UtilsAsync.linkPromises([this.fetchDeals(searchValue)]).then(() => {
          this.dealGrid.sortField = "none";
          this.isFirstDealCall = true;
          this.parseData();
        });
      }
    }, searchDebounce),
    render() {
      const deferred = T1UtilsAsync.makeDeferral();
      Polymer.dom(this.el[0]).innerHTML = template;
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise;
    }
  });
  return binStrandGrid;
});
