define('modules/reporting/campaigns/geoPerformance/views/controls',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/controls.html", "T1Layout", "T1Animate"], function ($, T1, T1View, T1Permissions, template, T1Layout, T1Animate) {
  "use strict";

  var ControlsView = T1View.extend({
    selectedColumns: null,
    template: template,
    events: {
      "click .columns": "showColumnSelector",
      "click .toggle-btn": "toggleChart",
      "keyup input": "searchStrategyInGrid"
    },
    dataEvents: {
      reportModel: {
        "change:columnKeys": "updateMetadataSortKeys",
        "change:sortKeyInfo": "resortCollection",
        "change:campaignBenchmarkOn": function () {
          T1.EventHub.publish("preference:change", {
            key: "campaignBenchmarkOn",
            value: String(this.reportModel.get("campaignBenchmarkOn"))
          });
        }
      }
    },
    initialize(args) {
      const {
        collection: collection,
        models: models,
        type: type
      } = args;
      const {
        campaign: campaign,
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel
      } = models;
      Object.assign(this, {
        campaign: campaign,
        cfgs: args,
        collection: collection,
        mainMeta: mainMeta,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel,
        timeSeries: "summary",
        type: type
      });
    },
    load() {
      const {
        mainMeta: mainMeta,
        reportMeta: reportMeta,
        reportModel: reportModel
      } = this;
      const reportMetaObj = $.extend({}, reportMeta.toJSON());
      const reportModelObj = $.extend({}, reportModel.toJSON());
      const preferences = reportModelObj.preferences;
      const focus = reportModelObj.focus || "all";
      const getFocusKey = reportModel.makeFocusKeyFunc(focus);
      const selectedColumns = preferences.get(getFocusKey("selectedColumns"));
      const sortKeyInfo = preferences.get(getFocusKey("sortKeyInfo"));
      const campaignBenchmarkOn = preferences.get("campaignBenchmarkOn");
      const campaignGoalType = this.campaign.get("goal_type");
      reportModel.setColumnKeys(selectedColumns === undefined || selectedColumns === "" ? reportModelObj.defaultColumnKeys : selectedColumns.split(","));
      reportModel.setSortKeyInfo(sortKeyInfo === undefined || sortKeyInfo === "" ? reportMeta.get("defaultSortForGoalType")[campaignGoalType] || "" : sortKeyInfo);
      reportModel.setCampaignBenchmarkState(campaignBenchmarkOn === undefined || campaignBenchmarkOn === "" ? true : campaignBenchmarkOn === "true");
      this.reportMetaObj = reportMetaObj;
      this.reportModelObj = reportModelObj;
      this.mainMeta = mainMeta;
      this.getFocusKey = getFocusKey;
      this.updateMetadataSortKeys();
      this.chartShown = this.mainModel.get("chartShown") === "true";
      this.render().then(() => {
        this.chartCover = this.$(".chart-cover");
        this.reportControls = this.$(".report-controls");
      });
    },
    unload() {
      this.chartCover = null;
      this.reportControls = null;
    },
    prepareMultiSortableData() {
      const {
        reportMeta: reportMeta,
        reportModelObj: reportModelObj,
        selectedColumns: selectedColumns
      } = this;
      const allColumnsObj = $.extend({}, reportMeta.additionalData.columns[reportModelObj.focus]);
      const selectedKeys = selectedColumns ? selectedColumns.split(",") : reportModelObj.defaultColumns;
      Object.entries(allColumnsObj).forEach(([key, val]) => {
        if (val.onlyViewableInCampaignCurrency) {
          delete allColumnsObj[key];
        }
        if (val.onlyInPerformance) {
          delete allColumnsObj[key];
        }
        if (val.featureFlag && !T1Permissions.check("feature", val.featureFlag)) {
          delete allColumnsObj[key];
        }
        if (val.section && !val.section.includes(reportModelObj.key)) {
          delete allColumnsObj[key];
        }
      });
      const selectedCols = selectedKeys.reduce((arr, item) => {
        const key = selectedColumns ? item : item.value;
        if (allColumnsObj[key]) {
          delete allColumnsObj[key];
          arr.push({
            text: reportMeta.getFriendlyName("metrics", key),
            value: key
          });
        }
        return arr;
      }, []);
      const availableKeys = Object.keys(allColumnsObj);
      const availableCols = availableKeys.map(key => ({
        text: reportMeta.getFriendlyName("metrics", key),
        value: key
      }));
      this.availableColumnsArray = availableCols;
      this.selectedColumnsArray = selectedCols;
    },
    initLayout() {
      this.layout = new T1Layout({
        el: () => this.$(".column-selector-dialog"),
        template: '<div class="column-selector"></div>',
        layout: {
          ".column-selector": [{
            module: "reporting/campaigns/geoPerformance",
            viewType: "columnSelector",
            options: {
              listA: this.availableColumnsArray,
              listB: this.selectedColumnsArray,
              onCloseCallback: obj => {
                if (obj) {
                  this.saveColumns(obj);
                }
                this.onFormClose();
              }
            }
          }]
        }
      });
    },
    saveColumns({
      listB: listB
    }) {
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("selectedColumns"),
        value: listB.toString()
      });
      this.reportModel.setColumnKeys(listB);
    },
    updateMetadataSortKeys() {
      const reportModelObj = $.extend({}, this.reportModel.toJSON());
      const {
        columnKeys: columnKeys,
        sortKeyInfo: sortKeyInfo
      } = reportModelObj;
      const sortKeyArray = sortKeyInfo ? sortKeyInfo.split(",") : [];
      const sortKeyIndexInColumnKeys = sortKeyArray.length ? columnKeys.indexOf(sortKeyArray[0]) : -1;
      if (sortKeyIndexInColumnKeys === -1) {
        this.reportModel.setSortKeyInfo(!columnKeys.length ? "Strategy_Name,1,1" : `${columnKeys[0]},-1`);
      }
    },
    resortCollection(reportModel) {
      const sortKeyInfo = reportModel.get("sortKeyInfo");
      if (sortKeyInfo && sortKeyInfo.length) {
        const [sortKey, order, name] = sortKeyInfo.split(",");
        this.collection.sortByKey(sortKey, parseInt(order, 10), this.timeSeries, false, parseInt(name, 10));
      }
      T1.EventHub.publish("preference:change", {
        key: this.getFocusKey("sortKeyInfo"),
        value: sortKeyInfo
      });
    },
    onFormClose() {
      delete this.layout.layout[".column-selector-dialog"];
    },
    showColumnSelector(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.selectedColumns = this.reportModel.get("preferences").get(this.getFocusKey("selectedColumns"));
      this.prepareMultiSortableData();
      this.initLayout();
      this.layout.load();
    },
    searchStrategyInGrid(event) {
      let inputText;
      const enterKey = 13;
      switch (event.keyCode) {
        case enterKey:
          inputText = $(event.currentTarget).val();
          event.preventDefault();
          T1.EventHub.publish(`reporting_${this.timeSeries}:search`, {
            term: inputText
          });
          break;
        case "default":
      }
    },
    toggleChart() {
      const chartShown = this.chartShown;
      const publish = T1.EventHub.publish;
      const renderDelay = 140;
      T1Animate({
        duration: 400,
        easing: "ease",
        options: {
          marginTop: chartShown ? "-4px" : "350px"
        },
        target: this.reportControls,
        complete: () => {
          publish("chart.toggleEnd");
          publish("track:event", {
            action: "Toggle chart",
            label: this.chartShown ? "show" : "hide"
          });
        }
      });
      setTimeout(() => {
        this.chartCover.toggle().removeClass("no-display");
        this.renderPartial(".toggle-btn");
      }, renderDelay);
      this.chartShown = !this.chartShown;
      publish("chart.toggle", this.chartShown);
    },
    serialize() {
      return {
        btnLabel: this.chartShown ? "hide chart" : "show chart",
        btnState: this.chartShown ? "hide" : "show",
        chartHidden: !this.chartShown
      };
    }
  });
  return ControlsView;
});
