define('modules/creatives/video/control/VastValidator',["jQuery", "Backbone", "T1", "T1Comm"], function ($, _, T1, T1Comm) {
  return {
    validateVast: function (vastXML, options) {
      options = options || {};
      var processAjaxResponse = function (resp) {
        return {
          jsonData: $.extend(resp, {
            status: "ok"
          }),
          statusCode: "ok"
        };
      };
      var opts = {
        sourceURL: T1.VIDEO_API_BASE + "creatives/validateVAST/",
        onSuccess: options.onSuccess || $.noop,
        dataType: "json",
        onError: options.onError || $.noop,
        processAjaxResponse: processAjaxResponse,
        data: vastXML
      };
      opts = $.extend(opts, options);
      return T1Comm.post(opts);
    }
  };
});
