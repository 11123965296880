define('T1Sortable',["Underscore", "jQuery", "T1"], function (_, $, T1) {
  T1.Sortable = {
    extendCollection: function (collection, config) {
      var sortConfig = {};
      var fetchOptions = {};
      var defaultConfig, fetchOptionsSortConfig, firstSortBy, selected;
      function updateSortConfig(actualSortConfig, sortByConfig, field, order) {
        var sortIndex = _.indexOf(sortByConfig, field);
        var firstSortByIndex = _.indexOf(sortByConfig, firstSortBy);
        var val = sortConfig.fields[field].actual;
        var sort_by = order === "descending" ? `-${val}` : val;
        if (sortIndex === -1) {
          actualSortConfig.splice(firstSortByIndex + 1, 0, sort_by);
        } else {
          actualSortConfig[sortIndex] = sort_by;
        }
        return actualSortConfig.join(",");
      }
      if (collection === undefined) {
        throw new Error("Paramerer Collection undefined");
      } else if (config === undefined) {
        throw new Error("Paramerer Config undefined");
      } else {
        if (!config.fields) {
          throw new Error("Config object needs fields property");
        }
      }
      sortConfig = $.extend(sortConfig, config);
      defaultConfig = config.default || null;
      if (defaultConfig) {
        selected = config.fields[defaultConfig.sort_by];
        firstSortBy = defaultConfig.firstSortBy;
        const sortOperator = defaultConfig.order_by === "descending" ? "-" : "";
        fetchOptions.sort_by = `${sortOperator}${selected.actual},id`;
        fetchOptions.order_by = defaultConfig.order_by;
        if (defaultConfig.firstSortBy) {
          fetchOptionsSortConfig = fetchOptions.sort_by.replace(/-/g, "").split(",");
          fetchOptions.sort_by = updateSortConfig(fetchOptions.sort_by.split(","), fetchOptionsSortConfig, firstSortBy, defaultConfig.firstSortByOrder);
        }
      }
      return collection.extend({
        initialize: function (models, options) {
          this.fetchOptions = $.extend(this.fetchOptions || {}, fetchOptions);
          collection.prototype.initialize.call(this, models, options);
        },
        removeSort: function (field) {
          var actualSortConfig = this.fetchOptions.sort_by.split(",");
          var sortByConfig = this.fetchOptions.sort_by.replace(/-/g, "").split(",");
          var sortIndex;
          sortIndex = _.indexOf(sortByConfig, field);
          if (sortIndex !== -1) {
            actualSortConfig.splice(sortIndex, 1);
          }
          this.fetchOptions.sort_by = actualSortConfig.join(",");
          this.page = 0;
        },
        sortBy: function (field, order, updateOrderBy) {
          var match = sortConfig.fields[field];
          var actualSortConfig = this.fetchOptions.sort_by.split(",");
          var sortByConfig = this.fetchOptions.sort_by.replace(/-/g, "").split(",");
          order = order || this.fetchOptions.order_by;
          if (match) {
            if (updateOrderBy) {
              this.fetchOptions.order_by = order;
            }
            this.fetchOptions.sort_by = updateSortConfig(actualSortConfig, sortByConfig, field, order);
            this.page = 0;
          }
        },
        getSortConfiguration: function () {
          var result = {
            selected: selected.display,
            selectedValue: selected.actual,
            order_by: this.fetchOptions.order_by,
            fields: []
          };
          var fields = result.fields;
          var sortByConfig = this.fetchOptions.sort_by;
          $.each(sortConfig.fields, function (key, value) {
            fields[fields.length] = {
              name: value.display,
              value: value.actual,
              state: sortByConfig.indexOf(value.actual) > -1 ? "selected" : ""
            };
          });
          return result;
        }
      });
    }
  };
  return T1.Sortable;
});
