define('modules/vendor/views/popup',["jQuery", "T1", "T1FilterList"], function ($, T1, T1FilterList) {
  "use strict";

  var Popup = T1FilterList.extend({
    partials: {
      item_all: null,
      item_all_search_results: null
    },
    events: {
      "open .w-FilterList": "open"
    },
    initialize: function (args) {
      this.collection = args.collection;
      this.autoVendors = args.autoVendors;
    },
    search: function () {
      var self = this;
      var term = this.searchInput.val();
      var filteredItemsLength;
      if (term.length > 0) {
        this.collection.isPaginated = false;
        this.collection.search.set({
          fetchRequired: true,
          success: function (e) {
            var $item;
            var id;
            var model;
            self.setItems(e.toJSON());
            if (self.items.length > 0) {
              self.updateList();
              filteredItemsLength = self.filteredItems ? self.filteredItems.length : 0;
              for (var i = 0; i < filteredItemsLength; i++) {
                $item = $(self.filteredItems[i]);
                id = $item.attr("data-id");
                model = self.manualVendorsColl.get(id);
                for (var j = 0; j < self.autoVendors.length; j++) {
                  if (self.autoVendors[j].get("name") === $($item[0]).find("span").text()) {
                    $item.addClass("disabled");
                    $item.find("input").attr("disabled", "disabled");
                  }
                }
                if (model) {
                  $item.find("input").prop("checked", true);
                  $item.addClass("selected");
                }
              }
            } else {
              self.emptyList();
              self.displayNoResults();
            }
          },
          params: [{
            term: term,
            searchField: "name|id"
          }, {
            term: "1",
            searchField: "is_eligible"
          }]
        });
      } else {
        this.emptyList();
      }
    },
    open: function (e, args) {
      var self = this;
      var documentEvents = "mousedown.t1filterlist keyup.t1filterlist";
      var $document = $(document);
      this.manualVendorsColl = args.collection;
      $document.mousedown();
      this.el.hide().fadeIn(200, this.onOpen);
      this.inputFocus();
      if (!this.filteredItems) {
        this.updateList();
      } else {
        this.list.scrollTop(0);
        this.list.perfectScrollbar("update");
      }
      if (!this.loadDataOnSearch && !this.items) {
        this.showLoadingMsg();
      }
      $document.one(documentEvents, function (event) {
        if (event.keyCode === 9 || event.keyCode === 27 || !event.keyCode) {
          self.close(event);
        }
      });
    },
    serialize: function () {
      return {
        items: this.collection.toJSON()
      };
    }
  });
  return Popup;
});
