define('modules/targeting/dayPart/views/item',["jQuery", "T1", "T1View", "text!../templates/item.html", "T1Selectbox"], function ($, T1, T1View, template) {
  "use strict";

  const halfDay = 12;
  var DayPartItem = T1View.extend({
    template: template,
    events: {
      "click .remove-day-part": "removeDayPart",
      "click .select-checkbox-all-day .checkbox": "toggleAllDay",
      "click .select-days label": "toggleSelectDays",
      "change .select-interval": "selectInterval",
      "change .select-relative-to input": "selectRelativeTo",
      "change .select-start-hour .select-hour": "setHour",
      "change .select-start-hour .select-am-pm": "setHour",
      "change .select-end-hour .select-hour": "setHour",
      "change .select-end-hour .select-am-pm": "setHour",
      "click .select-days .title": "removeDayPartErrorTooltip"
    },
    removeDayPartErrorTooltip: function () {
      $(".w-DayPartError").remove();
    },
    initialize: function ({
      controller: controller
    }) {
      this.controller = controller;
      this.isAllDayUnchecked = false;
    },
    removeDayPart: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.controller.removeItem(this);
    },
    toggleAllDay: function () {
      var dayPart = this.model;
      var startHour = dayPart.get("start_hour");
      var endHour = dayPart.get("end_hour");
      if (startHour !== "0" || endHour !== "23") {
        dayPart.set({
          start_hour: "0",
          end_hour: "23"
        });
        this.load();
      }
    },
    toggleSelectDays: function (event) {
      var $target = $(event.currentTarget);
      var days = this.model.get("days");
      var day = $target.data("day");
      var allDays = "MTWRFSU";
      var dayLetter = {
        monday: "M",
        tuesday: "T",
        wednesday: "W",
        thursday: "R",
        friday: "F",
        saturday: "S",
        sunday: "U"
      }[day];
      var newDays = "";
      function wasAlreadySelected(n) {
        return days.includes(allDays[n]);
      }
      function isDayLetter(n) {
        return allDays[n] === dayLetter;
      }
      event.stopPropagation();
      const isRemovingDay = days.includes(dayLetter);
      const isAddingDay = !isRemovingDay;
      for (let i = 0; i < allDays.length; i++) {
        if (isAddingDay && (wasAlreadySelected(i) || isDayLetter(i)) || isRemovingDay && wasAlreadySelected(i) && !isDayLetter(i)) {
          newDays += allDays[i];
        }
      }
      this.model.set({
        days: newDays
      });
      this.load();
    },
    selectInterval: function (e) {
      var interval = e.currentTarget.value;
      if (interval !== "Custom") {
        this.model.set({
          days: {
            Everyday: "MTWRFSU",
            Weekdays: "MTWRF",
            Weekends: "SU"
          }[interval]
        });
        this.load();
      }
    },
    selectRelativeTo: function (e) {
      this.model.set({
        user_time: e.currentTarget.value === "end-user" ? "1" : "0"
      });
    },
    setHour: function (e) {
      let newValue;
      var $target = $(e.currentTarget);
      var changingAttr = $target.data("startorend");
      const oldValue = Number(this.model.get(`${changingAttr}_hour`));
      var hourOrAmPm = $target.data("hourorampm");
      var givenVal = Number(e.currentTarget.value);
      if (hourOrAmPm === "hour") {
        newValue = oldValue >= halfDay ? givenVal + halfDay : givenVal;
      } else if (hourOrAmPm === "amPm") {
        newValue = oldValue < halfDay ? oldValue + halfDay : oldValue - halfDay;
      }
      newValue = newValue.toString();
      if (changingAttr === "start") {
        this.model.set({
          start_hour: newValue
        });
      } else if (changingAttr === "end") {
        this.model.set({
          end_hour: newValue
        });
      }
      this.load();
    },
    load: function () {
      this.render().then(() => {
        this.$(".select-days").buttonset();
        T1.Selectbox(this.$(".select-input"));
      });
    },
    serialize: function () {
      var result = {
        intervalFields: [{
          value: "Select One",
          text: "Select One"
        }, {
          value: "Weekdays",
          text: "Weekdays"
        }, {
          value: "Weekends",
          text: "Weekends"
        }, {
          value: "Everyday",
          text: "Everyday"
        }, {
          value: "Custom",
          text: "Custom"
        }],
        startHourFields: [{
          value: 0,
          text: "12:00"
        }, {
          value: 1,
          text: "01:00"
        }, {
          value: 2,
          text: "02:00"
        }, {
          value: 3,
          text: "03:00"
        }, {
          value: 4,
          text: "04:00"
        }, {
          value: 5,
          text: "05:00"
        }, {
          value: 6,
          text: "06:00"
        }, {
          value: 7,
          text: "07:00"
        }, {
          value: 8,
          text: "08:00"
        }, {
          value: 9,
          text: "09:00"
        }, {
          value: 10,
          text: "10:00"
        }, {
          value: 11,
          text: "11:00"
        }],
        startAmOrPmFields: [{
          value: "AM",
          text: "AM"
        }, {
          value: "PM",
          text: "PM"
        }],
        endHourFields: [{
          value: 0,
          text: "12:59"
        }, {
          value: 1,
          text: "01:59"
        }, {
          value: 2,
          text: "02:59"
        }, {
          value: 3,
          text: "03:59"
        }, {
          value: 4,
          text: "04:59"
        }, {
          value: 5,
          text: "05:59"
        }, {
          value: 6,
          text: "06:59"
        }, {
          value: 7,
          text: "07:59"
        }, {
          value: 8,
          text: "08:59"
        }, {
          value: 9,
          text: "09:59"
        }, {
          value: 10,
          text: "10:59"
        }, {
          value: 11,
          text: "11:59"
        }],
        endAmOrPmFields: [{
          value: "AM",
          text: "AM"
        }, {
          value: "PM",
          text: "PM"
        }]
      };
      var days = this.model.get("days");
      const startHour = parseInt(this.model.get("start_hour"), 10);
      const endHour = parseInt(this.model.get("end_hour"), 10);
      const userTime = parseInt(this.model.get("user_time"), 10);
      const fullDay = 23;
      function getInterval() {
        switch (days) {
          case "":
            return "Select One";
          case "MTWRFSU":
            return "Everyday";
          case "MTWRF":
            return "Weekdays";
          case "SU":
            return "Weekends";
          default:
            return "Custom";
        }
      }
      result.selectedInterval = getInterval();
      result.selectedStartHour = startHour < halfDay ? startHour : startHour - halfDay;
      result.selectedStartAmOrPm = startHour < halfDay ? "AM" : "PM";
      result.selectedEndHour = endHour < halfDay ? endHour : endHour - halfDay;
      result.selectedEndAmOrPm = endHour < halfDay ? "AM" : "PM";
      result.sun = days.includes("U");
      result.mon = days.includes("M");
      result.tue = days.includes("T");
      result.wed = days.includes("W");
      result.thu = days.includes("R");
      result.fri = days.includes("F");
      result.sat = days.includes("S");
      result.isAllDay = startHour === 0 && endHour === fullDay;
      result.isEndUser = userTime === 1;
      result.isTimezone = userTime === 0;
      if (this.isAllDayUnchecked) {
        result.isAllDay = false;
        this.isAllDayUnchecked = false;
      }
      result.cid = this.model.cid;
      return result;
    }
  });
  return T1.Form.ViewUtils.extend(DayPartItem, ["serialize"]);
});
