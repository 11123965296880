define('modules/strategy/creatives/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!modules/strategy/creatives/templates/bin_title.html", "text!../templates/bin_item.html", "text!../templates/bin_header.html", "text!../templates/bin_footer.html"], function ($, _, T1, BinsView, bin_title, bin_item, bin_header, bin_footer) {
  "use strict";

  const isWeightingEnabled = weightingType => {
    weightingType = weightingType.toLowerCase();
    return weightingType !== "no weighting" && weightingType !== "none";
  };
  function createIdLookupFromElements(elems) {
    const elemsMapById = {};
    for (let i = 0, length = elems.length; i < length; i++) {
      const currentElem = elems[i];
      elemsMapById[currentElem.dataset.id] = currentElem;
    }
    return elemsMapById;
  }
  return BinsView.extend({
    type: "concepts",
    configsOverride: true,
    configs: {
      include: {
        showAndOr: false,
        showWarning: true,
        warningMessage: "You must select at least one creative concept.",
        showBinKindIcon: false,
        count: 0,
        use_or: true,
        hasFooter: true
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_item: bin_item,
      bin_footer: bin_footer
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDraggable: false,
      isDroppable: true,
      onDropEvent: "bins:droppedConcept"
    },
    creativesCollections: {},
    events: {
      "keyup .weighting-input": "onWeightingChange",
      "changed .weighting-type": "onWeightingTypeChange"
    },
    dataEvents: {
      sharedCollection: {
        reset: "render"
      }
    },
    eventhubEvents: {
      "strategyConcepts:add-remove": "onConceptAddRemove",
      "strategyConcepts:save": "onConceptSave"
    },
    initialize: function ({
      sharedCollection: sharedCollection,
      strategy: strategy
    }) {
      this.sharedCollection = sharedCollection;
      this.strategy = strategy;
      this.hasInitiallyLoaded = false;
    },
    load: function () {
      this.render().then(() => {
        const delay = 250;
        this.el.trigger("binsContent:loaded");
        setTimeout(() => {
          const weightingType = this.getInitialWeightingType();
          this.updateWeightingTypeOnConcepts(weightingType);
          this.updateWeightingTypeDisplayed(weightingType);
          this.toggleWeightingElemsByWeightingType(weightingType);
          this.mapWeightingFromInputsToModels();
          this.refreshTotalWeightingDisplayed();
          T1.Form.Masks.attach($("input"));
          this.hasInitiallyLoaded = true;
        }, 0);
        setTimeout(() => {
          T1.EventHub.publish("resetIsFormElementsChanged:strategy-creatives");
        }, delay);
      });
    },
    onConceptAddRemove: function (args) {
      if (args && args.wasAdded && args.item) {
        const weightingType = this.el.find(".weighting-type")[0].value;
        const conceptModel = this.sharedCollection.get(args.item.id);
        const isWeightingTurnedOn = isWeightingEnabled(weightingType);
        conceptModel.set({
          weighting: weightingType.toUpperCase()
        });
        if (isWeightingTurnedOn && this.sharedCollection.length === 1) {
          this.showWeightingElems();
        }
      }
      this.refreshTotalWeightingDisplayed();
    },
    isProgrammaticStrategy: function () {
      const isPgStrategy = this.strategy.get("campaign").get("is_programmatic_guaranteed");
      if (isPgStrategy === "1") {
        const $weightingElem = this.$(".weighting-status");
        $weightingElem.attr("disabled", "disabled");
      }
    },
    onConceptSave: function () {
      this.mapWeightingFromInputsToModels();
    },
    onWeightingChange: function () {
      this.mapWeightingFromInputsToModels();
      this.refreshTotalWeightingDisplayed();
    },
    onWeightingTypeChange: function (event) {
      const newWeightingType = event.currentTarget.value.toUpperCase();
      this.toggleWeightingElemsByWeightingType(newWeightingType);
      this.updateWeightingTypeOnConcepts(newWeightingType);
      this.refreshTotalWeightingDisplayed();
    },
    getInitialWeightingType: function () {
      let currentWeighting;
      const currentConcepts = this.sharedCollection.toJSON();
      if (currentConcepts.length) {
        const firstConcept = currentConcepts[0];
        if (firstConcept.strategy_concept) {
          const savedConcept = this.sharedCollection.getStrategyConcept(this.strategy.id, firstConcept.strategy_concept);
          currentWeighting = savedConcept.weighting;
        } else if (firstConcept.weighting) {
          currentWeighting = firstConcept.weighting;
        }
      }
      return T1.Utils.capitalize(currentWeighting || "no weighting");
    },
    updateWeightingTypeDisplayed: function (newWeightingType) {
      const weightingTypeDropdown = this.el.find(".weighting-type")[0];
      weightingTypeDropdown.value = newWeightingType;
      return weightingTypeDropdown;
    },
    updateWeightingTypeOnConcepts: function (weighting) {
      this.sharedCollection.each(concept => {
        concept.set({
          weighting: weighting.toUpperCase()
        });
      });
    },
    toggleWeightingElemsByWeightingType: function (newWeightingType) {
      if (!isWeightingEnabled(newWeightingType)) {
        this.hideWeightingElems();
      } else {
        this.showWeightingElems();
        this.updateBins();
      }
    },
    hideWeightingElems: function () {
      this.el.find(".weighting-percentage").addClass("no-weighting");
      this.noWeighting = true;
    },
    showWeightingElems: function () {
      this.el.find(".weighting-percentage").removeClass("no-weighting");
      this.noWeighting = false;
      this.isProgrammaticStrategy();
    },
    mapWeightingFromInputsToModels: function () {
      const inputs = this.el.find(".weighting-input");
      const currentConcepts = this.sharedCollection.toJSON();
      const inputMapById = createIdLookupFromElements(inputs);
      const weightingType = this.$(".weighting-type").val();
      currentConcepts.forEach(concept => {
        const model = this.sharedCollection.get(concept.id);
        const strategyConcept = concept.strategy_concept;
        if (inputs.length) {
          const element = inputMapById[concept.id];
          let weighting = element.value || 0;
          const twoDigits = 2;
          if (weighting === "0.00" && strategyConcept) {
            if (strategyConcept.length > 1) {
              const filteredConcept = strategyConcept.filter(function (sConcept) {
                return sConcept.concept_id === concept.id && sConcept.weighting === weightingType.toUpperCase();
              });
              if (filteredConcept.length) {
                weighting = filteredConcept.length > 1 ? filteredConcept[filteredConcept.length - 1].percent : filteredConcept[0].percent;
              }
            } else {
              weighting = strategyConcept.percent;
            }
          }
          model.set({
            percent: parseFloat(weighting).toFixed(twoDigits)
          });
        } else {
          model.unset("percent");
          model.set({
            weighting: "NONE"
          });
        }
      });
    },
    refreshTotalWeightingDisplayed: function () {
      const weightingType = this.$(".weighting-type").val();
      const isWeightingTurnedOn = isWeightingEnabled(weightingType);
      if (isWeightingTurnedOn && this.sharedCollection.length) {
        const validationResult = this.sharedCollection.validateStrategyConcepts(weightingType, this.strategy.id);
        const weightingTotal = validationResult.total || "0.00";
        this.$(".total-weight").text(`${weightingTotal}%`);
        this.$(".footer-title").text("Total Weight");
      } else {
        this.$(".total-weight").text("");
        this.$(".footer-title").text("");
      }
      this.isProgrammaticStrategy();
    },
    mapItems: function () {
      const currentConcepts = this.sharedCollection.toJSON();
      return currentConcepts.map(concept => {
        if (!this.hasInitiallyLoaded && concept.strategy_concept) {
          const savedConcept = this.sharedCollection.getStrategyConcept(this.strategy.id, concept.strategy_concept);
          concept.percent = savedConcept.percent;
        }
        concept.percent = concept.percent || "0.00";
        concept.noWeighting = this.noWeighting;
        concept.isInactive = concept.status === "0";
        return concept;
      });
    },
    removeItem: function (event) {
      BinsView.prototype.removeItem.call(this, event);
      T1.EventHub.publish("strategyConcepts:add-remove");
    },
    updateBins: function (model, binRemoveItemsFrom) {
      const currentConcepts = this.sharedCollection.toJSON();
      if (currentConcepts.length) {
        model = this.sharedCollection.get(currentConcepts[0].id);
        BinsView.prototype.updateBins.call(this, model);
      } else {
        BinsView.prototype.updateBins.call(this, model, binRemoveItemsFrom);
      }
    }
  });
});
