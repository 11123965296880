define('modules/reporting/campaigns/views/selectAttribution',["jQuery", "T1", "T1View", "T1Permissions", "text!../templates/selectAttribution.html"], function ($, T1, T1View, T1Permissions, template) {
  "use strict";

  const DOCUMENT_EVENTS = "mousedown.attributionUI keyup.attributionUI";
  const T1_PUBLISH = T1.EventHub.publish;
  const ANIM_TIME = 200;
  const ESCAPE_KEY = 27;
  const TAB_KEY = 9;
  return T1View.extend({
    attributionGroup: "T1 Last Touch",
    template: template,
    list: [{
      id: "MediaMath Last Touch",
      text: "T1 Last Touch"
    }, {
      id: "Facebook Inc.",
      text: "Facebook Inc."
    }],
    events: {
      "mousedown .head-label": "toggle",
      "mousedown .w-Attribution": "stopBubbling",
      "mouseout li": "itemOut",
      "mouseover li": "itemOver",
      "mouseup .attribution-list li": "itemClick"
    },
    initialize: function (args) {
      const savedPref = args.models.reportModel.get("preferences").get("attributionGroup");
      this.attributionGroup = savedPref || this.attributionGroup;
    },
    load: function () {
      this.render().then(() => {
        this.$document = $(document);
        this.$wBody = this.$(".w-body");
        this.$attributionLabel = this.$(".head-label");
      });
    },
    unload: function () {
      this.$document.off(DOCUMENT_EVENTS);
      this.$document = null;
      this.$wBody = null;
      this.$attributionLabel = null;
      this.attributionUIOpen = null;
    },
    stopBubbling: function (evt) {
      evt.stopPropagation();
    },
    toggle: function (evt) {
      this.stopBubbling(evt);
      if (this.attributionUIOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open: function () {
      this.$document.mousedown();
      this.$wBody.removeClass("no-display").fadeIn(ANIM_TIME);
      this.$attributionLabel.addClass("white-bg");
      this.attributionUIOpen = true;
      this.$document.one(DOCUMENT_EVENTS, evt => {
        if (evt.keyCode === TAB_KEY || evt.keyCode === ESCAPE_KEY || !evt.keyCode) {
          this.close();
        }
      });
    },
    close: function () {
      this.$attributionLabel.removeClass("white-bg");
      this.$wBody.hide();
      this.attributionUIOpen = false;
      this.$document.off(DOCUMENT_EVENTS);
    },
    itemOver: function (evt) {
      $(evt.currentTarget).addClass("highlighted");
    },
    itemOut: function (evt) {
      $(evt.currentTarget).removeClass("highlighted");
    },
    itemClick: function (evt) {
      const selectedId = $(evt.currentTarget).data("id");
      if (this.attributionGroup !== selectedId) {
        this.attributionGroup = selectedId;
        T1_PUBLISH("select:attribution", this.attributionGroup);
        T1_PUBLISH("track:event", {
          action: "Choose Attribution Group",
          label: this.attributionGroup
        });
        this.reload();
      }
      this.close();
    },
    updateSelectedItem: function () {
      this.list.forEach(item => {
        item.selected = item.id === this.attributionGroup;
      });
    },
    getLastTouchDisplayName: function (attribution) {
      return attribution === "MediaMath Last Touch" ? "T1 Last Touch" : attribution;
    },
    serialize: function () {
      this.updateSelectedItem();
      return {
        attributionGroup: this.getLastTouchDisplayName(this.attributionGroup),
        attributionList: this.list
      };
    }
  });
});
