define('modules/targeting/location/views/bins',["jQuery", "Underscore", "T1", "T1BinsView", "text!../templates/overall_header.html", "text!../templates/bin_group_header.html", "text!../templates/bin_title.html", "text!../templates/bin_group_item.html", "text!../templates/bin_footer.html", "text!../templates/bins.html", "modules/strategy/views/helpers/defaultLocationPersistenceHelper"], function ($, _, T1, BinsView, overall_header, bin_group_header, bin_title, bin_group_item, bin_footer, template, DefaultLocPersistHelper) {
  "use strict";

  const LocationBinsView = BinsView.extend({
    type: "Locations",
    template: template,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: true,
        count: 0,
        use_or: true
      },
      exclude: {
        hasFooter: true
      }
    },
    partials: {
      overall_header: overall_header,
      bin_group_name: bin_group_header,
      bin_title: bin_title,
      bin_group_item: bin_group_item,
      bin_footer: bin_footer
    },
    draggableConfigs: {
      isDraggable: true,
      isDroppable: true,
      onDropEvent: "bins:droppedLocation",
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    showHeaderWarning: false,
    locationCodes: {
      region: "REGN",
      dma: "DMAX",
      postalCode: "USZC"
    },
    initialize: function ({
      collection: collection,
      strategy: strategy,
      sharedCollection: sharedCollection,
      filterConfigs: filterConfigs,
      hyperLocalTargeting: hyperLocalTargeting,
      ipTargetingAttachments: ipTargetingAttachments,
      partialCollection: partialCollection,
      ipCodesInfo: ipCodesInfo
    }) {
      var filters = [];
      this.showBanner = strategy.get("campaign").get("is_programmatic_guaranteed") !== "1";
      this.collection = collection;
      this.strategy = strategy;
      this.sharedCollection = sharedCollection;
      this.filterConfigs = $.extend(true, {}, filterConfigs);
      this.filterConfigs.filterOptions.push({
        text: "Postal Codes",
        value: "USZC"
      }, {
        text: "IP List",
        value: "IPTA"
      });
      this.hyperLocalTargeting = hyperLocalTargeting;
      this.locationCodes.hyperlocal = "hyperlocal";
      this.hyperLocalCollection = this.hyperLocalTargeting.hyperLocalCollection;
      this.hyperLocalAttachments = this.hyperLocalTargeting.hyperLocalAttachments;
      this.nemoTargets = this.hyperLocalTargeting.nemoTargets;
      this.ipTargetingAttachments = ipTargetingAttachments;
      $.each(this.filterConfigs.filterOptions, function (i, option) {
        filters.push(option.value);
      });
      this.filters = filters;
      this.partialCollection = partialCollection;
      this.refreshDefaultLocations();
      this.updateAllTempCollections();
      this.ipTargetsFile = ipCodesInfo.filename;
      this.ipTargetsCount = ipCodesInfo.count;
      this.ipTargetsAction = ipCodesInfo.action;
      this.showURL = ipCodesInfo.showURL;
    },
    setGeofenceOperatorState: function () {
      var checkedOperators = this.el.find("strand-radio[checked]");
      _.each(checkedOperators, group => {
        var action = group.className.split("hyperlocal-")[1];
        this.hyperLocalCollection.operators[action] = $(group).attr("value");
      });
    },
    updateAllTempCollections: function () {
      for (const code in this.locationCodes) {
        if (T1.Utils.hasOwnProp(this.locationCodes, code)) {
          this.updateTempCollection(this.locationCodes[code]);
        }
      }
    },
    updateTempCollection: function (code) {
      const targetDimensions = this.strategy.targetDimensions.get("target_dimensions");
      const locationTargets = targetDimensions.models.filter(model => model.id === code);
      const origColl = locationTargets[0].get("target_value");
      const tempColl = {
        data: []
      };
      origColl.each(function (model) {
        tempColl.data.push(model.clone());
      });
      origColl.tempBadgesCollection = tempColl;
      origColl.tempBadgesCollection.strategyId = this.strategy.id;
    },
    refreshDefaultLocations: function () {
      var arrAll = [];
      const defaultLocationPersister = new DefaultLocPersistHelper();
      defaultLocationPersister.init();
      const defaultSettings = defaultLocationPersister.getSettings();
      const arrCategories = defaultSettings.include.split("|").concat(defaultSettings.exclude.split("|"));
      _.each(arrCategories, function (categoryText) {
        if (categoryText) {
          arrAll = arrAll.concat(categoryText.split(","));
        }
      });
      this.allDefaultLocations = arrAll;
    },
    eventhubEvents: {
      uploadzipcodes: function (data) {
        if (data) {
          this.removePostalCodes("USZC");
          this.zipcodesfile = data.filename;
          this.zipcodecount = data.count;
          this.zipcodeaction = data.action;
        }
        this.updateBins();
      },
      uploadIPTargets: function (data) {
        if (data) {
          this.ipTargetsFile = data.filename;
          this.ipTargetsCount = data.count;
          this.ipTargetsAction = data.action;
          this.showURL = data.showURL;
        }
        this.updateBins();
      },
      defaultLocationSaved: function () {
        this.refreshDefaultLocations();
        this.updateBins();
      },
      "location-targeting:updateTempColl": "updateAllTempCollections",
      "panel:attachmentsLoaded": "load",
      "panel:saveTargetValues": "setGeofenceOperatorState",
      "location:dataLoaded": function () {
        this.dataLoaded = true;
        this.updateBins();
      }
    },
    removePostalCodes: function (code) {
      var sharedCollection = this.sharedCollection;
      const postalCodes = sharedCollection.filter(model => model.get("dimension_code") === code);
      sharedCollection.remove(postalCodes, {
        silent: true
      });
    },
    removeItem: function (event) {
      var $item = $(event.currentTarget).closest(".item");
      var id = $item.data("id");
      var sharedCollection = this.sharedCollection;
      var targetValue = this.collection.get(id) || sharedCollection.get(id);
      event.preventDefault();
      event.stopPropagation();
      if (id === "placeholder") {
        this.removePostalCodes("USZC");
        delete this.zipcodecount;
        delete this.zipcodesfile;
        delete this.zipcodeaction;
        this.updateBins();
        T1.EventHub.publish("clearzipcodes", {
          fileName: this.zipcodesfile || "postalcodes.csv"
        });
      } else if (id === " ") {
        this.removePostalCodes("IPTA");
        delete this.ipTargetsFile;
        delete this.ipTargetsCount;
        delete this.ipTargetsAction;
        this.updateBins();
        T1.EventHub.publish("clearIPTargets", {
          fileName: this.ipTargetsFile || "ipList.csv"
        });
      } else {
        sharedCollection.remove(id);
        targetValue.unset("action");
      }
    },
    load: function () {
      this.dataLoaded = false;
      this.updateBins();
    },
    updateBins: function (model) {
      var self = this;
      var isHyperlocal = model ? model.get("dimension_code") === "hyperlocal" : null;
      var isIPTargeting = model ? model.get("dimension_code") === "IPTA" : null;
      var binsArry = ["include", "exclude"];
      function updateBins(data) {
        self.updateDataBind(data, self.el, {
          doNotEscape: true
        });
      }
      for (let i = 0; i < binsArry.length; i++) {
        this.updateAction = binsArry[i];
        this.renderPartial(`.${this.updateAction}-bin .content-container`, undefined, "html", ".content-container");
      }
      if (this.sharedCollection && this.showBanner) {
        const fileNames = this.sharedCollection.models.filter(obj => obj.get("name"));
        const hasFileNames = fileNames.length > 0;
        const hasIPtargets = this.ipTargetsCount > 0;
        this.showHeaderWarning = !(hasFileNames || hasIPtargets || this.zipcodesfile);
      }
      if (this.dataLoaded) {
        this.renderPartial(`.w-Bins .overall-header`, undefined, "html", ".overall-header");
      }
      if (this.ipTargetingAttachments && this.ipTargetingAttachments.length && isIPTargeting) {
        this.ipTargetsFile = "ipList.csv";
        this.ipTargetsCount = this.ipTargetingAttachments.count;
        this.ipTargetsAction = this.ipTargetingAttachments.models[0].get("restriction");
      }
      this.updateAction = null;
      if (isHyperlocal) {
        const action = model.get("action");
        const geofences = this.hyperLocalTargeting.getAddedGeofences({
          strategy: this.strategy,
          action: action
        });
        if (!geofences.length && !this.hyperLocalCollection.operators[action]) {
          this.hyperLocalCollection.operators[action] = "or";
        }
      }
      updateBins({
        "geofence-cost-cpm": this.hyperLocalTargeting.getGeofenceCostDisplay(this)
      });
      return true;
    },
    unload: function () {
      delete this.zipcodecount;
      delete this.zipcodesfile;
      delete this.zipcodeaction;
      delete this.ipTargetsFile;
      delete this.ipTargetsCount;
      delete this.ipTargetsAction;
      T1.EventHub.publish("strategyTargetingLoader:start");
    },
    mapItems: function (items, updateAction) {
      var self = this;
      const {
        sharedCollection: sharedCollection,
        partialCollection: partialCollection,
        filterConfigs: filterConfigs,
        hyperLocalTargeting: hyperLocalTargeting,
        filters: filters,
        allDefaultLocations: defaultLocs
      } = this;
      const filterOptions = filterConfigs.filterOptions;
      var defaultGroupObj = {
        bin_show_group_name: true,
        count: "any",
        showGroupNameOnly: true,
        bin_group_name: "",
        groupItems: []
      };
      var result = [];
      var groups = {};
      function ensureMaxLength(fullPath) {
        var goalLength = 57;
        return fullPath.length > goalLength ? `...${fullPath.substr(-goalLength)}` : fullPath;
      }
      function formatPath(path, isBolded) {
        var displayed = path.replace(/\||\//g, "&raquo");
        if (isBolded) {
          return T1.Utils.boldLastPartOfFullPath({
            fullPath: displayed,
            separator: "&raquo",
            searchTermToBeHighlighted: null
          });
        }
        return displayed;
      }
      function buildFullPath(model, currName) {
        var coll = self.collection;
        var parentId = model.get("parent_value_id");
        var name = currName || model.get("targeting_code") || model.get("name");
        if (parentId) {
          const parent = partialCollection.get(parentId) || sharedCollection.get(parentId) || coll.get(parentId) || hyperLocalTargeting.findParentBySet(parentId);
          if (parent) {
            const grandParentId = parent.get("parent_value_id");
            name = `${parent.get("name")} | ${name}`;
            if (grandParentId) {
              name = buildFullPath(parent, name);
            }
          }
        }
        return name;
      }
      $.each(sharedCollection.models, function (j, model) {
        var json = model.toJSON();
        const id = String(json.id);
        const dimensionCode = json.dimension_code;
        if (json.action === updateAction && filters.includes(dimensionCode)) {
          const name = buildFullPath(model);
          const group = groups[dimensionCode] || $.extend(true, {}, defaultGroupObj);
          json.name = formatPath(ensureMaxLength(name), true);
          if (!defaultLocs.length && id === "251") {
            json.isDefault = true;
          } else if (defaultLocs.includes(id)) {
            json.isDefault = true;
          }
          json.tooltip = formatPath(name, false);
          group.groupItems.push(json);
          groups[dimensionCode] = group;
        }
      });
      $.each(filterOptions, function (index, option) {
        var group = groups[option.value];
        if (group && group.groupItems.length) {
          group.bin_group_name = option.text;
          group.count = group.groupItems.length;
          if (option.value === "USZC") {
            group.count = group.groupItems[0].count;
            group.groupItems = [{
              name: "postalcodes.csv",
              id: "placeholder",
              url: `${T1.API_ROOT}strategies/${self.strategy.id}/target_postcodes.csv`
            }];
          } else if (option.value === "hyperlocal") {
            group.include = updateAction === "include";
            group.action = updateAction;
            group.readOnlyView = false;
            const hyperLocalOperator = self.hyperLocalCollection.operators[group.action] || "or";
            group.and = hyperLocalOperator === "and";
            group.isHyperLocal = true;
          }
          result.push(group);
        } else if (option.value === "USZC" && self.zipcodesfile && self.zipcodeaction === updateAction.toUpperCase()) {
          result.push({
            bin_group_name: option.text,
            bin_show_group_name: true,
            showGroupNameOnly: true,
            count: self.zipcodecount,
            groupItems: [{
              name: self.zipcodesfile,
              id: "placeholder"
            }]
          });
        } else if (option.value === "IPTA" && self.ipTargetsFile && self.ipTargetsAction === updateAction.toUpperCase()) {
          result.push({
            bin_group_name: option.text,
            bin_show_group_name: true,
            showGroupNameOnly: true,
            count: self.ipTargetsCount,
            action: updateAction,
            include: updateAction === "include",
            groupItems: [{
              name: self.ipTargetsFile,
              id: " ",
              url: self.showURL ? `${T1.NEMO_API_BASE}targets.target_inet.csv?strategy_id=${self.strategy.id}` : ""
            }]
          });
        }
      });
      return result;
    },
    serialize: function () {
      let dataObj;
      const {
        showBanner: showBanner,
        showHeaderWarning: showHeaderWarning,
        type: type,
        updateAction: updateAction
      } = this;
      if (updateAction) {
        const {
          binItems = [],
          showWarning: showWarning,
          warningMessage: warningMessage,
          tooltipText: tooltipText
        } = this.generateBinData(updateAction);
        dataObj = {
          binItems: binItems,
          showBanner: showBanner,
          showHeaderWarning: showHeaderWarning,
          showWarning: showWarning,
          tooltipText: tooltipText,
          warningMessage: warningMessage
        };
      } else {
        const bins = Object.keys(this.configs).map(key => this.generateBinData(key));
        dataObj = {
          bin_label: type,
          bins: bins,
          type: type
        };
      }
      return dataObj;
    }
  });
  return LocationBinsView;
});
