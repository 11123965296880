define('T1View',['require','jQuery','Backbone','Mustache','T1','T1Form','T1Permissions','T1UtilsAsync','jQPlugins/jquery.placeholder','T1Loader'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const Backbone = require("Backbone");
  const T = require("Mustache");
  const T1 = require("T1");
  const T1Form = require("T1Form");
  const T1Permissions = require("T1Permissions");
  const T1UtilsAsync = require("T1UtilsAsync");
  const placeholder = require("jQPlugins/jquery.placeholder");
  const T1Loader = require("T1Loader");
  const {
    isEmptyObj: isEmptyObj,
    isFunc: isFunc,
    isString: isString
  } = T1.Utils;
  const setValue = function (target, value) {
    const type = target.attr("type");
    const val = target.val();
    if (type === "radio" && val === value) {
      target.prop("checked", true);
    } else if (type === "radio") {
      target.prop("checked", false);
    } else {
      target.val(value);
    }
  };
  const valueTagNames = {
    SELECT: (target, value) => target.val(value).trigger("liszt:updated"),
    INPUT: setValue,
    TEXTAREA: setValue
  };
  const bindMethods = {
    currency: T1.Utils.markupCurrency,
    decimalCurrency: T1.Utils.markupDecimalCurrency
  };
  function getDataObject(target) {
    let targetDataObj;
    const model = this.model;
    if (this[target] && isFunc(this[target].bind)) {
      targetDataObj = this[target];
    } else if (target === "collection" && model && model.collection && isFunc(model.collection.bind)) {
      targetDataObj = model.collection;
    }
    if (!targetDataObj) {
      throw new Error("View Databind: Invalid data object provided");
    }
    return targetDataObj;
  }
  function processEvents(dataEvents, eventOperation) {
    for (const [target, eventsObj] of Object.entries(dataEvents)) {
      const targetDataObj = getDataObject.call(this, target);
      if (isEmptyObj(eventsObj)) {
        throw new Error("View processEvents: Empty object provided");
      }
      for (const [eventName, bindFn] of Object.entries(eventsObj)) {
        let callback = bindFn;
        if (isString(callback)) {
          callback = this[callback];
        }
        if (!isFunc(callback)) {
          throw new Error("View processEvents: unknown function provided");
        }
        eventOperation(targetDataObj, eventName, callback, target);
      }
    }
  }
  const T1View = Backbone.View.extend({
    tmplPath: "",
    template: "",
    delegateDataEvents() {
      const dataEvents = Object.assign({}, this.dataEvents);
      const self = this;
      if (isEmptyObj(dataEvents)) {
        return;
      }
      function bindEvent(dataObj, eventName, callback) {
        dataObj.bind(eventName, callback, self);
      }
      function bindEventWithUnbind(dataObj, eventName, callback) {
        dataObj.unbind(eventName, callback);
        dataObj.bind(eventName, callback, self);
      }
      processEvents.call(this, dataEvents, this.preventDoubleBind ? bindEventWithUnbind : bindEvent);
      this.dataEvents = dataEvents;
    },
    unbindDataEvents() {
      const dataEvents = this.dataEvents;
      if (!dataEvents || isEmptyObj(dataEvents)) {
        return;
      }
      function unbindEvent(dataObj, eventName, callback) {
        dataObj.unbind(eventName, callback);
      }
      processEvents.call(this, dataEvents, unbindEvent);
    },
    addDataEvent(eventObj) {
      const self = this;
      const dataEvents = Object.assign({}, this.dataEvents);
      function bindEvent(dataObj, eventName, callback, target) {
        let originalCallback;
        let currentTarget = dataEvents[target];
        if (!currentTarget) {
          currentTarget = dataEvents[target] = {};
        } else {
          originalCallback = currentTarget[eventName];
        }
        if (originalCallback) {
          dataObj.unbind(eventName, originalCallback);
        }
        dataObj.bind(eventName, callback, self);
        dataEvents[target][eventName] = callback;
      }
      processEvents.call(this, eventObj, bindEvent);
      this.dataEvents = dataEvents;
    },
    removeDataEvents(eventsArry) {
      const dataEvents = this.dataEvents;
      if (!Array.isArray(eventsArry)) {
        throw new Error("removeDataEvents: passed parameter is not an array.");
      }
      for (const item of eventsArry) {
        const target = item.dataObject;
        if (!target) {
          continue;
        }
        const dataObj = getDataObject.call(this, target);
        if (!dataObj) {
          continue;
        }
        const targetDataObj = dataEvents[target];
        const events = item.events;
        if (!Array.isArray(events)) {
          throw new Error("removeDataEvents: events parameter is not an array.");
        }
        if (!target) {
          throw new Error("removeDataEvents: invalid dataObj.");
        }
        for (const evt of events) {
          let targetFn = targetDataObj[evt];
          if (isString(targetFn)) {
            targetFn = this[targetFn];
          }
          if (!targetFn) {
            throw new Error("removeDataEvents: eventName is not in dataEvents object");
          }
          dataObj.unbind(evt, targetFn);
          delete this.dataEvents[target][evt];
          if (isEmptyObj(this.dataEvents[target])) {
            delete this.dataEvents[target];
          }
        }
      }
    },
    delegateEventHubEvents() {
      const eventHubEvents = Object.assign({}, this.eventhubEvents);
      const subscribeEvent = T1.EventHub.subscribe;
      if (isEmptyObj(eventHubEvents)) {
        return;
      }
      for (const [eventName, callback] of Object.entries(eventHubEvents)) {
        let eventCallback;
        if (isString(callback) && this[callback] && isFunc(this[callback])) {
          eventCallback = this[callback];
        } else if (isFunc(callback)) {
          eventCallback = callback;
        }
        const eventCB = (...args) => {
          eventCallback.apply(this, args);
        };
        eventHubEvents[eventName] = eventCB;
        subscribeEvent(eventName, eventCB);
      }
      this.eventhubEvents = eventHubEvents;
    },
    addEventHubEvent(eventObj) {
      const eventHubEvents = Object.assign({}, this.eventhubEvents);
      const subscribeEvent = T1.EventHub.subscribe;
      if (isEmptyObj(eventObj)) {
        throw new Error("addEventHubEvent: empty eventObj passed.");
      }
      for (const [eventName, callback] of Object.entries(eventObj)) {
        let eventCallback;
        if (isString(callback) && this[callback] && isFunc(this[callback])) {
          eventCallback = this[callback];
        } else if (isFunc(callback)) {
          eventCallback = callback;
        }
        if (eventHubEvents[eventName]) {
          return;
        }
        const eventCB = (...args) => {
          eventCallback.apply(this, args);
        };
        subscribeEvent(eventName, eventCB);
        eventHubEvents[eventName] = eventCB;
      }
      this.eventhubEvents = eventHubEvents;
    },
    removeEventHubEvents(eventArry) {
      const eventHubEvents = this.eventhubEvents;
      const unsubscribeEvent = T1.EventHub.unsubscribe;
      for (const item of eventArry) {
        unsubscribeEvent(item, eventHubEvents[item]);
        delete eventHubEvents[item];
      }
    },
    unbindEventHubEvents() {
      const eventHubEvents = this.eventhubEvents;
      const unsubscribeEvent = T1.EventHub.unsubscribe;
      if (!eventHubEvents) {
        return;
      }
      for (const [eventName, callback] of Object.entries(eventHubEvents)) {
        unsubscribeEvent(eventName, callback);
      }
    },
    initialize(opts) {
      if (!opts) {
        return;
      }
      this.tmplPath = opts.tmplPath || this.tmplPath;
      this.template = opts.template || this.template;
    },
    _isEventsInitialized: false,
    bindViewEvents() {
      if (!this._isEventsInitialized) {
        this.delegateDataEvents();
        this.delegateEventHubEvents();
        this._isEventsInitialized = true;
      }
    },
    load() {
      this.render();
    },
    loader(cfg = this.loaderOpts) {
      const defaultConfig = {
        action: undefined,
        contentClass: "",
        context: this.el,
        loaderClass: "",
        target: undefined,
        text: ""
      };
      const loaderConfigs = $.extend(true, defaultConfig, cfg);
      const loader = T1.Loader.create(loaderConfigs);
      return loader;
    },
    reload() {
      this.load();
    },
    unload() {
      if (this.layout && (this.layout.isActive || this.layout.loaded)) {
        this.layout.unload();
      }
      $(this.el).empty();
      this.unbind();
    },
    destroy() {
      if (this.layout) {
        this.layout.destroy();
      }
      this.remove();
      this.unbind();
    },
    unbind() {
      this.unbindDataEvents();
      this.unbindEventHubEvents();
      this._isEventsInitialized = false;
    },
    pause: $.noop,
    resume: $.noop,
    render() {
      const tdata = this.serialize ? this.serialize() : this.model ? this.model.toJSON() : {};
      const partials = this.partials || {};
      const deferred = T1UtilsAsync.makeDeferral();
      const $el = $(this.el);
      const good = "area|base|br|col|command|embed|hr|img|input|keygen|link|meta|param|source|track|wbr)".split("|");
      const bad = /<(\w+)(\s[^>]*\/|\/)>/gi;
      tdata.appLocation = T1.Location.get();
      this.assignAsCurrency(tdata);
      if (!this.ignoreHTMLSanitize) {
        this.template = this.template.replace(bad, function (orig, tag) {
          if (!good.includes(tag)) {
            console.warn("Invalid markup detected in template", tag);
            return orig.replace("/>", `></${tag}>`);
          }
          return orig;
        });
      }
      const render = T.to_html(this.template, tdata, partials);
      if (T1.Utils.hasPolymer()) {
        if ($el[0]) {
          Polymer.dom($el[0]).innerHTML = render;
        }
      } else {
        $el.html(render);
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function viewRendered() {
        $el.find("input, textarea").placeholder();
        deferred.resolve(tdata);
      }, 0);
      return deferred.promise;
    },
    activateInputMasks(targetEl) {
      const currencyCode = this.getCurrencyCode();
      const $target = targetEl ? $(targetEl) : $(this.el);
      T1Form.Masks.attach($("input", $target), currencyCode);
    },
    assignAsCurrency(data) {
      if (!data.asCurrency) {
        data.asCurrency = T1.Utils.getCurrencyTemplateFunc(this.getCurrencyCode());
      }
    },
    getCurrencyCode() {
      const model = this.model || this.strategy;
      if (this.currencyCode && this.currencyCode.length) {
        return this.currencyCode;
      }
      if (model && model.name === "campaign" && model.get) {
        return model.get("currency_code");
      } else if (model && model.name === "strategy" && model.getCurrencyCode) {
        return model.getCurrencyCode();
      }
      return "";
    },
    delegateEvents(events) {
      setTimeout(function T1ViewDelegatedEvents() {
        Backbone.View.prototype.delegateEvents.call(this, events);
      }.bind(this), 0);
    },
    renderPartial(targetSelector, data, action = "html", templateTarget, context = this.el) {
      let templateFragment;
      const template = $(this.template);
      const fragmentSelect = template.find(templateTarget || targetSelector);
      const partials = this.partials || {};
      const location = T1.Location.get();
      if (!targetSelector || !fragmentSelect[0]) {
        return false;
      }
      if (data === undefined) {
        data = this.serialize && isFunc(this.serialize) ? this.serialize() : null;
      }
      if (T1.Utils.hasPolymer()) {
        templateFragment = $("<div/>").text(Polymer.dom(fragmentSelect[0]).innerHTML).text().replace(/&gt;/g, ">");
      } else {
        templateFragment = $("<div/>").text(fragmentSelect.html()).text().replace(/&gt;/g, ">");
      }
      const $target = $(context).find(targetSelector);
      data.appLocation = location;
      this.assignAsCurrency(data);
      const rendered = T.to_html(templateFragment, data, partials);
      if (T1.Utils.hasPolymer($target[0])) {
        if (action === "html") {
          Polymer.dom($target[0]).innerHTML = rendered;
          $target.show();
        } else if (action === "append") {
          Polymer.dom($target[0]).innerHTML = Polymer.dom($target[0]).innerHTML + rendered;
        }
      } else {
        $target[action](rendered).show();
      }
      this.activateInputMasks($target);
      if (this.delegateEvents) {
        this.delegateEvents();
      }
      return true;
    },
    renderManyPartials(targetSelectorArr, data, action, templateTarget, context) {
      for (const target of targetSelectorArr) {
        this.renderPartial(target, data, action, templateTarget, context);
      }
    },
    renderPartialTemplate(partialconfigs) {
      const configs = {
        action: "html",
        context: null,
        partials: this.partials || {},
        skipAttachFormMasks: false,
        skipDelegateEvents: false,
        targetEl: this.el,
        template: this.template,
        templateTarget: null,
        useTemplateHtml: true
      };
      const options = $.extend(true, configs, partialconfigs);
      const template = options.template;
      let fragmentSelect = template;
      if (options.templateTarget) {
        fragmentSelect = $(template).find(options.templateTarget);
      }
      if (T1.Utils.hasPolymer()) {
        fragmentSelect = !options.useTemplateHtml && options.templateTarget === null ? fragmentSelect : Polymer.dom(fragmentSelect[0]).innerHTML;
      } else {
        fragmentSelect = !options.useTemplateHtml && options.templateTarget === null ? fragmentSelect : fragmentSelect.html();
      }
      const templateFragment = $("<div/>").text(fragmentSelect).text().replace(/&gt;/g, ">");
      const $target = options.context ? $(options.context).find(options.targetEl) : $(options.targetEl);
      if (options.data === undefined) {
        options.data = this.serialize && isFunc(this.serialize) ? this.serialize() : null;
      }
      const data = options.data;
      data.appLocation = location;
      this.assignAsCurrency(data);
      const rendered = T.to_html(templateFragment, data, options.partials);
      if (T1.Utils.hasPolymer($target[0])) {
        if (options.action === "html") {
          Polymer.dom($target[0]).innerHTML = rendered;
        } else if (options.action === "append") {
          Polymer.dom($target[0]).appendChild(document.createRange().createContextualFragment(rendered));
        } else if (options.action === "replaceWith") {
          Polymer.dom($target[0].parentNode).insertBefore(document.createRange().createContextualFragment(rendered), $target[0]);
          $target.remove();
        }
      } else {
        $target[options.action](rendered);
      }
      if (!configs.skipDelegateEvents) {
        if (this.delegateEvents) {
          this.delegateEvents();
        }
      }
      if (!configs.skipAttachFormMasks) {
        this.activateInputMasks($target);
      }
    },
    updateDataBind(dataset, target, options) {
      const deferred = T1UtilsAsync.makeDeferral();
      const $targetArea = $(target).find("[data-bind]").not($('[data-bind="ignore"]', target).find("[data-bind]"));
      const escape = T1.Utils.escapeHTML;
      let $currencyInputsRequireMaskReattach = $([]);
      const self = this;
      const currencyCode = this.getCurrencyCode();
      function binTargetEls(index, source) {
        const $src = $(source);
        const method = $src.data("mask");
        const value = this;
        if ($src.attr("alt") === "currency") {
          $src.data("currencyCode", "");
          $currencyInputsRequireMaskReattach = $currencyInputsRequireMaskReattach.add($src);
        }
        let replaceValue = options && options.doNotEscape === true ? value : escape(value);
        if (method && bindMethods[method]) {
          replaceValue = bindMethods[method](replaceValue, "", self.getCurrencyCode());
        }
        if (valueTagNames[source.tagName]) {
          valueTagNames[source.tagName]($src, replaceValue);
        } else {
          if (T1.Utils.hasPolymer(source)) {
            if (source.hasAttribute("value")) {
              Polymer.dom(source).setAttribute("value", replaceValue);
            } else {
              Polymer.dom(source).innerHTML = replaceValue;
            }
          } else {
            $src.html(replaceValue);
          }
        }
      }
      setTimeout(function updateDataBound() {
        for (const [key, value] of Object.entries(dataset)) {
          const $targetEls = $targetArea.filter(`[data-bind='${key}']`);
          if (!$targetEls.length) {
            continue;
          }
          if (isFunc(value)) {
            value($targetEls);
            continue;
          }
          $.each($targetEls, binTargetEls.bind(value));
        }
        if ($currencyInputsRequireMaskReattach.length) {
          T1Form.Masks.attach($currencyInputsRequireMaskReattach, currencyCode);
        }
        deferred.resolve();
      }, 0);
      return deferred.promise;
    },
    buildPermissionedEvents() {
      let eventMap = {};
      const permissionLevel = this.model || this.collection;
      const self = this;
      if (this.permissionedEvents) {
        for (const [permission, events] of Object.entries(this.permissionedEvents)) {
          if (T1Permissions.check(permission, permissionLevel)) {
            eventMap = Object.assign(eventMap, events);
          } else {
            const index = 2;
            for (const [selector] of Object.entries(events)) {
              const el = selector.match(/^(\S+)\s*(.*)$/)[index];
              self.$(el).addClass("permission-locked");
              eventMap[selector] = "preventDefault";
            }
          }
        }
      }
      this.events = Object.assign({}, this.events, eventMap);
      this.delegateEvents();
    },
    preventDefault(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    },
    preferences: {
      apply: $.noop,
      count: $.noop,
      mark: $.noop,
      save: $.noop,
      unmark: $.noop
    }
  });
  const T1ViewExtend = T1View.extend;
  T1View.extend = function (...args) {
    const ViewSubClass = T1ViewExtend.apply(this, args);
    const originalUnload = ViewSubClass.prototype.unload || $.noop;
    ViewSubClass.prototype.unload = function (...args2) {
      originalUnload.apply(this, args2);
      T1View.prototype.unload.apply(this, args2);
    };
    return ViewSubClass;
  };
  return T1View;
});
