define('modules/reporting/campaigns/dataExport/createEdit/views/fileName',["jQuery", "Underscore", "T1", "T1View", "text!../templates/fileName.html"], function ($, _, T1, T1View, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  const DEBOUNCE_DELAY = 200;
  const ENTER_KEY_CODE = 13;
  const MSG_FIRST_CHAR = "File name cannot begin with:<br/>. _ or space";
  const MSG_ALLOWED_CHARS = "Allowed characters for file<br/>name are: A-Z a-z 0-9 . _<br/>space and non-English<br/>characters";
  const PASTE_DELAY = 100;
  const TIME_TO_READ = 1e3;
  const TIME_TO_RESET = 5e3;
  return T1View.extend({
    template: template,
    events: {
      "blur .file-name-input": "doubleCheckForeignCharacterInputHandler",
      "focus .file-name-input": "resetErrorStateHandler",
      "keypress .file-name-input": "enforceInputRestrictionHandler",
      "keyup .file-name-input": "saveInputHandler",
      "paste .file-name-input": "enforcePasteRestrictionHandler"
    },
    eventhubEvents: {
      "createEdit.fileNameError": "showErrorStateHandler"
    },
    initialize: function (model) {
      this.DataExportModel = model;
    },
    load: function () {
      const $el = this.el;
      const self = this;
      this.render().then(function () {
        $el.css("visibility", "visible");
        $el.addClass("fade-in");
        self.$input = $el.find(".file-name-input");
        self.$errorBox = $el.find(".error-box");
      });
    },
    unload: function () {
      this.$input = null;
      this.$errorBox = null;
    },
    showErrorStateHandler: function () {
      this.$input[0].error = true;
    },
    resetErrorStateHandler: function () {
      this.$input[0].error = false;
      this.$errorBox.hide();
    },
    showErrorMessage: _.debounce(function (message) {
      const $errorBox = this.$errorBox;
      if (Polymer && Polymer.dom) {
        Polymer.dom($errorBox[0]).innerHTML = message;
      } else {
        $errorBox.html(message);
      }
      $errorBox.show();
    }, TIME_TO_READ, true),
    resetErrorMessage: _.debounce(function () {
      this.resetErrorStateHandler();
    }, TIME_TO_READ, true),
    getInputValue: function () {
      return this.$input.attr("value");
    },
    enforceInputRestrictionHandler: function (event) {
      const charCode = event.charCode;
      const keyPressed = String.fromCharCode(charCode);
      if (keyPressed === "." || keyPressed === "_" || keyPressed === " ") {
        if (this.$input[0].$.input.selectionStart === 0) {
          this.errorInput(event, MSG_FIRST_CHAR);
          return;
        }
      }
      if (!this.DataExportModel.getAllowedCharsVerified(keyPressed) && charCode !== ENTER_KEY_CODE && event.key !== "Backspace") {
        this.errorInput(event, MSG_ALLOWED_CHARS);
        return;
      }
      this.resetErrorMessage();
    },
    enforcePasteRestrictionHandler: function (event) {
      const self = this;
      const {
        DataExportModel: DataExportModel,
        $input: $input
      } = this;
      function showError(msg) {
        $input.attr("value", "");
        self.errorInput(event, msg);
      }
      setTimeout(function () {
        const pasteValue = self.getInputValue();
        if (!DataExportModel.getFirstCharVerified(pasteValue)) {
          showError(MSG_FIRST_CHAR);
          return;
        }
        if (!DataExportModel.getAllowedCharsVerified(pasteValue)) {
          showError(MSG_ALLOWED_CHARS);
        }
      }, PASTE_DELAY);
    },
    doubleCheckForeignCharacterInputHandler: function (event) {
      const inputValue = this.getInputValue();
      if (inputValue && !this.DataExportModel.getAllowedCharsVerified(inputValue)) {
        this.errorInput(event, MSG_ALLOWED_CHARS);
      }
    },
    errorInput: function (event, message) {
      const self = this;
      event.preventDefault();
      this.showErrorStateHandler();
      this.showErrorMessage(message);
      setTimeout(function () {
        self.resetErrorStateHandler();
      }, TIME_TO_RESET);
    },
    saveInputHandler: _.debounce(function () {
      this.DataExportModel.setFileName($.trim(this.getInputValue()));
      T1Publish("change:formElements", true);
    }, DEBOUNCE_DELAY),
    serialize: function () {
      return {
        value: this.DataExportModel.get("fileName")
      };
    }
  });
});
