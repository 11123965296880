define('models/pixelBundleUrl',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  T1.Models.PixelBundleUrl = T1Model.extend({
    url: "pixel_bundle_urls/",
    omitIdFromPost: true,
    isNew: function () {
      return this.get("action") === "new";
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    drop: function (successHandler, errorHandler) {
      if (this.id) {
        this.save({
          id: this.id,
          version: this.get("version")
        }, {
          action: "delete",
          success: successHandler || $.noop,
          conflict: errorHandler || $.noop
        });
      } else {
        successHandler();
      }
    }
  });
  return T1.Models.PixelBundleUrl;
});
