define('modules/T1App/edit/access/users/main',["jQuery", "T1", "T1Module", "models/t1App"], function ($, T1, T1Module) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "T1App/edit/access/users",
      viewCfgs: {
        usersGrid: {},
        userBin: {},
        userSearch: {},
        users: {}
      },
      defaultView: "users"
    });
  }
  return instance;
});
