define('modules/agency/views/select',["jQuery", "T1", "T1View", "text!modules/agency/templates/select.html", "T1Selectbox"], function ($, T1, T1View, template) {
  return T1View.extend({
    template: template,
    initialize: function (args) {
      var self = this;
      self.collection.bind("reset", self.load, self);
      T1.EventHub.subscribe({
        "searchHistory:selected": function (data) {
          self.searchHistorySelected.call(self, data);
        },
        "filter:clear": function (data) {
          self.load.call(self, data);
        }
      });
    },
    searchHistorySelected: function (data) {
      var collection = this.collection;
      var agency = data.agency || false;
      var id = "";
      var name;
      if (agency) {
        id = agency.id;
        name = agency.name;
        collection.reset({
          silent: true
        });
        collection.add({
          id: id,
          name: name
        });
      }
      this.selected = id;
      this.load();
    },
    selected: "",
    fetchRequired: true,
    load: function () {
      var selectBox;
      var self = this;
      var collection = self.collection;
      var el = $(self.el);
      var selectBoxConfig = {
        searchFieldDisabled: false,
        default_text_default: "AGENCY",
        allow_single_deselect: true,
        onChange: function () {
          var id = selectBox.val();
          self.selected = id;
          T1.EventHub.publish("agency:select", {
            id: id,
            name: el.find(".select-input option:selected").text(),
            fetchRequired: self.fetchRequired
          });
        }
      };
      var autoCompleteConfig = {
        autoComplete: true,
        searchField: "name",
        onSearchRequest: function (searchOptions) {
          collection.search.set(searchOptions);
        }
      };
      self.render();
      selectBox = $(self.el).find(".select-input");
      T1.Selectbox(selectBox, selectBoxConfig, autoCompleteConfig);
    },
    reload: function () {
      this.load();
    },
    serialize: function () {
      var self = this;
      var agencies = self.collection;
      var result = [];
      var obj = {};
      agencies.each(function (agency) {
        obj = agency.toJSON();
        if (agency.id === self.selected) {
          obj.selected = "selected";
        }
        result.push(obj);
      });
      return {
        options: result
      };
    }
  });
});
