define('models/classifications',["T1", "T1Model", "Backbone"], function (T1, T1Model, Backbone) {
  T1.Models.Classifications = T1Model.extend({
    apiRoot: COMPASS_ENV.CLASSIFICATION_API_BASE,
    url: function () {
      var creativeId = this.get("id");
      if (!creativeId) {
        throw new Error("Creative ID is required for classifications");
      }
      return `${this.apiRoot}${creativeId}/inflate`;
    },
    fetch: function (options) {
      var self = this;
      var onSuccess;
      var onError;
      options = options || {};
      onSuccess = function (data) {
        if (options.onSuccess) {
          options.onSuccess(data);
        }
      };
      onError = function () {
        if (options.onError) {
          options.onError();
        }
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      self.ajx = self.sync("read", "", {
        action: self.action,
        dataType: "json",
        onSuccess: onSuccess,
        onError: onError,
        errorDisplaySuppressingConfig: {
          errorCodes: [404]
        },
        processAjaxResponse: function (data) {
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
      return self.ajx;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    fixture: "-classifications"
  });
  return T1.Models.Classifications;
});
