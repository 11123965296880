define('modules/reporting/campaigns/main',["jQuery", "T1", "T1Module"], function ($, T1, T1Module) {
  "use strict";

  return new T1Module({
    defaultView: "campaign_reports",
    name: "reporting/campaigns",
    viewCfgs: {
      campaign_filter_list: {},
      campaign_reports: {},
      date_picker: {},
      dimension_filter_list: {},
      no_data_chart: {},
      no_data_grid: {},
      report_info: {},
      selectCampaign: {},
      select_dimension: {},
      select_focus: {},
      select_geo_level: {},
      select_interval: {},
      select_site_filter: {},
      selectAttribution: {},
      selectDimension: {},
      selectMetric: {},
      settings_menu: {}
    }
  });
});
