define('app.config',{
  index: {
    el: "#t1-page-container",
    class: "",
    tmplPath: "templates/index.html",
    layout: {
      ".error-message": [{
        module: "error",
        viewType: "errorMessage"
      }],
      ".index-content": [{
        module: "auth",
        viewType: "login",
        options: {
          mode: "inline"
        }
      }]
    },
    bindings: {
      onUserLoginOk: {
        action: "changeLocation",
        params: ["monitor", {
          reload: true
        }]
      },
      onSessionOk: {
        action: "changeLocation",
        params: ["monitor", {
          reload: true
        }]
      }
    }
  },
  "reset-password": {
    el: "#t1-page-container",
    class: "",
    tmplPath: "templates/index.html",
    layout: {
      ".error-message": [{
        module: "error",
        viewType: "errorMessage"
      }],
      ".index-content": [{
        module: "auth",
        viewType: "resetPassword"
      }]
    }
  },
  preview: {
    el: "#t1-page-container",
    class: "app-lite",
    tmplPath: "templates/app_lite.html",
    layout: {
      ".error-message": [{
        module: "error",
        viewType: "errorMessage"
      }],
      ".user-feedback": [{
        module: "user",
        viewType: "feedback",
        options: {
          showFeedbackLink: false
        }
      }],
      ".head-title-c": [{
        module: "organization",
        viewType: "select",
        options: {
          isEnabled: false
        }
      }],
      ".main-column": [{
        module: "auth",
        viewType: "login",
        options: {
          mode: "dialog"
        }
      }]
    },
    sections: [{
      uid: "preview",
      url: "preview/display-creative"
    }]
  },
  app: {
    el: "#t1-page-container",
    class: "app",
    tmplPath: "templates/app.html",
    layout: {
      ".error-message": [{
        module: "error",
        viewType: "errorMessage"
      }],
      ".user-badge": [{
        module: "user",
        viewType: "badge"
      }],
      ".user-feedback": [{
        module: "user",
        viewType: "feedback"
      }],
      ".user-tools": [{
        module: "user",
        viewType: "tools"
      }],
      ".side-bar": [{
        module: "nav",
        viewType: "list",
        options: {
          name: "monitor",
          sections: [{
            uid: "campaigns",
            label: "campaigns",
            url: "monitor"
          }, {
            uid: "strategies",
            label: "strategies"
          }, {
            uid: "reports",
            label: "reports",
            url: "reports"
          }, {
            uid: "audiences",
            label: "audiences",
            url: "audiences"
          }, {
            uid: "creatives",
            label: "creatives",
            url: "creatives",
            permission: "video"
          }, {
            uid: "media",
            label: "media",
            url: "media",
            permission: "markets"
          }, {
            uid: "appstore",
            label: "appstore",
            url: "appstore"
          }, {
            uid: "admin",
            label: "admin",
            url: "admin"
          }]
        }
      }, {
        module: "version",
        viewType: "info"
      }],
      ".top-nav": [{
        module: "nav",
        viewType: "breadcrumb"
      }],
      ".head-title-c": [{
        module: "organization",
        viewType: "select"
      }],
      ".main-column": [{
        module: "auth",
        viewType: "login",
        options: {
          mode: "dialog"
        }
      }],
      ".mm-modal": [{
        module: "auth",
        viewType: "bab"
      }]
    }
  }
});
