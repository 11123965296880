define('modules/pixel/main',["jQuery", "T1", "T1Module", "collections/pixels", "collections/advertisers"], function ($, T1, T1Module, PixelBundles, Advertisers) {
  return new T1Module({
    name: "pixel",
    viewCfgs: {
      select: {
        collection: null
      },
      list: {
        collection: null
      },
      search: {
        model: null
      },
      grid: {
        collection: null
      },
      filterSearch: {
        model: null
      }
    },
    defaultView: "select",
    getView: function (type, opts) {
      switch (type) {
        case "search":
        case "filterSearch":
          break;
        default:
          var collection = new PixelBundles();
          collection.fetchOptions = $.extend({
            with: ["advertiser", "agency", "provider"],
            full: "*",
            sort_by: "name"
          }, collection.fetchOptions);
          if (type === "select") {
            collection.search.set({
              params: [{
                term: "1",
                searchField: "eligible"
              }],
              fetchRequired: false
            });
          }
          this.viewCfgs[type].collection = collection;
          break;
      }
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
