define('modules/T1App/apps/main',["jQuery", "T1", "T1Module", "models/t1App"], function ($, T1, T1Module, T1AppModel) {
  "use strict";

  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "T1App/apps",
      viewCfgs: {
        list: {
          model: T1AppModel.getT1App()
        },
        launch: {
          model: T1AppModel.getT1App()
        }
      },
      defaultView: "list"
    });
  }
  return instance;
});
