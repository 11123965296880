define('T1NemoCollection',["jQuery", "When", "T1", "T1Collection"], function ($, When, T1, T1Collection) {
  "use strict";

  return T1Collection.extend({
    apiRoot: T1.NEMO_API_BASE,
    contentType: "application/json",
    dataType: "json",
    count: 0,
    initialize: function (models, options) {
      var settings = {
        isPaginated: false,
        pageField: "page",
        pageLimitField: "rows_per_page"
      };
      options = options || {};
      $.extend(settings, options);
      ["dimension_code", "dimension"].forEach(function (key) {
        if (settings[key]) {
          this[key] = settings[key];
        }
      }, this);
      this.fetchOptions = $.extend(this.fetchOptions, settings.fetchOptions);
      return T1Collection.prototype.initialize.call(this, models, settings);
    },
    fetch: function (opts) {
      if (opts) {
        opts.dataType = opts.dataType || this.dataType;
        opts.processAjaxResponse = opts.processAjaxResponse || this.processAjaxResponse;
      }
      T1Collection.prototype.fetch.call(this, opts);
    },
    parse: function (response) {
      var entities = response.data || [];
      if (response.length) {
        this.count = response.length;
      } else if (response.meta && response.meta.total_count) {
        this.count = response.meta.total_count;
      } else {
        this.count = 0;
      }
      this.loaded = true;
      return entities;
    },
    processAjaxResponse: function (resp) {
      return {
        statusCode: "ok",
        jsonData: resp
      };
    }
  });
});
