define('collections/campaignMargins',['require','Underscore','models/campaignMargin','modules/organization/utils/Utils','T1','T1Collection'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const CampaignMargin = require("models/campaignMargin");
  const OrgUtils = require("modules/organization/utils/Utils");
  const T1 = require("T1");
  const T1Collection = require("T1Collection");
  var collection = T1Collection.extend({
    changedMargins: {},
    model: CampaignMargin,
    parse(...args) {
      if (!this.campaign) {
        throw new Error("campaign must be set");
      }
      let i = 0;
      const parsedJSON = T1Collection.prototype.parse.apply(this, args);
      const strategyArry = _.where(parsedJSON, {
        aggregate_level: "S"
      });
      const campaignMargin = this.campaign.get("margin_pct");
      function processMarginData(data, strategyCampaignMargin) {
        var marginData = data.margin_pct || strategyCampaignMargin;
        data._margin_pct = campaignMargin;
        if (marginData) {
          data._margin_pct = marginData;
        }
        data.total_spend_original = data.total_spend;
        return data;
      }
      const result = _.chain(parsedJSON).sortBy(function (data) {
        if (data.id === "") {
          data.id = i++;
          data.id.toString();
        }
        return Date.parse(data.margin_date);
      }).map(function (data) {
        const marginData = processMarginData(data);
        if (marginData.campaign_margin) {
          marginData.hasCustomMargin = true;
        }
        const campaignStrategies = _.where(strategyArry, {
          margin_date: marginData.margin_date
        });
        marginData.strategies = _.chain(campaignStrategies).map(stratData => processMarginData(stratData)).value();
        return data;
      }).value();
      return result;
    },
    url(...args) {
      if (this.id === undefined) {
        throw new Error("Campaign id needs to be set in id property of collection to construct URL");
      }
      this.urlString = `campaigns/${this.id}/margins`;
      const url = T1Collection.prototype.url.apply(this, args);
      return url;
    },
    saveMargin(successCB, invalidCB, conflictCB) {
      this.sync("POST", {
        data: this.changedMargins
      }, {
        conflict: conflictCB,
        statusInvalid: invalidCB,
        success: successCB
      });
    },
    syncPicardDataWithCollection(strategyReportsObj, campaignReportsObj) {
      var models = this.models;
      var campaignGoalType = this.campaign.get("goal_type");
      const picardStrategyAry = _.groupBy(strategyReportsObj, "start_date");
      const campaignAry = campaignReportsObj;
      const hasVcrImpression = OrgUtils.getOrgVcrSetting();
      const dataMargin = [];
      function parseMarginDate(date) {
        return new Date(Date.parse(date)).getTime();
      }
      function getGoalPerformanceValue(marginData) {
        var combinations = {
          cpa: "total_ad_cost_cpa",
          cpc: "total_ad_cost_cpc",
          cpe: "total_ad_cost_cpa",
          reach: "total_ad_cost_cpm",
          spend: "total_ad_cost_cpm"
        };
        return marginData[combinations[campaignGoalType]];
      }
      for (let i = 0; i < models.length; i++) {
        const campaignItem = models[i];
        const marginDate = parseMarginDate(campaignItem.get("margin_date"));
        for (let j = 0; j < campaignAry.length; j++) {
          const reportItem = campaignAry[j];
          const reportDate = parseMarginDate(reportItem.start_date);
          if (marginDate === reportDate) {
            const campaignProps = {
              _goal_performance: getGoalPerformanceValue(reportItem),
              campaign_goal_type: campaignGoalType,
              clicks: reportItem.clicks,
              cost_sum: reportItem.total_ad_cost,
              impressions: reportItem.impressions,
              in_view: reportItem.in_view,
              margin: reportItem.margin,
              measurable: reportItem.measurable,
              revenue: reportItem.revenue,
              total_conversions: reportItem.total_conversions,
              total_spend_cpm: reportItem.total_spend_cpm,
              total_spend_original: reportItem.total_spend,
              total_spend: reportItem.total_spend,
              video_complete: reportItem.video_complete,
              video_start: reportItem.video_start,
              viewability_rate: reportItem.viewability_rate
            };
            if (hasVcrImpression) {
              campaignProps.video_complete_rate_impression_based = reportItem.video_complete_rate_impression_based;
            } else {
              campaignProps.video_complete_rate = reportItem.video_complete_rate;
            }
            campaignItem.set(campaignProps);
            if (picardStrategyAry[reportItem.start_date]) {
              const strategies = picardStrategyAry[reportItem.start_date];
              _.each(strategies, function (strategy) {
                strategy.total_spend_original = strategy.total_spend;
                strategy.campaign_goal_type = campaignGoalType;
                strategy.name = strategy.strategy_name;
                strategy.id = strategy.strategy_id;
                strategy.cost_sum = strategy.total_ad_cost;
              });
              campaignItem.set({
                strategies: strategies
              });
            }
            dataMargin.push(campaignItem);
          }
        }
      }
      this.models = dataMargin;
    }
  });
  T1.Models.CampaignMargins = collection;
  return T1.Models.CampaignMargins;
});
