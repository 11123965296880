define('modules/reporting/campaigns/contextual/views/contextualChart',["jQuery", "Underscore", "T1", "T1View", "./contextualBarChart", "./contextualLineChart", "text!../templates/chart.html", "text!modules/shared/templates/chartNoDataGrid.html"], function ($, _, T1, T1View, ContextualBarChart, ContextualLineChart, template, noDataChartTmpl) {
  "use strict";

  return T1View.extend({
    template: template,
    partials: {
      chartNoDataChart: noDataChartTmpl
    },
    dimensionKey: "target_id",
    dimensionName: "target_name",
    yKey: "",
    yAxisLabel: "",
    eventhubEvents: {
      "update:metric": function () {
        this.prepareRenderData();
        this.chart.updateChart();
      },
      "switch:chart": function (chartType) {
        this.subSection = chartType;
        this.chart.unloadChart();
        this.setupChart();
      }
    },
    dataEvents: {
      collection: {
        sort: function () {
          if (this.subSection === "summary") {
            this.reload();
          }
        }
      },
      reportModel: {
        "change:dimensionItems": "reload",
        "change:highlightedItem": function () {
          this.updateChart(true);
        },
        "change:hiddenCount": "updateChart"
      }
    },
    initialize: function (args) {
      var models = args.models;
      this.subSection = args.models.mainModel.get("contextualSubSection");
      this.summaryCollection = args.dataGroup.collection;
      this.dailyCollection = args.dataGroup.dailyCollection;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.reportMetaObj = $.extend({}, this.reportMeta.toJSON());
    },
    load: function () {
      var self = this;
      var $el = self.el;
      this.prepareRenderData();
      this.render().then(function () {
        self.$noDataChart = $el.find(".no-data");
        self.$canvas = $el.find("#contextual-chart");
        self.setupChart();
      });
    },
    prepareRenderData: function () {
      var reportModel = this.reportModel;
      var reportMetaObj = this.reportMetaObj;
      var focus = reportModel.get("focus") || "all";
      var metricKey = this.reportMetaObj.metrics[focus];
      this.yKey = reportModel.get("metric");
      this.yKey = metricKey[this.yKey] ? this.yKey : this.reportMetaObj.metricOptions[0].value;
      this.yAxisLabel = metricKey[this.yKey].text;
      this.metricObj = reportMetaObj.fields[this.yKey];
    },
    setupChart: function () {
      var reportModel = this.reportModel;
      switch (this.subSection) {
        case "daily":
          this.chart = new ContextualLineChart({
            collection: this.dailyCollection,
            parentClass: this,
            reportModel: reportModel
          });
          break;
        case "summary":
          this.chart = new ContextualBarChart({
            collection: this.summaryCollection,
            parentClass: this,
            reportMetaObj: this.reportMetaObj,
            reportModel: reportModel
          });
          break;
      }
      this.showNoDataChart();
      this.hideCanvas();
      this.updateChart();
    },
    updateChart: function (isHighlight) {
      this.chart.updateChart(isHighlight);
    },
    showNoDataChart: function () {
      this.$noDataChart.removeClass("no-display");
    },
    hideNoDataChart: function () {
      this.$noDataChart.addClass("no-display");
    },
    showCanvas: function () {
      this.$canvas.removeClass("no-display");
    },
    hideCanvas: function () {
      this.$canvas.addClass("no-display");
    },
    unload: function () {
      this.chart.unloadChart();
      if (this.$canvas) {
        this.$canvas = null;
      }
    }
  });
});
