define('utils/sectionSwitchAssistant',["Underscore", "jQuery"], function (_, $) {
  "use strict";

  var AssistantDef = function SectionSwitchAssistant(configs) {
    var appSectionPreviousViews = {};
    var appSectionAndModuleNamesRefs = [{
      sectionName: "campaignStrategy",
      modules: ["monitor", "campaign", "strategy"]
    }, {
      sectionName: "reports",
      modules: ["reports"]
    }, {
      sectionName: "creatives",
      modules: ["creatives"]
    }, {
      sectionName: "audiences",
      modules: ["audiences"]
    }, {
      sectionName: "admin",
      modules: ["admin"]
    }, {
      sectionName: "preview",
      modules: ["preview"]
    }];
    var baseSectionUrls = configs.baseSectionUrls;
    function locationToUrl(location) {
      var locationObj = location || {};
      var resultUrl = "";
      if (locationObj.module) {
        resultUrl += location.module.replace(/#/g, "");
        if (locationObj.mode) {
          resultUrl += `/${location.mode}`;
          if (locationObj.section) {
            resultUrl += `/${locationObj.section}`;
          }
          if (locationObj.id) {
            resultUrl += `/${locationObj.id}`;
            if (locationObj.deepLinks && $.isArray(locationObj.deepLinks) === true && locationObj.deepLinks.length > 0) {
              _.each(locationObj.deepLinks, function (item) {
                resultUrl += `/${item.toString()}`;
              });
            }
          }
        }
      }
      return resultUrl;
    }
    function getSectionNameByModuleName(moduleName) {
      var module = moduleName ? moduleName.toLowerCase().replace(/#/g, "") : "";
      var resultPair;
      resultPair = _.find(appSectionAndModuleNamesRefs, pair => pair.modules.indexOf(module) > -1);
      return resultPair ? resultPair.sectionName : null;
    }
    function registerAppSectionChange(location) {
      var moduleName = location && location.module ? location.module : null;
      var sectionName = getSectionNameByModuleName(moduleName);
      if (sectionName) {
        appSectionPreviousViews[sectionName] = location;
      }
    }
    function detectAppSectionChange(locationOld, locationNew) {
      var moduleOld = locationOld && locationOld.module ? locationOld.module : null;
      var moduleNew = locationNew && locationNew.module ? locationNew.module : null;
      var sectionNameOld = getSectionNameByModuleName(moduleOld);
      var sectionNameNew = getSectionNameByModuleName(moduleNew);
      var result = {
        hasSectionChanged: false,
        savedLocation: null,
        savedUrl: null
      };
      var resultLocation, savedUrl;
      if ($.inArray(locationToUrl(locationNew), baseSectionUrls) > -1) {
        if (sectionNameNew && sectionNameOld && sectionNameNew !== sectionNameOld) {
          resultLocation = appSectionPreviousViews[sectionNameNew];
          savedUrl = locationToUrl(resultLocation);
          if (locationToUrl(locationNew).toLowerCase() !== savedUrl.toLowerCase()) {
            result.hasSectionChanged = true;
            result.savedLocation = resultLocation;
            result.savedUrl = savedUrl;
          }
        }
      }
      return result;
    }
    return {
      registerAppSectionChange: registerAppSectionChange,
      detectAppSectionChange: detectAppSectionChange,
      locationToUrl: locationToUrl
    };
  };
  return AssistantDef;
});
