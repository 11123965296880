define('modules/campaign/bulkedit/targeting/location/main',["T1Module"], function (T1Module) {
  "use strict";

  return new T1Module({
    name: "campaign/bulkedit/targeting/location",
    viewCfgs: {
      bins: {
        collection: null
      },
      browse: {
        collection: null
      },
      filter: {
        collection: null
      },
      panel: {
        collection: null
      }
    },
    getView: function (type, opts) {
      return T1Module.prototype.getView.call(this, type, opts);
    }
  });
});
