define('collections/publishers',["jQuery", "T1", "T1Collection", "models/publisher"], function ($, T1, T1Collection, Publisher) {
  var originalInitialize;
  var collection = T1Collection.extend({
    model: Publisher,
    canCache: true,
    cacheDuration: 6e4,
    urlString: "publishers",
    fixture: "-publishers",
    searchConfiguration: {
      fields: {
        organization_id: {
          type: "special"
        }
      }
    }
  });
  originalInitialize = collection.prototype.initialize;
  collection.prototype.initialize = function (models, options) {
    options = options || {};
    options.isPaginated = false;
    return originalInitialize.call(this, models, options);
  };
  T1.Models.Publishers = collection;
  return T1.Models.Publishers;
});
