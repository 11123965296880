define('modules/strategy/views/helpers/contextualSummaryTarget',["Underscore", "jQuery", "T1", "utils/CampaignStrategyUtils"], function (_, $, T1, Utils) {
  "use strict";

  return {
    prepareForSerialize: function (currencyCode, collection, strategy, partialCollection, safetyCollection) {
      var self = this;
      var collObj = collection;
      var safetyMaturityColl = safetyCollection;
      var firstEntry = true;
      const apiLimit = 100;
      var resultJSON = {
        data: []
      };
      var dataTextHash = Utils.maturityUgcDataDisplayHash;
      var targetingVendorOperators = {};
      var getMaturityData = function () {
        var ids = ["1591", "1592", "1593"];
        var result = [];
        $.each(ids, function (index, id) {
          const targetValueModel = safetyMaturityColl.get(parseInt(id, 10));
          if (targetValueModel && targetValueModel.get("dimension_code") === "SFTY") {
            result.push({
              action: "include",
              label: dataTextHash[id]
            });
            return false;
          }
        });
        return result;
      };
      var getUgcData = function () {
        const ids = ["1597", "1598"];
        const targetValueModel = safetyMaturityColl.get(parseInt(ids[1], 10));
        var dimensionCode = targetValueModel ? targetValueModel.get("dimension_code") : null;
        var result = [];
        if (targetValueModel && safetyMaturityColl.get(parseInt(ids[0], 10)) && dimensionCode === "SFTY") {
          result.push({
            action: "include",
            label: dataTextHash[`${ids[1]},${ids[0]}`]
          });
        } else if (targetValueModel && dimensionCode === "SFTY") {
          result.push({
            action: "include",
            label: dataTextHash[ids[1]]
          });
        }
        return result;
      };
      if (collection.count > apiLimit) {
        resultJSON.numberOfItemsLeft = collection.count - apiLimit;
      }
      this.strategy = strategy;
      this.collection = collection;
      if (this.collection) {
        this.collection.each(function (model) {
          const modelObj = model.toJSON();
          const fullPath = modelObj.full_path;
          if (fullPath.split(" - ")) {
            const vendorName = fullPath.split(" - ")[0];
            if (modelObj.action === "include") {
              targetingVendorOperators[vendorName] = modelObj.operator;
            }
          }
        });
        this.collection.targetingVendorOperators = targetingVendorOperators;
      }
      const sortedCollObj = _.groupBy(collObj.models, function (model) {
        return model.get("type") !== "retired_targeting_segment" ? model.get("full_path").split(" - ")[0] : model.get("full_retired_path").split(" - ")[0];
      });
      for (const item in sortedCollObj) {
        if (T1.Utils.hasOwnProp(sortedCollObj, item)) {
          const key = item;
          if (key === "Integral Ad Science") {
            firstEntry = true;
          }
          resultJSON.data.push(self.getTargetSegmentDataObject(sortedCollObj[item], key, currencyCode, firstEntry));
          if (firstEntry && key !== "Integral Ad Science") {
            firstEntry = false;
          }
        }
      }
      let tempData = getMaturityData();
      if (tempData.length) {
        resultJSON.data.push({
          items: tempData,
          header: "Maturity Data"
        });
      }
      tempData = getUgcData();
      if (tempData.length) {
        resultJSON.data.push({
          items: tempData,
          header: "UGC Data"
        });
      }
      return resultJSON;
    },
    getTargetSegmentDataObject: function (items, key) {
      var self = this;
      var targetArr = {
        header: key,
        items: []
      };
      var spaceEntity = "&nbsp;";
      var sortIncEx = function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      };
      const segmentOperator = this.collection.targetingVendorOperators[key] ? this.collection.targetingVendorOperators[key] : "OR";
      targetArr.includeOP = `(${segmentOperator})`;
      $.each(items, function (index, model) {
        const tmpObj = model.toJSON();
        const action = tmpObj.action;
        let path;
        if (tmpObj.full_path || tmpObj.full_retired_path) {
          if (tmpObj.type !== "retired_audience_segment") {
            path = tmpObj.full_path.replace(/ - /g, " &raquo ");
          } else {
            path = tmpObj.full_retired_path.replace(/ - /g, " &raquo ");
          }
        } else {
          path = spaceEntity;
        }
        targetArr.items.push({
          action: action,
          label: self.getPartialPathFromFull(path, tmpObj.name),
          boldText: tmpObj.name
        });
      });
      targetArr.items.sort(sortIncEx);
      return targetArr;
    },
    getPartialPathFromFull: function (fullPath, name) {
      return fullPath.replace(name, "");
    }
  };
});
