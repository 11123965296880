define('modules/organization/views/search',['require','jQuery','T1','T1View','text!modules/organization/templates/search.html','utils/UserDataUtils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const T1 = require("T1");
  const T1View = require("T1View");
  const template = require("text!modules/organization/templates/search.html");
  const UserDataUtils = require("utils/UserDataUtils");
  const platformUrl = "https://platform.mediamath.com";
  return T1View.extend({
    template: template,
    initialize(args) {
      this.enabled = args.isEnabled !== false;
      this.collection.getSelected().then(selectedId => {
        this.selectedOrg = this.collection.get(selectedId);
      });
      this.delegateDataEvents();
      if (window.top !== window.self) {
        window.top.postMessage("ready", platformUrl);
      }
    },
    dataEvents: {
      collection: {
        "organization:select": function () {
          this.unload();
          this.collection.getSelected().then(selectedId => {
            this.selectedOrg = this.collection.get(selectedId);
            this.load();
          });
        }
      }
    },
    opened: false,
    enabled: true,
    onWBody: false,
    load() {
      var self = this;
      var loader = this.loader();
      var orgName = this.selectedOrg ? this.selectedOrg.get("name") : "";
      const delay = 100;
      window.addEventListener("message", e => {
        if (e.origin === platformUrl && e.data !== "ready" && e.data !== "error") {
          try {
            const data = JSON.parse(e.data);
            const {
              organizationId: organizationId
            } = data;
            if (organizationId) {
              self.setSelected({
                id: organizationId
              });
            }
          } catch (err) {
            if (window.top !== window.self) {
              window.top.postMessage(`error: ${err.message}`, window.location.origin);
            }
          }
        }
      });
      loader.start();
      if (orgName === "") {
        return;
      }
      loader.stop();
      this.render().then(() => {
        setTimeout(() => {
          if (!this.enabled) {
            this.setEnabledState(false);
            this.$(".arrow-icon").css("display", "none");
          } else {
            this.setEnabledState(true);
            this.$(".arrow-icon").css("display", "block");
          }
        }, delay);
      });
      const searchInput = this.$(".search-input");
      searchInput.autocomplete({
        minLength: 1,
        appendTo: self.$(".auto-complete-results"),
        source: function (request, response) {
          if ($.trim(request.term) === "#") {
            searchInput.removeClass("ui-autocomplete-loading");
            return;
          }
          self.source(request, function (data) {
            if (!data.length) {
              data = [{
                summary: `No results found for "${T1.Utils.escapeHTML(request.term)}"`
              }];
            }
            searchInput.removeClass("ui-autocomplete-loading");
            response(data);
          });
        },
        select: function (event, ui) {
          var item = ui.item;
          searchInput.val(item.label);
          self.toggle();
          self.select({
            orgId: item.id
          });
          event.preventDefault();
          event.stopPropagation();
          return false;
        },
        position: {
          offset: "0 5"
        }
      }).data("autocomplete")._renderItem = this.renderItem;
      searchInput.data("autocomplete")._renderMenu = this.renderMenu;
      this.$(".org-name").text(orgName);
    },
    reload() {
      this.unload();
      this.load();
    },
    events: {
      "blur .search-input": "searchInputBlur",
      "click .organization-bar": "toggle",
      "mouseenter .w-body": "mouseEnterToWBody",
      "mouseleave .w-body": "mouseLeaveFromWBody"
    },
    eventhubEvents: {
      "dealEdit:populateEditForm": "setSelected",
      "organization:select:disable": "disable",
      "organization:select:enable": "enable"
    },
    setSelected(data) {
      this.collection.setSelected(data.id);
    },
    setEnabledState(isEnabled) {
      var method = isEnabled ? "removeClass" : "addClass";
      this.enabled = isEnabled;
      this.$(".w-body")[method]("disabled");
    },
    disable() {
      this.setEnabledState(false);
    },
    enable() {
      this.setEnabledState(true);
    },
    mouseEnterToWBody() {
      this.onWBody = true;
    },
    mouseLeaveFromWBody() {
      this.onWBody = false;
    },
    searchInputBlur() {
      if (!this.onWBody) {
        this.toggle();
      }
    },
    renderMenu(ul, items) {
      var self = this;
      $.each(items, function (index, item) {
        if (T1.Utils.hasOwnProp(item, "summary")) {
          ul.append(`<li class="ui-autocomplete-summary">${item.summary}</li>`);
        } else {
          self._renderItem(ul, item);
        }
      });
    },
    renderItem(ul, item) {
      return $(`<li><a>${item.label}</a></li>`).data("item.autocomplete", item).appendTo(ul);
    },
    source(request, response) {
      var collection = this.collection;
      const isValidSearchTerm = collection.search.set({
        params: [{
          term: request.term,
          searchField: "name|id"
        }],
        fetchRequired: false
      });
      if (!isValidSearchTerm) {
        response([]);
        return;
      }
      collection.fetch({
        success: function () {
          response($.map(collection.toJSON(), function (item) {
            item.label = item.value = `${item.name} (${item.id})`;
            return item;
          }));
        }
      });
    },
    toggle() {
      if (this.enabled) {
        this.toggleSearch();
      }
    },
    toggleSearch() {
      var panel = this.$(".panel");
      var searchInput = this.$("input");
      var arrowIcon = this.$(".arrow-icon");
      if (this.opened) {
        panel.hide();
        arrowIcon.removeClass("arrow-up-icon");
        this.opened = false;
      } else {
        panel.show();
        arrowIcon.addClass("arrow-up-icon");
        searchInput.focus().select();
        this.opened = true;
      }
    },
    select(obj) {
      var id = obj.orgId || "";
      UserDataUtils.update("organization");
      this.collection.setSelected(id, {
        fromSelect: true
      });
      this.collection.fetchSelectedOrg();
    },
    unload() {
      this.$(".w-body").unbind("mouseenter mouseleave");
      this.$(".organization-bar").unbind("click");
      this.$(".search-input").unbind("blur");
    }
  });
});
