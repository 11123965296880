define('modules/reporting/campaigns/views/date_picker',['require','jQuery','moment','T1','T1View','modules/reporting/campaigns/utils/reportingUtils','text!../templates/date_picker.html'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const moment = require("moment");
  const T1 = require("T1");
  const T1View = require("T1View");
  const ReportUtils = require("modules/reporting/campaigns/utils/reportingUtils");
  const template = require("text!../templates/date_picker.html");
  const displayFormat = "MMM dd, yyyy";
  const maxUserSelection = 90;
  function getDate(daysAgo) {
    return moment().add(-daysAgo, "days")._d;
  }
  return T1View.extend({
    template: template,
    events: {
      "click .dates span": "openDatePickerHandler",
      "saved .date-picker": "saveDatesHandler",
      "selected .date-picker": "presetHandler",
      "mouseover .dates span": "addHoverState",
      "mouseout .dates span": "removeHoverState"
    },
    initialize({
      models: {
        mainModel: mainModel,
        reportModel: reportModel,
        campaign: model
      }
    }) {
      const oneWeek = 7;
      const twoWeeks = 14;
      const oneMonth = 30;
      const yesterday = getDate(1);
      const weekAgo = getDate(oneWeek);
      const twoWeeksAgo = getDate(twoWeeks);
      const monthAgo = getDate(oneMonth);
      if (!mainModel) {
        throw new Error("Reporting models as arguments required for Datepicker");
      }
      Object.assign(this, {
        mainModel: mainModel,
        model: model,
        rangePresets: [{
          name: "Yesterday",
          value: "Yesterday",
          start: yesterday,
          end: yesterday
        }, {
          name: "Last 7 Days",
          value: "Last 7 Days",
          start: weekAgo,
          end: yesterday
        }, {
          name: "Last 14 Days",
          value: "Last 14 Days",
          start: twoWeeksAgo,
          end: yesterday
        }, {
          name: "Last 30 Days",
          value: "Last 30 Days",
          start: monthAgo,
          end: yesterday
        }],
        reportModel: reportModel,
        section: mainModel.get("section")
      });
      switch (this.section) {
        case "device_technology":
        case "geo":
        case "site_transparency":
          this.rangePresets.push({
            name: "Campaign To Date",
            value: "Campaign To Date",
            start: getDate(maxUserSelection + 1),
            end: yesterday
          });
          break;
      }
      if (reportModel.get("startDate")) {
        this.startDate = Date.parse(reportModel.get("startDate"));
        this.endDate = Date.parse(reportModel.get("endDate"));
      } else {
        this.startDate = ReportUtils.defaultStartDate;
        this.endDate = ReportUtils.defaultEndDate;
      }
    },
    load() {
      this.isCTD = this.reportModel.get("isCTD");
      this.render().then(() => {
        setTimeout(() => {
          const datePicker = this.datePicker = this.$(".date-picker")[0];
          datePicker.rangePresets = this.rangePresets;
          datePicker.target = this.$(".dates")[0];
          datePicker.closeLabel = "Cancel";
        }, 0);
      });
    },
    unload() {
      this.isCTD = null;
      this.datePicker = null;
      this.endDate = null;
      this.mainModel = null;
      this.model = null;
      this.rangePresets = null;
      this.reportModel = null;
      this.section = null;
      this.startDate = null;
    },
    addHoverState(event) {
      $(event.currentTarget).parent().addClass("onhover");
    },
    removeHoverState(event) {
      $(event.currentTarget).parent().removeClass("onhover");
    },
    openDatePickerHandler() {
      const {
        datePicker: datePicker,
        section: section
      } = this;
      const today = new Date();
      datePicker.end = this.endDate;
      datePicker.start = this.startDate;
      datePicker.allowedEnd = today;
      switch (section) {
        case "device_technology":
        case "geo":
        case "site_transparency":
        case "contextual_insights":
          datePicker.allowedStart = getDate(maxUserSelection);
          break;
      }
      if (this.isCTD) {
        datePicker.startEnabled = false;
        datePicker.endEnabled = false;
      }
      datePicker.open();
    },
    presetHandler(event) {
      const datePicker = this.datePicker;
      if (event.originalEvent.detail.name === "Campaign To Date") {
        datePicker.startEnabled = false;
        datePicker.endEnabled = false;
        this.isCTD = true;
      } else {
        datePicker.startEnabled = true;
        datePicker.endEnabled = true;
        this.isCTD = false;
      }
    },
    saveDatesHandler(event) {
      const dateFormat = "yyyy-MM-dd";
      const origEvent = event.originalEvent;
      const eventData = origEvent.detail;
      const isCTD = origEvent.target.rangeValue === "Campaign To Date";
      const newStartDate = moment(eventData.start).toDate();
      const newEndDate = moment(eventData.end).toDate();
      const model = this.model;
      if (this.startDate.toString(dateFormat) !== newStartDate.toString(dateFormat) || this.endDate.toString(dateFormat) !== newEndDate.toString(dateFormat) || this.isCTD !== isCTD) {
        this.isCTD = isCTD;
        this.startDate = newStartDate;
        this.endDate = newEndDate;
        this.reportModel.setStartAndEndDate(newStartDate, newEndDate);
        model.set({
          start: newStartDate.toString(dateFormat),
          end: newEndDate.toString(dateFormat),
          isCTD: this.isCTD
        });
        model.trigger("change:start_end");
        T1.EventHub.publish("reports:saveDate");
        this.updateDate();
      }
    },
    updateDate() {
      const $startDate = this.$(".start-date");
      const $endDate = this.$(".end-date");
      if (this.isCTD) {
        $startDate.text("Campaign");
        $endDate.text("To Date");
      } else {
        $startDate.text(this.startDate.toString(displayFormat));
        $endDate.text(this.endDate.toString(displayFormat));
      }
    },
    serialize() {
      const params = {
        start: this.isCTD ? "Campaign" : this.startDate.toString(displayFormat),
        end: this.isCTD ? "To Date" : this.endDate.toString(displayFormat)
      };
      return {
        params: params
      };
    }
  });
});
