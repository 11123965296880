define('modules/audiences/pixels/views/createEdit',["require", "jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1Form", "T1Permissions", "T1Collection", "models/pixel", "models/advertiser", "collections/organizations", "models/sessionUser", "collections/pixelBundleUrls", "collections/pixelsInContainer", "collections/dataproviders", "collections/attributes", "collections/attributesWhiteList", "utils/AttributeUtils", "utils/AdminUtils", "text!modules/audiences/pixels/templates/createEdit.html", "text!templates/info_box.html", "text!modules/audiences/pixels/templates/currency_ddl.html", "text!modules/admin/shared/templates/customAttributes.html", "text!modules/admin/shared/templates/customAttributesLoading.html", "text!modules/admin/shared/templates/deletedAttributesWarning.html", "models/organization", "Mustache", "jQPlugins/chosen.jquery"], function (require, $, _, When, T1, T1View, T1Layout, T1Form, T1Permissions, T1Collection, Pixel, Advertiser, Organizations, User, PixelBundleUrls, PixelsInContainer, DataProviders, Attributes, AttributesWhiteList, AttributeUtils, Utils, template, info_box_template, currency_ddl_template, attributesTemplate, attributesLoadingTemplate, deletedAttributesWarning, organizationModel, Mustache) {
  var user = User.getUser().toJSON();
  var PixelCreateEdit;
  var pixelTemplates = {
    event: "../templates/createEditEventPixelBody.html",
    data: "../templates/createEditDataPixelBody.html"
  };
  var tagTypes = {
    image: "Image MathTag",
    js: "JavaScript MathTag Container",
    iframe: "iFrame MathTag Container",
    dfa: "Image MathTag for DART Floodlight",
    uat: "Image MathTag for Atlas UAT"
  };
  var isGlobalAdmin = user.type === "INTERNAL" && user.role === "ADMIN" && user.scope === "GLOBAL";
  function getEventPixelTypeName(tag_type) {
    return tagTypes[tag_type] || "";
  }
  function getTemplatePath(type) {
    return pixelTemplates[type];
  }
  function getTemplateBody(type) {
    var deferred = When.defer();
    var tmplPath = getTemplatePath(type);
    require(["text!" + tmplPath], function (tmpl) {
      deferred.resolve(tmpl);
    });
    return deferred.promise;
  }
  function updateBreadCrumb(pixel) {
    var pathPieces;
    var pixelType = getPixelType(pixel);
    if (pixel.id) {
      pathPieces = [{
        label: pixel.name,
        location: "#audiences/pixels/" + pixelType.toLowerCase() + "/" + pixel.id
      }];
    } else {
      pathPieces = [{
        label: "New " + pixelType + " Pixel",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: getBreadCrumbHeaderInfo(pixel)
    });
  }
  function getBreadCrumbHeaderInfo(model) {
    var modelJSON = model.toJSON ? model.toJSON() : model;
    var valueToBeDisplayed = [{
      attr: "id",
      label: "Pixel id",
      className: "id"
    }, {
      attr: "created_on",
      label: "created on",
      className: "id"
    }, {
      attr: "eligible",
      label: "Status",
      className: "status"
    }];
    var returnArry = [];
    var i;
    var noData = "&nbsp;--";
    var formatDate = function (date) {
      return date ? Date.parse(date).toString("MM/dd/yyyy") : noData;
    };
    var formatData = function (attr, value) {
      var attrOperations = {
        created_on: formatDate,
        user: function () {
          return value ? value.first_name + " " + value.last_name : noData;
        },
        eligible: function () {
          var statusText = value === "0" ? "Inactive" : "Active";
          if (!model.id) {
            statusText = "Active";
          }
          return statusText;
        },
        defaultFormat: function () {
          return value || noData;
        }
      };
      var formatter = attrOperations[attr] || attrOperations.defaultFormat;
      return formatter(value);
    };
    for (i = 0; i < valueToBeDisplayed.length; i++) {
      returnArry.push({
        label: valueToBeDisplayed[i].label || valueToBeDisplayed[i].attr,
        value: formatData(valueToBeDisplayed[i].attr, modelJSON[valueToBeDisplayed[i].attr]),
        className: valueToBeDisplayed[i].className
      });
    }
    return returnArry.reverse();
  }
  function getPixelType(modelJSON) {
    return modelJSON.pixel_type === "event" ? "Event" : "Data";
  }
  PixelCreateEdit = T1View.extend({
    template: template,
    partials: {
      info_box: info_box_template,
      currency_ddl: currency_ddl_template,
      attributes: attributesTemplate
    },
    loaderOpts: {
      target: ".w-PixelsCreateEdit .data-attribute-loader",
      contentClass: "search-loader",
      text: "Loading Data Attributes"
    },
    tipConfig: {
      getExternalTip: true,
      delayIn: 0,
      delayOut: 0
    },
    events: {
      "click input[name='pricing']": "onPricingTypeClicked",
      "click input[name='attribution']": "onToggleAttribution",
      "click .revenue-attr": "setFormChangeCheckbox",
      "click .override-adv-custom-attributes": "overrideAdvCustomAttributes",
      "click #confirm": "closeWarning",
      "click #undo-remove": "undoRemoveAttribute",
      "removed #custom-attributes": "removeAttributeHandler",
      "change .standard-attributes": "changeStandardAttributeHandler"
    },
    eventhubEvents: {
      "organization:explicitChange": function () {
        T1.Location.set("#audiences/pixels");
      },
      "dropdown:selectAgency": "agencyChangeHandler",
      "dropdown:pixel_providers": "dataProviderChangeHandler",
      "dropdown:advertiserChange": "onAdvertiserChange",
      listChanged: "listChangedHandler"
    },
    isFormElementsChanged: false,
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveAndNew"
      }
    },
    initialize: function (args) {
      var self = this;
      var mode = args.section;
      var isApprovedAdmin = (user.role === "ADMIN" || user.role === "MANAGER") && T1Permissions.check("feature", "pixels_enable_nonjs");
      self.canEditAttributesPermission = user.edit_data_definition === "1";
      self.isAttributesViewOnlyPermission = user.view_data_definition === "1" && !self.canEditAttributesPermission;
      self.canViewSegments = self.canEditAttributesPermission || self.isAttributesViewOnlyPermission;
      self.showAttributionPair = true;
      self.mode = mode;
      self.isEdit = args.id && args.id !== "create";
      self.showNonJs = isGlobalAdmin || isApprovedAdmin;
      self.initializeData(args);
      self.bindUnsavedChanges();
    },
    setFormActions: function () {
      var self = this;
      if (!self.isEdit) {
        self.actionsConfig.formActions = [{
          label: "cancel",
          class: "cancel",
          location: "#audiences/pixels"
        }, {
          label: "save & create another",
          class: "saveAndNew btn-primary",
          location: false
        }, {
          label: "save & close",
          uuid: "save_close",
          class: "saveAndClose btn-primary",
          location: false
        }];
      } else {
        self.actionsConfig.formActions = [{
          label: "cancel",
          class: "cancel",
          location: "#audiences/pixels"
        }, {
          label: "save",
          class: "save btn-primary",
          location: false
        }, {
          label: "save & close",
          uuid: "save_close",
          class: "saveAndClose btn-primary",
          location: false
        }];
      }
    },
    initializeData: function (args) {
      var self = this;
      var orgModel;
      self.canLoad = false;
      this.dataAttributes = new Attributes();
      this.attrsWhiteList = new AttributesWhiteList();
      this.whiteList = {};
      this.whiteListOptions = "";
      Organizations.getOrganizations().getSelected().then(function (orgID) {
        self.orgID = orgID;
        orgModel = new organizationModel({
          id: orgID
        });
        orgModel.fetch({
          success: function () {
            self.orgCurrencyCode = orgModel.get("currency_code");
            self.updateModels(args).then(function (model) {
              self.model = model;
              updateBreadCrumb(model.toJSON());
              getTemplateBody(self.mode).then(function (templateBody) {
                self.canLoad = true;
                self.initLayout(templateBody);
                self.load();
              });
            });
          }
        });
      });
    },
    getDataAttributes: function () {
      var self = this;
      var deferred = When.defer();
      this.dataAttributes.fetch({}).then(function () {
        var models;
        if (self.isEdit) {
          models = self.dataAttributes.models;
          if (models.length === 0) {
            delete self.dataAttributes.pixelId;
            self.dataAttributes.advertiserId = self.dataAttributes.pixelAdvertiserId;
            self.dataAttributes.fetch({}).then(function () {
              deferred.resolve();
            });
            self.overrideDisabled = false;
            self.overrideSelected = false;
          } else {
            self.overrideDisabled = true;
            self.overrideSelected = true;
            deferred.resolve();
          }
        } else {
          self.overrideDisabled = false;
          self.overrideSelected = false;
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    updateModels: function (args) {
      var self = this;
      var deferred = When.defer();
      var model, advertiser, agency, advertiserParent;
      if (self.isEdit && args.id) {
        model = new Pixel({
          id: args.id
        });
        model.fetch({
          params: {
            with: self.mode === "event" ? ["advertiser,agency", "pixel_bundle_urls", "pixels"] : ["agency", "provider"]
          },
          success: function (model) {
            requestAnimationFrame(function () {
              model.set({
                isActive: model.get("eligible") === "1"
              });
              if (!model.get("revenue") && (!model.get("currency") || !model.get("currency_fixed"))) {
                model.set({
                  currency_fixed: self.orgCurrencyCode
                });
              }
              function mapPixelList(coll) {
                coll.map(function (item) {
                  item.unset("action");
                });
                return coll;
              }
              if (self.mode === "event") {
                self.eventUrlCollection = mapPixelList(model.get("pixel_bundle_url"));
                self.pixelListCollection = mapPixelList(model.get("pixel"));
                self.currencyType = model.get("currency") !== undefined ? "DYNAMIC" : "FIXED";
                self.isRevenueAttributionChecked = model.get("revenue") !== undefined && (model.get("currency") || model.get("currency_fixed") !== undefined);
                advertiser = model.get("advertiser");
                agency = advertiser.get("agency");
                if (agency) {
                  advertiserParent = agency.toJSON();
                  if (agency.get("organization_id")) {
                    Utils.fetchData(organizationModel, agency.get("organization_id")).then(function (org) {
                      advertiserParent.parent = org.toJSON();
                      if ("advertiserId" in self.dataAttributes) {
                        delete self.dataAttributes.advertiserId;
                      }
                      self.dataAttributes.pixelId = model.get("id");
                      self.dataAttributes.pixelAdvertiserId = model.get("advertiser_id");
                      self.getDataAttributes().then(function () {
                        deferred.resolve(model);
                      });
                    });
                  } else {
                    deferred.resolve(model);
                  }
                } else {
                  deferred.resolve(model);
                }
              } else {
                deferred.resolve(model);
              }
            });
          }
        });
      } else {
        model = new Pixel({
          action: "new",
          pixel_type: self.mode,
          isActive: true,
          currency_fixed: self.orgCurrencyCode,
          name: ""
        });
        if (self.mode === "event") {
          self.eventUrlCollection = new PixelBundleUrls();
          self.pixelListCollection = new PixelsInContainer();
          self.currencyType = "FIXED";
          self.isRevenueAttributionChecked = false;
          deferred.resolve(model);
        } else {
          self.getDataProviders().then(function () {
            deferred.resolve(model);
          });
        }
      }
      return deferred.promise;
    },
    dmpCheckEnable: function (advertiser, parent) {
      var data = {};
      var model = advertiser;
      var grandParent = parent.parent;
      var dmpEnabled = model.get("dmp_enabled");
      var parentDmpEnabled = parent && parent.dmp_enabled !== "disabled";
      var grandParentDmpEnabled = grandParent && grandParent.dmp_enabled ? grandParent.dmp_enabled !== "disabled" : parentDmpEnabled;
      data.dmp_enabled = typeof dmpEnabled !== "undefined" ? dmpEnabled !== "disabled" : parentDmpEnabled !== "disabled";
      data.deactivate_dmp = false;
      if (!parentDmpEnabled || !grandParentDmpEnabled) {
        data.dmp_enabled = false;
        data.deactivate_dmp = true;
      }
      return data;
    },
    dataAttributeLoaderHandler(start) {
      const loader = this.loader();
      const loaderEl = this.el.find(".data-attribute-loader");
      if (start) {
        loader.start();
        loaderEl.removeClass("hidden");
      } else {
        loader.stop();
        loaderEl.addClass("hidden");
      }
    },
    onAdvertiserChange: function (args) {
      var advertiser;
      var self = this;
      this.overrideSelected = false;
      if (!self.isEdit && self.mode === "event") {
        self.dataAttributeLoaderHandler(true);
        advertiser = new Advertiser({
          id: args.id
        });
        advertiser.fetch({
          params: {
            with: ["agency"]
          },
          success: function (data) {
            var model = data;
            var agency = advertiser.get("agency");
            var advertiserParent;
            if (agency) {
              advertiserParent = agency.toJSON();
              if (agency.get("organization_id")) {
                Utils.fetchData(organizationModel, agency.get("organization_id")).then(function (org) {
                  advertiserParent.parent = org.toJSON();
                  self.dataAttributes.advertiserId = model.id;
                  self.getDataAttributes().then(function () {
                    self.loadAttributeRows();
                  });
                });
              } else {
                self.showAttributesLoadingError("Failed to load Data Attributes.");
              }
            } else {
              self.showAttributesLoadingError("Failed to load Data Attributes.");
            }
          },
          statusInvalid: function () {
            self.showAttributesLoadingError("Failed to load Data Attributes.");
          }
        });
      }
    },
    overrideAdvCustomAttributes: function () {
      this.overrideSelected = this.el.find(".override-adv-custom-attributes").val();
      this.loadAttributeRows();
    },
    showAttributesLoading: function () {
      var $attributeEl = this.el.find(".attributes-list");
      var compiledTemplate = Mustache.to_html(attributesLoadingTemplate, {});
      if (Polymer && Polymer.dom) {
        Polymer.dom($attributeEl[0]).innerHTML = compiledTemplate;
      } else {
        $attributeEl.html(compiledTemplate);
      }
      $attributeEl.find(".data-attributes-loader").show();
      $attributeEl.find(".error-msg").hide();
      $attributeEl.removeClass("hidden");
    },
    showAttributesLoadingError: function (error_msg) {
      this.dataAttributeLoaderHandler(false);
      var $attributeEl = this.el.find(".attributes-list");
      $attributeEl.find(".data-attributes-loader").hide();
      $attributeEl.find(".error-msg").html(error_msg);
      $attributeEl.find(".error-msg").show();
    },
    loadAttributeRows: function () {
      var self = this;
      if (this.whiteListOptions) {
        this.showAttributeRows();
      } else {
        self.showAttributesLoading();
        self.whiteListOptions = "";
        this.attrsWhiteList.fetch({
          success: function (data) {
            $.each(data.models, function (index, model) {
              var trimmedKey = model.attributes.key.replace(/^query\./, "");
              self.whiteList[trimmedKey] = {
                key: model.attributes.key,
                name: model.attributes.name,
                data_type: model.attributes.data_type
              };
              self.whiteListOptions += '<option value="' + trimmedKey + '">' + model.attributes.name + "</option>";
            });
            self.showAttributeRows();
          },
          statusInvalid: function () {
            self.showAttributesLoadingError("Failed to load Data Attributes.");
          }
        });
      }
    },
    showAttributeRows: function () {
      var $attributeEl = this.el.find(".attributes-list");
      var tData, compiledTemplate, $standardAttrEl;
      if (this.isAttributesViewOnlyPermission) {
        this.isAttributesViewOnly = true;
        this.canEditAttributes = false;
        this.overrideDisabled = true;
      } else if (this.canEditAttributesPermission) {
        if (this.overrideSelected) {
          this.isAttributesViewOnly = false;
          this.canEditAttributes = true;
        } else {
          this.isAttributesViewOnly = true;
          this.canEditAttributes = false;
        }
      }
      tData = {
        model: "{{model}}",
        scope: "{{scope}}",
        index: "{{index}}",
        saved: "{{model.saved}}",
        unsaved: "{{!model.saved}}",
        cId: "{{model.cId}}",
        fixedKey: "{{model.fixedKey}}",
        customKey: "{{!model.fixedKey}}",
        key: "{{model.key}}",
        name: "{{model.name}}",
        data_type: "{{model.data_type}}",
        canEditAttributes: this.canEditAttributes,
        isAttributesViewOnly: this.isAttributesViewOnly,
        overrideSelected: this.overrideSelected,
        overrideDisabled: this.overrideDisabled,
        standardAttributeDisabled: this.isAttributesViewOnly
      };
      compiledTemplate = Mustache.to_html(attributesTemplate, tData);
      if (Polymer && Polymer.dom) {
        Polymer.dom($attributeEl[0]).innerHTML = compiledTemplate;
      } else {
        $attributeEl.html(compiledTemplate);
      }
      this.attributeRepeater = Polymer.dom($attributeEl[0]).querySelector("#custom-attributes");
      this.attributeRepeater.validation = AttributeUtils.validation.bind(isGlobalAdmin);
      AttributeUtils.loadData(this, this.isAttributesViewOnly);
      $standardAttrEl = $attributeEl.find(".standard-attributes");
      $standardAttrEl.chosen({
        width: "95%",
        placeholder_text_multiple: "Select Standard Attributes",
        search_contains: true
      });
      $standardAttrEl.html(this.whiteListOptions);
      if (this.savedStandardAttributes) {
        $standardAttrEl.val(Object.keys(this.savedStandardAttributes));
      }
      $standardAttrEl.trigger("liszt:updated");
      this.el.find(".override-adv-custom-attributes").show();
      this.el.find(".attributes-list").removeClass("hidden");
      this.dataAttributeLoaderHandler(false);
    },
    getDataProviders: function (agencyId) {
      var self = this;
      var deferred = When.defer();
      var collRef, filtered, providerAgencyId;
      collRef = new DataProviders(null, {
        isPaginated: false
      });
      collRef.fetchOptions = $.extend({
        full: "*"
      }, collRef.fetchOptions);
      collRef.fetch({
        success: function (coll) {
          filtered = _.chain(coll.models).filter(function (provider) {
            providerAgencyId = provider.get("agency_id");
            return providerAgencyId === undefined || providerAgencyId === agencyId;
          }).sortBy(function (model) {
            return model.get("name");
          }).value();
          self.dataProviderCollection = new DataProviders(filtered);
          deferred.resolve(self.dataProviderCollection);
        }
      });
      return deferred.promise;
    },
    initLayout: function (templateBody) {
      var self = this;
      var model = self.model;
      var orgID = self.orgID;
      var advertiserID = model.get("advertiser_id");
      var dataproviderID = model.get("provider_id");
      var agencyID = model.get("agency_id");
      var eventPixelLayout;
      var dataPixelEditLayout = {};
      eventPixelLayout = {
        ".event-url-list": [{
          module: "shared",
          viewType: "list",
          options: {
            title: "Event URLs <span class='optional'>(Optional)</span>",
            addLabel: "+ Add URL",
            fieldName: "url",
            initialFieldCount: 3,
            maxLength: 10,
            placeHolder: "e.g. https://www.sitename.com",
            collection: self.eventUrlCollection
          }
        }],
        ".pixel-list": [{
          module: "shared",
          viewType: "list",
          options: {
            title: "Add Pixels to Container <span class='optional'>(Optional)</span>",
            addLabel: "+ Add Pixel",
            fieldName: "tag",
            initialFieldCount: 3,
            maxLength: 10,
            collection: self.pixelListCollection
          }
        }]
      };
      if (!self.isEdit) {
        eventPixelLayout[".advertiser-select"] = [{
          module: "shared",
          viewType: "select",
          options: {
            entity: "advertisers",
            selectedId: advertiserID,
            dataBind: "advertiser_id",
            boundEntity: "organizations",
            boundEntityId: orgID,
            isSearchable: true,
            clearOnClose: true,
            initialLoad: true,
            selectEvent: "advertiserChange",
            placeholder: "Select an Advertiser",
            preselect: "Select an Advertiser",
            notFound: "No Advertisers Found!",
            defaultSortParams: {
              fields: {
                name: {
                  actual: "name",
                  display: "alphabetically"
                }
              },
              default: {
                sort_by: "name",
                order_by: "ascending"
              }
            }
          }
        }];
        dataPixelEditLayout = {
          ".agency-select": [{
            module: "shared",
            viewType: "select",
            options: {
              entity: "agencies",
              selectedId: agencyID,
              dataBind: "agency_id",
              boundEntity: "organizations",
              boundEntityId: orgID,
              boundEntityHeirarchy: ["organization", "agency"],
              isSearchable: true,
              clearOnClose: true,
              initialLoad: true,
              placeholder: "Select an Agency",
              preselect: "Select an Agency",
              notFound: "No Agencies Found!",
              selectEvent: "selectAgency",
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              }
            }
          }],
          ".dataprovider-select": [{
            module: "shared",
            viewType: "select",
            options: {
              entity: "pixel_providers",
              selectedId: dataproviderID,
              boundEntity: "agencies",
              boundEntityHeirarchy: ["organization", "agency", "pixel_provider"],
              boundEntityId: agencyID,
              dataBind: "provider_id",
              collection: self.dataProviderCollection,
              isSearchable: true,
              clearOnClose: true,
              initialLoad: false,
              refreshEvent: "agencySelected",
              isLocalCollection: true,
              placeholder: "Select a Dataprovider",
              preSelect: "Select an Agency First",
              notFound: "No Dataproviders Found!",
              disableCheck: function (count, hasId) {
                return !hasId;
              },
              defaultSortParams: {
                fields: {
                  name: {
                    actual: "name",
                    display: "alphabetically"
                  }
                },
                default: {
                  sort_by: "name",
                  order_by: "ascending"
                }
              }
            }
          }]
        };
      }
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-body",
        template: templateBody,
        partials: self.partials,
        serialize: $.proxy(self.serialize, self),
        layout: self.mode === "event" ? eventPixelLayout : dataPixelEditLayout
      });
    },
    load: function () {
      var self = this;
      if (!this.canLoad) {
        return;
      }
      this.setFormActions();
      this.render().then(function () {
        self.layout.load().then(function () {
          self.renderPlugins();
        });
      });
    },
    renderPlugins: function (context) {
      var self = this;
      var model = self.model;
      var $implementationRadio, $currencyTypeRadio, $revenue;
      context = context || self.el;
      if (!self.isEdit) {
        if (self.mode === "event") {
          $implementationRadio = context.find("strand-group[group='implementationGroup']");
          $implementationRadio.on("selected", function (e) {
            var type = $(e.originalEvent.detail.item).attr("value");
            self.togglePixelist(context, type);
          });
          self.togglePixelist(context, "js");
        }
      } else {
        self.togglePixelist(context, model.get("tag_type"));
      }
      if (self.showAttributionPair) {
        $currencyTypeRadio = context.find("strand-group[group='currencyTypeGroup']");
        $currencyTypeRadio.on("selected", function (e) {
          self.currencyType = $(e.originalEvent.detail.item).attr("value");
          self.renderPartialTemplate({
            template: currency_ddl_template,
            useTemplateHtml: false,
            context: context,
            targetEl: ".currency_ddl"
          });
          self.updatePartialTemplate(context);
        });
        setTimeout(function () {
          $revenue = context.find(".select-revenue");
          $revenue.on("selected", function (e) {
            self.removeTooltip(e);
            self.isFormElementsChanged = false;
          });
        }, 0);
      }
      if (self.dataAttributes.advertiserId || self.dataAttributes.pixelId) {
        self.loadAttributeRows();
      }
      T1.Tooltip(context, self.tipConfig);
      self.activateInputMasks();
      self.updatePartialTemplate(context);
    },
    updatePartialTemplate: function (context) {
      var self = this;
      var T1Selectbox = T1.Selectbox;
      if (self.currencyType === "FIXED") {
        T1Selectbox(context.find(".select-currency-fixed"), {
          searchFieldDisabled: false,
          searchInputPlaceHolder: "Search Currency"
        });
      }
    },
    togglePixelist: function (context, type) {
      var $pixelListContainer = context.find(".pixelListContainer");
      var $eventUrlListContainer = context.find(".event-url-list").parent();
      if (type === "js" || type === "iframe") {
        $eventUrlListContainer.removeClass("eventUrlListContainer");
        $pixelListContainer.slideDown();
        $pixelListContainer.removeAttr("data-bind");
      } else {
        $eventUrlListContainer.addClass("eventUrlListContainer");
        $pixelListContainer.slideUp();
        $pixelListContainer.attr("data-bind", "hidden");
      }
    },
    toggleDataProviderMessage: function (show) {
      var self = this;
      var $infoBox = $(self.el).find(".info-box-holder-dataprovider");
      if (show) {
        self.isInfoBoxShowing = true;
        $infoBox.slideDown(300);
      } else {
        self.isInfoBoxShowing = false;
        $infoBox.slideUp(300);
      }
    },
    agencyChangeHandler: function (data) {
      var self = this;
      T1.EventHub.publish("dropdown:agencySelected", {
        preventFetch: true
      });
      this.layout.layout[".dataprovider-select"][0].view.reset();
      self.togglePctMedia(false);
      self.getDataProviders(data.id).then(function (collection) {
        T1.EventHub.publish("dropdown:agencySelected", {
          id: data.id,
          collection: collection,
          preventFetch: true
        });
      });
    },
    dataProviderChangeHandler: function (data) {
      var self = this;
      self.model.set({
        pixel_provider: data.model,
        provider_id: data.id
      });
      self.togglePctMedia(data.model.get("execution_by") === "MEDIAMATH");
      self.toggleDataProviderMessage(true);
      self.updateDataBind(self.serialize(), self.el);
    },
    togglePctMedia: function (condition) {
      var pctMediaRadio = this.el.find(".pct-media-radio");
      var pctMediaVal = this.el.find(".cost_pct_cpm");
      var cpmRadio = this.el.find(".cpm-radio");
      if (condition) {
        cpmRadio.find(".radio").click();
        cpmRadio.hide();
        pctMediaRadio.hide();
        pctMediaVal.hide();
      } else {
        cpmRadio.show();
        pctMediaRadio.show();
        pctMediaVal.show();
      }
    },
    listChangedHandler: function (isChanged) {
      this.isFormElementsChanged = isChanged;
    },
    onPricingTypeClicked: function (e) {
      var $target = $(e.target);
      var $parent;
      var pricingTypes = ["cost_cpm", "cost_pct_cpm"];
      var pricingType = e.target.value;
      $parent = $target.closest("dl");
      function toggleDisabled(type, isDisabled) {
        $parent.find(".form-input." + type).toggleClass("disabled", isDisabled).find("input[type=text]").attr("disabled", isDisabled).attr("data-bind", isDisabled ? "hidden" : type);
      }
      $.each(pricingTypes, function (index, value) {
        toggleDisabled(value, value !== pricingType);
      });
    },
    onToggleAttribution: function (e) {
      var self = this;
      var $el = $(self.el);
      var $target = $(e.target);
      var isChecked = $target.is(":checked");
      var $attributionUI, $components, $currencyDDL;
      this.isRevenueAttributionChecked = isChecked;
      $attributionUI = $el.find(".attributionPair");
      $attributionUI.find("dd:first").toggleClass("disabled");
      $components = $attributionUI.find("strand-dropdown, strand-group, select");
      $currencyDDL = $attributionUI.find("select");
      isChecked ? $components.removeAttr("disabled") : $components.attr("disabled", true);
      if ($currencyDDL) {
        $currencyDDL.trigger("liszt:updated");
      }
    },
    preparePostData: function (formValues) {
      var self = this;
      var model = self.model;
      formValues.pixel_type = model.get("pixel_type");
      if (formValues.pixel_type === "event") {
        formValues.eventUrls = self.parseUrlList(self.eventUrlCollection, "url");
        formValues.pixelList = self.parseUrlList(self.pixelListCollection, "tag");
        formValues.isRevenueAttributionChecked = self.isRevenueAttributionChecked;
        formValues.currencyType = self.currencyType;
        if (formValues.isRevenueAttributionChecked) {
          if (formValues.currency_fixed && model.get("currency")) {
            formValues.currency = "";
          }
          if (formValues.currency && model.get("currency_fixed")) {
            formValues.currency_fixed = "";
          }
        } else {
          formValues.revenue = "";
          formValues.currency = "";
          formValues.currency_fixed = "";
        }
      } else {
        formValues.tag_type = "image";
        formValues.cost_cpm = formValues.pricingType === "cost_pct_cpm" ? "" : formValues.cost_cpm;
        formValues.cost_pct_cpm = formValues.pricingType === "cost_cpm" ? "" : formValues.cost_pct_cpm;
      }
      return formValues;
    },
    parseUrlList: function (inCollection, validationField) {
      var parsedList = [];
      var valueList = [];
      var fieldValue, index, outModel;
      if (inCollection.models.length) {
        parsedList = _.chain(inCollection.models).map(function (item) {
          fieldValue = item && item.get(validationField);
          if (item && fieldValue !== undefined) {
            index = valueList.indexOf(fieldValue);
            if (index === -1) {
              valueList.push(fieldValue);
            }
            if (item.id && item.get("action") === "delete" || index === -1) {
              if (!item.id) {
                item.set({
                  id: item.cid
                });
              }
              outModel = new inCollection.model({});
              outModel.set(item.toJSON());
              return outModel;
            }
          }
        }).compact().value();
      }
      return parsedList.reverse();
    },
    postData: function (formValues, success, statusInvalid, conflict) {
      var self = this;
      var model = self.model;
      var $el = $(self.el);
      var $header = $el.find("#main-header");
      var $radioGroup = $el.find('strand-group[group="currencyTypeGroup"]');
      var attributeRepeater = this.attributeRepeater;
      var canSubmit = attributeRepeater ? attributeRepeater.validate() : true;
      if (canSubmit) {
        $radioGroup.attr("disabled", true);
        model.preventDelete = true;
        model.createEdit(formValues, {
          success: function (model) {
            AttributeUtils.saveAttributes.call(self, self.attributeRepeater).then(function () {
              if ($(self.el).find(".form-footer").data("saveAndContinueMode")) {
                T1.Notify("message", "Pixel " + (self.isEdit ? "updated" : "created") + " successfully!");
                success(model);
              } else {
                if (self.isEdit) {
                  self.updateModels({
                    id: model.id
                  }).then(function (model) {
                    self.model = model;
                    $radioGroup.removeAttr("disabled");
                    self.isFormElementsChanged = false;
                    self.renderPlugins();
                    T1.Notify("message", "Pixel " + (self.isEdit ? "updated" : "created") + " successfully!");
                    success(model);
                    self.isFormElementsChanged = false;
                    updateBreadCrumb(model.toJSON());
                    Polymer.dom($header[0]).innerHTML = formValues.name;
                    self.model.preventDelete = false;
                  });
                } else {
                  $radioGroup.removeAttr("disabled");
                  T1.Notify("message", "Pixel " + (self.isEdit ? "updated" : "created") + " successfully!");
                  success(model);
                  self.isFormElementsChanged = false;
                  updateBreadCrumb(model.toJSON());
                  Polymer.dom($header[0]).innerHTML = formValues.name;
                  self.model.preventDelete = false;
                }
              }
            }, function (message, errorType) {
              if (self.isEdit) {
                self.updateModels({
                  id: model.id
                }).then(function (model) {
                  self.model = model;
                  $radioGroup.removeAttr("disabled");
                  self.isFormElementsChanged = false;
                  self.renderPlugins();
                  var errorMessage = errorType === "failure" ? "attribute creation failed" : "some attributes did not save";
                  statusInvalid([], message, ".form-body");
                  T1.Notify(errorType === "failure" ? "error" : "warning", "Pixel " + (self.isEdit ? "updated" : "created") + " successfully, but " + errorMessage);
                  self.isFormElementsChanged = false;
                  updateBreadCrumb(model.toJSON());
                  Polymer.dom($header[0]).innerHTML = formValues.name;
                  self.model.preventDelete = false;
                });
              } else {
                var errorMessage = errorType === "failure" ? "attribute creation failed" : "some attributes did not save";
                statusInvalid([], message, ".form-body");
                T1.Notify(errorType === "failure" ? "error" : "warning", "Pixel " + (self.isEdit ? "updated" : "created") + " successfully, but " + errorMessage);
                self.isFormElementsChanged = false;
                updateBreadCrumb(model.toJSON());
                Polymer.dom($header[0]).innerHTML = formValues.name;
                self.model.preventDelete = false;
                $radioGroup.removeAttr("disabled");
              }
            });
          },
          statusInvalid: function (errors) {
            var urlErrors = _.where(errors, {
              field: "url"
            });
            var tagErrors = _.where(errors, {
              field: "tag"
            });
            var $target, errorsExist;
            function displayListErrors(displayErrors) {
              if (displayErrors && displayErrors.length) {
                $.each(displayErrors, function (index, error) {
                  $target = $el.find("strand-input[data-id='" + error.id + "']");
                  if ($target.length) {
                    self.applyTooltip($target, error.message);
                    $target[0].error = true;
                    errorsExist = true;
                  }
                });
              }
            }
            displayListErrors(urlErrors);
            displayListErrors(tagErrors);
            statusInvalid(errors, null, ".form-body");
            if (errorsExist || errors.length) {
              self.updateFooterMessage({
                error: true
              });
            }
          },
          conflict: conflict,
          error: function (data, response) {
            var jsonData = T1.Utils.parseXMLtoJson($.parseXML(response.responseText));
            var errors;
            if (jsonData.errors && jsonData.errors.field_error) {
              errors = !$.isArray(jsonData.errors.field_error) ? [jsonData.errors.field_error] : jsonData.errors.field_error;
              errors = _.map(errors, function (error) {
                return {
                  field: error.name,
                  message: error.error
                };
              });
              statusInvalid(errors, null, ".form-body");
            }
          }
        });
      } else {
        statusInvalid([], null, ".form-body");
      }
    },
    saveFromAttributeWarning: function (e) {
      this.modal.hide();
      if ($(this.el).find(".form-footer").data("saveAndContinueMode")) {
        e.currentTarget = this.el.find(".saveAndClose")[0];
        this.saveOriginal(e, this.saveCallback);
      } else {
        e.currentTarget = this.el.find(".btn-primary")[0];
        this.saveOriginal(e);
      }
    },
    saveOverride: function (e, callback) {
      var canSubmit;
      var self = this;
      if (!this.canViewSegments || !this.isEdit || this.mode !== "event" || !this.attributeRepeater) {
        if (callback) {
          this.saveOriginal(e, callback);
        } else {
          this.saveOriginal(e);
        }
      } else {
        canSubmit = this.attributeRepeater ? this.attributeRepeater.validate() : true;
        if (canSubmit) {
          if (callback) {
            this.saveCallback = callback;
            this.saveOriginal(e, callback);
          } else {
            this.saveOriginal(e);
            this.saveCallback = null;
          }
        } else {
          $(".w-FormUnloadAlert .cancel").click();
          self.updateFooterMessage({
            error: true
          });
          if (e.currentTarget.tagName === "BUTTON") {
            this.stopSpinner($(".form-footer .save")[0]);
          }
        }
      }
    },
    closeWarning: function () {
      AttributeUtils.closeWarning.call(this);
      this.saveCallback = null;
    },
    undoRemoveAttribute: function () {
      AttributeUtils.undoDeleteAttribute.call(this);
      this.closeWarning();
    },
    showDeletedAttributeWarning: function (warningTemplate) {
      AttributeUtils.showDeletedAttributeWarning.call(this, warningTemplate);
    },
    removeAttributeHandler: function () {
      AttributeUtils.removeAttributeHandler(this);
    },
    changeStandardAttributeHandler: function (event, params) {
      var deselectedKey, deSelectedAttribute;
      this.isFormElementsChanged = true;
      if ("deselected" in params) {
        deselectedKey = params.deselected;
        if (deselectedKey in this.savedStandardAttributes) {
          deSelectedAttribute = this.savedStandardAttributes[deselectedKey];
          AttributeUtils.removeAttributeHandler(this, deSelectedAttribute);
        }
      }
    },
    setFormChangeCheckbox: function () {
      this.isFormElementsChanged = true;
    },
    onVersionConflictClose: function () {
      this.initializeData(this);
    },
    saveAndClose: function (e) {
      var callback = {
        success: function () {
          setTimeout(function () {
            T1.Location.set("#audiences/pixels/");
          }, 1e3);
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    saveAndNew: function (e) {
      var self = this;
      var callback = {
        success: function () {
          self.isEdit = false;
          self.unload();
          self.initializeData();
          T1.Location.set("#audiences/pixels/" + self.mode + "/create");
        }
      };
      this.setSaveAndContinueMode(true);
      return this.save(e, callback);
    },
    showUnloadAlertDiag: function (customRedirect) {
      var tagType = this.mode === "event" ? "Event Pixel" : "Data Pixel";
      var message = this.isEdit ? "Edit " + tagType : "Add " + tagType;
      this.unloadDialogShown = true;
      this.openUnloadAlertDialog({
        name: message
      }, customRedirect, true);
    },
    unload: function () {
      var self = this;
      function sanitizeList(prop) {
        var coll = self.model.get(prop);
        var output = {};
        if (coll) {
          coll = coll.filter(function (item) {
            return item && item.get("action") !== "new";
          });
        }
        output[prop] = coll;
        self.model.set(output);
      }
      $(".nav-list, .active, .logout").unbind();
      if (this.customRedirect) {
        T1.Location.resetAbort();
      }
      if (this.model) {
        sanitizeList("pixel_bundle_url");
        sanitizeList("pixel");
      }
    },
    canUnload: function (args) {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      if (!isFormElementsChanged) {
        if (args === true) {
          self.cancelPixelViewEdit.onAlertClose();
        }
      } else {
        self.showUnloadAlertDiag(args === true ? self.cancelPixelViewEdit : args);
      }
      return !isFormElementsChanged;
    },
    cancelPixelViewEdit: {
      onAlertClose: function () {
        var abortedUrl = T1.Location.getAbortedURL();
        if (abortedUrl === "" || abortedUrl === false) {
          setTimeout(function () {
            T1.Location.set("#audiences/pixels");
          }, 1);
        } else {
          T1.Location.set(abortedUrl);
        }
      }
    },
    bindUnsavedChanges: function () {
      var self = this;
      $(".nav-list, .logout").unbind().click(function (e) {
        var path = e.target.hash;
        e.preventDefault();
        if (!self.canUnload()) {
          T1.Location.setAbortedURL(path);
          self.redirectSet = true;
        } else {
          T1.Location.resetAbort();
          T1.Location.set(path);
        }
      });
    },
    cancelUnload: function () {
      var orgs = Organizations.getOrganizations();
      var existingOrg = this.orgID;
      orgs.getSelected().then(function (id) {
        if (existingOrg.toString() !== id.toString()) {
          orgs.setSelected(existingOrg, {
            implicitChange: true
          });
        }
      });
      this.unloadDialogShown = false;
      T1.EventHub.publish("pixel-create-edit:cancelUnload");
    },
    onDontSave: function () {
      this.model.reset();
    },
    getAgencyAndAdvertiserName: function () {
      var advertiser;
      var agency;
      var agencyDisplay;
      if (this.mode === "event") {
        advertiser = this.model && this.model.get ? this.model.get("advertiser") : null;
        if (advertiser && advertiser.get) {
          agency = advertiser.get("agency");
          agencyDisplay = agency ? agency.get("name") + "&nbsp;&raquo;&nbsp;" : "";
          return agencyDisplay + advertiser.get("name");
        }
      } else {
        agency = this.model && this.model.get ? this.model.get("agency") : null;
        return agency && agency.get ? agency.get("name") : null;
      }
    },
    serialize: function () {
      var model = this.model;
      var output = model.toJSON();
      output.isEdit = this.isEdit;
      output.pixelType = getPixelType(output);
      output.implementation = getEventPixelTypeName(output.tag_type);
      output.isJSorIFRAMEContainer = output.tag_type === "js" || output.tag_type === "iframe";
      output.showNonJs = this.showNonJs;
      output.showAttributionPair = this.showAttributionPair;
      output.isRevenueAttributionChecked = this.isRevenueAttributionChecked;
      output.isCurrencyTypeFixed = this.currencyType === "FIXED";
      output.currencyList = T1.Utils.getCurrencyList({
        selectedId: output.currency_fixed || this.orgCurrencyCode
      });
      if (output.pixel_provider) {
        output.billing = output.pixel_provider.execution_by.toLowerCase() === "mediamath" ? "MediaMath pays data " + "provider (T1 client invoiced by MediaMath)" : "T1 Client pays data provider directly (T1 client is not " + "invoiced by MediaMath)";
        output.executionBy = output.pixel_provider.execution_by.toLowerCase() === "mediamath" ? false : true;
      } else {
        output.billing = "--";
        output.executionBy = true;
      }
      if (!this.isEdit && this.mode === "data") {
        output.msgForDataProviderChange = {
          infoBoxMessageLines: ['Note: By selecting "T1 Client pays MediaMath", the T1 Client will pay MediaMath for Data Pixel costs ' + "and MediaMath will relay payment to the Data Provider. Alternatively, selecting a Data Provider labeled " + '"T1 Client pays vendor directly" means the T1 Client assumes responsibility to pay the Data Provider ' + "directly and will not be billed by MediaMath.", " ", "Next Step: Please generate code for this Data Pixel and implement with your data partner."]
        };
      }
      output.isCPM = output.cost_cpm !== undefined || !this.isEdit;
      if (output.isEdit) {
        output.advertiser_name = output.advertiser ? output.advertiser.name : "ADVERTISER";
        output.agency_name = output.agency ? output.agency.name : "AGENCY";
        output.dataprovider_name = output.pixel_provider ? output.pixel_provider.name : "PROVIDER";
        output.agencyAndAdvertiser = this.getAgencyAndAdvertiserName();
      }
      return output;
    }
  });
  PixelCreateEdit = T1.Form.ViewUtils.extend(PixelCreateEdit);
  PixelCreateEdit.prototype.saveOriginal = PixelCreateEdit.prototype.save;
  PixelCreateEdit.prototype.save = PixelCreateEdit.prototype.saveOverride;
  return PixelCreateEdit;
});
