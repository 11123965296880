define('utils/providers/VendorProvider',["models/vendor", "When", "utils/ExpiringCache", "utils/PromiseProvider"], function (Vendor, When, ExpiringCache, PromiseProvider) {
  var EXPIRATION_TIME = 5e3;
  var vendorDataPromiseCache = new ExpiringCache(EXPIRATION_TIME);
  var vendorPromiseFactory = function (id) {
    var deferred = When.defer();
    var vendor = new Vendor({
      id: id
    });
    vendor.fetch().then(function () {
      deferred.resolve(vendor);
    }, deferred.reject);
    return deferred.promise;
  };
  var VendorProvider = function (id) {
    PromiseProvider.call(this, id, vendorDataPromiseCache, vendorPromiseFactory, id);
  };
  VendorProvider.prototype = new PromiseProvider(null, vendorDataPromiseCache, vendorPromiseFactory, null);
  return VendorProvider;
});
