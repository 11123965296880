define('modules/T1App/edit/views/tabs',["jQuery", "Underscore", "T1", "T1View", "T1Permissions", "text!../templates/tabs.html", "text!../templates/header.html", "T1TabLayoutV2", "../models/editModel", "models/sessionUser", "collections/t1AppEdit", "collections/t1AppDevTeam"], function ($, _, T1, T1View, T1Permissions, template, headTemplate, T1TabLayout, Model, User, T1AppEditCollection, T1AppDevTeamCollection) {
  "use strict";

  var T1Location = T1.Location;
  return T1View.extend({
    model: new Model(),
    template: template,
    partials: {
      header: headTemplate
    },
    eventhubEvents: {
      "tab:select": "saveSelectedTab"
    },
    fetchOptions: {
      appID: null,
      precision: 2,
      urlString: ""
    },
    initialize: function () {
      var self = this;
      var locationHashArray = T1Location.get(true).split("/");
      var appID = locationHashArray[2];
      var user = User.getUser().toJSON();
      var userID = parseInt(user.id);
      var isGlobalAdmin = user.type === "INTERNAL" && user.role === "ADMIN" && user.scope === "GLOBAL";
      var isReporter = user.role === "REPORTER";
      var foundDev = false;
      this.collection = new T1AppEditCollection();
      this.devteam = new T1AppDevTeamCollection();
      if (isGlobalAdmin) {
        this.initLayout();
      } else if (isReporter) {
        T1.Location.set("#appstore");
      } else {
        this.devteam.fetch({
          appID: appID,
          success: function (data) {
            if (data.meta === "success") {
              $.each(data.data, function (index, devuser) {
                if (devuser.user_id === userID) {
                  self.initLayout();
                  foundDev = true;
                }
              });
              if (!foundDev) {
                T1.Location.set("#appstore");
              }
            } else {
              T1.Location.set("#appstore");
            }
          },
          error: function () {
            T1.Location.set("#appstore");
          }
        });
      }
    },
    initLayout: function () {
      var self = this;
      var locationHashArray = T1Location.get(true).split("/");
      var appID = locationHashArray[2];
      var model = this.model;
      var tabs = [{
        label: "Meta",
        module: "T1App/edit/meta",
        routeName: "meta",
        viewType: "meta"
      }, {
        label: "Versioning",
        module: "T1App/edit/version",
        routeName: "version",
        viewType: "version"
      }, {
        label: "Team",
        module: "T1App/edit/team",
        routeName: "team",
        viewType: "team"
      }];
      this.fetchOptions.appID = appID;
      this.collection.fetch({
        appID: this.fetchOptions.appID,
        success: function (data) {
          if (data.meta === "success") {
            if (data.data[0].deploy_status === "restricted") {
              tabs.push({
                label: "Access",
                module: "T1App/edit/access",
                routeName: "access",
                viewType: "acess"
              });
            }
            self.model.setData(data);
            self.layout = new T1TabLayout({
              tabSetName: "AppList",
              level: 1,
              disabled: [],
              tabs: tabs,
              selected: model.get("mainTabSectionId") || 0
            });
            self.loadTabs();
          } else {
            T1.Location.set("#appstore");
          }
        }
      });
    },
    loadTabs: function () {
      var self = this;
      this.render().then(function () {
        self.layout.el = self.el.find(".apps-edit-tabs");
        self.layout.load();
      });
    },
    saveSelectedTab: function (opts) {
      var selectedLabel = opts.tabLabel;
      var tabs = this.layout.tabCfgs.tabs;
      var selectedIndex = $.inArray(_.find(tabs, function (tab) {
        return tab.label === selectedLabel;
      }), tabs);
      this.model.setMainTabSection(selectedIndex);
    },
    canUnload: function () {
      return this.layout ? this.layout.canUnload() : true;
    },
    serialize: function () {
      var appData = this.model.get("appData");
      var reviewStatus = this.model.get("reviewStatus");
      var reviewComments = this.model.get("reviewComments");
      return {
        title: appData ? appData : "Edit",
        reviewRejected: reviewStatus === "rejected" ? true : false,
        reviewComments: reviewComments,
        subTitle: false
      };
    }
  });
});
