define('modules/targeting/video/views/browse',["jQuery", "T1", "T1View", "text!../templates/grid_item.html", "text!../templates/grid_subitem.html", "text!../templates/grid_footer.html", "modules/targeting/shared/itemSelectionBehavior", "T1GridView", "T1Spinner"], function ($, T1, T1View, gridItemTemplate, gridSubItemTemplate, gridFooter, ItemSelectionBehavior, createGrid, Spinner) {
  "use strict";

  var highlightText = T1.Utils.boldLastPartOfFullPath;
  var VideoTargetingBrowseView = T1View.extend({
    partials: {
      listItem: gridItemTemplate,
      gridSubItem: gridSubItemTemplate,
      gridFooter: gridFooter
    },
    headers: [{
      name: "type",
      label: "type"
    }, {
      name: "video",
      label: "video targeting"
    }, {
      name: "audio",
      label: "audio targeting"
    }],
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    initialize: function ({
      filterConfigs: filterConfigs,
      sharedCollection: sharedCollection
    }) {
      var self = this;
      const targetDimensions = filterConfigs.filterOptions;
      var initData = [];
      this.targetDimensions = {};
      $.each(targetDimensions, function (index, config) {
        initData.push({
          name: config.text,
          dimension_code: config.value,
          length: true,
          isAudioEnabled: config.value === "VSKP"
        });
        self.targetDimensions[config.value] = config.text;
      });
      this.initData = initData;
      this.sharedCollection = sharedCollection;
      this.viewType = "browse";
      ItemSelectionBehavior.attachBehavior(this);
    },
    draggableConfigs: {
      allowDrag: true,
      allowMultipleSelection: true,
      handlerTextEl: ".type"
    },
    events: {
      "click .item": "toggleNode",
      "click .item .icon.include": "includeTarget",
      "click .item .icon.exclude": "excludeTarget"
    },
    itemSelectionBehaviorConfigs: {
      parentIdKey: "parent_value_id",
      selectionAllowableKey: null,
      selectionAllowableValue: null,
      collectionKey: "collection"
    },
    eventhubEvents: {
      "bins:droppedVideoTargeting": function ({
        selectedList: selectedList
      }) {
        const {
          collection: collection,
          sharedCollection: sharedCollection,
          targetDimensions: targetDimensions
        } = this;
        var self = this;
        function addTargetValue(id, action) {
          $.each(targetDimensions, function (key) {
            var targetValue = sharedCollection.get(key).get("target_value").get(id);
            if (!targetValue) {
              targetValue = collection.get(id);
            }
            if (targetValue && targetValue.get("action") !== action) {
              self.addTarget(targetValue, action);
            }
          });
        }
        for (let i = 0; i < selectedList.length; i++) {
          addTargetValue(selectedList[i].id, selectedList[i].action);
        }
        T1.EventHub.publish("change:formStatus", true);
      }
    },
    dataEvents: {
      collection: {
        search: "search",
        "search.clear": "search",
        reset: "reload"
      },
      sharedCollection: {
        "add:target_value": function (model) {
          this.sharedCollection.add(model);
          if (!model.get("parent")) {
            this.sharedCollectionAddHandler(model);
          }
        },
        "remove:target_value": function (model) {
          this.sharedCollection.remove(model);
          if (!model.get("parent")) {
            this.sharedCollectionRemoveHandler(model);
          }
        }
      }
    },
    includeTarget: function (evt) {
      var id = $(evt.currentTarget).closest(".item").attr("data-id");
      var item = this.collection.get(id);
      evt.stopPropagation();
      evt.preventDefault();
      this.removeDragClasses();
      const name = item.get("dimension_code") === "VSKP" ? item.get("name").split(" ")[0] : item.get("name");
      let fullPath = this.getFullPath(item);
      item.set({
        name: name
      });
      fullPath = fullPath.substring(0, fullPath.lastIndexOf(name));
      this.renderPartial(".w-foot", {
        browse_path: fullPath,
        browse_path_end: item.get("name")
      });
      this.addTarget(item, "include");
      T1.EventHub.publish("change:formStatus", true);
    },
    excludeTarget: function (evt) {
      var id = $(evt.currentTarget).closest(".item").attr("data-id");
      var item = this.collection.get(id);
      evt.stopPropagation();
      evt.preventDefault();
      this.removeDragClasses();
      const name = item.get("dimension_code") === "VSKP" ? item.get("name").split(" ")[0] : item.get("name");
      let fullPath = this.getFullPath(item);
      item.set({
        name: name
      });
      fullPath = fullPath.substring(0, fullPath.lastIndexOf(name));
      this.renderPartial(".w-foot", {
        browse_path: fullPath,
        browse_path_end: item.get("name")
      });
      this.addTarget(item, "exclude");
      T1.EventHub.publish("change:formStatus", true);
    },
    addTarget: function (item, action) {
      let lastAction;
      const targetValues = this.sharedCollection.get(item.get("dimension_code")).get("target_value");
      const itemInSharedCollection = targetValues.get(item.id);
      if (itemInSharedCollection) {
        lastAction = itemInSharedCollection.get("action");
        targetValues.remove(itemInSharedCollection);
      }
      if (lastAction !== action) {
        item.set({
          action: action
        });
        targetValues.add(item);
      }
    },
    unload: function () {
      delete this.searchTerm;
      this.viewType = "browse";
      this.collection.search.clear();
    },
    toggleNode: function (event) {
      var self = this;
      var item = $(event.currentTarget);
      const idVal = item.data && item.data("id") || "";
      const id = $.isNumeric(idVal) ? idVal : undefined;
      var collection = this.collection;
      var arrow = item.find(".arrow");
      var iconArrow = arrow.find(".icon-arrow");
      var iconSpinner = arrow.find(".icon-spinner");
      var state = iconArrow.hasClass("expanded");
      const hasparent = item.parents(".item");
      if (!arrow.length || arrow.hasClass("hidden")) {
        return;
      }
      this.preventDefault(event);
      const children = item.closest(".item-wrapper").children(".children");
      if (!state) {
        const code = item.data("dimension_code");
        iconArrow.addClass("expanded");
        children.show();
        if (!children.children().length) {
          iconArrow.addClass("loading hide");
          iconSpinner = arrow.find(".icon-spinner");
          Spinner.apply(iconSpinner, this.spinnerOpts);
          collection.fetchOptions.dimension = code;
          if (id) {
            collection.fetchOptions.parent = id;
          } else if (collection.fetchOptions.parent) {
            delete collection.fetchOptions.parent;
          }
          collection.removeSort("name");
          this.dimension_code = code;
          this.id = id;
          this.parent = Boolean(hasparent.length);
          collection.fetch({
            silent: true,
            add: true,
            success: function () {
              var data;
              iconSpinner.spin(false);
              iconArrow.removeClass("loading hide");
              data = {
                list: self.mapItems(collection.models)
              };
              self.renderPartialTemplate({
                template: self.partials.gridSubItem,
                useTemplateHtml: false,
                action: "html",
                targetEl: children,
                data: data
              });
              self.updateScrollBar();
              self.applyDraggable();
            }
          });
        } else {
          this.updateScrollBar();
        }
      } else {
        children.hide();
        iconArrow.removeClass("expanded");
        this.updateScrollBar();
      }
    },
    search: function (data) {
      var collection = this.collection;
      this.dimension_code = "";
      if (data) {
        const {
          term: term,
          type: type
        } = data;
        this.viewType = "search";
        this.searchTerm = term;
        collection.fetchOptions.dimension = type;
        collection.search.set({
          params: [{
            searchField: "name",
            term: `*${term}*`
          }],
          fetchRequired: false
        });
        this.setSearchLoader();
        collection.fetch();
      } else {
        collection.search.clear();
        this.viewType = "browse";
        collection.reset([]);
      }
    },
    getFullPath: function (model, path = "") {
      function pathBuilder() {
        return path ? `${model.get("name")} &raquo; ${path}` : model.get("name");
      }
      if (model) {
        if (model.get("parent_value_id")) {
          path = this.getFullPath(this.collection.get(model.get("parent_value_id")), pathBuilder());
        } else {
          path = `${this.targetDimensions[model.get("dimension_code")]} &raquo; ${pathBuilder()}`;
        }
      }
      return path;
    },
    mapItems: function (models) {
      let targetValues;
      const {
        getFullPath: getFullPath,
        searchTerm: searchTerm,
        sharedCollection: sharedCollection,
        viewType: viewType
      } = this;
      var self = this;
      var modelsToSerialize = [];
      var result = [];
      if (viewType === "browse") {
        $.each(models, function (index, model) {
          if (model.get("dimension_code") === self.dimension_code) {
            if (!self.id && !model.get("parent_value_id")) {
              modelsToSerialize.push(model);
            } else {
              if (self.id === Number(model.get("parent_value_id"))) {
                modelsToSerialize.push(model);
              }
            }
          }
        });
      } else {
        modelsToSerialize = models;
      }
      $.each(modelsToSerialize, function (index, model) {
        let action;
        targetValues = targetValues || sharedCollection.get(model.get("dimension_code")).get("target_value");
        const sharedItem = targetValues.get(model.id);
        if (sharedItem) {
          action = sharedItem.get("action");
        }
        const item = model.toJSON();
        self.applySelectionStates(item, this.collection);
        item.length = Number(model.get("child_count")) > 1;
        item.isIncluded = action === "include";
        item.isExcluded = action === "exclude";
        item.isActive = action !== undefined;
        item.name = item.dimension_code === "VSKP" ? item.name.split(" ")[0] : item.name;
        model.set({
          name: item.name
        });
        item.isAudioEnabled = item.dimension_code === "VSKP";
        if (viewType === "search") {
          const regex = new RegExp(searchTerm, "gi");
          const fullPathName = getFullPath.call(self, model);
          if (regex.test(fullPathName)) {
            item.name = highlightText.call(T1.Utils, {
              fullPath: fullPathName,
              separator: " &raquo; ",
              searchTermToBeHighlighted: searchTerm
            });
            result.push(item);
          }
        } else {
          result.push(item);
        }
      });
      return result;
    },
    serialize: function () {
      const {
        collection: collection,
        viewType: viewType
      } = this;
      const data = viewType === "browse" ? this.initData : this.mapItems(collection.models);
      const result = {
        list: data,
        contentType: this.viewType
      };
      return result;
    }
  });
  return createGrid(VideoTargetingBrowseView);
});
