define('modules/reporting/campaigns/reachFrequency/views/chartPanel',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/chartLayout.html", "text!../templates/chartPanel.html", "T1Animate"], function ($, _, T1, T1View, T1Layout, chartLayout, template, T1Animate) {
  "use strict";

  return T1View.extend({
    template: chartLayout,
    events: {
      "toggled .w-chart-drawer": "toggleChart"
    },
    initialize: function (args) {
      this.cfgs = args.config;
      this.chartShown = this.cfgs.models.mainModel.get("chartShown") === "true";
      this.parentClass = args.parentClass;
      this.initLayout();
    },
    initLayout: function () {
      var self = this;
      this.cfgs.parentClass = this;
      this.layout = new T1Layout({
        el: function () {
          return self.el.find(".rf-chart-panel");
        },
        template: template,
        layout: {
          ".rf-chart-title-box": [{
            module: "reporting/campaigns/reachFrequency",
            options: this.cfgs,
            viewType: "chartTitle"
          }],
          ".metric-select-c": [{
            module: "reporting/campaigns",
            options: this.cfgs,
            viewType: "selectMetric"
          }],
          ".rf-chart-box": [{
            module: "reporting/campaigns/reachFrequency",
            options: this.cfgs,
            viewType: "chart"
          }]
        }
      });
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.layout.load();
      });
    },
    toggleChart: function () {
      var chartShown = this.chartShown;
      var publish = T1.EventHub.publish;
      T1Animate({
        target: this.parentClass.el.find(".report-controls"),
        options: {
          marginTop: chartShown ? "8px" : "350px"
        },
        duration: 400,
        easing: "ease",
        complete: function () {
          publish("chart.toggleEnd");
          publish("track:event", {
            action: "Toggle chart",
            label: chartShown ? "show" : "hide"
          });
        }
      });
      this.chartShown = !chartShown;
      publish("chart.toggle", this.chartShown);
    },
    serialize: function () {
      return {
        chartShown: this.chartShown
      };
    }
  });
});
