define('models/videoCreativeVariants',["T1", "T1Model", "Backbone"], function (T1, T1Model, Backbone) {
  T1.Models.VideoCreativeVariants = T1Model.extend({
    url: "creatives",
    apiRoot: T1.VIDEO_API_BASE,
    action: "variants",
    fetch: function (options) {
      var self = this;
      var onSuccess;
      options = options || {};
      onSuccess = function (data) {
        self.loaded = true;
        self.set(data);
        if (options.onSuccess) {
          options.onSuccess(self, data);
        }
      };
      if (self.ajx) {
        self.ajx.abort();
      }
      this.loaded = false;
      self.ajx = self.sync("read", "", {
        action: self.action,
        dataType: "json",
        onSuccess: onSuccess,
        processAjaxResponse: function (data) {
          return {
            statusCode: "ok",
            jsonData: data
          };
        }
      });
      if (self.ajx) {
        self.ajx.always(function () {
          self.ajx = null;
        });
      }
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    fixture: "-video_creative_variants"
  });
  return T1.Models.VideoCreativeVariants;
});
