define('modules/T1App/edit/meta/views/meta',["jQuery", "Underscore", "Mustache", "When", "T1", "T1View", "T1Comm", "T1FileUpload", "text!../templates/meta.html", "text!templates/form_footer.html", "text!modules/T1App/edit/meta/templates/modal.html", "text!modules/T1App/edit/meta/templates/billing.html", "../../models/editModel", "models/appIconModel", "models/termsPdfModel", "models/sessionUser", "collections/t1AppEdit", "collections/t1AppTerms", "utils/T1AppUtils", "T1Model", "T1Layout", "T1Preferences", "T1Signature", "T1Menu"], function ($, _, Mustache, When, T1, T1View, T1Comm, T1FileUpload, template, form_footer, modalTemplate, billingTemplate, Model, AppIconModel, TermsPdfModel, User, T1AppEditCollection, T1AppTermsCollection, T1AppUtils) {
  "use strict";

  var MetaCreateEdit;
  var T1Location = T1.Location;
  MetaCreateEdit = T1View.extend({
    isEdit: false,
    template: template,
    partials: {
      footer: form_footer,
      lmodal: modalTemplate,
      bmodal: billingTemplate
    },
    fetchOptions: {
      appID: null,
      precision: 2,
      urlString: ""
    },
    events: {
      "keyup #app-name-edit": "onAppNameKeyUp",
      "keyup #app-desc-edit": "onAppDescKeyUp",
      "click .learn-more-icon": "showModal",
      "click .close-modal": "closeModal",
      "click .close-billing-modal": "closeBillingModal",
      "click .confirm-billing-changes": "confirmBillingChanges",
      "keyup .file-uploader-icon .text-input": "stopBubbling",
      "keydown .file-uploader-icon .text-input": "stopBubbling",
      "keyup .file-uploader-modal .text-input": "stopBubbling",
      "keydown .file-uploader-modal .text-input": "stopBubbling",
      "keyup .file-uploader-billing .text-input": "stopBubbling",
      "keydown .file-uploader-billing .text-input": "stopBubbling",
      "keyup .description": "descriptionCounter",
      "keyup .app-name": "appNameCounter",
      "focusout .description": "hideDescriptionCounter",
      "focusout .app-name": "hideAppNameCounter",
      "click .radio": "changeAppType",
      "click .billing-header": "showHideBilling",
      "click #billing-radios .radio": "changeBillingType",
      "click .terms-link": "showAdditionalTerms",
      "click .remove-terms": "removeTerms"
    },
    actionsConfig: {
      events: {
        "click .saveAndClose": "saveAndClose",
        "click .saveAndNew": "saveOverride"
      },
      formActions: [{
        label: "cancel",
        uuid: "cancel",
        class: "cancel",
        location: "#appstore/myApps"
      }, {
        label: "save",
        uuid: "save",
        class: "save save-btn-secondary",
        location: false
      }, {
        label: "save & Continue",
        uuid: "save_close",
        class: "saveAndClose save-btn-primary",
        location: false
      }]
    },
    eventhubEvents: {
      clearUpload: function () {
        $(".file-input", this.el).val("");
        $(".file-selector .file-name input", this.el).val("");
      }
    },
    fileUploaderData: {
      formSelector: "image-upload-form",
      placeholder: "Select an image. Max size: 500kb",
      action: "append",
      accept: "image/png,image/jpg,image/jpeg",
      dataBind: "file"
    },
    billingUploaderData: {
      formSelector: "image-upload-form",
      placeholder: "Select an pdf. Max size: 500kb",
      action: "append",
      accept: "application/pdf",
      dataBind: "file"
    },
    initialize: function () {
      var locationHashArray = T1Location.get(true).split("/");
      var appID = locationHashArray[2];
      this.fetchOptions.appID = appID;
      this.appInfo = "";
      this.model = new Model();
      this.collection = new T1AppEditCollection();
      this.appIconModel = new AppIconModel();
      this.termsCollection = new T1AppTermsCollection();
      this.termsPdfModel = new TermsPdfModel();
      this.canLoad = false;
      this.canSave = true;
      this.fileInfo = {};
      this.learnMoreImageModal = false;
      this.isGlobalAdmin = false;
      this.initializeData({
        id: this.fetchOptions.appID
      });
    },
    initializeData: function (args) {
      var self = this;
      this.termsCollection.fetch({
        appID: args && args.id || self.fetchOptions.appID,
        success: function (data) {
          if (data.meta.status === "success") {
            self.termsPdfModel.set(data.data[0]);
          }
        }
      }).then(function () {
        self.collection.fetch({
          appID: args && args.id || self.fetchOptions.appID,
          success: function (data) {
            if (data.meta === "success") {
              self.canLoad = true;
              self.model.setMetaData(data);
              self.appIconModel.set(data.data[0]);
              self.load();
            } else {
              T1.Location.set("#appstore");
            }
          }
        });
      });
    },
    load: function () {
      var self = this;
      var user = User.getUser();
      var $el = $(this.el);
      self.isGlobalAdmin = user.get("type") === "INTERNAL" && user.get("role") === "ADMIN" && user.get("scope") === "GLOBAL";
      if (self.canLoad) {
        this.render().then(function () {
          self.renderFileUploader("icon", {
            width: 175,
            height: 175
          });
          self.renderFileUploader("learn_more_image", {
            width: 304,
            height: 550
          });
          self.renderFileUploader("billing");
          if (self.isGlobalAdmin) {
            $("#app-name-edit", $el).prop("disabled", false);
            $(".radio", $el).removeAttr("disabled");
            $("#external-checkbox", $el).removeAttr("disabled");
          }
          T1.resizeWidth($el);
        });
      }
    },
    reload: function () {
      this.initializeData();
    },
    unload: function () {
      this.callbacksOriginal = null;
    },
    changeAppType: function (e) {
      var $el = $(this.el);
      var $ct = $(e.currentTarget);
      var appType = $ct.attr("value");
      if (appType === "app") {
        $(".launch-externally", $el).addClass("hidden").hide();
      } else if (appType === "lab") {
        $(".launch-externally", $el).removeClass("hidden").css("display", "inline-block").show();
      }
    },
    renderFileUploader: function (type, acceptedDimensions) {
      var self = this;
      var $el = $(self.el);
      var fileName, input, $fileInput, file, trimmedData, field;
      var options = {};
      var $parent = this.el.parent();
      self.fileUploader = T1FileUpload.create({
        $el: $el.find(".file-uploader-" + type),
        useReader: true,
        onChange: function (e) {
          input = e.currentTarget;
          $fileInput = $el.find('[data-bind="app-' + type + '"] input.text');
          if ($fileInput) {
            self.removeTooltipOnElement($fileInput);
            $fileInput.removeClass("error");
            $fileInput.parent().attr("original-title", "");
          }
          file = input.files[0];
          if (file) {
            if (file.type.slice(0, 5) !== "image" && type !== "billing") {
              self.applyTooltip($fileInput, "File must be an image");
            }
            if (file.type !== "application/pdf" && type === "billing") {
              self.applyTooltip($fileInput, "File must be an pdf");
            }
          }
        },
        onDataReady: function (inputElem, result) {
          trimmedData = result.split("base64,")[1];
          file = inputElem.files[0];
          if (type !== "billing") {
            self.validateImage(trimmedData, file.name, acceptedDimensions, file.size, type).then(function success() {
              $parent.find(".save-btn-secondary, .save-btn-primary").attr("disabled", "disabled");
              $parent.find(".save-btn-secondary, .save-btn-primary").addClass("disabled");
              self.saveImage({
                file: file,
                saveType: "preview",
                type: type
              });
              if (type === "icon") {
                $(".app-icon", $el).css("background", "url(" + URL.createObjectURL(file) + ")");
              } else {
                self.learnMoreImageModal = URL.createObjectURL(file);
              }
            }, function error(reason) {
              field = "app-" + type;
              self.applyTooltip($el.find("[data-bind='" + field + "'] input.text"), reason.message);
            });
          } else {
            self.validatePdf(trimmedData, file.name, file.size).then(function success() {
              $parent.find(".save-btn-secondary, .save-btn-primary").attr("disabled", "disabled");
              $parent.find(".save-btn-secondary, .save-btn-primary").addClass("disabled");
              self.savePdf({
                file: file,
                saveType: "preview",
                type: type
              });
            }, function error(reason) {
              field = "app-" + type;
              self.applyTooltip($el.find("[data-bind='" + field + "'] input.text"), reason.message);
            });
          }
        }
      });
      if (type !== "billing") {
        fileName = self.appIconModel.get(type);
        $.extend(options, self.fileUploaderData);
      } else {
        fileName = self.termsPdfModel.get("upload");
        $.extend(options, self.billingUploaderData);
      }
      if (fileName) {
        options.placeholder = fileName;
      }
      self.fileUploader.render(options);
      $el.find(".file-upload-btn").click(function () {
        self.isFormElementsChanged = true;
      });
    },
    saveImage: function (options) {
      var self = this;
      var imgFormData = new FormData();
      var err = [];
      var parseData, appData;
      var $parent = this.el.parent();
      if (options.file) {
        imgFormData.append("image", options.file);
      }
      if (options.filename) {
        imgFormData.append("image", options.filename);
      }
      imgFormData.append("options", options.saveType);
      self.appIconModel.save(imgFormData, {
        imageType: options.type,
        success: function (response) {
          appData = response.data[0];
          self.fileInfo[options.type] = appData["tmp_" + options.type];
          $parent.find(".save-btn-secondary, .save-btn-primary").attr("disabled", false).removeClass("disabled");
          if (options.success) {
            options.success(arguments);
          }
        },
        error: function (data) {
          try {
            parseData = JSON.parse(data);
          } catch (e) {
            parseData = data;
          }
          $parent.find(".save-btn-secondary, .save-btn-primary").attr("disabled", false).removeClass("disabled");
          $.each(parseData.errors, function (index, value) {
            if (value.field === "image") {
              err.push({
                field: "app-icon",
                message: value.message
              });
            }
            if (value.field === "version") {
              T1.Notify("error", "Version Conflict.");
            }
          });
        }
      });
    },
    savePdf: function (options) {
      var self = this;
      var $el = $(self.el);
      var imgFormData = new FormData();
      var err = [];
      var parseData, appData, actFilename;
      var $parent = this.el.parent();
      var termsModel = this.termsPdfModel;
      var model = this.model;
      if (options.file) {
        imgFormData.append("document", options.file);
      }
      if (options.filename) {
        imgFormData.append("document", options.filename);
      }
      imgFormData.append("options", options.saveType);
      self.termsPdfModel.save(imgFormData, {
        appId: model.get("app_id"),
        success: function (response) {
          appData = response.data[0];
          if (options.saveType === "apply") {
            actFilename = appData.upload;
          } else {
            actFilename = appData.filename;
          }
          self.fileInfo[options.type] = actFilename;
          $(".terms-link", $el).text(actFilename).data("url", actFilename);
          $(".terms-uploader", $el).addClass("hidden");
          $(".terms-file", $el).removeClass("hidden");
          $parent.find(".save-btn-secondary, .save-btn-primary").attr("disabled", false).removeClass("disabled");
          $(".uploaded-terms .longBillingTooltip", $el).text(actFilename);
          if (actFilename.length > 65) {
            $("#longBillingTT", $el).removeClass("hidden");
            $("#longBillingUrl", $el).text(actFilename.substring(0, 62) + "...");
          } else {
            $("#longBillingUrl", $el).text(actFilename);
            $("#longBillingTT", $el).addClass("hidden");
          }
          if (appData.id) {
            termsModel.set(appData);
          }
          termsModel.set({
            removeTerms: false
          });
          if (options.success) {
            options.success(arguments);
          }
        },
        error: $.proxy(function (data) {
          try {
            parseData = JSON.parse(data);
          } catch (e) {
            parseData = data;
          }
          $parent.find(".save-btn-secondary, .save-btn-primary").attr("disabled", false).removeClass("disabled");
          $.each(parseData.errors, function (index, value) {
            if (value.field === "document") {
              err.push({
                field: "app-billing_terms",
                message: value.message
              });
            }
            if (value.field === "version") {
              T1.Notify("error", "Version Conflict.");
            }
          });
        })
      });
    },
    deleteTerms: function (termsId) {
      var self = this;
      var imgFormData = new FormData();
      var err = [];
      var parseData;
      self.termsPdfModel.save(imgFormData, {
        requestType: "delete",
        termsId: termsId,
        error: function (data) {
          $.each(parseData.errors, function (index, value) {
            if (value.field === "document") {
              err.push({
                field: "app-billing_terms",
                message: value.message
              });
            }
            if (value.field === "version") {
              T1.Notify("error", "Version Conflict.");
            }
          });
        }
      });
    },
    validateImage: function (imageFileData, lastUploadedFilename, acceptedDimensions, fileSize, type) {
      var self = this;
      var deferred = When.defer();
      var img, image, imageDimensionError;
      if (imageFileData) {
        img = new Image();
        img.src = "data:image/png;base64," + imageFileData;
        img.addEventListener("load", function () {
          image = this;
          imageDimensionError = self.validateStandardImageSize(image.width, image.height, acceptedDimensions, type);
          if (type === "learn_more_image") {
            if (imageDimensionError) {
              deferred.reject({
                message: "Image must be " + acceptedDimensions.width + "px wide and no more than " + acceptedDimensions.height + "px high"
              });
            } else if (fileSize > 5e5) {
              deferred.reject({
                message: "Image must be smaller than 500kb"
              });
            } else {
              deferred.resolve(true);
            }
          } else {
            if (imageDimensionError) {
              deferred.reject({
                message: "Image must be " + acceptedDimensions.width + "px x " + acceptedDimensions.height + "px"
              });
            } else if (fileSize > 5e5) {
              deferred.reject({
                message: "Image must be smaller than 500kb"
              });
            } else {
              deferred.resolve(true);
            }
          }
        });
      } else {
        if (!imageFileData && !lastUploadedFilename) {
          deferred.reject([{
            field: "file",
            message: "This is a required field."
          }]);
        } else {
          deferred.resolve(true);
        }
      }
      return deferred.promise;
    },
    validatePdf: function (pdfFileData, lastUploadedFilename, fileSize) {
      var deferred = When.defer();
      if (pdfFileData) {
        if (fileSize > 5e5) {
          deferred.reject({
            message: "PDF must be smaller than 500kb"
          });
        } else {
          deferred.resolve(true);
        }
      } else {
        if (!pdfFileData && !lastUploadedFilename) {
          deferred.reject([{
            field: "file",
            message: "This is a required field."
          }]);
        } else {
          deferred.resolve(true);
        }
      }
      return deferred.promise;
    },
    validateStandardImageSize: function (width, height, acceptedDimensions, type) {
      var maxWidth = acceptedDimensions.width;
      var maxHeight = acceptedDimensions.height;
      var reasons = [];
      var tooltip;
      if (type === "learn_more_image") {
        tooltip = "Dimensions: " + maxWidth + "x" + maxHeight;
        if (width !== maxWidth) {
          reasons.push("Image width must be " + maxWidth + "px");
        }
        if (height > maxHeight) {
          reasons.push("Image height must be no more than " + maxHeight + "px");
        }
        if (height < 1) {
          reasons.push("Image height must be at least 1px");
        }
      } else {
        tooltip = "Dimensions: " + maxWidth + "x" + maxHeight;
        if (width !== maxWidth) {
          reasons.push("Image width must be " + maxWidth + "px");
        }
        if (height !== maxHeight) {
          reasons.push("Image height must be " + maxHeight + "px");
        }
      }
      if (reasons.length > 0) {
        return {
          reason: reasons.join(", "),
          tooltip: tooltip
        };
      } else {
        return false;
      }
    },
    stopBubbling: function (e) {
      if (e) {
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    },
    showHideBilling: function () {
      var $el = $(this.el);
      var area = $(".billing-section", $el);
      var arrow = $(".billing-arrow", $el);
      if (arrow.hasClass("arrow-down")) {
        area.slideUp();
        arrow.removeClass("arrow-down");
      } else {
        area.slideDown();
        arrow.addClass("arrow-down");
      }
    },
    changeBillingType: function (e) {
      var $el = $(this.el);
      var $ct = $(e.currentTarget);
      var billingType = $ct.attr("value");
      if (billingType === "cpm") {
        $("#billing-cpm", $el).removeClass("hidden").show();
      } else {
        $("#billing-cpm", $el).addClass("hidden").hide();
      }
    },
    appNameCounter: function () {
      var $el = $(this.el);
      var totalChar = $("#app-name-edit", $el).val().replace(/\n/g, "\r\n").length;
      $(".app-name-counter", $el).text(45 - totalChar);
    },
    descriptionCounter: function () {
      var $el = $(this.el);
      var totalChar = $(".description", $el).val().replace(/\n/g, "\r\n").length;
      $(".description-counter", $el).text(120 - totalChar);
    },
    hideAppNameCounter: function () {
      var $el = $(this.el);
      $(".app-name-counter", $el).text("");
    },
    hideDescriptionCounter: function () {
      var $el = $(this.el);
      $(".description-counter", $el).text("");
    },
    onAppNameKeyUp: function () {
      var $el = $(this.el);
      var $inputBox = $el.find("#app-name-edit", $el);
      var term = $inputBox.val().trim();
      if (term.length > 0) {
        $(".app-title", $el).text(term);
      } else {
        $(".app-title", $el).text("App Name");
      }
    },
    onAppDescKeyUp: function () {
      var $el = $(this.el);
      var $inputBox = $el.find("#app-desc-edit", $el);
      var term = $inputBox.val().trim();
      if (term.length > 0) {
        $(".app-desc-text", $el).text(term);
      } else {
        $(".app-desc-text", $el).text("App description to give users an idea of what your app does.");
      }
    },
    showModal: function () {
      var $el = $(this.el);
      var title = $("#app-name-edit", $el).val() ? $("#app-name-edit", $el).val() : "App name";
      var learnMoreHtml = $(".learn-more-description textarea", $el).val();
      var learnMoreImage = this.learnMoreImageModal || T1AppUtils.getEnv() + "learn-more/" + this.model.get("learn_more_image");
      var appDetails, render;
      if (!learnMoreHtml) {
        learnMoreHtml = "Please add Learn More Text.";
      }
      appDetails = {
        title: title,
        learn_more_html: learnMoreHtml,
        learn_more_image: learnMoreImage
      };
      render = Mustache.to_html(modalTemplate, appDetails, this.partials);
      if (Polymer && Polymer.dom) {
        Polymer.dom(Polymer.dom($el[0]).querySelector(".learn-more-modal-edit")).innerHTML = render;
      } else {
        $el.find(".learn-more-modal-edit").html(render);
      }
      $(".learn-more-modal-edit")[0].show();
    },
    closeModal: function (lmodal) {
      var $el = $(this.el);
      lmodal.preventDefault();
      $(".learn-more-modal-edit", $el)[0].hide();
    },
    closeBillingModal: function (bmodal) {
      var $el = $(this.el);
      var self = this;
      bmodal.preventDefault();
      self.stopSpinner.call(self);
      $(".billing-changes", $el)[0].hide();
    },
    showAdditionalTerms: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $el = $(this.el);
      var url = $(".uploaded-terms span", $el).data("url");
      var fullUrl = T1AppUtils.getEnv() + "agreements/terms/" + url;
      window.open(fullUrl, "_blank");
    },
    removeTerms: function () {
      var self = this;
      var $el = $(this.el);
      var termsModel = this.termsPdfModel;
      self.fileInfo.billing = "";
      $(".terms-file", $el).addClass("hidden");
      $(".terms-uploader", $el).removeClass("hidden");
      $(".file-uploader-billing input").val("").attr("placeholder", "Select an pdf. Max size: 500kb");
      termsModel.set({
        removeTerms: true
      });
    },
    showBillingNotification: function (e, type, newFee, feeType, termsFile) {
      var $el = $(this.el);
      var self = this;
      var modalData = {};
      var model = this.model;
      var saveBtn = $(e.currentTarget);
      var termsModel = this.termsPdfModel;
      var render;
      modalData.saveType = type;
      modalData.cpmChanged = false;
      modalData.bothChanged = false;
      modalData.termsChanged = false;
      modalData.newCpm = parseFloat(newFee).toFixed(2);
      modalData.currentCpm = parseFloat(model.get("fee")).toFixed(2);
      if (parseFloat(model.get("fee")) !== parseFloat(newFee) && feeType !== "none") {
        modalData.cpmChanged = true;
        modalData.title = "Confirm Price Change";
      }
      if (termsModel.get("upload") !== termsFile && termsFile !== "") {
        modalData.termsChanged = true;
        modalData.title = "Confirm Terms &amp; Conditions Change";
      }
      if (modalData.termsChanged && modalData.cpmChanged) {
        modalData.bothChanged = true;
        modalData.title = "Confirm Price and Terms &amp; Conditions Change";
      }
      render = Mustache.to_html(billingTemplate, modalData, this.partials);
      if (Polymer && Polymer.dom) {
        Polymer.dom(Polymer.dom($el[0]).querySelector(".billing-changes")).innerHTML = render;
      } else {
        $el.find(".billing-changes").html(render);
      }
      $(".billing-changes", $el)[0].show();
      self.stopSpinner(saveBtn);
    },
    saveAndClose: function (e) {
      var self = this;
      var $el = $(this.el);
      var model = this.model;
      var termsModel = this.termsPdfModel;
      var newFee = $("#billing-cpm input", $el).val();
      var feeType = $("#billing-radios strand-group", $el).val();
      var termsFile = self.fileInfo.billing ? self.fileInfo.billing : "";
      var app_id;
      var changes = false;
      var callback = {
        success: function () {
          app_id = self.model.get("app_id");
          T1.Notify("message", "App updated successfully!");
          setTimeout(function () {
            T1.Location.set("#appstore/edit/" + app_id + "/version");
          }, 50);
        }
      };
      this.setSaveAndContinueMode(true);
      if (parseFloat(model.get("fee")) !== parseFloat(newFee) && feeType !== "none" || termsModel.get("upload") !== termsFile && termsFile !== "") {
        changes = true;
      }
      if (this.canSave && !changes) {
        return this.saveOriginal(e, callback);
      } else if (changes) {
        self.showBillingNotification(e, "continue", newFee, feeType, termsFile);
      }
    },
    saveOverride: function (e, customCallbacks) {
      var self = this;
      var $el = $(this.el);
      var model = this.model;
      var termsModel = this.termsPdfModel;
      var newFee = $("#billing-cpm input", $el).val();
      var feeType = $("#billing-radios strand-group", $el).val();
      var termsFile = self.fileInfo.billing ? self.fileInfo.billing : "";
      var changes = false;
      var callback = customCallbacks || {
        success: function () {
          T1.Notify("message", "App updated successfully!");
        }
      };
      if (parseFloat(model.get("fee")) !== parseFloat(newFee) && feeType !== "none" || termsModel.get("upload") !== termsFile && termsFile !== "") {
        changes = true;
      }
      if (this.canSave && !changes) {
        $(".ui-dialog").dialog("close").remove();
        self.stopSpinner.call(self);
        if (customCallbacks !== undefined) {
          setTimeout(function () {
            return self.saveOriginal(e, callback);
          }, 2e3);
        } else {
          return this.saveOriginal(e, callback);
        }
      } else if (changes) {
        this.callbacksOriginal = customCallbacks;
        self.showBillingNotification(e, "save", newFee, feeType, termsFile);
      }
    },
    confirmBillingChanges: function (e) {
      var self = this;
      var $el = $(this.el);
      var saveType = $(".confirm-billing-changes", $el).data("type");
      var app_id, callback;
      if (saveType === "continue") {
        callback = {
          success: function () {
            app_id = self.model.get("app_id");
            T1.Notify("message", "App updated successfully!");
            setTimeout(function () {
              T1.Location.set("#appstore/edit/" + app_id + "/version");
            }, 50);
          }
        };
        this.setSaveAndContinueMode(true);
      } else {
        callback = {
          success: function () {
            T1.Notify("message", "App updated successfully!");
            if (self.callbacksOriginal) {
              self.callbacksOriginal.success(e);
            }
          }
        };
      }
      $(".billing-changes", $el)[0].hide();
      $(".ui-dialog").dialog("close").remove();
      self.stopSpinner.call(self);
      if (this.canSave) {
        return this.saveOriginal(e, callback);
      }
    },
    postData: function (formValues, success, statusInvalid, conflict) {
      var $el = $(this.el);
      var model = this.model;
      var termsModel = this.termsPdfModel;
      var version = parseInt(formValues.version);
      var self = this;
      var queue = [self.validateEmail(formValues.support_email), self.validateURL(formValues.learn_more_link), self.validateEmpty(formValues.name), self.validateBillingType(formValues.billing_type, formValues.cpm)];
      var settled = When.settle(queue);
      var errors = [];
      var user = User.getUser();
      var internalAdmin = user.get("type") === "INTERNAL";
      settled.then(function (descriptors) {
        _.each(descriptors, function (d) {
          if (d.state === "rejected") {
            errors = errors.concat(d.reason);
          }
        });
        if (errors && errors.length) {
          statusInvalid(errors);
          $(".appDetailsForm", $el).stopSpinner();
        } else {
          model.set({
            app_id: formValues.app_id,
            description: formValues.description,
            learn_more_html: formValues.learn_more_html,
            support_email: formValues.support_email,
            learn_more_link: formValues.learn_more_link,
            organization_id: parseFloat(formValues.organization_id),
            billing_type: formValues.billing_type,
            fee: parseFloat(formValues.cpm),
            version: model.get("version") || version
          });
          if (internalAdmin) {
            model.set({
              lab: formValues.app_type === "lab" ? parseInt(1) : parseInt(0),
              external: formValues.lab_external && formValues.app_type === "lab" ? parseInt(1) : parseInt(0)
            });
          } else {
            model.unset("lab");
            model.unset("external");
          }
          if (self.isGlobalAdmin) {
            model.set({
              name: formValues.name
            });
          } else {
            model.unset("name");
          }
          model.save(model.toJSON(), {
            dataType: "json",
            statusInvalid: function () {},
            success: function (returnedModel) {
              var data = returnedModel.data && returnedModel.data[0];
              model.set({
                app_id: data.id,
                name: data.name,
                description: data.description,
                learn_more_html: data.learn_more_html,
                support_email: data.support_email,
                learn_more_link: data.learn_more_link,
                organization_id: data.organization_id,
                lab: data.lab,
                external: data.external,
                billing_type: data.billing_type,
                fee: data.fee,
                version: data.version
              });
              self.isFormElementsChanged = false;
              success(model);
              function saveImagesIfChanged(type) {
                if (self.fileInfo[type]) {
                  self.saveImage({
                    filename: self.fileInfo[type],
                    saveType: "apply",
                    type: type,
                    success: $.proxy(function (response) {
                      var obj = {};
                      obj[type] = response[0] && response[0].data[0] && response[0].data[0][type];
                      model.set(obj);
                      delete self.fileInfo[type];
                    })
                  });
                }
              }
              function savePdfIfChanged(type) {
                if (self.fileInfo[type]) {
                  self.savePdf({
                    filename: self.fileInfo[type],
                    saveType: "apply",
                    type: type,
                    success: $.proxy(function (response) {
                      var obj = {};
                      obj[type] = response[0] && response[0].data[0] && response[0].data[0][type];
                      model.set(obj);
                      if (termsModel.get("removeTerms")) {
                        self.deleteTerms(termsModel.get("id"));
                      }
                      delete self.fileInfo[type];
                    })
                  });
                } else {
                  if (termsModel.get("removeTerms")) {
                    if (termsModel.get("id")) {
                      self.deleteTerms(termsModel.get("id"));
                    }
                  }
                }
              }
              saveImagesIfChanged("icon");
              saveImagesIfChanged("learn_more_image");
              savePdfIfChanged("billing");
            },
            conflict: conflict,
            error: function (data) {
              var errorObj = $.parseJSON(data.responseText);
              var addError = function (fld, errMsg) {
                err.push({
                  field: fld,
                  message: errMsg
                });
              };
              var notify = function (msg) {
                T1.Notify("error", msg);
              };
              statusInvalid(errorObj, "There are errors on the page.", ".details");
              $.each(errorObj.errors, function (index, value) {
                var errMsg = value.message;
                switch (value.field) {
                  case "api_version":
                    notify("API version does not exist or is deprecated.");
                    break;
                  case "json_name":
                    addError("name", "An existing app already uses this name.");
                    break;
                  case "learn_more_link":
                    addError("learn_more_link", errMsg);
                    break;
                  case "support_email":
                    addError("support_email", errMsg);
                    break;
                  case "title":
                    addError("title", errMsg);
                    break;
                  case "version":
                    notify("Version Conflict.");
                    break;
                  case "version_type":
                    addError("version_id", errMsg);
                    break;
                  case "billing_type":
                    addError("billing_type", "Invalid billing type provided. Please select either Free or CPM.");
                    break;
                  case "fee":
                    addError("cpm", errMsg);
                    break;
                  default:
                    notify(errMsg);
                }
              });
              statusInvalid(err);
            },
            contentType: "application/json",
            processData: false,
            stringify: true
          });
        }
      });
    },
    validateEmail: function (attrs) {
      var deferred = When.defer();
      var regex = /^(([a-zA-Z]|[0-9])|([-]|[_]|[.]))+[@](([a-zA-Z0-9])|([-])){2,63}[.](([a-zA-Z0-9]){2,63})+$/;
      var validEmail = regex.test(attrs);
      if (validEmail || attrs === "") {
        deferred.resolve(true);
      } else {
        deferred.reject({
          field: "support_email",
          message: "Not a valid email address."
        });
      }
      return deferred.promise;
    },
    validateURL: function (attrs) {
      var deferred = When.defer();
      var regex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
      var validURL = regex.test(attrs);
      if (validURL || attrs === "") {
        deferred.resolve(true);
      } else {
        deferred.reject({
          field: "learn_more_link",
          message: "Not a valid URL."
        });
      }
      return deferred.promise;
    },
    validateBillingType: function (billing_type, cpm) {
      var deferred = When.defer();
      var regex = /^\d{0,}(?:\.\d{0,2}){0,1}$/;
      var validCPM = regex.test(cpm);
      if (billing_type === "none" || billing_type === "cpm") {
        if (billing_type === "none") {
          deferred.resolve(true);
        } else if (billing_type === "cpm") {
          if (validCPM && cpm > 0) {
            deferred.resolve(true);
          } else {
            deferred.reject({
              field: "cpm",
              message: "CPM must be a number."
            });
          }
        }
      } else {
        deferred.reject({
          field: "billing_type",
          message: "Billing Type must be either Free or CPM."
        });
      }
      return deferred.promise;
    },
    validateEmpty: function (attrs) {
      var deferred = When.defer();
      if (attrs !== "") {
        deferred.resolve(true);
      } else {
        deferred.reject({
          field: "name",
          message: "App Name is a required field."
        });
      }
      return deferred.promise;
    },
    canUnload: function (onTabChange) {
      var isFormElementsChanged = this.isFormElementsChanged;
      var title = this.model.get("name");
      if (isFormElementsChanged) {
        this.openUnloadAlertDialog({
          name: title
        }, onTabChange, false, true);
      }
      return !isFormElementsChanged;
    },
    serialize: function () {
      var output = this.model.toJSON();
      var user = User.getUser();
      var internalAdmin = user.get("type") === "INTERNAL";
      var termsModel = this.termsPdfModel.toJSON();
      output.isEdit = this.isEdit;
      output.env = T1AppUtils.getEnv();
      output.billingUrl = termsModel.upload;
      output.billingTerms = false;
      if (output.billingUrl) {
        if (output.billingUrl.length > 65) {
          output.billingUrlTooltip = true;
          output.billingUrlShort = output.billingUrl.substring(0, 62) + "...";
        } else {
          output.billingUrlTooltip = false;
          output.billingUrlShort = output.billingUrl;
        }
      }
      if (termsModel.upload) {
        output.billingTerms = true;
      }
      if (output.learn_more_image) {
        output.learn_more_image = T1AppUtils.getEnv() + "learn-more/" + output.learn_more_image;
      }
      if (output.name && output.name.length > 27) {
        output.tooltip = true;
      } else {
        output.tooltip = false;
      }
      output.internalAdmin = internalAdmin ? true : false;
      return output;
    }
  });
  MetaCreateEdit = T1.Form.ViewUtils.extend(MetaCreateEdit);
  MetaCreateEdit.prototype.saveOriginal = MetaCreateEdit.prototype.save;
  MetaCreateEdit.prototype.save = MetaCreateEdit.prototype.saveOverride;
  return MetaCreateEdit;
});
