define('modules/audiences/onboarding/create/views/mediaCreateEdit',["jQuery", "T1", "modules/audiences/onboarding/create/views/onboardingForm", "modules/audiences/segments/segmentUtils", "models/advertiser", "models/onboarding"], function ($, T1, OnboardingCreateEdit, Util, Advertiser, Onboarding) {
  "use strict";

  var mediaPixelCreateEditView = OnboardingCreateEdit.extend({
    ImplementationsTemplate: null,
    RevenueTemplate: null,
    renderConfigs: {
      viewInBrowserEnabled: false
    },
    load: function (opt) {
      this.viewOptions = opt && opt.viewOptions || T1.Location.getViewOptions();
      this.model = this.model || new Onboarding();
      this.model.onboardMethod = "media";
      this.model.method = "External Media";
      this.updateBreadCrumb(this.breadcrumb);
      this.render().then(() => {
        this.renderCallback();
      });
      if (this.mode !== "edit") {
        this.model.fetchSetup().then(() => this.populateRepeaters());
        this.$(".events-attributes-section").show();
      }
    },
    loadImplementations: function () {},
    populateRepeaters: function (cb, data) {
      this.attributeOptions = this.model.fetchAttributeOptions();
      this.eventOptions = this.model.fetchEventDefaults("default");
      this.customAttributeKeys = this.model.fetchCustomAttributeKeys();
      this.currentData = {
        "standard-attributes": this.attributeOptions,
        "custom-attributes": this.customAttributeKeys
      };
      this.populateEvents();
      this.populateStandardAttributes();
      this.populateCustomAttributes();
      this.$(".events-attributes-section").show();
      if (cb) {
        cb();
      }
    },
    searchOptions: function (e) {
      var searchValue = e.srcElement.value;
      var dropdown = $(e.target).closest("strand-dropdown")[0];
      var searchType = dropdown.id;
      var masterData = searchType === "eventOptions" ? this.model.fetchEventDefaults("default") : this.currentData["standard-attributes"];
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        let searchResults = masterData.filter(item => item.name.toLowerCase().includes(searchValue));
        dropdown.set("data", searchResults);
      } else {
        dropdown.set("data", masterData);
      }
      dropdown.open();
    },
    advertiserChanged: function () {
      var advertiserValue = this.el[0].querySelector("#advertisers").value || this.model.advertiser_id;
      if (typeof advertiserValue === "object") {
        return;
      }
      this.model.advertiser_id = advertiserValue;
      this.saveCheck();
    },
    repeatersChanged: function (e) {
      var isAddRow = e.srcElement.parentNode.className.includes("add-row");
      var isRemoveIcon = e.currentTarget.className.includes("remove-row");
      if (isRemoveIcon) {
        var removedRepeater = $(e.currentTarget).closest("strand-box").find("label")[0].innerText.toLowerCase().replace(/(\+add )/, "").replace(/\s/, "-").concat("s");
      }
      var targetId = e.currentTarget.id || removedRepeater;
      var targetType = e.srcElement.localName;
      var isAttr = targetId === "attributeKey" || targetId === "standard-attributes" || targetId === "custom-attributes";
      var isDrop = targetType === "strand-list-item";
      if (isAttr && (isDrop || isRemoveIcon || isAddRow)) {
        this.updateRepeaterDropdownData(targetId);
      }
      this.saveCheck();
    },
    updateBreadCrumb: function () {
      var pathPieces;
      this.title = this.mode === "edit" ? this.model.name : "New External Media Events";
      if (this.mode === "edit") {
        pathPieces = [{
          label: "Edit " + (this.model.name || ""),
          location: "#audiences/onboarding/media/edit/" + this.id
        }];
      } else {
        pathPieces = [{
          label: "New External Media Events",
          location: "#audiences/onboarding/media/create"
        }];
      }
      T1.EventHub.publish("updateNavPath", {
        locations: pathPieces
      });
    },
    showViewTemplate: function () {
      let viewText = "";
      this.model.templates.map(pixel => {
        viewText += "<pre>" + pixel.replace(/</g, "&lt;").replace(/>/g, "&gt;") + "</pre>";
      });
      Polymer.dom(this.el[0].querySelector("#onboarding-modal-text")).innerHTML = viewText;
      this.el[0].querySelector("#view-onboarding-modal").show();
      this.deliveryChanged();
      this.viewOptions.makeViewCall = false;
      this.model.postLoadView = false;
    },
    createSaveObj: function () {
      this.model.events = this.eventsRepeater.data;
      this.model.standard_attributes = this.standardAttributesRepeater.data;
      this.model.custom_attributes = this.customAttributesRepeater.data;
      this.model.delivery_format = "CSV";
      return this.model.dataToSaveObj();
    },
    successCallback: function (respObj, resp, e, postLoad) {
      var error = "";
      var viewOptions = {
        postSave: true
      };
      var returnedId;
      if (e) {
        Util.buttonReset(e.target, "Save");
        this.$(e.currentTarget).removeAttr("disabled");
      }
      this.el[0].querySelector("#onboarding-form").classList.remove("disabled");
      if (typeof resp === "string" && resp !== "success") {
        resp = $.parseJSON(resp);
        if ("errors" in resp) {
          if (Array.isArray(resp.errors) && resp.errors.length > 0) {
            error = error + resp.errors.join(";  ");
          }
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
        }
      } else {
        if (postLoad && postLoad == "download") {
          this.model.postLoadDownload = false;
          this.viewOptions.makeDownloadCall = false;
          var deliveryFormat = this.model.delivery_format.toLowerCase();
          var blob = new Blob([respObj], {
            type: deliveryFormat === "csv" ? "attachment/csv" : "text/plain"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          var elem = document.body.appendChild(a);
          var handler = function () {
            setTimeout(function () {
              window.URL.revokeObjectURL(url);
            }, 1e3);
            document.body.removeChild(elem);
            elem.removeEventListener("click", handler);
          };
          a.href = url;
          a.download = "pixel_data." + deliveryFormat;
          elem.addEventListener("click", handler);
          elem.click();
          return;
        } else {
          this.model.id = respObj.data.id;
          viewOptions.makeViewCall = this.model.postLoadView;
          viewOptions.makeDownloadCall = this.model.postLoadDownload;
          if (this.mode === "edit") {
            this.load({
              viewOptions: viewOptions
            });
          } else {
            T1.Location.set("audiences/onboard/media/edit/" + this.model.id, {
              viewOptions: viewOptions
            });
          }
        }
      }
    },
    fetchDownload: function () {
      this.el[0].querySelector("#onboarding-form").classList.add("disabled");
      Util.buttonLoader({
        currentTarget: "#save-button"
      });
      this.model.delivery_format = "CSV";
      this.model.fetchDownload({
        success: (model, resp) => {
          Util.buttonReset($("#save-button"), "Save");
          this.successCallback.call(this, model, resp, null, "download");
        },
        error: (e, status, error) => {
          Util.buttonReset($("#save-button"), "Save");
          this.showFooterMsg("Onboarding submission has failed. Please contact T1 Support.");
        }
      });
    },
    handlePostLoadOptions: function (options, data) {
      if (options && options.postSave) {
        this.showFooterMsg();
      }
      if (options && options.makeViewCall) {
        this.showViewTemplate();
      }
      if (options && options.makeDownloadCall) {
        this.fetchDownload();
      }
    },
    saveCheck: function (opts) {
      opts = opts || {};
      const groupNameSet = () => this.model.name;
      const advertiserSet = () => this.model.advertiser_id;
      var eventsExist = () => {
        if (!this.eventsRepeater) {
          return false;
        }
        return this.eventsRepeater.data.some(x => x.key);
      };
      var validateRepeaters = () => {
        if (this.repeaterValidationError) {
          var eventsError = !this.eventsRepeater.validate();
          var customAttributesError = !this.el[0].querySelector("#custom-attributes").validate();
          this.repeaterValidationError = eventsError || customAttributesError;
          return !this.repeaterValidationError;
        } else {
          return true;
        }
      };
      const validateDelivery = () => {
        const delivery = this.model.delivery_method && this.model.delivery_method.length > 0;
        if (delivery) {
          return !this.model.delivery_method.includes("email") ? true : this.model.email_to;
        } else {
          return true;
        }
      };
      var createCanSave = this.mode === "create" && this.model.advertiser_id && this.model.name;
      var editCanSave = this.mode === "edit" && this.eventsRepeater;
      if (editCanSave) {
        let oldData = JSON.parse(JSON.stringify(this.model.loadedData));
        let newData = JSON.parse(JSON.stringify(this.createSaveObj()));
        delete oldData.id;
        delete oldData.type;
        delete oldData.events;
        this.editHasChanged = Object.keys(oldData).filter(key => oldData[key] || newData[key]).some(key => {
          var prepareToCompare = function (value) {
            if (Array.isArray(value)) {
              value = value.map(item => {
                if (!item.note) {
                  delete item.note;
                }
                delete item.id;
                delete item.cId;
                delete item.custom;
                return item;
              }).sort((a, b) => {
                var matcher = a.key ? "key" : a.event_name ? "event_name" : "";
                if (matcher) {
                  return a[matcher] < b[matcher] ? -1 : 1;
                } else {
                  return a < b ? -1 : 1;
                }
              });
            }
            return JSON.stringify(value);
          };
          var matchedData = prepareToCompare(newData[key]) === prepareToCompare(oldData[key]);
          return !matchedData;
        });
      }
      var canSave = (createCanSave || editCanSave) && groupNameSet() && advertiserSet() && eventsExist() && validateRepeaters() && this.validateDelivery();
      if (!this.repeaterValidationError && !(this.mode === "edit" && !this.editHasChanged)) {
        this.hideFooterMsg();
      }
      if (canSave) {
        this.enableSave();
      } else {
        this.disableSave();
      }
    },
    loadModel: function () {
      this.model.loadGroup(this.id, this.viewOptions).then(data => {
        this.handlePostLoadOptions(this.viewOptions, data);
        let advertiser = new Advertiser({
          id: data.advertiser_id
        }).fetch({
          success: response => {
            this.el[0].querySelector("#selected-advertiser").innerHTML = response.get("name");
          },
          error: err => {
            this.showFooterMsg("Error loading advertiser");
          }
        });
        this.el[0].querySelector("#selected-name").innerHTML = this.model.name;
        Polymer.dom(this.el[0].querySelector(".onboarding-head strand-header")).innerHTML = this.model.name;
        this.advertiserChanged();
        this.updateBreadCrumb();
        this.model.fetchSetup().then(() => this.populateRepeaters(() => {
          this.eventsRepeater.set("data", data.events);
          this.standardAttributesRepeater.set("data", data.standard_attributes);
          this.customAttributesRepeater.set("data", data.custom_attributes);
          requestAnimationFrame(() => {
            [...this.el[0].querySelectorAll("#attributeKey")].map(dropdown => dropdown.set("data", this.customAttributeKeys));
            this.updateRepeaterDropdownData("custom-attributes");
            this.saveCheck({
              postLoad: true,
              viewOptions: this.viewOptions
            });
          });
          if (data.revenue) {
            this.el[0].querySelector("#attrPair").click();
          }
        }, data));
      });
    }
  });
  return mediaPixelCreateEditView;
});
