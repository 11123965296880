define('modules/creatives/video/views/createEdit',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "T1TabLayoutV2", "T1Permissions", "models/atomicCreative", "models/videoCreative", "models/videoCreativeDetail", "models/advertiser", "models/concept", "collections/organizations", "text!../templates/createEdit.html", "utils/nextTabViewNavigation", "moment", "T1Form"], function ($, _, When, T1, T1View, T1Layout, T1TabLayout, T1Permissions, AtomicCreative, VideoCreative, VideoCreativeDetail, Advertiser, Concept, Organizations, template, NextTabViewNavigation, moment) {
  "use strict";

  var CreativesCreateEdit;
  const escapeHTML = T1.Utils.escapeHTML;
  function fetchBreadCrumbData(model) {
    var isPreview = true;
    var deferred = $.Deferred();
    var date_created, creative_name, first_name, last_name, status, id, atomicCreative;
    id = parseInt(model.id);
    if (id) {
      atomicCreative = new AtomicCreative({
        id: model.id
      });
      atomicCreative.fetch({
        params: {
          with: ["advertiser,agency", "built_by_user"]
        },
        success: function (arg) {
          date_created = arg.get("created_on");
          creative_name = arg.get("name");
          status = arg.get("status");
          first_name = arg.get("user").first_name;
          last_name = arg.get("user").last_name;
          updateBreadCrumb(isPreview, model, arg.id, creative_name, first_name, last_name, date_created, status);
          deferred.resolve();
        }
      });
    } else {
      deferred.resolve();
      updateBreadCrumb(isPreview, {});
    }
    return deferred.promise();
  }
  function getBreadCrumbHeaderInfo(model, fName, lName, dateCreated, status) {
    var modelJSON = model || model.toJSON();
    var valueToBeDisplayed = [{
      attr: "id",
      label: "Creative id",
      className: "id"
    }, {
      attr: "user",
      label: "created by",
      className: "id"
    }, {
      attr: "created_on",
      label: "created on",
      className: "id"
    }, {
      attr: "status",
      className: "status"
    }];
    var returnArry = [];
    var i;
    var noData = "&nbsp;--";
    var formatDate = function () {
      return dateCreated ? Date.parse(dateCreated).toString("MM/dd/yyyy") : noData;
    };
    var formatData = function (attr, value) {
      var attrOperations = {
        created_on: formatDate,
        user: function () {
          return fName !== undefined ? fName + " " + lName : noData;
        },
        status: function () {
          var statusText = status === "0" ? "Inactive" : "Active";
          if (!model.id) {
            statusText = "Active";
          }
          return statusText;
        },
        defaultFormat: function () {
          return value || noData;
        }
      };
      var formatter = attrOperations[attr] || attrOperations.defaultFormat;
      return formatter(value);
    };
    for (i = 0; i < valueToBeDisplayed.length; i++) {
      returnArry.push({
        label: valueToBeDisplayed[i].label || valueToBeDisplayed[i].attr,
        value: formatData(valueToBeDisplayed[i].attr, modelJSON[valueToBeDisplayed[i].attr]),
        className: valueToBeDisplayed[i].className
      });
    }
    return returnArry.reverse();
  }
  function updateBreadCrumb(isPreview, model, cId, cName, fName, lName, dateCreated, status) {
    var pathPieces;
    if (cId) {
      pathPieces = [{
        label: cName,
        location: (isPreview ? "#creatives/preview/" : "#creatives/video/") + cId
      }];
    } else {
      pathPieces = [{
        label: "New Creative",
        location: ""
      }];
    }
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces,
      headerInfo: getBreadCrumbHeaderInfo(model, fName, lName, dateCreated, status)
    });
  }
  CreativesCreateEdit = T1View.extend({
    template: template,
    cors: COMPASS_ENV.VIDEO_API_BASE + "creatives/proxy/?url=",
    tabs: {
      details: 0,
      classification: 1
    },
    eventhubEvents: {
      "video_creative:updated": function (model) {
        fetchBreadCrumbData(model);
      },
      "video_creative:created": function (model) {
        var self = this;
        var tabLayout = this.layout;
        var tabs = this.tabs;
        self.model = model;
        self.updateModels({
          model: self.model
        });
        tabLayout.execute("enable", tabs.classification);
        tabLayout.options.tabs.forEach(function (tab) {
          tab.options.id = self.model.id;
          tab.options.model = self.model;
        });
      },
      "creative:updateModel": function (args) {
        var self = this;
        self.updateModels(args).then(function (model) {
          fetchBreadCrumbData(model.toJSON());
        });
        self.renderPartial(".w-head");
      }
    },
    initialize: function (args) {
      var self = this;
      var mode = args.mode;
      var isPreview = mode && mode.toLowerCase() === "preview" ? true : false;
      if (!T1Permissions.check("feature", "video")) {
        T1.Location.set("#unauthorized", {
          replace: true
        });
        return;
      }
      self.isPreview = isPreview;
      self.isEdit = false;
      self.mode = mode;
      self.model = new VideoCreative();
      NextTabViewNavigation.attachBehavior(self);
      self.initializeData(args);
    },
    initializeData: function (args) {
      var self = this;
      var modelOrgID, modelJSON, modelAdvertiser, modelAgency;
      self.canLoad = false;
      Organizations.getOrganizations().getSelected().then(function (orgID) {
        self.orgID = orgID;
        self.updateModels(args).then(function (model) {
          modelJSON = model.toJSON();
          modelAdvertiser = modelJSON.advertiser;
          modelAgency = modelAdvertiser && modelAdvertiser.agency;
          modelOrgID = modelAgency && modelAgency.organization_id;
          if (modelOrgID && orgID !== modelOrgID) {
            Organizations.getOrganizations().setSelected(modelOrgID).then(function () {
              self.initializeData(args);
            });
            return;
          }
          fetchBreadCrumbData(args);
          self.canLoad = true;
          self.initLayout();
          self.load();
        });
      });
    },
    updateModels: function (args) {
      var self = this;
      var $el = self.el;
      var deferred = When.defer();
      var model = self.model;
      var details;
      var id = parseInt(args.id || model.get("id"), 10);
      var atomicCreative = new AtomicCreative({
        id: id
      });
      if (id) {
        model.set({
          id: id
        });
        self.canLoad = false;
        self.isEdit = true;
        self.isCustomVastUrl = false;
        atomicCreative.fetch({
          params: {
            with: ["advertiser,agency", "built_by_user", "concept"]
          },
          success: function success(atomicmodel) {
            if (atomicmodel.get("media_type") === "display") {
              T1.Location.set("#creatives/display/" + atomicmodel.get("id"));
              return;
            }
            self.model.set({
              isActive: atomicmodel.get("status") === "1",
              isSecure: atomicmodel.get("is_https") === "1",
              advertiser: atomicmodel.get("advertiser"),
              agencyAndAdvertiser: atomicmodel.get("advertiser").get("agency").get("name") + " &raquo; " + atomicmodel.get("advertiser").get("name")
            });
            $el.find(".agencyAndAdvertiser").html(escapeHTML(self.model.get("agencyAndAdvertiser"), true));
            $el.find(".name").html(escapeHTML(atomicmodel.get("name"), true));
            self.isT1AS = model.get("t1as") === "1";
            self.model.sync("read", {
              id: id
            }, {
              success: function (data) {
                self.model.set(data);
                details = data && data.details;
                self.isSkippableChecked = details.skippableDuration !== undefined;
                deferred.resolve(self.model);
              },
              dataType: "json",
              processAjaxResponse: function (data) {
                return {
                  statusCode: "ok",
                  jsonData: data
                };
              }
            }).pipe($.proxy(self.fetchCreativeConcept, self));
          }
        });
      } else {
        self.model = new VideoCreative({
          action: "new"
        });
        deferred.resolve(self.model);
      }
      return deferred.promise;
    },
    fetchCreativeConcept: function () {
      var deferred = $.Deferred();
      var self = this;
      var serializedData = {};
      var success = function () {
        serializedData = self.serialize();
        self.updateDataBind(serializedData, self.el);
        deferred.resolve();
      };
      if (self.isPreview) {
        self.conceptModel = new Concept({
          id: this.model.get("details").get("concept")
        });
        self.conceptModel.fetch({
          success: success
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise();
    },
    initLayout: function () {
      var self = this;
      var model = self.model;
      var tabs = this.tabs;
      var disabledList = [];
      var tabData, layoutOptions;
      layoutOptions = {
        orgID: self.orgID,
        id: model.id,
        mode: self.mode,
        model: model
      };
      tabData = [{
        label: "Details",
        module: "creatives/video",
        viewType: "details",
        routeName: "details",
        showLoader: true,
        options: layoutOptions
      }];
      if (!self.isPreview) {
        tabData.push({
          label: "Classification",
          module: "creatives/video",
          viewType: "classification",
          routeName: "classification",
          showLoader: true,
          options: layoutOptions
        });
        if (!self.isEdit) {
          disabledList = [tabs.classification];
        }
      }
      if (!self.isPreview) {
        self.layout = new T1TabLayout({
          el: function () {
            return $(self.el).find(".w-body");
          },
          tabSetName: "VideoCreativeEditCreate",
          level: 1,
          disabled: disabledList,
          selected: tabs.details,
          tabs: tabData
        });
      } else {
        self.layout = new T1Layout({
          el: function () {
            return self.el;
          },
          selectorPath: ".w-body-vid",
          template: "<div class='preview'></div>",
          serialize: $.proxy(self.serialize, self),
          layout: {
            ".preview": [{
              module: "creatives/video",
              viewType: "details",
              options: layoutOptions
            }]
          }
        });
      }
    },
    load: function () {
      var self = this;
      if (!this.canLoad) {
        return;
      }
      this.render().then(function () {
        self.layout.load();
      });
    },
    canUnload: function () {
      return this.layout ? this.layout.canUnload() : true;
    },
    serialize: function () {
      var self = this;
      var vast;
      var output = this.model.toJSON();
      var durationFormat = "mm:ss";
      var seconds = output.duration * 1e3;
      var details = this.model.get("details");
      output.duration = moment.utc(seconds).format(durationFormat);
      output.id = this.model.id;
      var creativeName = escapeHTML(details.get("name"), true);
      if (creativeName) {
        output.name = creativeName;
        output.name_id = creativeName + " (" + output.id + ")";
      } else {
        output.name = "Create New";
      }
      output.isEdit = this.isEdit;
      output.isPreview = this.isPreview;
      output.isFileEditable = !this.isEdit || !output.isUploaded;
      output.isSecure = output.isSecure ? "Secure" : "Non-secure";
      output.dateCreated = this.dateCreated;
      output.showSkippable = output.isVideoAssets;
      output.isSkippableChecked = this.isSkippableChecked;
      output.agencyAndAdvertiser = this.model.get("agencyAndAdvertiser");
      if (this.isPreview && this.conceptModel) {
        output.concept = this.conceptModel.get("name") + " (" + this.conceptModel.id + ")";
      }
      if (details.get("customVAST")) {
        vast = details.get("customVAST");
        self.xmlDoc = $.parseXML(vast.trim()).documentElement;
        output.duration = $(self.xmlDoc).find("Duration").first().text().substr(-5) || moment.utc(seconds).format(durationFormat);
        output.duration = output.duration !== "" ? output.duration : "--";
      } else if (details.get("customVASTUrl")) {
        vast = details.get("customVASTUrl");
        $.get(self.cors + encodeURIComponent(vast)).done(function (data) {
          output.duration = $(data).find("Duration").first().text().substr(-5) || moment.utc(seconds).format(durationFormat);
          output.duration = output.duration !== "" ? output.duration : "--";
        }).fail(function () {
          $.get(vast.replace(/^http:\/\//, "https://")).done(function (data) {
            output.duration = $(data).find("Duration").first().text().substr(-5) || moment.utc(seconds).format(durationFormat);
            output.duration = output.duration !== "" ? output.duration : "--";
          });
        });
      }
      return output;
    }
  });
  return CreativesCreateEdit;
});
