define('modules/reporting/campaigns/contextual/views/grid',["jQuery", "Underscore", "Mustache", "T1", "T1View", "T1GridViewV2", "T1PerformanceTimer", "modules/reporting/campaigns/utils/gridColumnResize", "text!../templates/gridHeader.html", "text!../templates/gridFooter.html", "text!../templates/item.html", "text!../templates/subitem.html"], function ($, _, Mustache, T1, T1View, T1GridView, T1Timer, gridColumnResize, gridHeaderTemplate, gridFooterTemplate, itemTemplate, subItemTemplate) {
  "use strict";

  var $window = $(window);
  var viewportHeight = $window.height() || 0;
  var viewportLeft = $window.scrollLeft() || 0;
  var formatCurrency = T1.Utils.formatCurrency;
  var formatAs = T1.Formats.as;
  var GAMap = {
    render: "RPTG:CONTEXTUAL:GRID:RENDER"
  };
  var GridView = T1View.extend({
    partials: {
      gridFooter: gridFooterTemplate,
      gridHeader: gridHeaderTemplate,
      listItem: itemTemplate
    },
    keys: [],
    headers: [],
    mainKey: "target_name",
    dimensionKey: "target_id",
    scrollVLimit_chartOn: 546,
    scrollVLimit_chartOff: 192,
    section: "contextual",
    targetNameList: [],
    sortConfigs: {
      order: {
        default: "descending",
        custom: {
          Strategy_Name: "ascending"
        }
      },
      sortBy: function (sortKey, sortOrder) {
        var isName = this.keys[0] === sortKey || sortKey === "path" ? "1" : "0";
        var order = sortOrder === "ascending" ? 1 : -1;
        this.reportModel.setSortKeyInfo(`${sortKey},${order},${isName}`);
      }
    },
    events: {
      "click .icon": "toggleOpen",
      "click .item": "toggleHighlighted",
      "click .legend-chart": "toggleSelected"
    },
    dataEvents: {
      collection: {
        sort: "reload"
      },
      reportModel: {
        "change:columnKeys": "reload",
        "change:dimensionItems": "reload",
        "change:filteredOutItems": "filterOutItems",
        "change:highlightedItem": "highlightItem"
      }
    },
    eventhubEvents: {
      "chart.toggle": "chartToggled",
      "chart.toggleEnd": "chartToggleEnded",
      "grid.resetSearch": "reload",
      "grid.searchData": "reload"
    },
    initialize: function (args) {
      var self = this;
      var models = args.models;
      this.campaign = models.campaign;
      this.mainModel = models.mainModel;
      this.reportMeta = models.reportMeta;
      this.reportModel = models.reportModel;
      this.collection = args.collection;
      this.dailyCollection = args.dataGroup.dailyCollection;
      this.type = args.type;
      this.scrollVLimit = this.scrollVLimit_chartOn;
      this.appTop = 82;
      this.gridLeft = 113;
      this.gridHeader = $("");
      this.gridHeaderMode = null;
      this.reportModel.setGridScrollXPos(0);
      this.onScrollVHProxy = $.proxy(this.onScrollVH, this);
      this.viewportResizeListener = function () {
        viewportHeight = $window.height() || 0;
        self.updateScrollBar();
        self.shiftScrollbar($window.scrollTop());
      };
      this.bindViewEvents();
    },
    load: function () {
      var $contentHolder, $gridContentContainer, $gridHeaderContainer, $wDataGrid, offset, span, timerData;
      var self = this;
      var empiricalUndermeasurement = 52;
      var reportModel = this.reportModel;
      var savedGridScrollXPos = reportModel.get("scrollLeft");
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (!this.reportModel.get("columnKeys")) {
        return;
      }
      timerData = {
        category: GAMap.render,
        label: this.campaign.id
      };
      $window.off("scroll", this.onScrollVHProxy).on("scroll", this.onScrollVHProxy);
      $window.off("resize", this.viewportResizeListener).on("resize", this.viewportResizeListener);
      this.dataRendered = false;
      T1Timer.start(timerData);
      this.sortData(true);
      this.prepareRenderData();
      this.prepareGridData();
      $(".rptg-icon-edit-tip").remove();
      $(".rptg-icon-info-tip").remove();
      this.render().then(function () {
        self.recalculateSettings();
        T1Timer.stop(timerData);
        self.initColumnResize();
        self.dataRendered = true;
      });
      $wDataGrid = this.el.find(".w-DataGrid");
      $gridHeaderContainer = $wDataGrid.find(".header-container");
      $gridContentContainer = $wDataGrid.find(".content-c");
      this.$contentHolder = $contentHolder = $wDataGrid.find(".content-holder");
      $contentHolder.off("scroll").on("scroll", function () {
        var gridScrollXPos = $contentHolder.scrollLeft();
        if ($gridHeaderContainer) {
          $gridHeaderContainer.css("left", `${-gridScrollXPos}px`);
          reportModel.setGridScrollXPos(gridScrollXPos);
        }
      });
      if (savedGridScrollXPos !== 0) {
        this.$contentHolder.scrollLeft(savedGridScrollXPos);
      }
      offset = $contentHolder.offset();
      if (offset !== null) {
        this.gridLeft = offset.left;
      }
      offset = $(".app-container").offset();
      if (offset !== null) {
        this.appTop = offset.top;
      }
      span = $gridHeaderContainer.width() + empiricalUndermeasurement;
      if (span > $gridContentContainer.width()) {
        $gridContentContainer.width(span);
      }
      this.recalculateSettings();
    },
    unload: function () {
      if (this.$1stColHeader && this.$1stColHeader.hasClass("ui-resizable")) {
        this.$1stColHeader.find(".ui-resizable-handle").off();
        this.$1stColHeader.resizable("destroy");
        this.$1stColHeader.off();
      }
      if (this.$2ndColHeader && this.$2ndColHeader.hasClass("ui-resizable")) {
        this.$2ndColHeader.find(".ui-resizable-handle").off();
        this.$2ndColHeader.resizable("destroy");
        this.$2ndColHeader.off();
      }
      this.itemCount = null;
      $window.off("scroll", this.onScrollVHProxy);
      $window.off("resize", this.viewportResizeListener);
      this.dataRendered = null;
      if (this.$contentHolder) {
        this.$contentHolder.perfectScrollbar("destroy");
        this.$contentHolder.off("scroll");
      }
    },
    recalculateSettings: function () {
      this.chartToggled(this.mainModel.get("chartShown") === "true");
      this.recalculateSettingsForHeader();
      this.recalculateSettingsForScrollbar();
    },
    recalculateSettingsForHeader: function () {
      var gridHeader = this.el.find(".w-DataGrid .w-head");
      if (this.gridHeader[0] !== gridHeader[0]) {
        this.gridHeader = gridHeader;
        this.gridHeaderMode = null;
      }
      this.shiftHeader($window.scrollTop(), $window.scrollLeft());
    },
    recalculateSettingsForScrollbar: function () {
      var gridList = this.el.find(".content-c");
      var gridListOffset = gridList.offset();
      var gridListTop = gridListOffset ? gridListOffset.top : 0;
      var gridListHeight = gridList.height() || 0;
      var gridListBottom = gridListTop + gridListHeight;
      var $contentHolder = this.$contentHolder;
      if ($contentHolder) {
        this.scrollbar = $contentHolder.find(".ps-scrollbar-x");
        this.scrollbarRangeStart = gridListTop;
        this.scrollbarRangeEnd = gridListBottom;
        if (!this.scrollbar[0]) {
          this.scrollbarRangeEnd = this.scrollbarRangeStart;
        }
        this.shiftScrollbar($window.scrollTop());
      }
    },
    onScrollVH: function () {
      var scrollTop = $window.scrollTop();
      var scrollLeft = $window.scrollLeft();
      this.shiftHeader(scrollTop, scrollLeft);
      this.shiftScrollbar(scrollTop);
    },
    shiftHeader: function (scrollTop, scrollLeft) {
      var gridHeader = this.gridHeader;
      var adjustHorizontally = false;
      var isFrozen = this.gridHeaderMode === "frozen";
      if (!gridHeader[0]) {
        return;
      }
      if (viewportLeft !== scrollLeft) {
        viewportLeft = scrollLeft;
        adjustHorizontally = true;
      }
      if (!isFrozen && scrollTop >= this.scrollVLimit) {
        this.gridHeaderMode = "frozen";
        gridHeader.css({
          left: `${this.gridLeft - viewportLeft}px`,
          position: "fixed",
          top: `${this.appTop}px`,
          zIndex: "2"
        });
        adjustHorizontally = false;
      } else if (this.gridHeaderMode !== "normal" && scrollTop < this.scrollVLimit) {
        this.gridHeaderMode = "normal";
        gridHeader.css({
          left: "0",
          position: "absolute",
          top: "auto",
          zIndex: "auto"
        });
      }
      if (adjustHorizontally && isFrozen) {
        gridHeader.css("left", `${this.gridLeft - viewportLeft}px`);
      }
    },
    shiftScrollbar: function (scrollTop) {
      var viewportBottom, viewportBottomInRange;
      var scrollbar = this.scrollbar;
      var $contentHolder = this.$contentHolder;
      if (!scrollbar[0]) {
        return;
      }
      viewportBottom = scrollTop + viewportHeight;
      viewportBottomInRange = this.scrollbarRangeStart < viewportBottom && this.scrollbarRangeEnd > viewportBottom;
      if (viewportBottomInRange) {
        scrollbar.css({
          "margin-left": `${this.gridLeft}px`,
          bottom: "2px",
          position: "fixed"
        });
        $contentHolder.perfectScrollbar("freezeX");
      } else {
        scrollbar.css({
          "margin-left": 0,
          position: "absolute"
        });
        $contentHolder.perfectScrollbar("thawX");
      }
      $contentHolder.perfectScrollbar("update");
    },
    chartToggled: function (chartShown) {
      this.scrollVLimit = chartShown ? this.scrollVLimit_chartOn : this.scrollVLimit_chartOff;
    },
    chartToggleEnded: function () {
      if (this.scrollVLimit === this.scrollVLimit_chartOff && $window.scrollTop() > this.scrollVLimit) {
        $window.scrollTop(this.scrollVLimit);
      }
      this.recalculateSettingsForHeader();
      this.recalculateSettingsForScrollbar();
    },
    toggleOpen: function (e) {
      var selectedItem = $(e.target).closest(".item");
      var selectedArrow = $(e.target).closest(".arrow");
      var expandedClass = "expanded";
      var loadingClass = "loading";
      var action;
      if (selectedItem.hasClass(expandedClass)) {
        action = "conceal";
        selectedItem.removeClass(expandedClass);
        selectedArrow.removeClass(loadingClass);
      } else {
        action = "reveal";
        selectedItem.addClass(expandedClass);
        selectedArrow.addClass(loadingClass);
        this.appendChildList(e);
      }
      this.recalculateSettingsForScrollbar();
      e.preventDefault();
      if (selectedItem.hasClass("selected")) {
        e.stopPropagation();
      }
      T1.EventHub.publish("track:event", {
        action: "Disclose time series",
        label: action
      });
    },
    appendChildList: function (event) {
      var ele = $(event.target);
      var id = ele.attr("data-id");
      var childRow = ele.closest(".item-row").find(".child-row");
      var html, list;
      this.serialize();
      list = this.nestedItemLookup[id];
      if (!list || childRow.data("loaded")) {
        return;
      }
      html = Mustache.to_html(subItemTemplate, {
        list: list
      });
      childRow.html(html);
      childRow.data("loaded", true);
      event.preventDefault();
      if (this.dataRendered) {
        this.initColumnResize();
      }
    },
    sortData: function (silent) {
      var key, order, sortKeyArray;
      var sortKeyInfo = this.reportModel.get("sortKeyInfo") || "";
      const TYPE = 2;
      if (sortKeyInfo.length > 0) {
        sortKeyArray = sortKeyInfo.split(",");
        key = sortKeyArray[0];
        order = parseInt(sortKeyArray[1], 10);
        this.collection.sortByKey(key, order, this.type, silent, parseInt(sortKeyArray[TYPE], 10));
      }
    },
    setAllNoneClass: function () {
      var reportModel = this.reportModel;
      var hiddenCount = reportModel.getHiddenItemsCount();
      var $legend = $(".header-container .legend-chart.header", this.el);
      if (hiddenCount) {
        $legend.addClass("hide");
      } else {
        $legend.removeClass("hide");
      }
    },
    toggleAll: function (target) {
      var hideClass = "hide";
      var allHidden = target.hasClass(hideClass);
      var itemsToHide;
      var idList = {};
      var $legend = this.el.find(".legend-chart");
      if (allHidden) {
        this.reportModel.resetHiddenItems();
        $legend.removeClass(hideClass);
      } else {
        $legend.addClass(hideClass);
        itemsToHide = this.targetNameList;
        _.each(itemsToHide, function (item) {
          idList[item.id] = true;
        });
        this.reportModel.setHiddenItems(idList);
      }
    },
    toggleSelected: function (e) {
      var hideClass = "hide";
      var target = $(e.target);
      var item = target.closest(".item");
      e.stopPropagation();
      if (target.hasClass("header")) {
        this.toggleAll(target);
        return;
      }
      target.toggleClass(hideClass);
      this.reportModel.setHiddenItem({
        action: target.hasClass(hideClass) ? hideClass : "show",
        id: `${item.data("id")}`,
        type: "strategy"
      });
      T1.EventHub.publish("track:event", {
        action: "Add/remove row from chart",
        label: "legend-chart"
      });
      this.setAllNoneClass();
    },
    toggleHighlighted: function (e) {
      var item = $(e.currentTarget);
      var target = $(e.target);
      e.stopPropagation();
      if (target.hasClass("label")) {
        return;
      }
      this.reportModel.setHighlightedItem({
        from: "row",
        id: item.hasClass("selected") ? null : `${item.data("id")}`,
        type: "strategy"
      });
    },
    highlightItem: function (model) {
      var selectedClass = "selected";
      var contentC = $(this.el).find(".content-c");
      var highlightedItem = model.get("highlightedItem");
      contentC.children(".item").removeClass(selectedClass);
      if (highlightedItem && highlightedItem.id !== null) {
        contentC.find(`.item[data-id="${highlightedItem.id}"]`).addClass(selectedClass);
      }
    },
    filterOutItems: function (model) {
      var filteredInItem, filteredOutItem;
      var $contentC = this.el.find(".content-c");
      var filterOutClass = "filter-out";
      var filteredOutItems = model.get("filteredOutItems") || [];
      var filteredInItems = model.get("filteredInItems") || [];
      var count = this.reportModel.getFilteredInCount();
      for (let i = 0, outLength = filteredOutItems.length; i !== outLength; i++) {
        filteredOutItem = filteredOutItems[i];
        $contentC.find(`.item[data-id="${filteredOutItem.replace(/[']/g, "")}"]`).addClass(filterOutClass);
      }
      for (let inLength = filteredInItems.length, j = 0; j !== inLength; j++) {
        filteredInItem = filteredInItems[j];
        $contentC.find(`.item[data-id="${filteredInItem.replace(/[']/g, "")}"]`).removeClass(filterOutClass);
      }
      this.updateDataBind({
        count: count,
        total_count: count
      }, ".w-foot");
    },
    initColumnResize: function () {
      var headers = gridColumnResize(this.reportModel, this.el, true, true);
      this.$1stColHeader = headers[0];
      this.$2ndColHeader = headers[1];
    },
    prepareRenderData: function () {
      var dimensionInfo = this.reportModel.get("dimensionInfo");
      this.mainKey = dimensionInfo.dimension;
      this.dimensionKey = dimensionInfo.key;
    },
    prepareGridData: function () {
      var columnHeaderWidth, firstColumnWidths, isFirstCol, isFirstColumnWidthSaved, isSecondCol, isSecondColumnWidthSaved, isWidthSaved, reportMetaObj, secondColumnWidths;
      var headers = [];
      var reportMeta = this.reportMeta;
      var reportModel = this.reportModel;
      var sortKeyInfo = reportModel.get("sortKeyInfo") || "";
      var sortKeyArray = sortKeyInfo.split(",");
      var sortKey = sortKeyArray[0];
      var sortOrder = parseInt(sortKeyArray[1], 10);
      var columns = {};
      var defaultHeaders = {};
      var secondColumn = "path";
      this.reportMetaObj = reportMetaObj = $.extend({}, reportMeta.toJSON());
      this.keys = [this.mainKey, secondColumn].concat(reportModel.get("columnKeys"));
      defaultHeaders[this.mainKey] = "TARGET NAME";
      defaultHeaders[secondColumn] = secondColumn.toUpperCase();
      columns = $.extend(defaultHeaders, reportModel.get("formattedHeaderValues"));
      firstColumnWidths = reportModel.get("firstColumnWidths");
      secondColumnWidths = reportModel.get("secondColumnWidths");
      isFirstColumnWidthSaved = Boolean(firstColumnWidths);
      isSecondColumnWidthSaved = Boolean(secondColumnWidths);
      _.each(this.keys, function (metric, i) {
        var field = reportMetaObj.fields[metric];
        var format = field.type;
        var label = columns[metric];
        isFirstCol = i === 0;
        isSecondCol = i === 1;
        isWidthSaved = false;
        if (isFirstCol && isFirstColumnWidthSaved) {
          columnHeaderWidth = firstColumnWidths.header;
          isWidthSaved = true;
        }
        if (isSecondCol && isSecondColumnWidthSaved) {
          columnHeaderWidth = secondColumnWidths.header;
          isWidthSaved = true;
        }
        headers.push({
          ascending: sortOrder === 1,
          columnHeaderWidth: columnHeaderWidth,
          field: metric,
          format: format,
          isSortable: true,
          isWidthSaved: isWidthSaved,
          label: label,
          name: format,
          showSortOrder: metric === sortKey
        });
      });
      this.headers = headers;
    },
    prepareListForNoDataMessage: function (list) {
      const THIRD_ROW = 2;
      for (let i = 0, length = 4; i !== length; i++) {
        list.push({
          blank: "blank",
          id: "",
          msg: ""
        });
      }
      list[THIRD_ROW].msg = "No data available for this selection";
      list[THIRD_ROW].blank = "";
      return list;
    },
    serialize: function () {
      var reportModel = this.reportModel;
      var collection = this.collection;
      var dailyCollection = this.dailyCollection;
      var summaryItemJson = collection && collection.loaded ? collection.toJSON() : [];
      var dailyData = dailyCollection && dailyCollection.loaded ? dailyCollection.toJSON() : [];
      var reportMetaObj = this.reportMetaObj;
      var list = [];
      var keys = this.keys;
      var self = this;
      var item = {
        keys: []
      };
      var highlightedItem = reportModel.get("highlightedItem");
      var highlightedStrategy = highlightedItem && highlightedItem.type === "strategy" ? highlightedItem : null;
      var hiddenItems = reportModel.get("hiddenItems") || {};
      var filteredOutItems = reportModel.get("filteredOutItems") || [];
      var colorData = reportModel.get("colorData");
      var itemsHidden = reportModel.getHiddenItemsCount();
      var searchTerm = reportModel.getSearchTerm().toLowerCase();
      var columnChildrenWidth, columnNamesWidth, columnSubRowsWidth, count, is1stCol, is2ndCol, isWidthSaved;
      var currencyCode = "USD";
      var firstColumnWidths = reportModel.get("firstColumnWidths");
      var secondColumnWidths = reportModel.get("secondColumnWidths");
      var is1stColumnWidthSaved = Boolean(firstColumnWidths);
      var isSecondColumnWidthSaved = Boolean(secondColumnWidths);
      if (summaryItemJson && colorData) {
        _.each(summaryItemJson, function (rowData) {
          var id = rowData[self.dimensionKey];
          item = {
            keys: []
          };
          item.id = id = id && id.replace(/[']/g, "");
          item.isSelected = highlightedStrategy && highlightedStrategy.id === id;
          item.isFilteredOut = filteredOutItems.indexOf(id) > -1;
          item.targetName = rowData.target_name || "";
          if (searchTerm === "" || searchTerm !== "" && (item.targetName.toLowerCase().indexOf(searchTerm) !== -1 || rowData.path.toLowerCase().indexOf(searchTerm) !== -1)) {
            _.each(keys, function (metric, i) {
              var format = reportMetaObj.fields[metric].type;
              var value = rowData[metric] ? rowData[metric] : "--";
              var formattedValue = value === "--" ? value : format === "currency" ? formatCurrency(value, null, currencyCode) : formatAs(format)(value);
              is1stCol = i === 0;
              is2ndCol = i === 1;
              isWidthSaved = false;
              if (is1stCol && is1stColumnWidthSaved) {
                columnChildrenWidth = firstColumnWidths.children;
                columnNamesWidth = firstColumnWidths.names;
                columnSubRowsWidth = firstColumnWidths.subrows;
                isWidthSaved = true;
              }
              if (is2ndCol && isSecondColumnWidthSaved) {
                columnChildrenWidth = secondColumnWidths.children;
                columnNamesWidth = secondColumnWidths.names;
                columnSubRowsWidth = secondColumnWidths.subrows;
                isWidthSaved = true;
              }
              item.keys.push({
                checked: !hiddenItems[id],
                colorIndex: colorData[id] + 1,
                columnChildrenWidth: columnChildrenWidth,
                columnNamesWidth: columnNamesWidth,
                columnSubRowsWidth: columnSubRowsWidth,
                format: format,
                isName: metric === self.mainKey,
                isPath: metric === "path",
                isWidthSaved: isWidthSaved,
                value: formattedValue
              });
            });
            list.push(item);
          }
        });
      }
      if (colorData && dailyData && dailyData.length) {
        const groupedData = _.groupBy(dailyData, this.dimensionKey);
        const sortedData = _.mapObject(groupedData, data => _.sortBy(data, "start_date"));
        const nestedItemLookup = _.mapObject(sortedData, function (dataSet) {
          var nestedItems = [];
          _.each(dataSet, function (dayObj) {
            var nestedItem = {
              keys: []
            };
            _.each(keys, function (metric, i) {
              var format = reportMetaObj.fields[metric].type;
              var value = dayObj[metric] ? dayObj[metric] : "--";
              var formattedValue = value === "--" ? value : format === "currency" ? formatCurrency(value, null, currencyCode) : formatAs(format)(value);
              var isName = metric === self.mainKey;
              var isPath = metric === "path";
              is1stCol = i === 0;
              is2ndCol = i === 1;
              isWidthSaved = false;
              if (is1stCol && is1stColumnWidthSaved) {
                columnSubRowsWidth = firstColumnWidths.subrows;
                isWidthSaved = true;
              }
              if (is2ndCol && isSecondColumnWidthSaved) {
                columnSubRowsWidth = secondColumnWidths.subrows;
                isWidthSaved = true;
              }
              nestedItem.keys.push({
                columnSubRowsWidth: columnSubRowsWidth,
                format: isName ? "date" : format,
                isName: false,
                isWidthSaved: isWidthSaved,
                value: isName ? dayObj.start_date : isPath ? "--" : formattedValue
              });
            });
            nestedItems.push(nestedItem);
          });
          return nestedItems;
        });
        this.nestedItemLookup = nestedItemLookup;
      }
      this.itemCount = reportModel.get("dimensionItemsCount");
      count = list.length;
      if (reportModel.get("dimensionItems") && this.itemCount === 0 || searchTerm !== "" && count === 0) {
        list = this.prepareListForNoDataMessage(list);
      }
      this.targetNameList = list;
      return {
        contentType: "series-grid",
        hideNoResultMessage: true,
        isExpandable: true,
        itemsHidden: itemsHidden,
        list: list,
        pageControls: {
          areas: "Target Names",
          count: count,
          total: count
        }
      };
    }
  });
  return T1GridView(GridView);
});
