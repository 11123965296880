define('T1Notifier',["T1", "jQuery", "jQPlugins/sticky.full"], function (T1, $) {
  T1.Notify = function (type, messages, duration, duplicates) {
    var msgTypes = {
      error: "notify-error",
      warning: "notify-warning",
      message: "notify-message"
    };
    var msgType = msgTypes[type] || msgTypes.message;
    var settings = {
      duplicates: duplicates,
      autoclose: duration,
      className: msgType
    };
    var notifications = [];
    var hasNoMessages = msgType === undefined || messages === undefined || messages.length < 1;
    var isStringMsg;
    var isMultipleMsgs;
    var i = 0;
    if (hasNoMessages) {
      return false;
    }
    isStringMsg = typeof messages === "string" && messages.length > 0;
    isMultipleMsgs = $.isArray(messages) && messages.length > 0;
    if (isStringMsg) {
      notifications.push(messages);
    } else if (isMultipleMsgs) {
      notifications = messages;
    }
    for (i = 0; i < notifications.length; i++) {
      $.sticky(notifications[i], settings);
    }
    return true;
  };
});
