define('collections/creativesConceptsCampaigns',["jQuery", "T1", "T1Collection", "models/conceptCampaign", "T1Sortable"], function ($, T1, T1Collection, ConceptCampaign) {
  T1.Models.CreativesConceptsCampaigns = T1Collection.extend({
    model: ConceptCampaign,
    canCache: false,
    searchConfiguration: {
      fields: {
        name: {
          type: "string"
        }
      }
    },
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: true
      }, {
        entity: "agency",
        fetchOnChange: true
      }, {
        entity: "organization",
        fetchOnChange: false
      }]
    },
    urlString: "campaigns"
  });
  return T1.Models.CreativesConceptsCampaigns;
});
