define('modules/creatives/componentBased/views/createEditBulkTableReview',["jQuery", "Underscore", "When", "T1", "T1View", "T1Layout", "text!../templates/createEditBulkTableReviewBody.html"], function ($, _, When, T1, T1View, T1Layout, template) {
  "use strict";

  var ComponentCreateEditTableReview = T1View.extend({
    template: template,
    creativesData: {},
    initialize: function (args) {
      this.creativesData = args.data;
      this.uploadableData = args.uploadableData;
      this.nonUploadableData = args.nonUploadableData;
    },
    events: {},
    eventhubEvents: {},
    load: function () {
      var self = this;
      var $el = $(self.el);
      self.render().then(function () {
        var uploadableGrid = document.querySelector("#componentUploadableGridReview");
        var nonUploadableGrid = document.querySelector("#componentNonUploadableGridReview");
        nonUploadableGrid.data = self.nonUploadableData;
        uploadableGrid.data = self.uploadableData;
        $("#uploadable-count").html(self.uploadableData.length);
        $("#non-uploadable-count").html(self.nonUploadableData.length);
        var total = self.uploadableData.length + self.nonUploadableData.length;
        $(".w-ComponentBasedAdd .table-review .component-bulk-upload-total-count").html(total);
        setTimeout(function () {
          _.each(self.nonUploadableData, function (d) {
            if (d.reasonTooltip === undefined) {
              var tooltipIconElem = document.querySelector("#reason-tooltip-icon-" + d.htmlIdentifier);
              if (tooltipIconElem) {
                tooltipIconElem.parentNode.removeChild(tooltipIconElem);
              }
            } else {
              T1.Tooltip($el, {
                className: "component-icon-error-tip",
                delayIn: 10,
                delayOut: 10,
                fade: true,
                getExternalTip: false,
                gravity: "s",
                html: true,
                offset: 10,
                tooltipEle: ".component-bulk-error-msg .icon-tooltip"
              });
            }
          });
        }, 500);
      });
    },
    render: function () {
      var self = this;
      var $el = self.el;
      var deferred = $.Deferred();
      Polymer.dom($el[0]).innerHTML = template;
      $el.css("visibility", "visible");
      $el.addClass("fade-in");
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      self.bindViewEvents();
      return deferred.promise();
    }
  });
  return ComponentCreateEditTableReview;
});
