define('modules/strategy/views/helpers/siteListSummaryTarget',["Underscore", "jQuery"], function (_, $) {
  "use strict";

  return {
    prepareForSerialize: function (strategy, siteListCollection) {
      var siteLists = [];
      var collObj = siteListCollection;
      var resultJSON = {
        data: []
      };
      var buildForDomainRestrictions = function (nameVal, action) {
        return {
          label: nameVal,
          action: action ? "include" : "exclude"
        };
      };
      var sortIncEx = function (a, b) {
        if (a.action === "include" && b.action === "exclude") {
          return -1;
        }
        if (a.action === "exclude" && b.action === "include") {
          return 1;
        }
        return 0;
      };
      if (collObj && collObj.models && collObj.models.length) {
        $.each(collObj.models, function (index, model) {
          var isActive = model.get("status") === "1";
          if (model.get("assigned") === "1" && isActive) {
            siteLists.push({
              action: model.get("restriction") === "INCLUDE" ? "include" : "exclude",
              label: model.get("name")
            });
          }
        });
      }
      siteLists.sort(sortIncEx);
      if (siteLists.length) {
        resultJSON.data.push({
          items: siteLists,
          header: "Applied Site Lists"
        });
      } else {
        resultJSON.data.push({
          items: [{
            action: "none",
            label: "No site lists applied"
          }],
          header: "Applied Site Lists"
        });
      }
      if (strategy) {
        const domainRestrictions = strategy.getDomainRestrictions();
        const restrictBidIsChecked = strategy.get("site_restriction_transparent_urls") !== "0";
        if (domainRestrictions && domainRestrictions.length) {
          const firstDomainModel = domainRestrictions.models[0];
          const restrictionAction = firstDomainModel.get("restriction") === "INCLUDE";
          resultJSON.data.push({
            header: "Additional Sites",
            items: [buildForDomainRestrictions(`Additional Sites (${domainRestrictions.data.count})`, restrictionAction)]
          });
        }
        if (restrictBidIsChecked) {
          resultJSON.data.push({
            header: "Transparency Targeting",
            items: [{
              action: "none",
              label: "Enabled"
            }]
          });
        }
      }
      return resultJSON;
    }
  };
});
