define('modules/creatives/video/main',["jQuery", "T1", "T1Module", "T1Permissions", "collections/advertisers", "collections/organizations", "models/userPreferences"], function ($, T1, T1Module, T1Permissions, Advertisers, Organizations) {
  "use strict";

  var instance;
  var advertisers = new Advertisers(null, {
    isPaginated: false
  });
  if (instance === undefined) {
    instance = new T1Module({
      name: "creatives/video",
      viewCfgs: {
        details: {},
        classification: {},
        createEdit: {},
        list: {
          advertisers: advertisers
        },
        grid: {}
      },
      defaultView: "createEdit",
      initialize: function () {
        var orgPromise = Organizations.getOrganizations().getSelected();
        orgPromise.then(function (orgId) {
          advertisers.urlFilter.set({
            entity: "organization",
            id: orgId
          });
        });
        window.Config = window.Config || {};
        window.Config.CLASSIFICATION = COMPASS_ENV.CREATIVES_CLASSIFICATION_URL || "/";
      }
    });
  }
  return instance;
});
