define('modules/shared/views/strategy_list',["jQuery", "Underscore", "T1", "T1View", "text!../templates/strategy_list.html", "text!../templates/strategy_content.html", "T1Layout", "collections/organizations", "collections/strategies", "collections/campaigns", "utils/SearchBuilder"], function ($, _, T1, T1View, template, subtemplate, T1Layout, Organizations, Strategies, Campaigns, SearchBuilder) {
  return T1View.extend({
    template: template,
    selected: "",
    searchFilters: null,
    fetchRequired: true,
    loaderOpts: {
      target: ".content-holder",
      text: "Loading...",
      contentClass: "search-loader search-adjust"
    },
    eventhubEvents: {
      "strategyList:search": function (args) {
        this.searchStrategyLists.call(this, args);
      }
    },
    initialize: function (args) {
      this.searchLoader = this.loader();
      this.conceptID = args.conceptID;
      this.dataEvents = {};
      this.initLayout();
      this.collection.urlFilter.set({
        entity: "concept",
        id: this.conceptID
      });
      this.searchBuilder = new SearchBuilder({
        active_state: {
          active: {
            term: "1",
            searchField: "status"
          },
          inactive: {
            term: "0",
            searchField: "status"
          }
        },
        advertiser: {
          id: {
            term: this.advID,
            searchField: "advertiser_id"
          }
        },
        search: {
          input: {
            term: this.searchTerm,
            searchField: "multiField"
          }
        }
      });
      this.search();
    },
    initLayout: function () {
      var self = this;
      var filterAndClearCallback = function (term) {
        T1.EventHub.publish("strategyList:search", {
          term: term || ""
        });
      };
      this.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".strategy-list-list-container",
        tmplPath: "modules/shared/templates/strategy_content.html",
        wrapperEl: "<div></div>",
        layout: {
          ".filters": [{
            module: "shared",
            viewType: "filter",
            options: {
              filterCallback: filterAndClearCallback,
              clearFilterCallback: filterAndClearCallback
            }
          }],
          ".strategy-list-grid": [{
            module: "shared",
            viewType: "strategy_grid",
            options: {
              collection: self.collection,
              searchTerm: self.searchTerm
            }
          }]
        },
        serialize: function () {
          var selectivenessOptions;
          var showRestrictBidCheck = false;
          var restrictBidIsChecked = false;
          return {
            isStrategyView: self.strategy !== undefined,
            selectivenessOptions: selectivenessOptions,
            isRestrictBidCheckBoxVisible: showRestrictBidCheck,
            isRestrictBidChecked: restrictBidIsChecked
          };
        }
      });
    },
    load: function () {
      var self = this;
      var $el = self.el;
      this.render().then(function () {
        self.layout.load().then(function () {
          T1.Selectbox($(".list-footer .select", $(self.el)), {
            onChange: function (args) {
              self.onSelectChanged(args);
            }
          });
          setTimeout(function () {
            var $strategyContainer = $el.find(".strategy-list-list-container");
            var $strategyInput = $strategyContainer.find("input");
            if (!self.campaignID) {
              $strategyInput.prop("disabled", true);
              $strategyInput.css("opacity", "0.5");
              $strategyInput.addClass("hide-magnifying-glass");
              if (self.campaignID !== "") {
                $strategyContainer.find("dd").append('<strand-spinner radius="9" fill-color="#333333" style="margin: -2px 0 0 -28px;"></strand-spinner>');
              }
            }
          }, 200);
        });
      });
    },
    unload: function () {
      this.isActive = 1;
      this.searchTerm = "";
    },
    search: function () {
      var searchTerm = this.searchTerm;
      if (this.collection) {
        this.collection.reset();
      }
      if (searchTerm && searchTerm !== "") {
        this.searchStrategies();
      } else {
        this.searchStrategyConcepts();
      }
    },
    searchStrategyConcepts: function (stratIDs, fromStrategy) {
      var self = this;
      var $el = self.el;
      var searchConfig = [];
      var searchTerm = this.searchTerm;
      var collection = this.collection;
      var filterConfiguration = [];
      var $strategyInput, model, strategy, campaign;
      this.removeArray = [];
      this.searchLoader.start();
      this.countStrats = 0;
      this.totalStrats = 0;
      if (searchTerm && searchTerm !== "" && fromStrategy) {
        filterConfiguration.push({
          entity: "strategy",
          fetchRequired: false
        });
        collection.urlFilter.setConfiguration(filterConfiguration);
        collection.urlFilter.set({
          entity: "strategy",
          id: "(" + stratIDs + ")"
        });
      } else {
        filterConfiguration.push({
          entity: "concept",
          fetchRequired: false
        });
        collection.urlFilter.setConfiguration(filterConfiguration);
        collection.urlFilter.set({
          entity: "concept",
          id: this.conceptID
        });
      }
      if (this.conceptID !== null || searchTerm && searchTerm !== "") {
        self.campIDs = self.campIDs ? self.campIDs : [];
        collection.search.set({
          params: searchConfig,
          fetchRequired: true,
          success: function (data) {
            T1.EventHub.publish("strategygrid:setHighlightValue", self.searchTerm);
            if (!self.searchTerm) {
              T1.EventHub.publish("strategygrid:fetchSuccess", {
                strategies: data
              });
              model = data.models && data.models[0];
              strategy = model && model.get("strategy");
              campaign = strategy && strategy.campaign;
              self.campaignID = campaign ? campaign.id : "";
              $strategyInput = $el.find(".strategy-list-list-container input");
              $strategyInput.prop("disabled", false);
              $strategyInput.css("opacity", "1");
              $strategyInput.removeClass("hide-magnifying-glass");
              $el.find("strand-spinner").hide();
            }
            self.totalStrats = data.length;
            self.searchLoader.stop();
            data.each(function (strat) {
              var stratID = strat.id;
              var campID = strat.get("strategy") ? strat.get("strategy").campaign_id : "";
              self.campIDs.push(campID);
              self.countStrats++;
              if (strat.get("concept").id !== self.conceptID) {
                self.removeArray.push(stratID);
              }
              if (self.countStrats >= self.totalStrats) {
                self.collection.remove(self.removeArray);
              }
            });
            self.campIDs = self.campIDs.reduce(function (a, b) {
              if (a.indexOf(b) < 0) {
                a.push(b);
              }
              return a;
            }, []);
            self.collection.trigger("reset", self.collection);
          }
        });
      } else {
        self.searchLoader.stop();
        T1.EventHub.publish("strategygrid:fetchSuccess", {});
      }
    },
    getUrlFilter: function (collection, url, ids) {
      collection.urlFilter.set({
        entity: url,
        id: "(" + ids + ")"
      });
    },
    searchStrategies: function () {
      var self = this;
      var searchTerm = this.searchTerm;
      var counter = 0;
      this.collectStrategies = new Strategies(null, {
        pageLimit: 100
      });
      this.searchLoader.start();
      this.isSearching = false;
      this.stratIDs = [];
      if (searchTerm && searchTerm !== "") {
        this.collectStrategies.searchConfiguration = $.extend(true, this.collectStrategies.searchConfiguration, {
          fields: {
            multiField: {
              type: "multiField"
            }
          }
        });
        this.isSearching = true;
        if (self.campIDs.length) {
          self.getUrlFilter(self.collectStrategies, "campaign", self.campIDs);
        }
        if ($.isNumeric(self.searchTerm)) {
          self.getUrlFilter(self.collectStrategies, "campaign", self.searchTerm);
          self.collectStrategies.search.set({
            fetchRequired: true,
            success: function (data) {
              if (data.count !== 0) {
                self.getStrategyIds(data, counter);
              } else {
                self.getUrlFilter(self.collectStrategies, "strategy", self.searchTerm);
                self.collectStrategies.search.set({
                  fetchRequired: true,
                  params: [{
                    searchField: "multiField",
                    term: self.searchTerm
                  }],
                  success: function (stratData) {
                    if (stratData.count !== 0) {
                      self.getStrategyIds(stratData, counter);
                    }
                  }
                });
              }
            }
          });
        } else {
          self.collectStrategies.search.set({
            fetchRequired: true,
            params: [{
              searchField: "multiField",
              term: self.searchTerm
            }],
            success: function (data) {
              if (data.count !== 0) {
                self.getStrategyIds(data, counter);
              } else {
                self.searchCampaigns();
              }
            }
          });
        }
      }
    },
    searchCampaigns: function () {
      var self = this;
      var collectCampaigns = new Campaigns();
      collectCampaigns.pageLimit = 100;
      self.getUrlFilter(collectCampaigns, "strategy", self.campIDs);
      collectCampaigns.searchConfiguration = $.extend(true, collectCampaigns.searchConfiguration, {
        fields: {
          name: {
            type: "string",
            useWildCard: true
          },
          id: {
            type: "enum"
          },
          multiField: {
            type: "multiField"
          }
        }
      });
      collectCampaigns.search.set({
        fetchRequired: true,
        params: [{
          searchField: "multiField",
          term: self.searchTerm
        }],
        success: function (campData) {
          self.getCampaignData(campData);
        }
      });
    },
    getStrategyIds: function (data, counter) {
      var self = this;
      var totalCount = data.length;
      self.searchLoader.stop();
      self.isSearching = false;
      data.each(function (model) {
        counter++;
        self.stratIDs.push(model.id);
        if (counter >= totalCount) {
          self.searchStrategyConcepts(self.stratIDs, true);
        }
      });
    },
    getCampaignData: function (data) {
      var self = this;
      var counter = 0;
      self.campaIDs = [];
      _.each(data.models, function (item) {
        self.campaIDs.push(item.get("id"));
      });
      self.getUrlFilter(self.collectStrategies, "campaign", self.campaIDs);
      self.collectStrategies.search.set({
        fetchRequired: true,
        success: function (cdata) {
          self.getStrategyIds(cdata, counter);
        }
      });
    },
    searchStrategyLists: function (data) {
      var term = data.term;
      this.searchTerm = term;
      this.search();
    },
    updateAdditionalStrategyCount: function () {
      this.updateDataBind({
        "additional-strategy-count": "(" + this.domainRestrictions.data.count + ")"
      }, $(this.el));
    },
    onFormClose: function () {
      $(this.el).find(".list-footer .action.active").removeClass("active");
      delete this.layout.layout[".additional-strategy-dialog"];
    },
    onSelectChanged: function (e) {
      var selectedIndex = e.target.selectedIndex;
      var $el = $(".list-footer .checkBoxHolder", $(this.el));
      if (selectedIndex === 0) {
        $el.show();
      } else {
        $el.hide();
      }
    }
  });
});
