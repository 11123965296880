define('modules/error/views/errorPopup',["jQuery", "T1", "T1View", "text!modules/error/templates/errorPopup.html"], function ($, T1, T1View, template) {
  "use strict";

  var errorPopup = T1View.extend({
    template: template,
    initialize: function (args) {
      if (sessionStorage.getItem("browser.adBlockEnabled")) {
        return;
      }
      this.onCloseCallback = args.onCloseCallback || $.noop;
    },
    events: {
      "click .ui-cancel": "closeDialog"
    },
    load: function () {
      var self = this;
      var $el = $(this.el);
      this.render().then(function () {
        $el.dialog({
          dialogClass: "w-ErrorPopupDialog",
          closeText: "",
          autoOpen: true,
          modal: true,
          close: function () {
            self.closeDialog.call(self);
          }
        });
        self.delegateEvents();
      });
    },
    closeDialog: function (e) {
      var el = $(this.el);
      if (e) {
        e.preventDefault();
        el.dialog("close");
      }
      this.onCloseCallback();
      el.dialog("destroy");
      this.unload();
    }
  });
  return T1.Form.ViewUtils.extend(errorPopup);
});
