define('models/videoCreativeDetail',["jQuery", "T1", "T1Model"], function ($, T1, T1Model) {
  T1.Models.VideoCreativeDetail = T1Model.extend({
    url: "creatives",
    apiRoot: T1.VIDEO_API_BASE,
    validateAttributes: function (attrs) {
      var errors = [];
      var optionalFields = ["externalIdentifier", "concept_id", "startTime", "endTime", "viewabilityTemplate"];
      var reqFields = {
        advertiser_id: function () {
          return !attrs.advertiser_id;
        },
        name: function () {
          return !attrs.name;
        },
        clickthroughUrl: function () {
          return !attrs.clickthroughUrl && !attrs.customVAST;
        },
        landingUrl: function () {
          return !attrs.landingUrl;
        }
      };
      var requiredField = "This is a required field.";
      var characterMaxMessage = "Name must not exceed 256 characters.";
      const MAX_CHARS = 256;
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && $.inArray(key, optionalFields) === -1) {
          if (reqFields[key]) {
            if (reqFields[key]()) {
              errors.push({
                field: key,
                message: requiredField
              });
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
      });
      if (attrs.name && attrs.name.length > MAX_CHARS) {
        errors.push({
          field: "name",
          message: characterMaxMessage
        });
      }
      if (errors.length) {
        return errors;
      }
    },
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    createEdit: function (rawData, options) {
      var deferred = $.Deferred();
      var errors = this.validateAttributes(rawData);
      var statusInvalid = options.statusInvalid || $.noop;
      if (errors) {
        statusInvalid(errors, "There are errors on the page.", ".details");
        deferred.reject();
      } else {
        deferred.resolve();
      }
      return deferred;
    },
    processAjaxResponse: function (resp) {
      var dataType, jsonData, statusCode;
      function isJson(str) {
        var isJSON;
        try {
          jsonData = JSON.parse(str);
          isJSON = true;
        } catch (e) {
          dataType = "plaintxt";
        }
        return isJSON;
      }
      jsonData = isJson(resp) ? jsonData : resp;
      statusCode = jsonData && jsonData.status.code || resp.status && resp.status.code || "ok";
      return {
        jsonData: jsonData,
        statusCode: statusCode,
        dataType: dataType
      };
    },
    fixture: "-video-creative-detail"
  });
  return T1.Models.VideoCreativeDetail;
});
