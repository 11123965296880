define('modules/campaign/views/utils/changeLogMultiMarkerCalc',["Underscore"], function (_) {
  "use strict";

  const MultiMarkerCalc = function (dataStack, xCalc, markerDates, markerSize, multiMarkerWidth) {
    let referencePos;
    const half = 2;
    const halfMarkerSize = markerSize / half;
    const markerPosArr = [];
    const markerPosLookup = {};
    const multiMarkerArr = [];
    const multiMarkerDates = {};
    const overlapDates = {};
    let overlapArr = [];
    let spacing = markerSize;
    _.each(dataStack, function (item) {
      _.each(markerDates, function (date, index) {
        if (item.start_date === date) {
          const pos = xCalc(item.x) - halfMarkerSize;
          markerPosLookup[date] = pos;
          markerPosArr.push({
            date: date,
            pos: pos
          });
          if (index === 0) {
            referencePos = markerPosLookup[date];
          }
        }
      });
    });
    const markerPosArrLen = markerPosArr.length;
    _.each(markerPosArr, function ({
      pos: pos,
      date: date
    }, index) {
      switch (index) {
        case 0:
          if (markerPosArrLen > 1) {
            if (pos + spacing > markerPosArr[1].pos) {
              spacing = multiMarkerWidth;
              overlapDates[date] = true;
              overlapArr.push({
                date: date,
                pos: pos
              });
            }
          }
          break;
        case markerPosArrLen - 1:
          if (pos < referencePos + spacing) {
            const prevMultiMarkerDate = _.last(multiMarkerArr);
            overlapArr.push({
              date: date,
              pos: pos
            });
            if (prevMultiMarkerDate) {
              overlapDates[date] = true;
              _.each(overlapArr, function (overlapItem) {
                multiMarkerDates[prevMultiMarkerDate].push(overlapItem);
              });
            } else {
              multiMarkerDates[date] = overlapArr;
            }
          }
          break;
        default:
          if (pos < referencePos + spacing) {
            spacing = multiMarkerWidth;
            overlapDates[date] = true;
            overlapArr.push({
              date: date,
              pos: pos
            });
          } else {
            referencePos = pos;
            spacing = markerSize;
            if (overlapArr.length) {
              overlapArr.push({
                date: date,
                pos: pos
              });
              multiMarkerDates[date] = overlapArr;
              multiMarkerArr.push(date);
              overlapArr = [];
            }
          }
      }
    });
    return {
      markerPosLookup: markerPosLookup,
      multiMarkerDates: multiMarkerDates,
      overlapDates: overlapDates
    };
  };
  return MultiMarkerCalc;
});
