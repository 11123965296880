define('modules/creatives/bulkedit/views/assignment',["jQuery", "When", "T1", "T1View", "text!../templates/form_template.html", "text!../templates/assign_body.html", "text!../templates/form_footer.html", "T1Layout"], function ($, When, T1, T1View, template, formBody, formFooter, T1Layout) {
  "use strict";

  var BulkEditAssign = T1View.extend({
    template: template,
    partials: {
      footer: formFooter
    },
    actionsConfig: {
      events: {
        "click .back": "handleBack",
        "click .cancel": "handleCancel"
      }
    },
    eventhubEvents: {
      "bulkedit:assignment": "handleAssign"
    },
    initialize: function (args) {
      this.collection = args.collection;
      this.advertiser = args.advertiser;
      this.bulkAdvertiser = args.bulkAdvertiser;
      this.entityType = args.entityType;
      this.actionsConfig = {
        formActions: [{
          label: "cancel",
          class: "cancel ui-cancel",
          location: "#"
        }, {
          label: "review",
          class: "saveAndContinue btn-primary",
          location: false
        }]
      };
    },
    initLayout: function () {
      var self = this;
      self.layout = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".w-body",
        template: formBody,
        layout: {},
        serialize: function () {
          return {
            entityType: self.entityType,
            entityCount: self.collection.length,
            changeCount: Object.keys(self.formValues).length
          };
        }
      });
    },
    handleBack: function (e) {
      e.preventDefault();
      T1.EventHub.publish("bulkedit:edit");
    },
    handleCancel: function (e) {
      e.preventDefault();
      T1.EventHub.publish("bulkedit:cancel");
    },
    handleAssign: function (data) {
      this.formValues = data.formValues;
      this.initLayout();
      this.load();
    },
    load: function () {
      var self = this;
      var $el = $(self.el);
      var $assignmentView, assignments;
      function showAssignmentView() {
        $assignmentView = $el.find("#creativeAssignments")[0];
        assignments = self.formValues.add_classifications;
        if (assignments && $.isArray(assignments)) {
          assignments = assignments.map(function (item) {
            item.included = true;
            item.data = item;
            return item;
          });
          $assignmentView.updateBin(null, assignments);
        } else {
          $assignmentView.updateBin();
        }
      }
      self.render().then(function () {
        if (self.layout) {
          self.layout.load();
          self.makeTooltips();
          setTimeout(showAssignmentView, 1e3);
        }
      });
    },
    makeTooltips: function () {
      T1.Tooltip(this.el, {
        gravity: "ne",
        tooltipEle: ".entity-tip"
      });
    },
    preparePostData: function () {
      var self = this;
      var $el = $(self.el);
      var $assignmentView = $el.find("#creativeAssignments")[0];
      var addedClassification = $assignmentView.getAssignments("included");
      if (addedClassification && addedClassification.length) {
        this.formValues.add_classifications = addedClassification;
      } else {
        delete this.formValues.add_classifications;
      }
      return this.formValues;
    },
    postData: function (formValues) {
      this.updateFooterMessage();
      T1.EventHub.publish("bulkedit:review", {
        isAfterAssignment: true,
        formValues: formValues
      });
    },
    serialize: function () {
      var data = {};
      var advertiser = this.advertiser.get("name") ? this.advertiser : this.bulkAdvertiser;
      var agency = advertiser ? advertiser.get("agency") : null;
      if (this.collection.length) {
        data.title = "Classification (Step 2)";
        data.advertiser = advertiser ? advertiser.get("name") : "";
        data.agency = agency ? agency.get("name") : "";
        data.count = this.collection.length;
        data.entityType = this.entityType;
        data.entities = this.collection.pluck("name");
        data.backAction = {
          label: "back",
          class: "back ui-cancel",
          location: "#"
        };
      }
      return data;
    }
  });
  return T1.Form.ViewUtils.extend(BulkEditAssign);
});
