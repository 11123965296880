define('models/hyperLocalDesign',["jQuery", "Underscore", "Backbone", "T1", "T1Model", "models/nemoTarget"], function ($, _, Backbone, T1, T1Model, NemoTarget) {
  "use strict";

  return Backbone.Model.extend({
    urlRoot: T1.API_ROOT + "hyperlocal/geopulse/designs",
    initialize: function (options) {
      this.strategy = options && options.strategy;
      this.campaign = this.strategy && this.strategy.get("campaign");
      this.advertiserId = this.campaign && this.campaign.get("advertiser_id");
      T1Model.prototype.initialize.call(this, options);
    },
    fetch: function (opts) {
      var self = this;
      return Backbone.Model.prototype.fetch.call(this, {
        data: {
          advertiser_id: this.advertiserId
        },
        success: function () {
          self.applyDesignAttributes();
          self.applySetAttributes();
          opts.success.apply(self, arguments);
        },
        error: function (collection, response) {
          var resp = response.resp;
          T1.EventHub.publish("comm:error", {
            code: resp.type,
            detail: response.url + ": " + resp.message
          });
        }
      });
    },
    parse: function (response) {
      return response.response.data;
    },
    applyDesignAttributes: function () {
      this.set({
        is_targetable: "1",
        code: "99999999",
        type: "design",
        dimension_code: "hyperlocal",
        child_count: this.get("sets").length,
        cpm: this.strategy.get("geofenceCost")
      });
    },
    applySetAttributes: function () {
      var self = this;
      _.each(this.get("sets"), function (set) {
        set.dimension_code = "hyperlocal";
        set.id = set.set_id;
        set.name = set.set_name;
        set.is_targetable = "1";
        set.parent_value_id = self.get("id");
        set.design = self;
        set.type = "set";
        set.cpm = self.strategy.get("geofenceCost");
        set.status = self.get("status");
      });
    },
    returnForTarget: function (opts) {
      var returnObj;
      var sets = this.get("sets");
      switch (opts.type) {
        case "set":
          returnObj = _.findWhere(sets, {
            targeting_code: opts.target.get("factual_id")
          });
          returnObj = new NemoTarget(returnObj);
          returnObj.parent = this;
          break;
        case "design":
          returnObj = this;
      }
      return returnObj;
    }
  });
});
