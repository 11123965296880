define('models/vendorPixelDomain',["T1", "T1Model", "Backbone", "models/vendorDomain"], function (T1, T1Model, Backbone, VendorDomain) {
  T1.Models.VendorPixelDomain = T1Model.extend({
    url: "vendor_pixel_domain/",
    relations: [{
      type: Backbone.HasMany,
      key: "vendor_pixel",
      relatedModel: "VendorDomain",
      includeInJSON: true
    }]
  });
  return T1.Models.VendorPixelDomain;
});
