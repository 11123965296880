define('modules/T1App/myApps/views/grid_header_section',["jQuery", "Underscore", "Mustache", "When", "T1", "T1View", "T1Permissions", "text!modules/T1App/myApps/templates/grid_header_section.html", "text!modules/T1App/myApps/templates/new_app.html", "../models/newAppModel", "models/sessionUser", "models/t1App", "models/t1Lab", "collections/t1AppPermission", "collections/organizations", "T1Menu", "T1Tooltip", "jQueryUI", "T1Layout", "T1Model"], function ($, _, Mustache, When, T1, T1View, T1Permissions, template, new_app, AppModel, User, T1App, T1Lab, AppPermissionCollection, Organizations) {
  "use strict";

  var CreateNewApp = T1View.extend({
    template: template,
    partials: {
      newapp: new_app
    },
    events: {
      "click .add-app": "showModal",
      "click .close-app-modal": "closeModal",
      "click .saveAndOpen": "saveAndContinue",
      "click .saveAndClose": "saveAndClose",
      "keyup .app-name": "appNameCounter",
      "focusout .app-name": "hideAppNameCounter",
      "click .radio": "changeAppType"
    },
    initialize: function (args) {
      var organizationArray = [];
      var orgIds = [];
      var orgId;
      var self = this;
      self.outputModel = new AppModel();
      this.model = args.model;
      this.canSave = true;
      this.appPermissionCollection = new AppPermissionCollection();
      this.appPermissionCollection.fetch({
        success: function (data) {
          $.each(data.models, function (index, value) {
            orgIds.push(value.attributes.organization_id);
          });
        },
        error: function () {}
      });
      Organizations.getOrganizations().getSelected().then(function (id) {
        orgId = id;
        self.orgId = orgId;
      });
      var orgs = Organizations.createOrganizations();
      orgs.isPaginated = false;
      orgs.search.set({
        idList: orgIds
      });
      orgs.fetch({
        success: function (data) {
          $.each(data.models, function (index, value) {
            if (parseInt(value.attributes.id) === parseInt(orgId)) {
              organizationArray.push({
                name: value.attributes.name,
                value: value.attributes.id,
                selected: true
              });
            } else {
              organizationArray.push({
                name: value.attributes.name,
                value: value.attributes.id
              });
            }
            self.organizationArray = organizationArray;
          });
        }
      });
    },
    changeAppType: function (e) {
      var $el = $(this.el);
      var $ct = $(e.currentTarget);
      var appType = $ct.attr("value");
      if (appType === "app") {
        $(".launch-externally", $el).hide();
      } else if (appType === "lab") {
        $(".launch-externally", $el).css("display", "inline-block").show();
      }
    },
    showModal: function () {
      var $el = $(this.el);
      var self = this;
      var user = User.getUser();
      var isInternal = user.get("type") === "INTERNAL";
      var render;
      var data = {};
      var $addAppModal = $el.find(".add-app-modal");
      data.internalAdmin = isInternal ? true : false;
      if (self.organizationArray.length <= 0) {
        T1.Location.set("#appstore");
      } else {
        data.multipleOrgs = self.organizationArray.length > 1 ? true : false;
        data.orgName = self.organizationArray[0].name;
        data.orgId = self.organizationArray[0].value;
      }
      render = Mustache.to_html(new_app, data, this.partials);
      if (Polymer && Polymer.dom) {
        Polymer.dom($addAppModal[0]).innerHTML = render;
        setTimeout(function () {
          var dataDdl = document.getElementById("org_ddl");
          if (dataDdl) {
            dataDdl.set("data", self.organizationArray);
            dataDdl.set("value", self.orgId);
          }
        }, 0);
      } else {
        $addAppModal.html(render);
      }
      $addAppModal[0].show();
    },
    closeModal: function (modal) {
      modal.preventDefault();
      $(".add-app-modal")[0].hide();
    },
    saveAndContinue: function (e, customCallbacks) {
      var self = this;
      var $parent = this.el.parent();
      var callback = customCallbacks || {
        success: function () {
          var app_id = self.model.get("app_id");
          var labApp = self.model.get("lab");
          var t1AppModel;
          var t1LabModel;
          if (!labApp) {
            t1AppModel = T1App.getT1App();
            t1AppModel.loadAppsConfig();
          } else {
            t1LabModel = T1Lab.getT1Lab();
            t1LabModel.loadAppsConfig();
          }
          setTimeout(function () {
            T1.Location.set("#appstore/edit/" + app_id + "/meta");
          }, 1e3);
        }
      };
      $parent.find("#add-app-primary, #add-app-secondary").attr("disabled", "disabled");
      $parent.find("#add-app-primary, #add-app-secondary").addClass("disabled");
      if (this.canSave) {
        return this.saveOriginal(e, callback);
      }
    },
    saveAndClose: function (e, customCallbacks) {
      var self = this;
      var $parent = this.el.parent();
      var dataDdl;
      var $gridData;
      var user = User.getUser();
      var username = user.get("username");
      var userid = user.get("id");
      $parent.find("#add-app-primary, #add-app-secondary").attr("disabled", "disabled");
      $parent.find("#add-app-primary, #add-app-secondary").addClass("disabled");
      var callback = customCallbacks || {
        success: function () {
          var user = User.getUser();
          var isInternalUser = user.get("type") === "INTERNAL";
          var appId = self.model.get("app_id");
          var appName = self.model.get("app_name");
          var jsonName = self.model.get("json_name");
          var url = self.model.get("url");
          var isExternal = self.model.get("external") ? parseInt(1) : parseInt(0);
          var labApp = self.model.get("lab");
          var appType = labApp ? "Lab" : "App";
          var apiVersion = T1.API_ROOT.split("/");
          var t1AppModel;
          var t1LabModel;
          $parent.find(".add-app-modal")[0].hide();
          $parent.find(".app-name").val("");
          $parent.find(".app-url").val("");
          $parent.find(".app-name-counter").text("45");
          dataDdl = document.getElementById("org_ddl");
          if (dataDdl) {
            dataDdl.set("data", self.organizationArray);
            dataDdl.set("value", self.orgId);
          }
          $gridData = $parent.parent().find("#data-grid")[0];
          if (isInternalUser) {
            $gridData.push("data", {
              appColor: "#3e537b",
              id: appId,
              name: appName,
              json_name: jsonName,
              version_id: "0.1.0",
              deploy_status: "hidden",
              status: "offline",
              dev_team: username,
              dev_ids: [parseInt(userid)],
              total_views: 0,
              appType: appType,
              lab: labApp,
              billing_type: "none",
              external: isExternal,
              url: url,
              api_version: apiVersion[2]
            });
          } else {
            $gridData.push("data", {
              appColor: "#3e537b",
              id: appId,
              name: appName,
              json_name: jsonName,
              version_id: "0.1.0",
              deploy_status: "hidden",
              status: "offline",
              dev_team: username,
              dev_ids: [parseInt(userid)],
              total_views: 0,
              lab: labApp,
              billing_type: "none",
              external: isExternal,
              url: url,
              api_version: apiVersion[2]
            });
          }
          $parent.find("#add-app-primary, #add-app-secondary").attr("disabled", false);
          $parent.find("#add-app-primary, #add-app-secondary").removeClass("disabled");
          if (!labApp) {
            t1AppModel = T1App.getT1App();
            t1AppModel.loadAppsConfig();
          } else {
            t1LabModel = T1Lab.getT1Lab();
            t1LabModel.loadAppsConfig();
          }
        }
      };
      if (this.canSave) {
        return this.saveOriginal(e, callback);
      }
    },
    postData: function (formValues, success, statusInvalid) {
      var self = this;
      var $el = this.el;
      var deferred = When.defer();
      var user = User.getUser();
      var isInternal = user.get("type") === "INTERNAL";
      var newFormValues;
      if (isInternal) {
        newFormValues = {
          name: formValues.name,
          url: formValues.url,
          organization_id: parseInt(formValues.organization_id, 10),
          lab: formValues.app_type === "lab" ? parseInt(1) : parseInt(0),
          external: formValues.lab_external && formValues.app_type === "lab" ? parseInt(1) : parseInt(0)
        };
      } else {
        newFormValues = {
          name: formValues.name,
          url: formValues.url,
          organization_id: parseInt(formValues.organization_id, 10)
        };
      }
      self.outputModel.save(newFormValues, {
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        success: $.proxy(function (returnedModel) {
          var data = returnedModel.attributes ? returnedModel.attributes.data[0] : [];
          self.model.set({
            app_id: data.id,
            app_name: data.name,
            json_name: data.json_name,
            lab: data.lab,
            external: data.external,
            url: data.url
          }, {
            silent: true
          });
          success(data);
          T1.Notify("message", "App " + formValues.name + " created successfully!");
          $el.find(".add-app").click(self.showModal.bind(self));
          $el.find(".saveAndClose").click(self.saveAndClose.bind(self));
          $el.find(".saveAndOpen").click(self.saveAndContinue.bind(self));
          $el.find(".close-app-modal").click(self.closeModal.bind(self));
        }, self),
        statusInvalid: $.proxy(function (errors) {
          var err = [];
          var errMsg;
          var addError = function (fld, errMsg) {
            err.push({
              field: fld,
              message: errMsg
            });
          };
          var notify = function (msg) {
            T1.Notify("error", msg);
          };
          statusInvalid(errors, "There are errors on the page.", ".details");
          $.each(errors, function (index, value) {
            errMsg = value.message;
            switch (value.field) {
              case "json_name":
                addError("name", "An existing app already uses this name.");
                break;
              case "name":
                addError("name", errMsg);
                break;
              case "organization_id":
                addError("organization_id", "Please provide a valid Organization.");
                break;
              case "url":
                addError("url", "URL must include https://");
                break;
              default:
                notify(errMsg);
            }
          });
          self.el.parent().find("#add-app-primary, #add-app-secondary").attr("disabled", false);
          self.el.parent().find("#add-app-primary, #add-app-secondary").removeClass("disabled");
          statusInvalid(err);
        }, self),
        error: $.proxy(function (errors) {
          statusInvalid(errors);
        }, self)
      });
      return deferred.promise;
    },
    appNameCounter: function () {
      var totalChar = $(".app-name").val().replace(/\n/g, "\r\n").length;
      $(".app-name-counter").text(45 - totalChar);
    },
    hideAppNameCounter: function () {
      var $el = $(this.el);
      $(".app-name-counter", $el).text("");
    },
    render: function () {
      var $el = this.el;
      var deferred = $.Deferred();
      var permissionsCheck = T1Permissions.check;
      if ($el[0] && Polymer && Polymer.dom) {
        Polymer.dom($el[0]).innerHTML = template;
      } else {
        $el.html(template);
      }
      if (permissionsCheck("user") !== "REPORTER") {
        $(".add-app", $el).show();
      }
      this.buildPermissionedEvents();
      this.bindViewEvents();
      setTimeout(function () {
        deferred.resolve();
      }, 0);
      return deferred.promise();
    }
  });
  CreateNewApp = T1.Form.ViewUtils.extend(CreateNewApp);
  CreateNewApp.prototype.saveOriginal = CreateNewApp.prototype.save;
  CreateNewApp.prototype.save = CreateNewApp.prototype.saveOverride;
  return CreateNewApp;
});
