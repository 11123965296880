define('T1Searchable',["jQuery", "T1"], function ($, T1) {
  var Search = function (searchConfiguration, fetch, resetPage) {
    var criteria;
    var getByIdList = false;
    var isFetchSilent = searchConfiguration.hasOwnProperty("isFetchSilent") ? searchConfiguration.isFetchSilent : true;
    var searchIdList = "";
    var isSingleSearch = searchConfiguration.hasOwnProperty("isSingleSearch") ? searchConfiguration.isSingleSearch : true;
    var searchParameters = [];
    function getCriteria(term) {
      var searchString = "";
      var searchConfig = searchConfiguration;
      var comparator;
      var wildCardChar = "*";
      var currentField = "";
      if (searchParameters.length === 0 && searchIdList === "") {
        return {};
      }
      function escapeTerm(str) {
        return str.replace(/&/g, "%26");
      }
      if (searchParameters.length) {
        $.each(searchParameters, function (index, param) {
          var searchField = param.searchField;
          var hasSecondarySearchField = searchConfig.secondaryFields && searchConfig.secondaryFields[searchField];
          var secondarySearchValue;
          var useWildCard;
          var searchPart;
          if (searchConfig && (searchConfig.fields[searchField] || searchField === "name|id" || hasSecondarySearchField)) {
            if (searchField === "name|id") {
              if (param.term.indexOf("#") === 0) {
                searchPart = "id==" + $.trim(escapeTerm(param.term.substr(1)));
              } else {
                searchPart = "name=:" + wildCardChar + escapeTerm(param.term) + wildCardChar;
              }
            } else if (searchField === "multiField") {
              searchPart = "<" + param.term + ">";
            } else {
              currentField = hasSecondarySearchField || searchConfig.fields[searchField];
              if (currentField.type === "string") {
                comparator = "=:";
              } else if (currentField.type === "enum") {
                comparator = "==";
              } else if (currentField.type === "exclude") {
                comparator = "!=";
              } else if (currentField.type === "exact") {
                comparator = "==";
              } else if (currentField.type === "special") {
                comparator = ":";
              } else if (currentField.type === "greater") {
                comparator = ">=";
              }
              useWildCard = currentField.useWildCard !== undefined ? currentField.useWildCard : false;
              secondarySearchValue = useWildCard ? wildCardChar + escapeTerm(param.term) + wildCardChar : escapeTerm(param.term);
              searchPart = (hasSecondarySearchField ? hasSecondarySearchField.name : searchField) + comparator + secondarySearchValue;
            }
            if (searchIdList) {
              searchString = searchString + "(" + searchIdList + ")&" + searchPart + (searchParameters.length === index + 1 ? "" : "&");
            } else {
              searchString = searchString + searchPart + (searchParameters.length === index + 1 ? "" : "&");
            }
          }
        });
      } else {
        searchString = "(" + searchIdList + ")";
      }
      return {
        q: searchString
      };
    }
    function clear() {
      searchIdList = "";
      searchParameters = [];
      resetPage();
    }
    function setIdList(list) {
      clear();
      searchIdList = list;
    }
    function setTerm(params) {
      clear();
      searchParameters = params;
    }
    function setIdsAndParams(list, params) {
      clear();
      searchIdList = list;
      searchParameters = params;
    }
    function set(data) {
      var success = data.success || $.noop;
      var idList = data.idList || undefined;
      var params = data.params || [];
      var paramsAndIDs = data.paramsAndIDs || false;
      var error = false;
      if (!idList || paramsAndIDs) {
        $.each(params, function (index, param) {
          if ($.isEmptyObject(param)) {
            error = true;
          } else if (!param.term || !param.searchField) {
            error = true;
          }
          if (param.term && param.searchField === "name|id" && param.term.charAt(0) === "#" && isNaN(param.term.substr(1))) {
            error = true;
          }
        });
        if (error === true) {
          throw new Error("Search parameters not correct");
        }
        if (paramsAndIDs) {
          setIdsAndParams(idList, params);
        } else {
          setTerm(params);
        }
      } else {
        setIdList(idList);
      }
      if (data.hasOwnProperty("fetchRequired") && data.fetchRequired) {
        fetch({
          silent: isFetchSilent,
          success: success
        });
      }
      return true;
    }
    return {
      getCriteria: getCriteria,
      set: set,
      clear: clear
    };
  };
  return Search;
});
