define('modules/strategy/creatives/views/readOnly',["jQuery", "T1", "T1View", "T1Spinner", "collections/strategyConcepts", "collections/creatives", "utils/CreativeUtils", "utils/VideoUtils", "T1Permissions", "text!../templates/readOnly.html", "text!../templates/readOnly_child.html"], function ($, T1, T1View, Spinner, StrategyConcepts, Creatives, CreativeUtils, VideoUtils, T1Permissions, read_only_tmpl, child_tmpl) {
  "use strict";

  var CreativesReadOnly = T1View.extend({
    template: read_only_tmpl,
    loaderOpts: {
      target: ".view-loader",
      text: "loading...",
      contentClass: "loader"
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    partials: {
      child_tmpl: child_tmpl
    },
    events: {
      "click .icon-arrow": "toggleNode"
    },
    initialize: function ({
      strategy: strategy,
      editLinkPrefix: editLinkPrefix,
      canEdit: canEdit
    }) {
      var coll = new StrategyConcepts([], {
        isPaginated: false
      });
      coll.id = strategy.id;
      this.strategy = strategy;
      this.collection = coll;
      this.creatives = new Creatives([], {
        isPaginated: false
      });
      this.editLinkPrefix = editLinkPrefix;
      this.canEdit = Boolean(canEdit);
      this.isFetching = true;
      coll.fetch({
        success: () => {
          this.isFetching = false;
          this.load();
        }
      });
    },
    toggleNode: function (evt) {
      var self = this;
      var $item = $(evt.currentTarget).closest(".item");
      var $iconArrow = $item.find(".icon-arrow");
      var $iconSpinner = $item.find(".icon-spinner");
      var isExpanded = $iconArrow.hasClass("icon-arrow-expanded");
      var conceptId = $item.data("id");
      var $children = $item.find(".children");
      var creatives = this.creatives;
      function addChildren() {
        var serializedData = self.serialize(creatives);
        self.renderPartialTemplate({
          template: self.partials.child_tmpl,
          useTemplateHtml: false,
          action: "html",
          targetEl: $children,
          data: serializedData
        });
        T1.Tooltip($children, {
          tooltipEle: $children.find(".adx-error"),
          className: "adx-error-tipsy"
        });
        T1.Tooltip($children, {
          tooltipEle: $children.find(".adx-warning"),
          className: "adx-warning-tipsy"
        });
      }
      this.preventDefault(evt);
      if (isExpanded) {
        $iconArrow.removeClass("icon-arrow-expanded loading");
        $iconSpinner.spin(false);
        $children.hide();
      } else {
        $iconArrow.addClass("loading icon-arrow-expanded icon-arrow-hide");
        Spinner.apply($iconSpinner, this.spinnerOpts);
        creatives.urlFilter.set({
          entity: "concept",
          id: conceptId
        });
        creatives.search.set({
          params: [{
            term: "1",
            searchField: "status"
          }]
        });
        creatives.fetch({
          success: function () {
            var videos = null;
            creatives.models.forEach(function (model) {
              if (model.get("media_type") === "video") {
                (videos || (videos = [])).push(model);
              }
            });
            if (!videos || !videos.length) {
              present();
            } else {
              VideoUtils.processCreativesVideoMixins(videos, present);
            }
            function present() {
              $iconSpinner.spin(false);
              $iconArrow.removeClass("loading icon-arrow-hide");
              if ($children.is(":visible")) {
                addChildren();
              } else {
                $children.show();
              }
            }
          }
        });
      }
    },
    load: function () {
      const $el = this.el;
      const hasData = Boolean(this.collection.length);
      this.render().then(() => {
        const loader = this.loader();
        if (this.isFetching) {
          loader.start();
        } else {
          loader.stop();
          this.$(".loader-holder").hide();
          if (!hasData) {
            const halfTransparent = .5;
            this.$(".section-heading").css("opacity", halfTransparent);
            this.$(".no-selection").show();
          }
        }
        T1.Truncate($el, {
          el: ".creative-name",
          tooltip: true
        });
        T1.Tooltip($el, {
          getExternalTip: true
        });
      });
    },
    serialize: function (models = this.collection) {
      let weightingType;
      const coll = models;
      var concepts = [];
      var dateFormat = "MM/dd/yyyy";
      var parseDate = T1.Date.parse;
      var self = this;
      function getSize(json) {
        var width = json.width.toString();
        var height = json.height.toString();
        return `${width}x${height}`;
      }
      if (coll.length) {
        coll.each(function (model) {
          let conceptData;
          var json = model.toJSON();
          const strategy_concept = json.strategy_concept;
          if (strategy_concept && strategy_concept.length > 1) {
            conceptData = coll.getStrategyConcept(self.strategy.id, json.strategy_concept);
          } else {
            conceptData = json.strategy_concept;
          }
          const percent = conceptData && conceptData.percent;
          json.created_on = parseDate(json.created_on).toString(dateFormat);
          json.size = json.width ? getSize(json) : undefined;
          json.start_date = json.start_date ? parseDate(json.start_date).toString(dateFormat) : "--";
          json.end_date = json.end_date ? parseDate(json.end_date).toString(dateFormat) : "--";
          json.percent = percent ? `${percent}%` : "";
          json.isInactive = json.status === "0";
          weightingType = conceptData && conceptData.weighting;
          json.error_vendors = CreativeUtils.parseVendorListForDisplay(json.adxErrorVendors);
          const sslValidator = json.atomic_creative_ssl_validator;
          if (sslValidator && sslValidator.ignored !== "YES") {
            json.sslSecurity = true;
            json.secureWarning = sslValidator.is_https === "NO";
            json.verifiedSecure = sslValidator.is_https === "YES";
            if (sslValidator.is_https === "ERROR" || sslValidator.is_https === "UNKNOWN") {
              json.sslSecurity = false;
            }
            sslValidator.is_https = sslValidator.is_https.charAt(0) + sslValidator.is_https.substr(1).toLowerCase();
          } else {
            json.sslSecurity = false;
          }
          concepts.push(json);
        });
      }
      const returnObj = {
        hasData: concepts.length,
        concepts: concepts.length ? concepts : false,
        weightingEnabled: weightingType !== "NONE",
        weightingType: weightingType
      };
      if (this.canEdit) {
        returnObj.editLink = `${this.editLinkPrefix}/creatives`;
      }
      return returnObj;
    }
  });
  return CreativesReadOnly;
});
