define('modules/eventTracking/models/eventTypes',[], function () {
  function generate() {
    return [{
      text: "Impression",
      value: "creative:imp"
    }, {
      text: "Video Clickthrough",
      value: "creative:click"
    }, {
      text: "First Quartile (25% Watched)",
      value: "firstQuartile"
    }, {
      text: "Midpoint (50% Watched)",
      value: "midpoint"
    }, {
      text: "Third Quartile (75% Watched)",
      value: "thirdQuartile"
    }, {
      text: "Complete View (100% Watched)",
      value: "complete"
    }, {
      text: "Pause",
      value: "pause"
    }, {
      text: "Mute",
      value: "mute"
    }, {
      text: "Close",
      value: "close"
    }, {
      text: "Resume",
      value: "resume"
    }, {
      text: "Rewind",
      value: "rewind"
    }, {
      text: "Unmute",
      value: "unmute"
    }, {
      text: "Fullscreen",
      value: "fullscreen"
    }, {
      text: "Accept Invitation",
      value: "acceptInvitation"
    }, {
      text: "Skip",
      value: "skip"
    }, {
      text: "Creative View",
      value: "creativeView"
    }, {
      text: "Start",
      value: "start"
    }, {
      text: "Expand",
      value: "expand"
    }, {
      text: "Collapse",
      value: "collapse"
    }];
  }
  return generate();
});
