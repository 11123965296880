define('modules/creatives/display/views/clickThruModal',["jQuery", "Underscore", "T1", "T1View", "text!../templates/click_thru_dialog.html"], function ($, _, T1, T1View, tmpl) {
  return T1View.extend({
    template: tmpl,
    events: {
      "keydown .text-input.click-thru": "handleKey"
    },
    eventhubEvents: {
      "clickthrumodal:show": "show"
    },
    initialize: function (args) {
      this.model = args.model;
      this.options = {
        title: "Edit Click Through URL",
        message: "type URL here i.e. http://www.example.com"
      };
    },
    load: function () {
      var self = this;
      this.render().then(function () {
        self.modal = self.el;
        self.modal.dialog({
          autoOpen: false,
          modal: true,
          dialogClass: "w-customAlert click-thru",
          buttons: [{
            text: "Save",
            class: "save",
            click: $.proxy(self.saveClickThru, self)
          }, {
            text: "Cancel",
            class: "cancel",
            click: $.proxy(self.hide, self)
          }]
        });
      });
    },
    unload: function () {
      if (this.modal) {
        this.modal.remove();
        this.modal.dialog("destroy");
        this.modal = null;
      }
    },
    show: function () {
      this.el.find("input[type=text]").val("");
      this.modal.dialog("open");
    },
    hide: function () {
      this.modal.dialog("close");
      this.hideError();
    },
    showError: function (errors) {
      this.options.hasError = true;
      this.options.errors = errors;
      this.el.find("input[type=text]").addClass("error");
      this.renderPartial(".error-wrapper");
    },
    hideError: function () {
      this.options.hasError = false;
      this.options.errors = "";
      this.el.find("input[type=text]").removeClass("error");
      this.renderPartial(".error-wrapper");
    },
    handleKey: function (e) {
      if (e.keyCode === 13) {
        this.saveClickThru();
      }
    },
    saveClickThru: function () {
      var value = this.el.find("input[type=text]").val();
      var error = this.model.validateUrl(value);
      if (error) {
        this.showError(error);
      } else {
        this.model.setClickThru(value);
        this.modal.dialog("close");
        T1.EventHub.publish("reviewgrid:valid:update", {
          type: "click-thru"
        });
      }
    },
    serialize: function () {
      return {
        title: this.options.title,
        message: this.options.message,
        hasError: this.options.hasError,
        error: this.options.errors
      };
    }
  });
});
