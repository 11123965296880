define('models/reportingAbstractModel',['require','Underscore','jQuery','modules/reporting/campaigns/models/additionalData','modules/reporting/campaigns/models/metricCollections','modules/organization/utils/Utils','T1','T1Model','T1Permissions','modules/reporting/campaigns/utils/reportingUtils'],function (require) {
  "use strict";

  const _ = require("Underscore");
  const $ = require("jQuery");
  const additionalData = require("modules/reporting/campaigns/models/additionalData");
  const MetricCollections = require("modules/reporting/campaigns/models/metricCollections");
  const OrgUtils = require("modules/organization/utils/Utils");
  const T1 = require("T1");
  const T1Model = require("T1Model");
  const T1Permissions = require("T1Permissions");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  const Date = T1.Date;
  const dateParse = Date.parse;
  const dateCompare = Date.compare;
  const ReportingAbstractModel = T1Model.extend({
    initialize({
      mainMeta: mainMeta,
      mainModel: mainModel,
      key: sectionKey
    }) {
      Object.assign(this, {
        hasEcoCostFlag: T1Permissions.check("feature", "reporting_ecosystem_cost"),
        hasViewabilityFlag: T1Permissions.check("feature", "reporting_viewability"),
        mainMeta: mainMeta,
        mainModel: mainModel,
        sectionKey: sectionKey
      });
    },
    setReportMeta(reportMeta) {
      this.reportMeta = reportMeta;
    },
    parseColumns(isFromReachFrequency) {
      const reportMeta = this.reportMeta;
      const focus = this.get("focus") || "all";
      const columns = isFromReachFrequency ? reportMeta.get("columns").reach_frequency : reportMeta.get("columns")[focus];
      const displayMetrics = Object.keys(reportMeta.getDisplayMetrics());
      const section = this.mainModel.get("section");
      const overrides = reportMeta.get("columnsOverrides")[section];
      const allColumns = [];
      const availableColumns = [];
      const defaultColumns = [];
      const defaultColumnKeys = [];
      const formattedHeaderValues = {};
      for (const key in columns) {
        if (Object.prototype.hasOwnProperty.call(columns, key)) {
          if (!displayMetrics.includes(key)) {
            continue;
          }
          let label = reportMeta.getFriendlyName("metrics", key);
          if (columns[key].keepCase) {
            const caseIndex = reportMeta.getCaseIndex(label, columns[key].keepCase);
            label = `${label.substring(0, caseIndex - 1).toUpperCase()}<br/>${label.substring(caseIndex)}`;
          } else {
            label = label.toUpperCase();
          }
          formattedHeaderValues[key] = label;
          const obj = {
            value: key,
            text: reportMeta.getFriendlyName("metrics", key)
          };
          allColumns.push(obj);
          let treatAsDefault = columns[key].default && columns[key].default === "1";
          if (overrides && overrides[key]) {
            treatAsDefault = overrides[key].default && overrides[key].default === "1";
          }
          if (treatAsDefault) {
            defaultColumns.push(obj);
            defaultColumnKeys.push(key);
          } else {
            availableColumns.push(obj);
          }
        }
      }
      this.set({
        allColumns: allColumns,
        availableColumns: availableColumns,
        defaultColumnKeys: defaultColumnKeys,
        defaultColumns: defaultColumns,
        formattedHeaderValues: formattedHeaderValues
      });
    },
    setColumnKeys(columnKeys) {
      const displayMetrics = Object.keys(this.reportMeta.getDisplayMetrics());
      if (Utils.hideCostsAndFees) {
        columnKeys = _.reject(columnKeys, key => MetricCollections.fees.includes(key));
      }
      columnKeys = _.reject(columnKeys, key => !displayMetrics.includes(key));
      for (let i = 0, len = columnKeys.length; i < len; i++) {
        columnKeys[i] = columnKeys[i].toLowerCase();
      }
      this.set({
        columnKeys: columnKeys
      });
    },
    setColumnWidths(firstColumnWidths, secondColumnWidths = null) {
      this.set({
        firstColumnWidths: firstColumnWidths,
        secondColumnWidths: secondColumnWidths
      });
    },
    setNoDataRender(state) {
      this.set({
        noDataRender: state
      });
    },
    setGridScrollXPos(scrollPos) {
      this.set({
        scrollLeft: scrollPos
      });
    },
    setSortKeyInfo(sortKeyInfo) {
      this.set({
        sortKeyInfo: sortKeyInfo
      });
    },
    setCampaignBenchmarkState(campaignBenchmarkOn) {
      this.set({
        campaignBenchmarkOn: campaignBenchmarkOn
      });
    },
    setDimension(dim1, isTemp1, silent1, dim2, isTemp2, silent2, forceReset) {
      const focus = this.get("focus") || "all";
      const section = this.mainModel.get("section") || "performance";
      const isSiteTransparency = section === "site_transparency";
      const isTechnology = section === "device_technology";
      const dimSection = this.reportMeta.get("dimensions")[section];
      if (dim1) {
        let dim1Info;
        if (isTechnology) {
          dim1Info = $.extend({}, dimSection.viewBy[dim1], {
            dimension: dim1
          });
        } else {
          if (isSiteTransparency && dimSection.viewBy[dim1]) {
            dim1Info = $.extend({}, dimSection.viewBy[dim1], {
              dimension: dim1
            });
          } else {
            dim1Info = $.extend({}, dimSection[focus][dim1], {
              dimension: dim1
            });
          }
        }
        if (!dim1Info.key) {
          dim1Info.key = "strategy_id";
        }
        if (isTemp1) {
          this.set({
            tempDimension: dim1,
            tempDimensionInfo: dim1Info
          }, {
            silent: Boolean(silent1)
          });
        } else {
          this.set({
            dimension: dim1,
            dimensionInfo: dim1Info
          }, {
            silent: Boolean(silent1)
          });
        }
      }
      if (dim2) {
        let dim2Info;
        if (isTechnology) {
          dim2Info = $.extend({}, dimSection.filterBy[dim2], {
            dimension: dim2
          });
        } else {
          dim2Info = $.extend({}, dimSection[focus][dim2], {
            dimension: dim2
          });
        }
        if (isTemp2) {
          this.set({
            tempDim2: dim2,
            tempDim2Info: dim2Info
          }, {
            silent: Boolean(silent2)
          });
        } else {
          this.set({
            dim2: dim2,
            dim2Info: dim2Info
          }, {
            silent: Boolean(silent2)
          });
        }
      }
      if (forceReset && !dim2) {
        if (isTemp2) {
          this.set({
            tempDim2: null,
            tempDim2Info: null
          }, {
            silent: Boolean(silent2)
          });
        } else {
          this.set({
            dim2: null,
            dim2Info: null
          }, {
            silent: Boolean(silent2)
          });
        }
      }
    },
    clearTempDimension() {
      this.set({
        tempDimension: null,
        tempDimensionInfo: null,
        tempDim2: null,
        tempDim2Info: null
      }, {
        silent: true
      });
    },
    setAppliedFilteredItems(checkedItems, isDim2) {
      const prop = isDim2 ? "appliedFilteredDimItems2" : "appliedFilteredDimItems";
      this.set({
        [prop]: checkedItems
      });
    },
    getIsDim2Active() {
      const dim2 = this.get("dim2");
      return dim2 !== "" && dim2 !== "null" && dim2 !== null && dim2 !== undefined;
    },
    getDimension(isDim2) {
      return isDim2 ? this.get("dim2") : this.get("dimension");
    },
    getDimensionInfo(isDim2) {
      return isDim2 ? this.get("dim2Info") : this.get("dimensionInfo");
    },
    getTempDimension(isDim2) {
      return isDim2 ? this.get("tempDim2") : this.get("tempDimension");
    },
    getTempDimensionInfo(isDim2) {
      return isDim2 ? this.get("tempDim2Info") : this.get("tempDimensionInfo");
    },
    setMetric(metric, silent) {
      this.set({
        metric: metric
      }, {
        silent: silent
      });
    },
    setMetric2(metric2, silent) {
      this.set({
        metric2: metric2
      }, {
        silent: silent
      });
    },
    setHighlightedItem(highlightedItem) {
      this.set({
        highlightedItem: highlightedItem
      });
      if (highlightedItem && highlightedItem.from && highlightedItem.id) {
        T1.EventHub.publish("track:event", {
          action: "Highlights",
          label: `highlight ${highlightedItem.from}`
        });
      }
    },
    setHiddenItem(toggledItem, isDim2) {
      let hiddenItems = this.getHiddenItems(isDim2) || {};
      const id = toggledItem.id;
      hiddenItems = $.extend({}, hiddenItems);
      if (toggledItem.action === "show") {
        delete hiddenItems[id];
      } else {
        hiddenItems[id] = true;
      }
      if (isDim2) {
        this.set({
          hiddenItemsDim2: hiddenItems
        });
      } else {
        this.set({
          hiddenItems: hiddenItems
        });
      }
      this.setHiddenCount(false, isDim2);
    },
    setHiddenItems(hiddenItems, isDim2) {
      if (isDim2) {
        this.set({
          hiddenItemsDim2: hiddenItems
        });
      } else {
        this.set({
          hiddenItems: hiddenItems
        });
      }
      this.setHiddenCount(isDim2);
    },
    getHiddenItems(isDim2) {
      return isDim2 ? this.get("hiddenItemsDim2") : this.get("hiddenItems");
    },
    setHiddenCount(silent = false, isDim2) {
      const filteredInCount = this.getFilteredInCount(isDim2);
      const hiddenItems = this.getHiddenItems(isDim2) || {};
      let count = 0;
      if (filteredInCount === 0) {
        count = Object.keys(hiddenItems).length;
      } else {
        const filteredOutItems = this.getFilteredOutItems(isDim2);
        for (const [key, value] of Object.entries(hiddenItems)) {
          if (value && !filteredOutItems.includes(key)) {
            count++;
          }
        }
      }
      if (isDim2) {
        this.set({
          hiddenCountDim2: count
        }, {
          silent: silent
        });
      } else {
        this.set({
          hiddenCount: count
        }, {
          silent: silent
        });
      }
    },
    getHiddenItemsCount(isDim2) {
      return isDim2 ? this.get("hiddenCountDim2") : this.get("hiddenCount") || 0;
    },
    setColorData(collection, dimItems) {
      const mainModel = this.mainModel;
      const reportMeta = this.reportMeta;
      const dimensionInfo = this.get("dimensionInfo");
      const section = mainModel.get("section");
      const campaignId = collection && collection.id || null;
      const sectionDimensionHash = {
        geo: reportMeta.get("geoLevelConfig")[this.getGeoNav().level].dimension
      };
      const dimensionKey = sectionDimensionHash[section] || dimensionInfo.key;
      if (campaignId || dimItems) {
        const colorData = {};
        const chartColors = reportMeta.get("chartColors");
        const chartColorsLen = chartColors.length;
        const models = campaignId ? collection && collection.models || [] : dimItems;
        if (campaignId) {
          for (const [index, model] of models.entries()) {
            colorData[model.get(dimensionKey).replace(/[']/g, "")] = index % chartColorsLen;
          }
        } else {
          for (const [index, model] of models.entries()) {
            colorData[model.name.replace(/[']/g, "")] = index % chartColorsLen;
          }
        }
        this.set({
          colorData: colorData
        });
        if (dimensionKey === "site_domain") {
          const validSiteList = {};
          for (const model of models) {
            const domainName = model.get(dimensionKey);
            validSiteList[domainName] = this.getSiteValidation(domainName);
          }
          this.set({
            validSiteList: validSiteList
          });
        }
      }
    },
    setDimensionItems(dimensionItemsData, cfg = {}) {
      let dim1Items, dim1Name;
      const {
        applying: applying,
        dim1Data: dim1Data,
        dim1FilteredData: dim1FilteredData
      } = dimensionItemsData;
      let dim2Data = dimensionItemsData.dim2Data;
      const nameMaxLen = 22;
      const section = this.mainModel.get("section");
      const inTechnology = section.includes("technology");
      const isLongDimName = section.includes("frequency") || section.includes("contextual");
      if (dim1Data) {
        const dim1Temp = this.get("tempDimension");
        const dim1Info = dim1Temp ? this.get("tempDimensionInfo") : this.get("dimensionInfo");
        const dim1Key = dim1Info.key;
        const dim1ItemsLookup = {};
        dim1Items = [];
        dim1Name = dim1Info.nameField;
        for (const model of dim1Data.models) {
          const id = model.get(dim1Key);
          const name = model.get(dim1Name) || id;
          if (!dim1ItemsLookup[id]) {
            dim1Items.push({
              id: id,
              name: isLongDimName ? name : T1.Utils.trunc(name, nameMaxLen),
              origText: name.length > nameMaxLen ? name : "",
              searchKey: name
            });
          }
          dim1ItemsLookup[id] = true;
        }
        this.set({
          dim1DDLItems: dim1Items
        }, {
          silent: true
        });
        if (!dim1Temp) {
          this.set({
            dim1DDLItems_org: this.get("dim1DDLItems")
          });
          this.initFilteredOutItems(dim1Items, false, dim1Key);
        }
        this.trigger("update:dim1DDL");
        if (applying) {
          this.set({
            dimensionItems: dim1Items,
            dimensionItemsCount: dim1Items.length,
            dimensionItemsLookup: dim1ItemsLookup
          }, {
            silent: true
          });
        }
      }
      if (dim1FilteredData) {
        const dim1FilteredInfo = this.get("dimensionInfo");
        const dim1FilteredKey = dim1FilteredInfo.key;
        const dim1FilteredName = dim1FilteredInfo.nameField;
        const dim1FilteredItems = [];
        const dim1FilteredItemsLookup = {};
        for (const model of dim1FilteredData.models) {
          const id = model.get(dim1FilteredKey);
          const name = model.get(dim1FilteredName);
          if (dim1FilteredItemsLookup[id] === undefined) {
            dim1FilteredItems.push({
              id: id,
              name: isLongDimName ? name : T1.Utils.trunc(name, nameMaxLen),
              origText: name.length > nameMaxLen ? name : "",
              searchKey: name
            });
          }
          dim1FilteredItemsLookup[id] = true;
        }
        this.set({
          dim1FilteredDDLItems: dim1FilteredItems
        }, {
          silent: true
        });
        if (applying) {
          this.set({
            dim1FilteredItems: dim1FilteredItems,
            dim1FilteredItemsCount: dim1FilteredItems.length,
            dim1FilteredItemsLookup: dim1FilteredItemsLookup
          }, {
            silent: true
          });
        }
      }
      const dim2Temp = this.get("tempDim2");
      const dim2Info = dim2Temp ? this.get("tempDim2Info") : this.get("dim2Info");
      const dim2Key = dim2Info ? dim2Info.key : null;
      if (inTechnology) {
        const metricsList = dimensionItemsData.metrics;
        if (!dim2Temp && dim2Key) {
          dim2Data = dim1Data;
        }
        if (dim1Data && this.get("filterParams") === "") {
          if (!cfg.deviceLoadedSeparately || !cfg.inventoryLoadedSeparately) {
            const dim1DataJson = dim1Data.toJSON();
            if (!cfg.deviceLoadedSeparately && metricsList) {
              this.setDeviceData(this.aggregateData(dim1DataJson, "device_type", metricsList));
            }
            if (!cfg.inventoryLoadedSeparately && metricsList) {
              this.setInventoryData(this.aggregateData(dim1DataJson, "inventory_type", metricsList));
            }
          }
        }
        this.setColorData(null, dim1Items);
      }
      if (dim2Data) {
        const dim2Name = dim2Info ? dim2Info.nameField : null;
        const dim2Items = [];
        const dim2ItemsLookup = {};
        const filteredInItems = this.get("filteredInItems");
        const areFiltersApplied = this.get("filteredOutCount") > 0;
        const isFilteredByOS = dim1Name === "operating_system" && dim2Name === "os_version" && areFiltersApplied;
        const isFilteredByBrowser = dim1Name === "browser" && dim2Name === "browser_version" && areFiltersApplied;
        for (const model of dim2Data.models) {
          const id = model.get(dim2Key);
          const name = model.get(dim2Name);
          if (dim2ItemsLookup[id] === undefined) {
            if (isFilteredByOS || isFilteredByBrowser) {
              if (!filteredInItems.includes(model.get(dim1Name))) {
                continue;
              }
            }
            dim2Items.push({
              id: id,
              name: isLongDimName ? name : T1.Utils.trunc(name, nameMaxLen),
              origText: name.length > nameMaxLen ? name : "",
              searchKey: name
            });
          }
          dim2ItemsLookup[id] = true;
        }
        this.set({
          dim2DDLItems: dim2Items
        }, {
          silent: true
        });
        if (!dim2Temp) {
          this.set({
            dim2DDLItems_org: this.get("dim2DDLItems")
          });
          this.initFilteredOutItems(dim2Items, true, dim2Key);
        }
        this.trigger("update:dim2DDL");
        if (applying) {
          this.set({
            dim2Items: dim2Items,
            dim2ItemsCount: dim2Items.length,
            dim2ItemsLookup: dim2ItemsLookup
          }, {
            silent: true
          });
        }
      }
      this.resetDateRangeChanged();
      if (applying) {
        this.trigger("change:dimensionItems");
      }
    },
    restoreOriginalDDLItems() {
      this.set({
        dim1DDLItems: this.get("dim1DDLItems_org"),
        dim2DDLItems: this.get("dim2DDLItems_org")
      });
    },
    getDimensionFilterItems(isDim2) {
      return isDim2 ? this.get("dim2DDLItems") : this.get("dim1DDLItems");
    },
    initFilteredOutItems(dimensionItems, isDim2, dimensionKey) {
      let items = {};
      const filteredInItems = this.getFilteredInItems(isDim2) || [];
      const filteredKey = this.getFilteredKey(isDim2) || null;
      const tempDimension = this.getTempDimension(isDim2);
      const dimension = this.getDimension(isDim2);
      const inTemporaryListMode = tempDimension && dimension && tempDimension !== dimension;
      const appliedFilteredDimItems = this.get(isDim2 ? "appliedFilteredDimItems2" : "appliedFilteredDimItems");
      const dateRangeChanged = this.get("dateRangeChanged") === true;
      const dimChanged = filteredKey !== dimensionKey;
      const bigUpdate = dateRangeChanged || dimChanged;
      let usePrevFiltered = false;
      for (const item of dimensionItems) {
        if (filteredInItems.includes(item.id)) {
          usePrevFiltered = true;
          break;
        }
      }
      if (usePrevFiltered) {
        for (const {
          id: id
        } of dimensionItems) {
          items[id] = filteredInItems.includes(id);
        }
      } else {
        for (const item of dimensionItems) {
          items[item.id] = true;
        }
      }
      if (!inTemporaryListMode && !bigUpdate && appliedFilteredDimItems) {
        items = appliedFilteredDimItems;
      }
      const silently = bigUpdate ? true : !usePrevFiltered;
      this.setFilteredOutItems(items, silently, false, isDim2, dimensionKey);
    },
    setFilteredOutItems(filteredItems, silent, updateFilteredInItems, isDim2, dimensionKey) {
      let totalLabel;
      const items = $.extend(true, {}, filteredItems);
      const itemsIn = [];
      const itemsOut = [];
      const total = Object.keys(items).length;
      const multi = this.mainModel.get("multiCampaigns");
      for (const [key, value] of Object.entries(items)) {
        if (value) {
          itemsIn.push(key);
        } else {
          itemsOut.push(key);
        }
      }
      const countIn = itemsIn.length;
      const countOut = itemsOut.length;
      if (countIn === total) {
        totalLabel = multi ? "Multiple Campaigns Total" : "Total";
      } else {
        totalLabel = multi ? "Multiple Campaigns Total (filtered)" : "Total (filtered)";
      }
      if (isDim2) {
        this.set({
          filteredInCountDim2: countIn,
          filteredInItemsDim2: itemsIn,
          filteredKeyDim2: dimensionKey,
          filteredOutCountDim2: countOut,
          filteredOutItemsDim2: itemsOut,
          totalLabel: totalLabel
        }, {
          silent: silent
        });
      } else {
        this.set({
          filteredInCount: countIn,
          filteredInItems: itemsIn,
          filteredKey: dimensionKey,
          filteredOutCount: countOut,
          filteredOutItems: itemsOut,
          totalLabel: totalLabel
        }, {
          silent: silent
        });
      }
      if (!updateFilteredInItems) {
        if (isDim2) {
          this.trigger("update:dim2DDL");
        } else {
          this.trigger("update:dim1DDL");
        }
      }
      this.setHiddenCount(silent, isDim2);
    },
    getFilteredInCount(isDim2) {
      return isDim2 ? this.get("filteredInCountDim2") : this.get("filteredInCount") || 0;
    },
    getFilteredInItems(isDim2) {
      return isDim2 ? this.get("filteredInItemsDim2") : this.get("filteredInItems");
    },
    getFilteredKey(isDim2) {
      return isDim2 ? this.get("filteredKeyDim2") : this.get("filteredKey");
    },
    getFilteredOutCount(isDim2) {
      return isDim2 ? this.get("filteredOutCountDim2") : this.get("filteredOutCount") || 0;
    },
    getFilteredOutItems(isDim2) {
      return isDim2 ? this.get("filteredOutItemsDim2") : this.get("filteredOutItems");
    },
    getEscapedNames(items) {
      return items.map(item => `"${item}"`);
    },
    setTimeSeriesSlots(dateRange, type) {
      let dateformat, increaseTimeUnit;
      const reportType = type || "performance";
      let startDate = dateRange.start_date;
      const endDate = dateRange.end_date;
      const date = T1.parseDateAsUTC(startDate);
      const timeUnits = [{
        start_date: startDate
      }];
      const timeSeriesSlots = this.get("timeSeriesSlots");
      const needsNewTimeSeriesSlots = function () {
        return !timeSeriesSlots || timeSeriesSlots.start_date !== startDate || timeSeriesSlots.end_date !== endDate;
      };
      if (needsNewTimeSeriesSlots()) {
        if (startDate !== endDate) {
          if (reportType === "by_hour") {
            dateformat = "yyyy-MM-dd HH:mm:ss";
            increaseTimeUnit = "addHours";
          } else {
            dateformat = "yyyy-MM-dd";
            increaseTimeUnit = "addDays";
          }
          do {
            startDate = date[increaseTimeUnit](1).toString(dateformat);
            timeUnits.push({
              start_date: startDate
            });
          } while (startDate !== endDate);
        }
        this.set({
          timeSeriesSlots: $.extend({}, dateRange, {
            slots: timeUnits
          })
        });
      }
    },
    setCollectionFetchParams(fetchParams) {
      this.set({
        collectionFetchParams: fetchParams
      }, {
        silent: true
      });
    },
    setCollectionDailyFilterParams(filterParams) {
      this.set({
        collectionDailyFilterParams: filterParams
      }, {
        silent: true
      });
    },
    getDownloadURL(args) {
      if (!this.get("sortKeyInfo")) {
        return;
      }
      const self = this;
      const sortKeyInfoArray = this.get("sortKeyInfo").split(",");
      const sortDirection = sortKeyInfoArray[1] === "1" ? "" : "-";
      const filteredOutItems = this.get("filteredOutItems");
      const filteredInItems = this.get("filteredInItems");
      let filteredDimension = "";
      const mainModel = this.mainModel;
      const campaignId = mainModel.get("reportId");
      const {
        byDimension: byDimension,
        isSummary: isSummary
      } = args;
      let sourceURL = args.sourceURL;
      const section = mainModel.get("section");
      const focus = this.get("focus");
      if (byDimension && filteredOutItems && filteredOutItems.length && filteredInItems !== null) {
        filteredDimension = `&${this.get("dimensionInfo").key}=${this.getEscapedNames(filteredInItems).toString()}`;
      }
      if (this.hasViewabilityFlag && section === "site_transparency") {
        sourceURL = sourceURL.replace("/download", "_viewability/download");
      }
      if (this.hasEcoCostFlag && section === "performance") {
        sourceURL = sourceURL.replace("/download", "_ecosystem_cost/download");
      }
      const downloadURL = function () {
        let country;
        const dimensions = args.dimensions;
        const currencyType = mainModel.get("currency");
        let columnKeys = self.get("columnKeys");
        const allColumns = additionalData.columns[focus];
        const permissionCheck = T1Permissions.check;
        if (currencyType === "usd") {
          columnKeys = columnKeys.filter(function (key) {
            if (allColumns[key]) {
              if (!allColumns[key].onlyViewableInCampaignCurrency) {
                return key;
              }
            } else {
              return key;
            }
            return false;
          });
          if (section === "performance") {
            columnKeys = columnKeys.map(function (metric) {
              return MetricCollections.currency.includes(metric) ? `${metric}_usd` : metric;
            });
          }
        }
        columnKeys = columnKeys.filter(function (key) {
          const columnObj = allColumns[key];
          if (columnObj) {
            if (columnObj.featureFlag && !permissionCheck("feature", columnObj.featureFlag)) {
              return false;
            }
            return key;
          }
          return false;
        });
        const hasVcrImpression = OrgUtils.getOrgVcrSetting();
        if (hasVcrImpression) {
          const vcrIndex = columnKeys.indexOf("video_complete_rate");
          if (vcrIndex !== -1) {
            columnKeys.splice(vcrIndex, 1, "video_complete_rate_impression_based");
          }
        }
        const opts = $.extend({}, self.get("collectionFetchParams"), {
          metrics: columnKeys.toString()
        });
        switch (section) {
          case "performance":
            opts.order = `${sortDirection + sortKeyInfoArray[0]},date`;
            if (isSummary) {
              opts.time_rollup = "all";
            }
            break;
          case "site_transparency":
          case "geo":
          case "contextual_insights":
            opts.time_rollup = isSummary ? "all" : "by_day";
            break;
          case "device_technology":
            opts.order = sortDirection + sortKeyInfoArray[0];
            if (isSummary) {
              opts.time_rollup = "all";
            }
            break;
          case "reach_frequency":
            opts.metrics = `uniques,${opts.metrics}`;
            break;
        }
        if (byDimension) {
          opts.filter += campaignId + filteredDimension;
          switch (section) {
            case "geo":
              if (args.full) {
                country = self.get("geoNav").country;
                opts.filter += country ? `&country_name=${country}` : "";
              } else {
                opts.filter += self.get("geoFilters");
              }
              break;
            case "site_transparency":
              if (args.full) {
                delete opts.page_limit;
                delete opts.having;
              } else {
                if (!isSummary) {
                  delete opts.page_limit;
                  opts.filter += `&site_domain=${self.get("collectionDailyFilterParams")}`;
                }
              }
              break;
          }
          if (dimensions) {
            opts.dimensions = dimensions;
          }
        } else {
          opts.dimensions = "organization_id";
          opts.filter += campaignId;
        }
        return `${T1.RPT_API_ROOT + sourceURL}?${$.param(opts)}`;
      };
      return downloadURL();
    },
    setStartAndEndDate(startDate, endDate) {
      const lowerBounds = this.get("lowerBounds");
      if (lowerBounds && dateCompare(dateParse(startDate), lowerBounds) < 0) {
        startDate = lowerBounds;
      }
      this.set({
        startDate: startDate,
        endDate: endDate
      }, {
        silent: true
      });
      this.trigger("change:dates", {
        startDate: startDate,
        endDate: endDate
      });
    },
    resetHiddenItems(silent = false) {
      this.set({
        hiddenCount: 0,
        hiddenItems: {}
      }, {
        silent: silent
      });
    },
    resetUIStates() {
      this.set({
        dim1DDLItems_org: null,
        dim1DDLItems: null,
        dimensionItems: null,
        dimensionItemsCount: 0,
        dimensionItemsLookup: null,
        filteredInCount: 0,
        filteredInItems: null,
        filteredOutCount: 0,
        filteredOutItems: null,
        hiddenCount: 0,
        hiddenItems: null,
        appliedFilteredDimItems: null,
        dim1FilteredDDLItems: null,
        dim1FilteredItems: null,
        dim1FilteredItemsCount: 0,
        dim1FilteredItemsLookup: null,
        dim2DDLItems_org: null,
        dim2DDLItems: null,
        dim2Items: null,
        dim2ItemsCount: 0,
        dim2ItemsLookup: null,
        filteredInCountDim2: 0,
        filteredInItemsDim2: null,
        filteredOutCountDim2: 0,
        filteredOutItemsDim2: null,
        hiddenCountDim2: 0,
        hiddenItemsDim2: null,
        appliedFilteredDimItems2: null,
        highlightedItem: null,
        timeSeriesSlots: null
      }, {
        silent: true
      });
    },
    resetFilteredInItems() {
      this.set({
        filteredInCount: 0,
        filteredInCountDim2: 0,
        filteredInItems: null,
        filteredInItemsDim2: null
      }, {
        silent: true
      });
    },
    resetColorData() {
      this.set({
        colorData: null
      }, {
        silent: true
      });
    },
    resetColumnResize() {
      this.set({
        firstColumnWidths: null,
        secondColumnWidths: null,
        scrollLeft: 0
      });
    },
    resetOnDateRangeChange() {
      const dimFilterList0 = this.get("dimFilterList0");
      const dimFilterList1 = this.get("dimFilterList1");
      this.set({
        appliedFilteredDimItems: null,
        appliedFilteredDimItems2: null
      }, {
        silent: true
      });
      if (dimFilterList0 && dimFilterList0.allChecked) {
        this.set({
          filteredInItems: null
        }, {
          silent: true
        });
      }
      if (dimFilterList1 && dimFilterList1.allChecked) {
        this.set({
          filteredInItemsDim2: null
        }, {
          silent: true
        });
      }
      this.set({
        dateRangeChanged: true
      });
    },
    resetDateRangeChanged() {
      this.set({
        dateRangeChanged: false
      });
    },
    setReportState(reportState) {
      this.set({
        reportState: reportState
      });
    },
    setFocus(focus, silent) {
      this.set({
        focus: focus
      }, {
        silent: silent
      });
    },
    setChartShown(chartShown) {
      this.mainModel.setChartShown(chartShown);
    },
    setSection(section, silent) {
      if (this.mainModel) {
        this.mainModel.setSection(section, silent);
      }
    },
    setDateLowerBounds(dateLowerBounds) {
      this.set({
        dateLowerBounds: dateLowerBounds
      });
    },
    getDefaultMetric(campaign, metric2) {
      const multi = this.mainModel.get("multiCampaigns");
      const goalType = multi ? "multi" : campaign && campaign.get("goal_type");
      const reportMeta = this.reportMeta;
      if (!goalType) {
        throw Error("campaign is a required param if you are in the single campaign mode");
      }
      if (multi && metric2) {
        return "total_spend_cpm";
      }
      const metric = goalType && reportMeta.get("defaultSortForGoalType")[goalType].split(",")[0] || reportMeta.get("metricOptions")[0].value;
      return !metric ? "total_spend_cpa" : metric;
    },
    getReportMetrics(focus) {
      return additionalData.metrics[focus || "all"];
    },
    setPreferences(preferences) {
      this.set({
        preferences: preferences
      });
    },
    setReportInfo(reportType, responseHeaders) {
      if (responseHeaders && responseHeaders.data) {
        const responseHeadersData = responseHeaders.data;
        const reportInfo = this.get("reportInfo") || {
          reportTypes: {}
        };
        if (!reportInfo.reportTypes[reportType]) {
          reportInfo.reportTypes[reportType] = {
            lastModified: responseHeadersData["x-report-cutoff-time"] || responseHeadersData["X-Report-Cutoff-Time"]
          };
          this.set({
            reportInfo: reportInfo
          });
        }
      }
    },
    setReportType(reportType) {
      this.set({
        reportType: reportType
      });
    },
    makeFocusKeyFunc(focus) {
      const toInitialCaps = T1.Utils.toInitialCaps;
      return function getFocusKey(key) {
        return focus === "all" ? key : focus + toInitialCaps(key);
      };
    },
    setFilterParams(filterParams) {
      this.set({
        filterParams: filterParams
      });
    },
    setInterval(interval) {
      this.set({
        interval: interval
      });
    },
    getGeoNav() {
      return this.get("geoNav") || {
        level: "all",
        country: "",
        region: ""
      };
    },
    getTechFilters(type) {
      switch (type) {
        case "device":
          return this.get("techDeviceFilters") || "";
        case "inventory":
          return this.get("techInventoryFilters") || "";
        default:
          return this.getTechFilters("device") + this.getTechFilters("inventory");
      }
    },
    aggregateData(jsonData, groupKey, properties) {
      function sum(numbers) {
        return numbers.reduce((acc, val) => acc + parseFloat(val), 0);
      }
      return _.chain(jsonData).groupBy(groupKey).map(function (value, key) {
        const obj = {};
        obj[groupKey] = key;
        for (const field of properties) {
          obj[field] = sum(_.pluck(value, field));
        }
        return obj;
      }).value();
    },
    registerDimFilterList(dimFilterList, index) {
      this.set({
        [`dimFilterList${index}`]: dimFilterList
      });
    },
    sortItems(items) {
      return items.sort(function (a, b) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      });
    }
  });
  return ReportingAbstractModel;
});
