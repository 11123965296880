define('modules/admin/shared/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  return new T1Module({
    name: "admin/shared",
    viewCfgs: {
      search: {
        collection: null
      }
    }
  });
});
