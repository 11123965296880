define('modules/reporting/campaigns/dataExport/manage/views/manage',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "text!../templates/manage.html"], function ($, _, T1, T1View, T1Layout, template) {
  "use strict";

  const T1Publish = T1.EventHub.publish;
  return T1View.extend({
    template: template,
    dataEvents: {
      DataExportModel: {
        "toggle:btnAddReport": function (state) {
          T1Publish("manage.toggleBtnAddReport", state);
        },
        "update:reportStatus": function (id) {
          T1Publish("manage.updateReportStatus", id);
        }
      }
    },
    initialize: function (args) {
      this.DataExportModel = args.dataExportModel;
    },
    initLayouts: function () {
      this.layout = new T1Layout({
        el: () => this.el,
        template: template,
        layout: {
          ".header": [{
            module: "reporting/campaigns/dataExport/manage",
            options: {
              dataExportModel: this.DataExportModel
            },
            viewType: "header"
          }],
          ".grid-holder": [{
            module: "reporting/campaigns/dataExport/manage",
            options: {
              dataExportModel: this.DataExportModel
            },
            viewType: "grid"
          }]
        }
      });
    },
    load: function () {
      this.initLayouts();
      this.render().then(() => {
        this.layout.load();
      });
    }
  });
});
