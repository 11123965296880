define('modules/reporting/campaigns/performance/views/panel',['require','jQuery','Underscore','collections/campaignReport','text!../templates/layout.html','modules/reporting/utils/reportingComm','T1','T1Layout','T1PerformanceTimer','T1Permissions','T1View','text!../templates/panel.html','modules/reporting/campaigns/utils/reportingUtils','When'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const CampaignReport = require("collections/campaignReport");
  const layout = require("text!../templates/layout.html");
  const ReportingComm = require("modules/reporting/utils/reportingComm");
  const T1 = require("T1");
  const T1Layout = require("T1Layout");
  const T1Timer = require("T1PerformanceTimer");
  const T1Permissions = require("T1Permissions");
  const T1View = require("T1View");
  const template = require("text!../templates/panel.html");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  const When = require("When");
  let comm, timerValue;
  const section = "performance";
  const trackingSection = "Reporting - Performance";
  const GAMap = {
    campaignApiLoad: "RPTG:DAILY:SUM:API:CAMPAIGN",
    campaignNestedApiLoad: "RPTG:DAILY:API:CAMPAIGN",
    combinedLoad: "RPTG:DAILY:API:LOAD:TOTAL",
    dim1ApiLoad: "RPTG:DAILY:SUM:API:",
    dim1NestedApiLoad: "RPTG:DAILY:API:",
    dim2ApiLoad: "RPTG:DAILY2:SUM:API:",
    dim2NestedApiLoad: "RPTG:DAILY2:API:",
    totalApiLoad: "RPTG:DAILY:SUM:API:TOTAL",
    totalNestedApiLoad: "RPTG:DAILY:API:TOTAL"
  };
  return T1View.extend({
    dim1Key: "strategy_id",
    dim2Key: "",
    template: template,
    dataEvents: {
      reportModel: {
        "change:tempDimension": "loadTempDimensions",
        "change:tempDim2": "loadTempDimensions",
        "change:campaignBenchmarkOn": function () {
          T1.EventHub.publish("change:campaignBenchmarkOn");
        },
        "change:columnKeys": function () {
          T1.EventHub.publish("change:columnKeys");
        },
        "change:dimension": "changeDimension",
        "change:dim2": "changeDimension",
        "change:dimensionItems": function () {
          T1.EventHub.publish(`change:dimensionItems:${section}`);
        },
        "change:hiddenCount": function () {
          T1.EventHub.publish("change:hiddenCount");
        },
        "change:highlightedItem": function (model) {
          T1.EventHub.publish("change:highlightedItem", model);
        },
        "change:filteredInItems": "getTotalData",
        "change:filteredOutItems": function () {
          if (this.dim2Key) {
            this.prevDim1Key = null;
            this.changeDimension();
          } else {
            T1.EventHub.publish("change:filteredOutItems");
          }
        },
        "change:filteredOutItemsDim2": function () {
          this.prevDim2Key = null;
          this.changeDimension();
        }
      },
      campaign: {
        "change:start_end": function () {
          this.report.start = this.campaign.get("start");
          this.report.end = this.campaign.get("end");
          this.load();
        }
      }
    },
    eventhubEvents: {
      "reports:select:campaign": function (args) {
        if (this.currentReportId !== args.id) {
          this.report.id = args.id;
        }
      },
      "daily:grid:inited": function () {
        this.gridInited = true;
        this.getTotalData();
      },
      "remove:dim2": function (isReloadNeeded) {
        const {
          collection2: collection2,
          tempDimensionCollection2: tempDimensionCollection2
        } = comm.dataGroup;
        this.tempDim2Data = null;
        collection2.loaded = false;
        collection2.unregister();
        collection2.reset();
        tempDimensionCollection2.loaded = false;
        tempDimensionCollection2.unregister();
        tempDimensionCollection2.reset();
        if (isReloadNeeded) {
          this.changeDimension();
        }
      },
      "grid.showEditStrategyPopup": function (strategyId) {
        this.editStrategyQuickEditLayout.layout[".edit-strategy-popup-holder"][0].options.strategyId = strategyId;
        this.editStrategyQuickEditLayout.load();
      },
      "close.editStrategyPopup": function () {
        this.editStrategyQuickEditLayout.destroy();
      },
      "toggle:loader": function (show) {
        this.toggleLoader(show);
      }
    },
    initialize(args) {
      const {
        models: models,
        report: report
      } = args;
      const {
        campaign: campaign,
        mainModel: mainModel,
        reportMeta: reportMeta,
        reportModel: reportModel,
        reportState: reportState
      } = models;
      const focus = reportModel.get("focus");
      const fetchOptions = {
        dimensions: null,
        end_date: null,
        filter: "campaign_id=",
        order: "date",
        precision: 2,
        start_date: null,
        time_rollup: null
      };
      function fetchURL() {
        const opts = $.extend({}, this.fetchOptions);
        opts.filter += String(this.id);
        return `${T1.RPT_API_ROOT}${this.urlString}?${$.param(opts)}`;
      }
      function fetchURL2() {
        const opts = $.extend({}, this.fetchOptions);
        opts.filter += String(this.id) + this.filteredDimensionIDs;
        return `${T1.RPT_API_ROOT + this.urlString}?${$.param(opts)}`;
      }
      Object.assign(this, {
        attributionGroup: "MediaMath Last Touch",
        campaign: campaign,
        cfgs: args,
        currency: mainModel.get("currency"),
        currentReportId: report.id,
        hasEcoCostFlag: T1Permissions.check("feature", "reporting_ecosystem_cost"),
        mainModel: mainModel,
        report: report,
        reportMeta: reportMeta,
        reportModel: reportModel,
        reportModelObj: $.extend({}, reportModel.toJSON()),
        reportState: reportState,
        subSection: args.subSection,
        totalModel: models.total
      });
      comm = new ReportingComm({
        section: section,
        fetchOptions: fetchOptions
      });
      this.cfgs.dataGroup = comm.dataGroup;
      this.cfgs.type = "series";
      if (focus === "display" || focus === "video") {
        const strategyChannelFilter = `strategy_channel="${focus.toUpperCase()}"&campaign_id=`;
        fetchOptions.filter = strategyChannelFilter;
        models.totalStrategyChannel = $.extend(true, {}, models.total);
        models.totalStrategyChannel.fetchOptions.filter = strategyChannelFilter;
        models.summaryStrategyChannel = $.extend(true, {}, models.summary);
        models.summaryStrategyChannel.fetchOptions.filter = strategyChannelFilter;
        comm.buildModel("campaignModel", models.summaryStrategyChannel);
        comm.buildModel("totalModel", models.totalStrategyChannel);
      } else {
        comm.buildModel("campaignModel", models.summary);
        comm.buildModel("totalModel", models.total);
      }
      const campaignGrossTimeKey = models.timeSeries.summary.fetchKey;
      const seriesTimeKey = models.timeSeries.daily.fetchKey;
      this.updateFetchOptionsDate(fetchOptions);
      const dim1Info = reportModel.get("dimensionInfo");
      this.dim1Key = dim1Info.key;
      this.dim1NameField = this.dim1Key === dim1Info.nameField ? "" : `,${dim1Info.nameField}`;
      const dim1DimensionParams = `campaign_id,${this.dim1Key}${this.dim1NameField}`;
      comm.buildCollection("dim1Collection", models.summaryCollection, {
        dimensions: dim1DimensionParams,
        time_rollup: campaignGrossTimeKey
      });
      comm.buildCollection("dim1FilteredCollection", models.summaryCollection, {
        dimensions: dim1DimensionParams,
        time_rollup: campaignGrossTimeKey
      });
      let urlString = "std/performance";
      if (this.hasEcoCostFlag) {
        urlString = `std/performance_ecosystem_cost`;
      }
      comm.buildCollection("campaignNestedCollection", new CampaignReport({
        urlString: urlString,
        fetchOptions: $.extend({}, fetchOptions, {
          dimensions: "organization_id",
          time_rollup: seriesTimeKey
        }),
        url: fetchURL
      }));
      comm.buildCollection("totalNestedCollection", new CampaignReport({
        urlString: urlString,
        fetchOptions: $.extend({}, fetchOptions, {
          dimensions: "organization_id",
          time_rollup: seriesTimeKey
        }),
        url: fetchURL2
      }));
      comm.buildCollection("collection", new CampaignReport({
        urlString: urlString,
        fetchOptions: $.extend({}, fetchOptions, {
          dimensions: dim1DimensionParams,
          time_rollup: seriesTimeKey
        }),
        url: fetchURL2
      }));
      comm.buildCollection("dim1FilteredNestedCollection", new CampaignReport({
        urlString: urlString,
        fetchOptions: $.extend({}, fetchOptions, {
          dimensions: dim1DimensionParams,
          time_rollup: seriesTimeKey
        }),
        url: fetchURL2
      }));
      comm.buildCollection("dimensionItemsCollection", models.dimensionItemsCollection, {
        time_rollup: "all"
      });
      const dim2Info = mainModel.get("dim2Info");
      this.dim2Key = dim2Info && dim2Info.key;
      const dim2NameField = dim2Info && dim2Info.nameField;
      this.dim2NameField = this.dim2Key === dim2NameField ? "" : `,${dim2NameField}`;
      const dim2DimensionParams = `campaign_id,${this.dim2Key || ""}${this.dim2NameField}`;
      comm.buildCollection("dim2Collection", models.summaryCollection, {
        dimensions: dim2DimensionParams,
        time_rollup: campaignGrossTimeKey
      });
      comm.buildCollection("collection2", new CampaignReport({
        fetchOptions: $.extend({}, fetchOptions, {
          dimensions: dim2DimensionParams,
          time_rollup: seriesTimeKey
        }),
        url: fetchURL,
        urlString: urlString
      }));
      comm.buildCollection("tempDimensionCollection2", models.dimensionItemsCollection, {
        time_rollup: "all"
      });
      this.handleDataProxy = $.proxy(this.handleData, this);
    },
    initLayouts() {
      const cfgs = this.cfgs;
      this.gridInited = false;
      this.layout = new T1Layout({
        el: () => this.$(".w-report-panel"),
        template: layout,
        layout: {
          ".report-chart": [{
            module: "reporting/campaigns/performance",
            options: cfgs,
            viewType: "chartPanel"
          }],
          ".report-controls": [{
            module: "reporting/campaigns/performance",
            options: cfgs,
            viewType: "controls"
          }],
          ".report-grid": [{
            module: "reporting/campaigns/performance",
            options: cfgs,
            viewType: "grid"
          }]
        }
      });
      this.editStrategyQuickEditLayout = new T1Layout({
        el: () => this.$(".edit-strategy-popup"),
        template: `<div class="edit-strategy-popup-holder"></div>`,
        layout: {
          ".edit-strategy-popup-holder": [{
            module: "reporting/campaigns/performance",
            viewType: "strategyQuickEdit",
            options: {
              arguments: cfgs,
              selectedCurrency: this.currency,
              strategyId: this.strategyQuickViewID
            }
          }]
        }
      });
      this.layout.serialize = () => ({
        chartHidden: !this.chartShown
      });
    },
    updateFetchOptionsDate(fetchOptions) {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this.cfgs.models;
      const dateFormat = "yyyy-MM-dd";
      if (reportModel.get("startDate")) {
        fetchOptions.start_date = mainModel.getDateString(reportModel.get("startDate"));
        fetchOptions.end_date = mainModel.getDateString(reportModel.get("endDate"));
      } else {
        fetchOptions.start_date = Utils.defaultStartDate.toString(dateFormat);
        fetchOptions.end_date = Utils.defaultEndDate.toString(dateFormat);
      }
    },
    loadTempDimensions() {
      this.setGetDataOnce();
      this.getDataOnce().then(this.handleDataProxy);
    },
    changeDimension() {
      this.reportModel.resetColumnResize();
      this.reportModel.resetHiddenItems();
      this.load();
    },
    timer(category, label, startStop) {
      T1Timer[startStop]({
        category: category,
        label: label
      });
    },
    needsDataLoad() {
      return this.unloaded || this.currentReportId !== this.report.id || this.prevFocus !== this.focus || this.prevDim1Key !== this.dim1Key || this.prevDim2Key !== this.dim2Key || this.prevReportStart !== this.report.start || this.prevReportEnd !== this.report.end || this.prevCurrency !== this.currency || this.prevAttributionGroup !== this.attributionGroup;
    },
    resetData() {
      if (comm) {
        comm.clearDataGroup();
        comm = null;
      }
    },
    load() {
      const {
        mainModel: mainModel,
        reportModel: reportModel
      } = this;
      const dim1Info = reportModel.get("dimensionInfo");
      const dim2Info = reportModel.get("dim2Info");
      if (mainModel.leftTheSection(section)) {
        return;
      }
      this.focus = reportModel.get("focus");
      this.dim1Key = dim1Info.key;
      this.dim1NameField = this.dim1Key === dim1Info.nameField ? "" : `,${dim1Info.nameField}`;
      this.dim2Key = dim2Info && dim2Info.key;
      const dim2NameField = dim2Info && dim2Info.nameField;
      this.dim2NameField = this.dim2Key === dim2NameField ? "" : `,${dim2NameField}`;
      this.chartShown = mainModel.get("chartShown") === "true";
      this.currency = mainModel.get("currency");
      mainModel.setTrackingSection(trackingSection);
      if (this.needsDataLoad()) {
        reportModel.resetColorData();
        this.setGetDataOnce();
      } else {
        reportModel.setCollectionFetchParams(comm.dataGroup.collection.fetchOptions);
      }
      this.unloaded = false;
      if (this.layout) {
        this.layout.unload();
      }
      this.initLayouts();
      this.render().then(() => {
        this.layout.load();
        this.$loader = this.$(".loader-container");
        this.getDataOnce().then(this.handleDataProxy);
        this.prevFocus = this.focus;
        this.prevDim1Key = this.dim1Key;
        this.prevDim2Key = this.dim2Key;
        this.prevReportStart = this.report.start;
        this.prevReportEnd = this.report.end;
        this.prevCurrency = this.currency;
        this.prevAttributionGroup = this.attributionGroup;
      });
    },
    toggleLoader(show) {
      const animTime = 200;
      if (show) {
        this.$loader.fadeIn(animTime);
      } else {
        this.$loader.fadeOut(animTime);
      }
    },
    setGetDataOnce() {
      this.getDataOnce = _.once(this.getData);
    },
    updateFetchOptions(optsArr) {
      const requestMetrics = Object.keys(this.reportMeta.getRequestMetrics()).toString();
      for (const opts of optsArr) {
        const startIndex = opts.filter.indexOf("attribution_group");
        const endIndex = opts.filter.indexOf("&");
        if (startIndex > -1) {
          opts.filter = opts.filter.replace(opts.filter.substring(startIndex, endIndex + 1), "");
        }
        opts.filter = `attribution_group=${this.attributionGroup}&${opts.filter}`;
        opts.metrics = requestMetrics;
      }
    },
    processUSDstreamingData(data) {
      if (this.currency === "usd" && data) {
        if (data.models) {
          for (const model of data.models) {
            model.attributes = Utils.removeStrFromObjKey(model.attributes, "_usd");
          }
        } else {
          data.attributes = Utils.removeStrFromObjKey(data.attributes, "_usd");
        }
      }
    },
    getData() {
      let dateRangeChanged, dim1FilteredCollection, dim1FilteredNestedCollection, dim2FetchOptionsExtend, dim2FetchParams, dim2FilteredItems, dim2FilteredOutCount, dim2UrlParams, originalFilter, originalFilterIndex;
      const self = this;
      const {
        mainModel: mainModel,
        reportModel: reportModel,
        timer: timer,
        report: report
      } = this;
      let silent = false;
      const reportID = report.id;
      const focus = reportModel.get("focus") || "all";
      const dataGroup = comm.dataGroup;
      let dim1Name = "";
      const dim1Info = reportModel.get("dimensionInfo");
      const tempDim1 = reportModel.get("tempDimension");
      const tempDim1Info = reportModel.get("tempDimensionInfo");
      let dim1NestedCollection = tempDim1 ? dataGroup.dimensionItemsCollection : dataGroup.collection;
      let dim2Name = "";
      const dim2Info = reportModel.get("dim2Info");
      const tempDim2 = reportModel.get("tempDim2");
      const tempDim2Info = reportModel.get("tempDim2Info");
      let dim2Collection = dataGroup.dim2Collection;
      let dim2NestedCollection = tempDim2 ? dataGroup.tempDimensionCollection2 : dataGroup.collection2;
      const doLoadDim2 = Boolean(tempDim2Info && tempDim2Info.key || dim2Info && dim2Info.key);
      const campaignModelDeferred = When.defer();
      const campaignNestedCollectionDeferred = When.defer();
      const dim1CollectionDeferred = When.defer();
      const dim1NestedCollectionDeferred = When.defer();
      const dim1FilteredCollectionDeferred = When.defer();
      const dim1FilteredNestedCollectionDeferred = When.defer();
      const dim2CollectionDeferred = When.defer();
      const dim2NestedCollectionDeferred = When.defer();
      const promises = [campaignModelDeferred.promise, campaignNestedCollectionDeferred.promise, dim1CollectionDeferred.promise, dim1NestedCollectionDeferred.promise, dim1FilteredCollectionDeferred.promise, dim1FilteredNestedCollectionDeferred.promise, dim2CollectionDeferred.promise, dim2NestedCollectionDeferred.promise];
      const campaignFetchOptionsExtend = {};
      if (!reportID) {
        return;
      }
      if (!(tempDim1Info && tempDim1Info.key) && !(tempDim2Info && tempDim2Info.key)) {
        this.toggleLoader(true);
      }
      this.updateFetchOptionsDate(campaignFetchOptionsExtend);
      timerValue = reportID;
      this.currentReportId = reportID;
      this.mainCallsDone = false;
      let urlString = "std/performance";
      if (this.hasEcoCostFlag) {
        urlString = `std/performance_ecosystem_cost`;
      }
      comm.reportID = reportID;
      comm.urlString = urlString;
      const campaignModel = comm.resetDataItem("campaignModel", campaignFetchOptionsExtend);
      campaignModel.filteredDimensionIDs = "";
      this.campaignFetchOptionsExtend = campaignFetchOptionsExtend;
      const campaignNestedCollection = comm.resetDataItem("campaignNestedCollection", campaignFetchOptionsExtend);
      comm.resetDataItem("totalModel", campaignFetchOptionsExtend);
      const totalNestedCollection = comm.resetDataItem("totalNestedCollection", campaignFetchOptionsExtend);
      this.totalNestedCollection = totalNestedCollection;
      if (tempDim1Info) {
        dim1Name = tempDim1Info.nameField;
      } else if (dim1Info) {
        dim1Name = dim1Info.nameField;
      }
      dim1Name = dim1Name.toUpperCase().replace(/[ ]/g, "_");
      if (tempDim1) {
        this.dim1Key = tempDim1Info.key;
        this.dim1NameField = this.dim1Key === tempDim1Info.nameField ? "" : `,${tempDim1Info.nameField}`;
        silent = true;
      }
      const campaignFetchParams = mainModel.getMultiCampaignExtCols(this.dim1Key);
      const dim1FetchParams = this.dim1Key !== "campaign_name" ? this.dim1Key + this.dim1NameField : "";
      const dim1FetchOptionsExtend = {
        dimensions: campaignFetchParams + dim1FetchParams
      };
      $.extend(dim1FetchOptionsExtend, campaignFetchOptionsExtend);
      if (!tempDim1) {
        reportModel.setCollectionFetchParams(dim1NestedCollection.fetchOptions);
      }
      const dim1Collection = comm.resetDataItem("dim1Collection", dim1FetchOptionsExtend);
      dim1Collection.comparator = model => model.get(this.dim1Key);
      dim1NestedCollection = comm.resetDataItem("dim1NestedCollection", dim1FetchOptionsExtend, dim1NestedCollection);
      dim1NestedCollection.filteredDimensionIDs = dim1NestedCollection.filteredDimensionIDs || "";
      this.doLoadDim2 = doLoadDim2;
      if (doLoadDim2) {
        if (tempDim2Info && tempDim2Info.nameField) {
          dim2Name = tempDim2Info.nameField;
        } else if (dim2Info && dim2Info.nameField) {
          dim2Name = dim2Info.nameField;
        }
        dim2Name = dim2Name.toUpperCase().replace(/[ ]/g, "_");
        if (tempDim2 && tempDim2Info) {
          this.dim2Key = tempDim2Info.key;
          this.dim2NameField = this.dim2Key === tempDim2Info.nameField ? "" : `,${tempDim2Info.nameField}`;
          silent = true;
        }
        dim2FetchParams = this.dim2Key !== "campaign_name" ? this.dim2Key + this.dim2NameField : "";
        dim2FetchOptionsExtend = {
          dimensions: campaignFetchParams + dim2FetchParams
        };
        $.extend(dim2FetchOptionsExtend, campaignFetchOptionsExtend);
        dim2Collection = comm.resetDataItem("dim2Collection", dim2FetchOptionsExtend);
        dim2Collection.comparator = model => model.get(this.dim2Key);
        dim2NestedCollection = comm.resetDataItem("dim2NestedCollection", dim2FetchOptionsExtend, dim2NestedCollection);
        dim1FilteredCollection = comm.resetDataItem("dim1FilteredCollection", dim1FetchOptionsExtend);
        dim1FilteredCollection.comparator = model => model.get(this.dim1Key);
        dim1FilteredNestedCollection = comm.resetDataItem("dim1FilteredNestedCollection", dim1FetchOptionsExtend);
        dim1FilteredNestedCollection.filteredDimensionIDs = dim1FilteredNestedCollection.filteredDimensionIDs || "";
        dim2FilteredItems = reportModel.get("filteredInItemsDim2") || [];
        dim2FilteredOutCount = reportModel.get("filteredOutCountDim2") || 0;
        if (dim2FilteredItems.length) {
          dim2FilteredItems = reportModel.getEscapedNames(dim2FilteredItems);
        }
        dim2UrlParams = this.dim2Key && dim2FilteredItems.length && dim2FilteredOutCount > 0 ? `${this.dim2Key}=${dim2FilteredItems.toString()}&` : "";
        if (focus === "display" || focus === "video") {
          originalFilterIndex = dim1FilteredCollection.fetchOptions.filter.lastIndexOf("strategy_channel");
          originalFilter = dim1FilteredCollection.fetchOptions.filter.substring(originalFilterIndex);
        } else {
          originalFilterIndex = dim1FilteredCollection.fetchOptions.filter.lastIndexOf("campaign_id");
          originalFilter = dim1FilteredCollection.fetchOptions.filter.substring(originalFilterIndex);
        }
        dim1FilteredCollection.fetchOptions.filter = dim2UrlParams + originalFilter;
        dim1FilteredNestedCollection.fetchOptions.filter = dim2UrlParams + originalFilter;
        if (!tempDim2) {
          reportModel.setCollectionFetchParams(dim1FilteredNestedCollection.fetchOptions);
        }
      } else {
        dim1FilteredCollection = comm.dataGroup.dim1FilteredCollection;
        dim1FilteredCollection.reset();
        dim1FilteredNestedCollection = comm.dataGroup.dim1FilteredNestedCollection;
        dim1FilteredNestedCollection.reset();
      }
      this.updateFetchOptions([campaignModel.fetchOptions, campaignNestedCollection.fetchOptions, dim1Collection.fetchOptions, dim1FilteredCollection.fetchOptions, dim1FilteredNestedCollection.fetchOptions, dim1NestedCollection.fetchOptions, dim2Collection.fetchOptions, dim2NestedCollection.fetchOptions]);
      this.abortAjaxCalls(true);
      timer(GAMap.combinedLoad, timerValue, "start");
      function loadCampaignDataHandler() {
        timer(GAMap.campaignApiLoad, timerValue, "start");
        campaignModel.loaded = false;
        campaignModel.fetch({
          onSuccess(resp) {
            timer(GAMap.campaignApiLoad, timerValue, "stop");
            self.processUSDstreamingData(resp);
            campaignModel.loaded = true;
            campaignModelDeferred.resolve();
          },
          onError(error) {
            throw new Error(`campaignModel fetch failed: ${error}`);
          }
        });
      }
      function loadDim1DataHandler() {
        timer(GAMap.dim1ApiLoad + dim1Name, timerValue, "start");
        dim1Collection.loaded = false;
        dim1Collection.fetch({
          silent: silent,
          onSuccess(resp) {
            if (tempDim1) {
              self.tempDim1Data = dim1Collection;
            } else {
              self.tempDim1Data = null;
            }
            timer(GAMap.dim1ApiLoad + dim1Name, timerValue, "stop");
            self.processUSDstreamingData(resp);
            dim1Collection.loaded = true;
            reportModel.setReportInfo(`${section}-${focus}`, dim1Collection.ajxResponseHeaders);
            dim1CollectionDeferred.resolve();
          },
          onError(error) {
            throw new Error(`dim1Collection fetch failed: ${error}`);
          }
        });
      }
      function loadDim1FilteredDataHandler() {
        timer(GAMap.dim1ApiLoad + dim1Name, timerValue, "start");
        dim1FilteredCollection.loaded = false;
        dim1FilteredCollection.fetch({
          silent: silent,
          onSuccess: function (resp) {
            timer(GAMap.dim1ApiLoad + dim1Name, timerValue, "stop");
            self.processUSDstreamingData(resp);
            dim1FilteredCollection.loaded = true;
            dim1FilteredCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`dim1FilteredCollection fetch failed: ${error}`);
          }
        });
      }
      function loadDim2DataHandler() {
        timer(GAMap.dim2ApiLoad + dim2Name, timerValue, "start");
        dim2Collection.loaded = false;
        dim2Collection.fetch({
          silent: silent,
          onSuccess: function (resp) {
            if (tempDim2) {
              self.tempDim2Data = dim2Collection;
            } else {
              self.tempDim2Data = null;
            }
            timer(GAMap.dim2ApiLoad + dim2Name, timerValue, "stop");
            self.processUSDstreamingData(resp);
            dim2Collection.loaded = true;
            dim2CollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`dim2Collection fetch failed: ${error}`);
          }
        });
      }
      function loadCampaignNestedDataHandler() {
        timer(GAMap.campaignNestedApiLoad, timerValue, "start");
        campaignNestedCollection.loaded = false;
        campaignNestedCollection.fetch({
          silent: silent,
          onSuccess: function (resp) {
            timer(GAMap.campaignNestedApiLoad, timerValue, "stop");
            self.processUSDstreamingData(resp);
            campaignNestedCollection.loaded = true;
            campaignNestedCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`campaignNestedCollection fetch failed: ${error}`);
          }
        });
      }
      function loadDim1NestedDataHandler() {
        timer(GAMap.dim1NestedApiLoad + dim1Name, timerValue, "start");
        dim1NestedCollection.loaded = false;
        dim1NestedCollection.fetch({
          silent: silent,
          onSuccess: function (resp) {
            timer(GAMap.dim1NestedApiLoad + dim1Name, timerValue, "stop");
            self.processUSDstreamingData(resp);
            dim1NestedCollection.loaded = true;
            dim1NestedCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`dim1NestedCollection fetch failed: ${error}`);
          }
        });
      }
      function loadDim1FilteredNestedDataHandler() {
        timer(GAMap.dim1NestedApiLoad + dim1Name, timerValue, "start");
        dim1FilteredNestedCollection.loaded = false;
        dim1FilteredNestedCollection.fetch({
          silent: silent,
          onSuccess: function (resp) {
            timer(GAMap.dim1NestedApiLoad + dim1Name, timerValue, "stop");
            self.processUSDstreamingData(resp);
            dim1FilteredNestedCollection.loaded = true;
            dim1FilteredNestedCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`dim1FilteredNestedCollection fetch failed: ${error}`);
          }
        });
      }
      function loadDim2NestedDataHandler() {
        timer(GAMap.dim2NestedApiLoad + dim2Name, timerValue, "start");
        dim2NestedCollection.loaded = false;
        dim2NestedCollection.fetch({
          silent: silent,
          onSuccess: function (resp) {
            timer(GAMap.dim2NestedApiLoad + dim2Name, timerValue, "stop");
            self.processUSDstreamingData(resp);
            dim2NestedCollection.loaded = true;
            dim2NestedCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`dim2NestedCollection fetch failed: ${error}`);
          }
        });
      }
      reportModel.resetDateRangeChanged();
      if (tempDim1) {
        loadDim1DataHandler();
        campaignModelDeferred.resolve();
        campaignNestedCollectionDeferred.resolve();
        dim1NestedCollectionDeferred.resolve();
        dim1FilteredCollectionDeferred.resolve();
        dim1FilteredNestedCollectionDeferred.resolve();
        dim2CollectionDeferred.resolve();
        dim2NestedCollectionDeferred.resolve();
      } else if (tempDim2) {
        loadDim2DataHandler();
        campaignModelDeferred.resolve();
        campaignNestedCollectionDeferred.resolve();
        dim1CollectionDeferred.resolve();
        dim1NestedCollectionDeferred.resolve();
        dim1FilteredCollectionDeferred.resolve();
        dim1FilteredNestedCollectionDeferred.resolve();
        dim2NestedCollectionDeferred.resolve();
      } else {
        dateRangeChanged = this.prevReportStart !== undefined && (this.prevReportStart !== this.report.start || this.prevReportEnd !== this.report.end);
        if (dateRangeChanged) {
          reportModel.resetOnDateRangeChange();
        }
        loadCampaignDataHandler();
        loadCampaignNestedDataHandler();
        loadDim1DataHandler();
        loadDim1NestedDataHandler();
        if (doLoadDim2) {
          loadDim1FilteredDataHandler();
          loadDim1FilteredNestedDataHandler();
          loadDim2DataHandler();
          loadDim2NestedDataHandler();
        } else {
          dim1FilteredCollectionDeferred.resolve();
          dim1FilteredNestedCollectionDeferred.resolve();
          dim2CollectionDeferred.resolve();
          dim2NestedCollectionDeferred.resolve();
        }
      }
      return When.all(promises);
    },
    handleData() {
      if (this.mainModel.leftTheSection(section)) {
        return;
      }
      const reportModel = this.reportModel;
      const tempDim1 = reportModel.get("tempDimension");
      const tempDim2 = reportModel.get("tempDim2");
      const dataGroup = comm.dataGroup;
      this.timer(GAMap.combinedLoad, this.report.id, "stop");
      if (tempDim1) {
        reportModel.setDimensionItems({
          applying: false,
          dim1Data: this.tempDim1Data,
          dim1FilteredData: null,
          dim2Data: null
        });
      }
      if (tempDim2) {
        reportModel.setDimensionItems({
          applying: false,
          dim1Data: null,
          dim1FilteredData: null,
          dim2Data: this.tempDim2Data
        });
      }
      if (!tempDim1 && !tempDim2) {
        reportModel.setColorData(dataGroup.dim1Collection);
        reportModel.setDimensionItems({
          applying: true,
          dim1Data: dataGroup.dim1Collection,
          dim1FilteredData: dataGroup.dim1FilteredCollection,
          dim2Data: this.doLoadDim2 ? dataGroup.dim2Collection : null
        });
        if (dataGroup.dim1Collection.models.length) {
          reportModel.setTimeSeriesSlots(this.campaignFetchOptionsExtend);
        }
        this.mainCallsDone = true;
        T1.EventHub.publish("responseHeadersReady", reportModel.get("reportInfo").reportTypes[`performance-${reportModel.get("focus")}`]);
        this.getTotalData();
      }
      this.toggleLoader(false);
    },
    getTotalData() {
      if (this.mainCallsDone && this.gridInited && this.reportModel.get("dimensionItemsCount") > 0) {
        this.getTotal();
      }
    },
    getTotal() {
      if (this.mainModel.leftTheSection(section)) {
        return;
      }
      let urlParams;
      const self = this;
      const {
        reportModel: reportModel,
        timer: timer,
        dim1Key: dim1Key,
        dim2Key: dim2Key
      } = this;
      const {
        totalModel: totalModel,
        totalNestedCollection: totalNestedCollection
      } = comm.dataGroup;
      const changeEvent = "panel.filteredItemsChange";
      let dim1FilteredItems = reportModel.get("filteredInItems") || [];
      const dim1FilteredOutCount = reportModel.get("filteredOutCount") || 0;
      let dim2FilteredItems = reportModel.get("filteredInItemsDim2") || [];
      const dim2FilteredOutCount = reportModel.get("filteredOutCountDim2") || 0;
      const totalModelDeferred = When.defer();
      const totalNestedCollectionDeferred = When.defer();
      const promises = [totalModelDeferred.promise, totalNestedCollectionDeferred.promise];
      if (dim1FilteredItems.length) {
        dim1FilteredItems = reportModel.getEscapedNames(dim1FilteredItems);
      }
      if (dim2FilteredItems.length) {
        dim2FilteredItems = reportModel.getEscapedNames(dim2FilteredItems);
      }
      if (dim1Key === "deal_id") {
        urlParams = dim1FilteredItems.length ? `&${dim1Key}=${dim1FilteredItems.toString()}` : "";
      } else {
        urlParams = dim1Key && dim1FilteredItems.length && dim1FilteredOutCount > 0 ? `&${dim1Key}=${dim1FilteredItems.toString()}` : "";
      }
      if (dim2Key) {
        urlParams += dim2Key && dim2FilteredItems.length && dim2FilteredOutCount > 0 ? `&${dim2Key}=${dim2FilteredItems.toString()}` : "";
      }
      this.updateFetchOptions([totalModel.fetchOptions, totalNestedCollection.fetchOptions]);
      this.abortTotalAjaxCalls();
      totalNestedCollection.loaded = false;
      T1.EventHub.publish(changeEvent, false);
      function loadTotalHandler() {
        timer(GAMap.totalApiLoad, timerValue, "start");
        totalModel.filteredDimensionIDs = urlParams;
        totalModel.loaded = false;
        totalModel.fetch({
          onSuccess: function (resp) {
            timer(GAMap.totalApiLoad, timerValue, "stop");
            self.processUSDstreamingData(resp);
            totalModel.loaded = true;
            totalModelDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`total fetch failed: ${error}`);
          }
        });
      }
      function loadTotalSeriesHandler() {
        timer(GAMap.totalNestedApiLoad, timerValue, "start");
        totalNestedCollection.filteredDimensionIDs = urlParams;
        totalNestedCollection.loaded = false;
        totalNestedCollection.fetch({
          onSuccess: function (resp) {
            timer(GAMap.totalNestedApiLoad, timerValue, "stop");
            self.processUSDstreamingData(resp);
            totalNestedCollection.loaded = true;
            totalNestedCollectionDeferred.resolve();
          },
          onError: function (error) {
            throw new Error(`total nested fetch failed: ${error}`);
          }
        });
      }
      loadTotalHandler();
      loadTotalSeriesHandler();
      When.all(promises).then(function () {
        if (!self.mainModel.leftTheSection(section)) {
          T1.EventHub.publish(changeEvent, true);
        }
      });
    },
    abortAjaxCalls(dontAbortTotal) {
      if (comm) {
        comm.abortAjaxCalls(["campaignModel", "campaignNestedCollection", "dim1Collection", "dim2Collection", "dim1NestedCollection", "dim2NestedCollection", "dim1FilteredCollection", "collection", "collection2", "dimensionItemsCollection", "dim1FilteredNestedCollection", "tempDimensionCollection2"]);
      }
      if (!dontAbortTotal) {
        this.abortTotalAjaxCalls();
      }
    },
    abortTotalAjaxCalls() {
      if (comm) {
        comm.abortAjaxCalls(["totalModel", "totalNestedCollection"]);
      }
    },
    unload() {
      this.abortAjaxCalls();
      this.resetData();
      this.unloaded = true;
    }
  });
});
