define('modules/reporting/campaigns/siteTransparency/views/siteTransparency',["Underscore", "moment", "T1", "T1View", "T1Layout", "T1Permissions", "text!../templates/siteTransparency.html", "models/userPreferences", "modules/reporting/campaigns/models/additionalData", "T1Signature", "T1Preferences"], function (_, moment, T1, T1View, T1Layout, T1Permissions, template, UserPreferences, additionalData) {
  "use strict";

  let _reportModel;
  const defaultDimension = "site_domain";
  const defaultDimension2 = "strategy_name";
  const ID = "site_transparency";
  return T1View.extend({
    settings_menu_config: {
      raw_data: {
        label: "Export CSV",
        items: {
          export_current: {
            contentType: "text/csv",
            item_type: "action-item",
            label: "Current List",
            subSection: " (Summary)",
            url: `std/${ID}/download`,
            download_url: function () {
              return _reportModel.getDownloadURL({
                byDimension: true,
                dimensions: defaultDimension,
                full: false,
                isSummary: true,
                sourceURL: `std/${ID}/download`
              });
            }
          },
          export_current_daily: {
            contentType: "text/csv",
            item_type: "action-item",
            label: "Current List",
            subSection: " (Daily)",
            url: `std/${ID}/download`,
            download_url: function () {
              return _reportModel.getDownloadURL({
                byDimension: true,
                dimensions: defaultDimension,
                full: false,
                sourceURL: `std/${ID}/download`
              });
            }
          },
          export_full: {
            contentType: "text/csv",
            item_type: "action-item",
            label: "Full List",
            subSection: " (Summary)",
            url: `std/${ID}/download`,
            download_url: function () {
              return _reportModel.getDownloadURL({
                byDimension: true,
                dimensions: defaultDimension,
                full: true,
                isSummary: true,
                sourceURL: `std/${ID}/download`
              });
            }
          },
          export_full_daily: {
            contentType: "text/csv",
            item_type: "action-item",
            label: "Full List",
            subSection: " (Daily)",
            url: `std/${ID}/download`,
            download_url: function () {
              return _reportModel.getDownloadURL({
                byDimension: true,
                dimensions: defaultDimension,
                full: true,
                sourceURL: `std/${ID}/download`
              });
            }
          }
        }
      }
    },
    defaultSubSection: "summary",
    dimension2: "",
    focus: "",
    filterRange: "",
    filterMetric: "",
    filterExcludeZeros: "",
    metric: "",
    metric2: "",
    interval: "last_30_days",
    selectedColumns: "",
    subSection: "summary",
    sortKeyInfo: "",
    videoDimension2: "",
    videoFilterMetric: "",
    videoFilterRange: "",
    videoFilterExcludeZeros: "",
    videoMetric: "",
    videoMetric2: "",
    videoSelectedColumns: "",
    videoSortKeyInfo: "",
    displayDimension2: "",
    displayFilterMetric: "",
    displayFilterRange: "",
    displayFilterExcludeZeros: "",
    displayMetric: "",
    displayMetric2: "",
    displaySelectedColumns: "",
    displaySortKeyInfo: "",
    template: template,
    section: "siteTransparency",
    events: {
      click: "dehighlightItem",
      "action:resetFirstDimFilter": "resetFirstDimFilter"
    },
    eventhubEvents: {
      "select:focus": function (focus) {
        this.focus = focus;
        this.getFocusKey = _reportModel.makeFocusKeyFunc(focus);
        this.preferences.save();
      },
      "select:dimension": function (dimension = defaultDimension) {
        this[this.getFocusKey("dimension")] = dimension;
        this.preferences.save();
        _reportModel.setDimension(dimension);
      },
      "select:dim2": function (dimension) {
        this[this.getFocusKey("dimension2")] = dimension;
        this.preferences.save();
        _reportModel.setDimension(null, null, null, dimension);
      },
      "remove:dim2": function () {
        this[this.getFocusKey("dimension2")] = "";
        this.preferences.save();
      },
      "select:siteFilter": function (filterObj) {
        const getFocusKey = this.getFocusKey;
        this[getFocusKey("filterRange")] = filterObj.range;
        this[getFocusKey("filterMetric")] = filterObj.metric;
        this[getFocusKey("filterExcludeZeros")] = filterObj.excludeZeros;
        this.preferences.save();
        this.resetFirstDimFilter();
        _reportModel.setSiteFilter(filterObj);
        _reportModel.setSearchTerm("");
      },
      "select:interval": function (interval) {
        this.resetFirstDimFilter();
        this.interval = interval;
        this.preferences.save();
        _reportModel.setInterval(interval);
      },
      "select:metric": function (metric) {
        this[this.getFocusKey("metric")] = metric;
        this.preferences.save();
        _reportModel.setMetric(metric);
      },
      "select:metric2": function (metric) {
        this[this.getFocusKey("metric2")] = metric;
        this.preferences.save();
        _reportModel.setMetric2(metric);
      },
      "preference:change": function (args) {
        this[args.key] = args.value;
        this.preferences.save();
      },
      "reporting:campaign:load": "refreshUI",
      "reporting:meta:load": "reload",
      "reporting:focus:meta:load": function () {
        this.initPreferences();
        this.refreshUI();
      },
      "select:chart": function (chartType) {
        this.subSection = chartType;
        this.preferences.save();
        this.mainModel.setSubSection(this.mainModel.get("section"), chartType);
      }
    },
    refreshUI: function () {
      _reportModel.clearTempDimension();
      _reportModel.setSearchTerm("");
      T1.EventHub.publish("unload:noDataMessages");
      this.load();
    },
    initialize: function (cfgs = {}) {
      const models = this.models = cfgs.models;
      this.needEvents = true;
      this.report = cfgs.report;
      this.campaign = models.campaign;
      this.mainModel = models.mainModel;
      _reportModel = models.reportModel;
      _reportModel.setSection(ID);
      this.mainModel.setReportMainView(this);
    },
    initPreferences: function () {
      let focus, getFocusKey;
      const reportModel = _reportModel;
      const models = this.models;
      const signature = T1.Signature.create();
      const reportMeta = models.reportMeta;
      const siteTransparencyDimObj = reportMeta.get("dimensions").site_transparency;
      const siteTransparencySubSection = models.mainMeta.get("siteTransparencySubSection");
      signature.set(new Map([["root", "ui"], ["module", "reporting"], ["view", ID]]));
      this.preferences = T1.Preferences.create.call(this, signature, UserPreferences);
      this.preferences.mark(["dimension2", "displayDimension2", "displayFilterExcludeZeros", "displayFilterMetric", "displayFilterRange", "displayMetric", "displayMetric2", "displaySelectedColumns", "displaySortKeyInfo", "filterExcludeZeros", "filterMetric", "filterRange", "focus", "interval", "metric", "metric2", "selectedColumns", "sortKeyInfo", "subSection", "videoDimension2", "videoFilterExcludeZeros", "videoFilterMetric", "videoFilterRange", "videoMetric", "videoMetric2", "videoSelectedColumns", "videoSortKeyInfo"]);
      reportModel.setPreferences(this.preferences);
      reportModel.setInterval(this.interval);
      if (siteTransparencySubSection) {
        this.subSection = siteTransparencySubSection;
      } else {
        this.subSection = UserPreferences.get("ui.reporting.site_transparency.subSection") || this.defaultSubSection;
      }
      this.mainModel.setSubSection(this.mainModel.get("section"), this.subSection);
      this.focus = focus = this.focus || "all";
      this.getFocusKey = getFocusKey = reportModel.makeFocusKeyFunc(focus);
      reportModel.setFocus(focus);
      reportMeta.parseDimensions("dimensionOptions", siteTransparencyDimObj.viewBy);
      reportMeta.parseDimensions("dimension2Options", siteTransparencyDimObj.filterBy);
      reportMeta.parseMetrics();
      reportModel.parseColumns();
      const defaultMetric = "impressions";
      const defaultMetric2 = reportModel.getDefaultMetric(this.campaign, true);
      const dimensionKey2 = getFocusKey("dimension2");
      const dimension2 = this[dimensionKey2];
      let verifiedDimension2 = "";
      if (dimension2) {
        const verifiedDimensions = Object.keys(additionalData.dimensions.site_transparency[focus]);
        if (verifiedDimensions.includes(dimension2) && dimension2 !== defaultDimension) {
          verifiedDimension2 = dimension2;
        } else {
          verifiedDimension2 = defaultDimension2;
        }
      }
      this[dimensionKey2] = verifiedDimension2;
      this.preferences.save();
      reportModel.setDimension(defaultDimension, false, true, verifiedDimension2, false, true, true);
      reportModel.setMetric(this[getFocusKey("metric")] || defaultMetric, true);
      reportModel.setMetric2(this[getFocusKey("metric2")] || defaultMetric2, true);
      reportModel.setSiteFilter({
        excludeZeros: this[getFocusKey("filterExcludeZeros")] || "false",
        metric: this[getFocusKey("filterMetric")] || "-total_spend",
        range: this[getFocusKey("filterRange")] || "50"
      });
    },
    initLayouts: function () {
      const models = this.models;
      const reportMeta = models.reportMeta;
      const siteTransparencyDimObj = reportMeta.get("dimensions").site_transparency;
      const layoutConfig = {
        el: () => this.$(".w-site-transparency-panel"),
        template: `<div class="tabs-panel">\n          <div class="tabs-bar"></div>\n          <div class="tabs-control-focus"></div>\n          <div class="tabs-control-dimensions"></div>\n          <div class="tabs-control-dimensions-2"></div>\n          <div class="tabs-control-site-filter"></div>\n          \x3c!-- right-aligned items, hence reverse order --\x3e\n          <div class="tabs-info"></div>\n          <div class="tabs-settings"></div>\n          <div class="site-panel"></div>\n        </div>`,
        layout: {
          ".tabs-control-focus": [{
            module: "reporting/campaigns",
            options: {
              models: models
            },
            viewType: "select_focus"
          }],
          ".tabs-control-dimensions": [{
            module: "reporting/campaigns",
            options: {
              dimensionObj: siteTransparencyDimObj.viewBy,
              dimensionOptions: reportMeta.get("dimensionOptions"),
              index: 0,
              label: "View by:",
              models: models
            },
            viewType: "select_dimension"
          }],
          ".tabs-control-dimensions-2": [{
            module: "reporting/campaigns",
            options: {
              dimensionObj: siteTransparencyDimObj.all,
              dimensionOptions: reportMeta.get("dimension2Options"),
              index: 1,
              label: "+ Add Filter",
              models: models
            },
            viewType: "select_dimension"
          }],
          ".tabs-control-site-filter": [{
            module: "reporting/campaigns",
            options: {
              label: "Include only sites with:",
              models: models
            },
            viewType: "select_site_filter"
          }],
          ".tabs-settings": [{
            module: "reporting/campaigns",
            options: {
              menu_config: this.settings_menu_config,
              models: models
            },
            viewType: "settings_menu"
          }],
          ".tabs-info": [{
            module: "reporting/campaigns",
            viewType: "report_info"
          }],
          ".site-panel": [{
            module: "reporting/campaigns/siteTransparency",
            options: {
              models: models,
              report: this.report
            },
            viewType: "panel"
          }]
        }
      };
      this.layout = new T1Layout(layoutConfig);
    },
    load: function () {
      const models = this.models;
      _reportModel = models.reportModel;
      if (this.mainModel.leftTheSection(this.section)) {
        return;
      }
      if (this.needEvents) {
        this.bindViewEvents();
        this.needEvents = false;
      }
      _reportModel.setSection(ID, true);
      if (this.campaign.id && models.reportMeta.loaded) {
        if (this.layout) {
          this.layout.unload();
        }
        this.initPreferences();
        this.initLayouts();
        this.render().then(() => {
          this.layout.load();
          this.loaded = true;
        });
      }
    },
    unload: function () {
      this.loaded = false;
      this.needEvents = true;
    },
    dehighlightItem: function () {
      _reportModel.setHighlightedItem();
    },
    resetFirstDimFilter: function () {
      this.$(".clear-filter").trigger("click");
    }
  });
});
