define('models/campaign',['require','jQuery','Underscore','Backbone','When','T1','T1Model','T1Permissions','collections/campaignSiteLists','collections/customBrainSelections','models/performanceDataForModels','collections/pixelUniques','models/advertiser','models/budgetFlight','collections/budgetFlightCollection','models/campaignWatermark','models/extendedMarginManagementReport','collections/extendedMarginManagementReportCollection','models/goalMonitoringReport','collections/goalMonitoringReportCollection','models/strategy','collections/strategies','T1Preferences','T1Signature','models/userPreferences','collections/vendorContracts'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const Backbone = require("Backbone");
  const When = require("When");
  const T1 = require("T1");
  const T1Model = require("T1Model");
  const T1Permissions = require("T1Permissions");
  const CampaignSiteLists = require("collections/campaignSiteLists");
  const CustomBrainSelections = require("collections/customBrainSelections");
  const PerformanceData = require("models/performanceDataForModels");
  const PixelUniques = require("collections/pixelUniques");
  const Advertiser = require("models/advertiser");
  const BudgetFlight = require("models/budgetFlight");
  const BudgetFlightCollection = require("collections/budgetFlightCollection");
  const CampaignWatermark = require("models/campaignWatermark");
  const ExtendedMarginManagementReport = require("models/extendedMarginManagementReport");
  const ExtendedMarginManagementReportCollection = require("collections/extendedMarginManagementReportCollection");
  const GoalMonitoringReport = require("models/goalMonitoringReport");
  const GoalMonitoringReportCollection = require("collections/goalMonitoringReportCollection");
  const Strategy = require("models/strategy");
  const StrategyCollection = require("collections/strategies");
  const T1Preferences = require("T1Preferences");
  const T1Signature = require("T1Signature");
  const UserPreferences = require("models/userPreferences");
  const VendorsContracts = require("collections/vendorContracts");
  const validateAttributionWindowLength = function () {
    const minutesPerHour = 60;
    const hoursPerDay = 24;
    const maxDays = 30;
    const maxMinutes = minutesPerHour * maxDays * hoursPerDay;
    const maxConversions = 43200;
    const message = "Attribution window must be less than or equal to 30 days";
    const errorMessage = "Merit Attribution window must be less than or equal to 30 days";
    function isTooLong(length, unitOfTime) {
      return T1.Utils.getMinutes(length, unitOfTime) > maxMinutes;
    }
    return function (attrs, errorArr) {
      if (attrs.hasPCWindow && isTooLong(attrs.pc_window_minutes, attrs.pcWindowUnit)) {
        errorArr.push({
          field: "pc_window_minutes",
          message: message
        });
      }
      if (attrs.hasPVWindow && isTooLong(attrs.pv_window_minutes, attrs.pvWindowUnit)) {
        errorArr.push({
          field: "pv_window_minutes",
          message: message
        });
      }
      if (attrs.conversion_type === "variable" && attrs.conversion_variable_minutes > maxConversions) {
        errorArr.push({
          field: "conversion_variable_minutes",
          message: errorMessage
        });
      }
    };
  }();
  function removeNDARFormValues(acc, [key, val]) {
    if (!key.startsWith("nielsen_")) {
      acc[key] = val;
    }
    return acc;
  }
  T1.Models.Campaign = T1Model.extend({
    relations: [{
      type: Backbone.HasMany,
      key: "strategies",
      relatedModel: "Strategy",
      collectionType: "Strategies",
      includeInJSON: false,
      reverseRelation: {
        key: "campaign",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasOne,
      key: "advertiser",
      relatedModel: "Advertiser",
      includeInJSON: true
    }, {
      type: Backbone.HasMany,
      key: "rpt_legacy_goal_monitoring",
      relatedModel: "GoalMonitoringReport",
      collectionType: "GoalMonitoringReportCollection",
      includeInJSON: false,
      reverseRelation: {
        key: "campaign",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasMany,
      key: "rpt_goal_monitoring",
      relatedModel: "GoalMonitoringReport",
      collectionType: "GoalMonitoringReportCollection",
      includeInJSON: false,
      reverseRelation: {
        key: "campaign",
        includeInJSON: false
      }
    }, {
      type: Backbone.HasOne,
      key: "campaign_watermark",
      relatedModel: "CampaignWatermark",
      includeInJSON: false
    }, {
      type: Backbone.HasMany,
      key: "vendor_contract",
      relatedModel: "VendorContract",
      collectionType: "VendorContracts",
      includeInJSON: false
    }, {
      type: Backbone.HasMany,
      key: "budget_flight",
      relatedModel: "BudgetFlight",
      collectionType: "BudgetFlightCollection",
      includeInJSON: false,
      reverseRelation: {
        key: "campaign",
        includeInJSON: false
      }
    }],
    url: "campaigns/",
    name: "campaign",
    fixture: "-campaign",
    strategiesInitialLoad: false,
    favorite: false,
    showNotification: false,
    postEntityParse: {
      rpt_goal_monitoring: T1.Utils.objectTOArray,
      rpt_legacy_goal_monitoring: T1.Utils.objectTOArray,
      vendor_contract: T1.Utils.objectTOArray
    },
    canEdit() {
      var deferred = $.Deferred();
      deferred.resolve(T1Permissions.check("campaign.edit", this));
      return deferred;
    },
    validateAttrs(attrs) {
      var self = this;
      var errors = [];
      var requiredField = "This is a required field.";
      const {
        goal_type: goal_type,
        goal_value: goal_value,
        impression_cap_type: impression_cap_type,
        initialBudget: initialBudget,
        ongoingBudget: ongoingBudget,
        use_default: use_default,
        viewability_type: viewability_type
      } = attrs;
      var optionalFields = ["advertiser", "agency_fee", "connected_id_type", "ad_server_fee", "ads_txt_verified", "io_name", "io_reference_num", "id", "benchmark_cpm", "margin_pct", "total_impression_budget", "viewability_type"];
      const dependentRequired = {
        conversion_variable_minutes: () => attrs.conversion_type === "variable",
        frequency_amount: () => attrs.frequency_type !== "no-limit",
        initial_budget_pct: () => !use_default && initialBudget === "initial_budget_pct",
        initial_budget: () => !use_default && initialBudget === "initial_budget",
        learn_period: () => !use_default,
        margin_fixed_pct: () => attrs.margin_type === "fixed",
        merit_pixel_id: () => goal_type === "cpa" || goal_type === "cpe" || goal_type === "roi",
        ongoing_budget_pct: () => !use_default && initialBudget && ongoingBudget === "ongoing_budget_pct",
        ongoing_budget: () => !use_default && initialBudget && ongoingBudget === "ongoing_budget",
        pc_window_minutes: () => attrs.hasPCWindow,
        pv_window_minutes: () => attrs.hasPVWindow
      };
      if (goal_type === "ctr" || goal_type === "vcr" || goal_type === "viewability_rate" || goal_type === "vcpm") {
        optionalFields.push("merit_pixel_id");
      }
      validateAttributionWindowLength(attrs, errors);
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && !optionalFields.includes(key)) {
          if (dependentRequired[key]) {
            if (dependentRequired[key]()) {
              errors.push({
                field: key,
                message: requiredField
              });
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
        if (key === "use_viewability_reporting" && value) {
          if (!attrs.viewability_vendor_id) {
            errors.push({
              field: "viewability_vendor_id",
              message: requiredField
            });
          }
          if (!viewability_type) {
            errors.push({
              field: "viewability_type",
              message: requiredField
            });
          } else {
            if (viewability_type === "AUTO_TAG" && !attrs.viewability_sample_rate) {
              errors.push({
                field: "viewability_sample_rate",
                message: requiredField
              });
            }
          }
        }
        if (key === "goal_type" && (value === "vcpm" || value === "viewability_rate")) {
          if (!attrs.use_viewability_reporting) {
            errors.push({
              field: "use_viewability_reporting",
              message: "You must enable Viewability Post Bid Reporting in order to select a campaign goal type of " + "vCPM or Viewability Rate."
            });
          }
        }
        if (key === "suspicious_traffic_filter_level") {
          if (attrs.override_suspicious_traffic_filter === "1" && value === "-1") {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        }
        if (key === "frequency_amount") {
          const maxFreq = 9999999;
          if (attrs.frequency_amount > maxFreq) {
            errors.push({
              field: key,
              message: "Frequency amount cannot be higher than 9999999."
            });
          }
        }
        if (key === "impression_cap_automatic") {
          if ((impression_cap_type === "even" || impression_cap_type === "asap") && attrs.impression_cap_automatic === "1" && self.get("emptyImpBudget")) {
            errors.push({
              field: key,
              message: '"Auto" daily impression cap requires a non-zero Impression Budget.'
            });
          }
          if (impression_cap_type === "t1-optimized" && self.get("emptyImpBudget")) {
            errors.push({
              field: "impression-t1-optimized",
              message: '"T1-Optimized" daily impression cap requires a non-zero Impression Budget.'
            });
          }
        }
        if (key === "goal_value") {
          if (goal_type === "ctr" || goal_type === "vcr" || goal_type === "viewability_rate") {
            const maxVal = 100;
            if (goal_value < 0 || goal_value > maxVal) {
              let fieldName;
              switch (goal_type) {
                case "ctr":
                  fieldName = "CTR";
                  break;
                case "vcr":
                  fieldName = "Video Completion Rate";
                  break;
                case "viewability_rate":
                  fieldName = "viewability_rate";
                  break;
                default:
                  fieldName = goal_type.toUpperCase().replace(/_/g, " ");
                  break;
              }
              errors.push({
                field: key,
                message: `${fieldName} goal value must be a number between 0% and 100%.`
              });
            }
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    },
    fetchSiteLists(onSuccess) {
      var siteList = this.siteList || new CampaignSiteLists();
      this.siteList = siteList;
      siteList.id = this.id;
      siteList.fetchOptions = $.extend({
        full: "*",
        sort_by: "id",
        q: "assigned==1"
      }, siteList.fetchOptions);
      siteList.fetch({
        success: onSuccess || $.noop
      });
      return siteList;
    },
    fetchUniquesData(collection, onSuccess) {
      var self = this;
      var uniquesCollection = new PixelUniques();
      var json = collection.toJSON();
      var groupedPixels = _.groupBy(json, "selection_type");
      var options = {};
      var promises = [];
      function setOptionsAndFetch(pixelIds, fetchOptions) {
        var deferred = When.defer();
        var timeWindow = "yesterday";
        var advertiser = self.get("advertiser");
        var agency = advertiser && advertiser.get("agency");
        var orgId = agency.get("organization_id");
        uniquesCollection.fetchOptions = {
          dimensions: "pixel_name,pixel_id,referrer",
          filter: `organization_id=${orgId}&pixel_id=${pixelIds}&referrer_rank=01`,
          time_window: timeWindow,
          time_rollup: "by_day"
        };
        fetchOptions.success = function (resp) {
          if (!resp.length && timeWindow === "yesterday") {
            uniquesCollection.fetchOptions.time_window = "last_2_days";
            timeWindow = "last_2_days";
            uniquesCollection.fetch(fetchOptions);
          }
          self.customBrainSelections.forEach(function (model) {
            const uniquesModel = resp.find(item => item.pixel_id === model.get("selection_id"));
            if (uniquesModel) {
              model.set({
                load_count: uniquesModel.loads,
                refer_url: uniquesModel.referrer,
                uniques: uniquesModel.uniques
              });
            } else {
              model.unset("uniques");
              model.unset("load_count");
              model.unset("refer_url");
            }
          });
          deferred.resolve();
        };
        uniquesCollection.fetch(fetchOptions);
        return deferred.promise;
      }
      if (groupedPixels["Event Pixel"] && groupedPixels["Event Pixel"].length) {
        const eventPixelIds = _.pluck(groupedPixels["Event Pixel"], "selection_id");
        uniquesCollection.urlString = "event_pixel_loads";
        promises.push(setOptionsAndFetch(eventPixelIds, options, "event"));
      }
      if (groupedPixels["Event Pixel"] && groupedPixels["Data Pixel"].length) {
        const dataPixelIds = _.pluck(groupedPixels["Data Pixel"], "selection_id");
        uniquesCollection.urlString = "data_pixel_loads";
        promises.push(setOptionsAndFetch(dataPixelIds, options, "data"));
      }
      When.settle(promises).then(function () {
        onSuccess(self.customBrainSelections);
      });
    },
    fetchCustomBrainSelections(onSuccess, fetchUniquesData) {
      var customBrainSelections = this.customBrainSelections || new CustomBrainSelections();
      this.customBrainSelections = customBrainSelections;
      customBrainSelections.id = this.id;
      customBrainSelections.isPaginated = false;
      customBrainSelections.fetchOptions = $.extend({
        full: "*"
      }, customBrainSelections.fetchOptions);
      customBrainSelections.fetch({
        success: data => {
          if (fetchUniquesData) {
            this.fetchUniquesData(data, onSuccess);
          } else {
            onSuccess(data);
          }
        }
      });
      return customBrainSelections;
    },
    getPerformanceDataObj() {
      if (!this.performanceData) {
        this.performanceData = new PerformanceData({
          hostModel: this
        });
      }
      return this.performanceData;
    },
    fetch(opts) {
      var perfData = this.getPerformanceDataObj();
      perfData.hostModelFetchHandler(opts);
    },
    createEdit(rawData, options) {
      var statusInvalid = options.statusInvalid || $.noop();
      var conflictCallback = options.conflict || $.noop();
      const keepEmptyAndUndefinedAttribute = ["connected_id_type"];
      var getMinutes = T1.Utils.getMinutes;
      const validationErrors = this.validateAttrs(rawData);
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
        return;
      }
      if (/^(cpa|cpe|roi)$/.test(rawData.goal_type)) {
        rawData.pc_window_minutes = rawData.hasPCWindow === true ? getMinutes(rawData.pc_window_minutes, rawData.pcWindowUnit) : "";
        rawData.pv_window_minutes = rawData.hasPVWindow === true ? getMinutes(rawData.pv_window_minutes, rawData.pvWindowUnit) : "";
      } else if (/^(ctr|vcr|viewability_rate|vcpm)$/.test(rawData.goal_type)) {
        rawData.merit_pixel_id = "";
      }
      delete rawData.hasPVWindow;
      delete rawData.hasPCWindow;
      delete rawData.pcWindowUnit;
      delete rawData.pvWindowUnit;
      delete rawData.use_viewability_reporting;
      rawData = Object.entries(rawData).reduce(removeNDARFormValues, {});
      this.save(rawData, {
        success: model => {
          var onSuccess = options.success || $.noop();
          this.set({
            pc_window_minutes: rawData.pc_window_minutes,
            pv_window_minutes: rawData.pv_window_minutes
          });
          onSuccess(model);
        },
        statusInvalid: statusInvalid,
        conflict: conflictCallback,
        keepEmptyAndUndefinedAttribute: keepEmptyAndUndefinedAttribute
      });
    },
    duplicateCampaign(rowData, options) {
      var statusInvalid = options.invalid || $.noop();
      this.sync("POST", rowData, {
        action: "copy",
        success: function (data) {
          var onSuccess = options.success || $.noop;
          onSuccess(data);
        },
        statusInvalid: statusInvalid
      });
    },
    prepareStrategiesConfig(strategyCollection, fetchRequired) {
      var needToSetFetchRequired = fetchRequired !== undefined;
      var urlFilterObj = {
        entity: "campaign",
        id: this.id
      };
      if (needToSetFetchRequired === true) {
        urlFilterObj.fetchRequired = fetchRequired;
      }
      strategyCollection.urlFilter.set(urlFilterObj);
      strategyCollection.fetchOptions = $.extend({
        full: "*",
        with: ["rpt_goal_monitoring", "supplemental"]
      }, strategyCollection.fetchOptions);
    }
  });
  return T1.Models.Campaign;
});
