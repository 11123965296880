define('modules/creatives/video/control/headers',[], function () {
  return [{
    name: "checkbox-input",
    label: '<div class="checkbox all"></div>'
  }, {
    name: "toggle-arrow",
    label: "&nbsp;"
  }, {
    name: "default name",
    label: "name"
  }, {
    name: "default id",
    label: "id"
  }, {
    name: "default approval",
    label: "approval"
  }, {
    name: "default advertiser",
    label: "advertiser"
  }, {
    name: "default concept",
    label: "concept"
  }, {
    name: "default third-pas-id",
    label: "3pas id"
  }, {
    name: "default duration",
    label: "duration<br/>(seconds)"
  }, {
    name: "default conversion",
    label: "conversion"
  }, {
    name: "default adddate",
    label: "added on"
  }, {
    name: "default companion",
    label: "companion<br/>banner"
  }, {
    name: "secure",
    label: "secure",
    extraClasses: "default",
    infoTooltip: {
      info: "Secure creatives can run on both secure and non-secure inventory. Non-secure creatives can only run on non-secure inventory, which may limit scale. Visit our SSL KB for more details.",
      url: "https://mediamathsupport.force.com/s/article/Creative-Approvals"
    }
  }, {
    name: "default startdate",
    label: "start date"
  }, {
    name: "default enddate",
    label: "end date"
  }, {
    name: "config",
    label: "&nbsp;"
  }];
});
