define('modules/T1App/myApps/models/newAppModel',["jQuery", "Underscore", "Backbone", "T1", "T1Comm", "T1Model"], function ($, _, Backbone, T1, T1Comm, T1Model) {
  "use strict";

  T1.Models.newAppModel = T1Model.extend({
    apiRoot: T1.API_APPS_ROOT,
    url: "v1.0/apps",
    dataType: "json",
    initialize: function (options) {
      T1Model.prototype.initialize.call(this, options);
    },
    save: function (attributes, options) {
      var canEdit;
      var self = this;
      var modelErrors;
      var statusInvalid = options && options.statusInvalid ? options.statusInvalid : $.noop;
      var validationErrors;
      validationErrors = self.validateAttrs(attributes);
      if (validationErrors && validationErrors.length) {
        return statusInvalid(validationErrors);
      }
      function i(a, b, c) {
        return function (d) {
          return a ? a(b, d, c) : b.trigger("error", b, d, c);
        };
      }
      function sync() {
        var model = self;
        var success = options.success;
        var method;
        var errorDisplaySuppressingConfig;
        options.success = function (returnedAttributes, e, f) {
          if (!model.set(returnedAttributes)) {
            return !1;
          }
          return success && success(model, returnedAttributes, f);
        };
        options.statusInvalid = function (data) {
          var errors = [];
          var newData = JSON.parse(data);
          if ($.isArray(newData.errors)) {
            $.each(newData.errors, function (key, value) {
              errors.push({
                field: value.field,
                message: value.message
              });
            });
          }
          statusInvalid(errors);
        };
        options.error = i(options.error, model, options);
        method = "create";
        attributes = $.isEmptyObject(attributes) ? {} : $.extend({
          version: 0
        }, attributes);
        errorDisplaySuppressingConfig = options.errorDisplaySuppressingConfig;
        options = _.extend(options, {
          dataType: "json",
          errorDisplaySuppressingConfig: {
            errorSuppressed: true
          },
          processAjaxResponse: function (resp) {
            var jsonData;
            var dataType;
            var statusCode;
            if (resp.meta === "success") {
              statusCode = "ok";
            } else {
              statusCode = "error";
            }
            function isJson(str) {
              try {
                jsonData = JSON.parse(str);
              } catch (e) {
                dataType = "plaintxt";
              }
            }
            return {
              jsonData: isJson(resp) ? jsonData : resp,
              statusCode: statusCode
            };
          }
        });
        if (errorDisplaySuppressingConfig) {
          options.errorDisplaySuppressingConfig = errorDisplaySuppressingConfig;
        }
        return self.sync.call(self, method, attributes, options);
      }
      options = options || {};
      if (!this.isNew()) {
        canEdit = this.canEdit();
        canEdit.done(function (editable) {
          if (!editable) {
            return;
          }
          return sync();
        });
      } else {
        modelErrors = this.validate ? this.validate(attributes) : {};
        if ($.isArray(modelErrors) && modelErrors.length) {
          statusInvalid(modelErrors);
        } else {
          $.each(attributes, function (attr, val) {
            if (val === "" || val === undefined) {
              delete attributes[attr];
            }
          });
          return sync();
        }
      }
    },
    validateAttrs: function (attrs) {
      var errors = [];
      var requiredField = "This is a required field.";
      var validationResult;
      var reqFields = {
        name: function () {
          return !attrs.name;
        },
        url: function () {
          return !attrs.url;
        },
        organization_id: function () {
          return !attrs.organization_id;
        }
      };
      var extraValFields = {
        name: function () {
          if (attrs.name.length > 45) {
            return "Name cannot be longer than 45 characters.";
          }
          if (attrs.name === "") {
            return "Please provide a name.";
          }
        },
        url: function () {
          if (attrs.url === "") {
            return "You must provide a URL.";
          }
          if (attrs.url.indexOf("https://") === -1) {
            return "URL must include https://.";
          }
        },
        organization_id: function () {
          if (isNaN(attrs.organization_id)) {
            return "You must select an Organization.";
          }
        }
      };
      if ("string" === typeof attrs.name) {
        attrs.name = attrs.name.trim();
      }
      $.each(attrs, function (key, value) {
        if (value !== undefined && value === "" && !isNaN(value)) {
          if (reqFields[key]) {
            validationResult = reqFields[key]();
            if (validationResult) {
              if (typeof validationResult === "boolean") {
                errors.push({
                  field: key,
                  message: requiredField
                });
              } else {
                errors.push({
                  field: key,
                  message: validationResult
                });
              }
            }
          } else {
            errors.push({
              field: key,
              message: requiredField
            });
          }
        } else if (errors.length === 0 && extraValFields[key]) {
          validationResult = extraValFields[key]();
          if (validationResult) {
            errors.push({
              field: key,
              message: validationResult
            });
          }
        }
      });
      if (errors.length) {
        return errors;
      }
    }
  });
  return T1.Models.newAppModel;
});
