define('T1Permissions',["jQuery", "T1", "models/sessionUser", "models/userPreferences", "utils/LaunchDarklyUtils", "T1Preferences"], function ($, T1, User, UserPreferences, LaunchDarklyUtils) {
  "use strict";

  var instance;
  var userRoles = {
    admin: "ADMIN",
    manager: "MANAGER",
    reporter: "REPORTER"
  };
  var userScopes = {
    global: "GLOBAL",
    select: "SELECT"
  };
  var userTypes = {
    internal: "INTERNAL",
    others: "AGENCY"
  };
  function Permission() {
    var campaignEditCheck = function (user, campaign) {
      var serviceTypes = {
        self: "SELF",
        managed: "MANAGED"
      };
      var userRole = user.get("role");
      var userType = user.get("type");
      var userEditCampaign = parseInt(user.get("edit_campaigns"), 10);
      var campaignServiceType = campaign.service_type || campaign.get("service_type");
      if (userType === userTypes.internal) {
        if (userRole === userRoles.admin) {
          return campaignServiceType === serviceTypes.managed;
        }
        if (userRole === userRoles.manager) {
          return campaignServiceType === serviceTypes.managed && !!userEditCampaign;
        }
      } else {
        return campaignServiceType === serviceTypes.self && (userRole === userRoles.admin || userRole === userRoles.manager && !!userEditCampaign);
      }
      return false;
    };
    var permissions = {
      "campaign.create": function (user) {
        var userRole = user.get("role");
        var userEditCampaign = parseInt(user.get("edit_campaigns"), 10);
        var permission = false;
        if (userRole !== userRoles.reporter) {
          permission = true;
          if (userRole === userRoles.manager && userEditCampaign === 0) {
            permission = false;
          }
        }
        return permission;
      },
      "campaign.edit": campaignEditCheck,
      "campaign.editMargin": function (user, campaign) {
        var userRole = user.get("role");
        var userEditMargin = parseInt(user.get("edit_margins_and_performance"), 10);
        var permission = userRole !== userRoles.reporter;
        var canEditCampaign = campaignEditCheck(user, campaign);
        if (!canEditCampaign) {
          return false;
        }
        if (userRole === userRoles.manager && userEditMargin === 0) {
          permission = false;
        }
        return permission;
      },
      "campaign.readonly": function (user, campaign) {
        var allowEditing = campaignEditCheck(user, campaign);
        var userRole = user.get("role");
        return allowEditing === false && userRole !== userRoles.reporter;
      },
      "changeLog.view": function (user) {
        var userRole = user.get("role");
        return userRole !== userRoles.reporter;
      },
      "creative.edit": function (user) {
        var userRole = user.get("role");
        return userRole !== userRoles.reporter;
      },
      feature: function (user, featureName, data) {
        const launchDarklyFlag = LaunchDarklyUtils.getFlag(featureName);
        const isLaunchDarklyFeature = typeof launchDarklyFlag !== "undefined";
        const isPermission = this.hasOwnProperty(featureName);
        if (isPermission) {
          return this[featureName].apply(this, [user, data]);
        } else if (isLaunchDarklyFeature) {
          return launchDarklyFlag;
        }
        return UserPreferences.get(`ui.features.${featureName}`) === "1";
      },
      "feedback.edit": function () {
        return true;
      },
      "strategy.create": function (user, campaign) {
        var userRole = user.get("role");
        return userRole !== userRoles.reporter && campaignEditCheck(user, campaign);
      },
      "strategy.edit": function (user, strategy) {
        return campaignEditCheck(user, strategy.getRelated("campaign") || strategy.campaign);
      },
      "strategy.readonly": function (user, strategy) {
        var allowEditing = campaignEditCheck(user, strategy.getRelated("campaign"));
        var userRole = user.get("role");
        return allowEditing === false && userRole !== userRoles.reporter;
      },
      user: function (user) {
        return user.get("role");
      },
      "userpreferences.edit": function () {
        return true;
      },
      creatives: function (user) {
        var userRole = user.get("role");
        return userRole !== userRoles.reporter;
      },
      video: function (user, strategy) {
        var userRole = user.get("role");
        var pref = userRole !== userRoles.reporter;
        if (strategy) {
          return pref && strategy.get("media_type") === "VIDEO";
        }
        return pref;
      },
      admin_vendors: function (user) {
        var priv = false;
        if (user.get("scope") === userScopes.global && user.get("role") === userRoles.admin) {
          priv = true;
        }
        if (UserPreferences.get("ui.features.admin_vendors") === "1") {
          priv = true;
        }
        return priv;
      },
      pixels: function (user) {
        var userRole = user.get("role");
        return userRole !== userRoles.reporter;
      },
      "userpermissions.edit": function (user) {
        return user.get("role") !== userRoles.reporter || true;
      },
      "organization.create": function (user) {
        return user.get("scope") === userScopes.global && user.get("role") === userRoles.admin;
      },
      "organization.edit": function (user) {
        return user.get("type") === userTypes.internal && user.get("role") === userRoles.admin;
      },
      "organization.view": function (user) {
        return user.get("role") === userRoles.manager && user.get("view_organizations") === "1" || user.get("type") === userTypes.others && user.get("role") === userRoles.admin;
      },
      "agency_advertiser.add": function (user) {
        return user.get("role") !== userRoles.reporter;
      },
      "siteLists.edit": function (user) {
        switch (user.get("role")) {
          case userRoles.admin:
            return true;
          case userRoles.manager:
            return parseInt(user.get("edit_campaigns"), 10) === 1;
          case userRoles.reporter:
            return false;
        }
      },
      "pmp_d.video": function () {
        return UserPreferences.get("ui.features.pmpd_video") === "1" || COMPASS_ENV.PMPD_VIDEO_ENABLED;
      },
      "creative_components.admin.edit": function (user) {
        var hasCreativeComponentFlag = UserPreferences.get("ui.features.creative_component_admin") === "1";
        return hasCreativeComponentFlag && user.get("scope") === userScopes.global && user.get("role") === userRoles.admin;
      }
    };
    function checkPermission(action, data) {
      var user = User.getUser();
      if (!action) {
        throw new Error("permission action not provided.");
      }
      if (!permissions.hasOwnProperty(action)) {
        throw new Error('permission "' + action + '" is not defined');
      }
      if (action.indexOf("organization") === 0) {
        return permissions[action](user, data);
      }
      if (user.get("role") === userRoles.admin && user.get("type") !== userTypes.others && action !== "feature") {
        return !(action === "campaign.readonly" || action === "strategy.readonly");
      } else if (action === "feature") {
        var args = arguments ? [].slice.apply(arguments) : [];
        args.shift();
        args.unshift(user);
        return permissions[action].apply(permissions, args);
      }
      return permissions[action](user, data);
    }
    return {
      check: checkPermission
    };
  }
  if (!instance) {
    instance = new Permission();
  }
  return instance;
});
