define('modules/creatives/main',['require','T1Module'],function (require) {
  "use strict";

  const T1Module = require("T1Module");
  var instance;
  if (instance === undefined) {
    instance = new T1Module({
      name: "creatives",
      viewCfgs: {
        tabs: {},
        date_picker: {}
      },
      defaultView: "tabs",
      initialize: function () {}
    });
  }
  return instance;
});
