define('models/strategyTargetValue',["T1", "Backbone", "collections/strategyTargets", "T1Model"], function (T1, Backbone, StrategyTargets, T1Model) {
  T1.Models.StrategyTargetValue = T1Model.extend({
    relations: [{
      type: Backbone.HasMany,
      key: "target_value",
      relatedModel: "StrategyTargetValue",
      collectionType: "StrategyTargets",
      includeInJSON: false
    }]
  });
  return T1.Models.StrategyTargetValue;
});
