define('modules/agency/main',["jQuery", "T1", "T1Module", "collections/agencies", "collections/organizations"], function ($, T1, T1Module, Agencies, Organizations) {
  var collection = new Agencies();
  var orgPromise = Organizations.getOrganizations().getSelected();
  T1.EventHub.subscribe("organization:select, filter:clear", function () {
    collection.reset();
  });
  return new T1Module({
    name: "agency",
    viewCfgs: {
      select: {
        collection: collection
      }
    },
    defaultView: "select",
    initialize: function () {
      orgPromise.then(function (orgId) {
        collection.urlFilter.set({
          entity: "organization",
          id: orgId,
          fetchRequired: false
        });
      });
    }
  });
});
