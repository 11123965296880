define('T1Currencies',["Underscore", "jQuery", "currencies.config"], function (_, $, currenciesConfig) {
  var instance;
  var T1CurrencySupport;
  var defaultCurrencyCode = "USD";
  T1CurrencySupport = function () {
    var defaultFormat = currenciesConfig.defaultFormat;
    var currencies = currenciesConfig.currencies;
    var currencyList = [];
    var key;
    var obj;
    var currenciesRequireDirectionFlowSpan = currenciesConfig.currenciesRequireDirectionFlowSpan || [];
    for (key in currencies) {
      if (currencies.hasOwnProperty(key)) {
        obj = currencies[key];
        obj.format = $.extend(true, {}, defaultFormat, obj.format || {});
        currencyList.push({
          text: obj.name,
          value: key
        });
      }
    }
    currencyList.sort(function (a, b) {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });
    function getCurrencyObjByCode(currCode, defaultCurrencyCode) {
      var currObj = currencies[currCode];
      if (!currObj && defaultCurrencyCode) {
        currObj = currencies[defaultCurrencyCode];
      }
      return currObj;
    }
    function doFormat(numAsStr, decimalSep, groupSep) {
      var parts = numAsStr.toString().split(decimalSep);
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, groupSep);
      return parts.join(decimalSep);
    }
    function doFormatSpecialGroupings(numAsStr, decimalSep, groupSep, groupings) {
      var parts = numAsStr.toString().split(decimalSep);
      var groupIndex = 0;
      var lastGroupIndex = groupings.length - 1;
      var charCnt = 0;
      var resultArr = [];
      var i;
      for (i = parts[0].length - 1; i >= 0; i--) {
        if (groupIndex > lastGroupIndex) {
          groupIndex = lastGroupIndex;
        }
        charCnt++;
        if (charCnt > groupings[groupIndex]) {
          resultArr.unshift(groupSep);
          charCnt = 1;
          groupIndex++;
        }
        resultArr.unshift(parts[0].charAt(i));
      }
      return resultArr.join("") + (parts[1] ? decimalSep + parts[1] : "");
    }
    function formatNumber(currencyCode, number, defaultOutput, decimalCurrency) {
      var num;
      var currObj = getCurrencyObjByCode(currencyCode, defaultCurrencyCode);
      var result;
      var valWithDecimal;
      var specialGroupings = currObj.format.specialGroupings;
      var defaultOut = defaultOutput || "--";
      num = String(number);
      num = parseFloat(num.replace(/\,/g, ""));
      if (isNaN(num) === true) {
        return defaultOut;
      }
      if (decimalCurrency) {
        var splattedNumAry = num.toString().split(".");
        var toFixedAmount;
        var amount;
        if (splattedNumAry.length === 1) {
          valWithDecimal = num.toFixed(2);
        } else {
          amount = splattedNumAry[1];
          if (amount.length < 2) {
            toFixedAmount = 2;
          }
          if (amount.length > 4) {
            toFixedAmount = 4;
          }
          valWithDecimal = num.toFixed(toFixedAmount || amount.length);
        }
      } else {
        valWithDecimal = num.toFixed(currObj.format.decimalCnt);
      }
      valWithDecimal = valWithDecimal.replace(/\./g, currObj.format.decimalSep);
      if (specialGroupings && $.isArray(specialGroupings) === true && specialGroupings.length > 0) {
        result = doFormatSpecialGroupings(valWithDecimal, currObj.format.decimalSep, currObj.format.groupSep, specialGroupings);
      } else {
        result = doFormat(valWithDecimal, currObj.format.decimalSep, currObj.format.groupSep);
      }
      return result;
    }
    function unformatNumber(currencyCode, formattedText) {
      var text = formattedText;
      var currObj = getCurrencyObjByCode(currencyCode, defaultCurrencyCode);
      var result = NaN;
      var regex;
      if (text) {
        regex = new RegExp("\\" + currObj.format.decimalSep, "gi");
        text = text.replace(regex, "|");
        text = text.replace(/[^0-9\|\-]/gi, "");
        result = parseFloat(text.replace(/\|/gi, "."));
      }
      return result;
    }
    function getCurrencySymbol(currencyCode) {
      var currObj = getCurrencyObjByCode(currencyCode, defaultCurrencyCode);
      var result;
      if (currObj) {
        result = currObj.format.symbol;
      } else {
        result = "";
      }
      return result;
    }
    function getCurrencyList(options) {
      var currList = [];
      var selectedId = options && options.selectedId ? options.selectedId : undefined;
      var currItem;
      if (selectedId) {
        selectedId = (selectedId + "").toUpperCase();
      }
      _.each(currencyList, function (item) {
        currItem = {
          text: item.text,
          value: item.value
        };
        if (selectedId) {
          if (item.value.toUpperCase() === selectedId) {
            currItem.selected = true;
          }
        }
        currList.push(currItem);
      });
      return currList;
    }
    function getDecimalSeparator(currencyCode) {
      var currObj = getCurrencyObjByCode(currencyCode, defaultCurrencyCode);
      return currObj.format.decimalSep;
    }
    function getSelectedCurrencyCode(metadataCurrencyType, campaignCurrencyCode) {
      var selectedCurrency = metadataCurrencyType === "usd" ? "USD" : campaignCurrencyCode;
      return selectedCurrency;
    }
    function getCurrencyName(currencyCode) {
      return currencies[currencyCode].name;
    }
    function getCurrencyObj(currencyCode) {
      var currObj = getCurrencyObjByCode(currencyCode, defaultCurrencyCode);
      return currObj;
    }
    return {
      formatNumber: formatNumber,
      unformatNumber: unformatNumber,
      getCurrencySymbol: getCurrencySymbol,
      getCurrencyList: getCurrencyList,
      getCurrencyObj: getCurrencyObj,
      getDecimalSeparator: getDecimalSeparator,
      getCurrenciesRequireDirectionFlowSpan: function () {
        return currenciesRequireDirectionFlowSpan;
      },
      getSelectedCurrencyCode: getSelectedCurrencyCode,
      getCurrencyName: getCurrencyName
    };
  };
  if (!instance) {
    instance = new T1CurrencySupport();
  }
  return instance;
});
