define('modules/reporting/campaigns/views/no_data_chart',["jQuery", "T1", "T1View", "text!../templates/no_data_chart.html"], function ($, T1, T1View, template) {
  "use strict";

  const MAX_COLUMNS = 10;
  const MAX_ROWS = 3;
  return T1View.extend({
    template: template,
    initialize: function (args) {
      this.msg = args.msg;
    },
    serialize: function () {
      var vcells = [];
      var hcells = [];
      var bars = [];
      var i;
      for (i = 0; i < MAX_COLUMNS; i++) {
        vcells.push({});
      }
      for (i = 0; i < MAX_ROWS; i++) {
        hcells.push({});
        bars.push({
          "bar-id": i
        });
      }
      return {
        bars: bars,
        hcells: hcells,
        msg: this.msg,
        vcells: vcells
      };
    }
  });
});
