define('modules/reporting/campaigns/dataExport/models/dataExport',['require','jQuery','Underscore','../../models/metricCollections','collections/organizations','./reportDefaults','T1','T1Comm','T1Model','T1Permissions','modules/reporting/campaigns/utils/reportingUtils'],function (require) {
  "use strict";

  const $ = require("jQuery");
  const _ = require("Underscore");
  const MetricCollections = require("../../models/metricCollections");
  const Organizations = require("collections/organizations");
  const ReportDefaults = require("./reportDefaults");
  const T1 = require("T1");
  const T1Comm = require("T1Comm");
  const T1Model = require("T1Model");
  const T1Permissions = require("T1Permissions");
  const Utils = require("modules/reporting/campaigns/utils/reportingUtils");
  const T1Capitalize = T1.Utils.capitalize;
  const allowedChars = /^[\u00BF-\u1FFF\u2C00-\uD7FF\w. ]+$/;
  const apiTimeoutLimit = 3e5;
  const dateFormat = "yyyy-MM-dd";
  const defaultAttribution = "MediaMath Last Touch";
  const defaultFileType = "csv";
  const defaultReport = "Performance";
  const displayFormat = "MMM dd, yyyy";
  const gridIconColorDeselected = "#c3c3c3";
  const gridIconColorDeselectedOver = "#525252";
  const gridIconColorSelected = "#86c633";
  const twelveHrs = 12;
  const frequencyMap = Object.freeze(new Map([["-1", "Last"], ["1", "1st"], ["2", "2nd"], ["3", "3rd"], ["21", "21st"], ["22", "22nd"], ["23", "23rd"]]));
  const rollupMap = Object.freeze(new Map([["all", "Total"], ["various", "Various"]]));
  const additionalFilterTypes = Object.freeze(["exchange"]);
  const reportsWithClickMetrics = ["all_dimensions_and_metrics", "all_dimensions_and_metrics_ecosystem_cost", "app_transparency", "by_hour", "contextual_insights", "day_part", "device_technology", "geo", "hyperlocal", "performance", "performance_ecosystem_cost", "postal_code", "reach_frequency", "site_transparency", "site_transparency_viewability"];
  return T1Model.extend({
    canCache: true,
    cacheDuration: 18e5,
    url() {
      const orgID = this.getOrgInfo().id;
      const fetchName = this.getReportItem().fetchName;
      const orgParam = `temp_organization_id=${orgID}`;
      const versionParam = `v${this.getReportDefinitionLatestVersion(fetchName)}=`;
      const isSTViewability = fetchName === "site_transparency_viewability";
      let metaDataUrl = `${T1.RPT_API_ROOT}std/${fetchName}/meta?`;
      switch (true) {
        case reportsWithClickMetrics.includes(fetchName):
          metaDataUrl += isSTViewability ? `${orgParam}&v2=` : `${orgParam}&${versionParam}`;
          break;
        default:
          metaDataUrl += versionParam;
      }
      return metaDataUrl;
    },
    fixture: "",
    dataType: "json",
    action: "read",
    initialize(options) {
      T1Model.prototype.initialize.call(this, options);
      this.hasViewabilityFlag = T1Permissions.check("feature", "reporting_viewability");
      this.hasAudienceVerificationFlag = T1Permissions.check("feature", "reporting_data_export_audience_verification");
      this.hasContextualInsightsFlag = T1Permissions.check("feature", "reports_dataExport_contextualInsights");
      this.hasContextualDiscoveryFlag = T1Permissions.check("feature", "reports_dataExport_contextualDiscovery");
      this.hasEcoCostFlag = T1Permissions.check("feature", "reporting_ecosystem_cost");
      this.hasAllDimensionsAndMetricsFlag = T1Permissions.check("feature", "reporting_all_dimensions_and_metrics");
      this.setReportList();
    },
    getOrgInfo() {
      const orgs = Organizations.getOrganizations();
      const orgID = orgs.selected;
      return {
        id: orgID,
        name: orgs.get(orgID).get("name")
      };
    },
    setReportsTransition(reports) {
      const reportTransitions = {};
      for (const [reportName, reportProps] of Object.entries(reports)) {
        const transitionObj = reportProps.Transition;
        reportTransitions[reportName] = {
          inTransition: Boolean(transitionObj),
          nextVersion: transitionObj && transitionObj.next_version,
          version: reportProps.Version || 1
        };
      }
      if (this.hasViewabilityFlag) {
        reportTransitions.site_transparency_viewability = {
          inTransition: reportTransitions.site_transparency.inTransition,
          nextVersion: reportTransitions.site_transparency.nextVersion,
          version: reportTransitions.site_transparency.version
        };
      }
      if (this.hasEcoCostFlag) {
        reportTransitions.performance_ecosystem_cost = {
          inTransition: reportTransitions.performance.inTransition,
          nextVersion: reportTransitions.performance.nextVersion,
          version: reportTransitions.performance.version
        };
      }
      if (this.hasAllDimensionsAndMetricsFlag) {
        reportTransitions.all_dimensions_and_metrics = {
          inTransition: reportTransitions.performance.inTransition,
          nextVersion: reportTransitions.performance.nextVersion,
          version: reportTransitions.performance.version
        };
      }
      if (this.hasAllDimensionsAndMetricsFlag && this.hasEcoCostFlag) {
        reportTransitions.all_dimensions_and_metrics_ecosystem_cost = {
          inTransition: reportTransitions.performance.inTransition,
          nextVersion: reportTransitions.performance.nextVersion,
          version: reportTransitions.performance.version
        };
      }
      this.set({
        reportTransitions: reportTransitions
      });
    },
    fetchMetadata() {
      const sourceURL = this.url();
      this.loaded = false;
      this.abort();
      this.metadataCall = T1Comm.get({
        sourceURL: sourceURL,
        dataType: "json",
        onSuccess: ({
          structure: structure,
          time_windows: time_windows,
          time_aggregation: time_aggregation,
          time_rollups: time_rollups
        }) => {
          this.loaded = true;
          if (Utils.hideCostsAndFees) {
            structure.metrics = _.omit(structure.metrics, MetricCollections.fees);
          }
          this.setMetadata(structure, time_windows, time_aggregation, time_rollups);
        },
        processAjaxResponse(response) {
          return {
            jsonData: response,
            statusCode: "ok"
          };
        }
      });
      if (this.metadataCall) {
        this.metadataCall.always(() => {
          this.metadataCall = null;
        });
      }
    },
    abort() {
      if (this.metadataCall) {
        this.metadataCall.abort();
      }
    },
    getExportData() {
      const dateBasedType = this.getDateBasedType();
      const isCTD = dateBasedType === "ctd";
      const isFTD = dateBasedType === "ftd";
      const isCTDorFTD = isCTD || isFTD;
      const fetchName = this.getReportItem().fetchName;
      const timeAggregation = this.get("reportInfo").timeAggregation;
      const ctdFtdMap = new Map([[isCTD, "campaign_to_date"], [isFTD, "flight_to_date"]]);
      const timeWindow = ctdFtdMap.get(true) || this.getTimeWindowExport();
      return {
        dateBased: isCTDorFTD ? false : dateBasedType !== "rolling" && timeAggregation !== "various",
        dimensions: this.getSelectedDimensions(),
        endDate: this.getEndDate(),
        fetchName: fetchName,
        fileName: this.get("fileName"),
        fileType: this.getSelectedFileType(),
        filter: this.getFilterSelection(),
        metrics: this.getSelectedMetrics(),
        startDate: isCTDorFTD ? null : this.getStartDate(),
        timeRollup: isCTDorFTD || timeAggregation === "various" ? "all" : this.get("timeRollup"),
        timeWindow: fetchName === "by_hour" ? `${timeWindow}_inclusive` : timeWindow
      };
    },
    getSaveData(action, id, emailFormChanged) {
      let saveData;
      if (action === "saveStatus") {
        const savedReports = this.get("savedReports");
        const savedReport = savedReports.find(report => report.id === id);
        saveData = {
          active: savedReport.active,
          definition: savedReport.definition,
          delivery: savedReport.delivery,
          name: savedReport.report,
          report: savedReport.fetchName,
          schedule: savedReport.schedule,
          version: savedReport.version
        };
      } else {
        let time;
        const editReport = this.get("editReport");
        const exportData = this.getExportData();
        if (emailFormChanged) {
          const getEmailTime = function () {
            const emailTime = this.getEmailTime();
            const emailAMPM = this.getEmailAMPM();
            switch (true) {
              case emailAMPM === "PM" && emailTime !== "12:00":
                time = `${parseInt(emailTime, 10) + twelveHrs}:00`;
                break;
              case emailAMPM === "AM" && emailTime === "12:00":
                time = "00:00";
                break;
              default:
                time = emailTime;
            }
            return time;
          };
          saveData = {
            active: editReport ? editReport.active : true,
            name: exportData.fileName,
            report: exportData.fetchName,
            version: editReport ? editReport.version : 0,
            definition: {
              dimensions: exportData.dimensions,
              filter: exportData.filter,
              format: exportData.fileType,
              metrics: exportData.metrics,
              time_rollup: exportData.timeRollup
            },
            schedule: {
              end_date: this.getEmailStartEndDate().end,
              frequency_grain: this.getEmailFrequencyGrain(),
              frequency_value: this.getEmailFrequencyValue(),
              start_date: this.getEmailStartEndDate().start,
              time_of_day: getEmailTime.call(this),
              zone_name: this.get("emailTimezone")
            },
            delivery: {
              email_body: this.get("emailMessage"),
              email_reply_to: this.get("emailFromAddress"),
              email_subject: this.get("emailSubject"),
              email_to: this.get("emailToAddresses")
            }
          };
        } else {
          saveData = {
            active: editReport ? editReport.active : true,
            name: exportData.fileName,
            report: exportData.fetchName,
            version: editReport ? editReport.version : 0,
            definition: {
              dimensions: exportData.dimensions,
              filter: exportData.filter,
              format: exportData.fileType,
              metrics: exportData.metrics,
              time_rollup: exportData.timeRollup
            }
          };
          if (editReport && editReport.schedule) {
            saveData.schedule = editReport.schedule;
            saveData.delivery = editReport.delivery;
          }
        }
        if (exportData.dateBased) {
          saveData.definition.start_date = exportData.startDate;
          if (exportData.endDate) {
            saveData.definition.end_date = exportData.endDate;
          }
        } else {
          saveData.definition.time_window = exportData.timeWindow;
        }
        saveData.definition[`v${this.getReportDefinitionLatestVersion(exportData.fetchName)}`] = "";
      }
      return saveData;
    },
    getExportURL(reportID, downloadToken) {
      let url;
      if (reportID) {
        const savedReports = this.get("savedReports");
        const savedReport = savedReports.find(report => report.id === reportID);
        const definition = savedReport.definition;
        const fetchName = savedReport.fetchName;
        const fileName = encodeURIComponent(savedReport.report);
        const fileType = savedReport.definition.format;
        const filter = encodeURIComponent(definition.filter);
        const dimensions = encodeURIComponent(definition.dimensions);
        const metrics = encodeURIComponent(definition.metrics);
        url = `${T1.RPT_API_ROOT}std/${fetchName}/download/de?download_token=${downloadToken}` + `&filename=${fileName}.${fileType}&format=${fileType}&filter=${filter}` + `&dimensions=${dimensions}&metrics=${metrics}`;
        if (definition.time_window) {
          url += `&time_window=${definition.time_window}`;
        } else {
          if (definition.end_date) {
            url += `&start_date=${definition.start_date}&end_date=${definition.end_date}`;
          } else {
            url += `&start_date=${definition.start_date}`;
          }
        }
        url += `&time_rollup=${definition.time_rollup}`;
        url += `&v${this.getReportDefinitionVersion(savedReport)}=`;
      } else {
        const exportData = this.getExportData();
        const fetchName = exportData.fetchName;
        const fileType = exportData.fileType;
        const fileName = encodeURIComponent(exportData.fileName);
        const filter = encodeURIComponent(exportData.filter);
        const dimensions = encodeURIComponent(exportData.dimensions);
        const metrics = encodeURIComponent(exportData.metrics);
        url = `${T1.RPT_API_ROOT}std/${fetchName}/download/de?download_token=${downloadToken}` + `&filename=${fileName}.${fileType}&format=${fileType}&filter=${filter}` + `&dimensions=${dimensions}&metrics=${metrics}`;
        if (exportData.dateBased) {
          if (exportData.endDate) {
            url += `&start_date=${exportData.startDate}&end_date=${exportData.endDate}`;
          } else {
            url += `&start_date=${exportData.startDate}`;
          }
        } else {
          url += `&time_window=${exportData.timeWindow}`;
        }
        url += `&time_rollup=${exportData.timeRollup}`;
        url += `&v${this.getReportDefinitionLatestVersion(fetchName)}=`;
      }
      return url;
    },
    getApiTimeoutLimit() {
      return apiTimeoutLimit;
    },
    getReportDisplayName(fetchName) {
      const reports = this.getReports();
      return reports.find(report => report.fetchName === fetchName).label;
    },
    getReportDisplayDateRange(definition) {
      let dateRange;
      if (definition.time_window) {
        dateRange = T1Capitalize(definition.time_window.replace("_inclusive", "").replace(/_/g, " "));
      } else {
        const startDate = Date.parse(definition.start_date);
        if (definition.end_date) {
          const endDate = Date.parse(definition.end_date);
          dateRange = `${startDate.toString(displayFormat)} - ${endDate.toString(displayFormat)}`;
        } else {
          dateRange = `${startDate.toString(displayFormat)} - Last Update`;
        }
      }
      return dateRange;
    },
    getReportDefinitionVersion(reportItem) {
      const reportDefinitionVersion = Object.keys(reportItem.definition).find(item => item.charAt(0) === "v");
      return reportDefinitionVersion ? Number(reportDefinitionVersion.substring(1)) : 1;
    },
    getReportDefinitionLatestVersion(fetchName) {
      const transitionObj = this.get("reportTransitions")[fetchName];
      if (!transitionObj) {
        throw Error(`Report ${fetchName} not available in meta-call, check whether it's the right environment.`);
      }
      return transitionObj.inTransition ? transitionObj.nextVersion : transitionObj.version;
    },
    setSavedReports(collection, downloadTokens) {
      let block = false;
      let savedReports = [];
      const orgID = this.getOrgInfo().id;
      const transitionList = this.get("reportTransitions");
      const reports = this.getReports();
      function isReportSupported(reportItem) {
        return reports.find(report => report.fetchName === reportItem.report);
      }
      function doesReportBelongToOrg({
        organization_id: orgIDList
      }) {
        return orgIDList.some(item => item === orgID);
      }
      function extractAttribution(reportItem) {
        let attribution = "All Attribution Groups";
        const filter = reportItem.definition.filter;
        if (filter && filter.includes("attribution_group")) {
          attribution = _.last(filter.split("&")).replace("attribution_group=", "");
        }
        return attribution;
      }
      function getReportMeta({
        report: report
      }) {
        if (!transitionList[report]) {
          throw new Error(`${report} likely not available in meta-call on current environment.`);
        }
        const {
          inTransition: inTransition,
          version: metaVersion,
          nextVersion: nextVersion
        } = transitionList[report];
        return {
          inTransition: inTransition,
          metaVersion: metaVersion,
          nextVersion: nextVersion
        };
      }
      function isInTransition(reportItem) {
        const reportMeta = getReportMeta(reportItem);
        if (reportMeta.inTransition) {
          return reportItem.definition[`v${reportMeta.nextVersion}`] !== "";
        }
        return false;
      }
      function hasBeenDeprecated(reportItem) {
        const reportMeta = getReportMeta(reportItem);
        if (reportMeta.inTransition) {
          return false;
        }
        return this.getReportDefinitionVersion(reportItem) < reportMeta.metaVersion;
      }
      if (collection.models.length) {
        const now = new Date().getTime();
        for (const model of collection.models) {
          let downloadReady = true;
          let inProgress = false;
          let timeout = false;
          const item = model.toJSON();
          const id = model.id;
          if (!isReportSupported.call(this, item)) {
            continue;
          }
          if (!doesReportBelongToOrg(item)) {
            continue;
          }
          if (hasBeenDeprecated.call(this, item)) {
            continue;
          }
          if (!_.isEmpty(downloadTokens)) {
            const token = downloadTokens[id];
            if (token) {
              switch (token.state) {
                case "download":
                  downloadReady = true;
                  inProgress = false;
                  timeout = false;
                  break;
                case "progress":
                  if (now < token.timestamp + apiTimeoutLimit) {
                    downloadReady = false;
                    inProgress = true;
                    block = true;
                  } else {
                    downloadReady = false;
                    inProgress = false;
                    timeout = true;
                    block = false;
                  }
                  break;
                case "timeout":
                  downloadReady = false;
                  inProgress = false;
                  timeout = true;
                  break;
              }
            }
          }
          savedReports.push({
            actions: true,
            active: item.active,
            attribution: extractAttribution(item),
            blockConcurrentDownloads: "",
            createBy: item.created_by,
            dateRange: this.getReportDisplayDateRange(item.definition),
            definition: item.definition,
            delivery: item.delivery,
            download: downloadReady,
            enableEdit: !isInTransition(item),
            fetchName: item.report,
            id: id,
            inactiveReport: item.active ? "" : "inactive-report",
            progress: inProgress,
            refresh: false,
            report: item.name,
            schedule: item.schedule,
            statusAction: item.active ? "Deactivate" : "Activate",
            timeout: timeout,
            type: this.getReportDisplayName(item.report),
            version: item.version
          });
        }
      }
      if (!collection.models.length || !savedReports.length) {
        savedReports = this.createEmptyReportsList();
      }
      this.set({
        savedReports: savedReports
      }, {
        silent: true
      });
      this.setBlockConcurrentDownloads(!block);
      this.trigger("change:savedReports");
    },
    createEmptyReportsList() {
      const numOfEmptyRows = 3;
      const emptyList = [...Array(numOfEmptyRows).keys()];
      return emptyList.map(idx => ({
        actions: false,
        active: "",
        attribution: "",
        blockConcurrentDownloads: "",
        createBy: "",
        dateRange: "",
        definition: "",
        delivery: "",
        download: false,
        empty: "empty-grid",
        enableEdit: false,
        fetchName: "",
        id: "",
        inactiveReport: "inactive-report",
        progress: false,
        refresh: false,
        report: "",
        schedule: "",
        statusAction: "",
        timeout: false,
        type: idx === 1 ? "No saved reports available" : "",
        version: ""
      }));
    },
    setSavedReportStatus(reportID, state) {
      const savedReports = this.get("savedReports");
      const savedReport = savedReports.find(report => report.id === reportID);
      if (savedReport) {
        savedReport.download = state === "download";
        savedReport.progress = state === "progress";
        savedReport.timeout = state === "timeout";
      }
      if (reportID && state === "progress") {
        window.localStorage.setItem("reportDownloadProgress", reportID);
      } else if (state === "download" || state === "timeout") {
        window.localStorage.removeItem("reportDownloadProgress");
      }
      this.setBlockConcurrentDownloads(state !== "progress");
      this.trigger("change:savedReports");
    },
    setReportStatus(id, status) {
      const savedReports = this.get("savedReports");
      const activateReport = status === "Activate";
      const savedReport = savedReports.find(report => report.id === id);
      savedReport.active = activateReport;
      savedReport.statusAction = activateReport ? "Deactivate" : "Activate";
      savedReport.inactiveReport = savedReport.active ? "" : "inactive-report";
      this.set({
        savedReports: savedReports
      }, {
        silent: true
      });
      this.trigger("update:reportStatus", id);
    },
    updateSavedReportsList() {
      let savedReports = this.get("savedReports");
      switch (this.get("manageGridStatus")) {
        case "Active":
          savedReports = savedReports.filter(report => report.active);
          break;
        case "Inactive":
          savedReports = savedReports.filter(report => !report.active);
          break;
      }
      if (!savedReports.length) {
        savedReports = this.createEmptyReportsList();
      }
      this.set({
        savedReports: savedReports
      }, {
        silent: true
      });
      this.trigger("change:savedReports");
    },
    setBlockConcurrentDownloads(unblock) {
      const savedReports = this.get("savedReports");
      const reportDownloadProgress = window.localStorage.getItem("reportDownloadProgress");
      for (const report of savedReports) {
        if (reportDownloadProgress) {
          report.blockConcurrentDownloads = "blockConcurrentDownloads";
        } else {
          report.blockConcurrentDownloads = unblock ? "" : "blockConcurrentDownloads";
        }
      }
      this.trigger("toggle:btnAddReport", unblock);
    },
    setManageGridStatus(status) {
      this.set({
        manageGridStatus: status
      });
    },
    setEditReport(report) {
      const {
        definition: definition,
        delivery: delivery,
        schedule: schedule
      } = report;
      this.set({
        editReport: report
      });
      this.setFileName(report.report, true);
      this.setSelectedFileType({
        fileType: definition.format,
        silent: true
      });
      this.setSelectedReport(report.type, true);
      if (report.type === "Performance" || report.type === "Performance Ecosystem Cost") {
        this.setSelectedAttribution(report.attribution, true);
      }
      if (definition.time_window) {
        if (this.getReportItem().isIntervalBased) {
          this.setTimeWindow(definition.time_window, true);
        } else {
          if (definition.time_window === "campaign_to_date") {
            this.setDateBasedType("ctd");
          } else if (definition.time_window === "flight_to_date") {
            this.setDateBasedType("ftd");
          } else {
            switch (definition.time_window) {
              case "month_to_date":
                definition.time_window = `last_${moment().date()}_days`;
                break;
              case "yesterday":
                definition.time_window = `last_1_days`;
                break;
              default:
                if (!definition.time_window.includes("last_")) {
                  definition.time_window = `last_7_days`;
                }
                break;
            }
            const timeWindowUnit = definition.time_window.includes("hours") ? "hours" : "days";
            const timeWindow = report.type === "By Hour" ? definition.time_window.replace("_inclusive", "") : definition.time_window;
            this.setTimeWindow(timeWindow, true);
            this.setTimeWindowUnit(timeWindowUnit);
            this.setDateBasedType("rolling");
          }
        }
      } else {
        if (definition.end_date) {
          this.setCalendarRange(definition.start_date, definition.end_date);
          this.setDateBasedType("fixed");
        } else {
          this.setCalendarRange(definition.start_date, null);
          this.setDateBasedType("ongoing");
        }
      }
      this.setTimeRollup(definition.time_rollup);
      if (schedule) {
        let frequencyDisplayValue;
        const frequencyValue = schedule.frequency_value;
        const time = Number(schedule.time_of_day.replace(":00", ""));
        this.setEmailFrequencyGrain(schedule.frequency_grain);
        switch (schedule.frequency_grain) {
          case "weekly":
            this.setEmailWeeklyValue(frequencyValue);
            break;
          case "monthly":
            frequencyDisplayValue = frequencyMap.get(frequencyValue) || `${frequencyValue}th`;
            this.setEmailMonthlyValue({
              id: frequencyValue,
              value: frequencyDisplayValue
            });
            break;
        }
        this.setEmailStartEndDate({
          start: schedule.start_date,
          end: schedule.end_date
        });
        if (time === 0) {
          this.setEmailTime("12:00");
          this.setEmailAMPM("AM");
        } else if (time === twelveHrs) {
          this.setEmailTime(`${time}:00`);
          this.setEmailAMPM("PM");
        } else if (time > twelveHrs) {
          this.setEmailTime(`${time - twelveHrs}:00`);
          this.setEmailAMPM("PM");
        } else {
          this.setEmailTime(`${time}:00`);
          this.setEmailAMPM("AM");
        }
        this.setEmailTimezone(schedule.zone_name);
      }
      if (delivery) {
        this.setEmailToAddresses(delivery.email_to);
        this.setEmailSubject(delivery.email_subject);
        this.setEmailMessage(delivery.email_body);
      }
    },
    setEditReportPart2(report) {
      const binChangeItems = {
        add: [],
        remove: []
      };
      const entityData = report.entityData;
      const strReverseFn = T1.Utils.reverseString;
      const agencyLevel = 0;
      const advertiserLevel = 1;
      const campaignLevel = 2;
      const selectedReport = this.getSelectedReport();
      const additionalFilters = this.getAdditionalFilterTypes();
      const levelMap = new Map([["agency", agencyLevel], ["advertiser", advertiserLevel], ["campaign", campaignLevel], ["pixel", selectedReport === "Data Pixel Loads" ? advertiserLevel : campaignLevel]]);
      function parseEntityData(model, entity) {
        let groupName, itemParent, itemTopParent, name, parentID, parentName, path, title, topParentID;
        const item = model.toJSON();
        switch (entity) {
          case "agency":
            name = item.name;
            parentName = name;
            parentID = item.id;
            groupName = parentName;
            topParentID = parentID;
            path = "";
            title = parentName;
            break;
          case "advertiser":
            itemParent = item.agency;
            name = item.name;
            parentName = itemParent.name;
            parentID = itemParent.id;
            groupName = parentName;
            topParentID = parentID;
            path = "";
            title = `${parentName} » ${name}`;
            break;
          case "campaign":
          case "pixel":
            if (selectedReport === "Data Pixel Loads") {
              itemParent = item.agency;
              name = item.name;
              parentName = itemParent.name;
              parentID = itemParent.id;
              groupName = parentName;
              topParentID = parentID;
              path = "";
              title = `${parentName} » ${name}`;
            } else {
              itemParent = item.advertiser;
              itemTopParent = itemParent.agency;
              name = item.name;
              parentName = itemParent.name;
              parentID = itemParent.id;
              groupName = itemTopParent.name;
              topParentID = itemTopParent.id;
              path = `${parentName} « `;
              title = `${groupName} » ${parentName} » ${name}`;
            }
            break;
        }
        return {
          groupName: groupName,
          name: name,
          parentID: parentID,
          parentName: parentName,
          path: path,
          title: title,
          topParentID: topParentID
        };
      }
      if (entityData && entityData.pixel && selectedReport !== "Data Pixel Loads") {
        const advIDs = _.pluck(entityData.advertiser, "id");
        for (const model of entityData.pixel) {
          const advID = model.attributes.advertiser.id;
          if (advIDs.includes(advID)) {
            entityData.advertiser = entityData.advertiser.filter(item => item.id !== advID);
          }
        }
        if (!entityData.advertiser.length) {
          delete entityData.advertiser;
        }
      }
      for (const [entity, dataSet] of Object.entries(entityData)) {
        if (entity === "organization") {
          const {
            name: orgName,
            id: orgID
          } = dataSet.attributes;
          const binItem = {
            groupName: orgName,
            hasChildren: "",
            iconColor: "",
            iconState: "",
            iconType: "",
            id: orgID,
            isExpanded: "",
            level: "",
            name: orgName,
            parentID: orgID,
            parentName: orgName,
            path: "",
            showIncludeIcon: "",
            title: orgName,
            topParentID: orgID,
            type: "organization"
          };
          binChangeItems.add.push(binItem);
        } else if (additionalFilters.includes(entity)) {} else {
          for (const model of dataSet) {
            const {
              groupName: groupName,
              name: name,
              parentID: parentID,
              parentName: parentName,
              path: path,
              title: title,
              topParentID: topParentID
            } = parseEntityData(model, entity);
            const binItem = {
              groupName: groupName,
              hasChildren: "none",
              iconColor: gridIconColorSelected,
              iconState: "selected",
              iconType: "include",
              id: model.id,
              isExpanded: "",
              level: levelMap.get(entity),
              name: name,
              parentID: parentID,
              parentName: parentName,
              path: strReverseFn(path),
              pathReversed: true,
              showIncludeIcon: "block",
              title: title,
              topParentID: topParentID,
              type: entity
            };
            binChangeItems.add.push(binItem);
          }
        }
      }
      this.setBinChangeItems(binChangeItems);
    },
    setMetadata(structure, timeWindows, timeAggregation, timeRollups) {
      let timeRollupNames, timeWindowNames;
      const selectedReport = this.getSelectedReport();
      const reportItem = this.getReportItem();
      const editReport = this.get("editReport");
      const timeRollup = editReport ? editReport.definition.time_rollup : timeAggregation;
      function removeViewabilityMetrics() {
        structure.metrics = _.omit(structure.metrics, MetricCollections.viewability);
      }
      function addAttributionToDefaults() {
        if (!reportItem.defaultDimensions.includes("attribution_group")) {
          reportItem.defaultDimensions.unshift("attribution_group");
        }
      }
      switch (selectedReport) {
        case "Audience Index Pixel":
          delete structure.dimensions.pixel_external_id;
          break;
        case "Performance":
        case "Performance Ecosystem Cost":
          addAttributionToDefaults();
          break;
        case "Technology":
          if (!this.hasViewabilityFlag) {
            removeViewabilityMetrics();
          }
          delete structure.dimensions.connection_type;
          break;
      }
      const defaultDimensions = editReport && editReport.definition.dimensions ? editReport.definition.dimensions.split(",") : reportItem.defaultDimensions;
      const defaultMetrics = editReport && editReport.definition.metrics ? editReport.definition.metrics.split(",") : reportItem.defaultMetrics;
      const dimensions = this.parseSelectorItems(structure.dimensions, defaultDimensions);
      const metrics = this.parseSelectorItems(structure.metrics, defaultMetrics);
      if (timeWindows) {
        timeWindowNames = timeWindows.map(timeWindow => ({
          timeWindow: T1Capitalize(timeWindow.replace(/_/g, " "))
        }));
      }
      if (timeRollups) {
        timeRollupNames = timeRollups.map(rollup => {
          if (rollup === "all") {
            return {
              timeRollup: "Total"
            };
          }
          return {
            timeRollup: this.getTimeRollupDisplayName(rollup)
          };
        });
      }
      this.set({
        timeRollup: timeRollup
      });
      this.set({
        reportInfo: {
          availableDimensions: dimensions.availableItems,
          availableMetrics: metrics.availableItems,
          selectedDimensions: dimensions.selectedItems,
          selectedMetrics: metrics.selectedItems,
          structure: structure,
          timeAggregation: timeAggregation,
          timeAggregationName: this.getTimeRollupDisplayName(timeAggregation),
          timeRollupNames: timeRollupNames,
          timeRollups: timeRollups,
          timeWindowIDs: timeWindows,
          timeWindowNames: timeWindowNames
        }
      }, {
        silent: true
      });
      this.trigger("change:reportInfo", this);
    },
    setFileName(fileName, silent) {
      this.set({
        fileName: fileName
      }, {
        silent: silent
      });
    },
    getFirstCharVerified(fileName) {
      return fileName.charAt(0) !== "." && fileName.charAt(0) !== "_" && fileName.charAt(0) !== " ";
    },
    getAllowedCharsVerified(fileName) {
      return allowedChars.test(fileName);
    },
    getFileNameVerified() {
      const fileName = this.get("fileName");
      if (fileName) {
        return this.getFirstCharVerified(fileName) && this.getAllowedCharsVerified(fileName);
      }
      return false;
    },
    getSelectedFileType() {
      return this.get("fileType") || defaultFileType;
    },
    setSelectedFileType({
      fileType = defaultFileType,
      silent: silent
    }) {
      this.set({
        fileType: fileType
      }, {
        silent: silent
      });
    },
    setReportList() {
      let reportList = ReportDefaults;
      const reportFeatureFlagMapping = {
        contextualDiscoveryV1: [this.hasContextualDiscoveryFlag],
        contextualInsightsV1: [this.hasContextualInsightsFlag],
        audienceVerificationV1: [this.hasAudienceVerificationFlag],
        siteTransparencyViewabilityV2: [this.hasViewabilityFlag],
        performanceEcoCostV1: [this.hasEcoCostFlag],
        allDimensionsAndMetricsV1: [this.hasAllDimensionsAndMetricsFlag],
        allDimensionsAndMetricsEcosystemV1: [this.hasEcoCostFlag, this.hasAllDimensionsAndMetricsFlag]
      };
      reportList = reportList.filter(report => !(reportFeatureFlagMapping[report.tag] && reportFeatureFlagMapping[report.tag].includes(false)));
      this.set({
        reportList: reportList
      });
    },
    setSelectedReport(reportName, silent) {
      this.set({
        selectedReport: reportName
      }, {
        silent: silent
      });
      this.set({
        prevSelectedReport: reportName
      }, {
        silent: silent
      });
    },
    getSelectedReport() {
      return this.get("selectedReport") || defaultReport;
    },
    getReports() {
      return this.get("reportList");
    },
    getReportItem() {
      const reports = this.getReports();
      const selectedReport = this.getSelectedReport();
      return reports.find(item => item.label === selectedReport);
    },
    getSelectedAttribution() {
      return this.get("selectedAttribution") || defaultAttribution;
    },
    setSelectedAttribution(attribution, silent) {
      const reportInfo = this.get("reportInfo");
      this.set({
        selectedAttribution: attribution
      }, {
        silent: silent
      });
      if (reportInfo && Object.keys(reportInfo).length) {
        const selectedMetrics = reportInfo.selectedMetrics.map(item => item.value);
        this.updateSelectorItems("metrics", selectedMetrics);
        this.trigger("change:attribution");
      }
    },
    setDefaultDate(startDate, endDate) {
      this.set({
        defaultEndDate: endDate,
        defaultStartDate: startDate
      });
    },
    setCalendarRange(startDate, endDate) {
      const selectedReport = this.getSelectedReport();
      const calendarRange = Object.assign({}, this.get("calendarRange"));
      calendarRange[selectedReport] = {
        startDate: startDate,
        endDate: endDate
      };
      this.set({
        calendarRange: calendarRange
      });
    },
    resetCalendarRange() {
      this.set({
        calendarRange: null
      });
    },
    getStartDate() {
      const selectedReport = this.getSelectedReport();
      const calendarRange = this.get("calendarRange");
      const reportRange = calendarRange && calendarRange[selectedReport];
      return reportRange && reportRange.startDate || this.get("defaultStartDate");
    },
    getEndDate() {
      const selectedReport = this.getSelectedReport();
      const calendarRange = this.get("calendarRange");
      const reportRange = calendarRange && calendarRange[selectedReport];
      return reportRange && reportRange.endDate || null;
    },
    getDateFormat() {
      return dateFormat;
    },
    getDisplayFormat() {
      return displayFormat;
    },
    setDateBasedType(type) {
      this.set({
        dateBasedType: type
      });
    },
    getDateBasedType() {
      return this.get("dateBasedType") || "rolling";
    },
    setTimeWindow(timeRange, silent) {
      const selectedReport = this.getSelectedReport();
      const timeWindow = Object.assign({}, this.get("timeWindow"));
      timeWindow[selectedReport] = timeRange.toLowerCase().replace(/ /g, "_");
      this.set({
        timeWindow: timeWindow
      }, {
        silent: silent
      });
    },
    getTimeWindow() {
      const selectedReport = this.getSelectedReport();
      const timeWindowObj = this.get("timeWindow");
      let timeWindow;
      if (timeWindowObj && timeWindowObj[selectedReport]) {
        timeWindow = timeWindowObj[selectedReport];
      } else {
        timeWindow = this.getReportItem().defaultTimeWindow;
      }
      return T1Capitalize(timeWindow.replace(/_/g, " "));
    },
    getTimeWindowExport() {
      const selectedReport = this.getSelectedReport();
      const timeWindowObj = this.get("timeWindow");
      return timeWindowObj && timeWindowObj[selectedReport] || this.getReportItem().defaultTimeWindow;
    },
    setTimeWindowUnit(timeWindowUnit) {
      this.set({
        timeWindowUnit: timeWindowUnit.toLowerCase()
      });
    },
    getTimeWindowUnit() {
      const timeWindowUnit = this.get("timeWindowUnit") || "Days";
      return T1Capitalize(timeWindowUnit);
    },
    setTimeRollup(rollup) {
      const timeRollup = rollup === "Total" ? "all" : rollup.toLowerCase().replace(/ /g, "_");
      this.set({
        timeRollup: timeRollup
      });
    },
    getTimeRollup() {
      const reportInfo = this.get("reportInfo");
      const timeRollup = this.get("timeRollup");
      let rollup = "";
      if (timeRollup) {
        rollup = this.getTimeRollupDisplayName(timeRollup);
      } else if (reportInfo) {
        rollup = reportInfo.timeAggregationName;
      }
      return rollup;
    },
    getTimeRollupDisplayName(rollup) {
      const [wordBy, wordRollup] = rollup.split("_");
      return rollupMap.get(rollup) || `${wordBy} ${T1Capitalize(wordRollup)}`;
    },
    getGridIconColors() {
      return {
        deselected: gridIconColorDeselected,
        deselectedOver: gridIconColorDeselectedOver,
        selected: gridIconColorSelected
      };
    },
    setBinItems(items) {
      this.set({
        binItems: items
      });
    },
    getBinItems() {
      return this.get("binItems") || [];
    },
    setBinChangeItems(items) {
      this.set({
        binChangeItems: items
      });
    },
    getBinChangeItems() {
      return this.get("binChangeItems") || [];
    },
    getAdditionalFilterTypes() {
      return additionalFilterTypes;
    },
    getDimensionsVerified() {
      return Boolean(this.get("reportInfo").selectedDimensions.length);
    },
    getFilterVerified() {
      const binItems = this.getBinItems();
      const nonGroupItems = binItems.filter(item => item.groupClass !== "group-row");
      const nonAdditionalFilterItems = nonGroupItems.filter(item => !item.isAdditionalFilter);
      return Boolean(nonAdditionalFilterItems.length);
    },
    getFilterSelection() {
      let filterSelection = "";
      const selectedReport = this.getSelectedReport();
      const binItems = this.getBinItems();
      const groupRowExcluded = _.reject(binItems, item => item.groupClass === "group-row");
      const entityGroups = _.groupBy(groupRowExcluded, item => item.type);
      for (const [entity, children] of Object.entries(entityGroups)) {
        filterSelection += `${entity}_id=`;
        for (const item of children) {
          filterSelection += `${item.id},`;
        }
        filterSelection = filterSelection.slice(0, -1);
        filterSelection += "&";
      }
      filterSelection = filterSelection.slice(0, -1);
      if (selectedReport === "Audience Index Pixel") {
        filterSelection += "&pixel_type=event";
      }
      const selectedAttribution = this.getSelectedAttribution();
      if ((selectedReport === "Performance" || selectedReport === "Performance Ecosystem Cost") && selectedAttribution !== "All Attribution Groups") {
        filterSelection += `&attribution_group=${selectedAttribution}`;
      }
      return filterSelection;
    },
    parseSelectorItems(metadata, userSelection) {
      const availableItems = [];
      const selectedItems = [];
      const facebookReports = ["Performance", "Performance Ecosystem Cost"];
      const selectedAttribution = this.getSelectedAttribution();
      const selectedReport = this.getSelectedReport();
      const isFacebookReport = facebookReports.includes(selectedReport);
      const isMMLastTouch = selectedAttribution === defaultAttribution;
      const isFacebook = selectedAttribution === "Facebook Inc.";
      const facebookMetrics = MetricCollections.facebook;
      const attrMetrics = MetricCollections.attrGroupFacebookDataExportPerformance;
      for (const [key, {
        name: name
      }] of Object.entries(metadata)) {
        const column = userSelection.includes(key) ? selectedItems : availableItems;
        if (isFacebookReport) {
          if (isMMLastTouch) {
            if (facebookMetrics.includes(key)) {
              continue;
            }
          } else if (isFacebook) {
            if (attrMetrics.includes(key)) {
              continue;
            }
          }
        }
        column.push({
          value: key,
          text: name
        });
      }
      const orderedSelectedItems = userSelection.map(value => selectedItems.find(item => item.value === value));
      const filteredSelectedItems = orderedSelectedItems.filter(value => value);
      return {
        availableItems: availableItems,
        selectedItems: filteredSelectedItems
      };
    },
    updateSelectorItems(type, newlySelectedItems) {
      const reportInfo = $.extend({}, this.get("reportInfo"));
      const {
        availableDimensions: availableDimensions,
        availableMetrics: availableMetrics,
        selectedDimensions: selectedDimensions,
        selectedMetrics: selectedMetrics,
        structure: structure
      } = reportInfo;
      const {
        availableItems: availableItems,
        selectedItems: selectedItems
      } = this.parseSelectorItems(structure[type], newlySelectedItems);
      const infoData = {
        structure: structure,
        timeAggregation: reportInfo.timeAggregation,
        timeWindowIDs: reportInfo.timeWindowIDs,
        timeWindowNames: reportInfo.timeWindowNames
      };
      if (type === "dimensions") {
        $.extend(infoData, {
          availableDimensions: availableItems,
          availableMetrics: availableMetrics,
          selectedDimensions: selectedItems,
          selectedMetrics: selectedMetrics
        });
      } else {
        $.extend(infoData, {
          availableDimensions: availableDimensions,
          availableMetrics: availableItems,
          selectedDimensions: selectedDimensions,
          selectedMetrics: selectedItems
        });
      }
      this.set({
        reportInfo: infoData
      }, {
        silent: true
      });
    },
    getSelectedDimensions() {
      return this.get("reportInfo").selectedDimensions.map(selectedDimension => selectedDimension.value).toString();
    },
    getSelectedMetrics() {
      return this.get("reportInfo").selectedMetrics.map(selectedMetric => selectedMetric.value).toString();
    },
    setEmailFromAddress(value) {
      this.set({
        emailFromAddress: value
      });
    },
    setEmailToAddresses(value) {
      this.set({
        emailToAddresses: value
      });
    },
    getEmailAddressesVerified() {
      const maxLength = 20;
      const emailAddresses = this.get("emailToAddresses");
      const addressArr = emailAddresses ? emailAddresses.replace(/ /g, "").split(",").filter(Boolean) : [""];
      return addressArr.every(T1.Utils.validateEmail) && addressArr.length <= maxLength;
    },
    setEmailSubject(value) {
      this.set({
        emailSubject: value
      });
    },
    setEmailFrequencyGrain(value) {
      this.set({
        emailFrequencyGrain: value.toLowerCase()
      });
    },
    getEmailFrequencyGrain() {
      return this.get("emailFrequencyGrain") || "-1";
    },
    setEmailWeeklyValue(value) {
      this.set({
        emailWeeklyValue: value
      });
    },
    getEmailWeeklyValue() {
      return this.get("emailWeeklyValue") || "";
    },
    setEmailMonthlyValue(value) {
      this.set({
        emailMonthlyValue: value
      });
    },
    getEmailMonthlyValue() {
      return this.get("emailMonthlyValue") || {
        value: "",
        id: "-1"
      };
    },
    getEmailFrequencyValue(forDisplay) {
      const {
        id: id,
        value: value
      } = this.getEmailMonthlyValue();
      const frequencyGrainMap = new Map([["daily", ""], ["weekly", this.getEmailWeeklyValue()], ["monthly", forDisplay ? value : id]]);
      return frequencyGrainMap.get(this.getEmailFrequencyGrain());
    },
    setEmailStartEndDate(value) {
      this.set({
        emailStartEndDate: value
      });
    },
    getEmailStartEndDate() {
      const startDateObj = new Date();
      const endDateObj = this.getEmailMaxEndDate(startDateObj);
      const start = startDateObj.toString(dateFormat);
      const end = endDateObj.toString(dateFormat);
      return this.get("emailStartEndDate") || {
        start: start,
        end: end
      };
    },
    getEmailMaxEndDate(today) {
      const threeMonths = 90;
      return new Date(today.getFullYear(), today.getMonth(), today.getDate() + threeMonths);
    },
    setEmailTime(value) {
      this.set({
        emailTime: value
      });
    },
    getEmailTime() {
      return this.get("emailTime") || "-1";
    },
    setEmailAMPM(value) {
      this.set({
        emailAMPM: value
      });
    },
    getEmailAMPM() {
      return this.get("emailAMPM") || "-1";
    },
    setEmailTimezone(value) {
      this.set({
        emailTimezone: value
      });
    },
    setEmailMessage(value) {
      if (!(value instanceof Array)) {
        value = value.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/);
      }
      this.set({
        emailMessage: value
      });
    },
    getEmailMessage() {
      const message = this.get("emailMessage");
      return message && message.length ? message.join("\n") : "";
    },
    isPreferredTimeValid() {
      const startEndDate = this.getEmailStartEndDate();
      const startDate = moment(startEndDate.start);
      const endDate = moment(startEndDate.end);
      const currDate = moment();
      const selectedAmPm = this.getEmailAMPM();
      let selectedHour = this.getEmailTime();
      if (selectedHour !== "-1" && selectedAmPm !== "-1" && startDate.isSame(endDate)) {
        selectedHour = parseInt(selectedHour.split(":")[0], 10);
        if (selectedHour === twelveHrs && selectedAmPm === "AM") {
          selectedHour = 0;
        } else if (selectedHour === twelveHrs && selectedAmPm === "PM") {
          selectedHour = twelveHrs;
        } else {
          selectedHour = selectedAmPm === "PM" ? selectedHour + twelveHrs : selectedHour;
        }
        const selectedPreferredTime = moment({
          hour: selectedHour
        });
        currDate.add(1, "hour");
        return !selectedPreferredTime.isBefore(currDate);
      }
      return true;
    }
  });
});
