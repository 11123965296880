define('models/nemoAttachment',["Underscore", "jQuery", "T1", "Backbone"], function (_, $, T1, Backbone) {
  "use strict";

  var nemoAttachment = Backbone.Model.extend({
    urlRoot: T1.NEMO_API_BASE + "attachment",
    parse: function (response) {
      return response.data;
    }
  });
  return nemoAttachment;
});
