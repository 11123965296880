define('modules/shared/views/strategy_grid',["jQuery", "T1", "T1View", "T1GridView", "text!../templates/strategy_grid.html"], function ($, T1, T1View, createGrid, itemTemplate) {
  "use strict";

  var GridView = T1View.extend({
    selected: "",
    partials: {
      listItem: itemTemplate
    },
    headers: [{
      name: "campaign-name",
      label: "campaign name"
    }, {
      name: "campaign-id",
      label: "campaign id"
    }, {
      name: "strategy-name",
      label: "strategy name"
    }, {
      name: "strategy-id",
      label: "strategy id"
    }],
    events: {
      "mouseenter .item": "showDeepLink",
      "mouseleave .item": "hideDeepLink"
    },
    scrollConfigs: {
      target: ".content-holder",
      triggerPoint: 200
    },
    eventhubEvents: {
      "strategygrid:setHighlightValue": function (data) {
        this.highlightValue = data;
      }
    },
    initialize: function (args) {
      var self = this;
      if (args.collection) {
        self.collection = args.collection;
        this.addDataEvent({
          collection: {
            reset: function () {
              this.load();
            }
          }
        });
      }
      if (args.searchTerm) {
        this.highlightValue = args.searchTerm;
      }
    },
    showDeepLink: function (e) {
      var $target = $(e.target);
      $target.find(".link-icon-hold").show();
      $(".stratLink").show();
    },
    hideDeepLink: function () {
      $(".link-icon-hold").hide();
    },
    serialize: function (inputCollection) {
      var self = this;
      var strategyListData = [];
      var collection = inputCollection || this.collection;
      var collectionData;
      var highlightValue;
      if (collection) {
        collectionData = collection.toJSON();
        $.each(collectionData, function (index, strategyList) {
          var stratName = strategyList.strategy.name;
          var stratID = strategyList.strategy.id;
          var campName = strategyList.strategy.campaign.name;
          var campID = strategyList.strategy.campaign_id;
          strategyList.stratName = stratName;
          strategyList.stratID = stratID;
          strategyList.campName = campName;
          strategyList.campID = campID;
          if (self.highlightValue) {
            highlightValue = decodeURIComponent(self.highlightValue);
            strategyList.stratName = T1.Utils.highlightText(strategyList.stratName, highlightValue);
            strategyList.stratID = T1.Utils.highlightText(stratID, highlightValue);
            strategyList.campName = T1.Utils.highlightText(strategyList.campName, highlightValue);
            strategyList.campID = T1.Utils.highlightText(campID, highlightValue);
          }
          strategyList.isActive = strategyList.strategy.status === "1" ? true : false;
          strategyListData.push(strategyList);
        });
      }
      return {
        list: strategyListData,
        contentType: "strategy-list-grid"
      };
    }
  });
  return createGrid(GridView);
});
