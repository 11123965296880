define('modules/campaign/chart/winLoss/views/funnelChart',["jQuery", "Hogan", "../shared/canvasBar", "../shared/canvasText", "../shared/canvasTrapezoid", "text!../templates/ttipCampaignSummaryFunnel.html"], function ($, Hogan, Bar, Txt, Trapezoid, ttipTemplate) {
  "use strict";

  const HALF = 2;
  const PERCENTAGE = 100;
  const STROKE_WIDTH = 5;
  var FunnelChart = function ({
    funnelData: funnelData,
    isCreative: isCreative,
    formatter: formatter,
    canvas: canvas,
    ttip: ttip,
    ttipTrigger: ttipTrigger
  }) {
    let bar0, bar0Num, bar0txt, bar1, bar1Num, bar1Txt, bar2, bar2Num, bar2Txt, boundingRect, offsetX, offsetY, trapezoid0, trapezoid0Num, trapezoid1, trapezoid1LowerEdgeWidth, trapezoid1Num;
    const compiledTemplate = Hogan.compile(ttipTemplate);
    const ctx = canvas.getContext("2d");
    let width = canvas.width;
    let center = width / HALF;
    const funnelOffset = 10;
    const barHeight = 26;
    const fontsize = 12;
    const font = "AB";
    const baseline = "top";
    const txtSpacing = (barHeight - fontsize) / HALF;
    const barSpacing = 34;
    const bar0txtValue = isCreative ? "Bids" : "Matched Bid Opportunities";
    const bar0NumValue = isCreative ? funnelData.bids : funnelData.bidOppts;
    const trapezoid0txtValue = isCreative ? "Win Rate" : "Bid Rate";
    const trapezoid0NumValue = isCreative ? funnelData.winRate : funnelData.bidRate;
    let trapezoid0LowerEdgeWidth = width / PERCENTAGE * trapezoid0NumValue;
    const trapezoid1NumValue = funnelData.winRate;
    const bar1txtValue = isCreative ? "Wins" : "Bids";
    const bar1NumValue = isCreative ? funnelData.wins : funnelData.bids;
    function getMousePos(evt) {
      const posX = evt.clientX - offsetX;
      const posY = evt.clientY - offsetY;
      return {
        x: posX,
        y: posY
      };
    }
    function formatNum(value) {
      return formatter(value, "integers");
    }
    return {
      draw: function (w) {
        var self = this;
        width = w || width;
        canvas.width = width;
        center = width / HALF;
        trapezoid0LowerEdgeWidth = width / PERCENTAGE * trapezoid0NumValue;
        boundingRect = canvas.getBoundingClientRect();
        offsetX = Math.round(boundingRect.left);
        offsetY = Math.round(boundingRect.top);
        this.resetFunnel();
        ctx.clearRect(0, 0, width, canvas.height);
        this.drawBar0().then(function () {
          self.drawBar0Txt();
          self.drawBar0Num();
          self.drawTrapezoid0().then(function () {
            self.drawTrapezoid0Num();
            self.drawBar1().then(function () {
              self.drawBar1Txt();
              self.drawBar1Num();
              if (!isCreative) {
                self.drawTrapezoid1().then(function () {
                  self.drawTrapezoid1Num();
                  self.drawBar2().then(function () {
                    self.drawBar2Txt();
                    self.drawBar2Num();
                    $(canvas).on("mousemove.funnelTip", self.overlayTtip.bind(self));
                  });
                });
              } else {
                $(canvas).on("mousemove.funnelTip", self.overlayTtip.bind(self));
              }
            });
          });
        });
      },
      drawBar0: function () {
        bar0 = new Bar({
          color: "#9adfa0",
          context: ctx,
          height: barHeight,
          width: width,
          x: 0,
          y: 0
        });
        return bar0.draw("animateHeight");
      },
      drawBar0Txt: function () {
        if (ctx.measureText(bar0txtValue).width + txtSpacing < width) {
          bar0txt = new Txt({
            align: "left",
            baseline: baseline,
            color: "#fff",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: bar0txtValue,
            x: bar0.getBounds().xMin + txtSpacing,
            y: bar0.getBounds().yMin + txtSpacing
          });
          bar0txt.draw();
        }
      },
      drawBar0Num: function () {
        if (ctx.measureText(bar0NumValue).width + txtSpacing + ctx.measureText(bar0txtValue).width + txtSpacing < width) {
          bar0Num = new Txt({
            align: "right",
            baseline: baseline,
            color: "#fff",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: formatNum(bar0NumValue),
            x: bar0.getBounds().xMax - txtSpacing,
            y: bar0.getBounds().yMin + txtSpacing
          });
          bar0Num.draw();
        }
      },
      drawTrapezoid0: function () {
        trapezoid0LowerEdgeWidth = trapezoid0LowerEdgeWidth < STROKE_WIDTH ? STROKE_WIDTH : trapezoid0LowerEdgeWidth;
        const halfWidth = trapezoid0LowerEdgeWidth / HALF;
        trapezoid0 = new Trapezoid({
          color: "#e8e8e8",
          context: ctx,
          p1: {
            x: bar0.getBounds().xMin,
            y: bar0.getBounds().yMax
          },
          p2: {
            x: bar0.getBounds().xMax,
            y: bar0.getBounds().yMax
          },
          p3: {
            x: center + halfWidth,
            y: bar0.getBounds().yMax + barSpacing
          },
          p4: {
            x: center - halfWidth,
            y: bar0.getBounds().yMax + barSpacing
          }
        });
        return trapezoid0.draw("animateHeight");
      },
      drawTrapezoid0Num: function () {
        var trapezoid0NumWidth = txtSpacing + ctx.measureText(`${trapezoid0NumValue}%`).width + txtSpacing;
        var trapezoid0Bounds = trapezoid0.getBounds();
        var trapezoid0TopWidth = trapezoid0Bounds.p2.x - trapezoid0Bounds.p1.x;
        var trapezoid0BottomWidth = trapezoid0Bounds.p3.x - trapezoid0Bounds.p4.x;
        var trapezoid0MiddleWidth = (trapezoid0TopWidth + trapezoid0BottomWidth) / HALF;
        if (trapezoid0NumWidth < trapezoid0MiddleWidth) {
          const halfWidth = (barSpacing - fontsize) / HALF;
          trapezoid0Num = new Txt({
            align: "center",
            baseline: baseline,
            color: "#000",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: `${trapezoid0NumValue}%`,
            x: center,
            y: trapezoid0Bounds.p1.y + halfWidth
          });
          trapezoid0Num.draw();
        }
      },
      drawBar1: function () {
        const halfWidth = trapezoid0LowerEdgeWidth / HALF;
        bar1 = new Bar({
          color: "#65ab78",
          context: ctx,
          height: barHeight,
          width: trapezoid0LowerEdgeWidth,
          x: center - halfWidth,
          y: bar0.getBounds().yMax + barSpacing
        });
        return bar1.draw("animateHeight");
      },
      drawBar1Txt: function () {
        if (txtSpacing + ctx.measureText(bar1txtValue).width + txtSpacing < trapezoid0LowerEdgeWidth) {
          bar1Txt = new Txt({
            align: "left",
            baseline: baseline,
            color: "#fff",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: bar1txtValue,
            x: bar1.getBounds().xMin + txtSpacing,
            y: bar1.getBounds().yMin + txtSpacing
          });
          bar1Txt.draw();
        }
      },
      drawBar1Num: function () {
        if (txtSpacing + ctx.measureText(bar1NumValue).width + txtSpacing + ctx.measureText(bar1txtValue).width + txtSpacing < trapezoid0LowerEdgeWidth) {
          bar1Num = new Txt({
            align: "right",
            baseline: baseline,
            color: "#fff",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: formatNum(bar1NumValue),
            x: bar1.getBounds().xMax - txtSpacing,
            y: bar1.getBounds().yMin + txtSpacing
          });
          bar1Num.draw();
        }
      },
      drawTrapezoid1: function () {
        trapezoid1LowerEdgeWidth = (bar1.getBounds().xMax - bar1.getBounds().xMin) / PERCENTAGE * funnelData.winRate;
        trapezoid1LowerEdgeWidth = trapezoid1LowerEdgeWidth < STROKE_WIDTH ? STROKE_WIDTH : trapezoid1LowerEdgeWidth;
        const halfWidth = trapezoid1LowerEdgeWidth / HALF;
        trapezoid1 = new Trapezoid({
          color: "#e8e8e8",
          context: ctx,
          p1: {
            x: bar1.getBounds().xMin,
            y: bar1.getBounds().yMax
          },
          p2: {
            x: bar1.getBounds().xMax,
            y: bar1.getBounds().yMax
          },
          p3: {
            x: center + halfWidth,
            y: bar1.getBounds().yMax + barSpacing
          },
          p4: {
            x: center - halfWidth,
            y: bar1.getBounds().yMax + barSpacing
          }
        });
        return trapezoid1.draw("animateHeight");
      },
      drawTrapezoid1Num: function () {
        var trapezoid1NumWidth = txtSpacing + ctx.measureText(`${trapezoid1NumValue}%`).width + txtSpacing;
        var trapezoid1Bounds = trapezoid1.getBounds();
        var trapezoid1TopWidth = trapezoid1Bounds.p2.x - trapezoid1Bounds.p1.x;
        var trapezoid1BottomWidth = trapezoid1Bounds.p3.x - trapezoid1Bounds.p4.x;
        var trapezoid1MiddleWidth = (trapezoid1TopWidth + trapezoid1BottomWidth) / HALF;
        if (trapezoid1NumWidth < trapezoid1MiddleWidth) {
          const halfWidth = (barSpacing - fontsize) / HALF;
          trapezoid1Num = new Txt({
            align: "center",
            baseline: baseline,
            color: "#000",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: `${funnelData.winRate}%`,
            x: center,
            y: trapezoid1Bounds.p1.y + halfWidth
          });
          trapezoid1Num.draw();
        }
      },
      drawBar2: function () {
        const halfWidth = trapezoid1LowerEdgeWidth / HALF;
        bar2 = new Bar({
          color: "#149476",
          context: ctx,
          height: barHeight,
          width: trapezoid1LowerEdgeWidth,
          x: center - halfWidth,
          y: bar1.getBounds().yMax + barSpacing
        });
        return bar2.draw("animateHeight");
      },
      drawBar2Txt: function () {
        if (txtSpacing + ctx.measureText("Wins").width + txtSpacing < trapezoid1LowerEdgeWidth) {
          bar2Txt = new Txt({
            align: "left",
            baseline: baseline,
            color: "#fff",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: "Wins",
            x: bar2.getBounds().xMin + txtSpacing,
            y: bar2.getBounds().yMin + txtSpacing
          });
          bar2Txt.draw();
        }
      },
      drawBar2Num: function () {
        if (txtSpacing + ctx.measureText(funnelData.wins).width + txtSpacing + ctx.measureText("Wins").width + txtSpacing < trapezoid1LowerEdgeWidth) {
          bar2Num = new Txt({
            align: "right",
            baseline: baseline,
            color: "#fff",
            context: ctx,
            font: font,
            fontsize: fontsize,
            text: formatNum(funnelData.wins),
            x: bar2.getBounds().xMax - txtSpacing,
            y: bar2.getBounds().yMin + txtSpacing
          });
          bar2Num.draw();
        }
      },
      resetFunnel: function () {
        this.activeTip = "";
        ttipTrigger.css({
          height: 0,
          left: 0,
          top: 0,
          width: 0
        });
        $(canvas).off("mousemove.funnelTip");
      },
      renderFunnelTooltip: function (metric, value) {
        Polymer.dom(ttip).innerHTML = compiledTemplate.render({
          metric: metric,
          value: value
        });
      },
      overlayTtip: function (evt) {
        const mouse = getMousePos(evt);
        const x = mouse.x;
        const y = mouse.y;
        if (bar0.contains(x, y)) {
          if (this.activeTip !== "bar0") {
            this.renderFunnelTooltip(bar0txtValue, formatNum(bar0NumValue));
            ttipTrigger.css({
              height: barHeight,
              left: 0,
              top: funnelOffset,
              width: width
            });
          }
          this.activeTip = "bar0";
        }
        if (trapezoid0.contains(x, y)) {
          if (this.activeTip !== "trapezoid0") {
            this.renderFunnelTooltip(trapezoid0txtValue, `${trapezoid0NumValue}%`);
            ttipTrigger.css({
              height: barSpacing,
              left: 0,
              top: funnelOffset + barHeight,
              width: width
            });
          }
          this.activeTip = "trapezoid0";
        }
        if (bar1.contains(x, y)) {
          if (this.activeTip !== "bar1") {
            this.renderFunnelTooltip(bar1txtValue, formatNum(bar1NumValue));
            ttipTrigger.css({
              height: barHeight,
              left: 0,
              top: funnelOffset + bar1.getBounds().yMin,
              width: width
            });
          }
          this.activeTip = "bar1";
        }
        if (!isCreative) {
          if (trapezoid1.contains(x, y)) {
            if (this.activeTip !== "trapezoid1") {
              this.renderFunnelTooltip("Win Rate", `${funnelData.winRate}%`);
              ttipTrigger.css({
                height: barSpacing,
                left: 0,
                top: funnelOffset + trapezoid1.getBounds().p1.y,
                width: width
              });
            }
            this.activeTip = "trapezoid1";
          }
          if (bar2.contains(x, y)) {
            if (this.activeTip !== "bar2") {
              this.renderFunnelTooltip("Wins", formatNum(funnelData.wins));
              ttipTrigger.css({
                height: barHeight,
                left: 0,
                top: funnelOffset + bar2.getBounds().yMin,
                width: width
              });
            }
            this.activeTip = "bar2";
          }
        }
      },
      unload: function () {
        this.activeTip = null;
        $(canvas).off("mousemove.funnelTip");
      }
    };
  };
  return FunnelChart;
});
