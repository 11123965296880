define('models/mediaDealGroup',["jQuery", "moment", "T1", "T1Model"], function ($, moment, T1, T1Model) {
  "use strict";

  T1.Models.DealGroup = T1Model.extend({
    canCache: false,
    cacheDuration: 6e4,
    url: "deal_groups",
    apiRoot: "/media/v1.0/",
    getSuccessFunction(options = {}) {
      return typeof options.success === "function" ? options.success : $.noop;
    },
    getSyncOptions(options) {
      return {
        dataType: "json",
        success: this.getSuccessFunction(options),
        processAjaxResponse: this.processAjaxResponse
      };
    },
    fetch: function (options) {
      if (this.ajax) {
        this.ajax.abort();
      }
      this.ajax = this.sync("read", "", this.getSyncOptions(options));
      if (this.ajax) {
        this.ajax.always(() => {
          this.ajax = null;
        });
      }
      return this.ajax;
    },
    processAjaxResponse: function (resp) {
      return {
        jsonData: resp,
        statusCode: "ok"
      };
    },
    createDealGroup: function (rawData, options) {
      var statusInvalid = options.invalid || $.noop;
      var conflict = options.conflict || $.noop;
      var error = options.error || $.noop;
      this.save(rawData, {
        success: (model, attrs) => {
          var onSuccess = options.success || $.noop;
          this.parseDealGroup(model, attrs.data);
          onSuccess(model, attrs.data);
        },
        error: (model, responseString) => {
          var response = JSON.parse(responseString);
          var status = response.meta.status;
          var message = response.errors[0].message;
          T1.Notify(status, message);
          error(model, responseString);
        },
        dataType: "json",
        stringify: true,
        contentType: "application/json",
        processData: false,
        statusInvalid: statusInvalid,
        conflict: conflict,
        errorDisplaySuppressingConfig: {
          errorSuppressed: true
        },
        processAjaxResponse: function (resp) {
          const jsonData = typeof resp === "object" ? resp : JSON.parse(resp);
          const statusCode = jsonData.meta.status || "ok";
          if (statusCode === "error") {
            const errorObj = jsonData.errors[0];
            jsonData.errors = {
              field_error: {
                name: errorObj.field,
                error: errorObj.message
              }
            };
          }
          return {
            jsonData: jsonData,
            statusCode: statusCode
          };
        }
      });
    },
    parse: function ({
      id: id,
      name: name,
      owner: owner,
      entity_type: entity_type,
      created_on: created_on,
      updated_on: updated_on,
      deal_ids: deal_ids,
      description: description,
      status: status
    }) {
      return {
        id: id,
        name: name,
        owner: owner,
        entity_type: entity_type,
        created_on: created_on,
        updated_on: updated_on,
        deal_ids: deal_ids,
        deals: [],
        description: description,
        status: status ? "1" : "0",
        status_class: status ? "active" : "inactive"
      };
    },
    parseDealGroup: function (model, attrs) {
      model.set(attrs);
    }
  });
  return T1.Models.DealGroup;
});
