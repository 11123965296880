define('modules/version/main',["jQuery", "T1", "T1Module", "T1Versions", "T1PicardVersion"], function ($, T1, T1Module, T1Versions, T1PicardVersion) {
  return new T1Module({
    name: "version",
    viewCfgs: {
      info: {
        model: T1.VERSIONS,
        picardModel: T1.PICARD_VERSION
      }
    },
    defaultView: "info"
  });
});
