define('models/pixel',["jQuery", "Underscore", "T1", "Backbone", "T1Model", "models/pixelPlacementReport", "models/advertiser", "models/agency", "models/dataprovider", "models/pixelBundleUrl", "models/pixelInContainer"], function ($, _, T1, Backbone, T1Model, PixelPlacement, Advertiser, Agency, DataProvider, PixelBundleUrl, PixelInContainer) {
  T1.Models.Pixel = T1Model.extend({
    url: "pixel_bundles/",
    fixture: "-pixel",
    name: "pixel",
    allowInlineValidation: true,
    omitIdFromPost: true,
    removeFields: ["pricingType", "cost_type", "isRevenueAttributionChecked", "isRevenueAttributionChecked", "currencyType", "eventUrls", "pixelList"],
    canEdit: function () {
      var deferred = $.Deferred();
      deferred.resolve(true);
      return deferred;
    },
    relations: [{
      type: Backbone.HasOne,
      key: "rpt_pixel_placement_qa_top",
      relatedModel: "PixelPlacement",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "advertiser",
      relatedModel: "Advertiser",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "agency",
      relatedModel: "Agency",
      includeInJSON: true
    }, {
      type: Backbone.HasOne,
      key: "pixel_provider",
      relatedModel: "DataProvider",
      includeInJSON: true
    }, {
      type: Backbone.HasMany,
      key: "pixel_bundle_url",
      relatedModel: PixelBundleUrl,
      includeInJSON: true
    }, {
      type: Backbone.HasMany,
      key: "pixel",
      relatedModel: PixelInContainer,
      includeInJSON: true
    }],
    validateAttrs: function (attrs, isFromCreateEdit) {
      attrs = attrs || this.toJSON();
      if (isFromCreateEdit) {
        attrs = $.extend(true, attrs, {
          eventUrls: _.map(this.eventUrls, function (item) {
            return item.toJSON();
          }),
          pixelList: _.map(this.pixelList, function (item) {
            return item.toJSON();
          })
        });
      }
      var self = this;
      var optionalFields = ["keywords"];
      var requiredField = "This is a required field.";
      var validationResult;
      var errors = [];
      var error = {};
      var reqFields = {};
      function isValid(field) {
        return field !== undefined && field !== "" && field !== null;
      }
      if (attrs.pixel_type === "event") {
        reqFields.advertiser_id = function () {
          return !attrs.advertiser_id;
        };
        reqFields.url = function (field) {
          var fieldValue;
          if (field) {
            fieldValue = $.trim(field);
            if (fieldValue.length > 2048) {
              return "Url must not exceed 2048 characters";
            } else if (!fieldValue.length) {
              return "Url value cannot be empty";
            }
          }
          return false;
        };
        reqFields.tag = function (field) {
          var fieldValue;
          if (field) {
            fieldValue = $.trim(field);
            if (fieldValue.length > 1024) {
              return "Tag must not exceed 1024 characters";
            } else if (!fieldValue.length) {
              return "Tag cannot be empty";
            }
          }
          return false;
        };
        reqFields.revenue = function (field) {
          if (attrs.isRevenueAttributionChecked) {
            if (!isValid(field)) {
              return "If Revenue Attribution Pair is selected, Revenue and Currency must be populated.";
            }
          }
          return false;
        };
        reqFields.currency = function (field) {
          if (attrs.isRevenueAttributionChecked) {
            if (attrs.currencyType === "DYNAMIC" && !isValid(field)) {
              return "If Revenue Attribution Pair is selected, Revenue and Currency must be populated.";
            }
          }
          return false;
        };
        reqFields.currency_fixed = function (field) {
          if (attrs.isRevenueAttributionChecked) {
            if (attrs.currencyType === "FIXED" && !isValid(field)) {
              return "If Revenue Attribution Pair is selected, Revenue and Currency must be populated.";
            }
          }
          return false;
        };
      } else {
        reqFields.agency_id = function () {
          return !attrs.agency_id;
        };
        reqFields.provider_id = function () {
          return !attrs.provider_id;
        };
        reqFields.cost_pct_cpm = function () {
          if (attrs.cost_type && attrs.cost_type === "percent") {
            if (attrs.cost_pct_cpm !== undefined && attrs.cost_pct_cpm === "") {
              return "% value of Media Cost pricing is required.";
            }
          }
          if (attrs.pricingType && attrs.pricingType === "cost_pct_cpm") {
            if (attrs.cost_pct_cpm !== undefined && attrs.cost_pct_cpm === "") {
              return requiredField;
            }
          }
          if (parseFloat(attrs.cost_pct_cpm) >= parseFloat(99 + 9e-10) || parseFloat(attrs.cost_pct_cpm) < 0 || attrs.cost_pct_cpm !== "" && !$.isNumeric(attrs.cost_pct_cpm)) {
            return "Media Cost % cannot exceed 99% or be less than 0%";
          }
          return false;
        };
        reqFields.cost_cpm = function () {
          if (attrs.cost_type && (attrs.cost_type === "currency" || attrs.cost_type === "CPM")) {
            if (attrs.cost_cpm !== undefined && attrs.cost_cpm === "") {
              return "CPM for CPM pricing is required.";
            }
          }
          if (attrs.pricingType && attrs.pricingType === "cost_cpm") {
            if (attrs.cost_cpm !== undefined && attrs.cost_cpm === "") {
              return requiredField;
            }
          }
          if (parseFloat(attrs.cost_cpm) >= parseFloat(999 + 9e-10) || parseFloat(attrs.cost_cpm) < 0 || attrs.cost_cpm !== "" && !$.isNumeric(attrs.cost_cpm)) {
            return "CPM can't exceed 999 or be less than 0";
          }
          return false;
        };
      }
      function doValidation(data, itemId) {
        $.each(data, function (key, value) {
          error = {};
          if ((value !== undefined && value === "" || reqFields[key]) && $.inArray(key, optionalFields) === -1) {
            if (reqFields[key]) {
              validationResult = reqFields[key](value);
              if (validationResult) {
                if (typeof validationResult === "boolean") {
                  error = {
                    field: key,
                    message: requiredField
                  };
                } else {
                  error = {
                    field: key,
                    message: validationResult
                  };
                }
              }
            } else {
              error = {
                field: key,
                message: requiredField
              };
            }
            if (error.field) {
              error.id = itemId;
              errors.push(error);
            }
          }
        });
      }
      doValidation(attrs, self.id || self.cid);
      if (isFromCreateEdit) {
        $.each(attrs.eventUrls, function (index, item) {
          if (item.action !== "delete") {
            doValidation(item, item.id || item.cid);
          }
        });
        $.each(attrs.pixelList, function (index, item) {
          if (item.action !== "delete") {
            doValidation(item, item.id || item.cid);
          }
        });
      }
      if (errors.length) {
        return errors;
      }
    },
    createEdit: function (rawData, options) {
      var validationErrors;
      var self = this;
      var success = options.success || $.noop;
      var statusInvalid = options.statusInvalid || $.noop;
      var conflict = options.conflict || $.noop;
      var error = options.error || $.noop;
      var onSuccess;
      function getData(model) {
        var data = model.toJSON();
        delete data.rel;
        delete data.type;
        delete data.name;
        delete data.distributed;
        delete data.created_on;
        delete data.updated_on;
        delete data.pixel_type;
        delete data.id;
        delete data.action;
        if (data.version === undefined) {
          delete data.version;
        }
        return data;
      }
      function batchSaveModels(models, parentModel) {
        var deferred = $.Deferred();
        var totalCnt = models.length;
        var successCnt = 0;
        var saveSuccessHandler = function () {
          successCnt++;
          if (successCnt >= totalCnt) {
            deferred.resolve();
          }
        };
        if (totalCnt) {
          $.each(models, function (index, model) {
            var data = getData(model);
            if (model.get("action") === "delete") {
              model.drop(saveSuccessHandler, conflict);
            } else {
              if (data.url === undefined) {
                data.bundle_id = parentModel.id;
                data.pixel_type = parentModel.get("pixel_type");
              } else {
                data.pixel_bundle_id = parentModel.id;
              }
              if (model.isNew()) {
                model.unset("id");
              }
              model.save(data, {
                success: saveSuccessHandler,
                statusInvalid: statusInvalid,
                conflict: conflict,
                error: error,
                errorDisplaySuppressingConfig: {
                  errorSuppressed: true,
                  errorCodes: [400]
                }
              });
            }
          });
        } else {
          deferred.resolve();
        }
        return deferred.promise();
      }
      self.eventUrls = _.clone(rawData.eventUrls);
      self.pixelList = _.clone(rawData.pixelList);
      validationErrors = self.validateAttrs(rawData, true);
      onSuccess = function (model) {
        if (model.get("pixel_type") === "event") {
          batchSaveModels(self.eventUrls, model).done(function () {
            batchSaveModels(self.pixelList, model).done(function () {
              success(model);
            });
          });
        } else {
          success(model);
        }
      };
      if (validationErrors && validationErrors.length) {
        statusInvalid(validationErrors);
      } else {
        this.save(rawData, {
          success: onSuccess,
          statusInvalid: statusInvalid,
          conflict: conflict,
          error: error,
          errorDisplaySuppressingConfig: {
            errorSuppressed: true,
            errorCodes: [400]
          }
        });
      }
    },
    reset: function () {
      this.url = "";
      this.attributes = {};
    },
    save: function (saveObj, options) {
      var self = this;
      $.each(saveObj, function (index, val) {
        if (self.removeFields.indexOf(index) !== -1) {
          delete saveObj[index];
        }
      });
      if (saveObj.cost_cpm !== undefined && saveObj.cost_cpm !== "") {
        saveObj.cost_pct_cpm = "";
      } else {
        saveObj.cost_cpm = "";
      }
      if (!this.preventDelete) {
        if (saveObj.action === "status_change") {
          delete saveObj.action;
          delete saveObj.cost_cpm;
        }
        if (saveObj.name && !saveObj.provider_id) {
          delete saveObj.cost_cpm;
        }
      }
      T1Model.prototype.save.call(this, saveObj, options);
    }
  });
  return T1.Models.Pixel;
});
