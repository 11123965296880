define('collections/trackingEvents',["jQuery", "T1", "T1Collection", "models/trackingEvent", "T1Sortable"], function ($, T1, T1Collection, TrackingEvent) {
  "use strict";

  T1.Models.TrackingEvents = T1Collection.extend({
    model: TrackingEvent,
    initialize: function (args) {
      if (!args) {
        var i;
        for (i = 0; i < 5; i++) {
          this.add(new TrackingEvent({
            action: "new"
          }));
        }
      }
    },
    validateModels: function (isSkippableChecked) {
      var output = [];
      var validation;
      if (this.models.length > 0) {
        this.each(function (model) {
          validation = model.validateAttrs(model.toJSON(), isSkippableChecked);
          if (validation && model.get("action") !== "new") {
            output.push(validation);
          }
        });
      }
      if (output.length) {
        return output;
      }
    },
    urlString: "trackingEvents"
  });
  return T1.Models.TrackingEvents;
});
