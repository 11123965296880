define('modules/changelog/utils/mapper',["T1", "./mappingTable"], function (T1, MappingTable) {
  "use strict";

  var ChangeLogMapper = function (entityType, collection, searchTerm) {
    var result = {
      list: []
    };
    var mapping = MappingTable[entityType];
    var canDisplayRecord = function (rec) {
      var recordString;
      if (!searchTerm) {
        return Boolean(rec.name);
      }
      recordString = `${rec.name}|${rec.user_name}|${rec.old_value}|${rec.new_value}`;
      return recordString.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    };
    collection.each(function (model) {
      var modelJSON = model.toJSON();
      var fieldMapping;
      if (modelJSON.action === "update") {
        if (modelJSON.name) {
          fieldMapping = mapping[modelJSON.name];
          if (fieldMapping) {
            modelJSON.name = fieldMapping.label;
            if (fieldMapping.hide) {
              return true;
            }
            if (fieldMapping.values) {
              modelJSON.old_value = fieldMapping.values[modelJSON.old_value] || modelJSON.old_value;
              modelJSON.new_value = fieldMapping.values[modelJSON.new_value] || modelJSON.new_value;
            }
          } else {
            modelJSON.name = T1.Utils.capitalize(modelJSON.name.replace(/[_.]/gi, " "));
          }
        }
        modelJSON.old_value = modelJSON.old_value === "" ? "--" : modelJSON.old_value;
        modelJSON.new_value = modelJSON.new_value === "" ? "--" : modelJSON.new_value;
        modelJSON.date_time = modelJSON.date.replace("T", " at ");
        if (canDisplayRecord(modelJSON)) {
          result.list.push(modelJSON);
        }
      }
    });
    return result;
  };
  return ChangeLogMapper;
});
