define('modules/creatives/display/views/createT1ASReview',["jQuery", "Underscore", "When", "T1", "T1Collection", "models/t1asAsset", "models/atomicCreative", "models/advertiser", "collections/creativesDisplayT1as", "utils/CreativeUtils", "T1View", "T1Layout", "T1Permissions", "text!../templates/createT1ASReview.html", "T1Form", "T1Notifier"], function ($, _, When, T1, T1Collection, T1asAsset, AtomicCreative, Advertiser, T1asCollection, CreativeUtils, T1View, T1Layout, T1Permissions, template) {
  "use strict";

  var T1ASCreateReview;
  function updateBreadCrumb() {
    var pathPieces = [{
      label: "Upload New T1AS Hosted Assets (Step 2 of 2)",
      location: "creatives/display-t1as-review"
    }];
    T1.EventHub.publish("updateNavPath", {
      locations: pathPieces
    });
  }
  T1ASCreateReview = T1View.extend({
    template: template,
    events: {
      "click .toggle": "updateFields",
      "click .concept": "assignConcept",
      "click .t1as-valid-grid": "handleGridClicked",
      "click .saveBulkReview": "handleBulkReviewSave",
      "changed .expansion .click_url strand-input": "landingInputChanged",
      "changed .expansion .tpas_ad_tag strand-textarea": "tpasInputChanged"
    },
    eventhubEvents: {
      "bulkedit:reviewSaved": "handleReviewSaved"
    },
    isFormElementsChanged: false,
    initialize: function () {
      var self = this;
      if (_.isEmpty(this.model.attributes)) {
        T1.Location.set("creatives/display-t1as");
        return;
      } else {
        self.isFormElementsChanged = true;
        self.isLoaded = false;
        self.isBulkReviewEnabled = T1Permissions.check("feature", "creatives.bulk_review");
        self.actionsConfig = {
          formActions: [{
            label: "cancel",
            class: "cancel",
            location: "#"
          }, {
            label: "Save & Finish",
            class: "btn-primary save",
            location: "#"
          }]
        };
        self.updateModels().then(function () {
          self.initLayout();
          self.isLoaded = true;
          self.collection = new T1asCollection();
          self.collection.add(self.model.get("primaries"));
          self.load();
        });
      }
    },
    initLayout: function () {
      var self = this;
      updateBreadCrumb();
      this.layout1 = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".bulk-edit-modal",
        template: '<div class="bulk-edit-modal"></div>',
        layout: {
          ".choose-action": [{
            module: "shared",
            viewType: "pullDownButton",
            options: {
              id: "choose-action",
              menuLabel: "Choose Action",
              items: [{
                itemLabel: "Activate",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doRowActivateOrDeactivate(item.index, true);
                  });
                }
              }, {
                itemLabel: "Deactivate",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doRowActivateOrDeactivate(item.index, false);
                  });
                }
              }, {
                itemLabel: "Edit",
                itemCallback: $.proxy(self.showBulkEdit, self)
              }, {
                itemLabel: "Include from Upload",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doSkipOrUnSkip(item.index, false);
                  });
                }
              }, {
                itemLabel: "Exclude from Upload",
                itemCallback: function () {
                  _.each(self.getSelectedItems(), function (item) {
                    self.doSkipOrUnSkip(item.index, true);
                  });
                }
              }],
              disabled: true
            }
          }],
          ".review-grid-valid": [{
            module: "creatives/display",
            viewType: "t1AsReviewGridValid",
            options: {
              model: this.model
            }
          }],
          ".review-grid-invalid": [{
            module: "creatives/display",
            viewType: "t1AsReviewGridInvalid",
            options: {
              model: this.model
            }
          }],
          ".concept-modal": [{
            module: "creatives/display",
            viewType: "conceptModal",
            options: {
              model: this.model
            }
          }]
        }
      });
      this.layout2 = new T1Layout({
        el: function () {
          return self.el;
        },
        selectorPath: ".click-thru-modal",
        template: template,
        layout: {
          ".choose-action": [{
            module: "shared",
            viewType: "pullDownButton",
            options: {
              id: "choose-action",
              menuLabel: "Choose Action",
              items: [{
                itemLabel: "Assign Concept",
                itemCallback: function () {
                  var $el = $(this.el);
                  var $validGrid = $el.find(".t1as-valid-grid");
                  var $checkBoxes = $validGrid.find("strand-checkbox.strand-grid-item");
                  self.model.resetCheckedItems();
                  _.each($checkBoxes, function (item) {
                    if (item.checked_) {
                      var id = Number(item.id);
                      self.model.setCheckedItem(id);
                    }
                  });
                  T1.EventHub.publish("conceptmodal:show");
                }
              }, {
                itemLabel: "Edit Click Through URL",
                itemCallback: function () {
                  var $el = $(this.el);
                  var $validGrid = $el.find(".t1as-valid-grid");
                  var $checkBoxes = $validGrid.find("strand-checkbox.strand-grid-item");
                  self.model.resetCheckedItems();
                  _.each($checkBoxes, function (item) {
                    if (item.checked_) {
                      var id = Number(item.id);
                      self.model.setCheckedItem(id);
                    }
                  });
                  T1.EventHub.publish("clickthrumodal:show");
                }
              }],
              themeColor: "gray",
              disabled: true
            }
          }],
          ".review-grid-valid": [{
            module: "creatives/display",
            viewType: "t1AsReviewGridValid",
            options: {
              model: this.model
            }
          }],
          ".review-grid-invalid": [{
            module: "creatives/display",
            viewType: "t1AsReviewGridInvalid",
            options: {
              model: this.model
            }
          }],
          ".concept-modal-view": [{
            module: "creatives/display",
            viewType: "conceptModal",
            options: {
              model: this.model
            }
          }],
          ".click-thru-modal-view": [{
            module: "creatives/display",
            viewType: "clickThruModal",
            options: {
              model: this.model
            }
          }]
        }
      });
    },
    updateModels: function () {
      var self = this;
      var deferred = When.defer();
      var advertiser;
      advertiser = new Advertiser({
        id: self.model.get("advertiser_id")
      });
      advertiser.fetch({
        params: {
          with: ["agency"]
        },
        success: function (data) {
          self.model.set({
            advertiser: data
          });
          deferred.resolve(self.model);
        }
      });
      return deferred.promise;
    },
    getAgencyAndAdvertiserName: function () {
      var advertiser, agency, agencyDisplay;
      advertiser = this.model && this.model.get("advertiser") ? this.model.get("advertiser") : null;
      if (advertiser && advertiser.get("agency")) {
        agency = advertiser.get("agency");
        agencyDisplay = agency ? agency.get("name") + "&nbsp;&raquo;&nbsp;" : "";
        return agencyDisplay + advertiser.get("name");
      }
    },
    assignConcept: function (e) {
      var self = this;
      var $target = $(e.target);
      var id = Number($target.closest(".concept").attr("id"));
      self.model.resetCheckedItems();
      self.model.setCheckedItem(id);
      T1.EventHub.publish("conceptmodal:show");
      $(".save-disabled").removeClass("save-disabled").addClass("save");
    },
    handleGridClicked: function (evt) {
      var self = this;
      var $el = self.el;
      var $grid = $el.find(".t1as-valid-grid");
      var strandGrid = $grid && $grid[0];
      var $item = $grid.find("strand-grid-item");
      var $checkBoxes = $grid.find("strand-checkbox.strand-grid-item");
      var $allCheckbox = $grid.find("#selectAll");
      var primaries = self.model.get("primaries");
      setTimeout(function () {
        var count = strandGrid && strandGrid.data && strandGrid.data.length || 0;
        var selected = strandGrid ? strandGrid.selected : [];
        var selectedLen = selected.length;
        var isAllClicked = evt.target === $allCheckbox[0];
        var isAllChecked = isAllClicked && $allCheckbox[0].checked;
        if (selectedLen > 0 && count === selectedLen || selectedLen < count && selectedLen > 0 || isAllChecked) {
          T1.EventHub.publish("pullDownButton:choose-action:enable");
        } else {
          T1.EventHub.publish("pullDownButton:choose-action:disable");
        }
        _.each($checkBoxes, function (item, index) {
          var $gridItem = $item[index];
          item.id = $gridItem.id;
          primaries[index].pos = index + 1;
        });
      }, 0);
    },
    updateCheckedItemsArr: function () {
      var self = this;
      var $validGrid = self.el.find(".t1as-valid-grid");
      var $checkBoxes = $validGrid.find("strand-checkbox.strand-grid-item");
      var primaries = self.model.get("primaries");
      _.each($checkBoxes, function (item, index) {
        var checked = item.checked ? true : false;
        if (checked) {
          if (primaries[index]) {
            primaries[index].selected = true;
          }
        } else {
          if (primaries[index]) {
            primaries[index].selected = false;
          }
        }
      });
      self.resetCollection();
    },
    showBulkEdit: function () {
      var self = this;
      var $clickField = self.el.find("strand-input");
      var selected;
      self.updateCheckedItemsArr();
      selected = self.collection.getSelected();
      if (self.bulkEditLayout) {
        self.bulkEditLayout.destroy();
      }
      self.bulkEditLayout = new T1Layout({
        template: '<div class="bulk-edit"></div>',
        layout: {
          ".bulk-edit": [{
            module: "creatives/bulkedit",
            viewType: "dialog",
            options: {
              collection: selected,
              bulkAdvertiser: self.model.get("advertiser"),
              consistencyEnabled: true,
              isLocal: true
            }
          }]
        }
      });
      self.bulkEditLayout.load();
      self.hideError($clickField);
    },
    handleReviewSaved: function (data) {
      var self = this;
      var formValues = data && data.formValues || {};
      self.formValues = formValues;
      function updateClick($gridItem, clickThru) {
        var $clickThruInput;
        if ($gridItem.length) {
          $clickThruInput = $gridItem.find(".click-thru strand-input");
          $clickThruInput.val(clickThru);
        }
      }
      function updateMRAID($gridItem, mraid_type, expandable) {
        var isMRAID = mraid_type != undefined ? true : false;
        var $toggle = $gridItem.find("#carat");
        var $mraidCheckBox;
        var $mraidTypeSelect;
        var $mraidExpandSelect;
        if ($gridItem.length) {
          if (!$toggle.hasClass("expanded")) {
            setTimeout(function () {
              $gridItem.find("strand-icon").trigger("click");
            }, 0);
          }
          setTimeout(function () {
            $gridItem.find(".mraid-row").show();
            $mraidCheckBox = $gridItem.find(".mraid-select");
            $mraidCheckBox.attr("checked", isMRAID);
            $mraidTypeSelect = $gridItem.find(".mraid-type-select");
            $mraidTypeSelect.val(mraid_type);
          }, 0);
          if (mraid_type === "Expandable" && expandable) {
            setTimeout(function () {
              $mraidExpandSelect = $gridItem.find(".expandable-select");
              $mraidExpandSelect.val(expandable);
            }, 1e3);
          }
        }
      }
      function updateConcept($gridItem, concept) {
        var $concept;
        if ($gridItem.length) {
          $concept = $gridItem.find(".concept");
          $concept.attr({
            title: concept
          });
          $concept.html(concept);
        }
      }
      function updateLanding($gridItem, clickUrl) {
        var $landingPageInput;
        if ($gridItem.length) {
          $landingPageInput = $gridItem.find(".expansion .click_url strand-input");
          $landingPageInput.val(clickUrl);
        }
      }
      function updateTpas($gridItem, tpas_ad_tag) {
        var $tpasInput;
        if ($gridItem.length) {
          $tpasInput = $gridItem.find(".expansion .tpas_ad_tag strand-textarea");
          $tpasInput.val(tpas_ad_tag);
        }
      }
      function updateClassification($gridItem, classificationData) {
        var $classification;
        if ($gridItem.length) {
          $classification = $gridItem.find(".expansion creatives-classification-grid-drawer");
          _.each($classification, function (component) {
            component.data = [];
            component.data = classificationData;
          });
        }
      }
      self.addRemoveValues(formValues, updateConcept, updateLanding, updateTpas, updateClick, updateMRAID, updateClassification);
      self.resetCollection();
    },
    landingInputChanged: function (e) {
      var self = this;
      var primaries = self.model.get("primaries");
      _.each(primaries, function (item) {
        if (item.index === Number(e.target.id)) {
          item.click_url = $(e.currentTarget).val();
        }
      });
    },
    tpasInputChanged: function (e) {
      var self = this;
      var primaries = self.model.get("primaries");
      _.each(primaries, function (item) {
        if (item.index === Number(e.target.id)) {
          item.tpas_ad_tag = $(e.currentTarget).val();
        }
      });
    },
    updateFields: function () {
      var self = this;
      var primaries = self.model.get("primaries");
      var $gridItem;
      function updateLanding(clickUrl) {
        var $landingPageInput;
        if ($gridItem.length) {
          $landingPageInput = $gridItem.find(".expansion .click_url strand-input");
          clickUrl = clickUrl ? clickUrl : $landingPageInput.val();
          $landingPageInput.val(clickUrl);
        }
      }
      function updateTpas(tpas_ad_tag) {
        var $tpasInput;
        if ($gridItem.length) {
          $tpasInput = $gridItem.find(".expansion .tpas_ad_tag strand-textarea");
          tpas_ad_tag = tpas_ad_tag ? tpas_ad_tag : $tpasInput.val();
          $tpasInput.val(tpas_ad_tag);
        }
      }
      function updateClassification(classificationData) {
        var $classification;
        if ($gridItem.length) {
          $classification = $gridItem.find(".expansion creatives-classification-grid-drawer");
          _.each($classification, function (component) {
            component.data = [];
            component.data = classificationData;
          });
        }
      }
      setTimeout(function () {
        _.each(primaries, function (item) {
          $gridItem = self.getGridRowByID(item.index);
          updateClassification(item.current_classifications);
          updateLanding(item.click_url);
          updateTpas(item.tpas_ad_tag);
        });
      }, 0);
    },
    addRemoveValues: function (formValues, updateConcept, updateLanding, updateTpas, updateClick, updateMRAID, updateClassification) {
      var self = this;
      var primaries = self.model.get("primaries");
      var primary, $gridItem;
      _.each(primaries, function (item, index) {
        if (item.selected) {
          primary = item;
          $gridItem = self.getGridRowByID(item.index);
          if (formValues.status) {
            primary.status = formValues.status;
            self.doRowActivateOrDeactivate(item.index, formValues.status === "1");
          }
          if (formValues.mraid_type !== undefined) {
            primary.mraid_type = formValues.mraid_type;
            if (formValues.expandable !== undefined) {
              primary.expandable = formValues.expandable;
              updateMRAID($gridItem, formValues.mraid_type, formValues.expandable);
            } else {
              updateMRAID($gridItem, formValues.mraid_type);
            }
          }
          if (formValues.concept) {
            primary.concept_id = formValues.concept[index].get("id");
            updateConcept($gridItem, formValues.concept[index].get("name"));
          }
          if (formValues.click_through_url !== undefined) {
            primary.click_through_url = formValues.click_through_url;
            updateClick($gridItem, formValues.click_through_url);
          }
          if (formValues.click_url !== undefined) {
            primary.click_url = formValues.click_url;
            updateLanding($gridItem, formValues.click_url);
          }
          if (formValues.tpas_ad_tag !== undefined) {
            primary.tpas_ad_tag = formValues.tpas_ad_tag;
            updateTpas($gridItem, formValues.tpas_ad_tag);
          }
          if (formValues.add_classifications || formValues.remove_classifications) {
            primary.current_classifications = CreativeUtils.mapClassifications(primary, formValues);
          }
          updateClassification($gridItem, primary.current_classifications);
        }
      });
    },
    handleBulkReviewSave: function (e) {
      $(".saveBulkReview").addClass("save-action");
      this.save(e);
      e.preventDefault();
    },
    getSelectedItems: function () {
      var self = this;
      var $el = self.el;
      var $grid = $el.find(".t1as-valid-grid");
      return $grid && $grid[0].selected || [];
    },
    getGridRowByID: function (id) {
      var $grid = this.el.find(".t1as-valid-grid");
      return $grid.find("strand-grid-item[id=" + id + "]");
    },
    doRowActivateOrDeactivate: function (id, isActivate) {
      var self = this;
      var primaries = self.model.get("primaries");
      var $gridItem = self.getGridRowByID(id);
      var $column = $gridItem.find("._mm_column:not(.checkbox)");
      if ($gridItem) {
        if (isActivate) {
          CreativeUtils.setActiveStatus(primaries, id);
        } else {
          CreativeUtils.setInactiveStatus(primaries, id);
        }
        $column.each(function () {
          $(this).css("opacity", isActivate ? 1 : .5);
        });
        $gridItem.find("strand-input").css("opacity", isActivate ? 1 : .5);
        $gridItem.find("creatives-classification-grid-drawer").css("opacity", isActivate ? 1 : .5);
      }
      setTimeout(function () {
        self.activateIcons(id);
      }, 0);
    },
    activateIcons: function (id) {
      var $gridItem = this.getGridRowByID(id);
      var $toggle = $gridItem.find("._mm_column.toggle");
      $toggle.each(function () {
        $(this).css("opacity", 1);
      });
    },
    doSkipOrUnSkip: function (id, isSkip) {
      var self = this;
      var $allItems;
      var primaries = self.model.get("primaries");
      var $gridItem = self.getGridRowByID(id);
      var $clickField = $gridItem.find("strand-input");
      if ($gridItem) {
        $allItems = $gridItem.find(".upload");
        if (isSkip) {
          $allItems.html("EXCLUDE");
          CreativeUtils.skipCreative(primaries, id);
          self.hideError($clickField);
        } else {
          $allItems.html("INCLUDE");
          CreativeUtils.unskipCreative(primaries, id);
        }
      }
    },
    resetCollection: function () {
      var self = this;
      if (self.collection) {
        self.collection.trigger("reset", self.collection);
      }
      self.collection = new T1asCollection();
      self.collection.add(self.model.get("primaries"));
      return self.collection;
    },
    finalValidationSweep: function () {
      var self = this;
      var primaries = self.model.get("primaries");
      var errors = [];
      _.each(primaries, function (item) {
        var backupOnly = !!item.backup_only;
        var $gridItems = self.getGridRowByID(item.index);
        if (!backupOnly && item.valid === "1") {
          var $clickField = $gridItems.find(".click-thru strand-input");
          var $landingField = $gridItems.find(".expansion strand-input");
          var $mraidTypeField = $gridItems.find(".expansion .mraid-type-select");
          var $mraidExpandField = $gridItems.find(".expansion .expandable-select");
          var $toggle = $gridItems.find("#carat");
          var $toggleIcon = $toggle.find("strand-icon");
          var currentClickValue = item.click_through_url;
          var newClickValue = $clickField.val();
          var currentLandingValue = item.click_url;
          var newLandingValue = $landingField.val();
          var validateObj = {};
          var err;
          if (currentClickValue !== newClickValue) {
            item.click_through_url = newClickValue;
          }
          if (newLandingValue && currentLandingValue !== newLandingValue) {
            item.click_url = newLandingValue;
          }
          validateObj.click_through_url = newClickValue;
          validateObj.click_url = newLandingValue;
          if (item.isMraid && item.mraid_type !== "expandable") {
            validateObj.mraid_type = item.mraid_type || "";
          } else if (item.isMraid && item.mraid_type === "expandable") {
            validateObj.mraid_type = item.mraid_type || "";
            validateObj.expandable = item.expandable || "";
          }
          err = self.model.validateAttrs(validateObj);
          _.each(err, function (msg) {
            if (msg) {
              if (msg.field === "click_through_url") {
                self.displayError(msg.message, $clickField);
                errors.push(msg);
              } else if (!msg.field) {
                self.hideError($clickField);
              }
              if (msg.field === "click_url") {
                self.displayError(msg.message, $landingField);
                errors.push(msg);
              } else if (!msg.field) {
                self.hideError($landingField);
              }
              if (msg.field === "mraid_type") {
                if ($mraidTypeField.length <= 0 && !$toggle.hasClass("expanded")) {
                  $toggleIcon.trigger("click");
                }
                setTimeout(function () {
                  $mraidTypeField = $gridItems.find(".expansion .mraid-type-select");
                  self.displayError(msg.message, $mraidTypeField);
                  errors.push(msg);
                }, 0);
              } else if (!msg.field) {
                self.hideError($mraidTypeField);
              }
              if (msg.field === "expandable") {
                if ($mraidExpandField.length <= 0 && !$toggle.hasClass("expanded")) {
                  $toggleIcon.trigger("click");
                }
                setTimeout(function () {
                  $mraidExpandField = $gridItems.find(".expansion .expandable-select");
                  self.displayError(msg.message, $mraidExpandField);
                  errors.push(msg);
                }, 0);
              } else if (!msg.field) {
                self.hideError($mraidExpandField);
              }
            }
          });
        }
      });
      return errors.length <= 0;
    },
    displayError: function (msg, $field) {
      var errors = [];
      var errorConfig = {
        trigger: "manual",
        getExternalTip: false,
        className: "w-FormError w-review-error-tooltip",
        offset: 10
      };
      errorConfig.tooltipEle = $field;
      errorConfig.trigger = "hover";
      errorConfig.gravity = "s";
      $field.attr("title", msg.length > 100 ? msg.substring(0, 100) + "..." : msg);
      $field[0].error = true;
      T1.Tooltip($field, errorConfig);
      errors.push(msg);
    },
    hideError: function ($field) {
      $field.each(function () {
        $(this).attr("title", "");
        $(this).attr("original-title", "");
        $(this).removeAttr("error");
        $(this).removeClass("error");
      });
      $(".save", this.el).removeClass("save").addClass("saveBulkReview");
    },
    preparePostData: function (formValues) {
      var batch = this.model.get("batch_index");
      var primaries = this.model.get("primaries");
      formValues.batch_index = [];
      _.each(batch, function (item, i) {
        if (primaries[i].valid === "1") {
          formValues.batch_index.push(item);
        }
      });
      return formValues;
    },
    postData: function (formValues, successCallback, statusInvalid, conflictCallback) {
      var self = this;
      var outputModel = new T1asAsset();
      var index = 1;
      var count = 0;
      var queue = [];
      var primaries = self.model.get("primaries");
      var advertiserId = self.model.get("advertiser_id");
      var validationErrors, options, entities, includedModels, mergedModels, outputData;
      outputData = {
        is_https: self.model.get("is_https"),
        batch_index: formValues.batch_index,
        advertiserid: advertiserId
      };
      function creativesMapFunc(data) {
        return new AtomicCreative(data);
      }
      function getModelFormValues(model) {
        var saveObj = {
          status: model.get("status"),
          is_https: model.get("is_https"),
          concept_id: model.get("concept_id"),
          start_date: model.get("start_date"),
          end_date: model.get("end_date"),
          click_through_url: model.get("click_through_url"),
          click_url: model.get("click_url"),
          tpas_ad_tag: model.get("tpas_ad_tag")
        };
        saveObj = JSON.parse(JSON.stringify(saveObj));
        return saveObj;
      }
      function handleSuccess() {
        successCallback();
        count = outputData.batch_index.length;
        T1.Notify("message", count + " Creative(s) successfully added");
        T1.Location.set("creatives/displays");
      }
      function handleError() {
        T1.Notify("message", "Error creating Creative(s)");
      }
      function saveModel() {
        options = {
          success: function (model, data) {
            if (self.isBulkReviewEnabled) {
              var deferred = When.defer();
              entities = new T1Collection().parse(data);
              includedModels = self.model.get("primaries");
              includedModels = includedModels.filter(item => item.valid === "1");
              mergedModels = CreativeUtils.mergeArrays(entities, includedModels);
              mergedModels = _.chain(mergedModels).map(creativesMapFunc).value();
              queue.push(deferred.promise);
              CreativeUtils.saveClassifications(mergedModels).then(function () {
                _.each(mergedModels, function (mergedModel) {
                  mergedModel.set({
                    selected: false
                  });
                  CreativeUtils.saveCreativeWithDefaultConcept(mergedModel, getModelFormValues(mergedModel), statusInvalid, conflictCallback, deferred, advertiserId);
                });
              });
              When.all(queue).then(handleSuccess, handleError);
            } else {
              handleSuccess();
            }
          },
          statusInvalid: function () {
            statusInvalid([]);
          }
        };
        outputModel.save(outputData, options);
      }
      if (formValues.batch_index.length) {
        outputModel.url = "creative_assets/approve";
        validationErrors = self.finalValidationSweep();
        for (var i = 0; i < primaries.length; i++) {
          var item = primaries[i];
          var landingPage = "landingPage." + index;
          var primary = "primary." + index;
          var backup = "backup." + index;
          var concept = "concept." + index;
          var status = "status." + index;
          var is_https = "is_https";
          var clickUrl = "click_url." + index;
          var tpas_ad_tag = "tpas_ad_tag." + index;
          var mraid_type = "mraid_type." + index;
          var expandable = "expandable." + index;
          if (item.valid === "1" && validationErrors) {
            outputData[landingPage] = item.click_through_url;
            outputData[primary] = item.name;
            outputData[backup] = item.backup;
            outputData[concept] = item.concept_id;
            outputData[status] = item.status;
            outputData[is_https] = self.model.get("is_https");
            outputData[clickUrl] = item.click_url;
            outputData[tpas_ad_tag] = item.tpas_ad_tag;
            outputData[mraid_type] = item.mraid_type;
            outputData[expandable] = item.expandable;
            index++;
            count++;
          }
        }
        if (!validationErrors) {
          statusInvalid([]);
        } else {
          saveModel();
        }
      } else {
        handleSuccess();
      }
    },
    load: function () {
      var self = this;
      var $el = self.el;
      this.render().then(function () {
        if (self.isBulkReviewEnabled) {
          if (self.layout1) {
            self.layout1.load();
            $el.show();
          }
        } else {
          if (self.layout2) {
            self.layout2.load();
            $el.show();
          }
        }
      });
      T1.EventHub.publish("organization:select:disable");
    },
    unload: function () {
      T1.EventHub.publish("organization:select:enable");
      this.model.reset();
      if (this.layout) {
        this.layout.unload();
      }
      if (this.layout1) {
        this.layout1.unload();
      }
      if (this.layout2) {
        this.layout2.unload();
      }
    },
    customRedirect: {
      onAlertClose: function () {
        var T1Location = T1.Location;
        var abortedUrl = T1Location.getAbortedURL();
        if (abortedUrl === "" || abortedUrl === false) {
          setTimeout(function () {
            T1Location.set("#creatives/display-t1as");
          }, 1);
        } else {
          T1Location.set(abortedUrl);
        }
      }
    },
    canUnload: function () {
      var self = this;
      var isFormElementsChanged = this.isFormElementsChanged;
      if (isFormElementsChanged) {
        self.openUnloadAlertDialog({
          name: "Add T1AS Assets"
        }, self.customRedirect);
      }
      return !isFormElementsChanged;
    },
    serialize: function () {
      var hasData = !_.isEmpty(this.model.attributes);
      var output = {};
      if (hasData) {
        output.validCount = this.model.get("primaries").length;
        output.totalCount = this.model.get("unusables").length + this.model.get("primaries").length;
        output.invalidCount = this.model.get("unusables").length;
        output.agencyAndAdvertiser = this.getAgencyAndAdvertiserName();
        output.isLoaded = this.isLoaded;
      }
      return output;
    }
  });
  return T1.Form.ViewUtils.extend(T1ASCreateReview);
});
