define('models/supernode',["jQuery", "T1", "T1Comm", "T1Model", "Backbone"], function ($, T1, T1Comm, T1Model, Backbone) {
  "use strict";

  var Supernode = T1Model.extend({
    initialize: function (options) {
      let taxonomies = this.get("taxonomies");
      this.set({
        child_count: taxonomies.length,
        is_targetable: "0",
        code: "99999999",
        type: "supernode",
        taxonomies: this.applyGridSettingsToTaxonomies(taxonomies)
      });
    },
    applyGridSettingsToTaxonomies: function (taxonomies) {
      return taxonomies.map(taxonomy => {
        taxonomy.child_count = 1;
        taxonomy.is_targetable = "0";
        return taxonomy;
      });
    }
  });
  return Supernode;
});
