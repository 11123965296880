define('modules/creatives/concept/views/bins',["jQuery", "Underscore", "T1", "T1View", "T1Layout", "T1BinsView", "T1Spinner", "text!../templates/bin_header.html", "text!../templates/bin_title.html", "text!../templates/bin_group_name.html", "text!../templates/bin_item.html"], function ($, _, T1, T1View, T1Layout, BinsView, Spinner, bin_header, bin_title, bin_group_name, bin_item) {
  var CreativeListBinsView = BinsView.extend({
    type: "CreativeList",
    configsOverride: true,
    isFormElementsChanged: false,
    configs: {
      include: {
        showAndOr: false,
        showBinKindIcon: false,
        count: 0,
        use_or: true
      }
    },
    partials: {
      bin_header: bin_header,
      bin_title: bin_title,
      bin_group_name: bin_group_name,
      bin_item: bin_item
    },
    spinnerOpts: {
      lines: 8,
      length: 2,
      width: 2,
      radius: 2,
      corners: 1,
      rotate: 0,
      trail: 14,
      speed: 1.4
    },
    draggableConfigs: {
      isDroppable: true,
      checkExistenceSharedCollection: false,
      onDropEvent: "bins:droppedCreativeList"
    },
    dataEvents: {
      sharedCollection: {
        reset: "updateBins"
      }
    },
    updateBins: function () {},
    removeItem: function (event) {
      var id = $(event.currentTarget).closest(".item").data("id");
      event.preventDefault();
      event.stopPropagation();
      this.unset(id);
      this.isFormElementsChanged = true;
    },
    unset: function (id) {
      var sharedCollection = this.sharedCollection;
      var item = sharedCollection.get(id);
      var rowCount = 0;
      sharedCollection.remove(id);
      item.set({
        assigned: "0"
      });
      sharedCollection.add(item);
      sharedCollection.each(function (model) {
        if (model.get("assigned") === "1") {
          rowCount++;
        }
      });
      $(".assign-count").html("(" + rowCount + ")");
    },
    mapItems: function (items) {
      var self = this;
      var result = [];
      var modelArray, model, mappedItem, i;
      if (!items || typeof items !== "object") {
        throw new Error("mapItems requires an input object");
      }
      modelArray = items;
      for (i = 0; i < modelArray.length; i++) {
        mappedItem = {};
        model = modelArray[i];
        if (model.get("assigned") === "1") {
          mappedItem.id = model.get("id");
          mappedItem.full_path = model.get("name");
          mappedItem.full_path_end = self.getPath(mappedItem.full_path);
          result.push(mappedItem);
          self.isFormElementsChanged = true;
        }
      }
      return result;
    },
    getPath: function (fullPath) {
      var path = fullPath;
      var goalLength = 25;
      path = path.length > goalLength ? "..." + path.substr(-goalLength) : path;
      return path;
    }
  });
  return CreativeListBinsView;
});
