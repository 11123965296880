define('modules/campaign/models/multiSearch',["jQuery", "T1", "T1Collection", "T1Sortable"], function ($, T1, T1Collection, Sortable) {
  "use strict";

  const endIdx = 4;
  const agencyIdx = 3;
  const advertiserIdx = 2;
  const campaignIdx = 1;
  var filterConfigs = [{
    entity: "campaign",
    fetchOnChange: false
  }, {
    entity: "advertiser",
    fetchOnChange: false
  }, {
    entity: "agency",
    fetchOnChange: false
  }, {
    entity: "organization",
    fetchOnChange: false
  }];
  var entityConfiguration = {
    agencies: {
      urlString: "agencies",
      filterConfiguration: filterConfigs.slice(agencyIdx, endIdx)
    },
    advertisers: {
      urlString: "advertisers",
      filterConfiguration: filterConfigs.slice(advertiserIdx, endIdx)
    },
    campaigns: {
      urlString: "campaigns",
      filterConfiguration: filterConfigs.slice(campaignIdx, endIdx)
    },
    strategies: {
      urlString: "strategies",
      filterConfiguration: filterConfigs,
      fetchOptions: {
        with: "campaign"
      }
    },
    segments: {
      urlString: "segments",
      filterConfiguration: [{
        entity: "organization",
        fetchOnChange: false
      }],
      apiRoot: T1.SEGMENT_API_BASE,
      fetchOptions: {
        sort_by: "created_on"
      }
    }
  };
  var sortConfiguration = {
    fields: {
      name: {
        actual: "name",
        display: "alphabetically"
      }
    },
    default: {
      sort_by: "name",
      order_by: "ascending"
    }
  };
  const collection = T1Collection.extend({
    canCache: true,
    cacheDuration: 6e4,
    filterConfiguration: {
      filters: [{
        entity: "advertiser",
        fetchOnChange: false
      }, {
        entity: "agency",
        fetchOnChange: false
      }, {
        entity: "organization",
        fetchOnChange: false
      }]
    },
    searchConfiguration: {
      fields: {}
    },
    find: function (term, type, opts) {
      let filter, promise;
      const dataDeferred = $.Deferred();
      const fetchOptions = this.fetchOptions;
      if (!term || !term.length) {
        throw new Error("Term is required parameter for find");
      }
      if (!type || term.type <= 0) {
        throw new Error("Term is required parameter for find");
      }
      if (term && term.charAt(0) === "#" && isNaN(term.substr(1))) {
        promise = dataDeferred.promise();
        dataDeferred.reject();
        return promise;
      }
      const entity = entityConfiguration[type];
      if (!entity) {
        throw new Error("Invalid entity name");
      }
      this.urlString = entity.urlString;
      if (entity.fetchOptions) {
        this.fetchOptions = $.extend(this.fetchOptions, entity.fetchOptions);
      }
      this.apiRoot = entity.apiRoot ? entity.apiRoot : T1.API_ROOT;
      if (opts) {
        this.fetchOptions = $.extend(this.fetchOptions, opts);
      }
      this.urlFilter.setConfiguration(entity.filterConfiguration);
      this.urlFilter.set({
        entity: "organization",
        id: this.orgId,
        fetchRequired: false
      });
      this.search.set({
        params: [{
          term: term,
          searchField: "name|id"
        }]
      });
      if (type === "strategies" || opts && opts.with) {
        filter = function (response) {
          var category = type === "strategies" ? "campaign" : opts.with;
          var result = $.map(response.data, function (item) {
            item.category = item[category].name;
            item.category_id = item[category].id;
            item.category_type = category;
            delete item[category];
            return item;
          });
          response.data = result.sort(function (a, b) {
            var aCategory = a.category.toLowerCase();
            var bCategory = b.category.toLowerCase();
            if (aCategory === bCategory) {
              return 0;
            }
            return aCategory > bCategory ? 1 : -1;
          });
          return response;
        };
      } else {
        filter = function (result) {
          return result;
        };
      }
      promise = dataDeferred.pipe(filter);
      if (this.ajx) {
        this.ajx.abort();
      }
      this.ajx = this.fetch({
        success: () => {
          var result = {
            data: this.toJSON(),
            count: this.count
          };
          dataDeferred.resolve(result);
          this.ajx = null;
          this.fetchOptions = fetchOptions;
        }
      });
      return promise;
    }
  });
  return Sortable.extendCollection(collection, sortConfiguration);
});
